import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { FaPlus, FaTimes } from 'react-icons/fa';
import CreatableSelect from 'react-select/creatable';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row, CustomInput, Badge } from "reactstrap";
import { suggestionTypeOptions, trackerTypeOptions, dateError, imageFormatError, validateMacros, fileSizeExceedError } from "../../../config/configSmartSuggestions";
import { X } from "react-feather";
import { iconSize } from "../../../config/UserConfig";
import { staticIconSize } from "../../../config/configSuggestionDrawer";
import ConfirmationModal from "../../../pages/ui-elements/ConfirmationModal";
import ResponseModal from "../../../pages/ui-elements/ResponseModal";
import Routes from "../../../routes/index";
import { error, errorDescription, redirectOnTokenExpiry, redirectTo, handleCommaSeparatedTags } from "../../../utilities/commonUtil";
import moment from "moment";
import ToolTip from "../../../utilities/ToolTip";
import { contentResponses, showEditSuggestionDrawerContentButton, deeplinkTypeOptions, watermarkTypes,stylePropertiesOptions } from "../../../config/configSuggestionDrawer";
import { createSuggestionDrawerCategory, createSuggestionDrawerContent, fetchSuggestionDrawerCategoriesData, fetchSuggestionDrawerCategoryDataByID, fetchSuggestionDrawerContentData, fetchSuggestionDrawerContentDataByID, updateSuggestionDrawerContent } from "../../../utilities/apiUtils/suggestionDrawer";
import TagsInput from "react-tagsinput";
import { getKeyboardPromptsLanguages } from "../../../utilities/apiUtils/keyboardPrompts";
import { suggestionDrawerResponses } from "../../../config/configSuggestionDrawer";
import { validateHexCode } from "../../../utilities/commonUtil";
import { set } from "lodash";
import { getUTMCampaigns } from "../../../utilities/apiUtils/users";
import { convertUTCToIST, convertISTToUTC } from "../../../utilities/commonUtil";
const ManageSuggestionDrawerContent = (props) => {
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");
  const [changeType, setChangeType] = useState("Add");
  const [isPartnerNameOther, setIsPartnerNameOther] = useState(false)
  const [partnerNameOther, setPartnerNameOther] = useState("")
  const [newSuggestionDrawerId, setNewSuggestionDrawerId] = useState("")
  const [macroErrors, setMacroErrors] = useState({
    deeplink: "",
    clickUrl: "",
    redirectionUrl: ""
  })
  const [trackerUrlMacroError, setTrackerUrlMacroError] = useState([])
  const [trackers, setTrackers] = useState([]);
  const [tags, setTags] = useState([]);
  const [addLanguageCodeOption, setAddLanguageCodeOption] = useState([]);
  const hexColorRegex = /^#?([a-fA-F0-9]{6})$/;
  const handleTrackerChange = (event, index) => {
    const { name, value } = event.target;
    let temp = [...trackers];
    temp[index][name] = value;
    setTrackers(temp);
  };


  const [details, setDetails] = useState({
    name: "",
    description: "",
    deeplink: null,
    redirectionalDetails: "",
    pageSize: 0,
    suggestionDrawerAspectRatio: null,
    suggestionDrawerContentAspectRatio: null,
    minAppVersion: 0,
    maxAppVersion: 0,
    aspectRatio: null,
    text: "",
    mappingCategories: null,
    shareText: null,
    deeplinkType: deeplinkTypeOptions[0],
    enableShareText: true,
    enableExactTagMatch: false,
    isWatermarkEnabled: false,
    cta: {
      text: "",
      textColor: "",
      darkModeTextColor: "",
      backgroundColor: "",
      darkThemeBackgroundColor: "",
      ctaDeeplink: "",
      clickTracker: []
    },
    buttonDeeplink: null,
    backgroundColorDark: "",
    backgroundColorLight: "",
    body: "",
    bodyTextColorDark: "",
    bodyTextColorLight: "",
    subtext: "",
    subTextColorDark: "",
    subTextColorLight: "",
    title: "",
    titleTextColorDark: "",
    titleTextColorLight: "",
    assetWidthPercentage: 0,
    withoutImageAspectRatio: 0,
    utmCampaign: null,
    startDateTime: "",
    endDateTime: "",
    styleProperties : {
      leftMarginInDP: null,
      rightMarginInDP: null
    }
  });
  const [cta, setcta] = useState({})
  const [mapping, setmapping] = useState([])
  const [categories, setCategories] = useState([])

  const [shareAssetFile, setShareAssetFile] = useState(null);
  const [shareAssetFileURL, setShareAssetFileURL] = useState(null);
  const [shareAssetFilename, setShareAssetFilename] = useState(null);

  const [audioAnimationFile, setAudioAnimationFile] = useState(null);
  const [audioAnimationFileURL, setAudioAnimationFileURL] = useState(null);
  const [audioAnimationFilename, setAudioAnimationFilename] = useState(null);

  const [audioFile, setAudioFile] = useState(null);
  const [audioFileURL, setAudioFileURL] = useState(null);
  const [audioFilename, setAudioFilename] = useState(null);

  const [lightThemeButtonImageFile, setLightThemeButtonImageFile] = useState(null);
  const [lightThemeButtonImageURL, setLightThemeButtonImageURL] = useState(null);
  const [lightThemeButtonImageFilename, setLightThemeButtonImageFilename] = useState(null);

  const [darkThemeButtonImageFile, setDarkThemeButtonImageFile] = useState(null);
  const [darkThemeButtonImageURL, setDarkThemeButtonImageURL] = useState(null);
  const [darkThemeButtonImageFilename, setDarkThemeButtonImageFilename] = useState(null);
  const [customWatermarkedShareAssetFile, setCustomWatermarkedShareAssetFile] = useState(null);
  const [customWatermarkedShareAssetFileURL, setCustomWatermarkedShareAssetFileURL] = useState(null);
  const [customWatermarkedShareAssetFilename, setCustomWatermarkedShareAssetFilename] = useState(null);
  const [utmCampaignOptions, setUtmCampaignOptions] = useState([]);

  const [creatives, setCreatives] = useState({
    imageFile: null,
    imageFilename: "",
    imageURL: "",
    providerIconImageFile: null,
    providerIconImageFilename: "",
    providerIconImageURL: "",
  })
  const [keywords, setKeywords] = useState([
    {
      languageCode: "",
      tags: [],
    },
  ]);
  const [visibilitySettings, setVisibilitySettings] = useState({
    categories: [],
  })
  useEffect(() => {
    getCategories();
    setLanguageCode();
  }, [])
  const validateColor = (color) => {
    if (color !== null && color !== "" && color !== undefined) {
      return validateHexCode(color);
    }
    return true;
  }
  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let setConfirmationModalState = (event) => {
    event.preventDefault();
    setConfirmationModalIsOpen(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);

    // if edit - redirect to home
    if (props.match.params.id) {
      redirectTo(Routes.suggestionDrawerDashboard.children.contents.path);
    } else {
      redirectTo(Routes.suggestionDrawerDashboard.children.contents.path);
    }
  };
  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };
  const handleCTAChange = (event) => {
    const { checked } = event.target;
    console.log("here")
    setDetails(prevState => ({
      ...prevState,
      enableCTA: checked
    }));
  };
  const handleRepeatKeywords = () => {
    const selectedLanguages = keywords.map((item) => item.languageCode.value);
    const keywordsToRepeat = keywords.length > 0 ? keywords[0].tags : [];
    const repeatedKeywords = selectedLanguages.map((language) => ({
      languageCode: { value: language, label: language },
      tags: keywordsToRepeat,
    }));
    setKeywords([...keywords, ...repeatedKeywords]);
  };
  const handleDetailsChange = (event) => {
    const { name, value } = event.target;
    setCreatives((prevState) => ({
      ...prevState,
      staticIcon: null,
      staticIconFilename: "",
      staticIconSrc: "",
      staticIconAspectRatio: null,
      staticDarkThemeIcon: null
    }))
    setDetails((prevState) => ({
      ...prevState,
      [name]: value,
      cta: {
        ...prevState,
        [name]: value,
      }
    }));
  };
  const getCategories = async () => {
    let params = {
      status: "activated",
    }
    let categories = await fetchSuggestionDrawerCategoriesData(params)

    if (categories.categories && categories.categories?.length > 0) {
      const options = []
      categories.categories.forEach(type => {
        options.push({
          label: type.name + ":" + type.id,
          value: type.id
        })
      })
      setCategories(options)
    }
  }


  useEffect(() => {
    getCategories()
    const fetchData = async () => {
      const utmCampaigns = await getUTMCampaigns();
      setUtmCampaignOptions(utmCampaigns);
      // check path params
      if (props.match.params.id) {
        setChangeType("Edit");
        fetchSuggestionDrawerData(props.match.params.id, utmCampaigns);
      }
    }

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.id]);

  const setLanguageCode = async () => {
    const languageCode = await getKeyboardPromptsLanguages();
    if (languageCode?.languages?.length > 0) {
      const languageCodeOptions = languageCode.languages.map((language) => {
        return {
          value: language.code,
          label: language.name + " : " + language.code,
          target: {
            name: "languageCode",
            value: {
              value: language.code,
              label: language.name + " : " + language.code,
            },
          },
        };
      });
      setAddLanguageCodeOption(languageCodeOptions);
    }
  };

  const handleTagsChange = (tag) => {
    // Update the tags array with unique values

    let tagsTrimmed = tag.map((item) => item.trim());


    tagsTrimmed = handleCommaSeparatedTags(tagsTrimmed);

    // remove duplicates if present in the event
    const uniquetags = [...new Set(tagsTrimmed)];

    setTags(uniquetags);
  };

  const handleSubmit = async () => {
     // Convert startDateTime and endDateTime to IST formatted strings
     const startDateTime= convertISTToUTC(details.startDateTime);
     const endDateTime = convertISTToUTC(details.endDateTime);
 
     details.startDateTime = startDateTime;
     details.endDateTime = endDateTime;
     // Parse the formatted strings back into Date objects for comparison
     const startDate = new Date(startDateTime);
     const endDate = new Date(endDateTime);
 
     // Compare the dates
     if (startDate > endDate) {
       let text = dateError;
       showErrorModal(text);
       return;
     }
    const contentType = visibilitySettings.contentType?.map(type => type.value)

    let staticIconFilename = "", animatedIconFilename = "", providerIconFilename = "", staticIconSrc = "", animatedIconSrc = "", providerIconSrc = "";
    if (creatives.staticIconSrc) {
      staticIconFilename = creatives.staticIconSrc.startsWith("data:") ? "" : creatives.staticIconFilename
      staticIconSrc = creatives.staticIconSrc.startsWith("data:") ? creatives.staticIconSrc : ""
    }
    if (creatives.animatedIconSrc) {
      animatedIconFilename = creatives.animatedIconSrc.startsWith("data:") ? "" : creatives.animatedIconFilename
      animatedIconSrc = creatives.animatedIconSrc.startsWith("data:") ? creatives.animatedIconSrc : ""
    }
    if (creatives.providerIconSrc) {
      providerIconFilename = creatives.providerIconSrc.startsWith("data:") ? "" : creatives.providerIconFilename
      providerIconSrc = creatives.providerIconSrc.startsWith("data:") ? creatives.providerIconSrc : ""
    }
    const trackersPayload = trackers.map(tracker => ({
      type: tracker.type?.value ?? "",
      url: tracker.url
    })).filter(tracker => tracker.type)
    const mapped = []
    if (details.mappingCategories != null) {
      details.mappingCategories.forEach(item => {
        if (item.value != null) {
          mapped.push(
            {
              "id": null,
              "suggestionDrawerCategoryId": item.value,
              "suggestionDrawerContentId": null
            }
          );
        }
      })
    }
    if (!validateColor(cta.textColor)) {
      let text = "not Valid text Color"
      showErrorModal(text);
      return;
    }
    if (!validateColor(cta.darkModeBackgroundColor)) {
      let text = "not Valid dark Mode Background Color "
      showErrorModal(text);
      return;
    }
    if (!validateColor(cta.backgroundColor)) {
      let text = "not Valid  Background Color "
      showErrorModal(text);
      return;
    }
    if (!validateColor(cta.darkModeTextColor)) {
      let text = "not Valid  Dark Mode Text  Color "
      showErrorModal(text);
      return;
    }
    if (!validateTimeSlotsOnSubmit(dailyTimeSlots)) return;
    const validTimeSlots = cleanDailyTimeSlots(dailyTimeSlots);

    let tagsPayload = []
    if (tags.length > 0) {
      tagsPayload = tags.map(tag => ({
        tags: tag
      }))
    }
    
    const payload = {
      name: details.name,
      description: details.description,
      imageFile: creatives.imageFile,
      imageFilename: creatives.imageFilename,
      imageURL: creatives.imageURL,
      startDateTime: details.startDateTime,
      endDateTime: details.endDateTime,
      providerIconImageFile: creatives.providerIconImageFile,
      providerIconImageFilename: creatives.providerIconFilename,
      providerIconImageURL: creatives.providerIconImageURL,
      shareAssetFile: shareAssetFile,
      audioAnimationFile: audioAnimationFile,
      audioFile: audioFile,
      customWatermarkedShareAssetFile: customWatermarkedShareAssetFile,
      isAssetProcessingEnabled: customWatermarkedShareAssetFile == null && customWatermarkedShareAssetFilename === "",
      deeplink: (details?.deeplinkType?.label !== deeplinkTypeOptions[1].label) ? null : details?.deeplink,
      redirectionalDetails: details.redirectionalDetails,
      pageSize: Number(details.pageSize),
      enableExactTagMatch: details.enableExactTagMatch,
      isWatermarkEnabled: details.isWatermarkEnabled,
      suggestionDrawerAspectRatio: details.suggestionDrawerAspectRatio,
      suggestionDrawerContentAspectRatio: details.suggestionDrawerContentAspectRatio,
      minAppVersion: (Number(details.minAppVersion) == 0) ? null : Number(details.minAppVersion),
      maxAppVersion: (Number(details.maxAppVersion) == 0) ? null : Number(details.maxAppVersion),
      typingStateDeeplink: details.typingStateDeeplink,
      nonTypingStateDeeplink: details.nonTypingStateDeeplink,
      aspectRatio: Number(details.aspectRatio),
      shareText: details?.shareText?.trim() === "" ? null : details?.shareText?.trim(),
      deeplinkType: (details.deeplinkType?.label == "No Deeplink") ? "custom" : details?.deeplinkType?.value,
      enableShareText: details.enableShareText,
      trackers: trackersPayload,
      text: details.text,
      cta: details.enableCTA ? (Object.keys(cta).length > 0 ? cta : null) : null,
      mapping: (mapped.length > 0) ? mapped : null,
      tags: (tags.length > 0) ? tagsPayload : null,
      backgroundColorDark: details.backgroundColorDark,
      backgroundColorLight: details.backgroundColorLight,
      body: details.body,
      bodyTextColorDark: details.bodyTextColorDark,
      bodyTextColorLight: details.bodyTextColorLight,
      subtext: details.subtext,
      subTextColorDark: details.subTextColorDark,
      subTextColorLight: details.subTextColorLight,
      title: details.title,
      titleTextColorDark: details.titleTextColorDark,
      titleTextColorLight: details.titleTextColorLight,
      assetWidthPercentage: (Number(details.assetWidthPercentage) == 0) ? null : Number(details.assetWidthPercentage),
      withoutImageAspectRatio: (Number(details.withoutImageAspectRatio) == 0) ? null : Number(details.withoutImageAspectRatio),
      buttonDeeplink: details.buttonDeeplink === "" ? null : details.buttonDeeplink,
      darkThemeButtonImageFile: darkThemeButtonImageFile,
      lightThemeButtonImageFile: lightThemeButtonImageFile,
      utmCampaign: details?.utmCampaign?.value,
      dailyTimeSlots: validTimeSlots.length ? JSON.stringify(validTimeSlots) : null,
      styleProperties: {
        leftMarginInDP: details.styleProperties.leftMarginInDP
          ? details.styleProperties.leftMarginInDP.value
          : null,
        rightMarginInDP: details.styleProperties.rightMarginInDP
          ? details.styleProperties.rightMarginInDP.value
          : null,
      }
    }
    if (props.match.params.id) {
      // update 
      try {
        let response = await updateSuggestionDrawerContent(props.match.params.id, payload)
        if (response === "Success") {
          setSuccessModalText(suggestionDrawerResponses.updatedSuccessfully);
          setSuccessModalDisplay(true);
        } else {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken ||
            response.errorCode === error.unauthorized
          )
            redirectOnTokenExpiry();
          let text = errorDescription(error.unexpectedError);
          showErrorModal(text);
        }
      } catch (err) {
        let text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      }
    } else {
      try {
        let response = await createSuggestionDrawerContent(payload)
        if (response.status === "success") {
          const regex = /Content ID: (\d+)/;
          const match = response.message.match(regex);
          setSuccessModalText(suggestionDrawerResponses.addedSuccessfully);
          if (match) {
            const id = match[1];
            console.log(id)
            setNewSuggestionDrawerId(id)
          } else {
            console.log("ID not found in the message");
          }
          setSuccessModalDisplay(true);
        } else if (
            response.data.errorCode === error.tokenExpired ||
            response.data.errorCode === error.invalidAccessToken ||
            response.data.errorCode === error.unauthorized
          ) {
            redirectOnTokenExpiry();
          let text = errorDescription(error.unexpectedError);
          showErrorModal(text);
        } else {
          if (response.data.errorCode === "suggestionDrawerContentCreationError") {
            let text = response.data.errorDescription;
            showErrorModal(text);
          }
        }
      } catch (err) {
        let text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      }
    }
  };
  const handleCreativesChange = (event, type) => {
    const { name, files, accept } = event.target;
    const acceptedFormat = accept.split(",").map(format => format.trim())
    event.persist();
    // For showing Image preview
    if (files[0]) {
      // Check for size
      if ((type === "static" && (files[0].size > staticIconSize))) {
        let text = fileSizeExceedError;
        showErrorModal(text);
        setCreatives((prev) => ({
          ...prev,
          [name + "File"]: null,
          [name + "URL"]: "",
          [name + "Filename"]: "",
        }));
        return
      }
      // Check if format is accepted
      if (!acceptedFormat.includes(files[0].type)) {
        let text = imageFormatError;
        showErrorModal(text);
        setCreatives((prev) => ({
          ...prev,
          [name]: null,
          [name + "URL"]: "",
          [name + "AspectRatio"]: null,
          [name + "Filename"]: "",
        }));
        return
      }

      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          // Check if image is square when ad type IS NOT "product"

          setCreatives((prev) => ({
            ...prev,
            [name + "File"]: reader.result,
            [name + "URL"]: reader.result,
            [name + "AspectRatio"]: Number(image.width / image.height).toFixed(3),
            [name + "Filename"]: files[0]?.name,
          }));
        }
      };
    } else {
      setCreatives((prev) => ({
        ...prev,
        [name + "File"]: null,
        [name + "URL"]: "",
        [name + "AspectRatio"]: null,
        [name + "Filename"]: "",
      }));
    }
  };


  const fetchSuggestionDrawerData = async (id, utmCampaigns) => {
    try {
      let response = await fetchSuggestionDrawerContentDataByID(id)
      let params = {
        status: "activated",
      }
      let categories = await fetchSuggestionDrawerCategoriesData(params)
      setTags(response?.tags.map(tag => tag.tags))
      if (response?.id) {
        const trackers = response?.trackers?.map(tracker => ({
          ...tracker,
          type: {
            label: trackerTypeOptions.find(t => t.value === tracker?.type)?.label ?? tracker?.type,
            value: tracker?.type,
          }
        }))
        const map = response?.mapping.map(item => {
        const matchingCategory = categories.categories.find(category => category.id === item.suggestionDrawerCategoryId);
          return {
            
            value: item.suggestionDrawerCategoryId,
            label: matchingCategory ? matchingCategory.name : "Not Activated", // Default label for unmatched categories
          };
        });
        let type = {}
        if (response?.type) {
          type = {
            label: suggestionTypeOptions.find(type => type.value === response?.type)?.label ?? response?.type,
            value: response?.type
          }
        } else {
          type = details.type
        }
        setcta(
          {
            text: response?.cta?.text,
            textColor: response?.cta?.textColor,
            darkModeTextColor: response?.cta?.darkModeTextColor,
            backgroundColor: response?.cta?.backgroundColor,
            darkModeBackgroundColor: response?.cta?.darkModeBackgroundColor,
            deeplink: response?.cta?.deeplink,
            clickTracker: response?.cta?.clickTracker
          }
        )
        setDetails((prevState) => ({
          ...prevState,
          subTextColorDark: response?.subTextColorDark,
          subTextColorLight: response?.subTextColorLight,
          subtext: response?.subtext,
          bodyTextColorDark: response?.bodyTextColorDark,
          bodyTextColorLight: response?.bodyTextColorLight,
          body: response?.body,
          titleTextColorDark: response?.titleTextColorDark,
          titleTextColorLight: response?.titleTextColorLight,
          title: response?.title,
          startDateTime: response?.startDateTime
          ? convertUTCToIST(response.startDateTime)
          : "",
        endDateTime: response?.endDateTime
          ? convertUTCToIST(response.endDateTime)
          : "",
          enableExactTagMatch: response?.enableExactTagMatch,
          isWatermarkEnabled: response?.isWatermarkEnabled,
          backgroundColorDark: response?.backgroundColorDark,
          backgroundColorLight: response?.backgroundColorLight,
          assetWidthPercentage: response?.assetWidthPercentage,
          withoutImageAspectRatio: response?.withoutImageAspectRatio,
          buttonDeeplink: response?.buttonDeeplink,
          description: response?.description,
          deeplink: response?.deeplink,
          contentType: response?.contentType,
          redirectionalDetails: response?.redirectionalDetails,
          pageSize: response?.pageSize,
          suggestionDrawerAspectRatio: response?.suggestionDrawerAspectRatio,
          suggestionDrawerContentAspectRatio: response?.suggestionDrawerContentAspectRatio,
          minAppVersion: response?.minAppVersion,
          maxAppVersion: response?.maxAppVersion,
          typingStateDeeplink: response?.typingStateDeeplink,
          nonTypingStateDeeplink: response?.nonTypingStateDeeplink,
          aspectRatio: response?.aspectRatio,
          text: response?.text,
          shareText: response?.shareText,
          deeplinkType: response?.deeplink === null && response?.deeplinkType!=="default"? deeplinkTypeOptions[0] : deeplinkTypeOptions.find(type => type.value === response?.deeplinkType),
          enableShareText: response?.enableShareText,
          utmCampaign: response?.utmCampaign ? utmCampaigns.find(campaign => campaign.value === response?.utmCampaign) : null,
          cta: {
            text: response?.cta?.text,
            textColor: response?.cta?.textColor,
            darkModeTextColor: response?.cta?.darkModeTextColor,
            backgroundColor: response?.cta?.backgroundColor,
            darkThemeBackgroundColor: response?.cta?.darkThemeBackgroundColor,
            deeplink: response?.cta?.deeplink,
            clickTracker: response?.cta?.clickTracker
          },
          name: response?.name,
          mappingCategories: map,
          styleProperties: {
            leftMarginInDP: stylePropertiesOptions.find(
              (option) => option.value === response?.styleProperties?.leftMarginInDP
            ) ?? null,
            rightMarginInDP: stylePropertiesOptions.find(
              (option) => option.value === response?.styleProperties?.rightMarginInDP
            ) ?? null,
          }
        }))
        updateDailyTimeSlotsFromResponse(response);
        setCreatives((prevState) => ({
          ...prevState,
          imageURL: response?.imageURL,
          imageFilename: response?.imageFilename,
          providerIconImageURL: response?.providerIconImageURL,
          providerIconImageFilename: response?.providerIconImageFilename
        }))
        setTrackers(trackers ?? [])

        setShareAssetFileURL(response?.shareAssetURL)
        setShareAssetFilename(response?.shareAssetURL?.split("/").pop())
        setAudioAnimationFileURL(response?.audioAnimationURL)
        setAudioAnimationFilename(response?.audioAnimationURL?.split("/").pop())

        setAudioFileURL(response?.audioURL)
        setAudioFilename(response?.audioURL?.split("/").pop())

        setDarkThemeButtonImageFilename(response?.darkThemeButtonImageURL?.split("/").pop())
        setDarkThemeButtonImageURL(response?.darkThemeButtonImageURL)

        setLightThemeButtonImageFilename(response?.lightThemeButtonImageURL?.split("/").pop())
        setLightThemeButtonImageURL(response?.lightThemeButtonImageURL)
        setCustomWatermarkedShareAssetFileURL(response?.customWatermarkedShareAssetURL)
        setCustomWatermarkedShareAssetFilename(response?.customWatermarkedShareAssetURL.split("/").pop())

      } else {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken ||
          response.errorCode === error.unauthorized
        )
          redirectOnTokenExpiry();
      }
    } catch (err) {
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setcta({
      ...cta,
      [name]: value
    })

  };
  const [dailyTimeSlots, setDailyTimeSlots] = useState([
    { start: '', end: '' }
  ]);
  const [slotErrors, setSlotErrors] = useState([]); 
  
    // Validate all time slots
    const validateTimeSlotsOnSubmit = (slots) => {
      for (let slot of slots) {
        if (slot.start || slot.end) {
          if (!slot.start || !slot.end) {
            showErrorModal("Both start and end times are required for all time slots.");
            return false;
          }
        }
        if (slot.start > slot.end) {
          showErrorModal("Start time must be earlier than end time for all time slots.");
          return false;
        }
      }
      return true;
    };
  const addDailyTimeSlot = () => {
    setDailyTimeSlots([...dailyTimeSlots, { start: '', end: '' }]);
  };
  const cleanDailyTimeSlots = (slots) => {
    return slots.filter(slot => slot.start && slot.end); 
  };
  const removeLastDailyTimeSlot = (index) => {
    if (dailyTimeSlots.length > 1) {
      setDailyTimeSlots(dailyTimeSlots.filter((slot, i) => i !== index));
      setSlotErrors(slotErrors.filter((error, i) => i !== index));
    } else if (dailyTimeSlots.length === 1) {
      setDailyTimeSlots([{ start: '', end: '' }]);
      setSlotErrors([]); 
    }
  };
  
  const renderDailyTimeSlots = () => {
    return dailyTimeSlots.map((item, index) => (
      <div key={index} className="row mb-3">
        <div className="col-md-5">
          <FormGroup>
            <Label for={`start${index}`}>Start Time</Label>
            <Input
              type="time"
              id={`start${index}`}
              name={`start${index}`}
              value={item.start}
              onChange={(e) => handleDailyTimeSlotChange(e, index, 'start')}
            />
          </FormGroup>
        </div>
        <div className="col-md-5">
          <FormGroup>
            <Label for={`end${index}`}>End Time</Label>
            <Input
              type="time"
              id={`end${index}`}
              name={`end${index}`}
              value={item.end}
              onChange={(e) => handleDailyTimeSlotChange(e, index, 'end')}
            />
          </FormGroup>
        </div>
        <div className="col-md-2 d-flex align-items-center">
          {index === dailyTimeSlots.length - 1 && (
            <div>
              <Button color="primary" onClick={addDailyTimeSlot} className="mr-2">
                <FaPlus />
              </Button>
              {dailyTimeSlots.length > 0 && ( 
                <Button color="danger" onClick={() => removeLastDailyTimeSlot(index)}>
                <FaTimes />
              </Button>
              )}
            </div>
          )}
        </div>
  
        {/* Error message section for start/end time validation */}
        {slotErrors[index] && (
          <div className="col-12 text-danger mt-1">
            {slotErrors[index]} 
          </div>
        )}
      </div>
    ));
  };
  
  const handleDailyTimeSlotChange = (e, index, field) => {
    try {
      const updatedSlots = [...dailyTimeSlots];
      const time = e.target.value.length === 5 ? `${e.target.value}:00` : e.target.value;
      updatedSlots[index][field] = time;
  
      // Validate start < end and update errors dynamically
      const start = updatedSlots[index].start;
      const end = updatedSlots[index].end;
      const updatedErrors = [...slotErrors];
  
      if (start && end && start >= end) {
        updatedErrors[index] = "Start time must be earlier than end time.";
      } else {
        updatedErrors[index] = ""; // Clear error message if start time is earlier than end time
      }
  
      // Clear error message if time slot is removed
      if (dailyTimeSlots.length > updatedSlots.length) {
        updatedErrors.splice(index, 1);
      }
  
      setDailyTimeSlots(updatedSlots);
      setSlotErrors(updatedErrors);
    } catch (error) {
    }
  };
  
  // Function to safely update dailyTimeSlots from the API response
  const updateDailyTimeSlotsFromResponse = (response) => {
    try {
      const parsedSlots = response.dailyTimeSlots
        ? JSON.parse(response.dailyTimeSlots)
            .filter(slot => slot.start && slot.end) 
            .map(({ start, end }) => ({
              start: start.endsWith(':00') ? start : `${start}:00`,
              end: end.endsWith(':00') ? end : `${end}:00`,
            }))
        : [];
  
      setDailyTimeSlots(parsedSlots.length > 0 ? parsedSlots : [{ start: '', end: '' }]);
    } catch (error) {
      //console.error('Error parsing dailyTimeSlots:', error);
      setDailyTimeSlots([{ start: '', end: '' }]); 
    }
  };
  

  const isVideoFile = (file) => {
    return file && (file.startsWith('data:video') || file.endsWith('.mp4') || file.endsWith('.mkv'));
  };
  return (
    <Container fluid className="p-0">
      <Row className="mb-3">
        <Col md={6} xs={6}>
          <h1>{changeType} Suggestion Drawer Content </h1>
        </Col>
      </Row>

      <Form onSubmit={(event) => setConfirmationModalState(event)}>
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <Row form>

                  <Col lg={4} md={4}>
                    <FormGroup className="d-flex align-items-center">
                      <Label
                        className="font-weight-bolder mb-0"
                        style={{ fontSize: "14px" }}
                      >
                        Enable Share Text <ToolTip
                          target={"enableShareText"}
                          content={`Enable Share Text for deeplink.`}
                          position={"right"}
                        />
                      </Label>
                      <div className="custom-control custom-switch">
                        <CustomInput
                          type="checkbox"
                          id="enableShareText"
                          value={details.enableShareText}
                          onChange={(event) => {
                            setDetails((prevState) => ({
                              ...prevState,
                              enableShareText: !details.enableShareText
                            }))
                          }}
                          checked={details.enableShareText}
                          disabled={!showEditSuggestionDrawerContentButton()}
                        />
                      </div>
                    </FormGroup>
                  </Col>

                  <Col lg={4} md={4}>
                  </Col>
                  <Col lg={4} md={4}>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        <strong>
                          Image
                        </strong>
                        <ToolTip
                          data={
                            details.type?.value === "product"
                              ? "Formats: png, jpg, jpeg, webp, Aspect ratio: any, Size: 100KB"
                              : "Formats: png, jpg, jpeg, webp, Aspect ratio: 1:1, Size: 100KB"
                          }
                          id="staticIconTooltip"
                        />
                      </Label>
                      <Container className="custom-file">
                        <Input
                          type="file"
                          className="custom-file-input"
                          accept="image/png, image/jpg, image/jpeg, image/webp"
                          name="image"
                          onChange={(event) => {
                            handleCreativesChange(event, "static")
                          }}
                          disabled={!showEditSuggestionDrawerContentButton()}
                        />
                        <Label className="custom-file-label">
                          <strong>{creatives.imageFilename || "Choose a static image"}</strong>
                        </Label>
                      </Container>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        <strong>
                          Provider Icon Image
                        </strong>
                        <ToolTip
                          data={
                            details.type?.value === "product"
                              ? "Formats: png, jpg, jpeg, webp, Aspect ratio: any, Size: 100KB"
                              : "Formats: png, jpg, jpeg, webp, Aspect ratio: 1:1, Size: 100KB"
                          }
                          id="staticIconTooltip"
                        />
                      </Label>
                      <Container className="custom-file">
                        <Input
                          type="file"
                          className="custom-file-input"
                          accept="image/png, image/jpg, image/jpeg, image/webp"
                          name="providerIconImage"
                          onChange={(event) => {
                            handleCreativesChange(event, "static")
                          }}
                          disabled={!showEditSuggestionDrawerContentButton}
                        />
                        <Label className="custom-file-label">
                          <strong>{creatives.providerIconImageFilename || "Choose a static image"}</strong>
                        </Label>
                      </Container>
                    </FormGroup>
                  </Col>
                  <Col lg={6} md={6}>
                    {(creatives.imageURL) ? (
                      <Col className="pb-4">
                        <img
                          src={creatives.imageURL}
                          alt="Static Icon"
                          width="120"
                        />
                      </Col>
                    ) : (
                      <Col></Col>
                    )}
                  </Col>
                  <Col lg={6} md={6}>
                    {(creatives.providerIconImageURL) ? (
                      <Col className="pb-4">
                        <img
                          src={creatives.providerIconImageURL}
                          alt="Static Icon"
                          width="120"
                        />
                      </Col>
                    ) : (
                      <Col></Col>
                    )}
                  </Col>
                  {/* Create a video upload section + preview */}
                  <Col lg={6}>
                    <FormGroup>
                      <Label>
                        <strong>
                          Share Asset Video
                        </strong>
                        <ToolTip
                          data="Formats: mp4, mkv Size : 10 MB"
                          id="shareAssetVideoTooltip"
                        />
                      </Label>
                      <Container className="custom-file">
                        <Input
                          type="file"
                          className="custom-file-input"
                          accept="video/mp4, video/mkv"
                          name="shareAssetVideo"
                          onChange={(event) => {
                            // check file size
                            if (event.target.files[0].size > staticIconSize) {
                              let text = fileSizeExceedError;
                              showErrorModal(text);
                              return;
                            }
                            // save base64 of the video 
                            var reader = new FileReader();
                            reader.readAsDataURL(event.target.files[0]);
                            setShareAssetFilename(event.target.files[0].name);
                            reader.onload = () => {
                              setShareAssetFile(reader.result);
                            }
                          }}
                          disabled={!showEditSuggestionDrawerContentButton()}
                        />
                        <Label className="custom-file-label">
                          <strong>{shareAssetFilename || "Choose a video"}</strong>
                        </Label>
                      </Container>
                    </FormGroup>
                    {(shareAssetFileURL || shareAssetFile) ? (
                      <Col className="pb-4">
                        <video
                          src={shareAssetFile ? shareAssetFile : shareAssetFileURL}
                          alt="Share Asset Video"
                          width="320"
                          height="240"
                          controls
                        />
                      </Col>
                    ) : (
                      <Col></Col>
                    )}
                  </Col>

                    {/* Create an audio upload section + preview */}
                  <Col lg={12} md={12}>
                    <FormGroup>
                      <Label>
                        <strong>
                          Asset Audio File
                        </strong>
                        <ToolTip
                          data="Formats: mp3, wav Size : 2 MB"
                          id="audioFileTooltip"
                        />
                      </Label>
                      <Container className="custom-file">
                        <Input
                          type="file"
                          className="custom-file-input"
                          accept="audio/mp3, audio/wav"
                          name="audioFile"
                          onChange={(event) => {
                            // check file size
                            if (event.target.files[0].size > staticIconSize) {
                              let text = fileSizeExceedError;
                              showErrorModal(text);
                              return;
                            }
                            // save base64 of the audio
                            var reader = new FileReader();
                            reader.readAsDataURL(event.target.files[0]);
                            setAudioFilename(event.target.files[0].name);
                            reader.onload = () => {
                              setAudioFile(reader.result);
                            }
                          }}
                          disabled={!showEditSuggestionDrawerContentButton()}
                        />
                        <Label className="custom-file-label">
                          <strong>{audioFilename || "Choose an audio file"}</strong>
                        </Label>
                      </Container>
                    </FormGroup>
                  </Col>

                  <Col lg={12} md={12}> 
                    {(audioFileURL || audioFile) ? (
                      <Col className="pb-4">
                        <audio
                          src={audioFile ? audioFile : audioFileURL}
                          alt="Audio File"
                          controls
                        />
                      </Col>
                    ) : (
                      <Col></Col>
                    )}
                  </Col>

                  {/* Audio animation File (gif) + preview */}
                  <Col lg={12} md={12}>
                    <FormGroup>
                      <Label>
                        <strong>
                          Audio Animation File
                        </strong>
                        <ToolTip
                          data="Formats: gif Size : 2 MB"
                          id="audioAnimationFileTooltip"
                        />
                      </Label>
                      <Container className="custom-file">
                        <Input
                          type="file"
                          className="custom-file-input"
                          accept="image/gif"
                          name="audioAnimationFile"
                          onChange={(event) => {
                            // check file size
                            if (event.target.files[0].size > staticIconSize) {
                              let text = fileSizeExceedError;
                              showErrorModal(text);
                              return;
                            }
                            // save base64 of the audio animation
                            var reader = new FileReader();
                            reader.readAsDataURL(event.target.files[0]);
                            setAudioAnimationFilename(event.target.files[0].name);
                            reader.onload = () => {
                              setAudioAnimationFile(reader.result);
                            }
                          }}
                          disabled={!showEditSuggestionDrawerContentButton()}
                        />
                        <Label className="custom-file-label">
                          <strong>{audioAnimationFilename || "Choose an audio animation file"}</strong>
                        </Label>
                      </Container>
                    </FormGroup>
                  </Col>
                  <Col lg={12} md={12}>
                    {(audioAnimationFileURL || audioAnimationFile) ? (
                      <Col className="pb-4">
                        <img
                          src={audioAnimationFile ? audioAnimationFile : audioAnimationFileURL}
                          alt="Audio Animation File"
                          width="120"
                        />
                      </Col>
                    ) : (
                      <Col></Col>
                    )}
                  </Col>


                  <Col  md={6}>
                    <FormGroup>
                      <Label>
                        <strong>
                        Custom Watermarked Share Asset 
                        </strong>
                        <ToolTip
                          data="Assets with watermark at custom position, formats: mp4, mkv , png, jpg, jpeg, webp, gif Size : 10 MB"
                          id="watermarkedCustomShareAssetTooltip"
                        />
                      </Label>
                      <Container className="custom-file">
                        <Input
                          type="file"
                          className="custom-file-input"
                          accept="video/mp4, video/mkv image/png, image/jpg, image/jpeg, image/webp, image/gif"
                          name="customWatermarkedShareAsset"
                          onChange={(event) => {
                            // check file size
                            if (event.target.files[0].size > staticIconSize) {
                              let text = fileSizeExceedError;
                              showErrorModal(text);
                              return;
                            }
                            // save base64 of the video 
                            var reader = new FileReader();
                            reader.readAsDataURL(event.target.files[0]);
                            setCustomWatermarkedShareAssetFilename(event.target.files[0].name);
                            reader.onload = () => {
                              setCustomWatermarkedShareAssetFile(reader.result);
                            }
                          }}
                          disabled={!showEditSuggestionDrawerContentButton()}
                        />
                        <Label className="custom-file-label">
                          <strong>{customWatermarkedShareAssetFilename || "Choose a asset"}</strong>
                        </Label>
                      </Container>
                    </FormGroup>
                    {(customWatermarkedShareAssetFileURL || customWatermarkedShareAssetFile) ? (
                      <Col className="pb-4">
                        {
                          isVideoFile(customWatermarkedShareAssetFilename) ? (
                            <video
                              src={customWatermarkedShareAssetFile ? customWatermarkedShareAssetFile : customWatermarkedShareAssetFileURL}
                              alt="Custom Share Asset"
                              width="320"
                              height="240"
                              controls
                            />
                          ) : (
                            <img
                              src={customWatermarkedShareAssetFile ? customWatermarkedShareAssetFile : customWatermarkedShareAssetFileURL}
                              alt="Custom Share Asset"
                              width="320"
                            />
                          )
                        }
                      </Col>
                    ) : (
                      <Col></Col>
                    )}
                  </Col>
                  <Col lg={6} md={6}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "14px" }}
                      >
                        Deeplink Type <span className="text-danger">*</span>
                      </Label>{" "}
                      <ToolTip
                        data={`Custom Value: the deeplink value provided in "Deeplink" field will be used.\n
                        Auto Generate: a deeplink will be generated at backend, which will trigger share action will be generated based on share text, image and share assets.\n
                        No Deeplink: no deeplink will be generated. Hardcoded logic inside android app will be used to complete the post click/share action.`}
                        target="deeplinkType"
                      >
                      </ToolTip>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={deeplinkTypeOptions}
                        placeholder="Select Deeplink Type"
                        value={details.deeplinkType}
                        onChange={(value) => {
                          setDetails((prevState) => ({
                            ...prevState,
                            deeplinkType: value
                          }))
                        }}
                        isDisabled={!showEditSuggestionDrawerContentButton()}
                        required
                      />
                    </FormGroup>
                  </Col>
                  {(details.deeplinkType.label === "No Deeplink") ? null:<Col lg={6} md={6}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "14px" }}
                      >
                        Deeplink
                      </Label>
                      <Input
                        type="textarea"
                        name="deeplink"
                        placeholder="Enter Deeplink"
                        value={details.deeplink}
                        onChange={(event) => {
                          handleDetailsChange(event)
                        }}
                        disabled={!showEditSuggestionDrawerContentButton() || (shareAssetFileURL || shareAssetFile) || (details.deeplinkType.value === "default")}
                      required
                      />
                    </FormGroup>
                  </Col>}
                  <Col lg={6} md={6}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "14px" }}
                      >
                        Share Text
                      </Label>
                      <Input
                        type="textarea"
                        name="shareText"
                        placeholder="Enter Share Text"
                        value={details.shareText}
                        onChange={(event) => {
                          handleDetailsChange(event)
                        }}
                        disabled={!showEditSuggestionDrawerContentButton()}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6} md={6}>
                    <Label>
                      AspectRatio
                    </Label><span className="text-danger">*</span>
                    <ToolTip
                      data="Aspect Ratio of Image"
                      id="aspectRatio"
                    >
                    </ToolTip>
                    <Input
                      type="number"
                      className="pl-4"
                      step={0.00000000001}
                      name="aspectRatio"
                      min={0}
                      placeholder="Page Size"
                      value={details.aspectRatio}
                      onChange={handleDetailsChange}
                      disabled={!showEditSuggestionDrawerContentButton()}
                      required
                    />
                  </Col>
                  <Col lg={6} md={6}>
                    <Label>
                      Min App Version
                    </Label>
                    <ToolTip
                      data="Min App Version"
                      id="minAppVersion"
                    >
                    </ToolTip>
                    <Input
                      type="number"
                      className="pl-4"
                      step={1}
                      name="minAppVersion"
                      min={1}
                      placeholder="Min App version"
                      value={details.minAppVersion}
                      onChange={handleDetailsChange}
                      onKeyPress={(e) => {
                        // Allow only digits and prevent other characters
                        const isValidKey = /^\d+$/.test(e.key);
                        if (!isValidKey) {
                          e.preventDefault();
                        }
                      }}
                      disabled={!showEditSuggestionDrawerContentButton()}
                      required
                    />
                  </Col>
                  <Col lg={6} md={6}>
                    <Label>
                      Max App Version
                    </Label>
                    <ToolTip
                      data="Max App Version"
                      id="maxAppVersion"
                    >
                    </ToolTip>
                    <Input
                      type="number"
                      className="pl-4"
                      step={1}
                      name="maxAppVersion"
                      min={0}
                      placeholder="Max App version"
                      value={details.maxAppVersion}
                      onChange={handleDetailsChange}
                      onKeyPress={(e) => {
                        // Allow only digits and prevent other characters
                        const isValidKey = /^\d+$/.test(e.key);
                        if (!isValidKey) {
                          e.preventDefault();
                        }
                      }}
                      disabled={!showEditSuggestionDrawerContentButton()}
                    />
                  </Col>

                  <Col lg={6} md={6}>
                    <FormGroup>
                      <Label>
                        UTM Campaign
                      </Label>
                      <ToolTip
                        data="UTM Campaign"
                        id="utmCampaign"
                      >
                      </ToolTip>

                      {/* create a clearable select dropdown */}
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={utmCampaignOptions}
                        placeholder="Select UTM Campaign"
                        value={details.utmCampaign}
                        isClearable
                        onChange={(value) => {
                          setDetails((prevState) => ({
                            ...prevState,
                            utmCampaign: value
                          }))
                        }
                        }
                        isDisabled={!showEditSuggestionDrawerContentButton()}
                      />

                      
                    </FormGroup>
                  </Col>

                  {/* New Start DateTime Field */}
                  <Col lg={6} md={6}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "14px" }}
                      >
                        Start Date and Time
                      </Label>
                      <Input
                        type="datetime-local"
                        name="startDateTime"
                        value={details.startDateTime}
                        onChange={handleDetailsChange}
                      />
                    </FormGroup>
                  </Col>
                  {/* New End DateTime Field */}
                  <Col lg={6} md={6}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "14px" }}
                      >
                        End Date and Time
                      </Label>
                      <Input
                        type="datetime-local"
                        name="endDateTime"
                        value={details.endDateTime}
                        onChange={handleDetailsChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mt-3 ">
                    <Col md={10} xs={10}>
                      Watermark Enabled 
                    </Col>
                    <Col lg={2} md={2}>
                      <FormGroup className="d-flex align-items-center">
                        <div className="custom-control custom-switch" style={{ marginRight: 'auto' }}>
                          <CustomInput
                            type="checkbox"
                            id="isWatermarkEnabled"
                            value={details.isWatermarkEnabled}
                            onChange={(event) => {
                              setDetails((prevState) => ({
                                ...prevState,
                                isWatermarkEnabled: !details.isWatermarkEnabled
                              }))
                            }}
                            checked={details.isWatermarkEnabled}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* new styling prop card */}

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <Row form>
                <Col md={10} xs={10}>
                                <h3>Styling Properties</h3>
                              </Col>
                  <Col lg={6} md={6}>
                    <FormGroup>
                      <Label className="font-weight-bolder mb-2" style={{ fontSize: "14px" }}>
                        Left Margin (DP)
                      </Label>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={stylePropertiesOptions}
                        placeholder="Select Left Margin"
                        value={details.styleProperties.leftMarginInDP}
                        onChange={(value) => {
                          setDetails((prevState) => ({
                            ...prevState,
                            styleProperties: {
                              ...prevState.styleProperties,
                              leftMarginInDP: value,
                            },
                          }));
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6}>
                    <FormGroup>
                      <Label className="font-weight-bolder mb-2" style={{ fontSize: "14px" }}>
                        Right Margin (DP)
                      </Label>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={stylePropertiesOptions}
                        placeholder="Select Right Margin"
                        value={details.styleProperties.rightMarginInDP}
                        onChange={(value) => {
                          setDetails((prevState) => ({
                            ...prevState,
                            styleProperties: {
                              ...prevState.styleProperties,
                              rightMarginInDP: value,
                            },
                          }));
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Card>
        <CardBody>
          <h5 className="card-title">Schedule By Time</h5>
          <ToolTip
            data="Add Time Slots When This content will be made available to client, in clients time zone"
            id="sheduleByTime"
          >
          </ToolTip>
          <div className="schedule-by-time">{renderDailyTimeSlots()}</div>
        </CardBody>
      </Card>


        <Row className="mt-3 mb-3">
          <Col lg="12">
            <Row className="mb-3">
              <Col md={10} xs={10}>
                <h3>CTA</h3>
              </Col>
              <Col lg={2} md={2}>
                <FormGroup className="d-flex align-items-center">
                  <div className="custom-control custom-switch" style={{ marginRight: 'auto' }}>
                    <CustomInput
                      type="checkbox"
                      id="enableWatermark"
                      value={details.enableCTA}
                      onChange={(event) => {
                        handleCTAChange(event)
                      }}
                      checked={details.enableCTA}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
            {details.enableCTA ?
              <Card>
                <CardBody>
                  <Row form>
                    <Col lg={6} md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Text
                        </Label>
                        <ToolTip
                          data="CTA text"
                          id="ctaText"
                        >
                        </ToolTip>
                        <Input
                          type="text"
                          id="text"
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="text"
                          placeholder="text"
                          value={cta.text}
                          onChange={handleChange}
                          isDisabled={!showEditSuggestionDrawerContentButton()}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Deeplink
                        </Label>
                        <ToolTip
                          data="Deeplink"
                          id="deeplink"
                        >
                        </ToolTip>
                        <Input
                          type="text"
                          id="ctaDeeplink"
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="deeplink"
                          placeholder="text"
                          value={cta.deeplink}
                          onChange={handleChange}
                          isDisabled={!showEditSuggestionDrawerContentButton()}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} xs={4}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "16px" }}
                        >
                          Text Color (Hex):{" "}
                          <div
                            style={{
                              backgroundColor: details.cta.textColor,
                              width: "16px",
                              height: "16px",
                              display: "inline-block",
                              verticalAlign: "middle",
                              border: "1px solid #000",
                              borderRadius: "30%",
                            }}
                          >
                          </div>
                        </Label>
                        <ToolTip
                          data="CTA text Color"
                          id="ctaTextColor"
                        >
                        </ToolTip>
                        <Input
                          type="text"
                          name="textColor"
                          id="textColor"
                          value={cta.textColor}
                          onChange={handleChange}
                          className="carousel-form-input"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} xs={4}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "16px" }}
                        >
                          Dark Mode Text Color (Hex):{" "}
                          <div
                            style={{
                              backgroundColor: details.cta.darkModeTextColor,
                              width: "16px",
                              height: "16px",
                              display: "inline-block",
                              verticalAlign: "middle",
                              border: "1px solid #000",
                              borderRadius: "30%",
                            }}
                          >
                            {" "}
                          </div>
                        </Label>
                        <ToolTip
                          data="CTA text Color"
                          id="ctaTextColor"
                        >
                        </ToolTip>
                        <Input
                          type="text"
                          name="darkModeTextColor"
                          id="darkModeTextColor"
                          value={cta.darkModeTextColor}
                          onChange={handleChange}
                          className="carousel-form-input"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} xs={4}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "16px" }}
                        >
                          Background Color (Hex):{" "}
                          <div
                            style={{
                              backgroundColor: details.cta.backgroundColor,
                              width: "16px",
                              height: "16px",
                              display: "inline-block",
                              verticalAlign: "middle",
                              border: "1px solid #000",
                              borderRadius: "30%",
                            }}
                          >
                            {" "}
                          </div>
                        </Label>
                        <ToolTip
                          data="CTA text Color"
                          id="ctaTextColor"
                        >
                        </ToolTip>
                        <Input
                          type="text"
                          name="backgroundColor"
                          id="backgroundColor"
                          value={cta.backgroundColor}
                          onChange={handleChange}
                          className="carousel-form-input"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} xs={4}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "16px" }}
                        >
                          Dark Mode Background Color (Hex):{" "}
                          <div
                            style={{
                              backgroundColor: cta.darkModeBackgroundColor,
                              width: "16px",
                              height: "16px",
                              display: "inline-block",
                              verticalAlign: "middle",
                              border: "1px solid #000",
                              borderRadius: "30%",
                            }}
                          >
                            {" "}
                          </div>
                        </Label>
                        <ToolTip
                          data="CTA text Color"
                          id="ctaTextColor"
                        >
                        </ToolTip>
                        <Input
                          type="text"
                          name="darkModeBackgroundColor"
                          id="darkModeBackgroundColor"
                          value={cta.darkModeBackgroundColor}
                          onChange={handleChange}
                          className="carousel-form-input"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              : null}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg="12">
            <Row className="mb-3">
              <Col md={6} xs={6}>
                <h3>Card Metadata</h3>
              </Col>
            </Row>
            <Card>
              <CardBody>
                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        <strong>
                          Title
                        </strong>
                      </Label>
                      <Input
                        type="text"
                        name="title"
                        placeholder="Enter Title"
                        disabled={!showEditSuggestionDrawerContentButton()}
                        value={details.title}
                        onChange={handleDetailsChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        <strong>
                          Light Theme Title Text Color{" "}<span className="text-warning text-sm">(Enter Hex Code starting with #)</span>
                          {validateColor(details.titleTextColorLight) ? (
                            <div
                              style={{
                                backgroundColor:
                                  details.titleTextColorLight,
                                width: "16px",
                                height: "16px",
                                display: "inline-block",
                                verticalAlign: "middle",
                                border: "1px solid #000",
                                borderRadius: "30%",
                              }}
                            >
                              {" "}
                            </div>
                          ) : (
                            <small className="text-danger ml-2">
                              {"Invalid Hex Code"}
                            </small>
                          )}

                        </strong>
                      </Label>
                      <Input
                        type="text"
                        name="titleTextColorLight"
                        className={
                          validateColor(details.titleTextColorLight)
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        placeholder="Enter Text Color"
                        disabled={!showEditSuggestionDrawerContentButton()}
                        value={
                          details.titleTextColorLight === null ? null : details.titleTextColorLight
                        }
                        onChange={handleDetailsChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        <strong>
                          Dark Theme Title Text Color{" "}<span className="text-warning text-sm">(Enter Hex Code starting with #)</span>
                          {validateColor(details.titleTextColorDark) ? (
                            <div
                              style={{
                                backgroundColor:
                                  details.titleTextColorDark,
                                width: "16px",
                                height: "16px",
                                display: "inline-block",
                                verticalAlign: "middle",
                                border: "1px solid #000",
                                borderRadius: "30%",
                              }}
                            >
                              {" "}
                            </div>
                          ) : (
                            <small className="text-danger ml-2">
                              {"Invalid Hex Code"}
                            </small>
                          )}

                        </strong>
                      </Label>
                      <Input
                        type="text"
                        name="titleTextColorDark"
                        className={
                          validateColor(details.titleTextColorDark)
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        placeholder="Enter Title Text Color Dark"
                        disabled={!showEditSuggestionDrawerContentButton()}
                        value={
                          details.titleTextColorDark === null ? null : details.titleTextColorDark
                        }
                        onChange={handleDetailsChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        <strong>
                          Body
                        </strong>
                      </Label>
                      <Input
                        type="text"
                        name="body"
                        placeholder="Enter Body"
                        disabled={!showEditSuggestionDrawerContentButton()}
                        value={details.body}
                        onChange={handleDetailsChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        <strong>
                          Light Theme Body Text Color{" "}<span className="text-warning text-sm">(Enter Hex Code starting with #)</span>
                          {validateColor(details.bodyTextColorLight) ? (
                            <div
                              style={{
                                backgroundColor:
                                  details.bodyTextColorLight,
                                width: "16px",
                                height: "16px",
                                display: "inline-block",
                                verticalAlign: "middle",
                                border: "1px solid #000",
                                borderRadius: "30%",
                              }}
                            >
                              {" "}
                            </div>
                          ) : (
                            <small className="text-danger ml-2">
                              {"Invalid Hex Code"}
                            </small>
                          )}

                        </strong>
                      </Label>
                      <Input
                        type="text"
                        name="bodyTextColorLight"
                        className={
                          validateColor(details.bodyTextColorLight)
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        placeholder="Enter Text Color"
                        disabled={!showEditSuggestionDrawerContentButton()}
                        value={
                          details.bodyTextColorLight === null ? null : details.bodyTextColorLight
                        }
                        onChange={handleDetailsChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        <strong>
                          Dark Theme Body Text Color{" "}<span className="text-warning text-sm">(Enter Hex Code starting with #)</span>
                          {validateColor(details.bodyTextColorDark) ? (
                            <div
                              style={{
                                backgroundColor:
                                  details.bodyTextColorDark,
                                width: "16px",
                                height: "16px",
                                display: "inline-block",
                                verticalAlign: "middle",
                                border: "1px solid #000",
                                borderRadius: "30%",
                              }}
                            >
                              {" "}
                            </div>
                          ) : (
                            <small className="text-danger ml-2">
                              {"Invalid Hex Code"}
                            </small>
                          )}

                        </strong>
                      </Label>
                      <Input
                        type="text"
                        name="bodyTextColorDark"
                        className={
                          validateColor(details.bodyTextColorDark)
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        placeholder="Enter Text Color"
                        disabled={!showEditSuggestionDrawerContentButton()}
                        value={
                          details.bodyTextColorDark === null ? null : details.bodyTextColorDark
                        }
                        onChange={handleDetailsChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        <strong>
                          Light Theme Background Color{" "}<span className="text-warning text-sm">(Enter Hex Code starting with #)</span>
                          {validateColor(details.backgroundColorLight) ? (
                            <div
                              style={{
                                backgroundColor:
                                  details.backgroundColorLight,
                                width: "16px",
                                height: "16px",
                                display: "inline-block",
                                verticalAlign: "middle",
                                border: "1px solid #000",
                                borderRadius: "30%",
                              }}
                            >
                              {" "}
                            </div>
                          ) : (
                            <small className="text-danger ml-2">
                              {"Invalid Hex Code"}
                            </small>
                          )}

                        </strong>
                      </Label>
                      <Input
                        type="text"
                        name="backgroundColorLight"
                        className={
                          validateColor(details.backgroundColorLight)
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        placeholder="Enter Background Color"
                        disabled={!showEditSuggestionDrawerContentButton()}
                        value={
                          details.backgroundColorLight === null ? null : details.backgroundColorLight
                        }
                        onChange={handleDetailsChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        <strong>
                          Dark Theme Background Color{" "}<span className="text-warning text-sm">(Enter Hex Code starting with #)</span>
                          {validateColor(details.backgroundColorDark) ? (
                            <div
                              style={{
                                backgroundColor:
                                  details.backgroundColorDark,
                                width: "16px",
                                height: "16px",
                                display: "inline-block",
                                verticalAlign: "middle",
                                border: "1px solid #000",
                                borderRadius: "30%",
                              }}
                            >
                              {" "}
                            </div>
                          ) : (
                            <small className="text-danger ml-2">
                              {"Invalid Hex Code"}
                            </small>
                          )}

                        </strong>
                      </Label>
                      <Input
                        type="text"
                        name="backgroundColorDark"
                        className={
                          validateColor(details.backgroundColorDark)
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        placeholder="Enter Background Color"
                        disabled={!showEditSuggestionDrawerContentButton()}
                        value={
                          details.backgroundColorDark === null ? null : details.backgroundColorDark
                        }
                        onChange={handleDetailsChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        <strong>
                          Subtext
                        </strong>
                      </Label>
                      <Input
                        type="text"
                        name="subtext"
                        placeholder="Enter Subtext"
                        disabled={!showEditSuggestionDrawerContentButton()}
                        value={details.subtext}
                        onChange={handleDetailsChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        <strong>
                          Light Theme Sub Text Color{" "}<span className="text-warning text-sm">(Enter Hex Code starting with #)</span>
                          {validateColor(details.subTextColorLight) ? (
                            <div
                              style={{
                                backgroundColor:
                                  details.subTextColorLight,
                                width: "16px",
                                height: "16px",
                                display: "inline-block",
                                verticalAlign: "middle",
                                border: "1px solid #000",
                                borderRadius: "30%",
                              }}
                            >
                              {" "}
                            </div>
                          ) : (
                            <small className="text-danger ml-2">
                              {"Invalid Hex Code"}
                            </small>
                          )}

                        </strong>
                      </Label>
                      <Input
                        type="text"
                        name="subTextColorLight"
                        className={
                          validateColor(details.subTextColorLight)
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        placeholder="Enter Text Color"
                        disabled={!showEditSuggestionDrawerContentButton()}
                        value={
                          details.subTextColorLight === null ? null : details.subTextColorLight
                        }
                        onChange={handleDetailsChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        <strong>
                          Dark Theme Sub Text Color{" "}<span className="text-warning text-sm">(Enter Hex Code starting with #)</span>
                          {validateColor(details.subTextColorDark) ? (
                            <div
                              style={{
                                backgroundColor:
                                  details.subTextColorDark,
                                width: "16px",
                                height: "16px",
                                display: "inline-block",
                                verticalAlign: "middle",
                                border: "1px solid #000",
                                borderRadius: "30%",
                              }}
                            >
                              {" "}
                            </div>
                          ) : (
                            <small className="text-danger ml-2">
                              {"Invalid Hex Code"}
                            </small>
                          )}

                        </strong>
                      </Label>
                      <Input
                        type="text"
                        name="subTextColorDark"
                        className={
                          validateColor(details.subTextColorDark)
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        placeholder="Enter Text Color"
                        disabled={!showEditSuggestionDrawerContentButton()}
                        value={
                          details.subTextColorDark === null ? null : details.subTextColorDark
                        }
                        onChange={handleDetailsChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        <strong>
                          Card Icon Asset Width Percentage{" "}<span className="text-warning text-sm">(Enter Percentage)</span>
                        </strong>
                      </Label>
                      <Input
                        type="number"
                        name="assetWidthPercentage"
                        min={0}
                        max={1}
                        step={0.01}
                        placeholder="Enter Asset Width Percentage"
                        value={details.assetWidthPercentage}
                        onChange={handleDetailsChange}
                        disabled={!showEditSuggestionDrawerContentButton()}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        <strong>
                          Card Icon Without Image Aspect Ratio
                        </strong>
                      </Label>
                      <Input
                        type="number"
                        name="withoutImageAspectRatio"
                        step={0.01}
                        placeholder="Enter Without Image Aspect Ratio"
                        value={details.withoutImageAspectRatio}
                        onChange={handleDetailsChange}
                        disabled={!showEditSuggestionDrawerContentButton()}
                      />
                    </FormGroup>
                  </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          <strong>
                          Button Deeplink 
                          </strong>
                        </Label>{" "}
                        <ToolTip
                          data="Button Deeplink for Memechat Content. If not provided, card deeplink will be used in client side."
                          id="buttonDeeplink"
                        >
                        </ToolTip>
                        <Input
                          type="text"
                          name="buttonDeeplink"
                          placeholder="Enter Button Deeplink"
                          value={details.buttonDeeplink}
                          onChange={handleDetailsChange}
                          disabled={!showEditSuggestionDrawerContentButton()}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          <strong>
                            Light Theme Button Image File 
                            {/* {audioAnimationFile || audioAnimationFileURL ? (<span className="text-danger">*</span>) : null} */}
                          </strong>
                        </Label>
                        <Container className="custom-file">
                          <Input
                            type="file"
                            className="custom-file-input"
                            accept="image/png, image/jpg, image/jpeg, image/webp"
                            name="buttonImageLight"
                            onChange={(event) => {
                              // check file size
                              if (event.target.files[0].size > staticIconSize) {
                                let text = fileSizeExceedError;
                                showErrorModal(text);
                                return;
                              }
                              // save base64 of the audio
                              var reader = new FileReader();
                              reader.readAsDataURL(event.target.files[0]);
                              setLightThemeButtonImageFilename(event.target.files[0].name);
                              reader.onload = () => {
                                setLightThemeButtonImageFile(reader.result);
                              }
                            }}
                            // required={audioAnimationFile || audioAnimationFileURL}
                            disabled={!showEditSuggestionDrawerContentButton()}
                          />
                          <Label className="custom-file-label">
                            <strong>{lightThemeButtonImageFilename || "Choose a button image"}</strong>
                          </Label>
                        </Container>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          <strong>
                            Dark Theme Button Image File 
                            {/* {audioAnimationFile || audioAnimationFileURL ? (<span className="text-danger">*</span>) : null} */}
                          </strong>
                        </Label>
                        <Container className="custom-file">
                          <Input
                            type="file"
                            className="custom-file-input"
                            accept="image/png, image/jpg, image/jpeg, image/webp"
                            name="buttonImageDark"
                            onChange={(event) => {
                              // check file size
                              if (event.target.files[0].size > staticIconSize) {
                                let text = fileSizeExceedError;
                                showErrorModal(text);
                                return;
                              }
                              // save base64 of the audio
                              var reader = new FileReader();
                              reader.readAsDataURL(event.target.files[0]);
                              setDarkThemeButtonImageFilename(event.target.files[0].name);
                              reader.onload = () => {
                                setDarkThemeButtonImageFile(reader.result);
                              }
                            }}
                            // required={audioAnimationFile || audioAnimationFileURL}
                            disabled={!showEditSuggestionDrawerContentButton()}
                          />
                          <Label className="custom-file-label">
                            <strong>{darkThemeButtonImageFilename || "Choose a button image"}</strong>
                          </Label>
                        </Container>
                      </FormGroup>
                    </Col>
                    {/* show image preview */}
                    <Col md={6}>
                    {(lightThemeButtonImageURL || lightThemeButtonImageFile) ? (
                      <Col className="pb-4">
                        <img
                          src={lightThemeButtonImageFile ? lightThemeButtonImageFile : lightThemeButtonImageURL}
                          alt=""
                          width="120"
                        />
                      </Col>
                    ) : (
                      <Col></Col>
                    )}
                    </Col>
                    <Col md={6}>
                    {(darkThemeButtonImageURL || darkThemeButtonImageFile) ? (
                      <Col className="pb-4">
                        <img
                          src={darkThemeButtonImageFile ? darkThemeButtonImageFile : darkThemeButtonImageURL}
                          alt=""
                          width="120"
                        />
                      </Col>
                    ) : (
                      <Col></Col>
                    )}
                    </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Card>
              <Row className="mb-3">
                <Col md={6} xs={6}>
                  <h3>Tags</h3><span className="text-danger">*</span>
                </Col>
                <Col md={12}>
                  <FormGroup>
                  <TagsInput
                  value={tags}
                  onChange={handleTagsChange}
                  disabled={!showEditSuggestionDrawerContentButton()}
                />
                  </FormGroup>
                </Col>

              </Row>
              <Row className="mb-3">
                <Col md={10} xs={10}>
                  <h4>Show Content On Exact Tag Match</h4>
                </Col>
                <Col lg={2} md={2}>
                  <FormGroup className="d-flex align-items-center">
                    <div className="custom-control custom-switch" style={{ marginRight: 'auto' }}>
                      <CustomInput
                        type="checkbox"
                        id="enableExactTagMatch"
                        value={details.enableExactTagMatch}
                        onChange={(event) => {
                          setDetails((prevState) => ({
                            ...prevState,
                            enableExactTagMatch: !details.enableExactTagMatch
                          }))
                        }}
                        checked={details.enableExactTagMatch}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Row className="mb-3">
              <Col md={6} xs={6}>
                <h3>Trackers</h3>
              </Col>
              {showEditSuggestionDrawerContentButton() && (
                <Col md={6} xs={6}>
                  <div className="p-0">
                    <Button
                      color="success"
                      className="ml-auto d-flex btn-success"
                      onClick={() => {
                        setTrackers(
                          trackers.concat({
                            type: "",
                            url: "",
                          })
                        )
                        setTrackerUrlMacroError(trackerUrlMacroError.concat(""))
                      }}
                    >
                      <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                        Add New Tracker
                      </p>
                    </Button>
                  </div>
                </Col>
              )}
            </Row>
            <Card>
              <CardBody>
                {trackers?.map((item, index) => {
                  return (
                    <Row key={index} form>
                      <Col md={6}>
                        <FormGroup>
                          <Label
                            className="font-weight-bolder mb-2"
                            style={{ fontSize: "14px" }}
                          >
                            Tracker Type
                          </Label>
                          <Select
                            id="type"
                            className="react-select-container"
                            classNamePrefix="react-select"
                            name="type"
                            placeholder="Select Tracker Type"
                            value={item.type}
                            onChange={(event) =>
                              handleTrackerChange(event, index)
                            }
                            options={trackerTypeOptions}
                            isDisabled={!showEditSuggestionDrawerContentButton()}
                          />
                        </FormGroup>
                      </Col>
                      {showEditSuggestionDrawerContentButton() && (
                        <Col md={6}>
                          <div className="p-0" style={{ marginRight: "20px" }}>
                            <X
                              className="d-none d-lg-block ml-auto d-flex"
                              size={iconSize}
                              color="red"
                              style={{
                                cursor: "pointer",
                                border: "2px solid red",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                setTrackers((prevState) =>
                                  prevState.filter((_, i) => i !== index)
                                );
                                setTrackerUrlMacroError((prevState) =>
                                  prevState.filter((_, i) => i !== index)
                                );
                              }}
                            ></X>
                          </div>
                        </Col>
                      )}
                      <Col md={12}>
                        <FormGroup>
                          <Label
                            className="font-weight-bolder mb-2"
                            style={{ fontSize: "14px" }}
                          >
                            URL
                          </Label>
                          <Input
                            type="textarea"
                            name="url"
                            placeholder="Enter URL"
                            value={item.url}
                            onChange={(event) => {
                              handleTrackerChange(event, index)
                              const msg = validateMacros(event.target?.value)
                              let temp = [...trackerUrlMacroError];
                              temp[index] = msg;
                              setTrackerUrlMacroError(temp);
                            }}
                            disabled={!showEditSuggestionDrawerContentButton()}
                          />
                          {trackerUrlMacroError[index] ? <small className="text-danger font-weight-bold">{trackerUrlMacroError[index]}</small> : null}
                        </FormGroup>
                      </Col>
                      <hr
                        style={{
                          width: "75rem",
                          color: "black",
                        }}
                      />
                    </Row>
                  );
                })}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label
                className="font-weight-bolder mb-2"
                style={{ fontSize: "14px" }}
              >
                Mapped Categories
              </Label><span className="text-danger">*</span>
              <ToolTip
                data="All the categories that can map"
                id="contentTypes"
              >
              </ToolTip>
              <Select
                id="mappingCategories"
                isMulti
                className="react-select-container"
                classNamePrefix="react-select"
                name="mappingCategories"
                placeholder="Select Content Type"
                value={details.mappingCategories}
                onChange={(event) => {
                  setDetails((prevState) => ({
                    ...prevState,
                    mappingCategories: event,
                  })
                  )
                }}
                options={categories}
                isDisabled={!showEditSuggestionDrawerContentButton()}
                required
              />
            </FormGroup>
          </Col>
        </Row>
        {showEditSuggestionDrawerContentButton() && (
          <Button className="d-block ml-3 mt-2" color="primary">
            {(props.match.params.id) ? "Submit" : "Save"}
          </Button>
        )}
      </Form>
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={"submit"}
        onConfirmationTrue={() => {
          setConfirmationModalIsOpen(false);
          handleSubmit();
        }}
        onHide={() => setConfirmationModalIsOpen(false)}
      />
      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={"Success"}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={"Error"}
        modaltext={failureModalText}
      />
    </Container>
  );
};

export default ManageSuggestionDrawerContent;

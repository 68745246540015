import React, { useState, useEffect } from "react";
import {
	Collapse,
	Container,
	Row,
	Col,
	Card,
	CardBody,
	CardHeader,
	Form,
	FormGroup,
	Input,
	Label,
} from "reactstrap";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import Select from "react-select";
import ResponseModal from "./ResponseModal";
import { gendersList } from "../../config/index";
import AgeGenderRuleSummary from "./AgeGenderRuleSummary";

const AgeGenderRules = (props) => {
	const handleAgeChange = props.handleAgeChange;
	const handleGenderChange = props.handleGenderChange;
	const handleAgeAndGenderToggleChange = props.handleAgeAndGenderToggleChange;
	const showHardFiltering = props.showHardFiltering;

	const minAge = props.minAge;
	const maxAge = props.maxAge;
	const gender = props.gender;
	const ageHardFiltering = props.ageHardFiltering;
	const genderHardFiltering = props.genderHardFiltering;

	const [successModal, setSuccessModal] = useState({ text: "", isOpen: false });
	const [failureModal, setFailureModal] = useState({ text: "", isOpen: false });
	const [toggle, setToggle] = useState(false);

	let hideAlert = (elementId) => {
		document.getElementById(elementId).classList.add("d-none");
	};

	return (
		<Card>
			<CardHeader
				onClick={() => setToggle(!toggle)}
				className="pt-3 pb-0"
			>
				<div className="row">
					<Col md={8}>
						<h4>
							<strong>Age & Gender Rules</strong>
						</h4>
					</Col>
					<Col md={4}>
						<div className="d-flex justify-content-end">
							{toggle ? <FaChevronUp /> : <FaChevronDown />}
						</div>
					</Col>
				</div>
			</CardHeader>
			<Collapse isOpen={!toggle}>
				<CardBody md={12} className="pb-3 pt-1">
					<AgeGenderRuleSummary
						minAge={minAge}
						maxAge={maxAge}
						gender={gender}
						ageHardFiltering={ageHardFiltering}
						genderHardFiltering={genderHardFiltering}
						ageGenderHardFiltering={
							ageHardFiltering && genderHardFiltering
						}

					/>
				</CardBody>
			</Collapse>
			<Collapse isOpen={toggle}>
				<CardBody>
					<Form>
						<Row>
							<Col sm={4}>
								<FormGroup>
									<Label
										className="font-weight-bolder mb-2"
										style={{ fontSize: "16px" }}
									>
										MinAge{" "}
										<small
											id="invalidMinAgeAlert"
											className="text-danger d-none"
										>
											(Should be greater or equal to 0 and less than Max
											Age)
										</small>
									</Label>
									<Input
										type="number"
										name="minAge"
										onChange={handleAgeChange}
										placeholder="Enter MinAge"
										value={minAge}
										min={0}
										onFocus={() => hideAlert("invalidMinAgeAlert")}
										disabled={!props.updateAllowed}
									/>
								</FormGroup>
							</Col>
							<Col sm={4}>
								<FormGroup>
									<Label
										className="font-weight-bolder mb-2"
										style={{ fontSize: "16px" }}
									>
										MaxAge
										<small className="text-info">
											{" "}
											(Blank value for NO MaxAge)
										</small>
									</Label>
									<Input
										type="number"
										name="maxAge"
										onChange={handleAgeChange}
										placeholder="Enter MaxAge"
										value={maxAge}
										min={1}
										disabled={!props.updateAllowed}
									/>
								</FormGroup>
							</Col>
							<Col className="pb-2" sm={3}>
								<Label
									className="font-weight-bolder mb-2"
									style={{ fontSize: "16px" }}
								>
									Select Gender:{" "}
								</Label>
								<Select
									id="gender"
									className="react-select-container"
									classNamePrefix="react-select"
									name="gender"
									onChange={(select) => handleGenderChange(select)}
									value={gender}
									options={gendersList}
									isDisabled={!props.updateAllowed}
								/>
							</Col>
						</Row>
						{showHardFiltering ? (<>
							<Row className="pt-2">
								<Col lg={4} className="pb-2">
									{" "}
									<div
										className="custom-switch custom-control mb-3"
										style={{ zIndex: 0 }}
									>
										<input
											type="checkbox"
											id="ageGenderFilterSwitch"
											name="ageGenderHardFiltering"
											className="custom-control-input"
											checked={ageHardFiltering && genderHardFiltering}
											onChange={handleAgeAndGenderToggleChange}
											disabled={!props.updateAllowed}
										/>
										<label
											className="custom-control-label"
											htmlFor="ageGenderFilterSwitch"
										>
											Age & Gender Hard Filtering
										</label>
									</div>
								</Col>
								<Col lg={4} className="pb-2">
									<div
										className="custom-switch custom-control mb-3"
										style={{ zIndex: 0 }}
									>
										<input
											type="checkbox"
											id="ageFilterSwitch"
											name="ageHardFiltering"
											className="custom-control-input"
											checked={ageHardFiltering}
											onChange={handleAgeAndGenderToggleChange}
											disabled={!props.updateAllowed}
										/>
										<label
											className="custom-control-label"
											htmlFor="ageFilterSwitch"
										>
											Age Hard Filtering
										</label>
									</div>
								</Col>
								<Col lg={3} className="pb-2">
									<div
										className="custom-switch custom-control mb-3"
										style={{ zIndex: 0 }}
									>
										<input
											type="checkbox"
											id="genderFilterSwitch"
											name="genderHardFiltering"
											className="custom-control-input"
											checked={genderHardFiltering}
											onChange={handleAgeAndGenderToggleChange}
											disabled={!props.updateAllowed}
										/>
										<label
											className="custom-control-label"
											htmlFor="genderFilterSwitch"
										>
											Gender Hard Filtering
										</label>
									</div>
								</Col>
							</Row></>) : null}
					</Form>
					<ResponseModal
						show={successModal.isOpen}
						onHide={() => setSuccessModal({ isOpen: false })}
						modalheading={"Success"}
						modaltext={successModal.text}
					/>
					<ResponseModal
						show={failureModal.isOpen}
						onHide={() => setFailureModal({ isOpen: false })}
						modalheading={"Error"}
						modaltext={failureModal.text}
					/>
				</CardBody>
			</Collapse>
		</Card>
	);
};

export default AgeGenderRules;

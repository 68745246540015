export let validateCreateKeyboardLanguageLayoutForm = (
    autoSelect,
    characterIdentifier,
    type,
    languageLayoutId
) => {
    console.log(autoSelect);
    if (autoSelect === "") {
        return "The auto select field is required.";
    } else if (
        characterIdentifier != undefined &&
        characterIdentifier.length > 2
    ) {
        return "The Character Identifier field cannot consits of more than 2 characters.";
    } else if (type == "") {
        return "The type field is required.";
    } else if (languageLayoutId == "") {
        return "Language layout id field is required.";
    }
    return true;
};

export let formatJSON = (value) => {
    let res;
    try {
        res = JSON.parse(value.toString());
        if (res && typeof res === "object") {
            res = JSON.stringify(res, null, 4);
        }
    } catch (e) {
        res = value;
    }

    return res;
};
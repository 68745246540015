import React, { Component } from "react";

class Loading extends Component {
  render() {
    return (
      <React.Fragment>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="loader"
          width="200px"
          height="200px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <g transform="translate(50,50)">
            <circle
              cx="0"
              cy="0"
              r="5.555555555555555"
              fill="none"
              stroke="#e6261f"
              strokeWidth="4"
              strokeDasharray="17.453292519943293 17.453292519943293"
              transform="rotate(15.7526)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                values="0 0 0;360 0 0"
                times="0;1"
                dur="1s"
                calcMode="spline"
                keySplines="0.2 0 0.8 1"
                begin="0"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
            <circle
              cx="0"
              cy="0"
              r="11.11111111111111"
              fill="none"
              stroke="#eb7532"
              strokeWidth="4"
              strokeDasharray="34.906585039886586 34.906585039886586"
              transform="rotate(58.4657)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                values="0 0 0;360 0 0"
                times="0;1"
                dur="1s"
                calcMode="spline"
                keySplines="0.2 0 0.8 1"
                begin="-0.125"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
            <circle
              cx="0"
              cy="0"
              r="16.666666666666668"
              fill="none"
              stroke="#f7d038"
              strokeWidth="4"
              strokeDasharray="52.35987755982989 52.35987755982989"
              transform="rotate(110.281)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                values="0 0 0;360 0 0"
                times="0;1"
                dur="1s"
                calcMode="spline"
                keySplines="0.2 0 0.8 1"
                begin="-0.25"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
            <circle
              cx="0"
              cy="0"
              r="22.22222222222222"
              fill="none"
              stroke="#a3e048"
              strokeWidth="4"
              strokeDasharray="69.81317007977317 69.81317007977317"
              transform="rotate(165.706)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                values="0 0 0;360 0 0"
                times="0;1"
                dur="1s"
                calcMode="spline"
                keySplines="0.2 0 0.8 1"
                begin="-0.375"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
            <circle
              cx="0"
              cy="0"
              r="27.77777777777778"
              fill="none"
              stroke="#49da9a"
              strokeWidth="4"
              strokeDasharray="87.26646259971648 87.26646259971648"
              transform="rotate(221.779)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                values="0 0 0;360 0 0"
                times="0;1"
                dur="1s"
                calcMode="spline"
                keySplines="0.2 0 0.8 1"
                begin="-0.5"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
            <circle
              cx="0"
              cy="0"
              r="33.333333333333336"
              fill="none"
              stroke="#34bbe6"
              strokeWidth="4"
              strokeDasharray="104.71975511965978 104.71975511965978"
              transform="rotate(275.877)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                values="0 0 0;360 0 0"
                times="0;1"
                dur="1s"
                calcMode="spline"
                keySplines="0.2 0 0.8 1"
                begin="-0.625"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
            <circle
              cx="0"
              cy="0"
              r="38.888888888888886"
              fill="none"
              stroke="#4355db"
              strokeWidth="4"
              strokeDasharray="122.17304763960306 122.17304763960306"
              transform="rotate(324.244)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                values="0 0 0;360 0 0"
                times="0;1"
                dur="1s"
                calcMode="spline"
                keySplines="0.2 0 0.8 1"
                begin="-0.75"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
            <circle
              cx="0"
              cy="0"
              r="44.44444444444444"
              fill="none"
              stroke="#d23be7"
              strokeWidth="4"
              strokeDasharray="139.62634015954634 139.62634015954634"
              transform="rotate(357.549)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                values="0 0 0;360 0 0"
                times="0;1"
                dur="1s"
                calcMode="spline"
                keySplines="0.2 0 0.8 1"
                begin="-0.875"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </g>
        </svg>
      </React.Fragment>
    );
  }
}

export default Loading;

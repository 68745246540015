import React, { useState } from "react";
import ThemeFormContainer from "./ThemeFormContainer.js";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { permissions } from "../../../utilities/commonUtil";

const CreateTheme = (props) => {
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  const createAllowed = () => {
    return allowedPermissions.includes(permissions.keyboardThemeCreate);
  };

  return (
    <ThemeFormContainer
      {...props}
      formName={"Create Theme"}
      updateAllowed={createAllowed()}
    />
  );
};

export default CreateTheme;

import React, { useState, useEffect } from "react";
import { Card, CardBody, Container, Label } from "reactstrap";
import { Button, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  Row,
  Col,
} from "reactstrap";
import {
  errorDescription,
  allActions,
  error,
} from "../../../utilities/commonUtil";
import ResponseModal from "../ResponseModal";
import { SuggestionDrawerCategoryColumns } from "./tableColumn";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ConfirmationModal from "../ConfirmationModal";
import { formatTime, showActivateSuggestionDrawerCategoriesButton, showDeactivateSuggestionDrawerContentButton, showCreateSuggestionDrawerCategoriesButton, showDeactivateSuggestionDrawerCategoriesButton, showEditSuggestionDrawerCategoriesButton, showPublishSuggestionDrawerCategoriesButton, showSuggestionDrawerContentButton, showEditSuggestionDrawerCategoryTargetingRuleButton } from "../../../config/configSuggestionDrawer";
import { failureModalHeading, successModalHeading } from "../../../config";
import { targetingRulesNotSetError } from "../../../config/configSuggestionDrawer";
import { fetchSuggestionDrawerCategoriesData } from "../../../utilities/apiUtils/suggestionDrawer";
import { activateSuggestionDrawerCategory, deactivateSuggestionDrawerCategory, publishSuggestionDrawerCategory } from "../../../utilities/apiUtils/suggestionDrawer";
import { showSuggestionDrawerCategoryTargetingRuleButton, showSuggestionDrawerCategoriesButton, showSugges, showSuggestionDrawerCategoryContentOrder, showSuggestionDrawerCategoryOrder } from "../../../config/configSuggestionDrawer";
const { SearchBar } = Search;

const ExpandableRowsTable = () => {
  const [suggestionDrawerCategoryData, setCategoryData] = useState([]);
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [action, setAction] = useState("");
  const [id, setId] = useState(null);
  const [status, setStatus] = useState({ value: "activated", label: "Activated" });
  const [data, setData] = useState({
    name: '',
  })


  const fetchCategoryData = async () => {
    let params = {
      status: status.value,
    }
    try {
      let response = await fetchSuggestionDrawerCategoriesData(params)
      if (!response || response?.length === 0) {
        setCategoryData([]);
        return;
      }
      if (response.categories.length > 0) {
        setCategoryData(response.categories);
      } else {
        this.showErrorModal(errorDescription(response.errorCode));
      }
    } catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  }

  useEffect(() => {
    fetchCategoryData();
  }, [status]);

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    await fetchCategoryData();
  };

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let disableDeactivateButton = (row) => {
    if (new Date(row.deactivatedAt) > new Date() || row.deactivatedAt === null) return false;
    return true;
  };

  let disableActivateButton = (row) => {
    if (new Date(row.deactivatedAt) > new Date() || row.deactivatedAt === null) return true;
    return false;
  };
  let disablePublishButton = (row) => {
    if (new Date(row.publishedAt) > new Date() || row.publishededAt === null) return true;
    return false;
  };
  let deactivate = async (id) => {
    try {
      let response = await deactivateSuggestionDrawerCategory(id);
      if (response.status === "deactivated") {
        let text = "category has been Deactivated";
        showSuccessModal(text);
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  };

  let activate = async (id) => {
    try {
      let response = await activateSuggestionDrawerCategory(id);
      if (response.status === "activated") {
        let text = "category has been Activated";
        showSuccessModal(text);
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  };

  let publish = async (id) => {
    try {
      let response = await publishSuggestionDrawerCategory(id);
      if (response.status === "published") {
        let text = "Category has been Published";
        showSuccessModal(text);
        window.location.reload();
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  };


  let setConfirmationModalState = (event, row) => {
    setAction(event);
    setConfirmationModalIsOpen(true);
    setId(row.id)
  };

  let handleStatusChange = (status) => {
    setStatus({ value: status.value, label: status.label });
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <ul>
          <li>{`ID : ${row.id ?? "N/A"}`}</li>
          <li>{`Deactivated At : ${formatTime(row.deactivatedAt)}`}</li>
          <li>{`Published At : ${formatTime(row.publishedAt)}`}</li>
        </ul>
        {showSuggestionDrawerCategoriesButton() ? (
          <Link to={{
            pathname: `/suggestionDrawer/category/edit-category/${row.id}`,
            state: {
              formType: "edit",
            },
          }}>
            <Button className="mx-3">
              Edit
            </Button>
          </Link>
        ) : null}
        {(row.deactivatedAt == null && showDeactivateSuggestionDrawerContentButton()) ? (
          <Button
            className="mx-3 btn-danger"
            disabled={disableDeactivateButton(row)}
            onClick={(event) =>
              setConfirmationModalState(event.target.innerHTML, row)
            }
          >
            Deactivate
          </Button>
        ) : null}
        {(row.deactivatedAt != null && showActivateSuggestionDrawerCategoriesButton()) ? (
          <Button
            className="mx-3 btn-success"
            disabled={disableActivateButton(row)}
            onClick={(event) =>
              setConfirmationModalState(event.target.innerHTML, row)
            }
          >
            Activate
          </Button>
        ) : null}
        {(row.publishedAt == null && showPublishSuggestionDrawerCategoriesButton()) ? (
          <Button
            className="mx-3 btn-success"
            disabled={disablePublishButton(row)}
            onClick={(event) =>
              setConfirmationModalState(event.target.innerHTML, row)
            }
          >
            Publish
          </Button>
        ) : null
        }
        {showSuggestionDrawerCategoryTargetingRuleButton() ? (
          <Link to={{
            pathname: `/suggestionDrawer/category/targeting-rules/${row.id}`,
            state: {
              formType: "edit",
            },
          }}>
            <Button className="mx-3">
              Edit Targeting Rules
            </Button>
          </Link>
        ) : null}
        {showSuggestionDrawerCategoryContentOrder() ? (
          <Link to={{
            pathname: `/suggestionDrawer/category/${row.id}/content/order`,
            state: {
              formType: "edit",
            },
          }}>
            <Button className="mx-3">
              Content Order
            </Button>
          </Link>
        ) : null}
        {showEditSuggestionDrawerCategoryTargetingRuleButton() ? (
          <Link to={{
            pathname: `/suggestionDrawer/category/user-attribute-rules/${row.id}`,
            state: {
              formType: "edit",
            },
          }}>
            <Button className="mx-3">
              Edit User Attribute Rules
            </Button>
          </Link>
        ) : null}
        <ConfirmationModal
          show={confirmationModalIsOpen}
          modaltext={action}
          onConfirmationTrue={() => {
            setConfirmationModalIsOpen(false);
            if (action === allActions.deactivate) deactivate(id);
            else if (action === allActions.activate) activate(id);
            else if (action === allActions.publish) {
              publish(id)
            }
          }}
          onHide={() => setConfirmationModalIsOpen(false)}
        />
      </div >

    ),
  };

  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={3} sm={12}>
            <Label>Status</Label>
            <Select
              className="react-select-container mb-4"
              classNamePrefix="react-select"
              placeholder="Select Status"
              onChange={handleStatusChange}
              options={[
                { value: "activated", label: "Activated" },
                { value: "deactivated", label: "Deactivated" },
              ]}
              value={status}
            />
          </Col>
          <Col md={3} sm={12}>
          </Col>
        </Row>
        <ToolkitProvider keyField="id" data={suggestionDrawerCategoryData} columns={SuggestionDrawerCategoryColumns} search>
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} />
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                bordered={false}
                keyField="id"
                data={suggestionDrawerCategoryData}
                columns={SuggestionDrawerCategoryColumns}
                expandRow={expandRow}
                pagination={paginationFactory({
                  sizePerPage: 10,
                  sizePerPageList: [5, 10, 25, 50],
                })}
                defaultSorted={[{
                  dataField: 'id',
                  order: 'desc'
                }]}
              />
            </div>
          )}
        </ToolkitProvider>
      </CardBody>
      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={successModalHeading}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={failureModalHeading}
        modaltext={failureModalText}
      />
    </Card>
  );
};

const Category = () => {

  return (
    <Container fluid className="p-0">
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="h3">Suggestion Drawer Categories</h1>
        <div className="d-flex">
          {showCreateSuggestionDrawerCategoriesButton() ? (
            <div>
              <Link
                to={{
                  pathname: `/suggestionDrawer/category/create-category`,
                  state: {
                    formType: "create",
                  },
                }}
              >
                <Button
                  style={{ fontSize: "1rem" }}
                  className="mx-3 btn-success mb-3"
                >
                  Create New Category
                </Button>
              </Link>
            </div>
          ) : null}
          {showSuggestionDrawerCategoryOrder() ? (
            <div className="m1-auto">
              <Link
                to={{
                  pathname: `/suggestionDrawer/category/order`,
                  state: {
                    formType: "create",
                  },
                }}
              >
                <Button
                  style={{ fontSize: "1rem" }}
                  className="mx-3 btn-success mb-3"
                >
                  Category Order
                </Button>
              </Link>
            </div>
          ) : null}
           {showSuggestionDrawerCategoryOrder() ? (
            <div className="m1-auto">
              <Link
                to={{
                  pathname: `/suggestionDrawer/category/segmentOrder`,
                }}
              >
                <Button
                  style={{ fontSize: "1rem" }}
                  className="mx-3 btn-success mb-3"
                >
                  Segment Category Order
                </Button>
              </Link>
            </div>
          ) : null}
        </div>
      </div>
      <ExpandableRowsTable />
    </Container>
  )
};

export default Category;

import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col } from "reactstrap";
import { redirectTo } from "../../../utilities/commonUtil";
import {
  addResourceVariants,
  editResourceVariants,
} from "../../../utilities/apiUtils/keyboardLanguages";
import ResponseModal from "../ResponseModal";
import Routes from "../../../routes/index";
import ResourceVariantCard from "./ResourceVariantCard";
import {
  resourceVariantsAddedText,
  resourceVariantsUpdatedText,
  totalPercentageMismatchText,
  successModalHeading,
  failureModalHeading,
} from "../../../config/keyboardLanguages";

const ManageResourceVariants = (props) => {
  const [id, setID] = useState(props.location.state.abTestId);
  const [variants, setVariants] = useState([
    {
      description: "",
      percentage: "",
      version: "",
      selectedVariant: { label: "", value: "" },
    },
  ]);
  const [formType, setFormType] = useState("create");
  const [keyboardLanguageId, setKeyboardLanguageId] = useState(null);
  const [formHeading, setFormHeading] = useState("Add Resource Variants");

  const [successModalState, setSuccessModalState] = useState({
    successModalDisplay: false,
    successModalText: "",
  });
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });
  const [resources, setResources] = useState([]);
  const [updatedResources, setUpdatedResources] = useState([]);

  // This useEffect will be called for componentDidMount condition()
  useEffect(() => {
    if (props.location.state.formType === "edit") {
      setFormHeading("Edit Resource Variants");
      setVariants(props.location.state.variants);
      setID(props.location.state.abTestId);
    } else {
      if (
        props.location.state.variants === undefined ||
        props.location.state.variants.length === 0
      ) {
        addVariant();
        setKeyboardLanguageId(props.location.state.keyboardLanguageId);
        setResources(props.location.state.resources);
        setUpdatedResources(props.location.state.resources);
        setID(props.location.state.abTestId);
      } else {
        // Form type == create (both create an edit)
        for (const element of props.location.state.variants) {
          let selectedVersion = {};
          selectedVersion["label"] = element.version;
          selectedVersion["value"] = element.version;
          element["selectedVersion"] = selectedVersion;
        }
        setVariants(props.location.state.variants);
        setKeyboardLanguageId(props.location.state.keyboardLanguageId);
        setResources(props.location.state.resources);
        setUpdatedResources(props.location.state.resources);
        setID(props.location.state.abTestId);
        setFormHeading("Add/Edit Resource Variants");
      }
    }
  }, []);

  const showAlert = (elementId) => {
    document.getElementById(elementId).classList.remove("d-none");
  };

  const hideAlert = (elementId) => {
    document.getElementById(elementId).classList.add("d-none");
  };

  const successModalClose = () => {
    setSuccessModalState((prev) => ({ ...prev, successModalDisplay: false }));
    redirectTo(Routes.keyboardLanguages.children.abTests.path);
  };

  const failureModalClose = () => {
    setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
  };

  const handleResponse = (response) => {
    if (!response.errorCode) {
      let text = resourceVariantsAddedText;
      if (props.location.state.formType === "edit") {
        text = resourceVariantsUpdatedText;
      }
      setSuccessModalState({
        successModalDisplay: true,
        successModalText: text,
      });
      return;
    }
    setFailureModalState({
      failureModalDisplay: true,
      failureModalText: response.errorDescription,
    });
  };

  const addVariant = () => {
    let variant = {
      description: "",
      percentage: "",
      version: "",
      selectedVariant: { label: "", value: "" },
    };
    let updatedVariants = [...variants];
    updatedVariants.push(variant);
    setVariants(updatedVariants);
  };

  const deleteVariant = (index) => {
    let newVariants = [...variants];
    newVariants.splice(index, 1);
    setVariants(newVariants);
  };

  useEffect(() => {
    updateVariantsList();
  }, [variants]);

  const updateVariantsList = () => {
    let filteredUpdatedResources = [];

    let selectedVersions = [];
    for (const element of variants) {
      if (element.version != undefined && element.version !== "") {
        selectedVersions.push(element.version);
      }
    }

    for (const element of resources) {
      let isSelected = false;
      for (const selectedVersion of selectedVersions) {
        if (selectedVersion === element.value) {
          isSelected = true;
          break;
        }
      }
      if (!isSelected) {
        filteredUpdatedResources.push(element);
      }
    }
    setVariants(variants);
    if (resources.length > 0) setUpdatedResources(filteredUpdatedResources);
  };

  const handleResourceVersionChange = async (option, index) => {
    hideAlert(`invalidResourceVersion${index}`);
    let updatedVariants = [...variants];
    updatedVariants[index]["version"] = option.value;
    updatedVariants[index]["selectedVersion"] = option;

    setVariants(updatedVariants);
  };

  const handleChange = (event, index) => {
    hideAlert(`invalidPercentage${index}`);
    hideAlert(`invalidDescription${index}`);
    const { name, value } = event.target;
    let updatedVariants = [...variants];
    updatedVariants[index][name] = value;
    setVariants(updatedVariants);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let isVersionInvalid = false;
    let isPercentageInvalid = false;
    let isDescriptionInvalid = false;

    let totalPercentage = 0;
    for (let i = 0; i < variants.length; i++) {
      if (variants[i].percentage !== "" && variants[i].percentage !== null) {
        totalPercentage += parseInt(variants[i].percentage);
      }
      if (variants[i].version === "" || variants[i].version === null) {
        showAlert(`invalidResourceVersion${i}`);
        isVersionInvalid = true;
      }
      if (variants[i].percentage === "" || variants[i].percentage === null) {
        showAlert(`invalidPercentage${i}`);
        isPercentageInvalid = true;
      }
      if (variants[i].description === "" || variants[i].description === null) {
        showAlert(`invalidDescription${i}`);
        isDescriptionInvalid = true;
      }
    }

    if (
      isVersionInvalid === true ||
      isPercentageInvalid === true ||
      isDescriptionInvalid === true
    ) {
      return;
    }
    if (totalPercentage !== 100) {
      setFailureModalState({
        failureModalText: totalPercentageMismatchText,
        failureModalDisplay: true,
      });
      return;
    }

    let updatedVariants = [];
    for (const element of variants) {
      let variant = {};

      if (props.location.state.formType === "create") {
        variant["version"] = element.version;
      }
      if (props.location.state.formType === "edit") {
        variant["id"] = element.id;
      }
      variant["percentage"] = parseInt(element.percentage);
      variant["description"] = element.description;
      updatedVariants.push(variant);
    }

    let obj = {
      variants: updatedVariants,
    };

    let response;
    if (props.location.state.formType === "edit") {
      response = await editResourceVariants(id, obj);
    } else {
      response = await addResourceVariants(id, obj, (props.location.state.keyboardLanguageName + "-" + props.location.state.keyboardLanguageId));
    }
    handleResponse(response);
  };

  return (
    <Container>
      <Row>
        <h1 className="mb-4 mt-2 w-50 pl-3">{formHeading}</h1>
        <Col>
          {props.location.state.formType === "create" ? (
            <Button
              className=" d-block ml-auto mb-4 mr-3 ml-auto d-flex btn-success"
              onClick={() => {
                addVariant();
              }}
            >
              <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                Add
              </p>
            </Button>
          ) : null}
        </Col>
      </Row>
      {variants &&
        variants.map((variant, index) => {
          return (
            <ResourceVariantCard
              index={index}
              variant={variant}
              variants={variants}
              formType={props.location.state.formType}
              handleChange={handleChange} //
              handleResourceVersionChange={handleResourceVersionChange} //
              deleteVariant={deleteVariant} //
              updatedResources={updatedResources}
            />
          );
        })}
      <div className="d-block-mx-auto">
        <Button color="primary" onClick={(event) => handleSubmit(event)}>
          Submit
        </Button>
      </div>
      <ResponseModal
        show={successModalState.successModalDisplay}
        onHide={() => successModalClose()}
        modalheading={successModalHeading}
        modaltext={successModalState.successModalText}
      />
      <ResponseModal
        show={failureModalState.failureModalDisplay}
        onHide={() => failureModalClose()}
        modalheading={failureModalHeading}
        modaltext={failureModalState.failureModalText}
      />
    </Container>
  );
};

export default ManageResourceVariants;

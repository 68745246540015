import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  Button,
  CardBody,
  Badge,
  Collapse,
  Table,
} from "reactstrap";
import {
  errorDescription,
  redirectOnTokenExpiry,
  tokenExpiryErrorCodes,
  error,
  redirectionOnTokenExpiry,
  permissions,
  redirectTo,
} from "../../../utilities/commonUtil";
import Routes from "../../../routes/index";
import EditABTestModal from "./components/EditABTestModal.jsx";
import CompleteABTest from "./components/CompleteABTest.jsx";
import { Link } from "react-router-dom";
import {
  editABTest,
  getABTestDetail,
  getKeyboardLanguage,
  completeABTest,
} from "../../../utilities/apiUtils/keyboardLanguages";
import Loading from "../../../components/Loading";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import ResponseModal from "../ResponseModal";
import {
  abTestUpdatedStatus,
  abTestUpdatedText,
  abTestCompletedStatus,
  abTestCompletedText,
  abTestInvalidText,
  noResourceVariantsFoundText,
  successModalHeading,
  failureModalHeading,
} from "../../../config/keyboardLanguages";

const ABTestDetails = (props) => {
  const [loading, setLoading] = useState(false);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [isCompleteEnabled, setIsCompleteEnabled] = useState(false);
  const [abTestDetail, setABTestDetail] = useState({});
  const [successModalState, setSuccessModalState] = useState({
    successModalDisplay: false,
    successModalText: "",
  });
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });
  const [allowedPermissions, setAllowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );
  const [keyboardLanguage, setKeyboardLanguage] = useState({});
  const [resources, setResources] = useState([]);
  const [isABTestInvalid, setIsABTestInvalid] = useState(false);
  const [resourcesWithCurrentBaseVersion, setResourcesWithCurrentBaseVersion] =
    useState([]);
  const [resourceVariants, setResourceVariants] = useState([]);

  // This useEffect will be called for componentDidMount condition
  useEffect(() => {
    const fetchABTestDetails = async (abTestId) => {
      try {
        setLoading(true);
        const response = await getABTestDetail(abTestId);
        if (response.errorCode !== undefined) throw response;

        let variants = response.keyboardLanguageABTest.variants;
        variants.sort((x, y) => x.id - y.id);
        let resourceMappings = [];
        for (let i = 0; i < variants.length; i++) {
          response.keyboardLanguageABTest.variants[i].isOpen = false;
          let resourceVersion = {};
          if (i === 0) {
            resourceVersion["label"] = "Production-" + variants[i].version;
          } else {
            resourceVersion["label"] = "AB Test-" + variants[i].version;
          }
          resourceVersion["value"] = variants[i].id;
          resourceMappings.push(resourceVersion);
        }

        setIsABTestInvalid(!props.location.state.isABTestValid);
        setABTestDetail(response.keyboardLanguageABTest);
        setResourcesWithCurrentBaseVersion(resourceMappings);
        if (response.keyboardLanguageABTest.keyboardLanguageId) {
          getLanguageDetail(response.keyboardLanguageABTest.keyboardLanguageId);
        }
      } catch (err) {
        if (tokenExpiryErrorCodes.includes(err.errorCode))
          redirectOnTokenExpiry();
        setLoading(false);
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(err.unexpectedError),
        });
      }
    };

    fetchABTestDetails(props.location.state.abTestId);
  }, []);

  const openEditModal = () => {
    setIsEditEnabled(true);
  };

  const closeEditModal = () => {
    setIsEditEnabled(false);
  };

  const openCompleteModal = () => {
    setIsCompleteEnabled(true);
  };

  const closeCompleteModal = () => {
    setIsCompleteEnabled(false);
  };

  const successModalClose = () => {
    setSuccessModalState((prev) => ({ ...prev, successModalDisplay: false }));
    redirectTo(Routes.keyboardLanguages.children.abTests.path);
  };

  const failureModalClose = () => {
    setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
  };

  const getLanguageDetail = async (id) => {
    setLoading(true);
    try {
      const response = await getKeyboardLanguage(id);
      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectionOnTokenExpiry();
        setLoading(false);
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(response.errorCode),
        });
      } else if (response.keyboardLanguage) {
        let keyboardLanguage = response.keyboardLanguage;
        // filter resource Versions with base versions and above
        let resources = keyboardLanguage.resourceVersions;

        let baseVersionIndex;
        for (let i = 0; i < resources.length; i++) {
          if (resources[i].releaseType === "production") {
            baseVersionIndex = i + 1;
            break;
          }
        }

        let resourceVariants = resources.slice(0, baseVersionIndex);
        let resourceMappings = [];
        for (let i = 0; i < resourceVariants.length; i++) {
          let resourceVersion = {};
          if (i === resourceVariants.length - 1) {
            resourceVersion["label"] =
              "Production-" + resourceVariants[i].version;
          } else {
            resourceVersion["label"] = "AB Test-" + resourceVariants[i].version;
          }
          resourceVersion["value"] = resourceVariants[i].version;
          resourceMappings.push(resourceVersion);
        }

        setLoading(false);
        setKeyboardLanguage(keyboardLanguage);
        setResources(resources);
        setResourceVariants(resourceMappings);
      }
    } catch (err) {
      setLoading(false);
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const handleResponse = (response, event) => {
    if (response.status === abTestUpdatedStatus) {
      closeEditModal();
      setSuccessModalState({
        successModalDisplay: true,
        successModalText: abTestUpdatedText,
      });
    } else if (response.status === abTestCompletedStatus) {
      closeCompleteModal();
      setSuccessModalState({
        successModalDisplay: true,
        successModalText: abTestCompletedText,
      });
    } else {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: response.errorDescription,
      });
    }
  };

  const toggleCollapse = (index) => {
    let updatedABTestDetail = { ...abTestDetail };
    updatedABTestDetail.variants[index].isOpen =
      !updatedABTestDetail.variants[index].isOpen;
    setABTestDetail(updatedABTestDetail);
  };

  const showErrorModal = (errorCode) => {
    setFailureModalState({
      failureModalText: errorCode,
      failureModalDisplay: true,
    });
  };

  const showSuccessModal = (text) => {
    setSuccessModalState({
      successModalText: text,
      successModalDisplay: true,
    });
  };

  const showCreateResourceVariantButton = () => {
    return allowedPermissions.includes(
      permissions.keyboardLanguageABTestVariantCreate
    );
  };

  const showEditResourceVariantButton = () => {
    return allowedPermissions.includes(
      permissions.keyboardLanguageABTestVariantEdit
    );
  };

  const showCompleteABTestButton = () => {
    return allowedPermissions.includes(
      permissions.keyboardLanguageABTestComplete
    );
  };

  const showEditABTestButton = () => {
    return allowedPermissions.includes(permissions.keyboardLanguageABTestEdit);
  };

  const updateABTest = async (id, updatedABTest) => {
    let payload = {
      oldABTest: abTestDetail,
      updatedABTest: { ...abTestDetail, ...updatedABTest },
    }
    let response = await editABTest(id, payload, keyboardLanguage.name + "-" + abTestDetail.keyboardLanguageId);
    handleResponse(response, "updatedABTest");
  };

  const markBTestComplete = async (variant) => {
    let newVariant = new FormData();
    newVariant.append("variantId", parseInt(variant));
    let response = await completeABTest(abTestDetail.id, newVariant, keyboardLanguage.name + "-" + abTestDetail.keyboardLanguageId);
    handleResponse(response, "completed");
  };

  return (
    <Container>
      {isABTestInvalid && abTestDetail.publishedAt === null ? (
        <div className="d-flex" style={{ maxWidth: "max-content" }}>
          <Badge className="mt-2" style={{ display: "table" }} color="danger">
            {abTestInvalidText}
          </Badge>
        </div>
      ) : null}
      {loading ? (
        <Loading />
      ) : (
        <Fragment>
          <Card className="mt-3 mb-5">
            <CardBody>
              <Table borderless>
                <tbody>
                  <tr>
                    <td><strong>ID:</strong></td>
                    <td>{abTestDetail.id}</td>
                  </tr>
                  <tr>
                    <td><strong>Keyboard Language ID:</strong></td>
                    <td>
                      {keyboardLanguage.name}
                      {"-"}
                      {abTestDetail.keyboardLanguageId}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Existing Users Rollout Percentage:</strong></td>
                    <td>{abTestDetail.existingUsersRolloutPercentage}</td>
                  </tr>
                  <tr>
                    <td><strong>Description:</strong></td>
                    <td>{abTestDetail.description ? abTestDetail.description : "N/A"}</td>
                  </tr>
                  <tr>
                    <td><strong>Completed At:</strong></td>
                    <td>
                      {abTestDetail.completedAt !== null
                        ? abTestDetail.completedAt
                        : "NULL"}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Published At:</strong></td>
                    <td>
                      {abTestDetail.publishedAt !== null
                        ? abTestDetail.publishedAt
                        : "NULL"}
                    </td>
                  </tr>
                </tbody>
              </Table>

              {/* Complete and Edit buttons */}
              <Row>
                {showCompleteABTestButton() &&
                  abTestDetail.variants &&
                  abTestDetail.variants.length > 0 &&
                  abTestDetail.completedAt === null ? (
                  <div className="d-block ml-3 mt-3">
                    <Button
                      className="btn-success"
                      disabled={abTestDetail.publishedAt === null}
                      onClick={() => {
                        openCompleteModal();
                      }}
                    >
                      Complete
                    </Button>
                  </div>
                ) : null}

                {showEditABTestButton() ? (
                  <div className="d-block ml-3 mt-3">
                    <Button
                      color="primary"
                      className=""
                      onClick={() => {
                        openEditModal();
                      }}
                    >
                      Edit
                    </Button>
                  </div>
                ) : null}
              </Row>
            </CardBody>
          </Card>


          <Container className="d-flex px-0">
            {abTestDetail.variants && abTestDetail.variants.length > 0 ? (
              <h3 className="mt-3 mb-0">Resource Variants</h3>
            ) : null}
            {showCreateResourceVariantButton() &&
              abTestDetail.variants &&
              abTestDetail.variants.length === 0 ? (
              <Link
                className="ml-auto d-flex text-decoration-none"
                to={{
                  pathname: `/kl/abtests/${abTestDetail.id}/variants/add`,
                  state: {
                    formType: "create",
                    abTestId: abTestDetail.id,
                    keyboardLanguageId: abTestDetail.keyboardLanguageId,
                    keyboardLanguageName: keyboardLanguage.name,
                    resources: resourceVariants,
                  },
                }}
              >
                <Button color="primary" className="ml-auto d-flex">
                  <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                    Add new Resource Variant
                  </p>
                </Button>
              </Link>
            ) : null}

            {showEditResourceVariantButton() &&
              abTestDetail.variants &&
              abTestDetail.variants.length > 0 &&
              isABTestInvalid === false &&
              abTestDetail.completedAt === null ? (
              <Link
                className="ml-auto d-flex text-decoration-none"
                to={{
                  pathname: `/kl/abtests/${abTestDetail.id}/variants/edit`,
                  state: {
                    formType:
                      abTestDetail.publishedAt === null ? "create" : "edit",
                    variants: abTestDetail.variants,
                    abTestId: abTestDetail.id,
                    resources: resourcesWithCurrentBaseVersion,
                  },
                }}
              >
                <Button color="primary" className="ml-auto d-flex">
                  <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                    Edit Resource Variants
                  </p>
                </Button>
              </Link>
            ) : null}
          </Container>

          {/* Accordian for displaying resource variants */}
          {abTestDetail.variants && abTestDetail.variants.length > 0 ? (
            abTestDetail.variants.map((resourceVariant, index) => {
              return (
                <Fragment key={index}>
                  <Card
                    className="mb-0 mt-3"
                    style={{
                      cursor: "pointer",
                      backgroundColor: "rgb(200,215,250)",
                    }}
                  >
                    <CardBody
                      className="mb-0 p-2"
                      onClick={() => toggleCollapse(index)}
                    >
                      <Row>
                        <Col md={6} className="d-flex">
                          <p className="mb-0 mr-2">Version: </p>
                          <strong className="mb-0 p-0">
                            {resourceVariant.version}
                          </strong>
                        </Col>
                        <Col md={6} className="d-flex">
                          <p className="mb-0 mr-2">Percentage: </p>
                          <strong className="mb-0 p-0">
                            {resourceVariant.percentage}
                          </strong>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Collapse isOpen={resourceVariant.isOpen}>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col>
                            <span>
                              <strong>Version:</strong>{" "}
                              {resourceVariant.version}
                            </span>
                            <br />
                            <span>
                              <strong>Percentage:</strong>{" "}
                              {resourceVariant.percentage}
                            </span>
                            <br />
                            <span>
                              <strong>Description:</strong>{" "}
                              {resourceVariant.description}
                            </span>
                          </Col>
                          <Col md={6}>
                            <span>
                              <strong>Created At:</strong>{" "}
                              {resourceVariant.createdAt}
                            </span>
                            <br />
                            <span>
                              <strong>Updated At:</strong>{" "}
                              {resourceVariant.updatedAt}
                            </span>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Collapse>
                </Fragment>
              );
            })
          ) : (
            <h1 className="d-block mx-auto w-50 mt-5">
              {noResourceVariantsFoundText}
            </h1>
          )}
        </Fragment>
      )}
      <EditABTestModal
        show={isEditEnabled}
        id={abTestDetail.id}
        description={abTestDetail.description}
        existingUsersRolloutPercentage={abTestDetail.existingUsersRolloutPercentage}
        editABTest={updateABTest}
        onHide={() => closeEditModal()}
      />
      <CompleteABTest
        show={isCompleteEnabled}
        variants={resourcesWithCurrentBaseVersion}
        onHide={() => closeCompleteModal()}
        completeABTest={markBTestComplete}
      />
      <ResponseModal
        show={successModalState.successModalDisplay}
        onHide={() => successModalClose()}
        modalheading={successModalHeading}
        modaltext={successModalState.successModalText}
      />
      <ResponseModal
        show={failureModalState.failureModalDisplay}
        onHide={() => failureModalClose()}
        modalheading={failureModalHeading}
        modaltext={failureModalState.failureModalText}
      />
    </Container>
  );
};

export default ABTestDetails;

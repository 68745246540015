import React, { Component } from "react";
import { Col } from "reactstrap";

class EmptyGifPacks extends Component {
  render() {
    return (
      <Col sm="12" md="8" lg="6" className="mx-auto d-table mt-5">
        <h1 className="text-center">No Gif Packs</h1>
      </Col>
    );
  }
}

export default EmptyGifPacks;

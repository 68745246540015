import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Container, Row, Col } from 'reactstrap';
import RulesCard from "./components/EmojiStickerImageRulesCard";
import ResponseModal from "../ResponseModal";
import {
  error,
  errorDescription,
} from "../../../utilities/commonUtil";
import AgeGenderRules from "../AgeGenderRules";
import { gendersList } from "../../../config/UserConfig";

const EmojiStickerImageTargetingRules = (props) => {
  const getRules = props.getRules;
  const saveRules = props.saveRules;

  const updateAllowed = props.updateRulesAllowed;
  const [ageGenderRules, setAgeGenderRules] = useState({})
  const [rules, setRules] = useState([]);
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");

  const [minAge, setMinAge] = useState(0)
  const [maxAge, setMaxAge] = useState(null)
  const [gender, setGender] = useState(gendersList[0])
  const [genderHardFiltering, setGenderHardFiltering] = useState(false)
  const [ageHardFiltering, setAgeHardFiltering] = useState(false)
  const [ageGenderHardFiltering, setAgeGenderHardFiltering] = useState(false)


  useEffect(() => {
    const _getRules = async () => {
      let response = await getRules(props.emojiId, props.emojiStickerImageId);
      if (response != null && !response.errorCode) {
        for (let i = 0; i < response.length; i++) {
          response[i].id = i;
        }
        setRules(response);

        let ageGenderRuleResponse;
        if (response[0].ageGenderRules) {
          ageGenderRuleResponse = response[0].ageGenderRules
        }
        setAgeGenderRules(ageGenderRuleResponse ?? null)

        setMinAge(ageGenderRuleResponse.minAge ?? null)
        setMaxAge(ageGenderRuleResponse.maxAge ?? null)
        if (ageGenderRuleResponse.gender) {
          setGender({ "value": ageGenderRuleResponse.gender, "label": ageGenderRuleResponse.gender[0].toUpperCase() + ageGenderRuleResponse.gender.substring(1) })
        }
        setAgeHardFiltering(ageGenderRuleResponse.ageHardFiltering ?? null)
        setGenderHardFiltering(ageGenderRuleResponse.genderHardFiltering ?? null)
        setAgeGenderHardFiltering(ageGenderRuleResponse.ageHardFiltering && ageGenderRuleResponse.genderHardFiltering)
      } else {
        setRules([
          {
            id: 0,
            clientRules: [{ clientID: "global", effect: "allow" }],
            locationRules: [
              {
                countryID: 240,
                regionID: 1,
                cityID: 1,
                effect: "allow",
              },
            ],
          },
        ]);
      }
    };

    _getRules();
  }, [props.emojiId, props.emojiStickerImageId]);


  useEffect(() => {
    setAgeGenderRules({
      "minAge": parseInt(minAge),
      "maxAge": parseInt(maxAge),
      "gender": gender.value,
      "ageHardFiltering": ageHardFiltering,
      "genderHardFiltering": genderHardFiltering,

    })
  }, [minAge, maxAge, gender, ageHardFiltering, genderHardFiltering, ageGenderHardFiltering])

  const addRule = (event) => {
    setRules((prev) => [
      ...prev,
      {
        id: prev[prev.length - 1].id + 1,
        clientRules: [{ clientID: "global", effect: "allow" }],
        locationRules: [
          {
            countryID: 240,
            regionID: 1,
            cityID: 1,
            effect: "allow",
          },
        ],
      },
    ]);
  };

  const removeRule = (id) => {
    setRules((prev) => {
      return prev.filter((rule) => rule.id !== id);
    });
  };

  const updateRules = (ruleID, clientRules, locationRules, activate) => {
    setRules((prev) => {
      prev[ruleID].clientRules = clientRules;
      prev[ruleID].locationRules = locationRules;
      prev[ruleID].activate = activate;
      return [...prev];
    });
  };

  let handleAgeChange = (event) => {
    if (event.target.name === "minAge")
      setMinAge(event.target.value)
    else if (event.target.name === "maxAge")
      setMaxAge(event.target.value)

  }

  let handleGenderChange = (select) => {
    let selectedGender = {};
    if (select !== null) {
      selectedGender["value"] = select.value;
      selectedGender["label"] = select.label;
    }
    setAgeGenderRules({ ...ageGenderRules, "gender": select.value })
    setGender(selectedGender);
  };

  let handleAgeAndGenderToggleChange = (event) => {

    if (event.target.name === "ageGenderHardFiltering") {
      setGenderHardFiltering(!ageGenderHardFiltering)
      setAgeHardFiltering(!ageGenderHardFiltering)
      setAgeGenderHardFiltering(!ageGenderHardFiltering)
    }

    if (event.target.name === "ageHardFiltering") {
      // update the ageGenderHardFiltering Toggle value depending upon the previous values of age toggle and gender toggle
      // if ageHardFiltering value was false before and genderHadfiltering was true, that means the combine toggle will be true now
      setAgeGenderHardFiltering(!ageHardFiltering && genderHardFiltering)
      setAgeHardFiltering(!ageHardFiltering)
    }

    if (event.target.name === "genderHardFiltering") {
      // update the ageGenderHardFiltering Toggle value depending upon the previous values of age toggle and gender toggle
      setAgeGenderHardFiltering(ageHardFiltering && !genderHardFiltering)
      setGenderHardFiltering(!genderHardFiltering)

    }
  };

  let addAgeGenderRules = () => {
    for (const element of rules) {
      element.ageGenderRules = ageGenderRules
    }
  }

  const saveChanges = async () => {

    addAgeGenderRules()
    var response = await saveRules(rules, props.emojiId, props.emojiStickerImageId);
    if (response.errorCode) {
      setFailureModalText(errorDescription(response.errorCode) || errorDescription(error.unexpectedError));
      setFailureModalDisplay(true);
    } else if (response != null) {
      window.location.reload();
    }
  };

  return (
    <Container>
      <h1 className="h3 mb-3">Targeting Rules </h1>
      <Row>
        <AgeGenderRules
          updateRules={updateRules}
          removeRule={removeRule}
          updateAllowed={updateAllowed}
          minAge={minAge ?? 0}
          maxAge={maxAge ?? null}
          gender={gender ?? gendersList[0]}
          ageHardFiltering={ageHardFiltering ?? false}
          genderHardFiltering={genderHardFiltering ?? false}
          handleAgeChange={handleAgeChange}
          handleGenderChange={handleGenderChange}
          handleAgeAndGenderToggleChange={handleAgeAndGenderToggleChange}
        ></AgeGenderRules>
      </Row>
      {rules.map(function (object) {
        return (
          <Row>
            <Col md={12}>
              <RulesCard
                key={object.id}
                rule={object}
                updateRules={updateRules}
                removeRule={removeRule}
                updateAllowed={updateAllowed}
              />
            </Col>
          </Row>
        );
      })}
      {updateAllowed ? (
        <div className="d-flex">
          <div className="p-2">
            <Button onClick={saveChanges}>Save Changes</Button>
          </div>
          <div className="ml-auto p-2">
            <Button onClick={addRule}>Add Rule</Button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <ResponseModal
        show={failureModalDisplay}
        onHide={(e) => setFailureModalDisplay(false)}
        modalheading={"Error"}
        modaltext={failureModalText}
      />
    </Container>
  );
};

export default EmojiStickerImageTargetingRules;

import React, { useState, useEffect } from "react";
import {
    Col,
    Row,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Container,
    Button,
} from "reactstrap";
import { getClients } from "../../../../utilities/apiUtils/stickers";
import { trackersTypeOptions, quickRepliesTextDetailsType } from "../../../../config/quickReplies";
import { X } from "react-feather";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import QuickReplyCTAItems from "./QuickReplyCTAItems";
import QuickReplyActions from "./QuickReplyActions";
import { validateHexCode } from "../../../../utilities/commonUtil";

const QuickReplyTypeCard = ({ quickReplyTypeCard, setQuickReplyTypeCard, formName, updateAllowed }) => {
    const [imagePreview, setImagePreview] = useState(null);
    const [clientOptions, setClientOptions] = useState([]);

    useEffect(() => {
        _getClientOptions();
    }, []);

    const _getClientOptions = async () => {
        let clientOptions = await getClients();
        setClientOptions(clientOptions);
    };

    const addClickTracker = () => {
        setQuickReplyTypeCard({
            ...quickReplyTypeCard,
            clickTrackers: [...quickReplyTypeCard.clickTrackers, {
                type: { value: "direct_url", label: "Direct URL" },
                url: null,
            }]
        });
    }

    const addImpressionTracker = () => {
        setQuickReplyTypeCard({
            ...quickReplyTypeCard,
            impressionTrackers: [...quickReplyTypeCard.impressionTrackers, {
                type: { value: "direct_url", label: "Direct URL" },
                url: null,
            }]
        });
    }

    const addTextDetails = () => {
        setQuickReplyTypeCard({
            ...quickReplyTypeCard,
            textDetails: [...quickReplyTypeCard.textDetails, {
                type: null,
                lightThemeTextColor: null,
                darkThemeTextColor: null,
                text: null,
            }]
        });
    }

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setImagePreview(reader.result);
            setQuickReplyTypeCard({
                ...quickReplyTypeCard,
                image: reader.result,
                imageFilename: file.name
            });
        }

        reader.readAsDataURL(file);
        document.getElementById("image").innerHTML = file.name;
    }

    // remove selected options from the dropdown for quickRepliesTextDetailsType
    const textDetailsTypeOptions = () => {
        let selected = quickReplyTypeCard?.textDetails?.map(item => item?.type?.value);
        return quickRepliesTextDetailsType.filter(item => !selected.includes(item.value));
    }

    const disableAddEditActionButton = (action) => {
        if (action.type === null) {
          return "Type field is required.";
        }

        if (action.clientId === null && action.type.value !== "share") {
            return "Client field is required.";
        }
      
        if (action.type.value === "copyCode") {
          if (action.textToCopy === null) {
            return "Text to copy is required for copyCode action.";
          }
        
          if (action.deeplinkType === null) {
            return "Deeplink Type is required for copyCode action.";
          }

          if(action.deeplinkType.value === "custom" && (action.deeplink === "" || action.deeplink === null)) {
            return "Deeplink is required for copyCode action.";
          }

        }
      
        if (action.type.value === "share") {
          if (action.shareText === null) {
            return "Share text is required for share action.";
          }

          if (action.deeplinkType === null) {
            return "Deeplink Type is required for share action.";
          }

          if ((action.deeplinkType.value === "custom" && (action.deeplink === "" || action.deeplink === null))){
            return "Deeplink is required for share action."
          }
        }
      
        if (action.type.value === "redirect") {
          if (action.redirectionType === null) {
            return "Redirection type is required for redirect action.";
          }
          if (action.redirectionType.value !== "browser" && action.redirectionViewportHeight === null) {
            return "Redirection viewport height is required for redirect action.";
          }
          if (action.deeplinkType === null) {
            return "Deeplink Type is required for redirect action.";
          }
          if (action.url === null) {
            return "URL is required for redirect action.";
          }
          if (action.deeplinkType.value === "custom" && (action.deeplink === "" || action.deeplink === null)) {
            return "Custom Deeplink is required for redirect action.";
          }
        }
      
        return ""; // Return an empty string when all fields are filled
      };

      const allowEdit = () => {
        return formName === "edit" && !updateAllowed;
    }

    const validateColor = (color) => {
        if (color !== null && color !== "" && color !== undefined) {
            return validateHexCode(color);
        }
        return true;
    }

      

    return (
        <Container fluid className="p-0">
            <Card>
                <CardBody>
                    <Row >
                        <Col md={12}>
                            <FormGroup>
                                <Label>
                                    <strong>
                                        Select an Image <span className="text-danger">*</span>
                                    </strong>
                                    <p style={{ fontSize: 12, marginBottom: 0 }}>
                                        The image should be jpg/png/gif/webp
                                    </p>
                                </Label>

                                <br />
                                <Container className="custom-file">
                                    <Input
                                        type="file"
                                        className="custom-file-input"
                                        accept="image/*"
                                        required={formName !== "edit" && formName !== "duplicate"}
                                        onChange={handleImageUpload}
                                        disabled={allowEdit()}
                                    />

                                    <Label className="custom-file-label" id="image">
                                        <strong>{quickReplyTypeCard.imageFilename === null ? "Choose an image" : quickReplyTypeCard.imageFilename}</strong>
                                    </Label>
                                </Container>

                                {(imagePreview || quickReplyTypeCard.imageURL) && (
                                    <Col className="pb-4 mb-3 mt-3">
                                        <img src={imagePreview || quickReplyTypeCard.imageURL} alt="preview" width="100" />
                                    </Col>

                                )}

                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>
                                    <strong>
                                        Light Theme Background Color<span className="text-warning text-sm">(Mandatory for Bobble)</span>{" "}
                                        {validateColor(quickReplyTypeCard.lightThemeBackgroundColor) ? (
                                            <div
                                                style={{
                                                    backgroundColor:
                                                        quickReplyTypeCard.lightThemeBackgroundColor,
                                                    width: "16px",
                                                    height: "16px",
                                                    display: "inline-block",
                                                    verticalAlign: "middle",
                                                    border: "1px solid #000",
                                                    borderRadius: "30%",
                                                }}
                                            >
                                                {" "}
                                            </div>
                                        ) : (

                                            <small className="text-danger ml-2">
                                                {"Invalid Hex Code"}
                                            </small>
                                        )
                                        }

                                    </strong>
                                </Label>
                                <Input
                                    type="text"
                                    className={
                                        validateColor(quickReplyTypeCard.lightThemeBackgroundColor)
                                            ? "form-control is-valid"
                                            : "form-control is-invalid"
                                    }
                                    name="lightThemeBackgroundColor"
                                    placeholder="Enter Background Color"
                                    value={
                                        quickReplyTypeCard.lightThemeBackgroundColor
                                    }
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        setQuickReplyTypeCard((prev) => ({
                                            ...prev,
                                            [name]: value,
                                        }));
                                    }}
                                    disabled={allowEdit()}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>
                                    <strong>
                                        Dark Theme Background Color<span className="text-warning text-sm">(Mandatory for Bobble)</span>{" "}
                                        {validateColor(quickReplyTypeCard.darkThemeBackgroundColor) ? (
                                            <div
                                                style={{
                                                    backgroundColor:
                                                        quickReplyTypeCard.darkThemeBackgroundColor,
                                                    width: "16px",
                                                    height: "16px",
                                                    display: "inline-block",
                                                    verticalAlign: "middle",
                                                    border: "1px solid #000",
                                                    borderRadius: "30%",
                                                }}
                                            >
                                                {" "}
                                            </div>
                                        ) : (
                                            <small className="text-danger ml-2">
                                                {"Invalid Hex Code"}
                                            </small>
                                        )}
                                    </strong>
                                </Label>
                                <Input
                                    type="text"
                                    className={
                                        validateColor(quickReplyTypeCard.darkThemeBackgroundColor)
                                            ? "form-control is-valid"
                                            : "form-control is-invalid"
                                    }
                                    name="darkThemeBackgroundColor"
                                    placeholder="Enter Background Color"
                                    value={
                                        quickReplyTypeCard.darkThemeBackgroundColor
                                    }
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        setQuickReplyTypeCard((prev) => ({
                                            ...prev,
                                            [name]: value,
                                        }));
                                    }}
                                    disabled={allowEdit()}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>
                                    <strong>
                                        Start Date
                                    </strong>
                                </Label>
                                <Input
                                    type="date"
                                    name="startDate"
                                    value={quickReplyTypeCard.startDate}
                                    disabled={allowEdit()}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        setQuickReplyTypeCard((prev) => ({
                                            ...prev,
                                            [name]: value,
                                        }));
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>
                                    <strong>
                                        End Date
                                    </strong>
                                </Label>
                                <Input
                                    type="date"
                                    name="endDate"
                                    value={quickReplyTypeCard.endDate}
                                    disabled={allowEdit()}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        setQuickReplyTypeCard((prev) => ({
                                            ...prev,
                                            [name]: value,
                                        }));
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    {/* Text Details */}
                    <Row className="mt-6">
                        <Col md={6}>
                            <h1 className="h3 mb-3">Text Details</h1>
                        </Col>
                        <Col md={6}>
                            <Button className="float-right" onClick={addTextDetails} disabled={allowEdit()}>
                                <FontAwesomeIcon size="sm" icon={faPlus}></FontAwesomeIcon>
                                {" "}Add Text Element
                            </Button>
                        </Col>
                    </Row>
                    <Card>
                        <CardBody>
                            {quickReplyTypeCard.textDetails.map((item, index) => (
                                <Card key={`card-${index}`} className="p-3 mb-5" style={{
                                    boxShadow: '0 0 20px rgba(0,0,0,0.4)',  // Increased shadow
                                    borderRadius: '15px'
                                }}>

                                    <Row key={`row-${index}`}>
                                        <Col>
                                            <FormGroup>
                                                <Label>
                                                    <strong>
                                                        Type
                                                    </strong>
                                                </Label>
                                                <Select
                                                    className="react-select-container"
                                                    classNamePrefix="react-select"
                                                    name="type"
                                                    value={item.type}
                                                    placeholder="Select type"
                                                    isClearable={true}
                                                    options={textDetailsTypeOptions()}
                                                    onChange={(option) => {
                                                        let temp = [...quickReplyTypeCard.textDetails];
                                                        temp[index].type = option;
                                                        setQuickReplyTypeCard({
                                                            ...quickReplyTypeCard,
                                                            textDetails: temp,
                                                        });
                                                    }}
                                                    isDisabled={allowEdit()}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                        {(formName !== "edit" || updateAllowed) && (
                                            <X
                                            className="float-right"
                                            size={20}
                                            color="red"
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                                // do not delete the last element
                                                if (quickReplyTypeCard.textDetails.length === 1) {
                                                    return;
                                                }
                                                let temp = [...quickReplyTypeCard.textDetails];
                                                temp = temp.filter((_, idx) => idx !== index);
                                                setQuickReplyTypeCard({
                                                    ...quickReplyTypeCard,
                                                    textDetails: temp,
                                                });
                                            }}
                                        ></X>
                                        )}
                                            
                                        </Col>
                                    </Row>
                                    <Row key={`row-${index + 7}`}>
                                        <Col>
                                            <FormGroup>
                                                <Label>
                                                    <strong>
                                                        Light Theme Text Color<span className="text-warning text-sm">(Enter Hex Code starting with #)</span>{" "}
                                                        {validateColor(item.lightThemeTextColor) ? (
                                                            <div
                                                                style={{
                                                                    backgroundColor:
                                                                        item.lightThemeTextColor,
                                                                    width: "16px",
                                                                    height: "16px",
                                                                    display: "inline-block",
                                                                    verticalAlign: "middle",
                                                                    border: "1px solid #000",
                                                                    borderRadius: "30%",
                                                                }}
                                                            >
                                                                {" "}
                                                            </div>
                                                        ) : (
                                                            <small className="text-danger ml-2">
                                                                {"Invalid Hex Code"}
                                                            </small>
                                                        )}
                                                    </strong>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className={
                                                        validateColor(item.lightThemeTextColor)
                                                          ? "form-control is-valid"
                                                          : "form-control is-invalid"
                                                      }
                                                    name="lightThemeTextColor"
                                                    placeholder="Enter Text Color"
                                                    value={item.lightThemeTextColor}
                                                    disabled={allowEdit()}
                                                    onChange={(option) => {
                                                        let temp = [...quickReplyTypeCard.textDetails];
                                                        temp[index].lightThemeTextColor = option.target.value !== "" ? option.target.value : null;
                                                        setQuickReplyTypeCard({
                                                            ...quickReplyTypeCard,
                                                            textDetails: temp,
                                                        });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label>
                                                    <strong>
                                                        Dark Theme Text Color<span className="text-warning text-sm">(Enter Hex Code starting with #)</span>{" "}
                                                        {validateColor(item.darkThemeTextColor) ? (<div
                                                            style={{
                                                                backgroundColor:
                                                                    item.darkThemeTextColor,
                                                            }}
                                                        >
                                                            {" "}
                                                        </div>
                                                        ) : (
                                                            <small className="text-danger ml-2">
                                                            {"Invalid Hex Code"}
                                                          </small>
                                                        )}

                                                    </strong>
                                                </Label>
                                                <Input
                                                    type="text"
                                                    name="darkThemeTextColor"
                                                    className={
                                                        validateColor(item.darkThemeTextColor)
                                                          ? "form-control is-valid"
                                                          : "form-control is-invalid"
                                                      }
                                                    placeholder="Enter Text Color"
                                                    disabled={allowEdit()}
                                                    value={item.darkThemeTextColor}
                                                    onChange={(option) => {
                                                        let temp = [...quickReplyTypeCard.textDetails];
                                                        temp[index].darkThemeTextColor = option.target.value !== "" ? option.target.value : null;
                                                        setQuickReplyTypeCard({
                                                            ...quickReplyTypeCard,
                                                            textDetails: temp,
                                                        });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row key={`row-${index + 8}`}>
                                        <Col>
                                            <FormGroup>
                                                <Label>
                                                    <strong>
                                                        Text{" "}<span className="text-danger">*</span>
                                                    </strong>
                                                </Label>
                                                <Input
                                                    type="textarea"
                                                    name="text"
                                                    value={item.text}
                                                    placeholder="Enter Text"
                                                    disabled={allowEdit()}
                                                    onChange={(option) => {
                                                        let temp = [...quickReplyTypeCard.textDetails];
                                                        temp[index].text = option.target.value !== "" ? option.target.value : null;
                                                        setQuickReplyTypeCard({
                                                            ...quickReplyTypeCard,
                                                            textDetails: temp,
                                                        });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Card>
                            ))}
                        </CardBody>
                    </Card>

                </CardBody>
            </Card>


            <QuickReplyCTAItems
                quickReplyTypeCard={quickReplyTypeCard}
                setQuickReplyTypeCard={setQuickReplyTypeCard}
                clientOptions={clientOptions}
                formName={formName}
                updateAllowed={updateAllowed}
                disableAddEditActionButton={disableAddEditActionButton}
                validateColor={validateColor}
            />

            <QuickReplyActions
                quickReplyTypeCard={quickReplyTypeCard}
                setQuickReplyTypeCard={setQuickReplyTypeCard}
                clientOptions={clientOptions}
                formName={formName}
                updateAllowed={updateAllowed}
                disableAddEditActionButton={disableAddEditActionButton}
            />


            {/* ClickTrackers */}
            <Row>
                <Col md={6}>
                    <h1 className="h3 mb-3">Click Trackers</h1>
                </Col>
                <Col md={6}>
                    <Button className="float-right" onClick={addClickTracker} disabled={allowEdit()}>
                        <FontAwesomeIcon size="sm" icon={faPlus}></FontAwesomeIcon>
                        {" "}Add Click Tracker
                    </Button>
                </Col>
            </Row>

            <Card>
                <CardBody>
                    {quickReplyTypeCard?.clickTrackers?.map((item, index) => (
                        <Card key={`card-${index}`} className="p-3 mb-5" style={{
                            boxShadow: '0 0 20px rgba(0,0,0,0.4)',  // Increased shadow
                            borderRadius: '15px'
                        }}>

                            <Row key={`row-${index}`}>
                                <Col>
                                    <FormGroup>
                                        <Label>
                                            <strong>
                                                Type
                                            </strong>
                                        </Label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            name="type"
                                            value={item.type}
                                            placeholder="Select type"
                                            isClearable={false}

                                            options={trackersTypeOptions}
                                            onChange={(option) => {
                                                let temp = [...quickReplyTypeCard.clickTrackers];
                                                temp[index].type = option;
                                                setQuickReplyTypeCard({
                                                    ...quickReplyTypeCard,
                                                    clickTrackers: temp,
                                                });
                                            }}
                                            isDisabled={allowEdit()}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                {!(allowEdit()) && (
                                    <X
                                    className="float-right"
                                    size={20}
                                    color="red"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                        let temp = [...quickReplyTypeCard.clickTrackers];
                                        temp = temp.filter((_, idx) => idx !== index);
                                        setQuickReplyTypeCard({
                                            ...quickReplyTypeCard,
                                            clickTrackers: temp,
                                        });
                                    }}
                                ></X>
                                )}
                                </Col>
                            </Row>
                            <Row key={`row-${index + 7}`}>
                                <Col>
                                    <FormGroup>
                                        <Label>
                                            <strong>
                                                URL <span className="text-danger">*</span>
                                            </strong>
                                        </Label>
                                        <Input
                                            type="textarea"
                                            name="url"
                                            value={item.url}
                                            placeholder="Enter URL"
                                            onChange={(option) => {
                                                let temp = [...quickReplyTypeCard.clickTrackers];
                                                temp[index].url = option.target.value;
                                                setQuickReplyTypeCard({
                                                    ...quickReplyTypeCard,
                                                    clickTrackers: temp,
                                                });
                                            }}
                                            required
                                            disabled={allowEdit()}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Card>


                    ))}
                </CardBody>
            </Card>

            {/* ImpressionTrackers */}
            <Row>
                <Col md={6}>
                    <h1 className="h3 mb-3">Impression Trackers</h1>
                </Col>
                <Col md={6}>
                    <Button className="float-right" onClick={addImpressionTracker} disabled={allowEdit()}>
                        <FontAwesomeIcon size="sm" icon={faPlus}></FontAwesomeIcon>
                        {" "}Add Impression Tracker
                    </Button>
                </Col>
            </Row>

            <Card>
                <CardBody>
                    {quickReplyTypeCard?.impressionTrackers?.map((item, index) => (
                        <Card key={`card-${index}`} className="p-3 mb-5" style={{
                            boxShadow: '0 0 20px rgba(0,0,0,0.4)',  // Increased shadow
                            borderRadius: '15px'
                        }}>

                            <Row key={`row-${index}`}>
                                <Col>
                                    <FormGroup>
                                        <Label>
                                            <strong>
                                                Type
                                            </strong>
                                        </Label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            name="type"
                                            value={item.type}
                                            placeholder="Select type"
                                            isClearable={false}

                                            options={trackersTypeOptions}
                                            onChange={(option) => {
                                                let temp = [...quickReplyTypeCard.impressionTrackers];
                                                temp[index].type = option;
                                                setQuickReplyTypeCard({
                                                    ...quickReplyTypeCard,
                                                    impressionTrackers: temp,
                                                });
                                            }}
                                            isDisabled={allowEdit()}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                {!(allowEdit()) && (
                                    <X
                                    className="float-right"
                                    size={20}
                                    color="red"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                        let temp = [...quickReplyTypeCard.impressionTrackers];
                                        temp = temp.filter((_, idx) => idx !== index);
                                        setQuickReplyTypeCard({
                                            ...quickReplyTypeCard,
                                            impressionTrackers: temp,
                                        });
                                    }}
                                ></X>
                                )}
                                    

                                </Col>
                            </Row>
                            <Row key={`row-${index}`}>
                                <Col>
                                    <FormGroup>
                                        <Label>
                                            <strong>
                                                URL <span className="text-danger">*</span>
                                            </strong>
                                        </Label>
                                        <Input
                                            type="textarea"
                                            name="url"
                                            value={item.url}
                                            placeholder="Enter URL"
                                            onChange={(option) => {
                                                let temp = [...quickReplyTypeCard.impressionTrackers];
                                                temp[index].url = option.target.value;
                                                setQuickReplyTypeCard({
                                                    ...quickReplyTypeCard,
                                                    impressionTrackers: temp,
                                                });
                                            }}
                                            required
                                            disabled={allowEdit()}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Card>
                    ))}
                </CardBody>
            </Card>


        </Container>

    )
}

export default QuickReplyTypeCard
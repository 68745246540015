import React, { useState, useEffect } from "react";
import Select from "react-select";
import { FaPlus, FaMinus } from "react-icons/fa";
import { Row, Col, Label, Button, FormGroup, Input } from "reactstrap";
import CreatableSelect from 'react-select/creatable';

const UserAttributeRule = (props) => {
    const [filter, setFilter] = useState({ label: "Premium User Status", value: "flt_premium_user" });

    const _addRule = () => {
        props.addRule();
    };

    const _removeRule = () => {
        props.removeRule(props.rule.filterIndexId);
    };

    useEffect(() => {
        if (props.rule.ruleIndexId > -1 && props.rule.type) {
            const filterOption = props.getFilters.find((filter) => filter.value === props.rule.type);
            setFilter(filterOption);
        }
    }, [props.rule.ruleIndexId, props.rule.type]);

    return (
        <>
            <Row className="mr-1 mb-2">
                <Col md={4}>
                    <Label className="font-weight-bolder mb-2"
                        style={{ fontSize: "16px" }}>Filter</Label>
                    <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        name="filters"
                        placeholder="Select Filter"
                        value={filter}
                        options={props.getFilters}
                        onChange={(option) => {
                            props.handleChange(props.filterIndex, "type", option.value);
                        }}
                        required
                    />
                </Col>
                <Col md={7}>
                    {filter && filter?.value === "flt_premium_user" && (<div>
                        <Label className="font-weight-bolder mb-2"
                            style={{ fontSize: "16px" }}>Config</Label>
                        <div
                            className="custom-switch custom-control mt-1"
                            style={{ zIndex: 0 }}
                        >
                            <input
                                type="checkbox"
                                id={`premiumUserSwitch-${props.rule.ruleIndexId}-${props.filterIndex}`}
                                name="state"
                                className="custom-control-input"
                                checked={props.rule?.config?.state || false}
                                onChange={(e) => {
                                    props.handleConfigChange(props.filterIndex, "state", e.target.checked);
                                }}
                            />
                            <label className="custom-control-label" htmlFor={`premiumUserSwitch-${props.rule.ruleIndexId}-${props.filterIndex}`}>
                                Is Premium User
                            </label>
                        </div>
                    </div>)}
                    {filter && filter?.value === "flt_premium_stickers" && (<Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label className="font-weight-bolder mb-2"
                                    style={{ fontSize: "16px" }}>
                                    Minimum Number of generated Stickers
                                </Label>
                                <Input
                                    type="number"
                                    name="min_num_stickers"
                                    placeholder="Enter Minimum"
                                    value={props.rule?.config?.min_num_stickers || ""}
                                    onChange={(e) => {
                                        props.handleConfigChange(props.filterIndex, "min_num_stickers", parseInt(e.target.value, 10));
                                    }}
                                    min={0}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label className="font-weight-bolder mb-2"
                                    style={{ fontSize: "16px" }}>
                                    Maximum Number of generated Stickers
                                </Label>
                                <Input
                                    type="number"
                                    name="max_num_stickers"
                                    placeholder="Enter Maximum"
                                    value={props.rule?.config?.max_num_stickers || ""}
                                    onChange={(e) => {
                                        props.handleConfigChange(props.filterIndex, "max_num_stickers", parseInt(e.target.value, 10));
                                    }}
                                    min={1}
                                />
                            </FormGroup>
                        </Col>
                    </Row>)}
                    {filter && (filter?.value === "flt_premium_purchased_product" || filter?.value === "flt_premium_recommended_product") && (
                        <FormGroup>
                            <Label className="font-weight-bolder mb-2"
                                style={{ fontSize: "16px" }}>Products </Label>
                            <Select
                                id="products"
                                className="react-select-container"
                                classNamePrefix="react-select"
                                placeholder="Select Products"
                                name="products"
                                value={props.rule?.config?.productIds ? props.getProducts.filter((product) => props.rule?.config?.productIds.includes(product.value)) : []}
                                onChange={(selectedOptions) => {
                                    props.handleConfigChange(props.filterIndex, "productIds", selectedOptions ? selectedOptions.map(option => option.value) : []);
                                }}
                                options={props.getProducts}
                                isMulti
                            />
                        </FormGroup>)}
                    {filter && (filter?.value === "flt_segments") && (
                        <FormGroup>
                            <Label className="font-weight-bolder mb-2"
                                style={{ fontSize: "16px" }}>Segments </Label>
                            <Select
                                id="segments"
                                className="react-select-container"
                                classNamePrefix="react-select"
                                placeholder="Select Segments"
                                name="segments"
                                value={props.rule?.config?.segmentIds ? props.getSegments.filter((segment) => props.rule?.config?.segmentIds.includes(segment.value)) : []}
                                onChange={(selectedOptions) => {
                                    props.handleConfigChange(props.filterIndex, "segmentIds", selectedOptions ? selectedOptions.map(option => option.value) : []);
                                }}
                                options={props.getSegments}
                                isMulti
                            />
                        </FormGroup>)}
                    {filter && (filter?.value === "flt_blacklist_segments") && (
                        <FormGroup>
                            <Label className="font-weight-bolder mb-2"
                                style={{ fontSize: "16px" }}>Blacklist Segments </Label>
                            <Select
                                id="blacklistSegments"
                                className="react-select-container"
                                classNamePrefix="react-select"
                                placeholder="Select Blacklist Segments"
                                name="blacklistSegments"
                                value={props.rule?.config?.segmentIds ? props.getSegments.filter((segment) => props.rule?.config?.segmentIds.includes(segment.value)) : []}
                                onChange={(selectedOptions) => {
                                    props.handleConfigChange(props.filterIndex, "segmentIds", selectedOptions ? selectedOptions.map(option => option.value) : []);
                                }}
                                options={props.getSegments}
                                isMulti
                            />
                        </FormGroup>)}
                    {filter && (filter?.value === "flt_disallowed_package") && (
                        <FormGroup>
                            <Label className="font-weight-bolder mb-2"
                                style={{ fontSize: "16px" }}>Disallowed Packages </Label>
                            <CreatableSelect
                                id="disallowedPackages"
                                isMulti
                                className="react-select-container"
                                classNamePrefix="react-select"
                                name="disallowedPackages"
                                placeholder="Enter Package Name"
                                value={props.rule?.config?.packageNames?.map(name => ({
                                    label: name,
                                    value: name
                                }))}
                                onChange={(selectedOptions) => {
                                    props.handleConfigChange(props.filterIndex, "packageNames", selectedOptions ? selectedOptions.map(option => option.value) : []);
                                }}
                                components={{
                                    DropdownIndicator: null,
                                }}
                            />
                        </FormGroup>)}
                </Col>
                <Col md={1} className="align-self-end mb-3">
                    {props.rule.filterIndexId === 0 ? (
                        <Button onClick={_addRule} color="transparent">
                            <FaPlus />
                        </Button>
                    )
                        : (
                            <Button onClick={_removeRule} color="transparent">
                                <FaMinus />
                            </Button>
                        )}
                </Col>
            </Row>
            <hr></hr>
        </>
    );
};

export default UserAttributeRule;

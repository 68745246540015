import React, { Fragment, useState, useEffect } from "react";
import ResponseModal from "../ResponseModal";
import {
  getKeyboardTheme,
  createKeyboardTheme,
  editKeyboardTheme,
} from "../../../utilities/apiUtils/themes";
import KeyboardThemeForm from "./ThemeForm";
import Routes from "../../../routes/index";
import {
  errorDescription,
  error,
  redirectTo,
  redirectionOnTokenExpiry,
} from "../../../utilities/commonUtil";
import {
  successModalHeading,
  failureModalHeading,
  themeCreatedSuccessfullyText,
  themeUpdatedSuccessfullyText,
} from "../../../config/themes";

const KeyboardThemeFormContainer = (props) => {
  const [formTextState, setFormTextState] = useState({
    formName: "Create Keyboard Theme",
    name: "",
    type: "",
    brandCampaignID: 0,
    priority: { value: 1, valid: true },
    categoryID: { value: "", valid: true },
    isPremium: false, // Added isPremium field
    previewImage: null,
    backgroundImage: null,
    spaceKeyAnimation: null,
    spaceKeySound: null,
    defaultKeySound: null,
    deleteKeySound: null,

    previewImageSrc: "",
    backgroundImageSrc: "",
    spaceKeyAnimationSrc: "",
    spaceKeySoundSrc: "",
    defaultKeySoundSrc: "",
    deleteKeySoundSrc: "",
    min_android_app_version: { value: 1.0, valid: true },
    min_ios_app_version: { value: 1.0, valid: true },

    contentBarColor: { value: "#0D000000", valid: true },
    panelBackgroundColor: { value: "#0D000000", valid: true },
    suggestionDrawerCategoryButtonColor: { value: "#0D000000", valid: true },
    suggestionDrawerCategoryButtonTextColor: {
      value: "#0D000000",
      valid: true,
    },
    suggestionDrawerCategorySelectedButtonColor: {
      value: "#0D000000",
      valid: true,
    },
    suggestionDrawerCategorySelectedButtonTextColor: {
      value: "#0D000000",
      valid: true,
    },
    keyboardBackgroundColor: { value: "#cc000000", valid: true },
    keyPopUpPreviewBackgroundColor: { value: "#CD263238", valid: true },
    functionalTextColor: { value: "#FFFFFF", valid: true },
    keyBackgroundColor: { value: "#33ffffff", valid: true },
    keyTextColor: { value: "#FFFFFF", valid: true },
    keyboardOverlayOpacity: { value: 0.2, valid: true },
    keyPopupSelectionColor: { value: "#0F0F0F", valid: true },
    keyPopupExpandedBackgroundColor: { value: "#060606", valid: true },
    topBarBackgroundColor: { value: "#99000000", valid: true },
    suggestionsColorAutoCorrect: { value: "#00F0DC", valid: true },
    suggestionsColorSuggested: { value: "#C7C7C7", valid: true },
    suggestionsColorTypedWord: { value: "#FFFFFF", valid: true },
    suggestionsColorValidTypedWord: { value: "#FFFFFF", valid: true },
    swipeGestureTrailColor: { value: "#00E0FF", valid: true },
    topKeyTextColor: { value: "#FFFFFF", valid: true },
    isLightTheme: false,
    functionalKeyBackgroundColor: { value: "#0cffffff", valid: true },
    actionColor: { value: "#00D1FF", valid: true },
    selectedIconColor: { value: "#00F6FF", valid: true },
    keyBorderStrokeColor: { value: "#99000000", valid: true },
    keyBorderStrokeWidth: { value: 1, valid: true },
    keyShadowRadius: { value: 1, valid: true },

    enableKeyboardKeySound: null,
    enableKeyboardKeyBorder: null,
    enableKeyboardTopKeys: null,
    enableKeyboardKeyPopup: null,

    featureSubscriptions: null,
    selectionPromptDetails: null,
    usagePromptDetails: null,
    impressionTrackers: null,
    startDate: null,
    endDate: null,
  });
  const [showForm, setShowForm] = useState(false);
  const [successModalState, setSuccessModalState] = useState({
    successModalDisplay: false,
    successModalText: "",
  });
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });

  useEffect(() => {
    const getTheme = async () => {
      let themeId = props.match.params.id;

      // If ID is present, fetch the theme details for edit
      if (themeId) {
        try {
          let response = await getKeyboardTheme(themeId);
          if (response.theme) {
            // Set the theme values in state (overwrite the default values with fetched values)
            setFormTextState((prevState) => ({
              ...prevState,
              formName: "Edit Keyboard Theme",
              name: response.theme.name,
              type: response.theme.type,
              categoryID: {
                value: response.theme.categoryID,
                valid: true,
              },
              brandCampaignID: response.theme.brandCampaignID ?? 0,
              isPremium: response.theme.isPremium, // Set isPremium value
              previewImage: null,
              previewImageSrc: response.theme.previewImage,
              backgroundImage: null,
              backgroundImageSrc: response.theme.backgroundImage,


              backspaceKeyBackgroundImage: null,
              backspaceKeyBackgroundImageSrc: response.theme.backspaceKeyBackgroundImage,
          
              functionalKeyBackgroundImage: null,
              functionalKeyBackgroundImageSrc: response.theme.functionalKeyBackgroundImage,
          
              enterKeyBackgroundImage: null,
              enterKeyBackgroundImageSrc: response.theme.enterKeyBackgroundImage,
          
              shiftKeyBackgroundImage: null,
              shiftKeyBackgroundImageSrc: response.theme.shiftKeyBackgroundImage,
          
              spaceKeyBackgroundImage: null,
              spaceKeyBackgroundImageSrc: response.theme.spaceKeyBackgroundImage,
          
              spaceKeyLogoImage: null,
              spaceKeyLogoImageSrc: response.theme.spaceKeyLogoImage,
          
              keyBackgroundImage: null,
              keyBackgroundImageSrc: response.theme.keyBackgroundImage,
          
              functionalEmojiPanelKeyBackgroundImage: null,
              functionalEmojiPanelKeyBackgroundImageSrc: response.theme.functionalEmojiPanelKeyBackgroundImage,
          
              functionalLanguageSwitcherKeyBackgroundImage: null,
              functionalLanguageSwitcherKeyBackgroundImageSrc: response.theme.functionalLanguageSwitcherKeyBackgroundImage,

              spaceKeyAnimation: null,
              spaceKeyAnimationSrc: response.theme.spaceKeyAnimation,
              spaceKeySound: null,
              spaceKeySoundSrc: response.theme.spaceKeySound,
              defaultKeySound: null,
              defaultKeySoundSrc: response.theme.defaultKeySound,
              deleteKeySound: null,
              deleteKeySoundSrc: response.theme.deleteKeySound,

              contentBarColor: {
                value: response.theme.contentBarColor,
                valid: true,
              },
              panelBackgroundColor: {
                value: response.theme.panelBackgroundColor,
                valid: true,
              },
              suggestionDrawerCategoryButtonColor: {
                value: response.theme.suggestionDrawerCategoryButtonColor,
                valid: true,
              },
              suggestionDrawerCategoryButtonTextColor: {
                value: response.theme.suggestionDrawerCategoryButtonTextColor,
                valid: true,
              },
              suggestionDrawerCategorySelectedButtonColor: {
                value:
                  response.theme.suggestionDrawerCategorySelectedButtonColor,
                valid: true,
              },
              suggestionDrawerCategorySelectedButtonTextColor: {
                value:
                  response.theme
                    .suggestionDrawerCategorySelectedButtonTextColor,
                valid: true,
              },
              keyboardBackgroundColor: {
                value: response.theme.keyboardBackgroundColor,
                valid: true,
              },
              keyPopUpPreviewBackgroundColor: {
                value: response.theme.keyPopUpPreviewBackgroundColor,
                valid: true,
              },
              functionalTextColor: {
                value: response.theme.functionalTextColor,
                valid: true,
              },
              keyBackgroundColor: {
                value: response.theme.keyBackgroundColor,
                valid: true,
              },
              keyTextColor: {
                value: response.theme.keyTextColor,
                valid: true,
              },
              keyboardOverlayOpacity: {
                value: response.theme.keyboardOverlayOpacity,
                valid: true,
              },
              keyPopupSelectionColor: {
                value: response.theme.keyPopupSelectionColor,
                valid: true,
              },
              keyPopupExpandedBackgroundColor: {
                value: response.theme.keyPopupExpandedBackgroundColor,
                valid: true,
              },
              topBarBackgroundColor: {
                value: response.theme.topBarBackgroundColor,
                valid: true,
              },
              suggestionsColorAutoCorrect: {
                value: response.theme.suggestionsColorAutoCorrect,
                valid: true,
              },
              suggestionsColorSuggested: {
                value: response.theme.suggestionsColorSuggested,
                valid: true,
              },
              suggestionsColorTypedWord: {
                value: response.theme.suggestionsColorTypedWord,
                valid: true,
              },
              suggestionsColorValidTypedWord: {
                value: response.theme.suggestionsColorValidTypedWord,
                valid: true,
              },
              swipeGestureTrailColor: {
                value: response.theme.swipeGestureTrailColor,
                valid: true,
              },
              topKeyTextColor: {
                value: response.theme.topKeyTextColor,
                valid: true,
              },
              isLightTheme:
                String(response.theme.isLightTheme) !== "undefined"
                  ? response.theme.isLightTheme
                  : null,
              functionalKeyBackgroundColor: {
                value: response.theme.functionalKeyBackgroundColor,
                valid: true,
              },
              actionColor: { value: response.theme.actionColor, valid: true },
              selectedIconColor: {
                value: response.theme.selectedIconColor,
                valid: true,
              },
              keyBorderStrokeColor: {
                value: response.theme.keyBorderStrokeColor,
                valid: true,
              },
              keyBorderStrokeWidth: {
                value: response.theme.keyBorderStrokeWidth,
                valid: true,
              },
              keyShadowRadius: {
                value: response.theme.keyShadowRadius,
                valid: true,
              },

              priority: { value: response.theme.priority, valid: true },
              min_android_app_version: {
                value: response.theme.minAndroidAppVersion,
                valid: true,
              },
              min_ios_app_version: {
                value: response.theme.minAppleAppVersion,
                valid: true,
              },

              enableKeyboardKeySound: response.theme
                ? String(response.theme.keyboardKeySound) !== "undefined"
                  ? response.theme.keyboardKeySound
                  : null
                : null,
              enableKeyboardKeyBorder: response.theme
                ? String(response.theme.keyboardKeyBorder) !== "undefined"
                  ? response.theme.keyboardKeyBorder
                  : null
                : null,
              enableKeyboardTopKeys: response.theme
                ? String(response.theme.keyboardTopKeys) !== "undefined"
                  ? response.theme.keyboardTopKeys
                  : null
                : null,
              enableKeyboardKeyPopup: response.theme
                ? String(response.theme.keyboardKeyPopup) !== "undefined"
                  ? response.theme.keyboardKeyPopup
                  : null
                : null,

              featureSubscriptions: response.theme.featureSubscriptions,
              selectionPromptDetails: response.theme.selectionPromptDetails,
              usagePromptDetails: response.theme.usagePromptDetails,
              impressionTrackers: response.theme.impressionTrackers,
              startDate: response.theme.startDate,
              endDate: response.theme.endDate,
            }));
            // For keyboard theme edit form
            setShowForm(true);
            return;
          }
          // Handle error
          if (response.errorCode) {
            if (
              response.errorCode === error.tokenExpired ||
              response.errorCode === error.invalidAccessToken
            )
              redirectionOnTokenExpiry();
            setFailureModalState({
              failureModalDisplay: true,
              failureModalText: errorDescription(response.errorCode),
            });
          }
        } catch (err) {
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: errorDescription(error.unexpectedError),
          });
        }
      }
      // For keyboard theme create form
      setShowForm(true);
    };

    getTheme();
  }, []);

  const onSubmit = async (themeObj) => {
    let formData = new FormData();

    // Append values in Form data
    formData.append("name", themeObj.name);
    formData.append("categoryID", themeObj.categoryID.value);
    formData.append("brandCampaignID", themeObj.brandCampaignID);
    formData.append("type", themeObj.type);
    formData.append("isPremium", themeObj.isPremium); // Include isPremium in form data
    if (themeObj.startDateEpoch && themeObj.startDateEpoch !== "NaN")
      formData.append("startDate", themeObj.startDateEpoch.toString());
    if (themeObj.endDateEpoch && themeObj.endDateEpoch !== "NaN")
      formData.append("endDate", themeObj.endDateEpoch.toString());

    if (themeObj.backgroundImage)
      formData.append("backgroundImage", themeObj.backgroundImage);
    else if (themeObj.backgroundImageSrc)
      formData.append("backgroundImageSrc", themeObj.backgroundImageSrc);

    if (themeObj.previewImage)
      formData.append("previewImage", themeObj.previewImage);
    else if (themeObj.previewImageSrc)
      formData.append("previewImageSrc", themeObj.previewImageSrc);

    if (themeObj.backspaceKeyBackgroundImage)
      formData.append("backspaceKeyBackgroundImage", themeObj.backspaceKeyBackgroundImage);
    else if (themeObj.backspaceKeyBackgroundImageSrc)
      formData.append("backspaceKeyBackgroundImageSrc", themeObj.backspaceKeyBackgroundImageSrc);

    if (themeObj.functionalKeyBackgroundImage)
      formData.append("functionalKeyBackgroundImage", themeObj.functionalKeyBackgroundImage);
    else if (themeObj.functionalKeyBackgroundImageSrc)
      formData.append("functionalKeyBackgroundImageSrc", themeObj.functionalKeyBackgroundImageSrc);

    if (themeObj.enterKeyBackgroundImage)
      formData.append("enterKeyBackgroundImage", themeObj.enterKeyBackgroundImage);
    else if (themeObj.enterKeyBackgroundImageSrc)
      formData.append("enterKeyBackgroundImageSrc", themeObj.enterKeyBackgroundImageSrc);

    if (themeObj.shiftKeyBackgroundImage)
      formData.append("shiftKeyBackgroundImage", themeObj.shiftKeyBackgroundImage);
    else if (themeObj.shiftKeyBackgroundImageSrc)
      formData.append("shiftKeyBackgroundImageSrc", themeObj.shiftKeyBackgroundImageSrc);

    if (themeObj.spaceKeyBackgroundImage)
      formData.append("spaceKeyBackgroundImage", themeObj.spaceKeyBackgroundImage);
    else if (themeObj.spaceKeyBackgroundImageSrc)
      formData.append("spaceKeyBackgroundImageSrc", themeObj.spaceKeyBackgroundImageSrc);

    if (themeObj.spaceKeyLogoImage)
      formData.append("spaceKeyLogoImage", themeObj.spaceKeyLogoImage);
    else if (themeObj.spaceKeyLogoImageSrc)
      formData.append("spaceKeyLogoImageSrc", themeObj.spaceKeyLogoImageSrc);

    if (themeObj.keyBackgroundImage)
      formData.append("keyBackgroundImage", themeObj.keyBackgroundImage);
    else if (themeObj.keyBackgroundImageSrc)
      formData.append("keyBackgroundImageSrc", themeObj.keyBackgroundImageSrc);

    if (themeObj.functionalEmojiPanelKeyBackgroundImage)
      formData.append("functionalEmojiPanelKeyBackgroundImage", themeObj.functionalEmojiPanelKeyBackgroundImage);
    else if (themeObj.functionalEmojiPanelKeyBackgroundImageSrc)
      formData.append("functionalEmojiPanelKeyBackgroundImageSrc", themeObj.functionalEmojiPanelKeyBackgroundImageSrc);

    if (themeObj.functionalLanguageSwitcherKeyBackgroundImage)
      formData.append("functionalLanguageSwitcherKeyBackgroundImage", themeObj.functionalLanguageSwitcherKeyBackgroundImage);
    else if (themeObj.functionalLanguageSwitcherKeyBackgroundImageSrc)
      formData.append("functionalLanguageSwitcherKeyBackgroundImageSrc", themeObj.functionalLanguageSwitcherKeyBackgroundImageSrc);

    if (themeObj.spaceKeyAnimation)
      formData.append("spaceKeyAnimation", themeObj.spaceKeyAnimation);
    else if (themeObj.spaceKeyAnimationSrc)
      formData.append("spaceKeyAnimationSrc", themeObj.spaceKeyAnimationSrc);

    if (themeObj.spaceKeySound)
      formData.append("spaceKeySound", themeObj.spaceKeySound);
    else if (themeObj.spaceKeySoundSrc)
      formData.append("spaceKeySoundSrc", themeObj.spaceKeySoundSrc);

    if (themeObj.defaultKeySound)
      formData.append("defaultKeySound", themeObj.defaultKeySound);
    else if (themeObj.defaultKeySound)
      formData.append("defaultKeySoundSrc", themeObj.defaultKeySoundSrc);

    if (themeObj.deleteKeySound)
      formData.append("deleteKeySound", themeObj.deleteKeySound);
    else if (themeObj.deleteKeySoundSrc)
      formData.append("deleteKeySoundSrc", themeObj.deleteKeySoundSrc);

    formData.append("priority", themeObj.priority.value);
    formData.append(
      "minAndroidAppVersion",
      themeObj.min_android_app_version.value
    );
    formData.append("minAppleAppVersion", themeObj.min_ios_app_version.value);
    formData.append(
      "suggestionsColorSuggested",
      themeObj.suggestionsColorSuggested.value
    );
    formData.append(
      "keyboardBackgroundColor",
      themeObj.keyboardBackgroundColor.value
    );
    formData.append(
      "keyPopupSelectionColor",
      themeObj.keyPopupSelectionColor.value
    );
    formData.append(
      "keyPopUpPreviewBackgroundColor",
      themeObj.keyPopUpPreviewBackgroundColor.value
    );
    formData.append(
      "suggestionsColorTypedWord",
      themeObj.suggestionsColorTypedWord.value
    );
    formData.append("selectedIconColor", themeObj.selectedIconColor.value);
    formData.append(
      "swipeGestureTrailColor",
      themeObj.swipeGestureTrailColor.value
    );
    formData.append(
      "suggestionsColorAutoCorrect",
      themeObj.suggestionsColorAutoCorrect.value
    );
    formData.append(
      "keyPopupExpandedBackgroundColor",
      themeObj.keyPopupExpandedBackgroundColor.value
    );
    formData.append("keyBackgroundColor", themeObj.keyBackgroundColor.value);
    formData.append(
      "topBarBackgroundColor",
      themeObj.topBarBackgroundColor.value
    );
    formData.append("topKeyTextColor", themeObj.topKeyTextColor.value);
    formData.append("actionColor", themeObj.actionColor.value);
    formData.append(
      "functionalKeyBackgroundColor",
      themeObj.functionalKeyBackgroundColor.value
    );
    formData.append("isLightTheme", themeObj.isLightTheme);
    formData.append("contentBarColor", themeObj.contentBarColor.value);
    if (themeObj.panelBackgroundColor.value && themeObj.panelBackgroundColor.value.trim() !== "") {
      formData.append(
        "panelBackgroundColor",
        themeObj.panelBackgroundColor.value
      );
    }
    if (themeObj.suggestionDrawerCategoryButtonColor.value && themeObj.suggestionDrawerCategoryButtonColor.value.trim() !== "") {
      formData.append(
        "suggestionDrawerCategoryButtonColor",
        themeObj.suggestionDrawerCategoryButtonColor.value
      );
    }
    if (themeObj.suggestionDrawerCategoryButtonTextColor.value && themeObj.suggestionDrawerCategoryButtonTextColor.value.trim() !== "") {
      formData.append(
        "suggestionDrawerCategoryButtonTextColor",
        themeObj.suggestionDrawerCategoryButtonTextColor.value
      );
    }
    if (themeObj.suggestionDrawerCategorySelectedButtonColor.value && themeObj.suggestionDrawerCategorySelectedButtonColor.value.trim() !== "") {
      formData.append(
        "suggestionDrawerCategorySelectedButtonColor",
        themeObj.suggestionDrawerCategorySelectedButtonColor.value
      );
    }
    if (themeObj.suggestionDrawerCategorySelectedButtonTextColor.value && themeObj.suggestionDrawerCategorySelectedButtonTextColor.value.trim() !== "") {
      formData.append(
        "suggestionDrawerCategorySelectedButtonTextColor",
        themeObj.suggestionDrawerCategorySelectedButtonTextColor.value
      );
    }
    formData.append(
      "suggestionsColorValidTypedWord",
      themeObj.suggestionsColorValidTypedWord.value
    );
    formData.append("functionalTextColor", themeObj.functionalTextColor.value);
    formData.append(
      "keyboardOverlayOpacity",
      themeObj.keyboardOverlayOpacity.value
    );
    formData.append("keyTextColor", themeObj.keyTextColor.value);
    formData.append("keyShadowRadius", themeObj.keyShadowRadius.value);

    if (themeObj.keyBorderStrokeWidth.value)
      formData.append(
        "keyBorderStrokeWidth",
        themeObj.keyBorderStrokeWidth.value
      );
    if (themeObj.keyBorderStrokeColor.value)
      formData.append(
        "keyBorderStrokeColor",
        themeObj.keyBorderStrokeColor.value
      );

    if (
      themeObj.enableKeyboardKeySound != null &&
      themeObj.enableKeyboardKeySound.length != 0
    ) {
      formData.append(
        "enableKeyboardKeySound",
        themeObj.enableKeyboardKeySound
      );
    }

    if (
      themeObj.enableKeyboardKeyBorder != null &&
      themeObj.enableKeyboardKeyBorder.length != 0
    ) {
      formData.append(
        "enableKeyboardKeyBorder",
        themeObj.enableKeyboardKeyBorder
      );
    }

    if (
      themeObj.enableKeyboardTopKeys != null &&
      themeObj.enableKeyboardTopKeys.length != 0
    ) {
      formData.append("enableKeyboardTopKeys", themeObj.enableKeyboardTopKeys);
    }
    if (
      themeObj.enableKeyboardKeyPopup != null &&
      themeObj.enableKeyboardKeyPopup.length != 0
    ) {
      formData.append(
        "enableKeyboardKeyPopup",
        themeObj.enableKeyboardKeyPopup
      );
    }
    if (themeObj.featureSubscriptions)
      formData.append(
        "featureSubscriptions",
        JSON.stringify(themeObj.featureSubscriptions)
      );

    if (themeObj.selectionPromptDetails)
      formData.append(
        "selectionPromptDetails",
        JSON.stringify(themeObj.selectionPromptDetails)
      );
    if (themeObj.usagePromptDetails)
      formData.append(
        "usagePromptDetails",
        JSON.stringify(themeObj.usagePromptDetails)
      );
    if (themeObj.impressionTrackers)
      formData.append(
        "impressionTrackers",
        JSON.stringify(themeObj.impressionTrackers)
      );

    // API call for create theme
    if (formTextState.formName === "Create Keyboard Theme") {
      try {
        let response = await createKeyboardTheme(formData);
        if (response.errorCode) {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken
          ) {
            redirectionOnTokenExpiry();
            setFailureModalState({
              failureModalDisplay: true,
              failureModalText: errorDescription(response.errorCode),
            });
            return;
          }
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: response.errorDescription,
          });
        } else {
          setSuccessModalState({
            successModalDisplay: true,
            successModalText: themeCreatedSuccessfullyText,
          });
        }
      } catch (err) {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      }
    } else {
      // API call for edit theme
      formData.append("themeId", props.match.params.id);
      try {
        let response = await editKeyboardTheme(formData, props.match.params.id);
        if (response.errorCode) {
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: response.errorDescription,
          });
        } else {
          setSuccessModalState({
            successModalDisplay: true,
            successModalText: themeUpdatedSuccessfullyText,
          });
        }
      } catch (err) {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      }
    }
  };

  const failureModalClose = () => {
    setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
  };

  const successModalClose = () => {
    setSuccessModalState((prev) => ({ ...prev, successModalDisplay: false }));
    redirectTo(Routes.keyboardThemes.path);
  };

  return (
    <Fragment>
      {showForm ? (
        <KeyboardThemeForm
          theme={formTextState}
          submit={onSubmit}
          updateAllowed={props.updateAllowed}
        />
      ) : null}
      <ResponseModal
        show={failureModalState.failureModalDisplay}
        onHide={() => failureModalClose()}
        modalheading={failureModalHeading}
        modaltext={failureModalState.failureModalText}
      />
      <ResponseModal
        show={successModalState.successModalDisplay}
        onHide={() => successModalClose()}
        modalheading={successModalHeading}
        modaltext={successModalState.successModalText}
      />
    </Fragment>
  );
};

export default KeyboardThemeFormContainer;

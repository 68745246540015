export const fileSizeExceedError = "File is too large";

export const invalidAspectRatioError = "Aspect ratio is not accepted";

export const imageFormatError = "Please upload in accepted format";

export const staticIconError = "Please upload static icon";

export const aspectRatioError = "Aspect ratio is not 1:1";

export const landscapeImageError = "Image height exceeds its width; a landscape image is expected";

export const imageDimensionError = "Invalid image dimensions";

export const acceptedFormats = ["image/png", "image/jpeg", "image/webp"];

export const largeIconMinWidth = 72;
export const largeIconMaxWidth = 256;

export const smallIconMinWidth = 48;
export const smallIconMaxWidth = 96;

export const bigPictureMaxWidth = 1024;
export const bigPictureMaxHeight = 512;

export const largeIconSize = 500000; 

export const bigPictureImageSize = 500000; 

export const smallPictureImageSize = 500000; 

export const statusOptions = [
  { value: "", label: "All" },
  { value: "Activated", label: "Activated" },
  { value: "Deactivated", label: "Deactivated" },
  { value: "Unpublished", label: "Unpublished" },
];

export const scheduledOptions = [
  { value: "", label: "All" },
  { value: "send_on_publish", label: "Send on Publish" },
  { value: "recurring", label: "Recurring" },
  { value: "scheduled", label: "Scheduled" },
];

export const layoutTypeOptions = [
  { value: "standard", label: "Standard" },
  { value: "complete_image", label: "Complete Image" },
  { value: "portrait", label: "Portrait" },
];

export const typeOptions = [
  { value: "", label: "All" },
  { value: "default", label: "Default" },
  { value: "custom", label: "Custom" },
];

export const schedulingTypes = {
  SendOnPublish: "send_on_publish",
  Recurring: "recurring",
  Scheduled: "scheduled",
};

export const notificationIntensityOptions = [
  { value: 0, label: "High" },
  { value: 1, label: "Medium" },
  { value: 2, label: "Low" },
];


export const clientOptions = [
  {
    value: "7wZFJWA5chjgat68y826IAIKQ6s197RM",
    label: "Bobble Android",
    target: {
      name: "client",
      value: {
        value: "7wZFJWA5chjgat68y826IAIKQ6s197RM",
        label: "Bobble Android"
      }
    }
  }
]


export const targetRulesClients = [{
  value: "7wZFJWA5chjgat68y826IAIKQ6s197RM",
  label: "Bobble Android App"
}]

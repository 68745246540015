import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import { FaPlus, FaMinus } from "react-icons/fa";
import { Row, Col, FormGroup, Label, Input, Form, Button } from "reactstrap";
import Select, { components } from "react-select";
import { fetchPlacements } from '../../../utilities/apiUtils/configAPI';
import { isValidJSON, providerOptions } from '../../../config/configPlacementProviders';

const PlacementProviderModal = ({ show = false, type, formData, setFormData = () => { }, onSubmit = () => { }, onHide = () => { } }) => {
  const [data, setData] = useState({
    id: "",
    placementId: "",
    providersString: [],
  })
  const [fixedPositionProviders, setFixedPositionProviders] = useState([])
  const [placementOptions, setPlacementOptions] = useState([])

  const fetchPlacementOptions = async () => {
    try {
      let response = await fetchPlacements()
      if (response?.length > 0) {
        setPlacementOptions(response.map(placement => ({
          value: placement.id,
          label: placement.description,
          target: {
            name: "placementId",
            value: {
              value: placement.id,
              label: placement.description
            }
          }
        })))
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchPlacementOptions()
  }, [])

  useEffect(() => {
    if (formData?.id) {
      setData({
        id: formData?.id,
        placementId: formData?.placementId ? {
          label: placementOptions.find(option => option.value === formData?.placementId)?.label ?? formData?.placementId,
          value: formData?.placementId
        } : "",
        providersString: formData?.providersString
          ? isValidJSON(formData?.providersString)
            ? JSON.parse(formData?.providersString)?.map(provider => ({
              value: provider,
              label: providerOptions.find(option => option.value === provider)?.label ?? provider
            }))
            : []
          : [],
      })
      setFixedPositionProviders(formData?.fixedPositionProvidersString
        ? isValidJSON(formData?.fixedPositionProvidersString)
          ? JSON.parse(formData?.fixedPositionProvidersString)?.map(el => ({
            name: el?.name ? {
              value: el?.name,
              label: providerOptions.find(option => option.value === el?.name)?.label ?? el?.name
            } : "",
            position: el?.position,
            priority: el?.priority
          }))
          : []
        : [])
    } else {
      setData({
        id: "",
        placementId: "",
        providersString: "",
      })
      setFixedPositionProviders([])
    }
  }, [formData, placementOptions])

  const handleChange = (event) => {
    const { name, value } = event.target
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleFixedPositionProvidersChange = (event, index) => {
    const { name, value } = event.target
    const temp = [...fixedPositionProviders]
    temp[index][name] = value
    setFixedPositionProviders(temp)
  }

  const addFixedPositionProvider = () => {
    setFixedPositionProviders((prevState) => (
      prevState.concat({
        name: "",
        position: "",
        priority: ""
      })
    ));
  }

  const removeFixedPositionProvider = (index) => {
    setFixedPositionProviders((prevState) => (
      prevState.filter((_, i) => i !== index)
    ));
  }

  const removeAllFixedPositionProviders = (index) => {
    setFixedPositionProviders([]);
  }

  const handleHide = () => {
    setData({
      id: "",
      placementId: "",
      providersString: [],
    })
    setFixedPositionProviders([])
    onHide()
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = {
      id: data.id,
      placementId: data.placementId?.value,
      providersString: JSON.stringify(data.providersString?.map(el => el.value)),
      fixedPositionProvidersString: fixedPositionProviders?.length > 0
        ? JSON.stringify(fixedPositionProviders?.map(el => ({
          name: el.name?.value,
          position: Number(el.position),
          priority: Number(el.priority)
        })))
        : ""
    }
    setFormData(payload)
    onSubmit(payload)
  }

  const requiredComponent = (props) => (
    <div>
      <components.Input {...props} required={true} />
    </div>
  );

  const notRequiredComponent = (props) => (
    <div>
      <components.Input {...props} required={false} />
    </div>
  );

  return (
    <Modal
      show={show}
      onHide={handleHide}
      aria-labelledby="contained-modal-title-vcenter"
      size='lg'
      centered
      backdrop="static"
    >
      <Modal.Header className="bg-light" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {type} Placement Provider
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row form>
            <Col lg={12} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Placement <span className="text-danger">*</span>
                </Label>
                <Select
                  id="placementId"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="placementId"
                  placeholder="Select Placement"
                  value={data.placementId}
                  onChange={handleChange}
                  options={placementOptions}
                  components={{
                    Input:
                      (data.placementId)
                        ? notRequiredComponent
                        : requiredComponent,
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col lg={12} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Providers <span className="text-danger">*</span>
                </Label>
                <Select
                  id="providersString"
                  isMulti
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="providersString"
                  placeholder="Select Providers"
                  value={data.providersString}
                  onChange={(event) => {
                    setData((prevState) => ({
                      ...prevState,
                      providersString: event,
                    }))
                  }}
                  options={providerOptions}
                  components={{
                    Input:
                      (data.providersString?.length > 0)
                        ? notRequiredComponent
                        : requiredComponent,
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <div
                className="font-weight-bolder mb-2"
                style={{ fontSize: "14px" }}
              >
                Fixed Position Providers
              </div>
            </Col>
            <Col>
              {(fixedPositionProviders?.length <= 0) ? (
                <Button color='success' className="float-right" onClick={addFixedPositionProvider}>Add Fixed Position Providers</Button>
              ) :
                <Button color='danger' className="float-right" onClick={removeAllFixedPositionProviders}>Delete Fixed Position Providers</Button>
              }
            </Col>
          </Row>
          {fixedPositionProviders?.map((fixedPositionProvider, index) => (
            <Row key={index} form className="mb-3">
              <Col>
                <FormGroup className="mb-0">
                  <Label
                    className="font-weight-bolder mb-2"
                    style={{ fontSize: "14px" }}
                  >
                    Name <span className="text-danger">*</span>
                  </Label>
                  <Select
                    id="name"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name="name"
                    placeholder="Select Name"
                    value={fixedPositionProvider.name}
                    onChange={(event) => handleFixedPositionProvidersChange(event, index)}
                    options={data.providersString
                      ? data.providersString?.map(provider => ({
                        value: provider.value,
                        label: provider.label,
                        target: {
                          name: "name",
                          value: {
                            value: provider.value,
                            label: provider.label
                          }
                        }
                      })) : []
                    }
                    components={{
                      Input:
                        (fixedPositionProvider.name)
                          ? notRequiredComponent
                          : requiredComponent,
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="mb-0">
                  <Label
                    className="font-weight-bolder mb-2"
                    style={{ fontSize: "14px" }}
                  >
                    Position <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="number"
                    name="position"
                    min={1}
                    placeholder="Position"
                    value={fixedPositionProvider.position}
                    onChange={(event) => handleFixedPositionProvidersChange(event, index)}
                    required
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="mb-0">
                  <Label
                    className="font-weight-bolder mb-2"
                    style={{ fontSize: "14px" }}
                  >
                    Priority <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="number"
                    name="priority"
                    min={1}
                    placeholder="Priority"
                    value={fixedPositionProvider.priority}
                    onChange={(event) => handleFixedPositionProvidersChange(event, index)}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={1} className="align-self-end">
                {index === 0 ? (
                  <Button onClick={addFixedPositionProvider} color="transparent">
                    <FaPlus />
                  </Button>
                ) : (
                  <Button onClick={() => removeFixedPositionProvider(index)} color="transparent">
                    <FaMinus />
                  </Button>
                )}
              </Col>
            </Row>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button color="primary" onClick={handleHide}>Close</Button>
          <Button type='submit' color='success'>Submit</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default PlacementProviderModal
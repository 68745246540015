import React, { memo } from 'react'
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';

const DiffViewer = memo(({ oldValue, newValue }) => {
  return (
    <div className='diff-viewer'>
      <ReactDiffViewer
        oldValue={oldValue}
        newValue={newValue}
        splitView={true}
        leftTitle={"Old version"}
        rightTitle={"New version"}
        compareMethod={DiffMethod.WORDS_WITH_SPACE}
        hideLineNumbers={true}
        styles={{
          contentText: {
            width: "35vw",
          }
        }}
      />
    </div>
  )
})

export default DiffViewer
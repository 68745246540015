import { retrieveFromLocalStorage } from "../localStorageUtil";
import { host, publicAPIHost } from "../apiUtils/index";
import { secureFetch } from "../secureFetch";

export let fetchSmartSuggestionCategories = async () => {
  let url = `${host}/v4/internal/ads/categories`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchSmartSuggestionData = async () => {
  let url = `${host}/v4/internal/ads`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let publishSmartSuggestion = async (id, title = "") => {
  let url = `${host}/v4/internal/ads/${id}/publish?title=${title}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let unpublishSmartSuggestion = async (id, title = "") => {
  let url = `${host}/v4/internal/ads/${id}/unpublish?title=${title}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let activateSmartSuggestion = async (id, title = "") => {
  let url = `${host}/v4/internal/ads/${id}/activate?title=${title}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let deactivateSmartSuggestion = async (id, title = "") => {
  let url = `${host}/v4/internal/ads/${id}/deactivate?title=${title}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let createSmartSuggestion = async (data) => {
  let url = `${host}/v4/internal/ads`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchSmartSuggestionById = async (id) => {
  let url = `${host}/v4/internal/ads/${id}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let updateSmartSuggestionById = async (id, data) => {
  let url = `${host}/v4/internal/ads/${id}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchSmartSuggestionConversionsById = async (id, eventName = "install") => {
  let url = `${host}/v4/internal/ads/${id}/${eventName}/conversions`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchIntentTriggers = async () => {
  let url = `https://api.bobbleapp.asia/v1/intent/list?deviceId=3F3CDF6888142ABA&clientId=7wZFJWA5chjgat68y826IAIKQ6s197RM`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchSmartSuggestionCountries = async () => {
  let url = `${host}/v4/internal/ads/countries`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchSmartSuggestionsOrder = async ({ placementLocation, countryCode }) => {
  let url = `${host}/v4/internal/adsOrder?placementLocation=${placementLocation}&countryCode=${countryCode}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let updateSmartSuggestionsOrder = async ({ placementLocation, countryCode, countryId }, data) => {
  let url = `${host}/v4/internal/adsOrder?placementLocation=${placementLocation}&countryCode=${countryCode}&countryId=${countryId}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let getCountries = async () => {
  let countries = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/ads/countries`;
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.forEach((country) => {
        let countryObj = {
          value: country.id,
          label: country.name,
        };
        countries.push(countryObj);
      });
    }
    return countries;
  } catch (error) {
    return;
  }
};

export let getRegions = async (countryID) => {
  let regions = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/ads/regions?countryID=${countryID}`;
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.forEach((region) => {
        let regionObj = {
          value: region.id,
          label: region.name,
        };
        regions.push(regionObj);
      });
    }
    return regions;
  } catch (error) {
    return;
  }
};

export let getCities = async (countryID, regionID) => {
  let cities = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/ads/cities?countryID=${countryID}&regionID=${regionID}`;
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.forEach((city) => {
        let cityObj = {
          value: city.id,
          label: city.name,
        };
        cities.push(cityObj);
      });
    }
    return cities;
  } catch (error) {
    return;
  }
};

export let fetchSmartSuggestionTargetingRules = async (id) => {
  let rules = [];

  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/ads/${id}/targetingRules`;
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    var res = await response.json();
    if (res) {
      rules = res;
    }
    return rules;
  } catch (error) {
    return;
  }
};

export let saveSmartSuggestionTargetingRules = async (rules, id, data) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/ads/${id}/targetingRules`;
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};


export let fetchServerAdProviders = async () => {
  let url = `${host}/v1/internal/adx/serverAdProviders`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchAdTypes = async () => {
  let url = `${host}/v1/internal/adx/adTypes`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let updatePreferredCategories = async (data) => {
  let url = `${host}/v1/audienceTargeting/internal/ads/userPreferredClusters`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token
      },
      body: data,
    });
    return await response.json();
  } catch (error) {
    return error.json();
  }
};

export let fetchPreferredCategories = async () => {
  let url = `${host}/v1/audienceTargeting/internal/ads/gaidUploadsCount`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let deletePreferredCategories = async () => {
  let url = `${host}/v1/audienceTargeting/internal/ads/userPreferredClusters`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let searchPreferredCategories = async (value) => {
  let url = `${host}/v1/audienceTargeting/internal/ads/getPreferredClusters?gaid=${value}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchFixedPositionsPromotedNonPromotedAds = async (clientId, location, countryCode, adServerVersion = 4, regionCode, advertisingId = "fff7cd16-d2ed-4007-8b98-cfacbd0d77bf") => {
  let url = `${host}/v4/internal/ads?getPartnerAds=true&clientId=${clientId}&location=${location}&countryCode=${countryCode}&adServerVersion=${adServerVersion}&regionCode=${regionCode}&advertisingId=${advertisingId}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchPublicAdsAPI = async (clientId, countryCode, regionCode, adServerVersion = 4, placementId, advertisingId = "fff7cd16-d2ed-4007-8b98-cfacbd0d77bf") => {
  let url = `${publicAPIHost}/v4/ads?clientId=${clientId}&countryCode=${countryCode}&regionCode=${regionCode}&adServerVersion=${adServerVersion}&placementId=${placementId}&advertisingId=${advertisingId}&isDashboardRequest=true&appVersion=999999992&dupAds=0`;
  try {
    var response = await secureFetch(url, {
      method: "GET",
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchProvidersByClientIdLocation = async (clientId, location) => {
  let url = `${host}/v1/internal/adx/providers?clientId=${clientId}&location=${location}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchAllAdsList = async (clientId, location, countryCode, adServerVersion = 4, regionCode, advertisingId = "fff7cd16-d2ed-4007-8b98-cfacbd0d77bf") => {
  let url = `${host}/v4/internal/ads/list?clientId=${clientId}&location=${location}&countryCode=${countryCode}&adServerVersion=${adServerVersion}&regionCode=${regionCode}&advertisingId=${advertisingId}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let updateFixedPosition = async (data) => {
  let url = `${host}/v4/internal/ads/fixedPositions`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let updatePromotedAdsOrder = async (data) => {
  let url = `${host}/v4/internal/ads/promoted/order`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let updatePromoteAd = async (data) => {
  let url = `${host}/v4/internal/ads/promote`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let updateMetadataOverride = async (data) => {
  let url = `${host}/v4/internal/ads/metadata`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let resetMetadataOverride = async (id, title = "", adMetadata) => {
  let url = `${host}/v4/internal/ads/metadata/${id}?title=${title}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(adMetadata),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchPlacementWithLocationClientId = async (location, clientId) => {
  let url = `${host}/v4/internal/ads/placement?location=${location}&clientId=${clientId}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let updateStoryAd = async (data) => {
  let url = `${host}/v4/internal/ads/story`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchStory = async (storyId) => {
  let url = `${publicAPIHost}/v4/stories/${storyId}?limit=10&deviceType=android&appVersion=999999992&clientId=7wZFJWA5chjgat68y826IAIKQ6s197RM&timezone=Asia%2FKolkata&regionName=NCT&utmTerm=&utmContent=&locale=en_IN&languageCode=en&deviceId=10bd8e9dcd7b0bdb&utmCampaign=&cityName=Central%2BDelhi&countryCode=IN&sdkVersion=12&utmMedium=organic`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return error.json();
  }
};

export let resetStoryAd = async (id, title = "", storyAd) => {
  let url = `${host}/v4/internal/ads/story/${id}?title=${title}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(storyAd),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchClientAppPackages = async () => {
  let url = `${host}/v4/internal/ads/packages`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let getPartnerNames = async () => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/ads/partners`;

  let partners = [];

  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((partner) => {
        let partnerObj = {
          value: partner,
          label: partner,
        };
        partners.push(partnerObj);
      });
    }
    return partners;
  } catch (error) {
    return;
  }
};
import { host } from "./index";
import { retrieveFromLocalStorage } from "../localStorageUtil";

export let getQuickReplyCategories = async (params) => {
  let token = retrieveFromLocalStorage("token");
  let response = {};

  let url =
    `${host}/v4/internal/quickReplyCategories?` + new URLSearchParams(params);

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((res) => {
      if (res.quickReplyCategories)
        response = {
          quickReplyCategories: parseCategoryData(res.quickReplyCategories),
        };
      else response = res;
    });

  return response;
};

export let createQuickReplyCategory = async (newCategory) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/quickReplyCategories/create`;

  await fetch(url, {
    method: "POST",
    body: JSON.stringify(newCategory),
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let updateQuickReplyCategory = async (updatedCategory, categoryID) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/quickReplyCategories/${categoryID}/edit`;
  await fetch(url, {
    method: "POST",
    body: JSON.stringify(updatedCategory),
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let activateQuickReplyCategory = async (categoryID) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/quickReplyCategories/${categoryID}/activate`;

  await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let deactivateQuickReplyCategory = async (categoryID) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/quickReplyCategories/${categoryID}/deactivate`;

  await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let publishQuickReplyCategory = async (categoryID) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/quickReplyCategories/${categoryID}/publish`;

  await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let getQuickReplyCategoryDetail = async (categoryID) => {
  let token = retrieveFromLocalStorage("token");
  let quickReplyCategory = {};

  let url = `${host}/v4/internal/quickReplyCategories/${categoryID}/details`;
  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      quickReplyCategory = response;
    });
  return quickReplyCategory;
};

let parseCategoryData = (categories) => {
  let updatedCategories = [];
  categories.forEach((category) => {
    let updatedCategory = {};
    updatedCategory.status = addStatus(
      category.createdAt,
      category.publishedAt,
      category.deactivatedAt
    );
    updatedCategory.ID = category.ID;
    updatedCategory.name = category.name;
    updatedCategory.viewType = category.viewType;
    updatedCategory.priority = category.priority;
    updatedCategory.shareURL = category.shareURL;
    updatedCategory.providerName = category.providerName;
    updatedCategory.createdAt = category.createdAt;
    updatedCategory.publishedAt = category.publishedAt;
    updatedCategory.updatedAt = category.updatedAt;
    updatedCategory.deactivatedAt = category.deactivatedAt;
    updatedCategories.push(updatedCategory);
  });
  return updatedCategories;
};

export let getQuickReplyCategoryRules = async (id) => {
  let rules = [];

  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/quickReplyCategories/${id}/rules`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      rules = res.rules;
    }
    return rules;
  } catch (error) {
    return;
  }
};

export let saveQuickReplyCategoryRules = async (data, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/quickReplyCategories/${id}/rules`;
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ rules: data }),
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let getLanguages = async () => {
  let languages = [];
  let token = retrieveFromLocalStorage("token");
  let allObj = {
    value: "All",
    label: "All",
  };
  let englishObj = {
    value: "en",
    label: "English",
  };
  languages.push(allObj);
  languages.push(englishObj);

  let url = `${host}/v4/internal/stickers/languages`;
  try {
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let res = await response.json();
    if (res) {
      res.map((language) => {
        let languageObj = {
          value: language.code,
          label: language.name,
        };
        languages.push(languageObj);
      });
    }
    return languages;
  } catch (error) {
    return;
  }
};

export let getQuickReplies = async (params) => {
  let token = retrieveFromLocalStorage("token");
  let quickReplies = {};

  let url = `${host}/v4/internal/quickReplies?` + new URLSearchParams(params);

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    quickReplies = response.json();
  });
  return quickReplies;
};

export let getQuickReplyCategoriesForForm = async () => {
  let token = retrieveFromLocalStorage("token");
  let categories = [{ value: "", label: "All" }];

  let url = `${host}/v4/internal/quickReplyCategories`;
  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.quickReplyCategories) {
        response.quickReplyCategories.map((category) => {
          let categoryObj = {
            value: category.ID,
            label: category.name,
          };
          categories.push(categoryObj);
        });
      }
    });
  return categories;
};

export let activateQuickReply = async (id) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/quickReplies/${id}/activate`;

  await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let deactivateQuickReply = async (id) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/quickReplies/${id}/deactivate`;

  await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let addStatus = (created, published, deactivated) => {
  if (created !== null && published === null) return "Created";
  else if (created !== null && published !== null && deactivated === null)
    return "Activated";
  else if (created !== null && published !== null && deactivated !== null)
    return "Deactivated";
};

export let getCountries = async () => {
  let countries = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/quickReplies/countries`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((country) => {
        let countryObj = {
          value: country.id,
          label: country.name,
        };
        countries.push(countryObj);
      });
    }
    return countries;
  } catch (error) {
    return;
  }
};

export let getRegions = async (countryID) => {
  let regions = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/quickReplies/regions?countryID=${countryID}`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((region) => {
        let regionObj = {
          value: region.id,
          label: region.name,
        };
        regions.push(regionObj);
      });
    }
    return regions;
  } catch (error) {
    return;
  }
};

export let getCities = async (countryID, regionID) => {
  let cities = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/quickReplies/cities?countryID=${countryID}&regionID=${regionID}`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((city) => {
        let cityObj = {
          value: city.id,
          label: city.name,
        };
        cities.push(cityObj);
      });
    }
    return cities;
  } catch (error) {
    return;
  }
};

export let createQuickReplyBulk = async (replyObj) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/quickReplies/bulkCreate`;

  for (let i = 0; i < replyObj.length; i++) {
    if (
      isNaN(replyObj[i].categoryID) ||
      replyObj[i].rawText == undefined ||
      replyObj[i].languageCode == undefined ||
      replyObj[i].categoryID == "" ||
      replyObj[i].rawText == "" ||
      replyObj[i].languageCode == "" ||
      replyObj[i].client_ids == undefined
    ) {
      return {
        errorCode: 400,
        errorDescription:
          "Incorrect file uploaded. Please check the sample upload file",
      };
    }
  }

  await fetch(url, {
    method: "POST",
    body: JSON.stringify(replyObj),
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let createBulkImageTypeQuickReply = async (replyObj, zipFile) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/quickReplies/bulkCreate/image`;

  for (const element of replyObj) {
    if (
      isNaN(element.categoryID) ||
      element.imageFilename == undefined ||
      element.languageCode == undefined ||
      element.categoryID == "" ||
      element.rawText == "" ||
      element.imageFilename == "" ||
      element.client_ids == undefined
    ) {
      return {
        errorCode: 400,
        errorDescription:
          "Incorrect file uploaded. Please check the sample upload file",
      };
    }
  }

  // Send data to the server
  try {
    const formData = new FormData();
    formData.append('zipFile', zipFile);
    formData.append('quickReplies', JSON.stringify(replyObj));

    const response = await fetch(url, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log("Error: ", error);
    return {
      errorCode: 500,
      errorDescription: "An error occurred while processing your request.",
    };
  }
}

export let createQuickReply = async (data) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/quickReplies/create`;

  await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });

  return response;
};



export let publishQuickReply = async (id) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/quickReplies/${id}/publish`;

  await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let getQuickReplyDetail = async (id) => {
  let token = retrieveFromLocalStorage("token");
  let quickReply = {};
  let url = `${host}/v4/internal/quickReplies/${id}/details`;
  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      quickReply = response;
    });
  return quickReply;
};

export let updateQuickReply = async (replyObj, id) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/quickReplies/${id}/edit`;

  await fetch(url, {
    method: "POST",
    body: JSON.stringify(replyObj),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export const reorderAdBanners = async (data, id) => {
  const token = retrieveFromLocalStorage("token");
  const url = `${host}/v4/internal/quickReplyCategories/${id}/reorderAdBanners`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();

    return responseData;
  } catch (error) {
    // Handle any errors here
    console.error("Error while reordering ad banners:", error);
    throw error; // You can choose to re-throw the error or handle it differently.
  }
};

export const getQuickReplyTargetingRulesType = async (id) => {
  let rules = [];
  const token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/quickReplies/${id}/targetingRules`;
  try {
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let res = await response.json();
    if (res) {
      rules = res?.targetingDetailsSource;
    }
    return rules;
  } catch (error) {
    return;
  }
}

export const getQuickReplyTargetingRules = async (id) => {
  let rules = [];
  const token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/quickReplies/${id}/targetingRules`;
  try {
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let res = await response.json();
    if (res) {
      rules = res;
    }
    return rules;
  } catch (error) {
    return;
  }
};

export const saveQuickReplyTargetingRules = async (rules, id, data) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/quickReplies/${id}/targetingRules`;
  
  try {
    let response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    let res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};


export const getQuickRepliesTargetingRulesAndType = async (id) => {
  let rules = [];
  const token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/quickReplies/${id}/targetingRules`;
  try {
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let res = await response.json();
    if (res) {
      rules = res;
    }
    return rules;
  } catch (error) {
    return;
  }

}

export const saveQuickRepliesReordering = async (data) => {
  let token = retrieveFromLocalStorage("token");
  let quickReply = {};

  let url = `${host}/v4/internal/quickReplies/reorder`;

  try {
    let response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    let res = await response.json();
    if (res) {
      quickReply = res;
    }
    return quickReply;
  } catch (error) {
    return;
  }
}

export const getQuickRepliesAssets = async () => {
  let token = retrieveFromLocalStorage("token");
  let quickReply = {};

  let url = `${host}/v4/internal/quickReplies/assets`;

  try {
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let res = await response.json();
    if (res) {
      quickReply = res;
    }
    return quickReply;
  } catch (error) {
    return;
  }
}

// getQuickRepliesCTATexts returns the list of most commonly used CTA texts for quick replies
export const getQuickRepliesCTATexts = async () => {
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/quickReplies/ctaTexts`;

  try {
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let res = await response.json();
    return res;
  } catch (error) {
    return;
  }
}


export const updateQuickReplyCTAAssets = async(payload) => {
  let token = retrieveFromLocalStorage("token")
  let url = `${host}/v4/internal/quickReplies/ctaAssets`

  try {
    let response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    });
    let res = await response.json();
    return res
  } catch (error){
    return
  }

}
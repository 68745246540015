import React from "react";
import Routes from "./routes/Routes";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import store from "./redux/store/index";
import SignIn from "./pages/auth/SignIn";
import AuthLayout from "./layouts/AuthLayout";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { retrieveFromLocalStorage } from "./utilities/localStorageUtil";
import { redirectOnTokenExpiry } from "./utilities/commonUtil";

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route
            path="/"
            exact
            render={() =>
              retrieveFromLocalStorage("token") ? ((
                retrieveFromLocalStorage("exp") * 1000 < Date.now() ?
                  (redirectOnTokenExpiry()) :
                  (<Redirect to="/dashboard" />)
              )
              )
                : (
                  <AuthLayout>
                    <SignIn />
                  </AuthLayout>
                )
            }
          />
          <Routes />
        </Switch>
      </Router>
      <ReduxToastr
        timeOut={5000}
        newestOnTop={true}
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </Provider>
  );
}

export default App;
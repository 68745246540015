import React from 'react'
import { Redirect } from "react-router-dom";
import { authRoutes } from "../routes/index";
import {
    removeFromLocalStorage
  } from "../utilities/localStorageUtil";
class Logout extends React.Component{
    render(){
        removeFromLocalStorage("token");
        removeFromLocalStorage("username");
        removeFromLocalStorage("exp");
        return(
            <Redirect to={authRoutes.login.path}>
                <p className = "text-center lead">Logging you out ...</p>
            </Redirect>
        )
    }
}
export default Logout
import React, { Fragment, useState, useEffect } from "react";
import ResponseModal from "../ResponseModal";
import {
  getPopTextStyleDetails,
  createPopTextStyle,
  editPopTextStyle,
} from "../../../utilities/apiUtils/popTextStyles";
import PopTextStyleForm from "./PopTextStyleForm";
import Routes from "../../../routes/index";
import {
  errorDescription,
  error,
  redirectTo,
  redirectionOnTokenExpiry,
} from "../../../utilities/commonUtil";
import { successModalHeading, failureModalHeading } from "../../../config";
import { PopTextStyleCreatedSuccessfullyText, PopTextStyleUpdatedSuccessfullyText } from "../../../config/popTextStyles";

const PopTextStyleFormContainer = (props) => {
  const [formTextState, setFormTextState] = useState({
    formName: props.formName,
    startDate: null,
    endDate: null,
    name: "",
    brandCampaign: "",
    enableWatermark: false,
    priority: { value: 1, valid: true },
    font: {
      value: {
        name: "",
        color: "",
      },
      valid: true,
    },
    stroke1: {
      value: null,
      valid: true,
    },
    stroke2: {
      value: null,
      valid: true,
    },
    stroke3: {
      value: null,
      valid: true,
    },
    shadow: {
      value: null,
      valid: true,
    },
    customTextDetails: {
      value: null,
      valid: true,
    },
    previewImage: null,
    backgroundImage: null,

    previewImageSrc: "",
    backgroundImageSrc: "",

    targetLanguages: [],
    keywords: [],
  });
  const [showForm, setShowForm] = useState(false);
  const [successModalState, setSuccessModalState] = useState({
    successModalDisplay: false,
    successModalText: "",
  });
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });

  useEffect(() => {
    const getStyle = async () => {
      let id = props.match.params.id;
      // If ID is present, fetch the theme details for edit
      if (id) {
        try {
          let response = await getPopTextStyleDetails(id);

          if (response) {
            if (response.languages) {
              var languages = [];
              response.languages.map((language) => {
                var temp = {
                  value: language.code,
                  label: language.name,
                };
                languages.push(temp);
              });
            }

            if (response.startDate) {
              response.startDate = response.startDate.split("T")[0];
            }
    
            if (response.endDate) {
              response.endDate = response.endDate.split("T")[0];
            }

            // Set the theme values in state (overwrite the default values with fetched values)
            setFormTextState({
              formName: "Details",
              name: response.name,
              enableWatermark: response.enableWatermark,
              startDate: response.startDate,
              endDate: response.endDate,
              brandCampaign:
                response.brandCampaign != null
                  ? response.brandCampaign.id != 0
                    ? response.brandCampaign
                    : 0
                  : 0,
              fontID: response.fontID,
              previewImageSrc: response.previewImageURL,
              backgroundImageSrc: response.backgroundImageURL,

              font: {
                value: response.font
                  ? {
                      name: response.font.url,
                      color: response.font.color,
                    }
                  : null,
                valid: true,
              },
              stroke1: {
                value: response.stroke1
                  ? {
                      color: response.stroke1.color,
                      width: response.stroke1.width.toString(),
                    }
                  : null,
                valid: true,
                enable: response.stroke1 != null ? true : false,
              },
              stroke2: {
                value: response.stroke2
                  ? {
                      color: response.stroke2.color,
                      width: response.stroke2.width.toString(),
                    }
                  : null,
                valid: true,
                enable: response.stroke2 != null ? true : false,
              },
              stroke3: {
                value: response.stroke3
                  ? {
                      color: response.stroke3.color,
                      width: response.stroke3.width.toString(),
                    }
                  : null,
                valid: true,
                enable: response.stroke3 != null ? true : false,
              },
              shadow: {
                value: response.shadow
                  ? {
                      x: response.shadow.x.toString(),
                      y: response.shadow.y.toString(),
                      color: response.shadow.color,
                      radius: response.shadow.radius.toString(),
                    }
                  : null,
                enable: response.shadow != null ? true : false,

                valid: true,
              },
              customTextDetails: {
                value: response.customTextDetails
                  ? {
                      position: {
                        x: response.customTextDetails.position.x.toString(),
                        y: response.customTextDetails.position.y.toString(),
                        height:
                          response.customTextDetails.position.height.toString(),
                        width:
                          response.customTextDetails.position.width.toString(),
                      },
                    }
                  : null,
                valid: true,
              },

              priority: { value: response.priority, valid: true },

              keywords: response.keywords ?? [],
              targetLanguages: languages,
            });
            setShowForm(true);
            return;
          }
          // Handle error
          if (response.errorCode) {
            if (
              response.errorCode === error.tokenExpired ||
              response.errorCode === error.invalidAccessToken
            )
              redirectionOnTokenExpiry();
            setFailureModalState({
              filureModalDisplay: true,
              failureModalText: errorDescription(response.errorCode),
            });
          }
        } catch (err) {
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: errorDescription(error.unexpectedError),
          });
        }
      }
      setShowForm(true);
    };

    getStyle();
  }, []);

  const onSubmit = async (styleData) => {
    let formData = new FormData();
    // check date validation
    if (
      styleData.startDate !== null &&
      styleData.endDate !== null &&
      styleData.startDate > styleData.endDate
    ) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.endDateStartDateError),
      });
      return;
    }

    // Append values in Form data
    formData.append("name", styleData.name);

    if (styleData.backgroundImage)
      formData.append("backgroundImage", styleData.backgroundImage);
    else formData.append("backgroundImageSrc", styleData.backgroundImageSrc);

    if (styleData.previewImage)
      formData.append("previewImage", styleData.previewImage);
    else formData.append("previewImageSrc", styleData.previewImageSrc);
    formData.append("priority", styleData.priority.value);
    formData.append("brandCampaignID", styleData.brandCampaignID);
    formData.append("fontID", styleData.fontID);
    formData.append("enableWatermark", styleData.enableWatermark);
    formData.append("font", JSON.stringify(styleData.font.value));

    if (styleData.startDate !== null){
      formData.append("startDate", styleData.startDate);
    }
    if (styleData.endDate !== null){
      formData.append("endDate", styleData.endDate);
    }

    if (styleData.enableStroke1) {
      formData.append("stroke1", JSON.stringify(styleData.stroke1.value));
    } else {
      formData.append("stroke1", JSON.stringify(null));
    }

    if (styleData.enableStroke2) {
      formData.append("stroke2", JSON.stringify(styleData.stroke2.value));
    } else {
      formData.append("stroke2", JSON.stringify(null));
    }

    if (styleData.enableStroke3) {
      formData.append("stroke3", JSON.stringify(styleData.stroke3.value));
    } else {
      formData.append("stroke3", JSON.stringify(null));
    }

    if (styleData.enableShadow) {
      formData.append("shadow", JSON.stringify(styleData.shadow.value));
    } else {
      formData.append("shadow", JSON.stringify(null));
    }

    formData.append(
      "customTextDetails",
      JSON.stringify(styleData.customTextDetails.value)
    );
    formData.append("languages", JSON.stringify(styleData.targetLanguages));
    formData.append("keywords", styleData.keywords);

    if (props.formName === "Create Pop Text Style") {
      try {
        let response = await createPopTextStyle(
          formData,
          props.match.params.id
        );
        if (response.errorCode) {
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: response.errorDescription,
          });
        } else {
          setSuccessModalState({
            successModalDisplay: true,
            successModalText: PopTextStyleCreatedSuccessfullyText,
          });
        }
      } catch (err) {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      }
    } else {
      // API call for edit pop text style
      formData.append("id", props.match.params.id);
      try {
        let response = await editPopTextStyle(formData, props.match.params.id);
        if (response.errorCode) {
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: response.errorDescription,
          });
        } else {
          setSuccessModalState({
            successModalDisplay: true,
            successModalText: PopTextStyleUpdatedSuccessfullyText,
          });
        }
      } catch (err) {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      }
    }
  };

  const failureModalClose = () => {
    setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
  };

  const successModalClose = () => {
    setSuccessModalState((prev) => ({ ...prev, successModalDisplay: false }));
    redirectTo(Routes.popTextStyles.path);
  };

  return (
    <Fragment>
      {showForm ? (
        <PopTextStyleForm
          show={failureModalState.failureModalDisplay}
          onHide={() => failureModalClose()}
          style={formTextState}
          submit={onSubmit}
          updateAllowed={props.updateAllowed}
        />
      ) : null}
      <ResponseModal
        show={failureModalState.failureModalDisplay}
        onHide={() => failureModalClose()}
        modalheading={failureModalHeading}
        modaltext={failureModalState.failureModalText}
      />
      <ResponseModal
        show={successModalState.successModalDisplay}
        onHide={() => successModalClose()}
        modalheading={successModalHeading}
        modaltext={successModalState.successModalText}
      />
    </Fragment>
  );
};

export default PopTextStyleFormContainer;

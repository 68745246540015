// rewardsTableColumns.js
import React from "react";

const statusFormatter = (cell, row) => {
  return (
    <div className="d-flex align-items-center">
      {cell === "Activated" ? (
        <span
          className="badge-success badge badge-secondary"
          style={{ width: "1rem", height: "1rem", verticalAlign: "middle" }}
        >
          {" "}
        </span>
      ) : cell === "Deactivated" ? (
        <span
          className="badge-danger badge badge-secondary"
          style={{ width: "1rem", height: "1rem", verticalAlign: "middle" }}
        >
          {" "}
        </span>
      ) : cell === "Unpublished" ? (
        <span
          className="badge-warning badge badge-secondary"
          style={{ width: "1rem", height: "1rem", verticalAlign: "middle" }}
        >
          {" "}
        </span>
      ) : (
        "-"
      )}
      <div className="ml-1">{cell ?? "-"}</div>
    </div>
  );
};

const tableColumns = [
  {
    dataField: "id",
    text: "#",
    sort: true,
    headerAttrs: { width: 60 },
    searchable: true,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "name",
    text: "Name",
    // formatter: (cell, row) => titleFormatter(cell, row),
    headerAttrs: { width: 230 },
    sort: true,
    searchable: true,
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "description",
    text: "Description",
    headerAttrs: { width: 300 },
    sort: true,
    searchable: true,
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "status",
    text: "Status",
    headerAttrs: { width: 230 },
    sort: true,
    searchable: true,
    formatter: (cell, row) => statusFormatter(cell, row),
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "listing_priority",
    text: "Listing Priority",
    headerAttrs: { width: 120 },
    sort: true,
    searchable: true,
    style: {
      cursor: "pointer",
      color: "#f00",
      fontWeight: "bold",
    },
  },
  {
    dataField: "num_coins",
    text: "Num Coins",
    headerAttrs: { width: 120 },
    sort: true,
    searchable: true,
    style: {
      cursor: "pointer",
      color: "#00f",
      fontWeight: "bold",
    },
  },
];

export { tableColumns };

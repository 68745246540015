import moment from "moment";
import { retrieveFromLocalStorage } from "../utilities/localStorageUtil";
import { permissions } from "../utilities/commonUtil";

const allowedPermissions = retrieveFromLocalStorage("userPermissions");

export let isAutoCappingsCreatePermission = () => {
  return allowedPermissions.includes(permissions.autoCappingCreate);
};

export let isAutoCappingsEditPermission = () => {
  return allowedPermissions.includes(permissions.autoCappingEdit);
};

export let isAutoCappingsDeletePermission = () => {
  return allowedPermissions.includes(permissions.autoCappingDelete);
};

export const statusOptions = [
  { value: "", label: "All" },
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];

export const buyModelOptions = [
  { value: "", label: "All" },
  { value: "CPM", label: "CPM" },
  { value: "CPC", label: "CPC" },
  { value: "CPI", label: "CPI" },
];

export const metricsOptions = [
  { value: "impressions", label: "Impressions" },
  { value: "clicks", label: "Clicks" },
  { value: "installs", label: "Installs" },
];

export const allActions = {
  delete: "Delete Capping Rules"
};

export const autoCappingsResponses = {
  updatedSuccessfully: "Auto Capping updated successfully",
  addedSuccessfully: "Auto Capping added successfully",
};

export const startAtSuffix = " 00:00:00"
export const endAtSuffix = " 23:59:59"

export const getColorByPercentage = (percentage) => {
  // Ensure percentage is between 0 and 100
  percentage = Math.min(100, Math.max(0, percentage));

  if (percentage < 100) return '#f44455';
  return '#5fc27e';
}

export const parseDateInMoment = (date) => {
  return moment(date).local()
}

export const getDateValue = (date) => {
  if (date === null) return null
  return moment(date).local().format("YYYY-MM-DD");
}

export const getNextDate = (date) => {
  if (date === null) return null
  return moment(date).local().add(1, "day").format("YYYY-MM-DD");
}

export const getMetricsFromBuyModel = (buyModel) => {
  if (buyModel === "CPM") return "impressions"
  if (buyModel === "CPC") return "clicks"
  if (buyModel === "CPI") return "installs"
  return null
}

export const createDeepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}

export const isDateGreater = (date1, date2) => {
  return new Date(date1) > new Date(date2);
}

export const isDateBetween = (date, startDate, endDate) => {
  const dateObj = new Date(date)
  const startDateObj = new Date(startDate)
  const endDateObj = new Date(endDate)

  return dateObj >= startDateObj && dateObj <= endDateObj;
}

export const getDailyTarget = (overallTarget, startDate, endDate) => {
  return Math.ceil(Number(overallTarget) / (parseDateInMoment(endDate).diff(parseDateInMoment(startDate), 'days') + 1))
}
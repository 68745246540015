import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { errorDescription, error } from "../../../utilities/commonUtil";
import {
  updateTasksEvent,
  fetchTasksEventbyId,
} from "../../../utilities/apiUtils/tasks.js";
import ResponseModal from "../ResponseModal";
import { errorModalHeading } from "../../../config/config";

const EditTaskEvent = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const [formData, setFormData] = useState({
    name: "",
    task_id: "",
    display_name: "",
    screen_names: "",
    event_label: "",
  });

  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
    props.onHide();
  };

  useEffect(() => {
    fetchTaskEvent();
  }, [id]);

  const fetchTaskEvent = async () => {
    try {
      const response = await fetchTasksEventbyId(id);
      if (response && response.task_events && response.task_events.length > 0) {
        const taskEvent = response.task_events[0];
        setFormData(taskEvent);
      } else {
        console.error("No task events found in the response");
      }
    } catch (err) {
      showErrorModal("Error fetching task event. Please try again later.");
      console.error("Error fetching task event:", err);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateTasksEvent(id, formData);
      history.push("/tasks/view-tasks");
    } catch (err) {
      showErrorModal("Failed to update task event. Please try again later.");
      console.error("Error updating task event:", err);
    }
  };

  return (
    <Container className="my-5">
      <h1 className="mb-4">Edit Task Event</h1>
      <Card>
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="name">Name:</Label>
                  <Input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="task_id">Task ID:</Label>
                  <Input
                    type="text"
                    name="task_id"
                    value={formData.task_id}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="display_name">Display Name:</Label>
                  <Input
                    type="text"
                    name="display_name"
                    value={formData.display_name}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="screen_names">Screen Names:</Label>
                  <Input
                    type="text"
                    name="screen_names"
                    value={formData.screen_names}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="event_label">Event Label:</Label>
                  <Input
                    type="text"
                    name="event_label"
                    value={formData.event_label}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="text-center mt-4">
              <Button type="submit" color="primary">
                Update
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={errorModalHeading}
        modaltext={failureModalText}
      />
    </Container>
  );
};

export default EditTaskEvent;

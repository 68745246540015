import React from "react";
import Wrapper from "../components/Wrapper";
import Sidebar from "../components/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import { Col, Container, Row } from "reactstrap";
import { Button } from "react-bootstrap";

const AccessDenied = () => (
  <React.Fragment>
    <Wrapper>
      <Sidebar />
      <Main>
        <Navbar />
        <Content>
        <Container className="d-flex flex-column">
        <Row className="h-100">
          <Col sm="10" md="8" lg="6" className="mx-auto d-table h-100">
            <div className="d-table-cell align-middle text-center">
              <h1 className="my-5">404</h1>
              <h2>Access Denied</h2>
              <h3>You do not have permission to view this page</h3>
              <h4 className="mt-5">Please return to your previous page</h4>
              <Button onClick={ () => window.history.go(-1)}>Previous Page</Button>
            </div>
          </Col>
        </Row>
      </Container>
        </Content>
      </Main>
    </Wrapper>
  </React.Fragment>
);

export default AccessDenied;

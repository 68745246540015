import React, { useState } from "react";
import {
    Container,
} from "reactstrap";

import KeyboardLanguageForm from "./KeyboardLanguageForm";

const ManageKeyboardLanguage = () => {
    const [formTextState, setFormTextState] = useState({
        id: "",
        name: "",
        locale: "",
        priority: "",
        minAppVersion: "",
        minBobbleSDKVersion: "",
        code: "",
        deviceType: "",
        additionalDetails: {},
        iconImageURL: "",
        layouts: [],
        defaultLayoutId: null,
    });

    const formType = "create";

    return (
        <Container fluid className="p-0">
            <h3 className="mb-3">Add Keyboard Language</h3>
            <KeyboardLanguageForm
                id={formTextState.id}
                name={formTextState.name}
                locale={formTextState.locale}
                language={formTextState.language}
                priority={formTextState.priority}
                minAppVersion={formTextState.minAppVersion}
                languageCode={formTextState.languageCode}
                deviceType={formTextState.deviceType}
                minBobbleSDKVersion={formTextState.minBobbleSDKVersion}
                additionalDetails={formTextState.additionalDetails}
                formType={formType}
                layouts={formTextState.layouts}
                defaultLayoutId={formTextState.defaultLayoutId}
            />
        </Container>
    );
};

export default ManageKeyboardLanguage;

import React from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { Version } from "../config/Version";
import { Badge, Collapse, Button } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Box } from "react-feather";
import { sidebarRoutes } from "../routes/index";
import {
  removeFromLocalStorage,
  retrieveFromLocalStorage,
} from "../utilities/localStorageUtil";
import { authRoutes } from "../routes/index";
import { filterPermittedRoutes } from "../utilities/commonUtil";
import { cloneDeep } from "lodash";

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon: Icon,
    isOpen,
    children,
    onClick,
    location,
    to,
  }) => {
    // If location path matches with the path of Sidebar item, return active to the classList of Sidebar item
    const getSidebarItemClass = (path) => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === authRoutes.login.path &&
          path === sidebarRoutes.dashboard.path)
        ? "active"
        : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <span
          data-toggle="collapse"
          className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
          onClick={onClick}
          aria-expanded={isOpen ? "true" : "false"}
        >
          <Icon size={18} className="align-middle mr-3" />
          <span className="align-middle">{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" className={"sidebar-dropdown list-unstyled"}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon: Icon, location, to }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname === path ? "active" : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <NavLink to={to} className="sidebar-link" activeClassName="active">
          {Icon ? <Icon size={18} className="align-middle mr-3" /> : null}
          {name}
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

let ProtectedRoutes = [];

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggle = (index) => {
    // Collapse all elements
    Object.keys(this.state).forEach(
      (item) =>
        this.state[index] ||
        this.setState(() => ({
          [item]: false,
        }))
    );

    // Toggle selected element
    this.setState((state) => ({
      [index]: !state[index],
    }));
  };

  UNSAFE_componentWillMount() {
    /* Open collapse element that matches current url */
    const pathName = this.props.location.pathname;

    let _routes = cloneDeep(sidebarRoutes);
    ProtectedRoutes = filterPermittedRoutes(_routes);

    // Sort main menu items alphabetically for better user navigation
    ProtectedRoutes = ProtectedRoutes ? Object.values(ProtectedRoutes)
      .sort((a, b) => {
        return (a?.name || '').localeCompare(b?.name || '');
      }) : [];

    Object.values(sidebarRoutes).forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome =
        route.containsHome && pathName === authRoutes.login.path ? true : false;

      this.setState(() => ({
        [index]: isActive || isOpen || isHome,
      }));
    });
  }

  handleLogout = (event) => {
    removeFromLocalStorage("token");
    removeFromLocalStorage("username");
    this.props.history.push(authRoutes.login.path);
  };

  // Sort submenu items alphabetically by name 
  sortChildrenAlphabetically = (children) => {
    return children ? Object.values(children)
      .sort((a, b) => {
        return (a?.name || '').localeCompare(b?.name || '');
      }) : [];
  };

  render() {
    const { sidebar, layout } = this.props;
    sidebar.isSticky = true;
    return (
      <nav
        className={
          "sidebar" +
          (!sidebar.isOpen ? " toggled" : "") +
          (sidebar.isSticky ? " sidebar-sticky" : "")
        }
      >
        <div className="sidebar-content">
          <PerfectScrollbar>
            <a className="sidebar-brand" href={sidebarRoutes.dashboard.path}>
              <Box className="align-middle text-primary" size={24} />
              <span className="align-middle">Bobble Keyboard</span>
            </a>
            <ul className="sidebar-nav">
              {Object.values(ProtectedRoutes).map((category, index) => {
                return (
                  <React.Fragment key={index}>
                    {category.children ? (
                      <SidebarCategory
                        name={category.name}
                        badgeColor={category.badgeColor}
                        badgeText={category.badgeText}
                        icon={category.icon}
                        to={category.path}
                        isOpen={this.state[index]}
                        onClick={() => this.toggle(index)}
                      >
                        {this.sortChildrenAlphabetically(category.children).map(
                          (route, index) => (
                            <SidebarItem
                              key={index}
                              name={route.name}
                              to={route.path}
                              badgeColor={route.badgeColor}
                              badgeText={route.badgeText}
                            />
                          )
                        )}
                      </SidebarCategory>
                    ) : (
                      <SidebarItem
                        name={category.name}
                        to={category.path}
                        icon={category.icon}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </ul>

            {!layout.isBoxed && !sidebar.isSticky ? (
              <div className="sidebar-bottom d-none d-lg-block">
                <div className="media">
                  <div className="media-body">
                    <h5 className="mb-1">
                      {retrieveFromLocalStorage("username")}
                    </h5>
                    <div className="d-flex justify-content-between">
                      <div>v{Version}</div>
                      <Button onClick={this.handleLogout}>Sign Out</Button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </PerfectScrollbar>
        </div>
      </nav>
    );
  }
}

export default withRouter(
  connect((store) => ({
    sidebar: store.sidebar,
    layout: store.layout,
  }))(Sidebar)
);

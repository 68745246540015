import React, { Component } from "react";
import Search from "../../../components/Search"
import Select from "react-select";
import { Link } from "react-router-dom";
import { Button, Badge } from "react-bootstrap";
import {
  Card,
  CardImg,
  Row,
  Col,
  Container,
  CardBody,
  Spinner,
  CardTitle,
  Label,
  FormGroup,
  CustomInput,
} from "reactstrap";
import {
  errorDescription,
  error,
  allActions,
} from "../../../utilities/commonUtil";
import { config, formatTime, poptextStickerResponses, showActivateStickerButton, showCreateStickerButton, showDeactivateStickerButton, showEditStickerButton } from "../../../config/configTaasDashboard";
import { createUpdatePoptextSticker, fetchTaasClients, fetchTaasPoptextStickerURL, fetchTaasPoptextStickers } from "../../../utilities/apiUtils/taasDashboard";
import UncontrolledButtonDropdown from "reactstrap/lib/UncontrolledButtonDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import { capitalize } from "@material-ui/core";
import ResponseModal from "../ResponseModal";
import InfiniteScroll from "react-infinite-scroll-component";
import ConfirmationModal from "../ConfirmationModal";
import { failureModalHeading, successModalHeading } from "../../../config";

class PopTextStickers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: "",
      clientId: 0,
      successModalText: "",
      successModalDisplay: false,
      failureModalText: "",
      failureModalDisplay: false,
      pageNumber: 0,
      stickers: [],
      clients: [],
      hasMoreStickers: false,
      previewBackgroundColor: 'white',
      loading: true,
      status: { value: "", label: "All" },
      imageURL: "",
      searchBy: config.searchByDefault,
      confirmationModalIsOpen: false,
      action: "",
      data: {
        id: '',
        poptextStickerId: '',
        clientId: '',
        data: '',
      }
    };
  }

  componentDidMount() {
    this.search();
    this.fetchClientsData();
  }
  getStickers = async () => {
    // Query parameter.
    let params = {
      poptextStickerId: this.state.searchBy === "id" ? this.state.searchString : "",
      clientId: this.state.searchBy === "client" ? this.state.searchString : "",
      status: this.state.status.value,
      pageNumber: this.state.pageNumber,
    };
    // API called with given query parameters.
    fetchTaasPoptextStickers(params)
      .then(async (response) => {
        if (!response || response?.length === 0) {
          this.setState({ stickers: [], hasMoreStickers: false });
          return;
        }
        if (response?.length > 0) {
          for (let i = 0; i < response.length; i++) {
            let res = await fetchTaasPoptextStickerURL(response[i].poptextStickerId)
            response[i].imageURL = res?.sticker?.fixedWidthTiny?.png?.url;
          }
          this.setState({ stickers: response });
          this.setState({ hasMoreStickers: response.length < 10 ? false : true });
        } else {
          this.showErrorModal(errorDescription(response.errorCode));
        }
      })
      .catch((err) => {
        this.setState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      })
  };

  fetchClientsData = async () => {
    try {
      let response = await fetchTaasClients()
      if (response.length > 0) {
        this.setState({
          clients: response.map(d => ({
            value: d.id,
            label: d.name,
          }))
        });
      }
      else {
        this.showErrorModal(errorDescription(response.errorCode));
      }
    } catch (err) {
      this.setState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  }

  handleChange = (event) => {
    event.preventDefault();
    let value = event.target.value;
    const onlyNumbers = value?.replace(/\D/g, '');
    this.setState({ searchString: onlyNumbers });
    if (!event.target.value.length) {
      this.search();
    }
  };

  handlePreviewBackgroundColor = () => {
    if (this.state.previewBackgroundColor === 'white') {
      this.setState({ previewBackgroundColor: 'black' });
    }
    else
      this.setState({ previewBackgroundColor: 'white' });
  }

  onKeyDown = (event) => {
    if (event.keyCode === config.keypressDown) {
      this.search();
    }
  };

  search = () => {
    this.setState(
      { loading: true, pageNumber: 1, hasMoreStickers: false, searchBy: this.state.searchBy },
      () => {
        this.getStickers();
      });
  };

  fetchMoreData = () => {
    this.setState(
      { pageNumber: this.state.pageNumber + 1, hasMoreStickers: true, loading: true },
      async () => {
        // Query parameters
        let params = {
          poptextStickerId: this.state.searchBy === "id" ? this.state.searchString : "",
          clientId: this.state.searchBy === "client" ? this.state.searchString : "",
          status: this.state.status.value,
          pageNumber: this.state.pageNumber,
        };
        // API called with given query parameters
        fetchTaasPoptextStickers(params)
          .then(async (response) => {
            if (response?.length > 0) {
              for (let i = 0; i < response.length; i++) {
                let res = await fetchTaasPoptextStickerURL(response[i].poptextStickerId)
                response[i].imageURL = res?.sticker?.fixedWidthTiny?.png?.url;
              }
              this.setState({
                stickers: this.state.stickers.concat(response),
              });
              this.setState({ hasMoreStickers: response.length < 10 ? false : true });
            }
          })
          .catch((err) => {
            this.setState({
              failureModalDisplay: true,
              failureModalText: errorDescription(error.unexpectedError),
            });
          })
          .finally(() => {
            this.setState({ loading: false });
          })
      }
    )
  };

  showErrorModal = (text) => {
    this.setState({ failureModalText: text });
    this.setState({ failureModalDisplay: true });
  };

  failureModalClose = () => {
    this.setState({ failureModalDisplay: false });
  };

  successModalClose = () => {
    this.setState({ successModalDisplay: false });
    this.search();
  };

  showSuccessModal = (text) => {
    this.setState({ successModalText: text });
    this.setState({ successModalDisplay: true });
  };

  disableDeactivateButton = (row) => {
    if (new Date(row.deactivatedAt) > new Date() || row.deactivatedAt === null) return false;
    return true;
  };

  disableActivateButton = (row) => {
    if (new Date(row.deactivatedAt) > new Date() || row.deactivatedAt === null) return true;
    return false;
  };

  updateStatus = async (update) => {
    try {
      let formData = new FormData();
      formData.append("id", this.state.data.id);
      formData.append("poptextStickerId", this.state.data.poptextStickerId);
      formData.append("clientId", this.state.data.clientId);
      formData.append("data", this.state.data.data);
      formData.append("activatedAt", update === "deactivate" ? false : true);
      formData.append("deactivatedAt", update === "deactivate" ? true : false);
      let response = await createUpdatePoptextSticker(formData);
      if (response.code === "PoptextStickerUpdated") {
        this.showSuccessModal(update === "deactivate" ? poptextStickerResponses.deactivatedSuccessfully : poptextStickerResponses.activatedSuccessfully);
      } else {
        if (response.errorDescription) {
          this.showErrorModal(response.errorDescription)
        }
      }
    } catch (err) {
      this.showErrorModal(errorDescription(error.unexpectedError));
    }
  };

  setConfirmationModalState = (event, row) => {
    this.setState({
      action: event,
      confirmationModalIsOpen: true,
      data: {
        id: row.id,
        poptextStickerId: row.poptextStickerId,
        clientId: row.clientId,
        data: row.data,
      }
    })
  };

  handleStatusChange = (status) => {
    this.setState({ status: { value: status.value, label: status.label } }, () => this.search());
  };

  handleClient = (clientId) => {
    this.setState({ clientId: clientId.value, searchString: clientId.value }, () => this.search());
  }

  render() {
    return (
      < Container fluid className="p-0" >
        <div className="d-flex justify-content-between">
          <h1 className="h3">Poptext Stickers</h1>
          <Link to={{
            pathname: `/taas/poptext-stickers/create`
          }}>
            {showCreateStickerButton() ? (
              <Button
                style={{ fontSize: "1rem" }}
                className="mx-3 btn-success mb-3"
              >
                Add Poptext Sticker
              </Button>) : null}
          </Link>
        </div>
        <Row className="mt-4">
          <Col lg={6}>
            {this.state.searchBy !== "client" && <Search
              type="text"
              value={this.state.searchString}
              onChange={this.handleChange}
              onKeyDown={this.onKeyDown}
              placeholder={"Search Stickers"}
            ></Search>}
            {this.state.searchBy === "client" &&
              <Select
                className="react-select-container mb-4"
                id="statusSelect"
                classNamePrefix="react-select"
                name="client"
                placeholder="Select Client"
                onChange={this.handleClient}
                options={this.state.clients}
              />}
          </Col>
          <Col md={3} sm={12}>
            <UncontrolledButtonDropdown className="mr-1 mb-1">
              <DropdownToggle caret color="success">
                {"Search By " + capitalize(this.state.searchBy) + " "}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => this.setState({ searchBy: "id", searchString: "" })}>ID</DropdownItem>
                <DropdownItem onClick={() => this.setState({ searchBy: "client", searchString: "" })}>Client</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </Col>
        </Row>
        <Row>
          <Col md={3} sm={12}>
            <Label>Status</Label>
            <Select
              className="react-select-container mb-4"
              classNamePrefix="react-select"
              placeholder="Select Status"
              onChange={this.handleStatusChange}
              options={[
                { value: "", label: "All" },
                { value: "activated", label: "Activated" },
                { value: "deactivated", label: "Deactivated" },
              ]}
              value={this.state.status}
            />
          </Col>
          <Col md={9} sm={12}>
            <FormGroup className="d-flex align-items-center justify-content-end mt-4">
              <Label
                className="font-weight-bolder mb-0"
                style={{ fontSize: "14px" }}
              >
                <h4>
                  Try Dark Mode
                </h4>
              </Label>
              <div className="custom-control custom-switch mb-2">
                <CustomInput
                  type="checkbox"
                  id="changePreviewBackgroundColor"
                  onChange={this.handlePreviewBackgroundColor}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
        <InfiniteScroll
          style={{ overflow: "auto !important" }}
          className="infinite-scroll"
          dataLength={this.state.stickers?.length}
          next={this.fetchMoreData}
          hasMore={this.state.hasMoreStickers}
          loader={
            this.state.loading && (
              <Spinner color="info" className="d-flex mx-auto" />
            )
          }
        >
          <Row className="mt-4 mx-0">
            {(this.state.loading && this.state.stickers?.length <= 0) ? (
              <Spinner color="info" className="d-flex mx-auto" />
            ) :
              (this.state.stickers?.length > 0) ?
                this.state.stickers.map((sticker, index) => {
                  return (
                    <React.Fragment key={index}>
                      {sticker.imageURL &&
                        <Col md={6} lg={4}>
                          <Card className="importPageCard">
                            {sticker.imageURL ? <CardImg
                              top
                              src={sticker.imageURL ?? ""}
                              style={{ backgroundColor: this.state.previewBackgroundColor }}
                              alt="Card image cap"
                            /> : <Spinner color="info" className="d-flex mx-auto my-5" />}
                            <CardBody className="px-4">
                              <CardTitle tag="h3" className="mb-0">
                                {new Date(sticker.deactivatedAt) > new Date() || sticker.deactivatedAt === null ?
                                  <Badge className="badge-success float-right">{"Activated"}</Badge> :
                                  <Badge className="badge-danger float-right">{"Deactivated"}</Badge>}
                              </CardTitle>

                              <small>ID : {sticker.id}</small>
                              <br />
                              <small>Poptext Sticker ID : {sticker.poptextStickerId}</small>
                              <br />
                              <br />
                              <small className="font-weight-bold">
                                Client : {sticker.clientName}
                              </small>
                              <br />
                              <small className="font-weight-bold">
                                Activated At : {formatTime(sticker.activatedAt)}
                              </small>
                              <br />
                              <small className="font-weight-bold">
                                Deactivated At : {new Date(sticker.deactivatedAt) > new Date() || sticker.deactivatedAt === null ?
                                  "N/A" : formatTime(sticker.deactivatedAt)}
                              </small>
                              <br />
                              <small className="font-weight-bold">
                                Data : {sticker.data ? sticker.data : "N/A"}
                              </small>
                            </CardBody>
                            <CardBody className="px-4">
                              <Container className="text-center" fluid>
                                <Row>
                                  <Col sm="12" md="12">
                                    {showEditStickerButton() ? (
                                      <Link to={{
                                        pathname: `/taas/poptext-stickers/edit/${sticker.id}`
                                      }}>
                                        <Button
                                          className="m-2"
                                          color="info"
                                        >
                                          Edit
                                        </Button>
                                      </Link>) : null}
                                    {showDeactivateStickerButton() ? (
                                      <Button
                                        className="m-2 btn-danger"
                                        disabled={this.disableDeactivateButton(sticker)}
                                        onClick={(event) =>
                                          this.setConfirmationModalState(event.target.innerHTML, sticker)
                                        }
                                      >
                                        Deactivate
                                      </Button>) : null}
                                    {showActivateStickerButton() ? (
                                      <Button
                                        className="m-2 btn-success"
                                        disabled={this.disableActivateButton(sticker)}
                                        onClick={(event) =>
                                          this.setConfirmationModalState(event.target.innerHTML, sticker)
                                        }
                                      >
                                        Activate
                                      </Button>) : null}
                                  </Col>
                                </Row>
                              </Container>
                            </CardBody>
                          </Card>
                        </Col>}
                    </React.Fragment>
                  );
                }
                ) : <Container className="mt-5">
                  <h3 className="text-center">No Sticker available.</h3>
                </Container>
            }
          </Row>
        </InfiniteScroll>
        <ResponseModal
          show={this.state.successModalDisplay}
          onHide={this.successModalClose}
          modalheading={successModalHeading}
          modaltext={this.state.successModalText}
        />
        <ResponseModal
          show={this.state.failureModalDisplay}
          onHide={this.failureModalClose}
          modalheading={failureModalHeading}
          modaltext={this.state.failureModalText}
        />
        <ConfirmationModal
          show={this.state.confirmationModalIsOpen}
          modaltext={this.state.action}
          onConfirmationTrue={() => {
            this.setState({ confirmationModalIsOpen: false });
            if (this.state.action === allActions.deactivate) this.updateStatus("deactivate");
            else if (this.state.action === allActions.activate) this.updateStatus("activate");
          }}
          onHide={() => this.setState({ confirmationModalIsOpen: false })}
        />
      </Container >
    );
  }
}

export default PopTextStickers;

import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import {
    validateLocale,
    validateMinAppVersionKeyboardLanguages,
    isJSONParsable,
    error,
    errorDescription,
    redirectionOnTokenExpiry,
    redirectTo,
} from "../../../utilities/commonUtil";
import { formatJSON } from "../../../utilities/keyboardLanguageUtil";
import {
    Button,
    Container,
    Card,
    CardBody,
    Row,
    Form,
    Col,
    Label,
    FormGroup,
    Input,
} from "reactstrap";
import {
    fetchKeyboardLanguages,
    getParsedLanguages,
    createKeyboardLanguage,
    updateKeyboardLanguage,
    getTransliterationAlgorithms
} from "../../../utilities/apiUtils/keyboardLanguages";
import ResponseModal from "../ResponseModal";
import Routes from "../../../routes/index";
import Reorder, { reorder } from "react-reorder";
import {
    invalidLocaleAlertElementId,
    invalidLocaleAlertText,
    invalidLanguageIdAlertElementId,
    invalidMacronicKeyboardLanguageIdAlertElementId,
    invalidLanguageIdAlertText,
    keyboardLanguageCreatedStatus,
    keyboardLanguageCreatedText,
    keyboardLanguageUpdatedStatus,
    keyboardLanguageUpdatedText,
    successModalHeading,
    failureModalHeading,
    invalidMinAppVersionAlertElementId,
    invalidMinAppVersionAlertText,
    invalidMinBobbleSDKVersionAlertText,
    invalidMinBobbleSDKVersionAlertElementId,
    invalidLanguageCodeAlertElementId,
    invalidShortcutsAlertElementId,
    invalidShortcutsAlertText,
    deviceOptions,
    transliterationModels
} from "../../../config/keyboardLanguages";

const KeyboardLanguageForm = (props) => {
    const formType = props.formType;

    const [formTextState, setFormTextState] = useState({
        id: props.id,
        name: props.name,
        locale: props.locale,
        searchTags: props.searchTags,
        deviceType: props.deviceType,
        iconImage: props.iconImage,
        minAppVersion: props.minAppVersion,
        minBobbleSDKVersion: props.minBobbleSDKVersion,
        languageId: props.languageId,
        code: props.code,
        priority: props.priority,
        additionalDetails: props.additionalDetails,
        macaronicBaseKeyboardLanguageId: props.macaronicBaseKeyboardLanguageId,
        layouts: props.layouts,
        defaultLayoutId: props.defaultLayoutId,
    });

    const [useCustomTransliterationAlgoUsageOrder, setUsCustomTransliterationAlgoUsageOrder] =
        useState(props.additionalDetails.transliteration_algo_usage_orders &&
            props.additionalDetails.transliteration_algo_usage_orders.length > 0);

    const [languages, setLanguages] = useState([]);
    const [defaultLayoutOptions, setDefaultLayoutOptions] = useState([
        { label: "None", value: "" },
        ...formTextState.layouts.map((layout) => { return { label: `${layout.type} (id: ${layout.id})`, value: layout.id } })
    ]);
    var selectedDefaultLayout = props.layouts.find(layout => layout.id === props.defaultLayoutId)
    const [selectedDefaultLayoutOption, setSelectedDefaultLayoutOption] = useState(selectedDefaultLayout ? { label: `${selectedDefaultLayout.type} (id: ${selectedDefaultLayout.id})`, value: selectedDefaultLayout.id } : { label: "None", value: "" });
    const [macronicBaseKeyboardLanguageOptions, setMacronicBaseKeyboardLanguageOptions] = useState([]);

    const [deviceType, setDeviceType] = useState(props.deviceType ? { "value": props.deviceType, "label": props.deviceType[0].toUpperCase() + props.deviceType.substring(1) } : null);
    const [iconImage, setIconImage] = useState(null);
    const [iconImageName, setIconImageName] = useState(null);
    const [iconImageURL, setIconImageURL] = useState(props.iconImageURL ?? null);

    const [selectedLanguageCode, setSelectedLanguageCode] = useState({});
    const [shortcuts, setShortcuts] = useState(null)
    const [selectedMacronicBaseKeyboardLanguage, setSelectedMacronicBaseKeyboardLanguage] = useState({});
    const [additionalDetails, setAdditionalDetails] = useState(props.additionalDetails);
    const [transliterationAlgoUsageOrder, setTransliterationAlgoUsageOrder] = useState(props.additionalDetails.transliteration_algo_usage_orders ?? transliterationModels);

    const [successModalState, setSuccessModalState] = useState({
        successModalDisplay: false,
        successModalText: "",
    });
    const [failureModalState, setFailureModalState] = useState({
        failureModalDisplay: false,
        failureModalText: "",
    });


    // This useEffect will be called for componentDidMount condition
    useEffect(() => {
        const fetchLanguages = async () => {
            try {
                const response = await getParsedLanguages();
                if (response.errorCode) {
                    if (
                        response.errorCode === error.tokenExpired ||
                        response.errorCode === error.invalidAccessToken
                    )
                        redirectionOnTokenExpiry();
                    setFailureModalState({
                        failureModalDisplay: true,
                        failureModalText: errorDescription(response.errorCode),
                    });
                } else {
                    for (const element of response) {
                        if (element.value.split('-')[1] == props.languageId) {
                            selectedLanguageCode["value"] = element.code;
                            selectedLanguageCode["label"] = element.label;
                        }
                    }
                    setLanguages(response);
                    setSelectedLanguageCode(selectedLanguageCode);
                }
            } catch (err) {
                setFailureModalState({
                    failureModalDisplay: true,
                    failureModalText: errorDescription(error.unexpectedError),
                });
            }
        };
        const getKeyboardLanguages = async () => {
            try {
                let response = await fetchKeyboardLanguages();
                if (response.errorCode) {
                    if (
                        response.errorCode === error.tokenExpired ||
                        response.errorCode === error.invalidAccessToken
                    )
                        redirectionOnTokenExpiry();
                    setFailureModalState({
                        failureModalDisplay: true,
                        failureModalText: errorDescription(response.errorCode),
                    });
                } else {

                    response = response.keyboardLanguages.map((keyboardlanguage) => {
                        let newLanguage = {
                            label: `${keyboardlanguage.name}`,
                            value: keyboardlanguage.id,
                        };
                        return newLanguage;
                    });

                    for (const element of response) {
                        if (element.value === props.macaronicBaseKeyboardLanguageId) {
                            selectedMacronicBaseKeyboardLanguage["value"] = element.code;
                            selectedMacronicBaseKeyboardLanguage["label"] = element.label;
                        }
                    }
                    response.unshift({ label: "-", value: 0 });
                    setMacronicBaseKeyboardLanguageOptions(response);
                    setSelectedMacronicBaseKeyboardLanguage(selectedMacronicBaseKeyboardLanguage);
                }
            } catch (err) {
                setFailureModalState({
                    failureModalDisplay: true,

                    failureModalText: errorDescription(error.unexpectedError),
                });
            }
        }
        const fetchDefaultTransliterationAlgorithmPreferenceOrder = async () => {
            const useCustomOrder = props.additionalDetails.transliteration_algo_usage_orders
            if (useCustomOrder && Array.isArray(useCustomOrder) && useCustomOrder.length > 0) {
                return
            }

            try {
                const response = await getTransliterationAlgorithms();
                if (response && !response.errorCode) {
                    setTransliterationAlgoUsageOrder(response.transliterationModels)
                }
            } catch (err) {
                setTransliterationAlgoUsageOrder(transliterationModels)
            }
        }

        fetchLanguages();
        fetchDefaultTransliterationAlgorithmPreferenceOrder();
        getKeyboardLanguages();

        if (formType === "edit") {
            setShortcuts(
                additionalDetails &&
                    additionalDetails.shortcuts != null
                    ? JSON.stringify(additionalDetails.shortcuts)
                    : ""
            );
        }
    }, []);



    const handleFileChange = (event) => {
        const { files } = event.target;

        event.persist();
        // For showing Image preview
        if (files[0]) {
            setIconImage(files[0]);
            setIconImageName(files[0].name);
            setIconImageURL(URL.createObjectURL(files[0]));
        }
    };

    let handleDevice = (value) => {
        setDeviceType(value);
        setFormTextState({ ...formTextState, deviceType: value.value });
    };

    const showAlert = (elementId) => {
        document.getElementById(elementId).classList.remove("d-none");
    };

    const hideAlert = (elementId) => {
        document.getElementById(elementId).classList.add("d-none");
    };

    const handleChange = (event) => {
        hideAlert(invalidLocaleAlertElementId);
        hideAlert(invalidLanguageIdAlertElementId);
        hideAlert(invalidMinAppVersionAlertElementId);
        const { name, value } = event.target;
        setFormTextState((prev) => ({ ...prev, [name]: value }));
    };

    const handleAdditionalDetailsChange = (event) => {
        const { name, value } = event.target;
        setAdditionalDetails((prev) => ({ ...prev, [name]: value }));
    }

    useEffect(() => {
        if (shortcuts == "" || shortcuts == null) {
            setAdditionalDetails({ ...additionalDetails, shortcuts: null });
            return;
        }

        try {
            const shortcutsArray = JSON.parse(shortcuts);
            if (Array.isArray(shortcutsArray)) {
                setAdditionalDetails({ ...additionalDetails, shortcuts: shortcutsArray });
            } else {
                setAdditionalDetails({ ...additionalDetails, shortcuts: null });
            }
        } catch (err) {
            showAlert(invalidShortcutsAlertElementId);
            return;
        }
    }, [shortcuts])

    const handleShortcuts = (event) => {
        hideAlert(invalidShortcutsAlertElementId);
        const { value } = event.target;
        if (Array.isArray(value)) {
            setShortcuts(JSON.stringify(value));
        }
        else {
            setShortcuts(value);
        }

    };

    const handleLanguageChange = (language) => {
        hideAlert(invalidLanguageIdAlertElementId);
        setFormTextState((prev) => ({ ...prev, code: language.value.split('-')[0], languageId: language.value.split('-')[1] }));
        setSelectedLanguageCode(language);
    };

    const handleMacronianBaseKeyboardLanguageChange = (kBLanguage) => {
        setFormTextState((prev) => ({ ...prev, macaronicBaseKeyboardLanguageId: kBLanguage.value }));
        setSelectedMacronicBaseKeyboardLanguage(kBLanguage);
    };
    const handleDefaultLayoutChange = (option) => {
        setFormTextState((prev) => ({ ...prev, defaultLayoutId: option.value }));
        setSelectedDefaultLayoutOption(option);
    };
    const handleResponse = (response) => {
        if (response.status === keyboardLanguageCreatedStatus) {
            setSuccessModalState({
                successModalDisplay: true,
                successModalText: keyboardLanguageCreatedText,
            });
        } else if (response.status === keyboardLanguageUpdatedStatus) {
            setSuccessModalState({
                successModalDisplay: true,
                successModalText: keyboardLanguageUpdatedText,
            });
        } else {
            setFailureModalState({
                failureModalDisplay: true,
                failureModalText: response.errorDescription,
            });
        }
    };

    const successModalClose = () => {
        setSuccessModalState((prev) => ({ ...prev, successModalDisplay: false }));
        if (formType === "edit")
            redirectTo(`/kl/keyboard-languages/${props.id}/layouts`);
        else redirectTo(`/kl/keyboard-languages`);
    };

    const failureModalClose = () => {
        setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
    };

    const onReorder = (event, previousIndex, nextIndex, fromId, toId) => {
        setTransliterationAlgoUsageOrder(reorder(
            transliterationAlgoUsageOrder,
            previousIndex,
            nextIndex
        ),
        );
    };

    useEffect(() => {
        if (useCustomTransliterationAlgoUsageOrder)
            setAdditionalDetails({ ...additionalDetails, transliteration_algo_usage_orders: transliterationAlgoUsageOrder })
        else if (additionalDetails && additionalDetails.transliteration_algo_usage_orders) {
            setAdditionalDetails({ ...additionalDetails, transliteration_algo_usage_orders: null })
        }
    }, [useCustomTransliterationAlgoUsageOrder, transliterationAlgoUsageOrder])

    const handleSearchTagsChange = (e) => {
        setFormTextState({ ...formTextState, searchTags: e.target.value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        let isValid = validateMinAppVersionKeyboardLanguages(
            formTextState.minAppVersion
        );

        if (!isValid) {
            showAlert(invalidMinAppVersionAlertElementId);
        } else if (
            formTextState.code === "" ||
            formTextState.code === undefined
        ) {
            showAlert(invalidLanguageCodeAlertElementId);
        } else if (!validateLocale(formTextState.locale)) {
            showAlert(invalidLocaleAlertElementId);
        } else {
            let transliteration_algo_usage_orders =
                additionalDetails.transliteration_algo_usage_orders;
            if (transliteration_algo_usage_orders) {
                let len = transliteration_algo_usage_orders.length;
                transliteration_algo_usage_orders.forEach((algorithm, index) => {
                    transliteration_algo_usage_orders[index].priority =
                        (len - index) * 10;
                });
            }

            transliteration_algo_usage_orders = JSON.stringify(
                transliteration_algo_usage_orders
            );
            // API call
            if (formType === "create") {
                let newKeyboardLanguage = new FormData();
                newKeyboardLanguage.append("name", formTextState.name);
                newKeyboardLanguage.append("locale", formTextState.locale);
                newKeyboardLanguage.append("code", formTextState.code);
                newKeyboardLanguage.append("languageId", formTextState.languageId);
                newKeyboardLanguage.append("priority", formTextState.priority);
                newKeyboardLanguage.append("deviceType", formTextState.deviceType);
                newKeyboardLanguage.append("minAppVersion", formTextState.minAppVersion);
                newKeyboardLanguage.append("minBobbleSDKVersion", formTextState.minBobbleSDKVersion);
                if (formTextState.defaultLayoutId) {
                    newKeyboardLanguage.append("defaultLayoutId", formTextState.defaultLayoutId);
                }
                if ((typeof additionalDetails === "object" || typeof additionalDetails === 'function') && (additionalDetails !== null)) {
                    newKeyboardLanguage.append(
                        "additionalDetails",
                        JSON.stringify(additionalDetails)
                    );
                }
                

            if (formTextState.searchTags && formTextState.searchTags.length > 0) {
                const tags = formTextState.searchTags.split(',').map(tag => tag.trim());
                newKeyboardLanguage.append('searchTags', tags);
            }
                if (iconImage && (iconImage != "" || iconImage !== null)) {
                    newKeyboardLanguage.append("iconImage", iconImage);
                }
                if (formTextState.macaronicBaseKeyboardLanguageId) {
                    newKeyboardLanguage.append(
                        "macaronicBaseKeyboardLanguageId",
                        formTextState.macaronicBaseKeyboardLanguageId
                    );
                }
                try {
                    const response = await createKeyboardLanguage(newKeyboardLanguage);
                    handleResponse(response);
                } catch (err) {
                    setFailureModalState({
                        failureModalDisplay: true,
                        failureModalText: errorDescription(error.unexpectedError),
                    });
                }
            } else if (formType === "edit") {
                let updatedKeyboardLanguage = new FormData();
                updatedKeyboardLanguage.append("name", formTextState.name);
                updatedKeyboardLanguage.append("locale", formTextState.locale);
                updatedKeyboardLanguage.append("priority", formTextState.priority);
                updatedKeyboardLanguage.append("deviceType", formTextState.deviceType);
                updatedKeyboardLanguage.append("minAppVersion", formTextState.minAppVersion);
                updatedKeyboardLanguage.append("minBobbleSDKVersion", formTextState.minBobbleSDKVersion);
                updatedKeyboardLanguage.append("code", formTextState.code);
                updatedKeyboardLanguage.append("languageId", formTextState.languageId);
                if (formTextState.defaultLayoutId) {
                    updatedKeyboardLanguage.append("defaultLayoutId", formTextState.defaultLayoutId);
                }
                if ((typeof additionalDetails === "object" || typeof additionalDetails === 'function') && (additionalDetails !== null)) {
                    updatedKeyboardLanguage.append(
                        "additionalDetails",
                        JSON.stringify(additionalDetails)
                    );
                }
                 // Append searchTags if it exists
                // Ensure searchTags is a string before processing
                if (formTextState.searchTags && typeof formTextState.searchTags === 'string' && formTextState.searchTags.length > 0) {
                    // Split the string into an array of tags and trim any extra spaces
                    const tags = formTextState.searchTags.split(',').map(tag => tag.trim());

                    // Convert the array back to a comma-separated string
                    const tagsString = tags.join(',');

                    // Append the comma-separated string to FormData
                    updatedKeyboardLanguage.append('searchTags', tagsString);
                } else {
                    // Handle cases where searchTags is empty or not a string
                    console.warn('searchTags is not a valid string:', formTextState.searchTags);
                }
                if (iconImage && (iconImage != "" || iconImage !== null)) {
                    updatedKeyboardLanguage.append("iconImage", iconImage);
                }
                if (formTextState.macaronicBaseKeyboardLanguageId) {
                    updatedKeyboardLanguage.append(
                        "macaronicBaseKeyboardLanguageId",
                        formTextState.macaronicBaseKeyboardLanguageId
                    );
                }
                try {
                    const response = await updateKeyboardLanguage(
                        updatedKeyboardLanguage,
                        formTextState.id
                    );
                    handleResponse(response);
                } catch (err) {
                    setFailureModalState({
                        failureModalDisplay: true,
                        failureModalText: errorDescription(error.unexpectedError),
                    });
                }
            }

        };
    };

    return (
        <Card className="mb-0">
            <CardBody>
                <Form onSubmit={(event) => handleSubmit(event)}>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label>
                                    Name <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    name="name"
                                    placeholder="Enter keyboard language name"
                                    defaultValue={formTextState.name}
                                    onChange={(event) => handleChange(event)}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>
                                    Select Language Code <span className="text-danger">*</span>{" "}
                                    <small
                                        id={invalidLanguageIdAlertElementId}
                                        className="text-danger d-none"
                                    >
                                        ({invalidLanguageIdAlertText})
                                    </small>
                                </Label>
                                <Select
                                    className="react-select-container dropdown"
                                    classNamePrefix="react-select"
                                    name="code"
                                    onChange={(event) => handleLanguageChange(event)}
                                    options={languages}
                                    value={selectedLanguageCode}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>
                                    Min App Version <span className="text-danger">*</span>
                                    <small
                                        id={invalidMinAppVersionAlertElementId}
                                        className="text-danger d-none"
                                    >
                                        ({invalidMinAppVersionAlertText})
                                    </small>
                                </Label>
                                <Input
                                    type="number"
                                    name="minAppVersion"
                                    placeholder="Enter min app version"
                                    defaultValue={formTextState.minAppVersion}
                                    onChange={(event) => handleChange(event)}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>
                                    Min Bobble SDK Version <span className="text-danger">*</span>
                                    <small
                                        id={invalidMinBobbleSDKVersionAlertElementId}
                                        className="text-danger d-none"
                                    >
                                        ({invalidMinBobbleSDKVersionAlertText})
                                    </small>
                                </Label>
                                <Input
                                    type="number"
                                    name="minBobbleSDKVersion"
                                    placeholder="Enter min Bobble SDK version"
                                    defaultValue={formTextState.minBobbleSDKVersion}
                                    onChange={(event) => handleChange(event)}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>
                                    Priority <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="number"
                                    name="priority"
                                    placeholder="Enter priority"
                                    defaultValue={formTextState.priority}
                                    onChange={(event) => handleChange(event)}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>
                                    Locale <span className="text-danger">*</span>{" "}
                                    <small
                                        id={invalidLocaleAlertElementId}
                                        className="text-danger d-none"
                                    >
                                        ({invalidLocaleAlertText})
                                    </small>
                                </Label>
                                <Input
                                    type="text"
                                    name="locale"
                                    placeholder="Enter locale"
                                    defaultValue={formTextState.locale}
                                    onChange={(event) => handleChange(event)}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                        <FormGroup>
                            <Label>
                                Search Tags
                            </Label>
                            <Input
                                type="text"
                                name="searchTags"
                                placeholder="Enter Search Tags, separated by commas"
                                value={formTextState.searchTags} // Display tags as string
                                onChange={handleSearchTagsChange}
                            />
                        </FormGroup>
                        </Col>
                        <Col md={6} className="">
                            <Label>
                                Device Type <span className="text-danger">*</span>
                                {/* <small
                  id="invalidMinBobbleSDKVersionAlert"
                  className="text-danger d-none"
                >
                  (Enter a valid Min App Version)
                </small> */}
                            </Label>
                            <Select
                                className="react-select-container"
                                id="statusSelect"
                                classNamePrefix="react-select"
                                name="deviceType"
                                placeholder="Select Device"
                                value={deviceType}
                                onChange={handleDevice}
                                options={deviceOptions}
                            />
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>
                                    Personalized Dictionary Decay Min Threshold:
                                    <small
                                        id={invalidMinAppVersionAlertElementId}
                                        className="text-danger d-none"
                                    >
                                        ({invalidMinAppVersionAlertText})
                                    </small>
                                </Label>
                                <Input
                                    type="number"
                                    name="personalized_dictionary_decay_min_threshold"
                                    placeholder="Enter Min Threshold"
                                    defaultValue={additionalDetails.personalized_dictionary_decay_min_threshold}
                                    onChange={(event) => handleAdditionalDetailsChange(event)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>
                                    Min Transliteration App Version:
                                    <small
                                        id={invalidMinAppVersionAlertElementId}
                                        className="text-danger d-none"
                                    >
                                        ({invalidMinAppVersionAlertText})
                                    </small>
                                </Label>
                                <Input
                                    type="number"
                                    name="min_transliteration_app_version"
                                    placeholder="Enter Min Threshold"
                                    defaultValue={additionalDetails.min_transliteration_app_version}
                                    onChange={(event) => handleAdditionalDetailsChange(event)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>
                                    Select Macronian Base Keyboard Language
                                </Label>
                                <Select
                                    className="react-select-container dropdown"
                                    classNamePrefix="react-select"
                                    name="macronian_base_keyboard_language"
                                    onChange={(event) => handleMacronianBaseKeyboardLanguageChange(event)}
                                    options={macronicBaseKeyboardLanguageOptions}
                                    value={selectedMacronicBaseKeyboardLanguage}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>
                                    Select Default Layout
                                </Label>
                                <Select
                                    className="react-select-container dropdown"
                                    classNamePrefix="react-select"
                                    name="default_layout"
                                    onChange={(event) => handleDefaultLayoutChange(event)}
                                    options={defaultLayoutOptions}
                                    value={selectedDefaultLayoutOption}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={7}>
                            <FormGroup>
                                <Label>
                                    <strong>
                                        Icon Image
                                        <small className="text-info">
                                            (Choose an image)
                                        </small>
                                    </strong>
                                </Label>
                                <Container className="custom-file">
                                    <Input
                                        type="file"
                                        className="custom-file-input"
                                        accept="image/*"
                                        name="darkModePreviewImage"
                                        onChange={(event) => handleFileChange(event)}
                                        disabled={false}
                                    />
                                    <Label className="custom-file-label" id="iconImage">
                                        <strong>{iconImageName === null || iconImageName === ""
                                            ? "Choose an image"
                                            : "Image File: " + iconImageName}</strong>
                                    </Label>
                                </Container>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            {iconImageURL && iconImageURL !== "" &&
                                iconImageURL !== null ? (
                                <Col className="pb-4">
                                    <img
                                        src={iconImageURL}
                                        height="120"
                                        alt="iconImage"
                                        width="120"
                                    />
                                </Col>
                            ) : (
                                <Col></Col>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="custom-switch custom-control mb-3">
                                <input
                                    type="checkbox"
                                    id="useCustomTransliterationAlgoUsageOrderId"
                                    name="useCustomTransliterationAlgoUsageOrder"
                                    className="custom-control-input"
                                    checked={useCustomTransliterationAlgoUsageOrder}
                                    onChange={() => {
                                        setUsCustomTransliterationAlgoUsageOrder(!useCustomTransliterationAlgoUsageOrder);
                                    }}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="useCustomTransliterationAlgoUsageOrderId"
                                >
                                    Use Custom Transliteration Usage Order
                                </label>
                                <small className="ml-2 text-info">
                                    (If Disabled, Default Order from Configs will be used)
                                </small>
                            </div>
                        </Col>
                        {useCustomTransliterationAlgoUsageOrder ?
                            (
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>
                                            <b>Transliteration Usage Order</b>
                                        </Label>
                                        <Reorder
                                            lock="horizontal"
                                            reorderId="reorder-list"
                                            onReorder={onReorder}
                                        >
                                            {transliterationAlgoUsageOrder && transliterationAlgoUsageOrder.map(
                                                (algorithm, index) => {
                                                    return (
                                                        <div key={index} className="transliterationReorder">
                                                            {algorithm.displayName}
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </Reorder>
                                    </FormGroup>
                                </Col>
                            ) : null}
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <Label>Shortcuts</Label>
                                <small
                                    id={invalidShortcutsAlertElementId}
                                    className="text-danger d-none"
                                >
                                    ({invalidShortcutsAlertText})
                                </small>
                                <Input
                                    type="textarea"
                                    name="shortcuts"
                                    onChange={(event) => handleShortcuts(event)}
                                    style={{ height: "6rem" }}
                                    placeholder="Enter shortcuts"
                                    value={shortcuts}
                                    defaultValue={formType === "edit" ? shortcuts : null}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Button color="primary" type="submit">
                        Submit
                    </Button>
                </Form>
            </CardBody>
            <ResponseModal
                show={successModalState.successModalDisplay}
                onHide={() => successModalClose()}
                modalheading={successModalHeading}
                modaltext={successModalState.successModalText}
            />
            <ResponseModal
                show={failureModalState.failureModalDisplay}
                onHide={() => failureModalClose()}
                modalheading={failureModalHeading}
                modaltext={failureModalState.failureModalText}
            />
        </Card>
    );
}
export default KeyboardLanguageForm
import React, { useEffect, useState } from 'react'
import { Modal, Button } from "react-bootstrap";
import { Row, Col, FormGroup, Label, Input, Form } from "reactstrap";
import Select, { components } from "react-select";
import { fetchPlacements, fetchVendorsByPlacementId } from '../../../../utilities/apiUtils/configAPI';
import ToolTip from '../../../../utilities/ToolTip';

const MixingStrategyModal = ({ show = false, type, formData, setFormData = () => { }, onSubmit = () => { }, onHide = () => { } }) => {
  const [data, setData] = useState({
    id: "",
    placementId: "",
    clientAdVendorId: "",
    numberOfAds: "",
    priority: ""
  })
  const [placementOptions, setPlacementOptions] = useState([])
  const [vendorOptions, setVendorOptions] = useState([])

  const fetchPlacementOptions = async () => {
    try {
      let response = await fetchPlacements()
      if (response.length > 0) {
        setPlacementOptions(response.map(placement => ({
          value: placement.id,
          label: placement.description,
          target: {
            name: "placementId",
            value: {
              value: placement.id,
              label: placement.description
            }
          }
        })))
      }
    } catch (err) {
      console.log(err);
    }
  }

  const fetchVendorOptions = async (id) => {
    try {
      let response = await fetchVendorsByPlacementId(id)
      if (response?.length > 0) {
        setVendorOptions(response.map(vendor => ({
          value: vendor.id,
          label: vendor.name,
          target: {
            name: "clientAdVendorId",
            value: {
              value: vendor.id,
              label: vendor.name
            }
          }
        })))
      } else {
        setVendorOptions([])
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchPlacementOptions()
    if (formData?.placementId)
      fetchVendorOptions(formData.placementId)
  }, [formData.placementId])

  useEffect(() => {
    if (formData?.placementId) {
      setData({
        id: formData?.id,
        placementId: {
          label: placementOptions.find(option => option.value === formData?.placementId)?.label ?? formData?.placementId,
          value: formData?.placementId
        },
        clientAdVendorId: {
          label: vendorOptions.find(option => option.value === formData?.clientAdVendorId)?.label ?? formData?.clientAdVendorId,
          value: formData?.clientAdVendorId
        },
        numberOfAds: formData?.numberOfAds,
        priority: formData?.priority
      })
    } else {
      setData({
        id: "",
        placementId: "",
        clientAdVendorId: "",
        numberOfAds: "",
        priority: ""
      })
    }
  }, [formData, placementOptions, vendorOptions])

  const handleChange = (event) => {
    const { name, value } = event.target
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = {
      id: data.id,
      placementId: data.placementId?.value,
      clientAdVendorId: data.clientAdVendorId?.value,
      numberOfAds: data.numberOfAds,
      priority: data.priority
    }
    setFormData(payload)
    onSubmit(payload)
  }

  const requiredComponent = (props) => (
    <div>
      <components.Input {...props} required={true} />
    </div>
  );

  const notRequiredComponent = (props) => (
    <div>
      <components.Input {...props} required={false} />
    </div>
  );

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header className="bg-light" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {type} Mixing Strategy
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row form>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Placement <span className="text-danger">*</span>
                </Label>
                <Select
                  id="placementId"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="placementId"
                  placeholder="Select Placement"
                  value={data.placementId}
                  onChange={handleChange}
                  options={placementOptions}
                  isDisabled={true}
                  components={{
                    Input:
                      (data.placementId?.value)
                        ? notRequiredComponent
                        : requiredComponent,
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Vendor <span className="text-danger">* </span>
                  {vendorOptions?.length === 0 &&
                    <ToolTip
                      data={`No active vendors are mapped with placement ID ${data?.placementId?.value}. Please map vendors in manage placement to vendors mapping to continue.`}
                      id="clientVendorIdTooltip"
                    />
                  }
                </Label>
                <Select
                  id="clientAdVendorId"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="clientAdVendorId"
                  placeholder="Select Vendor"
                  value={data.clientAdVendorId}
                  onChange={handleChange}
                  options={vendorOptions}
                  components={{
                    Input:
                      (data.clientAdVendorId?.value)
                        ? notRequiredComponent
                        : requiredComponent,
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  No. of Ads <span className="text-danger">*</span>
                </Label>
                <Input
                  type="number"
                  name="numberOfAds"
                  min={0}
                  placeholder="Enter number"
                  value={data.numberOfAds}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Priority <span className="text-danger">*</span>
                </Label>
                <Input
                  type="number"
                  name="priority"
                  min={0}
                  placeholder="Enter Priority"
                  value={data.priority}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
          <Button type='submit' className='btn-success' disabled={vendorOptions?.length === 0}>Submit</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default MixingStrategyModal
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  Badge,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import {
  fetchKeyboardPromptsForSegment,
  createKeyboardPromptsPriorityRuleSet,
} from "../../../../utilities/apiUtils/keyboardPromptsOrder";
import { fetchSegmentsData } from "../../../../utilities/apiUtils/segments";

const PromptsRulesetForm = () => {
  const [segments, setSegments] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [priority, setPriority] = useState("");
  const [description, setDescription] = useState("");
  const [selectedKeyboardPrompts, setSelectedKeyboardPrompts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [filterStatus, setFilterStatus] = useState("All"); // State for filtering
  const [draggedPromptIndex, setDraggedPromptIndex] = useState(null); // For drag and drop
  const history = useHistory();

  useEffect(() => {
    const loadSegments = async () => {
      const data = await fetchSegmentsData();
      setSegments(data);
    };
    loadSegments();
  }, []);

  useEffect(() => {
    const loadKeyboardPrompts = async () => {
      if (selectedSegment) {
        const data = await fetchKeyboardPromptsForSegment(selectedSegment);
        if (data && Array.isArray(data.prompts)) {
          // Filter prompts to only include those with exactly ["pills"] as supportedUITypes
          const filteredPrompts = data.prompts.filter(prompt => {
            const supportedTypes = prompt.supportedUITypes || [];
            return supportedTypes.length === 1 && supportedTypes[0] === "pills";
          });
          setSelectedKeyboardPrompts(filteredPrompts);
        } else {
          console.error("Unexpected data structure:", data);
        }
      }
    };
    loadKeyboardPrompts();
  }, [selectedSegment]);

  const handleSegmentChange = (event) => {
    setSelectedSegment(event.target.value);
  };

  const validateForm = () => {
    if (!selectedSegment) {
      setError("Please select a segment.");
      return false;
    }
    if (!priority) {
      setError("Please enter a priority.");
      return false;
    }
    if (selectedKeyboardPrompts.length === 0) {
      setError("Please select at least one keyboard prompt.");
      return false;
    }
    setError(null);
    return true;
  };

  // Handle dragging by storing the dragged prompt's ID
  const handleDragStart = (promptId) => {
    setDraggedPromptIndex(
      selectedKeyboardPrompts.findIndex((prompt) => prompt.id === promptId)
    );
  };

  // Handle dropping by using the prompt's ID to identify its position in the original list
  const handleDrop = (promptId) => {
    const dropIndex = selectedKeyboardPrompts.findIndex(
      (prompt) => prompt.id === promptId
    );

    if (draggedPromptIndex !== null && draggedPromptIndex !== dropIndex) {
      const updatedPrompts = [...selectedKeyboardPrompts];
      const [draggedPrompt] = updatedPrompts.splice(draggedPromptIndex, 1);
      updatedPrompts.splice(dropIndex, 0, draggedPrompt);
      setSelectedKeyboardPrompts(updatedPrompts);
      setDraggedPromptIndex(null);
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const keyboardPromptIds = filteredKeyboardPrompts.map(
      (prompt) => prompt.id
    );

    const payload = {
      promptIDs: keyboardPromptIds.length > 0 ? keyboardPromptIds : null, // Store the filtered and ordered IDs
      segmentID: parseInt(selectedSegment, 10),
      priority: parseInt(priority, 10),
      description: description,
    };

    setLoading(true);
    try {
      await createKeyboardPromptsPriorityRuleSet(payload);
      setModalMessage("Priority rule set created successfully!");
    } catch (error) {
      console.error("Error creating priority rule set:", error);
      setModalMessage("Failed to create priority rule set. Please try again.");
    } finally {
      setLoading(false);
      setModalOpen(true);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    history.push("/keyboard-prompt/prompts/segmentOrder");
  };

  const getKeyboardPromptStatus = (prompt) => {
    const { publishedAt, deactivatedAt } = prompt;
    if (deactivatedAt) {
      return { status: "Deactivated", color: "red" };
    }
    if (publishedAt) {
      return { status: "Activated", color: "green" };
    }
    return { status: "Unpublished", color: "yellow" };
  };

  // Filter keyboard prompts based on the filterStatus
  const filteredKeyboardPrompts = selectedKeyboardPrompts.filter((prompt) => {
    const { status } = getKeyboardPromptStatus(prompt);
    if (filterStatus === "All") return true;
    return status === filterStatus;
  });

  const handleDragOver = (event) => {
    event.preventDefault(); // Required to allow drop
  };

  // Render the filter dropdown for prompt status
  const renderFilterDropdown = () => (
    <FormGroup>
      <Label for="filterStatus" style={{ fontWeight: "bold", color: "#333" }}>
        Filter Keyboard Pills
      </Label>
      <Input
        type="select"
        id="filterStatus"
        value={filterStatus}
        onChange={(e) => setFilterStatus(e.target.value)}
        style={{ borderRadius: "5px", fontSize: "13px", marginBottom: "20px" }}
      >
        <option value="All">All</option>
        <option value="Activated">Activated</option>
        <option value="Deactivated">Deactivated</option>
        <option value="Unpublished">Unpublished</option>
      </Input>
    </FormGroup>
  );

  // Render keyboard prompt cards
  const renderKeyboardPromptCards = () => {
    if (filteredKeyboardPrompts.length === 0) {
      return <p>No keyboard prompts available.</p>;
    }

    const rows = [];

    for (let i = 0; i < filteredKeyboardPrompts.length; i += 10) {
      const rowItems = filteredKeyboardPrompts.slice(i, i + 10);
      rows.push(
        <div
          key={i}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "0.5rem",
            gap: "0.4rem",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          {rowItems.map((prompt, index) => {
            const actions = prompt.actions || [];
            const displaySettings = actions[0]?.displaySettings || {};
            const bannerImageURL = displaySettings?.bannerImageURL;
            const leftIconImageURL = displaySettings?.leftIcon?.imageURL;
            const imageURL =
              bannerImageURL && bannerImageURL.trim() !== ""
                ? bannerImageURL
                : leftIconImageURL && leftIconImageURL.trim() !== ""
                ? leftIconImageURL
                : "N/A";
            const { status, color } = getKeyboardPromptStatus(prompt);
            const badgeColor =
              color === "red"
                ? "badge-danger"
                : color === "green"
                ? "badge-success"
                : "badge-warning";

            return (
              <div
                key={`${prompt.id}_${index}`}
                draggable
                onDragStart={() => handleDragStart(prompt.id)}
                onDragOver={handleDragOver}
                onDrop={() => handleDrop(prompt.id)}
                style={{
                  padding: "0.4rem",
                  border: "1px solid #e1e1e1",
                  borderRadius: "6px",
                  backgroundColor: "#fff",
                  cursor: "grab",
                  width: "calc((100% - 3.6rem) / 10)",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.2s ease-in-out",
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.transform = "scale(1.02)";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                }}
              >
                <div className="banner-image mb-2" style={{ width: "100%" }}>
                  {imageURL === "N/A" ? (
                    <p style={{ color: "#888", fontSize: "0.75rem" }}>No image</p>
                  ) : (
                    <img
                      src={imageURL}
                      alt={`${prompt.name} banner`}
                      style={{
                        maxWidth: "100%",
                        height: "40px",
                        objectFit: "contain",
                        borderRadius: "4px",
                      }}
                    />
                  )}
                </div>
                <h4 className="card-title" style={{ fontSize: "0.85rem", fontWeight: "600", marginBottom: "0.25rem" }}>
                  {prompt.name}
                </h4>
                <p className="card-text" style={{ fontSize: "0.75rem", color: "#555", marginBottom: "0.25rem" }}>
                  ID: {prompt.id}
                </p>
                <Badge
                  className={`${badgeColor}`}
                  style={{
                    fontSize: "0.75rem",
                    padding: "0.25rem 0.5rem",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                >
                  {status}
                </Badge>
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <div style={{ 
        maxHeight: "60vh", 
        overflowY: "auto",
        padding: "0.5rem",
        border: "1px solid #dee2e6",
        borderRadius: "0.25rem",
        marginBottom: "1rem"
      }}>
        {rows}
      </div>
    );
  };

  return (
    <Container fluid>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <h2
                style={{
                  textAlign: "center",
                  marginBottom: "20px",
                  color: "#4A4A4A",
                }}
              >
                Create Priority Rule Set for Keyboard Pills
              </h2>
              {error && <Alert color="danger">{error}</Alert>}
              <FormGroup>
                <Label
                  for="segmentSelect"
                  style={{ fontWeight: "bold", color: "#333" }}
                >
                  Select a Segment <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  type="select"
                  id="segmentSelect"
                  value={selectedSegment || ""}
                  onChange={handleSegmentChange}
                  style={{
                    borderRadius: "5px",
                    fontSize: "13px",
                    marginBottom: "20px",
                  }}
                >
                  <option value="">Select a Segment</option>
                  {segments.map((segment) => (
                    <option key={segment.id} value={segment.id}>
                      {segment.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>

              <FormGroup>
                <Label
                  for="priorityInput"
                  style={{ fontWeight: "bold", color: "#333" }}
                >
                  Priority <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  type="text"
                  id="priorityInput"
                  value={priority}
                  onChange={(e) => setPriority(e.target.value)}
                  placeholder="Enter priority"
                  style={{
                    borderRadius: "5px",
                    fontSize: "13px",
                    marginBottom: "20px",
                  }}
                />
              </FormGroup>

              <FormGroup>
                <Label
                  for="descriptionInput"
                  style={{ fontWeight: "bold", color: "#333" }}
                >
                  Description
                </Label>
                <Input
                  type="textarea"
                  id="descriptionInput"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter description"
                  style={{
                    borderRadius: "5px",
                    fontSize: "13px",
                    marginBottom: "20px",
                  }}
                />
              </FormGroup>

              <div style={{ marginBottom: "1rem" }}>
                <Row>
                  <Col md={6}>
                    {renderFilterDropdown()}
                  </Col>
                  <Col md={6} className="text-right">
                    <span style={{ fontSize: "0.9rem", color: "#666" }}>
                      Total Pills: {filteredKeyboardPrompts.length}
                    </span>
                  </Col>
                </Row>
              </div>

              {renderKeyboardPromptCards()}

              <Button 
                color="primary" 
                onClick={handleSubmit} 
                disabled={loading}
                style={{ marginTop: "1rem" }}
              >
                {loading ? "Creating..." : "Create Rule Set"}
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={modalOpen} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>Message</ModalHeader>
        <ModalBody>{modalMessage}</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default PromptsRulesetForm;

import { Link } from "react-router-dom";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas, faUpload, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  getStatus,
  getBtnName,
  getButtonColor,
  getStatusColor,
} from "../../../../utilities/commonUtil";
import { Config } from "../../../../config/UserConfig";
import {
  Badge,
  Card,
  CardBody,
  Button,
  Col,
  Row,
} from "reactstrap";
const ContentPromptCard = (props) => {
  let getIcon = (status) => {
    if (status === Config.PUBLISHED_STATUS) return faTimes;
    return faUpload;
  };
  let { prompt, name } = props;
  return (
    <Card>
      <CardBody>
        <Badge
          color={getStatusColor(
            getStatus(prompt.publishedAt, prompt.deactivatedAt)
          )}
          className="d-block float-right "
        >
          {getStatus(prompt.publishedAt, prompt.deactivatedAt)}
        </Badge>
        <span>
          <strong>ID:</strong> {prompt.id}
        </span>
        <br />

        <span>
          <strong>Type:</strong> {prompt.type}
        </span>
        <br />
        <span>
          <strong>Priority:</strong> {prompt.priority}
        </span>
        <br />
        <span>
          <strong>Min App Version:</strong> {prompt.minAppVersion}
        </span>
        <br />
        <span>
          <strong>Start At:</strong> {prompt.startAt}
        </span>
        <br />
        <span>
          <strong>End At:</strong> {prompt.endAt}
        </span>
        <br />
        
        <span>
          <strong>Content Updated At:</strong> {prompt.contentUpdatedAt}
        </span>
        <br />
        <span>
          <strong>Text:</strong> {prompt.additionalDetails.text ? prompt.additionalDetails.text.en : null}
        </span>
        <br />
        <Row className="mt-2">
          <Col md={6} className="my-2">
            <Link
              className="d-block ml-auto text-decoration-none"
              to={{
                pathname: `/cp/content-prompts/${prompt.id}`,
                state: {
                  name: name,
                  contentUpdatePromptDetail: prompt,
                  contentUpdatePromptId: prompt.id,
                },
              }}
            >
              <Button className="btn-block btn-info">Details</Button>
            </Link>
          </Col>
          <Col md={6} className="my-2">
            <Button
              color={getButtonColor(
                getStatus(prompt.publishedAt, prompt.deactivatedAt)
              )}
              className="btn-block"
              onClick={() => {
                props.setConfirmationModalState(
                  prompt.id,
                  getBtnName(
                    getStatus(prompt.publishedAt, prompt.deactivatedAt)
                  )
                );
              }}
            >
              {getBtnName(getStatus(prompt.publishedAt, prompt.deactivatedAt))}
              <FontAwesomeIcon
                icon={
                  (fas,
                    getIcon(getStatus(prompt.publishedAt, prompt.deactivatedAt)))
                }
                className="ml-2"
              ></FontAwesomeIcon>
            </Button>
          </Col>
          <Col md={12} className="my-2">
            <Link
              className="d-block ml-auto mb-4 text-decoration-none"
              to={{
                  pathname: `/cp/content-prompts/create`,
                  state: {
                      formType: "duplicate",
                      contentUpdatePromptId: prompt.id,
                      contentPromptDetail: prompt,
                  },
              }}
            >
              <Button className="btn-block" color="primary">Clone This Prompt</Button>
            </Link>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ContentPromptCard;

import React from "react";
import { Badge } from "reactstrap";

const statusFormatter = (cell, row) => {
  if (new Date(cell) > new Date() || cell === null) {
    if (row.endDateTime !== null && new Date(row.endDateTime) < new Date()) {
      return <Badge className="badge-danger">{"Expired"}</Badge>;
    } else {
      return <Badge className="badge-success">{"Activated"}</Badge>;
    }
  } else {
    // If deactivatedAt exists and is in the past
    return <Badge className="badge-danger">{"Deactivated"}</Badge>;
  }
};
export const SuggestionDrawerCategoryColumns = [
  {
    dataField: "id",
    text: "#",
    headerAttrs: { width: 60 },
    searchable: false,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "name",
    text: "Name",
    searchable: true,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "deactivatedAt",
    text: "Status",
    searchable: false,
    style: {
      cursor: "pointer",
    },
    formatter: statusFormatter,
  },
];

const tableColumns = [
  {
    dataField: "id",
    text: "#",
    sort: true,
    headerAttrs: { width: 60 },
    searchable: true,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "ruleId",
    text: "Rule ID",
    headerAttrs: { width: 100 },
    sort: true,
    searchable: true,
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "description",
    text: "Description",
    headerAttrs: { width: 250 },
    sort: true,
    searchable: true,
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "segmentId",
    text: "Segment ID",
    headerAttrs: { width: 100 },
    sort: true,
    searchable: true,
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "priority",
    text: "Priority",
    headerAttrs: { width: 120 },
    sort: true,
    searchable: true,
    style: {
      cursor: "pointer",
      color: "#f00",
      fontWeight: "bold",
    },
  },
  {
    dataField: "createdAt",
    text: "Created At",
    headerAttrs: { width: 180 },
    sort: true,
    searchable: false,
    formatter: (cell) => new Date(cell).toLocaleString(),
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "updatedAt",
    text: "Updated At",
    headerAttrs: { width: 180 },
    sort: true,
    searchable: false,
    formatter: (cell) => new Date(cell).toLocaleString(),
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
];

export { tableColumns };
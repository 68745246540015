import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Container,
  Card,
  CardBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
import { fetchSingletask, updatetask } from "../../../utilities/apiUtils/tasks";
import ResponseModal from "../ResponseModal";
import { errorModalHeading } from "../../../config/config";
import CustomTooltip from "../../../components/customToolTip";

const EditTaskForm = (props) => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    identifier: "",
    listing_priority: 1,
    num_coins: "",
    cta_text: "",
    cta_action: "",
    static_icon_src: "",
    is_listed: 0,
    file_data_url: "", // Added for file upload
    type: "",
    daily_limit: "",
  });

  const history = useHistory();
  const [error, setError] = useState(null);

  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
    props.onHide();
  };

  useEffect(() => {
    const fetchTask = async () => {
      try {
        const response = await fetchSingletask(id);
        const taskData = response;

        setFormData({
          ...taskData,
          file_data_url: taskData.tutorial_asset_filename,
        });
      } catch (error) {
        console.error("An error occurred:", error);
        showErrorModal(
          "There was an error while fetching the task. Please try again."
        );
      }
    };

    fetchTask();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Preserve the image field when other fields are changed
    setFormData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  // Handle file input change
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // Read the file and convert it to a data URL
      const reader = new FileReader();
      reader.onload = (event) => {
        setFormData({ ...formData, file_data_url: event.target.result });
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      name: formData.name,
      description: formData.description,
      identifier: formData.identifier,
      listing_priority: parseInt(formData.listing_priority),
      num_coins: parseInt(formData.num_coins),
      cta_text: formData.cta_text,
      cta_action: formData.cta_action,
      static_icon_filename: "",
      static_icon_src: formData.file_data_url,
      redirection_link: formData.redirection_link,
      is_listed: parseInt(formData.is_listed),
      type: formData.type,
      daily_limit: parseInt(formData.daily_limit),
    };

    try {
      const response = await updatetask(id, payload);
      history.push("/tasks/view-tasks");
    } catch (error) {
      console.error("An error occurred while updating the task:", error);
      showErrorModal(
        "There was an error while updating the task. Please try again."
      );
    }
  };

  return (
    <Container className="task-form-container my-5">
      <Row className="justify-content-between align-items-center mb-4">
        <Col md={6}>
          <h1 className="task-form-title mb-4">Edit Task</h1>
        </Col>
        <Col md={6}>
          <div className="text-right">
            <Link to={`/taskEvents/${id}`}>
              <Button color="primary" className="mr-2">
                Task Events
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
      <Card className="task-form-card">
        <CardBody>
          {error && <div className="error-message">{error}</div>}
          <Form onSubmit={handleSubmit}>
            <FormGroup className="mb-3">
              <Label for="name">
                Name <span className="text-danger">*</span>
                {/* Tooltip for Name */}
                <CustomTooltip
                  target="identifier-tooltip"
                  content={<>Enter the name for the task.</>}
                  placement="right"
                />
              </Label>
              <Input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup className="mb-4">
              <Label for="identifier">
                Identifier <span className="text-danger">*</span>
                {/* Tooltip for Identifier */}
                <CustomTooltip
                  target="identifier-tooltip"
                  content={<>Enter the identifier for the task.</>}
                  placement="right"
                />
              </Label>
              <Input
                type="text"
                name="identifier"
                id="identifier"
                value={formData.identifier}
                onChange={handleChange}
                required
                className="task-form-input"
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="description">
                Description <span className="text-danger">*</span>
                <CustomTooltip
                  target="description-tooltip"
                  content={<>Enter the description for the task.</>}
                  placement="right"
                />
              </Label>
              <Input
                type="textarea"
                name="description"
                id="description"
                value={formData.description}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="listing_priority">
                Listing Priority <span className="text-danger">*</span>
                <CustomTooltip
                  target="priority-tooltip"
                  content={<>Enter the priority for the task.</>}
                  placement="right"
                />
              </Label>
              <Input
                type="number"
                name="listing_priority"
                id="listing_priority"
                value={formData.listing_priority}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="num_coins">
                Number of Coins <span className="text-danger">*</span>
                {/* Tooltip for Coins */}
                <CustomTooltip
                  target="coins-tooltip"
                  content={<>Enter the number of coins for the task.</>}
                  placement="right"
                />
              </Label>
              <Input
                type="number"
                name="num_coins"
                id="num_coins"
                value={formData.num_coins}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="cta_text">CTA Text</Label>
              <CustomTooltip
                target="cta-text-tooltip"
                content={<>Enter the call-to-action text for the task.</>}
                placement="right"
              />
              <Input
                type="text"
                name="cta_text"
                id="cta_text"
                value={formData.cta_text}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="cta_action">CTA Action</Label>
              <CustomTooltip
                target="cta-action-tooltip"
                content={<>Enter the call-to-action action for the task.</>}
                placement="right"
              />
              <Input
                type="text"
                name="cta_action"
                id="cta_action"
                value={formData.cta_action}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="is_listed">Is Listed</Label>
              {/* Tooltip for Is Listed */}
              <CustomTooltip
                target="is-listed-tooltip"
                content={<>Select whether the task should be listed or not.</>}
                placement="right"
              />
              <Input
                type="select"
                name="is_listed"
                id="is_listed"
                value={formData.is_listed}
                onChange={handleChange}
              >
                <option value={0}>No</option>
                <option value={1}>Yes</option>
              </Input>
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="file">File Upload</Label>
              {/* Tooltip for Image File */}
              <CustomTooltip
                target="image-file-tooltip"
                content={<>Upload an image file for the task.</>}
                placement="right"
              />
              <Input
                type="file"
                name="file"
                id="file"
                onChange={handleFileChange}
              />
              {formData.file_data_url && (
                <div className="task-form-image-preview mt-3">
                  <img
                    src={formData.file_data_url}
                    alt="Selected File"
                    className="img-thumbnail"
                    style={{ maxWidth: "150px" }}
                  />
                </div>
              )}
            </FormGroup>
            <FormGroup className="mb-4">
              <Label for="type">Type</Label>
              {/* Tooltip for Type */}
              <CustomTooltip
                target="type-tooltip"
                content={<>Select the type of task.</>}
                placement="right"
              />
              <Input
                type="select"
                name="type"
                id="type"
                value={formData.type}
                onChange={handleChange}
                className="task-form-input"
              >
                <option value="">Select type</option>
                <option value="once">Once</option>
                <option value="daily">Daily</option>
              </Input>
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="daily_limit">Daily Limit</Label>
              {/* Tooltip for Daily Limit */}
              <CustomTooltip
                target="daily-limit-tooltip"
                content={<>Enter the daily limit for the task.</>}
                placement="right"
              />
              <Input
                type="number"
                name="daily_limit"
                id="daily_limit"
                value={formData.daily_limit}
                onChange={handleChange}
              />
            </FormGroup>
            <Button type="submit" color="primary">
              Update Task
            </Button>
          </Form>
        </CardBody>
      </Card>
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={errorModalHeading}
        modaltext={failureModalText}
      />
    </Container>
  );
};

export default EditTaskForm;

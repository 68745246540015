import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ResponseModal from "../ResponseModal";
import { Link } from "react-router-dom";
import Select from "react-select";
import { errorDescription, error } from "../../../utilities/commonUtil";
import { getLanguages } from "../../../utilities/apiUtils/carousel";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import {
  editCarouselItem,
  getCarouselItemById,
} from "../../../utilities/apiUtils/carousel.js";

const EditItemForm = () => {
  const { carouselId, itemId } = useParams();
  const [languagesOptions, setLanguagesOptions] = useState([]);
  const [successModalState, setSuccessModalState] = useState({
    successModalDisplay: false,
    successModalText: "",
  });
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });

  const [item, setItem] = useState({
    type: "",
    aspectRatio: 1,
    maxCount: 1,
    trackers: [
      {
        type: "",
        url: "",
      },
    ],
    translations: [
      {
        languageCode: null,
        imageFilename: null,
        imageFile: null,
        darkThemeImageFilename: null,
        darkThemeImageFile: null,
      },
    ],
    deeplink: {
      app: "",
      keyboard: "",
      hamburgerMenu: "",
      settings: "",
    },
    backgroundColor: "",
    imageFile: null,
    imageFilename: "",
    imageURL: "",
    darkThemeImageURL: "",
    darkThemeImageFile: null,
    darkThemeImageFilename: "",
  });

  useEffect(() => {
    const fetchItemById = async () => {
      try {
        const fetchedItem = await getCarouselItemById(carouselId, itemId);
        setItem(fetchedItem);
      } catch (error) {
        console.error("Error fetching carousel item:", error.message);
      }
    };

    fetchItemById();
  }, [carouselId, itemId]);

  const getLanguagesOptions = async () => {
    try {
      let response = await getLanguages();
      // remove All if present
      response = response?.filter((item) => item?.value !== "All");
      setLanguagesOptions(response);
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const handleImageFileChange = (e, isDarkTheme) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setItem({
          ...item,
          [isDarkTheme ? "darkThemeImageFile" : "imageFile"]:
            event.target.result,
          [isDarkTheme ? "darkThemeImageURL" : "imageURL"]:
            URL.createObjectURL(selectedFile),
        });
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const removeTracker = (index) => {
    const updatedTrackers = [...item.trackers];
    updatedTrackers.splice(index, 1);
    setItem({
      ...item,
      trackers: updatedTrackers,
    });
  };

  const handleDeeplinkChange = (type, value) => {
    setItem({
      ...item,
      deeplink: {
        ...item.deeplink,
        [type]: value,
      },
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Convert maxCount to a number
    const updatedValue =
      name === "aspectRatio" || name === "maxCount" ? parseFloat(value) : value;

    setItem({
      ...item,
      [name]: updatedValue,
    });
  };

  const handleTrackerChange = (index, field, value) => {
    const updatedTrackers = [...item.trackers];
    updatedTrackers[index][field] = value;

    setItem({
      ...item,
      trackers: updatedTrackers,
    });
  };
  const addTracker = () => {
    setItem({
      ...item,
      trackers: [
        ...item.trackers,
        {
          type: "",
          url: "",
        },
      ],
    });
  };

  const handleTrackerTypeChange = (index, value) => {
    handleTrackerChange(index, "type", value);
  };

  const handleTranslationChange = (index, field, value) => {
    const updatedTranslations = [...item.translations];
    updatedTranslations[index][field] = value;

    setItem({
      ...item,
      translations: updatedTranslations,
    });
  };
  const handleTrackerImageFileChange = (index, field, file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      handleTranslationChange(index, field, event.target.result);
    };
    reader.readAsDataURL(file);
  };

  const addTranslation = () => {
    setItem({
      ...item,
      translations: [
        ...item.translations,
        {
          languageCode: null,
          imageFilename: null,
          imageFile: null,
          darkThemeImageFilename: null,
          darkThemeImageFile: null,
        },
      ],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        item: {
          ...item,
          ...(item.trackers.length > 0 && { trackers: item.trackers }),
        },
      };
      // Remove 'trackers' key if it's an empty array
      if (payload.item.trackers && payload.item.trackers.length === 0) {
        delete payload.item.trackers;
      }
      const success = await editCarouselItem(carouselId, itemId, payload);
      setSuccessModalState({
        successModalDisplay: true,
        successModalText: "Item updated successfully",
      });
    } catch (error) {
      console.error("Error:", error);
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: "Error occurred while updating the item",
      });
    }
  };

  useEffect(() => {
    getLanguagesOptions();
  }, []);

  return (
    <Container className="carousel-form-container my-5">
      <Row className="justify-content-between align-items-center mb-4">
        <Col md={6}>
          <h1 className="carousel-form-title mb-0">Edit Carousel Item</h1>
        </Col>
        <Col md={6} className="text-right">
          <Link to={`/cre/carousel-details/${carouselId}`}>
            <Button color="primary" className="ml-2">
              Back to Carousel
            </Button>
          </Link>
        </Col>
      </Row>
      <Card className="carousel-form-card">
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="type">Type</Label>
                  <Input
                    type="select"
                    name="type"
                    id="type"
                    value={item.type}
                    onChange={handleChange}
                    className="carousel-form-input"
                  >
                    <option value="generic">generic</option>
                    <option value="grant_permission">grant_permission</option>
                    <option value="contact">contact</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="aspectRatio">Aspect Ratio</Label>
                  <Input
                    type="number"
                    name="aspectRatio"
                    value={item.aspectRatio}
                    onChange={handleChange}
                    className="carousel-form-input"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="maxCount">Max Count</Label>
                  <Input
                    type="number"
                    name="maxCount"
                    value={item.maxCount}
                    onChange={handleChange}
                    className="carousel-form-input"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="lightImageFile">Light Image File</Label>
                  <Input
                    type="file"
                    name="lightImageFile"
                    id="lightImageFile"
                    onChange={(e) => handleImageFileChange(e, false)}
                    className="carousel-form-input"
                  />
                  {item.imageURL && (
                    <div className="carousel-form-image-preview mt-3">
                      <img
                        src={item.imageURL}
                        alt="Selected File"
                        className="carousel-form-preview-image img-thumbnail"
                        style={{ maxWidth: "150px" }}
                      />
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label for="appDeeplink">App Deeplink</Label>
                  <Input
                    type="text"
                    name="appDeeplink"
                    value={item.deeplink.app}
                    onChange={(e) =>
                      handleDeeplinkChange("app", e.target.value)
                    }
                    className="carousel-form-input"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="keyboardDeeplink">Keyboard Deeplink</Label>
                  <Input
                    type="text"
                    name="keyboardDeeplink"
                    value={item.deeplink.keyboard}
                    onChange={(e) =>
                      handleDeeplinkChange("keyboard", e.target.value)
                    }
                    className="carousel-form-input"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="hamburgerMenuDeeplink">
                    Hamburger Menu Deeplink
                  </Label>
                  <Input
                    type="text"
                    name="hamburgerMenuDeeplink"
                    value={item.deeplink.hamburgerMenu}
                    onChange={(e) =>
                      handleDeeplinkChange("hamburgerMenu", e.target.value)
                    }
                    className="carousel-form-input"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="settingsDeeplink">Settings Deeplink</Label>
                  <Input
                    type="text"
                    name="settingsDeeplink"
                    value={item.deeplink.settings}
                    onChange={(e) =>
                      handleDeeplinkChange("settings", e.target.value)
                    }
                    className="carousel-form-input"
                  />
                </FormGroup>
                <FormGroup>
                  <Label
                    className="font-weight-bolder mb-2"
                    style={{ fontSize: "16px" }}
                  >
                    Background Color (Hex):{" "}
                    <span className="text-danger">*</span>{" "}
                    <div
                      style={{
                        backgroundColor: item.backgroundColor,
                        width: "16px",
                        height: "16px",
                        display: "inline-block",
                        verticalAlign: "middle",
                        border: "1px solid #000",
                        borderRadius: "30%",
                      }}
                    >
                      {" "}
                    </div>
                  </Label>
                  <Input
                    type="text"
                    name="backgroundColor"
                    id="backgroundColor"
                    value={item.backgroundColor}
                    onChange={handleChange}
                    required
                    className="carousel-form-input"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="darkImageFile">Dark Image File</Label>
                  <Input
                    type="file"
                    name="darkImageFile"
                    id="darkImageFile"
                    onChange={(e) => handleImageFileChange(e, true)}
                    className="carousel-form-input"
                  />
                  {item.darkThemeImageURL && (
                    <div className="carousel-form-image-preview mt-3">
                      <img
                        src={item.darkThemeImageURL}
                        alt="Selected File"
                        className="carousel-form-preview-image img-thumbnail"
                        style={{ maxWidth: "150px" }}
                      />
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <h3>Trackers</h3>
            <Card>
              <CardBody>
                {item.trackers.map((tracker, index) => (
                  <Row key={index} form>
                    <Col md={6}>
                      <FormGroup>
                        <Label>Tracker Type</Label>
                        <Input
                          type="select"
                          value={tracker.type}
                          onChange={(e) =>
                            handleTrackerTypeChange(index, e.target.value)
                          }
                        >
                          <option value="">Select Tracker Type</option>
                          <option value="click">Click</option>
                          <option value="impression">Impression</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>Tracker URL</Label>
                        <Input
                          type="text"
                          placeholder="Tracker URL"
                          value={tracker.url}
                          onChange={(e) =>
                            handleTrackerChange(index, "url", e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12} className="text-right">
                      <Button
                        color="danger"
                        onClick={() => removeTracker(index)}
                      >
                        Remove Tracker
                      </Button>
                    </Col>
                  </Row>
                ))}
                <Button color="secondary" onClick={addTracker}>
                  Add Tracker
                </Button>
              </CardBody>
            </Card>
            <Card className="mb-4">
              <h3>Translations</h3>
              <CardBody>
                {item.translations && item.translations.length > 0 ? (
                  item.translations.map((translation, index) => (
                    <Row key={index} form>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Language Code</Label>{" "}
                          <span className="text-danger">*</span>{" "}
                          <Select
                            options={languagesOptions} // Pass your language options array here
                            value={
                              languagesOptions.find(
                                (option) =>
                                  option.value ===
                                  item.translations[index].languageCode
                              ) || null
                            }
                            required
                            onChange={(selectedOption) =>
                              handleTranslationChange(
                                index,
                                "languageCode",
                                selectedOption.value
                              )
                            }
                            placeholder="Select Language Code"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Image File</Label>
                          <Input
                            type="file"
                            onChange={(e) =>
                              handleTrackerImageFileChange(
                                index,
                                "imageFile",
                                e.target.files[0]
                              )
                            }
                          />
                          {/* Display selected file */}
                          {translation.imageFile && (
                            <div className="carousel-form-image-preview mt-3">
                              <img
                                src={translation.imageFile}
                                alt="Selected File"
                                className="carousel-form-preview-image img-thumbnail"
                                style={{ maxWidth: "150px" }}
                              />
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Dark Theme Image File</Label>
                          <Input
                            type="file"
                            onChange={(e) =>
                              handleTrackerImageFileChange(
                                index,
                                "darkThemeImageFile",
                                e.target.files[0]
                              )
                            }
                          />
                          {/* Display selected file */}
                          {translation.darkThemeImageFile && (
                            <div className="carousel-form-image-preview mt-3">
                              <img
                                src={translation.darkThemeImageFile}
                                alt="Selected File"
                                className="carousel-form-preview-image img-thumbnail"
                                style={{ maxWidth: "150px" }}
                              />
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  ))
                ) : (
                  <>
                    <Row form>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Language Code</Label>
                          <Input type="text" placeholder="Language Code" />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Image File</Label>
                          <Input type="file" />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Dark Theme Image File</Label>
                          <Input type="file" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="secondary" onClick={addTranslation}>
                      Add Translation
                    </Button>
                  </>
                )}
              </CardBody>
            </Card>
            <Button
              color="primary"
              type="submit"
              className="carousel-form-button"
            >
              Update Item
            </Button>
          </Form>
          <ResponseModal
            show={successModalState.successModalDisplay}
            onHide={() => {
              setSuccessModalState({
                successModalDisplay: false,
                successModalText: "",
              });
            }}
            modalheading="Success Heading"
            modaltext={successModalState.successModalText}
          />

          <ResponseModal
            show={failureModalState.failureModalDisplay}
            onHide={() => {
              setFailureModalState({
                failureModalDisplay: false,
                failureModalText: "",
              });
            }}
            modalheading="Failure Heading"
            modaltext={failureModalState.failureModalText}
          />
        </CardBody>
      </Card>
    </Container>
  );
};

export default EditItemForm;

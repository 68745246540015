import React, { useState, useEffect } from "react";
import {
  Container,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  Button,
  Spinner,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  fetchSegmentsData,
  addSegment,
  updateSegment,
} from "../../../utilities/apiUtils/segments";
import { useParams } from "react-router-dom";
import { showAddSegmentButton, showEditSegmentButton } from "../../../config/segment"; // Import the permission functions
import ToolTip from "../../../utilities/ToolTip";
const SegmentsPage = () => {
  const [segments, setSegments] = useState([]);
  const [gaidFile, setGaidFile] = useState(null);
  const [gaidFileName, setGaidFileName] = useState("");
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [editName, setEditName] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [newSegmentFile, setNewSegmentFile] = useState(null);
  const [newSegmentFileName, setNewSegmentFileName] = useState("");
  const [newSegmentName, setNewSegmentName] = useState("");
  const [newSegmentDescription, setNewSegmentDescription] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateAll, setUpdateAll] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    const fetchSegments = async () => {
      try {
        const data = await fetchSegmentsData();
        setSegments(data || []);
      } catch (error) {
        console.error("Error fetching segments:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSegments();
  }, []);

  const handleFileChange = (event, setFile, setFileName) => {
    const file = event.target.files[0];
    if (file && /\.(csv)$/i.test(file.name)) {
      setFile(file);
      setFileName(file.name);
    } else {
      alert("Please upload a file in .csv format");
    }
  };

  const handleAddSegment = async () => {
    if (!newSegmentName || !newSegmentFile) {
      alert("Please provide a name and upload a file");
      return;
    }

    setIsAdding(true);
    try {
      const response = await addSegment(newSegmentFile, newSegmentName, newSegmentDescription);
      if (response && response.id) {
        setSegments([...segments, response]);
        alert(`Segment added successfully (ID: ${response.id})`);
        resetNewSegmentForm();
        setIsAddModalOpen(false);
      } else {
        alert("Failed to add segment");
      }
    } catch (error) {
      console.error("Error adding segment:", error);
      alert("An error occurred while adding the segment");
    } finally {
      setIsAdding(false);
    }
  };

  const handleUpdateSegment = async () => {
    if (!editName) {
      alert("Name is required");
      return;
    }
    
    setIsUpdating(true);
    const updatedSegment = {
      ...selectedSegment,
      name: editName,
      description: editDescription,
      updateAll: updateAll,
    };

    try {
      const response = await updateSegment(updatedSegment, newSegmentFile);
      if (response) {
        setSegments((prevSegments) =>
          prevSegments.map((seg) =>
            seg.id === updatedSegment.id ? updatedSegment : seg
          )
        );
        setIsModalOpen(false);
        alert("Segment updated successfully");
        resetEditSegmentForm();
      } else {
        alert("Failed to update segment");
      }
    } catch (error) {
      console.error("Error updating segment:", error);
      alert("An error occurred while updating the segment");
    } finally {
      setIsUpdating(false);
    }
  };

  const handleEditSegment = (segment) => {
    setSelectedSegment(segment);
    setEditName(segment.name);
    setEditDescription(segment.description);
    setIsModalOpen(true);
  };

  const resetNewSegmentForm = () => {
    setNewSegmentName("");
    setNewSegmentDescription("");
    setNewSegmentFile(null);
    setNewSegmentFileName("");
  };

  const resetEditSegmentForm = () => {
    setEditName("");
    setEditDescription("");
    setNewSegmentFile(null);
    setNewSegmentFileName("");
  };

  return (
    <Container className="mt-4">
      <Card>
        <CardBody>
          <div className="mb-4 d-flex justify-content-between align-items-center">
            <h4 className="font-weight-bold text-primary">Manage Segments</h4>
            {showAddSegmentButton() && (
              <Button color="primary" onClick={() => setIsAddModalOpen(true)}>
                Add New Segment
              </Button>
            )}
          </div>
          <hr />
          {loading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "200px" }}>
              <Spinner color="primary" />
            </div>
          ) : (
            <Table striped>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Num of Users</th>
                  <th>Created At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {segments.length > 0 ? (
                  segments.map((segment) => (
                    <tr key={segment.id}>
                      <td>{segment.id}</td>
                      <td>{segment.name}</td>
                      <td>{segment.description}</td>
                      <td>{segment.numUsers || "N/A"}</td>
                      <td>{segment.createdAt}</td>
                      <td>
                        {showEditSegmentButton() && (
                          <Button
                            color="warning"
                            onClick={() => handleEditSegment(segment)}
                          >
                            Edit
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">No segments available</td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>

      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
        <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>Edit Segment</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="editName">Name <span style={{ color: "red" }}>*</span></Label>
            <Input
              type="text"
              id="editName"
              value={editName}
              onChange={(e) => setEditName(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="editDescription">Description <span style={{ color: "red" }}>*</span></Label>
            <Input
              type="textarea"
              id="editDescription"
              value={editDescription}
              onChange={(e) => setEditDescription(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              id="updateAll"
              checked={updateAll}
              onChange={(e) => setUpdateAll(e.target.checked)}
            />
            Overwrite Existing Gaids
            <ToolTip
                          data="If checked all entries are updated, even those which were not manually updated by the dashbaord admin"
                          id="updateAllSegmentAssetTooltip"
                        />
          </Label>
        </FormGroup>
          <FormGroup>
            <Label for="editSegmentFile">GAID List Upload <span style={{ color: "red" }}>*</span></Label>
            <div style={{ display: "inline-block", marginLeft: "5px" }}>
              <Input
                type="file"
                id="editSegmentFile"
                accept=".csv"
                onChange={(e) => handleFileChange(e, setNewSegmentFile, setNewSegmentFileName)}
                style={{ display: "none" }}
              />
            </div>
            <Button
              color="primary"
              onClick={() => document.getElementById("editSegmentFile").click()}
              className="mt-2"
            >
              {newSegmentFileName || "Select File"}
            </Button>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleUpdateSegment}
            disabled={isUpdating}
          >
            {isUpdating ? <Spinner size="sm" /> : "Save"}
          </Button>
          <Button color="secondary" onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={isAddModalOpen} toggle={() => setIsAddModalOpen(!isAddModalOpen)}>
      <ModalHeader toggle={() => setIsAddModalOpen(!isAddModalOpen)}>Add New Segment</ModalHeader>
      <ModalBody>
        <FormGroup>
        <Label for="newSegmentName">Segment Name <span style={{ color: "red" }}>*</span></Label>
          <Input
            type="text"
            id="newSegmentName"
            value={newSegmentName}
            onChange={(e) => setNewSegmentName(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup>
        <Label for="newSegmentDescription">Description <span style={{ color: "red" }}>*</span></Label>
          <Input
            type="textarea"
            id="newSegmentDescription"
            value={newSegmentDescription}
            onChange={(e) => setNewSegmentDescription(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup>
        <Label for="newSegmentFile">GAID List Upload <span style={{ color: "red" }}>*</span></Label>
          <div style={{ display: "inline-block", marginLeft: "5px" }}>
            <Input
              type="file"
              id="newSegmentFile"
              accept=".csv"
              onChange={(e) => handleFileChange(e, setNewSegmentFile, setNewSegmentFileName)}
              style={{ display: "none" }}
            />
          </div>
          <Button
            color="primary"
            onClick={() => document.getElementById("newSegmentFile").click()}
            className="mt-2"
          >
            {newSegmentFileName || "Select File"}
          </Button>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
      <Button
            color="primary"
            onClick={handleAddSegment}
            disabled={isAdding}
          >
                      {isAdding ? <Spinner size="sm" /> : "Add Segment"}
        </Button>
        <Button color="secondary" onClick={() => setIsAddModalOpen(false)}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
    </Container>
  );
};

export default SegmentsPage;

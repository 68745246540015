import React from "react";
import { Badge } from "reactstrap";

const statusFormatter = (cell, row) => {
    if (row.deactivatedAt) {
        return <Badge className="badge-danger">{"Deactivated"}</Badge>;
    }
    return <Badge className="badge-success">{"Activated"}</Badge>;
};
export const systemPromptsTableColumns = [
    {
        dataField: "id",
        text: "#",
        headerAttrs: { width: 60 },
        searchable: false,
        style: {
            cursor: "pointer",
        },
    },
    {
        dataField: "description",
        text: "Description",
        searchable: true,
        style: {
            cursor: "pointer",
        },
    },
    {
        dataField: "version",
        text: "Version",
        searchable: true,
        style: {
            cursor: "pointer",
        },
    },
    {
        dataField: "type",
        text: "Type",
        searchable: true,
        style: {
            cursor: "pointer",
        },
    },
    {
        dataField: "deactivatedAt",
        text: "Status",
        searchable: false,
        style: {
            cursor: "pointer",
        },
        formatter: statusFormatter,
    },
];

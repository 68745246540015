import React, { useState } from "react";
import ResponseModal from "../ResponseModal";
import { partnerResponses } from "../../../config/configTaasDashboard";
import { successModalHeading } from '../../../config';
import { errorModalHeading } from '../../../config/config';
import {
    error, errorDescription
} from '../../../utilities/commonUtil';
import { useHistory } from "react-router-dom"
import Routes from "../../../routes/index";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import { createPartner } from "../../../utilities/apiUtils/taasDashboard";

const PartnerForm = () => {
    const history = useHistory();
    const [successModalDisplay, setSuccessModalDisplay] = useState(false);
    const [successModalText, setSuccessModalText] = useState("");
    const [failureModalDisplay, setFailureModalDisplay] = useState(false);
    const [failureModalText, setFailureModalText] = useState("");

    const [data, setData] = useState({
        name: '',
        activatedAt: false,
        deactivatedAt: true,
    })

    const handleChange = (event) => {
        const { name, value } = event.target
        setData((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    let showSuccessModal = (text) => {
        setSuccessModalText(text);
        setSuccessModalDisplay(true);
    };

    let successModalClose = async () => {
        setSuccessModalDisplay(false);
        history.goBack();
    };

    let failureModalClose = () => {
        setFailureModalDisplay(false);
    };

    let showErrorModal = (text) => {
        setFailureModalText(text);
        setFailureModalDisplay(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let formData = new FormData();
        formData.append("name", data.name);
        formData.append("activatedAt", data.activatedAt);
        formData.append("deactivatedAt", data.deactivatedAt);
        try {
            let response = await createPartner(formData)
            if (response.code === "partnerAdded") {
                showSuccessModal(partnerResponses.addedSuccessfully);
            }
            else {
                if (response.errorDescription) {
                    showErrorModal(response.errorDescription)
                }
            }
        } catch (err) {
            setFailureModalDisplay(true);
            setFailureModalText(errorDescription(error.unexpectedError));
        }
    }
    return (
        <>
            <h1 className="h3 mb-3">
                Create Partner
            </h1>
            <Card>
                <CardBody>
                    <Form onSubmit={(e) => handleSubmit(e)}>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>
                                        Name <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        type="text"
                                        name="name"
                                        value={data.name}
                                        placeholder="Enter Partner Name"
                                        required
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button color="primary mt-5">Submit</Button>
                    </Form>
                    <ResponseModal
                        show={successModalDisplay}
                        onHide={successModalClose}
                        modalheading={successModalHeading}
                        modaltext={successModalText}
                    />
                    <ResponseModal
                        show={failureModalDisplay}
                        onHide={failureModalClose}
                        modalheading={errorModalHeading}
                        modaltext={failureModalText}
                    />
                </CardBody>
            </Card>
        </>
    );
};
const ManagePartner = () => (
    <Container fluid className="p-0">
        <Row>
            <Col lg="12">
                <PartnerForm />
            </Col>
        </Row>
    </Container>
);
export default ManagePartner;

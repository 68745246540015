import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import ResponseModal from "./ResponseModal";
import { Oval } from "react-loader-spinner";
import { error, errorDescription } from "../../utilities/commonUtil";
import UserAttributeRulesCard from "./UserAttributeRulesCard";
import { failureModalHeading, successModalHeading } from "../../config";
import { getPremiumProducts } from "../../utilities/apiUtils";
import { fetchSegmentsData } from "../../utilities/apiUtils/segments";
const UserAttributeBulkFilters = (props) => {
    const getRules = props.getRules;
    const saveRules = props.saveRules;
    const queryParams = new URLSearchParams(window.location.search);
    const contentIds = queryParams.get("contentIds")?.split(",").map(Number) || [];
    const [products, setProducts] = useState([]);
    const [segments, setSegments] = useState([]);

    const updateAllowed = props.updateRulesAllowed ?? false;
    const [loader, setLoader] = useState(false || props.showLoader);
    const [failureModalText, setFailureModalText] = useState("");
    const [failureModalDisplay, setFailureModalDisplay] = useState(false);
    const [successModalText, setSuccessModalText] = useState("");
    const [successModalDisplay, setSuccessModalDisplay] = useState(false);
    const [rules, setRules] = useState([]);
    

    let showErrorModal = (text) => {
        setFailureModalText(text);
        setFailureModalDisplay(true);
    };

    let failureModalClose = () => {
        setFailureModalDisplay(false);
    };

    let successModalClose = async () => {
        setSuccessModalDisplay(false);
        window.location.reload();
    };

    let showSuccessModal = (text) => {
        setSuccessModalText(text);
        setSuccessModalDisplay(true);
    };

    const addRule = () => {
        setRules((prev) => [
            ...prev,
            {
                ruleIndexId: prev.length > 0 ? prev[prev.length - 1].ruleIndexId + 1 : 0,
                [props.ruleType]: 0,
                filtersArray: [
                    {
                        type: "flt_premium_user",
                        config: {},
                    },
                ],
            },
        ]);
    };

    const removeRule = (id) => {
        setRules((prev) => {
            const _updatedRules = prev.filter((rule) => rule.ruleIndexId !== id);
            return _updatedRules;
        });
    };

    const updateRules = (ruleID, newFilter) => {
        setRules((prev) => {
            return prev.map((rule) => {
                if (rule.ruleIndexId === ruleID) {
                    return {
                        ...rule,
                        filtersArray: newFilter
                    };
                }
                return rule;
            });
        });
    };

    useEffect(() => {
        const getProducts = async () => {
            try {
                let response = await getPremiumProducts();
                if (response.length > 0) {
                    let productsArray = response.map(product => ({
                        value: product.id,
                        label: product.identifier,
                    }));
                    setProducts(productsArray);
                } else {
                    setProducts([]);
                }
            } catch (err) {
                showErrorModal(errorDescription(error.unexpectedError));
            }
        };
        const getSegments = async () => {
            try {
                let response = await fetchSegmentsData();
                if (response.length > 0) {
                    let segmentsArray = response.map(segment => ({
                        value: segment.id,
                        label: segment.name,
                    }));
                    setSegments(segmentsArray);
                } else {
                    setSegments([]);
                }
            } catch (err) {
                showErrorModal(errorDescription(error.unexpectedError));
            }
        };
        getProducts();
        getSegments();
    }, []);

    useEffect(() => {
        setLoader(props.showLoader);
    }, [props.showLoader]);

    const validateFilters = (filtersArray) => {
        for (const filter of filtersArray) {
            const { type, config } = filter;

            switch (type) {
                case "flt_premium_stickers":
                    if (!config?.hasOwnProperty('min_num_stickers') || config.min_num_stickers == 0 || config.min_num_stickers === null) {
                        return "Please fill the field minimum number of generated stickers";
                    }
                    if (config?.hasOwnProperty('min_num_stickers') && config?.hasOwnProperty('max_num_stickers')) {
                        filter.config = { min_num_stickers: config.min_num_stickers, max_num_stickers: config.max_num_stickers };
                        break;
                    }
                    filter.config = { min_num_stickers: config.min_num_stickers };
                    break;

                case "flt_premium_purchased_product":
                case "flt_premium_recommended_product":
                    if (!config?.hasOwnProperty('productIds') || config.productIds.length == 0) {
                        return `Please select at least one product`;
                    }
                    filter.config = { productIds: config.productIds };
                    break;

                case "flt_segments":
                    if (!config?.hasOwnProperty('segmentIds') || config.segmentIds.length == 0) {
                        return "Please select at least one segment";
                    }
                    filter.config = { segmentIds: config.segmentIds };
                    break;

                case "flt_disallowed_package":
                    if (!config?.hasOwnProperty('packageNames') || config.packageNames.length == 0) {
                        return "Please enter at least one package name";
                    }
                    filter.config = { packageNames: config.packageNames };
                    break;

                default:
                    break;
            }
        }
        return null;
    };

    const saveChanges = async () => {
        setLoader(true);
        for (const rule of rules) {
            const validationError = validateFilters(rule.filtersArray);
            if (validationError) {
                setLoader(false);
                showErrorModal(validationError);
                return;
            }
        }
    
        try {
            const requests = contentIds.map(async (singleContentId) => {
                const formattedRules = rules.map((rule) => ({
                    [props.ruleType]: singleContentId,
                    filtersArray: rule.filtersArray.map((filter) => {
                        if (filter.type === "flt_premium_user" && Object.keys(filter.config).length === 0) {
                            return {
                                type: filter.type,
                                config: { state: false },
                            };
                        }
                        return {
                            type: filter.type,
                            config: filter.config,
                        };
                    }),
                }));
    
                try {
                    const response = await saveRules(formattedRules, singleContentId);
                    if (response?.status !== "created" && response?.errorCode) {
                        throw new Error(response?.errorMessage);
                    }
                } catch (error) {
                    return { success: false, contentId: singleContentId, errorMessage: error.message };
                }
                return { success: true, contentId: singleContentId };
            });
    
            const results = await Promise.all(requests);
            
            // Filter results by success/failure
            const failedSaves = results.filter(result => !result.success);
            const successfulSaves = results.filter(result => result.success);
    
            if (failedSaves.length > 0) {
                const failureMessages = failedSaves.map(
                    (fail) => `Failed to save for ID: ${fail.contentId} - ${fail.errorMessage}`
                ).join("\n");
                showErrorModal(`Save failed for some IDs:\n${failureMessages}`);
            } else {
                const successMessage = successfulSaves.map(
                    (success) => `Saved for ID: ${success.contentId}`
                ).join(", ");
                showSuccessModal(`User Attribute Rule Sets Saved for IDs: ${successMessage}`);
            }
        } catch (err) {
            showErrorModal(errorDescription(error.unexpectedError));
        } finally {
            setLoader(false);
        }
    };
    
    

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                    <h1 className="h3">User Attribute Rules</h1>
                    <span className="text-warning font-weight-bold" style={{ fontSize: 13 }}>⚠️ The form needs to be submitted to save the user attribute rules</span>
                </div>
            </div>
            {rules.map((rule) => (
                <UserAttributeRulesCard
                    getFilters={props.getFilters}
                    key={rule.ruleIndexId}
                    rule={rule}
                    updateRules={updateRules}
                    removeRule={removeRule}
                    getProducts={products}
                    getSegments={segments}
                    deactivateRule={props.deactivateRules}
                    activateRule={props.activateRules}
                    updateAllowed={updateAllowed}
                />
            ))}
            <div className="d-flex justify-content-end p-0">
                <Button onClick={addRule}>Add Rule</Button>
            </div>
            <div className="d-flex">
                {!loader ? (
                    <div className="p-0">
                        <Button onClick={saveChanges}>Save Changes</Button>
                    </div>
                ) : (
                    <Oval
                        height={80}
                        width={80}
                        color="#4fa94d"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={loader}
                        ariaLabel='oval-loading'
                        secondaryColor="#4fa94d"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                )}
            </div>
            <ResponseModal
                show={successModalDisplay}
                onHide={successModalClose}
                modalheading={successModalHeading}
                modaltext={successModalText}
            />
            <ResponseModal
                show={failureModalDisplay}
                onHide={failureModalClose}
                modalheading={failureModalHeading}
                modaltext={failureModalText}
            />
        </div>
    );
};

export default UserAttributeBulkFilters;

import React from "react";

const textFormatter = (cell) => {
    if (cell) {
        return cell.default
    }
}
const lightIconFormatter = (cell, row) => {
    let url = row.lightThemeIconSrc ?? "";
    return (
        <div className="d-flex align-items-center">
            <img src={url} alt={row.title} style={{ width: "1.5rem", height: "1.5rem", marginRight: 6 }} />
        </div>
    )
}
const darkIconFormatter = (cell, row) => {
    let url = row.darkThemeIconSrc ?? "";
    return (
        <div className="d-flex align-items-center">
            <img src={url} alt={row.title} style={{ width: "1.5rem", height: "1.5rem", marginRight: 6 }} />
        </div>
    )
}
export const placementButtonTableColumns = [
    {
        dataField: "identifier",
        text: "Identifier",
        searchable: true,
        style: {
            cursor: "pointer",
        },
    },
    {
        dataField: "text",
        text: "Text",
        searchable: true,
        style: {
            cursor: "pointer",
        },
        formatter: textFormatter,
    },
    {
        dataField: "lightThemeIconFileName",
        text: "Light Theme icon",
        formatter: (cell, row) => lightIconFormatter(cell, row),
        style: {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            cursor: "pointer",
        },
    },
    {
        dataField: "DarkThemeIconFileName",
        text: "Dark Theme icon",
        formatter: (cell, row) => darkIconFormatter(cell, row),
        style: {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            cursor: "pointer",
        },
    },
    {
        dataField: "priority",
        text: "Priority",
        searchable: true,
        style: {
            cursor: "pointer",
        },
    },
];

import React from 'react'
import {
    Card,
    CardBody,
    CardImg,
    Col,
    Row,
    Container,
    Badge,
} from "reactstrap";
import { getStatusColor } from '../../../../utilities/commonUtil';
const MovieGifPreview = (props) => {
    const movieGifDetails = props.movieGifDetails;
    if(movieGifDetails.fixedWidthTiny == null || movieGifDetails.fixedWidthTiny == undefined){
        movieGifDetails.fixedWidthTiny = 
        {
            "width": 200,
            "height": 200,
            "gif": {
                "url": "/img/white-bg.png"
            }
        }
    }
return (
    <Card>
        <CardBody>
            <Container>
                <Row style={{ justifyContent: "space-between" }}>
                    <Col md={2} lg={3} className="d-block my-auto">
                        <CardImg
                            top
                            src="/img/white-bg.png"
                            onLoad={(e) =>
                                (e.target.src = movieGifDetails.fixedWidthTiny.gif.url)
                            }
                            alt="Card image cap"
                        />
                    </Col>
                    <Col md={8} lg={7} className="d-block my-auto">
                        {[
                            { label: "Id", value: movieGifDetails.id },
                            { label: "Created At", value: movieGifDetails.createdAt },
                            { label: "Updated At", value: movieGifDetails.updatedAt },
                            { label: "Deactivated At", value: movieGifDetails.deactivatedAt },
                            { label: "Published At", value: movieGifDetails.publishedAt },
                            { label: "Status", value: <Badge color={getStatusColor(movieGifDetails.status)}>{movieGifDetails.status}</Badge> },
                            { label: "Locale", value: movieGifDetails.locale },
                            { label: "Source", value: movieGifDetails.source },
                            { label: "Source ID", value: movieGifDetails.sourceID },
                            { label: "Title", value: movieGifDetails.title },
                            { label: "Enable Watermark", value: movieGifDetails.enableWatermark ? "Yes" : "No" },
                            { label: "Enable Share Text In Keyboard", value: movieGifDetails.enableShareTextInKeyboard ? "Yes" : "No" },
                            { label: "Image Processing Status", value: <Badge color={getStatusColor(movieGifDetails.imageProcessingStatus)}>{movieGifDetails.imageProcessingStatus}</Badge> },
                            { label: "Source File Hash", value: movieGifDetails.sourceFileHash },
                            { label: "Source File MD5 Hash", value: movieGifDetails.sourceFileMD5Hash },
                            { label: "Source File Aspect Ratio", value: movieGifDetails.sourceFileAspectRatio },
                        ].map((detail, index) => (
                            <Row key={index}>
                                <Col sm={6} md={4} lg={3}>
                                    <p className="font-weight-bolder p-0 m-0">{detail.label}:</p>
                                </Col>
                                <Col sm={6} md={8} lg={9}>
                                    <p className="p-0 m-0">{detail.value ?? "Not specified"}</p>
                                </Col>
                            </Row>
                        ))}
                    </Col>
                </Row>
            </Container>
        </CardBody>
    </Card>
)
}

export default MovieGifPreview
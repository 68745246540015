import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import CreatableSelect from 'react-select/creatable';
import { Button, Card, CardBody, Col, CardImg, Container, Form, FormGroup, Input, Label, Row, CustomInput, Badge } from "reactstrap";
import Link from "antd/es/typography/Link";
import ResponseModal from "../../../pages/ui-elements/ResponseModal";
import moment from "moment";
import {
    getBrandCampaigns,
} from "../../../utilities/apiUtils/stickers";
import { getUTMCampaigns } from "../../../utilities/apiUtils/users";
import ConfirmationModal from "../../../pages/ui-elements/ConfirmationModal";
import TargetingRules from "../TargetingRules";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { permissions } from "../../../utilities/commonUtil";
import ToolTip from "../../../utilities/ToolTip";
import { error, errorDescription, redirectOnTokenExpiry, redirectTo } from "../../../utilities/commonUtil";
import {
    getCountries,
    getRegions,
    getCities,
} from "../../../utilities/apiUtils/stories";
import { getClients } from "../../../utilities/apiUtils/cre";
import { getStoryDetailsData, getStoryTagsData, fetchStoryRuleSets, getStoryCTAItemsData, updateStoriesListingTargetingRules, updateStoryRuleSets, getBucketsTagsData } from "../../../utilities/apiUtils/stories";
import { getFullBucketData, updateStory, updateStoryTags, createStory } from "../../../utilities/apiUtils/stories";
import { headGenderOptions } from "../../../config/configStoryDashboard";
import { getAudioList } from "../../../utilities/apiUtils/stories";
const StoriesEdit = (props) => {
    const [formData, setFormData] = useState({
    });
    const [filters, setFilters] = useState({
        startDate: null,
        endDate: null,
    })

    let showErrorModal = (text) => {
        setFailureModalText(text);
        setFailureModalDisplay(true);
    };
    
    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
    const [jsonString, setJsonString] = useState('');
    const [successModalDisplay, setSuccessModalDisplay] = useState(false);
    const [successModalText, setSuccessModalText] = useState("");
    const [failureModalDisplay, setFailureModalDisplay] = useState(false);
    const [failureModalText, setFailureModalText] = useState("");
    const [headGender, setHeadGender] = useState("");
    const [headGenderError, setHeadGenderError] = useState(false);
    const [audioOptions, setAudioOptions] = useState({ audio: [] });
    const [selectedAudio, setSelectedAudio] = useState('');
    const [sendAINotification, setSendAINotification] = useState(false); 
    const handleHeadGenderChange = (value) => {
        setHeadGender(value);
    };
    const [showNotificationTitleError, setShowNotificationTitleError] = useState(false);
    const [notificationMetadata, setNotificationMetadata] = useState({
        title: "",
        subtitle: "",
        startTime: "13:00:00",
    });
    const handleInputChange = (event) => {
        setJsonString(event.target.value);
    };
    // const handleCampaignChange = (campaign) => {
    //     setFormTextState((prev) => ({
    //       ...prev,
    //       brandCampaignID: campaign ? campaign.value : 0,
    //     }));
    //   };
    const [allowedPermissions] = useState(
        retrieveFromLocalStorage("userPermissions")
    );
    const handleAudioChange = (selectedId) => {
        setSelectedAudio(selectedId);
    };
    const handleSliderChange = (event) => {
        setSendAINotification(event.target.checked);
    };
    let successModalClose = async () => {
        setSuccessModalDisplay(false);
        fetchStoriesData(props.match.params.id, utmCampaignOptions);
    };
    let failureModalClose = () => {
        setFailureModalDisplay(false);
        fetchStoriesData(props.match.params.id, utmCampaignOptions);
    };

    const handleMetadataChange = (event) => {
        const { name, value } = event.target;
        setNotificationMetadata((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const updateRulesAllowed = () => {
        return (allowedPermissions.includes(permissions.storiesTargetingRuleUpdatePermission) & staticDisable);
    };
    const redirectToCTA = () => {
        const baseURL = `${window.location.protocol}//${window.location.host}`;

        const storyId = window.location.href.split("/")[5];
        const newURL = `${baseURL}/stories/${storyId}/edit-cta`;
        window.location.href = newURL;
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); 
        setIsLoading(true);
        setHeadGenderError(false); 
    
        if (!headGender) {
            setHeadGenderError(true);
            setIsLoading(false);
            return; 
        }
        if(!notificationMetadata.title && sendAINotification) {
            setShowNotificationTitleError(true);
            setIsLoading(false);
            return;
        }else{
            setShowNotificationTitleError(false);
        }
        setIsLoading(true)
        try {
            if (props.match.params.id) {
                const formData = new FormData();
                for (const key in details) {
                    if (details[key] !== null && key != "contentType" && key != "status" && key != "shareCTAText" && key != "startAt" && key !== "utmCampaign" && key != "endAt" &&
                        !/^(https?:\/\/)/.test(details[key])) {  // Check for null
                        formData.append(key, details[key]);
                    }
                }

                if(details?.utmCampaign?.value !== undefined)
                    formData.append('utmCampaign', details?.utmCampaign?.value)


                if (formData.has("headGender")) {
                    formData.set('headGender', headGender);  
                } else {
                    formData.append('headGender', headGender);  
                }
                if (!details.isAiTemplate) {
                    details.enableUserStoryGeneration = false;
                  }
                if (formData.has("enableUserStoryGeneration")) {
                    formData.set('enableUserStoryGeneration', details?.enableUserStoryGeneration);  
                } else {
                    formData.append('enableUserStoryGeneration', details?.enableUserStoryGeneration);  
                }

                if (selectedAudio !== null && selectedAudio !== undefined) {
                    if (formData.has("audioSnippetId")) {
                        formData.set("audioSnippetId", selectedAudio);
                    } else {
                        formData.append("audioSnippetId", selectedAudio);
                    }
                } else {
                    formData.delete("audioSnippetId");
                }

                if (formData.has("sendAiStoryNotification")) {
                    formData.set("sendAiStoryNotification", sendAINotification);
                } else {
                    formData.append("sendAiStoryNotification", sendAINotification);
                }
    
                if (sendAINotification) {
                    const metadataJson = JSON.stringify(notificationMetadata);
                    if (formData.has("notificationMetadata")) {
                        formData.set("notificationMetadata", metadataJson);
                    } else {
                        formData.append("notificationMetadata", metadataJson);
                    }
                }
    

                if (filters.endDate != null) {
                    if (moment(filters.endDate).format('YYYY-MM-DDTHH:mm:ssZ') !== undefined)
                        formData.append('endAt', moment(filters.endDate).format('YYYY-MM-DDTHH:mm:ssZ'))
                } else {
                    formData.append('endAt', details.endAt === undefined ? null : details.endAt)
                }
                if (filters.startDate != null) {
                    if (moment(filters.startDate).format('YYYY-MM-DDTHH:mm:ssZ') !== undefined)
                        formData.append('startAt', moment(filters.startDate).format('YYYY-MM-DDTHH:mm:ssZ'))
                } else {
                    formData.append('startAt', details.startAt === undefined ? null : details.startAt)
                }
                if (videoFile != null) {
                    formData.append('rawResourceFile', videoFile)
                    formData.append('previewSourceFile', videoFile)
                }
                formData.append('contentType', contentType)
                // Update Existing Story
                const response = await updateStory(props.match.params.id, formData);
                formData.append('contentType', contentType)
                if (response.status === "success") {
                    setSuccessModalText("Updated Succesfully");
                    setSuccessModalDisplay(true);
                    setIsLoading(false);
                } else {
                    if (
                        response.errorCode === error.tokenExpired ||
                        response.errorCode === error.invalidAccessToken ||
                        response.errorCode === error.unauthorized
                    )
                        redirectOnTokenExpiry();
                    let text = errorDescription(error.unexpectedError);
                    showErrorModal(text);
                }
            } else {
                const formData = new FormData();
                for (const key in details) {
                    if (details[key] !== null && key != "contentType" && key !== "utmCampaign") {  // Check for null
                        formData.append(key, details[key]);
                    }
                }
                if (filters.endDate != null) {
                    if (moment(filters.endDate).format('YYYY-MM-DDTHH:mm:ssZ') !== undefined)
                        formData.append('endAt', moment(filters.endDate).format('YYYY-MM-DDTHH:mm:ssZ'))
                } else {
                    if (details.endAt !== undefined) {
                        formData.append('endAt', details.endAt)
                    }
                }
                if (filters.startDate != null) {
                    if (moment(filters.startDate).format('YYYY-MM-DDTHH:mm:ssZ') !== undefined)
                        formData.append('startAt', moment(filters.startDate).format('YYYY-MM-DDTHH:mm:ssZ'))
                } else {
                    if (details.startAt !== undefined) {
                        formData.append('startAt', details.startAt)
                    }
                }

                if (formData.has("sendAiStoryNotification")) {
                    formData.set("sendAiStoryNotification", sendAINotification);
                } else {
                    formData.append("sendAiStoryNotification", sendAINotification);
                }
    
                if (sendAINotification) {
                    const metadataJson = JSON.stringify(notificationMetadata);
                    if (formData.has("notificationMetadata")) {
                        formData.set("notificationMetadata", metadataJson);
                    } else {
                        formData.append("notificationMetadata", metadataJson);
                    }
                }
    
                const tagValues = Tags.tags?.map(tag => tag.value);
                if (tagValues) {
                    formData.append('tags', JSON.stringify(tagValues));
                } else {
                    formData.append('tags', null);
                }
                formData.append('storyBucketId', bucketId)
                // formData.append('enableWaterMark', Watermark);
                formData.append('contentType', contentType)
                if (videoFile != null) {
                    formData.append('rawResourceFile', videoFile)
                    formData.append('previewSourceFile', videoFile)
                }

                if (selectedAudio !== null && selectedAudio !== undefined) {
                    if (formData.has("audioSnippetId")) {
                        formData.set("audioSnippetId", selectedAudio);
                    } else {
                        formData.append("audioSnippetId", selectedAudio);
                    }
                } else {
                    formData.delete("audioSnippetId");
                }
                if(details?.utmCampaign?.value !== undefined)
                    formData.append('utmCampaign', details?.utmCampaign?.value)

                if (formData.has("headGender")) {
                    formData.set('headGender', headGender);  
                } else {
                    formData.append('headGender', headGender);  
                }
                
                const response = await createStory(formData); // Assuming formData is suitable  
                setIsLoading(false);
                if (response.status === "success") {
                    setSuccessModalText("Created Succesfully");
                    setSuccessModalDisplay(true);
                    redirectTo(`/stories/edit-stories/${response.id}`)
                } else {
                    if (
                        response.errorCode === error.tokenExpired ||
                        response.errorCode === error.invalidAccessToken ||
                        response.errorCode === error.unauthorized
                    )
                        redirectOnTokenExpiry();
                    let text = errorDescription(error.unexpectedError);
                    showErrorModal(text);
                }
            }
        } catch (error) {
            // Handle errors
            console.error("Error submitting form:", error);
        }
    };

    const [details, setDetails] = useState({
        "id": null,
        "title": null,
        "orientation": null,
        "contentType": null,
        "storyBucketId": null,
        "rawResourcesFilename": null,
        "previewSourceFilename": null,
        "staticPreviewFilename": null,
        "animatedPreviewFilename": null,
        "videoPreviewFilename": null,
        "rawResourcesURL": null,
        "staticPreviewURL": null,
        "videoPreviewURL": null,
        "shareText": null,
        "shareCTAText": null,
        "ctaDetails": null,
        "enableWatermark": true,
        "rawResourceFile": null,
        "displayInterval": 0,
        "videoDetails": null,
        "deactivatedAt": null,
        "publishedAt": null,
        "enableShareText": true,
        "priority": null,
        "brandCampaignId": null,
        "autoPublish": true,
        "sourceIdentifier": null,
        "utmCampaign": null,
        "isAiTemplate": false,
        "headGender": "unisex",
        "enableUserStoryGeneration": false,
        "audioSnippetId": null,
    });
    const [utmCampaignOptions, setUtmCampaignOptions] = useState([]);

    const statusColors = {
        published: 'green', // Use a clear and accessible color
        deactivated: 'red', // Use a clear and accessible color
        unpublished: 'blue', // Use a clear and accessible color
    };
    const getStatusColor = (state) => {
        // Use a switch statement for cleaner and more concise logic
        switch (state) {
            case "active":
                return statusColors.published;
            case "deactivated":
                return statusColors.deactivated;
            case "unpublished":
                return statusColors.unpublished;
            default:
                return 'gray'; // Or any other default color you prefer
        }
    };
    const fetchBucket = async (id) => {
        try {
            let response = await getFullBucketData(id);
            const formattedBuckets = response.storyBuckets.map(bucket => ({
                value: bucket.id,
                label: bucket.name
            }));
            setBucketOptions((prevState) => ({
                ...prevState,
                storyBuckets: formattedBuckets
            }));

        } catch (error) {
            console.error("Error fetching story buckets:", error);
        }
    };

    const fetchAudioList = async () => {
        try {
            let response = await getAudioList();
    
            if (!Array.isArray(response)) {
                console.error("Unexpected API response format:", response);
                return;
            }
    
            const formattedAudio = response.map(audio => ({
                value: audio.id,
                label: audio.name.trim(),
                url: audio.url
            }));
    
    
            setAudioOptions((prevState) => ({
                ...prevState,
                audio: formattedAudio
            }));
        } catch (error) {
            console.error("Error fetching audio list:", error);
        }
    };
    
    const [Tags, setTags] = useState([])
    const [bucket, setBuckets] = useState([])
    const [bucketOptions, setBucketOptions] = useState([]);
    const [bucketId, setBucketId] = useState(null);
    const [contentType, setContentType] = useState("video");
    const [clients, setClients] = useState();

    const handleContentTypeChange = (value) => {
        setContentType(value);
    };
    const handleBucketChange = (selectedBucket) => {
        // Update a state variable to store the selected bucket ID
        setBucketId(selectedBucket.value);
        setBuckets(selectedBucket);
        fetchBucketTags(selectedBucket.value);
    };
    const [cta, setCta] = useState()
    const [changeType, setChangeType] = useState("Add");
    const handleDetailsChange = (event) => {
        if (!event?.target) {
            setDetails(prevState => ({
                ...prevState,
                brandCampaignId: event?.value === undefined ? null : event.value
            }));

        } else {
            const { name, value, files } = event.target;
            if (name === "rawResourceFile" && files?.length) {
                const file = files[0];
                setDetails((prevState) => ({
                    ...prevState,
                    [name]: file, 
                    previewSourceFile: file, 
                    rawResourcesFilename: file.name,
                }));
            } else {
                
                setDetails((prevState) => ({
                    ...prevState, // Spread the previous state properties
                    [name]: value// Update enableWatermark
                }));
                if (name == "enableShareText") {
                    const { checked } = event.target;
                    setDetails(prevState => ({
                        ...prevState,
                        enableShareText: checked
                    }));
                } 
                if (name === "isAiTemplate") {
                    const { checked } = event.target;
                    setDetails((prevState) => ({
                        ...prevState,
                        isAiTemplate: checked
                    }));
                }
                if (name == "autoPublish") {
                    const { checked } = event.target;
                    setDetails(prevState => ({
                        ...prevState,
                        autoPublish: checked
                    }));
                }
                if (name === "headGender") {
                    setDetails((prevState) => ({
                        ...prevState,
                        headGender: value 
                    }));
                }

                if (name === "enableUserStoryGeneration") {
                    const { checked } = event.target;
                    setDetails((prevState) => ({
                        ...prevState,
                        enableUserStoryGeneration: checked
                    }));
                }
            }           
        };
    }
    const [videoFile, setVideoFile] = useState(null);
    const [videoFileName, setVideoFileName] = useState(null);
    const handleVideoUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setVideoFileName(file.name);
            setVideoFile(file); // Store the file object directly
        }
    };
    const handleWatermarkChange = (event) => {
        const { checked } = event.target;
        setDetails(prevState => ({
            ...prevState,
            enableWatermark: checked
        }));
    };
    let setConfirmationModalState = (event) => {
        event.preventDefault();
        setConfirmationModalIsOpen(true);
    };
    const filenameUrlMap = {
        "share-v1.png": "https://assets.bobblekeyboard.net/stories/cta-icons/share-v1.png",
        "download-v1.png": "https://assets.bobblekeyboard.net/stories/cta-icons/download-v1.png",
        "whatsapp-v2.png": "https://assets.bobblekeyboard.net/stories/cta-icons/whatsapp-v2.png",
        "whatsapp.png": "https://assets.bobblekeyboard.net/stories/cta-icons/whatsapp-v2.png",
        "download.png": "https://assets.bobblekeyboard.net/stories/cta-icons/download-v1.png",
        "share.png": "https://assets.bobblekeyboard.net/stories/cta-icons/share-v1.png",
    };
    const [campaigns, setCampaigns] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [staticDisable, setStaticDisable] = useState(true);
    useEffect(() => {
        const fetchDate = async () => {
            let utmCampaigns = await getUTMCampaigns();
            setUtmCampaignOptions(utmCampaigns);
            if (props.match.params.id) {
                setChangeType("Edit");
                fetchStoriesData(props.match.params.id, utmCampaigns);
                fetchTags(props.match.params.id)
                fetchCtaData(props.match.params.id)
                fetchclients()
            } else {
                setChangeType("Create")
            }
        }
        fetchDate();
    }, [props.match.params.id]);
    const [isEditing, setIsEditing] = useState(false);
    useEffect(() => {
        const _getBrandCampaigns = async () => {
            let response = await getBrandCampaigns();
            setCampaigns(response);
        };
        _getBrandCampaigns();
    }, []);
    useEffect(() => {
        setIsEditing(!props.match.params.id);
        fetchBucket()
        fetchAudioList()
    }, [props.match.params.id]);
    function getdateValue(date) {
        if (date === null) {
            return null;
        }

        // Ensure the input is a valid Date object
        const validDate = new Date(date);
        if (isNaN(validDate.getTime())) { // Check if the date is valid
            return null; // Or throw an error if you prefer stricter handling
        }

        return validDate.toISOString().slice(0, 10); // Format as "YYYY-MM-DD"
    }
    const fetchStoriesData = async (id, utmCampaigns) => {
        try {
            let response = await getStoryDetailsData(id)
            await fetchBucket()
            // Update the filters
            if (response.sourceIdentifier == "legacy_dashboard") {
                setStaticDisable(false)
            } else {
                setStaticDisable(true)
            }
            response.utmCampaign = response?.utmCampaign ? utmCampaigns.find(campaign => campaign.value === response?.utmCampaign) : null;
            // remove the T from the date string
            response.startAt = getdateValue(response.startAt);
            response.endAt = getdateValue(response.endAt);
            setDetails(response);
            let selectedBucket
            if (selectedBucket != undefined) {
                selectedBucket = bucketOptions.storyBuckets.find(
                    (b) => b.value === parseInt(response.storyBucketId)
                );
            }

            if (selectedBucket) {
                setBucketId(selectedBucket.value);
                setBuckets(selectedBucket);
            } else {
                console.error('Bucket not found in existing options');
            }
            setContentType(response.contentType)
            setHeadGender(response.headGender);
            handleAudioChange(response.audioSnippetId)
            setSendAINotification(response.sendAiStoryNotification ?? false);

            setNotificationMetadata(response.notificationMetadata ? JSON.parse(response.notificationMetadata) : {
                title: "",
                subtitle: "",
                startTime: "13:00:00",
            });
        } catch {
            console.error("Error fetching story details:", error);
        }
    }
    const fetchCtaData = async (id) => {
        try {
            let response = await getStoryCTAItemsData(id)
            setCta(response)
        } catch {
            console.error("Error fetching story details:", error);
        }
    }
    const fetchTags = async (id) => {
        try {
            let response = await getStoryTagsData(id)
            // Assuming getStoryTagsData returns an array of tag strings
            const formattedTags = response.tags.map(tag => ({
                label: tag,
                value: tag,
                __isNew__: true
            }));
            setTags((prevState) => ({
                ...prevState,
                tags: formattedTags
            }));
        } catch {
            console.error("Error fetching story details:", error);
        }
    }
    const fetchBucketTags = async (bucketId) => {
        try {
            let response = await getBucketsTagsData(bucketId)
            // Assuming getStoryTagsData returns an array of tag strings
            const formattedTags = response.tags.map(tag => ({
                label: tag,
                value: tag,
                __isNew__: true
            }));
            setTags((prevState) => ({
                ...prevState,
                tags: formattedTags
            }));
        } catch {
            console.error("Error fetching story details:", error);
        }
    }
    const fetchclients = async () => {
        try {
            let response = await getClients()
            setClients(response)
        } catch {
            console.error("Error fetching story details:", error);
        }
    }
    function getBadgeText(status) {
        switch (status) {
            case 'not_started':
                return 'Not Started';
            case 'processing':
                return 'Ongoing';
            case 'completed':
                return 'Completed';
            case 'legacy_dashboard':
                return 'Legacy Dashboard'
            case `bobble_dashboard`:
                return 'Bobble Dashboard'
            default:
                return status; // Return the raw status if no match
        }
    }

    const handleSaveTags = async () => {
        try {
            const tagsData = {
                tags: Tags.tags.map(tag => tag.value)
            };

            const response = await updateStoryTags(props.match.params.id, tagsData);
            if (response.status === "success") {
                setSuccessModalText("updated Succesfully");
                setSuccessModalDisplay(true);
            } else {
                if (
                    response.errorCode === error.tokenExpired ||
                    response.errorCode === error.invalidAccessToken ||
                    response.errorCode === error.unauthorized
                )
                    redirectOnTokenExpiry();
                let text = errorDescription(error.unexpectedError);
                showErrorModal(text);
            }

        } catch (error) {
            console.error("Error updating tags:", error);
        }
    };
    return (
        <Container fluid className="p-0">
            <Row className="mb-3">
                <Col md={6} xs={6}>
                    <h1> {changeType} Stories </h1>
                </Col>
            </Row>

            {(changeType === 'Edit') && ( // Use && for more explicit conditional rendering 
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <Container>
                                    <Row style={{ justifyContent: "space-between" }}>
                                        <Col md={2} lg={3} className="d-block my-auto">
                                            {details.contentType === "video" ? (
                                                // Render a video element
                                                <video
                                                    src={details.videoPreviewURL || "/path/to/fallback-video.mp4"}
                                                    alt="Video Preview"
                                                    width="60%"
                                                    height="60%"
                                                    controls
                                                >
                                                    Your browser does not support the video tag.
                                                </video>
                                            ) : (
                                                <CardImg
                                                    top
                                                    src={
                                                        details.contentType === "video"
                                                            ? details.videoPreviewURL || "/img/fallback-video.jpg" // Video fallback
                                                            : details.contentType === "animated"
                                                                ? details.animatedPreviewURL || "/img/fallback-animated.gif" // Animated fallback
                                                                : details.staticPreviewURL || "/img/fallback-image.jpg" // Static fallback
                                                    }
                                                    style={{ width: "50%", height: "50%" }}
                                                    onLoad={(e) => {
                                                        // You can remove the onLoad logic if no longer needed 
                                                    }}

                                                    alt={
                                                        details.contentType === "animated"
                                                            ? "Animated Preview"
                                                            : "Static Preview"
                                                    }
                                                />
                                            )}
                                        </Col>
                                        <Col md={8} lg={7} className="d-block my-auto">
                                            <dl> {/* Description list for better structure */}
                                                <dt>ID:</dt>
                                                <dd>{props.match.params.id}</dd>
                                                <dt>Title:</dt>
                                                <dd>{details.title}</dd>
                                                <dt>Status</dt>
                                                <dd><Badge style={{ backgroundColor: getStatusColor(details.status) }}>{details.status} </Badge></dd>
                                                <dt>Source Identifier</dt>
                                                <dd> <Badge>
                                                    {getBadgeText(details.sourceIdentifier)}
                                                </Badge></dd>
                                                <dt>Image Processing Status</dt>
                                                <dd>
                                                    <Badge>
                                                        {getBadgeText(details.imageProcessingStatus)}
                                                    </Badge>
                                                </dd>
                                                <dt>PublishedAt:</dt>
                                                <dd>{details.publishedAt}</dd>
                                                {details.deactivatedAt ? (
                                                    <>
                                                        <dt>DeactivatedAt:</dt>
                                                        <dd>{details.deactivatedAt}</dd>
                                                    </>
                                                ) : null}

                                            </dl>
                                        </Col>
                                    </Row>
                                </Container>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}
            <Form onSubmit={(event) => setConfirmationModalState(event)}>
                <Row lg="12">
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg={2} md={6}>
                                        <FormGroup className="d-flex align-items-center">
                                            <Label
                                                className="font-weight-bolder mb-0"
                                                style={{ fontSize: "14px" }}
                                            >
                                                Enable Share Text
                                            </Label>
                                            <div className="custom-control custom-switch">
                                                <CustomInput
                                                    type="checkbox"
                                                    id="enableShareText"
                                                    name="enableShareText"
                                                    value={details.enableShareText}
                                                    onChange={(event) => {
                                                        handleDetailsChange(event)
                                                    }}
                                                    checked={details.enableShareText}
                                                    disabled={(!staticDisable)}
                                                />
                                            </div>
                                        </FormGroup>

                                        
                                    </Col>
                                    <Col lg={2} md={6}>
                                    <FormGroup className="d-flex align-items-center">
                                        <Label
                                            className="font-weight-bolder mb-0"
                                            style={{ fontSize: "14px" }}
                                        >
                                            AI Template
                                        </Label>
                                        <div className="custom-control custom-switch">
                                            <CustomInput
                                                type="checkbox"
                                                id="isAiTemplate"
                                                name="isAiTemplate"
                                                value={details.isAiTemplate}
                                                onChange={(event) => {
                                                    handleDetailsChange(event);
                                                }}
                                                checked={details.isAiTemplate}
                                                disabled={!staticDisable}
                                            />
                                        </div>
                                    </FormGroup>
                                    </Col>

                                    <Col lg={2} md={6}>
                                        <FormGroup className="d-flex align-items-center">
                                            <Label
                                                className="font-weight-bolder mb-0"
                                                style={{ fontSize: "14px" }}
                                            >
                                                Send AI Notification
                                            </Label>
                                            <div className="custom-control custom-switch">
                                                <CustomInput
                                                    type="checkbox"
                                                    id="sendAINotification"
                                                    name="sendAINotification"
                                                    value={sendAINotification}
                                                    onChange={handleSliderChange}
                                                    checked={sendAINotification}
                                                    disabled={!staticDisable}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>


                                    {details.isAiTemplate && (
                                    <Col lg={2} md={6}>
                                        <FormGroup className="d-flex align-items-center">
                                        <Label
                                            className="font-weight-bolder mb-0"
                                            style={{ fontSize: "14px" }}
                                        >
                                            Generate User Story for Head Update
                                        </Label>
                                        <div className="custom-control custom-switch">
                                            <CustomInput
                                            type="checkbox"
                                            id="enableUserStoryGeneration"
                                            name="enableUserStoryGeneration"
                                            value={details.enableUserStoryGeneration}
                                            onChange={(event) => {
                                                handleDetailsChange(event);
                                            }}
                                            checked={details.enableUserStoryGeneration}
                                            disabled={!staticDisable}
                                            />
                                        </div>
                                        </FormGroup>
                                    </Col>
                                    )}
                                    <Col lg={2} md={6}>
                                        <FormGroup className="d-flex align-items-center">
                                            <Label
                                                className="font-weight-bolder mb-0"
                                                style={{ fontSize: "14px" }}
                                            >
                                                Enable WaterMark
                                            </Label>
                                            <div className="custom-control custom-switch">
                                                <CustomInput
                                                    type="checkbox"
                                                    id="enableWatermark"
                                                    value={details.enableWatermark}
                                                    onChange={(event) => {
                                                        handleWatermarkChange(event)
                                                    }}
                                                    checked={details.enableWatermark}
                                                    disabled={(!staticDisable)}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={2} md={6}>
                                        <FormGroup className="d-flex align-items-center">
                                            <Label
                                                className="font-weight-bolder mb-0"
                                                style={{ fontSize: "14px" }}
                                            >
                                                Auto Publish
                                            </Label>
                                            <div className="custom-control custom-switch">
                                                <CustomInput
                                                    type="checkbox"
                                                    id="autoPublish"
                                                    name="autoPublish"
                                                    value={details.autoPublish}
                                                    onChange={handleDetailsChange}
                                                    checked={details.autoPublish}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col lg={6} md={6}>
                                        <FormGroup>
                                            <Label
                                                className="font-weight-bolder mb-2"
                                                style={{ fontSize: "14px" }}
                                            >
                                                <strong>
                                                    Share  Text
                                                </strong>
                                            </Label>
                                            <span style={{ marginRight: '5px' }}></span>
                                            <ToolTip
                                                data="If this field is empty, the default share text will be used. To disable share text, uncheck the 'Enable Share Text' option."
                                                id="shareText"
                                            >
                                            </ToolTip>
                                            <textarea
                                                type="text"
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                name="shareText"
                                                placeholder="Share Text"
                                                value={details.shareText}
                                                onChange={(event) => {
                                                    handleDetailsChange(event)
                                                }}
                                                disabled={(!staticDisable)}
                                                style={{ width: '100%' }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={6} md={6}>
                                        <FormGroup>
                                            <Label
                                                className="font-weight-bolder mb-2"
                                                style={{ fontSize: "14px" }}
                                            >
                                                <strong>
                                                    Title
                                                </strong>
                                            </Label>
                                            <span style={{ marginRight: '5px' }}></span>
                                            <Input
                                                type="text"
                                                id="text"
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                name="title"
                                                placeholder="Title"
                                                value={details.title}
                                                onChange={(event) => {
                                                    handleDetailsChange(event)
                                                }}
                                                disabled={(!staticDisable)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={6} md={6}>
                                        <FormGroup>
                                            <Label
                                                className="font-weight-bolder mb-2"
                                                style={{ fontSize: "14px" }}
                                            >
                                                Priority
                                            </Label>
                                            <Input
                                                type="number"
                                                id="priority"
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                name="priority"
                                                placeholder="Priority"
                                                value={details.priority}
                                                onChange={(event) => {
                                                    handleDetailsChange(event)
                                                }}
                                                disabled={(!staticDisable)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Content Type</Label>
                                            <Input
                                                type="select"
                                                value={contentType}
                                                onChange={(e) =>
                                                    handleContentTypeChange(e.target.value)
                                                }
                                                disabled={(!isEditing) || (!staticDisable)}
                                            >
                                                <option value="video">Video</option>
                                                <option value="static">Static</option>
                                                <option value="animated">Animated</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                    <FormGroup>
                                    <Label>Select Audio</Label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        value={
                                        selectedAudio === null
                                            ? null
                                            : audioOptions.audio?.find(opt => opt.value === selectedAudio)
                                        }
                                        isClearable={true}
                                        placeholder="Select an audio file"
                                        options={audioOptions.audio} 
                                        name="selectedAudio"
                                        onChange={event => handleAudioChange(event ? event.value : null)}
                                        isDisabled={!staticDisable}
                                        // Custom rendering: show both the name (label) and id (value)
                                        formatOptionLabel={option => (
                                        <div>
                                            {option.label} <span style={{ color: '#555' }}>({option.value})</span>
                                        </div>
                                        )}
                                    />
                                    </FormGroup>
                                    {/* Conditionally render the audio player if an audio is selected */}
                                    {selectedAudio && audioOptions.audio.find(opt => opt.value === selectedAudio) && (
                                        <div className="audio-player" style={{ marginTop: '20px' }}>
                                        <audio key={selectedAudio} controls style={{ width: '100%' }}>
                                            <source
                                            src={audioOptions.audio.find(opt => opt.value === selectedAudio).url}
                                            type="audio/mpeg"
                                            />
                                            Your browser does not support the audio element.
                                        </audio>
                                        </div>
                                    )}
                                    </Col>
                                    <Col md={6}>
                                    <FormGroup>
                                    <Label>Head Gender</Label>
                                    <Input
                                        type="select"
                                        value={headGender}
                                        onChange={(e) => handleHeadGenderChange(e.target.value)}
                                    >
                                        {headGenderOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                        ))}
                                    </Input>
                                    {headGenderError && (
                                        <div style={{ color: 'red', marginTop: '5px' }}>
                                        Please select a head gender.
                                        </div>
                                    )}
                                    </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                    <FormGroup>
                                            <Label>Brand Campaign ID</Label>
                                            <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                value={details.brandCampaignId === null
                                                    ? null
                                                    : campaigns?.find(opt => opt.value === details?.brandCampaignId)}
                                                isClearable={true}
                                                placeholder="Select campaign"
                                                options={campaigns}
                                                name="brandCampaignId"
                                                onChange={(event) => handleDetailsChange(event)}
                                                isDisabled={(!staticDisable)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        {contentType === 'video' ? (
                                            <FormGroup>
                                                <Label>
                                                    <strong>Video</strong><span className="text-danger">*</span>
                                                    <ToolTip
                                                        data="Formats: mp4, webm (more formats depending on browser support), Size: 5MB (example limit)"
                                                        id="videoTooltip"
                                                    />
                                                </Label>
                                                <Container className="custom-file">
                                                    <Input
                                                        type="file"
                                                        className="custom-file-input"
                                                        accept="video/mp4, video/webm" // Adjust accepted formats
                                                        name="rawResource"
                                                        disabled={(!staticDisable)}
                                                        onChange={(event) => handleVideoUpload(event)}
                                                    />
                                                    <Label className="custom-file-label">
                                                        <strong>{videoFileName || "Choose a video file"}</strong>
                                                    </Label>
                                                </Container>

                                            </FormGroup>

                                        ) : (
                                            <FormGroup>
                                                <Label>
                                                <strong>
                                                    Image
                                                </strong>
                                                <span className="text-danger">*</span>
                                                <ToolTip
                                                    data={
                                                        details.type?.value === "product"
                                                            ? "Formats: jpg, jpeg, webp, Aspect ratio: any, Size: 100KB, Dimensions: Height 1624px x Width 1082px"
                                                            : "Formats: jpg, jpeg, webp, Aspect ratio: 1:1, Size: 100KB, Dimensions: Height 1624px x Width 1082px"
                                                    }
                                                    id="staticIconTooltip"
                                                />
                                                </Label>
                                                <Container className="custom-file">
                                                    <Input
                                                        type="file"
                                                        className="custom-file-input"
                                                        accept="image/gif, image/jpg, image/jpeg, image/webp,video/mp4, video/webm"
                                                        name="rawResourceFile"
                                                        onChange={(event) => {
                                                            handleDetailsChange(event)
                                                        }}
                                                        disabled={(!isEditing) || (!staticDisable)}
                                                    />
                                                    <Label className="custom-file-label">
                                                        <strong>{details.rawResourcesFilename || "Choose a Preview image"}</strong>
                                                    </Label>
                                                </Container>
                                            </FormGroup>
                                        )}
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>
                                                Start Date:
                                            </Label>
                                            <Input
                                                type="date"
                                                name="startAt"
                                                onChange={(e) => {
                                                    setDetails({ ...details, startAt: e.target.value })
                                                }}
                                                value={details.startAt}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>
                                                End Date:
                                            </Label>
                                            <Input
                                                type="date"
                                                name="endAt"
                                                onChange={(e) => {
                                                    setDetails({ ...details, endAt: e.target.value })
                                                }}
                                                value={details.endAt}

                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col lg={6} md={6}>
                                        <FormGroup>
                                            <Label>
                                                UTM Campaign
                                            </Label>
                                            <ToolTip
                                                data="UTM Campaign"
                                                id="utmCampaign"
                                            >
                                            </ToolTip>

                                            {/* create a clearable select dropdown */}
                                            <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                options={utmCampaignOptions}
                                                placeholder="Select UTM Campaign"
                                                value={details.utmCampaign}
                                                isClearable
                                                onChange={(value) => {
                                                    setDetails((prevState) => ({
                                                        ...prevState,
                                                        utmCampaign: value
                                                    }))
                                                }
                                                }
                                            />

                                        </FormGroup>
                                    </Col>


                                    <Col md={12}>
                                        <FormGroup>
                                            <Label
                                                className="font-weight-bolder mb-2"
                                                style={{ fontSize: "14px" }}
                                            >Select Bucket<small className="text-info"></small>
                                            </Label>
                                            <Select
                                                id="bucketID"
                                                isMulti={false}
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                name="bucketID"
                                                placeholder="Select Bucket"
                                                value={bucketOptions.storyBuckets?.find(opt => opt.value === details.storyBucketId)}
                                                onChange={(event) => {
                                                    handleBucketChange(event)
                                                }}
                                                isDisabled={!isEditing}
                                                options={bucketOptions.storyBuckets}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {(!isEditing && staticDisable) ?
                                    < Button className="d-block ml-3 mt-2" color="primary" onClick={handleSubmit} disabled={isLoading}>
                                        {isLoading ? 'Loading...' : 'Submit'}
                                    </Button>
                                    : null}<br></br>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Form>
            <Row lg="12">
                <Col>
                    <Card>
                        <CardBody>
                            <Col md={6} >
                                <h1> Tags </h1>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <CreatableSelect
                                        id="tags"
                                        isMulti
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        name="tags"
                                        placeholder="Enter Tags"
                                        value={Tags.tags}
                                        onChange={(event) => {
                                            setTags((prevState) => ({
                                                ...prevState,
                                                tags: event,
                                            }))
                                        }}
                                        disabled={(!isEditing) || (staticDisable)}
                                        components={{
                                            DropdownIndicator: null,
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            {(!isEditing && staticDisable) ?
                                <Col>
                                    <Button color="primary" onClick={handleSaveTags}>Submit</Button>
                                </Col>
                                : null}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {sendAINotification && (
                <Row lg="12">
                    <Col>
                        <Card>
                            <CardBody>
                                <Col md={6}>
                                    <h1>Notification Metadata</h1>
                                </Col>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label>Title</Label>
                                        <Input
                                            type="text"
                                            name="title"
                                            value={notificationMetadata.title}
                                            onChange={handleMetadataChange}
                                            placeholder="Enter Title"
                                            required
                                            style={{ borderColor: notificationMetadata.title === "" ? 'red' : '' }}
                                        />
                                        {showNotificationTitleError && (
                                            <div style={{ color: 'red', marginTop: '5px' }}>
                                                Title is required.
                                            </div>
                                        )}
                                    </FormGroup>
                                </Col>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label>Subtitle</Label>
                                        <Input
                                            type="text"
                                            name="subtitle"
                                            value={notificationMetadata.subtitle}
                                            onChange={handleMetadataChange}
                                            placeholder="Enter Subtitle"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label>Start Time</Label>
                                        <Input
                                            type="time"
                                            step="1"  
                                            name="startTime"
                                            value={notificationMetadata.startTime}
                                            onChange={handleMetadataChange}
                                        />
                                    </FormGroup>
                                </Col>
                                {!isEditing && staticDisable ? (
                                    <Col>
                                        < Button className="d-block ml-3 mt-2" color="primary" onClick={handleSubmit} disabled={isLoading}>
                                            {isLoading ? 'Loading...' : 'Submit'}
                                        </Button>
                                    </Col>
                                ) : null}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}
            <Row lg="12">
                <Col>
                    {cta !== undefined && cta.length > 0 ? <Card>
                        <CardBody>
                            <Row>
                                <Col md={10} >
                                    <h1> CTA </h1>
                                </Col>
                                <Col md={2}>
                                    <Button className="m-2" color="info" onClick={redirectToCTA}>Edit Cta</Button>
                                </Col>
                            </Row>
                            <Row form>
                                {/* Create two columns */}
                                <Col md={6}>
                                    <Card>
                                        <CardBody>
                                            {/* Iterate and render items in the first column */}
                                            {cta.map((item, index) => (
                                                index % 2 === 0 && ( // Check if the index is even
                                                    <Card key={item.id}>
                                                        <Col md={12}>  {/* Use full column inside */}
                                                            {item.Image && <img src={item.Image} alt={item.text} />}
                                                            <h4>Text: {item.text}</h4>
                                                            <h4>Client ID:
                                                                {clients?.find((client) => client.value === item.clientId)?.label}
                                                            </h4>
                                                            {item.iconFilename && <img src={filenameUrlMap[item.iconFilename]} alt={item.text} />}
                                                        </Col>
                                                    </Card>
                                                )
                                            ))}
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col md={6}>
                                    <Card>
                                        <CardBody>
                                            {/* Iterate and render items in the second column */}
                                            {cta.map((item, index) => (
                                                index % 2 !== 0 && ( // Check if the index is odd
                                                    <Card key={item.id}>
                                                        <Col md={12}>
                                                            {item.Image && <img src={item.Image} alt={item.Text} />}
                                                            <h4>Text: {item.text}</h4>
                                                            <h4>Client ID:
                                                                {clients?.find((client) => client.value === item.clientId)?.label} </h4>
                                                            {/* {item.Image && <img src={item.Image} alt={item.Text} />} */}
                                                            {item.iconFilename && <img src={filenameUrlMap[item.iconFilename]} alt={item.text} />}
                                                        </Col>
                                                    </Card>
                                                )
                                            ))}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card> : null}
                </Col>
            </Row>
            {
                !isEditing ? <Row lg="12">
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <div>
                                    <TargetingRules
                                        showAgeGenderRules={true}
                                        showCustomAudienceTargetingRules={false}
                                        showPlacementRules={false}
                                        showClientRules={true}
                                        showHardFiltering={false}
                                        isAdvertisingAttributes={true}
                                        id={props.match.params.id}
                                        getRules={fetchStoryRuleSets}
                                        saveRules={updateStoryRuleSets}
                                        getClients={getClients}
                                        getCountries={getCountries}
                                        getRegions={getRegions}
                                        getCities={getCities}
                                        updateRulesAllowed={updateRulesAllowed()}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row> : null
            }
            {
                isEditing ?
                    < Button className="d-block ml-3 mt-2" color="primary" onClick={handleSubmit} disabled={isLoading} >
                        {isLoading ? 'Loading...' : 'Submit'}
                    </Button> : null
            }
            <ConfirmationModal
                show={confirmationModalIsOpen}
                modaltext={"submit"}
                onConfirmationTrue={() => {
                    setConfirmationModalIsOpen(false);
                    handleSubmit();
                }}
                onHide={() => setConfirmationModalIsOpen(false)}
            />
            <ResponseModal
                show={successModalDisplay}
                onHide={successModalClose}
                modalheading={"Success"}
                modaltext={successModalText}
            />
            <ResponseModal
                show={failureModalDisplay}
                onHide={failureModalClose}
                modalheading={"Error"}
                modaltext={failureModalText}
            />
        </Container >
    );
};

export default StoriesEdit;
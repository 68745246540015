import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { Link } from "react-router-dom";
import ResponseModal from "../ResponseModal";
import { errorDescription, error } from "../../../utilities/commonUtil";
import {
  getCarouselById,
  updateCarousel,
  getLanguages,
} from "../../../utilities/apiUtils/carousel";
import { getBrandCampaigns } from "../../../utilities/apiUtils/brandCampaign";

const EditCarouselForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    type: "",
    brandCampaignID: "",
    aspectRatio: 2.4,
    placements: [],
    contentType: [],
    minAppVersion: 1, // Added minAppVersion
    maxAppVersion: 0, // Added maxAppVersion
    background: {
      gradient: {
        startColor: "",
        centerColor: "",
        endColor: "",
      },
      darkThemegradient: {
        startColor: "",
        centerColor: "",
        endColor: "",
      },
      imageFile: "",
      imageURL: "",
      darkThemeImageFile: "",
      darkThemeImageURL: "",
      deeplink: {
        app: "",
        keyboard: "",
        hamburgerMenu: "",
        settings: "",
      },
      packageFilters: {
        whitelisted: [],
        blacklisted: [],
      },
      trackers: [{ type: "", url: "" }],
      translations: [
        {
          languageCode: null,
          imageFilename: null,
          imageFile: null,
          darkThemeImageFilename: null,
          darkThemeImageFile: null,
        },
      ],
    },
  });

  const history = useHistory();
  const { id } = useParams();

  const [updateCarouselId, setupdateCarouselId] = useState(null);
  const [addBrandCampaignIDOption, setAddBrandCampaignIDOption] = useState([]);
  const [languagesOptions, setLanguagesOptions] = useState([]);
  const [successModalState, setSuccessModalState] = useState({
    successModalDisplay: false,
    successModalText: "",
  });
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });

  const handleTrackerChange = (index, field, value) => {
    const updatedTrackers = [...formData.background.trackers];
    updatedTrackers[index][field] = value;

    setFormData({
      ...formData,
      background: {
        ...formData.background,
        trackers: updatedTrackers,
      },
    });
  };
  // Add package to whitelisted or blacklisted
const addPackageFilter = (type) => {
  setFormData((prevFormData) => ({
    ...prevFormData,
    packageFilters: {
      ...prevFormData.packageFilters,
      [type]: [...prevFormData.packageFilters[type], ""],
    },
  }));
};

// Remove package from whitelisted or blacklisted
const removePackageFilter = (type, index) => {
  setFormData((prevFormData) => {
    const updatedPackages = [...prevFormData.packageFilters[type]];
    updatedPackages.splice(index, 1);
    return {
      ...prevFormData,
      packageFilters: {
        ...prevFormData.packageFilters,
        [type]: updatedPackages,
      },
    };
  });
};

// Handle change in package filter (whitelisted or blacklisted)
const handlePackageFilterChange = (type, index, value) => {
  setFormData((prevFormData) => {
    const updatedPackages = [...prevFormData.packageFilters[type]];
    updatedPackages[index] = value;
    return {
      ...prevFormData,
      packageFilters: {
        ...prevFormData.packageFilters,
        [type]: updatedPackages,
      },
    };
  });
};

  const handleTrackerImageFileChange = (index, field, file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      handleTranslationChange(index, field, event.target.result);
    };
    reader.readAsDataURL(file);
  };

  const removeTranslation = (indexToRemove) => {
    const updatedTranslations = [...formData.background.translations];

    // Remove the translation entry at the specified index
    updatedTranslations.splice(indexToRemove, 1);

    // Update the state with the modified translations array
    setFormData({
      ...formData,
      background: {
        ...formData.background,
        translations: updatedTranslations,
      },
    });
  };

  // Define the function to remove images
  const removeImage = (isDarkTheme) => {
    // Create a copy of the form data object
    const updatedFormData = { ...formData };

    // Check if the dark theme image needs to be removed
    if (isDarkTheme) {
      // Set the dark theme image URL to null or an empty string
      updatedFormData.background.darkThemeImageURL = null; // Change this to an empty string if needed: ''
    } else {
      // Remove the regular background image
      updatedFormData.background.imageURL = null; // Change this to an empty string if needed: ''
    }

    // Update the state with the modified form data
    setFormData(updatedFormData);
  };

  const setBrandCampaign = async () => {
    const brandCampaigns = await getBrandCampaigns();

    if (brandCampaigns?.length > 0) {
      const brandCampaignOptions = brandCampaigns.map((brandCampaign) => {
        return {
          value: brandCampaign.value,
          label: brandCampaign.label,
          target: {
            name: "brandCampaignID",
            value: {
              value: brandCampaign.value,
              label: brandCampaign.label,
            },
          },
        };
      });
      setAddBrandCampaignIDOption(brandCampaignOptions);
    }
  };

  const getLanguagesOptions = async () => {
    try {
      let response = await getLanguages();
      // remove All if present
      response = response?.filter((item) => item?.value !== "All");
      setLanguagesOptions(response);
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const addTracker = () => {
    setFormData({
      ...formData,
      background: {
        ...formData.background,
        trackers: [
          ...formData.background.trackers,
          {
            type: "",
            url: "",
          },
        ],
      },
    });
  };

  const removeTracker = (indexToRemove) => {
    // Create a copy of the trackers array from the form data
    const updatedTrackers = [...formData.background.trackers];

    // Remove the tracker at the specified index
    updatedTrackers.splice(indexToRemove, 1);

    // Update the form data with the modified trackers array
    setFormData({
      ...formData,
      background: {
        ...formData.background,
        trackers: updatedTrackers,
      },
    });
  };

  const handleTrackerTypeChange = (index, value) => {
    handleTrackerChange(index, "type", value);
  };

  const handleTranslationChange = (index, field, value) => {
    setFormData((prevFormData) => {
      const updatedTranslations = [...prevFormData.background.translations];
      updatedTranslations[index] = {
        ...updatedTranslations[index],
        [field]: value,
      };

      return {
        ...prevFormData,
        background: {
          ...prevFormData.background,
          translations: updatedTranslations,
        },
      };
    });
  };

  const addTranslation = () => {
    setFormData({
      ...formData,
      background: {
        ...formData.background,
        translations: [
          ...formData.background.translations,
          {
            languageCode: null,
            imageFilename: null,
            imageFile: null,
            darkThemeImageFilename: null,
            darkThemeImageFile: null,
          },
        ],
      },
    });
  };

  const handleTypeChange = (selectedOption) => {
    setFormData({ ...formData, type: selectedOption.value });
  };

  const typeOptions = [
    { value: "generic", label: "Generic" },
    { value: "sotd", label: "SOTD" },
    { value: "full_image", label: "Full Image" },
    { value: "heads", label: "Heads" },
    { value: "permissions", label: "Permissions" },
  ];

  useEffect(() => {
    const fetchCarouselData = async () => {
      try {
        const carousel = await getCarouselById(id);
        if (carousel) {
          // Check if carousel.background.trackers is empty or undefined, then set a default value
          const updatedTrackers =
            carousel.background.trackers &&
              carousel.background.trackers.length > 0
              ? carousel.background.trackers
              : [];

          const updatedCarousel = {
            ...carousel,
            background: {
              ...carousel.background,
              trackers: updatedTrackers,
              translations: carousel.background.translations || [],
            },
          };
          // Update the form data with the API response
          setFormData(updatedCarousel);
        } else {
          console.error("Carousel data not found");
        }
      } catch (error) {
        console.error("Failed to fetch carousel data: ", error);
      }
    };

    fetchCarouselData();
    setBrandCampaign();
    getLanguagesOptions();
  }, [id]);

  const placementOptions = [
    { value: "app", label: "App" },
    { value: "keyboard", label: "Keyboard" },
    { value: "settings", label: "Settings" },
    { value: "hamburgerMenu", label: "HamburgerMenu" }
  ];

  const contentTypeOptions = [
    { value: "sticker", label: "Sticker" },
    { value: "gif", label: "GIF" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(`Changing ${name} to ${value}`);

    // Mapping for gradient and dark theme gradient fields
    const gradientMapping = {
      startColor: "startColor",
      centerColor: "centerColor",
      endColor: "endColor",
      darkThemeStartColor: "startColor",
      darkThemeCenterColor: "centerColor",
      darkThemeEndColor: "endColor",
    };

    // Check if the name is in the gradientMapping
    if (gradientMapping.hasOwnProperty(name)) {
      const mappedName = gradientMapping[name];

      // Check if it's a dark theme field
      if (name.startsWith("darkTheme")) {
        // Update dark theme gradient
        setFormData((prevFormData) => ({
          ...prevFormData,
          background: {
            ...prevFormData.background,
            darkThemegradient: {
              ...prevFormData.background.darkThemegradient,
              [mappedName]: value,
            },
          },
        }));
      } else {
        // Update regular gradient
        setFormData((prevFormData) => ({
          ...prevFormData,
          background: {
            ...prevFormData.background,
            gradient: {
              ...prevFormData.background.gradient,
              [mappedName]: value,
            },
          },
        }));
      }
    } else if (name.startsWith("deeplink.")) {
      const appOrKeyboard = name.split(".")[1];
      setFormData((prevFormData) => ({
        ...prevFormData,
        background: {
          ...prevFormData.background,
          deeplink: {
            ...prevFormData.background.deeplink,
            [appOrKeyboard]: value,
          },
        },
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleContentTypeChange = (selectedOptions) => {
    const contentTypes = selectedOptions.map((option) => option.value);
    setFormData({ ...formData, contentType: contentTypes });
  };

  const handlePlacementChange = (selectedOptions) => {
    const placements = selectedOptions.map((option) => option.value);
    setFormData({ ...formData, placements });
  };

  // Handle image file input change
  const handleImageFileChange = (e, isDarkTheme) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          background: {
            ...prevFormData.background,
            [isDarkTheme ? "darkThemeImageFile" : "imageFile"]:
              event.target.result,
          },
        }));
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleDetailsChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a function to filter out empty or falsy arrays
    const filterEmptyArrays = (array) =>
      array && array.length > 0 ? array : undefined;

    const combinedData = {
      name: formData.name,
      description: formData.description,
      type: formData.type,
      brandCampaignID: formData.brandCampaignID,
      aspectRatio: formData.aspectRatio,
      placements: formData.placements,
      contentType: formData.contentType,
      packageFilters: {
        whitelisted: formData.packageFilters.whitelisted || [],
        blacklisted: formData.packageFilters.blacklisted || [],
      },
      minAppVersion: parseFloat(formData.minAppVersion), 
      maxAppVersion: parseFloat(formData.maxAppVersion), 
      background: {
        gradient: formData.background.gradient,
        darkThemegradient: formData.background.darkThemegradient,
        imageFilename: "",
        imageFile: formData.background.imageFile,
        darkThemeImageFilename: "",
        darkThemeImageFile: formData.background.darkThemeImageFile,
        deeplink: formData.background.deeplink,
        // Conditionally add trackers and translations only when they have values
        ...(filterEmptyArrays(formData.background.trackers) && {
          trackers: formData.background.trackers,
        }),
        ...(filterEmptyArrays(formData.background.translations) && {
          translations: formData.background.translations,
        }),
      },
    };

    try {
      // Your update operation code
      const response = await updateCarousel(id, combinedData);
      setupdateCarouselId(id); // Set the created carousel's ID in state
      setSuccessModalState({
        successModalDisplay: true,
        successModalText: "Carousel updated successfully",
      });
    } catch (error) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: "Error occurred while updating the carousel",
      });
      console.error("Error updating the carousel:", error);
    }
  };

  return (
    <Container className="carousel-form-container my-5">
      <Row>
        <h1 className="carousel-form-title mb-4">Update Carousel</h1>
        {formData.type !== "full_image" && ( // Check if formData.type is not 'full_image'
          <div className="ml-auto">
            <Link to={`/cre/carousel/items/${id}`}>
              <Button color="primary" className="mr-2">
                Edit Items
              </Button>
            </Link>
          </div>
        )}
      </Row>
      <Card className="carousel-form-card">
        <CardBody>
          <Form onSubmit={handleSubmit}>
            {/* General Information */}
            <h3>General Information</h3>
            <Card className="mb-4">
              <CardBody>
                <FormGroup className="mb-4">
                  <Label for="name">Name</Label>
                  <Input
                    className="col-sm-4"
                    type="text"
                    name="name"
                    id="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
                <FormGroup className="mb-4">
                  <Label for="description">Description</Label>
                  <Input
                    type="text"
                    name="description"
                    id="description"
                    value={formData.description}
                    onChange={handleChange}
                    className="carousel-form-input"
                  />
                </FormGroup>
              </CardBody>
            </Card>

            {/* Type, Brand Campaign ID, Aspect Ratio, Placements, Content Type */}
            <h3>Type & Details</h3>
            <Card className="mb-4">
              <CardBody>
                <div className="row">
                  {/* First Input - Type */}
                  <div className="col-md-6">
                    <FormGroup className="mb-4">
                      <Label for="type">Type</Label>
                      <Select
                        value={typeOptions.find(
                          (option) => option.value === formData.type
                        )}
                        onChange={handleTypeChange}
                        options={typeOptions}
                      />
                    </FormGroup>
                  </div>

                  {/* Second Input - Brand Campaign ID */}
                  <div className="col-md-6">
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "16px" }}
                      >
                        Brand Campaign:
                      </Label>
                      <Select
                        id="brandCampaign"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        name="brandCampaign"
                        placeholder="Select BrandCampaign"
                        value={formData.brandCampaignID}
                        onChange={handleDetailsChange}
                        options={addBrandCampaignIDOption}
                      />
                    </FormGroup>
                  </div>

                  {/* Third Input - Aspect Ratio */}
                  <div className="col-md-6">
                    <FormGroup className="mb-4">
                      <Label for="aspectRatio">Aspect Ratio</Label>
                      <Input
                        type="number"
                        name="aspectRatio"
                        id="aspectRatio"
                        value={formData.aspectRatio}
                        onChange={handleChange}
                        className="carousel-form-input"
                      />
                    </FormGroup>
                  </div>

                  {/* Fourth Input - Placements */}
                  <div className="col-md-6">
                    <FormGroup className="mb-4">
                      <Label for="placements">Placements</Label>
                      <Select
                        isMulti
                        name="placements"
                        options={placementOptions}
                        value={placementOptions?.filter((option) =>
                          formData.placements?.includes(option.value)
                        )}
                        onChange={handlePlacementChange}
                      />
                    </FormGroup>
                  </div>

                  {/* Fifth Input - Content Type */}
                  <div className="col-md-6">
                    <FormGroup className="mb-4">
                      <Label for="contentType">Content Type</Label>
                      <Select
                        isMulti
                        name="contentType"
                        options={contentTypeOptions}
                        value={contentTypeOptions.filter((option) =>
                          formData.contentType.includes(option.value)
                        )}
                        onChange={handleContentTypeChange}
                      />
                    </FormGroup>
                  </div>

                  {/* Min App Version */}
                  <div className="col-md-6">
                    <FormGroup className="mb-4">
                      <Label for="minAppVersion">Min App Version</Label>
                      <Input
                        type="number"
                        name="minAppVersion"
                        id="minAppVersion"
                        value={formData.minAppVersion}
                        onChange={handleChange}
                        className="carousel-form-input"
                      />
                    </FormGroup>
                  </div>

                  {/* Max App Version */}
                  <div className="col-md-6">
                    <FormGroup className="mb-4">
                      <Label for="maxAppVersion">Max App Version</Label>
                      <Input
                        type="number"
                        name="maxAppVersion"
                        id="maxAppVersion"
                        value={formData.maxAppVersion}
                        onChange={handleChange}
                        className="carousel-form-input"
                      />
                    </FormGroup>
                  </div>
                </div>
              </CardBody>
            </Card>


            {/* Background Colors */}
            <h3>Background Colors</h3>
            <Card className="mb-4">
              <CardBody>
                <Row className="pb-3 pt-1" form>
                  {/* Regular Theme Colors */}
                  <Col md={4} xs={4}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "16px" }}
                      >
                        Start Color (Hex):{" "}
                        <span className="text-danger">*</span>{" "}
                        <div
                          style={{
                            backgroundColor:
                              formData.background.gradient.startColor,
                            width: "16px",
                            height: "16px",
                            display: "inline-block",
                            verticalAlign: "middle",
                            border: "1px solid #000",
                            borderRadius: "30%",
                          }}
                        >
                          {" "}
                        </div>
                      </Label>
                      <Input
                        type="text"
                        name="startColor"
                        id="startColor"
                        value={formData.background.gradient.startColor}
                        onChange={handleChange}
                        required
                        className="carousel-form-input"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4} xs={4}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "16px" }}
                      >
                        Center Color (Hex):{" "}
                        <span className="text-danger">*</span>{" "}
                        <div
                          style={{
                            backgroundColor:
                              formData.background.gradient.centerColor,
                            width: "16px",
                            height: "16px",
                            display: "inline-block",
                            verticalAlign: "middle",
                            border: "1px solid #000",
                            borderRadius: "30%",
                          }}
                        >
                          {" "}
                        </div>
                      </Label>
                      <Input
                        type="text"
                        name="centerColor"
                        id="centerColor"
                        value={formData.background.gradient.centerColor}
                        onChange={handleChange}
                        required
                        className="carousel-form-input"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4} xs={4}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "16px" }}
                      >
                        End Color (Hex): <span className="text-danger">*</span>{" "}
                        <div
                          style={{
                            backgroundColor:
                              formData.background.gradient.endColor,
                            width: "16px",
                            height: "16px",
                            display: "inline-block",
                            verticalAlign: "middle",
                            border: "1px solid #000",
                            borderRadius: "30%",
                          }}
                        >
                          {" "}
                        </div>
                      </Label>
                      <Input
                        type="text"
                        name="endColor"
                        id="endColor"
                        value={formData.background.gradient.endColor}
                        onChange={handleChange}
                        required
                        className="carousel-form-input"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="pb-3 pt-1" form>
                  {/* Dark Theme Colors */}
                  <Col md={4} xs={4}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "16px" }}
                      >
                        Dark Theme Start Color (Hex):{" "}
                        <span className="text-danger">*</span>{" "}
                        <div
                          style={{
                            backgroundColor:
                              formData.background.darkThemegradient.startColor,
                            width: "16px",
                            height: "16px",
                            display: "inline-block",
                            verticalAlign: "middle",
                            border: "1px solid #000",
                            borderRadius: "30%",
                          }}
                        ></div>
                      </Label>
                      <Input
                        type="text"
                        name="darkThemeStartColor"
                        id="darkThemeStartColor"
                        value={formData.background.darkThemegradient.startColor}
                        onChange={handleChange}
                        required
                        className="carousel-form-input"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4} xs={4}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "16px" }}
                      >
                        Dark Theme Center Color (Hex):{" "}
                        <span className="text-danger">*</span>{" "}
                        <div
                          style={{
                            backgroundColor:
                              formData.background.darkThemegradient.centerColor,
                            width: "16px",
                            height: "16px",
                            display: "inline-block",
                            verticalAlign: "middle",
                            border: "1px solid #000",
                            borderRadius: "30%",
                          }}
                        ></div>
                      </Label>
                      <Input
                        type="text"
                        name="darkThemeCenterColor"
                        id="darkThemeCenterColor"
                        value={
                          formData.background.darkThemegradient.centerColor
                        }
                        onChange={handleChange}
                        required
                        className="carousel-form-input"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4} xs={4}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "16px" }}
                      >
                        Dark Theme End Color (Hex):{" "}
                        <span className="text-danger">*</span>{" "}
                        <div
                          style={{
                            backgroundColor:
                              formData.background.darkThemegradient.endColor,
                            width: "16px",
                            height: "16px",
                            display: "inline-block",
                            verticalAlign: "middle",
                            border: "1px solid #000",
                            borderRadius: "30%",
                          }}
                        ></div>
                      </Label>
                      <Input
                        type="text"
                        name="darkThemeEndColor"
                        id="darkThemeEndColor"
                        value={formData.background.darkThemegradient.endColor}
                        onChange={handleChange}
                        required
                        className="carousel-form-input"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <h3>Background Image</h3>
            <Card className="mb-4">
              <CardBody>
                <div className="row">
                  {/* First Input - Background Image File */}
                  <div className="col-md-6">
                    <FormGroup className="mb-4">
                      <Label for="backgroundImageFile">
                        Background Image File
                      </Label>
                      <Input
                        type="file"
                        name="backgroundImageFile"
                        id="backgroundImageFile"
                        onChange={(e) => handleImageFileChange(e, false)}
                        className="carousel-form-input"
                      />
                      {formData.background.imageURL && (
                        <div className="carousel-form-image-preview mt-3 d-flex align-items-center">
                          <img
                            src={formData.background.imageURL}
                            alt="Selected File"
                            className="carousel-form-preview-image img-thumbnail"
                            style={{ maxWidth: "150px" }}
                          />
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() => removeImage(false)}
                            className="ml-2"
                          >
                            Remove Image
                          </Button>
                        </div>
                      )}
                    </FormGroup>
                  </div>

                  {/* Second Input - Dark Theme Image File */}
                  <div className="col-md-6">
                    <FormGroup className="mb-4">
                      <Label for="darkThemeImageFile">
                        Dark Theme Image File
                      </Label>
                      <Input
                        type="file"
                        name="darkThemeImageFile"
                        id="darkThemeImageFile"
                        onChange={(e) => handleImageFileChange(e, true)}
                        className="carousel-form-input"
                      />
                      {formData.background.darkThemeImageURL && (
                        <div className="carousel-form-image-preview mt-3 d-flex align-items-center">
                          <img
                            src={formData.background.darkThemeImageURL}
                            alt="Selected File"
                            className="carousel-form-preview-image img-thumbnail"
                            style={{ maxWidth: "150px" }}
                          />
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() => removeImage(true)}
                            className="ml-2"
                          >
                            Remove Image
                          </Button>
                        </div>
                      )}
                    </FormGroup>
                  </div>
                </div>
              </CardBody>
            </Card>

            {/* Deeplinks */}
            <h3>Deeplinks</h3>
            <Card className="mb-4">
              <CardBody>
                <div className="row">
                  {/* First Input - App Deeplink */}
                  <div className="col-md-6">
                    <FormGroup className="mb-4">
                      <Label for="deeplinkApp">App Deeplink</Label>
                      <Input
                        type="text"
                        name="deeplink.app"
                        id="deeplinkApp"
                        value={formData.background.deeplink.app}
                        onChange={handleChange}
                        className="carousel-form-input form-control-lg" // Use form-control-lg for larger size
                      />
                    </FormGroup>
                  </div>

                  {/* Second Input - Keyboard Deeplink */}
                  <div className="col-md-6">
                    <FormGroup className="mb-4">
                      <Label for="deeplinkKeyboard">Keyboard Deeplink</Label>
                      <Input
                        type="text"
                        name="deeplink.keyboard"
                        id="deeplinkKeyboard"
                        value={formData.background.deeplink.keyboard}
                        onChange={handleChange}
                        className="carousel-form-input form-control-lg" // Use form-control-lg for larger size
                      />
                    </FormGroup>
                  </div>
                </div>
                <div className="row">
                  {/* Third Input - Hamburger Deeplink */}
                  <div className="col-md-6">
                    <FormGroup className="mb-4">
                      <Label for="deeplinkHamburgerMenu">
                        Hamburger Menu Deeplink
                      </Label>
                      <Input
                        type="text"
                        name="deeplink.hamburgerMenu"
                        id="deeplinkHamburgerMenu"
                        value={formData.background.deeplink.hamburgerMenu}
                        onChange={handleChange}
                        className="carousel-form-input form-control-lg" // Use form-control-lg for larger size
                      />
                    </FormGroup>
                  </div>

                  {/* Fourth Input - Settings Deeplink */}
                  <div className="col-md-6">
                    <FormGroup className="mb-4">
                      <Label for="deeplinkSettings">Settings Deeplink</Label>
                      <Input
                        type="text"
                        name="deeplink.settings"
                        id="deeplinkSettings"
                        value={formData.background.deeplink.settings}
                        onChange={handleChange}
                        className="carousel-form-input form-control-lg" // Use form-control-lg for larger size
                      />
                    </FormGroup>
                  </div>
                </div>
              </CardBody>
            </Card>
            <h3>Package Filters</h3>
            <Card className="mb-4">
              <CardBody>
                <h5>Whitelisted Packages</h5>
                {formData.packageFilters?.whitelisted?.map((packageName, index) => (
                  <Row key={index} className="mb-2">
                    <Col md={10}>
                      <Input
                        type="text"
                        value={packageName}
                        onChange={(e) =>
                          handlePackageFilterChange("whitelisted", index, e.target.value)
                        }
                        placeholder="Enter package name"
                      />
                    </Col>
                    <Col md={2}>
                      <Button
                        color="danger"
                        onClick={() => removePackageFilter("whitelisted", index)}
                      >
                        Remove
                      </Button>
                    </Col>
                  </Row>
                ))}
                <Button color="secondary" onClick={() => addPackageFilter("whitelisted")}>
                  Add Whitelisted Package
                </Button>

                <hr />

                <h5>Blacklisted Packages</h5>
                {formData.packageFilters?.blacklisted?.map((packageName, index) => (
                  <Row key={index} className="mb-2">
                    <Col md={10}>
                      <Input
                        type="text"
                        value={packageName}
                        onChange={(e) =>
                          handlePackageFilterChange("blacklisted", index, e.target.value)
                        }
                        placeholder="Enter package name"
                      />
                    </Col>
                    <Col md={2}>
                      <Button
                        color="danger"
                        onClick={() => removePackageFilter("blacklisted", index)}
                      >
                        Remove
                      </Button>
                    </Col>
                  </Row>
                ))}
                <Button color="secondary" onClick={() => addPackageFilter("blacklisted")}>
                  Add Blacklisted Package
                </Button>
              </CardBody>
            </Card>
            {/* Trackers */}
            <h3>Trackers</h3>
            <Card className="mb-4">
              <CardBody>
                {formData.background.trackers.map((tracker, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-between align-items-center mb-3"
                  >
                    <Row form className="w-100">
                      <Col md={5}>
                        <FormGroup className="mb-md-0">
                          <Label>Tracker Type</Label>{" "}
                          <span className="text-danger">*</span>{" "}
                          <Input
                            type="select"
                            value={tracker.type}
                            onChange={(e) =>
                              handleTrackerTypeChange(index, e.target.value)
                            }
                            required
                          >
                            <option value="">Select Tracker Type</option>
                            <option value="click">Click</option>
                            <option value="impression">Impression</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={5}>
                        <FormGroup className="mb-md-0">
                          <Label>Tracker URL</Label>{" "}
                          <span className="text-danger">*</span>{" "}
                          <Input
                            type="text"
                            placeholder="Tracker URL"
                            value={tracker.url}
                            onChange={(e) =>
                              handleTrackerChange(index, "url", e.target.value)
                            }
                            required
                            style={{ width: "100%" }} // Adjust width here if needed
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        md={2}
                        className="text-end d-flex align-items-center justify-content-end"
                      >
                        {index === formData.background.trackers.length - 1 && (
                          <Button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => removeTracker(index)}
                            aria-label="Remove"
                            style={{
                              fontSize: "1rem",
                              padding: "5px 10px",
                              marginLeft: "auto",
                            }} // Adjust size and margin
                          >
                            X
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </div>
                ))}
                <Button color="secondary" onClick={addTracker}>
                  Add Tracker
                </Button>
              </CardBody>
            </Card>

            {/* Translations */}
            <h3>Translations</h3>
            <Card className="mb-4">
              <CardBody>
                {[...Array(formData.background.translations.length)].map(
                  (_, index) => (
                    <div key={index}>
                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label>Language Code</Label>{" "}
                            <span className="text-danger">*</span>{" "}
                            <Select
                              options={languagesOptions} // Pass your language options array here
                              value={
                                languagesOptions.find(
                                  (option) =>
                                    option.value ===
                                    formData.background.translations[index]
                                      .languageCode
                                ) || null
                              }
                              required
                              onChange={(selectedOption) =>
                                handleTranslationChange(
                                  index,
                                  "languageCode",
                                  selectedOption.value
                                )
                              }
                              placeholder="Select Language Code"
                            />
                          </FormGroup>
                        </Col>

                        <Col md={6}>
                          <FormGroup>
                            <Label>Image File</Label>
                            <Input
                              type="file"
                              onChange={(e) =>
                                handleTrackerImageFileChange(
                                  index,
                                  "imageFile",
                                  e.target.files[0]
                                )
                              }
                            />
                            {/* Display selected file */}
                            {formData.background.translations[index]
                              .imageURL && (
                                <div className="carousel-form-image-preview mt-3">
                                  <img
                                    src={
                                      formData.background.translations[index]
                                        .imageURL
                                    }
                                    alt="Selected File"
                                    className="carousel-form-preview-image img-thumbnail"
                                    style={{ maxWidth: "150px" }}
                                  />
                                </div>
                              )}
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label>Dark Theme Image File</Label>
                            <Input
                              type="file"
                              onChange={(e) =>
                                handleTrackerImageFileChange(
                                  index,
                                  "darkThemeImageFile",
                                  e.target.files[0]
                                )
                              }
                            />
                            {/* Display selected file */}
                            {formData.background.translations[index]
                              .darkThemeImageURL && (
                                <div className="carousel-form-image-preview mt-3">
                                  <img
                                    src={
                                      formData.background.translations[index]
                                        .darkThemeImageURL
                                    }
                                    alt="Selected File"
                                    className="carousel-form-preview-image img-thumbnail"
                                    style={{ maxWidth: "150px" }}
                                  />
                                </div>
                              )}
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <Button
                            type="button"
                            color="danger"
                            onClick={() => removeTranslation(index)}
                            aria-label="Remove Translation"
                            className="mt-4"
                          >
                            X
                          </Button>
                        </Col>
                      </Row>
                      {index !==
                        formData.background.translations.length - 1 && (
                          <hr />
                        )}{" "}
                    </div>
                  )
                )}
                <Button color="secondary" onClick={addTranslation}>
                  Add Translation
                </Button>
              </CardBody>
            </Card>
            {/* Submit button */}
            <div className="text-center">
              <Button
                color="primary"
                type="submit"
                className="carousel-form-button"
              >
                Update Carousel
              </Button>
            </div>
          </Form>
          <ResponseModal
            show={successModalState.successModalDisplay}
            onHide={() => {
              setSuccessModalState({
                successModalDisplay: false,
                successModalText: "",
              });
              history.push(`/cre/content-carousels`); // Navigate to the carousel details page
            }}
            modalheading="Success Heading"
            modaltext={successModalState.successModalText}
          />

          <ResponseModal
            show={failureModalState.failureModalDisplay}
            onHide={() => {
              setFailureModalState({
                failureModalDisplay: false,
                failureModalText: "",
              });
              history.push(`/cre/content-carousels`); // Navigate to the carousel details page
            }}
            modalheading="Failure Heading"
            modaltext={failureModalState.failureModalText}
          />
        </CardBody>
      </Card>
    </Container>
  );
};

export default EditCarouselForm;

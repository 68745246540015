import { host } from "./index";
import { retrieveFromLocalStorage } from "../localStorageUtil";
// let host = "http://localhost:1323";

// Fetch segments data
export let fetchSegmentsData = async () => {
    let url = `${host}/v4/internal/segments`;
    let token = retrieveFromLocalStorage("token");
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await response.json();
    } catch (error) {
        console.error("Error fetching segments data", error);
        return;
    }
};

// Fetch keyboard prompts for a specific segment
export let fetchKeyboardPromptsForSegment = async (selectedSegment) => {
    let url = `${host}/v4/internal/keyboardPrompts?status=all&segment=${selectedSegment}&uiType=pills`;
    let token = retrieveFromLocalStorage("token");
    
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        
        return await response.json();
    } catch (error) {
        console.error("Error fetching keyboard prompts for segment", error);
        return { keyboardPrompts: [] }; // Return an empty array in case of error
    }
};


// Update a keyboard prompts priority rule set by ID
export let updateKeyboardPromptsPriorityRuleSet = async (id, data) => {
    let url = `${host}/v4/internal/keyboardPrompts/priorityRuleSets/${id}`;
    let token = retrieveFromLocalStorage("token");
    try {
        const response = await fetch(url, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(data),
        });
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        return await response.json();
    } catch (error) {
        console.error("Error updating keyboard prompts priority rule set", error);
        return { status: "error" }; // Return a default status in case of error
    }
};

// List all keyboard prompts priority rule sets
export let listKeyboardPromptsPriorityRuleSets = async () => {
    let url = `${host}/v4/internal/keyboardPrompts/priorityRuleSets`;
    let token = retrieveFromLocalStorage("token");
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        return await response.json();
    } catch (error) {
        console.error("Error fetching keyboard prompts priority rule sets", error);
        return { priorityRuleSets: [] }; // Return an empty array in case of error
    }
};

// Create a new keyboard prompts priority rule set
export let createKeyboardPromptsPriorityRuleSet = async (data) => {
    let url = `${host}/v4/internal/keyboardPrompts/priorityRuleSets`;
    let token = retrieveFromLocalStorage("token");
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(data),
        });
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        return await response.json();
    } catch (error) {
        console.error("Error creating keyboard prompts priority rule set", error);
        return { status: "error" }; // Return a default status in case of error
    }
};

// Fetch keyboard prompts priority rule set by rule ID
export let fetchKeyboardPromptsPriorityRuleSetByRule = async (ruleID) => {
    let url = `${host}/v4/internal/keyboardPrompts/priorityRuleSets/${ruleID}`;
    let token = retrieveFromLocalStorage("token");
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        return await response.json();
    } catch (error) {
        console.error("Error fetching keyboard prompts priority rule set by rule ID", error);
        return { keyboardPrompts: [], priority: null }; // Return default values in case of error
    }
};

// Deactivate a keyboard prompts priority rule set by ID
export let deactivateKeyboardPromptsPriorityRuleSet = async (id) => {
    let url = `${host}/v4/internal/keyboardPrompts/priorityRuleSets/${id}/deactivate`;
    let token = retrieveFromLocalStorage("token");
    try {
        const response = await fetch(url, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        });
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        return await response.json();
    } catch (error) {
        console.error("Error deactivating keyboard prompts priority rule set", error);
        return { status: "error" }; // Return a default status in case of error
    }
};

// Activate a keyboard prompts priority rule set by ID
export let activateKeyboardPromptsPriorityRuleSet = async (id) => {
    let url = `${host}/v4/internal/keyboardPrompts/priorityRuleSets/${id}/activate`;
    let token = retrieveFromLocalStorage("token");
    try {
        const response = await fetch(url, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        });
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        return await response.json();
    } catch (error) {
        console.error("Error activating keyboard prompts priority rule set", error);
        return { status: "error" }; // Return a default status in case of error
    }
};

import { retrieveFromLocalStorage } from "../localStorageUtil";
import { host } from "./index";

// List all appResources
export let fetchAppResources = async (page,limit,description,sortOrder) => {
    let url = `${host}/v4/internal/appResources?limit=${limit}&page=${page}&description=${description}&sortOrder=${sortOrder}`;
    let token = retrieveFromLocalStorage("token");

    try {
        let response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
        return await response.json();
    } catch (error) {
        return ;
    }   
}

// Add appResources
export let addAppResourcesData = async (payload) => {
    let url = `${host}/v4/internal/appResources`;
    let token = retrieveFromLocalStorage("token");

    try {
        let response = await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(payload),
        })
        return await response.json();
    } catch (error) {
        return ;
    }
}


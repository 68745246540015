import React, { useState, useEffect } from "react";
import QuickReplyTextBulkUploadSampleCSV from './assets/sample-quick-replies.csv'
import QuickReplyImageBulkUploadSampleCSV from './assets/sample-quick-replies-image-bulk-upload.csv'
import Select, { components } from "react-select";
import { getKeyboardThemeCategoriesForForm } from "../../../utilities/apiUtils/themes";
import {
  validateHexCode,
  validateInteger,
  validateFloat,
} from "../../../utilities/commonUtil";
import {
  Col,
  Row,
  Button,
  Container,
  Card,
  CardBody,
  Input,
  Label,
  Form,
  FormGroup,
  CustomInput,
  Spinner
} from "reactstrap";
import { getQuickReplyCategories } from "../../../utilities/apiUtils/quickReplies";
import {quickRepliesBulkUploadType,QUICK_REPLY_IMAGE_BULK_UPLOAD_MAX_ZIP_FILE_SIZE} from "../../../config/quickReplies";

const QuickReplyForm = (props) => {
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [formTextState, setFormTextState] = useState({
    quickReplies: props?.quickReply?.quickReplies,
    autoPublish: props?.quickReply?.autoPublish,
    categoryID: props?.quickReply?.categoryID,
    type: props?.quickReply?.type,
    emojiToggle: props?.quickReply?.emojiToggle,
  });
  const [displayError, setDisplayError] = useState(false);
  const [categories, setCategories] = useState([]);
  const [zipFile, setZipFile] = useState(null);
  const [fileSizeError, setFileSizeError] = useState(null);


  let filteredQuickReplyCategories = categories.filter((item) => item?.viewType === formTextState?.type?.value);
  let quickReplyCategories = filteredQuickReplyCategories.map((item) => ({
    value: item.ID,
    label: item.name,
  }));

  let maxFileSizeInMB = QUICK_REPLY_IMAGE_BULK_UPLOAD_MAX_ZIP_FILE_SIZE / (1024 * 1024);
  maxFileSizeInMB = maxFileSizeInMB.toFixed(1);
  let disableSubmitButton =  formTextState?.type?.value === "image" ? !zipFile || fileSizeError : !formTextState?.categoryID || !formTextState?.type

  useEffect(() => {
    const _getQuickReplyCategories = async () => {
      let response = await getQuickReplyCategories();
      setCategories(response?.quickReplyCategories);
    };
    _getQuickReplyCategories();
  }, []);

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: files[0],
    }));

    event.persist();
    // For showing Image preview    
    if (files[0].type === "application/json") {
      var reader = new FileReader();
      reader.readAsText(files[0]);


      reader.onload = () => {
        if (name === "quickReplies") {
          const result = [];
          const obj = JSON.parse(reader.result);
          for (var i in obj)
            result.push(obj[i]);
          setFormTextState((prev) => ({
            ...prev,
            quickReplies: result,
            fileType: files[0].type
          }));
        }
      };
    } else {
      setFormTextState((prev) => ({
        ...prev,
        quickReplies: files[0],
        fileType: files[0].type
      }))
    }
  };

  const handleCheckBoxChange = (event) => {
    const { id, checked } = event.target;

    setFormTextState((prev) => ({
      ...prev,
      [id]: checked,
    }));

    setDisplayError(false);
  };

  const submit = async (event, zipFile) => {
    event.preventDefault();
    setLoading(true)
    setDisabled(true)
    const data = await props?.submit(formTextState, zipFile);
    if (data === false || data === undefined) {
      setLoading(false)
      setDisabled(false)
    }

  };

  const handleCategoryIDChange = (option) => {
    setFormTextState((prev) => ({
      ...prev,
      categoryID: option,
    }));
  };

  const handleTypeChange = (option) => {
    setFormTextState((prev) => ({
      ...prev,
      type: option,
    }));
  };

  const handleZipFileChange = (event) => {
    const {files } = event.target;
    const selectedFile = files[0];
  
    if (selectedFile.size > QUICK_REPLY_IMAGE_BULK_UPLOAD_MAX_ZIP_FILE_SIZE) {
      let fileSize = selectedFile.size / (1024 * 1024);
      fileSize = fileSize.toFixed(1)

      setFileSizeError(`File size ${fileSize} MB exceeds the allowed limit of ${maxFileSizeInMB} MB`);
      setZipFile(null);
    } else {
      setZipFile(selectedFile);
      setFileSizeError(null);
    }
  };
  

  const getViewTypeForCategory = (categoryID) => {
    let viewType = categories?.find((item) => item?.ID === categoryID)?.viewType;
   return viewType;
   }
 
   const getViewTypeItem = (viewType) => {
     let viewTypeItem = quickRepliesBulkUploadType?.find(
       (item) => item?.value === viewType
     );
     return viewTypeItem;
   }
 
   const getViewTypeForCategoryID = async (categoryID) => {
     let viewType = getViewTypeForCategory(categoryID);
     let viewTypeItem = getViewTypeItem(viewType);
     return viewTypeItem;
   }

  // make the input field required
  const requiredComponent = (props) => (
    <div>
      <components.Input {...props} required={true} />
    </div>
  );

  const notRequiredComponent = (props) => (
    <div>
      <components.Input {...props} required={false} />
    </div>
  );

  

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">{props?.quickReply?.formName}</h1>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Form
                onSubmit={(event) => {
                  submit(event, zipFile);
                }}
              >
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        <strong>
                          Upload Quick Replies{" "}
                          <small className="text-warning">(If you leave client id as empty/global, rule_type is category else rule_type is custom.)</small>
                        </strong>
                        {formTextState?.type?.value === "image" ? (
                          <p style={{ fontSize: 12, marginBottom: 0 }}>
                            Use this template for Image type Quick Replies
                            <a href={QuickReplyImageBulkUploadSampleCSV} download="sample-quick-replies-image-bulk-upload.csv">
                              {" "} Sample File For Image Type Quick Replies Bulk Upload
                            </a>
                            <small className="text-warning">(For Image Type, Make Sure the number of entries in the CSV File matches the number or images provided in zip folder.)</small>
                          </p>
                        ) : (
                          <p style={{ fontSize: 12, marginBottom: 0 }}>
                            Use this template for text type Quick Replies
                            <a href={QuickReplyTextBulkUploadSampleCSV} download="sample-quick-replies.csv">
                            {" "} Sample File For Text Type Quick Replies Bulk Upload
                            </a>
                          </p>
                        )}
                      </Label>
                      <Input
                        type="file"
                        name="quickReplies"
                        defaultValue={props?.quickReply?.quickReplies}
                        onChange={(event) => handleFileChange(event)}
                        required
                        disabled={!props?.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                  {formTextState?.type?.value === "image" && (
                    <Col>
                      <FormGroup>
                        <Label>
                          <strong>Upload Zip Folder Containing the images{" "}<small className="text-warning">(File Size Can not Exceed {maxFileSizeInMB} MB.)</small></strong>
                        </Label>
                        <br></br>
                        {fileSizeError != null && <small className="text-error">({fileSizeError})</small>}
                        <Input
                          type="file"
                          name="zipFile"
                          onChange={(event) => handleZipFileChange(event)}
                          required
                          disabled={!props?.updateAllowed}
                        />
                      </FormGroup>
                    </Col>
                  )}
                </Row>

                <hr style={{ margin: 8 }} />
                <Row className="my-2">
                  <Col className="align-middle" display="table">
                    <Row className="pl-3">
                      <Col className="pl-0">
                        <strong>Auto Publish</strong>
                      </Col>
                      <Col
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div className="custom-control custom-switch">
                          <CustomInput
                            type="checkbox"
                            id="autoPublish"
                            value={formTextState?.autoPublish === true}
                            onChange={(event) => handleCheckBoxChange(event)}
                            checked={formTextState?.autoPublish === true}
                            disabled={!props?.updateAllowed}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  {formTextState?.type?.value === "text" && <Col className="align-middle" display="table">
                    <Row className="pl-3">
                      <Col className="pl-0">
                        <strong>Add Emojis to Text</strong>
                      </Col>
                      <Col
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div className="custom-control custom-switch">
                          <CustomInput
                            type="checkbox"
                            id="emojiToggle"
                            value={formTextState?.emojiToggle === true}
                            onChange={(event) => handleCheckBoxChange(event)}
                            checked={formTextState?.emojiToggle === true}
                            disabled={!props?.updateAllowed}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>}
                </Row>
                <hr style={{ margin: 8 }} />

                <Row className="my-2">
                <Col md={6} className="align-middle">
                    <FormGroup>
                      <Label>
                        <strong>Type</strong>
                      </Label>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        name="type"
                        value={formTextState?.type}
                        placeholder="Select Type"
                        isClearable={true}
                        options={quickRepliesBulkUploadType}
                        onChange={(option) => handleTypeChange(option)}
                        isDisabled={!props?.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                <Col md={6} className="align-middle">
                    <FormGroup>
                      <Label>
                        <strong>Category ID</strong>
                      </Label>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        name="categoryID"
                        value={formTextState?.categoryID}
                        placeholder="Select Category ID"
                        isClearable={true}
                        options={quickReplyCategories}
                        onChange={(option) => {
                          handleCategoryIDChange(option)
                          getViewTypeForCategoryID(option?.value).then((viewTypeItem) => {
                            handleTypeChange(viewTypeItem);
                          })

                        }}
                        isDisabled={!props?.updateAllowed}
                        components={{
                          Input:
                            formTextState.categoryID === ""
                              ? requiredComponent
                              : notRequiredComponent,
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                {displayError ? (
                  <p className="text-danger mb-0">
                    Please enter all valid details.
                  </p>
                ) : null}
                <br />
                {props?.updateAllowed ? (
                  <Button color="primary" type="submit" className="mt-2" disabled={disableSubmitButton || disabled}>
                    Submit
                  </Button>
                ) : (
                  <div />
                )}
              </Form>
            </CardBody>
          </Card>
        </Col>
        {loading ? (
          <Spinner color="info" className=" d-flex mx-auto" />
        ) : ""}
      </Row>
    </Container>
  );
};

export default QuickReplyForm;
import React from "react";
import { Badge } from "reactstrap";
import {
  selectFilter,
} from "react-bootstrap-table2-filter";

// status options for status filter
const statusOptions = {
  Unpublished: "Unpublished",
  Deactivated: "Deactivated",
  Published: "Published",
};

let statusFilter;
// Table columns that are displayed in the table
const tableColumns = [
  {
    dataField: "id",
    text: "#",
    sort: true,
    headerAttrs: { width: 60 },
    searchable: true,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "name",
    text: "Name",
    sort: true,
    headerAttrs: { width: 230 },
    searchable: true,
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "minAndroidSDKVersion",
    text: "Min Android SDK Version",
    sort: true,
    headerAttrs: { width: 100 },
    formatter: (cell, row) => {
      if(row.minAndroidSDKVersion === null || row.minAndroidSDKVersion === 0) {
        return "null";
      } else {
        return row.minAndroidSDKVersion;
      }
    },
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
    searchable: false,
  },
  {
    dataField: "deactivatedAt",
    text: "Status",
    headerAttrs: { width: 120 },
    sort: true,
    formatter: (cell, row) => {
      if (row.deactivatedAt !== null) 
        return <Badge className="badge-danger">Deactivated</Badge>;
      else if (row.publishedAt !== null)
        return <Badge className="badge-success">Published</Badge>;
      else
        return <Badge className="badge-info">Unpublished</Badge>;
    },
    filter: selectFilter({
      getFilter: (filter) => {
        statusFilter = filter;
      },
      options: statusOptions,
      style: {
        display: "none",
      },
    }),
    searchable: false,
  },
  {
    dataField: "updatedAt",
    text: "Updated At",
    headerAttrs: { width: 200 },
    sort: true,
    formatter: (cell) => {
      let updatedAt = cell;
      return updatedAt;
    },
    searchable: false,
  },
];

export {
  tableColumns,
  statusFilter,
};

import SignIn from "../pages/auth/SignIn";
import Dashboard from "../pages/dashboards/Dashboard";
import PopTextStyles from "../pages/popTextStyles/PopTextStyles.js";
import StyleDetail from "../pages/ui-elements/popTextStyles/StyleDetail.js";
import KeyboardThemeCategories from "../pages/ui-elements/keyboardThemes/ThemeCategories";
import KeyboardThemes from "../pages/ui-elements/keyboardThemes/Themes";
import Languages from "../pages/ui-elements/keyboardLanguages/Languages";
import CreateLanguage from "../pages/ui-elements/keyboardLanguages/CreateLanguage";
import KeyboardLayouts from "../pages/ui-elements/keyboardLanguages/KeyboardLayouts";
import CreateKeyboardLayout from "../pages/ui-elements/keyboardLanguages/CreateKeyboardLayout";
import ABTests from "../pages/ui-elements/keyboardLanguages/ABTests";
import ABTestDetails from "../pages/ui-elements/keyboardLanguages/ABTestDetails";
import ManageResourceVariants from "../pages/ui-elements/keyboardLanguages/ManageResourceVariants";
import CreateABTest from "../pages/ui-elements/keyboardLanguages/CreateABTest";

//--------Carousel------//
import AddCarouselForm from "../pages/ui-elements/carousel/CarouselForm";
import EditCarouselForm from "../pages/ui-elements/carousel/CarouselEditForm";
import Carousel from "../pages/ui-elements/carousel/Carousel";
import ManageCarousel from "../pages/ui-elements/carousel/ManageCarousel";
import AddCarouselItems from "../pages/ui-elements/carousel/AddCarouselItems";
import ManageCarouselItems from "../pages/ui-elements/carousel/ManageCarouselItems";
import CarouselTargetingRules from "../pages/ui-elements/carousel/CarouselTargettingRules";
import CarouselItems from "../pages/ui-elements/carousel/Carouseltems";
import EditCarouselItems from "../pages/ui-elements/carousel/EditCarouselItems";

//----------------//
import QuickReplyCategories from "../pages/ui-elements/quickReplies/QuickReplyCategories";
import QuickReplies from "../pages/ui-elements/quickReplies/QuickReplies";
import QuickReplyDetails from "../pages/ui-elements/quickReplies/QuickReplyDetails";
import ThemeDetail from "../pages/ui-elements/keyboardThemes/ThemeDetails";
import ThemeCategoriesDetails from "../pages/ui-elements/keyboardThemes/ThemeCategoryDetails";
import QuickReplyCategoriesDetails from "../pages/ui-elements/quickReplies/QuickReplyCategoryDetails";
import CreateTheme from "../pages/ui-elements/keyboardThemes/CreateTheme";
import CreateQuickReply from "../pages/ui-elements/quickReplies/CreateQuickReply";
import CreateThemeCategory from "../pages/ui-elements/keyboardThemes/CreateThemeCategory";
import CreateQuickReplyCategory from "../pages/ui-elements/quickReplies/CreateQuickReplyCategory";
import Users from "../pages/users/Users";
import LogoutBtn from "../components/Logout";
import CreateUser from "../pages/users/CreateUser";
import Permissions from "../pages/permissions/Permissions";
import CreatePermission from "../pages/permissions/CreatePermission";
import Roles from "../pages/roles/Roles";
import CreateRole from "../pages/roles/CreateRole";
import ContentPrompts from "../pages/ui-elements/content-update-prompt/ContentPrompts";
import ManageContentUpdatePrompts from "../pages/ui-elements/content-update-prompt/ManageContentUpdatePrompts";
import ContentPromptDetails from "../pages/ui-elements/content-update-prompt/ContentPromptDetails";
import Profile from "../pages/ui-elements/user/Profile";

import StickerPack from "../pages/ui-elements/stickers/StickerPack";
import StickerPackRules from "../pages/ui-elements/stickers/StickerPackRules";
import StickersRules from "../pages/ui-elements/stickers/StickersRules";
import StickerPackAutoDownloadRules from "../pages/ui-elements/stickers/StickerPackAutoDownloadRules";
import AllStickerPacks from "../pages/ui-elements/stickers/AllStickerPacks";
import AllEmojiStickers from "../pages/ui-elements/stickers/AllEmojiStickers";
import AllEmojiStickerImages from "../pages/ui-elements/stickers/AllEmojiStickerImages";
import Stickers from "../pages/ui-elements/stickers/Stickers";
import StickerDetail from "../pages/ui-elements/stickers/StickerDetail";
import StickerEdit from "../pages/ui-elements/stickers/StickerEdit";
import EmojiStickerDetails from "../pages/ui-elements/stickers/EmojiStickerDetails";
import AddEmojiSticker from "../pages/ui-elements/stickers/AddEmoji";
import EmojiStickerImageDetails from "../pages/ui-elements/stickers/EmojiStickerImageDetails";
import CreateEmojiStickerImage from "../pages/ui-elements/stickers/CreateEmojiStickerImage";
import UpdateEmojiStickerImage from "../pages/ui-elements/stickers/UpdateEmojiStickerImage";

import AllGifPacks from "../pages/ui-elements/gifs/AllGifPacks";
import GifPack from "../pages/ui-elements/gifs/GifPack";
import Gifs from "../pages/ui-elements/gifs/Gifs";
import GifDetail from "../pages/ui-elements/gifs/GifDetail";
import GifPackRules from "../pages/ui-elements/gifs/GifPackRules";
import GifsRules from "../pages/ui-elements/gifs/GifsRules";
import GifPackAutoDownloadRules from "../pages/ui-elements/gifs/GifPackAutoDownloadRules";
import GifEdit from "../pages/ui-elements/gifs/GifEdit";

//--------kbl-----//
import KeyboardLanguages from "../pages/ui-elements/keyboardLanguages/KeyboardLanguages";
import ManageKeyboardLanguage from "../pages/ui-elements/keyboardLanguages/ManageKeyboardLanguage";
import LanguageDetail from "../pages/ui-elements/keyboardLanguages/LanguageDetail";
import ManageLayouts from "../pages/ui-elements/keyboardLanguages/ManageLayouts";

//-------Rewards Dashboard-------//
import Rewards from "../pages/ui-elements/rewards/rewards";
import RewardForm from "../pages/ui-elements/rewards/RewardForm";
import EditRewardForm from "../pages/ui-elements/rewards/EditRewardForm"

//--CRE Dashboard--//
import ContentCategories from "../pages/ui-elements/creDashboard/ContentCategories";
import CreateContentCategory from "../pages/ui-elements/creDashboard/CreateContentCategory";
import ContentCategoriesDetails from "../pages/ui-elements/creDashboard/ContentCategoryDetails";
import ContentCategoriesRules from "../pages/ui-elements/creDashboard/ContentCategoryRules";
import ContentCategoryUserAttributeRules from "../pages/ui-elements/creDashboard/ContentCategoryUserAttributeRules.js";
import PromotedTags from "../pages/ui-elements/creDashboard/PromotedTags";
import ContentSearch from "../pages/ui-elements/creDashboard/ContentSearch";
import CreatePromotedTag from "../pages/ui-elements/creDashboard/CreatePromotedTag";
import PromotedTagsDetails from "../pages/ui-elements/creDashboard/PromotedTagsDetails";
import PromotedTagsRules from "../pages/ui-elements/creDashboard/PromotedTagsRules";

import PackageSets from "../pages/ui-elements/keyboard-prompt/PackageSets/PackageSets";
import KeyboardPrompts from "../pages/ui-elements/keyboard-prompt/Prompts/KeyboardPrompts";
import ManageKeyboardPrompts from "../pages/ui-elements/keyboard-prompt/Prompts/ManageKeyboardPrompts";
import ManageKeyboardPromptOrder from "../pages/ui-elements/keyboard-prompt/Prompts/ManageKeyboardPromptsOrder.js";
import KeyboardPromptsTargetingRules from "../pages/ui-elements/keyboard-prompt/Prompts/KeyboardPromptsTargetingRules";
import EditPromptsRulesetForm from "../pages/ui-elements/keyboard-prompt/Prompts/EditPromptsRulesetForm.js";
import PromptsRulesetForm from "../pages/ui-elements/keyboard-prompt/Prompts/PromptsRulesetForm.js";
import SegmentPromptsOrder from "../pages/ui-elements/keyboard-prompt/Prompts/SegmentPromptsOrder.js";

import AppResourceTable from "../pages/ui-elements/app-resource/AppResourceTable";

//--Ad Banner--//
import Adbanners from "../pages/ui-elements/quickReplies/AdBanners";
import CreateAdBanner from "../pages/ui-elements/quickReplies/CreateAdBanner";
import AdBannerDetails from "../pages/ui-elements/quickReplies/AdBannerDetails";
import AdBannerTargetingRules from "../pages/ui-elements/quickReplies/AdBannerTargetingRules";

//--Quick Reply--//
import CreateQuickReplyPage from "../pages/ui-elements/quickReplies/CreateQuickReplyPage";
import QuickRepyTargettingRules from "../pages/ui-elements/quickReplies/QuickRepyTargettingRules";
import ReorderQuickReplies from "../pages/ui-elements/quickReplies/ReorderQuickReplies";
import ManageSuggestionDrawerCategory from "../pages/ui-elements/sugestionDrawerDashboard/ManageCategory.js";
import SuggestionDrawerCategoryTargetingRules from "../pages/ui-elements/sugestionDrawerDashboard/CategoryTaretingRules.js";
import SuggestionDrawerContentTargetingRules from "../pages/ui-elements/sugestionDrawerDashboard/ContentTargetingRules.js";
import BulkContentUserAttributeRules from "../pages/ui-elements/sugestionDrawerDashboard/BulkContentUserAttributeRule.js";
import SuggestionDrawerContentsTargetingRules from "../pages/ui-elements/sugestionDrawerDashboard/ContentsBulkTargetingRules.js";
import CategoryContentOrder from "../pages/ui-elements/sugestionDrawerDashboard/CategoryContentOrder.js";
import CategoryOrder from "../pages/ui-elements/sugestionDrawerDashboard/CategoryOrder.js";
import SegmentCategoryOrder from "../pages/ui-elements/sugestionDrawerDashboard/SegmentCategoryOrder.js";
import CategoryRulesetForm from "../pages/ui-elements/sugestionDrawerDashboard/CategoryRulesetForm.js";
import EditCategoryRulesetForm from "../pages/ui-elements/sugestionDrawerDashboard/EditCategoryRulesetForm.js";
import QuickReplyCTAAssetsBulkUpdate from "../pages/ui-elements/quickReplies/QuickReplyCTAAssetsBulkUpdate";
import StoriesBulkCreate from "../pages/ui-elements/storiesBulkUpload/CreateStoriesBulkUpload.js";
import StoriesEdit from "../pages/ui-elements/storiesDashboard/editStories.js";
import StoryTargetingRules from "../pages/ui-elements/storiesDashboard/TargetingRules.js";
import CTAItemsPage from "../pages/ui-elements/storiesDashboard/editCta.js";

import { permissions as ApiPermissions } from "../utilities/commonUtil";

import {
  Sliders as SlidersIcon,
  Users as UsersIcon,
  Users as UserIcon,
  Shield as ShieldIcon,
  Italic as ItalicIcon,
  Briefcase as Briefcase,
  Target as TargetIcon,
  Globe as GlobeIcon,
  Map as MapIcon,
  LogOut as KeyIcon,
  Smile as SmileIcon,
  Bell as BellIcon,
  Film as FilmIcon,
  CornerDownLeft as ReplyIcon, Link2, Gift
} from "react-feather";
import { BsAppIndicator, BsFileEarmarkText } from "react-icons/bs";
import { SiConvertio } from "react-icons/si";
import { MdOutlineCampaign } from "react-icons/md";
import { GiArtificialIntelligence, GiChatBubble } from "react-icons/gi";
import { FiTrendingUp } from "react-icons/fi";
import { FaDatabase } from "react-icons/fa";
import CreatePopTextStyle from "../pages/ui-elements/popTextStyles/CreatePopTextStyle";
import KeyboardFonts from "../pages/ui-elements/keyboardFonts/KeyboardFonts";
import KeyboardFontsReorder from "../pages/ui-elements/keyboardFonts/KeyboardFontsReorder";
import ManageKeyboardFont from "../pages/ui-elements/keyboardFonts/ManageKeyboardFont";
import Deeplinks from "../pages/ui-elements/deeplinks/deeplinks";
import SmartSuggestions from "../pages/placements/SmartSuggestions";
import AdsConflictDashboard from "../pages/placements/AdsConflictDashboard";
import ManageSmartSuggestions from "../pages/ui-elements/smart-suggestions/ManageSmartSuggestions";
import SmartSuggestionsOrder from "../pages/ui-elements/smart-suggestions/SmartSuggestionsOrder";
import SmartSuggestionsPreferredCategories from "../pages/ui-elements/smart-suggestions/SmartSuggestionsPreferredCategories";
import SmartSuggestionsTargetingRules from "../pages/ui-elements/smart-suggestions/SmartSuggestionsTargetingRules";
import ConfigAPIDashboard from "../pages/placements/ConfigAPIDashboard";
import ManageCreativeTypes from "../pages/ui-elements/configAPIDashboard/ManageCreativeTypes";
import ManageVendors from "../pages/ui-elements/configAPIDashboard/ManageVendors";
import ManagePlacements from "../pages/ui-elements/configAPIDashboard/ManagePlacements";
import ManagePlacementVendors from "../pages/ui-elements/configAPIDashboard/ManagePlacementVendor";
import ManageMixingStrategy from "../pages/ui-elements/configAPIDashboard/ManageMixingStrategy";
import RevenueDashboard from "../pages/ads/RevenueDashboard";
import PlacementProviders from "../pages/placementProviders/PlacementProvidersDashboard";
import MovieGifs from "../pages/ui-elements/gifs/MovieGifs.js";
import MovieGifsDetail from "../pages/ui-elements/gifs/MovieGifDetail.js";
import SmartSuggestionsMetadataOverride from "../pages/ui-elements/smart-suggestions/SmartSuggestionsMetadataOverride.js";
import DashboardSessionLogs from "../pages/ui-elements/dashboardLogs/DashboardSessionLogs.js";
import AutoCappingDashboard from "../pages/autoCapping/AutoCappingDashboard.js";
import AdsCategories from "../pages/ui-elements/adsCategories/AdsCategories.js";
import AdsEnvConfigsDashboard from "../pages/adsEnvConfigs/AdsEnvConfigsDashboard.js";

//-----Taas Dashboard------//
import Clients from "../pages/ui-elements/taasDashboard/Clients.js";
import ManageClient from "../pages/ui-elements/taasDashboard/ManageClient.js";
import ManageSticker from "../pages/ui-elements/taasDashboard/ManageSticker.js";
import ManageStory from "../pages/ui-elements/taasDashboard/ManageStory.js";
import StickersList from "../pages/ui-elements/taasDashboard/Stickers.js";
import Stories from "../pages/ui-elements/taasDashboard/Stories.js";
import Tasks from "../pages/ui-elements/task/tasks.js";
import TaskForm from "../pages/ui-elements/task/TaskForm.js";
import EditTaskForm from "../pages/ui-elements/task/EditTaskForm.js";
import AddTaskEventForm from "../pages/ui-elements/task/AddTaskEvents.js";
import TaskEvents from "../pages/ui-elements/task/TaskEvents.js";
import EditTaskEvent from "../pages/ui-elements/task/EditTaskEvents.js";

//------Task Dashboard------//

import ManagePartner from "../pages/ui-elements/taasDashboard/ManagePartner.js";
import Category from "../pages/ui-elements/sugestionDrawerDashboard/Category.js";
import SDSegments from "../pages/ui-elements/segmentsDashboard/Segments.js";
import Content from "../pages/ui-elements/sugestionDrawerDashboard/Content.js";
import ManageSuggestionDrawerContent from "../pages/ui-elements/sugestionDrawerDashboard/ManageContent.js";
//-----Gen AI Dashboard------//
import PromptTemplates from "../pages/ui-elements/genAIDashboard/PromptTemplates.js";
import PromptTemplateDetails from "../pages/ui-elements/genAIDashboard/PromptTemplateDetails.js";
import CreatePromptTemplate from "../pages/ui-elements/genAIDashboard/CreatePromptTemplate.js";

//-----------//
import MixingStrategyRatios from "../pages/mixingStrategyRatios/MixingStrategyRatiosDashboard.js";
import PlacementLocations from "../pages/placementLocations/PlacementLocationsDashboard.js";
import ClientStatistics from "../pages/ui-elements/taasDashboard/ClientStatistics.js";
import PopTextStickers from "../pages/ui-elements/taasDashboard/PopTextStickers.js";
import ManagePopTextSticker from "../pages/ui-elements/taasDashboard/ManagePopTextSticker.js";
import DashboardLogs from "../pages/ui-elements/dashboardLogs/DashboardLogs.js";
import MovieGifsEdit from "../pages/ui-elements/gifs/MovieGifEdit.js";

//-----Stories------//
import StoriesListing from "../pages/ui-elements/storiesDashboard/Listing.js";
import StoriesSotd from "../pages/ui-elements/storiesDashboard/SotdListing.js";
import UserStoriesListing from "../pages/ui-elements/storiesDashboard/UserStoryListing.js";
import TemplateStatsListing from "../pages/ui-elements/storiesDashboard/TemplateStatsListing.js";
import CreateSotd from "../pages/ui-elements/storiesDashboard/CreateSotd.js";
import CreateBucket from "../pages/ui-elements/storiesDashboard/CreateBucket.js";
import BucketListing from "../pages/ui-elements/storiesDashboard/BucketListing.js";
import StoriesRules from "../pages/ui-elements/storiesDashboard/BulkUpdateTargetingRules.js";
import SotdTargetingRules from "../pages/ui-elements/sotd/sotdTargetingRules.js";
import UserAttributeRules from "../pages/ui-elements/storiesDashboard/UserAttributeRules.js";
import LottieAnimation from "../pages/ui-elements/stickers/LottieAnimation.js";
import ManageLottieAnimation from "../pages/ui-elements/stickers/ManageLottieAnimation.js";
import ContentCategoryCarouselUserAttributeRules from "../pages/ui-elements/creDashboard/ContentCategoryCarouselUserAttributeRule.js";
import ContentUserAttributeRules from "../pages/ui-elements/sugestionDrawerDashboard/ContentUserAttributeRule.js";
import CategoryUserAttributeRules from "../pages/ui-elements/sugestionDrawerDashboard/CategoryUserAttributeRule.js";
import KeyboardPromptsUserAttributeRules from "../pages/ui-elements/keyboard-prompt/Prompts/KeyboardPromptsUserAttributeRules.js";
import ThemeCategoriesUserAttributeRules from "../pages/ui-elements/keyboardThemes/ThemeCategoriesUserAttributeRules.js";

//Gen AI Config Dashboard
import Placements from "../pages/ui-elements/genAIDashboard/Placements.js";
import PlacementDetails from "../pages/ui-elements/genAIDashboard/PlacementDetails.js";
import PlacementTasks from "../pages/ui-elements/genAIDashboard/Tasks.js";
import TaskDetails from "../pages/ui-elements/genAIDashboard/TaskDetails.js";
import CreatePlacement from "../pages/ui-elements/genAIDashboard/CreatePlacement.js";
import CreateButton from "../pages/ui-elements/genAIDashboard/CreateButton.js";
import CreateTask from "../pages/ui-elements/genAIDashboard/CreateTask.js";
import CreateDropdownOption from "../pages/ui-elements/genAIDashboard/CreateDropdownOption.js";

//Push Notifications Dashboard
import { MdNotificationsActive } from "react-icons/md";
import PushNotificationsTable from "../pages/ui-elements/notifications/push-notifications/PushNotificationsTable";
import PushNotificationExecution from "../pages/ui-elements/notifications/push-notifications/PushNotificationExecution";
import TestPushNotification from "../pages/ui-elements/notifications/push-notifications/TestPushNotification";
import ManagePushNotification from "../pages/ui-elements/notifications/push-notifications/ManagePushNotification.js";
import PushNotificationTargetingRules from "../pages/ui-elements/notifications/push-notifications/PushNotificationTargetingRules.js";
import UserSegments from "../pages/ui-elements/notifications/user-segments/UserSegmentTable.js";
import ManageUserSegment from "../pages/ui-elements/notifications/user-segments/ManageUserSegment.js";

// Chat Assistant Dashboard
import SystemPrompts from "../pages/ui-elements/chatAssistantDashboard/SystemPrompts.js";
import ManageSystemPrompt from "../pages/ui-elements/chatAssistantDashboard/ManageSystemPrompt.js";

const dashboard = {
  path: "/dashboard",
  name: "Dashboards",
  header: "Pages",
  icon: SlidersIcon,
  containsHome: true,
  component: Dashboard,
};

const login = {
  path: "/",
  name: "Auth",
  icon: UsersIcon,
  component: SignIn,
};

const userProfile = {
  path: "/user/profile",
  name: "User Profile",
  component: Profile,
};

const popTextStyles = {
  path: "/pop-text-styles",
  name: "Pop Text Styles",
  icon: ItalicIcon,
  children: {
    viewPopTextStyles: {
      path: "/pop-text-styles",
      name: "List Pop Text Styles",
      permission: ApiPermissions.popTextStyleList,
      component: PopTextStyles,
    },
  },

  //
  // },
};

const keyboardThemes = {
  path: "/keyboard-theme",
  name: "Keyboard Themes",
  icon: TargetIcon,
  children: {
    themeCategories: {
      path: "/keyboard-theme",
      name: "Themes",
      permission: ApiPermissions.keyboardThemeList,
      component: KeyboardThemes,
    },
    keyboardThemeCategories: {
      path: "/keyboard-theme/keyboard-theme-categories",
      name: "Theme Categories",
      permission: ApiPermissions.keyboardThemeCategoryList,
      component: KeyboardThemeCategories,
    },
  },
};

const brandCampaign = {
  path: "/brand-campaign",
  name: "Brand Campaign",
  icon: FiTrendingUp,
  children: {
    autoCapping: {
      path: "/brand-campaign/auto-capping",
      name: "Auto Capping",
      permission: ApiPermissions.autoCappingList,
      component: AutoCappingDashboard,
    },
  },
};

const keyboardPrompt = {
  path: "/keyboard-prompt",
  name: "Keyboard Prompt",
  icon: BsAppIndicator,
  children: {
    packageSets: {
      path: "/keyboard-prompt/package-sets",
      name: "Package Sets",
      permission: ApiPermissions.packageSetList,
      component: PackageSets,
    },
    keyboardPrompts: {
      path: "/keyboard-prompt/prompts",
      name: "Keyboard Prompts",
      permission: ApiPermissions.keyboardPromptList,
      component: KeyboardPrompts,
    },
  },
};

const ads = {
  path: "/ads",
  name: "Ads",
  icon: SiConvertio,
  children: {
    categories: {
      path: "/ads/categories",
      name: "Categories",
      permission: ApiPermissions.adsCategoriesDashboard,
      component: AdsCategories,
    },
    placements: {
      path: "/ads/placements",
      name: "Placements",
      permission: ApiPermissions.mediationSdkConfigDashboard,
      component: ManagePlacements,
    },
    placementLocations: {
      path: "/ads/placementLocations",
      name: "Placement Locations",
      permission: ApiPermissions.placementLocationsList,
      component: PlacementLocations,
    },
    placementProviders: {
      path: "/ads/placement-providers",
      name: "Placement Providers",
      permission: ApiPermissions.placementProvidersAll,
      component: PlacementProviders,
    },
    mixingStrategyRatios: {
      path: "/ads/mixing-strategy-ratios",
      name: "Mixing Strategy Ratios",
      permission: ApiPermissions.mixingStrategyRatiosList,
      component: MixingStrategyRatios,
    },
    smartSuggestions: {
      path: "/ads/smart-suggestions",
      name: "Smart Suggestions",
      permission: ApiPermissions.smartSuggestionsDashboard,
      component: SmartSuggestions,
    },
    adsConflictDashboard: {
      path: "/ads/conflict-management",
      name: "Conflict Management",
      permission: ApiPermissions.adsConflictDashboard,
      component: AdsConflictDashboard
    },
    configAPIDashboard: {
      path: "/ads/mediation-sdk",
      name: "Mediation SDK Configuration",
      permission: ApiPermissions.mediationSdkConfigDashboard,
      component: ConfigAPIDashboard,
    },
    revenueDashboard: {
      path: "/ads/revenue",
      name: "Revenue",
      permission: ApiPermissions.adsRevenueDashboard,
      component: RevenueDashboard,
    },
    envConfigsDashboard: {
      path: "/ads/env-configs",
      name: "Env Configs",
      permission: ApiPermissions.adsEnvConfigList,
      component: AdsEnvConfigsDashboard,
    },
  },
};

const quickReplies = {
  path: "/quick-reply",
  name: "Quick Replies",
  icon: ReplyIcon,
  children: {
    quickReplies: {
      path: "/quick-reply",
      name: "Quick Replies",
      permission: ApiPermissions.quickReplyList,
      component: QuickReplies,
    },
    quickReplyCategories: {
      path: "/quick-reply/quick-reply-categories",
      name: "Quick Reply Categories",
      permission: ApiPermissions.quickReplyCategoryList,
      component: QuickReplyCategories,
    },
    reorderQuickReplies: {
      path: "/quick-reply/reorder-share-deals",
      name: "Reorder Quick Replies",
      permission: ApiPermissions.quickReplyReorder,
      component: ReorderQuickReplies,
    },
    quickReplyCTAAssetsBulkUpdate: {
      path: "/quick-reply/cta-assets/bulk-update",
      name: "CTA Assets Bulk Update",
      permission: ApiPermissions.quickReplyCreate,
      component: QuickReplyCTAAssetsBulkUpdate,
    },
    adBanners: {
      path: "/quick-reply/ad-banners",
      name: "Ad Banners",
      permission: ApiPermissions.quickReplyAdbannerList,
      component: Adbanners,
    },
  },
};

const users = {
  path: "/users",
  name: "Users",
  icon: UserIcon,
  children: {
    viewUsers: {
      path: "/users/all-users",
      name: "View Users",
      permission: ApiPermissions.listUser,
      component: Users,
    },
    createUser: {
      path: "/users/add-user",
      name: "Create User",
      permission: ApiPermissions.createUser,
      component: CreateUser,
    },
  },
};

const permissions = {
  path: "/permissions",
  name: "Permissions",
  icon: GlobeIcon,
  children: {
    viewPermissions: {
      path: "/permissions/all-permissions",
      name: "View Permissions",
      permission: ApiPermissions.listPermission,
      component: Permissions,
    },
    createPermission: {
      path: "/permissions/add-permission",
      name: "Create Permission",
      permission: ApiPermissions.createPermission,
      component: CreatePermission,
    },
  },
};

const roles = {
  path: "/roles",
  name: "Roles",
  icon: MapIcon,
  children: {
    viewRoles: {
      path: "/roles/all-roles",
      name: "View Roles",
      permission: ApiPermissions.listRole,
      component: Roles,
    },
    createRole: {
      path: "/roles/add-role",
      name: "Create Role",
      permission: ApiPermissions.createRole,
      component: CreateRole,
    },
  },
};

const keyboardLanguages = {
  path: "/kl",
  name: "Keyboard Languages",
  icon: ItalicIcon,
  children: {
    keyboardFonts: {
      path: "/kl/keyboard-fonts",
      name: "View Keyboard Fonts",
      permission: ApiPermissions.keyboardFontList,
      component: KeyboardFonts,
    },
    keyboardLanguages: {
      path: "/kl/keyboard-languages",
      name: "View Keyboard Languages",
      permission: ApiPermissions.keyboardLanguageList,
      component: KeyboardLanguages,
    },
    language: {
      path: "/kl/languages",
      name: "View Languages",
      permission: ApiPermissions.languageList,
      component: Languages,
    },
    keyboardLayouts: {
      path: "/kl/keyboard-layouts",
      name: "View Keyboard Layouts",
      permission: ApiPermissions.keyboardLayoutList,
      component: KeyboardLayouts,
    },
    abTests: {
      path: "/kl/abtests",
      name: "View AB Tests",
      permission: ApiPermissions.keyboardLanguageABTestList,
      component: ABTests,
    },
  },
};

const deepLinks = {
  path: "/deeplinks",
  name: "Deeplinks",
  icon: Link2,
  children: {
    viewDeeplinks: {
      path: "/deeplinks",
      name: "Deeplinks",
      permission: ApiPermissions.deeplinkList,
      component: Deeplinks,
    },
  },
};

const taasDashboard = {
  path: "/taas",
  name: "TaaS",
  icon: MdOutlineCampaign,
  children: {
    clients: {
      path: "/taas/clients",
      name: "Clients",
      permission: ApiPermissions.taasClientList,
      component: Clients,
    },
    stickers: {
      path: "/taas/stickers",
      name: "Stickers",
      permission: ApiPermissions.taasStickerList,
      component: StickersList,
    },
    stories: {
      path: "/taas/stories",
      name: "Stories",
      permission: ApiPermissions.taasStoryList,
      component: Stories,
    },
    popTextStickers: {
      path: "/taas/poptext-stickers",
      name: "PopText Stickers",
      permission: ApiPermissions.taasStickerList,
      component: PopTextStickers,
    }
  },
};

const genAIDashboard = {
  path: "/gen-ai",
  name: "Gen AI",
  icon: GiArtificialIntelligence,
  children: {
    promptTemplates: {
      path: "/gen-ai/prompt-templates",
      name: "Prompt Templates",
      permission: ApiPermissions.AIPromptTemplateList,
      component: PromptTemplates,
    },
    placements: {
      path: "/gen-ai/placements",
      name: "Placements",
      permission: ApiPermissions.AIPoweredBarPlacementList,
      component: Placements,
    },
    tasks: {
      path: "/gen-ai/tasks",
      name: "Tasks",
      permission: ApiPermissions.AIPoweredBarTaskList,
      component: PlacementTasks,
    }
  },
};

const chatAssistantDashboard = {
  path: "/chat-assistant",
  name: "Chat Assistant",
  icon: GiChatBubble,
  children: {
    systemPrompts: {
      path: "/chat-assistant/system-prompts",
      name: "System Prompts",
      permission: ApiPermissions.chatAssistantSystemPromptList,
      component: SystemPrompts,
    },
  },
};

const suggestionDrawerDashboard = {
  path: "/suggestionDrawer",
  name: "Suggestion Drawer",
  icon: ItalicIcon,
  children: {
    contents: {
      path: "/suggestionDrawer/contents",
      name: "Content",
      permission: ApiPermissions.suggestionDrawerContentList,
      component: Content,
    },
    category: {
      path: "/suggestionDrawer/category",
      name: "Category",
      permission: ApiPermissions.suggestionDrawerCategoryList,
      component: Category,
    }
  }
}

const segmentsDashboard = {
  path: "/segmentsDashboard",
  name: "Segments",
  icon: Briefcase,
  children: {
    segments: {
      path: "/segment/segments",
      name: "Segments",
      permission: ApiPermissions.SegmentsList,
      component: SDSegments,
    }
  }
}

const stories = {
  path: "/stories",
  name: "Stories",
  icon: ItalicIcon,
  children: {
    Stories: {
      path: "/stories/stories",
      name: "Stories",
      permission: ApiPermissions.storyListPermission,
      component: StoriesListing,
    },
    UserStories: {
      path: "/stories/userStories",
      name: "User Stories",
      permission: ApiPermissions.userStoryListPermission,
      component: UserStoriesListing,
    },
    Stats: {
      path: "/stories/individualAiStoryStats",
      name: "AI Story Stats",
      permission: ApiPermissions.userStoryListPermission,
      component: TemplateStatsListing,
    },
    Sotd: {
      path: "/stories/sotd",
      name: "SOTD",
      permission: ApiPermissions.sotdListPermission,
      component: StoriesSotd,
    },
    Buckets: {
      path: "/stories/buckets",
      name: "Buckets",
      permission: ApiPermissions.storyBucketListPermission,
      component: BucketListing,
    },
    StoriesBulkCreate: {
      path: "/stories/bulkUpload",
      name: "Bulk Upload",
      permission: ApiPermissions.storiesBulkCreatePermission,
      component: StoriesBulkCreate,
    },
  },
};


const sessions = {
  path: "/logout",
  name: "Session",
  icon: KeyIcon,
  children: {
    logout: {
      path: "/logout",
      name: "Logout",
      permission: ApiPermissions.createRole,
      component: LogoutBtn,
    },
  },
};

const stickers = {
  path: "/stickers",
  name: "Stickers",
  icon: SmileIcon,
  children: {
    stickerPack: {
      path: "/stickers/sticker-pack",
      name: "Sticker Packs",
      permission: ApiPermissions.stickerPackList,
      component: AllStickerPacks,
    },
    stickers: {
      path: "/stickers/stickers",
      name: "Stickers",
      permission: ApiPermissions.stickerList,
      component: Stickers,
    },
    emojiStickers: {
      path: "/stickers/emoji-stickers",
      name: "Bigmojis",
      permission: ApiPermissions.listEmojiStickers,
      component: AllEmojiStickers,
    },
    emojiStickerImages: {
      path: "/stickers/emoji-sticker-images",
      name: "Emoji Sticker Images",
      permission: ApiPermissions.emojiStickerImageDetails,
      component: AllEmojiStickerImages,
    },
    lottieAnimations: {
      path: "/stickers/lottie-animations",
      name: "Lottie Animations",
      permission: ApiPermissions.EmojiStickerAnimationList,
      component: LottieAnimation,
    },
  },
};

const moviesGifs = {
  path: "/movie-gifs",
  name: "Movie Gifs",
  permission: ApiPermissions.movieGifsList,
  component: MovieGifs,
  icon: FilmIcon,
};

const gifs = {
  path: "/gifs",
  name: "Gifs",
  icon: SmileIcon,
  children: {
    gifsPack: {
      path: "/gifs/gifs-pack",
      name: "Gif Packs",
      permission: ApiPermissions.gifPackList,
      component: AllGifPacks,
    },
    gifs: {
      path: "/gifs/gifs",
      name: "Gifs",
      permission: ApiPermissions.gifList,
      component: Gifs,
    },
  },
};

const creDashboard = {
  path: "/cre",
  name: "Content Recommendation",
  icon: TargetIcon,
  children: {
    contentCategories: {
      path: "/cre/content-categories",
      name: "Content Categories",
      permission: ApiPermissions.contentCategoryList,
      component: ContentCategories,
    },
    promotedTags: {
      path: "/cre/promoted-tags",
      name: "Content Promoted Tags",
      permission: ApiPermissions.contentPromotedTagList,
      component: PromotedTags,
    },
    contentSearch: {
      path: "/cre/content-search",
      name: "Content Search",
      permission: ApiPermissions.contentList,
      component: ContentSearch,
    },
    viewCarousel: {
      path: "/cre/content-carousels",
      name: "Content Category Carousels",
      permission: ApiPermissions.contentCategoryCarouselList,
      component: Carousel,
    },
  },
};

const appResource = {
  path: "/app-resource",
  name: "App Resource",
  icon: BsFileEarmarkText,
  children: {
    appResource: {
      path: "/app-resource",
      name: "View App Resource",
      permission: ApiPermissions.appResourceList,
      component: AppResourceTable,
    },
  },
};

const commonRoutes = {
  children: {
    sticker: {
      path: "/stickers/sticker-pack/:id",
      name: "Sticker Pack",
      permission: ApiPermissions.stickerPackDetail,
      component: StickerPack,
    },
    gif: {
      path: "/gifs/gif-pack/:id",
      name: "Gif Pack",
      permission: ApiPermissions.gifPackDetail,
      component: GifPack,
    },
    stickerPackRules: {
      path: "/stickers/sticker-pack/:id/targetingRules",
      name: "Sticker Pack Rules",
      permission: ApiPermissions.stickerPackDetail,
      component: StickerPackRules,
    },
    stickersRules: {
      path: "/stickers/targetingRules",
      name: "Stickers Targeting Rules",
      permission: ApiPermissions.stickerDetail,
      component: StickersRules,
    },
    autoDownloadRules: {
      path: "/stickers/sticker-pack/:id/autoDownloadRules",
      name: "Sticker Pack Auto Download Rules",
      permission: ApiPermissions.stickerPackDetail,
      component: StickerPackAutoDownloadRules,
    },
    gifPackRules: {
      path: "/gifs/gif-pack/:id/targetingRules",
      name: "Gif Pack Rules",
      permission: ApiPermissions.gifPackDetail,
      component: GifPackRules,
    },
    gifsRules: {
      path: "/gifs/targetingRules",
      name: "Gifs Targeting Rules",
      permission: ApiPermissions.gifDetail,
      component: GifsRules,
    },
    gifAutoDownloadRules: {
      path: "/gifs/gif-pack/:id/autoDownloadRules",
      name: "Gif Pack Auto Download Rules",
      permission: ApiPermissions.gifPackDetail,
      component: GifPackAutoDownloadRules,
    },
    createPopText: {
      path: "/pop-text-styles/create",
      name: "Create Pop Text Style",
      permission: ApiPermissions.popTextStyleCreate,
      component: CreatePopTextStyle,
    },
    popTextStyleDetails: {
      path: "/pop-text-styles/:id",
      name: "Pop Text Style",
      permission: ApiPermissions.popTextStyleDetail,
      component: StyleDetail,
    },
    createKeyboardTheme: {
      path: "/keyboard-theme/create-keyboard-theme",
      name: "Create Keyboard Theme",
      permission: ApiPermissions.keyboardThemeCreate,
      component: CreateTheme,
    },
    createKeyboardThemeCategory: {
      path: "/keyboard-theme/create-keyboard-theme-category",
      name: "Create Theme Category",
      permission: ApiPermissions.keyboardThemeCategoryCreate,
      component: CreateThemeCategory,
    },
    themeDetail: {
      path: "/keyboard-theme/:id",
      name: "Theme Detail",
      permission: ApiPermissions.keyboardThemeDetail,
      component: ThemeDetail,
    },
    themeCategoryDetails: {
      path: "/keyboard-theme/keyboard-theme-categories/:categoryID",
      name: "Keyboard Themes Categories Details",
      permission: ApiPermissions.keyboardThemeCategoryDetail,
      component: ThemeCategoriesDetails,
    },
    themeCategoryUserAttributeRules: {
      path: "/keyboard-theme/keyboard-theme-categories/user-attribute-rules/:id",
      name: "Keyboard Themes Categories User Attribute Rules",
      permission: ApiPermissions.keyboardThemeCategoryRuleView,
      component: ThemeCategoriesUserAttributeRules,
    },
    lottieAnimationAdd: {
      path: "/stickers/lottie-animations/add",
      name: "Add Lottie Animation",
      permission: ApiPermissions.EmojiStickerAnimationCreate,
      component: ManageLottieAnimation,
    },
    EmojiStickerAnimationEdit: {
      path: "/stickers/lottie-animations/edit/:id",
      name: "Edit Lottie Animation",
      permission: ApiPermissions.EmojiStickerAnimationEdit,
      component: ManageLottieAnimation,
    },
    EmojiStickerAnimationView: {
      path: "/stickers/lottie-animations/view/:id",
      name: "View Lottie Animation",
      permission: ApiPermissions.EmojiStickerAnimationView,
      component: ManageLottieAnimation,
    },

    //-------keyboard language routes ----//
    createLanguage: {
      path: "/kl/create-language",
      name: "Create Language",
      permission: ApiPermissions.languageCreate,
      component: CreateLanguage,
    },
    createKeyboardLayout: {
      path: "/kl/create-keyboard-layout",
      name: "Create Keyboard Layout",
      permission: ApiPermissions.keyboardLayoutCreate,
      component: CreateKeyboardLayout,
    },
    createABTests: {
      path: "/kl/abtests/create",
      name: "Create AB Test",
      permission: ApiPermissions.keyboardLanguageABTestCreate,
      component: CreateABTest,
    },
    addResourceVariants: {
      path: "/kl/abtests/:id/variants/add",
      name: "Add Resource Variants",
      permission: ApiPermissions.keyboardLanguageABTestVariantCreate,
      component: ManageResourceVariants,
    },
    editResourceVariants: {
      path: "/kl/abtests/:id/variants/edit",
      name: "Edit Resource Variants",
      permission: ApiPermissions.keyboardLanguageABTestVariantEdit,
      component: ManageResourceVariants,
    },

    abTestDetails: {
      path: "/kl/abtests/:id",
      name: "AB Test Details",
      permission: ApiPermissions.keyboardLanguageABTestDetail,
      component: ABTestDetails,
    },

    //-------------------------------------------//
    createQuickReply: {
      path: "/quick-reply/create-quick-reply-bulk",
      name: "Create Quick Reply Bulk",
      permission: ApiPermissions.quickReplyCreate,
      component: CreateQuickReply,
    },
    createQuickReplyPage: {
      path: "/quick-reply/create-quick-reply",
      name: "Create Quick Reply",
      permission: ApiPermissions.quickReplyCreate,
      component: CreateQuickReplyPage,
    },
    reorderQuickReplies: {
      path: "/quick-reply/reorder-share-deals",
      name: "Reorder Quick Replies",
      permission: ApiPermissions.quickReplyReorder,
      component: ReorderQuickReplies,
    },
    quickRepyTargettingRules: {
      path: "/quick-reply/targeting-rules/:id",
      name: "Quick Reply Targeting Rules",
      permission: ApiPermissions.quickReplyRulesView,
      component: QuickRepyTargettingRules,
    },
    quickReplyDetail: {
      path: "/quick-replies/:id",
      name: "Quick Reply Detail",
      permission: ApiPermissions.quickReplyDetail,
      component: QuickReplyDetails,
    },
    createQuickReplyCategory: {
      path: "/quick-reply/create-quick-reply-category",
      name: "Create Quick Reply Category",
      permission: ApiPermissions.quickReplyCategoryCreate,
      component: CreateQuickReplyCategory,
    },
    quickReplyCategoryDetails: {
      path: "/quick-reply/quick-reply-categories/:categoryID",
      name: "quick replies Categories Details",
      permission: ApiPermissions.quickReplyCategoryDetail,
      component: QuickReplyCategoriesDetails,
    },
    adBanners: {
      path: "/quick-reply/ad-banners",
      name: "Ad Banners",
      permission: ApiPermissions.quickReplyAdbannerList,
      component: Adbanners,
    },
    createAdBanner: {
      path: "/quick-reply/create-adBanner",
      name: "Create Ad Banner",
      permission: ApiPermissions.quickReplyAdbannerCreate,
      component: CreateAdBanner,
    },
    adBannerDetails: {
      path: "/quick-reply/ad-banners/:id",
      name: "Ad Banner Details",
      permission: ApiPermissions.quickReplyAdbannerDetails,
      component: AdBannerDetails,
    },
    adBannerTargetingRules: {
      path: "/quick-reply/ad-banners/targeting-rules/:id",
      name: "Ad Banner Targeting Rules",
      permission: ApiPermissions.quickReplyAdbannerRulesView,
      component: AdBannerTargetingRules,
    },
    addEmojiSticker: {
      path: "/stickers/emoji-stickers/add",
      name: "Create Emoji Sticker",
      permission: ApiPermissions.addEmojiStickers,
      component: AddEmojiSticker,
    },
    emojiStickerDetails: {
      path: "/stickers/emoji-stickers/:id",
      name: "Emoji Sticker Image Details",
      permission: ApiPermissions.listEmojiStickers,
      component: EmojiStickerDetails,
    },
    emojiStickerImageDetails: {
      path: "/sticker/emoji-stickers/:emojiID/image/:emojiImageID",
      name: "Emoji Image Details",
      permission: ApiPermissions.emojiStickerImageDetails,
      component: EmojiStickerImageDetails,
    },
    createEmojiStickerImage: {
      path: "/sticker/emoji-stickers/:emojiID/addImage",
      name: "Add emojiImage",
      permission: ApiPermissions.uploadEmojiStickerImage,
      component: CreateEmojiStickerImage,
    },
    updateEmojiStickerImage: {
      path: "/sticker/emoji-stickers/:emojiID/image/:emojiImageID/update",
      name: "Update emojiImage",
      permission: ApiPermissions.updateEmojiStickerImages,
      component: UpdateEmojiStickerImage,
    },
    keyboardFontsReorder: {
      path: "/keyboard-fonts/reorder",
      name: "Reorder Keyboard Fonts",
      permission: ApiPermissions.keyboardFontReorder,
      component: KeyboardFontsReorder,
    },
    keyboardFontCreate: {
      path: "/keyboard-fonts/create",
      name: "Create Keyboard Fonts",
      permission: ApiPermissions.keyboardFontCreate,
      component: ManageKeyboardFont,
    },
    keyboardFontEdit: {
      path: "/keyboard-fonts/:id/edit",
      name: "Edit Keyboard Fonts",
      permission: ApiPermissions.keyboardFontEdit,
      component: ManageKeyboardFont,
    },
    createContentUpdatePrompt: {
      path: "/cp/content-prompts/create",
      name: "Create Content Prompts",
      permission: ApiPermissions.contentUpdatePromptCreate,
      component: ManageContentUpdatePrompts,
    },
    editContentUpdatePrompt: {
      path: "/cp/content-prompts/:contentUpdatePromptId/edit",
      name: "Edit Content Prompts",
      permission: ApiPermissions.contentUpdatePromptUpdate,
      component: ManageContentUpdatePrompts,
    },
    contentUpdatePromptsDetails: {
      path: "/cp/content-prompts/:id",
      name: "Content Prompt Details",
      permission: ApiPermissions.contentUpdatePromptDetail,
      component: ContentPromptDetails,
    },
    stickerDetail: {
      path: "/stickers/:stickerid/",
      name: "Sticker Detail",
      permission: ApiPermissions.stickerDetail,
      component: StickerDetail,
    },
    editSticker: {
      path: "/stickers/:id/edit",
      name: "Edit Sticker",
      permission: ApiPermissions.stickerEdit,
      component: StickerEdit,
    },
    gifDetail: {
      path: "/gifs/:gifid/",
      name: "Gif Detail",
      permission: ApiPermissions.gifDetail,
      component: GifDetail,
    },
    editGif: {
      path: "/gifs/:id/edit",
      name: "Edit Gif",
      permission: ApiPermissions.gifEdit,
      component: GifEdit,
    },
    movieGifDetail: {
      path: "/movie-gifs/:id",
      name: "Movie Gif Detail",
      permission: ApiPermissions.movieGifsList,
      component: MovieGifsDetail,
    },
    movieGifEdit: {
      path: "/movie-gifs/:id/edit",
      name: "Movie Gif Edit",
      permission: ApiPermissions.movieGifsUpdate,
      component: MovieGifsEdit,
    },
    //-------keyboard language routes ----//
    createKeyboardLanguage: {
      path: "/kl/create-keyboard-language",
      name: "Add Keyboard Language",
      permission: ApiPermissions.keyboardLanguageCreate,
      component: ManageKeyboardLanguage,
    },
    languageDetail: {
      path: "/kl/keyboard-languages/:languageId/layouts",
      name: "Language Detail",
      permission: ApiPermissions.keyboardLanguageDetail,
      component: LanguageDetail,
    },
    addLayouts: {
      path: "/kl/keyboard-languages/:languageId/layouts/create",
      name: "Manage Layouts",
      permission: ApiPermissions.keyboardLanguageLayoutCreate,
      component: ManageLayouts,
    },
    editLayout: {
      path: "/kl/keyboard-languages/:languageId/layouts/:layoutId/edit",
      name: "Manage Layouts",
      permission: ApiPermissions.keyboardLanguageLayoutEdit,
      component: ManageLayouts,
    },
    addRewards: {
      path: "/rewards/add",
      name: "Rewards",
      permission: ApiPermissions.rewardsCreate,
      component: RewardForm,
    },
    updateRewards: {
      path: "/rewards/edit/:id",
      name: "Rewards",
      permission: ApiPermissions.rewardsCreate,
      component: EditRewardForm,
    },
    addTasks: {
      path: "/tasks/add",
      name: "Tasks",
      permission: ApiPermissions.taskCreate,
      component: TaskForm,
    },
    updateTasks: {
      path: "/tasks/edit/:id",
      name: "tasks",
      permission: ApiPermissions.taskUpdate,
      component: EditTaskForm,
    },
    addTasksEvent: {
      path: "/tasksEvent/add/:id",
      name: "Tasks",
      permission: ApiPermissions.taskCreate,
      component: AddTaskEventForm,
    },
    TaskEvents: {
      path: "/taskEvents/:id",
      name: "Tasks",
      permission: ApiPermissions.taskCreate,
      component: TaskEvents,
    },
    editTaskEvents: {
      path: "/edit/taskEvents/:id",
      name: "Tasks",
      permission: ApiPermissions.taskCreate,
      component: EditTaskEvent,
    },
    createContentCategory: {
      path: "/cre/create-category",
      name: "Add New Category",
      permission: ApiPermissions.contentCategoryCreate,
      component: CreateContentCategory,
    },
    addCarousel: {
      path: "/cre/create-carousel",
      name: "Carousel",
      permission: ApiPermissions.contentCategoryCarouselCreate,
      component: AddCarouselForm,
    },
    updateCarousel: {
      path: "/cre/carousel-details/:id",
      name: "Carousel",
      permission: ApiPermissions.contentCategoryCarouselUpdate,
      component: EditCarouselForm,
    },
    AddCarouselItems: {
      path: "/cre/carousel/add-items/:id",
      name: "Carousel",
      permission: ApiPermissions.contentCategoryCarouselItemAdd,
      component: AddCarouselItems,
    },
    CarouselItems: {
      path: "/cre/carousel/items/:id",
      name: "Carousel",
      permission: ApiPermissions.contentCategoryCarouselItemView,
      component: CarouselItems,
    },
    EditCarouselItems: {
      path: "/cre/carousel/:carouselId/edit-items/:itemId",
      name: "Carousel",
      permission: ApiPermissions.contentCategoryCarouselItemAdd,
      component: EditCarouselItems,
    },
    CarouselTargetingRules: {
      path: "/cre/carousel-rules/:id",
      name: "Carousel",
      permission: ApiPermissions.contentCategoryCarouselRuleView,
      component: CarouselTargetingRules,
    },
    CarouselUserAttributeRules: {
      path: "/cre/carousel-user-attribute-rules/:id",
      name: "Carousel",
      permission: ApiPermissions.contentCategoryCarouselRuleView,
      component: ContentCategoryCarouselUserAttributeRules,
    },
    ManageCarousel: {
      path: "/cre/carousel/order",
      name: "Carousel",
      permission: ApiPermissions.contentCategoryCarouselOrderUpdate,
      component: ManageCarousel,
    },
    ManageCarouselItems: {
      path: "/cre/carousel/item-order/:id",
      name: "Carousel",
      permission: ApiPermissions.contentCategoryCarouselItemOrderUpdate,
      component: ManageCarouselItems,
    },
    contentCategoryDetails: {
      path: "/cre/content-categories-details/:id",
      name: "Content Categories Details",
      permission: ApiPermissions.contentCategoryDetail,
      component: ContentCategoriesDetails,
    },
    contentCategoryRules: {
      path: "/cre/content-categories-rules/:id",
      name: "Content Categories Rules",
      permission: ApiPermissions.contentCategoryRuleView,
      component: ContentCategoriesRules,
    },
    contentCategoryUserAttributeRules: {
      path: "/cre/content-categories-user-attribute-rules/:id",
      name: "Content Categories User Attribute Rules",
      permission: ApiPermissions.contentCategoryRuleView,
      component: ContentCategoryUserAttributeRules,
    },
    createPromotedTags: {
      path: "/cre/create-promoted-tag",
      name: "Add New Promoted Tag",
      permission: ApiPermissions.contentPromotedTagCreate,
      component: CreatePromotedTag,
    },
    contentPromotedTagsDetails: {
      path: "/cre/promoted-tags-details/:id",
      name: "Promoted Tag Details",
      permission: ApiPermissions.contentPromotedTagDetail,
      component: PromotedTagsDetails,
    },
    contentPromotedTagsRules: {
      path: "/cre/promoted-tags-rules/:id",
      name: "Promoted Tags Rules",
      permission: ApiPermissions.contentPromotedTagRuleView,
      component: PromotedTagsRules,
    },
    keyboardPromptsAdd: {
      path: "/keyboard-prompt/prompts/add",
      name: "Add Keyboard Prompts",
      permission: ApiPermissions.keyboardPromptCreate,
      component: ManageKeyboardPrompts,
    },
    keyboardPromptsEdit: {
      path: "/keyboard-prompt/prompts/edit/:id",
      name: "Edit Keyboard Prompts",
      permission: ApiPermissions.keyboardPromptEditView,
      component: ManageKeyboardPrompts,
    },
    keyboardPromptsOrder: {
      path: "/keyboard-prompt/prompts/order",
      name: "Order Keyboard Prompts",
      permission: ApiPermissions.keyboardPromptUpdate,
      component: ManageKeyboardPromptOrder,
    },
    KeyboardPromptsTargetingRules: {
      path: "/keyboard-prompt/prompts/targeting-rules/:id",
      name: "Keyboard Prompts Targeting Rules",
      permission: ApiPermissions.keyboardPromptList,
      component: KeyboardPromptsTargetingRules,
    },
    KeyboardPromptsUserAttributeRules: {
      path: "/keyboard-prompt/prompts/user-attribute-rules/:id",
      name: "Keyboard Prompts User Attribute Rules",
      permission: ApiPermissions.keyboardPromptTargetingRulesList,
      component: KeyboardPromptsUserAttributeRules,
    },
    KeyboardPromptsRulesetOrder: {
      path: "/keyboard-prompt/prompts/segmentOrder",
      name: "Category Ruleset Ordering",
      permission: ApiPermissions.suggestionDrawerCategoryOrderView,
      component: SegmentPromptsOrder,
    },
    KeyboardPromptsRulesetOrderForm: {
      path: "/keyboard-prompt/prompts/rulesetOrder/create",
      name: "Create Category Ruleset",
      permission: ApiPermissions.suggestionDrawerCategoryOrderView,
      component: PromptsRulesetForm,
    },
    KeyboardPromptsRulesetEditOrderForm: {
      path: "/keyboard-prompt/prompts/rulesetOrder/:segmentID",
      name: "Edit Category Ruleset",
      permission: ApiPermissions.suggestionDrawerCategoryOrderView,
      component: EditPromptsRulesetForm,
    },
    smartSuggestionsAdd: {
      path: "/ads/smart-suggestions/add",
      name: "Add Smart Suggestions",
      permission: ApiPermissions.smartSuggestionCreate,
      component: ManageSmartSuggestions,
    },
    smartSuggestionsEdit: {
      path: "/ads/smart-suggestions/edit/:id",
      name: "Edit Smart Suggestions",
      permission: ApiPermissions.smartSuggestionList,
      component: ManageSmartSuggestions,
    },
    smartSuggestionsDuplicate: {
      path: "/ads/smart-suggestions/duplicate/:id",
      name: "Duplicate Smart Suggestions",
      permission: ApiPermissions.smartSuggestionList,
      component: ManageSmartSuggestions,
    },
    smartSuggestionsOrdering: {
      path: "/ads/smart-suggestions/order",
      name: "Smart Suggestions Ordering",
      permission: ApiPermissions.smartSuggestionOrderList,
      component: SmartSuggestionsOrder,
    },
    smartSuggestionsPreferredCategories: {
      path: "/ads/smart-suggestions/preferred-categories",
      name: "Smart Suggestions - Preferred Categories",
      permission: ApiPermissions.smartSuggestionPreferredCategoriesList,
      component: SmartSuggestionsPreferredCategories,
    },
    smartSuggestionsMetadataOverride: {
      path: "/ads/smart-suggestions/manage-smart-suggestions",
      name: "Manage Smart Suggestions",
      permission: ApiPermissions.adsMetadataOverrideDashboard,
      component: SmartSuggestionsMetadataOverride,
    },
    smartSuggestionsTargetingRules: {
      path: "/ads/smart-suggestions/targeting-rules/:id",
      name: "Smart Suggestions Targeting Rules",
      permission: ApiPermissions.smartSuggestionTargetingRuleList,
      component: SmartSuggestionsTargetingRules,
    },
    configAPIDashboardCreativeTypes: {
      path: "/ads/mediation-sdk/creative-types",
      name: "Creative Types",
      permission: ApiPermissions.configAPIList,
      component: ManageCreativeTypes,
    },
    configAPIDashboardVendors: {
      path: "/ads/mediation-sdk/vendors",
      name: "Vendors",
      permission: ApiPermissions.configAPIList,
      component: ManageVendors,
    },
    configAPIDashboardConfigs: {
      path: "/ads/mediation-sdk/mixing-strategy",
      name: "Configs",
      permission: ApiPermissions.configAPIList,
      component: ManageMixingStrategy,
    },
    configAPIDashboardPlacementVendors: {
      path: "/ads/mediation-sdk/placement-vendors",
      name: "Placement Vendors",
      permission: ApiPermissions.configAPIList,
      component: ManagePlacementVendors,
    },
    sessionLogs: {
      path: "/logs/session",
      name: "Session Logs",
      permission: ApiPermissions.dashboardLogs,
      component: DashboardSessionLogs,
    },
    //-------Taas dashboard Routes---------//
    createTaasClient: {
      path: "/taas/clients/create",
      name: "Client",
      permission: ApiPermissions.taasClientManage,
      component: ManageClient,
    },
    editTaasClient: {
      path: "/taas/clients/edit/:id",
      name: "Client",
      permission: ApiPermissions.taasClientManage,
      component: ManageClient,
    },
    viewStatistics: {
      path: "/taas/clients/:id/statistics",
      name: "Statistics",
      permission: ApiPermissions.taasStatisticsViewer,
      component: ClientStatistics,
    },
    createTaasPartner: {
      path: "/taas/partner/create",
      name: "Partner",
      permission: ApiPermissions.taasClientManage,
      component: ManagePartner,
    },
    createTaasSticker: {
      path: "/taas/stickers/create",
      name: "Sticker",
      permission: ApiPermissions.taasStickerManage,
      component: ManageSticker,
    },
    editTaasSticker: {
      path: "/taas/stickers/edit/:id",
      name: "Sticker",
      permission: ApiPermissions.taasStickerManage,
      component: ManageSticker,
    },
    createTaasStory: {
      path: "/taas/stories/create",
      name: "Story",
      permission: ApiPermissions.taasStoryManage,
      component: ManageStory,
    },
    editTaasStory: {
      path: "/taas/stories/:type/edit/:id",
      name: "Story",
      permission: ApiPermissions.taasStoryManage,
      component: ManageStory,
    },
    createPopTextSticker: {
      path: "/taas/poptext-stickers/create",
      name: "PopText Sticker",
      permission: ApiPermissions.taasStickerManage,
      component: ManagePopTextSticker,
    },
    editPopTextSticker: {
      path: "/taas/poptext-stickers/edit/:id",
      name: "PopText Sticker",
      permission: ApiPermissions.taasStickerManage,
      component: ManagePopTextSticker,
    },
    //------------Gen AI Dashboard routes-------------//
    createGenAIPromptTemplate: {
      path: "/gen-ai/prompt-templates/create",
      name: "Prompt Template Form",
      permission: ApiPermissions.AIPromptTemplateAdd,
      component: CreatePromptTemplate,
    },
    editGenAIPromptTemplate: {
      path: "/gen-ai/prompt-templates/edit/:id",
      name: "Prompt Template",
      permission: ApiPermissions.AIPromptTemplateEdit,
      component: CreatePromptTemplate,
    },
    createGenAIPromptTemplateVersion: {
      path: "/gen-ai/prompt-templates/:promptTemplateId/version/create",
      name: "Prompt Template Version",
      permission: ApiPermissions.AIPromptTemplateVersionAdd,
      component: CreatePromptTemplate,
    },
    genAIPromptTemplateDetails: {
      path: "/gen-ai/prompt-templates/details/:id",
      name: "Prompt Template Details",
      permission: ApiPermissions.AIPromptTemplateDetail,
      component: PromptTemplateDetails,
    },
    genAIPlacementDetails: {
      path: "/gen-ai/placements/:id/details",
      name: "Placement Details",
      permission: ApiPermissions.AIPoweredBarPlacementDetail,
      component: PlacementDetails,
    },
    genAIPlacementCreate: {
      path: "/gen-ai/placements/create",
      name: "Placement",
      permission: ApiPermissions.AIPoweredBarPlacementAdd,
      component: CreatePlacement,
    },
    genAIPlacementEdit: {
      path: "/gen-ai/placements/:id/edit",
      name: "Placement",
      permission: ApiPermissions.AIPoweredBarPlacementEdit,
      component: CreatePlacement,
    },
    genAIButtonCreate: {
      path: "/gen-ai/placements/:placementId/buttons/create",
      name: "Button",
      permission: ApiPermissions.AIPoweredBarPlacementAdd,
      component: CreateButton,
    },
    genAIButtonEdit: {
      path: "/gen-ai/placements/:placementId/buttons/:id/edit",
      name: "Button",
      permission: ApiPermissions.AIPoweredBarPlacementEdit,
      component: CreateButton,
    },
    genAITaskDetails: {
      path: "/gen-ai/tasks/:id/details",
      name: "Task Details",
      permission: ApiPermissions.AIPoweredBarTaskDetail,
      component: TaskDetails,
    },
    genAITaskCreate: {
      path: "/gen-ai/tasks/create",
      name: "Task",
      permission: ApiPermissions.AIPoweredBarTaskAdd,
      component: CreateTask,
    },
    genAITaskEdit: {
      path: "/gen-ai/tasks/:id/edit",
      name: "Task",
      permission: ApiPermissions.AIPoweredBarTaskEdit,
      component: CreateTask,
    },
    genAITaskDropdownOptionCreate: {
      path: "/gen-ai/tasks/:taskId/dropdownOptions/create",
      name: "Task DropdownOption",
      permission: ApiPermissions.AIPoweredBarTaskAdd,
      component: CreateDropdownOption,
    },
    genAITaskDropdownOptionEdit: {
      path: "/gen-ai/tasks/:taskId/dropdownOptions/:id/edit",
      name: "Task DropdownOption",
      permission: ApiPermissions.AIPoweredBarTaskEdit,
      component: CreateDropdownOption,
    },
    //--------Chat Assistant Routes-------------
    createChatAssistantSystemPrompt: {
      path: "/chat-assistant/system-prompts/create",
      name: "System Prompt",
      permission: ApiPermissions.chatAssistantSystemPromptCreate,
      component: ManageSystemPrompt,
    },
    // -------suggestionDrawerDashboard Routes ---------------
    createSuggestionDrawerCategory: {
      path: "/suggestionDrawer/category/create-category",
      name: "Category",
      permission: ApiPermissions.suggestionDrawerCategoryCreate,
      component: ManageSuggestionDrawerCategory,
    },
    editSuggestionDrawerCategory: {
      path: "/suggestionDrawer/category/edit-category/:id",
      name: "Category",
      permission: ApiPermissions.suggestionDrawerCategoryDetail,
      component: ManageSuggestionDrawerCategory,
    },
    suggestionDrawerCategoryOrder: {
      path: "/suggestionDrawer/category/order",
      name: "Category Ordering",
      permission: ApiPermissions.suggestionDrawerCategoryOrderView,
      component: CategoryOrder,
    },
    suggestionDrawerCategoryRulesetOrder: {
      path: "/suggestionDrawer/category/segmentOrder",
      name: "Category Ruleset Ordering",
      permission: ApiPermissions.suggestionDrawerCategoryOrderView,
      component: SegmentCategoryOrder,
    },
    suggestionDrawerCategoryRulesetOrderForm: {
      path: "/suggestionDrawer/category/rulesetOrder/create",
      name: "Create Category Ruleset",
      permission: ApiPermissions.suggestionDrawerCategoryOrderView,
      component: CategoryRulesetForm,
    },
    suggestionDrawerCategoryRulesetEditOrderForm: {
      path: "/suggestionDrawer/category/rulesetOrder/:segmentID",
      name: "Edit Category Ruleset",
      permission: ApiPermissions.suggestionDrawerCategoryOrderView,
      component: EditCategoryRulesetForm,
    },
    suggestionDrawerCategoryContentOrder: {
      path: "/suggestionDrawer/category/:id/content/order",
      name: "Category Content Ordering",
      permission: ApiPermissions.suggestionDrawerContentOrderView,
      component: CategoryContentOrder
    },
    suggestionDrawerCategoryTargetingRules: {
      path: "/suggestionDrawer/category/targeting-rules/:id",
      name: "Category Targeting Rules",
      permission: ApiPermissions.SuggestionDrawerCategoryRulesView,
      component: SuggestionDrawerCategoryTargetingRules,
    },
    suggestionDrawerCategoryUserAttributeRules: {
      path: "/suggestionDrawer/category/user-attribute-rules/:id",
      name: "Category Targeting User Attribute Rules",
      permission: ApiPermissions.SuggestionDrawerCategoryRulesView,
      component: CategoryUserAttributeRules,
    },
    suggestionDrawerContentTargetingRules: {
      path: "/suggestionDrawer/content/targeting-rules/:id",
      name: "Content Targeting Rules",
      permission: ApiPermissions.SuggestionDrawerContentRulesView,
      component: SuggestionDrawerContentTargetingRules,
    },
    suggestionDrawerContentBulkTargetingRules: {
      path: "/suggestionDrawer/content/user-attributes-bulk-rules",
      name: "Multiple Content Targeting User Attribute Rules",
      permission: ApiPermissions.SuggestionDrawerContentRulesView,
      component: BulkContentUserAttributeRules,
    },
    suggestionDrawerContentsTargetingRules: {
      path: "/suggestionDrawer/content/targeting-rules",
      name: "Contents Targeting Rules",
      permission: ApiPermissions.SuggestionDrawerContentRulesView,
      component: SuggestionDrawerContentsTargetingRules,
    },
    suggestionDrawerContentUserAttributeRules: {
      path: "/suggestionDrawer/content/user-attribute-rules/:id",
      name: "Content Targeting User Attribute Rules",
      permission: ApiPermissions.SuggestionDrawerContentRulesView,
      component: ContentUserAttributeRules,
    },
    createSuggestionDrawerContent: {
      path: "/suggestionDrawer/content/create-content",
      name: "Content",
      permission: ApiPermissions.suggestionDrawerContentCreate,
      component: ManageSuggestionDrawerContent,
    },
    editSuggestionDrawerContent: {
      path: "/suggestionDrawer/content/edit-content/:id",
      name: "Content",
      permission: ApiPermissions.suggestionDrawerContentDetail,
      component: ManageSuggestionDrawerContent,
    },
    // ------------------ Stories -------------------//
    createSotd: {
      path: "/stories/sotd/create",
      name: "Create Story",
      permission: ApiPermissions.sotdCreatePermission,
      component: CreateSotd,
    },
    createBucket: {
      path: "/stories/buckets/create",
      name: "Create a Bucket",
      permission: ApiPermissions.storyBucketCreatePermission,
      component: CreateBucket,
    },
    // ------------Stories 
    editStories: {
      path: "/stories/edit-stories/:id",
      name: "Stories",
      permission: ApiPermissions.storyUpdatePermission,
      component: StoriesEdit,
    },
    createStories: {
      path: "/stories/create-stories",
      name: "Stories",
      permission: ApiPermissions.storyCreatePermission,
      component: StoriesEdit,
    },
    createTargetingRules: {
      path: "/stories/targetingRules/:id",
      name: "Stories",
      permission: ApiPermissions.storiesTargetingRuleViewPermission,
      component: StoryTargetingRules,
    },
    editCta: {
      path: "/stories/:id/edit-cta",
      name: "Stories",
      permission: ApiPermissions.storyCTAViewPermission,
      component: CTAItemsPage,
    },
    storiesRules: {
      path: "/stories/targetingRules",
      name: "Stories Targeting Rules",
      permission: ApiPermissions.storiesTargetingRuleViewPermission,
      component: StoriesRules,
    },
    editSotdRules: {
      path: "/stories/sotd/targetingRules/:id",
      name: "Stories",
      permission: ApiPermissions.sotdTargetingRuleViewPermission,
      component: SotdTargetingRules,
    },
    editUserAttributeRules: {
      path: "/stories/user-attribute-rules/:id",
      name: "Stories",
      permission: ApiPermissions.storyUserAttributePermission,
      component: UserAttributeRules,
    },
    pushNotificationExecution: {
      path: "/push-notification/execution/:id",
      name: "Push Notification Execution",
      permission: ApiPermissions.notificationExecutionList,
      component: PushNotificationExecution,
    },
    testPushNotification: {
      path: "/push-notification/test/:id",
      name: "Test Push Notification",
      permission: ApiPermissions.notificationTest,
      component: TestPushNotification,
    },
    createPushNotification: {
      path: "/push-notification/add",
      name: "Create Push Notification",
      permission: ApiPermissions.notificationCreate,
      component: ManagePushNotification,
    },
    editPushNotification: {
      path: "/push-notification/edit/:id",
      name: "Edit Push Notification",
      permission: ApiPermissions.notificationEdit,
      component: ManagePushNotification,
    },
    viewPushNotificationDetails: {
      path: "/push-notification/view/:id",
      name: "View Push Notification",
      permission: ApiPermissions.notificationDetails,
      component: ManagePushNotification,
    },
    viewPushNotificationTargetingRules: {
      path: "/push-notification/targeting-rules/view/:id",
      name: "Push Notification Targeting Rules View",
      permission: ApiPermissions.notificationRuleView,
      component: PushNotificationTargetingRules,
    },
    pushNotificationTargetingRules: {
      path: "/push-notification/targeting-rules/:id",
      name: "Push Notification Targeting Rules",
      permission: ApiPermissions.notificationRuleView,
      component: PushNotificationTargetingRules,
    },
    createUserSegment: {
      path: "/push-notification/user-segments/create",
      name: "Create User Segment",
      permission: ApiPermissions.userSegmentCreate,
      component: ManageUserSegment,
    },
    editUserSegment: {
      path: "/push-notification/user-segments/edit/:id",
      name: "Edit User Segment",
      permission: ApiPermissions.userSegmentEdit,
      component: ManageUserSegment,
    },
    viewUserSegmentDetails: {
      path: "/push-notification/user-segments/view/:id",
      name: "View User Segment",
      permission: ApiPermissions.userSegmentDetail,
      component: ManageUserSegment,
    },
  },
};
const contentUpdatePrompts = {
  path: "/cp",
  name: "Content Update Prompts",
  icon: BellIcon,
  children: {
    contentUpdatePrompts: {
      path: "/cp/content-prompts",
      name: "View Content Prompts",
      permission: ApiPermissions.contentUpdatePromptList,
      component: ContentPrompts,
    }
  },
};
const rewards = {
  path: "/rewards",
  name: "Rewards",
  icon: Gift,
  children: {
    viewRewards: {
      path: "/rewards/view-rewards",
      name: "View Rewards",
      permission: ApiPermissions.rewardsList,
      component: Rewards,
    },
    viewTasks: {
      path: "/tasks/view-tasks",
      name: "View Tasks",
      permission: ApiPermissions.taskList,
      component: Tasks,
    },
  },
};

const pushNotification = {
  path: "/push-notification",
  name: "Push Notification",
  icon: MdNotificationsActive,
  children: {
    viewPushNotification: {
      path: "/push-notification/view",
      name: "View Push Notification",
      permission: ApiPermissions.notificationList,
      component: PushNotificationsTable,
    },   
    userSegments: {
      path: "/push-notification/user-segments",
      name: "User Segments",
      permission: ApiPermissions.userSegmentList,
      component: UserSegments,
    },
  },
}

const logs = {
  path: "/logs",
  name: "Logs",
  icon: FaDatabase,
  children: {
    logs: {
      path: "/logs",
      name: "Logs",
      permission: ApiPermissions.dashboardLogs,
      component: DashboardLogs,
    },
  },
};

export const authRoutes = { login };

export const unprotectedDashboardRoutes = {
  userProfile,
};

export const sidebarRoutes = {
  dashboard,
  popTextStyles,
  keyboardThemes,
  quickReplies,
  users,
  permissions,
  roles,
  keyboardLanguages,
  stickers,
  stories,
  gifs,
  moviesGifs,
  contentUpdatePrompts,
  creDashboard,
  brandCampaign,
  keyboardPrompt,
  ads,
  deepLinks,
  rewards,
  appResource,
  taasDashboard,
  suggestionDrawerDashboard,
  segmentsDashboard,
  genAIDashboard,
  chatAssistantDashboard,
  pushNotification,
  logs,
  sessions,
};

export default {
  dashboard,
  popTextStyles,
  keyboardThemes,
  quickReplies,
  users,
  permissions,
  roles,
  keyboardLanguages,
  stickers,
  stories,
  gifs,
  moviesGifs,
  contentUpdatePrompts,
  creDashboard,
  brandCampaign,
  keyboardPrompt,
  ads,
  deepLinks,
  rewards,
  appResource,
  commonRoutes,
  taasDashboard,
  suggestionDrawerDashboard,
  segmentsDashboard,
  genAIDashboard,
  chatAssistantDashboard,
  pushNotification,
  logs,
  sessions,
};

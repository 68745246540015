import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Collapse,
  Container,
  Label,
} from "reactstrap";
import {
  noDefaultSuggestionsAvailableText,
  noLayoutsAvailableText,
} from "../../../config/keyboardLanguages";

const KeyboardLanguageLayouts = (props) => {
  const keyboardLanguage = props.keyboardLanguage;
  const toggleCollapse = props.toggleCollapse;
  const showEditLayoutButton = props.showEditLayoutButton;
  const showAddLayoutButton = props.showAddLayoutButton;
  return (
    <>
      <Container className="d-flex px-0">
        <h2 className="mt-3 mb-0">Layouts</h2>
        {keyboardLanguage.layouts > 0 ? (
          <h2 className="mt-3 mb-0">Layouts</h2>
        ) : null}
        {showAddLayoutButton() ? (
          <Link
            className="ml-auto d-flex text-decoration-none"
            to={{
              pathname: `/kl/keyboard-languages/${keyboardLanguage.id}/layouts/create`,
              state: {
                formType: "create",
                keyboardLanguageId: keyboardLanguage.id,
              },
            }}
          >
            <Button color="primary" className="ml-auto d-flex">
              <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                Add new Layout
              </p>
            </Button>
          </Link>
        ) : null}
      </Container>
      {/* Accordian for displaying Layouts */}
      {keyboardLanguage.layouts && keyboardLanguage.layouts.length > 0 ? (
        keyboardLanguage.layouts.map((layout, index) => {
          return (
            <Fragment key={index}>
              <Card
                className="mb-0 mt-3"
                style={{
                  cursor: "pointer",
                  backgroundColor: "rgb(200,215,250)",
                }}
              >
                <CardBody
                  className="mb-0 p-2"
                  onClick={() => toggleCollapse(index)}
                >
                  <Row>
                    <Col md={6} className="d-flex">
                      <p className="mb-0 mr-2">Type: </p>
                      <strong className="mb-0 p-0">{layout.type}</strong>
                    </Col>
                    <Col md={6} className="d-flex">
                      <p className="mb-0 mr-2">Full Name: </p>
                      <strong className="mb-0 p-0">{layout.fullName}</strong>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Collapse isOpen={layout.isOpen}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <span>
                          <strong>ID:</strong> {layout.id}
                        </span>
                        <br />
                        <span>
                          <strong>Keyboard Layout ID:</strong>{" "}
                          {layout.keyboardLayoutId}
                        </span>
                        <br />
                        <span>
                          <strong>Type:</strong> {layout.type}
                        </span>
                        <br />

                        <span>
                          <strong>Character Identifier:</strong>{" "}
                          {layout.characterIdentifier}
                        </span>
                        <br />
                        <span>
                          <strong>Is Popular:</strong>{" "}
                          {String(layout.isPopular) ?? "N/A"}
                        </span>
                        <br />
                        <span>
                          <strong>Auto Select:</strong>{" "}
                          {String(layout.autoSelect)}
                        </span>
                      </Col>
                      <Col md={6}>
                        <span>
                          <strong>Full Name:</strong> {layout.fullName}
                        </span>
                        <br />
                        <span>
                          <strong>Shortname:</strong> {layout.shortName}
                        </span>
                        <br />
                        <span>
                          <strong>Description:</strong> {layout.description}
                        </span>
                        <br />
                        <span>
                          <strong>Min App Version:</strong>{" "}
                          {layout.minAppVersion ?? "N/A"}
                        </span>
                        <br />
                        <span>
                          <strong>Min Bobble SDK Version:</strong>{" "}
                          {layout.minBobbleSDKVersion ?? "N/A"}
                        </span>
                        <br />
                        <span>
                          <strong>Priority:</strong> {String(layout.priority)}
                        </span>
                      </Col>
                      <Col md={6}>
                        <Label>
                          <strong>Preview Image</strong>
                        </Label>
                        {keyboardLanguage.previewImageURL &&
                        keyboardLanguage.previewImageURL !== "" &&
                        keyboardLanguage.previewImageURL !== null ? (
                          <Col className="pb-4">
                            <img
                              src={keyboardLanguage.previewImageURL}
                              height="120"
                              alt="Preview Img"
                              width="120"
                            />
                          </Col>
                        ) : (
                          <Col></Col>
                        )}
                      </Col>
                      <Col md={6}>
                        <Label>
                          <strong>Dark Mode Preview Image</strong>
                        </Label>
                        {keyboardLanguage.darkModePreviewImageURL &&
                        keyboardLanguage.darkModePreviewImageURL !== "" &&
                        keyboardLanguage.darkModePreviewImageURL !== null ? (
                          <Col className="pb-4">
                            <img
                              src={keyboardLanguage.darkModePreviewImageURL}
                              height="120"
                              alt="Dark Mode Preview Img"
                              width="120"
                            />
                          </Col>
                        ) : (
                          <Col></Col>
                        )}
                      </Col>
                    </Row>
                    <hr />
                    {/* Display default suggestions */}
                    <h4 className="d-flex justify-content-center">
                      <p className="pb-0 mb-0">Default Suggestions</p>
                    </h4>
                    <Row>
                      {layout.defaultSuggestions ? (
                        layout.defaultSuggestions.map((suggestion, index) => {
                          return (
                            <Col key={index} md={4}>
                              <Card
                                style={{
                                  backgroundColor: "rgb(184, 237, 173)",
                                }}
                                className="my-2 p-0 badge-light text-dark"
                              >
                                <CardBody className="m-0 p-2">
                                  {suggestion}
                                </CardBody>
                              </Card>
                            </Col>
                          );
                        })
                      ) : (
                        <p className="d-block mx-auto text-danger">
                          {noDefaultSuggestionsAvailableText}
                        </p>
                      )}
                    </Row>
                    {showEditLayoutButton() ? (
                      <Link
                        to={{
                          pathname: `/kl/keyboard-languages/${keyboardLanguage.id}/layouts/${layout.id}/edit`,
                          state: {
                            formType: "edit",
                            keyboardLanguageId: keyboardLanguage.id,
                            layout: keyboardLanguage.layouts.filter(
                              (_layout) => _layout.id === layout.id
                            ),
                          },
                        }}
                        className="text-decoration-none"
                      >
                        <Button
                          color="primary"
                          className="d-block mx-auto mt-4"
                        >
                          Edit Layout
                        </Button>
                      </Link>
                    ) : null}
                  </CardBody>
                </Card>
              </Collapse>
            </Fragment>
          );
        })
      ) : (
        <h1 className="d-block mx-auto w-25 mt-5">{noLayoutsAvailableText}</h1>
      )}
    </>
  );
};

export default KeyboardLanguageLayouts;

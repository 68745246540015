import React, { useState, useEffect } from "react";
import { Card, CardBody, Container } from "reactstrap";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import ResponseModal from "../ResponseModal";
import { failureModalHeading } from "../../../config";
import { GetAIPlacementButtons } from "../../../utilities/apiUtils/genAIDashboard";
import { formatTime, showCreatePlacementButton, showEditPlacementButton } from "../../../config/configGenAIDashboard";
import { useParams } from 'react-router-dom';
import { error, errorDescription } from "../../../utilities/commonUtil";
import { placementButtonTableColumns } from "./buttonTableColumn";


const ExpandableRowsTable = ({ placementId }) => {
    const [failureModalText, setFailureModalText] = useState("");
    const [failureModalDisplay, setFailureModalDisplay] = useState(false);
    const { id } = useParams();
    const [placementButtons, setPlacementButtons] = useState([]);

    const fetchPlacementButtons = async (id) => {
        try {
            let response = await GetAIPlacementButtons(id)
            if (!response || response?.length === 0) {
                setPlacementButtons([])
                return;
            }
            if (response.length > 0) {
                setPlacementButtons(response);
            } else {
                showErrorModal(errorDescription(response.errorCode));
            }
        } catch (err) {
            showErrorModal(errorDescription(error.unexpectedError));
        }
    }

    useEffect(() => {
        if (id) {
            fetchPlacementButtons(id);
        }
    }, [id]);


    let showErrorModal = (text) => {
        setFailureModalText(text);
        setFailureModalDisplay(true);
    };

    let failureModalClose = () => {
        setFailureModalDisplay(false);
    };

    const expandRow = {
        onlyOneExpanding: true,
        renderer: (row) => (
            <div>
                <ul>
                    <li><b>Light Theme Text Color : </b>{row.lightThemeTextColor ?? "N/A"}</li>
                    <li><b>Dark Theme Text Color: </b>{row.darkThemeTextColor ?? "N/A"}</li>
                    <li><b>Light Theme Background Color : </b>{row.lightThemeBackgroundColor ?? "N/A"}</li>
                    <li><b>Dark Theme Background Color : </b>{row.darkThemeBackgroundColor ?? "N/A"}</li>
                    <li><b>Border Type : </b>{row.borderType ?? "N/A"}</li>
                    <li><b>Light Theme Border Color : </b>{row.lightThemeBorderColor ?? "N/A"}</li>
                    <li><b>Dark Theme Border Color : </b>{row.darkThemeBorderColor ?? "N/A"}</li>
                    <li><b>Action Type : </b>{row.actionType ?? "N/A"}</li>
                    <li><b>Task ID : </b>{row.taskId ?? "N/A"}</li>
                    <li><b>Task Name : </b>{row.taskName ?? "N/A"}</li>
                    <li><b>Created At : </b>{formatTime(row.createdAt)}</li>
                    <li><b>Updated At : </b>{formatTime(row.updatedAt)}</li>
                </ul>
                <ul>
                    <h5>Action Metadata</h5>
                    <li><b>Deep Link : </b>{row?.actionMetadata?.deeplink}</li>
                    <li><b>Task ID : </b>{row?.actionMetadata?.taskId}</li>
                </ul>
                <ul>
                    <h5>Text Translations</h5>
                    {row?.text !== null ? Object.keys(row?.text).map(key => (
                        key !== "default" && <li key={key}><b>{key} : </b>{row?.text[key]}</li>
                    )) : "N/A"}
                </ul>
                {showEditPlacementButton() ? (
                    <>
                        <Link
                            to={{
                                pathname: `/gen-ai/placements/${placementId}/buttons/${row.id}/edit`
                            }}
                        >
                            <Button className="mx-3">
                                Edit
                            </Button>
                        </Link>
                    </>
                ) : null
                }
            </div >
        ),
    };

    return (
        <Card>
            <CardBody>
                <ToolkitProvider keyField="id" data={placementButtons} columns={placementButtonTableColumns}>
                    {(props) => (
                        <div>
                            <BootstrapTable
                                {...props.baseProps}
                                bootstrap4
                                bordered={false}
                                keyField="id"
                                expandRow={expandRow}
                                pagination={paginationFactory({
                                    sizePerPage: 10,
                                    sizePerPageList: [5, 10, 25, 50],
                                })}
                                defaultSorted={[{
                                    dataField: 'id',
                                    order: 'asc'
                                }]}
                            />
                        </div>
                    )}
                </ToolkitProvider>
            </CardBody>
            <ResponseModal
                show={failureModalDisplay}
                onHide={failureModalClose}
                modalheading={failureModalHeading}
                modaltext={failureModalText}
            />
        </Card>
    );
};

const PlacementButtons = ({ placementId }) => {
    return (
        <Container fluid className="p-0">
            <div className="d-flex justify-content-between">
                <h1 className="h3">Placement Buttons</h1>
                {showCreatePlacementButton() ? (
                    <div>
                        <Link
                            to={{
                                pathname: `/gen-ai/placements/${placementId}/buttons/create`
                            }}
                        >
                            <Button
                                style={{ fontSize: "1rem" }}
                                className="mx-3 btn-success mb-3"
                            >
                                Create a New Button
                            </Button>
                        </Link>
                    </div>
                ) : null}
            </div>
            <ExpandableRowsTable placementId={placementId} />
        </Container>
    )
}
export default PlacementButtons;
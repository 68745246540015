import React, { useEffect, useState } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  Button,
  Label,
} from "reactstrap";
import Select from "react-select";
import Reorder, { reorder } from "react-reorder";

import ConfirmationModal from "../ConfirmationModal";
import ResponseModal from "../ResponseModal";

import {
  saveQuickRepliesReordering,
  getQuickReplyCategoriesForForm,
  getQuickReplies
} from "../../../utilities/apiUtils/quickReplies";
import {statusOptions} from "../../../config/quickReplies"

import { errorDescription, error } from "../../../utilities/commonUtil";

const ReorderQuickReplies = () => {
  const [quickReplies, setQuickReplies] = useState([]);
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [action, setAction] = useState("");
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(null);
  const [status, setStatus] = useState("active")

  useEffect(() => {
    if (category !== null) {
      getQuickReplyList(status);
    }
  }, [category, status]);

  useEffect(() => {
    getCategories();
  }, []);

  const getQuickReplyList = async (status) => {
    let params = {
      categoryID: category,
      type: "card",
      status: status
    };
    const response = await getQuickReplies(params);
    if (response) {
      //  sort quickReplies by priority descending
      if (response?.quickReplies !== null) {
        response.quickReplies.sort((a, b) => b.priority - a.priority);
        setQuickReplies(response.quickReplies);
      } else {
        setQuickReplies([]);
      }
    }
  };

  const onQuickReplyCardReorder = (
    event,
    previousIndex,
    nextIndex,
    fromId,
    toId
  ) => {
    setQuickReplies(reorder(quickReplies, previousIndex, nextIndex));
  };

  let setConfirmationModalState = (event) => {
    event.preventDefault();
    setAction("Submit");
    setConfirmationModalIsOpen(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
  };

  const handleReorderSubmit = async () => {
    const payload = {
      quickReplies: [],
    };

    quickReplies.map((quickReply, index) => {
      payload.quickReplies.push({
        id: quickReply.id,
        priority: quickReplies.length - index,
      });
    });

    const response = await saveQuickRepliesReordering(payload);
    if (response?.errorCode) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: response.errorDescription,
      });
    } else {
      setSuccessModalText(response?.description);
      setSuccessModalDisplay(true);
    }
  };

  const truncateText = (text, maxWords) => {
    const words = text.split(" ");
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(" ") + "...";
    }
    return text;
  };

  const getCategories = async () => {
    try {
      let categories = await getQuickReplyCategoriesForForm();
      setCategories(categories);
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const handleCategoryChange = (category) => {
    setCategory(category.value);
  };

  const handleStatusChange = (status) => {
    setStatus(status.value)
  }

  return (
    <Container fluid className="p-0">
      <Row>
        <Col md={4}>
          <h1 className="h3 mb-3">Reorder Quick Replies</h1>
        </Col>
        <Col md={8}>
          <Card
            className="float-right"
            style={{
              boxShadow: "0 0 20px rgba(0,0,0,0.4)", // Increased shadow
              borderRadius: "15px",
            }}
          >
            <CardBody>
              <strong>
                <b>Note:</b>
              </strong>{" "}
              The cards can be reordered by dragging and dropping at the desired
              position. The top-left card will be assigned the highest priority.
              Priority decreases while going right and bottom. The order will
              only be saved when you click on the Save Order.
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md={4} sm={12}>
          <Label>
            <strong>Category ID</strong>
          </Label>
          <Select
            className="react-select-container mb-4"
            classNamePrefix="react-select"
            placeholder="Select category"
            onChange={(option) => handleCategoryChange(option)}
            options={categories}
          />
        </Col>
        <Col md={4} sm={12}>
          <Label>
            <strong>Status</strong>
          </Label>
          <Select
            className="react-select-container mb-4"
            classNamePrefix="react-select"
            placeholder="Select Status"
            onChange={(option) => handleStatusChange(option)}
            options={statusOptions}
            value={statusOptions.find((option) => option.value === status)}
          />
        </Col>
        <Col md={4}>
          <Button
            color="primary"
            className="float-right mb-3 btn-lg"
            onClick={(event) => setConfirmationModalState(event)}
          >
            Save Order
          </Button>
        </Col>
      </Row>

      <Row>
        <Reorder
          reorderId="reorder-list"
          onReorder={onQuickReplyCardReorder}
        >
          {quickReplies?.map((quickReply, index) => (
            <div
              key={quickReplies.id}
              style={{
                display: "inline-block",
              }}
            >
              <Card
                className="m-3 responseCard"
                style={{
                  width: "285px",
                  height: "14rem",
                  boxShadow: "0 0 20px rgba(0,0,0,0.4)",
                  borderRadius: "15px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {quickReply.imageURL ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CardImg
                      src={
                        quickReply.imageURL !== null
                          ? quickReply.imageURL
                          : "/img/no-image-found.png"
                      }
                      alt="Card image"
                      style={{
                        width: "200px",
                        height: "150px",
                        paddingLeft: "10px",
                        // marginBottom: "5px",
                      }}
                    />
                  </div>
                ) : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CardImg
                      src={"/img/no-image-icon.png"}
                      alt="Card image"
                      style={{
                        // width: "200px",
                        height: "150px",
                        paddingLeft: "10px",
                        marginBottom: "5px",
                      }}
                    />
                  </div>
                )}
                <CardBody style={{ width: "150px", alignItems: "center" }}>
                  <div style={{ marginBottom: "10px", width: "100px" }}>
                    {quickReply?.textDetails?.filter(
                      (card) => card.type === "header"
                    ).length > 0 && (
                      <div>
                        <h4>
                          {quickReply?.textDetails
                            ?.filter((card) => card.type === "header")
                            .map((card, index) => (
                              <span key={index}>
                                {truncateText(card.text, 4)}
                              </span>
                            ))}
                        </h4>
                      </div>
                    )}

                    {!quickReply?.textDetails?.some(
                      (card) => card.type === "header"
                    ) && <div style={{ height: "50px" }}></div>}

                    {quickReply?.textDetails?.filter(
                      (card) => card.type === "paragraph"
                    ).length > 0 && (
                      <div>
                        <p>
                          {quickReply?.textDetails
                            ?.filter((card) => card.type === "paragraph")
                            .map((card, index) => (
                              <span key={index}>
                                {truncateText(card.text, 4)}
                              </span>
                            ))}
                        </p>
                      </div>
                    )}

                {!quickReply?.textDetails?.some(
                      (card) => card.type === "paragraph"
                    ) && <div style={{ height: "50px" }}></div>}

                    {quickReply?.textDetails?.filter(
                      (card) => card.type === "subText"
                    ).length > 0 && (
                      <div>
                        <p>
                          {quickReply?.textDetails
                            ?.filter((card) => card.type === "subText")
                            .map((card, index) => (
                              <span key={index}>
                                {truncateText(card.text, 4)}
                              </span>
                            ))}
                        </p>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          ))}
        </Reorder>
      </Row>
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={action}
        onConfirmationTrue={() => {
          setConfirmationModalIsOpen(false);
          if (action === "Submit") handleReorderSubmit();
        }}
        onHide={() => setConfirmationModalIsOpen(false)}
      />

      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={"Success"}
        modaltext={successModalText}
      />

      <ResponseModal
        show={failureModalState.failureModalDisplay}
        onHide={() =>
          setFailureModalState({
            failureModalDisplay: false,
            failureModalText: "",
          })
        }
        modalheading={"Error"}
        modaltext={failureModalState.failureModalText}
      />
    </Container>
  );
};

export default ReorderQuickReplies;

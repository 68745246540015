import React, { useEffect, useState } from 'react'
import Select from "react-select";
import { Container, Row, Col, Card, CardBody, CardImg, Button, FormGroup, Label } from "reactstrap";
import Reorder, { reorder } from "react-reorder";
import { showOrderEditSmartSuggestionButton } from '../../../config/configSmartSuggestions';
import { fetchSmartSuggestionCountries, fetchSmartSuggestionsOrder, updateSmartSuggestionsOrder } from '../../../utilities/apiUtils/smartSuggestions';
import Loading from "../../../components/Loading";
import ResponseModal from '../ResponseModal';
import { successModalHeading } from '../../../config';
import { errorModalHeading } from '../../../config/config';
import { error, errorDescription, redirectOnTokenExpiry } from '../../../utilities/commonUtil';
import { fetchPlacementLocations } from '../../../utilities/apiUtils/placementLocations';

const SmartSuggestionsOrder = () => {
  const [loading, setLoading] = useState(true);
  const [successModalText, setSuccessModalText] = useState("");
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);

  const [ads, setAds] = useState([])
  const [adsOrderBeforeUpdate, setAdsOrderBeforeUpdate] = useState([])
  const [countryOptions, setCountryOptions] = useState([])
  const [countries, setCountries] = useState([])
  const [locationOptions, setLocationOptions] = useState([])

  const [filters, setFilters] = useState({
    location: { label: "Launcher", value: "launcher" },
    country: null
  })

  useEffect(() => {
    getCountries()
    getLocationsOptions()
  }, [])

  useEffect(() => {
    if (filters.location?.value && filters.country?.value) {
      getSmartSuggestionOrderWithLocationCountry(filters.location.value, filters.country.value)
    } else {
      setAds([])
    }
  }, [filters.location, filters.country])

  const getSmartSuggestionOrderWithLocationCountry = async (location, country) => {
    try {
      setLoading(true)
      let response = await fetchSmartSuggestionsOrder({ placementLocation: location, countryCode: country })
      if (response?.ads) {
        setAds(response.ads)
        setAdsOrderBeforeUpdate(response.ads)
      } else {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken ||
          response.errorCode === error.unauthorized
        )
          redirectOnTokenExpiry();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false)
    }
  }

  const getCountries = async () => {
    try {
      setLoading(true)
      let response = await fetchSmartSuggestionCountries()
      if (response?.length > 0) {
        const countries = []
        setCountries(response)
        response.forEach((country) => {
          countries.push({
            value: country.iso_code,
            label: country.name,
            target: {
              name: "country",
              value: {
                value: country.iso_code,
                label: country.name
              }
            }
          })
        })
        setCountryOptions(countries)
        setFilters((prevState) => ({
          ...prevState,
          country: countries.find(country => country.value === "IN").target.value
        }))
      } else {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken ||
          response.errorCode === error.unauthorized
        )
          redirectOnTokenExpiry();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false)
    }
  }

  const getLocationsOptions = async () => {
    try {
      let response = await fetchPlacementLocations();
      if (response?.length > 0) {
        setLocationOptions(response.map(location => ({
          value: location.value,
          label: location.name,
          target: {
            name: "location",
            value: {
              value: location.value,
              label: location.name
            }
          }
        })))
      } else {
        setLocationOptions([])
      }
    } catch (err) {
      console.log(err);
    }
  };

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    if (filters.location?.value && filters.country?.value) {
      getSmartSuggestionOrderWithLocationCountry(filters.location.value, filters.country.value)
    }
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target
    setFilters((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleReorder = (event, previousIndex, nextIndex, fromId, toId) => {
    setAds(reorder(ads, previousIndex, nextIndex))
  };

  const handleSubmit = async () => {
    const countryId = countries.find(country => country.iso_code === filters.country?.value).id ?? 0
    const payload = {
      oldAdsOrder: adsOrderBeforeUpdate,
      updatedAdsOrder: ads,
    }
    try {
      setLoading(true)
      let response = await updateSmartSuggestionsOrder({ placementLocation: filters.location?.value, countryCode: filters.country?.value, countryId }, { ...payload })
      if (response.status === "success") {
        let text = "Smart suggestion order has been updated";
        showSuccessModal(text);
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container fluid className="p-0">
      <Row>
        <h1 className="mb-4 mt-2 w-50 pl-3">Manage Ordering</h1>
      </Row>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Row form>
            <Col md={4}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Placement
                </Label>
                <Select
                  id="location"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="location"
                  placeholder="Select Location"
                  value={filters.location}
                  onChange={handleChange}
                  options={locationOptions}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Country
                </Label>
                <Select
                  id="country"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="country"
                  placeholder="Select Country"
                  value={filters.country}
                  onChange={handleChange}
                  options={countryOptions}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <h5>Instructions:</h5>
                      <ul>
                        <li>
                          Drag and Drop the ads icons to rearrange them.
                        </li>
                      </ul>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {(showOrderEditSmartSuggestionButton() && ads.length > 0) ? (
                        <Button className="d-block ml-2 mt-2 mb-3" color="primary" onClick={handleSubmit}>
                          Save
                        </Button>
                      ) : null}
                    </Col>
                  </Row>
                  {(ads.length > 0) ? (
                    <Reorder reorderId="reorder-list" onReorder={handleReorder} disabled={!showOrderEditSmartSuggestionButton()}>
                      {
                        ads?.map(ad => (
                          <div key={ad?.id} className="reorderContainer align-bottom mb-5">
                            <Card className="m-3 responseCard" style={{ cursor: "grab" }}>
                              <CardImg src={ad.iconURL} draggable={false} />
                            </Card>
                            <center style={{ fontWeight: 600, marginTop: -6 }}>{ad.title}</center>
                          </div>
                        ))
                      }
                    </Reorder>
                  )
                    : <p className='py-3'>No Smart Suggestions available for selected <strong>location</strong> and <strong>country</strong>.</p>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}
      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={successModalHeading}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={errorModalHeading}
        modaltext={failureModalText}
      />
    </Container>
  )
}

export default SmartSuggestionsOrder
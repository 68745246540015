import React, { Component } from "react";
import { Modal, Button, Row, Card, CardImg, Col } from "react-bootstrap";
import Reorder, { reorder } from "react-reorder";
import Select from "react-select";

class ImportStickers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stickers: props.stickers,
      id: [],
      stickerPackId: null,
      stickerPacks: [],
    };
  }

  componentWillReceiveProps() {
    let id = [];
    this.props.stickers.forEach((sticker) => id.push(sticker.id));

    this.setState({
      stickers: this.props.stickers,
      stickerPacks: this.props.stickerPacks,
      id: id,
    });
  }

  onReorder = (event, previousIndex, nextIndex, fromId, toId) => {
    this.setState({
      stickers: reorder(this.props.stickers, previousIndex, nextIndex),
    });
    let id = [];
    this.props.stickers.forEach((sticker) => id.push(sticker.id));
    this.setState({ id });
  };

  submitRearrangedList = () => {
    let stickers = {
      stickerIds: this.props.stickers.map((sticker) => sticker.id),
    };
    this.props.import(stickers, this.state.stickerPackId);
  };

  handleStickerPackChange = (stickerPack) => {
    this.setState({ stickerPackId: stickerPack.value });
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header className="bg-light" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Import Stickers
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Row>
            <Col md={6}>
              <h4>{this.props.stickers?.length} Sticker(s) selected</h4>
              <Row>
                <Reorder reorderId="reorder-list" disabled={true} onReorder={this.onReorder}>
                  {this.props.stickers.map((sticker, index) => {
                    return (
                      <div key={index} className="reorderContainer">
                        <Card className="m-3 responseCard">
                          <CardImg src={sticker.imageURL} />
                        </Card>
                      </div>
                    );
                  })}
                </Reorder>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col lg={8}>
                  <Select
                    className="react-select-container"
                    id="stickerPackSelect"
                    classNamePrefix="react-select"
                    name="stickerPackId"
                    placeholder="Select Sticker Pack"
                    onChange={this.handleStickerPackChange}
                    options={this.state.stickerPacks}
                  />
                </Col>
              </Row>
              <br />
              <h5 className="text-muted">Instructions:</h5>
              <ul>
                <li>
                  <small className="text-muted">
                    Choose a Sticker pack in which you want to add the
                    selected Stickers.
                  </small>
                </li>
                <li>
                  <small className="text-muted" >
                    <span style={{ color: "red", fontWeight: "bold" }}> {" "}
                      Note: The stickers will have same targeting rules even after importing to another pack, edit the stickers to add custom rules such that targeting rules are compatible for both the packs.
                    </span>
                  </small>
                </li>

                <li>
                  <small className="text-muted">
                    Click{" "}
                    <span style={{ color: "#000", fontWeight: 600 }}>
                      Submit
                    </span>{" "}
                    to add the selected Stickers to Sticker pack.
                  </small>
                </li>
              </ul>
            </Col>
          </Row>
          <Modal.Footer>
            <Button
              onClick={this.submitRearrangedList}
              disabled={!this.state.stickerPackId || this.props.stickers?.length == 0}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ImportStickers;

import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Button, Overlay, Tooltip } from "react-bootstrap";
import { tableColumns } from "./tableColumn";
import { ManageDeeplinks } from "./ManageDeeplinks";
import Select from "react-select";
import { clientOptions, showCreateDeeplinkButton } from "../../../config/deeplinks";

import filterFactory from "react-bootstrap-table2-filter";
import Loading from "../../../components/Loading";
import paginationFactory from "react-bootstrap-table2-paginator";
import Search from "../../../components/Search";
import BootstrapTable from "react-bootstrap-table-next";


import { error, errorDescription, redirectOnTokenExpiry, } from "../../../utilities/commonUtil";
import { Config } from "../../../config/UserConfig";
import { fetchDeeplinks } from "../../../utilities/apiUtils/deeplinks";

const Deeplinks = () => {
  const [loading, setLoading] = useState(true);
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [deeplinks, setDeeplinks] = useState([]);
  const [showCreateDeeplink, setShowCreateDeeplink] = useState(false);
  const [modalData, setModalData] = useState({});
  const [searchString, setSearchString] = useState("");
  const [clientFilter , setClientFilter] = useState("");
  const [filters, setFilters] = useState({});
  const [copyButtonText, setCopyButtonText] = useState("Copy to Clipboard");

  const [show, setShow] = useState(false);
  const target = useRef(null);

  let onTableChange = (type, newState) => {
    setFilters(newState.filters);
  };

  const unsecuredCopyToClipboard = (text) => { const textArea = document.createElement("textarea"); textArea.value = text; document.body.appendChild(textArea); textArea.focus(); textArea.select(); try { document.execCommand('copy') } catch (err) { console.error('Unable to copy to clipboard', err) } document.body.removeChild(textArea) };

  const copyToClipboard = (content) => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(content);
    } else {
      unsecuredCopyToClipboard(content);
    }
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <ul>
          <li>ID : {row.id !== null ? row.id : "null"}</li>
          <li>Description : {row.description !== null ? row.description : "null"}</li>
          <li>Client : {row.client !== null ? row.client : "null"}</li>
          <li>URL : {row.url !== null ? row.url : "null"}</li>
          <li>
            Created At : {row.createdAt !== null ? row.createdAt : "null"}
          </li>

          <Button id={row.id} key={"button" + row.id} ref={target} onClick={() => {
            copyToClipboard(row.url)
            setShow(!show)
            setTimeout(() => {
              setShow(false)
            }, 1000)
          }}>
            Copy to Clipboard
          </Button>
          <Overlay key={"overlay" + row.id} target={target.current} show={show} placement="right" id={row.id}>
            {(props) => (
              <Tooltip id="overlay-example" key={"tooltip" + row.id} {...props}>
                Copied!
              </Tooltip>
            )}
          </Overlay>
        </ul>
      </div>
    ),
  };


  const filterDeeplinks = () => {
    let filteredDeeplinks = deeplinks;

    if (searchString) {
      filteredDeeplinks = filteredDeeplinks.filter((item) => {
         return item.description.toLowerCase().includes(searchString.toLowerCase());
      });
    }

    if (clientFilter?.value) {
      filteredDeeplinks = filteredDeeplinks.filter((item) => {
        return item.client === clientFilter.value;
      });
    }
    return filteredDeeplinks;
  }


  const processDateTime = (data) => {
    // iterate data 
    data.deeplinks.forEach((item) => {
      // convert date time to local time and format it to yyyy-mm-dd , hour-mins-secs AM/PM format
      item.createdAt = new Date(item.createdAt).toLocaleString().replace(/\//g, "-");
    });

    return data;
  }

  const handleChange = (event) => {
    let value = event.target.value;
    setSearchString(value);
    if (!event.target.value.length) {
      fetchDeeplinksTableData();
    }
    event.preventDefault();
  };

  let closeCreateDeeplink = () => {
    setShowCreateDeeplink(false);
    setModalData({});
    fetchDeeplinksTableData();
  }

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  const onKeyDown = (event) => {
    if (event.keyCode === Config.KEYPRESS_DOWN) {
      fetchDeeplinksTableData();
    }
  };


  let fetchDeeplinksTableData = async () => {
    try {
      let response = await fetchDeeplinks();
      if (response?.deeplinks?.length) {
        response = processDateTime(response);
        setDeeplinks(response.deeplinks);
        setLoading(false);
      } else {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectOnTokenExpiry();
        let text = errorDescription(response.errorCode);
        setLoading(false);
        showErrorModal(text);
      }
    } catch (err) {
      setLoading(false);
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  };


  useEffect(() => {
    fetchDeeplinksTableData();
  }, []);

  return (
    <Container fluid className="p-0">
      <Row className="mb-3">
        <Col md={6} xs={6}>
          <h1>Deeplinks</h1>
        </Col>
        <Col md={6} xs={6}>
          <div className="p-0">

            {showCreateDeeplinkButton() && (
              <Button
                color="success"
                className="ml-auto d-flex btn-success"
                onClick={() => setShowCreateDeeplink(true)}
              >
                <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                  Add Deeplink
                </p>
              </Button>
            )}


            {showCreateDeeplink === true && (
              <ManageDeeplinks
                show={showCreateDeeplink}
                onHide={closeCreateDeeplink}
                action={"add"}
              />
            )}


          </div>
        </Col>
      </Row>
      <div>
        <Card style={{ overflow: "hidden", paddingBottom: "100px" }}>
          <div container-fullwidth="true" style={{ margin: "2%" }}>
            <Row
              className="mx-auto"
              style={{ display: "flex", justifyContent: "right" }}
            ></Row>
            <Row>
              <Col md={3} sm={12}>
                <Search
                  placeholder="Search description"
                  value={searchString}
                  onChange={(e) => handleChange(e)}
                  onKeyDown={onKeyDown}
                ></Search>
              </Col>

            <Col md={3} sm={12}>
              <Select
                  className="react-select-container"
                  id="clientFilter"
                  classNamePrefix="react-select"
                  name="clientFilter"
                  placeholder="Select Client"
                  value={clientFilter}
                  onChange={(event) => {
                    setClientFilter(event);
                  }}
                  options={clientOptions}
                />
              </Col>

            </Row>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <CardBody>
              <BootstrapTable
                bootstrap4={true}
                wrapperClasses="table-responsive"
                hover={true}
                bordered={false}
                keyField="id"
                data={filterDeeplinks()}
                columns={tableColumns}
                expandRow={expandRow}
                filter={filterFactory()}
                pagination={paginationFactory({
                  sizePerPage: 10,
                  sizePerPageList: [5, 10, 25, 50],
                })}
                defaultSorted={[{
                  dataField: 'id',
                  order: 'desc'
                }]}
                onTableChange={onTableChange}
                remote={{ filter: true }}
                noDataIndication="No results found"
              />
            </CardBody>
          )}
        </Card>
      </div>
    </Container>
  );
};

export default Deeplinks;

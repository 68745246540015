import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardImg,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Badge,
} from "reactstrap";
import { editSticker } from "../../../utilities/apiUtils/stickers";
import { error, errorDescription, redirectOnTokenExpiry, redirectTo, convertISTToUTC } from "../../../utilities/commonUtil";
import ConfirmationModal from "../../../pages/ui-elements/ConfirmationModal";
import ResponseModal from "../../../pages/ui-elements/ResponseModal";
import { fetchSticker } from "../../../utilities/apiUtils";
import CustomTooltip from "../../../components/customToolTip";


const StickerEdit = (props) => {
  const id = props.match.params.id;
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");
  const [startAt, setStartAt] = useState(null);
  const [endAt, setEndAt] = useState(null);
  const [isPremiumStickerTemplate, setIsPremiumStickerTemplate] = useState(false);
  const [isEventSticker, setIsEventSticker] = useState(false);
  const [sticker, setSticker] = useState({});

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);

    // if edit - redirect to home
    if (props.match.params.id) {
      redirectTo(`/stickers/${id}`);
    } else {
      redirectTo(`/stickers/${id}`);
    }
  };
  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  const convertUTCToIST = (utcDateStr) => {
    if (utcDateStr === null || utcDateStr === "") {
      return null;
    }

    // Create a Date object from the UTC string
    const date = new Date(utcDateStr);
  
    // IST is 5 hours 30 minutes ahead of UTC = 330 minutes in total
    const istOffsetMinutes = 330;
    
    // Get the timestamp in milliseconds and add the offset (in milliseconds)
    const istTimestamp = date.getTime() + istOffsetMinutes * 60000;
    
    // Create a new Date object with the adjusted time
    const istDate = new Date(istTimestamp);
  
    // Now, extract the IST components.
    // We use getUTC* methods because we already adjusted the timestamp.
    const year    = istDate.getUTCFullYear();
    const month   = String(istDate.getUTCMonth() + 1).padStart(2, '0'); // months are 0-indexed
    const day     = String(istDate.getUTCDate()).padStart(2, '0');
    const hours   = String(istDate.getUTCHours()).padStart(2, '0');
    const minutes = String(istDate.getUTCMinutes()).padStart(2, '0');
    const seconds = String(istDate.getUTCSeconds()).padStart(2, '0');
  
    // Construct the formatted string with the IST offset (+05:30)
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  const getStickerDetailsById = async (id) => {
    try {
      let response = await fetchSticker(id);
      if (response.sticker) {
        setStartAt(convertUTCToIST(response.sticker.startTime));
        setEndAt(convertUTCToIST(response.sticker.endTime));
        setIsPremiumStickerTemplate(response.sticker.isPremiumStickerTemplate);
        setIsEventSticker(response.sticker.isEventSticker);
        setSticker(response.sticker);
      }

    } catch (err) {
      let text = errorDescription(error.unexpectedError);
      showErrorModal(text);
    }
  };

  useEffect(() => {
    getStickerDetailsById(id);
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();

    // check startAt should be less than endAt
    if(startAt !== null && endAt !== null && startAt !== "" && endAt !== "" && startAt > endAt) {
      showErrorModal("Start At should be less than End At");
      return;
    }

    // convert datetime to ISO string
    let startTime = startAt !== "" && startAt !== null ? convertISTToUTC(startAt) : null;
    let endTime = endAt !== "" && endAt !== null ? convertISTToUTC(endAt) : null;

    const data = {
      "startTime": !isPremiumStickerTemplate ? null : startTime,
      "endTime": !isPremiumStickerTemplate ? null : endTime,
      "isPremiumStickerTemplate": isPremiumStickerTemplate,
      "isEventSticker": isEventSticker,
    };
    try {
      let response = await editSticker(id, data);
      if (response.status === "success") {
        setSuccessModalText("Sticker updated successfully");
        setSuccessModalDisplay(true);
      } else {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken ||
          response.errorCode === error.unauthorized
        )
          redirectOnTokenExpiry();
        let text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      }
    } catch (err) {
      let text = errorDescription(error.unexpectedError);
      showErrorModal(text);
    }

  };

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Edit Sticker</h1>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Container>
                <Row style={{ justifyContent: "space-between" }}>
                  <Col md={2} lg={3} className="d-block my-auto">
                    <CardImg
                      top
                      src={sticker.imageURL}
                      alt="Card image cap"
                    />
                  </Col>
                  <Col md={8} lg={7} className="d-block my-auto">
                    <Row>
                      <Col>
                        <p className="p-0 m-0">
                          {" "}
                          <span className="font-weight-bolder">
                            ID :
                          </span>{" "}
                          {sticker.id}
                        </p>

                        <p className="p-0 m-0">
                          {" "}
                          <span className="font-weight-bolder">
                            Start At :
                          </span>{" "}
                          {startAt}
                        </p>

                        <p className="p-0 m-0">
                          {" "}
                          <span className="font-weight-bolder">
                            End At :
                          </span>{" "}
                          {endAt}
                        </p>

                        <p className="p-0 m-0">
                          {" "}
                          <span className="font-weight-bolder">
                            Premium Sticker Template :
                          </span>{" "}
                          {isPremiumStickerTemplate ? (
                            <Badge color="success">Yes</Badge>
                          ) : (
                            <Badge color="danger">No</Badge>
                          )}
                        </p>
                        
                        <p className="p-0 m-0">
                          {" "}
                          <span className="font-weight-bolder">
                            Event Sticker :
                          </span>{" "}
                          {isEventSticker ? (
                            <Badge color="success">Yes</Badge>
                          ) : (
                            <Badge color="danger">No</Badge>
                          )}
                        </p>

                      </Col>

                    </Row>
                  </Col>
                </Row>
              </Container>
            </CardBody>


          </Card>
        </Col>

        <Col lg="12">
          <Card>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <Row className="pl-5 pr-5 pb-3">
                  <Col md={6}>
                    <div className="custom-switch custom-control mb-3">
                      <input
                        type="checkbox"
                        id="isPremiumStickerTemplate"
                        className="custom-control-input"
                        checked={isPremiumStickerTemplate}
                        onChange={() => setIsPremiumStickerTemplate(!isPremiumStickerTemplate)}
                      />
                      <Label
                        className="custom-control-label"
                        htmlFor="isPremiumStickerTemplate"
                      >
                        Premium Sticker Template
                      </Label>{" "}
                          <CustomTooltip
                            target="startAt-tooltip"
                            content={
                              <>
                                Marks if a sticker is a premium sticker or not.
                                Please mark it as premium if this sticker supports head generation else don't mark it as premium.
                              </>
                            }
                            placement="right"
                          />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="custom-switch custom-control mb-3">
                      <input
                        type="checkbox"
                        id="isEventSticker"
                        className="custom-control-input"
                        checked={isEventSticker}
                        onChange={() => setIsEventSticker(!isEventSticker)}
                      />
                      <Label
                        className="custom-control-label"
                        htmlFor="isEventSticker"
                      >
                        Event Sticker
                      </Label>
                    </div>
                  </Col>
                </Row>
                {isPremiumStickerTemplate && (
                  <>
                    <Row className="pl-5 pr-5 pb-3">
                      <Col md={6}>
                        <FormGroup>
                          <Label for="startAt">Start At</Label>{" "}
                          <CustomTooltip
                            target="startAt-tooltip"
                            content={
                              <>
                                Start At of the sticker. 
                                Please fill this field only if sticker is a premium sticker
                              </>
                            }
                            placement="right"
                          />
                          <Input
                            type="datetime-local"
                            id="startAt"
                            value={startAt}
                            onChange={(e) => setStartAt(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="endAt">End At</Label>{" "}
                          <CustomTooltip
                            target="endAt-tooltip"
                            content={
                              <>
                                End At of the sticker. 
                                Please fill this field only if sticker is a premium sticker
                              </>
                            }
                            placement="right"
                          />
                          <Input
                            type="datetime-local"
                            id="endAt"
                            value={endAt}
                            onChange={(e) => setEndAt(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </>
                )}
                <Row className="pl-5 pr-5 pb-3">
                      <Col>
                        <Button color="primary" type="submit">
                          Submit
                        </Button>
                      </Col>
                    </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={"submit"}
        onConfirmationTrue={() => {
          setConfirmationModalIsOpen(false);
          handleSubmit();
        }}
        onHide={() => setConfirmationModalIsOpen(false)}
      />
      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={"Success"}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={"Error"}
        modaltext={failureModalText}
      />
    </Container>
  );
};

export default StickerEdit;

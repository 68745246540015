import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Card, CardBody, Container, Row, Col, Badge } from "reactstrap";
import { Button } from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi"
import ResponseModal from "../ResponseModal";
import {
  successModalHeading,
  errorModalHeading,
} from "../../../config/config";
import Loading from "../../../components/Loading";
import {
  errorDescription,
  error,
  redirectOnTokenExpiry,
} from "../../../utilities/commonUtil";
import { createConfig, fetchConfigs, updateConfig } from "../../../utilities/apiUtils/configAPI";
import { processDateAndStatus, configsResponses, isConfigAPIEditPermission } from "../../../config/configConfigAPIDashboard";
import ConfigModal from "./modals/ConfigModal";

const formDataInitialState = {
  id: "",
  configUpdateInterval: "",
  appMetadataUrl: ""
}

const ConfigAPI = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState(formDataInitialState)
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [configs, setConfigs] = useState([]);
  const [successModalText, setSuccessModalText] = useState("");
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [changeType, setChangeType] = useState("Add")
  const [isConfigModalVisible, setIsConfigModalVisible] = useState(false)

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    setLoading(true)
    fetchConfigsTableData()
  };

  let showConfigModal = (type) => {
    setChangeType(type)
    setIsConfigModalVisible(true)
  }

  let hideConfigModal = () => {
    setIsConfigModalVisible(false)
    setFormData(formDataInitialState)
  }

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let fetchConfigsTableData = async () => {
    try {
      let response = await fetchConfigs();
      if (!response || response?.length === 0) {
        setConfigs([])
        setLoading(false)
      } else if (response?.length > 0) {
        response.forEach((item) => {
          item = processDateAndStatus(item);
        });
        setConfigs(response);
        setLoading(false);
      } else {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken ||
          response.errorCode === error.unauthorized
        )
          redirectOnTokenExpiry();
        let text = errorDescription(response.errorCode);
        setLoading(false);
        showErrorModal(text);
      }
    } catch (err) {
      setLoading(false);
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  };

  useEffect(() => {
    fetchConfigsTableData();
  }, []);

  const handleSubmit = async () => {
    const { id, appMetadataUrl, configUpdateInterval } = formData
    const payload = {
      configUpdateInterval: Number(configUpdateInterval),
      appMetadataUrl
    }
    try {
      let response
      if (changeType === "Add")
        response = await createConfig(payload)
      else
        response = await updateConfig(id, payload)
      if (response?.status === "success") {
        setSuccessModalText(changeType === "Add" ? configsResponses.addedSuccessfully : configsResponses.updatedSuccessfully);
        setSuccessModalDisplay(true);
      } else {
        let text
        if (response?.errorDescription)
          text = response?.errorDescription?.split("):")[1];
        else
          text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      }
    } catch (err) {
      let text = errorDescription(error.unexpectedError);
      showErrorModal(text);
    } finally {
      hideConfigModal()
    }
  }

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <Container fluid className="p-0">
      <div className="cursor-pointer mb-2" onClick={handleGoBack}>
        <BiArrowBack size={24} />
      </div>
      <Row>
        <h1 className="mb-4 mt-2 w-50 pl-3">Config API Dashboard</h1>

        <div className="d-flex align-items-center ml-auto">
          <Link
            className="d-block mb-4 pr-3 text-decoration-none"
            to={{
              pathname: `/ads/mediation-sdk/creative-types`
            }}
          >
            <Button color="success" className="d-flex btn-success">
              <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                Manage Creative Types
              </p>
            </Button>
          </Link>
          <Link
            className="d-block mb-4 pr-3 text-decoration-none"
            to={{
              pathname: `/ads/mediation-sdk/vendors`
            }}
          >
            <Button color="success" className="d-flex btn-success">
              <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                Manage Vendors
              </p>
            </Button>
          </Link>
          <Link
            className="d-block mb-4 pr-3 text-decoration-none"
            to={{
              pathname: `/ads/mediation-sdk/placement-vendors`
            }}
          >
            <Button color="success" className="d-flex btn-success">
              <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                Manage Placement To Vendors Mappings
              </p>
            </Button>
          </Link>
          <Link
            className="d-block mb-4 pr-3 text-decoration-none"
            to={{
              pathname: `/ads/mediation-sdk/mixing-strategy`
            }}
          >
            <Button color="success" className="d-flex btn-success">
              <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                Manage Mixing Strategy
              </p>
            </Button>
          </Link>
        </div>
      </Row>
      {loading ? (
        <Loading />
      ) : (
        <Row className="mt-4">
          {configs && configs?.map(config => (
            <Col lg={4} md={6} sm={12}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between mb-4">
                    <h3 className="mb-0 card-title">Config</h3>
                    {config.status === "Activated" ? (
                      <Badge className="badge-success">{config.status}</Badge>
                    ) : config.status === "Deactivated" ? (
                      <Badge className="badge-danger">{config.status}</Badge>
                    ) : (
                      <Badge className="badge-warning">{config.status}</Badge>
                    )}
                  </div>
                  <Row>
                    <Col lg={12} md={12}>
                      <span style={{ fontSize: 13 }}>Config Update Interval : {config?.configUpdateInterval || "null"}</span>
                    </Col>
                    <Col lg={12} md={12}>
                      <span style={{ fontSize: 13 }}>App Metadata URL : {config?.appMetadataUrl || "null"}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} md={12}>
                      {isConfigAPIEditPermission() && (
                        <Button
                          className="mt-3 px-3"
                          onClick={() => {
                            showConfigModal("Edit")
                            setFormData((prevState) => ({
                              ...prevState,
                              id: config.id,
                              configUpdateInterval: config.configUpdateInterval,
                              appMetadataUrl: config.appMetadataUrl
                            }))
                          }}
                        >
                          Edit
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      )
      }
      {(isConfigAPIEditPermission() && !loading && configs?.length <= 0) && (
        <div className="d-flex align-items-center">
          <Button color="success" className="d-flex btn-success mb-4 mr-3">
            <p className="mb-1 mt-1" style={{ fontSize: "1rem" }} onClick={() => showConfigModal("Add")}>
              Add New Config
            </p>
          </Button>
        </div>
      )}
      <ConfigModal
        show={isConfigModalVisible}
        type={changeType}
        formData={formData}
        setFormData={setFormData}
        onSubmit={handleSubmit}
        onHide={hideConfigModal}
      />
      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={successModalHeading}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={errorModalHeading}
        modaltext={failureModalText}
      />
    </Container>
  );
};

export default ConfigAPI;

import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Button, Col, Row, Container, Card, CardBody, Badge } from "reactstrap";
import ResetPasswordModal from "./components/ResetPasswordModal";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import {
  getUserDetails,
  resetUserPassword,
} from "../../../utilities/apiUtils/users";
import ResponseModal from "../ResponseModal";
import jwt_decode from "jwt-decode";
import Loading from "../../../components/Loading";
import {
  redirectionOnTokenExpiry,
} from "../../../utilities/commonUtil";
import { passwordResetSuccessText, success } from "../../../config/UserConfig";

const Profile = (props) => {
  const [userDetails, setUserDetails] = useState({});
  const [userId, setUserId] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");
  const [modalData, setModalData] = useState({
    oldPassword: "",
    Password: "",
    confirmedPassword: "",
    isPasswordVisible: false,
    isOldPasswordVisible: false,
  });

  const fetchData = useCallback(async () => {
    try {
      let token = retrieveFromLocalStorage("token");
      var decodedToken = jwt_decode(token);
      setUserId(decodedToken.ID);

      let userDetails = await getUserDetails(decodedToken.ID);
      if (userDetails.errorCode) {
        handleResponse(userDetails);
      }
      if (userDetails.roles === null) userDetails.roles = [];
      setUserDetails(userDetails);
      setLoading(false);
    } catch (err) {
      redirectionOnTokenExpiry();
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleResponse = (response) => {
    if (!response.errorCode) {
      setSuccessModalText(passwordResetSuccessText);
      setSuccessModalDisplay(true);
      return;
    }
    let text = response.errorDescription;
    if (response.errorCode === "invalidAccessToken") {
      redirectionOnTokenExpiry();
    }
    setFailureModalText(text);
    setFailureModalDisplay(true);
    setLoading(false);
  };

  const resetPassword = async (data) => {
    let response = await resetUserPassword(data);
    if (response.status === success) {
      let text = passwordResetSuccessText;
      closeResetPasswordModal();
      setSuccessModalDisplay(true);
      setSuccessModalText(text);
      setShowResetPasswordModal(false);
    } else {
      handleResponse(response);
    }
  };

  const failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  const successModalClose = () => {
    setSuccessModalDisplay(false);
  };

  const closeResetPasswordModal = () => {
    const modalData = {
      oldPassword: "",
      password: "",
      confirmedPassword: "",
      isPasswordVisible: false,
      isOldPasswordVisible: false,
    };
    setModalData(modalData);
    setShowResetPasswordModal(false);
  };

  return (
    <Fragment>
      {loading ? (
        <Loading />
      ) : (
        <Container>
          <div className="d-flex" style={{ maxWidth: "max-content" }}>
            <h1>User Profile</h1>
          </div>

          <Fragment>
            <Card className="mt-3 mb-5">
              <CardBody>
                <Row>
                  <Col md={6} xl={6}>
                    <Row>
                      <Col md={6} xl={6}>
                        <span>
                          <strong>ID:</strong>
                        </span>
                        <br />
                        <span>
                          <strong>Username:</strong>
                        </span>
                        <br />
                      </Col>
                      <Col md={5} xl={5}>
                        <span>{userDetails.id}</span>
                        <br />
                        <span>{userDetails.username}</span>
                        <br />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6} xl={6} className="mt-1">
                    <Row>
                      <Col md={4} xl={4}>
                        <span>
                          <strong>Created At:</strong>
                        </span>
                        <br />
                        <span>
                          <strong>Deactivated At:</strong>
                        </span>
                        <br />
                      </Col>
                      <Col md={5} xl={5}>
                        <span>
                          {userDetails.createdAt !== null
                            ? userDetails.createdAt
                            : "NULL"}
                        </span>
                        <br />
                        <span>
                          {userDetails.deactivatedAt !== null
                            ? userDetails.deactivatedAt
                            : "NULL"}
                        </span>
                        <br />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <span>
                      <strong>
                        Roles:
                        {userDetails.roles &&
                          userDetails.roles.map((role, index) => {
                            return (
                              <Badge
                                key={index}
                                className="badge-secondary ml-2"
                              >
                                {role.name}
                              </Badge>
                            );
                          })}
                      </strong>
                    </span>
                  </Col>
                </Row>

                <Row>
                  <div className="mt-4 ml-3">
                    <Button
                      color="primary"
                      className="btn-block"
                      onClick={() => {
                        setShowResetPasswordModal(true);
                      }}
                    >
                      Reset Password
                    </Button>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Fragment>

          <ResponseModal
            show={successModalDisplay}
            onHide={successModalClose}
            modalheading={"Success"}
            modaltext={successModalText}
          />
          <ResponseModal
            show={failureModalDisplay}
            onHide={failureModalClose}
            modalheading={"Error"}
            modaltext={failureModalText}
          />
          <ResetPasswordModal
            show={showResetPasswordModal}
            onHide={closeResetPasswordModal}
            data={modalData}
            userDetails={userDetails}
            resetPassword={resetPassword}
          />
        </Container>
      )}
    </Fragment>
  );
};

export default Profile;

import React, { useState, useEffect } from "react";
import ResponseModal from "../ResponseModal";
import DateTimePicker from 'react-datetime-picker';
import Select from 'react-select';
import {
    errorDescription,
    validateConfigForm,
    valueTypes,
    appIDs,
    error,
    redirectOnTokenExpiry,
    redirectTo,
    onAppVersionInput,
    validateInteger,
    validateJsonValue
} from "../../../utilities/commonUtil";
import { createPromotedTags } from "../../../utilities/apiUtils/cre";
import TagsInput from "react-tagsinput";
import Routes from "../../../routes/index";
import {
    Button,
    Card,
    CardBody,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Container,
    CustomInput,
} from "reactstrap";
import {
    successModalHeading,
    errorModalHeading,
} from "../../../config/config";

const CreatePromotedTagForm = (props) => {
    const [form, setForm] = useState({
        title: "",
        contentTypes: [],
        tags: [],
        priority: { value: 1, valid: true },
        boostHours: "",
        startTime: Math.round((new Date()).getTime() / 1000).toString(),
        endTime: Math.round((new Date()).getTime() / 1000).toString(),
        enableTag: false,
    });

    const [lastStartTimestamp, setLastStartTimestamp] = useState(new Date());
    const [lastEndTimestamp, setLastEndTimestamp] = useState(new Date());
    const [displayError, setDisplayError] = useState(false);
    const [selectedContent, setSelectedContent] = useState([]);

    const allContentTypes = [
        { name: "Sticker", value: "sticker" },
        { name: "Animated Sticker", value: "animatedSticker" },
        { name: "Movie GIF", value: "movieGif" },
        { name: "Pop Text", value: "popTextStyle" },
        { name: "Emoji Sticker", value: "emojiSticker" },
    ];

    const [successModal, setSuccessModal] = useState({
        display: false,
        text: "",
    });
    const [failureModal, setFailureModal] = useState({
        display: false,
        text: "",
    });

    let handleChange = (event) => {
        setForm({ ...form, [event.target.name]: event.target.value });
    };

    let successModalClose = () => {
        setSuccessModal({ ...successModal, display: false });
        redirectTo("/cre/promoted-tags");
    };

    let failureModalClose = () => {
        setFailureModal({ ...failureModal, display: false });
    };

    const handlePriorityChange = (event) => {
        const { name, value } = event.target;
        setForm((prev) => ({
            ...prev,
            [name]: {
                value: value,
                valid: validateInteger(value) && parseInt(value) !== 0,
            },
        }));
        setDisplayError(false);
    };

    const handleContentTypesChange = (selectedContent) => {
        let contentType = [];
        if (selectedContent !== null) {
            selectedContent.forEach((content) => {
                contentType.push(content.value);
            });
        }
        setForm({ ...form, contentTypes: contentType });
    };

    const handleTags = (tags) => {
        setForm((prev) => ({
            ...prev,
            ["tags"]: tags,
        }));
        setDisplayError(false);
    };

    const handleStartTime = (obj) => {
        setLastStartTimestamp(obj)
        setForm({ ...form, startTime: Math.round(obj.getTime() / 1000).toString() });
    }

    const handleEndTime = (obj) => {
        setLastEndTimestamp(obj)
        setForm({ ...form, endTime: Math.round(obj.getTime() / 1000).toString() });
    }

    const handleCheckBoxChange = (event) => {
        const { id, checked } = event.target;
        setForm((prev) => ({
            ...prev,
            [id]: checked,
        }));

        setDisplayError(false);
    };

    let handleSubmit = async (event) => {
        event.preventDefault();
        if (form.contentTypes.length > 0) {
            try {
                let content = [];
                form.contentTypes.map((c) => {
                    content.push({ contentType: c });
                })
                let tagsArray = [];
                form.tags.map((tag) => {
                    tagsArray.push({ text: tag });
                })

                let newTag = {
                    name: form.title,
                    contentTypes: content,
                    priority: parseInt(form.priority.value),
                    boostHours: form.boostHours,
                    startTime: form.startTime,
                    endTime: form.endTime,
                    enabled: form.enableTag,
                    tags: tagsArray
                }
                let response = await createPromotedTags(newTag);
                if (response.status === "contentPromotedTagCreated") {
                    setSuccessModal({ display: true, text: response.description });
                } else {
                    if (
                        response.errorCode === error.tokenExpired ||
                        response.errorCode === error.invalidAccessToken
                    )
                        redirectOnTokenExpiry();
                    setFailureModal({
                        display: true,
                        text: errorDescription(response.errorCode),
                    });
                }
            }
            catch (error) {
                setFailureModal({
                    display: true,
                    text: errorDescription(error.unexpectedError),
                });
            }
        }
        else {
            setDisplayError(true)
        }
    };

    return (
        <Container fluid className="p-0">
            <Row className="mb-3">
                <Col md={6} xs={4}>
                    <h1>Create Promoted Tag</h1>
                </Col>
            </Row>
            <Card>
                <CardBody>
                    <Form onSubmit={handleSubmit}>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>
                                        Tag Set Name<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        type="text"
                                        name="title"
                                        value={form.title}
                                        placeholder="Tag Set Name"
                                        onChange={handleChange}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>
                                        <strong>
                                            Priority<span className="text-danger">*</span>
                                            <small className="text-info">
                                                (Enter integer value greater than 0)
                                            </small>
                                        </strong>
                                    </Label>
                                    <Input
                                        type="number"
                                        name="priority"
                                        defaultValue={form.priority.value}
                                        className={
                                            form.priority.valid
                                                ? "form-control is-valid"
                                                : "form-control is-invalid"
                                        }
                                        onChange={(event) => handlePriorityChange(event)}
                                        disabled={false}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: 15 }}>
                            <Col md={6}>
                                <Label>
                                    <strong>
                                        Start Time<span className="text-danger">*</span>
                                    </strong>
                                </Label>
                                <br></br>
                                <DateTimePicker
                                    amPmAriaLabel="Select AM/PM"
                                    calendarAriaLabel="Toggle calendar"
                                    dayAriaLabel="Day"
                                    hourAriaLabel="Hour"
                                    maxDetail="second"
                                    minuteAriaLabel="Minute"
                                    monthAriaLabel="Month"
                                    nativeInputAriaLabel="Date and time"
                                    onChange={(obj) => handleStartTime(obj)}
                                    secondAriaLabel="Second"
                                    value={typeof lastStartTimestamp === "string" ? new Date(lastStartTimestamp) : lastStartTimestamp}
                                    yearAriaLabel="Year"
                                    required
                                />
                            </Col>
                            <Col md={6}>
                                <Label>
                                    <strong>
                                        End Time<span className="text-danger">*</span>
                                    </strong>
                                </Label>
                                <br></br>
                                <DateTimePicker
                                    amPmAriaLabel="Select AM/PM"
                                    calendarAriaLabel="Toggle calendar"
                                    dayAriaLabel="Day"
                                    hourAriaLabel="Hour"
                                    maxDetail="second"
                                    minuteAriaLabel="Minute"
                                    monthAriaLabel="Month"
                                    nativeInputAriaLabel="Date and time"
                                    onChange={(obj) => handleEndTime(obj)}
                                    secondAriaLabel="Second"
                                    value={typeof lastEndTimestamp === "string" ? new Date(lastEndTimestamp) : lastEndTimestamp}
                                    yearAriaLabel="Year"
                                    required
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>
                                        <strong>
                                            Boost Hours<span className="text-danger">*</span>
                                            <small className="text-info">
                                                (Enter a comma separated string)
                                            </small>
                                        </strong>
                                    </Label>
                                    <Input
                                        type="text"
                                        name="boostHours"
                                        defaultValue={form.boostHours}
                                        onChange={(event) => handleChange(event)}
                                        disabled={false}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <FormGroup>
                                    <Label>Content Types<span className="text-danger">*</span>
                                        {displayError ? (
                                            <small className="text-danger mb-0">
                                                Please select a Content Type.
                                            </small>
                                        ) : null}
                                    </Label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        name="Content Types"
                                        defaultValue={selectedContent}
                                        onChange={handleContentTypesChange}
                                        options={allContentTypes}
                                        getOptionLabel={(options) => options['name']}
                                        getOptionValue={(options) => options['value']}
                                        isMulti
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <FormGroup>
                                    <Label>
                                        <strong>Tags</strong>
                                    </Label>
                                    <TagsInput
                                        value={form.tags}
                                        onChange={(tags) => handleTags(tags)}
                                        disabled={false}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <strong>Enable Tag<span className="text-danger">*</span></strong>
                            </Col>
                            <Col md={3}>
                                <div className="custom-control custom-switch">
                                    <CustomInput
                                        type="checkbox"
                                        id="enableTag"
                                        value={form.enableTag === true}
                                        onChange={(event) => handleCheckBoxChange(event)}
                                        checked={form.enableTag === true}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Button color="primary" style={{ marginTop: 20 }}>Submit</Button>
                    </Form>
                    <ResponseModal
                        show={successModal.display}
                        onHide={successModalClose}
                        modalheading={successModalHeading}
                        modaltext={"Content Promoted Tag Successfully created"}
                    />
                    <ResponseModal
                        show={failureModal.display}
                        onHide={failureModalClose}
                        modalheading={errorModalHeading}
                        modaltext={failureModal.text}
                    />
                </CardBody>
            </Card>
        </Container>
    );
};

export default CreatePromotedTagForm;

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody, Container, Row, Col, Badge } from "reactstrap";
import { Button } from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi"
import { vendorsTableColumns } from "./tableColumn";
import Select from "react-select";
import ConfirmationModal from "../ConfirmationModal";
import ResponseModal from "../ResponseModal";
import {
  successModalHeading,
  errorModalHeading,
} from "../../../config/config";
import filterFactory from "react-bootstrap-table2-filter";
import Loading from "../../../components/Loading";
import paginationFactory from "react-bootstrap-table2-paginator";
import Search from "../../../components/Search";
import BootstrapTable from "react-bootstrap-table-next";
import {
  errorDescription,
  error,
  redirectOnTokenExpiry,
} from "../../../utilities/commonUtil";
import { activateVendor, createVendor, deactivateVendor, deleteVendor, fetchVendors, updateVendor } from "../../../utilities/apiUtils/configAPI";
import { processDateAndStatus, statusOptions, allActions, vendorsResponses, isConfigAPIEditPermission, isConfigAPIActivatePermission, isConfigAPIDeactivatePermission, isConfigAPIDeletePermission, isConfigAPICreatePermission } from "../../../config/configConfigAPIDashboard";
import VendorModal from "./modals/VendorModal";

const formDataInitialState = {
  id: "",
  name: "",
  enableTrackerApi: true,
  categoryMappingUrl: ""
}

const ManageVendors = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [statusFilter, setStatusFilter] = useState("");
  const [formData, setFormData] = useState(formDataInitialState)
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [action, setAction] = useState("");
  const [id, setId] = useState(null);
  const [successModalText, setSuccessModalText] = useState("");
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [changeType, setChangeType] = useState("Add")
  const [isVendorModalVisible, setIsVendorModalVisible] = useState(false)

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    setLoading(true)
    fetchVendorsTableData()
  };

  let showVendorModal = (type) => {
    setChangeType(type)
    setIsVendorModalVisible(true)
  }

  let hideVendorModal = () => {
    setIsVendorModalVisible(false)
    setFormData(formDataInitialState)
  }

  let onTableChange = (type, newState) => { };

  let disableDeactivateButton = (row) => {
    return row.status === "Deactivated";
  };

  let disableActivateButton = (row) => {
    return row.status === "Activated";
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  const handleClick = () => {
    setStatusFilter("");
    setNameFilter("");
    sessionStorage.removeItem("vendorsFilterStatus");
    sessionStorage.removeItem("vendorsFilterName")
  };

  const ClearButton = () => {
    return (
      <Button
        style={{ float: "right" }}
        className="form-control-lg"
        onClick={handleClick}
      >
        Reset Filters
      </Button>
    );
  };

  let setConfirmationModalState = (id, event) => {
    setAction(event);
    setConfirmationModalIsOpen(true);
    setId(id);
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        {isConfigAPIEditPermission() && (
          <Button
            className="mx-3"
            onClick={() => {
              showVendorModal("Edit")
              setFormData((prevState) => ({
                ...prevState,
                id: row.id,
                name: row.name,
                enableTrackerApi: row.enableTrackerApi,
                categoryMappingUrl: row.categoryMappingUrl
              }))
            }}
          >
            Edit
          </Button>
        )}

        {isConfigAPIActivatePermission() && (
          <Button
            className="mx-3 btn-success"
            disabled={disableActivateButton(row)}
            onClick={(event) =>
              setConfirmationModalState(row.id, event.target.innerHTML)
            }
          >
            Activate
          </Button>
        )}

        {isConfigAPIDeactivatePermission() && (
          <Button
            className="mx-3 btn-secondary"
            disabled={disableDeactivateButton(row)}
            onClick={(event) =>
              setConfirmationModalState(row.id, event.target.innerHTML)
            }
          >
            Deactivate
          </Button>
        )}

        {isConfigAPIDeletePermission() && (
          <Button
            className="mx-3 btn-danger"
            onClick={(event) =>
              setConfirmationModalState(row.id, event.target.innerHTML)
            }
          >
            Delete
          </Button>
        )}

        {!(isConfigAPIEditPermission() || isConfigAPIActivatePermission() || isConfigAPIDeactivatePermission() || isConfigAPIDeletePermission()) && (
          <center className="text-info" style={{ fontSize: 13 }}>You do not have permissions to perform any action.</center>
        )}
      </div>
    ),
  };

  let activateRecord = async (id) => {
    try {
      let response = await activateVendor(id);
      if (response.status === "success") {
        let text = "Vendor has been activated";
        showSuccessModal(text);
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  };

  let deactivateRecord = async (id) => {
    try {
      let response = await deactivateVendor(id);
      if (response.status === "success") {
        let text = "Vendor has been deactivated";
        showSuccessModal(text);
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  };

  let deleteRecord = async (id) => {
    try {
      let response = await deleteVendor(id);
      if (response.status === "success") {
        let text = "Vendor has been deleted";
        showSuccessModal(text);
      } else {
        let text
        if (response?.errorDescription)
          text = response?.errorDescription?.split("):")[1];
        else
          text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  };

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let fetchVendorsTableData = async () => {
    try {
      let response = await fetchVendors();
      if (!response || response?.length === 0) {
        setVendors([])
        setLoading(false)
      } else if (response?.length > 0) {
        response.forEach((item) => {
          item = processDateAndStatus(item);
        });
        setVendors(response);
        setLoading(false);
      } else {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken ||
          response.errorCode === error.unauthorized
        )
          redirectOnTokenExpiry();
        let text = errorDescription(error.unexpectedError);
        setLoading(false);
        showErrorModal(text);
      }
    } catch (err) {
      setLoading(false);
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  };

  const filterVendors = () => {
    let items = vendors;
    if (statusFilter.value)
      items = items.filter((item) => item.status === statusFilter.value);

    if (nameFilter)
      items = items.filter((item) =>
        item.name?.toLowerCase().includes(nameFilter.toLowerCase())
      );

    return items;
  };

  useEffect(() => {
    fetchVendorsTableData();

    // Fill stored filter values
    if (sessionStorage.getItem("vendorsFilterName"))
      setNameFilter(sessionStorage.getItem("vendorsFilterName"))
    if (sessionStorage.getItem("vendorsFilterStatus"))
      setStatusFilter({
        value: sessionStorage.getItem("vendorsFilterStatus") ?? "",
        label: statusOptions.find(option => option.value === sessionStorage.getItem("vendorsFilterStatus"))?.label ?? "",
      })
  }, []);

  const handleSubmit = async () => {
    const { id, name, enableTrackerApi, categoryMappingUrl } = formData
    const payload = {
      name,
      enableTrackerApi: name === "bobble-adx" ? true : enableTrackerApi,
      categoryMappingUrl
    }
    try {
      let response
      if (changeType === "Add")
        response = await createVendor(payload)
      else
        response = await updateVendor(id, payload)
      if (response?.status === "success") {
        setSuccessModalText(changeType === "Add" ? vendorsResponses.addedSuccessfully : vendorsResponses.updatedSuccessfully);
        setSuccessModalDisplay(true);
      } else {
        let text
        if (response?.errorDescription)
          text = response?.errorDescription?.split("):")[1];
        else
          text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      }
    } catch (err) {
      let text = errorDescription(error.unexpectedError);
      showErrorModal(text);
    } finally {
      hideVendorModal()
    }
  }

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <Container fluid className="p-0">
      <div className="cursor-pointer mb-2" onClick={handleGoBack}>
        <BiArrowBack size={24} />
      </div>
      <Row>
        <h1 className="mb-4 mt-2 w-50 pl-3">Vendors</h1>

        {isConfigAPICreatePermission() && (
          <div className="d-flex align-items-center ml-auto">
            <Button color="success" className="d-flex btn-success mb-4 mr-3">
              <p className="mb-1 mt-1" style={{ fontSize: "1rem" }} onClick={() => showVendorModal("Add")}>
                Add New Vendor
              </p>
            </Button>
          </div>
        )}
      </Row>
      <div>
        <Card style={{ overflow: "hidden", paddingBottom: "100px" }}>
          <div container-fullwidth="true" style={{ margin: "2%" }}>
            <Row className="justify-content-md-center mx-auto mb-4">
              <Col md={6} sm={12} className="mt-3">
                <Select
                  className="react-select-container"
                  id="statusSelect"
                  classNamePrefix="react-select"
                  name="status"
                  placeholder="Select Status"
                  value={statusFilter}
                  onChange={(event) => {
                    setStatusFilter(event);
                    sessionStorage.setItem("vendorsFilterStatus", event.value);
                  }}
                  options={statusOptions}
                />
              </Col>
              <Col md={6} xs={5} className="p-0 mt-3">
                <ClearButton>Reset Filters</ClearButton>
              </Col>
            </Row>

            <Row className="justify-content-md-center mx-auto">
              <Col md={12} xs={12}>
                <Search
                  placeholder="Search Name"
                  value={nameFilter}
                  onChange={(e) => {
                    setNameFilter(e.target.value)
                    sessionStorage.setItem("vendorsFilterName", e.target.value)
                  }}
                ></Search>
              </Col>
            </Row>

            <Row className="justify-content-md-center mx-auto">
              <Col md={12} sm={12} className="mt-3">
                <Badge
                  className="badge-success"
                  style={{
                    width: "1rem",
                    height: "1rem",
                    verticalAlign: "middle",
                  }}
                >
                  {" "}
                </Badge>{" "}
                : Status Activated
              </Col>
              <Col md={12} sm={12} className="mt-3">
                <Badge
                  className="badge-danger"
                  style={{
                    width: "1rem",
                    height: "1rem",
                    verticalAlign: "middle",
                  }}
                >
                  {" "}
                </Badge>{" "}
                : Status Deactivated
              </Col>
            </Row>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <CardBody>
              <BootstrapTable
                bootstrap4={true}
                wrapperClasses="table-responsive"
                hover={true}
                bordered={false}
                keyField="id"
                data={filterVendors()}
                columns={vendorsTableColumns}
                expandRow={expandRow}
                filter={filterFactory()}
                pagination={paginationFactory({
                  sizePerPage: 10,
                  sizePerPageList: [5, 10, 25, 50],
                })}
                defaultSorted={[{
                  dataField: 'id',
                  order: 'desc'
                }]}
                onTableChange={onTableChange}
                remote={{ filter: true }}
                noDataIndication="No results found"
              />
            </CardBody>
          )}
        </Card>
      </div>
      <VendorModal
        show={isVendorModalVisible}
        type={changeType}
        formData={formData}
        setFormData={setFormData}
        onSubmit={handleSubmit}
        onHide={hideVendorModal}
      />
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={action}
        onConfirmationTrue={() => {
          setConfirmationModalIsOpen(false);
          if (action === allActions.activate) activateRecord(id)
          else if (action === allActions.deactivate) deactivateRecord(id)
          else if (action === allActions.delete) deleteRecord(id)
        }}
        onHide={() => setConfirmationModalIsOpen(false)}
      />
      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={successModalHeading}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={errorModalHeading}
        modaltext={failureModalText}
      />
    </Container>
  );
};

export default ManageVendors;
import React, { useEffect, useState } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  Button,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import Reorder, { reorder } from "react-reorder";

import ConfirmationModal from "../ConfirmationModal";
import ResponseModal from "../ResponseModal";
import Search from "../../../components/Search";

import {
  saveQuickRepliesReordering,
  getQuickReplyCategoriesForForm,
  getQuickReplies,
  getQuickRepliesCTATexts,
  getQuickRepliesAssets,
  updateQuickReplyCTAAssets
} from "../../../utilities/apiUtils/quickReplies";

import { errorDescription, error } from "../../../utilities/commonUtil";
import {defaultQuickReplySearchStatus} from "../../../config/quickReplies";

const QuickReplyCTAAssetsBulkUpdate = () => {
  const [quickReplies, setQuickReplies] = useState([]);
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [action, setAction] = useState("");
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(null);
  const [selectedQuickReplies, setSelectedQuickReplies] = useState([]);
  const [ctaSearchString, setCtaSearchString] = useState("");
  const [commonlyUsedCTATexts, setCommonlyUsedCTATexts] = useState([]);
  const commonlyUsedCTATextsOptions = Array.isArray(commonlyUsedCTATexts)
    ? commonlyUsedCTATexts?.map((text) => ({
        label: text.toUpperCase(),
        value: text,
      }))
    : [];
  const [quickRepliesAssetsOptions, setQuickRepliesAssetsOptions] = useState(
    []
  );
  const [lightThemeIconImage, setLightThemeIconImage] = useState({
    file: null,
    name: null,
    url: null,
  });
  const [
    enableLightThemeIconImageDropdown,
    setEnableLightThemeIconImageDropdown,
  ] = useState(false);
  const [darkThemeIconImage, setDarkThemeIconImage] = useState({
    file: null,
    name: null,
    url: null,
  });
  const [
    enableDarkThemeIconImageDropdown,
    setEnableDarkThemeIconImageDropdown,
  ] = useState(false);

  const [darkThemeBackGroundImage, setDarkThemeBackGroundImage] = useState({
    file: null,
    name: null,
    url: null,
  });

  const [enableLightThemeBackGroundImageDropdown, setEnableLightThemeBackGroundImageDropdown] = useState(false)

  const [lightThemeBackGroundImage, setLightThemeBackGroundImage] = useState({
    file: null,
    name: null,
    url: null,
  });

  const [enableDarkThemeBackGroundImageDropdown, setEnableDarkThemeBackGroundImageDropdown] = useState(false)

  useEffect(() => {
    if (category !== null) {
      let params = {
        categoryID: category,
        type: "card",
        status: defaultQuickReplySearchStatus,
        ctaSearchString: ctaSearchString,
      };
      getQuickReplyList(params);
    }
  }, [category, ctaSearchString]);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    fetchQuickRepliesCTATexts();
  }, []);

  const fetchQuickRepliesCTATexts = async () => {
    const response = await getQuickRepliesCTATexts();
    if (response) {
      if (response?.ctaTexts !== null) {
        setCommonlyUsedCTATexts(response?.ctaTexts);
      } else {
        setCommonlyUsedCTATexts([]);
      }
    }
  };

  useEffect(() => {
    const getQuickRepliesAssetsData = async () => {
      const response = await getQuickRepliesAssets();
      if (response) {
        const quickRepliesAssetsOptions = response?.assets?.map((item) => {
          return {
            value: item?.imageFilename,
            label: (
              <div>
                <img
                  src={item?.imageURL}
                  height="30px"
                  width={item?.type === "icon" ? "30" : "70"}
                  alt={item?.imageIdentifier}
                />{" "}
                {item?.imageIdentifier}
              </div>
            ),
            url: item?.imageURL,
            theme: item?.theme,
            type: item?.type,
          };
        });
        setQuickRepliesAssetsOptions(quickRepliesAssetsOptions);
      }
    };
    getQuickRepliesAssetsData();
  }, []);

  const getQuickReplyList = async (params) => {
    setQuickReplies([]);
    const response = await getQuickReplies(params);
    if (response) {
      if (response?.quickReplies !== null) {
        setQuickReplies(response.quickReplies);
      }
    }
  };

  useEffect(() => {
    setSelectedQuickReplies([]);
  }, [quickReplies]);

  let setConfirmationModalState = (event) => {
    event.preventDefault();
    setAction("Submit");
    setConfirmationModalIsOpen(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    window.location.reload();
  };

  const truncateText = (text, maxWords) => {
    const words = text.split(" ");
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(" ") + "...";
    }
    return text;
  };

  const getCategories = async () => {
    try {
      let categories = await getQuickReplyCategoriesForForm();
      setCategories(categories);
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const handleCategoryChange = (category) => {
    setCategory(category.value);
  };

  const handleCheckboxChange = (event, quickReply) => {
    if (event?.target?.checked) {
      setSelectedQuickReplies([...selectedQuickReplies, quickReply]);
    } else {
      setSelectedQuickReplies(
        selectedQuickReplies.filter(
          (quickReplyObj) => quickReplyObj !== quickReply
        )
      );
    }
  };

  const handleCTASearchStringChange = (event) => {
    setCtaSearchString(event?.target?.value);
  };

  const onKeyDown = (event) => {
    // if enter key is pressed
    if (event.keyCode === 13) {
      search();
    }
  };

  const search = () => {
    if (category === null) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: "Please select a category",
      });
      return;
    }

    let params = {
      categoryID: category,
      type: "card",
      status: defaultQuickReplySearchStatus,
      ctaSearchString: ctaSearchString,
    };
    getQuickReplyList(params);
  };

  const handleCTAOptionChange = (event) => {
    if (category === null) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: "Please select a category",
      });
      return;
    }
    setCtaSearchString(event?.value);
    let params = {
      categoryID: category,
      type: "card",
      status: defaultQuickReplySearchStatus,
      ctaSearchString: event?.value,
    };
    getQuickReplyList(params);
  };

  const updateCTAAssets = () =>{
    let ctaIds = []
    selectedQuickReplies.map((quickReply)=>{
      ctaIds.push(quickReply?.ctaItems?.[0]?.id)
    })


    let payload = {
      ctaIds: ctaIds,
      darkThemeBackgroundImage: darkThemeBackGroundImage?.file,
      lightThemeBackGroundImage: lightThemeBackGroundImage?.file,
      lightThemeBackgroundImageFilename: lightThemeBackGroundImage?.name,
      darkThemeBackgroundImageFilename: darkThemeBackGroundImage?.name,
      lightThemeIconImage: lightThemeIconImage?.file,
      darkThemeIconImage: darkThemeIconImage?.file,
      lightThemeIconImageFilename: lightThemeIconImage?.name,
      darkThemeIconImageFilename: darkThemeIconImage?.name,
    }

    let res = updateQuickReplyCTAAssets(payload)
    if (res?.errorCode){
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: res.errorDescription
      })
    } else{
      setSuccessModalText(res?.description?.length > 0 ? res?.description : "Assets Updated Successfully");
      setSuccessModalDisplay(true);
      setSuccessModalDisplay(true)
    }
  }


  const handleSubmit = () => {
    if (ctaSearchString === ""){
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: "Please enter or choose CTA Text to chose the CTA to be updated"
      })
      return
    }
    updateCTAAssets()
  }
  return (
    <Container fluid className="p-0">
      <Row>
        <Col md={6}>
          <h1 className="h3 mb-3">Bulk Update CTA Assets for Quick Replies</h1>
        </Col>
      </Row>

      <Row>
        <Col md={3} sm={12}>
          <Label>
            <strong>Category ID</strong><span className="text-danger">*</span>
          </Label>
          <Select
            className="react-select-container mb-4"
            classNamePrefix="react-select"
            placeholder="Select category"
            onChange={(option) => handleCategoryChange(option)}
            options={categories}
          />
        </Col>
        <Col md={3} sm={12}>
        <Label>
            <strong>Search By CTA Text</strong><span className="text-danger">*</span>
          </Label>
          <Search
            value={ctaSearchString}
            onChange={handleCTASearchStringChange}
            onKeyDown={onKeyDown}
            placeholder={"Enter CTA Text"}
          ></Search>
        </Col>
        <Col md={3} sm={12}>
        <Label>
            <strong>Search By Most Common CTA Texts</strong><span className="text-danger">*</span>
          </Label>
          <Select
            className="react-select-container mb-4"
            classNamePrefix="react-select"
            placeholder="Select CTA Text"
            onChange={(option) => handleCTAOptionChange(option)}
            options={commonlyUsedCTATextsOptions}
            isClearable={true}
          />
        </Col>
        <Col md={3}>
          <Button
            color="primary"
            className="float-right mb-3 btn-lg"
            onClick={() => search()}
          >
            Search
          </Button>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>
              <strong>
                Light Theme Icon Image <span className="text-danger">*</span>
                <p style={{ fontSize: 12, marginBottom: 0 }}>
                  The image should be jpg/png/gif/webp
                </p>
              </strong>
            </Label>
            <Button
              className="float-right btn-sm"
              onClick={(e) => {
                setEnableLightThemeIconImageDropdown(
                  !enableLightThemeIconImageDropdown
                );
              }}
              disabled={false}
            >
              {enableLightThemeIconImageDropdown
                ? "Upload new image"
                : "Select from Uploaded Images"}
            </Button>

            <br />

            {!enableLightThemeIconImageDropdown && (
              <Container className="custom-file">
                <Input
                  type="file"
                  className="custom-file-input"
                  accept="image/*"
                  disabled={false}
                  onChange={(event) => {
                    const file = event?.target?.files[0];
                    const reader = new FileReader();

                    reader.onloadend = () => {
                      setLightThemeIconImage({
                        ...lightThemeIconImage,
                        file: reader?.result,
                      });
                    };
                    reader.readAsDataURL(file);
                  }}
                />
                <Label className="custom-file-label">
                  <strong>
                    {lightThemeIconImage?.name === null
                      ? "Choose an image"
                      : lightThemeIconImage?.name}
                  </strong>
                </Label>
              </Container>
            )}

            {enableLightThemeIconImageDropdown && (
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                name="lightThemeIconImage"
                value={quickRepliesAssetsOptions.filter(
                  (item) => item?.value === lightThemeIconImage?.name
                )}
                placeholder="Select Uploaded Images"
                isClearable={false}
                onChange={(option) => {
                  setLightThemeIconImage({
                    ...lightThemeIconImage,
                    name: option?.value,
                    url: option?.url,
                  });
                }}
                isDisabled={false}
                options={quickRepliesAssetsOptions.filter(
                  (item) => item?.theme === "light" && item?.type === "icon"
                )}
              />
            )}

            {(lightThemeIconImage?.file || lightThemeIconImage?.url) && (
              <Col className="pb-4 mb-3 mt-3">
                <img
                  src={lightThemeIconImage?.file || lightThemeIconImage?.url}
                  alt="preview"
                  width="100"
                />
              </Col>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>
              <strong>
                Dark Theme Icon Image <span className="text-danger">*</span>
                <p style={{ fontSize: 12, marginBottom: 0 }}>
                  The image should be jpg/png/gif/webp
                </p>
              </strong>
            </Label>
            <Button
              className="float-right btn-sm"
              onClick={(e) => {
                setEnableDarkThemeIconImageDropdown(
                  !enableDarkThemeIconImageDropdown
                );
              }}
              disabled={false}
            >
              {enableDarkThemeIconImageDropdown
                ? "Upload new image"
                : "Select from Uploaded Images"}
            </Button>

            <br />

            {!enableDarkThemeIconImageDropdown && (
              <Container className="custom-file">
                <Input
                  type="file"
                  className="custom-file-input"
                  accept="image/*"
                  disabled={false}
                  onChange={(event) => {
                    const file = event.target.files[0];
                    const reader = new FileReader();

                    reader.onloadend = () => {
                      setDarkThemeIconImage({
                        ...darkThemeIconImage,
                        file: reader.result,
                      });
                    };
                    reader.readAsDataURL(file);
                  }}
                />
                <Label className="custom-file-label">
                  <strong>
                    {darkThemeIconImage?.name === null
                      ? "Choose an image"
                      : darkThemeIconImage?.name}
                  </strong>
                </Label>
              </Container>
            )}

            {enableDarkThemeIconImageDropdown && (
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                name="darkThemeIconImage"
                value={quickRepliesAssetsOptions.filter(
                  (item) => item?.value === darkThemeIconImage?.name
                )}
                placeholder="Select Uploaded Images"
                isClearable={false}
                onChange={(option) => {
                  setDarkThemeIconImage({
                    ...darkThemeIconImage,
                    name: option.value,
                    url: option.url,
                  });
                }}
                isDisabled={false}
                options={quickRepliesAssetsOptions.filter(
                  (item) => item.theme === "dark" && item.type === "icon"
                )}
              />
            )}

            {(darkThemeIconImage?.file || darkThemeIconImage?.url) && (
              <Col className="pb-4 mb-3 mt-3">
                <img
                  src={darkThemeIconImage?.file || darkThemeIconImage?.url}
                  alt="preview"
                  width="100"
                />
              </Col>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>
              <strong>
                Light Theme Background Image <span className="text-danger">*</span>
                <p style={{ fontSize: 12, marginBottom: 0 }}>
                  The image should be jpg/png/gif/webp
                </p>
              </strong>
            </Label>
            <Button
              className="float-right btn-sm"
              onClick={(e) => {
                setEnableLightThemeBackGroundImageDropdown(
                  !enableLightThemeBackGroundImageDropdown
                );
              }}
              disabled={false}
            >
              {enableLightThemeBackGroundImageDropdown
                ? "Upload new image"
                : "Select from Uploaded Images"}
            </Button>

            <br />

            {!enableLightThemeBackGroundImageDropdown && (
              <Container className="custom-file">
                <Input
                  type="file"
                  className="custom-file-input"
                  accept="image/*"
                  disabled={false}
                  onChange={(event) => {
                    const file = event.target.files[0];
                    const reader = new FileReader();

                    reader.onloadend = () => {
                      setLightThemeBackGroundImage({
                        ...lightThemeBackGroundImage,
                        file: reader.result,
                      });
                    };
                    reader.readAsDataURL(file);
                  }}
                />
                <Label className="custom-file-label">
                  <strong>
                    {lightThemeBackGroundImage?.name === null
                      ? "Choose an image"
                      : lightThemeBackGroundImage?.name}
                  </strong>
                </Label>
              </Container>
            )}

            {enableLightThemeBackGroundImageDropdown && (
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                name="lightThemeBackGroundImage"
                value={quickRepliesAssetsOptions.filter(
                  (item) => item?.value === lightThemeBackGroundImage?.name
                )}
                placeholder="Select Uploaded Images"
                isClearable={false}
                onChange={(option) => {
                  setLightThemeBackGroundImage({
                    ...lightThemeBackGroundImage,
                    name: option.value,
                    url: option.url,
                  });
                }}
                isDisabled={false}
                options={quickRepliesAssetsOptions.filter(
                  (item) => item.theme === "light" && item.type === "background_image"
                )}
              />
            )}

            {(lightThemeBackGroundImage?.file || lightThemeBackGroundImage?.url) && (
              <Col className="pb-4 mb-3 mt-3">
                <img
                  src={lightThemeBackGroundImage?.file || lightThemeBackGroundImage?.url}
                  alt="preview"
                  width="100"
                />
              </Col>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>
              <strong>
                Dark Theme Background Image <span className="text-danger">*</span>
                <p style={{ fontSize: 12, marginBottom: 0 }}>
                  The image should be jpg/png/gif/webp
                </p>
              </strong>
            </Label>
            <Button
              className="float-right btn-sm"
              onClick={(e) => {
                setEnableDarkThemeBackGroundImageDropdown(
                  !enableDarkThemeBackGroundImageDropdown
                );
              }}
              disabled={false}
            >
              {enableDarkThemeBackGroundImageDropdown
                ? "Upload new image"
                : "Select from Uploaded Images"}
            </Button>

            <br />

            {!enableDarkThemeBackGroundImageDropdown && (
              <Container className="custom-file">
                <Input
                  type="file"
                  className="custom-file-input"
                  accept="image/*"
                  disabled={false}
                  onChange={(event) => {
                    const file = event.target.files[0];
                    const reader = new FileReader();

                    reader.onloadend = () => {
                      setDarkThemeBackGroundImage({
                        ...darkThemeBackGroundImage,
                        file: reader.result,
                      });
                    };
                    reader.readAsDataURL(file);
                  }}
                />
                <Label className="custom-file-label">
                  <strong>
                    {darkThemeBackGroundImage?.name === null
                      ? "Choose an image"
                      : darkThemeBackGroundImage?.name}
                  </strong>
                </Label>
              </Container>
            )}

            {enableDarkThemeBackGroundImageDropdown && (
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                name="darkThemeBackGroundImage"
                value={quickRepliesAssetsOptions.filter(
                  (item) => item?.value === darkThemeBackGroundImage?.name
                )}
                placeholder="Select Uploaded Images"
                isClearable={false}
                onChange={(option) => {
                  setDarkThemeBackGroundImage({
                    ...darkThemeBackGroundImage,
                    name: option.value,
                    url: option.url,
                  });
                }}
                isDisabled={false}
                options={quickRepliesAssetsOptions.filter(
                  (item) => item.theme === "dark" && item.type === "background_image"
                )}
              />
            )}

            {(darkThemeBackGroundImage?.file || darkThemeBackGroundImage?.url) && (
              <Col className="pb-4 mb-3 mt-3">
                <img
                  src={darkThemeBackGroundImage?.file || darkThemeBackGroundImage?.url}
                  alt="preview"
                  width="100"
                />
              </Col>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
         Number of Selected Cards: {selectedQuickReplies?.length}
        </Col>
        <Col md={6}>
          <Button
            color="primary"
            className="float-right mb-3 btn-lg"
            onClick={(event) => setConfirmationModalState(event)}
          >
            Submit
          </Button>
        </Col>
      </Row>
      <Row>

        {quickReplies?.map((quickReply, index) => (
          <div
            key={quickReply.id}
            style={{
              display: "inline-block",
            }}
          >
            <Card
              className="m-3 responseCard"
              style={{
                width: "285px",
                height: "14rem",
                boxShadow: "0 0 20px rgba(0,0,0,0.4)",
                borderRadius: "15px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              {quickReply.imageURL ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CardImg
                    src={
                      quickReply.imageURL !== null
                        ? quickReply.imageURL
                        : "/img/no-image-found.png"
                    }
                    alt="Card image"
                    style={{
                      width: "200px",
                      height: "150px",
                      paddingLeft: "10px",
                    }}
                  />
                </div>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CardImg
                    src={"/img/no-image-icon.png"}
                    alt="Card image"
                    style={{
                      height: "150px",
                      paddingLeft: "10px",
                      marginBottom: "5px",
                    }}
                  />
                </div>
              )}
              <Input
                type="checkbox"
                className="importPageCheckbox"
                id={quickReply.id}
                onChange={(event) => handleCheckboxChange(event, quickReply)}
              />
              <CardBody style={{ width: "150px", alignItems: "center" }}>
                <div style={{ marginBottom: "10px", width: "100px" }}>
                  {quickReply?.textDetails?.filter(
                    (card) => card.type === "header"
                  ).length > 0 && (
                    <div>
                      <h4>
                        {quickReply?.textDetails
                          ?.filter((card) => card.type === "header")
                          .map((card, index) => (
                            <span key={index}>
                              {truncateText(card.text, 4)}
                            </span>
                          ))}
                      </h4>
                    </div>
                  )}

                  {!quickReply?.textDetails?.some(
                    (card) => card.type === "header"
                  ) && <div style={{ height: "50px" }}></div>}

                  {quickReply?.textDetails?.filter(
                    (card) => card.type === "paragraph"
                  ).length > 0 && (
                    <div>
                      <p>
                        {quickReply?.textDetails
                          ?.filter((card) => card.type === "paragraph")
                          .map((card, index) => (
                            <span key={index}>
                              {truncateText(card.text, 4)}
                            </span>
                          ))}
                      </p>
                    </div>
                  )}

                  {!quickReply?.textDetails?.some(
                    (card) => card.type === "paragraph"
                  ) && <div style={{ height: "50px" }}></div>}

                  {quickReply?.textDetails?.filter(
                    (card) => card.type === "subText"
                  ).length > 0 && (
                    <div>
                      <p>
                        {quickReply?.textDetails
                          ?.filter((card) => card.type === "subText")
                          .map((card, index) => (
                            <span key={index}>
                              {truncateText(card.text, 4)}
                            </span>
                          ))}
                      </p>
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </div>
        ))}
      </Row>
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={action}
        onConfirmationTrue={() => {
          setConfirmationModalIsOpen(false);
          if (action === "Submit") handleSubmit();
        }}
        onHide={() => setConfirmationModalIsOpen(false)}
      />

      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={"Success"}
        modaltext={successModalText}
      />

      <ResponseModal
        show={failureModalState.failureModalDisplay}
        onHide={() =>
          setFailureModalState({
            failureModalDisplay: false,
            failureModalText: "",
          })
        }
        modalheading={"Error"}
        modaltext={failureModalState.failureModalText}
      />
    </Container>
  );
};

export default QuickReplyCTAAssetsBulkUpdate;

import { retrieveFromLocalStorage } from "../localStorageUtil";
import { host, mintHost, mintAPIToken } from "../apiUtils/index";
import moment from "moment";
import { secureFetch } from "../secureFetch";

// let host = "http://localhost:1323";

export let fetchPackageSetData = async () => {
  let url = `${host}/v4/internal/packageSets`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let addPackageSetData = async (data) => {
  let url = `${host}/v4/internal/packageSets`;
  let token = retrieveFromLocalStorage("token");

  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: data,
    });
    return response;
  } catch (error) {
    return;
  }
};

export let editPackageSetData = async (data, id) => {
  let url = `${host}/v4/internal/packageSets/${id}`;
  let token = retrieveFromLocalStorage("token");

  try {
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: data,
    });
    return response;
  } catch (error) {
    return;
  }
};

export let fetchKeyboardPrompts = async () => {
  let url = `${host}/v4/internal/keyboardPrompts`;
  let token = retrieveFromLocalStorage("token");

  try {
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    return await response.json();
  } catch (error) {
    return;
  }
};

export let getKeyboardPromptsLanguages = async () => {
  let url = `${host}/v4/internal/keyboardPrompts/languages`;
  let token = retrieveFromLocalStorage("token");

  try {
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    return await response.json();
  } catch (error) {
    return;
  }
};

export let getCategories = async () => {
  let url = `${host}/v4/internal/contentCategories`;
  let token = retrieveFromLocalStorage("token");

  try {
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    return await response.json();
  } catch (error) {
    return;
  }
};

export let getMintCREContentCategories = async () => {
  let url = `${mintHost}/v1/internal/contentCategories`;
  let token = mintAPIToken;

  try {
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    return await response.json();
  } catch (error) {
    return;
  }
};


export let getMintSuggestionDrawerCategories = async () => {
  let url = `${mintHost}/v4/internal/suggestionDrawerCategories`;
  let token = mintAPIToken;

  try {
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    return await response.json();
  } catch (error) {
    return;
  }
};


export let getSuggestionDrawerCategories = async (params) => {
  let url = `${host}/v4/internal/suggestionDrawerCategories?` + new URLSearchParams(params);
  let token = retrieveFromLocalStorage("token");

  try {
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    return await response.json();
  } catch (error) {
    return;
  }
};
export let publishKeyboardPrompts = async (id) => {
  let url = `${host}/v4/internal/keyboardPrompts/${id}/publish`;
  let token = retrieveFromLocalStorage("token");

  try {
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    return await response.json();
  } catch (error) {
    return;
  }
};

export let deactivateKeyboardPrompts = async (id) => {
  let url = `${host}/v4/internal/keyboardPrompts/${id}/deactivate`;
  let token = retrieveFromLocalStorage("token");

  try {
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    return await response.json();
  } catch (error) {
    return;
  }
};

export let activateKeyboardPrompts = async (id) => {
  let url = `${host}/v4/internal/keyboardPrompts/${id}/activate`;
  let token = retrieveFromLocalStorage("token");

  try {
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    return await response.json();
  } catch (error) {
    return;
  }
};

export let addKeyboardPrompts = async (data) => {
  let url = `${host}/v4/internal/keyboardPrompts`;
  let token = retrieveFromLocalStorage("token");

  try {
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchKeyboardPromptsByID = async (id) => {
  let url = `${host}/v4/internal/keyboardPrompts/${id}`;
  let token = retrieveFromLocalStorage("token");

  try {
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    return await response.json();
  } catch (error) {
    return;
  }
};

export let updateKeyboardPrompts = async (data, id) => {
  let url = `${host}/v4/internal/keyboardPrompts/${id}`;
  let token = retrieveFromLocalStorage("token");

  try {
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    return await response.json();
  } catch (error) {
    return;
  }
};

export let getKeyboardPromptsRules = async (id) => {
  let rules = [];

  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardPrompts/${id}/targetingRules`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    var res = await response.json();
    if (res) {
      rules = res;
    }
    return rules;
  } catch (error) {
    return;
  }
};

export let saveKeyboardPromptsRules = async (rules, id, data) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardPrompts/${id}/targetingRules`;
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let getCountries = async () => {
  let countries = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardPrompts/countries`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((country) => {
        let countryObj = {
          value: country.id,
          label: country.name,
        };
        countries.push(countryObj);
      });
    }
    return countries;
  } catch (error) {
    return;
  }
};

export let getRegions = async (countryID) => {
  let regions = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardPrompts/regions?countryID=${countryID}`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((region) => {
        let regionObj = {
          value: region.id,
          label: region.name,
        };
        regions.push(regionObj);
      });
    }
    return regions;
  } catch (error) {
    return;
  }
};

export let getCities = async (countryID, regionID) => {
  let cities = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardPrompts/cities?countryID=${countryID}&regionID=${regionID}`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((city) => {
        let cityObj = {
          value: city.id,
          label: city.name,
        };
        cities.push(cityObj);
      });
    }
    return cities;
  } catch (error) {
    return;
  }
};

export let processDateAndStatus = (item) => {
  if (item.startDate !== null) {
    item.startDate = item.startDate.split("T")[0];
  }
  if (item.endDate !== null) {
    item.endDate = item.endDate.split("T")[0];
  }
  if (item.publishedAt !== null) {
    item.publishedAt = new Date(item.publishedAt).toLocaleString();
    if (item.publishedAt === "Invalid Date") {
      item.publishedAt = null;
    }
  }
  if (item.createdAt !== null) {
    item.createdAt = new Date(item.createdAt).toLocaleString();
    if (item.createdAt === "Invalid Date") {
      item.createdAt = null;
    }
  }
  if (item.updatedAt !== null) {
    item.updatedAt = new Date(item.updatedAt).toLocaleString();
    if (item.updatedAt === "Invalid Date") {
      item.updatedAt = null;
    }
  }
  if (item.deactivatedAt !== null) {
    item.deactivatedAt = new Date(item.deactivatedAt).toLocaleString();
    if (item.deactivatedAt === "Invalid Date") {
      item.deactivatedAt = null;
    }
  }
  if (
    (item.publishedAt === null || item.publishedAt === "Invalid Date") &&
    item.deactivatedAt === null
  ) {
    item.status = "Unpublished";
  }
  if (
    item.publishedAt !== null &&
    item.publishedAt !== "Invalid Date" &&
    item.deactivatedAt === null
  ) {
    item.status = "Activated";
  }
  if (item.publishedAt !== null && item.deactivatedAt !== null) {
    item.status = "Deactivated";
  }
  if (item.endDate) {
    const diff = moment(item.endDate).diff(moment().format("YYYY-MM-DD"), "days")
    if (diff <= 0)
      item.status = "Deactivated";
  }
  return item
};

export let getKeyboardPromptsOrder = async () => {
  let url = `${host}/v4/internal/keyboardPrompts/order`;
  let token = retrieveFromLocalStorage("token");

  try {
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    return await response.json();
  } catch (error) {
    return;
  }
};

export let updateKeyboardPromptsOrder = async (promptOrderRequests) => {
  let url = `${host}/v4/internal/keyboardPrompts/order`; // Assuming this is the correct endpoint
  let token = retrieveFromLocalStorage("token");

  try {
    let response = await fetch(url, {
      method: "POST", // Use POST method for updating priority
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json", // Specify JSON content type
      },
      body: JSON.stringify(promptOrderRequests),
    });
    console.log("json", JSON.stringify(promptOrderRequests))
    return await response.json();
  } catch (error) {
    console.error("Failed to update prompt priority:", error);
    return null;
  }
};

export let getKeyboardPromptsUserAttributeRules = async (id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardPrompts/${id}/userAttributeRules`;
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    return res;
  } catch (error) {
    return;
  }
};

export let saveKeyboardPromptsUserAttributeRules = async (data, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardPrompts/${id}/userAttributeRules`;
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let deactivateKeyboardPromptsUserAttributeRules = async (promptId, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardPrompts/${promptId}/userAttributeRules/${id}/deactivate`;
  try {
    var response = await secureFetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let activateKeyboardPromptsUserAttributeRules = async (promptId, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardPrompts/${promptId}/userAttributeRules/${id}/activate`;
  try {
    var response = await secureFetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};
import React, { useState, useEffect } from "react";
import { Card, CardBody, Container, Label } from "reactstrap";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
    Row,
    Col,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ResponseModal from "../ResponseModal";
import { failureModalHeading, successModalHeading } from "../../../config";
import ConfirmationModal from "../ConfirmationModal";
import { ActivateAIPlacement, DeactivateAIPlacement, GetAIPlacements, PublishAIPlacement } from "../../../utilities/apiUtils/genAIDashboard";
import { allActions, error, errorDescription } from "../../../utilities/commonUtil";
import { formatTime, placementResponses, showActivatePlacement, showCreatePlacement, showDeactivatePlacement, showDetailPlacement, showPublishPlacement } from "../../../config/configGenAIDashboard";
import { placementsTableColumns } from "./placementTableColumn";

const { SearchBar } = Search;

const ExpandableRowsTable = () => {
    const [placementsData, setPlacementsData] = useState([]);
    const [failureModalText, setFailureModalText] = useState("");
    const [failureModalDisplay, setFailureModalDisplay] = useState(false);
    const [successModalText, setSuccessModalText] = useState("");
    const [successModalDisplay, setSuccessModalDisplay] = useState(false);
    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
    const [action, setAction] = useState("");
    const [status, setStatus] = useState({ value: "all", label: "All" });
    const [id, setId] = useState();

    const fetchPlacements = async (status) => {
        try {
            let response = await GetAIPlacements()
            if (!response || response?.length === 0) {
                setPlacementsData([]);
                return;
            }
            if (response.length > 0) {
                if (status === "all")
                    setPlacementsData(response);
                else if (status === "published") {
                    const publishedPlacements = response.filter(placement => placement.publishedAt != null);
                    setPlacementsData(publishedPlacements);
                }
                else if (status === "activated") {
                    const activatedPlacements = response.filter(placement => placement.deactivatedAt == null);
                    setPlacementsData(activatedPlacements);
                }
                else if (status === "deactivated") {
                    const deactivatedPlacements = response.filter(placement => placement.deactivatedAt != null);
                    setPlacementsData(deactivatedPlacements);
                }
            } else {
                showErrorModal(errorDescription(response.errorCode));
            }
        } catch (err) {
            showErrorModal(errorDescription(error.unexpectedError));
        }
    }

    useEffect(() => {
        if (status?.value) {
            fetchPlacements(status.value);
        }
    }, [status]);

    let showErrorModal = (text) => {
        setFailureModalText(text);
        setFailureModalDisplay(true);
    };

    let failureModalClose = () => {
        setFailureModalDisplay(false);
    };

    let successModalClose = async () => {
        setSuccessModalDisplay(false);
        await fetchPlacements(status.value);
    };

    let showSuccessModal = (text) => {
        setSuccessModalText(text);
        setSuccessModalDisplay(true);
    };

    let disableDeactivateButton = (row) => {
        if (row.deactivatedAt !== null || row.publishedAt === null) return true;
        return false;
    };

    let disablePublishButton = (row) => {
        if (row.publishedAt === null) return false;
        return true;
    };

    let disableActivateButton = (row) => {
        if (row.deactivatedAt === null || row.publishedAt === null) return true;
        return false;
    };

    let activate = async (id) => {
        try {
            let response = await ActivateAIPlacement(id);
            if (response.status === "aiPoweredBarPlacementActivated") {
                showSuccessModal(placementResponses.activatedSuccessfully);
            } else {
                if (response.errorCode) {
                    showErrorModal(response.errorCode)
                }
            }
        } catch (err) {
            showErrorModal(errorDescription(error.unexpectedError));
        }
    };

    let deactivate = async (id) => {
        try {
            let response = await DeactivateAIPlacement(id);
            if (response.status === "aiPoweredBarPlacementDeactivated") {
                showSuccessModal(placementResponses.deactivatedSuccessfully);
            } else {
                if (response.errorCode) {
                    showErrorModal(response.errorCode)
                }
            }
        } catch (err) {
            showErrorModal(errorDescription(error.unexpectedError));
        }
    };

    let publish = async (id) => {
        try {
            let response = await PublishAIPlacement(id);
            if (response.status === "aiPoweredBarPlacementPublished") {
                showSuccessModal(placementResponses.publishedSuccessfully);
            } else {
                if (response.errorCode) {
                    showErrorModal(response.errorCode)
                }
            }
        } catch (err) {
            showErrorModal(errorDescription(error.unexpectedError));
        }
    };

    let updateStatus = (update, id) => {
        if (update === "activate") {
            activate(id);
        }
        else if (update === "deactivate") {
            deactivate(id);
        }
        else if (update === "publish") {
            publish(id);
        }
    };

    let setConfirmationModalState = (event, row) => {
        setAction(event);
        setConfirmationModalIsOpen(true);
        setId(row.id);
    };

    let handleStatusChange = (status) => {
        setStatus({ value: status.value, label: status.label });
    };

    const expandRow = {
        onlyOneExpanding: true,
        renderer: (row) => (
            <div>
                <ul>
                    <li>{`Device Type : ${row.deviceType ?? "N/A"}`}</li>
                    <li>{`Min App Version : ${row.minAppVersion ?? "N/A"}`}</li>
                    <li>{`Max App Version : ${row.maxAppVersion ?? "N/A"}`}</li>
                    <li>{`Created At : ${formatTime(row.createdAt)}`}</li>
                    <li>{`Updated At : ${formatTime(row.updatedAt)}`}</li>
                    <li>{`Published At : ${formatTime(row.publishedAt)}`}</li>
                    <li>{`Deactivated At : ${formatTime(row.deactivatedAt)}`}</li>
                </ul>
                {showDetailPlacement() ? (
                    <Link to={{
                        pathname: `/gen-ai/placements/${row.id}/details`
                    }}>
                        <Button className="mx-3">
                            Details
                        </Button>
                    </Link>
                ) : null}
                {showDeactivatePlacement() ? (
                    <Button
                        className="mx-3 btn-danger"
                        disabled={disableDeactivateButton(row)}
                        onClick={(event) =>
                            setConfirmationModalState(event.target.innerHTML, row)
                        }
                    >
                        Deactivate
                    </Button>
                ) : null}
                {
                    showActivatePlacement() ? (
                        <Button
                            className="mx-3 btn-success"
                            disabled={disableActivateButton(row)}
                            onClick={(event) =>
                                setConfirmationModalState(event.target.innerHTML, row)
                            }
                        >
                            Activate
                        </Button>
                    ) : null
                }
                {
                    showPublishPlacement() ? (
                        <Button className="mx-3 btn-secondary"
                            disabled={disablePublishButton(row)}
                            onClick={(event) =>
                                setConfirmationModalState(event.target.innerHTML, row)
                            }
                        >
                            Publish
                        </Button>
                    ) : null
                }
            </div >
        ),
    };

    return (
        <Card>
            <CardBody>
                <Row>
                    <Col md={3} sm={12}>
                        <Label>Status</Label>
                        <Select
                            className="react-select-container mb-4"
                            classNamePrefix="react-select"
                            placeholder="Select Status"
                            onChange={handleStatusChange}
                            options={[
                                { value: "all", label: "All" },
                                { value: "activated", label: "Activated" },
                                { value: "deactivated", label: "Deactivated" },
                                { value: "published", label: "Published" },
                            ]}
                            value={status}
                        />
                    </Col>
                </Row>
                <ToolkitProvider keyField="id" data={placementsData} columns={placementsTableColumns} search>
                    {(props) => (
                        <div>
                            <SearchBar {...props.searchProps} />
                            <BootstrapTable
                                {...props.baseProps}
                                bootstrap4
                                bordered={false}
                                keyField="id"
                                expandRow={expandRow}
                                pagination={paginationFactory({
                                    sizePerPage: 10,
                                    sizePerPageList: [5, 10, 25, 50],
                                })}
                                defaultSorted={[{
                                    dataField: 'id',
                                    order: 'asc'
                                }]}
                            />
                        </div>
                    )}
                </ToolkitProvider>
            </CardBody>
            <ResponseModal
                show={successModalDisplay}
                onHide={successModalClose}
                modalheading={successModalHeading}
                modaltext={successModalText}
            />
            <ResponseModal
                show={failureModalDisplay}
                onHide={failureModalClose}
                modalheading={failureModalHeading}
                modaltext={failureModalText}
            />
            <ConfirmationModal
                show={confirmationModalIsOpen}
                modaltext={action}
                onConfirmationTrue={() => {
                    setConfirmationModalIsOpen(false);
                    if (action === allActions.deactivate) updateStatus("deactivate", id);
                    else if (action === allActions.activate) updateStatus("activate", id);
                    else if (action === allActions.publish) updateStatus("publish", id);
                }}
                onHide={() => setConfirmationModalIsOpen(false)}
            />
        </Card>
    );
};

const Placements = () => {
    return (
        <Container fluid className="p-0">
            <div className="d-flex justify-content-between">
                <h1 className="h3">Placements</h1>
                {showCreatePlacement() ? (
                    <div>
                        <Link
                            to={{
                                pathname: `/gen-ai/placements/create`
                            }}
                        >
                            <Button
                                style={{ fontSize: "1rem" }}
                                className="mx-3 btn-success mb-3"
                            >
                                Create a New Placement
                            </Button>
                        </Link>
                    </div>
                ) : null}
            </div>
            <ExpandableRowsTable />
        </Container>
    )
}
export default Placements;
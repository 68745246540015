import React, { useState, useEffect } from "react";
import Loading from "../../../components/Loading";
import { Container, Button, Col, Row } from "reactstrap";
import {
  error,
  errorDescription,
  redirectionOnTokenExpiry,
} from "../../../utilities/commonUtil";
import {
  fetchActiveKeyboardFonts, reorderKeyboardFonts
} from "../../../utilities/apiUtils/keyboardFonts";

import ResponseModal from "../ResponseModal";
import Reorder, { reorder } from "react-reorder";
import {
  errorModalHeading,
  successModalHeading,
  reorderSuccess,
} from "../../../config/config";


const KeyboardFontsReorder = (props) => {
  const [loading, setLoading] = useState(true);
  const [keyboardFonts, setKeyboardFonts] = useState([]);
  const [keyboardFontsOriginalOrder, setKeyboardFontsOriginalOrder] =
    useState([]);
  const [isReordered, setIsReordered] = useState(false);
  const [successModal, setSuccessModal] = useState({
    display: false,
    text: "",
  });
  const [failureModal, setFailureModal] = useState({
    display: false,
    text: "",
  });
  // Fetch keyboard fonts on component mount
  useEffect(() => {
    getKeyboardFonts();
  }, []);
  // Fetches the fonts
  let getKeyboardFonts = async () => {
    try {
      let response = await fetchActiveKeyboardFonts();
      if (response.keyboardFonts) {
        setKeyboardFonts(response.keyboardFonts);
        setKeyboardFontsOriginalOrder(response.keyboardFonts);
        setIsReordered(false);
        setLoading(false);
      } else {
        if (response.errorCode) {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken
          )
            redirectionOnTokenExpiry();
          setLoading(false);
          showErrorModal(errorDescription(response.errorCode));
        }
      }
    } catch (err) {
      setLoading(false);
      showErrorModal(errorDescription(error.unexpectedError));
    }
  };
  // Functions to display error modal
  let showErrorModal = (errorCode) => {
    setFailureModal({ display: true, text: errorCode });
  };
  // Functions to display success modal
  let successModalClose = () => {
    setSuccessModal({ ...successModal, display: false });
    getKeyboardFonts();
  };
  // Functions to close error modal
  let failureModalClose = () => {
    setFailureModal({ ...failureModal, display: false });
  };

  let getResponseStatus = (action) => {
    switch (action) {
      case "reorder":
        return reorderSuccess;
      default:
    }
  };

  let handleResponse = (response, action) => {
    if (!response.errorCode) {
      let text = getResponseStatus(action);
      setSuccessModal({ display: true, text: text });
      return;
    }
    setFailureModal({
      display: true,
      text: errorDescription(response.errorCode),
    });
  };
  // Function to reorder the fonts
  let reorderFonts = async () => {
    let order = keyboardFonts.map((font) => font.id);
    let response = await reorderKeyboardFonts(order);
    handleResponse(response, "reorder");
  };

  let onReorder = (event, previousIndex, nextIndex, fromId, toId) => {
    let updatedOrder = reorder(keyboardFonts, previousIndex, nextIndex);
    let isReordered =
      updatedOrder.map((font) => font.id).join(",") !=
      keyboardFontsOriginalOrder.map((font) => font.id).join(",");
    setKeyboardFonts(updatedOrder);
    setIsReordered(isReordered);
  };

  return (
    <Container>
      <Row>
        <h1 className="mb-4 mt-2 w-50 pl-3">Reorder Keyboard Fonts</h1>
      </Row>

      <Row>
        <Button
          color="primary"
          className="ml-auto"
          style={{ height: "100%" }}
          onClick={reorderFonts}
          disabled={!isReordered}
        >
          <p className="my-1" style={{ fontSize: "1rem" }}>
            Save Order
          </p>
        </Button>
      </Row>

      {loading ? (
        <Loading />
      ) : (
        <div className="row justify-content-lg-center">
          {(keyboardFonts && Object(keyboardFonts).length === 0) ? (
            <Container className="mt-5">
              <h3 className="text-center">
                No Keyboard Fonts Avaliable.
              </h3>
            </Container>
          ) : (
            <Col md={6}>
              <Reorder
                lock="horizontal"
                reorderId="reorder-list"
                onReorder={onReorder}
              >
                {keyboardFonts && keyboardFonts.map((font, index) => {
                  return (
                    <div
                      key={index}
                      className="transliterationReorder text-center"
                    >
                      {`${font.id} - ${font.name}`}
                    </div>
                  );
                })}
              </Reorder>
            </Col>
          )}
        </div>
      )}

      <ResponseModal
        show={successModal.display}
        onHide={successModalClose}
        modalheading={successModalHeading}
        modaltext={successModal.text}
      />
      <ResponseModal
        show={failureModal.display}
        onHide={failureModalClose}
        modalheading={errorModalHeading}
        modaltext={failureModal.text}
      />
    </Container>
  );
};

export default KeyboardFontsReorder;

import React, { Component, useState, useEffect } from "react";
import Search from "../../../components/Search";
import { Link } from "react-router-dom";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import Loading from "../../../components/Loading";
import { Container, Button, Col, Row } from "reactstrap";
import {
  error,
  errorDescription,
  redirectionOnTokenExpiry,
  allActions,
  permissions,
} from "../../../utilities/commonUtil";
import {
  getABTests,
  publishABTest,
  fetchKeyboardLanguages,
} from "../../../utilities/apiUtils/keyboardLanguages";
import { Config } from "../../../config/UserConfig";
import ResponseModal from "../ResponseModal";
import ConfirmationModal from "../ConfirmationModal";
import ABTestCard from "./components/ABTestCard";
import {
  abTestPublishedText,
  noABTestsAvailableText,
  invalidABTestSearchTagText,
  successModalHeading,
  failureModalHeading,
} from "../../../config/keyboardLanguages";

const ABTests = () => {
  const [loading, setLoading] = useState(true);
  const [abTests, setABTests] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedABTests, setSearchedABTests] = useState([]);
  const [successModalState, setSuccessModalState] = useState({
    successModalDisplay: false,
    successModalText: "",
  });
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });
  const [id, setID] = useState("");
  const [keyboardLanguageName, setKeyboardLanguageName] = useState("");
  const [action, setAction] = useState("");
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [allowedPermissions, setAllowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );
  const [keyboardLanguages, setKeyboardLanguages] = useState([]);

  // This useEffect will be called for componentDidMount condition
  useEffect(() => {
    const getKeyboardLanguages = async () => {
      try {
        const response = await fetchKeyboardLanguages();
        if (response.errorCode) {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken ||
            response.errorCode === "Unauthorized"
          )
            redirectionOnTokenExpiry();
          let errorCode = errorDescription(response.errorCode);
          showErrorModal(errorCode);
        } else if (response.keyboardLanguages) {
          setKeyboardLanguages(response.keyboardLanguages);
        }
      } catch (err) {
        showErrorModal(errorDescription(error.unexpectedError));
      }
    };

    getKeyboardLanguages();
    fetchABTests();
  }, []);

  useEffect(() => {
    for (let i = 0; i < abTests.length; i++) {
      let keyboardLanguageName = getKeyboardLangaugeName(
        abTests[i].keyboardLanguageId
      );
      abTests[i]["keyboardLanguageName"] = keyboardLanguageName;
    }
    setABTests(abTests);
    // setLoading(false);
  }, [keyboardLanguages]);

  const isABTestValid = (abTest) => {
    let variants = abTest.variants;
    let isBaseVersionPersent = false;

    if (variants.length === 0) {
      isBaseVersionPersent = true;
    }

    for (let i = 0; i < variants.length; i++) {
      if (variants[i].version === abTest.keyboardLanguageBaseResourceVersion) {
        isBaseVersionPersent = true;
        break;
      }
    }

    if (abTest.publishedAt === null && isBaseVersionPersent === false) {
      return false;
    }
    return true;
  };

  const fetchABTests = async () => {
    setLoading(true);
    try {
      const response = await getABTests();
      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken ||
          response.errorCode === "Unauthorized"
        )
          redirectionOnTokenExpiry();
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
        setLoading(false);
      } else if (response.keyboardLanguageABTests) {
        let abTests = response.keyboardLanguageABTests;

        abTests.sort((testOne, testTwo) => {
          return testTwo.id - testOne.id;
        });

        for (let i = 0; i < abTests.length; i++) {
          abTests[i]["isABTestValid"] = isABTestValid(abTests[i]);
        }
        setABTests(abTests);
        setSearchedABTests(response.keyboardLanguageABTests);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
      setLoading(false);
    }
  };

  const successModalClose = () => {
    setSuccessModalState((prev) => ({ ...prev, successModalDisplay: false }));
    fetchABTests();
  };

  const failureModalClose = () => {
    setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
  };

  const showErrorModal = (errorCode) => {
    setFailureModalState({
      failureModalText: errorCode,
      failureModalDisplay: true,
    });
  };

  const showSuccessModal = (text) => {
    setSuccessModalState({
      successModalText: text,
      successModalDisplay: true,
    });
  };

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
    if (!event.target.value.length) {
      reset();
    }
    event.preventDefault();
  };

  const reset = () => {
    setSearchedABTests(abTests);
  };

  const onKeyDown = (event) => {
    if (event.keyCode === Config.KEYPRESS_DOWN) {
      search();
    }
  };

  const search = () => {
    if (searchText === "") return;
    let searchedABTests = filterABTests(abTests);
    setSearchedABTests(searchedABTests);
  };

  // Filter AB Tests that include search text in their name
  const filterABTests = (abTests) => {
    return abTests.filter((test) => {
      let text = test.keyboardLanguageName;
      return text.toLowerCase().includes(searchText.toLowerCase());
    });
  };

  const performAction = () => {
    if (action === allActions.publish) {
      publish(id, keyboardLanguageName);
    }
  };

  const getResponseStatus = (action) => {
    switch (action) {
      case "publish":
        return abTestPublishedText;
    }
  };

  const handleResponse = (response, action) => {
    if (!response.errorCode) {
      let text = getResponseStatus(action);

      setSuccessModalState({
        successModalDisplay: true,
        successModalText: text,
      });
      return;
    }
    setFailureModalState({
      failureModalDisplay: true,
      failureModalText: response.errorDescription,
    });
  };

  const publish = async (id, keyboardLanguageName) => {
    let response = await publishABTest(id, keyboardLanguageName);
    handleResponse(response, "publish");
  };

  const setConfirmationModalState = (id, btnName, keyboardLanguageName) => {
    setID(id);
    setAction(btnName);
    setKeyboardLanguageName(keyboardLanguageName);
    setConfirmationModalIsOpen(true);
  };

  const showCreateABTestButton = () => {
    return allowedPermissions.includes(
      permissions.keyboardLanguageABTestCreate
    );
  };

  const getKeyboardLangaugeName = (keyboardLanguageId) => {
    for (let i = 0; i < keyboardLanguages.length; i++) {
      if (keyboardLanguages[i].id == keyboardLanguageId) {
        return keyboardLanguages[i].name;
      }
    }
  };

  return (
    <Container>
      <Row>
        <Col md="4" sm="12">
          <Search
            value={searchText}
            onChange={(event) => handleSearchTextChange(event)}
            onKeyDown={(event) => onKeyDown(event)}
          ></Search>
        </Col>
        <Col md="4" sm="2">
          <Button color="success" onClick={search}>
            Search
          </Button>
        </Col>
      </Row>
      <Row>
        <h1 className="mb-4 mt-2 w-50 pl-3">AB Tests</h1>
        {showCreateABTestButton() ? (
          <Link
            className="d-block ml-auto mb-4 pr-3 text-decoration-none"
            to={{
              pathname: `/kl/abtests/create`,
              state: {
                formType: "create",
              },
            }}
          >
            <Button color="primary" className="ml-auto d-flex">
              <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                Create AB Test
              </p>
            </Button>
          </Link>
        ) : null}
      </Row>
      {loading ? (
        <Loading />
      ) : (
        <Row>
          {Object(searchedABTests).length === 0 ? (
            <Container className="mt-5">
              <h3 className="text-center">{noABTestsAvailableText}</h3>
              <h4 className="text-center">{invalidABTestSearchTagText}</h4>
            </Container>
          ) : (
            Object(searchedABTests).map((test, index) => {
              return (
                <Col md={6} key={index}>
                  <ABTestCard
                    test={test}
                    name={getKeyboardLangaugeName(test.keyboardLanguageId)}
                    setConfirmationModalState={setConfirmationModalState}
                  />
                </Col>
              );
            })
          )}
        </Row>
      )}
      <ResponseModal
        show={successModalState.successModalDisplay}
        onHide={() => successModalClose()}
        modalheading={successModalHeading}
        modaltext={successModalState.successModalText}
      />
      <ResponseModal
        show={failureModalState.failureModalDisplay}
        onHide={() => failureModalClose()}
        modalheading={failureModalHeading}
        modaltext={failureModalState.failureModalText}
      />
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={action}
        onHide={() => {
          setConfirmationModalIsOpen(false);
        }}
        onConfirmationTrue={() => {
          setConfirmationModalIsOpen(false);
          performAction();
        }}
      />
    </Container>
  );
};

export default ABTests;

import { host } from "./index";
import { retrieveFromLocalStorage } from "../localStorageUtil";

export let getClients = async () => {
  let clients = [];

  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/stickers/clients`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((client) => {
        let clientObj = {
          value: client.id,
          label: client.name,
        };
        clients.push(clientObj);
      });
    }
    return clients;
  } catch (error) {
    return;
  }
};

export let getBrandCampaigns = async () => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stickers/brandCampaigns`;

  let campaigns = [];

  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((campaign) => {
        let campaignObj = {
          value: campaign.id,
          label: campaign.name,
        };
        campaigns.push(campaignObj);
      });
    }
    return campaigns;
  } catch (error) {
    return;
  }
};

export let getFonts = async () => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stickers/fonts`;
  let fonts = [];

  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((font) => {
        let fontObj = {
          value: font.id,
          label: font.name,
        };
        fonts.push(fontObj);
      });
    }
  } catch (error) {
    return;
  }
  return fonts;
};

export let getLanguages = async () => {
  let languages = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stickers/languages`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((language) => {
        let languageObj = {
          value: language.code,
          label: language.name,
        };
        languages.push(languageObj);
      });
    }
    return languages;
  } catch (error) {
    return;
  }
};

export let getCountries = async () => {
  let countries = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stickers/countries`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((country) => {
        let countryObj = {
          value: country.id,
          label: country.name,
          isoCode: country.iso_code
        };
        countries.push(countryObj);
      });
    }
    return countries;
  } catch (error) {
    return;
  }
};

export let getRegions = async (countryID) => {
  let regions = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stickers/regions?countryID=${countryID}`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((region) => {
        let regionObj = {
          value: region.id,
          label: region.name,
          regionCode: region.regionCode
        };
        regions.push(regionObj);
      });
    }
    return regions;
  } catch (error) {
    return;
  }
};

export let getCities = async (countryID, regionID) => {
  let cities = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stickers/cities?countryID=${countryID}&regionID=${regionID}`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((city) => {
        let cityObj = {
          value: city.id,
          label: city.name,
        };
        cities.push(cityObj);
      });
    }
    return cities;
  } catch (error) {
    return;
  }
};

export let getAutoDownloadRules = async (id) => {
  let rules = [];

  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stickerPacks/${id}/autoDownloadRules`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      rules = res.rules;
    }
    return rules;
  } catch (error) {
    return;
  }
};

export let saveAutoDownloadRules = async (data, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stickerPacks/${id}/autoDownloadRules`;
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ rules: data }),
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let getStickerPackTargetingRules = async (id) => {
  let rules = [];

  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stickerPacks/${id}/targetingRules`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      rules = res.rules;
    }
    return rules;
  } catch (error) {
    return;
  }
};

export let saveStickerPackTargetingRules = async (data, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stickerPacks/${id}/targetingRules`;
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ rules: data }),
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let saveStickersTargetingRules = async (data, stickerIds) => {
  let token = retrieveFromLocalStorage("token");
  stickerIds = stickerIds.split(",").map(id => parseInt(id))

  let url = `${host}/v4/internal/stickers/targetingRules`;
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ stickerIds: stickerIds, rules: data }),
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let saveStickerShareText = async (data, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stickers/${id}/shareTexts`;
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let getStickerShareText = async (id) => {
  let token = retrieveFromLocalStorage("token");
  let stickerShareText = {};

  let url = `${host}/v4/internal/stickers/${id}/shareTexts`;

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    stickerShareText = response.json();
  });

  return stickerShareText;
};

export let getLottieAnimations = async () => {
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/emojiStickers/animations`;

  try {
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};


export let createLottieAnimation = async (formData) => {
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/emojiStickers/animations`;

  // this is a form body
  try {
    let response = await fetch(url, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
}

export let getLottieAnimation = async (id) => {
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/emojiStickers/animations/${id}`;

  try {
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    return await response.json();
  } catch (error) {
    return;
  }
};

export let editLottieAnimation = async (formData, id) => {
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/emojiStickers/animations/${id}`;

  try {
    let response = await fetch(url, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let editSticker = async (id, data) => {
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/stickers/${id}`;

  try {
    let response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
    },
    });
    return await response.json();
  } catch (error) {
    return;
  }
}
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Alert,
  Badge,
} from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import {
  fetchKeyboardPromptsPriorityRuleSetByRule,
  updateKeyboardPromptsPriorityRuleSet,
} from "../../../../utilities/apiUtils/keyboardPromptsOrder"; 
import CustomTooltip from "../../../../components/customToolTip";

const EditPromptsRulesetForm = () => {
  const [keyboardPrompts, setkeyboardPrompts] = useState([]);
  const [priority, setPriority] = useState("");
  const [description, setDescription] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [selectedKeyboardPrompts, setSelectedKeyboardPrompts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dragIndex, setDragIndex] = useState(null);
  const history = useHistory();
  const { segmentID } = useParams();

  useEffect(() => {
    if (!segmentID) {
      console.error("Segment ID is missing from the URL");
      setError("Segment ID is missing from the URL");
      return;
    }
  
    const loadPriorityRuleSet = async () => {
      setLoading(true);
      try {
        // Fetch the keyboard prompts priority rule set by segment ID
        const response = await fetchKeyboardPromptsPriorityRuleSetByRule(segmentID);
        // If the response contains valid keyboard prompts
        if (response && response.length > 0) {
          const {
            priority = "",
            description = "",
            keyboardPrompts = [],
            createdAt = "",
          } = response[0];
  
          setkeyboardPrompts(keyboardPrompts);
          setSelectedKeyboardPrompts(keyboardPrompts);
          setPriority(priority);
          setDescription(description);
          setCreatedAt(createdAt)
        } else {
          setError("No keyboard prompts found for this segment.");
        }
      } catch (error) {
        console.error("Error fetching priority rule set:", error);
        setError("Error fetching priority rule set. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
  
    loadPriorityRuleSet();
  }, [segmentID]);
  

  
  const getKeyboardPromptStatus = (keyboardPrompt) => {
    const { publishedAt, deactivatedAt } = keyboardPrompt;
    if (publishedAt && !deactivatedAt) {
      return "Active";
    } else if (deactivatedAt) {
      return "Deactivated";
    } else {
      return "Unpublished";
    }
  };

  const getBadgeClass = (status) => {
    switch (status) {
      case "Active":
        return "badge-success";
      case "Deactivated":
        return "badge-danger";
      case "Unpublished":
        return "badge-warning";
      default:
        return "";
    }
  };


  const onKeyboardPromptDragStart = (index, actualIndex) => {
    setDragIndex(actualIndex);
  };

  const onKeyboardPromptDragOver = (event, index, actualIndex) => {
    event.preventDefault();
    if (actualIndex === dragIndex) return;
  
    const items = [...selectedKeyboardPrompts];
    const draggedItem = items[dragIndex];
    items.splice(dragIndex, 1);
    items.splice(actualIndex, 0, draggedItem);
  
    setSelectedKeyboardPrompts(items);
    setDragIndex(actualIndex);
  };

  const onKeyboardPromptDrop = () => {
    setDragIndex(null);
  };

  const handleSubmit = async () => {
    if (priority === "" || selectedKeyboardPrompts.length === 0) {
      setError("Please set a valid priority and select at least one keyboard prompt.");
      return;
    }
  
    // Extract the keyboard prompt IDs from the selected prompts as numbers
    const keyboardPromptIds = selectedKeyboardPrompts.map((kp) => kp.id); // No need to convert to string
    
    // Build the payload with the correct format for promptIDs
    const payload = {
      promptIDs: keyboardPromptIds,  // This will now be an array of numbers
      segmentID: parseInt(segmentID, 10),
      priority: parseInt(priority, 10),
      description: description,
      createdAt: createdAt,
    };
  
    setLoading(true);
    try {
      await updateKeyboardPromptsPriorityRuleSet(segmentID, payload);
      history.push("/keyboard-prompt/prompts/segmentOrder");
    } catch (error) {
      setError("Failed to update priority rule set. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  
  

  const renderKeyboardPromptCards = () => {
    if (selectedKeyboardPrompts.length === 0) {
      return <p>No keyboard prompts available.</p>;
    }

    // Filter out deactivated prompts for display only, but keep track of original indices
    const displayPromptsWithIndices = selectedKeyboardPrompts
      .map((prompt, index) => ({ prompt, originalIndex: index }))
      .filter(({ prompt }) => !prompt.deactivatedAt);

    if (displayPromptsWithIndices.length === 0) {
      return <p>No active keyboard prompts available.</p>;
    }

    const rows = [];

    for (let i = 0; i < displayPromptsWithIndices.length; i += 10) {
      const rowItems = displayPromptsWithIndices.slice(i, i + 10);
      rows.push(
        <div
          key={i}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "0.5rem",
            gap: "0.4rem",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          {rowItems.map(({ prompt, originalIndex }, index) => {
            const actions = prompt.actions || [];
            const displaySettingsString = actions[0]?.display_settings;
            const displaySettings = displaySettingsString
              ? JSON.parse(displaySettingsString)
              : {};
  
            const bannerImageURL = displaySettings?.bannerImageURL;
            const leftIconImageURL = displaySettings?.leftIcon?.imageURL;
            const imageURL =
              bannerImageURL && bannerImageURL.trim() !== ""
                ? bannerImageURL
                : leftIconImageURL && leftIconImageURL.trim() !== ""
                ? leftIconImageURL
                : "N/A";
  
            const status = getKeyboardPromptStatus(prompt);
            const badgeColor = getBadgeClass(status);
  
            return (
              <div
                key={prompt.id}
                draggable
                onDragStart={() => onKeyboardPromptDragStart(i + index, originalIndex)}
                onDragOver={(e) => onKeyboardPromptDragOver(e, i + index, originalIndex)}
                onDrop={onKeyboardPromptDrop}
                style={{
                  padding: "0.4rem",
                  border: "1px solid #e1e1e1",
                  borderRadius: "6px",
                  backgroundColor: "#fff",
                  cursor: "grab",
                  width: "calc((100% - 3.6rem) / 10)",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.2s ease-in-out",
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.transform = "scale(1.02)";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                }}
              >
                <div className="banner-image mb-2" style={{ width: "100%" }}>
                  {imageURL === "N/A" ? (
                    <p style={{ color: "#888", fontSize: "0.75rem" }}>No image</p>
                  ) : (
                    <img
                      src={imageURL}
                      alt={`${prompt.name} banner`}
                      style={{
                        maxWidth: "100%",
                        height: "40px",
                        objectFit: "contain",
                        borderRadius: "4px",
                      }}
                    />
                  )}
                </div>
                <h4 className="card-title" style={{ fontSize: "0.85rem", fontWeight: "600", marginBottom: "0.25rem" }}>
                  {prompt.name}
                </h4>
                <p className="card-text" style={{ fontSize: "0.75rem", color: "#555", marginBottom: "0.25rem" }}>
                  ID: {prompt.id}
                </p>
                <Badge
                  className={`${badgeColor}`}
                  style={{
                    fontSize: "0.75rem",
                    padding: "0.25rem 0.5rem",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                >
                  {status}
                </Badge>
              </div>
            );
          })}
        </div>
      );
    }
  
    return (
      <div style={{ 
        maxHeight: "60vh", 
        overflowY: "auto",
        padding: "0.5rem",
        border: "1px solid #dee2e6",
        borderRadius: "0.25rem",
        marginBottom: "1rem"
      }}>
        {rows}
      </div>
    );
  };
   

  return (
    <Container>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <h2 style={{ textAlign: "center", marginBottom: "20px", color: "#4A4A4A" }}>
                Edit Priority Rule Set
              </h2>
              {loading ? (
                <Spinner color="primary" />
              ) : error ? (
                <Alert color="danger">{error}</Alert>
              ) : (
                <>
                  <FormGroup>
                    <Label for="priority">Priority:</Label>
                    <CustomTooltip
                      target="priority-tooltip"
                      content="Specifies the order in which this rule set is applied, with higher priority rules taking precedence."
                      placement="right"
                    />
                    <Input
                      type="number"
                      id="priority"
                      value={priority}
                      onChange={(e) => setPriority(e.target.value)}
                      min="1"
                      placeholder="Enter priority level"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="description">
                      Description <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      type="text"
                      id="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder="Enter a description"
                    />
                  </FormGroup>
                  <h3>Keyboard Pills <CustomTooltip
                  target="identifier-tooltip"
                  content={<>Ordering Goes Left to Right</>}
                  placement="right"
                /></h3>
                  {renderKeyboardPromptCards()}
                  <Button
                    color="primary"
                    onClick={handleSubmit}
                    style={{ marginTop: "1rem" }}
                  >
                    Update Rule Set
                  </Button>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EditPromptsRulesetForm;

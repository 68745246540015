import React, { useState, useEffect } from "react";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import { Button } from "react-bootstrap";
import { tableColumns } from "./tableColumn";
import { ManagePackageSets } from "./ManagePackageSets";
import { Badge } from "reactstrap";
import UncontrolledButtonDropdown from "reactstrap/lib/UncontrolledButtonDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import { capitalize } from "@material-ui/core";

import filterFactory from "react-bootstrap-table2-filter";
import Loading from "../../../../components/Loading";
import paginationFactory from "react-bootstrap-table2-paginator";
import Search from "../../../../components/Search";
import BootstrapTable from "react-bootstrap-table-next";
import { fetchPackageSetData } from "../../../../utilities/apiUtils/keyboardPrompts";

import {
  showAddPackageSetsButton,
  showEditPackageSetsButton
} from "../../../../config/ConfigKeyboardPrompts"

import {
  errorDescription,
  error,
  redirectOnTokenExpiry,
} from "../../../../utilities/commonUtil";
import { Config } from "../../../../config/UserConfig";

const PackageSets = () => {
  const [loading, setLoading] = useState(true);
  const [statusFilter, setStatusFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [packageSets, setPackageSets] = useState([]);
  const [showAddPackageSets, setShowAddPackageSets] = useState(false);
  const [showEditPackageSets , setEditPackageSets] = useState(false);
  const [modalData, setModalData] = useState({});
  const [searchBy, setSearchBy] = useState("Package Set");
  const [searchString, setSearchString] = useState("");
  const [filters, setFilters] = useState({});
  const [viewPackageSets, setViewPackageSets] = useState(false);



  let onTableChange = (type, newState) => {
    setFilters(newState.filters);
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <ul>
          <li>ID : {row.id !== null ? row.id : "null"}</li>
          <li>Name : {row.name !== null ? row.name : "null"}</li>
          <li>
            Created At : {row.createdAt !== null ? row.createdAt : "null"}
          </li>
          <li>
            Updated At : {row.updatedAt !== null ? row.updatedAt : "null"}
          </li>
          <li>
            Packages :{" "}
            {row.packages.map((item, index) => (
              <>
                <Badge className="badge-secondary">{item.packageName}</Badge>{" "}
              </>
            ))}
          </li>
        </ul>

        <Button
          className="mx-3 btn-warning"
          onClick={() => {
            setViewPackageSets(true);
          }}
        >
          View
        </Button>

        {viewPackageSets === true && (
          <ManagePackageSets
            show={viewPackageSets}
            onHide={() => setViewPackageSets(false)}
            action={"view"}
            packageSet={row}
          />
        )}


        {showEditPackageSetsButton() && (
          <Button className="mx-3" onClick={()=> setEditPackageSets(true)}>Edit</Button>
        )}

        {showEditPackageSets === true && (
              <ManagePackageSets
                show={showEditPackageSets}
                onHide={closeEditPackageSets}
                action={"edit"}
                packageSet={row}
              />
            )}
      </div>
    ),
  };

  const filterPackageSets = (filter) => {
    var filteredPackageSets;
    if(filter ===  "Package Set") {
       filteredPackageSets = packageSets.filter((item) => {
        return item.name.toLowerCase().includes(searchString.toLowerCase());
      });
    }

    if (filter === "Package Name") {
      filteredPackageSets = packageSets.filter((item) => {
        return item.packages.some((packageItem) => {
          return packageItem.packageName
            .toLowerCase()
            .includes(searchString.toLowerCase());
        });
      });
    }
    return filteredPackageSets;
  }


  const processDateTime = (data) => {
    // iterate data 
    data.packageSets.forEach((item) => {
      // convert date time to local time and format it to yyyy-mm-dd , hour-mins-secs AM/PM format
      item.createdAt = new Date(item.createdAt).toLocaleString().replace(/\//g, "-");
      item.updatedAt = new Date(item.updatedAt).toLocaleString().replace(/\//g, "-");
    });

    return data;
  }

  const handleChange = (event) => {
    let value = event.target.value;
    setSearchString(value);
    if (!event.target.value.length) {
      fetchPackageSetsTableData();
    }
    event.preventDefault();
  };

  let closeAddPackageSets = () => {
    setShowAddPackageSets(false);
    setModalData({});
    fetchPackageSetsTableData();
  };

  let closeEditPackageSets = () => {
    setEditPackageSets(false);
    setModalData({});
    fetchPackageSetsTableData();
  }

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  const onKeyDown = (event) => {
    if (event.keyCode === Config.KEYPRESS_DOWN) {
      fetchPackageSetsTableData();
    }
  };

  let fetchPackageSetsTableData = async () => {
    try {
      let response = await fetchPackageSetData();
      if (response?.packageSets?.length) {
        response = processDateTime(response);
        setPackageSets(response.packageSets);
        setLoading(false);
      } else {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectOnTokenExpiry();
        let text = errorDescription(response.errorCode);
        setLoading(false);
        showErrorModal(text);
      }
    } catch (err) {
      setLoading(false);
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  };

  useEffect(() => {
    fetchPackageSetsTableData();
  }, []);

  return (
    <Container fluid className="p-0">
      <Row className="mb-3">
        <Col md={6} xs={6}>
          <h1>Package Sets</h1>
        </Col>
        <Col md={6} xs={6}>
          <div className="p-0">

      {showAddPackageSetsButton() && (
        <Button
        color="success"
        className="ml-auto d-flex btn-success"
        onClick={() => setShowAddPackageSets(true)}
      >
        <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
          Add Package Set
        </p>
      </Button>
      )}
            

            {showAddPackageSets === true && (
              <ManagePackageSets
                show={showAddPackageSets}
                onHide={closeAddPackageSets}
                action={"add"}
              />
            )}

            
          </div>
        </Col>
      </Row>
      <div>
        <Card style={{ overflow: "hidden", paddingBottom: "100px" }}>
          <div container-fullwidth="true" style={{ margin: "2%" }}>
            <Row
              className="mx-auto"
              style={{ display: "flex", justifyContent: "right" }}
            ></Row>
            <Row>
              <Col md={3} sm={12}>
                <Search
                  placeholder="Search"
                  value={searchString}
                  onChange={(e) => handleChange(e)}
                  onKeyDown={onKeyDown}
                ></Search>
              </Col>
              <Col md={3} sm={12}>
                <UncontrolledButtonDropdown className="mr-1 mb-1">
                  <DropdownToggle caret color="success">
                    {"Search By " + capitalize(searchBy) + " "}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => {
                        setSearchBy("Package Set");
                      }}
                    >
                      Package Set
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        setSearchBy("Package Name");
                      }}
                    >
                      Package Name
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </Col>
            </Row>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <CardBody>
              <BootstrapTable
                bootstrap4={true}
                wrapperClasses="table-responsive"
                hover={true}
                bordered={false}
                keyField="id"
                data={filterPackageSets(searchBy)}
                columns={tableColumns}
                expandRow={expandRow}
                filter={filterFactory()}
                pagination={paginationFactory({
                  sizePerPage: 10,
                  sizePerPageList: [5, 10, 25, 50],
                })}
                  onTableChange={onTableChange}
                remote={{ filter: true }}
                noDataIndication="No results found"
              />
            </CardBody>
          )}
        </Card>
      </div>
    </Container>
  );
};

export default PackageSets;

import { retrieveFromLocalStorage } from "../localStorageUtil";
import { host } from "./index";

// List all deeplinks
export let fetchDeeplinks = async () => {
    let url = `${host}/v4/internal/deeplinks`;
    let token = retrieveFromLocalStorage("token");

    try {
        var response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
        return await response.json();
    } catch (error) {
        return ;
    }

}

// Creates deeplink
export let createDeeplink = async (newDeeplink) => {
    let url = `${host}/v4/internal/deeplinks`;
    let token = retrieveFromLocalStorage("token");

    try {
        var response = await fetch(url, {
            method: "POST",
            body: newDeeplink,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",

            },
        })
        return await response.json();
    } catch (error) {
        return ;
    }

}

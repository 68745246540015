import React from "react";
import { Modal } from "react-bootstrap";
import { formatJSON } from "../../../utilities/keyboardLanguageUtil";
import TextareaAutosize from "react-textarea-autosize";

import { Badge } from "reactstrap";

export const ContentSearchDetailsModal = (props) => {
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        centered
        scrollable={true}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Content ID : {props.data?.type} - {props.data?.id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            <li>
              <b>Content Generic Search Tags : </b>
              {props?.data?.genericSearchTags?.map((tag, i) => {
                return (
                  <Badge
                    className="badge-primary"
                    style={{ margin: 5 }}
                    key={i}
                  >
                    {tag}
                  </Badge>
                );
              })}
            </li>
            <li>
              <b>Content Generic OTF Tags : </b>
              {props?.data?.genericOTFTags?.map((tag, i) => {
                return (
                  <Badge className="badge-info" style={{ margin: 5 }} key={i}>
                    {tag}
                  </Badge>
                );
              })}
            </li>
            <li>
              <li>
                <li>
                  <b>Overall Content Score = </b>{" "}
                  {props?.data?.finalContentScore}{" "}
                  <ul>
                    <li>
                      Distribution
                      <ul>
                        <li>
                          {" "}
                          <b>Computed Content Score = </b>{" "}
                          {props?.data?.contentScoreDetails?.score}
                          <ul>
                            <li>
                              Distribution
                              <ul>
                                <li>
                                  Historical Popularity Score ={" "}
                                  <strong>
                                    {
                                      props?.data
                                        ?.historicalPopularityScore
                                    }
                                  </strong>
                                  <ul>
                                    <li> Distribution </li>
                                    <ul>
                                    <li>
                                        Content Historical Shares ={" "}
                                        <strong>
                                          {
                                            props?.data
                                              ?.historicalShares
                                          }
                                        </strong>
                                      </li>
                                      <li>
                                        Max Historical Shares ={" "}
                                        <strong>
                                          {
                                            props?.data
                                              ?.historicalMaxShares
                                          }
                                        </strong>
                                      </li>
                                      <li>
                                        Min Historical Shares ={" "}
                                        <strong>
                                          {
                                            props?.data
                                              ?.historicalMinShares
                                          }
                                        </strong>
                                      </li>
                                    </ul>
                                  </ul>
                                </li>
                                <li>
                                  Recent Popularity Score ={" "}
                                  <strong>
                                    {
                                      props?.data
                                        ?.recentPopularityScore
                                    }
                                  </strong>
                                  <ul>
                                    Distribution
                                    <ul>
                                      <li>
                                        Recent Content Shares ={" "}
                                        <strong>
                                          {
                                            props?.data
                                              ?.recentShares
                                          }
                                        </strong>
                                      </li>
                                      <li>
                                        Recent Max Shares ={" "}
                                        <strong>
                                          {
                                            props?.data
                                              ?.recentMaxShares
                                          }
                                        </strong>
                                      </li>
                                      <li>
                                        Recent Min Shares ={" "}
                                        <strong>
                                          {
                                            props?.data
                                              ?.recentMinShares
                                          }
                                        </strong>
                                      </li>
                                    </ul>
                                  </ul>
                                </li>
                                <li>
                                  Freshness Score ={" "}
                                  <strong>
                                    {props?.data?.freshenessScore}
                                  </strong>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li>
                          {" "}
                          <b>Search Context Match Score = </b>{" "}
                          {
                            props?.data?.contentScoreDetails?.searchContextMatch
                              ?.score
                          }
                          <ul>
                            <li>
                              Distribution
                              <ul>
                                <li>
                                  {" "}
                                  <b>Search Tag Match Score = </b>{" "}
                                  {
                                    props?.data?.contentScoreDetails
                                      ?.searchContextMatch?.searchTagMatch
                                      ?.score
                                  }
                                </li>
                                <li>
                                  {" "}
                                  <b>OTF Tag Match Score = </b>{" "}
                                  {
                                    props?.data?.contentScoreDetails
                                      ?.searchContextMatch?.otfTagMatch?.score
                                  }
                                </li>
                              </ul>
                              <ul></ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </li>
            </li>
          </ul>
          <ul>
            <h4 style={{ textAlign: "center" }}>Matched Tags </h4>
            <table
              border="1"
              style={{
                width: "100%",
                borderCollapse: "collapse",
                marginTop: "20px",
              }}
            >
              <thead>
                <tr style={{ textAlign: "center", backgroundColor: "#f2f2f2" }}>
                  <th>Matched Tag Type</th>
                  <th>Matched Tag </th>
                  <th>Score</th>
                  <th>Percentage</th>
                </tr>
              </thead>
              <tbody>
                {props?.data?.contentScoreDetails?.searchContextMatch?.searchTagMatch?.distribution?.matchedTagsScores?.map(
                  (row) => (
                    <tr
                      key={row.id}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      <td>Generic Search Tag</td>
                      <td>
                        <Badge
                          className="badge-primary"
                          style={{ margin: 5 }}
                          key={row.id}
                        >
                          {row.tagName}
                        </Badge>
                      </td>
                      <td>{row.score}</td>
                      <td>{row.percentage}</td>
                    </tr>
                  )
                )}
                {props?.data?.contentScoreDetails?.searchContextMatch?.otfTagMatch?.distribution?.matchedTagsScores?.map(
                  (row) => (
                    <tr
                      key={row.id}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      <td>Generic OTF Tag</td>
                      <Badge
                        className="badge-info"
                        style={{ margin: 5 }}
                        key={row.id}
                      >
                        {row.tagName}
                      </Badge>
                      <td>{row.score}</td>
                      <td>{row.percentage}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </ul>
          <ul>
            <h4 style={{ textAlign: "center" }}>
              Elasticsearch Explain API Details
            </h4>
            <TextareaAutosize
              defaultValue={formatJSON(
                JSON.stringify(props?.data?.explanation)
              )}
              placeholder="Value"
              name="value"
              style={{
                width: "100%",
                color: "#495057",
                backgroundClip: "padding-box",
                border: "1px solid #ced4da",
                borderRadius: "0.2rem",
                outlineColor: "#CFEBEE",
                paddingLeft: "12px",
                padding: "0.25rem 0.7rem",
                fontSize: "0.875rem",
                fontWeight: "400",
                lineHeight: "1.5",
              }}
              minRows={2}
              maxRows={15}
              disabled={true}
            />
          </ul>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ContentSearchDetailsModal;

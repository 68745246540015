import React, { useState } from "react";
import { FiArrowUpLeft } from "react-icons/fi";
import { MdOutlineEdit } from "react-icons/md";
import { Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row, CustomInput, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { IoCloseCircleOutline } from "react-icons/io5";
import { getParsedHTML, sectionGrayedOutStyle, uiProductsCardTwoImagePlaceholder, uiProductsCardThreeImagePlaceholder, staticIconSize, animatedIconSize, fileSizeExceedError, imageFormatError, aspectRatioError, creativeUITypeAcceptedIconFormat, uiProductsCardThreeAspectRatio, uiProductsCardThreeAspectRatioError, uiProductsCardTwoAspectRatio, uiProductsCardTwoAspectRatioError, calculateInputLength, getCreativeUILengthLimits } from "../../../config/configSmartSuggestions";
import ToolTip from "../../../utilities/ToolTip";
import { truncateDecimals } from "../../../utilities/commonUtil";

const SmartSuggestionDetails = ({
  isMetadataOverride = false,
  disabled = false,
  showDuplicate = false,
  details,
  creatives,
  creativeUIDetails,
  detailsCardFieldsEdit,
  setDetailsCardFieldsEdit = () => { },
  setCreativeUIDetails = () => { },
  setCreatives = () => { },
  setAction = () => { },
  setCustomAppDetails = () => { },
  handleDuplicate = () => { },
  showErrorModal = () => { },
}) => {
  const [productsCardActiveTab, setProductsCardActiveTab] = useState(1)
  const [creativeUILengthLimit] = useState(getCreativeUILengthLimits())

  const handleCreativesChange = (event, type) => {
    const { name, files, accept } = event.target;
    const acceptedFormat = accept.split(",").map(format => format.trim())
    event.persist();
    // For showing Image preview
    if (files[0]) {
      // Check for size
      if ((type === "static" && (files[0].size > staticIconSize)) || (type === "animated" && (files[0].size > animatedIconSize))) {
        let text = fileSizeExceedError;
        showErrorModal(text);
        setCreatives((prev) => ({
          ...prev,
          [name]: null,
          [name + "Src"]: "",
          [name + "AspectRatio"]: null,
          [name + "Filename"]: "",
        }));
        return
      }
      // Check if format is accepted
      if (!acceptedFormat.includes(files[0].type)) {
        let text = imageFormatError;
        showErrorModal(text);
        setCreatives((prev) => ({
          ...prev,
          [name]: null,
          [name + "Src"]: "",
          [name + "AspectRatio"]: null,
          [name + "Filename"]: "",
        }));
        return
      }

      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          // Check if image is square
          if (image.width !== image.height) {
            let text = aspectRatioError;
            showErrorModal(text);
            setCreatives((prev) => ({
              ...prev,
              [name]: null,
              [name + "Src"]: "",
              [name + "AspectRatio"]: null,
              [name + "Filename"]: "",
            }));
            return
          }

          setCreatives((prev) => ({
            ...prev,
            [name]: files[0],
            [name + "Src"]: reader.result,
            [name + "AspectRatio"]: Number(image.width / image.height).toFixed(3),
            [name + "Filename"]: files[0]?.name,
          }));
        }
      };
    } else {
      setCreatives((prev) => ({
        ...prev,
        [name]: null,
        [name + "Src"]: "",
        [name + "AspectRatio"]: null,
        [name + "Filename"]: "",
      }));
    }
  };

  const handleCreativeUIDetailsChange = (event, field) => {
    const { name, value } = event.target
    setCreativeUIDetails((prevState) => ({
      ...prevState,
      [field]: {
        ...prevState[field],
        [name]: value
      }
    }))
  }

  const handleCreativeUIIconUpload = (event, field) => {
    const { files } = event.target;
    const file = files[0]

    // Check file size
    if (file.size > animatedIconSize) {
      let text = fileSizeExceedError;
      showErrorModal(text);
      setCreativeUIDetails((prev) => ({
        ...prev,
        [field]: {
          ...prev[field],
          "iconURL": "",
          "iconAspectRatio": null,
          "iconFilename": "",
        }
      }));
      return
    }

    // Check if format is accepted
    if (!creativeUITypeAcceptedIconFormat.includes(file.type)) {
      let text = imageFormatError;
      showErrorModal(text);
      setCreativeUIDetails((prev) => ({
        ...prev,
        [field]: {
          ...prev[field],
          "iconURL": "",
          "iconAspectRatio": null,
          "iconFilename": "",
        }
      }));
      return
    }

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const image = new Image();
      image.src = reader.result;
      image.onload = () => {
        const imageAspectRatio = truncateDecimals(Number(image.width / image.height), 3)

        // Check aspect ratio for products card three UI
        const uiProductsCardThreeAspectRatioMinLimit = uiProductsCardThreeAspectRatio - uiProductsCardThreeAspectRatio * 0.1
        const uiProductsCardThreeAspectRatioMaxLimit = uiProductsCardThreeAspectRatio + uiProductsCardThreeAspectRatio * 0.1

        if (field === "uiProductsCardThreeDetails" && (imageAspectRatio < uiProductsCardThreeAspectRatioMinLimit || imageAspectRatio > uiProductsCardThreeAspectRatioMaxLimit)) {
          let text = uiProductsCardThreeAspectRatioError;
          showErrorModal(text);
          setCreativeUIDetails((prev) => ({
            ...prev,
            [field]: {
              ...prev[field],
              "iconURL": "",
              "iconAspectRatio": null,
              "iconFilename": "",
            }
          }));
          return
        }
        // Check aspect ratio for products card two UI
        const uiProductsCardTwoAspectRatioMinLimit = uiProductsCardTwoAspectRatio - uiProductsCardTwoAspectRatio * 0.1
        const uiProductsCardTwoAspectRatioMaxLimit = uiProductsCardTwoAspectRatio + uiProductsCardTwoAspectRatio * 0.1

        if (field === "uiProductsCardTwoDetails" && (imageAspectRatio < uiProductsCardTwoAspectRatioMinLimit || imageAspectRatio > uiProductsCardTwoAspectRatioMaxLimit)) {
          let text = uiProductsCardTwoAspectRatioError;
          showErrorModal(text);
          setCreativeUIDetails((prev) => ({
            ...prev,
            [field]: {
              ...prev[field],
              "iconURL": "",
              "iconAspectRatio": null,
              "iconFilename": "",
            }
          }));
          return
        }
        // Check if image is square (for tiles, details and products card UI)
        if ((field === "uiTilesDetails" || field === "uiDetailsCardDetails" || field === "uiProductsCardDetails") && image.width !== image.height) {
          let text = aspectRatioError;
          showErrorModal(text);
          setCreativeUIDetails((prev) => ({
            ...prev,
            [field]: {
              ...prev[field],
              "iconURL": "",
              "iconAspectRatio": null,
              "iconFilename": "",
            }
          }));
          return
        }

        setCreativeUIDetails((prev) => ({
          ...prev,
          [field]: {
            ...prev[field],
            "iconURL": reader.result,
            "iconAspectRatio": imageAspectRatio,
            "iconFilename": file?.name,
          }
        }));
      }
    };
  };

  const handleCreativeUIDetailsToggle = (event) => {
    const { id, checked } = event.target;
    // If id = uiProductsCard - if checked, then enable only 1:1 products card UI & if not checked, then disable all products card UI
    if (id === "uiProductsCard") {
      if (checked) {
        setCreativeUIDetails((prevState) => ({
          ...prevState,
          uiProductsCardDetails: {
            ...prevState["uiProductsCardDetails"],
            isDisabled: false
          },
          uiProductsCardTwoDetails: {
            ...prevState["uiProductsCardTwoDetails"],
            isDisabled: true
          },
          uiProductsCardThreeDetails: {
            ...prevState["uiProductsCardThreeDetails"],
            isDisabled: true
          },
        }))
        return
      }
      setCreativeUIDetails((prevState) => ({
        ...prevState,
        uiProductsCardDetails: {
          ...prevState["uiProductsCardDetails"],
          isDisabled: true
        },
        uiProductsCardTwoDetails: {
          ...prevState["uiProductsCardTwoDetails"],
          isDisabled: true
        },
        uiProductsCardThreeDetails: {
          ...prevState["uiProductsCardThreeDetails"],
          isDisabled: true
        },
      }))
      setDetailsCardFieldsEdit((prevState) => ({
        ...prevState,
        title: true,
        subTitle: true
      }))
      return
    }
    // Default case
    setCreativeUIDetails((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        isDisabled: !checked
      }
    }))

    // If products card 1:1 UI is disabled, then details card fields should be manually filled
    if (id === "uiProductsCardDetails" && !checked) {
      setDetailsCardFieldsEdit((prevState) => ({
        ...prevState,
        title: true,
        subTitle: true
      }))
    }
  }

  const handleToggleProductsCard = (index) => {
    setProductsCardActiveTab(index)
  }

  const handleResetIcon = (name) => {
    // If animated icon, then reset both animated & static icon
    if (name === "animatedIcon") {
      setCreatives((prev) => ({
        ...prev,
        animatedIcon: null,
        animatedIconSrc: "",
        animatedIconAspectRatio: null,
        animatedIconFilename: "",
        staticIcon: null,
        staticIconSrc: "",
        staticIconAspectRatio: null,
        staticIconFilename: "",
      }))
      return
    }

    // Default case
    setCreatives((prev) => ({
      ...prev,
      [name]: null,
      [name + "Src"]: "",
      [name + "AspectRatio"]: null,
      [name + "Filename"]: "",
    }))
  }

  const handleResetIconCards = (field) => {
    setCreativeUIDetails((prev) => ({
      ...prev,
      [field]: {
        ...prev[field],
        iconURL: "",
        iconAspectRatio: null,
        iconFilename: "",
      }
    }));
  };

  return (
    <>
      <Row>
        <Col lg="12">
          <Row className="mb-3">
            <Col md={6} xs={6}>
              {isMetadataOverride
                ? <h4>Choose Your Icon</h4>
                : <h3>Choose Your Icon</h3>
              }
            </Col>
            {showDuplicate && (
              <Col md={6} xs={6}>
                <Button className="float-right" onClick={handleDuplicate}>Duplicate Smart Suggestion</Button>
              </Col>
            )}
          </Row>
          <Card style={isMetadataOverride ? { boxShadow: "none" } : {}}>
            <CardBody style={isMetadataOverride ? { padding: 0 } : {}}>
              <Row form>
                <Col md={12} className="mb-4">
                  <div className="text-info font-weight-bolder">(Before uploading the icon, please compress it as much as possible to prevent excessive data-out)</div>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label>
                      <strong>
                        Static/Animated Icon{" "}
                      </strong>
                      <ToolTip
                        data="Formats: png, jpg, jpeg, gif, webp, Aspect ratio: 1:1, Size: 200KB"
                        id="staticAnimatedIconTooltip"
                      />
                    </Label>
                    {isMetadataOverride
                      ? (creatives.animatedIconOverride || creatives.staticIconOverride) && (creatives.animatedIconSrc || creatives.staticIconSrc) && (
                        <span
                          onClick={() => handleResetIcon("animatedIcon")}
                          className="ml-2 text-danger text-decoration-underline cursor-pointer"
                          style={{ textDecoration: "underline" }}
                        >
                          Reset
                        </span>
                      ) : (creatives.animatedIconSrc || creatives.staticIconSrc) && (
                        <span
                          onClick={() => handleResetIcon("animatedIcon")}
                          className="ml-2 text-danger text-decoration-underline cursor-pointer"
                          style={{ textDecoration: "underline" }}
                        >
                          Reset
                        </span>
                      )}
                    <Container className="custom-file">
                      <Input
                        type="file"
                        className="custom-file-input"
                        accept="image/png, image/jpg, image/jpeg, image/gif, image/webp"
                        name="animatedIcon"
                        onChange={(event) => {
                          handleCreativesChange(event, "animated")
                        }}
                        disabled={disabled}
                      />
                      <Label
                        className="custom-file-label"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis"
                        }}
                      >
                        <strong>{creatives.animatedIconFilename || creatives.staticIconFilename || "Choose an image"}</strong>
                      </Label>
                    </Container>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label>
                      <strong>
                        Provider Icon{" "}
                      </strong>
                      <ToolTip
                        data="Formats: png, jpg, jpeg, webp, Aspect ratio: 1:1, Size: 200KB"
                        id="providerIconTooltip"
                      />
                    </Label>
                    {isMetadataOverride
                      ? (creatives.providerIconOverride && creatives.providerIconSrc) && (
                        <span
                          onClick={() => handleResetIcon("providerIcon")}
                          className="ml-2 text-danger text-decoration-underline cursor-pointer"
                          style={{ textDecoration: "underline" }}
                        >
                          Reset
                        </span>
                      ) : (creatives.providerIconSrc) && (
                        <span
                          onClick={() => handleResetIcon("providerIcon")}
                          className="ml-2 text-danger text-decoration-underline cursor-pointer"
                          style={{ textDecoration: "underline" }}
                        >
                          Reset
                        </span>
                      )}
                    <Container className="custom-file">
                      <Input
                        type="file"
                        className="custom-file-input"
                        accept="image/png, image/jpg, image/jpeg, image/webp"
                        name="providerIcon"
                        onChange={(event) => {
                          handleCreativesChange(event, "static")
                        }}
                        disabled={disabled}
                      />
                      <Label
                        className="custom-file-label"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis"
                        }}
                      >
                        <strong>{creatives.providerIconFilename || "Choose a static image"}</strong>
                      </Label>
                    </Container>
                  </FormGroup>
                </Col>
                <Col className="creativeTypePreview tiles primary">
                  <div className="d-flex flex-column creativeTypePreviewCard active align-items-start">
                    <div className="position-relative" style={{ height: "fit-content", width: "fit-content" }}>
                      <img
                        className="primary-icon"
                        src={creatives.animatedIconSrc || creatives.staticIconSrc}
                        width="100"
                        alt="Preview Icon"
                      />
                      {creatives.providerIconSrc && (
                        <img
                          className="position-absolute secondary-icon"
                          style={{ bottom: -6, right: -6 }}
                          src={creatives.providerIconSrc}
                          width="20"
                          alt="Provider Icon"
                        />
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <Row className="mb-3">
            <Col md={6} xs={6}>
              <div className="d-flex align-items-center">
                <CustomInput
                  type="checkbox"
                  id="uiTilesDetails"
                  onChange={handleCreativeUIDetailsToggle}
                  checked={!creativeUIDetails.uiTilesDetails?.isDisabled}
                  disabled={disabled}
                />
                {isMetadataOverride
                  ? <h4 className="mb-0 mr-1">Tiles Card</h4>
                  : <h3 className="mb-0 mr-1">Tiles Card</h3>
                }
                <ToolTip
                  data={`Tiles UI is primarily used on playstore, appstore, and contacts placements.`}
                  id="tilesUITooltip"
                />
              </div>
            </Col>
          </Row>
          <Card style={isMetadataOverride ? { boxShadow: "none" } : {}}>
            {creativeUIDetails.uiTilesDetails?.isDisabled && <div className="block-section w-100 h-100"></div>}
            <CardBody style={isMetadataOverride ? { padding: 0 } : {}}>
              <Row form>
                <Col md={6}>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          <strong>
                            Static/Animated Icon{" "}
                          </strong>
                          <ToolTip
                            data="Formats: png, jpg, jpeg, gif, webp, Aspect ratio: 1:1, Size: 200KB"
                            id="tilesStaticAnimatedIconTooltip"
                          />
                        </Label>
                        {isMetadataOverride
                          ? (creativeUIDetails.uiTilesDetails?.iconOverride && creativeUIDetails.uiTilesDetails?.iconURL) && (
                            <span
                              onClick={() => handleResetIconCards("uiTilesDetails")}
                              className="ml-2 text-danger text-decoration-underline cursor-pointer"
                              style={{
                                textDecoration: "underline"
                              }}
                            >
                              Reset
                            </span>
                          ) : (creativeUIDetails.uiTilesDetails?.iconURL) && (
                            <span
                              onClick={() => handleResetIconCards("uiTilesDetails")}
                              className="ml-2 text-danger text-decoration-underline cursor-pointer"
                              style={{
                                textDecoration: "underline"
                              }}
                            >
                              Reset
                            </span>
                          )}
                        <Container className="custom-file">
                          <Input
                            type="file"
                            className="custom-file-input"
                            accept="image/png, image/jpg, image/jpeg, image/gif, image/webp"
                            name="animatedIcon"
                            onChange={(event) => handleCreativeUIIconUpload(event, "uiTilesDetails")}
                            disabled={disabled}
                          />
                          <Label
                            className="custom-file-label"
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis"
                            }}
                          >
                            <strong>{creativeUIDetails.uiTilesDetails?.iconFilename || creatives.animatedIconFilename || creatives.staticIconFilename || "Choose an image"}</strong>
                          </Label>
                        </Container>
                      </FormGroup>
                    </Col>
                    <Col md={6}></Col>
                    <Col md={12}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2 w-100"
                          style={{ fontSize: "14px" }}
                        >
                          Title <span className="text-danger">*</span><small> (Tiles card only)</small><small className="text-info float-right">Upto {creativeUILengthLimit.tilesCard.title} characters</small>
                        </Label>
                        <Input
                          type="textarea"
                          name="title"
                          placeholder="Enter Title"
                          value={creativeUIDetails.uiTilesDetails?.title ?? details.title ?? ""}
                          onChange={(event) => {
                            const { value } = event.target
                            if (!isMetadataOverride && value?.includes("__KWD__")) {
                              setAction(prevState => ({
                                ...prevState,
                                isDynamic: true
                              }))
                              setCustomAppDetails((prevDetails) =>
                                prevDetails.map((item) => ({
                                  ...item,
                                  isDynamic: value?.includes("__KWD__")
                                    ? true
                                    : !item.clickUrl?.includes("__KWD__") ? false : item.isDynamic,
                                }))
                              );
                            }
                            if (calculateInputLength(value) > creativeUILengthLimit.tilesCard.title) {
                              event.preventDefault();
                              return;
                            }
                            handleCreativeUIDetailsChange(event, "uiTilesDetails")
                          }}
                          disabled={disabled || creativeUIDetails.uiTilesDetails?.isDisabled}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <hr />
                      <div className="d-flex align-items-center mb-2">
                        <h5 className="mb-0 mr-1">Playstore Typing State</h5>
                      </div>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2 w-100"
                          style={{ fontSize: "14px" }}
                        >
                          Title <span className="text-danger">*</span><small className="text-info float-right">Upto {creativeUILengthLimit.tilesCard.titleLong} characters</small>
                        </Label>
                        <Input
                          type="textarea"
                          name="titleLong"
                          placeholder="Enter Title"
                          value={creativeUIDetails.uiTilesDetails?.titleLong ?? (creativeUIDetails.uiDetailsCardDetails?.title || creativeUIDetails.uiProductsCardDetails?.title || details.titleLong || details.title) ?? ""}
                          onChange={(event) => {
                            const { value } = event.target
                            if (calculateInputLength(value) > creativeUILengthLimit.tilesCard.titleLong) {
                              event.preventDefault();
                              return;
                            }
                            handleCreativeUIDetailsChange(event, "uiTilesDetails")
                          }}
                          disabled={disabled || creativeUIDetails.uiTilesDetails?.isDisabled}
                          required
                        />
                      </FormGroup>
                    </Col>
                    {/* <Col md={12}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2 w-100"
                          style={{ fontSize: "14px" }}
                        >
                          Sub Title <small className="text-info float-right">Upto {creativeUILengthLimit.tilesCard.subTitle} characters</small>
                        </Label>
                        <Input
                          type="textarea"
                          name="subTitle"
                          placeholder="Enter Sub Title"
                          value={creativeUIDetails.uiTilesDetails?.subTitle ?? (creativeUIDetails.uiDetailsCardDetails?.subTitle || creativeUIDetails.uiProductsCardDetails?.subTitle || details.subTitle) ?? ""}
                          onChange={(event) => {
                            const { value } = event.target
                            if (calculateInputLength(value) > creativeUILengthLimit.tilesCard.subTitle) {
                              event.preventDefault();
                              return;
                            }
                            handleCreativeUIDetailsChange(event, "uiTilesDetails")
                          }}
                          disabled={disabled || creativeUIDetails.uiTilesDetails?.isDisabled}
                        />
                      </FormGroup>
                    </Col> */}
                  </Row>
                </Col>
                <Col md={6}>
                  <Row className="h-100">
                    <Col md={12} style={{ flex: "auto" }} className="creativeTypePreview tiles">
                      <div className="d-flex flex-column creativeTypePreviewCard active align-items-start">
                        <div className="position-relative" style={{ height: "fit-content", width: "fit-content" }}>
                          <img
                            className="primary-icon"
                            src={creativeUIDetails.uiTilesDetails?.iconURL || creatives.animatedIconSrc || creatives.staticIconSrc}
                            width="80"
                            alt="Preview Icon"
                          />
                          {creatives.providerIconSrc && (
                            <img
                              className="position-absolute secondary-icon"
                              style={{ bottom: -6, right: -6 }}
                              src={creatives.providerIconSrc}
                              width="20"
                              alt="Provider Icon"
                            />
                          )}
                        </div>
                        <div className="mt-3 text-center two-lines-ellipsis" style={{ color: "#000", fontSize: 14, fontWeight: 400, width: 80, lineHeight: "1.1" }} dangerouslySetInnerHTML={{ __html: getParsedHTML(creativeUIDetails.uiTilesDetails?.title ?? details.title) }}></div>
                      </div>
                    </Col>
                    <Col md={12} style={{ flex: "auto" }} className="creativeTypePreview detailsCard">
                      <div className="d-flex creativeTypePreviewCard active" style={{ width: 550 }}>
                        <div className="position-relative py-1 pl-1">
                          <img
                            className="primary-icon"
                            src={creativeUIDetails.uiTilesDetails?.iconURL || creatives.animatedIconSrc || creatives.staticIconSrc}
                            height="60"
                            alt="Preview Icon"
                          />
                          {creatives.providerIconSrc && (
                            <img
                              className="position-absolute secondary-icon"
                              style={{ bottom: -2, right: -2 }}
                              src={creatives.providerIconSrc}
                              width="16"
                              alt="Provider Icon"
                            />
                          )}
                        </div>
                        <div className="py-1 pl-2 w-100 creativeCardPreviewContent d-flex flex-column justify-content-center">
                          <div className="one-lines-ellipsis" style={{ color: "#000", fontSize: 24, fontWeight: 400, lineHeight: "1.1" }} dangerouslySetInnerHTML={{
                            __html: getParsedHTML(creativeUIDetails.uiTilesDetails?.titleLong ?? (creativeUIDetails.uiDetailsCardDetails?.title || creativeUIDetails.uiProductsCardDetails?.title || details.titleLong || details.title))
                          }}></div>
                          {/* <div className="one-lines-ellipsis mt-2" style={{ color: "#949494", fontSize: 16, fontWeight: 400, lineHeight: "1.1" }} dangerouslySetInnerHTML={{
                            __html: getParsedHTML(creativeUIDetails.uiTilesDetails?.subTitle ?? (creativeUIDetails.uiDetailsCardDetails?.subTitle || creativeUIDetails.uiProductsCardDetails?.subTitle || details.subTitle))
                          }}></div> */}
                        </div>
                        <div className="d-flex align-items-center justify-content-center pr-1" style={{ background: "#fff", borderRadius: "0 10px 10px 0" }}>
                          <FiArrowUpLeft size={32} color="#a9a9a9" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <Row className="mb-3">
            <Col md={6} xs={6}>
              <div className="d-flex align-items-center">
                <CustomInput
                  type="checkbox"
                  id="uiProductsCard"
                  onChange={handleCreativeUIDetailsToggle}
                  checked={!creativeUIDetails.uiProductsCardDetails?.isDisabled || !creativeUIDetails.uiProductsCardTwoDetails?.isDisabled || !creativeUIDetails.uiProductsCardThreeDetails?.isDisabled}
                  disabled={disabled}
                />
                {isMetadataOverride
                  ? <h4 className="mb-0 mr-1">Product Cards</h4>
                  : <h3 className="mb-0 mr-1">Product Cards</h3>
                }
                <ToolTip
                  data={`Products card UI is used on placements other than playstore, appstore, contacts and browser.`}
                  id="productsUITooltip"
                />
              </div>
            </Col>
          </Row>
          <Card style={isMetadataOverride ? { boxShadow: "none" } : {}}>
            {(creativeUIDetails.uiProductsCardDetails?.isDisabled && creativeUIDetails.uiProductsCardTwoDetails?.isDisabled && creativeUIDetails.uiProductsCardThreeDetails?.isDisabled) && <div className="block-section w-100 h-100"></div>}
            <CardBody style={isMetadataOverride ? { padding: 0 } : {}}>
              <Row form>
                <Col md={6}>
                  <Nav tabs className="customTabs">
                    <NavItem>
                      <NavLink
                        className={`d-flex align-items-center ${productsCardActiveTab === 1 ? "active" : ""}`}
                        onClick={() => handleToggleProductsCard(1)}
                      >
                        1:1 Card
                        <CustomInput
                          type="checkbox"
                          className="ml-2"
                          id="uiProductsCardDetails"
                          onChange={handleCreativeUIDetailsToggle}
                          checked={!creativeUIDetails.uiProductsCardDetails?.isDisabled}
                          disabled={disabled}
                        />
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={`d-flex align-items-center ${productsCardActiveTab === 2 ? "active" : ""}`}
                        onClick={() => handleToggleProductsCard(2)}
                      >
                        4:3 Card
                        <CustomInput
                          type="checkbox"
                          className="ml-2"
                          id="uiProductsCardTwoDetails"
                          onChange={handleCreativeUIDetailsToggle}
                          checked={!creativeUIDetails.uiProductsCardTwoDetails?.isDisabled}
                          disabled={disabled}
                        />
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={`d-flex align-items-center ${productsCardActiveTab === 3 ? "active" : ""}`}
                        onClick={() => handleToggleProductsCard(3)}
                      >
                        16:9 Card
                        <CustomInput
                          type="checkbox"
                          className="ml-2"
                          id="uiProductsCardThreeDetails"
                          onChange={handleCreativeUIDetailsToggle}
                          checked={!creativeUIDetails.uiProductsCardThreeDetails?.isDisabled}
                          disabled={disabled}
                        />
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={productsCardActiveTab} className="pt-4">
                    <TabPane tabId={1} className="position-relative">
                      {creativeUIDetails.uiProductsCardDetails?.isDisabled && <div className="block-section w-100 h-100"></div>}
                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label>
                              <strong>
                                Static/Animated Icon{" "}
                              </strong>
                              <ToolTip
                                data="Formats: png, jpg, jpeg, gif, webp, Aspect ratio: 1:1, Size: 200KB"
                                id="productsStaticAnimatedIconTooltip"
                              />
                            </Label>
                            {isMetadataOverride
                              ? (creativeUIDetails.uiProductsCardDetails?.iconOverride && creativeUIDetails.uiProductsCardDetails?.iconURL) && (
                                <span
                                  onClick={() => handleResetIconCards("uiProductsCardDetails")}
                                  className="ml-2 text-danger text-decoration-underline cursor-pointer"
                                  style={{
                                    textDecoration: "underline",
                                  }}
                                >
                                  Reset
                                </span>
                              ) : (creativeUIDetails.uiProductsCardDetails?.iconURL) && (
                                <span
                                  onClick={() => handleResetIconCards("uiProductsCardDetails")}
                                  className="ml-2 text-danger text-decoration-underline cursor-pointer"
                                  style={{
                                    textDecoration: "underline",
                                  }}
                                >
                                  Reset
                                </span>
                              )}
                            <Container className="custom-file">
                              <Input
                                type="file"
                                className="custom-file-input"
                                accept="image/png, image/jpg, image/jpeg, image/gif, image/webp"
                                name="animatedIcon"
                                onChange={(event) => handleCreativeUIIconUpload(event, "uiProductsCardDetails")}
                                disabled={disabled}
                              />
                              <Label
                                className="custom-file-label"
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis"
                                }}
                              >
                                <strong>{creativeUIDetails.uiProductsCardDetails?.iconFilename || creatives.animatedIconFilename || creatives.staticIconFilename || "Choose an image"}</strong>
                              </Label>
                            </Container>
                          </FormGroup>
                        </Col>
                        <Col md={6}></Col>
                        <Col md={12}>
                          <FormGroup>
                            <Label
                              className="font-weight-bolder mb-2 w-100"
                              style={{ fontSize: "14px" }}
                            >
                              Title <span className="text-danger">*</span><small className="text-info float-right">Upto {creativeUILengthLimit.productsCard.title} characters</small>
                            </Label>
                            <Input
                              type="textarea"
                              name="title"
                              placeholder="Enter Title"
                              value={creativeUIDetails.uiProductsCardDetails?.title ?? (details.titleLong || details.title) ?? ""}
                              onChange={(event) => {
                                const { value } = event.target
                                if (!isMetadataOverride && value?.includes("__KWD__")) {
                                  setAction(prevState => ({
                                    ...prevState,
                                    isDynamic: true
                                  }))
                                  setCustomAppDetails((prevDetails) =>
                                    prevDetails.map((item) => ({
                                      ...item,
                                      isDynamic: value?.includes("__KWD__")
                                        ? true
                                        : !item.clickUrl?.includes("__KWD__") ? false : item.isDynamic,
                                    }))
                                  );
                                }
                                if (calculateInputLength(value) > creativeUILengthLimit.productsCard.title) {
                                  event.preventDefault();
                                  return;
                                }
                                handleCreativeUIDetailsChange(event, "uiProductsCardDetails")
                              }}
                              disabled={disabled || creativeUIDetails.uiProductsCardDetails?.isDisabled}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          <FormGroup>
                            <Label
                              className="font-weight-bolder mb-2 w-100"
                              style={{ fontSize: "14px" }}
                            >
                              Sub Title<small className="text-info float-right">Upto {creativeUILengthLimit.productsCard.subTitle} characters</small>
                            </Label>
                            <Input
                              type="textarea"
                              name="subTitle"
                              placeholder="Enter Sub Title"
                              value={creativeUIDetails.uiProductsCardDetails?.subTitle ?? details.subTitle ?? ""}
                              onChange={(event) => {
                                const { value } = event.target
                                if (calculateInputLength(value) > creativeUILengthLimit.productsCard.subTitle) {
                                  event.preventDefault();
                                  return;
                                }
                                handleCreativeUIDetailsChange(event, "uiProductsCardDetails")
                              }}
                              disabled={disabled}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          <FormGroup>
                            <Label
                              className="font-weight-bolder mb-2 w-100"
                              style={{ fontSize: "14px" }}
                            >
                              CTA<small className="text-info float-right">Upto {creativeUILengthLimit.productsCard.description} characters</small>
                            </Label>
                            <Input
                              type="textarea"
                              name="description"
                              placeholder="Enter CTA"
                              value={creativeUIDetails.uiProductsCardDetails?.description ?? details.description ?? ""}
                              onChange={(event) => {
                                const { value } = event.target
                                if (calculateInputLength(value) > creativeUILengthLimit.productsCard.description) {
                                  event.preventDefault();
                                  return;
                                }
                                handleCreativeUIDetailsChange(event, "uiProductsCardDetails")
                              }}
                              disabled={disabled}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId={2} className="position-relative">
                      {creativeUIDetails.uiProductsCardTwoDetails?.isDisabled && <div className="block-section w-100 h-100"></div>}
                      <h4 className="text-danger mb-3">For new versions only</h4>
                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label>
                              <strong>
                                Static/Animated Icon{" "}
                              </strong>
                              <ToolTip
                                data="Formats: png, jpg, jpeg, gif, webp, Aspect ratio: 4:3, Size: 200KB"
                                id="productsTwoStaticAnimatedIconTooltip"
                              />
                            </Label>
                            {isMetadataOverride
                              ? (creativeUIDetails.uiProductsCardTwoDetails?.iconOverride && creativeUIDetails.uiProductsCardTwoDetails?.iconURL) && (
                                <span
                                  onClick={() => handleResetIconCards("uiProductsCardTwoDetails")}
                                  className="ml-2 text-danger text-decoration-underline cursor-pointer"
                                  style={{
                                    textDecoration: "underline",
                                  }}
                                >
                                  Reset
                                </span>
                              ) : (creativeUIDetails.uiProductsCardTwoDetails?.iconURL) && (
                                <span
                                  onClick={() => handleResetIconCards("uiProductsCardTwoDetails")}
                                  className="ml-2 text-danger text-decoration-underline cursor-pointer"
                                  style={{
                                    textDecoration: "underline",
                                  }}
                                >
                                  Reset
                                </span>
                              )}
                            <Container className="custom-file">
                              <Input
                                type="file"
                                className="custom-file-input"
                                accept="image/png, image/jpg, image/jpeg, image/gif, image/webp"
                                name="animatedIcon"
                                onChange={(event) => handleCreativeUIIconUpload(event, "uiProductsCardTwoDetails")}
                                disabled={disabled}
                              />
                              <Label
                                className="custom-file-label"
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis"
                                }}
                              >
                                <strong>{creativeUIDetails.uiProductsCardTwoDetails?.iconFilename || "Choose an image"}</strong>
                              </Label>
                            </Container>
                          </FormGroup>
                        </Col>
                        <Col md={6}></Col>
                        <Col md={12}>
                          <FormGroup>
                            <Label
                              className="font-weight-bolder mb-2 w-100"
                              style={{ fontSize: "14px" }}
                            >
                              Title <span className="text-danger">*</span><small className="text-info float-right">Upto {creativeUILengthLimit.productsCardTwo.title} characters</small>
                            </Label>
                            <Input
                              type="textarea"
                              name="title"
                              placeholder="Enter Title"
                              value={creativeUIDetails.uiProductsCardTwoDetails?.title ?? (creativeUIDetails.uiProductsCardDetails?.title || details.titleLong || details.title) ?? ""}
                              onChange={(event) => {
                                const { value } = event.target
                                if (!isMetadataOverride && value?.includes("__KWD__")) {
                                  setAction(prevState => ({
                                    ...prevState,
                                    isDynamic: true
                                  }))
                                  setCustomAppDetails((prevDetails) =>
                                    prevDetails.map((item) => ({
                                      ...item,
                                      isDynamic: value?.includes("__KWD__")
                                        ? true
                                        : !item.clickUrl?.includes("__KWD__") ? false : item.isDynamic,
                                    }))
                                  );
                                }
                                if (calculateInputLength(value) > creativeUILengthLimit.productsCardTwo.title) {
                                  event.preventDefault();
                                  return;
                                }
                                handleCreativeUIDetailsChange(event, "uiProductsCardTwoDetails")
                              }}
                              disabled={disabled || creativeUIDetails.uiProductsCardTwoDetails?.isDisabled}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          <FormGroup>
                            <Label
                              className="font-weight-bolder mb-2 w-100"
                              style={{ fontSize: "14px" }}
                            >
                              Sub Title<small className="text-info float-right">Upto {creativeUILengthLimit.productsCardTwo.subTitle} characters</small>
                            </Label>
                            <Input
                              type="textarea"
                              name="subTitle"
                              placeholder="Enter Sub Title"
                              value={creativeUIDetails.uiProductsCardTwoDetails?.subTitle ?? (creativeUIDetails.uiProductsCardDetails?.subTitle || details.subTitle) ?? ""}
                              onChange={(event) => {
                                const { value } = event.target
                                if (calculateInputLength(value) > creativeUILengthLimit.productsCardTwo.subTitle) {
                                  event.preventDefault();
                                  return;
                                }
                                handleCreativeUIDetailsChange(event, "uiProductsCardTwoDetails")
                              }}
                              disabled={disabled}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          <FormGroup>
                            <Label
                              className="font-weight-bolder mb-2 w-100"
                              style={{ fontSize: "14px" }}
                            >
                              CTA<small className="text-info float-right">Upto {creativeUILengthLimit.productsCardTwo.description} characters</small>
                            </Label>
                            <Input
                              type="textarea"
                              name="description"
                              placeholder="Enter CTA"
                              value={creativeUIDetails.uiProductsCardTwoDetails?.description ?? (creativeUIDetails.uiProductsCardDetails?.description || details.description) ?? ""}
                              onChange={(event) => {
                                const { value } = event.target
                                if (calculateInputLength(value) > creativeUILengthLimit.productsCardTwo.description) {
                                  event.preventDefault();
                                  return;
                                }
                                handleCreativeUIDetailsChange(event, "uiProductsCardTwoDetails")
                              }}
                              disabled={disabled}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId={3} className="position-relative">
                      {creativeUIDetails.uiProductsCardThreeDetails?.isDisabled && <div className="block-section w-100 h-100"></div>}
                      <h4 className="text-danger mb-3">For new versions only</h4>
                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label>
                              <strong>
                                Static/Animated Icon{" "}
                              </strong>
                              <ToolTip
                                data="Formats: png, jpg, jpeg, gif, webp, Aspect ratio: 16:9, Size: 200KB"
                                id="productsThreeStaticAnimatedIconTooltip"
                              />
                            </Label>
                            {isMetadataOverride
                              ? (creativeUIDetails.uiProductsCardThreeDetails?.iconOverride && creativeUIDetails.uiProductsCardThreeDetails?.iconURL) && (
                                <span
                                  onClick={() => handleResetIconCards("uiProductsCardThreeDetails")}
                                  className="ml-2 text-danger text-decoration-underline cursor-pointer"
                                  style={{
                                    textDecoration: "underline",
                                  }}
                                >
                                  Reset
                                </span>
                              ) : (creativeUIDetails.uiProductsCardThreeDetails?.iconURL) && (
                                <span
                                  onClick={() => handleResetIconCards("uiProductsCardThreeDetails")}
                                  className="ml-2 text-danger text-decoration-underline cursor-pointer"
                                  style={{
                                    textDecoration: "underline",
                                  }}
                                >
                                  Reset
                                </span>
                              )}
                            <Container className="custom-file">
                              <Input
                                type="file"
                                className="custom-file-input"
                                accept="image/png, image/jpg, image/jpeg, image/gif, image/webp"
                                name="animatedIcon"
                                onChange={(event) => handleCreativeUIIconUpload(event, "uiProductsCardThreeDetails")}
                                disabled={disabled}
                              />
                              <Label
                                className="custom-file-label"
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis"
                                }}
                              >
                                <strong>{creativeUIDetails.uiProductsCardThreeDetails?.iconFilename || "Choose an image"}</strong>
                              </Label>
                            </Container>
                          </FormGroup>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </Col>
                <Col className="creativeTypePreview productsCard">
                  {productsCardActiveTab === 1 ? (
                    <>
                      <h5>1:1</h5>
                      <div className="d-flex creativeTypePreviewCard active mb-4" style={{ width: 443, minHeight: 165 }}>
                        <div className="position-relative">
                          <img
                            className="primary-icon"
                            src={creativeUIDetails.uiProductsCardDetails?.iconURL || creatives.animatedIconSrc || creatives.staticIconSrc}
                            height="165"
                            alt="Preview Icon"
                          />
                          {creatives.providerIconSrc && (
                            <img
                              className="position-absolute secondary-icon"
                              style={{ bottom: 8, right: 8 }}
                              src={creatives.providerIconSrc}
                              width="24"
                              alt="Provider Icon"
                            />
                          )}
                        </div>
                        <div className="pl-2 py-3 w-100 creativeCardPreviewContent">
                          <div className={`${(creativeUIDetails.uiProductsCardDetails?.subTitle ?? details.subTitle) && (creativeUIDetails.uiProductsCardDetails?.description ?? details.description)
                            ? "two-lines-ellipsis"
                            : (creativeUIDetails.uiProductsCardDetails?.subTitle ?? details.subTitle) || (creativeUIDetails.uiProductsCardDetails?.description ?? details.description)
                              ? "three-lines-ellipsis"
                              : "four-lines-ellipsis"
                            }`}
                            style={{ color: "#000", fontSize: 24, fontWeight: 700, lineHeight: "1.1" }}
                            dangerouslySetInnerHTML={{ __html: getParsedHTML(creativeUIDetails.uiProductsCardDetails?.title ?? (details.titleLong || details.title)) }}>
                          </div>
                          <div className="two-lines-ellipsis" style={{ color: "#000000BF", fontSize: 20, fontWeight: 400, lineHeight: "1.1", marginTop: 8 }} dangerouslySetInnerHTML={{ __html: getParsedHTML(creativeUIDetails.uiProductsCardDetails?.subTitle ?? details.subTitle) }}></div>
                          <div className="one-lines-ellipsis" style={{ color: "#00BAB5", fontSize: 20, fontWeight: 700, lineHeight: "1.1", marginTop: 8, position: "absolute", bottom: 16 }} dangerouslySetInnerHTML={{ __html: getParsedHTML(creativeUIDetails.uiProductsCardDetails?.description ?? details.description) }}></div>
                        </div>
                      </div>
                    </>
                  ) : <h5 className="text-danger">For new versions only</h5>}
                  {productsCardActiveTab === 2 && (
                    <>
                      <h5>4:3</h5>
                      <div className="d-flex creativeTypePreviewCard active mb-4" style={{ width: 460, ...sectionGrayedOutStyle(!creativeUIDetails.uiProductsCardTwoDetails) }}>
                        <div className="position-relative">
                          <img
                            className="primary-icon"
                            src={creativeUIDetails.uiProductsCardTwoDetails?.iconURL || uiProductsCardTwoImagePlaceholder}
                            height="165"
                            alt="Preview Icon"
                          />
                          {creatives.providerIconSrc && (
                            <img
                              className="position-absolute secondary-icon"
                              style={{ bottom: 8, right: 8 }}
                              src={creatives.providerIconSrc}
                              width="24"
                              alt="Provider Icon"
                            />
                          )}
                        </div>
                        <div className="pl-2 py-3 w-100 creativeCardPreviewContent">
                          <div className={`${(creativeUIDetails.uiProductsCardTwoDetails?.subTitle ?? (creativeUIDetails.uiProductsCardDetails?.subTitle || details.subTitle)) && (creativeUIDetails.uiProductsCardTwoDetails?.description ?? (creativeUIDetails.uiProductsCardDetails?.description || details.description))
                            ? "two-lines-ellipsis"
                            : (creativeUIDetails.uiProductsCardTwoDetails?.subTitle ?? (creativeUIDetails.uiProductsCardDetails?.subTitle || details.subTitle)) || (creativeUIDetails.uiProductsCardTwoDetails?.description ?? (creativeUIDetails.uiProductsCardDetails?.description || details.description))
                              ? "three-lines-ellipsis"
                              : "four-lines-ellipsis"
                            }`}
                            style={{ color: "#000", fontSize: 24, fontWeight: 700, lineHeight: "1.1" }}
                            dangerouslySetInnerHTML={{ __html: getParsedHTML(creativeUIDetails.uiProductsCardTwoDetails?.title ?? (creativeUIDetails.uiProductsCardDetails?.title || details.titleLong || details.title)) }}>
                          </div>
                          <div className="two-lines-ellipsis" style={{ color: "#000000BF", fontSize: 20, fontWeight: 400, lineHeight: "1.1", marginTop: 8 }} dangerouslySetInnerHTML={{ __html: getParsedHTML(creativeUIDetails.uiProductsCardTwoDetails?.subTitle ?? (creativeUIDetails.uiProductsCardDetails?.subTitle || details.subTitle)) }}></div>
                          <div className="one-lines-ellipsis" style={{ color: "#00BAB5", fontSize: 20, fontWeight: 700, lineHeight: "1.1", marginTop: 8, position: "absolute", bottom: 16 }} dangerouslySetInnerHTML={{ __html: getParsedHTML(creativeUIDetails.uiProductsCardTwoDetails?.description ?? (creativeUIDetails.uiProductsCardDetails?.description || details.description)) }}></div>
                        </div>
                      </div>
                    </>
                  )}
                  {productsCardActiveTab === 3 && (
                    <>
                      <h5>16:9</h5>
                      <div className="d-flex creativeTypePreviewCard active mb-4" style={{ width: "fit-content", ...sectionGrayedOutStyle(!creativeUIDetails.uiProductsCardThreeDetails) }}>
                        <div className="position-relative" style={{ borderRadius: 10 }}>
                          <img
                            className="primary-icon"
                            src={creativeUIDetails.uiProductsCardThreeDetails?.iconURL || uiProductsCardThreeImagePlaceholder}
                            height="165"
                            alt="Preview Icon"
                          />
                          {creatives.providerIconSrc && (
                            <img
                              className="position-absolute secondary-icon"
                              style={{ bottom: 8, right: 8 }}
                              src={creatives.providerIconSrc}
                              width="24"
                              alt="Provider Icon"
                            />
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <Row className="mb-3">
            <Col md={6} xs={6}>
              <div className="d-flex align-items-center">
                <CustomInput
                  type="checkbox"
                  id="uiDetailsCardDetails"
                  onChange={handleCreativeUIDetailsToggle}
                  checked={!creativeUIDetails.uiDetailsCardDetails?.isDisabled}
                  disabled={disabled}
                />
                {isMetadataOverride
                  ? <h4 className="mb-0 mr-1">Details Card</h4>
                  : <h3 className="mb-0 mr-1">Details Card</h3>
                }
                <ToolTip
                  data={`Details card UI is primarily used on browser placements.`}
                  id="detailsUITooltip"
                />
              </div>
            </Col>
          </Row>
          <Card style={isMetadataOverride ? { boxShadow: "none" } : {}}>
            {creativeUIDetails.uiDetailsCardDetails?.isDisabled && <div className="block-section w-100 h-100"></div>}
            <CardBody style={isMetadataOverride ? { padding: 0 } : {}}>
              <Row form>
                <Col md={6}>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          <strong>
                            Static/Animated Icon{" "}
                          </strong>
                          <ToolTip
                            data="Formats: png, jpg, jpeg, gif, webp, Aspect ratio: 1:1, Size: 200KB"
                            id="detailsStaticAnimatedIconTooltip"
                          />
                        </Label>
                        {isMetadataOverride
                          ? (creativeUIDetails.uiDetailsCardDetails?.iconOverride && creativeUIDetails.uiDetailsCardDetails?.iconURL) && (
                            <span
                              onClick={() => handleResetIconCards("uiDetailsCardDetails")}
                              className="ml-2 text-danger text-decoration-underline cursor-pointer"
                              style={{
                                textDecoration: "underline",
                              }}
                            >
                              Reset
                            </span>
                          ) : (creativeUIDetails.uiDetailsCardDetails?.iconURL) && (
                            <span
                              onClick={() => handleResetIconCards("uiDetailsCardDetails")}
                              className="ml-2 text-danger text-decoration-underline cursor-pointer"
                              style={{
                                textDecoration: "underline",
                              }}
                            >
                              Reset
                            </span>
                          )}
                        <Container className="custom-file">
                          <Input
                            type="file"
                            className="custom-file-input"
                            accept="image/png, image/jpg, image/jpeg, image/gif, image/webp"
                            name="animatedIcon"
                            onChange={(event) => handleCreativeUIIconUpload(event, "uiDetailsCardDetails")}
                            disabled={disabled}
                          />
                          <Label
                            className="custom-file-label"
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis"
                            }}
                          >
                            <strong>{creativeUIDetails.uiDetailsCardDetails?.iconFilename || creatives.animatedIconFilename || creatives.staticIconFilename || "Choose an image"}</strong>
                          </Label>
                        </Container>
                      </FormGroup>
                    </Col>
                    <Col md={6}></Col>
                    <Col md={12}>
                      <FormGroup className="position-relative">
                        <Label
                          className="font-weight-bolder mb-2 w-100"
                          style={{ fontSize: "14px" }}
                        >
                          Title <span className="text-danger">*</span><small className="text-info float-right">Upto {creativeUILengthLimit.detailsCard.title} characters</small>
                        </Label>
                        <Input
                          type="textarea"
                          name="title"
                          placeholder="Enter Title"
                          value={
                            (detailsCardFieldsEdit.title)
                              ? creativeUIDetails.uiDetailsCardDetails?.title ?? ""
                              : (creativeUIDetails.uiProductsCardDetails?.title || details.titleLong || details.title) ?? ""
                          }
                          onChange={(event) => {
                            const { value } = event.target
                            if (!isMetadataOverride && value?.includes("__KWD__")) {
                              setAction(prevState => ({
                                ...prevState,
                                isDynamic: true
                              }))
                              setCustomAppDetails((prevDetails) =>
                                prevDetails.map((item) => ({
                                  ...item,
                                  isDynamic: value?.includes("__KWD__")
                                    ? true
                                    : !item.clickUrl?.includes("__KWD__") ? false : item.isDynamic,
                                }))
                              );
                            }
                            if (calculateInputLength(value) > creativeUILengthLimit.detailsCard.title) {
                              event.preventDefault();
                              return;
                            }
                            handleCreativeUIDetailsChange(event, "uiDetailsCardDetails")
                          }}
                          disabled={disabled || !detailsCardFieldsEdit.title || creativeUIDetails.uiDetailsCardDetails?.isDisabled}
                          required
                        />
                        {(detailsCardFieldsEdit.title) ? (
                          <IoCloseCircleOutline
                            className="text-danger cursor-pointer position-absolute"
                            size={24}
                            style={{ right: 8, top: "70%", transform: "translateY(-70%)", zIndex: 2 }}
                            onClick={() => {
                              setDetailsCardFieldsEdit((prevState) => ({
                                ...prevState,
                                title: false
                              }))
                              setCreativeUIDetails((prevState) => ({
                                ...prevState,
                                uiDetailsCardDetails: {
                                  ...prevState["uiDetailsCardDetails"],
                                  title: null
                                }
                              }))
                            }}
                          />
                        ) : (
                          <MdOutlineEdit
                            className="text-info cursor-pointer position-absolute"
                            size={24}
                            style={{ right: 8, top: "70%", transform: "translateY(-70%)", zIndex: 2 }}
                            onClick={() => setDetailsCardFieldsEdit((prevState) => ({
                              ...prevState,
                              title: true
                            }))}
                          />
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="position-relative">
                        <Label
                          className="font-weight-bolder mb-2 w-100"
                          style={{ fontSize: "14px" }}
                        >
                          Sub Title<small className="text-info float-right">Upto {creativeUILengthLimit.detailsCard.subTitle} characters</small>
                        </Label>
                        <Input
                          type="textarea"
                          name="subTitle"
                          placeholder="Enter Sub Title"
                          value={
                            detailsCardFieldsEdit.subTitle
                              ? creativeUIDetails.uiDetailsCardDetails?.subTitle ?? ""
                              : (creativeUIDetails.uiProductsCardDetails?.subTitle || details.subTitle) ?? ""
                          }
                          onChange={(event) => {
                            const { value } = event.target
                            if (calculateInputLength(value) > creativeUILengthLimit.detailsCard.subTitle) {
                              event.preventDefault();
                              return;
                            }
                            handleCreativeUIDetailsChange(event, "uiDetailsCardDetails")
                          }}
                          disabled={disabled || !detailsCardFieldsEdit.subTitle}
                        />
                        {(detailsCardFieldsEdit.subTitle) ? (
                          <IoCloseCircleOutline
                            className="text-danger cursor-pointer position-absolute"
                            size={24}
                            style={{ right: 8, top: "70%", transform: "translateY(-70%)", zIndex: 2 }}
                            onClick={() => {
                              setDetailsCardFieldsEdit((prevState) => ({
                                ...prevState,
                                subTitle: false
                              }))
                              setCreativeUIDetails((prevState) => ({
                                ...prevState,
                                uiDetailsCardDetails: {
                                  ...prevState["uiDetailsCardDetails"],
                                  subTitle: null
                                }
                              }))
                            }}
                          />
                        ) : (
                          <MdOutlineEdit
                            className="text-info cursor-pointer position-absolute"
                            size={24}
                            style={{ right: 8, top: "70%", transform: "translateY(-70%)", zIndex: 2 }}
                            onClick={() => setDetailsCardFieldsEdit((prevState) => ({
                              ...prevState,
                              subTitle: true
                            }))}
                          />
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col className="creativeTypePreview detailsCard">
                  <div className="d-flex creativeTypePreviewCard active" style={{ width: 550 }}>
                    <div className="position-relative py-1 pl-1">
                      <img
                        className="primary-icon"
                        src={creativeUIDetails.uiDetailsCardDetails?.iconURL || creatives.animatedIconSrc || creatives.staticIconSrc}
                        height="60"
                        alt="Preview Icon"
                      />
                      {creatives.providerIconSrc && (
                        <img
                          className="position-absolute secondary-icon"
                          style={{ bottom: -2, right: -2 }}
                          src={creatives.providerIconSrc}
                          width="16"
                          alt="Provider Icon"
                        />
                      )}
                    </div>
                    <div className="py-1 pl-2 w-100 creativeCardPreviewContent d-flex flex-column justify-content-center">
                      <div className="one-lines-ellipsis" style={{ color: "#000", fontSize: 24, fontWeight: 400, lineHeight: "1.1" }} dangerouslySetInnerHTML={{
                        __html: getParsedHTML(
                          detailsCardFieldsEdit.title
                            ? creativeUIDetails.uiDetailsCardDetails?.title
                            : (creativeUIDetails.uiProductsCardDetails?.title || details.titleLong || details.title)
                        )
                      }}></div>
                      <div className="one-lines-ellipsis mt-2" style={{ color: "#949494", fontSize: 16, fontWeight: 400, lineHeight: "1.1" }} dangerouslySetInnerHTML={{
                        __html: getParsedHTML(
                          detailsCardFieldsEdit.subTitle
                            ? creativeUIDetails.uiDetailsCardDetails?.subTitle
                            : (creativeUIDetails.uiProductsCardDetails?.subTitle || details.subTitle)
                        )
                      }}></div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center pr-1" style={{ background: "#fff", borderRadius: "0 10px 10px 0" }}>
                      <FiArrowUpLeft size={32} color="#a9a9a9" />
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default SmartSuggestionDetails;
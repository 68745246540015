const defaultFormatter = (cell, row) => {
  return cell || "-"
}

export const tableColumns = [
  {
    dataField: "id",
    text: "#",
    sort: true,
    headerAttrs: { width: 60 },
    searchable: true,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "name",
    text: "Name",
    sort: true,
    searchable: true,
    formatter: (cell, row) => defaultFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "value",
    text: "Value",
    sort: true,
    searchable: true,
    formatter: (cell, row) => defaultFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  }
];
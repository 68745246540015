import { retrieveFromLocalStorage } from "../localStorageUtil";
import { secureFetch } from "../secureFetch";
import { host } from "./index";

let bobbleApiHost = "https://api.bobbleapp.me";
let contentApiHost = window._env_.REACT_APP_CONTENT_API_HOST;
let xApiKey = window._env_.REACT_APP_BOBBLE_TESTING_API_KEY;

export let fetchTaasStickers = async (params) => {
    let token = retrieveFromLocalStorage("token");
    let url = `${host}/v1/internal/taas/stickers?` + new URLSearchParams(params);
    try {
        var response = await secureFetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let fetchTaasStories = async (params) => {
    let token = retrieveFromLocalStorage("token");
    let url = `${host}/v1/internal/taas/stories?` + new URLSearchParams(params);
    try {
        var response = await secureFetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let fetchTaasClients = async (params) => {
    let token = retrieveFromLocalStorage("token");
    let url = `${host}/v1/internal/taas/clients?` + new URLSearchParams(params);
    try {
        var response = await secureFetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let fetchTaasPartners = async () => {
    let token = retrieveFromLocalStorage("token");
    let url = `${host}/v1/internal/taas/partners`;
    try {
        var response = await secureFetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let createUpdateClient = async (data) => {
    let url = `${host}/v1/internal/taas/clients`;
    let token = retrieveFromLocalStorage("token");
    try {
        let response = await secureFetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let createPartner = async (data) => {
    let url = `${host}/v1/internal/taas/partners`;
    let token = retrieveFromLocalStorage("token");
    try {
        let response = await secureFetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let createUpdateSticker = async (data) => {
    let url = `${host}/v1/internal/taas/stickers`;
    let token = retrieveFromLocalStorage("token");
    try {
        let response = await secureFetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let createUpdateStory = async (data) => {
    let url = `${host}/v1/internal/taas/stories`;
    let token = retrieveFromLocalStorage("token");
    try {
        let response = await secureFetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let generatePreview = async (data) => {
    let url = `${host}/v1/internal/taas/previewMusicalStories`;
    let token = retrieveFromLocalStorage("token");
    try {
        let response = await secureFetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let fetchTaasClient = async (id) => {
    let token = retrieveFromLocalStorage("token");
    let url = `${host}/v1/internal/taas/clients?clientId=${id}`;
    try {
        var response = await secureFetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let fetchTaasSticker = async (id) => {
    let token = retrieveFromLocalStorage("token");
    let url = `${host}/v1/internal/taas/stickers?id=${id}`;
    try {
        var response = await secureFetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let fetchTaasStory = async (id, type) => {
    let token = retrieveFromLocalStorage("token");
    let url = `${host}/v1/internal/taas/stories?id=${id}&type=${type}`;
    try {
        var response = await secureFetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let fetchTaasStoryURL = async (id) => {
    let token = retrieveFromLocalStorage("token");
    let url = `${bobbleApiHost}/v4/stories/${id}`;
    try {
        var response = await secureFetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let fetchTaasStickerURL = async (id) => {
    let token = retrieveFromLocalStorage("token");
    let url = `${bobbleApiHost}/v4/stickers/${id}`;
    try {
        var response = await secureFetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let fetchStatisticsGraphData = async ({ startDate, endDate, clientId, overallStats }) => {
    let url = `${host}/v1/internal/taas/clientStats?startDate=${startDate}&endDate=${endDate}&clientId=${clientId}&overallStats=${overallStats}`;
    let token = retrieveFromLocalStorage("token");
    try {
        var response = await secureFetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await response.json();
    } catch (error) {
        return;
        return;
    }
};

export let fetchTaasPoptextStickerURL = async (id) => {
    let token = retrieveFromLocalStorage("token");
    let url = `${bobbleApiHost}/v4/stickers/${id}?includeCustomTextDetails=1`;
    try {
        var response = await secureFetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let fetchTaasPoptextStickers = async (params) => {
    let token = retrieveFromLocalStorage("token");
    let url = `${host}/v1/internal/taas/poptextStickers?` + new URLSearchParams(params);
    try {
        var response = await secureFetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let fetchTaasPoptextSticker = async (id) => {
    let token = retrieveFromLocalStorage("token");
    let url = `${host}/v1/internal/taas/poptextStickers?id=${id}`;
    try {
        var response = await secureFetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let createUpdatePoptextSticker = async (data) => {
    let url = `${host}/v1/internal/taas/poptextStickers`;
    let token = retrieveFromLocalStorage("token");
    try {
        let response = await secureFetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
            },
            body: data,
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let testTextPoptextSticker = async (data) => {
    let url = `${contentApiHost}/v4/popText`;
    data.fontStickerId = parseInt(data.fontStickerId, 10);
    try {
        let response = await fetch(url, {
            method: "POST",
            headers: {
                "X-API-KEY": xApiKey,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        return await response.json();
    } catch (error) {
        return;
    }
}
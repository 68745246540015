import { retrieveFromLocalStorage } from "../utilities/localStorageUtil";
import { permissions } from "../utilities/commonUtil";
import moment from "moment";

const allowedPermissions = retrieveFromLocalStorage("userPermissions");

export const statusOptions = [
    { value: "all", label: "All" },
    { value: "activated", label: "Activated" },
    { value: "deactivated", label: "Deactivated" },
];
export const clientOptions = [
    { value: "global", label: "Global" },
    { value: "7wZFJWA5chjgat68y826IAIKQ6s197RM", label: "Android" },
    { value: "gB77CvHO1ZCh5R5z7pAzn63to99qrBox", label: "IOS" },
]
export const llmProviderOptions = [
    { value: "global", label: "Global" },
    { value: "monsterai", label: "MonsterAI (8B model)" },
    { value: "monsteraiV2", label: "MonsterAI v2 (70B model)" },
]
export const personaNameOptions = [
    { value: "global", label: "Global" },
    { value: "bubbly", label: "Bubbly" },
]
export const personaTriggerOptions = [
    { value: "global", label: "Global" },
    { value: "app_onboarding", label: "App Onboarding" },
    { value: "notification", label: "Notification" },
    { value: "fab", label: "Fab" },
    { value: "prompt", label: "Prompt" },
    { value: "suggestion_pill", label: "Suggestion Pill" },
    { value: "rewards", label: "Rewards" },
    { value: "superapp", label: "Superapp" },
    { value: "cre_carousel", label: "Cre Carousel" },
    { value: "smart_suggestion", label: "Smart Suggestion" },
    { value: "story_of_the_day", label: "Story Of The Day" },
    { value: "vertical_story", label: "Vertical Story" },
    { value: "kb_suggestion_drawer", label: "Suggestion Drawer" },
    { value: "bubbly", label: "Bubbly" },
    { value: "quick_reply", label: "Quick Reply" },
    { value: "content_icon", label: "Content Icon" },
    { value: "top_bar_icons", label: "Top Bar Icons" },
    { value: "cricket_bar", label: "Cricket Bar" },
    { value: "football_bar", label: "Football Bar" },
    { value: "subscription_home", label: "Subscription Home" },
    { value: "subscription_manage", label: "Subscription Manage" },
    { value: "settings_page", label: "Settings Page" }
];
export const typeOptions = [
    { value: "production", label: "Production" },
    { value: "ab_test", label: "AB Test" },
]

export const systemPromptAPIStatus = {
    created: "chatAssistantSystemPromptCreated",
    deactivated: "chatAssistantSystemPromptDeactivated",
    activated: "chatAssistantSystemPromptActivated",
}

export let systemPromptResponses = {
    addedSuccessfully: "System Prompt added successfully",
    activatedSuccessfully: "System Prompt activated successfully",
    deactivatedSuccessfully: "System Prompt deactivated successfully",
}

export let showActivateSystemPromptButton = () => {
    return allowedPermissions.includes(permissions.chatAssistantSystemPromptActivate);
};
export let showDeactivateSystemPromptButton = () => {
    return allowedPermissions.includes(permissions.chatAssistantSystemPromptDeactivate);
};
export let showCreateSystemPromptButton = () => {
    return allowedPermissions.includes(permissions.chatAssistantSystemPromptCreate);
};

export let formatTime = (date) => {
    if (date === null) {
        return "N/A";
    }
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
};

export let errorMissingMessage = "Please fill the required fields"
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Row, Col, Container, Card, CardBody, Button } from "reactstrap";
import {
  fetchContentUpdatePromptsCountryCodes,
  fetchContentUpdatePromptsRegionCodes,
  fetchContentUpdatePromptsCities,
} from "../../../utilities/apiUtils/contentPrompts";
import RulesCard from "./components/ContentPromptRuleCard.jsx";
import {
  error,
  errorDescription,
  permissions,
} from "../../../utilities/commonUtil";
import ResponseModal from "../ResponseModal";
import Loading from "../../../components/Loading";
import { defaultLocationRule } from "../../../config/contentUpdatePrompts";
import AgeGenderRules from "../AgeGenderRules";
import { createContentUpdatePromptRulesets } from "../../../utilities/apiUtils/contentPrompts";

import {
  successModalHeading,
  failureModalHeading,
  rulesetCreatedSuccessfullyText,
} from "../../../config/contentUpdatePrompts";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
const ContentPromptRulesets = (props) => {
  const [loading, setLoading] = useState(true);
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );
  const [successModalState, setSuccessModalState] = useState({
    successModalDisplay: false,
    successModalText: "",
  });

  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });
  const [rules, setRules] = useState([]);
  const [ageGenderRules, setAgeGenderRules] = useState({});
  const [minAge, setMinAge] = useState(0);
  const [maxAge, setMaxAge] = useState(null);
  const [gender, setGender] = useState({
    label: "None",
    value: "none",
  });
  const [genderHardFiltering, setGenderHardFiltering] = useState(false);
  const [ageHardFiltering, setAgeHardFiltering] = useState(false);
  const [ageGenderHardFiltering, setAgeGenderHardFiltering] = useState(false);
  const [countries, setCountries] = useState();
  const [regions, setRegions] = useState();
  const [cities, setCities] = useState();

  useEffect(() => {
    const getRules = async (id) => {
      if (props.contentUpdatePrompt.rulesets) {
        let rulesets = props.contentUpdatePrompt.rulesets;
        for (let i = 0; i < rulesets.length; i++) {
          rulesets[i]["id"] = i;
        }
        setRules(rulesets);
        let ageGenderRuleResponse;
        if (rulesets.length) {
          if (rulesets[0].ageGenderRules) {
            ageGenderRuleResponse = rulesets[0].ageGenderRules;
          }
        }

        if (ageGenderRuleResponse) {
          setMinAge(ageGenderRuleResponse.minAge ?? null);
          setMaxAge(ageGenderRuleResponse.maxAge ?? null);
          if (ageGenderRuleResponse.gender) {
            setGender({
              value: ageGenderRuleResponse.gender,
              label:
                ageGenderRuleResponse.gender[0].toUpperCase() +
                ageGenderRuleResponse.gender.substring(1),
            });
          }
          setAgeHardFiltering(ageGenderRuleResponse.ageHardFiltering ?? null);
          setGenderHardFiltering(
            ageGenderRuleResponse.genderHardFiltering ?? null
          );
          setAgeGenderHardFiltering(
            ageGenderRuleResponse.ageHardFiltering &&
              ageGenderRuleResponse.genderHardFiltering
          );
        }
        setAgeGenderRules(ageGenderRuleResponse ?? null);
        setLoading(false);
      }
    };
    if (props.contentUpdatePrompt && countries && regions && cities) {
      getRules(props.contentUpdatePrompt);
    }
  }, [props.contentUpdatePrompt, countries, regions, cities]);

  useEffect(() => {
    setAgeGenderRules({
      minAge: parseInt(minAge),
      maxAge: parseInt(maxAge),
      gender: gender ? gender.value : "",
      ageHardFiltering: ageHardFiltering,
      genderHardFiltering: genderHardFiltering,
    });
  }, [
    minAge,
    maxAge,
    gender,
    ageHardFiltering,
    genderHardFiltering,
    ageGenderHardFiltering,
  ]);

  useEffect(() => {
    getCountryCodes();
    getRegionCodes();
    getCitiesCodes();
  }, []);

  const getCountryCodes = async () => {
    try {
      const response = await fetchContentUpdatePromptsCountryCodes();
      if (response.countries) {
        let countryOptions = [];
        for (let i = 0; i < response.countries.length; i++) {
          let country = response.countries[i];
          if (country.iso_code !== "GLOBAL") {
            let countryName =
              country.iso_code +
              " - " +
              country.name[0] +
              country.name.slice(1).toLowerCase();
            countryOptions = [
              ...countryOptions,
              { label: countryName, value: country.id },
            ];
          } else {
            let countryName = country.name.toUpperCase();
            countryOptions = [
              { label: countryName, value: country.id },
              ...countryOptions,
            ];
          }
        }
        setCountries(countryOptions);
      } else {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      }
    } catch (err) {
      setLoading(false);
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const getRegionCodes = async () => {
    try {
      const regions = await fetchContentUpdatePromptsRegionCodes();
      if (Object.keys(regions).length > 0) {
        setRegions(regions);
      } else {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      }
    } catch (err) {
      setLoading(false);
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };
  const getCitiesCodes = async () => {
    try {
      const cities = await fetchContentUpdatePromptsCities();
      if (Object.keys(cities).length > 0) {
        setCities(cities);
      } else {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };
  const updateAllowed = () => {
    return (
      allowedPermissions.includes(permissions.contentUpdatePromptCreate) &&
      allowedPermissions.includes(permissions.countryList)
    );
  };

  const addRow = (state = undefined) => {
    setRules((prev) => [
      ...prev,
      {
        id: prev.length ? prev[prev.length - 1].id + 1 : 0,
        clientRules: [{ clientId: "global", effect: "allow" }],
        locationRules: [defaultLocationRule],
      },
    ]);
  };

  const subtractRow = (index) => {
    const _updatedRules = rules.filter((rule) => rule.id !== index);
    setRules(_updatedRules);
  };
  useEffect(() => {
    let _updatedRules = rules;
    for (let i = 0; i < _updatedRules.length; i++) {
      _updatedRules[i]["id"] = i;
    }
    setRules(_updatedRules);
  }, [rules]);
  const updateRow = (ruleID, clientRules, locationRules, deactivate) => {
    if (rules)
      if (rules[ruleID])
        setRules((prev) => {
          prev[ruleID].clientRules = clientRules;
          prev[ruleID].locationRules = locationRules;
          prev[ruleID].deactivatedAt = deactivate
            ? new Date().toTimeString()
            : null;

          return [...prev];
        });
  };
  const failureModalClose = () => {
    setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
  };

  const successModalClose = () => {
    setSuccessModalState((prev) => ({ ...prev, successModalDisplay: false }));
    window.location.reload();
  };

  const handleSubmit = async () => {
    try {
      if (rules.length === 0) {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.missingLocationRule),
        });
        return;
      }
      for (const element of rules) {
        element.ageGenderRules = ageGenderRules;
      }
      var response = await createContentUpdatePromptRulesets(
        rules,
        props.contentUpdatePrompt.id
      );
      if (!response.status) {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: response.errorDescription,
        });
      } else {
        setSuccessModalState((prev) => ({
          ...prev,
          successModalDisplay: true,
        }));
      }
    } catch {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };
  let handleAgeChange = (event) => {
    if (event.target.name === "minAge") setMinAge(event.target.value);
    else if (event.target.name === "maxAge") setMaxAge(event.target.value);
  };

  let handleGenderChange = (select) => {
    let selectedGender = {};
    if (select !== null) {
      selectedGender["value"] = select.value;
      selectedGender["label"] = select.label;
    }
    setAgeGenderRules({ ...ageGenderRules, gender: select.value });
    setGender(selectedGender);
  };

  let handleAgeAndGenderToggleChange = (event) => {
    if (event.target.name === "ageGenderHardFiltering") {
      setGenderHardFiltering(!ageGenderHardFiltering);
      setAgeHardFiltering(!ageGenderHardFiltering);
      setAgeGenderHardFiltering(!ageGenderHardFiltering);
    }

    if (event.target.name === "ageHardFiltering") {
      // update the ageGenderHardFiltering Toggle value depending upon the previous values of age toggle and gender toggle
      // if ageHardFiltering value was false before and genderHadfiltering was true, that means the combine toggle will be true now
      setAgeGenderHardFiltering(!ageHardFiltering && genderHardFiltering);
      setAgeHardFiltering(!ageHardFiltering);
    }

    if (event.target.name === "genderHardFiltering") {
      // update the ageGenderHardFiltering Toggle value depending upon the previous values of age toggle and gender toggle
      setAgeGenderHardFiltering(ageHardFiltering && !genderHardFiltering);
      setGenderHardFiltering(!genderHardFiltering);
    }
  };
  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Targetting Rules</h1>
      <p className="">
        Rules deciding where this contentUpdatePrompts gets available
      </p>
      {loading ? (
        <Loading />
      ) : (
        <Row>
          <Col md={12}>
            <AgeGenderRules
              updateRules={updateRow}
              removeRule={subtractRow}
              updateAllowed={updateAllowed}
              minAge={minAge ?? 0}
              maxAge={maxAge ?? null}
              gender={
                gender ?? {
                  label: "None",
                  value: "none",
                }
              }
              ageHardFiltering={ageHardFiltering ?? false}
              genderHardFiltering={genderHardFiltering ?? false}
              handleAgeChange={handleAgeChange}
              handleGenderChange={handleGenderChange}
              handleAgeAndGenderToggleChange={handleAgeAndGenderToggleChange}
            ></AgeGenderRules>
          </Col>
          <Col md={12}>
            <Card className="mx-0 px-0">
              <CardBody className="mx-0 px-0">
                {rules.length ? (
                  rules.map(function (object) {
                    return (
                      <RulesCard
                        key={object.id}
                        rule={object}
                        updateRules={updateRow}
                        removeRule={subtractRow}
                        updateAllowed={updateAllowed}
                        countries={countries}
                        regions={regions}
                        cities={cities}
                        rules={rules}
                      />
                    );
                  })
                ) : (
                  <Row className="justify-content-md-center">
                    <strong>No ruleset found !</strong>
                  </Row>
                )}
                <div className="d-flex">
                  <div className="ml-auto p-2">
                    <Button
                      className="d-block ml-3 pr-3 text-decoration-none"
                      color="primary"
                      onClick={addRow}
                    >
                      <FontAwesomeIcon size="sm" icon={faPlus} /> Add Rule
                    </Button>
                  </div>
                </div>
                <ResponseModal
                  show={successModalState.successModalDisplay}
                  onHide={() => successModalClose()}
                  modalheading={successModalHeading}
                  modaltext={rulesetCreatedSuccessfullyText}
                />
                <ResponseModal
                  show={failureModalState.failureModalDisplay}
                  onHide={() => failureModalClose()}
                  modalheading={failureModalHeading}
                  modaltext={failureModalState.failureModalText}
                />
              </CardBody>
            </Card>
            <Row className="ml-2">
              <Button
                onClick={handleSubmit}
                color="success"
                disabled={!countries && !regions && !cities}
              >
                Save Changes
              </Button>
            </Row>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default ContentPromptRulesets;

import React, { useState } from "react";
import ResponseModal from "../ResponseModal";
import { successModalHeading } from '../../../config';
import { errorModalHeading } from '../../../config/config';
import {
    error, errorDescription
} from '../../../utilities/commonUtil';
import { useHistory } from "react-router-dom"
import Select from "react-select";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    CustomInput,
} from "reactstrap";
import ToolTip from "../../../utilities/ToolTip";
import { clientOptions, personaNameOptions, personaTriggerOptions, typeOptions, llmProviderOptions, systemPromptResponses, errorMissingMessage, systemPromptAPIStatus } from "../../../config/configSystemPrompts";
import { createSystemPrompt } from "../../../utilities/apiUtils/chatAssistantDashboard";

const SystemPromptForm = () => {
    const history = useHistory();
    const [successModalDisplay, setSuccessModalDisplay] = useState(false);
    const [successModalText, setSuccessModalText] = useState("");
    const [failureModalDisplay, setFailureModalDisplay] = useState(false);
    const [failureModalText, setFailureModalText] = useState("");

    const [systemPromptData, setSystemPromptData] = useState({
        description: "",
        type: typeOptions[0],
        client: clientOptions[0],
        personaName: personaNameOptions[0],
        personaTrigger: personaTriggerOptions[0],
        llmProvider: llmProviderOptions[0],
        value: "",
        isActivate: false
    });

    let showSuccessModal = (text) => {
        setSuccessModalText(text);
        setSuccessModalDisplay(true);
    };

    let successModalClose = () => {
        setSuccessModalDisplay(false);
        history.goBack();
    };

    let failureModalClose = () => {
        setFailureModalDisplay(false);
    };

    let showErrorModal = (text) => {
        setFailureModalText(text);
        setFailureModalDisplay(true);
    };

    const handleSubmit = async () => {
        if (systemPromptData.value === "") {
            showErrorModal(errorMissingMessage)
            return;
        }
        const formSystempPromptData = {
            description: systemPromptData.description,
            type: systemPromptData.type?.value,
            clientId: systemPromptData.client?.value,
            personaName: systemPromptData.personaName?.value,
            personaTrigger: systemPromptData.personaTrigger?.value,
            llmProvider: systemPromptData.llmProvider?.value,
            value: systemPromptData.value,
            isActivate: systemPromptData.isActivate
        };
        try {
            let response = await createSystemPrompt(formSystempPromptData)
            if (response?.status === systemPromptAPIStatus.created) {
                showSuccessModal(systemPromptResponses.addedSuccessfully);
            }
            else {
                if (response?.errorCode) {
                    showErrorModal(response?.errorMessage)
                }
            }
        } catch (err) {
            setFailureModalDisplay(true);
            setFailureModalText(errorDescription(error.unexpectedError));
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target
        setSystemPromptData((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleType = (event) => {
        setSystemPromptData((prevState) => ({
            ...prevState,
            type: event
        }))
    };

    const handleClient = (event) => {
        setSystemPromptData((prevState) => ({
            ...prevState,
            client: event
        }))
    };

    const handlePersonaName = (event) => {
        setSystemPromptData((prevState) => ({
            ...prevState,
            personaName: event
        }))
    };

    const handlePersonaTrigger = (event) => {
        setSystemPromptData((prevState) => ({
            ...prevState,
            personaTrigger: event
        }))
    };

    const handleLLMProvider = (event) => {
        setSystemPromptData((prevState) => ({
            ...prevState,
            llmProvider: event
        }))
    };

    const handleCheckboxActivateChange = (event) => {
        const { checked } = event.target;
        setSystemPromptData((prevState) => ({
            ...prevState,
            isActivate: checked
        }))
    };

    return (
        <>
            <Card>
                <CardBody>
                    <h1 className="h3 mb-3">
                        Create System Prompt
                    </h1>
                    <Form>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>
                                        Description <ToolTip data={"Details about the system prompt"} id="description" />
                                    </Label>
                                    <Input
                                        type="text"
                                        name="description"
                                        value={systemPromptData.description}
                                        placeholder="Enter Description"
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>
                                        Type <span className="text-danger">*</span>
                                    </Label>
                                    <Select
                                        id="type"
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        placeholder="Select type"
                                        name="type"
                                        value={systemPromptData.type}
                                        onChange={handleType}
                                        options={typeOptions}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>
                                        Client <span className="text-danger">*</span>
                                    </Label>
                                    <Select
                                        id="client"
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        placeholder="Select Client"
                                        name="client"
                                        value={systemPromptData.client}
                                        onChange={handleClient}
                                        options={clientOptions}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>
                                        LLM Provider <span className="text-danger">*</span>
                                    </Label>
                                    <Select
                                        id="llmProvider"
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        placeholder="Select LLM provider"
                                        name="llmProvider"
                                        value={systemPromptData.llmProvider}
                                        onChange={handleLLMProvider}
                                        options={llmProviderOptions}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>
                                        Persona Name <span className="text-danger">*</span><ToolTip data={"Persona which will use this system prompt"} id="personaName" />
                                    </Label>
                                    <Select
                                        id="personaName"
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        placeholder="Select Persona"
                                        name="personaName"
                                        value={systemPromptData.personaName}
                                        onChange={handlePersonaName}
                                        options={personaNameOptions}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>
                                        Persona Trigger <span className="text-danger">*</span><ToolTip data={"Trigger point from where the AI feature will be invoked"} id="personaTrigger" />
                                    </Label>
                                    <Select
                                        id="personaTrigger"
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        placeholder="Select Trigger"
                                        name="personaTrigger"
                                        value={systemPromptData.personaTrigger}
                                        onChange={handlePersonaTrigger}
                                        options={personaTriggerOptions}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={12}>
                                <FormGroup>
                                    <Label>
                                        Value <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        type="textarea"
                                        name="value"
                                        value={systemPromptData.value}
                                        placeholder="Enter Value"
                                        required
                                        rows="5"
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <FormGroup className="d-flex align-items-center">
                                    <Label
                                        className="font-weight-bolder mb-0"
                                        style={{ fontSize: "14px" }}
                                    >
                                        Activate System Prompt
                                    </Label>
                                    <div className="custom-control custom-switch">
                                        <CustomInput
                                            type="checkbox"
                                            id="isActivate"
                                            onChange={handleCheckboxActivateChange}
                                            checked={systemPromptData.isActivate}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
            <Button color="primary" onClick={handleSubmit}>Submit</Button>
            <ResponseModal
                show={successModalDisplay}
                onHide={successModalClose}
                modalheading={successModalHeading}
                modaltext={successModalText}
            />
            <ResponseModal
                show={failureModalDisplay}
                onHide={failureModalClose}
                modalheading={errorModalHeading}
                modaltext={failureModalText}
            />
        </>
    );
};
const ManageSystemPrompt = () => (
    <Container fluid className="p-0">
        <Row>
            <Col lg="12">
                <SystemPromptForm />
            </Col>
        </Row>
    </Container>
);
export default ManageSystemPrompt;

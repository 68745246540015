import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  Badge
} from "reactstrap";
import { useHistory } from "react-router-dom";
import {
  fetchSegmentsData,
  fetchCategoriesForSegment,
  createPriorityRuleSet,
} from "../../../utilities/apiUtils/segmentCategoryOrder";
import CustomTooltip from "../../../components/customToolTip";

const CategoryRulesetForm = () => {
  const [segments, setSegments] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [priority, setPriority] = useState("");
  const [description, setDescription] = useState(""); // New state for description
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const history = useHistory();

  useEffect(() => {
    const loadSegments = async () => {
      const data = await fetchSegmentsData();
      setSegments(data);
    };

    loadSegments();
  }, []);

  useEffect(() => {
    const loadCategories = async () => {
      if (selectedSegment) {
        const data = await fetchCategoriesForSegment(selectedSegment);
        if (data && data.categories) {
          setCategories(data.categories);
          setSelectedCategories(data.categories);
        } else {
          console.error("Unexpected data structure:", data);
        }
      }
    };

    loadCategories();
  }, [selectedSegment]);

  const handleSegmentChange = (event) => {
    setSelectedSegment(event.target.value);
  };

  const onCategoryDragStart = (index) => {
    const draggedItem = selectedCategories[index];
    draggedItem.isDragging = true;
    setSelectedCategories([...selectedCategories]);
  };

  const onCategoryDragOver = (event, index) => {
    event.preventDefault();
    const draggedOverItem = selectedCategories[index];
    if (draggedOverItem.isDragging) return;

    const items = selectedCategories.filter((item) => !item.isDragging);
    items.splice(
      index,
      0,
      selectedCategories.find((item) => item.isDragging)
    );
    setSelectedCategories(items);
  };

  const onCategoryDrop = () => {
    const items = selectedCategories.map((item) => {
      item.isDragging = false;
      return item;
    });
    setSelectedCategories(items);
  };

  const validateForm = () => {
    // Check if required fields are filled
    if (!selectedSegment) {
      setError("Please select a segment.");
      return false;
    }
    if (!priority) {
      setError("Please enter a priority.");
      return false;
    }
    if (selectedCategories.length === 0) {
      setError("Please select at least one category.");
      return false;
    }
    setError(null); // Clear error if validation passes
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    const categoryIds = selectedCategories.map((cat) => cat.id);
    const payload = {
      categoryIDs: categoryIds,
      segmentID: parseInt(selectedSegment, 10),
      priority: parseInt(priority, 10),
      description: description, // Include description in payload
    };

    setLoading(true);
    try {
      await createPriorityRuleSet(payload);
      setModalMessage("Priority rule set created successfully!");
    } catch (error) {
      console.error("Error creating priority rule set:", error);
      setModalMessage("Failed to create priority rule set. Please try again.");
    } finally {
      setLoading(false);
      setModalOpen(true);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    history.push("/suggestionDrawer/category/segmentOrder");
  };

  const getCategoryStatus = (category) => {
    const { publishedAt, deactivatedAt, endDateTime } = category;
    const now = new Date();
    if (endDateTime && new Date(endDateTime) < now) {
      return { status: "Expired", color: "red" };
    }
    if (deactivatedAt) {
      return { status: "Deactivated", color: "red" };
    }
    if (publishedAt) {
      return { status: "Activated", color: "green" };
    }
    return { status: "Unpublished", color: "yellow" };
  };

  const renderCategoryCards = () => {
    const rows = [];
    for (let i = 0; i < selectedCategories.length; i += 5) {
      const rowItems = selectedCategories.slice(i, i + 5);
      rows.push(
        <div
          key={i}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          {rowItems.map((category, index) => {
            const { status, color } = getCategoryStatus(category);
            const badgeColor = color === "red" ? "badge-danger" : color === "green" ? "badge-success" : "badge-warning";
  
            return (
              <div
                key={category.id}
                draggable
                onDragStart={() => onCategoryDragStart(i + index)}
                onDragOver={(e) => onCategoryDragOver(e, i + index)}
                onDrop={onCategoryDrop}
                style={{
                  padding: "1.5rem",
                  border: "1px solid #dee2e6",
                  borderRadius: "0.25rem",
                  backgroundColor: "#f9f9f9",
                  cursor: "grab",
                  width: "18%",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    marginBottom: "auto",
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }}
                >
                  {category.name} (ID: {category.id}){" "}
                </span>
                <Badge className={`mt-2 ${badgeColor}`} style={{ fontSize: "0.85rem", width: "80%" }}>
                  {status}
                </Badge>
              </div>
            );
          })}
        </div>
      );
    }
    return rows;
  };
  

  return (
    <Container>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <h2
                style={{
                  textAlign: "center",
                  marginBottom: "20px",
                  color: "#4A4A4A",
                }}
              >
                Create Priority Rule Set
              </h2>
              {error && <Alert color="danger">{error}</Alert>}
              <FormGroup>
                <Label
                  for="segmentSelect"
                  style={{ fontWeight: "bold", color: "#333" }}
                >
                  Select a Segment <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  type="select"
                  id="segmentSelect"
                  value={selectedSegment || ""}
                  onChange={handleSegmentChange}
                  style={{
                    borderRadius: "5px",
                    fontSize: "13px",
                    marginTop: "10px",
                  }}
                >
                  <option value="" disabled>
                    Please select a segment
                  </option>
                  {segments.map((segment) => (
                    <option key={segment.id} value={segment.id}>
                      {segment.id} - {segment.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="priority">
                  Priority <span style={{ color: "red" }}>*</span>
                </Label>
                <CustomTooltip
                  target="priority-tooltip"
                  content={
                    <>
                      Specifies the order in which this rule set is applied,
                      with higher priority rules taking precedence over lower
                      priority ones.
                    </>
                  }
                  placement="right"
                />
                <Input
                  type="number"
                  id="priority"
                  value={priority}
                  onChange={(e) => setPriority(e.target.value)}
                  min="1"
                  placeholder="Enter priority level"
                />
              </FormGroup>
              <FormGroup>
                <Label for="description">
                  Description <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  type="text"
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter a description"
                />
              </FormGroup>
              <h3>Categories</h3>
              <div
                style={{
                  border: "1px solid #dee2e6",
                  borderRadius: "0.25rem",
                  padding: "1rem",
                }}
              >
                {renderCategoryCards()}
              </div>
              <Button
                color="primary"
                onClick={handleSubmit}
                style={{ marginTop: "1rem" }}
                disabled={loading}
              >
                {loading ? "Creating..." : "Create Rule Set"}
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={modalOpen} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>Response</ModalHeader>
        <ModalBody>{modalMessage}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={closeModal}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default CategoryRulesetForm;

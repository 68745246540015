import React, { useState } from "react";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { permissions } from "../../../utilities/commonUtil";
import UserAttributeFilters from "../UserAttributeFilters";
import { getStoryUserAttributeRules, activateStoryUserAttributeRules, deactivateStoryUserAttributeRules, saveStoryUserAttributeRules } from "../../../utilities/apiUtils/stories";
import { sdv3UserAttributeRuleFilterOptions } from "../../../config/configSuggestionDrawer";

const UserAttributeRules = () => {

    const [allowedPermissions] = useState(
        retrieveFromLocalStorage("userPermissions")
    );

    const updateRulesAllowed = () => {
        return allowedPermissions.includes(permissions.storyUserAttributePermission);
    };

    return (
        <div>
            <UserAttributeFilters
                ruleType={"storyId"}
                getFilters={sdv3UserAttributeRuleFilterOptions}
                getRules={getStoryUserAttributeRules}
                saveRules={saveStoryUserAttributeRules}
                deactivateRules={deactivateStoryUserAttributeRules}
                activateRules={activateStoryUserAttributeRules}
                updateRulesAllowed={updateRulesAllowed()}
            />
        </div>
    );
};

export default UserAttributeRules;

import React, { useState, useEffect } from "react";
import Search from "../../../components/Search";
import Select from "react-select";
import { StoryListingCard } from "./components/StoryListingCard";
import ResponseModal from "../ResponseModal";
import ConfirmationModal from "../ConfirmationModal";
import {
  Col,
  Container,
  Row,
  Button,
  Spinner,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { Config } from "../../../config";
import { getQuickReplyCategoriesForForm } from "../../../utilities/apiUtils/quickReplies";
import {
  getStoriesData,
  activateSotdData,
  deactivateSotdData,
  getStoryDetailsData,
  getSotdDetailsData,
  publishSotdData,
  getSotdData,
} from "../../../utilities/apiUtils/stories";
import { successModalHeading, errorModalHeading } from "../../../config/config";

import {
  errorDescription,
  error,
  getStatus,
  allActions,
  permissions,
  redirectionOnTokenExpiry,
  getStatusColor,
  getButtonColor,
  getBtnName,
  redirectOnTokenExpiry,
} from "../../../utilities/commonUtil";

import {
  sotdCreatePermission,
  sotdListPermission,
  sortByOptions,
  statusOptions,
} from "../../../config/configStoryDashboard";

import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { Link } from "react-router-dom";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import EmptyStoriesDisplay from "./components/EmptyStoriesDisplay";
import { faUpload, faTimes } from "@fortawesome/free-solid-svg-icons";
import UncontrolledButtonDropdown from "reactstrap/lib/UncontrolledButtonDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import { capitalize } from "@material-ui/core";
import { SotdCard } from "./components/SotdCard";

const Sotd = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const statusParams = statusOptions.find(
    (status) => status.value === searchParams.get("status")
  );
  const sortByParams = sortByOptions.find(
    (options) => options.value === searchParams.get("sortBy")
  );
  const searchStringParams = searchParams.get("searchString");

  const [id, setID] = useState(null);
  const [action, setAction] = useState("");
  const [stories, setStories] = useState([]);
  const [searchString, setSearchString] = useState(
    searchStringParams !== null ? searchStringParams : ""
  );
  const [bucket, setBucket] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [bucketList, setBucketList] = useState([]);
  const [hasMoreStories, setHasMoreStories] = useState(true);
  const [noStoriesFound, setNoStoriesFound] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [language, setLanguage] = useState("");
  const [successModalState, setSuccessModalState] = useState({
    successModalDisplay: false,
    successModalText: "",
  });
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );
  const [status, setStatus] = useState(
    statusParams !== undefined ? statusParams : statusOptions[0]
  );
  const [sortBy, setSortBy] = useState(
    sortByParams !== undefined ? sortByParams : sortByOptions[0]
  );
  const [searchBy, setSearchBy] = useState(Config.SEARCH_BY_TITLE);
  const [loading, setLoading] = useState(true);
  const [firstRender, setFirstRender] = useState(true);
  const [selectedStories, setSelectedStories] = useState([]);
  const [isBucketSelected, setIsBucketSelected] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");
  const [searchTag, setSearchTag] = useState("");
  const [searchSOTDId, setSearchSOTDId] = useState("");
  const [searchStoryId, setSearchStoryId] = useState("");

  useEffect(() => {
    getStories();
    setFirstRender(false);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let params = {
        searchString: searchTitle,
        ids: searchSOTDId,
        storyIds: searchStoryId,
        tag: searchTag,
        status: status.value,
        sortBy: sortBy.value,
        page: page,
        limit: limit,
      };

      let response = await getSotdData(params);
      if (response.featuredStories !== null) {
        let allStoriesData = stories.concat(response.featuredStories);
        setStories(allStoriesData);
      } else {
        setHasMoreStories(false);
      }
    };

    if (!firstRender) {
      fetchData();
    }
  }, [page]);

  const getStories = async () => {
    let params = {
      searchString: searchTitle,
      ids: searchSOTDId,
      storyIds: searchStoryId,
      tag: searchTag,
      status: status.value,
      sortBy: sortBy.value,
      page: page,
      limit: limit,
    };

    try {
      let response = await getSotdData(params);

      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectionOnTokenExpiry();
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: response.errorDescription,
        });
      } else if (response.featuredStories) {
        if (response.featuredStories !== null) {
          setStories(response.featuredStories);
          setLoading(false);
          return;
        }
      }
      setLoading(false);
      setNoStoriesFound(true);
    } catch (err) {
      setLoading(false);
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const handleSearchStringChange = (event) => {
    event.preventDefault();
    let { placeholder, value } = event.target;
    if (placeholder === "Search by Title") setSearchTitle(value);
    if (placeholder === "Search by Tag") setSearchTag(value);
    if (placeholder === "Search by SOTD ID") setSearchSOTDId(value);
    if (placeholder === "Search by Story ID") setSearchStoryId(value);
    if (!value.length) {
      setSearchString("");
      search();
      return;
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams();
    searchParams.set("status", status?.value);
    searchParams.set("sortBy", sortBy?.value);
    searchParams.set("searchString", searchTitle);

    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
    if (!firstRender) {
      search();
    }
  }, [bucket, status, sortBy, searchTitle, searchTag, searchSOTDId, searchStoryId]);

  const handleBucketChange = (bucket) => {
    setBucket(bucket.value);
  };

  const handleStatusChange = (status) => {
    setStatus(status);
  };

  const handleOrderChange = (sortBy) => {
    setSortBy(sortBy);
  };

  const fetchMoreData = async () => {
    if (searchBy === "sotd Id" || searchBy === "story Id") return;
    if (searchSOTDId || searchStoryId) return; 

    setPage(page + 1);
    setNoStoriesFound(false);
    setHasMoreStories(true);
  };

  const search = async (localSearchBy) => {
    setPage(0);
    setNoStoriesFound(false);
    setHasMoreStories(true);
    setSearchBy(localSearchBy || searchBy);
    setLoading(true);
    setStories([]);
    let params = {
      searchString: searchTitle,
      ids: searchSOTDId,
      storyIds: searchStoryId,
      tag: searchTag,
      status: status.value,
      sortBy: sortBy.value,
      page: page,
      limit: limit,
    };

    try {
      let response = await getSotdData(params);
      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectionOnTokenExpiry();
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: response.errorDescription,
        });
      } else {
        if (response.featuredStories != null) {
          setStories(response.featuredStories);
          setLoading(false);
          return;
        }
        if (page === 0) {
          setLoading(false);
          setNoStoriesFound(true);
        } else {
          setLoading(false);
          setNoStoriesFound(false);
        }
      }
    } catch (err) {
      setLoading(false);
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const setConfirmationModalState = (id, btnName) => {
    setID(id);
    setAction(btnName);
    setConfirmationModalIsOpen(true);
  };

  const performAction = () => {
    if (action === allActions.publish) {
      publish(id);
    }
    if (action === allActions.deactivate) {
      deactivate(id);
    }
    if (action === allActions.activate) {
      activate(id);
    }
  };

  const handleResponse = async (response) => {
    if (!response.errorCode) {
      // If response indicates success
      setSuccessModalState({
        successModalDisplay: true,
        successModalText: response.message,
      });
    } else {
      // If there's an error
      if (
        response.errorCode === error.tokenExpired ||
        response.errorCode === error.invalidAccessToken
      ) {
        redirectionOnTokenExpiry();
      }
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: response.errorDescription,
      });
    }

    // If response contains new story data
    if (response) {
      try {
        const newData = await getSotdDetailsData(response.id);
        // Update the stories state
        setStories((prevStories) => {
          // Check if the new data matches any existing story by ID
          const updatedStories = prevStories.map((story) =>
            story.id === newData.id
              ? {
                  ...story,
                  publishedAt: newData.publishedAt,
                  deactivatedAt: newData.deactivatedAt,
                }
              : story
          );
          return updatedStories;
        });
      } catch (error) {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      }
    }
  };

  const publish = async (id) => {
    try {
      let response = await publishSotdData(id);
      handleResponse(response);
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const deactivate = async (id) => {
    try {
      let response = await deactivateSotdData(id);
      handleResponse(response);
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const activate = async (id) => {
    try {
      let response = await activateSotdData(id);
      handleResponse(response);
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const failureModalClose = () => {
    setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
  };

  const successModelClose = () => {
    setSuccessModalState((prev) => ({
      ...prev,
      successModalDisplay: false,
    }));
  };

  const getIcon = (status) => {
    if (status === "Deactivated") return faUpload;
    return faTimes;
  };

  const renderAllReplies = () => {
    if (stories.length === 0 && !loading) {
      return <EmptyStoriesDisplay />;
    } else {
      return stories.map((reply) => {
        let status = getStatus(reply.publishedAt, reply.deactivatedAt);
        let color = getStatusColor(status);
        let btnColor = getButtonColor(status);
        let btnName = getBtnName(status);
        return (
          <Col md="6" lg="4" key={reply.id}>
            <SotdCard
              reply={reply}
              color={color}
              status={status}
              btnName={btnName}
              btnColor={btnColor}
              btnIcon={getIcon(status)}
              getConfirmation={setConfirmationModalState}
              selectedStories={selectedStories}
              setSelectedStories={setSelectedStories}
              bucket={bucket}
              isChecked={isBucketSelected}
            />
          </Col>
        );
      });
    }
  };

  const onKeyDown = (event) => {
    if (event.keyCode === Config.KEYPRESS_DOWN) {
      search();
    }
  };

  return (
    <Container fluid className="p-0">
      <Row className="align-items-center">
        <Col md={9} sm={6}>
          <h1 className="mb-3 mt-4">SOTD</h1>
        </Col>
        {sotdCreatePermission() ? (
          <Col md={3} sm={6}>
            <Link
              className=" my-auto text-decoration-none mx-2"
              to={{
                pathname: `/stories/sotd/create`,
              }}
            >
              <Button color="primary" className="ml-auto d-flex">
                <p className="my-0">Create SOTD</p>
              </Button>
            </Link>
          </Col>
        ) : null}
      </Row>
      <Row>
      <Col md={3} sm={12}>
          <Search
            placeholder="Search by Title"
            value={searchTitle}
            onChange={handleSearchStringChange}
            onKeyDown={onKeyDown}
          />
        </Col>
        <Col md={3} sm={12}>
          <Input
            type="number"
            placeholder="Search by SOTD ID"
            value={searchSOTDId}
            onChange={handleSearchStringChange}
            onKeyDown={onKeyDown}
          />
        </Col>
        <Col md={3} sm={12}>
          <Input
            type="number"
            placeholder="Search by Story ID"
            value={searchStoryId}
            onChange={handleSearchStringChange}
            onKeyDown={onKeyDown}
          />
        </Col>
        <Col md={3} sm={12}>
          <Search
            placeholder="Search by Tag"
            value={searchTag}
            onChange={handleSearchStringChange}
            onKeyDown={onKeyDown}
          />
        </Col>
      </Row>
      <Row>
        <Col md={3} sm={12}>
          <Label>Select Status</Label>
          <Select
            className="react-select-container mb-4"
            classNamePrefix="react-select"
            placeholder="Select Status"
            value={status}
            onChange={(option) => handleStatusChange(option)}
            options={statusOptions}
          />
        </Col>
        <Col md={3} sm={12}>
          <Label>Sort By</Label>
          <Select
            className="react-select-container mb-4"
            classNamePrefix="react-select"
            placeholder={"Sort By"}
            value={sortBy}
            onChange={(option) => handleOrderChange(option)}
            options={sortByOptions}
          />
        </Col>
      </Row>

      <InfiniteScroll
        className="infinite-scroll"
        dataLength={stories.length}
        next={fetchMoreData}
        hasMore={hasMoreStories}
        loader={
          !loading &&
          searchBy !== "id" &&
          noStoriesFound && <Spinner color="info" className=" d-flex mx-auto" />
        }
      >
        {loading ? (
          <Spinner color="info" className=" d-flex mx-auto" />
        ) : noStoriesFound ? (
          <Container
            sm="12"
            md="8"
            lg="6"
            className="mx-auto d-table mt-5 pt-2 pb-5"
          >
            <h1 className="text-center">No reply found</h1>
          </Container>
        ) : (
          <React.Fragment>
            <Row className="mt-2">{renderAllReplies()}</Row>
            <ResponseModal
              show={successModalState.successModalDisplay}
              onHide={() => successModelClose()}
              modalheading={successModalHeading}
              modaltext={successModalState.successModalText}
            />
            <ResponseModal
              show={failureModalState.failureModalDisplay}
              onHide={() => failureModalClose()}
              modalheading={errorModalHeading}
              modaltext={failureModalState.failureModalText}
            />
            <ConfirmationModal
              show={confirmationModalIsOpen}
              modaltext={action}
              onHide={() => {
                setConfirmationModalIsOpen(false);
              }}
              onConfirmationTrue={() => {
                setConfirmationModalIsOpen(false);
                performAction();
              }}
            />
          </React.Fragment>
        )}
      </InfiniteScroll>
    </Container>
  );
};

export default Sotd;

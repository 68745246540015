import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { Card, CardBody, CardHeader } from 'reactstrap';
import Loading from "../../../components/Loading";

const chartOptions = {
  chart: {
    type: 'line',
    width: '100%',
    toolbar: {
      export: {
        csv: {
          filename: "statistics_graph",
          headerCategory: 'Date',
        },
        svg: {
          filename: "statistics_graph"
        },
        png: {
          filename: "statistics_graph"
        }
      }
    }
  },
  dataLabels: {
    enabled: false
  },
  markers: {
    size: 5,
    hover: {
      sizeOffset: 2
    }
  },
  tooltip: {
    x: {
      format: 'dd-MMM-yyyy',
    }
  },
  fill: {
    colors: ['#5fc27e', '#47BAC1', '#f44455']
  },
  colors: ['#5fc27e', '#47BAC1', '#f44455'],
  stroke: {
    width: 4,
    curve: 'smooth'
  },
  xaxis: {
    type: 'datetime',
    title: {
      text: 'Date',
      style: {
        fontSize: 14,
        fontWeight: 'bold'
      }
    },
    labels: {
      datetimeFormatter: {
        year: 'yyyy',
        month: 'MMM yyyy',
        day: 'dd MMM yyyy',
        hour: 'HH:mm',
      },
    },
  },
  yaxis: {
    title: {
      text: 'Count',
      style: {
        fontSize: 14,
        fontWeight: 'bold'
      }
    },
    labels: {
      formatter: function (value) {
        return value;
      }
    },
    axisBorder: {
      show: true
    },
  }
}

const StatisticsGraph = ({ loading = false, data = [] }) => {
  const [series, setSeries] = useState([])

  useEffect(() => {
    if (data && data.length > 0) {
      const seriesData = {
        '200': [],
        '400': [],
        '500': []
      };

      data.forEach(item => {
        if (item.statusCode && seriesData[item.statusCode]) {
          seriesData[item.statusCode].push([new Date(item.logTime).getTime(), item.count]);
        }
      });

      const series = Object.keys(seriesData)
        .filter(statusCode => seriesData[statusCode].length > 0)
        .map(statusCode => ({ name: statusCode, data: seriesData[statusCode] }));

      setSeries(series);
    }
  }, [data])

  return (
    <Card>
      <CardHeader>
        <h4 className="mb-0 pt-2">Request Traffic</h4>
      </CardHeader>
      <CardBody className="pt-0">
        {
          loading
            ? <Loading></Loading>
            : data.length > 0
              ? <Chart options={chartOptions} series={series} type="line" height={500} />
              : <h4 className='text-center py-5 font-weight-light'>No statistics available for the selected filters</h4>
        }
      </CardBody>
    </Card>
  )
}

export default StatisticsGraph;
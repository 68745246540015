import { host } from "../apiUtils/index";
import { retrieveFromLocalStorage } from "../localStorageUtil";
import { valueTypes, error, redirectOnTokenExpiry, processTimestamps } from "../commonUtil";
import { addStatus } from '../apiUtils'
import { secureFetch } from "../secureFetch";

let parseContentCategoriesData = (dataArray) => {
  dataArray.forEach((data) => {
    if (data.valueType === valueTypes.json) {
      data.value = JSON.stringify(data.value);
    }
    data.status = addStatus(
      data.createdAt,
      data.publishedAt,
      data.deactivatedAt
    );
    processTimestamps(data);
  });

  return dataArray;
};

let addTagsStatus = (created, enabled) => {
  if (created !== null && enabled === null) {
    return "Created"
  }
  else if (created !== null && enabled === true) {
    return "Enabled"
  }
  else if (created !== null && enabled === false) {
    return "Disabled"
  }
}

let parsePromotedTagsData = (dataArray) => {
  dataArray.forEach((data) => {
    if (data.valueType === valueTypes.json) {
      data.value = JSON.stringify(data.value);
    }
    data.status = addTagsStatus(
      data.createdAt,
      data.enabled,
    );
    processTimestamps(data);
  });

  return dataArray;
};

export let getContentCategories = async (params) => {
  let token = retrieveFromLocalStorage("token");
  let data = [];

  let url = `${host}/v4/internal/contentCategories?` + new URLSearchParams(params);;

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.errorCode) {
        data = res;
      }
      else if (Object.keys(res.contentCategories).length > 0) data = parseContentCategoriesData(res.contentCategories);
      else data = res;
    });

  return data;
};

export let createContentCategory = async (newCategory) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentCategories`;

  await fetch(url, {
    method: "POST",
    body: newCategory,
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let getContentCategoryDetail = async (categoryID) => {
  let token = retrieveFromLocalStorage("token");
  let contentCategory = {};

  let url = `${host}/v4/internal/contentCategories/${categoryID}`;
  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then(async (response) => {
      let data = await response.json();
      processTimestamps(data.contentCategory);
      return data;
    })
    .then((response) => {
      contentCategory = response;
    });
  return contentCategory;
};

export let updateContentCategory = async (updatedCategory, categoryID) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentCategories/${categoryID}`;

  await fetch(url, {
    method: "POST",
    body: updatedCategory,
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let activateContentCategory = async (categoryID) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentCategories/${categoryID}/activate`;

  await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let deactivateContentCategory = async (categoryID) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentCategories/${categoryID}/deactivate`;

  await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let publishContentCategory = async (categoryID) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentCategories/${categoryID}/publish`;

  await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let getPromotedTags = async (params) => {
  let token = retrieveFromLocalStorage("token");
  let promotedTags = {};

  let url = `${host}/v4/internal/contentPromotedTags?` + new URLSearchParams(params);

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.errorCode) {
        promotedTags = res;
      }
      else if (Object.keys(res.contentPromotedTags).length > 0) promotedTags = parsePromotedTagsData(res.contentPromotedTags);
      else promotedTags = res;
    });

  return promotedTags;
};

export let createPromotedTags = async (newTag) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentPromotedTags`;

  await fetch(url, {
    method: "POST",
    body: JSON.stringify(newTag),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json"
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let getPromotedTagsDetails = async (id) => {
  let token = retrieveFromLocalStorage("token");
  let theme = {};
  let url = `${host}/v4/internal/contentPromotedTags/${id}`;

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then(async (response) => {
      let data = await response.json();
      processTimestamps(data.contentPromotedTag);
      return data;
    })
    .then((response) => {
      theme = response;
    });
  return theme;
};

export let editPromotedTags = async (updatedTag, id) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentPromotedTags/${id}`;

  await fetch(url, {
    method: "POST",
    body: JSON.stringify(updatedTag),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json"
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let enablePromotedTags = async (id) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentPromotedTags/${id}/enable`;

  await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let disablePromotedTags = async (id) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentPromotedTags/${id}/disable`;

  await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let getPromotedTagsRules = async (id) => {
  let rules = [];

  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentPromotedTags/${id}/rules`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      rules = res.rules;
    }
    return rules;
  } catch (error) {
    return;
  }
};

export let savePromotedTagsRules = async (data, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentPromotedTags/${id}/rules`;
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ rules: data }),
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let getContentCategoryRules = async (id) => {
  let rules = [];

  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentCategories/${id}/rules`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      rules = res.rules;
    }
    return rules;
  } catch (error) {
    return;
  }
};

export let saveContentCategoryRules = async (data, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentCategories/${id}/rules`;
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ rules: data }),
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let getContentCategoryUserAttributeRules = async (id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentCategories/${id}/userAttributeRules`;
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    return res;
  } catch (error) {
    return;
  }
};

export let saveContentCategoryUserAttributeRules = async (data, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentCategories/${id}/userAttributeRules`;
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let deactivateContentCategoryUserAttributeRules = async (contentCategoryId, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentCategories/${contentCategoryId}/userAttributeRules/${id}/deactivate`;
  try {
    var response = await secureFetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let activateContentCategoryUserAttributeRules = async (contentCategoryId, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentCategories/${contentCategoryId}/userAttributeRules/${id}/activate`;
  try {
    var response = await secureFetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let getContentCategoryCarouselUserAttributeRules = async (id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentCategoryCarousels/${id}/userAttributeRules`;
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    return res;
  } catch (error) {
    return;
  }
};

export let saveContentCategoryCarouselUserAttributeRules = async (data, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentCategoryCarousels/${id}/userAttributeRules`;
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let deactivateContentCategoryCarouselUserAttributeRules = async (contentCategoryCarouselId, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentCategoryCarousels/${contentCategoryCarouselId}/userAttributeRules/${id}/deactivate`;
  try {
    var response = await secureFetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let activateContentCategoryCarouselUserAttributeRules = async (contentCategoryCarouselId, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentCategoryCarousels/${contentCategoryCarouselId}/userAttributeRules/${id}/activate`;
  try {
    var response = await secureFetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let getClients = async () => {
  let clients = [];

  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stickers/clients`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((client) => {
        let clientObj = {
          value: client.id,
          label: client.name,
        };
        clients.push(clientObj);
      });
    }
    return clients;
  } catch (error) {
    return;
  }
};

export let getCountries = async () => {
  let countries = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stickers/countries`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((country) => {
        let countryObj = {
          value: country.id,
          label: country.name,
        };
        countries.push(countryObj);
      });
    }
    return countries;
  } catch (error) {
    return;
  }
};

export let getRegions = async (countryID) => {
  let regions = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stickers/regions?countryID=${countryID}`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((region) => {
        let regionObj = {
          value: region.id,
          label: region.name,
        };
        regions.push(regionObj);
      });
    }
    return regions;
  } catch (error) {
    return;
  }
};

export let getCities = async (countryID, regionID) => {
  let cities = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stickers/cities?countryID=${countryID}&regionID=${regionID}`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((city) => {
        let cityObj = {
          value: city.id,
          label: city.name,
        };
        cities.push(cityObj);
      });
    }
    return cities;
  } catch (error) {
    return;
  }
};

export let getInternalContentSearches = async (params) => {
  let token = retrieveFromLocalStorage("token");
  let contentSearches = {};

  let url = `${host}/v4/internal/contents/debug?` + new URLSearchParams(params);

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then(async (response) => {
      let data = await response.json();
      if (data.contentSearches) {
        data.contentSearches.forEach((contentSearch) => {
          processTimestamps(contentSearch);
        });
      }
      return data;
    })
    .then((response) => {
      contentSearches = response;
    });
  return contentSearches;
};
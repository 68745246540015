import React, { useState } from "react";
import TargetingRules from "../TargetingRules";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { permissions } from "../../../utilities/commonUtil";
import { getPromotedTagsRules, savePromotedTagsRules } from "../../../utilities/apiUtils/cre";
import {
    getClients,
    getCountries,
    getRegions,
    getCities,
} from "../../../utilities/apiUtils/stickers";

const StyleDetail = (props) => {
    let id = props.match.params.id;

    const [allowedPermissions] = useState(
        retrieveFromLocalStorage("userPermissions")
    );

    const updateRulesAllowed = () => {
        return allowedPermissions.includes(permissions.contentPromotedTagRuleUpdate);
    };

    return (
        <div>
            <TargetingRules
                showAgeGenderRules={true}
                id={id}
                getRules={getPromotedTagsRules}
                saveRules={savePromotedTagsRules}
                getClients={getClients}
                getCountries={getCountries}
                getRegions={getRegions}
                getCities={getCities}
                updateRulesAllowed={updateRulesAllowed()}
            />
        </div>
    );
};

export default StyleDetail;

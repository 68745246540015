import { host } from "./index";
import { retrieveFromLocalStorage } from "../localStorageUtil";
import { secureFetch } from "../secureFetch";

export let getCarousels = async () => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentCategoryCarousels`;
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    if (response.ok) {
      return await response.json();
    } else {
      // Handle error cases here
      throw new Error(`Failed to fetch rewards (${response.status})`);
    }
  } catch (error) {
    // Handle network errors or other exceptions
    throw error;
  }
};

export const createCarousel = async (carouselData) => {
  const token = retrieveFromLocalStorage("token");
  const url = `${host}/v4/internal/contentCategoryCarousels`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(carouselData),
    });

    if (response.ok) {
      const createdCarousel = await response.json();
      return createdCarousel.id; // Return the ID of the created carousel
    } else {
      throw new Error(`Failed to create carousel (${response.status})`);
    }
  } catch (error) {
    throw error;
  }
};

export const getCarouselById = async (carouselId) => {
  const token = retrieveFromLocalStorage("token");
  const url = `${host}/v4/internal/contentCategoryCarousels/${carouselId}`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      return await response.json();
    } else {
      // Handle error cases here
      throw new Error(`Failed to fetch carousel (${response.status})`);
    }
  } catch (error) {
    // Handle network errors or other exceptions
    throw error;
  }
};

export const updateCarousel = async (id, carouselData) => {
  const token = retrieveFromLocalStorage("token");
  const url = `${host}/v4/internal/contentCategoryCarousels/${id}`; // Assuming `carouselData` includes an `id` field

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Set the content type to JSON
      },
      body: JSON.stringify(carouselData), // Convert carousel data to JSON
    });

    if (response.ok) {
      return await response.json();
    } else {
      // Handle error cases here
      throw new Error(`Failed to update carousel (${response.status})`);
    }
  } catch (error) {
    // Handle network errors or other exceptions
    throw error;
  }
};

export const addItemToCarousel = async (carouselId, newItemData) => {
  const token = retrieveFromLocalStorage("token");
  const url = `${host}/v4/internal/contentCategoryCarousels/${carouselId}/items`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newItemData),
    });

    if (response.ok) {
      return true; // Indicate success without returning the item ID
    } else {
      // Handle error cases here
      throw new Error(
        `Failed to create item in the carousel (${response.status})`
      );
    }
  } catch (error) {
    // Handle network errors or other exceptions
    throw error;
  }
};

// POST API to update content category carousels order
export const updateContentCategoryCarouselsOrder = async (orderData) => {
  const token = retrieveFromLocalStorage("token");
  const url = `${host}/v4/internal/contentCategoryCarouselsOrder`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orderData),
    });

    if (response.ok) {
      return true; // Indicate success
    } else {
      // Handle error cases here
      throw new Error(
        `Failed to update content category carousels order (${response.status})`
      );
    }
  } catch (error) {
    // Handle network errors or other exceptions
    throw error;
  }
};

// GET API to fetch content category carousels order
export const getContentCategoryCarouselsOrder = async () => {
  const token = retrieveFromLocalStorage("token");
  const url = `${host}/v4/internal/contentCategoryCarouselsOrder`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data; // Return the content category carousels order data
    } else {
      // Handle error cases here
      throw new Error(
        `Failed to fetch content category carousels order (${response.status})`
      );
    }
  } catch (error) {
    // Handle network errors or other exceptions
    throw error;
  }
};

// GET API to fetch items of a content category carousel
export const getCarouselItems = async (carouselId) => {
  const token = retrieveFromLocalStorage("token");
  const url = `${host}/v4/internal/contentCategoryCarousels/${carouselId}/items`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      return await response.json();
    } else {
      // Handle error cases here
      throw new Error(`Failed to fetch carousel items (${response.status})`);
    }
  } catch (error) {
    // Handle network errors or other exceptions
    throw error;
  }
};

// Get details of a specific carousel item
export const getCarouselItemById = async (carouselId, itemId) => {
  const token = retrieveFromLocalStorage("token");
  const url = `${host}/v4/internal/contentCategoryCarousels/${carouselId}/items/${itemId}`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    });

    if (response.ok) {
      const data = await response.json();
      // Handle the retrieved item data if needed
      return data;
    } else {
      throw new Error(`Failed to fetch carousel item (${response.status})`);
    }
  } catch (error) {
    throw error;
  }
};

//Activate carousel item
export let activateCarouselItem = async (id, itemId) => {
  let url = `${host}/v4/internal/contentCategoryCarousels/${id}/items/${itemId}/activate`;
  let token = retrieveFromLocalStorage("token");
  try {
    let response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        'Content-Type': 'application/json'
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

//Deactivate carousel item
export let deactivateCarouselItem = async (id, itemId) => {
  let url = `${host}/v4/internal/contentCategoryCarousels/${id}/items/${itemId}/deactivate`;
  let token = retrieveFromLocalStorage("token");
  try {
    let response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        'Content-Type': 'application/json'
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

// POST API to update the order of items within a content category carousel
export const updateCarouselItemsOrder = async (carouselId, updatedOrder) => {
  const token = retrieveFromLocalStorage("token");
  const url = `${host}/v4/internal/contentCategoryCarousels/${carouselId}/itemsOrder`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedOrder),
    });

    if (response.ok) {
      return true; // Indicate success
    } else {
      // Handle error cases here
      throw new Error(`Failed to update carousel items order (${response.status})`);
    }
  } catch (error) {
    // Handle network errors or other exceptions
    throw error;
  }
};


// POST API to edit a content category carousel item
export const editCarouselItem = async (carouselId, itemId, updatedItemData) => {
  const token = retrieveFromLocalStorage("token");
  const url = `${host}/v4/internal/contentCategoryCarousels/${carouselId}/items/${itemId}`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedItemData),
    });

    if (response.ok) {
      return true; // Indicate success
    } else {
      // Handle error cases here
      throw new Error(`Failed to edit carousel item (${response.status})`);
    }
  } catch (error) {
    // Handle network errors or other exceptions
    throw error;
  }
};

// POST API to publish, activate, or deactivate a content category carousel
export const updateCarouselStatus = async (carouselId, action) => {
  const token = retrieveFromLocalStorage("token");
  const url = `${host}/v4/internal/contentCategoryCarousels/${carouselId}/${action}`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      return true; // Indicate success
    } else {
      // Handle error cases here
      throw new Error(`Failed to ${action} carousel (${response.status})`);
    }
  } catch (error) {
    // Handle network errors or other exceptions
    throw error;
  }
};

// GET API to fetch targeting rules of a content category carousel
export const getCarouselTargetingRules = async (carouselId) => {
  const token = retrieveFromLocalStorage("token");
  const url = `${host}/v4/internal/contentCategoryCarousels/${carouselId}/targetingRules`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      return await response.json();
    } else {
      // Handle error cases here
      throw new Error(`Failed to fetch carousel targeting rules (${response.status})`);
    }
  } catch (error) {
    // Handle network errors or other exceptions
    throw error;
  }
};

// POST API to update targeting rules of a content category carousel
export const updateCarouselTargetingRules = async (rules, carouselId, updatedRules) => {
  const token = retrieveFromLocalStorage("token");
  const url = `${host}/v4/internal/contentCategoryCarousels/${carouselId}/targetingRules`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedRules),
    });

    if (response.ok) {
      return true; // Indicate success
    } else {
      // Handle error cases here
      throw new Error(`Failed to update carousel targeting rules (${response.status})`);
    }
  } catch (error) {
    // Handle network errors or other exceptions
    throw error;
  }
};

export const fetchCarouselItemsOrder = async (carouselId) => {
  const token = retrieveFromLocalStorage("token");
  const url = `${host}/v4/internal/contentCategoryCarousels/${carouselId}/itemsOrder`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    });

    if (response.ok) {
      const data = await response.json();
      return data; // Return the fetched data
    } else {
      throw new Error(`Failed to fetch carousel items order (${response.status})`);
    }
  } catch (error) {
    console.error('Error fetching carousel items order:', error.message);
    throw error;
  }
};


export let getCountries = async () => {
  let countries = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentCategoryCarousels/countries`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.forEach((country) => {
        let countryObj = {
          value: country.id,
          label: country.name,
        };
        countries.push(countryObj);
      });
    }
    return countries;
  } catch (error) {
    return;
  }
};

export let getRegions = async (countryID) => {
  let regions = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentCategoryCarousels/regions?countryID=${countryID}`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.forEach((region) => {
        let regionObj = {
          value: region.regionId,
          label: region.region,
        };
        regions.push(regionObj);
      });
    }
    return regions;
  } catch (error) {
    return;
  }
};

export let getCities = async (countryID, regionID) => {
  let cities = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentCategoryCarousels/cities?countryID=${countryID}&regionID=${regionID}`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.forEach((city) => {
        let cityObj = {
          value: city.cityId,
          label: city.cityName,
        };
        cities.push(cityObj);
      });
    }
    return cities;
  } catch (error) {
    return;
  }
};

export let getLanguages = async () => {
  let languages = [];
  let token = retrieveFromLocalStorage("token");
  let allObj = {
    value: "All",
    label: "All",
  };
  let englishObj = {
    value: "en",
    label: "English",
  };
  languages.push(allObj);
  languages.push(englishObj);

  let url = `${host}/v4/internal/stickers/languages`;
  try {
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let res = await response.json();
    if (res) {
      res.map((language) => {
        let languageObj = {
          value: language.code,
          label: language.name,
        };
        languages.push(languageObj);
      });
    }
    return languages;
  } catch (error) {
    return;
  }
};
import React from "react";
import { Badge, Row, Col } from "reactstrap";

const AgeGenderRuleSummary = (props) => {
  const showHardFiltering = props.showHardFiltering;

  return (
    <div>
      <Row className="my-1 align-items-start pl-2">
        <Col className="p-1 ">
          <Badge className="float-left m-1" color={"success"}>
            Min Age : {props.minAge ?? null}
          </Badge>
          <Badge className="float-left m-1" color={"success"}>
            Max Age : {props.maxAge ?? null}
          </Badge>
          <Badge className="float-left m-1" color={"success"}>
            Gender : {props.gender.value ?? "Unisex"}
          </Badge>
          {showHardFiltering ? <>
            <Badge
              className="float-left m-1"
              color={props.ageHardFiltering ? "success" : "danger"}
            >
              Age Hard Filtering :{" "}
              {props.ageHardFiltering ? "Enabled" : "Disabled"}
            </Badge>
            <Badge
              className="float-left m-1"
              color={props.genderHardFiltering ? "success" : "danger"}
            >
              Gender Hard Filtering :{" "}
              {props.genderHardFiltering ? "Enabled" : "Disabled"}
            </Badge>
          </> : null}
        </Col>
      </Row>
    </div>
  );
};

export default AgeGenderRuleSummary;

import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import {
  Card,
  FormGroup,
  Label,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  Input,
} from "reactstrap";
import Select from "react-select";

const EditABTestModal = (props) => {
  const [id, setID] = useState("");
  const [description, setDescription] = useState("");
  const [existingUsersRolloutPercentage, setExistingUsersRolloutPercentage] = useState(0);

  // This useEffect will be called for componentWillReceiveProps condition
  useEffect(() => {
    setID(props.id);
    setDescription(props.description);
    setExistingUsersRolloutPercentage(props.existingUsersRolloutPercentage);
  }, [props]);

  const handlePercentageChange = (event) => {
    setExistingUsersRolloutPercentage(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    const { value } = event.target;
    setDescription(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let updatedABTest = {};
    if (description.length > 0) {
      updatedABTest.description = description;
    }
    updatedABTest.existingUsersRolloutPercentage = parseInt(existingUsersRolloutPercentage, 10);
    props.editABTest(id, updatedABTest);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit AB Test
        </Modal.Title>
      </Modal.Header>
      <Card className="mb-0">
        <CardBody>
          <Form onSubmit={(event) => handleSubmit(event)}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Description</Label>
                  <Input
                    type="textarea"
                    name="description"
                    placeholder="Description"
                    value={description}
                    defaultValue={props.description}
                    onChange={(event) => handleDescriptionChange(event)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Existing Users Rollout Percentage
                  </Label>
                  <Input
                    type="number"
                    name="existingUsersRolloutPercentage"
                    onChange={(event) => handlePercentageChange(event)}
                    placeholder="Existing users rollout percentage"
                    value={existingUsersRolloutPercentage}
                    max={100}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary" type="submit">
              Submit
            </Button>
          </Form>
        </CardBody>
      </Card>
    </Modal>
  );
};

export default EditABTestModal;

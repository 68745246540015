import React, { PureComponent } from "react";
import {
    fetchGifPack,
    getGifPacks,
    fetchGifsInGifPack,
    reorderGifs,
    importGifs,
    updateGifStatus,
    updateGifPackGifs
} from "../../../utilities/apiUtils";
import CustomTooltip from "../../../components/customToolTip";
import InfiniteScroll from "react-infinite-scroll-component";
import Gif from "./components/Gif";
import {
    error,
    getStatus,
    getStatusColor,
    errorDescription,
    redirectOnTokenExpiry,
    redirectTo,
    permissions
} from "../../../utilities/commonUtil";
import CountryTags from "../../../components/CountryTags";
import ResponseModal from "../ResponseModal";
import Loading from "../../../components/Loading";
import {
    Card,
    CardBody,
    CardImg,
    CardHeader,
    CardTitle,
    Col,
    Container,
    Row,
    Spinner,
    Button,
    Collapse,
    Input,
    Label,
} from "reactstrap";
import Select from "react-select";
import { Config } from "../../../config/UserConfig";
import { Fragment } from "react";
import UncontrolledButtonDropdown from "reactstrap/lib/UncontrolledButtonDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import Search from "../../../components/Search";
import { capitalize } from "@material-ui/core";
import { Alert } from "react-bootstrap";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import RearrangeGifs from "./RearrangeGifs";
import ImportGifs from "./ImportGifs";
import GifStatus from "./GifStatus";

class GifPack extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            hasResponseError: false,
            activeTab: "all",
            status: "",
            gifPack: {},
            allGifs: [],
            filteredGifs: [],
            activeGif: [],
            successModalText: "",
            failureModalText: "",
            successModalDisplay: false,
            failureModalDisplay: false,
            id: null,
            searchString: "",
            page: 0,
            limit: 20,
            hasMoreGifs: true,
            sortBy: Config.UPDATED_AT_DESCENDING,
            searchBy: "tag",
            allowedPermissions: retrieveFromLocalStorage("userPermissions"),
            isChecked: false,
            selectedGifs: [],
            showRearrangeModal: false,
            showImportModal: false,
            showStatusModal: false,
            selectedGif: {},
            statusModalAction: "publish",
            gifsReordered: [],
            loadingGifs: false,
            startAt: null,
            endAt: null,
            filterDateTime: null,   
            isBulkActionCollapseOpen: false,   
            filterStatus: null,
        };
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        this.setState({ id });
        this.getGifPack(id);
        this.getGifInPack(id);
        this.getGifPackList();
    }

    getGifPackList = async () => {
        await getGifPacks()
            .then((response) => {
                if (response.errorCode) {
                    if (
                        response.errorCode === error.tokenExpired ||
                        response.errorCode === error.invalidAccessToken
                    ) {
                        redirectOnTokenExpiry();
                        this.setState({
                            failureModalDisplay: true,
                            failureModalText: errorDescription(response.errorCode),
                        });
                    }
                } else {
                    this.setState({ gifPacks: response });
                }
            })
            .catch((err) => { });
    };

    getGifInPack = async (id) => {
        let params = {
            page: this.state.page,
            limit: this.state.limit,
        };
        await fetchGifsInGifPack(id, params)
            .then((res) => {
                if (res.gifs) {
                    let activeGif = this.getActiveGifs(res.gifs);
                    this.setState({
                        allGifs: res.gifs,
                        filteredGifs: res.gifs.map((gif) => {
                            let newgif = {
                                ...gif,
                                isChecked: false
                            }
                            return newgif
                        }),
                        activeGif,
                    });
                } else {
                    this.setState({
                        hasResponseError: true,
                        failureModalText: errorDescription(res.errorCode),
                    });
                }
                this.setState({ loading: false });
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                    failureModalDisplay: true,
                    failureModalText: errorDescription(error.unexpectedError),
                });
            });
    }

    fetchMoreData = async () => {
        if (this.state.hasMoreGifs) {
            const { page, limit, status } = this.state; // Get the current filter
        
            // Query parameters
            const params = {
              page: page + 1,
              limit: limit,
              filter: status, // Pass the current filter status to the next page
              date: this.state.filterDateTime !== "" && this.state.filterDateTime !== null ? new Date(this.state.filterDateTime).toISOString() : ""
            };
      
            // API call with given query parameters
      const res = await fetchGifsInGifPack(this.state.id, params);
  
      if (res.gifs && res.gifs.length > 0) {
        this.setState((prevState) => ({
          allGifs: prevState.allGifs.concat(res.gifs),
          filteredGifs: prevState.filteredGifs.concat(res.gifs),
          page: prevState.page + 1, // Increment the page from prevState
          hasMoreGifs: true,
        }));
      } else {
        this.setState({ hasMoreGifs: false });
    }
  }
};
      
    getGifPack = (id) => {
        fetchGifPack(id)
            .then((res) => {
                if (res.gifPack) {
                    this.setState({
                        gifPack: res.gifPack,
                    });
                } else {
                    this.setState({
                        hasResponseError: true,
                        failureModalText: errorDescription(res.errorCode),
                    });
                }
                this.setState({ loading: false });
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                    failureModalDisplay: true,
                    failureModalText: errorDescription(error.unexpectedError),
                });
            });
    };

    getActiveGifs = (pack) => {
        let activePacks = pack.filter((gif) => {
            return (
                getStatus(gif.publishedAt, gif.deactivatedAt) ===
                Config.PUBLISHED_STATUS
            );
        });
        return activePacks;
    };

    getActiveOrUnpublishedGifs = (pack) => {
        let packs = pack.filter((gif) => {
            return (
                getStatus(gif.publishedAt, gif.deactivatedAt) === Config.PUBLISHED_STATUS
                || getStatus(gif.publishedAt, gif.deactivatedAt) === Config.UNPUBLISHED_STATUS
            );
        });
        return packs;
    };

    handleFilterChange = async (selectedOption) => {
        const status = !!selectedOption?.value ? selectedOption.value : "";
        console.log(status);
        this.setState({ filterStatus: selectedOption });
        // Reset page to 0 when status changes
        await this.setState({ page: 0, hasMoreGifs: true, status });
      
        if (status !== "" || (this.state.filterDateTime !== null)) {
            // Query parameters
          const params = {
            page: this.state.page,
            limit: this.state.limit,
            filter: status,
            date: this.state.filterDateTime !== "" && this.state.filterDateTime !== null ? new Date(this.state.filterDateTime).toISOString() : ""
          };
      
          // API call with given query parameters
          const res = await fetchGifsInGifPack(this.state.id, params);
      
          if (res.gifs) {
            this.setState({
              allGifs: res.gifs,
              filteredGifs: res.gifs,
              hasMoreGifs: true,
            });
          } else {
            this.setState({ hasMoreGifs: false });
          }
        }
      };
    onKeyDown = (event) => {
        if (event.keyCode === Config.KEYPRESS_DOWN) {
            this.search();
        }
    };

    handleOrderChange = (sortBy) => {
        let gifs = [];
        if (sortBy.value == Config.UPDATED_AT_DESCENDING) {
            gifs = this.state.filteredGifs.sort((a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt));
        } else if (sortBy.value == Config.UPDATED_AT_ASCENDING) {
            gifs = this.state.filteredGifs.sort((a, b) => Date.parse(a.updatedAt) - Date.parse(b.updatedAt));
        }
        this.setState({ sortBy: sortBy.value, filteredGifs: gifs });
    };

    handleSearchStringChange = (event) => {
        let value = event.target.value;
        this.setState({ searchString: value }, () => {
            if (!value.length) {
                this.search();
            }
        });
        event.preventDefault();
    };

    search = (searchBy) => {
        searchBy = searchBy || this.state.searchBy;
        this.setState({ searchBy });
        let status = this.state.status;
        let sortBy = this.state.sortBy;
        let searchString = this.state.searchString;
        let gifs = this.state.allGifs;

        if (searchBy == "id") {
            gifs = gifs.filter(gif => gif.id == searchString)
            this.setState({ filteredGifs: gifs });
            return;
        }
        // search by tags
        if (searchString != "") {
            gifs = gifs.filter(gif => gif.tags.includes(searchString))
        }

        // sort
        if (sortBy == Config.UPDATED_AT_DESCENDING) {
            gifs = gifs.sort((a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt));
        } else if (sortBy == Config.UPDATED_AT_ASCENDING) {
            gifs = gifs.sort((a, b) => Date.parse(a.updatedAt) - Date.parse(b.updatedAt));
        }
        this.setState({ filteredGifs: gifs });
    }

    updateGifPackGifsDateTime = async () => {

        if(this.state.startAt !== null && this.state.endAt !== null && this.state.startAt !== "" && this.state.endAt !== "" && this.state.startAt > this.state.endAt) {
            this.setState({
            failureModalDisplay: true,
            failureModalText: "Start date should be less than end date",
          });
          return;
        }
    
        let selectedGifs = this.state.selectedGifs;
        let gifPackId = this.state.id;
        // convert datetime to ISO string
        let startAt = this.state.startAt !== "" && this.state.startAt !== null ? new Date(this.state.startAt).toISOString() : null;
        let endAt = this.state.endAt !== "" && this.state.endAt !== null ? new Date(this.state.endAt).toISOString() : null;
    
        let data = {
          gifIds : selectedGifs.map(gif => gif.id),
          startAt: startAt,
          endAt: endAt
        }
    
        let response = await updateGifPackGifs(gifPackId, data);
    
        if (response.status === "Success") {
          this.setState({
            successModalDisplay: true,
            successModalText: response.description,
          });
        } else {
          this.setState({
            failureModalDisplay: true,
            failureModalText: response.errorDescription,
          });
        }
    
      };

      isAddButtonDisabled = () => {
        let { activeTab, allGifs, activeGif } = this.state;
        switch (activeTab) {
          case "all":
            return allGifs.length === 0;
          case "active":
            return activeGif.length === 0;
          default:
            return 1;
        }
      };

    toggleBulkActionCollapse = () => {
        this.setState({ isBulkActionCollapseOpen: !this.state.isBulkActionCollapseOpen });
    };

    closeBulkActionCollapse = () => {
        this.toggleBulkActionCollapse();
        this.setState({ selectedGifs: [] });
    };
    
    showEditRulesButton() {
        return this.state.allowedPermissions.includes(permissions.listGifPackRules)
    }

    redirectToLocationRule = () => {
        let var1 = window.location.href.split("/")[0]
        let var2 = window.location.href.split("/")[2]
        let var3 = window.location.href.split("/")[5]
        window.location.href = `${var1}//${var2}/gifs/gif-pack/${var3}/targetingRules`
    }

    showEditRulesAutoDownloadButton = () => {
        return this.state.allowedPermissions.includes(permissions.listGifPackAutoDownloadRules)
    }

    redirectToAutoDownloadLocationRules = () => {
        let var1 = window.location.href.split("/")[0]
        let var2 = window.location.href.split("/")[2]
        let var3 = window.location.href.split("/")[5]
        window.location.href = `${var1}//${var2}/gifs/gif-pack/${var3}/autoDownloadRules`
    }

    handleCheckboxChange = (event, gif) => {
        if (event.target.checked) {
            this.setState({
                selectedGifs: [...this.state.selectedGifs, gif],
            });
        } else {
            this.setState({
                selectedGifs: this.state.selectedGifs.filter(
                    (gifObj) => gifObj !== gif
                ),
            });
        }
    };

    displayRearrangeGifModal = () => {
        this.setState({ showRearrangeModal: true });
    };

    hideRearrangeGifModal = () => {
        this.setState({ showRearrangeModal: false });
    };

    displayImportGifsModal = () => {
        this.setState({ showImportModal: true });
    };

    hideImportGifsModal = () => {
        this.setState({ showImportModal: false });
    };

    displayGifStatusModal = (gif, action) => {
        this.setState({ selectedGif: gif, statusModalAction: action }, () => { this.setState({ showStatusModal: true }) });
    };

    hideGifStatusModal = () => {
        this.setState({ showStatusModal: false });
    };

    failureModalClose = () => {
        this.setState({ failureModalDisplay: false });
    };

    successModalClose = () => {
        this.setState({ successModalDisplay: false, selectedGifs : [] });
        this.getGifInPack(this.state.id).then(() => {
            this.handleFilterChange(this.state.filterStatus);
          });
    };

    fetchAllGifPackGifs = async (filter, filterExpiredContents = false) => {
        this.setState({ loadingGifs: true });
        await fetchGifsInGifPack(this.state.id, { limit: 999999, filter: filter, page: 0, filterExpiredContents: filterExpiredContents })
            .then((res) => {
                if (res.gifs) {
                    let gifs = this.getActiveOrUnpublishedGifs(res.gifs);
                    this.setState({
                        gifsReordered: gifs,
                        loadingGifs: false,
                    });
                } else {
                    this.setState({
                        hasResponseError: true,
                        failureModalText: errorDescription(res.errorCode),
                    });
                }
                this.setState({ loading: false });
            })
            .catch((err) => {
                this.setState({
                    loadingGifs: false,
                    failureModalDisplay: true,
                    failureModalText: errorDescription(error.unexpectedError),
                });
            });
    }

    reorder = async (gifs) => {
        await reorderGifs(gifs, this.state.gifPack.id)
            .then((response) => {
                if (response.status === "Success") {
                    this.setState({
                        successModalDisplay: true,
                        successModalText: "Gifs rearranged successfully.",
                    });
                    window.location.reload();
                } else {
                    this.setState({
                        failureModalDisplay: true,
                        failureModalText: response.errorDescription,
                    });
                }
                this.hideRearrangeGifModal();
            })
            .catch((err) => {
                this.setState({
                    failureModalDisplay: true,
                    failureModalText: errorDescription(error.unexpectedError),
                });
            });
    };

    import = async (gifs, gifPackId) => {
        await importGifs(gifs, gifPackId)
            .then((response) => {
                if (response.status === "Success") {
                    this.setState({
                        successModalDisplay: true,
                        successModalText: "Gifs imported successfully.",
                    });
                } else {
                    this.setState({
                        failureModalDisplay: true,
                        failureModalText: response.errorDescription,
                    });
                }
                this.hideImportGifsModal();
            })
            .catch((err) => {
                this.setState({
                    failureModalDisplay: true,
                    failureModalText: errorDescription(error.unexpectedError),
                });
            });
    };

    updateGifStatus = async (gifId, action, gifPackIds, globalStatus) => {
        await updateGifStatus(gifId, action, gifPackIds, globalStatus)
            .then((response) => {
                if (response.status === "Success") {
                    this.setState({
                        successModalDisplay: true,
                        successModalText: "Gif status updated successfully.",
                    });
                    window.location.reload();
                } else {
                    this.setState({
                        failureModalDisplay: true,
                        failureModalText: response.errorDescription,
                    });
                }
                this.hideGifStatusModal();
            })
            .catch((err) => {
                this.setState({
                    failureModalDisplay: true,
                    failureModalText: errorDescription(error.unexpectedError),
                });
            });
    };

    redirectToGifsRules = () => {
        let var1 = window.location.href.split("/")[0]
        let var2 = window.location.href.split("/")[2]
        let gifIds = this.state.selectedGifs.map(gif => gif.id).join(",")
        window.location.href = `${var1}//${var2}/gifs/targetingRules?gifIds=${gifIds}`
    }

    render() {
        return (
            <Container fluid className="p-0">
                {this.state.hasResponseError ? (
                    <ResponseModal
                        show={this.state.hasResponseError}
                        onHide={() => {
                            this.setState({ hasResponseError: false });
                        }}
                        modalheading={"Error"}
                        modaltext={this.state.failureModalText}
                    />
                ) : (
                    <React.Fragment>
                        <h1 className="h3 mb-3">{this.state.gifPack.name}</h1>
                        <Row>
                            <Col md="9" xl="10">
                                <Card>
                                    <React.Fragment>
                                        <CardImg
                                            src={this.state.gifPack.bannerImageURL}
                                        ></CardImg>
                                    </React.Fragment>
                                    <CardBody className="d-md-flex">
                                        <Container>
                                            <Row>
                                                <Col md="8">
                                                    <h3>Description</h3>
                                                    <p>{this.state.gifPack.description}</p>
                                                </Col>
                                                <Col md="4">
                                                    {
                                                        this.state.loadingGifs
                                                            ? <Spinner animation="border" role="status" className="d-block ml-auto mt-2"></Spinner>
                                                            :
                                                            (
                                                                <Button color="secondary"
                                                                    className="d-block ml-auto mt-2"
                                                                    onClick={async () => {
                                                                        await this.fetchAllGifPackGifs("Published", true);
                                                                        this.displayRearrangeGifModal();
                                                                    }}
                                                                >
                                                                    Rearrange Gifs
                                                                </Button>
                                                            )
                                                    }
                                                </Col>
                                            </Row>
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h5>Active Head Supported Gifs:</h5>
                                                    <p>{this.state.gifPack.activeHeadSupportedGifs}</p>
                                                </div>
                                            </div>
                                            {this.state.gifPack.activeHeadSupportedGifs > 0 &&
                                                !this.state.gifPack.isHeadSupported && (
                                                    <Row>
                                                        <Col className="pb-2">
                                                            <Alert className="opacity-3" variant="warning">
                                                                <span className="p-2">Note: The pack contains head supported gif. Please edit the pack and enable Is Head Supported toggle.</span>
                                                            </Alert>
                                                        </Col>
                                                    </Row>
                                                )}
                                            {this.state.gifPack.activeHeadSupportedGifs == 0
                                                && this.state.gifPack.isHeadSupported && (
                                                    <Row>
                                                        <Col className="pb-2">

                                                            <Alert className="opacity-3" variant="warning">
                                                                <span className="p-2">Note: The pack does not contain head supported gifs. Please edit the pack and disable Is Head Supported toggle.</span>
                                                            </Alert>
                                                        </Col>
                                                    </Row>
                                                )}
                                        </Container>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <Row className="px-3">
                                            <Col md={8} sm={6}>
                                                <h3>Targeting Rules</h3>
                                                <p>Age-Gender, Clients and Locations based targeting for this gif pack</p>
                                            </Col>
                                            {
                                                this.showEditRulesButton() ?
                                                    <Col md={4} sm={6}>
                                                        <Button color="primary"
                                                            className="d-block ml-auto mt-2"
                                                            onClick={this.redirectToLocationRule}
                                                        >
                                                            Edit Rules
                                                        </Button>
                                                    </Col> : null
                                            }
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <Row className="px-3">
                                            <Col md={8} sm={6}>
                                                <h3>Autodownload Targeting Rules</h3>
                                                <p>Age-Gender, Clients, Locations based targeting for which this gif pack gets auto downloaded</p>
                                            </Col>
                                            {
                                                this.showEditRulesAutoDownloadButton() ?
                                                    <Col md={4} sm={6}>
                                                        <Button color="primary"
                                                            className="d-block ml-auto mt-2"
                                                            onClick={this.redirectToAutoDownloadLocationRules}
                                                        >
                                                            Edit Rules
                                                        </Button>
                                                    </Col> : null
                                            }
                                        </Row>
                                    </CardBody>
                                </Card>

                                <Card className="mb-4">
                                    <CardHeader>
                                        <CardTitle className="mb-0">
                                        <Row>
                                            <Col className="pt-2">
                                            <h4 className="mb-0 p-0 collapseHeading">
                                                Bulk Actions on Gifs
                                            </h4>
                                            </Col>
                                            <Col>
                                            {this.state.isBulkActionCollapseOpen ? null : (
                                                <Button
                                                color="primary"
                                                className="d-block float-right px-3"
                                                onClick={this.toggleBulkActionCollapse}
                                                disabled={this.isAddButtonDisabled()}
                                                >
                                                ACTION
                                                </Button>
                                            )}
                                            </Col>
                                        </Row>
                                        </CardTitle>
                                    </CardHeader>
                                    </Card>

                                    <Collapse isOpen={this.state.isBulkActionCollapseOpen}>
                                        <Card className="dropdownBackground">
                                            <CardBody>
                                            <Row>
                                                <Col md={12}>
                                                <Row className="mt-3 mb-3">
                                                    {this.showEditRulesButton() &&
                                                    <Button
                                                        className="ml-3 btn-success px-3"
                                                        onClick={() => this.updateGifPackGifsDateTime()}
                                                        disabled={this.state.selectedGifs.length === 0}
                                                    >
                                                        Edit Date Time Range
                                                    </Button>
                                                    }
                                                    <Button
                                                        className="ml-3 btn-danger px-3"
                                                        onClick={this.closeBulkActionCollapse}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Row>
                                                {/* Add start date and end date fields */}
                                                <Row className="mt-3 mb-3">
                                                    <Col md={6}>
                                                    <Label>Start At</Label>
                                                    <Input
                                                        type="datetime-local"
                                                        className="form-control"
                                                        placeholder="Start Date"
                                                        value={this.state.startAt}
                                                        onChange={(e) => this.setState({ startAt: e.target.value })}
                                                    />
                                                    </Col>
                                                    <Col md={6}>
                                                    <Label>End At</Label>
                                                    <Input
                                                        type="datetime-local"
                                                        className="form-control"
                                                        placeholder="End Date"
                                                        value={this.state.endAt}
                                                        onChange={(e) => this.setState({ endAt: e.target.value })}
                                                    />
                                                    </Col>
                                                </Row>
                                                <small className="mt-4">
                                                    {this.state.selectedGifs.length} Gif(s) selected
                                                </small>
                                                </Col>

                                            </Row>
                                        </CardBody>
                                    </Card>
                                    </Collapse>
                            </Col>
                        </Row>

                        <Row className="align-items-center">
                            <Col md={9} sm={6}>
                                <h1 className="h3 mb-3 mt-4">Gifs</h1>
                            </Col>
                            <Col md={3} sm={6}>
                                <UncontrolledButtonDropdown className="my-auto float-right">
                                    <DropdownToggle
                                        disabled={this.state.selectedGifs.length == 0}
                                        caret color="secondary">
                                        {"Bulk Operations"}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem
                                            onClick={() => {
                                                this.displayImportGifsModal();
                                            }}>Import Gifs</DropdownItem>
                                        <DropdownItem
                                            onClick={() => {
                                                this.redirectToGifsRules();
                                            }}>Update Targeting Rules</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </Col>
                        </Row>
                        {this.state.loading ? (
                            <Loading />
                        ) : (
                            <Fragment>
                                <Row>
                                    <Col md={3} sm={12}>
                                        <Search
                                            placeholder="Search"
                                            value={this.state.searchString}
                                            onChange={this.handleSearchStringChange}
                                            onKeyDown={this.onKeyDown}
                                        ></Search>
                                    </Col>
                                    <Col md={3} sm={12}>
                                        <UncontrolledButtonDropdown className="mr-1 mb-1">
                                            <DropdownToggle caret color="success">
                                                {"Search By " + capitalize(this.state.searchBy) + " "}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => { this.search("id") }}>ID</DropdownItem>
                                                <DropdownItem onClick={() => { this.search("tag") }}>Tag</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3} sm={12}>
                                        <Select
                                            className="react-select-container mb-4"
                                            classNamePrefix="react-select"
                                            placeholder="Select Status"
                                            onChange={this.handleFilterChange}
                                            options={[
                                                { value: "All", label: "All" },
                                                { value: "Published", label: "Active" },
                                                { value: "Deactivated", label: "Deactivated" },
                                                { value: "Unpublished", label: "Unpublished" },
                                                { value: "ActivatedGlobally", label: "Active Globally" },
                                                { value: "DeactivatedGlobally", label: "Deactivated Globally" },
                                            ]}
                                        />
                                    </Col>
                                    <Col md={3} sm={12}>
                                        <Select
                                            className="react-select-container mb-4"
                                            classNamePrefix="react-select"
                                            placeholder="Sort By"
                                            onChange={this.handleOrderChange}
                                            options={[
                                                { value: Config.UPDATED_AT_DESCENDING, label: "Updated At \u2193" },
                                                { value: Config.UPDATED_AT_ASCENDING, label: "Updated At \u2191" },
                                            ]}
                                        />
                                    </Col>
                                </Row>
                                {/* Date time filter */}
                                <Row>
                                <Col md={12} sm={12}>
                                    <div className="d-flex align-items-center">
                                        <Label className="font-weight-bold mr-2" style={{ fontSize: 15 }}>
                                        Date Time
                                        </Label>
                                        <Alert className="opacity-3" variant="warning">
                                        <span className="p-2">Filter that returns gifs available on a given date by checking if their “Start At” date is before or on the date, and their “End At” date is after or on the date.</span>
                                        </Alert>
                                    </div>
                                    </Col>
                                    </Row>
                                    <Row>
                                    <Col md={3} sm={12}>
                                        <Input
                                            type="datetime-local"
                                            className="form-control"
                                            placeholder="Start At"
                                            value={this.state.filterDateTime}
                                            onChange={(e) => {
                                            this.setState({ filterDateTime: e.target.value }, () => {
                                                this.handleFilterChange(this.state.filterStatus);
                                            });                          
                                            }}
                                        />
                                    </Col>
                                </Row>
                                {/* Component to displays all the Gifs */}
                                <InfiniteScroll
                                    style={{ overflow: "auto !important" }}
                                    className="infinite-scroll"
                                    dataLength={this.state.filteredGifs.length}
                                    next={this.fetchMoreData}
                                    hasMore={this.state.hasMoreGifs}
                                    loader={
                                        this.state.fetchFailed ? (
                                            false
                                        ) : (
                                            <Spinner color="info" className="d-flex mx-auto" />
                                        )
                                    }
                                >
                                    <Row className="mt-4 mx-0">
                                        {this.state.filteredGifs.length === 0 ? (
                                            <Container className="mt-5">
                                                <h3 className="text-center">No Gif available.</h3>
                                                <h4 className="text-center">
                                                    Enter a valid search tag to view gifs.
                                                </h4>
                                            </Container>
                                        ) : (
                                            this.state.filteredGifs.map((gif, index) => {
                                                let status = getStatus(gif.publishedAt, gif.deactivatedAt);
                                                let globalStatus = getStatus(gif.publishedAtGlobal, gif.deactivatedAtGlobal);
                                                let color = getStatusColor(status);
                                                let globalColor = getStatusColor(globalStatus);
                                                return (
                                                    <Col md="6" lg="4" key={gif.id}>
                                                        <Gif
                                                            id={gif.id}
                                                            packId={this.state.id}
                                                            createdAt={gif.createdAt}
                                                            publishedAt={gif.publishedAt}
                                                            startAt={gif.startAt}
                                                            endAt={gif.endAt}                              
                                                            deactivatedAt={gif.deactivatedAt}
                                                            image={gif.imageURL}
                                                            color={color}
                                                            globalColor={globalColor}
                                                            status={status}
                                                            globalStatus={globalStatus}
                                                            imageProcessingStatus={gif.imageProcessingStatus}
                                                            isHeadSupported={gif.isHeadSupported}
                                                            handleCheckboxChange={this.handleCheckboxChange}
                                                            gif={gif}
                                                            displayGifStatusModal={this.displayGifStatusModal}
                                                        />
                                                    </Col>
                                                );
                                            })
                                        )}
                                    </Row>
                                </InfiniteScroll>
                                <RearrangeGifs
                                    show={this.state.showRearrangeModal}
                                    onHide={this.hideRearrangeGifModal}
                                    gifs={this.state.gifsReordered}
                                    isLoading={this.state.loadingGifs}
                                    reorder={this.reorder}
                                />
                                <ImportGifs
                                    show={this.state.showImportModal}
                                    onHide={this.hideImportGifsModal}
                                    gifs={this.state.selectedGifs}
                                    gifPacks={this.state.gifPacks}
                                    import={this.import}
                                />
                                <GifStatus
                                    show={this.state.showStatusModal}
                                    onHide={this.hideGifStatusModal}
                                    gifs={this.state.selectedGifs}
                                    gif={this.state.selectedGif}
                                    gifPacks={this.state.gifPacks}
                                    updateGifStatus={this.updateGifStatus}
                                    action={this.state.statusModalAction}
                                />
                                {/* Modal component to display failure response */}
                                <ResponseModal
                                    show={this.state.failureModalDisplay}
                                    onHide={this.failureModalClose}
                                    modalheading={"Error"}
                                    modaltext={this.state.failureModalText}
                                />
                                <ResponseModal
                                    show={this.state.successModalDisplay}
                                    onHide={this.successModalClose}
                                    modalheading={"Success"}
                                    modaltext={this.state.successModalText}
                                />
                            </Fragment>
                        )}
                    </React.Fragment>
                )}
            </Container>
        );
    }
}

export default GifPack;

import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { errorDescription, error } from "../../../utilities/commonUtil";
import { getLanguages } from "../../../utilities/apiUtils/carousel";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Table,
} from "reactstrap";
import { addItemToCarousel } from "../../../utilities/apiUtils/carousel.js";

const AddItemsToCarouselForm = () => {
  const { id } = useParams();
  const history = useHistory();

  const [items, setItems] = useState([]);
  const [languagesOptions, setLanguagesOptions] = useState([]);
  const [showTrackers, setShowTrackers] = useState(false);
  const [showTranslations, setShowTranslations] = useState(false);
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });

  const [newItem, setNewItem] = useState({
    type: "",
    aspectRatio: 1,
    maxCount: 1,
    backgroundColor: "",
    trackers: [
      {
        type: "",
        url: "",
      },
    ],
    translations: [
      {
        languageCode: null,
        imageFilename: null,
        imageFile: null,
        darkThemeImageFilename: null,
        darkThemeImageFile: null,
      },
    ],
    deeplink: {
      app: "",
      keyboard: "",
      hamburgerMenu: "",
      settings: "",
    },
    imageFile: "",
    darkThemeImageFile: "",
  });

  const getLanguagesOptions = async () => {
    try {
      let response = await getLanguages();
      // remove All if present
      response = response?.filter((item) => item?.value !== "All");
      setLanguagesOptions(response);
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  // Truncate long strings to a specified length
  const truncateString = (str, maxLength = 50) => {
    return str.length > maxLength ? str.substring(0, maxLength) + "..." : str;
  };

  // Handle image file input change
  const handleImageFileChange = (e, isDarkTheme) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setNewItem({
          ...newItem,
          [isDarkTheme ? "darkThemeImageFile" : "imageFile"]:
            event.target.result,
        });
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Convert maxCount to a number if it is the maxCount field
    const updatedValue = name === "maxCount" ? parseInt(value, 10) : value;

    setNewItem({
      ...newItem,
      [name]: updatedValue,
    });
  };

  const handleTrackerChange = (index, field, value) => {
    const updatedTrackers = [...newItem.trackers];
    updatedTrackers[index][field] = value;

    setNewItem({
      ...newItem,
      trackers: updatedTrackers,
    });
  };
  const addTracker = () => {
    setNewItem({
      ...newItem,
      trackers: [
        ...newItem.trackers,
        {
          type: "",
          url: "",
        },
      ],
    });
    setShowTrackers(true); // Set the state to true to show the trackers section
  };

  const handleTrackerTypeChange = (index, value) => {
    handleTrackerChange(index, "type", value);
  };

  const removeTracker = (index) => {
    const updatedTrackers = [...newItem.trackers];
    updatedTrackers.splice(index, 1);
    setNewItem({
      ...newItem,
      trackers: updatedTrackers,
    });
  };

  const removeTranslation = (index) => {
    const updatedTranslations = [...newItem.translations];
    updatedTranslations.splice(index, 1);
    setNewItem({
      ...newItem,
      translations: updatedTranslations,
    });
  };

  const handleTranslationChange = (index, field, value) => {
    const updatedTranslations = [...newItem.translations];
    updatedTranslations[index][field] = value;

    setNewItem({
      ...newItem,
      translations: updatedTranslations,
    });
  };
  const handleTrackerImageFileChange = (index, field, file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      handleTranslationChange(index, field, event.target.result);
    };
    reader.readAsDataURL(file);
  };

  const addTranslation = () => {
    setNewItem({
      ...newItem,
      translations: [
        ...newItem.translations,
        {
          languageCode: null,
          imageFilename: null,
          imageFile: null,
          darkThemeImageFilename: null,
          darkThemeImageFile: null,
        },
      ],
    });
    setShowTranslations(true);
  };

  const typeOptions = [
    { value: "generic", label: "Generic" },
    { value: "grant_permission", label: "Grant Permission" },
    { value: "contact", label: "Contact" },
  ];

  const handleTypeChange = (selectedOption) => {
    setNewItem({ ...newItem, type: selectedOption.value });
  };

  const handleDeeplinkChange = (type, value) => {
    setNewItem({
      ...newItem,
      deeplink: {
        ...newItem.deeplink,
        [type]: value,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newItemData = {
      type: newItem.type,
      aspectRatio: parseInt(newItem.aspectRatio),
      maxCount: newItem.maxCount,
      backgroundColor: newItem.backgroundColor,
      deeplink: newItem.deeplink,
      imageFilename: "",
      imageFile: newItem.imageFile,
      darkThemeImageFilename: "",
      darkThemeImageFile: newItem.darkThemeImageFile,
    };

    const filteredTrackers = newItem.trackers.filter(
      (tracker) => tracker.type.trim() !== "" || tracker.url.trim() !== ""
    );

    const filteredTranslations = newItem.translations.filter(
      (translation) =>
        translation.languageCode !== null ||
        translation.imageFilename !== null ||
        translation.imageFile !== null ||
        translation.darkThemeImageFilename !== null ||
        translation.darkThemeImageFile !== null
    );

    if (filteredTrackers.length > 0) {
      newItemData.trackers = filteredTrackers;
    } else {
      delete newItemData.trackers;
    }

    if (filteredTranslations.length > 0) {
      newItemData.translations = filteredTranslations;
    } else {
      delete newItemData.translations;
    }
    try {
      const success = await addItemToCarousel(id, newItemData);
      console.log(newItem.deeplink);
      if (success) {
        setItems([...items, newItem]);
        setNewItem({
          type: null,
          aspectRatio: 1,
          maxCount: 1,
          backgroundColor: "",
          trackers: [
            {
              type: "",
              url: "",
            },
          ],
          translations: [
            {
              languageCode: null,
              imageFilename: null,
              imageFile: null,
              darkThemeImageFilename: null,
              darkThemeImageFile: null,
            },
          ],
          deeplink: {
            app: "",
            keyboard: "",
          },
          darkThemeImageFilename: "",
          imageFilename: "",
          imageFile: "",
          darkThemeImageFile: "",
        });

        console.log("Item added successfully");
      } else {
        console.error("Failed to add the item to the carousel");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleTargettingRules = () => {
    history.push(`/cre/carousel-rules/${id}`);
  };

  useEffect(() => {
    getLanguagesOptions();
  }, []);
  return (
    <Container className="carousel-form-container my-5">
      <Row className="justify-content-between align-items-center mb-4">
        <Col md={6}>
          <h1 className="carousel-form-title mb-0">Add Item to Carousel</h1>
        </Col>
        <Col md={6} className="text-right">
          <Button
            color="primary"
            className="ml-2"
            onClick={handleTargettingRules}
          >
            Edit Targeting Rules
          </Button>
        </Col>
      </Row>
      <Card className="carousel-form-card">
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="type">Type</Label>
                  <Select
                    name="type"
                    id="type"
                    className="carousel-form-input"
                    value={typeOptions.find(
                      (option) => option.value === newItem.type
                    )}
                    onChange={handleTypeChange}
                    options={typeOptions}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="aspectRatio">Aspect Ratio</Label>
                  <Input
                    type="number"
                    name="aspectRatio"
                    value={newItem.aspectRatio}
                    onChange={(e) =>
                      setNewItem({ ...newItem, aspectRatio: e.target.value })
                    }
                    className="carousel-form-input"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="maxCount">Max Count</Label>
                  <Input
                    type="number"
                    name="maxCount"
                    value={newItem.maxCount}
                    onChange={handleChange}
                    className="carousel-form-input"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="lightImageFile">Light Image File</Label>{" "}
                  <span className="text-danger">*</span>
                  <Input
                    type="file"
                    name="lightImageFile"
                    id="lightImageFile"
                    onChange={(e) => handleImageFileChange(e, false)}
                    className="carousel-form-input"
                    required
                  />
                  {newItem.imageFile && (
                    <div className="carousel-form-image-preview mt-3">
                      <img
                        src={newItem.imageFile}
                        alt="Selected File"
                        className="carousel-form-preview-image img-thumbnail"
                        style={{ maxWidth: "150px" }}
                      />
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label for="appDeeplink">App Deeplink</Label>{" "}
                  <span className="text-danger">*</span>
                  <Input
                    type="text"
                    name="appDeeplink"
                    value={newItem.deeplink.app}
                    onChange={(e) =>
                      handleDeeplinkChange("app", e.target.value)
                    }
                    className="carousel-form-input"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="keyboardDeeplink">Keyboard Deeplink</Label>{" "}
                  <span className="text-danger">*</span>
                  <Input
                    type="text"
                    name="keyboardDeeplink"
                    value={newItem.deeplink.keyboard}
                    onChange={(e) =>
                      handleDeeplinkChange("keyboard", e.target.value)
                    }
                    className="carousel-form-input"
                  />
                </FormGroup>
                <FormGroup>
                  <Label
                    className="font-weight-bolder mb-2"
                    style={{ fontSize: "16px" }}
                  >
                    Background Color (Hex):{" "}
                    <span className="text-danger">*</span>{" "}
                    <div
                      style={{
                        backgroundColor: newItem.backgroundColor,
                        width: "16px",
                        height: "16px",
                        display: "inline-block",
                        verticalAlign: "middle",
                        border: "1px solid #000",
                        borderRadius: "30%",
                      }}
                    >
                      {" "}
                    </div>
                  </Label>
                  <Input
                    type="text"
                    name="backgroundColor"
                    id="backgroundColor"
                    value={newItem.backgroundColor}
                    onChange={handleChange}
                    required
                    className="carousel-form-input"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="darkImageFile">Dark Image File</Label>{" "}
                  <span className="text-danger">*</span>
                  <Input
                    type="file"
                    name="darkImageFile"
                    id="darkImageFile"
                    onChange={(e) => handleImageFileChange(e, true)}
                    className="carousel-form-input"
                    required
                  />
                  {newItem.darkThemeImageFile && (
                    <div className="carousel-form-image-preview mt-3">
                      <img
                        src={newItem.darkThemeImageFile}
                        alt="Selected File"
                        className="carousel-form-preview-image img-thumbnail"
                        style={{ maxWidth: "150px" }}
                      />
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <h3>Trackers</h3>
            <Card>
              <CardBody>
                {showTrackers &&
                  newItem.trackers.map((tracker, index) => (
                    <Row key={index} form>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Tracker Type</Label>
                          <Input
                            type="select"
                            value={tracker.type}
                            onChange={(e) =>
                              handleTrackerTypeChange(index, e.target.value)
                            }
                          >
                            <option value="">Select Tracker Type</option>
                            <option value="click">Click</option>
                            <option value="impression">Impression</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Tracker URL</Label>
                          <Input
                            type="text"
                            placeholder="Tracker URL"
                            value={tracker.url}
                            onChange={(e) =>
                              handleTrackerChange(index, "url", e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md={12} className="text-right">
                        <Button
                          color="danger"
                          onClick={() => removeTracker(index)}
                        >
                          Remove Tracker
                        </Button>
                      </Col>
                    </Row>
                  ))}
                <Button color="secondary" onClick={addTracker}>
                  Add Tracker
                </Button>
              </CardBody>
            </Card>

            <Card className="mb-4">
              <h3>Translations</h3>
              <CardBody>
                {showTranslations &&
                  [...Array(newItem.translations.length)].map((_, index) => (
                    <Row key={index} form>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Language Code</Label>{" "}
                          <span className="text-danger">*</span>{" "}
                          <Select
                            options={languagesOptions} // Pass your language options array here
                            value={
                              languagesOptions.find(
                                (option) =>
                                  option.value ===
                                  newItem.translations[index].languageCode
                              ) || null
                            }
                            required
                            onChange={(selectedOption) =>
                              handleTranslationChange(
                                index,
                                "languageCode",
                                selectedOption.value
                              )
                            }
                            placeholder="Select Language Code"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Image File</Label>
                          <Input
                            type="file"
                            onChange={(e) =>
                              handleTrackerImageFileChange(
                                index,
                                "imageFile",
                                e.target.files[0]
                              )
                            }
                          />
                          {/* Display selected file */}
                          {newItem.translations[index].imageFile && (
                            <div className="carousel-form-image-preview mt-3">
                              <img
                                src={newItem.translations[index].imageFile}
                                alt="Selected File"
                                className="carousel-form-preview-image img-thumbnail"
                                style={{ maxWidth: "150px" }}
                              />
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Dark Theme Image File</Label>
                          <Input
                            type="file"
                            onChange={(e) =>
                              handleTrackerImageFileChange(
                                index,
                                "darkThemeImageFile",
                                e.target.files[0]
                              )
                            }
                          />
                          {/* Display selected file */}
                          {newItem.translations[index].darkThemeImageFile && (
                            <div className="carousel-form-image-preview mt-3">
                              <img
                                src={
                                  newItem.translations[index].darkThemeImageFile
                                }
                                alt="Selected File"
                                className="carousel-form-preview-image img-thumbnail"
                                style={{ maxWidth: "150px" }}
                              />
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={12} className="text-right">
                        <Button
                          color="danger"
                          onClick={() => removeTranslation(index)}
                        >
                          Remove Translation
                        </Button>
                      </Col>
                    </Row>
                  ))}
                <Button color="secondary" onClick={addTranslation}>
                  Add Translation
                </Button>
              </CardBody>
            </Card>
            <Button
              color="primary"
              type="submit"
              className="carousel-form-button"
            >
              Add Item to Carousel
            </Button>
          </Form>

          {items.length > 0 && (
            <Card className="mt-5">
              <CardBody>
                <h2>Added Items</h2>
                <Table>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Aspect Ratio</th>
                      <th>Max Count</th>
                      <th>Background Color</th>
                      <th>App Deeplink</th>
                      <th>Keyboard Deeplink</th>
                      <th>Hamburger Deeplink</th>
                      <th>Settings Deeplink</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, index) => (
                      <tr key={index}>
                        <td>{item.type}</td>
                        <td>{item.aspectRatio}</td>
                        <td>{item.maxCount}</td>
                        <td>{item.backgroundColor}</td>
                        <td>{truncateString(item.deeplink.app)}</td>
                        <td>{truncateString(item.deeplink.keyboard)}</td>
                        <td>{truncateString(item.deeplink.hamburgerMenu)}</td>
                        <td>{truncateString(item.deeplink.settings)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default AddItemsToCarouselForm;

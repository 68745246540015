import React, { useState } from "react";
import {
    Col,
    Row,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Container,
    Button,
} from "reactstrap";

import { trackersTypeOptions } from "../../../../config/quickReplies";
import { X } from "react-feather";
import Select from "react-select";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";


const QuickReplyTypeImage = ({
    quickReplyTypeImage,
    setQuickReplyTypeImage,
    formName,
    updateAllowed
}) => {

    const [imagePreview, setImagePreview] = useState(null);

    const addClickTracker = () => {
        setQuickReplyTypeImage({
            ...quickReplyTypeImage,
            clickTrackers: [...quickReplyTypeImage.clickTrackers, {
                type: { value: "direct_url", label: "Direct URL" },
                url: null,
            }]
        });
    }

    const addImpressionTracker = () => {
        setQuickReplyTypeImage({
            ...quickReplyTypeImage,
            impressionTrackers: [...quickReplyTypeImage.impressionTrackers, {
                type: { value: "direct_url", label: "Direct URL" },
                url: null,
            }]
        });
    }

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setImagePreview(reader.result);
            setQuickReplyTypeImage({
                ...quickReplyTypeImage,
                image: reader.result,
                imageFilename: file.name
            });
        }

        reader.readAsDataURL(file);
    }

    const allowEdit = () => {
        return formName === "edit" && !updateAllowed;
    }

    return (
        <Container fluid className="p-0">
            <Card>
                <CardBody>
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <Label>
                                    <strong>
                                        Select an Image <span className="text-danger">*</span>
                                    </strong>
                                    <p style={{ fontSize: 12, marginBottom: 0 }}>
                                        The image should be jpg/png/gif/webp
                                    </p>
                                </Label>

                                <br />

                                <Container className="custom-file">
                                    <Input
                                        type="file"
                                        className="custom-file-input"
                                        accept="image/*"
                                        onChange={handleImageUpload}
                                        required={formName !== "edit"}
                                        disabled={allowEdit()}
                                    />

                                    <Label className="custom-file-label" id="image">
                                        <strong>{quickReplyTypeImage.imageFilename === null ? "Choose an image" : quickReplyTypeImage.imageFilename}</strong>
                                    </Label>
                                </Container>

                                {(imagePreview || quickReplyTypeImage.imageURL) && (
                                    <Col className="pb-4 mb-3 mt-3">
                                        <img src={imagePreview || quickReplyTypeImage.imageURL} alt="preview" width="100" />
                                    </Col>

                                )}

                            </FormGroup>
                        </Col>

                        <Col md={6}>
                            <FormGroup>
                                <Label>
                                    <strong>Priority</strong>
                                    <p style={{ fontSize: 12, marginBottom: 0 }}>
                                        Enter integer value greater than 0
                                    </p>
                                </Label>

                                <Input
                                    type="number"
                                    min={1}
                                    name="priority"
                                    value={quickReplyTypeImage.priority}
                                    onChange={(e) =>
                                        setQuickReplyTypeImage({
                                            ...quickReplyTypeImage,
                                            priority: e.target.value
                                        })
                                    }
                                    disabled={allowEdit()}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            {/* ClickTrackers */}
            <Row>
                <Col md={6}>
                    <h1 className="h3 mb-3">Click Trackers</h1>
                </Col>
                <Col md={6}>
                    <Button className="float-right" onClick={addClickTracker} disabled={allowEdit()}>
                        <FontAwesomeIcon size="sm" icon={faPlus}></FontAwesomeIcon>
                        {" "}Add Click Tracker
                    </Button>
                </Col>
            </Row>

            <Card>
                <CardBody>
                    {quickReplyTypeImage?.clickTrackers?.map((item, index) => (
                        <Card key={`card-${index + 6}`} className="p-3 mb-5" style={{
                            boxShadow: '0 0 20px rgba(0,0,0,0.4)',  // Increased shadow
                            borderRadius: '15px'
                        }}>

                            <Row key={`row-${index + 6}`}>
                                <Col>
                                    <FormGroup>
                                        <Label>
                                            <strong>
                                                Type
                                            </strong>
                                        </Label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            name="type"
                                            value={item.type}
                                            placeholder="Select type"
                                            isClearable={false}

                                            options={trackersTypeOptions}
                                            onChange={(option) => {
                                                let temp = [...quickReplyTypeImage.clickTrackers];
                                                temp[index].type = option;
                                                setQuickReplyTypeImage({
                                                    ...quickReplyTypeImage,
                                                    clickTrackers: temp,
                                                });
                                            }}
                                            isDisabled={allowEdit()}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    {!(allowEdit()) && (
                                        <X
                                            className="float-right"
                                            size={20}
                                            color="red"
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                                let temp = [...quickReplyTypeImage.clickTrackers];
                                                temp = temp.filter((_, idx) => idx !== index);
                                                setQuickReplyTypeImage({
                                                    ...quickReplyTypeImage,
                                                    clickTrackers: temp,
                                                });
                                            }}
                                        ></X>
                                    )}
                                </Col>
                            </Row>
                            <Row key={`row-${index + 7}`}>
                                <Col>
                                    <FormGroup>
                                        <Label>
                                            <strong>
                                                URL <span class="text-danger">*</span>
                                            </strong>
                                        </Label>
                                        <Input
                                            type="textarea"
                                            name="url"
                                            value={item.url}
                                            placeholder="Enter URL"
                                            onChange={(option) => {
                                                let temp = [...quickReplyTypeImage.clickTrackers];
                                                temp[index].url = option.target.value;
                                                setQuickReplyTypeImage({
                                                    ...quickReplyTypeImage,
                                                    clickTrackers: temp,
                                                });
                                            }}
                                            required
                                            disabled={allowEdit()}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Card>
                    ))}
                </CardBody>
            </Card>

            {/* ImpressionTrackers */}
            <Row>
                <Col md={6}>
                    <h1 className="h3 mb-3">Impression Trackers</h1>
                </Col>
                <Col md={6}>
                    <Button className="float-right" onClick={addImpressionTracker} disabled={allowEdit()}>
                        <FontAwesomeIcon size="sm" icon={faPlus}></FontAwesomeIcon>
                        {" "}Add Impression Tracker
                    </Button>
                </Col>
            </Row>

            <Card>
                <CardBody>
                    {quickReplyTypeImage?.impressionTrackers?.map((item, index) => (
                        <Card key={`card-${index + 6}`} className="p-3 mb-5" style={{
                            boxShadow: '0 0 20px rgba(0,0,0,0.4)',  // Increased shadow
                            borderRadius: '15px'
                        }}>
                            <Row key={`row-${index + 6}`}>
                                <Col>
                                    <FormGroup>
                                        <Label>
                                            <strong>
                                                Type
                                            </strong>
                                        </Label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            name="type"
                                            value={item.type}
                                            placeholder="Select type"
                                            isClearable={false}

                                            options={trackersTypeOptions}
                                            onChange={(option) => {
                                                let temp = [...quickReplyTypeImage.impressionTrackers];
                                                temp[index].type = option;
                                                setQuickReplyTypeImage({
                                                    ...quickReplyTypeImage,
                                                    impressionTrackers: temp,
                                                });
                                            }}
                                            isDisabled={allowEdit()}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    {!(allowEdit()) && (
                                        <X
                                            className="float-right"
                                            size={20}
                                            color="red"
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                                let temp = [...quickReplyTypeImage.impressionTrackers];
                                                temp = temp.filter((_, idx) => idx !== index);
                                                setQuickReplyTypeImage({
                                                    ...quickReplyTypeImage,
                                                    impressionTrackers: temp,
                                                });
                                            }}
                                        ></X>
                                    )}
                                </Col>
                            </Row>
                            <Row key={`row-${index + 7}`}>
                                <Col>
                                    <FormGroup>
                                        <Label>
                                            <strong>
                                                URL <span class="text-danger">*</span>
                                            </strong>
                                        </Label>
                                        <Input
                                            type="textarea"
                                            name="url"
                                            value={item.url}
                                            placeholder="Enter URL"
                                            onChange={(option) => {
                                                let temp = [...quickReplyTypeImage.impressionTrackers];
                                                temp[index].url = option.target.value;
                                                setQuickReplyTypeImage({
                                                    ...quickReplyTypeImage,
                                                    impressionTrackers: temp,
                                                });
                                            }}
                                            required
                                            disabled={allowEdit()}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Card>
                    ))}
                </CardBody>
            </Card>


        </Container>
    );
}

export default QuickReplyTypeImage;
import React, { useState, useEffect, Fragment } from "react"
import {
    Container
} from "reactstrap"
import {
    error,
    redirectOnTokenExpiry,
    errorDescription,
} from "../../../utilities/commonUtil"
import Loading from "../../../components/Loading";
import { emojiStickerImageDetails } from "../../../utilities/apiUtils/emojiStickers"
import EmojiStickerImage from "./components/EmojiStickerImageForm"

const UpdateEmojiStickerImage = (props) => {

    const [loading, setLoading] = useState(true)
    const [emojiImageDetails, setEmojiImageDetails] = useState({})
    const [hasResponseError, setHasResponseError] = useState(true)
    const [errorText, setErrorText] = useState("")
    const [failureModalDisplay, setFailureModalDisplay] = useState(false)
    const [failureModalText, setFailureModalText] = useState("")

    useEffect(() => {
        const fetchEmojiImageDetails = async (emojiID, emojiImageID) => {
            setLoading(true)
            try {
                let res = await emojiStickerImageDetails(emojiID, emojiImageID)
                if (res) {
                    setHasResponseError(false)
                    setEmojiImageDetails(res.emojiStickerImage)
                    setLoading(false);
                } else {
                    if (res.errorCode === error.tokenExpired || res.errorCode === error.invalidAccessToken)
                        redirectOnTokenExpiry();
                    setErrorText(errorDescription(res.errorCode))
                    setHasResponseError(true)
                    setLoading(false)
                }
                setLoading(false)
            } catch (err) {
                setLoading(false)
                setFailureModalText(errorDescription(error.unexpectedError))
                setFailureModalDisplay(true)
            }
        }
        fetchEmojiImageDetails(props.match.params.emojiID, props.match.params.emojiImageID)
    }, [props])

    return (
        <Container fluid>
            {
                loading ? (<Loading />) :
                    (<Fragment>
                        <h3 className="h3">Update BigMoji Image</h3><br />
                        <EmojiStickerImage forCreate={false}
                            emojiID={props.match.params.emojiID ??  null}
                            emojiImageID={props.match.params.emojiImageID ??  null}
                            watermarkSupport={emojiImageDetails.watermarkSupport ?? null}
                            isComboEmojiSticker={emojiImageDetails.isComboEmojiSticker ?? null}
                            startDate={emojiImageDetails.startTime ?? null}
                            endDate={emojiImageDetails.endTime ?? null}
                            eventType={emojiImageDetails.eventType ??  null}
                            brandCampaignId={emojiImageDetails.brandCampaignId ?? null}
                            additionalDetails={emojiImageDetails.additionalDetails ?? null}
                            baseURL={emojiImageDetails.baseURL ?? null}
                            isSmartBigmoji={emojiImageDetails.isSmartBigmoji ?? null}
                            autoDownload = {emojiImageDetails.autoDownload ?? null}
                            />
                    </Fragment>)
            }
        </Container>
    )
}
export default UpdateEmojiStickerImage
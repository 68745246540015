import { retrieveFromLocalStorage } from "../localStorageUtil";
import { host } from "../apiUtils/index";

export let fetchKeyboardLanguageCountryCodes = async () => {
  let countryCodes = {};
  let url = `${host}/v4/internal/keyboardLanguages/countries`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    countryCodes = response.json();
  });

  return countryCodes;
};

export let createLanguage = async (newLanguage) => {
  let response = {};
  let url = `${host}/v4/internal/languages`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "POST",
    body: newLanguage,
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let updateLanguage = async (updatedLanguage, id) => {
  let response = {};
  let url = `${host}/v4/internal/languages/${id}`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "POST",
    body: updatedLanguage,
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let createKeyboardLayout = async (newKeyboardLayout) => {
  let response = {};
  let url = `${host}/v4/internal/keyboardLayouts`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "POST",
    body: newKeyboardLayout,
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let createABTest = async (abTest, lang) => {
  let resStatus = "";
  let url = `${host}/v4/internal/keyboardLanguageABTests?lang=${lang}`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "POST",
    body: abTest,
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    resStatus = response.json();
  });

  return resStatus;
};

export let publishABTest = async (keyboardLanguageABTestId, lang) => {
  let resStatus = "";
  let url = `${host}/v4/internal/keyboardLanguageABTests/${keyboardLanguageABTestId}/publish?lang=${lang}`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    resStatus = response.json();
  });

  return resStatus;
};

export let getABTests = async () => {
  let abTests = [];
  let url = `${host}/v4/internal/keyboardLanguageABTests`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      abTests = res;
    });
  return abTests;
};

export let getABTestDetail = async (keyboardLanguageABTestId) => {
  let abTestDetail = {};
  let url = `${host}/v4/internal/keyboardLanguageABTests/${keyboardLanguageABTestId}`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      abTestDetail = res;
    });
  return abTestDetail;
};

export let editABTest = async (keyboardLanguageABTestId, updatedABTest, lang) => {
  let resStatus = "";
  let url = `${host}/v4/internal/keyboardLanguageABTests/${keyboardLanguageABTestId}?lang=${lang}`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "PUT",
    body: JSON.stringify(updatedABTest),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    resStatus = response.json();
  });

  return resStatus;
};

export let completeABTest = async (keyboardLanguageABTestId, variant, lang) => {
  let resStatus = "";
  let url = `${host}/v4/internal/keyboardLanguageABTests/${keyboardLanguageABTestId}/complete?lang=${lang}`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "POST",
    body: variant,
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    resStatus = response.json();
  });

  return resStatus;
};

export let editResourceVariants = async (
  keyboardLanguageABTestId,
  variants
) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/keyboardLanguageABTests/${keyboardLanguageABTestId}/variants`;

  await fetch(url, {
    method: "PUT",
    body: JSON.stringify(variants),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let addResourceVariants = async (keyboardLanguageABTestId, variants, lang) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/keyboardLanguageABTests/${keyboardLanguageABTestId}/variants?lang=${lang}`;

  await fetch(url, {
    method: "POST",
    body: JSON.stringify(variants),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let fetchKeyboardFonts = async () => {
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/keyboardFonts`;

  let response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  return response.json();
};

export let fetchKeyboardFontDetails = async (id) => {
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/keyboardFonts/${id}`;

  let response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  return response.json();
};

export let fetchKeyboardLanguageRegionCodes = async () => {
  let token = retrieveFromLocalStorage("token");
  let regionCodes = {};
  let url = `${host}/v4/internal/keyboardLanguages/regions`;

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then(async (response) => {
    regionCodes = await response.json();
  });
  return groupRegionsByCountry(regionCodes["regions"]);
};

export let fetchKeyboardLanguageCities = async () => {
  let token = retrieveFromLocalStorage("token");
  let cityNames = {};
  let url = `${host}/v4/internal/keyboardLanguages/cities`;

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then(async (response) => {
    cityNames = await response.json();
  });

  return groupCities(cityNames["cities"]);
};

let groupRegionsByCountry = (list) => {
  let regions = {};
  if (list === undefined) {
    return regions;
  }
  for (let i = 0; i < list.length; i++) {
    const element = list[i];

    if (!regions[element["countryId"]]) {
      regions[element["countryId"]] = [];
    }
    if (element["regionCode"] !== "GLOBAL")
      regions[element["countryId"]] = [
        ...regions[element["countryId"]],
        element,
      ];
    else {
      regions[element["countryId"]] = [
        element,
        ...regions[element["countryId"]],
      ];
    }
  }
  return regions;
};

//groups the cities by region code and country code
let groupCities = (list) => {
  let cities = {};

  if (list === undefined) {
    return cities;
  }
  for (let i = 0; i < list.length; i++) {
    const element = list[i];
    if (
      !cities[element["countryId"].toString() + element["regionId"].toString()]
    ) {
      cities[element["countryId"].toString() + element["regionId"].toString()] =
        [];
    }
    if (element["cityName"] !== "GLOBAL")
      cities[element["countryId"].toString() + element["regionId"].toString()] =
        [
          ...cities[
            element["countryId"].toString() + element["regionId"].toString()
          ],
          element,
        ];
    else {
      cities[element["countryId"].toString() + element["regionId"].toString()] =
        [
          element,
          ...cities[
            element["countryId"].toString() + element["regionId"].toString()
          ],
        ];
    }
  }
  return cities;
};

export let createKeyboardLanguageRulesets = async (data, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardLanguages/${id}/rules`;
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ rulesets: data }),
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let createKeyboardLanguageLayoutRulesets = async (
  data,
  keyboardLanguageId,
  keyboardLanguageLayoutId
) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardLanguages/${keyboardLanguageId}/layouts/${keyboardLanguageLayoutId}/rules`;
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ rulesets: data }),
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let createKeyboardLanguage = async (newKeyboardLanguage) => {
  let response = {};
  let url = `${host}/v4/internal/keyboardLanguages`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "POST",
    body: newKeyboardLanguage,
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let updateKeyboardLanguage = async (keyboardLanguage, id) => {
  let response = {};
  let url = `${host}/v4/internal/keyboardLanguages/${id}`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "POST",
    body: keyboardLanguage,
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let fetchKeyboardLanguages = async () => {
  let languages = {};
  let url = `${host}/v4/internal/keyboardLanguages`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    languages = response.json();
  });

  return languages;
};

export let getKeyboardLanguage = async (id) => {
  let language = {};
  let url = `${host}/v4/internal/keyboardLanguages/${id}`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    language = response.json();
  });

  return language;
};

export let getKeyboardLanguageDetails = async (keyboardLanguageId) => {
  let keyboardLanguageDetails = {};
  let url = `${host}/v4/internal/keyboardLanguages/${keyboardLanguageId}`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    keyboardLanguageDetails = response.json();
  });

  return keyboardLanguageDetails;
};

export let fetchSuggestedKeyboardLanguages = async (countryCode = "") => {
  let res = {};
  let url = `${host}/v4/internal/keyboardLanguages/suggested?countryCode=${countryCode}`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    res = response.json();
  });

  return res;
};

export let updateSuggestedKeyboardLanguages = async (
  countryCode,
  suggestedKeyboardLanguages
) => {
  let response = {};
  let body = {
    countryCode: countryCode,
    suggestedKeyboardLanguages: suggestedKeyboardLanguages,
  };

  let url = `${host}/v4/internal/keyboardLanguages/suggested`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      response = res;
    });

  return response;
};

export let publishKeyboardLanguage = async (id) => {
  let response = {};
  let url = `${host}/v4/internal/keyboardLanguages/${id}/publish`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let activateKeyboardLanguage = async (id) => {
  let response = {};

  let url = `${host}/v4/internal/keyboardLanguages/${id}/activate`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let deactivateKeyboardLanguage = async (id) => {
  let response = {};

  let url = `${host}/v4/internal/keyboardLanguages/${id}/deactivate`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let getAllKeyboardLanguageLayouts = async () => {
  let response = {};
  let url = `${host}/v4/internal/keyboardLanguageLayouts`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    response = res.json();
  });

  return response;
};

export let fetchSuggestedKeyboardLanguageLayouts = async (countryCode = "") => {
  let res = {};
  let url = `${host}/v4/internal/keyboardLanguageLayouts/suggested?countryCode=${countryCode}`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    res = response.json();
  });

  return res;
};

export let updateSuggestedKeyboardLanguageLayouts = async (
  countryCode,
  suggestedKeyboardLanguageLayouts
) => {
  let response = {};
  let body = {
    countryCode: countryCode,
    suggestedKeyboardLanguageLayouts: suggestedKeyboardLanguageLayouts,
  };

  let url = `${host}/v4/internal/keyboardLanguageLayouts/suggested`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      response = res;
    });

  return response;
};

export let uploadLayoutForKeyboardLanguage = async (layout, id) => {
  let response = {};
  let url = `${host}/v4/internal/keyboardLanguages/${id}/layout`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "POST",
    body: layout,
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let updateLayoutForKeyboardLanguage = async (
  layout,
  id,
  keyboardLanguageLayoutId
) => {
  let response = {};
  let url = `${host}/v4/internal/keyboardLanguages/${id}/layout/${keyboardLanguageLayoutId}`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "POST",
    body: layout,
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let getKeyboardLayouts = async () => {
  let layouts = {};
  let url = `${host}/v4/internal/keyboardLayouts`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    layouts = res.json();
  });

  return layouts;
};

export let updateKeyboardLayout = async (updatedKeyboardLayout, id) => {
  let response = {};
  let url = `${host}/v4/internal/keyboardLayouts/${id}`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "POST",
    body: updatedKeyboardLayout,
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let getLanguages = async () => {
  let languages = {};
  let url = `${host}/v4/internal/languages`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => {
    languages = res.json();
  });

  return languages;
};

export let getParsedLanguages = async () => {
  let response = await getLanguages();
  if (response.errorCode) return response;

  let languages = {};
  let parsedLanguages = [];
  if (response.languages) languages = response.languages;
  languages.map((language) => {
    let newLanguage = {
      label: `${language.name} (Territory: ${language.territory})`,
      value: language.code + "-" + language.id,
    };
    parsedLanguages.push(newLanguage);
  });
  return parsedLanguages;
};

export let getTransliterationAlgorithms = async () => {
  let algorithms = [];
  let url = `${host}/v4/internal/keyboardLanguages/transliterationModels`;
  let token = retrieveFromLocalStorage("token");

  let res = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  algorithms = await res.json();

  return algorithms;
};

export let uploadKeyboardLanguageResources = async (resources, id) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/keyboardLanguages/${id}/resources`;

  await fetch(url, {
    method: "POST",
    body: resources,
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

import React, { useState, useEffect } from "react";
import { Card, CardBody,Badge } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { columns } from "./tagTableColumns";


const TagTable = ({ tag }) => {
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    if (tag !== undefined && tag.length > 0) {
      const groupedTags = tag.reduce((grouped, tag) => {
        const { type, text } = tag;
        if (!grouped[type]) {
          grouped[type] = [];
        }
        grouped[type].push(text);

        return grouped;
      }, {});
      const result = Object.entries(groupedTags).map(([type, texts]) => ({
        type,
        text: texts.join(", "),
      }));

      setUserData(result);
    }
  }, [tag]);
  const textFormatter = (cell, row, rowIndex, columnIndex, columnIndexOriginal) => (
    <div style={{ whiteSpace: "normal" }}>
      {row.text.split(',').map((text, index) => (
        <Badge
          key={index}
          className="badge-secondary ml-2 mb-2 p-1"
        >
          {text.trim()}
        </Badge>
      ))}
    </div>
  );
  return (
    <Card>
      <CardBody>
        <ToolkitProvider keyField="type" data={userData} columns={columns} bootstrap4>
          {(props) => (
            <div>
              <BootstrapTable
                {...props.baseProps}
                bordered={false}
                keyField="type"
                data={userData}
                columns={[
                    ...columns.map((col) => ({
                      ...col,
                      formatter: col.dataField === "text" ? textFormatter : col.formatter,
                    })),
                  ]}
                pagination={paginationFactory({
                  sizePerPage: 10,
                  sizePerPageList: [5, 10, 25, 50],
                })}
                hover={false}
                rowEvents={{
                  onMouseOver: (e, row, rowIndex) => {
                    e.target.style.cursor = "auto";
                  },
                }}
                groupBy="type"
                
              />
            </div>
          )}
        </ToolkitProvider>
      </CardBody>
    </Card>
  );
};

export default TagTable;

import React, { useState } from "react";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { permissions } from "../../../utilities/commonUtil";
import { getContentCategoryUserAttributeRules, saveContentCategoryUserAttributeRules, deactivateContentCategoryUserAttributeRules, activateContentCategoryUserAttributeRules } from "../../../utilities/apiUtils/cre";
import UserAttributeFilters from "../UserAttributeFilters";
import { userAttributeRuleFilterOptions } from "../../../config/config";

const ContentCategoryUserAttributeRules = () => {

    const [allowedPermissions] = useState(
        retrieveFromLocalStorage("userPermissions")
    );

    const updateRulesAllowed = () => {
        return allowedPermissions.includes(permissions.contentCategoryRuleUpdate);
    };

    return (
        <div>
            <UserAttributeFilters
                ruleType={"contentCategoryId"}
                getFilters={userAttributeRuleFilterOptions}
                getRules={getContentCategoryUserAttributeRules}
                saveRules={saveContentCategoryUserAttributeRules}
                deactivateRules={deactivateContentCategoryUserAttributeRules}
                activateRules={activateContentCategoryUserAttributeRules}
                updateRulesAllowed={updateRulesAllowed()}
            />
        </div>
    );
};

export default ContentCategoryUserAttributeRules;

import { retrieveFromLocalStorage } from "../utilities/localStorageUtil";
import { permissions } from "../utilities/commonUtil";

const allowedPermissions = retrieveFromLocalStorage("userPermissions");

export let isEnvConfigCreatePermission = () => {
  return allowedPermissions.includes(permissions.adsEnvConfigCreate);
};

export let isEnvConfigEditPermission = () => {
  return allowedPermissions.includes(permissions.adsEnvConfigEdit);
};

export let isEnvConfigDeletePermission = () => {
  return allowedPermissions.includes(permissions.adsEnvConfigDelete);
};

export const envConfigsAllActions = {
  delete: "Delete",
};

export const envConfigsResponses = {
  updatedSuccessfully: "Env config updated successfully",
  addedSuccessfully: "Env config added successfully",
};

export const isValidJSON = (str = "") => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

export const formatJSON = (str = "") => {
  if (isValidJSON(str)) {
    return JSON.stringify(JSON.parse(str), null, 2);
  }
  return str
}
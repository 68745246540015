import React, { useState, useEffect, PureComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import {
  permissions,
  redirectToNewTab,
} from "../../../../utilities/commonUtil";
import { Menu } from "react-feather";
import { retrieveFromLocalStorage } from "../../../../utilities/localStorageUtil";
import { Config } from "../../../../config/UserConfig";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  CardImg,
  CardTitle,
} from "reactstrap";

const EmojiSticker = (props) => {
  const allowedPermissions = retrieveFromLocalStorage("userPermissions");
  const showDetailsButton = () => {
    return allowedPermissions.includes(permissions.emojiStickerImageDetails);
  };

  return (
    <Card>
      <Container
        className="cursor-pointer"
        onClick={() => {
          if (allowedPermissions.includes(permissions.emojiStickerImageDetails))
            redirectToNewTab(`/stickers/emoji-stickers/${props.emoji.emojiId}`);
        }}
      >
        <p
          className="mx-auto"
          style={{
            fontSize: "100px",
            maxWidth: "max-content",
          }}
        >
          {props.emoji.emoji}
        </p>
      </Container>
      <CardHeader className="px-4 pt-4">
        <CardTitle tag="h3" className="mb-0">
          {props.emoji.name}
        </CardTitle>
      </CardHeader>
      <CardBody className="px-4 pt-2">
        <small className="font-weight-bold">ID : {props.emoji.emojiId}</small>
        <br />
        <small className="font-weight-bold">
          Created At : {props.emoji.createdAt}
        </small>
        <br />
        <small className="font-weight-bold">
          Updated At : {props.emoji.updatedAt}
        </small>
        <br />
      </CardBody>
      <CardBody className="px-4 pt-2 pl-0 pr-0">
        <Container className="text-center" fluid>
          <Row>
            <Col sm="12">
              {showDetailsButton() ? (
                <Button
                  className="mw-75 w-120"
                  color="info"
                  onClick={() =>
                    redirectToNewTab(
                      `/stickers/emoji-stickers/${props.emoji.emojiId}`
                    )
                  }
                >
                  Details
                  <FontAwesomeIcon
                    icon={(fas, faInfoCircle)}
                    className="ml-2"
                  ></FontAwesomeIcon>
                </Button>
              ) : null}
            </Col>
          </Row>
        </Container>
      </CardBody>
    </Card>
  );
};

export default EmojiSticker;

import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Col, Row } from "reactstrap";

export const AppResourcesResponseModal = (props) => {
  const [copyButtonText, setCopyButtonText] = useState("Copy to Clipboard");
  const [copiedButtonId, setCopiedButtonId] = useState(null);

  const copy = (url, id) => {
    navigator.clipboard.writeText(url);
    setCopiedButtonId(id);
    setCopyButtonText("Copied");
    setTimeout(() => {
      setCopiedButtonId(null);
      setCopyButtonText("Copy to Clipboard");
    }, 1000);
  };

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      scrollable={true}
    >
      <Modal.Header className="bg-light" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.modalheading}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.responses.map((response, index) => (
          <Row key={index}>
            <Col md={12}>Description: {response.description}</Col>
            <Col md={12}>URL: {response?.compressedURL ?? response?.originalURL}</Col>
            {/* Make a copy to clipboard button */}
            <Col md={6}>
              <Button
                id={index}
                key={"button" + index}
                onClick={() => copy(response?.compressedURL ?? response?.originalURL, index)}
                disabled={copiedButtonId === index}
              >
                {copiedButtonId === index ? "Copied" : "Copy to Clipboard"}
              </Button>
            </Col>

            <hr
              style={{
                width: "75rem",
                color: "black",
              }}
            />
          </Row>
        ))}
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

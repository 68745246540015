import React, { Fragment, useState, useEffect } from "react";
import ResponseModal from "../ResponseModal";
import {
  createThemeCategory,
  updateThemeCategory,
  getKeyboardThemeCategoryDetail,
} from "../../../utilities/apiUtils/themes";
import ThemeCategoryForm from "./ThemeCategoryForm";
import Routes from "../../../routes/index";
import {
  errorDescription,
  error,
  redirectTo,
  redirectionOnTokenExpiry,
} from "../../../utilities/commonUtil";
import {
  successModalHeading,
  failureModalHeading,
  themeCreatedSuccessfullyText,
  themeUpdatedSuccessfullyText,
} from "../../../config/themes";

const KeyboardThemeCategoryFormContainer = (props) => {
  const [formTextState, setFormTextState] = useState({
    formName: "Create Keyboard Theme Category",
    name: "",
    bannerImage: null,
    bannerImageSrc: "",
    brandCampaignID: 0,
    numPreviewThemes: { value: 6, valid: true },
    displayExpandedView: false,
    priority: { value: 1, valid: true },
  });
  const [showForm, setShowForm] = useState(false);
  const [successModalState, setSuccessModalState] = useState({
    successModalDisplay: false,
    successModalText: "",
  });
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });

  useEffect(() => {
    const getTheme = async () => {
      let ID = props.match.params.categoryID;

      // If ID is present, fetch the theme details for edit
      if (ID) {
        try {
          let response = await getKeyboardThemeCategoryDetail(ID);
          if (response) {
            // Set the theme values in state (overwrite the default values with fetched values)
            setFormTextState({
              formName: "Edit Keyboard Theme",
              name: response.name,
              numPreviewThemes: {
                value: response.numPreviewThemes,
                valid: true,
              },
              brandCampaignID: response.brandCampaignID,
              displayExpandedView: response.displayExpandedView,
              bannerImage: null,
              priority: { value: response.priority, valid: true },
              bannerImageSrc: response.bannerImage,
            });
            // For keyboard theme edit form
            setShowForm(true);
            return;
          }
          // Handle error
          if (response.errorCode) {
            if (
              response.errorCode === error.tokenExpired ||
              response.errorCode === error.invalidAccessToken
            )
              redirectionOnTokenExpiry();
            setFailureModalState({
              filureModalDisplay: true,
              failureModalText: errorDescription(response.errorCode),
            });
          }
        } catch (err) {
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: errorDescription(error.unexpectedError),
          });
        }
      }
      // For keyboard theme create form
      setShowForm(true);
    };

    getTheme();
  }, []);

  const onSubmit = async (themeObj) => {
    let formData = new FormData();

    // Append values in Form data
    formData.append("name", themeObj.name);
    formData.append("numPreviewThemes", themeObj.numPreviewThemes.value);
    formData.append("displayExpandedView", themeObj.displayExpandedView);
    if (themeObj.brandCampaignID) {
      formData.append("brandCampaignID", themeObj.brandCampaignID);
    } else if (themeObj.bannerImageSrc) {
      formData.append("bannerImageSrc", themeObj.bannerImageSrc);
    }
    if (themeObj.bannerImage)
      formData.append("bannerImage", themeObj.bannerImage);
    formData.append("priority", themeObj.priority.value);

    // API call for create theme
    if (formTextState.formName === "Create Keyboard Theme Category") {
      try {
        let response = await createThemeCategory(formData);
        if (response.errorCode) {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken
          ) {
            redirectionOnTokenExpiry();
            setFailureModalState({
              failureModalDisplay: true,
              failureModalText: errorDescription(response.errorCode),
            });
            return;
          }
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: response.errorDescription,
          });
        } else {
          setSuccessModalState({
            successModalDisplay: true,
            successModalText: themeCreatedSuccessfullyText,
          });
        }
      } catch (err) {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      }
    } else {
      // API call for edit theme
      formData.append("categoryID", props.match.params.categoryID);
      try {
        let response = await updateThemeCategory(
          formData,
          props.match.params.categoryID
        );
        if (response.errorCode) {
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: response.errorDescription,
          });
        } else {
          setSuccessModalState({
            successModalDisplay: true,
            successModalText: themeUpdatedSuccessfullyText,
          });
        }
      } catch (err) {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      }
    }
  };

  const failureModalClose = () => {
    setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
  };

  const successModalClose = () => {
    setSuccessModalState((prev) => ({ ...prev, successModalDisplay: false }));
    redirectTo(Routes.keyboardThemes.children.keyboardThemeCategories.path);
  };

  return (
    <Fragment>
      {showForm ? (
        <ThemeCategoryForm
          category={formTextState}
          submit={onSubmit}
          updateAllowed={props.updateAllowed}
        />
      ) : null}
      <ResponseModal
        show={failureModalState.failureModalDisplay}
        onHide={() => failureModalClose()}
        modalheading={failureModalHeading}
        modaltext={failureModalState.failureModalText}
      />
      <ResponseModal
        show={successModalState.successModalDisplay}
        onHide={() => successModalClose()}
        modalheading={successModalHeading}
        modaltext={successModalState.successModalText}
      />
    </Fragment>
  );
};

export default KeyboardThemeCategoryFormContainer;

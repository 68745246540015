import {retrieveFromLocalStorage} from "../utilities/localStorageUtil";
import {permissions} from "../utilities/commonUtil";
export const PopTextStyleCreatedSuccessfullyText =
  "Pop Text Style created successfully.";
export const PopTextStyleUpdatedSuccessfullyText = "Pop Text Style updated.";
export const PopTextStyleLimit = 20;
const allowedPermissions = retrieveFromLocalStorage("userPermissions");

export const PopTextStyleShareTextResponses = {
    updatedSuccessfully : "Share Texts  updated successfully",
}

export let showGifShareTextButton = () => {
    return allowedPermissions.includes(permissions.PopTextStyleShareTextUpdate);
};
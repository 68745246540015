import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import CreatableSelect from 'react-select/creatable';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row, CustomInput, Badge } from "reactstrap";
import ConfirmationModal from "../../../pages/ui-elements/ConfirmationModal";
import ResponseModal from "../../../pages/ui-elements/ResponseModal";
import TargetingRules from "./components/TargetingRules";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { permissions } from "../../../utilities/commonUtil";
import { error, errorDescription, redirectOnTokenExpiry, redirectTo } from "../../../utilities/commonUtil";
import { getFullBucketData, fetchBucketTargetingRulesData, updateStoryRuleSets, createStoriesBulk, getBucketsTagsData } from "../../../utilities/apiUtils/stories";
import {
    getCountries,
    getRegions,
    getCities,
} from "../../../utilities/apiUtils/stories";
import { getClients } from "../../../utilities/apiUtils/cre";
import {
    getBrandCampaigns,
} from "../../../utilities/apiUtils/stickers";
import { getUTMCampaigns } from "../../../utilities/apiUtils/users";

const StoriesBulkCreate = (props) => {
    const urlParams = new URLSearchParams(window.location.search);
    const bucketIdFromParams = urlParams.get('bucketId');
    const [bucketId, setBucketId] = useState(bucketIdFromParams ? bucketIdFromParams : null);
    
    const [filters, setFilters] = useState({
        startDate: null,
        endDate: null,
    })
    const [targetingRules, setTargetingRules] = useState(null);
    useEffect(() => {
        fetchBucket()
    }, []);

    let showErrorModal = (text) => {
        setFailureModalText(text);
        setFailureModalDisplay(true);
    };
    let successModalClose = async () => {
        setSuccessModalDisplay(false);
    };
    let failureModalClose = () => {
        setFailureModalDisplay(false);
    };
    const [isTargetingDataSet, setIsTargetingDataSet] = useState(false);
    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
    const [successModalDisplay, setSuccessModalDisplay] = useState(false);
    const [successModalText, setSuccessModalText] = useState("");
    const [failureModalDisplay, setFailureModalDisplay] = useState(false);
    const [failureModalText, setFailureModalText] = useState("");
    const [jsonString, setJsonString] = useState('');
    const [jsonData, setJsonData] = useState(null);
    const [Tags, setTags] = useState([])
    const [bucket, setBuckets] = useState([])
    const [bucketOptions, setBucketOptions] = useState([]);
    const [campaigns, setCampaigns] = useState([]);
    const [details, setDetails] = useState({
        directory: null,
        tags: [],
        storyBucketId: null,
        startDate: "",
        endDate: "",
        autoPublish: true,
        shareText: null,
        enableShareText: true,
        storyTargetingRules: [],
        enableWatermark: true,
        utmCampaign :   null,
    });
    const [utmCampaignOptions, setUtmCampaignOptions] = useState([]);

    const handleDetailsChange = (event) => {
        if (!event.target) {
            setDetails(prevState => ({
                ...prevState,
                brandCampaignID: event.value
            }));

        } else {
            const { name, value } = event.target;
            if (name === "rawResourceFile") {
                setDetails((prevState) => ({
                    ...prevState, // Spread the previous state properties
                    [name]: event.target.files[0],
                    "previewSourceFile": event.target.files[0],
                    "rawResourcesFilename": event.target.files[0].name,// Update enableWatermark
                }))
            } else {

                setDetails((prevState) => ({
                    ...prevState, // Spread the previous state properties
                    [name]: value// Update enableWatermark
                }));
                if (name === "enableShareText") {
                    const { checked } = event.target;
                    setDetails(prevState => ({
                        ...prevState,
                        enableShareText: checked
                    }));
                }
            }
        };
    };
    const handleInputChange = (event) => {
        setJsonString(event.target.value);
    };
    const [allowedPermissions] = useState(
        retrieveFromLocalStorage("userPermissions")
    );
    const updateRulesAllowed = () => {
        return allowedPermissions.includes(permissions.SuggestionDrawerContentRulesUpdate);
    };
    useEffect(() => {
        const _getBrandCampaigns = async () => {
            let response = await getBrandCampaigns();
            setCampaigns(response);
        };
        const fetchUTMCampaigns = async () => {
            let response = await getUTMCampaigns();
            setUtmCampaignOptions(response);
        };
        fetchUTMCampaigns();
        _getBrandCampaigns();
    }, []);
    const handleSubmit = async () => {
        setIsLoading(true);
        let payload = {
            storyBucketId: bucketId,
            directory: details.directory,
            tags: Tags.tags.map(tag => tag.value),
            startAt: filters.startDate,
            endAt: filters.endDate,
            autoPublish: details.autoPublish,
            shareText: details.shareText === "" ? null : details.shareText,
            enableShareText: details.enableShareText,
            enableWatermark: details.enableWatermark,
            targetingRules: targetingRules,
            brandCampaignId: details.brandCampaignID,
            utmCampaign: details?.utmCampaign?.value
        }

        const response = await createStoriesBulk(payload)
        setIsLoading(false);
        if (response.status === "success") {
            setSuccessModalText(response.message);
            setSuccessModalDisplay(true);
            redirectTo(`/stories/stories?bucketId=${bucketId}&status=&sortBy=created_at+desc&searchString=&selectAll=false`)
        } else {
            if (
                response.errorCode === error.tokenExpired ||
                response.errorCode === error.invalidAccessToken ||
                response.errorCode === error.unauthorized
            )
                redirectOnTokenExpiry();
            let text = errorDescription(error.unexpectedError);
            showErrorModal(text);
        }
    };

    useEffect(() => {
        if (isTargetingDataSet) {
            setConfirmationModalState();
        }
    }, [isTargetingDataSet]);


    
    const handleVisualChange = (event) => {
        const { checked, name } = event.target;
        setDetails(prevState => ({
            ...prevState,
            [name]: checked
        }));
    }
    let setConfirmationModalState = (event) => {
        event.preventDefault();
        setConfirmationModalIsOpen(true);
    };
    const fetchTags = async (id) => {
        try {
            let response = await getBucketsTagsData(id)
            // Assuming getStoryTagsData returns an array of tag strings
            const formattedTags = response.tags.map(tag => ({
                label: tag,
                value: tag,
                __isNew__: true
            }));
            setTags((prevState) => ({
                ...prevState,
                tags: formattedTags
            }));
        } catch {
            console.error("Error fetching story details:", error);
        }
    }
    const [isLoading, setIsLoading] = useState(false);
    const handleDateChange = (event) => {
        const { value } = event.target;
        setFilters((prevState) => ({
            ...prevState,
            startDate: value,
            endDate: value
        }));
    }
    const fetchBucket = async (id) => {
        try {
            let response = await getFullBucketData(id);
            const formattedBuckets = response.storyBuckets.map(bucket => ({
                value: bucket.id,
                label: bucket.name
            }));

            setBucketOptions((prevState) => ({
                ...prevState,
                storyBuckets: formattedBuckets
            }));

        } catch (error) {
            console.error("Error fetching story buckets:", error);
        }
    };
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const bucketIdFromParams = urlParams.get('bucketId');
        let selectedBucket;
        if (bucketIdFromParams) {
            fetchTags(bucketIdFromParams)
            if (bucketOptions.storyBuckets !== undefined) {
                selectedBucket = bucketOptions.storyBuckets.find(
                    (b) => b.value === parseInt(bucketIdFromParams)
                );
            }
            if (selectedBucket) {
                setBucketId(selectedBucket.value);
                setBuckets(selectedBucket);
                setIsEditing(false); // Disable editing
                handleBucketChange(selectedBucket);
            } else {
                // Handle the error if bucket not found in existing options
                console.error('Bucket not found in existing options');
            }
        } else {
            setIsEditing(true); // Enable editing
        }
    }, [bucketOptions]);

    const handleBucketChange = async (selectedBucket) => {
        // Update a state variable to store the selected bucket ID
        setBucketId(selectedBucket.value);
        setBuckets(selectedBucket)
        fetchTags(selectedBucket.value);
        let params = {
            id: selectedBucket.value,
        };

        let response = await getFullBucketData(params)
        response.storyBuckets[0].startAt = response?.storyBuckets[0]?.startAt?.split("T")[0]
        response.storyBuckets[0].endAt = response?.storyBuckets[0]?.endAt?.split("T")[0]
        setDetails((prevState) => ({
            ...prevState,
            ...response?.storyBuckets[0]
        })
        );
        setFilters((prevState) => ({
            ...prevState,
            startDate: response?.storyBuckets[0]?.startAt?.split("T")[0],
            endDate: response?.storyBuckets[0]?.endAt?.split("T")[0]
        }));
    };

    return (
        <Container fluid className="p-0">
            <Row className="mb-3">
                <Col md={6} xs={6}>
                    <h1> Stories Bulk Create </h1>
                </Col>
            </Row>
            <Form onSubmit={(event) => setConfirmationModalState(event)}>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label
                                                className="font-weight-bolder mb-2"
                                                style={{ fontSize: "14px" }}
                                            >Select Bucket<span className="text-danger">*</span><small className="text-info"></small>
                                            </Label>
                                            <Select
                                                id="bucketID"
                                                isMulti={false}
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                name="bucketID"
                                                placeholder="Select Bucket"
                                                value={bucket}
                                                onChange={(event) => {
                                                    handleBucketChange(event)
                                                }}
                                                isDisabled={!isEditing}
                                                options={bucketOptions.storyBuckets}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row lg="12">
                    <Col>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg={4} md={6}>
                                        <FormGroup className="d-flex align-items-center">
                                            <Label
                                                className="font-weight-bolder mb-0"
                                                style={{ fontSize: "14px" }}
                                            >
                                                Auto Publish
                                            </Label>
                                            <div className="custom-control custom-switch">
                                                <CustomInput
                                                    type="checkbox"
                                                    id="autoPublish"
                                                    name="autoPublish"
                                                    value={details.autoPublish}
                                                    onChange={handleVisualChange}
                                                    checked={details.autoPublish}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4} md={6}>
                                        <FormGroup className="d-flex align-items-center">
                                            <Label
                                                className="font-weight-bolder mb-0"
                                                style={{ fontSize: "14px" }}
                                            >
                                                Enable Share Text
                                            </Label>
                                            <div className="custom-control custom-switch">
                                                <CustomInput
                                                    type="checkbox"
                                                    id="enableShareText"
                                                    name="enableShareText"
                                                    value={details.enableShareText}
                                                    onChange={handleVisualChange}
                                                    checked={details.enableShareText}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4} md={6}>
                                        <FormGroup className="d-flex align-items-center">
                                            <Label
                                                className="font-weight-bolder mb-0"
                                                style={{ fontSize: "14px" }}
                                            >
                                                Enable WaterMark
                                            </Label>
                                            <div className="custom-control custom-switch">
                                                <CustomInput
                                                    type="checkbox"
                                                    id="enableWatermark"
                                                    value={details.enableWatermark}
                                                    onChange={
                                                        handleVisualChange}
                                                    checked={details.enableWatermark}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label>
                                                <strong>
                                                    File Directory
                                                </strong>
                                            </Label>
                                            <Input
                                                type="text"
                                                id="text"
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                name="directory"
                                                placeholder="text"
                                                value={details.directory}
                                                onChange={handleDetailsChange}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label>
                                                <strong>
                                                    Share Text
                                                </strong>
                                            </Label>
                                            <textarea
                                                type="text"
                                                id="text"
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                name="shareText"
                                                placeholder="text"
                                                value={details.shareText}
                                                onChange={handleDetailsChange}
                                                style={{ width: "100%" }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>
                                                Start Date:
                                            </Label>

                                            <Input
                                                type="date"
                                                name="startDate"
                                                onChange={handleDateChange}
                                                value={details.startAt !== "" ? details.startAt : ""}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>
                                                End Date:
                                            </Label>
                                            <Input
                                                type="date"
                                                name="endDate"
                                                onChange={handleDateChange}
                                                value={details.endAt !== "" ? details.endAt : ""}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Brand Campaign ID</Label>
                                            <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                value={campaigns.find(opt => opt.value === details.brandCampaignID)}
                                                placeholder="Select campaign"
                                                isClearable={true}
                                                options={campaigns}
                                                name="brandCampaignID"
                                                onChange={(event) => handleDetailsChange(event)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={6} md={6}>
                                    <FormGroup>
                                    <Label>
                                        UTM Campaign
                                    </Label>
                                    

                                    {/* create a clearable select dropdown */}
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        options={utmCampaignOptions}
                                        placeholder="Select UTM Campaign"
                                        value={details.utmCampaign}
                                        isClearable
                                        onChange={(value) => {
                                        setDetails((prevState) => ({
                                            ...prevState,
                                            utmCampaign: value
                                        }))
                                        }
                                        }
                                    />

                                    
                                    </FormGroup>
                                </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row lg="12">
                    <Col>
                        <Card>
                            <CardBody>
                                <Col md={6} >
                                    <h1> Tags </h1>
                                </Col>
                                <Col md={12}>
                                    <FormGroup>
                                        <CreatableSelect
                                            id="tags"
                                            isMulti
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            name="tags"
                                            placeholder="Enter Tags"
                                            value={Tags.tags}
                                            onChange={(event) => {
                                                setTags((prevState) => ({
                                                    ...prevState,
                                                    tags: event,
                                                }))
                                            }}
                                            components={{
                                                DropdownIndicator: null,
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {bucketId && (
                    <Row lg="12">
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <div>
                                        <TargetingRules
                                            key={bucketId}  
                                            showAgeGenderRules={true}
                                            showCustomAudienceTargetingRules={false}
                                            showPlacementRules={false}
                                            showClientRules={true}
                                            id={bucketId}
                                            showHardFiltering={false}
                                            isAdvertisingAttributes={true}
                                            getRules={fetchBucketTargetingRulesData}
                                            saveRules={updateStoryRuleSets}
                                            getClients={getClients}
                                            getCountries={getCountries}
                                            getRegions={getRegions}
                                            getCities={getCities}
                                            updateRulesAllowed={updateRulesAllowed()}
                                            setTargetingData={setTargetingRules}
                                            setIsTargetingDataSet={setIsTargetingDataSet}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )}
                <Button className="d-block ml-3 mt-2" color="primary" disabled={isLoading}>
                    {isLoading ? 'Loading...' : 'Submit'}
                </Button>
            </Form>
            <ConfirmationModal
                show={confirmationModalIsOpen}
                modaltext={"submit"}
                onConfirmationTrue={() => {
                    setConfirmationModalIsOpen(false);
                    handleSubmit();
                }}
                onHide={() => setConfirmationModalIsOpen(false)}
            />
            <ResponseModal
                show={successModalDisplay}
                onHide={successModalClose}
                modalheading={"Success"}
                modaltext={successModalText}
            />
            <ResponseModal
                show={failureModalDisplay}
                onHide={failureModalClose}
                modalheading={"Error"}
                modaltext={failureModalText}
            />
        </Container>
    );
};

export default StoriesBulkCreate;
import { retrieveFromLocalStorage } from "../utilities/localStorageUtil";
import { permissions } from "../utilities/commonUtil";

const allowedPermissions = retrieveFromLocalStorage("userPermissions");

export let isRevenueNoteCreatePermission = () => {
  return allowedPermissions.includes(permissions.revenueNotesCreate);
};

export let isRevenueNoteEditPermission = () => {
  return allowedPermissions.includes(permissions.revenueNotesEdit);
};

export let isRevenueNoteDeletePermission = () => {
  return allowedPermissions.includes(permissions.revenueNotesDelete);
};

export const dataSourceOptions = [
  {
    value: "mysql",
    label: "Aggregated Layer"
  },
  {
    value: "bigquery",
    label: "Raw Layer"
  }
]

export const graphGranularityOptions = [
  {
    value: "day",
    label: "Day Level"
  },
  {
    value: "hour",
    label: "Hour Level"
  }
]

export const revenueTypeOptions = [
  {
    value: "cpi",
    label: "CPI",
  }
]

export const clientOptions = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "7wZFJWA5chjgat68y826IAIKQ6s197RM",
    label: "Bobble Android",
  },
  {
    value: "com.mint.keyboard",
    label: "Com.Mint",
  },
  {
    value: "ai.mint.keyboard",
    label: "AI.Mint",
  }
]

export const providerDataGroupByOptions = [
  {
    value: "date",
    label: "Date",
  },
  {
    value: "client_id",
    label: "Client",
  },
  {
    value: "placement_id",
    label: "Placement",
  },
  {
    value: "provider_id",
    label: "Provider",
  },
  {
    value: "ad_type",
    label: "Revenue Type",
  }
]

export const adDataGroupByOptions = [
  {
    value: "date",
    label: "Date",
  },
  {
    value: "client_id",
    label: "Client",
  },
  {
    value: "placement_id",
    label: "Placement",
  },
  {
    value: "provider_id",
    label: "Provider",
  },
  {
    value: "ad_id",
    label: "Ad Name",
  },
  {
    value: "ad_type",
    label: "Revenue Type",
  }
]

export const revenueDashboardNotesAllActions = {
  delete: "Delete",
};

export const revenueDashboardNotesResponses = {
  updatedSuccessfully: "Note updated successfully",
  addedSuccessfully: "Note added successfully",
};

export const bigqueryErrorMessage = "Please contact backend team for more details."

export const getValidProviderName = (string = "") => {
  if (string.includes("_cpi")) string = string.replace(/_cpi/g, '')
  if (string.includes("_cpc")) string = string.replace(/_cpc/g, '')
  return string
}

export const formatRevenueValue = (revenue) => {
  if (revenue >= 1e9) {
    return (revenue / 1e9).toFixed(2) + 'Bn';
  } else if (revenue >= 1e6) {
    return (revenue / 1e6).toFixed(2) + 'Mn';
  } else if (revenue >= 1e3) {
    return (revenue / 1e3).toFixed(2) + 'K';
  } else {
    return revenue.toFixed(2).toString();
  }
}

export const convertToIndianFormat = (revenue) => {
  return revenue?.toLocaleString('en-IN')
}
import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { Card, CardBody, CardHeader } from 'reactstrap';
import Loading from "../../../components/Loading";
import { formatRevenueValue } from '../../../config/configRevenueDashboard';

const chartOptions = {
  chart: {
    type: 'line',
    width: '100%',
    toolbar: {
      export: {
        csv: {
          filename: "revenue_graph",
          headerCategory: 'Date',
        },
        svg: {
          filename: "revenue_graph"
        },
        png: {
          filename: "revenue_graph"
        }
      }
    }
  },
  dataLabels: {
    enabled: false
  },
  markers: {
    size: 5,
    hover: {
      sizeOffset: 2
    }
  },
  tooltip: {
    x: {
      format: 'dd MMM HH:mm:ss',
    }
  },
  fill: {
    colors: ['#5fc27e', '#47BAC1', '#f44455']
  },
  colors: ['#5fc27e', '#47BAC1', '#f44455'],
  stroke: {
    width: 4,
    curve: 'smooth'
  },
  xaxis: {
    type: 'datetime',
    title: {
      text: 'Date',
      style: {
        fontSize: 14,
        fontWeight: 'bold'
      }
    },
    labels: {
      datetimeFormatter: {
        year: 'yyyy',
        month: 'MMM yyyy',
        day: 'dd MMM yyyy',
        hour: 'HH:mm',
      },
    },
  },
  yaxis: {
    title: {
      text: 'Revenue',
      style: {
        fontSize: 14,
        fontWeight: 'bold'
      }
    },
    labels: {
      formatter: function (value) {
        return formatRevenueValue(value);
      }
    },
    axisBorder: {
      show: true
    },
  }
}

const RevenueGraph = ({ loading = false, data = [] }) => {
  const [series, setSeries] = useState([
    {
      name: 'Install Revenue',
      data: []
    }
  ])

  useEffect(() => {
    if (data) {
      const installRevenue = []
      data.forEach(day => {
        installRevenue.push([new Date(day.date).getTime(), day.eIn])
      })
      setSeries((prevState) => ([
        {
          ...prevState[0],
          data: installRevenue
        }
      ]))
    }
  }, [data])

  return (
    <Card>
      <CardHeader>
        <h4 className="mb-0 pt-2">Revenue Graph</h4>
      </CardHeader>
      <CardBody className="pt-0">
        {
          loading
            ? <Loading></Loading>
            : data.length > 0
              ? <Chart options={chartOptions} series={series} type="line" height={500} />
              : <h4 className='text-center py-5 font-weight-light'>No revenue data available for the selected filters</h4>
        }
      </CardBody>
    </Card>
  )
}

export default RevenueGraph
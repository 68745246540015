import { retrieveFromLocalStorage } from "../localStorageUtil";
import { host } from "../apiUtils/index";

export let fetchPushNotifications = async () => {
    let url = `${host}/v1/internal/pushNotifications`;
let token = retrieveFromLocalStorage("token");

    try {
        let response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
        return await response.json();
    } catch (error) {
        return;
    }
};

export let fetchUserSegments = async () => {
    let url = `${host}/v1/internal/pushNotifications/userSegments`;
let token = retrieveFromLocalStorage("token");

    try {
        let response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
        return await response.json();
    } catch (error) {
        return;
    }
};

export let publishPushNotification = async (id) => {
    let url = `${host}/v1/internal/pushNotifications/${id}/publish`;
let token = retrieveFromLocalStorage("token");

    try {
        let response = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
        return await response.json();
    } catch (error) {
        return;
    }
};

export let activatePushNotification = async (id) => {
    let url = `${host}/v1/internal/pushNotifications/${id}/activate`;
let token = retrieveFromLocalStorage("token");

    try {
        let response = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
        return await response.json();
    } catch (error) {
        return;
    }
};

export let deactivatePushNotification = async (id) => {
    let url = `${host}/v1/internal/pushNotifications/${id}/deactivate`;
let token = retrieveFromLocalStorage("token");

    try {
        let response = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
        return await response.json();
    } catch (error) {
        return;
    }
};

export let triggerInstantPushNotification = async (id) => {
    let url = `${host}/v1/internal/pushNotifications/${id}/send`;
let token = retrieveFromLocalStorage("token");

    try {
        let response = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
        return await response.json();
    } catch (error) {
        return;
    }
};

export let cancelNotification = async (id) => {
    let url = `${host}/v1/internal/pushNotifications/${id}/cancel`;
let token = retrieveFromLocalStorage("token");

    try {
        let response = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
        return await response.json();
    } catch (error) {
        return;
    }
};

export let fetchPushNotificationExecution = async (id) => {
    let url = `${host}/v1/internal/pushNotifications/${id}/executions`;
let token = retrieveFromLocalStorage("token");

    try {
        let response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
        return await response.json();
    } catch (error) {
        return;
    }
};

export let testPushNotification = async (id, gcmIDs) => {
    let url = `${host}/v1/internal/pushNotifications/${id}/test`;
let token = retrieveFromLocalStorage("token");

    try {
        let response = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(gcmIDs),
        })
        return await response.json();
    } catch (error) {
        return;
    }
};

export let createPushNotification = async (formData) => {
    let url = `${host}/v1/internal/pushNotifications`;
let token = retrieveFromLocalStorage("token");

    try {
        let response = await fetch(url, {
            method: "POST",
            body: formData,
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};

export let editPushNotification = async (id, formData) => {
    let url = `${host}/v1/internal/pushNotifications/${id}`;
let token = retrieveFromLocalStorage("token");

    try {
        let response = await fetch(url, {
            method: "POST",
            body: formData,
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};

export let fetchPushNotification = async (id) => {
    let url = `${host}/v1/internal/pushNotifications/${id}`;
let token = retrieveFromLocalStorage("token");

    try {
        let response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
        return await response.json();
    } catch (error) {
        return;
    }

};

export let fetchPushNotificationTargetingRules = async (id) => {
    let url = `${host}/v1/internal/pushNotifications/${id}/rules`;
let token = retrieveFromLocalStorage("token");

    try {
        let response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
        let rules = await response.json();
        // remove country, region, city from locationRules
        if (rules?.rules !== undefined) {
            rules.rules.forEach(rule => {
                rule.locationRules.forEach(locationRule => {
                    if (locationRule.hasOwnProperty("country")) {
                        delete locationRule.country;
                    }
                    if (locationRule.hasOwnProperty("city")) {
                        delete locationRule.city;
                    }
                    if (locationRule.hasOwnProperty("region")) {
                        delete locationRule.region;
                    }
                });
            });
        }
        return rules?.rules;
    } catch (error) {
        return;
    }
};

export let savePushNotificationTargetingRules = async (rules, id, data) => {
    let url = `${host}/v1/internal/pushNotifications/${id}/rules`;
let token = retrieveFromLocalStorage("token");

    try {
        let response = await fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};

export let publishUserSegment = async (id) => {
    let url = `${host}/v1/internal/pushNotifications/userSegments/${id}/publish`;
let token = retrieveFromLocalStorage("token");

    try {
        let response = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
        return await response.json();
    } catch (error) {
        return;
    }
};

export let activateUserSegment = async (id) => {
    let url = `${host}/v1/internal/pushNotifications/userSegments/${id}/activate`;
let token = retrieveFromLocalStorage("token");

    try {
        let response = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
        return await response.json();
    } catch (error) {
        return;
    }
};

export let deactivateUserSegment = async (id) => {
    let url = `${host}/v1/internal/pushNotifications/userSegments/${id}/deactivate`;
let token = retrieveFromLocalStorage("token");

    try {
        let response = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
        return await response.json();
    } catch (error) {
        return;
    }
};

export let createUserSegment = async (data) => {
    let url = `${host}/v1/internal/pushNotifications/userSegments`;
let token = retrieveFromLocalStorage("token");

    try {
        let response = await fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};

export let fetchUserSegment = async (id) => {
    let url = `${host}/v1/internal/pushNotifications/userSegments/${id}`;
let token = retrieveFromLocalStorage("token");

    try {
        let response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
        return await response.json();
    } catch (error) {
        return;
    }

};

export let editUserSegment = async (id, data) => {
    let url = `${host}/v1/internal/pushNotifications/userSegments/${id}`;
let token = retrieveFromLocalStorage("token");

    try {
        let response = await fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};


export let fetchUserSegmentFiles = async () => {
    let url = `${host}/v1/internal/pushNotifications/userSegmentFiles`;
let token = retrieveFromLocalStorage("token");

    try {
        let response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
        return await response.json();
    } catch (error) {
        return ;
    }
}


export let getCountries = async () => {
    let countries = [];
let token = retrieveFromLocalStorage("token");

    let url = `${host}/v1/internal/pushNotifications/countries`;
    try {
        var response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        var res = await response.json();
        if (res) {
            res.map((country) => {
                let countryObj = {
                    value: country.id,
                    label: country.name,
                };
                countries.push(countryObj);
            });
        }
        return countries;
    } catch (error) {
        return;
    }
};

export let getRegions = async (countryID) => {
    let regions = [];
let token = retrieveFromLocalStorage("token");

    let url = `${host}/v1/internal/pushNotifications/regions?countryID=${countryID}`;
    try {
        var response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        var res = await response.json();
        if (res) {
            res.map((region) => {
                let regionObj = {
                    value: region.id,
                    label: region.name,
                };
                regions.push(regionObj);
            });
        }
        return regions;
    } catch (error) {
        return;
    }
};

export let getCities = async (countryID, regionID) => {
    let cities = [];
let token = retrieveFromLocalStorage("token");

    let url = `${host}/v1/internal/pushNotifications/cities?countryID=${countryID}&regionID=${regionID}`;
    try {
        var response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        var res = await response.json();
        if (res) {
            res.map((city) => {
                let cityObj = {
                    value: city.id,
                    label: city.name,
                };
                cities.push(cityObj);
            });
        }
        return cities;
    } catch (error) {
        return;
    }
};
import React, { useState, useEffect } from "react";
import ResponseModal from "../ResponseModal";
import { createUser } from "../../../utilities/apiUtils/users";
import { getRolesDropdown } from "../../../utilities/apiUtils/roles";
import {
  validateUserForm,
  errorDescription,
  redirectTo,
  error,
  redirectOnTokenExpiry,
} from "../../../utilities/commonUtil";
import Select from "react-select";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Routes from "../../../routes/index";

const UserForm = (props) => {
  const [userData, setUserData] = useState({
    username: "",
    password: "",
    confirm_password: "",
    roleIDs: [],
  });
  const [allRoles, setAllRoles] = useState([]);
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [failureModal, setFailureModal] = useState({
    display: false,
    text: "",
  });

  useEffect(() => {
    let fetchRoles = async () => {
      try {
        let res = await getRolesDropdown();
        if (res.length) {
          setAllRoles(res);
        } else if (res.errorCode) {
          let text = errorDescription(res.errorCode);
          setFailureModal({ display: true, text: text });
        }
      } catch (err) {
        setFailureModal({
          display: true,
          text: errorDescription(error.unexpectedError),
        });
      }
    };
    fetchRoles();
  }, []);

  const handleChange = (event) => {
    switch (event.target.name) {
      case "username":
        setUserData({ ...userData, username: event.target.value });
        break;
      case "password":
        setUserData({ ...userData, password: event.target.value });
        break;
      case "confirm_password":
        setUserData({ ...userData, confirm_password: event.target.value });
        break;

      default:
        return;
    }
  };

  const handleRoleChange = (select) => {
    let roles = [];
    if (select !== null) {
      select.forEach((option) => {
        roles.push(option.value);
      });
    }
    setUserData({ ...userData, roleIDs: roles });
  };

  const showAlert = (elementId) => {
    document.getElementById(elementId).classList.remove("d-none");
  };

  const hideAlert = (elementId) => {
    document.getElementById(elementId).classList.add("d-none");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let user = validateUserForm(userData);
    if (user.isValid) {
      try {
        let response = await createUser(user.data);
        if (response.success) {
          setSuccessModalDisplay(true);
        } else if (response.errorCode) {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken
          )
            redirectOnTokenExpiry();
          setFailureModal({
            display: true,
            text: errorDescription(response.errorCode),
          });
        }
      } catch (err) {
        setFailureModal({
          display: true,
          text: errorDescription(error.unexpectedError),
        });
      }
    } else {
      if (!user.hasValidUsername) {
        showAlert("invalidUsername");
      }
      if (!user.hasValidPassword) {
        showAlert("invalidPassword");
      }
      if (user.hasValidPassword && !user.hasValidConfirm_password) {
        showAlert("invalidConfirmPassword");
      }
    }
  };

  const successModalClose = () => {
    setSuccessModalDisplay(true);
    redirectTo(Routes.users.children.viewUsers.path);
  };

  const failureModalClose = () => {
    setFailureModal({ ...failureModal, display: false });
  };

  return (
    <Card>
      {allRoles && (
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Username
                    <small id="invalidUsername" className="text-danger d-none">
                      (Username should be Alphanumeric or can contain [@ OR . OR
                      _ OR -])
                    </small>
                  </Label>
                  <Input
                    type="text"
                    name="username"
                    value={userData.username}
                    placeholder="Username"
                    onFocus={() => hideAlert("invalidUsername")}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Roles</Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name="roles"
                    onChange={handleRoleChange}
                    options={allRoles}
                    isMulti
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Password
                    <small id="invalidPassword" className="text-danger d-none">
                      (Minimum length 6 required)
                    </small>
                  </Label>
                  <Input
                    type="password"
                    name="password"
                    value={userData.password}
                    placeholder="Enter Password"
                    onFocus={() => hideAlert("invalidPassword")}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Confirm Password
                    <small
                      id="invalidConfirmPassword"
                      className="text-danger d-none"
                    >
                      (Password mismatch)
                    </small>
                  </Label>
                  <Input
                    type="password"
                    name="confirm_password"
                    value={userData.confirm_password}
                    placeholder="Re-enter Password"
                    onChange={handleChange}
                    onFocus={() => hideAlert("invalidConfirmPassword")}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary">Submit</Button>
          </Form>
          <ResponseModal
            show={successModalDisplay}
            onHide={successModalClose}
            modalheading={"Success"}
            modaltext={"User created successfully."}
          />
          <ResponseModal
            show={failureModal.display}
            onHide={failureModalClose}
            modalheading={"Error"}
            modaltext={failureModal.text}
          />
        </CardBody>
      )}
    </Card>
  );
};

const CreateUserForm = () => (
  <Container fluid className="p-0">
    <h1 className="h3 mb-3">Create a new User</h1>
    <Row>
      <Col lg="12">
        <UserForm />
      </Col>
    </Row>
  </Container>
);

export default CreateUserForm;

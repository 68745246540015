import React, { useState } from "react";
import { retrieveFromLocalStorage } from "../../../../utilities/localStorageUtil";
import { Config } from "../../../../config";
import {
  redirectToNewTab,
  getStatus,
  getStatusColor,
  getBtnName,
  getButtonColor,
  permissions,
} from "../../../../utilities/commonUtil";
import {
  Badge,
  Card,
  Container,
  CardImg,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";

export const ThemeCard = (props) => {
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );
  const [theme] = useState(props.theme);

  const showDetailsButton = () => {
    return allowedPermissions.includes(permissions.keyboardThemeDetail);
  };

  const showButton = (btnName) => {
    if (btnName === Config.ACTIVATE_BUTTON)
      return allowedPermissions.includes(permissions.keyboardThemeActivate);
    else if (btnName === Config.DEACTIVATE_BUTTON)
      return allowedPermissions.includes(permissions.keyboardThemeDeactivate);
    else if (btnName === Config.PUBLISH_BUTTON)
      return allowedPermissions.includes(permissions.keyboardThemePublish);
    else return false;
  };

  let status = getStatus(theme.publishedAt, theme.deactivatedAt);

  return (
    <Card>
      <React.Fragment>
        {theme.previewImage ? (
          <CardImg src={theme.previewImage} alt="Card image" />
        ) : (
          <div className="theme-preview-image bg-dark"></div>
        )}
      </React.Fragment>
      <CardBody>
        <CardTitle tag="h3" className="mb-0">
          {theme.themeName}
          <Badge className="float-right" color={getStatusColor(status)}>
            {status}
          </Badge>
        </CardTitle>
        <small>ID : {theme.ID}</small>
        <br />
        <strong className="font-weight-bold">{theme.name}</strong>
        <br />
        <small className="font-weight-bold">
          Start Date : {theme.startDate?theme.startDate:"N/A"}
        </small>
        <br />
        <small className="font-weight-bold">
          End Date : {theme.endDate?theme.endDate:"N/A"}
        </small>
        <br />
        <small className="font-weight-bold">
          Created At : {theme.createdAt}
        </small>
        <br />
        <small className="font-weight-bold">
          Updated At : {theme.updatedAt}
        </small>
        <br />
        <small className="font-weight-bold">
          Published At : {theme.publishedAt}
        </small>
        <br />
        <small className="font-weight-bold">
          Deactivated At : {theme.deactivatedAt}
        </small>
        <Container className=" my-4 d-flex justify-content-around" fluid>
          {showDetailsButton() ? (
            <Button
              className="mw-75 w-120 mx-2"
              color="info"
              onClick={() => {
                redirectToNewTab(`/keyboard-theme/${theme.ID}`);
              }}
            >
              Details
            </Button>
          ) : null}
          {showButton(getBtnName(status)) ? (
            <Button
              className="mw-75 w-120 mx-2"
              color={getButtonColor(status)}
              onClick={() => {
                props.getConfirmation(theme.ID, getBtnName(status));
              }}
            >
              {getBtnName(status)}
            </Button>
          ) : null}
        </Container>
      </CardBody>
    </Card>
  );
};

export default ThemeCard;

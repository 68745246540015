import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardImg,
  Col,
  Container,
  Form,
  Label,
  Row,
  Badge,
} from "reactstrap";
import { editGif } from "../../../utilities/apiUtils/gifs";
import { error, errorDescription, redirectOnTokenExpiry, redirectTo } from "../../../utilities/commonUtil";
import ConfirmationModal from "../../../pages/ui-elements/ConfirmationModal";
import ResponseModal from "../../../pages/ui-elements/ResponseModal";
import { fetchGif } from "../../../utilities/apiUtils";

const GifEdit = (props) => {
  const id = props.match.params.id;
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");
  const [isEventGif, setIsEventGif] = useState(false);
  const [gif, setGif] = useState({});

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);

    // if edit - redirect to home
    if (props.match.params.id) {
      redirectTo(`/gifs/${id}`);
    } else {
      redirectTo(`/gifs/${id}`);
    }
  };
  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };


  const getGifDetailsById = async (id) => {
    try {
      let response = await fetchGif(id);
      if (response.gif) {
        setGif(response.gif);
        setIsEventGif(response.gif.isEventGif);
      }

    } catch (err) {
      let text = errorDescription(error.unexpectedError);
      showErrorModal(text);
    }
  };

  useEffect(() => {
    getGifDetailsById(id);
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();
    // convert datetime to ISO string
    const data = {
      "isEventGif": isEventGif,
    };
    try {
      let response = await editGif(id, data);
      if (response.status === "Success") {
        setSuccessModalText("Gif updated successfully");
        setSuccessModalDisplay(true);
      } else {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken ||
          response.errorCode === error.unauthorized
        )
          redirectOnTokenExpiry();
        let text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      }
    } catch (err) {
      let text = errorDescription(error.unexpectedError);
      showErrorModal(text);
    }

  };

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Edit Gif</h1>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Container>
                <Row style={{ justifyContent: "space-between" }}>
                  <Col md={2} lg={3} className="d-block my-auto">
                    <CardImg
                      top
                      src={gif.imageURL}
                      alt="Card image cap"
                    />
                  </Col>
                  <Col md={8} lg={7} className="d-block my-auto">
                    <Row>
                      <Col>
                        <p className="p-0 m-0">
                          {" "}
                          <span className="font-weight-bolder">
                            ID :
                          </span>{" "}
                          {gif.id}
                        </p>
                        
                        <p className="p-0 m-0">
                          {" "}
                          <span className="font-weight-bolder">
                            Event Gif :
                          </span>{" "}
                          {isEventGif ? (
                            <Badge color="success">Yes</Badge>
                          ) : (
                            <Badge color="danger">No</Badge>
                          )}
                        </p>

                      </Col>

                    </Row>
                  </Col>
                </Row>
              </Container>
            </CardBody>


          </Card>
        </Col>

        <Col lg="12">
          <Card>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <Row className="pl-5 pr-5 pb-3">
                  <Col md={6}>
                    <div className="custom-switch custom-control mb-3">
                      <input
                        type="checkbox"
                        id="isEventGif"
                        className="custom-control-input"
                        checked={isEventGif}
                        onChange={() => setIsEventGif(!isEventGif)}
                      />
                      <Label
                        className="custom-control-label"
                        htmlFor="isEventGif"
                      >
                        Event Gif
                      </Label>
                    </div>
                  </Col>
                </Row>
                <Row className="pl-5 pr-5 pb-3">
                      <Col>
                        <Button color="primary" type="submit">
                          Submit
                        </Button>
                      </Col>
                    </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={"submit"}
        onConfirmationTrue={() => {
          setConfirmationModalIsOpen(false);
          handleSubmit();
        }}
        onHide={() => setConfirmationModalIsOpen(false)}
      />
      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={"Success"}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={"Error"}
        modaltext={failureModalText}
      />
    </Container>
  );
};

export default GifEdit;

import React, { Component, useEffect, useState } from "react";
import { Card, CardBody, Container, Button, Badge, Row } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { Link } from "react-router-dom";
import {
  getKeyboardLayouts,
  updateKeyboardLayout,
} from "../../../utilities/apiUtils/keyboardLanguages";
import ResponseModal from "../ResponseModal";
import EditKeyboardLayout from "./EditKeyboardLayout";
import {
  error,
  permissions,
  errorDescription,
  redirectionOnTokenExpiry,
} from "../../../utilities/commonUtil";
import {
  keyboardLayoutTableColumns,
  keyboardLayoutUpdatedStatus,
  keyboardLayoutUpdatedText,
  successModalHeading,
  failureModalHeading,
} from "../../../config/keyboardLanguages";

const { SearchBar } = Search;

const LayoutsTable = () => {
  const [layouts, setLayouts] = useState([]);
  const [column] = useState(keyboardLayoutTableColumns);
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [editFormDisplay, setEditFormDisplay] = useState(false);
  const [editData, setEditData] = useState({});
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  useEffect(() => {
    fetchKeyboardLayouts();
  }, []);

  let fetchKeyboardLayouts = async () => {
    try {
      const response = await getKeyboardLayouts();
      if (response.errorCode) {
        let error_code = errorDescription(response.errorCode);
        showErrorModal(error_code);
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken ||
          response.errorCode === "Unauthorized"
        )
          redirectionOnTokenExpiry();
      } else if (response.keyboardLayouts) {
        setLayouts(response.keyboardLayouts);
      }
    } catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  let successModalClose = () => {
    setSuccessModalDisplay(false);
    setEditFormDisplay(false);
    fetchKeyboardLayouts();
  };

  let showErrorModal = (error_code) => {
    setFailureModalText(error_code);
    setFailureModalDisplay(true);
  };

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let showEditButton = () => {
    return allowedPermissions.includes(permissions.keyboardLayoutEdit);
  };

  let showEditForm = (row) => {
    setEditFormDisplay(true);
    let keyboardLayout = {
      deviceType: row.deviceType,
      id: row.id,
      identifier: row.identifier,
      minAppVersion: row.minAppVersion,
      minBobbleSDKVersion: row.minBobbleSDKVersion,
      xmlResourceFile: row.xmlResourceFile,
      additionalDetails: row.additionalDetails,
      xmlResourceURL: row.xmlResourceURL,
    };
    setEditData(keyboardLayout);
  };

  let hideEditForm = () => {
    setEditFormDisplay(false);
  };

  let editKeyboardLayout = async (updatedKeyboardLayout, id) => {
    try {
      const response = await updateKeyboardLayout(updatedKeyboardLayout, id);

      if (!response.errorCode) {
        showSuccessModal(keyboardLayoutUpdatedText);
      } else {
        showErrorModal(response.errorDescription);
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken ||
          response.errorCode === "Unauthorized"
        )
          redirectionOnTokenExpiry();
      }
    } catch (err) {
      showErrorModal(error.unexpectedError);
    }
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <ul>
          <li>{`Created At : ${row.createdAt}`}</li>
          <li>{`Updated At : ${row.updatedAt}`}</li>
        </ul>
        {showEditButton() ? (
          <Button
            color="primary"
            className="mx-3"
            onClick={() => showEditForm(row)}
          >
            Edit
          </Button>
        ) : null}
        {Object.keys(editData).length > 0 ? (
          <EditKeyboardLayout
            show={editFormDisplay}
            onHide={hideEditForm}
            data={editData}
            edit={editKeyboardLayout}
          />
        ) : null}
      </div>
    ),
  };

  return (
    <Card>
      <CardBody>
        <ToolkitProvider keyField="id" data={layouts} columns={column} search>
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} />
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                bordered={false}
                keyField="id"
                data={layouts}
                columns={column}
                expandRow={expandRow}
                pagination={paginationFactory({
                  sizePerPage: 10,
                  sizePerPageList: [5, 10, 25, 50],
                })}
              />
            </div>
          )}
        </ToolkitProvider>
        <ResponseModal
          show={successModalDisplay}
          onHide={successModalClose}
          modalheading={successModalHeading}
          modaltext={successModalText}
        />
        <ResponseModal
          show={failureModalDisplay}
          onHide={failureModalClose}
          modalheading={failureModalHeading}
          modaltext={failureModalText}
        />
      </CardBody>
    </Card>
  );
};

const KeyboardLayouts = (props) => {
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  const showCreateKeyboardLayoutButton = () => {
    return allowedPermissions.includes(permissions.keyboardLayoutCreate);
  };

  return (
    <Container fluid className="p-0">
      <Row>
        <h1 className="mb-4 mt-2 w-50 pl-3">Keyboard Layouts</h1>
        {showCreateKeyboardLayoutButton() ? (
          <Link
            className="d-block ml-auto mb-4 pr-3 text-decoration-none"
            to={{
              pathname: `/kl/create-keyboard-layout`,
            }}
          >
            <Button color="primary" className="ml-auto d-flex">
              <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                Add Keyboard Layout
              </p>
            </Button>
          </Link>
        ) : null}
      </Row>
      <Row></Row>
      <LayoutsTable />
    </Container>
  );
};

export default KeyboardLayouts;

import React, { useState, useEffect, useCallback, Fragment } from "react"
import {
    CardHeader,
    Card,
    CardBody,
    CardImg,
    Col,
    Row,
    Container,
    CardFooter,
    Button,
    Badge,
} from "reactstrap";
import { emojiStickerDetails } from "../../../utilities/apiUtils/emojiStickers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../../components/Loading";
import { fas, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import {
    getStatus,
    errorDescription,
    error,
    permissions,
    redirectTo,
    redirectOnTokenExpiry,
} from "../../../utilities/commonUtil";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";

const EmojiStickerDetails = (props) => {
    const allowedPermissions = retrieveFromLocalStorage("userPermissions");

    const emojiID = props.match.params.id
    const [hasResponseError, setHasResponseError] = useState(false);
    const [emojiStickerDetail, setEmojiStickerDetail] = useState({})
    const [emojiStickerImages, setEmojiStickerImages] = useState([])
    const [errorText, setErrorText] = useState("")
    const [loading, setLoading] = useState(true)
    const [failureModalDisplay, setFailureModalDisplay] = useState(false)
    const [failureModalText, setFailureModalText] = useState("")

    useEffect(() => {
        const fetchEmojiStickerDetail = async (emojiID) => {
            try {
                let res = await emojiStickerDetails(emojiID)
                if (!res.errorCode) {
                    setHasResponseError(false)
                    setEmojiStickerDetail(res.emojiSticker)
                    setEmojiStickerImages(res.emojiStickerImages)
                } else {
                    if (
                        res.errorCode === error.tokenExpired ||
                        res.errorCode === error.invalidAccessToken
                    )
                        redirectOnTokenExpiry();
                    setHasResponseError(true)
                    setErrorText(errorDescription(res.errorCode))
                }
                setLoading(false)
            } catch (err) {
                setLoading(false);
                setFailureModalText(errorDescription(error.unexpectedError))
                setFailureModalDisplay(true);
            }
        }
        fetchEmojiStickerDetail(emojiID)
    }, [emojiID])

    const redirectToEmojiImageDetails = (imageId) => {
        redirectTo(`/sticker/emoji-stickers/${emojiStickerDetail.emojiId}/image/${imageId}`)
    }

    return (
        <Container fluid>

            {
                loading && !failureModalDisplay ? (<Loading />) :
                    <Fragment>
                        <h1 className="h2">{emojiStickerDetail.name ?? null}</h1>
                        <Card>
                            <CardBody>
                                <Container>
                                    <Row style={{ justifyContent: "space-between" }}>
                                        <Col md={2} lg={3} className="d-block my-auto">
                                            <p className="d-block mx-auto mb-0"
                                                style={{ maxWidth: "max-content", fontSize: "140px" }}>
                                                {emojiStickerDetail.emoji ?? null}
                                            </p>
                                        </Col>
                                        <Col md={8} lg={7} className="d-block my-auto">
                                            <Row>
                                                <Col sm={6} md={4} lg={3}>
                                                    <p className="font-weight-bolder p-0 m-0">Emoji Id:</p>
                                                    <p className="font-weight-bolder p-0 m-0">Emoji Name:</p>
                                                    <p className="font-weight-bolder p-0 m-0">Created At:</p>
                                                    <p className="font-weight-bolder p-0 m-0">Updated At:</p>
                                                    <p className="font-weight-bolder p-0 m-0 ">Tags :</p>
                                                </Col>
                                                <Col sm={6} md={8} lg={9}>
                                                    <p className="p-0 m-0">{emojiStickerDetail.emojiId ?? null}</p>
                                                    <p className="p-0 m-0">{emojiStickerDetail.name ?? null}</p>
                                                    <p className="p-0 m-0">{emojiStickerDetail.createdAt ?? null}</p>
                                                    <p className="p-0 m-0">{emojiStickerDetail.updatedAt ?? null}</p>
                                                    <p className="p-0 m-0">
                                                        {emojiStickerDetail.tags  && Object.values(emojiStickerDetail.tags).map(
                                                            (tag, index) => (
                                                                <Badge
                                                                    key={index}
                                                                    className="badge-secondary ml-2 mb-2 p-1"
                                                                >
                                                                    {tag.text}
                                                                </Badge>
                                                            )
                                                        )}
                                                    </p>

                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Container>
                            </CardBody>
                        </Card>

                        <Row style={{ justifyContent: "space-between" }} className="px-3">
                            <h2 className="h3">Images</h2>
                            {allowedPermissions.includes(permissions.uploadEmojiStickerImage) ? <Button color="primary" onClick={(e) => redirectTo(`/sticker/emoji-stickers/${emojiStickerDetail.emojiId}/addImage`)}>
                                {"Add Image "}
                            </Button> : null}
                        </Row><br />
                        <Row>
                            {
                                emojiStickerImages.length === 0 ? (
                                    <Container fluid>
                                        <h1 style={{ maxWidth: "max-content" }} className="h2 d-block mx-auto">No Images Added</h1>
                                    </Container>
                                )
                                    : (emojiStickerImages.map((element, index) => {
                                        return (
                                            <>
                                                <Col sm={4}>
                                                    <Card key={index}>
                                                        <React.Fragment>
                                                            <CardImg top
                                                                src={element.webpURL != null ? element.webpURL : element.pngURL}
                                                            ></CardImg>
                                                        </React.Fragment>
                                                        <CardBody>
                                                            <span>
                                                                <strong>Image ID: </strong>
                                                                {element.imageId ? element.imageId : "Not Available"}
                                                            </span><br />
                                                            <span>
                                                                <strong>Watermark Support: </strong>
                                                                {element.watermarkSupport ? "Available" : "Not Available"}
                                                            </span><br />
                                                            <span>
                                                                <strong>Start Time: </strong>
                                                                {element.startTime ? element.startTime.split(" ")[0] : "Not Avaiable"}
                                                            </span><br />
                                                            <span>
                                                                <strong>End Time: </strong>
                                                                {element.endTime ? element.endTime.split(" ")[0] : "Not Avaiable"}
                                                            </span><br />
                                                            <span>
                                                                <strong>Event Type: </strong>
                                                                {element.eventType}
                                                            </span><br />
                                                            <span>
                                                                <strong>Is Smart Bigmoji: </strong>
                                                                {element.isSmartBigmoji? "Yes": "No"}
                                                            </span><br />
                                                            <br />
                                                            {element.webpURL == null && element.imageProcessingStatus === "ongoing" ? (
                                                                <Badge style={{ whiteSpace: "initial" }}>
                                                                    Image under processing
                                                                </Badge>
                                                            ) : null}
                                                            {element.webpURL == null && element.imageProcessingStatus === "not_started" ? (
                                                                <Badge style={{ whiteSpace: "initial" }}>
                                                                    Processing not started
                                                                </Badge>
                                                            ) : null}
                                                        </CardBody>
                                                        <CardFooter>
                                                            <Row style={{ justifyContent: "space-between" }}>
                                                                <Button color="info"
                                                                    className="d-block mx-auto" onClick={(e) => redirectToEmojiImageDetails(element.imageId)}>Details
                                                                    <FontAwesomeIcon
                                                                        icon={(fas, faInfoCircle)}
                                                                        className="ml-2"
                                                                    ></FontAwesomeIcon>
                                                                </Button>
                                                            </Row>
                                                        </CardFooter>
                                                    </Card>
                                                </Col>
                                            </>
                                        )
                                    })
                                    )}
                        </Row>
                    </Fragment>
            }
        </Container>
    )
}
export default EmojiStickerDetails
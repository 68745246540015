import React, { useState } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Input,
    Label,
    FormGroup,
    Button
} from "reactstrap"
import { addEmojiSticker } from "../../../utilities/apiUtils/emojiStickers"
import ResponseModal from "../ResponseModal";
import { error, redirectionOnTokenExpiry, redirectTo } from "../../../utilities/commonUtil"

const AddEmoji = () => {

    const [emoji, setEmoji] = useState("")
    const [emojiName, setEmojiName] = useState("")
    const [successModalDisplay, setSuccessModalDisplay] = useState(false)
    const [successModalText, setSuccessModalText] = useState("")
    const [failureModalDisplay, setFailureModalDisplay] = useState(false)
    const [failureModalText, setFailureModalText] = useState("")

    const handleEmojiChange = (e) => {
        setEmoji(e.target.value);
    }
    const handleEmojiName = (e) => {
        setEmojiName(e.target.value);
    }
    const handleSubmit = async (e) => {
        let body = {
            "emoji": emoji,
            "emojiName": emojiName
        }
        try {
            let res = await addEmojiSticker(body)
            if (res.errorCode !== undefined) {
                if (res.errorCode === error.tokenExpired || res.errorCode === error.invalidAccessToken)
                    redirectionOnTokenExpiry()
                else {
                    setFailureModalText(res.errorDescription)
                    setFailureModalDisplay(true)
                }
            } else {
                setSuccessModalText("Successfully Added the emoji")
                setSuccessModalDisplay(true)
            }
        } catch (err) {
            setFailureModalText(err)
            setFailureModalDisplay(true)
        }

    }

    return (
        <Container fluid>
            <h2 className="h3">Add BigMoji</h2>
            <Card className="p-2">
                <CardBody>
                    <Form onSubmit={(e) => { e.preventDefault(); handleSubmit() }}>
                        <Row form style={{ justifyContent: "space-between" }}>
                            <Col md={6} className="px-3">
                                <FormGroup>
                                    <Label>
                                        <strong style={{ fontSize: "15px" }}>Emoji</strong>
                                    </Label>
                                    <Input type="text"
                                        name="emojiValue"
                                        value={emoji}
                                        placeholder="Emoji"
                                        required
                                        minlength="1"
                                        onChange={handleEmojiChange}
                                    ></Input>
                                </FormGroup>
                            </Col>
                            <Col md={6} className="px-3">
                                <FormGroup>
                                    <Label>
                                        <strong style={{ fontSize: "15px" }}>Emoji Name</strong>
                                    </Label>
                                    <Input type="text"
                                        name="emojiName"
                                        value={emojiName}
                                        placeholder="Emoji Name"
                                        required
                                        onChange={handleEmojiName}
                                    ></Input>
                                </FormGroup>
                            </Col>
                        </Row><br />
                        <Button type="submit" className="d-block mx-auto" color="primary">Submit</Button>
                    </Form>
                </CardBody>
            </Card>
            <ResponseModal
                show={successModalDisplay}
                onHide={(e) => redirectTo("/stickers/emoji-stickers")}
                modalheading={"Success"}
                modaltext={successModalText}
            />
            <ResponseModal
                show={failureModalDisplay}
                onHide={(e) => setFailureModalDisplay(false)}
                modalheading={"Error"}
                modaltext={failureModalText}
            />
        </Container>
    )
}
export default AddEmoji
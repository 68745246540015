//Config 
export const success = "success";
export const create = "create";

export const created = "Created";
export const ongoing = "Ongoing";
export const completed = "Completed";

export const successModalHeading = "Success";
export const errorModalHeading = "Error";
export const reorderSuccess = "Successfully Reordered";
export const publishSuccess = "Successfully Published";
export const deactivateSuccess = "Successfully Deactivated";
export const activateSuccess = "Successfully Activated";

// Status options for status filter
export const statusOptions = [
  { value: "", label: "All" },
  { value: "Unpublished", label: "Unpublished" },
  { value: "Deactivated", label: "Deactivated" },
  { value: "Published", label: "Published" },
];

export const promptTypes = {
  prompt: "prompt",
  suggestionDrawer: "suggestionDrawer",
  aiPoweredBar: "aiPoweredBar",
  popText: "popText",
  scorebar: "scorebar",
  aiClipboard: "aiClipboard",
  pills: "pills"
}
// change in case of bobble
export const dashboard = "bobble";

// Custom Audience Targeting Supported Modules
export const customAudienceTargetingSupportedModules = [
  "keyboard-prompt",
  "smart-suggestions",
  "quick-reply/targeting-rules",
  "carousel"
]

export const userAttributeRuleFilterOptions = [
  { label: "Premium User Status", value: "flt_premium_user" },
  { label: "Premium Stickers", value: "flt_premium_stickers" },
  { label: "Premium Purchased Product", value: "flt_premium_purchased_product" },
  { label: "Premium Recommended Product", value: "flt_premium_recommended_product" },
  { label: "Segment", value: "flt_segments" },
  { label: "Blacklist Segment", value: "flt_blacklist_segments" },
];

export let processDateAndStatus = (item) => {
  if (item.published_at !== null) {
    item.published_at = new Date(item.published_at).toLocaleString();
    if (item.published_at === "Invalid Date") {
      item.published_at = null;
    }
  }
  if (item.deactivated_at !== null) {
    item.deactivated_at = new Date(item.deactivated_at).toLocaleString();
    if (item.deactivated_at === "Invalid Date") {
      item.deactivated_at = null;
    }
  }
  if (
    (item.published_at === null || item.published_at === "Invalid Date") &&
    item.deactivated_at === null
  ) {
    item.status = "Unpublished";
  }
  if (
    item.published_at !== null &&
    item.published_at !== "Invalid Date" &&
    item.deactivated_at === null
  ) {
    item.status = "Activated";
  }
  if (item.published_at !== null && item.deactivated_at !== null) {
    item.status = "Deactivated";
  }
};

// Server Ad Versions Targeting Supported Modules
export const serverAdVersionsTargetingSupportedModules = [
  "smart-suggestions",
]

// Session Logging Targeting Supported Modules
export const sessionLoggingTargetingSupportedModules = [
  "smart-suggestions",
]
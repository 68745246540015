import React, { Component, useState } from "react";
import { Modal } from "react-bootstrap";
import { Button, Row, Form, Col, Label, FormGroup, Input } from "reactstrap";
import { validateCode, validateJSON } from "../../../utilities/commonUtil";

const EditLanguage = (props) => {
  const [formTextState, setFormTextState] = useState({
    id: props.data.id,
    name: props.data.name,
    nativeName: props.data.nativeName,
    code: props.data.code,
    territory: props.data.territory,
    vocabulary:
      props.data.vocabulary.length > 0
        ? JSON.stringify(props.data.vocabulary)
        : "",
  });

  const handleChange = (event) => {
    hideAlert("invalidTerritoryAlert");
    hideAlert("invalidCodeAlert");
    const { name, value } = event.target;
    setFormTextState((prev) => ({ ...prev, [name]: value }));
  };

  const handleTagChange = (event) => {
    hideAlert("invalidVocabularyAlert");
    const { value } = event.target;
    setFormTextState((prev) => ({ ...prev, vocabulary: value }));
  };

  const showAlert = (elementId) => {
    document.getElementById(elementId).classList.remove("d-none");
  };

  const hideAlert = (elementId) => {
    document.getElementById(elementId).classList.add("d-none");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateCode(formTextState.code)) {
      showAlert("invalidCodeAlert");
    } else if (!validateJSON(formTextState.vocabulary)) {
      showAlert("invalidVocabularyAlert");
    } else {
      let updatedLanguage = new FormData();
      updatedLanguage.append("name", formTextState.name);
      if (
        formTextState.nativeName != null &&
        formTextState.nativeName.length > 0
      ) {
        updatedLanguage.append("nativeName", formTextState.nativeName);
      }
      if (
        formTextState.territory != null &&
        formTextState.territory.length > 0
      ) {
        updatedLanguage.append("territory", formTextState.territory);
      }
      updatedLanguage.append("code", formTextState.code);
      if (formTextState.vocabulary.length > 0) {
        formTextState.vocabulary = JSON.parse(formTextState.vocabulary);
        formTextState.vocabulary.forEach((vocab, index) => {
          updatedLanguage.append(`vocabulary[${index}]`, vocab);
        });
      }
      props.edit(updatedLanguage, formTextState.id);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Language
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Name <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="name"
                  placeholder="Enter language name"
                  defaultValue={formTextState.name}
                  onChange={(event) => handleChange(event)}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Native name</Label>
                <Input
                  type="text"
                  name="nativeName"
                  placeholder="Enter native name"
                  defaultValue={formTextState.nativeName}
                  onChange={(event) => handleChange(event)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Code <span className="text-danger">*</span>
                  <small id="invalidCodeAlert" className="text-danger d-none">
                    (Enter a valid code)
                  </small>
                </Label>
                <Input
                  type="text"
                  name="code"
                  placeholder="Enter code"
                  defaultValue={formTextState.code}
                  onChange={(event) => handleChange(event)}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Territory
                  <small
                    id="invalidTerritoryAlert"
                    className="text-danger d-none"
                  >
                    (Enter a valid territory)
                  </small>
                </Label>
                <Input
                  type="text"
                  name="territory"
                  placeholder="Enter territory"
                  defaultValue={formTextState.territory}
                  onChange={(event) => handleChange(event)}
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label>Vocabulary </Label>
                <small
                  id="invalidVocabularyAlert"
                  className="text-danger d-none"
                >
                  (Vocabulary must be a valid json array)
                </small>
                <Input
                  type="textarea"
                  name="vocabulary"
                  onChange={(event) => handleTagChange(event)}
                  style={{ height: "6rem" }}
                  placeholder="Vocabulary"
                  // value={JSON.stringify(formTextState.vocabulary)}
                  value={formTextState.vocabulary}
                  defaultValue={null}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Button color="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditLanguage;

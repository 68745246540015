import React, { useState } from "react";
import {
  Button,
  Container,
  Card,
  CardBody,
  Row,
  Form,
  Col,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import {
  error,
  errorDescription,
  redirectionOnTokenExpiry,
  redirectTo,
  validateMinBobbleSDKVersionKeyboardLanguages,
  validateMinAppVersionKeyboardLanguages,
  validateJSON,
  validateJSONString,
} from "../../../utilities/commonUtil";
import { createKeyboardLayout } from "../../../utilities/apiUtils/keyboardLanguages";
import ResponseModal from "../ResponseModal";
import Routes from "../../../routes/index";
import {
  keyboardLayoutCreatedStatus,
  keyboardLayoutCreatedText,
  invalidMinAppVersionAlertElementId,
  invalidMinBobbleSDKVersionAlertElementId,
  invalidMinAppVersionAlertText,
  invalidAdditionalDetailsElementId,
  invalidAdditionalDetailsAlertText,
  invalidMinBobbleSDKVersionAlertText,
  successModalHeading,
  failureModalHeading,
  deviceOptions,
} from "../../../config/keyboardLanguages";
import Select from "react-select";

const CreateKeyboardLayout = () => {
  const [device, setDevice] = useState(deviceOptions[0]);
  const [isIOSSelected, setIsIOSSelected] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [resourceFile, setresourceFile] = useState(null);
  const [formTextState, setFormTextState] = useState({
    identifier: "",
    deviceType: "android",
    minAppVersion: "",
    minBobbleSDKVersion: "",
    xmlResourceFile: "",
    additionalDetails: "",
  });
  const [successModalState, setSuccessModalState] = useState({
    successModalDisplay: false,
    successModalText: "",
  });
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });

  const handleFormTextChange = (event) => {
    const { name, value } = event.target;
    hideAlert(invalidMinAppVersionAlertElementId);
    setFormTextState((prev) => ({ ...prev, [name]: value }));
  };
  let handleDevice = (value) => {
    if (value.value && value.value === "iOS") {
      setIsIOSSelected(true);
    } else {
      setIsIOSSelected(false);
    }
    setDevice(value);
    setFormTextState({ ...formTextState, deviceType: value.value });
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    const file = event.target.files[0];
    setSelectedFileName(file ? file.name : "");
    setresourceFile(file ? file.name : "");
    setFormTextState((prev) => ({ ...prev, [name]: files[0] }));
  };

  const handleAdditionalDetails = (event) => {
    const { name, value } = event.target;
    setFormTextState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let isValid = validateMinAppVersionKeyboardLanguages(
      formTextState.minAppVersion
    );
    if (isValid !== true) {
      showAlert(invalidMinAppVersionAlertElementId);
      return;
    }

    let isSDKValid = validateMinBobbleSDKVersionKeyboardLanguages(
      formTextState.minBobbleSDKVersion
    );
    if (isSDKValid !== true) {
      showAlert(invalidMinBobbleSDKVersionAlertElementId);
      return;
    }

    if (!validateJSONString(formTextState.additionalDetails)) {
      showAlert(invalidAdditionalDetailsElementId);
      return;
    }
    let newKeyboardLayout = new FormData();
    newKeyboardLayout.append("identifier", formTextState.identifier);
    newKeyboardLayout.append("minAppVersion", formTextState.minAppVersion);
    newKeyboardLayout.append(
      "minBobbleSDKVersion",
      formTextState.minBobbleSDKVersion
    );
    newKeyboardLayout.append("deviceType", formTextState.deviceType);
    newKeyboardLayout.append("xmlResourceFile", formTextState.xmlResourceFile);
    newKeyboardLayout.append(
      "additionalDetails",
      formTextState.additionalDetails
    );

    try {
      const response = await createKeyboardLayout(newKeyboardLayout);
      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectionOnTokenExpiry();
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: response.errorDescription,
        });
      } else if (response.status === keyboardLayoutCreatedStatus) {
        setSuccessModalState({
          successModalDisplay: true,
          successModalText: keyboardLayoutCreatedText,
        });
      }
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const failureModalClose = () => {
    setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
  };

  const successModalClose = () => {
    setSuccessModalState((prev) => ({ ...prev, successModalDisplay: false }));
    redirectTo(Routes.keyboardLanguages.children.keyboardLayouts.path);
  };

  const showAlert = (elementId) => {
    document.getElementById(elementId).classList.remove("d-none");
  };

  const hideAlert = (elementId) => {
    document.getElementById(elementId).classList.add("d-none");
  };

  return (
    <Container fluid className="p-0">
      <h3 className="mb-3">Create Keyboard Layout</h3>
      <Card className="mb-0">
        <CardBody>
          <Form onSubmit={(event) => handleSubmit(event)}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Identifier <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="identifier"
                    placeholder="Enter keyboard layout identifier"
                    defaultValue={formTextState.identifier}
                    onChange={(event) => handleFormTextChange(event)}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Min App Version <span className="text-danger">*</span>
                    <small
                      id="invalidMinAppVersionAlert"
                      className="text-danger d-none"
                    >
                      ({invalidMinAppVersionAlertText})
                    </small>
                  </Label>
                  <Input
                    type="number"
                    name="minAppVersion"
                    placeholder="Enter min app version"
                    defaultValue={formTextState.minAppVersion}
                    onChange={(event) => handleFormTextChange(event)}
                    required
                  />
                </FormGroup>
              </Col>

              {/* Min Bobble SDK  */}
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Min Bobble SDK Version
                    <span className="text-danger">*</span>
                    <small
                      id="invalidMinBobbleSDKVersionAlert"
                      className="text-danger d-none"
                    >
                      ({invalidMinBobbleSDKVersionAlertText})
                    </small>
                  </Label>
                  <Input
                    type="number"
                    name="minBobbleSDKVersion"
                    placeholder="Enter min SDK version"
                    defaultValue={formTextState.minBobbleSDKVersion}
                    onChange={(event) => handleFormTextChange(event)}
                    required
                  />
                </FormGroup>
              </Col>

              {/* Device Type */}
              <Col md={6} className="">
                <Label>
                  Device Type <span className="text-danger">*</span>
                </Label>
                <Select
                  className="react-select-container"
                  id="statusSelect"
                  classNamePrefix="react-select"
                  name="deviceType"
                  placeholder="Select Device"
                  value={device}
                  // defaultValue={deviceOptions[0]}
                  onChange={handleDevice}
                  options={deviceOptions}
                />
              </Col>

              {isIOSSelected ? (
                <>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        <strong>
                          XML Resource
                          <small className="text-info">(Choose a file)</small>
                        </strong>
                      </Label>
                      <Container className="custom-file">
                        <Input
                          type="file"
                          className="custom-file-input"
                          name="xmlResourceFile"
                          accept=".zip"
                          onChange={handleFileChange}
                          // required
                        />
                        <Label className="custom-file-label" id="backgroundImg">
                          {resourceFile === null
                            ? "Choose a File"
                            : "File: " + selectedFileName}
                        </Label>
                      </Container>
                    </FormGroup>
                  </Col>

                  <Col md={12}>
                    <FormGroup>
                      <Label>Additional Details </Label>
                      <small
                        id={invalidAdditionalDetailsElementId}
                        className="text-danger d-none"
                      >
                        ({invalidAdditionalDetailsAlertText})
                      </small>
                      <Input
                        type="textarea"
                        name="additionalDetails"
                        onChange={handleAdditionalDetails}
                        style={{ height: "6rem" }}
                        placeholder="Additional Details"
                        value={formTextState.additionalDetails}
                        // required

                        // defaultValue={null}
                      />
                    </FormGroup>
                  </Col>
                </>
              ) : null}
            </Row>
            <Button color="primary" type="submit">
              Submit
            </Button>
          </Form>
        </CardBody>
      </Card>
      <ResponseModal
        show={successModalState.successModalDisplay}
        onHide={() => successModalClose()}
        modalheading={successModalHeading}
        modaltext={successModalState.successModalText}
      />
      <ResponseModal
        show={failureModalState.failureModalDisplay}
        onHide={() => failureModalClose()}
        modalheading={failureModalHeading}
        modaltext={failureModalState.failureModalText}
      />
    </Container>
  );
};

export default CreateKeyboardLayout;

import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Card,
  CardImg,
  CardBody,
  Button,
  Badge,
  Input,
  Label
} from "reactstrap";
import ShareTextsTable from "./components/ShareTextsTable";
import { getEmojiStickerImageShareText } from "../../../utilities/apiUtils/emojiStickers";
import ManageEmojiStickerImageShareTexts from "./ManageEmojiStickerImageShareText";
import {
  error,
  redirectOnTokenExpiry,
  redirectTo,
  permissions,
  errorDescription,
} from "../../../utilities/commonUtil";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import {
  emojiStickerImageDetails,
  getEmojiStickerImageRules,
  updateEmojiStickerImageRules,
} from "../../../utilities/apiUtils/emojiStickers";
import EmojiStickerImageTargetingRules from "./EmojiStickerImageTargetingRules";

const EmojiStickerImage = (props) => {
  const allowedPermissions = retrieveFromLocalStorage("userPermissions");
  const emojiID = props.match.params.emojiID;
  const emojiImageID = props.match.params.emojiImageID;

  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(true);
  const [hasResponseError, setHasResponseError] = useState(false);
  const [emojiImageDetails, setEmojiImageDetails] = useState({});
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [showEmojiStickerImageShareText,setEmojiStickerImageShareText] = useState(false);
  const [showEnableShareTextInKeyboard,setEnableShareTextInKeyboard] = useState();
  const [shareTexts,setShareTexts] = useState({});
  const [emojiStickerImageID,setEmojiStickerImageID] = useState(0);
  useEffect(() => {
    fetchEmojiStickerImageShareText(emojiImageID)
    const fetchEmojiImageDetails = async (emojiID, emojiImageID) => {
      try {
        let res = await emojiStickerImageDetails(emojiID, emojiImageID);
        if (res) {
          setHasResponseError(false);
          setEmojiImageDetails(res.emojiStickerImage);
        } else {
          if (
            res.errorCode === error.tokenExpired ||
            res.errorCode === error.invalidAccessToken
          )
            redirectOnTokenExpiry();
          setErrorText(errorDescription(res.errorCode));
          setHasResponseError(true);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setFailureModalText(errorDescription(error.unexpectedError));
        setFailureModalDisplay(true);
      }
    };
    setEmojiStickerImageID(props.match.params.emojiImageID)
    fetchEmojiImageDetails(
      props.match.params.emojiID,
      props.match.params.emojiImageID
    );
  }, [props]);


  const closeCreateEmojiStickerImageShareText = () => {
    setEmojiStickerImageShareText(false)
  };
  const fetchEmojiStickerImageShareText = async (EmojiStickerImageId) => {
    if (EmojiStickerImageId != null) {
      await getEmojiStickerImageShareText(props.match.params.emojiID,EmojiStickerImageId)
        .then((response) => {
          if (response) {

            let shareTexts = response.shareTexts
            setShareTexts(shareTexts)
            let enableShareTextInKeyboard = response.enableShareTextInKeyboard
            setEnableShareTextInKeyboard(enableShareTextInKeyboard)
          } else if (response.errorCode) {
            if (
              response.errorCode === error.tokenExpired ||
              response.errorCode === error.invalidAccessToken
            ) {
              redirectOnTokenExpiry();
            } else {
              setHasResponseError(true)
              setFailureModalText(errorDescription(response.errorCode))
            }
          }
        })
        .catch((err) => {
          setLoading(false)
          setFailureModalDisplay(true)
          setFailureModalText(errorDescription(error.unexpectedError))
        });

    }
  }
  const showEditShareTextButton = () => {
    return allowedPermissions.includes(permissions.emojiStickerImageShareTextUpdate)
  }
  const updateRulesAllowed = () => {
    return allowedPermissions.includes(permissions.emojiStickerImageRuleUpdate);
  };

  return (
    <Container>
      <h2 className="h3">Bigmoji-Image Details</h2>
      <Card>
        <React.Fragment>
          <CardImg
            top
            style={{
              width: "24rem",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "1rem",
              marginTop: "1rem",
            }}
            src={emojiImageDetails.webpURL ?? emojiImageDetails.pngURL ?? null}
          ></CardImg>
        </React.Fragment>
        <CardBody>
          <Row className="px-2 w-75 m-auto">
            <Col md={6}>
              <span>
                <strong>Image ID: </strong>
                {emojiImageDetails.imageId
                  ? emojiImageDetails.imageId
                  : "Not Available"}
              </span>
              <br />
              <span>
                <strong>Emoji Sticker ID:</strong>
                {" " + emojiImageDetails.emojiStickerId}
              </span>
              <br />
              <span>
                <strong>Watermark Support: </strong>
                {emojiImageDetails.watermarkSupport
                  ? "Available"
                  : "Not Available"}
              </span>
              <br />
              <span>
                <strong>Image Processing Status: </strong>
                {emojiImageDetails.imageProcessingStatus
                  ? emojiImageDetails.imageProcessingStatus
                  : "Not Available"}
              </span>
              <br />
              <span>
                <strong>Created At:</strong>
                {" " + emojiImageDetails.createdAt}
              </span>
              <br />
              <span>
                <strong>Updated At:</strong>
                {" " + emojiImageDetails.updatedAt}
              </span>
              <br />
            </Col>
            <br />
            <Col md={6}>
              <span>
                <strong>Event Type: </strong>
                {emojiImageDetails.eventType}
              </span>
              <br />
              <span>
                <strong>Start Time: </strong>
                {emojiImageDetails.startTime
                  ? emojiImageDetails.startTime.split(" ")[0]
                  : "Not Avaiable"}
              </span>
              <br />
              <span>
                <strong>End Time: </strong>
                {emojiImageDetails.endTime
                  ? emojiImageDetails.endTime.split(" ")[0]
                  : "Not Available"}
              </span>
              <br />
              <span>
                <strong>Is Smart Bigmoji: </strong>
                {emojiImageDetails.isSmartBigmoji? "Yes"
                  : "No"}
              </span>
              <br />
              <span>
                <strong>Priority: </strong>
                {emojiImageDetails.priority
                  ? emojiImageDetails.priority
                  : "Not Available"}
              </span>
              <br />
            </Col>
            <br />
          </Row>
          <div className="mt-4">
            {allowedPermissions.includes(permissions.updateEmojiStickerImages) ? <Button
              color="info"
              className="d-block mx-auto"
              onClick={(e) =>
                redirectTo(
                  `/sticker/emoji-stickers/${emojiID}/image/${props.match.params.emojiImageID}/update`
                )
              }
            >
              Edit
            </Button> : null}
          </div>
        </CardBody>
      </Card>
      <Card>
      <CardBody>
          <Container fluid className="p-0">
            <Row>
              <Col xs={6}>
                <h3 className="mb-4 mt-2 w-50 pl-3" style={{ padding: "0 ", display: "flex", alignItems: "left" }}>Share Texts</h3>
              </Col>
              <Col xs={6} style={{ padding: "0px 5% 0px 0px", display: "flex", alignItems: "center" }}>
                {showEditShareTextButton() ? <Button color="primary" className="ml-auto d-flex" style={{ padding: "0px 10px 0px 10px" }} onClick={() => setEmojiStickerImageShareText(true)}>
                    <p className="mb-1 mt-1" style={{ fontSize: "1rem", margin: "0" }}>
                      Edit
                    </p>
                  </Button> : null
                }
                {showEmojiStickerImageShareText=== true && (
                  <ManageEmojiStickerImageShareTexts
                    show={showEmojiStickerImageShareText}
                    onHide={closeCreateEmojiStickerImageShareText}
                    shareTexts={shareTexts}
                    enableShareTextInKeyboard={showEnableShareTextInKeyboard}
                    emojiID = {props.match.params.emojiID}
                    emojiImageID={props.match.params.emojiImageID}
                    action={"edit"}
                  />
                )}
              </Col>
            </Row>
            <Row className="mb-3" style={{ padding: '0px 50px 0px 0px', margin: '0px 50px 0px 0px ' }}>
              <Col xs={12} className="d-flex align-items-left" >
                <Input
                  type="checkbox"
                  name="enableShareTextInKeyboard"
                  checked={showEnableShareTextInKeyboard}
                  disabled="true"
                  style={{ padding: '0px 50px 0px 0px', margin: '15px 50px 0px 0px ' }}
                />
                <Label
                  className="mb-4 mt-2"
                  style={{ fontSize: "16px", padding: '0px 50px 0px 30px', margin: '0px' }}
                >
                  Enable Share Text in Keyboard
                </Label>
              </Col>
            </Row>

            <ShareTextsTable shareTexts={shareTexts} ></ShareTextsTable>
          </Container>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Row className="px-3">
            <EmojiStickerImageTargetingRules
              showAgeGenderRules={true}
              emojiId={emojiID}
              emojiStickerImageId={emojiImageID}
              emojiImageDetails={emojiImageDetails}
              getRules={getEmojiStickerImageRules}
              saveRules={updateEmojiStickerImageRules}
              updateRulesAllowed={updateRulesAllowed()}
            />
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};
export default EmojiStickerImage;

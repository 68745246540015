import React, { Fragment, useState, useEffect } from "react";
import "react-h5-audio-player/lib/styles.css";
import {
  Badge,
  Col,
  Row,
  Card,
  CardBody,
  CardImg,
  CardTitle,
} from "reactstrap";
import { getPopTextStyleDetails } from "../../../../utilities/apiUtils/popTextStyles";
import Loading from "../../../../components/Loading";
import { getStatus, getStatusColor } from "../../../../utilities/commonUtil";

const StyleDetailBoard = (props) => {
  const [style, setTheme] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getDetails = async () => {
      let id = props.id;
      let response = await getPopTextStyleDetails(id);
      if (response) {
        setTheme(response);

        setLoading(false);
      }
    };

    getDetails();
  }, []);

  function convertUnicode(input) {
    return decodeURIComponent(
      JSON.parse('"' + input.replace(/\"/g, '\\"') + '"')
    );
  }

  return (
    <Fragment>
      {loading ? (
        <Loading />
      ) : (
        <Fragment>
          <Card>
            <React.Fragment>
              {style.previewImageURL ? (
                <CardImg src={style.previewImageURL} alt="Card image" style={{ maxHeight: '400px', overflow: 'hidden' }} className="align-items-center" />
              ) : (
                <div className="theme-preview-image bg-dark"></div>
              )}

              {/* Background Images Section */}
              <div className="p-3 border-top">
                <Row>
                  <Col xs="6">
                    <div className="text-center">
                      <div className="bg-light rounded overflow-hidden" style={{ height: '150px' }}>
                        {style.backgroundImageURL ? (
                          <img
                            src={style.backgroundImageURL}
                            alt="Background"
                            className="img-fluid h-100 w-100 object-fit-cover"
                          />
                        ) : (
                          <div className="h-100 d-flex align-items-center justify-content-center text-muted">
                            No background image
                          </div>
                        )}
                      </div>
                      <small className="d-block mt-2 font-weight-bold">Background Image</small>
                    </div>
                  </Col>
                  <Col xs="6">
                    <div className="text-center">
                      <div className="bg-light rounded overflow-hidden" style={{ height: '150px' }}>
                        {style.compressedBackgroundImageURL ? (
                          <img
                            src={style.compressedBackgroundImageURL}
                            alt="Compressed background"
                            className="img-fluid h-100 w-100 object-fit-cover"
                          />
                        ) : (
                          <div className="h-100 d-flex align-items-center justify-content-center text-muted">
                            No compressed image
                          </div>
                        )}
                      </div>
                      <small className="d-block mt-2 font-weight-bold">Background Image Compressed</small>
                    </div>
                  </Col>
                </Row>
              </div>
            </React.Fragment>
            <CardBody>
              <CardTitle tag="h3">
                {style.name}
                <Badge
                  className="float-right"
                  color={getStatusColor(
                    getStatus(style.publishedAt, style.deactivatedAt)
                  )}
                >
                  {getStatus(style.publishedAt, style.deactivatedAt)}
                </Badge>
              </CardTitle>
              <Col>
                <Row>
                  <strong>ID : </strong>
                  {style.id}
                </Row>
                <Row>
                  <strong>Name: </strong> {style.name}
                </Row>
                <Row>
                  {style.keywords ? <strong>Keywords : </strong> : null}
                  {style.keywords
                    ? convertUnicode(style.keywords.join(", "))
                    : null}
                </Row>
                <Row>
                  {style.languageCodes && style.languageCodes.length > 0 ? (
                    <strong>LanguageCodes :</strong>
                  ) : null}
                  {style.languageCodes && style.languageCodes.length > 0
                    ? convertUnicode(style.languageCodes.join(", "))
                    : null}
                </Row>
                <Row>
                  {style.brandCampaign && style.brandCampaign.name ? (
                    <strong>Brand Campaign: </strong>
                  ) : null}
                  {style.brandCampaign && style.brandCampaign.name
                    ? style.brandCampaign.name
                    : null}
                </Row>
                <Row>
                  <strong>Created At :</strong> {style.createdAt}
                </Row>
                <Row>
                  <strong>Updated At :</strong> {style.updatedAt}
                </Row>
                <Row>
                  <strong>Published At :</strong> {style.publishedAt}
                </Row>
                <Row>
                  <strong>Deactivated At :</strong> {style.deactivatedAt}
                </Row>
              </Col>
            </CardBody>
          </Card>
        </Fragment>
      )}
    </Fragment>
  );
};

export default StyleDetailBoard;

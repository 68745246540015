import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import {
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Form,
    Container,
    Progress,
    Button,
    CustomInput,
} from "reactstrap";
import Select from "react-select";
import { Check } from "react-feather";
import { resourceTypesDropdown, locationZonesDropdown } from "../../../config/keyboardLanguages";

const UploadResource = (props) => {
    const [resourceTypes, setResourceTypes] = useState(resourceTypesDropdown);

    const [id, setID] = useState(props.id);
    const [selectedResourceType, setSelectedResourceType] = useState("");
    const [resourceFile, setResourceFile] = useState("");
    const [selectedFileName, setSelectedFileName] = useState("");
    const [uploadNotes, setUploadNotes] = useState("");
    const [uploadFileProgess, setUploadFileuploadFileProgess] = useState(0);
    const [selectedReleaseType, setSelectedReleaseType] = useState("production");
    const [selectedLocationZone, setSelectedLocationZone] = useState("");


    useEffect(() => {
        setID(props.id);
        setUploadFileuploadFileProgess(props.uploadFileProgess);

        // This useEffect will be called for componentWillReceiveProps condition
        if (props.uploadFileProgess == 100) {
            setResourceFile("");
            setSelectedResourceType("");
            setSelectedFileName("");
            setUploadNotes("");
            setSelectedLocationZone("");
        }
    }, [props]);

    useEffect(() => {
        if (resourceFile.name) setSelectedFileName(resourceFile.name);
    }, [resourceFile]);

    const handleFileChange = (event) => {
        event.persist();
        const { files } = event.target;
        if (files[0] != undefined && files[0] != null) {
            setResourceFile(files[0]);
        }
    };

    const handleResourceTypeChange = (option) => {
        setSelectedResourceType(option.value);
    };

    const handleLocationZoneChange = (option) => {
        setSelectedLocationZone(option.value);
    };

    const handleSelectedReleaseTypeChange = (event) => {
        const { value } = event.target;
        setSelectedReleaseType(value);
    };

    const handleUploadNotesChange = (event) => {
        const { value } = event.target;
        setUploadNotes(value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let resource = new FormData();
        resource.append("resourceType", selectedResourceType);
        resource.append("resourceFile", resourceFile);
        resource.append("updateNotes", uploadNotes);
        if (props.isReleaseTypeABTest === false) {
            resource.append("releaseType", selectedReleaseType);
        } else {
            resource.append("releaseType", "ab_test");
            resource.append("version", props.version);
        }
        if (selectedLocationZone != "") {
            resource.append("locationZone", selectedLocationZone);
        }
        props.uploadKeyboardLanguageResources(resource, id);
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Upload Resource
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={(event) => handleSubmit(event)}>
                    {props.isReleaseTypeABTest === true ? (
                        <Row>
                            <Col md={6}>
                                <p>Version: {props.version}</p>
                            </Col>
                        </Row>
                    ) : null}
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Select Resource Type</Label>
                                <Select
                                    className="react-select-container dropdown"
                                    classNamePrefix="react-select"
                                    name="resourceType"
                                    onChange={(option) => handleResourceTypeChange(option)}
                                    options={resourceTypes}
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        {props.isReleaseTypeABTest === false ? (
                            <Col md={12}>
                                <FormGroup className="row">
                                    <Label className="col-lg-2 col-sm-12">Release Type:</Label>
                                    <CustomInput
                                        type="radio"
                                        id="production"
                                        name="selectedReleaseType"
                                        label="Production"
                                        value="production"
                                        className="col-lg-2 col-sm-4"
                                        onChange={(event) => handleSelectedReleaseTypeChange(event)}
                                        defaultChecked={"production"}
                                    />
                                    <CustomInput
                                        type="radio"
                                        id="ab_test"
                                        name="selectedReleaseType"
                                        label="AB Test"
                                        value="ab_test"
                                        className="col-lg-2 col-sm-4"
                                        onChange={(event) => handleSelectedReleaseTypeChange(event)}
                                    />
                                </FormGroup>
                            </Col>
                        ) : null}
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Upload File</Label>
                                <Container className="custom-file" style={{ zIndex: "0" }}>
                                    <Input
                                        type="file"
                                        className="custom-file-input"
                                        name="resourceFile"
                                        onChange={(event) => {
                                            handleFileChange(event);
                                        }}
                                        required
                                    />
                                    <Label className="custom-file-label" id="resourceFile">
                                        {resourceFile == ""
                                            ? "Choose an file"
                                            : "File: " + selectedFileName}
                                    </Label>
                                </Container>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Select Location Zone</Label>
                                <Select
                                    className="react-select-container dropdown"
                                    classNamePrefix="react-select"
                                    name="locationZone"
                                    onChange={(option) => handleLocationZoneChange(option)}
                                    options={locationZonesDropdown}
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Progress
                                    color="success"
                                    value={uploadFileProgess}
                                    animated
                                    striped
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                {uploadFileProgess == 100 ? (
                                    <Check
                                        color="green"
                                        size="28"
                                        style={{ marginTop: "-1rem" }}
                                    />
                                ) : null}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Update notes</Label>
                                <Input
                                    name="uploadNotes"
                                    type="textarea"
                                    placeholder="Add update notes"
                                    onChange={(event) => handleUploadNotesChange(event)}
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Button color="primary" type="submit">
                        Upload
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default UploadResource;

import React, { useState,useEffect } from "react";
import TargetingRules from "../TargetingRules";
import StyleDetailBoard from "./components/StyleDetailBoard";
import PopTextStyleFormContainer from "./PopTextStyleFormContainer";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { permissions } from "../../../utilities/commonUtil";
import { getRules, saveRules } from "../../../utilities/apiUtils/popTextStyles";
import {
  Row,
  Col,
  CardBody,
  Button,
  Container,
  Input,
  Label
} from "reactstrap";
import ManagePopTextStyleShareTexts from "./ManagePopTextStyleShareTexts";
import {
  error,
  errorDescription,
  redirectOnTokenExpiry
} from "../../../utilities/commonUtil";
import {
  getClients,
  getCountries,
  getRegions,
  getCities,
} from "../../../utilities/apiUtils/stickers";
import ShareTextsTable from "./components/ShareTextsTable";
import { getPopTextStyleShareText } from "../../../utilities/apiUtils/popTextStyles";

const StyleDetail = (props) => {
  let id = props.match.params.id;
  const [showPopTextStyleShareText,setPopTextStyleShareText] = useState(false);
  const [showEnableShareTextInKeyboard,setEnableShareTextInKeyboard] = useState();
  const [shareTexts,setShareTexts] = useState({});
  const [hasResponseError, setHasResponseError] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");
  const [loading, setLoading] = useState(true);

  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  const showRules = () => {
    return (
      allowedPermissions.includes(permissions.clientList) &&
      allowedPermissions.includes(permissions.countryList) &&
      allowedPermissions.includes(permissions.regionList) &&
      allowedPermissions.includes(permissions.cityList) &&
      allowedPermissions.includes(permissions.popTextStyleRuleView)
    );
  };

  const updateAllowed = () => {
    return allowedPermissions.includes(permissions.popTextStyleUpdate);
  };

  const updateRulesAllowed = () => {
    return allowedPermissions.includes(permissions.popTextStyleRuleUpdate);
  };
  const componentDidMount = async () => {
    await this.getPopTextStyle();
  };
  const showEditShareTextButton = () => {
    return allowedPermissions.includes(permissions.popTextStyleShareTextUpdate)
  }
  const closePopTextStyleShareText = () => {
    setPopTextStyleShareText(false)
  };
  const fetchPopTextStyleShareText = async () => {
    try {
      const response = await getPopTextStyleShareText(id);
      if (response) {
        setShareTexts(response.shareTexts);
        setEnableShareTextInKeyboard(response.enableShareTextInKeyboard);
      } else if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        ) {
          redirectOnTokenExpiry();
        } else {
          setHasResponseError(true);
          setFailureModalText(errorDescription(response.errorCode));
        }
      }
    } catch (err) {
      setHasResponseError(true);
      setFailureModalText(errorDescription(error.unexpectedError));
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchPopTextStyleShareText();
  }, []);

  return (
    <div>
      <div style={{ width: "25%", float: "left", position: "fixed" }}>
        <StyleDetailBoard id={id} />
      </div>
      <div style={{ width: "65%", float: "right" }}>
        <PopTextStyleFormContainer
          {...props}
          formName={"Details"}
          updateAllowed={updateAllowed()}
        />
        <div><h3 className="mb-4 mt-2 w-50 pl-3" style={{ padding: "0 ", display: "flex", alignItems: "left" }}>Share Texts</h3></div>
        <CardBody style={{ backgroundColor: "white" }}>
          <Container fluid className="p-0">
            <Row>
              <Col xs={6}>
              </Col>
              <Col xs={6} style={{ padding: "0px 5% 0px 0px", display: "flex", alignItems: "center" }}>
                {
                  showEditShareTextButton() ? <Button color="primary" className="ml-auto d-flex" style={{ padding: "0px 10px 0px 10px" }} onClick={() => (setPopTextStyleShareText(true))}>
                    <p className="mb-1 mt-1" style={{ fontSize: "1rem", margin: "0" }}>
                      Edit
                    </p>
                  </Button> : null
                }
                {showPopTextStyleShareText === true && (
                  <ManagePopTextStyleShareTexts
                    show={showPopTextStyleShareText}
                    onHide={closePopTextStyleShareText}
                    shareTexts={shareTexts}
                    enableShareTextInKeyboard={showEnableShareTextInKeyboard}
                    popTextStyleID={id}
                    action={"edit"}
                  />
                )}
              </Col>
            </Row>
            <Row className="mb-3" style={{ padding: '0px 50px 0px 0px', margin: '0px 50px 0px 0px ' }}>
              <Col xs={12} className="d-flex align-items-left" >
                <Input
                  type="checkbox"
                  name="enableShareTextInKeyboard"
                  checked={showEnableShareTextInKeyboard}
                  disabled="true"
                  style={{ padding: '0px 50px 0px 0px', margin: '15px 50px 0px 0px ' }}
                />
                <Label
                  className="mb-4 mt-2"
                  style={{ fontSize: "16px", padding: '0px 50px 0px 30px', margin: '0px' }}
                >
                  Enable Share Text in Keyboard
                </Label>
              </Col>
            </Row>

            <ShareTextsTable shareTexts={shareTexts} ></ShareTextsTable>
          </Container>
        </CardBody>
        {showRules() ? (
          <TargetingRules
            showAgeGenderRules={true}
            id={id}
            getRules={getRules}
            saveRules={saveRules}
            getClients={getClients}
            getCountries={getCountries}
            getRegions={getRegions}
            getCities={getCities}
            updateRulesAllowed={updateRulesAllowed()}
          />
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

export default StyleDetail;

import React, { useState, useEffect } from "react";
import { Card, CardBody, Container, Row, Col, Button } from "reactstrap";
import { columns } from "./pushNotificationExecutionColumns";
import { Badge } from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import Search from "../../../../components/Search";
import ConfirmationModal from "../../../../pages/ui-elements/ConfirmationModal";
import ResponseModal from "../../../../pages/ui-elements/ResponseModal";
import {
    successModalHeading,
    errorModalHeading,
} from "../../../../config/config";
import Loading from "../../../../components/Loading";



import { processDateTime, isOperationPermitted, permissions, errorDescription, error, redirectOnTokenExpiry } from "../../../../utilities/commonUtil";

import {
    fetchPushNotificationExecution,
} from "../../../../utilities/apiUtils/pushNotifications";

import {
    cancelNotification,
} from "../../../../utilities/apiUtils/pushNotifications";


import { retrieveFromLocalStorage } from "../../../../utilities/localStorageUtil";

import { IoMdRefresh } from "react-icons/io";


const PushNotificationExecution = () => {
    const [pushNotificationExecutionsList, setPushNotificationExecutionsList] = useState([]);
    const [column] = useState(columns);
    const [idFilter, setIdFilter] = useState("");
    const [allowedPermissions, setAllowedPermissions] = useState(
        retrieveFromLocalStorage("userPermissions")
    );
    const [action, setAction] = useState("");
    const [id, setId] = useState(null);
    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
    const [failureModalText, setFailureModalText] = useState("");
    const [failureModalDisplay, setFailureModalDisplay] = useState(false);
    const [successModalText, setSuccessModalText] = useState("");
    const [successModalDisplay, setSuccessModalDisplay] = useState(false);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        // get id from url
        let id = window.location.href.split("/").pop();
        let data = await fetchPushNotificationExecution(id);
        if (
            data.errorCode === error.tokenExpired ||
            data.errorCode === error.invalidAccessToken
        )
            redirectOnTokenExpiry();
        if (data?.executions !== undefined) {
            data.executions = processDateTime(data.executions);
            setPushNotificationExecutionsList(data.executions);
        }
        setLoading(false);
    };

    const filterPushNotificationExecutions = () => {
        let filteredData = [...pushNotificationExecutionsList];
        if (idFilter !== "") {
            filteredData = filteredData?.filter((item) =>
                item.id === parseInt(idFilter)
            );
        }
        return filteredData;
    };

    let setConfirmationModalState = (id, event) => {
        setAction(event);
        setConfirmationModalIsOpen(true);
        setId(id);
    };

    let showSuccessModal = (text) => {
        setSuccessModalText(text);
        setSuccessModalDisplay(true);
    };

    let successModalClose = async () => {
        setSuccessModalDisplay(false);
        await fetchData();
    };

    let failureModalClose = () => {
        setFailureModalDisplay(false);
    };

    let showErrorModal = (text) => {
        setFailureModalText(text);
        setFailureModalDisplay(true);
    };

    let cancel = async (id) => {
        let response = await cancelNotification(id);
        if (response?.status === "success") {
            let text = "Push Notification Execution has been Cancelled";
            showSuccessModal(text);
        } else {
            let text = errorDescription(response?.errorCode);
            showErrorModal(text);
        }
    };

    const expandRow = {
        onlyOneExpanding: true,
        renderer: (row) => (
            <div>
                <ul>
                    <li>
                        <strong>Success Count:</strong> {row.successCount}
                    </li>
                    <li>
                        <strong>Failure Count:</strong> {row.failureCount}
                    </li>
                    <li>
                        <strong>Checkpoint:</strong> {row.checkpoint}
                    </li>
                    <li>
                        <strong>Status:</strong>{" "}
                        {row.status === "completed" ? (
                            <Badge className="badge-success">{row.status}</Badge>
                        ) : row.status === "failed" ? (
                            <Badge className="badge-danger">{row.status}</Badge>
                        ) : row.status === "canceled" ? (
                            <Badge className="badge-primary">{row.status}</Badge>
                        ) : row.status === "ongoing" ? (
                            <Badge className="badge-warning">{row.status}</Badge>
                        ) : (
                            <Badge className="badge-info">{"not started"}</Badge>
                        )}
                    </li>
                    <li>
                        <strong>Push Notification Id:</strong> {row.pushNotificationId}
                    </li>
                    <li>
                        <strong>User Segment Version Id:</strong> {row.userSegmentVersionId}
                    </li>
                    <li>
                        <strong>Completed At:</strong> {row.completedAt}
                    </li>
                    <li>
                        <strong>Canceled At:</strong> {row.canceledAt}
                    </li>
                    <li>
                        <strong>Created At:</strong> {row.createdAt}
                    </li>
                    <li>
                        <strong>Updated At:</strong> {row.updatedAt}
                    </li>
                </ul>

                {row?.status === "ongoing" && (
                    <Row className="mx-auto mb-4">
                        {isOperationPermitted(allowedPermissions, permissions.notificationCancel) && (
                            <Button
                                className="mx-3 btn-danger"
                                onClick={(event) =>
                                    setConfirmationModalState(row.id, event.target.innerHTML)
                                }
                            >
                                Cancel Execution
                            </Button>
                        )}
                    </Row>
                )}

                <ConfirmationModal
                    show={confirmationModalIsOpen}
                    modaltext={action}
                    onConfirmationTrue={() => {
                        setConfirmationModalIsOpen(false);
                        if (action === "Cancel Execution") cancel(id);
                    }}
                    onHide={() => setConfirmationModalIsOpen(false)}
                />
                <ResponseModal
                    show={successModalDisplay}
                    onHide={successModalClose}
                    modalheading={successModalHeading}
                    modaltext={successModalText}
                />
                <ResponseModal
                    show={failureModalDisplay}
                    onHide={failureModalClose}
                    modalheading={errorModalHeading}
                    modaltext={failureModalText}
                />
            </div>
        )
    };


    return (
        <Container fluid className="p-0">
            <Row className="mb-3">
                <Col md={6} xs={6}>
                    <h1>Executions</h1>
                    <span className="text-warning font-weight-bold" style={{ fontSize: 13 }}>⚠️ The execution statistics do not indicate that notifications are delivered to the user. Instead, they specifically signify the acknowledgement or acceptance of a request by Firebase.</span>
                </Col>

                <Col md={6} xs={6}>
                    <Button color="info" className="ml-auto d-flex btn-info" onClick={fetchData}>
                        <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                            Refresh <IoMdRefresh />
                        </p>
                    </Button>
                </Col>


            </Row>

            {loading ? (
                <Loading />
            ) : (
                <Card style={{ overflow: "hidden", paddingBottom: "100px" }}>
                    <div container-fullwidth="true" style={{ margin: "2%" }}>
                        <Row
                            className="mx-auto"
                            style={{ display: "flex", justifyContent: "right" }}
                        ></Row>
                        <Row>
                            <Col md={3} sm={12}>
                                <Search
                                    placeholder="Search id"
                                    value={idFilter}
                                    onChange={(e) => setIdFilter(e.target.value)}
                                ></Search>
                            </Col>


                            <CardBody>
                                <ToolkitProvider
                                    keyField="id"
                                    data={pushNotificationExecutionsList}
                                    columns={column}
                                    search
                                >
                                    {(props) => (
                                        <div>
                                            <BootstrapTable
                                                bootstrap4={true}
                                                wrapperClasses="table-responsive"
                                                hover={true}
                                                bordered={false}
                                                keyField="id"
                                                data={filterPushNotificationExecutions()}
                                                columns={column}
                                                filter={filterFactory()}
                                                expandRow={expandRow}
                                                pagination={paginationFactory({
                                                    sizePerPage: 10,
                                                    sizePerPageList: [5, 10, 25, 50],
                                                })}
                                                remote={{ filter: true }}
                                                noDataIndication="No results found"
                                            />
                                        </div>
                                    )}
                                </ToolkitProvider>
                            </CardBody>

                        </Row>
                    </div>
                </Card>
            )}
        </Container>
    )
};

export default PushNotificationExecution;

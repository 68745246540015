import React, { Fragment, useState, useEffect } from "react";
import ResponseModal from "../ResponseModal";
import Papa from "papaparse";
import {
  createBulkImageTypeQuickReply,
  createQuickReplyBulk,
} from "../../../utilities/apiUtils/quickReplies";
import QuickReplyForm from "./QuickReplyForm";
import Routes from "../../../routes/index";
import {
  errorDescription,
  error,
  redirectTo,
  redirectionOnTokenExpiry,
} from "../../../utilities/commonUtil";
import {
  successModalHeading,
  failureModalHeading,
  quickReplyCreatedSuccessfullyText,
} from "../../../config/quickReplies";

import { getClients } from "../../../utilities/apiUtils/stickers";

const QuickReplyFormContainer = (props) => {
  const [formTextState, setFormTextState] = useState({
    formName: "Create Quick Reply",
    quickReplies: [],
    autoPublish: false,
    emojiToggle: false,
    categoryID: "",
    type: {
      value: "text",
      label: "Text",
    },
  });
  const [showForm, setShowForm] = useState(true);
  const [successModalState, setSuccessModalState] = useState({
    successModalDisplay: false,
    successModalText: "",
  });
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });

  const [formData, setFormData] = useState([]);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const _getClientOptions = async () => {
      let clientsOptions = await getClients();
      setClients(clientsOptions);
    };

    _getClientOptions();
  }, []);

  const onSubmit = async (e, zipFile) => {
    let type = e.type.value;
    try {
      let file = e.quickReplies;
      let fileType = e.fileType;
      let uploadError = false;
      const validFileTypes = ["text/csv", "application/json"];
      if (validFileTypes.includes(fileType)) {
        var finalData;
        if (fileType === "application/json") {

          if (type === "image") {
            if (!zipFile) {
              setFailureModalState({
                failureModalDisplay: true,
                failureModalText: "Please upload zip file containing images",
              });
              return false;
            }
            if (file?.length === 0) {
              setFailureModalState({
                failureModalDisplay: true,
                failureModalText:
                  "The CSV file should contain at least one image",
              });
              return false;
            }
          }

          for (let i = 0; i < file.length; i++) {
            if (
              file[i].client_ids == undefined ||
              file[i].rawText == undefined ||
              file[i].languageCode == undefined ||
              file[i].rawText == "" ||
              file[i].languageCode == "" ||
              file[i].client_ids == undefined
            ) {
              uploadError = true;
              break;
            }

            let client_ids = file[i].client_ids.split(",");

            // check if client ids are valid and remove those are not valid
            let validClientIds = [];
            for (let j = 0; j < client_ids.length; j++) {
              let client = clients?.find(
                (client) => client.value == client_ids[j],
              );
              if (client) {
                validClientIds.push(client_ids[j]);
              }
            }

            formData.push({
              categoryID: e.categoryID.value,
              rawText: file[i].rawText,
              attributionText: file[i].attributionText,
              priority: parseInt(file[i].priority),
              additionalDetails: file[i].additionalDetails,
              languageCode: file[i].languageCode,
              autoPublish: e.autoPublish,
              emojiToggle: e.emojiToggle,
            });
          }

          finalData = formData;
          if (!uploadError) {
            let response = await createQuickReplyBulk(finalData);
            if (response.errorCode) {
              if (
                response.errorCode === error.tokenExpired ||
                response.errorCode === error.invalidAccessToken
              ) {
                redirectionOnTokenExpiry();
                setFailureModalState({
                  failureModalDisplay: true,
                  failureModalText: errorDescription(response.errorCode),
                });
                return false;
              }
              setFailureModalState({
                failureModalDisplay: true,
                failureModalText: response.errorDescription,
              });
            } else {
              setSuccessModalState({
                successModalDisplay: true,
                successModalText: quickReplyCreatedSuccessfullyText,
              });
            }
          } else if (uploadError) {
            setFailureModalState({
              failureModalDisplay: true,
              failureModalText:
                "Incorrect file uploaded. Please check the sample upload file",
            });
            return false;
          } else {
          }
        } else {
          Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: async (results) => {
              finalData = results.data;
              if (type === "image") {
                if (!zipFile) {
                  setFailureModalState({
                    failureModalDisplay: true,
                    failureModalText: "Please upload zip file containing images",
                  });
                  return false;
                }

                if (finalData?.length === 0) {
                  setFailureModalState({
                    failureModalDisplay: true,
                    failureModalText:
                      "The CSV file should contain at least one image",
                  });
                  return false;
                }

              }

              for (let i = 0; i < finalData?.length; i++) {
                finalData[i].priority = parseInt(finalData[i].priority);
                if (finalData[i].client_ids == undefined) {
                  uploadError = true;
                  break;
                }

                // check if client ids are valid and remove those are not valid
                let client_ids = finalData[i]?.client_ids?.split(",");
                let validClientIds = [];
                for (let j = 0; j < client_ids?.length; j++) {
                  let client = clients?.find(
                    (client) => client.value == client_ids[j],
                  );
                  if (client) {
                    validClientIds.push(client_ids[j]);
                  }
                }

                finalData[i].client_ids = validClientIds;

                if (type === "image") {
                  let clickTracker = {
                    type: "direct_url",
                    url: finalData[i]?.clickTracker,
                  };

                  if (finalData[i]?.clickTracker?.length > 0)
                  finalData[i].clickTrackers = [clickTracker];

                  let impressionTracker = {
                    type: "direct_url",
                    url: finalData[i]?.impressionTracker,
                  };

                  if (finalData[i]?.impressionTracker?.length > 0)
                  finalData[i].impressionTrackers = [impressionTracker];
                }

                // attach category id
                finalData[i].categoryID = e.categoryID.value;
              }

              let response;

              if (finalData.length > 0 && uploadError === false) {
                finalData.forEach((el) => {
                  el.autoPublish = e.autoPublish;
                });

                if (type === "text") {
                  finalData.forEach((el) => {
                  el.emojiToggle = e.emojiToggle
                })
                response = await createQuickReplyBulk(finalData);
                } else {
                  try {
                    response = await createBulkImageTypeQuickReply(finalData, zipFile);
                  } catch (error) {
                    // Handle other unexpected errors here
                    setFailureModalState({
                      failureModalDisplay: true,
                      failureModalText: `An error occurred while processing your request. ${error}`,
                    });
                  }
                }
              } else {
                setFailureModalState({
                  failureModalDisplay: true,
                  failureModalText:
                    "Failed to parse CSV, please use the template mentioned",
                });
                return false;
              }

              
              if (response?.errorCode) {
                if (
                  response?.errorCode === error.tokenExpired ||
                  response?.errorCode === error.invalidAccessToken
                ) {
                  redirectionOnTokenExpiry();
                  setFailureModalState({
                    failureModalDisplay: true,
                    failureModalText: errorDescription(response.errorCode),
                  });
                  return false;
                }
                setFailureModalState({
                  failureModalDisplay: true,
                  failureModalText: response.errorDescription,
                });
                return false;
              } else {
                setSuccessModalState({
                  successModalDisplay: true,
                  successModalText: quickReplyCreatedSuccessfullyText,
                });
                return false;
              }
            },
          });
        }
      } else {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: "Please upload JSON or CSV",
        });
        return false;
      }
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error?.unexpectedError),
      });
    }
  };

  const failureModalClose = () => {
    setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
  };

  const successModalClose = () => {
    setSuccessModalState((prev) => ({ ...prev, successModalDisplay: false }));
    redirectTo(Routes.quickReplies.path);
  };

  return (
    <Fragment>
      {showForm ? (
        <QuickReplyForm
          quickReply={formTextState}
          submit={onSubmit}
          updateAllowed={props.updateAllowed}
        />
      ) : null}
      <ResponseModal
        show={failureModalState.failureModalDisplay}
        onHide={() => failureModalClose()}
        modalheading={failureModalHeading}
        modaltext={failureModalState.failureModalText}
      />
      <ResponseModal
        show={successModalState.successModalDisplay}
        onHide={() => successModalClose()}
        modalheading={successModalHeading}
        modaltext={successModalState.successModalText}
      />
    </Fragment>
  );
};

export default QuickReplyFormContainer;

import React, { useState } from "react";
import {
  validateCode,
  validateTerritory,
  validateJSON,
} from "../../../utilities/commonUtil";
import {
  Button,
  Container,
  Card,
  CardBody,
  Row,
  Form,
  Col,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import { createLanguage } from "../../../utilities/apiUtils/keyboardLanguages";
import ResponseModal from "../ResponseModal";
import {
  error,
  errorDescription,
  redirectionOnTokenExpiry,
  redirectTo,
} from "../../../utilities/commonUtil";
import Routes from "../../../routes/index";
import {
  invalidCodeAlertElementId,
  invalidCodeAlertText,
  invalidTerritoryAlertElementId,
  invalidTerritoryAlertText,
  invalidVocabularyAlertElementId,
  invalidVocabularyAlertText,
  languageCreatedStatus,
  languageCreatedText,
  successModalHeading,
  failureModalHeading,
} from "../../../config/keyboardLanguages";
import Loading from "../../../components/Loading";

const CreateLanguage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [disable, setDisable] = useState(false);

  const [formTextState, setFormTextState] = useState({
    name: "",
    nativeName: "",
    code: "",
    territory: "",
    vocabulary: "",
  });
  const [successModalState, setSuccessModalState] = useState({
    successModalDisplay: false,
    successModalText: "",
  });
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });

  const showAlert = (elementId) => {
    document.getElementById(elementId).classList.remove("d-none");
  };

  const hideAlert = (elementId) => {
    document.getElementById(elementId).classList.add("d-none");
  };

  const handleFormTextChange = (event) => {
    hideAlert(invalidCodeAlertElementId);
    hideAlert(invalidTerritoryAlertElementId);
    const { name, value } = event.target;
    setFormTextState((prev) => ({ ...prev, [name]: value }));
  };

  const handleTagChange = (event) => {
    hideAlert(invalidVocabularyAlertElementId);
    const { value } = event.target;
    setFormTextState((prev) => ({ ...prev, vocabulary: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateCode(formTextState.code)) showAlert(invalidCodeAlertElementId);
    else if (!validateJSON(formTextState.vocabulary)) {
      showAlert(invalidVocabularyAlertElementId);
    } else {
      let newLanguage = new FormData();
      newLanguage.append("name", formTextState.name);
      if (formTextState.nativeName.length > 0) {
        newLanguage.append("nativeName", formTextState.nativeName);
      }
      if (formTextState.territory.length > 0) {
        newLanguage.append("territory", formTextState.territory);
      }
      newLanguage.append("code", formTextState.code);
      if (formTextState.vocabulary.length > 0) {
        formTextState.vocabulary = JSON.parse(formTextState.vocabulary);
        formTextState.vocabulary.forEach((vocab, index) => {
          newLanguage.append(`vocabulary[${index}]`, vocab);
        });
      }

      try {
        const response = await createLanguage(newLanguage);
        if (response.errorCode) {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken
          )
            redirectionOnTokenExpiry();
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: response.errorDescription,
          });
        } else if (response.status === languageCreatedStatus) {
          setSuccessModalState({
            successModalDisplay: true,
            successModalText: languageCreatedText,
          });
        }
      } catch (err) {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      }
    }
  };

  const handleSubmitButtonDisable = () => {
    if (
      invalidCodeAlertText ||
      invalidVocabularyAlertText ||
      invalidTerritoryAlertText
    ) {
    } else {
    }
  };

  const successModalClose = () => {
    setSuccessModalState((prev) => ({ ...prev, successModalDisplay: false }));
    redirectTo(Routes.keyboardLanguages.children.language.path);
  };

  const failureModalClose = () => {
    setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
  };

  return (
    <Container fluid className="p-0">
      <h3 className="mb-3">Create Language</h3>
      <Card className="mb-0">
        <CardBody>
          <Form onSubmit={(event) => handleSubmit(event)}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Name <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    placeholder="Enter language name"
                    defaultValue={formTextState.name}
                    onChange={(event) => handleFormTextChange(event)}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Native name</Label>
                  <Input
                    type="text"
                    name="nativeName"
                    placeholder="Enter native name"
                    defaultValue={formTextState.nativeName}
                    onChange={(event) => handleFormTextChange(event)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Code <span className="text-danger">*</span>{" "}
                    <small
                      id={invalidCodeAlertElementId}
                      className="text-danger d-none"
                    >
                      ({invalidCodeAlertText})
                    </small>
                  </Label>
                  <Input
                    type="text"
                    name="code"
                    placeholder="Enter code"
                    defaultValue={formTextState.code}
                    onChange={(event) => handleFormTextChange(event)}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Territory
                    <small
                      id={invalidTerritoryAlertElementId}
                      className="text-danger d-none"
                    >
                      ({invalidTerritoryAlertText})
                    </small>
                  </Label>
                  <Input
                    type="text"
                    name="territory"
                    placeholder="Enter territory"
                    defaultValue={formTextState.territory}
                    onChange={(event) => handleFormTextChange(event)}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label>Vocabulary </Label>
                  <small
                    id={invalidVocabularyAlertElementId}
                    className="text-danger d-none"
                  >
                    ({invalidVocabularyAlertText})
                  </small>
                  <Input
                    type="textarea"
                    name="vocabulary"
                    onChange={(event) => handleTagChange(event)}
                    style={{ height: "6rem" }}
                    placeholder="Vocabulary"
                    value={formTextState.vocabulary}
                    defaultValue={null}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button
              color="primary"
              type="submit"
              disabled={isLoading ? disable : null}
              // onClick={() => setDisable(true)}
              onClick={handleSubmitButtonDisable}
            >
              Submit
              {/* {isLoading ? `Submitting...` : `Submit`} */}
            </Button>
          </Form>
        </CardBody>
      </Card>
      <ResponseModal
        show={successModalState.successModalDisplay}
        onHide={() => successModalClose()}
        modalheading={successModalHeading}
        modaltext={successModalState.successModalText}
      />
      <ResponseModal
        show={failureModalState.failureModalDisplay}
        onHide={() => failureModalClose()}
        modalheading={failureModalHeading}
        modaltext={failureModalState.failureModalText}
      />
    </Container>
  );
};

export default CreateLanguage;

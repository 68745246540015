import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import Select from "react-select";
import { Container, Row, Col, FormGroup, Label, Input, Button } from "reactstrap";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Loading from "../../../components/Loading";
import { fetchDashboardLogs, fetchDashboardLogsOperations } from "../../../utilities/apiUtils/dashboardLogs";
import Logs from "./Logs";
import { fetchClients, fetchPlacements } from "../../../utilities/apiUtils/configAPI";
import { fetchSmartSuggestionCountries } from "../../../utilities/apiUtils/smartSuggestions";
import { createDeepCopy } from "../../../config/configDashboardLogs";

const LIMIT = 5

const filtersInitialState = {
  startDate: moment().format("MM/DD/YYYY"),
  endDate: moment().format("MM/DD/YYYY"),
  service: {
    label: "All",
    value: ""
  },
  username: "",
  operations: [],
  search: ""
}

const DashboardLogs = () => {
  const [loading, setLoading] = useState(true);
  const [sessions, setSessions] = useState([])
  const [allSessions, setAllSessions] = useState([])
  const [activeSessionAccordion, setActiveSessionAccordion] = useState(null)
  const [activeSessionChangeAccordion, setActiveSessionChangeAccordion] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const [activePage, setActivePage] = useState(0)
  const [serviceOperations, setServiceOperations] = useState(null)
  const [serviceOptions, setServiceOptions] = useState([])
  const [operationOptions, setOperationOptions] = useState([])
  const [filters, setFilters] = useState(filtersInitialState)
  const [clients, setClients] = useState([])
  const [placements, setPlacements] = useState([])
  const [countries, setCountries] = useState([])

  const handleToggleSessionAccordion = useCallback((value) => {
    if (value === activeSessionAccordion) setActiveSessionAccordion(null)
    else setActiveSessionAccordion(value)
    setActiveSessionChangeAccordion(null)
  }, [activeSessionAccordion])

  const handleToggleSessionChangeAccordion = useCallback((value) => {
    if (value === activeSessionChangeAccordion) setActiveSessionChangeAccordion(null)
    else setActiveSessionChangeAccordion(value)
  }, [activeSessionChangeAccordion])

  const getDashboardLogs = async (filters) => {
    try {
      let response = await fetchDashboardLogs(filters);
      if (response?.logs?.length > 0) {
        setPageCount(Math.ceil(response.total / LIMIT));
        setAllSessions(response.logs);
      } else {
        setPageCount(0);
        setAllSessions([]);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const getDashboardLogsOperations = async () => {
    try {
      // Fetch services and their related operations
      let response = await fetchDashboardLogsOperations();

      // Process response
      if (response && Object.keys(response)?.length > 0) {
        let serviceOptions = [{ label: "All", value: "" }]
        let operationOptions = []

        // Fill service options
        if (Object.keys(response).length > 0) {
          Object.entries(response).forEach(([key, val]) => {
            serviceOptions.push({
              label: key,
              value: key
            })

            // Fill operation options
            Object.entries(val).forEach(([key, val]) => {
              operationOptions.push({
                label: val,
                value: key
              })
            })
          })
        }
        setServiceOptions(serviceOptions)
        setOperationOptions(operationOptions)
        setServiceOperations(response)
      }
    } catch (err) {
      console.log(err);
    }
  }

  const getClients = async () => {
    try {
      let response = await fetchClients();
      if (response?.clients?.length > 0) setClients(response.clients)
      else setClients([])
    } catch (err) {
      console.log(err);
    }
  }

  const getPlacements = async () => {
    try {
      let response = await fetchPlacements();
      if (response?.length > 0) setPlacements(response)
      else setPlacements([])
    } catch (err) {
      console.log(err);
    }
  }

  const getCountries = async () => {
    try {
      let response = await fetchSmartSuggestionCountries();
      if (response?.length > 0) setCountries(response)
      else setCountries([])
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    // Fetch dashboard logs - initial call
    const queryFilters = {
      ...filtersInitialState,
      limit: LIMIT,
      page: 1,
      service: filtersInitialState.service?.value,
      operations: filtersInitialState.operations?.map(operation => operation.label).join() || "",
      start: moment(filtersInitialState.startDate).format("YYYY-MM-DD"),
      end: moment(filtersInitialState.endDate).format("YYYY-MM-DD")
    }
    setLoading(true)
    Promise.all([
      getDashboardLogs(queryFilters),
      getDashboardLogsOperations(),
      getClients(),
      getPlacements(),
      getCountries()
    ]).finally(() => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (allSessions?.length > 0 && clients?.length > 0 && placements?.length > 0 && countries?.length > 0) {
      const updatedSessions = createDeepCopy(allSessions)
      updatedSessions.forEach(session => {
        session.changes.forEach(change => {
          change.clientName = clients.find(client => client.id === change.clientId)?.name ?? null
          change.placementDesc = placements.find(placement => placement.id === change.placementId)?.description ?? null
          change.countryName = countries.find(country => country.id === change.countryId)?.name ?? null
        })
      })
      setSessions(updatedSessions)
    } else {
      setSessions([])
    }
  }, [allSessions, clients, placements, countries])

  const handleServiceChange = (event) => {
    setFilters((prevState) => ({
      ...prevState,
      service: event,
      operations: []
    }));

    /* Change operation options */
    let options = []

    // If 'All' is selected → operation options = all service's operations
    if (!event?.value) {
      Object.entries(serviceOperations).forEach(([key, val]) => {
        Object.entries(val).forEach(([key, val]) => {
          options.push({
            label: val,
            value: key
          })
        })
      })
      setOperationOptions(options)
      return
    }

    // If any service is selected → operation options = that service's operations
    const operations = serviceOperations[event?.value] || null
    if (Object.keys(operations).length > 0) {
      Object.entries(operations).forEach(([key, val]) => {
        options.push({
          label: val,
          value: key
        })
      })
    }
    setOperationOptions(options)
  }

  const handlePageClick = (event) => {
    setActivePage(event.selected)
    setLoading(true)
    const queryFilters = {
      limit: LIMIT,
      page: event.selected + 1,
      service: filters.service?.value,
      username: filters.username,
      search: filters.search,
      operations: filters.operations?.map(operation => operation.label).join() || "",
      start: moment(filters.startDate).format("YYYY-MM-DD"),
      end: moment(filters.endDate).format("YYYY-MM-DD")
    }
    getDashboardLogs(queryFilters).finally(() => {
      setLoading(false)
    })
  };

  const ranges = {
    Today: [moment(), moment()],
    Yesterday: [
      moment().subtract(1, "days"),
      moment().subtract(1, "days")
    ],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()]
  };

  const handleApplyDates = (e, { startDate, endDate }) => {
    setFilters((prevState) => ({
      ...prevState,
      startDate: startDate.format("MM/DD/YYYY"),
      endDate: endDate.format("MM/DD/YYYY")
    }));
  };

  const handleOnFilter = () => {
    setActivePage(0)
    const { startDate, endDate, service, username, operations, search } = filters
    const queryFilters = {
      limit: LIMIT,
      page: 1,
      service: service?.value,
      username: username,
      search: search,
      operations: operations?.map(operation => operation.label).join() || "",
      start: moment(startDate).format("YYYY-MM-DD"),
      end: moment(endDate).format("YYYY-MM-DD")
    }
    setLoading(true)
    getDashboardLogs(queryFilters).finally(() => {
      setLoading(false)
    })
  }

  return (
    <Container fluid className="p-0">
      <Row>
        <h1 className="mb-2 mt-2 w-50 pl-3">Logs</h1>
      </Row>
      <p className="text-info font-weight-bold mb-4" style={{ fontSize: 13 }}>ⓘ Press filter button after changing filters to apply changes</p>
      <Row form>
        <Col md={2}>
          <FormGroup>
            <Label
              className="font-weight-bolder mb-2"
              style={{ fontSize: "14px" }}
            >
              Service
            </Label>
            <Select
              id="service"
              className="react-select-container"
              classNamePrefix="react-select"
              name="service"
              placeholder="Select Service"
              value={filters.service}
              onChange={handleServiceChange}
              options={serviceOptions}
            />
          </FormGroup>
        </Col>
        <Col md={2}>
          <FormGroup>
            <Label
              className="font-weight-bolder mb-2"
              style={{ fontSize: "14px" }}
            >
              Username
            </Label>
            <Input
              type="text"
              name="username"
              placeholder="Search Username"
              value={filters.username}
              onChange={(event) => {
                const { value } = event.target
                setFilters((prevState) => ({
                  ...prevState,
                  username: value || ""
                }));
              }}
            />
          </FormGroup>
        </Col>
        <Col md={2}>
          <FormGroup>
            <Label
              className="font-weight-bolder mb-2"
              style={{ fontSize: "14px" }}
            >
              Operation
            </Label>
            <Select
              isMulti
              id="operations"
              className="react-select-container"
              classNamePrefix="react-select"
              name="operations"
              placeholder="Select Operations"
              value={filters.operations}
              onChange={(event) => {
                setFilters((prevState) => ({
                  ...prevState,
                  operations: event
                }));
              }}
              options={operationOptions}
            />
          </FormGroup>
        </Col>
        <Col md={4} className="ml-auto">
          <FormGroup>
            <Label
              className="font-weight-bolder mb-2"
              style={{ fontSize: "14px" }}
            >
              Date Range <small className="text-info">(MM/DD/YYYY format)</small>
            </Label>
            <DateRangePicker
              initialSettings={{ startDate: filters.startDate, endDate: filters.endDate, ranges }}
              onApply={handleApplyDates}
            >
              <input
                type="text"
                value={filters.startDate + " - " + filters.endDate}
                className="form-control"
              />
            </DateRangePicker>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Input
              type="text"
              name="search"
              placeholder="Search Logs"
              value={filters.search}
              onChange={(event) => {
                const { value } = event.target
                setFilters((prevState) => ({
                  ...prevState,
                  search: value || ""
                }));
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Button className="d-block mb-4 px-3" color="primary" onClick={handleOnFilter}>
        Filter
      </Button>
      {!loading && sessions.length <= 0 && (
        <span className="text-info font-weight-bold">No logs available for the selected filters</span>
      )}
      {loading ? (
        <Loading />
      ) : sessions.length > 0 && (
        <Logs
          sessions={sessions}
          activeSessionAccordion={activeSessionAccordion}
          activeSessionChangeAccordion={activeSessionChangeAccordion}
          pageCount={pageCount}
          activePage={activePage}
          limit={LIMIT}
          onPageClick={handlePageClick}
          onToggleSessionAccordion={handleToggleSessionAccordion}
          onToggleSessionChangeAccordion={handleToggleSessionChangeAccordion}
        />
      )
      }
    </Container>
  );
};

export default DashboardLogs;
import { host } from "./index";
import { retrieveFromLocalStorage } from "../localStorageUtil";
import { maxDefaultLimit } from "../../config/UserConfig";

export let getEmojiStickers = async (params = {}) => {
  if (!params.limit) params.limit = maxDefaultLimit;
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/emojiStickers?` + new URLSearchParams(params);
  let resStatus = "";

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      resStatus = res;
    });
  return resStatus;
};

export let getEmojiStickerImages = async (params = {}) => {
  if (!params.limit) params.limit = maxDefaultLimit;
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/emojiStickers/images?` + new URLSearchParams(params);
  let resStatus = "";

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      resStatus = res;
    });
  return resStatus;
}

export let addEmojiSticker = async (body) => {
  let token = retrieveFromLocalStorage("token");
  let url = "",
    resStatus = "";

  url = `${host}/v4/internal/emojiStickers`;
  await fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    resStatus = response.json();
  });

  return resStatus;
};

export let emojiStickerDetails = async (emojiID) => {
  let token = retrieveFromLocalStorage("token");
  let url = "",
    resStatus = "";

  url = `${host}/v4/internal/emojiStickers/${emojiID}`;
  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    resStatus = response.json();
  });

  return resStatus;

};

export let emojiStickerImageDetails = async (emojiID, emojiImageID) => {
  let token = retrieveFromLocalStorage("token");
  let url = "",
    resStatus = "";

  url = `${host}/v4/internal/emojiStickers/${emojiID}/image/${emojiImageID}`;
  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    resStatus = response.json();
  });
  
  return resStatus;
};

export let addEmojiStickerImage = async (body, emojiID) => {
  let token = retrieveFromLocalStorage("token");
  let url = "",
    resStatus = "";

  url = `${host}/v4/internal/emojiStickers/${emojiID}/image`;
  await fetch(url, {
    method: "POST",
    body: body,
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    resStatus = response.json();
  });

  return resStatus;
};

export let updateEmojiStickerImage = async (body, emojiID, emojiImageID) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/emojiStickers/${emojiID}/image/${emojiImageID}`;
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: body,
    });

    return response.json();

  } catch (error) {
    return;
  }
};

export let updateEmojiStickerImageRules = async (
  body,
  emojiID,
  emojiImageID
) => {
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/emojiStickers/${emojiID}/image/${emojiImageID}/rules`;

  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ rules: body }),
    });

    return await response.json();

  } catch (error) {
    return;
  }
};

export let getEmojiStickerImageRules = async (emojiID, emojiImageID) => {
  let rules = [];

  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/emojiStickers/${emojiID}/image/${emojiImageID}/rules`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    var res = await response.json();
    if (res) {
      rules = res.rules;
    }

    return rules;

  } catch (error) {
    return;
  }
};


export let saveEmojiStickerImageShareText = async (data,emojiID, emojiImageID) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/emojiStickers/${emojiID}/image/${emojiImageID}/shareTexts`;
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let getEmojiStickerImageShareText = async (emojiID, emojiImageID) => {
  let token = retrieveFromLocalStorage("token");
  let stickerShareText = {};

  let url = `${host}/v4/internal/emojiStickers/${emojiID}/image/${emojiImageID}/shareTexts`;

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    stickerShareText = response.json();
  });

  return stickerShareText;
};
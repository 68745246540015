import React, { useState } from "react";
import TargetingRules from "../TargetingRules";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { permissions } from "../../../utilities/commonUtil";
import {
  fetchSugggestionDrawerContentTargetingRules, updateSugggestionDrawerContentTargetingRules
}from  "../../../utilities/apiUtils/suggestionDrawer";
import {
  getCountries,
  getRegions,
  getCities,
} from "../../../utilities/apiUtils/smartSuggestions";
import { getClients } from "../../../utilities/apiUtils/cre";

const SuggestionDrawerContentTargetingRules = (props) => {
  let id = props.match.params.id;
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  const updateRulesAllowed = () => {
    return allowedPermissions.includes(permissions.SuggestionDrawerContentRulesUpdate);
  };

  return (
    <div>
      <TargetingRules
        showAgeGenderRules={true}
        showCustomAudienceTargetingRules={false}
        showPlacementRules={false}
        showClientRules={true}
        showHardFiltering={false}
        isAdvertisingAttributes={true}
        id={id}
        getRules={fetchSugggestionDrawerContentTargetingRules}
        saveRules={updateSugggestionDrawerContentTargetingRules}
        getClients={getClients}
        getCountries={getCountries}
        getRegions={getRegions}
        getCities={getCities}
        updateRulesAllowed={updateRulesAllowed()}
      />
    </div>
  );
};

export default SuggestionDrawerContentTargetingRules;

import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import CreatableSelect from 'react-select/creatable';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row, CustomInput, Badge } from "reactstrap";
import { suggestionTypeOptions, partnerTypeOptions, dateError, imageFormatError, titleLength, fileSizeExceedError, descriptionLength } from "../../../config/configSmartSuggestions";
import { staticIconSize } from "../../../config/configSuggestionDrawer";
import { X } from "react-feather";
import { iconSize } from "../../../config/UserConfig";
import ConfirmationModal from "../../../pages/ui-elements/ConfirmationModal";
import ResponseModal from "../../../pages/ui-elements/ResponseModal";
import Routes from "../../../routes/index";
import { error, errorDescription, redirectOnTokenExpiry, redirectTo, validateColor } from "../../../utilities/commonUtil";
import moment from "moment";
import ToolTip from "../../../utilities/ToolTip";
import { contentTypes, contentCategorySubTypes, showEditSuggestionDrawerCategoriesButton, showSuggestionDrawerCategoriesButton, suggestionDrawerResponses, defaultAssetWidthPercentage } from "../../../config/configSuggestionDrawer";
import { createSuggestionDrawerCategory, fetchSuggestionDrawerCategoryDataByID, updateSuggestionDrawerCategory } from "../../../utilities/apiUtils/suggestionDrawer";
import { getKeyboardPromptsLanguages } from "../../../utilities/apiUtils/keyboardPrompts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { getUTMCampaigns } from "../../../utilities/apiUtils/users";
import { repeat } from "lodash";


const ManageSuggestionDrawerCategory = (props) => {
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");
  const [changeType, setChangeType] = useState("Add");
  const [isPartnerNameOther, setIsPartnerNameOther] = useState(false)
  const [partnerNameOther, setPartnerNameOther] = useState("")
  const [contentType, setContentType] = useState([])
  const [newSuggestionDrawerId, setNewSuggestionDrawerId] = useState(0)
  const [shouldAllowMulti, setShowAllowMulti] = useState(true)
  const [macroErrors, setMacroErrors] = useState({
    deeplink: "",
    clickUrl: "",
    redirectionUrl: ""
  })
  const [translationName, setTranslationName] = useState([]);
  const [translationLanguageCode, setTranslationLanguageCode] = useState([])
  const [addLanguageCodeOption, setAddLanguageCodeOption] = useState([]);
  const [translation, setTranslation] = useState(
    [
      {
        languageCode: "",
        name: ""
      }
    ]
  );
  const [utmCampaignOptions, setUtmCampaignOptions] = useState([]);
  const [details, setDetails] = useState({
    name: "",
    description: "",
    contentTypes: contentType[0],
    redirectionalDetails: "",
    pageSize: 8,
    prefetchDistance: 2,
    suggestionDrawerAspectRatio: null,
    suggestionDrawerContentPlaceholderAspectRatio: 0,
    minAppVersion: null,
    maxAppVersion: null,
    startDateTime: "",
    endDateTime: "",
    startInterval: null,
    repeatInterval: null,
    startIndexTyped: null,
    repeatIntervalTyped: null,
    AspectRatio: null,
    text: "",
    isPaginated: false,
    enableContentPrediction: false,
    utmCampaign: null,
    Translation: [
      {
        languageCode: "",
        name: ""
      }
    ],
    suggestionDrawerCategoryRedirectionDetails: {
      id: 0,
      typingStateDeeplink: null,
      nonTypingStateDeeplink: null,
      aspectRatio: 0,
      text: null,
      imageFile: null,
      imageFilename: null,
      imageURL: ""
    }
  });

  const [cardMetadata, setCardMetadata] = useState({
    titleTextColor: {
      lightTheme: null,
      darkTheme: null
    },
    bodyTextColor: {
      lightTheme: null,
      darkTheme: null
    },
    subTextColor: {
      lightTheme: null,
      darkTheme: null
    },
    backgroundColor: {
      lightTheme: null,
      darkTheme: null
    },
    assetWidthPercentage: defaultAssetWidthPercentage,
    aspectRatio: null,
    withoutImageAspectRatio: null,
    enableShareTextInKeyboard: false,
    shareTexts: [],
    subtext: null
  });

  const [creatives, setCreatives] = useState({
    imageFile: null,
    imageURL: "",
    imageFilename: "",
    imageAspectRatio: null,
    darkModeImageFile: null,
    darkModeImageURL: "",
    darkModeImageFilename: "",
    darkModeImageFile: ""
  })
  let getdateValue = (date) => {
    if (date === null) {
      return null;
    }
    return moment(date).format("YYYY-MM-DD");
  }
  const handleLanguageCodeChange = (event, index) => {
    const { name, value } = event.target;
    let temp = [...keywords];
    temp[index][name] = value;
    setKeywords(temp);
  };
  const [keywords, setKeywords] = useState([]);
  const [visibilitySettings, setVisibilitySettings] = useState({
    isPaginated: true,
    enableContentPrediction: true,
    enableHttpCache: true,
    contentType: [],
    contentSubtype: null
  })

  useEffect(() => {
    getContentTypes()
    setLanguageCode()
  }, [])

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let setConfirmationModalState = (event) => {
    event.preventDefault();
    setConfirmationModalIsOpen(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);

    // if edit - redirect to home
    if (props.match.params.id) {
      redirectTo(Routes.suggestionDrawerDashboard.children.category.path);
    } else {
      redirectTo(Routes.suggestionDrawerDashboard.children.category.path);
    }
  };
  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  const handleDetailsChange = (event) => {
    const { name, value } = event.target;
    setDetails((prevState) => ({
      ...prevState,
      [name]: value,
      suggestionDrawerCategoryRedirectionDetails: {
        ...prevState,
        [name]: value
      }
    }));
  };

  const handleVisiblitySettingsChange = (event) => {
    const { id, checked } = event.target;
    setDetails((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
  };


  useEffect(() => {
    // check path params
    const fetchData = async () => {
      const utmCampaigns = await getUTMCampaigns();
      setUtmCampaignOptions(utmCampaigns);
      if (props.match.params.id) {
        setChangeType("Edit");
        fetchSuggestionDrawerData(props.match.params.id, utmCampaigns);
      }
    }
    fetchData();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.id]);

  const getContentTypes = () => {

    if (contentTypes && contentTypes?.length > 0) {
      const options = []
      contentTypes.forEach(type => {
        options.push({
          label: type.label,
          value: type.value
        })
      })
      setContentType(options)
    }
  }
  const setLanguageCode = async () => {
    const languageCode = await getKeyboardPromptsLanguages();
    if (languageCode?.languages?.length > 0) {
      const languageCodeOptions = languageCode.languages.map((language) => {
        return {
          value: language.code,
          label: language.name + " : " + language.code,
          target: {
            name: "languageCode",
            value: {
              value: language.code,
              label: language.name + " : " + language.code,
            },
          },
        };
      });
      setAddLanguageCodeOption(languageCodeOptions);
    }
  };
  function transformLanguageCode(object) {
    if (object.languageCode && typeof object.languageCode === 'object') {
      return {
        id: object.id || null,
        languageCode: object.languageCode.value || null,
        name: object.name || null,
        createdAt: object.createdAt || null,
        updatedAt: object.updatedAt || null,
      };
    } else {
      return object; // If the object is already in the correct format, return it unchanged
    }
  }
  const handleSubmit = async () => {
    // Convert startDateTime and endDateTime to IST formatted strings
    const startDateTimeIST = convertUTCToIST(details.startDateTime);
    const endDateTimeIST = convertUTCToIST(details.endDateTime);

    // Parse the formatted strings back into Date objects for comparison
    const startDate = new Date(startDateTimeIST);
    const endDate = new Date(endDateTimeIST);

    // Compare the dates
    if (startDate > endDate) {
      let text = dateError;
      showErrorModal(text);
      return;
    }
    if (props?.match?.params?.id === "1") {
      if (details.startIndex === null || details.startIndex === "") {
        let text = "Start Index can`t be empty";
        showErrorModal(text);
        return;
      }

      if (details.repeatInterval === null || details.repeatInterval === "") {
        let text = "Repeat Interval can`t be empty";
        showErrorModal(text);
        return;
      }

      if (details.repeatInterval > details.pageSize) {
        let text = "Repeat Interval can`t be greater than Page Size";
        showErrorModal(text);
        return;
      }

      if (details.repeatIntervalTyped > details.pageSize) {
        let text = "Repeat Interval can`t be greater than Page Size";
        showErrorModal(text);
        return;
      }
    }

    // Validate prefetchDistance
    if (details.prefetchDistance === null || details.prefetchDistance === "") {
      let text = "Prefetch Distance can`t be empty";
      showErrorModal(text);
      return;
    }

    if (!(details.prefetchDistance >= 1 && details.prefetchDistance <= details.pageSize/3)) {
      let text = "Prefetch Distance should be between 1 and Page Size/3";
      showErrorModal(text);
      return;
    }

    let inputsWithError = document.getElementsByClassName("is-invalid");
    if (inputsWithError.length !== 0) {
      let text = "Please enter correct hex code for color."
      showErrorModal(text);
      return;
    }



    let imageURL = "", imageFilename = "", providerIconFilename = "", staticIconSrc = "", animatedIconSrc = "", providerIconSrc = "";
    if (creatives.imageURL) {
      imageFilename = creatives.imageURL.startsWith("data:") ? "" : creatives.imageFilename
      imageURL = creatives.imageURL.startsWith("data:") ? creatives.imageURL : ""
    }
    // if (creatives.animatedIconSrc) {
    //   animatedIconFilename = creatives.animatedIconSrc.startsWith("data:") ? "" : creatives.animatedIconFilename
    //   animatedIconSrc = creatives.animatedIconSrc.startsWith("data:") ? creatives.animatedIconSrc : ""
    // }

    if (visibilitySettings.contentType == null) {
      let text = "Content Type can`t be empty";
      showErrorModal(text);
      return;
    }
    const contentTypes = visibilitySettings.contentType.map(item => item.label)
    const additionalInfo = {
      subtext: cardMetadata.subtext,
      titleTextColor: cardMetadata.titleTextColor,
      bodyTextColor: cardMetadata.bodyTextColor,
      subTextColor: cardMetadata.subTextColor,
      backgroundColor: cardMetadata.backgroundColor,
      assetWidthPercentage: cardMetadata.assetWidthPercentage,
      enableShareTextInKeyboard: cardMetadata.enableShareTextInKeyboard,
      aspectRatio: cardMetadata.aspectRatio,
      withoutImageAspectRatio: cardMetadata.withoutImageAspectRatio,
      shareTexts: cardMetadata?.shareTexts?.map((shareText) => {
        return {
          type: shareText?.type?.value,
          text: shareText?.text
        }
      })
    };
    const payload = {
      name: details.name,
      description: details.description,
      contentTypes: contentTypes,
      redirectionalDetails: details.redirectionalDetails,
      pageSize: Number(details.pageSize),
      prefetchDistance : Number(details.prefetchDistance),
      suggestionDrawerAspectRatio: Number(details.suggestionDrawerAspectRatio),
      suggestionDrawerContentPlaceholderAspectRatio: Number(details.suggestionDrawerContentPlaceholderAspectRatio),
      minAppVersion: (Number(details.minAppVersion) == 0) ? null : Number(details.minAppVersion),
      maxAppVersion: (Number(details.maxAppVersion) == 0) ? null : Number(details.maxAppVersion),
      translations: (keywords.map(transformLanguageCode).length >= 1) ? keywords.map(transformLanguageCode) : null,
      startDateTime: details.startDateTime,
      endDateTime: details.endDateTime,
      isPaginated: details.isPaginated,
      enableContentPrediction: details.enableContentPrediction,
      additionalInfo: contentTypes.includes("genericCard") ? JSON.stringify(additionalInfo) : null,
      contentSubtype: visibilitySettings?.contentSubtype?.value,
      enableHttpCache: details?.enableHttpCache,
      shareText: details?.shareText,
      startIndex: parseInt(details?.startIndex),
      repeatInterval: parseInt(details?.repeatInterval),
      startIndexTyped: parseInt(details?.startIndexTyped),
      repeatIntervalTyped: parseInt(details?.repeatIntervalTyped),
      utmCampaign: details?.utmCampaign?.value,
      suggestionDrawerCategoryRedirectionDetails: details.isPaginated ? null : {
        id: details.suggestionDrawerCategoryRedirectionDetails?.id,
        typingStateDeeplink: details.suggestionDrawerCategoryRedirectionDetails?.typingStateDeeplink,
        nonTypingStateDeeplink: details.suggestionDrawerCategoryRedirectionDetails?.nonTypingStateDeeplink,
        aspectRatio: Number(details.suggestionDrawerCategoryRedirectionDetails?.aspectRatio),
        text: details.suggestionDrawerCategoryRedirectionDetails?.text,
        imageFile: creatives?.imageFile,
        imageFilename: creatives?.imageFilename,
        imageURL: creatives?.imageURL,
        darkImageFile: creatives?.darkModeImageFile !== "" ? creatives?.darkModeImageFile : null,
        darkImageURL: creatives.darkModeImageURL,
        darkImageFilename: creatives.darkModeImageFilename
      }
    }
    if (props.match.params.id) {
      // update smart suggestion
      try {
        let response = await updateSuggestionDrawerCategory(props.match.params.id, payload)
        if (response === "success") {
          await setSuccessModalText(suggestionDrawerResponses.updatedSuccessfully);
          await setSuccessModalDisplay(true);
        } else {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken ||
            response.errorCode === error.unauthorized
          )
            redirectOnTokenExpiry();
          let text = errorDescription(error.unexpectedError);
          showErrorModal(text);
        }
      } catch (err) {
        let text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      }
    } else {
      try {
        let response = await createSuggestionDrawerCategory(payload)
        if (typeof response === 'number') {
          await setNewSuggestionDrawerId(response)
          await setSuccessModalText(suggestionDrawerResponses.addedSuccessfully);
          await setSuccessModalDisplay(true);
          redirectTo(Routes.suggestionDrawerDashboard.children.category.path);
        } else {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken ||
            response.errorCode === error.unauthorized
          )
            redirectOnTokenExpiry();
          let text = errorDescription(error.unexpectedError);
          showErrorModal(text);
        }
      } catch (err) {
        let text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      }
    }
  };
  const handleCreativesChange = (event, type) => {
    const { name, files, accept } = event.target;
    const acceptedFormat = accept.split(",").map(format => format.trim())
    event.persist();
    // For showing Image preview
    if (files[0]) {
      // Check for size
      if ((type === "static" && (files[0].size > staticIconSize))) {
        let text = fileSizeExceedError;
        showErrorModal(text);
        setCreatives((prev) => ({
          ...prev,
          [name + "File"]: null,
          [name + "URL"]: "",
          [name + "Filename"]: "",
        }));
        return
      }
      // Check if format is accepted
      if (!acceptedFormat.includes(files[0].type)) {
        let text = imageFormatError;
        showErrorModal(text);
        setCreatives((prev) => ({
          ...prev,
          [name + "File"]: null,
          [name + "URL"]: "",
          [name + "AspectRatio"]: null,
          [name + "Filename"]: "",
        }));
        return
      }

      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          setCreatives((prev) => ({
            ...prev,
            [name + "File"]: reader.result,
            [name + "URL"]: reader.result,
            [name + "AspectRatio"]: Number(image.width / image.height).toFixed(3),
            [name + "Filename"]: files[0]?.name,
          }));
        }
      };
    } else {
      setCreatives((prev) => ({
        ...prev,
        [name + "File"]: null,
        [name + "URL"]: "",
        [name + "AspectRatio"]: null,
        [name + "Filename"]: "",
      }));
    }
  };

  function convertUTCToIST(dateTimeString) {
    // If the input is empty or null, return null
    if (!dateTimeString) {
        return null;
    }

    // Create a new Date object from the UTC date string
    const date = new Date(dateTimeString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        return null;
    }

    // Use Intl.DateTimeFormat for proper timezone conversion
    const options = {
        timeZone: 'Asia/Kolkata',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false, // Use 24-hour format
    };

    // Convert UTC to IST using 'Asia/Kolkata' timezone
    const formatter = new Intl.DateTimeFormat('en-GB', options);
    const parts = formatter.formatToParts(date);

    // Extract the date and time components without seconds
    const year = parts.find(part => part.type === 'year').value;
    const month = parts.find(part => part.type === 'month').value;
    const day = parts.find(part => part.type === 'day').value;
    const hour = parts.find(part => part.type === 'hour').value;
    const minute = parts.find(part => part.type === 'minute').value;

    // Return formatted date-time string
    return `${year}-${month}-${day}T${hour}:${minute}`;
}

  const fetchSuggestionDrawerData = async (id, utmCampaigns) => {
    try {
      let response = await fetchSuggestionDrawerCategoryDataByID(id)
      const languageCode = await getKeyboardPromptsLanguages();
      if (response?.id) {
        const contentType = response?.ContentTypes?.map((category) => ({
          label: category,
          value: contentTypes.find(item => item.label.toLowerCase() === category.toLowerCase(),
          )?.value
        }))
        setVisibilitySettings((prevState) => ({
          ...prevState,
          contentType: contentType
        }))
        setVisibilitySettings((prevState) => ({
          ...prevState,
          enableHttpCache: response?.enableHttpCache
        })
        )
        let type = {}
        if (response?.type) {
          type = {
            label: suggestionTypeOptions.find(type => type.value === response?.type)?.label ?? response?.type,
            value: response?.type
          }
        } else {
          type = details.type
        }
        let partnerName = null
        if (partnerTypeOptions.some(partner => partner.value === response?.partnerName) && response?.partnerName !== "other") {
          // partner name exists in pre-defined options except "other"
          partnerName = {
            label: partnerTypeOptions.find(partner => partner.value === response?.partnerName)?.label ?? response?.partnerName,
            value: response?.partnerName
          }
        } else if (!response?.partnerName) {
          // check if partner name is null or ""
          partnerName = null
        } else {
          // partner name is other
          setIsPartnerNameOther(true)
          setPartnerNameOther(response?.partnerName)
          partnerName = {
            value: "other",
            label: "Other"
          }
        }
        const language = response?.Translations.map((translation) => ({
          ...translation,
          languageCode: {
            label: (languageCode.languages.find((obj) => obj.code === translation.languageCode)?.name + ":" + translation.languageCode) || translation.languageCode,
            value: translation.languageCode,
          },
          name: translation.name
        }))
        setKeywords(language)
        // setKeywords(response?.Translations)
        setDetails((prevState) => ({
          ...prevState,
          name: response?.name,
          description: response?.description,
          contentTypes: response?.contentTypes,
          redirectionalDetails: response?.redirectionalDetails,
          pageSize: response?.pageSize,
          prefetchDistance: response?.prefetchDistance,
          suggestionDrawerAspectRatio: response?.suggestionDrawerAspectRatio,
          suggestionDrawerContentPlaceholderAspectRatio: response?.suggestionDrawerContentPlaceholderAspectRatio,
          minAppVersion: response?.minAppVersion,
          maxAppVersion: response?.maxAppVersion,
          Translation: response?.Translations,
          startDateTime: response?.startDateTime
          ? convertUTCToIST(response.startDateTime)
          : "",
        endDateTime: response?.endDateTime
          ? convertUTCToIST(response.endDateTime)
          : "",
          AspectRatio: response?.AspectRatio,
          text: response?.text,
          isPaginated: response?.isPaginated,
          enableContentPrediction: response?.enableContentPrediction,
          startIndex: parseInt(response?.startIndex),
          repeatInterval: parseInt(response?.repeatInterval),
          startIndexTyped: parseInt(response?.startIndexTyped),
          repeatIntervalTyped: parseInt(response?.repeatIntervalTyped),
          shareText: response?.shareText,
          enableHttpCache: response?.enableHttpCache,
          utmCampaign: response?.utmCampaign ? utmCampaigns.find(campaign => campaign.value === response?.utmCampaign) : null,
          suggestionDrawerCategoryRedirectionDetails: {
            id: response?.suggestionDrawerCategoryRedirectionDetails?.id,
            typingStateDeeplink: response?.suggestionDrawerCategoryRedirectionDetails?.typingStateDeeplink,
            nonTypingStateDeeplink: response?.suggestionDrawerCategoryRedirectionDetails?.nonTypingStateDeeplink,
            aspectRatio: response?.suggestionDrawerCategoryRedirectionDetails?.aspectRatio,
            text: response?.suggestionDrawerCategoryRedirectionDetails?.text,
            imageFile: response?.suggestionDrawerCategoryRedirectionDetails?.imageFile,
            imageFilename: response?.suggestionDrawerCategoryRedirectionDetails?.imageFilename,
            imageURL: response?.suggestionDrawerCategoryRedirectionDetails?.imageURL
          }
        }))
        setCreatives((prevState) => ({
          ...prevState,
          imageFile: null,
          imageFilename: response?.suggestionDrawerCategoryRedirectionDetails?.imageFilename,
          imageURL: response?.suggestionDrawerCategoryRedirectionDetails?.imageURL,
          darkModeImageFile: null,
          darkModeImageURL: response?.suggestionDrawerCategoryRedirectionDetails?.darkImageURL,
          darkModeImageFilename: response?.suggestionDrawerCategoryRedirectionDetails?.darkImageFilename
        }))

        setVisibilitySettings((prevState) => ({
          ...prevState,
          contentSubtype: contentCategorySubTypes.find((subtype) => subtype.value === response?.contentSubtype)
        }))

        if (response?.additionalInfo) {
          // parse additionalInfo to json object
          response.additionalInfo = JSON.parse(response.additionalInfo)

          setCardMetadata((prevState) => ({
            ...prevState,
            titleTextColor: response?.additionalInfo?.titleTextColor,
            bodyTextColor: response?.additionalInfo?.bodyTextColor,
            subTextColor: response?.additionalInfo?.subTextColor,
            backgroundColor: response?.additionalInfo?.backgroundColor,
            assetWidthPercentage: response?.additionalInfo?.assetWidthPercentage,
            enableShareTextInKeyboard: response?.additionalInfo?.enableShareTextInKeyboard,
            aspectRatio: response?.additionalInfo?.aspectRatio,
            withoutImageAspectRatio: response?.additionalInfo?.withoutImageAspectRatio,
            shareTexts: response?.additionalInfo?.shareTexts.map((shareText) => {
              return {
                type: shareText?.type === "default" ? { label: "Default", value: "default" } : shareText?.type === "custom" ? { label: "Custom", value: "custom" } : null,
                text: shareText?.text
              }
            }),
            subtext: response?.additionalInfo?.subtext
          }));
        }


      } else {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken ||
          response.errorCode === error.unauthorized
        )
          redirectOnTokenExpiry();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container fluid className="p-0">
      <Row className="mb-3">
        <Col md={6} xs={6}>
          <h1>{changeType} Suggestion Drawer Category</h1>
        </Col>
      </Row>

      <Form onSubmit={(event) => setConfirmationModalState(event)}>
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <Row form>
                  <Col lg={4} md={6}>
                    <FormGroup className="d-flex align-items-center">
                      <Label
                        className="font-weight-bolder mb-0"
                        style={{ fontSize: "14px" }}
                      >
                        Is Paginated
                      </Label>
                      <div className="custom-control custom-switch">
                        <CustomInput
                          type="checkbox"
                          id="isPaginated"
                          value={details.isPaginated}
                          onChange={handleVisiblitySettingsChange}
                          checked={details.isPaginated}
                          disabled={!showEditSuggestionDrawerCategoriesButton()}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col lg={4} md={6}>
                    <FormGroup className="d-flex align-items-center">
                      <Label
                        className="font-weight-bolder mb-0"
                        style={{ fontSize: "14px" }}
                      >
                        Enable HTTP Cache
                      </Label>
                      <div className="custom-control custom-switch">
                        <CustomInput
                          type="checkbox"
                          id="enableHttpCache"
                          value={details.enableHttpCache}
                          onChange={handleVisiblitySettingsChange}
                          checked={details.enableHttpCache}
                          disabled={!showEditSuggestionDrawerCategoriesButton()}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col lg={4} md={6}>
                    <FormGroup className="d-flex align-items-center">
                      <Label
                        className="font-weight-bolder mb-0"
                        style={{ fontSize: "14px" }}
                      >
                        Enable Content Prediction
                      </Label>
                      <div className="custom-control custom-switch">
                        <CustomInput
                          type="checkbox"
                          id="enableContentPrediction"
                          value={details.enableContentPrediction}
                          onChange={handleVisiblitySettingsChange}
                          checked={details.enableContentPrediction}
                          disabled={!showEditSuggestionDrawerCategoriesButton()}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col lg={6} md={6}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "14px" }}
                      >
                        Name <span className="text-danger">*</span><small className="text-info"> (Upto {titleLength} characters)</small>
                      </Label>
                      <ToolTip
                        data="Name of Category"
                        id="name"
                      >
                      </ToolTip>
                      <Input
                        type="textarea"
                        name="name"
                        placeholder="Enter Title"
                        value={details.name}
                        onChange={(event) => {
                          const { value } = event.target
                          if (value.length > titleLength) {
                            event.preventDefault();
                            return;
                          }
                          handleDetailsChange(event)
                        }}
                        disabled={!showEditSuggestionDrawerCategoriesButton()}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6} md={6}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "14px" }}
                      >
                        Description <small className="text-info">(Upto {descriptionLength} characters)</small>
                      </Label>
                      <ToolTip
                        data="Description"
                        id="description"
                      >
                      </ToolTip>
                      <Input
                        type="textarea"
                        name="description"
                        placeholder="Enter Description"
                        value={details.description}
                        onChange={(event) => {
                          const { value } = event.target
                          if (value.length > descriptionLength) {
                            event.preventDefault();
                            return;
                          }
                          handleDetailsChange(event)
                        }}
                        disabled={!showEditSuggestionDrawerCategoriesButton()}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6} md={6}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "14px" }}
                      >
                        Share Text
                      </Label>
                      <Input
                        type="textarea"
                        name="shareText"
                        placeholder="Enter Share Text"
                        value={details.shareText}
                        onChange={(event) => {
                          handleDetailsChange(event)
                        }}
                        disabled={!showEditSuggestionDrawerCategoriesButton()}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6} md={6}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "14px" }}
                      >
                        Redirection Details
                      </Label>
                      <ToolTip
                        data="Redirection Details"
                        id="redirectionalDetails"
                      >
                      </ToolTip>
                      <Input
                        type="textarea"
                        name="redirectionalDetails"
                        placeholder="Enter Redirection Details"
                        value={details.redirectionalDetails}
                        onChange={(event) => {
                          handleDetailsChange(event)
                        }}
                        disabled={!showEditSuggestionDrawerCategoriesButton()}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6} md={6}>
                    <Label>
                      Page Size<span className="text-danger">*</span><small className="text-info"></small>
                    </Label>
                    <ToolTip
                      data="Page Size"
                      id="pageSize"
                    >
                    </ToolTip>
                    <Input
                      type="number"
                      className="pl-4"
                      step={1}
                      name="pageSize"
                      min={0}
                      placeholder="Page Size"
                      value={details.pageSize}
                      onChange={handleDetailsChange}
                      disabled={!showEditSuggestionDrawerCategoriesButton()}
                      required
                    />
                  </Col>
                  <Col lg={3} md={6}>
                    <Label>
                      Suggestion Drawer Aspect Ratio<span className="text-danger">*</span><small className="text-info"></small>
                    </Label>
                    <ToolTip
                      data="Suggestion Drawer Aspect Ratio"
                      id="suggestionDrawerAspectRatio"
                    >
                    </ToolTip>
                    <Input
                      type="number"
                      className="pl-4"
                      step={0.000001}
                      name="suggestionDrawerAspectRatio"
                      placeholder="Suggestion Drawer Aspect Ratio "
                      defaultValue={details.suggestionDrawerAspectRatio}
                      onChange={handleDetailsChange}
                      disabled={!showEditSuggestionDrawerCategoriesButton()}
                      required
                    />
                  </Col>
                  <Col lg={3} md={6}>
                    <Label>
                      Suggestion Drawer Content AspectRatio<span className="text-danger">*</span><small className="text-info"></small>
                    </Label>
                    <Input
                      type="number"
                      className="pl-4"
                      step={0.0001}
                      name="suggestionDrawerContentPlaceholderAspectRatio"
                      placeholder="Suggestion Drawer Content AspectRatio"
                      value={details.suggestionDrawerContentPlaceholderAspectRatio}
                      onChange={handleDetailsChange}
                      disabled={!showEditSuggestionDrawerCategoriesButton()}
                      required
                    />
                  </Col>
                  <Col lg={3} md={6}>
                    <Label>
                      Min App Version
                    </Label>
                    <ToolTip
                      data="Min App Version used for version check"
                      id="minAppVersion"
                    >
                    </ToolTip>
                    <Input
                      type="number"
                      className="pl-4"
                      step={1}
                      name="minAppVersion"
                      min={1}
                      placeholder="Min App Version"
                      defaultValue={details.minAppVersion}
                      onChange={handleDetailsChange}
                      onKeyPress={(e) => {
                        // Allow only digits and prevent other characters
                        const isValidKey = /^\d+$/.test(e.key);
                        if (!isValidKey) {
                          e.preventDefault();
                        }
                      }}
                      disabled={!showEditSuggestionDrawerCategoriesButton()}
                      required
                    />
                  </Col>
                    <Col lg={3} md={6}>
                      <Label>
                        Max App Version
                      </Label>
                      <ToolTip
                        data="Max App Version used for version check"
                        id="maxAppVersion"
                      >
                      </ToolTip>
                      <Input
                        type="number"
                        className="pl-4"
                        step={1}
                        name="maxAppVersion"
                        min={0}
                        placeholder="Max App Version"
                        defaultValue={details.maxAppVersion}
                        onChange={handleDetailsChange}
                        disabled={!showEditSuggestionDrawerCategoriesButton()}
                        onKeyPress={(e) => {
                          // Allow only digits and prevent other characters
                          const isValidKey = /^\d+$/.test(e.key);
                          if (!isValidKey) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Col>
                    <Col lg={3} md={6}>
                      <Label>
                        Prefetch Distance<span className="text-danger">*</span>
                      </Label>
                      <ToolTip
                        data="Number of pages to prefetch. It should be between 1 and Page Size/3"
                        id="prefetchDistance"
                      >
                      </ToolTip>
                      <Input
                        type="number"
                        className="pl-4"
                        step={1}
                        name="prefetchDistance"
                        min={1}
                        placeholder="Prefetch Distance"
                        value={details.prefetchDistance}
                        onChange={handleDetailsChange}
                        disabled={!showEditSuggestionDrawerCategoriesButton()}
                        onKeyPress={(e) => {
                          // Allow only digits and prevent other characters
                          const isValidKey = /^\d+$/.test(e.key);
                          if (!isValidKey) {
                            e.preventDefault();
                          }
                        }}
                        required
                      />
                    </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "14px" }}
                      >
                        Content Types<span className="text-danger">*</span><small className="text-info"></small>
                      </Label>
                      <ToolTip
                        data="Only Generic , Story and memes are allways single selected"
                        id="contentTypes"
                      >

                      </ToolTip>
                      <Select
                        id="contentType"
                        isMulti
                        className="react-select-container"
                        classNamePrefix="react-select"
                        name="contentType"
                        placeholder="Select Content Type"
                        value={visibilitySettings.contentType}
                        onChange={(event) => {
                          // Check if event is an array before using .some()
                          if (Array.isArray(event)) {

                            const isMemesSelected = event.some(
                              (option) => option.label === "memes"
                            );
                            const isStorySelected = event.some(
                              (option) => option.label === "story"
                            );
                            if (isMemesSelected) {
                              setVisibilitySettings((prevState) => ({
                                contentType: [
                                  {
                                    label: "memes",
                                    value: 9,
                                  },
                                ],
                              }));
                            } else if (isStorySelected) {
                              setVisibilitySettings((prevState) => ({
                                contentType: [
                                  {
                                    label: "story",
                                    value: 8,
                                  },
                                ],
                              }));
                            } else {
                              setVisibilitySettings((prevState) => ({
                                ...prevState,
                                contentType: event,
                              }));
                            }

                            if (
                              !event.some((option) => option.label === "genericCard")
                            ) {
                              setVisibilitySettings((prevState) => ({
                                ...prevState,
                                contentSubtype: null,
                              }));
                            }
                          } else {
                            // Handle single object selection
                            setVisibilitySettings((prevState) => ({
                              contentType: [event], // Set as an array with one element
                            }));
                          }
                        }}
                        options={contentType}
                        isDisabled={!showEditSuggestionDrawerCategoriesButton()}
                      />
                    </FormGroup>
                  </Col>
                  {/* if visibilitySettings.contentType contains genericCard then render this select type */}
                  {visibilitySettings?.contentType && visibilitySettings?.contentType?.some((option) => option?.label === "genericCard") &&
                    <Col lg={4} md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Content Category Sub Types
                        </Label>
                        <ToolTip
                          data="Content Category Sub Types"
                          id="contentSubtype"
                        >
                        </ToolTip>
                        <Select
                          id="contentSubtype"
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="contentSubtype"
                          placeholder="Select Content Category Sub Types"
                          value={visibilitySettings.contentSubtype}
                          onChange={(event) => {
                            setVisibilitySettings((prevState) => ({
                              ...prevState,
                              contentSubtype: event,
                            }));
                          }}
                          options={contentCategorySubTypes}
                          isDisabled={!showEditSuggestionDrawerCategoriesButton()}
                        />
                      </FormGroup>
                    </Col>
                  }


                  <Col lg={4} md={6}>
                    <FormGroup>
                      <Label>
                        UTM Campaign
                      </Label>
                      <ToolTip
                        data="UTM Campaign"
                        id="utmCampaign"
                      >
                      </ToolTip>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={utmCampaignOptions}
                        placeholder="Select UTM Campaign"
                        value={details.utmCampaign}
                        isClearable
                        onChange={(value) => {
                          setDetails((prevState) => ({
                            ...prevState,
                            utmCampaign: value
                          }))
                        }
                        }
                        isDisabled={!showSuggestionDrawerCategoriesButton()}
                      />

                    </FormGroup>
                  </Col>


                 
                  {/* New Start DateTime Field */}
                  <Col lg={6} md={6}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "14px" }}
                      >
                        Start Date and Time
                      </Label>
                      <Input
                        type="datetime-local"
                        name="startDateTime"
                        value={details.startDateTime}
                        onChange={handleDetailsChange}
                      />
                    </FormGroup>
                  </Col>
                  {/* New End DateTime Field */}
                  <Col lg={6} md={6}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "14px" }}
                      >
                        End Date and Time
                      </Label>
                      <Input
                        type="datetime-local"
                        name="endDateTime"
                        value={details.endDateTime}
                        onChange={handleDetailsChange}
                      />
                    </FormGroup>
                  </Col>

                  {props?.match?.params?.id === "1" && (
                    <>
                      <Col lg={6} md={6}>
                        <FormGroup>
                          <Label
                            className="font-weight-bolder mb-2"
                            style={{ fontSize: "14px" }}
                          >
                            Start Position Non-Typing State <span className="text-danger">*</span>
                          </Label>
                          <ToolTip
                            data="Start Position of Generic and Generic Card Content in non-typing state. Numbering starts from 1"
                            id="startIndex"
                          ></ToolTip>
                          <Input
                            type="number"
                            name="startIndex"
                            placeholder="Enter Start Index"
                            value={details.startIndex}
                            onChange={handleDetailsChange}
                            disabled={!showEditSuggestionDrawerCategoriesButton()}
                            min={1}
                            required
                          />
                        </FormGroup>
                      </Col>

                      <Col lg={6} md={6}>
                        <FormGroup>
                          <Label
                            className="font-weight-bolder mb-2"
                            style={{ fontSize: "14px" }}
                          >
                            Repeat Interval Non-Typing State <span className="text-danger">*</span>
                          </Label>
                          <ToolTip
                            data="Repeat Interval of Generic and Generic Card Content in non-typing state. This value should not exceed page size."
                            id="repeatInterval"
                          ></ToolTip>
                          <Input
                            type="number"
                            name="repeatInterval"
                            placeholder="Enter Repeat Interval"
                            value={details.repeatInterval}
                            onChange={handleDetailsChange}
                            min={1}
                            max={details.pageSize}
                            disabled={!showEditSuggestionDrawerCategoriesButton()}
                            required
                          />
                        </FormGroup>
                      </Col>

                      <Col lg={6} md={6}>
                        <FormGroup>
                          <Label
                            className="font-weight-bolder mb-2"
                            style={{ fontSize: "14px" }}
                          >
                            Start Position Typing State
                          </Label>
                          <ToolTip
                            data="Start Position of Generic and Generic Card Content in typing state. Numbering starts from 1"
                            id="startIndexTyped"
                          ></ToolTip>
                          <Input
                            type="number"
                            name="startIndexTyped"
                            placeholder="Enter Start Index in Typing State"
                            value={details.startIndexTyped}
                            onChange={handleDetailsChange}
                            disabled={!showEditSuggestionDrawerCategoriesButton()}
                            min={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col lg={6} md={6}>
                        <FormGroup>
                          <Label
                            className="font-weight-bolder mb-2"
                            style={{ fontSize: "14px" }}
                          >
                            Repeat Interval Typing State
                          </Label>
                          <ToolTip
                            data="Repeat Interval of Generic and Generic Card Content in Typing State. This value should not exceed page size."
                            id="repeatIntervalTyped"
                          ></ToolTip>
                          <Input
                            type="number"
                            name="repeatIntervalTyped"
                            placeholder="Enter Repeat Interval in Typing State"
                            value={details.repeatIntervalTyped}
                            onChange={handleDetailsChange}
                            min={1}
                            max={details.pageSize}
                            disabled={!showEditSuggestionDrawerCategoriesButton()}
                          />
                        </FormGroup>
                      </Col>
                    </>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {visibilitySettings?.contentType && visibilitySettings?.contentType?.some((option) => option?.label === "genericCard") && (
          <Row>
            <Col lg="12">
              <Row className="mb-3">
                <Col md={6} xs={6}>
                  <h3>Card Metadata</h3>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          <strong>
                            Subtext
                          </strong>
                        </Label>
                        <Input
                          type="text"
                          name="subtext"
                          placeholder="Enter Subtext"
                          disabled={!showEditSuggestionDrawerCategoriesButton()}
                          value={cardMetadata.subtext}
                          onChange={(event) => {
                            const { name, value } = event.target;
                            setCardMetadata((prevState) => ({
                              ...prevState,
                              subtext: value,
                            }));
                          }
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          <strong>
                            Light Theme Title Text Color{" "}<span className="text-warning text-sm">(Enter Hex Code starting with #)</span>
                            {validateColor(cardMetadata.titleTextColor.lightTheme) ? (
                              <div
                                style={{
                                  backgroundColor:
                                    cardMetadata.titleTextColor.lightTheme,
                                  width: "16px",
                                  height: "16px",
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                  border: "1px solid #000",
                                  borderRadius: "30%",
                                }}
                              >
                                {" "}
                              </div>
                            ) : (
                              <small className="text-danger ml-2">
                                {"Invalid Hex Code"}
                              </small>
                            )}

                          </strong>
                        </Label>
                        <Input
                          type="text"
                          name="textColor"
                          className={
                            validateColor(cardMetadata.titleTextColor.lightTheme)
                              ? "form-control is-valid"
                              : "form-control is-invalid"
                          }
                          placeholder="Enter Text Color"
                          disabled={!showEditSuggestionDrawerCategoriesButton()}
                          value={
                            cardMetadata.titleTextColor.lightTheme === null ? null : cardMetadata.titleTextColor.lightTheme
                          }
                          onChange={(event) => {
                            const { name, value } = event.target;
                            setCardMetadata((prevState) => ({
                              ...prevState,
                              titleTextColor: {
                                ...prevState.titleTextColor,
                                lightTheme: value,
                              },
                            }));
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          <strong>
                            Dark Theme Title Text Color{" "}<span className="text-warning text-sm">(Enter Hex Code starting with #)</span>
                            {validateColor(cardMetadata.titleTextColor.darkTheme) ? (
                              <div
                                style={{
                                  backgroundColor:
                                    cardMetadata.titleTextColor.darkTheme,
                                  width: "16px",
                                  height: "16px",
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                  border: "1px solid #000",
                                  borderRadius: "30%",
                                }}
                              >
                                {" "}
                              </div>
                            ) : (
                              <small className="text-danger ml-2">
                                {"Invalid Hex Code"}
                              </small>
                            )}

                          </strong>
                        </Label>
                        <Input
                          type="text"
                          name="textColor"
                          className={
                            validateColor(cardMetadata.titleTextColor.darkTheme)
                              ? "form-control is-valid"
                              : "form-control is-invalid"
                          }
                          placeholder="Enter Text Color"
                          disabled={!showEditSuggestionDrawerCategoriesButton()}
                          value={
                            cardMetadata.titleTextColor.darkTheme === null ? null : cardMetadata.titleTextColor.darkTheme
                          }
                          onChange={(event) => {
                            const { name, value } = event.target;
                            setCardMetadata((prevState) => ({
                              ...prevState,
                              titleTextColor: {
                                ...prevState.titleTextColor,
                                darkTheme: value,
                              },
                            }));
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          <strong>
                            Light Theme Body Text Color{" "}<span className="text-warning text-sm">(Enter Hex Code starting with #)</span>
                            {validateColor(cardMetadata.bodyTextColor.lightTheme) ? (
                              <div
                                style={{
                                  backgroundColor:
                                    cardMetadata.bodyTextColor.lightTheme,
                                  width: "16px",
                                  height: "16px",
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                  border: "1px solid #000",
                                  borderRadius: "30%",
                                }}
                              >
                                {" "}
                              </div>
                            ) : (
                              <small className="text-danger ml-2">
                                {"Invalid Hex Code"}
                              </small>
                            )}

                          </strong>
                        </Label>
                        <Input
                          type="text"
                          name="textColor"
                          className={
                            validateColor(cardMetadata.bodyTextColor.lightTheme)
                              ? "form-control is-valid"
                              : "form-control is-invalid"
                          }
                          placeholder="Enter Text Color"
                          disabled={!showEditSuggestionDrawerCategoriesButton()}
                          value={
                            cardMetadata.bodyTextColor.lightTheme === null ? null : cardMetadata.bodyTextColor.lightTheme
                          }
                          onChange={(event) => {
                            const { name, value } = event.target;
                            setCardMetadata((prevState) => ({
                              ...prevState,
                              bodyTextColor: {
                                ...prevState.bodyTextColor,
                                lightTheme: value,
                              },
                            }));
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          <strong>
                            Dark Theme Body Text Color{" "}<span className="text-warning text-sm">(Enter Hex Code starting with #)</span>
                            {validateColor(cardMetadata.bodyTextColor.darkTheme) ? (
                              <div
                                style={{
                                  backgroundColor:
                                    cardMetadata.bodyTextColor.darkTheme,
                                  width: "16px",
                                  height: "16px",
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                  border: "1px solid #000",
                                  borderRadius: "30%",
                                }}
                              >
                                {" "}
                              </div>
                            ) : (
                              <small className="text-danger ml-2">
                                {"Invalid Hex Code"}
                              </small>
                            )}

                          </strong>
                        </Label>
                        <Input
                          type="text"
                          name="textColor"
                          className={
                            validateColor(cardMetadata.bodyTextColor.darkTheme)
                              ? "form-control is-valid"
                              : "form-control is-invalid"
                          }
                          placeholder="Enter Text Color"
                          disabled={!showEditSuggestionDrawerCategoriesButton()}
                          value={
                            cardMetadata.bodyTextColor.darkTheme === null ? null : cardMetadata.bodyTextColor.darkTheme
                          }
                          onChange={(event) => {
                            const { name, value } = event.target;
                            setCardMetadata((prevState) => ({
                              ...prevState,
                              bodyTextColor: {
                                ...prevState.bodyTextColor,
                                darkTheme: value,
                              },
                            }));
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          <strong>
                            Light Theme Background Color{" "}<span className="text-warning text-sm">(Enter Hex Code starting with #)</span>
                            {validateColor(cardMetadata.backgroundColor.lightTheme) ? (
                              <div
                                style={{
                                  backgroundColor:
                                    cardMetadata.backgroundColor.lightTheme,
                                  width: "16px",
                                  height: "16px",
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                  border: "1px solid #000",
                                  borderRadius: "30%",
                                }}
                              >
                                {" "}
                              </div>
                            ) : (
                              <small className="text-danger ml-2">
                                {"Invalid Hex Code"}
                              </small>
                            )}

                          </strong>
                        </Label>
                        <Input
                          type="text"
                          name="backgroundColor"
                          className={
                            validateColor(cardMetadata.backgroundColor.lightTheme)
                              ? "form-control is-valid"
                              : "form-control is-invalid"
                          }
                          placeholder="Enter Background Color"
                          disabled={!showEditSuggestionDrawerCategoriesButton()}
                          value={
                            cardMetadata.backgroundColor.lightTheme === null ? null : cardMetadata.backgroundColor.lightTheme
                          }
                          onChange={(event) => {
                            const { name, value } = event.target;
                            setCardMetadata((prevState) => ({
                              ...prevState,
                              backgroundColor: {
                                ...prevState.backgroundColor,
                                lightTheme: value,
                              },
                            }));
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          <strong>
                            Dark Theme Background Color{" "}<span className="text-warning text-sm">(Enter Hex Code starting with #)</span>
                            {validateColor(cardMetadata.backgroundColor.darkTheme) ? (
                              <div
                                style={{
                                  backgroundColor:
                                    cardMetadata.backgroundColor.darkTheme,
                                  width: "16px",
                                  height: "16px",
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                  border: "1px solid #000",
                                  borderRadius: "30%",
                                }}
                              >
                                {" "}
                              </div>
                            ) : (
                              <small className="text-danger ml-2">
                                {"Invalid Hex Code"}
                              </small>
                            )}

                          </strong>
                        </Label>
                        <Input
                          type="text"
                          name="backgroundColor"
                          className={
                            validateColor(cardMetadata.backgroundColor.darkTheme)
                              ? "form-control is-valid"
                              : "form-control is-invalid"
                          }
                          placeholder="Enter Background Color"
                          disabled={!showEditSuggestionDrawerCategoriesButton()}
                          value={
                            cardMetadata.backgroundColor.darkTheme === null ? null : cardMetadata.backgroundColor.darkTheme
                          }
                          onChange={(event) => {
                            const { name, value } = event.target;
                            setCardMetadata((prevState) => ({
                              ...prevState,
                              backgroundColor: {
                                ...prevState.backgroundColor,
                                darkTheme: value,
                              },
                            }));
                          }}
                        />
                      </FormGroup>
                    </Col>
                    {/* Do for subText */}
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          <strong>
                            Light Theme Sub Text Color{" "}<span className="text-warning text-sm">(Enter Hex Code starting with #)</span>
                            {validateColor(cardMetadata.subTextColor.lightTheme) ? (
                              <div
                                style={{
                                  backgroundColor:
                                    cardMetadata.subTextColor.lightTheme,
                                  width: "16px",
                                  height: "16px",
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                  border: "1px solid #000",
                                  borderRadius: "30%",
                                }}
                              >
                                {" "}
                              </div>
                            ) : (
                              <small className="text-danger ml-2">
                                {"Invalid Hex Code"}
                              </small>
                            )}

                          </strong>
                        </Label>
                        <Input
                          type="text"
                          name="textColor"
                          className={
                            validateColor(cardMetadata.subTextColor.lightTheme)
                              ? "form-control is-valid"
                              : "form-control is-invalid"
                          }
                          placeholder="Enter Text Color"
                          disabled={!showEditSuggestionDrawerCategoriesButton()}
                          value={
                            cardMetadata.subTextColor.lightTheme === null ? null : cardMetadata.subTextColor.lightTheme
                          }
                          onChange={(event) => {
                            const { name, value } = event.target;
                            setCardMetadata((prevState) => ({
                              ...prevState,
                              subTextColor: {
                                ...prevState.subTextColor,
                                lightTheme: value,
                              },
                            }));
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          <strong>
                            Dark Theme Sub Text Color{" "}<span className="text-warning text-sm">(Enter Hex Code starting with #)</span>
                            {validateColor(cardMetadata.subTextColor.darkTheme) ? (
                              <div
                                style={{
                                  backgroundColor:
                                    cardMetadata.subTextColor.darkTheme,
                                  width: "16px",
                                  height: "16px",
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                  border: "1px solid #000",
                                  borderRadius: "30%",
                                }}
                              >
                                {" "}
                              </div>
                            ) : (
                              <small className="text-danger ml-2">
                                {"Invalid Hex Code"}
                              </small>
                            )}

                          </strong>
                        </Label>
                        <Input
                          type="text"
                          name="textColor"
                          className={
                            validateColor(cardMetadata.subTextColor.darkTheme)
                              ? "form-control is-valid"
                              : "form-control is-invalid"
                          }
                          placeholder="Enter Text Color"
                          disabled={!showEditSuggestionDrawerCategoriesButton()}
                          value={
                            cardMetadata.subTextColor.darkTheme === null ? null : cardMetadata.subTextColor.darkTheme
                          }
                          onChange={(event) => {
                            const { name, value } = event.target;
                            setCardMetadata((prevState) => ({
                              ...prevState,
                              subTextColor: {
                                ...prevState.subTextColor,
                                darkTheme: value,
                              },
                            }));
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          <strong>
                            Asset Width Percentage{" "}<span className="text-warning text-sm">(Enter Percentage)</span>
                          </strong>
                        </Label>
                        <Input
                          type="number"
                          name="assetWidthPercentage"
                          min={0}
                          max={1}
                          step={0.01}
                          placeholder="Enter Asset Width Percentage"
                          value={cardMetadata.assetWidthPercentage}
                          onChange={(event) => {
                            const { name, value } = event.target;
                            setCardMetadata((prevState) => ({
                              ...prevState,
                              assetWidthPercentage: parseFloat(value),
                            }));
                          }}
                          disabled={!showEditSuggestionDrawerCategoriesButton()}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          <strong>
                            Aspect Ratio
                          </strong>
                        </Label>
                        <Input
                          type="number"
                          name="aspectRatio"
                          step={0.01}
                          placeholder="Enter Aspect Ratio"
                          value={cardMetadata.aspectRatio}
                          onChange={(event) => {
                            const { name, value } = event.target;
                            setCardMetadata((prevState) => ({
                              ...prevState,
                              aspectRatio: parseFloat(value),
                            }));
                          }}
                          disabled={!showEditSuggestionDrawerCategoriesButton()}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          <strong>
                            Without Image Aspect Ratio
                          </strong>
                        </Label>
                        <Input
                          type="number"
                          name="withoutImageAspectRatio"
                          step={0.01}
                          placeholder="Enter Without Image Aspect Ratio"
                          value={cardMetadata.withoutImageAspectRatio}
                          onChange={(event) => {
                            const { name, value } = event.target;
                            setCardMetadata((prevState) => ({
                              ...prevState,
                              withoutImageAspectRatio: parseFloat(value),
                            }));
                          }}
                          disabled={!showEditSuggestionDrawerCategoriesButton()}
                        />
                      </FormGroup>
                    </Col>
                    {/* enableShareTextInKeyboard create a toggle button */}
                    <Col md={6}>
                      <FormGroup className="d-flex align-items-center">
                        <Label
                          className="font-weight-bolder mb-0"
                          style={{ fontSize: "14px" }}
                        >
                          Enable Share Text In Keyboard
                        </Label>
                        <div className="custom-control custom-switch">
                          <CustomInput
                            type="checkbox"
                            id="enableShareTextInKeyboard"
                            value={cardMetadata.enableShareTextInKeyboard}
                            onChange={(event) => {
                              const { name, checked } = event.target;
                              setCardMetadata((prevState) => ({
                                ...prevState,
                                enableShareTextInKeyboard: checked,
                              }));
                            }
                            }
                            checked={cardMetadata.enableShareTextInKeyboard}
                            disabled={!showEditSuggestionDrawerCategoriesButton()}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <h1 className="h3 mb-3">Share Texts</h1>
                    </Col>
                    <Col md={6}>
                      <Button className="float-right" onClick={
                        () => {
                          setCardMetadata((prevState) => ({
                            ...prevState,
                            shareTexts: prevState.shareTexts.concat({
                              type: null,
                              text: null,
                            })
                          }))
                        }

                      }
                        disabled={!showEditSuggestionDrawerCategoriesButton()}>
                        <FontAwesomeIcon size="sm" icon={faPlus}></FontAwesomeIcon>
                        {" "}Add Share Texts
                      </Button>
                    </Col>
                  </Row>

                  <Card>
                    <CardBody>
                      {cardMetadata?.shareTexts?.map((item, index) => (
                        <Card key={`card-${index}`} className="p-3 mb-5" style={{
                          boxShadow: '0 0 20px rgba(0,0,0,0.4)',  // Increased shadow
                          borderRadius: '15px'
                        }}>
                          <Row key={`row-${index}`}>
                            <Col>
                              <FormGroup>
                                <Label>
                                  <strong>
                                    Type
                                  </strong>
                                </Label>
                                <Select
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  name="type"
                                  placeholder="Select Type"
                                  value={item.type}
                                  onChange={(event) => {
                                    setCardMetadata((prevState) => ({
                                      ...prevState,
                                      shareTexts: prevState.shareTexts.map((shareText, i) => {
                                        if (i === index) {
                                          return {
                                            ...shareText,
                                            type: event,
                                          }
                                        }
                                        return shareText;
                                      })
                                    }))
                                  }}
                                  options={[{
                                    label: "Default",
                                    value: "default"
                                  },
                                  {
                                    label: "Custom",
                                    value: "custom"
                                  }]}
                                />
                              </FormGroup>
                            </Col>
                            <Col>
                              {(showEditSuggestionDrawerCategoriesButton()) && (
                                <X
                                  className="float-right"
                                  size={20}
                                  color="red"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setCardMetadata((prevState) => ({
                                      ...prevState,
                                      shareTexts: prevState.shareTexts.filter((_, i) => i !== index)
                                    }))
                                  }}
                                ></X>
                              )}
                            </Col>
                          </Row>
                          <Row key={`row-${index}-2`}>
                            <Col>
                              <FormGroup>
                                <Label>
                                  <strong>
                                    Text
                                  </strong>
                                </Label>
                                <Input
                                  type="textarea"
                                  name="text"

                                  placeholder="Enter Text"
                                  value={item.text}
                                  onChange={(event) => {
                                    const { name, value } = event.target;
                                    setCardMetadata((prevState) => ({
                                      ...prevState,
                                      shareTexts: prevState.shareTexts.map((shareText, i) => {
                                        if (i === index) {
                                          return {
                                            ...shareText,
                                            text: value,
                                          }
                                        }
                                        return shareText;
                                      })
                                    }))
                                  }}
                                  disabled={!showEditSuggestionDrawerCategoriesButton()}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Card>
                      ))}
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}






        <Row>
          <Col md={6} xs={6}>
          </Col>
          <Col md={6} xs={6}>
            <div className="p-0">
              <Button
                color="success"
                className="ml-auto d-flex btn-success"
                onClick={() =>
                  setKeywords(
                    keywords.concat({
                      languageCode: "",
                      name: "",
                    })
                  )
                }
              >
                <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                  Add Language
                </p>
              </Button>
            </div>
          </Col>
          <Card>
            <CardBody>
              {keywords?.map((item, index) => {
                return (
                  <Row key={index} form>
                    <Col md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "16px" }}
                        >
                          Language Code:{" "}
                        </Label>
                        <Select
                          id="languageCode"
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="languageCode"
                          placeholder="Select Language Code"
                          value={item.languageCode}
                          onChange={(event) =>
                            handleLanguageCodeChange(event, index)

                          }
                          options={addLanguageCodeOption}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <div className="p-0" style={{ marginRight: "20px" }}>
                        <X
                          className="d-none d-lg-block ml-auto d-flex"
                          size={iconSize}
                          color="red"
                          style={{
                            cursor: "pointer",
                            border: "2px solid red",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            setKeywords(
                              keywords.filter((_, i) => i !== index)
                            );
                          }}
                        ></X>
                      </div>
                    </Col>
                    <Col md={12}>
                      <Col md={12}>
                        <FormGroup>
                          <Input
                            type="name"
                            id="name"
                            className="react-select-container"
                            classNamePrefix="react-select"
                            name="name"
                            placeholder="name"
                            value={item.name}
                            onChange={(event) =>
                              handleLanguageCodeChange(event, index)
                            }
                            isDisabled={false}
                          />
                        </FormGroup>
                      </Col>
                    </Col>
                    <hr
                      style={{
                        width: "75rem",
                        color: "black",
                      }}
                    />
                  </Row>
                );
              })}
            </CardBody>
          </Card>
        </Row>
        {!details.isPaginated && (

          <Row>
            <Col lg="12">
              <Row className="mb-3">
                <Col md={6} xs={6}>
                  <h3>Redirection Details<span className="text-danger">*</span></h3>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <Row form>
                    <Col lg={6} md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Typing State Deeplink
                        </Label>
                        <ToolTip
                          data="Typing State Deeplink"
                          id="typingStateDeeplink"
                        >
                        </ToolTip>
                        <Input
                          type="text"
                          id="typing State Deeplink"
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="typingStateDeeplink"
                          placeholder="text"
                          value={details.suggestionDrawerCategoryRedirectionDetails.typingStateDeeplink}
                          onChange={handleDetailsChange}
                          isDisabled={!showEditSuggestionDrawerCategoriesButton()}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Non-Typing State Deeplink
                        </Label>
                        <ToolTip
                          data="Non Typing State Deeplink"
                          id="nonTypingStateDeeplink"
                        >
                        </ToolTip>
                        <Input
                          type="text"
                          id="non Typing State Deeplink"
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="nonTypingStateDeeplink"
                          placeholder="text"
                          value={details.suggestionDrawerCategoryRedirectionDetails.nonTypingStateDeeplink}
                          onChange={handleDetailsChange}
                          isDisabled={!showEditSuggestionDrawerCategoriesButton()}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label>
                          <strong>
                            Image
                          </strong>
                          <ToolTip
                            data={
                              details.type?.value === "product"
                                ? "Formats: png, jpg, jpeg, webp, Aspect ratio: any, Size: 100KB"
                                : "Formats: png, jpg, jpeg, webp, Aspect ratio: 1:1, Size: 100KB"
                            }
                            id="staticIconTooltip"
                          />
                        </Label>
                        <Container className="custom-file">
                          <Input
                            type="file"
                            className="custom-file-input"
                            accept="image/png, image/jpg, image/jpeg, image/webp"
                            name="image"
                            onChange={(event) => {
                              handleCreativesChange(event, "static")
                            }}
                            disabled={!showEditSuggestionDrawerCategoriesButton()}
                          />
                          <Label className="custom-file-label">
                            <strong>{creatives.imageFilename || "Choose a static image"}</strong>
                          </Label>
                        </Container>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label>
                          <strong>
                            Dark Theme Static Icon
                          </strong>
                          <ToolTip
                            data={
                              details.type?.value === "product"
                                ? "Formats: png, jpg, jpeg, webp, Aspect ratio: any, Size: 100KB"
                                : "Formats: png, jpg, jpeg, webp, Aspect ratio: 1:1, Size: 100KB"
                            }
                            id="staticIconTooltip"
                          />
                        </Label>
                        <Container className="custom-file">
                          <Input
                            type="file"
                            className="custom-file-input"
                            accept="image/png, image/jpg, image/jpeg, image/webp"
                            name="darkModeImage"
                            onChange={(event) => {
                              handleCreativesChange(event, "static")
                            }}
                            disabled={!showEditSuggestionDrawerCategoriesButton()}
                          />
                          <Label className="custom-file-label">
                            <strong>{creatives.darkModeImageFilename || "Choose a static image"}</strong>
                          </Label>
                        </Container>
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                      <Label>
                        Aspect Ratio
                      </Label>
                      <Input
                        type="number"
                        className="pl-4"
                        step={0.0000001}
                        name="aspectRatio"
                        placeholder="Aspect Ratio"
                        value={details.suggestionDrawerCategoryRedirectionDetails.aspectRatio}
                        onChange={handleDetailsChange}
                        disabled={!showEditSuggestionDrawerCategoriesButton()}
                        required
                      />
                    </Col>
                    <Col lg={4} md={6}>
                      {(creatives.imageURL) ? (
                        <Col className="pb-4">
                          <img
                            src={creatives.imageURL}
                            alt="Static Icon"
                            width="120"
                          />
                        </Col>
                      ) : (
                        <Col></Col>
                      )}
                    </Col>
                    <Col lg={4} md={6}>
                      {(creatives.darkModeImageURL) ? (
                        <Col className="pb-4">
                          <img
                            src={creatives.darkModeImageURL}
                            alt="Static Icon"
                            width="120"
                          />
                        </Col>
                      ) : (
                        <Col></Col>
                      )}
                    </Col>
                    <Col lg={12} md={12}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Text
                        </Label>
                        <ToolTip
                          data="Redirectional Details Text"
                          id="text"
                        >
                        </ToolTip>
                        <Input
                          type="text"
                          id="text"
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="text"
                          placeholder="text"
                          value={details.suggestionDrawerCategoryRedirectionDetails.text}
                          onChange={handleDetailsChange}
                          isDisabled={!showEditSuggestionDrawerCategoriesButton()}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        {showEditSuggestionDrawerCategoriesButton() && (
          <Button className="d-block ml-3 mt-2" color="primary">
            {(props.match.params.id) ? "Submit" : "Save "}
          </Button>
        )}
      </Form>
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={"submit"}
        onConfirmationTrue={() => {
          setConfirmationModalIsOpen(false);
          handleSubmit();
        }}
        onHide={() => setConfirmationModalIsOpen(false)}
      />
      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={"Success"}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={"Error"}
        modaltext={failureModalText}
      />
    </Container>
  );
};

export default ManageSuggestionDrawerCategory;

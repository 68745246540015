export const languageMapping = [
    {
      "id": 3,
      "name": "Arabic",
      "code": "ar"
    },
    {
      "id": 18,
      "name": "Azerbaijani",
      "code": "az"
    },
    {
      "id": 21,
      "name": "Belarusian",
      "code": "be"
    },
    {
      "id": 23,
      "name": "Bulgarian",
      "code": "bg"
    },
    {
      "id": 26,
      "name": "Catalan",
      "code": "ca"
    },
    {
      "id": 28,
      "name": "Czech",
      "code": "cs"
    },
    {
      "id": 30,
      "name": "Welsh",
      "code": "cy"
    },
    {
      "id": 32,
      "name": "Danish",
      "code": "da"
    },
    {
      "id": 34,
      "name": "German",
      "code": "de"
    },
    {
      "id": 40,
      "name": "Divehi",
      "code": "dv"
    },
    {
      "id": 42,
      "name": "Greek",
      "code": "el"
    },
    {
      "id": 44,
      "name": "English",
      "code": "en"
    },
    {
      "id": 58,
      "name": "Esperanto",
      "code": "eo"
    },
    {
      "id": 59,
      "name": "Spanish",
      "code": "es"
    },
    {
      "id": 80,
      "name": "Estonian",
      "code": "et"
    },
    {
      "id": 82,
      "name": "Basque",
      "code": "eu"
    },
    {
      "id": 84,
      "name": "Persian",
      "code": "fa"
    },
    {
      "id": 86,
      "name": "Finnish",
      "code": "fi"
    },
    {
      "id": 88,
      "name": "Faroese",
      "code": "fo"
    },
    {
      "id": 90,
      "name": "French",
      "code": "fr"
    },
    {
      "id": 97,
      "name": "Galician",
      "code": "gl"
    },
    {
      "id": 99,
      "name": "Gujarati",
      "code": "gu"
    },
    {
      "id": 101,
      "name": "Hebrew",
      "code": "he"
    },
    {
      "id": 103,
      "name": "Hindi",
      "code": "hi"
    },
    {
      "id": 105,
      "name": "Croatian",
      "code": "hr"
    },
    {
      "id": 108,
      "name": "Hungarian",
      "code": "hu"
    },
    {
      "id": 110,
      "name": "Armenian",
      "code": "hy"
    },
    {
      "id": 112,
      "name": "Indonesian",
      "code": "in"
    },
    {
      "id": 114,
      "name": "Icelandic",
      "code": "is"
    },
    {
      "id": 116,
      "name": "Italian",
      "code": "it"
    },
    {
      "id": 119,
      "name": "Japanese",
      "code": "ja"
    },
    {
      "id": 121,
      "name": "Georgian",
      "code": "ka"
    },
    {
      "id": 123,
      "name": "Kazakh",
      "code": "kk"
    },
    {
      "id": 125,
      "name": "Kannada",
      "code": "kn"
    },
    {
      "id": 127,
      "name": "Korean",
      "code": "ko"
    },
    {
      "id": 129,
      "name": "",
      "code": "kok"
    },
    {
      "id": 131,
      "name": "Kyrgyz",
      "code": "ky"
    },
    {
      "id": 133,
      "name": "Lithuanian",
      "code": "lt"
    },
    {
      "id": 135,
      "name": "Latvian",
      "code": "lv"
    },
    {
      "id": 137,
      "name": "Māori",
      "code": "mi"
    },
    {
      "id": 139,
      "name": "Macedonian",
      "code": "mk"
    },
    {
      "id": 141,
      "name": "Mongolian",
      "code": "mn"
    },
    {
      "id": 143,
      "name": "Marathi",
      "code": "mr"
    },
    {
      "id": 145,
      "name": "Malay",
      "code": "ms"
    },
    {
      "id": 148,
      "name": "Maltese",
      "code": "mt"
    },
    {
      "id": 150,
      "name": "Norwegian Bokmål",
      "code": "nb"
    },
    {
      "id": 152,
      "name": "Dutch",
      "code": "nl"
    },
    {
      "id": 156,
      "name": "",
      "code": "ns"
    },
    {
      "id": 158,
      "name": "Punjabi",
      "code": "pa"
    },
    {
      "id": 160,
      "name": "Polish",
      "code": "pl"
    },
    {
      "id": 162,
      "name": "Pashto",
      "code": "ps"
    },
    {
      "id": 164,
      "name": "Portuguese",
      "code": "pt"
    },
    {
      "id": 167,
      "name": "Quechua",
      "code": "qu"
    },
    {
      "id": 171,
      "name": "Romanian",
      "code": "ro"
    },
    {
      "id": 173,
      "name": "Russian",
      "code": "ru"
    },
    {
      "id": 175,
      "name": "Sanskrit",
      "code": "sa"
    },
    {
      "id": 177,
      "name": "Northern Sami",
      "code": "se"
    },
    {
      "id": 187,
      "name": "Slovak",
      "code": "sk"
    },
    {
      "id": 189,
      "name": "Slovene",
      "code": "sl"
    },
    {
      "id": 191,
      "name": "Albanian",
      "code": "sq"
    },
    {
      "id": 197,
      "name": "Swedish",
      "code": "sv"
    },
    {
      "id": 200,
      "name": "Swahili",
      "code": "sw"
    },
    {
      "id": 202,
      "name": "",
      "code": "syr"
    },
    {
      "id": 204,
      "name": "Tamil",
      "code": "ta"
    },
    {
      "id": 206,
      "name": "Telugu",
      "code": "te"
    },
    {
      "id": 208,
      "name": "Thai",
      "code": "th"
    },
    {
      "id": 210,
      "name": "Tagalog",
      "code": "tl"
    },
    {
      "id": 212,
      "name": "Tswana",
      "code": "tn"
    },
    {
      "id": 214,
      "name": "Turkish",
      "code": "tr"
    },
    {
      "id": 216,
      "name": "Tatar",
      "code": "tt"
    },
    {
      "id": 218,
      "name": "Tsonga",
      "code": "ts"
    },
    {
      "id": 219,
      "name": "Ukrainian",
      "code": "uk"
    },
    {
      "id": 221,
      "name": "Urdu",
      "code": "ur"
    },
    {
      "id": 223,
      "name": "Uzbek",
      "code": "uz"
    },
    {
      "id": 226,
      "name": "Vietnamese",
      "code": "vi"
    },
    {
      "id": 228,
      "name": "Xhosa",
      "code": "xh"
    },
    {
      "id": 230,
      "name": "Chinese",
      "code": "zh"
    },
    {
      "id": 236,
      "name": "Zulu",
      "code": "zu"
    },
    {
      "id": 238,
      "name": "Bengali",
      "code": "bn"
    },
    {
      "id": 239,
      "name": "Malayalam",
      "code": "ml"
    },
    {
      "id": 240,
      "name": "Nepali",
      "code": "ne"
    },
    {
      "id": 241,
      "name": "Odia",
      "code": "or"
    },
    {
      "id": 242,
      "name": "Assamese",
      "code": "as"
    },
    {
      "id": 243,
      "name": "Kashmiri",
      "code": "ks"
    },
    {
      "id": 244,
      "name": "Santali",
      "code": "sat"
    },
    {
      "id": 245,
      "name": "Maithili",
      "code": "mai"
    },
    {
      "id": 246,
      "name": "Manipuri",
      "code": "mni"
    },
    {
      "id": 248,
      "name": "Rajasthani",
      "code": "raj"
    },
    {
      "id": 249,
      "name": "Bhojpuri",
      "code": "bho"
    },
    {
      "id": 250,
      "name": "Konkani",
      "code": "kok"
    },
    {
      "id": 251,
      "name": "Sindhi",
      "code": "sn"
    },
    {
      "id": 252,
      "name": "Marwari",
      "code": "mwr"
    },
    {
      "id": 253,
      "name": "Dogri",
      "code": "doi"
    },
    {
      "id": 262,
      "name": "Bodo",
      "code": "brx"
    },
    {
      "id": 270,
      "name": "Haryanvi",
      "code": "bgc"
    },
    {
      "id": 275,
      "name": "Balochi",
      "code": "bal"
    },
    {
      "id": 277,
      "name": "Filipino",
      "code": "fil"
    },
    {
      "id": 278,
      "name": "Lao",
      "code": "lo"
    },
    {
      "id": 313,
      "name": "Batak Karo",
      "code": "btx"
    },
    {
      "id": 320,
      "name": "Rejang",
      "code": "rej"
    }
  ]


import React from 'react'
import { capitalize } from "@material-ui/core";
import { isValidJSON } from '../../../config/configPlacementProviders';

const defaultFormatter = (cell, row) => {
  return cell || "-"
}

const providersFormatter = (cell, row) => {
  return cell
    ? isValidJSON(cell)
      ? JSON.parse(cell)
        ?.map(provider => provider?.split("_")
          ?.map(word => capitalize(word || ""))
          ?.join(" "))?.join(", ")
      : cell
    : "-"
}

const fixedPositionProvidersFormatter = (cell, row) => {
  return cell
    ? isValidJSON(cell)
      ? <ul style={{ paddingLeft: 12 }}>
        {JSON.parse(cell)?.map((el, index) => (
          <li key={index}>
            Name: {el.name?.split("_")
              ?.map(word => capitalize(word || ""))
              ?.join(" ")},
            Position: {el.position},
            Priority: {el.priority}
          </li>
        ))}
      </ul>
      : cell
    : "-"
}

export const tableColumns = [
  {
    dataField: "id",
    text: "#",
    sort: true,
    headerAttrs: { width: 60 },
    searchable: true,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "placementDesc",
    text: "Placement",
    sort: true,
    searchable: true,
    formatter: (cell, row) => defaultFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "providersString",
    text: "Providers",
    sort: true,
    searchable: true,
    formatter: (cell, row) => providersFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "fixedPositionProvidersString",
    text: "Fixed Position Providers",
    sort: true,
    searchable: true,
    formatter: (cell, row) => fixedPositionProvidersFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
];
import React, { useState, useRef } from "react";
import { Tooltip as ReactstrapTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const CustomTooltip = ({ target, content, linkColor, placement = "top" }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const timeoutRef = useRef(null);

  const showTooltip = () => {
    clearTimeout(timeoutRef.current);
    setTooltipOpen(true);
  };

  const hideTooltip = () => {
    timeoutRef.current = setTimeout(() => {
      setTooltipOpen(false);
    }, 5000);
  };

  return (
    <>
      <FontAwesomeIcon
        id={`${target}-icon`}
        className="info-icon"
        icon={faInfoCircle}
        style={{
          color: "#47BAC1",
        }}
        onMouseOver={showTooltip}
        onMouseOut={hideTooltip}
      />
      <ReactstrapTooltip
        placement={placement} // Set tooltip placement dynamically
        isOpen={tooltipOpen}
        target={`${target}-icon`}
        toggle={() => {}}
        autohide={false}
        style={{
          backgroundColor: "#47BAC1",
          color: "white",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          transition: "opacity 0.3s ease-in-out",
          arrow: { color: "#47BAC1" }, // Set arrow color to tooltip background color
        }}
        onMouseOver={showTooltip}
        onMouseOut={hideTooltip}
      >
        <div style={{ color: linkColor }}>{content}</div>
      </ReactstrapTooltip>
    </>
  );
};

export default CustomTooltip;

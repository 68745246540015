import React, { Component, useState, useEffect } from "react";
import {
  Button,
  Container,
  Card,
  CardBody,
  Row,
  Form,
  Col,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import {
  createABTest,
  fetchKeyboardLanguages,
} from "../../../utilities/apiUtils/keyboardLanguages";
import ResponseModal from "../ResponseModal";
import Select from "react-select";
import {
  error,
  errorDescription,
  redirectionOnTokenExpiry,
  redirectTo,
} from "../../../utilities/commonUtil";
import Routes from "../../../routes/index";
import Loading from "../../../components/Loading";
import {
  abTestCreatedStatus,
  abTestCreatedText,
  invalidKeyboardLanguageAlertElementId,
  invalidKeyboardLanguageAlertText,
  successModalHeading,
  failureModalHeading,
} from "../../../config/keyboardLanguages";

const CreateABTest = () => {
  const [loadingState, setLoadingState] = useState(true);
  const [successModalState, setSuccessModalState] = useState({
    successModalDisplay: false,
    successModalText: "",
  });
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });
  const [description, setDescription] = useState("");
  const [existingUsersRolloutPercentage, setExistingUsersRolloutPercentage] = useState(0);
  const [keyboardLanguageIds, setKeyboardLanguageIds] = useState([]);
  const [selectedLanguageId, setSelectedLanguageId] = useState({});

  // This useEffect will be called for componentDidMount condition
  useEffect(() => {
    const fetchLanguages = async () => {
      await getKeyboardLanguages();
    };

    fetchLanguages();
  }, []);

  const showAlert = (elementId) => {
    document.getElementById(elementId).classList.remove("d-none");
  };

  const hideAlert = (elementId) => {
    document.getElementById(elementId).classList.add("d-none");
  };

  const showErrorModal = (text) => {
    setFailureModalState({
      failureModalDisplay: false,
      failureModalText: text,
    });
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handlePercentageChange = (event) => {
    setExistingUsersRolloutPercentage(event.target.value);
  };

  const successModalClose = () => {
    setSuccessModalState((prev) => ({ ...prev, successModalDisplay: false }));
    redirectTo(Routes.keyboardLanguages.children.abTests.path);
  };

  const failureModalClose = () => {
    setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
  };

  const getKeyboardLanguages = async () => {
    await fetchKeyboardLanguages()
      .then(async (response) => {
        if (response.errorCode) {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken
          )
            redirectionOnTokenExpiry();
          let error_code = errorDescription(response.errorCode);
          showErrorModal(error_code);
        } else if (response.keyboardLanguages) {
          let keyboardLanguageIds = [];
          for (let i = 0; i < response.keyboardLanguages.length; i++) {
            let layout = {};
            layout["value"] = response.keyboardLanguages[i].id;
            layout["label"] =
              response.keyboardLanguages[i].name +
              "-" +
              response.keyboardLanguages[i].id;
            keyboardLanguageIds.push(layout);
          }
          setLoadingState(false);
          setKeyboardLanguageIds(keyboardLanguageIds);
        }
      })
      .catch((err) => {
        setLoadingState(false);
        showErrorModal(errorDescription(error.unexpectedError));
      });
  };

  const handleKeyboardLanguageIdChange = async (option) => {
    hideAlert(invalidKeyboardLanguageAlertElementId);
    setSelectedLanguageId(option);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (Object.keys(selectedLanguageId).length === 0) {
      showAlert(invalidKeyboardLanguageAlertElementId);
      return;
    }

    const newABTest = new FormData();
    newABTest.append("keyboardLanguageId", selectedLanguageId.value);
    newABTest.append("existingUsersRolloutPercentage", existingUsersRolloutPercentage);
    if (description.length > 0) {
      newABTest.append("description", description);
    }

    try {
      const response = await createABTest(newABTest, selectedLanguageId.label);
      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectionOnTokenExpiry();
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: response.errorDescription,
        });
      } else if (response.status === abTestCreatedStatus) {
        setSuccessModalState({
          successModalDisplay: true,
          successModalText: abTestCreatedText,
        });
      }
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  return (
    <Container fluid className="p-0">
      <h3 className="mb-3">Create ABTest</h3>
      {loadingState ? (
        <Loading />
      ) : (
        <Card className="mb-0">
          <CardBody>
            <Form onSubmit={(event) => handleSubmit(event)}>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      Select Keyboard Language{" "}
                      <span className="text-danger">*</span>{" "}
                      <small
                        id={invalidKeyboardLanguageAlertElementId}
                        className="text-danger d-none"
                      >
                        ({invalidKeyboardLanguageAlertText})
                      </small>
                    </Label>
                    <Select
                      className="react-select-container dropdown"
                      classNamePrefix="react-select"
                      name="layoutId"
                      id="layoutId"
                      placeholder="Select Keyboard Language"
                      onChange={(option) =>
                        handleKeyboardLanguageIdChange(option)
                      }
                      defaultValue={selectedLanguageId}
                      value={selectedLanguageId}
                      options={keyboardLanguageIds}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      Existing Users Rollout Percentage
                    </Label>
                    <Input
                      type="number"
                      name="existingUsersRolloutPercentage"
                      onChange={(event) => handlePercentageChange(event)}
                      placeholder="Existing users rollout percentage"
                      value={existingUsersRolloutPercentage}
                      max={100}
                    />
                  </FormGroup>
                </Col>

                <Col md={12}>
                  <FormGroup>
                    <Label>Description</Label>
                    <Input
                      type="textarea"
                      name="description"
                      onChange={(event) => handleDescriptionChange(event)}
                      style={{ height: "6rem" }}
                      placeholder="Description"
                      value={description}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button color="primary" type="submit">
                Submit
              </Button>
            </Form>
          </CardBody>
        </Card>
      )}
      <ResponseModal
        show={successModalState.successModalDisplay}
        onHide={() => successModalClose()}
        modalheading={successModalHeading}
        modaltext={successModalState.successModalText}
      />
      <ResponseModal
        show={failureModalState.failureModalDisplay}
        onHide={() => failureModalClose()}
        modalheading={failureModalHeading}
        modaltext={failureModalState.failureModalText}
      />
    </Container>
  );
};

export default CreateABTest;

import React, { useState, useEffect } from "react";
import { FaChevronUp, FaChevronDown, FaPlus } from "react-icons/fa";
import { Collapse, Card, CardBody, CardHeader, Button, Badge, Row, Col } from "reactstrap";
import ClientRule from "./ClientRule";
import LocationRule from "./LocationRule";
import RuleSummary from "./RuleSummary";
import PlacementRule from "./PlacementRule";
import AppVersionRule from "./AppVersionRule";

const RulesCard = (props) => {
  const showClientRules = props.showClientRules ?? true
  const showPlacementRules = props.showPlacementRules ?? false
  const isCustomAudienceTargetingEnabled = props.isCustomAudienceTargetingEnabled ?? false;
  const showAppVersionRules = props.showAppVersionRules ?? false;
  const locationClientMappingOptions = props.locationClientMappingOptions ?? {}
  const locationOptions = props.locationOptions ?? []

  const [clientRules, setClientRules] = useState([
    { id: 0, clientID: "global", effect: "allow" },
  ]);
  const [locationRules, setLocationRules] = useState([
    {
      id: 0,
      countryID: 240,
      regionID: 1,
      cityID: 1,
      effect: "allow",
    },
  ]);
  const [placementRules, setPlacementRules] = useState([{
    placementLocation: "launcher",
    clientID: "7wZFJWA5chjgat68y826IAIKQ6s197RM",
    effect: "allow"
  }])
  const [appVersionRules, setAppVersionRules] = useState({
    minAppVersion: 1,
    maxAppVersion: null,
  });
  const [activate, setActivate] = useState(true);
  const [toggle, setToggle] = useState(false);
  const [clientRulesSummary, setClientRulesSummary] = useState([]);
  const [locationRulesSummary, setLocationRulesSummary] = useState([]);

  const _updateClientRuleSummary = (clientRuleID, clientvalue, effect) => {
    const index = clientRulesSummary.findIndex((rule) => {
      return rule.id === clientRuleID;
    });
    if (index == -1) {
      setClientRulesSummary((prev) => {
        prev.push({
          id: clientRuleID,
          client: clientvalue,
          effect: effect,
        });
        return [...prev];
      });
    } else {
      setClientRulesSummary((prev) => {
        prev[index].client = clientvalue;
        prev[index].effect = effect;
        return [...prev];
      });
    }
  };

  const _updateLocationRuleSummary = (
    locationRuleID,
    country,
    region,
    city,
    effect
  ) => {
    const index = locationRulesSummary.findIndex((rule) => {
      return rule.id === locationRuleID;
    });
    if (index == -1) {
      setLocationRulesSummary((prev) => {
        prev.push({
          id: locationRuleID,
          country: country,
          region: region,
          city: city,
          effect: effect,
        });

        return [...prev];
      });
    } else {
      setLocationRulesSummary((prev) => {
        prev[index].country = country;
        prev[index].region = region;
        prev[index].city = city;
        prev[index].effect = effect;
        return [...prev];
      });
    }
  };

  const _updateClientRule = (clientRuleID, clientID, effect) => {
    const index = clientRules.findIndex((rule) => {
      return rule.id === clientRuleID;
    });
    setClientRules((prev) => {
      prev[index].clientID = clientID;
      prev[index].effect = effect;
      return [...prev];
    });

    // check if any clientID == global
    if (clientID === "global") {
      setAppVersionRules((prev) => {
        prev.minAppVersion = 1;
        prev.maxAppVersion = null;
        return { ...prev };
      });
    }

    props.updateRules(props.rule.id, clientRules, locationRules, placementRules, activate, appVersionRules);
  };

  const _updateLocationRule = (
    locationRuleID,
    countryID,
    regionID,
    cityID,
    effect
  ) => {
    const index = locationRules.findIndex((rule) => {
      return rule.id === locationRuleID;
    });
    setLocationRules((prev) => {
      prev[index].countryID = countryID;
      prev[index].regionID = regionID;
      prev[index].cityID = cityID;
      prev[index].effect = effect;
      return [...prev];
    });
    props.updateRules(props.rule.id, clientRules, locationRules, placementRules, activate, appVersionRules);
  };

  const _updatePlacementRule = (event, index, name) => {
    const { value } = event.target;
    let pRules = [...placementRules];
    pRules[index][name] = value?.value;
    if (name === "placementLocation")
      pRules[index]["clientID"] = "7wZFJWA5chjgat68y826IAIKQ6s197RM";
    setPlacementRules(pRules);
    props.updateRules(props.rule.id, clientRules, locationRules, pRules, activate, appVersionRules);
  }

  const removePlacementRule = (index) => {
    const filteredRules = placementRules.filter((_, i) => i !== index)
    setPlacementRules(filteredRules);
    props.updateRules(props.rule.id, clientRules, locationRules, filteredRules, activate, appVersionRules)
  }

  const addPlacementRule = () => {
    const updatedPlacementRules = placementRules.concat({
      placementLocation: "launcher",
      clientID: "7wZFJWA5chjgat68y826IAIKQ6s197RM",
      effect: "allow"
    })
    setPlacementRules(updatedPlacementRules)
    props.updateRules(props.rule.id, clientRules, locationRules, updatedPlacementRules, activate, appVersionRules)
  }

  const _updateActivate = (status) => {
    setActivate(status);
    props.updateRules(props.rule.id, clientRules, locationRules, placementRules, status, appVersionRules);
  };

  useEffect(() => {
    const SetActivate = async () => {
      setActivate(props.rule.activate);
    };
    SetActivate();
  });

  useEffect(() => {
    const SetClientRules = async () => {
      for (let i = 0; i < props.rule.clientRules.length; i++) {
        props.rule.clientRules[i].id = i;
      }
      setClientRules(props.rule.clientRules);
    };
    SetClientRules();
    setClientRulesSummary([])
  }, [isCustomAudienceTargetingEnabled]);

  useEffect(() => {
    const SetLocationRules = async () => {
      for (let i = 0; i < props.rule.locationRules.length; i++) {
        props.rule.locationRules[i].id = i;
      }
      setLocationRules(props.rule.locationRules);
    };
    SetLocationRules();
    setLocationRulesSummary([])
  }, [isCustomAudienceTargetingEnabled]);

  useEffect(() => {
    if (props.rule && props.rule?.placementRules?.length > 0) {
      // Fill existing placement rules
      setPlacementRules(props.rule.placementRules ?? [])
    }
  }, [isCustomAudienceTargetingEnabled])

  useEffect(() => {
    if (props.rule && props.rule?.appVersionRules) {
      // Fill existing app version rules
      setAppVersionRules(props.rule.appVersionRules ?? {})
    }
  }, [isCustomAudienceTargetingEnabled])

  const addClientRule = () => {
    var _updateClientRules = clientRules;
    _updateClientRules.push({
      id: clientRules[clientRules.length - 1].id + 1,
      clientID: "global",
      effect: "allow",
    });
    setClientRules(_updateClientRules);
    props.updateRules(
      props.rule.id,
      _updateClientRules,
      locationRules,
      placementRules,
      activate,
      appVersionRules
    );
  };

  const removeClientRule = (id) => {
    const _updateClientRules = clientRules.filter((rule) => rule.id !== id);
    const _updateClientRulesSummary = clientRulesSummary.filter(
      (rule) => rule.id !== id
    );

    for (let i = 0; i < _updateClientRulesSummary.length; i++) {
      _updateClientRulesSummary[i]["id"] = i;
    }

    setClientRules(_updateClientRules);
    setClientRulesSummary(_updateClientRulesSummary);
    props.updateRules(
      props.rule.id,
      _updateClientRules,
      locationRules,
      placementRules,
      activate,
      appVersionRules
    );
  };

  const addLocationRule = (event) => {
    var _updateLocationRules = locationRules;

    if (locationRules.length <= 0) {
      _updateLocationRules.push({
        id: 0,
        countryID: 240,
        regionID: 1,
        cityID: 1,
        effect: "allow",
      });
    } else {
      _updateLocationRules.push({
        id: locationRules[locationRules.length - 1].id + 1,
        countryID: 240,
        regionID: 1,
        cityID: 1,
        effect: "allow",
      });
    }

    setLocationRules(_updateLocationRules);
    props.updateRules(
      props.rule.id,
      clientRules,
      _updateLocationRules,
      placementRules,
      activate,
      appVersionRules
    );
  };

  const removeLocationRule = (id) => {
    const _updateLocationRules = locationRules.filter((rule) => rule.id !== id);
    const _updateLocationRulesSummary = locationRulesSummary.filter(
      (rule) => rule.id !== id
    );
    for (let i = 0; i < _updateLocationRulesSummary.length; i++) {
      _updateLocationRulesSummary[i]["id"] = i;
    }

    setLocationRules(_updateLocationRules);
    setLocationRulesSummary(_updateLocationRulesSummary);

    props.updateRules(
      props.rule.id,
      clientRules,
      _updateLocationRules,
      placementRules,
      activate,
      appVersionRules
    );
  };

  const _removeRule = () => {
    props.removeRule(props.rule.id);
  };

  const _updateAppVersionRules = (event, name) => {
    const { value } = event.target;
    setAppVersionRules((prev) => {
      prev[name] = parseFloat(value);
      return { ...prev };
    });
    props.updateRules(props.rule.id, clientRules, locationRules, placementRules, activate, appVersionRules);
  }

  const disableAppVersion = clientRules.some((rule) => rule.clientID === "global");

  return (
    <Card className="mb-3">
      <CardHeader onClick={() => setToggle(!toggle)} className="pt-3 pb-0">
        <div className="d-flex">
          <h4 style={{ marginRight: 10 }}>
            <strong>{!isCustomAudienceTargetingEnabled ? `Rule ${props.rule.id + 1}` : "Placement Rules"}</strong>
          </h4>
          <div style={{ marginTop: -2 }}>
            {activate ? (
              <Badge color="success">Activated</Badge>
            ) : (
              <Badge color="danger">
                Deactivated
              </Badge>
            )}
          </div>
          <div className="ml-auto">
            {toggle ? <FaChevronUp /> : <FaChevronDown />}
          </div>
        </div>
      </CardHeader>
      {!isCustomAudienceTargetingEnabled && (
        <Collapse isOpen={!toggle}>
          <CardBody className="pb-3 pt-1">
            <RuleSummary
              key={props.rule.id}
              clientRules={clientRulesSummary}
              locationRules={locationRulesSummary}
              appVersionRules={appVersionRules}
              showAppVersionRules={showAppVersionRules}
            />
          </CardBody>
        </Collapse>
      )}
      <Collapse isOpen={toggle}>
        <CardBody className="pb-3 pt-1">
          {showClientRules && (
            <p className="mt-3 mb-1">
              <strong>Clients</strong>
            </p>
          )}
          {(showClientRules && clientRules)
            ? clientRules.map(function (object, i) {
              return (
                <ClientRule
                  key={i}
                  rule={object}
                  updateRule={_updateClientRule}
                  updateRuleSummary={_updateClientRuleSummary}
                  addClientRule={addClientRule}
                  removeClientRule={removeClientRule}
                  updateAllowed={props.updateAllowed}
                  getClients={props.getClients}
                  overrideClientApi={props.overrideClientApi}
                />
              );
            })
            : null
          }
          {!isCustomAudienceTargetingEnabled && showAppVersionRules && (
            <p className="mt-3 mb-1">
              <strong>App Version <span className="text-warning font-weight-bold" style={{ fontSize: 13 }}>⚠️ Appversion should be Client specific</span></strong>
            </p>
          )}
          {(!isCustomAudienceTargetingEnabled && showAppVersionRules) && (
            <AppVersionRule
              appVersionRules={appVersionRules}
              updateAppVersionRules={_updateAppVersionRules}
              updateAllowed={props.updateAllowed}
              disableAppVersion={disableAppVersion}
            />
          )}
          {!isCustomAudienceTargetingEnabled && (
            <p className="mt-3 mb-1">
              <strong>Location</strong>
            </p>
          )}
          {(!isCustomAudienceTargetingEnabled && locationRules)
            ? locationRules.map(function (object, i) {
              return (
                <LocationRule
                  key={object.id}
                  rule={object}
                  updateRule={_updateLocationRule}
                  updateRuleSummary={_updateLocationRuleSummary}
                  addLocationRule={addLocationRule}
                  removeLocationRule={removeLocationRule}
                  updateAllowed={props.updateAllowed}
                  getCountries={props.getCountries}
                  getRegions={props.getRegions}
                  getCities={props.getCities}
                />
              );
            })
            : null}
          {(!isCustomAudienceTargetingEnabled && locationRules.length === 0) ? (
            <Row className="mr-1 justify-content-end" style={{ marginTop: -32 }}>
              <Col md={1} className="align-self-end">
                <Button onClick={addLocationRule} color="transparent">
                  <FaPlus />
                </Button>
              </Col>
            </Row>
          ) : null}
          {showPlacementRules && (
            <p className="mt-3 mb-1">
              <strong>Placement</strong>
            </p>
          )}
          {(showPlacementRules && placementRules) ?
            placementRules.map((placement, index) => (
              <PlacementRule
                key={index}
                index={index}
                placement={placement}
                locationClientMappingOptions={locationClientMappingOptions}
                locationOptions={locationOptions}
                updateRules={_updatePlacementRule}
                addRules={addPlacementRule}
                removeRules={removePlacementRule}
                updateAllowed={props.updateAllowed}
              />
            )) : null}
          <div className="mt-4 d-flex flex-nowrap">
            <div className="order-1 p-2">
              <Button onClick={_removeRule} color={"danger"} disabled={!props.updateAllowed}>
                Delete Rule
              </Button>
            </div>

            <div className="order-2 p-2">
              {activate ? (
                <Button
                  onClick={(e) => _updateActivate(false)}
                  color={"warning"} disabled={!props.updateAllowed}
                >
                  Deactivate Rule
                </Button>
              ) : (
                <Button
                  onClick={(e) => _updateActivate(true)}
                  color={"success"} disabled={!props.updateAllowed}
                >
                  Activate Rule
                </Button>
              )}
            </div>
          </div>
        </CardBody>
      </Collapse>
    </Card>
  );
};

export default RulesCard;
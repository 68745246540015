import { retrieveFromLocalStorage } from "../utilities/localStorageUtil";
import { permissions } from "../utilities/commonUtil";
import moment from "moment";

const allowedPermissions = retrieveFromLocalStorage("userPermissions");

export let config = {
    searchByDefault: "id",
    keypressDown: 13,
    debounceTime: 1000,
};

export const staticIconSize = 1e+7;

export let showEditSuggestionDrawerCategoriesButton = () => {
    return allowedPermissions.includes(permissions.suggestionDrawerCategoryUpdate);
};
export let showCreateSuggestionDrawerCategoriesButton = () => {
    return allowedPermissions.includes(permissions.suggestionDrawerCategoryCreate);
};
export let showSuggestionDrawerCategoriesButton = () => {
    return allowedPermissions.includes(permissions.suggestionDrawerCategoryDetail);
};
export let showActivateSuggestionDrawerCategoriesButton = () => {
    return allowedPermissions.includes(permissions.suggestionDrawerCategoryActivate);
};
export let showDeactivateSuggestionDrawerCategoriesButton = () => {
    return allowedPermissions.includes(permissions.suggestionDrawerCategoryDeactivate);
};
export let showPublishSuggestionDrawerCategoriesButton = () => {
    return allowedPermissions.includes(permissions.suggestionDrawerCategoryPublish);
};
export let showSuggestionDrawerCategoryTargetingRuleButton = () => {
    return allowedPermissions.includes(permissions.SuggestionDrawerCategoryRulesView);
};
export let showEditSuggestionDrawerCategoryTargetingRuleButton = () => {
    return allowedPermissions.includes(permissions.SuggestionDrawerCategoryRulesUpdate);
}
export let showSuggestionDrawerCategoryContentOrder = () => {
    return allowedPermissions.includes(permissions.suggestionDrawerContentOrderView);
}
export let showEditSuggestionDrawerCategoryContentOrder = () => {
    return allowedPermissions.includes(permissions.suggestionDrawerContentOrderUpdate);
}
export let showSuggestionDrawerCategoryOrder = () => {
    return allowedPermissions.includes(permissions.suggestionDrawerCategoryOrderView);
}
export let showEditSuggestionDrawerCategoryOrder = () => {
    return allowedPermissions.includes(permissions.suggestionDrawerCategoryOrderUpdate);
}

export let showEditSuggestionDrawerContentButton = () => {
    return allowedPermissions.includes(permissions.suggestionDrawerCategoryUpdate);
};
export let showCreateSuggestionDrawerContentButton = () => {
    return allowedPermissions.includes(permissions.suggestionDrawerCategoryCreate);
};
export let showSuggestionDrawerContentButton = () => {
    return allowedPermissions.includes(permissions.suggestionDrawerCategoryDetail);
};
export let showActivateSuggestionDrawerContentButton = () => {
    return allowedPermissions.includes(permissions.suggestionDrawerCategoryActivate);
};
export let showDeactivateSuggestionDrawerContentButton = () => {
    return allowedPermissions.includes(permissions.suggestionDrawerCategoryDeactivate);
};
export let showPublishSuggestionDrawerContentButton = () => {
    return allowedPermissions.includes(permissions.suggestionDrawerCategoryPublish);
};
export let showSuggestionDrawerContentTargetingRuleButton = () => {
    return allowedPermissions.includes(permissions.SuggestionDrawerCategoryRulesView);
};
export let showEditSuggestionDrawerContentTargetingRuleButton = () => {
    return allowedPermissions.includes(permissions.SuggestionDrawerCategoryRulesUpdate);
}
export let showEditSuggestionDrawerContentRuleButton = () => {
    return allowedPermissions.includes(permissions.SuggestionDrawerContentRulesUpdate);
}

export const targetingRulesNotSetError = "Please set targeting rules in order to publish the category";

export let formatTime = (date) => {
    if (date === null) {
        return "N/A";
    }
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
};

export const suggestionDrawerResponses = {
    updatedSuccessfully: " Updated Successfully",
    addedSuccessfully: " Added Successfully",
};

export const defaultAssetWidthPercentage = 0.31;


export const contentTypes = [
    {
        value: 1,
        label: "sticker"
    }, {
        value: 2,
        label: "gif"
    }, {
        value: 3,
        label: "movieGif"
    },
    {
        value: 4,
        label: "emojiSticker"
    },
    {
        value: 5,
        label: "popTextStyle"
    },
    {
        value: 6,
        label: "animatedSticker"
    },
    {
        value: 7,
        label: "youmoji"
    },
    {
        value: 8,
        label: "story"
    },
    {
        value: 9,
        label: "memes"
    },
    {
        value: 10,
        label: "generic"

    },
    {
        value: 11,
        label: "genericCard"
    },
    {
        value: 12,
        label: "premiumSticker"
    }
];

export const contentCategorySubTypes = [
    { label: "Cricket", value: "cricket" },
    { label: "Football", value: "football" },
];

export let contentResponses = {
    addedSuccessfully: "Sticker added successfully",
    updatedSuccessfully: "Sticker updated successfully",
    activatedSuccessfully: "Sticker activated successfully",
    deactivatedSuccessfully: "Sticker deactivated successfully",
}

export const deeplinkTypeOptions = [
    { value: "noDeeplink", label: "No Deeplink" },
    { value: "custom", label: "Custom value" },
    { value: "default", label: "Auto Generate From Input Values" }];

export const sdv3UserAttributeRuleFilterOptions = [
    { label: "Premium User Status", value: "flt_premium_user" },
    { label: "Premium Stickers", value: "flt_premium_stickers" },
    { label: "Premium Purchased Product", value: "flt_premium_purchased_product" },
    { label: "Premium Recommended Product", value: "flt_premium_recommended_product" },
    { label: "Segment", value: "flt_segments" },
    { label: "Disallowed Packages", value: "flt_disallowed_package" }
];

// Export style properties options (for dropdown)
export const stylePropertiesOptions = [
    { value: null, label: "Null" },
    { value: 4, label: "4" },
    { value: 8, label: "8" },
    { value: 12, label: "12" },
    { value: 16, label: "16" },
    { value: 20, label: "20" },
    { value: 24, label: "24" },
    { value: 28, label: "28" },
    { value: 32, label: "32" },
  ];
  export const statusOptions = [
    { value: "", label: "All" },
    { value: "activated", label: "Activated" },
    { value: "deactivated", label: "Deactivated" },
    { value: "unpublished", label: "Unpublished" },
    { value: "expired", label: "Expired" },
  ];
  export const groupedOptions = [
    {
      label: "Sort By Updated At",
      options: [
        { value: "updated_at desc", label: "Descending ↓" },
        { value: "updated_at asc", label: "Ascending ↑" },
      ],
    },
    {
      label: "Sort By Created At",
      options: [
        { value: "created_at desc", label: "Descending ↓" },
        { value: "created_at asc", label: "Ascending ↑" },
      ],
    },
  ];
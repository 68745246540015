import React, { PureComponent } from "react";
import {
  fetchStickerPack,
  getStickerPacks,
  fetchStickersInStickerPack,
  reorderStickers,
  importStickers,
  updateStickerStatus,
  updateStickerPackStickers
} from "../../../utilities/apiUtils";
import InfiniteScroll from "react-infinite-scroll-component";
import Sticker from "./components/Sticker";
import CustomTooltip from "../../../components/customToolTip";
import {
  error,
  getStatus,
  getStatusColor,
  errorDescription,
  redirectOnTokenExpiry,
  redirectTo,
  permissions
} from "../../../utilities/commonUtil";
import Empty from "./components/EmptyStickers";
import CountryTags from "../../../components/CountryTags";
import ResponseModal from "../ResponseModal";
import Loading from "../../../components/Loading";
import {
  Card,
  CardBody,
  CardImg,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  Spinner,
  Button,
  Collapse,
  Input,
  Label,
} from "reactstrap";
import Select from "react-select";
import { Config } from "../../../config/UserConfig";
import { Fragment } from "react";
import UncontrolledButtonDropdown from "reactstrap/lib/UncontrolledButtonDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import Search from "../../../components/Search";
import { capitalize } from "@material-ui/core";
import { Alert } from "react-bootstrap"
import { getRules, saveRules } from "../../../utilities/apiUtils/popTextStyles";
import { getAutoDownloadRules, getStickerPackTargetingRules } from "../../../utilities/apiUtils/stickers";
import { getCountries, getRegions, getCities, getClients } from "../../../utilities/apiUtils/stickers";
import { ClientTags } from "../../../components/ClientTags";
import { AgeGenderTag } from "../../../components/AgeGenderTag";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import RearrangeStickers from "./RearrangeStickers";
import ImportStickers from "./ImportStickers";
import StickerStatus from "./StickerStatus";
import { dateFilter } from "react-bootstrap-table2-filter";


class StickerPack extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      hasResponseError: false,
      status: "",
      stickerPack: {},
      allStickers: [],
      filteredStickers: [],
      activeSticker: [],
      activeTab: "all",
      successModalText: "",
      failureModalText: "",
      successModalDisplay: false,
      failureModalDisplay: false,
      id: null,
      stickerPacks: [],
      searchString: "",
      page: 0,
      limit: 20,
      hasMoreStickers: true,
      isBulkActionCollapseOpen: false,
      sortBy: Config.UPDATED_AT_DESCENDING,
      searchBy: "tag",
      allowedPermissions: retrieveFromLocalStorage("userPermissions"),
      rules: [],
      countries: [],
      regions: [],
      cities: [],
      clients: [],
      clientTagData: [],
      clientRulesCount: 0,
      locationRulesCount: 0,
      ageGenderData: [],
      isChecked: false,
      locationData: [],
      targetingRules: [],
      targetedCountries: [],
      targetedRegions: [],
      targetedCities: [],
      targetedClients: [],
      targetedClientTagData: [],
      targetedClientRulesCount: 0,
      targetedLocationRulesCount: 0,
      targetedAgeGenderData: [],
      selectedStickers: [],
      showRearrangeModal: false,
      showImportModal: false,
      showStatusModal: false,
      selectedSticker: {},
      statusModalAction: "publish",
      stickersReordered: [],
      loadingStickers: false,
      startAt: null,
      endAt: null,
      filterDateTime: null,
      filterStatus: null,
    };
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    this.setState({ id });
    this.getStickerPack(id);
    this.getStickerInPack(id);
    this.getStickerPackList();
  }

  getTargetingRules = async (id) => {
    await getStickerPackTargetingRules(id)
      .then((response) => {
        if (response.errorCode) {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken
          ) {
            redirectOnTokenExpiry();
            this.setState({
              failureModalDisplay: true,
              failureModalText: errorDescription(response.errorCode),
            });
          }
        } else {
          console.log(response)
          this.setState({ targetingRules: response });
        }
      })
      .catch((err) => { });
  };

  getStickerPackRules = async (id) => {
    await getAutoDownloadRules(id)
      .then((response) => {
        if (response.errorCode) {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken
          ) {
            redirectOnTokenExpiry();
            this.setState({
              failureModalDisplay: true,
              failureModalText: errorDescription(response.errorCode),
            });
          }
        } else {
          console.log(response)
          this.setState({ rules: response });
        }
      })
      .catch((err) => { });
  };

  getStickerPackList = async () => {
    await getStickerPacks()
      .then((response) => {
        if (response.errorCode) {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken
          ) {
            redirectOnTokenExpiry();
            this.setState({
              failureModalDisplay: true,
              failureModalText: errorDescription(response.errorCode),
            });
          }
        } else {
          this.setState({ stickerPacks: response });
        }
      })
      .catch((err) => { });
  };

  getStickerInPack = async (id) => {
    let params = {
      page: this.state.page,
      limit: this.state.limit,
    };
    await fetchStickersInStickerPack(id, params)
      .then((res) => {
        if (res.stickers) {
          let activeSticker = this.getActiveStickers(res.stickers);
          this.setState({
            allStickers: res.stickers,
            filteredStickers: res.stickers.map((sticker) => {
              let newsticker = {
                ...sticker,
                isChecked: false
              }
              return newsticker
            }),
            activeSticker,
          });
        } else {
          this.setState({
            hasResponseError: true,
            failureModalText: errorDescription(res.errorCode),
          });
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      });
  }

  fetchMoreData = async () => {
    if (this.state.hasMoreStickers) {
      const { page, limit, status } = this.state; // Get the current filter

      // Query parameters
      const params = {
        page: page + 1,
        limit: limit,
        filter: status, // Pass the current filter status to the next page
        date: this.state.filterDateTime !== "" && this.state.filterDateTime !== null ? new Date(this.state.filterDateTime).toISOString() : ""
      };

      // API call with given query parameters
      const res = await fetchStickersInStickerPack(this.state.id, params);

      if (res.stickers && res.stickers.length > 0) {
        this.setState((prevState) => ({
          allStickers: prevState.allStickers.concat(res.stickers),
          filteredStickers: prevState.filteredStickers.concat(res.stickers),
          page: prevState.page + 1, // Increment the page from prevState
          hasMoreStickers: true,
        }));
      } else {
        this.setState({ hasMoreStickers: false });
      }
    }
  };

  getStickerPack = (id) => {
    fetchStickerPack(id)
      .then((res) => {
        if (res.stickerPack) {
          this.setState({
            stickerPack: res.stickerPack,
          });
        } else {
          this.setState({
            hasResponseError: true,
            failureModalText: errorDescription(res.errorCode),
          });
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      });
  };

  getActiveStickers = (pack) => {
    let activePacks = pack.filter((sticker) => {
      return (
        getStatus(sticker.publishedAt, sticker.deactivatedAt) ===
        Config.PUBLISHED_STATUS
      );
    });
    return activePacks;
  };

  getUnpublishedStickers = (pack) => {
    let unpublishedPacks = pack.filter((sticker) => {
      return (
        getStatus(sticker.publishedAt, sticker.deactivatedAt) ===
        Config.UNPUBLISHED_STATUS
      );
    });
    return unpublishedPacks;
  };

  getDeactivatedStickers = (pack) => {
    let deactivatedPacks = pack.filter((sticker) => {
      return (
        getStatus(sticker.publishedAt, sticker.deactivatedAt) ===
        Config.DEACTIVATED_STATUS
      );
    });
    return deactivatedPacks;
  };

  getActiveOrUnpublishedStickers = (pack) => {
    let packs = pack.filter((sticker) => {
      return (
        getStatus(sticker.publishedAt, sticker.deactivatedAt) === Config.PUBLISHED_STATUS
        || getStatus(sticker.publishedAt, sticker.deactivatedAt) === Config.UNPUBLISHED_STATUS
      );
    });
    return packs;
  };

  handleFilterChange = async (selectedOption) => {
    const status = !!selectedOption?.value ? selectedOption.value : "";
    console.log(status);
    this.setState({ filterStatus: selectedOption });
    // Reset page to 0 when status changes
    await this.setState({ page: 0, hasMoreStickers: true, status });

    if (status !== "" || (this.state.filterDateTime !== null)) {
      // Query parameters
      const params = {
        page: this.state.page,
        limit: this.state.limit,
        filter: status,
        date: this.state.filterDateTime !== "" && this.state.filterDateTime !== null ? new Date(this.state.filterDateTime).toISOString() : ""
      };

      // API call with given query parameters
      const res = await fetchStickersInStickerPack(this.state.id, params);

      if (res.stickers) {
        this.setState({
          allStickers: res.stickers,
          filteredStickers: res.stickers,
          hasMoreStickers: true,
        });
      } else {
        this.setState({ hasMoreStickers: false });
      }
    }
  };


  onKeyDown = (event) => {
    if (event.keyCode === Config.KEYPRESS_DOWN) {
      this.search();
    }
  };

  handleOrderChange = (sortBy) => {
    let stickers = [];
    if (sortBy.value == Config.UPDATED_AT_DESCENDING) {
      stickers = this.state.filteredStickers.sort((a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt));
    } else if (sortBy.value == Config.UPDATED_AT_ASCENDING) {
      stickers = this.state.filteredStickers.sort((a, b) => Date.parse(a.updatedAt) - Date.parse(b.updatedAt));
    }
    this.setState({ sortBy: sortBy.value, filteredStickers: stickers });
  };

  handleSearchStringChange = (event) => {
    let value = event.target.value;
    this.setState({ searchString: value }, () => {
      if (!value.length) {
        this.search();
      }
    });
    event.preventDefault();
  };

  search = (searchBy) => {
    searchBy = searchBy || this.state.searchBy;
    this.setState({ searchBy });
    let status = this.state.status;
    let sortBy = this.state.sortBy;
    let searchString = this.state.searchString;
    let stickers = this.state.allStickers;

    if (searchBy == "id") {
      stickers = stickers.filter(sticker => sticker.id == searchString)
      this.setState({ filteredStickers: stickers });
      return;
    }
    // search by tags
    if (searchString != "") {
      stickers = stickers.filter(sticker => sticker.tags.includes(searchString))
    }

    // sort
    if (sortBy == Config.UPDATED_AT_DESCENDING) {
      stickers = stickers.sort((a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt));
    } else if (sortBy == Config.UPDATED_AT_ASCENDING) {
      stickers = stickers.sort((a, b) => Date.parse(a.updatedAt) - Date.parse(b.updatedAt));
    }
    
    this.setState({ filteredStickers: stickers });
  }

  updateStickerPackStickersDateTime = async () => {

    if(this.state.startAt !== null && this.state.endAt !== null && this.state.startAt !== "" && this.state.endAt !== "" && this.state.startAt > this.state.endAt) {
      this.setState({
        failureModalDisplay: true,
        failureModalText: "Start date should be less than end date",
      });
      return;
    }

    let selectedStickers = this.state.selectedStickers;
    let stickerPackId = this.state.id;
    // convert datetime to ISO string
    let startAt = this.state.startAt !== "" && this.state.startAt !== null ? new Date(this.state.startAt).toISOString() : null;
    let endAt = this.state.endAt !== "" && this.state.endAt !== null ? new Date(this.state.endAt).toISOString() : null;

    let data = {
      stickerIds : selectedStickers.map(sticker => sticker.id),
      startAt: startAt,
      endAt: endAt
    }

    let response = await updateStickerPackStickers(stickerPackId, data);

    if (response.status === "Success") {
      this.setState({
        successModalDisplay: true,
        successModalText: response.description,
      });
    } else {
      this.setState({
        failureModalDisplay: true,
        failureModalText: response.errorDescription,
      });
    }

  };

  showEditRulesButton() {
    // return this.state.allowedPermissions.includes(permissions.updateStickerPackRules)
    return this.state.allowedPermissions.includes(permissions.listStickerPackRules)
  }

  toggleBulkActionCollapse = () => {
    this.setState({ isBulkActionCollapseOpen: !this.state.isBulkActionCollapseOpen });
  };

  isAddButtonDisabled = () => {
    let { activeTab, allStickers, activeSticker } = this.state;
    switch (activeTab) {
      case "all":
        return allStickers.length === 0;
      case "active":
        return activeSticker.length === 0;
      default:
        return 1;
    }
  };

  closeBulkActionCollapse = () => {
    this.toggleBulkActionCollapse();
    this.setState({ selectedStickers: [] });
  };

  redirectToLocationRule = () => {
    let var1 = window.location.href.split("/")[0]
    let var2 = window.location.href.split("/")[2]
    let var3 = window.location.href.split("/")[5]
    window.location.href = `${var1}//${var2}/stickers/sticker-pack/${var3}/targetingRules`
  }

  showEditRulesAutoDownloadButton = () => {
    // return this.state.allowedPermissions.includes(permissions.updateStickerPackAutoDownloadRules) &&
    return this.state.allowedPermissions.includes(permissions.listStickerPackAutoDownloadRules)
  }

  redirectToAutoDownloadLocationRules = () => {
    let var1 = window.location.href.split("/")[0]
    let var2 = window.location.href.split("/")[2]
    let var3 = window.location.href.split("/")[5]
    window.location.href = `${var1}//${var2}/stickers/sticker-pack/${var3}/autoDownloadRules`
  }

  handleCheckboxChange = (event, sticker) => {
    if (event.target.checked) {
      this.setState({
        selectedStickers: [...this.state.selectedStickers, sticker],
      });
    } else {
      this.setState({
        selectedStickers: this.state.selectedStickers.filter(
          (stickerObj) => stickerObj !== sticker
        ),
      });
    }
  };

  displayRearrangeStickerModal = () => {
    this.setState({ showRearrangeModal: true });
  };

  hideRearrangeStickerModal = () => {
    this.setState({ showRearrangeModal: false });
  };

  displayImportStickersModal = () => {
    this.setState({ showImportModal: true });
  };

  hideImportStickersModal = () => {
    this.setState({ showImportModal: false });
  };

  displayStickerStatusModal = (sticker, action) => {
    this.setState({ selectedSticker: sticker, statusModalAction: action }, () => { this.setState({ showStatusModal: true }) });
  };

  hideStickerStatusModal = () => {
    this.setState({ showStatusModal: false });
  };

  failureModalClose = () => {
    this.setState({ failureModalDisplay: false });
  };

  successModalClose = () => {
    this.setState({ successModalDisplay: false, selectedStickers : [] });
    this.getStickerInPack(this.state.id).then(() => {
      this.handleFilterChange(this.state.filterStatus);
    });
    
  };

  fetchAllStickerPackStickers = async (filter, filterExpiredContents = false) => {
    this.setState({ loadingStickers: true });
    
    await fetchStickersInStickerPack(this.state.id, { limit: 999999, filter: filter, page: 0, filterExpiredContents: filterExpiredContents })
      .then((res) => {
        if (res.stickers) {
          let stickers = this.getActiveOrUnpublishedStickers(res.stickers);
          this.setState({
            stickersReordered: stickers,
            loadingStickers: false,
          });
        } else {
          this.setState({
            hasResponseError: true,
            failureModalText: errorDescription(res.errorCode),
          });
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({
          loadingStickers: false,
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      });
  }
  

  reorder = async (stickers) => {
    await reorderStickers(stickers, this.state.stickerPack.id)
      .then((response) => {
        if (response.status === "Success") {
          this.setState({
            successModalDisplay: true,
            successModalText: "Stickers rearranged successfully.",
          });
          window.location.reload();
        } else {
          this.setState({
            failureModalDisplay: true,
            failureModalText: response.errorDescription,
          });
        }
        this.hideRearrangeStickerModal();
      })
      .catch((err) => {
        this.setState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      });
  };

  import = async (stickers, stickerPackId) => {
    await importStickers(stickers, stickerPackId)
      .then((response) => {
        if (response.status === "Success") {
          this.setState({
            successModalDisplay: true,
            successModalText: "Stickers imported successfully.",
          });
        } else {
          this.setState({
            failureModalDisplay: true,
            failureModalText: response.errorDescription,
          });
        }
        this.hideImportStickersModal();
      })
      .catch((err) => {
        this.setState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      });
  };

  updateStickerStatus = async (stickerId, action, stickerPackIds, globalStatus) => {
    await updateStickerStatus(stickerId, action, stickerPackIds, globalStatus)
      .then((response) => {
        if (response.status === "Success") {
          this.setState({
            successModalDisplay: true,
            successModalText: "Sticker status updated successfully.",
          });
          window.location.reload();
        } else {
          this.setState({
            failureModalDisplay: true,
            failureModalText: response.errorDescription,
          });
        }
        this.hideStickerStatusModal();
      })
      .catch((err) => {
        this.setState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      });
  };

  redirectToStickersRules = () => {
    let var1 = window.location.href.split("/")[0]
    let var2 = window.location.href.split("/")[2]
    let stickerIds = this.state.selectedStickers.map(sticker => sticker.id).join(",")
    window.location.href = `${var1}//${var2}/stickers/targetingRules?stickerIds=${stickerIds}`
  }

  render() {
    return (
      <Container fluid className="p-0">
        {this.state.hasResponseError ? (
          <ResponseModal
            show={this.state.hasResponseError}
            onHide={() => {
              this.setState({ hasResponseError: false });
            }}
            modalheading={"Error"}
            modaltext={this.state.failureModalText}
          />
        ) : (
          <React.Fragment>
            <h1 className="h3 mb-3">{this.state.stickerPack.name}</h1>
            <Row>
              <Col md="9" xl="10">
                <Card>
                  <React.Fragment>
                    <CardImg
                      src={this.state.stickerPack.bannerImageURL}
                    ></CardImg>
                  </React.Fragment>
                  <CardBody className="d-md-flex">
                    <Container>
                      <Row>
                        <Col md="8">
                          <h3>Description</h3>
                          <p>{this.state.stickerPack.description}</p>
                        </Col>
                        <Col md="4">
                          {
                            this.state.loadingStickers
                              ? <Spinner animation="border" role="status" className="d-block ml-auto mt-2"></Spinner>
                              :
                              (
                                <Button color="secondary"
                                  className="d-block ml-auto mt-2"
                                  onClick={async () => {
                                    await this.fetchAllStickerPackStickers("Published", true);
                                    this.displayRearrangeStickerModal();
                                  }}
                                >
                                  Rearrange Stickers
                                </Button>
                              )
                          }
                        </Col>
                      </Row>
                      <div className="row">
                        <div className="col-md-8">
                          <h5>Active Head Supported Stickers:</h5>
                          <p>{this.state.stickerPack.activeHeadSupportedStickers}</p>
                        </div>
                      </div>
                      {this.state.stickerPack.activeHeadSupportedStickers > 0 &&
                        !this.state.stickerPack.isHeadSupported && (
                          <Row>
                            <Col className="pb-2">
                              <Alert className="opacity-3" variant="warning">
                                <span className="p-2">Note: The pack contains head supported stickers. Please edit the pack and enable Is Head Supported toggle.</span>
                              </Alert>
                            </Col>
                          </Row>
                        )}
                      {this.state.stickerPack.activeHeadSupportedStickers == 0
                        && this.state.stickerPack.isHeadSupported && (
                          <Row>
                            <Col className="pb-2">

                              <Alert className="opacity-3" variant="warning">
                                <span className="p-2">Note: The pack does not contain head supported stickers. Please edit the pack and disable Is Head Supported toggle.</span>
                              </Alert>
                            </Col>
                          </Row>
                        )}
                    </Container>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row className="px-3">
                      <Col md={8} sm={6}>
                        <h3>Targeting Rules</h3>
                        <p>Age-Gender, Clients and Locations based targeting for this sticker pack</p>
                      </Col>
                      {
                        this.showEditRulesButton() ?
                          <Col md={4} sm={6}>
                            <Button color="primary"
                              className="d-block ml-auto mt-2"
                              onClick={this.redirectToLocationRule}
                            >
                              Edit Rules
                            </Button>
                          </Col> : null
                      }
                    </Row>
                    {/* <Col>
                      <AgeGenderTag ageGenderTag={this.state.targetedAgeGenderData}></AgeGenderTag>
                    </Col>
                    <Col>
                      <ClientTags clients={this.state.targetedClientTagData}></ClientTags>
                    </Col>
                    <Col>
                      <CountryTags countries={this.state.targetedLocationData}></CountryTags>
                    </Col> */}
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row className="px-3">
                      <Col md={8} sm={6}>
                        <h3>Autodownload Targeting Rules</h3>
                        <p>Age-Gender, Clients, Locations based targeting for which this sticker pack gets auto downloaded</p>
                      </Col>
                      {
                        this.showEditRulesAutoDownloadButton() ?
                          <Col md={4} sm={6}>
                            <Button color="primary"
                              className="d-block ml-auto mt-2"
                              onClick={this.redirectToAutoDownloadLocationRules}
                            >
                              Edit Rules
                            </Button>
                          </Col> : null
                      }
                    </Row>
                    {/* <Col>
                      <AgeGenderTag ageGenderTag={this.state.ageGenderData}></AgeGenderTag>
                    </Col>
                    <Col>
                      <ClientTags clients={this.state.clientTagData}></ClientTags>
                    </Col>
                    <Col>
                      <CountryTags countries={this.state.locationData}></CountryTags>
                    </Col> */}
                  </CardBody>
                </Card>

                <Card className="mb-4">
                  <CardHeader>
                    <CardTitle className="mb-0">
                      <Row>
                        <Col className="pt-2">
                          <h4 className="mb-0 p-0 collapseHeading">
                            Bulk Actions on Stickers
                          </h4>
                        </Col>
                        <Col>
                          {this.state.isBulkActionCollapseOpen ? null : (
                            <Button
                              color="primary"
                              className="d-block float-right px-3"
                              onClick={this.toggleBulkActionCollapse}
                              disabled={this.isAddButtonDisabled()}
                            >
                              ACTION
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </CardTitle>
                  </CardHeader>
                </Card>

                <Collapse isOpen={this.state.isBulkActionCollapseOpen}>
                  <Card className="dropdownBackground">
                    <CardBody>
                      <Row>
                        <Col md={12}>
                          <Row className="mt-3 mb-3">
                            {this.showEditRulesButton() &&
                              <Button
                              className="ml-3 btn-success px-3"
                              onClick={() => this.updateStickerPackStickersDateTime()}
                              disabled={this.state.selectedStickers.length === 0}
                            >
                              Edit Date Time Range
                            </Button>
                            }
                            <Button
                              className="ml-3 btn-danger px-3"
                              onClick={this.closeBulkActionCollapse}
                            >
                              Cancel
                            </Button>
                          </Row>
                          {/* Add start date and end date fields */}
                          <Row className="mt-3 mb-3">
                            <Col md={6}>
                              <Label>Start At</Label>
                              <Input
                                type="datetime-local"
                                className="form-control"
                                placeholder="Start Date"
                                value={this.state.startAt}
                                onChange={(e) => this.setState({ startAt: e.target.value })}
                              />
                            </Col>
                            <Col md={6}>
                              <Label>End At</Label>
                              <Input
                                type="datetime-local"
                                className="form-control"
                                placeholder="End Date"
                                value={this.state.endAt}
                                onChange={(e) => this.setState({ endAt: e.target.value })}
                              />
                            </Col>
                          </Row>
                          <small className="mt-4">
                            {this.state.selectedStickers.length} Sticker(s) selected
                          </small>
                        </Col>

                      </Row>
                    </CardBody>
                  </Card>
                </Collapse>

              </Col>
            </Row>
            <Row className="align-items-center">
              <Col md={9} sm={6}>
                <h1 className="h3 mb-3 mt-4">Stickers</h1>
              </Col>
              <Col md={3} sm={6}>
                <UncontrolledButtonDropdown className="my-auto float-right">
                  <DropdownToggle
                    disabled={this.state.selectedStickers.length == 0}
                    caret color="secondary">
                    {"Bulk Operations"}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => {
                        this.displayImportStickersModal();
                      }}>Import Stickers</DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        console.log("Update Targeting Rules", this.state.selectedStickers);
                        this.redirectToStickersRules();
                      }}>Update Targeting Rules</DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </Col>
            </Row>
            {this.state.loading ? (
              <Loading />
            ) : (
              <Fragment>
                <Row>
                  <Col md={3} sm={12}>
                    <Search
                      placeholder="Search"
                      value={this.state.searchString}
                      onChange={this.handleSearchStringChange}
                      onKeyDown={this.onKeyDown}
                    ></Search>
                  </Col>
                  <Col md={3} sm={12}>
                    <UncontrolledButtonDropdown className="mr-1 mb-1">
                      <DropdownToggle caret color="success">
                        {"Search By " + capitalize(this.state.searchBy) + " "}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => { this.search("id") }}>ID</DropdownItem>
                        <DropdownItem onClick={() => { this.search("tag") }}>Tag</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </Col>
                </Row>
                <Row>
                  <Col md={3} sm={12}>
                    <Select
                      className="react-select-container mb-4"
                      classNamePrefix="react-select"
                      placeholder="Select Status"
                      onChange={this.handleFilterChange}
                      options={[
                        { value: "All", label: "All" },
                        { value: "Published", label: "Active" },
                        { value: "Deactivated", label: "Deactivated" },
                        { value: "Unpublished", label: "Unpublished" },
                        { value: "ActivatedGlobally", label: "Active Globally" },
                        { value: "DeactivatedGlobally", label: "Deactivated Globally" },
                      ]}
                    />
                  </Col>
                  <Col md={3} sm={12}>
                    <Select
                      className="react-select-container mb-4"
                      classNamePrefix="react-select"
                      placeholder="Sort By"
                      onChange={this.handleOrderChange}
                      options={[
                        { value: Config.UPDATED_AT_DESCENDING, label: "Updated At \u2193" },
                        { value: Config.UPDATED_AT_ASCENDING, label: "Updated At \u2191" },
                      ]}
                    />
                  </Col>
                </Row>
                {/* Date time filter */}
                <Row>
                <Col md={12} sm={12}>
                  <div className="d-flex align-items-center">
                    <Label className="font-weight-bold mr-2" style={{ fontSize: 15 }}>
                      Date Time
                    </Label>
                    <Alert className="opacity-3" variant="warning">
                      <span className="p-2">Filter that returns stickers available on a given date by checking if their “Start At” date is before or on the date, and their “End At” date is after or on the date.</span>
                    </Alert>
                  </div>
                </Col>
                  </Row>
                  <Row>
                  <Col md={3} sm={12}>
                  <Input
                        type="datetime-local"
                        className="form-control"
                        placeholder="Start At"
                        value={this.state.filterDateTime}
                        onChange={(e) => {
                          this.setState({ filterDateTime: e.target.value }, () => {
                            this.handleFilterChange(this.state.filterStatus);
                          });                          
                        }}
                      />
                  </Col>
                </Row>
                {/* Component to displays all the Stickers */}
                <InfiniteScroll
                  style={{ overflow: "auto !important" }}
                  className="infinite-scroll"
                  dataLength={this.state.filteredStickers.length}
                  next={this.fetchMoreData}
                  hasMore={this.state.hasMoreStickers}
                  loader={
                    this.state.fetchFailed ? (
                      false
                    ) : (
                      <Spinner color="info" className="d-flex mx-auto" />
                    )
                  }
                >
                  <Row className="mt-4 mx-0">
                    {this.state.filteredStickers.length === 0 ? (
                      <Container className="mt-5">
                        <h3 className="text-center">No Sticker available.</h3>
                        <h4 className="text-center">
                          Enter a valid search tag to view stickers.
                        </h4>
                      </Container>
                    ) : (
                      this.state.filteredStickers.map((sticker, index) => {
                        let status = getStatus(sticker.publishedAt, sticker.deactivatedAt);
                        let globalStatus = getStatus(sticker.publishedAtGlobal, sticker.deactivatedAtGlobal);
                        let color = getStatusColor(status);
                        let globalColor = getStatusColor(globalStatus);
                        return (
                          <Col md="6" lg="4" key={sticker.id}>
                            <Sticker
                              id={sticker.id}
                              packId={this.state.id}
                              createdAt={sticker.createdAt}
                              publishedAt={sticker.publishedAt}
                              deactivatedAt={sticker.deactivatedAt}
                              startAt={sticker.startAt}
                              endAt={sticker.endAt}
                              image={sticker.imageURL}
                              color={color}
                              globalColor={globalColor}
                              status={status}
                              globalStatus={globalStatus}
                              imageProcessingStatus={sticker.imageProcessingStatus}
                              isHeadSupported={sticker.isHeadSupported}
                              handleCheckboxChange={this.handleCheckboxChange}
                              sticker={sticker}
                              displayStickerStatusModal={this.displayStickerStatusModal}
                            />
                          </Col>
                        );
                      })
                    )}
                  </Row>
                </InfiniteScroll>
                <RearrangeStickers
                  show={this.state.showRearrangeModal}
                  onHide={this.hideRearrangeStickerModal}
                  stickers={this.state.stickersReordered}
                  isLoading={this.state.loadingStickers}
                  reorder={this.reorder}
                />
                <ImportStickers
                  show={this.state.showImportModal}
                  onHide={this.hideImportStickersModal}
                  stickers={this.state.selectedStickers}
                  stickerPacks={this.state.stickerPacks}
                  import={this.import}
                />
                <StickerStatus
                  show={this.state.showStatusModal}
                  onHide={this.hideStickerStatusModal}
                  stickers={this.state.selectedStickers}
                  sticker={this.state.selectedSticker}
                  stickerPacks={this.state.stickerPacks}
                  updateStickerStatus={this.updateStickerStatus}
                  action={this.state.statusModalAction}
                />
                {/* Modal component to display failure response */}
                <ResponseModal
                  show={this.state.failureModalDisplay}
                  onHide={this.failureModalClose}
                  modalheading={"Error"}
                  modaltext={this.state.failureModalText}
                />
                <ResponseModal
                  show={this.state.successModalDisplay}
                  onHide={this.successModalClose}
                  modalheading={"Success"}
                  modaltext={this.state.successModalText}
                />
              </Fragment>
            )}
          </React.Fragment>
        )}
      </Container>
    );
  }
}

export default StickerPack;

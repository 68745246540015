import React, { useState } from "react";
import TargetingRules from "../../TargetingRules";
import { retrieveFromLocalStorage } from "../../../../utilities/localStorageUtil";
import { permissions } from "../../../../utilities/commonUtil";
import {
  getKeyboardPromptsRules,
  saveKeyboardPromptsRules,
} from "../../../../utilities/apiUtils/keyboardPrompts";
import {
  getCountries,
  getRegions,
  getCities,
} from "../../../../utilities/apiUtils/keyboardPrompts";

import { getClients } from "../../../../utilities/apiUtils/cre";

const StyleDetail = (props) => {
  let id = props.match.params.id;

  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  const updateRulesAllowed = () => {
    return allowedPermissions.includes(
      permissions.keyboardPromptTargetingRulesUpdate
    );
  };

  return (
    <div>
      <TargetingRules
        showAgeGenderRules={true}
        showCustomAudienceTargetingRules={false}
        id={id}
        getRules={getKeyboardPromptsRules}
        saveRules={saveKeyboardPromptsRules}
        getClients={getClients}
        getCountries={getCountries}
        getRegions={getRegions}
        getCities={getCities}
        updateRulesAllowed={updateRulesAllowed()}
      />
    </div>
  );
};

export default StyleDetail;

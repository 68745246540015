import React, { useState, useEffect } from "react";
import { Card, CardBody, Container, Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { columns } from "./pushNotificationColumns";
import { statusOptions, scheduledOptions } from "../../../../config/ConfigPushNotifications";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import Search from "../../../../components/Search";
import Select from "react-select";
import ConfirmationModal from "../../../../pages/ui-elements/ConfirmationModal";
import ResponseModal from "../../../../pages/ui-elements/ResponseModal";

import { schedulingTypes } from "../../../../config/ConfigPushNotifications";


import {
    fetchPushNotifications,
    publishPushNotification,
    activatePushNotification,
    deactivatePushNotification,
    triggerInstantPushNotification,
} from "../../../../utilities/apiUtils/pushNotifications";

import {
    isOperationPermitted,
    processDateAndStatus,
    permissions,
    allActions,
    errorDescription,
    error,
    redirectOnTokenExpiry,
    reduceSentence,
} from "../../../../utilities/commonUtil";

import { retrieveFromLocalStorage } from "../../../../utilities/localStorageUtil";

import {
    successModalHeading,
    errorModalHeading,
} from "../../../../config/config";


const PushNotificationsTable = () => {
    const [pushNotificationList, setPushNotificationList] = useState([]);
    const [column] = useState(columns);
    const [filters, setFilters] = useState({});
    const [contentSearch, setContentSearch] = useState("");
    const [scheduledFilter, setScheduledFilter] = useState("");
    const [pushNotificationStatusFilter, setPushNotificationStatusFilter] = useState("");
    const [allowedPermissions, setAllowedPermissions] = useState(
        retrieveFromLocalStorage("userPermissions")
    );

    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
    const [action, setAction] = useState("");
    const [id, setId] = useState(null);
    const [failureModalText, setFailureModalText] = useState("");
    const [failureModalDisplay, setFailureModalDisplay] = useState(false);
    const [successModalText, setSuccessModalText] = useState("");
    const [successModalDisplay, setSuccessModalDisplay] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        let data = await fetchPushNotifications();
        if (
            data.errorCode === error.tokenExpired ||
            data.errorCode === error.invalidAccessToken
        )
            redirectOnTokenExpiry();


        if (data?.notifications !== undefined && data?.notifications?.length > 0) {

            data.notifications.map((item) => {
                item = processDateAndStatus(item);
            })

            // set userSegmentName
            data.notifications.map((item) => {
                item.userSegmentName = reduceSentence(item.userSegment.name) + " - " + item.userSegment.id;
            })

            setPushNotificationList(data.notifications);
        }
    };

    let disablePublishButton = (row) => {
        return row.publishedAt !== null;
    };

    let disableDeactivateButton = (row) => {
        return row.deactivatedAt !== null || row.publishedAt === null;
    };

    let disableActivateButton = (row) => {
        return row.deactivatedAt === null;
    };

    let setConfirmationModalState = (id, event) => {
        setAction(event);
        setConfirmationModalIsOpen(true);
        setId(id);
    };

    let showSuccessModal = (text) => {
        setSuccessModalText(text);
        setSuccessModalDisplay(true);
    };

    let successModalClose = async () => {
        setSuccessModalDisplay(false);
        await fetchData();
    };

    let failureModalClose = () => {
        setFailureModalDisplay(false);
    };

    let showErrorModal = (text) => {
        setFailureModalText(text);
        setFailureModalDisplay(true);
    };


    let onTableChange = (type, newState) => {
        setFilters(newState.filters);
    };

    let publish = async (id, scheduling) => {
        let response = await publishPushNotification(id);
        if (response?.status === "success") {
            let text = "Push Notification has been Published";
            showSuccessModal(text);

        } else {
            let text = response?.errorDescription || errorDescription(response?.errorCode);
            showErrorModal(text);
        }

        // send a send request to the server
        if (scheduling === schedulingTypes.SendOnPublish) {
            await triggerInstantNotification(id);
        }
    };

    let deactivate = async (id) => {
        let response = await deactivatePushNotification(id);
        if (response?.status === "success") {
            let text = "Push Notification has been Deactivated";
            showSuccessModal(text);
        } else {
            let text = response?.errorDescription || errorDescription(response?.errorCode);
            showErrorModal(text);
        }
    };

    let activate = async (id) => {
        let response = await activatePushNotification(id);
        if (response?.status === "success") {
            let text = "Push Notification has been Activated";
            showSuccessModal(text);
        } else {
            let text = response?.errorDescription || errorDescription(response?.errorCode);
            showErrorModal(text);
        }
    };

    let triggerInstantNotification = async (id) => {
        let response = await triggerInstantPushNotification(id);
        if (response?.status === "success") {
            let text = "Push Notification has been Triggered";
            showSuccessModal(text);
        } else {
            let text = response?.errorDescription || errorDescription(response?.errorCode);
            showErrorModal(text);
        }
    };

    const expandRow = {
        onlyOneExpanding: true,
        renderer: (row) => (
            <div>
                <ul>
                    <Row className="justify-content-md-center mx-auto mb-4">
                        <Col md={6} sm={12}>
                            <li>Layout Type : {row.layoutType !== null ? row.layoutType : "null"}</li>
                            <li>Name : {row.name !== null ? row.name : "null"}</li>
                            <li>Content Title : {row.contentTitle !== null ? reduceSentence(row.contentTitle) : "null"}</li>
                            <li>Content Text : {row.contentText !== null ? reduceSentence(row.contentText) : "null"}</li>
                            <li>Big Text : {row.bigText !== null ? reduceSentence(row.bigText) : "null"}</li>
                            <li>Priority : {row.priority !== null ? row.priority : "null"}</li>
                            <li>Start Date : {row.startDate !== null ? row.startDate : "null"}</li>
                            <li>End Date : {row.endDate !== null ? row.endDate : "null"}</li>
                            <li>User Segment Id : {row.userSegmentName !== null ? row.userSegmentName : "null"}</li>
                        </Col>
                        <Col md={6} sm={12}>
                            <li><a
                                href={row.largeIconImageURL}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Large Icon Image URL
                            </a></li>
                            <li><a
                                href={row.bigPictureImageURL}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Big Picture Image URL
                            </a></li>
                            <li><a
                                href={row.smallPictureImageURL}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Small Picture Image URL
                            </a></li>
                            <li>Scheduling Status : {row.scheduling !== null ? row.scheduling : "null"}</li>
                            <li>Created At : {row.createdAt !== null ? row.createdAt : "null"}</li>
                            <li>Updated At : {row.updatedAt !== null ? row.updatedAt : "null"}</li>
                            <li>Published At : {row.publishedAt !== null ? row.publishedAt : "null"}</li>
                            <li>Deactivated At : {row.deactivatedAt !== null ? row.deactivatedAt : "null"}</li>
                        </Col>
                        <Col md={12} sm={12}>
                            <li>Description : {row.description !== null ? row.description : "null"}</li>
                        </Col>
                    </Row>
                </ul>

                <Row className="mx-auto mb-4">

                    {(isOperationPermitted(
                        allowedPermissions,
                        permissions.notificationEdit
                    ) ||
                        isOperationPermitted(
                            allowedPermissions,
                            permissions.notificationDetails
                        )) && (
                            <Link
                                className="text-decoration-none"
                                to={{
                                    pathname: isOperationPermitted(allowedPermissions, permissions.notificationEdit) && row.publishedAt === null ? `/push-notification/edit/${row.id}` : `/push-notification/view/${row.id}`,
                                }}
                            >
                                <Button className="mx-3">
                                    {isOperationPermitted(
                                        allowedPermissions,
                                        permissions.notificationEdit
                                    ) && row.publishedAt === null
                                        ? "Edit"
                                        : "View"}
                                </Button>
                            </Link>
                        )}

                    {isOperationPermitted(allowedPermissions, permissions.notificationPublish) && !disablePublishButton(row) && (
                        <Button
                            className="mx-3  btn-success"
                            disabled={disablePublishButton(row)}
                            onClick={(event) => {
                                const confirmationMessage = row.scheduling === schedulingTypes.SendOnPublish ? " ? This will start sending the notification to users immediately once published. Are you sure you want to perform this action" : "";
                                setConfirmationModalState(row.id, event.target.innerHTML + confirmationMessage);
                            }}
                        >
                            Publish
                        </Button>
                    )}

                    {isOperationPermitted(allowedPermissions, permissions.notificationDeactivate) && !disableDeactivateButton(row) && (
                        <Button
                            className="mx-3 btn-danger"
                            disabled={disableDeactivateButton(row)}
                            onClick={(event) =>
                                setConfirmationModalState(row.id, event.target.innerHTML)
                            }
                        >
                            Deactivate
                        </Button>
                    )}

                    {isOperationPermitted(allowedPermissions, permissions.notificationActivate) && !disableActivateButton(row) && (
                        <Button
                            className="mx-3 btn-success"
                            disabled={disableActivateButton(row)}
                            onClick={(event) =>
                                setConfirmationModalState(row.id, event.target.innerHTML)
                            }
                        >
                            Activate
                        </Button>
                    )}

                    {isOperationPermitted(allowedPermissions, permissions.notificationRuleView) && (
                        <Link
                            className="text-decoration-none"
                            to={{
                                pathname: isOperationPermitted(allowedPermissions, permissions.notificationRuleUpdate) && row.publishedAt === null ? `/push-notification/targeting-rules/${row.id}` : `/push-notification/targeting-rules/view/${row.id}`,
                            }}
                        >
                            <Button className="mx-3" color="primary">{isOperationPermitted(allowedPermissions, permissions.notificationRuleUpdate) && row.publishedAt === null ? "Edit Targeting Rules" : "View Targeting Rules"}</Button>
                        </Link>
                    )}

                    {isOperationPermitted(allowedPermissions, permissions.notificationCreate) && (
                        <Link
                            className="text-decoration-none"
                            target="_blank" // Open in a new tab
                            to={{
                                pathname: `/push-notification/add?id=${row.id}`,
                                formName: "duplicate",
                                id: row.id,
                            }}
                        >
                            <Button className="mx-3 btn-secondary">Duplicate</Button>
                        </Link>
                    )}

                    {isOperationPermitted(allowedPermissions, permissions.notificationExecutionList) && (
                        <Link
                            className="text-decoration-none"
                            to={{
                                pathname: `/push-notification/execution/${row.id}`,
                            }}
                        >
                            <Button className="mx-3" color="warning">View executions</Button>
                        </Link>
                    )}


                </Row>
                <Row className="mx-auto mb-4">
                    {isOperationPermitted(allowedPermissions, permissions.notificationTest) && (
                        <Link
                            className="text-decoration-none"
                            to={{
                                pathname: `/push-notification/test/${row.id}`,
                            }}
                        >
                            <Button className="mx-3" color="info">Test Notification</Button>
                        </Link>
                    )}

                </Row>

                <ConfirmationModal
                    show={confirmationModalIsOpen}
                    modaltext={action}
                    onConfirmationTrue={() => {
                        setConfirmationModalIsOpen(false);
                        if (action.includes(allActions.publish)) publish(id, row?.scheduling);
                        else if (action === allActions.deactivate) deactivate(id);
                        else if (action === allActions.activate) activate(id);
                        else if (action === "Send Notification") triggerInstantNotification(id);
                    }}
                    onHide={() => setConfirmationModalIsOpen(false)}
                />
                <ResponseModal
                    show={successModalDisplay}
                    onHide={successModalClose}
                    modalheading={successModalHeading}
                    modaltext={successModalText}
                />
                <ResponseModal
                    show={failureModalDisplay}
                    onHide={failureModalClose}
                    modalheading={errorModalHeading}
                    modaltext={failureModalText}
                />
            </div>
        )
    };

    const filterPushNotification = () => {
        let filteredData = [...pushNotificationList];

        if (contentSearch !== "") {
            filteredData = filteredData?.filter((item) =>
                item?.contentTitle?.toLowerCase().includes(contentSearch?.toLowerCase()) ||
                item?.contentText?.toLowerCase().includes(contentSearch?.toLowerCase()) ||
                item?.name?.toLowerCase().includes(contentSearch?.toLowerCase()) ||
                item?.description?.toLowerCase().includes(contentSearch?.toLowerCase())
            );
        }

        if (scheduledFilter.value)
            filteredData = filteredData?.filter((item) => item.scheduling === scheduledFilter.value);

        if (pushNotificationStatusFilter.value) {
            filteredData = filteredData?.filter((item) => item.status === pushNotificationStatusFilter.value);

        }

        

        return filteredData;
    }



    return (
        <Container fluid className="p-0">
            <Row className="mb-3">
                <Col md={6} xs={6}>
                    <h1>Push Notification</h1>
                </Col>

                {isOperationPermitted(
                    allowedPermissions,
                    permissions.notificationCreate
                ) && (
                        <Link
                            className="d-block ml-auto mb-4 pr-3 text-decoration-none"
                            to={{
                                pathname: `/push-notification/add`,
                                state: {
                                    formType: "Add",
                                },
                            }}
                        >
                            <Button color="success" className="ml-auto d-flex btn-success">
                                <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                                    Add
                                </p>
                            </Button>
                        </Link>
                    )}
            </Row>
            <Card style={{ overflow: "hidden", paddingBottom: "100px" }}>
                <div container-fullwidth="true" style={{ margin: "2%" }}>
                    <Row
                        className="mx-auto"
                        style={{ display: "flex", justifyContent: "right" }}
                    ></Row>
                    <Row>
                        <Col md={3} sm={12}>
                            <Search
                                placeholder="Search"
                                value={contentSearch}
                                onChange={(e) => setContentSearch(e.target.value)}
                            ></Search>
                        </Col>
                        <Col md={3} sm={12}>
                            <Select
                                className="react-select-container"
                                id="scheduledSelect"
                                classNamePrefix="react-select"
                                name="scheduled"
                                placeholder="Select Scheduled Type"
                                value={scheduledFilter}
                                onChange={(event) => {
                                    setScheduledFilter(event);
                                }}
                                options={scheduledOptions}
                            />
                        </Col>
                        <Col md={3} sm={12}>
                            <Select
                                className="react-select-container"
                                id="pushNotificationStatusSelect"
                                classNamePrefix="react-select"
                                name="pushNotificationStatus"
                                placeholder="Select Push Notification Status"
                                value={pushNotificationStatusFilter}
                                onChange={(event) => {
                                    setPushNotificationStatusFilter(event);
                                }}
                                options={statusOptions}
                            />
                        </Col>
                        <CardBody>
                            <ToolkitProvider
                                keyField="id"
                                data={pushNotificationList}
                                columns={column}
                                search
                            >
                                {(props) => (
                                    <div>
                                        <BootstrapTable
                                            bootstrap4={true}
                                            wrapperClasses="table-responsive"
                                            hover={true}
                                            bordered={false}
                                            keyField="id"
                                            data={filterPushNotification()}
                                            columns={column}
                                            expandRow={expandRow}
                                            filter={filterFactory()}
                                            pagination={paginationFactory({
                                                sizePerPage: 10,
                                                sizePerPageList: [5, 10, 25, 50],
                                            })}
                                            remote={{ filter: true }}
                                            onTableChange={onTableChange}
                                            noDataIndication="No results found"
                                            defaultSorted={[{
                                                dataField: 'id',  // specify the field you want to sort by
                                                order: 'desc'     // 'desc' for descending order
                                            }]}                                        
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </CardBody>
                    </Row>
                </div>
            </Card>
        </Container>
    );
};

export default PushNotificationsTable;
import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Container,
  Card,
  CardBody,
  Label,
  Form,
  FormGroup,
  Badge,
} from "reactstrap";
import { useLocation } from 'react-router-dom'

import Routes from "../../../routes/index";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";

import QuickReplyTypeText from "./components/QuickReplyTypeText";
import QuickReplyTypeImage from "./components/QuickReplyTypeImage";
import QuickReplyTypeCard from "./components/QuickReplyTypeCard";
import ConfirmationModal from "../../../pages/ui-elements/ConfirmationModal";
import ResponseModal from "../../../pages/ui-elements/ResponseModal";

import {
  getLanguages,
  getQuickReplyCategories,
  getQuickRepliesTargetingRulesAndType,
  getQuickReplyDetail,
  getCountries,
  getCities,
  getRegions,
  createQuickReply,
  updateQuickReply,
} from "../../../utilities/apiUtils/quickReplies";

import {
  getBrandCampaigns,
  getClients,
} from "../../../utilities/apiUtils/stickers";

import {
  errorDescription,
  error,
  redirectToNewTab,
  redirectTo,
  permissions,
} from "../../../utilities/commonUtil";

import Select, { components } from "react-select";

import {
  quickRepliesPageType,
  trackersTypeOptions,
  quickRepliesTextDetailsType,
  quickRepliesCTAActionsType,
  quickRepliesCTAActionsRedirectionType,
  quickRepliesCTAActionsDeeplinkType,
  invalidColorCodeResponse,
} from "../../../config/quickReplies";

const CreateQuickReplyPage = (props) => {
  // define the state of the component
  const [quickReply, setQuickReply] = useState({
    viewType: null,
    categoryID: null,
    brandCampaignId: null,
    languageCode: null,
  });
  const [quickReplyTypeText, setQuickReplyTypeText] = useState({
    rawText: null,
    attributionText: null,
    additionalDetails: null,
    priority: null,
    emojiToggle: false,
  });
  const [quickReplyTypeImage, setQuickReplyTypeImage] = useState({
    imageFilename: null,
    image: null,
    priority: null,
    clickTrackers: [],
    impressionTrackers: [],
  });
  const [quickReplyTypeCard, setQuickReplyTypeCard] = useState({
    imageFilename: null,
    image: null,
    lightThemeBackgroundColor: null,
    darkThemeBackgroundColor: null,
    startDate: null,
    endDate: null,
    textDetails: [
      {
        type: null,
        lightThemeTextColor: null,
        darkThemeTextColor: null,
        text: null,
      },
    ],
    ctaItems: [],
    actions: [],
    clickTrackers: [],
    impressionTrackers: [],
  });

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [brandCampaignsOptions, setBrandCampaignsOptions] = useState([]);
  const [languagesOptions, setLanguagesOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [rules, setRules] = useState([]);

  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [action, setAction] = useState("");
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );
  const [categories, setCategories] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');


  // useEffect to call api
  useEffect(() => {
    getCategoriesOptions();
    getBrandCampaignsOptions();
    getLanguagesOptions();
    _getClientOptions();
  }, []);

  // useEffect for formName edit
  useEffect(() => {
    const getRules = async () => {
      let rules = await getQuickRepliesTargetingRulesAndType(props.id);
      let countryList = await getCountries();
      let allRules = [];

      for (const item of rules.rules) {
        for (const locationItem of item.locationRules) {
          let countryName = countryList.find(
            (country) => country.value === locationItem.countryID
          )?.label;

          // Use a try-catch block to handle the error when calling getRegions
          try {
            let regionList = await getRegions(locationItem.countryID);
            let regionName = regionList.find(
              (region) => region.value === locationItem.regionID
            )?.label;

            let cityList = await getCities(
              locationItem.countryID,
              locationItem.regionID
            );
            let cityName = cityList.find(
              (city) => city.value === locationItem.cityID
            )?.label;

            allRules.push({
              ...item,
              effect : locationItem.effect,
              countryName: countryName,
              regionName: regionName,
              cityName: cityName,
            });
          } catch (error) {
            console.error("Error fetching regions:", error);
          }
        }
      }

      allRules.targetingDetailsSource = rules.targetingDetailsSource;
      setRules(allRules);
    };
    
    if (
      props.formName === "edit" &&
      clientOptions.length > 0 &&
      categoryOptions.length > 0 &&
      brandCampaignsOptions.length > 0 &&
      languagesOptions.length > 0
    ) {
      getRules();
      getQuickReply(props.quickReply);
    }
  }, [
    clientOptions,
    props.formName,
    props.id,
    categoryOptions,
    brandCampaignsOptions,
    languagesOptions,
    props.quickReply,
  ]);

  useEffect(() => {
    if ((props?.location?.formName === "duplicate" || id !== null)  && clientOptions.length > 0 &&
    categoryOptions.length > 0 &&
    brandCampaignsOptions.length > 0 &&
    languagesOptions.length > 0) {
      
      getQuickReplyDetails();
    }
  }, [props?.location?.formName, clientOptions, categoryOptions, brandCampaignsOptions, languagesOptions]);

  // subcomponent
  const requiredComponent = (props) => {
    let required = props.selectProps.required;
    return (
      <div>
        <components.Input {...props} required={required} />
      </div>
    );
  };

  const getQuickReplyDetails = async () => {
    const response = await getQuickReplyDetail(props?.location?.id || id);
    if (response) {
      getQuickReply(response);
    }
  };

  // function to get the quick reply details
  const getQuickReply =  (data) => {
    let response = data;
    setQuickReply({
      viewType: quickRepliesPageType.find(
        (item) => item.value === response.viewType
      ),
      categoryID: categoryOptions.find(
        (item) => item.value === response.categoryID
      ),
      brandCampaignId: brandCampaignsOptions.find(
        (item) => item.value === response.brandCampaignId
      ),
      languageCode: languagesOptions.find(
        (item) => item.value === response.languageCode
      ),
    });
    if (response) {
      if (response.viewType === "text") {
        setQuickReplyTypeText({
          rawText: response.rawText,
          attributionText: response.attributionText,
          additionalDetails: response.additionalDetails,
          priority: response.priority,
          customizedText: response.text,
          emojiToggle: response.emojiToggle,
        });
      }

      if (response.viewType === "image") {
        setQuickReplyTypeImage({
          imageFilename: response.imageFilename,
          // image: response.image,
          imageURL: response.imageURL,
          priority: response.priority,
          clickTrackers: response?.clickTrackers?.map((item) => ({
            type: trackersTypeOptions.find(
              (tracker) => tracker.value === item.type
            ),
            url: item.url,
          })),
          impressionTrackers: response?.impressionTrackers?.map((item) => ({
            type: trackersTypeOptions.find(
              (tracker) => tracker.value === item.type
            ),
            url: item.url,
          })),
        });
      }

      if (response.viewType === "card") {
        setQuickReplyTypeCard({
          imageFilename: response.imageFilename,
          // image: response.image,
          imageURL: response.imageURL,
          lightThemeBackgroundColor: response.lightThemeBackgroundColor !== "" ? response.lightThemeBackgroundColor : null,
          darkThemeBackgroundColor: response.darkThemeBackgroundColor !== "" ? response.darkThemeBackgroundColor : null,
          startDate: response.startDate?.split("T")[0],
          endDate: response.endDate?.split("T")[0],
          textDetails: response?.textDetails?.map((item) => ({
            type: quickRepliesTextDetailsType.find(
              (textDetail) => textDetail.value === item.type
            ),
            lightThemeTextColor: item.lightThemeTextColor !== "" ? item.lightThemeTextColor : null,
            darkThemeTextColor: item.darkThemeTextColor !== "" ? item.darkThemeTextColor : null,
            text: item.text,
          })),
          ctaItems: response.ctaItems?.map((item) => ({
            text: item.text,
            widthPercentage: item.widthPercentage,
            lightThemeBackgroundImageURL: item.lightThemeBackgroundImage,
            lightThemeBackgroundImageName:
              item.lightThemeBackgroundImageFilename,
            darkThemeBackgroundImageURL: item.darkThemeBackgroundImage,
            darkThemeBackgroundImageName:
              item.darkThemeBackgroundImageFilename,
            lightThemeIconImageURL: item.lightThemeIconImage,
            lightThemeIconImageName: item.lightThemeIconImageFilename,
            darkThemeIconImageURL: item.darkThemeIconImage,
            darkThemeIconImageName: item.darkThemeIconImageFilename,

            textColor: item.textColor !== "" ? item.textColor : null,
            darkThemeTextColor: item.darkThemeTextColor !== "" ? item.darkThemeTextColor : null,
            clientId: clientOptions.find(
              (client) => client.value === item.clientId
            ),
            priority: item.priority,
            clickTrackers: item.clickTrackers?.map((item) => ({
              type: trackersTypeOptions.find(
                (tracker) => tracker.value === item.type
              ),
              url: item.url,
            })),
            ctaActions: item.actions?.map((actionItem) => ({
              type: quickRepliesCTAActionsType.find(
                (action) => action.value === actionItem.type
              ),
              clientId: clientOptions.find(
                (client) => client.value === actionItem.clientId
              ),
              textToCopy: actionItem.textToCopy,
              shareText: actionItem.shareText,
              redirectionType: quickRepliesCTAActionsRedirectionType.find(
                (redirection) =>
                  redirection.value === actionItem.redirectionType
              ),
              redirectionViewportHeight: actionItem.redirectionViewportHeight,
              deeplink: actionItem.deeplink,
              deeplinkType: quickRepliesCTAActionsDeeplinkType.find(
                (deeplink) => deeplink.value === actionItem.deeplinkType
              ),
              url: actionItem.url,
              priority: actionItem.priority,
            })),
          })),
          actions: response.actions?.map((item) => ({
            type: quickRepliesCTAActionsType.find(
              (action) => action.value === item.type
            ),
            clientId: clientOptions.find(
              (client) => client.value === item.clientId
            ),
            textToCopy: item.textToCopy,
            shareText: item.shareText,
            redirectionType: quickRepliesCTAActionsRedirectionType.find(
              (redirection) => redirection.value === item.redirectionType
            ),
            redirectionViewportHeight: item.redirectionViewportHeight,
            deeplink: item.deeplink,
            deeplinkType: quickRepliesCTAActionsDeeplinkType.find(
              (deeplink) => deeplink.value === item.deeplinkType
            ),
            url: item.url,
          })),
          clickTrackers: response?.clickTrackers?.map((item) => ({
            type: trackersTypeOptions.find(
              (tracker) => tracker.value === item.type
            ),
            url: item.url,
          })),
          impressionTrackers: response?.impressionTrackers?.map((item) => ({
            type: trackersTypeOptions.find(
              (tracker) => tracker.value === item.type
            ),
            url: item.url,
          })),
        });
      }
    }
  };

  // functions to handle the changes in the form
  const getCategoriesOptions = async () => {
    try {
      let response = await getQuickReplyCategories();
      setCategories(response?.quickReplyCategories);
      let categories = response?.quickReplyCategories?.map((item) => ({
        value: item.ID,
        label: item.name,
      }));
      setCategoryOptions(categories);
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

 const getViewTypeForCategory = (categoryID) => {
   let viewType = categories.find((item) => item.ID === categoryID)?.viewType;
  return viewType;
  }

  const getViewTypeItem = (viewType) => {
    let viewTypeItem = quickRepliesPageType.find(
      (item) => item.value === viewType
    );
    return viewTypeItem;
  }

  const getViewTypeForCategoryID = async (categoryID) => {
    let viewType = getViewTypeForCategory(categoryID);
    let viewTypeItem = getViewTypeItem(viewType);
    return viewTypeItem;
  }

  const getBrandCampaignsOptions = async () => {
    try {
      let response = await getBrandCampaigns();
      setBrandCampaignsOptions(response);
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const getLanguagesOptions = async () => {
    try {
      let response = await getLanguages();
      // remove All if present
      response = response?.filter((item) => item?.value !== "All");
      setLanguagesOptions(response);
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const _getClientOptions = async () => {
    let clientOptions = await getClients();
    setClientOptions(clientOptions);
  };

  let setConfirmationModalState = (event) => {
    event.preventDefault();
    setAction("Submit");
    setConfirmationModalIsOpen(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    redirectTo(Routes.quickReplies.path);
  };

  const handleSubmit = async () => {
    let payload = {};

    let inputsWithError = document.getElementsByClassName("is-invalid");
    if (inputsWithError.length !== 0) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: invalidColorCodeResponse,
      });
      return;
    }

    if (quickReply.viewType?.value === "text") {
      if (!quickReplyTypeText.rawText) {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.invalidData),
        });
        return;
      } else {
        payload = {
          viewType: quickReply.viewType.value,
          categoryID: quickReply.categoryID.value,
          brandCampaignId: quickReply.brandCampaignId?.value,
          languageCode: quickReply.languageCode.value,
          rawText: quickReplyTypeText.rawText,
          attributionText: quickReplyTypeText.attributionText,
          additionalDetails: quickReplyTypeText.additionalDetails,
          priority: parseInt(quickReplyTypeText.priority),
          emojiToggle: quickReplyTypeText.emojiToggle,
        };
      }
    }

    if (quickReply.viewType?.value === "image") {
      if (
        props.formName !== "edit" &&
        (!quickReplyTypeImage.imageFilename)
      ) {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.invalidData),
        });
        return;
      } else {
        payload = {
          viewType: quickReply.viewType.value,
          categoryID: quickReply.categoryID.value,
          brandCampaignId: quickReply.brandCampaignId?.value,
          languageCode: quickReply.languageCode.value,
          imageFilename: quickReplyTypeImage.imageFilename,
          image: quickReplyTypeImage.image,
          priority: parseInt(quickReplyTypeImage.priority),
          clickTrackers: quickReplyTypeImage.clickTrackers?.map((item) => ({
            type: item.type.value,
            url: item.url,
          })),
          impressionTrackers: quickReplyTypeImage.impressionTrackers?.map(
            (item) => ({
              type: item.type.value,
              url: item.url,
            })
          ),
        };
      }
    }

    if (quickReply.viewType?.value === "card") {
      payload = {
        viewType: quickReply.viewType?.value,
        categoryID: quickReply.categoryID?.value,
        brandCampaignId: quickReply.brandCampaignId?.value,
        languageCode: quickReply.languageCode?.value,
        imageFilename: quickReplyTypeCard.imageFilename,
        image: quickReplyTypeCard.image,
        lightThemeBackgroundColor: quickReplyTypeCard.lightThemeBackgroundColor !== "" ? quickReplyTypeCard.lightThemeBackgroundColor : null,
        darkThemeBackgroundColor: quickReplyTypeCard.darkThemeBackgroundColor !== "" ? quickReplyTypeCard.darkThemeBackgroundColor : null,
        startDate: quickReplyTypeCard.startDate,
        endDate: quickReplyTypeCard.endDate,
        textDetails: quickReplyTypeCard.textDetails?.map((item) => ({
          type: item.type?.value,
          lightThemeTextColor: item.lightThemeTextColor !== "" ? item.lightThemeTextColor : null,
          darkThemeTextColor: item.darkThemeTextColor !== "" ? item.darkThemeTextColor : null,
          text: item.text,
        })),
        ctaItems: quickReplyTypeCard.ctaItems?.map((item) => ({
          text: item.text,
          widthPercentage: parseInt(item.widthPercentage),
          lightThemeBackgroundImage: item.lightThemeBackgroundImage,
          lightThemeBackgroundImageFilename: item.lightThemeBackgroundImageName,
          darkThemeBackgroundImage: item.darkThemeBackgroundImage,
          darkThemeBackgroundImageFilename: item.darkThemeBackgroundImageName,
          lightThemeIconImage: item.lightThemeIconImage,
          lightThemeIconImageFilename: item.lightThemeIconImageName,
          darkThemeIconImage: item.darkThemeIconImage,
          darkThemeIconImageFilename: item.darkThemeIconImageName,
          textColor: item.textColor !== "" ? item.textColor : null,
          darkThemeTextColor: item.darkThemeTextColor !== "" ? item.darkThemeTextColor : null,
          clientId: item.clientId?.value,
          priority: parseInt(item.priority),
          clickTrackers: item.clickTrackers?.map((item) => ({
            type: item.type?.value,
            url: item.url,
          })),
          actions: item?.ctaActions?.map((actionItem) => ({
            type: actionItem.type?.value,
            clientId: actionItem.clientId?.value,
            textToCopy: actionItem.textToCopy,
            shareText: actionItem.shareText,
            redirectionType: actionItem.redirectionType?.value,
            redirectionViewportHeight: parseInt(
              actionItem.redirectionViewportHeight
            ),
            deeplink: actionItem.deeplink,
            deeplinkType: actionItem.deeplinkType?.value,
            url: actionItem.url,
            priority: parseInt(actionItem.priority),
          })),
        })),
        actions: quickReplyTypeCard?.actions?.map((item) => ({
          type: item.type?.value,
          clientId: item.clientId?.value,
          textToCopy: item.textToCopy,
          shareText: item.shareText,
          redirectionType: item.redirectionType?.value,
          redirectionViewportHeight: parseInt(item.redirectionViewportHeight),
          deeplink: item.deeplink,
          deeplinkType: item.deeplinkType?.value,
          url: item.url,
        })),
        clickTrackers: quickReplyTypeCard.clickTrackers?.map((item) => ({
          type: item.type?.value,
          url: item.url,
        })),
        impressionTrackers: quickReplyTypeCard.impressionTrackers?.map(
          (item) => ({
            type: item.type?.value,
            url: item.url,
          })
        ),
      };
    }

    try {
      let response = null;
      if (props.formName === "edit") {
        response = await updateQuickReply(payload, props.id);
      } else {
        response = await createQuickReply(payload);
      }

      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        ) {
          // redirectionOnTokenExpiry();
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: errorDescription(response.errorCode),
          });
          return false;
        }
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: response.errorDescription,
        });
      } else {
        setSuccessModalText(response?.description);
        setSuccessModalDisplay(true);
      }
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const showTargetingRulesButton = () => {
    return allowedPermissions.includes(permissions.quickReplyRulesView);
  };

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">
        {props.formName === "edit" ? "Edit Quick Reply" : "Create Quick Reply"}
      </h1>
      {props.formName === "edit" && showTargetingRulesButton() && (
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <Row>
                  <Col
                    md={6}
                    style={{
                      marginBottom: "25px",
                    }}
                  >
                    <h3>
                      <strong>
                        Targeting Details Source :{" "}
                        <Badge className="badge-success" pill>
                          {rules?.targetingDetailsSource}
                        </Badge>
                      </strong>
                    </h3>
                  </Col>
                  <Col
                    md={6}
                    style={{
                      marginBottom: "25px",
                    }}
                  >
                    <Button
                      className="float-right"
                      color="secondary"
                      onClick={() => {
                        redirectToNewTab(
                          `/quick-reply/targeting-rules/${props.id}`
                        );
                      }}
                    >
                      Edit Targeting Rules
                    </Button>
                  </Col>
                      
                  <Row className="">
                    {rules?.map((item, index) => (
                      <Col md={12} key={index}>
                        <Badge
                          className="float-left m-1"
                          color={`${item.activate ? "success" : "danger"}`}
                        >
                          Client:{" "}
                          {
                            item?.clientRules.map((clientRule, index) => {
                              const label = clientOptions.find(
                                (client) => client.value === clientRule.clientID
                              )?.label;
                              return index === 0 ? label : `, ${label}`;
                            })
                          }
                        </Badge>
                        <Badge
                          className="float-left m-1"
                          color={`${item.activate && item.effect === "allow" ? "success" : "danger"}`}
                        >
                          {item?.countryName} : {item?.regionName} : {item?.cityName}
                        </Badge>
                        <Badge
                          className="float-left m-1"
                          color={`${item.activate ? "success" : "danger"}`}
                        >
                          Max App:{" "}
                          {item?.appVersionRules?.maxAppVersion === null
                            ? "null"
                            : item?.appVersionRules?.maxAppVersion}
                        </Badge>
                        <Badge
                          className="float-left m-1"
                          color={`${item.activate ? "success" : "danger"}`}
                        >
                          Min App:{" "}
                          {item?.appVersionRules?.minAppVersion === null
                            ? "null"
                            : item?.appVersionRules?.minAppVersion}
                        </Badge>
                      </Col>
                    ))}
                  </Row>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}

      <Form onSubmit={(event) => setConfirmationModalState(event)}>
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <Row>
                <Col md={6}>
                    <FormGroup>
                      <Label>
                        <strong>
                          Category <span className="text-danger">*</span>
                        </strong>
                      </Label>
                      <Select
                        name="category"
                        className="react-select-container mb-4"
                        classNamePrefix="react-select"
                        options={categoryOptions}
                        onChange={(e) =>
                          {
                          getViewTypeForCategoryID(e.value).then((viewTypeItem) => {
                            setQuickReply({ ...quickReply, categoryID: e, viewType: viewTypeItem });
                          })
                        }
                        }
                        value={quickReply.categoryID}
                        components={{
                          Input: requiredComponent,
                        }}
                        placeholder="Select a category"
                        required={quickReply.categoryID === null}
                        isDisabled={
                          props.formName === "edit" && !props.updateAllowed
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        <strong>
                          View Type <span className="text-danger">*</span>
                        </strong>
                      </Label>
                      <Select
                        name="viewType"
                        className="react-select-container mb-4"
                        classNamePrefix="react-select"
                        options={quickRepliesPageType}
                        onChange={(e) =>
                          setQuickReply({ ...quickReply, viewType: e })
                        }
                        value={quickReply.viewType}
                        components={{
                          Input: requiredComponent,
                        }}
                        placeholder="Select a view type"
                        required={quickReply.viewType === null}
                        isDisabled={
                        true
                        }
                      />
                    </FormGroup>
                  </Col>
                  
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        <strong>Brand Campaign Id</strong>
                      </Label>
                      <Select
                        name="brandCampaign"
                        className="react-select-container mb-4"
                        classNamePrefix="react-select"
                        options={brandCampaignsOptions}
                        onChange={(e) =>
                          setQuickReply({ ...quickReply, brandCampaignId: e })
                        }
                        value={quickReply.brandCampaignId}
                        placeholder="Select a brand campaign"
                        isClearable={true}
                        isDisabled={
                          props.formName === "edit" && !props.updateAllowed
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        <strong>
                          Language Code <span className="text-danger">*</span>
                        </strong>
                      </Label>
                      <Select
                        name="language"
                        className="react-select-container mb-4"
                        classNamePrefix="react-select"
                        options={languagesOptions}
                        onChange={(e) =>
                          setQuickReply({ ...quickReply, languageCode: e })
                        }
                        value={quickReply.languageCode}
                        components={{
                          Input: requiredComponent,
                        }}
                        placeholder="Select a language"
                        required={quickReply.languageCode === null}
                        isDisabled={
                          props.formName === "edit" && !props.updateAllowed
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {quickReply.viewType?.value === "text" && (
              <QuickReplyTypeText
                quickReplyTypeText={quickReplyTypeText}
                setQuickReplyTypeText={setQuickReplyTypeText}
                formName={props.formName}
                updateAllowed={props.formName === "edit" && props.updateAllowed}
              />
            )}
            {quickReply.viewType?.value === "image" && (
              <QuickReplyTypeImage
                quickReplyTypeImage={quickReplyTypeImage}
                setQuickReplyTypeImage={setQuickReplyTypeImage}
                formName={props.formName || props?.location?.formName || (id !== null ? "duplicate" : null)}
                updateAllowed={props.formName === "edit" && props.updateAllowed}
              />
            )}
            {quickReply.viewType?.value === "card" && (
              <QuickReplyTypeCard
                quickReplyTypeCard={quickReplyTypeCard}
                setQuickReplyTypeCard={setQuickReplyTypeCard}
                formName={props.formName || props?.location?.formName || (id !== null ? "duplicate" : null)}
                updateAllowed={props.updateAllowed}
              />
            )}
          </Col>
        </Row>
        {(props.formName !== "edit" || props.updateAllowed) && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button color="primary" type="submit" className="mt-2">
              Submit
            </Button>
          </div>
        )}
      </Form>
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={action}
        onConfirmationTrue={() => {
          setConfirmationModalIsOpen(false);
          if (action === "Submit") handleSubmit();
        }}
        onHide={() => setConfirmationModalIsOpen(false)}
      />

      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={"Success"}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalState.failureModalDisplay}
        onHide={() =>
          setFailureModalState({
            failureModalDisplay: false,
            failureModalText: "",
          })
        }
        modalheading={"Error"}
        modaltext={failureModalState.failureModalText}
      />
    </Container>
  );
};

export default CreateQuickReplyPage;

import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Select from "react-select";
import { actionStatus } from "../../../../config/configStoryDashboard";

const SdContentPropertiesModal = (props) => {
  const [status, setStatus] = useState(actionStatus[0]);

  const handleSubmit = (e) => {
    e.preventDefault();

    props.selectedStories.forEach((id) => {
      if (status.value === "activate") {
        props.activate(id);
      } else if (status.value === "deactivate") {
        props.deactivate(id); 
      }else if (status.value === "publish") {
        props.publish(id);
      }
    });
    props.onHide(); 
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header className="bg-light" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update SD Content Properties ({props.selectedStories?.length}
          {props.selectedStories?.length === 1 ? " story" : " stories"} selected)
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formStatusSelect">
            <Form.Label>Status</Form.Label>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              placeholder={status.label}
              value={status}
              onChange={(option) => setStatus(option)}
              options={actionStatus}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleSubmit}
          disabled={!status}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SdContentPropertiesModal;

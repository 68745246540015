import { retrieveFromLocalStorage } from "../utilities/localStorageUtil";
import { permissions } from "../utilities/commonUtil";

const allowedPermissions = retrieveFromLocalStorage("userPermissions");

export let showCreateDeeplinkButton = () => {
    return allowedPermissions.includes(permissions.deeplinkCreate);
};

export let deeplinkCreatedSuccessfullyResponse =
    "Deeplink created successfully.";

export let clientOptions = [
    { label: "Mint", value: "mint" },
    { label: "Bobble", value: "bobble" },
    { label: "Common", value: "common" }
]

export let deeplinkOptions = {
    mint: {
        App: [
            {
                name: "Stickers Tab with category",
                id: "stickers_tab_with_category",
                url: "home/stickers",
                params: [
                    {
                        name: "CategoryId",
                        key: "categoryId",
                        dropdown: true,
                    },
                    {
                        name: "UTM Campaign",
                        key: "utmCampaign",
                    },
                ],
            },
            {
                name: "Stickers Tab",
                id: "stickers_tab",
                url: "home/stickers",
                params: [
                    {
                        name: "CategoryId",
                        key: "categoryId",
                        dropdown: true,
                    },
                    {
                        name: "ID",
                        key: "id",
                    },
                    {
                        name: "UTM Campaign",
                        key: "utmCampaign",
                    },
                ],
            },
            {
                name: "Share stories",
                url: "story/share",
                params: [
                    {
                        name: "ID",
                        key: "id",
                    },
                ],
            },
            {
                name: "Share stories on Whatsapp",
                url: "story/whatsappShare",
                params: [
                    {
                        name: "ID",
                        key: "id",
                    },
                ],
            },
            {
                name: "Download stories",
                url: "story/download",
                params: [
                    {
                        name: "ID",
                        key: "id",
                    },
                ],
            },
        ],
        Keyboard: [
            {
                name: "Sticker section with category",
                id: "sticker_section_with_category",
                url: "feature/stickers",
                params: [
                    {
                        name: "Category ID",
                        key: "categoryId",
                        dropdown: true,
                    },
                ],
            },
            {
                name: "Movie Gifs section with category",
                id: "movie_gifs_section_with_category",
                url: "feature/gifs",
                params: [
                    {
                        name: "Category ID",
                        key: "categoryId",
                        dropdown: true,
                    },
                ],
            },
            {
                name: "Content Suggestion Drawer",
                id: "content_suggestion_v2",
                url: "feature/contentSuggestions",
                params: [
                    {
                        name: "Search String",
                        key: "searchString",
                        required: false,
                    },
                    {
                        "name": "Category ID",
                        "key": "categoryId",
                        required: false,
                    },
                    {
                        "name": "Category Name",
                        "key": "categoryName",
                        required: false,
                    },
                    {
                        "name" : "Match ID",
                        "key" : "matchId",
                        required: false,
                    },
                    {
                        "name" : "Brand Campaign ID",
                        "key" : "brandCampaignId",
                        "type" : "checkbox",
                        "value" : "__BRAND_CAMPAIGN_ID__",
                        required: false,
                    },
                    {
                        "name" : "Match Event ID",
                        "key" : "matchEventId",
                        "type" : "checkbox",
                        "value" : "__MATCH_EVENT_ID__",
                        required: false,
                    }
                ],
            },
        ],
        Redirection: [
            {
                name: "Specific website in external browser",
                id: "specific_website_in_external_browser",
                url: "redirection/browser",
                params: [
                    {
                        name: "Destination URL",
                        key: "destinationUrl",
                    },
                    {
                        name: "Auto Send Settings",
                        key: "autoSendSettings",
                        required: false,
                    },
                    {
                        name: "Open Type (chromeTab/browser)",
                        key: "openType",
                    },
                    {
                        name: "Toolbar Settings (JSON)",
                        key: "toolbarSettings",
                        required: false,
                    },
                    {
                        name: "Redirection Viewport Height Percentage",
                        key: "redirectionViewPortHeight",
                    },
                    {
                        name: "Package Name",
                        key: "packageName",
                        required: false,
                    },
                    {
                        name: "Share message",
                        key: "shareMessage",
                        required: false,
                    },
                    {
                        name: "UTM Campaign",
                        key: "utmCampaign",
                        required: false,
                    },
                    {
                        name: "UTM Medium",
                        key: "utmMedium",
                        required: false,
                    },
                    {
                        name: "UTM Source",
                        key: "utmSource",
                        required: false,
                    },
                ],
            },
            {
                name: "Specific website in Web View",
                id: "specific_website_in_web_view",
                url: "redirection/webView",
                params: [
                    {
                        name: "Destination URL",
                        key: "destinationUrl",
                    },
                    {
                        name: "Package Name",
                        key: "packageName",
                        required: false,
                    },
                    {
                        name: "WebView Height Percentage",
                        key: "webViewHeightPercentage",
                    },
                    {
                        name: "Share message",
                        key: "shareMessage",
                        required: false,
                    },
                    {
                        name: "UTM Campaign",
                        key: "utmCampaign",
                        required: false,
                    },
                    {
                        name: "UTM Medium",
                        key: "utmMedium",
                        required: false,
                    },
                    {
                        name: "UTM Source",
                        key: "utmSource",
                        required: false,
                    },
                ],
            },
            {
                name: "Installed Application via Package Name",
                id: "installed_application_via_package_name",
                url: "redirection/installedApp",
                params: [
                    {
                        name: "Package Name",
                        key: "packageName",
                    },
                    {
                        name: "Fallback URL",
                        key: "fallbackUrl",
                    },
                ],
            },
        ],
        Sharing: [
            {
                name: "Share custom message",
                id: "share_custom_message",
                url: "shareMessage/custom",
                params: [
                    {
                        name: "Text",
                        key: "text",
                        required: true,
                    },
                    {
                        name: "PNG Urls (comma separated)",
                        key: "pngUrls",
                        required: false,
                    },
                    {
                        name: "PNG Content Ids (comma separated)",
                        key: "pngContentIds",
                        required: false,
                    },
                    {
                        name: "PNG Content Types (comma separated)",
                        key: "pngContentTypes",
                        required: false,
                    },
                    {
                        name: "GIF Urls (comma separated)",
                        key: "gifUrls",
                        required: false,
                    },
                    {
                        name: "GIF Content Ids (comma separated)",
                        key: "gifContentIds",
                        required: false,
                    },
                    {
                        name: "GIF Content Types (comma separated)",
                        key: "gifContentTypes",
                        required: false,
                    },
                    {
                        name: "Webp Urls (comma separated)",
                        key: "webpUrls",
                        required: false,
                    },
                    {
                        name: "Webp Content Ids (comma separated)",
                        key: "webpContentIds",
                        required: false,
                    },
                    {
                        name: "Webp Content Types (comma separated)",
                        key: "webpContentTypes",
                        required: false,
                    },
                    {
                        name: "Type",
                        key: "type",
                        required: true,
                    },
                    {
                        name: "Allow Direct Share (true/false)",
                        key: "allowDirectShare",
                        required: true,
                    },
                ],
            },
            {
                name: "Share custom message with multiple texts",
                id: "share_custom_message_multiple_texts",
                url: "shareMessage/custom",
                params: [
                    {
                        name: "Texts (Array of strings)",
                        key: "texts",
                        required: true,
                    },
                    {
                        name: "PNG Urls (comma separated)",
                        key: "pngUrls",
                        required: false,
                    },
                    {
                        name: "PNG Content Ids (comma separated)",
                        key: "pngContentIds",
                        required: false,
                    },
                    {
                        name: "PNG Content Types (comma separated)",
                        key: "pngContentTypes",
                        required: false,
                    },
                    {
                        name: "GIF Urls (comma separated)",
                        key: "gifUrls",
                        required: false,
                    },
                    {
                        name: "GIF Content Ids (comma separated)",
                        key: "gifContentIds",
                        required: false,
                    },
                    {
                        name: "GIF Content Types (comma separated)",
                        key: "gifContentTypes",
                        required: false,
                    },
                    {
                        name: "Webp Urls (comma separated)",
                        key: "webpUrls",
                        required: false,
                    },
                    {
                        name: "Webp Content Ids (comma separated)",
                        key: "webpContentIds",
                        required: false,
                    },
                    {
                        name: "Webp Content Types (comma separated)",
                        key: "webpContentTypes",
                        required: false,
                    },
                    {
                        name: "Type",
                        key: "type",
                        required: true,
                    },
                    {
                        name: "Allow Direct Share (true/false)",
                        key: "allowDirectShare",
                        required: true,
                    },
                ],
            },
            {
                name: "Share assets",
                id: "share_assets",
                url: "shareMessage/assets",
                params: [
                    {
                        name: "Text",
                        key: "text",
                        required: true,
                    },
                    {
                        name: "PNG Urls (comma separated)",
                        key: "pngUrls",
                        required: false,
                    },
                    {
                        name: "PNG Content Ids (comma separated)",
                        key: "pngContentIds",
                        required: false,
                    },
                    {
                        name: "PNG Content Types (comma separated)",
                        key: "pngContentTypes",
                        required: false,
                    },
                    {
                        name: "GIF Urls (comma separated)",
                        key: "gifUrls",
                        required: false,
                    },
                    {
                        name: "GIF Content Ids (comma separated)",
                        key: "gifContentIds",
                        required: false,
                    },
                    {
                        name: "GIF Content Types (comma separated)",
                        key: "gifContentTypes",
                        required: false,
                    },
                    {
                        name: "Webp Urls (comma separated)",
                        key: "webpUrls",
                        required: false,
                    },
                    {
                        name: "Webp Content Ids (comma separated)",
                        key: "webpContentIds",
                        required: false,
                    },
                    {
                        name: "Webp Content Types (comma separated)",
                        key: "webpContentTypes",
                        required: false,
                    },
                ],
            }
        ]
    },
    bobble: {
        App: [
            {
                id: "bubbly_tutorial",
                name: "Bubbly Tutorial",
                url: "bubbly",
                params: [
                    {
                        name: "Task Id",
                        key: "taskId",
                    },
                ],
            },
            {
                id: "subscription",
                name: "Premium Screen",
                url: "subscriptions/home",
                params: [
                    {
                        name: "Product ID",
                        key: "productId",
                        required: false,
                    },
                    {
                        name: "Showcase Asset ID",
                        key: "showcaseAssetId",
                        required: false,
                    },
                    {
                        name: "Bottom Sheet (true/false)",
                        key: "bottomSheet",
                        required: false,
                    },
                ],
            },
            {
                id: "subscription",
                name: "Premium Manage Screen",
                url: "subscriptions/manage",
                params: [],
            },
            {
                id: "subscription",
                name: "Premium Onboarding gift screen",
                url: "subscriptions/onboardingGift",
                params: [],
            },
            {
                id: "subscription",
                name: "Play store Subscriptions",
                url: "subscriptions/playstore",
                params: [
                    {
                        name: "Subs ID",
                        key: "subsId",
                        required: false,
                    },
                ],
            },
            {
                id: "subscription",
                name: "Premium Screen with selected plan",
                url: "subscriptions/home",
                params: [
                    {
                        name: "Product ID",
                        key: "productId",
                        required: false,
                    },
                    {
                        name: "Showcase Asset ID",
                        key: "showcaseAssetId",
                        required: false,
                    },
                    {
                        name: "Bottom Sheet (true/false)",
                        key: "bottomSheet",
                        required: false,
                    },
                    {
                        name: "Selected Tier ID",
                        key: "selectedTierId",
                        required: false,
                    },
                ],
            },
            {
                id: "subscription",
                name: "Premium Manage Screen with selected plan",
                url: "subscriptions/manage",
                params: [
                    {
                        name: "Selected Tier ID",
                        key: "selectedTierId",
                        required: false,
                    },
                ],
            },
            {
                id: "subscription",
                name: "Premium Screen with bottom sheet height",
                url: "subscriptions/home",
                params: [
                    {
                        name: "Product ID",
                        key: "productId",
                        required: false,
                    },
                    {
                        name: "Showcase Asset ID",
                        key: "showcaseAssetId",
                        required: false,
                    },
                    {
                        name: "Bottom Sheet (true/false)",
                        key: "bottomSheet",
                        required: false,
                    },
                    {
                        name: "Selected Tier ID",
                        key: "selectedTierId",
                        required: false,
                    },
                    {
                        name: "Bottom Sheet Height Percentage (30-99)",
                        key: "bottomSheetHeightPercentage",
                        required: false,
                    },
                ],
            },
            {
                id: "createPremiumContents",
                name: "Create Premium stickers (Camera)",
                url: "createPremiumContents/camera",
                params: [],
            },
            {
                id: "createPremiumContents",
                name: "Create Premium stickers (Gallery)",
                url: "createPremiumContents/gallery",
                params: [],
            },
            {
                id: "createPremiumContents",
                name: "Create Premium stickers (Sync)",
                url: "createPremiumContents/sync",
                params: [],
            },
            {
                id: "email",
                name: "Email",
                url: "email",
                params: [
                    {
                        name: "To",
                        key: "to",
                        required: false,
                    },
                    {
                        name: "Cc",
                        key: "cc",
                        required: false,
                    },
                    {
                        name: "Subject",
                        key: "subject",
                        required: false,
                    },
                    {
                        name: "Body",
                        key: "body",
                        required: false,
                    },
                ],
            },
            {
                id: "stickers",
                name: "Stickers",
                url: "home/stickers",
                params: [
                    {
                        name: "ID",
                        key: "id",
                    },
                    {
                        name: "UTM Campaign",
                        key: "utmCampaign",
                    },
                ],
            },
            {
                id: "feedback",
                name: "Feedback",
                url: "feedback",
                params: [],
            },
            {
                id: "rewards_system",
                name: "Rewards System",
                url: "userRewards",
                params: [
                    {
                        name: "Destination Url",
                        key: "destinationUrl",
                    },
                ],
            },
            {
                id: "no_action",
                name: "No Action",
                url: "noActionDeeplink/null",
                params: [],
            },
            {
                id: "open_prompt",
                name: "Open Prompt",
                url: "feature/openPrompt",
                params: [
                    {
                        name: "Prompt ID",
                        key: "promptId",
                    },
                ],
            },
        ],
        Settings: [
            {
                id: "edit_profile",
                name: "Edit Profile",
                url: "kbSettings/editProfile",
                params: [],
            },
            {
                id: "friends_setting",
                name: "Friends Setting",
                url: "kbSettings/friends",
                params: [],
            },
            {
                id: "customize_top_bar",
                name: "Customize Top bar",
                url: "kbSettings/customiseTopBar",
                params: [],
            },
            {
                id: "additional_settings",
                name: "Additional Settings",
                url: "kbSettings/additionalSettings",
                params: [],
            },
            {
                id: "privacy_settings",
                name: "Privacy Settings",
                url: "kbSettings/privacySettings",
                params: [],
            },
            {
                id: "toggle_a_setting",
                name: "Toggle a Setting",
                url: "kbSettings/toggle",
                params: [
                    {
                        name: "Setting Name",
                        key: "settingName",
                    },
                    {
                        name: "Setting State",
                        key: "settingState",
                    },
                ],
            },
            {
                id: "ask_permission",
                name: "Ask Permission",
                url: "askPermission",
                params: [
                    {
                        name: "Id",
                        key: "id",
                    },
                ],
            },
        ],
        Keyboard: [
            {
                id: "subscription",
                name: "Premium Screen",
                url: "subscriptions/home",
                params: [
                    {
                        name: "Product ID",
                        key: "productId",
                        required: false,
                    },
                    {
                        name: "Showcase Asset ID",
                        key: "showcaseAssetId",
                        required: false,
                    },
                    {
                        name: "Bottom Sheet (true/false)",
                        key: "bottomSheet",
                        required: false,
                    },
                ],
            },
            {
                id: "subscription",
                name: "Premium Manage Screen",
                url: "subscriptions/manage",
                params: [],
            },
            {
                id: "subscription",
                name: "Premium Onboarding gift screen",
                url: "subscriptions/onboardingGift",
                params: [],
            },
            {
                id: "subscription",
                name: "Play store Subscriptions",
                url: "subscriptions/playstore",
                params: [
                    {
                        name: "Subs ID",
                        key: "subsId",
                        required: false,
                    },
                ],
            },
            {
                id: "subscription",
                name: "Premium Screen with selected plan",
                url: "subscriptions/home",
                params: [
                    {
                        name: "Product ID",
                        key: "productId",
                        required: false,
                    },
                    {
                        name: "Showcase Asset ID",
                        key: "showcaseAssetId",
                        required: false,
                    },
                    {
                        name: "Bottom Sheet (true/false)",
                        key: "bottomSheet",
                        required: false,
                    },
                    {
                        name: "Selected Tier ID",
                        key: "selectedTierId",
                        required: false,
                    },
                ],
            },
            {
                id: "subscription",
                name: "Premium Manage Screen with selected plan",
                url: "subscriptions/manage",
                params: [
                    {
                        name: "Selected Tier ID",
                        key: "selectedTierId",
                        required: false,
                    },
                ],
            },
            {
                id: "subscription",
                name: "Premium Screen with bottom sheet height",
                url: "subscriptions/home",
                params: [
                    {
                        name: "Product ID",
                        key: "productId",
                        required: false,
                    },
                    {
                        name: "Showcase Asset ID",
                        key: "showcaseAssetId",
                        required: false,
                    },
                    {
                        name: "Bottom Sheet (true/false)",
                        key: "bottomSheet",
                        required: false,
                    },
                    {
                        name: "Selected Tier ID",
                        key: "selectedTierId",
                        required: false,
                    },
                    {
                        name: "Bottom Sheet Height Percentage (30-99)",
                        key: "bottomSheetHeightPercentage",
                        required: false,
                    },
                ],
            },
            {
                id: "subscription",
                name: "Subscriptions - Direct Purchase flow for google sdk",
                url: "subscriptions/pay",
                params: [
                    {
                        name: "SDK",
                        key: "sdk",
                    },
                    {
                        name: "Subs ID",
                        key: "subsId",
                    },
                    {
                        name: "Offer ID`s (comma separated)" ,
                        key: "offerIds",
                    },
                    {
                        name: "Success Redirection Link",
                        key: "successRedirectionDeeplink",
                    },
                ],
            },
            {
                id: "createPremiumContents",
                name: "Create Premium stickers (Camera)",
                url: "createPremiumContents/camera",
                params: [],
            },
            {
                id: "createPremiumContents",
                name: "Create Premium stickers (Gallery)",
                url: "createPremiumContents/gallery",
                params: [],
            },
            {
                id: "createPremiumContents",
                name: "Create Premium stickers (Sync)",
                url: "createPremiumContents/sync",
                params: [],
            },
            {
                id: "genAI",
                name: "Open AI Translation Bar",
                url: "feature/aiTranslationBar",
                params: [
                    {
                        name: "Text",
                        key: "text",
                        required: false,
                    },
                ],
            },
            {
                id: "genAI",
                name: "Show Gen AI Bar Placement",
                url: "feature/aiPoweredBar",
                params: [
                    {
                        name: "Placement ID",
                        key: "placementId",
                    },
                ],
            },
            {
                id: "genAI",
                name: "Show Gen AI Bar",
                url: "feature/aiPoweredBar",
                params: [
                    {
                        name: "Text",
                        key: "text",
                        required: false,
                    },
                    {
                        name: "Task ID",
                        key: "taskId",
                    },
                    {
                        name: "Retention  Duration",
                        key: "retentionDuration",
                        required: false,
                    },
                    {
                        name: "Response Display UI Type",
                        key: "responseDisplayUIType",
                        required: false,
                    },
                    {
                        name: "View",
                        key: "view",
                        required: false,
                    },
                ],
            },
            {
                id: "genAI",
                name: "Show AI Clipboard",
                url: "feature/clipboard",
                params: [
                    {
                        name: "Tab",
                        key: "tab",
                    },
                    {
                        name: "Text",
                        key: "text",
                        required: false,
                    },
                ],
            },
            {
                id: "email",
                name: "Email",
                url: "email",
                params: [
                    {
                        name: "To",
                        key: "to",
                        required: false,
                    },
                    {
                        name: "Cc",
                        key: "cc",
                        required: false,
                    },
                    {
                        name: "Subject",
                        key: "subject",
                        required: false,
                    },
                    {
                        name: "Body",
                        key: "body",
                        required: false,
                    },
                ],
            },
            {
                id: "customisation_panel",
                name: "Customisation Panel",
                url: "feature/customiseTopBar",
                params: [],
            },
            {
                id: "sticker_section_with_category",
                name: "Sticker section with category",
                url: "feature/stickers",
                params: [
                    {
                        name: "Category ID",
                        key: "categoryId",
                    },
                ],
            },
            {
                id: "movie_gifs_section_with_category",
                name: "Movie Gifs section with category",
                url: "feature/gifs",
                params: [
                    {
                        name: "Category ID",
                        key: "categoryId",
                    },
                ],
            },
            {
                id: "voice_typing_panel",
                name: "Voice Typing Panel",
                url: "feature/voiceTyping",
                params: [],
            },
            {
                id: "youmoji_panel",
                name: "YouMoji Panel",
                url: "feature/youMoji",
                params: [],
            },
            {
                id: "clipboard_panel",
                name: "Clipboard Panel",
                url: "feature/clipboard",
                params: [],
            },
            {
                id: "shortcuts_panel",
                name: "Shortcuts Panel",
                url: "feature/shortcuts",
                params: [],
            },
            {
                name: "Content Suggestion Drawer",
                id: "content_suggestion_drawer",
                url: "feature/contentSuggestions",
                params: [
                    {
                        name: "Search String",
                        key: "searchString",
                        required: false,
                    },
                    {
                        "name": "Category ID",
                        "key": "categoryId",
                        required: false,
                    },
                    {
                        "name": "Category Name",
                        "key": "categoryName",
                        required: false,
                    },
                    {
                        "name" : "Match ID",
                        "key" : "matchId",
                        required: false,
                    },
                    {
                        "name" : "Brand Campaign ID",
                        "key" : "brandCampaignId",
                        "type" : "checkbox",
                        "value" : "__BRAND_CAMPAIGN_ID__",
                        required: false,
                    },
                    {
                        "name" : "Match Event ID",
                        "key" : "matchEventId",
                        "type" : "checkbox",
                        "value" : "__MATCH_EVENT_ID__",
                        required: false,
                    }
                ],
            },
        ],
        Redirection: [
            {
                id: "specific_website_in_external_browser",
                name: "Specific website in external browser",
                url: "redirection/browser",
                params: [
                    {
                        name: "Destination URL",
                        key: "destinationUrl",
                    },
                    {
                        name: "Auto Send Settings",
                        key: "autoSendSettings",
                        required: false,
                    },
                    {
                        name: "Open Type (chromeTab/browser)",
                        key: "openType",
                    },
                    {
                        name: "Toolbar Settings (JSON)",
                        key: "toolbarSettings",
                        required: false,
                    },
                ],
            },
            {
                id: "specific_website_in_web_view",
                name: "Specific website in Web View",
                url: "redirection/webView",
                params: [
                    {
                        name: "Destination URL",
                        key: "destinationUrl",
                    },
                    {
                        name: "WebView Height Percentage",
                        key: "webViewHeightPercentage",
                    },
                ],
            },
            {
                id: "super_apps_web_view",
                name: "Super Apps Web View",
                url: "redirection/superappWebView",
                params: [
                    {
                        name: "Destination URL",
                        key: "destinationUrl",
                    },
                    {
                        name: "WebView Height Percentage",
                        key: "webViewHeightPercentage",
                    },
                ],
            },
        ],
        Sharing: [
            {
                id: "invite_friend_default",
                name: "Invite Friend Default",
                url: "shareMessage/invite",
                params: [],
            },
            {
                id: "share_custom_message",
                name: "Share custom message",
                url: "shareMessage/custom",
                params: [
                    {
                        name: "Text",
                        key: "text",
                    },
                    {
                        name: "Image Urls (comma separated)",
                        key: "imageUrls",
                    },
                    {
                        name: "Video Urls (comma separated)",
                        key: "videoUrls",
                        required: false,
                    },
                    {
                        name: "Type",
                        key: "type",
                    },
                    {
                        name: "Allow Direct Share (true/false)",
                        key: "allowDirectShare",
                    },
                    {
                        name: "Assets Preload Priority",
                        key: "assetsPreloadPriority"
                    }
                ],
            },
            {
                id: "share_custom_message_with_multiple_texts",
                name: "Share custom message with multiple texts",
                url: "shareMessage/custom",
                params: [
                    {
                        name: "Texts (Array of strings)",
                        key: "texts",
                    },
                    {
                        name: "Image Urls (comma separated)",
                        key: "imageUrls",
                    },
                    {
                        name: "Video Urls (comma separated)",
                        key: "videoUrls",
                        required: false,
                    },
                    {
                        name: "Type",
                        key: "type",
                    },
                    {
                        name: "Allow Direct Share (true/false)",
                        key: "allowDirectShare",
                    },
                    {
                        "name": "Assets Preload Priority",
                        "key": "assetsPreloadPriority"
                    }
                ],
            },
            {
                id: "share_assets",
                name: "Share assets",
                url: "shareMessage/assets",
                params: [
                    {
                        name: "Text",
                        key: "text",
                    },
                    {
                        name: "Assets Urls (comma separated)",
                        key: "assetUrls",
                    },
                    {
                        "name": "Assets Preload Priority",
                        "key": "assetsPreloadPriority"
                    }
                ],
            },
        ],
    },
    common: {
        App: [
            {
                id: "stories_tab",
                name: "Stories Tab",
                url: "home/story",
                params: [],
            },
            {
                id: "memes_tab",
                name: "Memes Tab",
                url: "home/memes",
                params: [],
            },
            {
                id: "stickers_tab",
                name: "Sticker Tab",
                url: "home/stickers",
                params: [],
            },
            {
                id: "my_stories",
                name: "My Stories",
                url: "story/myStory",
                params: [],
            },
            {
                id: "search_stories_with_tag",
                name: "Search Stories with tag",
                url: "story/search",
                params: [
                    {
                        name: "Search String",
                        key: "searchString",
                    },
                ],
            },
            {
                name: "Open Story with Story Id in Sotd",
                id: "open_story_with_story_id_in_sotd",
                url: "story/sotd",
                params: [
                    {
                        name: "ID",
                        key: "id",
                    },
                ],
            },
            {
                name: "Open Story with Story Id in vertical UI",
                id: "open_story_with_story_id_in_vertical_ui",
                url: "story/vertical",
                params: [
                    {
                        name: "ID",
                        key: "id",
                    },
                ],
            },
            {
                name: "Themes Tab",
                id: "themes_tab",
                url: "home/themes",
                params: [],
            },
            {
                name: "Languages Tab",
                id: "languages_tab",
                url: "home/languages",
                params: [],
            },
            {
                id: "heads_tab",
                name: "Heads Tab",
                url: "home/heads",
                params: [],
            },
            {
                id: "create_head_via_camera",
                name: "Create head via camera",
                url: "createHead/camera",
                params: [],
            },
            {
                id: "create_head_via_gallery",
                name: "Create head via gallery",
                url: "createHead/gallery",
                params: [],
            },
            {
                id: "app_update",
                name: "App Update",
                url: "appUpdate",
                params: [
                    {
                        name: "Force",
                        key: "force",
                    },
                ],
            },
            {
                id: "login",
                name: "Login",
                url: "login",
                params: [],
            }
        ],
        Keyboard: [
            {
                name: "Fonts section",
                id: "fonts_section",
                url: "feature/fonts",
                params: [],
            },
            {
                name: "Quick replies section",
                id: "quick_replies_section",
                url: "feature/quickReply",
                params: [
                    {
                        name: "Category ID",
                        key: "categoryId",
                        dropdown: true,
                    },
                ],
            },
            {
                name: "Bigmoji Panel",
                id: "bigmoji_panel",
                url: "feature/bigMoji",
                params: [
                    {
                        name: "Search String",
                        key: "searchString",
                    },
                ],
            },
            {
                name: "Sticker section with search",
                id: "sticker_section_with_search",
                url: "feature/stickers",
                params: [
                    {
                        name: "Search String",
                        key: "searchString",
                    },
                ],
            },
            {
                name: "Movie Gifs section with search",
                id: "movie_gifs_section_with_search",
                url: "feature/gifs",
                params: [
                    {
                        name: "Search String",
                        key: "searchString",
                    },
                ],
            },
        ],
        Redirection: [
            {
                id: "specific_application",
                name: "Specific Application",
                url: "redirection/application",
                params: [
                    {
                        name: "Package Names (Comma Seperated)",
                        key: "packageNames",
                    },
                    {
                        name: "Deep Link Path",
                        key: "destinationUrl",
                    },
                    {
                        name: "Fallback Deep Link Path",
                        key: "fallbackDeepLinkPath",
                    },
                    {
                        name: "Auto Send Settings",
                        key: "autoSendSettings",
                        required: false,
                    },
                ],
            },
        ],
    },
};

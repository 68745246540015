import { retrieveFromLocalStorage } from "../utilities/localStorageUtil";
import { permissions } from "../utilities/commonUtil";

const allowedPermissions = retrieveFromLocalStorage("userPermissions");

export let isPlacementProvidersCreatePermission = () => {
  return allowedPermissions.includes(permissions.placementProvidersCreate);
};

export let isPlacementProvidersEditPermission = () => {
  return allowedPermissions.includes(permissions.placementProvidersEdit);
};

export let isPlacementProvidersDeletePermission = () => {
  return allowedPermissions.includes(permissions.placementProvidersDelete);
};

export const allActions = {
  delete: "Delete"
};

export const placementProvidersResponses = {
  updatedSuccessfully: "Placement Provider updated successfully",
  addedSuccessfully: "Placement Provider added successfully",
};

export const providerOptions = [
  {
    value: "internal",
    label: "Internal",
  },
  {
    value: "affinity_cpc",
    label: "Affinity Cpc",
  },
  {
    value: "affinity_cpi",
    label: "Affinity Cpi",
  },
  {
    value: "affinity_affiliate",
    label: "Affinity Affiliate",
  },
  {
    value: "mob_avenue_cpc",
    label: "Mob Avenue Cpc",
  },
  {
    value: "mob_avenue_cpi",
    label: "Mob Avenue Cpi",
  },
  {
    value: "value_leaf_cpc",
    label: "Value Leaf Cpc",
  },
  {
    value: "value_leaf_cpi",
    label: "Value Leaf Cpi",
  },
  {
    value: "zupee",
    label: "Zupee",
  },
  {
    value: "avow_cpi",
    label: "Avow Cpi",
  },
];

export const isValidJSON = (str = "") => {
  try {
    JSON.parse(str)
  } catch (err) {
    return false
  }
  return true
}
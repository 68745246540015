// ABTestDetails.js
export const abTestUpdatedStatus = "keyboardLanguageABTestUpdated";
export const abTestUpdatedText = "AB Test updated successfully.";
export const abTestCompletedStatus = "keyboardLanguageABTestCompleted";
export const abTestCompletedText = "AB Test completed successfully.";
export const abTestInvalidText =
  "The AB Test is invalid due to new resource version upload";
export const noResourceVariantsFoundText = "No Resource Variants available";

// ABTests.js
export const publishActionStatus = "publish";
export const abTestPublishedText = "AB Test published successfully";
export const noABTestsAvailableText = "No AB Tests Avaiable.";
export const invalidABTestSearchTagText =
  "Enter a valid search tag to view AB Tests.";

// CreateABTest.js
export const abTestCreatedStatus = "keyboardLanguageABTestCreated";
export const abTestCreatedText = "AB Test created successfully.";
export const invalidKeyboardLanguageAlertElementId = "invalidKeyboardLanguage";
export const invalidKeyboardLanguageAlertText =
  "Keyboard Language is a required field.";

export const successModalHeading = "Success";
export const failureModalHeading = "Error";

// CreateKeyboardLayout.js
export const keyboardLayoutCreatedStatus = "keyboardLayoutCreated";
export const keyboardLayoutCreatedText = "Keyboard layout create successfully.";
export const invalidMinAppVersionAlertElementId = "invalidMinAppVersionAlert";
export const invalidMinBobbleSDKVersionAlertElementId =
  "invalidMinBobbleSDKVersionAlert";
export const invalidMinAppVersionAlertText = "Enter a valid Min App Version"; //The min app version must be numeric &gt;= 1. and &lt;= 1.0E+17
export const invalidMinBobbleSDKVersionAlertText =
  "Enter a valid Min Bobble SDK Version";

export const invalidAdditionalDetailsAlertText = "Must be a valid json";
export const invalidAdditionalDetailsElementId =
  "invalidAdditionalDetailsAlert";

// Device Options
export const deviceOptions = [
  { value: "android", label: "Android" },
  { value: "iOS", label: "iOS" },
];

// CreateLanguage.js
export const invalidCodeAlertElementId = "invalidCodeAlert";
export const invalidCodeAlertText = "Enter a valid code";
export const invalidTerritoryAlertElementId = "invalidTerritoryAlert";
export const invalidTerritoryAlertText = "Enter a valid territory";
export const invalidVocabularyAlertElementId = "invalidVocabularyAlert";
export const invalidVocabularyAlertText =
  "Vocabulary must be a valid json array";
export const languageCreatedStatus = "languageCreated";
export const languageCreatedText = "Language create successfully.";

// KeyboardLanguageLayoutsSuggested.js
export const keyboardLanguageLayoutAlreadyExistsText =
  "The Keyboard Language Layout is already present in the list.";

export const noSuggestedKeyboardLanguageLayoutsText =
  "No Suggested Keyboard Language Layouts";

// KeyboardLanguage.js
export const actions = [
  { status: "publish", text: "Successfully Published" },
  { status: "deactivate", text: "Successfully Deactivated" },
  { status: "activate", text: "Successfully Activated" },
  { status: "updated", text: "Successfully Updated" },
];
export const missingCountryStatus = "missingCountry";
export const rulesetCreatedSuccessfullyText = "Ruleset created Successfully.";
export const missingCountryRegionCityStatus = "missingCountryRegionCity";
// KeyboardLanguageSuggested.js
export const keyboardLanguageAlreadyExistsText =
  "The Keyboard Language is already present in the list.";

// KeyboardLayouts.js
export const keyboardLayoutTableColumns = [
  {
    dataField: "id",
    text: "#",
    headerAttrs: { width: 60 },
    searchable: false,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "identifier",
    text: "Identifier",
    searchable: true,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "minAppVersion",
    text: "Min App Version",
    searchable: false,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "minBobbleSDKVersion",
    text: "Min Bobble SDK Version",
    searchable: false,
    style: {
      cursor: "pointer",
    },
  },

  {
    dataField: "deviceType",
    text: "Device Type",
    searchable: false,
    style: {
      cursor: "pointer",
    },
  },
];

export const noSuggestedKeyboardLanguagesText =
  "No Suggested Keyboard Languages";

export const keyboardLayoutUpdatedStatus = "keyboardLayoutUpdated";
export const keyboardLayoutUpdatedText =
  "Keyboard Layout updated successfully.";

// LanguageDetail.js
export const keyboardLanguageResourceUpdatedStatus =
  "keyboardLanguageResourceUpdated";
export const keyboardLanguageResourceUpdatedText =
  "Keyboard Language Resource Updated Successfully.";
export const noShortcutsAvailableText = "---- No shortcuts available ----";
export const noDefaultSuggestionsAvailableText =
  "---- No default suggestions available ----";
export const noLayoutsAvailableText = "No layouts available";

// Languages.js
export const languagesTableColumns = [
  {
    dataField: "id",
    text: "#",
    headerAttrs: { width: 60 },
    searchable: false,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "name",
    text: "Name",
    searchable: true,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "territory",
    text: "Territory",
    searchable: false,
    style: {
      cursor: "pointer",
    },
  },
];
export const languageUpdatedStatus = "languageUpdated";
export const languageUpdatedText = "Language updated successfully.";

// ManageKeyboardLanguage
export const invalidLocaleAlertElementId = "invalidLocaleAlert";
export const invalidLocaleAlertText = "Enter a valid locale";
export const invalidLanguageIdAlertElementId = "invalidLanguageIdAlert";
export const invalidMacronicKeyboardLanguageIdAlertElementId = "invalidMacronicKeyboardLanguageIdAlert"
export const invalidLanguageCodeAlertElementId = "invalidLanguageCodeAlert";
export const invalidLanguageIdAlertText = "Select a language ID";
export const keyboardLanguageCreatedStatus = "keyboardLanguageCreated";
export const keyboardLanguageCreatedText =
  "Keyboard Language created successfully.";
export const keyboardLanguageUpdatedStatus = "keyboardLanguageUpdated";
export const keyboardLanguageUpdatedText =
  "Keyboard Language updated successfully.";

// ManageLayouts.js
export const layoutTypes = [
  { value: "inscript", label: "inscript" },
  { value: "transliteration", label: "transliteration" },
  { value: "macaronic", label: "macaronic" },
  { value: "indic", label: "indic" },
  { value: "varnmala", label: "varnmala" },
];
export const invalidShortcutsAlertElementId = "invalidShortcutsAlert";
export const invalidShortcutsAlertText = "Shortcuts must be a valid json array";
export const invalidSuggestionsAlertElementId = "invalidSuggestionsAlert";
export const invalidSuggestionsAlertText =
  "Default Suggestions must be a valid json array";
export const keyboardLanguageLayoutUpdatedText =
  "Successfully updated keyboard language layout";
export const keyboardLanguageLayoutUploadedText =
  "Successfully uploaded keyboard language layout";

// ManageResourceVariants.js
export const resourceVariantsAddedText = "Successfully Added Resource Variants";
export const resourceVariantsUpdatedText =
  "Successfully Updated Resource Variants";
export const totalPercentageMismatchText =
  "Combined percentage of all the variants should be 100";

export const options = [
  { label: "Allow", value: "allow" },
  { label: "Deny", value: "deny" },
];

export const releaseTypes = [
  { value: "production", label: "Production" },
  { value: "ab_test", label: "AB Test" },
];

export const defaultLocationRule = {
  name: "GLOBAL",
  countryId: 240,
  regionId: 1,
  cityId: 1,
  effect: "allow",
};

// Upload Resource.js
export const mintResourceTypesDropdown = [
  {
    value: "dictionary",
    label: "dictionary",
  },
  {
    value: "keyword_emoji_mapping",
    label: "keyword_emoji_mapping",
  },
  {
    value: "transliteration_mapping",
    label: "transliteration_mapping",
  },
  {
    value: "transliteration_character_mapping",
    label: "transliteration_character_mapping",
  },
  {
    value: "transliteration_regex_mapping",
    label: "transliteration_regex_mapping",
  },
  {
    value: "swipe_dictionary",
    label: "swipe_dictionary",
  },
  {
    value: "swipe_dictionary_v2",
    label: "swipe_dictionary_v2",
  },
  {
    value: "latin_keyword_emoji_mapping",
    label: "latin_keyword_emoji_mapping",
  },
  {
    value: "transliteration_dictionary",
    label: "transliteration_dictionary",
  },
  {
    value: "transliteration_mapping_dictionary",
    label: "transliteration_mapping_dictionary",
  },
  {
    value: "transliteration_model",
    label: "transliteration_model",
  },
  {
    value: "appnext_playstore_category_dictionary",
    label: "appnext_playstore_category_dictionary",
  },
  {
    value: "appnext_browser_category_dictionary",
    label: "appnext_browser_category_dictionary",
  },
  {
    value: "appnext_playstore_category_mapping",
    label: "appnext_playstore_category_mapping",
  },
  {
    value: "appnext_browser_category_mapping",
    label: "appnext_browser_category_mapping",
  },
  {
    value: "macronian_word_prediction_model",
    label: "macronian_word_prediction_model",
  },
  {
    value: "inscript_word_prediction_model",
    label: "inscript_word_prediction_model",
  },
];

export const transliterationModels = [
  {
    id: 1,
    displayName: "wfst_transliteration",
    name: "wfst_transliteration",
  },
  {
    id: 2,
    displayName: "character_mapping_transliteration",
    name: "character_mapping_transliteration",
  },
  {
    id: 3,
    displayName: "mapping_transliteration",
    name: "mapping_transliteration",
  },
  {
    id: 4,
    displayName: "regex_transliteration",
    name: "regex_transliteration",
  },
];

export const resourceTypesDropdown = [
  { value: "pure_dictionary", label: "pure_dictionary" },
  { value: "keyword_emoji_mapping", label: "keyword_emoji_mapping" },
  { value: "transliteration_mapping", label: "transliteration_mapping" },
  {
    value: "transliteration_character_mapping",
    label: "transliteration_character_mapping",
  },
  {
    value: "transliteration_regex_mapping",
    label: "transliteration_regex_mapping",
  },
  {
    value: "latin_keyword_emoji_mapping",
    label: "latin_keyword_emoji_mapping",
  },
  { value: "transliteration_file_v4", label: "transliteration_file_v4" },
  {
    value: "transliteration_mapping_dictionary",
    label: "transliteration_mapping_dictionary",
  },
  { value: "transliteration_model", label: "transliteration_model" },
  {
    value: "emoji_sugestion_model_resources",
    label: "emoji_sugestion_model_resources",
  },
  {
    value: "appnext_playstore_category_dictionary",
    label: "appnext_playstore_category_dictionary",
  },
  {
    value: "appnext_browser_category_dictionary",
    label: "appnext_browser_category_dictionary",
  },
  {
    value: "appnext_playstore_category_mapping",
    label: "appnext_playstore_category_mapping",
  },
  {
    value: "appnext_browser_category_mapping",
    label: "appnext_browser_category_mapping",
  },
  {
    value: "intent_detection_model_resources",
    label: "intent_detection_model_resources",
  },
  {
    value: "word_prediction_model_resources",
    label: "word_prediction_model_resources",
  },
  { value: "macaronic_dictionary", label: "macaronic_dictionary" },
  {
    value: "macaronic_emoji_suggestion_model_resources",
    label: "macaronic_emoji_suggestion_model_resources",
  },
  {
    value: "macaronic_intent_detection_model_resources",
    label: "macaronic_intent_detection_model_resources",
  },
  {
    value: "macaronic_word_prediction_model_resources",
    label: "macaronic_word_prediction_model_resources",
  },
  {
    value: "content_intent_detection_dictionary",
    label: "content_intent_detection_dictionary",
  },
  {
    value: "content_trigger_dictionary",
    label: "content_trigger_dictionary",
  },
  {
    value: "content_prediction_dictionary",
    label: "content_prediction_dictionary",
  },
  {
    value: "macaronic_content_prediction_dictionary",
    label: "macaronic_content_prediction_dictionary",
  },
  {
    value: "macaronic_content_intent_detection_dictionary",
    label: "macaronic_content_intent_detection_dictionary"
  },
  {
    value: "intent_prompt_resources",
    label: "intent_prompt_resources",
  },
  {
    value: "macaronic_intent_prompt_resources",
    label: "macaronic_intent_prompt_resources",
  },
  {
    value: "smart_compose_model_resources",
    label: "smart_compose_model_resources",
  },
  {
    value: "macaronic_smart_compose_model_resources",
    label: "macaronic_smart_compose_model_resources",
  },
  {
    value: "macaronic_content_trigger_dictionary",
    label: "macaronic_content_trigger_dictionary",
  },
  {
    value: "smart_suggestions_browser_category_dictionary_v2",
    label: "smart_suggestions_browser_category_dictionary_v2",
  },
  {
    value: "smart_suggestions_playstore_category_dictionary_v2",
    label: "smart_suggestions_playstore_category_dictionary_v2",
  },
  {
    value: "ai_suggestion_resources",
    label: "ai_suggestion_resources",
  },
  {
    value: "macaronic_ai_suggestion_resources",
    label: "macaronic_ai_suggestion_resources",
  },
  {
    value: "smart_suggestions_affiliate_ads_resources",
    label: "smart_suggestions_affiliate_ads_resources",
  },
  {
    value: "keyword_emoji_mapping_dictionary",
    label: "keyword_emoji_mapping_dictionary",
  },
  {
    value: "macaronic_keyword_emoji_mapping_dictionary",
    label: "macaronic_keyword_emoji_mapping_dictionary",
  },
  {
    value: "mini_word_prediction_model_resources",
    label: "mini_word_prediction_model_resources"
	},
  {
    value: "mini_macaronic_word_prediction_model_resources",
    label: "mini_macaronic_word_prediction_model_resources"
  }
];


export const locationZonesDropdown = [
  { value: "", label: "GLOBAL" },
  { value: "IN_NORTH", label: "North India" },
  { value: "IN_SOUTH", label: "South India" },
  { value: "IN_EAST", label: "East India" },
  { value: "IN_WEST", label: "West India" }
];

export const appVersionOptions = [
  { value: "9.8", label: "9.8 and above" },
  { value: "9.7", label: "Below 9.8" }
];

export const resourceCompressionMaxAppVersion = "9.8";
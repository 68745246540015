import React, { useState, useEffect } from "react";
import Select from "react-select";
import { getKeyboardThemeCategoriesForForm } from "../../../utilities/apiUtils/themes";
import {
  validateHexCode,
  validateInteger,
  validateFloat,
  validateARGB,
} from "../../../utilities/commonUtil";
import {
  Col,
  Row,
  Button,
  Container,
  Card,
  CardBody,
  Input,
  Label,
  Form,
  FormGroup,
  CustomInput,
} from "reactstrap";
import ReactAudioPlayer from "react-audio-player";
import ToolTip from "../../../utilities/ToolTip";
import { getBrandCampaigns } from "../../../utilities/apiUtils/stickers";
import { MdOutlineCancel } from "react-icons/md";

const KeyboardThemeForm = (props) => {
  const [formTextState, setFormTextState] = useState({
    name: props.theme.name,
    type: props.theme.type,
    categoryID: props.theme.categoryID,
    isPremium: props.theme.isPremium,
    brandCampaignID: props.theme.brandCampaignID,

    previewImage: "",
    backgroundImage: "",
    spaceKeyAnimation: "",
    spaceKeySound: "",
    defaultKeySound: "",
    deleteKeySound: "",

    backspaceKeyBackgroundImage: "",
    functionalKeyBackgroundImage: "",
    enterKeyBackgroundImage: "",
    shiftKeyBackgroundImage: "",
    spaceKeyBackgroundImage: "",
    spaceKeyLogoImage: "",
    keyBackgroundImage: "",
    functionalEmojiPanelKeyBackgroundImage: "",
    functionalLanguageSwitcherKeyBackgroundImage: "",

    actionColor: { value: props.theme.actionColor.value, valid: true },
    contentBarColor: {
      value: props.theme.contentBarColor.value,
      valid: true,
    },
    panelBackgroundColor: {
      value: props.theme.panelBackgroundColor.value,
      valid: true,
    },
    suggestionDrawerCategoryButtonColor: {
      value: props.theme.suggestionDrawerCategoryButtonColor.value,
      valid: true,
    },
    suggestionDrawerCategoryButtonTextColor: {
      value: props.theme.suggestionDrawerCategoryButtonTextColor.value,
      valid: true,
    },
    suggestionDrawerCategorySelectedButtonColor: {
      value: props.theme.suggestionDrawerCategorySelectedButtonColor.value,
      valid: true,
    },
    suggestionDrawerCategorySelectedButtonTextColor: {
      value: props.theme.suggestionDrawerCategorySelectedButtonTextColor.value,
      valid: true,
    },
    functionalKeyBackgroundColor: {
      value: props.theme.functionalKeyBackgroundColor.value,
      valid: true,
    },
    functionalTextColor: {
      value: props.theme.functionalTextColor.value,
      valid: true,
    },
    keyBackgroundColor: {
      value: props.theme.keyBackgroundColor.value,
      valid: true,
    },
    keyPopUpPreviewBackgroundColor: {
      value: props.theme.keyPopUpPreviewBackgroundColor.value,
      valid: true,
    },
    keyPopupExpandedBackgroundColor: {
      value: props.theme.keyPopupExpandedBackgroundColor.value,
      valid: true,
    },
    keyPopupSelectionColor: {
      value: props.theme.keyPopupSelectionColor.value,
      valid: true,
    },
    keyTextColor: {
      value: props.theme.keyTextColor.value,
      valid: true,
    },
    keyboardBackgroundColor: {
      value: props.theme.keyboardBackgroundColor.value,
      valid: true,
    },
    keyboardOverlayOpacity: {
      value: props.theme.keyboardOverlayOpacity.value,
      valid: true,
    },
    selectedIconColor: {
      value: props.theme.selectedIconColor.value,
      valid: true,
    },
    suggestionsColorAutoCorrect: {
      value: props.theme.suggestionsColorAutoCorrect.value,
      valid: true,
    },
    suggestionsColorSuggested: {
      value: props.theme.suggestionsColorSuggested.value,
      valid: true,
    },
    suggestionsColorTypedWord: {
      value: props.theme.suggestionsColorTypedWord.value,
      valid: true,
    },
    suggestionsColorValidTypedWord: {
      value: props.theme.suggestionsColorValidTypedWord.value,
      valid: true,
    },
    swipeGestureTrailColor: {
      value: props.theme.swipeGestureTrailColor.value,
      valid: true,
    },
    topBarBackgroundColor: {
      value: props.theme.topBarBackgroundColor.value,
      valid: true,
    },
    topKeyTextColor: {
      value: props.theme.topKeyTextColor.value,
      valid: true,
    },
    keyBorderStrokeColor: {
      value: props.theme.keyBorderStrokeColor.value,
      valid: true,
    },
    keyBorderStrokeWidth: {
      value: props.theme.keyBorderStrokeWidth.value,
      valid: true,
    },
    keyShadowRadius: {
      value: props.theme.keyShadowRadius.value,
      valid: true,
    },
    priority: {
      value: props.theme.priority.value,
      valid: true,
    },
    min_android_app_version: {
      value: props.theme.min_android_app_version.value,
      valid: true,
    },
    min_ios_app_version: {
      value: props.theme.min_ios_app_version.value,
      valid: true,
    },
    isLightTheme: props.theme.isLightTheme,
    enableKeyboardKeySound: props.theme.enableKeyboardKeySound,
    enableKeyboardKeyBorder: props.theme.enableKeyboardKeyBorder,
    enableKeyboardTopKeys: props.theme.enableKeyboardTopKeys,
    enableKeyboardKeyPopup: props.theme.enableKeyboardKeyPopup,

    previewImageSrc: props.theme.previewImageSrc,
    backgroundImageSrc: props.theme.backgroundImageSrc,
    spaceKeyAnimationSrc: props.theme.spaceKeyAnimationSrc,
    spaceKeySoundSrc: props.theme.spaceKeySoundSrc,
    defaultKeySoundSrc: props.theme.defaultKeySoundSrc,
    deleteKeySoundSrc: props.theme.deleteKeySoundSrc,

    backspaceKeyBackgroundImageSrc: props.theme.backspaceKeyBackgroundImageSrc,
    functionalKeyBackgroundImageSrc: props.theme.functionalKeyBackgroundImageSrc,
    enterKeyBackgroundImageSrc: props.theme.enterKeyBackgroundImageSrc,
    shiftKeyBackgroundImageSrc: props.theme.shiftKeyBackgroundImageSrc,
    spaceKeyBackgroundImageSrc: props.theme.spaceKeyBackgroundImageSrc,
    spaceKeyLogoImageSrc: props.theme.spaceKeyLogoImageSrc,
    keyBackgroundImageSrc: props.theme.keyBackgroundImageSrc,
    functionalEmojiPanelKeyBackgroundImageSrc: props.theme.functionalEmojiPanelKeyBackgroundImageSrc,
    functionalLanguageSwitcherKeyBackgroundImageSrc: props.theme.functionalLanguageSwitcherKeyBackgroundImageSrc,

    featureSubscriptions: props.theme.featureSubscriptions,
    selectionPromptDetails: props.theme.selectionPromptDetails,
    usagePromptDetails: props.theme.usagePromptDetails,
    impressionTrackers: props.theme.impressionTrackers,
    startDate: props.theme.startDate,
    endDate: props.theme.endDate,
    endDateEpoch: "",
    startDateEpoch: "",
  });

  const [displayError, setDisplayError] = useState(false);
  const [defaultCategory, setDefaultCategory] = useState({});
  const [categories, setCategories] = useState([]);

  const [campaigns, setCampaigns] = useState([]);
  const [defaultCampaign, setDefaultcampaign] = useState(null);

  useEffect(() => {
    const _getBrandCampaigns = async () => {
      let response = await getBrandCampaigns();
      setCampaigns(response);
    };
    _getBrandCampaigns();
  }, []);

  useEffect(() => {
    setFormTextState({
      ...formTextState,
      startDateEpoch: new Date(props.theme.startDate).getTime() / 1000,
      endDateEpoch: new Date(props.theme.endDate).getTime() / 1000,
    });
  }, []);

  useEffect(() => {
    setDefaultcampaign(defaultValueForCampaign());
  }, [campaigns]);

  const defaultValueForCampaign = () => {
    var brandID = props.theme.brandCampaignID;
    var defCampaign = campaigns.find((item) => item.value === brandID);
    return defCampaign;
  };

  const handleCampaignChange = (campaign) => {
    setFormTextState((prev) => ({
      ...prev,
      brandCampaignID: campaign ? campaign.value : 0,
    }));
    setDefaultcampaign(campaign);
    setDisplayError(false);
  };

  useEffect(() => {
    const getCategories = async () => {
      let response = await getKeyboardThemeCategoriesForForm();
      let updatedCategories = response.filter(
        (category) => category.label !== "All"
      );
      setCategories(updatedCategories);
    };

    getCategories();
  }, []);

  useEffect(() => {
    setDefaultCategory(defaultValueForCategory());
  }, [categories]);

  const defaultValueForCategory = () => {
    let categoryObj = {};
    if (categories) {
      categories.forEach((category) => {
        if (category.value === props.theme.categoryID.value) {
          categoryObj = category;
        }
      });
    }
    return categoryObj;
  };

  const handleHexCodeChange = (event) => {
    const { name, value } = event.target;

    // Validate ARGB, RGBA, or Hex color code
    const valid = value === '' || validateARGB(value) || validateHexCode(value);

    setFormTextState((prev) => ({
      ...prev,
      [name]: {
        value: value,
        valid: valid,
      },
    }));
    setDisplayError(false);
  };


  const handleOpacityChange = (event) => {
    const { name, value } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: {
        value: value,
        valid: value >= 0 && value <= 1,
      },
    }));
    setDisplayError(false);
  };

  const handlePriorityChange = (event) => {
    const { name, value } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: {
        value: value,
        valid: validateInteger(value) && parseInt(value) !== 0,
      },
    }));
    setDisplayError(false);
  };

  const handleIntChange = (event) => {
    const { name, value } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: {
        value: value,
        valid: validateInteger(value) && parseInt(value) !== 0,
      },
    }));
    setDisplayError(false);
  };

  const handleFloatChange = (event) => {
    const { name, value } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: {
        value: value,
        valid: validateFloat(value),
      },
    }));
    setDisplayError(false);
  };

  const handleMinAppVersionChange = (event) => {
    const { name, value } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: {
        value: value,
        valid: validateFloat(value) && parseFloat(value) !== 0,
      },
    }));
    setDisplayError(false);
  };

  const handleCategoryChange = (category) => {
    setFormTextState((prev) => ({
      ...prev,
      categoryID: { value: category.value, valid: true },
    }));
    setDefaultCategory(category);
    setDisplayError(false);
  };

  const handleIsPremiumToggle = (event) => {
    // Persist the event to avoid issues with synthetic event reuse
    event.persist();
    setFormTextState((prev) => ({
      ...prev,
      isPremium: event.target.checked,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: value,
    }));
    setDisplayError(false);
  };

  const handleCheckBoxChange = (event) => {
    const { id, checked } = event.target;

    setFormTextState((prev) => ({
      ...prev,
      [id]: checked,
    }));

    setDisplayError(false);
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: files[0],
    }));

    event.persist();
    // For showing Image preview
    if (files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        if (name === "previewImage") {
          setFormTextState((prev) => ({
            ...prev,
            previewImageSrc: reader.result,
          }));
          document.getElementById("previewImg").innerHTML = files[0].name;
        }
        if (name === "backgroundImage") {
          setFormTextState((prev) => ({
            ...prev,
            backgroundImageSrc: reader.result,
          }));
          document.getElementById("backgroundImg").innerHTML = files[0].name;
        }
        if (name === "spaceKeyAnimation") {
          setFormTextState((prev) => ({
            ...prev,
            spaceKeyAnimationSrc: reader.result,
          }));
          document.getElementById("spaceKeyAnimationGif").innerHTML =
            files[0].name;
        }
        if (name === "spaceKeySound") {
          setFormTextState((prev) => ({
            ...prev,
            spaceKeySoundSrc: reader.result,
          }));
          document.getElementById("spaceKeySoundMp3").innerHTML = files[0].name;
        }
        if (name === "defaultKeySound") {
          setFormTextState((prev) => ({
            ...prev,
            defaultKeySoundSrc: reader.result,
          }));
          document.getElementById("defaultKeySoundMp3").innerHTML =
            files[0].name;
        }
        if (name === "deleteKeySound") {
          setFormTextState((prev) => ({
            ...prev,
            deleteKeySoundSrc: reader.result,
          }));
          document.getElementById("deleteKeySoundMp3").innerHTML =
            files[0].name;
        }
        if (name === "backspaceKeyBackgroundImage") {
          setFormTextState((prev) => ({
            ...prev,
            backspaceKeyBackgroundImageSrc: reader.result,
          }));
          document.getElementById("backspaceKeyBackgroundImg").innerHTML = files[0].name;
        }
        if (name === "functionalKeyBackgroundImage") {
          setFormTextState((prev) => ({
            ...prev,
            functionalKeyBackgroundImageSrc: reader.result,
          }));
          document.getElementById("functionalKeyBackgroundImg").innerHTML = files[0].name;
        }

        if (name === "enterKeyBackgroundImage") {
          setFormTextState((prev) => ({
            ...prev,
            enterKeyBackgroundImageSrc: reader.result,
          }));
          document.getElementById("enterKeyBackgroundImg").innerHTML = files[0].name;
        }
        if (name === "shiftKeyBackgroundImage") {
          setFormTextState((prev) => ({
            ...prev,
            shiftKeyBackgroundImageSrc: reader.result,
          }));
          document.getElementById("shiftKeyBackgroundImg").innerHTML = files[0].name;
        }


        if (name === "spaceKeyBackgroundImage") {
          setFormTextState((prev) => ({
            ...prev,
            spaceKeyBackgroundImageSrc: reader.result,
          }));
          document.getElementById("spaceKeyBackgroundImg").innerHTML = files[0].name;
        }
        if (name === "spaceKeyLogoImage") {
          setFormTextState((prev) => ({
            ...prev,
            spaceKeyLogoImageSrc: reader.result,
          }));
          document.getElementById("spaceKeyLogoImg").innerHTML = files[0].name;
        }

        if (name === "keyBackgroundImage") {
          setFormTextState((prev) => ({
            ...prev,
            keyBackgroundImageSrc: reader.result,
          }));
          document.getElementById("keyBackgroundImg").innerHTML = files[0].name;
        }
        if (name === "functionalEmojiPanelKeyBackgroundImage") {
          setFormTextState((prev) => ({
            ...prev,
            functionalEmojiPanelKeyBackgroundImageSrc: reader.result,
          }));
          document.getElementById("functionalEmojiPanelKeyBackgroundImg").innerHTML = files[0].name;
        }
        if (name === "functionalLanguageSwitcherKeyBackgroundImage") {
          setFormTextState((prev) => ({
            ...prev,
            functionalLanguageSwitcherKeyBackgroundImageSrc: reader.result,
          }));
          document.getElementById("functionalLanguageSwitcherKeyBackgroundImg").innerHTML = files[0].name;
        }

      };
    }
  };

  const handleRemoveImage = (name, imageType, labelId, inputId) => {
    setFormTextState((prevState) => ({
      ...prevState,
      [imageType]: "",
      [name]: "",
    }));

    const fileInput = document.getElementById(inputId);
    if (fileInput) {
      fileInput.value = "";
    }
    const labelInput = document.getElementById(labelId);
    if (labelInput) {
      labelInput.innerHTML = "";
      labelInput.value = "";
    }

  };

  const submit = (event) => {
    event.preventDefault();
    let inputsWithError = document.getElementsByClassName("is-invalid");
    if (inputsWithError.length === 0 && formTextState.categoryID.value !== "")
      props.submit(formTextState);
    else {
      setDisplayError(true);
      if (formTextState.categoryID.value === "") {
        setFormTextState((prev) => ({
          ...prev,
          categoryID: {
            value: formTextState.categoryID.value,
            valid: false,
          },
        }));
      }
    }
  };
  let handleDateChange = (e) => {
    let targetedValue = new Date(e.target.value);
    if (e.target.name === "startDate") {
      setFormTextState({
        ...formTextState,
        startDate: e.target.value,
        startDateEpoch: targetedValue.getTime() / 1000,
      });
    } else {
      setFormTextState({
        ...formTextState,
        endDate: e.target.value,
        endDateEpoch: targetedValue.getTime() / 1000,
      });
    }
  };
  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">{props.theme.formName}</h1>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Form
                onSubmit={(event) => {
                  submit(event);
                }}
              >
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        <strong>Theme Name*</strong>
                        <p style={{ fontSize: 12, marginBottom: 0 }}>
                          Name of the theme
                        </p>
                      </Label>
                      <Input
                        type="text"
                        name="name"
                        defaultValue={props.theme.name}
                        onChange={(event) => handleChange(event)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col className="themeCategoryDropdown" style={{ zIndex: 10 }}>
                    <Label>
                      <strong>Theme Category</strong>{" "}
                    </Label>
                    {formTextState.categoryID.valid ? null : (
                      <small className="text-danger">(Select category)</small>
                    )}
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="categoryID"
                      value={
                        Object.keys(defaultCategory).length === 0
                          ? null
                          : defaultCategory
                      }
                      placeholder="Select category"
                      onChange={(option) => handleCategoryChange(option)}
                      options={categories}
                      required
                      isDisabled={!props.updateAllowed}
                    />
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col className="themeCategoryDropdown">
                    <Label>
                      <strong>Brand Campaign</strong>
                      <p style={{ fontSize: 12 }}>
                        Brand Campaign Pop text style is linked with
                      </p>
                    </Label>
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="campaign"
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      value={defaultCampaign != null ? defaultCampaign : null}
                      placeholder="Select campaign"
                      isClearable={true}
                      options={campaigns}
                      onChange={(option) => handleCampaignChange(option)}
                      isDisabled={!props.updateAllowed}
                    />
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />

                <Row className="my-2">
                  <Col>
                    <FormGroup check inline>
                      <Label>
                        <strong>Theme Type</strong>
                      </Label>
                      <CustomInput
                        type="radio"
                        id="typeColor"
                        name="type"
                        label="color"
                        value="color"
                        className="mb-2 ml-5"
                        onChange={(event) => handleChange(event)}
                        defaultChecked={props.theme.type === "color"}
                        required
                        disabled={!props.updateAllowed}
                      />
                      <CustomInput
                        type="radio"
                        id="typeImage"
                        name="type"
                        label="image"
                        value="image"
                        className="mb-2 ml-5"
                        onChange={(event) => handleChange(event)}
                        defaultChecked={props.theme.type === "image"}
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        <strong>Priority</strong>
                        <small className="text-info">
                          (Enter integer value greater than 0)
                        </small>
                      </Label>
                      <Input
                        type="number"
                        name="priority"
                        defaultValue={props.theme.priority.value}
                        className={
                          formTextState.priority.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        value={formTextState.priority.value}
                        onChange={(event) => handlePriorityChange(event)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />

                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        <strong>Min Android App Version</strong>
                        <small className="text-info">
                          (Enter float value greater than 0.0)
                        </small>
                      </Label>
                      <Input
                        type="text"
                        name="min_android_app_version"
                        defaultValue={props.theme.min_android_app_version.value}
                        className={
                          formTextState.min_android_app_version.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleMinAppVersionChange(event)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        <strong>Min iOS App Version</strong>
                        <small className="text-info">
                          (Enter float value greater than 0.0)
                        </small>
                      </Label>
                      <Input
                        type="text"
                        name="min_ios_app_version"
                        defaultValue={props.theme.min_ios_app_version.value}
                        className={
                          formTextState.min_ios_app_version.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleMinAppVersionChange(event)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />

                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        Preview Image{" "}
                        <small className="text-info">
                          (Choose a 324x234 jpeg file)
                        </small>
                      </Label>
                      <Container className="custom-file">
                        <Input
                          type="file"
                          className="custom-file-input"
                          accept="image/jpeg"
                          name="previewImage"
                          onChange={(event) => handleFileChange(event)}
                          required={
                            formTextState.type === "color" &&
                            props.theme.formName === "Create Keyboard Theme"
                          }
                          disabled={!props.updateAllowed}
                        />
                        <Label className="custom-file-label" id="previewImg">
                          Choose an image
                        </Label>
                      </Container>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  {formTextState.previewImageSrc !== "" &&
                    formTextState.previewImageSrc !== null ? (
                    <Col className="pb-4">
                      <img
                        src={formTextState.previewImageSrc}
                        height="120"
                        alt="previewImage"
                      />
                    </Col>
                  ) : (
                    <Col></Col>
                  )}
                </Row>
                <hr style={{ margin: 8 }} />

                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        Background Image{" "}
                        <small className="text-info">
                          (Choose a 1080x780 jpeg file)
                        </small>
                      </Label>
                      <Container className="custom-file">
                        <Input
                          type="file"
                          className="custom-file-input"
                          accept="image/jpeg"
                          name="backgroundImage"
                          onChange={(event) => handleFileChange(event)}
                          required={
                            formTextState.type === "image" &&
                            props.theme.formName === "Create Keyboard Theme"
                          }
                          disabled={!props.updateAllowed}
                        />
                        <Label className="custom-file-label" id="backgroundImg">
                          Choose an image
                        </Label>
                      </Container>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  {formTextState.backgroundImageSrc !== "" &&
                    formTextState.backgroundImageSrc !== null ? (
                    <Col className="pb-4">
                      <img
                        src={formTextState.backgroundImageSrc}
                        height="120"
                        alt="backgroundImage"
                      />
                    </Col>
                  ) : (
                    <Col></Col>
                  )}
                </Row>
                <hr style={{ margin: 8 }} />

                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        Space Key Animation{" "}
                        <small className="text-info">(Choose a gif file)</small>
                      </Label>
                      <Container className="custom-file">
                        <Input
                          type="file"
                          className="custom-file-input"
                          accept="image/gif"
                          name="spaceKeyAnimation"
                          onChange={(event) => handleFileChange(event)}
                          disabled={!props.updateAllowed}
                        />
                        <Label
                          className="custom-file-label"
                          id="spaceKeyAnimationGif"
                        >
                          Choose a gif file
                        </Label>
                      </Container>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  {formTextState.spaceKeyAnimationSrc !== "" &&
                    formTextState.spaceKeyAnimationSrc !== null ? (
                    <Col className="pb-4">
                      <img
                        src={formTextState.spaceKeyAnimationSrc}
                        height="120"
                        alt="spaceKeyAnimationGif"
                      />
                    </Col>
                  ) : (
                    <Col></Col>
                  )}
                </Row>

                <hr style={{ margin: 8 }} />

                <Row>
                  <Col lg={6} md={12}>
                    <FormGroup>
                      <Label>
                        Backspace Key Background Image{" "}
                        <small className="text-info">(Choose a image file)</small>
                      </Label>
                      <Container className="custom-file">
                        <Input
                          type="file"
                          className="custom-file-input"
                          accept="image/png, image/jpeg"
                          name="backspaceKeyBackgroundImage"
                          id="backspaceKeyBackgroundImageInp"
                          onChange={(event) => handleFileChange(event)}
                          disabled={!props.updateAllowed}
                        />
                        <Label
                          className="custom-file-label"
                          id="backspaceKeyBackgroundImg"
                        >
                          Choose a Image file
                        </Label>
                      </Container>
                    </FormGroup>

                    {formTextState.backspaceKeyBackgroundImageSrc !== "" &&
                      formTextState.backspaceKeyBackgroundImageSrc !== null ? (
                      <Col className="pb-4">
                        <img
                          src={formTextState.backspaceKeyBackgroundImageSrc}
                          height="120"
                          alt="backspaceKeyBackgroundImg"
                        />
                        <MdOutlineCancel
                          size={24}
                          color="red"
                          className="ml-2"
                          style={{
                            cursor: "pointer",
                            marginLeft: "20px"
                          }}
                          onClick={() => handleRemoveImage(
                            'backspaceKeyBackgroundImage',
                            'backspaceKeyBackgroundImageSrc',
                            'backspaceKeyBackgroundImg',
                            'backspaceKeyBackgroundImageInp'
                          )}
                        />
                      </Col>
                    ) : (
                      <Col></Col>
                    )}
                  </Col>
                  <Col lg={6} md={12}>
                    <FormGroup>
                      <Label>
                        Functional Key Background Image{" "}
                        <small className="text-info">(Choose a image file)</small>
                      </Label>
                      <Container className="custom-file">
                        <Input
                          type="file"
                          className="custom-file-input"
                          accept="image/png, image/jpeg"
                          name="functionalKeyBackgroundImage"
                          id="functionalKeyBackgroundImageInp"
                          onChange={(event) => handleFileChange(event)}
                          disabled={!props.updateAllowed}
                        />
                        <Label
                          className="custom-file-label"
                          id="functionalKeyBackgroundImg"
                        >
                          Choose a Image file
                        </Label>
                      </Container>
                    </FormGroup>

                    {formTextState.functionalKeyBackgroundImageSrc !== "" &&
                      formTextState.functionalKeyBackgroundImageSrc !== null ? (
                      <Col className="pb-4">
                        <img
                          src={formTextState.functionalKeyBackgroundImageSrc}
                          height="120"
                          alt="functionalKeyBackgroundImg"
                        />
                        <MdOutlineCancel
                          size={24}
                          color="red"
                          className="ml-2"
                          style={{
                            cursor: "pointer",
                            marginLeft: "20px"
                          }}
                          onClick={() => handleRemoveImage(
                            'functionalKeyBackgroundImage',
                            'functionalKeyBackgroundImageSrc',
                            'functionalKeyBackgroundImg',
                            'functionalKeyBackgroundImageInp'
                          )}
                        />
                      </Col>
                    ) : (
                      <Col></Col>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col lg={6} md={12}>
                    <FormGroup>
                      <Label>
                        Enter Key Background Image{" "}
                        <small className="text-info">(Choose a image file)</small>
                      </Label>
                      <Container className="custom-file">
                        <Input
                          type="file"
                          className="custom-file-input"
                          accept="image/png, image/jpeg"
                          name="enterKeyBackgroundImage"
                          id="enterKeyBackgroundImageInp"
                          onChange={(event) => handleFileChange(event)}
                          disabled={!props.updateAllowed}
                        />
                        <Label
                          className="custom-file-label"
                          id="enterKeyBackgroundImg"
                        >
                          Choose a Image file
                        </Label>
                      </Container>
                    </FormGroup>

                    {formTextState.enterKeyBackgroundImageSrc !== "" &&
                      formTextState.enterKeyBackgroundImageSrc !== null ? (
                      <Col className="pb-4">
                        <img
                          src={formTextState.enterKeyBackgroundImageSrc}
                          height="120"
                          alt="enterKeyBackgroundImg"
                        />
                        <MdOutlineCancel
                          size={24}
                          color="red"
                          className="ml-2"
                          style={{
                            cursor: "pointer",
                            marginLeft: "20px"
                          }}
                          onClick={() => handleRemoveImage(
                            'enterKeyBackgroundImage',
                            'enterKeyBackgroundImageSrc',
                            'enterKeyBackgroundImg',
                            'enterKeyBackgroundImageInp'
                          )}
                        />
                      </Col>
                    ) : (
                      <Col></Col>
                    )}
                  </Col>
                  <Col lg={6} md={12}>
                    <FormGroup>
                      <Label>
                        Shift Key Background Image{" "}
                        <small className="text-info">(Choose a image file)</small>
                      </Label>
                      <Container className="custom-file">
                        <Input
                          type="file"
                          className="custom-file-input"
                          accept="image/png, image/jpeg"
                          name="shiftKeyBackgroundImage"
                          id="shiftKeyBackgroundImageInp"
                          onChange={(event) => handleFileChange(event)}
                          disabled={!props.updateAllowed}
                        />
                        <Label
                          className="custom-file-label"
                          id="shiftKeyBackgroundImg"
                        >
                          Choose a Image file
                        </Label>
                      </Container>
                    </FormGroup>

                    {formTextState.shiftKeyBackgroundImageSrc !== "" &&
                      formTextState.shiftKeyBackgroundImageSrc !== null ? (
                      <Col className="pb-4">
                        <img
                          src={formTextState.shiftKeyBackgroundImageSrc}
                          height="120"
                          alt="shiftKeyBackgroundImg"
                        />
                        <MdOutlineCancel
                          size={24}
                          color="red"
                          className="ml-2"
                          style={{
                            cursor: "pointer",
                            marginLeft: "20px"
                          }}
                          onClick={() => handleRemoveImage(
                            'shiftKeyBackgroundImage',
                            'shiftKeyBackgroundImageSrc',
                            'shiftKeyBackgroundImg',
                            'shiftKeyBackgroundImageInp'
                          )}
                        />
                      </Col>
                    ) : (
                      <Col></Col>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col lg={6} md={12}>
                    <FormGroup>
                      <Label>
                        Space Key Background Image{" "}
                        <small className="text-info">(Choose a image file)</small>
                      </Label>
                      <Container className="custom-file">
                        <Input
                          type="file"
                          className="custom-file-input"
                          accept="image/png, image/jpeg"
                          name="spaceKeyBackgroundImage"
                          id="spaceKeyBackgroundImageInp"
                          onChange={(event) => handleFileChange(event)}
                          disabled={!props.updateAllowed}
                        />
                        <Label
                          className="custom-file-label"
                          id="spaceKeyBackgroundImg"
                        >
                          Choose a Image file
                        </Label>
                      </Container>
                    </FormGroup>

                    {formTextState.spaceKeyBackgroundImageSrc !== "" &&
                      formTextState.spaceKeyBackgroundImageSrc !== null ? (
                      <Col className="pb-4">
                        <img
                          src={formTextState.spaceKeyBackgroundImageSrc}
                          height="120"
                          alt="spaceKeyBackgroundImage"
                        />
                        <MdOutlineCancel
                          size={24}
                          color="red"
                          className="ml-2"
                          style={{
                            cursor: "pointer",
                            marginLeft: "20px"
                          }}
                          onClick={() => handleRemoveImage(
                            'spaceKeyBackgroundImage',
                            'spaceKeyBackgroundImageSrc',
                            'spaceKeyBackgroundImg',
                            'spaceKeyBackgroundImageInp'
                          )}
                        />
                      </Col>
                    ) : (
                      <Col></Col>
                    )}
                  </Col>
                  <Col lg={6} md={12}>
                    <FormGroup>
                      <Label>
                        Space Key Logo Image{" "}
                        <small className="text-info">(Choose a image file)</small>
                      </Label>
                      <Container className="custom-file">
                        <Input
                          type="file"
                          className="custom-file-input"
                          accept="image/png, image/jpeg"
                          name="spaceKeyLogoImage"
                          id="spaceKeyLogoImageInp"
                          onChange={(event) => handleFileChange(event)}
                          disabled={!props.updateAllowed}
                        />
                        <Label
                          className="custom-file-label"
                          id="spaceKeyLogoImg"
                        >
                          Choose a Image file
                        </Label>
                      </Container>
                    </FormGroup>

                    {formTextState.spaceKeyLogoImageSrc !== "" &&
                      formTextState.spaceKeyLogoImageSrc !== null ? (
                      <Col className="pb-4">
                        <img
                          src={formTextState.spaceKeyLogoImageSrc}
                          height="120"
                          alt="spaceKeyLogoImg"
                        />
                        <MdOutlineCancel
                          size={24}
                          color="red"
                          className="ml-2"
                          style={{
                            cursor: "pointer",
                            marginLeft: "20px"
                          }}
                          onClick={() => handleRemoveImage(
                            'spaceKeyLogoImage',
                            'spaceKeyLogoImageSrc',
                            'spaceKeyLogoImg',
                            'spaceKeyLogoImageInp'
                          )}
                        />
                      </Col>
                    ) : (
                      <Col></Col>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col lg={6} md={12}>
                    <FormGroup>
                      <Label>
                        Functional Emoji Panel Key Background Image{" "}
                        <small className="text-info">(Choose a image file)</small>
                      </Label>
                      <Container className="custom-file">
                        <Input
                          type="file"
                          className="custom-file-input"
                          accept="image/png, image/jpeg"
                          name="functionalEmojiPanelKeyBackgroundImage"
                          id="functionalEmojiPanelKeyBackgroundImageInp"
                          onChange={(event) => handleFileChange(event)}
                          disabled={!props.updateAllowed}
                        />
                        <Label
                          className="custom-file-label"
                          id="functionalEmojiPanelKeyBackgroundImg"
                        >
                          Choose a Image file
                        </Label>
                      </Container>
                    </FormGroup>

                    {formTextState.functionalEmojiPanelKeyBackgroundImageSrc !== "" &&
                      formTextState.functionalEmojiPanelKeyBackgroundImageSrc !== null ? (
                      <Col className="pb-4">
                        <img
                          src={formTextState.functionalEmojiPanelKeyBackgroundImageSrc}
                          height="120"
                          alt="functionalEmojiPanelKeyBackgroundImg"
                        />
                        <MdOutlineCancel
                          size={24}
                          color="red"
                          className="ml-2"
                          style={{
                            cursor: "pointer",
                            marginLeft: "20px"
                          }}
                          onClick={() => handleRemoveImage(
                            'functionalEmojiPanelKeyBackgroundImage',
                            'functionalEmojiPanelKeyBackgroundImageSrc',
                            'functionalEmojiPanelKeyBackgroundImg',
                            'functionalEmojiPanelKeyBackgroundImageInp'
                          )}
                        />
                      </Col>
                    ) : (
                      <Col></Col>
                    )}
                  </Col>
                  <Col lg={6} md={12}>
                    <FormGroup>
                      <Label>
                        Functional Language Switcher Key Background Image{" "}
                        <small className="text-info">(Choose a image file)</small>
                      </Label>
                      <Container className="custom-file">
                        <Input
                          type="file"
                          className="custom-file-input"
                          accept="image/png, image/jpeg"
                          name="functionalLanguageSwitcherKeyBackgroundImage"
                          id="functionalLanguageSwitcherKeyBackgroundImageInp"
                          onChange={(event) => handleFileChange(event)}
                          disabled={!props.updateAllowed}
                        />
                        <Label
                          className="custom-file-label"
                          id="functionalLanguageSwitcherKeyBackgroundImg"
                        >
                          Choose a Image file
                        </Label>
                      </Container>
                    </FormGroup>

                    {formTextState.functionalLanguageSwitcherKeyBackgroundImageSrc !== "" &&
                      formTextState.functionalLanguageSwitcherKeyBackgroundImageSrc !== null ? (
                      <Col className="pb-4">
                        <img
                          src={formTextState.functionalLanguageSwitcherKeyBackgroundImageSrc}
                          height="120"
                          alt="functionalLanguageSwitcherKeyBackgroundImage"
                        />
                        <MdOutlineCancel
                          size={24}
                          color="red"
                          className="ml-2"
                          style={{
                            cursor: "pointer",
                            marginLeft: "20px"
                          }}
                          onClick={() => handleRemoveImage(
                            'functionalLanguageSwitcherKeyBackgroundImage',
                            'functionalLanguageSwitcherKeyBackgroundImageSrc',
                            'functionalLanguageSwitcherKeyBackgroundImg',
                            'functionalLanguageSwitcherKeyBackgroundImageInp'
                          )}
                        />
                      </Col>
                    ) : (
                      <Col></Col>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        Key Background Image{" "}
                        <small className="text-info">(Choose a image file)</small>
                      </Label>
                      <Container className="custom-file">
                        <Input
                          type="file"
                          className="custom-file-input"
                          accept="image/png, image/jpeg"
                          name="keyBackgroundImage"
                          id="keyBackgroundImageInp"
                          onChange={(event) => handleFileChange(event)}
                          disabled={!props.updateAllowed}
                        />
                        <Label
                          className="custom-file-label"
                          id="keyBackgroundImg"
                        >
                          Choose a Image file
                        </Label>
                      </Container>
                    </FormGroup>

                    {formTextState.keyBackgroundImageSrc !== "" &&
                      formTextState.keyBackgroundImageSrc !== null ? (
                      <Col className="pb-4">
                        <img
                          src={formTextState.keyBackgroundImageSrc}
                          height="120"
                          alt="keyBackgroundImage"
                        />
                        <MdOutlineCancel
                          size={24}
                          color="red"
                          className="ml-2"
                          style={{
                            cursor: "pointer",
                            marginLeft: "20px"
                          }}
                          onClick={() => handleRemoveImage(
                            'keyBackgroundImage',
                            'keyBackgroundImageSrc',
                            'keyBackgroundImg',
                            'keyBackgroundImageInp'
                          )}
                        />
                      </Col>
                    ) : (
                      <Col></Col>
                    )}
                  </Col>
                </Row>

                <hr style={{ margin: 8 }} />

                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        Space Key Sound{" "}
                        <small className="text-info">
                          (Choose an audio file)
                        </small>
                      </Label>
                      <Container className="custom-file">
                        <Input
                          type="file"
                          className="custom-file-input"
                          accept="audio/mp3"
                          name="spaceKeySound"
                          onChange={(event) => handleFileChange(event)}
                          disabled={!props.updateAllowed}
                        />
                        <Label
                          className="custom-file-label"
                          id="spaceKeySoundMp3"
                        >
                          Choose an audio file
                        </Label>
                      </Container>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  {formTextState.spaceKeySoundSrc !== "" &&
                    formTextState.spaceKeySoundSrc !== null ? (
                    <Col className="pb-4">
                      <ReactAudioPlayer
                        src={formTextState.spaceKeySoundSrc}
                        controls
                        height="120"
                        alt="spaceKeySoundMp3"
                      />
                    </Col>
                  ) : (
                    <Col></Col>
                  )}
                </Row>

                <hr style={{ margin: 8 }} />

                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        Default Key Sound{" "}
                        <small className="text-info">
                          (Choose an audio file)
                        </small>
                      </Label>
                      <Container className="custom-file">
                        <Input
                          type="file"
                          className="custom-file-input"
                          accept="audio/mp3"
                          name="defaultKeySound"
                          onChange={(event) => handleFileChange(event)}
                          disabled={!props.updateAllowed}
                        />
                        <Label
                          className="custom-file-label"
                          id="defaultKeySoundMp3"
                        >
                          Choose an audio file
                        </Label>
                      </Container>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  {formTextState.defaultKeySoundSrc !== "" &&
                    formTextState.defaultKeySoundSrc !== null ? (
                    <Col className="pb-4">
                      <ReactAudioPlayer
                        src={formTextState.defaultKeySoundSrc}
                        controls
                        height="120"
                        alt="defaultKeySoundMp3"
                      />
                    </Col>
                  ) : (
                    <Col></Col>
                  )}
                </Row>

                <hr style={{ margin: 8 }} />
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        Delete Key Sound{" "}
                        <small className="text-info">
                          (Choose an audio file)
                        </small>
                      </Label>
                      <Container className="custom-file">
                        <Input
                          type="file"
                          className="custom-file-input"
                          accept="audio/mp3"
                          name="deleteKeySound"
                          onChange={(event) => handleFileChange(event)}
                          disabled={!props.updateAllowed}
                        />
                        <Label
                          className="custom-file-label"
                          id="deleteKeySoundMp3"
                        >
                          Choose an audio file
                        </Label>
                      </Container>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  {formTextState.deleteKeySoundSrc !== "" &&
                    formTextState.deleteKeySoundSrc !== null ? (
                    <Col className="pb-4">
                      <ReactAudioPlayer
                        src={formTextState.deleteKeySoundSrc}
                        controls
                        height="120"
                        alt="deleteKeySoundMp3"
                      />
                    </Col>
                  ) : (
                    <Col></Col>
                  )}
                </Row>

                <hr style={{ margin: 8 }} />
                <Row className="mb-4 mt-2">
                  <Col>
                    <strong style={{ fontSize: 18 }}>Properties</strong>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>
                        Content Bar Color
                        {formTextState.contentBarColor.valid ? (
                          <div
                            className="displayColor ml-2"
                            style={{
                              backgroundColor:
                                formTextState.contentBarColor.value,
                            }}
                          ></div>
                        ) : (
                          <small className="text-danger ml-2">
                            {"Invalid Hex Code"}
                          </small>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="contentBarColor"
                        defaultValue={props.theme.contentBarColor.value}
                        className={
                          formTextState.contentBarColor.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleHexCodeChange(event)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>
                        Keyboard Background Color
                        {formTextState.keyboardBackgroundColor.valid ? (
                          <div
                            className="displayColor ml-2"
                            style={{
                              backgroundColor:
                                formTextState.keyboardBackgroundColor.value,
                            }}
                          ></div>
                        ) : (
                          <small className="text-danger ml-2">
                            {"Invalid Hex Code"}
                          </small>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="keyboardBackgroundColor"
                        defaultValue={props.theme.keyboardBackgroundColor.value}
                        className={
                          formTextState.keyboardBackgroundColor.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleHexCodeChange(event)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>
                        Key PopUp Preview Background Color
                        {formTextState.keyPopUpPreviewBackgroundColor.valid ? (
                          <div
                            className="displayColor ml-2"
                            style={{
                              backgroundColor:
                                formTextState.keyPopUpPreviewBackgroundColor
                                  .value,
                            }}
                          ></div>
                        ) : (
                          <small className="text-danger ml-2">
                            {"Invalid Hex Code"}
                          </small>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="keyPopUpPreviewBackgroundColor"
                        defaultValue={
                          props.theme.keyPopUpPreviewBackgroundColor.value
                        }
                        className={
                          formTextState.keyPopUpPreviewBackgroundColor.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleHexCodeChange(event)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>
                        Functional Text Color
                        {formTextState.functionalTextColor.valid ? (
                          <div
                            className="displayColor ml-2"
                            style={{
                              backgroundColor:
                                formTextState.functionalTextColor.value,
                            }}
                          ></div>
                        ) : (
                          <small className="text-danger ml-2">
                            {"Invalid Hex Code"}
                          </small>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="functionalTextColor"
                        defaultValue={props.theme.functionalTextColor.value}
                        className={
                          formTextState.functionalTextColor.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleHexCodeChange(event)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>
                        Key Background Color
                        {formTextState.keyBackgroundColor.valid ? (
                          <>
                            <div
                              className="displayColor ml-2"
                              style={{
                                backgroundColor:
                                  formTextState.keyBackgroundColor.value,
                              }}
                            ></div>
                          </>
                        ) : (
                          <small className="text-danger ml-2">
                            Invalid Color Code.
                          </small>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="keyBackgroundColor"
                        defaultValue={props.theme.keyBackgroundColor.value}
                        className={
                          formTextState.keyBackgroundColor.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleHexCodeChange(event)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>
                        Key Text Color
                        {formTextState.keyTextColor.valid ? (
                          <div
                            className="displayColor ml-2"
                            style={{
                              backgroundColor: formTextState.keyTextColor.value,
                            }}
                          ></div>
                        ) : (
                          <small className="text-danger ml-2">
                            {"Invalid Hex Code"}
                          </small>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="keyTextColor"
                        defaultValue={props.theme.keyTextColor.value}
                        className={
                          formTextState.keyTextColor.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleHexCodeChange(event)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>
                        Key Popup Selection Color
                        {formTextState.keyPopupSelectionColor.valid ? (
                          <div
                            className="displayColor ml-2"
                            style={{
                              backgroundColor:
                                formTextState.keyPopupSelectionColor.value,
                            }}
                          ></div>
                        ) : (
                          <small className="text-danger ml-2">
                            {"Invalid Hex Code"}
                          </small>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="keyPopupSelectionColor"
                        defaultValue={props.theme.keyPopupSelectionColor.value}
                        className={
                          formTextState.keyPopupSelectionColor.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleHexCodeChange(event)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>
                        Key Popup Expanded Background Color
                        {formTextState.keyPopupExpandedBackgroundColor.valid ? (
                          <div
                            className="displayColor ml-2"
                            style={{
                              backgroundColor:
                                formTextState.keyPopupExpandedBackgroundColor
                                  .value,
                            }}
                          ></div>
                        ) : (
                          <small className="text-danger ml-2">
                            {"Invalid Hex Code"}
                          </small>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="keyPopupExpandedBackgroundColor"
                        defaultValue={
                          props.theme.keyPopupExpandedBackgroundColor.value
                        }
                        className={
                          formTextState.keyPopupExpandedBackgroundColor.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleHexCodeChange(event)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>
                        Top Bar Background Color
                        {formTextState.topBarBackgroundColor.valid ? (
                          <div
                            className="displayColor ml-2"
                            style={{
                              backgroundColor:
                                formTextState.topBarBackgroundColor.value,
                            }}
                          ></div>
                        ) : (
                          <small className="text-danger ml-2">
                            {"Invalid Hex Code"}
                          </small>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="topBarBackgroundColor"
                        defaultValue={props.theme.topBarBackgroundColor.value}
                        className={
                          formTextState.topBarBackgroundColor.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleHexCodeChange(event)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>
                        Suggestions Color Auto Correct
                        {formTextState.suggestionsColorAutoCorrect.valid ? (
                          <div
                            className="displayColor ml-2"
                            style={{
                              backgroundColor:
                                formTextState.suggestionsColorAutoCorrect.value,
                            }}
                          ></div>
                        ) : (
                          <small className="text-danger ml-2">
                            {"Invalid Hex Code"}
                          </small>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="suggestionsColorAutoCorrect"
                        defaultValue={
                          props.theme.suggestionsColorAutoCorrect.value
                        }
                        className={
                          formTextState.suggestionsColorAutoCorrect.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleHexCodeChange(event)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>
                        Suggestions Color Suggested
                        {formTextState.suggestionsColorSuggested.valid ? (
                          <div
                            className="displayColor ml-2"
                            style={{
                              backgroundColor:
                                formTextState.suggestionsColorSuggested.value,
                            }}
                          ></div>
                        ) : (
                          <small className="text-danger ml-2">
                            {"Invalid Hex Code"}
                          </small>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="suggestionsColorSuggested"
                        defaultValue={
                          props.theme.suggestionsColorSuggested.value
                        }
                        className={
                          formTextState.suggestionsColorSuggested.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleHexCodeChange(event)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>
                        Suggestions Color Typed Word
                        {formTextState.suggestionsColorTypedWord.valid ? (
                          <div
                            className="displayColor ml-2"
                            style={{
                              backgroundColor:
                                formTextState.suggestionsColorTypedWord.value,
                            }}
                          ></div>
                        ) : (
                          <small className="text-danger ml-2">
                            {"Invalid Hex Code"}
                          </small>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="suggestionsColorTypedWord"
                        defaultValue={
                          props.theme.suggestionsColorTypedWord.value
                        }
                        className={
                          formTextState.suggestionsColorTypedWord.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleHexCodeChange(event)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>
                        Suggestions Color Valid Typed Word
                        {formTextState.suggestionsColorValidTypedWord.valid ? (
                          <div
                            className="displayColor ml-2"
                            style={{
                              backgroundColor:
                                formTextState.suggestionsColorValidTypedWord
                                  .value,
                            }}
                          ></div>
                        ) : (
                          <small className="text-danger ml-2">
                            {"Invalid Hex Code"}
                          </small>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="suggestionsColorValidTypedWord"
                        defaultValue={
                          props.theme.suggestionsColorValidTypedWord.value
                        }
                        className={
                          formTextState.suggestionsColorValidTypedWord.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleHexCodeChange(event)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>
                        Swipe Gesture Trail Color
                        {formTextState.swipeGestureTrailColor.valid ? (
                          <div
                            className="displayColor ml-2"
                            style={{
                              backgroundColor:
                                formTextState.swipeGestureTrailColor.value,
                            }}
                          ></div>
                        ) : (
                          <small className="text-danger ml-2">
                            {"Invalid Hex Code"}
                          </small>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="swipeGestureTrailColor"
                        defaultValue={props.theme.swipeGestureTrailColor.value}
                        className={
                          formTextState.swipeGestureTrailColor.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleHexCodeChange(event)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>
                        Top Key Text Color
                        {formTextState.topKeyTextColor.valid ? (
                          <div
                            className="displayColor ml-2"
                            style={{
                              backgroundColor:
                                formTextState.topKeyTextColor.value,
                            }}
                          ></div>
                        ) : (
                          <small className="text-danger ml-2">
                            {"Invalid Hex Code"}
                          </small>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="topKeyTextColor"
                        defaultValue={props.theme.topKeyTextColor.value}
                        className={
                          formTextState.topKeyTextColor.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleHexCodeChange(event)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>
                        Functional Key Background Color
                        {formTextState.functionalKeyBackgroundColor.valid ? (
                          <div
                            className="displayColor ml-2"
                            style={{
                              backgroundColor:
                                formTextState.functionalKeyBackgroundColor
                                  .value,
                            }}
                          ></div>
                        ) : (
                          <small className="text-danger ml-2">
                            {"Invalid Hex Code"}
                          </small>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="functionalKeyBackgroundColor"
                        defaultValue={
                          props.theme.functionalKeyBackgroundColor.value
                        }
                        className={
                          formTextState.functionalKeyBackgroundColor.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleHexCodeChange(event)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>
                        Action Color
                        {formTextState.actionColor.valid ? (
                          <div
                            className="displayColor ml-2"
                            style={{
                              backgroundColor: formTextState.actionColor.value,
                            }}
                          ></div>
                        ) : (
                          <small className="text-danger ml-2">
                            {"Invalid Hex Code"}
                          </small>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="actionColor"
                        defaultValue={props.theme.actionColor.value}
                        className={
                          formTextState.actionColor.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleHexCodeChange(event)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>
                        Selected Icon Color
                        {formTextState.selectedIconColor.valid ? (
                          <div
                            className="displayColor ml-2"
                            style={{
                              backgroundColor:
                                formTextState.selectedIconColor.value,
                            }}
                          ></div>
                        ) : (
                          <small className="text-danger ml-2">
                            {"Invalid Hex Code"}
                          </small>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="selectedIconColor"
                        defaultValue={props.theme.selectedIconColor.value}
                        className={
                          formTextState.selectedIconColor.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleHexCodeChange(event)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>
                        Key Border Stroke Color
                        {formTextState.keyBorderStrokeColor.valid ? (
                          <div
                            className="displayColor ml-2"
                            style={{
                              backgroundColor:
                                formTextState.keyBorderStrokeColor.value,
                            }}
                          ></div>
                        ) : (
                          <small className="text-danger ml-2">
                            {"Invalid Hex Code"}
                          </small>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="keyBorderStrokeColor"
                        defaultValue={props.theme.keyBorderStrokeColor.value}
                        className={
                          formTextState.keyBorderStrokeColor.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleHexCodeChange(event)}
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>
                        Panel Background Color
                        {formTextState.panelBackgroundColor.valid ? (
                          <div
                            className="displayColor ml-2"
                            style={{
                              backgroundColor:
                                formTextState.panelBackgroundColor.value,
                            }}
                          ></div>
                        ) : (
                          <small className="text-danger ml-2">
                            {"Invalid Hex Code"}
                          </small>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="panelBackgroundColor"
                        defaultValue={props.theme.panelBackgroundColor.value}
                        className={
                          formTextState.panelBackgroundColor.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleHexCodeChange(event)}
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>
                        Suggestion Drawer Category Button Color
                        {formTextState.suggestionDrawerCategoryButtonColor.valid ? (
                          <div
                            className="displayColor ml-2"
                            style={{
                              backgroundColor:
                                formTextState.suggestionDrawerCategoryButtonColor.value,
                            }}
                          ></div>
                        ) : (
                          <small className="text-danger ml-2">
                            {"Invalid Hex Code"}
                          </small>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="suggestionDrawerCategoryButtonColor"
                        defaultValue={props.theme.suggestionDrawerCategoryButtonColor.value}
                        className={
                          formTextState.suggestionDrawerCategoryButtonColor.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleHexCodeChange(event)}
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>
                        Suggestion Drawer Category Button Text Color
                        {formTextState.suggestionDrawerCategoryButtonTextColor.valid ? (
                          <div
                            className="displayColor ml-2"
                            style={{
                              backgroundColor:
                                formTextState.suggestionDrawerCategoryButtonTextColor.value,
                            }}
                          ></div>
                        ) : (
                          <small className="text-danger ml-2">
                            {"Invalid Hex Code"}
                          </small>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="suggestionDrawerCategoryButtonTextColor"
                        defaultValue={props.theme.suggestionDrawerCategoryButtonTextColor.value}
                        className={
                          formTextState.suggestionDrawerCategoryButtonTextColor.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleHexCodeChange(event)}
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>
                        Suggestion Drawer Category Selected Button Color
                        {formTextState.suggestionDrawerCategorySelectedButtonColor.valid ? (
                          <div
                            className="displayColor ml-2"
                            style={{
                              backgroundColor:
                                formTextState.suggestionDrawerCategorySelectedButtonColor.value,
                            }}
                          ></div>
                        ) : (
                          <small className="text-danger ml-2">
                            {"Invalid Hex Code"}
                          </small>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="suggestionDrawerCategorySelectedButtonColor"
                        defaultValue={props.theme.suggestionDrawerCategorySelectedButtonColor.value}
                        className={
                          formTextState.suggestionDrawerCategorySelectedButtonColor.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleHexCodeChange(event)}
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>
                        Suggestion Drawer Category Selected Button Text Color
                        {formTextState.suggestionDrawerCategorySelectedButtonTextColor.valid ? (
                          <div
                            className="displayColor ml-2"
                            style={{
                              backgroundColor:
                                formTextState.suggestionDrawerCategorySelectedButtonTextColor.value,
                            }}
                          ></div>
                        ) : (
                          <small className="text-danger ml-2">
                            {"Invalid Hex Code"}
                          </small>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="suggestionDrawerCategorySelectedButtonTextColor"
                        defaultValue={props.theme.suggestionDrawerCategorySelectedButtonTextColor.value}
                        className={
                          formTextState.suggestionDrawerCategorySelectedButtonTextColor.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleHexCodeChange(event)}
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>
                        Keyboard Overlay Opacity
                        <small className="text-info">
                          {"(0 <= value <= 1)"}
                        </small>
                      </Label>
                      <Input
                        type="text"
                        name="keyboardOverlayOpacity"
                        defaultValue={props.theme.keyboardOverlayOpacity.value}
                        className={
                          formTextState.keyboardOverlayOpacity.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleOpacityChange(event)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>Key Border Stroke Width</Label>
                      <Input
                        type="text"
                        name="keyBorderStrokeWidth"
                        defaultValue={props.theme.keyBorderStrokeWidth.value}
                        className={
                          formTextState.keyBorderStrokeWidth.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleFloatChange(event)}
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>Key Shadow Radius</Label>
                      <Input
                        type="text"
                        name="keyShadowRadius"
                        defaultValue={props.theme.keyShadowRadius.value}
                        className={
                          formTextState.keyShadowRadius.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleFloatChange(event)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        Start Date{" "}
                        <ToolTip
                          data="Keyboard Theme will be available after this date."
                          id="startDate"
                        />
                      </Label>

                      <Input
                        type="date"
                        name="startDate"
                        onChange={handleDateChange}
                        defaultValue={
                          formTextState.startDate !== ""
                            ? formTextState.startDate
                            : ""
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        End Date{" "}
                        <ToolTip
                          data="Keyboard Theme will not be available after this date."
                          id="endDate"
                        />
                      </Label>
                      <Input
                        type="date"
                        name="endDate"
                        onChange={handleDateChange}
                        defaultValue={
                          formTextState.endDate !== ""
                            ? formTextState.endDate
                            : ""
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup className="row mt-3">
                  <Label className="col-lg-5 col-sm-12">Is Light Theme</Label>
                  <CustomInput
                    type="radio"
                    id="isLightThemeTrue"
                    name="isLightTheme"
                    label="True"
                    value="true"
                    className="mb-2 col-lg-2 col-sm-4 pl-5"
                    onChange={(event) => handleChange(event)}
                    required
                    defaultChecked={props.theme.isLightTheme === true}
                  />
                  <CustomInput
                    type="radio"
                    id="isLightThemeFalse"
                    name="isLightTheme"
                    label="False"
                    value="false"
                    className="mb-2 col-lg-2 col-sm-4 pl-5"
                    onChange={(event) => handleChange(event)}
                    defaultChecked={props.theme.isLightTheme === false}
                  />
                </FormGroup>
                <FormGroup className="row">
                  <Label className="col-lg-5 col-sm-12">
                    Enable Keyboard Keypress Sound
                  </Label>
                  <CustomInput
                    type="radio"
                    id="enableKeyboardKeySoundTrue"
                    name="enableKeyboardKeySound"
                    label="True"
                    value="true"
                    className="mb-2 col-lg-2 col-sm-4 pl-5"
                    onChange={(event) => handleChange(event)}
                    defaultChecked={props.theme.enableKeyboardKeySound === true}
                  />
                  <CustomInput
                    type="radio"
                    id="enableKeyboardKeySoundFalse"
                    name="enableKeyboardKeySound"
                    label="False"
                    value="false"
                    className="mb-2 col-lg-2 col-sm-4 pl-5"
                    onChange={(event) => handleChange(event)}
                    defaultChecked={
                      props.theme.enableKeyboardKeySound === false
                    }
                  />
                  <CustomInput
                    type="radio"
                    id="enableKeyboardKeySoundDefault"
                    name="enableKeyboardKeySound"
                    label="User's default setting"
                    value=""
                    className="mb-2 col-lg-3 col-sm-4 pl-5"
                    onChange={(event) => handleChange(event)}
                    defaultChecked={props.theme.enableKeyboardKeySound === null}
                  />
                </FormGroup>
                <FormGroup className="row">
                  <Label className="col-lg-5 col-sm-12">
                    Enable Keyboard Key Border
                  </Label>
                  <CustomInput
                    type="radio"
                    id="enableKeyboardKeyBorderTrue"
                    name="enableKeyboardKeyBorder"
                    label="True"
                    value="true"
                    className="mb-2 col-lg-2 col-sm-4 pl-5"
                    onChange={(event) => handleChange(event)}
                    defaultChecked={
                      props.theme.enableKeyboardKeyBorder === true
                    }
                  />
                  <CustomInput
                    type="radio"
                    id="enableKeyboardKeyBorderFalse"
                    name="enableKeyboardKeyBorder"
                    label="False"
                    value="false"
                    className="mb-2 col-lg-2 col-sm-4 pl-5"
                    onChange={(event) => handleChange(event)}
                    defaultChecked={
                      props.theme.enableKeyboardKeyBorder === false
                    }
                  />
                  <CustomInput
                    type="radio"
                    id="enableKeyboardKeyBorderDefault"
                    name="enableKeyboardKeyBorder"
                    label="User's default setting"
                    value=""
                    className="mb-2 col-lg-3 col-sm-4 pl-5"
                    onChange={(event) => handleChange(event)}
                    defaultChecked={
                      props.theme.enableKeyboardKeyBorder === null
                    }
                  />
                </FormGroup>
                <FormGroup className="row">
                  <Label className="col-lg-5 col-sm-12">
                    Enable Keyboard Top Keys
                  </Label>
                  <CustomInput
                    type="radio"
                    id="enableKeyboardTopKeysTrue"
                    name="enableKeyboardTopKeys"
                    label="True"
                    value="true"
                    className="mb-2 col-lg-2 col-sm-4 pl-5"
                    onChange={(event) => handleChange(event)}
                    defaultChecked={props.theme.enableKeyboardTopKeys === true}
                  />
                  <CustomInput
                    type="radio"
                    id="enableKeyboardTopKeysFalse"
                    name="enableKeyboardTopKeys"
                    label="False"
                    value="false"
                    className="mb-2 col-lg-2 col-sm-4 pl-5"
                    onChange={(event) => handleChange(event)}
                    defaultChecked={props.theme.enableKeyboardTopKeys === false}
                  />
                  <CustomInput
                    type="radio"
                    id="enableKeyboardTopKeysDefault"
                    name="enableKeyboardTopKeys"
                    label="User's default setting"
                    value=""
                    className="mb-2 col-lg-3 col-sm-4 pl-5"
                    onChange={(event) => handleChange(event)}
                    defaultChecked={props.theme.enableKeyboardTopKeys === null}
                  />
                </FormGroup>
                <FormGroup className="row">
                  <Label className="col-lg-5 col-sm-12">
                    Enable Keyboard Key Popup
                  </Label>
                  <CustomInput
                    type="radio"
                    id="enableKeyboardKeyPopupTrue"
                    name="enableKeyboardKeyPopup"
                    label="True"
                    value="true"
                    className="mb-2 col-lg-2 col-sm-4 pl-5"
                    onChange={(event) => handleChange(event)}
                    defaultChecked={props.theme.enableKeyboardKeyPopup === true}
                  />
                  <CustomInput
                    type="radio"
                    id="enableKeyboardKeyPopupFalse"
                    name="enableKeyboardKeyPopup"
                    label="False"
                    value="false"
                    className="mb-2  col-lg-2 col-sm-4 pl-5"
                    onChange={(event) => handleChange(event)}
                    defaultChecked={
                      props.theme.enableKeyboardKeyPopup === false
                    }
                  />
                  <CustomInput
                    type="radio"
                    id="enableKeyboardKeyPopupDefault"
                    name="enableKeyboardKeyPopup"
                    label="User's default setting"
                    value=""
                    className="mb-2  col-lg-3 col-sm-4 pl-5"
                    onChange={(event) => handleChange(event)}
                    defaultChecked={props.theme.enableKeyboardKeyPopup === null}
                  />
                </FormGroup>
                <FormGroup className="row">
                  <Label className="col-lg-5 col-sm-12">Premium Theme</Label>
                  <Col lg={7}>
                    <CustomInput
                      type="switch"
                      id="isPremium"
                      name="isPremium"
                      label={formTextState.isPremium ? "Yes" : "No"}
                      checked={formTextState.isPremium}
                      onChange={handleIsPremiumToggle}
                      disabled={!props.updateAllowed}
                    />
                  </Col>
                </FormGroup>
                <hr style={{ margin: 8 }} />

                {displayError ? (
                  <p className="text-danger mb-0">
                    Please enter all valid details.
                  </p>
                ) : null}
                <br />
                {props.updateAllowed ? (
                  <Button color="primary" type="submit" className="mt-2">
                    Submit
                  </Button>
                ) : (
                  <div />
                )}
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default KeyboardThemeForm;

import fetch from "isomorphic-fetch";
import { storeInLocalStorage } from "../localStorageUtil";
import decode from "jwt-decode";

let localHost = "http://localhost:1323";

let host = window._env_.REACT_APP_API_HOST;

let publicAPIHost = window._env_.REACT_APP_BOBBLE_API_HOST;

let mintHost = window._env_.REACT_APP_MINT_API_HOST;

let mintAPIToken = window._env_.REACT_APP_MINT_API_TOKEN;

let cronStartMinute = window._env_.REACT_APP_CRON_START_MINUTE;

let cronStartHours = window._env_.REACT_APP_CRON_START_HOURS;

let cronIntervalMinutes = window._env_.REACT_APP_CRON_INTERVAL_MINUTES;

let storyAdImageAssetSize = window._env_.REACT_APP_STORY_AD_IMAGE_ASSET_SIZE;

let storyAdVideoAssetSize = window._env_.REACT_APP_STORY_AD_VIDEO_ASSET_SIZE;

let storyAdImageAssetAspectRatioRange = window._env_.REACT_APP_STORY_AD_IMAGE_ASSET_ASPECT_RATIO_RANGE;

let storyAdVideoAssetAspectRatioRange = window._env_.REACT_APP_STORY_AD_VIDEO_ASSET_ASPECT_RATIO_RANGE;

if (!cronStartMinute) cronStartMinute = "0";
cronStartMinute = parseInt(cronStartMinute);

if (!cronStartHours) cronStartHours = "[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]"
cronStartHours = JSON.parse(cronStartHours)

if (!cronIntervalMinutes) cronIntervalMinutes = "5"
cronIntervalMinutes = parseInt(cronIntervalMinutes);

if (!storyAdImageAssetSize) storyAdImageAssetSize = null
storyAdImageAssetSize = parseInt(storyAdImageAssetSize);

if (!storyAdVideoAssetSize) storyAdVideoAssetSize = null
storyAdVideoAssetSize = parseInt(storyAdVideoAssetSize);

if (!storyAdImageAssetAspectRatioRange) storyAdImageAssetAspectRatioRange = "[]"
storyAdImageAssetAspectRatioRange = JSON.parse(storyAdImageAssetAspectRatioRange)

if (!storyAdVideoAssetAspectRatioRange) storyAdVideoAssetAspectRatioRange = "[]"
storyAdVideoAssetAspectRatioRange = JSON.parse(storyAdVideoAssetAspectRatioRange)

if (host === undefined) host = localHost;
if (publicAPIHost === undefined) publicAPIHost = localHost;
if (mintHost === undefined) mintHost = localHost;
export { host, publicAPIHost, mintHost, mintAPIToken, cronStartMinute, cronStartHours, cronIntervalMinutes, storyAdImageAssetSize, storyAdVideoAssetSize, storyAdImageAssetAspectRatioRange, storyAdVideoAssetAspectRatioRange };

export let verifyLogin = async (username, password) => {
  let token = "";

  let formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);
  let url = `${host}/internal/auth/login`;

  await fetch(url, {
    method: "POST",
    body: formData,
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.accessToken) {
        token = data.accessToken;
        storeInLocalStorage("userPermissions", decode(token).Permissions);
        storeInLocalStorage("token", token);
        storeInLocalStorage("username", username);
        storeInLocalStorage("exp", decode(token).exp);
      }
    });

  return token;
};

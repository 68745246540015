import React from "react";
import moment from "moment";
import { Badge } from "reactstrap";
import { promptTypes } from "../../../../config/config"

const endDateFormatter = (cell, row) => {
  const diff = moment(cell).diff(moment().format("YYYY-MM-DD"), "days")
  return cell
    ? <Badge style={{ fontSize: 13 }} className={diff > 1 ? `badge-success` : diff > 0 ? `badge-warning` : `badge-danger`}>{cell}</Badge>
    : "NULL"
}

const statusTypeDecider = (cell, row) => {
  if (row.status === "Activated") {
    if (row.type === promptTypes.prompt) {
      return <>
        <Badge className="badge-success" style={{
          width: "1.5rem"
        }}>P</Badge>{" "}
        {cell}
      </>
    } else if (row.type === promptTypes.suggestionDrawer) {
      return <>
        <Badge className="badge-success" style={{
          width: "1.5rem"
        }}>SD</Badge>{" "}
        {cell}
      </>
    } else if (row.type === promptTypes.aiPoweredBar) {
      return <>
        <Badge className="badge-success" style={{
          width: "1.5rem"
        }}>AI</Badge>{" "}
        {cell}
      </>
    } else {
      return cell;
    }
  } else if (row.status === "Deactivated") {
    if (row.type === promptTypes.prompt) {
      return <>
        <Badge className="badge-danger" style={{
          width: "1.5rem"
        }}>P</Badge>{" "}
        {cell}
      </>
    } else if (row.type === promptTypes.suggestionDrawer) {
      return <>
        <Badge className="badge-danger" style={{
          width: "1.5rem"
        }}>SD</Badge>{" "}
        {cell}
      </>
    } else if (row.type === promptTypes.aiPoweredBar) {
      return <>
        <Badge className="badge-danger" style={{
          width: "1.5rem"
        }}>AI</Badge>{" "}
        {cell}
      </>
    }
  } else if (row.status === "Unpublished") {
    if (row.type === promptTypes.prompt) {
      return <>
        <Badge className="badge-warning" style={{
          width: "1.5rem"
        }}>P</Badge>{" "}
        {cell}
      </>
    } else if (row.type === promptTypes.suggestionDrawer) {
      return <>
        <Badge className="badge-warning" style={{
          width: "1.5rem"
        }}>SD</Badge>{" "}
        {cell}
      </>
    } else if (row.type === promptTypes.aiPoweredBar) {
      return <>
        <Badge className="badge-warning" style={{
          width: "1.5rem"
        }}>AI</Badge>{" "}
        {cell}
      </>
    }
  } else if (row.type === promptTypes.popText) {
    return <>
      <Badge className="badge-success" style={{
        width: "1.5rem"
      }}>Pop</Badge>{" "}
      {cell}
    </>
  } else if (row.type === promptTypes.scorebar) {
    return <>
      <Badge className="badge-success" style={{
        width: "1.5rem"
      }}>S</Badge>{" "}
      {cell}
    </>
  } else if (row.type === promptTypes.aiClipboard) {
    return <>
      <Badge className="badge-success" style={{
        width: "1.5rem"
      }}>AI Clip</Badge>{" "}
      {cell}
    </>
  } else {
    return cell;
  }
}


const tableColumns = [
  {
    dataField: "id",
    text: "#",
    sort: true,
    headerAttrs: { width: 60 },
    searchable: true,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "name",
    text: "Name",
    sort: true,
    searchable: true,
    formatter: (cell, row) => statusTypeDecider(cell, row),
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "description",
    text: "Description",
    headerAttrs: { width: 230 },
    searchable: true,
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "priority",
    text: "Priority",
    sort: true,
    searchable: true,
    style: {
      cursor: "pointer",
      color: "#f00",
      fontWeight: "bold"
    },
  },
  {
    dataField: "startDate",
    text: "Start Date",
    sort: true,
    searchable: false,
    formatter: (cell) => {
      if ({ cell }.cell === null) return "NULL";
      else return { cell }.cell;
    },
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "endDate",
    text: "End Date",
    sort: true,
    searchable: false,
    formatter: (cell, row) => endDateFormatter(cell, row),
    style: {
      cursor: "pointer",
    },
  },
];

export { tableColumns };


const tableOrderColumns = [
  {
    dataField: "id",
    text: "#",
    sort: true,
    headerAttrs: { width: 60 },
    searchable: true,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "ruleId",
    text: "Rule ID",
    headerAttrs: { width: 100 },
    sort: true,
    searchable: true,
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "description",
    text: "Description",
    headerAttrs: { width: 250 },
    sort: true,
    searchable: true,
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "segmentId",
    text: "Segment ID",
    headerAttrs: { width: 100 },
    sort: true,
    searchable: true,
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "priority",
    text: "Priority",
    headerAttrs: { width: 120 },
    sort: true,
    searchable: true,
    style: {
      cursor: "pointer",
      color: "#f00",
      fontWeight: "bold",
    },
  },
  {
    dataField: "createdAt",
    text: "Created At",
    headerAttrs: { width: 180 },
    sort: true,
    searchable: false,
    formatter: (cell) => new Date(cell).toLocaleString(),
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "updatedAt",
    text: "Updated At",
    headerAttrs: { width: 180 },
    sort: true,
    searchable: false,
    formatter: (cell) => new Date(cell).toLocaleString(),
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
];

export { tableOrderColumns };
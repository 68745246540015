import React, { useState, useEffect, useRef } from "react";
import { Card, CardBody, Container, Row, Col, Input, Badge } from "reactstrap";
import { Button } from "react-bootstrap";
import {
  activateContentCategory,
  deactivateContentCategory,
  publishContentCategory
} from "../../../utilities/apiUtils/cre";
import { getContentCategories } from "../../../utilities/apiUtils/cre";
import {
  errorDescription,
  permissions,
  allActions,
  error,
  redirectOnTokenExpiry,
  isOperationPermitted,
  redirectTo,
  getStatus
} from "../../../utilities/commonUtil";
import ResponseModal from "../ResponseModal";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  tableColumns,
  statusFilter,
} from "./CategoriesTableColumn.js";
import ConfirmationModal from "../ConfirmationModal";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { Link } from "react-router-dom";
import Loading from "../../../components/Loading";
import {
  successModalHeading,
  errorModalHeading,
  create,
} from "../../../config/config";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import Select from "react-select";
const { SearchBar } = Search;
let statusFilterRef;
const ExpandableRowsTable = () => {
  const [loading, setLoading] = useState(true);
  const [categoriesData, setCategoriesData] = useState([]);
  const [column] = useState(tableColumns);
  const [successModalText, setSuccessModalText] = useState("");
  const [failureModalText, setFailureModalText] = useState("");
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [id, setId] = useState(null);
  const [action, setAction] = useState("");
  const [filters, setFilters] = useState({});
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  statusFilterRef = useRef();

  useEffect(() => {
    let fetchCategoriesData = async () => {
      try {
        let response = await getContentCategories();
        if (response.length > 0) {
          setCategoriesData(response);
          setLoading(false)
        }
        else {
          let text = errorDescription(response.errorCode);
          setLoading(false);
          showErrorModal(text);
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken
          )
            redirectOnTokenExpiry();
        }
      } catch (err) {
        setLoading(false);
        setFailureModalDisplay(true);
        setFailureModalText(errorDescription(error.unexpectedError));
      }
    };
    fetchCategoriesData();
  }, [])

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    let response = await getContentCategories();
    if (response.length) {
      setCategoriesData(response);
    } else if (response.errorCode) {
      let text = errorDescription(response.errorCode);
      showErrorModal(text);
    }
  };

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let disablePublishButton = (row) => {
    if (row.publishedAt === null) return false;
    return true;
  };

  let disableDeactivateButton = (row) => {
    if (row.publishedAt !== null && row.deactivatedAt === null) return false;
    return true;
  };

  let disableActivateButton = (row) => {
    if (row.publishedAt !== null && row.deactivatedAt !== null) return false;
    return true;
  };

  let publish = async (id) => {
    let response = await publishContentCategory(id);
    if (response.status === "published") {
      showSuccessModal(response.description);
    } else {
      let text = errorDescription(response.errorCode);
      showErrorModal(text);
      if (
        response.errorCode === error.tokenExpired ||
        response.errorCode === error.invalidAccessToken
      )
        redirectOnTokenExpiry();
    }
  };

  let deactivate = async (id) => {
    let response = await deactivateContentCategory(id);
    if (response.status === "deactivated") {
      showSuccessModal(response.description);
    } else {
      let text = errorDescription(response.errorCode);
      showErrorModal(text);
      if (
        response.errorCode === error.tokenExpired ||
        response.errorCode === error.invalidAccessToken
      )
        redirectOnTokenExpiry();
    }
  };

  let activate = async (id) => {
    let response = await activateContentCategory(id);
    if (response.status === "activated") {
      showSuccessModal(response.description);
    } else {
      let text = errorDescription(response.errorCode);
      showErrorModal(text);
      if (
        response.errorCode === error.tokenExpired ||
        response.errorCode === error.invalidAccessToken
      )
        redirectOnTokenExpiry();
    }
  };

  let setConfirmationModalState = (id, event) => {
    setAction(event);
    setConfirmationModalIsOpen(true);
    setId(id);
  };

  let showPublishButton = () => {
    return allowedPermissions.includes(permissions.contentCategoryPublish);
  };

  let showActivateButton = () => {
    return allowedPermissions.includes(permissions.contentCategoryActivate);
  };

  let showDeactivateButton = () => {
    return allowedPermissions.includes(permissions.contentCategoryDeactivate);
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <ul>
          <li>Content Types :
            {row.contentTypes.map((content, i) => {
              return <Badge className="badge-primary" style={{ margin: 5 }} key={i}>{content.contentType}</Badge>
            })}
          </li>
          <li>{`Start Time : ${row.startTime}`}</li>
          <li>{`End Time: ${row.endTime}`}</li>
          <li>{`Created At : ${row.createdAt}`}</li>
          <li>{`Updated At : ${row.updatedAt}`}</li>
          <li>{`Published At : ${row.publishedAt}`}</li>
          <li>{`Deactivated At : ${row.deactivatedAt}`}</li>
        </ul>
        <Link to={{
          pathname: `/cre/content-categories-details/${row.id}`
        }}>
          <Button
            className="mx-3"
            updateAllowed={false}
          >
            Edit Details
          </Button>
        </Link>

        <Button
          className="mx-3 btn-info"
          disabled={disablePublishButton(row) || !showPublishButton()}
          onClick={(event) =>
            setConfirmationModalState(row.id, event.target.innerHTML)
          }
        >
          Publish
        </Button>

        <Button
          className="mx-3 btn-danger"
          disabled={disableDeactivateButton(row) || !showDeactivateButton()}
          onClick={(event) =>
            setConfirmationModalState(row.id, event.target.innerHTML)
          }
        >
          Deactivate
        </Button>

        <Button
          className="mx-3 btn-success"
          disabled={disableActivateButton(row) || !showActivateButton()}
          onClick={(event) =>
            setConfirmationModalState(row.id, event.target.innerHTML)
          }
        >
          Activate
        </Button>

        <Link to={{
          pathname: `/cre/content-categories-rules/${row.id}`
        }}>
          <Button
            className="mx-3"
            disabled={false}
          >
            Edit Targeting Rules
          </Button>
        </Link>
        <Link to={{
          pathname: `/cre/content-categories-user-attribute-rules/${row.id}`
        }}>
          <Button
            className="mx-3"
            disabled={false}
          >
            Edit User Attribute Rules
          </Button>
        </Link>
      </div>
    ),
  };

  let onTableChange = (type, newState) => {
    setFilters(newState.filters);
  };

  const contentCategoriesData = () => {
    let items = categoriesData;

    const statusFilter = filters.status ? filters.status.filterVal : null;
    if (statusFilter)
      items = items.filter((item) => item.status === statusFilter);

    return items;
  };

  const statusOptions = [
    { value: "", label: "All" },
    { value: "Activated", label: "Activated" },
    { value: "Created", label: "Created" },
    { value: "Deactivated", label: "Deactivated" },
  ];

  return (
    <React.Fragment>
      {loading ? (
        <Loading />
      ) : (
        <>
          <ToolkitProvider
            keyField="id"
            columns={column}
            data={categoriesData}
            search={{
              searchFormatted: true,
            }}
          >
            {(props) => (
              <div>
                <Card style={{ overflow: "hidden", paddingBottom: "100px" }}>
                  <div container-fullwidth="true" style={{ margin: "2%" }}>
                    <Row className="justify-content-md-center mx-auto">
                      <Col md={6} xs={7} className="p-0 mt-0 pb-4">
                        <SearchBar
                          placeholder="Search By Name "
                          {...props.searchProps}
                          style={{
                            position: "absolute",
                          }}
                        />
                      </Col>
                      <Col md={6} xs={5} className="p-0">
                        <ClearButton
                          {...props.searchProps}
                        >
                          Reset Filters
                        </ClearButton>
                      </Col>
                    </Row>
                    <Row></Row>
                    <Row>
                      <Col md={3} sm={12} className="mt-3">
                        <Select
                          className="react-select-container"
                          id="statusSelect"
                          classNamePrefix="react-select"
                          name="status"
                          placeholder="Select Status"
                          onChange={(event) => {
                            if (event) statusFilter(event.value);
                          }}
                          options={statusOptions}
                          ref={(ref) => {
                            statusFilterRef = ref;
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                  <CardBody>
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      wrapperClasses="table-responsive"
                      hover={true}
                      bordered={false}
                      keyField="id"
                      data={contentCategoriesData()}
                      columns={column}
                      expandRow={expandRow}
                      filter={filterFactory()}
                      pagination={paginationFactory({
                        sizePerPage: 10,
                        sizePerPageList: [5, 10, 25, 50],
                      })}
                      defaultSorted={[{
                        dataField: 'id',
                        order: 'desc'
                      }]}
                      onTableChange={onTableChange}
                      remote={{ filter: true }}
                      noDataIndication="No results found"
                    />
                  </CardBody>
                </Card>
              </div>
            )}
          </ToolkitProvider>

          <ResponseModal
            show={successModalDisplay}
            onHide={successModalClose}
            modalheading={successModalHeading}
            modaltext={successModalText}
          />
          <ResponseModal
            show={failureModalDisplay}
            onHide={failureModalClose}
            modalheading={errorModalHeading}
            modaltext={failureModalText}
          />
          <ConfirmationModal
            show={confirmationModalIsOpen}
            modaltext={action}
            onConfirmationTrue={() => {
              setConfirmationModalIsOpen(false);
              if (action === allActions.publish) publish(id);
              else if (action === allActions.deactivate) deactivate(id);
              else if (action === allActions.activate) activate(id);
            }}
            onHide={() => setConfirmationModalIsOpen(false)}
          />
        </>
      )}
    </React.Fragment>
  );
};

const ClearButton = (props) => {
  const handleClick = () => {
    statusFilter("");
    if (statusFilterRef.select) statusFilterRef.select.clearValue();
    props.onSearch("");
  };
  return (
    <Button
      style={{ float: "right" }}
      className="form-control-lg"
      onClick={handleClick}
    >
      Reset Filters
    </Button>
  );
};

const Tables = (props) => {
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  return (
    <Container fluid className="p-0">
      <Row className="mb-3">
        <Col md={6} xs={4}>
          <h1>Content Categories</h1>
        </Col>
        <Col md={6} xs={8}>

          <Link
            className="d-block ml-auto text-decoration-none"
            to={{
              pathname: isOperationPermitted(allowedPermissions, permissions.contentCategoryCreate) ? `/cre/create-category` : '',
              state: {
                formType: create,
              },
            }}

          >
            <Button color="primary" className="ml-auto d-flex" disabled={!isOperationPermitted(allowedPermissions, permissions.contentCategoryCreate)}>
              <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                Add New Category
              </p>
            </Button>
          </Link>

        </Col>
      </Row>
      <ExpandableRowsTable />
    </Container>
  );
};

export default Tables;

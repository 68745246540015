import React, { useEffect, useState } from "react";
import { Container, Button, Row, Col, Spinner } from "reactstrap";
import Search from "../../../components/Search";
import AdBannerCard from "./components/AdBannerCard";

import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import UncontrolledButtonDropdown from "reactstrap/lib/UncontrolledButtonDropdown";
import { Config } from "../../../config";
import Select from "react-select";

import {
  getAdBanners,
  activateAdBanner,
  deactivateAdBanner,
  publishAdBanner,
} from "../../../utilities/apiUtils/adBanners";
import { capitalize } from "@material-ui/core";
import EmptyAdBanners from "./components/EmptyAdBanners";

import {
  successModalHeading,
  failureModalHeading,
  publishedStatus,
  activatedStatus,
  deactivatedStatus,
} from "../../../config/quickReplies";
import ConfirmationModal from "../ConfirmationModal";
import ResponseModal from "../ResponseModal";
import {
  allActions,
  error,
  errorDescription,
  getStatus,
  getBtnName,
  getStatusColor,
  getButtonColor,
  permissions,
  redirectionOnTokenExpiry,
} from "../../../utilities/commonUtil";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { faUpload, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faWindows } from "@fortawesome/free-brands-svg-icons";

const { SearchBar } = Search;

const AdBanners = () => {
  const [adBanners, setAdBanners] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [searchString, setSearchString] = useState("");
  const [mediaType , setMediaType] = useState("");
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [id, setId] = useState(null);
  const [action, setAction] = useState("");
  const [hasMoreAdBanners, setHasMoreAdBanners] = useState(true);
  const [noAdBannerFound, setNoAdBannerFound] = useState(false);
  const [status, setStatus] = useState("");
  const [type , setType] = useState("");
  const [searchBy, setSearchBy] = useState("id");
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState(Config.UpdatedAtDescending);

  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  const [successModalState, setSuccessModalState] = useState({
    successModalDisplay: false,
    successModalText: "",
  });
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });

  const fetchMoreData = async () => {
    if (searchBy === "id") return;

    setPage(page + 1);
    setNoAdBannerFound(false);
    setHasMoreAdBanners(true);
  };

  useEffect(() => {
    search();
  }, [searchString]);

  useEffect(() => {
    fetchAdBannersData();
  }, [page, status, type, mediaType ,  sortBy]);

  const showCreateAdBannerButton = () => {
    return allowedPermissions.includes(permissions.quickReplyAdbannerCreate);
  };

  const handleMediaTypeChange = (option) => {
    setPage(0);

    setAdBanners([]);
    setMediaType(option.value);
  };

  const handleStatusChange = (option) => {
    setPage(0);

    setAdBanners([]);
    setStatus(option.value);
  };

  const handleTypeChange = (option) => {
    setPage(0);

    setAdBanners([]);
    setType(option.value);
  };

  const handleOrderChange = (sortBy) => {
    setPage(0);

    setAdBanners([]);

    setSortBy(sortBy.value);
  };

  const fetchAdBannersData = async () => {
    try {
      let params = {
        id: searchBy === "id" ? searchString : "",
        name : searchBy === "name" ? searchString : "",
        status: status,
        sortBy: sortBy,
        type : type,
        mediaType : mediaType,
        page: page,
        limit: limit,
      };

      let response = await getAdBanners(params);

      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectionOnTokenExpiry();
        let errorCode = errorDescription(response.errorCode);
        showErrorModal(errorCode);
      } else if (response.adbanners) {
        setAdBanners(response.adbanners);
        setLoading(false);
      }
    } catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  };

  const getIcon = (status) => {
    if (status === "Deactivated") return faUpload;
    return faTimes;
  };

  const performAction = () => {
    if (action === allActions.publish) {
      publish(id);
    }
    if (action === allActions.deactivate) {
      deactivate(id);
    }
    if (action === allActions.activate) {
      activate(id);
    }
  };

  const successModelClose = () => {
    setSuccessModalState((prev) => ({ ...prev, successModalDisplay: false }));
    setAdBanners([]);
    setPage(0);
    setHasMoreAdBanners(true);
    fetchAdBannersData();
  };

  const search = async (localSearchBy) => {
    setPage(0);
    setNoAdBannerFound(false);
    setHasMoreAdBanners(true);
    setSearchBy(localSearchBy || searchBy);
    setLoading(true);
    setAdBanners([]);

    let params = {
      id: searchBy === "id" ? searchString : "",
      name : searchBy === "name" ? searchString : "",
      status: status,
      sortBy: sortBy,
      type : type,
      mediaType : mediaType,
      page: page,
      limit: limit,
    };

    try {
      let response = await getAdBanners(params);
      if (response.adbanners != null) {
        setAdBanners(response.adbanners);
        setLoading(false);
        return;
      }
      if (page === 0) {
        setLoading(false);
        setNoAdBannerFound(true);
      } else {
        setLoading(false);
        setNoAdBannerFound(false);
      }
    } catch (err) {
      setLoading(false);
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const failureModalClose = () => {
    setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
  };

  const showErrorModal = (errorCode) => {
    setFailureModalState({
      failureModalText: errorDescription(errorCode),
      failureModalDisplay: true,
    });
  };

  const showSuccessModal = (text) => {
    setSuccessModalState({
      successModalDisplay: true,
      successModalText: text,
    });
  };

  const renderAllAdBanners = () => {
    if (adBanners.length === 0 && !loading) {
      return <EmptyAdBanners />;
    } else {
      return adBanners.map((adBanner) => {
        let status = getStatus(adBanner.publishedAt, adBanner.deactivatedAt);
        let color = getStatusColor(status);
        let btnColor = getButtonColor(status);
        let btnName = getBtnName(status);
        return (
          <Col md="6" lg="4" key={adBanner.id}>
            <AdBannerCard
              adBanner={adBanner}
              color={color}
              status={status}
              btnName={btnName}
              btnColor={btnColor}
              btnIcon={getIcon(status)}
              getConfirmation={setConfirmationModalState}
            />
          </Col>
        );
      });
    }
  };

  const setConfirmationModalState = (id, event) => {
    setAction(event);
    setConfirmationModalIsOpen(true);
    setId(id);
  };

  const publish = async (id) => {
    try {
      let response = await publishAdBanner(id);
      if (response.status === publishedStatus) {
        showSuccessModal(response.description);
      } else {
        showErrorModal(response.errorDescription);
      }
    } catch (err) {
      showErrorModal(error.unexpectedError);
    }
  };

  const deactivate = async (id) => {
    try {
      let response = await deactivateAdBanner(id);
      if (response.status === deactivatedStatus) {
        showSuccessModal(response.description);
      } else {
        showErrorModal(response.errorDescription);
      }
    } catch (err) {
      showErrorModal(error.unexpectedError);
    }
  };

  const activate = async (id) => {
    try {
      let response = await activateAdBanner(id);
      if (response.status === activatedStatus) {
        showSuccessModal(response.description);
      } else {
        showErrorModal(response.errorDescription);
      }
    } catch (err) {
      showErrorModal(error.unexpectedError);
    }
  };

  const handleSearchStringChange = (event) => {
    event.preventDefault();
    let { value } = event.target;
    setSearchString(value);
    if (!value.length) {
      setSearchString("");
      search();
      return;
    }
  };

  const onKeyDown = (event) => {
    if (event.keyCode === Config.KEYPRESS_DOWN) {
      search();
    }
  };

  return (
    <Container fluid className="p-0">
      <Row>
        <h1 className="mb-3 pl-3">Ad Banners</h1>
        {showCreateAdBannerButton() ? (
          <Link
            className="d-block ml-auto mb-3 pr-3 text-decoration-none"
            to={{
              pathname: `/quick-reply/create-adBanner`,
            }}
          >
            <Button color="primary" className="ml-auto d-flex">
              <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                Create Ad Banner
              </p>
            </Button>
          </Link>
        ) : null}
      </Row>
      <Row>
        <Col md={4} sm={12}>
          <Search
            placeholder="Search"
            value={searchString}
            onChange={(event) => handleSearchStringChange(event)}
            onKeyDown={(event) => onKeyDown(event)}
          ></Search>
        </Col>
        <Col md={3} sm={12}>
          <UncontrolledButtonDropdown className="mr-1 mb-1">
            <DropdownToggle caret color="success">
              {"Search By " + capitalize(searchBy) + " "}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  search("id");
                }}
              >
                ID
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  search("name");
                }}
              >
                Name
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      <Row>
      <Col md={3} sm={12}>
          <Select
            className="react-select-container mb-4"
            classNamePrefix="react-select"
            placeholder="Select Media Type"
            onChange={(option) => handleMediaTypeChange(option)}
            options={[
              { value: "", label: "All" },
              { value: "image", label: "Image" },
              { value: "video", label: "Video" },
            ]}
          />
        </Col>
        <Col md={3} sm={12}>
          <Select
            className="react-select-container mb-4"
            classNamePrefix="react-select"
            placeholder="Select Status"
            onChange={(option) => handleStatusChange(option)}
            options={[
              { value: "", label: "All" },
              { value: "active", label: "Active" },
              { value: "deactivated", label: "Deactivated" },
              { value: "unpublished", label: "Unpublished" },
            ]}
          />
        </Col>
        <Col md={3} sm={12}>
          <Select
            className="react-select-container mb-4"
            classNamePrefix="react-select"
            placeholder="Sort By"
            onChange={(option) => handleOrderChange(option)}
            options={[
              {
                value: Config.UpdatedAtDescending,
                label: "Updated At \u2193",
              },
              {
                value: Config.UpdatedAtAscending,
                label: "Updated At \u2191",
              },
              {
                value: Config.CreatedAtDescending,
                label: "Created At \u2193",
              },
              {
                value: Config.CreatedAtAscending,
                label: "Created At \u2191",
              },
            ]}
          />
        </Col>
        <Col md={3} sm={12}>
          <Select
            className="react-select-container mb-4"
            classNamePrefix="react-select"
            placeholder="Select Type"
            onChange={(option) => handleTypeChange(option)}
            options={[
              { value: "", label: "All" },
              { value: "horizontal", label: "Horizontal" },
              { value: "vertical", label: "Vertical" },
            ]}
          />
        </Col>
      </Row>

      <InfiniteScroll
        className="infinite-scroll"
        dataLength={adBanners.length}
        next={fetchMoreData}
        hasMore={hasMoreAdBanners}
        loader={
          !loading &&
          searchBy !== "id" &&
          !noAdBannerFound && <Spinner color="info" className=" d-flex mx-auto" />
        }
      >
        {loading ? (
          <Spinner color="info" className=" d-flex mx-auto" />
        ) : noAdBannerFound ? (
          <Container
            sm="12"
            md="8"
            lg="6"
            className="mx-auto d-table mt-5 pt-2 pb-5"
          >
            <h1 className="text-center">No Ad Banners Found</h1>
          </Container>
        ) : (
          <React.Fragment>
            <Row className="mt-2">{renderAllAdBanners()}</Row>
            <ResponseModal
              show={successModalState.successModalDisplay}
              onHide={() => successModelClose()}
              modalheading={successModalHeading}
              modaltext={successModalState.successModalText}
            />
            <ResponseModal
              show={failureModalState.failureModalDisplay}
              onHide={() => failureModalClose()}
              modalheading={failureModalHeading}
              modaltext={failureModalState.failureModalText}
            />
            <ConfirmationModal
              show={confirmationModalIsOpen}
              modaltext={action}
              onHide={() => {
                setConfirmationModalIsOpen(false);
              }}
              onConfirmationTrue={() => {
                setConfirmationModalIsOpen(false);
                performAction();
              }}
            />
          </React.Fragment>
        )}
      </InfiniteScroll>
    </Container>
  );
};

export default AdBanners;

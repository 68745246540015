import { retrieveFromLocalStorage } from "../utilities/localStorageUtil";
import { permissions } from "../utilities/commonUtil";

const allowedPermissions = retrieveFromLocalStorage("userPermissions");

export let isMixingStrategyRatiosCreatePermission = () => {
  return allowedPermissions.includes(permissions.mixingStrategyRatiosCreate);
};

export let isMixingStrategyRatiosEditPermission = () => {
  return allowedPermissions.includes(permissions.mixingStrategyRatiosEdit);
};

export let isMixingStrategyRatiosDeletePermission = () => {
  return allowedPermissions.includes(permissions.mixingStrategyRatiosDelete);
};

export const allActions = {
  delete: "Delete"
};

export const mixingStrategyRatiosResponses = {
  updatedSuccessfully: "Mixing Strategy Ratio updated successfully",
  addedSuccessfully: "Mixing Strategy Ratio added successfully",
};
import React, { useState, useEffect } from "react";
import ResponseModal from "../ResponseModal";
import DateTimePicker from 'react-datetime-picker'
import Select from "react-select";
import { FaMinus } from "react-icons/fa";
import {
  errorDescription,
  error,
  redirectOnTokenExpiry,
  redirectTo,
  validateInteger, permissions
} from "../../../utilities/commonUtil";
import { updateContentCategory, getContentCategoryDetail } from "../../../utilities/apiUtils/cre";
import { getUTMCampaigns } from "../../../utilities/apiUtils/users";
import TagsInput from "react-tagsinput";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Container
} from "reactstrap";
import {
  successModalHeading,
  errorModalHeading,
} from "../../../config/config";
import {
  getLanguages
} from "../../../utilities/apiUtils/stickers";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";

const EditCategoryForm = (props) => {
  const [form, setForm] = useState({
    title: "",
    contentTypes: [],
    priority: { value: 1, valid: true },
    image: null,
    imageSrc: "",
    startTime: Math.round((new Date()).getTime() / 1000).toString(),
    endTime: Math.round((new Date()).getTime() / 1000).toString(),
    tags: [],
    utmCampaigns: null,
    firstLoadLimit: { value: 1, valid: true },
    limit: { value: 1, valid: true }
  });

  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [translations, setTranslations] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [showTranslations, setShowTranslations] = useState(false);
  const [utmCampaignOptions, setUtmCampaignOptions] = useState([]);

  let id = props.match.params.id;
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );
  // Update allowed permissions for the user
  let updateAllowed = () => {
    return allowedPermissions.includes(permissions.contentCategoryUpdate);
  };
  useEffect(() => {
    const _getLanguages = async () => {
      let response = await getLanguages();
      if (!response?.errorCode) {
        setLanguages(response);
        setShowTranslations(true);
      }
      else {
        let text = errorDescription(response.errorCode);
        setLoading(false);
        showErrorModal(text);
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectOnTokenExpiry();
      }
    };

    _getLanguages();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const utmCampaigns = await getUTMCampaigns();
      setUtmCampaignOptions(utmCampaigns);
      fetchCategoriesData(utmCampaigns);
    };
    
    let fetchCategoriesData = async (utmCampaigns) => {
      try {
        let response = await getContentCategoryDetail(id);
        if (!response.errorCode) {
          let arrTags = []
          if (response.contentCategory && response.contentCategory.tags) {
            response.contentCategory.tags.map((obj) => {
              arrTags.push(obj.text)
            });
          }

          let arrUtmCampaigns = []
          if (response.contentCategory && response.contentCategory.utmCampaigns) {
            response.contentCategory.utmCampaigns.map((obj) => {
              arrUtmCampaigns.push(obj)
            });
          }

          setLastEndTimestamp(response.contentCategory.endTime)
          setLastStartTimestamp(response.contentCategory.startTime)

          if (response.contentCategory.translations && response.contentCategory.translations.length > 0) {
            let translation = []
            let index = 0;
            response.contentCategory.translations.map((trans) => {
              languages.map((lang) => {
                if (lang.value === trans.languageCode) {
                  translation.push({ id: index, languageCode: lang.value, name: trans.name, dropdownValue: lang })
                }
              })
              index++;
            });
            setTranslations(translation);
          }

          let contentType = []
          response.contentCategory.contentTypes && response.contentCategory.contentTypes.map((content) => {
            contentType.push(content.contentType);
          });

          setForm({
            ...form,
            title: response.contentCategory.name,
            contentTypes: contentType,
            priority: { value: response.contentCategory.priority, valid: true },
            imageSrc: response.contentCategory.iconImageURL,
            startTime: Math.round((new Date(response.contentCategory.startTime)).getTime() / 1000).toString(),
            endTime: Math.round((new Date(response.contentCategory.endTime)).getTime() / 1000).toString(),
            tags: arrTags,
            utmCampaigns: arrUtmCampaigns.length > 0 ? utmCampaigns.find(campaign => campaign.value === arrUtmCampaigns[0]) : null,
            limit: { value: response.contentCategory.limit, valid: true },
            firstLoadLimit: { value: response.contentCategory.firstLoadLimit, valid: true },
            image: response.contentCategory.iconImageURL,
          });

          let arr = [];
          contentType.map((content) => {
            allContentTypes.map((c) => {
              if (content === c.value) {
                arr.push({ name: c.name, value: c.value });
              }
            })
          })
          setSelectedContent(arr);
          setShowForm(true);
          setLoading(false)
        } else {
          let text = errorDescription(response.errorCode);
          setLoading(false);
          showErrorModal(text);
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken
          )
            redirectOnTokenExpiry();
        }
      } catch (err) {
        setLoading(false);
        setFailureModalDisplay(true);
        setFailureModalText(errorDescription(error.unexpectedError));
      }

      setShowForm(true);
    };
    fetchData();
  }, [showTranslations])

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  const [lastStartTimestamp, setLastStartTimestamp] = useState(new Date());
  const [lastEndTimestamp, setLastEndTimestamp] = useState(new Date());
  const [displayError, setDisplayError] = useState(false);
  const [selectedContent, setSelectedContent] = useState([]);

  const allContentTypes = [
    { name: "Sticker", value: "sticker" },
    { name: "Animated Sticker", value: "animatedSticker" },
    { name: "Movie GIF", value: "movieGif" },
    { name: "Pop Text", value: "popTextStyle" },
    { name: "Emoji Sticker", value: "emojiSticker" },
    { name: "Premium Sticker", value: "premiumSticker" },
  ];

  const handleTranslationChange = (id, event, languageCode) => {
    const newTranslations = translations.map(i => {
      if (id === i.id) {
        if (languageCode !== null) {
          i["languageCode"] = languageCode.value;
        }
        else {
          i[event.target.name] = event.target.value
        }
      }
      return i;
    })

    setTranslations(newTranslations);
  }

  const handleAddTranslation = () => {
    if (translations.length > 0) {
      setTranslations([...translations, { id: translations[translations.length - 1].id + 1, languageCode: "", name: "" }])
    }
    else {
      setTranslations([...translations, { id: 0, languageCode: "", name: "" }]);
    }
  }

  const handleRemoveTranslation = (id) => {
    const values = [...translations];
    values.splice(values.findIndex(value => value.id === id), 1);
    setTranslations(values);
  }

  const [successModal, setSuccessModal] = useState({
    display: false,
    text: "",
  });
  const [failureModal, setFailureModal] = useState({
    display: false,
    text: "",
  });

  let handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  let successModalClose = () => {
    setSuccessModal({ ...successModal, display: false });
    redirectTo("/cre/content-categories");
  };

  let failureModalClose = () => {
    setFailureModal({ ...failureModal, display: false });
  };

  const handlePriorityChange = (event) => {
    const { name, value } = event.target;
    setForm((prev) => ({
      ...prev,
      [name]: {
        value: value,
        valid: validateInteger(value) && parseInt(value) !== 0,
      },
    }));
    setDisplayError(false);
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setForm((prev) => ({
      ...prev,
      [name]: files[0],
    }));

    event.persist();
    // For showing Image preview
    if (files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        if (name === "image") {
          setForm((prev) => ({
            ...prev,
            imageSrc: reader.result,
          }));
          document.getElementById("image").innerHTML = files[0].name;
        }
      };
    }
  };

  const handleTags = (tags) => {
    setForm((prev) => ({
      ...prev,
      ["tags"]: tags,
    }));
    setDisplayError(false);
  };

  const handleLimitChange = (event) => {
    const { name, value } = event.target;
    setForm((prev) => ({
      ...prev,
      [name]: {
        value: value,
        valid: validateInteger(value) && parseInt(value) !== 0,
      },
    }));
    setDisplayError(false);
  };

  const handleFirstLoadLimitChange = (event) => {
    const { name, value } = event.target;
    setForm((prev) => ({
      ...prev,
      [name]: {
        value: value,
        valid: validateInteger(value) && parseInt(value) !== 0,
      },
    }));
    setDisplayError(false);
  };

  const handleContentTypesChange = (selectedContent) => {
    let contentType = [];
    if (selectedContent !== null) {
      selectedContent.forEach((content) => {
        contentType.push(content.value);
      });
    }
    setForm({ ...form, contentTypes: contentType });
  };

  const handleStartTime = (obj) => {
    setLastStartTimestamp(obj)
    setForm({ ...form, startTime: Math.round(obj.getTime() / 1000).toString() });
  }

  const handleEndTime = (obj) => {
    setLastEndTimestamp(obj)
    setForm({ ...form, endTime: Math.round(obj.getTime() / 1000).toString() });
  }


  let handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let arrTags = []
      if (form.tags) {
        form.tags.map((t) => {
          let obj = { text: t }
          arrTags.push(obj)
        });
      }

      let arrUtmCampaigns = []
      if (form.utmCampaigns) {
        arrUtmCampaigns.push(form.utmCampaigns.value)
      }

      let content = form.contentTypes;

      let formData = new FormData();
      formData.append("name", form.title);
      formData.append("priority", parseInt(form.priority.value));
      formData.append("startTime", form.startTime);
      formData.append("endTime", form.endTime);
      formData.append("firstLoadLimit", parseInt(form.firstLoadLimit.value));
      formData.append("limit", parseInt(form.limit.value));
      formData.append("utmCampaigns", arrUtmCampaigns.join());
      if (form.image !== undefined) {
        formData.append("iconImage", form.image)
      }
      if (arrTags) {
        let index = 0;
        arrTags.map((tags) => {
          formData.append(`tags[${index}].text`, tags.text)
          index++;
        });
      }
      if (translations.length > 0) {
        translations.map((t) => {
          if (t.languageCode !== "" && t.name !== "") {
            formData.append(`translations[${t.id}].languageCode`, t.languageCode)
            formData.append(`translations[${t.id}].name`, t.name)
          }
        });
      }
      if (content !== []) {
        let index = 0;
        content.map((c) => {
          formData.append(`contentTypes[${index}].contentType`, c)
          index++;
        });
      }
      let response = await updateContentCategory(formData, id);
      if (response.status === "success") {
        setSuccessModal({ display: true, text: response.description });
      } else {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectOnTokenExpiry();
        setFailureModal({
          display: true,
          text: errorDescription(response.errorCode),
        });
      }

    }
    catch (error) {
      setFailureModal({
        display: true,
        text: errorDescription(error.unexpectedError),
      });
    }
  };

  return (
    <Container fluid className="p-0">
      <Row className="mb-3">
        <Col md={6} xs={4}>
          <h1>Edit Content Category</h1>
        </Col>
      </Row>
      {showForm ?
        <Card>
          <CardBody>
            <Form onSubmit={handleSubmit}>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      Category Name<span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      name="title"
                      value={form.title != "" ? form.title : ""}
                      placeholder={"Category Name"}
                      onChange={handleChange}
                      required
                      disabled={!updateAllowed()}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>
                      <strong>
                        Priority<span className="text-danger">*</span>
                        <small className="text-info">
                          (Enter integer value greater than 0)
                        </small>
                      </strong>
                    </Label>
                    <Input
                      type="number"
                      name="priority"
                      defaultValue={form.priority.value ? form.priority.value : 1}
                      className={
                        form.priority.valid
                          ? "form-control is-valid"
                          : "form-control is-invalid"
                      }
                      onChange={(event) => handlePriorityChange(event)}
                      disabled={!updateAllowed()}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <Label>
                      <strong>
                        Image
                        <small className="text-info">
                          (Choose an image file)
                        </small>
                      </strong>
                    </Label>
                    <Container className="custom-file">
                      <Input
                        type="file"
                        className="custom-file-input"
                        accept="false/jpeg"
                        name="image"
                        onChange={(event) => handleFileChange(event)}
                        disabled={!updateAllowed()}
                      />
                      <Label className="custom-file-label" id="image">
                        <strong>Choose an image</strong>
                      </Label>
                    </Container>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                {form.imageSrc !== "" &&
                  form.imageSrc !== null && form.imageSrc !== undefined ? (
                  <Col className="pb-4">
                    <img
                      src={form.imageSrc}
                      alt="previewImage"
                    />
                  </Col>
                ) : (
                  <Col></Col>
                )}
              </Row>
              <Row>
                <Col md={6}>
                  <Label>
                    <strong>
                      Start Time<span className="text-danger">*</span>
                    </strong>
                  </Label>
                  <br></br>
                  <DateTimePicker
                    amPmAriaLabel="Select AM/PM"
                    calendarAriaLabel="Toggle calendar"
                    dayAriaLabel="Day"
                    hourAriaLabel="Hour"
                    maxDetail="second"
                    minuteAriaLabel="Minute"
                    monthAriaLabel="Month"
                    nativeInputAriaLabel="Date and time"
                    onChange={(obj) => handleStartTime(obj)}
                    secondAriaLabel="Second"
                    value={typeof lastStartTimestamp === "string" ? new Date(lastStartTimestamp) : lastStartTimestamp}
                    yearAriaLabel="Year"
                    required
                    disabled={!updateAllowed()}
                  />
                </Col>
                <Col md={6}>
                  <Label>
                    <strong>
                      End Time<span className="text-danger">*</span>
                    </strong>
                  </Label>
                  <br></br>
                  <DateTimePicker
                    amPmAriaLabel="Select AM/PM"
                    calendarAriaLabel="Toggle calendar"
                    dayAriaLabel="Day"
                    hourAriaLabel="Hour"
                    maxDetail="second"
                    minuteAriaLabel="Minute"
                    monthAriaLabel="Month"
                    nativeInputAriaLabel="Date and time"
                    onChange={(obj) => handleEndTime(obj)}
                    secondAriaLabel="Second"
                    value={typeof lastEndTimestamp === "string" ? new Date(lastEndTimestamp) : lastEndTimestamp}
                    yearAriaLabel="Year"
                    required
                    disabled={!updateAllowed()}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      <strong>
                        Limit
                        <small className="text-info">
                          (Enter integer value greater than 0)
                        </small>
                      </strong>
                    </Label>
                    <Input
                      type="number"
                      name="limit"
                      defaultValue={form.limit.value}
                      className={
                        form.limit.valid
                          ? "form-control is-valid"
                          : "form-control is-invalid"
                      }
                      onChange={(event) => handleLimitChange(event)}
                      disabled={!updateAllowed()}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      <strong>
                        First Load Limit
                        <small className="text-info">
                          (Enter integer value greater than 0)
                        </small>
                      </strong>
                    </Label>
                    <Input
                      type="number"
                      name="firstLoadLimit"
                      defaultValue={form.firstLoadLimit.value}
                      className={
                        form.firstLoadLimit.valid
                          ? "form-control is-valid"
                          : "form-control is-invalid"
                      }
                      onChange={(event) => handleFirstLoadLimitChange(event)}
                      disabled={!updateAllowed()}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <FormGroup>
                    <Label>
                      <strong>Tags</strong>
                    </Label>
                    <TagsInput
                      value={form.tags}
                      onChange={(tags) => handleTags(tags)}
                      inputProps={{
                        disabled: !updateAllowed()
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <FormGroup>
                    <Label>Content Types</Label>
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="Content Types"
                      defaultValue={selectedContent}
                      onChange={handleContentTypesChange}
                      options={allContentTypes}
                      getOptionLabel={(options) => options['name']}
                      getOptionValue={(options) => options['value']}
                      isMulti
                      isDisabled={!updateAllowed()}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <FormGroup>
                    <Label>
                      <strong>UTM Campaign</strong>
                    </Label>
                    <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={utmCampaignOptions}
                        placeholder="Select UTM Campaign"
                        value={form.utmCampaigns}
                        isClearable
                        onChange={(value) => {
                          setForm((prevState) => ({
                            ...prevState,
                            utmCampaigns: value
                          }))
                        }
                        }
                        isDisabled={!updateAllowed()}
                      />
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{ marginBottom: 10 }}>
                <Col>
                  <strong>Translations</strong>
                </Col>
              </Row>
              {translations
                ? translations.map(translation => {
                  return (
                    <Row key={translation.id}>
                      <Col md={4}>
                        <FormGroup>
                          <Label>
                            Target Language
                          </Label>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            name="languageCode"
                            placeholder="Select Languages"
                            options={languages}
                            defaultValue={translation.dropdownValue}
                            onChange={(option) => handleTranslationChange(translation.id, null, option)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label>
                            Translation Text
                          </Label>
                          <Input
                            type="text"
                            name="name"
                            value={translation.name}
                            placeholder="Translation"
                            onChange={(event) => handleTranslationChange(translation.id, event, null)}
                            required
                            disabled={!updateAllowed()}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2} style={{ display: "flex", justifyContent: "center" }}>
                        <Button onClick={() => handleRemoveTranslation(translation.id)} color="transparent">
                          <FaMinus />
                        </Button>
                      </Col>
                    </Row>
                  );
                })
                : {}}
              <Row>
                <Col>
                  <Button onClick={handleAddTranslation} disabled={!updateAllowed()}>Add Translations</Button>
                </Col>
              </Row>
              {displayError ? (
                <p className="text-danger mb-0">
                  Please enter all valid details.
                </p>
              ) : null}
              <Button color="primary" style={{ marginTop: 20 }} disabled={!updateAllowed()}>Submit</Button>
            </Form>
            <ResponseModal
              show={successModal.display}
              onHide={successModalClose}
              modalheading={successModalHeading}
              modaltext={"Category Successfully Updated"}
            />
            <ResponseModal
              show={failureModal.display}
              onHide={failureModalClose}
              modalheading={errorModalHeading}
              modaltext={failureModal.text}
            />
          </CardBody>
        </Card>
        : null
      }
    </Container>
  );
};

export default EditCategoryForm;

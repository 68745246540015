import React from "react";
import { Badge, Button } from "reactstrap";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import { showViewStatisticsButton } from "../../../config/configTaasDashboard";


const statusFormatter = (cell) => {
  if (new Date(cell) > new Date() || cell === null) {
    return <Badge className="badge-success">{"Activated"}</Badge>;
  } else {
    return <Badge className="badge-danger">{"Deactivated"}</Badge>;
  }
};
const statisticsFormatter = (id) => {
  return <>
    {showViewStatisticsButton() ?
      <Link to={{ pathname: `/taas/clients/${id}/statistics` }}><Button>View Statistics</Button></Link> : <span data-tip data-for={"stats-disabled-button"}>
        <Button disabled={true}>View Statistics</Button>
        <ReactTooltip id={"stats-disabled-button"} place="top" effect="solid">
          You don't have the access to view this.
        </ReactTooltip>
      </span>
    }
  </>
}
export const clientTableColumns = [
  {
    dataField: "id",
    text: "#",
    headerAttrs: { width: 60 },
    searchable: false,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "name",
    text: "Name",
    searchable: true,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "deactivatedAt",
    text: "Status",
    searchable: false,
    style: {
      cursor: "pointer",
    },
    formatter: statusFormatter,
  },
  {
    dataField: "id",
    text: "Statistics",
    searchable: false,
    style: {
      cursor: "pointer",
    },
    formatter: statisticsFormatter,
  },
];

import React, { useState } from "react";
import { retrieveFromLocalStorage } from "../../../../utilities/localStorageUtil";
import { Config } from "../../../../config";
import { Link } from "react-router-dom";
import {
  getStatus,
  getStatusColor,
  getBtnName,
  getButtonColor,
  permissions,
} from "../../../../utilities/commonUtil";
import {
  Badge,
  Card,
  Container,
  CardImg,
  CardBody,
  CardTitle,
  Button,
  Row,
  Col,
} from "reactstrap";

export const ThemeCategoryCard = (props) => {
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );
  const [category] = useState(props.category);

  const showDetailsButton = () => {
    return allowedPermissions.includes(permissions.keyboardThemeCategoryDetail);
  };

  const showEditUserAttributeButton = () => {
    return allowedPermissions.includes(permissions.keyboardThemeCategoryRuleView);
  };

  const showButton = (btnName) => {
    if (btnName === Config.ACTIVATE_BUTTON)
      return allowedPermissions.includes(
        permissions.keyboardThemeCategoryActivate
      );
    else if (btnName === Config.DEACTIVATE_BUTTON)
      return allowedPermissions.includes(
        permissions.keyboardThemeCategoryDeactivate
      );
    else if (btnName === Config.PUBLISH_BUTTON)
      return allowedPermissions.includes(
        permissions.keyboardThemeCategoryPublish
      );
    else return false;
  };

  let status = getStatus(category.publishedAt, category.deactivatedAt);

  return (
    <Card>
      <React.Fragment>
        {category.bannerImage ? (
          <CardImg src={category.bannerImage} alt="Card image" />
        ) : (
          <div className="theme-preview-image bg-dark"></div>
        )}
      </React.Fragment>
      <CardBody>
        <CardTitle tag="h3" className="mb-0">
          {category.name}
          <Badge className="float-right" color={getStatusColor(status)}>
            {status}
          </Badge>
        </CardTitle>
        <small>ID : {category.ID}</small>
        <br />
        <strong className="font-weight-bold">{category.name}</strong>
        <br />
        <small className="font-weight-bold">
          Created At : {category.createdAt}
        </small>
        <br />
        <small className="font-weight-bold">
          Updated At : {category.updatedAt}
        </small>
        <br />
        <small className="font-weight-bold">
          Published At : {category.publishedAt}
        </small>
        <br />
        <small className="font-weight-bold">
          Deactivated At : {category.deactivatedAt}
        </small>
        <CardBody className="px-4">
          <Container className="text-center" fluid>
            <Row>
              <Col sm="12" md="12">
                {showDetailsButton() ? (
                  <Link
                    className="text-decoration-none"
                    to={{
                      pathname: `/keyboard-theme/keyboard-theme-categories/${category.ID}`
                    }}>
                    <Button
                      className="m-2"
                      color="info"
                    >
                      Details
                    </Button>
                  </Link>
                ) : null}
                {showButton(getBtnName(status)) ? (
                  <Button
                    className="m-2"
                    color={getButtonColor(status)}
                    onClick={() => {
                      props.getConfirmation(category.ID, getBtnName(status));
                    }}
                  >
                    {getBtnName(status)}
                  </Button>
                ) : null}
                {showEditUserAttributeButton() ? (
                  <Link
                    className="text-decoration-none"
                    to={{
                      pathname: `/keyboard-theme/keyboard-theme-categories/user-attribute-rules/${category.ID}`
                    }}>
                    <Button
                      className="m-2"
                    >
                      Edit User Attribute Rules
                    </Button>
                  </Link>) : null}
              </Col>
            </Row>
          </Container>
        </CardBody>
      </CardBody>
    </Card >
  );
};

export default ThemeCategoryCard;

import React, { Fragment, useState, useEffect } from "react";
import ResponseModal from "../ResponseModal";
import {
  createQuickReplyCategory,
  updateQuickReplyCategory,
  getQuickReplyCategoryDetail,
  reorderAdBanners,
} from "../../../utilities/apiUtils/quickReplies";

import { getClients } from "../../../utilities/apiUtils/stickers";

import QuickReplyCategoryForm from "./QuickReplyCategoryForm";
import Routes from "../../../routes/index";
import {
  errorDescription,
  error,
  redirectTo,
  redirectionOnTokenExpiry,
} from "../../../utilities/commonUtil";
import {
  titleAlignmentOptions, 
  quickRepliesPageType,
  successModalHeading,
  failureModalHeading,
  quickReplyCreatedSuccessfullyText,
  quickReplyUpdatedSuccessfullyText,
} from "../../../config/quickReplies";

const QuickReplyCategoryFormContainer = (props) => {
  const [formTextState, setFormTextState] = useState({
    formName: "Create Quick Reply Category",
    name: "",
    providerName: "",
    metadata: [],
    viewType: { value: "text", label: "Text"},
    priority: { value: 1, valid: true },
    translations: [],
    horizontal: false,
    vertical: false,
    adBanners: [],
  });
  const [showForm, setShowForm] = useState(false);
  const [successModalState, setSuccessModalState] = useState({
    successModalDisplay: false,
    successModalText: "",
  });
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });

  useEffect(() => {
    const getQuickReply = async () => {
      let ID = props.match.params.categoryID;

      if (ID) {
        try {
          let response = await getQuickReplyCategoryDetail(ID);

          let clients = await getClients();
          
          let adBanners = [];
          if (response) {

            let viewType =  quickRepliesPageType.find((option) => option.value === response.viewType);

            if(response.adBanners !== undefined)
              adBanners = response.adBanners;

            if (response?.metadata) {
              response.metadata.forEach((metadata) => {
                  if (metadata?.bannerSettings?.horizontal) {
                    metadata.horizontal = true;
                    // update bannerSettings 
                    if(metadata?.bannerSettings?.horizontal?.titleAlignment) {
                      metadata.bannerSettings.horizontal.titleAlignment = titleAlignmentOptions.find((option) => option.value === metadata.bannerSettings.horizontal.titleAlignment);
                    }
                  }

                  if (metadata?.bannerSettings?.vertical) {
                    metadata.vertical = true;
                    // update bannerSettings 
                    if(metadata?.bannerSettings?.vertical?.titleAlignment) {
                      metadata.bannerSettings.vertical.titleAlignment = titleAlignmentOptions.find((option) => option.value === metadata.bannerSettings.vertical.titleAlignment);
                    }
                  }

                  clients.forEach((client) => {
                    if (metadata?.clientId === client?.value) {
                      metadata.clientId = client;
                    }
                  });

              });
            }

          
            setFormTextState({
              formName: "Edit Quick Reply Category",
              name: response.name,
              metadata: response.metadata,
              providerName: response.providerName,
              priority: { value: response.priority, valid: true },
              translations: response.translations,
              adBanners: adBanners,
              viewType: viewType,
            });
            setShowForm(true);
            return;
          }
          // Handle error
          if (response.errorCode) {
            if (
              response.errorCode === error.tokenExpired ||
              response.errorCode === error.invalidAccessToken
            )
              redirectionOnTokenExpiry();
            setFailureModalState({
              filureModalDisplay: true,
              failureModalText: errorDescription(response.errorCode),
            });
          }
        } catch (err) {
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: errorDescription(error.unexpectedError),
          });
        }
      }
      setShowForm(true);
    };

    getQuickReply();
  }, []);

  const onSubmit = async (replyObj) => {
    // iterate over metadata and remove bannerSettings if vertical and horizontal are not selected
    replyObj.metadata.forEach((metadata) => { 
      if (metadata?.vertical === false && metadata?.horizontal === false)
        delete metadata?.bannerSettings;

      // remove vertical and horizontal if not selected
      if (!metadata?.horizontal){
        delete metadata?.bannerSettings?.horizontal;
        delete metadata?.horizontal;
      }

      if (!metadata?.vertical){
        delete metadata?.bannerSettings?.vertical;
        delete metadata?.vertical;
      }
        
    });


    replyObj.viewType = replyObj?.viewType?.value;
    if (formTextState.formName === "Create Quick Reply Category") {
      try {
        let response = await createQuickReplyCategory(replyObj);
        if (response.errorCode) {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken
          ) {
            redirectionOnTokenExpiry();
            setFailureModalState({
              failureModalDisplay: true,
              failureModalText: errorDescription(response.errorCode),
            });
            return;
          }
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: response.errorDescription,
          });
        } else {
          setSuccessModalState({
            successModalDisplay: true,
            successModalText: quickReplyCreatedSuccessfullyText,
          });
        }
      } catch (err) {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      }
    } else {
      replyObj["categoryID"] = parseInt(props.match.params.categoryID);
      try {
        let response = await updateQuickReplyCategory(
          replyObj,
          props.match.params.categoryID
        );
        if (response.errorCode) {
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: response.errorDescription,
          });
        } else {
          setSuccessModalState({
            successModalDisplay: true,
            successModalText: quickReplyUpdatedSuccessfullyText,
          });
        }
      } catch (err) {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      }
    }
  };

  const failureModalClose = () => {
    setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
  };

  const successModalClose = () => {
    setSuccessModalState((prev) => ({ ...prev, successModalDisplay: false }));
    redirectTo(Routes.quickReplies.children.quickReplyCategories.path);
  };

  const onAdBannerIDSubmit = async (adBanners) => {
    try {
      let response = await reorderAdBanners(
        adBanners,
        props.match.params.categoryID
      );
      if (response.errorCode) {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: response.errorDescription,
        });
      }

      setSuccessModalState({
        successModalDisplay: true,
        successModalText: response.description,
      });
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  return (
    <Fragment>
      {showForm ? (
        <QuickReplyCategoryForm
          category={formTextState}
          submit={onSubmit}
          submitPriority={onAdBannerIDSubmit}
          updateAllowed={props.updateAllowed}
        />
      ) : null}
      <ResponseModal
        show={failureModalState.failureModalDisplay}
        onHide={() => failureModalClose()}
        modalheading={failureModalHeading}
        modaltext={failureModalState.failureModalText}
      />
      <ResponseModal
        show={successModalState.successModalDisplay}
        onHide={() => successModalClose()}
        modalheading={successModalHeading}
        modaltext={successModalState.successModalText}
      />
    </Fragment>
  );
};

export default QuickReplyCategoryFormContainer;

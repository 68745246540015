import React from "react";
import { Badge } from "reactstrap";
import {
  selectFilter,
  numberFilter,
  Comparator,
} from "react-bootstrap-table2-filter";

let createTableColumn = (inputDataField, inputText, searchable) => {
  let column = {
    dataField: inputDataField,
    text: inputText,
    headerAttrs: { width: 150 },
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
    searchable: searchable,
  };
  return column;
};

const statusOptions = {
  Enabled : "Enabled",
  Created: "Created",
  Disabled : "Disabled",
};
let statusFilter;

const tableColumns = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    headerAttrs: { width: 60 },
    searchable: false,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "name",
    text: "Tag Set Name",
    sort: true,
    headerAttrs: { width: 200 },
    searchable: true,
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  createTableColumn("priority", "Priority", true),
  createTableColumn("boostHours","Boost Hours",true),
  {
    dataField: "status",
    text: "Status",
    headerAttrs: { width: 100 },
    sort: true,
    formatter: (cell) => {
      if ({ cell }.cell === "Enabled")
        return <Badge className="badge-success">{cell}</Badge>;
      else if ({ cell }.cell === "Disabled")
        return <Badge className="badge-danger">{cell}</Badge>;
      else if ({ cell }.cell === "Created")
        return <Badge className="badge-primary">{cell}</Badge>;
    },
    filter: selectFilter({
      getFilter: (filter) => {
        statusFilter = filter;
      },
      options: statusOptions,
      style: {
        display: "none",
      },
    }),
  },
];

export {
  tableColumns,
  statusFilter,
};

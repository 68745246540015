import React, { useState, useEffect } from "react";
import TargetingRules from "../TargetingRules";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { permissions } from "../../../utilities/commonUtil";
import { getClients } from "../../../utilities/apiUtils/stickers";
import {
    getCountries,
    getRegions,
    getCities,
} from "../../../utilities/apiUtils/stories";
import { getStoriesData, getStoryDetailsData } from "../../../utilities/apiUtils/stories";
import { getRules, saveRules } from "../../../utilities/apiUtils/popTextStyles";
import { Alert } from "react-bootstrap";
import { Row, Col, Card, CardBody, CardImg } from "reactstrap";
import { useHistory } from "react-router-dom";
import { fetchStoryRuleSets, updateStoryRuleSets } from "../../../utilities/apiUtils/stories";

const StoriesRules = (props) => {
    let history = useHistory();
    const [stories, setStories] = useState([]);

    const queryParams = new URLSearchParams(window.location.search);
    const storiesIds = queryParams.get("storyIds");
    const [storyCount, setStoryCount] = useState(0);
    const [storyPreviews, setStoryPreviews] = useState([]);
    const [allowedPermissions] = useState(
        retrieveFromLocalStorage("userPermissions")
    );

    const updateRulesAllowed = () => {
        return allowedPermissions.includes(permissions.storiesTargetingRuleCreatePermission);
    };


    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const storiesIds = queryParams.get("storyIds");
        if (storiesIds) {
            const storyIdsArray = storiesIds.split(','); // Assuming comma-separated IDs
            setStoryCount(storyIdsArray.length);

            // Fetch previews for up to 20 stories
            const idsForPreview = storyIdsArray.slice(0, 20);
            fetchStoryPreviews(idsForPreview);  // You'll need to implement this function
        }
    }, []);
    const fetchStoryPreviews = async (idsArray) => {
        // Implement logic to fetch story previews based on the ids in idsArray 
        // ... (Assume you have API calls to get previews)
        const previews = await Promise.all(idsArray.map(id => getStoryDetailsData(id)));
        setStoryPreviews(previews);
    };
    return (
        <div>
            <h3>Stories</h3>

            <Row>
                {storyPreviews.map((story) => {
                    return (
                        <Col key={story.id} md={2} lg={2}>
                            <Card className="importPageCard">
                                <CardBody>
                                    {story.contentType === "video" ? (
                                        // Render a video element
                                        <video
                                            src={story.videoPreviewURL || "/path/to/fallback-video.mp4"}
                                            alt="Video Preview"
                                            width="100%"
                                            height="100%"
                                            controls
                                        >
                                            Your browser does not support the video tag.
                                        </video>
                                    ) : (
                                        <CardImg
                                            top
                                            src={
                                                story.contentType === "video"
                                                    ? story.videoPreviewURL || "/img/fallback-video.jpg" // Video fallback
                                                    : story.contentType === "animated"
                                                        ? story.animatedPreviewURL || "/img/fallback-animated.gif" // Animated fallback
                                                        : story.staticPreviewURL || "/img/fallback-image.jpg" // Static fallback
                                            }
                                            style={{ width: "100%", height: "100%" }}
                                            onLoad={(e) => {
                                                // You can remove the onLoad logic if no longer needed 
                                            }}

                                            alt={
                                                story.contentType === "animated"
                                                    ? "Animated Preview"
                                                    : "Static Preview"
                                            }
                                        />
                                    )}

                                    <br></br>
                                    ID: {story.id}
                                </CardBody>
                            </Card>
                        </Col>
                    )
                })}
            </Row>

            {/* Info section  */}
            <Alert variant="info">
                Note: Only previews for up to 20 stories are shown here.
            </Alert>
            <TargetingRules
                showAgeGenderRules={true}
                id={storiesIds}
                showClientRules={true}
                showHardFiltering={false}
                getRules={fetchStoryRuleSets}
                saveRules={async (rules, id, data) => {
                    if (id.includes(",")) {
                        const idsArray = id.split(","); // Split comma-separated IDs into an array
                        for (const individualId of idsArray) {
                            try {
                                await updateStoryRuleSets(rules, individualId, data);
                                // Assuming updateStoryRuleSets can handle individual IDs
                            } catch (error) {
                                console.error(`Error updating rules for ID ${individualId}:`, error);
                                // Handle error for individual updates
                            }
                        }
                    } else {
                        // Original logic for single ID
                        await updateStoryRuleSets(rules, id, data);
                    }
                }}
                getClients={getClients}
                getCountries={getCountries}
                getRegions={getRegions}
                getCities={getCities}
                updateRulesAllowed={updateRulesAllowed()}
            />
        </div>
    );
};

export default StoriesRules;

import React, { Component } from "react";
import { Input } from "reactstrap";
class Search extends Component {
  render() {
    return (
      <React.Fragment>
        <Input
          className="form-control mb-4"
          placeholder={this.props.placeholder}
          value={this.props.value}
          onChange={this.props.onChange}
          onKeyDown={this.props.onKeyDown}
        ></Input>
      </React.Fragment>
    );
  }
}

export default Search;

import React, { Component } from "react";
import ResponseModal from "../ui-elements/ResponseModal";
import { verifyLogin } from "../../utilities/apiUtils/index";
import Routes from "../../routes/index";
import { redirectTo } from "../../utilities/commonUtil";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  ButtonToolbar,
} from "reactstrap";
import { retrieveFromLocalStorage } from "../../utilities/localStorageUtil";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      incorrectCredentialsModalDisplay: false,
    };
  }

  handleChange = (event) => {
    let newState = {
      [event.target.name]: event.target.value,
    };
    this.setState(newState);
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let token = await verifyLogin(this.state.username, this.state.password);
    if (token) {
      let url = retrieveFromLocalStorage("url")
      if (url && url !== '/') {
        redirectTo(url);
      }
      else {
        redirectTo(Routes.dashboard.path);
      }
    } else {
      let newState = {
        incorrectCredentialsModalDisplay: true,
        password: "",
      };
      this.setState(newState);
    }
  };

  render() {
    let newState = { incorrectCredentialsModalDisplay: false };
    let modalClose = () => this.setState(newState);

    return (
      <React.Fragment>
        <Card>
          <div className="text-center mt-5">
            <h2>SIGN IN</h2>
          </div>

          <CardBody>
            <div className="m-sm-4">
              <Form onSubmit={this.handleSubmit}>
                <FormGroup>
                  <Label>Username</Label>
                  <Input
                    bsSize="lg"
                    type="text"
                    name="username"
                    placeholder="Enter your Username"
                    value={this.state.username}
                    onChange={this.handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Password</Label>
                  <Input
                    bsSize="lg"
                    type="password"
                    name="password"
                    placeholder="Enter your Password"
                    value={this.state.password}
                    onChange={this.handleChange}
                  />
                </FormGroup>
                <div className="text-center mt-3">
                  <ButtonToolbar>
                    <Button color="primary" size="lg">
                      Sign in
                    </Button>
                    <ResponseModal
                      show={this.state.incorrectCredentialsModalDisplay}
                      onHide={modalClose}
                      modalheading={"Incorrect Credentials"}
                      modaltext={
                        "Either your Username or your Password is incorrect. Please try again."
                      }
                    />
                  </ButtonToolbar>
                </div>
              </Form>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}
export default SignIn;

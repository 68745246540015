import React from "react";

// create columns for id, filename, changeLog, createdAt, updatedAt
export const columns = [
    {
        dataField: "id",
        text: "#",
        headerAttrs: { width: 60 },
        searchable: false,
        sort: true,
        style: {
            cursor: "pointer",
        },
    },
    {
        dataField: "filename",
        text: "Filename",
        searchable: true,
        style: {
            cursor: "pointer",
        },
    },
    {
        dataField: "changeLog",
        text: "Change Log",
        searchable: true,
        style: {
            cursor: "pointer",
        },
    },
    {
        dataField: "createdAt",
        text: "Created At",
        searchable: true,
        sort: true,
        style: {
            cursor: "pointer",
        },
    },
    {
        dataField: "updatedAt",
        text: "Updated At",
        searchable: true,
        sort: true,
        style: {
            cursor: "pointer",
        },
    },
];

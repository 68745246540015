import { host } from "./index";
import { retrieveFromLocalStorage } from "../localStorageUtil";

export let getPermissions = async () => {
  let token = retrieveFromLocalStorage("token");
  let data = [];

  let url = `${host}/internal/permissions`;

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.length) data = parsePermissionsData(res);
      else data = res;
    });
  return data;
};

let parsePermissionsData = (getPermissionsArray) => {
  let parsedPermissions = [];
  getPermissionsArray.forEach((data) => {
    let permission = {};
    permission.id = data.id;
    permission.name = data.name;
    permission.identifier = data.identifier;
    permission.description = data.description;
    permission.createdAt = data.createdAt;
    permission.updatedAt = data.updatedAt;
    parsedPermissions.push(permission);
  });
  return parsedPermissions;
};

export let getPermissionsDropdown = async () => {
  let getPermissionsArray = await getPermissions();
  // used for dropdown values in create and edit role forms
  let permissions = [];
  if (getPermissionsArray.length) {
    getPermissionsArray.forEach((data) => {
      let permission = {
        value: data.id,
        label: data.name,
      };
      permissions.push(permission);
    });
  }
  return permissions;
};

export let createPermission = async (data) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/internal/permissions`;

  await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let editPermission = async (data, id) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/internal/permissions/${id}`;

  await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

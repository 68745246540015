import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom"
import { Button, Row } from "react-bootstrap";
import DeviceTargetingDoc from './quickReplies/assets/sample_device_targeting.csv';
import AdvertisingTargetingDoc from './quickReplies/assets/sample_advertising_targeting.csv';
import RulesCard from "./RulesCard";
import { gendersList } from "../../config/UserConfig";
import ResponseModal from "./ResponseModal";
import AgeGenderRules from "./AgeGenderRules";
import { AutoDownloadConfigs } from "./AutoDownloadConfigs";
import { customAudienceTargetingSupportedModules, errorModalHeading, serverAdVersionsTargetingSupportedModules, sessionLoggingTargetingSupportedModules } from "../../config/config";
import { Col, FormGroup, Input, Label, Card, CardBody, CustomInput, Container } from "reactstrap";
import { Oval } from "react-loader-spinner";
import { error, redirectOnTokenExpiry, redirectTo } from "../../utilities/commonUtil";
import { fetchPlacements } from "../../utilities/apiUtils/configAPI";
import { fetchPlacementLocations } from "../../utilities/apiUtils/placementLocations";

const TargetingRules = (props) => {
    const history = useHistory()
    const getRules = props.getRules;
    const saveRules = props.saveRules;
    const showAgeGenderRules = props.showAgeGenderRules ?? false;
    const isAdvertising = props.isAdvertising ?? false;
    const showAutoDownloadConfigs = props.showAutoDownloadConfigs ?? false;
    const showAppVersionRules = props.showAppVersionRules ?? false;
    const showCustomAudienceTargetingRules = props.showCustomAudienceTargetingRules ?? false;
    const showPlacementRules = props.showPlacementRules ?? false
    const showHardFiltering = props.showHardFiltering ?? true;
    const showClientRules = props.showClientRules ?? true
    const isGoogleAdvertisingAttributes = props.isGoogleAdvertisingAttributes ?? false 
    const overrideClientApi = props.overrideClientApi ?? false

    const updateAllowed = props.updateRulesAllowed ?? false;
    const [isCustomAudienceTargetingEnabled, setIsCustomAudienceTargetingEnabled] = useState(false);
    const [targetUserDeviceIDsFile, setTargetUserDeviceIDsFile] = useState(null);
    const [targetUserDeviceIDsFileName, setTargetUserDeviceIDsFileName] = useState("");
    const [targetUserDeviceIDsCount, setTargetUserDeviceIDsCount] = useState(0);
    const [advertisingIdCount, setAdvertisingIdCount] = useState(0);
    const [loader, setLoader] = useState(false || props.showLoader);
    const [ageGenderRules, setAgeGenderRules] = useState({});
    const [minAge, setMinAge] = useState(0);
    const [maxAge, setMaxAge] = useState(null);
    const [gender, setGender] = useState(gendersList[0]);
    const [genderHardFiltering, setGenderHardFiltering] = useState(false);
    const [ageHardFiltering, setAgeHardFiltering] = useState(false);
    const [ageGenderHardFiltering, setAgeGenderHardFiltering] = useState(false);
    const [autoDownloadConfig, setAutoDownloadConfig] = useState({});
    const [allowUserDelete, setAllowUserDelete] = useState(false);
    const [requireUserHead, setRequireUserHead] = useState(false);
    const [failureModalText, setFailureModalText] = useState("");
    const [failureModalDisplay, setFailureModalDisplay] = useState(false);
    const [isDefaultTargetingRulesSet, setIsDefaultTargetingRulesSet] = useState(false)
    const [failureModal, setFailureModal] = useState({
        display: false, text: "",
    });
    const [locationClientMappingOptions, setLocationClientMappingOptions] = useState({})
    const [locationOptions, setLocationOptions] = useState([])

    const [rules, setRules] = useState([]);
    const [adServerVersions, setAdServerVersions] = useState({ min: 1, max: null })
    const [targetingRulesBeforeUpdate, setTargetingRulesBeforeUpdate] = useState(null)

    let failureModalClose = () => {
        setFailureModal({ ...failureModal, display: false });
    };

    useEffect(() => {
        const _getRules = async () => {
            let id = props.id
            if (history?.location?.search?.includes("?duplicate"))
                id = history?.location?.search?.split("=")[1]
            if (typeof getRules === 'function') {
                let response = await getRules(id);
                if (
                    response?.errorCode === error.tokenExpired ||
                    response?.errorCode === error.invalidAccessToken ||
                    response?.errorCode === error.unauthorized
                ) redirectOnTokenExpiry();
                else {
                    if (response !== null && response !== undefined) {
                        // Deep clone existing targeting rules for session logging
                        setTargetingRulesBeforeUpdate(JSON.parse(JSON.stringify(response)))
    
                        var rules;
                                            // check if any of the customAudienceTargetingSupportedModules present in the path
                        if (customAudienceTargetingSupportedModules.some(v => window.location.pathname.includes(v))) {
                            rules = response.rules;
                            setIsCustomAudienceTargetingEnabled(response.isCustomAudienceTargetingEnabled ?? false);
                            if (isGoogleAdvertisingAttributes) {
                                setTargetUserDeviceIDsFileName(response.googleAdvertisingIdsFileName)
                                setTargetUserDeviceIDsCount(response.googleAdvertisingIdsCount)
                            } else {
                                setTargetUserDeviceIDsFileName(response.deviceIdsFileName)
                                setTargetUserDeviceIDsCount(response.deviceIdsCount)
                            }
                            if (isAdvertising) {
                                setAdvertisingIdCount(response.advertisingIdCount)
                            }
                            setAdServerVersions((prevState) => ({
                                ...prevState,
                                min: response?.minAdServerVersion || 1,
                                max: response?.maxAdServerVersion || null
                            }))
                        } else {
                            rules = response;
                        }
    
                        for (let i = 0; i < rules.length; i++) {
                            rules[i].id = i;
                        }
                        setRules(rules);
    
                        let autoDownloadConfigsResponse = {};
                        if (rules[0]?.autoDownloadConfig) {
                            autoDownloadConfigsResponse = rules[0].autoDownloadConfig;
                        }
                        setAutoDownloadConfig(autoDownloadConfigsResponse ?? null);
                        setAllowUserDelete(autoDownloadConfigsResponse.allowUserDelete ?? false);
                        setRequireUserHead(autoDownloadConfigsResponse.requireUserHead ?? false);
    
                        let ageGenderRuleResponse = {};
                        if (rules[0]?.ageGenderRules) {
                            ageGenderRuleResponse = rules[0].ageGenderRules;
                        }
    
                        setAgeGenderRules(ageGenderRuleResponse ?? null);

                        setMinAge(ageGenderRuleResponse.minAge ?? null);
                        setMaxAge(ageGenderRuleResponse.maxAge ?? null);
                        if (ageGenderRuleResponse.gender) {
                            setGender({
                                value: ageGenderRuleResponse.gender,
                                label: ageGenderRuleResponse.gender[0].toUpperCase() + ageGenderRuleResponse.gender.substring(1),
                            });
                        }
                        setAgeHardFiltering(ageGenderRuleResponse.ageHardFiltering ?? false);
                        setGenderHardFiltering(ageGenderRuleResponse.genderHardFiltering ?? false);
                        setAgeGenderHardFiltering(ageGenderRuleResponse.ageHardFiltering && ageGenderRuleResponse.genderHardFiltering);

                    } else {
                            setRules([{
                                id: 0, clientRules: [{ clientID: "global", effect: "allow" }], locationRules: [{
                                    countryID: 240, regionID: 1, cityID: 1, effect: "allow",
                                },],
                            },]);
                        }
                }
            }
        };
    
        _getRules();
        if (showPlacementRules) {
            getPlacements()
            getLocationsOptions()
        }
    }, [props.id, props?.triggerTargetingRulesUpdate]);

    const getPlacements = async () => {
        try {
            let response = await fetchPlacements()
            if (response?.length > 0) {
                let locationClientMapping = {}
                response.forEach(placement => {
                    if (!locationClientMapping[placement.location]) locationClientMapping[placement.location] = []
                    let clientIds = locationClientMapping[placement.location] ?? []
                    if (!clientIds?.includes(placement.clientId))
                        locationClientMapping[placement.location].push(placement.clientId)
                })
                setLocationClientMappingOptions(locationClientMapping)
            } else {
                setLocationClientMappingOptions({})
            }
        } catch (err) {
            console.log(err);
        }
    }

    const getLocationsOptions = async () => {
        try {
            let response = await fetchPlacementLocations();
            if (response?.length > 0) {
                setLocationOptions(response.map(location => ({
                    value: location.value,
                    label: location.name,
                    target: {
                        name: "location",
                        value: {
                            value: location.value,
                            label: location.name
                        }
                    }
                })))
            } else {
                setLocationOptions([])
            }
        } catch (err) {
            console.log(err);
        }
    };

    const addRule = (event) => {
        setRules((prev) => [...prev, {
            id: prev.length > 0 ? prev[prev.length - 1].id + 1 : 0,
            clientRules: [{ clientID: "global", effect: "allow" }],
            locationRules: [{
                countryID: 240, regionID: 1, cityID: 1, effect: "allow",
            }],
            appVersionRules: {
                minAppVersion: 1,
                maxAppVersion: null,
            },
            placementRules: [{
                placementLocation: "launcher",
                clientID: "7wZFJWA5chjgat68y826IAIKQ6s197RM",
                effect: "allow"
            }]
        },]);
    };

    useEffect(() => {
        if (isDefaultTargetingRulesSet) {
            setRules([{
                id: 0,
                activate: true,
                clientRules: [{ clientID: "global", effect: "allow" }],
                appVersionRules: {
                    minAppVersion: 1,
                    maxAppVersion: null,
                },
                locationRules: [
                    {
                        id: 0,
                        countryID: 99,
                        regionID: 4504,
                        cityID: 106525,
                        effect: "allow"
                    }
                ],
                placementRules: [
                    {
                        placementLocation: "launcher",
                        clientID: "7wZFJWA5chjgat68y826IAIKQ6s197RM",
                        effect: "allow"
                    },
                    {
                        placementLocation: "launcher",
                        clientID: "com.mint.keyboard",
                        effect: "allow"
                    },
                    {
                        placementLocation: "launcher",
                        clientID: "ai.mint.keyboard",
                        effect: "allow"
                    },
                    {
                        placementLocation: "play_store",
                        clientID: "7wZFJWA5chjgat68y826IAIKQ6s197RM",
                        effect: "allow"
                    },
                    {
                        placementLocation: "play_store",
                        clientID: "com.mint.keyboard",
                        effect: "allow"
                    },
                    {
                        placementLocation: "play_store",
                        clientID: "ai.mint.keyboard",
                        effect: "allow"
                    },
                    {
                        placementLocation: "app_store",
                        clientID: "7wZFJWA5chjgat68y826IAIKQ6s197RM",
                        effect: "allow"
                    },
                    {
                        placementLocation: "app_store",
                        clientID: "com.mint.keyboard",
                        effect: "allow"
                    },
                    {
                        placementLocation: "app_store",
                        clientID: "ai.mint.keyboard",
                        effect: "allow"
                    },
                    {
                        placementLocation: "browser",
                        clientID: "7wZFJWA5chjgat68y826IAIKQ6s197RM",
                        effect: "allow"
                    },
                    {
                        placementLocation: "browser",
                        clientID: "com.mint.keyboard",
                        effect: "allow"
                    },
                    {
                        placementLocation: "browser",
                        clientID: "ai.mint.keyboard",
                        effect: "allow"
                    }
                ],
            }])
            setAgeGenderRules((prevState) => ({
                ...prevState,
                minAge: 0,
                maxAge: 100,
                gender: "unisex",
            }))
            setMinAge(0)
            setMaxAge(null)
            setGender({
                label: "Unisex",
                value: "unisex",
            })
        }
    }, [isDefaultTargetingRulesSet])

    const setDefaultTargetingRules = () => {
        setRules([])
        setAgeGenderRules({})
        setMinAge(0)
        setMaxAge(null)
        setGender(gendersList[0])
        setIsDefaultTargetingRulesSet(true)
    }

    useEffect(() => {
        setAutoDownloadConfig({
            allowUserDelete: allowUserDelete, requireUserHead: requireUserHead,
        });
    }, [requireUserHead, allowUserDelete]);

    useEffect(() => {
        setAgeGenderRules({
            minAge: parseInt(minAge),
            maxAge: parseInt(maxAge),
            gender: gender.value,
            ageHardFiltering: ageHardFiltering,
            genderHardFiltering: genderHardFiltering,
        });
        if (isDefaultTargetingRulesSet) setIsDefaultTargetingRulesSet(false)
        // not adding 'isDefaultTargetingRulesSet' in deps array to prevent infinite rendering
    }, [minAge, maxAge, gender, ageHardFiltering, genderHardFiltering, ageGenderHardFiltering]);

    let handleAgeChange = (event) => {
        if (event.target.name === "minAge") setMinAge(event.target.value); else if (event.target.name === "maxAge") setMaxAge(event.target.value);
    };

    let handleGenderChange = (select) => {
        let selectedGender = {};
        if (select !== null) {
            selectedGender["value"] = select.value;
            selectedGender["label"] = select.label;
        }
        setAgeGenderRules({ ...ageGenderRules, gender: select.value });
        setGender(selectedGender);
    };

    let handleAgeAndGenderToggleChange = (event) => {
        if (event.target.name === "ageGenderHardFiltering") {
            setGenderHardFiltering(!ageGenderHardFiltering);
            setAgeHardFiltering(!ageGenderHardFiltering);
            setAgeGenderHardFiltering(!ageGenderHardFiltering);
        }

        if (event.target.name === "ageHardFiltering") {
            // update the ageGenderHardFiltering Toggle value depending upon the previous values of age toggle and gender toggle
            // if ageHardFiltering value was false before and genderHadfiltering was true, that means the combine toggle will be true now
            setAgeGenderHardFiltering(!ageHardFiltering && genderHardFiltering);
            setAgeHardFiltering(!ageHardFiltering);
        }

        if (event.target.name === "genderHardFiltering") {
            // update the ageGenderHardFiltering Toggle value depending upon the previous values of age toggle and gender toggle
            setAgeGenderHardFiltering(ageHardFiltering && !genderHardFiltering);
            setGenderHardFiltering(!genderHardFiltering);
        }
    };

    let handleRequireUserHeadChange = (event) => {
        if (event.target.name === "requireUserHead") {
            setRequireUserHead(!requireUserHead);
        }
    };

    let handleAllowUserDeleteChange = (event) => {
        if (event.target.name === "allowUserDelete") {
            setAllowUserDelete(!allowUserDelete);
        }
    };

    let handleAdServerVersionsChange = (event) => {
        const { name, value } = event.target
        const adVersion = parseInt(value, 10)
        if (!value) {
            setAdServerVersions((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else if (!Number.isNaN(adVersion) && adVersion > 0) {
            setAdServerVersions((prevState) => ({
                ...prevState,
                [name]: adVersion,
            }));
        }
    }

    let addAgeGenderRules = () => {
        for (const element of rules) {
            element.ageGenderRules = ageGenderRules;
        }
    };

    let addAutoDownloadConfigRules = () => {
        for (const element of rules) {
            element.autoDownloadConfig = autoDownloadConfig;
        }
    };

    const removeRule = (id) => {
        setRules((prev) => {
            const _updatedRules = prev.filter((rule) => rule.id !== id);
            return _updatedRules;
        });
    };

    const updateRules = (ruleID, clientRules, locationRules, placementRules, activate, appVersionRules) => {
        setRules((prev) => {
            prev[ruleID].clientRules = clientRules;
            prev[ruleID].locationRules = locationRules;
            prev[ruleID].placementRules = placementRules;
            prev[ruleID].activate = activate;
            prev[ruleID].appVersionRules = appVersionRules;
            return [...prev];
        });
        if (isDefaultTargetingRulesSet) setIsDefaultTargetingRulesSet(false)
    };

    useEffect(() => {
        setLoader(props.showLoader);
    }, [props.showLoader])

    const saveChanges = async () => {
        if (isCustomAudienceTargetingEnabled && targetUserDeviceIDsCount <= 0 && !targetUserDeviceIDsFileName) {
            setFailureModal({
                display: true, text: "Please upload device id list or disable custom audience targeting",
            });
            return;
        }

        addAgeGenderRules();
        addAutoDownloadConfigRules();

        // check if minAge is greater than maxAge
        if (minAge > maxAge && minAge !== 0 && maxAge !== null && maxAge !== "") {
            setFailureModal({
                display: true, text: "Minimum age cannot be greater than maximum age",
            });
            return;
        }

        // check if keyboard-prompt is present in the path
        if (window.location.pathname.includes("keyboard-prompt")) {
            for (const element of rules) {
                element.keyboardPromptId = parseInt(props.id);
            }
        }

        let data = {}
        if (isGoogleAdvertisingAttributes) {
            data = {
                "isCustomAudienceTargetingEnabled": isCustomAudienceTargetingEnabled,
                "rules": rules,
                "googleAdvertisingIds": targetUserDeviceIDsFile,
                "googleAdvertisingIdsFileName": targetUserDeviceIDsFileName
            }
        } else {
            if (isAdvertising) {
                data = {
                    "isCustomAudienceTargetingEnabled": isCustomAudienceTargetingEnabled,
                    "rules": rules,
                    "advertisingIds": targetUserDeviceIDsFile,
                    "advertisingFileName": targetUserDeviceIDsFileName
                }
            } else {
                data = {
                    "isCustomAudienceTargetingEnabled": isCustomAudienceTargetingEnabled,
                    "rules": rules,
                    "deviceIds": targetUserDeviceIDsFile,
                    "deviceIdsFileName": targetUserDeviceIDsFileName
                }
            }

        }

        if (window.location.href.match(/\/quick-reply\/targeting-rules\/[0-9-]+/)) {
            data["targetingType"] = props.rulesType
        }

        if (serverAdVersionsTargetingSupportedModules.some(v => window.location.pathname.includes(v))) {
            if (adServerVersions.min < 1) {
                setFailureModal({
                    display: true, text: "Min ad server version can't be less than 1",
                });
                return
            }
            data["minAdServerVersion"] = adServerVersions.min || 1
            data["maxAdServerVersion"] = adServerVersions.max || null

            if ((data["minAdServerVersion"] && data["maxAdServerVersion"]) && (data["minAdServerVersion"] > data["maxAdServerVersion"])) {
                setFailureModal({
                    display: true, text: "Min ad server version can't greater than max ad server version",
                });
                return
            }
        }
        if (sessionLoggingTargetingSupportedModules.some(v => window.location.pathname.includes(v))) {
            data = {
                oldTargetingRules: targetingRulesBeforeUpdate,
                updatedTargetingRules: data
            }
        }

        if (history.location.pathname === '/stories/sotd/create' || history.location.pathname === 'stories/buckets') {
            saveRules(rules);
            return;
        }

        // Start the loading spinner
        setLoader(true);
        const response = await saveRules(rules, props.id, data);
        setLoader(false);

        if (response != null) {
            if (history.location.pathname.includes("/ads/smart-suggestions"))
                redirectTo("/ads/smart-suggestions")
            else
                window.location.reload();
        }
    };

    return (
        <div>
            {showCustomAudienceTargetingRules ? (
                <Card>
                    <CardBody>
                        <FormGroup className="mb-0">
                            <Row>
                                <Col>
                                    <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}
                                    >
                                        Is Custom Audience Targeting Enabled
                                    </Label>
                                </Col>
                                <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <div className="custom-control custom-switch">
                                        <CustomInput
                                            type="checkbox"
                                            id="customAudienceTargeting"
                                            value={isCustomAudienceTargetingEnabled}
                                            onChange={(event) => {
                                                const { checked } = event.target
                                                setIsCustomAudienceTargetingEnabled(prevState => !prevState)
                                                setRules([{
                                                    id: 0,
                                                    activate: false,
                                                    clientRules: checked ? [] : [{ clientID: "global", effect: "allow" }],
                                                    locationRules: checked ? [] : [{
                                                        countryID: 240, regionID: 1, cityID: 1, effect: "allow",
                                                    }],
                                                    placementRules: [{
                                                        placementLocation: "launcher",
                                                        clientID: "7wZFJWA5chjgat68y826IAIKQ6s197RM",
                                                        effect: "allow"
                                                    }]
                                                }]);
                                            }}
                                            checked={isCustomAudienceTargetingEnabled}
                                            disabled={!updateAllowed}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </FormGroup>
                    </CardBody>
                </Card>
            ) : null}
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                    {isCustomAudienceTargetingEnabled ?
                        <h1 className="h3">Custom Audience Targeting Rules</h1> : <>{showAutoDownloadConfigs ? (
                            <h1 className="h3">AutoDownload Rules</h1>) : (
                            <h1 className="h3">Targeting Rules</h1>)}</>
                    }
                    <span className="text-warning font-weight-bold" style={{ fontSize: 13 }}>⚠️ The form needs to be submitted to save the targeting rules</span>
                </div>
                {(rules && rules.length === 0 && !isCustomAudienceTargetingEnabled)
                    ? <Button color="success" className="btn-success" onClick={setDefaultTargetingRules}>Set Default Targeting Rules</Button>
                    : null
                }
            </div>
            {serverAdVersionsTargetingSupportedModules.some(v => window.location.pathname.includes(v)) && (
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "14px" }}
                                    >
                                        Min Ad Server Version <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        type="number"
                                        step="1"
                                        name="min"
                                        min="1"
                                        placeholder="Min Ad Server Version"
                                        value={adServerVersions.min}
                                        onChange={handleAdServerVersionsChange}
                                        disabled={!updateAllowed}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "14px" }}
                                    >
                                        Max Ad Server Version
                                    </Label>
                                    <Input
                                        type="number"
                                        step="1"
                                        name="max"
                                        placeholder="Max Ad Server Version"
                                        value={adServerVersions.max}
                                        onChange={handleAdServerVersionsChange}
                                        disabled={!updateAllowed}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            )}
            {isCustomAudienceTargetingEnabled ? <Card>
                <CardBody>
                    <div>
                        <p className="font-weight-bolder mb-2" style={{ fontSize: "14px" }}>{isAdvertising ? "Advertising IDs" : "Device IDs "} Count: <strong>{isAdvertising ? advertisingIdCount : targetUserDeviceIDsCount}</strong></p>
                    </div>
                    <hr
                        style={{
                            width: "75rem",
                            color: "black",
                        }}
                    />
                    <div>
                        <Row>
                            <Col md={12} xs={12}>
                                <FormGroup className="d-flex flex-column">
                                    <Label>
                                        <strong>
                                            {isAdvertising ? "Advertising IDs List" : "Device IDs List"}
                                            <small className="text-info">(Formats: xlsx, xls, csv)</small>
                                        </strong>
                                        <p style={{ fontSize: 12, marginBottom: 0 }}>
                                            Use this template <a href={isAdvertising ? AdvertisingTargetingDoc : DeviceTargetingDoc} download={isAdvertising ? "sample-advertising-targeting.csv" : "sample-device-targeting.csv"}>
                                                {isAdvertising ? "Sample Advertising Targeting IDs List" : "Sample Device Targeting IDs List"}
                                            </a>
                                        </p>
                                    </Label>
                                    <Container className="custom-file" style={{ width: 300, marginLeft: "inherit" }}>
                                        <Input
                                            type="file"
                                            className="custom-file-input"
                                            name="deviceIds"
                                            id="deviceIds"
                                            placeholder="Device IDs"
                                            accept=".xlsx, .xls, .csv"
                                            disabled={!updateAllowed}
                                            onChange={(event) => {
                                                const file = event.target.files[0];
                                                if (file?.name?.endsWith(".xlsx") || file?.name?.endsWith(".xls") || file?.name?.endsWith(".csv")) {
                                                    const reader = new FileReader();
                                                    reader.onload = (e) => {
                                                        setTargetUserDeviceIDsFile(e.target.result)
                                                        setTargetUserDeviceIDsFileName(file.name)
                                                    };
                                                    reader.readAsDataURL(file);
                                                } else {
                                                    setFailureModal((prevState) => ({
                                                        ...prevState,
                                                        display: true,
                                                        text: "Please upload file in accepted format"
                                                    }))
                                                }
                                            }}
                                        />
                                        <Label className="custom-file-label">
                                            <strong>{targetUserDeviceIDsFileName ?? "Choose a file"}</strong>
                                        </Label>
                                    </Container>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card> : null
            }
            {!isCustomAudienceTargetingEnabled && showAutoDownloadConfigs ? (<Row>
                <AutoDownloadConfigs
                    allowUserDelete={allowUserDelete}
                    requireUserHead={requireUserHead}
                    handleAllowUserDeleteChange={handleAllowUserDeleteChange}
                    handleRequireUserHeadChange={handleRequireUserHeadChange}
                ></AutoDownloadConfigs>
            </Row>) : null}
            {(!isCustomAudienceTargetingEnabled && showAgeGenderRules) ? (<AgeGenderRules
                updateRules={updateRules}
                removeRule={removeRule}
                updateAllowed={updateAllowed}
                minAge={minAge ?? 0}
                maxAge={maxAge ?? null}
                gender={gender ?? gendersList[0]}
                ageHardFiltering={ageHardFiltering ?? false}
                genderHardFiltering={genderHardFiltering ?? false}
                handleAgeChange={handleAgeChange}
                handleGenderChange={handleGenderChange}
                handleAgeAndGenderToggleChange={handleAgeAndGenderToggleChange}
                showHardFiltering={showHardFiltering ?? true}
            ></AgeGenderRules>) : null}
            {(!isCustomAudienceTargetingEnabled || showPlacementRules) ? rules.map(function (object) {
                return ((!object.isCustomAudienceTargetingEnabled || showPlacementRules) ? <RulesCard
                    key={object.id}
                    rule={object}
                    getClients={props.getClients}
                    getCountries={props.getCountries}
                    getRegions={props.getRegions}
                    getCities={props.getCities}
                    updateRules={updateRules}
                    removeRule={removeRule}
                    updateAllowed={updateAllowed}
                    showClientRules={showClientRules}
                    showAppVersionRules={showAppVersionRules}
                    showPlacementRules={showPlacementRules}
                    locationClientMappingOptions={locationClientMappingOptions}
                    locationOptions={locationOptions}
                    isCustomAudienceTargetingEnabled={isCustomAudienceTargetingEnabled}
                    overrideClientApi={overrideClientApi}
                /> : null);
            }) : null}
            {(!isCustomAudienceTargetingEnabled && updateAllowed) ? <div className="d-flex justify-content-end p-0">
                <Button onClick={addRule}>Add Rule</Button>
            </div> : null}
            {updateAllowed ? (<div className="d-flex">
                {!loader ? <div className="p-0">
                    <Button onClick={saveChanges}>Save Changes</Button>
                </div> : <Oval
                    height={80}
                    width={80}
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={loader}
                    ariaLabel='oval-loading'
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}

                />}
            </div>) : (<div></div>)}
            <ResponseModal
                show={failureModal.display}
                onHide={failureModalClose}
                modalheading={errorModalHeading}
                modaltext={failureModal.text}
            />
        </div>
    );
};

export default TargetingRules;

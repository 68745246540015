import React, { useState, useEffect } from "react";

import {
  Card,
  CardBody,
  Container,
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
  Input,
} from "reactstrap";

import Select from "react-select";
import Loading from "../../../components/Loading";

import {
  redirectTo,
  errorDescription,
  isOperationPermitted,
  permissions,
} from "../../../utilities/commonUtil";

import Routes from "../../../routes/index";
import { LottieAnimationResponse } from "../../../config/ConfigLottieAnimation";

import { getEmojiStickers } from "../../../utilities/apiUtils/emojiStickers";
import {
  createLottieAnimation,
  editLottieAnimation,
  getLottieAnimation,
} from "../../../utilities/apiUtils/stickers";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";

import ConfirmationModal from "../ConfirmationModal";
import ResponseModal from "../ResponseModal";

const ManageLottieAnimation = () => {
  const [lottieAnimation, setLottieAnimation] = useState({
    name: null,
    filename: null,
    emojiStickerIDs: [],
    lottieAnimationFile: null,
  });

  const [loading, setLoading] = useState(true);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [action, setAction] = useState("");
  const [changeType, setChangeType] = useState("Add");
  const [emojiStickerOptions, setEmojiStickerOptions] = useState([]);
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");
  const [allowedPermissions, setAllowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  useEffect(() => {
    const fetchEmojiStickers = async () => {
      try {
        let emojiStickers = await getEmojiStickers();
        if (emojiStickers?.emojis !== undefined) {
          let emojiStickerOptions = emojiStickers.emojis
            .filter((emoji) => emoji.lottieAnimation === null)
            .map((emoji) => {
              return {
                value: emoji.emojiId,
                label:
                  emoji?.emojiId + " - " + emoji?.name + " - " + emoji?.emoji,
              };
            });

          if (window.location.pathname.includes("edit") || window.location.pathname.includes("view")) {
            let id = window.location.pathname.split("/").pop();
            let data = await getLottieAnimation(id);
            if (data !== undefined) {
              // map this with emojiStickerOptions. We are getting only array of ids from backend
              let emojiStickerIds = [];
              emojiStickers.emojis.map((emojiSticker) => {
                if (data.emojiStickerIDs.includes(emojiSticker.emojiId)) {
                  emojiStickerIds.push({
                    value: emojiSticker.emojiId,
                    label:
                      emojiSticker?.emojiId +
                      " - " +
                      emojiSticker?.name +
                      " - " +
                      emojiSticker?.emoji,
                  });
                }
              });

              // add emojiStickerIDs to emojiStickerOptions
              emojiStickerOptions = emojiStickerOptions.concat(emojiStickerIds);
              setEmojiStickerOptions(emojiStickerOptions);

              setLottieAnimation({
                name: data.name,
                filename: data.filename,
                emojiStickerIDs: emojiStickerIds,
                lottieAnimationFile: null,
              });
            }
          }
          setLoading(false)
          setEmojiStickerOptions(emojiStickerOptions);
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
        setFailureModalDisplay(true);
        setFailureModalText(errorDescription(err.unexpectedError));
      }
    };
    fetchEmojiStickers();
    if (window.location.pathname.includes("edit")) {
      setChangeType("Edit");
    } else if (window.location.pathname.includes("view")) {
      setChangeType("View");
    }

  }, []);

  let setConfirmationModalState = (event) => {
    event.preventDefault();
    setAction("Submit");
    setConfirmationModalIsOpen(true);
  };

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    redirectTo(Routes.stickers.children.lottieAnimations.path);
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  let handleSubmit = async () => {
    let formData = new FormData();
    formData.append("name", lottieAnimation.name);

    if (lottieAnimation.lottieAnimationFile !== null) {
      formData.append(
        "lottieAnimationFile",
        lottieAnimation.lottieAnimationFile
      );
    }

    let emojiStickerIDs = [];

    if (lottieAnimation.emojiStickerIDs !== null) {
      emojiStickerIDs = lottieAnimation.emojiStickerIDs.map(
        (emojiSticker) => emojiSticker.value
      );
    }

    for (const emojiStickerID of emojiStickerIDs) {
      formData.append("emojiStickerIDs", emojiStickerID);
    }

    if (changeType === "Add") {
      let data = await createLottieAnimation(formData);
      if (data.status === "success") {
        setSuccessModalText(LottieAnimationResponse.createdSuccess);
        setSuccessModalDisplay(true);
      } else {
        showErrorModal(errorDescription(data));
      }
    }

    if (changeType === "Edit") {
      let id = window.location.pathname.split("/").pop();
      let data = await editLottieAnimation(formData, id);
      if (data.status === "success") {
        setSuccessModalText(LottieAnimationResponse.updatedSuccess);
        setSuccessModalDisplay(true);
      } else {
        showErrorModal(errorDescription(data));
      }
    }
  };

  let disabled = () => {
    if (changeType === "Add") return false;

    return !isOperationPermitted(
      allowedPermissions,
      permissions.EmojiStickerAnimationEdit
    );
  };

  return (
    <Container fluid className="p-0">
      <Row className="mb-3">
        <Col md={6} xs={6}>
          <h1>{changeType} Lottie Animation</h1>
        </Col>
      </Row>

      {loading ? (
        <Loading />
      ) : (
        <Form onSubmit={(event) => setConfirmationModalState(event)}>
          <Card>
            <CardBody>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label
                      className="font-weight-bolder mb-2"
                      style={{ fontSize: "16px" }}
                    >
                      Name <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      placeholder="Enter Name"
                      required
                      disabled={disabled()}
                      value={lottieAnimation.name}
                      id="name"
                      onChange={(event) =>
                        setLottieAnimation({
                          ...lottieAnimation,
                          name: event.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label
                      className="font-weight-bolder mb-2"
                      style={{ fontSize: "16px" }}
                    >
                      Lottie Animation File{" "}
                      <span className="text-danger">*</span>
                    </Label>
                    <Container className="custom-file">
                      <Input
                        type="file"
                        className="custom-file-input"
                        id="lottieAnimationFile"
                        required={changeType === "Add"}
                        accept=".json"
                        disabled={disabled()}
                        onChange={(event) => {
                          const file = event.target.files[0];
                          if (file && file.type !== "application/json") {
                            return;
                          }
                          setLottieAnimation({
                            ...lottieAnimation,
                            lottieAnimationFile: file,
                            filename: file.name,
                          });
                        }}
                      />
                      <Label
                        className="custom-file-label"
                        htmlFor="lottieAnimationFile"
                      >
                        {lottieAnimation.filename
                          ? lottieAnimation.filename
                          : "Choose file"}
                      </Label>
                    </Container>
                  </FormGroup>
                </Col>

                <Col md={12}>
                  <FormGroup>
                    <Label
                      className="font-weight-bolder mb-2"
                      style={{ fontSize: "16px" }}
                    >
                      Emoji Stickers :
                    </Label>
                    <Select
                      id="emojiStickerIDs"
                      isMulti
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="emojiStickerIDs"
                      placeholder="Select Emoji Stickers"
                      isDisabled={disabled()}
                      value={lottieAnimation.emojiStickerIDs}
                      onChange={(event) => {
                        setLottieAnimation({
                          ...lottieAnimation,
                          emojiStickerIDs: event,
                        });
                      }}
                      options={emojiStickerOptions}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          {/* Submit button */}
          {!disabled() && (
            <div style={{ display: "flex", alignItems: "center" }}>
            <Button color="primary" type="submit" className="mt-2">
              Submit
            </Button>
          </div>
          )}
          
        </Form>
      )}

      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={action}
        onConfirmationTrue={() => {
          setConfirmationModalIsOpen(false);
          if (action === "Submit") handleSubmit();
        }}
        onHide={() => setConfirmationModalIsOpen(false)}
      />

      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={"Success"}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={"Error"}
        modaltext={failureModalText}
      />
    </Container>
  );
};

export default ManageLottieAnimation;

import React, { useState, useEffect, useCallback } from "react";
import ResponseModal from "../ResponseModal";
import { useParams, useLocation } from 'react-router-dom';
import { successModalHeading } from '../../../config';
import { errorModalHeading } from '../../../config/config';
import { X } from "react-feather";
import {
    error, errorDescription
} from '../../../utilities/commonUtil';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom"
import Select from "react-select";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import { CreateAIDropdownOption, GetAITaskDropdownOptionDetails, UpdateAIDropdownOption } from "../../../utilities/apiUtils/genAIDashboard";
import { taskDropdownOptionResponses } from "../../../config/configGenAIDashboard";
import ToolTip from "../../../utilities/ToolTip";
import { iconSize } from "../../../config/UserConfig";
import { languageMapping } from "../../../utilities/languageMapping";
import Loading from "../../../components/Loading";

const DropdownOptionForm = () => {
    const { taskId, id } = useParams();
    const history = useHistory();
    const location = useLocation();
    const currentPath = location.pathname.split('/');
    const pageType = currentPath[currentPath.length - 1];
    let [addLanguageCodeOption, setAddLanguageCodeOption] = useState([]);
    const [successModalDisplay, setSuccessModalDisplay] = useState(false);
    const [successModalText, setSuccessModalText] = useState("");
    const [failureModalDisplay, setFailureModalDisplay] = useState(false);
    const [failureModalText, setFailureModalText] = useState("");
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
        identifier: "",
        priority: "",
        displayName: {
            default: "",
        },
        attributes: {},
        createdAt: "",
        updatedAt: "",
    })

    const [translations, setTranslations] = useState([
        {
            languageCode: "",
            displayName: "",
        },
    ]);
    const [attributesArray, setAttributesArray] = useState([
        {
            key: "",
            value: ""
        }
    ]);

    const setLanguageCode = async () => {
        let languageCode = languageMapping;
        if (languageCode?.length > 0) {
            const languageCodeOptions = languageCode.map((language) => {
                return {
                    value: language.code,
                    label: language.name + " : " + language.code,
                    target: {
                        name: "languageCode",
                        value: {
                            value: language.code,
                            label: language.name + " : " + language.code,
                        },
                    },
                };
            });
            setAddLanguageCodeOption(languageCodeOptions);
        }
    };

    const showLanguageCodeOptions = () => {
        let finalLanguageCode = [...addLanguageCodeOption];
        // remove language codes from the dropdown those are already selected.
        translations.forEach((translation) => {
            finalLanguageCode = finalLanguageCode.filter(
                (item) => item?.value !== translation.languageCode?.value
            );
        });
        return finalLanguageCode;
    };

    const handleLanguageCodeChange = (event, index) => {
        const { name, value } = event.target;
        let temp = [...translations];
        temp[index][name] = value;
        setTranslations(temp);
    };

    const handleTextChange = (event, index) => {
        const { name, value } = event.target;
        let temp = [...translations];
        temp[index][name] = value;
        setTranslations(temp);
    };

    const handleChange = (event) => {
        const { name, value } = event.target
        setData((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const convertDisplayNameToTranslations = (textObj) => {
        return Object.entries(textObj)
            .filter(([key]) => key !== "default")
            .map(([key, value]) => ({
                languageCode: {
                    value: key,
                    label: languageMapping.find(lang => lang.code === key)?.name + " : " + key || key,
                },
                displayName: value,
            }));
    };

    const convertAttributes = (textObj) => {
        return Object.entries(textObj)
            .filter(([key]) => key !== "default")
            .map(([key, value]) => ({
                key: key,
                value: value
            }));
    };

    const addRow = () => {
        let currentState = [...attributesArray, {
            "key": "",
            "value": "",
        }];
        setAttributesArray(currentState);
    };

    const subtractRow = (index) => {
        let currentState = [...attributesArray];
        currentState = currentState.filter((item, idx) => idx !== index);
        setAttributesArray(currentState);
    };

    const handleAttributeKey = (event, index) => {
        let updatedState = [...attributesArray];
        let { value } = event.target;
        updatedState[index].key = value;
        setAttributesArray(updatedState);
    };

    const handleAttributeValue = (event, index) => {
        let updatedState = [...attributesArray];
        let { value } = event.target;
        updatedState[index].value = value;
        setAttributesArray(updatedState);
    };

    let fetchTaskDropdownOption = useCallback(async (taskId) => {
        try {
            let response = await GetAITaskDropdownOptionDetails(taskId, id);
            if (response?.identifier) {
                setData({
                    identifier: response.identifier,
                    priority: response.priority,
                    displayName: response.displayName.default,
                    attributes: {},
                })
                setAttributesArray(convertAttributes(response?.attributes));
                setTranslations(convertDisplayNameToTranslations(response?.displayName));
            }
            else {
                setData({
                    identifier: "",
                    priority: "",
                    displayName: {
                        default: "",
                    },
                    attributes: {},
                });
            }
        }
        catch (err) {
            showErrorModal(errorDescription(error.unexpectedError));
        }
    }, []);

    useEffect(() => {
        if (pageType !== "create") {
            fetchTaskDropdownOption(taskId, id);
        }
        setLanguageCode();
    }, [id, pageType, taskId]);

    let showSuccessModal = (text) => {
        setSuccessModalText(text);
        setSuccessModalDisplay(true);
    };

    let successModalClose = () => {
        setSuccessModalDisplay(false);
        history.goBack();
    };

    let failureModalClose = () => {
        setFailureModalDisplay(false);
    };

    let showErrorModal = (text) => {
        setFailureModalText(text);
        setFailureModalDisplay(true);
    };

    const updateDisplayNameData = (translations) => {
        const displayName = translations.reduce((acc, item) => {
            acc[item.languageCode.value] = item.displayName;
            return acc;
        }, { default: data.displayName });
        return displayName;
    };

    const updateAttributes = (attributesArray) => {
        const attributes = attributesArray.reduce((acc, item) => {
            acc[item.key] = item.value;
            return acc;
        }, {});
        return attributes;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let displayNameValue;
        displayNameValue = translations[0]?.displayName !== "" ? updateDisplayNameData(translations) : data?.displayName;
        let attributesValue;
        attributesValue = attributesArray[0]?.key !== "" ? updateAttributes(attributesArray) : data?.attributes;
        try {
            const formData = {
                identifier: data.identifier,
                priority: parseInt(data.priority),
                displayName: displayNameValue,
                attributes: attributesValue,
            };
            if (!id) {
                let response = await CreateAIDropdownOption(formData, taskId)
                if (response?.status === "aiPoweredBarTaskDropdownOptionCreated") {
                    showSuccessModal(taskDropdownOptionResponses.addedSuccessfully);
                }
                else {
                    if (response?.errorCode) {
                        showErrorModal(response?.errorMessage)
                    }
                }
            }
            else {
                let response = await UpdateAIDropdownOption(formData, taskId, id)
                if (response?.status === "aiPoweredBarTaskDropdownOptionEdited") {
                    showSuccessModal(taskDropdownOptionResponses.updatedSuccessfully);
                }
                else {
                    if (response?.errorCode) {
                        showErrorModal(response?.errorMessage)
                    }
                }
            }
        } catch (err) {
            setFailureModalDisplay(true);
            setFailureModalText(errorDescription(error.unexpectedError));
        }
        finally {
            setLoading(false);
        }
    }


    return (
        <>
            {loading ? <Loading /> :
                <Card>
                    <CardBody>
                        <h1 className="h3 mb-3">
                            {pageType === "create" ? "Create Dropdown Option" : "Edit Dropdown Option"}
                        </h1>
                        <Form onSubmit={(e) => handleSubmit(e)}>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>
                                            Identifier <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="text"
                                            name="identifier"
                                            value={data.identifier}
                                            placeholder="Enter identifier"
                                            required
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>
                                            Priority
                                        </Label>  <span className="text-danger">*</span>
                                        <Input
                                            type="number"
                                            name="priority"
                                            value={data.priority}
                                            placeholder="Enter priority"
                                            required
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>
                                            Display Name <span className="text-danger">*</span> <ToolTip data={"Display name of the dropdown option"} id="displayName" />
                                        </Label>
                                        <Input
                                            type="text"
                                            name="displayName"
                                            required
                                            value={data.displayName.default !== "" ? data.displayName : data.displayName.default}
                                            placeholder="Enter Display Name"
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <Row className="mb-3 mt-3">
                                        <Col md={6} xs={6}>
                                            <h3>Translations</h3>
                                        </Col>
                                        <Col md={6} xs={6}>
                                            <div className="p-0">
                                                <Button
                                                    color="success"
                                                    className="ml-auto d-flex btn-success"
                                                    onClick={() =>
                                                        setTranslations(
                                                            translations.concat({
                                                                languageCode: "",
                                                                text: "",
                                                            })
                                                        )
                                                    }
                                                >
                                                    <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                                                        Add Language
                                                    </p>
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Card>
                                        <CardBody>
                                            {translations?.map((item, index) => {
                                                return (
                                                    <Row key={index} form>
                                                        <Col md={5}>
                                                            <FormGroup>
                                                                <Label
                                                                    className="font-weight-bolder mb-2"
                                                                    style={{ fontSize: "16px" }}
                                                                >
                                                                    Language Code:{" "}
                                                                </Label>
                                                                <Select
                                                                    id="languageCode"
                                                                    className="react-select-container"
                                                                    classNamePrefix="react-select"
                                                                    name="languageCode"
                                                                    placeholder="Select Language Code"
                                                                    value={item.languageCode}
                                                                    onChange={(event) =>
                                                                        handleLanguageCodeChange(event, index)
                                                                    }
                                                                    options={showLanguageCodeOptions()}
                                                                    required
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={5}>
                                                            <FormGroup>
                                                                <Label className="font-weight-bolder mb-2" style={{ fontSize: "16px" }}>
                                                                    Translation:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    name="displayName"
                                                                    placeholder="Add a translation"
                                                                    value={item.displayName}
                                                                    onChange={(event) => handleTextChange(event, index)}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="p-0" style={{ marginRight: "20px" }}>
                                                                <X
                                                                    className="d-none d-lg-block ml-auto d-flex"
                                                                    size={iconSize}
                                                                    color="red"
                                                                    style={{ cursor: "pointer", border: "2px solid red" }}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setTranslations(translations.filter((_, i) => i !== index));
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>

                                                        <hr
                                                            style={{
                                                                width: "75rem",
                                                                color: "black",
                                                            }}
                                                        />
                                                    </Row>
                                                );
                                            })}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <Row className="mb-3 mt-3">
                                        <Col md={6} xs={6}>
                                            <h4>Attributes <ToolTip data={"Additional attributes or data associated with the option"} id="attributes" /></h4>
                                        </Col>
                                    </Row>
                                    <Card>
                                        <CardBody>
                                            {attributesArray && attributesArray.map((item, index) => {
                                                return (
                                                    <Row key={index}>
                                                        <Col sm={5}>
                                                            <FormGroup>
                                                                <Input
                                                                    type="text"
                                                                    name="attributekey"
                                                                    value={item.key}
                                                                    placeholder="Enter Key"
                                                                    onChange={(e) => {
                                                                        handleAttributeKey(e, index);
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={5}>
                                                            <FormGroup>
                                                                <Input
                                                                    type="text"
                                                                    name="attributeValue"
                                                                    value={item.value}
                                                                    placeholder="Enter Value"
                                                                    onChange={(e) => {
                                                                        handleAttributeValue(e, index);
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={1}>
                                                            <X
                                                                className="d-none d-lg-block mt-1"
                                                                size={iconSize}
                                                                color="red"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={(e) => {
                                                                    subtractRow(index);
                                                                }}
                                                            ></X>
                                                        </Col>
                                                    </Row>
                                                );
                                            })}
                                            <Button
                                                className="d-block pr-3 text-decoration-none"
                                                color="secondary"
                                                onClick={() => {
                                                    addRow();
                                                }}
                                            >
                                                <FontAwesomeIcon size="sm" icon={faPlus}></FontAwesomeIcon>{" "}
                                                Add More
                                            </Button>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Button color="primary">Submit</Button>
                            <ResponseModal
                                show={successModalDisplay}
                                onHide={successModalClose}
                                modalheading={successModalHeading}
                                modaltext={successModalText}
                            />
                            <ResponseModal
                                show={failureModalDisplay}
                                onHide={failureModalClose}
                                modalheading={errorModalHeading}
                                modaltext={failureModalText}
                            />
                        </Form>
                    </CardBody >
                </Card >
            }
        </>
    );
};
const CreateDropdownOption = () => (
    <Container fluid className="p-0">
        <Row>
            <Col lg="12">
                <DropdownOptionForm />
            </Col>
        </Row>
    </Container>
);
export default CreateDropdownOption;

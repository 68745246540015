import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Input, Label, Button, FormGroup } from 'reactstrap'; // Or your preferred UI library
import { getStoryCTAItemsData } from '../../../utilities/apiUtils/stories';
import Select from 'react-select';
import { placementTypeOptions, itemTypeOptions, redirectionTypeOptions, placementNativeTypeOptions } from '../../../config/configStoryDashboard'
import { updateStoryCtaItems } from '../../../utilities/apiUtils/stories';
import ConfirmationModal from "../../../pages/ui-elements/ConfirmationModal";
import ResponseModal from "../../../pages/ui-elements/ResponseModal";
import { getStoriesCTAAssets } from "../../../utilities/apiUtils/stories"
import { error, errorDescription, redirectOnTokenExpiry, redirectTo, getStatusColor } from "../../../utilities/commonUtil";
import ToolTip from '../../../utilities/ToolTip';
import { Alert } from "react-bootstrap";
import { getClients } from "../../../utilities/apiUtils/stickers";
const CTAItemsPage = (props) => {
    const [ctaItems, setCtaItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [formInput, setFormInput] = useState({
        RedirectionType: '',
    });
    const [clientOptions, setClientOptions] = useState();
    let showErrorModal = (text) => {
        setFailureModalText(text);
        setFailureModalDisplay(true);
    };
    const [cardInputStates, setCardInputStates] = useState(ctaItems.map(item => ({ ...item })));
    const [successModalDisplay, setSuccessModalDisplay] = useState(false);
    const [successModalText, setSuccessModalText] = useState("");
    const [failureModalDisplay, setFailureModalDisplay] = useState(false);
    const [failureModalText, setFailureModalText] = useState("");
    let successModalClose = async () => {
        setSuccessModalDisplay(false);
    };
    let failureModalClose = () => {
        setFailureModalDisplay(false);
    };
    const handleInputChange = (event, field, cardIndex) => {
        if (field != "redirectionType" && field != "itemType" && field != "placementType" && event.target && event.target.value !== null && field != "priority") {
            const updatedValue = event.target.value; // Capture the value
            setCardInputStates(prevStates => ({
                ...prevStates,
                [cardIndex]: { ...prevStates[cardIndex], [field]: updatedValue } // Use the copy
            }))
        } else if (field == "priority") {
            const updatedValue = parseInt(event.target.value); // Parse to integer
            setCardInputStates(prevStates => ({
                ...prevStates,
                [cardIndex]: { ...prevStates[cardIndex], [field]: updatedValue }
            }))
        } else if (field == "itemType") {
            setCardInputStates(prevStates => ({
                ...prevStates,
                [cardIndex]: { ...prevStates[cardIndex], [field]: event.value },
            }))
            setCardInputStates(prevStates => ({
                ...prevStates,
                [cardIndex]: {
                    ...prevStates[cardIndex], ["iconFilename"]: placementTypeFilenameMap[event.value],
                    ["iconURL"]: filenameUrlMap[placementTypeFilenameMap[event.value]]
                },
            }))
            setLightThemeIconImage(prevStates => ({
                ...prevStates,
                name: placementTypeFilenameMap[event.value],
                url: filenameUrlMap[placementTypeFilenameMap[event.value]]
            })
            )
        } else {
            setCardInputStates(prevStates => ({
                ...prevStates,
                [cardIndex]: { ...prevStates[cardIndex], [field]: event.value }
            }))
        };
    };

    const [visibilitySettings, setVisibilitySettings] = useState({
        itemType: [],
        placementType: [],
        redirectionType: []
    })
    const getClientOptions = async () => {
        let clientOptions = await getClients();
        setClientOptions(clientOptions);
      };
    useEffect(() => {
        const fetchCtaItems = async () => {
            setIsLoading(true);
            try {
                const response = await getStoryCTAItemsData(props.match.params.id);
                const placementType = response?.PlacementType?.map((placement) => ({
                    label: placement,
                    value: placementTypeOptions.find(item => item.label.toLowerCase() === placement.toLowerCase(),
                    )?.value
                }))
                setVisibilitySettings(placementType)
                setCtaItems(response);
                setCardInputStates(response)

            } catch (error) {
            } finally {
                setIsLoading(false);
            }
        };
        fetchCtaItems();
        getClientOptions();
    }, []);
    const filenameUrlMap = {
        "share-v1.png": "https://assets.bobblekeyboard.net/stories/cta-icons/share-v1.png",
        "download-v1.png": "https://assets.bobblekeyboard.net/stories/cta-icons/download-v1.png",
        "whatsapp-v2.png": "https://assets.bobblekeyboard.net/stories/cta-icons/whatsapp-v2.png"
    };

    const placementTypeFilenameMap = {
        "shareStoryWhatsapp": "whatsapp-v2.png",
        "shareStory": "share-v1.png",
        "downloadStory": "download-v1.png",
    }
    const handleAddItem = (cardState) => {
        let index = Object.values(cardInputStates).length
        const newItem = {
            placementType: "generic",
            redirectionType: "native",
            textColor: "#000000",
            backgroundColor: "#FFFFFF",
            itemType: "openDeeplink",
            text: cardState?.text,
            iconFilename: cardInputStates[index]?.iconFilename,
            focusedTextColor: cardState?.focusedTextColor,
            focusedBackgroundColor: cardState?.focusedBackgroundColor,
            url: cardState?.url,
            priority: cardState?.priority,
        };
        setCtaItems([...ctaItems, newItem]);
        setCardInputStates(prevStates => {
            const updatedState = {
                ...prevStates,
            };
            updatedState[index] = newItem;
            return updatedState;
        });
    };
    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
    let setConfirmationModalState = (event) => {
        event.preventDefault();
        setConfirmationModalIsOpen(true);
    };
    const [
        enableLightThemeIconImageDropdown,
        setEnableLightThemeIconImageDropdown,
    ] = useState(true);
    const [storiesAssetsOptions, setQuickRepliesAssetsOptions] = useState(
        []
    );
    useEffect(() => {
        const getStoriesAssetsData = async () => {
            const response = await getStoriesCTAAssets();
            if (response) {
                const storiesAssetsOptions = response?.map((item) => {
                    return {
                        value: item.imageFilename,
                        label: <div>
                            <img src={item.iconURL} height="30px" width={"30"} alt={item.imageIdentifier} />    {item.imageIdentifier}
                        </div>,
                        url: item.iconURL,
                    };
                });

                setQuickRepliesAssetsOptions(storiesAssetsOptions);
            }
        }
        getStoriesAssetsData();
    }, []);
    const [lightThemeIconImage, setLightThemeIconImage] = useState({
        file: null,
        name: null,
        url: null,
    });
    const handleAddDeeplink = (cardState) => {
        let index = Object.values(cardInputStates).length + 1
        let newItem = {
            type: cardState?.type,
            placementType: cardState.placementType,
            itemType: "openDeeplink",
            text: cardState?.text,
            iconFilename: cardState?.iconFilename,
            textColor: cardState.textColor,
            backgroundColor: cardState?.backgroundColor,
            focusedTextColor: cardState?.focusedTextColor,
            focusedBackgroundColor: cardState?.focusedBackgroundColor,
            priority: Number(cardState?.priority),
            redirectionType: "deeplink"
        };
        switch (cardState.itemType) {
            case "shareStory":
                newItem.deeplink = `story/share?id=__STORY_ID__&text=__STORY_SHARE_TEXT__`;
                break;

            case "openWebBrowser":
                newItem.deeplink = `redirection/browser?destinationUrl=${encodeURIComponent(cardState.url)}`;
                break;

            case "downloadStory":
                newItem.deeplink = `story/download?id=__STORY_ID__`;
                break;

            case "shareStoryWhatsapp":
                newItem.deeplink = `story/whatsappShare?id=__STORY_ID__&text=__STORY_SHARE_TEXT__`;
                break;
        }
        setCtaItems([...ctaItems, newItem]);
        setCardInputStates(prevStates => {
            const updatedState = {
                ...prevStates,
                [index - 1]: {
                    ...prevStates[index - 1],
                }
            };
            updatedState[index - 1] = newItem;
            return updatedState;
        });

    };
    const handleDeleteItem = (index) => {
        setCtaItems((prevItems) => prevItems.filter((_, idx) => idx !== index));
        setCardInputStates(prevStates => {
            const updatedState = { ...prevStates }; // Create a copy
            delete updatedState[index]; // Remove the item
            return updatedState;
        });
    };
    const handleSaveItem = async (event) => {
        let payload = Object.values(cardInputStates)
        const response = await updateStoryCtaItems(props.match.params.id, payload);
        if (response.status === "success") {
            setSuccessModalText("updated Succesfully");
            setSuccessModalDisplay(true);
        } else {
            if (
                response.errorCode === error.tokenExpired ||
                response.errorCode === error.invalidAccessToken ||
                response.errorCode === error.unauthorized
            )
                redirectOnTokenExpiry();
            let text = errorDescription(error.unexpectedError);
            showErrorModal(text);
        }
    };

    return (
        <Container fluid className="p-0">
            <Row className="mb-3">
                <Col md={6} xs={6}>
                    <h1> CTA Items </h1>
                </Col>
                <Col md={6} xs={6}>
                    <Button onClick={handleAddItem} className="float-right">Add</Button>
                </Col>
            </Row>
            <Row>
                <Col md={6} xs={6}>
                    {/* Info section  */}
                    <Alert variant="info">
                        Note: Changes in Cta will get reflected only after submission
                    </Alert>
                </Col>
            </Row>
            <Row>
                {ctaItems.map((item, index) => (
                    <Col md={12} key={item.ID}>
                        <Card>
                            <CardBody>
                                <Button
                                    color="danger"
                                    className="ml-2 float-right"
                                    onClick={() => handleDeleteItem(index)}
                                >
                                    Delete
                                </Button>
                                <div className="clearfix"> </div>
                                <Row style={{ marginBottom: '15px' }}>
                                    <Col md={6}>
                                        <Label style={{ marginTop: '15px' }} for="ItemType">Redirection Type</Label>
                                        <Select
                                            id="ItemType"
                                            options={redirectionTypeOptions}
                                            value={cardInputStates[index]?.redirectionType !== undefined // Check if value exists in state
                                                ? redirectionTypeOptions.find(opt => opt.value === cardInputStates[index]?.redirectionType)
                                                : redirectionTypeOptions.find(opt => opt.value === item.redirectionType)}
                                            onChange={(event) => handleInputChange(event, 'redirectionType', index)}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Label style={{ marginTop: '15px' }} for="ItemType">Placement Type</Label>
                                        <Select
                                            id="ItemType"
                                            options={itemTypeOptions}
                                            value={itemTypeOptions.find(opt => opt.value === (cardInputStates[index]?.placementType || item.placementType))} // Pre-select
                                            onChange={(event) => handleInputChange(event, 'placementType', index)}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Label style={{ marginTop: '15px' }} for="PlacementType">Item Type</Label>
                                        <Select
                                            id="PlacementType"
                                            options={(cardInputStates[index]?.redirectionType !== 'native') ? placementTypeOptions : placementNativeTypeOptions}
                                            value={(cardInputStates[index]?.redirectionType !== 'native') ? placementTypeOptions.find(opt => opt.value === "openDeeplink") : placementNativeTypeOptions.find(opt => opt.value === (cardInputStates[index]?.itemType || item.itemType))} // Pre-select
                                            onChange={(event) => handleInputChange(event, 'itemType', index)}
                                        />
                                    </Col>
                                    <Col md={6} >
                                        <Label style={{ marginTop: '15px' }} for="Text">Text</Label><span className="text-danger">*</span>
                                        <Input
                                            id="Text"
                                            type="text"
                                            name="text"
                                            value={cardInputStates[index]?.text}
                                            onChange={(event) => handleInputChange(event, 'text', index)}
                                            required
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Label style={{ marginTop: '15px' }} for="TextColor">Text Color</Label>
                                        <div
                                            style={{
                                                backgroundColor: cardInputStates[index]?.textColor,
                                                width: "16px",
                                                height: "16px",
                                                display: "inline-block",
                                                verticalAlign: "middle",
                                                border: "1px solid #000",
                                                borderRadius: "30%",
                                                marginLeft: "5px"
                                            }}
                                        >
                                            {" "}
                                        </div>
                                        <Input
                                            id="textColor"
                                            type="text"
                                            name="textColor"
                                            value={cardInputStates[index]?.textColor}
                                            onChange={(event) => handleInputChange(event, 'textColor', index)}
                                        />
                                    </Col>

                                    <Col md={6}>
                                        <Label style={{ marginTop: '15px' }} for="BackgroundColor">Background Color</Label>
                                        <div
                                            style={{
                                                backgroundColor: cardInputStates[index]?.backgroundColor,
                                                width: "16px",
                                                height: "16px",
                                                display: "inline-block",
                                                verticalAlign: "middle",
                                                border: "1px solid #000",
                                                borderRadius: "30%",
                                                marginLeft: "5px"
                                            }}
                                        >
                                            {" "}
                                        </div>
                                        <Input
                                            id="BackgroundColor"
                                            type="text"
                                            name="BackgroundColor"
                                            value={cardInputStates[index]?.backgroundColor}
                                            onChange={(event) => handleInputChange(event, 'backgroundColor', index)}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Label style={{ marginTop: '15px' }} for="FocusedTextColor">Focused Text Color</Label>
                                        <div
                                            style={{
                                                backgroundColor: cardInputStates[index]?.focusedTextColor,
                                                width: "16px",
                                                height: "16px",
                                                display: "inline-block",
                                                verticalAlign: "middle",
                                                border: "1px solid #000",
                                                borderRadius: "30%",
                                                marginLeft: "5px"
                                            }}
                                        >
                                            {" "}
                                        </div>
                                        <Input
                                            id="FocusedTextColor"
                                            type="text"
                                            name="focusedTextColor"
                                            value={cardInputStates[index]?.focusedTextColor}
                                            onChange={(event) => handleInputChange(event, 'focusedTextColor', index)}
                                        />
                                    </Col>

                                    <Col md={6}>
                                        <Label style={{ marginTop: '15px' }} for="FocusedBackgroundColor">Focused Background Color</Label>
                                        <div
                                            style={{
                                                backgroundColor: cardInputStates[index]?.focusedBackgroundColor,
                                                width: "16px",
                                                height: "16px",
                                                display: "inline-block",
                                                verticalAlign: "middle",
                                                border: "1px solid #000",
                                                borderRadius: "30%",
                                                marginLeft: "5px"
                                            }}
                                        >
                                            {" "}
                                        </div>
                                        <Input
                                            id="FocusedBackgroundColor"
                                            type="text"
                                            name="focusedBackgroundColor"
                                            value={cardInputStates[index]?.focusedBackgroundColor}
                                            onChange={(event) => handleInputChange(event, 'focusedBackgroundColor', index)}
                                        />
                                    </Col>
                                    <Col md={6} >
                                        <Label style={{ marginTop: '15px' }} >Priority</Label><ToolTip
                                            data={"For left CTA priority should be 1. For right Priority should be 0"}
                                            id="staticIconTooltip">

                                        </ToolTip>
                                        <Input
                                            id="Priority"
                                            type="number"
                                            min={0}
                                            max={1}
                                            step={1}
                                            name="FocusedBackgroundColor"
                                            value={cardInputStates[index]?.priority}
                                            onChange={(event) => handleInputChange(event, 'priority', index)}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>
                                                <strong>
                                                    Client Id{" "}
                                                    <span className="text-danger">*</span>
                                                </strong>
                                            </Label>
                                            <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                name="clientId"
                                                value={clientOptions?.find(option => option.value === cardInputStates[index]?.clientId)}
                                                placeholder="Select Client Id"
                                                isClearable={false}
                                                options={clientOptions}
                                                onChange={(event) => handleInputChange(event, 'clientId', index)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    {(formInput.redirectionType === 'native' || cardInputStates[index]?.redirectionType === `native`) && (cardInputStates[index]?.itemType === `openWebBrowser`) && ( // Show this if RedirectionType is 'Native'
                                        <Col md={6}>
                                            <Label style={{ marginTop: '15px' }} for="DisplayURL"> URL</Label>
                                            <textarea
                                                id="DisplayURL"
                                                type="textArea"
                                                name="url"
                                                value={cardInputStates[index]?.url}
                                                onChange={(event) => handleInputChange(event, 'url', index)}
                                                style={{ width: '100%' }}
                                            />
                                        </Col>
                                    )}
                                    {(formInput.redirectionType === 'deeplink' || cardInputStates[index]?.redirectionType === `deeplink`) && ( // Show this otherwise
                                        <Col md={6}>
                                            <Label style={{ marginTop: '15px' }} for="Deeplink">Deeplink</Label>
                                            <textarea
                                                id="Deeplink"
                                                type="textArea"
                                                name="deeplink"
                                                value={cardInputStates[index]?.deeplink}
                                                onChange={(event) => handleInputChange(event, 'deeplink', index)}
                                                style={{ width: '100%' }}
                                            />
                                        </Col>
                                    )}
                                </Row>
                                <Row style={{ width: '100%' }} >
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>
                                                <strong>
                                                    Icon Image <span className="text-danger">*</span>
                                                    <p style={{ fontSize: 12, marginBottom: 0 }}>
                                                        The image should be jpg/png/gif/webp
                                                    </p>
                                                </strong>
                                            </Label>
                                            <Button
                                                className="float-right btn-sm"
                                                onClick={(e) => {
                                                    setEnableLightThemeIconImageDropdown(
                                                        !enableLightThemeIconImageDropdown
                                                    );
                                                }}
                                            >
                                                {enableLightThemeIconImageDropdown
                                                    ? "Upload new image"
                                                    : "Select from Uploaded Images"}
                                            </Button>

                                            <br />

                                            {!enableLightThemeIconImageDropdown && (
                                                <Container className="custom-file">
                                                    <Input
                                                        type="file"
                                                        className="custom-file-input"
                                                        accept="image/*"
                                                        disabled={false}
                                                        onChange={(event) => {
                                                            const file = event?.target?.files[0];
                                                            var reader = new FileReader();
                                                            reader.readAsDataURL(file);
                                                            reader.onload = () => {
                                                                const image = new Image();
                                                                image.src = reader.result;
                                                                image.onload = () => {
                                                                    setCardInputStates(prevStates => {
                                                                        const updatedState = { ...prevStates };
                                                                        updatedState[index] = {
                                                                            ...updatedState[index],
                                                                            iconFilename: file.name,
                                                                            iconImageFile: reader.result,
                                                                        }
                                                                        return updatedState;
                                                                    });
                                                                }
                                                            };
                                                        }}
                                                    />
                                                    <Label className="custom-file-label">
                                                        <strong>
                                                            {updateStoryCtaItems[index]?.iconFilename === null
                                                                ? "Choose an image"
                                                                : updateStoryCtaItems[index]?.iconFilename}
                                                        </strong>

                                                    </Label>
                                                </Container>
                                            )}

                                            {enableLightThemeIconImageDropdown && (
                                                <Select
                                                    className="react-select-container"
                                                    classNamePrefix="react-select"
                                                    name="lightThemeIconImage"
                                                    value={storiesAssetsOptions.filter(
                                                        (item) => item?.value === cardInputStates[index]?.iconFilename
                                                    )}
                                                    placeholder="Select Uploaded Images"
                                                    isClearable={false}
                                                    onChange={(option) => {
                                                        // setLightThemeIconImage({
                                                        //     ...lightThemeIconImage,
                                                        //     name: option?.value,
                                                        //     url: option?.url,
                                                        // });
                                                        setCardInputStates(prevStates => {
                                                            const updatedState = { ...prevStates };
                                                            updatedState[index] = {
                                                                ...updatedState[index],
                                                                iconFilename: option?.value,
                                                            }
                                                            return updatedState;
                                                        });
                                                    }}
                                                    isDisabled={false}
                                                    options={storiesAssetsOptions}
                                                />
                                            )}

                                            {(cardInputStates[index]?.iconImageFile || cardInputStates[index]?.iconURL) && (
                                                <Col className="pb-4 mb-3 mt-3">
                                                    <img
                                                        src={cardInputStates[index]?.iconImageFile || cardInputStates[index]?.iconURL}
                                                        alt="preview"
                                                        width="100"
                                                    />
                                                </Col>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    {(formInput.redirectionType === 'native' || cardInputStates[index]?.redirectionType === `native`) ? <Col md={6} xs={6}>
                                        <Button onClick={() => handleAddDeeplink(cardInputStates[index])} className="float-right">Create corresponding Deeplink CTA </Button>
                                    </Col> : null}
                                </Row>

                            </CardBody>
                        </Card>
                    </Col>
                ))}
                <Button onClick={handleSaveItem}>Submit</Button>
            </Row>
            <ConfirmationModal
                show={confirmationModalIsOpen}
                modaltext={"submit"}
                onConfirmationTrue={() => {
                    setConfirmationModalIsOpen(false);
                    handleSaveItem()
                }}
                onHide={() => setConfirmationModalIsOpen(false)}
            />
            <ResponseModal
                show={successModalDisplay}
                onHide={successModalClose}
                modalheading={"Success"}
                modaltext={successModalText}
            />
            <ResponseModal
                show={failureModalDisplay}
                onHide={failureModalClose}
                modalheading={"Error"}
                modaltext={failureModalText}
            />
        </Container >
    );
};

export default CTAItemsPage;

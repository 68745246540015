import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardImg,
  Container,
  Row,
  Col,
  CardTitle,
  Badge,
  Input,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas, faInfoCircle, faGlobe } from "@fortawesome/free-solid-svg-icons";
import { permissions, redirectTo } from "../../../../utilities/commonUtil";
import { retrieveFromLocalStorage } from "../../../../utilities/localStorageUtil";
import UncontrolledButtonDropdown from "reactstrap/lib/UncontrolledButtonDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";

class Sticker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allowedPermissions: retrieveFromLocalStorage("userPermissions"),
    };
  }

  render() {
    return (
      <React.Fragment>
        {/* className="importPageCard" */}
        <Card className="importPageCard">
          <Input
            type="checkbox"
            className="importPageCheckbox"
            id={this.props.id}
            checked={this.props.isChecked}
            onChange={(event) =>
              this.props.handleCheckboxChange(event, this.props.sticker)
            }
          />
          <React.Fragment>
            <CardImg
              top
              src="/img/white-bg.png"
              onLoad={(e) => (e.target.src = this.props.image)}
              alt="Card image cap"
              className="cursor-pointer"
              onClick={() => {
                redirectTo(`/stickers/${this.props.id}`);
              }}
            />
          </React.Fragment>
          <CardBody className="px-4">
            <CardTitle tag="h3" className="mb-0">
              {this.props.imageProcessingStatus === "completed" ? (
                <Badge className="float-right" color={this.props.color}>
                  {this.props.status}
                </Badge>
              ) : null}

              {this.props.imageProcessingStatus === "ongoing" ? (
                <Badge style={{ whiteSpace: "initial" }}>
                  Image under processing
                </Badge>
              ) : null}

              {this.props.imageProcessingStatus === "not_started" ? (
                <Badge style={{ whiteSpace: "initial" }}>
                  Processing not started
                </Badge>
              ) : null}
            </CardTitle>

            <small>ID : {this.props.id}</small>
            <br />
            <br />
            <small className="font-weight-bold">
              Start At : {this.props.startAt ? new Date(this.props.startAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' }) : "Not available"}
            </small>
            <br />
            <small className="font-weight-bold">
              End At : {this.props.endAt ? new Date(this.props.endAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' }) : "Not available"}
            </small>
            <br />
            <small className="font-weight-bold">
              Created At : {this.props.createdAt}
            </small>

            <br />
            <small className="font-weight-bold">
              Published At : {this.props.publishedAt}
            </small>
            <br />
            <small className="font-weight-bold">
              Deactivated At : {this.props.deactivatedAt}
            </small>
            <br />
            <small className="font-weight-bold">
              Global Status :{"  "}
              <Badge color={this.props.globalColor}>
                {this.props.globalStatus}
              </Badge>
            </small>
            <br />
            <small className="font-weight-bold">
              Is Head Supported :{"  "}
              {this.props.isHeadSupported ? "Yes" : "No"}
            </small>
          </CardBody>
          <CardBody className="px-4">
            <Container className="text-center" fluid>
              <Row>
                <Col sm="12" md="12">
                  <Button
                    className="mw-75 w-120"
                    color="info"
                    onClick={() => {
                      redirectTo(`/stickers/${this.props.id}`);
                    }}
                  >
                    Details
                    <FontAwesomeIcon
                      icon={(fas, faInfoCircle)}
                      className="ml-2"
                    ></FontAwesomeIcon>
                  </Button>
                  {"  "}
                  <UncontrolledButtonDropdown className="my-auto">
                    <DropdownToggle caret color="primary">
                      {"Update Status"}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => this.props.displayStickerStatusModal(this.props.sticker, "publish")}>Publish</DropdownItem>
                      <DropdownItem onClick={() => this.props.displayStickerStatusModal(this.props.sticker, "activate")}>Activate</DropdownItem>
                      <DropdownItem onClick={() => this.props.displayStickerStatusModal(this.props.sticker, "deactivate")}>Deactivate</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default Sticker;

import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Card,
  CardBody,
} from "reactstrap";
import { useParams, useHistory } from "react-router-dom";
import ResponseModal from "../ResponseModal";
import { errorModalHeading } from "../../../config/config";
import { createtask } from "../../../utilities/apiUtils/tasks";
import CustomTooltip from "../../../components/customToolTip";

const TaskForm = (props) => {
  const [formData, setFormData] = useState({
    name: "",
    identifier: "",
    description: "",
    listing_priority: 1,
    num_coins: "",
    cta_text: "",
    cta_action: "",
    static_icon_src: "",
    is_listed: 0,
    file_data_url: "", // Added for file upload
    type: "",
    daily_limit: "",
  });

  const history = useHistory();

  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
    props.onHide();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const parsedValue = name === "is_listed" ? parseInt(value) : value;
    setFormData({ ...formData, [name]: parsedValue });
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setFormData({ ...formData, file_data_url: event.target.result });
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure is_listed is not null
    const payload = {
      ...formData,
      listing_priority: parseInt(formData.listing_priority),
      num_coins: parseInt(formData.num_coins),
      static_icon_filename: "",
      static_icon_src: formData.file_data_url,
      daily_limit: parseInt(formData.daily_limit),
    };

    try {
      const response = await createtask(payload);
      history.push(`/tasksEvent/add/${response.task_id}`);
    } catch (error) {
      showErrorModal("Failed to add task. Please try again later.");
    }
  };

  return (
    <Container className="task-form-container my-5">
      <Card className="task-form-card">
        <CardBody>
          <h1 className="task-form-title mb-4">Add task</h1>
          <Form onSubmit={handleSubmit}>
            <FormGroup className="mb-4">
              <Label for="name">
                Name <span className="text-danger">*</span>
                {/* Tooltip for Name */}
                <CustomTooltip
                  target="identifier-tooltip"
                  content={<>Enter the name for the task.</>}
                  placement="right"
                />
              </Label>
              <Input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="task-form-input"
              />
            </FormGroup>

            <FormGroup className="mb-4">
              <Label for="identifier">
                Identifier <span className="text-danger">*</span>
                {/* Tooltip for Identifier */}
                <CustomTooltip
                  target="identifier-tooltip"
                  content={<>Enter the identifier for the task.</>}
                  placement="right"
                />
              </Label>
              <Input
                type="text"
                name="identifier"
                id="identifier"
                value={formData.identifier}
                onChange={handleChange}
                required
                className="task-form-input"
              />
            </FormGroup>

            <FormGroup className="mb-3">
              <Label for="description">
                Description <span className="text-danger">*</span>
                <CustomTooltip
                  target="description-tooltip"
                  content={<>Enter the description for the task.</>}
                  placement="right"
                />
              </Label>
              <Input
                type="textarea"
                name="description"
                id="description"
                value={formData.description}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup className="mb-4">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 me-4">
                  <Label for="listing_priority" className="mb-2">
                    Priority <span className="text-danger">*</span>
                    {/* Tooltip for Priority */}
                    <CustomTooltip
                      target="priority-tooltip"
                      content={<>Enter the priority for the task.</>}
                      placement="right"
                    />
                  </Label>
                  <Input
                    type="number"
                    name="listing_priority"
                    id="listing_priority"
                    value={formData.listing_priority}
                    onChange={handleChange}
                    required
                    className="task-form-input"
                  />
                </div>
                <div className="flex-grow-1">
                  <Label for="num_coins" className="mb-2">
                    Coins <span className="text-danger">*</span>
                    {/* Tooltip for Coins */}
                    <CustomTooltip
                      target="coins-tooltip"
                      content={<>Enter the number of coins for the task.</>}
                      placement="right"
                    />
                  </Label>
                  <Input
                    type="number"
                    name="num_coins"
                    id="num_coins"
                    value={formData.num_coins}
                    onChange={handleChange}
                    required
                    className="task-form-input"
                  />
                </div>
              </div>
            </FormGroup>

            <FormGroup className="mb-4">
              <Label for="cta_text">CTA Text</Label>
              {/* Tooltip for CTA Text */}
              <CustomTooltip
                target="cta-text-tooltip"
                content={<>Enter the call-to-action text for the task.</>}
                placement="right"
              />
              <Input
                type="text"
                name="cta_text"
                id="cta_text"
                value={formData.cta_text}
                onChange={handleChange}
                className="task-form-input"
              />
            </FormGroup>

            <FormGroup className="mb-4">
              <Label for="cta_action">CTA Action</Label>
              {/* Tooltip for CTA Action */}
              <CustomTooltip
                target="cta-action-tooltip"
                content={<>Enter the call-to-action action for the task.</>}
                placement="right"
              />
              <Input
                type="text"
                name="cta_action"
                id="cta_action"
                value={formData.cta_action}
                onChange={handleChange}
                className="task-form-input"
              />
            </FormGroup>

            <FormGroup className="mb-4">
              <Label for="is_listed">
                Is Listed <span className="text-danger">*</span>
                {/* Tooltip for Is Listed */}
                <CustomTooltip
                  target="is-listed-tooltip"
                  content={
                    <>Select whether the task should be listed or not.</>
                  }
                  placement="right"
                />
              </Label>
              <Input
                type="select"
                name="is_listed"
                id="is_listed"
                value={formData.is_listed}
                onChange={handleChange}
                required
                className="task-form-input"
              >
                <option value={0}>No</option>
                <option value={1}>Yes</option>
              </Input>
            </FormGroup>

            <FormGroup className="mb-4">
              <Label for="file">Image File</Label>
              {/* Tooltip for Image File */}
              <CustomTooltip
                target="image-file-tooltip"
                content={<>Upload an image file for the task.</>}
                placement="right"
              />
              <Input
                type="file"
                name="file"
                id="file"
                onChange={handleFileChange}
                className="task-form-input"
              />
              {formData.file_data_url && (
                <div className="task-form-image-preview mt-3">
                  <img
                    src={formData.file_data_url}
                    alt="Selected File"
                    className="task-form-preview-image img-thumbnail"
                    style={{ maxWidth: "150px" }}
                  />
                </div>
              )}
            </FormGroup>

            <FormGroup className="mb-4">
              <Label for="type">Type</Label>
              {/* Tooltip for Type */}
              <CustomTooltip
                target="type-tooltip"
                content={<>Select the type of task.</>}
                placement="right"
              />
              <Input
                type="select"
                name="type"
                id="type"
                value={formData.type}
                onChange={handleChange}
                className="task-form-input"
              >
                <option value="">Select type</option>
                <option value="once">Once</option>
                <option value="daily">Daily</option>
              </Input>
            </FormGroup>

            {/* New form group for daily limit */}
            <FormGroup className="mb-4">
              <Label for="daily_limit">Daily Limit</Label>
              {/* Tooltip for Daily Limit */}
              <CustomTooltip
                target="daily-limit-tooltip"
                content={<>Enter the daily limit for the task.</>}
                placement="right"
              />
              <Input
                type="number"
                name="daily_limit"
                id="daily_limit"
                value={formData.daily_limit}
                onChange={handleChange}
                className="task-form-input"
              />
            </FormGroup>

            <Button color="primary" type="submit" className="task-form-button">
              Add task
            </Button>
          </Form>
        </CardBody>
      </Card>
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={errorModalHeading}
        modaltext={failureModalText}
      />
    </Container>
  );
};

export default TaskForm;

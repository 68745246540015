import React, { useState, useEffect } from "react";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import { Button } from "react-bootstrap";
import { IoCaretDown, IoCaretUp } from "react-icons/io5";
import Select from "react-select";
import moment from "moment";
import { autoCappingTableColumns, cappingRulesTableColumns } from "./tableColumn";
import ConfirmationModal from "../ConfirmationModal";
import ResponseModal from "../ResponseModal";
import {
  successModalHeading,
  errorModalHeading,
} from "../../../config/config";
import filterFactory from "react-bootstrap-table2-filter";
import Loading from "../../../components/Loading";
import paginationFactory from "react-bootstrap-table2-paginator";
import Search from "../../../components/Search";
import BootstrapTable from "react-bootstrap-table-next";
import {
  errorDescription,
  error
} from "../../../utilities/commonUtil";
import { createAutoCapping, deleteAutoCapping, fetchAutoCappings, fetchBrandCampaignsAds, fetchBrandCampaignsConfig, updateAutoCapping } from "../../../utilities/apiUtils/autoCappings";
import AutoCappingModal from "./AutoCappingModal";
import { allActions, autoCappingsResponses, buyModelOptions, createDeepCopy, endAtSuffix, getColorByPercentage, getDailyTarget, isAutoCappingsCreatePermission, isAutoCappingsDeletePermission, isAutoCappingsEditPermission, parseDateInMoment, startAtSuffix, statusOptions } from "../../../config/configAutoCappings";
import ToolTip from "../../../utilities/ToolTip";

const formDataInitialState = {
  id: "",
  metrics: "",
  target: "",
  completed: "",
  rules: []
}

const AutoCapping = () => {
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState(formDataInitialState)
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [autoCappings, setAutoCappings] = useState([]);
  const [autoCappingsDetails, setAutoCappingsDetails] = useState([]);
  const [brandCampaigns, setBrandCampaigns] = useState([])
  const [brandCampaignsAds, setBrandCampaignsAds] = useState([])
  const [statusFilter, setStatusFilter] = useState("");
  const [buyModelFilter, setBuyModelFilter] = useState("");
  const [nameIdFilter, setNameIdFilter] = useState("");
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [action, setAction] = useState("");
  const [id, setId] = useState(null);
  const [brandCampaignTitle, setBrandCampaignTitle] = useState("")
  const [successModalText, setSuccessModalText] = useState("");
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [changeType, setChangeType] = useState("Add")
  const [isAutoCappingModalVisible, setIsAutoCappingModalVisible] = useState(false)

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    fetchAutoCappingsTableData()
  };

  let showAutoCappingModal = (type) => {
    setChangeType(type)
    setIsAutoCappingModalVisible(true)
  }

  let hideAutoCappingModal = () => {
    setIsAutoCappingModalVisible(false)
    setFormData(formDataInitialState)
  }

  let onTableChange = (type, newState) => { };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  const handleClick = () => {
    setStatusFilter("");
    setBuyModelFilter("");
    setNameIdFilter("")
    sessionStorage.removeItem("autoCappingsFilterStatus");
    sessionStorage.removeItem("autoCappingsFilterBuyModel");
    sessionStorage.removeItem("autoCappingsFilterNameId")
  };

  const ClearButton = () => {
    return (
      <Button
        style={{ float: "right" }}
        className="form-control-lg"
        onClick={handleClick}
      >
        Reset Filters
      </Button>
    );
  };

  let setConfirmationModalState = (id, title, event) => {
    setAction(event);
    setConfirmationModalIsOpen(true);
    setId(id);
    setBrandCampaignTitle(title)
  };

  const expandCappingRule = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div className="my-3">
        <Container fluid>
          <Row>
            <Col>
              <h5>Date Range:</h5>
            </Col>
            <Col>
              <h5>Overall Target:</h5>
            </Col>
            <Col>
              <h5>Initially defined target per day:</h5>
            </Col>
            <Col>
              <h5>Auto-adjusted target per day{" "}
                <ToolTip
                  data="Automatically adjusted daily target to meet the overall target within the campaign distribution's remaining days."
                  id="autoAdjustedTargetPerDay"
                />:
              </h5>
            </Col>
            <Col>
              <h5>Overall Complete %:</h5>
            </Col>
            <Col>
              <h5>Current Day Complete %:</h5>
            </Col>
          </Row>
          {row.distribution?.map((distribution, index) => {
            const percent = Number(distribution.completed / distribution.overallTarget * 100)
            const roundedPercent = percent ? percent.toFixed(2) : 0
            const percentCurrDay = Number(distribution.completedCurrentDay / distribution.targetPerDay * 100)
            const roundedPercentCurrDay = percentCurrDay ? percentCurrDay.toFixed(2) : 0
            const initiallyDefinedTargetPerDay = getDailyTarget(distribution.overallTarget, parseDateInMoment(distribution.startAt).format('YYYY-MM-DD') + startAtSuffix, parseDateInMoment(distribution.endAt).format('YYYY-MM-DD') + endAtSuffix)
            return (
              <Row key={distribution.id} className="align-items-center">
                <Col>{parseDateInMoment(distribution.startAt).format("Do MMM YYYY") + " - " + parseDateInMoment(distribution.endAt).format("Do MMM YYYY")}</Col>
                <Col>{distribution.overallTarget + " " + row.metrics}</Col>
                <Col>{initiallyDefinedTargetPerDay + " " + row.metrics}/day</Col>
                <Col>
                  {distribution.targetPerDay === initiallyDefinedTargetPerDay ? "-" : distribution.targetPerDay + " " + row.metrics + "/day"}
                  {distribution.targetPerDay === initiallyDefinedTargetPerDay
                    ? null
                    : distribution.targetPerDay > initiallyDefinedTargetPerDay
                      ? <IoCaretUp color="#5fc27e" size={20} />
                      : <IoCaretDown color="#f44455" size={20} />
                  }
                </Col>
                <Col>
                  <div style={{ color: roundedPercent ? getColorByPercentage(roundedPercent) : "#495057", fontWeight: 900 }}>
                    {roundedPercent ? roundedPercent + "%" : "-"}
                  </div>
                  <small className="font-weight-bold">({distribution.completed ?? 0} {row.metrics})</small>
                </Col>
                <Col>
                  <div style={{ color: roundedPercentCurrDay ? getColorByPercentage(roundedPercentCurrDay) : "#495057", fontWeight: 900 }}>
                    {(roundedPercentCurrDay > 100 || roundedPercentCurrDay === Infinity) ? "Target Exceeded" : roundedPercentCurrDay ? roundedPercentCurrDay + "%" : "-"}
                  </div>
                  <small className="font-weight-bold">({distribution.completedCurrentDay ?? 0} {row.metrics})</small>
                </Col>
                {(index + 1 !== row.distribution.length) && (
                  <Col md={12}>
                    <hr />
                  </Col>
                )}
              </Row>
            )
          })}
        </Container>
      </div>
    ),
  };

  const filterCappingRules = (rule) => {
    let items = rule;

    return items;
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <div className="d-flex align-items-center my-3">
          <h4 className="mb-0">Capping Rules</h4>
          {isAutoCappingsEditPermission() && (
            <Button
              className="mx-3"
              onClick={() => {
                showAutoCappingModal("Edit")
                setFormData((prevState) => ({
                  ...prevState,
                  id: row.id,
                  metrics: row.metrics,
                  target: row.target,
                  completed: row.completed,
                  rules: row.rules
                }))
              }}
            >
              Edit Capping Rules
            </Button>
          )}

          {isAutoCappingsDeletePermission() && (
            <Button
              className="btn-danger"
              onClick={(event) =>
                setConfirmationModalState(row.id, row.name, event.target.innerHTML)
              }
            >
              Delete Capping Rules
            </Button>
          )}
        </div>
        <BootstrapTable
          bootstrap4={true}
          wrapperClasses="table-responsive"
          hover={true}
          bordered={false}
          keyField="id"
          data={filterCappingRules(row.rules)}
          columns={cappingRulesTableColumns}
          expandRow={expandCappingRule}
          filter={filterFactory()}
          defaultSorted={[{
            dataField: 'completed',
            order: 'desc'
          }]}
          onTableChange={onTableChange}
          remote={{ filter: true }}
          noDataIndication="No results found"
        />
      </div>
    ),
  };

  let deleteRecord = async (id, title) => {
    try {
      let autoCapping = autoCappings.find(el => el.id === id)
      autoCapping.name = title
      let response = await deleteAutoCapping(id, autoCapping);
      if (response.status === "success") {
        let text = "Auto Capping has been deleted";
        showSuccessModal(text);
      } else {
        let text
        if (response?.errorDescription)
          text = response?.errorDescription?.split("):")[1];
        else
          text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  };

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let fetchAutoCappingsTableData = async () => {
    setLoading(true)
    try {
      let response = await fetchAutoCappings();
      if (response?.length > 0) {
        setAutoCappings(response);
      } else {
        setAutoCappings([])
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    } finally {
      setLoading(false);
    }
  };

  const fetchBrandCampaigns = async () => {
    try {
      let response = await fetchBrandCampaignsConfig()
      if (response?.brandCampaigns?.length > 0) {
        setBrandCampaigns(response.brandCampaigns)
      } else {
        setBrandCampaigns([])
        setAutoCappingsDetails([])
      }
    } catch (err) {
      console.log(err);
    }
  }

  const getBrandCampaignsAds = async () => {
    try {
      let response = await fetchBrandCampaignsAds()
      if (Object.keys(response).length > 0) {
        setBrandCampaignsAds(response)
      } else {
        setBrandCampaignsAds({})
      }
    } catch (err) {
      console.log(err);
    }
  }

  const filterAutoCappings = () => {
    let items = autoCappingsDetails;

    if (statusFilter.value)
      items = items.filter((item) => item.status === statusFilter.value);

    if (buyModelFilter.value)
      items = items.filter((item) => item.mediaBuyType === buyModelFilter.value);

    if (nameIdFilter) {
      items = items.filter((item) =>
        item.id?.toString()?.includes(nameIdFilter.toLowerCase()) || item.name?.toLowerCase()?.includes(nameIdFilter.toLowerCase())
      );
    }

    return items;
  };

  useEffect(() => {
    if (autoCappings?.length > 0 && brandCampaigns?.length > 0) {
      const brandCampaignsMap = new Map()
      brandCampaigns.forEach(el => {
        brandCampaignsMap.set(el.id, el)
      })
      const updatedAutoCapping = createDeepCopy(autoCappings)
      updatedAutoCapping.forEach(el => {
        if (brandCampaignsMap.has(el.id)) {
          const { name, mediaBuyType, deliveryTarget, unitPriceINR, roAmountINR, startAt, endAt } = brandCampaignsMap.get(el.id)
          const diff = parseDateInMoment(endAt).startOf('day').diff(moment().startOf('day'), 'days');
          el.name = name
          el.mediaBuyType = mediaBuyType
          el.deliveryTarget = deliveryTarget
          el.unitPriceINR = unitPriceINR
          el.roAmountINR = roAmountINR
          el.startAt = startAt
          el.endAt = endAt
          el.completePercentage = el.completed / el.target * 100
          el.days = diff
          el.status = diff < 0 ? "Inactive" : "Active"
          if (el.rules?.length > 0) {
            el.rules.forEach(rule => {
              rule.cappingDistribution = rule.distributionType === "even" ? "Even" : "Custom"
              rule.completePercentage = rule.completed / rule.target * 100
            })
          }
        }
      })
      setAutoCappingsDetails(updatedAutoCapping)
    }
  }, [autoCappings, brandCampaigns])

  useEffect(() => {
    fetchAutoCappingsTableData();
    fetchBrandCampaigns();
    getBrandCampaignsAds();

    // Fill stored filter values
    if (sessionStorage.getItem("autoCappingsFilterStatus"))
      setStatusFilter({
        value: sessionStorage.getItem("autoCappingsFilterStatus") ?? "",
        label: statusOptions.find(option => option.value === sessionStorage.getItem("autoCappingsFilterStatus"))?.label ?? "",
      })
    if (sessionStorage.getItem("autoCappingsFilterBuyModel"))
      setBuyModelFilter({
        value: sessionStorage.getItem("autoCappingsFilterBuyModel") ?? "",
        label: buyModelOptions.find(option => option.value === sessionStorage.getItem("autoCappingsFilterBuyModel"))?.label ?? "",
      })
    if (sessionStorage.getItem("autoCappingsFilterNameId"))
      setNameIdFilter(sessionStorage.getItem("autoCappingsFilterNameId"))
  }, []);

  const handleSubmit = async (data) => {
    const { id, name, ...rest } = data
    let payload = {
      id,
      name,
      ...rest
    }
    try {
      let response
      if (changeType === "Add")
        response = await createAutoCapping(payload)
      else {
        let oldAutoCapping = autoCappings.find(el => el.id === id)
        oldAutoCapping.name = name
        payload = {
          oldAutoCapping,
          updatedAutoCapping: payload
        }
        response = await updateAutoCapping(id, payload)
      }
      if (response?.status === "success") {
        setSuccessModalText(changeType === "Add" ? autoCappingsResponses.addedSuccessfully : autoCappingsResponses.updatedSuccessfully);
        setSuccessModalDisplay(true);
      } else {
        let text
        if (response?.errorDescription)
          text = response?.errorDescription?.split("):")[1];
        else
          text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      }
    } catch (err) {
      let text = errorDescription(error.unexpectedError);
      showErrorModal(text);
    } finally {
      hideAutoCappingModal()
    }
  }

  return (
    <Container fluid className="p-0">
      <Row>
        <h1 className="mb-4 mt-2 w-50 pl-3">Auto Cappings</h1>

        {isAutoCappingsCreatePermission() && (
          <div className="d-flex align-items-center ml-auto">
            <Button color="success" className="d-flex btn-success mb-4 mr-3">
              <p className="mb-1 mt-1" style={{ fontSize: "1rem" }} onClick={() => showAutoCappingModal("Add")}>
                Add New Auto Capping
              </p>
            </Button>
          </div>
        )}
      </Row>
      <div>
        <Card style={{ overflow: "hidden", paddingBottom: "100px" }}>
          <div container-fullwidth="true" style={{ margin: "2%" }}>
            <Row className="justify-content-md-center mx-auto">
              <Col md={3} sm={12} className="mt-3">
                <Select
                  className="react-select-container"
                  id="statusSelect"
                  classNamePrefix="react-select"
                  name="status"
                  placeholder="Select Status"
                  value={statusFilter}
                  onChange={(event) => {
                    setStatusFilter(event);
                    sessionStorage.setItem("autoCappingsFilterStatus", event.value);
                  }}
                  options={statusOptions}
                />
              </Col>
              <Col md={3} sm={12} className="mt-3">
                <Select
                  className="react-select-container"
                  id="buyModelSelect"
                  classNamePrefix="react-select"
                  name="buyModel"
                  placeholder="Select Buy Model"
                  value={buyModelFilter}
                  onChange={(event) => {
                    setBuyModelFilter(event);
                    sessionStorage.setItem("autoCappingsFilterBuyModel", event.value);
                  }}
                  options={buyModelOptions}
                />
              </Col>
              <Col md={6} xs={5} className="mt-3">
                <ClearButton>Reset Filters</ClearButton>
              </Col>
            </Row>
            <Row className="justify-content-md-center mx-auto">
              <Col md={12} sm={12} className="mt-3">
                <Search
                  placeholder="Search any entity by ID or name"
                  value={nameIdFilter}
                  onChange={(e) => {
                    setNameIdFilter(e.target.value)
                    sessionStorage.setItem("autoCappingsFilterNameId", e.target.value)
                  }}
                ></Search>
              </Col>
            </Row>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <CardBody>
              <BootstrapTable
                bootstrap4={true}
                wrapperClasses="table-responsive"
                bordered={false}
                keyField="id"
                data={filterAutoCappings()}
                columns={autoCappingTableColumns}
                expandRow={expandRow}
                filter={filterFactory()}
                pagination={paginationFactory({
                  sizePerPage: 10,
                  sizePerPageList: [5, 10, 25, 50],
                })}
                defaultSorted={[{
                  dataField: 'days',
                  order: 'desc'
                }]}
                onTableChange={onTableChange}
                remote={{ filter: true }}
                noDataIndication="No results found"
              />
            </CardBody>
          )}
        </Card>
      </div>
      <AutoCappingModal
        show={isAutoCappingModalVisible}
        type={changeType}
        brandCampaigns={brandCampaigns}
        brandCampaignsAds={brandCampaignsAds}
        autoCappingsDetails={autoCappingsDetails}
        formData={formData}
        onSubmit={handleSubmit}
        onHide={hideAutoCappingModal}
      />
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={action}
        onConfirmationTrue={() => {
          setConfirmationModalIsOpen(false);
          if (action === allActions.delete) deleteRecord(id, brandCampaignTitle)
        }}
        onHide={() => setConfirmationModalIsOpen(false)}
      />
      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={successModalHeading}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={errorModalHeading}
        modaltext={failureModalText}
      />
    </Container>
  );
};

export default AutoCapping;
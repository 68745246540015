import React, { Component } from "react";
import Search from "../../../components/Search"
import Select from "react-select";
import { Link } from "react-router-dom";
import { Button, Badge } from "react-bootstrap";
import {
  Card,
  CardImg,
  Row,
  Col,
  Container,
  CardBody,
  Spinner,
  CardTitle,
  Label,
} from "reactstrap";
import {
  errorDescription,
  error,
  allActions,
  redirectTo
} from "../../../utilities/commonUtil";
import { config, formatTime, showActivateSuggestionDrawerContentButton, showSuggestionDrawerContentButton, showCreateSuggestionDrawerContentButton, showDeactivateSuggestionDrawerContentButton, showEditSuggestionDrawerCategoryTargetingRuleButton, showEditSuggestionDrawerContentButton, showEditSuggestionDrawerContentTargetingRuleButton, showPublishSuggestionDrawerContentButton, showEditSuggestionDrawerContentRuleButton } from "../../../config/configSuggestionDrawer"
import ResponseModal from "../ResponseModal";
import InfiniteScroll from "react-infinite-scroll-component";
import ConfirmationModal from "../ConfirmationModal";
import { failureModalHeading, successModalHeading } from "../../../config";
import { fetchSuggestionDrawerContentData, activateSuggestionDrawerContent, fetchSuggestionDrawerCategoriesData, publishSuggestionDrawerContent, deactivateSuggestionDrawerContent } from "../../../utilities/apiUtils/suggestionDrawer";
import UncontrolledButtonDropdown from "reactstrap/lib/UncontrolledButtonDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import { capitalize } from "@material-ui/core";
import { Config } from "../../../config/UserConfig";
import { getUTMCampaigns } from "../../../utilities/apiUtils/users";
import { getRegions,getCities,getCountries } from "../../../utilities/apiUtils/stickers";
import SdContentPropertiesModal from "./components/SdContentPropertiesModal";
import { fetchSegmentsData } from "../../../utilities/apiUtils/segments";
import { withRouter } from 'react-router-dom';
import { statusOptions } from "../../../config/configSuggestionDrawer";
import { groupedOptions } from "../../../config/configSuggestionDrawer";


class SuggestionDrawerContent extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      searchString: "",
      contentId: 0,
      pageNumber: 0,
      successModalText: "",
      successModalDisplay: false,
      failureModalText: "",
      failureModalDisplay: false,
      pageNumber: 0,
      contents: [],
      hasMoreContents: false,
      loading: true,
      status: { value: "activated", label: "Activated" },
      imageURL: "",
      searchBy: config.searchByDefault,
      confirmationModalIsOpen: false,
      action: "",
      categories: [],
      data: {
        id: '',
        contentId: '',
      },
      sortBy: Config.UPDATED_AT_DESCENDING,
      selectedContents: [],
      minAppVersion: "",
      maxAppVersion: "",
      utm: "",
      utmCampaigns: [],
      countries: [],
      regions: [],
      cities: [],
      selectedCountry: null,
      selectedRegion: null,
      selectedCity: null,
      showSdContentPropertiesModal: false,
      segments: [],
      selectedSegment:null
    };
    this.handleContentSelection = this.handleContentSelection.bind(this);
  }
    // Method to open the modal
    handleShowPropertiesModal = () => {
      this.setState({ showSdContentPropertiesModal: true });
    };

    // Method to close the modal
    handleHidePropertiesModal = () => {
      this.setState({ showSdContentPropertiesModal: false });
    };
  deactivate = async (id) => {
    try {
      let response = await deactivateSuggestionDrawerContent(id);
      window.location.reload();
    } catch (err) {
      this.showErrorModal(errorDescription(err));
    }
  };

  activate = async (id) => {
    try {
      let response = await activateSuggestionDrawerContent(id);
      window.location.reload();
    } catch (err) {
      this.showErrorModal(errorDescription(err));
    }
  };

  publish = async (id) => {
    try {
      let response = await publishSuggestionDrawerContent(id);
      window.location.reload();
    } catch (err) {
      this.showErrorModal(errorDescription(err));
    }
  };

  updateQueryParams = () => {
    const params = new URLSearchParams();
  
    if (this.state.categoryID?.value) {
      params.set("categoryId", this.state.categoryID.value);
    }
    if (this.state.searchString === "id" && this.state.searchString) {
      params.set("contentId", this.state.searchString);
    }
    if (this.state.status?.value) {
      params.set("status", this.state.status.value);
    }
    if (this.state.sortBy?.value) {
      params.set("sortBy", this.state.sortBy.value);
    } else {
      params.set("sortBy", Config.UPDATED_AT_DESCENDING);
    }
    if (this.state.minAppVersion) {
      params.set("minAppVersion", this.state.minAppVersion);
    }
    if (this.state.maxAppVersion) {
      params.set("maxAppVersion", this.state.maxAppVersion);
    }
    if (this.state.utm) {
      params.set("utm", this.state.utm);
    }
    if (this.state.selectedCountry?.label) {
      params.set("country", this.state.selectedCountry.label);
    }
    if (this.state.selectedRegion?.label) {
      params.set("region", this.state.selectedRegion.label);
    }
    if (this.state.selectedCity?.label) {
      params.set("city", this.state.selectedCity.label);
    }
    if (this.state.selectedSegment?.value) {
      params.set("segment", this.state.selectedSegment.value);
    }
  
    this.props.history.push({ search: params.toString() });
  };
  
  loadCountries = async () => {
    const countries = await getCountries();
    this.setState({ countries });
  };

  loadSegments = async () => {
    const segmentsData = await fetchSegmentsData();
    const segments = segmentsData.map(segment => ({
        value: segment.id,      
        label: segment.name     
    }));
    this.setState({ segments });
};

handleSegmentChange = (selectedSegment) => {
  this.setState({ selectedSegment });
  this.search();
}
handleCountryChange = async (selectedCountry) => {
  this.setState({
    selectedCountry,
    selectedRegion: null,
    selectedCity: null,
    regions: [],
    cities: [],
  });

  if (selectedCountry) {
    const regions = await getRegions(selectedCountry.value);

    if (regions && regions.length > 0) {
      const selectedRegion = regions[regions.length - 1]; // Get the last region
      this.setState({ regions, selectedRegion });

      const cities = await getCities(selectedCountry.value, selectedRegion.value);

      if (cities && cities.length > 0) {
        const selectedCity = cities[cities.length - 1]; // Get the last city
        this.setState({ cities, selectedCity });
        this.search();
      }
    }
  }
};


handleRegionChange = async (selectedRegion) => {
  const { selectedCountry } = this.state;

  this.setState({
    selectedRegion,
    selectedCity: null,
    cities: [],
  });

  if (selectedRegion && selectedCountry) {
    const cities = await getCities(selectedCountry.value, selectedRegion.value);

    // Preselect the last city in the response
    if (cities && cities.length > 0) {
      const selectedCity = cities[cities.length - 1]; 
      this.setState({ cities, selectedCity });
      this.search();
    }
  }
};

  handleCityChange = (selectedCity) => {
    this.setState({ selectedCity });
    this.search();
  };
  getCategories = async () => {
    let params = {
      status: "activated",
    }
    let categories = await fetchSuggestionDrawerCategoriesData(params)

    if (categories.categories && categories.categories?.length > 0) {
      const options = []
      categories.categories.forEach(type => {
        options.push({
          label: type.name + ":" + type.id,
          value: type.id
        })
      })
      this.setState({ categories: options })
    }
  }
  getUTMCampaigns = async () => {
    const utmCampaigns = await getUTMCampaigns();
    this.setState({ utmCampaigns });
  }
  handleContentSelection = (id, checked) => {
    let selectedContents = this.state.selectedContents; 
    if (checked) {
      selectedContents = [...selectedContents, id];
    } else {
      selectedContents = selectedContents.filter((contentId) => contentId !== id);
    }
    this.setState({
      selectedContents: selectedContents,
    });
  };
  getContents = async () => {
        // Query parameter.
    let params = {
      contentId: this.state.searchBy === "id" ? this.state.searchString : "",
      status: this.state.status === null ? "" : this.state.status.value,
      pageNumber: this.state.pageNumber,
      limit: 10,
      categoryId: this.state?.categoryID?.value === undefined ? "" : this.state?.categoryID?.value,
      sortBy: this.state.sortBy?.value,
      minAppVersion: this.state.minAppVersion || "",
      maxAppVersion: this.state.maxAppVersion || "",
      utmCampaign: this.state.utm || "", 
      countryCode: this.state.selectedCountry?.isoCode || "",
      regionCode: this.state.selectedRegion?.regionCode || "",
      cityName: this.state.selectedCity?.label || "",
      segmentId: this.state.selectedSegment?.value || "",

    };
    this.updateQueryParams();
    // API called with given query parameters.
    fetchSuggestionDrawerContentData(params)
    .then(async (response) => {
      if (!response.contents || response.contents.length === 0) {
        this.setState({ contents: [], hasMoreContents: false });
        return;
      }
      if (response.contents?.length > 0) {
        this.setState({ contents: response.contents });
        this.setState({ hasMoreContents: response.contents.length < 10 ? false : true });
      } else {
        this.showErrorModal(errorDescription(response.errorCode));
      }
    })
      .catch((err) => {
        this.setState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      })
  };
  
  showDeactivateSuggestionDrawerContentButton(content) {
    return content.publishedAt !== null && content.deactivatedAt === null;
  }

  showActivateSuggestionDrawerContentButton(content) {
    return content.deactivatedAt > content.publishedAt;
  }

  showPublishSuggestionDrawerContentButton(content) {
    return content.publishedAt === null && content.deactivatedAt === null;
  }
  handleChange = (event) => {
    event.preventDefault();
    let value = event.target.value;
    const onlyNumbers = value.replace(/\D/g, '');
    this.setState({ searchString: onlyNumbers });
    if (!event.target.value.length) {
      this.search();
    }
  };
 
  onKeyDown = (event) => {
    if (event.keyCode === config.keypressDown) {
      this.search();
    }
  };

  search = () => {
    this.setState(
      { loading: true, pageNumber: 0, hasMoreSuggestionDrawerContent: false, searchBy: this.state.searchBy },
      () => {
        this.getContents();
      });
  };
  async componentDidMount() {
    try {
      await Promise.all([
        this.getCategories(),
        this.getUTMCampaigns(),
        this.loadCountries(),
        this.loadSegments(),
      ]);
  
      this.setInitialFiltersFromParams();
    } catch (error) {
      console.error("Error while initializing filters:", error);
    }
  }
  

  setInitialFiltersFromParams = async () => {
    try {
      const params = new URLSearchParams(this.props.location.search);
      const categoryId = params.get("categoryId");
      const segmentId = params.get("segment");
      const statusValue = params.get("status");
      const sortByValue = (params.get("sortBy") || Config.UPDATED_AT_DESCENDING).trim();
  
      const selectedCategory = categoryId
        ? this.state.categories.find((cat) => cat.value === Number(categoryId)) || { value: "", label: "Select Category" }
        : { value: "", label: "Select Category" };
  
      const selectedCountry = params.get("country")
        ? this.state.countries.find((country) => country.label === params.get("country")) || null
        : null;
      if (selectedCountry) {
        await this.handleCountryChange(selectedCountry);
      }
  
      const selectedRegion = params.get("region")
        ? this.state.regions.find((region) => region.label === params.get("region")) || null
        : null;
      if (selectedRegion) {
        await this.handleRegionChange(selectedRegion);
      }
  
      const selectedCity = params.get("city")
        ? this.state.cities.find((city) => city.label === params.get("city")) || null
        : null;
  
      const selectedSegment = segmentId
        ? this.state.segments.find((segment) => segment.value === Number(segmentId)) || { value: "", label: "Select Segment" }
        : { value: "", label: "Select Segment" };
  
      const selectedStatus = statusOptions.find((status) => status.value === statusValue) || { value: "activated", label: "Activated" };

  
      const flatOptions = groupedOptions.flatMap((group) => group.options);
      const selectedSortBy = flatOptions.find((option) => option.value === sortByValue) || flatOptions[0];

  
      this.setState(
        {
          categoryID: selectedCategory,
          status: selectedStatus,
          sortBy: selectedSortBy,
          minAppVersion: params.get("minAppVersion") || "",
          maxAppVersion: params.get("maxAppVersion") || "",
          utm: params.get("utm") || "",
          selectedCountry: selectedCountry,
          selectedRegion: selectedRegion,
          selectedCity: selectedCity,
          selectedSegment: selectedSegment,
        },
        () => this.search()
      );
    } catch (error) {
      console.error("Error parsing query parameters: ", error);
      this.setState(
        {
          categoryID: { value: "", label: "Select Category" },
          searchString: "",
          status: { value: "activated", label: "Activated" },
          sortBy: Config.UPDATED_AT_DESCENDING,
          minAppVersion: "",
          maxAppVersion: "",
          utm: "",
          selectedCountry: null,
          selectedRegion: null,
          selectedCity: null,
          selectedSegment: { value: "", label: "Select Segment" },
        },
        () => this.search()
      );
    }
    this.handleOrderChange(this.state.sortBy);
  };
  
  
  
getSortByLabel = (sortByValue) => {
  const sortByOptions = [
    { value: Config.UPDATED_AT_DESCENDING, label: "Updated At \u2193" },
    { value: Config.UPDATED_AT_ASCENDING, label: "Updated At \u2191" },
    { value: Config.CREATED_AT_DESCENDING, label: "Created At \u2193" },
    { value: Config.CREATED_AT_ASCENDING, label: "Created At \u2191" },
  ];
  const selectedOption = sortByOptions.find(option => option.value === sortByValue);
  return selectedOption ? selectedOption.label : "Sort By";
};

  clearQueryParams = () => {
    const { history, location } = this.props;
    
    if (history && location) {
      history.replace({
        pathname: location.pathname,
        search: "",
      });
  
      this.setState({
        categoryID: { value: "", label: "Select Category" },
        searchString: "",
        status: { value: "activated", label: "Activated" },
        sortBy: Config.UPDATED_AT_DESCENDING,
        minAppVersion: "",
        maxAppVersion: "",
        utm: "",
        selectedCountry: null,
        selectedRegion: null,
        selectedCity: null,
        selectedSegment: null,
      }, () => this.search());
    } else {
      console.error("History or location is not available to clear query parameters.");
    }
  };
  fetchMoreData = () => {
    this.setState(
      { pageNumber: this.state.pageNumber + 1, loading: true },
      async () => {
        let params = {
          contentId: this.state.searchBy === "id" ? this.state.searchString : "",
          status: this.state.status === null ? "" : this.state.status.value,
          pageNumber: this.state.pageNumber,
          limit: 10,
          categoryId: this.state?.categoryID?.value === undefined ? "" : this.state?.categoryID?.value,
          sortBy: this.state.sortBy?.value,
          minAppVersion: this.state.minAppVersion || "",
          maxAppVersion: this.state.maxAppVersion || "",
          utmCampaign: this.state.utm || "", 
          countryCode: this.state.selectedCountry?.isoCode || "",
          regionCode: this.state.selectedRegion?.regionCode || "",
          cityName: this.state.selectedCity?.label || "",
          segmentId: this.state.selectedSegment?.value || "",
        };
        this.updateQueryParams();

        // API called with given query parameters
        await fetchSuggestionDrawerContentData(params)
        .then(async (response) => {
                    // Check if response.contents exists and is an array
          if (Array.isArray(response.contents) && response.contents.length > 0) {
              this.setState((prevState) => ({
                contents: prevState.contents.concat(response.contents),
                hasMoreContents: response.contents.length === 10,
              }));
            } else {
              this.setState({ hasMoreContents: false });
            }
          })
          .catch((err) => {
            this.setState({
              failureModalDisplay: true,
              failureModalText: errorDescription(error.unexpectedError),
            });
          })
          .finally(() => {
            this.setState({ loading: false });
          })
        }
      )
    };

  showErrorModal = (text) => {
    this.setState({ failureModalText: text });
    this.setState({ failureModalDisplay: true });
  };

  failureModalClose = () => {
    this.setState({ failureModalDisplay: false });
  };

  successModalClose = async () => {
    this.setState({ successModalDisplay: false });
    this.search();
  };

  showSuccessModal = (text) => {
    this.setState({ successModalText: text });
    this.setState({ successModalDisplay: true });
  };

  disableDeactivateButton = (row) => {
    if (new Date(row.deactivatedAt) > new Date() || row.deactivatedAt === null) return false;
    return true;
  };

  disableActivateButton = (row) => {
    if (new Date(row.deactivatedAt) > new Date() || row.deactivatedAt === null) return true;
    return false;
  };
  disablePublishButton = (row) => {
    if (new Date(row.publishedAt) > new Date() || row.publishedAt === null) {
      return false;
    }

    return true;
  };


  setConfirmationModalState = (event, row) => {
    this.setState({
      action: event,
      confirmationModalIsOpen: true,
      contentId: row.id
    })

  };

  handleStatusChange = (status) => {
    this.setState({ status: { value: status.value, label: status.label } }, () => this.search());
  };
  handleOrderChange = (sortBy) => {
    if (sortBy && sortBy.value) {
      this.setState({ sortBy }, () => {
        this.search();
      });
    } else {
      this.setState({ sortBy: { value: Config.UPDATED_AT_DESCENDING, label: "Descending ↓" } }, () => {
        this.search();
      });
    }
  };

  handleUpdateTargetingRules = (e) => {
    e.preventDefault();
    const data = this.state.selectedContents.map((content) => content).join(",");
    redirectTo(`/suggestionDrawer/content/targeting-rules?contentIds=${data}`);
  };

  handleUpdateUserAttributes = (e) => {
    e.preventDefault();
    const data = this.state.selectedContents.map((content) => content).join(",");
    redirectTo(`/suggestionDrawer/content/user-attributes-bulk-rules?contentIds=${data}`);
  }
  render() {
    const { countries, regions, cities,segments, selectedCountry, selectedRegion, selectedCity,selectedSegment} = this.state;
    const { showSdContentPropertiesModal } = this.state;

    return (
      <Container fluid className="p-0">
       <div className="d-flex justify-content-between align-items-center mb-4">
          <h1 className="h3">Contents</h1>
          <div className="d-flex">
            <Button
                  style={{ fontSize: "1rem" }}
                  className="btn-success mx-2"
                  onClick={() => this.clearQueryParams()} 
                >
                  Clear Filters
            </Button>
            <Link to={{
              pathname: `/suggestionDrawer/content/create-content`,
              state: {
                formType: "create",
              },
            }}>
              {showCreateSuggestionDrawerContentButton() ? (
                <Button
                  style={{ fontSize: "1rem" }}
                  className="btn-success mx-2"
                >
                  Create Content
                </Button>
              ) : null}
            </Link>

            {/* Bulk Operations Button */}
            {showCreateSuggestionDrawerContentButton() ? (
              <UncontrolledButtonDropdown className="ml-2">
                <DropdownToggle
                 disabled={this.state.selectedContents.length == 0}
                  caret
                  color="secondary"
                >
                  Bulk Operations
                </DropdownToggle>
                <DropdownMenu>
                <DropdownItem onClick={this.handleShowPropertiesModal}>
                  Update Properties
                </DropdownItem>
                  <DropdownItem
                  onClick={(e) => {
                      this.handleUpdateTargetingRules(e);
                    }}>
                    Update Targeting Rules
                  </DropdownItem>
                  <DropdownItem
                  onClick={(e) => {
                      this.handleUpdateUserAttributes(e);
                    }}>
                    Update User Attributes
                  </DropdownItem>
    
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            ) : null}
          </div>
        </div>


        <Row className="mt-4">
          <Col md={2} sm={12}>
            <Search
              value={this.state.searchString}
              onChange={this.handleChange}
              onKeyDown={this.onKeyDown}
              placeholder={"Search Content"}
            ></Search>
          </Col>
          <Col md={2} sm={12}>
            <UncontrolledButtonDropdown className="mr-1 mb-1">
              <DropdownToggle caret color="success">
                {"Search By " + capitalize(this.state.searchBy) + " "}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => { this.search("id") }}>ID</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </Col>
          <Col md={2} sm={12}>
          <Select
            className="react-select-container mb-4"
            classNamePrefix="react-select"
            placeholder="Select Status"
            onChange={this.handleStatusChange}
            options={statusOptions} 
            value={this.state.status}
          />
          </Col>
          <Col md={2} sm={12}>
          <Select
            className="react-select-container mb-4"
            classNamePrefix="react-select"
            placeholder="Sort By"
            options={groupedOptions}
            value={this.state.sortBy} 
            onChange={(selectedOption) => this.handleOrderChange(selectedOption)}
          />
          </Col>
            <Col md={2} sm={12}>
              <Select
                className="react-select-container mb-4"
                classNamePrefix="react-select"
                placeholder="Select Category"
                onChange={(category) => {
                  this.setState({ categoryID: category }, () => this.search()); 
                }}
                options={this.state.categories}
                value={this.state.categoryID}
              />
            </Col>

          <Col md={2} sm={12}>
          <input
            type="text"
            name="minAppVersion"
            value={this.state.minAppVersion}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                this.setState({ minAppVersion: value });
              }
            }}
            onKeyDown={(e) => e.key === "Enter" && this.search()}
            className="form-control mb-4"
            placeholder="Minimun (Min App Version)"
          />
          </Col>
          <Col md={2} sm={12}>
            <input
              type="text"
              name="maxAppVersion"
              value={this.state.maxAppVersion}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  this.setState({ maxAppVersion: value });
                }
              }}
              onKeyDown={(e) => e.key === "Enter" && this.search()}
              className="form-control mb-4"
              placeholder="Maximum (Max App Version)"
          />
          </Col>


          <Col md={2} sm={12}>
          <Select
          className="react-select-container mb-4"
          classNamePrefix="react-select"
          placeholder="Select UTM"
          onChange={(selectedUTM) => {
            this.setState({ utm: selectedUTM.label }, () => this.search());
          }}
          options={this.state.utmCampaigns}
          value={this.state.utmCampaigns.find(option => option.label === this.state.utm) || null}
        />
          </Col>
          <Col md={2} sm={12}>
                {/* Segment Dropdown */}
                <Select
                    className="react-select-container mb-4"
                    classNamePrefix="react-select"
                    placeholder="Select Segment"
                    onChange={this.handleSegmentChange}
                    options={segments}
                    value={selectedSegment} // Ensure selectedSegment is formatted correctly
                />
            </Col>
          <Col md={2} sm={12}>
            {/* Country Dropdown */}
            <Select
              className="react-select-container mb-4"
              classNamePrefix="react-select"
              placeholder="Select Country"
              onChange={this.handleCountryChange}
              options={countries}
              value={selectedCountry}
            />
            </Col>
            <Col md={2} sm={12}>
            {/* Region Dropdown (visible only when a country is selected) */}
            {selectedCountry && (
              <Select
                className="react-select-container mb-4"
                classNamePrefix="react-select"
                placeholder="Select Region"
                onChange={this.handleRegionChange}
                options={regions}
                value={selectedRegion}
              />
            )}
          </Col>
          <Col md={2} sm={12}>
            {/* City Dropdown (visible only when a region is selected) */}
            {selectedRegion && (
              <Select
                className="react-select-container mb-4"
                classNamePrefix="react-select"
                placeholder="Select City"
                onChange={this.handleCityChange}
                options={cities}
                value={selectedCity}
              />
            )}
        </Col>
          
        </Row>
        <InfiniteScroll
          style={{ overflow: "auto !important" }}
          className="infinite-scroll"
          dataLength={this.state.contents.length}
          next={this.fetchMoreData}
          hasMore={this.state.hasMoreContents}
          loader={
            this.state.loading && (
              <Spinner color="info" className="d-flex mx-auto" />
            )
          }
        >
          <Row className="mt-4 mx-0">
            {(this.state.loading && this.state.contents?.length <= 0) ? (
              <Spinner color="info" className="d-flex mx-auto" />
            ) :
              (this.state.contents?.length > 0) ?
                this.state.contents.map((content, index) => {
                  return (<>
                    { <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
                      <Card className="importPageCard">
                      <div className="checkbox-container">
                        <input
                          type="checkbox"
                          checked={this.state.selectedContents.includes(content.id)}
                          onChange={(e) => this.handleContentSelection(content.id, e.target.checked)}
                        />
                      </div>
                        <React.Fragment>
                          {<CardImg
                            top
                            src={content.imageURL ? content.imageURL : "/img/no-image-icon.png"}
                            alt="No Image Available"
                          />}
                        </React.Fragment>
                        <CardBody className="px-4">
                        <CardTitle tag="h3" className="mb-0">
                            {
                              content.publishedAt === null && content.deactivatedAt === null ? (
                                <Badge className="badge-secondary float-right">Not Published</Badge>
                              ) : content.publishedAt !== null && content.deactivatedAt === null ? (
                                content.endDateTimeString !== null && new Date(content.endDateTimeString) < new Date() ? (
                                  <Badge className="badge-danger float-right">Expired</Badge>
                                ) : (
                                  <Badge className="badge-success float-right">Activated</Badge>
                                )
                              ) : content.deactivatedAt !== null ? (
                                <Badge className="badge-danger float-right">Deactivated</Badge>
                              ) : (
                                null 
                              )
                            }
                          </CardTitle>
                        <div className="mt-3">
                            {content?.title && (
                              <h4
                                className="text-truncate"
                                style={{
                                  maxWidth: "100%",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                <br></br>
                                <strong>Title: {content.title.replace(/<[^>]*>/g, "")}</strong>
                              </h4>
                            )}

                            {content?.subtext && (
                              <p
                                className="text-truncate"
                                style={{
                                  maxWidth: "100%",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                Subtext: {content.subtext}
                              </p>
                            )}

                            {content?.body && (
                              <div style={{ maxHeight: "40px", overflowY: "auto" }}>
                                Body: <p>{content.body.replace(/<[^>]*>/g, "")}</p>
                              </div>
                            )}
                          </div>
                          <small>ID : {content.id}</small>
                          <br />
                          {/* <small className="font-weight-bold">
                            Deactivated At : {new Date(content.deactivatedAt) > new Date() || content.deactivatedAt === null ?
                              "N/A" : formatTime(content.deactivatedAt)}
                          </small>
                          <br />
                          <small className="font-weight-bold">
                            Published At : {new Date(content.publishedAt) > new Date() || content.publishedAt === null ?
                              "N/A" : formatTime(content.publishedAt)}
                          </small> */}
                          
                        </CardBody>
                        <CardBody className="px-4">
                          <Container className="text-center" fluid>
                            <Row>
                              <Col sm="12" md="12">
                                {showSuggestionDrawerContentButton() ? (
                                  <Link to={{
                                    pathname: `/suggestionDrawer/content/edit-content/${content.id}`,
                                    state: {
                                      formType: "edit",
                                    },
                                  }}>
                                    <Button
                                      className="m-2"
                                      color="info"
                                    >
                                      Edit
                                    </Button>
                                  </Link>) : null}
                                {showEditSuggestionDrawerContentTargetingRuleButton() ? (
                                  <Link to={{
                                    pathname: `/suggestionDrawer/content/targeting-rules/${content.id}`,
                                    state: {
                                      formType: "edit",
                                    },
                                  }}>
                                    <Button
                                      className="m-2"
                                      color="info"
                                    >
                                      Targeting Rules
                                    </Button>
                                  </Link>) : null}
                                {"  "}
                                {content.deactivatedAt == null ? (
                                  <Button
                                    className="m-2 btn-danger"
                                    disabled={this.disableDeactivateButton(content)}
                                    onClick={(event) =>
                                      this.setConfirmationModalState(event.target.innerHTML, content)
                                    }
                                  >
                                    Deactivate
                                  </Button>
                                ) : null}
                                {"  "}
                                {content.deactivatedAt != null ? (
                                  <Button
                                    className="m-2 btn-success"
                                    disabled={this.disableActivateButton(content)}
                                    onClick={(event) =>
                                      this.setConfirmationModalState(event.target.innerHTML, content)
                                    }
                                  >
                                    Activate
                                  </Button>
                                ) : null}
                                {content.publishedAt == null ? (
                                  <Button
                                    className="m-2 btn-danger"
                                    disabled={this.disablePublishButton(content)}
                                    onClick={(event) =>
                                      this.setConfirmationModalState(event.target.innerHTML, content)
                                    }
                                  >
                                    Publish
                                  </Button>
                                ) : null}
                                {showEditSuggestionDrawerContentRuleButton() ? (
                                  <Link to={{
                                    pathname: `/suggestionDrawer/content/user-attribute-rules/${content.id}`,
                                    state: {
                                      formType: "edit",
                                    },
                                  }}>
                                    <Button
                                      className="m-2"
                                      color="info"
                                    >
                                      User Attribute Rules
                                    </Button>
                                  </Link>) : null}
                                {"  "}
                              </Col>
                            </Row>
                          </Container>
                        </CardBody>
                      </Card>
                    </Col>}
                  </>
                  );
                }
                ) : <Container className="mt-5">
                  <h3 className="text-center">No Content available.</h3>
                </Container>
            }
          </Row>
        </InfiniteScroll>
        <ResponseModal
          show={this.state.successModalDisplay}
          onHide={this.successModalClose}
          modalheading={successModalHeading}
          modaltext={this.state.successModalText}
        />
        <ResponseModal
          show={this.state.failureModalDisplay}
          onHide={this.failureModalClose}
          modalheading={failureModalHeading}
          modaltext={this.state.failureModalText}
        />
        <ConfirmationModal
          show={this.state.confirmationModalIsOpen}
          modaltext={this.state.action}
          onConfirmationTrue={() => {
            this.setState({ confirmationModalIsOpen: false });
            if (this.state.action === allActions.deactivate) this.deactivate(this.state.contentId);
            else if (this.state.action === allActions.activate) this.activate(this.state.contentId);
            else if (this.state.action === allActions.publish) this.publish(this.state.contentId)
          }}
          onHide={() => this.setState({ confirmationModalIsOpen: false })}
        />
         {/* SdContentPropertiesModal */}
         <SdContentPropertiesModal
        show={this.state.showSdContentPropertiesModal}
        onHide={this.handleHidePropertiesModal}
        selectedStories={this.state.selectedContents}
        activate={this.activate}
        deactivate={this.deactivate}
        publish={this.publish}
      />
      </Container>
    );
  }
}

export default withRouter(SuggestionDrawerContent);

import React from 'react'
import { Container, Row, Col, Card, Form, CardHeader } from 'reactstrap'

export const AutoDownloadConfigs = (props) => {

    const allowUserDelete = props.allowUserDelete;
    const requireUserHead = props.requireUserHead;

    const handleAllowUserDeleteChange = props.handleAllowUserDeleteChange;
    const handleRequireUserHeadChange = props.handleRequireUserHeadChange;

    return (
        <Container>
            <Row>
                <Col lg="12" className="p-0 ">
                    <Card>
                        <CardHeader
                            className="pt-3 pb-0"
                        >
                            <div className="container">
                                <div className="row">
                                    <Col md={8} className="p-0 ">
                                        <h4>
                                            <strong>AutoDownload Config Rules</strong>
                                        </h4>
                                    </Col>
                                </div>
                            </div>
                        </CardHeader>
                        <Form>
                            <Row lg={4} className="pb-2">
                                <div
                                    className="custom-switch custom-control"
                                    style={{ zIndex: 0 , marginLeft: "2rem"}}
                                >
                                    <input
                                        type="checkbox"
                                        id="allowUserDeleteSwitch"
                                        name="allowUserDelete"
                                        className="custom-control-input"
                                        checked={allowUserDelete}
                                        onChange={handleAllowUserDeleteChange}
                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor="allowUserDeleteSwitch"
                                    >
                                        Allow User Delete
                                    </label>
                                </div>
                                <div
                                    className="custom-switch custom-control ml-4"
                                    style={{ zIndex: 0 }}
                                >
                                    <input
                                        type="checkbox"
                                        id="requireUserHeadSwitch"
                                        name="requireUserHead"
                                        className="custom-control-input"
                                        checked={requireUserHead}
                                        onChange={handleRequireUserHeadChange}
                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor="requireUserHeadSwitch"
                                    >
                                        Require User Head
                                    </label>
                                </div>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

import React, { useState, useEffect } from "react";
import Select from "react-select";
import { FaPlus, FaMinus } from "react-icons/fa";
import { Row, Col, Label, Button } from "reactstrap";

const options = [
  { label: "Allow", value: "allow" },
  { label: "Deny", value: "deny" },
];

const LocationRule = (props) => {
  var firstLoading = true;
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [countryRule, setCountryRule] = useState({});
  const [regionRule, setRegionRule] = useState({});
  const [cityRule, setCityRule] = useState({});
  const [ruleEffect, setRuleEffect] = useState({});

  var getCountries = props.getCountries;
  var getRegions = props.getRegions;
  var getCities = props.getCities;

  const _addLocationRule = () => {
    props.addLocationRule();
  };

  const _removeLocationRule = () => {
    props.removeLocationRule(props.rule.id);
  };

  useEffect(() => {
    const _getCountries = async () => {
      let response = await getCountries();
      setCountries(response);
    };
    _getCountries();
  }, []);

  const handleCountries = async (event) => {
    setCountryRule({});
    setRegionRule({});
    setCityRule({});
    setRegions([]);
    setCities([]);

    if (event != null) {
      setCountryRule(event);
      let response = await getRegions(event.value);

      // check for every label if "GLOBAL" is present else include it in the response (regions)
      let globalRegions = response.find((item) => item.label.toUpperCase() === "GLOBAL");
      if (globalRegions === undefined) {
        globalRegions = {
          value: 1,
          label: "GLOBAL",
        };
      }

      setRegions(response);

      response = await getCities(event.value, globalRegions.value);

      // check for every label if "GLOBAL" is present else include it in the list (cities)
      let globalCities = response.find((item) => item.label.toUpperCase() === "GLOBAL");
      if (globalCities === undefined) {
        globalCities = {
          value: 1,
          label: "GLOBAL",     
        };
      }

      handleRegions();
      props.updateRule(
        props.rule.id,
        event?.value,
        globalRegions?.value,
        globalCities?.value,
        ruleEffect?.value
      );
      props.updateRuleSummary(
        props.rule.id,
        event?.label,
        globalRegions?.label,
        globalCities?.label,
        ruleEffect?.label
      );

      setRegionRule(globalRegions);

      setCityRule(
        globalCities
      );

      
    }
  };

  const handleRegions = async (event) => {
    setRegionRule({});
    setCityRule({});
    setCities([]);

    if (event != null) {
      setRegionRule(event);
      let response = await getCities(countryRule.value, event.value);

      // check for every label if "GLOBAL" is present else include it in the list

      let global = response.find((item) => item.label.toUpperCase() === "GLOBAL");
      if (global === undefined) {
        response.push({
          value: 1,
          label: "GLOBAL",
        });
        global = response[response.length - 1];
        
      }
      
      props.updateRule(
        props.rule.id,
        countryRule?.value,
        event?.value,
        global?.value,
        ruleEffect?.value
      );
      props.updateRuleSummary(
        props.rule.id,
        countryRule?.label,
        event?.label,
        global?.label,
        ruleEffect?.label
      );

      setCities(response);
      setCityRule(global);
    }
  };

  const handleCities = async (event) => {
    setCityRule({});
    if (event != null) {
      props.updateRule(
        props.rule.id,
        countryRule?.value,
        regionRule?.value,
        event?.value,
        ruleEffect?.value
      );
      props.updateRuleSummary(
        props.rule.id,
        countryRule?.label,
        regionRule?.label,
        event?.label,
        ruleEffect?.label
      );
      setCityRule(event);
    }
  };

  const handleEffect = async (event) => {
    setRuleEffect({});
    if (event != null) {
      props.updateRule(
        props.rule.id,
        countryRule.value,
        regionRule.value,
        cityRule.value,
        event.value
      );
      setRuleEffect(event);
    }
  };

  useEffect(() => {
    const _setInitValue = async () => {
      if (firstLoading && countries && countries.length > 0) {
        var country = countries.find(
          (item) => item.value === props?.rule?.countryID
        );

        setCountryRule(country);
        let _regions = await getRegions(country.value);

        setRegions(_regions);
        var region = _regions.find(
          (item) => item.value === props?.rule?.regionID
        );

        setRegionRule(region);
        let _cities = await getCities(country?.value, region?.value);

        // check for every label if "GLOBAL" is present else include it in the list

        let global = _cities?.find((item) => item.label.toUpperCase() === "GLOBAL");
        if (global === undefined) {
            if(_cities !== undefined){
                _cities.push({
                    value: 1,
                    label: "GLOBAL",
                    });
            }
        }

        setCities(_cities);

        var city = _cities?.find((item) => item.value === props.rule.cityID);

        setCityRule(city);
        var effect = options?.find((item) => item.value === props.rule.effect);
        setRuleEffect(effect);

        props.updateRuleSummary(
          props?.rule?.id,
          country?.label,
          region?.label,
          city?.label,
          effect?.label
        );
        firstLoading = false;
      }
    };
    _setInitValue();
  }, [countries, props.rule]);

  return (
    <Row className="mr-1">
      <Col>
        <Label>Country</Label>
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          name="country"
          placeholder="Select Country"
          options={countries}
          onChange={(option) => handleCountries(option)}
          isDisabled={!props.updateAllowed}
          value={countryRule}
          required
        />
      </Col>
      <Col>
        <Label>Region</Label>

        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          name="region"
          placeholder="Select region"
          options={regions}
          value={regionRule}
          onChange={(option) => handleRegions(option)}
          isDisabled={!props.updateAllowed}
          required
        />
      </Col>
      <Col>
        <Label>City</Label>

        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          name="city"
          placeholder="Select City"
          options={cities}
          value={cityRule}
          onChange={(option) => handleCities(option)}
          isDisabled={!props.updateAllowed}
          required
        />
      </Col>
      <Col md={2}>
        <Label>Effect</Label>

        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          name="locationEffect"
          placeholder="Select Effects"
          options={options}
          value={ruleEffect}
          onChange={(option) => handleEffect(option)}
          isDisabled={!props.updateAllowed}
          required
        />
      </Col>
      {props.updateAllowed ? (
        <Col md={1} className="align-self-end">
          {props.rule.id === 0 ? (
            <Button onClick={_addLocationRule} color="transparent">
              <FaPlus />
            </Button>
          ) : (
            <Button onClick={_removeLocationRule} color="transparent">
              <FaMinus />
            </Button>
          )}
        </Col>
      ) : (
        <div></div>
      )}
    </Row>
  );
};

export default LocationRule;

import React, { useState, useEffect, useRef } from "react";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import { Button } from "reactstrap";
import {
  errorDescription,
  permissions,
  allActions,
  error,
  redirectOnTokenExpiry,
  isOperationPermitted,
} from "../../../utilities/commonUtil";
import ResponseModal from "../ResponseModal";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  tableColumns,
  statusFilter,
} from "./tableColumns.js";
import ConfirmationModal from "../ConfirmationModal";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { Link } from "react-router-dom";
import Loading from "../../../components/Loading";
import {
  successModalHeading,
  errorModalHeading,
  publishSuccess,
  deactivateSuccess,
  activateSuccess,
  statusOptions
} from "../../../config/config";
import filterFactory from "react-bootstrap-table2-filter";
import Select from "react-select";
import { fetchKeyboardFonts, activateKeyboardFont, deactivateKeyboardFont, publishKeyboardFont } from "../../../utilities/apiUtils/keyboardFonts";
import { Config } from "../../../config";

const { SearchBar } = Search;
let statusFilterRef;

const ExpandableRowsTable = () => {
  const [loading, setLoading] = useState(true);
  const [keyboardFontsData, setKeyboardFontsData] = useState([]);
  const [column] = useState(tableColumns);
  const [successModalText, setSuccessModalText] = useState("");
  const [failureModalText, setFailureModalText] = useState("");
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [id, setId] = useState(null);
  const [action, setAction] = useState("");
  const [filters, setFilters] = useState({});
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );
  statusFilterRef = useRef();
  // Fetching fonts data when the component mounts
  useEffect(() => {
    let fetchFonts = async () => {
      try {
        let response = await fetchKeyboardFonts();
        if (response.keyboardFonts) {
          setKeyboardFontsData(response.keyboardFonts);
          setLoading(false);
        } else {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken
          )
            redirectOnTokenExpiry();
          let text = errorDescription(response.errorCode);
          setLoading(false);
          showErrorModal(text);
        }
      } catch (err) {
        setLoading(false);
        setFailureModalDisplay(true);
        setFailureModalText(errorDescription(error.unexpectedError));
      }
    };
    fetchFonts();
  }, []);
  // Function to close failure modal
  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };
  // Function to close success modal
  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    let response = await fetchKeyboardFonts();
    if (response.keyboardFonts) {
      setKeyboardFontsData(response.keyboardFonts);
    } else if (response.errorCode) {
      let text = errorDescription(response.errorCode);
      showErrorModal(text);
      if (
        response.errorCode === error.tokenExpired ||
        response.errorCode === error.invalidAccessToken
      )
        redirectOnTokenExpiry();
    }
  };
  // Function to open error modal
  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };
  // Function to open success modal
  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };
  // Function that disables publish button
  let disablePublishButton = (row) => {
    if (row.publishedAt === null) return false;
    return true;
  };
  // Function that disables deactivate button
  let disableDeactivateButton = (row) => {
    if (row.publishedAt !== null && row.deactivatedAt === null) return false;
    return true;
  };
  // Function that disables activate button
  let disableActivateButton = (row) => {
    if (row.publishedAt !== null && row.deactivatedAt !== null) return false;
    return true;
  };
  // Publishes the font
  let publish = async (id) => {
    let response = await publishKeyboardFont(id);
    if (response.status) {
      let text = publishSuccess;
      showSuccessModal(text);
    } else {
      let text = errorDescription(response.errorCode);
      showErrorModal(text);
    }
  };
  // Activates the font
  let activate = async (id) => {
    let response = await activateKeyboardFont(id);
    if (response.status) {
      let text = activateSuccess;
      showSuccessModal(text);
    } else {
      let text = errorDescription(response.errorCode);
      showErrorModal(text);
    }
  };
  // Deactivates the font
  let deactivate = async (id) => {
    let response = await deactivateKeyboardFont(id);
    if (response.status) {
      let text = deactivateSuccess;
      showSuccessModal(text);
    } else {
      let text = errorDescription(response.errorCode);
      showErrorModal(text);
    }
  };
  // Function to open confirmation modal
  let setConfirmationModalState = (id, event) => {
    setAction(event);
    setConfirmationModalIsOpen(true);
    setId(id);
  };

  // Expanding row function for a single font
  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <ul>
          <li>{`ID: ${row.id}`}</li>
          <li>{`Name : ${row.name}`}</li>
          <li>{`Created At : ${row.createdAt}`}</li>
          <li>{`Updated At : ${row.updatedAt}`}</li>
          <li>{`Published At : ${row.publishedAt}`}</li>
          <li>{`Deactivated At : ${row.deactivatedAt}`}</li>
        </ul>
        {isOperationPermitted(allowedPermissions, permissions.keyboardFontEdit) ? (
          <Link
            to={{
              pathname: `/keyboard-fonts/${row.id}/edit`,
              state: {
                font: row,
                formType: "edit",
              },
            }}
          >
            <Button
              className="mx-3"
            >
              Edit
            </Button>
          </Link>
        ) : null}
        {isOperationPermitted(allowedPermissions, permissions.keyboardFontPublish) ? (
          <Button
            className="mx-3 btn-info"
            disabled={disablePublishButton(row)}
            onClick={(event) =>
              setConfirmationModalState(row.id, event.target.innerHTML)
            }
          >
            Publish
          </Button>
        ) : null}
        {isOperationPermitted(allowedPermissions, permissions.keyboardFontDeactivate) ? (
          <Button
            className="mx-3 btn-danger"
            disabled={disableDeactivateButton(row)}
            onClick={(event) =>
              setConfirmationModalState(row.id, event.target.innerHTML)
            }
          >
            Deactivate
          </Button>
        ) : null}
        {isOperationPermitted(allowedPermissions, permissions.keyboardFontActivate) ? (
          <Button
            className="mx-3 btn-success"
            disabled={disableActivateButton(row)}
            onClick={(event) =>
              setConfirmationModalState(row.id, event.target.innerHTML)
            }
          >
            Activate
          </Button>
        ) : null}

      </div>
    ),
  };
  let onColumnMatch = ({ searchText, value, column, row }) => {
    // Custom logic for searching ID and Name in every cell value
    let name = "";
    let searchString = "";
    let id = 0;

    if (row && row.name && typeof row.name == "string") name = row.name.toLowerCase();
    if (searchText && typeof searchText == "string") searchString = searchText.toLowerCase();
    if (row && row.id) id = row.id;

    if (searchString === "") return true;
    if (!isNaN(searchString) && !isNaN(parseInt(searchString))) {
      return id == parseInt(searchString); //return true;
    }
    return name.includes(searchString);
  }
  // Funtions handling filters
  let onTableChange = (type, newState) => {
    setFilters(newState.filters);
  };
  // Returns the font data for the table
  const fontData = () => {
    let items = keyboardFontsData;

    const statusFilter = filters.deactivatedAt ? filters.deactivatedAt.filterVal : null;
    if (statusFilter) {
      if (statusFilter === Config.DEACTIVATED_STATUS) {
        items = items.filter((item) => item.deactivatedAt !== null);
      } else if (statusFilter === Config.UNPUBLISHED_STATUS) {
        items = items.filter((item) => (item.deactivatedAt === null && item.publishedAt === null));
      } else if (statusFilter === Config.PUBLISHED_STATUS) {
        items = items.filter((item) => (item.publishedAt !== null && item.deactivatedAt === null));
      }
    }

    return items;
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loading />
      ) : (
        <>
          <ToolkitProvider
            keyField="id"
            columns={column}
            data={keyboardFontsData}
            search={{
              searchFormatted: true,
              onColumnMatch,
            }}
          >
            {(props) => (
              <div>
                <Card style={{ overflow: "hidden", paddingBottom: "100px" }}>
                  <div container-fullwidth="true" style={{ margin: "2%" }}>
                    <Row className="justify-content-md-center mx-auto">
                      <Col md={6} xs={7} className="p-0 mt-0 pb-4">
                        <SearchBar
                          placeholder="Search By Name or ID"
                          {...props.searchProps}
                          style={{
                            position: "absolute",
                          }}
                        />
                      </Col>
                      <Col md={6} xs={5} className="p-0">
                        <ClearButton
                          onClick={() => {

                          }}
                          {...props.searchProps}
                        >
                          Reset Filters
                        </ClearButton>
                      </Col>
                    </Row>
                    <Row></Row>
                    <Row>
                      <Col md={3} sm={12} className="mt-3">
                        <Select
                          className="react-select-container"
                          id="statusSelect"
                          classNamePrefix="react-select"
                          name="status"
                          placeholder="Select Status"
                          onChange={(event) => {
                            if (event) statusFilter(event.value);
                          }}
                          options={statusOptions}
                          ref={(ref) => {
                            statusFilterRef = ref;
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                  <CardBody>
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      wrapperClasses="table-responsive"
                      hover={true}
                      bordered={false}
                      keyField="id"
                      data={fontData()}
                      columns={column}
                      expandRow={expandRow}
                      filter={filterFactory()}
                      pagination={paginationFactory({
                        sizePerPage: 10,
                        sizePerPageList: [5, 10, 25, 50],
                      })}
                      onTableChange={onTableChange}
                      remote={{ filter: true }}
                      noDataIndication="No results found"
                    />
                  </CardBody>
                </Card>
              </div>
            )}
          </ToolkitProvider>

          <ResponseModal
            show={successModalDisplay}
            onHide={successModalClose}
            modalheading={successModalHeading}
            modaltext={successModalText}
          />
          <ResponseModal
            show={failureModalDisplay}
            onHide={failureModalClose}
            modalheading={errorModalHeading}
            modaltext={failureModalText}
          />
          <ConfirmationModal
            show={confirmationModalIsOpen}
            modaltext={action}
            onConfirmationTrue={() => {
              setConfirmationModalIsOpen(false);
              if (action === allActions.publish) publish(id);
              else if (action === allActions.deactivate) deactivate(id);
              else if (action === allActions.activate) activate(id);
            }}
            onHide={() => setConfirmationModalIsOpen(false)}
          />
        </>
      )}
    </React.Fragment>
  );
};

const ClearButton = (props) => {
  const handleClick = () => {
    statusFilter("");
    props.onSearch("");
    if (statusFilterRef.select) statusFilterRef.select.clearValue();

  };
  return (
    <Button
      style={{ float: "right" }}
      className="form-control-lg"
      onClick={handleClick}
    >
      Reset Filters
    </Button>
  );
};

const KeyboardFonts = (props) => {
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );
  return (
    <Container fluid className="p-0">
      <Row>
        <h1 className="mb-4 mt-2 w-50 pl-3">Keyboard Fonts</h1>
        {isOperationPermitted(allowedPermissions, permissions.keyboardFontCreate) ? (
          <Link
            className="d-block ml-auto mb-4 pr-3 text-decoration-none"
            to={{
              pathname: `/keyboard-fonts/create`,
              state: {
                formType: "create"
              }
            }}
          >
            <Button color="primary" className="ml-auto d-flex">
              <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                Add New Font
              </p>
            </Button>
          </Link>
        ) : null}
        {isOperationPermitted(allowedPermissions, permissions.keyboardFontReorder) ? (
          <Link
            className="text-decoration-none mr-3"
            to={{
              pathname: `/keyboard-fonts/reorder`,
            }}
          >
            <Button color="secondary" className="ml-auto d-flex">
              <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                Reorder Fonts
              </p>
            </Button>
          </Link>
        ) : null}
      </Row>
      <ExpandableRowsTable />
    </Container>
  );
};

export default KeyboardFonts;

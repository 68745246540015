import { retrieveFromLocalStorage } from "../localStorageUtil";
import { host } from "./index";
// let host = "http://127.0.0.1:8000"
export let createUser = async (data) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/internal/users`;

  let response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });

  let res = await response.json();
  response = res;

  return response;
};

export let editUser = async (data) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/internal/users/${data.id}`;
  delete data.id;

  let response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  let res = await response.json();
  response = res;

  return response;
};

export let getUsers = async () => {
  let token = retrieveFromLocalStorage("token");
  let data = [];

  let url = `${host}/internal/users`;

  let response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  let res = await response.json();
  if (res.length) data = parseUserData(res);
  else data = res;

  return data;
};

export let getUserDetails = async (userId) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/internal/users/${userId}/profile`;

  let response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  let res = await response.json();
  response = res;

  return response;
};

export let activateUser = async (id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/internal/users/${id}/activate`;

  let response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  let res = await response.json();
  response = res;

  return response;
};

export let deactivateUser = async (id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/internal/users/${id}/deactivate`;

  let response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  let res = await response.json();
  response = res;

  return response;
};

export let resetUserPassword = async (data) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/internal/users/${data.id}/reset-password`;
  delete data.id;

  let response = await fetch(url, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  let res = await response.json();
  response = res;

  return response;
};

export let getUTMCampaigns = async () => {
  try {
    let token = retrieveFromLocalStorage("token");
    let url = `${host}/v4/internal/referralCampaigns`;

    let response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let res = await response.json();
    // make a key value pair of utmCampaigns only
    let utmCampaigns = [];

    if (res.referralCampaigns) {
      res.referralCampaigns.forEach((campaign) => {
        utmCampaigns.push({ value: campaign.utmCampaign, label: campaign.utmCampaign });
      });
    }
    return utmCampaigns;
  } catch (error) {
    console.log(error);
  }
};

let parseUserData = (getUserArray) => {
  let parsedUser = [];
  getUserArray.forEach((data) => {
    let user = {};
    if (data.roles === null) user.roles = [];
    else user.roles = data.roles;
    user.status = addUserStatus(data.deactivatedAt);
    user.id = data.id;
    user.username = data.username;
    user.createdAt = data.createdAt;
    user.deactivatedAt = data.deactivatedAt;
    parsedUser.push(user);
  });
  return parsedUser;
};

let addUserStatus = (deactivated) => {
  if (deactivated === null) return "Activated";
  else return "Deactivated";
};

import { host } from "./index";
import { retrieveFromLocalStorage } from "../localStorageUtil";

export const getAdBanners = async (params) => {
  const token = retrieveFromLocalStorage("token");
  const response = await fetch(
    `${host}/v4/internal/adBanners?` + new URLSearchParams(params),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const data = await response.json();

  return data;
};

export const activateAdBanner = async (id) => {
  const token = retrieveFromLocalStorage("token");
  const response = await fetch(`${host}/v4/internal/adbanner/${id}/activate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();

  return data;
};

export const deactivateAdBanner = async (id) => {
  const token = retrieveFromLocalStorage("token");
  const response = await fetch(
    `${host}/v4/internal/adbanner/${id}/deactivate`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const data = await response.json();

  return data;
};

export const publishAdBanner = async (id) => {
  const token = retrieveFromLocalStorage("token");
  const response = await fetch(`${host}/v4/internal/adbanner/${id}/publish`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();

  return data;
};

export const createAdBanner = async (formData) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/adbanner`;

  try {
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    return await response.json();
  } catch (error) {
    return;
  }
};

export const getAdBannerDetail = async (id) => {
  const token = retrieveFromLocalStorage("token");
  const response = await fetch(`${host}/v4/internal/adbanner/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();

  return data;
}

export const editAdBanner = async (formData, id) => {
  const token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/adbanner/${id}`;

  try {
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    return await response.json();

  } catch (error) {
    return;
  }
};

export const getAdBannerTargetingRules = async (id) => {
  let rules = [];
  const token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/adbanner/${id}/rules`;
  try {
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let res = await response.json();
    if (res) {
      rules = res.rules;
    }
    return rules;
  } catch (error) {
    return;
  }
}

export const saveAdBannerTargetingRules = async (data, id) => {
  const token = retrieveFromLocalStorage("token");
  const response = await fetch(
    `${host}/v4/internal/adbanner/${id}/rules`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }
  );

  const responseData = await response.json();

  return responseData;
}

import React, { useState, useEffect } from "react";
import ResponseModal from "../ResponseModal";
import DateTimePicker from 'react-datetime-picker'
import Select from "react-select";
import { FaPlus, FaMinus } from "react-icons/fa";
import {
  errorDescription,
  validateConfigForm,
  valueTypes,
  appIDs,
  error,
  redirectOnTokenExpiry,
  redirectTo,
  onAppVersionInput,
  validateInteger,
  validateJSON,
  validateJsonValue
} from "../../../utilities/commonUtil";
import { createContentCategory } from "../../../utilities/apiUtils/cre";
import TagsInput from "react-tagsinput";
import Routes from "../../../routes/index";
import { getUTMCampaigns } from "../../../utilities/apiUtils/users";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Container
} from "reactstrap";
import {
  successModalHeading,
  errorModalHeading,
} from "../../../config/config";
import {
  getLanguages
} from "../../../utilities/apiUtils/stickers";

const CreateCategoryForm = (props) => {
  const [form, setForm] = useState({
    title: "",
    contentTypes: [],
    priority: { value: 1, valid: true },
    image: null,
    imageSrc: "",
    startTime: Math.round((new Date()).getTime() / 1000).toString(),
    endTime: Math.round((new Date()).getTime() / 1000).toString(),
    tags: [],
    utmCampaigns: null,
    targetLanguages: [],
    firstLoadLimit: { value: 1, valid: true },
    limit: { value: 1, valid: true }
  });

  const [translations, setTranslations] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [lastStartTimestamp, setLastStartTimestamp] = useState(new Date());
  const [lastEndTimestamp, setLastEndTimestamp] = useState(new Date());
  const [displayError, setDisplayError] = useState(false);
  const [selectedContent, setSelectedContent] = useState([]);
  const [successModal, setSuccessModal] = useState({
    display: false,
    text: "",
  });
  const [failureModal, setFailureModal] = useState({
    display: false,
    text: "",
  });
  const [utmCampaignOptions, setUtmCampaignOptions] = useState([]);

  useEffect(() => {
    const _getLanguages = async () => {
      let response = await getLanguages();
      setLanguages(response);
    };
    const fetchUTMCampaign = async () => {
      let response = await getUTMCampaigns();
      setUtmCampaignOptions(response);
    };
    fetchUTMCampaign();
    _getLanguages();
  }, []);

  const allContentTypes = [
    { name: "Sticker", value: "sticker" },
    { name: "Animated Sticker", value: "animatedSticker" },
    { name: "Movie GIF", value: "movieGif" },
    { name: "Pop Text", value: "popTextStyle" },
    { name: "Emoji Sticker", value: "emojiSticker" },
  ];

  const handleTranslationChange = (id, event, languageCode) => {
    const newTranslations = translations.map(i => {
      if (id === i.id) {
        if (languageCode !== null) {
          i["languageCode"] = languageCode.value;
        }
        else {
          i[event.target.name] = event.target.value
        }
      }
      return i;
    })

    setTranslations(newTranslations);
  }

  const handleAddTranslation = () => {
    if (translations.length > 0) {
      setTranslations([...translations, { id: translations[translations.length - 1].id + 1, languageCode: "", name: "" }])
    }
    else {
      setTranslations([...translations, { id: 0, languageCode: "", name: "" }]);
    }
  }

  const handleRemoveTranslation = id => {
    const values = [...translations];
    values.splice(values.findIndex(value => value.id === id), 1);
    setTranslations(values);
  }

  let handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  let successModalClose = () => {
    setSuccessModal({ ...successModal, display: false });
    redirectTo("/cre/content-categories");
  };

  let failureModalClose = () => {
    setFailureModal({ ...failureModal, display: false });
  };

  const handlePriorityChange = (event) => {
    const { name, value } = event.target;
    setForm((prev) => ({
      ...prev,
      [name]: {
        value: value,
        valid: validateInteger(value) && parseInt(value) !== 0,
      },
    }));
    setDisplayError(false);
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setForm((prev) => ({
      ...prev,
      [name]: files[0],
    }));

    event.persist();
    // For showing Image preview
    if (files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        if (name === "image") {
          setForm((prev) => ({
            ...prev,
            imageSrc: reader.result,
          }));
          document.getElementById("image").innerHTML = files[0].name;
        }
      };
    }
  };

  const handleTags = (tags) => {
    setForm((prev) => ({
      ...prev,
      ["tags"]: tags,
    }));
    setDisplayError(false);
  };

  const handleUtmCampaigns = (utmCampaigns) => {
    setForm((prev) => ({
      ...prev,
      ["utmCampaigns"]: utmCampaigns,
    }));
    setDisplayError(false);
  }

  const handleLimitChange = (event) => {
    const { name, value } = event.target;
    setForm((prev) => ({
      ...prev,
      [name]: {
        value: value,
        valid: validateInteger(value) && parseInt(value) !== 0,
      },
    }));
    setDisplayError(false);
  };

  const handleFirstLoadLimitChange = (event) => {
    const { name, value } = event.target;
    setForm((prev) => ({
      ...prev,
      [name]: {
        value: value,
        valid: validateInteger(value) && parseInt(value) !== 0,
      },
    }));
    setDisplayError(false);
  };

  const handleContentTypesChange = (selectedContent) => {
    let contentType = [];
    if (selectedContent !== null) {
      selectedContent.forEach((content) => {
        contentType.push(content.value);
      });
    }

    setForm({ ...form, contentTypes: contentType });
  };

  const handleStartTime = (obj) => {
    setLastStartTimestamp(obj)
    setForm({ ...form, startTime: Math.round(obj.getTime() / 1000).toString() });
  }

  const handleEndTime = (obj) => {
    setLastEndTimestamp(obj)
    setForm({ ...form, endTime: Math.round(obj.getTime() / 1000).toString() });
  }

  let handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let arrTags = []
      if (form.tags) {
        form.tags.map((t) => {
          let obj = { text: t }
          arrTags.push(obj)
        });
      }

      let arrUtmCampaigns = []
      if (form.utmCampaigns) {
        arrUtmCampaigns.push(form.utmCampaigns.value)
      }

      let content = form.contentTypes;
      let formData = new FormData();
      formData.append("name", form.title);
      formData.append("priority", parseInt(form.priority.value));
      formData.append("startTime", form.startTime);
      formData.append("endTime", form.endTime);
      formData.append("firstLoadLimit", parseInt(form.firstLoadLimit.value));
      formData.append("limit", parseInt(form.limit.value));
      formData.append("iconImage", form.image)
      formData.append("utmCampaigns", arrUtmCampaigns.join());

      if (arrTags) {
        let index = 0;
        arrTags.map((tags) => {
          formData.append(`tags[${index}].text`, tags.text)
          index++;
        });
      }
      if (translations.length > 0) {
        translations.map((t) => {
          if (t.languageCode !== "" && t.name !== "") {
            formData.append(`translations[${t.id}].languageCode`, t.languageCode)
            formData.append(`translations[${t.id}].name`, t.name)
          }
        });
      }
      if (content !== []) {
        let index = 0;
        content.map((c) => {
          formData.append(`contentTypes[${index}].contentType`, c)
          index++;
        });
      }
      let response = await createContentCategory(formData);
      if (response.status === "success") {
        setSuccessModal({ display: true, text: response.description });
      } else {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectOnTokenExpiry();
        setFailureModal({
          display: true,
          text: errorDescription(response.errorCode),
        });
      }
    }
    catch (error) {
      setFailureModal({
        display: true,
        text: errorDescription(error.unexpectedError),
      });
    }
  };

  return (
    <Container fluid className="p-0">
      <Row className="mb-3">
        <Col md={6} xs={4}>
          <h1>Create Content Category</h1>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Category Name<span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="title"
                    value={form.title}
                    required
                    placeholder="Category Name"
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    <strong>
                      Priority<span className="text-danger">*</span>
                      <small className="text-info">
                        (Enter integer value greater than 0)
                      </small>
                    </strong>
                  </Label>
                  <Input
                    type="number"
                    name="priority"
                    defaultValue={form.priority.value}
                    className={
                      form.priority.valid
                        ? "form-control is-valid"
                        : "form-control is-invalid"
                    }
                    onChange={(event) => handlePriorityChange(event)}
                    disabled={false}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label>
                    <strong>
                      Image
                      <small className="text-info">
                        (Choose an image)
                      </small>
                    </strong>
                  </Label>
                  <Container className="custom-file">
                    <Input
                      type="file"
                      className="custom-file-input"
                      accept="false/jpeg"
                      name="image"
                      onChange={(event) => handleFileChange(event)}
                      disabled={false}
                    />
                    <Label className="custom-file-label" id="image">
                      <strong>Choose an image</strong>
                    </Label>
                  </Container>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {form.imageSrc !== "" &&
                form.imageSrc !== null ? (
                <Col className="pb-4">
                  <img
                    src={form.imageSrc}
                    alt="previewImage"
                  />
                </Col>
              ) : (
                <Col></Col>
              )}
            </Row>
            <Row>
              <Col md={6}>
                <Label>
                  <strong>
                    Start Time<span className="text-danger">*</span>
                  </strong>
                </Label>
                <br></br>
                <DateTimePicker
                  amPmAriaLabel="Select AM/PM"
                  calendarAriaLabel="Toggle calendar"
                  dayAriaLabel="Day"
                  hourAriaLabel="Hour"
                  maxDetail="second"
                  minuteAriaLabel="Minute"
                  monthAriaLabel="Month"
                  nativeInputAriaLabel="Date and time"
                  onChange={(obj) => handleStartTime(obj)}
                  secondAriaLabel="Second"
                  value={typeof lastStartTimestamp === "string" ? new Date(lastStartTimestamp) : lastStartTimestamp}
                  yearAriaLabel="Year"
                  required
                />
              </Col>
              <Col md={6}>
                <Label>
                  <strong>
                    End Time<span className="text-danger">*</span>
                  </strong>
                </Label>
                <br></br>
                <DateTimePicker
                  amPmAriaLabel="Select AM/PM"
                  calendarAriaLabel="Toggle calendar"
                  dayAriaLabel="Day"
                  hourAriaLabel="Hour"
                  maxDetail="second"
                  minuteAriaLabel="Minute"
                  monthAriaLabel="Month"
                  nativeInputAriaLabel="Date and time"
                  onChange={(obj) => handleEndTime(obj)}
                  secondAriaLabel="Second"
                  value={typeof lastEndTimestamp === "string" ? new Date(lastEndTimestamp) : lastEndTimestamp}
                  yearAriaLabel="Year"
                  required
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    <strong>
                      Limit
                      <small className="text-info">
                        (Enter integer value greater than 0)
                      </small>
                    </strong>
                  </Label>
                  <Input
                    type="number"
                    name="limit"
                    defaultValue={form.limit.value}
                    className={
                      form.limit.valid
                        ? "form-control is-valid"
                        : "form-control is-invalid"
                    }
                    onChange={(event) => handleLimitChange(event)}
                    disabled={false}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    <strong>
                      First Load Limit
                      <small className="text-info">
                        (Enter integer value greater than 0)
                      </small>
                    </strong>
                  </Label>
                  <Input
                    type="number"
                    name="firstLoadLimit"
                    defaultValue={form.firstLoadLimit.value}
                    className={
                      form.firstLoadLimit.valid
                        ? "form-control is-valid"
                        : "form-control is-invalid"
                    }
                    onChange={(event) => handleFirstLoadLimitChange(event)}
                    disabled={false}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <FormGroup>
                  <Label>
                    <strong>Tags</strong>
                  </Label>
                  <TagsInput
                    value={form.tags}
                    onChange={(tags) => handleTags(tags)}
                    disabled={false}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <FormGroup>
                  <Label>Content Types</Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name="Content Types"
                    defaultValue={selectedContent}
                    onChange={handleContentTypesChange}
                    options={allContentTypes}
                    getOptionLabel={(options) => options['name']}
                    getOptionValue={(options) => options['value']}
                    isMulti
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <FormGroup>
                  <Label>
                    <strong>UTM Campaign</strong>
                  </Label>
                  <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={utmCampaignOptions}
                        placeholder="Select UTM Campaign"
                        value={form.utmCampaigns}
                        isClearable
                        onChange={(value) => {
                          setForm((prevState) => ({
                            ...prevState,
                            utmCampaigns: value
                          }))
                        }
                        }
                      />
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Col>
                <strong>Translations</strong>
              </Col>
            </Row>
            {translations
              ? translations.map(translation => {
                return (
                  <Row key={translation.id}>
                    <Col md={4}>
                      <FormGroup>
                        <Label>
                          Target Language
                        </Label>
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="languageCode"
                          placeholder="Select Languages"
                          options={languages}
                          defaultValue={translation.languageCode}
                          onChange={(option) => handleTranslationChange(translation.id, null, option)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label>
                          Translation Text
                        </Label>
                        <Input
                          type="text"
                          name="name"
                          value={translation.name}
                          placeholder="Translation"
                          onChange={(event) => handleTranslationChange(translation.id, event, null)}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2} style={{ display: "flex", justifyContent: "center" }}>
                      <Button onClick={() => handleRemoveTranslation(translation.id)} color="transparent">
                        <FaMinus />
                      </Button>
                    </Col>
                  </Row>
                );
              })
              : {}}
            <Row>
              <Col>
                <Button onClick={handleAddTranslation}>Add Translations</Button>
              </Col>
            </Row>
            {displayError ? (
              <p className="text-danger mb-0">
                Please enter all valid details.
              </p>
            ) : null}
            <Button color="primary" style={{ marginTop: 20 }}>Submit</Button>
          </Form>
          <ResponseModal
            show={successModal.display}
            onHide={successModalClose}
            modalheading={successModalHeading}
            modaltext={"Category Successfully created"}
          />
          <ResponseModal
            show={failureModal.display}
            onHide={failureModalClose}
            modalheading={errorModalHeading}
            modaltext={failureModal.text}
          />
        </CardBody>
      </Card>
    </Container>
  );
};

export default CreateCategoryForm;

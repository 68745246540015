import React, { useState, useEffect } from "react";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { Collapse, Card, CardBody, CardHeader, Button, Badge } from "reactstrap";
import UserAttributeRule from "./UserAttributeRule";
import ResponseModal from "./ResponseModal";
import { failureModalHeading, successModalHeading } from "../../config";
import { error, errorDescription } from "../../utilities/commonUtil";
import { useParams } from 'react-router-dom';

const UserAttributefilterRulesCard = (props) => {

    const [filterRules, setFilterRules] = useState([
        { type: "", config: {} },
    ]);
    const { id } = useParams();
    const deactivateRule = props.deactivateRule;
    const activateRule = props.activateRule;
    const [failureModalText, setFailureModalText] = useState("");
    const [failureModalDisplay, setFailureModalDisplay] = useState(false);
    const [successModalText, setSuccessModalText] = useState("");
    const [successModalDisplay, setSuccessModalDisplay] = useState(false);
    const [activate, setActivate] = useState(true);
    const [toggle, setToggle] = useState(false);


    let showErrorModal = (text) => {
        setFailureModalText(text);
        setFailureModalDisplay(true);
    };

    let failureModalClose = () => {
        setFailureModalDisplay(false);
    };

    let successModalClose = async () => {
        setSuccessModalDisplay(false);
        window.location.reload();
    };

    let showSuccessModal = (text) => {
        setSuccessModalText(text);
        setSuccessModalDisplay(true);
    };

    const _updateActivate = async (status) => {
        try {
            let response = status === false ? await deactivateRule(id, props.rule.id) : await activateRule(id, props.rule.id);
            if (response?.status === "deactivated" || response?.status === "activated") {
                showSuccessModal("Rule Updated");
            }
            else {
                if (response?.errorCode) {
                    showErrorModal(response?.errorMessage)
                }
            }
        } catch (err) {
            setFailureModalDisplay(true);
            setFailureModalText(errorDescription(error.unexpectedError));
        }
        setActivate(status);
    };

    useEffect(() => {
        setActivate(!props.rule.deactivatedAt);
    }, [props.rule.deactivatedAt]);

    useEffect(() => {
        const updatedfilterRules = props.rule?.filtersArray?.map((filter, index) => ({
            ...filter,
            filterIndexId: index,
            ruleIndexId: props.rule.ruleIndexId
        }));
        setFilterRules(updatedfilterRules);
    }, [props.rule.filtersArray, props.rule.ruleIndexId]);

    const addRule = () => {
        const newRule = {
            filterIndexId: filterRules.length > 0 ? filterRules[filterRules.length - 1].filterIndexId + 1 : 0,
            ruleIndexId: props.rule.ruleIndexId,
            type: "flt_premium_user",
            config: {},
        };
        const updatedfilterRules = [...filterRules, newRule];
        setFilterRules(updatedfilterRules);
        props.updateRules(props.rule.ruleIndexId, updatedfilterRules);
    };

    const removeRule = (id) => {
        const updatedfilterRules = filterRules.filter((rule) => rule.filterIndexId !== id);
        updatedfilterRules.forEach((rule, index) => (rule.filterIndexId = index));
        setFilterRules(updatedfilterRules);
        props.updateRules(props.rule.ruleIndexId, updatedfilterRules);
    };

    const _removeRule = () => {
        props.removeRule(props.rule.ruleIndexId);
    };

    const handleChange = (filterRuleIndex, fieldName, fieldValue) => {
        const filterRulesDeepCopy = JSON.parse(JSON.stringify(filterRules))
        filterRulesDeepCopy[filterRuleIndex] = {
            ...filterRulesDeepCopy[filterRuleIndex],
            [fieldName]: fieldValue
        }
        setFilterRules(filterRulesDeepCopy)
        props.updateRules(props.rule.ruleIndexId, filterRulesDeepCopy);
    }

    const handleConfigChange = (filterRuleIndex, fieldName, fieldValue) => {
        const filterRulesDeepCopy = JSON.parse(JSON.stringify(filterRules))
        filterRulesDeepCopy[filterRuleIndex] = {
            ...filterRulesDeepCopy[filterRuleIndex],
            config: {
                ...filterRulesDeepCopy[filterRuleIndex].config,
                [fieldName]: fieldValue
            }
        }
        setFilterRules(filterRulesDeepCopy)
        props.updateRules(props.rule.ruleIndexId, filterRulesDeepCopy);
    }


    return (
        <Card className="mb-3">
            <CardHeader onClick={() => setToggle(!toggle)} className="pt-3 pb-0">
                <div className="d-flex">
                    <h4 style={{ marginRight: 10 }}>
                        <strong>Rule {props.rule.ruleIndexId + 1}</strong>
                    </h4>
                    <div style={{ marginTop: -2 }}>
                        {activate ? (
                            <Badge color="success">Activated</Badge>
                        ) : (
                            <Badge color="danger">
                                Deactivated
                            </Badge>
                        )}
                    </div>
                    <div className="ml-auto">
                        {toggle ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                </div>
            </CardHeader>
            <Collapse isOpen={toggle}>
                <CardBody className="pb-3 pt-1">
                    <p className="mt-3 mb-1">
                        <strong>Filters</strong>
                    </p>
                    {filterRules?.length > 0 &&
                        filterRules?.map((filter, index) => (
                            <UserAttributeRule
                                getFilters={props.getFilters}
                                key={filter.ruleIndexId + "-" + filter.filterIndexId}
                                filterIndex={index}
                                rule={filter}
                                addRule={addRule}
                                removeRule={removeRule}
                                getProducts={props.getProducts}
                                getSegments={props.getSegments}
                                updateAllowed={props.updateAllowed}
                                handleChange={handleChange}
                                handleConfigChange={handleConfigChange}
                            />
                        ))}
                    <div className="mt-4 d-flex flex-nowrap">
                        <div className="order-1 p-2">
                            <Button onClick={_removeRule} color={"danger"} disabled={!props.updateAllowed}>
                                Delete Rule
                            </Button>
                        </div>

                        <div className="order-2 p-2">
                            {activate ? (
                                <Button
                                    onClick={(e) => _updateActivate(false)}
                                    color={"warning"} disabled={!props.updateAllowed}
                                >
                                    Deactivate Rule
                                </Button>
                            ) : (
                                <Button
                                    onClick={(e) => _updateActivate(true)}
                                    color={"success"} disabled={!props.updateAllowed}
                                >
                                    Activate Rule
                                </Button>
                            )}
                        </div>
                    </div>
                </CardBody>
            </Collapse>
            <ResponseModal
                show={successModalDisplay}
                onHide={successModalClose}
                modalheading={successModalHeading}
                modaltext={successModalText}
            />
            <ResponseModal
                show={failureModalDisplay}
                onHide={failureModalClose}
                modalheading={failureModalHeading}
                modaltext={failureModalText}
            />
        </Card>
    );
};

export default UserAttributefilterRulesCard;
import moment from "moment";
import { permissions } from "../utilities/commonUtil";
import { retrieveFromLocalStorage } from "../utilities/localStorageUtil";

const allowedPermissions = retrieveFromLocalStorage("userPermissions");

export const staticIconSize = 1e+7;

export const imageFormatError = "Please upload in accepted format";

export const staticIconError = "Please upload static icon";

export const fileSizeExceedError = "File is too large";

export const responseTypeOptions = [
    { value: "text", label: "Text" },
    { value: "multiText", label: "Multiple Texts" },
    { value: "multiVariant", label: "Multiple Variants" },
    { value: "multiStyleMultiVariant", label: "Multiple Styles x Multiple Variants" },
];
export const dataTypeOptions = [
    { value: "jsonObject", label: "JSON Object" },
    { value: "jsonObjectRaw", label: "JSON Object (Raw)" },
    { value: "xmlObject", label: "XML Object" },
    { value: "string", label: "String" },
];
export const llmProviderOptions = [
    // { value: "qxlabai", label: "QxlabAI (deprecated)" },
    // { value: "openai", label: "OpenAI" },
    { value: "qxlabaiV2", label: "QxlabAI v2" },
    { value: "monsterai", label: "MonsterAI (8B model)" },
    { value: "monsteraiV2", label: "MonsterAI v2 (70B model)" },
];
export const styleOptions = [
    { value: "balanced", label: "Balanced" },
    { value: "creative", label: "Creative" },
    { value: "precise", label: "Precise" },
];
export const deviceTypeOptions = [
    { value: "android", label: "Android" },
    { value: "ios", label: "IOS" },
];
export const actionTypeOptions = [
    { value: "deeplink", label: "Deeplink" },
];
export const borderTypeOptions = [
    { value: "solid", label: "Solid" },
    { value: "dashed", label: "Dashed" }
];
export const backButtonActionTypeOptions = [
    { value: "deeplink", label: "Deeplink" },
];
export const defaultOperationTypeOptions = [
    { value: "SERVER_REQUEST", label: "SERVER_REQUEST" },
];
export let promptTemplateResponses = {
    addedSuccessfully: "Prompt Template added successfully",
    updatedSuccessfully: "Prompt Template updated successfully",
    activatedSuccessfully: "Prompt Template activated successfully",
    deactivatedSuccessfully: "Prompt Template deactivated successfully",
    publishedSuccessfully: "Prompt Template published successfully"
}
export let promptTemplateVersionResponses = {
    addedSuccessfully: "Prompt Template Version added successfully",
    updatedSuccessfully: "Prompt Template Version updated successfully",
}
export let placementResponses = {
    addedSuccessfully: "Placement added successfully",
    updatedSuccessfully: "Placement updated successfully",
    activatedSuccessfully: "Placement activated successfully",
    deactivatedSuccessfully: "Placement deactivated successfully",
    publishedSuccessfully: "Placement published successfully"
}
export let placementButtonResponses = {
    addedSuccessfully: "Button added successfully",
    updatedSuccessfully: "Button updated successfully",
}
export let taskResponses = {
    addedSuccessfully: "Task added successfully",
    updatedSuccessfully: "Task updated successfully",
    activatedSuccessfully: "Task activated successfully",
    deactivatedSuccessfully: "Task deactivated successfully",
    publishedSuccessfully: "Task published successfully"
}
export let taskDropdownOptionResponses = {
    addedSuccessfully: "TaskDropdownOption added successfully",
    updatedSuccessfully: "TaskDropdownOption updated successfully",
}
export let errorMissingMessage = "Please fill the required fields"
export let formatTime = (date) => {
    if (date === null) {
        return "N/A";
    }
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
};
export let showCreatePromptTemplateButton = () => {
    return allowedPermissions.includes(permissions.AIPromptTemplateAdd);
};
export let showEditPromptTemplateButton = () => {
    return allowedPermissions.includes(permissions.AIPromptTemplateEdit);
};
export let showDetailPromptTemplateButton = () => {
    return allowedPermissions.includes(permissions.AIPromptTemplateDetail);
};
export let showActivatePromptTemplateButton = () => {
    return allowedPermissions.includes(permissions.AIPromptTemplateActivate);
};
export let showDeactivatePromptTemplateButton = () => {
    return allowedPermissions.includes(permissions.AIPromptTemplateDeactivate);
};
export let showPublishPromptTemplateButton = () => {
    return allowedPermissions.includes(permissions.AIPromptTemplatePublish);
};
export let showCreatePromptTemplateVersionButton = () => {
    return allowedPermissions.includes(permissions.AIPromptTemplateVersionAdd);
};
export let showEditPromptTemplateVersionButton = () => {
    return allowedPermissions.includes(permissions.AIPromptTemplateVersionEdit);
};
export let showCreatePlacement = () => {
    return allowedPermissions.includes(permissions.AIPoweredBarPlacementAdd);
};
export let showEditPlacement = () => {
    return allowedPermissions.includes(permissions.AIPoweredBarPlacementEdit);
};
export let showDetailPlacement = () => {
    return allowedPermissions.includes(permissions.AIPoweredBarPlacementDetail);
};
export let showActivatePlacement = () => {
    return allowedPermissions.includes(permissions.AIPoweredBarPlacementActivate);
};
export let showDeactivatePlacement = () => {
    return allowedPermissions.includes(permissions.AIPoweredBarPlacementDeactivate);
};
export let showPublishPlacement = () => {
    return allowedPermissions.includes(permissions.AIPoweredBarPlacementPublish);
};
export let showCreatePlacementButton = () => {
    return allowedPermissions.includes(permissions.AIPoweredBarPlacementAdd);
};
export let showEditPlacementButton = () => {
    return allowedPermissions.includes(permissions.AIPoweredBarPlacementEdit);
};
export let showCreateTask = () => {
    return allowedPermissions.includes(permissions.AIPoweredBarTaskAdd);
};
export let showEditTask = () => {
    return allowedPermissions.includes(permissions.AIPoweredBarTaskEdit);
};
export let showDetailTask = () => {
    return allowedPermissions.includes(permissions.AIPoweredBarTaskDetail);
};
export let showActivateTask = () => {
    return allowedPermissions.includes(permissions.AIPoweredBarTaskActivate);
};
export let showDeactivateTask = () => {
    return allowedPermissions.includes(permissions.AIPoweredBarTaskDeactivate);
};
export let showPublishTask = () => {
    return allowedPermissions.includes(permissions.AIPoweredBarTaskPublish);
};
export let showCreateTaskDropdownOption = () => {
    return allowedPermissions.includes(permissions.AIPoweredBarTaskAdd);
};
export let showEditTaskDropdownOption = () => {
    return allowedPermissions.includes(permissions.AIPoweredBarTaskEdit);
};
export let showCreatePromptTemplateVariantButton = () => {
    return allowedPermissions.includes(permissions.AIPromptTemplateAdd);
};
export let formatVariants = (variants) => {
    let formattedVariants = [];
    for (let i = 0; i < variants.length; i++) {
        const variant = variants[i];
        formattedVariants.push(`${variant.locale} (${variant.customAiPromptTemplateId})`)
    }
    return formattedVariants.join(", ");
};
export const localeOptions = [
    { "label": "Acehnese (ace_ID)", "value": "ace_ID" },
    { "label": "Afrikaans (af_ZA)", "value": "af_ZA" },
    { "label": "Alas (btz_ID)", "value": "btz_ID" },
    { "label": "Albanian / Shqip (sq_AL)", "value": "sq_AL" },
    { "label": "Ambonese Malay (abs_ID)", "value": "abs_ID" },
    { "label": "Armenian / հայերէն (hy_AM)", "value": "hy_AM" },
    { "label": "Assamese / অসমীয়া (as_IN)", "value": "as_IN" },
    { "label": "Azerbaijani / Azərbaycan (az_AZ)", "value": "az_AZ" },
    { "label": "Balinese (ban_ID)", "value": "ban_ID" },
    { "label": "Banggai (bdz_ID)", "value": "bdz_ID" },
    { "label": "Bangka Malay (mfb_ID)", "value": "mfb_ID" },
    { "label": "Bangla (BD) / বাংলা (bn_BD)", "value": "bn_BD" },
    { "label": "Bangla / বাংলা (bn_IN)", "value": "bn_IN" },
    { "label": "Banjarese (bjn_ID)", "value": "bjn_ID" },
    { "label": "Basque / Euskara (eu_ES)", "value": "eu_ES" },
    { "label": "Batak Karo (btx)", "value": "btx" },
    { "label": "Batak Simalungun (bts_ID)", "value": "bts_ID" },
    { "label": "Batak Toba (bbc_ID)", "value": "bbc_ID" },
    { "label": "Belarusian / беларуская (be_BY)", "value": "be_BY" },
    { "label": "Betawi (bew_ID)", "value": "bew_ID" },
    { "label": "Bhojpuri / भोजपुरी (bho_IN)", "value": "bho_IN" },
    { "label": "Bodo / बर' (brx_IN)", "value": "brx_IN" },
    { "label": "Bosnian / Bosanski (bs_BA)", "value": "bs_BA" },
    { "label": "Buginese (bug_ID)", "value": "bug_ID" },
    { "label": "Bulgarian / български (bg_BG)", "value": "bg_BG" },
    { "label": "Burmese / ဗမာ (my_MM)", "value": "my_MM" },
    { "label": "Catalan / Català (ca_ES)", "value": "ca_ES" },
    { "label": "Central Malay (pse_ID)", "value": "pse_ID" },
    { "label": "Chinese / 简体中文 (zh_CN)", "value": "zh_CN" },
    { "label": "Chinese / 繁體台灣 (zh_TW)", "value": "zh_TW" },
    { "label": "Chinese / 繁體香港 (zh_HK)", "value": "zh_HK" },
    { "label": "Chinese-Hakka (hakka_CN)", "value": "hakka_CN" },
    { "label": "Croatian / Hrvatski (hr_HR)", "value": "hr_HR" },
    { "label": "Czech / Čeština (cs_CZ)", "value": "cs_CZ" },
    { "label": "Danish / Dánský (da_DK)", "value": "da_DK" },
    { "label": "Dogri / डोगरी (doi_IN)", "value": "doi_IN" },
    { "label": "Duri (mvp_ID)", "value": "mvp_ID" },
    { "label": "Dutch / Nederlands (nl_NL)", "value": "nl_NL" },
    { "label": "English (Bangladesh) (en_BD)", "value": "en_BD" },
    { "label": "English (India) (en_IN)", "value": "en_IN" },
    { "label": "English (South Africa) (en_ZA)", "value": "en_ZA" },
    { "label": "English (Sri Lanka) (en_LK)", "value": "en_LK" },
    { "label": "English (UAE) (en_UA)", "value": "en_UA" },
    { "label": "English (UK) (en_GB)", "value": "en_GB" },
    { "label": "English (US) (en_US)", "value": "en_US" },
    { "label": "Estonian / Eesti (et_EE)", "value": "et_EE" },
    { "label": "Finnish / Suomi (fi_FI)", "value": "fi_FI" },
    { "label": "French / Français (fr_FR)", "value": "fr_FR" },
    { "label": "Galician / Galego (gl_ES)", "value": "gl_ES" },
    { "label": "Gayo (gayo_ID)", "value": "gayo_ID" },
    { "label": "Georgian / ქართული (ka_GE)", "value": "ka_GE" },
    { "label": "German / Deutsch (de_DE)", "value": "de_DE" },
    { "label": "Gorontalo (gor_ID)", "value": "gor_ID" },
    { "label": "Greek / Ελληνικά (el_GR)", "value": "el_GR" },
    { "label": "Gujarati / ગુજરાતી (gu_IN)", "value": "gu_IN" },
    { "label": "Hausa (ha_NG)", "value": "ha_NG" },
    { "label": "Hawu (hvn_ID)", "value": "hvn_ID" },
    { "label": "Hebrew / עברית (iw_IL)", "value": "iw_IL" },
    { "label": "Hinglish / हिन्दी (hi_IN)", "value": "hi_IN" },
    { "label": "Hungarian / Magyar (hu_HU)", "value": "hu_HU" },
    { "label": "Indonesian (in_ID)", "value": "in_ID" },
    { "label": "isiXhosa (xh_ZA)", "value": "xh_ZA" },
    { "label": "isiZulu (zu_ZA)", "value": "zu_ZA" },
    { "label": "Italian / Italiano (it_IT)", "value": "it_IT" },
    { "label": "Jambi Malay (jax_ID)", "value": "jax_ID" },
    { "label": "Japanese / 日本 (ja_JP)", "value": "ja_JP" },
    { "label": "Javanese (jv_ID)", "value": "jv_ID" },
    { "label": "Kannada / ಕನ್ನಡ (kn_IN)", "value": "kn_IN" },
    { "label": "Kazakh / Қазақ (kk_KZ)", "value": "kk_KZ" },
    { "label": "Khmer / ខ្មែរ (km_KH)", "value": "km_KH" },
    { "label": "Komering (kge_ID)", "value": "kge_ID" },
    { "label": "Konkani / कोंकणी (kok_IN)", "value": "kok_IN" },
    { "label": "Korean / 한국어 (ko_KR)", "value": "ko_KR" },
    { "label": "Kutai Malay (vkt_ID)", "value": "vkt_ID" },
    { "label": "Lampung api (ljp_ID)", "value": "ljp_ID" },
    { "label": "Latvian / Latviešu (lv_LV)", "value": "lv_LV" },
    { "label": "Ledo Kaili (lew_ID)", "value": "lew_ID" },
    { "label": "Lithuanian / Lietuvių (lt_LT)", "value": "lt_LT" },
    { "label": "Maanyan (mhy_ID)", "value": "mhy_ID" },
    { "label": "Macedonian / Mакедонски (mk_MK)", "value": "mk_MK" },
    { "label": "Madurese (mad_ID)", "value": "mad_ID" },
    { "label": "Maithili / मैथिली (mai_IN)", "value": "mai_IN" },
    { "label": "Makassar Malay (mfp_ID)", "value": "mfp_ID" },
    { "label": "Makassarese (mak_ID)", "value": "mak_ID" },
    { "label": "Malay / Bahasa Melayu (ms_MY)", "value": "ms_MY" },
    { "label": "Malayalam / മലയാളം (ml_IN)", "value": "ml_IN" },
    { "label": "Maltese / Malti (mt_MT)", "value": "mt_MT" },
    { "label": "Mamasa (mqj_ID)", "value": "mqj_ID" },
    { "label": "Manado Malay (xmm_ID)", "value": "xmm_ID" },
    { "label": "Mandar (mdr_ID)", "value": "mdr_ID" },
    { "label": "Manggarai (mqy_ID)", "value": "mqy_ID" },
    { "label": "Manipuri / মণিপুরী (mni_IN)", "value": "mni_IN" },
    { "label": "Marathi / मराठी (mr_IN)", "value": "mr_IN" },
    { "label": "Marwari / मारवाड़ी (mwr_IN)", "value": "mwr_IN" },
    { "label": "Minangkabau (min_ID)", "value": "min_ID" },
    { "label": "Mongondow (mog_ID)", "value": "mog_ID" },
    { "label": "Muna (mnb_ID)", "value": "mnb_ID" },
    { "label": "Musi (mui_ID)", "value": "mui_ID" },
    { "label": "Nepali / नेपाली (ne_IN)", "value": "ne_IN" },
    { "label": "Ngaju Dayak (nij_ID)", "value": "nij_ID" },
    { "label": "Nias (nia_ID)", "value": "nia_ID" },
    { "label": "North Moluccan Malay (max_ID)", "value": "max_ID" },
    { "label": "Northern Sotho (nso_ZA)", "value": "nso_ZA" },
    { "label": "Norwegian / Norsk (nb_NO)", "value": "nb_NO" },
    { "label": "Odia / ଓଡ଼ିଆ (or_IN)", "value": "or_IN" },
    { "label": "Pamona (pmf_ID)", "value": "pmf_ID" },
    { "label": "Papuan Malay (pmy_ID)", "value": "pmy_ID" },
    { "label": "Persian / فارسی (fa_IR)", "value": "fa_IR" },
    { "label": "Polish / Polski (pl_PL)", "value": "pl_PL" },
    { "label": "Portuguese (BR) / Português (pt_BR)", "value": "pt_BR" },
    { "label": "Portuguese (PT)/ Português (pt_PT)", "value": "pt_PT" },
    { "label": "Punjabi / ਪੰਜਾਬੀ (pa_IN)", "value": "pa_IN" },
    { "label": "Rajasthani / राजस्थानी (raj_IN)", "value": "raj_IN" },
    { "label": "Romanian / Română (ro_RO)", "value": "ro_RO" },
    { "label": "Russian / Pусский (ru_RU)", "value": "ru_RU" },
    { "label": "Sanskrit / संस्कृतम् (sa_IN)", "value": "sa_IN" },
    { "label": "Santali / संताली (sat_IN)", "value": "sat_IN" },
    { "label": "Sasak (sas_ID)", "value": "sas_ID" },
    { "label": "Serbian / Cрпски (sr_RS)", "value": "sr_RS" },
    { "label": "Sesotho (st_ZA)", "value": "st_ZA" },
    { "label": "Sindhi / सिन्धी (sd_IN)", "value": "sd_IN" },
    { "label": "Sinhala / සිංහල (si_LK)", "value": "si_LK" },
    { "label": "Slovak / Slovenčina (sk_SK)", "value": "sk_SK" },
    { "label": "Slovenian / Slovenščina (sl_SI)", "value": "sl_SI" },
    { "label": "Spanish / Español (es_ES)", "value": "es_ES" },
    { "label": "Spanish / Latinoamérica (es_US)", "value": "es_US" },
    { "label": "Sundanese (su_ID)", "value": "su_ID" },
    { "label": "Swedish / Svenska (sv_SE)", "value": "sv_SE" },
    { "label": "Tamil / தமிழ் (ta_IN)", "value": "ta_IN" },
    { "label": "Tamil / தமிழ் (Sri Lanka) (ta_LK)", "value": "ta_LK" },
    { "label": "Telugu / తెలుగు (te_IN)", "value": "te_IN" },
    { "label": "Tetum (tet_TL)", "value": "tet_TL" },
    { "label": "Thai / ไทย (th_TH)", "value": "th_TH" },
    { "label": "Toraja-Sa’dan (sda_ID)", "value": "sda_ID" },
    { "label": "Tsonga (tso_ZA)", "value": "tso_ZA" },
    { "label": "Tswana (tsn_ZA)", "value": "tsn_ZA" },
    { "label": "Turkish / Türkçe (tr_TR)", "value": "tr_TR" },
    { "label": "Ukrainian / Українська (uk_UA)", "value": "uk_UA" },
    { "label": "Urdu (PK) / بھارتو (ur_PK)", "value": "ur_PK" },
    { "label": "Uzbek / Ózbekça (uz_UZ)", "value": "uz_UZ" },
    { "label": "Vietnamese / Việt (vi_VN)", "value": "vi_VN" },
    { "label": "اردو / Urdu (ur_IN)", "value": "ur_IN" },
    { "label": "العَرَبِيَّة / Arabic (ar_EG)", "value": "ar_EG" },
    { "label": "کأشُر / Kashmiri (ks_IN)", "value": "ks_IN" },
    { "label": "नेपाली (नेपाल)/ Nepali (ne_NP)", "value": "ne_NP" }
];
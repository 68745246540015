import React, { useState } from "react";
import ThemeCategoryFormContainer from "./ThemeCategoryFormContainer.js";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { permissions } from "../../../utilities/commonUtil";
import { Col } from "reactstrap";
const CreateThemeCategory = (props) => {
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  const createAllowed = () => {
    return allowedPermissions.includes(permissions.keyboardThemeCategoryCreate);
  };

  return (
    <Col lg="8">
      <ThemeCategoryFormContainer
        {...props}
        formName={"Create Theme Category"}
        updateAllowed={createAllowed()}
      />
    </Col>
  );
};

export default CreateThemeCategory;

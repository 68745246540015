import React from 'react';
import {Badge} from "reactstrap";  // replace 'path-to-your-badge-component' with the actual import path
import { languageMapping } from '../../../../utilities/languageMapping';


const movieGifColumns = [
  {
    dataField: "type",
    text: "Type",
    sort: false,
    searchable: true,
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
    formatter: (cell ,row , rowIndex , columnIndex) => {
      console.log(row)
      const typeMapping = {
        'generic': 'Generic',
        'objects': 'Objects',
        'geography': 'Geography',
        'otfTexts': 'OtfTexts',
        'expression': 'Expression',
      };
      const formattedType = typeMapping[row.type.toLowerCase()] || cell;

      return <span>{formattedType}</span>;
    },
  },
  {
    dataField:"languageCode",
    text:"LanguageCode",
    sort: false,
    searchable: true,
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
    formatter:(cell, row, rowIndex, columnIndex) => {
      const languageId = row.languageId
      var languageCodeString;
      for(let i = 0 ; i < languageMapping.length ; i++){
        var val = languageMapping[i]
        if (val.id.toString() === languageId){
          languageCodeString = val.name
        }
       }
      return <span>{languageCodeString}</span> 
    },
  },
  {
    dataField: "text",
    text: "Text",
    sort: false,
    searchable: true,
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
    formatter: (cell, row, rowIndex, columnIndex) => {
      if (columnIndex === 1 && row.text) {
        // Use map to create an array of Badge components
        const badges = row.text.map((text, index) => (
          <Badge
            key={index}
            className="badge-secondary ml-2 mb-2 p-1"
          >
            {text}
          </Badge>
        ));

        return badges;
      }
      return cell;
    },
  },
];

const gifColumns = [
  {
    dataField: "type",
    text: "Type",
    sort: false,
    searchable: true,
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
    formatter: (cell) => {
      const typeMapping = {
        'generic': 'Generic',
        'objects': 'Objects',
        'generic_search_tag': 'Generic Search Tag',
        'generic_emoji_search_tag': 'Generic Emoji Search Tag',
        'generic_otf_tag': 'Generic Otf Tag',
      };
      const formattedType = typeMapping[cell.toLowerCase()] || cell;

      return <span>{formattedType}</span>;
    },
  },
  {
    dataField: "text",
    text: "Text",
    sort: false,
    searchable: true,
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
    formatter: (cell, row, rowIndex, columnIndex) => {
      if (columnIndex === 1 && row.text) {
        // Use map to create an array of Badge components
        const badges = row.text.map((text, index) => (
          <Badge
            key={index}
            className="badge-secondary ml-2 mb-2 p-1"
          >
            {text}
          </Badge>
        ));

        return badges;
      }
      return cell;
    },
  },
];

export { movieGifColumns , gifColumns };

import React, { Component } from "react";
import { Col } from "reactstrap";

class Empty extends Component {
  render() {
    return (
      <Col sm="12" md="8" lg="6" className="mx-auto d-table mt-5 pt-2 pb-5">
        <h1 className="text-center">{this.props.statusInfo}</h1>
        <h1 className="text-center">No Stickers</h1>
      </Col>
    );
  }
}

export default Empty;

import { retrieveFromLocalStorage } from "../localStorageUtil";
import { secureFetch } from "../secureFetch";
import { host } from "./index";

export let GetAIPromptTemplates = async () => {
    let token = retrieveFromLocalStorage("token");
    let url = `${host}/v4/internal/generativeAI/promptTemplates`;
    try {
        var response = await secureFetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};

export let GetAIPromptTemplateVersions = async (templateId) => {
    let token = retrieveFromLocalStorage("token");
    let url = `${host}/v4/internal/generativeAI/promptTemplates/${templateId}/versions`;
    try {
        var response = await secureFetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};

export let GetAIPromptTemplatesDetails = async (id) => {
    let token = retrieveFromLocalStorage("token");
    let url = `${host}/v4/internal/generativeAI/promptTemplates/${id}`;
    try {
        var response = await secureFetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};

export let createPromptTemplate = async (data) => {
    let url = `${host}/v4/internal/generativeAI/promptTemplates`;
    let token = retrieveFromLocalStorage("token");
    try {
        let response = await secureFetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return await response.json();
    } catch (error) {
        return error?.json();
    }
};
export let updatePromptTemplate = async (data, id) => {
    let url = `${host}/v4/internal/generativeAI/promptTemplates/${id}`;
    let token = retrieveFromLocalStorage("token");
    try {
        let response = await secureFetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let activatePromptTemplate = async (id) => {
    let url = `${host}/v4/internal/generativeAI/promptTemplates/${id}/activate`;
    let token = retrieveFromLocalStorage("token");
    try {
        let response = await secureFetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let deactivatePromptTemplate = async (id) => {
    let url = `${host}/v4/internal/generativeAI/promptTemplates/${id}/deactivate`;
    let token = retrieveFromLocalStorage("token");
    try {
        let response = await secureFetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let publishPromptTemplate = async (id) => {
    let url = `${host}/v4/internal/generativeAI/promptTemplates/${id}/publish`;
    let token = retrieveFromLocalStorage("token");
    try {
        let response = await secureFetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let createPromptTemplateVersion = async (data) => {
    let url = `${host}/v4/internal/generativeAI/promptTemplates/versions`;
    let token = retrieveFromLocalStorage("token");
    try {
        let response = await secureFetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return await response.json();
    } catch (error) {
        return error?.json();
    }
};
export let updatePromptTemplateVersion = async (data, id) => {
    let url = `${host}/v4/internal/generativeAI/promptTemplates/versions/${id}`;
    let token = retrieveFromLocalStorage("token");
    try {
        let response = await secureFetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return await response.json();
    } catch (error) {
        return;
    }
};

export let GetAIPlacements = async () => {
    let token = retrieveFromLocalStorage("token");
    let url = `${host}/v4/internal/generativeAI/placements`;
    try {
        var response = await secureFetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let GetAIPlacementDetails = async (id) => {
    let token = retrieveFromLocalStorage("token");
    let url = `${host}/v4/internal/generativeAI/placements/${id}`;
    try {
        var response = await secureFetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let CreateAIPlacement = async (data) => {
    let url = `${host}/v4/internal/generativeAI/placements`;
    let token = retrieveFromLocalStorage("token");
    try {
        let response = await secureFetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return await response.json();
    } catch (error) {
        return error?.json();
    }
};
export let UpdateAIPlacement = async (data, id) => {
    let url = `${host}/v4/internal/generativeAI/placements/${id}`;
    let token = retrieveFromLocalStorage("token");
    try {
        let response = await secureFetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return await response.json();
    } catch (error) {
        return error?.json();
    }
};
export let DeactivateAIPlacement = async (id) => {
    let url = `${host}/v4/internal/generativeAI/placements/${id}/deactivate`;
    let token = retrieveFromLocalStorage("token");
    try {
        let response = await secureFetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let ActivateAIPlacement = async (id) => {
    let url = `${host}/v4/internal/generativeAI/placements/${id}/activate`;
    let token = retrieveFromLocalStorage("token");
    try {
        let response = await secureFetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let PublishAIPlacement = async (id) => {
    let url = `${host}/v4/internal/generativeAI/placements/${id}/publish`;
    let token = retrieveFromLocalStorage("token");
    try {
        let response = await secureFetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let GetAIPlacementButtons = async (placementId) => {
    let token = retrieveFromLocalStorage("token");
    let url = `${host}/v4/internal/generativeAI/placements/${placementId}/buttons`;
    try {
        var response = await secureFetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let GetAIButtonDetails = async (placementId, id) => {
    let token = retrieveFromLocalStorage("token");
    let url = `${host}/v4/internal/generativeAI/placements/${placementId}/buttons/${id}`;
    try {
        var response = await secureFetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let CreateAIButton = async (data, placementId) => {
    let url = `${host}/v4/internal/generativeAI/placements/${placementId}/buttons`;
    let token = retrieveFromLocalStorage("token");
    try {
        let response = await secureFetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return await response.json();
    } catch (error) {
        return error?.json();
    }
};
export let UpdateAIButton = async (data, placementId, id) => {
    let url = `${host}/v4/internal/generativeAI/placements/${placementId}/buttons/${id}`;
    let token = retrieveFromLocalStorage("token");
    try {
        let response = await secureFetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return await response.json();
    } catch (error) {
        return error?.json();
    }
};
export let GetAITasks = async () => {
    let token = retrieveFromLocalStorage("token");
    let url = `${host}/v4/internal/generativeAI/tasks`;
    try {
        var response = await secureFetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let GetAITaskDetails = async (taskId) => {
    let token = retrieveFromLocalStorage("token");
    let url = `${host}/v4/internal/generativeAI/tasks/${taskId}`;
    try {
        var response = await secureFetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let CreateAITask = async (data) => {
    let url = `${host}/v4/internal/generativeAI/tasks`;
    let token = retrieveFromLocalStorage("token");
    try {
        let response = await secureFetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return await response.json();
    } catch (error) {
        return error?.json();
    }
};
export let UpdateAITask = async (data, id) => {
    let url = `${host}/v4/internal/generativeAI/tasks/${id}`;
    let token = retrieveFromLocalStorage("token");
    try {
        let response = await secureFetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return await response.json();
    } catch (error) {
        return error?.json();
    }
};
export let DeactivateAITask = async (id) => {
    let url = `${host}/v4/internal/generativeAI/tasks/${id}/deactivate`;
    let token = retrieveFromLocalStorage("token");
    try {
        let response = await secureFetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let ActivateAITask = async (id) => {
    let url = `${host}/v4/internal/generativeAI/tasks/${id}/activate`;
    let token = retrieveFromLocalStorage("token");
    try {
        let response = await secureFetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let PublishAITask = async (id) => {
    let url = `${host}/v4/internal/generativeAI/tasks/${id}/publish`;
    let token = retrieveFromLocalStorage("token");
    try {
        let response = await secureFetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let GetAITaskDropdownOptions = async (taskId) => {
    let token = retrieveFromLocalStorage("token");
    let url = `${host}/v4/internal/generativeAI/tasks/${taskId}/dropdownOptions`;
    try {
        var response = await secureFetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let GetAITaskDropdownOptionDetails = async (taskId, id) => {
    let token = retrieveFromLocalStorage("token");
    let url = `${host}/v4/internal/generativeAI/tasks/${taskId}/dropdownOptions/${id}`;
    try {
        var response = await secureFetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
};
export let CreateAIDropdownOption = async (data, taskId) => {
    let url = `${host}/v4/internal/generativeAI/tasks/${taskId}/dropdownOptions`;
    let token = retrieveFromLocalStorage("token");
    try {
        let response = await secureFetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return await response.json();
    } catch (error) {
        return error?.json();
    }
};
export let UpdateAIDropdownOption = async (data, taskId, id) => {
    let url = `${host}/v4/internal/generativeAI/tasks/${taskId}/dropdownOptions/${id}`;
    let token = retrieveFromLocalStorage("token");
    try {
        let response = await secureFetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return await response.json();
    } catch (error) {
        return error?.json();
    }
};
import fetch from "isomorphic-fetch";
import { retrieveFromLocalStorage } from "./localStorageUtil";
import { host } from "./apiUtils/index";
import { redirectOnTokenExpiry } from "./commonUtil";
import { maxDefaultLimit } from "../config/UserConfig";
import { secureFetch } from "./secureFetch";

export let fetchGifPacks = async () => {
  let token = retrieveFromLocalStorage("token");
  let gifPack = {};

  let url = `${host}/v4/internal/stickers/gifPacks`;

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    gifPack = response.json();
  });

  return gifPack;
};

export let fetchStickerPack = async (id) => {
  let token = retrieveFromLocalStorage("token");
  let stickerPack = {};

  let url = `${host}/v4/internal/stickerPacks/${id}`;

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    if (response.status === 200) stickerPack = response.json();
    else if (response.status === 401) redirectOnTokenExpiry();
  });

  return stickerPack;
};

export let fetchGifPack = async (id) => {
  let token = retrieveFromLocalStorage("token");
  let gifPack = {};

  let url = `${host}/v4/internal/gifPacks/${id}`;

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    if (response.status === 200) gifPack = response.json();
    else if (response.status === 401) redirectOnTokenExpiry();
  });

  return gifPack;
};

export let fetchStickersInStickerPack = async (id, params) => {
  let token = retrieveFromLocalStorage("token");
  let stickers = {};

  let url = `${host}/v4/internal/stickerPacks/${id}/stickers?` + new URLSearchParams(params);;

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    if (response.status === 200) stickers = response.json();
    else if (response.status === 401) redirectOnTokenExpiry();
  });

  return stickers;
};

export let fetchGifsInGifPack = async (id, params) => {
  let token = retrieveFromLocalStorage("token");
  let gifs = {};

  let url = `${host}/v4/internal/gifPacks/${id}/gifs?` + new URLSearchParams(params);

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    if (response.status === 200) gifs = response.json();
    else if (response.status === 401) redirectOnTokenExpiry();
  });

  return gifs;
};

export let fetchStickerPacks = async () => {
  let token = retrieveFromLocalStorage("token");
  let stickerPack = {};

  let url = `${host}/v4/internal/stickers/stickerPacks`;

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    if (response.status === 200) stickerPack = response.json();
    else if (response.status === 401) redirectOnTokenExpiry();
  });

  return stickerPack;
};

export let fetchOfferZones = async () => {
  let token = retrieveFromLocalStorage("token");
  let offers = {};

  let url = `${host}/v4/internal/ads/keyboardPromotionalOffers`;

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    if (response.status === 200) offers = response.json();
    else if (response.status === 401) redirectOnTokenExpiry();
  });

  return offers;
};

export let fetchAllStickerPacks = async (params = {}) => {
  if (!params.limit) params.limit = maxDefaultLimit;
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/stickerPacks?` + new URLSearchParams(params);

  let res = {};
  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      res = data;
    });
  return res;
};

export let fetchAllGifPacks = async (params = {}) => {
  if (!params.limit) params.limit = maxDefaultLimit;
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/gifPacks?` + new URLSearchParams(params);

  let res = {};
  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      res = data;
    });
  return res;
};

export let fetchStickers = async (params) => {
  let token = retrieveFromLocalStorage("token");
  let stickers = {};

  let url = `${host}/v4/internal/stickers?` + new URLSearchParams(params);

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    stickers = response.json();
  });
  return stickers;
};

export let fetchGifs = async (params) => {
  let token = retrieveFromLocalStorage("token");
  let gifs = {};

  let url = `${host}/v4/internal/gifs?` + new URLSearchParams(params);

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    gifs = response.json();
  });
  return gifs;
};

export let fetchSticker = async (id) => {
  let token = retrieveFromLocalStorage("token");
  let sticker = {};

  let url = `${host}/v4/internal/stickers/${id}`;

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    sticker = response.json();
  });

  return sticker;
};

export let fetchGif = async (id) => {
  let token = retrieveFromLocalStorage("token");
  let gif = {};

  let url = `${host}/v4/internal/gifs/${id}`;

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    gif = response.json();
  });

  return gif;
};

export let getStickerPacks = async () => {
  let response = await fetchAllStickerPacks().then((stickerPacksObject) => {
    if (stickerPacksObject.errorCode) {
      return stickerPacksObject;
    } else {
      let stickerPacks = [];
      Object.values(stickerPacksObject).map((allStickerPacks) => {
        allStickerPacks.map((stickerPack) => {
          let stickerObj = {
            value: stickerPack.id,
            label: stickerPack.name,
          };
          stickerPacks.push(stickerObj);
        });
      });
      return stickerPacks;
    }
  });
  return response;
};

export let getGifPacks = async () => {
  let response = await fetchAllGifPacks().then((gifPacksObject) => {
    if (gifPacksObject.errorCode) {
      return gifPacksObject;
    } else {
      let gifPacks = [];
      Object.values(gifPacksObject).map((allGifPacks) => {
        allGifPacks.map((gifPack) => {
          let gifObj = {
            value: gifPack.id,
            label: gifPack.name,
          };
          gifPacks.push(gifObj);
        });
      });
      return gifPacks;
    }
  });
  return response;
};

export let addStatus = (created, published, deactivated) => {
  if (created !== null && published === null) return "Created";
  else if (created !== null && published !== null && deactivated === null)
    return "Activated";
  else if (created !== null && published !== null && deactivated !== null)
    return "Deactivated";
};

export let reorderStickers = async (stickerIds, stickerPackId) => {
  let token = retrieveFromLocalStorage("token");
  let response = {};

  let url = `${host}/v4/internal/stickerPacks/${stickerPackId}/reorderStickers`;

  await fetch(url, {
    method: "POST",
    body: JSON.stringify(stickerIds),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      response = res;
    });

  return response;
};

export let importStickers = async (stickers, stickerPackId) => {
  let token = retrieveFromLocalStorage("token");
  let response = {};

  let url = `${host}/v4/internal/stickerPacks/${stickerPackId}/importStickers`;

  await fetch(url, {
    method: "POST",
    body: JSON.stringify(stickers),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      response = res;
    });

  return response;
};

export let updateStickerStatus = async (stickerId, action, stickerPackIds, globalStatus) => {
  let token = retrieveFromLocalStorage("token");
  let response = {};

  let url = `${host}/v4/internal/stickers/${stickerId}/${action}`;

  let data = {
    stickerPackIds: stickerPackIds,
    updateSticker: globalStatus,
  }
  await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      response = res;
    });

  return response;
};

export let reorderGifs = async (gifIds, gifPackId) => {
  let token = retrieveFromLocalStorage("token");
  let response = {};

  let url = `${host}/v4/internal/gifPacks/${gifPackId}/reorderGifs`;

  await fetch(url, {
    method: "POST",
    body: JSON.stringify(gifIds),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      response = res;
    });

  return response;
};

export let importGifs = async (gifs, gifPackId) => {
  let token = retrieveFromLocalStorage("token");
  let response = {};

  let url = `${host}/v4/internal/gifPacks/${gifPackId}/importGifs`;

  await fetch(url, {
    method: "POST",
    body: JSON.stringify(gifs),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      response = res;
    });

  return response;
};

export let updateGifStatus = async (gifId, action, gifPackIds, globalStatus) => {
  let token = retrieveFromLocalStorage("token");
  let response = {};

  let url = `${host}/v4/internal/gifs/${gifId}/${action}`;

  let data = {
    gifPackIds: gifPackIds,
    updateGif: globalStatus,
  }
  await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      response = res;
    });

  return response;
};

export let getPremiumProducts = async () => {

  let url = `${host}/v5/internal/premium/products`;
  try {
    var response = await secureFetch(url, {
      method: "GET",
    });
    var res = await response.json();
    return res;
  } catch (error) {
    return;
  }
};

export let updateStickerPackStickers = async (stickerPackId, data) => {
  let token = retrieveFromLocalStorage("token");
  let response = {};
  
  let url = `${host}/v4/internal/stickerPacks/${stickerPackId}/stickers`;
  await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      response = res;
    });

  return response;
};

export let updateGifPackGifs = async (gifPackId, data) => {
  let token = retrieveFromLocalStorage("token");
  let response = {};
  
  let url = `${host}/v4/internal/gifPacks/${gifPackId}/gifs`;
  await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      response = res;
    });

  return response;
};
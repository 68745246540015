export let MinPasswordLength = 6;
export const iconSize = 20;
export const tagFontSize = "12px";
export const deactivatedTagColor = "rgba(237, 76, 76, 0.5)";
export const activatedTagColor = "rgba(102, 227, 89, 0.5)";

export const bigMojiImageUploadSuccessText = "The Image has been successfully added for the given emoji"
export const bigMojiImageUpdateSuccessText = "The Image has been successfully updated for the given emoji"

export const statusOptions = [
  { value: "activated", label: "Activated" },
  { value: "deactivated", label: "Deactivated" },
  { value: "all", label: "All" }
];

export let Config = {
  KEYPRESS_DOWN: 13,
  PUBLISHED_STATUS: "Published",
  UNPUBLISHED_STATUS: "Unpublished",
  DEACTIVATED_STATUS: "Deactivated",
  COMPLETED_STATUS: "Completed",
  ACTIVATE_BUTTON: "Activate",
  DEACTIVATE_BUTTON: "Deactivate",
  PUBLISH_BUTTON: "Publish",
  UPDATED_AT_DESCENDING: "updated_at desc",
  UPDATED_AT_ASCENDING: "updated_at asc",
  CREATED_AT_DESCENDING: "created_at desc", 
  CREATED_AT_ASCENDING: "created_at asc", 
  DEFAULT_PACKS: "default",
  SEARCH_BY_DEFAULT: "tag",
};

export const gendersList = [
  {
    label: "Unisex",
    value: "unisex",
  },
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
];

export const eventTypeMissingText = "Event Type field is required"
export const defaultBrandCampaign = { value: "none", label: "none" };
export const imageValidationFailureText = "Any one of the image files must be uploaded. Either GIF or PNG"
export const waterMarkMissingText = "Watermark support should be present"
export const isComboEmojiStickerMissingText = "Is Combo BigMoji field is required"

export const passwordResetSuccessText = "Password reset successful";
export const success = "success";
export const permissionUpdateSuccessful = "Successfully updated permission";
export const dateValidationFailure =
  "Start Date must be Less/Equal than End Date or both should be NULL";
export const maxDefaultLimit = 99999;
export const userUpdateSuccessText = "Successfully updated user details";
export const permissionCreateSuccessText = "Permission created successfully.";
export const stickerUpdateSuccessText = "Successfully updated sticker details";


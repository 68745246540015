import React from "react";
import ReactTooltip from "react-tooltip";
import { FaInfoCircle } from "react-icons/fa";

const ToolTip = (props) => {
  return (
    <>
      <span data-tip data-for={props.id}>
        <FaInfoCircle />
        <ReactTooltip id={props.id} place="top" effect="solid">
          <div dangerouslySetInnerHTML={{ __html: props.data }} />
        </ReactTooltip>
      </span>
    </>
  );
};

export default ToolTip;
import React, { useState, useEffect } from "react";
import TargetingRules from "../TargetingRules";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { permissions } from "../../../utilities/commonUtil";
import {
  fetchSugggestionDrawerContentTargetingRules,
  updateSugggestionDrawerContentTargetingRules,
} from "../../../utilities/apiUtils/suggestionDrawer";
import {
  getCountries,
  getRegions,
  getCities,
} from "../../../utilities/apiUtils/smartSuggestions";
import { getClients } from "../../../utilities/apiUtils/cre";

const SuggestionDrawerContentTargetingRules = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const contentIds = queryParams.get("contentIds");
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  const updateRulesAllowed = () =>
    allowedPermissions.includes(
      permissions.SuggestionDrawerContentRulesUpdate
    );

  useEffect(() => {
    if (!contentIds) {
      console.warn("No content IDs provided.");
    }
  }, [contentIds]);

  const saveRules = async (rules, id, data) => {
    if (id.includes(",")) {
      const idsArray = id.split(",");
      for (const individualId of idsArray) {
        try {
          await updateSugggestionDrawerContentTargetingRules(rules, individualId, data);
        } catch (error) {
          console.error(`Error updating rules for ID ${individualId}:`, error);
        }
      }
    } else {
      await updateSugggestionDrawerContentTargetingRules(rules, id, data);
    }
  };

  return (
    <div>
      <TargetingRules
        showAgeGenderRules={true}
        showCustomAudienceTargetingRules={false}
        showPlacementRules={false}
        showClientRules={true}
        showHardFiltering={false}
        isAdvertisingAttributes={true}
        id={contentIds}
      //  getRules={fetchSugggestionDrawerContentTargetingRules}
        saveRules={async (rules, id, data) => {
          if (id.includes(",")) {
              const idsArray = id.split(",");
              for (const individualId of idsArray) {
                  try {
                      await updateSugggestionDrawerContentTargetingRules(rules, individualId, data);
                  } catch (error) {
                      console.error(`Error updating rules for ID ${individualId}:`, error);
                  }
              }
          } else {
              await updateSugggestionDrawerContentTargetingRules(rules, id, data);
          }
      }}
        getClients={getClients}
        getCountries={getCountries}
        getRegions={getRegions}
        getCities={getCities}
        updateRulesAllowed={updateRulesAllowed()}
      />
    </div>
  );
};

export default SuggestionDrawerContentTargetingRules;

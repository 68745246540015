import React from "react";
import { Badge } from "reactstrap";
import {
    reduceSentence,
} from "../../../../utilities/commonUtil";

export const columns = [
    {
        dataField: "id",
        text: "#",
        headerAttrs: { width: 60 },
        searchable: false,
        sort: true,
        style: {
            cursor: "pointer",
        },
    },
    {
        dataField: "name",
        text: "Name",
        searchable: true,
        style: {
            cursor: "pointer",
        },
        // limit upto 5 words
        formatter: (cell) => reduceSentence(cell),
    },
    {
        dataField: "description",
        text: "Description",
        searchable: true,
        style: {
            cursor: "pointer",
        },
        // limit upto 5 words
        formatter: (cell) => reduceSentence(cell),
    },
    {
        dataField: "type",
        text: "Type",
        searchable: true,
        style: {
            cursor: "pointer",
        },
    },
    {
        dataField: "status",
        text: "Status",
        searchable: true,
        style: {
            cursor: "pointer",
        },
        formatter: (cell) => {
            if ({ cell }.cell === "Activated")
                return <Badge className="badge-success">{cell}</Badge>;
            else if ({ cell }.cell === "Deactivated")
                return <Badge className="badge-danger">{cell}</Badge>;
            else if ({ cell }.cell === "Unpublished")
                return <Badge className="badge-primary">{cell}</Badge>;
        },
    },
    {
        dataField: "createdAt",
        text: "Created At",
        searchable: true,
        style: {
            cursor: "pointer",
        },
    },
    {
        dataField: "updatedAt",
        text: "Updated At",
        searchable: true,
        style: {
            cursor: "pointer",
        },
    },
];


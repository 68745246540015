export const successModalHeading = "Success";
export const failureModalHeading = "Error";

export const missingCountryRegionCityStatus = "missingCountryRegionCity";

export const locationRuleCreatedSuccessfullyText =
  "Location Rule created Successfully.";
export const themeCreatedSuccessfullyText =
  "Keyboard theme created successfully.";
export const themeUpdatedSuccessfullyText = "Keyboard theme updated.";

export const publishedStatus = "published";
export const deactivatedStatus = "deactivated";
export const successStatus = "success";
export const activatedStatus = "activated";

import React, { useState, useEffect } from "react";
import Search from "../../../components/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas, faUpload, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import Loading from "../../../components/Loading";

import {
    Badge,
    Container,
    Card,
    CardBody,
    Button,
    CardHeader,
    Col,
    Row,
} from "reactstrap";
import {
    redirectTo,
    error,
    errorDescription,
    redirectionOnTokenExpiry,
    allActions,
    getStatus,
    getBtnName,
    getButtonColor,
    getStatusColor,
    permissions,
} from "../../../utilities/commonUtil";
import {
    fetchKeyboardLanguages,
    publishKeyboardLanguage,
    activateKeyboardLanguage,
    deactivateKeyboardLanguage,
} from "../../../utilities/apiUtils/keyboardLanguages";
import { Config } from "../../../config/UserConfig";
import ResponseModal from "../ResponseModal";
import ConfirmationModal from "../ConfirmationModal";
import {
    successModalHeading,
    errorModalHeading,
    publishSuccess,
    deactivateSuccess,
    activateSuccess
} from "../../../config/config";

const KeyboardLanguages = () => {
    const [keyboardLanguages, setKeyboardLanguages] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [searchedLanguages, setSearchedLanguages] = useState([]);
    const [loading, setLoading] = useState(false);

    const [successModalState, setSuccessModalState] = useState({
        successModalDisplay: false,
        successModalText: "",
    });
    const [failureModalState, setFailureModalState] = useState({
        failureModalDisplay: false,
        failureModalText: "",
    });

    const [allowedPermissions, setAllowedPermissions] = useState(
        retrieveFromLocalStorage("userPermissions")
    );

    const [id, setID] = useState("");
    const [action, setAction] = useState("");
    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);

    // This useEffect will be called for componentDidMount condition
    useEffect(() => {
        getLanguages();
    }, []);

    const getLanguages = async () => {
        setLoading(true);
        try {
            const response = await fetchKeyboardLanguages();
            if (response.errorCode) {
                setLoading(false);
                setFailureModalState({
                    failureModalDisplay: true,
                    failureModalText: errorDescription(response.errorCode),
                });
                if (
                    response.errorCode === "Unauthorized" ||
                    response.errorCode === error.invalidAccessToken ||
                    response.errorCode === error.tokenExpired
                )
                    redirectionOnTokenExpiry();
            } else if (response.keyboardLanguages) {
                setKeyboardLanguages(response.keyboardLanguages);
                setSearchedLanguages(response.keyboardLanguages);
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            setFailureModalState({
                failureModalDisplay: true,
                failureModalText: errorDescription(error.unexpectedError),
            });
        }
    };

    const getIcon = (status) => {
        if (status === Config.PUBLISHED_STATUS) return faTimes;
        return faUpload;
    };

    const successModalClose = () => {
        setSuccessModalState((prev) => ({ ...prev, successModalDisplay: false }));
        getLanguages();
    };

    const failureModalClose = () => {
        setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
    };

    const handleSearchTextChange = (event) => {
        const { value } = event.target;
        setSearchText(value);
        if (!value.length) {
            reset();
        }
        event.preventDefault();
    };

    const reset = () => {
        setSearchedLanguages(keyboardLanguages);
    };

    const onKeyDown = (event) => {
        if (event.keyCode === Config.KEYPRESS_DOWN) {
            search();
        }
    };

    const search = () => {
        if (searchText === "") return;
        let searchedLanguages = filterLanguages(keyboardLanguages);
        setSearchedLanguages(searchedLanguages);
    };

    // Filter keyboard Languages that include search text in their name
    const filterLanguages = (keyboardLanguages) => {
        return keyboardLanguages.filter((language) => {
            let text = language.name;
            return text.toLowerCase().includes(searchText.toLowerCase());
        });
    };

    const performAction = () => {
        if (action === allActions.publish) {
            publish(id);
        }
        if (action === allActions.deactivate) {
            deactivate(id);
        }
        if (action === allActions.activate) {
            activate(id);
        }
    };

    const getResponseStatus = (action) => {

        let status = {
            "Publish": publishSuccess,
            "Deactivate": deactivateSuccess,
            "Activate": activateSuccess,
        }
        return status[action];
    };

    const handleResponse = (response, action) => {
        if (!response.errorCode) {
            let text = getResponseStatus(action);
            setSuccessModalState({
                successModalDisplay: true,
                successModalText: text,
            });
            return;
        }
        setFailureModalState({
            failureModalDisplay: true,
            failureModalText: response.errorCode,
        });
    };

    const publish = async (id) => {
        let response = await publishKeyboardLanguage(id);
        handleResponse(response, allActions.publish);
    };

    const deactivate = async (id) => {
        let response = await deactivateKeyboardLanguage(id);
        handleResponse(response, allActions.deactivate);
    };

    const activate = async (id) => {
        let response = await activateKeyboardLanguage(id);
        handleResponse(response, allActions.activate);
    };

    const setConfirmationModalState = (id, btnName) => {
        setID(id);
        setAction(btnName);
        setConfirmationModalIsOpen(true);
    };

    const showCreateKeyboardLanguageButton = () => {
        return allowedPermissions.includes(permissions.keyboardLanguageCreate);
    };

    const showManageSuggestedLanguagesButton = () => {
        return allowedPermissions.includes(
            permissions.keyboardLanguageManageSuggested
        );
    };

    return (
        <Container>
            <Row>
                <Col md="4" sm="12">
                    <Search
                        value={searchText}
                        onChange={(event) => handleSearchTextChange(event)}
                        onKeyDown={(event) => onKeyDown(event)}
                    ></Search>
                </Col>
                <Col md="4" sm="2">
                    <Button color="success" onClick={() => search()}>
                        Search
                    </Button>
                </Col>
            </Row>
            <Row>
                <h1 className="mb-4 mt-2 w-50 pl-3">Keyboard Languages</h1>
                {showCreateKeyboardLanguageButton() ? (
                    <Link
                        className="d-block ml-auto mb-4 pr-3 text-decoration-none"
                        to={{
                            pathname: `/kl/create-keyboard-language`,
                        }}
                    >
                        <Button color="primary" className="ml-auto d-flex">
                            <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                                Add Keyboard Language
                            </p>
                        </Button>
                    </Link>
                ) : null}
            </Row>
            {loading ? (
                <Loading />
            ) : (
                <Row>
                    {Object(searchedLanguages).map((language, index) => {
                        return (
                            <Col md={6} key={index}>
                                <Card>
                                    <CardHeader
                                        style={{ backgroundColor: "rgba(0, 185, 183, 0.7)" }}
                                        className="d-flex justify-content-center"
                                    >
                                        <h4 className="mb-0">{language.name ?? "N/A"}</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <Badge
                                            color={getStatusColor(
                                                getStatus(language.publishedAt, language.deactivatedAt)
                                            )}
                                            className="d-block float-right "
                                        >
                                            {getStatus(language.publishedAt, language.deactivatedAt)}
                                        </Badge>
                                        <span>
                                            <strong>ID:</strong> {language.id ?? "N/A"}
                                        </span>
                                        <br />
                                        <span>
                                            <strong>Name:</strong> {language.name ?? "N/A"}
                                        </span>
                                        <br />
                                        <span>
                                            <strong>Locale:</strong> {language.locale ?? "N/A"}
                                        </span>
                                        <br />
                                        <span>
                                            <strong>Priority:</strong> {language.priority ?? "N/A"}
                                        </span>
                                        <br />
                                        <span>
                                            <strong>language ID:</strong> {language.languageId ?? "N/A"}
                                        </span>
                                        <br />
                                        <span>
                                            <strong>language Code:</strong> {language.code ?? "N/A"}
                                        </span>
                                        <br />
                                        <span>
                                            <strong>Min App Version:</strong> {language.minAppVersion ?? "N/A"}
                                        </span>
                                        <br />
                                        <span>
                                            <strong>Min Bobble SDK Version:</strong> {language.minBobbleSDKVersion ?? "N/A"}
                                        </span>
                                        <br />
                                        <span>
                                            <strong>Device Type:</strong>{" "}
                                            {language.deviceType ?? "N/A"}
                                        </span>
                                        <br />
                                        <span>
                                            <strong>Macronic Base Keyboard Language ID:</strong> {language.macaronicBaseKeyboardLanguageId ?? "N/A"}
                                        </span>
                                        <br />
                                        <span></span>
                                        <Row className="mt-2">
                                            <Col md={6} className="my-2">
                                                <Button
                                                    className="btn-block btn-info"
                                                    onClick={() =>
                                                        redirectTo(
                                                            `/kl/keyboard-languages/${language.id}/layouts`
                                                        )
                                                    }
                                                >
                                                    View Details
                                                </Button>
                                            </Col>
                                            <Col md={6} className="my-2">
                                                <Button
                                                    color={getButtonColor(
                                                        getStatus(
                                                            language.publishedAt,
                                                            language.deactivatedAt
                                                        )
                                                    )}
                                                    className="btn-block"
                                                    onClick={() => {
                                                        setConfirmationModalState(
                                                            language.id,
                                                            getBtnName(
                                                                getStatus(
                                                                    language.publishedAt,
                                                                    language.deactivatedAt
                                                                )
                                                            )
                                                        );
                                                    }}
                                                >
                                                    {getBtnName(
                                                        getStatus(
                                                            language.publishedAt,
                                                            language.deactivatedAt
                                                        )
                                                    )}
                                                    <FontAwesomeIcon
                                                        icon={
                                                            (fas,
                                                                getIcon(
                                                                    getStatus(
                                                                        language.publishedAt,
                                                                        language.deactivatedAt
                                                                    )
                                                                ))
                                                        }
                                                        className="ml-2"
                                                    ></FontAwesomeIcon>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            )}
            <ResponseModal
                show={successModalState.successModalDisplay}
                onHide={() => successModalClose()}
                modalheading={successModalHeading}
                modaltext={successModalState.successModalText}
            />
            <ResponseModal
                show={failureModalState.failureModalDisplay}
                onHide={() => failureModalClose()}
                modalheading={errorModalHeading}
                modaltext={failureModalState.failureModalText}
            />
            <ConfirmationModal
                show={confirmationModalIsOpen}
                modaltext={action}
                onHide={() => {
                    setConfirmationModalIsOpen(false);
                }}
                onConfirmationTrue={() => {
                    setConfirmationModalIsOpen(false);
                    performAction();
                }}
            />
        </Container>
    );
};

export default KeyboardLanguages;

import { retrieveFromLocalStorage } from "../utilities/localStorageUtil";
import { permissions } from "../utilities/commonUtil";
import { Config } from "./index.js";
import moment from "moment";
const allowedPermissions = retrieveFromLocalStorage("userPermissions");

// Stories
export const storyCreatePermission = () => {
  return allowedPermissions.includes(permissions.storyCreatePermission);
};

export const storyUpdatePermission = () => {
  return allowedPermissions.includes(permissions.storyUpdatePermission);
};

export const storyViewPermission = () => {
  return allowedPermissions.includes(permissions.storyViewPermission);
};

export const storyListPermission = () => {
  return allowedPermissions.includes(permissions.storyListPermission);
};

export const storyDeletePermission = () => {
  return allowedPermissions.includes(permissions.storyDeletePermission);
};

export const storyCTAUpdatePermission = () => {
  return allowedPermissions.includes(permissions.storyCTAUpdatePermission);
};

export const storyUserAttributePermission = () => {
  return allowedPermissions.includes(permissions.storyUserAttributePermission);
};

export const storyPublishPermission = () => {
  return allowedPermissions.includes(permissions.storyPublishPermission);
};

export const storyUnpublishPermission = () => {
  return allowedPermissions.includes(permissions.storyUnpublishPermission);
};

export const storyActivatePermission = () => {
  return allowedPermissions.includes(permissions.storyActivatePermission);
};

export const storyDeactivatePermission = () => {
  return allowedPermissions.includes(permissions.storyDeactivatePermission);
};

export const storiesTargetingRuleCreatePermission = () => {
  return allowedPermissions.includes(
    permissions.storiesTargetingRuleCreatePermission
  );
};

export const storiesTargetingRuleUpdatePermission = () => {
  return allowedPermissions.includes(
    permissions.storiesTargetingRuleUpdatePermission
  );
};

export const storyTargetingRuleViewPermission = () => {
  return allowedPermissions.includes(
    permissions.storiesTargetingRuleViewPermission
  );
};

export const storiesBulkCreatePermission = () => {
  return allowedPermissions.includes(permissions.storiesBulkCreatePermission);
};

export const storiesBulkUpdatePermission = () => {
  return allowedPermissions.includes(permissions.storiesBulkUpdatePermission);
};

// SOTD Section

export const sotdCreatePermission = () => {
  return allowedPermissions.includes(permissions.sotdCreatePermission);
};

export const sotdUpdatePermission = () => {
  return allowedPermissions.includes(permissions.sotdUpdatePermission);
};

export const sotdViewPermission = () => {
  return allowedPermissions.includes(permissions.sotdViewPermission);
};

export const sotdListPermission = () => {
  return allowedPermissions.includes(permissions.sotdListPermission);
};

export const sotdTargetingRuleViewPermission = () => {
  return allowedPermissions.includes(
    permissions.sotdTargetingRuleViewPermission
  );
};

// Bucket Section
export const storyBucketCreatePermission = () => {
  return allowedPermissions.includes(permissions.storyBucketCreatePermission);
};

export const storyBucketUpdatePermission = () => {
  return allowedPermissions.includes(permissions.storyBucketUpdatePermission);
};

export const storyBucketViewPermission = () => {
  return allowedPermissions.includes(permissions.storyBucketViewPermission);
};

export const storyBucketListPermission = () => {
  return allowedPermissions.includes(permissions.storyBucketListPermission);
};

export const storyBucketTargetingRuleCreatePermission = () => {
  return allowedPermissions.includes(
    permissions.storyBucketTargetingRuleCreatePermission
  );
};

export const storyBucketTargetingRuleUpdatePermission = () => {
  return allowedPermissions.includes(
    permissions.storyBucketTargetingRuleUpdatePermission
  );
};

export const storyBucketTargetingRuleViewPermission = () => {
  return allowedPermissions.includes(
    permissions.storyBucketTargetingRuleViewPermission
  );
};

export const storyBucketTargetingRuleListPermission = () => {
  return allowedPermissions.includes(
    permissions.storyBucketTargetingRuleListPermission
  );
};

// Other functionalities

export const sortByOptions = [
  {
    value: Config.IdDescending,
    label: "Id \u2193",
  },
  {
    value: Config.IdAscending,
    label: "Id \u2191",
  },
  {
    value: Config.PriorityDescending,
    label: "Priority \u2193",
  },
  {
    value: Config.ProirityAscending,
    label: "Priority \u2191",
  },
  {
    value: Config.PUBLISHED_AT_DESCENDING,
    label: "Published At \u2193",
  },
  {
    value: Config.PUBLISHED_AT_ASCENDING,
    label: "Published At \u2191",
  },
];

export const statusOptions = [
  { value: "", label: "All" },
  { value: "active", label: "Activated" },
  { value: "deactivated", label: "Deactivated" },
  { value: "unpublished", label: "Unpublished" },
];

export const actionStatus = [
  { value: "activate", label: "Activate" },
  { value: "deactivate", label: "Deactivate" },
  { value: "publish", label: "Publish" },
];

export const getdateValue = (date) => {
  if (date === null) {
    return null;
  }
  return moment.utc(date).format("YYYY-MM-DD");
};

export const getProcessingStatus = (status) => {
  if (status === "not_started" || status === "") {
    return "Not Started";
  } else if (status === "processing") {
    return "Ongoing";
  } else if (status === "completed") {
    return "Completed";
  }
};

export let getProcessingStatusColor = (state) => {
  if (state === "completed") return "success";
  if (state === "not_started") return "danger";
  if (state === "processing") return "warning";
};

export const truncateText = (text, maxWords) => {
  const words = text.split(" ");
  if (words.length > maxWords) {
    return words.slice(0, maxWords).join(" ") + "...";
  }
  return text;
};

export const truncateTextByChar = (text, maxCharacters) => {
  if (text.length > maxCharacters) {
    return text.slice(0, maxCharacters) + "...";
  }
  return text;
};

export let sotdConfig = {
  SUBMIT: "Submit",
  SAVE_TARGETING_RULES: "Save Targeting Rules",
};

export let bucketsConfig = {
  SUBMIT: "Submit",
  SAVE_TAGS: "Save Tags",
  SAVE_TARGETING_RULES: "Save Targeting Rules",
};
export const itemTypeOptions = [
  { label: "Story Of The Day", value: "storyOfTheDay" },
  { label: "Generic", value: "generic" },
  // ... more options
];

export const placementNativeTypeOptions = [
  { label: "shareStoryWhatsapp", value: "shareStoryWhatsapp" },
  { label: "openWebBrowser", value: "openWebBrowser" },
  { label: "shareStory", value: "shareStory" },
  { label: "downloadStory", value: "downloadStory" },
];
export const placementTypeOptions = [
  { label: "openDeeplink", value: "openDeeplink" },
];
export const redirectionTypeOptions = [
  { label: "Deeplink", value: "deeplink" },
  { label: "Native", value: "native" },
];

export const headGenderOptions = [
  { value: "", label: "Select Gender" },
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "unisex", label: "Unisex" },
];

import React, { useState } from "react";
import { retrieveFromLocalStorage } from "../../../../utilities/localStorageUtil";
import { Config } from "../../../../config";
import {
  redirectToNewTab,
  getStatus,
  getStatusColor,
  getBtnName,
  getButtonColor,
  permissions,
} from "../../../../utilities/commonUtil";
import {
  Badge,
  Card,
  Container,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";

export const QuickReplyCategoryCard = (props) => {
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );
  const [category] = useState(props.category);

  const showDetailsButton = () => {
    return allowedPermissions.includes(permissions.quickReplyCategoryDetail);
  };

  const showButton = (btnName) => {
    if (btnName === Config.ACTIVATE_BUTTON)
      return allowedPermissions.includes(
        permissions.quickReplyCategoryActivate
      );
    else if (btnName === Config.DEACTIVATE_BUTTON)
      return allowedPermissions.includes(
        permissions.quickReplyCategoryDeactivate
      );
    else if (btnName === Config.PUBLISH_BUTTON)
      return allowedPermissions.includes(
        permissions.quickReplyCategoryPublish
      );
    else return false;
  };

  let status = getStatus(category.publishedAt, category.deactivatedAt);

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h3" className="mb-0">
          {category.name}
          <Badge className="float-right" color={getStatusColor(status)}>
            {status}
          </Badge>
        </CardTitle>
        <small>ID : {category.ID}</small>
        <br />
          <small>View Type: {category.viewType}</small>
        <br />
        <strong className="font-weight-bold">{category.name}</strong>
        <br />
        <small className="font-weight-bold">
          Created At : {category.createdAt}
        </small>
        <br />
        <small className="font-weight-bold">
          Updated At : {category.updatedAt}
        </small>
        <br />
        <small className="font-weight-bold">
          Published At : {category.publishedAt}
        </small>
        <br />
        <small className="font-weight-bold">
          Deactivated At : {category.deactivatedAt}
        </small>
        <Container className=" my-4 d-flex justify-content-around" fluid>
          {showDetailsButton() ? (
            <Button
              className="mw-75 w-120 mx-2"
              color="info"
              onClick={() => {
                redirectToNewTab(
                  `/quick-reply/quick-reply-categories/${category.ID}`
                );
              }}
            >
              Details
            </Button>
          ) : null}
          {showButton(getBtnName(status)) ? (
            <Button
              className="mw-75 w-120 mx-2"
              color={getButtonColor(status)}
              onClick={() => {
                props.getConfirmation(category.ID, getBtnName(status));
              }}
            >
              {getBtnName(status)}
            </Button>
          ) : null}
        </Container>
      </CardBody>
    </Card>
  );
};

export default QuickReplyCategoryCard;

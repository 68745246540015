import { retrieveFromLocalStorage } from "../localStorageUtil";
import { host } from "./index";

// List all rewards
export const fetchRewards = async () => {
    const url = `${host}/v4/internal/rewards`; // Correct the URL to include "/rewards"
    const token = retrieveFromLocalStorage("token");

    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });

        if (response.ok) {
            return await response.json();
        } else {
            // Handle error cases here
            throw new Error(`Failed to fetch rewards (${response.status})`);
        }
    } catch (error) {
        // Handle network errors or other exceptions
        throw error;
    }
}
// Fetch a single reward by id
export const fetchSingleReward = async (id) => {
    const url = `${host}/v4/internal/rewards/${id}`;
    const token = retrieveFromLocalStorage("token");
  
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      });
  
      if (response.ok) {
        // If the response is OK (status 200), parse and return the reward data
        return await response.json();
      } else if (response.status === 404) {
        // If the response status is 404, it means the reward was not found
        throw new Error("Reward not found");
      } else {
        // Handle other error cases
        throw new Error(`Failed to fetch reward (${response.status})`);
      }
    } catch (error) {
      // Handle network errors or other exceptions
      throw error;
    }
  };

// Create a reward
export const createReward = async (newReward) => {
    const url = `${host}/v4/internal/rewards`; // Correct the URL to include "/rewards"
    const token = retrieveFromLocalStorage("token");

    try {
        const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify(newReward), // Pass the new reward data
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        });

        if (response.ok) {
            return await response.json();
        } else {
            // Handle error cases here
            throw new Error(`Failed to create reward (${response.status})`);
        }
    } catch (error) {
        // Handle network errors or other exceptions
        throw error;
    }
}

// Update a reward
export const updateReward = async (id, updatedReward) => {
    const url = `${host}/v4/internal/rewards/${id}`;
    const token = retrieveFromLocalStorage("token");

    try {
        const response = await fetch(url, {
            method: "PUT", // Use PUT method for updating
            body: JSON.stringify(updatedReward),
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        });

        if (response.ok) {
            return await response.json();
        } else {
            // Handle error cases here
            throw new Error(`Failed to update reward (${response.status})`);
        }
    } catch (error) {
        // Handle network errors or other exceptions
        throw error;
    }
}
// Deactivate a reward
export const deactivateReward = async (id) => {
  const url = `${host}/v4/internal/rewards/${id}/deactivate`;
  const token = retrieveFromLocalStorage("token");

  try {
    const response = await fetch(url, {
      method: "PUT", // Use PUT method for deactivation
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    if (response.ok) {
      return {
        message: "Reward Deactivated",
        status: "success",
      };
    } else {
      // Handle error cases here
      throw new Error(`Failed to deactivate reward (${response.status})`);
    }
  } catch (error) {
    // Handle network errors or other exceptions
    throw error;
  }
};


export const ActivateReward = async (id) => {
const url = `${host}/v4/internal/rewards/${id}/activate`;
const token = retrieveFromLocalStorage("token");

try {
    const response = await fetch(url, {
    method: "PUT", // Use PUT method for deactivation
    headers: {
        Authorization: "Bearer " + token,
    },
    });

    if (response.ok) {
    return await response.json();
    } else {
    // Handle error cases here
    throw new Error(`Failed to activate reward (${response.status})`);
    }
} catch (error) {
    // Handle network errors or other exceptions
    throw error;
}
};

//Publish a reward
export const publishReward = async (id) => {
    const url = `${host}/v4/internal/rewards/${id}/publish`;
    const token = retrieveFromLocalStorage("token");

    try {
        let response = await fetch(url, {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + token,
          },
        });
    
        return await response.json();
      } catch (error) {
        return;
      }
  };
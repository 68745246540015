import React, { useEffect, useState } from "react";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { permissions } from "../../../utilities/commonUtil";
import CreateQuickReplyPage from "./CreateQuickReplyPage";
import { getQuickReplyDetail } from "../../../utilities/apiUtils/quickReplies";

const QuickReplyDetail = (props) => {
  let id = props.match.params.id;
  const [quickReply, setQuickReply] = useState({});

  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  const updateAllowed = () => {
    return allowedPermissions.includes(permissions.quickReplyUpdate);
  };

  useEffect(() => {
    const getQuickReply = async () => {
      const response = await getQuickReplyDetail(id);
      if (response) {
        setQuickReply(response);
      }
    };
    getQuickReply();
  }
  , [id]);

  return (
    <CreateQuickReplyPage
      id={id}
      updateAllowed={updateAllowed()}
      formName={"edit"}
      quickReply={quickReply}
    ></CreateQuickReplyPage>
  );
};

export default QuickReplyDetail;

import React from "react";

const textFormatter = (cell) => {
    if (cell) {
        return cell.default
    }
}
export const taskDropdownOptionTableColumns = [
    {
        dataField: "identifier",
        text: "Identifier",
        searchable: true,
        style: {
            cursor: "pointer",
        },
    },
    {
        dataField: "displayName",
        text: "Display Name",
        searchable: true,
        style: {
            cursor: "pointer",
        },
        formatter: textFormatter,
    },
    {
        dataField: "priority",
        text: "Priority",
        searchable: true,
        style: {
            cursor: "pointer",
        },
    },
];

import React, { useState, useEffect } from "react";
import {
    Card,
    CardBody,
    Form,
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Container,
    Button,
    CustomInput,
} from "reactstrap";
import Select from "react-select";
import {
    error,
    errorDescription,
    redirectionOnTokenExpiry,
    redirectTo,
    validateJSON,
} from "../../../utilities/commonUtil";
import { validateCreateKeyboardLanguageLayoutForm } from "../../../utilities/keyboardLanguageUtil"
import {
    getKeyboardLayouts,
    updateLayoutForKeyboardLanguage,
    uploadLayoutForKeyboardLanguage,
} from "../../../utilities/apiUtils/keyboardLanguages";
import ResponseModal from "../ResponseModal";
import {
    layoutTypes,
    invalidShortcutsAlertElementId,
    invalidShortcutsAlertText,
    invalidSuggestionsAlertElementId,
    invalidSuggestionsAlertText,
    keyboardLanguageLayoutUpdatedText,
    keyboardLanguageLayoutUploadedText,
    successModalHeading,
    failureModalHeading,
} from "../../../config/keyboardLanguages";

const ManageLayouts = (props) => {
    const [formType, setFormType] = useState(props.location.state.formType);
    const [formHeading, setFormHeading] = useState("Create a new Layout");
    const [formTextState, setFormTextState] = useState({
        id: "",
        type: "",
        shortName: "",
        isPopular: false,
        fullName: "",
        description: "",
        minAppVersion: "",
        minBobbleSDKVersion: "",
        autoSelect: "",
        priority: "",
        keyboardLayoutId: "",
        defaultSuggestions: "",
        characterIdentifier: "",
    });
    const [selectedType, setSelectedType] = useState({});
    const [types, setTypes] = useState(layoutTypes);
    const [successModalState, setSuccessModalState] = useState({
        successModalDisplay: false,
        successModalText: "",
    });
    const [failureModalState, setFailureModalState] = useState({
        failureModalDisplay: false,
        failureModalText: "",
    });
    const [keyboardLanguageId, setKeyboardLanguageId] = useState("");
    const [keyboardLayoutIds, setKeyboardLayoutIds] = useState([]);
    const [selectedLayoutId, setSelectedLayoutId] = useState({});
    const [shortcuts, setShortcuts] = useState("");
    const [defaultSuggestions, setDefaultSuggestions] = useState("");
    const [previewImage, setPreviewImage] = useState("");
    const [previewImageName, setPreviewImageName] = useState("");
    const [previewImageURL, setPreviewImageURL] = useState("")
    const [darkModePreviewImage, setDarkModePreviewImage] = useState("");
    const [darkModePreviewImageName, setDarkModePreviewImageName] = useState("");
    const [darkModePreviewImageURL, setDarkModePreviewImageURL] = useState("")

    // This useEffect will be called for componentDidMount condition
    useEffect(() => {
        const getKeyboardLayoutsList = async () => {
            try {
                const response = await getKeyboardLayouts();
                if (response.errorCode) {
                    let error_code = errorDescription(response.errorCode);
                    showErrorModal(error_code);
                    if (
                        response.errorCode === error.tokenExpired ||
                        response.errorCode === error.invalidAccessToken
                    )
                        redirectionOnTokenExpiry();
                } else if (response.keyboardLayouts) {
                    let keyboardLayoutId = formTextState.keyboardLayoutId;
                    let keyboardLayoutIds = [];
                    let selectedLayoutId = {};

                    for (const element of response.keyboardLayouts) {
                        let layout = {};
                        layout["value"] = element.id;
                        layout["label"] =
                            element.identifier +
                            "-" +
                            element.id;
                        keyboardLayoutIds.push(layout);
                    }
                    if (formType === "edit") {
                        let keyboardLayoutId =
                            props.location.state.layout[0].keyboardLayoutId;
                        let selectedIdObj = response.keyboardLayouts.find((obj) => {
                            return obj.id === keyboardLayoutId;
                        });

                        selectedLayoutId["value"] = selectedIdObj.id;
                        selectedLayoutId["label"] =
                            selectedIdObj.identifier + "-" + selectedIdObj.id;
                        setKeyboardLayoutIds(keyboardLayoutIds);
                        setSelectedLayoutId(selectedLayoutId);
                        return;
                    }
                    setKeyboardLayoutIds(keyboardLayoutIds);
                }
            } catch (err) {
                showErrorModal(errorDescription(error.unexpectedError));
            }
        };

        document.getElementById("layoutId").required = true;
        getKeyboardLayoutsList();
        if (props.location.state.formType === "edit") {
            setFormTextState(props.location.state.layout[0]);
            setKeyboardLanguageId(props.location.state.keyboardLanguageId);
            setFormType(props.location.state.formType);
            setFormHeading("Edit Layout");
            setPreviewImageURL(props.location.state.layout[0].previewImageURL);
            setDarkModePreviewImageURL(props.location.state.layout[0].darkModePreviewImageURL);
            setShortcuts(
                props.location.state.layout.length > 0 &&
                    props.location.state.layout[0].shortcuts != null &&
                    props.location.state.layout[0].shortcuts.length > 0
                    ? JSON.stringify(props.location.state.layout[0].shortcuts)
                    : ""
            );
            setDefaultSuggestions(
                props.location.state.layout.length > 0 &&
                    props.location.state.layout[0].defaultSuggestions != null &&
                    props.location.state.layout[0].defaultSuggestions.length > 0
                    ? JSON.stringify(props.location.state.layout[0].defaultSuggestions)
                    : ""
            );
            setSelectedType({
                value: props.location.state.layout[0].type,
                label: props.location.state.layout[0].type,
            });
        } else {
            setKeyboardLanguageId(props.location.state.keyboardLanguageId);
        }
    }, [props]);

    const checkAutoSelect = () => {
        return formTextState.autoSelect !== "";
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormTextState((prev) => ({ ...prev, [name]: value }));
    };

    const handleIsPopularChange = (event) => {
        const checked = event.target.value;
        setFormTextState((prev) => ({ ...prev, isPopular: checked }));
    }

    const handleTypeChange = (option) => {
        setFormTextState((prev) => ({ ...prev, type: option.value }));
        setSelectedType(option);
    };

    const handleIdChange = (option) => {
        setSelectedLayoutId(option);
        setFormTextState((prev) => ({ ...prev, keyboardLayoutId: option.value }));
    };

    const showAlert = (elementId) => {
        document.getElementById(elementId).classList.remove("d-none");
    };

    const hideAlert = (elementId) => {
        document.getElementById(elementId).classList.add("d-none");
    };

    const handleShortcuts = (event) => {
        hideAlert(invalidShortcutsAlertElementId);
        const { value } = event.target;
        setShortcuts(value);
    };

    const handleDefaultSuggestions = (event) => {
        hideAlert(invalidSuggestionsAlertElementId);
        const { value } = event.target;
        setDefaultSuggestions(value);
    };

    const failureModalClose = () => {
        setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
    };

    const successModalClose = () => {
        setSuccessModalState((prev) => ({ ...prev, successModalDisplay: false }));
        redirectTo(`/kl/keyboard-languages/${keyboardLanguageId}/layouts`);
    };

    const showErrorModal = (errorCode) => {
        setFailureModalState({
            failureModalText: errorCode,
            failureModalDisplay: true,
        });
    };

    const showSuccessModal = (text) => {
        setSuccessModalState({
            successModalText: text,
            successModalDisplay: true,
        });
    };

    const handleResponse = (response) => {
        let text;
        let error_description;
        if (response.errorCode || response.statusCode >= 400) {
            if (response.errorCode) {
                if (
                    response.errorCode === error.tokenExpired ||
                    response.errorCode === error.invalidAccessToken
                )
                    redirectionOnTokenExpiry();
                error_description = errorDescription(response.errorCode) || response.errorDescription || "Unexpected Error";
            }
            error_description = response.errorDescription || "Unexpected Error";
            showErrorModal(error_description);
            return
        }

        if (!response.errorCode) {
            if (formType == "edit") {
                text = keyboardLanguageLayoutUpdatedText;
            } else {
                text = keyboardLanguageLayoutUploadedText;
            }
            setSuccessModalState({
                successModalDisplay: true,
                successModalText: text,
            });
            return;
        }
    };

    const handleFileChange = (event) => {
        const { name, files } = event.target;

        event.persist();
        // For showing Image preview
        if (files[0]) {
            if (name === "previewImage") {
                setPreviewImage(files[0]);
                setPreviewImageName(files[0].name);
                setPreviewImageURL(URL.createObjectURL(files[0]));
            } else if (name === "darkModePreviewImage") {
                setDarkModePreviewImage(files[0]);
                setDarkModePreviewImageName(files[0].name);
                setDarkModePreviewImageURL(URL.createObjectURL(files[0]));
            }
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        let layout = formTextState;
        if (!validateJSON(shortcuts)) {
            showAlert(invalidShortcutsAlertElementId);
        } else if (!validateJSON(defaultSuggestions)) {
            showAlert(invalidSuggestionsAlertElementId);
        } else {
            const updatedLayout = new FormData();
            updatedLayout.append("keyboardLayoutId", layout.keyboardLayoutId);
            updatedLayout.append("minAppVersion", layout.minAppVersion);
            updatedLayout.append("minBobbleSDKVersion", layout.minBobbleSDKVersion);
            updatedLayout.append("shortName", layout.shortName);
            updatedLayout.append("fullName", layout.fullName);
            updatedLayout.append("description", layout.description);
            updatedLayout.append("type", layout.type);
            if (layout.characterIdentifier !== "") {
                updatedLayout.append("characterIdentifier", layout.characterIdentifier);
            }

            if (previewImage && (previewImage != "" || previewImage !== null)) {
                updatedLayout.append("previewImage", previewImage);
            }
            if (darkModePreviewImage && (darkModePreviewImage != "" || darkModePreviewImage !== null)) {
                updatedLayout.append("darkModePreviewImage", darkModePreviewImage);
            }

            if (
                defaultSuggestions != null &&
                defaultSuggestions !== undefined &&
                defaultSuggestions.length > 0
            ) {
                let updatedDefaultSuggestions = JSON.parse(defaultSuggestions);
                updatedDefaultSuggestions.forEach((suggestions, index) => {
                    updatedLayout.append(`defaultSuggestions[${index}]`, suggestions);
                });
            }

            updatedLayout.append("autoSelect", layout.autoSelect === "true" ? 1 : 0);
            updatedLayout.append("isPopular", layout.isPopular);
            updatedLayout.append("priority", layout.priority);

            let validationResponse = validateCreateKeyboardLanguageLayoutForm(
                layout.autoSelect,
                layout.characterIdentifier,
                layout.type,
                layout.languageLayoutId
            );
            if (validationResponse === true) {
                if (formType === "edit") {
                    // Here layout.id = keyboard language layout id
                    let response = await updateLayoutForKeyboardLanguage(
                        updatedLayout,
                        keyboardLanguageId,
                        layout.id
                    );
                    handleResponse(response);
                    return;
                }
                let response = await uploadLayoutForKeyboardLanguage(
                    updatedLayout,
                    keyboardLanguageId
                );
                handleResponse(response);
            } else {
                showErrorModal(validationResponse);
            }
        }
    };

    return (
        <Container>
            <h3 className="mb-3">{formHeading}</h3>
            <Card>
                <CardBody>
                    <Form onSubmit={(event) => handleSubmit(event)}>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>
                                        FullName <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        type="text"
                                        name="fullName"
                                        placeholder="Enter layout fullName"
                                        onChange={(event) => handleChange(event)}
                                        value={formTextState.fullName}
                                        defaultValue={formTextState.fullName}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>
                                        Shortname <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        type="text"
                                        name="shortName"
                                        placeholder="Enter layout shortname"
                                        onChange={(event) => handleChange(event)}
                                        value={formTextState.shortName}
                                        defaultValue={formTextState.shortName}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <FormGroup>
                                        <Label>
                                            Type <span className="text-danger">*</span>
                                        </Label>
                                        <Select
                                            className="react-select-container dropdown"
                                            classNamePrefix="react-select"
                                            name="type"
                                            onChange={(option) => handleTypeChange(option)}
                                            value={selectedType}
                                            options={types}
                                            required
                                        />
                                    </FormGroup>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>
                                        Min App Version <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        type="number"
                                        name="minAppVersion"
                                        onChange={(event) => handleChange(event)}
                                        placeholder="Enter min app verision"
                                        value={formTextState.minAppVersion}
                                        defaultValue={formTextState.minAppVersion}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>
                                        Min Bobble SDK Version <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        type="number"
                                        name="minBobbleSDKVersion"
                                        onChange={(event) => handleChange(event)}
                                        placeholder="Enter min app verision"
                                        value={formTextState.minBobbleSDKVersion}
                                        defaultValue={formTextState.minAppVersion}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>
                                        Description <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        type="text"
                                        name="description"
                                        onChange={(event) => handleChange(event)}
                                        placeholder="Enter description"
                                        value={formTextState.description}
                                        defaultValue={formTextState.description}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Character Identifier</Label>
                                    <Input
                                        type="text"
                                        name="characterIdentifier"
                                        onChange={(event) => handleChange(event)}
                                        placeholder="Enter character identifier"
                                        value={
                                            formTextState !== undefined && formTextState != null
                                                ? formTextState.characterIdentifier
                                                : ""
                                        }
                                        defaultValue={formTextState.characterIdentifier}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>
                                        Priority <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        type="number"
                                        name="priority"
                                        onChange={(event) => handleChange(event)}
                                        placeholder="Enter priority"
                                        value={formTextState.priority}
                                        defaultValue={formTextState.priority}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>
                                        Select Language Layout ID{" "}
                                        <span className="text-danger">*</span>
                                    </Label>
                                    <Select
                                        className="react-select-container dropdown"
                                        classNamePrefix="react-select"
                                        name="layoutId"
                                        id="layoutId"
                                        onChange={(option) => handleIdChange(option)}
                                        value={selectedLayoutId}
                                        options={keyboardLayoutIds}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={7}>
                                <FormGroup className="row">
                                    <Label className="col-lg-5 col-sm-12">
                                        Auto Select <span className="text-danger">*</span>
                                    </Label>
                                    <CustomInput
                                        type="radio"
                                        id="autoSelectTrue"
                                        name="autoSelect"
                                        label="True"
                                        value={true}
                                        className="col-lg-2 col-sm-4 pl-5"
                                        onChange={(event) => handleChange(event)}
                                        defaultChecked={
                                            props.location.state && props.location.state.layout
                                                ? props.location.state.layout[0].autoSelect
                                                : false
                                        }
                                    />
                                    <CustomInput
                                        type="radio"
                                        id="autoSelectFalse"
                                        name="autoSelect"
                                        label="False"
                                        value={false}
                                        className="col-lg-2 col-sm-4 pl-5"
                                        onChange={(event) => handleChange(event)}
                                        defaultChecked={
                                            props.location.state && props.location.state.layout
                                                ? !props.location.state.layout[0].autoSelect
                                                : false
                                        }
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={7}>
                                <FormGroup className="row">
                                    <Label className="col-lg-5 col-sm-12">
                                        Is Popular <span className="text-danger">*</span>
                                    </Label>
                                    <CustomInput
                                        type="radio"
                                        id="isPopularTrue"
                                        name="isPopular"
                                        label="True"
                                        value={true}
                                        className="col-lg-2 col-sm-4 pl-5"
                                        onChange={handleIsPopularChange}
                                        defaultChecked={props.location.state && props.location.state.layout
                                            ? props.location.state.layout[0].isPopular
                                            : false}
                                    />
                                    <CustomInput
                                        type="radio"
                                        id="isPopularFalse"
                                        name="isPopular"
                                        label="False"
                                        value={false}
                                        className="col-lg-2 col-sm-4 pl-5"
                                        onChange={(event) => handleIsPopularChange(event)}
                                        defaultChecked={props.location.state && props.location.state.layout
                                            ? !props.location.state.layout[0].isPopular
                                            : false}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>
                                        <strong>
                                            Preview Image
                                            <small className="text-info">
                                                (Choose an image)
                                            </small>
                                        </strong>
                                    </Label>
                                    <Container className="custom-file">
                                        <Input
                                            type="file"
                                            className="custom-file-input"
                                            accept="image/*"
                                            name="previewImage"
                                            onChange={(event) => handleFileChange(event)}
                                            disabled={false}
                                        />
                                        <Label className="custom-file-label" id="previewImage">
                                            <strong> {previewImageName === null || previewImageName === ""
                                                ? "Choose an image"
                                                : "Image File: " + previewImageName}</strong>
                                        </Label>
                                    </Container>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                {previewImageURL && previewImageURL !== "" &&
                                    previewImageURL !== null ? (
                                    <Col className="pb-4">
                                        <img
                                            src={previewImageURL}
                                            height="120"
                                            alt="previewImage"
                                            width="120"
                                        />
                                    </Col>
                                ) : (
                                    <Col></Col>
                                )}
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>
                                        <strong>
                                            Dark Mode Preview Image
                                            <small className="text-info">
                                                (Choose an image)
                                            </small>
                                        </strong>
                                    </Label>
                                    <Container className="custom-file">
                                        <Input
                                            type="file"
                                            className="custom-file-input"
                                            accept="image/*"
                                            name="darkModePreviewImage"
                                            onChange={(event) => handleFileChange(event)}
                                            disabled={false}
                                        />
                                        <Label className="custom-file-label" id="darkModePreviewImage">
                                            <strong>{darkModePreviewImageName === null || darkModePreviewImageName === ""
                                                ? "Choose an image"
                                                : "Image File: " + darkModePreviewImageName}</strong>
                                        </Label>
                                    </Container>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                {darkModePreviewImageURL && darkModePreviewImageURL !== "" &&
                                    darkModePreviewImageURL !== null ? (
                                    <Col className="pb-4">
                                        <img
                                            src={darkModePreviewImageURL}
                                            height="120"
                                            alt="darkModePreviewImage"
                                            width="120"
                                        />
                                    </Col>
                                ) : (
                                    <Col></Col>
                                )}
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label>Default Suggestions</Label>
                                    <small
                                        id={invalidSuggestionsAlertElementId}
                                        className="text-danger d-none"
                                    >
                                        ({invalidSuggestionsAlertText})
                                    </small>
                                    <Input
                                        type="textarea"
                                        style={{ height: "6rem" }}
                                        name="defaultSuggestions"
                                        onChange={(event) => handleDefaultSuggestions(event)}
                                        placeholder="Enter default suggestions"
                                        value={defaultSuggestions}
                                        defaultValue={
                                            formType === "edit" ? defaultSuggestions : null
                                        }
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button color="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </CardBody>
            </Card>
            <ResponseModal
                show={successModalState.successModalDisplay}
                onHide={() => successModalClose()}
                modalheading={successModalHeading}
                modaltext={successModalState.successModalText}
            />
            <ResponseModal
                show={failureModalState.failureModalDisplay}
                onHide={() => failureModalClose()}
                modalheading={failureModalHeading}
                modaltext={failureModalState.failureModalText}
            />
        </Container>
    );
};

export default ManageLayouts;

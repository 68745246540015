import React, { Component } from "react";
import { Modal, Button, Row, Col, Alert } from "react-bootstrap";
import Reorder, { reorder } from "react-reorder";
import Select from "react-select";
import { capitalize } from "@material-ui/core";
import GifPackGifStatusTable from "./components/GifPackGifStatusTable";
import {
  getStatus,
  getStatusColor,
} from "../../../utilities/commonUtil";
import { Badge, Card, CardBody } from "reactstrap";
import ToolTip from "../../../utilities/ToolTip";

class GifStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gif: {},
      gifPackIds: [],
      globalStatus: false,
    };
  }

  componentWillReceiveProps() {
    if (this.props.gif != undefined && this.props.gif.gifPacks) {
      for (let i = 0; i < this.props.gif.gifPacks.length; i++) {
        const element = this.props.gif.gifPacks[i];
        this.props.gif.gifPacks[i].status = getStatus(element.publishedAt, element.deactivatedAt)
      }
    }
    this.props.gif.status = getStatus(this.props.gif.publishedAtGlobal, this.props.gif.deactivatedAtGlobal)
    this.setState({
      gif: this.props.gif,
    });
  }

  handleGifPackChange = (gifPack) => {
    this.setState({ gifPackId: gifPack.value });
  };

  handleGifPackSelection = (data, isSelected) => {
    if (isSelected) {
      this.setState({ gifPackIds: [...this.state.gifPackIds, data.id] });
    }
    else {
      this.setState({ gifPackIds: this.state.gifPackIds.filter(id => id !== data.id) });
    }
  }

  handleGlobalStatusSelection = (e) => {
    const isSelected = e.target.checked;
    this.setState({ globalStatus: isSelected });
  }

  handleSubmit = () => {
    this.props.updateGifStatus(this.state.gif.id, this.props.action, this.state.gifPackIds, this.state.globalStatus);
    this.setState({ gifPackIds: [], globalStatus: false, gif: {} });
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header className="bg-light" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {capitalize(this.props.action)} Gif
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <GifPackGifStatusTable
            gifPacks={this.state.gif?.gifPacks}
            action={capitalize(this.props.action)}
            onSelectRow={this.handleGifPackSelection}
          />
          <Card>
            <CardBody>
              <Row className="pb-2">
                <h4 className="px-2">Global Status</h4>
                <span className="px-2">
                  <ToolTip
                    data="Global Status controls the availability of gif in searches & recommendations."
                    id="gifGlobalStatus"
                  />
                </span>
                <Badge color={getStatusColor(this.state.gif?.status)}>{this.state.gif?.status}</Badge>
              </Row>
              <Row>
                <Col>
                  <input type="checkbox" class="selection-input-4" onChange={this.handleGlobalStatusSelection}></input>{"  "}
                  Update Global Status
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Modal.Footer>
            <Button onClick={this.handleSubmit}>Submit</Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    );
  }
}

export default GifStatus;

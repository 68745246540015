import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, CardHeader, CardBody, Button, FormGroup, Label, Input } from "reactstrap";
import { IoSearch } from "react-icons/io5";
import { deletePreferredCategories, fetchPreferredCategories, searchPreferredCategories, updatePreferredCategories } from '../../../utilities/apiUtils/smartSuggestions';
import Loading from "../../../components/Loading";
import ResponseModal from '../ResponseModal';
import { alertModalHeading, successModalHeading } from '../../../config';
import { errorModalHeading } from '../../../config/config';
import { error, errorDescription, redirectOnTokenExpiry } from '../../../utilities/commonUtil';
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { permissions } from "../../../utilities/commonUtil";
import { Oval } from "react-loader-spinner";
import ExampleDoc from './assets/ads_preferred_clusters.csv.gz'
import Search from '../../../components/Search';
import ConfirmationModal from '../ConfirmationModal';
import ToolTip from "../../../utilities/ToolTip";
import { preferredClustersSize,fileSizeExceedError } from '../../../config/configSmartSuggestions';

const SmartSuggestionsPreferredCategories = () => {
  const [loading, setLoading] = useState(true);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [altertModalText, setAlterModalText] = useState("");
  const [alertModalDisplay, setAlertModalDisplay] = useState(false);
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [preferredCategoriesFile, setPreferredCategoriesFile] = useState(null);
  const [preferredCategoriesFileName, setPreferredCategoriesFileName] = useState("");
  const [usersCount, setUsersCount] = useState(0);
  const [gaidLastUpdatedAt, setGaidLastUpdatedAt] = useState("");
  const [uploadRestrictSeconds, setUploadRestrictSeconds] = useState(0);

  const [searchValue, setSearchValue] = useState("")
  const [preferredCategories, setPreferredCategories] = useState([])

  let setConfirmationModalState = () => {
    setConfirmationModalIsOpen(true);
  };

  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );
  const updateAllowed = () => {
    return allowedPermissions.includes(permissions.smartSuggestionPreferredCategoriesEdit);
  };
  const deleteAllowed = () => {
    return allowedPermissions.includes(permissions.smartSuggestionPreferredCategoriesDelete);
  };
  const searchAllowed = () => {
    return allowedPermissions.includes(permissions.smartSuggestionPreferredCategoriesSearch);
  };

  useEffect(() => {
    getPreferredCategories()
  }, [])

  const getPreferredCategories = async () => {
    try {
      setLoading(true)
      let response = await fetchPreferredCategories()
      setGaidLastUpdatedAt(response.lastUpdatedAt ?? "")
      setUploadRestrictSeconds(response.uploadRestrictSeconds ?? 0)
      if (response?.count) {
        setUsersCount(response.count)
      } else {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken ||
          response.errorCode === error.unauthorized
        )
          redirectOnTokenExpiry();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false)
    }
  }

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };
  let showAlertModal = (text) => {
    setAlterModalText(text);
    setAlertModalDisplay(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    window.location.reload();
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };
  let alertModalClose = () => {
    setAlertModalDisplay(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true)

      // Check if response has lastUpdatedAt and compare with current time
      if (gaidLastUpdatedAt) {
        let lastUpdatedTime = new Date(gaidLastUpdatedAt);
        let currentTime = new Date();
        let timeDifference = (currentTime - lastUpdatedTime) / 1000;

        // If time restricted is greater than the time difference, then show alert modal and do not proceed
        if (timeDifference <= uploadRestrictSeconds) {
          let minutes = Number(Math.floor((uploadRestrictSeconds - timeDifference) / 60).toFixed(0))
          let seconds = Number(((uploadRestrictSeconds - timeDifference) % 60).toFixed(0))
          showAlertModal(`Clusters are being updated. Please try again after ${minutes ? minutes + " mins " : ""}${seconds} secs.`);
          setLoading(false);
          return;
        }
      }

      let reqBody = new FormData();
      reqBody.append("file", preferredCategoriesFile);

      let response = await updatePreferredCategories(reqBody)
      if (response.status === "success") {
        let text = "Request was successful. Preferred Clusters are being updated in background.";
        showSuccessModal(text);
      } else if (response.errorCode) {
        setFailureModalDisplay(true);
        setFailureModalText(response.errorDescription);
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    } finally {
      setLoading(false)
    }
  }

  const handleReset = async () => {
    try {
      setLoading(true)
      let response = await deletePreferredCategories()
      if (response.status === "success") {
        let text = "Preferred Clusters have been reset in background.";
        showSuccessModal(text);
      } else if (response.errorCode) {
        setFailureModalDisplay(true);
        setFailureModalText(errorDescription(response.errorCode));
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    } finally {
      setLoading(false)
    }
  }

  const handleSearch = async () => {
    try {
      setLoading(true)
      let response = await searchPreferredCategories(searchValue)
      if (response?.length > 0) setPreferredCategories(response)
      else setPreferredCategories([])
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container fluid className="p-0">
      <Row>
        <h1 className="mb-4 mt-2 w-50 pl-3">Manage Preferred Clusters</h1>
      </Row>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Card>
            <CardBody>
              <div>
                <p className="font-weight-bolder mb-2" style={{ fontSize: "14px" }}>Current Number of Users: <strong>{usersCount}</strong></p>
              </div>
              <hr
                style={{
                  color: "black",
                }}
              />
              <div>
                <Row>
                  <Col md={12} xs={12}>
                    <FormGroup className="d-flex flex-column">
                      <Label>
                        <strong>
                          Preferred Clusters List <small className="text-info">(Format: .csv.gz)</small> 
                          <ToolTip style={{  marginLeft: "4rem" }}
                            data={
                              `Formats: .csv.gz, Size: max 175MB"
                              }`
                            }
                            id="preferredClusterTooltip"
                          />
                          <p style={{ fontSize: 12, marginBottom: 0 }}>
                            <a href={ExampleDoc} download="ads_preferred_clusters.csv.gz">
                              Sample File
                            </a>
                          </p>
                        </strong>
                      </Label>
                      <Container className="custom-file" style={{ width: 300, marginLeft: "inherit" }}>
                        <Input
                          type="file"
                          className="custom-file-input"
                          name="deviceIds"
                          id="deviceIds"
                          placeholder="Preferred Clusters"
                          accept=".gz"
                          disabled={!updateAllowed}
                          onChange={(event) => {
                            const file = event.target.files[0];
                            if (file && ((file.size > preferredClustersSize)) ) {    
                              let text = fileSizeExceedError;
                              setFailureModalDisplay(true);
                              setFailureModalText(text);
                            }else if (file?.name?.endsWith(".csv.gz")) {
                              setPreferredCategoriesFile(file);
                              setPreferredCategoriesFileName(file.name);
                            } else {
                              setFailureModalDisplay(true);
                              setFailureModalText("Please upload file in accepted format (.csv.gz)");
                            }
                          }}
                        />
                        <Label className="custom-file-label">
                          <strong>{preferredCategoriesFileName || "Choose a file"}</strong>
                        </Label>
                      </Container>
                    </FormGroup>
                  </Col>
                </Row>
                <div className='d-flex justify-content-between flex-wrap'>
                  {updateAllowed ? (<div className="d-flex">
                    {!loading ? <div className="p-0">
                      <Button onClick={handleSubmit} disabled={preferredCategoriesFile == null}>Upload</Button>
                    </div> : <Oval
                      height={80}
                      width={80}
                      color="#4fa94d"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={loading}
                      ariaLabel='oval-loading'
                      secondaryColor="#4fa94d"
                      strokeWidth={2}
                      strokeWidthSecondary={2}

                    />}
                  </div>) : (<div></div>)}
                  {
                    deleteAllowed() ? (
                      <Button color="danger" className="mt-2" onClick={setConfirmationModalState} disabled={usersCount === 0}>Reset</Button>
                    ) : <></>
                  }
                </div>
              </div>
            </CardBody>
          </Card>
          {searchAllowed() ? (
            <Card>
              <CardHeader>
                <h4 className="font-weight-bolder mb-2">Search Preferred Clusters</h4>
              </CardHeader>
              <CardBody className="pt-0">
                <Row>
                  <Col md={6} className="d-flex align-items-center">
                    <Search
                      placeholder="Search Google Advertising ID"
                      value={searchValue}
                      onChange={(e) => {
                        setSearchValue(e.target.value)
                      }}
                    ></Search>
                    <Button
                      className="mb-4 ml-2"
                      onClick={handleSearch}
                      disabled={!searchValue}
                    >
                      <IoSearch size={17} />
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    {(preferredCategories?.length > 0) ? (
                      <ul>
                        {
                          preferredCategories?.map((category, index) => (
                            <li key={index}>{category}</li>
                          ))
                        }
                      </ul>
                    ) : <></>
                    }
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ) : <></>
          }
        </div>
      )}
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={"Reset"}
        onConfirmationTrue={() => {
          setConfirmationModalIsOpen(false);
          handleReset();
        }}
        onHide={() => setConfirmationModalIsOpen(false)}
      />
      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={successModalHeading}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={errorModalHeading}
        modaltext={failureModalText}
      />
      <ResponseModal
        show={alertModalDisplay}
        onHide={alertModalClose}
        modalheading={alertModalHeading}
        modaltext={altertModalText}
      />
    </Container>
  )
}

export default SmartSuggestionsPreferredCategories
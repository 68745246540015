import React, { useState, useEffect } from "react";

import {
    Card,
    CardBody,
    Container,
    Button,
    Col,
    Form,
    FormGroup,
    Label,
    Row,
    Input,
} from "reactstrap";

import Select, { components } from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { columns } from "./versionsColumns";
import { BsToggleOn, BsToggleOff } from "react-icons/bs";
import IconButton from "@material-ui/core/IconButton";


import {
    redirectTo,
    errorDescription,
    isOperationPermitted,
    permissions,
    processDateTime,
    redirectOnTokenExpiry,
    error,
} from "../../../../utilities/commonUtil";

import {
    createUserSegment,
    fetchUserSegment,
    editUserSegment,
    fetchUserSegmentFiles,
} from '../../../../utilities/apiUtils/pushNotifications';

import Routes from "../../../../routes/index";
import { retrieveFromLocalStorage } from "../../../../utilities/localStorageUtil";
import { typeOptions } from "../../../../config/ConfigPushNotifications";

import ConfirmationModal from "../../ConfirmationModal";
import ResponseModal from "../../ResponseModal";

const ManageUserSegment = () => {
    const [userSegment, setUserSegment] = useState({
        name: null,
        description: null,
        type: null,
        changeLog: null,
        filename: null,
    });
    const [changeType, setChangeType] = useState("Add");
    const [action, setAction] = useState("");
    const [fileOptions, setFileOptions] = useState({});
    const [successModalDisplay, setSuccessModalDisplay] = useState(false);
    const [successModalText, setSuccessModalText] = useState("");
    const [failureModalDisplay, setFailureModalDisplay] = useState(false);
    const [failureModalText, setFailureModalText] = useState("");
    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
    const [allowedPermissions, setAllowedPermissions] = useState(
        retrieveFromLocalStorage("userPermissions")
    );
    const [versions, setVersions] = useState([]);
    const [toggle, setToggle] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            let id = window.location.pathname.split("/").pop();
            const response = await fetchUserSegment(id);
            if (
                response.errorCode === error.tokenExpired ||
                response.errorCode === error.invalidAccessToken
            )
                redirectOnTokenExpiry();
            if (response?.errorCode) {
                showErrorModal(errorDescription(response?.errorCode));
            } else {
                setUserSegment({
                    name: response?.userSegment?.name,
                    description: response?.userSegment?.description,
                    type: typeOptions.filter(
                        (option) => option.value === response?.userSegment?.type
                    )[0],
                });
                // convert date time to local date time
                response.userSegment.versions = processDateTime(response.userSegment.versions);

                setVersions(response?.userSegment?.versions);
            }
        };

        const fetchFiles = async () => {
            const response = await fetchUserSegmentFiles();
            if (response?.errorCode) {
                showErrorModal(errorDescription(response?.errorCode));
            } else {
                setFileOptions(response?.userSegmentFiles);
            }
        };
        fetchFiles();
        if (window.location.pathname.includes("edit")) {
            fetchData();
            setChangeType("Edit");
        } else if (window.location.pathname.includes("view")) {
            fetchData();
            setChangeType("View");
        }
    }, []);

    const handleSubmit = async () => {
        let payload = {
            name: userSegment.name,
            description: userSegment.description,
            type: userSegment.type.value,
            changeLog: userSegment.changeLog,
            filename: userSegment?.filename?.value,
        };

        if (changeType === "Edit") {
            let id = window.location.pathname.split("/").pop();
            let response = await editUserSegment(id, payload);
            if (response?.errorCode || response === undefined) {
                showErrorModal(response?.errorDescription || errorDescription(response?.errorCode));
            } else {
                setSuccessModalText("User Segment updated successfully");
                setSuccessModalDisplay(true);
            }
            return;
        };

        let response = await createUserSegment(payload);
        if (response?.errorCode || response === undefined) {
            showErrorModal(response?.errorDescription || errorDescription(response?.errorCode));
        } else {
            setSuccessModalText("User Segment created successfully");
            setSuccessModalDisplay(true);
        }
    };


    const requiredComponent = (props) => {
        let required = props.selectProps.required;
        return (
            <components.Input {...props} required={required} />
        );
    };

    let setConfirmationModalState = (event) => {
        event.preventDefault();
        setAction("Submit");
        setConfirmationModalIsOpen(true);
    };

    let showErrorModal = (text) => {
        setFailureModalText(text);
        setFailureModalDisplay(true);
    };

    let successModalClose = async () => {
        setSuccessModalDisplay(false);
        redirectTo(Routes.pushNotification.children.userSegments.path);
    };

    let failureModalClose = () => {
        setFailureModalDisplay(false);
    };

    let disabled = () => {
        if (changeType === "Add") return false;

        return !isOperationPermitted(
            allowedPermissions,
            permissions.userSegmentEdit
        );
    };

    let fileDropdown = () => {
        let options = [];

        if(userSegment?.type?.value === "default") {
            options = fileOptions?.default.map((file) => {
                return { label: file, value: file };
            });
        }

        if(userSegment?.type?.value === "custom") {
            options = fileOptions?.custom.map((file) => {
                return { label: file, value: file };
            });
        }

        return options;
    }


    return (
        <Container fluid className="p-0">
            <Row className="mb-3">
                <Col md={6} xs={6}>
                    <h1>{changeType} User Segment</h1>
                </Col>
            </Row>
            <Form onSubmit={(event) => setConfirmationModalState(event)}>
                <Card>
                    <CardBody>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}
                                    >
                                        Name <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        type="text"
                                        name="name"
                                        id="name"
                                        placeholder="Name"
                                        value={userSegment.name}
                                        onChange={(event) => {
                                            setUserSegment({
                                                ...userSegment,
                                                name: event.target.value,
                                            });
                                        }}
                                        required
                                        disabled={disabled()}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}
                                    >
                                        Type <span className="text-danger">*</span>
                                    </Label>
                                    <Select
                                        className="react-select-container mb-4"
                                        classNamePrefix="react-select"
                                        style={{
                                            fontSize: "0.875rem",
                                        }}
                                        name="type"
                                        options={typeOptions.filter(
                                            (option) => option.value !== ""
                                        )}
                                        value={userSegment.type}
                                        onChange={(value) => {
                                            setUserSegment({
                                                ...userSegment,
                                                type: value,
                                                filename: null,
                                            });
                                        }}
                                        components={{
                                            Input: requiredComponent,
                                        }}
                                        isDisabled={disabled() || changeType === "Edit"}
                                        required={userSegment.type === null}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}
                                    >
                                        Description <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        type="textarea"
                                        name="description"
                                        id="description"
                                        style={{ height: "100px" }}
                                        placeholder="Description"
                                        value={userSegment.description}
                                        onChange={(event) => {
                                            setUserSegment({
                                                ...userSegment,
                                                description: event.target.value,
                                            });
                                        }}
                                        required
                                        disabled={disabled()}
                                    />
                                </FormGroup>
                            </Col>

                            {changeType === "Edit"  && (
                                <Col md={12}>
                                    <FormGroup >
                                        <Label
                                            className="font-weight-bolder mb-2"
                                            style={{ fontSize: "16px" }}>
                                            Add User Segment
                                        </Label>
                                        <IconButton
                                            onClick={() => {
                                                setToggle(!toggle);
                                            }}
                                            disabled={disabled()}
                                        >
                                            {toggle ? (
                                                <BsToggleOn size={30} />
                                            ) : (
                                                <BsToggleOff size={30} />
                                            )}
                                        </IconButton>
                                    </FormGroup>
                                </Col>
                            )}


                            {(changeType === "Add" || toggle) && (
                                <>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label
                                                className="font-weight-bolder mb-2"
                                                style={{ fontSize: "16px" }}
                                            >
                                                File Name <span className="text-danger">*</span>
                                            </Label>
                                            <Select
                                                className="react-select-container mb-4"
                                                classNamePrefix="react-select"
                                                name="filename"
                                                options={fileDropdown()}
                                                value={userSegment.filename}
                                                onChange={(value) => {
                                                    setUserSegment({
                                                        ...userSegment,
                                                        filename: value,
                                                    });
                                                }}
                                                components={{
                                                    Input: requiredComponent,
                                                }}
                                                isDisabled={disabled()}
                                                required={userSegment.filename === null}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label
                                                className="font-weight-bolder mb-2"
                                                style={{ fontSize: "16px" }}
                                            >
                                                Change Log <span className="text-danger">*</span>
                                            </Label>
                                            <Input
                                                type="text"
                                                name="changeLog"
                                                id="changeLog"
                                                // style={{ height: "100px" }}
                                                placeholder="Enter Change Log"
                                                value={userSegment.changeLog}
                                                onChange={(event) => {
                                                    setUserSegment({
                                                        ...userSegment,
                                                        changeLog: event.target.value,
                                                    });
                                                }}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                </>
                            )}



                        </Row>
                    </CardBody>
                </Card>

                {(changeType === "Edit" || changeType === "View") && (
                    <Row>
                        <Col md={6}>
                            <h1 className="h3 mb-3">Versions</h1>
                        </Col>
                        <Card style={{ overflow: "hidden" }}>
                            <div container-fullwidth="true" style={{ margin: "2%" }}>
                                <Row
                                    className="mx-auto"
                                    style={{ display: "flex", justifyContent: "right" }}
                                ></Row>
                                <Row>
                                    <CardBody>
                                        <ToolkitProvider
                                            keyField="id"
                                            data={versions}
                                            columns={columns}
                                            search
                                        >
                                            {(props) => (
                                                <div>
                                                    <BootstrapTable
                                                        bootstrap4={true}
                                                        wrapperClasses="table-responsive"
                                                        hover={true}
                                                        bordered={false}
                                                        keyField="id"
                                                        data={versions}
                                                        columns={columns}
                                                        pagination={paginationFactory({
                                                            sizePerPage: 10,
                                                            sizePerPageList: [5, 10, 25, 50],
                                                        })}
                                                        remote={{ filter: true }}
                                                        noDataIndication="No results found"
                                                    />
                                                </div>
                                            )}
                                        </ToolkitProvider>
                                    </CardBody>
                                </Row>
                            </div>
                        </Card>
                    </Row>
                )}



                {!(disabled()) && (
                    <Button
                        type="submit"
                        className="float-left"
                        color="primary"
                        disabled={disabled()}
                    >
                        Submit
                    </Button>
                )}
            </Form>

            <ConfirmationModal
                show={confirmationModalIsOpen}
                modaltext={action}
                onConfirmationTrue={() => {
                    setConfirmationModalIsOpen(false);
                    if (action === "Submit") handleSubmit();
                }}
                onHide={() => setConfirmationModalIsOpen(false)}
            />

            <ResponseModal
                show={successModalDisplay}
                onHide={successModalClose}
                modalheading={"Success"}
                modaltext={successModalText}
            />
            <ResponseModal
                show={failureModalDisplay}
                onHide={failureModalClose}
                modalheading={"Error"}
                modaltext={failureModalText}
            />
        </Container>
    )
};

export default ManageUserSegment;
import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { FaPlus, FaMinus } from "react-icons/fa";
import CreatableSelect from 'react-select/creatable';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row, CustomInput, Badge, CardHeader } from "reactstrap";
import { ColorPicker } from "antd";
import { IoCloseCircleOutline, IoSearch, IoSync, IoAddCircleOutline } from "react-icons/io5";
import { suggestionTypeOptions, trackerTypeOptions, dateError, smartSuggestionResponses, imageFormatError, packageNameOptions, showEditSmartSuggestionButton, validateMacros, unsupportedMacroError, staticIconSize, fileSizeExceedError, clickTrackerMissingError, storyAdCtaItemTypeOptions, shareUrlPlaceholder, formatValue, storyAdLinkError, smartSuggestionsAllActions, invalidAspectRatioError, storyCTASharePackageNameOptions, maxKeywords, categoryOptions, startAtSuffix, endAtSuffix, convertUTCToIST, parseDateInMoment, parseHTMLToURL } from "../../../config/configSmartSuggestions";
import ConfirmationModal from "../../../pages/ui-elements/ConfirmationModal";
import ResponseModal from "../../../pages/ui-elements/ResponseModal";
import Routes from "../../../routes/index";
import { error, errorDescription, redirectOnTokenExpiry, redirectTo, truncateDecimals } from "../../../utilities/commonUtil";
import moment from "moment";
import { createSmartSuggestion, fetchAdTypes, fetchClientAppPackages, fetchSmartSuggestionById, fetchSmartSuggestionCategories, fetchStory, updateSmartSuggestionById, getPartnerNames } from "../../../utilities/apiUtils/smartSuggestions";
import ToolTip from "../../../utilities/ToolTip";
import { getBrandCampaigns } from "../../../utilities/apiUtils/brandCampaign";
import SupportedMacros from "./SupportedMacros";
import { fetchCreativeTypes } from "../../../utilities/apiUtils/configAPI";
import Loading from "../../../components/Loading";
import { storyAdImageAssetAspectRatioRange, storyAdImageAssetSize, storyAdVideoAssetAspectRatioRange, storyAdVideoAssetSize } from "../../../utilities/apiUtils/index";
import SmartSuggestionDetails from "./SmartSuggestionDetails";

const storyAdAssetSettingsInitialState = {
  videoAsset: null,
  videoAssetSrc: "",
  videoAssetFilename: "",
  videoAssetAspectRatio: null,
  imageAsset: null,
  imageAssetSrc: "",
  imageAssetFilename: "",
  imageAssetAspectRatio: null,
}

const storyAdCtaItemInitalState = {
  type: null,
  text: "",
  textColor: "#000000",
  backgroundColor: "#ffffff",
  icon: null,
  iconUrl: "",
  iconFilename: "",
  iconAspectRatio: null,
  clickUrl: "",
  deeplink: "",
  shareText: "",
  shareUrl: "",
  sharePackageName: null,
}

const customAppDetailsInitialState = {
  appPackage: null,
  actionType: "browser",
  isDynamic: false,
  deeplink: null,
  clickUrl: null,
  redirectionUrl: null,
  trackers: []
}

const whitelistedDurationInitialState = {
  startTime: "",
  endTime: ""
}


const ManageSmartSuggestions = (props) => {
  const [loading, setLoading] = useState(false)
  const [storyAdsLoading, setStoryAdsLoading] = useState(false)
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");
  const [changeType, setChangeType] = useState("Add");
  const [confirmAction, setConfirmAction] = useState("");
  const [isPartnerNameOther, setIsPartnerNameOther] = useState(false)
  const [partnerName, setPartnerName] = useState("")
  const [isAutoSendChatbotSettingsEnable, setIsAutoSendChatbotSettingsEnable] = useState(false)
  const [isSupportedMacrosModalVisible, setIsSupportedMacrosModalVisible] = useState(false)
  const [adCreativeTypeOptions, setAdCreativeTypeOptions] = useState([])
  const [adCreativeTypeId, setAdCreativeTypeId] = useState("")
  const [storyDetails, setStoryDetails] = useState(null)
  const [showStoryDetails, setShowStoryDetails] = useState(false)
  const [revenueModelOptions, setRevenueModelOptions] = useState([])
  const [revenueModel, setRevenueModel] = useState("")
  const [categoriesOptions, setCategoriesOptions] = useState([])
  const [brandCampaignsOptions, setBrandCampaignsOptions] = useState([])
  const [brandCampaignId, setBrandCampaignId] = useState("")
  const [packagesOptions, setPackagesOptions] = useState([])
  const [newSmartSuggestionId, setNewSmartSuggestionId] = useState("")
  const [isAffinityAffiliateAd, setIsAffinityAffiliateAd] = useState(false)
  const [valuesBeforeUpdate, setValuesBeforeUpdate] = useState(null)
  const [macroErrors, setMacroErrors] = useState({
    deeplink: "",
    clickUrl: "",
    redirectionUrl: ""
  })

  const [customAppMacroErrors, setCustomAppMacroErrors] = useState([]);

  const [details, setDetails] = useState({
    title: "",
    titleLong: "",
    subTitle: "",
    description: "",
    type: suggestionTypeOptions[0].target.value,
    category: null,
    partnerName: null,
    packageName: "",
    revenueModel: null,
    unitPrice: "",
    domainName: "",
    keywords: null,
    typingStatePriority: "",
    serverAdProviderId: null,
    adCreativeTypeId: null,
    storyAdId: null,
    customAppPackages: [],
  });

  const [creativeUIDetails, setCreativeUIDetails] = useState({
    uiTilesDetails: { isDisabled: false },
    uiDetailsCardDetails: { isDisabled: false },
    uiProductsCardDetails: { isDisabled: false },
    uiProductsCardTwoDetails: { isDisabled: true },
    uiProductsCardThreeDetails: { isDisabled: true }
  })
  const [detailsCardFieldsEdit, setDetailsCardFieldsEdit] = useState({
    title: false,
    subTitle: false
  })

  const [campaignSettings, setCampaignSettings] = useState({
    endDate: null,
    startDate: null,
    dailyConversionLimit: "",
    brandCampaign: null
  });

  const [whitelistedDuration, setWhitelistedDuration] = useState([])

  const [creatives, setCreatives] = useState({
    staticIcon: null,
    animatedIcon: null,
    providerIcon: null,
    staticIconSrc: "",
    animatedIconSrc: "",
    providerIconSrc: "",
    staticIconFilename: "",
    animatedIconFilename: "",
    providerIconFilename: "",
    staticIconAspectRatio: null,
    animatedIconAspectRatio: null,
    providerIconAspectRatio: null,
  })

  const [action, setAction] = useState({
    actionType: "browser",
    isDynamic: false,
    deeplink: "",
    clickUrl: "",
    redirectionUrl: ""
  })

  const [customAppDetails, setCustomAppDetails] = useState([])
  const [customAppPackagesRes, setCustomAppPackagesRes] = useState([])

  const [visibilitySettings, setVisibilitySettings] = useState({
    presentInTypingState: true,
    presentInNonTypingState: true,
    allowDuplication: false,
    isPromoted: false,
    intentTriggers: [],
    showAd: false,
    showAdOnlyIfAppIsInstalled: false,
    relatedCategories: [],
    blacklistedPackages: []
  })

  const [allBlacklistedPackages, setAllBlacklistedPackages] = useState([])

  const [chatbotSettings, setChatbotSettings] = useState({
    packageNames: packageNameOptions,
    messageText: "",
    triggerTimeout: "10000"
  })

  const [storyAdAssetSettings, setStoryAdAssetSettings] = useState(storyAdAssetSettingsInitialState)
  const [storyAdCtaItems, setStoryAdCtaItems] = useState([])

  const [trackers, setTrackers] = useState([]);
  const [trackerUrlMacroError, setTrackerUrlMacroError] = useState([])

  const [keywords, setKeywords] = useState({
    whitelistExact: null,
    whitelistAutocomplete: null,
    blacklistExact: null,
    blacklistAutocomplete: null
  });
  const [partnersNameOptions, setPartnersNameOptions] = useState([])

  useEffect(() => {
    if (details.title?.includes("__KWD__")
      || details.titleLong?.includes("__KWD__")
      || action.clickUrl?.includes("__KWD__")
      || creativeUIDetails.uiTilesDetails?.title?.includes("__KWD__")
      || creativeUIDetails.uiDetailsCardDetails?.title?.includes("__KWD__")
      || creativeUIDetails.uiProductsCardDetails?.title?.includes("__KWD__")
      || creativeUIDetails.uiProductsCardTwoDetails?.title?.includes("__KWD__")
    ) setIsAffinityAffiliateAd(true)
    else setIsAffinityAffiliateAd(false)
  }, [details.title, details.titleLong, action.clickUrl, creativeUIDetails.uiTilesDetails, creativeUIDetails.uiDetailsCardDetails, creativeUIDetails.uiProductsCardDetails, creativeUIDetails.uiProductsCardTwoDetails])

  useEffect(() => {
    getCategories()
    fetchBrandCampaigns()
    fetchPartnerName()
    getAdCreativeTypes()
    getAdTypes()
    getClientAppPackages()
  }, [])

  useEffect(() => {
    if (allBlacklistedPackages?.length > 0 && packagesOptions?.length > 0) {
      setVisibilitySettings((prevState) => ({
        ...prevState,
        blacklistedPackages: allBlacklistedPackages.map(pkg => ({
          label: packagesOptions.find(option => option.value === pkg)?.label ?? pkg,
          value: pkg
        }))
      }))
    }
  }, [allBlacklistedPackages, packagesOptions])

  useEffect(() => {
    if (brandCampaignId && brandCampaignsOptions?.length > 0) {
      setCampaignSettings((prevState) => ({
        ...prevState,
        brandCampaign: brandCampaignsOptions.find(option => option.value === Number(brandCampaignId))?.target?.value ?? brandCampaignId
      }))
      setValuesBeforeUpdate((prevState) => ({
        ...prevState,
        brandCampaignName: brandCampaignsOptions.find(option => option.value === Number(brandCampaignId))?.label ?? brandCampaignId
      }))
    }
  }, [brandCampaignId, brandCampaignsOptions])

  useEffect(() => {
    if (revenueModel && revenueModelOptions?.length > 0) {
      setDetails((prevState) => ({
        ...prevState,
        revenueModel: {
          label: revenueModelOptions.find(option => option.value === revenueModel)?.label ?? revenueModel,
          value: revenueModel,
        }
      }))
    }
  }, [revenueModel, revenueModelOptions])

  useEffect(() => {
    if (adCreativeTypeId && adCreativeTypeOptions?.length > 0) {
      setDetails((prevState) => ({
        ...prevState,
        adCreativeTypeId: {
          label: adCreativeTypeOptions.find(option => option.value === adCreativeTypeId)?.label ?? adCreativeTypeId,
          value: adCreativeTypeId,
        }
      }))
      setValuesBeforeUpdate((prevState) => ({
        ...prevState,
        adCreativeTypeName: adCreativeTypeOptions.find(option => option.value === adCreativeTypeId)?.label ?? adCreativeTypeId
      }))
    }
  }, [adCreativeTypeId, adCreativeTypeOptions])

  useEffect(() => {
    setCustomAppDetails(customAppPackagesRes?.map(item => ({
      appPackage: {
        label: packagesOptions.find(option => option.value === item.appPackage)?.label ?? item.appPackage,
        value: item.appPackage,
      },
      actionType: "browser",
      isDynamic: item.isDynamic,
      deeplink: item.deeplink || "",
      clickUrl: item.clickURL || "",
      redirectionUrl: item.redirectionURL || "",
      trackers: (item.trackers || []).map(tracker => ({
        type: {
          label: trackerTypeOptions.find(option => option.value === tracker.type)?.label ?? tracker.type,
          value: tracker.type,
        },
        url: tracker.url || ""
      }))
    })) || [])
    const newErrors = customAppPackagesRes?.map((item) => ({
      deeplink: "",
      clickUrl: "",
      redirectionUrl: "",
      trackers: []
    }));
    setCustomAppMacroErrors(newErrors);
  }, [customAppPackagesRes, packagesOptions])

  useEffect(() => {
    let partnerNameObj = null
    if (partnersNameOptions.some(partner => partner.value === partnerName)) {
      // partner name exists in pre-defined options
      partnerNameObj = {
        label: partnersNameOptions.find(partner => partner.value === partnerName)?.label ?? partnerName,
        value: partnerName
      }
      setIsPartnerNameOther(false)
    } else if (partnerName.toLowerCase === "other") {
      setIsPartnerNameOther(true)
    }
    setDetails((prevState) => ({
      ...prevState,
      partnerName: partnerNameObj,
    }));
  }, [partnerName, partnersNameOptions])

  let getdateValue = (date) => {
    if (date === null) {
      return null;
    }
    return moment(date).format("YYYY-MM-DD");

  }

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let setConfirmationModalState = (event, action) => {
    event.preventDefault();
    setConfirmAction(action)
    setConfirmationModalIsOpen(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);

    // if edit - redirect to home
    if (props.match.params.id && !props.match.path?.includes("duplicate")) {
      redirectTo(Routes.ads.children.smartSuggestions.path);
    } else if (props.match.path?.includes("duplicate")) {
      // if duplicate - redirect to targeting rules with duplicate query
      redirectTo(`/ads/smart-suggestions/targeting-rules/${newSmartSuggestionId}?duplicate=${props.match.params.id}`)
    } else {
      // create new ad - redirect to targeting rules
      redirectTo(`/ads/smart-suggestions/targeting-rules/${newSmartSuggestionId}`)
    }
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  const handleDetailsChange = (event) => {
    const { name, value } = event.target;
    // If story ad id is changed then reset story ad details
    if (name === "storyAdId" && storyDetails) {
      setStoryDetails(null)
    }
    setDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCampaignSettingsChange = (event) => {
    const { name, value } = event.target;
    setCampaignSettings((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleActionSettingsChange = (event) => {
    const { name, value } = event.target;
    setAction((prevState) => ({
      ...prevState,
      [name]: parseHTMLToURL(value),
    }));
  }

  const handleVisiblitySettingsChange = (event) => {
    const { id, checked } = event.target;
    setVisibilitySettings((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
  };

  const handleChatBotSettingsChange = (event) => {
    const { name, value } = event.target;
    setChatbotSettings((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleStoryAdAssetFileChange = (event, type) => {
    const { name, files, accept } = event.target;
    const acceptedFormat = accept.split(",").map(format => format.trim())
    event.persist();
    // For showing Image preview
    if (files[0]) {
      // Check for size
      if ((type === "image" && (files[0].size > storyAdImageAssetSize)) || (type === "video" && (files[0].size > storyAdVideoAssetSize))) {
        let text = fileSizeExceedError;
        showErrorModal(text);
        setStoryAdAssetSettings((prev) => ({
          ...prev,
          [name]: null,
          [name + "Src"]: "",
          [name + "AspectRatio"]: null,
          [name + "Filename"]: "",
        }));
        return
      }
      // Check if format is accepted
      if (!acceptedFormat.includes(files[0].type)) {
        let text = imageFormatError;
        showErrorModal(text);
        setStoryAdAssetSettings((prev) => ({
          ...prev,
          [name]: null,
          [name + "Src"]: "",
          [name + "AspectRatio"]: null,
          [name + "Filename"]: "",
        }));
        return
      }

      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        if (type === "image") {
          const image = new Image();
          image.src = reader.result;
          image.onload = () => {
            // Check if image is square when ad type IS NOT "product"
            let width = image.width
            let height = image.height
            // Check for aspect ratio
            if (storyAdImageAssetAspectRatioRange?.length === 2) {
              let aspectRatio = truncateDecimals(width / height, 3)
              if (aspectRatio < storyAdImageAssetAspectRatioRange[0] || aspectRatio > storyAdImageAssetAspectRatioRange[1]) {
                let text = invalidAspectRatioError;
                showErrorModal(text);
                setStoryAdAssetSettings((prev) => ({
                  ...prev,
                  [name]: null,
                  [name + "Src"]: "",
                  [name + "AspectRatio"]: null,
                  [name + "Filename"]: "",
                }));
                return
              }
            }

            setStoryAdAssetSettings((prev) => ({
              ...prev,
              [name]: files[0],
              [name + "Src"]: reader.result,
              [name + "AspectRatio"]: Number(width / height).toFixed(3),
              [name + "Filename"]: files[0]?.name,
            }));
          }
        } else if (type === "video") {
          const video = document.createElement('video');
          video.src = reader.result;
          video.onloadedmetadata = () => {
            let width = video.videoWidth;
            let height = video.videoHeight;
            // Check for aspect ratio
            if (storyAdVideoAssetAspectRatioRange?.length === 2) {
              let aspectRatio = truncateDecimals(width / height, 3)
              if (aspectRatio < storyAdVideoAssetAspectRatioRange[0] || aspectRatio > storyAdVideoAssetAspectRatioRange[1]) {
                let text = invalidAspectRatioError;
                showErrorModal(text);
                setStoryAdAssetSettings((prev) => ({
                  ...prev,
                  [name]: null,
                  [name + "Src"]: "",
                  [name + "AspectRatio"]: null,
                  [name + "Filename"]: "",
                }));
                return
              }
            }

            setStoryAdAssetSettings((prev) => ({
              ...prev,
              [name]: files[0],
              [name + "Src"]: reader.result,
              [name + "AspectRatio"]: Number(width / height).toFixed(3),
              [name + "Filename"]: files[0]?.name,
            }));
          };
        }
      };
    } else {
      setStoryAdAssetSettings((prev) => ({
        ...prev,
        [name]: null,
        [name + "Src"]: "",
        [name + "AspectRatio"]: null,
        [name + "Filename"]: "",
      }));
    }
  };

  const handleResetStoryAdAsset = (name) => {
    switch (name) {
      case "video":
        setStoryAdAssetSettings((prevState) => ({
          ...prevState,
          videoAssetSrc: "",
          videoAssetFilename: "",
          videoAssetAspectRatio: null,
        }))
        break;
      case "image":
        setStoryAdAssetSettings((prevState) => ({
          ...prevState,
          imageAssetSrc: "",
          imageAssetFilename: "",
          imageAssetAspectRatio: null,
        }))
        break;
      default:
        break;
    }
  }

  const handleResetStoryAdCtaItemFile = (index) => {
    const temp = [...storyAdCtaItems]
    temp[index]["iconUrl"] = ""
    temp[index]["iconFilename"] = ""
    temp[index]["iconAspectRatio"] = null
    setStoryAdCtaItems(temp)
  }

  const handleStoryAdCtaItemsChange = (event, index) => {
    const { name, value } = event.target
    const temp = [...storyAdCtaItems]
    temp[index][name] = value
    setStoryAdCtaItems(temp)
  }

  const handleStoryAdCtaItemFileChange = (event, type, index) => {
    const { name, files, accept } = event.target;
    const acceptedFormat = accept.split(",").map(format => format.trim())
    event.persist();
    // For showing Image preview
    if (files[0]) {
      // Check for size
      if (type === "image" && (files[0].size > staticIconSize)) {
        let text = fileSizeExceedError;
        showErrorModal(text);
        const temp = [...storyAdCtaItems]
        temp[index][name] = null
        temp[index][name + "Url"] = ""
        temp[index][name + "AspectRatio"] = null
        temp[index][name + "Filename"] = ""
        setStoryAdCtaItems(temp)
        return
      }
      // Check if format is accepted
      if (!acceptedFormat.includes(files[0].type)) {
        let text = imageFormatError;
        showErrorModal(text);
        const temp = [...storyAdCtaItems]
        temp[index][name] = null
        temp[index][name + "Url"] = ""
        temp[index][name + "AspectRatio"] = null
        temp[index][name + "Filename"] = ""
        setStoryAdCtaItems(temp)
        return
      }

      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          // Check if image is square when ad type IS NOT "product"
          let width = image.width
          let height = image.height
          console.log({ width, height });
          // Check for aspect ratio

          const temp = [...storyAdCtaItems]
          temp[index][name] = files[0]
          temp[index][name + "Url"] = reader.result
          temp[index][name + "AspectRatio"] = Number(image.width / image.height).toFixed(3)
          temp[index][name + "Filename"] = files[0]?.name
          setStoryAdCtaItems(temp)
        }
      };
    } else {
      const temp = [...storyAdCtaItems]
      temp[index][name] = null
      temp[index][name + "Url"] = ""
      temp[index][name + "AspectRatio"] = null
      temp[index][name + "Filename"] = ""
      setStoryAdCtaItems(temp)
    }
  };

  const handleStoryAdCtaItemColorChange = (color, name, index) => {
    const temp = [...storyAdCtaItems]
    temp[index][name] = color
    setStoryAdCtaItems(temp)
  }

  const addStoryAdCtaItem = () => {
    setStoryAdCtaItems((prevState) => (
      prevState.concat({ ...storyAdCtaItemInitalState })
    ));
  }

  const removeStoryAdCtaItem = (index) => {
    setStoryAdCtaItems((prevState) => (
      prevState.filter((_, i) => i !== index)
    ));
  }

  const addCustomApp = () => {
    setCustomAppDetails(customAppDetails.concat({ ...customAppDetailsInitialState }))
    setCustomAppMacroErrors((prevState) => [
      ...prevState,
      {
        deeplink: "",
        clickUrl: "",
        redirectionUrl: "",
        trackers: []
      }
    ]);
  }

  const removeAllCustomApps = () => {
    setCustomAppDetails([])
    setCustomAppMacroErrors([])
  }

  const removeCustomApp = (index) => (e) => {
    e.preventDefault();
    setCustomAppDetails((prevDetails) => prevDetails.filter((_, currentIndex) => currentIndex !== index));
    setCustomAppMacroErrors((prevState) => prevState.filter((_, currentIndex) => currentIndex !== index));
  };

  const handleCustomAppChange = (event, index) => {
    const { name, value } = event.target;
    const temp = [...customAppDetails];
    temp[index][name] = parseHTMLToURL(value);
    setCustomAppDetails(temp);
  }

  const validateCustomAppFieldMacros = (field, value, customAppIndex) => {
    const msg = validateMacros(value);
    setCustomAppMacroErrors((prevState) =>
      prevState.map((errorObj, idx) => idx === customAppIndex ? { ...errorObj, [field]: msg } : errorObj)
    );
  };

  const addCustomAppAdTracker = (index) => {
    setCustomAppDetails((prevState) => {
      const updatedDetails = [...prevState];
      updatedDetails[index] = {
        ...updatedDetails[index],
        trackers: updatedDetails[index].trackers.concat({
          type: "",
          url: ""
        }),
      };
      return updatedDetails;
    });

    setCustomAppMacroErrors((prevState) => {
      const updatedErrors = [...prevState];
      updatedErrors[index] = {
        ...updatedErrors[index],
        trackers: [...updatedErrors[index].trackers, ""],
      };
      return updatedErrors;
    });
  };

  const removeCustomAppAdTracker = (index, trackerIndex) => {
    setCustomAppDetails((prevState) =>
      prevState.map((item, ind) =>
        ind === index
          ? {
            ...item,
            trackers: item.trackers.filter((_, i) => i !== trackerIndex),
          }
          : item
      )
    );

    setCustomAppMacroErrors((prevState) =>
      prevState.map((errorObj, idx) =>
        idx === index
          ? {
            ...errorObj,
            trackers: errorObj.trackers.filter((_, i) => i !== trackerIndex),
          }
          : errorObj
      )
    );
  };

  const removeAllCustomAppAdTracker = (index) => {
    setCustomAppDetails((prevState) =>
      prevState.map((detail, idx) =>
        index === idx
          ? {
            ...detail,
            trackers: [],
          }
          : detail
      )
    );

    setCustomAppMacroErrors((prevState) => {
      const updatedErrors = [...prevState];
      updatedErrors[index].trackers = [];
      return updatedErrors;
    });
  };

  const handleCustomAppTrackerChange = (event, trackerIndex, customAppIndex) => {
    let { name, value } = event.target;
    if (name === "url") {
      value = parseHTMLToURL(value);
    }
    setCustomAppDetails(prevDetails => {
      const updatedDetails = [...prevDetails];
      const customApp = updatedDetails[customAppIndex];
      const updatedTrackers = customApp.trackers.map((tracker, index) => {
        if (index === trackerIndex) {
          return {
            ...tracker,
            [name]: value,
          };
        }
        return tracker;
      });

      updatedDetails[customAppIndex] = {
        ...customApp,
        trackers: updatedTrackers,
      };

      return updatedDetails;
    });
  };

  const removeAllStoryAdCtaItems = () => {
    setStoryAdCtaItems([]);
  }

  const handleWhitelistedDurationChange = (event, index) => {
    const { name, value } = event.target
    const temp = [...whitelistedDuration]
    temp[index][name] = value
    setWhitelistedDuration(temp)
  }

  const addWhitelistedDuration = () => {
    setWhitelistedDuration((prevState) => (
      prevState.concat({ ...whitelistedDurationInitialState })
    ));
  }

  const removeWhitelistedDuration = (index) => {
    setWhitelistedDuration((prevState) => (
      prevState.filter((_, i) => i !== index)
    ));
  }

  const removeAllWhitelistedDurations = () => {
    setWhitelistedDuration([]);
  }

  const handleTrackerChange = (event, index) => {
    let { name, value } = event.target;
    let temp = [...trackers];

    // If field is 'URL' then parse HTML string to actual URL
    if (name === "url") {
      value = parseHTMLToURL(value);
    }

    temp[index][name] = value;
    setTrackers(temp);
  };

  const showSupportedMacrosModal = () => {
    setIsSupportedMacrosModalVisible(true)
  }

  const hideSupportedMacrosModal = () => {
    setIsSupportedMacrosModalVisible(false)
  }

  useEffect(() => {
    // check path params
    if (props.match.params.id) {
      setChangeType("Edit");
      fetchSmartSuggestionData(props.match.params.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.id]);

  const getClientAppPackages = async () => {
    try {
      let response = await fetchClientAppPackages()
      const options = []
      if (response?.length > 0) {
        response.forEach(pkg => {
          options.push({
            label: pkg.name,
            value: pkg.value
          })
        })
      }
      setPackagesOptions(options)
    } catch (err) {
      console.log(err);
    }
  }

  const getCategories = async () => {
    try {
      let response = await fetchSmartSuggestionCategories()
      const options = []
      if (response?.categories?.length > 0) {
        response.categories.forEach(category => {
          options.push({
            label: formatCategoryName(category.name),
            value: category.id
          })
        })
      }
      setCategoriesOptions(options)
    } catch (err) {
      console.log(err);
    }
  }

  const fetchBrandCampaigns = async () => {
    try {
      let response = await getBrandCampaigns()
      const options = []
      options.push({
        value: "none",
        label: "None",
        target: {
          name: "brandCampaign",
          value: {
            value: "none",
            label: "None"
          }
        }
      })
      if (response?.length > 0) {
        response.forEach(option => {
          options.push({
            value: option.value,
            label: option.label,
            target: {
              name: "brandCampaign",
              value: {
                value: option.value,
                label: option.label
              }
            }
          })
        })
      } else {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken ||
          response.errorCode === error.unauthorized
        )
          redirectOnTokenExpiry();
      }
      setBrandCampaignsOptions(options)
    } catch (err) {
      console.log(err);
    }
  }

  const fetchPartnerName = async () => {
    try {
      let response = await getPartnerNames();
      const options = [];
      if (response?.length > 0) {
        response.forEach(option => {
          options.push({
            value: option.value,
            label: option.label,
            target: {
              name: "partnerName",
              value: {
                value: option.value,
                label: option.label
              }
            }
          });
        });
      } else {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken ||
          response.errorCode === error.unauthorized
        )
          redirectOnTokenExpiry();
      }
      setPartnersNameOptions(options);
    } catch (err) {
      console.log(err);
    }
  }

  const getAdCreativeTypes = async () => {
    try {
      let response = await fetchCreativeTypes()
      const options = []
      if (response?.length > 0) {
        response.forEach(option => {
          options.push({
            value: option.id,
            label: option.name,
            target: {
              name: "adCreativeTypeId",
              value: {
                value: option.id,
                label: option.name
              }
            }
          })
        })
      } else {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken ||
          response.errorCode === error.unauthorized
        )
          redirectOnTokenExpiry();
      }
      setAdCreativeTypeOptions(options)
    } catch (err) {
      console.log(err);
    }
  }

  const getAdTypes = async () => {
    try {
      let response = await fetchAdTypes()
      const options = []
      if (response?.length > 0) {
        response.forEach(option => {
          options.push({
            value: option.name,
            label: option.name?.replace(/_/g, ' ').toUpperCase(),
            target: {
              name: "revenueModel",
              value: {
                value: option.name,
                label: option.name?.replace(/_/g, ' ').toUpperCase()
              }
            }
          })
        })
      } else {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken ||
          response.errorCode === error.unauthorized
        )
          redirectOnTokenExpiry();
      }
      setRevenueModelOptions(options)
    } catch (err) {
      console.log(err);
    }
  }

  const handleSubmit = async () => {
    if (campaignSettings.startDate > campaignSettings.endDate) {
      let text = dateError;
      showErrorModal(text);
      return;
    }

    const isTrackerUrlMacroError = trackerUrlMacroError.some(error => error?.length > 0)
    if (macroErrors.clickUrl || macroErrors.deeplink || macroErrors.redirectionUrl || isTrackerUrlMacroError) {
      let text = unsupportedMacroError;
      showErrorModal(text);
      return;
    }

    const isCustomAppTrackerUrlMacroError = customAppMacroErrors.some(
      (errorObj) => errorObj.trackers.some((error) => error?.length > 0)
    );

    const isCustomAppMacroError = customAppMacroErrors.some(
      (errorObj) =>
        errorObj.clickUrl ||
        errorObj.deeplink ||
        errorObj.redirectionUrl ||
        isCustomAppTrackerUrlMacroError
    );

    if (isCustomAppMacroError) {
      let text = unsupportedMacroError;
      showErrorModal(text);
      return;
    }

    const isClickTracker = trackers.some(tracker => tracker.type?.value === "click")
    if (action.redirectionUrl && !isClickTracker) {
      let text = clickTrackerMissingError;
      showErrorModal(text);
      return;
    }

    const hasRedirectionWithoutClickTracker = customAppDetails.some(
      (appDetail) => appDetail.redirectionUrl && !appDetail.trackers.some((tracker) => tracker.type?.value === "click")
    );

    if (hasRedirectionWithoutClickTracker) {
      let text = clickTrackerMissingError;
      showErrorModal(text);
      return;
    }

    if (Number(details.storyAdId) && !storyDetails?.id) {
      let text = storyAdLinkError;
      showErrorModal(text);
      return;
    }

    const relatedCategories = visibilitySettings.relatedCategories && visibilitySettings.relatedCategories.length > 0 ? visibilitySettings.relatedCategories?.map(rc => { return { id: rc.value, name: formatCategoryName(rc.label) } }) : null
    const intentIds = visibilitySettings.intentTriggers?.map(intent => intent.value)
    const intentIdsList = visibilitySettings.intentTriggers?.map(intent => intent.label).join(",")

    const trackersPayload = trackers.map(tracker => ({
      type: tracker.type?.value ?? "",
      url: tracker.url
    })).filter(tracker => tracker.type)

    let cbSettings = {
      packageNames: [],
      messageText: "",
      triggerTimeout: null
    }

    if (details.type?.value === "chatbot" && isAutoSendChatbotSettingsEnable) {
      const packageNames = chatbotSettings.packageNames?.map(el => el.value) ?? []
      cbSettings = {
        packageNames: packageNames,
        messageText: chatbotSettings.messageText,
        triggerTimeout: Number(chatbotSettings.triggerTimeout)
      }
    }

    let staticIconFilename = "", animatedIconFilename = "", providerIconFilename = "", staticIconSrc = "", animatedIconSrc = "", providerIconSrc = "";
    if (creatives.staticIconSrc) {
      staticIconFilename = creatives.staticIconSrc.startsWith("data:") ? "" : creatives.staticIconFilename
      staticIconSrc = creatives.staticIconSrc.startsWith("data:") ? creatives.staticIconSrc : ""
    }
    if (creatives.animatedIconSrc) {
      animatedIconFilename = creatives.animatedIconSrc.startsWith("data:") ? "" : creatives.animatedIconFilename
      animatedIconSrc = creatives.animatedIconSrc.startsWith("data:") ? creatives.animatedIconSrc : ""
    }
    if (creatives.providerIconSrc) {
      providerIconFilename = creatives.providerIconSrc.startsWith("data:") ? "" : creatives.providerIconFilename
      providerIconSrc = creatives.providerIconSrc.startsWith("data:") ? creatives.providerIconSrc : ""
    }

    let isDynamic = action.isDynamic
    if (!(details.title?.includes("__KWD__") || action.clickUrl?.includes("__KWD__"))) isDynamic = false

    // Creative UI details
    let uiTilesDetails = null, uiDetailsCardDetails = null, uiProductsCardDetails = null, uiProductsCardTwoDetails = null, uiProductsCardThreeDetails = null

    // Set tiles UI details
    uiTilesDetails = { isDisabled: !!creativeUIDetails.uiTilesDetails?.isDisabled }
    if (creativeUIDetails.uiTilesDetails?.title)
      uiTilesDetails = {
        ...uiTilesDetails,
        title: creativeUIDetails.uiTilesDetails?.title
      }
    if (creativeUIDetails.uiTilesDetails?.titleLong)
      uiTilesDetails = {
        ...uiTilesDetails,
        titleLong: creativeUIDetails.uiTilesDetails?.titleLong
      }
    if (creativeUIDetails.uiTilesDetails?.subTitle)
      uiTilesDetails = {
        ...uiTilesDetails,
        subTitle: creativeUIDetails.uiTilesDetails?.subTitle
      }
    if (creativeUIDetails.uiTilesDetails?.iconURL)
      uiTilesDetails = {
        ...uiTilesDetails,
        iconFilename: creativeUIDetails?.uiTilesDetails?.iconURL.startsWith("data:") ? "" : creativeUIDetails.uiTilesDetails.iconFilename,
        iconURL: creativeUIDetails?.uiTilesDetails?.iconURL.startsWith("data:") ? creativeUIDetails?.uiTilesDetails?.iconURL : "",
        iconAspectRatio: Number(creativeUIDetails.uiTilesDetails.iconAspectRatio) || 0
      }

    // Set details card UI details
    uiDetailsCardDetails = { isDisabled: !!creativeUIDetails.uiDetailsCardDetails?.isDisabled }
    if (creativeUIDetails.uiDetailsCardDetails?.title)
      uiDetailsCardDetails = {
        ...uiDetailsCardDetails,
        title: creativeUIDetails.uiDetailsCardDetails?.title
      }
    if (creativeUIDetails.uiDetailsCardDetails?.subTitle)
      uiDetailsCardDetails = {
        ...uiDetailsCardDetails,
        subTitle: creativeUIDetails.uiDetailsCardDetails?.subTitle
      }
    if (creativeUIDetails.uiDetailsCardDetails?.iconURL)
      uiDetailsCardDetails = {
        ...uiDetailsCardDetails,
        iconFilename: creativeUIDetails?.uiDetailsCardDetails?.iconURL.startsWith("data:") ? "" : creativeUIDetails.uiDetailsCardDetails.iconFilename,
        iconURL: creativeUIDetails?.uiDetailsCardDetails?.iconURL.startsWith("data:") ? creativeUIDetails?.uiDetailsCardDetails?.iconURL : "",
        iconAspectRatio: Number(creativeUIDetails.uiDetailsCardDetails.iconAspectRatio) || 0
      }

    // Set products card UI details
    uiProductsCardDetails = { isDisabled: !!creativeUIDetails.uiProductsCardDetails?.isDisabled }
    if (creativeUIDetails.uiProductsCardDetails?.title)
      uiProductsCardDetails = {
        ...uiProductsCardDetails,
        title: creativeUIDetails.uiProductsCardDetails?.title
      }
    if (creativeUIDetails.uiProductsCardDetails?.subTitle)
      uiProductsCardDetails = {
        ...uiProductsCardDetails,
        subTitle: creativeUIDetails.uiProductsCardDetails?.subTitle
      }
    if (creativeUIDetails.uiProductsCardDetails?.description)
      uiProductsCardDetails = {
        ...uiProductsCardDetails,
        description: creativeUIDetails.uiProductsCardDetails?.description
      }
    if (creativeUIDetails.uiProductsCardDetails?.iconURL)
      uiProductsCardDetails = {
        ...uiProductsCardDetails,
        iconFilename: creativeUIDetails?.uiProductsCardDetails?.iconURL.startsWith("data:") ? "" : creativeUIDetails.uiProductsCardDetails.iconFilename,
        iconURL: creativeUIDetails?.uiProductsCardDetails?.iconURL.startsWith("data:") ? creativeUIDetails?.uiProductsCardDetails?.iconURL : "",
        iconAspectRatio: Number(creativeUIDetails.uiProductsCardDetails.iconAspectRatio) || 0
      }

    // Set products card two UI details
    uiProductsCardTwoDetails = { isDisabled: !!creativeUIDetails.uiProductsCardTwoDetails?.isDisabled }
    if (creativeUIDetails.uiProductsCardTwoDetails?.title)
      uiProductsCardTwoDetails = {
        ...uiProductsCardTwoDetails,
        title: creativeUIDetails.uiProductsCardTwoDetails?.title
      }
    if (creativeUIDetails.uiProductsCardTwoDetails?.subTitle)
      uiProductsCardTwoDetails = {
        ...uiProductsCardTwoDetails,
        subTitle: creativeUIDetails.uiProductsCardTwoDetails?.subTitle
      }
    if (creativeUIDetails.uiProductsCardTwoDetails?.description)
      uiProductsCardTwoDetails = {
        ...uiProductsCardTwoDetails,
        description: creativeUIDetails.uiProductsCardTwoDetails?.description
      }
    if (creativeUIDetails.uiProductsCardTwoDetails?.iconURL)
      uiProductsCardTwoDetails = {
        ...uiProductsCardTwoDetails,
        iconFilename: creativeUIDetails?.uiProductsCardTwoDetails?.iconURL.startsWith("data:") ? "" : creativeUIDetails.uiProductsCardTwoDetails.iconFilename,
        iconURL: creativeUIDetails?.uiProductsCardTwoDetails?.iconURL.startsWith("data:") ? creativeUIDetails?.uiProductsCardTwoDetails?.iconURL : "",
        iconAspectRatio: Number(creativeUIDetails.uiProductsCardTwoDetails.iconAspectRatio) || 0
      }

    // Set products card three UI details
    uiProductsCardThreeDetails = { isDisabled: !!creativeUIDetails.uiProductsCardThreeDetails?.isDisabled }
    if (creativeUIDetails.uiProductsCardThreeDetails?.iconURL)
      uiProductsCardThreeDetails = {
        ...uiProductsCardThreeDetails,
        iconFilename: creativeUIDetails?.uiProductsCardThreeDetails?.iconURL.startsWith("data:") ? "" : creativeUIDetails.uiProductsCardThreeDetails.iconFilename,
        iconURL: creativeUIDetails?.uiProductsCardThreeDetails?.iconURL.startsWith("data:") ? creativeUIDetails?.uiProductsCardThreeDetails?.iconURL : "",
        iconAspectRatio: Number(creativeUIDetails.uiProductsCardThreeDetails.iconAspectRatio) || 0
      }

    // Story ad assets payload
    let videoAssetFilename = "", imageAssetFilename = "", videoAssetSrc = "", imageAssetSrc = ""
    if (storyAdAssetSettings.videoAssetSrc) {
      videoAssetFilename = storyAdAssetSettings.videoAssetSrc.startsWith("data:") ? "" : storyAdAssetSettings.videoAssetFilename
      videoAssetSrc = storyAdAssetSettings.videoAssetSrc.startsWith("data:") ? storyAdAssetSettings.videoAssetSrc : ""
    }
    if (storyAdAssetSettings.imageAssetSrc) {
      imageAssetFilename = storyAdAssetSettings.imageAssetSrc.startsWith("data:") ? "" : storyAdAssetSettings.imageAssetFilename
      imageAssetSrc = storyAdAssetSettings.imageAssetSrc.startsWith("data:") ? storyAdAssetSettings.imageAssetSrc : ""
    }

    // Story ad cta items
    let storyAdCtaItemsPayload = JSON.parse(JSON.stringify(storyAdCtaItems))
    storyAdCtaItemsPayload.forEach((ctaItem, index) => {
      ctaItem.type = ctaItem.type.value ?? ""
      ctaItem.sharePackageName = ctaItem.sharePackageName?.value ?? ""
      let iconFilename = "", iconUrl = ""
      if (ctaItem.iconUrl) {
        iconFilename = ctaItem.iconUrl.startsWith("data:") ? "" : ctaItem.iconFilename
        iconUrl = ctaItem.iconUrl.startsWith("data:") ? ctaItem.iconUrl : ""
      }
      ctaItem.iconUrl = iconUrl
      ctaItem.iconAspectRatio = Number(ctaItem.iconAspectRatio) || null
      ctaItem.iconFilename = iconFilename

      if (ctaItem.type === "click") {
        ctaItem.shareText = ""
        ctaItem.shareUrl = ""
        ctaItem.sharePackageName = ""
      } else if (ctaItem.type === "share") {
        ctaItem.clickUrl = ""
        ctaItem.deeplink = ""
      }
    })

    const customAppDetailsPayload = customAppDetails?.map(item => ({
      appPackage: item.appPackage.value,
      actionType: item.actionType,
      isDynamic: item.isDynamic,
      deeplink: item.deeplink || null,
      clickUrl: item.clickUrl || null,
      redirectionUrl: item.redirectionUrl || null,
      trackers: item.trackers.map(tracker => ({
        type: tracker.type.value ?? "",
        url: tracker.url
      }))
    }));

    let payload = {
      title: creativeUIDetails.uiTilesDetails?.title ?? details.title ?? "",
      longTitle: creativeUIDetails.uiDetailsCardDetails?.title ?? (creativeUIDetails.uiProductsCardDetails?.title || details.titleLong || details.title),
      subTitle: creativeUIDetails.uiDetailsCardDetails?.subTitle ?? (creativeUIDetails.uiProductsCardDetails?.subTitle || details.subTitle),
      description: creativeUIDetails.uiProductsCardDetails?.description ?? details.description,
      type: details.type?.value ?? "",
      category: details.category?.value ?? "",
      partnerName: details.partnerName?.value,
      packageName: details.packageName ?? "",
      revenueModel: details.revenueModel?.value ?? "",
      unitPrice: details.revenueModel?.value === "cpi" ? Number(details.unitPrice) ?? null : null,
      domainName: details.revenueModel?.value === "cpi" ? null : details.domainName ?? null,
      typingStatePriority: Number(details.typingStatePriority) ?? 1,
      serverAdProviderId: details.serverAdProviderId?.value ?? null,
      serverAdProviderName: details.serverAdProviderId?.label ?? null,
      adCreativeTypeId: details.adCreativeTypeId?.value ?? null,
      adCreativeTypeName: details.adCreativeTypeId?.label ?? null,
      keywords: details.keywords?.length > 0 ? details.keywords?.map(keyword => keyword.value)?.join(",") : null,

      storyAdId: Number(details.storyAdId) || null,
      storyAdName: !Number(details.storyAdId) ? null : storyDetails?.title || null,

      videoAssetSrc,
      videoAssetAspectRatio: Number(storyAdAssetSettings.videoAssetAspectRatio) || null,
      imageAssetSrc,
      imageAssetAspectRatio: Number(storyAdAssetSettings.imageAssetAspectRatio) || null,

      videoAssetFilename,
      imageAssetFilename,

      storyAdCtaItems: storyAdCtaItemsPayload?.map(el => ({
        type: el.type,
        text: el.text,
        txtc: el.textColor,
        bgcr: el.backgroundColor,
        icon: el.icon,
        isrc: el.iconUrl,
        icfn: el.iconFilename,
        iasc: el.iconAspectRatio,
        curl: el.clickUrl,
        dplk: el.deeplink,
        stxt: el.shareText,
        surl: el.shareUrl,
        spkg: el.sharePackageName,
      })),
      rawd: {
        id: storyDetails?.id ?? null,
        title: storyDetails?.title ?? null,
        orientation: storyDetails?.orientation ?? null,
        contentType: storyDetails?.contentType ?? null,
        isHeadSupported: storyDetails?.isHeadSupported ?? null,
        isOTFSupported: storyDetails?.isOTFSupported ?? null,
        rawResourcesURL: storyDetails?.rawResourcesURL ?? null,
        fixedWidthSmall: storyDetails?.fixedWidthSmall ?? null,
        fixedWidthMedium: storyDetails?.fixedWidthMedium ?? null,
        fixedWidthFull: storyDetails?.fixedWidthFull ?? null,
        actors: storyDetails?.actors ?? null,
        textBubbles: storyDetails?.textBubbles ?? null,
        enableWatermark: storyDetails?.enableWatermark ?? null,
        watermarkDetails: storyDetails?.watermarkDetails ?? null,
        displayInterval: storyDetails?.displayInterval ?? null,
        characters: storyDetails?.characters ?? null,
        expressions: storyDetails?.expressions ?? null,
        wigs: storyDetails?.wigs ?? null,
        accessories: storyDetails?.accessories ?? null,
      },

      startAt: parseDateInMoment(campaignSettings.startDate).format('YYYY-MM-DD') + startAtSuffix,
      endAt: parseDateInMoment(campaignSettings.endDate).format('YYYY-MM-DD') + endAtSuffix,
      dailyConversionLimit: isPartnerNameOther ? null : Number(campaignSettings.dailyConversionLimit),
      brandCampaign: Number(campaignSettings.brandCampaign?.value) ?? null,
      brandCampaignName: campaignSettings.brandCampaign?.label ?? null,

      whitelistedDuration: whitelistedDuration?.length > 0 ? whitelistedDuration : [],

      staticIconSrc,
      staticIconAspectRatio: Number(creatives.staticIconAspectRatio) ?? 0,
      animatedIconSrc,
      animatedIconAspectRatio: Number(creatives.animatedIconAspectRatio) ?? 0,
      providerIconSrc,
      providerIconAspectRatio: Number(creatives.providerIconAspectRatio) ?? 0,

      staticIconFilename,
      animatedIconFilename,
      providerIconFilename,

      uiTilesDetails,
      uiDetailsCardDetails,
      uiProductsCardDetails,
      uiProductsCardTwoDetails,
      uiProductsCardThreeDetails,

      actionType: action.actionType,
      clickURL: action.clickUrl ?? "",
      deeplink: action.deeplink ?? "",
      redirectionUrl: action.redirectionUrl ?? "",
      isDynamic,

      presentInTypingState: visibilitySettings.presentInTypingState,
      presentInNonTypingState: visibilitySettings.presentInNonTypingState,
      allowDuplication: visibilitySettings.allowDuplication,
      showAdIfAppIsInstalled: visibilitySettings.showAd,
      showAdOnlyIfAppIsInstalled: visibilitySettings.showAdOnlyIfAppIsInstalled,
      isPromoted: visibilitySettings.isPromoted,
      intentIds: intentIds,
      intentIdsList,
      relatedCategories: relatedCategories,
      blacklistedPackages: visibilitySettings.blacklistedPackages?.length > 0 ? visibilitySettings.blacklistedPackages?.map(pkg => pkg.value) : [],

      chatbotSettings: cbSettings,

      trackers: trackersPayload,
      customAppPackages: customAppDetailsPayload ?? [],
      whitelistAutocomplete: keywords.whitelistAutocomplete?.length > 0 ? keywords.whitelistAutocomplete?.map(keyword => keyword.value)?.join(",") : null,
      whitelistExact: keywords.whitelistExact?.length > 0 ? keywords.whitelistExact?.map(keyword => keyword.value)?.join(",") : null,
      blacklistAutocomplete: keywords.blacklistAutocomplete?.length > 0 ? keywords.blacklistAutocomplete?.map(keyword => keyword.value)?.join(",") : null,
      blacklistExact: keywords.blacklistExact?.length > 0 ? keywords.blacklistExact?.map(keyword => keyword.value)?.join(",") : null,
    }

    if (props.match.params.id && !props.match.path?.includes("duplicate")) {
      // update smart suggestion
      payload = {
        oldAd: valuesBeforeUpdate,
        updatedAd: payload
      }
      try {
        setLoading(true)
        let response = await updateSmartSuggestionById(props.match.params.id, payload)
        if (response?.status === "success") {
          setSuccessModalText(smartSuggestionResponses.updatedSuccessfully);
          setSuccessModalDisplay(true);
        } else if (response.errorCode === "invalidAssetSize") {
          let text = 'File is too large to compress. Please upload a smaller file.';
          showErrorModal(text);
        } else {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken ||
            response.errorCode === error.unauthorized
          )
            redirectOnTokenExpiry();
          let text = errorDescription(error.unexpectedError);
          showErrorModal(text);
        }
      } catch (err) {
        let text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      } finally {
        setLoading(false)
      }
    } else {
      // create smart suggestion
      try {
        setLoading(true)
        let response = await createSmartSuggestion(payload)
        if (response?.status === "success") {
          setNewSmartSuggestionId(response?.id)
          setSuccessModalText(smartSuggestionResponses.addedSuccessfully);
          setSuccessModalDisplay(true);
        } else {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken ||
            response.errorCode === error.unauthorized
          )
            redirectOnTokenExpiry();
          let text = errorDescription(error.unexpectedError);
          showErrorModal(text);
        }
      } catch (err) {
        let text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      } finally {
        setLoading(false)
      }
    }
  };

  const fetchSmartSuggestionData = async (id) => {
    try {
      setLoading(true)
      let response = await fetchSmartSuggestionById(id)
      if (response?.id) {
        const relatedCategories = response?.relatedCategories?.map(category => ({
          label: formatCategoryName(category.name),
          value: category.id
        }))
        const packageNames = response?.chatbotSettings?.packageNames?.map(pkg => ({
          label: pkg,
          value: pkg
        }))
        const keywords = response?.keywords ? response?.keywords?.split(",")?.map(keyword => ({
          label: keyword,
          value: keyword
        })) : null
        const trackers = response?.trackers?.map(tracker => ({
          ...tracker,
          type: {
            label: trackerTypeOptions.find(t => t.value === tracker?.type)?.label ?? tracker?.type,
            value: tracker?.type,
          }
        }))
        let type = {}
        if (response?.type) {
          type = {
            label: suggestionTypeOptions.find(type => type.value === response?.type)?.label ?? response?.type,
            value: response?.type
          }
        } else {
          type = details.type
        }

        if (packageNames?.length > 0 || response?.chatbotSettings?.messageText || response?.chatbotSettings?.triggerTimeout) {
          setIsAutoSendChatbotSettingsEnable(true)
        }

        setPartnerName(response?.partnerName)
        setDetails((prevState) => ({
          ...prevState,
          title: response?.title,
          titleLong: response?.longTitle,
          subTitle: response?.subTitle,
          description: response?.description,
          type: type,
          category: response?.category ? {
            label: categoryOptions.find(option => option.value.toLowerCase() === response?.category?.toLowerCase())?.label ?? response?.category,
            value: response?.category
          } : null,
          packageName: response?.packageName,
          unitPrice: response?.unitPrice,
          domainName: response?.domainName ?? "",
          typingStatePriority: response?.typingStatePriority,
          keywords,
          storyAdId: response?.storyAdId,
          customAppPackages: response?.customAppPackages
        }))

        setCreativeUIDetails((prevState) => ({
          ...prevState,
          uiTilesDetails: response?.uiTilesDetails,
          uiDetailsCardDetails: response?.uiDetailsCardDetails,
          uiProductsCardDetails: response?.uiProductsCardDetails,
          uiProductsCardTwoDetails: response?.uiProductsCardTwoDetails,
          uiProductsCardThreeDetails: response?.uiProductsCardThreeDetails
        }))
        setDetailsCardFieldsEdit((prevState) => ({
          ...prevState,
          title: !!response?.uiDetailsCardDetails?.title,
          subTitle: !!response?.uiDetailsCardDetails?.subTitle
        }))

        setStoryAdAssetSettings((prevState) => ({
          ...prevState,
          videoAssetSrc: response?.videoAssetSrc,
          videoAssetFilename: response?.videoAssetFilename,
          videoAssetAspectRatio: response?.videoAssetAspectRatio,
          imageAssetSrc: response?.imageAssetSrc,
          imageAssetFilename: response?.imageAssetFilename,
          imageAssetAspectRatio: response?.imageAssetAspectRatio,
        }))

        setStoryAdCtaItems(response?.storyAdCtaItems?.length > 0 ? response?.storyAdCtaItems?.map(ctaItem => ({
          type: ctaItem.type ? {
            label: storyAdCtaItemTypeOptions.find(option => option.value === ctaItem.type).label ?? ctaItem.type,
            value: ctaItem.type
          } : storyAdCtaItemTypeOptions[0].target.value,
          text: ctaItem.text,
          textColor: ctaItem.txtc,
          backgroundColor: ctaItem.bgcr,
          icon: null,
          iconUrl: ctaItem.isrc,
          iconFilename: ctaItem.icfn,
          iconAspectRatio: ctaItem.iasc,
          clickUrl: ctaItem.curl,
          deeplink: ctaItem.dplk,
          shareText: ctaItem.stxt,
          shareUrl: ctaItem.surl,
          sharePackageName: ctaItem.spkg ? {
            label: storyCTASharePackageNameOptions.find(option => option.value === ctaItem.spkg)?.label ?? ctaItem.spkg,
            value: ctaItem.spkg
          } : null,
        })) : [])

        setStoryDetails(response?.rawd)
        setCustomAppPackagesRes(response?.customAppPackages)
        setRevenueModel(response?.revenueModel)
        setAdCreativeTypeId(response?.adCreativeTypeId)

        setCampaignSettings((prevState) => ({
          ...prevState,
          startDate: convertUTCToIST(response?.startDate),
          endDate: convertUTCToIST(response?.endDate),
          dailyConversionLimit: response?.dailyConversionLimit,
          brandCampaign: response?.brandCampaign ? response?.brandCampaign : {
            value: "none",
            label: "None"
          }
        }))

        setWhitelistedDuration(response?.whitelistedDuration?.length > 0 ? response?.whitelistedDuration : [])

        setBrandCampaignId(response?.brandCampaign)

        setCreatives((prevState) => ({
          ...prevState,
          staticIconSrc: response?.staticIconSrc,
          animatedIconSrc: response?.animatedIconSrc,
          providerIconSrc: response?.providerIconSrc,
          staticIconFilename: response?.staticIconFilename,
          animatedIconFilename: response?.animatedIconFilename,
          providerIconFilename: response?.providerIconFilename,
          staticIconAspectRatio: response?.staticIconAspectRatio,
          animatedIconAspectRatio: response?.animatedIconAspectRatio,
          providerIconAspectRatio: response?.providerIconAspectRatio,
        }))

        setAction((prevState) => ({
          ...prevState,
          isDynamic: response?.isDynamic,
          deeplink: response?.deeplink,
          clickUrl: response?.clickURL,
          redirectionUrl: response?.redirectionUrl
        }))

        setVisibilitySettings((prevState) => ({
          ...prevState,
          presentInTypingState: response?.presentInTypingState,
          presentInNonTypingState: response?.presentInNonTypingState,
          allowDuplication: response?.allowDuplication,
          isPromoted: response?.isPromoted,
          showAd: response?.showAdIfAppIsInstalled,
          showAdOnlyIfAppIsInstalled: response?.showAdOnlyIfAppIsInstalled,
          relatedCategories: relatedCategories,
        }))

        setAllBlacklistedPackages(response?.blacklistedPackages?.length > 0 ? response?.blacklistedPackages : [])

        setChatbotSettings((prevState) => ({
          ...prevState,
          packageNames: packageNames?.length > 0 ? packageNames : packageNames?.length === 0 ? packageNameOptions : [],
          messageText: response?.chatbotSettings?.messageText ?? "",
          triggerTimeout: response?.chatbotSettings?.triggerTimeout ?? "10000"
        }))

        setKeywords((prevState) => ({
          ...prevState,
          whitelistAutocomplete: response?.whitelistAutocomplete
            ? response.whitelistAutocomplete.split(",").map((keyword) => ({
              label: keyword,
              value: keyword,
            }))
            : null,
          whitelistExact: response?.whitelistExact
            ? response.whitelistExact.split(",").map((keyword) => ({
              label: keyword,
              value: keyword,
            }))
            : null,
          blacklistAutocomplete: response?.blacklistAutocomplete
            ? response.blacklistAutocomplete.split(",").map((keyword) => ({
              label: keyword,
              value: keyword,
            }))
            : null,
          blacklistExact: response?.blacklistExact
            ? response.blacklistExact.split(",").map((keyword) => ({
              label: keyword,
              value: keyword,
            }))
            : null,
        }))

        setTrackers(trackers ?? [])

        setValuesBeforeUpdate((prevState) => ({
          ...prevState,
          title: response?.title ?? "",
          longTitle: response?.longTitle ?? null,
          subTitle: response?.subTitle ?? null,
          description: response?.description ?? null,
          type: type?.value ?? "",
          category: response.category ?? "",
          partnerName: response?.partnerName ?? null,
          packageName: response?.packageName ?? "",
          revenueModel: response?.revenueModel ?? "",
          unitPrice: response?.unitPrice ?? null,
          domainName: response?.domainName ?? null,
          typingStatePriority: response?.typingStatePriority ?? 1,
          serverAdProviderId: response?.serverAdProviderId ?? null,
          adCreativeTypeId: response?.adCreativeTypeId ?? null,
          storyAdId: response?.storyAdId ?? null,
          storyAdName: response?.storyAdName ?? null,
          storyAdCtaItems: response?.storyAdCtaItems ?? null,
          rawd: response?.rawd,
          keywords: response?.keywords ?? "",
          uiTilesDetails: response?.uiTilesDetails,
          uiDetailsCardDetails: response?.uiDetailsCardDetails,
          uiProductsCardDetails: response?.uiProductsCardDetails,
          uiProductsCardTwoDetails: response?.uiProductsCardTwoDetails,
          uiProductsCardThreeDetails: response?.uiProductsCardThreeDetails,
          startDate: response?.startDate,
          endDate: response?.endDate,
          dailyConversionLimit: response?.dailyConversionLimit,
          brandCampaign: response?.brandCampaign ?? null,
          whitelistedDuration: response?.whitelistedDuration?.length > 0 ? response?.whitelistedDuration : [],
          staticIconSrc: response?.staticIconSrc ?? "",
          staticIconAspectRatio: response?.staticIconAspectRatio,
          animatedIconSrc: response?.animatedIconSrc ?? "",
          animatedIconAspectRatio: response?.animatedIconAspectRatio,
          providerIconSrc: response?.providerIconSrc ?? "",
          providerIconAspectRatio: response?.providerIconAspectRatio,
          staticIconFilename: response?.staticIconFilename ?? "",
          animatedIconFilename: response?.animatedIconFilename ?? "",
          providerIconFilename: response?.providerIconFilename ?? "",
          actionType: response?.actionType,
          isDynamic: response?.isDynamic,
          allowDuplication: response?.allowDuplication,
          clickUrl: response?.clickURL ?? "",
          deeplink: response?.deeplink ?? "",
          redirectionUrl: response?.redirectionUrl ?? "",
          presentInTypingState: response?.presentInTypingState,
          presentInNonTypingState: response?.presentInNonTypingState,
          showAdIfAppIsInstalled: response?.showAdIfAppIsInstalled,
          showAdOnlyIfAppIsInstalled: response?.showAdOnlyIfAppIsInstalled,
          isPromoted: response?.isPromoted,
          intentIds: response?.intentIds,
          relatedCategories: response?.relatedCategories?.map(rc => { return { id: rc.id, name: formatCategoryName(rc.name) } }) ?? null,
          blacklistedPackages: response?.blacklistedPackages?.length > 0 ? response?.blacklistedPackages : [],
          chatbotSettings: {
            packageNames: packageNames?.length > 0 ? packageNames?.map(el => el.value) : [],
            messageText: response?.chatbotSettings?.messageText ?? "",
            triggerTimeout: Number(response?.chatbotSettings?.triggerTimeout ?? 10000),
          },
          trackers: response?.trackers,
          customAppPackages: response?.customAppPackages,
          whitelistAutocomplete: response?.whitelistAutocomplete ?? null,
          whitelistExact: response?.whitelistExact ?? null,
          blacklistAutocomplete: response?.blacklistAutocomplete ?? null,
          blacklistExact: response?.blacklistExact ?? null,
        }))
      } else {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken ||
          response.errorCode === error.unauthorized
        )
          redirectOnTokenExpiry();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false)
    }
  };

  const handleSearchStory = async (storyAdId) => {
    setStoryAdsLoading(true)
    try {
      let response = await fetchStory(storyAdId)
      if (response?.story) {
        let details = {
          ...response.story,
          characters: response.characters,
          expressions: response.expressions,
          wigs: response.wigs,
          accessories: response.accessories
        }
        setStoryDetails(details)
      }
      else setStoryDetails(response)
      return response
    } catch (err) {
      console.log(err);
    } finally {
      setStoryAdsLoading(false)
    }
  }

  const requiredComponent = (props) => (
    <div>
      <components.Input {...props} required={true} />
    </div>
  );

  const notRequiredComponent = (props) => (
    <div>
      <components.Input {...props} required={false} />
    </div>
  );

  const formatCategoryName = (s) => {
    s = s?.replaceAll('_', ' ') ?? ""
    var splitStr = s?.toLowerCase()?.split(' ');
    for (let index = 0; index < splitStr?.length; index++) {
      const str = splitStr[index];
      splitStr[index] = str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
    }
    return splitStr?.join(' ');
  }

  const handleDuplicate = () => {
    if (props.match.params.id)
      redirectTo(`/ads/smart-suggestions/duplicate/${props.match.params.id}`);
  }

  return (
    <Container fluid className="p-0">
      <Row className="mb-3">
        <Col md={6} xs={6}>
          <h1>{changeType} Smart Suggestion</h1>
        </Col>
      </Row>

      {loading ? (
        <Loading />
      ) : (
        <Form onSubmit={(event) => setConfirmationModalState(event, smartSuggestionsAllActions.submitSmartSuggestion)}>
          <SmartSuggestionDetails
            showDuplicate={showEditSmartSuggestionButton() && props.match.params.id && !props.match.path?.includes("duplicate")}
            disabled={!showEditSmartSuggestionButton()}
            details={details}
            creatives={creatives}
            creativeUIDetails={creativeUIDetails}
            detailsCardFieldsEdit={detailsCardFieldsEdit}
            setDetailsCardFieldsEdit={setDetailsCardFieldsEdit}
            setCreativeUIDetails={setCreativeUIDetails}
            setCreatives={setCreatives}
            setAction={setAction}
            setCustomAppDetails={setCustomAppDetails}
            handleDuplicate={handleDuplicate}
            showErrorModal={showErrorModal}
          />

          <Row>
            <Col lg="12">
              <Row className="mb-3">
                <Col md={6} xs={6}>
                  <h3>Story Ad Settings</h3>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          <strong>
                            Video Asset
                            <div className="text-info"><small>Format: video, Aspect ratio: {storyAdVideoAssetAspectRatioRange?.length === 2 ? JSON.stringify(storyAdVideoAssetAspectRatioRange) : "any"}, Size: {storyAdVideoAssetSize ? storyAdVideoAssetSize / 1024 + "KB" : "any"}</small></div>
                          </strong>
                        </Label>
                        <Container className="custom-file">
                          <Input
                            type="file"
                            className="custom-file-input"
                            accept="video/mp4,video/x-m4v,video/mov,video/wmv,video/mkv"
                            name="videoAsset"
                            onChange={(event) => {
                              handleStoryAdAssetFileChange(event, "video")
                            }}
                            disabled={!showEditSmartSuggestionButton()}
                          />
                          <Label
                            className="custom-file-label"
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis"
                            }}
                          >
                            <strong>{storyAdAssetSettings.videoAssetFilename || "Choose a video asset"}</strong>
                          </Label>
                        </Container>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          <strong>
                            Image Asset <small className="text-info">(Also used as video asset fallback)</small>
                            <div className='text-info'><small>Formats: png, jpg, jpeg, webp, Aspect ratio: {storyAdImageAssetAspectRatioRange?.length === 2 ? JSON.stringify(storyAdImageAssetAspectRatioRange) : "any"}, Size: {storyAdImageAssetSize ? storyAdImageAssetSize / 1024 + "KB" : "any"}</small></div>
                          </strong>
                        </Label>
                        <Container className="custom-file">
                          <Input
                            type="file"
                            className="custom-file-input"
                            accept="image/png, image/jpg, image/jpeg, image/webp"
                            name="imageAsset"
                            onChange={(event) => {
                              handleStoryAdAssetFileChange(event, "image")
                            }}
                            disabled={!showEditSmartSuggestionButton()}
                          />
                          <Label
                            className="custom-file-label"
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis"
                            }}
                          >
                            <strong>{storyAdAssetSettings.imageAssetFilename || "Choose an image asset"}</strong>
                          </Label>
                        </Container>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      {(storyAdAssetSettings.videoAssetSrc) ? (
                        <Col className="pb-4">
                          <video width="250" autoPlay loop muted>
                            <source src={storyAdAssetSettings.videoAssetSrc} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                          {showEditSmartSuggestionButton() && (
                            <div className="text-danger font-weight-bolder cursor-pointer btn-link mt-2" onClick={() => handleResetStoryAdAsset("video")}>
                              Reset
                            </div>
                          )}
                        </Col>
                      ) : (
                        <Col></Col>
                      )}
                    </Col>
                    <Col md={6}>
                      {(storyAdAssetSettings.imageAssetSrc) ? (
                        <Col className="pb-4">
                          <img
                            src={storyAdAssetSettings.imageAssetSrc}
                            alt="Static asset"
                            width="120"
                          />
                          {showEditSmartSuggestionButton() && (
                            <div className="text-danger font-weight-bolder cursor-pointer btn-link mt-2" onClick={() => handleResetStoryAdAsset("image")}>
                              Reset
                            </div>
                          )}
                        </Col>
                      ) : (
                        <Col></Col>
                      )}
                    </Col>
                    <Col lg={6} md={12} className="d-flex align-items-end mb-2">
                      <FormGroup className="w-100 mb-0">
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Story ID <small className="text-info">(Click on search icon after changing story ID)</small>
                        </Label>
                        <Input
                          type="number"
                          name="storyAdId"
                          min={0}
                          placeholder="Search Story ID"
                          value={details.storyAdId}
                          onChange={handleDetailsChange}
                          disabled={!showEditSmartSuggestionButton()}
                        />
                      </FormGroup>
                      <Button
                        className="ml-2"
                        onClick={() => handleSearchStory(details.storyAdId)}
                        disabled={!showEditSmartSuggestionButton() || !details.storyAdId}
                      >
                        <IoSearch size={17} />
                      </Button>
                      {details.storyAdId && (
                        <IoCloseCircleOutline
                          className="ml-2 cursor-pointer"
                          size={32}
                          color="red"
                          onClick={() => {
                            setDetails(prevState => ({
                              ...prevState,
                              storyAdId: ""
                            }))
                            setStoryDetails(null)
                          }}
                        />
                      )}
                    </Col>
                    {details.storyAdId && (
                      <Col>
                        <Button
                          className="float-right"
                          onClick={() => handleSearchStory(details.storyAdId)}
                          disabled={!showEditSmartSuggestionButton()}
                        >
                          <IoSync size={17} />
                        </Button>
                      </Col>
                    )}
                  </Row>
                  {storyAdsLoading && (
                    <Loading />
                  )}
                  {!storyAdsLoading && storyDetails && (storyDetails?.id || storyDetails?.errorDescription) && (
                    <Row>
                      <Col md={12}>
                        {storyDetails?.errorDescription ? (
                          <div className="text-danger font-weight-bolder">{storyDetails?.errorDescription}</div>
                        ) : (
                          <Row>
                            <Col md={12}>
                              <Card className="mb-3" style={{ width: "fit-content" }}>
                                <CardHeader>
                                  {storyDetails?.fixedWidthMedium?.mp4?.url ? (
                                    <video height="250" autoPlay loop muted>
                                      <source src={storyDetails?.fixedWidthMedium?.mp4?.url} type="video/mp4" />
                                      Your browser does not support the video tag.
                                    </video>
                                  ) : (
                                    <img src={storyDetails?.fixedWidthMedium?.jpg?.url} alt="Story preview" height={300} />
                                  )}
                                </CardHeader>
                                <CardBody className="pt-0">
                                  <div><strong>ID : </strong>{storyDetails?.id || "NA"}</div>
                                  <div><strong>Title : </strong>{storyDetails?.title || "NA"}</div>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col lg={6} md={12}>
                              <FormGroup className="d-flex align-items-center">
                                <Label
                                  className="font-weight-bolder mb-0"
                                  style={{ fontSize: "14px" }}
                                >
                                  Show Story Details
                                </Label>
                                <div className="custom-control custom-switch">
                                  <CustomInput
                                    type="checkbox"
                                    id="showStoryDetails"
                                    value={showStoryDetails}
                                    onChange={(e) => setShowStoryDetails(e.target.checked)}
                                    checked={showStoryDetails}
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            {showStoryDetails && (
                              <Col md={12}>
                                <ul>
                                  <li><strong>ID : </strong>{storyDetails?.id || "NA"}</li>
                                  <li><strong>Title : </strong>{storyDetails?.title || "NA"}</li>
                                  <li><strong>Orientation : </strong>{storyDetails?.orientation || "NA"}</li>
                                  <li><strong>Content Type : </strong>{storyDetails?.contentType || "NA"}</li>
                                  <li><strong>Is Head Supported : </strong>{storyDetails?.isHeadSupported ? "Yes" : "No"}</li>
                                  <li><strong>Is OTF Supported : </strong>{storyDetails?.isOTFSupported ? "Yes" : "No"}</li>
                                  <li><strong>Raw Resources URL : </strong>{storyDetails?.rawResourcesURL || "NA"}</li>
                                  <li><strong>Display Interval : </strong>{storyDetails?.displayInterval || "NA"}</li>
                                  <li><strong>Enable Watermark : </strong>{storyDetails?.enableWatermark ? "Yes" : "No"}</li>
                                  <li><strong>Watermark Details : </strong><br /><span dangerouslySetInnerHTML={{ __html: formatValue(storyDetails?.watermarkDetails) || "NA" }}></span></li>
                                  <li><strong>Fixed Width Small : </strong><br /><span dangerouslySetInnerHTML={{ __html: formatValue(storyDetails?.fixedWidthSmall) || "NA" }}></span></li>
                                  <li><strong>Fixed Width Medium : </strong><br /><span dangerouslySetInnerHTML={{ __html: formatValue(storyDetails?.fixedWidthMedium) || "NA" }}></span></li>
                                  <li><strong>Fixed Width Full : </strong><br /><span dangerouslySetInnerHTML={{ __html: formatValue(storyDetails?.fixedWidthFull) || "NA" }}></span></li>
                                  <li><strong>Actors : </strong><br /><span dangerouslySetInnerHTML={{ __html: formatValue(storyDetails?.actors) || "NA" }}></span></li>
                                  <li><strong>Text Bubbles : </strong><br /><span dangerouslySetInnerHTML={{ __html: formatValue(storyDetails?.textBubbles) || "NA" }}></span></li>
                                  <li><strong>Characters : </strong><br /><span dangerouslySetInnerHTML={{ __html: formatValue(storyDetails?.characters) || "NA" }}></span></li>
                                  <li><strong>Expressions : </strong><br /><span dangerouslySetInnerHTML={{ __html: formatValue(storyDetails?.expressions) || "NA" }}></span></li>
                                  <li><strong>Wigs : </strong><br /><span dangerouslySetInnerHTML={{ __html: formatValue(storyDetails?.wigs) || "NA" }}></span></li>
                                  <li><strong>Accessories : </strong><br /><span dangerouslySetInnerHTML={{ __html: formatValue(storyDetails?.accessories) || "NA" }}></span></li>
                                </ul>
                              </Col>
                            )}
                          </Row>
                        )}
                      </Col>
                    </Row>
                  )}
                  <hr />
                  <Row>
                    <Col>
                      <div
                        className="font-weight-bolder my-2"
                        style={{ fontSize: "14px" }}
                      >
                        CTA Items
                      </div>
                    </Col>
                    {showEditSmartSuggestionButton() && (
                      <Col>
                        {(storyAdCtaItems?.length <= 0) ? (
                          <Button color='success' className="float-right" onClick={addStoryAdCtaItem}>Add CTA Item</Button>
                        ) :
                          <Button color='danger' className="float-right" onClick={removeAllStoryAdCtaItems}>Delete CTA Items</Button>
                        }
                      </Col>
                    )}
                  </Row>
                  {storyAdCtaItems?.map((ctaItem, index) => (
                    <Row key={index} form className="mb-3 mt-3">
                      <Col md={12}>
                        <div
                          className="font-weight-bolder mb-2 text-info"
                          style={{ fontSize: "13px" }}
                        >
                          CTA Item #{index + 1}
                        </div>
                      </Col>
                      <Col>
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <Label
                                className="font-weight-bolder mb-2"
                                style={{ fontSize: "14px" }}
                              >
                                Type <span className="text-danger">*</span>
                              </Label>
                              <Select
                                id="type"
                                className="react-select-container"
                                classNamePrefix="react-select"
                                name="type"
                                placeholder="Select CTA Item Type"
                                value={ctaItem.type}
                                onChange={(event) => {
                                  handleStoryAdCtaItemsChange(event, index)
                                }}
                                options={storyAdCtaItemTypeOptions}
                                components={{
                                  Input:
                                    (ctaItem.type?.value)
                                      ? notRequiredComponent
                                      : requiredComponent,
                                }}
                                isDisabled={!showEditSmartSuggestionButton()}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={3}>
                            <FormGroup>
                              <Label
                                className="font-weight-bolder mb-2"
                                style={{ fontSize: "14px" }}
                              >
                                Text Color <span className="text-danger">*</span>
                              </Label>
                              <br />
                              <ColorPicker
                                showText
                                value={ctaItem.textColor}
                                onChange={(color, hexCode) => handleStoryAdCtaItemColorChange(hexCode, "textColor", index)}
                                disabled={!showEditSmartSuggestionButton()}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={3}>
                            <FormGroup>
                              <Label
                                className="font-weight-bolder mb-2"
                                style={{ fontSize: "14px" }}
                              >
                                Background Color <span className="text-danger">*</span>
                              </Label>
                              <br />
                              <ColorPicker
                                showText
                                value={ctaItem.backgroundColor}
                                onChange={(color, hexCode) => handleStoryAdCtaItemColorChange(hexCode, "backgroundColor", index)}
                                disabled={!showEditSmartSuggestionButton()}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label
                                className="font-weight-bolder mb-2"
                                style={{ fontSize: "14px" }}
                              >
                                Text <span className="text-danger">* </span>
                              </Label>
                              <Input
                                type="text"
                                name="text"
                                placeholder="Enter Text"
                                value={ctaItem.text}
                                onChange={(event) => {
                                  handleStoryAdCtaItemsChange(event, index)
                                }}
                                required
                                disabled={!showEditSmartSuggestionButton()}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label>
                                <strong>
                                  Icon <small className="text-info">Formats: png, jpg, jpeg, webp, Aspect ratio: any, Size: 2MB (Optional) </small>
                                </strong>
                              </Label>
                              <Container className="custom-file">
                                <Input
                                  type="file"
                                  className="custom-file-input"
                                  accept="image/png, image/jpg, image/jpeg, image/webp"
                                  name="icon"
                                  onChange={(event) => {
                                    handleStoryAdCtaItemFileChange(event, "image", index)
                                  }}
                                  disabled={!showEditSmartSuggestionButton()}
                                />
                                <Label
                                  className="custom-file-label"
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                  }}
                                >
                                  <strong>{ctaItem.iconFilename || "Choose an icon"}</strong>
                                </Label>
                              </Container>
                              {(ctaItem.iconUrl) && (
                                <Col className="pt-3">
                                  <img
                                    src={ctaItem.iconUrl}
                                    alt="Icon"
                                    width="120"
                                  />
                                  {showEditSmartSuggestionButton() && (
                                    <div className="text-danger font-weight-bolder cursor-pointer btn-link mt-2" onClick={() => handleResetStoryAdCtaItemFile(index)}>
                                      Reset
                                    </div>
                                  )}
                                </Col>
                              )}
                            </FormGroup>
                          </Col>
                          {ctaItem.type?.value === "click" && (
                            <Col md={6}>
                              <FormGroup>
                                <Label
                                  className="font-weight-bolder mb-2"
                                  style={{ fontSize: "14px" }}
                                >
                                  Deeplink <small className="text-info">(Optional for overriding)</small>
                                </Label>
                                <Input
                                  type="textarea"
                                  name="deeplink"
                                  placeholder="Enter Deeplink"
                                  value={ctaItem.deeplink}
                                  onChange={(event) => {
                                    handleStoryAdCtaItemsChange(event, index)
                                  }}
                                  disabled={!showEditSmartSuggestionButton()}
                                />
                              </FormGroup>
                            </Col>
                          )}
                          {ctaItem.type?.value === "click" && (
                            <Col md={6}>
                              <FormGroup>
                                <Label
                                  className="font-weight-bolder mb-2"
                                  style={{ fontSize: "14px" }}
                                >
                                  Click URL <small className="text-info">(Optional for overriding)</small>
                                </Label>
                                <Input
                                  type="textarea"
                                  name="clickUrl"
                                  placeholder="Enter Click URL"
                                  value={ctaItem.clickUrl}
                                  onChange={(event) => {
                                    handleStoryAdCtaItemsChange(event, index)
                                  }}
                                  disabled={!showEditSmartSuggestionButton()}
                                />
                              </FormGroup>
                            </Col>
                          )}
                          {ctaItem.type?.value === "share" && (
                            <Col md={6}>
                              <FormGroup>
                                <Label
                                  className="font-weight-bolder mb-2"
                                  style={{ fontSize: "14px" }}
                                >
                                  Share Text <span className="text-danger">* </span>
                                  <ToolTip
                                    data={`To use share text along with share URL, use ${shareUrlPlaceholder} placeholder.`}
                                    id="shareTextTooltip"
                                  />
                                </Label>
                                <Input
                                  type="textarea"
                                  name="shareText"
                                  placeholder={`Enter Share Text with ${shareUrlPlaceholder}`}
                                  value={ctaItem.shareText}
                                  onChange={(event) => {
                                    handleStoryAdCtaItemsChange(event, index)
                                  }}
                                  required
                                  disabled={!showEditSmartSuggestionButton()}
                                />
                              </FormGroup>
                            </Col>
                          )}
                          {ctaItem.type?.value === "share" && (
                            <Col md={6}>
                              <FormGroup>
                                <Label
                                  className="font-weight-bolder mb-2"
                                  style={{ fontSize: "14px" }}
                                >
                                  Share URL
                                  <br />
                                  {(ctaItem.shareText?.includes(shareUrlPlaceholder) && !ctaItem.shareUrl) ? (
                                    <span className="text-warning font-weight-bold">
                                      <span role="img" aria-label="warning-icon">⚠️</span> Missing share URL
                                    </span>
                                  ) : null
                                  }
                                </Label>
                                <Input
                                  type="textarea"
                                  name="shareUrl"
                                  placeholder="Enter Share URL"
                                  value={ctaItem.shareUrl}
                                  onChange={(event) => {
                                    handleStoryAdCtaItemsChange(event, index)
                                  }}
                                  disabled={!showEditSmartSuggestionButton()}
                                />
                              </FormGroup>
                            </Col>
                          )}
                          {ctaItem.type?.value === "share" && (
                            <Col md={6}>
                              <FormGroup>
                                <Label
                                  className="font-weight-bolder mb-2"
                                  style={{ fontSize: "14px" }}
                                >
                                  Share Package Name{" "}
                                  <ToolTip
                                    data={`Package name of the specific application on which story needs to be shared.`}
                                    id="sharePackageNameTooltip"
                                  />
                                </Label>
                                <CreatableSelect
                                  id="sharePackageName"
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  name="sharePackageName"
                                  placeholder="Select Share Package Name"
                                  value={ctaItem.sharePackageName}
                                  onChange={(event) => {
                                    const temp = [...storyAdCtaItems]
                                    temp[index]["sharePackageName"] = event
                                    setStoryAdCtaItems(temp)
                                  }}
                                  options={storyCTASharePackageNameOptions}
                                  isClearable={true}
                                  isDisabled={!showEditSmartSuggestionButton()}
                                />
                              </FormGroup>
                            </Col>
                          )}
                        </Row>
                      </Col>
                      {showEditSmartSuggestionButton() && (
                        <Col md={1}>
                          {index === 0 ? (
                            <Button className="mt-4" onClick={addStoryAdCtaItem} color="transparent">
                              <FaPlus />
                            </Button>
                          ) : (
                            <Button className="mt-4" onClick={() => removeStoryAdCtaItem(index)} color="transparent">
                              <FaMinus />
                            </Button>
                          )}
                        </Col>
                      )}
                      <Col lg="12">
                        {index !== (storyAdCtaItems.length - 1) && <hr style={{ marginBottom: 0 }} />}
                      </Col>
                    </Row>
                  ))}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Row className="mb-3">
                <Col md={6} xs={6}>
                  <h3>Details</h3>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <Row form>
                    <Col lg={4} md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Type
                        </Label>
                        <Select
                          id="type"
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="type"
                          placeholder="Select Type"
                          value={details.type}
                          onChange={handleDetailsChange}
                          options={suggestionTypeOptions}
                          isDisabled={!showEditSmartSuggestionButton()}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Category <span className="text-danger">*</span>
                        </Label>
                        <Select
                          id="category"
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="category"
                          placeholder="Select Category"
                          value={details.category}
                          onChange={
                            (event) => {
                              setDetails((prevState) => ({
                                ...prevState,
                                category: event,
                              }));
                            }
                          }
                          options={categoryOptions}
                          isDisabled={!showEditSmartSuggestionButton()}
                          components={{
                            Input:
                              (details.category?.value)
                                ? notRequiredComponent
                                : requiredComponent,
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Partner Name <span className="text-danger">* </span>
                          <ToolTip
                            data="If the partner's name is not listed in the provided options, please enter the partner name, e.g., mobavenue"
                            id="partnerNameTooltip"
                          />
                        </Label>
                        <CreatableSelect
                          id="partnerName"
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="partnerName"
                          placeholder="Select Partner Name"
                          value={details.partnerName}
                          onChange={(event) => {
                            setDetails((prevState) => ({
                              ...prevState,
                              partnerName: event,
                            }))
                          }}
                          options={partnersNameOptions}
                          components={{
                            Input:
                              (details.partnerName?.value)
                                ? notRequiredComponent
                                : requiredComponent,
                          }}
                          isDisabled={!showEditSmartSuggestionButton()}
                        />

                      </FormGroup>
                    </Col>

                    <Col lg={4} md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Package Name {(details.type?.value === "app_install" || details.revenueModel?.value === "cpi" || visibilitySettings.showAd || visibilitySettings.showAdOnlyIfAppIsInstalled) && <span className="text-danger">*</span>}
                        </Label>
                        <Input
                          type="text"
                          name="packageName"
                          placeholder="Enter Package Name"
                          pattern="^[a-zA-Z0-9_]+(?:\.[a-zA-Z0-9_]+)+$"
                          title="Enter a valid alphanumeric sequence of words separated by dots"
                          value={details.packageName}
                          onChange={handleDetailsChange}
                          required={(details.type?.value === "app_install" || details.revenueModel?.value === "cpi" || visibilitySettings.showAd || visibilitySettings.showAdOnlyIfAppIsInstalled)}
                          disabled={!showEditSmartSuggestionButton()}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={4} md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Revenue Model <span className="text-danger">*</span>
                        </Label>
                        <Select
                          id="revenueModel"
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="revenueModel"
                          placeholder="Select Revenue Model"
                          value={details.revenueModel}
                          onChange={handleDetailsChange}
                          options={revenueModelOptions}
                          isDisabled={!showEditSmartSuggestionButton()}
                          components={{
                            Input:
                              (details.revenueModel?.value)
                                ? notRequiredComponent
                                : requiredComponent,
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                      <FormGroup className="position-relative">
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Unit Price {details.revenueModel?.value === "cpi" && <span className="text-danger">*</span>}{" "}
                          <ToolTip
                            data="Revenue per installation from this ad (in Rs.)"
                            id="unitPriceTooltip"
                          />
                        </Label>
                        {(details.revenueModel?.value === "cpc" || details.revenueModel?.value === "whatsapp_bot")
                          ? (
                            <Input
                              type="text"
                              name="unitPrice"
                              placeholder="Unit Price"
                              value="NA"
                              disabled={true}
                            />
                          ) : (
                            <>
                              <Badge
                                className="text-dark position-absolute bg-transparent"
                                style={{
                                  top: 33,
                                  left: 2,
                                  fontSize: 14
                                }}
                              >
                                ₹
                              </Badge>
                              <Input
                                type="number"
                                className="pl-4"
                                step={0.001}
                                name="unitPrice"
                                min={0}
                                placeholder="Unit Price (in Rs.)"
                                value={details.unitPrice}
                                onChange={handleDetailsChange}
                                required={details.revenueModel?.value === "cpi"}
                                disabled={!showEditSmartSuggestionButton()}
                              />
                            </>
                          )}
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Domain Name {(details.revenueModel?.value === "cpc" || details.revenueModel?.value === "whatsapp_bot") && <span className="text-danger">*</span>}
                        </Label>
                        {details.revenueModel?.value === "cpi"
                          ? (
                            <Input
                              type="text"
                              name="domainName"
                              placeholder="Enter Domain Name"
                              value="NA"
                              disabled={true}
                            />
                          ) : (
                            <Input
                              type="text"
                              name="domainName"
                              placeholder="Enter Domain Name"
                              value={details.domainName}
                              onChange={handleDetailsChange}
                              required={details.revenueModel?.value === "cpc" || details.revenueModel?.value === "whatsapp_bot"}
                              disabled={!showEditSmartSuggestionButton()}
                            />
                          )
                        }
                      </FormGroup>
                    </Col>

                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Row className="mb-3">
                <Col md={6} xs={6}>
                  <h3>Campaign Settings</h3>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <Row form>
                    <Col lg={4} md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Start Date <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="date"
                          name="startDate"
                          onChange={handleCampaignSettingsChange}
                          value={getdateValue(campaignSettings.startDate)}
                          disabled={!showEditSmartSuggestionButton()}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          End Date <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="date"
                          name="endDate"
                          onChange={handleCampaignSettingsChange}
                          value={getdateValue(campaignSettings.endDate)}
                          disabled={!showEditSmartSuggestionButton()}
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={4} md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Brand Campaign <span className="text-danger">*</span>
                        </Label>
                        <Select
                          id="brandCampaign"
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="brandCampaign"
                          placeholder="Select Brand Campaign"
                          value={campaignSettings.brandCampaign}
                          onChange={handleCampaignSettingsChange}
                          options={brandCampaignsOptions}
                          isDisabled={!showEditSmartSuggestionButton()}
                          components={{
                            Input:
                              (campaignSettings.brandCampaign?.value)
                                ? notRequiredComponent
                                : requiredComponent,
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div
                        className="font-weight-bolder my-2"
                        style={{ fontSize: "14px" }}
                      >
                        Whitelisted Duration
                      </div>
                    </Col>
                    {showEditSmartSuggestionButton() && (
                      <Col>
                        {(whitelistedDuration?.length <= 0) ? (
                          <Button color='success' className="float-right" onClick={addWhitelistedDuration}>Add Whitelisted Duration</Button>
                        ) :
                          <Button color='danger' className="float-right" onClick={removeAllWhitelistedDurations}>Delete Whitelisted Duration</Button>
                        }
                      </Col>
                    )}
                  </Row>
                  {whitelistedDuration?.map((duration, index) => (
                    <Row key={index} form className="my-2">
                      <Col md={12}>
                        <div
                          className="font-weight-bolder mb-2 text-info"
                          style={{ fontSize: "13px" }}
                        >
                          Duration #{index + 1}
                        </div>
                      </Col>
                      <Col>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label
                                className="font-weight-bolder mb-2"
                                style={{ fontSize: "14px" }}
                              >
                                Start Time <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="time"
                                name="startTime"
                                onChange={(event) => {
                                  handleWhitelistedDurationChange(event, index)
                                }}
                                value={duration.startTime}
                                disabled={!showEditSmartSuggestionButton()}
                                required={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label
                                className="font-weight-bolder mb-2"
                                style={{ fontSize: "14px" }}
                              >
                                End Time <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="time"
                                name="endTime"
                                onChange={(event) => {
                                  handleWhitelistedDurationChange(event, index)
                                }}
                                value={duration.endTime}
                                disabled={!showEditSmartSuggestionButton()}
                                required={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                      {showEditSmartSuggestionButton() && (
                        <Col md={1}>
                          {index === 0 ? (
                            <Button className="mt-4" onClick={addWhitelistedDuration} color="transparent">
                              <FaPlus />
                            </Button>
                          ) : (
                            <Button className="mt-4" onClick={() => removeWhitelistedDuration(index)} color="transparent">
                              <FaMinus />
                            </Button>
                          )}
                        </Col>
                      )}
                      <Col lg="12">
                        {index !== (whitelistedDuration.length - 1) && <hr style={{ marginBottom: 0 }} />}
                      </Col>
                    </Row>
                  ))}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Row className="mb-3">
                <Col md={3} xs={3}>
                  <h3>Action</h3>
                </Col>
                <Col md={9} xs={9}>
                  <Button className="float-right" onClick={showSupportedMacrosModal}>Supported Macros List</Button>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <Row form>
                    <Col lg={4} md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Deeplink
                        </Label>
                        <Input
                          type="textarea"
                          name="deeplink"
                          placeholder="Enter Deeplink"
                          value={action.deeplink}
                          onChange={(event) => {
                            handleActionSettingsChange(event)
                            const msg = validateMacros(event.target?.value)
                            setMacroErrors((prevState) => ({
                              ...prevState,
                              deeplink: msg
                            }))
                          }}
                          disabled={!showEditSmartSuggestionButton()}
                        />
                        {macroErrors.deeplink ? <small className="text-danger font-weight-bold">{macroErrors.deeplink}</small> : null}
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Click URL <span className="text-danger">*</span>{" "}
                          <ToolTip
                            data="This URL handles redirection as well as tracking"
                            id="clickUrlTooltip"
                          />
                        </Label>
                        <Input
                          type="textarea"
                          name="clickUrl"
                          placeholder="Enter Click URL"
                          value={action.clickUrl}
                          onChange={(event) => {
                            const { value } = event.target
                            if (value?.includes("__KWD__"))
                              setAction(prevState => ({
                                ...prevState,
                                isDynamic: true
                              }))
                            handleActionSettingsChange(event)
                            const msg = validateMacros(event.target?.value)
                            setMacroErrors((prevState) => ({
                              ...prevState,
                              clickUrl: msg
                            }))
                          }}
                          disabled={!showEditSmartSuggestionButton()}
                          required
                        />
                        {macroErrors.clickUrl ? <small className="text-danger font-weight-bold">{macroErrors.clickUrl}</small> : null}
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Redirection URL{" "}
                          <ToolTip
                            data="This URL handles only redirection. It should be used in combination with Click Trackers."
                            id="redirectionUrl"
                          />
                        </Label>
                        <Input
                          type="textarea"
                          name="redirectionUrl"
                          placeholder="Enter Redirection URL"
                          value={action.redirectionUrl}
                          onChange={(event) => {
                            handleActionSettingsChange(event)
                            const msg = validateMacros(event.target?.value)
                            setMacroErrors((prevState) => ({
                              ...prevState,
                              redirectionUrl: msg
                            }))
                          }}
                          disabled={!showEditSmartSuggestionButton()}
                        />
                        {macroErrors.redirectionUrl ? <small className="text-danger font-weight-bold">{macroErrors.redirectionUrl}</small> : null}
                      </FormGroup>
                    </Col>
                    {isAffinityAffiliateAd && (
                      <Col lg={4} md={6}>
                        <FormGroup className="d-flex align-items-center">
                          <Label
                            className="font-weight-bolder mb-0"
                            style={{ fontSize: "14px" }}
                          >
                            Is Dynamic{" "}
                            <ToolTip
                              data="Set true if this ad supports user intent based title replacement and deep links for the redirection, if true works only on typing state when ad server version >= 6."
                              id="isDynamicTooltip"
                            />
                          </Label>
                          <div className="custom-control custom-switch">
                            <CustomInput
                              type="checkbox"
                              id="isDynamic"
                              value={action.isDynamic}
                              onChange={(e) => {
                                setAction(prevState => ({
                                  ...prevState,
                                  isDynamic: e.target.checked
                                }))
                              }}
                              checked={action.isDynamic}
                              disabled={!showEditSmartSuggestionButton()}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row className="mt-1">
                    <Col lg="12">
                      <Row className="mb-3">
                        <Col md={6} xs={6}>
                          <Label className="font-weight-bolder mb-2" style={{ fontSize: "14px" }}> Trackers - Default</Label>
                        </Col>
                        {showEditSmartSuggestionButton() && (
                          <Col>
                            {(trackers?.length <= 0) ? (
                              <Button color='success' className="float-right" onClick={() => {
                                setTrackers(
                                  trackers.concat({
                                    type: "",
                                    url: "",
                                  })
                                )
                                setTrackerUrlMacroError(trackerUrlMacroError.concat(""))
                              }}>Add Trackers</Button>
                            ) :
                              <Button color='danger' className="float-right" onClick={(e) => {
                                e.preventDefault();
                                setTrackers([]);
                                setTrackerUrlMacroError([]);
                              }}>Remove All Trackers</Button>
                            }
                          </Col>
                        )}
                      </Row>
                      {trackers?.map((item, index) => {
                        return (
                          <Row key={index} className="pl-4" form>
                            <Col md={2}>
                              <FormGroup>
                                <Label
                                  className="font-weight-bolder mb-2"
                                  style={{ fontSize: "14px" }}
                                >
                                  Tracker Type <span className="text-danger">*</span>
                                </Label>
                                <Select
                                  id="type"
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  name="type"
                                  placeholder="Select Type"
                                  value={item.type}
                                  onChange={(event) =>
                                    handleTrackerChange(event, index)
                                  }
                                  components={{
                                    Input:
                                      (item.type)
                                        ? notRequiredComponent
                                        : requiredComponent,
                                  }}
                                  options={trackerTypeOptions}
                                  isDisabled={!showEditSmartSuggestionButton()}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={9}>
                              <FormGroup>
                                <Label
                                  className="font-weight-bolder mb-2"
                                  style={{ fontSize: "14px" }}
                                >
                                  URL <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  type="textarea"
                                  name="url"
                                  placeholder="Enter URL"
                                  value={item.url}
                                  onChange={(event) => {
                                    handleTrackerChange(event, index)
                                    const msg = validateMacros(event.target?.value)
                                    let temp = [...trackerUrlMacroError];
                                    temp[index] = msg;
                                    setTrackerUrlMacroError(temp);
                                  }}
                                  required
                                  style={{ height: '32px' }}
                                  disabled={!showEditSmartSuggestionButton()}
                                />
                                {trackerUrlMacroError[index] ? <small className="text-danger font-weight-bold">{trackerUrlMacroError[index]}</small> : null}
                              </FormGroup>
                            </Col>
                            {showEditSmartSuggestionButton() && (
                              <Col md={1}>
                                {index === 0 ? (
                                  <Button
                                    className="mt-4"
                                    onClick={() => {
                                      setTrackers(
                                        trackers.concat({
                                          type: "",
                                          url: "",
                                        })
                                      )
                                      setTrackerUrlMacroError(trackerUrlMacroError.concat(""))
                                    }}
                                    color="transparent">
                                    <FaPlus />
                                  </Button>
                                ) : (
                                  <Button
                                    className="mt-4"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setTrackers((prevState) =>
                                        prevState.filter((_, i) => i !== index)
                                      );
                                      setTrackerUrlMacroError((prevState) =>
                                        prevState.filter((_, i) => i !== index)
                                      );
                                    }}
                                    color="transparent">
                                    <FaMinus />
                                  </Button>
                                )}
                              </Col>
                            )}
                          </Row>
                        );
                      })}
                    </Col>
                  </Row>
                  <Row className="mt-1 d-flex">
                    <Col md={12}>
                      <hr style={{ marginTop: 10 }} />
                    </Col>
                    <Col md={6} xs={6}>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "14px", alignItems: "center", marginTop: "5px" }}
                      >
                        Customize App
                      </Label>
                    </Col>
                    {showEditSmartSuggestionButton() && (
                      <Col md={6} xs={6}>
                        {(customAppDetails?.length <= 0) ? (
                          <Button color='success' className="float-right" onClick={addCustomApp}>Add Customize App</Button>
                        ) :
                          <Button color='danger' className="float-right" onClick={removeAllCustomApps}>Remove All Customize App</Button>
                        }
                      </Col>
                    )}
                  </Row>
                  {customAppDetails?.length > 0 && customAppDetails?.map((item, index) => {
                    return (<div key={index}>
                      <Col lg="12">
                        {index !== 0 && <hr style={{ marginBottom: 0 }} />}
                      </Col>
                      <Row className="align-items-center">
                        <Col md={6} xs={8}>
                          <Label className="font-weight-bolder mb-2 text-info" style={{ fontSize: "13px", marginTop: "14px" }}> Customized App Details #{index + 1}</Label>
                        </Col>
                        {showEditSmartSuggestionButton() && (
                          <Col md={6} xs={4} className="text-right">
                            {index === 0 ? (
                              <Button
                                className="btn-lg"
                                style={{ padding: "5px 15px", cursor: "pointer" }}
                                onClick={addCustomApp}
                                color="transparent">
                                <IoAddCircleOutline size={24} className="text-success" />
                              </Button>
                            ) : (
                              <Button
                                className="mt-4"
                                style={{ padding: "5px 15px", cursor: "pointer" }}
                                onClick={removeCustomApp(index)}
                                color="transparent">
                                <IoCloseCircleOutline size={24} className="text-danger" />
                              </Button>
                            )}
                          </Col>
                        )}
                      </Row>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          For App <span className="text-danger">*</span>
                        </Label>
                        <Select
                          id="package"
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="appPackage"
                          placeholder="Choose App Package"
                          value={customAppDetails[index].appPackage}
                          onChange={(event) => {
                            const temp = [...customAppDetails];
                            temp[index]["appPackage"] = event;
                            setCustomAppDetails(temp);
                          }}
                          options={packagesOptions}
                          components={{
                            Input:
                              (customAppDetails[index].appPackage)
                                ? notRequiredComponent
                                : requiredComponent,
                          }}
                          isDisabled={!showEditSmartSuggestionButton()}
                        />
                      </FormGroup>
                      <Row>
                        <Col lg="12">
                          <Row form>
                            <Col lg={4} md={6}>
                              <FormGroup>
                                <Label
                                  className="font-weight-bolder mb-2"
                                  style={{ fontSize: "14px" }}
                                >
                                  Deeplink
                                </Label>
                                <Input
                                  type="textarea"
                                  name="deeplink"
                                  placeholder="Enter Deeplink"
                                  value={item.deeplink}
                                  onChange={(event) => {
                                    handleCustomAppChange(event, index)
                                    validateCustomAppFieldMacros('deeplink', event.target?.value, index);
                                  }}
                                  disabled={!showEditSmartSuggestionButton()}
                                />
                                {customAppMacroErrors[index].deeplink ? <small className="text-danger font-weight-bold">{customAppMacroErrors[index].deeplink}</small> : null}
                              </FormGroup>
                            </Col>
                            <Col lg={4} md={6}>
                              <FormGroup>
                                <Label
                                  className="font-weight-bolder mb-2"
                                  style={{ fontSize: "14px" }}
                                >
                                  Click URL <span className="text-danger">*</span>{" "}
                                  <ToolTip
                                    data="This URL handles redirection as well as tracking"
                                    id="clickUrlTooltip"
                                  />
                                </Label>
                                <Input
                                  type="textarea"
                                  name="clickUrl"
                                  placeholder="Enter Click URL"
                                  value={customAppDetails[index].clickUrl}
                                  onChange={(event) => {
                                    const { value } = event.target
                                    const temp = [...customAppDetails];
                                    temp[index].isDynamic = value?.includes("__KWD__") || details.title?.includes("__KWD__");
                                    setCustomAppDetails(temp);
                                    handleCustomAppChange(event, index)
                                    validateCustomAppFieldMacros('clickUrl', event.target?.value, index);
                                  }}
                                  disabled={!showEditSmartSuggestionButton()}
                                  required
                                />
                                {customAppMacroErrors[index].clickUrl ? <small className="text-danger font-weight-bold">{customAppMacroErrors[index].clickUrl}</small> : null}
                              </FormGroup>
                            </Col>
                            <Col lg={4} md={6}>
                              <FormGroup>
                                <Label
                                  className="font-weight-bolder mb-2"
                                  style={{ fontSize: "14px" }}
                                >
                                  Redirection URL{" "}
                                  <ToolTip
                                    data="This URL handles only redirection. It should be used in combination with Click Trackers."
                                    id="redirectionUrl"
                                  />
                                </Label>
                                <Input
                                  type="textarea"
                                  name="redirectionUrl"
                                  placeholder="Enter Redirection URL"
                                  value={customAppDetails[index].redirectionUrl}
                                  onChange={(event) => {
                                    handleCustomAppChange(event, index)
                                    validateCustomAppFieldMacros('redirectionUrl', event.target?.value, index);
                                  }}
                                  disabled={!showEditSmartSuggestionButton()}
                                />
                                {customAppMacroErrors[index].redirectionUrl ? <small className="text-danger font-weight-bold">{customAppMacroErrors[index].redirectionUrl}</small> : null}
                              </FormGroup>
                            </Col>
                            {(details.title?.includes("__KWD__")
                              || details.titleLong?.includes("__KWD__")
                              || creativeUIDetails.uiTilesDetails?.title?.includes("__KWD__")
                              || creativeUIDetails.uiDetailsCardDetails?.title?.includes("__KWD__")
                              || creativeUIDetails.uiProductsCardDetails?.title?.includes("__KWD__")
                              || creativeUIDetails.uiProductsCardTwoDetails?.title?.includes("__KWD__")
                              || customAppDetails[index].clickUrl?.includes("__KWD__")) && (
                                <Col lg={4} md={6}>
                                  <FormGroup className="d-flex align-items-center">
                                    <Label
                                      className="font-weight-bolder mb-0"
                                      style={{ fontSize: "14px" }}
                                    >
                                      Is Dynamic{" "}
                                      <ToolTip
                                        data="Set true if this ad supports user intent based title replacement and deep links for the redirection, if true works only on typing state when ad server version >= 6."
                                        id="isCustomAppDynamicTooltip"
                                      />
                                    </Label>
                                    <div className="custom-control custom-switch">
                                      <CustomInput
                                        type="checkbox"
                                        id={"isCustomAppDynamic" + index}
                                        value={customAppDetails[index].isDynamic}
                                        onChange={(e) => {
                                          let temp = [...customAppDetails]
                                          temp[index].isDynamic = e.target.checked

                                          setCustomAppDetails(temp)
                                        }}
                                        checked={customAppDetails[index].isDynamic}
                                        disabled={!showEditSmartSuggestionButton()}
                                      />
                                    </div>
                                  </FormGroup>
                                </Col>
                              )}
                          </Row>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col md={6} xs={6}>
                          <h4 style={{ fontSize: "14px" }}> Trackers - For App</h4>
                        </Col>
                        {showEditSmartSuggestionButton() && (
                          <Col>
                            {(customAppDetails[index]?.trackers?.length <= 0) ? (
                              <Button
                                color='success'
                                className="float-right"
                                onClick={() => addCustomAppAdTracker(index)}>Add App Trackers</Button>
                            ) :
                              <Button
                                color='danger'
                                className="float-right"
                                onClick={() => removeAllCustomAppAdTracker(index)}>Remove All App Trackers</Button>
                            }
                          </Col>
                        )}
                        <Col lg="12">
                          {customAppDetails[index]?.trackers.map((item, trackerIndex) => (
                            <Row key={trackerIndex} form className="mb-3 mt-3 pl-4">
                              <Col md={2}>
                                <FormGroup>
                                  <Label
                                    className="font-weight-bolder mb-2"
                                    style={{ fontSize: "14px" }}
                                  >
                                    Tracker Type <span className="text-danger">*</span>
                                  </Label>
                                  <Select
                                    id="type"
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    name="type"
                                    placeholder="Select Type"
                                    value={item.type}
                                    onChange={(event) =>
                                      handleCustomAppTrackerChange(event, trackerIndex, index)
                                    }
                                    options={trackerTypeOptions}
                                    components={{
                                      Input:
                                        (item.type)
                                          ? notRequiredComponent
                                          : requiredComponent,
                                    }}
                                    isDisabled={!showEditSmartSuggestionButton()}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={9}>
                                <FormGroup>
                                  <Label
                                    className="font-weight-bolder mb-2"
                                    style={{ fontSize: "14px" }}
                                  >
                                    URL <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="textarea"
                                    name="url"
                                    placeholder="Enter URL"
                                    value={item.url}
                                    onChange={(event) => {
                                      handleCustomAppTrackerChange(event, trackerIndex, index)
                                      const msg = validateMacros(event.target?.value)
                                      let temp = JSON.parse(JSON.stringify(customAppMacroErrors))
                                      temp[index].trackers[trackerIndex] = msg
                                      setCustomAppMacroErrors(temp)
                                    }}
                                    required
                                    style={{ height: '32px' }}
                                    disabled={!showEditSmartSuggestionButton()}
                                  />
                                  {customAppMacroErrors[index].trackers ? <small className="text-danger font-weight-bold">{customAppMacroErrors[index].trackers[trackerIndex]}</small> : null}
                                </FormGroup>
                              </Col>
                              {showEditSmartSuggestionButton() && (
                                <Col md={1}>
                                  {trackerIndex === 0 ? (
                                    <Button
                                      className="mt-4"
                                      onClick={() => addCustomAppAdTracker(index)}
                                      color="transparent">
                                      <FaPlus />
                                    </Button>
                                  ) : (
                                    <Button
                                      className="mt-4"
                                      onClick={() => removeCustomAppAdTracker(index, trackerIndex)}
                                      color="transparent">
                                      <FaMinus />
                                    </Button>
                                  )}
                                </Col>
                              )}
                            </Row>
                          ))}
                        </Col>
                      </Row>
                    </div>);

                  })}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Row className="mb-3">
                <Col md={6} xs={6}>
                  <h3>Misc Visibility Settings</h3>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <Row form>
                    <Col lg={4} md={6}>
                      <FormGroup className="d-flex align-items-center">
                        <Label
                          className="font-weight-bolder mb-0"
                          style={{ fontSize: "14px" }}
                        >
                          Present in Typing State
                        </Label>
                        <div className="custom-control custom-switch">
                          <CustomInput
                            type="checkbox"
                            id="presentInTypingState"
                            value={visibilitySettings.presentInTypingState}
                            onChange={handleVisiblitySettingsChange}
                            checked={visibilitySettings.presentInTypingState}
                            disabled={!showEditSmartSuggestionButton()}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                      <FormGroup className="d-flex align-items-center">
                        <Label
                          className="font-weight-bolder mb-0"
                          style={{ fontSize: "14px" }}
                        >
                          Present in Non-Typing State
                        </Label>
                        <div className="custom-control custom-switch">
                          <CustomInput
                            type="checkbox"
                            id="presentInNonTypingState"
                            value={visibilitySettings.presentInNonTypingState}
                            onChange={handleVisiblitySettingsChange}
                            checked={visibilitySettings.presentInNonTypingState}
                            disabled={!showEditSmartSuggestionButton()}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                      <FormGroup className="d-flex align-items-center">
                        <Label
                          className="font-weight-bolder mb-0"
                          style={{ fontSize: "14px" }}
                        >
                          Show ad even if the app is installed
                        </Label>
                        <div className="custom-control custom-switch">
                          <CustomInput
                            type="checkbox"
                            id="showAd"
                            value={visibilitySettings.showAd}
                            onChange={handleVisiblitySettingsChange}
                            checked={visibilitySettings.showAd}
                            disabled={!showEditSmartSuggestionButton()}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                      <FormGroup className="d-flex align-items-center">
                        <Label
                          className="font-weight-bolder mb-0"
                          style={{ fontSize: "14px" }}
                        >
                          Show ad only if the app is installed
                        </Label>
                        <div className="custom-control custom-switch">
                          <CustomInput
                            type="checkbox"
                            id="showAdOnlyIfAppIsInstalled"
                            value={visibilitySettings.showAdOnlyIfAppIsInstalled}
                            onChange={handleVisiblitySettingsChange}
                            checked={visibilitySettings.showAdOnlyIfAppIsInstalled}
                            disabled={!showEditSmartSuggestionButton()}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                      <FormGroup className="d-flex align-items-center">
                        <Label
                          className="font-weight-bolder mb-0"
                          style={{ fontSize: "14px" }}
                        >
                          Allow duplicate versions{" "}
                          <ToolTip
                            data="Enable this option if you want to show multiple cards of same campaign on the same placement"
                            id="allowDuplicateTooltip"
                          />
                        </Label>
                        <div className="custom-control custom-switch">
                          <CustomInput
                            type="checkbox"
                            id="allowDuplication"
                            value={visibilitySettings.allowDuplication}
                            onChange={handleVisiblitySettingsChange}
                            checked={visibilitySettings.allowDuplication}
                            disabled={!showEditSmartSuggestionButton()}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Related Categories
                        </Label>
                        <Select
                          id="relatedCategories"
                          isMulti
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="relatedCategories"
                          placeholder="Select Related Categories"
                          value={visibilitySettings.relatedCategories}
                          onChange={(event) => {
                            setVisibilitySettings((prevState) => ({
                              ...prevState,
                              relatedCategories: event,
                            }))
                          }}
                          options={categoriesOptions}
                          isDisabled={!showEditSmartSuggestionButton()}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Blacklisted Packages <small className="text-info">(If the app's name is not listed in the provided options, please enter the app package name, e.g., com.facebook.katana)</small>
                        </Label>
                        <CreatableSelect
                          id="blacklistedPackages"
                          isMulti
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="blacklistedPackages"
                          placeholder="Select Blacklisted Packages"
                          value={visibilitySettings.blacklistedPackages}
                          onChange={(event) => {
                            setVisibilitySettings((prevState) => ({
                              ...prevState,
                              blacklistedPackages: event,
                            }))
                          }}
                          options={packagesOptions}
                          isDisabled={!showEditSmartSuggestionButton()}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={3} md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Whitelist Keywords (Exact Match) {" "}
                          <ToolTip
                            data="Ad shows only when the exact keyword is typed (e.g., 'hello' triggers the ad, but 'hell' does not)."
                            id="whitelistExactMatchTooltip"
                          />
                          <div>
                            <small className="text-info">(Max 20 keywords)</small>
                          </div>
                        </Label>
                        <CreatableSelect
                          id="whitelistExact"
                          isMulti
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="whitelistExact"
                          placeholder="Enter exact keyword"
                          value={keywords.whitelistExact}
                          onChange={(selectedOptions) => {
                            const limitedOptions = selectedOptions ? selectedOptions.slice(0, maxKeywords) : [];
                            setKeywords((prevState) => ({
                              ...prevState,
                              whitelistExact: limitedOptions
                            }));
                          }}
                          disabled={!showEditSmartSuggestionButton()}
                          components={{
                            DropdownIndicator: null,
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={3} md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Whitelist Keywords (Autocomplete Match) {" "}
                          <ToolTip
                            data="Ad shows when any prefix of the keyword is typed (e.g., 'hello' triggers the ad from 'h' to 'hello')."
                            id="whitelistAutocompleteMatchTooltip"
                          />
                          <div>
                            <small className="text-info">(Max 20 keywords)</small>
                          </div>
                        </Label>
                        <CreatableSelect
                          id="whitelistAutocomplete"
                          isMulti
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="whitelistAutocomplete"
                          placeholder="Enter autocomplete keyword"
                          value={keywords.whitelistAutocomplete}
                          onChange={(selectedOptions) => {
                            const limitedOptions = selectedOptions ? selectedOptions.slice(0, maxKeywords) : [];
                            setKeywords((prevState) => ({
                              ...prevState,
                              whitelistAutocomplete: limitedOptions
                            }));
                          }}
                          disabled={!showEditSmartSuggestionButton()}
                          components={{
                            DropdownIndicator: null,
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={3} md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Blacklist Keywords (Exact Match) {" "}
                          <ToolTip
                            data="Ad is blocked when the exact keyword is typed (e.g., 'hello' blocks the ad, but 'hel' does not)."
                            id="blacklistExactMatchTooltip"
                          />
                          <div>
                            <small className="text-info">(Max 20 keywords)</small>
                          </div>
                        </Label>
                        <CreatableSelect
                          id="blacklistExact"
                          isMulti
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="blacklistExact"
                          placeholder="Enter exact keyword"
                          value={keywords.blacklistExact}
                          onChange={(selectedOptions) => {
                            const limitedOptions = selectedOptions ? selectedOptions.slice(0, maxKeywords) : [];
                            setKeywords((prevState) => ({
                              ...prevState,
                              blacklistExact: limitedOptions
                            }));
                          }}
                          disabled={!showEditSmartSuggestionButton()}
                          components={{
                            DropdownIndicator: null,
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={3} md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Blacklist Keywords (Autocomplete Match) {" "}
                          <ToolTip
                            data="Ad is blocked when any prefix of the keyword is typed (e.g., 'hello' blocks the ad from 'h' to 'hello')."
                            id="blacklistAutocompleteMatchTooltip"
                          />
                          <div>
                            <small className="text-info">(Max 20 keywords)</small>
                          </div>
                        </Label>
                        <CreatableSelect
                          id="blacklistAutocomplete"
                          isMulti
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="blacklistAutocomplete"
                          placeholder="Enter autocomplete keyword"
                          value={keywords.blacklistAutocomplete}
                          onChange={(selectedOptions) => {
                            const limitedOptions = selectedOptions ? selectedOptions.slice(0, maxKeywords) : [];
                            setKeywords((prevState) => ({
                              ...prevState,
                              blacklistAutocomplete: limitedOptions
                            }));
                          }}
                          disabled={!showEditSmartSuggestionButton()}
                          components={{
                            DropdownIndicator: null,
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {details.type?.value === "chatbot" ? (
            <Row>
              <Col lg="12">
                <Row className="mb-3">
                  <Col md={6} xs={6}>
                    <FormGroup className="d-flex align-items-center mb-0">
                      <Label
                        className="font-weight-bolder mb-0"
                      >
                        <h3>Auto Send Chatbot Settings</h3>
                      </Label>
                      <div className="custom-control custom-switch">
                        <CustomInput
                          type="checkbox"
                          id="isAutoSendChatbotSettingsEnable"
                          value={isAutoSendChatbotSettingsEnable}
                          onChange={() => setIsAutoSendChatbotSettingsEnable(!isAutoSendChatbotSettingsEnable)}
                          checked={isAutoSendChatbotSettingsEnable}
                          disabled={!showEditSmartSuggestionButton()}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                {isAutoSendChatbotSettingsEnable && (
                  <Card>
                    <CardBody>
                      <Row form>
                        <Col md={12}>
                          <FormGroup>
                            <Label
                              className="font-weight-bolder mb-2"
                              style={{ fontSize: "14px" }}
                            >
                              Package Names <span className="text-danger">*</span>
                            </Label>
                            <CreatableSelect
                              id="packageNames"
                              isMulti
                              className="react-select-container"
                              classNamePrefix="react-select"
                              name="packageNames"
                              placeholder="Select Package Names or Create New Package"
                              value={chatbotSettings.packageNames}
                              onChange={(event) => {
                                setChatbotSettings((prevState) => ({
                                  ...prevState,
                                  packageNames: event,
                                }))
                              }}
                              options={packageNameOptions}
                              isDisabled={!showEditSmartSuggestionButton()}
                              components={{
                                Input:
                                  (chatbotSettings.packageNames && chatbotSettings.packageNames?.length > 0)
                                    ? notRequiredComponent
                                    : requiredComponent,
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col md={6}>
                          <FormGroup>
                            <Label
                              className="font-weight-bolder mb-2"
                              style={{ fontSize: "14px" }}
                            >
                              Message Text <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              name="messageText"
                              placeholder="Enter Message Text"
                              value={chatbotSettings.messageText}
                              onChange={handleChatBotSettingsChange}
                              disabled={!showEditSmartSuggestionButton()}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label
                              className="font-weight-bolder mb-2"
                              style={{ fontSize: "14px" }}
                            >
                              Trigger Timeout <small className="text-info">(in milliseconds (ms))</small>
                            </Label>
                            <Input
                              type="number"
                              name="triggerTimeout"
                              min={10000}
                              placeholder="Enter Trigger Timeout"
                              value={chatbotSettings.triggerTimeout}
                              onChange={handleChatBotSettingsChange}
                              disabled={!showEditSmartSuggestionButton()}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Row>
          ) : null
          }

          {showEditSmartSuggestionButton() && (
            <Button className={`d-block ml-3 mt-2 ${props.match.params.id && !props.match.path?.includes("duplicate") ? "fixed-bottom-right" : ""}`} color="primary">
              {(props.match.params.id && !props.match.path?.includes("duplicate")) ? "Submit" : "Save & Proceed to Targeting Rules"}
            </Button>
          )}
        </Form>
      )}
      <SupportedMacros
        show={isSupportedMacrosModalVisible}
        onHide={hideSupportedMacrosModal}
      />
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={confirmAction}
        onConfirmationTrue={() => {
          setConfirmationModalIsOpen(false);
          if (confirmAction === smartSuggestionsAllActions.submitSmartSuggestion) handleSubmit();
        }}
        onHide={() => setConfirmationModalIsOpen(false)}
      />
      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={"Success"}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={"Error"}
        modaltext={failureModalText}
      />
    </Container>
  );
};

export default ManageSmartSuggestions;

import React, { useState, useEffect, useCallback } from "react";
import ResponseModal from "../ResponseModal";
import { generateApiKey } from 'generate-api-key';
import { taasAllowedEndpoints, clientResponses, showGenerateApiKeyButton, booleanOptions, contentPrefrencesOptions, showActivateClientButton } from "../../../config/configTaasDashboard";
import { useParams, useLocation } from 'react-router-dom';
import { successModalHeading } from '../../../config';
import { errorModalHeading } from '../../../config/config';
import {
  error, errorDescription
} from '../../../utilities/commonUtil';
import { useHistory } from "react-router-dom"
import Select, { components } from "react-select";
import Routes from "../../../routes/index";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CustomInput,
} from "reactstrap";
import { createUpdateClient, fetchTaasClient, fetchTaasPartners } from "../../../utilities/apiUtils/taasDashboard";

const ClientForm = () => {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname.split('/');
  const pageType = currentPath[currentPath.length - 1];
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");
  const [allowedEndpointsOptions, setAllowedEndpointsOptions] = useState([]);
  const [partnerOptions, setPartnerOptions] = useState([]);

  useEffect(() => {
    setAllowedEndpointsOptions(taasAllowedEndpoints.map((endpoint, index) => ({
      value: endpoint, label: endpoint
    })))
    getPartners()
  }, []);

  const [data, setData] = useState({
    name: '',
    allowedEndpoints: [],
    apiKey: '',
    partner: [],
    contentPreferences: contentPrefrencesOptions[0],
    identifier: '',
    randomizeResults: booleanOptions[1],
    enableCustomContentLimit: booleanOptions[1],
    customContentLimit: 0,
    enableWatermark: booleanOptions[0],
    enablePublicStorageContent: booleanOptions[0],
    enableContentURLRegeneration: booleanOptions[1],
    remarks: '',
    activatedAt: false,
    deactivatedAt: true,
  })

  const handleChange = (event) => {
    const { name, value } = event.target
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const createIdentifier = (inputString) => {
    const words = inputString && inputString.split(/[\s_]+/);
    if (words) {
      for (let i = 1; i < words.length; i++) {
        if (words[i][0])
          words[i] = words[i][0].toUpperCase() + words[i].substring(1);
      }
      return data.identifier = words.join('');
    }
  }

  const getPartners = async () => {
    try {
      let response = await fetchTaasPartners()
      if (response?.length > 0) {
        setPartnerOptions(response.map(d => ({
          value: d.id,
          label: d.name,
        })))
      }
      else if (response & response.errorCode) {
        showErrorModal(errorDescription(response.errorCode));
      }
    } catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  }

  const handleCheckboxChange = (event) => {
    const { checked } = event.target;
    setData({
      ...data,
      activatedAt: checked,
      deactivatedAt: !checked,
    });
  };

  let fetchClient = useCallback(async (id) => {
    try {
      let client = await fetchTaasClient(id);
      if (client?.length > 0) {
        client = client[0];
        setData({
          clientId: client?.id,
          name: client?.name,
          apiKey: client?.apiKey.APIKey,
          allowedEndpoints: client?.allowedEndpoints?.map((d) => ({
            label: allowedEndpointsOptions.find(option => option.value === d.allowedEndpoint)?.label ?? d.allowedEndpoint,
            value: d.allowedEndpoint
          })),
          partner: {
            label: partnerOptions.find(option => option.value === client?.partner?.name)?.label ?? client?.partner?.name,
            value: client?.partnerId
          },
          contentPreferences: {
            label: contentPrefrencesOptions.find(option => option.value === client?.contentPreferences)?.label ?? client?.contentPreferences,
            value: client?.contentPreferences
          },
          identifier: client?.identifier,
          randomizeResults: {
            label: booleanOptions.find(option => option.value === client?.randomizeResults?.toString())?.label ?? client?.randomizeResults?.toString(),
            value: client?.randomizeResults?.toString()
          },
          enableCustomContentLimit: {
            label: booleanOptions.find(option => option.value === client?.enableCustomContentLimit?.toString())?.label ?? client?.enableCustomContentLimit?.toString(),
            value: client?.enableCustomContentLimit?.toString()
          },
          customContentLimit: client?.customContentLimit,
          enableWatermark: {
            label: booleanOptions.find(option => option.value === client?.enableWatermark?.toString())?.label ?? client?.enableWatermark?.toString(),
            value: client?.enableWatermark?.toString()
          },
          enablePublicStorageContent: {
            label: booleanOptions.find(option => option.value === client?.enablePublicStorageContent?.toString())?.label ?? client?.enablePublicStorageContent?.toString(),
            value: client?.enablePublicStorageContent?.toString()
          },
          enableContentURLRegeneration: {
            label: booleanOptions.find(option => option.value === client?.enableContentUrlRegeneration?.toString())?.label ?? client?.enableContentUrlRegeneration?.toString(),
            value: client?.enableContentUrlRegeneration?.toString()
          },
          remarks: client?.remarks,
          activatedAt: new Date(client?.deactivatedAt) > new Date() || client?.deactivatedAt === null ? true : false,
          deactivatedAt: new Date(client?.deactivatedAt) > new Date() || client?.deactivatedAt === null ? false : true,
        });
      }
      else {
        setData({
          name: '',
          allowedEndpoints: [],
          apiKey: '',
          partner: [],
          contentPreferences: contentPrefrencesOptions[0],
          identifier: '',
          randomizeResults: booleanOptions[1],
          enableCustomContentLimit: booleanOptions[1],
          customContentLimit: 0,
          enableWatermark: booleanOptions[0],
          enablePublicStorageContent: booleanOptions[0],
          enableContentURLRegeneration: booleanOptions[1],
          remarks: '',
          activatedAt: false,
          deactivatedAt: true,
        })
      }
    }
    catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  }, []);

  useEffect(() => {
    if (pageType !== "create" && id) {
      fetchClient(id);
    }
  }, [id, fetchClient, pageType]);

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let successModalClose = () => {
    setSuccessModalDisplay(false);
    history.goBack();
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  const requiredComponent = (props) => (
    <div>
      <components.Input {...props} required={true} />
    </div>
  );

  const notRequiredComponent = (props) => (
    <div>
      <components.Input {...props} required={false} />
    </div>
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    data.clientId && formData.append("clientId", data.clientId);
    formData.append("name", data.name);
    formData.append("allowedEndpoints", data.allowedEndpoints?.map(obj => obj['value']).join(','));
    formData.append("apiKey", data.apiKey);
    formData.append("partnerId", data.partner?.value ?? '');
    formData.append("contentPreferences", data.contentPreferences?.value);
    formData.append("identifier", data.identifier);
    formData.append("randomizeResults", data.randomizeResults?.value);
    formData.append("enableCustomContentLimit", data.enableCustomContentLimit?.value);
    formData.append("customContentLimit", data.customContentLimit);
    formData.append("enableWatermark", data.enableWatermark?.value);
    formData.append("enablePublicStorageContent", data.enablePublicStorageContent?.value);
    formData.append("enableContentURLRegeneration", data.enableContentURLRegeneration?.value);
    formData.append("remarks", data.remarks);
    formData.append("activatedAt", data.activatedAt);
    formData.append("deactivatedAt", data.deactivatedAt);
    try {
      if (!data.apiKey) {
        showErrorModal("Api key is empty");
      }
      else {
        let response = await createUpdateClient(formData)
        if (response.code === "clientAdded") {
          showSuccessModal(clientResponses.addedSuccessfully);
        } else if (response.code === "clientUpdated") {
          showSuccessModal(clientResponses.updatedSuccessfully);
        }
        else {
          if (response.errorDescription) {
            showErrorModal(response.errorDescription)
          }
        }
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  }
  return (
    <>
      <h1 className="h3 mb-3">
        {pageType !== "create" ? "Edit Client" : "Create Client"}
      </h1>
      <Card>
        <CardBody>
          <Form onSubmit={(e) => handleSubmit(e)}>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Name <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    value={data.name}
                    placeholder="Enter Client Name"
                    required
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Allowed Endpoints <span className="text-danger">*</span></Label>
                  <Select
                    id="allowedEndpoints"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    placeholder="Select Allowed Endpoints"
                    name="allowedEndpoints"
                    value={data.allowedEndpoints}
                    onChange={(event) => {
                      setData((prevState) => ({
                        ...prevState,
                        allowedEndpoints: event,
                      }))
                    }}
                    components={{
                      Input:
                        (data.allowedEndpoints && data.allowedEndpoints?.length > 0)
                          ? notRequiredComponent
                          : requiredComponent,
                    }}
                    options={allowedEndpointsOptions}
                    isMulti
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    API Key <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="apiKey"
                    value={data.apiKey}
                    placeholder="API Key"
                    required={!data.apiKey}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Partner</Label>
                  <Select
                    id="partner"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    placeholder="Select Partner"
                    name="partner"
                    value={data.partner}
                    onChange={(event) => {
                      setData((prevState) => ({
                        ...prevState,
                        partner: event,
                      }))
                    }}
                    options={partnerOptions}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col className="align-items-right mb-2">
                {showGenerateApiKeyButton() ? (
                  <>
                    <Button
                      className="btn-secondary"
                      onClick={(event) => {
                        let value = generateApiKey({ method: 'bytes', length: 32 });
                        setData((prevState) => ({
                          ...prevState,
                          apiKey: value,
                        }))
                      }}
                    >
                      Generate
                    </Button>
                    {pageType !== "create" && <div className="mt-2" style={{ color: "red" }}>* This will replace the previous API key. </div>} </>) : null}
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Content Preferences
                  </Label>
                  <Select
                    className="react-select-container"
                    id="statusSelect"
                    classNamePrefix="react-select"
                    name="contentPreferences"
                    placeholder="Select Content Preferences"
                    value={data.contentPreferences}
                    onChange={(event) => {
                      setData((prevState) => ({
                        ...prevState,
                        contentPreferences: event,
                      }))
                    }}
                    options={contentPrefrencesOptions}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Identifier</Label>
                  <Input
                    type="text"
                    name="identifier"
                    disabled
                    value={createIdentifier(data.name)}
                    placeholder="Identifier"
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Randomize Results
                  </Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name="randomizeResults"
                    placeholder="Select Randomize Results"
                    value={data.randomizeResults}
                    onChange={(event) => {
                      setData((prevState) => ({
                        ...prevState,
                        randomizeResults: event,
                      }))
                    }}
                    options={booleanOptions}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Enable Custom Content Limit</Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name="enableCustomContentLimit"
                    placeholder="Select Enable Custom Content Limit"
                    value={data.enableCustomContentLimit}
                    onChange={(event) => {
                      setData((prevState) => ({
                        ...prevState,
                        enableCustomContentLimit: event,
                      }))
                    }}
                    options={booleanOptions}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Custom Content Limit
                  </Label>
                  <Input
                    type="number"
                    name="customContentLimit"
                    value={data.customContentLimit}
                    placeholder="Enter Custom Content Limit"
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Enable Watermark</Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    placeholder="Select Enable Watermark"
                    name="enableWatermark"
                    value={data.enableWatermark}
                    onChange={(event) => {
                      setData((prevState) => ({
                        ...prevState,
                        enableWatermark: event,
                      }))
                    }}
                    options={booleanOptions}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Enable Public Storage Content
                  </Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name="enablePublicStorageContent"
                    placeholder="Select Enable Public Storage Content"
                    value={data.enablePublicStorageContent}
                    onChange={(event) => {
                      setData((prevState) => ({
                        ...prevState,
                        enablePublicStorageContent: event,
                      }))
                    }}
                    options={booleanOptions}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Enable Content URL Regeneration</Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name="enableContentUrlRegeneration"
                    placeholder="Select Enable Content URL Regeneration"
                    value={data.enableContentURLRegeneration}
                    onChange={(event) => {
                      setData((prevState) => ({
                        ...prevState,
                        enableContentURLRegeneration: event,
                      }))
                    }}
                    options={booleanOptions}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label>Remarks</Label>
                  <Input
                    type="textarea"
                    name="remarks"
                    value={data.remarks}
                    placeholder="Add Remakrs"
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            {pageType === "create" && showActivateClientButton() ?
              <Row>
                <Col xs={12}>
                  <FormGroup className="d-flex align-items-center">
                    <Label
                      className="font-weight-bolder mb-0"
                      style={{ fontSize: "14px" }}
                    >
                      Activate Client
                    </Label>
                    <div className="custom-control custom-switch">
                      <CustomInput
                        type="checkbox"
                        id="activateClientByDefault"
                        onChange={handleCheckboxChange}
                        checked={data.activatedAt}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row> : ''}
            <Button color="primary">Submit</Button>
          </Form>
          <ResponseModal
            show={successModalDisplay}
            onHide={successModalClose}
            modalheading={successModalHeading}
            modaltext={successModalText}
          />
          <ResponseModal
            show={failureModalDisplay}
            onHide={failureModalClose}
            modalheading={errorModalHeading}
            modaltext={failureModalText}
          />
        </CardBody>
      </Card>
    </>
  );
};
const ManageClient = () => (
  <Container fluid className="p-0">
    <Row>
      <Col lg="12">
        <ClientForm />
      </Col>
    </Row>
  </Container>
);
export default ManageClient;

import React, { useState, useEffect, useCallback } from "react";
import ResponseModal from "../ResponseModal";
import { useParams, useLocation } from 'react-router-dom';
import { successModalHeading } from '../../../config';
import { errorModalHeading } from '../../../config/config';
import {
    error, errorDescription
} from '../../../utilities/commonUtil';
import { useHistory } from "react-router-dom"
import Select from "react-select";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import { GetAIPlacementDetails, CreateAIPlacement, UpdateAIPlacement } from "../../../utilities/apiUtils/genAIDashboard";
import { deviceTypeOptions, placementResponses } from "../../../config/configGenAIDashboard";
import ToolTip from "../../../utilities/ToolTip";
import Loading from "../../../components/Loading";

const PlacementForm = () => {
    const { id } = useParams();
    const history = useHistory();
    const location = useLocation();
    const currentPath = location.pathname.split('/');
    const pageType = currentPath[currentPath.length - 1];
    const [successModalDisplay, setSuccessModalDisplay] = useState(false);
    const [successModalText, setSuccessModalText] = useState("");
    const [failureModalDisplay, setFailureModalDisplay] = useState(false);
    const [failureModalText, setFailureModalText] = useState("");
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
        name: '',
        deviceType: deviceTypeOptions[0],
        description: '',
        minAppVersion: '',
        maxAppVersion: '',
    })

    const handleChange = (event) => {
        const { name, value } = event.target
        setData((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    let fetchPlacement = useCallback(async (placementId) => {
        try {
            let response = await GetAIPlacementDetails(placementId);
            if (response) {
                setData({
                    name: response.name,
                    deviceType: {
                        label: deviceTypeOptions.find(option => option.value === response?.deviceType)?.label ?? response?.deviceType,
                        value: response?.deviceType
                    },
                    description: response.description,
                    minAppVersion: response.minAppVersion,
                    maxAppVersion: response.maxAppVersion,
                });
            }
            else {
                setData({
                    name: '',
                    deviceType: deviceTypeOptions[0],
                    description: '',
                    minAppVersion: '',
                    maxAppVersion: '',
                });
            }
        }
        catch (err) {
            showErrorModal(errorDescription(error.unexpectedError));
        }
    }, []);

    useEffect(() => {
        if (pageType !== "create") {
            fetchPlacement(id);
        }
    }, [id, fetchPlacement, pageType]);

    let showSuccessModal = (text) => {
        setSuccessModalText(text);
        setSuccessModalDisplay(true);
    };

    let successModalClose = () => {
        setSuccessModalDisplay(false);
        history.goBack();
    };

    let failureModalClose = () => {
        setFailureModalDisplay(false);
    };

    let showErrorModal = (text) => {
        setFailureModalText(text);
        setFailureModalDisplay(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const formData = {
                name: data.name,
                deviceType: data.deviceType.value,
                description: data.description,
                minAppVersion: data.minAppVersion?.toString(),
                maxAppVersion: data.maxAppVersion !== "" && data.maxAppVersion !== null
                    ? data.maxAppVersion?.toString()
                    : null,
            };
            if (id) {
                let response = await UpdateAIPlacement(formData, id)
                if (response?.status === "aiPoweredBarPlacementEdited") {
                    showSuccessModal(placementResponses.updatedSuccessfully);
                }
                else {
                    if (response?.errorCode) {
                        showErrorModal(response?.errorMessage)
                    }
                }
            }
            else {
                let response = await CreateAIPlacement(formData)
                if (response?.status === "aiPoweredBarPlacementCreated") {
                    showSuccessModal(placementResponses.addedSuccessfully);
                }
                else {
                    if (response?.errorCode) {
                        showErrorModal(response?.errorMessage)
                    }
                }
            }
        } catch (err) {
            setFailureModalDisplay(true);
            setFailureModalText(errorDescription(error.unexpectedError));
        }
        finally {
            setLoading(false);
        }
    }


    return (
        <>
            {loading ? <Loading /> :
                <Card>
                    <CardBody>
                        <h1 className="h3 mb-3">
                            {pageType === "create" ? "Create Placement" : "Edit Placement"}
                        </h1>
                        <Form onSubmit={(e) => handleSubmit(e)}>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>
                                            Name <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="text"
                                            name="name"
                                            value={data.name}
                                            placeholder="Enter Name"
                                            required
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>
                                            Device Type <span className="text-danger">*</span>
                                        </Label>
                                        <Select
                                            id="version"
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            placeholder="Select Device Type"
                                            name="deviceType"
                                            required
                                            value={data.deviceType}
                                            onChange={(event) => {
                                                setData((prevState) => ({
                                                    ...prevState,
                                                    deviceType: event,
                                                }))
                                            }}
                                            options={deviceTypeOptions}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>
                                            Min App Version <span className="text-danger">*</span><ToolTip data={"Minimum required App version of the Placement. for eg: 1.000"} />
                                        </Label>
                                        <Input
                                            type="number"
                                            name="minAppVersion"
                                            value={data.minAppVersion}
                                            required
                                            placeholder="Enter Min App Version"
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>
                                            Max App Version
                                        </Label>
                                        <Input
                                            type="number"
                                            name="maxAppVersion"
                                            value={data.maxAppVersion}
                                            placeholder="Enter Max App Version"
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>
                                            Description
                                        </Label>
                                        <Input
                                            type="textarea"
                                            name="description"
                                            value={data.description}
                                            placeholder="Enter Description"
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Button color="primary">Submit</Button>
                            <ResponseModal
                                show={successModalDisplay}
                                onHide={successModalClose}
                                modalheading={successModalHeading}
                                modaltext={successModalText}
                            />
                            <ResponseModal
                                show={failureModalDisplay}
                                onHide={failureModalClose}
                                modalheading={errorModalHeading}
                                modaltext={failureModalText}
                            />
                        </Form>
                    </CardBody>
                </Card>
            }
        </>
    );
};
const CreatePlacement = () => (
    <Container fluid className="p-0">
        <Row>
            <Col lg="12">
                <PlacementForm />
            </Col>
        </Row>
    </Container>
);
export default CreatePlacement;

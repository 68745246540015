import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import ConfirmationModal from "../ConfirmationModal";
import ResponseModal from "../ResponseModal";
import {
  deactivatetask,
  fetchTasks,
  publishtask,
  Activatetask,
} from "../../../utilities/apiUtils/tasks";
import { tableColumns } from "./tableColumn";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import Search from "../../../components/Search";

import Loading from "../../../components/Loading";
import { allActions } from "../../../utilities/commonUtil";
import { successModalHeading, errorModalHeading } from "../../../config/config";
import { statusOptions, processDateAndStatus } from "../../../config/tasks";

const Tasks = () => {
  const [loading, setLoading] = useState(true);
  const [tasks, settasks] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [successModalText, setSuccessModalText] = useState("");
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [action, setAction] = useState("");

  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [statusFilter, setStatusFilter] = useState(""); // Set the default status filter to "ALL"
  let onTableChange = (type, newState) => {};

  const fetchtasksData = async () => {
    try {
      let tasksData = await fetchTasks();

      if (tasksData?.tasks !== undefined) {
        tasksData.tasks.forEach((item) => {
          item = processDateAndStatus(item);
        });
        // Set the tasks state with the processed tasks
        settasks(tasksData.tasks);
        setLoading(false);
      }
    } catch (error) {
      setFailureModalText(error.message);
      setFailureModalDisplay(true);
      setLoading(false);
    }
  };

  const handleClick = () => {
    setStatusFilter("");
    setNameFilter("");
  };

  const ClearButton = () => {
    return (
      <Button
        style={{ float: "right" }}
        className="form-control-lg"
        onClick={handleClick}
      >
        Reset Filters
      </Button>
    );
  };

  const filtertasks = () => {
    let items = tasks;

    if (statusFilter.value) {
      items = items.filter((item) => item.status === statusFilter.value);
    }

    if (nameFilter) {
      items = items.filter((item) =>
        item.name?.toLowerCase().includes(nameFilter.toLowerCase())
      );
    }

    return items;
  };

  useEffect(() => {
    fetchtasksData();
    if (sessionStorage.getItem("tasksFilterName"))
      setNameFilter(sessionStorage.getItem("tasksFilterName"));
    if (sessionStorage.getItem("tasksFilterStatus"))
      setStatusFilter({
        value: sessionStorage.getItem("tasksFilterStatus") ?? "",
        label:
          statusOptions.find(
            (option) =>
              option.value === sessionStorage.getItem("tasksFilterStatus")
          )?.label ?? "",
      });
  }, []);

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    setLoading(true);
    fetchtasksData();
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  const handlePublish = async (taskId) => {
    try {
      const response = await publishtask(taskId);
      if (response.message === "Task published") {
        let text = "Task has been Published";
        showSuccessModal(text);
        fetchtasksData(); // Refresh the tasks data after successful publishing
      } else {
        console.error(`Failed to publish task (${response.published_at})`);
      }
    } catch (error) {
      console.error(error);
      setFailureModalText("Failed to publish task");
      setFailureModalDisplay(true);
    }
  };

  const handleDeactivate = async (taskId) => {
    try {
      const response = await deactivatetask(taskId);
      if ((response.status = "success")) {
        let text = "Task has been Deactivated";
        showSuccessModal(text);
      } else {
        console.error(`Failed to deactivate task (${response.status})`);
      }
    } catch (error) {
      console.error(error);
      setFailureModalText("Failed to deactivate task");
      setFailureModalDisplay(true);
    }
  };

  const handleActivate = async (taskId) => {
    try {
      const response = await Activatetask(taskId);
      if ((response.status = "success")) {
        let text = "Task has been Activated";
        showSuccessModal(text);
      } else {
        console.error(`Failed to activate task (${response.status})`);
      }
    } catch (error) {
      console.error(error);
      setFailureModalText("Failed to activate task");
      setFailureModalDisplay(true);
    }
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <Link
          className="text-decoration-none"
          to={{
            pathname: `/tasks/edit/${row.id}`,
            state: {
              formType: "edit",
            },
          }}
        >
          <Button className="mx-3">Edit</Button>
        </Link>
        {row.status === "Unpublished" ? (
          <Button
            className="mx-3  btn-success"
            onClick={() => handlePublish(row.id)}
          >
            Publish
          </Button>
        ) : (
          <Button className="mx-3  btn-success" disabled>
            Publish
          </Button>
        )}
        {row.status === "Activated" ? (
          <Button
            className="mx-3  btn-danger"
            onClick={() => handleDeactivate(row.id)}
          >
            Deactivate
          </Button>
        ) : (
          <Button className="mx-3  btn-danger" disabled>
            Deactivate
          </Button>
        )}
        {row.status === "Deactivated" ? (
          <Button
            className="mx-3  btn-success"
            onClick={() => handleActivate(row.id)}
          >
            Activate
          </Button>
        ) : (
          <Button className="mx-3  btn-success" disabled>
            Activate
          </Button>
        )}
        <ConfirmationModal
          show={confirmationModalIsOpen}
          modaltext={action}
          onConfirmationTrue={() => {
            setConfirmationModalIsOpen(false);
            if (action === allActions.deactivate) handleDeactivate(row.id);
            else if (action === allActions.activate) handleActivate(row.id);
            else if (action === allActions.publish) {
              handlePublish(row.id);
            }
          }}
          onHide={() => setConfirmationModalIsOpen(false)}
        />
      </div>
    ),
  };

  return (
    <Container fluid className="p-0">
      <Row>
        <h1 className="mb-4 mt-2 w-50 pl-3">Tasks</h1>
        <Link
          className="d-block ml-auto mb-4 pr-3 text-decoration-none"
          to="/tasks/add"
        >
          <Button color="success" className="ml-auto d-flex btn-success">
            <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
              Add task
            </p>
          </Button>
        </Link>
      </Row>
      <div>
        <Card style={{ overflow: "hidden", paddingBottom: "100px" }}>
          <CardBody>
            <Row className="justify-content-md-center mx-auto mb-4">
              <Col md={6} sm={12} className="mt-3">
                <Select
                  className="react-select-container"
                  id="statusSelect"
                  classNamePrefix="react-select"
                  name="status"
                  placeholder="Select Status"
                  value={statusFilter}
                  onChange={(event) => {
                    setStatusFilter(event);
                    sessionStorage.setItem("tasksFilterStatus", event.value);
                  }}
                  options={statusOptions}
                />
              </Col>
              <Col md={6} xs={5} className="p-0 mt-3">
                <ClearButton>Reset Filters</ClearButton>
              </Col>
            </Row>
            <Row className="justify-content-md-center mx-auto">
              <Col md={12} xs={12}>
                <Search
                  placeholder="Search Name"
                  value={nameFilter}
                  onChange={(e) => {
                    setNameFilter(e.target.value);
                    sessionStorage.setItem("tasksFilterName", e.target.value);
                  }}
                ></Search>
              </Col>
            </Row>
            {loading ? (
              <Loading />
            ) : (
              <CardBody>
                <BootstrapTable
                  bootstrap4={true}
                  wrapperClasses="table-responsive"
                  hover={true}
                  bordered={false}
                  keyField="id"
                  data={filtertasks()}
                  columns={tableColumns}
                  expandRow={expandRow}
                  filter={filterFactory()}
                  pagination={paginationFactory({
                    sizePerPage: 10,
                    sizePerPageList: [5, 10, 25, 50],
                  })}
                  defaultSorted={[{ dataField: "id", order: "asc" }]}
                  onTableChange={onTableChange}
                  remote={{ filter: true }}
                  noDataIndication="No results found"
                />
              </CardBody>
            )}
          </CardBody>
        </Card>
      </div>
      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={successModalHeading}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={errorModalHeading}
        modaltext={failureModalText}
      />
    </Container>
  );
};

export default Tasks;

import { MinPasswordLength } from "../config/UserConfig";
import { Config } from "../config";
import {
  retrieveFromLocalStorage,
  removeFromLocalStorage,
  storeInLocalStorage,
} from "./localStorageUtil";
import { number } from "prop-types";
import moment from "moment";
import md5 from 'md5';
import { capitalize } from "@material-ui/core";

export let tokenExpiryErrorCodes = [
  "invalidAccessToken",
  "tokenNotRecieved",
  "tokenExpired",
];
export let valueTypes = {
  json: "json",
  string: "string",
  integer: "integer",
  float: "float",
  boolean: "boolean",
};
export let appIDs = ["generic", "com.mint.keyboard", "ai.mint.keyboard"];

export let effectTypes = {
  allow: "allow",
  deny: "deny",
};

export let error = {
  tokenExpired: "tokenExpired",
  unauthorized: "unauthorized",
  invalidAccessToken: "invalidAccessToken",
  unexpectedError: "unexpectedError",
  missingLocationRule: "missingLocationRule",
  endDateStartDateError: "endDateStartDateError",
  invalidAuthorization: "invalidAuthorization",
};

export let errorDescription = (value) => {
  let description = "";
  switch (value) {
    case "permissionDenied": {
      description = "You do not have permission for this.";
      break;
    }
    case "invalidAccessToken": {
      description = "Login expired. Try again.";
      break;
    }
    case "invalidAuthorization": {
      description = "Login expired. Try again.";
      break;
    }
    case "tokenExpired": {
      description = "Login expired. Try again.";
      break;
    }
    case "configNotFound": {
      description = "Config not found.";
      break;
    }
    case "invalidID": {
      description = "Config not found or ID does not exist.";
      break;
    }
    case "activated": {
      description = "Config activated successfully.";
      break;
    }
    case "Deactivated": {
      description = "Config deactivated successfully.";
      break;
    }
    case "published": {
      description = "Config published successfully.";
      break;
    }
    case "configNotExists": {
      description = "Config does not exist.";
      break;
    }
    case "configUpdated": {
      description = "Config updated successfully.";
      break;
    }
    case "problemWhileUpdating": {
      description = "Please try again later.";
      break;
    }
    case "invalidJSONType": {
      description = "Not a valid JSON.";
      break;
    }
    case "invalidObjectReceived": {
      description = "Please try again.";
      break;
    }
    case "configCreated": {
      description = "Config created successfully.";
      break;
    }
    case "invalidAppVersion": {
      description = "App Version not correct.";
      break;
    }
    case "invalidMinAppVersion": {
      description = "Invalid Min App Version.";
      break;
    }
    case "invalidMaxAppVersion": {
      description = "Invalid Max App Version.";
      break;
    }
    case "configExists": {
      description = "Config already exists.";
      break;
    }
    case "userExists": {
      description = "User already exists.";
      break;
    }
    case "userDeactivated": {
      description = "User deactivated successfully.";
      break;
    }
    case "userNotFound": {
      description = "User not found.";
      break;
    }
    case "userActivated": {
      description = "User activated successfully.";
      break;
    }
    case "someRoleIDsNotFound": {
      description = "Some role IDs not found. Please try again.";
      break;
    }
    case "somePermissionIdsNotFound": {
      description = "Some permission IDs not found. Please try again.";
      break;
    }
    case "userRolesUpdated": {
      description = "User roles updated successfully.";
      break;
    }
    case "invalidRoleID": {
      description = "Role ID invalid.";
      break;
    }
    case "invalidName": {
      description = "Name invalid.";
      break;
    }
    case "invalidIdentifier": {
      description = "Identifier invalid.";
      break;
    }
    case "invalidDescription": {
      description = "Description invalid.";
      break;
    }
    case "permissionExists": {
      description = "Permission already exists.";
      break;
    }
    case "roleExists": {
      description = "Role already exists.";
      break;
    }
    case "roleUpdated": {
      description = "Role updated successfully.";
      break;
    }
    case "invalidValueForType": {
      description = "Invalid value for type.";
      break;
    }
    case "stickerPackNotFound": {
      description = "Sticker Pack not found.";
      break;
    }
    case "invalidStickerPackId": {
      description = "Sticker Pack ID invalid.";
      break;
    }
    case "stickerNotFound": {
      description = "Sticker not found.";
      break;
    }
    case "gifPackNotFound": {
      description = "GIF Pack not found.";
      break;
    }
    case "invalidGifPackId": {
      description = "GIF Pack ID invalid.";
      break;
    }
    case "gifNotFound": {
      description = "GIF not found.";
      break;
    }
    case "invalidAutoDownloadRules": {
      description =
        "Auto-Download Location Rules Should Be A Subset of Location Rules.";
      break;
    }
    case "missingLocationRule": {
      description =
        "Atleast One Location Rule must be present along with Age & Gender Targeting Rules.";
      break;
    }
    case "duplicatePrompt": {
      description = "There is already an active Keyboard Prompt of same type";
      break;
    }
    case "badRequest": {
      description = "Data payload is invalid";
      break;
    }
    case "duplicatePackageSet": {
      description = "Package set already exists";
      break;
    }
    case "invalidDate": {
      description =
        "The date provided is invalid should be of format YYYY-MM-DD and end date should be greater than start date";
      break;
    }
    case "invalidStartDate": {
      description =
        "The start date provided is invalid should be of format YYYY-MM-DD";
      break;
    }
    case "invalidEndDate": {
      description =
        "The end date provided is invalid should be of format YYYY-MM-DD";
      break;
    }
    case "endDateStartDateError": {
      description = "End date should be greater than start date";
      break;
    }
    case "invalidTime": {
      description = "The time provided is invalid should be of format HH:MM:SS";
      break;
    }
    case "invalidDisplaySettings": {
      description = "The display settings provided are invalid";
      break;
    }
    case "invalidPromptMetaData": {
      description = "The prompt meta data provided is invalid";
      break;
    }
    case "invalidCountryID": {
      description = "The country ID provided is invalid";
      break;
    }
    case "invalidRegionID": {
      description = "The region ID provided is invalid";
      break;
    }
    case "imageUploadError": {
      description = "There was an error uploading the image";
      break;
    }
    case "invalidImage": {
      description = "Image in the payload is invalid";
      break;
    }
    case "invalidImageType": {
      description = "Image type is invalid";
      break;
    }
    case "invalidImageSize": {
      description = "image size exceeds maximum allowed size";
      break;
    }
    case "invalidImageDimensions": {
      description = "Image dimensions are invalid";
      break;
    }
    case "invalidImageFormat": {
      description = "Image format is invalid";
      break;
    }
    case "invalidPromptType": {
      description = "Prompt type is invalid";
      break;
    }
    case "missingCountry": {
      description = "Country Code is required";
      break;
    }
    case error.unexpectedError: {
      description =
        "Some technical error occured. Please check your internet connection or try again later.";
      break;
    }
    default: {
      description = "Something went wrong. Please try again.";
      break;
    }
  }
  return description;
};

export let updateStatusActions = {
  activate: "activate",
  deactivate: "deactivate",
  publish: "publish",
};

export const validateColor = (color) => {
  if (color !== null && color !== "" && color !== undefined) {
    return validateHexCode(color);
  }
  return true;
}

export let invalidAutoDownloadRuleError =
  "Auto Download Rules Should Be A Subset Of Location Rules";

export let validateConfigForm = (input) => {
  let config = {
    hasValidKey: false,
    hasValidValue: false,
    hasValidDescription: false,
    hasValidVersion: false,
    isValid: false,
    data: {
      key: "",
      value: "",
      type: "",
      description: "",
      min_app_version: 0,
      max_app_version: 0,
      app_id: "",
    },
  };

  let key = input.key;
  let value = input.value;
  let type = input.type;
  let description = input.description;
  let min_app_version = input.min_app_version;
  let max_app_version = input.max_app_version;

  let reg = new RegExp("^[a-zA-Z0-9]+$");
  if (reg.test(key)) {
    config.hasValidKey = true;
  }

  if (parseFloat(max_app_version) === 0 || max_app_version === "") {
    if (
      min_app_version !== "" &&
      parseFloat(min_app_version) !== 0 &&
      parseFloat(min_app_version) > 0
    ) {
      config.data.min_app_version = parseFloat(min_app_version);
      config.data.max_app_version = null;
      config.hasValidVersion = true;
    }
  } else {
    if (
      parseFloat(min_app_version) < parseFloat(max_app_version) &&
      parseFloat(min_app_version) !== 0 &&
      min_app_version !== "" &&
      parseFloat(min_app_version) > 0
    ) {
      config.data.min_app_version = parseFloat(min_app_version);
      config.data.max_app_version = parseFloat(max_app_version);
      config.hasValidVersion = true;
    }
  }

  switch (type) {
    case "integer": {
      var reg1 = new RegExp("^[0-9]+$");
      if (reg1.test(value) && Number.isSafeInteger(parseInt(value))) {
        config.data.value = parseInt(value);
        config.hasValidValue = true;
      }
      break;
    }
    case "boolean": {
      if (value === "1" || value === "0") {
        config.data.value = parseInt(value);
        config.hasValidValue = true;
      }
      break;
    }
    case "float": {
      var reg2 = new RegExp(/^((\+|-)?(0|([1-9][0-9]*))(\.[0-9]+)?)$/);
      if (reg2.test(value)) {
        config.data.value = parseFloat(value);
        config.hasValidValue = true;
      }
      break;
    }
    case "json": {
      try {
        if (JSON.parse(value)) {
          config.data.value = JSON.parse(value);
          config.hasValidValue = true;
        }
      } catch (e) {
        config.isValid = false;
      }
      break;
    }
    case "string": {
      if (value.length !== 0) {
        config.hasValidValue = true;
        config.data.value = value;
      }
      break;
    }
    default: {
      config.data.value = value;
      config.hasValidValue = true;
    }
  }

  if (description) {
    config.hasValidDescription = true;
  }

  if (
    config.hasValidKey &&
    config.hasValidValue &&
    config.hasValidVersion &&
    config.hasValidDescription
  ) {
    config.isValid = true;
    config.data.key = input.key;
    config.data.type = input.type;
    config.data.description = input.description;
    config.data.app_id = input.app_id;
  }

  return config;
};

export let validateJsonValue = (value) => {
  if (value.length === 0 || value[0] === "") {
    return true;
  }
  try {
    if (JSON.parse(value)) {
      return true;
    }
  } catch (err) {
    return false;
  }
  return false;
};

export let validateUserForm = (input) => {
  let username = input.username;
  let password = input.password;
  let confirm_password = input.confirm_password;

  let user = {
    hasValidUsername: true,
    hasValidPassword: true,
    hasValidConfirm_password: true,
    isValid: false,
    data: {},
  };

  let reg = new RegExp("^[a-zA-Z0-9_\\-@.]+$");
  if (!reg.test(username)) {
    user.hasValidUsername = false;
  }

  if (password.length < MinPasswordLength) {
    user.hasValidPassword = false;
  }

  if (confirm_password !== password) {
    user.hasValidConfirm_password = false;
  }

  if (
    user.hasValidUsername &&
    user.hasValidPassword &&
    user.hasValidConfirm_password
  ) {
    user.isValid = true;
    user.data.username = username;
    user.data.password = password;
    user.data.roleIDs = input.roleIDs;
  }

  return user;
};

export let validatePermissionForm = (input) => {
  let name = input.permissionName;
  let identifier = input.permissionIdentifier;
  let description = input.permissionDescription;

  let permission = {
    hasValidName: true,
    hasValidIdentifier: true,
    hasValidDescription: true,
    isValid: false,
    data: {},
  };

  if (name === "") {
    permission.hasValidName = false;
  }

  if (identifier === "") {
    permission.hasValidIdentifier = false;
  }

  if (description === "") {
    permission.hasValidDescription = false;
  }

  if (
    permission.hasValidName &&
    permission.hasValidIdentifier &&
    permission.hasValidDescription
  ) {
    permission.isValid = true;
    permission.data.name = name;
    permission.data.identifier = identifier;
    permission.data.description = description;
  }

  return permission;
};

export let validateRoleForm = (input) => {
  let name = input.roleName;
  let description = input.roleDescription;
  let permissionIDs = input.permissionIDs;

  let role = {
    hasValidName: true,
    hasValidDescription: true,
    isValid: false,
    data: {},
  };

  if (name === "") {
    role.hasValidName = false;
  }

  if (description === "") {
    role.hasValidDescription = false;
  }

  if (role.hasValidName && role.hasValidDescription) {
    role.isValid = true;
    role.data.name = name;
    role.data.description = description;
    role.data.permissionIDs = permissionIDs;
  }

  return role;
};

export let redirectTo = (path) => {
  window.location = window.location.origin + path;
};

export let redirectToNewTab = (path) => {
  window.open(window.location.origin + path, "_blank");
};

export let filterPermittedRoutes = (allRoutes) => {
  //Get permissions array from localStorage
  let permissionsArray = [];
  let _permissionsArray = retrieveFromLocalStorage("userPermissions");
  if (_permissionsArray) permissionsArray = _permissionsArray.split(",");

  let dashboardRoute = Object.values(allRoutes).shift();
  let movieGifsRoute = Object.values(allRoutes).filter((route) => route.name == "Movie Gifs")[0];

  // Filter routes array on the basis of allowed permissions
  let _allRoutes = Object.values(allRoutes);

  /*  Structure of _allRoutes Array
  _allRoutes = [
    {
      path: "/configs",
      name: "Configs",
      icon: ShieldIcon,
      children: {
        viewConfigs: {
          path: "/configs/all-configs",
          name: "View Configs",
          permission: "config-list",
          component: Configs,
        },
        createConfig: {
          path: "/configs/addConfig",
          name: "Add New Config",
          permission: "config-create",
          component: CreateConfig,
        },
      },
    },
  ]; */

  _allRoutes.forEach((routeGroup) => {
    if (routeGroup.children) {
      let allChildren = Object.values(routeGroup.children);

      let allowedChildren = [];
      //Add only those children who have access permission
      Object.values(allChildren).forEach((child) => {
        if (permissionsArray.includes(child.permission)) {
          allowedChildren.push(child);
        }
      });

      //Update children array
      routeGroup.children = allowedChildren;
    }
  });

  //Remove elements from array which have no children to render
  let routes = Object.values(allRoutes).filter(
    (route) =>
    (route.path !== allRoutes.dashboard.path
      &&
      route.path !== allRoutes.moviesGifs.path
    )
  );

  routes = routes.filter((element) => element.children.length > 0);

  // pushing movieGifs route after gifs
  const gifIndex = routes.findIndex(route => route.name === "Gifs");
  routes.splice(gifIndex + 1, 0, movieGifsRoute)

  routes.unshift(dashboardRoute);

  return routes;
};

export let permissions = {
  listConfig: "config-list",
  createConfig: "config-create",
  updateConfig: "config-update",
  publishConfig: "config-publish",
  deactivateConfig: "config-deactivate",
  activateConfig: "config-activate",

  listConfigABTest: "configABTest-list",
  createConfigABTest: "configABTest-create",
  editConfigABTest: "configABTest-edit",
  startConfigABTest: "configABTest-start",
  completeConfigABTest: "configABTest-complete",

  listUser: "user-list",
  createUser: "user-create",
  updateUser: "user-update",
  deactivateUser: "user-deactivate",
  activateUser: "user-activate",
  listPermission: "permission-list",
  createPermission: "permission-create",
  editPermission: "permission-edit",

  listRole: "role-list",
  createRole: "role-create",
  updateRole: "role-update",

  deeplinkList: "deeplink-list",
  deeplinkCreate: "deeplink-create",

  rewardsList: "rewards-list",
  rewardsCreate: "rewards-create",

  taskList: "tasks-list",
  taskCreate: "tasks-create",
  taskUpdate: "tasks-update",
  taskActivate: "tasks-activate",
  taskDeactivate: "tasks-deactivate",
  taskPublish: "tasks-publish",

  popTextStyleList: "popTextStyle-list",
  popTextStyleDetail: "popTextStyle-detail",
  popTextStyleActivate: "popTextStyle-activate",
  popTextStyleDeactivate: "popTextStyle-deactivate",
  popTextStylePublish: "popTextStyle-publish",
  popTextStyleCreate: "popTextStyle-create",
  popTextStyleUpdate: "popTextStyle-update",
  popTextStyleRulesView: "popTextStyleRules-view",
  popTextStyleRulesUpdate: "popTextStyleRules-update",

  stickerPackList: "stickerPacks-list",
  stickerPackCreate: "stickerPack-create",
  stickerPackDetail: "stickerPack-detail",
  stickerPackEdit: "stickerPack-edit",
  updateStickerRules: "stickerRules-update",
  updateStickerPackRules: "stickerPackRules-update",
  updateStickerPackAutoDownloadRules: "stickerPackAutoDownloadRules-update",
  listStickerPackRules: "stickerPackRules-list",
  listStickerPackAutoDownloadRules: "stickerPackAutoDownloadRules-list",
  stickerActivate: "sticker-activate",
  stickerDeactivate: "sticker-deactivate",
  stickerEdit: "sticker-edit",
  stickerPublish: "sticker-publish",
  stickerList: "sticker-list",
  stickerDetail: "sticker-detail",

  gifPackList: "gifPacks-list",
  gifPackCreate: "gifPack-create",
  gifPackDetail: "gifPack-detail",
  gifPackEdit: "gifPack-edit",
  gifActivate: "gif-activate",
  gifDeactivate: "gif-deactivate",
  gifPublish: "gif-publish",
  gifList: "gif-list",
  gifDetail: "gif-detail",
  gifEdit : "gif-edit",
  updateGifPackRules: "gifPackRules-update",
  updateGifPackAutoDownloadRules: "gifPackAutoDownloadRules-update",
  updateGifRules: "gifRules-update",
  listGifPackRules: "gifPackRules-list",
  listGifPackAutoDownloadRules: "gifPackAutoDownloadRules-list",

  listEmojiStickers: "emojiStickers-list",
  addEmojiStickers: "emojiStickers-add",
  emojiStickerImageDetails: "emojiStickerImage-details",
  updateEmojiStickerImages: "emojiStickerImages-update",
  uploadEmojiStickerImage: "emojiStickerImage-add",
  emojiStickerImageRuleView: "emojiStickerImageRules-view",
  emojiStickerImageRuleUpdate: "emojiStickerImageRules-update",
  emojiStickerImageShareTextUpdate: "emojiStickerImageShareText-update",
  emojiStickerImageShareTextList: "emojiStickerImageShareText-list",

  popTextStyleRuleView: "popTextStyleRule-view",
  popTextStyleRuleUpdate: "popTextStyleRule-update",
  popTextStyleShareTextView: "popTextStyleShareText-list",
  popTextStyleShareTextUpdate: "popTextStyleShareText-update",

  keyboardThemeList: "keyboardTheme-list",
  keyboardThemeDetail: "keyboardTheme-detail",
  keyboardThemeActivate: "keyboardTheme-activate",
  keyboardThemeDeactivate: "keyboardTheme-deactivate",
  keyboardThemePublish: "keyboardTheme-publish",
  keyboardThemeCreate: "keyboardTheme-create",
  keyboardThemeUpdate: "keyboardTheme-update",
  keyboardThemeRuleView: "keyboardThemeRule-view",
  keyboardThemeRuleUpdate: "keyboardThemeRule-update",

  keyboardThemeCategoryList: "keyboardThemeCategory-list",
  keyboardThemeCategoryDetail: "keyboardThemeCategory-detail",
  keyboardThemeCategoryCreate: "keyboardThemeCategory-create",
  keyboardThemeCategoryUpdate: "keyboardThemeCategory-update",
  keyboardThemeCategoryActivate: "keyboardThemeCategory-activate",
  keyboardThemeCategoryDeactivate: "keyboardThemeCategory-deactivate",
  keyboardThemeCategoryPublish: "keyboardThemeCategory-publish",
  keyboardThemeCategoryRuleView: "keyboardThemeCategoryRule-view",
  keyboardThemeCategoryRuleUpdate: "keyboardThemeCategoryRule-update",

  //-------------kbl related permissions--//
  keyboardLanguageList: "keyboardLanguage-list",
  keyboardLanguageDetail: "keyboardLanguage-detail",
  keyboardLanguageCreate: "keyboardLanguage-create",
  keyboardLanguageEdit: "keyboardLanguage-edit",
  keyboardLanguageCreateRulesets: "keyboardLanguage-createRulesets",

  keyboardLanguageListSuggested: "keyboardLanguage-listSuggested",
  keyboardLanguageEditSuggested: "keyboardLanguage-editSuggested",

  keyboardLanguageLayoutList: "keyboardLanguageLayout-list",
  keyboardLanguageLayoutListSuggested: "keyboardLanguageLayout-listSuggested",
  keyboardLanguageLayoutEditSuggested: "keyboardLanguageLayout-editSuggested",

  keyboardLanguageResourceUpdate: "keyboardLanguageResource-update",
  keyboardLanguageLayoutCreate: "keyboardLanguageLayout-create",
  keyboardLanguageLayoutEdit: "keyboardLanguageLayout-edit",
  keyboardLanguageLayoutCreateRulesets: "keyboardLanguageLayout-createRulesets",

  languageList: "language-list",
  languageCreate: "language-create",
  languageEdit: "language-edit",
  listCountryCodes: "countryCodes-list",

  keyboardLanguagePublish: "keyboardLanguage-publish",
  keyboardLanguageActivate: "keyboardLanguage-activate",
  keyboardLanguageDeactivate: "keyboardLanguage-deactivate",
  keyboardLayoutList: "keyboardLayout-list",
  keyboardLayoutCreate: "keyboardLayout-create",
  keyboardLayoutEdit: "keyboardLayout-edit",
  keyboardLanguageABTestList: "keyboardLanguageABTest-list",
  keyboardLanguageABTestDetail: "keyboardLanguageABTest-detail",
  keyboardLanguageABTestCreate: "keyboardLanguageABTest-create",
  keyboardLanguageABTestEdit: "keyboardLanguageABTest-edit",
  keyboardLanguageABTestPublish: "keyboardLanguageABTest-publish",
  keyboardLanguageABTestComplete: "keyboardLanguageABTest-complete",
  keyboardLanguageABTestVariantCreate: "keyboardLanguageABTestVariant-create",
  keyboardLanguageABTestVariantEdit: "keyboardLanguageABTestVariant-edit",
  //-----------------------------------------------------------//
  quickReplyList: "quickReply-list",
  quickReplyDetail: "quickReply-detail",
  quickReplyActivate: "quickReply-activate",
  quickReplyDeactivate: "quickReply-deactivate",
  quickReplyPublish: "quickReply-publish",
  quickReplyCreate: "quickReply-create",
  quickReplyUpdate: "quickReply-update",
  quickReplyRulesView: "quickReplyRule-view",
  quickReplyRulesUpdate: "quickReplyRule-update",
  quickReplyReorder: "quickReply-reorder",
  quickReplyAssetsView: "quick-reply-assets-view",
  quickReplyCTATextsView: "quick-reply-cta-texts-view",

  quickReplyCategoryList: "quickReplyCategory-list",
  quickReplyCategoryCreate: "quickReplyCategory-create",
  quickReplyCategoryDetail: "quickReplyCategory-detail",
  quickReplyCategoryActivate: "quickReplyCategory-activate",
  quickReplyCategoryDeactivate: "quickReplyCategory-deactivate",
  quickReplyCategoryRuleUpdate: "quickReplyCategoryRule-update",
  quickReplyCategoryUpdate: "quickReplyCategory-update",
  quickReplyCategoryRuleView: "quickReplyCategoryRule-view",
  quickReplyCategoryPublish: "quickReplyCategory-publish",

  brandCampaignList: "brandCampaign-list",
  clientList: "client-list",
  countryList: "country-list",
  regionList: "region-list",
  cityList: "city-list",
  fontList: "font-list",

  keyboardFontList: "keyboardFont-list",
  keyboardFontDetail: "keyboardFont-detail",
  keyboardFontCreate: "keyboardFont-create",
  keyboardFontEdit: "keyboardFont-edit",
  keyboardFontPublish: "keyboardFont-publish",
  keyboardFontActivate: "keyboardFont-activate",
  keyboardFontDeactivate: "keyboardFont-deactivate",
  keyboardFontReorder: "keyboardFont-reorder",

  contentUpdatePromptActivate: "contentUpdatePrompt-activate",
  contentUpdatePromptCreate: "contentUpdatePrompt-create",
  contentUpdatePromptList: "contentUpdatePrompt-list",
  contentUpdatePromptPublish: "contentUpdatePrompt-publish",
  contentUpdatePromptUpdate: "contentUpdatePrompt-update",
  contentUpdatePromptDetail: "contentUpdatePrompt-detail",
  contentUpdatePromptDeactivate: "contentUpdatePrompt-deactivate",
  contentUpdatePromptRuleView: "contentUpdatePromptRule-view",
  contentUpdatePromptRuleUpdate: "contentUpdatePromptRule-update",

  // cre dashboard
  contentList: "content-list",
  contentCategoryList: "contentCategory-list",
  contentCategoryDetail: "contentCategory-detail",
  contentCategoryCreate: "contentCategory-create",
  contentCategoryUpdate: "contentCategory-update",
  contentCategoryActivate: "contentCategory-activate",
  contentCategoryDeactivate: "contentCategory-deactivate",
  contentCategoryPublish: "contentCategory-publish",
  contentCategoryRuleUpdate: "contentCategoryRule-update",
  contentCategoryRuleView: "contentCategoryRule-view",

  contentPromotedTagList: "contentPromotedTag-list",
  contentPromotedTagDetail: "contentPromotedTag-detail",
  contentPromotedTagCreate: "contentPromotedTag-create",
  contentPromotedTagUpdate: "contentPromotedTag-update",
  contentPromotedTagEnable: "contentPromotedTag-enable",
  contentPromotedTagDisable: "contentPromotedTag-disable",
  contentPromotedTagRuleUpdate: "contentPromotedTagRule-update",
  contentPromotedTagRuleView: "contentPromotedTagRule-view",

  // carousel Dashboard
  publishContentCategoryCarousel: "contentCategoryCarousel-publish",
  deactivateContentCategoryCarousel: "contentCategoryCarousel-deactivate",
  activateContentCategoryCarousel: "contentCategoryCarousel-activate",
  contentCategoryCarouselList: "contentCategoryCarousel-list",
  contentCategoryCarouselCreate: "contentCategoryCarousel-create",
  contentCategoryCarouselUpdate: "contentCategoryCarousel-update",
  contentCategoryCarouselOrderUpdate: "contentCategoryCarouselOrder-update",
  contentCategoryCarouselItemView: "contentCategoryCarouselItem-view",
  contentCategoryCarouselItemAdd: "contentCategoryCarouselItem-add",
  contentCategoryCarouselItemUpdate: "contentCategoryCarouselItem-update",
  contentCategoryCarouselItemOrderUpdate: "contentCategoryCarouselItemOrder-update",
  contentCategoryCarouselRuleUpdate: "contentCategoryCarouselRule-update",
  contentCategoryCarouselRuleView: "contentCategoryCarouselRule-view",

  // keyboard prompts
  packageSetList: "packageSet-list",
  packageSetCreate: "packageSet-create",
  packageSetUpdate: "packageSet-update",
  packageSetDetail: "packageSet-details",
  keyboardPromptCreate: "keyboardPrompt-create",
  keyboardPromptUpdate: "keyboardPrompt-update",
  keyboardPromptEditView: "keyboardPrompt-editView",
  keyboardPromptDetail: "keyboardPrompt-details",
  keyboardPromptActivate: "keyboardPrompt-activate",
  keyboardPromptDeactivate: "keyboardPrompt-deactivate",
  keyboardPromptPublish: "keyboardPrompt-publish",
  keyboardPromptList: "keyboardPrompt-list",
  keyboardPromptTargetingRulesList: "keyboardPromptTargetingRules-list",
  keyboardPromptTargetingRulesUpdate: "keyboardPromptTargetingRules-update",
  keyboardPromptCountriesList: "keyboardPromptCountries-list",
  keyboardPromptRegionsList: "keyboardPromptRegions-list",
  keyboardPromptCitiesList: "keyboardPromptCities-list",

  // app resources
  appResourceList: "appResource-list",
  appResourceCreate: "appResource-create",

  // quick-reply adbanners
  quickReplyAdbannerList: "quick-reply-adbanner-list",
  quickReplyAdbannerDetails: "quick-reply-adbanner-details",
  quickReplyAdbannerPublish: "quick-reply-adbanner-publish",
  quickReplyAdbannerActivate: "quick-reply-adbanner-activate",
  quickReplyAdbannerDeactivate: "quick-reply-adbanner-deactivate",
  quickReplyAdbannerCreate: "quick-reply-adbanner-create",
  quickReplyAdbannerEdit: "quick-reply-adbanner-edit",
  quickReplyAdbannerRulesView: "quick-reply-adbannerRules-view",
  quickReplyAdbannerRulesUpdate: "quick-reply-adbannerRules-update",
  quickReplyAdbannerReorder: "quick-reply-adbanner-reorder",

  // smart suggestions
  smartSuggestionsDashboard: "smartSuggestions-dashboard",
  smartSuggestionsDashboardAdmin: "smartSuggestionsDashboard-admin",
  smartSuggestionList: "adAll-list",
  smartSuggestionCreate: "ad-create",
  smartSuggestionEdit: "ad-update",
  smartSuggestionActivate: "ad-activate",
  smartSuggestionDeactivate: "ad-deactivate",
  smartSuggestionPublish: "ad-publish",
  smartSuggestionUnpublish: "ad-unpublish",
  smartSuggestionTargetingRuleList: "adTargetingRule-list",
  smartSuggestionTargetingRuleEdit: "adTargetingRule-edit",
  smartSuggestionOrderList: "adsOrder-list",
  smartSuggestionOrderEdit: "adsOrder-update",
  smartSuggestionCountriesList: "adsCountries-list",
  smartSuggestionRegionsList: "adsRegions-list",
  smartSuggestionCitiesList: "adsCities-list",

  smartSuggestionPreferredCategoriesList: "ads-userPreferredCategories-list",
  smartSuggestionPreferredCategoriesEdit: "ads-userPreferredCategories-edit",
  smartSuggestionPreferredCategoriesDelete: "ads-userPreferredCategories-delete",
  smartSuggestionPreferredCategoriesSearch: "ads-userPreferredCategories-search",
  adsCategoriesDashboard: "adsCategories-dashboard",

  // ads config API dashboard
  mediationSdkConfigDashboard: "mediationSdkConfig-dashboard",
  configAPIList: "adxConfig-list",
  configAPICreate: "adxConfig-create",
  configAPIEdit: "adxConfig-edit",
  configAPIDelete: "adxConfig-delete",
  configAPIActivate: "adxConfig-activate",
  configAPIDeactivate: "adxConfig-deactivate",

  // stories dashboard
  storyCreatePermission: "story-create",
  storyUpdatePermission: "story-update",
  storyViewPermission: "story-view",
  storyListPermission: "story-list",
  userStoryListPermission: "user-story-list",
  viewAiStoryStats: "view-ai-story-stats",
  storyCTAUpdatePermission: "story-cta-update",
  storyUserAttributePermission: "story-user-attribute-update",
  storyDeletePermission: "story-delete",
  storyPublishPermission: "story-publish",
  storyUnpublishPermission: "story-unpublish",
  storyActivatePermission: "story-activate",
  storyDeactivatePermission: "story-deactivate",
  storyCTAViewPermission: "story-cta-view",
  storyCTACreatePermission: "story-cta-create",
  sotdCreatePermission: "sotd-create",
  sotdUpdatePermission: "sotd-update",
  sotdViewPermission: "sotd-view",
  sotdListPermission: "sotd-list",
  storiesTargetingRuleCreatePermission: "stories-targeting-rule-create",
  storiesTargetingRuleUpdatePermission: "stories-targeting-rule-update",
  storiesTargetingRuleViewPermission: "stories-targeting-rule-view",
  storiesTargetingRuleListPermission: "stories-targeting-rule-list",
  sotdTargetingRuleCreatePermission: "sotd-targeting-rule-create",
  sotdTargetingRuleUpdatePermission: "sotd-targeting-rule-update",
  sotdTargetingRuleViewPermission: "sotd-targeting-rule-view",
  sotdTargetingRuleListPermission: "sotd-targeting-rule-list",
  storyBucketCreatePermission: "story-bucket-create",
  storyBucketUpdatePermission: "story-bucket-update",
  storyBucketViewPermission: "story-bucket-view",
  storyBucketListPermission: "story-bucket-list",
  storyBucketTargetingRuleCreatePermission: "story-bucket-targeting-rule-create",
  storyBucketTargetingRuleUpdatePermission: "story-bucket-targeting-rule-update",
  storyBucketTargetingRuleViewPermission: "story-bucket-targeting-rule-view",
  storyBucketTargetingRuleListPermission: "story-bucket-targeting-rule-list",
  storiesBulkUpdatePermission: "stories-bulk-update",
  storiesBulkCreatePermission: "stories-bulk-create",

  //taas dashboard
  taasClientList: "taasClient-list",
  taasClientManage: "taasClient-manage",
  taasStatisticsViewer: "taasStats-list",
  taasStickerList: "taasSticker-list",
  taasStickerManage: "taasSticker-manage",
  taasStoryList: "taasStory-list",
  taasStoryManage: "taasStory-manage",
  taasClientApiKeyManage: "taasClient-apiKey-manage",

  //gen-ai dashboard
  AIPromptTemplateList: "aiPromptTemplate-list",
  AIPromptTemplateDetail: "aiPromptTemplate-detail",
  AIPromptTemplateAdd: "aiPromptTemplate-create",
  AIPromptTemplateEdit: "aiPromptTemplate-edit",
  AIPromptTemplatePublish: "aiPromptTemplate-publish",
  AIPromptTemplateDeactivate: "aiPromptTemplate-deactivate",
  AIPromptTemplateActivate: "aiPromptTemplate-activate",
  AIPromptTemplateVersionList: "aiPromptTemplateVersion-list",
  AIPromptTemplateVersionDetail: "aiPromptTemplateVersion-detail",
  AIPromptTemplateVersionAdd: "aiPromptTemplateVersion-create",
  AIPromptTemplateVersionEdit: "aiPromptTemplateVersion-edit",
  AIPromptTemplateVersionPublish: "aiPromptTemplateVersion-publish",
  AIPromptTemplateVersionDeactivate: "aiPromptTemplateVersion-deactivate",
  AIPromptTemplateVersionActivate: "aiPromptTemplateVersion-activate",

  //gen-ai-config-dashboard
  AIPoweredBarPlacementList: "aiPoweredBarPlacement-list",
  AIPoweredBarPlacementDetail: "aiPoweredBarPlacement-detail",
  AIPoweredBarPlacementAdd: "aiPoweredBarPlacement-create",
  AIPoweredBarPlacementEdit: "aiPoweredBarPlacement-edit",
  AIPoweredBarPlacementPublish: "aiPoweredBarPlacement-publish",
  AIPoweredBarPlacementDeactivate: "aiPoweredBarPlacement-deactivate",
  AIPoweredBarPlacementActivate: "aiPoweredBarPlacement-activate",
  AIPoweredBarTaskList: "aiPoweredBarTask-list",
  AIPoweredBarTaskDetail: "aiPoweredBarTask-detail",
  AIPoweredBarTaskAdd: "aiPoweredBarTask-create",
  AIPoweredBarTaskEdit: "aiPoweredBarTask-edit",
  AIPoweredBarTaskPublish: "aiPoweredBarTask-publish",
  AIPoweredBarTaskDeactivate: "aiPoweredBarTask-deactivate",
  AIPoweredBarTaskActivate: "aiPoweredBarTask-activate",

  // revenue dashboard
  adsRevenueDashboard: "adsRevenue-dashboard",
  revenueMetricsList: "adxRevenueMetrics-list",
  revenueGraphList: "adxRevenueGraph-list",
  revenueProviderList: "adxRevenueProvider-list",
  revenueAdsList: "adxRevenueAds-list",
  revenueNotesList: "adxRevenueDashboardNotes-list",
  revenueNotesCreate: "adxRevenueDashboardNotes-create",
  revenueNotesEdit: "adxRevenueDashboardNotes-edit",
  revenueNotesDelete: "adxRevenueDashboardNotes-delete",

  // sticker share text dashboard
  stickerShareTextUpdate: "stickerShareText-update",

  gifShareTextUpdate: "gifShareTexts-update",

  // movie gifs
  movieGifsList: "movieGifs-list",
  movieGifsUpdate: "movieGifs-update",

  // placement providers dashboard
  placementProvidersAll: "adxPlacementProviders-all",
  placementProvidersCreate: "adxPlacementProviders-create",
  placementProvidersEdit: "adxPlacementProviders-edit",
  placementProvidersDelete: "adxPlacementProviders-delete",

  // mixing strategy ratios dashboard
  mixingStrategyRatiosList: "adxMixingStrategyRatios-list",
  mixingStrategyRatiosCreate: "adxMixingStrategyRatios-create",
  mixingStrategyRatiosEdit: "adxMixingStrategyRatios-edit",
  mixingStrategyRatiosDelete: "adxMixingStrategyRatios-delete",

  // placement locations dashboard
  placementLocationsList: "adxPlacementLocations-list",
  placementLocationsCreate: "adxPlacementLocations-create",
  placementLocationsEdit: "adxPlacementLocations-edit",
  placementLocationsDelete: "adxPlacementLocations-delete",

  // ad metadata override dashboard
  adsMetadataOverrideDashboard: "adsMetadataOverride-dashboard",
  adMetadataUpdate: "ad-metadata-update",
  adMetadataReset: "ad-metadata-reset",
  adPromoteUpdate: "ad-promote-update",
  promotedAdsOrderUpdate: "promotedAdsOrder-update",
  fixedPositionsUpdate: "fixedPositions-update",

  // suggestion drawer dashboard 
  suggestionDrawerCategoryList: "suggestionDrawerCategories-list",
  suggestionDrawerContentList: "suggestionDrawerContent-list",
  suggestionDrawerCategoryDetail: "suggestionDrawerCategory-detail",
  suggestionDrawerCategoryCreate: "suggestionDrawerCategory-create",
  suggestionDrawerCategoryUpdate: "suggestionDrawerCategory-update",
  suggestionDrawerCategoryPublish: "suggestionDrawerCategory-publish",
  suggestionDrawerCategoryActivate: "suggestionDrawerCategory-activate",
  suggestionDrawerCategoryDeactivate: "suggestionDrawerCategory-deactivate",
  suggestionDrawerCategoryOrderView: "suggestionDrawerCategoryOrder-view",
  suggestionDrawerCategoryOrderUpdate: "suggestionDrawerCategoryOrder-update",
  SuggestionDrawerCategoryRulesView: "suggestionDrawerCategoryRules-view",
  SuggestionDrawerCategoryRulesUpdate: "suggestionDrawerCategoryRules-update",
  suggestionDrawerContentDetail: "suggestionDrawerContent-detail",
  suggestionDrawerContentCreate: "suggestionDrawerContent-create",
  suggestionDrawerContentUpdate: "suggestionDrawerContent-update",
  suggestionDrawerContentPublish: "suggestionDrawerContent-publish",
  suggestionDrawerContentActivate: "suggestionDrawerContent-activate",
  suggestionDrawerContentDeactivate: "suggestionDrawerContent-deactivate",
  suggestionDrawerContentOrderView: "suggestionDrawerContentOrder-view",
  suggestionDrawerContentOrderUpdate: "suggestionDrawerContentOrder-update",
  SuggestionDrawerContentRulesView: "suggestionDrawerContentRules-view",
  SuggestionDrawerContentRulesUpdate: "suggestionDrawerContentRules-update",

  SegmentsList: "segment-list",
  SegmentCreate: "segment-create",
  SegmentUpdate: "segment-update",
  //conflict Dashboard
  adsConflictDashboard: "adsConflict-dashboard",
  adsConflictList: "ad-conflict-list",
  adsConflictUpdate: "ad-conflict-update",
  adsConflictReset: "ad-conflict-reset",

  // ads dashboard logs
  dashboardLogs: "dashboard-logs",

  // story ads api dashboard
  storyAdUpdate: "storyAd-update",
  storyAdReset: "storyAd-reset",

  // Lottie-Animations Permissions
  EmojiStickerAnimationList: "EmojiStickerAnimation-list",
  EmojiStickerAnimationCreate: "EmojiStickerAnimation-create",
  EmojiStickerAnimationView: "EmojiStickerAnimation-view",
  EmojiStickerAnimationEdit: "EmojiStickerAnimation-edit",

  // brand campaigns auto capping dashboard
  autoCappingList: "brandCampaign-autoCapping-list",
  autoCappingCreate: "brandCampaign-autoCapping-create",
  autoCappingEdit: "brandCampaign-autoCapping-update",
  autoCappingDelete: "brandCampaign-autoCapping-delete",

  // ads env configs dashboard
  adsEnvConfigList: "adxEnvConfig-list",
  adsEnvConfigCreate: "adxEnvConfig-create",
  adsEnvConfigEdit: "adxEnvConfig-edit",
  adsEnvConfigDelete: "adxEnvConfig-delete",

  // notification
  notificationList: "notification-list",
  notificationPublish: "notification-publish",
  notificationActivate: "notification-activate",
  notificationDeactivate: "notification-deactivate",
  notificationRuleView: "notificationRule-view",
  notificationRuleUpdate: "notificationRule-update",
  notificationCancel: "notification-cancel",
  notificationExecutionList: "notificationExecution-list",
  notificationSend: "notification-send",
  notificationTest: "notification-test",
  notificationCreate: "notification-create",
  notificationDetails: "notification-detail",
  notificationEdit: "notification-edit",

  // user segment
  userSegmentList: "userSegment-list",
  userSegmentCreate: "userSegment-create",
  userSegmentEdit: "userSegment-edit",
  userSegmentDetail: "userSegment-detail",
  userSegmentPublish: "userSegment-publish",
  userSegmentActivate: "userSegment-activate",
  userSegmentDeactivate: "userSegment-activate",

  //chatAssistant dashboard
  chatAssistantSystemPromptList: "chatAssistantSystemPrompt-list",
  chatAssistantSystemPromptCreate: "chatAssistantSystemPrompt-create",
  chatAssistantSystemPromptActivate: "chatAssistantSystemPrompt-deactivate",
  chatAssistantSystemPromptDeactivate: "chatAssistantSystemPrompt-activate",
};

export let allActions = {
  activate: "Activate",
  deactivate: "Deactivate",
  publish: "Publish",
  unpublish: "Unpublish",
};

export let getGlobalBtnName = (status) => {
  if (status === Config.PUBLISHED_STATUS) return "Deactivate";
  if (status === Config.DEACTIVATED_STATUS) return "Activate";
};

export let getStatus = (publishedAt, deactivateAt) => {
  if (deactivateAt !== null || deactivateAt === "") {
    return Config.DEACTIVATED_STATUS;
  }
  if (publishedAt !== null || publishedAt === "") {
    return Config.PUBLISHED_STATUS;
  } else {
    return Config.UNPUBLISHED_STATUS;
  }
};

export let getStatusOfBtn = (publishedAt, deactivateAt) => {
  if (deactivateAt === null && publishedAt === null) {
    return Config.UNPUBLISHED_STATUS;
  }
  else if (publishedAt !== null && deactivateAt === null) {
    return Config.ACTIVATED_STATUS;
  }
  else if (publishedAt !== null && deactivateAt !== null) {
    return Config.DEACTIVATED_STATUS;
  }
};

export let getStatusColor = (state) => {
  if (state === Config.PUBLISHED_STATUS) return "success";
  if (state === Config.DEACTIVATED_STATUS) return "danger";
  if (state === Config.UNPUBLISHED_STATUS) return "primary";
  if (state === Config.COMPLETED) return "success";
  if (state === Config.NOT_STARTED) return "secondary";
  if (state === Config.ONGOING) return "warning";
  if (state === Config.FAILED) return "danger";
};

export let getButtonColor = (status) => {
  if (status === Config.PUBLISHED_STATUS) {
    return "danger";
  } else return "success";
};

export let getBtnName = (status) => {
  if (status === Config.PUBLISHED_STATUS) return "Deactivate";
  if (status === Config.DEACTIVATED_STATUS) return "Activate";
  if (status === Config.ACTIVATED_STATUS) return "Deactivate";
  if (status === Config.UNPUBLISHED_STATUS) return "Publish";
};

// status checks for AB Tests
export let getABTestStatus = (publishedAt, completedAt) => {
  if (completedAt !== null || completedAt === "") {
    return Config.COMPLETED_STATUS;
  }
  if (publishedAt !== null || publishedAt === "") {
    return Config.PUBLISHED_STATUS;
  } else {
    return Config.UNPUBLISHED_STATUS;
  }
};

export let getABTestStatusColor = (state) => {
  if (state === Config.PUBLISHED_STATUS) return "primary";
  if (state === Config.COMPLETED_STATUS) return "success";
  if (state === Config.UNPUBLISHED_STATUS) return "danger";
};

export let getABTestButtonColor = (status) => {
  return "success";
};

export let getABTestBtnName = (status) => {
  return "Publish";
};

export let redirectionOnTokenExpiry = () => {
  removeFromLocalStorage("token");
  removeFromLocalStorage("exp");
  storeInLocalStorage("url", window.location.pathname);
  window.location.href = window.location.origin;
};

export let redirectOnTokenExpiry = () => {
  removeFromLocalStorage("token");
  removeFromLocalStorage("exp");
  storeInLocalStorage("url", window.location.pathname);
  window.location.href = window.location.origin;
};

export let validateHexCode = (hexCode) => {
  // Define the pattern for a valid hex color code
  const pattern = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

  // Check if the input matches the pattern
  return pattern.test(hexCode);
};

export let validateARGB = (argbCode) => {
  const pattern = /^#([A-Fa-f0-9]{8})$/;

  return pattern.test(argbCode);
};

export let validateInteger = (intValue) => {
  let pattern = new RegExp("^[0-9]+$");
  return pattern.test(intValue);
};

export let validateFloat = (floatValue) => {
  let pattern = new RegExp(/^((\+|-)?(0|([1-9][0-9]*))(\.[0-9]+)?)$/);
  return pattern.test(floatValue);
};

export let validateTerritory = (territory) => {
  if (territory.length > 0) {
    let pattern = new RegExp(/^[A-Z]{2}$/);
    return pattern.test(territory);
  }
  return true;
};

export let validateLocale = (locale) => {
  let pattern = new RegExp(/^[A-Za-z_]*$/);
  return pattern.test(locale);
};

export let validateCode = (code) => {
  let pattern = new RegExp(/^[A-Za-z_]*$/);
  return pattern.test(code) && code.length <= 10;
};

export let validateCreateKeyboardLanguageLayoutForm = (
  autoSelect,
  characterIdentifier,
  type,
  languageLayoutId
) => {
  if (autoSelect === "") {
    return "The auto select field is required.";
  } else if (
    characterIdentifier != undefined &&
    characterIdentifier.length > 2
  ) {
    return "The Character Identifier field cannot consits of more than 2 characters.";
  } else if (type == "") {
    return "The type field is required.";
  } else if (languageLayoutId == "") {
    return "Language layout id field is required.";
  }
  return true;
};

let countDecimals = (minAppVersion) => {
  let decimals = minAppVersion.toString().split(".")[1];
  if (decimals === undefined) {
    return 0;
  }
  return decimals.length;
};

export let validateMinAppVersionKeyboardLanguages = (minAppVersion) => {
  if (
    minAppVersion >= 1 &&
    minAppVersion <= 99999999999999999.999 &&
    countDecimals(minAppVersion) <= 3
  ) {
    return true;
  }
  return "The min app version must be numeric >= 1. and <= 1.0E+17";
};

export let validateMinBobbleSDKVersionKeyboardLanguages = (
  minBobbleSDKVersion
) => {
  if (
    minBobbleSDKVersion >= 1 &&
    minBobbleSDKVersion <= 99999999999999999.999 &&
    countDecimals(minBobbleSDKVersion) <= 3
  ) {
    return true;
  }
  return "The min app version must be numeric >= 1. and <= 1.0E+17";
};

export let dateFormatted = (str) => {
  let targetedValue = new Date(str);
  let month =
    (targetedValue.getMonth() + 1 < 10 ? "0" : "") +
    (targetedValue.getMonth() + 1);
  let day = (targetedValue.getDate() < 10 ? "0" : "") + targetedValue.getDate();
  let hour =
    (targetedValue.getHours() < 10 ? "0" : "") + targetedValue.getHours();
  let minutes =
    (targetedValue.getMinutes() < 10 ? "0" : "") + targetedValue.getMinutes();
  let date =
    targetedValue.getFullYear() +
    "-" +
    month +
    "-" +
    day +
    "T" +
    hour +
    ":" +
    minutes;
  return date;
};

export let payloadDateFormatter = (str) => {
  let targetedValue = new Date(str);
  let month =
    (targetedValue.getMonth() + 1 < 10 ? "0" : "") +
    (targetedValue.getMonth() + 1);
  let day = (targetedValue.getDate() < 10 ? "0" : "") + targetedValue.getDate();
  let hour =
    (targetedValue.getHours() < 10 ? "0" : "") + targetedValue.getHours();
  let minutes =
    (targetedValue.getMinutes() < 10 ? "0" : "") + targetedValue.getMinutes();
  let date =
    targetedValue.getFullYear() +
    "-" +
    month +
    "-" +
    day +
    " " +
    hour +
    ":" +
    minutes +
    ":00";
  return date;
};

export let validateJSON = (text) => {
  try {
    if (text != null && text !== undefined && text.length !== 0) {
      let parsedJSON = JSON.parse(text);
      if (!Array.isArray(parsedJSON)) {
        return false;
      }
      for (const element of parsedJSON) {
        if (typeof element !== "string") {
          return false;
        }
      }
      return true;
    }
    return true;
  } catch (err) {
    return false;
  }
};

// Function for field - additionalDetails
export let validateJSONString = (text) => {
  try {
    if (text != null && text !== undefined && text.length !== 0) {
      const json = JSON.parse(text);
      if (
        Object.prototype.toString.call(json).slice(8, -1) !== "Object" ||
        Object.getOwnPropertyNames(json).length === 0 // checks for {}
      ) {
        return false;
      }

      return true;
    }
    return true;
  } catch (err) {
    return false;
  }
};
export let parseValue = (text, dataType) => {
  switch (dataType) {
    case "integer": {
      var reg1 = new RegExp("^[0-9]+$");
      if (reg1.test(text)) return { value: parseInt(text), error: null };
      else return { value: null, error: "invalidValue" };
    }
    case "boolean": {
      if (text.toString() === "true" || text.toString() === "false")
        return { value: text, error: null };
      else return { value: null, error: "invalidValue" };
    }
    case "float": {
      var reg2 = new RegExp(/^((\+|-)?(0|([1-9][0-9]*))(\.[0-9]+)?)$/);
      if (reg2.test(text)) return { value: parseFloat(text), error: null };
      else return { value: null, error: "invalidValue" };
    }
    case "json": {
      if (JSON.parse(text)) return { value: JSON.parse(text), error: null };
      else return { value: null, error: "invalidValue" };
    }
    case "string": {
      if (text.length !== 0) return { value: text, error: null };
      else return { value: null, error: "invalidValue" };
    }
    default: {
      return { value: text, error: null };
    }
  }
};

export let dateFormattedWithoutTime = (str) => {
  let targetedValue = new Date(str);
  let month =
    (targetedValue.getMonth() + 1 < 10 ? "0" : "") +
    (targetedValue.getMonth() + 1);
  let day = (targetedValue.getDate() < 10 ? "0" : "") + targetedValue.getDate();
  let date = targetedValue.getFullYear() + "-" + month + "-" + day;

  return date;
};

export let payloadDateFormatterWithoutTime = (str) => {
  let targetedValue = new Date(str);
  let month =
    (targetedValue.getMonth() + 1 < 10 ? "0" : "") +
    (targetedValue.getMonth() + 1);
  let day = (targetedValue.getDate() < 10 ? "0" : "") + targetedValue.getDate();
  let date = targetedValue.getFullYear() + "-" + month + "-" + day;

  return date;
};

export let validateBrandCampaignIdentifier = (identifier) => {
  let validationRegex = "^[a-zA-Z0-9_]*$";
  var regex = new RegExp(validationRegex, "g");

  if (identifier.length > 0 && regex.test(identifier)) {
    return true;
  }
  return false;
};

//Checks if the autodownloadLocationRules array is a subset of normal location rules array)
export let isSubsetOfLocationRules = (
  locationRules,
  autoDownloadLocationRules
) => {
  if (autoDownloadLocationRules.length > locationRules.length) {
    return false;
  }

  let count = 0;
  for (let i = 0; i < autoDownloadLocationRules.length; i++) {
    for (let j = 0; j < locationRules.length; j++) {
      if (
        locationRules[j].cityID ===
        autoDownloadLocationRules[i].selectedCityID &&
        locationRules[j].countryCode ===
        autoDownloadLocationRules[i].selectedCountryCode &&
        locationRules[j].regionCode ===
        autoDownloadLocationRules[i].selectedRegionCode
      ) {
        count++;
        break;
      }
    }
  }
  return count === autoDownloadLocationRules.length;
};

//splits the array into chunks of specified size
export let chunkArray = (array, chunkSize) => {
  let chunkedArray = [];
  for (let index = 0; index < array.length; index += chunkSize) {
    let newChunk = array.slice(index, index + chunkSize);
    chunkedArray.push(newChunk);
  }

  return chunkedArray;
};

let addStatus = (created, published, deactivated) => {
  if (created !== null && published === null) return "Created";
  else if (created !== null && published !== null && deactivated === null)
    return "Activated";
  else if (created !== null && published !== null && deactivated !== null)
    return "Deactivated";
};

export let validateEmojiStickerImage = (perferredExtension, file) => {
  return new Promise((resolve, reject) => {
    let image = new Image();
    let objURL = window.URL.createObjectURL(file);

    image.src = objURL;

    image.onload = function () {
      if (perferredExtension === "image/gif") {
        if (!(this.width === 512 && this.height === 512))
          reject("The Image resolution For GIF should be 512 * 512");
        else resolve(true);
      } else {
        if (!(this.width === 512 && this.height === 512))
          reject("The Image resolution should be 512 * 512");
        else resolve(true);
      }
    };
  });
};

export let validateAge = (input) => {
  let age = {
    hasValidAge: false,
    data: {
      minAge: 0,
      maxAge: "",
    },
  };

  let minAge = input.minAge;
  let maxAge = input.maxAge;
  if (
    minAge < 0 ||
    (maxAge !== "" && maxAge !== null && (minAge > maxAge || maxAge <= 0))
  ) {
    return age;
  }

  age.data.minAge = minAge ? parseInt(minAge) : 0;
  age.data.maxAge = maxAge ? parseInt(maxAge) : "";
  age.hasValidAge = true;
  return age;
};

// Validates the keyboardFont details in ManageKeyboardFont component
export let validateKeyboardFont = (input) => {
  let name = input.name;
  let characterMappings = input.characterMappings;
  let supportedLanguageCodes = input.supportedLanguageCodes;
  let minAndroidSDKVersion = input.minAndroidSDKVersion;
  let badgeSettings = input.badgeSettings;

  let font = {
    hasValidName: true,
    hasCharacterMappings: true,
    hasSupportedLanguageCodes: true,
    isValid: false,
    data: {},
  };

  // Check for empty name
  if (name === null || name === "") {
    font.hasValidName = false;
  }

  // Check for empty characterMappings
  let validCharacterMappings = true;
  for (const element of characterMappings) {
    if (element.key === "" || element.value === "") {
      validCharacterMappings = false;
      break;
    }
  }

  if (characterMappings.length === 0 || !validCharacterMappings) {
    font.hasCharacterMappings = false;
  }

  // Check for empty supportedLanguageCodes
  if (supportedLanguageCodes.length === 0) {
    font.hasSupportedLanguageCodes = false;
  }

  // Valid font details
  if (
    font.hasValidName &&
    font.hasCharacterMappings &&
    font.hasSupportedLanguageCodes
  ) {
    font.isValid = true;
    font.data.name = name;
    font.data.characterMappings = characterMappings;
    font.data.supportedLanguageCodes = supportedLanguageCodes;
    font.data.minAndroidSDKVersion = minAndroidSDKVersion;
    font.data.badgeSettings = badgeSettings;
  }

  return font;
};

export let isOperationPermitted = (allowedPermissions, permission) => {
  return allowedPermissions.includes(permission);
};
export let onAppVersionInput = (e) => {
  e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 9);
};

export let isJSONParsable = (text) => {
  try {
    if (text != null && text !== undefined && text.length !== 0) {
      let parsedJSON = JSON.parse(text);
      if (!Array.isArray(parsedJSON)) {
        return false;
      }
      for (const element of parsedJSON) {
        if (typeof element !== "string") {
          return false;
        }
      }
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export let formatJSON = (value) => {
  let res;
  try {
    res = JSON.parse(value.toString());
    if (res && typeof res === "object") {
      res = JSON.stringify(res, null, 4);
    }
  } catch (e) {
    res = value;
  }
  return res;
};

export let convertToLocalTime = (timestamp) => {
  let timestampFormat = "YYYY-MM-DD HH:mm:ss";
  return moment(timestamp + " UTC").format(timestampFormat);
};

export let processTimestamps = (data) => {
  if (data.startTime !== null) {
    data.startTime = convertToLocalTime(data.startTime);
  }
  if (data.endTime !== null) {
    data.endTime = convertToLocalTime(data.endTime);
  }
  if (data.createdAt !== null) {
    data.createdAt = convertToLocalTime(data.createdAt);
  }
  if (data.updatedAt !== null) {
    data.updatedAt = convertToLocalTime(data.updatedAt);
  }
  if (data.publishedAt !== null) {
    data.publishedAt = convertToLocalTime(data.publishedAt);
  }
  if (data.deactivatedAt !== null) {
    data.deactivatedAt = convertToLocalTime(data.deactivatedAt);
  }
};

// Format the date and time in dd/mm/yyyy hh:mm AM/PM format
export let convertToISTFormat = (dateString = new Date()) => {
  const date = new Date(dateString);
  const options = { timeZone: "Asia/Calcutta" };

  const day = date.toLocaleString("en-US", { ...options, day: "2-digit" });
  const month = date.toLocaleString("en-US", { ...options, month: "2-digit" });
  const year = date.toLocaleString("en-US", { ...options, year: "numeric" });

  const time = date.toLocaleString("en-US", {
    ...options,
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  return `${day}/${month}/${year} ${time}`;
};

//  Format the date part (dd/mm/yyyy) formatted according to IST (Indian Standard Time) timezone.
export let convertToISTDateFormat = (dateString = new Date()) => {
  const date = new Date(dateString);
  const options = { timeZone: "Asia/Calcutta" };

  const day = date.toLocaleString("en-US", { ...options, day: "2-digit" });
  const month = date.toLocaleString("en-US", { ...options, month: "2-digit" });
  const year = date.toLocaleString("en-US", { ...options, year: "numeric" });

  return `${day}/${month}/${year}`;
};

const invertColor = (hex) => {
  // Convert hex to RGB
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  // Calculate the perceived luminance of the color
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Choose white or black text based on the luminance
  return luminance > 0.5 ? '#000' : '#fff';
};

// Generate unique color from text
export const generateColor = (text = "") => {
  const hexColor = `#${md5(text.toLowerCase() ?? "").substring(0, 6)}`;
  return { background: hexColor, color: invertColor(hexColor) }
};

// Replace underscore with space and capitalize each word
export const replaceUnderscoreAndCapitalize = (text = "") => {
  return text?.toLowerCase()?.split("_")
    ?.map(word => capitalize(word || ""))
    ?.join(" ")
}

// Convert camel case text to human readable
export const convertCamelCaseToHumanReadable = (text = "") => {
  return text.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase().replace(/^./, str => str.toUpperCase());
}

// Truncates a number to fraction digits decimal places without rounding off 
export const truncateDecimals = (number, fractionDigits) => {
  const decimalIndex = number.toString().indexOf('.');
  if (decimalIndex === -1) {
    return number;
  }
  return Number(number.toString().slice(0, decimalIndex + fractionDigits + 1));
}

export const processDateTime = (data) => {
  // iterate data
  if (data !== undefined) {
    data.forEach((item) => {
      // convert date time to local time and format it to yyyy-mm-dd , hour-mins-secs AM/PM format
      item.createdAt = new Date(item.createdAt)
        .toLocaleString()
        .replace(/\//g, "-");
      item.updatedAt = new Date(item.updatedAt)
        .toLocaleString()
        .replace(/\//g, "-");

      if (item?.canceledAt !== null && item?.canceledAt !== undefined) {
        item.canceledAt = new Date(item?.canceledAt)
          .toLocaleString()
          .replace(/\//g, "-");
      }

      if (item?.completedAt !== null && item?.completedAt !== undefined) {
        item.completedAt = new Date(item?.completedAt)
          .toLocaleString()
          .replace(/\//g, "-");
      }


    });
  }
  return data;
};

// function to handle the comma seperated tags
export let handleCommaSeparatedTags = (tags) => {
  if (tags.length === 0) {
    return tags;
  }
  var commaSeperatedTags = tags[tags.length - 1].split(",");
  tags.pop();
  while (commaSeperatedTags.length > 0) {
    var tag = commaSeperatedTags.pop().trim();
    if (tag.length > 0) {
      tags.push(tag);
    }
  }
  return tags;
}

export const convertUTCToIST = (dateTimeString) => {
  if (!dateTimeString) {
    return null;
  }

  const date = new Date(dateTimeString);

  if (isNaN(date.getTime())) {
    return null;
  }

  const istOffset = (5 * 60 + 30) * 60 * 1000; // IST offset in milliseconds
  const istDate = new Date(date.getTime() + istOffset);

  const year = istDate.getFullYear();
  const month = String(istDate.getMonth() + 1).padStart(2, "0");
  const day = String(istDate.getDate()).padStart(2, "0");
  const hour = String(istDate.getHours()).padStart(2, "0");
  const minute = String(istDate.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hour}:${minute}`;
}

export const convertISTToUTC = (dateTimeString) => {

  if (!dateTimeString) {
    return null;
  }

  const [datePart, timePart] = dateTimeString.split("T");
  const [hour, minute] = timePart.split(":");
  const istDate = new Date(`${datePart}T${hour}:${minute}:00+05:30`); // Treat input as IST

  const utcDate = new Date(istDate.toISOString());

  const year = utcDate.getUTCFullYear();
  const month = String(utcDate.getUTCMonth() + 1).padStart(2, "0");
  const day = String(utcDate.getUTCDate()).padStart(2, "0");
  const hourUTC = String(utcDate.getUTCHours()).padStart(2, "0");
  const minuteUTC = String(utcDate.getUTCMinutes()).padStart(2, "0");

  const result = `${year}-${month}-${day}T${hourUTC}:${minuteUTC}`;
  return result;
}

export let processDateAndStatus = (item) => {
  if (item?.startDate !== null && item?.startDate !== undefined) {
    item.startDate = item.startDate.split("T")[0];
  }
  if (item?.endDate !== null && item?.endDate !== undefined) {
    item.endDate = item.endDate.split("T")[0];
  }
  if (item.publishedAt !== null && item.publishedAt !== undefined) {
    item.publishedAt = new Date(item.publishedAt).toLocaleString();
    if (item.publishedAt === "Invalid Date") {
      item.publishedAt = null;
    }
  }
  if (item.createdAt !== null && item.createdAt !== undefined) {
    item.createdAt = new Date(item.createdAt).toLocaleString();
    if (item.createdAt === "Invalid Date") {
      item.createdAt = null;
    }
  }
  if (item.updatedAt !== null && item.updatedAt !== undefined) {
    item.updatedAt = new Date(item.updatedAt).toLocaleString();
    if (item.updatedAt === "Invalid Date") {
      item.updatedAt = null;
    }
  }
  if (item.deactivatedAt !== null && item.deactivatedAt !== undefined) {
    item.deactivatedAt = new Date(item.deactivatedAt).toLocaleString();
    if (item.deactivatedAt === "Invalid Date") {
      item.deactivatedAt = null;
    }
  }
  if (item.canceledAt !== null && item.canceledAt !== undefined) {
    item.canceledAt = new Date(item.canceledAt).toLocaleString();
    if (item.canceledAt === "Invalid Date") {
      item.canceledAt = null;
    }
  }

  if
    (item.publishedAt === null && (item.publishedAt === "Invalid Date" || item.publishedAt === null) &&
    item.deactivatedAt === null) {
    item.status = "Unpublished";
  }
  if (
    item.publishedAt !== null &&
    item.publishedAt !== "Invalid Date" &&
    item.deactivatedAt === null
  ) {
    item.status = "Activated";
  }
  if (item.publishedAt !== null && item.deactivatedAt !== null) {
    item.status = "Deactivated";
  }
};

export const reduceSentence = (sentence, maxWords = 5) => {
  if (sentence.split(" ").length > maxWords) {
    return sentence.split(" ").slice(0, maxWords).join(" ") + "...";
  }
  return sentence;
};

export const convertBytesToKB = (bytes) => {
  return (bytes / 1024); // Convert bytes to KB
}

export const createDeepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}
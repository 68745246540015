import React, { Component, useState, useEffect, useRef } from "react";
import { Card, CardBody, Container, Label, Row, Col} from "reactstrap";
import { Button, Badge } from "react-bootstrap";
import {
  getUsers,
  activateUser,
  deactivateUser,
  editUser,
} from "../../../utilities/apiUtils/users";
import { getRolesDropdown } from "../../../utilities/apiUtils/roles";
import {
  errorDescription,
  permissions,
  allActions,
  error,
  redirectOnTokenExpiry,
} from "../../../utilities/commonUtil";
import ResponseModal from "../ResponseModal";
import Select from "react-select";
import { columns } from "./userTableColumns";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import EditUserFormModal from "./EditUserFormModal";
import ConfirmationModal from "../ConfirmationModal";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import Search from "../../../components/Search";
import {
  success, userUpdateSuccessText
} from "../../../config/UserConfig";
import { statusOptions } from "../../../config/UserConfig";

const { SearchBar } = Search;

const ExpandableRowsTable = () => {
  const [userData, setUserData] = useState([]);
  const [allUsersData, setAllUsersData] = useState([]);
  const [column] = useState(columns);
  const [failuremodaltext, setFailuremodaltext] = useState("");
  const [failuremodaldisplay, setFailuremodaldisplay] = useState(false);
  const [successmodaltext, setSuccessmodaltext] = useState("");
  const [successmodaldisplay, setSuccessmodaldisplay] = useState(false);
  const [showUserEditForm, setShowUserEditForm] = useState(false);
  const [modalData, setModalData] = useState({});
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [id, setId] = useState(null);
  const [action, setAction] = useState("");
  const [allowedPermissions] = useState(retrieveFromLocalStorage("userPermissions"));
  const [status, setStatus] = useState({ value: "activated", label: "Activated" });
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const searchInputRef = useRef(null);

  useEffect(() => {
    fetchUserData();
    fetchRoles();
  }, []);

  useEffect(() => {
    filterUsers();
  }, [status, selectedRoles, allUsersData, searchValue]);

  const fetchRoles = async () => {
    try {
      const rolesData = await getRolesDropdown();
      setRoles(rolesData);
    } catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  };

  async function fetchUserData() {
    try {
      let response = await getUsers()
      if (response.length) {
        setAllUsersData(response);
        filterUsers();
      } else if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectOnTokenExpiry();
        showErrorModal(errorDescription(response.errorCode));
      }
    } catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  }

  const filterUsers = () => {
    if (!allUsersData.length) return;

    let filteredData = [...allUsersData];

    if (status.value !== "all") {
      if (status.value === "activated") {
        filteredData = filteredData.filter(user => user.deactivatedAt === null);
      } else if (status.value === "deactivated") {
        filteredData = filteredData.filter(user => user.deactivatedAt !== null);
      }
    }

    if (selectedRoles.length > 0) {
      const selectedRoleIds = selectedRoles.map(role => role.value);
      filteredData = filteredData.filter(user =>
        selectedRoleIds.every(selectedRoleId => 
          user.roles.some(userRole => userRole.id === selectedRoleId)
        )
      );
    }

    if (searchValue) {
      const search = searchValue.toLowerCase();
      filteredData = filteredData.filter(user =>
        user.id?.toString()?.toLowerCase()?.includes(search) ||
        user.username?.toLowerCase()?.includes(search) ||
        user.roles?.some(role => role.name?.toLowerCase()?.includes(search))
      );
    }

    setUserData(filteredData);
  };

  let handleStatusChange = (selectedStatus) => {
    setStatus({ value: selectedStatus.value, label: selectedStatus.label });
  };

  let handleRolesChange = (selectedOptions) => {
    setSelectedRoles(selectedOptions || []);
  };

  let handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  let closeUserEditForm = () => {
    setShowUserEditForm(false);
    setModalData({});
  };

  let showErrorModal = (text) => {
    setFailuremodaltext(text);
    setFailuremodaldisplay(true);
  };

  let failureModalClose = () => {
    setFailuremodaldisplay(false);
  };

  let successModalClose = async () => {
    setSuccessmodaldisplay(false);
    try {
      let response = await getUsers();
      if (response.length) {
        setAllUsersData(response);
        filterUsers();
      } else if (response.errorCode) {
        showErrorModal(errorDescription(response.errorCode));
      }
    } catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  };

  let showSuccessModal = (text) => {
    setSuccessmodaltext(text);
    setSuccessmodaldisplay(true);
  };

  let disableDeactivateButton = (row) => {
    if (row.deactivatedAt === null) return false;
    return true;
  };

  let disableActivateButton = (row) => {
    if (row.deactivatedAt !== null) return false;
    return true;
  };

  let deactivate = async (id) => {
    try {
      let response = await deactivateUser(id);
      if (response.success) {
        showSuccessModal(errorDescription(response.success));
      } else {
        showErrorModal(errorDescription(response.errorCode));
      }
    } catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  };

  let activate = async (id) => {
    try {
      let response = await activateUser(id);
      if (response.success) {
        showSuccessModal(errorDescription(response.success));
      } else {
        showErrorModal(errorDescription(response.errorCode));
      }
    } catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  };

  let update = async (data) => {
    try {
      let response = await editUser(data);
      if (response.status === success) {
        setShowUserEditForm(false);
        setModalData({});
        showSuccessModal(userUpdateSuccessText);
      } else {
        showErrorModal(errorDescription(response.errorCode));
      }
    } catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  };

  let showEditModal = (row) => {
    let selectedRoles = [];
    let selectedRoleIds = [];
    row.roles.forEach((role) => {
      let data = {
        value: role.id,
        label: role.name,
      };
      selectedRoles.push(data);
      selectedRoleIds.push(role.id);
    });

    let formData = {
      id: row.id,
      username: row.username,
      selectedRoles: selectedRoles,
      selectedRoleIds: selectedRoleIds,
    };
    setModalData(formData);
    setShowUserEditForm(true);
  };

  let setConfirmationModalState = (id, event) => {
    setAction(event);
    setConfirmationModalIsOpen(true);
    setId(id);
  };

  let showEditButton = () => {
    return allowedPermissions.includes(permissions.updateUser);
  };

  let showActivateButton = () => {
    return allowedPermissions.includes(permissions.activateUser);
  };

  let showDeactivateButton = () => {
    return allowedPermissions.includes(permissions.deactivateUser);
  };

  const handleClick = () => {
    setStatus({ value: "activated", label: "Activated" });
    setSelectedRoles([]);
    setSearchValue("");
  };

  const ClearButton = () => {
    return (
      <Button
        style={{ float: "right" }}
        className="form-control-lg"
        onClick={handleClick}
      >
        Reset Filters
      </Button>
    );
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <ul>
          <li>{`Created At : ${row.createdAt}`}</li>
          <li>{`Deactivated At : ${row.deactivatedAt}`}</li>
          <li>
            Roles:
            {Object.values(row.roles).map((role, index) => {
              return (
                <Badge key={index} className="badge-secondary ml-2">
                  {role.name}
                </Badge>
              );
            })}
          </li>
        </ul>
        {showEditButton() ? (
          <Button className="mx-3" onClick={() => showEditModal(row)}>
            Edit
          </Button>
        ) : null}
        {showDeactivateButton() ? (
          <Button
            className="mx-3 btn-danger"
            disabled={disableDeactivateButton(row)}
            onClick={(event) =>
              setConfirmationModalState(row.id, event.target.innerHTML)
            }
          >
            Deactivate
          </Button>
        ) : null}
        {showActivateButton() ? (
          <Button
            className="mx-3 btn-success"
            disabled={disableActivateButton(row)}
            onClick={(event) =>
              setConfirmationModalState(row.id, event.target.innerHTML)
            }
          >
            Activate
          </Button>
        ) : null}
        {Object.keys(modalData).length > 0 ? (
          <EditUserFormModal
            show={showUserEditForm}
            onHide={closeUserEditForm}
            data={modalData}
            update={update}
          />
        ) : null}
      </div>
    ),
  };

  return (
    <Card>
      <CardBody>
        <div>
          <Row>
            <Col md={3} sm={12}>
              <Label>Search</Label>
              <Search
                placeholder="Enter"
                value={searchValue}
                onChange={handleSearchChange}
              />
            </Col>
            <Col md={3} sm={12}>
              <Label>Status</Label>
              <Select
                className="react-select-container mb-4"
                classNamePrefix="react-select"
                placeholder="Select Status"
                onChange={handleStatusChange}
                options={statusOptions}
                value={status}
              />
            </Col>
            <Col md={3} sm={12}>
              <Label>Roles</Label>
              <Select
                isMulti
                className="react-select-container mb-4"
                classNamePrefix="react-select"
                placeholder="Select Roles"
                onChange={handleRolesChange}
                options={roles}
                value={selectedRoles}
              />
            </Col>
            <Col md={3} sm={12} className="d-flex justify-content-md-start justify-content-center mt-3 mt-md-4">
            <ClearButton>Reset Filters</ClearButton>
            </Col>
          </Row>
          <BootstrapTable
            bootstrap4
            bordered={false}
            keyField="id"
            data={userData}
            columns={column}
            expandRow={expandRow}
            pagination={paginationFactory({
              sizePerPage: 10,
              sizePerPageList: [5, 10, 25, 50],
            })}
            defaultSorted={[
              {
                dataField: "id",
                order: "asc",
              },
            ]}
          />
        </div>
      </CardBody>
      <ResponseModal
        show={successmodaldisplay}
        onHide={successModalClose}
        modalheading={"Success"}
        modaltext={successmodaltext}
      />
      <ResponseModal
        show={failuremodaldisplay}
        onHide={failureModalClose}
        modalheading={"Error"}
        modaltext={failuremodaltext}
      />
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={action}
        onConfirmationTrue={() => {
          setConfirmationModalIsOpen(false);
          if (action === allActions.deactivate) deactivate(id);
          else if (action === allActions.activate) activate(id);
        }}
        onHide={() => setConfirmationModalIsOpen(false)}
      />
    </Card>
  );
};

class UsersTable extends Component {
  render() {
    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">All Users</h1>

        <ExpandableRowsTable />
      </Container>
    );
  }
}

export default UsersTable;

import React, { useState } from "react";
import TargetingRules from "../TargetingRules";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { permissions } from "../../../utilities/commonUtil";
import { getContentCategoryRules, saveContentCategoryRules } from "../../../utilities/apiUtils/cre";
import { getClients, getRegions, getCities, getCountries, getStickerPackTargetingRules, saveStickerPackTargetingRules } from "../../../utilities/apiUtils/stickers";
import { getRules, saveRules } from "../../../utilities/apiUtils/popTextStyles";

const StyleDetail = (props) => {
    let id = props.match.params.id;

    const [allowedPermissions] = useState(
        retrieveFromLocalStorage("userPermissions")
    );

    const updateRulesAllowed = () => {
        return allowedPermissions.includes(permissions.updateStickerPackRules);
    };

    return (
        <div>
            <TargetingRules
                showAgeGenderRules={true}
                id={id}
                getRules={getStickerPackTargetingRules}
                saveRules={saveStickerPackTargetingRules}
                getClients={getClients}
                getCountries={getCountries}
                getRegions={getRegions}
                getCities={getCities}
                updateRulesAllowed={updateRulesAllowed()}
                showHardFiltering={false}
            />
        </div>
    );
};

export default StyleDetail;

import React, { useState, useEffect } from "react";
import { Card, CardBody, Container } from "reactstrap";
import { Button } from "react-bootstrap";
import {
  getPermissions,
  editPermission,
} from "../../../utilities/apiUtils/permissions";
import {
  errorDescription,
  permissions,
  error,
  redirectOnTokenExpiry,
} from "../../../utilities/commonUtil";
import ResponseModal from "../ResponseModal";
import { columns } from "./permissionTableColumns";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import EditPermissionFormModal from "./components/EditPermissionFormModal";
import {
  success,
  permissionUpdateSuccessful,
} from "../../../config/UserConfig";

const { SearchBar } = Search;

const ExpandableRowsTable = () => {
  const [permissionData, setPermissionData] = useState([]);
  const [column] = useState(columns);
  const [failuremodaltext, setFailuremodaltext] = useState("");
  const [modalData, setModalData] = useState({});
  const [failuremodaldisplay, setFailuremodaldisplay] = useState(false);
  const [showPermissionEditForm, setShowPermissionEditForm] = useState(false);
  const [successModalText, setSuccessmodaltext] = useState("");
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  useEffect(() => {
    let fetchPermissionData = async () => {
      try {
        let response = await getPermissions()
        if (response.length) {
          setPermissionData(response);
        } else if (response.errorCode) {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken
          )
            redirectOnTokenExpiry();
          let text = errorDescription(response.errorCode);
          showErrorModal(text);
        }
      } catch (err) {
        setFailuremodaldisplay(true);
        setFailuremodaltext(errorDescription(error.unexpectedError));
      }
    }
    fetchPermissionData();
  }, []);

  let failureModalClose = () => {
    setFailuremodaldisplay(false);
  };

  let showErrorModal = (text) => {
    setFailuremodaltext(text);
    setFailuremodaldisplay(true);
  };

  let showEditButton = () => {
    return allowedPermissions.includes(permissions.editPermission);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    let response = await getPermissions();
    if (response.length) {
      setPermissionData(response);
    } else if (response.errorCode) {
      let text = errorDescription(response.errorCode);
      showErrorModal(text);
    }
  };

  let showSuccessModal = (text) => {
    setSuccessmodaltext(text);
    setSuccessModalDisplay(true);
  };

  let closePermissionEditForm = () => {
    setShowPermissionEditForm(false);
    setModalData({});
  };

  let showEditModal = (row) => {
    let formData = {
      id: row.id,
      name: row.name,
      description: row.description,
      identifier: row.identifier,
    };

    setModalData(formData);
    setShowPermissionEditForm(true);
  };

  let update = async (updatedData) => {
    let response = await editPermission(updatedData, updatedData.id);
    if (response.status === success) {
      let text = permissionUpdateSuccessful;
      setShowPermissionEditForm(false);
      setSuccessModalDisplay(true);
      showSuccessModal(text);
      setModalData({});
    } else {
      let text = errorDescription(response.errorCode);
      showErrorModal(text);
    }
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <ul>
          <li>{`Description : ${row.description}`}</li>
          <li>{`Created At : ${row.createdAt}`}</li>
          <li>{`Updated At : ${row.updatedAt}`}</li>
        </ul>
        {showEditButton() ? (
          <Button className="mx-3" onClick={() => showEditModal(row)}>
            Edit
          </Button>
        ) : null}
        {Object.keys(modalData).length > 0 ? (
          <EditPermissionFormModal
            show={showPermissionEditForm}
            onHide={closePermissionEditForm}
            data={modalData}
            update={update}
          />
        ) : null}
      </div>
    ),
  };

  return (
    <Card>
      <CardBody>
        <ToolkitProvider
          keyField="id"
          data={permissionData}
          columns={column}
          search
        >
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} />
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                bordered={false}
                keyField="id"
                data={permissionData}
                columns={column}
                expandRow={expandRow}
                pagination={paginationFactory({
                  sizePerPage: 10,
                  sizePerPageList: [5, 10, 25, 50],
                })}
              />
            </div>
          )}
        </ToolkitProvider>
        <ResponseModal
          show={successModalDisplay}
          onHide={successModalClose}
          modalheading={"Success"}
          modaltext={successModalText}
        />
        <ResponseModal
          show={failuremodaldisplay}
          onHide={failureModalClose}
          modalheading={"Error"}
          modaltext={failuremodaltext}
        />
      </CardBody>
    </Card>
  );
};

const PermissionsTable = () => {
  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">All Permissions</h1>

      <ExpandableRowsTable />
    </Container>
  );
};

export default PermissionsTable;

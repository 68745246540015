import React from 'react'
import { Modal, Button, Badge } from "react-bootstrap";
import { supportedExternalMacros } from '../../../config/configSmartSuggestions';

const SupportedMacros = ({ show = false, onHide = () => { } }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header className="bg-light" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Supported Macros
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {supportedExternalMacros.map((macro, index) => (
          <Badge key={index} className="badge-secondary ml-2">
            {macro}
          </Badge>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SupportedMacros
import React from 'react'

const statusFormatter = (cell, row) => {
  return (
    <div className="d-flex align-items-center">
      {cell === "Activated"
        ? <span className="badge-success badge badge-secondary" style={{ width: "1rem", height: "1rem", verticalAlign: "middle" }}> </span>
        : <span className="badge-danger badge badge-secondary" style={{ width: "1rem", height: "1rem", verticalAlign: "middle" }}> </span>
      }
      <div className="ml-1">{cell ?? "-"}</div>
    </div>
  )
}

const booleanValueFormatter = (cell, row) => {
  return (
    <div className="d-flex align-items-center">
      {cell ? "Yes" : "No"}
    </div>
  )
}

const defaultFormatter = (cell, row) => {
  return cell || "-"
}

const placementFormatter = (cell, row) => {
  return (
    <div className="d-flex align-items-center">
      {row.status === "Activated"
        ? <span className="badge-success badge badge-secondary" style={{ width: "1rem", height: "1rem", verticalAlign: "middle" }}> </span>
        : <span className="badge-danger badge badge-secondary" style={{ width: "1rem", height: "1rem", verticalAlign: "middle" }}> </span>
      }
      <div className="ml-1">{cell ?? "-"}</div>
    </div>
  )
}

const vendorFormatter = (cell, row) => {
  return (
    <div className="d-flex align-items-center">
      {row.status === "Activated"
        ? <span className="badge-success badge badge-secondary" style={{ width: "1rem", height: "1rem", verticalAlign: "middle" }}> </span>
        : <span className="badge-danger badge badge-secondary" style={{ width: "1rem", height: "1rem", verticalAlign: "middle" }}> </span>
      }
      <div className="ml-1">{cell ?? "-"}</div>
    </div>
  )
}

export const creativeTypeTableColumns = [
  {
    dataField: "id",
    text: "#",
    sort: true,
    headerAttrs: { width: 100 },
    searchable: true,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "name",
    text: "Name",
    sort: true,
    searchable: true,
    formatter: (cell, row) => defaultFormatter(cell, row),
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  }
];

export const vendorsTableColumns = [
  {
    dataField: "id",
    text: "#",
    sort: true,
    headerAttrs: { width: 60 },
    searchable: true,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "name",
    text: "Name",
    sort: true,
    searchable: true,
    formatter: (cell, row) => vendorFormatter(cell, row),
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "enableTrackerApi",
    text: "Tracker API Enabled",
    sort: true,
    searchable: true,
    formatter: (cell, row) => booleanValueFormatter(cell, row),
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "categoryMappingUrl",
    text: "Category Mapping URL",
    sort: true,
    searchable: true,
    formatter: (cell, row) => defaultFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
];

export const placementsTableColumns = [
  {
    dataField: "description",
    text: "Name",
    sort: true,
    searchable: true,
    formatter: (cell, row) => placementFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "locationName",
    text: "Location",
    sort: true,
    searchable: true,
    formatter: (cell, row) => defaultFormatter(cell, row),
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "clientName",
    text: "Client Name",
    sort: true,
    searchable: true,
    formatter: (cell, row) => defaultFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "creativeTypeName",
    text: "Creative Type",
    sort: true,
    searchable: true,
    formatter: (cell, row) => defaultFormatter(cell, row),
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
];

export const configsTableColumns = [
  {
    dataField: "id",
    text: "#",
    sort: true,
    headerAttrs: { width: 60 },
    searchable: true,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    searchable: true,
    formatter: (cell, row) => statusFormatter(cell, row),
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "configUpdateInterval",
    text: "Config Update Interval",
    sort: true,
    searchable: true,
    formatter: (cell, row) => defaultFormatter(cell, row),
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "appMetadataUrl",
    text: "App Metadata URL",
    sort: true,
    searchable: true,
    formatter: (cell, row) => defaultFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
];

export const placementVendorTableColumns = [
  {
    dataField: "id",
    text: "#",
    sort: true,
    headerAttrs: { width: 100 },
    searchable: true,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "placementDesc",
    text: "Placement Name",
    sort: true,
    searchable: true,
    formatter: (cell, row) => defaultFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "clientAdVendorName",
    text: "Vendor Name",
    sort: true,
    searchable: true,
    formatter: (cell, row) => defaultFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "clientName",
    text: "Client Name",
    sort: true,
    searchable: true,
    formatter: (cell, row) => defaultFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "maxAds",
    text: "Max Ads",
    sort: true,
    searchable: true,
    formatter: (cell, row) => defaultFormatter(cell, row),
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "adFetchMechanismName",
    text: "Ad Fetch Mechanism",
    sort: true,
    searchable: true,
    formatter: (cell, row) => defaultFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
];
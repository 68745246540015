import { retrieveFromLocalStorage } from "../utilities/localStorageUtil";
import { convertCamelCaseToHumanReadable, permissions } from "../utilities/commonUtil";
import moment from "moment";

const allowedPermissions = retrieveFromLocalStorage("userPermissions");

export let showOrderListSmartSuggestionButton = () => {
  return allowedPermissions.includes(permissions.smartSuggestionOrderList);
};

export let showOrderEditSmartSuggestionButton = () => {
  return allowedPermissions.includes(permissions.smartSuggestionOrderEdit);
};

export let showAddSmartSuggestionButton = () => {
  return allowedPermissions.includes(permissions.smartSuggestionCreate);
};

export let showEditSmartSuggestionButton = () => {
  return allowedPermissions.includes(permissions.smartSuggestionEdit);
};

export let showPublishSmartSuggestionButton = () => {
  return allowedPermissions.includes(permissions.smartSuggestionPublish);
};

export let showUnpublishSmartSuggestionButton = () => {
  return allowedPermissions.includes(permissions.smartSuggestionUnpublish);
};

export let showActivateSmartSuggestionButton = () => {
  return allowedPermissions.includes(permissions.smartSuggestionActivate);
};

export let showDeactivateSmartSuggestionButton = () => {
  return allowedPermissions.includes(permissions.smartSuggestionDeactivate);
};

export let showTargetingRulesListSmartSuggestionButton = () => {
  return allowedPermissions.includes(permissions.smartSuggestionTargetingRuleList);
};

export let showPreferredCategoriesSmartSuggestionButton = () => {
  return allowedPermissions.includes(permissions.smartSuggestionPreferredCategoriesList);
};

export let showManageSmartSuggestionButton = () => {
  return allowedPermissions.includes(permissions.adsMetadataOverrideDashboard);
};

export let showAdMetadataUpdateButton = () => {
  return allowedPermissions.includes(permissions.adMetadataUpdate);
};

export let showAdMetadataResetButton = () => {
  return allowedPermissions.includes(permissions.adMetadataReset);
};

export let showAdPromoteUpdateButton = () => {
  return allowedPermissions.includes(permissions.adPromoteUpdate);
};

export let showPromotedAdsOrderUpdateButton = () => {
  return allowedPermissions.includes(permissions.promotedAdsOrderUpdate);
};

export let showFixedPositionsUpdateButton = () => {
  return allowedPermissions.includes(permissions.fixedPositionsUpdate);
};

export let showStoryAdUpdateButton = () => {
  return allowedPermissions.includes(permissions.storyAdUpdate);
};

export let showStoryAdResetButton = () => {
  return allowedPermissions.includes(permissions.storyAdReset);
};

export let showSmartSuggestionsDashboardAdminOptions = () => {
  return allowedPermissions.includes(permissions.smartSuggestionsDashboardAdmin);
};

export let isPartnerAd = (id) => {
  return id >= 1000000;
};

export const getCreativeUILengthLimits = () => {
  if (showSmartSuggestionsDashboardAdminOptions()) {
    return {
      tilesCard: {
        title: 255,
        titleLong: 500,
        subTitle: 255
      },
      detailsCard: {
        title: 500,
        subTitle: 255
      },
      productsCard: {
        title: 500,
        subTitle: 255,
        description: 255
      },
      productsCardTwo: {
        title: 500,
        subTitle: 255,
        description: 255
      }
    }
  }
  return {
    tilesCard: {
      title: 20,
      titleLong: 25,
      subTitle: 35
    },
    detailsCard: {
      title: 25,
      subTitle: 35
    },
    productsCard: {
      title: 20,
      subTitle: 35,
      description: 25
    },
    productsCardTwo: {
      title: 20,
      subTitle: 35,
      description: 25
    }
  }
}

export const maxAlternateLongTitles = 50;

export const titleLength = 50;

export const subTitleLength = 100;

export const longTitleLength = 150;

export const descriptionLength = 100;

export const staticIconSize = 204800;

export const animatedIconSize = 204800;

export const preferredClustersSize = 18e+7;

export const uiProductsCardTwoAspectRatio = 1.333;

export const uiProductsCardThreeAspectRatio = 1.778;

export const maxKeywords = 20;

export const shareUrlPlaceholder = "__SHARE_URL__"

export const dateError = "End date should be greater than start date";

export const imageFormatError = "Please upload in accepted format";

export const staticIconError = "Please upload static icon";

export const aspectRatioError = "Aspect ratio is not 1:1";

export const uiProductsCardTwoAspectRatioError = "Aspect ratio is not 4:3";

export const uiProductsCardThreeAspectRatioError = "Aspect ratio is not 16:9";

export const fileSizeExceedError = "File is too large";

export const invalidAspectRatioError = "Aspect ratio is not accepted";

export const unsupportedMacroError = "The URL contains macros which are not supported by our system. This might lead to loss in attribution. Please contact backend team for more details.";

export const clickTrackerMissingError = "Redirection URL requires at least one Click Tracker. Please add a click tracker, or use Click URL instead of Redirection URL";

export const targetingRulesNotSetError = "Please set targeting rules in order to publish the smart suggestion";

export const storyAdLinkError = "Please search for a valid story ID or remove it";

export const storyAdError = "Please upload either video asset or image asset";

export const creativeUITypeAcceptedIconFormat = ["image/png", "image/jpg", "image/jpeg", "image/webp", "image/gif"]

export const uiProductsCardTwoImagePlaceholder = "https://assets.bobblekeyboard.net/ads/icon-images/94dff3d4-baad-35ff-8d9d-65f0b0a98bed.webp"

export const uiProductsCardThreeImagePlaceholder = "https://assets.bobblekeyboard.net/ads/icon-images/6aae3ab1-5d8f-e126-5af0-6c26d52a287c.webp"

export const startAtSuffix = " 00:00:00"

export const endAtSuffix = " 23:59:59"

export const smartSuggestionResponses = {
  updatedSuccessfully: "Smart Suggestion Updated Successfully",
  addedSuccessfully: "Smart Suggestion Added Successfully",
};

export const statusOptions = [
  { value: "", label: "All" },
  { value: "Activated", label: "Activated" },
  { value: "Deactivated", label: "Deactivated" },
  { value: "Unpublished", label: "Unpublished" },
  { value: "Expired", label: "Expired" },
  { value: "Scheduled", label: "Scheduled" },
];

export const statusMetaOverrideOptions = [
  { value: "", label: "All" },
  { value: "Activated", label: "Activated" },
  { value: "Unpublished", label: "Unpublished" },
];

export const storyAdFilterOptions = [
  { value: "", label: "All" },
  { value: "storyAds", label: "Story Ads" },
  { value: "nonStoryAds", label: "Non-Story Ads" }
];

export const smartSuggestionsAllActions = {
  submitSmartSuggestion: "submit",
};

export const metadataOverrideAllActions = {
  resetMetadataOverride: "reset override metadata",
  updatePromotedAdsOrder: "update promoted ads order",
  copyDefaultAppearance: "copy default appearance to all creative types",
  resetStoryAd: "reset story ad"
};

export const suggestionTypeOptions = [
  {
    value: "app_install",
    label: "App Install",
    target: {
      name: "type",
      value: {
        value: "app_install",
        label: "App Install"
      }
    }
  },
  {
    value: "web_campaign",
    label: "Web Campaign",
    target: {
      name: "type",
      value: {
        value: "web_campaign",
        label: "Web Campaign"
      }
    }
  },
  {
    value: "chatbot",
    label: "Chatbot",
    target: {
      name: "type",
      value: {
        value: "chatbot",
        label: "Chatbot"
      }
    }
  },
  {
    value: "product",
    label: "Product",
    target: {
      name: "type",
      value: {
        value: "product",
        label: "Product"
      }
    }
  }
]

export const partnerTypeOptions = [
  {
    value: "affinity",
    label: "Affinity",
    target: {
      name: "partnerName",
      value: {
        value: "affinity",
        label: "Affinity"
      }
    }
  },
  {
    value: "value_leaf",
    label: "Value Leaf",
    target: {
      name: "partnerName",
      value: {
        value: "value_leaf",
        label: "Value Leaf"
      }
    }
  },
  {
    value: "mob_avenue",
    label: "Mob Avenue",
    target: {
      name: "partnerName",
      value: {
        value: "mob_avenue",
        label: "Mob Avenue"
      }
    }
  },
  {
    value: "zupee",
    label: "Zupee",
    target: {
      name: "partnerName",
      value: {
        value: "zupee",
        label: "Zupee"
      }
    }
  },
  {
    value: "AM",
    label: "AM",
    target: {
      name: "partnerName",
      value: {
        value: "AM",
        label: "AM"
      }
    }
  },
  {
    value: "Affiliate",
    label: "Affiliate",
    target: {
      name: "partnerName",
      value: {
        value: "Affiliate",
        label: "Affiliate"
      }
    }
  },
  {
    value: "other",
    label: "Other",
    target: {
      name: "partnerName",
      value: {
        value: "other",
        label: "Other"
      }
    }
  },
]

export const actionTypeOptions = [
  {
    value: "browser",
    label: "Browser",
    target: {
      name: "actionType",
      value: {
        value: "browser",
        label: "Browser"
      }
    }
  },
  {
    value: "browser_redirect",
    label: "Browser Redirect",
    target: {
      name: "actionType",
      value: {
        value: "browser_redirect",
        label: "Browser Redirect"
      }
    }
  }
]

export const packageNameOptions = [
  {
    value: "com.whatsapp",
    label: "com.whatsapp",
  },
  {
    value: "com.gbwhatsapp",
    label: "com.gbwhatsapp",
  },
  {
    value: "com.whatsapp.w4b",
    label: "com.whatsapp.w4b",
  },
  {
    value: "com.fmwhatsapp",
    label: "com.fmwhatsapp",
  },
  {
    value: "com.yowhatsapp",
    label: "com.yowhatsapp",
  },
  {
    value: "com.gbwhatsapp3",
    label: "com.gbwhatsapp3"
  }
]

export const storyCTASharePackageNameOptions = [
  {
    value: "com.whatsapp",
    label: "com.whatsapp",
  },
  {
    value: "com.facebook.ocra",
    label: "com.facebook.ocra",
  },
  {
    value: "com.facebook.katana",
    label: "com.facebook.katana",
  },
  {
    value: "com.instagram.android",
    label: "com.instagram.android",
  }
]

export const trackerTypeOptions = [
  {
    value: "click",
    label: "Click",
    target: {
      name: "type",
      value: {
        value: "click",
        label: "Click"
      }
    }
  },
  {
    value: "impression",
    label: "Impression",
    target: {
      name: "type",
      value: {
        value: "impression",
        label: "Impression"
      }
    }
  }
]

export const modifiedOptions = [
  {
    value: "all",
    label: "All",
    target: {
      name: "modified",
      value: {
        value: "all",
        label: "All"
      }
    }
  },
  {
    value: "modified",
    label: "Modified",
    target: {
      name: "modified",
      value: {
        value: "modified",
        label: "Modified"
      }
    }
  },
  {
    value: "unmodified",
    label: "Unmodified",
    target: {
      name: "modified",
      value: {
        value: "unmodified",
        label: "Unmodified"
      }
    }
  }
]

export const storyAdsOptions = [
  {
    value: "all",
    label: "All",
    target: {
      name: "storyAds",
      value: {
        value: "all",
        label: "All"
      }
    }
  },
  {
    value: "storyAds",
    label: "Story Ads",
    target: {
      name: "storyAds",
      value: {
        value: "storyAds",
        label: "Story Ads"
      }
    }
  },
  {
    value: "nonStoryAds",
    label: "Non-Story Ads",
    target: {
      name: "storyAds",
      value: {
        value: "nonStoryAds",
        label: "Non-Story Ads"
      }
    }
  }
]

export const adServerVersionOptions = [
  {
    value: "4",
    label: "4",
    target: {
      name: "adServerVersion",
      value: {
        value: "4",
        label: "4"
      }
    }
  },
  {
    value: "5",
    label: "5",
    target: {
      name: "adServerVersion",
      value: {
        value: "5",
        label: "5"
      }
    }
  },
  {
    value: "6",
    label: "6",
    target: {
      name: "adServerVersion",
      value: {
        value: "6",
        label: "6"
      }
    }
  },
  {
    value: "7",
    label: "7",
    target: {
      name: "adServerVersion",
      value: {
        value: "7",
        label: "7"
      }
    }
  },
]

export const fixedPositionTypeOptions = [
  {
    value: "ad",
    label: "Ad",
    target: {
      name: "type",
      value: {
        value: "ad",
        label: "Ad"
      }
    }
  },
  {
    value: "provider",
    label: "Provider",
    target: {
      name: "type",
      value: {
        value: "provider",
        label: "Provider"
      }
    }
  }
]

export const clientOptions = [
  {
    value: "7wZFJWA5chjgat68y826IAIKQ6s197RM",
    label: "Bobble Android",
    target: {
      name: "client",
      value: {
        value: "7wZFJWA5chjgat68y826IAIKQ6s197RM",
        label: "Bobble Android"
      }
    }
  },
  {
    value: "com.mint.keyboard",
    label: "Com.Mint",
    target: {
      name: "client",
      value: {
        value: "com.mint.keyboard",
        label: "Com.Mint"
      }
    }
  },
  {
    value: "ai.mint.keyboard",
    label: "AI.Mint",
    target: {
      name: "client",
      value: {
        value: "ai.mint.keyboard",
        label: "AI.Mint"
      }
    }
  },
  {
    value: "rsEBSwo0yrDvRns7BE5nhzQqOgs3L1N0",
    label: "Malayalam Keyboard",
    target: {
      name: "client",
      value: {
        value: "rsEBSwo0yrDvRns7BE5nhzQqOgs3L1N0",
        label: "Malayalam Keyboard"
      }
    }
  },
  {
    value: "hbobVIWvQuV01gTBlnL94VKTLkD6t82hM2lVTvnM",
    label: "Bangla Keyboard",
    target: {
      name: "client",
      value: {
        value: "hbobVIWvQuV01gTBlnL94VKTLkD6t82hM2lVTvnM",
        label: "Bangla Keyboard"
      }
    }
  },
  {
    value: "dVQYCMXRB3rZ7IS3CyhtBy3TQbjSuNB8Nouos1E9",
    label: "Hindi Keyboard",
    target: {
      name: "client",
      value: {
        value: "dVQYCMXRB3rZ7IS3CyhtBy3TQbjSuNB8Nouos1E9",
        label: "Hindi Keyboard"
      }
    }
  },
  {
    value: "mDfMe0f6rmvMVLA4KUINUtysfgnVINPfIuVkMcxl",
    label: "Marathi Keyboard",
    target: {
      name: "client",
      value: {
        value: "mDfMe0f6rmvMVLA4KUINUtysfgnVINPfIuVkMcxl",
        label: "Marathi Keyboard"
      }
    }
  }
]

export const efftectOptions = [
  {
    value: "allow",
    label: "Allow",
    target: {
      name: "effect",
      value: {
        value: "allow",
        label: "Allow"
      }
    }
  },
  {
    value: "deny",
    label: "Deny",
    target: {
      name: "effect",
      value: {
        value: "deny",
        label: "Deny"
      }
    }
  }
]

export const storyAdCtaItemTypeOptions = [
  {
    value: "click",
    label: "Click",
    target: {
      name: "type",
      value: {
        value: "click",
        label: "Click"
      }
    }
  },
  {
    value: "share",
    label: "Share",
    target: {
      name: "type",
      value: {
        value: "share",
        label: "Share"
      }
    }
  }
]

export const categoryOptions = [
  {
    value: "HEALTHCARE",
    label: "Healthcare",
    target: {
      name: "category",
      value: {
        value: "HEALTHCARE",
        label: "Healthcare"
      }
    }
  },
  {
    value: "SHOPPING",
    label: "Shopping",
    target: {
      name: "category",
      value: {
        value: "SHOPPING",
        label: "Shopping"
      }
    }
  },
  {
    value: "FASHION",
    label: "Fashion",
    target: {
      name: "category",
      value: {
        value: "FASHION",
        label: "Fashion"
      }
    }
  },
  {
    value: "QUICK_ECOM",
    label: "Quick E-commerce",
    target: {
      name: "category",
      value: {
        value: "QUICK_ECOM",
        label: "Quick E-commerce"
      }
    }
  },
  {
    value: "PAYMENTS",
    label: "Payments",
    target: {
      name: "category",
      value: {
        value: "PAYMENTS",
        label: "Payments"
      }
    }
  },
  {
    value: "BNPL",
    label: "Buy Now, Pay Later",
    target: {
      name: "category",
      value: {
        value: "BNPL",
        label: "Buy Now, Pay Later"
      }
    }
  },
  {
    value: "LOAN",
    label: "Loan",
    target: {
      name: "category",
      value: {
        value: "LOAN",
        label: "Loan"
      }
    }
  },
  {
    value: "INSURANCE",
    label: "Insurance",
    target: {
      name: "category",
      value: {
        value: "INSURANCE",
        label: "Insurance"
      }
    }
  },
  {
    value: "INVESTMENT",
    label: "Investment",
    target: {
      name: "category",
      value: {
        value: "INVESTMENT",
        label: "Investment"
      }
    }
  },
  {
    value: "MOBILE_BANKING",
    label: "Mobile Banking",
    target: {
      name: "category",
      value: {
        value: "MOBILE_BANKING",
        label: "Mobile Banking"
      }
    }
  },
  {
    value: "NEO_BANKING",
    label: "Neobanking",
    target: {
      name: "category",
      value: {
        value: "NEO_BANKING",
        label: "Neobanking"
      }
    }
  },
  {
    value: "CRYPTO",
    label: "Cryptocurrency",
    target: {
      name: "category",
      value: {
        value: "CRYPTO",
        label: "Cryptocurrency"
      }
    }
  },
  {
    value: "ACTION_GAMES",
    label: "Action Games",
    target: {
      name: "category",
      value: {
        value: "ACTION_GAMES",
        label: "Action Games"
      }
    }
  },
  {
    value: "ADVENTURE_GAMES",
    label: "Adventure Games",
    target: {
      name: "category",
      value: {
        value: "ADVENTURE_GAMES",
        label: "Adventure Games"
      }
    }
  },
  {
    value: "ARCADE_GAMES",
    label: "Arcade Games",
    target: {
      name: "category",
      value: {
        value: "ARCADE_GAMES",
        label: "Arcade Games"
      }
    }
  },
  {
    value: "BOARD_GAMES",
    label: "Board Games",
    target: {
      name: "category",
      value: {
        value: "BOARD_GAMES",
        label: "Board Games"
      }
    }
  },
  {
    value: "CASINO_GAMES",
    label: "Casino Games",
    target: {
      name: "category",
      value: {
        value: "CASINO_GAMES",
        label: "Casino Games"
      }
    }
  },
  {
    value: "STREAMING_GAMES",
    label: "Streaming Games",
    target: {
      name: "category",
      value: {
        value: "STREAMING_GAMES",
        label: "Streaming Games"
      }
    }
  },
  {
    value: "FANTASY_GAMES",
    label: "Fantasy Games",
    target: {
      name: "category",
      value: {
        value: "FANTASY_GAMES",
        label: "Fantasy Games"
      }
    }
  },
  {
    value: "OTT_AND_ENTERTAINMENT",
    label: "OTT & Entertainment",
    target: {
      name: "category",
      value: {
        value: "OTT_AND_ENTERTAINMENT",
        label: "OTT & Entertainment"
      }
    }
  },
  {
    value: "MESSAGING",
    label: "Messaging",
    target: {
      name: "category",
      value: {
        value: "MESSAGING",
        label: "Messaging"
      }
    }
  },
  {
    value: "TRAVEL",
    label: "Travel",
    target: {
      name: "category",
      value: {
        value: "TRAVEL",
        label: "Travel"
      }
    }
  },
  {
    value: "MUSIC",
    label: "Music",
    target: {
      name: "category",
      value: {
        value: "MUSIC",
        label: "Music"
      }
    }
  },
  {
    value: "FOOD_ORDERING",
    label: "Food Ordering",
    target: {
      name: "category",
      value: {
        value: "FOOD_ORDERING",
        label: "Food Ordering"
      }
    }
  }
];

export const regionOptions = [
  {
    value: "GLOBAL",
    label: "GLOBAL",
    target: {
      name: "region",
      value: {
        value: "GLOBAL",
        label: "GLOBAL"
      }
    }
  },
  {
    value: "DL",
    label: "DELHI",
    target: {
      name: "region",
      value: {
        value: "DL",
        label: "DELHI"
      }
    }
  },
  {
    value: "TN",
    label: "TAMIL NADU",
    target: {
      name: "region",
      value: {
        value: "TN",
        label: "TAMIL NADU"
      }
    }
  }
]

export const supportedExternalMacros = ["{device_identifier}", "{click_id}", "{subid}", "{af_siteid}", "{af_sub_siteid}", "{af_c_id}", "{af_adset}", "{af_adset_id}", "{af_ad}", "{af_ad_id}", "{af_ad_type}", "{af_channel}", "{af_cost_model}", "{af_cost_currency}", "{af_cost_value}", "{af_sub1}", "{af_sub2}", "{af_sub3}", "{af_sub4}", "{af_sub5}", "{clickid}", "{is_retargeting}", "{android_id}", "{advertising_id}", "{idfa}", "{ua}", "{ip}"]

export let processDateAndStatus = (item) => {
  const diffEnd = parseDateInMoment(convertUTCToIST(item?.endAt)).startOf('day').diff(moment().startOf('day'), 'days');
  const diffStart = parseDateInMoment(convertUTCToIST(item?.startAt)).startOf('day').diff(moment().startOf('day'), 'days');

  if (item.publishAt !== null) {
    item.publishAt = new Date(item.publishAt).toLocaleString();
    if (item.publishAt === "Invalid Date") {
      item.publishAt = null;
    }
  }
  if (item.deactivateAt !== null) {
    item.deactivateAt = new Date(item.deactivateAt).toLocaleString();
    if (item.deactivateAt === "Invalid Date") {
      item.deactivateAt = null;
    }
  }
  if (
    (item.publishAt === null || item.publishAt === "Invalid Date") &&
    item.deactivateAt === null
  ) {
    item.status = "Unpublished";
  }
  if (
    item.publishAt !== null &&
    item.publishAt !== "Invalid Date" &&
    item.deactivateAt === null
  ) {
    item.status = "Activated";
  }
  if (item.publishAt !== null && item.deactivateAt !== null) {
    item.status = "Deactivated";
  } else if (diffEnd < 0) {
    item.status = "Expired";
  } else if (diffStart > 0) {
    item.status = "Scheduled";
  }
  if (isPartnerAd(item.id)) {
    item.status = "Activated";
  }
};

export let validateMacros = (url) => {
  // try catch used to handle invalid url
  try {
    const parsedUrl = new URL(url);
    const searchParams = parsedUrl.searchParams;
    let error = false

    searchParams.forEach((value, key) => {
      // check if the key is a macro
      if (value.startsWith('{') && value.endsWith('}') && !supportedExternalMacros.includes(value)) {
        error = true
      }
    });
    if (error) {
      return unsupportedMacroError
    } else {
      return ""
    }
  } catch (err) {
    // console.log("Not a URL", err);
    return ""
  }
}

const openingTagRegex = /<f(-?\d+)([^>]*)>/g;
const closingTagRegex = /<\/f(-?\d+)>/g;

export let getParsedHTML = (str = "") => {
  let replacedText = str?.replace(openingTagRegex, (match, fontSize, existingStyles) => {
    let parsedFontSize = 100; // Default font size (in percentage)
    if (fontSize) parsedFontSize = 100 + (fontSize * 5);
    if (existingStyles) existingStyles = existingStyles?.replace(`style="`, "")?.replace(`;"`, ";")
    const combinedStyles = `style="margin-bottom:0;font-size:${parsedFontSize}%;${existingStyles}"`;
    return `<p ${combinedStyles}>`;
  });
  replacedText = replacedText?.replace(closingTagRegex, '</p>');
  return replacedText;
}

export let getClientId = (clientId = "") => {
  if (clientId === "mcSLTFw2VeMm1SvkPwWyd1vNKSers1kF")
    return "ai.mint.keyboard"
  if (clientId === "SGLbkv3GEHoe2vioLg2xX0HvBfc7wDc5")
    return "com.mint.keyboard"
  return clientId
}

export let generatePriority = () => {
  const min = 100000;
  const max = 9999999;
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const formatValue = (value) => {
  let parsedValue = value
  try {
    parsedValue = JSON.parse(value)
  } catch (err) {
  }
  if (parsedValue == null || parsedValue === undefined || parsedValue === "") return "NA"
  if (typeof parsedValue === 'string') {
    return parsedValue;
  }
  if (typeof parsedValue === 'object' && !Array.isArray(parsedValue)) {
    return Object.entries(parsedValue).map(([key,
      val]) => `${convertCamelCaseToHumanReadable(key)}: ${formatNestedValue(val, 1)}`).join('<br/>');
  }
  if (Array.isArray(parsedValue)) {
    if (parsedValue?.length === 0) return "NA"
    return parsedValue.map((item, index) => {
      return `${index + 1}. ${formatNestedValue(item, 1)}`
    }).join('<br/>');
  }
  return value
}

const formatNestedValue = (value, level) => {
  if (value == null || value === undefined || value === "") return "NA"
  if (typeof value === 'string') {
    return value;
  }
  if (typeof value === 'object' && !Array.isArray(value)) {
    return Object.entries(value).map(([key, val]) => `<br/>${Array.from(Array(level).keys()).map(key => '&nbsp;&nbsp;&nbsp;&nbsp;').join('')}${convertCamelCaseToHumanReadable(key)}: ${formatNestedValue(val, level + 1)}`).join('');
  }
  if (Array.isArray(value)) {
    if (value?.length === 0) return "NA"
    return value.map((item, index) => {
      return `<br/>${Array.from(Array(level).keys()).map(key => '&nbsp;&nbsp;&nbsp;&nbsp;').join('')}${index + 1}. ${formatNestedValue(item, level + 1)}`
    }).join('');
  }
  return value
}

export const sectionGrayedOutStyle = (isGrayed) => {
  if (isGrayed) return {
    opacity: 0.2,
    cursor: "not-allowed"
  }
  return {}
}

export const updateMetrics = (metrics, provider) => {
  if (provider?.endsWith('_cpi')) {
    metrics.cpi += 1;
  } else if (provider?.endsWith('_cpm')) {
    metrics.cpm += 1;
  } else {
    metrics.cpc += 1;
  }
  metrics.total += 1;
  return metrics;
};

export const convertUTCToIST = (utcDateString) => {
  // If not a valid date then return
  if (!utcDateString) return

  // Create a Date object from the UTC string
  const utcDate = new Date(utcDateString);

  // Calculate the IST offset in milliseconds (+5:30 hours)
  const istOffset = 5.5 * 60 * 60 * 1000;

  const istDate = new Date(utcDate.getTime() + istOffset);

  // Return the date formatted to 'YYYY-MM-DD'
  return istDate.toISOString().split('T')[0];
};

export const parseDateInMoment = (date) => {
  return moment(date, "YYYY-MM-DD HH:mm:ss")
}

export const calculateInputLength = (str = "") => {
  const textWithoutTags = str.replace(/<[^>]*>/g, '').replace(/<.*$/, '');
  return textWithoutTags.length;
};

// Function to check if a string contains HTML
export const isHTMLString = (str) => /<\/?[a-z][\s\S]*>/i.test(str);

// Function to extract URLs from src, href, or srcset attributes using regex
export const parseHTMLToURL = (htmlString) => {
  if (!isHTMLString(htmlString)) {
    return htmlString; // Return input as is if it's not HTML
  }

  // Regex patterns to extract src, href, and srcset URLs
  const imgSrcMatch = htmlString.match(/<img[^>]+src\s*=\s*["']([^"']+)["']/i);
  const srcMatch = htmlString.match(/\b(?:src)\s*=\s*["']([^"']+)["']/i);
  const hrefMatch = htmlString.match(/\b(?:href)\s*=\s*["']([^"']+)["']/i);
  const srcsetMatch = htmlString.match(/\b(?:srcset)\s*=\s*["']([^"']+)["']/i);

  let extractedUrl =
    imgSrcMatch?.[1] ||
    srcMatch?.[1] ||
    hrefMatch?.[1] ||
    srcsetMatch?.[1]?.split(",")[0]?.trim().split(" ")[0] ||
    "";

  return extractedUrl || ""; // If extracted URL is empty and input is HTML, return ""
}
import React, { memo } from 'react'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { Accordion } from "react-bootstrap";
import { BsChevronDown, BsChevronUp } from "react-icons/bs"
import moment from "moment";
import ReactPaginate from 'react-paginate';
import DiffViewer from './DiffViewer';
import { convertCamelCaseToHumanReadable, generateColor, replaceUnderscoreAndCapitalize } from '../../../utilities/commonUtil';
import { clientSpecificServices, countrySpecificServices, locationSpecificServices, placementSpecificServices } from '../../../config/configDashboardLogs';

const Logs = memo(({
  sessions = [],
  activeSessionAccordion,
  activeSessionChangeAccordion,
  pageCount = 0,
  activePage = 0,
  limit,
  onPageClick = () => { },
  onToggleSessionAccordion = () => { },
  onToggleSessionChangeAccordion = () => { }
}) => {
  const formatValue = (value) => {
    let parsedValue = value
    try {
      parsedValue = JSON.parse(value)
    } catch (err) {
    }
    if (parsedValue == null || parsedValue === undefined || parsedValue === "") return "NA"
    if (typeof parsedValue === 'string') {
      return parsedValue;
    }
    if (typeof parsedValue === 'object' && !Array.isArray(parsedValue)) {
      return Object.entries(parsedValue).map(([key,
        val]) => `${convertCamelCaseToHumanReadable(key)}: ${formatNestedValue(val, 1)}`).join('\n');
    }
    if (Array.isArray(parsedValue)) {
      if (parsedValue?.length === 0) return "NA"
      return parsedValue.map((item, index) => {
        return `${index + 1}. ${formatNestedValue(item, 1)}`
      }).join('\n');
    }
    return value
  }

  const formatNestedValue = (value, level) => {
    if (value == null || value === undefined || value === "") return "NA"
    if (typeof value === 'string') {
      return value;
    }
    if (typeof value === 'object' && !Array.isArray(value)) {
      return Object.entries(value).map(([key, val]) => `\n${Array.from(Array(level).keys()).map(key => '  ').join('')}${convertCamelCaseToHumanReadable(key)}: ${formatNestedValue(val, level + 1)}`).join('');
    }
    if (Array.isArray(value)) {
      if (value?.length === 0) return "NA"
      return value.map((item, index) => {
        return `\n${Array.from(Array(level).keys()).map(key => '  ').join('')}${index + 1}. ${formatNestedValue(item, level + 1)}`
      }).join('');
    }
    return value
  }

  return (
    <Card>
      {sessions.length > 0 && (
        <Card className="mb-0" style={{ borderBottom: "1px solid #dee2e6" }}>
          <CardHeader>
            <Row>
              <Col md={4}><strong>Session ID</strong></Col>
              <Col md={4}><strong>Username</strong></Col>
              <Col md={4}><strong>Last changes done at</strong></Col>
            </Row>
          </CardHeader>
        </Card>
      )}
      <Accordion activeKey={activeSessionAccordion}>
        {
          sessions.length > 0 && sessions?.map((session) => (
            <Card key={session?.id} className="mb-0 sessionCard shadow-none">
              <Accordion.Toggle
                key={session?.id}
                as={CardHeader}
                eventKey={session?.id}
                onClick={() => onToggleSessionAccordion(session?.id)}
                style={{ backgroundColor: activeSessionAccordion === session?.id && "#dfdfdf" }}
              >
                <Row>
                  <Col md={4}>{session?.id}</Col>
                  <Col md={4}>{session?.username}</Col>
                  <Col md={4} className="d-flex justify-content-between align-items-center">
                    {moment(session?.updatedAt).format('DD MMM YYYY, h:mmA')}
                    {activeSessionAccordion === session?.id ? <BsChevronUp /> : <BsChevronDown />}
                  </Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={session?.id}>
                <Card className="mb-0 sessionChangeCard shadow-none">
                  <CardHeader>
                    <Row>
                      <Col md={3}><strong>Type</strong></Col>
                      <Col md={3}><strong>Name</strong></Col>
                      <Col md={3}><strong>Operation</strong></Col>
                      <Col md={3}><strong>Change done at</strong></Col>
                    </Row>
                    <Accordion activeKey={activeSessionChangeAccordion}>
                      {
                        session?.changes?.length > 0 && session?.changes?.map((change) => (
                          <Card key={change?.id} className="mb-0 shadow-none">
                            <Accordion.Toggle
                              key={change?.id}
                              as={CardHeader}
                              eventKey={change?.id}
                              onClick={() => onToggleSessionChangeAccordion(change?.id)}
                              style={{ backgroundColor: activeSessionChangeAccordion === change?.id && "#dfdfdf" }}
                            >
                              <Row>
                                <Col md={3}>{change?.entityType}</Col>
                                <Col md={3}>{change?.entityId === change?.entityName ? "" : `${change?.entityId}: `}{change?.entityName}</Col>
                                <Col md={3}>
                                  <span style={{
                                    background: generateColor(change?.operation)
                                      .background,
                                    color: generateColor(change?.operation).color,
                                    fontSize: 12,
                                    fontWeight: 800,
                                    borderRadius: 4,
                                    width: "fit-content",
                                    margin: "auto",
                                    padding: "2px 10px",
                                  }}>
                                    {change?.operation}
                                  </span>
                                </Col>
                                <Col md={3} className="d-flex justify-content-between align-items-center">
                                  {moment(change?.createdAt).format('DD MMM YYYY, h:mmA')}
                                  {activeSessionChangeAccordion === change?.id ? <BsChevronUp /> : <BsChevronDown />}
                                </Col>
                              </Row>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={change?.id}>
                              <Card>
                                <CardBody>
                                  {clientSpecificServices.includes(change.service) && <p style={{ fontSize: 13, marginBottom: 6 }}><strong>Client:</strong> {change?.clientName || "Global"}</p>}
                                  {locationSpecificServices.includes(change.service) && <p style={{ fontSize: 13, marginBottom: 6 }}><strong>Location:</strong> {replaceUnderscoreAndCapitalize(change?.placementLocation) || "Global"}</p>}
                                  {placementSpecificServices.includes(change.service) && <p style={{ fontSize: 13, marginBottom: 6 }}><strong>Placement:</strong> {replaceUnderscoreAndCapitalize(change?.placementDesc) || "Global"}</p>}
                                  {countrySpecificServices.includes(change.service) && <p style={{ fontSize: 13, marginBottom: 0 }}><strong>Country:</strong> {replaceUnderscoreAndCapitalize(change?.countryName) || "Global"}</p>}
                                </CardBody>
                                <DiffViewer oldValue={formatValue(change.oldValue)} newValue={formatValue(change.newValue)} />
                              </Card>
                            </Accordion.Collapse>
                          </Card>
                        ))
                      }
                    </Accordion>
                  </CardHeader>
                </Card>
              </Accordion.Collapse>
            </Card>
          ))
        }
      </Accordion>
      <CardHeader>
        <ReactPaginate
          className="react-pagination"
          breakLabel="..."
          nextLabel=">"
          onPageChange={onPageClick}
          pageRangeDisplayed={limit}
          pageCount={pageCount}
          forcePage={activePage}
          marginPagesDisplayed={2}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />
      </CardHeader>
    </Card>
  )
})

export default Logs
import React, { useState, useEffect } from "react";
import TargetingRules from "../TargetingRules";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { permissions } from "../../../utilities/commonUtil";
import { getContentCategoryRules, saveContentCategoryRules } from "../../../utilities/apiUtils/cre";
import { getClients, getRegions, getCities, getCountries, getStickerPackTargetingRules, saveStickersTargetingRules } from "../../../utilities/apiUtils/stickers";
import { fetchStickers } from "../../../utilities/apiUtils";
import { getRules, saveRules } from "../../../utilities/apiUtils/popTextStyles";
import { Alert } from "react-bootstrap";
import { Row, Col, Card, CardBody, CardImg } from "reactstrap";
import { useHistory } from "react-router-dom";

const StickersRules = (props) => {
    let history = useHistory();
    const [stickers, setStickers] = useState([]);

    const queryParams = new URLSearchParams(window.location.search);
    const stickerIds = queryParams.get("stickerIds");

    const [allowedPermissions] = useState(
        retrieveFromLocalStorage("userPermissions")
    );

    const updateRulesAllowed = () => {
        return allowedPermissions.includes(permissions.updateStickerRules);
    };

    const fetchStickersData = async (ids) => {
        await fetchStickers({ ids: ids })
            .then((response) => {
                if (response.stickers && response.stickers.length !== 0) {
                    setStickers(response.stickers);
                }
            })
            .catch((err) => {

            });
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const stickerIds = queryParams.get("stickerIds");
        fetchStickersData(stickerIds);
    }, []);

    return (
        <div>
            <h3>Stickers</h3>
            <Row>
                {
                    stickers.map((sticker) => {
                        return (
                            <Col key={sticker.id} md={2} lg={2}>
                                <Card className="importPageCard">
                                    <React.Fragment>
                                        <CardImg
                                            src="/img/white-bg.png"
                                            onLoad={(e) => (e.target.src = sticker.imageURL)}
                                        />
                                    </React.Fragment>
                                    <CardBody>
                                        ID: {sticker.id}
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    })
                }
            </Row>
            <Row>
                <Col className="pb-2">
                    <Alert className="opacity-3" variant="warning">
                        <span className="p-2">Note: Once the custom targeting rules are applied to the stickers, they will no longer inherit their pack's targeting rules.</span>
                    </Alert>
                </Col>
            </Row>
            <TargetingRules
                showAgeGenderRules={true}
                id={stickerIds}
                getRules={getStickerPackTargetingRules}
                saveRules={async (rules, id) => {
                    await saveStickersTargetingRules(rules, id);
                    history.goBack();
                }}
                getClients={getClients}
                getCountries={getCountries}
                getRegions={getRegions}
                getCities={getCities}
                updateRulesAllowed={updateRulesAllowed()}
                showHardFiltering={false}
            />
        </div>
    );
};

export default StickersRules;

import React from "react";
import { Card, CardImg, CardBody, CardFooter, Col, Row, Button, Badge } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import {
    redirectTo,
} from "../../../../utilities/commonUtil";

const EmojiStickerImage = ({ emoji }) => {
    const redirectToEmojiImageDetails = (imageId) => {
        redirectTo(`/sticker/emoji-stickers/${emoji.emojiStickerId}/image/${imageId}`)
    };

    return (
        <Card>
            <React.Fragment>
                <CardImg
                    top
                    src={emoji.webpURL != null ? emoji.webpURL : emoji.pngURL}
                ></CardImg>
            </React.Fragment>
            <CardBody>
                <span>
                    <strong>Image ID: </strong>
                    {emoji.imageId ? emoji.imageId : "Not Available"}
                </span>
                <br />
                <span>
                    <strong>Emoji Sticker ID: </strong>
                    {emoji.emojiStickerId ? emoji.emojiStickerId : "Not Available"}
                </span>
                <br />
                <span>
                    <strong>Emoji : </strong>
                    {emoji.emojiStickerId ? emoji.emoji : "Not Available"}
                </span>
                <br />
                <span>
                    <strong>Image Processing Status: </strong>
                    {emoji.imageProcessingStatus ? emoji.imageProcessingStatus : "Not Available"}
                </span>
                <br />
                <span>
                    <strong>Created At: </strong>
                    {emoji.createdAt ? emoji.createdAt : "Not Available"}
                </span>
                <br />
                <span>
                    <strong>Updated At: </strong>
                    {emoji.updatedAt ? emoji.updatedAt : "Not Available"}
                </span>
                <br />
            </CardBody>
            <CardFooter>
                <Row style={{ justifyContent: "space-between" }}>
                    <Button
                        color="info"
                        className="d-block mx-auto"
                        onClick={() => redirectToEmojiImageDetails(emoji.imageId)}
                    >
                        Details
                        <FontAwesomeIcon
                            icon={(fas, faInfoCircle)}
                            className="ml-2"
                        ></FontAwesomeIcon>
                    </Button>
                </Row>
            </CardFooter>
        </Card>
    );
};

export default EmojiStickerImage;

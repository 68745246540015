import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import {
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CardImg
} from "reactstrap";
import { X } from "react-feather";
import TagsInput from "react-tagsinput";

import ConfirmationModal from '../ConfirmationModal';
import ResponseModal from '../ResponseModal';
import { error, errorDescription, redirectOnTokenExpiry, redirectTo } from '../../../utilities/commonUtil';
import Routes from "../../../routes/index";
import { getMovieGifsLocales, movieGifDetail, processDateAndStatus, updateMovieGifs } from '../../../utilities/apiUtils/movieGifs';
import { iconSize } from '../../../config/UserConfig';
import Loading from '../../../components/Loading';
import { languageMapping } from '../../../utilities/languageMapping';
import MovieGifPreview from './components/MovieGifPreview';
import { movieGifResponses, movieGifsUpdatedSuccessfully } from '../../../config/movieGifs';


const MovieGifsEdit = (props) => {

  const movieGifsID = props.match.params.id

  const [action, setAction] = useState("");
  const [movieGifDetails, setmovieGifDetails] = useState({})
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");
  const [hasResponseError, setHasResponseError] = useState(false);
  const [errorText, setErrorText] = useState("")
  const [loading, setLoading] = useState(true)
  const [addLanguageCodeOption, setAddLanguageCodeOption] = useState([]);
  const [addLocaleCodeOptions, setAddLocaleCodeOptions] = useState([])
  const [locale, SetLocale] = useState({})
  const [newType, setNewType] = useState()
  const [keywords, setKeywords] = useState({
    "generic": {
      languageCode: "",
      tags: [],
    },
  });

  const typeList = ["generic"]

  // setting up language and locoale options and fetching movie gif data
  useEffect(() => {
    setLanguageCode()
    fetchMovieGifData(movieGifsID)
  }, [movieGifsID],);

  // setting up movie gif details to be rendered
  useEffect(() => {

    const fetchKeywords = []
    let finalKeywords = []

    setGifLocale(movieGifDetails.locale)

    if (movieGifDetails.tags !== null && movieGifDetails.tags !== undefined) {
      movieGifDetails.tags.forEach((val) => {
        let languageIdString = val.languageId.toString()
        if (fetchKeywords[val.type] == null) {
          fetchKeywords[val.type] = {}
        }
        if (fetchKeywords[val.type][languageIdString] == null) {
          fetchKeywords[val.type][languageIdString] = {
            tags: [], languageCode: addLanguageCodeOption.find((option) => {
              if (option.id === val.languageId) {
                return true;
              }
            }),
          }
        }
        fetchKeywords[val.type][languageIdString].tags.push(val.text)
        const tagArray = fetchKeywords[val.type][languageIdString].tags
        const uniqueTags = [...new Set(tagArray)]
        fetchKeywords[val.type][languageIdString].tags = uniqueTags
        let tempKeywords = Object.values(fetchKeywords[val.type])
        finalKeywords[val.type] = tempKeywords
      })
    }
    setKeywords(finalKeywords)
  }, [movieGifDetails, addLanguageCodeOption, addLocaleCodeOptions])

  // fetch locales data
  useEffect(() => {
    setLocaleOptions()
  }, [])
  
  // process date ,time and status 
  useEffect(() => {
    processDateAndStatus(movieGifDetails)
},[movieGifDetails])


  const setGifLocale = (localeCode) => {
    for (let i in addLocaleCodeOptions) {
      var tempLocale = addLocaleCodeOptions[i]
      if (tempLocale.value == localeCode) {
        SetLocale(tempLocale)
        break
      }
    }
  }
  const fetchMovieGifData = async (id) => {
    try {
      let res = await movieGifDetail(id)
      if (!res.errorCode) {
        setHasResponseError(false)
        setmovieGifDetails(res)
      } else {
        if (
          res.errorCode === error.tokenExpired ||
          res.errorCode === error.invalidAccessToken
        )
          redirectOnTokenExpiry();
        setHasResponseError(true)
        setErrorText(errorDescription(res.errorCode))
      }
      setLoading(false)
    } catch (err) {
      setLoading(false);
      setFailureModalText(errorDescription(error.unexpectedError))
      setFailureModalDisplay(true);
    }
  }


  const handleSubmit = async () => {

    let requestPayload = {}
    let tagObjectsList = [];

    Object.keys(keywords).map((key) => {
      keywords[key].map((language, index) => {
        if (keywords[key][index].tags.length > 0) {
          const languageCode = language.languageCode
          keywords[key][index].tags.forEach((tag) => {
            tagObjectsList.push({
              "type": key,
              "text": tag,
              "languageId": languageCode.id
            })
          })
        }
      })
    })

    requestPayload["tags"] = tagObjectsList
    requestPayload["locale"] = locale.value

    setLoading(true)
    let response = await updateMovieGifs(requestPayload, movieGifsID)
    if (response.status === "success") {
      setLoading(false)
      setSuccessModalText(movieGifResponses.updatedSuccessfully);
      setSuccessModalDisplay(true);
    } else {
      setLoading(false)
      setFailureModalText(errorDescription(response.errorCode));
      setFailureModalDisplay(true);
    }
  }

  const setLocaleOptions = async () => {
    const localesData = await getMovieGifsLocales()
    const localeCodeOptions = localesData.map((locale) => {
      return {
        value: locale.code,
        label: locale.name + " : " + locale.code,
        target: {
          name: "localeCode",
          value: {
            value: locale.code,
            label: locale.name + " : " + locale.code,
          },
        },
      };
    });
    setAddLocaleCodeOptions(localeCodeOptions)
  }

  const setLanguageCode = () => {
    const languageCodeOptions = languageMapping.map((language) => {
      return {
        id: language.id,
        value: language.code,
        label: language.name + " : " + language.code,
        target: {
          name: "languageCode",
          value: {
            id: language.id,
            value: language.code,
            label: language.name + " : " + language.code,
          },
        },
      };
    });
    setAddLanguageCodeOption(languageCodeOptions);
  };

  const handleLanguageCodeChange = (event, index, type) => {
    const { name, value } = event.target;
    let temp = { ...keywords };
    temp[type][index][name] = value;
    setKeywords(temp);
  };

  const handleAddLanguage = (type) => {
    if (keywords[type] == null || keywords[type] == undefined) {
      keywords[type] = [{
        languageCode: "",
        tags: [],
      }]
    }
    else {
      keywords[type].push({
        languageCode: "",
        tags: [],
      })
    }
  }

  const showLanguageCodeOptions = (type) => {
    let finalLanguageCode = addLanguageCodeOption;
    // remove language codes from the dropdown those are already selected.
    keywords[type].forEach((keyword) => {
      finalLanguageCode = finalLanguageCode.filter(
        (item) => item?.value !== keyword.languageCode?.value
      );
    });
    return finalLanguageCode;
  };


  const handleTagsChange = (tags, index, type) => {
    let temp = { ...keywords };

    // Split the input value by commas and trim spaces around each tag
    const tagsSplit = tags
      .map((item) => item.split(","))
      .flat() // Flatten the array to remove nested arrays
      .map((item) => item.trim());

    // Remove duplicates by converting the tagsSplit array to a Set and then back to an array
    const uniqueTags = [...new Set(tagsSplit)];

    temp[type][index].tags = uniqueTags;
    setKeywords(temp);
  };

  let setConfirmationModalState = (event) => {
    event.preventDefault();
    setAction("Submit");
    setConfirmationModalIsOpen(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    redirectTo(`/movie-gifs/${movieGifsID}/edit`);
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };


  return (
    <Container fluid className="p-0">
      {loading && !failureModalDisplay ? (<Loading />) : <>
        <MovieGifPreview movieGifDetails={movieGifDetails} />
        <Row className="mb-3">
          <Col md={6} xs={6}>
            <h1>Edit Movie Gif</h1>
            <div className='mb-3 mt-3'>
              <h3>Locale</h3>
              <Select
                id="locale"
                className="react-select-container"
                classNamePrefix="react-select"
                name="locale"
                placeholder="Select Locale"
                value={locale}
                onChange={(e) => { SetLocale(e.target.value) }}
                options={addLocaleCodeOptions}
                required
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6} xs={6}>
            <h2>Tags</h2>
          </Col>
        </Row>
        <Form onSubmit={(event) => setConfirmationModalState(event)}>
          {typeList.map((type, ind) => {
            return (
              <Row key={ind}>
                <Col lg="12">
                  <Row className="mb-3">
                    <Col md={6} xs={6}>
                      <h3> {type.toString().toUpperCase()} Tags</h3>
                    </Col>
                    <Col md={6} xs={6}>
                      <div className="p-0">
                        <Button
                          color="success"
                          className="ml-auto d-flex btn-success"
                          onClick={() => {
                            handleAddLanguage(type)
                            setKeywords({ ...keywords })
                          }}
                        >
                          <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                            Add Language
                          </p>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  {
                    keywords[type] != null && keywords[type] != undefined ?
                      <Card>
                        <CardBody>
                          {keywords[type].map((item, index) => {
                            return (
                              <Row key={index} form>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label
                                      className="font-weight-bolder mb-2"
                                      style={{ fontSize: "16px" }}
                                    >
                                      Language Code:{" "}
                                    </Label>
                                    <Select
                                      id="languageCode"
                                      className="react-select-container"
                                      classNamePrefix="react-select"
                                      name="languageCode"
                                      placeholder="Select Language Code"
                                      value={item.languageCode}
                                      onChange={(event) =>
                                        handleLanguageCodeChange(event, index, type)
                                      }
                                      options={showLanguageCodeOptions(type)}
                                      required
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <div className="p-0" style={{ marginRight: "20px" }}>
                                    <X
                                      className="d-none d-lg-block ml-auto d-flex"
                                      size={iconSize}
                                      color="red"
                                      style={{
                                        cursor: "pointer",
                                        border: "2px solid red",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        keywords[type] = keywords[type].filter((_, i) => i !== index)
                                        if (keywords[type].length == 0) {
                                          keywords[type] = [{
                                            languageCode: "",
                                            tags: [],
                                          }]
                                        }
                                        setKeywords({ ...keywords });
                                      }}
                                    ></X>
                                  </div>
                                </Col>
                                <Col md={12}>
                                  <FormGroup>
                                    <TagsInput
                                      inputProps={{
                                        placeholder: "Add a Tag",
                                      }}
                                      value={item.tags}
                                      onChange={(select) =>
                                        handleTagsChange(select, index, type)
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <hr
                                  style={{
                                    width: "75rem",
                                    color: "black",
                                  }}
                                />
                              </Row>
                            );
                          })}
                        </CardBody>
                      </Card>
                      :
                      <Row className="mb-3">
                        <Col md={6} xs={6}>
                          <h4>No Tags</h4>
                        </Col>
                      </Row>
                  }
                </Col>
              </Row>
            )
          })
          }

          <Button className="d-block ml-3 mt-2" color="primary">
            Submit
          </Button>
        </Form>
        <ConfirmationModal
          show={confirmationModalIsOpen}
          modaltext={action}
          onConfirmationTrue={() => {
            setConfirmationModalIsOpen(false);
            if (action === "Submit") handleSubmit();
          }}
          onHide={() => setConfirmationModalIsOpen(false)}
        />
        <ResponseModal
          show={successModalDisplay}
          onHide={successModalClose}
          modalheading={"Success"}
          modaltext={successModalText}
        />
        <ResponseModal
          show={failureModalDisplay}
          onHide={failureModalClose}
          modalheading={"Error"}
          modaltext={failureModalText}
        />
      </>
      }

    </Container>
  );
}

export default MovieGifsEdit
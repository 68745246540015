export const columns = [
  {
    dataField: "id",
    text: "#",
    headerAttrs: { width: 60 },
    searchable: false,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "name",
    text: "Name",
    searchable: true,
    style: {
      cursor: "pointer",
    },
  },
];

import React from "react";
import { Badge } from "reactstrap";

// create columns for id , name , filename , created_at , updated_at
export const columns = [
  {
    dataField: "id",
    text: "#",
    headerAttrs: { width: 60 },
    searchable: false,
    sort: true,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "name",
    text: "Name",
    searchable: true,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "filename",
    text: "Lottie Animation Filename",
    searchable: true,
    formatter: (cell, row) => (
        <a
            href={row.lottieAnimationURL}
            target="_blank"
            rel="noopener noreferrer"
        >
            {cell}
        </a>
        ),
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "createdAt",
    text: "Created At",
    sort: true,
    searchable: false,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "updatedAt",
    text: "Updated At",
    sort: true,
    searchable: false,
    style: {
      cursor: "pointer",
    },
  },
];
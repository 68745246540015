import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  CardImg,
  Label,
  Row,
  Spinner,
  CustomInput,
} from "reactstrap";
import moment from "moment";
import ResponseModal from "../ResponseModal";
import { Chips } from 'primereact/chips';
import { components } from "react-select";
import {
  errorDescription,
  error,
} from "../../../utilities/commonUtil";
import { useParams, useLocation } from 'react-router-dom';
import Select from "react-select";
import { createUpdateSticker, fetchTaasClients, fetchTaasSticker, fetchTaasStickerURL, fetchTaasStickers } from "../../../utilities/apiUtils/taasDashboard";
import { genderOptions, config, stickerResponses, showActivateStickerButton } from "../../../config/configTaasDashboard";
import { successModalHeading } from "../../../config";
import { useHistory } from "react-router-dom"
import { errorModalHeading } from "../../../config/config";
import { debounce } from "../../../utilities/debounce";

const StickerForm = () => {
  const location = useLocation();
  const history = useHistory()
  const currentPath = location.pathname.split('/');
  const pageType = currentPath[currentPath.length - 1];
  const { id } = useParams();
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [imageURL, setImageURL] = useState('');
  const [successModalText, setSuccessModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");
  const [selectedStickerIds, setSelectedStickerIds] = useState([]);
  const [showDuplicateStickerAlert, setShowDuplicateStickerAlert] = useState(false);

  const [data, setData] = useState({
    stickerIds: [],
    clientId: [],
    gender: genderOptions[0],
    startDate: "",
    endDate: "",
    activatedAt: false,
    deactivatedAt: true,
  })

  let fetchClientsData = async () => {
    try {
      let response = await fetchTaasClients()
      if (response?.length > 0) {
        setClients(response.map(d => ({
          value: d.id,
          label: d.name,
        }))
        )
      }
      else if (response & response.errorCode) {
        showErrorModal(errorDescription(response.errorCode));
      }
    } catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  }

  const fetchStickerDetails = async (id) => {
    try {
      let sticker = await fetchTaasSticker(id);
      if (sticker?.length > 0) {
        sticker = sticker[0];
        await fetchData(sticker.stickerId);
        setData({
          id: id,
          stickerIds: sticker.stickerId,
          clientId: {
            label: clients.find(option => option.value === sticker.clientId)?.label ?? sticker.clientName,
            value: sticker.clientId
          },
          gender: {
            label: genderOptions.find(option => option.value === sticker.gender)?.label ?? sticker.gender,
            value: sticker.gender
          },
          startDate: sticker.startDate,
          endDate: sticker.endDate,
          activatedAt: new Date(sticker.deactivatedAt) > new Date() || sticker.deactivatedAt === null ? true : false,
          deactivatedAt: new Date(sticker.deactivatedAt) > new Date() || sticker.deactivatedAt === null ? false : true,
        });
      }
      else {
        setData({
          stickerIds: [],
          clientId: [],
          gender: genderOptions[0],
          startDate: '',
          endDate: '',
          activatedAt: false,
          deactivatedAt: true,
        })
      }
    }
    catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  }

  useEffect(() => {
    fetchClientsData();
  }, [])

  useEffect(() => {
    if (pageType !== "create" && id) {
      fetchStickerDetails(id);
    }
  }, [id, pageType])

  const fetchData = async (stickerId) => {
    try {
      const res = await fetchTaasStickerURL(stickerId);
      if (res?.sticker?.fixedWidthSmall) {
        setImageURL(res?.sticker?.fixedWidthSmall?.png?.url);
      }
      else {
        setImageURL('');
      }
    } catch (err) {
      setImageURL('');
    }
    finally {
      setIsLoading(false);
    }
  };

  const fetchStickerData = async (stickerId) => {
    let params = {
      stickerId: stickerId,
    };
    try {
      const res = await fetchTaasStickers(params);
      if (res?.length > 0) {
        if (res[0].count >= 1) {
          setShowDuplicateStickerAlert(true);
          setSelectedStickerIds((prevState) =>
            prevState.map((item) =>
              item.label === stickerId ? { ...item, showAlert: true } : item
            )
          );
        }
      } else {
        setShowDuplicateStickerAlert(false);
        setSelectedStickerIds((prevState) =>
          prevState.map((item) =>
            item.label === stickerId ? { ...item, showAlert: false } : item
          )
        );
      }
    } catch (err) {
      setShowDuplicateStickerAlert(false);
      setSelectedStickerIds((prevState) =>
        prevState.map((item) =>
          item.label === stickerId ? { ...item, showAlert: false } : item
        )
      );
    }
  };

  const handleStickerIdChange = (event) => {
    const { name, value } = event.target
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }))
    fetchStickerData(value)
    debounce(() => {
      setIsLoading(true);
      fetchData(value).finally(() => setIsLoading(false))
    }, config.debounceTime)
  };

  const fetchUrl = async (val) => {
    let ans = '';
    try {
      const res = await fetchTaasStickerURL(val);
      if (res.errorCode) {
        ans = '';
      }
      if (res.sticker?.fixedWidthSmall) {
        ans = res.sticker?.fixedWidthSmall?.png?.url;
      }
    }
    catch (err) {
      console.error('Error fetching data:', err);
    }
    return ans;
  }

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    history.goBack();
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const startDate = new Date(data.startDate);
    const endDate = new Date(data.endDate);
    if (startDate > endDate) {
      showErrorModal("Start Date should come before End Date.");
    }
    else {
      let formData = new FormData();
      data.id && formData.append("id", data.id);
      formData.append("stickerIds", data.stickerIds);
      formData.append("clientId", data.clientId?.value);
      formData.append("gender", data.gender?.value);
      formData.append("startDate", data.startDate);
      formData.append("endDate", data.endDate);
      formData.append("activatedAt", data.activatedAt);
      formData.append("deactivatedAt", data.deactivatedAt);
      try {
        if ((pageType !== "create" && !imageURL) || (pageType === "create" && data.stickerIds?.length === 0)) {
          showErrorModal("Enter a valid sticker id");
        }
        else {
          if (pageType === "create") {
            let response = await createUpdateSticker(formData)
            if (response.code === "stickerAdded") {
              showSuccessModal(stickerResponses.addedSuccessfully);
            }
            else {
              if (response.errorDescription) {
                showErrorModal(response.errorDescription)
              }
            }
          }
          else {
            let response = await createUpdateSticker(formData);
            if (response.code === "stickerUpdated") {
              showSuccessModal(stickerResponses.updatedSuccessfully);
            }
            else {
              if (response.errorDescription) {
                showErrorModal(response.errorDescription)
              }
            }
          }
        }
      } catch (err) {
        setFailureModalDisplay(true);
        setFailureModalText(errorDescription(error.unexpectedError));
      }
    }
  }
  const requiredComponent = (props) => (
    <div>
      <components.Input {...props} required={true} />
    </div>
  );

  const notRequiredComponent = (props) => (
    <div>
      <components.Input {...props} required={false} />
    </div>
  );

  const handleCheckboxChange = (event) => {
    const { checked } = event.target;
    setData({
      ...data,
      activatedAt: checked,
      deactivatedAt: !checked,
    });
  };

  const renderChip = (item, index) => {
    return <div key={index} className="text-2xl text-black">{item.label}</div>;
  };

  const handleAddChip = async (newChip) => {
    let url = await fetchUrl(newChip.value);
    if (newChip.value) {
      fetchStickerData(newChip.value)
    }
    if (url.length > 1) {
      setData((prevState) => ({
        ...prevState,
        stickerIds: [...prevState.stickerIds, newChip.value],
      })
      )
    }
    setSelectedStickerIds((prevState) => [...prevState, { label: newChip.value, val: url }]);
  };

  const handleRemoveChip = (chip) => {
    chip = chip.value;
    setSelectedStickerIds((prevState) => {
      const newSequence = [...prevState];
      newSequence.splice(selectedStickerIds.findIndex((value) => value === chip[0]), 1);
      return newSequence;
    })
    let tempArr = data.stickerIds;
    if (tempArr.findIndex((value) => value === chip[0].label) >= 0)
      tempArr.splice(tempArr.findIndex((value) => value === chip[0].label), 1);
    setData((prevState) => ({
      ...prevState,
      stickerIds: tempArr,
    })
    )
  };

  const handleStartDateChange = (event) => {
    const { value } = event.target;
    setData((prevState) => ({
      ...prevState,
      startDate: moment(value).format("YYYY-MM-DD"),
    }));
  }

  const handleEndDateChange = (event) => {
    const { value } = event.target;
    setData((prevState) => ({
      ...prevState,
      endDate: moment(value).format("YYYY-MM-DD"),
    }));
  }

  let getdateValue = (date) => {
    if (!date) {
      return null;
    }
    return moment(date).format("YYYY-MM-DD");
  }

  return (
    <>
      <h1 className="h3 mb-3">
        {pageType !== "create" ? "Edit Sticker" : "Add Sticker(s)"}
      </h1>
      <Card>
        <CardBody>
          <Form onSubmit={(e) => handleSubmit(e)}>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  {pageType !== "create" ? <Label>
                    Bobble Sticker ID <span className="text-danger">*</span>
                  </Label> : <Label>
                    Bobble Sticker IDs <span className="text-danger">*</span><small className="text-info"> (Press enter after entering a sticker id)</small>
                  </Label>}
                  {pageType !== "create" ?
                    <Input
                      type="number"
                      name="stickerIds"
                      value={data.stickerIds}
                      placeholder="Enter Bobble Sticker ID"
                      required
                      onChange={handleStickerIdChange}
                    /> :
                    <div>
                      <Chips
                        value={selectedStickerIds}
                        onAdd={handleAddChip}
                        onRemove={handleRemoveChip}
                        itemTemplate={renderChip}
                      />
                    </div>}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Client <span className="text-danger">*</span></Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    placeholder="Select Client"
                    name="clientId"
                    required
                    value={data.clientId}
                    onChange={(event) => {
                      setData((prevState) => ({
                        ...prevState,
                        clientId: event,
                      }))
                    }}
                    components={{
                      Input:
                        (data.clientId && data.clientId?.value)
                          ? notRequiredComponent
                          : requiredComponent,
                    }}
                    options={clients}
                  />
                </FormGroup>
              </Col>
            </Row>
            {pageType === "create" && showActivateStickerButton() ?
              <Row form>
                <Col xs={12}>
                  <FormGroup className="d-flex align-items-center">
                    <Label
                      className="font-weight-bolder mb-0"
                      style={{ fontSize: "14px" }}
                    >
                      Activate Stickers
                    </Label>
                    <div className="custom-control custom-switch">
                      <CustomInput
                        type="checkbox"
                        id="activateStickersByDefault"
                        onChange={handleCheckboxChange}
                        checked={data.activatedAt}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row> : ''}
            <Row form>
              <Col md={6}>
                <Label>
                  Start Date <small className="text-info">(DD/MM/YYYY format)</small>
                </Label>
                <Input
                  type="date"
                  name="startDate"
                  onChange={handleStartDateChange}
                  value={getdateValue(data.startDate)}
                />
              </Col>
              <Col md={6}>
                <Label>
                  End Date <small className="text-info">(DD/MM/YYYY format)</small>
                </Label>
                <Input
                  type="date"
                  name="endDate"
                  onChange={handleEndDateChange}
                  value={getdateValue(data.endDate)}
                />
              </Col>
            </Row>
            <Row form className="mt-3">
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Gender
                  </Label>
                  <Select
                    className="react-select-container"
                    id="statusSelect"
                    classNamePrefix="react-select"
                    name="gender"
                    placeholder="Select Gender"
                    value={data.gender}
                    onChange={(event) => {
                      setData((prevState) => ({
                        ...prevState,
                        gender: event,
                      }))
                    }}
                    options={genderOptions}
                    defaultValue={{ label: "Unisex", value: "unisex" }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={pageType !== "create" ? 6 : 12}>
                <FormGroup>
                  <Label>
                    Preview
                  </Label>
                  {pageType !== "create" ?
                    <div style={{ display: 'flex' }}>
                      <div style={{ flex: '0 0 auto', margin: 'auto', width: '200px' }}>
                        {isLoading ? (
                          <Spinner color="info" className="d-flex mx-auto" />
                        ) : imageURL ? (<>
                          <CardImg
                            top
                            src={imageURL ?? ""}
                            alt="Card image cap"
                            style={{ width: '100%' }}
                          /> {showDuplicateStickerAlert ? <div className="mt-2" style={{ color: "red" }}>*This sticker has already been used.</div> : ''}</>
                        ) : (
                          <>No Sticker</>
                        )}
                      </div>
                    </div> :
                    <>{selectedStickerIds?.length > 0 &&
                      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        {selectedStickerIds.map((item, i) => (
                          <div style={{ flex: '0 0 auto', margin: '10px', width: '250px' }}>
                            {item.val ? <> <CardImg
                              key={i}
                              className="my-2"
                              top
                              src={item.val ?? ""}
                              alt="Card image cap"
                              style={{ width: '100%' }}
                            /> {item.showAlert ? <div className="mt-2" style={{ color: "red" }}>*This sticker has already been used.</div> : ''}</>
                              : <div>No Sticker</div>
                            }
                          </div>
                        ))}
                      </div>}</>}
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary">Submit</Button>
          </Form>
          <ResponseModal
            show={successModalDisplay}
            onHide={successModalClose}
            modalheading={successModalHeading}
            modaltext={successModalText}
          />
          <ResponseModal
            show={failureModalDisplay}
            onHide={failureModalClose}
            modalheading={errorModalHeading}
            modaltext={failureModalText}
          />
        </CardBody>
      </Card>
    </>
  )
}
const ManageSticker = () => (
  <Container fluid className="p-0">
    <Row>
      <Col lg="12">
        <StickerForm />
      </Col>
    </Row>
  </Container>
)
export default ManageSticker;
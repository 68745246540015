import React, { useState } from "react";
import TargetingRules from "../TargetingRules";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { permissions } from "../../../utilities/commonUtil";
import {
  getCarouselTargetingRules,
  updateCarouselTargetingRules,
} from "../../../utilities/apiUtils/carousel.js";

import { getClients } from "../../../utilities/apiUtils/cre";

import {
  getCountries,
  getRegions,
  getCities,
} from "../../../utilities/apiUtils/carousel.js";

const CarouselTargetingRules = (props) => {
  let id = props.match.params.id;

  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  const updateRulesAllowed = () => {
    return allowedPermissions.includes(permissions.contentUpdatePromptList);
  };

  return (
    <div>
      <TargetingRules
        showAgeGenderRules={true}
        showCustomAudienceTargetingRules={false}
        showPlacementRules={true}
        showClientRules={true}
        showHardFiltering={false}
        isGoogleAdvertisingAttributes={false}
        id={id}
        getRules={getCarouselTargetingRules}
        saveRules={updateCarouselTargetingRules}
        getClients={getClients}
        getCountries={getCountries}
        getRegions={getRegions}
        getCities={getCities}
        updateRulesAllowed={updateRulesAllowed()}
      />
    </div>
  );
};

export default CarouselTargetingRules;

import React, { useState, useEffect } from "react";
import { Card, CardBody, Container, Label } from "reactstrap";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { Input } from "reactstrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import ResponseModal from "../ResponseModal";
import { failureModalHeading, successModalHeading } from "../../../config";
import ConfirmationModal from "../ConfirmationModal";
import { GetAIPromptTemplateVersions, updatePromptTemplateVersion } from "../../../utilities/apiUtils/genAIDashboard";
import { formatTime, promptTemplateVersionResponses, showCreatePromptTemplateVersionButton, showEditPromptTemplateVersionButton } from "../../../config/configGenAIDashboard";
import { useParams } from 'react-router-dom';
import { promptTemplateVersionTableColumns } from "./versionTableColumn";
import { error, errorDescription } from "../../../utilities/commonUtil";


const ExpandableRowsTable = () => {
    const [failureModalText, setFailureModalText] = useState("");
    const [failureModalDisplay, setFailureModalDisplay] = useState(false);
    const [successModalText, setSuccessModalText] = useState("");
    const [successModalDisplay, setSuccessModalDisplay] = useState(false);
    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
    const [action, setAction] = useState("");
    const { id } = useParams();
    const [versionId, setVersionId] = useState();
    const [promptTemplateVersions, setPromptTemplateVersions] = useState([]);

    const [data, setData] = useState({
        aiPromptTemplateId: 0,
        identifier: '',
        llmProvidersOrder: '',
        style: '',
        systemPrompt: '',
        text: '',
        maxRetries: 0,
        additionalDetails: {},
        isCurrentVersion: '',
        isBetaVersion: ''
    })

    const fetchPromptTemplatesVersions = async (id) => {
        try {
            let response = await GetAIPromptTemplateVersions(id)
            if (!response || response?.length === 0) {
                setPromptTemplateVersions([])
                return;
            }
            if (response.length > 0) {
                setPromptTemplateVersions(response);
            } else {
                showErrorModal(errorDescription(response.errorCode));
            }
        } catch (err) {
            showErrorModal(errorDescription(error.unexpectedError));
        }
    }


    useEffect(() => {
        if (id) {
            fetchPromptTemplatesVersions(id);
        }
    }, [id]);


    let showErrorModal = (text) => {
        setFailureModalText(text);
        setFailureModalDisplay(true);
    };

    let failureModalClose = () => {
        setFailureModalDisplay(false);
    };

    let successModalClose = async () => {
        setSuccessModalDisplay(false);
        await fetchPromptTemplatesVersions(id);
    };

    let showSuccessModal = (text) => {
        setSuccessModalText(text);
        setSuccessModalDisplay(true);
    };

    let updateStatus = async (update, id) => {
        try {
            const formPromptVersionData = {
                aiPromptTemplateId: data.aiPromptTemplateId,
                identifier: data.identifier,
                llmProvidersOrder: data.llmProvidersOrder,
                style: data.style,
                systemPrompt: data.systemPrompt,
                text: data.text,
                maxRetries: data.maxRetries,
                additionalDetails: data.additionalDetails,
                isCurrentVersion: update === "enableCurrentVersion" ? true : data.isCurrentVersion,
                isBetaVersion: update === "enableBetaVersion" ? true : data.isBetaVersion,
            };
            let response = await updatePromptTemplateVersion(formPromptVersionData, id);
            if (response.status === "aiPromptTemplateVersionEdited") {
                showSuccessModal(promptTemplateVersionResponses.updatedSuccessfully);
            } else {
                if (response.errorDescription) {
                    showErrorModal(response.errorDescription)
                }
            }
        } catch (err) {
            showErrorModal(errorDescription(error.unexpectedError));
        }
    };

    let setConfirmationModalState = (event, row) => {
        setAction(event);
        setConfirmationModalIsOpen(true);
        setVersionId(row.id);
        setData({
            aiPromptTemplateId: row.aiPromptTemplateId,
            identifier: row.identifier,
            llmProvidersOrder: row.llmProvidersOrder,
            style: row.style,
            systemPrompt: row.systemPrompt,
            text: row.text,
            maxRetries: row.maxRetries,
            additionalDetails: row.additionalDetails,
            isCurrentVersion: row.isCurrentVersion,
            isBetaVersion: row.isBetaVersion,
        })
    };

    const expandRow = {
        onlyOneExpanding: true,
        renderer: (row) => (
            <div>
                <ol>
                    <h5>LLM Providers</h5>
                    {row.llmProvidersOrder?.map((llmProvider, index) => (
                        <li key={index}>{llmProvider}</li>
                    ))}
                </ol>
                <ul>
                    <li><b>Style: </b>{row.style}</li>
                    <li><b>System Prompt : </b>
                        {(row.systemPrompt ?
                            <Input
                                type="textarea"
                                name="systemPrompt"
                                placeholder=""
                                value={row.systemPrompt}
                                rows="5"
                                disabled={true}
                                required
                                style={{ backgroundColor: 'white' }}
                            />
                            : "N/A"
                        )}
                    </li>
                    <li><b>Text : </b>
                        <Input
                            type="textarea"
                            name="text"
                            placeholder="Enter Text"
                            value={row.text}
                            rows="15"
                            disabled={true}
                            required
                            style={{ backgroundColor: 'white' }}
                        />
                    </li>
                    <li><b>Max Retries : </b>{row.maxRetries}</li>
                    <li><b>Created At : </b>{formatTime(row.createdAt)}</li>
                    <li><b>Updated At : </b>{formatTime(row.updatedAt)}</li>
                    <li><b>Published At : </b>{formatTime(row.publishedAt)}</li>
                    <li><b>Deactivated At : </b>{formatTime(row.deactivatedAt)}</li>
                </ul>
                <ul>
                    <h5>Additional Details</h5>
                    <li><b>Bobble API's Response Type : </b>{row?.additionalDetails?.responseType || "text"}</li>
                    <li><b>LLM Output Data Type : </b>{row?.additionalDetails?.output?.dataType}</li>
                    <h6 className="mt-2">{`LLM Response Parsing Configuration`}</h6>
                    {row?.additionalDetails?.output?.responseFieldsMapping !== null ? Object.keys(row?.additionalDetails?.output?.responseFieldsMapping).map(key => (
                        <li key={key}><b>{key} : </b>{row?.additionalDetails?.output?.responseFieldsMapping[key]}</li>
                    )) : "N/A"}
                </ul>
                {showEditPromptTemplateVersionButton() ? (
                    <>
                        <Button className="mx-3"
                            disabled={row.isCurrentVersion}
                            onClick={(event) =>
                                setConfirmationModalState(event.target.innerHTML, row)
                            }
                        >
                            Enable for Live Users
                        </Button>
                        <Button className="mx-3 btn-secondary"
                            disabled={row.isBetaVersion}
                            onClick={(event) =>
                                setConfirmationModalState(event.target.innerHTML, row)
                            }
                        >
                            Enable for Beta Users
                        </Button>
                    </>
                ) : null
                }
            </div >
        ),
    };

    return (
        <Card>
            <CardBody>
                <ToolkitProvider keyField="id" data={promptTemplateVersions} columns={promptTemplateVersionTableColumns}>
                    {(props) => (
                        <div>
                            <BootstrapTable
                                {...props.baseProps}
                                bootstrap4
                                bordered={false}
                                keyField="id"
                                expandRow={expandRow}
                                pagination={paginationFactory({
                                    sizePerPage: 10,
                                    sizePerPageList: [5, 10, 25, 50],
                                })}
                                defaultSorted={[{
                                    dataField: 'id',
                                    order: 'asc'
                                }]}
                            />
                        </div>
                    )}
                </ToolkitProvider>
            </CardBody>
            <ResponseModal
                show={successModalDisplay}
                onHide={successModalClose}
                modalheading={successModalHeading}
                modaltext={successModalText}
            />
            <ResponseModal
                show={failureModalDisplay}
                onHide={failureModalClose}
                modalheading={failureModalHeading}
                modaltext={failureModalText}
            />
            <ConfirmationModal
                show={confirmationModalIsOpen}
                modaltext={action}
                onConfirmationTrue={() => {
                    setConfirmationModalIsOpen(false);
                    if (action === "Enable for Live Users") updateStatus("enableCurrentVersion", versionId);
                    else if (action === "Enable for Beta Users") updateStatus("enableBetaVersion", versionId);
                }}
                onHide={() => setConfirmationModalIsOpen(false)}
            />
        </Card>
    );
};

const PromptTemplateVersions = ({ promptTemplateId }) => {
    return (
        <Container fluid className="p-0">
            <div dangerouslySetInnerHTML={{ __html: "" }}></div>
            <div className="d-flex justify-content-between">
                <h1 className="h3">Prompt Template Versions</h1>
                {showCreatePromptTemplateVersionButton() ? (
                    <div>
                        <Link
                            to={{
                                pathname: `/gen-ai/prompt-templates/${promptTemplateId}/version/create`
                            }}
                        >
                            <Button
                                style={{ fontSize: "1rem" }}
                                className="mx-3 btn-success mb-3"
                            >
                                Create a New Prompt Template Version
                            </Button>
                        </Link>
                    </div>
                ) : null}
            </div>
            <ExpandableRowsTable />
        </Container>
    )
}
export default PromptTemplateVersions;
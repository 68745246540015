import { retrieveFromLocalStorage } from "../localStorageUtil";
import { host } from "./index";
import { maxDefaultLimit } from "../../config/UserConfig";
import { secureFetch } from "../secureFetch";

export let createThemeCategory = async (newCategory) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardThemeCategories/create`;

  await fetch(url, {
    method: "POST",
    body: newCategory,
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let getThemeCategories = async (params) => {
  let token = retrieveFromLocalStorage("token");
  let response = {};

  let url =
    `${host}/v4/internal/keyboardThemeCategories?` +
    new URLSearchParams(params);

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((res) => {
      if (res.keyboardThemeCategories)
        response = {
          keyboardThemeCategories: parseCategoryData(
            res.keyboardThemeCategories
          ),
        };
      else response = res;
    });

  return response;
};

export let updateThemeCategory = async (updatedCategory, categoryID) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardThemeCategories/${categoryID}/edit`;

  await fetch(url, {
    method: "POST",
    body: updatedCategory,
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let activateKeyboardThemeCategory = async (categoryID) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardThemeCategories/${categoryID}/activate`;

  await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let deactivateKeyboardThemeCategory = async (categoryID) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardThemeCategories/${categoryID}/deactivate`;

  await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let publishKeyboardThemeCategory = async (categoryID) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardThemeCategories/${categoryID}/publish`;

  await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let getKeyboardThemeCategoryDetail = async (categoryID) => {
  let token = retrieveFromLocalStorage("token");
  let themeCategory = {};

  let url = `${host}/v4/internal/keyboardThemeCategories/${categoryID}/details`;
  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      themeCategory = response;
    });
  return themeCategory;
};

export let getKeyboardThemeCategoriesForForm = async () => {
  let token = retrieveFromLocalStorage("token");
  let categories = [{ value: "", label: "All" }];

  let url = `${host}/v4/internal/keyboardThemeCategories`;
  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.keyboardThemeCategories) {
        response.keyboardThemeCategories.map((category) => {
          let categoryObj = {
            value: category.ID,
            label: category.name,
          };
          categories.push(categoryObj);
        });
      }
    });
  return categories;
};

export let updateThemesCategoriesCountryMappings = async (body, themeID) => {
  let token = retrieveFromLocalStorage("token");
  let url = "",
    resStatus = "";

  url = `${host}/v4/internal/keyboardThemeCategories/${themeID}/countries`;

  await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      countryEffects: body["countryEffects"],
    }),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    resStatus = response.json();
  });

  return resStatus;
};

export let updateThemesCountryMappings = async (body, themeID) => {
  let token = retrieveFromLocalStorage("token");
  let url = "",
    resStatus = "";

  url = `${host}/v4/internal/keyboardThemes/${themeID}/countries`;

  await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      countryEffects: body["countryEffects"],
    }),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    resStatus = response.json();
  });

  return resStatus;
};

export let getKeyboardThemes = async (params) => {
  let token = retrieveFromLocalStorage("token");
  let keyboardThemes = {};

  let url = `${host}/v4/internal/keyboardThemes?` + new URLSearchParams(params);

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    keyboardThemes = response.json();
  });
  return keyboardThemes;
};

export let createKeyboardTheme = async (themeObj) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardThemes/create`;

  await fetch(url, {
    method: "POST",
    body: themeObj,
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let getKeyboardTheme = async (id) => {
  let token = retrieveFromLocalStorage("token");
  let theme = {};
  let url = `${host}/v4/internal/keyboardThemes/${id}/details`;
  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      theme = response;
    });
  return theme;
};

export let editKeyboardTheme = async (themeObj, themeId) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardThemes/${themeId}/edit`;

  await fetch(url, {
    method: "POST",
    body: themeObj,
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let publishKeyboardTheme = async (id) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardThemes/${id}/publish`;

  await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let activateKeyboardTheme = async (id) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardThemes/${id}/activate`;

  await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let deactivateKeyboardTheme = async (id) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardThemes/${id}/deactivate`;

  await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let fetchThemesCountryCodes = async () => {
  let token = retrieveFromLocalStorage("token");
  let countryCodes = {};
  let url = `${host}/v4/internal/keyboardThemes/countries`;

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    countryCodes = response.json();
  });

  return countryCodes;
};

export let fetchThemesRegionCodes = async () => {
  let token = retrieveFromLocalStorage("token");
  let regionCodes = {};
  let url = `${host}/v4/internal/keyboardThemes/regions`;

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then(async (response) => {
    regionCodes = await response.json();
  });

  return groupRegionsByCountry(regionCodes["regions"]);
};

export let fetchThemesCities = async () => {
  let token = retrieveFromLocalStorage("token");
  let cityNames = {};
  let url = `${host}/v4/internal/keyboardThemes/cities`;

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then(async (response) => {
    cityNames = await response.json();
  });

  return groupCities(cityNames["cities"]);
};

let parseCategoryData = (categories) => {
  let updatedCategories = [];
  categories.forEach((category) => {
    let updatedCategory = {};
    updatedCategory.status = addStatus(
      category.createdAt,
      category.publishedAt,
      category.deactivatedAt
    );
    updatedCategory.ID = category.ID;
    updatedCategory.name = category.name;
    updatedCategory.priority = category.priority;
    updatedCategory.bannerImage = category.bannerImage;
    updatedCategory.displayExpandedView = category.displayExpandedView;
    updatedCategory.numPreviewThemes = category.numPreviewThemes;
    updatedCategory.createdAt = category.createdAt;
    updatedCategory.publishedAt = category.publishedAt;
    updatedCategory.updatedAt = category.updatedAt;
    updatedCategory.deactivatedAt = category.deactivatedAt;
    updatedCategories.push(updatedCategory);
  });
  return updatedCategories;
};

let groupRegionsByCountry = (list) => {
  let regions = {
    "": [
      {
        country_code: "",
        region: "",
        region_code: "",
      },
    ],
  };
  if (list == undefined) {
    return regions;
  }
  for (let i = 0; i < list.length; i++) {
    const element = list[i];

    if (!regions[element["country_code"]]) {
      regions[element["country_code"]] = [
        {
          country_code: element["country_code"],
          region: "All Regions",
          region_code: "GLOBAL",
        },
      ];
    }
    if (element["region_code"] != "GLOBAL")
      regions[element["country_code"]] = [
        ...regions[element["country_code"]],
        element,
      ];
  }
  return regions;
};

//groups the cities by region code and country code
let groupCities = (list) => {
  let cities = {
    "": [
      {
        country_code: "",
        region_code: "",
        city_name: "",
        city_id: "",
      },
    ],
  };

  if (list == undefined) {
    return cities;
  }
  for (let i = 0; i < list.length; i++) {
    const element = list[i];
    if (!cities[element["country_code"] + element["region_code"]]) {
      cities[element["country_code"] + element["region_code"]] = [
        {
          country_code: element["country_code"],
          region_code: element["region_code"],
          city_name: "All Cities",
          city_id: "1",
        },
      ];
      cities[element["country_code"] + "GLOBAL"] = [
        {
          country_code: element["country_code"],
          region_code: "GLOBAL",
          city_name: "All Cities",
          city_id: "1",
        },
      ];
    }
    if (element["city_name"] != "GLOBAL")
      cities[element["country_code"] + element["region_code"]] = [
        ...cities[element["country_code"] + element["region_code"]],
        element,
      ];
  }
  return cities;
};

export let fetchAllKeyboardThemes = async (params = {}) => {
  if (!params.limit) params.limit = maxDefaultLimit;
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/keyboardThemes?` + new URLSearchParams(params);

  let res = {};
  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      res = data;
    });
  return res;
};

export let addStatus = (created, published, deactivated) => {
  if (created !== null && published === null) return "Created";
  else if (created !== null && published !== null && deactivated === null)
    return "Activated";
  else if (created !== null && published !== null && deactivated !== null)
    return "Deactivated";
};

export let getThemeRules = async (id) => {
  let rules = [];

  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardThemes/${id}/rules`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      rules = res.rules;
    }
    return rules;
  } catch (error) {
    return;
  }
};

export let saveThemeRules = async (data, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardThemes/${id}/rules`;
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ rules: data }),
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let getThemeCategoryRules = async (id) => {
  let rules = [];

  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardThemeCategories/${id}/rules`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      rules = res.rules;
    }
    return rules;
  } catch (error) {
    return;
  }
};

export let saveThemeCategoryRules = async (data, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardThemeCategories/${id}/rules`;
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ rules: data }),
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let getCountries = async () => {
  let countries = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardThemes/countries`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((country) => {
        let countryObj = {
          value: country.id,
          label: country.name,
        };
        countries.push(countryObj);
      });
    }
    return countries;
  } catch (error) {
    return;
  }
};

export let getRegions = async (countryID) => {
  let regions = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardThemes/regions?countryID=${countryID}`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((region) => {
        let regionObj = {
          value: region.id,
          label: region.name,
        };
        regions.push(regionObj);
      });
    }
    return regions;
  } catch (error) {
    return;
  }
};

export let getCities = async (countryID, regionID) => {
  let cities = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardThemes/cities?countryID=${countryID}&regionID=${regionID}`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((city) => {
        let cityObj = {
          value: city.id,
          label: city.name,
        };
        cities.push(cityObj);
      });
    }
    return cities;
  } catch (error) {
    return;
  }
};


export let getThemeCategoriesUserAttributeRules = async (id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardThemeCategories/${id}/userAttributeRules`;
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    return res;
  } catch (error) {
    return;
  }
};

export let saveThemeCategoriesUserAttributeRules = async (data, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardThemeCategories/${id}/userAttributeRules`;
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let deactivateThemeCategoriesUserAttributeRules = async (categoryId, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardThemeCategories/${categoryId}/userAttributeRules/${id}/deactivate`;
  try {
    var response = await secureFetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let activateThemeCategoriesUserAttributeRules = async (categoryId, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardThemeCategories/${categoryId}/userAttributeRules/${id}/activate`;
  try {
    var response = await secureFetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};
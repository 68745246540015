import { retrieveFromLocalStorage } from "../localStorageUtil";
import { host } from "../apiUtils/index";
import { secureFetch } from "../secureFetch";

export let fetchBrandCampaignsConfig = async () => {
  let url = `${host}/v4/internal/brandCampaigns/entities`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchAutoCappings = async () => {
  let url = `${host}/v4/internal/brandCampaigns/cappings`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let deleteAutoCapping = async (id, data) => {
  let url = `${host}/v4/internal/brandCampaigns/capping/${id}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let createAutoCapping = async (data) => {
  let url = `${host}/v4/internal/brandCampaigns/capping`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let updateAutoCapping = async (id, data) => {
  let url = `${host}/v4/internal/brandCampaigns/capping/${id}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchBrandCampaignsAds = async () => {
  let url = `${host}/v4/internal/ads/campaignAds`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};
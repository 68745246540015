import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import ConfirmationModal from "../ConfirmationModal";
import ResponseModal from "../ResponseModal";
import { listPriorityRuleSets } from "../../../utilities/apiUtils/segmentCategoryOrder";
import { tableColumns } from "./tableColumn";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import Loading from "../../../components/Loading";
import { successModalHeading, errorModalHeading } from "../../../config/config";
import { statusOptions, processDateAndStatus } from "../../../config/segmentCategories";

const SegmentCategoryOrder = () => {
  const [loading, setLoading] = useState(true);
  const [segmentCategories, setSegmentCategories] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [successModalText, setSuccessModalText] = useState("");
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [action, setAction] = useState("");
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);

  // Fetch segment categories data
  const fetchSegmentCategoriesData = async () => {
    try {
      setLoading(true);
      const data = await listPriorityRuleSets();
      console.log('API Data:', data); // Check the structure of the response
      if (data) {
        // const processedCategories = data.map(item => processDateAndStatus(item));
        console.log('Processed Data:', data); // Check processed data
        setSegmentCategories(data);
      }
      setLoading(false);
    } catch (error) {
      setFailureModalText(error.message);
      setFailureModalDisplay(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSegmentCategoriesData();
    // Restore filters from sessionStorage
    const savedNameFilter = sessionStorage.getItem("segmentCategoriesFilterName") || "";
    const savedStatusFilterValue = sessionStorage.getItem("segmentCategoriesFilterStatus") || "";
    setNameFilter(savedNameFilter);
    if (savedStatusFilterValue) {
      setStatusFilter({
        value: savedStatusFilterValue,
        label: statusOptions.find(option => option.value === savedStatusFilterValue)?.label || "",
      });
    }
  }, []);

  // Filter segment categories based on filters
  const filterSegmentCategories = () => {
    let items = segmentCategories;

    if (statusFilter?.value) {
      items = items.filter(item => item.status === statusFilter.value);
    }

    if (nameFilter) {
      items = items.filter(item => item.name?.toLowerCase().includes(nameFilter.toLowerCase()));
    }

    console.log('Filtered Categories:', items); // Check the filtered data
    return items;
  };

  // Close success modal and reload data
  const successModalClose = async () => {
    setSuccessModalDisplay(false);
    await fetchSegmentCategoriesData(); // Fetch data after closing the modal
  };

  // Close failure modal
  const failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  // Expand row configuration
  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <Link
          className="text-decoration-none"
          to={{ pathname: `/suggestionDrawer/category/rulesetOrder/${row.ruleId}`, state: { formType: "edit" } }}
        >
          <Button className="mx-3">Edit</Button>
        </Link>
      </div>
    ),
  };

  return (
    <Container fluid className="p-0">
      <Row>
        <h1 className="mb-4 mt-2 w-50 pl-3">Segment Categories</h1>
        <Link
          className="d-block ml-auto mb-4 pr-3 text-decoration-none"
          to="/suggestionDrawer/category/rulesetOrder/create"
        >
          <Button color="success" className="ml-auto d-flex btn-success">
            <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>Add Segment Order</p>
          </Button>
        </Link>
      </Row>
      <div>
        <Card style={{ overflow: "hidden", paddingBottom: "100px" }}>
          <CardBody>
            {loading ? (
              <Loading />
            ) : (
              <CardBody>
                <BootstrapTable
                  bootstrap4={true}
                  wrapperClasses="table-responsive"
                  hover={true}
                  bordered={false}
                  keyField="id"
                  data={filterSegmentCategories()}
                  columns={tableColumns}
                  expandRow={expandRow}
                  filter={filterFactory()}
                  pagination={paginationFactory({
                    sizePerPage: 10,
                    sizePerPageList: [5, 10, 25, 50],
                  })}
                  defaultSorted={[{ dataField: "priority", order: "asc" }]}
                  remote={{ filter: true }}
                  noDataIndication="No results found"
                />
              </CardBody>
            )}
          </CardBody>
        </Card>
      </div>
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={action}
        onHide={() => setConfirmationModalIsOpen(false)}
      />
      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={successModalHeading}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={errorModalHeading}
        modaltext={failureModalText}
      />
    </Container>
  );
};

export default SegmentCategoryOrder;

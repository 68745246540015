import React, { useState, useEffect } from "react";
import { InputGroup, Modal } from "react-bootstrap";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
  Input,
  InputGroupText,
  InputGroupAddon,
} from "reactstrap";

import { Eye as EyeIcon, EyeOff as EyeOffIcon } from "react-feather";

const ResetPasswordModal = (props) => {
  const [oldPassword, setOldPassword] = useState(props.data.oldPassword);
  const [password, setPassword] = useState(props.data.password);
  const [confirmedPassword, setConfirmedPassword] = useState(
    props.data.confirmedPassword
  );
  const [isPasswordVisible, setIsPasswordVisible] = useState(
    props.data.isPasswordVisible
  );
  const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(
    props.data.isOldPasswordVisible
  );

  useEffect(() => {
    setOldPassword(props.data.isOldPassword);
    setPassword(props.data.password);
    setConfirmedPassword(props.data.confirmedPassword);
    setIsOldPasswordVisible(props.data.isPasswordVisible);
    setIsOldPasswordVisible(props.data.isOldPasswordVisible);
  }, [props.data]);

  const handleChange = (event) => {
    hideAlert("passwordMismatch");

    switch (event.target.name) {
      case "oldPassword":
        setOldPassword(event.target.value);
        break;
      case "password":
        setPassword(event.target.value);
        break;
      case "confirmedPassword":
        setConfirmedPassword(event.target.value);
        break;

      default:
        return;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (password !== confirmedPassword) {
      showAlert("passwordMismatch");
      return;
    }
    let data = {
      id: props.userDetails.id,
    };
    if (
      password !== "" &&
      password !== undefined &&
      oldPassword !== "" &&
      oldPassword !== undefined
    ) {
      data["currentPassword"] = oldPassword;
      data["newPassword"] = password;
    }
    props.resetPassword(data);
  };

  const showAlert = (elementId) => {
    document.getElementById(elementId).classList.remove("d-none");
  };

  const hideAlert = (elementId) => {
    document.getElementById(elementId).classList.add("d-none");
  };

  const generateRandomPassword = () => {
    var randomPassword = Math.random().toString(36).slice(-10);
    setPassword(randomPassword);
    setConfirmedPassword(randomPassword);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Reset Password
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label>Old Password</Label>
                <InputGroup>
                  <Input
                    type={isOldPasswordVisible ? "text" : "password"}
                    name="oldPassword"
                    className="border-right-0"
                    placeholder="Enter Old Password"
                    onChange={handleChange}
                    value={oldPassword}
                    required
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText className="d-flex align-items-center justify-content-center border-left-0 bg-white">
                      {isOldPasswordVisible ? (
                        <EyeIcon
                          size={16}
                          onClick={() => {
                            setIsOldPasswordVisible(!isOldPasswordVisible);
                          }}
                        />
                      ) : (
                        <EyeOffIcon
                          onClick={() => {
                            setIsOldPasswordVisible(!isOldPasswordVisible);
                          }}
                          size={16}
                        />
                      )}
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label>New Password</Label>
                <InputGroup>
                  <Input
                    type={isPasswordVisible ? "text" : "password"}
                    name="password"
                    className="border-right-0"
                    placeholder="Enter Password"
                    onChange={handleChange}
                    value={password}
                    required
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText className="d-flex align-items-center justify-content-center border-left-0 bg-white">
                      {isPasswordVisible ? (
                        <EyeIcon
                          size={16}
                          onClick={() => {
                            setIsPasswordVisible(!isPasswordVisible);
                          }}
                        />
                      ) : (
                        <EyeOffIcon
                          onClick={() => {
                            setIsPasswordVisible(!isPasswordVisible);
                          }}
                          size={16}
                        />
                      )}
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>
                  Confirm Password
                  <small id="passwordMismatch" className="text-danger d-none">
                    (Password mismatch)
                  </small>
                </Label>
                <InputGroup>
                  <Input
                    type={isPasswordVisible ? "text" : "password"}
                    name="confirmedPassword"
                    placeholder="Confirm Password"
                    className="border-right-0"
                    onChange={handleChange}
                    value={confirmedPassword}
                    required
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText className="d-flex align-items-center justify-content-center border-left-0 bg-white">
                      {isPasswordVisible ? (
                        <EyeIcon
                          size={16}
                          onClick={() => {
                            setIsPasswordVisible(!isPasswordVisible);
                          }}
                        />
                      ) : (
                        <EyeOffIcon
                          onClick={() => {
                            setIsPasswordVisible(!isPasswordVisible);
                          }}
                          size={16}
                        />
                      )}
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row className="mt-1">
            <Button
              className="ml-3"
              color="info"
              onClick={() => {
                generateRandomPassword();
              }}
            >
              Generate Password
            </Button>

            <Button className="ml-2" color="primary" type="submit">
              Submit
            </Button>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ResetPasswordModal;

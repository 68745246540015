import { retrieveFromLocalStorage } from "../localStorageUtil";
import { host } from "../apiUtils/index";
import { secureFetch } from "../secureFetch";

export let fetchConflictAds = async () => {
    let url = `${host}/v4/internal/ads/conflicts`;
    let token = retrieveFromLocalStorage("token");
    try {
      var response = await secureFetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      return await response.json();
    } catch (error) {
      return [];
    }
  };

  export let updateAdsPriority = async (data) => {
    let url = `${host}/v4/internal/ads/conflicts`;
    let token = retrieveFromLocalStorage("token");
    try {
      var response = await secureFetch(url, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      return await response.json();
    } catch (error) {
      return;
    }
  };

  export let resetAdsPriority = async (data) => {
    let url = `${host}/v4/internal/ads/conflicts/reset`;
    let token = retrieveFromLocalStorage("token");
    try {
      var response = await secureFetch(url, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      return await response.json();
    } catch (error) {
      return;
    }
  };

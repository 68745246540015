import React, { Fragment, useState, useEffect } from "react";
import "react-h5-audio-player/lib/styles.css";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { Badge, Card, CardBody, CardImg, CardTitle } from "reactstrap";
import {
  getAdBannerDetail, // Change the import
} from "../../../utilities/apiUtils/adBanners"; // Change the import
import Loading from "../../../components/Loading";
import { getStatus, getStatusColor } from "../../../utilities/commonUtil";

const AdBannerDetailsBoard = (props) => {
  // Change the component name
  const [adBanner, setAdBanner] = useState({}); // Change reply to adBanner
  const [loading, setLoading] = useState(false);
  const [bannerVideoBufferType, setBannerVideoBufferType] = useState("");

  useEffect(() => {
    setLoading(true);
    const getAdBannerDetails = async () => {
      // Change the function name
      let res = await getAdBannerDetail(props.id); // Change the function name
      // set bannerVideoBufferType by using the bannerVideoURL
      if (res.bannerVideoURL !== "" && res.bannerVideoURL !== null) {
        // detect video type
        if (res.bannerVideoURL.includes(".mp4")) {
          setBannerVideoBufferType("video");
        } else {
          setBannerVideoBufferType("image");
        }
      }
      setLoading(false);
      setAdBanner(res); // Change reply to adBanner
    };
    getAdBannerDetails();
  }, [props.id]); // Add props.id as a dependency to useEffect

  return (
    <Fragment>
      {loading ? (
        <Loading />
      ) : (
        <Fragment>
          <Card>
            <React.Fragment>
              {adBanner.bannerImageURL ? (
                <div
                  style={{
                    overflow: "hidden",
                    height: "300px",
                    backgroundSize: "contain",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${adBanner.bannerImageURL}) , linear-gradient(rgba(200, 200, 200, 0.5), rgba(200, 200, 200, 0.5))`,
                  }}
                ></div>
              ) : (
                <div className="theme-preview-image bg-dark"></div>
              )}
            </React.Fragment>
            <CardBody>
              <CardTitle tag="h3">
                <Badge
                  className="float-right"
                  color={getStatusColor(
                    getStatus(adBanner.publishedAt, adBanner.deactivatedAt)
                  )}
                >
                  {getStatus(adBanner.publishedAt, adBanner.deactivatedAt)}
                </Badge>
              </CardTitle>
              ID : {adBanner.id}
              <br />
              Name : {adBanner.name}
              <br />
              Created At : {adBanner.createdAt}
              <br />
              Updated At : {adBanner.updatedAt}
              <br />
              Published At : {adBanner.publishedAt}
              <br />
              Deactivated At : {adBanner.deactivatedAt}
              <br />
              {adBanner.bannerVideoURL && (
                <Fragment>
                  {bannerVideoBufferType === "video" && (
                    <>
                      <h4>Video Preview:</h4>
                      <video
                        controls
                        style={{
                          height: "200px",
                          width: "100%",
                        }}
                      >
                        <source
                          src={adBanner.bannerVideoURL}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </>
                  )}
                  {bannerVideoBufferType === "image" && (
                    <>
                      <h4>Image Preview</h4>
                      <img
                            src={
                              
                              adBanner.bannerVideoURL
                            }
                            style={{
                              height: "200px",
                              width: "100%",
                            }}
                            alt="bannerVideoBuffer"
                          />
                    </>
                  )}


                </Fragment>
              )}
            </CardBody>
          </Card>
        </Fragment>
      )}
    </Fragment>
  );
};

export default AdBannerDetailsBoard; // Change the export name

import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import {
  Card,
  FormGroup,
  Label,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  CustomInput,
} from "reactstrap";

const CompleteABTest = (props) => {
  const [variants, setVariants] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState({});

  // This useEffect will be called for componentWillReceiveProps condition
  useEffect(() => {
    setVariants(props.variants);
  }, [props]);

  const handleSelectedVariantChange = (event) => {
    const { value } = event.target;
    setSelectedVariant(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    props.completeABTest(selectedVariant);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Complete AB Test
        </Modal.Title>
      </Modal.Header>
      <Card className="mb-0">
        <CardBody>
          <Form onSubmit={(event) => handleSubmit(event)}>
            <Row>
              {variants &&
                variants.map((variant, index) => {
                  return (
                    <Col md={7} key={index}>
                      <FormGroup className="row">
                        <Label className="col-lg-3 col-sm-12">
                          {variant.label}
                        </Label>
                        <CustomInput
                          type="radio"
                          id={variant.value}
                          name="selectedVariant"
                          value={variant.value}
                          className="col-lg-2 col-sm-4 pl-5"
                          onChange={(event) =>
                            handleSelectedVariantChange(event)
                          }
                        />
                      </FormGroup>
                    </Col>
                  );
                })}
            </Row>
            <Button color="primary" type="submit">
              Submit
            </Button>
          </Form>
        </CardBody>
      </Card>
    </Modal>
  );
};

export default CompleteABTest;

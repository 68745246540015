import React, { PureComponent } from "react";
import { fetchGif } from "../../../utilities/apiUtils";
import { getBrandCampaign } from "../../../utilities/apiUtils/brandCampaign";
import Loading from "../../../components/Loading";
import {
  error,
  errorDescription,
  getStatus,
  permissions,
  getStatusColor,
  redirectTo,
  redirectOnTokenExpiry
} from "../../../utilities/commonUtil";
import ManageGifShareTexts from "./ManageGifShareTexts"
import ResponseModal from "../ResponseModal";
import CountryTags from "../../../components/CountryTags";
import ShareTextsTable from "./components/ShareTextsTable";
import TagTable from "./components/tagTable";
import { Modal } from "react-bootstrap";
import {
  Row,
  Col,
  Badge,
  Card,
  CardBody,
  CardImg,
  CardHeader,
  CardTitle,
  Button,
  Container,
  Input,
  Label
} from "reactstrap";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import GifInGifPacksTable from "./components/GifInGifPacksTable";
import { Link } from "react-router-dom";
import { getGifShareText } from "../../../utilities/apiUtils/gifs";

class GifDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasResponseError: false,
      failureModalText: "",
      gif: {},
      loading: true,
      status: "",
      allowedPermissions: retrieveFromLocalStorage("userPermissions"),
      gifPacks: [],
      brandCampaign: {},
      showGifShareText: false,
      showModal: false,
      showImageJson: false
    };
  }

  async componentDidMount() {
    await this.getGif();
  }
  closeGifShareText = () => {
    this.setState({ showGifShareText: false });
  };
  handleShowModal = () => {
    this.setState({ showModal: true });
  };


  toggleImageJson = () => {
    this.setState(prevState => ({
      showImageJson: !prevState.showImageJson
    }));
  };
  handleCloseModal = () => {
    this.setState({ showModal: false });
  };
  getGif = () => {
    fetchGif(this.props.match.params.gifid)
      .then((res) => {
        if (res.gif) {
          let gifPacks = res.gifPacks;

          for (let i = 0; i < gifPacks.length; i++) {
            gifPacks[i]["status"] = getStatus(
              gifPacks[i].publishedAt,
              gifPacks[i].deactivatedAt
            );
          }
          this.setState({
            gif: res.gif,
            gifPacks: gifPacks,
            status: getStatus(
              res.gif.publishedAt,
              res.gif.deactivatedAt
            ),
          });
          this.fetchBrandCampaign(res.gif.brandCampaignId)
          this.fetchGifShareText(this.state.gif.id)
        } else {
          this.setState({
            hasResponseError: true,
            failureModalText: errorDescription(res.errorCode),
          });
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          hasResponseError: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      });
  };

  fetchBrandCampaign = async (brandCampaignId) => {
    if (brandCampaignId != null) {
      await getBrandCampaign(brandCampaignId)
        .then((response) => {
          if (response) {
            let brandCampaign = response.brandCampaign
            this.setState({ brandCampaign })
          } else if (response.errorCode) {
            if (
              response.errorCode === error.tokenExpired ||
              response.errorCode === error.invalidAccessToken
            ) {
              redirectOnTokenExpiry();
            } else {
              this.setState({
                hasResponseError: true,
                failureModalText: errorDescription(response.errorCode),
              });
            }
          }
        })
        .catch((err) => {
          this.setState({
            loading: false,
            failureModalDisplay: true,
            failureModalText: errorDescription(error.unexpectedError),
          });
        });
    }
  }
  fetchGifShareText = async (GifId) => {
    if (GifId != null) {
      await getGifShareText(GifId)
        .then((response) => {
          if (response) {

            let shareTexts = response.shareTexts
            this.setState({ shareTexts })
            let enableShareTextInKeyboard = response.enableShareTextInKeyboard
            this.setState({ enableShareTextInKeyboard })
          } else if (response.errorCode) {
            if (
              response.errorCode === error.tokenExpired ||
              response.errorCode === error.invalidAccessToken
            ) {
              redirectOnTokenExpiry();
            } else {
              this.setState({
                hasResponseError: true,
                failureModalText: errorDescription(response.errorCode),
              });
            }
          }
        })
        .catch((err) => {
          this.setState({
            loading: false,
            failureModalDisplay: true,
            failureModalText: errorDescription(error.unexpectedError),
          });
        });

    }
  }
  showEditShareTextButton = () => {
    return this.state.allowedPermissions.includes(permissions.gifShareTextUpdate)
  }

  render() {
    let { gif, showModal, showImageJson } = this.state
    return (
      <Container fluid className="p-0">
        {this.state.hasResponseError ? (
          <ResponseModal
            show={this.state.hasResponseError}
            onHide={() => {
              this.setState({ hasResponseError: false });
            }}
            modalheading={"Error"}
            modaltext={this.state.failureModalText}
          />
        ) : (
          <React.Fragment>
            <h1 className="h3 mb-3">Gif Detail</h1>
            {this.state.loading ? (
              <Loading />
            ) : (
              <div>
                <Card>
                  <CardBody>
                    <Container>
                      <Row style={{ justifyContent: "space-between" }}>
                        <Col md={2} lg={3} className="d-block my-auto">
                          <CardImg
                            top
                            src="/img/white-bg.png"
                            onLoad={(e) =>
                              (e.target.src = this.state.gif.imageURL)
                            }
                            alt="Card image cap"
                          />
                        </Col>
                        <Col md={8} lg={7} className="d-block my-auto">
                          <Row>
                            <Col>
                              <p className="p-0 m-0">
                                {" "}
                                <span className="font-weight-bolder">
                                  ID :
                                </span>{" "}
                                {this.state.gif.id}
                              </p>
                              <p className="p-0 m-0">
                                <span className="font-weight-bolder">
                                  Image Processing Status:
                                </span>{" "}
                                <Badge
                                  className="mb-1"
                                // color="primary"
                                >
                                  {this.state.gif.imageProcessingStatus}
                                </Badge>
                              </p>

                              <p className="p-0 m-0">
                                <span className="font-weight-bolder">
                                  Event Gif :
                                </span>{" "}
                                {this.state.gif.isEventGif ? (
                                  <span className="text-success">Yes</span>
                                ) : (
                                  <span className="text-danger">No</span>
                                )}
                              </p>

                              <p className="p-0 m-0">
                                <span className="font-weight-bolder">
                                  Status:
                                </span>{" "}
                                <Badge
                                  className="mb-1"
                                  color={getStatusColor(this.state.status)}
                                >
                                  {this.state.status}
                                </Badge>
                              </p>
                              <br></br>
                              <Button variant="primary" color="primary" onClick={this.handleShowModal}>
                                Show More
                              </Button>
                              <div className="justify-content-center mt-3 row">
                                <div className="col-sm-12 col-md-3">
                                  <Link to={`/gifs/${gif.id}/edit`}>
                                    <Button class="mw-75 w-120 btn btn-primary">Edit</Button>
                                  </Link>
                                </div>
                              </div>
                              <Modal show={showModal} onHide={this.handleCloseModal} centered>
                                <Modal.Header closeButton>
                                  <Modal.Title>Gif Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <Row className="gy-3">
                                    {/* Grid Layout */}
                                    <Col xs={6}>
                                      <strong>Default Language:</strong>
                                    </Col>
                                    <Col xs={6}>{this.state.gif.defaultLanguage || "Not specified"}</Col>

                                    <Col xs={6}>
                                      <strong>Is Head Supported:</strong>
                                    </Col>
                                    <Col xs={6}>
                                      {this.state.gif.isHeadSupported ? (
                                        <span className="text-success">Yes</span>
                                      ) : (
                                        <span className="text-danger">No</span>
                                      )}
                                    </Col>
                                    <Col xs={6}>
                                      <strong>Head Gender:</strong>
                                    </Col>
                                    <Col xs={6}>{this.state.gif.headGender || "Not specified"}</Col>

                                    <Col xs={6}>
                                      <strong>Head Type:</strong>
                                    </Col>
                                    <Col xs={6}>{this.state.gif.headType || "Not specified"}</Col>
                                    <Col xs={6}>
                                      <strong>Is Event Gif:</strong>
                                    </Col>
                                    <Col xs={6}>{gif.isEventGif ? (
                                      <span className="text-success">Yes</span>
                                    ) : (
                                      <span className="text-danger">No</span>
                                    )}</Col>
                                    <Col xs={6}>
                                      <strong>Use Mascot Head:</strong>
                                    </Col>
                                    <Col xs={6}>
                                      {this.state.gif.useMascotHead ? (
                                        <span className="text-success">Yes</span>
                                      ) : (
                                        <span className="text-danger">No</span>
                                      )}
                                    </Col>

                                    <Col xs={6}>
                                      <strong>Watermark Enabled:</strong>
                                    </Col>
                                    <Col xs={6}>
                                      {this.state.gif.isWatermarkEnabled ? (
                                        <span className="text-success">Yes</span>
                                      ) : (
                                        <span className="text-danger">No</span>
                                      )}
                                    </Col>

                                    <Col xs={6}>
                                      <strong>OTF Supported:</strong>
                                    </Col>
                                    <Col xs={6}>
                                      {this.state.gif.isOtfSupported ? (
                                        <span className="text-success">Yes</span>
                                      ) : (
                                        <span className="text-danger">No</span>
                                      )}
                                    </Col>
                                    <Col xs={6}>
                                      <strong>Font ID:</strong>
                                    </Col>
                                    <Col xs={6}>{this.state.gif.fontId || "Not specified"}</Col>

                                    <Col xs={6}>
                                      <strong>Start At:</strong>
                                    </Col>
                                    <Col xs={6}>{this.state.gif.startAt || "Not specified"}</Col>

                                    <Col xs={6}>
                                      <strong>End At:</strong>
                                    </Col>
                                    <Col xs={6}>{this.state.gif.endAt || "Not specified"}</Col>

                                    <Col xs={6}>
                                      <strong>UTM Campaign:</strong>
                                    </Col>
                                    <Col xs={6}>{this.state.gif.utmCampaign || "Not specified"}</Col>

                                    <Col xs={6}>
                                      <strong>Is Political:</strong>
                                    </Col>
                                    <Col xs={6}>
                                      {this.state.gif.isPolitical ? (
                                        <span className="text-success">Yes</span>
                                      ) : (
                                        <span className="text-danger">No</span>
                                      )}
                                    </Col>

                                    <Col xs={6}>
                                      <strong>Is Copyright:</strong>
                                    </Col>
                                    <Col xs={6}>
                                      {this.state.gif.isCopyright ? (
                                        <span className="text-success">Yes</span>
                                      ) : (
                                        <span className="text-danger">No</span>
                                      )}
                                    </Col>

                                    <Col xs={6}>
                                      <strong>Content Rating:</strong>
                                    </Col>
                                    <Col xs={6}>{this.state.gif.contentRating || "Not specified"}</Col>

                                    {/* Additional Fields */}
                                    <Col xs={6}>
                                      <strong>Brand Campaign:</strong>
                                    </Col>
                                    <Col xs={6}>
                                      {this.state.gif?.brandCampaignId != null
                                        ? this.state.gif?.brandCampaign?.brandName
                                        : "Brand not available"}
                                    </Col>
                                    <Col xs={6}>
                                    <strong>Brand Campaign ID:</strong>
                                  </Col>
                                  <Col xs={6}>{this.state.gif?.brandCampaignId || "Not specified"}</Col>
                                    <Col xs={6}>
                                      <strong>Created At:</strong>
                                    </Col>
                                    <Col xs={6}>
                                      {this.state.gif.createdAt
                                        ? new Date(this.state.gif.createdAt).toLocaleString()
                                        : "Not specified"}
                                    </Col>

                                    <Col xs={6}>
                                      <strong>Published At:</strong>
                                    </Col>
                                    <Col xs={6}>
                                      {this.state.gif.publishedAt
                                        ? new Date(this.state.gif.publishedAt).toLocaleString()
                                        : "Not specified"}
                                    </Col>

                                    <Col xs={6}>
                                      <strong>Deactivated At:</strong>
                                    </Col>
                                    <Col xs={6}>
                                      {this.state.gif.deactivatedAt
                                        ? new Date(this.state.gif.deactivatedAt).toLocaleString()
                                        : "Not specified"}
                                    </Col>

                                    <Col xs={6}>
                                      <strong>Updated At:</strong>
                                    </Col>
                                    <Col xs={6}>
                                      {this.state.gif.updatedAt
                                        ? new Date(this.state.gif.updatedAt).toLocaleString()
                                        : "Not specified"}
                                    </Col>


                                    <Col xs={6}>
                                      <strong>Targetting Details Source:</strong>
                                    </Col>
                                    <Col xs={6}>{gif.targettingDetailsSource || "Not specified"}</Col>

                                    <Col xs={6}>
                                      <strong>Expression IDs:</strong>
                                    </Col>
                                    <Col xs={6}>{gif.expressionIds || "Not specified"}</Col>

                                    <Col xs={6}>
                                      <strong>Wig IDs:</strong>
                                    </Col>
                                    <Col xs={6}>{gif.wigIds || "Not specified"}</Col>

                                    <Col xs={6}>
                                      <strong>Accessory IDs:</strong>
                                    </Col>
                                    <Col xs={6}>{gif.accessoryIds || "Not specified"}</Col>

                                    <Col xs={6}>
                                      <strong>Customization Details:</strong>
                                    </Col>
                                    <Col xs={6}>
                                      <pre className="bg-light p-2 rounded">
                                        {JSON.stringify(gif.customizationDetails, null, 2)}
                                      </pre>
                                    </Col>
                                    {/* Image JSON with Show More */}
                                    <Col xs={6}>
                                      <strong>Images JSON</strong>
                                    </Col>
                                    <Col xs={6} className="my-3">
                                      <Button
                                        variant="outline-secondary"
                                        className="my-2 px-3 py-1 text-decoration-none"
                                        style={{ borderRadius: "5px", fontSize: "14px" }}
                                        onClick={this.toggleImageJson}
                                      >
                                        {showImageJson ? "Hide JSON" : "Show More"}
                                      </Button>
                                    </Col>

                                    {showImageJson && (
                                      <pre className="bg-light p-2 rounded mt-2">
                                        {JSON.stringify(JSON.parse(gif.imageJson || "{}"), null, 2)}
                                      </pre>
                                    )}

                                    <Col xs={6}>
                                      <strong>Raw Resources:</strong>
                                    </Col>
                                    <Col xs={6}>
                                      <Button
                                        variant="primary"
                                        onClick={() => window.open(gif.rawResourceUrl, "_blank")}
                                      >
                                        Download
                                      </Button>
                                    </Col>
                                  </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button variant="secondary" color="primary" onClick={this.handleCloseModal}>
                                    Close
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <h3>Tags</h3>
                    <TagTable tag={this.state.gif.tags} gifType="gif"></TagTable>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row className="px-3">
                      <Col md={8} sm={6}>
                        <h3>Available Locations</h3>
                        <p>Locations in which countries this gif is available</p>
                      </Col>
                    </Row>
                    <Col>
                      <CountryTags countries={this.state.gif.countries}></CountryTags>
                    </Col>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Container fluid className="p-0">
                      <Row>
                        <Col xs={6}>
                          <h3 className="mb-4 mt-2 w-50 pl-3" style={{ padding: "0 ", display: "flex", alignItems: "left" }}>Share Texts</h3>
                        </Col>
                        <Col xs={6} style={{ padding: "0px 5% 0px 0px", display: "flex", alignItems: "center" }}>
                          {
                            this.showEditShareTextButton() ? <Button color="primary" className="ml-auto d-flex" style={{ padding: "0px 10px 0px 10px" }} onClick={() => this.setState({ showGifShareText: true })}>
                              <p className="mb-1 mt-1" style={{ fontSize: "1rem", margin: "0" }}>
                                Edit
                              </p>
                            </Button> : null
                          }
                          {this.state.showGifShareText === true && (
                            <ManageGifShareTexts
                              show={this.state.showGifShareText}
                              onHide={this.closeGifShareText}
                              shareTexts={this.state.shareTexts}
                              enableShareTextInKeyboard={this.state.enableShareTextInKeyboard}
                              gifID={this.state.gif.id}
                              action={"edit"}
                            />
                          )}
                        </Col>
                      </Row>
                      <Row className="mb-3" style={{ padding: '0px 50px 0px 0px', margin: '0px 50px 0px 0px ' }}>
                        <Col xs={12} className="d-flex align-items-left" >
                          <Input
                            type="checkbox"
                            name="enableShareTextInKeyboard"
                            checked={this.state.enableShareTextInKeyboard}
                            disabled="true"
                            style={{ padding: '0px 50px 0px 0px', margin: '15px 50px 0px 0px ' }}
                          />
                          <Label
                            className="mb-4 mt-2"
                            style={{ fontSize: "16px", padding: '0px 50px 0px 30px', margin: '0px' }}
                          >
                            Enable Share Text in Keyboard
                          </Label>
                        </Col>
                      </Row>

                      <ShareTextsTable shareTexts={this.state.shareTexts} ></ShareTextsTable>
                    </Container>
                  </CardBody>
                </Card>
                <GifInGifPacksTable gifPacks={this.state.gifPacks} />
              </div>
            )}
          </React.Fragment>
        )}
      </Container>
    );
  }
}

export default GifDetail;

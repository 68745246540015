import React, { useState, useEffect } from "react";
import { Card, CardBody, Container, Label, Input } from "reactstrap";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
    Row,
    Col,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ResponseModal from "../ResponseModal";
import { failureModalHeading, successModalHeading } from "../../../config";
import ConfirmationModal from "../ConfirmationModal";
import { allActions, error, errorDescription } from "../../../utilities/commonUtil";
import { systemPromptsTableColumns } from "./systemPromptsTableColumns";
import { activateSystemPrompt, deactivateSystemPrompt, getSystemPrompts } from "../../../utilities/apiUtils/chatAssistantDashboard";
import { systemPromptResponses, clientOptions, formatTime, llmProviderOptions, personaNameOptions, personaTriggerOptions, showActivateSystemPromptButton, showCreateSystemPromptButton, showDeactivateSystemPromptButton, statusOptions, typeOptions, systemPromptAPIStatus } from "../../../config/configSystemPrompts";
import WarningConfirmationModal from "../WarningConfirmationModal";
import Search from "../../../components/Search";
import filterFactory from "react-bootstrap-table2-filter";

const ExpandableRowsTable = () => {
    const [systemPromptsData, setSystemPromptsData] = useState([]);
    const [failureModalText, setFailureModalText] = useState("");
    const [failureModalDisplay, setFailureModalDisplay] = useState(false);
    const [successModalText, setSuccessModalText] = useState("");
    const [successModalDisplay, setSuccessModalDisplay] = useState(false);
    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
    const [action, setAction] = useState("");
    const [status, setStatus] = useState({ value: "all", label: "All" });
    const [clientId, setClientId] = useState({ value: "", label: "Select Client" });
    const [llmProvider, setLLMProvider] = useState({ value: "", label: "Select LLM Provider" });
    const [personaName, setPersonaName] = useState({ value: "", label: "Select Persona" });
    const [personaTrigger, setPersonaTrigger] = useState({ value: "", label: "Select Trigger" });
    const [type, setType] = useState({ value: "production", label: "Production" });
    const [id, setId] = useState();
    const [warningModalIsOpen, setWarningModalIsOpen] = useState(false);
    const [tempRow, setTempRow] = useState(null);
    const [searchValue, setSearchValue] = useState("");

    const fetchSystemPrompts = async (status) => {
        const params = {
            type: type?.value || "",
            clientId: clientId?.value || "",
            personaName: personaName?.value || "",
            personaTrigger: personaTrigger?.value || "",
            llmProvider: llmProvider?.value || ""
        }
        try {
            let response = await getSystemPrompts(params)
            if (!response || response?.length === 0) {
                setSystemPromptsData([]);
                return;
            }
            if (response.length > 0) {
                if (status === "all")
                    setSystemPromptsData(response);
                else if (status === "activated") {
                    const activatedPrompts = response.filter(prompt => prompt.deactivatedAt == null);
                    setSystemPromptsData(activatedPrompts);
                }
                else if (status === "deactivated") {
                    const deactivatedPrompts = response.filter(prompt => prompt.deactivatedAt != null);
                    setSystemPromptsData(deactivatedPrompts);
                }
            } else {
                showErrorModal(errorDescription(response.errorCode));
            }
        } catch (err) {
            showErrorModal(errorDescription(error.unexpectedError));
        }
    }

    useEffect(() => {
        if (status?.value) {
            fetchSystemPrompts(status.value);
        }
    }, [status, clientId, llmProvider, personaName, personaTrigger, type]);

    let onTableChange = (type, newState) => { };

    let showErrorModal = (text) => {
        setFailureModalText(text);
        setFailureModalDisplay(true);
    };

    let failureModalClose = () => {
        setFailureModalDisplay(false);
    };

    let successModalClose = () => {
        setSuccessModalDisplay(false);
        fetchSystemPrompts(status.value);
    };

    let showSuccessModal = (text) => {
        setSuccessModalText(text);
        setSuccessModalDisplay(true);
    };

    let disableDeactivateButton = (row) => {
        return row.deactivatedAt !== null
    };

    let disableActivateButton = (row) => {
        return row.deactivatedAt === null
    };

    let activatePrompt = async (id) => {
        try {
            let response = await activateSystemPrompt(id);
            if (response.status === systemPromptAPIStatus.activated) {
                showSuccessModal(systemPromptResponses.activatedSuccessfully);
            } else {
                if (response.errorCode) {
                    showErrorModal(response.errorCode)
                }
            }
        } catch (err) {
            showErrorModal(errorDescription(error.unexpectedError));
        }
    };

    let deactivatePrompt = async (id) => {
        try {
            let response = await deactivateSystemPrompt(id);
            if (response.status === systemPromptAPIStatus.deactivated) {
                showSuccessModal(systemPromptResponses.deactivatedSuccessfully);
            } else {
                if (response.errorCode) {
                    showErrorModal(response.errorCode)
                }
            }
        } catch (err) {
            showErrorModal(errorDescription(error.unexpectedError));
        }
    };

    let setConfirmationModalState = (event, row) => {
        const activePrompts = systemPromptsData.filter(
            prompt =>
                prompt.clientID === row.clientID &&
                prompt.personaName === row.personaName &&
                prompt.personaTrigger === row.personaTrigger &&
                prompt.llmProvider === row.llmProvider &&
                prompt.deactivatedAt === null
        );
        if (event === allActions.deactivate && activePrompts.length === 1) {
            setTempRow(row);
            setWarningModalIsOpen(true);
        } else {
            setAction(event);
            setConfirmationModalIsOpen(true);
            setId(row.id);
        }
    };

    let proceedWithDeactivation = () => {
        setWarningModalIsOpen(false);
        setAction(allActions.deactivate);
        setConfirmationModalIsOpen(true);
        setId(tempRow.id);
    };

    let handleStatusChange = (status) => {
        setStatus({ value: status.value, label: status.label });
    };
    let handleClientChange = (clientId) => {
        setClientId({ value: clientId.value, label: clientId.label });
    };
    let handleLLMProviderChange = (llmProvider) => {
        setLLMProvider({ value: llmProvider.value, label: llmProvider.label });
    };
    let handlePersonaNameChange = (personaName) => {
        setPersonaName({ value: personaName.value, label: personaName.label });
    };
    let handlePersonaTriggerChange = (personaTrigger) => {
        setPersonaTrigger({ value: personaTrigger.value, label: personaTrigger.label });
    };
    let handleTypeChange = (type) => {
        setType({ value: type.value, label: type.label });
    };

    let handleSearchChange = (e) => {
        setSearchValue(e.target.value)
    }

    const filterSystemPrompts = () => {
        let items = systemPromptsData;
        if (searchValue) {
            let search = searchValue.toLowerCase();
            items = items.filter(prompt =>
                prompt.id?.toString()?.toLowerCase()?.includes(search) ||
                prompt.description?.toLowerCase()?.includes(search) ||
                prompt.version?.toString()?.toLowerCase()?.includes(search) ||
                prompt.clientID?.toLowerCase()?.includes(search) ||
                prompt.llmProvider?.toLowerCase()?.includes(search) ||
                prompt.personaName?.toLowerCase()?.includes(search) ||
                prompt.personaTrigger?.toLowerCase()?.includes(search) ||
                prompt.type?.toLowerCase()?.includes(search) ||
                prompt.value?.toLowerCase()?.includes(search)
            )
        };
        return items;
    }

    const handleClick = () => {
        setStatus({ value: "all", label: "All" });
        setClientId({ value: "", label: "Select Client" });
        setLLMProvider({ value: "", label: "Select LLM Provider" });
        setPersonaName({ value: "", label: "Select Persona" });
        setPersonaTrigger({ value: "", label: "Select Trigger" });
        setType({ value: "production", label: "Production" });
    };

    const ClearButton = () => {
        return (
            <Button
                style={{ float: "right" }}
                className="form-control-lg"
                onClick={handleClick}
            >
                Reset Filters
            </Button>
        );
    };

    const expandRow = {
        onlyOneExpanding: true,
        renderer: (row) => (
            <div>
                <ul>
                    <li><b>Client : </b>{clientOptions?.find(option => option.value === row.clientID)?.label || "N/A"}</li>
                    <li><b>Persona Name : </b>{row.personaName ? row.personaName : "N/A"}</li>
                    <li><b>Persona Trigger : </b>{row.personaTrigger ? row.personaTrigger : "N/A"}</li>
                    <li><b>LLM Provider : </b>{row.llmProvider ? row.llmProvider : "N/A"}</li>
                    <li><b>Value: </b>
                        <Input
                            type="textarea"
                            name="text"
                            placeholder="Enter Value"
                            value={row.value ? row.value : "N/A"}
                            rows="10"
                            disabled={true}
                            required
                            style={{ backgroundColor: 'white' }}
                        />
                    </li>
                    <li><b>Created At : </b>{formatTime(row.createdAt)}</li>
                    <li><b>Updated At : </b>{formatTime(row.updatedAt)}</li>
                    <li><b>Deactivated At : </b>{formatTime(row.deactivatedAt)}</li>
                </ul>
                {showDeactivateSystemPromptButton() ? (
                    <Button
                        className="mx-3 btn-danger"
                        disabled={disableDeactivateButton(row)}
                        onClick={(event) =>
                            setConfirmationModalState(event.target.innerHTML, row)
                        }
                    >
                        Deactivate
                    </Button>
                ) : null}
                {
                    showActivateSystemPromptButton() ? (
                        <Button
                            className="mx-3 btn-success"
                            disabled={disableActivateButton(row)}
                            onClick={(event) =>
                                setConfirmationModalState(event.target.innerHTML, row)
                            }
                        >
                            Activate
                        </Button>
                    ) : null
                }
            </div >
        ),
    };

    return (
        <Card>
            <CardBody>
                <Row>
                    <Col md={3} sm={12}>
                        <Label>Status</Label>
                        <Select
                            className="react-select-container mb-4"
                            classNamePrefix="react-select"
                            placeholder="Select Status"
                            onChange={handleStatusChange}
                            options={statusOptions}
                            value={status}
                        />
                    </Col>
                    <Col md={3} sm={12}>
                        <Label>Client</Label>
                        <Select
                            className="react-select-container mb-4"
                            classNamePrefix="react-select"
                            placeholder={"Select Client"}
                            onChange={handleClientChange}
                            options={clientOptions}
                            value={clientId}
                        />
                    </Col>
                    <Col md={3} sm={12}>
                        <Label>LLM Provider</Label>
                        <Select
                            className="react-select-container mb-4"
                            classNamePrefix="react-select"
                            placeholder="Select LLM Provider"
                            onChange={handleLLMProviderChange}
                            options={llmProviderOptions}
                            value={llmProvider}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={3} sm={12}>
                        <Label>Persona Name</Label>
                        <Select
                            className="react-select-container mb-4"
                            classNamePrefix="react-select"
                            placeholder="Select Persona"
                            onChange={handlePersonaNameChange}
                            options={personaNameOptions}
                            value={personaName}
                        />
                    </Col>
                    <Col md={3} sm={12}>
                        <Label>Persona Trigger</Label>
                        <Select
                            className="react-select-container mb-4"
                            classNamePrefix="react-select"
                            placeholder="Select Trigger"
                            onChange={handlePersonaTriggerChange}
                            options={personaTriggerOptions}
                            value={personaTrigger}
                        />
                    </Col>
                    <Col md={3} sm={12}>
                        <Label>Type</Label>
                        <Select
                            className="react-select-container mb-4"
                            classNamePrefix="react-select"
                            placeholder="Select Status"
                            onChange={handleTypeChange}
                            options={typeOptions}
                            value={type}
                        />
                    </Col>
                    <Col md={3} sm={12} className="d-flex justify-content-md-start justify-content-center mt-3 mt-md-4">
                        <ClearButton className="w-100 w-md-auto text-center">Reset Filters</ClearButton>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col xs={12}>
                        <Search
                            placeholder="Search"
                            value={searchValue}
                            onChange={handleSearchChange}
                        ></Search>
                    </Col>
                </Row>
                <CardBody>
                    <BootstrapTable
                        bootstrap4={true}
                        wrapperClasses="table-responsive"
                        hover={true}
                        bordered={false}
                        keyField="id"
                        data={filterSystemPrompts()}
                        columns={systemPromptsTableColumns}
                        expandRow={expandRow}
                        filter={filterFactory()}
                        pagination={paginationFactory({
                            sizePerPage: 10,
                            sizePerPageList: [5, 10, 25, 50],
                        })}
                        defaultSorted={[{
                            dataField: 'id',
                            order: 'desc'
                        }]}
                        onTableChange={onTableChange}
                        remote={{ filter: true }}
                        noDataIndication="No results found"
                    />
                </CardBody>
            </CardBody>
            <ResponseModal
                show={successModalDisplay}
                onHide={successModalClose}
                modalheading={successModalHeading}
                modaltext={successModalText}
            />
            <ResponseModal
                show={failureModalDisplay}
                onHide={failureModalClose}
                modalheading={failureModalHeading}
                modaltext={failureModalText}
            />
            <ConfirmationModal
                show={confirmationModalIsOpen}
                modaltext={action}
                onConfirmationTrue={() => {
                    setConfirmationModalIsOpen(false);
                    if (action === allActions.deactivate) deactivatePrompt(id);
                    else if (action === allActions.activate) activatePrompt(id);
                }}
                onHide={() => setConfirmationModalIsOpen(false)}
            />
            <WarningConfirmationModal
                show={warningModalIsOpen}
                modaltext={`You are trying to deactivate the last active system prompt for Client ID: ${tempRow?.clientID}, Persona Name: ${tempRow?.personaName}, Persona Trigger: ${tempRow?.personaTrigger} andLLM Provider: ${tempRow?.llmProvider} with Type: ${tempRow?.type}. Are you sure you want to proceed?`}
                onConfirmationTrue={proceedWithDeactivation}
                onHide={() => setWarningModalIsOpen(false)}
            />
        </Card>
    );
};

const SystemPrompts = () => {
    return (
        <Container fluid className="p-0">
            <div className="d-flex justify-content-between">
                <h1 className="h3">System Prompts</h1>
                {showCreateSystemPromptButton() ? (
                    <div>
                        <Link
                            to={{
                                pathname: `/chat-assistant/system-prompts/create`
                            }}
                        >
                            <Button
                                style={{ fontSize: "1rem" }}
                                className="mx-3 btn-success mb-3"
                            >
                                Create a New System Prompt
                            </Button>
                        </Link>
                    </div>
                ) : null}
            </div>
            <ExpandableRowsTable />
        </Container>
    )
}
export default SystemPrompts;
import React, { useEffect, useState } from 'react'
import Reorder, { reorder } from "react-reorder";
import Select from "react-select";
import { HiOutlinePlus } from "react-icons/hi";
import { IoSend } from 'react-icons/io5';
import { FaInfoCircle } from 'react-icons/fa';
import { Container, Row, Col, Card, CardBody, CardImg, FormGroup, Label, CustomInput, Collapse, InputGroup, Input } from "reactstrap";
import { VscTriangleDown, VscTriangleUp } from "react-icons/vsc";
import { Button } from "react-bootstrap";
import { Drawer, message, Tooltip } from 'antd';
import { fetchAllAdsList, fetchFixedPositionsPromotedNonPromotedAds, fetchProvidersByClientIdLocation, fetchPlacementWithLocationClientId, fetchSmartSuggestionCountries, resetMetadataOverride, resetStoryAd, updateFixedPosition, updateMetadataOverride, updatePromoteAd, updatePromotedAdsOrder, updateStoryAd, fetchPublicAdsAPI } from '../../../utilities/apiUtils/smartSuggestions';
import Loading from "../../../components/Loading";
import ResponseModal from '../ResponseModal';
import { successModalHeading } from '../../../config';
import { errorModalHeading } from '../../../config/config';
import SmartSuggestionsMetadataOverrideModal from './SmartSuggestionsMetadataOverrideModal';
import Search from "../../../components/Search";
import { clientOptions, getClientId, metadataOverrideAllActions, modifiedOptions, showFixedPositionsUpdateButton, showPromotedAdsOrderUpdateButton, storyAdsOptions, statusMetaOverrideOptions, processDateAndStatus, isPartnerAd, regionOptions, updateMetrics, adServerVersionOptions } from '../../../config/configSmartSuggestions';
import { error, errorDescription, generateColor, replaceUnderscoreAndCapitalize } from '../../../utilities/commonUtil';
import SmartSuggestionsFixedPositionModal from './SmartSuggestionsFixedPositionModal';
import ConfirmationModal from '../ConfirmationModal';
import ManageStoryAdsModal from './ManageStoryAdsModal';
import ToolTip from '../../../utilities/ToolTip';
import { fetchPlacementLocations } from '../../../utilities/apiUtils/placementLocations';
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import { providerTableColumns } from "./tableColumn";
import RightSidebar from '../../../components/RightSidebar';

const filtersInitalState = {
  client: clientOptions[0].target.value,
  location: { label: "Launcher", value: "launcher" },
  partner: [],
  status: "",
  country: "",
  region: { label: "GLOBAL", value: "GLOBAL" },
  modified: modifiedOptions[0].target.value,
  storyAds: storyAdsOptions[0].target.value,
  adServerVersion: {
    value: "7",
    label: "7"
  },
  advertisingId: "fff7cd16-d2ed-4007-8b98-cfacbd0d77bf"
}

const SmartSuggestionsMetadataOverride = () => {
  const [loading, setLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [action, setAction] = useState("");
  const [successModalText, setSuccessModalText] = useState("");
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [isMetadataOverrideModalVisible, setIsMetadataOverrideModalVisible] = useState(false)
  const [isFixedPositionModalVisible, setIsFixedPositionModalVisible] = useState(false)
  const [isManageStoryAdsModalVisible, setIsManageStoryAdsModalVisible] = useState(false)
  const [selectedFixedPosition, setSelectedFixedPosition] = useState("")
  const [fixedPositionType, setFixedPositionType] = useState("")
  const [isExtraFixedPosition, setIsExtraFixedPosition] = useState(false)
  const [searchFilter, setSearchFilter] = useState("");
  const [isReorderEnabled, setIsReorderEnabled] = useState(false)
  const [partnerFilterOptions, setPartnerFilterOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [providerAdsData, setProviderAdsData] = useState([]);
  const [overallAds, setOverallAds] = useState([])

  const [ads, setAds] = useState({
    fixedPositions: [],
    extraFixedPositions: [],
    promotedAds: [],
    nonPromotedAds: []
  })
  const [valuesBeforeUpdate, setValuesBeforeUpdate] = useState({
    fixedPositions: [],
    promotedAds: [],
    nonPromotedAds: []
  })
  const [countryOptions, setCountryOptions] = useState([])
  const [locationOptions, setLocationOptions] = useState([])
  const [modalAdDetails, setModalAdDetails] = useState(null)
  const [fixedPositionData, setFixedPositionData] = useState(null)
  const [storyAdDetails, setStoryAdDetails] = useState(null)
  const [allAdsOptions, setAllAdsOptions] = useState([])
  const [placement, setPlacement] = useState(null)
  const [placementProvider, setPlacementProvider] = useState(null)

  const [filters, setFilters] = useState(filtersInitalState)

  useEffect(() => {
    setLoading(true)
    Promise.all([
      getCountries(),
      getLocationsOptions()
    ]).finally(() => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (filters.client.value && filters.location.value && filters.country.value && filters.region.value) {
      getAllAds(getClientId(filters.client.value), filters.location.value, filters.country.value, filters.adServerVersion.value, filters.region.value, filters.advertisingId)
      getAllAdsList(getClientId(filters.client.value), filters.location.value, filters.country.value, filters.adServerVersion.value, filters.region.value, filters.advertisingId)
    }
  }, [filters.client, filters.location, filters.country, filters.adServerVersion, filters.region])

  useEffect(() => {
    if (filters.country.value && filters.region.value && placement?.pid) {
      getPublicAdsAPI(getClientId(filters.client.value), filters.country.value, filters.region.value, filters.adServerVersion.value, placement?.pid, filters.advertisingId)
    }
  }, [filters.country, filters.adServerVersion, filters.region, placement])

  useEffect(() => {
    if (filters.client.value && filters.location.value) {
      getPlacement(filters.location.value, getClientId(filters.client.value))
      getPlacementProviders(filters.location.value, getClientId(filters.client.value))
    }
  }, [filters.client, filters.location])

  useEffect(() => {
    const data = getProviderAdsMetrics(placementProvider, filterAds("promotedAds"), filterAds("nonPromotedAds"), filterAds("fixedPositions"), filterAds("extraFixedPositions"), overallAds);
    setProviderAdsData(data)
  }, [placementProvider, ads, filters.status, filters.modified, overallAds])

  const getCountries = async () => {
    try {
      let response = await fetchSmartSuggestionCountries()
      if (response?.length > 0) {
        const countries = []
        response.forEach((country) => {
          countries.push({
            value: country.iso_code,
            label: country.name,
            target: {
              name: "country",
              value: {
                value: country.iso_code,
                label: country.name
              }
            }
          })
        })
        setCountryOptions(countries)
        setFilters((prevState) => ({
          ...prevState,
          country: countries.find(country => country.value === "IN").target.value
        }))
      }
    } catch (err) {
      console.log(err);
    }
  }

  const getLocationsOptions = async () => {
    try {
      let response = await fetchPlacementLocations();
      if (response?.length > 0) {
        setLocationOptions(response.map(location => ({
          value: location.value,
          label: location.name,
          target: {
            name: "location",
            value: {
              value: location.value,
              label: location.name
            }
          }
        })))
      } else {
        setLocationOptions([])
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllAds = async (clientId, location, countryCode, adServerVersion, regionCode, advertisingId) => {
    try {
      setLoading(true)
      let response = await fetchFixedPositionsPromotedNonPromotedAds(clientId, location, countryCode, adServerVersion, regionCode, advertisingId)
      setValuesBeforeUpdate(response)
      if (response?.fixedPositions?.length > 0) {
        response.fixedPositions.forEach(fixedPosition => {
          if (fixedPosition.fixedPositionAds?.length > 0) {
            fixedPosition.fixedPositionAds.forEach((item) => {
              item = processDateAndStatus(item);
            });
          }
        })
        setAds((prevState) => ({
          ...prevState,
          fixedPositions: response?.fixedPositions?.filter(el => el.position <= 5),
          extraFixedPositions: response?.fixedPositions?.filter(el => el.position > 5)
        }))
      } else {
        setAds((prevState) => ({
          ...prevState,
          fixedPositions: [],
          extraFixedPositions: []
        }))
      }
      if (response?.promotedAds?.length > 0) {
        response.promotedAds.forEach((item) => {
          item = processDateAndStatus(item);
        });
        setAds((prevState) => ({
          ...prevState,
          promotedAds: response?.promotedAds
        }))
      } else {
        setAds((prevState) => ({
          ...prevState,
          promotedAds: []
        }))
      }
      if (response?.nonPromotedAds?.length > 0) {
        response.nonPromotedAds.forEach((item) => {
          item = processDateAndStatus(item);
        });
        setAds((prevState) => ({
          ...prevState,
          nonPromotedAds: response?.nonPromotedAds
        }))
      } else {
        setAds((prevState) => ({
          ...prevState,
          nonPromotedAds: []
        }))
      }
      if (response?.errors?.length > 0) {
        response.errors.forEach((err) => {
          message.error(err)
        })
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false)
    }
  }

  const getAllAdsList = async (clientId, location, countryCode, adServerVersion, regionCode, advertisingId) => {
    try {
      let response = await fetchAllAdsList(clientId, location, countryCode, adServerVersion, regionCode, advertisingId)
      if (response?.ads?.length > 0) {
        setAllAdsOptions(response.ads)
        const uniquePartners = Array.from(
          new Set(
            response?.ads
              ?.map(item => item?.provider)
              .map(provider => replaceUnderscoreAndCapitalize(provider.replace(/(_cpc|_cpi|_+)+$/, '')))
              .filter(provider => provider)
          )
        ).map(value => ({
          value,
          label: value
        }));

        setPartnerFilterOptions(uniquePartners);
      } else {
        setAllAdsOptions([])
      }
    } catch (err) {
      console.log(err);
    }
  }

  const getPublicAdsAPI = async (clientId, countryCode, regionCode, adServerVersion, placementId, advertisingId) => {
    try {
      let response = await fetchPublicAdsAPI(clientId, countryCode, regionCode, adServerVersion, placementId, advertisingId)
      if (response?.ads?.length > 0) setOverallAds(response?.ads)
      else setOverallAds([])
    } catch (err) {
      console.log(err);
    }
  }

  const getPlacement = async (location, clientId) => {
    try {
      let response = await fetchPlacementWithLocationClientId(location, clientId)
      setPlacement(response)
    } catch (err) {
      console.log(err);
    }
  }

  const getPlacementProviders = async (location, clientId) => {
    try {
      let response = await fetchProvidersByClientIdLocation(clientId, location)
      if (response?.length > 0) setPlacementProvider(response)
      else setPlacementProvider([])
    } catch (err) {
      console.log(err);
    }
  }

  const isRegionDisabled = () => filters.country?.value !== "IN";

  const toggleCollapse = () => setIsOpen(!isOpen);
  let onTableChange = (type, newState) => { };

  let hideMetadataOverrideModal = () => {
    setIsMetadataOverrideModalVisible(false)
    setModalAdDetails(null)
  }

  let hideFixedPositionModal = () => {
    setIsFixedPositionModalVisible(false)
    setIsExtraFixedPosition(false)
    setFixedPositionData(null)
  }

  let hideManageStoryAdsModal = () => {
    setIsManageStoryAdsModalVisible(false)
    setStoryAdDetails(null)
  }

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    if (isReorderEnabled)
      setIsReorderEnabled(false)
    getAllAds(getClientId(filters.client?.value), filters.location?.value, filters.country?.value, filters.adServerVersion?.value, filters.region?.value, filters.advertisingId)
    getAllAdsList(getClientId(filters.client?.value), filters.location?.value, filters.country?.value, filters.adServerVersion?.value, filters.region?.value, filters.advertisingId)
  };

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  let setConfirmationModalState = (event) => {
    setAction(event);
    setConfirmationModalIsOpen(true);
  };

  const handleOpenSidebar = () => {
    setIsSidebarOpen(true)
  }

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false)
  }

  const applyClientSideFilters = (items = []) => {
    if (filters.modified?.value)
      items = items.filter((item) => filters.modified.value === "all"
        ? true
        : filters.modified.value === "modified"
          ? item.isModified
          : !item.isModified
      );

    if (filters.partner?.length)
      items = items.filter((item) =>
        filters.partner.some(partner => replaceUnderscoreAndCapitalize(item.provider ?? '').includes(partner.value))
      );

    if (filters.status?.value)
      items = items.filter((item) => item.status === filters.status.value);

    if (filters.storyAds?.value)
      items = items.filter((item) => filters.storyAds.value === "all"
        ? true
        : filters.storyAds.value === "storyAds"
          ? item.storyAd?.id
          : !item.storyAd?.id
      );

    if (searchFilter)
      items = items.filter((item) =>
        item.id?.toString().includes(searchFilter.toLowerCase()) || item.title?.toLowerCase().includes(searchFilter.toLowerCase())
      );

    return items
  }

  const filterAds = (type) => {
    let items = ads[type]

    if (type === "fixedPositions" || type === "extraFixedPositions") {
      items = items.map(fixedPosition => ({
        ...fixedPosition,
        fixedPositionAds: applyClientSideFilters(fixedPosition.fixedPositionAds ?? [])
      }))
      return items
    }

    if (!(isReorderEnabled && type === "promotedAds")) {
      items = applyClientSideFilters(items ?? [])
    }
    return items
  }

  const handleReorder = (event, previousIndex, nextIndex, fromId, toId) => {
    // Deep cloning promoted ads to avoid same ref
    let updatedPromotedAdsOrder = reorder(JSON.parse(JSON.stringify(ads.promotedAds)), previousIndex, nextIndex)
    updatedPromotedAdsOrder.forEach((el, index) => {
      el.priority = ads.promotedAds[index].priority
    })
    setAds((prevState) => ({
      ...prevState,
      promotedAds: updatedPromotedAdsOrder
    }))
  };

  const handleGaidSubmit = () => {
    let advertisingId = filters.advertisingId
    if (!advertisingId) {
      setFilters((prevState) => ({
        ...prevState,
        advertisingId: filtersInitalState.advertisingId
      }))
      advertisingId = filtersInitalState.advertisingId
    }
    getAllAds(getClientId(filters.client?.value), filters.location?.value, filters.country?.value, filters.adServerVersion?.value, filters.region?.value, advertisingId)
    getAllAdsList(getClientId(filters.client?.value), filters.location?.value, filters.country?.value, filters.adServerVersion?.value, filters.region?.value, advertisingId)
    getPublicAdsAPI(getClientId(filters.client?.value), filters.country?.value, filters.region?.value, filters.adServerVersion?.value, placement?.pid, advertisingId)
  }

  const handleAdClick = (adDetails, isPromoted) => {
    setModalAdDetails({ ...adDetails, isPromoted })
    setIsMetadataOverrideModalVisible(true)
  }

  const handleManageStoryAdClick = (adDetails, isPromoted) => {
    setStoryAdDetails({ ...adDetails, isPromoted })
    setIsManageStoryAdsModalVisible(true)
  }

  const handleFixedPositionClick = (position, data, isExtraFixedPosition = false) => {
    setSelectedFixedPosition(position)
    setIsExtraFixedPosition(isExtraFixedPosition)
    if (data) {
      setFixedPositionType(data?.fixedPositionProviders?.length > 0 ? "provider" : "ad")
      setFixedPositionData(data)
    } else {
      setFixedPositionType("")
      setFixedPositionData(null)
    }
    setIsFixedPositionModalVisible(true)
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setFilters((prevState) => ({
      ...prevState,
      [name]: value
    }))

    if (name === "country") {
      setFilters((prevState) => ({
        ...prevState,
        region: { label: "GLOBAL", value: "GLOBAL" },
      }))
    }
  }

  const handlePartnerChange = (selectedOptions) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      partner: selectedOptions ? selectedOptions : [],
    }));
  };

  const handleStatusChange = (selectedOptions) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      status: selectedOptions,
    }));
  };

  const handleUpdatePromotedAds = async () => {
    const oldPromotedAds = valuesBeforeUpdate.promotedAds?.map(ad => ({
      adId: ad.id,
      title: ad.title,
      priority: ad.priority
    }))
    const updatedPromotedAds = ads.promotedAds?.map(ad => ({
      adId: ad.id,
      title: ad.title,
      priority: ad.priority
    }))
    const payload = {
      oldPromotedAds,
      updatedPromotedAds,
      location: filters.location.value,
      clientId: getClientId(filters.client?.value)
    }
    setLoading(true)
    try {
      let response = await updatePromotedAdsOrder(payload)
      if (response?.status === "success") {
        showSuccessModal("Promoted ads order has been updated")
      } else {
        let text
        if (response?.errorDescription)
          text = response?.errorDescription?.split("):")[1];
        else
          text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      }
    } catch (err) {
      let text = errorDescription(error.unexpectedError);
      showErrorModal(text);
    } finally {
      setLoading(false)
    }
  }

  const handleUpdateMetadata = async (data, isPromoted) => {
    let oldAdMetadata
    if (isPromoted)
      oldAdMetadata = valuesBeforeUpdate.promotedAds?.find(ad => ad?.id === data?.id)
    else
      oldAdMetadata = valuesBeforeUpdate.nonPromotedAds?.find(ad => ad?.id === data?.id)
    setLoading(true)
    try {
      let response = await updateMetadataOverride({ oldAdMetadata, updatedAdMetadata: data })
      if (response?.status === "success") {
        showSuccessModal("Metadata has been updated")
      } else {
        let text
        if (response?.errorDescription)
          text = response?.errorDescription?.split("):")[1];
        else
          text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      }
    } catch (err) {
      let text = errorDescription(error.unexpectedError);
      showErrorModal(text);
    } finally {
      setLoading(false)
    }
  }

  const handleUpdateFixedPosition = async (data, positionBeforeUpdate) => {
    const updatedFixedPosition = {
      ...data,
      location: filters.location.value,
      clientId: getClientId(filters.client?.value)
    }
    const fixedPositionBeforeUpdate = valuesBeforeUpdate.fixedPositions?.find(fixedPosition => fixedPosition.position === positionBeforeUpdate) ?? {
      fixedPositionAds: [],
      fixedPositionProviders: [],
      position: data.position
    }
    const oldFixedPosition = {
      ...fixedPositionBeforeUpdate,
      location: filters.location.value,
      clientId: getClientId(filters.client?.value),
      isAppliedToAllPlacementIds: false
    }
    setLoading(true)
    try {
      let response = await updateFixedPosition({ updatedFixedPosition, oldFixedPosition })
      if (response?.status === "success") {
        showSuccessModal("Fixed position has been updated")
      } else {
        let text
        if (response?.errorDescription)
          text = response?.errorDescription?.split("):")[1];
        else
          text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      }
    } catch (err) {
      let text = errorDescription(error.unexpectedError);
      showErrorModal(text);
    } finally {
      setLoading(false)
    }
  }

  const handleManageStoryAd = async (data) => {
    setLoading(true)
    try {
      let response = await updateStoryAd({ ...data, placementId: placement?.pid })
      if (response?.status === "success") {
        showSuccessModal("Story ad has been updated")
      } else {
        let text
        if (response?.errorDescription)
          text = response?.errorDescription?.split("):")[1];
        else
          text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      }
    } catch (err) {
      let text = errorDescription(error.unexpectedError);
      showErrorModal(text);
    } finally {
      setLoading(false)
    }
  }

  const handlePromoteAd = async (data) => {
    const payload = {
      ...data,
      location: filters.location.value,
      clientId: getClientId(filters.client?.value)
    }
    setLoading(true)
    try {
      let response = await updatePromoteAd(payload)
      if (response?.status === "success") {
        if (data.isPromoted) showSuccessModal("Ad has been set as promoted")
        else showSuccessModal("Ad has been set as non-promoted")
      } else {
        let text
        if (response?.errorDescription)
          text = response?.errorDescription?.split("):")[1];
        else
          text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      }
    } catch (err) {
      let text = errorDescription(error.unexpectedError);
      showErrorModal(text);
    } finally {
      setLoading(false)
    }
  }

  const handleResetOverride = async (adid, title, isPromoted) => {
    let adMetadata
    if (isPromoted)
      adMetadata = valuesBeforeUpdate.promotedAds?.find(ad => ad?.id === adid)
    else
      adMetadata = valuesBeforeUpdate.nonPromotedAds?.find(ad => ad?.id === adid)
    setLoading(true)
    try {
      let response = await resetMetadataOverride(adid, title, adMetadata)
      if (response?.status === "success") {
        showSuccessModal("Metadata override has been deleted")
      } else {
        let text
        if (response?.errorDescription)
          text = response?.errorDescription?.split("):")[1];
        else
          text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      }
    } catch (err) {
      let text = errorDescription(error.unexpectedError);
      showErrorModal(text);
    } finally {
      setLoading(false)
    }
  }

  const handleResetStoryAd = async (adid, title, isPromoted) => {
    let storyAd
    if (isPromoted)
      storyAd = valuesBeforeUpdate.promotedAds?.find(ad => ad?.id === adid).storyAd
    else
      storyAd = valuesBeforeUpdate.nonPromotedAds?.find(ad => ad?.id === adid).storyAd
    setLoading(true)
    try {
      let response = await resetStoryAd(adid, title, storyAd)
      if (response?.status === "success") {
        showSuccessModal("Story ad has been deleted")
      } else {
        let text
        if (response?.errorDescription)
          text = response?.errorDescription?.split("):")[1];
        else
          text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      }
    } catch (err) {
      let text = errorDescription(error.unexpectedError);
      showErrorModal(text);
    } finally {
      setLoading(false)
    }
  }

  const handleManageStoryAdSwitch = () => {
    setIsMetadataOverrideModalVisible(false)
    handleManageStoryAdClick(modalAdDetails, modalAdDetails.isPromoted)
  }

  const handleMetadataOverrideSwitch = () => {
    setIsManageStoryAdsModalVisible(false)
    handleAdClick(storyAdDetails, storyAdDetails.isPromoted)
  }

  const getProviderAdsMetrics = (providers, promotedAdsList, nonPromotedAdsList, fixedPositions, extraFixedPositions, overallAdsList) => {
    const providerFreq = { internal: { cpi: 0, cpc: 0, cpm: 0, total: 0 }, overall: { cpi: 0, cpc: 0, cpm: 0, total: 0 } };

    const adsList = [
      ...promotedAdsList,
      ...nonPromotedAdsList,
      ...fixedPositions.flatMap(ad => (ad.fixedPositionAds || []).filter(ad => ad != null)),
      ...extraFixedPositions.flatMap(ad => (ad.fixedPositionAds || []).filter(ad => ad != null))
    ];

    adsList.forEach(ad => {
      if (ad) {
        const provider = ad?.provider;
        const baseProvider = provider?.replace(/_cpi|_cpc|_cpm/, '');

        // Handle internal ads (non-partner ads)
        if (!isPartnerAd(ad?.id)) {
          providerFreq.internal = updateMetrics(providerFreq.internal, provider);
        } else {
          providerFreq[baseProvider] = providerFreq[baseProvider] || { cpi: 0, cpc: 0, cpm: 0, total: 0 };
          providerFreq[baseProvider] = updateMetrics(providerFreq[baseProvider], provider);
        }
      }
    });

    overallAdsList.forEach(ad => {
      const provider = ad?.provider;
      providerFreq.overall = updateMetrics(providerFreq.overall, provider);
    })

    return Object.keys(providerFreq).map(provider => ({
      provider: provider === 'overall' ? 'Overall (Public API)' : replaceUnderscoreAndCapitalize(provider),
      cpi: providerFreq[provider].cpi,
      cpc: providerFreq[provider].cpc,
      cpm: providerFreq[provider].cpm,
      total: providerFreq[provider].total
    }));
  };

  return (
    <div className='d-flex'>
      <Container fluid className="p-0">
        <Row className="align-items-center pt-3" style={{ position: "sticky", top: 0, backgroundColor: "#f5f9fc", zIndex: 2, borderBottom: "1px solid #dce0e2" }}>
          <h1 className="pl-3 mb-0">Manage Smart Suggestions</h1>
          {!isSidebarOpen && (
            <Button
              className="ml-auto mr-2 sidebar-toggle sidebar-btn"
              onClick={handleOpenSidebar}
            >
              <i className="hamburger" />
            </Button>
          )}
          <Col md={12}>
            <Row>
              <Col md={8} className="search-container mt-3">
                <Search
                  placeholder="Search ad name or ID"
                  value={searchFilter}
                  onChange={(e) => {
                    setSearchFilter(e.target.value)
                  }}
                ></Search>
              </Col>
            </Row>
          </Col>
        </Row>
        <Drawer
          width={350}
          open={isSidebarOpen}
          onClose={handleCloseSidebar}
          mask={false}
          maskClosable={false}
          closeIcon={
            <Button
              className='sidebar-toggle sidebar-btn'
            >
              <i className="hamburger" />
            </Button>
          }
        >
          <Row form>
            <Col md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Client
                </Label>
                <Select
                  id="client"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="client"
                  placeholder="Select Client"
                  value={filters.client}
                  onChange={handleChange}
                  options={clientOptions}
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Placement
                </Label>
                <Select
                  id="location"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="location"
                  placeholder="Select Location"
                  value={filters.location}
                  onChange={handleChange}
                  options={locationOptions}
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Country
                </Label>
                <Select
                  id="country"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="country"
                  placeholder="Select Country"
                  value={filters.country}
                  onChange={handleChange}
                  options={countryOptions}
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Region
                </Label>
                <Select
                  id="region"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="region"
                  placeholder="Select Region"
                  value={filters.region}
                  onChange={handleChange}
                  options={regionOptions}
                  isDisabled={isRegionDisabled()}
                />
              </FormGroup>
            </Col>
          </Row>
          <hr style={{ marginTop: 0 }} />
          <Row form>
            <Col md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Partner
                </Label>
                <Select
                  id="partner"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="partner"
                  placeholder="Select Partner"
                  value={filters.partner}
                  onChange={handlePartnerChange}
                  options={partnerFilterOptions}
                  isMulti
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Status
                </Label>
                <Select
                  id="status"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="status"
                  placeholder="Select Status"
                  value={filters.status}
                  onChange={handleStatusChange}
                  options={statusMetaOverrideOptions}
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Modified
                </Label>
                <Select
                  id="modified"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="modified"
                  placeholder="Select Modified"
                  value={filters.modified}
                  onChange={handleChange}
                  options={modifiedOptions}
                />
              </FormGroup>
            </Col>
            {placement?.isSupportedStoryAds && (
              <Col md={12}>
                <FormGroup>
                  <Label
                    className="font-weight-bolder mb-2"
                    style={{ fontSize: "14px" }}
                  >
                    Story Ads
                  </Label>
                  <Select
                    id="storyAds"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name="storyAds"
                    placeholder="Select Story Ads"
                    value={filters.storyAds}
                    onChange={handleChange}
                    options={storyAdsOptions}
                  />
                </FormGroup>
              </Col>
            )}
            <Col md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Ad Server Version
                </Label>
                <Select
                  id="adServerVersion"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="adServerVersion"
                  placeholder="Select Ad Server Version"
                  value={filters.adServerVersion}
                  onChange={handleChange}
                  options={adServerVersionOptions}
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <ul className='pl-3' style={{ listStyleType: "none" }}>
                <li className='text-info'>4 - Products Card</li>
                <li className='text-info'>5 - Deeplink Support</li>
                <li className='text-info'>6 - Affinity Affiliate Ads</li>
                <li className='text-info'>7 - Products card adaptability: 4:3, 16:9</li>
              </ul>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Advertising ID (GAID){" "}
                  <Tooltip title="Empty the field to pass the default advertising id">
                    <FaInfoCircle />
                  </Tooltip>
                </Label>
                <InputGroup>
                  <Input
                    type="text"
                    name="advertisingId"
                    placeholder="Enter Advertising ID (GAID)"
                    value={filters.advertisingId}
                    onChange={handleChange}
                  />
                  <Button
                    style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                    onClick={handleGaidSubmit}
                  >
                    <IoSend />
                  </Button>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </Drawer>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <div>
              <h4 className='mt-3'>
                Provider Ads Metrics {" "}
                <ToolTip
                  data="Shows the number of ads by provider in dashboard and for overall metrics, based on ads served via the public API"
                  id="providerAdsMetricsTooltip"
                />
                <button
                  className="btn btn-link customToggleBtn"
                  onClick={toggleCollapse}
                >
                  {isOpen ? <VscTriangleUp /> : <VscTriangleDown />}
                </button>
              </h4>
              <Collapse isOpen={isOpen}>
                <Card>
                  <CardBody>
                    <BootstrapTable
                      bootstrap4={true}
                      wrapperClasses="table-responsive"
                      hover={true}
                      bordered={true}
                      keyField="provider"
                      data={providerAdsData}
                      columns={providerTableColumns}
                      filter={filterFactory()}
                      defaultSorted={[{
                        dataField: 'total',
                        order: 'asc'
                      }]}
                      onTableChange={onTableChange}
                      remote={{ filter: true }}
                      noDataIndication="No results found"
                    />
                  </CardBody>
                </Card>
              </Collapse>
            </div>

            <hr style={{ marginTop: 0 }} />

            <span className="text-warning font-weight-bold" style={{ fontSize: 14 }}>
              <span role="img" aria-label="warning-icon">⚠️</span> The below displayed ads order may not be final due to potential variations in IP addresses and GAIDs across different clients. <br />
              Additionally, there are duplicate app package name ads present in the listed ads.
            </span>
            <h4 className='mt-3'>Top Fixed Positions</h4>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {[...Array(5).keys()].map(el => {
                      const fixedPosition = filterAds("fixedPositions").find(fixedPosition => fixedPosition.position === (el + 1))
                      if (fixedPosition) {
                        let bgProvider = "", colorProvider = "", bgAd = "", colorAd = ""
                        if (fixedPosition.fixedPositionProviders?.length > 0) {
                          const { background, color } = generateColor(fixedPosition.fixedPositionProviders[0].name)
                          bgProvider = background
                          colorProvider = color
                        } else if (fixedPosition.fixedPositionAds?.length > 0) {
                          const { background, color } = generateColor(fixedPosition.fixedPositionAds[0].provider)
                          bgAd = background
                          colorAd = color
                        } else {
                          return null
                        }
                        return (
                          <div key={fixedPosition?.position} className="reorderContainer align-bottom mr-2" style={{ height: 140 }}>
                            {(fixedPosition.fixedPositionProviders?.length > 0)
                              ? (
                                <Card
                                  className="mx-2 responseCard h-100 align-items-center justify-content-center p-3"
                                  style={{ cursor: "pointer", background: bgProvider, color: colorProvider }}
                                  onClick={() => handleFixedPositionClick(fixedPosition?.position, fixedPosition)}
                                >
                                  <p className='mb-0 text-center font-weight-bold'>{replaceUnderscoreAndCapitalize(fixedPosition.fixedPositionProviders[0].name)}</p>
                                  {(fixedPosition.fixedPositionProviders?.length > 1)
                                    && <div className='totalCount' style={{ background: colorProvider, color: colorProvider === "#fff" ? "#000" : "#fff" }}>+{fixedPosition.fixedPositionProviders?.length - 1}</div>
                                  }
                                </Card>
                              ) : (fixedPosition.fixedPositionAds?.length > 0)
                                ? (
                                  <>
                                    <Card
                                      className="mb-3 ml-3 mr-3 mt-0.5 responseCard"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleFixedPositionClick(fixedPosition?.position, fixedPosition)}
                                    >
                                      <CardImg src={fixedPosition.fixedPositionAds[0].iconURL ?? ""} />
                                      {(fixedPosition.fixedPositionAds?.length > 1)
                                        && <div className='totalCount'>+{fixedPosition.fixedPositionAds?.length - 1}</div>
                                      }
                                    </Card>
                                    <center style={{
                                      fontSize: 12,
                                      fontWeight: 700,
                                      marginTop: -10,
                                      marginBottom: 4,
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis"
                                    }}>{fixedPosition.fixedPositionAds[0].id}</center>
                                    <center style={{
                                      fontWeight: 600,
                                      marginTop: -6,
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis"
                                    }}>{fixedPosition.fixedPositionAds[0].title}</center>
                                    <center style={{
                                      background: bgAd,
                                      color: colorAd,
                                      fontSize: 11,
                                      fontWeight: 800,
                                      borderRadius: 4,
                                      width: "fit-content",
                                      margin: "auto",
                                      padding: "2px 10px"
                                    }}>{replaceUnderscoreAndCapitalize(fixedPosition.fixedPositionAds[0].provider)}</center>
                                  </>
                                ) : null
                            }
                          </div>
                        )
                      }
                      return ((
                        <div key={el + 1} className="reorderContainer align-bottom mr-2" style={{ height: 140 }}>
                          <Card
                            className="mx-2 h-100 align-items-center justify-content-center p-3"
                            style={{ cursor: showFixedPositionsUpdateButton() ? "pointer" : "not-allowed", border: "3px dashed #cccccc" }}
                            onClick={() => {
                              if (showFixedPositionsUpdateButton())
                                handleFixedPositionClick(el + 1)
                            }}
                          >
                            <HiOutlinePlus size={24} color='#c5c5c5' />
                          </Card>
                        </div>
                      ))
                    })}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <h4>Extra Fixed Positions{" "}
              <ToolTip
                data="This can be used for weightage-based ads distribution"
                id="extraFixedPositionsTooltip"
              />
            </h4>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {filterAds("extraFixedPositions").map(fixedPosition => {
                      let bgProvider = "", colorProvider = "", bgAd = "", colorAd = ""
                      if (fixedPosition.fixedPositionProviders?.length > 0) {
                        const { background, color } = generateColor(fixedPosition.fixedPositionProviders[0].name)
                        bgProvider = background
                        colorProvider = color
                      } else if (fixedPosition.fixedPositionAds?.length > 0) {
                        const { background, color } = generateColor(fixedPosition.fixedPositionAds[0].provider)
                        bgAd = background
                        colorAd = color
                      } else {
                        return null
                      }
                      return (
                        <div key={fixedPosition?.position} className="reorderContainer mb-4 align-bottom mr-2" style={{ height: 140 }}>
                          {(fixedPosition.fixedPositionProviders?.length > 0)
                            ? (
                              <Card
                                className="mx-2 responseCard h-100 align-items-center justify-content-center p-3"
                                style={{ cursor: "pointer", background: bgProvider, color: colorProvider }}
                                onClick={() => handleFixedPositionClick(fixedPosition?.position, fixedPosition, true)}
                              >
                                <p className='mb-0 text-center font-weight-bold'>{replaceUnderscoreAndCapitalize(fixedPosition.fixedPositionProviders[0].name)}</p>
                                {(fixedPosition.fixedPositionProviders?.length > 1)
                                  && <div className='totalCount' style={{ background: colorProvider, color: colorProvider === "#fff" ? "#000" : "#fff" }}>+{fixedPosition.fixedPositionProviders?.length - 1}</div>
                                }
                                <Tooltip title="Fixed position index">
                                  <div className='fixedPositionNumber' style={{ background: colorProvider, color: colorProvider === "#fff" ? "#000" : "#fff" }}>{fixedPosition?.position}</div>
                                </Tooltip>
                              </Card>
                            ) : (fixedPosition.fixedPositionAds?.length > 0)
                              ? (
                                <>
                                  <Card
                                    className="mb-3 ml-3 mr-3 mt-0.5 responseCard"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleFixedPositionClick(fixedPosition?.position, fixedPosition, true)}
                                  >
                                    <CardImg src={fixedPosition.fixedPositionAds[0].iconURL ?? ""} />
                                    {(fixedPosition.fixedPositionAds?.length > 1)
                                      && <div className='totalCount'>+{fixedPosition.fixedPositionAds?.length - 1}</div>
                                    }
                                    <Tooltip title="Fixed position index">
                                      <div className='fixedPositionNumber'>{fixedPosition?.position}</div>
                                    </Tooltip>
                                  </Card>
                                  <center style={{
                                    fontSize: 12,
                                    fontWeight: 700,
                                    marginTop: -10,
                                    marginBottom: 4,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                  }}>{fixedPosition.fixedPositionAds[0].id}</center>
                                  <center style={{
                                    fontWeight: 600,
                                    marginTop: -6,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                  }}>{fixedPosition.fixedPositionAds[0].title}</center>
                                  <center style={{
                                    background: bgAd,
                                    color: colorAd,
                                    fontSize: 11,
                                    fontWeight: 800,
                                    borderRadius: 4,
                                    width: "fit-content",
                                    margin: "auto",
                                    padding: "2px 10px"
                                  }}>{replaceUnderscoreAndCapitalize(fixedPosition.fixedPositionAds[0].provider)}</center>
                                </>
                              ) : null
                          }
                        </div>
                      )
                    })}
                    <div className="reorderContainer mb-4 align-bottom mr-2" style={{ height: 140 }}>
                      <Card
                        className="mx-2 h-100 align-items-center justify-content-center p-3"
                        style={{ cursor: showFixedPositionsUpdateButton() ? "pointer" : "not-allowed", border: "3px dashed #cccccc" }}
                        onClick={() => {
                          if (showFixedPositionsUpdateButton())
                            handleFixedPositionClick("", null, true)
                        }}
                      >
                        <HiOutlinePlus size={24} color='#c5c5c5' />
                      </Card>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div className='d-flex justify-content-between align-items-center mb-2'>
              <div className=''>
                <h4 className='mb-0'>Promoted Ads</h4>
                <div style={{ fontSize: 13 }} className="text-info font-weight-bold">{isReorderEnabled ? "Drag and Drop the ads to rearrange them and change priority" : "Click on ad to update its metadata"}</div>
                <div style={{ fontSize: 13 }} className="text-success font-weight-bold">{isReorderEnabled && "Disable toggle to override metadata"}</div>
              </div>
              {(showPromotedAdsOrderUpdateButton() && ads.promotedAds?.length > 0) && (
                <div className=''>
                  <div className="custom-control custom-switch d-flex align-items-center mr-2">
                    <strong className="mr-4">Reorder Promoted Ads?</strong>
                    <CustomInput
                      type="checkbox"
                      id="reorderToggle"
                      value={isReorderEnabled}
                      onChange={() => setIsReorderEnabled(!isReorderEnabled)}
                      checked={isReorderEnabled}
                      disabled={ads.promotedAds?.length <= 0}
                    />
                  </div>
                  {isReorderEnabled &&
                    <Button className="d-block ml-auto mt-2 mb-1" color="primary" onClick={() => setConfirmationModalState(metadataOverrideAllActions.updatePromotedAdsOrder)}>
                      Update
                    </Button>
                  }
                </div>
              )}
            </div>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {(filterAds("promotedAds")?.length > 0)
                      ? isReorderEnabled
                        ? (
                          <Reorder reorderId="reorder-list" onReorder={handleReorder}>
                            {filterAds("promotedAds")?.map(ad => {
                              const { background, color } = generateColor(ad.provider)
                              return (
                                <div key={ad?.id} className="reorderContainer mb-3 align-top h-auto mr-2">
                                  <Card
                                    className="m-3 responseCard"
                                    style={{ cursor: "grab" }}
                                  >
                                    <CardImg
                                      src={ad.animatedIconSrc || ad.staticIconSrc || ad.uiTilesDetails?.iconURL || ad.uiDetailsCardDetails?.iconURL || ad.uiProductsCardDetails?.iconURL || ad.uiProductsCardTwoDetails?.iconURL || ad.uiProductsCardThreeDetails?.iconURL}
                                      draggable={false}
                                    />
                                    <div className='badge-section'>
                                      {ad.isModified
                                        && <div className='modified'>Modified</div>
                                      }
                                      {ad.storyAd?.id
                                        ? <div className='storyAdBadge'>Story Ad</div>
                                        : null
                                      }
                                    </div>
                                  </Card>
                                  <center style={{
                                    fontSize: 12,
                                    fontWeight: 700,
                                    marginTop: -10,
                                    marginBottom: 4,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                  }}>{ad.id}</center>
                                  <center style={{
                                    fontWeight: 600,
                                    marginTop: -6,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}>{ad.title || ad.uiTilesDetails?.title || ad.uiDetailsCardDetails?.title || ad.uiProductsCardDetails?.title || ad.uiProductsCardTwoDetails?.title}</center>
                                  <center style={{
                                    background,
                                    color,
                                    fontSize: 11,
                                    fontWeight: 800,
                                    borderRadius: 4,
                                    width: "fit-content",
                                    margin: "auto",
                                    padding: "2px 10px"
                                  }}>{replaceUnderscoreAndCapitalize(ad.provider)}</center>
                                </div>
                              )
                            })}
                          </Reorder>
                        ) : filterAds("promotedAds")?.map(ad => {
                          const { background, color } = generateColor(ad.provider)
                          return (
                            <div key={ad?.id} className="reorderContainer mb-3 align-top h-auto mr-2">
                              <Card
                                className="m-3 responseCard"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (placement?.isSupportedStoryAds) handleManageStoryAdClick(ad, true)
                                  else handleAdClick(ad, true)
                                }}
                              >
                                <CardImg
                                  src={ad.animatedIconSrc || ad.staticIconSrc || ad.uiTilesDetails?.iconURL || ad.uiDetailsCardDetails?.iconURL || ad.uiProductsCardDetails?.iconURL || ad.uiProductsCardTwoDetails?.iconURL || ad.uiProductsCardThreeDetails?.iconURL}
                                />
                                <div className='badge-section'>
                                  {ad.isModified
                                    && <div className='modified'>Modified</div>
                                  }
                                  {ad.storyAd?.id
                                    ? <div className='storyAdBadge'>Story Ad</div>
                                    : null
                                  }
                                </div>
                              </Card>
                              <center style={{
                                fontSize: 12,
                                fontWeight: 700,
                                marginTop: -10,
                                marginBottom: 4,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                              }}>{ad.id}</center>
                              <center style={{
                                fontWeight: 600,
                                marginTop: -6,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}>{ad.title || ad.uiTilesDetails?.title || ad.uiDetailsCardDetails?.title || ad.uiProductsCardDetails?.title || ad.uiProductsCardTwoDetails?.title}</center>
                              <center style={{
                                background,
                                color,
                                fontSize: 11,
                                fontWeight: 800,
                                borderRadius: 4,
                                width: "fit-content",
                                margin: "auto",
                                padding: "2px 10px"
                              }}>{replaceUnderscoreAndCapitalize(ad.provider)}</center>
                            </div>
                          )
                        })
                      : <p className='py-3 mb-0'>No promoted ads found for <strong>selected filters</strong>.</p>
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <h4 className='mb-0'>Non-Promoted Ads</h4>
            <div style={{ fontSize: 13 }} className="text-info font-weight-bold mb-2">Click on ad to update its metadata</div>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {(filterAds("nonPromotedAds")?.length > 0) ? (
                      filterAds("nonPromotedAds")?.map(ad => {
                        const { background, color } = generateColor(ad.provider)
                        return (
                          <div key={ad?.id} className="reorderContainer mb-3 align-top h-auto mr-2">
                            <Card
                              className="m-3 responseCard"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                if (placement?.isSupportedStoryAds) handleManageStoryAdClick(ad, false)
                                else handleAdClick(ad, false)
                              }}
                            >
                              <CardImg
                                src={ad.animatedIconSrc || ad.staticIconSrc || ad.uiTilesDetails?.iconURL || ad.uiDetailsCardDetails?.iconURL || ad.uiProductsCardDetails?.iconURL || ad.uiProductsCardTwoDetails?.iconURL || ad.uiProductsCardThreeDetails?.iconURL}
                              />
                              <div className='badge-section'>
                                {ad.isModified
                                  && <div className='modified'>Modified</div>
                                }
                                {ad.storyAd?.id
                                  ? <div className='storyAdBadge'>Story Ad</div>
                                  : null
                                }
                              </div>
                            </Card>
                            <center style={{
                              fontSize: 12,
                              fontWeight: 700,
                              marginTop: -10,
                              marginBottom: 4,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis"
                            }}>{ad.id}</center>
                            <center style={{
                              fontWeight: 600,
                              marginTop: -6,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}>{ad.title || ad.uiTilesDetails?.title || ad.uiDetailsCardDetails?.title || ad.uiProductsCardDetails?.title || ad.uiProductsCardTwoDetails?.title}</center>
                            <center style={{
                              background,
                              color,
                              fontSize: 11,
                              fontWeight: 800,
                              borderRadius: 4,
                              width: "fit-content",
                              margin: "auto",
                              padding: "2px 10px"
                            }}>{replaceUnderscoreAndCapitalize(ad.provider)}</center>
                          </div>
                        )
                      })
                    )
                      : <p className='py-3 mb-0'>No non-promoted ads found for <strong>selected filters</strong>.</p>
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
        <SmartSuggestionsFixedPositionModal
          show={isFixedPositionModalVisible}
          type={fixedPositionType}
          position={selectedFixedPosition}
          isExtraFixedPosition={isExtraFixedPosition}
          allAdsOptions={allAdsOptions}
          placementProvider={placementProvider}
          formData={fixedPositionData}
          setFormData={setFixedPositionData}
          onSubmit={handleUpdateFixedPosition}
          onHide={hideFixedPositionModal}
        />
        <SmartSuggestionsMetadataOverrideModal
          show={isMetadataOverrideModalVisible}
          adDetails={modalAdDetails}
          isSupportedStoryAds={placement?.isSupportedStoryAds || false}
          promoteAd={handlePromoteAd}
          onReset={handleResetOverride}
          onSubmit={handleUpdateMetadata}
          onHide={hideMetadataOverrideModal}
          onManageStoryAds={handleManageStoryAdSwitch}
        />
        <ManageStoryAdsModal
          show={isManageStoryAdsModalVisible}
          adDetails={storyAdDetails}
          onReset={handleResetStoryAd}
          onSubmit={handleManageStoryAd}
          onHide={hideManageStoryAdsModal}
          onMetadataOverride={handleMetadataOverrideSwitch}
        />
        <ConfirmationModal
          show={confirmationModalIsOpen}
          modaltext={action}
          onConfirmationTrue={() => {
            setConfirmationModalIsOpen(false);
            if (action === metadataOverrideAllActions.updatePromotedAdsOrder) handleUpdatePromotedAds();
          }}
          onHide={() => setConfirmationModalIsOpen(false)}
        />
        <ResponseModal
          show={successModalDisplay}
          onHide={successModalClose}
          modalheading={successModalHeading}
          modaltext={successModalText}
        />
        <ResponseModal
          show={failureModalDisplay}
          onHide={failureModalClose}
          modalheading={errorModalHeading}
          modaltext={failureModalText}
        />
      </Container>
      <RightSidebar width={350} isSidebarOpen={isSidebarOpen} />
    </div>
  )
}

export default SmartSuggestionsMetadataOverride
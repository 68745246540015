import React, { useState } from "react";
import {
    Col,
    Row,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Button,
    Collapse,
} from "reactstrap";
import ResponseModal from "../../../../pages/ui-elements/ResponseModal";
import { quickRepliesCTAActionsType, quickRepliesCTAActionsRedirectionType, quickRepliesCTAActionsDeeplinkType, clientIDOptions } from "../../../../config/quickReplies";
import ActionCard from "./ActionCard";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { Modal } from "react-bootstrap";

const QuickReplyActions = ({ quickReplyTypeCard, setQuickReplyTypeCard, clientOptions, formName, updateAllowed, disableAddEditActionButton }) => {
    const [action, setAction] = useState({
        type: { value: "share", label: "Share" },
        clientId: null,
        textToCopy: null,
        shareText: null,
        redirectionType: null,
        redirectionViewportHeight: null,
        deeplink: null,
        deeplinkType: null,
        autoGeneratedDeeplink: null,
        url: null,
    });
    const [actionToggle, setActionToggle] = useState(true);
    const [showActionSubmitModal, setShowActionSubmitModal] = useState(false);
    const [showActionSectionDetails, setShowActionSectionDetails] = useState(false);
    const [actionIndexForEdit, setActionIndexForEdit] = useState(null);
    const [failureModalState, setFailureModalState] = useState({
        failureModalDisplay: false,
        failureModalText: "",
    });
    const [clientCheckBox, setClientCheckBox] = useState({
        selectAll: true,
        aiMintKeyboard: true,
        bobbleAndroidApp: true,
        mintKeyboardPreload: true,
    });

    const addActions = () => {
        setAction({
            type: { value: "share", label: "Share" },
            clientId: null,
            textToCopy: null,
            shareText: null,
            redirectionType: null,
            redirectionViewportHeight: null,
            deeplink: null,
            deeplinkType: null,
            autoGeneratedDeeplink: null,
            url: null,
        })
        setShowActionSubmitModal(true);
    }

    const saveActions = () => {
        if(disableAddEditActionButton(action) !== ""){
            setFailureModalState({
                failureModalDisplay: true,
                failureModalText: disableAddEditActionButton(action),
            })
            return;
        }
        
        if (actionIndexForEdit !== null) {
            let temp = [...quickReplyTypeCard.actions];
            temp[actionIndexForEdit] = action;
            setQuickReplyTypeCard({
                ...quickReplyTypeCard,
                actions: temp,
            });
        } else {
            if (quickReplyTypeCard.actions === undefined || quickReplyTypeCard.actions === null) {
                quickReplyTypeCard.actions = []
            }

            if (action.type.value === "share") {
                let tempAction = []
                if (clientCheckBox.aiMintKeyboard) {
                    let temp = { ...action };
                    temp.clientId = clientIDOptions[0];
                    tempAction.push(temp);
                }

                if (clientCheckBox.bobbleAndroidApp) {
                    let temp = { ...action };
                    temp.clientId = clientIDOptions[1];
                    tempAction.push(temp);
                }

                if (clientCheckBox.mintKeyboardPreload) {
                    let temp = { ...action };
                    temp.clientId = clientIDOptions[2];
                    tempAction.push(temp);
                }

                setQuickReplyTypeCard({
                    ...quickReplyTypeCard,
                    actions: [...quickReplyTypeCard.actions, ...tempAction]
                })
            } else {
                setQuickReplyTypeCard({
                    ...quickReplyTypeCard,
                    actions: [...quickReplyTypeCard.actions, action]
                })
            }
        }
        setShowActionSubmitModal(false);
        setAction({
            type: { value: "share", label: "Share" },
            clientId: null,
            textToCopy: null,
            shareText: null,
            redirectionType: null,
            redirectionViewportHeight: null,
            deeplink: null,
            deeplinkType: null,
            url: null,
        })
        setActionIndexForEdit(null);
        setClientCheckBox({
            selectAll: true,
            aiMintKeyboard: true,
            bobbleAndroidApp: true,
            mintKeyboardPreload: true,
        })
    }

    const getUniqueClientIdsWithName = () => {
        let uniqueClientIds = [];
        let uniqueClientIdsWithName = [];
        if (quickReplyTypeCard.actions !== undefined && quickReplyTypeCard.actions !== null) {
            quickReplyTypeCard.actions.map((item) => {
                if (item.clientId) {
                    uniqueClientIds.push(item.clientId.value);
                }
            });
        }
        uniqueClientIds = [...new Set(uniqueClientIds)];
        uniqueClientIds.map((item) => {
            clientOptions.map((client) => {
                if (client.value === item) {
                    uniqueClientIdsWithName.push(client);
                }
            });
        });
        return uniqueClientIdsWithName;
    };


    const removeAction = (index) => {
        let temp = [...quickReplyTypeCard.actions];
        temp = temp.filter((_, idx) => idx !== index);
        setQuickReplyTypeCard({
            ...quickReplyTypeCard,
            actions: temp,
        });
    }

    const duplicateAction = (item) => {
        setQuickReplyTypeCard({
            ...quickReplyTypeCard,
            actions: [...quickReplyTypeCard.actions, item]
        })
    }

    const allowEdit = () => {
        return formName === "edit" && !updateAllowed;
    }



    return (
        <>
            <Row className="mt-6">
                <Col md={6}>
                    <h1 className="h3 mb-3">Actions</h1>
                </Col>
                <Col md={6}>
                    <div className="float-right" onClick={(e) => {
                        setActionToggle(!actionToggle)
                    }}>
                        {actionToggle ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                    <Button onClick={addActions} className="float-right mr-2" disabled={allowEdit()}>
                        <FontAwesomeIcon size="sm" icon={faPlus}></FontAwesomeIcon>
                        {" "}Add Actions
                    </Button>

                </Col>
            </Row>

            <Collapse isOpen={actionToggle}>
                <Card>
                    <CardBody>
                        {getUniqueClientIdsWithName().map((client, index) => (
                            <>
                                <Row key={`row-${index}`}>
                                    <Col md={6}>
                                        <h1 className="h3 mb-3">
                                            {client.label}
                                        </h1>
                                    </Col>
                                    <Col md={6}>

                                    </Col>
                                </Row>

                                <Row key={`row-${index + 7}`}>
                                    {quickReplyTypeCard.actions.map((item, index) => {
                                        return item?.clientId?.value === client.value && (
                                            <Col md={6}>
                                                <ActionCard
                                                    height="200px"
                                                    index={index}
                                                    item={item}
                                                    formName={formName}
                                                    updateAllowed={updateAllowed}
                                                    setShowActionSectionDetails={setShowActionSectionDetails}
                                                    setAction={setAction}
                                                    setShowActionSubmitModal={setShowActionSubmitModal}
                                                    setActionIndexForEdit={setActionIndexForEdit}
                                                    duplicateAction={duplicateAction}
                                                    removeAction={removeAction}
                                                />
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </>
                        ))}
                    </CardBody>
                </Card>
            </Collapse>

            <Modal
                show={showActionSubmitModal}
                onHide={() => {
                    setShowActionSubmitModal(false)
                    setActionIndexForEdit(null);
                    setAction({
                        type: { value: "share", label: "Share" },
                        clientId: null,
                        textToCopy: null,
                        shareText: null,
                        redirectionType: null,
                        redirectionViewportHeight: null,
                        deeplink: null,
                        deeplinkType: null,
                        autoGeneratedDeeplink: null,
                        url: null,
                    })
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    {showActionSectionDetails === false && (
                        <Modal.Title>{actionIndexForEdit === null ? "Create  Action" : "Edit Action"}</Modal.Title>
                    )}
                </Modal.Header>

                <Modal.Body>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="type">Type<span class="text-danger">*</span></Label>
                                <Select
                                    id="type"
                                    name="type"
                                    className="react-select-container mb-4"
                                    classNamePrefix="react-select"
                                    options={quickRepliesCTAActionsType}
                                    value={action.type}
                                    isDisabled={showActionSectionDetails}
                                    onChange={(e) => {
                                        setAction({
                                            ...action,
                                            type: e,
                                            clientId: null,
                                            textToCopy: null,
                                            shareText: null,
                                            redirectionType: null,
                                            redirectionViewportHeight: null,
                                            deeplink: "",
                                            deeplinkType: null,
                                            autoGeneratedDeeplink: "",
                                            url: null,
                                        })
                                    }}
                                />
                            </FormGroup>
                        </Col>

                        {action?.type?.value === "share" && actionIndexForEdit === null && !showActionSectionDetails && (
                            <Row className="px-4">
                                <Col md={12} className="mb-4">
                                    <strong>
                                        Select Client<span className="text-danger">*</span>
                                    </strong>
                                </Col>
                                <Col md={3}>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                id="selectAll"
                                                name="selectAll"
                                                checked={clientCheckBox.selectAll}
                                                onChange={(e) => {
                                                    setClientCheckBox({
                                                        ...clientCheckBox,
                                                        selectAll: e.target.checked,
                                                        aiMintKeyboard: e.target.checked,
                                                        bobbleAndroidApp: e.target.checked,
                                                        mintKeyboardPreload: e.target.checked,
                                                    })
                                                }}
                                            />
                                            Select All
                                        </Label>
                                    </FormGroup>
                                </Col>

                                <Col md={3}>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                id="aiMintKeyboard"
                                                name="aiMintKeyboard"
                                                checked={clientCheckBox.aiMintKeyboard}
                                                onChange={(e) => {
                                                    setClientCheckBox({
                                                        ...clientCheckBox,
                                                        aiMintKeyboard: e.target.checked,
                                                        selectAll: e.target.checked && clientCheckBox.bobbleAndroidApp && clientCheckBox.mintKeyboardPreload,
                                                    })
                                                }}
                                            />
                                            AI Mint Keyboard (Playstore)
                                        </Label>
                                    </FormGroup>
                                </Col>

                                <Col md={3}>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                id="bobbleAndroidApp"
                                                name="bobbleAndroidApp"
                                                checked={clientCheckBox.bobbleAndroidApp}
                                                onChange={(e) => {
                                                    setClientCheckBox({
                                                        ...clientCheckBox,
                                                        bobbleAndroidApp: e.target.checked,
                                                        selectAll: e.target.checked && clientCheckBox.aiMintKeyboard && clientCheckBox.mintKeyboardPreload,
                                                    })
                                                }}
                                            />
                                            Bobble Android App
                                        </Label>
                                    </FormGroup>
                                </Col>

                                <Col md={3}>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                id="mintKeyboardPreload"
                                                name="mintKeyboardPreload"
                                                checked={clientCheckBox.mintKeyboardPreload}
                                                onChange={(e) => {
                                                    setClientCheckBox({
                                                        ...clientCheckBox,
                                                        mintKeyboardPreload: e.target.checked,
                                                        selectAll: e.target.checked && clientCheckBox.aiMintKeyboard && clientCheckBox.bobbleAndroidApp,
                                                    })
                                                }}
                                            />
                                            Mint Keyboard (Preload)
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        )}

                        {action?.type !== null && ((actionIndexForEdit === null && action?.type?.value !== "share") || actionIndexForEdit !== null || showActionSectionDetails) && (
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="clientId">Client ID<span class="text-danger">*</span></Label>
                                    <Select
                                        id="clientId"
                                        name="clientId"
                                        className="react-select-container mb-4"
                                        classNamePrefix="react-select"
                                        options={action.type.value === "share" ? clientIDOptions : clientOptions}
                                        value={action.clientId}
                                        isDisabled={showActionSectionDetails}
                                        onChange={(e) => {
                                            setAction({
                                                ...action,
                                                clientId: e
                                            })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        )}

                        {action?.type?.value === "copyCode" && (
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="textToCopy">Text to Copy<span class="text-danger">*</span></Label>
                                    <Input
                                        type="text"
                                        id="textToCopy"
                                        name="textToCopy"
                                        disabled={showActionSectionDetails}
                                        value={action.textToCopy}
                                        onChange={(e) => {
                                            setAction({
                                                ...action,
                                                textToCopy: e.target.value
                                            })
                                        }}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                        )}

                        {action?.type?.value === "share" && (
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="shareText">Share Text<span class="text-danger">*</span></Label>
                                    <Input
                                        type="textarea"
                                        id="shareText"
                                        name="shareText"
                                        value={action.shareText}
                                        disabled={showActionSectionDetails}
                                        onChange={(e) => {
                                            setAction({
                                                ...action,
                                                shareText: e.target.value
                                            })
                                        }}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                        )}

                        {action?.type?.value === "redirect" && (
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="redirectionType">Redirection Type<span class="text-danger">*</span></Label>
                                    <Select
                                        id="redirectionType"
                                        name="redirectionType"
                                        className="react-select-container mb-4"
                                        classNamePrefix="react-select"
                                        options={quickRepliesCTAActionsRedirectionType}
                                        value={action.redirectionType}
                                        isDisabled={showActionSectionDetails}
                                        onChange={(e) => {
                                            setAction({
                                                ...action,
                                                redirectionType: e
                                            })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        )}

                        {action?.type?.value === "redirect" && (
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="redirectionViewportHeight">Redirection Viewport Height</Label>
                                    <Input
                                        type="number"
                                        id="redirectionViewportHeight"
                                        name="redirectionViewportHeight"
                                        disabled={showActionSectionDetails}
                                        value={action.redirectionViewportHeight}
                                        onChange={(e) => {
                                            setAction({
                                                ...action,
                                                redirectionViewportHeight: e.target.value
                                            })
                                        }}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                        )}

                        {action?.type?.value === "redirect" && (
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="url">URL<span class="text-danger">*</span></Label>
                                    <Input
                                        type="textarea"
                                        id="url"
                                        name="url"
                                        disabled={showActionSectionDetails}
                                        value={action.url}
                                        onChange={(e) => {
                                            setAction({
                                                ...action,
                                                url: e.target.value
                                            })
                                        }}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                        )}

                        {action?.type !== null && (
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="deeplinkType">Deeplink Type<span class="text-danger">*</span></Label>
                                    <Select
                                        id="deeplinkType"
                                        name="deeplinkType"
                                        isDisabled={showActionSectionDetails}
                                        className="react-select-container mb-4"
                                        classNamePrefix="react-select"
                                        options={quickRepliesCTAActionsDeeplinkType}
                                        value={action.deeplinkType}
                                        onChange={(e) => {
                                            setAction({
                                                ...action,
                                                deeplinkType: e,
                                                deeplink: "",
                                            })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        )}

                        {action?.type !== null && (
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="deeplink">Deeplink<span class="text-danger">*</span></Label>
                                    <Input
                                        type="textarea"
                                        id="deeplink"
                                        name="deeplink"
                                        value={action?.deeplinkType?.value === "default" ? action.autoGeneratedDeeplink : action.deeplink}
                                        disabled={showActionSectionDetails || (action.deeplinkType?.value === "default" || action.deeplinkType === null)}
                                        onChange={(e) => {
                                            setAction({
                                                ...action,
                                                deeplink: e.target.value,
                                            })
                                        }}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                        )}
                    </Row>

                    <Modal.Footer style={{
                        padding: '0px',
                    }}>
                        <Button
                            color="primary"
                            type="submit"
                            className="btn-lg"
                            onClick={() => {
                                
                                saveActions();
                            }}
                            disabled={showActionSectionDetails}
                        >
                            {actionIndexForEdit !== null ? "Edit" : "Add"}
                        </Button>
                    </Modal.Footer>
                </Modal.Body>

            </Modal>

            <ResponseModal
                show={failureModalState.failureModalDisplay}
                onHide={() =>
                    setFailureModalState({
                        failureModalDisplay: false,
                        failureModalText: "",
                    })
                }
                modalheading={"Error"}
                modaltext={failureModalState.failureModalText}
            />
        </>
    )
}

export default QuickReplyActions;
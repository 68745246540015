import React, { Fragment, useState, useEffect } from "react";
import TargetingRules from "../TargetingRules";

import QuickReplyCategoryDetailBoard from "./components/QuickReplyCategoryDetailBoard";
import QuickReplyCategoryFormContainer from "./QuickReplyCategoryFormContainer";
import {
  getQuickReplyCategoryRules,
  saveQuickReplyCategoryRules,
  getCountries,
  getRegions,
  getCities,
  getQuickReplyCategoryDetail,
} from "../../../utilities/apiUtils/quickReplies";
import { getClients } from "../../../utilities/apiUtils/stickers";

import {
  error,
  errorDescription,
  permissions,
  redirectOnTokenExpiry,
} from "../../../utilities/commonUtil";

import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { Card } from "reactstrap";

const QuickReplyCategoryDetails = (props) => {
  let id = props.match.params.categoryID;

  const [loading, setLoading] = useState(false);
  const [quickReplyCategory, setQuickReplyCategory] = useState({});
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  const updateRulesAllowed = () => {
    return allowedPermissions.includes(
      permissions.quickReplyCategoryRuleUpdate
    );
  };

  const updateAllowed = () => {
    return allowedPermissions.includes(permissions.quickReplyCategoryUpdate);
  };

  const showRules = () => {
    return (
      allowedPermissions.includes(permissions.clientList) &&
      allowedPermissions.includes(permissions.countryList) &&
      allowedPermissions.includes(permissions.regionList) &&
      allowedPermissions.includes(permissions.cityList) &&
      allowedPermissions.includes(permissions.quickReplyCategoryRuleView)
    );
  };
  useEffect(() => {
    const fetchQuickReplyCategoryDetails = async (categoryID) => {
      setLoading(true);

      try {
        const response = await getQuickReplyCategoryDetail(categoryID);
        if (response.errorCode) {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken
          )
            redirectOnTokenExpiry();
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: errorDescription(response.unexpectedError),
          });
        }
        setQuickReplyCategory(response);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(err.unexpectedError),
        });
      }
    };

    fetchQuickReplyCategoryDetails(props.match.params.categoryID);
  }, []);

  return (
    <div>
      <div>
        <QuickReplyCategoryDetailBoard id={id} />
      </div>
      <div>
        <QuickReplyCategoryFormContainer
          {...props}
          id={id}
          formName={"Details"}
          updateAllowed={updateAllowed()}
        />
        {showRules() ? (
          <Card  className="p-3 mb-5" style={{
            boxShadow: '0 0 20px rgba(0,0,0,0.4)',  // Increased shadow
            borderRadius: '15px'
        }}>
          <TargetingRules
            id={id}
            getRules={getQuickReplyCategoryRules}
            saveRules={saveQuickReplyCategoryRules}
            getClients={getClients}
            getCountries={getCountries}
            getRegions={getRegions}
            getCities={getCities}
            showAppVersionRules={true}
            updateRulesAllowed={updateRulesAllowed()}
          />
          </Card>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

export default QuickReplyCategoryDetails;

import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import ConfirmationModal from "../../ConfirmationModal";
import ResponseModal from "../../ResponseModal";
import { listKeyboardPromptsPriorityRuleSets, deactivateKeyboardPromptsPriorityRuleSet, activateKeyboardPromptsPriorityRuleSet } from "../../../../utilities/apiUtils/keyboardPromptsOrder";
import { tableOrderColumns } from "./tableColumn";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import Loading from "../../../../components/Loading";
import { successModalHeading, errorModalHeading } from "../../../../config/config";
import { statusOptions } from "../../../../config/segmentCategories";

const SegmentPromptsOrder = () => {
  const [loading, setLoading] = useState(true);
  const [keyboardPrompts, setKeyboardPrompts] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [successModalText, setSuccessModalText] = useState("");
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [action, setAction] = useState("");
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);

  const fetchKeyboardPromptsData = async () => {
    try {
      setLoading(true);
      const data = await listKeyboardPromptsPriorityRuleSets();
      if (data) {
        setKeyboardPrompts(data);
      }
      setLoading(false);
    } catch (error) {
      setFailureModalText(error.message);
      setFailureModalDisplay(true);
      setLoading(false);
    }
  };

  const handleDeactivate = async (promptsRuleSetID) => {
    try {
      const response = await deactivateKeyboardPromptsPriorityRuleSet(promptsRuleSetID);
      if (response?.message === 'Prompts ruleset deactivated') {
        setSuccessModalText("Keyboard Prompt Order Deactivated successfully");
        setSuccessModalDisplay(true);
        fetchKeyboardPromptsData();
      } else {
        setFailureModalText("Failed to deactivate the keyboard prompt order.");
        setFailureModalDisplay(true);
      }
    } catch (error) {
      console.error(error);
      setFailureModalText("An error occurred while deactivating the keyboard prompt.");
      setFailureModalDisplay(true);
    }
  };
  

  const handleActivate = async (promptsRuleSetID) => {
    try {
      const response = await activateKeyboardPromptsPriorityRuleSet(promptsRuleSetID);
      if (response?.message === 'Prompts ruleset activated') {
        setSuccessModalText("Keyboard Prompt Order Activated successfully");
        setSuccessModalDisplay(true);
        fetchKeyboardPromptsData();
      }  else {
        setFailureModalText("Failed to activate the keyboard prompt order.");
        setFailureModalDisplay(true);
      }
    } catch (error) {
      console.error(error);
      setFailureModalText("An error occurred while activating the keyboard prompt.");
      setFailureModalDisplay(true);
    }
  };

  useEffect(() => {
    fetchKeyboardPromptsData();
    const savedNameFilter = sessionStorage.getItem("keyboardPromptsFilterName") || "";
    const savedStatusFilterValue = sessionStorage.getItem("keyboardPromptsFilterStatus") || "";
    setNameFilter(savedNameFilter);
    if (savedStatusFilterValue) {
      setStatusFilter({
        value: savedStatusFilterValue,
        label: statusOptions.find((option) => option.value === savedStatusFilterValue)?.label || "",
      });
    }
  }, []);

  const filterKeyboardPrompts = () => {
    let items = keyboardPrompts;

    if (statusFilter?.value) {
      items = items.filter((item) => item.status === statusFilter.value);
    }

    if (nameFilter) {
      items = items.filter((item) =>
        item.name?.toLowerCase().includes(nameFilter.toLowerCase())
      );
    }

    return items;
  };

  const successModalClose = async () => {
    setSuccessModalDisplay(false);
    await fetchKeyboardPromptsData();
  };

  const failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => {
      // Check if deactivatedAt is a valid date or a default date like "0001-01-01T00:00:00Z"
      const isValidDate = (date) => {
        const parsedDate = new Date(date);
        return parsedDate instanceof Date && !isNaN(parsedDate);
      };
  
      const isDeactivated = row.deactivatedAt && isValidDate(row.deactivatedAt) && new Date(row.deactivatedAt) < new Date();
      const isNotDeactivated = !row.deactivatedAt || !isValidDate(row.deactivatedAt) || new Date(row.deactivatedAt) >= new Date();
  
      const handleAction = async (actionType, rowId) => {
        if (actionType === "deactivate") {
          await handleDeactivate(rowId);
        } else if (actionType === "activate") {
          await handleActivate(rowId);
        }
      };
  
      return (
        <div>
          <Link
            className="text-decoration-none"
            to={{
              pathname: `/keyboard-prompt/prompts/rulesetOrder/${row.ruleId}`,
              state: { formType: "edit" },
            }}
          >
            <Button className="mx-3">Edit</Button>
          </Link>
          {/* Deactivate Button - Only visible if not deactivated */}
          <Button
            className={`mx-3 ${isNotDeactivated ? "btn-danger" : "btn-danger disabled"}`}
            onClick={() => isNotDeactivated && handleAction("deactivate", row.id)}
            disabled={!isNotDeactivated} // Disable if deactivated
          >
            Deactivate
          </Button>
          {/* Activate Button - Only visible if deactivated */}
          <Button
            className={`mx-3 ${isDeactivated ? "btn-success" : "btn-success disabled"}`}
            onClick={() => isDeactivated && handleAction("activate", row.id)}
            disabled={!isDeactivated} // Disable if not deactivated
          >
            Activate
          </Button>
        </div>
      );
    },
  };
  
  
  return (
    <Container fluid className="p-0">
      <Row>
        <h1 className="mb-4 mt-2 w-50 pl-3">Keyboard Pills Rulesets</h1>
        <Link
          className="d-block ml-auto mb-4 pr-3 text-decoration-none"
          to="/keyboard-prompt/prompts/rulesetOrder/create"
        >
          <Button color="success" className="ml-auto d-flex btn-success">
            <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
              Add Keyboard Pills Ruleset Order
            </p>
          </Button>
        </Link>
      </Row>
      <div>
        <Card style={{ overflow: "hidden", paddingBottom: "100px" }}>
          <CardBody>
            {loading ? (
              <Loading />
            ) : (
              <BootstrapTable
                bootstrap4={true}
                wrapperClasses="table-responsive"
                hover={true}
                bordered={false}
                keyField="id"
                data={filterKeyboardPrompts()}
                columns={tableOrderColumns}
                expandRow={expandRow}
                filter={filterFactory()}
                pagination={paginationFactory({
                  sizePerPage: 10,
                  sizePerPageList: [5, 10, 25, 50],
                })}
                defaultSorted={[{ dataField: "priority", order: "asc" }]}
                remote={{ filter: true }}
                noDataIndication="No results found"
              />
            )}
          </CardBody>
        </Card>
      </div>
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={action}
        onHide={() => setConfirmationModalIsOpen(false)}
      />
      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={successModalHeading}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={errorModalHeading}
        modaltext={failureModalText}
      />
    </Container>
  );
};

export default SegmentPromptsOrder;

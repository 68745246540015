import React, { Fragment, useState, useEffect } from "react";
import TargetingRules from "../TargetingRules";

import ThemeCategoryDetailBoard from "./components/ThemeCategoryDetailBoard";
import ThemeCategoryFormContainer from "./ThemeCategoryFormContainer";
import {
  getThemeCategoryRules,
  saveThemeCategoryRules,
  getCountries,
  getRegions,
  getCities,
} from "../../../utilities/apiUtils/themes";
import { getClients } from "../../../utilities/apiUtils/stickers";

import {
  error,
  errorDescription,
  permissions,
  redirectOnTokenExpiry,
} from "../../../utilities/commonUtil";

import { getKeyboardThemeCategoryDetail } from "../../../utilities/apiUtils/themes";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";

const ThemeCategoryDetails = (props) => {
  let id = props.match.params.categoryID;

  const [loading, setLoading] = useState(false);
  const [themeCategory, setThemeCategory] = useState({});
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  const updateRulesAllowed = () => {
    return allowedPermissions.includes(
      permissions.keyboardThemeCategoryRuleUpdate
    );
  };

  const updateAllowed = () => {
    return allowedPermissions.includes(permissions.keyboardThemeCategoryUpdate);
  };

  const showRules = () => {
    return (
      allowedPermissions.includes(permissions.clientList) &&
      allowedPermissions.includes(permissions.countryList) &&
      allowedPermissions.includes(permissions.regionList) &&
      allowedPermissions.includes(permissions.cityList) &&
      allowedPermissions.includes(permissions.keyboardThemeCategoryRuleView)
    );
  };
  useEffect(() => {
    const fetchThemeCategoryDetails = async (categoryID) => {
      setLoading(true);

      try {
        const response = await getKeyboardThemeCategoryDetail(categoryID);
        if (response.errorCode) {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken
          )
            redirectOnTokenExpiry();
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: errorDescription(response.unexpectedError),
          });
        }
        setThemeCategory(response);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(err.unexpectedError),
        });
      }
    };

    fetchThemeCategoryDetails(props.match.params.categoryID);
  }, []);

  return (
    <div>
      <div style={{ width: "25%", float: "left", position: "fixed" }}>
        <ThemeCategoryDetailBoard id={id} />
      </div>
      <div style={{ width: "65%", float: "right" }}>
        <ThemeCategoryFormContainer
          {...props}
          id={id}
          formName={"Details"}
          updateAllowed={updateAllowed()}
        />
        {showRules() ? (
          <TargetingRules
            id={id}
            getRules={getThemeCategoryRules}
            saveRules={saveThemeCategoryRules}
            getClients={getClients}
            getCountries={getCountries}
            getRegions={getRegions}
            getCities={getCities}
            updateRulesAllowed={updateRulesAllowed()}
          />
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

export default ThemeCategoryDetails;

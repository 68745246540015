import React, { Fragment, useState, useEffect } from "react";
import "react-h5-audio-player/lib/styles.css";
import { retrieveFromLocalStorage } from "../../../../utilities/localStorageUtil";
import {
  Badge,
  Card,
  CardBody,
  CardImg,
  CardTitle,
} from "reactstrap";
import {
  getQuickReplyCategoryDetail,
} from "../../../../utilities/apiUtils/quickReplies";
import Loading from "../../../../components/Loading";
import {
  getStatus,
  getStatusColor,
} from "../../../../utilities/commonUtil";

const QuickReplyCategoryDetailBoard = (props) => {
  const [category, setCategory] = useState({});
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setLoading(true);
    const getCategoryDetails = async () => {
      let res = await getQuickReplyCategoryDetail(props.id);
      setLoading(false);
      setCategory(res);
    };
    getCategoryDetails();
  }, []);

  return (
    <Fragment>
      {loading ? (
        <Loading />
      ) : (
        <Fragment>
          <Card>
            <CardBody>
              <CardTitle tag="h3">
                {category.name}
                <Badge
                  className="float-right"
                  color={getStatusColor(
                    getStatus(category.publishedAt, category.deactivatedAt)
                  )}
                >
                  {getStatus(category.publishedAt, category.deactivatedAt)}
                </Badge>
              </CardTitle>
              ID : {category.ID}
              <br />
              Name : {category.name}
              <br />
              Provider Name : {category.providerName}
              <br />
              Created At : {category.createdAt}
              <br />
              Updated At : {category.updatedAt}
              <br />
              Published At : {category.publishedAt}
              <br />
              Deactivated At : {category.deactivatedAt}
              <br />
            </CardBody>
          </Card>
        </Fragment>
      )}
    </Fragment>
  );
};

export default QuickReplyCategoryDetailBoard;

import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Alert,
  Badge
} from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import {
  fetchPriorityRuleSetBySegment,
  updatePriorityRuleSet,
} from "../../../utilities/apiUtils/segmentCategoryOrder";
import CustomTooltip from "../../../components/customToolTip";

const EditCategoryRulesetForm = () => {
  const [categories, setCategories] = useState([]);
  const [priority, setPriority] = useState("");
  const [description, setDescription] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dragIndex, setDragIndex] = useState(null);
  const history = useHistory();
  const { segmentID } = useParams();

  useEffect(() => {
    if (!segmentID) {
      console.error("Segment ID is missing from the URL");
      setError("Segment ID is missing from the URL");
      return;
    }

    const loadPriorityRuleSet = async () => {
      setLoading(true);
      try {
        const response = await fetchPriorityRuleSetBySegment(segmentID);
        console.log("API Response:", response);

        if (response && response.length > 0) {
          const {
            priority = "",
            description = "",
            categories = [],
          } = response[0];

          setCategories(categories);
          setSelectedCategories(categories); // Initially set selectedCategories to all fetched categories
          setPriority(priority);
          setDescription(description);
        }
      } catch (error) {
        console.error("Error fetching priority rule set:", error);
        setError("Error fetching priority rule set. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    loadPriorityRuleSet();
  }, [segmentID]);

  const getCategoryStatus = (category) => {
    const { publishedAt, deactivatedAt, endDate } = category;
    const now = new Date();
  
    if (endDate && new Date(endDate) < now) {
      return "Expired";
    } else if (publishedAt && !deactivatedAt) {
      return "Active";
    } else if (deactivatedAt) {
      return "Deactivated";
    } else {
      return "Unpublished";
    }
  };

  const getBadgeClass = (status) => {
    switch (status) {
      case "Active":
        return "badge-success";
      case "Deactivated":
        return "badge-danger";
      case "Unpublished":
        return "badge-warning";
      case "Expired":
        return "badge-danger"; 
    }
  };

  const onCategoryDragStart = (index) => {
    setDragIndex(index);
  };

  const onCategoryDragOver = (event, index) => {
    event.preventDefault();
    if (index === dragIndex) return;

    const items = [...selectedCategories];
    const draggedItem = items[dragIndex];
    items.splice(dragIndex, 1);
    items.splice(index, 0, draggedItem);

    setSelectedCategories(items);
    setDragIndex(index);
  };

  const onCategoryDrop = () => {
    setDragIndex(null);
  };

  const handleSubmit = async () => {
    if (priority === "" || selectedCategories.length === 0) {
      setError("Please set a valid priority and select at least one category.");
      return;
    }

    const categoryIds = selectedCategories.map((cat) => cat.id);
    const payload = {
      categoryIDs: categoryIds,
      segmentID: parseInt(segmentID, 10),
      priority: parseInt(priority, 10),
      description: description,
    };

    setLoading(true);
    try {
      await updatePriorityRuleSet(segmentID, payload);
      history.push("/suggestionDrawer/category/segmentOrder");
    } catch (error) {
      console.error("Error updating priority rule set:", error);
      setError("Failed to update priority rule set. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const renderCategoryCards = () => {
    if (selectedCategories.length === 0) {
      return <p>No categories available.</p>;
    }

    const rows = [];
    for (let i = 0; i < selectedCategories.length; i += 5) {
      const rowItems = selectedCategories.slice(i, i + 5);
      rows.push(
        <div
          key={i}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          {rowItems.map((category, index) => {
            const status = getCategoryStatus(category);
            return (
              <div
                key={category.id}
                draggable
                onDragStart={() => onCategoryDragStart(i + index)}
                onDragOver={(e) => onCategoryDragOver(e, i + index)}
                onDrop={onCategoryDrop}
                style={{
                  padding: "1.5rem",
                  border: "1px solid #dee2e6",
                  borderRadius: "0.25rem",
                  backgroundColor: "#f9f9f9",
                  cursor: "grab",
                  width: "18%",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span
                style={{
                  marginBottom: "auto",
                  fontWeight: "bold",
                  fontSize: "1rem",
                }}>
                  {category.name} (ID: {category.id})
                </span>
                <Badge className={`mt-2 ${getBadgeClass(status)}`} style={{ fontSize: "0.85rem", width: "80%" }}>
                  {status}
                </Badge>
              </div>
            );
          })}
        </div>
      );
    }
    return rows;
  };

  return (
    <Container>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <h2
                style={{
                  textAlign: "center",
                  marginBottom: "20px",
                  color: "#4A4A4A",
                }}
              >
                Edit Priority Rule Set
              </h2>
              {loading ? (
                <Spinner color="primary" />
              ) : error ? (
                <Alert color="danger">{error}</Alert>
              ) : (
                <>
                  <FormGroup>
                    <Label for="priority">Priority:</Label>
                    <CustomTooltip
                      target="priority-tooltip"
                      content={
                        <>
                          Specifies the order in which this rule set is applied,
                          with higher priority rules taking precedence over
                          lower priority ones.
                        </>
                      }
                      placement="right"
                    />
                    <Input
                      type="number"
                      id="priority"
                      value={priority}
                      onChange={(e) => setPriority(e.target.value)}
                      min="1"
                      placeholder="Enter priority level"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="description">
                      Description <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      type="text"
                      id="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder="Enter a description"
                    />
                  </FormGroup>
                  <h3>Categories</h3>
                  <div
                    style={{
                      border: "1px solid #dee2e6",
                      borderRadius: "0.25rem",
                      padding: "1rem",
                    }}
                  >
                    {renderCategoryCards()}
                  </div>
                  <Button
                    color="primary"
                    onClick={handleSubmit}
                    style={{ marginTop: "1rem" }}
                  >
                    Update Rule Set
                  </Button>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EditCategoryRulesetForm;

import React, { useState, useEffect } from "react";
import { Card, CardBody, Container, Label } from "reactstrap";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  Row,
  Col,
} from "reactstrap";
import { promptTemplatesTableColumns } from "./tableColumn";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ResponseModal from "../ResponseModal";
import { failureModalHeading, successModalHeading } from "../../../config";
import ConfirmationModal from "../ConfirmationModal";
import { GetAIPromptTemplates, activatePromptTemplate, deactivatePromptTemplate, publishPromptTemplate } from "../../../utilities/apiUtils/genAIDashboard";
import { allActions, error, errorDescription } from "../../../utilities/commonUtil";
import { formatTime, formatVariants, promptTemplateResponses, showActivatePromptTemplateButton, showCreatePromptTemplateButton, showDeactivatePromptTemplateButton, showDetailPromptTemplateButton, showPublishPromptTemplateButton, showCreatePromptTemplateVariantButton } from "../../../config/configGenAIDashboard";

const { SearchBar } = Search;

const ExpandableRowsTable = () => {
  const [promptTemplatesData, setPromptTemplatesData] = useState([]);
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [action, setAction] = useState("");
  const [status, setStatus] = useState({ value: "all", label: "All" });
  const [id, setId] = useState();

  const fetchPromptTemplates = async (status) => {
    try {
      let response = await GetAIPromptTemplates()
      if (!response || response?.length === 0) {
        setPromptTemplatesData([]);
        return;
      }
      if (response.length > 0) {
        if (status === "all")
          setPromptTemplatesData(response);
        else if (status === "published") {
          const publishedTemplates = response.filter(template => template.publishedAt != null);
          setPromptTemplatesData(publishedTemplates);
        }
        else if (status === "activated") {
          const activatedTemplates = response.filter(template => template.deactivatedAt == null);
          setPromptTemplatesData(activatedTemplates);
        }
        else if (status === "deactivated") {
          const deactivatedTemplates = response.filter(template => template.deactivatedAt != null);
          setPromptTemplatesData(deactivatedTemplates);
        }
      } else {
        showErrorModal(errorDescription(response.errorCode));
      }
    } catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  }

  useEffect(() => {
    if (status?.value) {
      fetchPromptTemplates(status.value);
    }
  }, [status]);

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    await fetchPromptTemplates(status.value);
  };

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let disableDeactivateButton = (row) => {
    if (row.deactivatedAt !== null || row.publishedAt === null) return true;
    return false;
  };

  let disablePublishButton = (row) => {
    if (row.publishedAt === null) return false;
    return true;
  };

  let disableActivateButton = (row) => {
    if (row.deactivatedAt === null || row.publishedAt === null) return true;
    return false;
  };

  let activatePrompt = async (id) => {
    try {
      let response = await activatePromptTemplate(id);
      if (response.status === "aiPromptTemplateActivated") {
        showSuccessModal(promptTemplateResponses.activatedSuccessfully);
      } else {
        if (response.errorCode) {
          showErrorModal(response.errorCode)
        }
      }
    } catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  };

  let deactivatePrompt = async (id) => {
    try {
      let response = await deactivatePromptTemplate(id);
      if (response.status === "aiPromptTemplateDeactivated") {
        showSuccessModal(promptTemplateResponses.deactivatedSuccessfully);
      } else {
        if (response.errorCode) {
          showErrorModal(response.errorCode)
        }
      }
    } catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  };

  let publishPrompt = async (id) => {
    try {
      let response = await publishPromptTemplate(id);
      if (response.status === "aiPromptTemplatePublished") {
        showSuccessModal(promptTemplateResponses.publishedSuccessfully);
      } else {
        if (response.errorCode) {
          showErrorModal(response.errorCode)
        }
      }
    } catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  };

  let updateStatus = (update, id) => {
    if (update === "activate") {
      activatePrompt(id);
    }
    else if (update === "deactivate") {
      deactivatePrompt(id);
    }
    else if (update === "publish") {
      publishPrompt(id);
    }
  };

  let setConfirmationModalState = (event, row) => {
    setAction(event);
    setConfirmationModalIsOpen(true);
    setId(row.id);
  };

  let handleStatusChange = (status) => {
    setStatus({ value: status.value, label: status.label });
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <ul>
          <li>{`Variants : ${row.variants?.length > 0 ? formatVariants(row?.variants) : 'None'}`}</li>
          <li>{`Created At : ${formatTime(row.createdAt)}`}</li>
          <li>{`Updated At : ${formatTime(row.updatedAt)}`}</li>
          <li>{`Published At : ${formatTime(row.publishedAt)}`}</li>
          <li>{`Deactivated At : ${formatTime(row.deactivatedAt)}`}</li>
        </ul>
        {showDetailPromptTemplateButton() ? (
          <Link to={{
            pathname: `/gen-ai/prompt-templates/details/${row.id}`
          }}>
            <Button className="mx-3">
              Details
            </Button>
          </Link>
        ) : null}
        {showDeactivatePromptTemplateButton() ? (
          <Button
            className="mx-3 btn-danger"
            disabled={disableDeactivateButton(row)}
            onClick={(event) =>
              setConfirmationModalState(event.target.innerHTML, row)
            }
          >
            Deactivate
          </Button>
        ) : null}
        {
          showActivatePromptTemplateButton() ? (
            <Button
              className="mx-3 btn-success"
              disabled={disableActivateButton(row)}
              onClick={(event) =>
                setConfirmationModalState(event.target.innerHTML, row)
              }
            >
              Activate
            </Button>
          ) : null
        }
        {
          showPublishPromptTemplateButton() ? (
            <Button className="mx-3 btn-secondary"
              disabled={disablePublishButton(row)}
              onClick={(event) =>
                setConfirmationModalState(event.target.innerHTML, row)
              }
            >
              Publish
            </Button>
          ) : null
        }
        {
          showCreatePromptTemplateVariantButton() ? (
            <Link
              to={{
                pathname: `/gen-ai/prompt-templates/create?type=variant&basePromptTemplateId=${row.id}`
              }}
            >
              <Button className="mx-3 btn-success">
                Create Language Variant
              </Button>
            </Link>
          ) : null
        }
      </div >
    ),
  };

  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={3} sm={12}>
            <Label>Status</Label>
            <Select
              className="react-select-container mb-4"
              classNamePrefix="react-select"
              placeholder="Select Status"
              onChange={handleStatusChange}
              options={[
                { value: "all", label: "All" },
                { value: "activated", label: "Activated" },
                { value: "deactivated", label: "Deactivated" },
                { value: "published", label: "Published" },
              ]}
              value={status}
            />
          </Col>
        </Row>
        <ToolkitProvider keyField="id" data={promptTemplatesData} columns={promptTemplatesTableColumns} search>
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} />
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                bordered={false}
                keyField="id"
                expandRow={expandRow}
                pagination={paginationFactory({
                  sizePerPage: 10,
                  sizePerPageList: [5, 10, 25, 50],
                })}
                defaultSorted={[{
                  dataField: 'id',
                  order: 'asc'
                }]}
              />
            </div>
          )}
        </ToolkitProvider>
      </CardBody>
      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={successModalHeading}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={failureModalHeading}
        modaltext={failureModalText}
      />
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={action}
        onConfirmationTrue={() => {
          setConfirmationModalIsOpen(false);
          if (action === allActions.deactivate) updateStatus("deactivate", id);
          else if (action === allActions.activate) updateStatus("activate", id);
          else if (action === allActions.publish) updateStatus("publish", id);
        }}
        onHide={() => setConfirmationModalIsOpen(false)}
      />
    </Card>
  );
};

const PromptTemplates = () => {
  return (
    <Container fluid className="p-0">
      <div className="d-flex justify-content-between">
        <h1 className="h3">Prompt Templates</h1>
        {showCreatePromptTemplateButton() ? (
          <div>
            <Link
              to={{
                pathname: `/gen-ai/prompt-templates/create`
              }}
            >
              <Button
                style={{ fontSize: "1rem" }}
                className="mx-3 btn-success mb-3"
              >
                Create a New Prompt Template
              </Button>
            </Link>
          </div>
        ) : null}
      </div>
      <ExpandableRowsTable />
    </Container>
  )
}
export default PromptTemplates;
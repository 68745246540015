/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Container,
  Button,
} from "reactstrap";
import Select from "react-select";
import {
  error,
  errorDescription,
  redirectionOnTokenExpiry,
  redirectTo,
  validateHexCode,
  onAppVersionInput,
} from "../../../utilities/commonUtil";
import {
  fetchGifPacks,
  fetchStickerPacks,
  fetchOfferZones,
} from "../../../utilities/apiUtils";
import { validateDetailsForm } from "../../../utilities/apiUtils/contentPrompts";
import { fetchAllKeyboardThemes } from "../../../utilities/apiUtils/themes";
import {
  updateContentUpdatePrompt,
  createContentUpdatePrompt,
} from "../../../utilities/apiUtils/contentPrompts";
import ResponseModal from "../ResponseModal";
import Loading from "../../../components/Loading";
import { getBrandCampaigns } from "../../../utilities/apiUtils/brandCampaign";
import { defaultBrandCampaign } from "../../../config/UserConfig";
import {
  resourceTypes,
  appVersionInputLowerLimit,
  appVersionInputUpperLimit,
  ContentUpdatePromptCreatedSuccessfullyText,
  ContentUpdatePromptUpdatedSuccessfullyText,
} from "../../../config/contentUpdatePrompts";
import { successModalHeading, errorModalHeading } from "../../../config/config";
const ManageContentUpdatePrompts = (props) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    formType: "create",
    formHeading: "Create Content Update Prompt",
    resourceTypes: resourceTypes,
    selectedResourceType: {},
    resources: [],
    selectedResource: {},
    contentUpdatePromptId: "",
    searchString: "",
    text: "",
    priority: "",
    endAt: "",
    minAppVersion: "",
    startAt: "",
    contentUpdatedAt: "",
    endAtEpoch: "",
    startAtEpoch: "",
    contentUpdatedAtEpoch: "",
    contentPromptDetail: {},
    keyboardThemes: [],
    offersZone: [],
    additionalDetails: {
      brandCampaignId: "",
      maxCount: "",
      minKBSessionCount: "",
      repeatKBSessionCount: "",
      textMaxLines: "",
      logoURL: "",
      backgroundColor: { value: "", valid: true },
      textColor: { value: "", valid: true },
      text: "",
      ctaTextColor: { value: "", valid: true },
      ctaBackgroundColor: { value: "", valid: true },
      ctaText: "",
      impressionTrackers: "",
    },
  });
  const [brandCampaigns, setBrandCampaigns] = useState([]);
  const [selectedBrandCampaign, setSelectedBrandCampaign] =
    useState(defaultBrandCampaign);
  const [successModal, setSuccessModal] = useState({
    display: false,
    text: "",
  });
  const [failureModal, setFailureModal] = useState({
    display: false,
    text: "",
  });
  useEffect(() => {
    if (props.location.state.formType === "edit") {
      let { formType, contentUpdatePromptId, contentPromptDetail } =
        props.location.state;
      let selectedResourceType = {};
      for (let i = 0; i < form.resourceTypes.length; i++) {
        if (form.resourceTypes[i].value === contentPromptDetail.type) {
          selectedResourceType = form.resourceTypes[i];
        }
      }
      let startTime = formatDateForPicker(contentPromptDetail.startAt);
      let endTime = formatDateForPicker(contentPromptDetail.endAt);
      let contentUpdatedAtTime = formatDateForPicker(
        contentPromptDetail.contentUpdatedAt
      );
      let startValue = new Date(startTime);
      let endValue = new Date(endTime);
      let contentUpdatedValue = new Date(contentUpdatedAtTime);
      let startAtEpoch = startValue.getTime() / 1000;
      let endAtEpoch = endValue.getTime() / 1000;
      let contentUpdatedAtEpoch = contentUpdatedValue.getTime() / 1000;
      setForm({
        ...form,
        formType: formType,
        formHeading: "Edit Content Update Prompt",
        selectedResourceType: selectedResourceType,
        contentUpdatePromptId: contentUpdatePromptId,
        resources: [],
        selectedResource: {},
        startAt: startTime,
        endAt: endTime,
        contentUpdatedAt: contentUpdatedAtTime,
        startAtEpoch: startAtEpoch,
        endAtEpoch: endAtEpoch,
        contentUpdatedAtEpoch: contentUpdatedAtEpoch,
        contentPromptDetail: contentPromptDetail,
        priority: contentPromptDetail.priority,
        minAppVersion: contentPromptDetail.minAppVersion,
        additionalDetails: {
          maxCount: contentPromptDetail.additionalDetails.maxCount,
          minKBSessionCount:
            contentPromptDetail.additionalDetails.minKBSessionCount,
          repeatKBSessionCount:
            contentPromptDetail.additionalDetails.repeatKBSessionCount,
          textMaxLines: contentPromptDetail.additionalDetails.textMaxLines,
          logoURL: contentPromptDetail.additionalDetails.logoURL,
          backgroundColor: {
            value: contentPromptDetail.additionalDetails.backgroundColor,
            valid: true,
          },
          textColor: {
            value: contentPromptDetail.additionalDetails.textColor,
            valid: true,
          },
          text: contentPromptDetail.additionalDetails
            ? JSON.stringify(contentPromptDetail.additionalDetails.text)
            : "",
          ctaTextColor: {
            value: contentPromptDetail.additionalDetails.ctaTextColor,
            valid: true,
          },
          ctaBackgroundColor: {
            value: contentPromptDetail.additionalDetails.ctaBackgroundColor,
            valid: true,
          },
          ctaText:
            contentPromptDetail.additionalDetails.ctaText !== null
              ? JSON.stringify(contentPromptDetail.additionalDetails.ctaText)
              : "",
          impressionTrackers:
            contentPromptDetail.additionalDetails.impressionTrackers !== null
              ? JSON.stringify(
                  contentPromptDetail.additionalDetails.impressionTrackers
                )
              : "",
        },
      });
    } else if (props.location.state.formType === "duplicate") {
      let { formType, contentUpdatePromptId, contentPromptDetail } =
        props.location.state;
      let selectedResourceType = {};
      for (let i = 0; i < form.resourceTypes.length; i++) {
        if (form.resourceTypes[i].value === contentPromptDetail.type) {
          selectedResourceType = form.resourceTypes[i];
        }
      }
      let startTime = formatDateForPicker(contentPromptDetail.startAt);
      let endTime = formatDateForPicker(contentPromptDetail.endAt);
      let contentUpdatedAtTime = formatDateForPicker(
        contentPromptDetail.contentUpdatedAt
      );
      let startValue = new Date(startTime);
      let endValue = new Date(endTime);
      let contentUpdatedValue = new Date(contentUpdatedAtTime);
      let startAtEpoch = startValue.getTime() / 1000;
      let endAtEpoch = endValue.getTime() / 1000;
      let contentUpdatedAtEpoch = contentUpdatedValue.getTime() / 1000;
      setForm({
        ...form,
        formType: formType,
        formHeading: "Create Content Update Prompt",
        selectedResourceType: selectedResourceType,
        contentUpdatePromptId: contentUpdatePromptId,
        resources: [],
        selectedResource: {},
        startAt: startTime,
        endAt: endTime,
        contentUpdatedAt: contentUpdatedAtTime,
        startAtEpoch: startAtEpoch,
        endAtEpoch: endAtEpoch,
        contentUpdatedAtEpoch: contentUpdatedAtEpoch,
        contentPromptDetail: contentPromptDetail,
        priority: contentPromptDetail.priority,
        minAppVersion: contentPromptDetail.minAppVersion,
        additionalDetails: {
          maxCount: contentPromptDetail.additionalDetails.maxCount,
          minKBSessionCount:
            contentPromptDetail.additionalDetails.minKBSessionCount,
          repeatKBSessionCount:
            contentPromptDetail.additionalDetails.repeatKBSessionCount,
          textMaxLines: contentPromptDetail.additionalDetails.textMaxLines,
          logoURL: contentPromptDetail.additionalDetails.logoURL,
          backgroundColor: {
            value: contentPromptDetail.additionalDetails.backgroundColor,
            valid: true,
          },
          textColor: {
            value: contentPromptDetail.additionalDetails.textColor,
            valid: true,
          },
          text: contentPromptDetail.additionalDetails
            ? JSON.stringify(contentPromptDetail.additionalDetails.text)
            : "",
          ctaTextColor: {
            value: contentPromptDetail.additionalDetails.ctaTextColor,
            valid: true,
          },
          ctaBackgroundColor: {
            value: contentPromptDetail.additionalDetails.ctaBackgroundColor,
            valid: true,
          },
          ctaText:
            contentPromptDetail.additionalDetails.ctaText !== null
              ? JSON.stringify(contentPromptDetail.additionalDetails.ctaText)
              : "",
          impressionTrackers:
            contentPromptDetail.additionalDetails.impressionTrackers !== null
              ? JSON.stringify(
                  contentPromptDetail.additionalDetails.impressionTrackers
                )
              : "",
        },
      });
    }
    fetchBrandCampaigns();
  }, [props]);

  useEffect(() => {
    getPromptResoureDetails(form.selectedResourceType.value);
  }, [form.selectedResourceType]);

  let getPromptResoureDetails = (resourceType) => {
    switch (resourceType) {
      case "stickerPack":
        getStickerPacks();
        break;
      case "gifPack":
        getGifPacks();
        break;
      case "keyboardTheme":
        getKeyboardThemes();
        break;
      case "offersZone":
        getOffersZone();
        break;
      default:
        return;
    }
  };

  let getStickerPacks = async () => {
    setLoading(true);
    try {
      let response = await fetchStickerPacks();
      let { contentPromptDetail, formType } = form;
      if (response.stickerPacks) {
        let resources = [];
        let selectedResource = {};
        for (let i = 0; i < response.stickerPacks.length; i++) {
          let stickerPacks = response.stickerPacks;
          let resource = {};
          if (
            formType === "edit" &&
            parseInt(contentPromptDetail.additionalDetails.stickerPackId) ===
              stickerPacks[i].id
          ) {
            selectedResource["label"] = stickerPacks[i].name;
            selectedResource["value"] = stickerPacks[i].id;
          }

          resource["label"] = stickerPacks[i].name;
          resource["value"] = stickerPacks[i].id;
          resources.push(resource);
        }
        setForm({
          ...form,
          resources: resources,
          selectedResource: selectedResource,
          stickerPacks: response,
        });
      } else if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        ) {
          redirectionOnTokenExpiry();
        }
        setLoading(false);
        showErrorModal(errorDescription(response.errorCode));
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      showErrorModal(errorDescription(errorDescription(error.unexpectedError)));
    }
  };

  let getGifPacks = async () => {
    setLoading(true);
    try {
      let response = await fetchGifPacks();
      let { contentPromptDetail, formType } = form;
      if (response.gifPacks) {
        let resources = [];
        let selectedResource = {};
        for (let i = 0; i < response.gifPacks.length; i++) {
          let gifPacks = response.gifPacks;
          let resource = {};
          if (
            formType === "edit" &&
            parseInt(contentPromptDetail.additionalDetails.gifPackId) ===
              gifPacks[i].id
          ) {
            selectedResource["label"] = gifPacks[i].name;
            selectedResource["value"] = gifPacks[i].id;
          }
          resource["label"] = gifPacks[i].name;
          resource["value"] = gifPacks[i].id;
          resources.push(resource);
        }
        setForm({
          ...form,
          resources: resources,
          selectedResource: selectedResource,
          gifPacks: response,
        });
        setLoading(false);
      } else if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        ) {
          redirectionOnTokenExpiry();
        }
        setLoading(false);
        showErrorModal(errorDescription(response.errorCode));
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      showErrorModal(errorDescription(error.unexpectedError));
    }
  };
  let getOffersZone = async () => {
    setLoading(true);
    try {
      let response = await fetchOfferZones();
      let { contentPromptDetail, formType } = form;
      if (response.offers) {
        let resources = [];
        let selectedResource = {};
        for (let i = 0; i < response.offers.length; i++) {
          let offers = response.offers;
          let resource = {};
          if (
            formType === "edit" &&
            contentPromptDetail.additionalDetails.offerId === offers[i].id
          ) {
            selectedResource["label"] = offers[i].name;
            selectedResource["value"] = offers[i].id;
          }
          resource["label"] = offers[i].name;
          resource["value"] = offers[i].id;
          resources.push(resource);
        }
        setForm({
          ...form,
          resources: resources,
          selectedResource: selectedResource,
          gifPacks: response,
        });
        setLoading(false);
      } else if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        ) {
          redirectionOnTokenExpiry();
        }
        setLoading(false);
        showErrorModal(errorDescription(response.errorCode));
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      showErrorModal(errorDescription(error.unexpectedError));
    }
  };
  let getKeyboardThemes = async () => {
    setLoading(true);
    try {
      let response = await fetchAllKeyboardThemes();
      let { contentPromptDetail, formType } = form;
      if (response.keyboardThemes) {
        let resources = [];
        let selectedResource = {};
        for (let i = 0; i < response.keyboardThemes.length; i++) {
          let resource = {};
          if (
            formType === "edit" &&
            parseInt(contentPromptDetail.additionalDetails.themePackId) ===
              response.keyboardThemes[i].ID
          ) {
            selectedResource["label"] = response.keyboardThemes[i].name;
            selectedResource["value"] = response.keyboardThemes[i].ID;
          }
          resource["label"] = response.keyboardThemes[i].name;
          resource["value"] = response.keyboardThemes[i].ID;
          resources.push(resource);
        }
        setForm({
          ...form,
          resources: resources,
          selectedResource: selectedResource,
          keyboardThemes: response.keyboardThemes,
        });
        setLoading(false);
      } else if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        ) {
          redirectionOnTokenExpiry();
        }
        setLoading(false);
        showErrorModal(errorDescription(response.errorCode));
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      showErrorModal(errorDescription(error.unexpectedError));
    }
  };

  let fetchBrandCampaigns = async () => {
    setLoading(true);
    try {
      let response = await getBrandCampaigns();
      if (response.length) {
        let brandCampaigns = response;
        let { contentPromptDetail, formType } = props.location.state;
        brandCampaigns = [defaultBrandCampaign].concat(brandCampaigns);
        for (let i = 0; i < brandCampaigns.length; i++) {
          if (
            formType === "edit" &&
            contentPromptDetail.additionalDetails.brandCampaignId !== null &&
            brandCampaigns[i].value ===
              contentPromptDetail.additionalDetails.brandCampaignId
          ) {
            setSelectedBrandCampaign(brandCampaigns[i]);
          }
        }
        setBrandCampaigns(brandCampaigns);
        setLoading(false);
      } else if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        ) {
          redirectionOnTokenExpiry();
          setLoading(false);
        } else {
          setLoading(false);
          showErrorModal(errorDescription(response.errorCode));
        }
      }
    } catch (err) {
      setLoading(false);
      showErrorModal(errorDescription(error.unexpectedError));
    }
  };

  let showAlert = (elementId) => {
    document.getElementById(elementId).classList.remove("d-none");
  };

  let hideAlert = (elementId) => {
    document.getElementById(elementId).classList.add("d-none");
  };

  let handleSubmit = async (event) => {
    event.preventDefault();
    let {
      formType,
      contentUpdatePromptId,
      selectedResource,
      selectedResourceType,
      endAtEpoch,
      startAtEpoch,
      contentUpdatedAtEpoch,
      priority,
      additionalDetails,
      minAppVersion,
    } = form;

    if (Object.keys(selectedResourceType).length === 0) {
      showAlert("invalidResourceType");
      return;
    }

    if (
      (selectedResourceType.value === "stickerPack" ||
        selectedResourceType.value === "gifPack" ||
        selectedResourceType.value === "keyboardTheme" ||
        selectedResourceType.value === "offersZone") &&
      Object.keys(selectedResource).length === 0
    ) {
      showAlert("invalidResource");
      return;
    }

    let updatedContentUpdatePrompt = new FormData();
    let prompts = validateDetailsForm(additionalDetails);
    if (selectedBrandCampaign.value !== "none") {
      prompts.data["brandCampaignId"] = selectedBrandCampaign.value;
    }
    updatedContentUpdatePrompt["type"] = selectedResourceType.value;
    updatedContentUpdatePrompt["priority"] = parseInt(priority);
    updatedContentUpdatePrompt["endAT"] = endAtEpoch.toString();
    updatedContentUpdatePrompt["startAT"] = startAtEpoch.toString();
    updatedContentUpdatePrompt["minAppVersion"] = parseFloat(minAppVersion);
    updatedContentUpdatePrompt["contentUpdatedAT"] =
      contentUpdatedAtEpoch.toString();
    if (!prompts.isValid) {
      if (!prompts.hasValidText) showAlert("invalidJsonTextAlert");
      if (!prompts.hasValidCtaText) showAlert("invalidCtaTextJsonAlert");
      if (!prompts.hasValidImpressionTrackers)
        showAlert("invalidImpressionTrackersJsonAlert");
      return;
    }
    updatedContentUpdatePrompt["additionalDetails"] = prompts.data;
    if (selectedResourceType.value === "stickerPack")
      updatedContentUpdatePrompt["additionalDetails"]["stickerPackId"] =
        selectedResource.value;

    if (selectedResourceType.value === "gifPack")
      updatedContentUpdatePrompt["additionalDetails"]["gifPackId"] =
        selectedResource.value;
    if (selectedResourceType.value === "keyboardTheme")
      updatedContentUpdatePrompt["additionalDetails"]["themePackId"] =
        selectedResource.value;
    if (selectedResourceType.value === "offersZone")
      updatedContentUpdatePrompt["additionalDetails"]["offerId"] =
        selectedResource.value;
    if (formType === "edit") {
      try {
        let response = await updateContentUpdatePrompt(
          updatedContentUpdatePrompt,
          contentUpdatePromptId
        );
        if (response.errorCode || response.status || response.message)
          handleResponse(response);
      } catch (err) {
        showErrorModal(errorDescription(error.unexpectedError));
      }
    } else {
      try {
        let response = await createContentUpdatePrompt(
          updatedContentUpdatePrompt
        );
        handleResponse(response);
      } catch (err) {
        showErrorModal(errorDescription(error.unexpectedError));
      }
    }
  };

  let formatDateForPicker = (dateTime) => {
    let dateTimeArray = dateTime.split(" ");
    return dateTimeArray[0];
  };

  let handleResponse = (response) => {
    let { formType } = form;
    let text;
    if (response.status === "success") {
      if (formType === "edit") {
        text = ContentUpdatePromptUpdatedSuccessfullyText;
      } else {
        text = ContentUpdatePromptCreatedSuccessfullyText;
      }
      setSuccessModal({ text: text, display: true });
      return;
    } else if (response.errorCode !== undefined) {
      setFailureModal({ text: response.errorDescription, display: true });
    } else if (response.message !== undefined) {
      setFailureModal({ text: response.message, display: true });
    } else {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  };

  let handleResourceTypeChange = (select) => {
    hideAlert("invalidResourceType");
    let selectedResourceType = {};
    if (select !== null) {
      selectedResourceType["value"] = select.value;
      selectedResourceType["label"] = select.label;
    }
    setForm({ ...form, selectedResourceType: selectedResourceType });
  };

  let handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  let handleResourceChange = (option) => {
    hideAlert("invalidResource");
    setForm({ ...form, selectedResource: option });
  };

  let handleDateChange = (e) => {
    let targetedValue = new Date(e.target.value);
    if (e.target.name === "contentUpdatedDate") {
      setForm({
        ...form,
        contentUpdatedAt: e.target.value,
        contentUpdatedAtEpoch: targetedValue.getTime() / 1000,
      });
    } else if (e.target.name === "startDate") {
      setForm({
        ...form,
        startAt: e.target.value,
        startAtEpoch: targetedValue.getTime() / 1000,
      });
    } else {
      setForm({
        ...form,
        endAt: e.target.value,
        endAtEpoch: targetedValue.getTime() / 1000,
      });
    }
  };

  let successModalClose = () => {
    if (props.location.state.formType === "edit") {
      setSuccessModal({ ...successModal, display: false });
      redirectTo(`/cp/content-prompts/${form.contentUpdatePromptId}`);
    } else {
      setSuccessModal({ ...successModal, display: false });
      redirectTo(`/cp/content-prompts/`);
    }
  };

  let failureModalClose = () => {
    setFailureModal({ ...failureModal, display: false });
  };

  let showErrorModal = (error_code) => {
    setFailureModal({ text: error_code, display: true });
  };

  let handlePromptDetailsChange = (event) => {
    let { additionalDetails } = form;
    if (additionalDetails == null) {
      additionalDetails = {};
    }

    additionalDetails[event.target.name] = event.target.value;
    setForm({ ...form, additionalDetails: additionalDetails });
  };

  const handleHexCodeChange = (event) => {
    let { additionalDetails } = form;
    const { name, value } = event.target;
    additionalDetails[name] = {
      value: value,
      valid: validateHexCode(value),
    };
    setForm({ ...form, additionalDetails: additionalDetails });
  };

  let handleBrandChange = (select) => {
    let selectedBrandCampaign = {};
    selectedBrandCampaign["value"] = select.value;
    selectedBrandCampaign["label"] = select.label;
    setSelectedBrandCampaign(selectedBrandCampaign);
  };

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <>
          <h3 className="mb-3">{form.formHeading}</h3>
          <Card>
            <CardBody>
              <Form onSubmit={(e) => handleSubmit(e)}>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        Select Resource Type{" "}
                        <span className="text-danger">*</span>{" "}
                        <small
                          id="invalidResourceType"
                          className="text-danger d-none"
                        >
                          (You must select atleast one resource type)
                        </small>
                      </Label>
                      <Select
                        id="resourceType"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        name="resourceTypes"
                        onChange={handleResourceTypeChange}
                        value={
                          form.selectedResourceType !== ""
                            ? form.selectedResourceType
                            : ""
                        }
                        options={form.resourceTypes}
                        isDisabled={form.formType === "edit" ? true : false}
                        required
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        Priority <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        name="priority"
                        onChange={handleChange}
                        placeholder="Enter Priority"
                        defaultValue={form.priority}
                        min={0}
                        required
                      />
                    </FormGroup>
                  </Col>
                  {
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          Select Resource <span className="text-danger">*</span>{" "}
                          <small
                            id="invalidResource"
                            className="text-danger d-none"
                          >
                            (Resource is a required field)
                          </small>
                        </Label>
                        <Select
                          className="react-select-container dropdown"
                          classNamePrefix="react-select"
                          name="resource"
                          id="resource"
                          onChange={handleResourceChange}
                          value={form.selectedResource}
                          options={form.resources}
                          required
                        />
                      </FormGroup>
                    </Col>
                  }
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        Min App Version{" "}
                        <small
                          id="invalidMinVersionAlert"
                          className="text-danger d-none"
                        >
                          (Should be greater than 0 and less than Max App
                          Version)
                        </small>
                        <br />
                      </Label>
                      <Input
                        type="number"
                        name="minAppVersion"
                        value={form.minAppVersion}
                        placeholder="Min App Version"
                        onFocus={() => hideAlert("invalidMinVersionAlert")}
                        onChange={handleChange}
                        min={appVersionInputLowerLimit}
                        max={appVersionInputUpperLimit}
                        onInput={onAppVersionInput}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        Start Date: <span className="text-danger">*</span>{" "}
                        <small
                          id="invalidStartDate"
                          className="text-danger d-none"
                        >
                          (Start Date is a required field)
                        </small>
                      </Label>

                      <Input
                        type="date"
                        name="startDate"
                        onChange={handleDateChange}
                        defaultValue={form.startAt !== "" ? form.startAt : ""}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        End Date: <span className="text-danger">*</span>{" "}
                        <small
                          id="invalidEndDate"
                          className="text-danger d-none"
                        >
                          (End Date is a required field)
                        </small>
                      </Label>
                      <Input
                        type="date"
                        name="endDate"
                        onChange={handleDateChange}
                        defaultValue={form.endAt !== "" ? form.endAt : ""}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        Content Updated Date:{" "}
                        <span className="text-danger">*</span>{" "}
                        <small
                          id="invalidContentUpdatedDate"
                          className="text-danger d-none"
                        >
                          (Content Updated is a required field)
                        </small>
                      </Label>

                      <Input
                        type="date"
                        name="contentUpdatedDate"
                        onChange={handleDateChange}
                        defaultValue={
                          form.contentUpdatedAt !== ""
                            ? form.contentUpdatedAt
                            : ""
                        }
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <h2 className="d-block mx-auto mt-4 mb-4">
                    Additional Details
                  </h2>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        Max Count<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        name="maxCount"
                        onChange={handlePromptDetailsChange}
                        placeholder="Enter MaxCount"
                        defaultValue={
                          form.additionalDetails.maxCount != null
                            ? form.additionalDetails.maxCount
                            : ""
                        }
                        min={1}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        Repeat KB Session Count{" "}
                        <span className="text-danger">*</span>{" "}
                      </Label>
                      <Input
                        type="number"
                        name="repeatKBSessionCount"
                        onChange={handlePromptDetailsChange}
                        placeholder="Enter RepeatKBSessionCount"
                        defaultValue={
                          form.additionalDetails.repeatKBSessionCount != null
                            ? form.additionalDetails.repeatKBSessionCount
                            : ""
                        }
                        min={1}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        Minimum KB Session Count{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        name="minKBSessionCount"
                        onChange={handlePromptDetailsChange}
                        placeholder="Enter MinKBSessionCount"
                        defaultValue={
                          form.additionalDetails.minKBSessionCount != null
                            ? form.additionalDetails.minKBSessionCount
                            : ""
                        }
                        min={1}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        Text Maximum Lines{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        name="textMaxLines"
                        onChange={handlePromptDetailsChange}
                        placeholder="Enter TextMaxLines"
                        defaultValue={
                          form.additionalDetails.textMaxLines != null
                            ? form.additionalDetails.textMaxLines
                            : ""
                        }
                        min={1}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Brand Campaign Id</Label>
                      <Select
                        id="brand"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        name="brand"
                        onChange={(select) => handleBrandChange(select)}
                        value={selectedBrandCampaign}
                        options={brandCampaigns}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Logo URL</Label>
                      <Input
                        type="text"
                        name="logoURL"
                        onChange={handlePromptDetailsChange}
                        placeholder="Enter LogoURL"
                        defaultValue={
                          form.additionalDetails.logoURL != null
                            ? form.additionalDetails.logoURL
                            : ""
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        Background Color <span className="text-danger">*</span>
                        {form.additionalDetails.backgroundColor.valid ? (
                          <div
                            className="displayColor ml-2"
                            style={{
                              backgroundColor:
                                form.additionalDetails.backgroundColor.value,
                            }}
                          ></div>
                        ) : (
                          <small className="text-danger ml-2">
                            {"Invalid Hex Code"}
                          </small>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="backgroundColor"
                        onChange={(event) => handleHexCodeChange(event)}
                        placeholder="Enter BackgroundColor"
                        defaultValue={
                          form.additionalDetails.backgroundColor.value != null
                            ? form.additionalDetails.backgroundColor.value
                            : ""
                        }
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        Text Color <span className="text-danger">*</span>
                        {form.additionalDetails.textColor.valid ? (
                          <div
                            className="displayColor ml-2"
                            style={{
                              backgroundColor:
                                form.additionalDetails.textColor.value,
                            }}
                          ></div>
                        ) : (
                          <small className="text-danger ml-2">
                            {"Invalid Hex Code"}
                          </small>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="textColor"
                        onChange={handleHexCodeChange}
                        placeholder="Enter TextColor"
                        defaultValue={
                          form.additionalDetails.textColor.value != null
                            ? form.additionalDetails.textColor.value
                            : ""
                        }
                        required
                      />
                    </FormGroup>
                  </Col>

                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        Cta Background Color{" "}
                        <span className="text-danger">*</span>
                        {form.additionalDetails.ctaBackgroundColor.valid ? (
                          <div
                            className="displayColor ml-2"
                            style={{
                              backgroundColor:
                                form.additionalDetails.ctaBackgroundColor.value,
                            }}
                          ></div>
                        ) : (
                          <small className="text-danger ml-2">
                            {"Invalid Hex Code"}
                          </small>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="ctaBackgroundColor"
                        onChange={handleHexCodeChange}
                        placeholder="Enter CtaBackgroundColor"
                        defaultValue={
                          form.additionalDetails.ctaBackgroundColor.value !=
                          null
                            ? form.additionalDetails.ctaBackgroundColor.value
                            : ""
                        }
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        Cta Text Color <span className="text-danger">*</span>
                        {form.additionalDetails.ctaTextColor.valid ? (
                          <div
                            className="displayColor ml-2"
                            style={{
                              backgroundColor:
                                form.additionalDetails.ctaTextColor.value,
                            }}
                          ></div>
                        ) : (
                          <small className="text-danger ml-2">
                            {"Invalid Hex Code"}
                          </small>
                        )}
                      </Label>
                      <Input
                        type="text"
                        name="ctaTextColor"
                        onChange={handleHexCodeChange}
                        placeholder="Enter CtaTextColor"
                        defaultValue={
                          form.additionalDetails.ctaTextColor.value != null
                            ? form.additionalDetails.ctaTextColor.value
                            : ""
                        }
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        Text <span className="text-danger">*</span>{" "}
                        <small
                          id="invalidJsonTextAlert"
                          className="text-danger d-none"
                        >
                          (Enter valid JSON value)
                        </small>
                      </Label>
                      <Input
                        type="textarea"
                        name="text"
                        onChange={handlePromptDetailsChange}
                        placeholder={`Enter Text - Example : {"en":"sample-value","hi":"sample-value"}`}
                        value={
                          form.additionalDetails.text != null
                            ? form.additionalDetails.text
                            : ""
                        }
                        onFocus={() => {
                          hideAlert("invalidJsonTextAlert");
                        }}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        Cta Text <span className="text-danger">*</span>{" "}
                        <small
                          id="invalidCtaTextJsonAlert"
                          className="text-danger d-none"
                        >
                          (Enter valid JSON value)
                        </small>
                      </Label>
                      <Input
                        type="textarea"
                        name="ctaText"
                        onChange={handlePromptDetailsChange}
                        placeholder={`Enter CtaText - Example : {"en":"sample-value","hi":"sample-value"}`}
                        defaultValue={
                          form.additionalDetails.ctaText !== ""
                            ? form.additionalDetails.ctaText
                            : ""
                        }
                        onFocus={() => {
                          hideAlert("invalidCtaTextJsonAlert");
                        }}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <Label>
                        Impression Trackers{" "}
                        <small
                          id="invalidImpressionTrackersJsonAlert"
                          className="text-danger d-none"
                        >
                          (Enter valid JSON Array )
                        </small>
                      </Label>
                      <br></br>
                      <Input
                        type="textarea"
                        name="impressionTrackers"
                        onChange={handlePromptDetailsChange}
                        placeholder={`Enter Impression Trackers - Example : [{ type: "sample-value", url: "sample-value" }]`}
                        defaultValue={
                          form.additionalDetails.impressionTrackers !== ""
                            ? form.additionalDetails.impressionTrackers
                            : ""
                        }
                        onFocus={() => {
                          hideAlert("invalidImpressionTrackersJsonAlert");
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Button color="primary" type="submit" className="mt-4">
                  Submit
                </Button>
              </Form>
            </CardBody>
          </Card>
          <ResponseModal
            show={successModal.display}
            onHide={successModalClose}
            modalheading={successModalHeading}
            modaltext={successModal.text}
          />
          <ResponseModal
            show={failureModal.display}
            onHide={failureModalClose}
            modalheading={errorModalHeading}
            modaltext={failureModal.text}
          />
        </>
      )}
    </Container>
  );
};

export default ManageContentUpdatePrompts;

import React, { useState, useEffect, useCallback } from "react";
import ResponseModal from "../ResponseModal";
import {
	errorDescription,
	redirectTo,
	error,
	redirectOnTokenExpiry,
	validateKeyboardFont,
} from "../../../utilities/commonUtil";
import {
	validateCharacterMappingsArray,
	validateCharacterMappingsJSON,
} from "../../../utilities/keyboardFontUtil";
import Select from "react-select";
import Loading from "../../../components/Loading";
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Form,
	FormGroup,
	Input,
	Label,
	Row,
	Collapse,
} from "reactstrap";
import Routes from "../../../routes/index";
import { X } from 'react-feather'
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { iconSize } from "../../../config/UserConfig";
import { fetchFontLanguages, updateKeyboardFont, createKeyboardFont } from "../../../utilities/apiUtils/keyboardFonts";
import { Config } from "../../../config";
import IconButton from "@material-ui/core/IconButton";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";

const ManageFontForm = (props) => {
	const [formType, setFormType] = useState("create");
	const [ID, setID] = useState(null);
	const [font, setFont] = useState({ name: null, minAndroidSDKVersion: null, supportedLanguageCodes: [], badgeSettings: {} });
	const [isPremium, setIsPremium] = useState(false);
	const [characterMappingJSONInputToggle, setCharacterMappingJSONInputToggle] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [fontLanguages, setFontLanguages] = useState({});
	const [fontLanguageOptions, setFontLanguageOptions] = useState([]);
	const [selectedLanguages, setSelectedLanguages] = useState([]);
	const [successModalDisplay, setSuccessModalDisplay] = useState(false);
	const [characterMappings, setCharacterMappings] = useState([
		{
			"key": "",
			"value": ""
		}
	]);
	const [characterMappingsJSON, setCharacterMappingsJSON] = useState({ "": "" });
	const [characterMappingsString, setCharacterMappingsString] = useState("");
	const [failureModal, setFailureModal] = useState({
		display: false,
		text: "",
	});
	const [badgeSettingsToggle, setBadgeSettingsToggle] = useState(false);

	// Fetch font languages for Supported Language dropdown
	let fetchKeyboardFontLanguages = useCallback(async () => {
		try {
			let languages = await fetchFontLanguages();
			if (languages && languages.fontLanguages) {
				let options = []
				let fontLanguageCodeMap = {}, fontLanguageNameMap = {}
				languages.fontLanguages.map((language) => {
					fontLanguageCodeMap[language.id] = language.languageCode
					fontLanguageNameMap[language.id] = language.languageName
					let option = {
						"label": language.languageName,
						"value": language.id,
					};
					options.push(option)
				})
				setFontLanguages(fontLanguageNameMap);	// Mapping values for id -> language name
				setFontLanguageOptions(options);
				setIsLoading(false);
			} else if (languages.errorCode) {
				if (
					languages.errorCode === error.tokenExpired ||
					languages.errorCode === error.invalidAccessToken
				) {
					redirectOnTokenExpiry();
				}
				setFailureModal({ display: true, text: errorDescription(languages.errorCode) });
				setIsLoading(false);

			}
		} catch (err) {
			setFailureModal({ display: true, text: error.unexpectedError });
			setIsLoading(false);
		}
	}, []);

	// Fetch font languages when component mounts
	useEffect(() => {
		fetchKeyboardFontLanguages();
		setFormType(props.font.location.state.formType);
		setID(props.font.match.params.id);
	}, []);

	// Set font details for the font (edit case)
	let setFontDetails = (font) => {
		setID(font.id);
		setFont({
			name: font.name,
			minAndroidSDKVersion: font.minAndroidSDKVersion ? (font.minAndroidSDKVersion).toString() : null,
			badgeSettings: font.badgeSettings ? font.badgeSettings : {},
		});
		let fontSupportedLanguages = [];

		for (const key of Object.keys(font.supportedLanguageCodes)) {
			let supportedLanguageObj = {}
			supportedLanguageObj["value"] = Number(key);
			supportedLanguageObj["label"] = fontLanguages[key];
			fontSupportedLanguages.push(supportedLanguageObj);
		}

		setSelectedLanguages(fontSupportedLanguages);

		let fontCharacterMappings = []
		for (let key in font.characterMappings) {
			let obj = {
				"key": key,
				"value": font.characterMappings[key]
			};
			fontCharacterMappings.push(obj);
		}
		setCharacterMappings(fontCharacterMappings);

		if (font.badgeSettings) {
			setBadgeSettingsToggle(true);
		}
	}

	// Populate font details when font languages are fetched and form type is edit
	useEffect(() => {
		if (formType === Config.FORM_TYPE_EDIT) {
			let fontDetails = props.font.location.state.font;
			setFontDetails(fontDetails);
		}
	}, [fontLanguages])


	let convertCharacterMappingsToJSON = () => {
		if (characterMappings.length > 0) {
			let characterMappingJSON = characterMappings.reduce(
				(obj, item) => Object.assign(obj, { [item.key]: item.value }), {});
			setCharacterMappingsJSON(characterMappingJSON);
		}
	}

	let convertCharacterMappingJSONToArray = () => {
		if (characterMappingsJSON) {
			let characterMappingArray = Object.entries(characterMappingsJSON).map(([key, value]) => ({ "key": key, "value": value }));
			setCharacterMappings(characterMappingArray);
		}
	}

	useEffect(() => {
		let characterMappingString = JSON.stringify(characterMappingsJSON, undefined, 4);
		setCharacterMappingsString(characterMappingString);
		convertCharacterMappingJSONToArray();
	}, [characterMappingsJSON]);

	let toggleCharacterMappingsView = () => {
		if (characterMappingJSONInputToggle) {
			let isValid = validateCharacterMappingsJSON(characterMappingsString);
			if (!isValid) {
				showAlert("invalidCharacterMappings");
				return;
			}
			setCharacterMappingsJSON(JSON.parse(characterMappingsString));
			convertCharacterMappingJSONToArray();
			setCharacterMappingJSONInputToggle(!characterMappingJSONInputToggle);
		} else {
			let isValid = validateCharacterMappingsArray(characterMappings);
			if (!isValid) {
				showAlert("invalidCharacterMappings");
				return;
			}
			convertCharacterMappingsToJSON();
			setCharacterMappingJSONInputToggle(!characterMappingJSONInputToggle);
		}
	}

	// handleSupportedLanguagesChange handles selected languages for Supported Language dropdown
	let handleSupportedLanguageChange = (select) => {
		let languages = [];
		if (select !== null) {
			select.forEach((option) => {
				let obj = {
					"label": option.label,
					"value": option.value,
				}
				languages.push(obj);
			});
		}
		setSelectedLanguages(languages);
	};
	// Hide validation alerts
	let hideAlert = (elementId) => {
		document.getElementById(elementId).classList.add("d-none");
	};
	// Show validation alerts
	let showAlert = (elementId) => {
		document.getElementById(elementId).classList.remove("d-none");
	};
	// Close success modal
	let successModalClose = () => {
		setSuccessModalDisplay(false);
		redirectTo(Routes.keyboardLanguages.children.keyboardFonts.path);
	};
	// Add a row to character mappings' values
	const addRow = (state = undefined) => {
		let characterMappingRow, currentState;
		characterMappingRow = {
			"key": "",
			"value": "",
		};
		if (state !== undefined) {
			currentState = [...state];
		} else {
			currentState = [...characterMappings];
		}
		currentState = [...currentState, characterMappingRow];
		setCharacterMappings(currentState);
	};
	// Delete a row from character mappings' values
	const subtractRow = (index) => {
		let currentState = [...characterMappings];
		currentState = currentState.filter((item, idx) => idx !== index);
		setCharacterMappings(currentState);
		hideAlert("invalidCharacterMappings");
	};
	// Update character mapping's key value
	const handleCharacterKey = (event, index) => {
		let updatedState = [...characterMappings];
		let { value } = event.target;
		updatedState[index].key = value;
		setCharacterMappings(updatedState);
	};
	// Update character mapping's character value
	const handleCharacterValue = (event, index) => {
		let updatedState = [...characterMappings];
		let { value } = event.target;
		updatedState[index].value = value;
		setCharacterMappings(updatedState);
	};

	// Submit form to create keyboard font
	let handleSubmit = async (event) => {
		event.preventDefault();
		// Add supportedLanguages' id to the font
		let supportedLanguages = [];
		selectedLanguages.forEach((language) => {
			supportedLanguages.push(language.value);
		});
		let characterMappingsArray = characterMappings;
		if (characterMappingJSONInputToggle && characterMappingsJSON) {
			let isValid = validateCharacterMappingsJSON(characterMappingsString);
			if (!isValid) {
				showAlert("invalidCharacterMappings");
				return;
			}
			let characterMappingJSON = JSON.parse(characterMappingsString);
			let characterMapping = Object.entries(characterMappingJSON).map(([key, value]) => ({ "key": key, "value": value }));
			characterMappingsArray = characterMapping
		}
		// Preparing payload for the request
		let data = {
			name: font.name,
			minAndroidSDKVersion: (font.minAndroidSDKVersion) ? parseFloat(font.minAndroidSDKVersion.replace(/(\d+)\.(\d+)\.(\d+)/, '$1.$2$3')) : null,
			supportedLanguageCodes: supportedLanguages,
			characterMappings: characterMappingsArray,
		};
		// append badge settings if badge settings toggle is ON
		if (badgeSettingsToggle) {
			data.badgeSettings = font.badgeSettings;
		}
		// Validate Keyboard Font
		let keyboardFont = validateKeyboardFont(data);
		if (keyboardFont.isValid) {
			try {
				if (formType === Config.FORM_TYPE_EDIT) {
					let response = await updateKeyboardFont(keyboardFont.data, ID);
					if (response.status) setSuccessModalDisplay(true);
					else if (response.errorCode) {
						if (
							response.errorCode === error.tokenExpired ||
							response.errorCode === error.invalidAccessToken
						)
							redirectOnTokenExpiry();
						setFailureModal({ display: true, text: errorDescription(response.errorCode) });
					}
				} else if (formType === Config.FORM_TYPE_CREATE) {
					let response = await createKeyboardFont(keyboardFont.data);
					if (response.status) setSuccessModalDisplay(true);
					else if (response.errorCode) {
						if (
							response.errorCode === error.tokenExpired ||
							response.errorCode === error.invalidAccessToken
						)
							redirectOnTokenExpiry();
						setFailureModal({ display: true, text: errorDescription(response.errorCode) });
					}
				} else {
					setFailureModal({ display: true, text: "Something went wrong. Please try again later." });
				}
			} catch (err) {
				setFailureModal({ display: true, text: error.unexpectedError });
			}
		} else {
			if (!keyboardFont.hasValidName) {
				showAlert("invalidName");
			}
			if (!keyboardFont.hasCharacterMappings) {
				showAlert("invalidCharacterMappings");
			}
			if (!keyboardFont.hasSupportedLanguageCodes) {
				showAlert("invalidSupportedLanguages");
			}
		}
	};

	return (
		<>
			{isLoading ? (
				<Loading />
			)
				: (fontLanguageOptions && fontLanguageOptions.length > 0) ? (
					<Card>
						<CardBody>
							<Form onSubmit={(e) => handleSubmit(e)}>
								<Row form>
									<Col md={6}>
										<FormGroup>
											<Label>
												Name{" "}
												<small id="invalidName" className="text-danger d-none">
													{" "}(Name cannot be empty)
												</small>
											</Label>
											<Input
												type="text"
												name="name"
												value={font.name ? font.name : ""}
												placeholder="Name"
												onFocus={() => hideAlert("invalidName")}
												onChange={(e) => {
													setFont({ ...font, name: e.target.value })
												}}
											/>
										</FormGroup>
									</Col>
									<Col md={6}>
										<FormGroup>
											<Label>
												Min SDK Version{" "}
											</Label>
											<Input
												type="text"
												name="minAndroidSDKVersion"
												value={font.minAndroidSDKVersion || ""}
												placeholder="Min Android SDK Version"
												onChange={(e) => {
													setFont({ ...font, minAndroidSDKVersion: e.target.value })
												}
												}
											/>
										</FormGroup>
									</Col>
								</Row>
								<Row form>
									<Col md={12}>
										<FormGroup>
											<Label>
												Supported Languages
												<small id="invalidSupportedLanguages" className="text-danger d-none">
													{" "}(Please select a supported language)
												</small>
											</Label>
											<Select
												className="react-select-container"
												classNamePrefix="react-select"
												name="supportedLanguages"
												onFocus={() => hideAlert("invalidSupportedLanguages")}
												onChange={(e) => handleSupportedLanguageChange(e)}
												options={fontLanguageOptions}
												value={selectedLanguages}
												isMulti={true}
											/>
										</FormGroup>
									</Col>
								</Row>
								<hr></hr>
								<Row form className="mb-2">
									<h3 className="ml-1">Badge</h3>
									<IconButton
										style={{
											backgroundColor: "transparent",
											border: "none",
											verticalAlign: "top",
											padding: "0rem",
											marginLeft: "1rem",
										}}
										onClick={() =>
											setBadgeSettingsToggle(!badgeSettingsToggle)
										}
										className="d-none d-lg-block d-flex"
									>
										{badgeSettingsToggle ? <BsToggleOn /> : <BsToggleOff />}
									</IconButton>
								</Row>
								<Collapse isOpen={badgeSettingsToggle}>
									<Row form>
										<Col md={4}>
											<FormGroup>
												<Label>
													Text
												</Label>
												<Input
													type="text"
													name="text"
													value={font.badgeSettings?.text ? font.badgeSettings.text : ""}
													placeholder="text"
													onChange={(e) => {
														setFont({ ...font, badgeSettings: { ...font.badgeSettings, text: e.target.value } })
													}}
												/>
											</FormGroup>
										</Col>
									</Row>
									<Row form>
										<Col md={3}>
											<FormGroup>
												<Label>
													Text Color
												</Label>
												<Input
													type="text"
													name="textColor"
													value={font.badgeSettings?.textColor ? font.badgeSettings.textColor : ""}
													placeholder="Text Color"
													onChange={(e) => {
														setFont({ ...font, badgeSettings: { ...font.badgeSettings, textColor: e.target.value } })
													}}
												/>
											</FormGroup>
										</Col>
										<Col md={3}>
											<FormGroup>
												<Label>
													Dark Theme Text Color
												</Label>
												<Input
													type="text"
													name="darkThemeTextColor"
													value={font.badgeSettings?.darkThemeTextColor ? font.badgeSettings.darkThemeTextColor : ""}
													placeholder="Dark Theme Text Color"
													onChange={(e) => {
														setFont({ ...font, badgeSettings: { ...font.badgeSettings, darkThemeTextColor: e.target.value } })
													}}
												/>
											</FormGroup>
										</Col>
										<Col md={3}>
											<FormGroup>
												<Label>
													Background Color
												</Label>
												<Input
													type="text"
													name="backgroundColor"
													value={font.badgeSettings?.backgroundColor ? font.badgeSettings.backgroundColor : ""}
													placeholder="Background Color"
													onChange={(e) => {
														setFont({ ...font, badgeSettings: { ...font.badgeSettings, backgroundColor: e.target.value } })
													}}
												/>
											</FormGroup>
										</Col>
										<Col md={3}>
											<FormGroup>
												<Label>
													Dark Theme Background Color
												</Label>
												<Input
													type="text"
													name="darkThemeBackgroundColor"
													value={font.badgeSettings?.darkThemeBackgroundColor ? font.badgeSettings.darkThemeBackgroundColor : ""}
													placeholder="Dark Theme Background Color"
													onChange={(e) => {
														setFont({ ...font, badgeSettings: { ...font.badgeSettings, darkThemeBackgroundColor: e.target.value } })
													}}
												/>
											</FormGroup>
										</Col>
									</Row>
								</Collapse>
								<hr></hr>
								<Row form className="mb-2">
									<h3 className="ml-1">Character Mappings</h3>
									<small id="invalidCharacterMappings" className="text-danger d-none ml-2">
										{" "}(Please add proper character mappings)
									</small>
								</Row>
								<Row form>
									<Col md={4} className="pb-2">
										<FormGroup>
											<div className="custom-switch">
												<input
													type="checkbox"
													id="characterMappingJSONInputToggle"
													name="inputToggle"
													className="custom-control-input"
													checked={characterMappingJSONInputToggle}
													onChange={() => {
														toggleCharacterMappingsView();
													}}
												/>
												<label
													className="custom-control-label"
													htmlFor="characterMappingJSONInputToggle"
												>
													Enter Character Mappings as JSON
												</label>
											</div>
										</FormGroup>
									</Col>
								</Row>
								{characterMappingJSONInputToggle ?
									(
										<FormGroup>
											<Input type="textarea"
												name="characterMappingsJSONObject"
												rows="10"
												id="characterMappingsJSON"
												value={characterMappingsString ? characterMappingsString : ""}
												placeholder="Character Mappings"
												onFocus={() => hideAlert("invalidCharacterMappings")}
												onChange={(e) => {
													// setFont({ ...font, name: e.target.value })
													setCharacterMappingsString(e.target.value);
												}}
											/>
										</FormGroup>
									) : (
										<>
											<Row className="text-center">
												<Col md={5} className="text-center">
													<h6>Character Key</h6>
												</Col>
												<Col md={5}>
													<h6>Character Value</h6>
												</Col>
											</Row>
											{characterMappings && characterMappings.map((item, index) => {
												return (
													<Row key={index}>
														<Col sm={5}>
															<FormGroup>
																<Input
																	type="text"
																	name="characterKey"
																	value={item.key}
																	onFocus={() => hideAlert("invalidCharacterMappings")}
																	placeholder="Character Key"
																	onChange={(e) => {
																		handleCharacterKey(e, index);
																	}}
																/>
															</FormGroup>
														</Col>
														<Col sm={5}>
															<FormGroup>
																<Input
																	type="text"
																	name="characterValue"
																	value={item.value}
																	onFocus={() => hideAlert("invalidCharacterMappings")}
																	placeholder="Character Value"
																	onChange={(e) => {
																		handleCharacterValue(e, index);
																	}}
																/>
															</FormGroup>
														</Col>
														<Col sm={1}>
															<X
																className="d-none d-lg-block mt-1"
																size={iconSize}
																color="red"
																style={{ cursor: "pointer" }}
																onClick={(e) => {
																	subtractRow(index);
																}}
															></X>
														</Col>
													</Row>
												);
											})}
											<Button
												className="d-block pr-3 text-decoration-none"
												color="secondary"
												onClick={(e) => {
													addRow();
												}}
											>
												<FontAwesomeIcon size="sm" icon={faPlus}></FontAwesomeIcon>{" "}
												Add Character Mapping
											</Button>
										</>)}
								<br />
								<Button
									className="d-block mt-2"
									color="primary"
								>
									Submit
								</Button>
							</Form>
							<ResponseModal
								show={successModalDisplay}
								onHide={successModalClose}
								modalheading={"Success"}
								modaltext={(formType === Config.FORM_TYPE_EDIT ?
									("Font updated successfully") :
									(formType === Config.FORM_TYPE_CREATE ?
										("Font created successfully") :
										("Success")
									))}
							/>
							<ResponseModal
								show={failureModal.display}
								onHide={() => setFailureModal({ display: false })}
								modalheading={"Error"}
								modaltext={failureModal.text}
							/>
						</CardBody>
					</Card>
				) :
					<Card>
						<Container className="mt-5 mb-5">
							<h3 className="text-center">
								No Keyboard Font Languages Avaliable.
							</h3>
						</Container>
					</Card>
			}
		</>
	);
};

const ManageKeyboardFont = (props) => (
	<Container fluid className="p-0">
		{props.location.state.formType === Config.FORM_TYPE_EDIT ? (<h1 className="h3 mb-3">Edit Keyboard Font</h1>)
			: (props.location.state.formType === Config.FORM_TYPE_CREATE ? (<h1 className="h3 mb-3">Create a new keyboard font</h1>) : <></>)}
		<Row>
			<Col lg="12">
				<ManageFontForm font={props} />
			</Col>
		</Row>
	</Container>
);
export default ManageKeyboardFont;

import React from "react";
import { Badge } from "reactstrap";

const statusFormatter = (cell, row) => {
  if (row.publishedAt === null) {
    return <Badge className="badge-warning">{"Unpublished"}</Badge>;
  } else if (row.deactivatedAt) {
    return <Badge className="badge-danger">{"Deactivated"}</Badge>;
  } else {
    return <Badge className="badge-success">{"Activated"}</Badge>;
  }
};
export const promptTemplatesTableColumns = [
  {
    dataField: "id",
    text: "#",
    headerAttrs: { width: 60 },
    searchable: false,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "identifier",
    text: "Identifier",
    searchable: true,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "description",
    text: "Description",
    searchable: true,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "deactivatedAt",
    text: "Status",
    searchable: false,
    style: {
      cursor: "pointer",
    },
    formatter: statusFormatter,
  },
];

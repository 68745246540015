import React, { PureComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas, faInfoCircle, faGlobe } from "@fortawesome/free-solid-svg-icons";
import { permissions, redirectTo } from "../../../../utilities/commonUtil";
import { retrieveFromLocalStorage } from "../../../../utilities/localStorageUtil";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  CardImg,
  CardTitle,
} from "reactstrap";

class GifPack extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      allowedPermissions: retrieveFromLocalStorage("userPermissions"),
      hamburgerOpen: false,
      anchorElement: null,
      toggleStatusDropdown: false,
    };
  }

  showDetailsButton = () => {
    return this.state.allowedPermissions.includes(
      permissions.gifPackDetail
    );
  };

  render() {
    return (
      <Card>
        <React.Fragment>
          <CardImg
            top
            src={this.props.gif.bannerImageURL}
            alt="Card image cap"
            className="cursor-pointer"
            onClick={() => {
              if (
                this.state.allowedPermissions.includes(
                  permissions.gifPackDetail
                )
              )
                redirectTo(`/gifs/gif-pack/${this.props.gif.id}`);
            }}
          />
        </React.Fragment>
        <CardHeader className="px-4 pt-4">
          <CardTitle tag="h3" className="mb-0">
            {this.props.gif.name}
            <Badge className="float-right" color={this.props.color}>
              {this.props.status}
            </Badge>
          </CardTitle>
        </CardHeader>
        <CardBody className="px-4 pt-2">
          <p className="desc">{this.props.gif.description}</p>
          <small className="font-weight-bold">
            ID : {this.props.gif.id}
          </small>
          <br />
          <small className="font-weight-bold">
            Created At : {this.props.gif.createdAt}
          </small>
          <br />
          <small className="font-weight-bold">
            Published At : {this.props.gif.publishedAt}
          </small>
          <br />
          <small className="font-weight-bold">
            Deactivated At : {this.props.gif.deactivatedAt}
          </small>
          <br />
          <small className="font-weight-bold">
            Active Head Supported Gifs :{" "}
            {this.props.gif.activeHeadSupportedGifs}
          </small>
          <br />
          <small className="font-weight-bold">
            Is Head Supported :{" "}
            {this.props.gif.isHeadSupported ? "Yes" : "No"}
          </small>
        </CardBody>
        <CardBody className="px-4 pt-2 pl-0 pr-0">
          <Container className="text-center" fluid>
            <Row>
              <Col sm="12" md="12">
                {this.showDetailsButton() ? (
                  <Button
                    className="mw-75 w-120"
                    color="info"
                    onClick={() =>
                      redirectTo(
                        `/gifs/gif-pack/${this.props.gif.id}`
                      )
                    }
                  >
                    Details
                    <FontAwesomeIcon
                      icon={(fas, faInfoCircle)}
                      className="ml-2"
                    ></FontAwesomeIcon>
                  </Button>
                ) : null}
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card>
    );
  }
}

export default GifPack;

import React, { useState } from "react";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { permissions } from "../../../utilities/commonUtil";
import UserAttributeFilters from "../UserAttributeFilters";
import { activateContentUserAttributeRules, deactivateContentUserAttributeRules, getContentUserAttributeRules, saveContentUserAttributeRules } from "../../../utilities/apiUtils/suggestionDrawer";
import { sdv3UserAttributeRuleFilterOptions } from "../../../config/configSuggestionDrawer";

const ContentUserAttributeRules = () => {

    const [allowedPermissions] = useState(
        retrieveFromLocalStorage("userPermissions")
    );

    const updateRulesAllowed = () => {
        return allowedPermissions.includes(permissions.SuggestionDrawerContentRulesUpdate);
    };

    return (
        <div>
            <UserAttributeFilters
                ruleType={"suggestionDrawerContentId"}
                getFilters={sdv3UserAttributeRuleFilterOptions}
                getRules={getContentUserAttributeRules}
                saveRules={saveContentUserAttributeRules}
                deactivateRules={deactivateContentUserAttributeRules}
                activateRules={activateContentUserAttributeRules}
                updateRulesAllowed={updateRulesAllowed()}
            />
        </div>
    );
};

export default ContentUserAttributeRules;

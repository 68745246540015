import React, { useState, useEffect } from "react";
import Select from "react-select";
import { getBrandCampaigns } from "../../../utilities/apiUtils/stickers";
import {
  validateHexCode,
  validateInteger,
  validateFloat,
} from "../../../utilities/commonUtil";
import {
  Col,
  Row,
  Button,
  Container,
  Card,
  CardBody,
  Input,
  Label,
  Form,
  FormGroup,
  CustomInput,
} from "reactstrap";

const ThemeCategoryForm = (props) => {
  const [formTextState, setFormTextState] = useState({
    name: props.category.name,
    numPreviewThemes: {
      value: props.category.numPreviewThemes.value,
      valid: props.category.numPreviewThemes.valid,
    },
    bannerImage: "",
    priority: {
      value: props.category.priority.value,
      valid: props.category.priority.valid,
    },
    brandCampaignID: props.category.brandCampaignID,
    displayExpandedView: props.category.displayExpandedView,
    bannerImageSrc: props.category.bannerImageSrc,
  });

  const [displayError, setDisplayError] = useState(false);

  const [campaigns, setCampaigns] = useState([]);
  const [defaultCampaign, setDefaultcampaign] = useState(null);

  useEffect(() => {
    const _getBrandCampaigns = async () => {
      let response = await getBrandCampaigns();
      setCampaigns(response);
    };
    _getBrandCampaigns();
  }, []);

  useEffect(() => {
    setDefaultcampaign(defaultValueForCampaign());
  }, [campaigns]);

  const defaultValueForCampaign = () => {
    var brandID = props.category.brandCampaignID;
    var defCampaign = campaigns.find((item) => item.value === brandID);
    return defCampaign;
  };

  const handleCampaignChange = (campaign) => {
    setFormTextState((prev) => ({
      ...prev,
      brandCampaignID: campaign ? campaign.value : 0,
    }));
    setDefaultcampaign(campaign);
    setDisplayError(false);
  };

  const handleHexCodeChange = (event) => {
    const { name, value } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: {
        value: value,
        valid: validateHexCode(value),
      },
    }));
    setDisplayError(false);
  };

  const handleOpacityChange = (event) => {
    const { name, value } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: {
        value: value,
        valid: value >= 0 && value <= 1,
      },
    }));
    setDisplayError(false);
  };

  const handlePriorityChange = (event) => {
    const { name, value } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: {
        value: value,
        valid: validateInteger(value) && parseInt(value) !== 0,
      },
    }));
    setDisplayError(false);
  };

  const handleMinAppVersionChange = (event) => {
    const { name, value } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: {
        value: value,
        valid: validateFloat(value) && parseFloat(value) !== 0,
      },
    }));
    setDisplayError(false);
  };

  const handleCategoryChange = (category) => {
    setFormTextState((prev) => ({
      ...prev,
      categoryID: { value: category.value, valid: true },
    }));
    setDisplayError(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: value,
    }));
    setDisplayError(false);
  };

  const handleCheckBoxChange = (event) => {
    const { id, checked } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [id]: checked,
    }));

    setDisplayError(false);
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: files[0],
    }));

    event.persist();
    if (files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        setFormTextState((prev) => ({
          ...prev,
          bannerImageSrc: reader.result,
        }));
        document.getElementById("bannerImg").innerHTML = files[0].name;
      };
    }
  };

  const submit = (event) => {
    event.preventDefault();
    let inputsWithError = document.getElementsByClassName("is-invalid");
    if (inputsWithError.length === 0) props.submit(formTextState);
    else {
      setDisplayError(true);
      if (formTextState.categoryID.value === "") {
        setFormTextState((prev) => ({
          ...prev,
          categoryID: {
            value: formTextState.categoryID.value,
            valid: false,
          },
        }));
      }
    }
  };

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">{props.category.formName}</h1>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Form
                onSubmit={(event) => {
                  submit(event);
                }}
              >
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        <strong>Name*</strong>
                        <p style={{ fontSize: 12, marginBottom: 0 }}>
                          Name of the theme
                        </p>
                      </Label>
                      <Input
                        type="text"
                        name="name"
                        defaultValue={props.category.name}
                        onChange={(event) => handleChange(event)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col className="themeCategoryDropdown">
                    <Label>
                      <strong>Brand Campaign</strong>
                      <p style={{ fontSize: 12 }}>
                        Brand Campaign Pop text style is linked with
                      </p>
                    </Label>
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="campaign"
                      value={defaultCampaign != null ? defaultCampaign : null}
                      placeholder="Select campaign"
                      isClearable={true}
                      options={campaigns}
                      onChange={(option) => handleCampaignChange(option)}
                      isDisabled={!props.updateAllowed}
                    />
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row className="my-2">
                  <Col className="align-middle" display="table">
                    <Row className="pl-2">
                      <Col className="pl-0">
                        <strong>Display Expanded View</strong>
                      </Col>
                      <Col
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div className="custom-control custom-switch">
                          <CustomInput
                            type="checkbox"
                            id="displayExpandedView"
                            value={formTextState.displayExpandedView === true}
                            onChange={(event) => handleCheckBoxChange(event)}
                            checked={formTextState.displayExpandedView === true}
                            disabled={!props.updateAllowed}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        <strong>Number of Preview Themes</strong>
                        <small className="text-info">
                          (Enter integer value greater than 0)
                        </small>
                      </Label>
                      <Input
                        type="number"
                        name="numPreviewThemes"
                        defaultValue={props.category.numPreviewThemes.value}
                        className={
                          formTextState.priority.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        value={formTextState.numPreviewThemes.value}
                        onChange={(event) => handlePriorityChange(event)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        <strong>Priority</strong>
                        <small className="text-info">
                          (Enter integer value greater than 0)
                        </small>
                      </Label>
                      <Input
                        type="number"
                        name="priority"
                        defaultValue={props.category.priority.value}
                        className={
                          formTextState.priority.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        value={formTextState.priority.value}
                        onChange={(event) => handlePriorityChange(event)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        Preview Image{" "}
                        <small className="text-info">
                          (Choose a 324x234 jpeg file)
                        </small>
                      </Label>
                      <Container className="custom-file">
                        <Input
                          type="file"
                          className="custom-file-input"
                          accept="image/jpeg"
                          name="bannerImage"
                          onChange={(event) => handleFileChange(event)}
                          required={
                            formTextState.type === "color" &&
                            props.category.formName === "Create Keyboard Theme"
                          }
                          disabled={!props.updateAllowed}
                        />
                        <Label className="custom-file-label" id="bannerImg">
                          Choose an image
                        </Label>
                      </Container>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  {formTextState.bannerImageSrc !== "" &&
                  formTextState.bannerImageSrc !== null ? (
                    <Col className="pb-4">
                      <img
                        src={formTextState.bannerImageSrc}
                        height="120"
                        alt="bannerImage"
                      />
                    </Col>
                  ) : (
                    <Col></Col>
                  )}
                </Row>
                <hr style={{ margin: 8 }} />

                {displayError ? (
                  <p className="text-danger mb-0">
                    Please enter all valid details.
                  </p>
                ) : null}
                <br />
                {props.updateAllowed ? (
                  <Button color="primary" type="submit" className="mt-2">
                    Submit
                  </Button>
                ) : (
                  <div />
                )}
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ThemeCategoryForm;

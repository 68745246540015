import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { retrieveFromLocalStorage } from "../../../../utilities/localStorageUtil";
import { Config } from "../../../../config";
import {
  redirectToNewTab,
  getStatusOfBtn,
  getStatusColor,
  getBtnName,
  getButtonColor,
  permissions,
  redirectTo,
} from "../../../../utilities/commonUtil";
import {
  Badge,
  Card,
  Container,
  CardImg,
  CardBody,
  CardTitle,
  CardFooter,
  Button,
  Row,
  Col,
} from "reactstrap";
import {
  storyActivatePermission,
  storyDeactivatePermission,
  storyPublishPermission,
  sotdTargetingRuleViewPermission,
  sotdViewPermission,
  getdateValue,
} from "../../../../config/configStoryDashboard";

export const SotdCard = (props) => {
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );
  const [story, setStory] = useState(props.reply);

  const showButton = (btnName) => {
    if (btnName === Config.ACTIVATE_BUTTON) return storyActivatePermission();
    else if (btnName === Config.DEACTIVATE_BUTTON)
      return storyDeactivatePermission();
    else if (btnName === Config.PUBLISH_BUTTON) return storyPublishPermission();
    else return false;
  };

  let status = getStatusOfBtn(
    story.publishedAt ? story.publishedAt : null,
    story.deactivatedAt ? story.deactivatedAt : null
  );

  const handleRedirect = function (req, res) {
    redirectTo(`/stories/${story.id}`);
  };

  useEffect(() => {
    setStory(props.reply);
  }, [props.reply]);

  return (
    <Card>
      {story?.videoPreviewURL ? (
        <video
          src={story.videoPreviewURL}
          controls
          controlsList="nodownload noremoteplayback"
          className="p-2"
          style={{ height: "400px", cursor: "pointer", width: "100%" }}
        />
      ) : story?.staticPreviewURL ? (
        <Container className="d-flex justify-content-center">
          <CardImg
            top
            src={story.staticPreviewURL ?? ""}
            alt="Card image cap"
            className="p-2"
            style={{
              height: "400px",
              width: "100%",
              maxWidth: "250px",
              cursor: "pointer",
            }}
          />
        </Container>
      ) : (
        <CardImg
          src={"/img/no-image-icon.png"}
          alt="Card image"
          style={{
            height: "400px",
            paddingLeft: "10px",
            marginBottom: "5px",
          }}
        />
      )}

      <CardBody>
        <div tag="h3" className="mb-0">
          <Badge className="float-right" color={getStatusColor(status)}>
            {status}
          </Badge>
        </div>
        <small>SOTD ID: {story?.id}</small>
        <br />
        <small>Story ID: {story?.storyId}</small>
        <br />
        <small>Title: {story?.title}</small>
        <br />
        <small>Start At: {getdateValue(story?.startAt)}</small>
        <br />
        <small>End At: {getdateValue(story?.endAt)}</small>
        <br />
        <small>Published At: {getdateValue(story?.publishedAt)}</small>
        <br />
        <small>Deactivated At: {getdateValue(story?.deactivatedAt)}</small>
      </CardBody>

      <CardFooter>
        <Container className="">
          <Row className="justify-content-center">
            {sotdViewPermission() && (
              <Col xs={12} sm={6} className="mb-2">
                <Button
                  className="mw-75 w-100"
                  color="info"
                  onClick={() => {
                    redirectTo(`/stories/sotd/create?sotdId=${story.id}`);
                  }}
                >
                  Details
                </Button>
              </Col>
            )}
            {showButton(getBtnName(status)) ? (
              <Col xs={12} sm={6} className="mb-2">
                <Button
                  className="mw-100 w-100"
                  color={getButtonColor(status)}
                  onClick={() => {
                    props.getConfirmation(story.id, getBtnName(status));
                  }}
                >
                  {getBtnName(status)}
                </Button>
              </Col>
            ) : null}
            {sotdTargetingRuleViewPermission && (
              <Col xs={12} sm={12} className="mb-2">
                <Button
                  className="mw-100 w-100"
                  color="secondary"
                  style={{
                    width: "100%",
                  }}
                  onClick={() => {
                    redirectTo(`/stories/sotd/targetingRules/${story.id}`);
                  }}
                >
                  Targeting Rules
                </Button>
              </Col>
            )}
          </Row>
        </Container>
      </CardFooter>
    </Card>
  );
};

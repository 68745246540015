import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardHeader, CardBody, Container, Row, Col, CustomInput } from "reactstrap";
import { Button, Accordion } from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi"
import Reorder from "react-reorder";
import { BsChevronDown, BsChevronUp } from "react-icons/bs"
import { FiEdit } from "react-icons/fi"
import { AiOutlineDelete, AiOutlineSwap, AiOutlinePlus } from "react-icons/ai"
import ResponseModal from "../ResponseModal";
import Loading from "../../../components/Loading";
import { fetchMixingStrategy, deleteMixingStrategy, createMixingStrategy, updateMixingStrategy, updateSingleMixingStrategy } from "../../../utilities/apiUtils/configAPI";
import { error, errorDescription, redirectOnTokenExpiry } from "../../../utilities/commonUtil";
import { errorModalHeading, successModalHeading } from "../../../config/config";
import ConfirmationModal from "../ConfirmationModal";
import { isConfigAPIDeletePermission, isConfigAPIEditPermission, mixingStrategyAllActions, mixingStrategyResponses, clientOptions } from "../../../config/configConfigAPIDashboard";
import MixingStrategyModal from "./modals/MixingStrategyModal";
import Search from "../../../components/Search";

const defaultActiveAccordion = 0

const formDataInitialState = {
  id: "",
  placementId: "",
  clientAdVendorId: "",
  numberOfAds: "",
  priority: ""
}

const ManageMixingStrategy = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState(formDataInitialState)
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [mixingStrategy, setMixingStrategy] = useState([])
  const [activeAccordion, setActiveAccordion] = useState(defaultActiveAccordion)
  const [isReorderEnabled, setIsReorderEnabled] = useState(false)
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [id, setId] = useState(null);
  const [action, setAction] = useState("");
  const [changeType, setChangeType] = useState("Add")
  const [isMixingStrategyModalVisible, setIsMixingStrategyModalVisible] = useState(false)
  const [placementFilter, setPlacementFilter] = useState("");
  const [clientFilter, setClientFilter] = useState("");

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    setIsReorderEnabled(false)
    setLoading(true)
    getMixingStrategy()
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let setConfirmationModalState = (id, event) => {
    setAction(event);
    setConfirmationModalIsOpen(true);
    setId(id);
  };

  let showMixingStrategyModal = (type) => {
    setChangeType(type)
    setIsMixingStrategyModalVisible(true)
  }

  let hideMixingStrategyModal = () => {
    setIsMixingStrategyModalVisible(false)
    setFormData(formDataInitialState)
  }

  const handleClick = () => {
    setPlacementFilter("");
    setClientFilter("")
    sessionStorage.removeItem("mixingStrategyFilterPlacement")
    sessionStorage.removeItem("mixingStrategyFilterClient")
  };

  const ClearButton = () => {
    return (
      <Button
        style={{ float: "right" }}
        className="form-control-lg"
        onClick={handleClick}
      >
        Reset Filters
      </Button>
    );
  };

  let handleAddNewVendor = (id) => {
    setFormData({
      id: "",
      placementId: id,
      clientAdVendorId: "",
      numberOfAds: "",
      priority: ""
    })
    showMixingStrategyModal("Add")
  }

  const handleToggleAccordion = (value) => {
    if (value === activeAccordion) setActiveAccordion(null)
    else setActiveAccordion(value)
  }

  const handleReorder = (event, previousIndex, nextIndex, fromId, toId) => {
    // swap priority logic
    let deepCloneMixingStrategy = JSON.parse(JSON.stringify(mixingStrategy))
    deepCloneMixingStrategy.forEach(strategy => {
      if (strategy.placementId === fromId.split("reorder-list-")[1]) {
        // swap priority
        let temp = strategy.vendors[previousIndex].priority
        strategy.vendors[previousIndex].priority = strategy.vendors[nextIndex].priority
        strategy.vendors[nextIndex].priority = temp

        // swap objects
        temp = strategy.vendors[previousIndex]
        strategy.vendors[previousIndex] = strategy.vendors[nextIndex]
        strategy.vendors[nextIndex] = temp

      }
    })
    setMixingStrategy(deepCloneMixingStrategy)
  };

  const getMixingStrategy = async () => {
    try {
      let response = await fetchMixingStrategy();
      if (!response || response?.length === 0) {
        setMixingStrategy([])
        setLoading(false)
      } else if (response?.length > 0) {
        response.forEach((item) => {
          item.clientName = clientOptions.find(option => option.value === item?.clientId)?.label ?? item?.clientId
        })
        setMixingStrategy(response);
        setLoading(false);
      } else {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken ||
          response.errorCode === error.unauthorized
        )
          redirectOnTokenExpiry();
        let text = errorDescription(response.errorCode);
        setLoading(false);
        showErrorModal(text);
      }
    } catch (err) {
      setLoading(false);
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  }

  useEffect(() => {
    getMixingStrategy()

    // Fill stored filter values
    if (sessionStorage.getItem("mixingStrategyFilterPlacement"))
      setPlacementFilter(sessionStorage.getItem("mixingStrategyFilterPlacement"))
    if (sessionStorage.getItem("mixingStrategyFilterClient"))
      setClientFilter(sessionStorage.getItem("mixingStrategyFilterClient"))
  }, [])

  const deleteStrategy = async (id) => {
    try {
      let response = await deleteMixingStrategy(id);
      if (response.status === "success") {
        let text = "Mixing Strategy has been deleted";
        showSuccessModal(text);
      } else {
        let text
        if (response?.errorDescription)
          text = response?.errorDescription?.split("):")[1];
        else
          text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  }

  const filterMixingStrategy = () => {
    let items = mixingStrategy;

    if (placementFilter)
      items = items.filter((item) =>
        item.placementDesc?.toLowerCase().includes(placementFilter.toLowerCase())
      );

    if (clientFilter)
      items = items.filter((item) =>
        item.clientName?.toLowerCase().includes(clientFilter.toLowerCase())
      );

    return items;
  };

  const handleSubmit = async (data) => {
    const { id, clientAdVendorId, numberOfAds, placementId, priority } = data
    const payload = {
      placementId,
      clientAdVendorId,
      numberOfAds: Number(numberOfAds),
      priority: Number(priority)
    }
    try {
      let response
      if (changeType === "Add")
        response = await createMixingStrategy(payload)
      else
        response = await updateSingleMixingStrategy(id, payload)
      if (response?.status === "success") {
        setSuccessModalText(changeType === "Add" ? mixingStrategyResponses.addedSuccessfully : mixingStrategyResponses.updatedSuccessfully);
        setSuccessModalDisplay(true);
      } else {
        let text
        if (response?.errorDescription)
          text = response?.errorDescription?.split("):")[1];
        else
          text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      }
    } catch (err) {
      let text = errorDescription(error.unexpectedError);
      showErrorModal(text);
    } finally {
      hideMixingStrategyModal()
    }
  }

  const handleUpdateMixingStrategy = async () => {
    try {
      let response = await updateMixingStrategy(mixingStrategy);
      if (response.status === "success") {
        let text = "Mixing Strategy has been updated";
        showSuccessModal(text);
      }
    } catch (err) {
      let text = errorDescription(error.unexpectedError);
      showErrorModal(text);
    }
  }

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <Container fluid className="p-0">
      <div className="cursor-pointer mb-2" onClick={handleGoBack}>
        <BiArrowBack size={24} />
      </div>
      <Row>
        <h1 className="mb-4 mt-2 w-50 pl-3">Mixing Strategy</h1>
      </Row>
      <Card>
        <CardBody>
          <Row className="justify-content-md-center mx-auto mb-1">
            <Col md={6} sm={12} className="mt-3">
              <Search
                placeholder="Search Placement Name"
                value={placementFilter}
                onChange={(e) => {
                  setPlacementFilter(e.target.value)
                  sessionStorage.setItem("mixingStrategyFilterPlacement", e.target.value)
                }}
              ></Search>
            </Col>
            <Col md={6} xs={5} className="p-0 mt-3">
              <ClearButton>Reset Filters</ClearButton>
            </Col>
          </Row>
          <Row className="justify-content-md-center mx-auto">
            <Col md={12} xs={12}>
              <Search
                placeholder="Search Client Name"
                value={clientFilter}
                onChange={(e) => {
                  setClientFilter(e.target.value)
                  sessionStorage.setItem("mixingStrategyFilterClient", e.target.value)
                }}
              ></Search>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column">
            {isConfigAPIEditPermission() && <span style={{ fontSize: 13 }} className="text-info font-weight-bold">{isReorderEnabled ? "Drag and Drop the vendors vertically to rearrange them and change priority" : "Enable toggle to reorder vendors"}</span>}
            <span style={{ fontSize: 13 }} className="text-success font-weight-bold">{isReorderEnabled && "Disable toggle to edit/delete vendors"}</span>
          </div>
          <div className="">
            {isConfigAPIEditPermission() && (
              <div className="custom-control custom-switch d-flex align-items-center mr-2">
                <strong className="mr-4">Reorder vendors?</strong>
                <CustomInput
                  type="checkbox"
                  id="reorderToggle"
                  value={isReorderEnabled}
                  onChange={() => setIsReorderEnabled(!isReorderEnabled)}
                  checked={isReorderEnabled}
                />
              </div>
            )}
            {(isConfigAPIEditPermission() && mixingStrategy.length > 0 && isReorderEnabled) && (
              <Button className="d-block ml-auto mt-3" color="primary" onClick={handleUpdateMixingStrategy}>
                Update strategy
              </Button>
            )}
          </div>
        </CardBody>
      </Card>
      {!loading && mixingStrategy.length <= 0 && (
        <span className="text-info font-weight-bold">No placements created</span>
      )}
      {loading ? (
        <Loading />
      ) : (
        <>
          {mixingStrategy.length > 0 && (
            <Card className="mb-0" style={{ borderBottom: "1px solid #dee2e6" }}>
              <CardHeader>
                <Row>
                  <Col md={4}><strong>Placement Name</strong></Col>
                  <Col md={4}><strong>Client Name</strong></Col>
                </Row>
              </CardHeader>
            </Card>
          )}
          <Accordion defaultActiveKey={activeAccordion?.toString()}>
            {
              mixingStrategy.length > 0 && filterMixingStrategy()?.map((strategy, index) => (
                <Card key={strategy?.placementId}>
                  <Accordion.Toggle as={CardHeader} eventKey={index.toString()} onClick={() => handleToggleAccordion(index)}>
                    <Row>
                      <Col md={4}>{strategy?.placementDesc}</Col>
                      <Col md={4}>{strategy?.clientName}</Col>
                      <Col md={4} className="d-flex justify-content-end align-items-center">
                        {isConfigAPIEditPermission() && (
                          <Button className="btn-primary mr-3" onClick={() => handleAddNewVendor(strategy?.placementId)}>
                            <AiOutlinePlus /> Map vendor
                          </Button>
                        )}
                        {activeAccordion === index ? <BsChevronUp /> : <BsChevronDown />}
                      </Col>
                    </Row>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={index.toString()}>
                    <CardBody className="p-0 px-3 py-1">
                      {
                        strategy?.vendors?.length === 0
                          ? <center className="text-danger font-weight-bold">No vendors mapped</center>
                          : (
                            <Row>
                              <Col md={4}><strong className="ml-1">Vendor Name</strong></Col>
                              <Col md={4}><strong className="ml-1">No. of Ads</strong></Col>
                            </Row>
                          )
                      }
                      <Reorder reorderId={`reorder-list-${strategy?.placementId}`} className="w-100 mx-3" onReorder={handleReorder} disabled={!(isReorderEnabled && isConfigAPIEditPermission())}>
                        {
                          strategy?.vendors?.map((vendor) => (
                            <Row key={vendor.id} style={{ background: "#f4f4f4", cursor: isReorderEnabled ? "grab" : "initial" }} className="reorderContainer my-2 py-2 rounded d-flex h-auto w-100">
                              <Col md={4}>{isReorderEnabled && <AiOutlineSwap className="mr-4" color="#c7c7c7" size={16} />} {vendor?.vendor?.name}</Col>
                              <Col md={4}>{vendor?.numberOfAds}</Col>
                              {
                                (!isReorderEnabled) && (
                                  <Col md={4} className="d-flex justify-content-end">
                                    {isConfigAPIEditPermission() && (
                                      <Button className="btn-primary px-1 py-0" onClick={() => {
                                        setFormData({
                                          id: vendor?.id,
                                          placementId: strategy?.placementId,
                                          clientAdVendorId: vendor?.clientAdVendorId,
                                          numberOfAds: vendor?.numberOfAds,
                                          priority: vendor?.priority
                                        })
                                        showMixingStrategyModal("Edit")
                                      }}>
                                        <FiEdit />
                                      </Button>
                                    )}
                                    {
                                      (isConfigAPIDeletePermission() && strategy?.vendors?.length > 1) && (
                                        <Button className="btn-danger px-1 py-0 ml-2" onClick={() => setConfirmationModalState(vendor.id, "Delete")}>
                                          <AiOutlineDelete />
                                        </Button>
                                      )
                                    }
                                  </Col>
                                )
                              }
                            </Row>
                          ))
                        }
                      </Reorder>
                    </CardBody>
                  </Accordion.Collapse>
                </Card>
              ))
            }
          </Accordion>
        </>
      )
      }
      <MixingStrategyModal
        show={isMixingStrategyModalVisible}
        type={changeType}
        formData={formData}
        setFormData={setFormData}
        onSubmit={handleSubmit}
        onHide={hideMixingStrategyModal}
      />
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={action}
        onConfirmationTrue={() => {
          setConfirmationModalIsOpen(false);
          if (action === mixingStrategyAllActions.delete) deleteStrategy(id)
        }}
        onHide={() => setConfirmationModalIsOpen(false)}
      />
      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={successModalHeading}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={errorModalHeading}
        modaltext={failureModalText}
      />
    </Container>
  );
};

export default ManageMixingStrategy;

import React from "react"
import {
    Container
} from "reactstrap"
import EmojiStickerImage from "./components/EmojiStickerImageForm"

const CreateEmojiStickerImage = (props) => {
    return (
        <Container fluid>
            <h3 className="h3">Add BigMoji Image</h3><br />
            <EmojiStickerImage forCreate emojiID={props.match.params.emojiID} />
        </Container>
    )
}
export default CreateEmojiStickerImage
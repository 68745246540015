import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { Link } from "react-router-dom";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { FaPlus, FaMinus } from "react-icons/fa";
import IconButton from "@material-ui/core/IconButton";
import CustomTooltip from "../../../../components/customToolTip";

import {
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import {
  addActionTypeOptions,
  addTypeOptions,
  dateError,
  iconPointerOptions,
  internalRedirectionOptions,
  keyboardPromptResponses,
  timeError,
  typeNotSelectedError,
  categoryOptions,
  showAddKeyboardPromptsButton,
  endDateError,
  trackerTypeOptions,
  fileSizeLimit,
} from "../../../../config/ConfigKeyboardPrompts";

import { showEditKeyboardPromptsButton } from "../../../../config/ConfigKeyboardPrompts";

import { X } from "react-feather";

import { iconSize } from "../../../../config/UserConfig";

import { dashboard } from "../../../../config/config";

import { getBrandCampaigns } from "../../../../utilities/apiUtils/brandCampaign";

import { getQuickReplyCategories } from "../../../../utilities/apiUtils/quickReplies";

import Search from "../../../../components/Search";

import ConfirmationModal from "../../../../pages/ui-elements/ConfirmationModal";
import ResponseModal from "../../../../pages/ui-elements/ResponseModal";
import {
  getSuggestionDrawerCategories,
  addKeyboardPrompts,
  fetchKeyboardPromptsByID,
  fetchPackageSetData,
  getCategories,
  getKeyboardPromptsLanguages,
  updateKeyboardPrompts,
} from "../../../../utilities/apiUtils/keyboardPrompts";

import Routes from "../../../../routes/index";

import { convertBytesToKB, errorDescription, redirectTo } from "../../../../utilities/commonUtil";
import ToolTip from "../../../../utilities/ToolTip";

import TagsInput from "react-tagsinput";
import moment from "moment";
import ReactAudioPlayer from "react-audio-player";
import Loading from "../../../../components/Loading";

const { SearchBar } = Search;

const trackersInitialState = [{
  type: null,
  url: ""
}]

const ManageKeyboardPrompts = (props) => {
  const handleSubmit = async () => {
    const today = moment().format("YYYY-MM-DD")

    if (details.endDate && today >= moment(details.endDate).format("YYYY-MM-DD")) {
      let text = endDateError;
      showErrorModal(text);
      return;
    }

    if (details.startDate > details.endDate) {
      let text = dateError;
      showErrorModal(text);
      return;
    }

    if (settings.startTime > settings.endTime) {
      let text = timeError;
      showErrorModal(text);
      return;
    }

    if (details.type === undefined) {
      let text = typeNotSelectedError;
      showErrorModal(text);
      return;
    }

    if (details.category === undefined) {
      let text = typeNotSelectedError;
      showErrorModal(text);
      return;
    }

    // // append actions
    const formActions = [];

    actions.forEach((action) => {
      var displaySettings = null;

      if (action.displayToggle === true) {
        displaySettings = {
          backgroundColor: action.backgroundColor,
          darkThemeBackgroundColor: action.darkThemeBackgroundColor,
          textColor: action.textColor,
          darkThemeTextColor: action.darkThemeTextColor,
          text: action.text,
          texts: (action.texts || []).filter((text) => text.trim() !== ""),
          textsRotationIntervalInMs: parseInt(action.textsRotationIntervalInMs),
          textSize: action?.textSize?.value,
          description: action.description,
          bannerImage: action.bannerImage,
          bannerImageName: action.bannerImageName,
          darkThemeBannerImage: action.darkThemeBannerImage,
          darkThemeBannerImageName: action.darkThemeBannerImageName,
          useExistingBannerImage: action.useExistingBannerImage,
          dismissible: action.dismissible,
          bannerAspectRatio: action.bannerAspectRatio,
          lightThemeBannerAspectRatio: action.lightThemeBannerAspectRatio,
          darkThemeBannerAspectRatio: action.darkThemeBannerAspectRatio,
          iconPointer: action?.iconPointer?.value,
          textAlignment: action?.textAlignment?.value,
          aspectRatio: action?.aspectRatio
            ? parseFloat(action.aspectRatio)
            : null,
          expandedAspectRatio: action?.expandedAspectRatio
            ? parseFloat(action.expandedAspectRatio)
            : null,
          soundFile: action.soundFile,
          soundFileName: action.soundFileName,
        };

        if (
          !(
            action.darkThemeBackgroundColor != "" ||
            action.darkThemeBackgroundColor != undefined ||
            action.darkThemeBackgroundColor != null
          )
        ) {
          displaySettings.darkThemeBackgroundColor =
            action.darkThemeBackgroundColor;
        }

        if (
          !(
            action.darkThemeTextColor != "" ||
            action.darkThemeTextColor != undefined ||
            action.darkThemeTextColor != null
          )
        ) {
          displaySettings.darkThemeTextColor = action.darkThemeTextColor;
        }

        if (action.soundSettings) {
          displaySettings.soundSettings = {
            ...action.soundSettings,
            playbackDelayInMs: parseInt(
              action.soundSettings.playbackDelayInMs,
              10
            ),
            loop: action.soundSettings.loop,
            loopCount: parseInt(action.soundSettings.loopCount, 10),
          };
        }
        if (leftIconToggle) {
          let leftIconTrackers = { click: [], impression: [] };
          action.leftIcon.trackers.forEach(tracker => {
            if (tracker.type?.value === "click")
              leftIconTrackers.click.push({ type: "direct_url", url: tracker.url })
            else if (tracker.type?.value === "impression")
              leftIconTrackers.impression.push({ type: "direct_url", url: tracker.url })
          })
          displaySettings.leftIcon = {
            ...action.leftIcon,
            widthPercentage: parseFloat(action.leftIcon.widthPercentage),
            aspectRatio: parseFloat(action.leftIcon.aspectRatio),
            trackers: leftIconTrackers,
            image: action.leftIcon.image,
            imageName: action.leftIcon.imageName,
            darkThemeImage: action.leftIcon.darkThemeImage,
            darkThemeImageName: action.leftIcon.darkThemeImageName,
            useExistingLeftIconForDarkTheme: action.leftIcon.useExistingLeftIconForDarkTheme
          };
        }

        if (rightIconToggle) {
          let rightIconTrackers = { click: [], impression: [] };
          action.rightIcon.trackers.forEach(tracker => {
            if (tracker.type?.value === "click")
              rightIconTrackers.click.push({ type: "direct_url", url: tracker.url })
            else if (tracker.type?.value === "impression")
              rightIconTrackers.impression.push({ type: "direct_url", url: tracker.url })
          })
          displaySettings.rightIcon = {
            ...action.rightIcon,
            widthPercentage: parseFloat(action.rightIcon.widthPercentage),
            aspectRatio: parseFloat(action.rightIcon.aspectRatio),
            trackers: rightIconTrackers,
            image: action.rightIcon.image,
            imageName: action.rightIcon.imageName,
            darkThemeImage: action.rightIcon.darkThemeImage,
            darkThemeImageName: action.rightIcon.darkThemeImageName,
          };
          let rightIcon2Trackers = { click: [], impression: [] };
          action.rightIcon2.trackers.forEach(tracker => {
            if (tracker.type?.value === "click")
              rightIcon2Trackers.click.push({ type: "direct_url", url: tracker.url })
            else if (tracker.type?.value === "impression")
              rightIcon2Trackers.impression.push({ type: "direct_url", url: tracker.url })
          })
          displaySettings.rightIcon2 = {
            ...action.rightIcon2,
            widthPercentage: parseFloat(action.rightIcon2.widthPercentage),
            aspectRatio: parseFloat(action.rightIcon2.aspectRatio),
            trackers: rightIcon2Trackers,
            image: action.rightIcon2.image,
            imageName: action.rightIcon2.imageName,
            darkThemeImage: action.rightIcon2.darkThemeImage,
            darkThemeImageName: action.rightIcon2.darkThemeImageName,
          };
        }

        if (action.buttons != null && action.buttons != undefined) {
          displaySettings.buttons = action.buttons.map((button) => {
            let buttonTrackers = { click: [], impression: [] };
            button.trackers.forEach(tracker => {
              if (tracker.type?.value === "click")
                buttonTrackers.click.push({ type: "direct_url", url: tracker.url })
              else if (tracker.type?.value === "impression")
                buttonTrackers.impression.push({ type: "direct_url", url: tracker.url })
            })
            return {
              ...button,
              widthPercentage: parseFloat(button.widthPercentage),
              aspectRatio: parseFloat(button.aspectRatio),
              trackers: buttonTrackers,
              image: button.image,
              imageName: button.imageName,
            };
          });
        }

        if (
          action.textTranslations != null &&
          action.textTranslations != undefined
        ) {
          displaySettings.textTranslations =
            action?.textTranslations && JSON.parse(action.textTranslations);
        }
      }

      const metadata = {};

      // Check if each field in `metadata` is empty, and only add non-empty fields to `metadata`
      if (
        action.categoryID !== undefined &&
        (action?.categoryID?.value !== "" || action?.categoryID?.value !== null)
      ) {
        metadata.categoryId = action.categoryID.value;
      }
      if (
        action.suggestionDrawerCategoryId !== undefined &&
        (action?.suggestionDrawerCategoryId?.value !== "" ||
          action?.suggestionDrawerCategoryId?.value !== null)
      ) {
        metadata.suggestionDrawerCategoryId =
          action.suggestionDrawerCategoryId.value;
      }
      if (action.screenName !== "") {
        metadata.screenName = action.screenName;
      }
      if (action.searchString !== "") {
        metadata.searchString = action.searchString;
      }
      if (action.url !== "") {
        metadata.url = action.url;
      }

      if (
        action.internalRedirection !== undefined &&
        (action.internalRedirection !== "" ||
          action.internalRedirection !== null)
      ) {
        metadata.internalRedirectionType = action.internalRedirection.value;
      }

      let trackers = { click: [], impression: [] };
      action.trackers.forEach(tracker => {
        if (tracker.type?.value === "click")
          trackers.click.push({ type: "direct_url", url: tracker.url })
        else if (tracker.type?.value === "impression")
          trackers.impression.push({ type: "direct_url", url: tracker.url })
      })

      const formAction = {
        type: action.actionType.value,
        metadata: metadata,
        displaySettings: displaySettings,
        trackers: trackers,
        deeplink: action.deeplink,
        dismissible: action.dismissible !== null ? action.dismissible : true,
        bannerAspectRatio: action.bannerAspectRatio,
      };

      formActions.push(formAction);
    });

    const formSettings = {};

    if (!settings.dailyMaxCount && details.minAppVersion.toString() < 7500000) {
      formSettings.dismissCount = 1000;
      formSettings.repeatInterval = 1000;
      formSettings.skipCount = 1000;
      formSettings.keywordMatchCount = 1000;
    } else {
      formSettings.dismissCount = parseInt(settings.dismissCount ?? 100);
      formSettings.repeatInterval = parseInt(settings.repeatInterval);
      formSettings.skipCount = parseInt(settings.skipCount ?? 100);
      formSettings.keywordMatchCount = parseInt(settings.keywordMatchCount ?? 10);
    }
    formSettings.displayDuration = parseInt(settings.displayDuration)
    formSettings.repeatSessionCount = parseInt(settings.repeatSessionCount)
    formSettings.maxCount = parseInt(settings.maxCount)
    formSettings.dailyMaxCount = parseInt(settings.dailyMaxCount)
    formSettings.intentIds = settings.intentIds || [];
    formSettings.endTime = settings.endTime;
    formSettings.startTime = settings.startTime;
    formSettings.packageSets = [];
    formSettings.blacklistFilters = settings.blacklistFilters || [];
    formSettings.triggerAction = settings?.triggerAction?.value;
    if (eventFiltersToggle === true)
      formSettings.eventFilters = JSON.parse(settings.eventFilters);
    if (appStateFiltersToggle === true)
      formSettings.appStateFilters = JSON.parse(settings.appStateFilters);
    if (packageFiltersToggle === true)
      formSettings.packageFilters = JSON.parse(settings.packageFilters);
    formSettings.placements =
      settings.placements.map((placement) => placement.value) || [];

    if (settings.packageSets !== undefined) {
      settings.packageSets.forEach((packageSet) => {
        const formPackageSet = {
          id: packageSet?.value,
          name: packageSet?.label,
        };

        formSettings.packageSets.push(formPackageSet);
      });
    }

    formSettings.keywords = {};

    if (keywords.length > 0) {
      keywords.forEach((keyword) => {
        if (keyword?.languageCode?.value !== undefined)
          formSettings.keywords[keyword?.languageCode?.value] = keyword.tags;
      });
    }

    // make a json payload
    let prompt = {
      name: details.name,
      description: details.description,
      type: details.type?.value ?? "prompt",
      category: details.category?.value ?? "redirection",
      brandCampaignId: details.brandCampaign?.value === "none" ? undefined : details.brandCampaign?.value,
      suggestionDrawerCategoryId: details?.suggestionDrawerCategory?.value,
      priority: parseInt(details.priority ?? 1),
      assetsPreloadPriority: details?.assetsPreloadPriority?.value,
      endDate: details.endDate ? moment(details.endDate).format("YYYY-MM-DD") + "T00:00:00Z" : null,
      startDate: details.startDate ? moment(details.startDate).format("YYYY-MM-DD") + "T00:00:00Z" : null,
      minAppVersion: details.minAppVersion.toString(),
      maxAppVersion:
        details.maxAppVersion !== "" && details.maxAppVersion !== null
          ? details.maxAppVersion.toString()
          : null,
      triggerKeywordCharacterLimit:
        details.triggerKeywordCharacterLimit !== "" &&
          details.triggerKeywordCharacterLimit !== null
          ? parseInt(details.triggerKeywordCharacterLimit)
          : null,
      settings: formSettings,
      actions: formActions,
      isLegacyConfigPrompt: details.isLegacyConfigPrompt,
      widthPercentage: parseFloat(details.widthPercentage),
      supportedUITypes: details.supportedUITypes,
    };

    // if intentToggle is true, make keywords an empty object and if it is false, make intentIds an empty array
    if (intentToggle === true) {
      prompt.settings.keywords = {};
    } else {
      prompt.settings.intentIds = [];
    }

    if (props.match.params.id) {
      let response = await updateKeyboardPrompts(prompt, props.match.params.id);
      if (response.status === "success") {
        setSuccessModalText(keyboardPromptResponses.updatedSuccessfully);
        setSuccessModalDisplay(true);
      } else {
        setFailureModalText(errorDescription(response.errorCode));
        setFailureModalDisplay(true);
      }
      return;
    }

    // make add request
    let response = await addKeyboardPrompts(prompt);

    if (response.status === "success") {
      setSuccessModalText(keyboardPromptResponses.addeddSuccessfully);
      setSuccessModalDisplay(true);
    } else {
      setFailureModalText(errorDescription(response.errorCode));
      setFailureModalDisplay(true);
    }
  };

  const convertUtcToIst = (utcTime) => {
    var dateObj = new Date(utcTime);
    var utcString = dateObj.toUTCString();
    var utc = Date.parse(utcString);
    var offset = 5.5 * 60 * 60; // 5 hours and 30 minutes ahead of UTC
    var ist = new Date(utc + offset);

    // now convert this ist to YYYY-MM-DDTHH:MM format
    var year = ist.getFullYear();
    var month = ist.getMonth() + 1;
    var date = ist.getDate();
    var hours = ist.getHours();
    var minutes = ist.getMinutes();

    if (month < 10) {
      month = "0" + month;
    }
    if (date < 10) {
      date = "0" + date;
    }
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    ist = year + "-" + month + "-" + date + "T" + hours + ":" + minutes;
    return ist;
  };

  const fetchKeyboardPromptsData = async (id) => {
    setLoading(true)
    const response = await fetchKeyboardPromptsByID(id);
    // convert into local time
    // if (response.startDate !== null)
    //   response.startDate = convertUtcToIst(response.startDate);

    // if (response.endDate !== null)
    //   response.endDate = convertUtcToIst(response.endDate);

    // set details
    setDetails((prevState) => ({
      ...prevState,
      name: response.name,
      description: response.description,
      type: addTypeOptions.find((option) => option.value === response.type),
      category: categoryOptions.find(
        (option) => option.value === response.category
      ),
      priority: response.priority,
      assetsPreloadPriority: assetsPreloadPriorityOptions.find(
        (option) => option.value === response.assetsPreloadPriority
      ),
      isLegacyConfigPrompt: response.isLegacyConfigPrompt,
      triggerKeywordCharacterLimit: response.triggerKeywordCharacterLimit,
      endDate: response.endDate,
      startDate: response.startDate,
      minAppVersion: response.minAppVersion || 1,
      maxAppVersion: response.maxAppVersion,
      widthPercentage: response.widthPercentage,
      supportedUITypes: response.supportedUITypes,
    }));

    // set settings
    setSettings((prevState) => ({
      ...prevState,
      dailyMaxCount: response.settings.dailyMaxCount,
      endTime: response.settings.endTime === null || response.settings.endTime.trim() === "" ? null : response.settings.endTime.trim(),
      intentIds: response.settings.intentIds || [],
      startTime: response.settings.startTime === null || response.settings.startTime.trim() === "" ? null : response.settings.startTime.trim(),
      maxCount: response.settings.maxCount,
      repeatSessionCount: response.settings.repeatSessionCount,
      blacklistFilters: response.settings.blacklistFilters || [],
      displayDuration: response.settings.displayDuration,
      dismissCount: response.settings.dismissCount,
      repeatInterval: response.settings.repeatInterval,
      skipCount: response.settings.skipCount,
      keywordMatchCount: response.settings.keywordMatchCount,
      triggerAction: response.settings.triggerAction && {
        label: response.settings.triggerAction,
        value: response.settings.triggerAction,
      },
      eventFilters: response.settings.eventFilters
        ? JSON.stringify(response.settings.eventFilters, undefined, 4)
        : [],
      appStateFilters: response.settings.appStateFilters
        ? JSON.stringify(response.settings.appStateFilters, undefined, 4)
        : [],
      packageFilters: response.settings.packageFilters
        ? JSON.stringify(response.settings.packageFilters, undefined, 4)
        : [],
      placements:
        (response.settings.placements &&
          response.settings.placements.map((placement) => {
            return { value: placement, label: placement };
          })) ||
        [],
    }));

    if (response.settings.intentIds && response.settings.intentIds.length > 0) {
      setIntentToggle(true);
    }

    if (
      response.settings.eventFilters &&
      response.settings.eventFilters.length > 0
    ) {
      setEventFiltersToggle(true);
    }

    if (
      response.settings.appStateFilters &&
      response.settings.appStateFilters.length > 0
    ) {
      setAppStateFiltersToggle(true);
    }

    if (response.settings.packageFilters) {
      setPackageFiltersToggle(true);
    }

    if (
      response.settings.placements &&
      response.settings.placements.length > 0
    ) {
      setPlacementsToggle(true);
    }

    // set actions

    setPromptData(response);

    setLoading(false)
  };

  const [loading, setLoading] = useState(false)
  const [isDetatilsAdvanceOptionEnabled, setIsDetatilsAdvanceOptionEnabled] = useState(false)
  const [isSettingsAdvanceOptionEnabled, setIsSettingsAdvanceOptionEnabled] = useState(false)
  const [addBrandCampaignIDOption, setAddBrandCampaignIDOption] = useState([]);
  const [addSDCategoryOption, setAddSDCategoryOption] = useState([]);
  const [addPackageSetsOption, setAddPackageSetsOption] = useState([]);
  const [addLanguageCodeOption, setAddLanguageCodeOption] = useState([]);
  const [addCategoryOption, setAddCategoryOption] = useState([]);
  const [addQuickRepliesOption, setAddQuickRepliesOption] = useState([]);
  const [action, setAction] = useState("");
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");
  const [changeType, setChangeType] = useState("Add");
  const [promptData, setPromptData] = useState({});
  const [intentToggle, setIntentToggle] = useState(false);
  const [eventFiltersToggle, setEventFiltersToggle] = useState(false);
  const [appStateFiltersToggle, setAppStateFiltersToggle] = useState(false);
  const [packageFiltersToggle, setPackageFiltersToggle] = useState(false);
  const [placementsToggle, setPlacementsToggle] = useState(false);
  const [leftIconToggle, setLeftIconToggle] = useState(false);
  const [rightIconToggle, setRightIconToggle] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [widthPercentageError, setWidthPercentageError] = useState(false);
  const [formError, setFormError] = useState("");

  const addText = (actionIndex) => {
    setActions((prevActions) =>
      prevActions.map((action, index) =>
        index === actionIndex
          ? { ...action, texts: [...(action.texts || []), ""] }
          : action
      )
    );
  };

  const removeText = (actionIndex, textIndexToRemove) => {
    setActions((prevActions) =>
      prevActions.map((action, index) =>
        index === actionIndex
          ? {
            ...action,
            texts: action.texts.filter(
              (_, tIndex) => tIndex !== textIndexToRemove
            ),
          }
          : action
      )
    );
  };

  const [assetsPreloadPriorityOptions, setAssetsPreloadPriorityOptions] =
    useState([
      { value: 0, label: "High" },
      { value: 1, label: "Medium" },
      { value: 2, label: "Low" },
    ]);

  const [contentCategories, setContentCategories] = useState([]);

  const [details, setDetails] = useState({
    name: "",
    description: "",
    type: null,
    isLegacyConfigPrompt: false,
    category: null,
    brandCampaign: "",
    suggestionDrawerCategory: "",
    priority: null,
    assetsPreloadPriority: assetsPreloadPriorityOptions[0],
    triggerKeywordCharacterLimit: "",
    endDate: null,
    startDate: null,
    minAppVersion: "6400002",
    maxAppVersion: "",
    widthPercentage: .8,
    supportedUITypes: [],
  });

  const [settings, setSettings] = useState({
    repeatSessionCount: "",
    maxCount: "",
    dailyMaxCount: "",
    packageSets: [],
    endTime: null,
    intentIds: [] || [],
    blacklistFilters: [],
    startTime: null,
    displayDuration: null,
    dismissCount: null,
    repeatInterval: "",
    skipCount: null,
    keywordMatchCount: null,
    eventFilters: [],
    appStateFilters: [],
    packageFilters: [],
    placements: [],
    triggerAction: "",
    supportedUITypes: [],
  });

  const [actions, setActions] = useState([
    {
      actionType: "",
      categoryID: "",
      suggestionDrawerCategoryId: "",
      screenName: "",
      searchString: "",
      url: "",
      backgroundColor: "",
      darkThemeBackgroundColor: "",
      textColor: "",
      darkThemeTextColor: "",
      text: "",
      texts: [],
      textsRotationIntervalInMs: "",
      textSize: "",
      description: "",
      dismissible: true,
      bannerAspectRatio: 0,
      darkThemeBannerAspectRatio: 0,
      lightThemeBannerAspectRatio: 0,
      bannerImage: "",
      bannerImageName: "",
      bannerImageURL: "",
      darkThemeBannerImage: "",
      darkThemeBannerImageName: "",
      darkThemeBannerImageURL: "",
      trackers: trackersInitialState,
      deeplink: null,
      displayToggle: false,
      internalRedirection: "",
      textAlignment: "",
      aspectRatio: "",
      expandedAspectRatio: "",
      iconPointer: {},
      leftIcon: {
        trackers: trackersInitialState,
        useExistingLeftIconForDarkTheme: false
      },
      rightIcon: {
        trackers: trackersInitialState
      },
      rightIcon2: {
        trackers: trackersInitialState
      },
      soundSettings: {},
      buttons: [],
      soundFile: "",
      soundFileName: "",
      soundFileURL: "",
      textTranslations: "{}",
      isDisplayAdvanceOptionEnabled: false,
      useExistingBannerImage: false,
    },
  ]);

  const [keywords, setKeywords] = useState([
    {
      languageCode: "",
      tags: [],
    },
  ]);

  let getDateValue = (date) => {
    if (date === null) {
      return null;
    }
    return moment(date).format("YYYY-MM-DD");
  };

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let setConfirmationModalState = (event) => {
    event.preventDefault();
    setAction("Submit");
    setConfirmationModalIsOpen(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    redirectTo(Routes.keyboardPrompt.children.keyboardPrompts.path);
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  const setBrandCampaign = async () => {
    let brandCampaignOptions = []
    brandCampaignOptions.push({
      value: "none",
      label: "None",
      target: {
        name: "brandCampaign",
        value: {
          value: "none",
          label: "None",
        },
      },
    })
    const brandCampaigns = await getBrandCampaigns();

    if (brandCampaigns?.length > 0) {
      brandCampaigns.forEach((brandCampaign) => {
        brandCampaignOptions.push({
          value: brandCampaign.value,
          label: brandCampaign.label,
          target: {
            name: "brandCampaign",
            value: {
              value: brandCampaign.value,
              label: brandCampaign.label,
            },
          },
        })
      });
    }
    setAddBrandCampaignIDOption(brandCampaignOptions);
  };
  const SetSDCategories = async () => {
    let params = {
      status: "activated",
    };
    const categories = await getSuggestionDrawerCategories(params);
    if (categories?.categories?.length > 0) {
      const categoryOptions = categories.categories.map((category) => {
        return {
          value: category.id,
          label: category.name + " : " + category.id,
          target: {
            name: "suggestionDrawerCategory",
            value: {
              value: category.id,
              label: category.name + " : " + category.id,
            },
          },
        };
      });

      const nullCategory = {
        value: null,
        label: "None",
        target: {
          name: "suggestionDrawerCategory",
          value: {
            value: null,
            label: "None",
          },
        },
      };
      // add suggestion drawer categories

      // add null category to the dropdown
      categoryOptions.unshift(nullCategory);

      setAddSDCategoryOption(categoryOptions);
    }
  };
  const setPackageSets = async () => {
    const packageSets = await fetchPackageSetData();

    if (packageSets?.packageSets?.length > 0) {
      const packageSetsOptions = packageSets.packageSets.map((packageSet) => {
        return {
          value: packageSet.id,
          label: packageSet.name,
          target: {
            name: "packageSets",
            value: {
              value: packageSet.id,
              label: packageSet.name,
            },
          },
        };
      });
      setAddPackageSetsOption(packageSetsOptions);
    }
  };

  const setLanguageCode = async () => {
    const languageCode = await getKeyboardPromptsLanguages();
    if (languageCode?.languages?.length > 0) {
      const languageCodeOptions = languageCode.languages.map((language) => {
        return {
          value: language.code,
          label: language.name + " : " + language.code,
          target: {
            name: "languageCode",
            value: {
              value: language.code,
              label: language.name + " : " + language.code,
            },
          },
        };
      });
      setAddLanguageCodeOption(languageCodeOptions);
    }
  };

  const setCategories = async () => {
    const categories = await getCategories();
    if (categories?.contentCategories?.length > 0) {
      setContentCategories(categories.contentCategories);
      const categoryOptions = categories.contentCategories.map((category) => {
        return {
          value: category.id,
          label: category.name + " : " + category.id,
          target: {
            name: "categoryID",
            value: {
              value: category.id,
              label: category.name + " : " + category.id,
            },
          },
        };
      });

      const nullCategory = {
        value: null,
        label: "None",
        target: {
          name: "categoryID",
          value: {
            value: null,
            label: "None",
          },
        },
      };

      // add null category to the dropdown
      categoryOptions.unshift(nullCategory);

      setAddCategoryOption(categoryOptions);
    }
  };

  const setQuickReplies = async () => {
    const quickReplies = await getQuickReplyCategories();
    if (quickReplies?.quickReplyCategories?.length > 0) {
      const quickRepliesOptions = quickReplies.quickReplyCategories.map(
        (quickReply) => {
          return {
            value: quickReply.ID,
            label: quickReply.name + " : " + quickReply.ID,
            target: {
              name: "quickReplyCategoryID",
              value: {
                value: quickReply.ID,
                label: quickReply.name + " : " + quickReply.ID,
              },
            },
          };
        }
      );

      const nullQuickReply = {
        value: null,
        label: "None",
        target: {
          name: "quickReplyCategoryID",
          value: {
            value: null,
            label: "None",
          },
        },
      };

      // add null quick reply to the dropdown
      quickRepliesOptions.unshift(nullQuickReply);

      setAddQuickRepliesOption(quickRepliesOptions);
    }
  };

  const supportedUITypesOptions = [
    { value: "prompts", label: "prompts" },
    { value: "pills", label: "pills" },
  ];

  const checkFileSizeLimit = (file, imageName, staticSizeLimit, animatedSizeLimit) => {
    const fileSizeError = "Image should be less than ";
    const animatedImagesType = [
      "image/gif",
      "image/webp",
    ]

    if (animatedImagesType.includes(file.type) && file.size > animatedSizeLimit) {
      showErrorModal(imageName + (file.type === "image/gif" ? " Gif " : " Webp ") + fileSizeError + convertBytesToKB(animatedSizeLimit) + "KB");
      return true
    }
    else if (!animatedImagesType.includes(file.type) && file.size > staticSizeLimit) {
      showErrorModal(imageName + " " + fileSizeError + convertBytesToKB(staticSizeLimit) + "KB");
      return true
    }
  }

  const showLanguageCodeOptions = () => {
    let finalLanguageCode = addLanguageCodeOption;
    // remove language codes from the dropdown those are already selected.
    keywords.forEach((keyword) => {
      finalLanguageCode = finalLanguageCode.filter(
        (item) => item?.value !== keyword.languageCode?.value
      );
    });
    return finalLanguageCode;
  };

  const handleDetailsChange = (event) => {
    const { name, value } = event.target;

    // If the input is for 'widthPercentage'
    if (name === "widthPercentage") {
      // Parse the input value as a float
      let newValue = parseFloat(value);

      // Check if the parsed value is a valid number between 0 and 1
      if (!isNaN(newValue) && newValue >= 0 && newValue <= 1) {
        // If valid, update the state with the new value and clear the error
        setDetails((prevState) => ({
          ...prevState,
          [name]: newValue,
        }));
        setWidthPercentageError(false);
      } else {
        // If the value is invalid, set the error
        setWidthPercentageError(true);
      }
    } else if (name === "type") {
      // If the input is for 'type', clear the 'supportuitype' field
      setDetails((prevState) => ({
        ...prevState,
        [name]: value,
        supportedUITypes: [], // Clear the supportuitype field
      }));
    } else {
      // For other inputs, update the state directly
      setDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleDetailsChangeDate = (event) => {
    const { name, value } = event.target;
    const parsedValue = value.trim(); // Trim whitespace
    setDetails((prevState) => ({
      ...prevState,
      [name]: parsedValue === "" ? null : parsedValue,
    }));
  };

  const handleSuggestionDrawerCategoryChange = (event) => {
    const { name, value } = event.target;
    setDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log("suggestion drawer category", value);
    console.log("suggestion drawer category", name);
  };

  const categoryhandleDetailsChange = (event) => {
    const { name, value } = event.target;
    setDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSettingsChange = (event) => {
    const { name, value } = event.target;
    setSettings((prevState) => ({
      ...prevState,
      [name]: value.trim() === "" ? null : value.trim(), // Convert empty string or whitespace to null
    }));
  };

  const handleLanguageCodeChange = (event, index) => {
    const { name, value } = event.target;
    let temp = [...keywords];
    temp[index][name] = value;
    setKeywords(temp);
  };

  const handleIntentsIdsChange = (ids) => {
    // convert ids to array of numbers
    const idsArray = ids.map((id) => parseInt(id));
    // if not a number, remove it from the array
    const filteredIds = idsArray.filter((id) => !isNaN(id));
    // remove duplicates if present in the event
    const uniqueIds = [...new Set(filteredIds)];
    if (uniqueIds.length > 0) {
      setSettings((prevState) => ({
        ...prevState,
        ["intentIds"]: uniqueIds,
      }));
    } else {
      setSettings((prevState) => ({
        ...prevState,
        ["intentIds"]: [],
      }));
    }
  };

  const handleBlacklistFilters = (filters) => {
    let temp = [...filters];
    // remove spaces from all tags
    const filtersTrimmed = temp.map((item) => item.trim());
    // remove duplicates if present in the event
    const uniqueFilters = [...new Set(filtersTrimmed)];

    setSettings((prevState) => ({
      ...prevState,
      ["blacklistFilters"]: uniqueFilters,
    }));
  };

  const handleTagsChange = (tags, index) => {
    let temp = [...keywords];

    // Split the input value by commas and trim spaces around each tag
    const tagsSplit = tags
      .map((item) => item.split(","))
      .flat() // Flatten the array to remove nested arrays
      .map((item) => item.trim());

    // Remove duplicates by converting the tagsSplit array to a Set and then back to an array
    const uniqueTags = [...new Set(tagsSplit)];

    temp[index].tags = uniqueTags;
    setKeywords(temp);
  };

  const handleRepeatKeywords = () => {
    const selectedLanguages = keywords.map((item) => item.languageCode.value);
    const keywordsToRepeat = keywords.length > 0 ? keywords[0].tags : [];
    const repeatedKeywords = selectedLanguages.map((language) => ({
      languageCode: { value: language, label: language },
      tags: keywordsToRepeat,
    }));
    setKeywords([...keywords, ...repeatedKeywords]);
  };

  // make the input field required
  const requiredComponent = (props) => (
    <div>
      <components.Input {...props} required={true} />
    </div>
  );

  const notRequiredComponent = (props) => (
    <div>
      <components.Input {...props} required={false} />
    </div>
  );

  const filterContentCategories = (category) => {
    const filteredCategories = [];

    contentCategories.map((item) => {
      item.contentTypes.forEach((contentType) => {
        if (contentType.contentType === category) {
          filteredCategories.push(item);
          // break the loop
          return false;
        }
      });
    });

    const categoryOptions = filteredCategories.map((category) => {
      return {
        value: category.id,
        label: category.name + " : " + category.id,
        target: {
          name: "categoryID",
          value: {
            value: category.id,
            label: category.name + " : " + category.id,
          },
        },
      };
    });

    const nullCategory = {
      value: null,
      label: "None",
      target: {
        name: "categoryID",
        value: {
          value: null,
          label: "None",
        },
      },
    };

    // add null category to the dropdown
    categoryOptions.unshift(nullCategory);

    return categoryOptions;
  };

  useEffect(() => {
    setLoading(true)
    Promise.all([
      setBrandCampaign(),
      setPackageSets(),
      setLanguageCode(),
      setCategories(),
      setQuickReplies(),
      SetSDCategories()
    ]).finally(() => {
      setLoading(false)
    })
    // check path params
    if (props.match.params.id) {
      setChangeType("Edit");
      fetchKeyboardPromptsData(props.match.params.id);
    } else if (props.location.state.clonePromptId) {
      setChangeType("Add");
      fetchKeyboardPromptsData(props.location.state.clonePromptId);
    }
  }, []);

  useEffect(() => {
    if (addBrandCampaignIDOption?.length > 0 && promptData?.id) {
      setDetails((prevState) => ({
        ...prevState,
        brandCampaign: !promptData.brandCampaignId ? addBrandCampaignIDOption.find(
          (option) => option.value === "none"
        ) : addBrandCampaignIDOption.find(
          (option) => option.value === promptData.brandCampaignId
        ),
      }));
    }
  }, [addBrandCampaignIDOption, promptData]);

  useEffect(() => {
    if (addSDCategoryOption?.length > 0) {
      setDetails((prevState) => ({
        ...prevState,
        suggestionDrawerCategory: addSDCategoryOption.find(
          (option) => option.value === promptData.suggestionDrawerCategoryId
        ),
      }));
    }
  }, [addSDCategoryOption, promptData]);

  useEffect(() => {
    setSettings((prevState) => ({
      ...prevState,
      packageSets: promptData?.settings?.packageSets?.map((packageSet) => {
        return addPackageSetsOption.find(
          (option) => option.label === packageSet
        );
      }),
    }));
  }, [promptData, addPackageSetsOption]);

  useEffect(() => {
    // set keywords from response.settings.keywords
    const fetchKeywords = [];

    if (
      promptData?.settings?.keywords !== undefined &&
      promptData?.settings?.keywords !== null
    ) {
      Object.keys(promptData?.settings?.keywords).forEach((key) => {
        fetchKeywords.push({
          languageCode: addLanguageCodeOption.find((option) => {
            if (option.value === key) {
              return true;
            }
          }),
          tags: promptData?.settings?.keywords[key],
        });
      });

      // do not set keywords if there fetchKeyword.languageCode is undefined
      if (fetchKeywords[0]?.languageCode !== undefined) {
        setKeywords(fetchKeywords);
      }
    }
  }, [promptData, addLanguageCodeOption]);

  useEffect(() => {
    if (promptData?.actions) {
      const fetchActions = [];

      promptData.actions.forEach((action) => {
        const fetchAction = {};

        fetchAction.actionType = addActionTypeOptions.find(
          (option) => option.value === action.type
        );

        if (
          action.metadata?.categoryId !== undefined &&
          addCategoryOption.length > 0 &&
          action.metadata.internalRedirectionType !== "quotesShayariJokes"
        ) {
          fetchAction.categoryID = addCategoryOption?.find(
            (option) => option?.value === parseInt(action.metadata.categoryId)
          );
        }

        if (
          action.metadata?.suggestionDrawerCategoryId !== undefined &&
          addSDCategoryOption.length > 0
        ) {
          fetchAction.suggestionDrawerCategoryId = addSDCategoryOption?.find(
            (option) =>
              option?.value ===
              parseInt(action.metadata.suggestionDrawerCategoryId)
          );
        }

        if (
          action.metadata?.categoryId !== undefined &&
          addQuickRepliesOption.length > 0 &&
          action.metadata.internalRedirectionType === "quotesShayariJokes"
        ) {
          fetchAction.categoryID = addQuickRepliesOption?.find(
            (option) => option?.value === parseInt(action.metadata.categoryId)
          );
        }

        if (action.metadata?.internalRedirectionType !== undefined) {
          fetchAction.internalRedirection = addActionTypeOptions
            .find((x) => x.value === "internalRedirection")
            .internalRedirectionOptions.find(
              (option) =>
                option.value === action.metadata.internalRedirectionType
            );
        }

        if (action.metadata?.screenName !== undefined) {
          fetchAction.screenName = action.metadata.screenName;
        }

        if (action.metadata?.searchString !== undefined) {
          fetchAction.searchString = action.metadata.searchString;
        }

        if (action.metadata?.url !== undefined) {
          fetchAction.url = action.metadata.url;
        }

        // Parse action trackers
        let trackers = []
        if (action?.trackers?.click?.length > 0 || action?.trackers?.impression?.length > 0) {
          action.trackers.click.forEach(ct => {
            trackers.push({
              type: trackerTypeOptions.find(option => option.value === "click"),
              url: ct.url
            })
          })
          action.trackers.impression.forEach(it => {
            trackers.push({
              type: trackerTypeOptions.find(option => option.value === "impression"),
              url: it.url
            })
          })
        } else {
          trackers = trackersInitialState
        }
        fetchAction.trackers = trackers;
        fetchAction.deeplink = action.deeplink;

        if (fetchAction.deeplink === "null") fetchAction.deeplink = "";

        if (action.displaySettings === null) {
          fetchAction.displaySettings = null;
        } else {
          fetchAction.dismissible = action.displaySettings.dismissible;
          fetchAction.bannerAspectRatio =
            action.displaySettings.bannerAspectRatio;
          fetchAction.lightThemeBannerAspectRatio =
            action.displaySettings.lightThemeBannerAspectRatio;
          fetchAction.darkThemeBannerAspectRatio =
            action.displaySettings.darkThemeBannerAspectRatio;
          fetchAction.backgroundColor = action.displaySettings.backgroundColor;
          fetchAction.darkThemeBackgroundColor =
            action.displaySettings.darkThemeBackgroundColor;
          fetchAction.textColor = action.displaySettings.textColor;
          fetchAction.darkThemeTextColor =
            action.displaySettings.darkThemeTextColor;
          fetchAction.bannerImage = "";
          fetchAction.textAlignment = {
            value: action.displaySettings.textAlignment,
            label: action.displaySettings.textAlignment,
          };
          fetchAction.soundSettings = action.displaySettings.soundSettings
            ? {
              ...action.displaySettings.soundSettings,
            }
            : {};
          fetchAction.textSize = action.displaySettings.textSize && {
            value: action.displaySettings.textSize,
            label: action.displaySettings.textSize,
          };
          fetchAction.aspectRatio = action.displaySettings.aspectRatio;
          fetchAction.expandedAspectRatio =
            action.displaySettings.expandedAspectRatio;
          fetchAction.iconPointer = {
            value: action.displaySettings.iconPointer,
            label: action.displaySettings.iconPointer,
          };
          fetchAction.buttons = action.displaySettings.buttons;

          // Parse left icon trackers
          let leftIconTrackers = []
          if (action?.displaySettings?.leftIcon?.trackers?.click?.length > 0 || action?.displaySettings?.leftIcon?.trackers?.impression?.length > 0) {
            action.displaySettings.leftIcon.trackers.click.forEach(ct => {
              leftIconTrackers.push({
                type: trackerTypeOptions.find(option => option.value === "click"),
                url: ct.url
              })
            })
            action.displaySettings.leftIcon.trackers.impression.forEach(it => {
              leftIconTrackers.push({
                type: trackerTypeOptions.find(option => option.value === "impression"),
                url: it.url
              })
            })
          } else {
            leftIconTrackers = trackersInitialState
          }
          fetchAction.leftIcon = action.displaySettings.leftIcon
            ? {
              ...action.displaySettings.leftIcon,
              trackers: leftIconTrackers
            }
            : {};

          // Parse right icon trackers
          let rightIconTrackers = []
          if (action?.displaySettings?.rightIcon?.trackers?.click?.length > 0 || action?.displaySettings?.rightIcon?.trackers?.impression?.length > 0) {
            action.displaySettings.rightIcon.trackers.click.forEach(ct => {
              rightIconTrackers.push({
                type: trackerTypeOptions.find(option => option.value === "click"),
                url: ct.url
              })
            })
            action.displaySettings.rightIcon.trackers.impression.forEach(it => {
              rightIconTrackers.push({
                type: trackerTypeOptions.find(option => option.value === "impression"),
                url: it.url
              })
            })
          } else {
            rightIconTrackers = trackersInitialState
          }
          fetchAction.rightIcon = action.displaySettings.rightIcon
            ? {
              ...action.displaySettings.rightIcon,
              trackers: rightIconTrackers
            }
            : {};

          // Parse right icon 2 trackers
          let rightIcon2Trackers = []
          if (action?.displaySettings?.rightIcon2?.trackers?.click?.length > 0 || action?.displaySettings?.rightIcon2?.trackers?.impression?.length > 0) {
            action.displaySettings.rightIcon2.trackers.click.forEach(ct => {
              rightIcon2Trackers.push({
                type: trackerTypeOptions.find(option => option.value === "click"),
                url: ct.url
              })
            })
            action.displaySettings.rightIcon2.trackers.impression.forEach(it => {
              rightIcon2Trackers.push({
                type: trackerTypeOptions.find(option => option.value === "impression"),
                url: it.url
              })
            })
          } else {
            rightIcon2Trackers = trackersInitialState
          }
          fetchAction.rightIcon2 = action.displaySettings.rightIcon2
            ? {
              ...action.displaySettings.rightIcon2,
              trackers: rightIcon2Trackers,
            }
            : {};
          fetchAction.buttons = action.displaySettings.buttons
            ? action.displaySettings.buttons.map((btn) => {
              // Button trackers
              let buttonTrackers = []
              if (btn?.trackers?.click?.length > 0 || btn?.trackers?.impression?.length > 0) {
                btn.trackers.click.forEach(ct => {
                  buttonTrackers.push({
                    type: trackerTypeOptions.find(option => option.value === "click"),
                    url: ct.url
                  })
                })
                btn.trackers.impression.forEach(it => {
                  buttonTrackers.push({
                    type: trackerTypeOptions.find(option => option.value === "impression"),
                    url: it.url
                  })
                })
              } else {
                buttonTrackers = trackersInitialState
              }
              return {
                ...btn,
                trackers: buttonTrackers,
              };
            })
            : [];

          fetchAction.textTranslations = action.displaySettings.textTranslations
            ? JSON.stringify(action.displaySettings.textTranslations, null, 2)
            : "{}";
          // add the last part of the url
          fetchAction.bannerImageName = action.displaySettings.bannerImageURL
            ?.split("/")
            .pop();
          fetchAction.bannerImageURL = action.displaySettings.bannerImageURL;

          fetchAction.darkThemeBannerImage = "";
          // add the last part of the url
          fetchAction.darkThemeBannerImageName =
            action.displaySettings.darkThemeBannerImageURL?.split("/").pop();
          fetchAction.darkThemeBannerImageURL =
            action.displaySettings.darkThemeBannerImageURL;

          fetchAction.text = action.displaySettings.text;
          fetchAction.texts = action.displaySettings.texts;
          fetchAction.textsRotationIntervalInMs =
            action.displaySettings.textsRotationIntervalInMs;
          fetchAction.description = action.displaySettings.description;
          fetchAction.displayToggle = true;

          if (fetchAction.leftIcon?.imageURL != undefined) {
            fetchAction.leftIcon.imageName = fetchAction.leftIcon.imageURL
              .split("/")
              .pop();
            if (fetchAction.leftIcon.darkThemeImageURL != undefined) {
              fetchAction.leftIcon.darkThemeImageName =
                fetchAction.leftIcon.darkThemeImageURL.split("/").pop();
            }
            setLeftIconToggle(true);
          }
          if (fetchAction.rightIcon?.imageURL != undefined) {
            fetchAction.rightIcon.imageName = fetchAction.rightIcon.imageURL
              .split("/")
              .pop();
            if (fetchAction.rightIcon.darkThemeImageURL != undefined) {
              fetchAction.rightIcon.darkThemeImageName =
                fetchAction.rightIcon.darkThemeImageURL.split("/").pop();
            }
            setRightIconToggle(true);
          }
          if (fetchAction.rightIcon2?.imageURL != undefined) {
            fetchAction.rightIcon2.imageName = fetchAction.rightIcon2.imageURL
              .split("/")
              .pop();
            if (fetchAction.rightIcon2.darkThemeImageURL != undefined) {
              fetchAction.rightIcon2.darkThemeImageName =
                fetchAction.rightIcon2.darkThemeImageURL.split("/").pop();
            }
            setRightIconToggle(true);
          }
          if (fetchAction.bannerImageURL != "" && (fetchAction.bannerImageURL == fetchAction.darkThemeBannerImageURL)) {
            fetchAction.useExistingBannerImage = true
          }
          if ((fetchAction.leftIcon?.imageURL == fetchAction.leftIcon?.darkThemeImageURL)) {
            fetchAction.leftIcon.useExistingLeftIconForDarkTheme = true
          }
        }
        fetchActions.push(fetchAction);
      });

      setActions(fetchActions);
    }
  }, [
    promptData,
    addCategoryOption,
    addQuickRepliesOption,
    addSDCategoryOption,
  ]);

  const handleSupportedUITypesChange = (selectedOptions) => {
    const maxSelections =
      details.type &&
        (details.type.value === "suggestionDrawer" ||
          details.type.value === "aiPoweredBar" ||
          details.type.value === "aiClipboard")
        ? 2 // Allow up to two selections for these types
        : 1; // Allow only one selection for other types

    const supportedUITypes = selectedOptions
      ? selectedOptions.slice(0, maxSelections).map((option) => option.value)
      : [];

    setDetails((prevDetails) => ({
      ...prevDetails,
      supportedUITypes: supportedUITypes,
    }));

    // Check if both "prompts" and "pills" are selected when type is "aiPoweredBar" or "aiClipboard"
    if (
      (details.type?.value === "aiPoweredBar" || details.type?.value === "aiClipboard" || details.type?.value === "suggestionDrawer") &&
      !(supportedUITypes.includes("prompts") && supportedUITypes.includes("pills"))
    ) {
      setFormError("Both 'prompts' and 'pills' must be selected for the chosen type.");
    } else {
      setFormError(""); // Clear error if valid
    }
  };

  // Filtered options are always the full list of options
  const filteredUITypesOptions = supportedUITypesOptions;

  return (
    <Container fluid className="p-0">
      <Row className="mb-3">
        <Col md={6} xs={6}>
          <h1>{changeType} Keyboard Prompts</h1>
          {!showEditKeyboardPromptsButton() && (
            <p className="text-info">
              You don`t have permission to Edit KB prompt
            </p>
          )}
        </Col>
      </Row>

      {loading ? (
        <Loading />
      ) : (
        <Form onSubmit={(event) => setConfirmationModalState(event)}>
          <Row>
            <Col lg="12">
              <Row className="mb-3">
                <Col md={6} xs={6}>
                  <h3>Details</h3>
                </Col>
                {showAddKeyboardPromptsButton() && props.match.params.id && (
                  <Col md={6} xs={6}>
                    <Link
                      className="text-decoration-none"
                      to={{
                        pathname: `/keyboard-prompt/prompts/add`,
                        state: {
                          formType: "create",
                          clonePromptId: props.match.params.id
                        },
                      }}
                    >
                      <Button className="float-right">Clone KB Prompt</Button>
                    </Link>
                  </Col>
                )}
              </Row>
              <Card>
                <CardBody>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "16px" }}
                        >
                          Name <span className="text-danger">*</span>{" "}
                        </Label>
                        <Input
                          type="text"
                          name="name"
                          placeholder="Enter Name"
                          defaultValue={details.name}
                          onChange={handleDetailsChange}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "16px" }}
                        >
                          Description
                        </Label>
                        <Input
                          type="text"
                          name="description"
                          placeholder="Enter Description"
                          defaultValue={details.description}
                          onChange={handleDetailsChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "16px" }}
                        >
                          Brand Campaign: <span className="text-danger">*</span>
                        </Label>
                        <Select
                          id="brandCampaign"
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="brandCampaign"
                          placeholder="Select BrandCampaign"
                          value={details.brandCampaign}
                          onChange={handleDetailsChange}
                          options={addBrandCampaignIDOption}
                          components={{
                            Input:
                              !details.brandCampaign?.value
                                ? requiredComponent
                                : notRequiredComponent,
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "16px" }}
                        >
                          Start Date:
                          <small
                            id="invalidStartDate"
                            className="text-danger d-none"
                          ></small>
                        </Label>
                        <Input
                          type="date" // Change type to datetime-local
                          name="startDate"
                          onChange={handleDetailsChangeDate}
                          value={getDateValue(details.startDate)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "16px" }}
                        >
                          End Date:
                          <small
                            id="invalidEndDate"
                            className="text-danger d-none"
                          ></small>
                        </Label>
                        <Input
                          type="date" // Change type to datetime-local
                          name="endDate"
                          onChange={handleDetailsChangeDate}
                          value={getDateValue(details.endDate)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "16px" }}
                        >
                          Min App Version <span className="text-danger">*</span>{" "}
                        </Label>
                        <Input
                          type="number"
                          name="minAppVersion"
                          placeholder="Enter Min App Version"
                          value={details.minAppVersion}
                          onChange={handleDetailsChange}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "16px" }}
                        >
                          Max App Version
                        </Label>
                        <Input
                          type="number"
                          name="maxAppVersion"
                          placeholder="Enter Max App Version"
                          value={details.maxAppVersion}
                          onChange={handleDetailsChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label className="font-weight-bolder mb-2" style={{ fontSize: "16px" }}>
                          Type: <span className="text-danger">*</span>
                        </Label>
                        <Select
                          id="type"
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="type"
                          placeholder="Select Type"
                          value={details.type}
                          onChange={handleDetailsChange}
                          options={addTypeOptions}
                          components={{
                            Input: details.type === null ? requiredComponent : notRequiredComponent,
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label className="font-weight-bolder mb-2" style={{ fontSize: "16px" }}>
                          Supported UI Types <span className="text-danger">*</span>
                        </Label>
                        <Select
                          id="supportedUITypes"
                          isMulti
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="supportedUITypes"
                          placeholder="Select UI Types"
                          options={filteredUITypesOptions}
                          value={
                            details.supportedUITypes
                              ? details.supportedUITypes.map((type) => ({
                                value: type,
                                label: type,
                              }))
                              : []
                          }
                          onChange={handleSupportedUITypesChange}
                          isClearable={true}
                          isDisabled={details.type === null} // Disable if no type is selected
                        />
                      </FormGroup>
                    </Col>
                    {formError && (
                      <div className="text-danger" style={{ marginBottom: "10px" }}>
                        {formError}
                      </div>
                    )}
                  </Row>

                  <Row form>
                    <Col lg={12} md={12}>
                      <FormGroup className="d-flex align-items-center">
                        <Label
                          className="font-weight-bolder mb-0"
                          style={{ fontSize: "16px" }}
                        >
                          Advance Options
                        </Label>
                        <div className="custom-control custom-switch">
                          <CustomInput
                            type="checkbox"
                            id="isDetatilsAdvanceOptionEnabled"
                            value={isDetatilsAdvanceOptionEnabled}
                            onChange={(event) => setIsDetatilsAdvanceOptionEnabled(event.target.checked)}
                            checked={isDetatilsAdvanceOptionEnabled}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    {isDetatilsAdvanceOptionEnabled && (
                      <>
                        <Col md={6}>
                          <FormGroup>
                            <Label
                              className="font-weight-bolder mb-2"
                              style={{ fontSize: "16px" }}
                            >
                              Category: <span className="text-danger">*</span>{" "}
                            </Label>
                            <Select
                              id="category"
                              className="react-select-container"
                              classNamePrefix="react-select"
                              name="category"
                              placeholder="Select Category"
                              value={details.category}
                              onChange={categoryhandleDetailsChange}
                              options={categoryOptions.filter((item) => {
                                return item.disabled !== true;
                              })}
                              components={{
                                Input:
                                  details.category === null
                                    ? requiredComponent
                                    : notRequiredComponent,
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={3}>
                          <FormGroup>
                            <Label
                              className="font-weight-bolder mb-2"
                              style={{ fontSize: "16px" }}
                            >
                              Priority: <span className="text-danger">*</span>{" "}
                            </Label>
                            <Input
                              type="number"
                              name="priority"
                              onChange={handleDetailsChange}
                              placeholder="Priority"
                              value={details.priority}
                              min={0}
                              disabled={dashboard === "mint" ? true : false}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col md={3}>
                          <FormGroup>
                            <Label
                              className="font-weight-bolder mb-2 pr-2"
                              style={{ fontSize: "16px" }}
                            >
                              Assets Preload Priority
                            </Label>
                            <Select
                              id="assetsPreloadPriority"
                              className="react-select-container"
                              classNamePrefix="react-select"
                              name="assetsPreloadPriority"
                              placeholder="Assets Preload Priority"
                              value={details.assetsPreloadPriority}
                              onChange={(event) => {
                                setDetails((prevState) => ({
                                  ...prevState,
                                  assetsPreloadPriority: event,
                                }));
                              }}
                              options={assetsPreloadPriorityOptions}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label
                              className="font-weight-bolder mb-2"
                              style={{ fontSize: "16px" }}
                            >
                              Trigger Keyword Character Limit:
                            </Label>
                            <Input
                              type="number"
                              name="triggerKeywordCharacterLimit"
                              onChange={handleDetailsChange}
                              placeholder="Trigger Keyword Character Limit"
                              value={details.triggerKeywordCharacterLimit}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label
                              className="font-weight-bolder"
                              style={{ fontSize: "16px" }}
                            ></Label>
                            Is Legacy Config Prompt:
                            <IconButton
                              onClick={() => {
                                handleDetailsChange({
                                  target: {
                                    name: "isLegacyConfigPrompt",
                                    value: !details.isLegacyConfigPrompt,
                                  },
                                });
                              }}
                            >
                              {details.isLegacyConfigPrompt ? (
                                <BsToggleOn />
                              ) : (
                                <BsToggleOff />
                              )}
                            </IconButton>
                          </FormGroup>
                        </Col>
                      </>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Row className="mb-3">
                <Col md={6} xs={6}>
                  <h3>Settings</h3>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <Row form>
                    <Col md={3}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "16px" }}
                        >
                          Repeat Session Count:
                        </Label>
                        <CustomTooltip
                          target="seession-tooltip"
                          content={<>Takes in non-zero, 4 digit values</>}
                          placement="top"
                        />
                        <Input
                          type="tel"
                          name="repeatSessionCount"
                          onChange={handleSettingsChange}
                          placeholder="Enter Repeat Session Count"
                          value={settings.repeatSessionCount}
                          maxLength={4}
                          pattern="[1-9][0-9]{0,3}" // Ensures the input starts with a non-zero digit
                          min={1} // Minimum value is 1
                          max={9999} // Maximum value is 9999
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "16px" }}
                        >
                          Max Count:
                        </Label>
                        <Input
                          type="tel"
                          name="maxCount"
                          onChange={handleSettingsChange}
                          placeholder="Enter Max Count"
                          value={settings.maxCount}
                          maxLength={7}
                          pattern="[0-9]{1,7}"
                          min={0}
                          max={9999999}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "16px" }}
                        >
                          Daily Max Count:
                        </Label>
                        <Input
                          type="tel"
                          name="dailyMaxCount"
                          onChange={handleSettingsChange}
                          placeholder="Enter Daily Max Count"
                          value={settings.dailyMaxCount}
                          maxLength={5}
                          pattern="[0-9]{1,5}"
                          min={0}
                          max={999999}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "16px" }}
                        >
                          Package Sets: <span className="text-danger">*</span>{" "}
                        </Label>
                        <Select
                          id="packageSets"
                          isMulti
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="packageSets"
                          placeholder="Select Package Sets"
                          value={settings.packageSets}
                          onChange={(event) => {
                            const packageSets = event;
                            const newSettings = { ...settings, packageSets };
                            setSettings(newSettings);
                          }}
                          components={{
                            Input:
                              settings.packageSets &&
                                settings.packageSets?.length > 0
                                ? notRequiredComponent
                                : requiredComponent,
                          }}
                          options={addPackageSetsOption}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row form>
                    <Col lg={12} md={12}>
                      <FormGroup className="d-flex align-items-center">
                        <Label
                          className="font-weight-bolder mb-0"
                          style={{ fontSize: "16px" }}
                        >
                          Advance Options
                        </Label>
                        <div className="custom-control custom-switch">
                          <CustomInput
                            type="checkbox"
                            id="isSettingsAdvanceOptionEnabled"
                            value={isSettingsAdvanceOptionEnabled}
                            onChange={(event) => setIsSettingsAdvanceOptionEnabled(event.target.checked)}
                            checked={isSettingsAdvanceOptionEnabled}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    {isSettingsAdvanceOptionEnabled && (
                      <>
                        <Col md={3}>
                          <FormGroup>
                            <Label
                              className="font-weight-bolder mb-2"
                              style={{ fontSize: "16px" }}
                            >
                              Display Duration (in seconds):
                            </Label>
                            <Input
                              type="tel"
                              name="displayDuration"
                              onChange={handleSettingsChange}
                              placeholder="Enter Display Duration"
                              value={settings.displayDuration}
                              maxLength={4}
                              pattern="[0-9]{1,4}"
                              min={0}
                              max={9999}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={3}>
                          <FormGroup>
                            <Label
                              className="font-weight-bolder mb-2"
                              style={{ fontSize: "16px" }}
                            >
                              Repeat Interval (in seconds):
                            </Label>
                            <Input
                              type="tel"
                              name="repeatInterval"
                              onChange={handleSettingsChange}
                              placeholder="Enter Repeat Interval"
                              value={settings.repeatInterval}
                              maxLength={4}
                              pattern="[0-9]{1,4}"
                              min={0}
                              max={9999}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={3}>
                          <FormGroup>
                            <Label
                              className="font-weight-bolder mb-2"
                              style={{ fontSize: "16px" }}
                            >
                              Dismiss Count:
                            </Label>
                            <Input
                              type="tel"
                              name="dismissCount"
                              onChange={handleSettingsChange}
                              placeholder="Enter Dismiss Count"
                              value={settings.dismissCount}
                              maxLength={4}
                              pattern="[0-9]{1,4}"
                              min={0}
                              max={9999}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={3}>
                          <FormGroup>
                            <Label
                              className="font-weight-bolder mb-2"
                              style={{ fontSize: "16px" }}
                            >
                              Skip Count:
                            </Label>
                            <Input
                              type="tel"
                              name="skipCount"
                              onChange={handleSettingsChange}
                              placeholder="Enter Skip Count"
                              value={settings.skipCount}
                              maxLength={4}
                              pattern="[0-9]{1,4}"
                              min={0}
                              max={9999}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                            <Label
                              className="font-weight-bolder mb-2"
                              style={{ fontSize: "16px" }}
                            >
                              Keyword Match Count:
                            </Label>
                            <Input
                              type="tel"
                              name="keywordMatchCount"
                              onChange={handleSettingsChange}
                              placeholder="Enter Keyword Match Count"
                              value={settings.keywordMatchCount}
                              maxLength={4}
                              pattern="[0-9]{1,4}"
                              min={0}
                              max={9999}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                            <Label
                              className="font-weight-bolder mb-2"
                              style={{ fontSize: "16px" }}
                            >
                              Start Time:
                              <small
                                id="invalidStartTime"
                                className="text-danger d-none"
                              ></small>
                            </Label>
                            <Input
                              type="time"
                              name="startTime"
                              onChange={handleSettingsChange}
                              value={settings.startTime === null ? "" : settings.startTime} // Convert null to empty string for the input value
                            />
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                            <Label
                              className="font-weight-bolder mb-2"
                              style={{ fontSize: "16px" }}
                            >
                              End Time:
                              <small
                                id="invalidEndTime"
                                className="text-danger d-none"
                              ></small>
                            </Label>

                            <Input
                              type="time"
                              name="endTime"
                              onChange={handleSettingsChange}
                              value={settings.endTime === null ? "" : settings.endTime} // Convert null to empty string for the input value
                            />
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          <FormGroup>
                            <Label
                              className="font-weight-bolder mb-2"
                              style={{ fontSize: "16px" }}
                            >
                              Blacklist Filters:
                            </Label>
                            <TagsInput
                              inputProps={{
                                placeholder: "Add Blacklist Filters",
                              }}
                              value={settings.blacklistFilters || []}
                              onChange={(event) => handleBlacklistFilters(event)}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          <FormGroup>
                            <Label
                              className="font-weight-bolder mb-2"
                              style={{ fontSize: "16px" }}
                            >
                              Trigger Action:
                            </Label>
                            <Select
                              id="triggerAction"
                              className="react-select-container"
                              classNamePrefix="react-select"
                              name="triggerAction"
                              placeholder="Select Trigger Action"
                              value={settings.triggerAction}
                              onChange={(event) => {
                                const triggerAction = event;
                                const newSettings = { ...settings, triggerAction };
                                setSettings(newSettings);
                              }}
                              options={[
                                { value: null, label: "Default" },
                                {
                                  value: "onCharacterTyped",
                                  label: "On Character Typed",
                                },
                                { value: "onWordTyped", label: "On Word Typed" },
                                { value: "onMessageSend", label: "On Message Send" },
                              ]}
                            />
                          </FormGroup>
                        </Col>
                      </>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row xs={3} md={6} lg={12}>
            <Col>
              <h3>Keywords</h3>
            </Col>
            <Col>
              <div className="p-0">
                {intentToggle ? (
                  <IconButton
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      verticalAlign: "middle",
                      padding: "0.5rem",
                    }}
                    onClick={() => setIntentToggle(false)}
                    className="d-none d-lg-block d-flex"
                  >
                    <BsToggleOn />
                  </IconButton>
                ) : (
                  <IconButton
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      verticalAlign: "middle",
                      padding: "0.5rem",
                    }}
                    onClick={() => setIntentToggle(true)}
                    className="d-none d-lg-block d-flex"
                  >
                    <BsToggleOff />
                  </IconButton>
                )}
              </div>
            </Col>
            <Col>
              <h3>Intents</h3>
            </Col>
          </Row>

          {intentToggle ? (
            <Row>
              <Col lg="12">
                <Row className="mb-3">
                  <Col md={6} xs={6}>
                    <h3>Intents</h3>
                  </Col>
                </Row>
                <Card>
                  <CardBody>
                    <Row form>
                      <Col md={12}>
                        <FormGroup>
                          <TagsInput
                            inputProps={{
                              placeholder: "Intent IDs",
                            }}
                            value={settings.intentIds || []}
                            onChange={(select) => handleIntentsIdsChange(select)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col lg="12">
                <Row className="mb-3">
                  <Col md={6} xs={6}>
                    <h3>Keywords</h3>
                  </Col>
                  <Col md={6} xs={6}>
                    <div className="p-0">
                      <Button
                        color="success"
                        className="ml-auto d-flex btn-success"
                        onClick={() =>
                          setKeywords(
                            keywords.concat({
                              languageCode: "",
                              tags: [],
                            })
                          )
                        }
                      >
                        <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                          Add Language
                        </p>
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Card>
                  <CardBody>
                    {keywords?.map((item, index) => {
                      return (
                        <Row key={index} form>
                          <Col md={6}>
                            <FormGroup>
                              <Label
                                className="font-weight-bolder mb-2"
                                style={{ fontSize: "16px" }}
                              >
                                Language Code:{" "}
                              </Label>
                              <Select
                                id="languageCode"
                                className="react-select-container"
                                classNamePrefix="react-select"
                                name="languageCode"
                                placeholder="Select Language Code"
                                value={item.languageCode}
                                onChange={(event) =>
                                  handleLanguageCodeChange(event, index)
                                }
                                options={showLanguageCodeOptions()}
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <div className="p-0" style={{ marginRight: "20px" }}>
                              <X
                                className="d-none d-lg-block ml-auto d-flex"
                                size={iconSize}
                                color="red"
                                style={{
                                  cursor: "pointer",
                                  border: "2px solid red",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setKeywords(
                                    keywords.filter((_, i) => i !== index)
                                  );
                                }}
                              ></X>
                            </div>
                          </Col>
                          <Col md={12}>
                            <FormGroup>
                              <TagsInput
                                inputProps={{
                                  placeholder: "Add a Keyword",
                                }}
                                value={item.tags}
                                onChange={(select) =>
                                  handleTagsChange(select, index)
                                }
                              />
                            </FormGroup>
                            <Button onClick={handleRepeatKeywords}>
                              Repeat Keywords
                            </Button>
                          </Col>

                          <hr
                            style={{
                              width: "75rem",
                              color: "black",
                            }}
                          />
                        </Row>
                      );
                    })}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          <Row>
            <Col lg="12">
              <Row className="mb-3">
                <Col md={2} xs={2}>
                  <h3>Event Filters</h3>
                </Col>
                <Col>
                  <div className="p-0">
                    <IconButton
                      onClick={() => setEventFiltersToggle(!eventFiltersToggle)}
                      className="d-none d-lg-block d-flex"
                    >
                      {eventFiltersToggle ? <BsToggleOn /> : <BsToggleOff />}
                    </IconButton>
                  </div>
                </Col>
              </Row>
              {eventFiltersToggle && (
                <Card>
                  <CardBody>
                    <Row form>
                      <Col md={12}>
                        <FormGroup>
                          <Input
                            type="textarea"
                            name="eventFilters"
                            id="eventFilters"
                            style={{ height: "10rem" }}
                            placeholder="Enter Event Filters"
                            value={settings.eventFilters}
                            onChange={(event) => {
                              setSettings({
                                ...settings,
                                eventFilters: event.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Row className="mb-3">
                <Col md={2} xs={2}>
                  <h3>App State Filters</h3>
                </Col>
                <Col>
                  <div className="p-0">
                    <IconButton
                      onClick={() =>
                        setAppStateFiltersToggle(!appStateFiltersToggle)
                      }
                      className="d-none d-lg-block d-flex"
                    >
                      {appStateFiltersToggle ? <BsToggleOn /> : <BsToggleOff />}
                    </IconButton>
                  </div>
                </Col>
              </Row>
              {appStateFiltersToggle && (
                <Card>
                  <CardBody>
                    <Row form>
                      <Col md={12}>
                        <FormGroup>
                          <Input
                            type="textarea"
                            name="appStateFilters"
                            id="appStateFilters"
                            style={{ height: "10rem" }}
                            placeholder="Enter App State Filters"
                            value={settings.appStateFilters}
                            onChange={(event) => {
                              setSettings({
                                ...settings,
                                appStateFilters: event.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Row className="mb-3">
                <Col md={2} xs={2}>
                  <h3>Package Filters</h3>
                </Col>
                <Col>
                  <div className="p-0">
                    <IconButton
                      onClick={() =>
                        setPackageFiltersToggle(!packageFiltersToggle)
                      }
                      className="d-none d-lg-block d-flex"
                    >
                      {packageFiltersToggle ? <BsToggleOn /> : <BsToggleOff />}
                    </IconButton>
                  </div>
                </Col>
              </Row>
              {packageFiltersToggle && (
                <Card>
                  <CardBody>
                    <Row form>
                      <Col md={12}>
                        <FormGroup>
                          <Input
                            type="textarea"
                            name="packageFilters"
                            id="packageFilters"
                            style={{ height: "10rem" }}
                            placeholder="Enter Package Filters"
                            value={settings.packageFilters}
                            onChange={(event) => {
                              setSettings({
                                ...settings,
                                packageFilters: event.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Row className="mb-3">
                <Col md={2} xs={2}>
                  <h3>Placements</h3>
                </Col>
                <Col>
                  <div className="p-0">
                    <IconButton
                      onClick={() => setPlacementsToggle(!placementsToggle)}
                      className="d-none d-lg-block d-flex"
                    >
                      {placementsToggle ? <BsToggleOn /> : <BsToggleOff />}
                    </IconButton>
                  </div>
                </Col>
              </Row>
              {placementsToggle && (
                <Card>
                  <CardBody>
                    <Row form>
                      <Col md={12}>
                        <FormGroup>
                          <Select
                            id="placements"
                            isMulti
                            className="react-select-container"
                            classNamePrefix="react-select"
                            name="placements"
                            placeholder="Select Placements"
                            value={settings.placements}
                            onChange={(event) => {
                              const placements = event;
                              const newSettings = { ...settings, placements };
                              setSettings(newSettings);
                            }}
                            options={[
                              { value: "kb_home", label: "kb_home" },
                              { value: "content_panel", label: "content_panel" },
                              {
                                value: "hamburger_menu",
                                label: "hamburger_menu",
                              },
                            ]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Row className="mb-3">
                <Col md={6} xs={6}>
                  <h3>Suggestion Pill UI Settings</h3>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "16px" }}
                        >
                          Width Percentage
                        </Label>
                        <Input
                          type="text"
                          name="widthPercentage"
                          placeholder="Width Percentage"
                          defaultValue={details.widthPercentage}
                          onChange={handleDetailsChange}
                        />
                        {widthPercentageError && (
                          <span className="text-danger">
                            Value should be between 0 and 1
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Row className="mb-3">
                <Col md={6} xs={6}>
                  <h3>Actions</h3>
                </Col>
                <Col md={6} xs={6}>
                  <div className="p-0">
                    <Button
                      color="success"
                      className="ml-auto d-flex btn-success"
                      onClick={() =>
                        setActions(
                          actions.concat({
                            actionType: "",
                            categoryID: "",
                            screenName: "",
                            searchString: "",
                            url: "",
                            backgroundColor: "",
                            darkThemeBackgroundColor: "",
                            textColor: "",
                            darkThemeTextColor: "",
                            text: "",
                            bannerImageURL: "",
                            bannerImage: "",
                            darkThemeBannerImageURL: "",
                            lightThemeBannerImage: "",
                            trackers: trackersInitialState,
                            deeplink: "",
                            displayToggle: false,
                            internalRedirection: "",
                            dismissible: true,
                            bannerAspectRatio: 0,
                            lightThemeBannerAspectRatio: 0,
                            darkThemeBannerAspectRatio: 0,
                            leftIcon: {
                              trackers: trackersInitialState,
                              useExistingLeftIconForDarkTheme: false,
                            },
                            rightIcon: {
                              trackers: trackersInitialState
                            },
                            rightIcon2: {
                              trackers: trackersInitialState
                            },
                            isDisplayAdvanceOptionEnabled: false,
                            useExistingBannerImage: false,
                          })
                        )
                      }
                    >
                      <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                        Add Actions
                      </p>
                    </Button>
                  </div>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  {actions?.map((item, index) => {
                    return (
                      <Row key={index} form>
                        <Col md={6}>
                          <FormGroup>
                            <Label
                              className="font-weight-bolder mb-2"
                              style={{ fontSize: "16px" }}
                            >
                              Action Type: <span className="text-danger">*</span>{" "}
                            </Label>
                            <Select
                              id="actionType"
                              className="react-select-container"
                              classNamePrefix="react-select"
                              name="actionType"
                              placeholder="Select Action Type"
                              value={item.actionType}
                              onChange={(event) => {
                                const newActions = [...actions];

                                newActions[index] = {
                                  ...newActions[index],
                                  actionType: event,
                                };

                                newActions[index] = {
                                  ...newActions[index],
                                  categoryID: "",
                                  screenName: "",
                                  searchString: "",
                                  url: "",
                                  internalRedirection: "",
                                };

                                setActions(newActions);
                              }}
                              options={addActionTypeOptions.filter((item) => {
                                return item.disabled !== true;
                              })}
                              components={{
                                Input:
                                  item.actionType === ""
                                    ? requiredComponent
                                    : notRequiredComponent,
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <div className="p-0" style={{ marginRight: "20px" }}>
                            <X
                              className="d-none d-lg-block ml-auto d-flex"
                              size={iconSize}
                              color="red"
                              style={{
                                cursor: "pointer",
                                border: "2px solid red",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                setActions(
                                  actions?.filter((_, i) => i !== index)
                                );
                              }}
                            ></X>
                          </div>
                        </Col>

                        {/* render this only if selected value is internalRedirection */}
                        {item.actionType?.value === "internalRedirection" && (
                          <>
                            <Col md={6} xs={6}>
                              <FormGroup>
                                <Label
                                  className="font-weight-bolder mb-2"
                                  style={{ fontSize: "16px" }}
                                >
                                  Internal Redirection{" "}
                                  <span className="text-danger">*</span>{" "}
                                </Label>
                                <Select
                                  id="internalRedirection"
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  name="internalRedirection"
                                  placeholder="Select Internal Redirection"
                                  value={item.internalRedirection}
                                  onChange={(event) => {
                                    const newActions = [...actions];
                                    const selectedOption = event.value;

                                    newActions[index] = {
                                      ...newActions[index],
                                      internalRedirection: event,
                                    };

                                    // make categoryID: "", screenName: "", searchString: "", url: "",

                                    newActions[index] = {
                                      ...newActions[index],
                                      categoryID: "",
                                      screenName: "",
                                      searchString: "",
                                      url: "",
                                    };

                                    if (selectedOption) {
                                      const selectedOptions =
                                        internalRedirectionOptions[
                                        selectedOption
                                        ];
                                      selectedOptions.forEach((option) => {
                                        newActions[index] = {
                                          ...newActions[index],
                                          [option.name]: option.value,
                                        };
                                      });
                                    }

                                    setActions(newActions);
                                  }}
                                  options={addActionTypeOptions
                                    .find(
                                      (x) => x.value === "internalRedirection"
                                    )
                                    .internalRedirectionOptions.filter((item) => {
                                      return item.disabled !== true;
                                    })}
                                  components={{
                                    Input:
                                      item.internalRedirection === ""
                                        ? requiredComponent
                                        : notRequiredComponent,
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </>
                        )}

                        {/* render this if there is some selected value of action type */}
                        {item.actionType?.value && (
                          <>
                            <Col md={12} xs={12} className="mt-2">
                              <h4>Metadata</h4>
                            </Col>

                            {/* if actionType.value is not internalRedirection then render this */}
                            {item.actionType?.value !== "internalRedirection" && (
                              <>
                                {addActionTypeOptions
                                  .find((x) => x.value === item.actionType.value)
                                  .metadata.map((metadata) => {
                                    return (
                                      <>
                                        <Col md={6} xs={6}>
                                          <FormGroup>
                                            <Label
                                              className="font-weight-bolder mb-2"
                                              style={{ fontSize: "16px" }}
                                            >
                                              {metadata.label}{" "}
                                              {metadata.required ? (
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              ) : (
                                                ""
                                              )}{" "}
                                            </Label>
                                            <Input
                                              type="text"
                                              name={metadata.name}
                                              id={metadata.name}
                                              placeholder={metadata.label}
                                              value={item[metadata.name]}
                                              required={metadata.required}
                                              onChange={(event) => {
                                                setActions([
                                                  ...actions.slice(0, index),
                                                  {
                                                    ...actions[index],
                                                    [metadata.name]:
                                                      event.target.value,
                                                  },
                                                  ...actions.slice(index + 1),
                                                ]);
                                              }}
                                            />
                                          </FormGroup>
                                        </Col>
                                      </>
                                    );
                                  })}
                              </>
                            )}

                            {/* if actionType.value is internalRedirection then render this */}
                            {item.internalRedirection?.value && (
                              <>
                                {internalRedirectionOptions[
                                  item.internalRedirection.value
                                ].map((option) => {
                                  return (
                                    <>
                                      {option.name === "categoryID" ? (
                                        <Col md={6} xs={6}>
                                          <FormGroup>
                                            <Label
                                              className="font-weight-bolder mb-2"
                                              style={{ fontSize: "16px" }}
                                            >
                                              {option.label}:{" "}
                                              <span className="text-danger">
                                                *
                                              </span>{" "}
                                            </Label>
                                            <Select
                                              id="categoryID"
                                              className="react-select-container"
                                              classNamePrefix="react-select"
                                              name="categoryID"
                                              placeholder={option.placeholder}
                                              value={item.categoryID}
                                              onChange={(event) => {
                                                setActions([
                                                  ...actions.slice(0, index),
                                                  {
                                                    ...actions[index],
                                                    categoryID: event,
                                                  },
                                                  ...actions.slice(index + 1),
                                                ]);
                                              }}
                                              options={
                                                item.internalRedirection.value ===
                                                  "specificCategoryInMovieGIFs"
                                                  ? filterContentCategories(
                                                    "movieGif"
                                                  )
                                                  : item.internalRedirection
                                                    .value ===
                                                    "specificCategoryInStickers"
                                                    ? filterContentCategories(
                                                      "sticker"
                                                    )
                                                    : item.internalRedirection
                                                      .value ===
                                                      "quotesShayariJokes"
                                                      ? addQuickRepliesOption
                                                      : addCategoryOption
                                              }
                                              required
                                            />
                                          </FormGroup>
                                        </Col>
                                      ) : (
                                        <Col md={6} xs={6}>
                                          <FormGroup>
                                            <Label
                                              className="font-weight-bolder mb-2"
                                              style={{ fontSize: "16px" }}
                                            >
                                              {option.label}{" "}
                                              {option.required && (
                                                <>
                                                  <span className="text-danger">
                                                    *
                                                  </span>{" "}
                                                </>
                                              )}
                                            </Label>
                                            <Input
                                              type="text"
                                              name={option.name}
                                              id={option.name}
                                              placeholder={option.placeholder}
                                              value={item[option.name]}
                                              onChange={(event) => {
                                                setActions([
                                                  ...actions.slice(0, index),
                                                  {
                                                    ...actions[index],
                                                    [option.name]:
                                                      event.target.value,
                                                  },
                                                  ...actions.slice(index + 1),
                                                ]);
                                              }}
                                              required={option.required}
                                              disabled={option.disabled}
                                            />
                                          </FormGroup>
                                        </Col>
                                      )}
                                    </>
                                  );
                                })}
                              </>
                            )}
                            {item.actionType?.value ===
                              "autoOpenSuggestionDrawer" && (
                                <>
                                  <Col md={6} xs={6}>
                                    <FormGroup>
                                      <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}
                                      >
                                        Suggestion Drawer Category:{" "}
                                        <span className="text-danger">*</span>{" "}
                                      </Label>
                                      <Select
                                        id="suggestionDrawerCategoryId"
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        name="suggestionDrawerCategoryId"
                                        placeholder={
                                          "Select Suggestion Drawer Category"
                                        }
                                        value={item.suggestionDrawerCategoryId}
                                        onChange={(event) => {
                                          setActions([
                                            ...actions.slice(0, index),
                                            {
                                              ...actions[index],
                                              suggestionDrawerCategoryId: event,
                                            },
                                            ...actions.slice(index + 1),
                                          ]);
                                        }}
                                        options={addSDCategoryOption}
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                </>
                              )}
                          </>
                        )}

                        <Col lg={12}>
                          <Row className="pb-3 pt-1" form>
                            <Col md={12} xs={12}>
                              <FormGroup>
                                <Label
                                  className="font-weight-bolder mb-2"
                                  style={{ fontSize: "16px" }}
                                >
                                  Deeplink{" "}
                                </Label>
                                <Input
                                  type="textarea"
                                  name="deeplink"
                                  id="deeplink"
                                  style={{ height: "4rem" }}
                                  placeholder="Enter Deep Link"
                                  value={item.deeplink}
                                  onChange={(event) => {
                                    const newDeeplink = event.target.value.trim(); // Trim whitespace
                                    setActions([
                                      ...actions.slice(0, index),
                                      {
                                        ...actions[index],
                                        deeplink:
                                          newDeeplink === "" ? null : newDeeplink, // Set to null if empty, otherwise use the trimmed value
                                      },
                                      ...actions.slice(index + 1),
                                    ]);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row className="pb-3 pt-1" form>
                            <Col md={12} xs={12}>
                              <FormGroup className="mb-0">
                                <Label
                                  className="font-weight-bolder mb-2"
                                  style={{ fontSize: "16px" }}
                                >
                                  Trackers
                                </Label>
                              </FormGroup>
                              {item.trackers?.map((tracker, trackerIndex) => (
                                <Row key={trackerIndex} form>
                                  <Col>
                                    <Row form>
                                      <Col md={12} xs={12}>
                                        <FormGroup>
                                          <Label
                                            className="font-weight-bolder mb-2"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Tracker Type
                                          </Label>
                                          <Select
                                            id="type"
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            name="type"
                                            placeholder="Select Tracker Type"
                                            value={tracker.type}
                                            onChange={(event) => {
                                              setActions([
                                                ...actions.slice(0, index),
                                                {
                                                  ...actions[index],
                                                  trackers: [
                                                    ...actions[index].trackers.slice(0, trackerIndex),
                                                    {
                                                      ...actions[index].trackers[trackerIndex],
                                                      type: event,
                                                    },
                                                    ...actions[index].trackers.slice(trackerIndex + 1),
                                                  ],
                                                },
                                                ...actions.slice(index + 1),
                                              ]);
                                            }}
                                            options={trackerTypeOptions}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md={12} xs={12}>
                                        <FormGroup>
                                          <Label
                                            className="font-weight-bolder mb-2"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Tracker URL
                                          </Label>
                                          <Input
                                            type="text"
                                            name="url"
                                            placeholder="Tracker URL"
                                            value={tracker.url}
                                            onChange={(event) => {
                                              setActions([
                                                ...actions.slice(0, index),
                                                {
                                                  ...actions[index],
                                                  trackers: [
                                                    ...actions[index].trackers.slice(0, trackerIndex),
                                                    {
                                                      ...actions[index].trackers[trackerIndex],
                                                      url: event.target.value,
                                                    },
                                                    ...actions[index].trackers.slice(trackerIndex + 1),
                                                  ],
                                                },
                                                ...actions.slice(index + 1),
                                              ]);
                                            }}
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col md={1} className="mt-4">
                                    {trackerIndex === 0 ? (
                                      <Button onClick={() => {
                                        setActions([
                                          ...actions.slice(0, index),
                                          {
                                            ...actions[index],
                                            trackers: [
                                              ...actions[index].trackers,
                                              {
                                                type: null,
                                                url: ""
                                              },
                                            ],
                                          },
                                          ...actions.slice(index + 1),
                                        ]);
                                      }} color="transparent">
                                        <FaPlus />
                                      </Button>
                                    ) : (
                                      <Button onClick={(e) => {
                                        e.preventDefault();
                                        setActions([
                                          ...actions.slice(0, index),
                                          {
                                            ...actions[index],
                                            trackers: item.trackers?.filter((_, i) => i !== trackerIndex),
                                          },
                                          ...actions.slice(index + 1),
                                        ]);
                                      }} color="transparent">
                                        <FaMinus />
                                      </Button>
                                    )}
                                  </Col>
                                  <Col lg="12">
                                    {trackerIndex !== (item.trackers?.length - 1) && <hr />}
                                  </Col>
                                </Row>
                              ))}
                            </Col>
                          </Row>

                          <Row className="mb-3">
                            <Col
                              md={3}
                              xs={3}
                              className="mt-2"
                              style={{
                                maxWidth: "20%",
                                paddingRight: "0px",
                              }}
                            >
                              <h4>Display Settings</h4>
                            </Col>

                            <Col
                              md={9}
                              className="mb-2"
                              style={{
                                paddingLeft: "0px",
                              }}
                            >
                              <div className="p-0">
                                {item.displayToggle ? (
                                  <IconButton
                                    style={{
                                      backgroundColor: "transparent",
                                      border: "none",
                                      verticalAlign: "middle",
                                      padding: "0.5rem",
                                    }}
                                    onClick={() =>
                                      setActions([
                                        ...actions.slice(0, index),
                                        {
                                          ...actions[index],
                                          displayToggle: false,
                                        },
                                        ...actions.slice(index + 1),
                                      ])
                                    }
                                    className="d-none d-lg-block d-flex"
                                  >
                                    <BsToggleOn />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    style={{
                                      backgroundColor: "transparent",
                                      border: "none",
                                      verticalAlign: "middle",
                                      padding: "0.5rem",
                                    }}
                                    onClick={() =>
                                      setActions([
                                        ...actions.slice(0, index),
                                        {
                                          ...actions[index],
                                          displayToggle: true,
                                        },
                                        ...actions.slice(index + 1),
                                      ])
                                    }
                                    className="d-none d-lg-block d-flex"
                                  >
                                    <BsToggleOff />
                                  </IconButton>
                                )}
                              </div>
                            </Col>
                          </Row>
                          <Collapse isOpen={item.displayToggle}>
                            <Row className="pb-3 pt-1" form>
                              <Col md={3} xs={3}>
                                <FormGroup>
                                  <Label className="font-weight-bolder mb-2" style={{ fontSize: "16px" }}>
                                    Background Color (Hex):{" "}
                                    <div
                                      style={{
                                        backgroundColor: item.backgroundColor,
                                        width: "16px",
                                        height: "16px",
                                        display: "inline-block",
                                        verticalAlign: "middle",
                                        border: "1px solid #000",
                                        borderRadius: "30%",
                                      }}
                                    >
                                      {" "}
                                    </div>
                                  </Label>
                                  <Input
                                    type="text"
                                    name="backgroundColor"
                                    id="backgroundColor"
                                    placeholder="Background Color"
                                    value={item.backgroundColor || ""}
                                    onChange={(event) => {
                                      const value = event.target.value.trim() === "" ? null : event.target.value;
                                      setActions([
                                        ...actions.slice(0, index),
                                        {
                                          ...actions[index],
                                          backgroundColor: value,
                                        },
                                        ...actions.slice(index + 1),
                                      ]);
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={3} xs={3}>
                                <FormGroup>
                                  <Label className="font-weight-bolder mb-2" style={{ fontSize: "16px" }}>
                                    Dark Theme Background Color (Hex):{" "}
                                    <div
                                      style={{
                                        backgroundColor: item.darkThemeBackgroundColor,
                                        width: "16px",
                                        height: "16px",
                                        display: "inline-block",
                                        verticalAlign: "middle",
                                        border: "1px solid #000",
                                        borderRadius: "30%",
                                      }}
                                    >
                                      {" "}
                                    </div>
                                  </Label>
                                  <Input
                                    type="text"
                                    name="darkThemeBackgroundColor"
                                    id="darkThemeBackgroundColor"
                                    placeholder="Background Color"
                                    value={item.darkThemeBackgroundColor || ""}
                                    onChange={(event) => {
                                      const value = event.target.value.trim() === "" ? null : event.target.value;
                                      setActions([
                                        ...actions.slice(0, index),
                                        {
                                          ...actions[index],
                                          darkThemeBackgroundColor: value,
                                        },
                                        ...actions.slice(index + 1),
                                      ]);
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={3} xs={3}>
                                <FormGroup>
                                  <Label className="font-weight-bolder mb-2" style={{ fontSize: "16px" }}>
                                    Text Color (Hex):{" "}
                                    <div
                                      style={{
                                        backgroundColor: item.textColor,
                                        width: "16px",
                                        height: "16px",
                                        display: "inline-block",
                                        verticalAlign: "middle",
                                        border: "1px solid #000",
                                        borderRadius: "30%",
                                      }}
                                    >
                                      {" "}
                                    </div>
                                  </Label>
                                  <Input
                                    type="text"
                                    name="textColor"
                                    id="textColor"
                                    placeholder="Text Color"
                                    value={item.textColor || ""}
                                    onChange={(event) => {
                                      const value = event.target.value.trim() === "" ? null : event.target.value;
                                      setActions([
                                        ...actions.slice(0, index),
                                        {
                                          ...actions[index],
                                          textColor: value,
                                        },
                                        ...actions.slice(index + 1),
                                      ]);
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={3} xs={3}>
                                <FormGroup>
                                  <Label className="font-weight-bolder mb-2" style={{ fontSize: "16px" }}>
                                    Dark Theme Text Color (Hex):{" "}
                                    <div
                                      style={{
                                        backgroundColor: item.darkThemeTextColor,
                                        width: "16px",
                                        height: "16px",
                                        display: "inline-block",
                                        verticalAlign: "middle",
                                        border: "1px solid #000",
                                        borderRadius: "30%",
                                      }}
                                    >
                                      {" "}
                                    </div>
                                  </Label>
                                  <Input
                                    type="text"
                                    name="darkThemeTextColor"
                                    id="darkThemeTextColor"
                                    placeholder="Text Color"
                                    value={item.darkThemeTextColor || ""}
                                    onChange={(event) => {
                                      const value = event.target.value.trim() === "" ? null : event.target.value;
                                      setActions([
                                        ...actions.slice(0, index),
                                        {
                                          ...actions[index],
                                          darkThemeTextColor: value,
                                        },
                                        ...actions.slice(index + 1),
                                      ]);
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6} xs={6}>
                                <FormGroup>
                                  <Label
                                    className="font-weight-bolder mb-2"
                                    style={{ fontSize: "16px" }}
                                  >
                                    Text: <span className="text-danger">*</span>{" "}
                                  </Label>
                                  <Input
                                    type="text"
                                    name="text"
                                    id="text"
                                    placeholder="Text"
                                    value={item.text}
                                    required={item.displayToggle}
                                    onChange={(event) => {
                                      setActions([
                                        ...actions.slice(0, index),
                                        {
                                          ...actions[index],
                                          text: event.target.value,
                                        },
                                        ...actions.slice(index + 1),
                                      ]);
                                    }}
                                  />
                                </FormGroup>
                              </Col>

                              <Col md={6} xs={6}>
                                <FormGroup>
                                  <Label
                                    className="font-weight-bolder mb-2"
                                    style={{ fontSize: "16px" }}
                                  >
                                    Texts:
                                  </Label>
                                  {(item.texts || []).map((text, textIndex) => (
                                    <div
                                      key={textIndex}
                                      style={{
                                        display: "flex",
                                        marginBottom: "15px",
                                      }}
                                    >
                                      <Input
                                        type="text"
                                        value={text}
                                        onChange={(event) => {
                                          const updatedTexts = item.texts.map(
                                            (t, i) =>
                                              i === textIndex
                                                ? event.target.value
                                                : t
                                          );
                                          setActions([
                                            ...actions.slice(0, index),
                                            {
                                              ...actions[index],
                                              texts: updatedTexts,
                                            },
                                            ...actions.slice(index + 1),
                                          ]);
                                        }}
                                        style={{ width: "80%", fontSize: "16px" }}
                                      />
                                      <Button
                                        type="button"
                                        color="danger"
                                        onClick={() =>
                                          removeText(index, textIndex)
                                        }
                                        style={{ marginLeft: "10px" }}
                                      >
                                        Remove
                                      </Button>
                                    </div>
                                  ))}
                                  <Button
                                    type="button"
                                    color="secondary"
                                    onClick={() => addText(index)}
                                    style={{ marginBottom: "15px" }}
                                  >
                                    Add Field
                                  </Button>
                                </FormGroup>
                              </Col>

                              <Col md={6}>
                                <FormGroup>
                                  <Label
                                    className="font-weight-bolder mb-2"
                                    style={{ fontSize: "16px" }}
                                  >
                                    Text Rotation Interval In Ms{" "}
                                  </Label>
                                  <Input
                                    type="number"
                                    name="textsRotationIntervalInMs"
                                    placeholder="Texts Rotation Interval"
                                    defaultValue={item.textsRotationIntervalInMs}
                                    onChange={(event) => {
                                      setActions([
                                        ...actions.slice(0, index),
                                        {
                                          ...actions[index],
                                          textsRotationIntervalInMs:
                                            event.target.value,
                                        },
                                        ...actions.slice(index + 1),
                                      ]);
                                    }}
                                    step="1"
                                  />
                                </FormGroup>
                              </Col>


                              <Col md={3}>
                                <FormGroup>
                                  <Label
                                    className="font-weight-bolder mb-2"
                                    style={{ fontSize: "16px" }}
                                  >
                                    Aspect Ratio:
                                  </Label>
                                  <Input
                                    type="aspectRatio"
                                    name="aspectRatio"
                                    id={`a-${index}-aspectRatio`}
                                    placeholder="Aspect Ratio"
                                    value={item.aspectRatio}
                                    onChange={(event) => {
                                      setActions([
                                        ...actions.slice(0, index),
                                        {
                                          ...actions[index],
                                          aspectRatio: event.target.value,
                                        },
                                        ...actions.slice(index + 1),
                                      ]);
                                    }}
                                  />
                                </FormGroup>
                              </Col>

                              <Col md={3} xs={3}>
                                <FormGroup>
                                  <Label
                                    className="font-weight-bolder mb-2"
                                    style={{ fontSize: "16px" }}
                                  >
                                    Is Dismissible:
                                  </Label>
                                  <div className="p-0">
                                    {item.dismissible ? (
                                      <IconButton
                                        style={{
                                          backgroundColor: "transparent",
                                          border: "none",
                                          verticalAlign: "middle",
                                          padding: "0.5rem",
                                        }}
                                        onClick={() =>
                                          setActions([
                                            ...actions.slice(0, index),
                                            {
                                              ...actions[index],
                                              dismissible: false,
                                            },
                                            ...actions.slice(index + 1),
                                          ])
                                        }
                                        className="d-none d-lg-block d-flex"
                                      >
                                        <BsToggleOn />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        style={{
                                          backgroundColor: "transparent",
                                          border: "none",
                                          verticalAlign: "middle",
                                          padding: "0.5rem",
                                        }}
                                        onClick={() =>
                                          setActions([
                                            ...actions.slice(0, index),
                                            {
                                              ...actions[index],
                                              dismissible: true,
                                            },
                                            ...actions.slice(index + 1),
                                          ])
                                        }
                                        className="d-none d-lg-block d-flex"
                                      >
                                        <BsToggleOff />
                                      </IconButton>
                                    )}
                                  </div>
                                </FormGroup>
                              </Col>
                              <Col md={12} xs={12}>
                                <FormGroup>
                                  <Label
                                    className="font-weight-bolder mb-2"
                                    style={{ fontSize: "16px" }}
                                  >
                                    Banner Image <CustomTooltip
                                      target="banner-tooltip"
                                      content={<>(Max Static Size : {convertBytesToKB(fileSizeLimit.bannerImage)}KB) or (Max Animated Size : {convertBytesToKB(fileSizeLimit.animatedBannerImage)}KB)</>}
                                      placement="top"
                                    />
                                  </Label>
                                  <Input
                                    type="file"
                                    name="bannerImage"
                                    id="bannerImage"
                                    placeholder="Banner Image"
                                    onChange={(event) => {
                                      const file = event.target.files[0];
                                      const allowedTypes = [
                                        "image/png",
                                        "image/jpg",
                                        "image/jpeg",
                                        "image/gif",
                                        "image/webp",
                                      ];

                                      if (!allowedTypes.includes(file.type)) {
                                        console.log("Invalid file type");
                                        return;
                                      }

                                      if (checkFileSizeLimit(file, "Banner", fileSizeLimit.bannerImage, fileSizeLimit.animatedBannerImage)) return

                                      const reader = new FileReader();
                                      reader.onload = (e) => {
                                        setActions([
                                          ...actions.slice(0, index),
                                          {
                                            ...actions[index],
                                            useExistingBannerImage: false,
                                            bannerImage: e.target.result,
                                            bannerImageName: file.name,
                                          },
                                          ...actions.slice(index + 1),
                                        ]);
                                      };
                                      reader.readAsDataURL(file);
                                    }}
                                  />
                                </FormGroup>
                              </Col>

                              {/* Show the preview of the image if selected */}
                              {(item.bannerImage || item.bannerImageURL) && (
                                <Col md={12} xs={12}>
                                  <FormGroup>
                                    <img
                                      src={
                                        item.bannerImage === ""
                                          ? item.bannerImageURL
                                          : item.bannerImage
                                      }
                                      alt="Light Theme Banner"
                                      style={{ width: "20%" }}
                                    />
                                    <X
                                      className="ml-2"
                                      size={iconSize}
                                      color="red"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) => {
                                        setActions([
                                          ...actions.slice(0, index),
                                          {
                                            ...actions[index],
                                            bannerImage: "",
                                            bannerImageName: "",
                                            bannerImageURL: "",
                                            bannerAspectRatio: 0,
                                          },
                                          ...actions.slice(index + 1),
                                        ]);
                                      }}
                                    ></X>
                                  </FormGroup>
                                </Col>
                              )}

                              {/* Dark theme banner image */}

                              <Col md={12} xs={12}>
                                <FormGroup>
                                  <Label
                                    className="font-weight-bolder mb-2"
                                    style={{ fontSize: "16px" }}
                                  >
                                    Dark Theme Banner Image <CustomTooltip
                                      target="dark-banner-tooltip"
                                      content={<>(Max Static Size : {convertBytesToKB(fileSizeLimit.bannerImage)}KB) or (Max Animated Size : {convertBytesToKB(fileSizeLimit.animatedBannerImage)}KB)</>}
                                      placement="top"
                                    />
                                  </Label>
                                  <Input
                                    type="file"
                                    name="darkThemeBannerImage"
                                    id="darkThemeBannerImage"
                                    placeholder="Dark Theme Banner Image"
                                    onChange={(event) => {
                                      const file = event.target.files[0];
                                      const allowedTypes = [
                                        "image/png",
                                        "image/jpg",
                                        "image/jpeg",
                                        "image/gif",
                                        "image/webp",
                                      ];

                                      if (!allowedTypes.includes(file.type)) {
                                        console.log("Invalid file type");
                                        return;
                                      }

                                      if (checkFileSizeLimit(file, "Dark Theme Banner", fileSizeLimit.bannerImage, fileSizeLimit.animatedBannerImage)) return

                                      const reader = new FileReader();
                                      reader.onload = (e) => {
                                        setActions([
                                          ...actions.slice(0, index),
                                          {
                                            ...actions[index],
                                            useExistingBannerImage: false,
                                            darkThemeBannerImage: e.target.result,
                                            darkThemeBannerImageName: file.name,
                                          },
                                          ...actions.slice(index + 1),
                                        ]);
                                      };
                                      reader.readAsDataURL(file);
                                    }}
                                  />
                                </FormGroup>
                              </Col>

                              {/* Show the preview of the image if selected */}
                              {(item.darkThemeBannerImage ||
                                item.darkThemeBannerImageURL) && (
                                  <Col md={12} xs={12}>
                                    <FormGroup>
                                      <img
                                        src={
                                          item.darkThemeBannerImage === ""
                                            ? item.darkThemeBannerImageURL
                                            : item.darkThemeBannerImage
                                        }
                                        alt="Dark Theme Banner"
                                        style={{ width: "20%" }}
                                      />
                                      <X
                                        className="ml-2"
                                        size={iconSize}
                                        color="red"
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={(e) => {
                                          setActions([
                                            ...actions.slice(0, index),
                                            {
                                              ...actions[index],
                                              darkThemeBannerImage: "",
                                              darkThemeBannerImageName: "",
                                              darkThemeBannerImageURL: "",
                                              darkThemeBannerAspectRatio: 0,
                                            },
                                            ...actions.slice(index + 1),
                                          ]);
                                        }}
                                      ></X>
                                    </FormGroup>
                                  </Col>
                                )}

                              <Col md={12} xs={12}>
                                {/* Conditionally render the toggle based on item.bannerImage or item.bannerImageURL */}
                                {(item.bannerImage || item.bannerImageURL) && (
                                  <FormGroup>
                                    <Label className="font-weight-bolder mb-2" style={{ fontSize: "16px" }}>
                                      Use Existing Banner Image
                                    </Label>
                                    <div className="custom-control custom-switch">
                                      <CustomInput
                                        type="checkbox"
                                        id={`useExistingBannerImage-${index}`}
                                        value={item.useExistingBannerImage}
                                        onChange={(event) => {
                                          setActions([
                                            ...actions.slice(0, index),
                                            {
                                              ...actions[index],
                                              useExistingBannerImage: event.target.checked,
                                              darkThemeBannerImage: event.target.checked ? item.bannerImage : null,
                                              darkThemeBannerImageName: event.target.checked ? item.bannerImageName : null,
                                            },
                                            ...actions.slice(index + 1),
                                          ])
                                        }}
                                        checked={item.useExistingBannerImage}
                                      />
                                    </div>
                                  </FormGroup>
                                )}
                              </Col>
                            </Row>

                            <Row form>
                              <Col lg={12} md={12}>
                                <FormGroup className="d-flex align-items-center">
                                  <Label
                                    className="font-weight-bolder mb-0"
                                    style={{ fontSize: "16px" }}
                                  >
                                    Advance Options
                                  </Label>
                                  <div className="custom-control custom-switch">
                                    <CustomInput
                                      type="checkbox"
                                      id={`isDisplayAdvanceOptionEnabled-${index + 1}`}
                                      value={item.isDisplayAdvanceOptionEnabled}
                                      onChange={(event) => setActions([
                                        ...actions.slice(0, index),
                                        {
                                          ...actions[index],
                                          isDisplayAdvanceOptionEnabled: event.target.checked,
                                        },
                                        ...actions.slice(index + 1),
                                      ])}
                                      checked={item.isDisplayAdvanceOptionEnabled}
                                    />
                                  </div>
                                </FormGroup>
                              </Col>
                              {item.isDisplayAdvanceOptionEnabled && (
                                <>
                                  <Col md={4} xs={6}>
                                    <FormGroup>
                                      <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}
                                      >
                                        Description:
                                      </Label>
                                      <Input
                                        type="description"
                                        name="description"
                                        id="description"
                                        placeholder="Description"
                                        value={item.description}
                                        onChange={(event) => {
                                          setActions([
                                            ...actions.slice(0, index),
                                            {
                                              ...actions[index],
                                              description: event.target.value,
                                            },
                                            ...actions.slice(index + 1),
                                          ]);
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}
                                      >
                                        Text Size:
                                      </Label>
                                      <Select
                                        id="textSize"
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        name="textSize"
                                        placeholder="Select Text Size"
                                        value={item.textSize}
                                        onChange={(event) => {
                                          const newActions = [...actions];
                                          newActions[index] = {
                                            ...newActions[index],
                                            textSize: event,
                                          };
                                          setActions(newActions);
                                        }}
                                        options={[
                                          { value: "small", label: "Small" },
                                          { value: "medium", label: "Medium" },
                                          { value: "large", label: "Large" },
                                        ]}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}
                                      >
                                        Text Alignment:
                                      </Label>
                                      <Select
                                        id="textAlignment"
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        name="textAlignment"
                                        placeholder="Select Text Alignment"
                                        value={item.textAlignment}
                                        onChange={(event) => {
                                          const newActions = [...actions];
                                          newActions[index] = {
                                            ...newActions[index],
                                            textAlignment: event,
                                          };
                                          setActions(newActions);
                                        }}
                                        options={[
                                          { value: "left", label: "Left" },
                                          { value: "center", label: "Center" },
                                          { value: "right", label: "Right" },
                                        ]}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} xs={6}>
                                    <FormGroup>
                                      <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}
                                      >
                                        Text Translations:
                                      </Label>
                                      <Input
                                        type="textarea"
                                        name="textTranslations"
                                        id="textTranslations"
                                        style={{ height: "6rem" }}
                                        placeholder="Enter textTranslations"
                                        value={item.textTranslations}
                                        onChange={(event) => {
                                          setActions([
                                            ...actions.slice(0, index),
                                            {
                                              ...actions[index],
                                              textTranslations: event.target.value,
                                            },
                                            ...actions.slice(index + 1),
                                          ]);
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}
                                      >
                                        Expanded Aspect Ratio:
                                      </Label>
                                      <Input
                                        type="expandenAspectRatio"
                                        name="expandenAspectRatio"
                                        id={`a-${index}-expandenAspectRatio`}
                                        placeholder="Expanded Aspect Ratio"
                                        value={item.expandedAspectRatio}
                                        onChange={(event) => {
                                          setActions([
                                            ...actions.slice(0, index),
                                            {
                                              ...actions[index],
                                              expandedAspectRatio: event.target.value,
                                            },
                                            ...actions.slice(index + 1),
                                          ]);
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}
                                      >
                                        Icon Pointer:
                                      </Label>
                                      <Select
                                        id="iconPointer"
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        name="iconPointer"
                                        placeholder="Select Action Type"
                                        value={item.iconPointer}
                                        onChange={(event) => {
                                          const newActions = [...actions];
                                          newActions[index] = {
                                            ...newActions[index],
                                            iconPointer: event,
                                          };
                                          setActions(newActions);
                                        }}
                                        options={iconPointerOptions}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} xs={12}>
                                    <FormGroup>
                                      <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}
                                      >
                                        Prompt Sound{" "}
                                        <small className="text-info">
                                          (Choose an audio file)
                                        </small>
                                      </Label>
                                      <Container className="custom-file">
                                        <Input
                                          type="file"
                                          className="custom-file-input"
                                          accept="audio/mp3"
                                          name="sound"
                                          disabled={false}
                                          onChange={(event) => {
                                            const file = event.target.files[0];

                                            const reader = new FileReader();
                                            reader.onload = (e) => {
                                              setActions([
                                                ...actions.slice(0, index),
                                                {
                                                  ...actions[index],
                                                  soundFile: e.target.result,
                                                  soundFileName: file.name,
                                                },
                                                ...actions.slice(index + 1),
                                              ]);
                                            };
                                            reader.readAsDataURL(file);
                                          }}
                                        />
                                        <Label
                                          className="custom-file-label"
                                          id="soundMp3"
                                        >
                                          Choose an audio file
                                        </Label>
                                      </Container>
                                    </FormGroup>
                                  </Col>
                                </>
                              )}
                            </Row>
                            <Row>
                              {item.isDisplayAdvanceOptionEnabled && (item.soundFile || item.soundFileURL) && (
                                <Col className="pb-4">
                                  <ReactAudioPlayer
                                    src={
                                      item.soundFile === ""
                                        ? item.soundFileURL
                                        : item.soundFile
                                    }
                                    controls
                                    height="80"
                                    alt="soundMp3"
                                  />
                                  <X
                                    className="ml-2"
                                    size={iconSize}
                                    color="red"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      setActions([
                                        ...actions.slice(0, index),
                                        {
                                          ...actions[index],
                                          soundFile: "",
                                          soundFileName: "",
                                          soundFileURL: "",
                                        },
                                        ...actions.slice(index + 1),
                                      ]);
                                    }}
                                  ></X>
                                </Col>
                              )}
                            </Row>
                            {item.isDisplayAdvanceOptionEnabled && (
                              <Row>
                                <Col md={4}>
                                  <FormGroup>
                                    <Label
                                      className="font-weight-bolder mb-2"
                                      style={{ fontSize: "16px" }}
                                    >
                                      Playback Delay in ms:
                                    </Label>
                                    <Input
                                      type="number"
                                      name="playbackDelay"
                                      id="playbackDelay"
                                      placeholder="playbackDelay"
                                      value={
                                        item.soundSettings
                                          ? item.soundSettings.playbackDelayInMs
                                          : ""
                                      }
                                      onChange={(event) => {
                                        const newPlaybackDelay = event.target.value;
                                        setActions((prevActions) => [
                                          ...prevActions.slice(0, index),
                                          {
                                            ...prevActions[index],
                                            soundSettings: {
                                              ...prevActions[index].soundSettings,
                                              playbackDelayInMs: newPlaybackDelay,
                                            },
                                          },
                                          ...prevActions.slice(index + 1),
                                        ]);
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={4}>
                                  <FormGroup check className="mb-3">
                                    <Label
                                      check
                                      className="font-weight-bolder"
                                      style={{ fontSize: "16px" }}
                                    >
                                      <Input
                                        type="checkbox"
                                        name="loop"
                                        id="loop"
                                        checked={
                                          item.soundSettings
                                            ? item.soundSettings.loop
                                            : false
                                        }
                                        onChange={(event) => {
                                          const newLoopValue = event.target.checked;
                                          setActions((prevActions) => [
                                            ...prevActions.slice(0, index),
                                            {
                                              ...prevActions[index],
                                              soundSettings: {
                                                ...prevActions[index].soundSettings,
                                                loop: newLoopValue,
                                              },
                                            },
                                            ...prevActions.slice(index + 1),
                                          ]);
                                        }}
                                      />
                                      Enable Loop
                                    </Label>
                                  </FormGroup>
                                </Col>
                                <Col md={4}>
                                  <FormGroup>
                                    <Label
                                      className="font-weight-bolder mb-2"
                                      style={{ fontSize: "16px" }}
                                    >
                                      Loop Count:
                                    </Label>
                                    <Input
                                      type="number"
                                      name="loopCount"
                                      id="loopCount"
                                      placeholder="loopCount"
                                      value={
                                        item.soundSettings
                                          ? item.soundSettings.loopCount
                                          : ""
                                      }
                                      onChange={(event) => {
                                        const newLoopCount = event.target.value;
                                        setActions((prevActions) => [
                                          ...prevActions.slice(0, index),
                                          {
                                            ...prevActions[index],
                                            soundSettings: {
                                              ...prevActions[index].soundSettings,
                                              loopCount: newLoopCount,
                                            },
                                          },
                                          ...prevActions.slice(index + 1),
                                        ]);
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            )}

                            <Row className="pt-1" form>
                              <Col md={6} xs={12}>
                                <Row className="pt-1" form>
                                  <Col
                                    md={3}
                                    xs={3}
                                    className="mt-2"
                                    style={{
                                      maxWidth: "20%",
                                      paddingRight: "0px",
                                    }}
                                  >
                                    <h4>Left Icon</h4>
                                  </Col>
                                  <Col>
                                    <div className="p-0">
                                      <IconButton
                                        onClick={() => {
                                          const newActions = [...actions];
                                          newActions[index] = {
                                            ...newActions[index],
                                            leftIcon: {
                                              trackers: trackersInitialState
                                            },
                                          };
                                          setActions(newActions);
                                          setLeftIconToggle(!leftIconToggle);
                                        }}
                                        className="d-none d-lg-block d-flex"
                                      >
                                        {leftIconToggle ? (
                                          <BsToggleOn />
                                        ) : (
                                          <BsToggleOff />
                                        )}
                                      </IconButton>
                                    </div>
                                  </Col>
                                </Row>
                                {item.leftIcon && leftIconToggle && (
                                  <Card style={{ backgroundColor: "#F5F9FC" }}>
                                    <CardBody>
                                      <Row className="pt-1" form>
                                        <Col md={6} xs={6}>
                                          <FormGroup>
                                            <Label
                                              className="font-weight-bolder mb-2"
                                              style={{ fontSize: "16px" }}
                                            >
                                              Icon Image <CustomTooltip
                                                target="left-Icon-tooltip"
                                                content={<>(Max Static Size : {convertBytesToKB(fileSizeLimit.iconImage)}KB) or (Max Animated Size : {convertBytesToKB(fileSizeLimit.animatedIconImage)}KB)</>}
                                                placement="top"
                                              />
                                            </Label>
                                            <Input
                                              type="file"
                                              name="leftIconImage"
                                              id={`a-${index}-leftIconImage`}
                                              placeholder="Left Icon Image"
                                              onChange={(event) => {
                                                const file =
                                                  event.target.files[0];
                                                const allowedTypes = [
                                                  "image/png",
                                                  "image/jpg",
                                                  "image/jpeg",
                                                  "image/gif",
                                                  "image/webp",
                                                ];

                                                if (
                                                  !allowedTypes.includes(
                                                    file.type
                                                  )
                                                ) {
                                                  console.log(
                                                    "Invalid file type"
                                                  );
                                                  return;
                                                }

                                                if (checkFileSizeLimit(file, "Icon", fileSizeLimit.iconImage, fileSizeLimit.animatedIconImage)) return

                                                const reader = new FileReader();
                                                reader.onload = (e) => {
                                                  setActions([
                                                    ...actions.slice(0, index),
                                                    {
                                                      ...actions[index],
                                                      leftIcon: {
                                                        ...actions[index]
                                                          .leftIcon,
                                                        useExistingLeftIconForDarkTheme: false,
                                                        image: e.target.result,
                                                        imageName: file.name,
                                                      },
                                                    },
                                                    ...actions.slice(index + 1),
                                                  ]);
                                                };
                                                reader.readAsDataURL(file);
                                              }}
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md={6} xs={6}>
                                          <FormGroup>
                                            {/* Show the preview of the image if selected */}
                                            {(item.leftIcon.image ||
                                              item.leftIcon.imageURL) && (
                                                <img
                                                  src={
                                                    !item.leftIcon.image
                                                      ? item.leftIcon.imageURL
                                                      : item.leftIcon.image
                                                  }
                                                  alt="Left Icon Image"
                                                  style={{ width: "20%" }}
                                                />
                                              )}
                                          </FormGroup>
                                        </Col>

                                        <Col md={6} xs={6}>
                                          <FormGroup>
                                            <Label
                                              className="font-weight-bolder mb-2"
                                              style={{ fontSize: "16px" }}
                                            >
                                              Dark Theme Icon Image <CustomTooltip
                                                target="left-dark-icon-tooltip"
                                                content={<>(Max Static Size : {convertBytesToKB(fileSizeLimit.iconImage)}KB) or (Max Animated Size : {convertBytesToKB(fileSizeLimit.animatedIconImage)}KB)</>}
                                                placement="top"
                                              />
                                            </Label>
                                            <Input
                                              type="file"
                                              name="leftIconImage"
                                              id={`a-${index}-leftIconImage-darkTheme`}
                                              placeholder="Left Icon Image (Dark Theme)"
                                              onChange={(event) => {
                                                const file =
                                                  event.target.files[0];
                                                const allowedTypes = [
                                                  "image/png",
                                                  "image/jpg",
                                                  "image/jpeg",
                                                  "image/gif",
                                                  "image/webp",
                                                ];

                                                if (
                                                  !allowedTypes.includes(
                                                    file.type
                                                  )
                                                ) {
                                                  console.log(
                                                    "Invalid file type"
                                                  );
                                                  return;
                                                }

                                                if (checkFileSizeLimit(file, "Dark Theme Icon", fileSizeLimit.iconImage, fileSizeLimit.animatedIconImage)) return

                                                const reader = new FileReader();
                                                reader.onload = (e) => {
                                                  setActions([
                                                    ...actions.slice(0, index),
                                                    {
                                                      ...actions[index],
                                                      leftIcon: {
                                                        ...actions[index]
                                                          .leftIcon,
                                                        useExistingLeftIconForDarkTheme: false,
                                                        darkThemeImage:
                                                          e.target.result,
                                                        darkThemeImageName:
                                                          file.name,
                                                      },
                                                    },
                                                    ...actions.slice(index + 1),
                                                  ]);
                                                };
                                                reader.readAsDataURL(file);
                                              }}
                                            />
                                          </FormGroup>
                                        </Col>

                                        <Col md={6} xs={6}>
                                          <FormGroup>
                                            {/* Show the preview of the image if selected */}
                                            {(item.leftIcon.darkThemeImage ||
                                              item.leftIcon
                                                .darkThemeImageURL) && (
                                                <img
                                                  src={
                                                    !item.leftIcon.darkThemeImage
                                                      ? item.leftIcon
                                                        .darkThemeImageURL
                                                      : item.leftIcon.darkThemeImage
                                                  }
                                                  alt="Left Icon Image"
                                                  style={{ width: "20%" }}
                                                />
                                              )}
                                          </FormGroup>
                                        </Col>

                                        <Col md={12} xs={12}>

                                          {(item.leftIcon.image || item.leftIcon.imageURL) && (
                                            <FormGroup>
                                              <Label className="font-weight-bolder mb-2" style={{ fontSize: "16px" }}>
                                                Use Existing Left Icon for Dark Theme
                                              </Label>
                                              <div className="custom-control custom-switch">
                                                <CustomInput
                                                  type="checkbox"
                                                  id={`useExistingLeftIconForDarkTheme-${index}`}
                                                  value={item.leftIcon.useExistingLeftIconForDarkTheme}
                                                  onChange={(event) => {
                                                    // When the toggle is checked/unchecked, update the dark theme specific properties for the right icon
                                                    setActions([
                                                      ...actions.slice(0, index),
                                                      {
                                                        ...actions[index],
                                                        leftIcon: {
                                                          ...actions[index].leftIcon,
                                                          useExistingLeftIconForDarkTheme: event.target.checked,
                                                          darkThemeImage: event.target.checked ? item.leftIcon.image : null,
                                                          darkThemeImageName: event.target.checked ? item.leftIcon.imageName : null,
                                                        },
                                                      },
                                                      ...actions.slice(index + 1),
                                                    ]);
                                                  }}
                                                  checked={item.leftIcon.useExistingLeftIconForDarkTheme}
                                                />
                                              </div>
                                            </FormGroup>
                                          )}
                                        </Col>

                                        <Col md={6}>
                                          <FormGroup>
                                            <Label
                                              className="font-weight-bolder mb-2"
                                              style={{ fontSize: "16px" }}
                                            >
                                              Width Percentage:
                                            </Label>
                                            <CustomTooltip
                                              target="width-tooltip"
                                              content={<>Icon Width Percentage used within the pill</>}
                                              placement="top"
                                            />
                                            <Input
                                              type="number"
                                              min={0}
                                              step={0.01}
                                              name="widthPercentage"
                                              id={`a-${index}-leftIcon-widthPercentage`}
                                              placeholder="Width Percentage"
                                              value={
                                                item.leftIcon.widthPercentage
                                              }
                                              onChange={(event) => {
                                                setActions([
                                                  ...actions.slice(0, index),
                                                  {
                                                    ...actions[index],
                                                    leftIcon: {
                                                      ...actions[index].leftIcon,
                                                      widthPercentage:
                                                        event.target.value,
                                                    },
                                                  },
                                                  ...actions.slice(index + 1),
                                                ]);
                                              }}
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md={6} xs={6}>
                                          <FormGroup>
                                            <Label
                                              className="font-weight-bolder mb-2"
                                              style={{ fontSize: "16px" }}
                                            >
                                              Icon Shape
                                            </Label>
                                            <Input
                                              type="select"
                                              name="iconShape"
                                              id={`a-${index}-iconShape`}
                                              value={
                                                item.leftIcon.iconShape || ""
                                              } // Set value to '' if iconShape is null
                                              onChange={(event) => {
                                                const shape =
                                                  event.target.value || null; // Set shape to null if no option is selected
                                                setActions([
                                                  ...actions.slice(0, index),
                                                  {
                                                    ...actions[index],
                                                    leftIcon: {
                                                      ...actions[index].leftIcon,
                                                      iconShape: shape,
                                                    },
                                                  },
                                                  ...actions.slice(index + 1),
                                                ]);
                                              }}
                                            >
                                              <option value="">
                                                Select Icon Shape
                                              </option>
                                              <option value="circle">
                                                Circle
                                              </option>
                                              <option value="round_corner_small">
                                                Round Corner Small
                                              </option>
                                              <option value="round_corner_large">
                                                Round Corner Large
                                              </option>
                                            </Input>
                                          </FormGroup>
                                        </Col>

                                        <Col md={6} xs={6}>
                                          <FormGroup>
                                            <Label
                                              className="font-weight-bolder mb-2"
                                              style={{ fontSize: "16px" }}
                                            >
                                              Icon Scale
                                            </Label>
                                            <CustomTooltip
                                              target="crop-tooltip"
                                              content={<>Handles Image cropping</>}
                                              placement="top"
                                            />
                                            <Input
                                              type="select"
                                              name="iconScale"
                                              id={`a-${index}-iconScale`}
                                              value={
                                                item.leftIcon.iconScale || ""
                                              }
                                              onChange={(event) => {
                                                const scale =
                                                  event.target.value === ""
                                                    ? null
                                                    : event.target.value;
                                                setActions([
                                                  ...actions.slice(0, index),
                                                  {
                                                    ...actions[index],
                                                    leftIcon: {
                                                      ...actions[index].leftIcon,
                                                      iconScale: scale,
                                                    },
                                                  },
                                                  ...actions.slice(index + 1),
                                                ]);
                                              }}
                                            >
                                              <option value="">
                                                Select Icon Scale
                                              </option>
                                              <option value="scale_fit">
                                                Scale Fit
                                              </option>
                                              <option value="scale_center_crop">
                                                Scale Center Crop
                                              </option>
                                            </Input>
                                          </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                          <FormGroup>
                                            <Label
                                              className="font-weight-bolder mb-2"
                                              style={{ fontSize: "16px" }}
                                            >
                                              Aspect Ratio:
                                            </Label>
                                            <CustomTooltip
                                              target="aspectRatio-tooltip"
                                              content={<>Left Icon Aspect Ratio relative to the pill</>}
                                              placement="top"
                                            />
                                            <Input
                                              type="number"
                                              min={0}
                                              step={0.01}
                                              name="aspectRatio"
                                              id={`a-${index}-leftIcon-aspectRatio`}
                                              placeholder="Aspect Ratio"
                                              value={
                                                item.leftIcon.aspectRatio
                                              }
                                              onChange={(event) => {
                                                setActions([
                                                  ...actions.slice(0, index),
                                                  {
                                                    ...actions[index],
                                                    leftIcon: {
                                                      ...actions[index].leftIcon,
                                                      aspectRatio:
                                                        event.target.value,
                                                    },
                                                  },
                                                  ...actions.slice(index + 1),
                                                ]);
                                              }}
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                          <FormGroup>
                                            <Label
                                              className="font-weight-bolder mb-2"
                                              style={{ fontSize: "16px" }}
                                            >
                                              Background Color:{" "}
                                              <div
                                                style={{
                                                  backgroundColor:
                                                    item.leftIcon.backgroundColor,
                                                  width: "16px",
                                                  height: "16px",
                                                  display: "inline-block",
                                                  verticalAlign: "middle",
                                                  border: "1px solid #000",
                                                  borderRadius: "30%",
                                                }}
                                              ></div>
                                            </Label>
                                            <Input
                                              type="backgroundColor"
                                              name="backgroundColor"
                                              id={`a-${index}-leftIcon-backgroundColor`}
                                              placeholder="Background Color"
                                              value={
                                                item.leftIcon.backgroundColor
                                              }
                                              onChange={(event) => {
                                                setActions([
                                                  ...actions.slice(0, index),
                                                  {
                                                    ...actions[index],
                                                    leftIcon: {
                                                      ...actions[index].leftIcon,
                                                      backgroundColor:
                                                        event.target.value,
                                                    },
                                                  },
                                                  ...actions.slice(index + 1),
                                                ]);
                                              }}
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                          <FormGroup>
                                            <Label
                                              className="font-weight-bolder mb-2"
                                              style={{ fontSize: "16px" }}
                                            >
                                              Dark Theme Background Color:{" "}
                                              <div
                                                style={{
                                                  backgroundColor:
                                                    item.leftIcon
                                                      .darkThemeBackgroundColor,
                                                  width: "16px",
                                                  height: "16px",
                                                  display: "inline-block",
                                                  verticalAlign: "middle",
                                                  border: "1px solid #000",
                                                  borderRadius: "30%",
                                                }}
                                              ></div>
                                            </Label>
                                            <Input
                                              type="darkThemeBackgroundColor"
                                              name="darkThemeBackgroundColor"
                                              id={`a-${index}-leftIcon-darkThemeBackgroundColor`}
                                              placeholder="Background Color"
                                              value={
                                                item.leftIcon
                                                  .darkThemeBackgroundColor
                                              }
                                              onChange={(event) => {
                                                setActions([
                                                  ...actions.slice(0, index),
                                                  {
                                                    ...actions[index],
                                                    leftIcon: {
                                                      ...actions[index].leftIcon,
                                                      darkThemeBackgroundColor:
                                                        event.target.value,
                                                    },
                                                  },
                                                  ...actions.slice(index + 1),
                                                ]);
                                              }}
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md={12} xs={12}>
                                          <FormGroup>
                                            <Label
                                              className="font-weight-bolder mb-2"
                                              style={{ fontSize: "16px" }}
                                            >
                                              Deeplink{" "}
                                            </Label>
                                            <Input
                                              type="textarea"
                                              name="deeplink"
                                              id="deeplink"
                                              style={{ height: "5rem" }}
                                              placeholder="Enter Deep Link"
                                              value={item.leftIcon.deeplink}
                                              onChange={(event) => {
                                                setActions([
                                                  ...actions.slice(0, index),
                                                  {
                                                    ...actions[index],
                                                    leftIcon: {
                                                      ...actions[index].leftIcon,
                                                      deeplink:
                                                        event.target.value,
                                                    },
                                                  },
                                                  ...actions.slice(index + 1),
                                                ]);
                                              }}
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md={12} xs={12}>
                                          <FormGroup className="mb-0">
                                            <Label
                                              className="font-weight-bolder mb-2"
                                              style={{ fontSize: "16px" }}
                                            >
                                              Trackers
                                            </Label>
                                          </FormGroup>
                                          {item.leftIcon?.trackers?.map((tracker, trackerIndex) => (
                                            <Row key={trackerIndex} form>
                                              <Col>
                                                <Row form>
                                                  <Col md={12} xs={12}>
                                                    <FormGroup>
                                                      <Label
                                                        className="font-weight-bolder mb-2"
                                                        style={{ fontSize: "14px" }}
                                                      >
                                                        Tracker Type
                                                      </Label>
                                                      <Select
                                                        id="type"
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        name="type"
                                                        placeholder="Select Tracker Type"
                                                        value={tracker.type}
                                                        onChange={(event) => {
                                                          setActions([
                                                            ...actions.slice(0, index),
                                                            {
                                                              ...actions[index],
                                                              leftIcon: {
                                                                ...actions[index].leftIcon,
                                                                trackers: [
                                                                  ...actions[index].leftIcon.trackers.slice(0, trackerIndex),
                                                                  {
                                                                    ...actions[index].leftIcon.trackers[trackerIndex],
                                                                    type: event,
                                                                  },
                                                                  ...actions[index].leftIcon.trackers.slice(trackerIndex + 1),
                                                                ],
                                                              },
                                                            },
                                                            ...actions.slice(index + 1),
                                                          ]);
                                                        }}
                                                        options={trackerTypeOptions}
                                                      />
                                                    </FormGroup>
                                                  </Col>
                                                  <Col md={12} xs={12}>
                                                    <FormGroup>
                                                      <Label
                                                        className="font-weight-bolder mb-2"
                                                        style={{ fontSize: "14px" }}
                                                      >
                                                        Tracker URL
                                                      </Label>
                                                      <Input
                                                        type="text"
                                                        name="url"
                                                        placeholder="Tracker URL"
                                                        value={tracker.url}
                                                        onChange={(event) => {
                                                          setActions([
                                                            ...actions.slice(0, index),
                                                            {
                                                              ...actions[index],
                                                              leftIcon: {
                                                                ...actions[index].leftIcon,
                                                                trackers: [
                                                                  ...actions[index].leftIcon.trackers.slice(0, trackerIndex),
                                                                  {
                                                                    ...actions[index].leftIcon.trackers[trackerIndex],
                                                                    url: event.target.value,
                                                                  },
                                                                  ...actions[index].leftIcon.trackers.slice(trackerIndex + 1),
                                                                ],
                                                              },
                                                            },
                                                            ...actions.slice(index + 1),
                                                          ]);
                                                        }}
                                                      />
                                                    </FormGroup>
                                                  </Col>
                                                </Row>
                                              </Col>
                                              <Col md={1} className="mt-4">
                                                {trackerIndex === 0 ? (
                                                  <Button onClick={() => {
                                                    setActions([
                                                      ...actions.slice(0, index),
                                                      {
                                                        ...actions[index],
                                                        leftIcon: {
                                                          ...actions[index].leftIcon,
                                                          trackers: [
                                                            ...actions[index].leftIcon.trackers,
                                                            {
                                                              type: null,
                                                              url: ""
                                                            },
                                                          ],
                                                        }
                                                      },
                                                      ...actions.slice(index + 1),
                                                    ]);
                                                  }} color="transparent">
                                                    <FaPlus />
                                                  </Button>
                                                ) : (
                                                  <Button onClick={(e) => {
                                                    e.preventDefault();
                                                    setActions([
                                                      ...actions.slice(0, index),
                                                      {
                                                        ...actions[index],
                                                        leftIcon: {
                                                          ...actions[index].leftIcon,
                                                          trackers: item.leftIcon.trackers?.filter((_, i) => i !== trackerIndex)
                                                        },
                                                      },
                                                      ...actions.slice(index + 1),
                                                    ]);
                                                  }} color="transparent">
                                                    <FaMinus />
                                                  </Button>
                                                )}
                                              </Col>
                                              <Col lg="12">
                                                {trackerIndex !== (item.leftIcon.trackers?.length - 1) && <hr />}
                                              </Col>
                                            </Row>
                                          ))}
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                )}
                              </Col>
                              <Col md={6} xs={12}>
                                <Row className="pt-1" form>
                                  <Col
                                    md={3}
                                    xs={3}
                                    className="mt-2"
                                    style={{
                                      maxWidth: "20%",
                                      paddingRight: "0px",
                                    }}
                                  >
                                    <h4>Right Icon</h4>
                                  </Col>
                                  <Col>
                                    <div className="p-0">
                                      <IconButton
                                        onClick={() => {
                                          const newActions = [...actions];
                                          newActions[index] = {
                                            ...newActions[index],
                                            rightIcon: {
                                              trackers: trackersInitialState
                                            },
                                          };
                                          setActions(newActions);
                                          setRightIconToggle(!rightIconToggle);
                                        }}
                                        className="d-none d-lg-block d-flex"
                                      >
                                        {rightIconToggle ? (
                                          <BsToggleOn />
                                        ) : (
                                          <BsToggleOff />
                                        )}
                                      </IconButton>
                                    </div>
                                  </Col>
                                </Row>
                                {item.rightIcon && rightIconToggle && (
                                  <Card style={{ backgroundColor: "#F5F9FC" }}>
                                    <CardBody>
                                      <Row className="pt-1" form>
                                        <Col md={6} xs={6}>
                                          <FormGroup>
                                            <Label
                                              className="font-weight-bolder mb-2"
                                              style={{ fontSize: "16px" }}
                                            >
                                              Icon Image <CustomTooltip
                                                target="right-icon-tooltip"
                                                content={<>(Max Static Size : {convertBytesToKB(fileSizeLimit.iconImage)}KB) or (Max Animated Size : {convertBytesToKB(fileSizeLimit.animatedIconImage)}KB)</>}
                                                placement="top"
                                              />
                                            </Label>
                                            <Input
                                              type="file"
                                              name="rightIconImage"
                                              id={`a-${index}-rightIconImage`}
                                              placeholder="Right Icon Image"
                                              onChange={(event) => {
                                                const file =
                                                  event.target.files[0];
                                                const allowedTypes = [
                                                  "image/png",
                                                  "image/jpg",
                                                  "image/jpeg",
                                                  "image/gif",
                                                  "image/webp",
                                                ];

                                                if (
                                                  !allowedTypes.includes(
                                                    file.type
                                                  )
                                                ) {
                                                  console.log(
                                                    "Invalid file type"
                                                  );
                                                  return;
                                                }

                                                if (checkFileSizeLimit(file, "Icon", fileSizeLimit.iconImage, fileSizeLimit.animatedIconImage)) return

                                                const reader = new FileReader();
                                                reader.onload = (e) => {
                                                  setActions([
                                                    ...actions.slice(0, index),
                                                    {
                                                      ...actions[index],
                                                      rightIcon: {
                                                        ...actions[index]
                                                          .rightIcon,
                                                        image: e.target.result,
                                                        imageName: file.name,
                                                      },
                                                    },
                                                    ...actions.slice(index + 1),
                                                  ]);
                                                };
                                                reader.readAsDataURL(file);
                                              }}
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md={6} xs={6}>
                                          <FormGroup>
                                            {/* Show the preview of the image if selected */}
                                            {(item.rightIcon.image ||
                                              item.rightIcon.imageURL) && (
                                                <img
                                                  src={
                                                    !item.rightIcon.image
                                                      ? item.rightIcon.imageURL
                                                      : item.rightIcon.image
                                                  }
                                                  alt="Right Icon Image"
                                                  style={{ width: "20%" }}
                                                />
                                              )}
                                          </FormGroup>
                                        </Col>

                                        <Col md={6} xs={6}>
                                          <FormGroup>
                                            <Label
                                              className="font-weight-bolder mb-2"
                                              style={{ fontSize: "16px" }}
                                            >
                                              Icon Image 2 <CustomTooltip
                                                target="right-icon-2-tooltip"
                                                content={<>(Max Static Size : {convertBytesToKB(fileSizeLimit.iconImage)}KB) or (Max Animated Size : {convertBytesToKB(fileSizeLimit.animatedIconImage)}KB)</>}
                                                placement="top"
                                              />
                                            </Label>
                                            <Input
                                              type="file"
                                              name="rightIconImage2"
                                              id={`a-${index}-rightIconImage2`}
                                              placeholder="Right Icon Image 2"
                                              onChange={(event) => {
                                                const file =
                                                  event.target.files[0];
                                                const allowedTypes = [
                                                  "image/png",
                                                  "image/jpg",
                                                  "image/jpeg",
                                                  "image/gif",
                                                  "image/webp",
                                                ];

                                                if (
                                                  !allowedTypes.includes(
                                                    file.type
                                                  )
                                                ) {
                                                  console.log(
                                                    "Invalid file type"
                                                  );
                                                  return;
                                                }

                                                if (checkFileSizeLimit(file, "Icon 2", fileSizeLimit.iconImage, fileSizeLimit.animatedIconImage)) return

                                                const reader = new FileReader();
                                                reader.onload = (e) => {
                                                  setActions([
                                                    ...actions.slice(0, index),
                                                    {
                                                      ...actions[index],
                                                      rightIcon2: {
                                                        ...actions[index]
                                                          .rightIcon2,
                                                        image: e.target.result,
                                                        imageName: file.name,
                                                      },
                                                    },
                                                    ...actions.slice(index + 1),
                                                  ]);
                                                };
                                                reader.readAsDataURL(file);
                                              }}
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md={6} xs={6}>
                                          <FormGroup>
                                            {/* Show the preview of Image 2 if selected */}
                                            {(item.rightIcon2?.image ||
                                              item.rightIcon2?.imageURL) && (
                                                <img
                                                  src={
                                                    !item.rightIcon2.image
                                                      ? item.rightIcon2.imageURL
                                                      : item.rightIcon2.image
                                                  }
                                                  alt="Right Icon Image 2"
                                                  style={{ width: "20%" }}
                                                />
                                              )}
                                          </FormGroup>
                                        </Col>

                                        <Col md={6} xs={6}>
                                          <FormGroup>
                                            <Label
                                              className="font-weight-bolder mb-2"
                                              style={{ fontSize: "16px" }}
                                            >
                                              Dark Theme Icon Image <CustomTooltip
                                                target="right-dark-icon-tooltip"
                                                content={<>(Max Static Size : {convertBytesToKB(fileSizeLimit.iconImage)}KB) or (Max Animated Size : {convertBytesToKB(fileSizeLimit.animatedIconImage)}KB)</>}
                                                placement="top"
                                              />
                                            </Label>
                                            <Input
                                              type="file"
                                              name="rightIconImage"
                                              id={`a-${index}-rightIconImage-darkTheme`}
                                              placeholder="Right Icon Image (Dark Theme)"
                                              onChange={(event) => {
                                                const file =
                                                  event.target.files[0];
                                                const allowedTypes = [
                                                  "image/png",
                                                  "image/jpg",
                                                  "image/jpeg",
                                                  "image/gif",
                                                  "image/webp",
                                                ];

                                                if (
                                                  !allowedTypes.includes(
                                                    file.type
                                                  )
                                                ) {
                                                  console.log(
                                                    "Invalid file type"
                                                  );
                                                  return;
                                                }

                                                if (checkFileSizeLimit(file, "Dark Theme Icon", fileSizeLimit.iconImage, fileSizeLimit.animatedIconImage)) return

                                                const reader = new FileReader();
                                                reader.onload = (e) => {
                                                  setActions([
                                                    ...actions.slice(0, index),
                                                    {
                                                      ...actions[index],
                                                      rightIcon: {
                                                        ...actions[index]
                                                          .rightIcon,
                                                        darkThemeImage:
                                                          e.target.result,
                                                        darkThemeImageName:
                                                          file.name,
                                                      },
                                                    },
                                                    ...actions.slice(index + 1),
                                                  ]);
                                                };
                                                reader.readAsDataURL(file);
                                              }}
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md={6} xs={6}>
                                          <FormGroup>
                                            {/* Show the preview of the image if selected */}
                                            {(item.rightIcon.darkThemeImage ||
                                              item.rightIcon
                                                .darkThemeImageURL) && (
                                                <img
                                                  src={
                                                    !item.rightIcon.darkThemeImage
                                                      ? item.rightIcon
                                                        .darkThemeImageURL
                                                      : item.rightIcon
                                                        .darkThemeImage
                                                  }
                                                  alt="Right Icon Image"
                                                  style={{ width: "20%" }}
                                                />
                                              )}
                                          </FormGroup>
                                        </Col>

                                        <Col md={6} xs={6}>
                                          <FormGroup>
                                            <Label
                                              className="font-weight-bolder mb-2"
                                              style={{ fontSize: "16px" }}
                                            >
                                              Dark Theme Icon Image 2 <CustomTooltip
                                                target="right-dark-icon-2-tooltip"
                                                content={<>(Max Static Size : {convertBytesToKB(fileSizeLimit.iconImage)}KB) or (Max Animated Size : {convertBytesToKB(fileSizeLimit.animatedIconImage)}KB)</>}
                                                placement="top"
                                              />
                                            </Label>
                                            <Input
                                              type="file"
                                              name="rightIconImage2"
                                              id={`a-${index}-rightIconImage-darkTheme2`}
                                              placeholder="Right Icon Image 2 (Dark Theme)"
                                              onChange={(event) => {
                                                const file =
                                                  event.target.files[0];
                                                const allowedTypes = [
                                                  "image/png",
                                                  "image/jpg",
                                                  "image/jpeg",
                                                  "image/gif",
                                                  "image/webp",
                                                ];

                                                if (
                                                  !allowedTypes.includes(
                                                    file.type
                                                  )
                                                ) {
                                                  console.log(
                                                    "Invalid file type"
                                                  );
                                                  return;
                                                }

                                                if (checkFileSizeLimit(file, "Dark Theme Icon 2", fileSizeLimit.iconImage, fileSizeLimit.animatedIconImage)) return

                                                const reader = new FileReader();
                                                reader.onload = (e) => {
                                                  setActions([
                                                    ...actions.slice(0, index),
                                                    {
                                                      ...actions[index],
                                                      rightIcon2: {
                                                        ...actions[index]
                                                          .rightIcon2,
                                                        darkThemeImage:
                                                          e.target.result,
                                                        darkThemeImageName:
                                                          file.name,
                                                      },
                                                    },
                                                    ...actions.slice(index + 1),
                                                  ]);
                                                };
                                                reader.readAsDataURL(file);
                                              }}
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md={6} xs={6}>
                                          <FormGroup>
                                            {/* Show the preview of Image 2 if selected */}
                                            {(item.rightIcon2.darkThemeImage ||
                                              item.rightIcon2
                                                .darkThemeImageURL) && (
                                                <img
                                                  src={
                                                    !item.rightIcon2.darkThemeImage
                                                      ? item.rightIcon2
                                                        .darkThemeImageURL
                                                      : item.rightIcon2
                                                        .darkThemeImage
                                                  }
                                                  alt="Right Icon Image"
                                                  style={{ width: "20%" }}
                                                />
                                              )}
                                          </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                          <FormGroup>
                                            <Label
                                              className="font-weight-bolder mb-2"
                                              style={{ fontSize: "16px" }}
                                            >
                                              Width Percentage:
                                            </Label>
                                            <Input
                                              type="number"
                                              min={0}
                                              step={0.01}
                                              name="widthPercentage"
                                              id={`a-${index}-rightIcon-widthPercentage`}
                                              placeholder="Width Percentage"
                                              value={
                                                item.rightIcon.widthPercentage
                                              }
                                              onChange={(event) => {
                                                setActions([
                                                  ...actions.slice(0, index),
                                                  {
                                                    ...actions[index],
                                                    rightIcon: {
                                                      ...actions[index].rightIcon,
                                                      widthPercentage:
                                                        event.target.value,
                                                    },
                                                  },
                                                  ...actions.slice(index + 1),
                                                ]);
                                              }}
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                          <FormGroup>
                                            <Label
                                              className="font-weight-bolder mb-2"
                                              style={{ fontSize: "16px" }}
                                            >
                                              Aspect Ratio:
                                            </Label>
                                            <Input
                                              type="aspectRatio"
                                              name="aspectRatio"
                                              id={`a-${index}-rightIcon-aspectRatio`}
                                              placeholder="Aspect Ratio"
                                              value={item.rightIcon.aspectRatio}
                                              onChange={(event) => {
                                                setActions([
                                                  ...actions.slice(0, index),
                                                  {
                                                    ...actions[index],
                                                    rightIcon: {
                                                      ...actions[index].rightIcon,
                                                      aspectRatio:
                                                        event.target.value,
                                                    },
                                                  },
                                                  ...actions.slice(index + 1),
                                                ]);
                                              }}
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                          <FormGroup>
                                            <Label
                                              className="font-weight-bolder mb-2"
                                              style={{ fontSize: "16px" }}
                                            >
                                              Background Color:{" "}
                                              <div
                                                style={{
                                                  backgroundColor:
                                                    item.rightIcon
                                                      .backgroundColor,
                                                  width: "16px",
                                                  height: "16px",
                                                  display: "inline-block",
                                                  verticalAlign: "middle",
                                                  border: "1px solid #000",
                                                  borderRadius: "30%",
                                                }}
                                              ></div>
                                            </Label>
                                            <Input
                                              type="backgroundColor"
                                              name="backgroundColor"
                                              id={`a-${index}-rightIcon-backgroundColor`}
                                              placeholder="Background Color"
                                              value={
                                                item.rightIcon.backgroundColor
                                              }
                                              onChange={(event) => {
                                                setActions([
                                                  ...actions.slice(0, index),
                                                  {
                                                    ...actions[index],
                                                    rightIcon: {
                                                      ...actions[index].rightIcon,
                                                      backgroundColor:
                                                        event.target.value,
                                                    },
                                                  },
                                                  ...actions.slice(index + 1),
                                                ]);
                                              }}
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                          <FormGroup>
                                            <Label
                                              className="font-weight-bolder mb-2"
                                              style={{ fontSize: "16px" }}
                                            >
                                              Dark Theme Background Color:{" "}
                                              <div
                                                style={{
                                                  backgroundColor:
                                                    item.rightIcon
                                                      .darkThemeBackgroundColor,
                                                  width: "16px",
                                                  height: "16px",
                                                  display: "inline-block",
                                                  verticalAlign: "middle",
                                                  border: "1px solid #000",
                                                  borderRadius: "30%",
                                                }}
                                              ></div>
                                            </Label>
                                            <Input
                                              type="darkThemeBackgroundColor"
                                              name="darkThemeBackgroundColor"
                                              id={`a-${index}-rightIcon-darkThemeBackgroundColor`}
                                              placeholder="Background Color"
                                              value={
                                                item.rightIcon
                                                  .darkThemeBackgroundColor
                                              }
                                              onChange={(event) => {
                                                setActions([
                                                  ...actions.slice(0, index),
                                                  {
                                                    ...actions[index],
                                                    rightIcon: {
                                                      ...actions[index].rightIcon,
                                                      darkThemeBackgroundColor:
                                                        event.target.value,
                                                    },
                                                  },
                                                  ...actions.slice(index + 1),
                                                ]);
                                              }}
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md={12} xs={12}>
                                          <FormGroup>
                                            <Label
                                              className="font-weight-bolder mb-2"
                                              style={{ fontSize: "16px" }}
                                            >
                                              Deeplink{" "}
                                            </Label>
                                            <Input
                                              type="textarea"
                                              name="deeplink"
                                              id="deeplink"
                                              style={{ height: "5rem" }}
                                              placeholder="Enter Deep Link"
                                              value={item.rightIcon.deeplink}
                                              onChange={(event) => {
                                                setActions([
                                                  ...actions.slice(0, index),
                                                  {
                                                    ...actions[index],
                                                    rightIcon: {
                                                      ...actions[index].rightIcon,
                                                      deeplink:
                                                        event.target.value,
                                                    },
                                                  },
                                                  ...actions.slice(index + 1),
                                                ]);
                                              }}
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md={12} xs={12}>
                                          <FormGroup className="mb-0">
                                            <Label
                                              className="font-weight-bolder mb-2"
                                              style={{ fontSize: "16px" }}
                                            >
                                              Trackers
                                            </Label>
                                          </FormGroup>
                                          {item.rightIcon?.trackers?.map((tracker, trackerIndex) => (
                                            <Row key={trackerIndex} form>
                                              <Col>
                                                <Row form>
                                                  <Col md={12} xs={12}>
                                                    <FormGroup>
                                                      <Label
                                                        className="font-weight-bolder mb-2"
                                                        style={{ fontSize: "14px" }}
                                                      >
                                                        Tracker Type
                                                      </Label>
                                                      <Select
                                                        id="type"
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        name="type"
                                                        placeholder="Select Tracker Type"
                                                        value={tracker.type}
                                                        onChange={(event) => {
                                                          setActions([
                                                            ...actions.slice(0, index),
                                                            {
                                                              ...actions[index],
                                                              rightIcon: {
                                                                ...actions[index].rightIcon,
                                                                trackers: [
                                                                  ...actions[index].rightIcon.trackers.slice(0, trackerIndex),
                                                                  {
                                                                    ...actions[index].rightIcon.trackers[trackerIndex],
                                                                    type: event,
                                                                  },
                                                                  ...actions[index].rightIcon.trackers.slice(trackerIndex + 1),
                                                                ],
                                                              },
                                                            },
                                                            ...actions.slice(index + 1),
                                                          ]);
                                                        }}
                                                        options={trackerTypeOptions}
                                                      />
                                                    </FormGroup>
                                                  </Col>
                                                  <Col md={12} xs={12}>
                                                    <FormGroup>
                                                      <Label
                                                        className="font-weight-bolder mb-2"
                                                        style={{ fontSize: "14px" }}
                                                      >
                                                        Tracker URL
                                                      </Label>
                                                      <Input
                                                        type="text"
                                                        name="url"
                                                        placeholder="Tracker URL"
                                                        value={tracker.url}
                                                        onChange={(event) => {
                                                          setActions([
                                                            ...actions.slice(0, index),
                                                            {
                                                              ...actions[index],
                                                              rightIcon: {
                                                                ...actions[index].rightIcon,
                                                                trackers: [
                                                                  ...actions[index].rightIcon.trackers.slice(0, trackerIndex),
                                                                  {
                                                                    ...actions[index].rightIcon.trackers[trackerIndex],
                                                                    url: event.target.value,
                                                                  },
                                                                  ...actions[index].rightIcon.trackers.slice(trackerIndex + 1),
                                                                ],
                                                              },
                                                            },
                                                            ...actions.slice(index + 1),
                                                          ]);
                                                        }}
                                                      />
                                                    </FormGroup>
                                                  </Col>
                                                </Row>
                                              </Col>
                                              <Col md={1} className="mt-4">
                                                {trackerIndex === 0 ? (
                                                  <Button onClick={() => {
                                                    setActions([
                                                      ...actions.slice(0, index),
                                                      {
                                                        ...actions[index],
                                                        rightIcon: {
                                                          ...actions[index].rightIcon,
                                                          trackers: [
                                                            ...actions[index].rightIcon.trackers,
                                                            {
                                                              type: null,
                                                              url: ""
                                                            },
                                                          ],
                                                        }
                                                      },
                                                      ...actions.slice(index + 1),
                                                    ]);
                                                  }} color="transparent">
                                                    <FaPlus />
                                                  </Button>
                                                ) : (
                                                  <Button onClick={(e) => {
                                                    e.preventDefault();
                                                    setActions([
                                                      ...actions.slice(0, index),
                                                      {
                                                        ...actions[index],
                                                        rightIcon: {
                                                          ...actions[index].rightIcon,
                                                          trackers: item.rightIcon.trackers?.filter((_, i) => i !== trackerIndex)
                                                        },
                                                      },
                                                      ...actions.slice(index + 1),
                                                    ]);
                                                  }} color="transparent">
                                                    <FaMinus />
                                                  </Button>
                                                )}
                                              </Col>
                                              <Col lg="12">
                                                {trackerIndex !== (item.rightIcon.trackers?.length - 1) && <hr />}
                                              </Col>
                                            </Row>
                                          ))}
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                )}
                              </Col>
                            </Row>
                            <Row className="pt-1" form>
                              <Col md={6} xs={6} className="mt-2 mb-2">
                                <h3>Buttons</h3>
                              </Col>
                              <Col md={6} xs={6}>
                                <div className="p-0">
                                  <Button
                                    color="success"
                                    className="ml-auto d-flex btn-success"
                                    onClick={() => {
                                      setActions([
                                        ...actions.slice(0, index),
                                        {
                                          ...actions[index],
                                          buttons: [
                                            ...actions[index].buttons,
                                            {
                                              text: "",
                                              deeplink: "",
                                              backgroundColor: "",
                                              darkThemeBackgroundColor: "",
                                              textColor: "",
                                              darkThemeTextColor: "",
                                              widthPercentage: "",
                                              aspectRatio: "",
                                              trackers: trackersInitialState
                                            },
                                          ],
                                        },
                                        ...actions.slice(index + 1),
                                      ]);
                                    }}
                                  >
                                    <p
                                      className="mb-1 mt-1"
                                      style={{ fontSize: "1rem" }}
                                    >
                                      Add Button
                                    </p>
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            {item.buttons?.map((btn, btnIndex) => {
                              return (
                                <Card style={{ backgroundColor: "#F5F9FC" }}>
                                  <CardBody>
                                    <Row
                                      className="pb-3 pt-1"
                                      key={btnIndex}
                                      form
                                    >
                                      <Col md={6}>
                                        <h4>Button {btnIndex + 1}</h4>
                                      </Col>
                                      <Col md={6}>
                                        <div
                                          className="p-0"
                                          style={{ marginRight: "20px" }}
                                        >
                                          <X
                                            className="d-none d-lg-block ml-auto d-flex"
                                            size={iconSize}
                                            color="red"
                                            style={{
                                              cursor: "pointer",
                                              border: "2px solid red",
                                            }}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setActions([
                                                ...actions.slice(0, index),
                                                {
                                                  ...actions[index],
                                                  buttons: item.buttons?.filter(
                                                    (_, i) => i !== btnIndex
                                                  ),
                                                },
                                                ...actions.slice(index + 1),
                                              ]);
                                            }}
                                          ></X>
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <FormGroup>
                                          <Label
                                            className="font-weight-bolder mb-2"
                                            style={{ fontSize: "16px" }}
                                          >
                                            Text:
                                          </Label>
                                          <Input
                                            type="text"
                                            name="text"
                                            id={`a-${index}-btn-${btnIndex}-text`}
                                            placeholder="Text"
                                            value={btn.text}
                                            onChange={(event) => {
                                              setActions([
                                                ...actions.slice(0, index),
                                                {
                                                  ...actions[index],
                                                  buttons: [
                                                    ...actions[
                                                      index
                                                    ].buttons.slice(0, btnIndex),
                                                    {
                                                      ...actions[index].buttons[
                                                      btnIndex
                                                      ],
                                                      text: event.target.value,
                                                    },
                                                    ...actions[
                                                      index
                                                    ].buttons.slice(btnIndex + 1),
                                                  ],
                                                },
                                                ...actions.slice(index + 1),
                                              ]);
                                            }}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md={4}>
                                        <FormGroup>
                                          <Label
                                            className="font-weight-bolder mb-2"
                                            style={{ fontSize: "16px" }}
                                          >
                                            Width Percentage:
                                          </Label>
                                          <Input
                                            type="number"
                                            min={0}
                                            step={0.01}
                                            name="widthPercentage"
                                            id={`a-${index}-btn-${btnIndex}-widthPercentage`}
                                            placeholder="Width Percentage"
                                            value={btn.widthPercentage}
                                            onChange={(event) => {
                                              setActions([
                                                ...actions.slice(0, index),
                                                {
                                                  ...actions[index],
                                                  buttons: [
                                                    ...actions[
                                                      index
                                                    ].buttons.slice(0, btnIndex),
                                                    {
                                                      ...actions[index].buttons[
                                                      btnIndex
                                                      ],
                                                      widthPercentage:
                                                        event.target.value,
                                                    },
                                                    ...actions[
                                                      index
                                                    ].buttons.slice(btnIndex + 1),
                                                  ],
                                                },
                                                ...actions.slice(index + 1),
                                              ]);
                                            }}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md={4}>
                                        <FormGroup>
                                          <Label
                                            className="font-weight-bolder mb-2"
                                            style={{ fontSize: "16px" }}
                                          >
                                            Aspect Ratio:
                                          </Label>
                                          <Input
                                            type="number"
                                            step={0.01}
                                            name="aspectRatio"
                                            id={`a-${index}-btn-${btnIndex}-aspectRatio`}
                                            placeholder="Aspect Ratio"
                                            value={btn.aspectRatio}
                                            onChange={(event) => {
                                              setActions([
                                                ...actions.slice(0, index),
                                                {
                                                  ...actions[index],
                                                  buttons: [
                                                    ...actions[
                                                      index
                                                    ].buttons.slice(0, btnIndex),
                                                    {
                                                      ...actions[index].buttons[
                                                      btnIndex
                                                      ],
                                                      aspectRatio:
                                                        event.target.value,
                                                    },
                                                    ...actions[
                                                      index
                                                    ].buttons.slice(btnIndex + 1),
                                                  ],
                                                },
                                                ...actions.slice(index + 1),
                                              ]);
                                            }}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md={3}>
                                        <FormGroup>
                                          <Label
                                            className="font-weight-bolder mb-2"
                                            style={{ fontSize: "16px" }}
                                          >
                                            Background Color:{" "}
                                            <div
                                              style={{
                                                backgroundColor:
                                                  btn.backgroundColor,
                                                width: "16px",
                                                height: "16px",
                                                display: "inline-block",
                                                verticalAlign: "middle",
                                                border: "1px solid #000",
                                                borderRadius: "30%",
                                              }}
                                            ></div>
                                          </Label>
                                          <Input
                                            type="backgroundColor"
                                            name="backgroundColor"
                                            id={`a-${index}-btn-${btnIndex}-backgroundColor`}
                                            placeholder="Background Color"
                                            value={btn.backgroundColor}
                                            onChange={(event) => {
                                              setActions([
                                                ...actions.slice(0, index),
                                                {
                                                  ...actions[index],
                                                  buttons: [
                                                    ...actions[
                                                      index
                                                    ].buttons.slice(0, btnIndex),
                                                    {
                                                      ...actions[index].buttons[
                                                      btnIndex
                                                      ],
                                                      backgroundColor:
                                                        event.target.value,
                                                    },
                                                    ...actions[
                                                      index
                                                    ].buttons.slice(btnIndex + 1),
                                                  ],
                                                },
                                                ...actions.slice(index + 1),
                                              ]);
                                            }}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md={3}>
                                        <FormGroup>
                                          <Label
                                            className="font-weight-bolder mb-2"
                                            style={{ fontSize: "16px" }}
                                          >
                                            Dark Theme Background Color:{" "}
                                            <div
                                              style={{
                                                backgroundColor:
                                                  btn.darkThemeBackgroundColor,
                                                width: "16px",
                                                height: "16px",
                                                display: "inline-block",
                                                verticalAlign: "middle",
                                                border: "1px solid #000",
                                                borderRadius: "30%",
                                              }}
                                            ></div>
                                          </Label>
                                          <Input
                                            type="darkThemeBackgroundColor"
                                            name="darkThemeBackgroundColor"
                                            id={`a-${index}-btn-${btnIndex}-darkThemeBackgroundColor`}
                                            placeholder="Background Color"
                                            value={btn.darkThemeBackgroundColor}
                                            onChange={(event) => {
                                              setActions([
                                                ...actions.slice(0, index),
                                                {
                                                  ...actions[index],
                                                  buttons: [
                                                    ...actions[
                                                      index
                                                    ].buttons.slice(0, btnIndex),
                                                    {
                                                      ...actions[index].buttons[
                                                      btnIndex
                                                      ],
                                                      darkThemeBackgroundColor:
                                                        event.target.value,
                                                    },
                                                    ...actions[
                                                      index
                                                    ].buttons.slice(btnIndex + 1),
                                                  ],
                                                },
                                                ...actions.slice(index + 1),
                                              ]);
                                            }}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md={3}>
                                        <FormGroup>
                                          <Label
                                            className="font-weight-bolder mb-2"
                                            style={{ fontSize: "16px" }}
                                          >
                                            Text Color:{" "}
                                            <div
                                              style={{
                                                backgroundColor: btn.textColor,
                                                width: "16px",
                                                height: "16px",
                                                display: "inline-block",
                                                verticalAlign: "middle",
                                                border: "1px solid #000",
                                                borderRadius: "30%",
                                              }}
                                            ></div>
                                          </Label>
                                          <Input
                                            type="textColor"
                                            name="textColor"
                                            id={`a-${index}-btn-${btnIndex}-textColor`}
                                            placeholder="Background Color"
                                            value={btn.textColor}
                                            onChange={(event) => {
                                              setActions([
                                                ...actions.slice(0, index),
                                                {
                                                  ...actions[index],
                                                  buttons: [
                                                    ...actions[
                                                      index
                                                    ].buttons.slice(0, btnIndex),
                                                    {
                                                      ...actions[index].buttons[
                                                      btnIndex
                                                      ],
                                                      textColor:
                                                        event.target.value,
                                                    },
                                                    ...actions[
                                                      index
                                                    ].buttons.slice(btnIndex + 1),
                                                  ],
                                                },
                                                ...actions.slice(index + 1),
                                              ]);
                                            }}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md={3}>
                                        <FormGroup>
                                          <Label
                                            className="font-weight-bolder mb-2"
                                            style={{ fontSize: "16px" }}
                                          >
                                            Dark Theme Text Color:{" "}
                                            <div
                                              style={{
                                                backgroundColor:
                                                  btn.darkThemeTextColor,
                                                width: "16px",
                                                height: "16px",
                                                display: "inline-block",
                                                verticalAlign: "middle",
                                                border: "1px solid #000",
                                                borderRadius: "30%",
                                              }}
                                            ></div>
                                          </Label>
                                          <Input
                                            type="darkThemeTextColor"
                                            name="darkThemeTextColor"
                                            id={`a-${index}-btn-${btnIndex}-darkThemeTextColor`}
                                            placeholder="Background Color"
                                            value={btn.darkThemeTextColor}
                                            onChange={(event) => {
                                              setActions([
                                                ...actions.slice(0, index),
                                                {
                                                  ...actions[index],
                                                  buttons: [
                                                    ...actions[
                                                      index
                                                    ].buttons.slice(0, btnIndex),
                                                    {
                                                      ...actions[index].buttons[
                                                      btnIndex
                                                      ],
                                                      darkThemeTextColor:
                                                        event.target.value,
                                                    },
                                                    ...actions[
                                                      index
                                                    ].buttons.slice(btnIndex + 1),
                                                  ],
                                                },
                                                ...actions.slice(index + 1),
                                              ]);
                                            }}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md={12} xs={12}>
                                        <FormGroup>
                                          <Label
                                            className="font-weight-bolder mb-2"
                                            style={{ fontSize: "16px" }}
                                          >
                                            Deeplink{" "}
                                          </Label>
                                          <Input
                                            type="textarea"
                                            name="deeplink"
                                            id={`a-${index}-btn-${btnIndex}-deeplink`}
                                            style={{ height: "6rem" }}
                                            placeholder="Enter Deeplink"
                                            value={btn.deeplink}
                                            onChange={(event) => {
                                              setActions([
                                                ...actions.slice(0, index),
                                                {
                                                  ...actions[index],
                                                  buttons: [
                                                    ...actions[
                                                      index
                                                    ].buttons.slice(0, btnIndex),
                                                    {
                                                      ...actions[index].buttons[
                                                      btnIndex
                                                      ],
                                                      deeplink:
                                                        event.target.value,
                                                    },
                                                    ...actions[
                                                      index
                                                    ].buttons.slice(btnIndex + 1),
                                                  ],
                                                },
                                                ...actions.slice(index + 1),
                                              ]);
                                            }}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md={12} xs={12}>
                                        <FormGroup className="mb-0">
                                          <Label
                                            className="font-weight-bolder mb-2"
                                            style={{ fontSize: "16px" }}
                                          >
                                            Trackers
                                          </Label>
                                        </FormGroup>
                                        {btn.trackers?.map((tracker, trackerIndex) => (
                                          <Row key={`${index}-${btnIndex}-${trackerIndex}`} form>
                                            <Col>
                                              <Row form>
                                                <Col md={12} xs={12}>
                                                  <FormGroup>
                                                    <Label
                                                      className="font-weight-bolder mb-2"
                                                      style={{ fontSize: "14px" }}
                                                    >
                                                      Tracker Type
                                                    </Label>
                                                    <Select
                                                      id="type"
                                                      className="react-select-container"
                                                      classNamePrefix="react-select"
                                                      name="type"
                                                      placeholder="Select Tracker Type"
                                                      value={tracker.type}
                                                      onChange={(event) => {
                                                        setActions([
                                                          ...actions.slice(0, index),
                                                          {
                                                            ...actions[index],
                                                            buttons: [
                                                              ...actions[index].buttons.slice(0, btnIndex),
                                                              {
                                                                ...actions[index].buttons[btnIndex],
                                                                trackers: [
                                                                  ...actions[index].buttons[btnIndex].trackers.slice(0, trackerIndex),
                                                                  {
                                                                    ...actions[index].buttons[btnIndex].trackers[trackerIndex],
                                                                    type: event,
                                                                  },
                                                                  ...actions[index].buttons[btnIndex].trackers.slice(trackerIndex + 1),
                                                                ],
                                                              },
                                                              ...actions[index].buttons.slice(btnIndex + 1),
                                                            ],
                                                          },
                                                          ...actions.slice(index + 1),
                                                        ]);
                                                      }}
                                                      options={trackerTypeOptions}
                                                    />
                                                  </FormGroup>
                                                </Col>
                                                <Col md={12} xs={12}>
                                                  <FormGroup>
                                                    <Label
                                                      className="font-weight-bolder mb-2"
                                                      style={{ fontSize: "14px" }}
                                                    >
                                                      Tracker URL
                                                    </Label>
                                                    <Input
                                                      type="text"
                                                      name="url"
                                                      placeholder="Tracker URL"
                                                      value={tracker.url}
                                                      onChange={(event) => {
                                                        setActions([
                                                          ...actions.slice(0, index),
                                                          {
                                                            ...actions[index],
                                                            buttons: [
                                                              ...actions[index].buttons.slice(0, btnIndex),
                                                              {
                                                                ...actions[index].buttons[btnIndex],
                                                                trackers: [
                                                                  ...actions[index].buttons[btnIndex].trackers.slice(0, trackerIndex),
                                                                  {
                                                                    ...actions[index].buttons[btnIndex].trackers[trackerIndex],
                                                                    url: event.target.value,
                                                                  },
                                                                  ...actions[index].buttons[btnIndex].trackers.slice(trackerIndex + 1),
                                                                ],
                                                              },
                                                              ...actions[index].buttons.slice(btnIndex + 1),
                                                            ],
                                                          },
                                                          ...actions.slice(index + 1),
                                                        ]);
                                                      }}
                                                    />
                                                  </FormGroup>
                                                </Col>
                                              </Row>
                                            </Col>
                                            <Col md={1} className="mt-4">
                                              {trackerIndex === 0 ? (
                                                <Button onClick={() => {
                                                  setActions([
                                                    ...actions.slice(0, index),
                                                    {
                                                      ...actions[index],
                                                      buttons: [
                                                        ...actions[index].buttons.slice(0, btnIndex),
                                                        {
                                                          ...actions[index].buttons[btnIndex],
                                                          trackers: [
                                                            ...actions[index].buttons[btnIndex].trackers,
                                                            {
                                                              type: null,
                                                              url: ""
                                                            },
                                                          ],
                                                        },
                                                        ...actions[index].buttons.slice(btnIndex + 1),
                                                      ],
                                                    },
                                                    ...actions.slice(index + 1),
                                                  ]);
                                                }} color="transparent">
                                                  <FaPlus />
                                                </Button>
                                              ) : (
                                                <Button onClick={(e) => {
                                                  e.preventDefault();
                                                  setActions([
                                                    ...actions.slice(0, index),
                                                    {
                                                      ...actions[index],
                                                      buttons: [
                                                        ...actions[index].buttons.slice(0, btnIndex),
                                                        {
                                                          ...actions[index].buttons[btnIndex],
                                                          trackers: btn.trackers?.filter((_, i) => i !== trackerIndex),
                                                        },
                                                        ...actions[index].buttons.slice(btnIndex + 1),
                                                      ],
                                                    },
                                                    ...actions.slice(index + 1),
                                                  ]);
                                                }} color="transparent">
                                                  <FaMinus />
                                                </Button>
                                              )}
                                            </Col>
                                            <Col lg="12">
                                              {trackerIndex !== (btn.trackers?.length - 1) && <hr />}
                                            </Col>
                                          </Row>
                                        ))}
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              );
                            })}
                          </Collapse>
                        </Col>
                        <hr
                          style={{
                            width: "75rem",
                            color: "black",
                          }}
                        />
                      </Row>
                    );
                  })}

                </CardBody>
              </Card>
              <Row className="mb-3">
                <Col md={12} xs={12}>
                  <div className="p-0">
                    <Button
                      color="success"
                      className="ml-auto d-flex btn-success"
                      onClick={() =>
                        setActions(
                          actions.concat({
                            actionType: "",
                            categoryID: "",
                            screenName: "",
                            searchString: "",
                            url: "",
                            backgroundColor: "",
                            darkThemeBackgroundColor: "",
                            textColor: "",
                            darkThemeTextColor: "",
                            text: "",
                            bannerImageURL: "",
                            bannerImage: "",
                            darkThemeBannerImageURL: "",
                            lightThemeBannerImage: "",
                            trackers: trackersInitialState,
                            deeplink: "",
                            displayToggle: false,
                            internalRedirection: "",
                            dismissible: true,
                            bannerAspectRatio: 0,
                            lightThemeBannerAspectRatio: 0,
                            darkThemeBannerAspectRatio: 0,
                            leftIcon: {
                              trackers: trackersInitialState,
                              useExistingLeftIconForDarkTheme: false
                            },
                            rightIcon: {
                              trackers: trackersInitialState
                            },
                            rightIcon2: {
                              trackers: trackersInitialState
                            },
                            isDisplayAdvanceOptionEnabled: false,
                            useExistingBannerImage: false

                          })
                        )
                      }
                    >
                      <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                        Add Actions
                      </p>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          {showEditKeyboardPromptsButton() && (
            <Button className="d-block ml-3 mt-2" color="primary">
              Submit
            </Button>
          )}
        </Form>
      )}
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={action}
        onConfirmationTrue={() => {
          setConfirmationModalIsOpen(false);
          if (action === "Submit") handleSubmit();
        }}
        onHide={() => setConfirmationModalIsOpen(false)}
      />

      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={"Success"}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={"Error"}
        modaltext={failureModalText}
      />
    </Container>
  );
};

export default ManageKeyboardPrompts;

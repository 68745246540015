import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import { Button } from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi"
import { creativeTypeTableColumns } from "./tableColumn";
import ConfirmationModal from "../ConfirmationModal";
import ResponseModal from "../ResponseModal";
import {
  successModalHeading,
  errorModalHeading,
} from "../../../config/config";
import filterFactory from "react-bootstrap-table2-filter";
import Loading from "../../../components/Loading";
import paginationFactory from "react-bootstrap-table2-paginator";
import Search from "../../../components/Search";
import BootstrapTable from "react-bootstrap-table-next";
import {
  errorDescription,
  error,
  redirectOnTokenExpiry,
} from "../../../utilities/commonUtil";
import { createCreativeType, deleteCreativeType, fetchCreativeTypes, updateCreativeType } from "../../../utilities/apiUtils/configAPI";
import { creativeTypesAllActions, creativeTypesResponses, isConfigAPICreatePermission, isConfigAPIDeletePermission, isConfigAPIEditPermission } from "../../../config/configConfigAPIDashboard";
import CreativeTypeModal from "./modals/CreativeTypeModal";

const formDataInitialState = {
  id: "",
  name: ""
}

const ManageCreativeTypes = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState(formDataInitialState)
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [creativeTypes, setCreativeTypes] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [action, setAction] = useState("");
  const [id, setId] = useState(null);
  const [successModalText, setSuccessModalText] = useState("");
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [changeType, setChangeType] = useState("Add")
  const [isCreativeTypeModalVisible, setIsCreativeTypeModalVisible] = useState(false)

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    setLoading(true)
    fetchCreativeTypesTableData()
  };

  let showCreativeTypeModal = (type) => {
    setChangeType(type)
    setIsCreativeTypeModalVisible(true)
  }

  let hideCreativeTypeModal = () => {
    setIsCreativeTypeModalVisible(false)
    setFormData(formDataInitialState)
  }

  let onTableChange = (type, newState) => { };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  const handleClick = () => {
    setNameFilter("");
    sessionStorage.removeItem("creativeTypeFilterName")
  };

  const ClearButton = () => {
    return (
      <Button
        style={{ float: "right" }}
        className="form-control-lg"
        onClick={handleClick}
      >
        Reset Filters
      </Button>
    );
  };

  let setConfirmationModalState = (id, event) => {
    setAction(event);
    setConfirmationModalIsOpen(true);
    setId(id);
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        {isConfigAPIEditPermission() && (
          <Button
            className="mx-3"
            onClick={() => {
              showCreativeTypeModal("Edit")
              setFormData((prevState) => ({
                ...prevState,
                id: row.id,
                name: row.name
              }))
            }}
          >
            Edit
          </Button>
        )}

        {isConfigAPIDeletePermission() && (
          <Button
            className="mx-3 btn-danger"
            onClick={(event) =>
              setConfirmationModalState(row.id, event.target.innerHTML)
            }
          >
            Delete
          </Button>
        )}

        {!(isConfigAPIEditPermission() || isConfigAPIDeletePermission()) && (
          <center className="text-info" style={{ fontSize: 13 }}>You do not have permissions to perform any action.</center>
        )}
      </div>
    ),
  };

  let deleteRecord = async (id) => {
    try {
      let response = await deleteCreativeType(id);
      if (response.status === "success") {
        let text = "Creative type has been deleted";
        showSuccessModal(text);
      } else {
        let text
        if (response?.errorDescription)
          text = response?.errorDescription?.split("):")[1];
        else
          text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  };

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let fetchCreativeTypesTableData = async () => {
    try {
      let response = await fetchCreativeTypes();
      if (!response || response?.length === 0) {
        setCreativeTypes([])
        setLoading(false)
      } else if (response?.length > 0) {
        setCreativeTypes(response);
        setLoading(false);
      } else {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken ||
          response.errorCode === error.unauthorized
        )
          redirectOnTokenExpiry();
        let text = errorDescription(response.errorCode);
        setLoading(false);
        showErrorModal(text);
      }
    } catch (err) {
      setLoading(false);
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  };

  const filterCreativeTypes = () => {
    let items = creativeTypes;

    if (nameFilter)
      items = items.filter((item) =>
        item.name?.toLowerCase().includes(nameFilter.toLowerCase())
      );

    return items;
  };

  useEffect(() => {
    fetchCreativeTypesTableData();

    // Fill stored filter values
    if (sessionStorage.getItem("creativeTypeFilterName"))
      setNameFilter(sessionStorage.getItem("creativeTypeFilterName"))
  }, []);

  const handleSubmit = async () => {
    const { id, name } = formData
    const payload = {
      name
    }
    try {
      let response
      if (changeType === "Add")
        response = await createCreativeType(payload)
      else
        response = await updateCreativeType(id, payload)
      if (response?.status === "success") {
        setSuccessModalText(changeType === "Add" ? creativeTypesResponses.addedSuccessfully : creativeTypesResponses.updatedSuccessfully);
        setSuccessModalDisplay(true);
      } else {
        let text
        if (response?.errorDescription)
          text = response?.errorDescription?.split("):")[1];
        else
          text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      }
    } catch (err) {
      let text = errorDescription(error.unexpectedError);
      showErrorModal(text);
    } finally {
      hideCreativeTypeModal()
    }
  }

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <Container fluid className="p-0">
      <div className="cursor-pointer mb-2" onClick={handleGoBack}>
        <BiArrowBack size={24} />
      </div>
      <Row>
        <h1 className="mb-4 mt-2 w-50 pl-3">Creative Types</h1>

        {isConfigAPICreatePermission() && (
          <div className="d-flex align-items-center ml-auto">
            <Button color="success" className="d-flex btn-success mb-4 mr-3">
              <p className="mb-1 mt-1" style={{ fontSize: "1rem" }} onClick={() => showCreativeTypeModal("Add")}>
                Add New Creative Type
              </p>
            </Button>
          </div>
        )}
      </Row>
      <div>
        <Card style={{ overflow: "hidden", paddingBottom: "100px" }}>
          <div container-fullwidth="true" style={{ margin: "2%" }}>
            <Row className="justify-content-md-center mx-auto">
              <Col md={6} sm={12} className="mt-3">
                <Search
                  placeholder="Search Name"
                  value={nameFilter}
                  onChange={(e) => {
                    setNameFilter(e.target.value)
                    sessionStorage.setItem("creativeTypeFilterName", e.target.value)
                  }}
                ></Search>
              </Col>
              <Col md={6} xs={5} className="p-0 mt-3">
                <ClearButton>Reset Filters</ClearButton>
              </Col>
            </Row>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <CardBody>
              <BootstrapTable
                bootstrap4={true}
                wrapperClasses="table-responsive"
                hover={true}
                bordered={false}
                keyField="id"
                data={filterCreativeTypes()}
                columns={creativeTypeTableColumns}
                expandRow={expandRow}
                filter={filterFactory()}
                pagination={paginationFactory({
                  sizePerPage: 10,
                  sizePerPageList: [5, 10, 25, 50],
                })}
                defaultSorted={[{
                  dataField: 'id',
                  order: 'desc'
                }]}
                onTableChange={onTableChange}
                remote={{ filter: true }}
                noDataIndication="No results found"
              />
            </CardBody>
          )}
        </Card>
      </div>
      <CreativeTypeModal
        show={isCreativeTypeModalVisible}
        type={changeType}
        formData={formData}
        setFormData={setFormData}
        onSubmit={handleSubmit}
        onHide={hideCreativeTypeModal}
      />
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={action}
        onConfirmationTrue={() => {
          setConfirmationModalIsOpen(false);
          if (action === creativeTypesAllActions.delete) deleteRecord(id);
        }}
        onHide={() => setConfirmationModalIsOpen(false)}
      />
      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={successModalHeading}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={errorModalHeading}
        modaltext={failureModalText}
      />
    </Container>
  );
};

export default ManageCreativeTypes;
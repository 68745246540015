import React, { useMemo, useState, useEffect } from "react";
import { Container, Col, Row, Card, Button, CardBody, Badge } from "reactstrap";
import {
  getStatus,
  error,
  errorDescription,
  getStatusColor,
  redirectOnTokenExpiry,
  permissions,
} from "../../../utilities/commonUtil";

import { Link } from "react-router-dom";
import {
  fetchStickerPacks,
  fetchGifPacks,
  fetchOfferZones,
} from "../../../utilities/apiUtils";
import { getContentUpdatePromptDetails } from "../../../utilities/apiUtils/contentPrompts";
import Loading from "../../../components/Loading";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import ResponseModal from "../ResponseModal";
import { getKeyboardTheme } from "../../../utilities/apiUtils/themes";
import ContentPromptRulesets from "./ContentPromptRulesets";
const ContentPromptDetails = (props) => {
  const [loading, setLoading] = useState(true);
  const [contentPromptDetail, setContentPromptDetail] = useState({});
  const [successModal, setSuccessModal] = useState({
    display: false,
    text: "",
  });
  const [failureModal, setFailureModal] = useState({
    display: false,
    text: "",
  });
  const [resourceName, setResourceName] = useState("");
  const allowedPermissions = retrieveFromLocalStorage("userPermissions");

  useMemo(() => {
    let fetchPromptDetails = async (promptID) => {
      try {
        let response = await getContentUpdatePromptDetails(promptID);
        if (response.prompt) {
          setContentPromptDetail(response.prompt);
          if (
            !(
              response.prompt.type === "stickerPack" ||
              response.prompt.type === "gifPack" ||
              response.prompt.type === "keyboardTheme" ||
              response.prompt.type === "offersZone"
            )
          ) {
            setLoading(false);
          }
        } else {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken
          )
            redirectOnTokenExpiry();
          setLoading(false);
          setFailureModal({
            display: true,
            text: errorDescription(error.unexpectedError),
          });
        }
      } catch (err) {
        setFailureModal({
          display: true,
          text: errorDescription(error.unexpectedError),
        });
      }
    };
    fetchPromptDetails(props.match.params.id);
  }, [props.match.params.id]);

  useEffect(() => {
    getPromptDetails(contentPromptDetail.type);
  }, [contentPromptDetail.type]);

  let getPromptDetails = (resourceType) => {
    switch (resourceType) {
      case "stickerPack":
        getStickerPack();
        break;
      case "gifPack":
        getGifPacks();
        break;
      case "keyboardTheme":
        if (contentPromptDetail.additionalDetails && contentPromptDetail.additionalDetails.themePackId!==undefined)
          fetchKeyboardTheme(contentPromptDetail.additionalDetails.themePackId);
        setLoading(false);
        break;
      case "offersZone":
        getOfferZones();
        break;
      default:
    }
  };

  let getStickerPack = async () => {
    try {
      let response = await fetchStickerPacks();
      if (response.stickerPacks) {
        for (let i = 0; i < response.stickerPacks.length; i++) {
          let stickerPacks = response.stickerPacks;
          if (
            stickerPacks[i].id ===
            contentPromptDetail.additionalDetails.stickerPackId
          ) {
            setResourceName(stickerPacks[i].name);
          }
        }
        setLoading(false);
      } else {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectOnTokenExpiry();
        setLoading(false);
        setFailureModal({
          display: true,
          text: errorDescription(error.unexpectedError),
        });
      }
    } catch (err) {
      setLoading(false);
      setFailureModal({
        display: true,
        text: errorDescription(error.unexpectedError),
      });
    }
  };

  let getGifPacks = async () => {
    try {
      let response = await fetchGifPacks();
      if (response.gifPacks) {
        for (let i = 0; i < response.gifPacks.length; i++) {
          let gifPacks = response.gifPacks;
          if (
            gifPacks[i].id === contentPromptDetail.additionalDetails.gifPackId
          ) {
            setResourceName(gifPacks[i].name);
          }
        }
        setLoading(false);
      } else {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectOnTokenExpiry();
        setLoading(false);
        setFailureModal({
          display: true,
          text: errorDescription(error.unexpectedError),
        });
      }
    } catch (err) {
      setLoading(false);
      setFailureModal({
        display: true,
        text: errorDescription(error.unexpectedError),
      });
    }
  };

  let fetchKeyboardTheme = async (id) => {
    try {
      let response = await getKeyboardTheme(id);
      if (response.theme) {
        setResourceName(response.theme.name);
        setLoading(false);
      } else {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectOnTokenExpiry();
        setLoading(false);
        setFailureModal({
          display: true,
          text: errorDescription(error.unexpectedError),
        });
      }
    } catch (err) {
      setLoading(false);
      setFailureModal({
        display: true,
        text: errorDescription(error.unexpectedError),
      });
    }
  };
  let getOfferZones = async () => {
    try {
      let response = await fetchOfferZones();
      if (response.offers) {
        for (let i = 0; i < response.offers.length; i++) {
          let offers = response.offers;
          if (offers[i].id === contentPromptDetail.additionalDetails.offerId) {
            setResourceName(offers[i].name);
          }
        }
        setLoading(false);
      } else {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectOnTokenExpiry();
        setLoading(false);
        setFailureModal({
          display: true,
          text: errorDescription(error.unexpectedError),
        });
      }
    } catch (err) {
      setLoading(false);
      setFailureModal({
        display: true,
        text: errorDescription(error.unexpectedError),
      });
    }
  };
  let showRules = () => {
    return allowedPermissions.includes(permissions.contentUpdatePromptRuleView);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Container>
          <div className="d-flex" style={{ maxWidth: "max-content" }}>
            <h1>{resourceName}</h1>
            <Badge
              className="ml-3 mt-1 pt-2 mb-3"
              style={{ display: "table" }}
              color={getStatusColor(
                getStatus(
                  contentPromptDetail.publishedAt,
                  contentPromptDetail.deactivatedAt
                )
              )}
            >
              {getStatus(
                contentPromptDetail.publishedAt,
                contentPromptDetail.deactivatedAt
              )}
            </Badge>
          </div>
          <>
            <Card className="mt-3 mb-5">
              <CardBody>
                <Row>
                  <Col md={6} xl={6}>
                    <Row>
                      <Col md={6} xl={6}>
                        <span>
                          <strong>ID:</strong>
                        </span>
                      </Col>
                      <Col md={5} xl={5}>
                        <span>{contentPromptDetail.id}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} xl={6}>
                        <span>
                          <strong>Type:</strong>
                        </span>
                      </Col>
                      <Col md={5} xl={5}>
                        <span>{contentPromptDetail.type}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} xl={6}>
                        <span>
                          <strong>Priority:</strong>
                        </span>
                      </Col>
                      <Col md={5} xl={5}>
                        <span>{contentPromptDetail.priority}</span>
                      </Col>
                    </Row>
                    {contentPromptDetail.type === "stickerPack" ? (
                      <Row>
                        <Col md={6} xl={6}>
                          <span>
                            <strong>Sticker Pack ID:</strong>
                          </span>
                        </Col>
                        <Col md={5} xl={5}>
                          <span>
                            {
                              contentPromptDetail.additionalDetails
                                .stickerPackId
                            }
                          </span>
                        </Col>
                      </Row>
                    ) : null}
                    {contentPromptDetail.type === "gifPack" ? (
                      <Row>
                        <Col md={6} xl={6}>
                          <span>
                            <strong>Gif Pack ID:</strong>
                          </span>
                        </Col>
                        <Col md={5} xl={5}>
                          <span>
                            {contentPromptDetail.additionalDetails.gifPackId}
                          </span>
                        </Col>
                      </Row>
                    ) : null}
                    {contentPromptDetail.type === "keyboardTheme" ? (
                      <Row>
                        <Col md={6} xl={6}>
                          <span>
                            <strong>Keyboard Theme ID:</strong>
                          </span>
                        </Col>
                        <Col md={5} xl={5}>
                          <span>
                            {contentPromptDetail.additionalDetails.themePackId}
                          </span>
                        </Col>
                      </Row>
                    ) : null}{" "}
                    {contentPromptDetail.type === "offersZone" ? (
                      <Row>
                        <Col md={6} xl={6}>
                          <span>
                            <strong>Offer Zone Id:</strong>
                          </span>
                        </Col>
                        <Col md={5} xl={5}>
                          <span>
                            {contentPromptDetail.additionalDetails.offerId}
                          </span>
                        </Col>
                      </Row>
                    ) : null}
                    <Row>
                      <Col md={6} xl={6}>
                        <span>
                          <strong>Min App Version:</strong>
                        </span>
                      </Col>
                      <Col md={5} xl={5}>
                        <span>{contentPromptDetail.minAppVersion}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} xl={6}>
                        <span>
                          <strong>Text:</strong>
                        </span>
                      </Col>
                      <Col md={5} xl={5}>
                        <span>
                          {contentPromptDetail.additionalDetails === undefined
                            ? ""
                            : contentPromptDetail.additionalDetails.text.en
                            ? contentPromptDetail.additionalDetails.text.en
                            : null}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6} xl={6}>
                    <Row>
                      <Col md={4} xl={4}>
                        <span>
                          <strong>Created At:</strong>
                        </span>
                        <br />
                        <span>
                          <strong>Updated At:</strong>
                        </span>
                        <br />
                        <span>
                          <strong>Published At:</strong>
                        </span>
                        <br />
                        <span>
                          <strong>Deactivated At:</strong>
                        </span>
                        <br />
                      </Col>
                      <Col md={5} xl={5}>
                        <span>
                          {contentPromptDetail.createdAt !== null
                            ? contentPromptDetail.createdAt
                            : "NULL"}
                        </span>
                        <br />
                        <span>
                          {contentPromptDetail.updatedAt !== null
                            ? contentPromptDetail.updatedAt
                            : "NULL"}
                        </span>
                        <br />
                        <span>
                          {contentPromptDetail.publishedAt !== null
                            ? contentPromptDetail.publishedAt
                            : "NULL"}
                        </span>
                        <br />
                        <span>
                          {contentPromptDetail.deactivatedAt !== null
                            ? contentPromptDetail.deactivatedAt
                            : "NULL"}
                        </span>
                        <br />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <div className="d-block mx-auto mt-3">
                    <Link
                      className="text-decoration-none"
                      to={{
                        pathname: `/cp/content-prompts/${contentPromptDetail.id}/edit`,
                        state: {
                          formType: "edit",
                          contentUpdatePromptId: contentPromptDetail.id,
                          contentPromptDetail: contentPromptDetail,
                        },
                      }}
                    >
                      <Button color="primary" className="btn-block">
                        Edit
                      </Button>
                    </Link>
                  </div>
                </Row>
              </CardBody>
            </Card>
            {showRules() ? (
              <Card>
                <CardBody>
                  <Row className="px-3">
                    <ContentPromptRulesets
                      contentUpdatePrompt={contentPromptDetail}
                    />
                  </Row>
                </CardBody>
              </Card>
            ) : null}
          </>
          <ResponseModal
            show={successModal.display}
            onHide={() => setSuccessModal({ ...successModal, display: false })}
            modalheading={"Success"}
            modaltext={successModal.text}
          />
          <ResponseModal
            show={failureModal.display}
            onHide={() => setFailureModal({ ...failureModal, display: false })}
            modalheading={"Error"}
            modaltext={failureModal.text}
          />
        </Container>
      )}
    </>
  );
};

export default ContentPromptDetails;

import { redirectOnTokenExpiry } from "./commonUtil";

export function secureFetch(url, options) {
  return new Promise((resolve, reject) => {
    fetch(url, options).then(response => {
      // success response
      if (response.ok) {
        resolve(response);
      } else {
        // handle errors
        switch (response.status) {
          case 401:
            redirectOnTokenExpiry();
            break;
          default:
            break;
        }

        reject(response);
      }
    })
      .catch(error => {
        reject(error);
      });
  });
}  
import React from "react";
import { Badge } from "reactstrap";

const tableColumns = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    headerAttrs: { width: 100 },
    searchable: true,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "description",
    text: "Description",
    sort: true,
    searchable: true,
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "client",
    text: "Client",
    sort: true,
    searchable: true,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "createdAt",
    text: "Created At",
    hidden: false,
    sort: false,
    headerAttrs: { width: 230 },
    formatter: (cell) => {
      if ({ cell }.cell === null) return "NULL";
      else return { cell }.cell;
    },
    searchable: false,
  }
];

export { tableColumns };

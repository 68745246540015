import React, { useState } from "react";
import ResponseModal from "../ResponseModal";
import { createPermission } from "../../../utilities/apiUtils/permissions";
import {
  validatePermissionForm,
  errorDescription,
  redirectTo,
  error,
  redirectOnTokenExpiry,
} from "../../../utilities/commonUtil";
import Routes from "../../../routes/index";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { permissionCreateSuccessText } from "../../../config/UserConfig";

const PermissionForm = () => {
  const [permissionState, setPermissionState] = useState({
    permissionName: "",
    permissionIdentifier: "",
    permissionDescription: "",
  });

  const [successModalDisplayState, setSuccessModalDisplayState] =
    useState(false);
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });

  const handlePermissionChange = (event) => {
    const { name, value } = event.target;
    setPermissionState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let permission = validatePermissionForm(permissionState);
    if (permission.isValid) {
      try {
        let response = await createPermission(permission.data);
        if (response.success) {
          setSuccessModalDisplayState(true);
        } else if (response.errorCode) {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken
          )
            redirectOnTokenExpiry();
          let text = errorDescription(response.errorCode);
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: text,
          });
        }
      } catch (err) {
        let text = errorDescription(error.unexpectedError);
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: text,
        });
      }
    } else {
      if (!permission.hasValidName) {
        showAlert("invalidName");
      }
      if (!permission.hasValidIdentifier) {
        showAlert("invalidIdentifier");
      }
      if (!permission.hasValidDescription) {
        showAlert("invalidDescription");
      }
    }
  };

  const successModalClose = () => {
    setSuccessModalDisplayState(false);

    redirectTo(Routes.permissions.children.viewPermissions.path);
  };

  const failureModalClose = () => {
    setFailureModalState((prev) => ({
      ...prev,
      failureModalDisplay: false,
    }));
  };

  const showAlert = (elementId) => {
    document.getElementById(elementId).classList.remove("d-none");
  };

  const hideAlert = (elementId) => {
    document.getElementById(elementId).classList.add("d-none");
  };

  return (
    <Card>
      <CardBody>
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Name
                  <small id="invalidName" className="text-danger d-none">
                    (Name cannot be empty)
                  </small>
                </Label>
                <Input
                  type="text"
                  name="permissionName"
                  value={permissionState.permissionName}
                  placeholder="Name"
                  onChange={(event) => handlePermissionChange(event)}
                  onFocus={() => hideAlert("invalidName")}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Identifier
                  <small id="invalidIdentifier" className="text-danger d-none">
                    (Identifier cannot be empty)
                  </small>
                </Label>
                <Input
                  type="text"
                  name="permissionIdentifier"
                  value={permissionState.permissionIdentifier}
                  placeholder="Identifier"
                  onChange={(event) => handlePermissionChange(event)}
                  onFocus={() => hideAlert("invalidIdentifier")}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={12}>
              <FormGroup>
                <Label>
                  Description
                  <small id="invalidDescription" className="text-danger d-none">
                    (Description cannot be empty)
                  </small>
                </Label>
                <Input
                  type="textarea"
                  name="permissionDescription"
                  value={permissionState.permissionDescription}
                  placeholder="Description"
                  onChange={(event) => handlePermissionChange(event)}
                  onFocus={() => hideAlert("invalidDescription")}
                />
              </FormGroup>
            </Col>
          </Row>
          <Button color="primary">Submit</Button>
        </Form>
        <ResponseModal
          show={successModalDisplayState}
          onHide={successModalClose}
          modalheading={"Success"}
          modaltext={permissionCreateSuccessText}
        />
        <ResponseModal
          show={failureModalState.failureModalDisplay}
          onHide={failureModalClose}
          modalheading={"Error"}
          modaltext={failureModalState.failureModalText}
        />
      </CardBody>
    </Card>
  );
};

const CreatePermissionForm = () => (
  <Container fluid className="p-0">
    <h1 className="h3 mb-3">Create a new Permission</h1>
    <Row>
      <Col lg="12">
        <PermissionForm />
      </Col>
    </Row>
  </Container>
);

export default CreatePermissionForm;

import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import {
  validateMinAppVersionKeyboardLanguages,
  validateMinBobbleSDKVersionKeyboardLanguages,
} from "../../../utilities/commonUtil";
import {
  Button,
  Row,
  Form,
  Col,
  Label,
  FormGroup,
  Container,
  Input,
} from "reactstrap";
import Select from "react-select";
import { deviceOptions } from "../../../config/keyboardLanguages";
import {
  invalidAdditionalDetailsAlertText,
  invalidAdditionalDetailsElementId,
} from "../../../config/keyboardLanguages";
import { validateJSONString } from "../../../utilities/commonUtil";

const EditKeyboardLayout = (props) => {
  const [selectedFileName, setSelectedFileName] = useState("");
  const [resourceFile, setresourceFile] = useState(null);
  const [isIOSSelected, setIsIOSSelected] = useState(
    props.data.deviceType == "iOS"
  );
  const [device, setDevice] = useState({
    value: props.data.deviceType,
    label:
      props.data.deviceType[0].toUpperCase() +
      props.data.deviceType.substring(1),
  });
  // const [isXMLResourcePresent, setIsXMLResourcePresent] = useState([
  //   isXMLResourcePresent
  // ]);
  const [formTextState, setFormTextState] = useState({
    id: "",
    identifier: "",
    deviceType: "",
    minAppVersion: "",
    minBobbleSDKVersion: "",
    xmlResourceFile: "",
    additionalDetails: "",
    xmlResourceURL: "",
  });

  // This useEffect will be called for componentDidMount condition
  useEffect(() => {
    setFormTextState({
      id: props.data.id,
      identifier: props.data.identifier,
      deviceType: props.data.deviceType,
      minAppVersion: props.data.minAppVersion,
      minBobbleSDKVersion: props.data.minBobbleSDKVersion,
      xmlResourceFile: props.data.xmlResourceFile,
      additionalDetails: props.data.additionalDetails,
      xmlResourceURL: props.data.xmlResourceURL,
    });
    // setIsXMLResourcePresent(console.log(isXMLResourcePresent));
    console.log(props.data.xmlResourceURL);
  }, []);

  // This useEffect will be called for componentWillReceiveProps condition
  useEffect(() => {
    if (formTextState.id != props.data.id) {
      setFormTextState({
        id: props.data.id,
        identifier: props.data.identifier,
        deviceType: props.data.deviceType,
        minAppVersion: props.data.minAppVersion,
        minBobbleSDKVersion: props.data.minBobbleSDKVersion,
        xmlResourceFile: props.data.xmlResourceFile,
        additionalDetails: props.data.additionalDetails,
        xmlResourceURL: props.data.xmlResourceURL,
      });
      // setIsXMLResourcePresent(console.log(isXMLResourcePresent));
      console.log(props.data.xmlResourceURL);
    }
  }, [props.data]);
  const handleFormTextChange = (event) => {
    const { name, value } = event.target;
    hideAlert("invalidMinAppVersionAlert");
    setFormTextState((prev) => ({ ...prev, [name]: value }));
  };

  let handleDevice = (value) => {
    if (value.value && value.value === "iOS") {
      setIsIOSSelected(true);
    } else {
      setIsIOSSelected(false);
    }
    setDevice(value);
    setFormTextState({ ...formTextState, deviceType: value.value });
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    const file = event.target.files[0];
    setSelectedFileName(file ? file.name : "");
    setresourceFile(file ? file.name : "");
    setFormTextState((prev) => ({ ...prev, [name]: files[0] }));
  };

  const handleAdditionalDetails = (event) => {
    const { name, value } = event.target;
    setFormTextState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let isValid = validateMinAppVersionKeyboardLanguages(
      formTextState.minAppVersion
    );
    if (isValid !== true) {
      showAlert("invalidMinAppVersionAlert");
      return;
    }
    let isSDKValid = validateMinBobbleSDKVersionKeyboardLanguages(
      formTextState.minBobbleSDKVersion
    );

    if (isSDKValid !== true) {
      showAlert("invalidMinBobbleSDKVersionAlert");
      return;
    }
    if (!validateJSONString(formTextState.additionalDetails)) {
      showAlert(invalidAdditionalDetailsElementId);
      return;
    }
    let updatedKeyboardLayout = new FormData();
    updatedKeyboardLayout.append("id", formTextState.id);
    updatedKeyboardLayout.append("identifier", formTextState.identifier);
    updatedKeyboardLayout.append("minAppVersion", formTextState.minAppVersion);
    updatedKeyboardLayout.append(
      "minBobbleSDKVersion",
      formTextState.minBobbleSDKVersion
    );
    updatedKeyboardLayout.append("deviceType", formTextState.deviceType);
    updatedKeyboardLayout.append(
      "xmlResourceFile",
      formTextState.xmlResourceFile
    );
    updatedKeyboardLayout.append(
      "additionalDetails",
      formTextState.additionalDetails
    );
    props.edit(updatedKeyboardLayout, formTextState.id);
  };

  const showAlert = (elementId) => {
    document.getElementById(elementId).classList.remove("d-none");
  };

  const hideAlert = (elementId) => {
    document.getElementById(elementId).classList.add("d-none");
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Keyboard Layout
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Identifier <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="identifier"
                  defaultValue={props.data.identifier}
                  onChange={(event) => handleFormTextChange(event)}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Min App Version <span className="text-danger">*</span>
                  <small
                    id="invalidMinAppVersionAlert"
                    className="text-danger d-none"
                  >
                    (Enter a valid Min App Version)
                  </small>
                </Label>
                <Input
                  type="number"
                  name="minAppVersion"
                  defaultValue={props.data.minAppVersion}
                  onChange={(event) => handleFormTextChange(event)}
                  required
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>
                  Bobble SDK Version <span className="text-danger">*</span>
                  <small
                    id="invalidMinAppVersionAlert"
                    className="text-danger d-none"
                  >
                    (Enter a valid Min App Version)
                  </small>
                </Label>
                <Input
                  type="number"
                  name="minBobbleSDKVersion"
                  defaultValue={props.data.minBobbleSDKVersion}
                  onChange={(event) => handleFormTextChange(event)}
                  required
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <Label>
                Device Type <span className="text-danger">*</span>
              </Label>
              <Select
                className="react-select-container"
                id="statusSelect"
                classNamePrefix="react-select"
                name="deviceType"
                placeholder="Select Device"
                value={device}
                onChange={handleDevice}
                options={deviceOptions}
              />
            </Col>
            {isIOSSelected ? (
              <>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      <strong>
                        XML Resource
                        <small className="text-info">(Choose a file)</small>
                      </strong>
                    </Label>
                    <div className="mb-2">
                      {formTextState.xmlResourceURL !== null ? (
                        <a href={formTextState.xmlResourceURL}>
                          Uploaded XML Resource File
                        </a>
                      ) : null}
                    </div>

                    <Container className="custom-file">
                      <Input
                        type="file"
                        className="custom-file-input"
                        name="xmlResourceFile"
                        accept=".zip"
                        onChange={handleFileChange}
                      />
                      <Label className="custom-file-label" id="backgroundImg">
                        {resourceFile === null
                          ? "Choose a File"
                          : "File: " + selectedFileName}
                      </Label>
                    </Container>
                  </FormGroup>
                </Col>

                <Col md={12}>
                  <FormGroup>
                    <Label>Additional Details </Label>
                    <small
                      id={invalidAdditionalDetailsElementId}
                      className="text-danger d-none"
                    >
                      ({invalidAdditionalDetailsAlertText})
                    </small>
                    <Input
                      type="textarea"
                      name="additionalDetails"
                      onChange={handleAdditionalDetails}
                      style={{ height: "6rem" }}
                      placeholder="Additional Details"
                      value={formTextState.additionalDetails}
                      // required

                      // defaultValue={null}
                    />
                  </FormGroup>
                </Col>
              </>
            ) : null}
          </Row>
          <Button
            color="primary"
            type="submit"
            // onClick={props.onHide}
            style={{ marginTop: "5px" }}
          >
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditKeyboardLayout;

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button, Col, Form, FormGroup, Label, Row, Input } from "reactstrap";

const EditPermissionFormModal = (props) => {
  const [formState, setFormState] = useState({
    id: props.data.id,
    name: props.data.name,
    identifier: props.data.identifier,
    description: props.data.description,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let { name, description, id, identifier } = formState;
    let data = {
      id: id,
    };

    if (name !== "" && name !== undefined) {
      data["name"] = name;
    }
    if (identifier !== "" && identifier !== undefined) {
      data["identifier"] = identifier;
    }
    if (description !== "" && description !== undefined) {
      data["description"] = description;
    }
    props.update(data);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Permission
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label>Name</Label>
                <Input
                  type="text"
                  name="name"
                  placeholder="Enter Name"
                  onChange={(event) => handleChange(event)}
                  value={formState.name}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Identifier</Label>
                <Input
                  type="text"
                  name="identifier"
                  placeholder="Enter Identifier"
                  onChange={(event) => handleChange(event)}
                  value={formState.identifier}
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label>Description</Label>
                <Input
                  type="textarea"
                  name="description"
                  placeholder="Enter Description"
                  onChange={(event) => handleChange(event)}
                  value={formState.description}
                />
              </FormGroup>
            </Col>
          </Row>
          <Button color="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditPermissionFormModal;

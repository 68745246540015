import React, { useState } from "react";
import { retrieveFromLocalStorage } from "../../../../utilities/localStorageUtil";
import { Config } from "../../../../config";
import {
  redirectTo,
  redirectToNewTab,
  getStatus,
  getStatusColor,
  getBtnName,
  getButtonColor,
  permissions,
} from "../../../../utilities/commonUtil";
import {
  Row,
  Col,
  Badge,
  Card,
  Container,
  CardImg,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";

export const StyleCard = (props) => {
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );
  const [style] = useState(props.style);

  const showDetailsButton = () => {
    return allowedPermissions.includes(permissions.popTextStyleDetail);
  };

  const showButton = (btnName) => {
    if (btnName === Config.ACTIVATE_BUTTON)
      return allowedPermissions.includes(permissions.popTextStyleActivate);
    else if (btnName === Config.DEACTIVATE_BUTTON)
      return allowedPermissions.includes(permissions.popTextStyleDeactivate);
    else if (btnName === Config.PUBLISH_BUTTON)
      return allowedPermissions.includes(permissions.popTextStylePublish);
    else return false;
  };

  let status = getStatus(style.publishedAt, style.deactivatedAt);

  return (
    <Card>
      <React.Fragment>
        {style.previewImageURL ? (
          <CardImg src={style.previewImageURL} alt="Card image" />
        ) : (
          <div className="theme-preview-image bg-dark"></div>
        )}
      </React.Fragment>
      <CardBody>
        <small>ID : {style.id}</small>
        <CardTitle tag="h3" className="mb-0">
          {style.name}
          <Badge className="float-right" color={getStatusColor(status)}>
            {status}
          </Badge>
        </CardTitle>
        <Col className="mt-2">
          <Row>
            <small className="font-weight-bold">
              Created At : {style.createdAt}
            </small>
          </Row>
          <Row>
            <small className="font-weight-bold">
              Updated At : {style.updatedAt}
            </small>
          </Row>

          <Row>
            <small className="font-weight-bold">
              Published At : {style.publishedAt}
            </small>
          </Row>

          <Row>
            <small className="font-weight-bold">
              Deactivated At : {style.deactivatedAt}
            </small>
          </Row>
        </Col>
        <Container className=" my-4 d-flex justify-content-around" fluid>
          {showDetailsButton() ? (
            <Button
              className="mw-75 w-120 mx-2"
              color="info"
              onClick={() => {
                redirectToNewTab(`/pop-text-styles/${style.id}`);
              }}
            >
              Details
            </Button>
          ) : null}
          {showButton(getBtnName(status)) ? (
            <Button
              className="mw-75 w-120 mx-2"
              color={getButtonColor(status)}
              onClick={() => {
                props.getConfirmation(style.id, getBtnName(status));
              }}
            >
              {getBtnName(status)}
            </Button>
          ) : null}
        </Container>
      </CardBody>
    </Card>
  );
};

export default StyleCard;

import React, { useState, useEffect } from "react";
import moment from "moment";
import Reorder, { reorder } from "react-reorder";
import { Container, Row, Col, Form } from "react-bootstrap";
import { FormGroup, Label } from "reactstrap";
import Select from "react-select";
import Loading from "../../../components/Loading";
import { Card, CardImg } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { generateColor, replaceUnderscoreAndCapitalize, } from "../../../utilities/commonUtil";
import { fetchConflictAds, updateAdsPriority, resetAdsPriority, } from "../../../utilities/apiUtils/adsConflict";
import AlertModal from "./AlertModel";
import { cronIntervalMinutes, cronStartHours, cronStartMinute } from "../../../utilities/apiUtils/index";
import { fetchPlacements } from "../../../utilities/apiUtils/configAPI"
import { fetchSmartSuggestionCountries } from '../../../utilities/apiUtils/smartSuggestions';
import ConfirmationModal from "../ConfirmationModal";
import ResponseModal from "../ResponseModal";
import {
  successModalHeading,
  errorModalHeading,
} from "../../../config/config";

import {
  errorDescription,
  error
} from "../../../utilities/commonUtil";

const AdsConflictManagement = () => {
  const [loading, setLoading] = useState(true);
  const [adsData, setAdsData] = useState([]);
  const [adsDataAll, setAdsDataAll] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [placementOptions, setPlacementOptions] = useState([]);
  const [apkgOptions, setApkgOptions] = useState([]);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [apkg, setApkg] = useState("");
  const [placementId, setPlacementId] = useState("");
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [action, setAction] = useState("");

  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");


  let setSaveConfirmationModalState = (id, event) => {
    setAction(event);
    setPlacementId(id);
    setConfirmationModalIsOpen(true);
  };

  let setResetConfirmationModalState = (id, apkg, event) => {
    setAction(event);
    setPlacementId(id);
    setApkg(apkg)
    setConfirmationModalIsOpen(true);
  };

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    setLoading(true);
    fetchAdsData();
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    Promise.all([getCountries(), getPlacements(), fetchAdsData()]).finally(() => {
      setLoading(false);
    });
  }, []);

  const [filters, setFilters] = useState({
    country: { value: "global", label: "GLOBAL" },
    placementId: { value: "All", label: "All" },
    appPackageName: { value: "All", label: "All" },
  });

  const handleChange = (selectedOption, actionMeta) => {
    if (actionMeta.name === "country") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        country: selectedOption,
      }));
    } else if (actionMeta.name === "placementId") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        placementId: selectedOption,
      }));
    } else if (actionMeta.name === "appPackageName") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        appPackageName: selectedOption,
      }));
    }
  };

  const getCountries = async () => {
    try {
      let response = await fetchSmartSuggestionCountries()
      if (response?.length > 0) {
        const countries = []
        countries.push({
          value: "global",
          label: "GLOBAL",
          target: {
            name: "country",
            value: {
              value: "global",
              label: "GLOBAL"
            }
          }
        })
        response.forEach((country) => {
          countries.push({
            value: country.iso_code,
            label: country.name,
            target: {
              name: "country",
              value: {
                value: country.iso_code,
                label: country.name
              }
            }
          })
        })
        setCountryOptions(countries)
        setFilters((prevState) => ({
          ...prevState,
          country: countries.find(country => country.value === "IN").target.value
        }))
      }
    } catch (err) {
      console.log(err);
    }
  }

  const getPlacements = async () => {
    try {
      const response = await fetchPlacements();
      if (response?.length > 0) {
        const placementOptions = [{ value: "All", label: "All" }];
        response.forEach((data) => {
          if (data?.location !== "browser") {
            placementOptions.push({
              value: data.id,
              label: replaceUnderscoreAndCapitalize(data.description),
            });
          }
        });
        setPlacementOptions(placementOptions);
      }
    } catch (error) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  }


  const fetchAdsData = async () => {
    if (!isCurrentTimeInSpecificInterval()) {
      try {
        const response = await fetchConflictAds();
        if (response?.length > 0) {
          setAdsData(response);
          setAdsDataAll(response);

          const apkgOptions = [{ value: "All", label: "All" }];

          const apkgSet = new Set();

          response.forEach((data) => {
            Object.keys(data.conflictApkg).forEach((key) => {
              apkgSet.add(key);
            });

          });

          apkgSet.forEach((key) => {
            apkgOptions.push({
              value: key,
              label: key,
            });
          });
          setApkgOptions(apkgOptions);
        } else {
          setAdsData([]);
          setAdsDataAll([]);
        }
      } catch (error) {
        setFailureModalDisplay(true);
        setFailureModalText(errorDescription(error.unexpectedError));
      }
    } else {
      setAlertMessage("You can't perform this action at this time, Please try again later!");
      setShowAlert(true);
    }
  };

  const isCurrentTimeInSpecificInterval = () => {
    var now = new Date();
    var currentHour = now.getHours();
    var currentMinute = now.getMinutes();
    if (
      cronStartHours.includes(currentHour) &&
      currentMinute >= cronStartMinute &&
      currentMinute <= cronStartMinute + cronIntervalMinutes
    ) {
      return true;
    }
    return false;
  };

  const handleReset = async (placementId, apkgName) => {
    if (!isCurrentTimeInSpecificInterval()) {
      try {
        setLoading(true);
        const requestBody = {
          apkg: apkgName,
          plid: placementId,
        }
        const response = await resetAdsPriority(requestBody);
        if (response.status === "success") {
          let text = "Ads priority reset successfully";
          showSuccessModal(text);
        } else {
          let text
          if (response?.errorDescription)
            text = response?.errorDescription?.split("):")[1];
          else
            text = errorDescription(error.unexpectedError);
          showErrorModal(text);
        }
      } catch (error) {
        setFailureModalDisplay(true);
        setFailureModalText(errorDescription(error.unexpectedError));
      } finally {
        setLoading(false);
      }
    } else {
      setAlertMessage("You can't perform this action at this time, Please try again later!");
      setShowAlert(true);
    }
  };

  const handleSave = async (placementId) => {
    if (!isCurrentTimeInSpecificInterval()) {
      try {
        setLoading(true);
        const foundAds = adsData.find((data) => data.placementId === placementId);
        if (foundAds) {
          setAdsData([foundAds]);
          const response = await updateAdsPriority(foundAds);
          if (response.status === "success") {
            setLoading(false);
            let text = "Ads priority saved successfully";
            showSuccessModal(text);
          } else {
            let text
            if (response?.errorDescription)
              text = response?.errorDescription?.split("):")[1];
            else
              text = errorDescription(error.unexpectedError);
            showErrorModal(text);
          }
        }
      } catch (error) {
        setFailureModalDisplay(true);
        setFailureModalText(errorDescription(error.unexpectedError));
      } finally {
        setLoading(false);
      }
    } else {
      setAlertMessage("You can't perform this action at this time, Please try again later!");
      setShowAlert(true);
    }
  };

  const handleRedirect = () => {
    setShowAlert(false);
    window.location.href = window.location.origin;
  };

  const handleReorder = (placementId, apkg, newAdsArray) => {
    const updatedAdsData = adsData.map((data) => {
      if (data.placementId === placementId && data.conflictApkg[apkg]) {
        return {
          ...data,
          conflictApkg: {
            ...data.conflictApkg,
            [apkg]: newAdsArray.map((ad, index) => ({
              ...ad,
              priority: 9999 - index,
            })),
          },
        };
      }
      return data;
    });
    setAdsData(updatedAdsData);
  };

  const filterAndRenderAds = () => {
    return adsData
      .filter(
        (ads) =>
          (filters.placementId.value === "All" ||
            ads.placementId === filters.placementId.value) &&
          (filters.country.value === "global" ||
            filters.country.value === ads.countryCode)
      )
      .map((ads, index) => (
        <React.Fragment key={index}>
          {ads && renderAds(ads, filters.appPackageName.value, index)}
        </React.Fragment>
      ));
  };


  const renderAds = (ads, apkgFilter, index) => {
    if (
      apkgFilter === "All" ||
      Object.keys(ads.conflictApkg).includes(apkgFilter)
    ) {
      return (
        <>
          <h5 style={{ marginBottom: "5px", marginTop: "15px" }}>
            {replaceUnderscoreAndCapitalize(ads.desc)}
          </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <label style={{ color: "skyblue" }}>{ads.placementId}</label>
            <Button
              className="ml-2 m-2"
              color="primary"
              onClick={(event) => setSaveConfirmationModalState(ads.placementId, event.target.innerHTML)}
            >
              Save
            </Button>
          </div>
          <hr style={{ marginTop: 0 }} />
          <div style={{ backgroundColor: "white", padding: "10px" }}>
            <h4 style={{ fontWeight: "bold", marginRight: "auto" }}>Ads</h4>

            {Object.entries(ads.conflictApkg).map(([apkg, adsArray]) => {
              const hasPriorityZeroAds = adsDataAll[adsDataAll.findIndex((ad) => ad?.placementId === ads?.placementId)]?.conflictApkg[apkg]?.every(
                (ad) => ad.priority !== 0
              );
              return (
                (apkgFilter === "All" || apkgFilter === apkg) && (
                  <div key={`${ads.placementId}_${apkg}`}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "5px 10px",
                      }}
                    >
                      <h5>{apkg}</h5>
                      {hasPriorityZeroAds && (
                        <Button
                          className="ml-auto btn-danger"
                          color="danger"
                          style={{ backgroundColor: "red", outline: "none" }}
                          onClick={(event) => setResetConfirmationModalState(ads.placementId, apkg, event.target.innerHTML)}
                        >
                          Reset
                        </Button>
                      )}
                    </div>
                    <Row style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                      <Reorder
                        reorderId={`reorder-list-${ads.placementId}_${apkg}`}
                        onReorder={(event, previousIndex, nextIndex) => {
                          const newAdsArray = reorder(
                            adsArray,
                            previousIndex,
                            nextIndex
                          );
                          handleReorder(ads.placementId, apkg, newAdsArray);
                        }}
                      >
                        {adsArray.map((ad) => (
                          <Col
                            key={`${ads.placementId}_${apkg}_${ad.id}`}
                            className="reorderContainer mb-3 align-top h-auto mr-2"
                          >
                            <div className="reorderContainer mb-3 align-top h-auto mr-2">
                              <Card
                                className="m-3 responseCard"
                                style={{ cursor: "grab" }}
                              >
                                <CardImg src={ad.iconUrl} draggable={false} />
                              </Card>
                              <center
                                style={{
                                  fontSize: 12,
                                  fontWeight: 700,
                                  marginTop: -10,
                                  marginBottom: 4,
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {ad.id}
                              </center>
                              <center
                                style={{
                                  fontWeight: 600,
                                  marginTop: -6,
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {ad.title}
                              </center>
                              <center
                                style={{
                                  background: generateColor(ad.provider)
                                    .background,
                                  color: generateColor(ad.provider).color,
                                  fontSize: 11,
                                  fontWeight: 800,
                                  borderRadius: 4,
                                  width: "fit-content",
                                  margin: "auto",
                                  padding: "2px 10px",
                                }}
                              >
                                {replaceUnderscoreAndCapitalize(ad.provider)}
                              </center>
                              <center
                                style={{
                                  fontSize: 12,
                                  fontWeight: 700,
                                  lineHeight: 1.2,
                                  marginTop: 4,
                                  whiteSpace: "normal",
                                }}
                              >
                                <strong style={{ color: "#111" }}>Last sync on:</strong> <br />
                                {moment(ad.createdAt).format('DD MMM YYYY, h:mmA')}
                              </center>
                            </div>
                          </Col>
                        ))}
                      </Reorder>
                    </Row>
                    <hr style={{ marginTop: 0 }} />
                  </div>
                )
              );
            })}
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <Container fluid style={{ padding: 0 }}>
      <Row>
        <Col>
          <h1
            style={{
              marginBottom: "1rem",
              marginTop: "1rem",
              width: "50%",
            }}
          >
            Ads Conflict Dashboard
          </h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup style={{ width: "200px" }}>
            <Label
              style={{
                fontWeight: "bold",
                marginBottom: "0.5rem",
                fontSize: "14px",
              }}
            >
              Country
            </Label>
            <Select
              id="country"
              className="react-select-container"
              classNamePrefix="react-select"
              name="country"
              placeholder="Select Country"
              value={filters.country}
              onChange={handleChange}
              options={countryOptions}
            />
          </FormGroup>
          <hr style={{ marginTop: 0 }} />
          <Row>
            <Col>
              <Label>Placement ID</Label>
              <Select
                id="placementId"
                className="react-select-container"
                classNamePrefix="react-select"
                name="placementId"
                value={filters.placementId}
                onChange={handleChange}
                options={placementOptions}
              />
            </Col>
            <Col>
              <FormGroup controlId="appPackageName">
                <Label>App Package Name</Label>
                <Select
                  id="appPackageName"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="appPackageName"
                  value={filters.appPackageName}
                  onChange={handleChange}
                  options={apkgOptions}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4 style={{ marginTop: "1rem" }}>Instruction</h4>
          <ul>
            <li>Drag and Drop the ads icons to rearrange them</li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 style={{ marginTop: "2rem" }}>Placements</h3>
          {loading ? (
            <Loading />
          ) : (
            <React.Fragment>
              {filterAndRenderAds()}
            </React.Fragment>
          )}
        </Col>
      </Row>
      <AlertModal
        show={showAlert}
        onHide={handleRedirect}
        modaltext={alertMessage}
      />

      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={action}
        onConfirmationTrue={() => {
          setConfirmationModalIsOpen(false);
          if (action === "Save") {
            handleSave(placementId);
          } else if (action === "Reset") {
            handleReset(placementId, apkg);
          }
        }}
        onHide={() => setConfirmationModalIsOpen(false)}
      />

      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={successModalHeading}
        modaltext={successModalText}
      />

      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={errorModalHeading}
        modaltext={failureModalText}
      />


    </Container>
  );
};

export default AdsConflictManagement;
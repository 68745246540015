import { retrieveFromLocalStorage } from "../localStorageUtil";
import moment from "moment";
import { host } from "./index";
import { secureFetch } from "../secureFetch";

export let fetchDashboardLogs = async ({ limit, page, service, username, operations, start, end, search }) => {
  let startDate = moment.utc(start + " 00:00:00+05:30").format("YYYY-MM-DD HH:mm:ss")
  let endDate = moment.utc(end + " 23:59:59+05:30").format("YYYY-MM-DD HH:mm:ss")
  let url = `${host}/v4/internal/dashboardUsersLogs?limit=${limit}&page=${page}&service=${service}&username=${username}&operations=${operations}&start=${startDate}&end=${endDate}&search=${search}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchDashboardLogsOperations = async () => {
  let url = `${host}/v4/internal/dashboardUsersLogs/operations`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchDashboardSessionLogs = async ({ id, start, end, service }) => {
  let url = `${host}/v4/internal/dashboardUsersLogs/logs/${id}?startTime=${start}&endTime=${end}&service=${service}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};
import React, { useEffect, useState } from 'react'
import { Modal as ReactBootstrapModal } from "react-bootstrap";
import Select, { components } from "react-select";
import CreatableSelect from 'react-select/creatable';
import { FaPlus, FaMinus } from "react-icons/fa";
import { ColorPicker, Modal } from 'antd';
import { Row, Col, FormGroup, Label, Input, Form, Button, Container } from "reactstrap";
import ToolTip from "../../../utilities/ToolTip";
import { fileSizeExceedError, imageFormatError, invalidAspectRatioError, metadataOverrideAllActions, shareUrlPlaceholder, showStoryAdResetButton, showStoryAdUpdateButton, staticIconSize, storyAdCtaItemTypeOptions, storyAdError, storyCTASharePackageNameOptions } from '../../../config/configSmartSuggestions';
import ResponseModal from '../ResponseModal';
import ConfirmationModal from '../ConfirmationModal';
import { storyAdImageAssetAspectRatioRange, storyAdImageAssetSize, storyAdVideoAssetAspectRatioRange, storyAdVideoAssetSize } from '../../../utilities/apiUtils/index';
import { truncateDecimals } from '../../../utilities/commonUtil';

const dataInitialState = {
  videoAsset: null,
  videoAssetSrc: "",
  videoAssetFilename: "",
  videoAssetAspectRatio: null,
  imageAsset: null,
  imageAssetSrc: "",
  imageAssetFilename: "",
  imageAssetAspectRatio: null,
}

const ctaItemInitalState = {
  type: null,
  text: "",
  textColor: "#000000",
  backgroundColor: "#ffffff",
  icon: null,
  iconUrl: "",
  iconFilename: "",
  iconAspectRatio: null,
  clickUrl: "",
  deeplink: "",
  shareText: "",
  shareUrl: "",
  sharePackageName: null,
}

const ManageStoryAdsModal = ({ show = false, adDetails, onReset = async () => { }, onSubmit = () => { }, onHide = () => { }, onMetadataOverride = () => { } }) => {
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");
  const [action, setAction] = useState("");

  const [isPromoted, setIsPromoted] = useState(false)
  const [data, setData] = useState(dataInitialState)
  const [ctaItems, setCtaItems] = useState([{ ...ctaItemInitalState }])

  useEffect(() => {
    let storyAdDetails = adDetails?.storyAd
    if (storyAdDetails?.id) {
      setData((prevState) => ({
        ...prevState,
        videoAssetSrc: storyAdDetails?.vats,
        videoAssetFilename: storyAdDetails?.videoAssetFilename,
        videoAssetAspectRatio: storyAdDetails?.vatr,
        imageAssetSrc: storyAdDetails?.iats,
        imageAssetFilename: storyAdDetails?.imageAssetFilename,
        imageAssetAspectRatio: storyAdDetails?.iatr,
      }))

      setCtaItems(storyAdDetails?.cta?.length > 0 ? storyAdDetails?.cta?.map(ctaItem => ({
        type: ctaItem.type ? {
          label: storyAdCtaItemTypeOptions.find(option => option.value === ctaItem.type).label ?? ctaItem.type,
          value: ctaItem.type
        } : storyAdCtaItemTypeOptions[0].target.value,
        text: ctaItem.text,
        textColor: ctaItem.txtc,
        backgroundColor: ctaItem.bgcr,
        icon: null,
        iconUrl: ctaItem.isrc,
        iconFilename: ctaItem.icfn,
        iconAspectRatio: ctaItem.iasc,
        clickUrl: ctaItem.curl,
        deeplink: ctaItem.dplk,
        shareText: ctaItem.stxt,
        shareUrl: ctaItem.surl,
        sharePackageName: ctaItem.spkg ? {
          label: storyCTASharePackageNameOptions.find(option => option.value === ctaItem.spkg)?.label ?? ctaItem.spkg,
          value: ctaItem.spkg
        } : null,
      })) : [{ ...ctaItemInitalState }])

      setIsPromoted(adDetails?.isPromoted)
    }
  }, [adDetails])

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  const handleFileChange = (event, type) => {
    const { name, files, accept } = event.target;
    const acceptedFormat = accept.split(",").map(format => format.trim())
    event.persist();
    // For showing Image preview
    if (files[0]) {
      // Check for size
      if ((type === "image" && (files[0].size > storyAdImageAssetSize)) || (type === "video" && (files[0].size > storyAdVideoAssetSize))) {
        let text = fileSizeExceedError;
        showErrorModal(text);
        setData((prev) => ({
          ...prev,
          [name]: null,
          [name + "Src"]: "",
          [name + "AspectRatio"]: null,
          [name + "Filename"]: "",
        }));
        return
      }
      // Check if format is accepted
      if (!acceptedFormat.includes(files[0].type)) {
        let text = imageFormatError;
        showErrorModal(text);
        setData((prev) => ({
          ...prev,
          [name]: null,
          [name + "Src"]: "",
          [name + "AspectRatio"]: null,
          [name + "Filename"]: "",
        }));
        return
      }

      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        if (type === "image") {
          const image = new Image();
          image.src = reader.result;
          image.onload = () => {
            // Check if image is square when ad type IS NOT "product"
            let width = image.width
            let height = image.height
            // Check for aspect ratio
            if (storyAdImageAssetAspectRatioRange?.length === 2) {
              let aspectRatio = truncateDecimals(width / height, 3)
              if (aspectRatio < storyAdImageAssetAspectRatioRange[0] || aspectRatio > storyAdImageAssetAspectRatioRange[1]) {
                let text = invalidAspectRatioError;
                showErrorModal(text);
                setData((prev) => ({
                  ...prev,
                  [name]: null,
                  [name + "Src"]: "",
                  [name + "AspectRatio"]: null,
                  [name + "Filename"]: "",
                }));
                return
              }
            }

            setData((prev) => ({
              ...prev,
              [name]: files[0],
              [name + "Src"]: reader.result,
              [name + "AspectRatio"]: Number(width / height).toFixed(3),
              [name + "Filename"]: files[0]?.name,
            }));
          }
        } else if (type === "video") {
          const video = document.createElement('video');
          video.src = reader.result;
          video.onloadedmetadata = () => {
            let width = video.videoWidth;
            let height = video.videoHeight;
            // Check for aspect ratio
            if (storyAdVideoAssetAspectRatioRange?.length === 2) {
              let aspectRatio = truncateDecimals(width / height, 3)
              if (aspectRatio < storyAdVideoAssetAspectRatioRange[0] || aspectRatio > storyAdVideoAssetAspectRatioRange[1]) {
                let text = invalidAspectRatioError;
                showErrorModal(text);
                setData((prev) => ({
                  ...prev,
                  [name]: null,
                  [name + "Src"]: "",
                  [name + "AspectRatio"]: null,
                  [name + "Filename"]: "",
                }));
                return
              }
            }

            setData((prev) => ({
              ...prev,
              [name]: files[0],
              [name + "Src"]: reader.result,
              [name + "AspectRatio"]: Number(width / height).toFixed(3),
              [name + "Filename"]: files[0]?.name,
            }));
          };
        }
      };
    } else {
      setData((prev) => ({
        ...prev,
        [name]: null,
        [name + "Src"]: "",
        [name + "AspectRatio"]: null,
        [name + "Filename"]: "",
      }));
    }
  };

  const handleResetAsset = (name) => {
    switch (name) {
      case "video":
        setData((prevState) => ({
          ...prevState,
          videoAssetSrc: "",
          videoAssetFilename: "",
          videoAssetAspectRatio: null,
        }))
        break;
      case "image":
        setData((prevState) => ({
          ...prevState,
          imageAssetSrc: "",
          imageAssetFilename: "",
          imageAssetAspectRatio: null,
        }))
        break;
      default:
        break;
    }
  }

  const handleResetCtaItemFile = (index) => {
    const temp = [...ctaItems]
    temp[index]["iconUrl"] = ""
    temp[index]["iconFilename"] = ""
    temp[index]["iconAspectRatio"] = null
    setCtaItems(temp)
  }

  const handleCtaItemsChange = (event, index) => {
    const { name, value } = event.target
    const temp = [...ctaItems]
    temp[index][name] = value
    setCtaItems(temp)
  }

  const handleCtaItemFileChange = (event, type, index) => {
    const { name, files, accept } = event.target;
    const acceptedFormat = accept.split(",").map(format => format.trim())
    event.persist();
    // For showing Image preview
    if (files[0]) {
      // Check for size
      if (type === "image" && (files[0].size > staticIconSize)) {
        let text = fileSizeExceedError;
        showErrorModal(text);
        const temp = [...ctaItems]
        temp[index][name] = null
        temp[index][name + "Url"] = ""
        temp[index][name + "AspectRatio"] = null
        temp[index][name + "Filename"] = ""
        setCtaItems(temp)
        return
      }
      // Check if format is accepted
      if (!acceptedFormat.includes(files[0].type)) {
        let text = imageFormatError;
        showErrorModal(text);
        const temp = [...ctaItems]
        temp[index][name] = null
        temp[index][name + "Url"] = ""
        temp[index][name + "AspectRatio"] = null
        temp[index][name + "Filename"] = ""
        setCtaItems(temp)
        return
      }

      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          // Check if image is square when ad type IS NOT "product"
          let width = image.width
          let height = image.height
          console.log({ width, height });
          // Check for aspect ratio

          const temp = [...ctaItems]
          temp[index][name] = files[0]
          temp[index][name + "Url"] = reader.result
          temp[index][name + "AspectRatio"] = Number(image.width / image.height).toFixed(3)
          temp[index][name + "Filename"] = files[0]?.name
          setCtaItems(temp)
        }
      };
    } else {
      const temp = [...ctaItems]
      temp[index][name] = null
      temp[index][name + "Url"] = ""
      temp[index][name + "AspectRatio"] = null
      temp[index][name + "Filename"] = ""
      setCtaItems(temp)
    }
  };

  const handleCtaItemColorChange = (color, name, index) => {
    const temp = [...ctaItems]
    temp[index][name] = color
    setCtaItems(temp)
  }

  const addCtaItem = () => {
    setCtaItems((prevState) => (
      prevState.concat({ ...ctaItemInitalState })
    ));
  }

  const removeCtaItem = (index) => {
    setCtaItems((prevState) => (
      prevState.filter((_, i) => i !== index)
    ));
  }

  let setConfirmationModalState = (event) => {
    setAction(event);
    setConfirmationModalIsOpen(true);
  };

  const handleHide = () => {
    setData(dataInitialState)
    setCtaItems([{ ...ctaItemInitalState }])
    setIsPromoted(false)
    onHide()
  };

  const handleSubmit = (event) => {
    event.preventDefault()
    if (!data.videoAssetSrc && !data.imageAssetSrc) {
      let text = storyAdError;
      showErrorModal(text);
      return;
    }

    let videoAssetFilename = "", imageAssetFilename = "", videoAssetSrc = "", imageAssetSrc = ""
    if (data.videoAssetSrc) {
      videoAssetFilename = data.videoAssetSrc.startsWith("data:") ? "" : data.videoAssetFilename
      videoAssetSrc = data.videoAssetSrc.startsWith("data:") ? data.videoAssetSrc : ""
    }
    if (data.imageAssetSrc) {
      imageAssetFilename = data.imageAssetSrc.startsWith("data:") ? "" : data.imageAssetFilename
      imageAssetSrc = data.imageAssetSrc.startsWith("data:") ? data.imageAssetSrc : ""
    }

    let ctaItemsPayload = JSON.parse(JSON.stringify(ctaItems))
    ctaItemsPayload.forEach((ctaItem, index) => {
      ctaItem.type = ctaItem.type.value ?? ""
      ctaItem.sharePackageName = ctaItem.sharePackageName?.value ?? ""
      let iconFilename = "", iconUrl = ""
      if (ctaItem.iconUrl) {
        iconFilename = ctaItem.iconUrl.startsWith("data:") ? "" : ctaItem.iconFilename
        iconUrl = ctaItem.iconUrl.startsWith("data:") ? ctaItem.iconUrl : ""
      }
      ctaItem.iconUrl = iconUrl
      ctaItem.iconAspectRatio = Number(ctaItem.iconAspectRatio) || null
      ctaItem.iconFilename = iconFilename

      if (ctaItem.type === "click") {
        ctaItem.shareText = ""
        ctaItem.shareUrl = ""
        ctaItem.sharePackageName = ""
      } else if (ctaItem.type === "share") {
        ctaItem.clickUrl = ""
        ctaItem.deeplink = ""
      }
    })

    const payload = {
      id: adDetails?.storyAd?.id,
      adID: adDetails.id,

      vats: videoAssetSrc,
      vatr: Number(data.videoAssetAspectRatio) || null,
      iats: imageAssetSrc,
      iatr: Number(data.imageAssetAspectRatio) || null,

      videoAssetFilename,
      imageAssetFilename,

      cta: ctaItemsPayload?.map(el => ({
        type: el.type,
        text: el.text,
        txtc: el.textColor,
        bgcr: el.backgroundColor,
        icon: el.icon,
        isrc: el.iconUrl,
        icfn: el.iconFilename,
        iasc: el.iconAspectRatio,
        curl: el.clickUrl,
        dplk: el.deeplink,
        stxt: el.shareText,
        surl: el.shareUrl,
        spkg: el.sharePackageName,
      })),
      rawd: adDetails?.storyAd?.rawd,
    }
    onSubmit(payload)
    handleHide()
  }

  const handleMetadataOverride = () => {
    onMetadataOverride()
    handleHide()
  }

  const requiredComponent = (props) => (
    <div>
      <components.Input {...props} required={true} />
    </div>
  );

  const notRequiredComponent = (props) => (
    <div>
      <components.Input {...props} required={false} />
    </div>
  );

  return (
    <Modal
      open={show}
      onCancel={handleHide}
      width={1140}
      centered
      footer={null}
      className='antd-customized-modal'
    >
      <ReactBootstrapModal.Header className="bg-light">
        <ReactBootstrapModal.Title id="contained-modal-title-vcenter">
          Manage Story Ad {adDetails?.title ? (" - " + adDetails?.title) : ""}{adDetails?.id ? (" (" + adDetails?.id + ")") : ""}
        </ReactBootstrapModal.Title>
      </ReactBootstrapModal.Header>
      <Form onSubmit={handleSubmit}>
        <ReactBootstrapModal.Body>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label>
                  <strong>
                    Video Asset
                    <div className="text-info"><small>Format: video, Aspect ratio: {storyAdVideoAssetAspectRatioRange?.length === 2 ? JSON.stringify(storyAdVideoAssetAspectRatioRange) : "any"}, Size: {storyAdVideoAssetSize ? storyAdVideoAssetSize / 1024 + "KB" : "any"}</small></div>
                  </strong>
                </Label>
                <Container className="custom-file">
                  <Input
                    key={`videoAssetUpload-${adDetails?.title}-${adDetails?.id}`}
                    type="file"
                    className="custom-file-input"
                    accept="video/mp4,video/x-m4v,video/mov,video/wmv,video/mkv"
                    name="videoAsset"
                    onChange={(event) => {
                      handleFileChange(event, "video")
                    }}
                    disabled={!showStoryAdUpdateButton()}
                  />
                  <Label
                    className="custom-file-label"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}
                  >
                    <strong>{data.videoAssetFilename || "Choose a video asset"}</strong>
                  </Label>
                </Container>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>
                  <strong>
                    Image Asset <small className="text-info">(Also used as video asset fallback)</small>
                    <div className='text-info'><small>Formats: png, jpg, jpeg, webp, Aspect ratio: {storyAdImageAssetAspectRatioRange?.length === 2 ? JSON.stringify(storyAdImageAssetAspectRatioRange) : "any"}, Size: {storyAdImageAssetSize ? storyAdImageAssetSize / 1024 + "KB" : "any"}</small></div>
                  </strong>
                </Label>
                <Container className="custom-file">
                  <Input
                    key={`imageAssetUpload-${adDetails?.title}-${adDetails?.id}`}
                    type="file"
                    className="custom-file-input"
                    accept="image/png, image/jpg, image/jpeg, image/webp"
                    name="imageAsset"
                    onChange={(event) => {
                      handleFileChange(event, "image")
                    }}
                    disabled={!showStoryAdUpdateButton()}
                  />
                  <Label
                    className="custom-file-label"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}
                  >
                    <strong>{data.imageAssetFilename || "Choose an image asset"}</strong>
                  </Label>
                </Container>
              </FormGroup>
            </Col>

            <Col md={6}>
              {(data.videoAssetSrc) ? (
                <Col className="pb-4">
                  <video
                    key={`videoAssetPreview-${adDetails?.title}-${adDetails?.id}-${data.videoAssetFilename}`}
                    width="250"
                    autoPlay
                    loop
                    muted
                  >
                    <source src={data.videoAssetSrc} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  {showStoryAdUpdateButton() && (
                    <div className="text-danger font-weight-bolder cursor-pointer btn-link mt-2" onClick={() => handleResetAsset("video")}>
                      Reset
                    </div>
                  )}
                </Col>
              ) : (
                <Col></Col>
              )}
            </Col>
            <Col md={6}>
              {(data.imageAssetSrc) ? (
                <Col className="pb-4">
                  <img
                    key={`imageAssetPreview-${adDetails?.title}-${adDetails?.id}-${data.imageAssetFilename}`}
                    src={data.imageAssetSrc}
                    alt="Static asset"
                    width="120"
                  />
                  {showStoryAdUpdateButton() && (
                    <div className="text-danger font-weight-bolder cursor-pointer btn-link mt-2" onClick={() => handleResetAsset("image")}>
                      Reset
                    </div>
                  )}
                </Col>
              ) : (
                <Col></Col>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <div
                className="font-weight-bolder mb-2"
                style={{ fontSize: "14px" }}
              >
                CTA Items
              </div>
            </Col>
          </Row>
          {ctaItems?.map((ctaItem, index) => (
            <Row key={index} form className="mb-3 mt-3">
              <Col md={12}>
                <div
                  className="font-weight-bolder mb-2 text-info"
                  style={{ fontSize: "13px" }}
                >
                  CTA Item #{index + 1}
                </div>
              </Col>
              <Col>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "14px" }}
                      >
                        Type <span className="text-danger">*</span>
                      </Label>
                      <Select
                        id="type"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        name="type"
                        placeholder="Select CTA Item Type"
                        value={ctaItem.type}
                        onChange={(event) => {
                          handleCtaItemsChange(event, index)
                        }}
                        options={storyAdCtaItemTypeOptions}
                        components={{
                          Input:
                            (ctaItem.type?.value)
                              ? notRequiredComponent
                              : requiredComponent,
                        }}
                        isDisabled={!showStoryAdUpdateButton()}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "14px" }}
                      >
                        Text Color <span className="text-danger">*</span>
                      </Label>
                      <br />
                      <ColorPicker
                        showText
                        value={ctaItem.textColor}
                        onChange={(color, hexCode) => handleCtaItemColorChange(hexCode, "textColor", index)}
                        disabled={!showStoryAdUpdateButton()}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "14px" }}
                      >
                        Background Color <span className="text-danger">*</span>
                      </Label>
                      <br />
                      <ColorPicker
                        showText
                        value={ctaItem.backgroundColor}
                        onChange={(color, hexCode) => handleCtaItemColorChange(hexCode, "backgroundColor", index)}
                        disabled={!showStoryAdUpdateButton()}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "14px" }}
                      >
                        Text <span className="text-danger">* </span>
                      </Label>
                      <Input
                        type="text"
                        name="text"
                        placeholder="Enter Text"
                        value={ctaItem.text}
                        onChange={(event) => {
                          handleCtaItemsChange(event, index)
                        }}
                        required
                        disabled={!showStoryAdUpdateButton()}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        <strong>
                          Icon <small className="text-info">Formats: png, jpg, jpeg, webp, Aspect ratio: any, Size: 100KB (Optional) </small>
                        </strong>
                      </Label>
                      <Container className="custom-file">
                        <Input
                          type="file"
                          className="custom-file-input"
                          accept="image/png, image/jpg, image/jpeg, image/webp"
                          name="icon"
                          onChange={(event) => {
                            handleCtaItemFileChange(event, "image", index)
                          }}
                          disabled={!showStoryAdUpdateButton()}
                        />
                        <Label
                          className="custom-file-label"
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                          }}
                        >
                          <strong>{ctaItem.iconFilename || "Choose an icon"}</strong>
                        </Label>
                      </Container>
                      {(ctaItem.iconUrl) && (
                        <Col className="pt-3">
                          <img
                            src={ctaItem.iconUrl}
                            alt="Icon"
                            width="120"
                          />
                          {showStoryAdUpdateButton() && (
                            <div className="text-danger font-weight-bolder cursor-pointer btn-link mt-2" onClick={() => handleResetCtaItemFile(index)}>
                              Reset
                            </div>
                          )}
                        </Col>
                      )}
                    </FormGroup>
                  </Col>
                  {ctaItem.type?.value === "click" && (
                    <Col md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Deeplink <small className="text-info">(Optional for overriding)</small>
                        </Label>
                        <Input
                          type="textarea"
                          name="deeplink"
                          placeholder="Enter Deeplink"
                          value={ctaItem.deeplink}
                          onChange={(event) => {
                            handleCtaItemsChange(event, index)
                          }}
                          disabled={!showStoryAdUpdateButton()}
                        />
                      </FormGroup>
                    </Col>
                  )}
                  {ctaItem.type?.value === "click" && (
                    <Col md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Click URL <small className="text-info">(Optional for overriding)</small>
                        </Label>
                        <Input
                          type="textarea"
                          name="clickUrl"
                          placeholder="Enter Click URL"
                          value={ctaItem.clickUrl}
                          onChange={(event) => {
                            handleCtaItemsChange(event, index)
                          }}
                          disabled={!showStoryAdUpdateButton()}
                        />
                      </FormGroup>
                    </Col>
                  )}
                  {ctaItem.type?.value === "share" && (
                    <Col md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Share Text <span className="text-danger">* </span>
                          <ToolTip
                            data={`To use share text along with share URL, use ${shareUrlPlaceholder} placeholder.`}
                            id="shareTextTooltip"
                          />
                        </Label>
                        <Input
                          type="textarea"
                          name="shareText"
                          placeholder={`Enter Share Text with ${shareUrlPlaceholder}`}
                          value={ctaItem.shareText}
                          onChange={(event) => {
                            handleCtaItemsChange(event, index)
                          }}
                          required
                          disabled={!showStoryAdUpdateButton()}
                        />
                      </FormGroup>
                    </Col>
                  )}
                  {ctaItem.type?.value === "share" && (
                    <Col md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Share URL
                          <br />
                          {(ctaItem.shareText?.includes(shareUrlPlaceholder) && !ctaItem.shareUrl) ? (
                            <span className="text-warning font-weight-bold">
                              ⚠️ Missing share URL
                            </span>
                          ) : null
                          }
                        </Label>
                        <Input
                          type="textarea"
                          name="shareUrl"
                          placeholder="Enter Share URL"
                          value={ctaItem.shareUrl}
                          onChange={(event) => {
                            handleCtaItemsChange(event, index)
                          }}
                          disabled={!showStoryAdUpdateButton()}
                        />
                      </FormGroup>
                    </Col>
                  )}
                  {ctaItem.type?.value === "share" && (
                    <Col md={6}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "14px" }}
                        >
                          Share Package Name{" "}
                          <ToolTip
                            data={`Package name of the specific application on which story needs to be shared.`}
                            id="sharePackageNameTooltip"
                          />
                        </Label>
                        <CreatableSelect
                          id="sharePackageName"
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="sharePackageName"
                          placeholder="Select Share Package Name"
                          value={ctaItem.sharePackageName}
                          onChange={(event) => {
                            const temp = [...ctaItems]
                            temp[index]["sharePackageName"] = event
                            setCtaItems(temp)
                          }}
                          options={storyCTASharePackageNameOptions}
                          isClearable={true}
                          isDisabled={!showStoryAdUpdateButton()}
                        />
                      </FormGroup>
                    </Col>
                  )}
                </Row>
              </Col>
              {showStoryAdUpdateButton() && (
                <Col md={1}>
                  {index === 0 ? (
                    <Button className="mt-4" onClick={addCtaItem} color="transparent">
                      <FaPlus />
                    </Button>
                  ) : (
                    <Button className="mt-4" onClick={() => removeCtaItem(index)} color="transparent">
                      <FaMinus />
                    </Button>
                  )}
                </Col>
              )}
              <Col lg="12">
                {index !== (ctaItems.length - 1) && <hr style={{ marginBottom: 0 }} />}
              </Col>
            </Row>
          ))}
        </ReactBootstrapModal.Body>
        <ReactBootstrapModal.Footer>
          <Button color="secondary" onClick={handleMetadataOverride}>Metadata Override</Button>
          {showStoryAdResetButton() && adDetails?.storyAd?.id &&
            <Button color="danger" onClick={() => setConfirmationModalState(metadataOverrideAllActions.resetStoryAd)}>
              Reset Story Ad
            </Button>
          }
          <Button color="primary" onClick={handleHide}>Close</Button>
          {showStoryAdUpdateButton() &&
            <Button type='submit' color='success'>Submit</Button>
          }
        </ReactBootstrapModal.Footer>
      </Form>
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={"Error"}
        modaltext={failureModalText}
      />
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={action}
        onConfirmationTrue={() => {
          if (action === metadataOverrideAllActions.resetStoryAd) {
            onReset(adDetails?.id, adDetails?.title, isPromoted).finally(() => {
              setConfirmationModalIsOpen(false);
              handleHide()
            })
          }
        }}
        onHide={() => setConfirmationModalIsOpen(false)}
      />
    </Modal>
  )
}

export default ManageStoryAdsModal
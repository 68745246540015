import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, CardBody, CardImg, Button, FormGroup, Label } from "reactstrap";
import Reorder, { reorder } from "react-reorder";
import Loading from "../../../components/Loading";
import ResponseModal from '../ResponseModal';
import { successModalHeading } from '../../../config';
import { errorModalHeading } from '../../../config/config';
import { error, errorDescription, } from '../../../utilities/commonUtil';
import {fetchSuggestionDrawerCategoryContentOrderData,updateSuggestionDrawerContentOrderData} from '../../../utilities/apiUtils/suggestionDrawer'
import { showSuggestionDrawerCategoryContentOrder } from '../../../config/configSuggestionDrawer';
import ToolTip from "../../../utilities/ToolTip";
const CategoryContentOrder = (props) => {
  const [loading, setLoading] = useState(true);
  const [successModalText, setSuccessModalText] = useState("");
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);

  const [content, setContent] = useState([])


  useEffect(() => {
    if (props.match.params.id)  {
      getSuggestionDrawerCategoryContentOrder()
    } else {setContent([])
    }
  },[props.match.params.id] )

  const getSuggestionDrawerCategoryContentOrder = async () => {
    setLoading(true)
    let response = await fetchSuggestionDrawerCategoryContentOrderData(props.match.params.id)
    setContent(response.suggestionDrawerCategories)
    setLoading(false)
  } 

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  const handleReorder = (event, previousIndex, nextIndex, fromId, toId) => {
    setContent(reorder(content, previousIndex, nextIndex))
  };

  const handleSubmit = async () => {
    try {
      setLoading(true)
      let suggestionDrawerCategories = content
      let response = await updateSuggestionDrawerContentOrderData(props.match.params.id, { suggestionDrawerCategories })
      if (response.status === "success") {
        let text = "Content order has been updated";
        showSuccessModal(text);
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container fluid className="p-0">
      <Row>
        <h1 className="mb-4 mt-2 w-50 pl-3">Manage Ordering</h1>
      </Row>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <h5>Instructions:</h5>
                      <ul>
                        <li>
                          Drag and Drop the contents to rearrange them.
                        </li>
                      </ul>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {(showSuggestionDrawerCategoryContentOrder() && content.length > 0) ? (
                        <Button className="d-block ml-2 mt-2 mb-3" color="primary" onClick={handleSubmit}>
                          Save
                        </Button>
                      ) : null}
                    </Col>
                  </Row>
                  {(content.length > 0) ? (
                    <Reorder reorderId="reorder-list" onReorder={handleReorder} disabled={!showSuggestionDrawerCategoryContentOrder()}>
                      {content?.map(content => (
                          <div key={content?.id} className="reorderContainer align-bottom mb-5">
                            <Card className="m-3 responseCard" style={{ cursor: "pointer" }}>
                              <CardImg src={content.imageURL} />
                              <ToolTip
                                data={`
                                  ID: ${content.id}
                                  Priority: ${content.priority}
                                  ${content.title ? `Title: ${content.title}` : ""}
                                  ${content.description ? `Description: ${content.description}` : ""}
                                  ${content.subtext ? `Subtext: ${content.subtext}` : ""}
                                `}
                                id={`tooltip-${content.id}`}
                              />
                            </Card>

                          </div>
                        ))
                      }
                    </Reorder>
                  )
                    : <p className='py-3'>No Contents.</p>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}
      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={successModalHeading}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={errorModalHeading}
        modaltext={failureModalText}
      />
    </Container>
  )
}

export default CategoryContentOrder
import { host } from "./index";
import { retrieveFromLocalStorage } from "../localStorageUtil";
// let host = "http://127.0.0.1:8000"
export let createRole = async (data) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/internal/roles`;

  await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let editRole = async (data) => {
  let token = retrieveFromLocalStorage("token");
  let response = {};

  let sendData = {
    description: data.description,
    permissionIDs: data.permissionIDs,
  };

  let url = `${host}/internal/roles/${data.id}`;

  await fetch(url, {
    method: "POST",
    body: JSON.stringify(sendData),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let getRoles = async () => {
  let roles = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/internal/roles`;

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.length) roles = parseRolesData(res);
      else roles = res;
    });
  return roles;
};

let parseRolesData = (getRolesArray) => {
  let parsedRoles = [];
  getRolesArray.forEach((data) => {
    let role = {};
    if (data.permissions === null) role.permissions = [];
    else role.permissions = data.permissions;
    role.id = data.id;
    role.name = data.name;
    role.description = data.description;
    role.createdAt = data.createdAt;
    parsedRoles.push(role);
  });
  return parsedRoles;
};

export let getRolesDropdown = async () => {
  let getRolesArray = await getRoles();
  // used for dropdown values in create and edit user form
  let rolesArray = [];
  if (getRolesArray.length) {
    getRolesArray.forEach((data) => {
      let role = {
        value: data.id,
        label: data.name,
      };
      rolesArray.push(role);
    });
  }
  return rolesArray;
};

import React, { useState } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  Dropdown,
  Container,
} from "react-bootstrap";
import { DatePicker } from "antd";
import TextArea from "antd/es/input/TextArea";
import Checkbox from "antd/es/checkbox/Checkbox";
import { bulkStoriesData } from "../../../../utilities/apiUtils/stories";
import ConfirmationModal from "../../ConfirmationModal";
import { allActions } from "../../../../utilities/commonUtil";
import Select from "react-select";
import { actionStatus } from "../../../../config/configStoryDashboard";

const UserStoriesPropertiesModal = (props) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [notSelectedOptions, setNotSelectedOptions] = useState([
    "Status",
    "Date Range",
    "Share Text",
    "Enable Share Text",
  ]);
  const [status, setStatus] = useState(actionStatus[0]);
  const [startAt, setStartAt] = useState(null);
  const [endAt, setEndAt] = useState(null);
  const [shareText, setShareText] = useState("");
  const [enableShareText, setEnableShareText] = useState(true);

  const handleOptionSelect = (option) => {
    setSelectedOptions([...selectedOptions, option]);
    setNotSelectedOptions(notSelectedOptions.filter((item) => item !== option));
  };

  const handleRemoveOption = (option) => {
    setSelectedOptions(selectedOptions.filter((item) => item !== option));
    setNotSelectedOptions([...notSelectedOptions, option]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let requestData = {
      ids: props.selectedStories,
    };

    selectedOptions.forEach((option) => {
      switch (option) {
        case "Date Range":
          requestData.startAt = startAt.format('YYYY-MM-DD');
          requestData.endAt = endAt.format('YYYY-MM-DD');
          break;
        case "Share Text":
          requestData.shareText = shareText;
          break;
        case "Enable Share Text":
          requestData.enableShareText = enableShareText;
          break;
        case "Status":
          requestData.status = status.value;
          break;
        default:
          break;
      }
    });

    props.setAction("Update Stories Property");
    props.setUpdateBulkStories(requestData);
    props.setConfirmationModalIsOpen(true);
    setSelectedOptions([]);
    setNotSelectedOptions([
      "Status",
      "Date Range",
      "Share Text",
      "Enable Share Text",
    ]);
    setStatus(actionStatus[0]);
    setStartAt(null);
    setEndAt(null);
    setShareText("");
    setEnableShareText(true);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header className="bg-light" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update Story Properties ({props.selectedStories?.length}
          {props.selectedStories?.length === 1 ? " story" : " stories"} selected)
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={6}>
            <Form>
              <Form.Group controlId="formControlsSelect">
                <Form.Label>Select Properties to Change:</Form.Label>
                <Dropdown>
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    Select Property
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {notSelectedOptions.length > 0 ? (
                      notSelectedOptions.map((option) => (
                        <Dropdown.Item
                          key={option}
                          onSelect={() => handleOptionSelect(option)}
                        >
                          {option}
                        </Dropdown.Item>
                      ))
                    ) : (
                      <Dropdown.Item disabled>No Options left</Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
            </Form>
            <h5 className="text-muted">Instructions:</h5>
            <ul>
              <li>
                <small className="text-muted">
                  Select the properties you want to update from the dropdown
                  above.
                </small>
              </li>
              <li>
                <small className="text-muted">
                  You can remove the property by clicking on the remove button,
                  if you don't want to update the property.
                </small>
              </li>

              <li>
                <small className="text-muted">
                  Click on{" "}
                  <span style={{ color: "#000", fontWeight: 600 }}>Submit</span>{" "}
                  to update the selected properties.
                </small>
              </li>
            </ul>
          </Col>
          <Col md={6}>
            {/* Additional content can be added here if needed */}
            {selectedOptions.length > 0 &&
              selectedOptions.map((option, index) => (
                <Container className="mb-3" key={index}>
                  <h5>{option}</h5>
                  <Container className="d-flex flex-row align-items-center justify-content-between">
                    {option === "Date Range" && (
                      <Container className="d-flex flex-column gap-2">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center text-center p-0">
                            Start At
                          </div>
                          <DatePicker
                            selected={startAt}
                            onChange={(date) => setStartAt(date)}
                          />
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-2">
                          <div className="d-flex align-items-center text-center p-0">
                            End At
                          </div>
                          <DatePicker
                            selected={endAt}
                            onChange={(date) => setEndAt(date)}
                          />
                        </div>
                      </Container>
                    )}
                    {option === "Share Text" && (
                      <TextArea
                        className="w-100"
                        type="text"
                        value={shareText}
                        onChange={(e) => setShareText(e.target.value)}
                      />
                    )}
                    {option === "Enable Share Text" && (
                      <Checkbox
                        checked={enableShareText}
                        onChange={(e) => setEnableShareText(e.target.checked)}
                      >
                        Enable Share Text
                      </Checkbox>
                    )}
                    {option === "Status" && (
                      <Select
                      className="react-select-container w-100"
                      classNamePrefix="react-select"
                      placeholder={status.label}
                      value={status.label}
                      onChange={(option) => setStatus(option)}
                      options={actionStatus}
                    />
                    )}
                    <Button
                      className="ml-2"
                      variant="danger"
                      onClick={() => handleRemoveOption(option)}
                    >
                      Remove
                    </Button>
                  </Container>
                </Container>
              ))}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={selectedOptions.length === 0} variant="secondary" onClick={(e) => handleSubmit(e)}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserStoriesPropertiesModal;

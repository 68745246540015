import React, { useState, useEffect } from "react";
import Search from "../../../components/Search";
import Select from "react-select";
import { BucketListingCard } from "./components/BucketListingCard";
import ResponseModal from "../ResponseModal";
import ConfirmationModal from "../ConfirmationModal";
import {
  Col,
  Container,
  Row,
  Button,
  Spinner,
  FormGroup,
  Input,
} from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { Config } from "../../../config";
import { getFullBucketData } from "../../../utilities/apiUtils/stories";
import { successModalHeading, errorModalHeading } from "../../../config/config";
import {
  storyBucketCreatePermission,
  storyBucketListPermission,
  storyBucketUpdatePermission,
  storyBucketViewPermission,
  sotdListPermission,
  storiesBulkUpdatePermission,
} from "../../../config/configStoryDashboard";

import {
  errorDescription,
  error,
  getStatus,
  allActions,
  permissions,
  redirectionOnTokenExpiry,
  getStatusColor,
  getButtonColor,
  getBtnName,
  redirectOnTokenExpiry,
  redirectTo,
} from "../../../utilities/commonUtil";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { Link } from "react-router-dom";
import { useParams, useLocation, useHistory } from "react-router-dom";
import EmptyStoriesDisplay from "./components/EmptyStoriesDisplay";
import { faUpload, faTimes } from "@fortawesome/free-solid-svg-icons";
import UncontrolledButtonDropdown from "reactstrap/lib/UncontrolledButtonDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import { capitalize } from "@material-ui/core";
import {
  createStoryBucket,
  updateStoryBucket,
} from "../../../utilities/apiUtils/stories";

const BucketListing = () => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const searchStringParams = searchParams.get("searchString");

  const [id, setID] = useState(null);
  const [action, setAction] = useState("");
  const [stories, setStories] = useState([]);
  const [searchString, setSearchString] = useState(
    searchStringParams !== null ? searchStringParams : ""
  );
  const [bucket, setBucket] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [bucketList, setBucketList] = useState([]);
  const [hasMoreStories, setHasMoreStories] = useState(true);
  const [noStoriesFound, setNoStoriesFound] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [successModalState, setSuccessModalState] = useState({
    successModalDisplay: false,
    successModalText: "",
  });
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );
  const [status, setStatus] = useState("");
  const [sortBy, setSortBy] = useState(Config.CREATED_AT_DESCENDING);
  const [searchBy, setSearchBy] = useState(Config.SEARCH_BY_NAME);
  const [loading, setLoading] = useState(true);
  const [firstRender, setFirstRender] = useState(true);
  const [selectedStories, setSelectedStories] = useState([]);
  const [isBucketSelected, setIsBucketSelected] = useState(false);
  const [showCreateNewBucketModal, setShowCreateNewBucketModal] =
    useState(false);
  const [createNewBucketName, setCreateNewBucketName] = useState("");
  const [createNewBucketShareText, setCreateNewBucketShareText] = useState("");
  const [createNewBucketDescription, setCreateNewBucketDescription] =
    useState("");
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [editDetailsName, setEditDetailsName] = useState("");
  const [editDetailsShareText, setEditDetailsShareText] = useState("");
  const [editDetailsDescription, setEditDetailsDescription] = useState("");
  const [editDetails, setEditDetails] = useState({
    id: -1,
    name: "",
    shareText: "",
    description: "",
  });
  const [searchName, setSearchName] = useState("");
  const [searchId, setSearchId] = useState("");

  useEffect(() => {
    getStories();
    setFirstRender(false);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let params = {
        searchString: searchName,
        id: searchId,
        page: page,
        limit: limit,
      };

      let response = await getFullBucketData(params);
      if (response.storyBuckets !== null) {
        setStories((prevStories) => [...prevStories, ...response.storyBuckets]);
      } else {
        setHasMoreStories(false);
      }
    };

    if (!firstRender) {
      fetchData();
    }
  }, [page]);

  const getStories = async () => {
    let params = {
      searchString: searchName,
      id: searchId,
      page: page,
      limit: limit,
    };

    try {
      let response = await getFullBucketData(params);
      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectionOnTokenExpiry();
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: response.errorDescription,
        });
      } else if (response?.storyBuckets) {
        if (response.storyBuckets !== null) {
          setStories(response.storyBuckets);
          setLoading(false);
          return;
        }
      }
      setLoading(false);
      setNoStoriesFound(true);
    } catch (err) {
      setLoading(false);
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const handleSearchStringChange = (event) => {
    event.preventDefault();
    let { placeholder, value } = event.target;
    if (placeholder === "Search by Name") setSearchName(value);
    if (placeholder === "Search by ID") setSearchId(value);
    if (!value.length) {
      setSearchString("");
      search();
      return;
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams();
    searchParams.set("searchString", searchString);

    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
    if (!firstRender) {
      search();
    }
  }, [searchName, searchId]);

  const fetchMoreData = async () => {
    if (searchBy === "id") return;

    setPage(page + 1);
    setNoStoriesFound(false);
    setHasMoreStories(true);
  };

  const search = async (localSearchBy) => {
    setPage(0);
    setNoStoriesFound(false);
    setHasMoreStories(true);
    setSearchBy(localSearchBy || searchBy);
    setLoading(true);
    setStories([]);

    let params = {
      searchString: searchName,
      id: searchId,
      page: page,
      limit: limit,
    };

    try {
      let response = await getFullBucketData(params);
      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectionOnTokenExpiry();
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: response.errorDescription,
        });
      } else {
        if (response.storyBuckets != null) {
          setStories(response.storyBuckets);
          setLoading(false);
          return;
        }
        if (page === 0) {
          setLoading(false);
          setNoStoriesFound(true);
        } else {
          setLoading(false);
          setNoStoriesFound(false);
        }
      }
    } catch (err) {
      setLoading(false);
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const setConfirmationModalState = (id, btnName) => {
    setID(id);
    setAction(btnName);
    setConfirmationModalIsOpen(true);
  };

  const handleResponse = (response) => {
    if (!response.errorCode) {
      setSuccessModalState({
        successModalDisplay: true,
        successModalText: response.description,
      });
    } else {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: response.errorCode,
      });
    }
  };

  const failureModalClose = () => {
    setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
  };

  const successModelClose = () => {
    setSuccessModalState((prev) => ({ ...prev, successModalDisplay: false }));
    setStories([]);
    setPage(0);
    setHasMoreStories(true);
    getStories();
  };

  const getIcon = (status) => {
    if (status === "Deactivated") return faUpload;
    return faTimes;
  };

  const handleSelectAll = () => {
    if (isBucketSelected) {
      setSelectedStories([]);
    } else {
      setSelectedStories(stories.map((reply) => reply.id));
    }
    setIsBucketSelected(!isBucketSelected);
  };

  const onHideCreateNewBucketModal = () => {
    setShowCreateNewBucketModal(false);
  };

  const onHideDetailsModal = () => {
    setShowDetailsModal(false);
  };

  const handleSubmitConfirmationCreateNewBucketModal = () => {
    if (createNewBucketName === "") {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: "Bucket name cannot be empty",
      });
      return;
    } else {
      setConfirmationModalIsOpen(true);
      setAction("create a new bucket name " + createNewBucketName);
    }
  };

  const handleSubmitCreateNewBucket = () => {
    let bucketData = {
      name: createNewBucketName,
      shareText: createNewBucketShareText,
      description: createNewBucketDescription,
    };
    createStoryBucket(bucketData).then((response) => {
      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectOnTokenExpiry();
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: response.errorDescription,
        });
      } else {
        setSuccessModalState({
          successModalDisplay: true,
          successModalText: response.message,
        });
        setShowCreateNewBucketModal(false);
        setCreateNewBucketName("");
        setCreateNewBucketShareText("");
        setCreateNewBucketDescription("");
      }
    });
  };

  const handleSubmitConfirmationEditDetailsModal = () => {
    setConfirmationModalIsOpen(true);
    setAction("edit details of bucket name " + editDetails.name);
  };

  const handleSubmitEditDetails = async () => {
    let data = editDetails;
    let bucketId = editDetails.id;
    delete data.id;
    await updateStoryBucket(data, bucketId).then((response) => {
      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectOnTokenExpiry();
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: response.errorDescription,
        });
      } else {
        setSuccessModalState({
          successModalDisplay: true,
          successModalText: response.message,
        });
        setShowDetailsModal(false);
        setEditDetails({
          id: -1,
          name: "",
          shareText: "",
          description: "",
        });
      }
    });
  };

  const renderAllReplies = () => {
    if (stories.length === 0 && !loading) {
      return <EmptyStoriesDisplay />;
    } else {
      return stories.map((reply) => {
        let status = getStatus(reply.publishedAt, reply.deactivatedAt);
        let color = getStatusColor(status);
        let btnColor = getButtonColor(status);
        let btnName = getBtnName(status);
        return (
          <Col md="6" lg="4" key={reply.id} className="d-flex">
            <BucketListingCard
              reply={reply}
              getConfirmation={setConfirmationModalState}
              setShowDetailsModal={setShowDetailsModal}
              setEditDetails={setEditDetails}
            />
          </Col>
        );
      });
    }
  };

  const onKeyDown = (event) => {
    if (event.keyCode === Config.KEYPRESS_DOWN) {
      search();
    }
  };

  return (
    <Container fluid className="p-0">
      <Row className="align-items-center">
        <Col md={3} sm={6}>
          <h1 className="mb-3 mt-4">Buckets</h1>
        </Col>
        {storyBucketCreatePermission() ? (
          <>
            <Col md={9} sm={12} className="d-flex flex-row-reverse">
              <Button
                color="primary"
                className="ml-auto d-flex"
                onClick={() => redirectTo("/stories/buckets/create")}
              >
                <p className="my-0">Create a Bucket</p>
              </Button>
            </Col>
          </>
        ) : null}
      </Row>
      <Row>
      <Col md={4} sm={12}>
          <Search
            placeholder="Search by Name"
            value={searchName}
            onChange={handleSearchStringChange}
            onKeyDown={onKeyDown}
          />
        </Col>
        <Col md={4} sm={12}>
          <Input
            type="number"
            placeholder="Search by ID"
            value={searchId}
            onChange={handleSearchStringChange}
            onKeyDown={onKeyDown}
          />
        </Col>
      </Row>

      <InfiniteScroll
        className="infinite-scroll"
        dataLength={stories.length}
        next={fetchMoreData}
        hasMore={hasMoreStories}
        loader={
          !loading &&
          searchBy !== "id" &&
          noStoriesFound && <Spinner color="info" className=" d-flex mx-auto" />
        }
      >
        {loading ? (
          <Spinner color="info" className=" d-flex mx-auto" />
        ) : noStoriesFound ? (
          <Container
            sm="12"
            md="8"
            lg="6"
            className="mx-auto d-table mt-5 pt-2 pb-5"
          >
            <h1 className="text-center">No reply found</h1>
          </Container>
        ) : (
          <React.Fragment>
            {sotdListPermission && (
              <Row className="mt-2">{renderAllReplies()}</Row>
            )}
            <ResponseModal
              show={successModalState.successModalDisplay}
              onHide={() => successModelClose()}
              modalheading={successModalHeading}
              modaltext={successModalState.successModalText}
            />
            <ResponseModal
              show={failureModalState.failureModalDisplay}
              onHide={() => failureModalClose()}
              modalheading={errorModalHeading}
              modaltext={failureModalState.failureModalText}
            />
            <ConfirmationModal
              show={confirmationModalIsOpen}
              modaltext={action}
              onHide={() => {
                setConfirmationModalIsOpen(false);
              }}
              onConfirmationTrue={() => {
                setConfirmationModalIsOpen(false);
                if (
                  action ===
                  "create a new bucket name " + createNewBucketName
                ) {
                  handleSubmitCreateNewBucket();
                } else if (
                  action ===
                  "edit details of bucket name " + editDetails.name
                ) {
                  handleSubmitEditDetails();
                }
              }}
            />
          </React.Fragment>
        )}
      </InfiniteScroll>
    </Container>
  );
};

export default BucketListing;

import React from 'react'
import moment from "moment";
import { convertToIndianFormat } from '../../../config/configRevenueDashboard';

const defaultFormatter = (cell, row) => {
  return cell ?? "-"
}

const providerFormatter = (cell, row) => {
  let value = cell?.replace(/_/g, ' ') || ""
  return <strong>{value ? value?.charAt(0)?.toUpperCase() + value?.slice(1) : "-"}</strong>
}

const revenueTypeFormatter = (cell, row) => {
  return cell === "cpi" ? "Install" : cell === "cpc" ? "Click" : cell ?? "-"
}

const indianStandardNumberFormatter = (cell, row) => {
  return cell ? convertToIndianFormat(cell) : "0"
}

const revenueFormatter = (cell, row) => {
  return <strong>{cell ? convertToIndianFormat(cell) : "-"}</strong>
}

const ctrFormatter = (cell, row) => {
  return <strong>{cell || "NA"}</strong>
}

const conversionRateFormatter = (cell, row) => {
  return <strong>{cell || "NA"}</strong>
}

const adNameFormatter = (cell, row) => {
  return <strong>{cell ? !row.adName ? row.adId : row.adId === row.adName ? row.adId : `${row.adId} : ${row.adName}` : "-"}</strong>
}

const dateFormatter = (cell, row) => {
  return cell ? moment(cell).format("DD-MM-YYYY") : "-"
}

const noteDescriptionFormatter = (cell, row) => {
  return (
    <>
      <strong>{row.title}:</strong><br />{row.description}
    </>
  )
}

export const revenueNotesTableColumns = [
  {
    dataField: "date",
    text: "Date (DD-MM-YYYY)",
    sort: true,
    headerAttrs: { width: 200 },
    searchable: false,
    formatter: (cell, row) => dateFormatter(cell, row),
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "description",
    text: "Description",
    sort: false,
    searchable: false,
    formatter: (cell, row) => noteDescriptionFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  }
];

export const providersRevenueTableColumns = [
  {
    dataField: "date",
    text: "Date (DD-MM-YYYY)",
    sort: true,
    headerAttrs: { width: 200 },
    searchable: false,
    formatter: (cell, row) => dateFormatter(cell, row),
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "clientId",
    text: "Client",
    sort: true,
    headerAttrs: { width: 150 },
    searchable: false,
    formatter: (cell, row) => defaultFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "placementId",
    text: "Placement",
    sort: true,
    headerAttrs: { width: 150 },
    searchable: false,
    formatter: (cell, row) => defaultFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "providerId",
    text: "Provider",
    sort: true,
    headerAttrs: { width: 150 },
    searchable: false,
    formatter: (cell, row) => providerFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "adType",
    text: "Revenue Type",
    sort: true,
    headerAttrs: { width: 150 },
    searchable: false,
    formatter: (cell, row) => revenueTypeFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "impressions",
    text: "Impressions",
    sort: true,
    headerAttrs: { width: 150 },
    searchable: false,
    formatter: (cell, row) => indianStandardNumberFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "clicks",
    text: "Clicks",
    sort: true,
    headerAttrs: { width: 150 },
    searchable: false,
    formatter: (cell, row) => indianStandardNumberFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "installs",
    text: "Installs",
    sort: true,
    headerAttrs: { width: 150 },
    searchable: false,
    formatter: (cell, row) => indianStandardNumberFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "adValue",
    text: "Revenue (₹)",
    sort: true,
    headerAttrs: { width: 150 },
    searchable: false,
    formatter: (cell, row) => revenueFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "ctr",
    text: "Click Through Rate (%)",
    sort: true,
    headerAttrs: { width: 200 },
    searchable: false,
    formatter: (cell, row) => ctrFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "conversionRate",
    text: "Conversion Rate (%)",
    sort: true,
    headerAttrs: { width: 200 },
    searchable: false,
    formatter: (cell, row) => conversionRateFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
];

export const adsRevenueTableColumns = [
  {
    dataField: "date",
    text: "Date (DD-MM-YYYY)",
    sort: true,
    headerAttrs: { width: 200 },
    searchable: false,
    formatter: (cell, row) => dateFormatter(cell, row),
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "clientId",
    text: "Client",
    sort: true,
    headerAttrs: { width: 150 },
    searchable: false,
    formatter: (cell, row) => defaultFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "placementId",
    text: "Placement",
    sort: true,
    headerAttrs: { width: 150 },
    searchable: false,
    formatter: (cell, row) => defaultFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "providerId",
    text: "Provider",
    sort: true,
    headerAttrs: { width: 150 },
    searchable: false,
    formatter: (cell, row) => providerFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "adId",
    text: "Ad Name",
    sort: true,
    headerAttrs: { width: 150 },
    searchable: false,
    formatter: (cell, row) => adNameFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "adType",
    text: "Revenue Type",
    sort: true,
    headerAttrs: { width: 150 },
    searchable: false,
    formatter: (cell, row) => revenueTypeFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "impressions",
    text: "Impressions",
    sort: true,
    headerAttrs: { width: 150 },
    searchable: false,
    formatter: (cell, row) => indianStandardNumberFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "clicks",
    text: "Clicks",
    sort: true,
    headerAttrs: { width: 150 },
    searchable: false,
    formatter: (cell, row) => indianStandardNumberFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "installs",
    text: "Installs",
    sort: true,
    headerAttrs: { width: 150 },
    searchable: false,
    formatter: (cell, row) => indianStandardNumberFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "adValue",
    text: "Revenue (₹)",
    sort: true,
    headerAttrs: { width: 150 },
    searchable: false,
    formatter: (cell, row) => revenueFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "ctr",
    text: "Click Through Rate (%)",
    sort: true,
    headerAttrs: { width: 200 },
    searchable: false,
    formatter: (cell, row) => ctrFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "conversionRate",
    text: "Conversion Rate (%)",
    sort: true,
    headerAttrs: { width: 200 },
    searchable: false,
    formatter: (cell, row) => conversionRateFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
];
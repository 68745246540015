import React, { useState } from "react";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { permissions } from "../../../utilities/commonUtil";
import UserAttributeBulkFilters from "../UserAttributeBulkFilters";
import { activateContentUserAttributeRules, deactivateContentUserAttributeRules, getContentUserAttributeRules, saveContentUserAttributeRules } from "../../../utilities/apiUtils/suggestionDrawer";
import { sdv3UserAttributeRuleFilterOptions } from "../../../config/configSuggestionDrawer";
import { saveBulkContentUserAttributeRules } from "../../../utilities/apiUtils/suggestionDrawer";
import { getBulkContentUserAttributeRules } from "../../../utilities/apiUtils/suggestionDrawer";
const BulkContentUserAttributeRules = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const contentIds = queryParams.get("contentIds")?.split(",").map(Number);
    const [allowedPermissions] = useState(
        retrieveFromLocalStorage("userPermissions")
    );

    const updateRulesAllowed = () => {
        return allowedPermissions.includes(permissions.SuggestionDrawerContentRulesUpdate);
    };

    return (
        <div>
            <UserAttributeBulkFilters
                ruleType={"suggestionDrawerContentId"}
                getFilters={sdv3UserAttributeRuleFilterOptions}
                getRules={getContentUserAttributeRules}
                saveRules={saveBulkContentUserAttributeRules}
                deactivateRules={deactivateContentUserAttributeRules}
                activateRules={activateContentUserAttributeRules}
                updateRulesAllowed={updateRulesAllowed()}
            />
        </div>
    );
};

export default BulkContentUserAttributeRules;

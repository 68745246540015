import React from 'react';
const columns = [
    {
        dataField: "id",
        text: "ID",
        sort: false,
        searchable: false,
        style: {
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          cursor: "pointer",
        },
      },
    {
      dataField: "text",
      text: "Text",
      sort: false,
      searchable: true,
      style: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        cursor: "pointer",
      },
    },
    {
      dataField: "type",
      text: "Type",
      sort: false,
      searchable: true,
      style: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        cursor: "pointer",
      },
    },
  ];
  
  export { columns };
  
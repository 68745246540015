import React from "react";
import { Badge } from "reactstrap";
import { schedulingTypes } from "../../../../config/ConfigPushNotifications";


export const columns = [
    {
        dataField: "id",
        text: "#",
        headerAttrs: { width: 60 },
        searchable: false,
        sort: true,
        style: {
            cursor: "pointer",
        },
        formatter: (cell) => {
            return cell === null ? "--" : cell;
        },
    },
    {
        dataField: "name",
        text: "Name",
        searchable: true,
        style: {
            cursor: "pointer",
        },
        formatter: (cell) => {
            return cell === null || cell === undefined ? "--" : cell;
        },
    },
    {
        dataField: "layoutType",
        text: "Layout Type",
        searchable: true,
        style: {
            cursor: "pointer",
        },
        formatter: (cell) => {
            return cell === null ? "--" : cell;
        },
    },
    {
        dataField: "contentTitle",
        text: "Content Title",
        searchable: true,
        style: {
            cursor: "pointer",
        },
        // limit upto 5 words
        formatter: (cell) => {
            if(cell === null) return "--";
            const words = cell.split(" ");
            if (words.length > 5) {
                return words.slice(0, 5).join(" ") + " ...";
            } else {
                return cell;
            }
        },
    },
    {
        dataField: "priority",
        text: "Priority",
        searchable: true,
        style: {
            cursor: "pointer",
        },
        formatter: (cell) => {
            return cell === null ? "--" : cell;
        }
    },
    {
        dataField: "startDate",
        text: "Start Date",
        sort: true,
        searchable: false,
        style: {
            cursor: "pointer",
        },
        formatter: (cell) => {
            return cell === null ? "--" : cell;
        }
    },
    {
        dataField: "endDate",
        text: "End Date",
        sort: true,
        searchable: false,
        style: {
            cursor: "pointer",
        },
        formatter: (cell) => {
            return cell === null ? "--" : cell;
        }
    },
    {
        dataField: "startHour",
        text: "Start Hour",
        sort: true,
        searchable: false,
        style: {
            cursor: "pointer",
        },
        formatter: (cell) => {
            return cell === null ? "--" : cell;
        }
    },
    {
        dataField: "scheduling",
        text: "Scheduling",
        sort: true,
        searchable: false,
        style: {
            cursor: "pointer",
        },
        formatter: (cell) => {
            if ({ cell }.cell === schedulingTypes.SendOnPublish)
                return <Badge className="badge-primary">{cell}</Badge>;
            else if ({ cell }.cell === schedulingTypes.Recurring)
                return <Badge className="badge-warning">{cell}</Badge>;
            else if ({ cell }.cell === schedulingTypes.Scheduled)
                return <Badge className="badge-info">{cell}</Badge>;
        }
    },
    {
        dataField: "status",
        text: "Push Notification Status",
        sort: true,
        searchable: false,
        style: {
            cursor: "pointer",
        },
        formatter: (cell) => {
            if ({ cell }.cell === "Activated")
                return <Badge className="badge-success">{cell}</Badge>;
            else if ({ cell }.cell === "Deactivated")
                return <Badge className="badge-danger">{cell}</Badge>;
            else if ({ cell }.cell === "Unpublished")
                return <Badge className="badge-primary">{cell}</Badge>;
        },
    },
    {
        dataField: "userSegmentName",
        text: "User Segment",
        sort: true,
        searchable: false,
        style: {
            cursor: "pointer",
        },
        formatter: (cell) => {
            return cell === null ? "--" : cell;
        },
    },
];
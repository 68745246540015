export const successModalHeading = "Success";
export const failureModalHeading = "Error";

export const locationRuleCreatedSuccessfullyText =
  "Location Rule created Successfully.";
export const quickReplyCreatedSuccessfullyText =
  "Quick reply created successfully.";
export const quickReplyUpdatedSuccessfullyText = "Quick reply updated.";

export const invalidColorCodeResponse = "Please enter correct hex code for color.";

export const publishedStatus = "published";
export const deactivatedStatus = "deactivated";
export const successStatus = "success";
export const activatedStatus = "activated";

export const QuickReplyCategoriesLimit = 20;

export const adBannerTypes = [
  { value: "horizontal", label: "Horizontal" },
  { value: "vertical", label: "Vertical" },
];

export const actionTypeOptions = [
  { value: "webView", label: "Web View" },
  { value: "chrometab", label: "Chrome Tab" },
  { value: "browser", label: "Browser" },
];

export const mediaTypeOptions = [
  { value: "image", label: "Image" },
  { value: "video", label: "Video" },
];

export const trackersTypeOptions = [
  { value : "direct_url" , label : "Direct URL" },
];

export const titleAlignmentOptions = [
  { value: "left", label: "Left" },
  { value: "center", label: "Center" },
  { value: "right", label: "Right" },
];

export const bannerSizeOptions = {
  horizontal: {
    bannerAspectRatio: 2.5,
    widthPercentage: 83.33,
  },
  vertical: {
    bannerAspectRatio: 0.5625,
    widthPercentage: 41.67,
  },
}

export const quickRepliesBulkUploadType = [
  { value: "text", label: "Text" },
  { value: "image", label: "Image" },
]

export const quickRepliesPageType = [
  { value: "text", label: "Text" },
  { value: "image", label: "Image" },
  { value: "card", label: "Card" },
]

export const quickRepliesTextDetailsType = [
  { value: "header", label: "Header" },
  { value: "subText", label: "Subtext" },
  { value: "paragraph", label: "Paragraph" },
  { value: "collapsableText", label: "Collapsible Text" },
]

export const quickRepliesCTAActionsType = [
  { value: "copyCode", label: "Copy Code" },
  { value: "redirect", label: "Redirect" },
  { value: "share", label: "Share" },
]

export const quickRepliesCTAActionsRedirectionType = [
  { value: "webView", label: "Web View" },
  { value: "chrometab", label: "Chrome Tab" },
  { value: "browser", label: "Browser" },
]

export const quickRepliesCTAActionsDeeplinkType = [
  { value: "custom", label: "Custom value"},
  { value: "default", label: "Auto Generate From Input Values"},
]

export const DeeplinkType = [
  { value: "custom", label: "Custom value"},
  { value: "default", label: "Auto Generate From Input Values"},
]

export const targettingTypeOptions = [
  { value: "category" , label: "Category" },
  { value: "custom" , label: "Custom" },
]

export const clientIDOptions = [
  { value : "mcSLTFw2VeMm1SvkPwWyd1vNKSers1kF" , label : "AI Mint Keyboard (Playstore)"},
  { value : "7wZFJWA5chjgat68y826IAIKQ6s197RM" , label : "Bobble Android App" },
  { value : "SGLbkv3GEHoe2vioLg2xX0HvBfc7wDc5" , label : "Mint Keyboard (Preload)"},
];

let QUICK_REPLY_IMAGE_BULK_UPLOAD_MAX_ZIP_FILE_SIZE = window._env_.REACT_APP_QUICK_REPLY_IMAGE_BULK_UPLOAD_MAX_ZIP_FILE_SIZE;
if (QUICK_REPLY_IMAGE_BULK_UPLOAD_MAX_ZIP_FILE_SIZE === undefined) QUICK_REPLY_IMAGE_BULK_UPLOAD_MAX_ZIP_FILE_SIZE = 20 * 1024 * 1024;
export  {QUICK_REPLY_IMAGE_BULK_UPLOAD_MAX_ZIP_FILE_SIZE} ;

export const statusOptions = [
  { value: "", label: "All" },
  { value: "active", label: "Active" },
  { value: "deactivated", label: "Deactivated" },
  { value: "unpublished", label: "Unpublished" },
]

export const statusActive = "active";
export const statusDeactivated = "deactivated";
export const defaultQuickReplySearchStatus = statusActive

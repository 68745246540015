import { replaceUnderscoreAndCapitalize } from "../../../utilities/commonUtil";

const defaultFormatter = (cell, row) => {
  return replaceUnderscoreAndCapitalize(cell) || "-"
}

export const tableColumns = [
  {
    dataField: "id",
    text: "#",
    sort: true,
    headerAttrs: { width: 100 },
    searchable: true,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "name",
    text: "Name",
    sort: true,
    searchable: true,
    formatter: (cell, row) => defaultFormatter(cell, row),
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  }
];
import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import { Row, Col, FormGroup, Label, Input, Form, Button } from "reactstrap";
import Select, { components } from "react-select";
import { fetchPlacements } from '../../../utilities/apiUtils/configAPI';
import { fetchServerAdProviders } from '../../../utilities/apiUtils/smartSuggestions';
import { fetchProvidersByPlacementId } from '../../../utilities/apiUtils/revenue';

const MixingStrategyRatiosModal = ({ show = false, type, formData, setFormData = () => { }, onSubmit = () => { }, onHide = () => { } }) => {
  const [data, setData] = useState({
    id: "",
    placementId: "",
    serverAdProviderId: "",
    numberOfAds: "",
    priority: ""
  })
  const [placementOptions, setPlacementOptions] = useState([])
  const [serverAdProvidersAllOptions, setServerAdProvidersAllOptions] = useState([])
  const [serverAdProvidersOptions, setServerAdProvidersOptions] = useState([])

  const fetchPlacementOptions = async () => {
    try {
      let response = await fetchPlacements()
      if (response?.length > 0) {
        setPlacementOptions(response.map(placement => ({
          value: placement.id,
          label: placement.description,
          target: {
            name: "placementId",
            value: {
              value: placement.id,
              label: placement.description
            }
          }
        })))
      }
    } catch (err) {
      console.log(err);
    }
  }

  const fetchAllServerAdProviders = async () => {
    try {
      let response = await fetchServerAdProviders()
      if (response?.length > 0) {
        setServerAdProvidersAllOptions(response.map(serverAdProvider => ({
          value: serverAdProvider.id,
          label: serverAdProvider.name,
        })))
      }
    } catch (err) {
      console.log(err);
    }
  }

  const fetchServerAdProvidersOptions = async (placementId) => {
    try {
      let response = await fetchProvidersByPlacementId(placementId)
      const serverAdProviders = []
      serverAdProviders.push({
        value: 1,
        label: "Internal",
        target: {
          name: "serverAdProviderId",
          value: {
            value: 1,
            label: "Internal"
          }
        }
      })
      if (response?.length > 0) {
        let serverAdProviderId, serverAdProviderName
        response.forEach(serverAdProvider => {
          if (!serverAdProvider?.includes("internal")) {
            serverAdProvider = serverAdProvider?.replace(/_/g, ' ')
            serverAdProviderName = serverAdProvider?.charAt(0)?.toUpperCase() + serverAdProvider?.slice(1)
            serverAdProviderId = serverAdProvidersAllOptions.find(option => option.label === serverAdProviderName)?.value ?? serverAdProviderName
            serverAdProviders.push({
              value: serverAdProviderId,
              label: serverAdProviderName,
              target: {
                name: "serverAdProviderId",
                value: {
                  value: serverAdProviderId,
                  label: serverAdProviderName
                }
              }
            })
          }
        })
      }
      setServerAdProvidersOptions(serverAdProviders)
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchPlacementOptions()
    fetchAllServerAdProviders()
  }, [])

  useEffect(() => {
    if (formData?.id) {
      setData({
        id: formData?.id,
        placementId: formData?.placementId ? {
          label: placementOptions.find(option => option.value === formData?.placementId)?.label ?? formData?.placementId,
          value: formData?.placementId
        } : "",
        serverAdProviderId: formData?.serverAdProviderId ? {
          label: formData?.serverAdProviderId === 1
            ? "Internal"
            : serverAdProvidersAllOptions.find(option => option.value === formData?.serverAdProviderId)?.label ?? formData?.serverAdProviderId,
          value: formData?.serverAdProviderId
        } : "",
        numberOfAds: formData?.numberOfAds,
        priority: formData?.priority,
      })
      fetchServerAdProvidersOptions(formData?.placementId)
    } else {
      setData({
        id: "",
        placementId: "",
        serverAdProviderId: "",
        numberOfAds: "",
        priority: ""
      })
    }
  }, [formData, placementOptions, serverAdProvidersAllOptions])

  const handleChange = (event) => {
    const { name, value } = event.target
    if (name === "placementId") {
      setData((prevState) => ({
        ...prevState,
        serverAdProviderId: "",
        [name]: value
      }))
      fetchServerAdProvidersOptions(value?.value)
    } else {
      setData((prevState) => ({
        ...prevState,
        [name]: value
      }))
    }
  }

  const handleHide = () => {
    setData({
      id: "",
      placementId: "",
      serverAdProviderId: "",
      numberOfAds: "",
      priority: ""
    })
    setServerAdProvidersOptions([])
    onHide()
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = {
      id: data.id,
      placementId: data.placementId?.value,
      serverAdProviderId: serverAdProvidersOptions?.length === 1 ? 1 : data.serverAdProviderId?.value,
      numberOfAds: Number(data.numberOfAds),
      priority: Number(data.priority)
    }
    setFormData(payload)
    onSubmit(payload)
  }

  const requiredComponent = (props) => (
    <div>
      <components.Input {...props} required={true} />
    </div>
  );

  const notRequiredComponent = (props) => (
    <div>
      <components.Input {...props} required={false} />
    </div>
  );

  return (
    <Modal
      show={show}
      onHide={handleHide}
      aria-labelledby="contained-modal-title-vcenter"
      size='lg'
      centered
      backdrop="static"
    >
      <Modal.Header className="bg-light" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {type} Mixing Strategy Ratio
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row form>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Placement <span className="text-danger">*</span>
                </Label>
                <Select
                  id="placementId"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="placementId"
                  placeholder="Select Placement"
                  value={data.placementId}
                  onChange={handleChange}
                  options={placementOptions}
                  components={{
                    Input:
                      (data.placementId)
                        ? notRequiredComponent
                        : requiredComponent,
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Server Ad Provider <span className="text-danger">*</span>
                </Label>
                <Select
                  id="serverAdProviderId"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="serverAdProviderId"
                  placeholder="Select Server Ad Provider"
                  value={serverAdProvidersOptions?.length === 1
                    ? {
                      value: 1,
                      label: "Internal"
                    }
                    : data.serverAdProviderId
                  }
                  onChange={handleChange}
                  options={serverAdProvidersOptions}
                  isDisabled={serverAdProvidersOptions?.length <= 1}
                  components={{
                    Input:
                      (data.serverAdProviderId)
                        ? notRequiredComponent
                        : requiredComponent,
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Number Of Ads <span className="text-danger">*</span>
                </Label>
                <Input
                  type="number"
                  name="numberOfAds"
                  min={1}
                  placeholder="Enter Number Of Ads"
                  value={data.numberOfAds}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Priority <span className="text-danger">*</span>
                </Label>
                <Input
                  type="number"
                  name="priority"
                  min={1}
                  placeholder="Enter Priority"
                  value={data.priority}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button color="primary" onClick={handleHide}>Close</Button>
          <Button type='submit' color='success'>Submit</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default MixingStrategyRatiosModal
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
  Input,
  Container,
} from "reactstrap";
import { capitalize } from "@material-ui/core";
import ResponseModal from "../ResponseModal";
import { X } from "react-feather";
import { iconSize } from "../../../config/UserConfig";
import { addAppResourcesData } from "../../../utilities/apiUtils/appResources";
import { successModalHeading, errorModalHeading } from "../../../config/config";
import { AppResourcesResponseModal } from "./AppResourcesResponseModal";
import { error } from "../../../utilities/commonUtil"

import { appResourcesResponses, imageFileSizeExceedError, compressibleImageTypes, maxImageUploadSize } from "../../../config/ConfigAppResources";

export const ManageAppResources = (props) => {
  const [resources, setResources] = useState([
    {
      description: "",
      filenameBuffer: null,
      filename: "",
    },
  ]);

  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");
  const [responseAppResources, setResponseAppResources] = useState([]);
  const [temporaryMessage, setTemporaryMessage] = useState("");

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    props.onHide();
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
    props.onHide();
  };

  const setAppResourcesData = () => {
    if (props.action === "edit" || props.action === "view") {
      setResources(props.resources);
    }
  };

  const handleDescriptionChange = (event, index) => {
    let temp = [...resources];
    temp[index].description = event.target.value;
    // check if the number of characters is greater than 191
    if (temp[index].description.length > 191) {
      return;
    }
    setResources(temp);
  };

  const showTemporaryMessage = (message) => {
    setTemporaryMessage(message);
    setTimeout(() => {
      setTemporaryMessage("");
    }, 2500);
  };

  const handleFileChange = (event, index) => {
    let temp = [...resources];
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      // Check if the file type is in the supported compressible file list
      const isSupportedImage = compressibleImageTypes.includes(file.type);
      if (file.type === "image/gif") {
        showTemporaryMessage("GIF uploaded! Please use WebP if possible.");
      }
      if (isSupportedImage && file.size > maxImageUploadSize) {
        let text = imageFileSizeExceedError;
        showErrorModal(text);
        temp[index].filenameBuffer = "";
        temp[index].filename = "";
        return;
      }
      temp[index].filenameBuffer = e.target.result;
      temp[index].filename = file.name;
      setResources(temp);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (resources.length > 5) {
      showErrorModal("You can create up to 5 app resources.");
      return;
    }

    if (resources.length === 0) {
      showErrorModal("Please add at least one app resource.");
      return;
    }

    if (props.action === "add") {
      const payload = {
        appResources: [],
      };

      resources.forEach((resource) => {
        payload.appResources.push({
          description: resource.description,
          filenameBuffer: resource.filenameBuffer,
          filename: resource.filename,
        });
      });

      let response = await addAppResourcesData(payload);

      console.log(response);
      let text = ""
      if (response?.errorCode === undefined && response?.length > 0) {
        text = appResourcesResponses.addedSuccessfully;
        showSuccessModal(text);
        setResponseAppResources(response);
      } else {
        if (response === undefined)
          text = error.unexpectedError;
        else
          text = response.errorDescription;
        showErrorModal(text);
      }
    }
  };

  useEffect(() => {
    setAppResourcesData();
  }, []);

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {capitalize(props.action)} App Resources
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(event) => handleSubmit(event)}>
            <Row form>
              <Col md={12}>
                <Row className="mb-3">
                  <Col md={6} xs={6}>
                    <Label
                      className="font-weight-bolder mb-2"
                      style={{
                        fontSize: "16px",
                        paddingBottom: "3rem"
                      }}
                    >
                      App Resources:
                    </Label>
                  </Col>
                  <Col md={6} xs={6}>
                    <div className="p-0">
                      {props.action !== "view" && resources.length < 5 && (
                        <Button
                          color="success"
                          className="ml-auto d-flex btn-success"
                          onClick={() =>
                            setResources(
                              resources.concat({
                                description: "",
                                filenameBuffer: null,
                                filename: "",
                              })
                            )
                          }
                        >
                          <p
                            className="mb-1 mt-1"
                            style={{ fontSize: "0.75rem" }}
                          >
                            Add App Resources
                          </p>
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
                {resources?.map((item, index) => {
                  return (
                    <Row key={index} form>
                      <Col md={8}>
                        <FormGroup>
                          <Label>Description: </Label>
                          <Input
                            type="text"
                            name="description"
                            placeholder="Enter Description"
                            value={item.description}
                            disabled={props.action === "view"}
                            onChange={(event) =>
                              handleDescriptionChange(event, index)
                            }
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <div className="p-0" style={{ marginRight: "20px" }}>
                          {props.action !== "view" && (
                            <X
                              className="d-none d-lg-block ml-auto d-flex"
                              size={iconSize}
                              color="red"
                              style={{
                                cursor: "pointer",
                                border: "2px solid red",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                setResources(
                                  resources.filter((_, i) => i !== index)
                                );
                              }}
                            ></X>
                          )}
                        </div>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Label>File: </Label>
                          {temporaryMessage && (
                            <p style={{ color: "red", marginBottom: "5px" }}>{temporaryMessage}</p>
                          )}
                          <Container className="custom-file">
                            <Input
                              type="file"
                              name="file"
                              className="custom-file-input"
                              onChange={(event) =>
                                handleFileChange(event, index)
                              }
                              required
                            />
                            <Label
                              id={`filename${index}`}
                              className="custom-file-label"
                            >
                              {item.filename === "" ? "Choose File" : item.filename}
                            </Label>
                          </Container>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        {/* show preview if the file is image or video */}
                        {item.filenameBuffer &&
                          /\.(png|jpe?g|gif|mp4|webm)$/i.test(
                            item.filename
                          ) && (
                            <div className="d-flex justify-content-center">
                              <img
                                src={item.filenameBuffer}
                                alt="preview"
                                style={{ width: "20%", height: "20%" }}
                              />
                            </div>
                          )}
                      </Col>

                      <hr
                        style={{
                          width: "75rem",
                          color: "black",
                        }}
                      />
                    </Row>
                  );
                })}
              </Col>
            </Row>
            <div className="d-block w-100">
              {props.action !== "view" && (
                <Button color="primary" type="submit">
                  Submit
                </Button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <AppResourcesResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={successModalHeading}
        modaltext={successModalText}
        responses={responseAppResources}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={errorModalHeading}
        modaltext={failureModalText}
      />
    </>
  );
};

export default ManageAppResources;

import { retrieveFromLocalStorage } from "../localStorageUtil";
import { host } from "../apiUtils/index";
export const fetchSotdListingTargetingRules = async (featuredStoryId) => { // Update parameter
    let rules = [];

    let token = retrieveFromLocalStorage("token");

    let url = `${host}/v4/internal/stories/featured/${featuredStoryId}/targetingRules`; // Updated URL
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        });

        const data = await response.json(); 
        if (data) {
            rules = data.rules; 
        }
        return rules;

    } catch (error) {
        console.error("Error fetching featured story rules:", error); 
        return; 
    }
};

export const updateSotdListingTargetingRules = async ( rules,featuredStoryId, data) => {
    let token = retrieveFromLocalStorage("token");

    let url = `${host}/v4/internal/stories/featured/${featuredStoryId}/targetingRules`; 
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });

        const result = await response.json(); // Use 'result' for clarity
        return result; 

     } catch (error) {
        console.error("Error updating featured story rules:", error); 
        return; 
    }
};

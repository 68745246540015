import React, { Component } from "react";
import Select from "react-select";
import {
  Card,
  CardImg,
  Row,
  Col,
  Container,
  CardBody,
  Spinner,
} from "reactstrap";
import {
  errorDescription,
  error,
  redirectOnTokenExpiry,
  redirectTo,
} from "../../../utilities/commonUtil";
import Search from "../../../components/Search";
import ResponseModal from "../ResponseModal";
import { Config } from "../../../config/UserConfig";
import InfiniteScroll from "react-infinite-scroll-component";
import { fetchGifs, getGifPacks } from "../../../utilities/apiUtils";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import UncontrolledButtonDropdown from "reactstrap/lib/UncontrolledButtonDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import { capitalize } from "@material-ui/core";

class Gifs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: "",
      failureModalText: "",
      failureModalDisplay: false,
      page: 0,
      limit: 20,
      gifs: [],
      hasMoreGifs: true,
      gifPacks: [],
      gifPackId: "",
      fetchFailed: false,
      pricing: "",
      status: "",
      sortBy: Config.UPDATED_AT_DESCENDING,
      searchBy: Config.SEARCH_BY_DEFAULT,
    };
  }

  componentDidMount() {
    this.getGifs();
  }

  getGifs = async () => {
    console.log("IN get gifs")
    // Query parameter.
    let params = {
      searchString: this.state.searchBy === "tag" ? this.state.searchString : "",
      ids: this.state.searchBy === "id" ? this.state.searchString : "",
      pricing: this.state.pricing,
      status: this.state.status,
      sortBy: this.state.sortBy,
      page: this.state.page,
      limit: this.state.limit,
    };
    // API called with given query parameters.
    await fetchGifs(params)
      .then((response) => {
        console.log("Response get gifs", response)
        if (response.gifs && response.gifs.length !== 0) {
          this.setState({ gifs: response.gifs });
          if (response.gifs.length < 20)
            this.setState({ hasMoreGifs: false });
        } else this.setState({ gifs: [], hasMoreGifs: false });
      })
      .catch((err) => {
        this.setState({
          fetchFailed: true,
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      });
  };


  handleChange = (event) => {
    event.preventDefault();
    let value = event.target.value;
    this.setState({ searchString: value });
    if (!event.target.value.length) {
      this.search();
    }
  };

  onKeyDown = (event) => {
    if (event.keyCode === Config.KEYPRESS_DOWN) {
      this.search();
    }
  };

  search = (searchBy) => {
    this.setState(
      { loading: true, page: 0, hasMoreGifs: true, searchBy: searchBy || this.state.searchBy },
      async () => {
        this.getGifs();
      });
  };

  fetchMoreData = () => {
    this.setState(
      { page: this.state.page + 1, hasMoreGifs: true },
      async () => {
        // Query parameters
        let params = {
          searchString: this.state.searchBy === "tag" ? this.state.searchString : "",
          ids: this.state.searchBy === "id" ? this.state.searchString : "",
          pricing: this.state.pricing,
          status: this.state.status,
          sortBy: this.state.sortBy,
          page: this.state.page,
          limit: this.state.limit,
        };
        // API called with given query parameters
        let res = await fetchGifs(params);
        if (res.gifs && res.gifs.length > 0) {
          this.setState({
            gifs: this.state.gifs.concat(res.gifs),
            hasMoreGifs: true,
          });
        } else {
          this.setState({ hasMoreGifs: false });
        }
      }
    );
  };

  failureModalClose = () => {
    this.setState({ failureModalDisplay: false });
  };

  handleStatusChange = (status) => {
    this.setState({ status: status.value }, () => this.search());
  };

  handleOrderChange = (sortBy) => {
    this.setState({ sortBy: sortBy.value }, () => this.search());
  };

  render() {
    return (
      <Container fluid className="p-0">
        <h1 className="heading">Gifs</h1>
        <Row className="mt-4">
          <Col lg={6}>
            <Search
              value={this.state.searchString}
              onChange={this.handleChange}
              onKeyDown={this.onKeyDown}
              placeholder={"Search Gifs"}
            ></Search>
          </Col>
          <Col md={3} sm={12}>
            <UncontrolledButtonDropdown className="mr-1 mb-1">
              <DropdownToggle caret color="success">
                {"Search By " + capitalize(this.state.searchBy) + " "}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => { this.search("id") }}>ID</DropdownItem>
                <DropdownItem onClick={() => { this.search("tag") }}>Tag</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </Col>
        </Row>
        <Row>
          <Col md={3} sm={12}>
            <Select
              className="react-select-container mb-4"
              classNamePrefix="react-select"
              placeholder="Select Status"
              onChange={this.handleStatusChange}
              options={[
                { value: "", label: "All" },
                { value: "active", label: "Active" },
                { value: "deactivated", label: "Deactivated" },
                { value: "unpublished", label: "Unpublished" },
              ]}
            />
          </Col>
          <Col md={3} sm={12}>
            <Select
              className="react-select-container mb-4"
              classNamePrefix="react-select"
              placeholder="Sort By"
              onChange={this.handleOrderChange}
              options={[
                { value: Config.UPDATED_AT_DESCENDING, label: "Updated At \u2193" },
                { value: Config.UPDATED_AT_ASCENDING, label: "Updated At \u2191" },
              ]}
            />
          </Col>
        </Row>
        {/* Component to displays all the Gifs */}
        <InfiniteScroll
          style={{ overflow: "auto !important" }}
          className="infinite-scroll"
          dataLength={this.state.gifs.length}
          next={this.fetchMoreData}
          hasMore={this.state.hasMoreGifs}
          loader={
            this.state.fetchFailed ? (
              false
            ) : (
              <Spinner color="info" className="d-flex mx-auto" />
            )
          }
        >
          <Row className="mt-4 mx-0">
            {this.state.gifs.length === 0 ? (
              <Container className="mt-5">
                <h3 className="text-center">No Gif available.</h3>
                <h4 className="text-center">
                  Enter a valid search tag to view gifs.
                </h4>
              </Container>
            ) : (
              this.state.gifs.map((gif, index) => {
                return (
                  <Col key={index} md={6} lg={3}>
                    <Card className="importPageCard">
                      <React.Fragment>
                        <CardImg
                          src="/img/white-bg.png"
                          onLoad={(e) => (e.target.src = gif.imageURL)}
                          onClick={() => {
                            redirectTo(`/gifs/${gif.id}`);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </React.Fragment>
                      <CardBody>
                        Id: {gif.id}
                      </CardBody>
                    </Card>
                  </Col>
                );
              })
            )}
          </Row>
        </InfiniteScroll>
        {/* Modal component to display failure response */}
        <ResponseModal
          show={this.state.failureModalDisplay}
          onHide={this.failureModalClose}
          modalheading={"Error"}
          modaltext={this.state.failureModalText}
        />
      </Container>
    );
  }
}

export default Gifs;

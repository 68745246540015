export const statusOptions = [
    { value: "", label: "All" },
    { value: "Activated", label: "Activated" },
    { value: "Deactivated", label: "Deactivated" },
    { value: "Unpublished", label: "Unpublished" },
  ];
  
export let processDateAndStatus = (item) => {
  if (item.published_at !== null) {
    item.published_at = new Date(item.published_at).toLocaleString();
    if (item.published_at === "Invalid Date") {
      item.published_at = null;
    }
  }
  if (item.deactivated_at !== null) {
    item.deactivated_at = new Date(item.deactivated_at).toLocaleString();
    if (item.deactivated_at === "Invalid Date") {
      item.deactivated_at = null;
    }
  }
  if (
    (item.published_at === null || item.published_at === "Invalid Date") &&
    item.deactivated_at === null
  ) {
    item.status = "Unpublished";
  }
  if (
    item.published_at !== null &&
    item.published_at !== "Invalid Date" &&
    item.deactivated_at === null
  ) {
    item.status = "Activated";
  }
  if (item.published_at !== null && item.deactivated_at !== null) {
    item.status = "Deactivated";
  }
};
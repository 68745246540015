import React from "react";
import { Badge, Row, Col } from "reactstrap";

const RuleSummary = (props) => {

  return (
    <div>
      <Row className="my-1 align-items-start">
        {props.clientRules
          ? props.clientRules.map(function (object, i) {
              return (
                <Col className="p-1 col-auto" key={i}>
                  <Badge
                    className="float-left"
                    color={object.effect == "Allow" ? "success" : "danger"}
                  >
                    {object.client}
                  </Badge>
                </Col>
              );
            })
          : {}}
      </Row>
      <Row className="my-1 align-items-start">
        {props.appVersionRules && props.showAppVersionRules && (
          <>
          <Col className="p-1 col-auto">
            <Badge
              className="float-left"
              color={"success"}
            >
              Min App Version : {props.appVersionRules?.minAppVersion}
            </Badge>
          </Col>
          <Col className="p-1 col-auto">
            <Badge
              className="float-left"
              color={"success"}
            >
              Max App Version : {props.appVersionRules?.maxAppVersion === null ? "null" : props.appVersionRules?.maxAppVersion}
            </Badge>
          </Col>
          </>
        )}
        </Row>
      <Row className="my-1 align-items-start">
        {props.locationRules
          ? props.locationRules.map(function (object, i) {
              return (
                <Col className="p-1 col-auto" key={i}>
                  <Badge
                    className="float-left"
                    color={object.effect == "Allow" ? "success" : "danger"}
                  >
                    {object.country} : {object.region} : {object.city}
                  </Badge>
                </Col>
              );
            })
          : {}}
      </Row>
    </div>
  );
};

export default RuleSummary;

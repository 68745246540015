import React, { useState, useEffect } from "react";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import { Button } from "react-bootstrap";
import { tableColumns } from "./tableColumn";
import Select from "react-select";
import { Link } from "react-router-dom";
import ConfirmationModal from "../ConfirmationModal";
import ResponseModal from "../ResponseModal";
import moment from "moment";
import {
  processDateAndStatus,
  showActivateSmartSuggestionButton,
  showAddSmartSuggestionButton,
  showDeactivateSmartSuggestionButton,
  showOrderListSmartSuggestionButton,
  showPublishSmartSuggestionButton,
  showUnpublishSmartSuggestionButton,
  showTargetingRulesListSmartSuggestionButton,
  showPreferredCategoriesSmartSuggestionButton,
  statusOptions,
  targetingRulesNotSetError,
  showManageSmartSuggestionButton,
  storyAdFilterOptions,
  showEditSmartSuggestionButton,
  convertUTCToIST,
  parseDateInMoment
} from "../../../config/configSmartSuggestions";

import {
  successModalHeading,
  errorModalHeading,
} from "../../../config/config";

import filterFactory from "react-bootstrap-table2-filter";
import Loading from "../../../components/Loading";
import paginationFactory from "react-bootstrap-table2-paginator";
import Search from "../../../components/Search";

import BootstrapTable from "react-bootstrap-table-next";

import {
  errorDescription,
  error,
  allActions,
  redirectOnTokenExpiry,
  replaceUnderscoreAndCapitalize
} from "../../../utilities/commonUtil";
import { getBrandCampaigns } from "../../../utilities/apiUtils/brandCampaign";
import { activateSmartSuggestion, deactivateSmartSuggestion, fetchSmartSuggestionData, publishSmartSuggestion, unpublishSmartSuggestion } from "../../../utilities/apiUtils/smartSuggestions";

const SmartSuggestions = () => {
  const [loading, setLoading] = useState(true);
  const [statusFilter, setStatusFilter] = useState("");
  const [storyAdFilter, setStoryAdFilter] = useState("");
  const [partnerFilter, setPartnerFilter] = useState([]);
  const [brandCampaignFilter, setBrandCampaignFilter] = useState([]);
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [allSmartSuggestions, setAllSmartSuggestions] = useState([])
  const [smartSuggestions, setSmartSuggestions] = useState([]);
  const [smartSuggestionFilter, setSmartSuggestionFilter] = useState("");
  const [partnerFilterOptions, setPartnerFilterOptions] = useState([]);
  const [brandCampaignsOptions, setBrandCampaignsOptions] = useState([]);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [action, setAction] = useState("");
  const [id, setId] = useState(null);
  const [adTitle, setAdTitle] = useState(null)
  const [successModalText, setSuccessModalText] = useState("");
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    setLoading(true)
    fetchSmartSuggestionsTableData()
  };

  let onTableChange = (type, newState) => { };

  let disablePublishButton = (row) => {
    return row.publishAt !== null;
  };

  let disableUnpublishButton = (row) => {
    return (row.status === "Deactivated") || (row.status === "Unpublished");
  };

  let disableDeactivateButton = (row) => {
    return (row.status === "Deactivated") || (row.status === "Unpublished");
  };

  let disableActivateButton = (row) => {
    return (row.status === "Activated") || (row.status === "Unpublished");
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  const handleClick = () => {
    setStatusFilter("");
    setStoryAdFilter("");
    setPartnerFilter([]);
    setBrandCampaignFilter([]);
    setSmartSuggestionFilter("")
    sessionStorage.removeItem("smartSuggestionFilter")
    sessionStorage.removeItem("smartSuggestionFilterStatus")
    sessionStorage.removeItem("smartSuggestionFilterStoryAd")
    sessionStorage.removeItem("smartSuggestionFilterPartner")
    sessionStorage.removeItem("smartSuggestionFilterBrandCampaigns")
  };

  const ClearButton = () => {
    return (
      <Button
        style={{ float: "right" }}
        className="form-control-lg"
        onClick={handleClick}
      >
        Reset Filters
      </Button>
    );
  };

  let setConfirmationModalState = (id, title, event) => {
    setAction(event);
    setConfirmationModalIsOpen(true);
    setId(id);
    setAdTitle(title)
  };

  const fetchBrandCampaigns = async () => {
    try {
      let response = await getBrandCampaigns()
      const options = []
      options.push({
        value: "none",
        label: "None",
        target: {
          name: "brandCampaign",
          value: {
            value: "none",
            label: "None"
          }
        }
      })
      if (response?.length > 0) {
        response.forEach(option => {
          options.push({
            value: option.value,
            label: option.label,
            target: {
              name: "brandCampaign",
              value: {
                value: option.value,
                label: option.label
              }
            }
          })
        })
      } else {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken ||
          response.errorCode === error.unauthorized
        )
          redirectOnTokenExpiry();
      }
      setBrandCampaignsOptions(options)
    } catch (err) {
      console.log(err);
    }
  }

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <Link
          className="text-decoration-none"
          to={{
            pathname: `/ads/smart-suggestions/edit/${row.id}`,
            state: {
              formType: "edit",
            },
          }}
        >
          <Button
            className="mx-3"
          >
            Edit
          </Button>
        </Link>

        {showUnpublishSmartSuggestionButton() && (
          <Button
            className="mx-3 btn-warning"
            disabled={disableUnpublishButton(row)}
            onClick={(event) =>
              setConfirmationModalState(row.id, row.title, event.target.innerHTML)
            }
          >
            Unpublish
          </Button>
        )}

        {showPublishSmartSuggestionButton() && (
          <Button
            className="mx-3  btn-success"
            disabled={disablePublishButton(row)}
            onClick={(event) =>
              setConfirmationModalState(row.id, row.title, event.target.innerHTML)
            }
          >
            Publish
          </Button>
        )}

        {showDeactivateSmartSuggestionButton() && (
          <Button
            className="mx-3 btn-danger"
            disabled={disableDeactivateButton(row)}
            onClick={(event) =>
              setConfirmationModalState(row.id, row.title, event.target.innerHTML)
            }
          >
            Deactivate
          </Button>
        )}

        {showActivateSmartSuggestionButton() && (
          <Button
            className="mx-3 btn-success"
            disabled={disableActivateButton(row)}
            onClick={(event) =>
              setConfirmationModalState(row.id, row.title, event.target.innerHTML)
            }
          >
            Activate
          </Button>
        )}

        {showTargetingRulesListSmartSuggestionButton() && (
          <Link
            className="text-decoration-none"
            to={{
              pathname: `/ads/smart-suggestions/targeting-rules/${row.id}`,
              state: {
                formType: "edit",
              },
            }}
          >
            <Button className="mx-3">Edit Targeting Rules</Button>
          </Link>
        )}

        {showEditSmartSuggestionButton() && (
          <Link
            className="text-decoration-none"
            to={{
              pathname: `/ads/smart-suggestions/duplicate/${row.id}`,
              state: {
                formType: "edit",
              },
            }}
          >
            <Button className="mx-3 btn-secondary">Duplicate</Button>
          </Link>
        )}

        <ConfirmationModal
          show={confirmationModalIsOpen}
          modaltext={action}
          onConfirmationTrue={() => {
            setConfirmationModalIsOpen(false);
            if (action === allActions.deactivate) deactivate(id, adTitle);
            else if (action === allActions.activate) activate(id, adTitle);
            else if (action === allActions.publish) {
              if (!row.isTargetingSet) {
                setFailureModalDisplay(true);
                setFailureModalText(targetingRulesNotSetError);
              } else {
                publish(id, adTitle)
              }
            }
            else if (action === allActions.unpublish) unpublish(id, adTitle)
          }}
          onHide={() => setConfirmationModalIsOpen(false)}
        />
        <ResponseModal
          show={successModalDisplay}
          onHide={successModalClose}
          modalheading={successModalHeading}
          modaltext={successModalText}
        />
        <ResponseModal
          show={failureModalDisplay}
          onHide={failureModalClose}
          modalheading={errorModalHeading}
          modaltext={failureModalText}
        />
      </div>
    ),
  };

  let deactivate = async (id, title) => {
    try {
      let response = await deactivateSmartSuggestion(id, title);
      if (response.status === "success") {
        let text = "Smart suggestion has been Deactivated";
        showSuccessModal(text);
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  };

  let activate = async (id, title) => {
    try {
      let response = await activateSmartSuggestion(id, title);
      if (response.status === "success") {
        let text = "Smart suggestion has been Activated";
        showSuccessModal(text);
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  };

  let publish = async (id, title) => {
    try {
      let response = await publishSmartSuggestion(id, title);
      if (response.status === "success") {
        let text = "Smart suggestion has been Published";
        showSuccessModal(text);
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  };

  let unpublish = async (id, title) => {
    try {
      let response = await unpublishSmartSuggestion(id, title);
      if (response.status === "success") {
        let text = "Smart suggestion has been Unpublished";
        showSuccessModal(text);
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  };

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let fetchSmartSuggestionsTableData = async () => {
    try {
      let response = await fetchSmartSuggestionData();
      if (response?.ads !== undefined) {
        response.ads.forEach((item) => {
          item = processDateAndStatus(item);
        });
        const uniquePartners = [
          ...Array.from(
            new Set(
              response?.ads
                ?.map(item => replaceUnderscoreAndCapitalize(item?.partner ?? ''))
                .filter(partner => partner)
            )
          ).map(value => ({
            value,
            label: value
          }))
        ];

        setPartnerFilterOptions(uniquePartners);
        setAllSmartSuggestions(response.ads);
        setLoading(false);
      } else {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken ||
          response.errorCode === error.unauthorized
        )
          redirectOnTokenExpiry();
        let text = errorDescription(response.errorCode);
        setLoading(false);
        showErrorModal(text);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  };

  const filterSmartSuggestions = () => {
    let items = smartSuggestions;
    if (statusFilter.value)
      items = items.filter((item) => item.status === statusFilter.value);

    if (storyAdFilter.value)
      items = storyAdFilter.value === "storyAds"
        ? items.filter((item) => item.storyAdId)
        : items.filter((item) => !item.storyAdId);


    if (smartSuggestionFilter)
      items = items.filter(item => item.title?.toLowerCase()?.includes(smartSuggestionFilter?.toLowerCase()) || item.id?.toString()?.toLowerCase()?.includes(smartSuggestionFilter?.toLowerCase()));

    let partnerFilterValues = [];
    if (partnerFilter?.length > 0) partnerFilterValues = partnerFilter.map(filter => filter.value);

    if (partnerFilterValues.length > 0)
      items = items.filter(item => partnerFilterValues.includes(replaceUnderscoreAndCapitalize(item.partner)));

    let brandCampaignFilterValues = [];
    if (brandCampaignFilter?.length > 0) brandCampaignFilterValues = brandCampaignFilter.map(filter => filter.value);

    if (brandCampaignFilterValues.length > 0)
      items = items.filter(item => brandCampaignFilterValues.includes(item.brandCampaignID));

    return items;
  };

  useEffect(() => {
    fetchSmartSuggestionsTableData();
    fetchBrandCampaigns();

    // Fill stored filter values
    if (sessionStorage.getItem("smartSuggestionFilter"))
      setSmartSuggestionFilter(sessionStorage.getItem("smartSuggestionFilter"))
    if (sessionStorage.getItem("smartSuggestionFilterStatus"))
      setStatusFilter({
        value: sessionStorage.getItem("smartSuggestionFilterStatus") ?? "",
        label: statusOptions.find(option => option.value === sessionStorage.getItem("smartSuggestionFilterStatus"))?.label ?? "",
      })
    if (sessionStorage.getItem("smartSuggestionFilterStoryAd"))
      setStoryAdFilter({
        value: sessionStorage.getItem("smartSuggestionFilterStoryAd") ?? "",
        label: storyAdFilterOptions.find(option => option.value === sessionStorage.getItem("smartSuggestionFilterStoryAd"))?.label ?? "",
      })
  }, []);


  useEffect(() => {
    if (partnerFilterOptions.length > 0 && sessionStorage.getItem("smartSuggestionFilterPartner"))
      setPartnerFilter(
        JSON.parse(sessionStorage.getItem("smartSuggestionFilterPartner")).map(filter => ({
          value: filter,
          label: partnerFilterOptions.find(option => option.value === filter)?.label ?? ""
        }))
      )
  }, [partnerFilterOptions]);

  useEffect(() => {
    if (brandCampaignsOptions.length > 0 && sessionStorage.getItem("smartSuggestionFilterBrandCampaigns"))
      setBrandCampaignFilter(
        JSON.parse(sessionStorage.getItem("smartSuggestionFilterBrandCampaigns")).map(filter => ({
          value: filter,
          label: brandCampaignsOptions.find(option => option.value === filter)?.label ?? ""
        }))
      )
  }, [brandCampaignsOptions]);

  useEffect(() => {
    if (allSmartSuggestions?.length > 0 && brandCampaignsOptions?.length > 0) {
      setSmartSuggestions(allSmartSuggestions.map(smartSuggestion => {
        const diffEnd = parseDateInMoment(smartSuggestion.endAt).startOf('day').diff(moment().startOf('day'), 'days')
        const diffStart = parseDateInMoment(smartSuggestion.startAt).startOf('day').diff(moment().startOf('day'), 'days')
        return {
          ...smartSuggestion,
          brandCampaignName: brandCampaignsOptions.find(option => option.value === smartSuggestion.brandCampaignID)?.label ?? smartSuggestion.brandCampaignID,
          startAt: convertUTCToIST(smartSuggestion.startAt),
          endAt: convertUTCToIST(smartSuggestion.endAt),
          timeline: smartSuggestion.status === "Deactivated" ? 0 : Math.abs(diffStart <= 0 ? diffEnd : diffStart)
        }
      }))
    }
  }, [allSmartSuggestions, brandCampaignsOptions])

  return (
    <Container fluid className="p-0">
      <Row>
        <h1 className="mb-4 mt-2 w-50 pl-3">Smart Suggestions</h1>

        <div className="d-flex align-items-center ml-auto">
          {showManageSmartSuggestionButton() && (
            <Link
              className="d-block mb-4 pr-3 text-decoration-none"
              to={{
                pathname: `/ads/smart-suggestions/manage-smart-suggestions`,
                state: {
                  formType: "create",
                },
              }}
            >
              <Button color="success" className="d-flex">
                <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                  Manage Smart Suggestions
                </p>
              </Button>
            </Link>
          )}
          {showPreferredCategoriesSmartSuggestionButton() && (
            <Link
              className="d-block mb-4 pr-3 text-decoration-none"
              to={{
                pathname: `/ads/smart-suggestions/preferred-categories`,
                state: {
                  formType: "create",
                },
              }}
            >
              <Button color="secondary" className="d-flex">
                <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                  Manage Preferred Clusters
                </p>
              </Button>
            </Link>
          )}
          {showOrderListSmartSuggestionButton() && (
            <Link
              className="d-block mb-4 pr-3 text-decoration-none"
              to={{
                pathname: `/ads/smart-suggestions/order`,
                state: {
                  formType: "create",
                },
              }}
            >
              <Button color="success" className="d-flex btn-success">
                <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                  Manage Order
                </p>
              </Button>
            </Link>
          )}
          {showAddSmartSuggestionButton() && (
            <Link
              className="d-block mb-4 pr-3 text-decoration-none"
              to={{
                pathname: `/ads/smart-suggestions/add`,
                state: {
                  formType: "create",
                },
              }}
            >
              <Button color="success" className="d-flex btn-success">
                <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                  Add Smart Suggestions
                </p>
              </Button>
            </Link>
          )}
        </div>
      </Row>
      <div>
        <Card style={{ overflow: "hidden", paddingBottom: "100px" }}>
          <div container-fullwidth="true" style={{ margin: "2%" }}>
            <Row className="justify-content-start align-items-center mx-auto mb-4 mt-3">
              <Col>
                <Select
                  className="react-select-container"
                  id="statusSelect"
                  classNamePrefix="react-select"
                  name="status"
                  placeholder="Select Status"
                  value={statusFilter}
                  onChange={(event) => {
                    setStatusFilter(event);
                    sessionStorage.setItem("smartSuggestionFilterStatus", event.value);
                  }}
                  options={statusOptions}
                />
              </Col>
              <Col>
                <Select
                  className="react-select-container"
                  id="storyAdSelect"
                  classNamePrefix="react-select"
                  name="storyAd"
                  placeholder="Select Story Ad"
                  value={storyAdFilter}
                  onChange={(event) => {
                    setStoryAdFilter(event);
                    sessionStorage.setItem("smartSuggestionFilterStoryAd", event.value);
                  }}
                  options={storyAdFilterOptions}
                />
              </Col>
              <Col>
                <Select
                  className="react-select-container"
                  id="partnerAdSelect"
                  classNamePrefix="react-select"
                  name="partner"
                  placeholder="Select Partner"
                  value={partnerFilter}
                  onChange={(selectedOptions) => {
                    setPartnerFilter(selectedOptions);
                    sessionStorage.setItem("smartSuggestionFilterPartner", JSON.stringify((selectedOptions ?? []).map(option => option.value)));
                  }}
                  options={partnerFilterOptions}
                  isMulti
                />
              </Col>
              <Col>
                <Select
                  className="react-select-container"
                  id="brandCampaignSelect"
                  classNamePrefix="react-select"
                  name="partner"
                  placeholder="Select Campaign"
                  value={brandCampaignFilter}
                  onChange={(selectedOptions) => {
                    setBrandCampaignFilter(selectedOptions);
                    sessionStorage.setItem("smartSuggestionFilterBrandCampaigns", JSON.stringify((selectedOptions ?? []).map(option => option.value)));
                  }}
                  options={brandCampaignsOptions}
                  isMulti
                />
              </Col>
              <Col>
                <ClearButton>Reset Filters</ClearButton>
              </Col>
            </Row>

            <Row className="justify-content-start mx-auto">
              <Col xs={12}>
                <Search
                  placeholder="Search ID or Name"
                  value={smartSuggestionFilter}
                  onChange={(e) => {
                    setSmartSuggestionFilter(e.target.value)
                    sessionStorage.setItem("smartSuggestionFilter", e.target.value)
                  }}
                ></Search>
              </Col>
            </Row>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <CardBody>
              <BootstrapTable
                bootstrap4={true}
                wrapperClasses="table-responsive"
                hover={true}
                bordered={false}
                keyField="id"
                data={filterSmartSuggestions()}
                columns={tableColumns}
                expandRow={expandRow}
                filter={filterFactory()}
                pagination={paginationFactory({
                  sizePerPage: 10,
                  sizePerPageList: [5, 10, 25, 50],
                })}
                defaultSorted={[{
                  dataField: 'id',
                  order: 'desc'
                }]}
                onTableChange={onTableChange}
                remote={{ filter: true }}
                noDataIndication="No results found"
              />
            </CardBody>
          )}
        </Card>
      </div>
    </Container>
  );
};

export default SmartSuggestions;

import moment from "moment";

const dateFormatter = (cell, row) => {
    return cell ? moment(cell).format("DD-MM-YYYY") : "-"
}
const defaultFormatter = (cell, row) => {
    return cell ? cell : "-"
}
export const statisticsTableColumns = [
    {
        dataField: "createdAt",
        text: "Date (DD-MM-YYYY)",
        sort: true,
        headerAttrs: { width: 200 },
        searchable: false,
        formatter: (cell, row) => dateFormatter(cell, row),
        style: {
            cursor: "pointer",
        },
    },
    {
        dataField: "internalId",
        text: "Internal ID",
        sort: true,
        headerAttrs: { width: 150 },
        searchable: false,
        formatter: (cell, row) => defaultFormatter(cell, row),
        style: {
            cursor: "pointer",
        },
    },
    {
        dataField: "statusCode",
        text: "Status Code",
        sort: true,
        headerAttrs: { width: 150 },
        searchable: false,
        formatter: (cell, row) => defaultFormatter(cell, row),
        style: {
            cursor: "pointer",
        },
    },
    {
        dataField: "source",
        text: "Source",
        headerAttrs: { width: 150 },
        searchable: false,
        formatter: (cell, row) => defaultFormatter(cell, row),
        style: {
            textOverflow: "ellipsis",
            overflow: "hidden",
            cursor: "pointer",
        },
    },
    {
        dataField: "count",
        text: "Count",
        sort: true,
        headerAttrs: { width: 150 },
        searchable: false,
        formatter: (cell, row) => defaultFormatter(cell, row),
        style: {
            textOverflow: "ellipsis",
        },
    },
];
import React from "react";
import {
  Badge,
  Card,
  CardBody,
  Button,
  CardHeader,
  Col,
  Row,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas, faUpload } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import {
  getABTestStatus,
  getABTestBtnName,
  getABTestButtonColor,
  getABTestStatusColor,
} from "../../../../utilities/commonUtil";
import { Config } from "../../../../config/UserConfig";

const ABTestCard = (props) => {
  const getIcon = (status) => {
    return faUpload;
  };

  let { test, name } = props;

  return (
    <Card>
      <CardHeader
        style={{ backgroundColor: "rgba(0, 185, 183, 0.7)" }}
        className="d-flex justify-content-center"
      >
        <h4 className="mb-0">{name}</h4>
      </CardHeader>
      <CardBody>
        {test.isABTestValid ? (
          <Badge
            color={getABTestStatusColor(
              getABTestStatus(test.publishedAt, test.completedAt)
            )}
            className="d-block float-right "
          >
            {getABTestStatus(test.publishedAt, test.completedAt)}
          </Badge>
        ) : (
          <Badge color="danger" className="d-block float-right ">
            Invalid Version
          </Badge>
        )}
        <span>
          <strong>ID:</strong> {test.id}
        </span>
        <br />
        <span>
          <strong>Keyboard Language Id:</strong> {test.keyboardLanguageId}
        </span>
        <br />
        <span>
          <strong>Existing Users Rollout Percentage:</strong> {test.existingUsersRolloutPercentage}
        </span>
        <br />
        <span>
          <strong>Published At:</strong> {test.publishedAt}
        </span>
        <br />
        <span>
          <strong>Completed At:</strong> {test.completedAt}
        </span>
        <br />
        <span>
          <strong>Description:</strong> {test.description}
        </span>
        <br />
        <Row className="mt-2">
          <Col md={6} className="my-2">
            <Link
              className="d-block ml-auto text-decoration-none"
              to={{
                pathname: `/kl/abtests/${test.id}`,
                state: {
                  name: name,
                  abTestId: test.id,
                  description: test.description,
                  existingUsersRolloutPercentage: test.existingUsersRolloutPercentage,
                  keyboardLanguageId: test.keyboardLanguageId,
                  completedAt: test.completedAt,
                  publishedAt: test.publishedAt,
                  isABTestValid: test.isABTestValid,
                },
              }}
            >
              <Button className="btn-block btn-info">Details</Button>
            </Link>
          </Col>
          <Col md={6} className="my-2">
            <Button
              color={getABTestButtonColor(
                getABTestStatus(test.publishedAt, test.completedAt)
              )}
              className="btn-block"
              disabled={
                getABTestStatus(test.publishedAt, test.completedAt) !==
                Config.UNPUBLISHED_STATUS
              }
              onClick={() => {
                props.setConfirmationModalState(
                  test.id,
                  getABTestBtnName(
                    getABTestStatus(test.publishedAt, test.completedAt)
                  ),
                  name + "-" + test.keyboardLanguageId
                );
              }}
            >
              {getABTestBtnName(
                getABTestStatus(test.publishedAt, test.completedAt)
              )}
              <FontAwesomeIcon
                icon={
                  (fas,
                    getIcon(getABTestStatus(test.publishedAt, test.completedAt)))
                }
                className="ml-2"
              ></FontAwesomeIcon>
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ABTestCard;

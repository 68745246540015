import React, { useEffect, useState } from 'react'
import { Modal, Button } from "react-bootstrap";
import { Row, Col, FormGroup, Label, Input, Form, CustomInput } from "reactstrap";
import Select, { components } from "react-select";
import CreatableSelect from 'react-select/creatable';
import { clientOptions, placementTypeOptions, adsMixingStrategyOptions } from '../../../../config/configConfigAPIDashboard';
import { fetchCreativeTypes } from '../../../../utilities/apiUtils/configAPI';
import { fetchPlacementLocations } from '../../../../utilities/apiUtils/placementLocations';
import { fetchClusters } from "../../../../utilities/apiUtils/configAPI";
import { fetchSmartSuggestionCategories } from "../../../../utilities/apiUtils/smartSuggestions";
import { replaceUnderscoreAndCapitalize } from '../../../../utilities/commonUtil';

const PlacementModal = ({ show = false, type, formData, setFormData = () => { }, onSubmit = () => { }, onHide = () => { } }) => {
  const [data, setData] = useState({
    id: "",
    location: "",
    clientId: "",
    summary: "",
    type: "",
    description: "",
    creativeTypeId: "",
    minAppVersion: "",
    maxAppVersion: "",
    excludeAppVersionList: [],
    adsMixingStrategy: "",
    isSupportedGaid: false,
    isSupportedStoryAds: false,
    category: [],
    cluster: [],
  })
  const [creativeTypeOptions, setCreativeTypeOptions] = useState([])
  const [locationOptions, setLocationOptions] = useState([])
  const [mixingStrategyOptions, setMixingStrategyOptions] = useState(adsMixingStrategyOptions)
  const [categoryOptions, setCategoryOptions] = useState([])
  const [clusterOptions, setClusterOptions] = useState([])
  const [error, setError] = useState({
    summary: ""
  })

  const handleHide = () => {
    setError({
      summary: ""
    })
    onHide()
  }

  const fetchCreativeTypeOptions = async () => {
    try {
      let response = await fetchCreativeTypes()
      if (response?.length > 0) {
        setCreativeTypeOptions(response.map(creativeType => ({
          value: creativeType.id,
          label: creativeType.name,
          target: {
            name: "creativeTypeId",
            value: {
              value: creativeType.id,
              label: creativeType.name
            }
          }
        })))
      }
    } catch (err) {
      console.log(err);
    }
  }

  const getLocationsOptions = async () => {
    try {
      let response = await fetchPlacementLocations();
      if (response?.length > 0) {
        setLocationOptions(response.map(location => ({
          value: location.value,
          label: location.name,
          target: {
            name: "location",
            value: {
              value: location.value,
              label: location.name
            }
          }
        })))
      } else {
        setLocationOptions([])
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCategoryOptions = async () => {
    try {
      let response = await fetchSmartSuggestionCategories()
      if (response?.categories?.length > 0) {
        setCategoryOptions(response.categories.map(category => ({
          value: category.id,
          label: replaceUnderscoreAndCapitalize(category.name),
          target: {
            name: "categoryId",
            value: {
              value: category.id,
              label: replaceUnderscoreAndCapitalize(category.name)
            }
          }
        })))
      }
    } catch (err) {
      console.log(err);
    }
  }

  const getclusterOptions = async () => {
    try {
      let response = await fetchClusters()
      if (response?.clusters?.length > 0) {
        setClusterOptions(response.clusters.map(cluster => ({
          value: cluster.id,
          label: replaceUnderscoreAndCapitalize(cluster.name),
          target: {
            name: "clusterId",
            value: {
              value: cluster.id,
              label: replaceUnderscoreAndCapitalize(cluster.name)
            }
          }
        })))
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchCreativeTypeOptions()
    getLocationsOptions()
    getCategoryOptions();
    getclusterOptions();
  }, [])

  useEffect(() => {
    if (formData?.id) {
      let summary = formData?.description?.split(" - ")[0], type = ""
      if (formData?.description?.split(" - ")?.length > 1) {
        type = formData?.description?.split(" - ")[1]
      }
      setData({
        id: formData?.id,
        location: {
          label: locationOptions.find(option => option.value === formData?.location)?.label ?? formData?.location,
          value: formData?.location
        },
        clientId: {
          label: clientOptions.find(option => option.value === formData?.clientId)?.label ?? formData?.clientId,
          value: formData?.clientId
        },
        summary,
        type: type ? {
          label: type,
          value: placementTypeOptions.find(option => option.label === type)?.value ?? type
        } : "",
        description: formData?.description,
        creativeTypeId: {
          label: creativeTypeOptions.find(option => option.value === formData?.creativeTypeId)?.label ?? formData?.creativeTypeId,
          value: formData?.creativeTypeId
        },
        minAppVersion: formData?.minAppVersion,
        maxAppVersion: formData?.maxAppVersion,
        excludeAppVersionList: formData?.excludeAppVersionList?.length > 0 ? formData?.excludeAppVersionList?.split(",").map(version => ({
          label: version,
          value: version
        })) : [],
        adsMixingStrategy: {
          label: adsMixingStrategyOptions.find(option => option.value === formData?.adsMixingStrategy)?.label ?? formData?.adsMixingStrategy,
          value: formData?.adsMixingStrategy
        },
        category: formData?.categoriesName?.length > 0 ? formData?.categoriesName?.split(",").map(categoryId => ({
          label: categoryOptions.find(option => option.value === Number(categoryId))?.label ?? categoryId,
          value: Number(categoryId),
        })) : [],
        cluster: formData?.clustersName?.length > 0 ? formData?.clustersName?.split(",").map(clusterId => ({
          label: clusterOptions.find(option => option.value === Number(clusterId))?.label ?? clusterId,
          value: Number(clusterId),
        })) : [],
        isSupportedGaid: formData?.isSupportedGaid,
        isSupportedStoryAds: formData?.isSupportedStoryAds
      })
    } else {
      setData({
        id: "",
        location: "",
        clientId: "",
        summary: "",
        type: "",
        description: "",
        creativeTypeId: "",
        minAppVersion: "",
        maxAppVersion: "",
        excludeAppVersionList: [],
        adsMixingStrategy: "",
        isSupportedGaid: false,
        isSupportedStoryAds: false,
        cluster: [],
        category: [],
      })
    }

  }, [formData, creativeTypeOptions, locationOptions, categoryOptions, clusterOptions])

  const handleChange = (event) => {
    const { name, value } = event.target
    if (name === "summary") {
      // Case 1: Summary is changed
      if (/^[a-zA-Z_ ]*$/.test(value)) {
        // Set description as "summary - type"
        setData((prevState) => ({
          ...prevState,
          [name]: value,
          description: value + " - " + (prevState.type?.label ?? "")
        }))
      }
    } else if (name === "type") {
      // Case 2: Placement type is changed
      if (value?.label === "Ads API" && data.adsMixingStrategy?.value === "ratio") {
        // Case 2.a: Placement type is "Ads API" and ads mixing strategy is already selected as "ratio" -> empty ads mixing strategy
        setData((prevState) => ({
          ...prevState,
          [name]: value,
          adsMixingStrategy: "",
          description: prevState.summary + " - " + (value?.label ?? "")
        }))
      } else {
        // Case 2.b: Handle placement type change
        // Set description as "summary - type"
        setData((prevState) => ({
          ...prevState,
          [name]: value,
          description: prevState.summary + " - " + (value?.label ?? "")
        }))
      }

      // If placement type is "Ads API" then "ratio" is not allowed
      setMixingStrategyOptions(value?.label === "Mediation SDK API"
        ? adsMixingStrategyOptions
        : adsMixingStrategyOptions.filter(option => option.value !== "ratio"))
    } else {
      // Default condition
      setData((prevState) => ({
        ...prevState,
        [name]: value
      }))
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (Number(data.minAppVersion) > Number(data.maxAppVersion))
      return
    if (!/^[a-zA-Z_ ]*$/.test(data.summary)) {
      setError((prevState) => ({
        ...prevState,
        summary: "Hyphens and numbers are not allowed"
      }))
      return
    }
    const payload = {
      id: data.id,
      location: data.location?.value,
      clientId: data.clientId?.value,
      description: data.description,
      creativeTypeId: data.creativeTypeId?.value,
      minAppVersion: data.minAppVersion,
      maxAppVersion: data.maxAppVersion,
      excludeAppVersionList: data.excludeAppVersionList?.length > 0 ? data.excludeAppVersionList?.map(version => version?.value).join(",") : "",
      adsMixingStrategy: data.adsMixingStrategy?.value,
      isSupportedGaid: data.isSupportedGaid,
      isSupportedStoryAds: data.isSupportedStoryAds,
      categoriesName: data.category?.length > 0
        ? data.category.map(item => item.value).join(",")
        : "",
      clustersName: data.cluster?.length > 0
        ? data.cluster.map(item => item.value).join(",")
        : "",
    }
    setFormData(payload)
    onSubmit(payload)
  }

  const requiredComponent = (props) => (
    <div>
      <components.Input {...props} required={true} />
    </div>
  );

  const notRequiredComponent = (props) => (
    <div>
      <components.Input {...props} required={false} />
    </div>
  );

  return (
    <Modal
      show={show}
      onHide={handleHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header className="bg-light" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {type} Placement
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row form>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Summary <span className="text-danger">*</span><small className='text-info'> (Hyphens and numbers are not allowed)</small>
                </Label>
                <Input
                  type="text"
                  name="summary"
                  placeholder="Enter Summary"
                  pattern="^[a-zA-Z_ ]*$"
                  value={data.summary}
                  onChange={handleChange}
                  required
                />
                {error.summary
                  ? (
                    <small className='text-danger'>{error.summary}</small>
                  ) : <></>
                }
              </FormGroup>
            </Col>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Placement Type <span className="text-danger">*</span>
                </Label>
                <Select
                  id="type"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="type"
                  placeholder="Select Placement Type"
                  value={data.type}
                  onChange={handleChange}
                  options={placementTypeOptions}
                  components={{
                    Input:
                      (data.type)
                        ? notRequiredComponent
                        : requiredComponent,
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col lg={12} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Description <span className="text-danger">*</span><small className="text-info"> (Combination of placement summary and type)</small>
                </Label>
                <Input
                  type="text"
                  name="description"
                  value={data.description}
                  onChange={handleChange}
                  disabled={true}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col lg={12} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Location <span className="text-danger">*</span>
                </Label>
                <Select
                  id="location"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="location"
                  placeholder="Select Location"
                  value={data.location}
                  onChange={handleChange}
                  options={locationOptions}
                  components={{
                    Input:
                      (data.location)
                        ? notRequiredComponent
                        : requiredComponent,
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Client ID <span className="text-danger">*</span>
                </Label>
                <Select
                  id="clientId"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="clientId"
                  placeholder="Select Client ID"
                  value={data.clientId}
                  onChange={handleChange}
                  options={clientOptions}
                  components={{
                    Input:
                      (data.clientId)
                        ? notRequiredComponent
                        : requiredComponent,
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Creative Type <span className="text-danger">*</span>
                </Label>
                <Select
                  id="creativeTypeId"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="creativeTypeId"
                  placeholder="Select Creative Type"
                  value={data.creativeTypeId}
                  onChange={handleChange}
                  options={creativeTypeOptions}
                  components={{
                    Input:
                      (data.creativeTypeId)
                        ? notRequiredComponent
                        : requiredComponent,
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Min App Version <span className="text-danger">*</span>
                </Label>
                <Input
                  type="number"
                  name="minAppVersion"
                  min={0}
                  placeholder="Enter Min App Version"
                  value={data.minAppVersion}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Max App Version <span className="text-danger">*</span>
                </Label>
                <Input
                  type="number"
                  name="maxAppVersion"
                  min={0}
                  placeholder="Enter Max App Version"
                  value={data.maxAppVersion}
                  onChange={handleChange}
                  required
                />
                {(data.minAppVersion && data.maxAppVersion && Number(data.minAppVersion) > Number(data.maxAppVersion)) &&
                  <small className='text-danger'>Max app version can't be less than min app version</small>
                }
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col lg={12} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Exclude Version List
                </Label>
                <CreatableSelect
                  id="excludeAppVersionList"
                  isMulti
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="excludeAppVersionList"
                  placeholder="Type versions and hit enter"
                  value={data.excludeAppVersionList}
                  onChange={(event) => {
                    setData((prevState) => ({
                      ...prevState,
                      excludeAppVersionList: event,
                    }))
                  }}
                  options={[]}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col lg={12} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Ads Mixing Strategy <span className="text-danger">*</span>
                </Label>
                <Select
                  id="adsMixingStrategy"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="adsMixingStrategy"
                  placeholder="Select Ads Mixing Strategy"
                  value={data.adsMixingStrategy}
                  onChange={handleChange}
                  options={mixingStrategyOptions}
                  components={{
                    Input:
                      (data.adsMixingStrategy)
                        ? notRequiredComponent
                        : requiredComponent,
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col lg={12} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Category
                </Label>
                <Select
                  id="category"
                  isMulti
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="category"
                  placeholder="Select category"
                  value={data.category}
                  onChange={(event) => {
                    setData((prevState) => ({
                      ...prevState,
                      category: event,
                    }))
                  }}
                  options={categoryOptions}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col lg={12} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Cluster
                </Label>
                <Select
                  id="cluster"
                  isMulti
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="cluster"
                  placeholder="Select cluster"
                  value={data.cluster}
                  onChange={(event) => {
                    setData((prevState) => ({
                      ...prevState,
                      cluster: event,
                    }))
                  }}
                  options={clusterOptions}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col lg={6} md={6}>
              <FormGroup className="d-flex align-items-center">
                <Label
                  className="font-weight-bolder mb-0"
                  style={{ fontSize: "14px" }}
                >
                  Is Supported GAID
                </Label>
                <div className="custom-control custom-switch">
                  <CustomInput
                    type="checkbox"
                    id="isSupportedGaid"
                    value={data.isSupportedGaid}
                    onChange={() => {
                      setData((prevState) => ({
                        ...prevState,
                        isSupportedGaid: !prevState.isSupportedGaid
                      }))
                    }}
                    checked={data.isSupportedGaid}
                  />
                </div>
              </FormGroup>
            </Col>
            <Col lg={6} md={6}>
              <FormGroup className="d-flex align-items-center">
                <Label
                  className="font-weight-bolder mb-0"
                  style={{ fontSize: "14px" }}
                >
                  Is Supported Story Ads
                </Label>
                <div className="custom-control custom-switch">
                  <CustomInput
                    type="checkbox"
                    id="isSupportedStoryAds"
                    value={data.isSupportedStoryAds}
                    onChange={() => {
                      setData((prevState) => ({
                        ...prevState,
                        isSupportedStoryAds: !prevState.isSupportedStoryAds
                      }))
                    }}
                    checked={data.isSupportedStoryAds}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleHide}>Close</Button>
          <Button type='submit' className='btn-success'>Submit</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default PlacementModal

export let validateCharacterMappingsArray = (characterMappings) => {

	// Check for empty characterMappings
	let validCharacterMappings = true;
	for (let i = 0; i < characterMappings.length; i++) {
		if (characterMappings[i].key === "" || characterMappings[i].value === "") {
			validCharacterMappings = false;
			break;
		}
	}

	if (characterMappings.length === 0) {
		validCharacterMappings = false;
	}

	return validCharacterMappings;
}

export let validateCharacterMappingsJSON = (characterMappings) => {
	if(characterMappings.length === 0) {
		return false;
	}
	try {
		if (characterMappings != null && characterMappings !== undefined && characterMappings.length !== 0) {
			JSON.parse(characterMappings);
			return true
		}
		return true;
	} catch (err) {
		return false;
	}
}
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { retrieveFromLocalStorage } from "../../../../utilities/localStorageUtil";
import { Config } from "../../../../config";
import {
  redirectToNewTab,
  getStatus,
  getStatusColor,
  getBtnName,
  getButtonColor,
  permissions,
} from "../../../../utilities/commonUtil";
import {
  Badge,
  Card,
  Container,
  CardImg,
  CardBody,
  CardTitle,
  CardFooter,
  Button,
  Row,
  Col,
} from "reactstrap";


export const QuickReplyCard = (props) => {
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );
  const [quickReply] = useState(props.reply);

  const showDetailsButton = () => {
    return allowedPermissions.includes(permissions.quickReplyDetail);
  };

  const showTargetingRulesButton = () => {
    return allowedPermissions.includes(
      permissions.quickReplyRulesView
    );
  };

  const showButton = (btnName) => {
    if (btnName === Config.ACTIVATE_BUTTON)
      return allowedPermissions.includes(permissions.quickReplyActivate);
    else if (btnName === Config.DEACTIVATE_BUTTON)
      return allowedPermissions.includes(permissions.quickReplyDeactivate);
    else if (btnName === Config.PUBLISH_BUTTON)
      return allowedPermissions.includes(permissions.quickReplyPublish);
    else return false;
  };

  const truncateText = (text, maxWords) => {
    const words = text.split(' ');
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(' ') + '...';
    }
    return text;
  };

  let status = getStatus(quickReply.publishedAt, quickReply.deactivatedAt);

  return (
    <Card style={{ height: '450px' }}>
      <CardBody>
        <CardTitle tag="h3" className="mb-0">
          {quickReply.quickReplyName}
          <Badge className="float-right" color={getStatusColor(status)}>
            {status}
          </Badge>
        </CardTitle>
        <small>ID: {quickReply.id}</small>
        <br />
        <small>Language Code: {quickReply.languageCode}</small>
        <br />
        <small>View Type: {quickReply.viewType}</small>
        <br />
        <br />
        <br />
        <strong className="font-weight-bold">
          {quickReply.text !== "" ? truncateText(quickReply.text, 40) : truncateText(quickReply.rawText, 40)}
        </strong>
        <br />
       
        
      </CardBody>
      <CardFooter>
        <Container className="my-4">
          <Row className="justify-content-center">
            <Col xs={12} sm={6} className="mb-2">
              {showDetailsButton() ? (
                <Button
                  className="mw-75 w-100"
                  color="info"
                  onClick={() => {
                    redirectToNewTab(`/quick-replies/${quickReply.id}`);
                  }}
                >
                  Details
                </Button>
              ) : null}
            </Col>
            <Col xs={12} sm={6} className="mb-2">
              {showButton(getBtnName(status)) ? (
                <Button
                  className="mw-100 w-100"
                  color={getButtonColor(status)}
                  onClick={() => {
                    props.getConfirmation(quickReply.id, getBtnName(status));
                  }}
                >
                  {getBtnName(status)}
                </Button>
              ) : null}
            </Col>
          </Row>
          {showTargetingRulesButton() && (
            <Row className="justify-content-center">
              <Col xs={12} sm={12} className="mb-2">
                <Button
                  className="mw-100 w-100"
                  color="secondary"
                  style={{
                    width: "100%",
                  }}
                  onClick={() => {
                    redirectToNewTab(`/quick-reply/targeting-rules/${quickReply.id}`);
                  }}
                >
                  Edit Targeting Rules
                </Button>
              </Col>
            </Row>
          )}
        </Container>
      </CardFooter>
    </Card>
  );
};

export const QuickReplyCardType = (props) => {

  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );
  const [quickReply] = useState(props.reply);

  const showDetailsButton = () => {
    return allowedPermissions.includes(permissions.quickReplyDetail);
  };

  const showCloneQuickReplyButton = () => {
    return allowedPermissions.includes(permissions.quickReplyDetail) && allowedPermissions.includes(permissions.quickReplyCreate);
  };

  const showTargetingRulesButton = () => {
    return allowedPermissions.includes(
      permissions.quickReplyRulesView
    );
  };


  const showButton = (btnName) => {
    if (btnName === Config.ACTIVATE_BUTTON)
      return allowedPermissions.includes(permissions.quickReplyActivate);
    else if (btnName === Config.DEACTIVATE_BUTTON)
      return allowedPermissions.includes(permissions.quickReplyDeactivate);
    else if (btnName === Config.PUBLISH_BUTTON)
      return allowedPermissions.includes(permissions.quickReplyPublish);
    else return false;
  };

  let status = getStatus(quickReply.publishedAt, quickReply.deactivatedAt);

  const truncateText = (text, maxWords) => {
    const words = text.split(' ');
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(' ') + '...';
    }
    return text;
  };

  return (
    <Card style={{ height: '450px' }}>
      <CardBody>
      <CardTitle tag="h3" className="mb-0">
          {quickReply.quickReplyName}
          <Badge className="float-right" color={getStatusColor(status)}>
            {status}
          </Badge>
        </CardTitle>
        
          <small>ID: {quickReply.id}</small>
         
          <br />
          <small>Language Code: {quickReply.languageCode}</small>
          <br />
          <small>View Type: {quickReply.viewType}</small>
        <Card
          style={{ flexDirection: 'row', padding: '0.5rem', height: '200px' }}
        >
          {quickReply.imageURL ? (
            <div style={{ alignItems: "center" }}>
              <CardImg
                src={quickReply.imageURL}
                alt="Card image"
                style={{
                  width: "200px",
                  height: "150px",
                  paddingLeft: "10px",
                  marginBottom: "5px",
                }}
              />
            </div>
          ) : (
            <div style={{ alignItems: "center" }}>
              <CardImg
                src={"/img/no-image-icon.png"}
                alt="Card image"
                style={{
                  width: "200px",
                  height: "150px",
                  paddingLeft: "10px",
                  marginBottom: "5px",
                }}
              />
            </div>
          )}
          <CardBody style={{ width: "120px" }}>
            <div style={{ width: "100px" }}>
              {quickReply?.textDetails?.filter((card) => card.type === "header").length > 0 && (
                <div>
                  <h5>
                    {quickReply?.textDetails
                      ?.filter((card) => card.type === "header")
                      .map((card, index) => (
                        <span key={index}>{card.text}</span>
                      ))}
                  </h5>
                </div>
              )}

              {!quickReply?.textDetails?.some((card) => card.type === "header") && (
                <div style={{ height: "50px" }}></div>
              )}

              {quickReply?.textDetails?.filter((card) => card.type === "paragraph").length > 0 && (
                <div>
                  <p style={{ fontSize: '10px' }}>
                    {quickReply?.textDetails
                      ?.filter((card) => card.type === "paragraph")
                      .map((card, index) => (
                        <span key={index}>{truncateText(card.text, 4)}</span>
                      ))}
                  </p>
                </div>
              )}

              {!quickReply?.textDetails?.some((card) => card.type === "paragraph") && (
                <div style={{ height: "50px" }}></div>
              )}

              {quickReply?.textDetails?.filter((card) => card.type === "subText").length > 0 && (
                <div>
                  <p style={{ fontSize: '10px' }}>
                    {quickReply?.textDetails
                      ?.filter((card) => card.type === "subText")
                      .map((card, index) => (
                        <span key={index}>{truncateText(card.text, 4)}</span>
                      ))}
                  </p>
                </div>
              )}
            </div>
          </CardBody>


        </Card>

      </CardBody>

      <CardFooter>
        <Container className="my-4">
          <Row className="justify-content-center">
            <Col xs={12} sm={6} className="mb-2">
              {showDetailsButton() ? (
                <Button
                  className="mw-75 w-100"
                  color="info"
                  onClick={() => {
                    redirectToNewTab(`/quick-replies/${quickReply.id}`);
                  }}
                >
                  Details
                </Button>
              ) : null}
            </Col>
            <Col xs={12} sm={6} className="mb-2">
              {showButton(getBtnName(status)) ? (
                <Button
                  className="mw-100 w-100"
                  color={getButtonColor(status)}
                  onClick={() => {
                    props.getConfirmation(quickReply.id, getBtnName(status));
                  }}
                >
                  {getBtnName(status)}
                </Button>
              ) : null}
            </Col>
          </Row>
          {showTargetingRulesButton() && (
            <Row className="justify-content-center">
              <Col xs={12} sm={12} className="mb-2">
                <Button
                  className="mw-100 w-100"
                  color="secondary"
                  style={{
                    width: "100%",
                  }}
                  onClick={() => {
                    redirectToNewTab(`/quick-reply/targeting-rules/${quickReply.id}`);
                  }}
                >
                  Edit Targeting Rules
                </Button>
              </Col>
            </Row>
          )}
          {showCloneQuickReplyButton() && quickReply.viewType === "card" &&  (
            <Row className="justify-content-center">
              <Col xs={12} sm={12} className="mb-2">
                <Link
                  to={{
                    pathname: `/quick-reply/create-quick-reply?id=${quickReply.id}`,
                    formName: "duplicate",
                    id: quickReply.id,
                  }}
                  target="_blank" // Open in a new tab
                >
                  <Button
                    className="mw-100 w-100"
                    color="primary"
                    style={{
                      width: "100%",
                    }}
                  >
                    Clone
                  </Button>
                </Link>
              </Col>
            </Row>
          )}

        </Container>
      </CardFooter>
    </Card>
  )
}
import React, { useState, useEffect } from "react";
import Search from "../../../components/Search";
import Select from "react-select";
import { StoryListingCard } from "./components/StoryListingCard";
import ResponseModal from "../ResponseModal";
import ConfirmationModal from "../ConfirmationModal";
import {
  Col,
  Container,
  Row,
  Button,
  Spinner,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { Config } from "../../../config";
import {
  getStoriesData,
  activateStoryData,
  deactivateStoryData,
  getStoryDetailsData,
  publishStoryData,
  getBucketData,
  updateBulkStoriesData,
  getMultipleStoryDetailsData,
} from "../../../utilities/apiUtils/stories";
import { successModalHeading, errorModalHeading } from "../../../config/config";
import {
  storyCreatePermission,
  storyUpdatePermission,
  storyViewPermission,
  storyListPermission,
  storiesBulkCreatePermission,
  storiesBulkUpdatePermission,
  sortByOptions,
  statusOptions,
} from "../../../config/configStoryDashboard";

import {
  errorDescription,
  error,
  getStatus,
  allActions,
  permissions,
  redirectionOnTokenExpiry,
  getStatusColor,
  getButtonColor,
  getBtnName,
  redirectOnTokenExpiry,
  redirectTo,
} from "../../../utilities/commonUtil";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { Link } from "react-router-dom";
import EmptyStoriesDisplay from "./components/EmptyStoriesDisplay";
import { faUpload, faTimes } from "@fortawesome/free-solid-svg-icons";
import UncontrolledButtonDropdown from "reactstrap/lib/UncontrolledButtonDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import { capitalize } from "@material-ui/core";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import UserStoriesPropertiesModal from "./components/UserStoriesPropertiesModal";

const Stories = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const bucketId = searchParams.get("bucketId");
  const [bucketList, setBucketList] = useState([]);
  const bucketParams = bucketList?.find(
    (bucket) => bucket.value === searchParams.get("bucketId")
  );
  const statusParams = statusOptions.find(
    (status) => status.value === searchParams.get("status")
  );
  const sortByParams = sortByOptions.find(
    (options) => options.value === searchParams.get("sortBy")
  );
  const isSelectAllCheckedParams =
    searchParams.get("selectAll") === "true" ? true : false;
  const searchStringParams = searchParams.get("searchString");
  const history = useHistory();

  const [id, setID] = useState(null);
  const [action, setAction] = useState("");
  const [stories, setStories] = useState([]);
  const [searchString, setSearchString] = useState(
    searchStringParams !== null ? searchStringParams : ""
  );
  const [bucket, setBucket] = useState(
    bucketParams !== undefined ? bucketParams : { value: -1, label: "All" }
  );
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [hasMoreStories, setHasMoreStories] = useState(false);
  const [noStoriesFound, setNoStoriesFound] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [successModalState, setSuccessModalState] = useState({
    successModalDisplay: false,
    successModalText: "",
  });
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );
  const [status, setStatus] = useState(
    statusParams !== undefined ? statusParams : statusOptions[0]
  );
  const [sortBy, setSortBy] = useState(
    sortByParams !== undefined ? sortByParams : sortByOptions[0]
  );
  const [searchBy, setSearchBy] = useState(Config.SEARCH_BY_TITLE);
  const [loading, setLoading] = useState(true);
  const [firstRender, setFirstRender] = useState(true);
  const [selectedStories, setSelectedStories] = useState([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(
    isSelectAllCheckedParams ? isSelectAllCheckedParams : false
  );
  const [updateBulkStories, setUpdateBulkStories] = useState({});
  const [showUserStoriesPropertiesModal, setShowUserStoriesPropertiesModal] =
    useState(false);
  
  const [searchTitle, setSearchTitle] = useState("");
  const [searchTag, setSearchTag] = useState("");
  const [searchId, setSearchId] = useState("");
  const [templateType, setTemplateType] = useState({ value: "all", label: "All" });

  useEffect(() => {
    getStories();
    getBucketList();
    setFirstRender(false);
  }, []);

  useEffect(() => {
    if (bucket?.label === "All") {
      setLimit(20);
    } else {
      setLimit(500);
    }
  }, [bucket]);

  useEffect(() => {
    const fetchData = async () => {
      let params = {
        storyBucketId: bucket?.label !== "All" ? bucket.value : "",
        searchString: searchTitle,
        id: searchId,
        tag: searchTag,
        status: status?.label !== "All" ? status.value : "",
        sortBy: sortBy.value,
        page: page,
        limit: bucket?.label === "All" ? 20 : 500,
        templateType: templateType ? templateType.value : "",
            };
      let response = await getStoriesData(params);
      if (response.stories !== null) {
        let allStoriesData = stories.concat(response.stories);
        setStories(allStoriesData);
        if (response?.stories?.length < limit) {
          setHasMoreStories(false);
        } else setHasMoreStories(true);
      } else {
        setHasMoreStories(false);
      }
    };

    if (!firstRender && page > 0) {
      fetchData();
    }
  }, [page]);

  const getStories = async () => {
    let params = {
      storyBucketId: bucket.label !== "All" ? bucket.value : "",
      searchString: searchTitle,
      id: searchId,
      tag: searchTag,
      status: status.label !== "All" ? status.value : "",
      sortBy: sortBy.value,
      page: 0,
      limit: bucket.label === "All" ? 20 : 500,
      templateType: templateType ? templateType.value : "",
        };

    try {
      let response = await getStoriesData(params);

      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectionOnTokenExpiry();
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: response.errorDescription,
        });
      } else if (response.stories) {
        if (response.stories !== null) {
          setStories(response.stories);
          setLoading(false);
          if (bucket?.label !== "All" && isSelectAllChecked) {
            // If bucket and select are available, set all stories as selected
            setSelectedStories(response.stories.map((story) => story.id));
            setIsSelectAllChecked(true);
          }
          if (response.stories.length == limit) {
            setHasMoreStories(true);
          }
          return;
        }
      }
      setLoading(false);
      setNoStoriesFound(true);
    } catch (err) {
      setLoading(false);
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const getBucketList = async () => {
    try {
      let response = await getBucketData();
      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectionOnTokenExpiry();
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: response.errorDescription,
        });
      } else {
        setBucketList(response);
        if (bucketId) {
          if (!bucketId) setBucket({ id: bucketId, name: "All" });
          else setBucket(response.find((item) => item.value == bucketId));
        } else {
          setBucket(response[0]);
        }
      }
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const handleSearchStringChange = (event) => {
    event.preventDefault();
    console.log(event.target);
    let { placeholder, value } = event.target;
    if (placeholder === "Search by Title") setSearchTitle(value);
    if (placeholder === "Search by ID") setSearchId(value);
    if (placeholder === "Search by Tag") setSearchTag(value);
    if (!value.length) {
      setSearchString("");
      search();
      return;
    }
  };

  useEffect(() => {
    if (!firstRender) {
      const searchParams = new URLSearchParams();

      if (bucket?.label !== "All") searchParams.set("bucketId", bucket?.value);
      else searchParams.set("bucketId", "");

      searchParams.set("status", status?.value);
      searchParams.set("sortBy", sortBy?.value);
      searchParams.set("searchString", searchTitle);
      searchParams.set("id", searchId);
      searchParams.set("tag", searchTag);
      searchParams.set("selectAll", isSelectAllChecked);
      searchParams.set("templateType", templateType?.value);

      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
      search();
    }
  }, [bucket, status, sortBy, searchId, searchTitle, searchTag, templateType]);

  useEffect(() => {
    if (!firstRender) {
      const searchParams = new URLSearchParams();
      searchParams.set("selectAll", isSelectAllChecked);
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    }
  }, [isSelectAllChecked]);

  const handleBucketChange = (bucket) => {
    setBucket(bucket);
    setIsSelectAllChecked(false);
    setSelectedStories([]);
  };

  const handleStatusChange = (status) => {
    setStatus(status);
  };

  const handleOrderChange = (sortBy) => {
    setSortBy(sortBy);
  };

  const fetchMoreData = async () => {
    if (searchBy === "id") return;
    setPage(page + 1);
    setNoStoriesFound(false);
    setHasMoreStories(true);
  };

  const search = async (localSearchBy) => {
    setPage(0);
    setNoStoriesFound(false);
    setHasMoreStories(false);
    setSearchBy(localSearchBy || searchBy);
    setLoading(true);
    setStories([]);

    let params = {
      storyBucketId: bucket.label !== "All" ? bucket.value : "",
      searchString: searchTitle,
      id: searchId,
      tag: searchTag,
      status: status.label !== "All" ? status.value : "",
      sortBy: sortBy.value,
      page: 0,
      limit: bucket.label === "All" ? 20 : 500,
      templateType: templateType ? templateType.value : "",
    };

    try {
      let response = await getStoriesData(params);
      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectionOnTokenExpiry();
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: response.errorDescription,
        });
      } else if (response.stories != null) {
        setStories(response.stories);
        setLoading(false);
        if (bucket?.label !== "All" && isSelectAllChecked) {
          // If bucket and select are available, set all stories as selected
          setSelectedStories(response.stories.map((story) => story.id));
          setIsSelectAllChecked(true);
        }
        if (response?.stories?.length == limit) {
          setHasMoreStories(true);
        }
        return;
      }
      setLoading(false);
      setNoStoriesFound(true);
    } catch (err) {
      setLoading(false);
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const setConfirmationModalState = (id, btnName) => {
    setID(id);
    setAction(btnName);
    setConfirmationModalIsOpen(true);
  };

  const performAction = () => {
    if (action === allActions.publish) {
      publish(id);
    } else if (action === allActions.deactivate) {
      deactivate(id);
    } else if (action === allActions.activate) {
      activate(id);
    } else if (action === "Update Stories Property") {
      handleUpdateBulkStories(updateBulkStories);
    }
  };

  const UpdateBulkStoriesData = async (data) => {
    try {
      const response = await getMultipleStoryDetailsData(data);
      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectionOnTokenExpiry();
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: response.errorDescription,
        });
      } else {
        setStories((prevStories) => {
          return prevStories.map((story) => {
            const updatedStoryData = response.stories.find(
              (updatedStory) => updatedStory.id === story.id
            );
            if (updatedStoryData) {
              // Update the publishedAt and deactivatedAt properties if present in the updated data
              const updatedStory = {
                ...story,
                publishedAt: updatedStoryData.publishedAt,
                deactivatedAt: updatedStoryData.deactivatedAt,
              };
              return updatedStory;
            }
            return story; // Return the original story if not updated
          });
        });
        setIsSelectAllChecked(false);
      }
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const handleTemplateTypeChange = (option) => {
    setTemplateType(option); // Just update the state, no need to call search directly
  };

  const handleUpdateBulkStories = async (data) => {
    await updateBulkStoriesData(data).then((response) => {
      if (response.status === "success") {
        setSuccessModalState({
          successModalDisplay: true,
          successModalText: response.message,
        });
        onHideUserStoriesPropertiesModal();
        setSelectedStories([]);
        UpdateBulkStoriesData(selectedStories);
      } else {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: response.errorDescription,
        });
      }
    });
  };

  const handleResponse = async (response, id) => {
    if (!response.errorCode) {
      // If response indicates success
      setSuccessModalState({
        successModalDisplay: true,
        successModalText: response.message,
      });
    } else {
      // If there's an error
      if (
        response.errorCode === error.tokenExpired ||
        response.errorCode === error.invalidAccessToken
      ) {
        redirectionOnTokenExpiry();
      }
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(response.errorCode),
      });
    }

    // If response contains new story data
    if (response) {
      try {
        const newData = await getStoryDetailsData(id);
        // Update the stories state
        if (newData.errorCode) {
          if (
            newData.errorCode === newData.tokenExpired ||
            newData.errorCode === newData.invalidAccessToken
          )
            redirectionOnTokenExpiry();
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: errorDescription(newData.errorDescription),
          });
        } else {
        setStories((prevStories) => {
          // Check if the new data matches any existing story by ID
          const updatedStories = prevStories.map((story) =>
            story.id === newData.id
              ? {
                  ...story,
                  publishedAt: newData.publishedAt,
                  deactivatedAt: newData.deactivatedAt,
                }
              : story
          );
          return updatedStories;
        });
      }
      } catch (error) {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      }
    }
  };

  const publish = async (id) => {
    try {
      let response = await publishStoryData(id);
      handleResponse(response, id);
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const deactivate = async (id) => {
    try {
      let response = await deactivateStoryData(id);
      handleResponse(response, id);
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const activate = async (id) => {
    try {
      let response = await activateStoryData(id);
      handleResponse(response, id);
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const failureModalClose = () => {
    setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
  };

  const successModelClose = () => {
    setSuccessModalState((prev) => ({
      ...prev,
      successModalDisplay: false,
    }));
  };

  const getIcon = (status) => {
    if (status === "Deactivated") return faUpload;
    return faTimes;
  };

  const handleSelectAll = () => {
    if (isSelectAllChecked) {
      setSelectedStories([]);
    } else {
      setSelectedStories(stories.map((reply) => reply.id));
    }
    setIsSelectAllChecked(!isSelectAllChecked);
  };

  const getBucketName = (bucketId) => {
    const bucket = bucketList.find((item) => item.value == bucketId);
    return bucket ? bucket.label : "All";
  };

  const onHideUserStoriesPropertiesModal = () => {
    setShowUserStoriesPropertiesModal(false);
  };

  const handleUpdateTargetingRules = (e) => {
    e.preventDefault();
    const data = selectedStories.map((story) => story).join(",");
    redirectTo(`/stories/targetingRules?storyIds=${data}`);
  };

  const renderAllReplies = () => {
    if (stories.length === 0 && !loading) {
      return <EmptyStoriesDisplay />;
    } else {
      return stories.map((reply) => {
        let status = getStatus(reply.publishedAt, reply.deactivatedAt);
        let color = getStatusColor(status);
        let btnColor = getButtonColor(status);
        let btnName = getBtnName(status);
        return (
          <Col md="6" lg="4" xxl="3" key={reply.id}>
            <StoryListingCard
              reply={reply}
              color={color}
              status={status}
              btnName={btnName}
              btnColor={btnColor}
              btnIcon={getIcon(status)}
              getConfirmation={setConfirmationModalState}
              selectedStories={selectedStories}
              setSelectedStories={setSelectedStories}
              bucket={bucket}
              isChecked={isSelectAllChecked}
              stories={stories}
              setIsSelectAllChecked={setIsSelectAllChecked}
            />
          </Col>
        );
      });
    }
  };

  const onKeyDown = (event) => {
    if (event.keyCode === Config.KEYPRESS_DOWN) {
      search();
    }
  };

  return (
    <Container fluid className="p-0">
      <Row className="align-items-center">
        <Col md={3} sm={6}>
          <h1 className="mb-3 mt-4">Stories</h1>
        </Col>
        <>
        
          <Col md={9} sm={12} className="d-flex flex-row-reverse">
            {storiesBulkUpdatePermission() ? (
              <UncontrolledButtonDropdown className="my-auto float-right mx-2">
                <DropdownToggle
                  disabled={selectedStories.length == 0}
                  caret
                  color="secondary"
                >
                  {"Bulk Operations"}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={(e) => {
                      setShowUserStoriesPropertiesModal(true);
                    }}
                  >
                    Update Story Properties
                  </DropdownItem>
                  <DropdownItem
                    onClick={(e) => {
                      handleUpdateTargetingRules(e);
                    }}
                  >
                    Update Targeting Rules
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            ) : null}
            {storiesBulkCreatePermission() ? (
              <Link
                className=" my-auto text-decoration-none mx-2"
                to={{
                  pathname: `/stories/bulkUpload`,
                }}
              >
                <Button color="primary" className="ml-auto d-flex">
                  <p className="my-0">Create Bulk Stories</p>
                </Button>
              </Link>
            ) : null}
            {storyCreatePermission() ? (
              <Link
                className=" my-auto text-decoration-none mx-2"
                to={{
                  pathname: `/stories/create-stories`,
                }}
              >
                <Button color="primary" className="ml-auto d-flex">
                  <p className="my-0">Create Story</p>
                </Button>
              </Link>
            ) : null}
          </Col>
        </>
      </Row>
      <Row>
        <Col md={4} sm={12}>
          <Search
            placeholder="Search by Title"
            value={searchTitle}
            onChange={handleSearchStringChange}
            onKeyDown={onKeyDown}
          />
        </Col>
        <Col md={4} sm={12}>
          <Input
            type="number"
            placeholder="Search by ID"
            value={searchId}
            onChange={handleSearchStringChange}
            onKeyDown={onKeyDown}
          />
        </Col>
        <Col md={4} sm={12}>
          <Search
            placeholder="Search by Tag"
            value={searchTag}
            onChange={handleSearchStringChange}
            onKeyDown={onKeyDown}
          />
        </Col>
      </Row>
      <Row>
        <Col md={3} sm={12}>
          <Label>Select Bucket</Label>
          <Select
            id="bucketList"
            className="react-select-container mb-4"
            classNamePrefix="react-select"
            value={bucket}
            placeholder={bucket ? getBucketName(bucket) : "Select Bucket"}
            onChange={(option) => handleBucketChange(option)}
            options={bucketList}
          />
        </Col>
        <Col md={3} sm={12}>
          <Label>Select Status</Label>
          <Select
            className="react-select-container mb-4"
            classNamePrefix="react-select"
            placeholder="Select Status"
            value={status}
            onChange={(option) => handleStatusChange(option)}
            options={statusOptions}
          />
        </Col>
        <Col md={3} sm={12}>
          <Label>Sort By</Label>
          <Select
            className="react-select-container mb-4"
            classNamePrefix="react-select"
            placeholder={"Sort By"}
            value={sortBy}
            onChange={(option) => handleOrderChange(option)}
            options={sortByOptions}
          />
        </Col>
        <Col md={3} sm={12}>
          <Label>Select Template Type</Label>
          <Select
            id="templateTypeList"
            className="react-select-container mb-4"
            classNamePrefix="react-select"
            value={templateType}
            placeholder={templateType ? templateType.label : "Select Template Type"}
            onChange={(option) => handleTemplateTypeChange(option)}
            options={[
              { value: "all", label: "All" },
              { value: "default", label: "Default" },
              { value: "ai_template", label: "AI Template" },
            ]}
          />
        </Col>

        <Col md={3} sm={12} className="d-flex align-items-end">
          {bucket.label !== "All" && (
            <FormGroup className="mb-4">
              <Button
                color={"primary"}
                onClick={handleSelectAll}
                onBlur={(e) => e.target.blur()}
                className="d-flex"
              >
                <input
                  type="checkbox"
                  className="d-flex align-items-center mr-2"
                  checked={isSelectAllChecked}
                  style={{ height: "20px", width: "20px" }}
                />
                <span>Select All</span>
              </Button>
            </FormGroup>
          )}
        </Col>
      </Row>

      <InfiniteScroll
        className="infinite-scroll"
        dataLength={stories.length}
        next={fetchMoreData}
        hasMore={hasMoreStories}
        loader={
          !loading &&
          searchBy !== "id" &&
          noStoriesFound && <Spinner color="info" className=" d-flex mx-auto" />
        }
      >
        {loading ? (
          <Spinner color="info" className=" d-flex mx-auto" />
        ) : noStoriesFound ? (
          <Container
            sm="12"
            md="8"
            lg="6"
            className="mx-auto d-table mt-5 pt-2 pb-5"
          >
            <h1 className="text-center">No reply found</h1>
          </Container>
        ) : (
          <React.Fragment>
            {storyListPermission ? (
              <Row className="mt-2">{renderAllReplies()}</Row>
            ) : null}
            <UserStoriesPropertiesModal
              show={showUserStoriesPropertiesModal}
              onHide={onHideUserStoriesPropertiesModal}
              selectedStories={selectedStories}
              setConfirmationModalIsOpen={setConfirmationModalIsOpen}
              ac
              setAction={setAction}
              setStatus={status.value}
              setUpdateBulkStories={setUpdateBulkStories}
            />
            <ResponseModal
              show={successModalState.successModalDisplay}
              onHide={() => successModelClose()}
              modalheading={successModalHeading}
              modaltext={successModalState.successModalText}
            />
            <ResponseModal
              show={failureModalState.failureModalDisplay}
              onHide={() => failureModalClose()}
              modalheading={errorModalHeading}
              modaltext={failureModalState.failureModalText}
            />
            <ConfirmationModal
              show={confirmationModalIsOpen}
              modaltext={action}
              onHide={() => {
                setConfirmationModalIsOpen(false);
              }}
              onConfirmationTrue={() => {
                setConfirmationModalIsOpen(false);
                performAction();
              }}
            />
          </React.Fragment>
        )}
      </InfiniteScroll>
    </Container>
  );
};

export default Stories;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { retrieveFromLocalStorage } from "../../../../utilities/localStorageUtil";
import { Config } from "../../../../config";
import {
  redirectToNewTab,
  getStatusOfBtn,
  getStatusColor,
  getBtnName,
  getButtonColor,
  permissions,
  redirectTo,
} from "../../../../utilities/commonUtil";
import {
  Badge,
  Card,
  Container,
  CardImg,
  CardBody,
  CardTitle,
  CardFooter,
  Button,
  Row,
  Col,
  Input,
} from "reactstrap";
import {
  storyActivatePermission,
  storyDeactivatePermission,
  storyPublishPermission,
  storyViewPermission,
  storyCTAUpdatePermission,
  storyTargetingRuleViewPermission,
  sotdCreatePermission,
  storyUserAttributePermission,
  getProcessingStatus,
  getProcessingStatusColor,
  getdateValue,
} from "../../../../config/configStoryDashboard";

export const StoryListingCard = (props) => {
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );
  const [story, setStory] = useState(props.reply);
  const [isChecked, setIsChecked] = useState(props.isChecked);

  const showButton = (btnName) => {
    if (btnName === Config.ACTIVATE_BUTTON) return storyActivatePermission();
    else if (btnName === Config.DEACTIVATE_BUTTON)
      return storyDeactivatePermission();
    else if (btnName === Config.PUBLISH_BUTTON) return storyPublishPermission();
    else return false;
  };
  function getBadgeText(status) {
    switch (status) {
      case "legacy_dashboard":
        return "Legacy Dashboard";
      case `bobble_dashboard`:
        return "Bobble Dashboard";
      default:
        return status; // Return the raw status if no match
    }
  }
  let status = getStatusOfBtn(
    story.publishedAt ? story.publishedAt : null,
    story.deactivatedAt ? story.deactivatedAt : null
  );

  const handleImgRedirect = function (req, res) {
    redirectTo(`/stories/edit-stories/${story.id}`);
  };

  useEffect(() => {
    setStory(props.reply);
  }, [props.reply]);

  const handleSelectedStories = (e) => {
    const { checked } = e.target;
    setIsChecked(checked);
    if (story.sourceIdentifier != "legacy_dashboard") {
      // Update the list of selected stories based on the checkbox state
      if (checked) {
        // Add the story ID to the list of selected stories
        props.setSelectedStories((prev) => [...prev, story.id]);
        // If all stories are selected, set the select all checkbox to checked
        if (props.selectedStories.length === props.stories.length - 1) {
          props.setIsSelectAllChecked(true);
        }
      } else {
        // Remove the story ID from the list of selected stories
        props.setSelectedStories((prev) =>
          prev.filter((id) => id !== story.id)
        );
        props.setIsSelectAllChecked(false);
      }
    }
  };

  return (
    <Card>
      <Container className="d-flex justify-content-center">
        <Input
          type="checkbox"
          className="importPageCheckbox"
          id={story.id}
          checked={props.selectedStories.includes(story.id)}
          onChange={(event) => handleSelectedStories(event)}
        />
        
        {story.contentType === "video" ? (
        story.videoPreviewURL ? (
          <video
            src={story.videoPreviewURL}
            controls
            controlsList="nodownload noremoteplayback"
            className="p-2"
            style={{ height: "400px", cursor: "pointer", width: "100%" }}
          />
        ) : story.staticPreviewURL ? (
          <CardImg
            top
            src={story.staticPreviewURL ?? ""}
            alt="Card image cap"
            className="p-2"
            style={{
              height: "400px",
              width: "100%",
              maxWidth: "250px",
              cursor: "pointer",
            }}
            onClick={handleImgRedirect}
          />
        ) : (
          <CardImg
            src={"/img/no-image-icon.png"}
            alt="Card image"
            style={{
              height: "400px",
              paddingLeft: "10px",
              marginBottom: "5px",
            }}
          />
        )
      ) : story.contentType === "static" && story.staticPreviewURL ? (
        <CardImg
          top
          src={story.staticPreviewURL ?? ""}
          alt="Card image cap"
          className="p-2"
          style={{
            height: "400px",
            width: "100%",
            maxWidth: "250px",
            cursor: "pointer",
          }}
          onClick={handleImgRedirect}
        />
      ) : (
        <CardImg
          src={"/img/no-image-icon.png"}
          alt="Card image"
          style={{
            height: "400px",
            paddingLeft: "10px",
            marginBottom: "5px",
          }}
        />
      )}
      </Container>

      <CardBody>
        <div tag="h3" className="mb-0">
          <Badge className="float-right" color={getStatusColor(status)}>
            {status}
          </Badge>
        </div>
        <small>ID: {story?.id}</small>
        <br />
        <small>Title: {story?.title}</small>
        <br />
        <small>Start At: {getdateValue(story?.startAt)}</small>
        <br />
        <small>End At: {getdateValue(story?.endAt)}</small>
        <br />
        <small>Published At: {getdateValue(story?.publishedAt)}</small>
        <br />
        <small>Deactivated At: {getdateValue(story?.deactivatedAt)}</small>
        <br />
        <small>AI Story: {story?.isAiTemplate ? "Yes" : "No"}</small>
        <br />
        <small>
          Processing Status:
          <Badge
            className="ml-2"
            color={getProcessingStatusColor(story?.imageProcessingStatus)}
          >
            {getProcessingStatus(story?.imageProcessingStatus)}
          </Badge>
        </small>
        <br />
        <small>
          Source Identifier: {getBadgeText(story?.sourceIdentifier)}
        </small>
      </CardBody>

      <CardFooter>
        <Container className="">
          <Row className="justify-content-center">
            {storyViewPermission() ? (
              <Col xs={12} xl={6} className="mb-2">
                <Button
                  className="mw-75 w-100"
                  color="info"
                  onClick={() => {
                    redirectTo(`/stories/edit-stories/${story.id}`);
                  }}
                >
                  Details
                </Button>
              </Col>
            ) : null}
            {story?.sourceIdentifier != "legacy_dashboard" &&
            showButton(getBtnName(status)) ? (
              <Col xs={12} xl={6} className="mb-2">
                <Button
                  className="mw-100 w-100"
                  color={getButtonColor(status)}
                  onClick={() => {
                    props.getConfirmation(story.id, getBtnName(status));
                  }}
                >
                  {getBtnName(status)}
                </Button>
              </Col>
            ) : null}
            {story?.sourceIdentifier != "legacy_dashboard" &&
              storyTargetingRuleViewPermission() && (
                <Col xs={12} xl={6} className="mb-2">
                  <Button
                    className="mw-100 w-100"
                    color="secondary"
                    style={{
                      width: "100%",
                    }}
                    onClick={() => {
                      redirectTo(`/stories/targetingRules/${story.id}`);
                    }}
                  >
                    Targeting Rules
                  </Button>
                </Col>
              )}
            {storyCTAUpdatePermission() && (
              <Col xs={12} xl={6} className="mb-2">
                <Button
                  className="mw-100 w-100"
                  color="primary"
                  style={{
                    width: "100%",
                  }}
                  onClick={() => {
                    redirectTo(`/stories/${story.id}/edit-cta`);
                  }}
                >
                  Update CTA
                </Button>
              </Col>
            )}
            {sotdCreatePermission() && (
              <Col xs={12} xl={6} className="mb-2">
                <Button
                  className="mw-100 w-100"
                  color="secondary"
                  style={{
                    width: "100%",
                  }}
                  onClick={() => {
                    redirectTo(`/stories/sotd/create/?id=${story.id}`);
                  }}
                >
                  Add to SOTD
                </Button>
              </Col>
            )}

            {storyUserAttributePermission() && (
              <Col xs={12} xl={6} className="mb-2">
                <Button
                  className="mw-100 w-100"
                  color="secondary"
                  style={{
                    width: "100%",
                  }}
                  onClick={() => {
                    redirectTo(`/stories/user-attribute-rules/${story.id}`);
                  }}
                >
                  User Attribute Rules
                </Button>
              </Col>
            )}
          </Row>
        </Container>
      </CardFooter>
    </Card>
  );
};

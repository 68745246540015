import React, { useState, useEffect } from "react";
import {
    Col,
    Row,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Container,
    Button,
    Collapse
} from "reactstrap";
import { trackersTypeOptions, invalidColorCodeResponse } from "../../../../config/quickReplies";
import { getQuickRepliesAssets } from "../../../../utilities/apiUtils/quickReplies"
import { X } from "react-feather";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import Reorder, { reorder } from "react-reorder";
import { Modal } from "react-bootstrap";
import QuickReplyCTAActions from "./QuickReplyCTAActions";
import ResponseModal from "../../../../pages/ui-elements/ResponseModal";

const QuickReplyCTAItems = ({ quickReplyTypeCard, setQuickReplyTypeCard, clientOptions, formName, updateAllowed, disableAddEditActionButton, validateColor }) => {
    const [ctaItemsToggle, setCtaItemsToggle] = useState(true);
    const [ctaItemsClickTrackersToggle, setCtaItemsClickTrackersToggle] = useState(true);
    const [showCTAItemSection, setShowCTAItemSection] = useState(false);
    const [showCTAItemReorderModal, setShowCTAItemReorderModal] = useState(false);
    const [showCTAItemSectionDetails, setShowCTAItemSectionDetails] = useState(
        formName === "edit" && !updateAllowed
    );
    const [ctaItemsByClientID, setCtaItemsByClientID] = useState([]);
    const [ctaItemIndexForEdit, setCtaItemIndexForEdit] = useState(null);
    const [ctaItem, setCtaItem] = useState({
        text: null,
        widthPercentage: null,
        lightThemeBackgroundImage: null,
        lightThemeBackgroundImageName: null,
        enableLightThemeBackgroundImageDropdown: false,
        darkThemeBackgroundImage: null,
        darkThemeBackgroundImageName: null,
        enableDarkThemeBackgroundImageDropdown: false,
        lightThemeIconImage: null,
        lightThemeIconImageName: null,
        enableLightThemeIconImageDropdown: false,
        darkThemeIconImage: null,
        darkThemeIconImageName: null,
        enableDarkThemeIconImageDropdown: false,
        textColor: null,
        darkThemeTextColor: null,
        clientId: { value: "global", label: "Global" },
        priority: null,
        clickTrackers: [],
        ctaActions: [],
    });
    const [quickRepliesAssetsOptions, setQuickRepliesAssetsOptions] = useState([]);
    const [failureModalState, setFailureModalState] = useState({
        failureModalDisplay: false,
        failureModalText: "",
    });

    useEffect(() => {
        const getQuickRepliesAssetsData = async () => {
            const response = await getQuickRepliesAssets();
            if (response) {
                const quickRepliesAssetsOptions = response?.assets?.map((item) => {
                    return {
                        value: item.imageFilename,
                        label: <div>
                            <img src={item.imageURL} height="30px" width={item.type==="icon" ? "30" : "70"} alt={item.imageIdentifier}/>    {item.imageIdentifier}
                        </div>,
                        url: item.imageURL,
                        theme: item.theme,
                        type: item.type,
                    };
                });
                setQuickRepliesAssetsOptions(quickRepliesAssetsOptions);
            }
        }
        getQuickRepliesAssetsData();
    }, []);


    const addCTAItems = () => {
        setCtaItem({
            text: null,
            widthPercentage: null,
            lightThemeBackgroundImage: null,
            lightThemeBackgroundImageName: null,
            enableLightThemeBackgroundImageDropdown: false,
            darkThemeBackgroundImage: null,
            darkThemeBackgroundImageName: null,
            enableDarkThemeBackgroundImageDropdown: false,
            lightThemeIconImage: null,
            lightThemeIconImageName: null,
            enableLightThemeIconImageDropdown: false,
            darkThemeIconImage: null,
            darkThemeIconImageName: null,
            enableDarkThemeIconImageDropdown: false,
            textColor: null,
            darkThemeTextColor: null,
            clientId: { value: "global", label: "Global" },
            priority: null,
            clickTrackers: [],
            ctaActions: [],
        });
        setShowCTAItemSection(true);
        setShowCTAItemSectionDetails(false);
        setCtaItemIndexForEdit(null);
    }

    const getUniqueClientIdsWithName = () => {
        let uniqueClientIds = [];
        let uniqueClientIdsWithName = [];
        if (quickReplyTypeCard.ctaItems !== undefined && quickReplyTypeCard.ctaItems !== null) {
            quickReplyTypeCard.ctaItems.map((item, index) => {
                if (item.clientId) {
                    uniqueClientIds.push(item.clientId.value);
                }
            })
        }

        uniqueClientIds = [...new Set(uniqueClientIds)];
        uniqueClientIds.map((item, index) => {
            clientOptions.map((client, index) => {
                if (client.value === item) {
                    uniqueClientIdsWithName.push(client);
                }
            })
        })
        return uniqueClientIdsWithName;
    }

    const checkCTAItemValidity = () => {
        return !!(
            ctaItem.text === null ||
            ctaItem.text === "" ||
            ctaItem.widthPercentage === null ||
            ctaItem.widthPercentage === "" ||
            ctaItem.lightThemeBackgroundImageName === null ||
            ctaItem.lightThemeBackgroundImageName === "" ||
            ctaItem.darkThemeBackgroundImageName === null ||
            ctaItem.darkThemeBackgroundImageName === "" ||
            ctaItem.lightThemeIconImageName === null ||
            ctaItem.lightThemeIconImageName === "" ||
            ctaItem.darkThemeIconImageName === null ||
            ctaItem.darkThemeIconImageName === "" ||
            // and so on for other fields

            ctaItem.clientId === null ||
            ctaItem.clientId === ""
        );
    }

    const onCTAItemsReorder = (
        event,
        previousIndex,
        nextIndex,
        fromId,
        toId
    ) => {
        setCtaItemsByClientID(reorder(ctaItemsByClientID, previousIndex, nextIndex));
    }

    const setCTAItemStatus = () => {

        if (showCTAItemSectionDetails === true) {
            return "CTA Item";
        } else if (ctaItemIndexForEdit === null) {
            return "Add CTA Item";
        } else {
            return "Edit CTA Item";
        }
    }

    const saveCTAItems = () => {
        let inputsWithError = document.getElementsByClassName("is-invalid");
        if (inputsWithError.length !== 0) {
            setFailureModalState({
                failureModalDisplay: true,
                failureModalText: invalidColorCodeResponse,
            });
            return;
        }


        if (ctaItemIndexForEdit !== null) {
            // Add priority to the cta item
            ctaItem.priority = quickReplyTypeCard.ctaItems.length + 1;
            let temp = [...quickReplyTypeCard.ctaItems];
            temp[ctaItemIndexForEdit] = ctaItem;
            setQuickReplyTypeCard({
                ...quickReplyTypeCard,
                ctaItems: temp,
            })
            setShowCTAItemSection(false);
            setCtaItem({
                text: null,
                widthPercentage: null,
                lightThemeBackgroundImage: null,
                enableLightThemeBackgroundImageDropdown: false,
                darkThemeBackgroundImage: null,
                enableDarkThemeBackgroundImageDropdown: false,
                lightThemeIconImage: null,
                enableLightThemeIconImageDropdown: false,
                darkThemeIconImage: null,
                enableDarkThemeIconImageDropdown: false,
                textColor: null,
                darkThemeTextColor: null,
                clientId: null,
                priority: null,
                clickTrackers: [],
                ctaActions: [],
            });
            setCtaItemIndexForEdit(null);
            return;
        }

        if (quickReplyTypeCard.ctaItems === undefined) {
            quickReplyTypeCard.ctaItems = [];
        }

        // Add priority to the cta item
        ctaItem.priority = quickReplyTypeCard.ctaItems.length + 1;

        setQuickReplyTypeCard({
            ...quickReplyTypeCard,
            ctaItems: [...quickReplyTypeCard.ctaItems, ctaItem]
        })
        setShowCTAItemSection(false);
        setCtaItem({
            text: null,
            widthPercentage: null,
            lightThemeBackgroundImage: null,
            enableLightThemeBackgroundImageDropdown: false,
            darkThemeBackgroundImage: null,
            enableDarkThemeBackgroundImageDropdown: false,
            lightThemeIconImage: null,
            enableLightThemeIconImageDropdown: false,
            darkThemeIconImage: null,
            enableDarkThemeIconImageDropdown: false,
            textColor: null,
            darkThemeTextColor: null,
            clientId: null,
            priority: null,
            clickTrackers: [],
            ctaActions: [],
        });
    }

    const allowEdit = () => {
        return formName === "edit" && !updateAllowed;
    }

    return (
        <>
            <Row className="mt-6">
                <Col md={6}>
                    <h1 className="h3 mb-3">CTA Items</h1>
                </Col>
                <Col md={6}>
                    <div
                        className="float-right"
                        onClick={(e) => {
                            setCtaItemsToggle(!ctaItemsToggle);
                        }}
                    >
                        {ctaItemsToggle ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                    <Button onClick={addCTAItems} className="float-right mr-2" disabled={allowEdit()}>
                        <FontAwesomeIcon size="sm" icon={faPlus} />
                        {" "}Add CTA
                    </Button>

                </Col>
            </Row>


            <Collapse isOpen={ctaItemsToggle}>
                <Card>
                    <CardBody>
                        {getUniqueClientIdsWithName().map((client, index) => (
                            <>
                                <Row key={`row-${index + 6}`}>
                                    <Col md={6}>
                                        <h1 className="h3 mb-3">
                                            {client.label}
                                        </h1>
                                    </Col>
                                    <Col md={6}>
                                        <Button disabled={allowEdit()} className="float-right btn-warning" onClick={
                                            (e) => {
                                                setCtaItemsByClientID(quickReplyTypeCard.ctaItems.filter((item, index) => item.clientId?.value === client?.value));
                                                setShowCTAItemReorderModal(true);
                                            }
                                        }>
                                            Reorder
                                        </Button>
                                    </Col>
                                </Row>

                                <Row key={`row-${index + 7}`}>
                                    {quickReplyTypeCard.ctaItems?.map((item, index) => {
                                        return item?.clientId?.value === client.value && (
                                            <Col md={6}>
                                                <Card
                                                    key={`card-${index + 6}`}
                                                    className="p-3 mb-5"
                                                    style={{
                                                        boxShadow: '0 0 20px rgba(0,0,0,0.4)',  // Increased shadow
                                                        borderRadius: '15px'
                                                    }}
                                                >
                                                    <CardBody>
                                                        <h5>CTA Text: {item.text}
                                                            {!(allowEdit()) && (
                                                                <X
                                                                    className="float-right"
                                                                    size={20}
                                                                    color="red"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={(e) => {
                                                                        let temp = [...quickReplyTypeCard.ctaItems];
                                                                        temp = temp.filter((_, idx) => idx !== index);
                                                                        setQuickReplyTypeCard({
                                                                            ...quickReplyTypeCard,
                                                                            ctaItems: temp,
                                                                        });
                                                                    }}
                                                                ></X>
                                                            )}

                                                        </h5>
                                                        <p>Width Percentage: {item.widthPercentage}</p>
                                                        <p>Text Color: {item.textColor} <div
                                                            style={{
                                                                backgroundColor:
                                                                    item.textColor,
                                                                width: "16px",
                                                                height: "16px",
                                                                display: "inline-block",
                                                                verticalAlign: "middle",
                                                                border: "1px solid #000",
                                                                borderRadius: "30%",
                                                            }}
                                                        >
                                                            {" "}
                                                        </div></p>
                                                        <p>Dark Theme Text Color: {item.darkThemeTextColor} <div
                                                            style={{
                                                                backgroundColor:
                                                                    item.darkThemeTextColor,
                                                                width: "16px",
                                                                height: "16px",
                                                                display: "inline-block",
                                                                verticalAlign: "middle",
                                                                border: "1px solid #000",
                                                                borderRadius: "30%",
                                                            }}
                                                        >
                                                            {" "}
                                                        </div></p>
                                                    </CardBody>
                                                    <Row>
                                                        <Col md={4} className="d-flex justify-content-center">
                                                            <Button className="btn-block" color="primary" onClick={(e) => {
                                                                setShowCTAItemSectionDetails(true);
                                                                setCtaItem(item);
                                                                setShowCTAItemSection(true);
                                                            }}>View Details</Button>
                                                        </Col>
                                                        <Col md={4} className="d-flex justify-content-center">
                                                            <Button className="btn-block"
                                                                disabled={allowEdit()} color="success" onClick={(e) => {
                                                                    setQuickReplyTypeCard({
                                                                        ...quickReplyTypeCard,
                                                                        ctaItems: [...quickReplyTypeCard.ctaItems, item]
                                                                    })
                                                                }
                                                                }>Duplicate</Button>
                                                        </Col>
                                                        <Col md={4} className="d-flex justify-content-center">
                                                            <Button className="btn-block"
                                                                disabled={allowEdit()} color="info" onClick={(e) => {
                                                                    setShowCTAItemSectionDetails(false);
                                                                    setCtaItem(item);
                                                                    setShowCTAItemSection(true);
                                                                    setCtaItemIndexForEdit(index);
                                                                }}>Edit</Button>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </>
                        ))}
                    </CardBody >
                </Card>
            </Collapse>

            {(showCTAItemSection || showCTAItemSectionDetails) && (
                <Card>
                    <CardBody>
                        {showCTAItemSection && (
                            <Container fluid className="p-0">
                                <Row>
                                    <Col>
                                        <h1 className="h3 mb-3">
                                            <strong>
                                                {setCTAItemStatus()}
                                            </strong>
                                            <X
                                                className="float-right"
                                                size={20}
                                                color="red"
                                                style={{ cursor: "pointer" }}
                                                onClick={(e) => {
                                                    setShowCTAItemSection(false);
                                                    setShowCTAItemSectionDetails(false);
                                                    setCtaItemIndexForEdit(null);
                                                    setCtaItem({
                                                        text: null,
                                                        widthPercentage: null,
                                                        lightThemeBackgroundImage: null,
                                                        enableLightThemeBackgroundImageDropdown: false,
                                                        darkThemeBackgroundImage: null,
                                                        enableDarkThemeBackgroundImageDropdown: false,
                                                        lightThemeIconImage: null,
                                                        enableLightThemeIconImageDropdown: false,
                                                        darkThemeIconImage: null,
                                                        enableDarkThemeIconImageDropdown: false,
                                                        textColor: null,
                                                        darkThemeTextColor: null,
                                                        clientId: { value: "global", label: "Global" },
                                                        priority: null,
                                                        clickTrackers: [],
                                                    });
                                                }}

                                            ></X>
                                        </h1>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>
                                                <strong>
                                                    Text{" "}<span class="text-danger">*</span>
                                                </strong>
                                            </Label>
                                            <Input
                                                type="text"
                                                name="text"
                                                value={ctaItem.text === null ? null : ctaItem.text}
                                                placeholder="Enter Text"
                                                disabled={showCTAItemSectionDetails}
                                                onChange={(option) => {
                                                    setCtaItem({
                                                        ...ctaItem,
                                                        text: option.target.value,
                                                    });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>
                                                <strong>
                                                    Width Percentage{" "}<span class="text-danger">*</span>
                                                </strong>
                                            </Label>
                                            <Input
                                                type="number"
                                                name="widthPercentage"
                                                value={ctaItem.widthPercentage === null ? null : ctaItem.widthPercentage}
                                                placeholder="Enter Width Percentage"
                                                disabled={showCTAItemSectionDetails}
                                                onChange={(option) => {
                                                    setCtaItem({
                                                        ...ctaItem,
                                                        widthPercentage: option.target.value,
                                                    });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>
                                                <strong>
                                                    Light Theme Icon Image{" "} <span className="text-danger">*</span>

                                                    <p style={{ fontSize: 12, marginBottom: 0 }}>
                                                        The image should be jpg/png/gif/webp
                                                    </p>
                                                </strong>

                                            </Label>
                                            <Button
                                                className="float-right btn-sm"
                                                onClick={(e) => {
                                                    setCtaItem({
                                                        ...ctaItem,
                                                        enableLightThemeIconImageDropdown: !ctaItem.enableLightThemeIconImageDropdown
                                                    })
                                                }
                                                }
                                                disabled={showCTAItemSectionDetails}
                                            >
                                                {ctaItem.enableLightThemeIconImageDropdown ? "Upload new image" : "Select from Uploaded Images"}
                                            </Button>

                                            <br />


                                            {!ctaItem.enableLightThemeIconImageDropdown && (
                                                <Container className="custom-file">
                                                    <Input
                                                        type="file"
                                                        className="custom-file-input"
                                                        accept="image/*"
                                                        disabled={showCTAItemSectionDetails}
                                                        onChange={(event) => {
                                                            const file = event.target.files[0];
                                                            const reader = new FileReader();

                                                            reader.onloadend = () => {
                                                                setCtaItem({
                                                                    ...ctaItem,
                                                                    lightThemeIconImage: reader.result,
                                                                    lightThemeIconImageName: file.name
                                                                });
                                                            }
                                                            reader.readAsDataURL(file);
                                                        }}
                                                    />
                                                    <Label className="custom-file-label">
                                                        <strong>{ctaItem.lightThemeIconImageName === null ? "Choose an image" : ctaItem.lightThemeIconImageName}</strong>
                                                    </Label>
                                                </Container>
                                            )}

                                            {ctaItem.enableLightThemeIconImageDropdown && (
                                                <Select
                                                    className="react-select-container"
                                                    classNamePrefix="react-select"
                                                    name="lightThemeIconImage"
                                                    value={
                                                        quickRepliesAssetsOptions.filter((item) => item.value === ctaItem.lightThemeIconImageName)
                                                    }
                                                    placeholder="Select Uploaded Images"
                                                    isClearable={false}
                                                    onChange={(option) => {
                                                        setCtaItem({
                                                            ...ctaItem,
                                                            lightThemeIconImageName: option.value,
                                                            lightThemeIconImageURL: option.url
                                                        });
                                                    }}
                                                    isDisabled={showCTAItemSectionDetails}
                                                    options={quickRepliesAssetsOptions.filter((item) => item.theme === "light" && item.type === "icon")}
                                                />
                                            )}


                                            {(ctaItem.lightThemeIconImage || ctaItem.lightThemeIconImageURL) && (
                                                <Col className="pb-4 mb-3 mt-3">
                                                    <img src={ctaItem.lightThemeIconImage || ctaItem.lightThemeIconImageURL} alt="preview" width="100" />
                                                </Col>

                                            )}

                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>
                                                <strong>
                                                    Dark Theme Icon Image{" "} <span className="text-danger">*</span>
                                                    <p style={{ fontSize: 12, marginBottom: 0 }}>
                                                        The image should be jpg/png/gif/webp
                                                    </p>
                                                </strong>
                                            </Label>
                                            <Button
                                                className="float-right btn-sm"
                                                onClick={(e) => {
                                                    setCtaItem({
                                                        ...ctaItem,
                                                        enableDarkThemeIconImageDropdown: !ctaItem.enableDarkThemeIconImageDropdown
                                                    })
                                                }
                                                }
                                                disabled={showCTAItemSectionDetails}
                                            >
                                                {ctaItem.enableDarkThemeIconImageDropdown ? "Upload new image" : "Select from Uploaded Images"}
                                            </Button>

                                            <br />
                                            {!ctaItem.enableDarkThemeIconImageDropdown && (
                                                <Container className="custom-file">
                                                    <Input
                                                        type="file"
                                                        className="custom-file-input"
                                                        accept="image/*"
                                                        disabled={showCTAItemSectionDetails}
                                                        onChange={(event) => {
                                                            const file = event.target.files[0];
                                                            const reader = new FileReader();

                                                            reader.onloadend = () => {
                                                                setCtaItem({
                                                                    ...ctaItem,
                                                                    darkThemeIconImage: reader.result,
                                                                    darkThemeIconImageName: file.name
                                                                });
                                                            }
                                                            reader.readAsDataURL(file);
                                                        }
                                                        }
                                                    />
                                                    <Label className="custom-file-label">
                                                        <strong>{ctaItem.darkThemeIconImageName === null ? "Choose an image" : ctaItem.darkThemeIconImageName}</strong>
                                                    </Label>
                                                </Container>
                                            )}

                                            {ctaItem.enableDarkThemeIconImageDropdown && (
                                                <Select
                                                    className="react-select-container"
                                                    classNamePrefix="react-select"
                                                    name="darkThemeIconImage"
                                                    value={quickRepliesAssetsOptions.filter((item) => item.value === ctaItem.darkThemeIconImageName)}
                                                    onChange={(option) => {
                                                        setCtaItem({
                                                            ...ctaItem,
                                                            darkThemeIconImageName: option.value,
                                                            darkThemeIconImageURL: option.url
                                                        });
                                                    }}
                                                    placeholder="Select Uploaded Images"
                                                    isClearable={false}
                                                    isDisabled={showCTAItemSectionDetails}
                                                    options={quickRepliesAssetsOptions.filter((item) => item.theme === "dark" && item.type === "icon")}
                                                />
                                            )}


                                            {(ctaItem.darkThemeIconImage || ctaItem.darkThemeIconImageURL) && (
                                                <Col className="pb-4 mb-3 mt-3">
                                                    <img src={ctaItem.darkThemeIconImage || ctaItem.darkThemeIconImageURL} alt="preview" width="100" />
                                                </Col>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>
                                                <strong>
                                                    Light Theme Background Image{" "} <span className="text-danger">*</span>
                                                    <p style={{ fontSize: 12, marginBottom: 0 }}>
                                                        The image should be jpg/png/gif/webp
                                                    </p>
                                                </strong>
                                            </Label>
                                            <Button
                                                className="float-right btn-sm"
                                                onClick={(e) => {
                                                    setCtaItem({
                                                        ...ctaItem,
                                                        enableLightThemeBackgroundImageDropdown: !ctaItem.enableLightThemeBackgroundImageDropdown
                                                    })
                                                }
                                                }
                                                disabled={showCTAItemSectionDetails}
                                            >
                                                {ctaItem.enableLightThemeBackgroundImageDropdown ? "Upload new image" : "Select from Uploaded Images"}
                                            </Button>

                                            <br />
                                            {!ctaItem.enableLightThemeBackgroundImageDropdown && (
                                                <Container className="custom-file">
                                                    <Input
                                                        type="file"
                                                        className="custom-file-input"
                                                        accept="image/*"
                                                        disabled={showCTAItemSectionDetails}
                                                        onChange={(event) => {
                                                            const file = event.target.files[0];
                                                            const reader = new FileReader();

                                                            reader.onloadend = () => {
                                                                setCtaItem({
                                                                    ...ctaItem,
                                                                    lightThemeBackgroundImage: reader.result,
                                                                    lightThemeBackgroundImageName: file.name
                                                                });
                                                            }
                                                            reader.readAsDataURL(file);
                                                        }}
                                                    />
                                                    <Label className="custom-file-label">
                                                        <strong>{ctaItem.lightThemeBackgroundImageName === null ? "Choose an image" : ctaItem.lightThemeBackgroundImageName}</strong>
                                                    </Label>

                                                </Container>
                                            )}

                                            {ctaItem.enableLightThemeBackgroundImageDropdown && (
                                                
                                                <Select
                                                    style={{
                                                        control: base => ({
                                                            ...base,
                                                            height: 35,
                                                            minHeight: 35
                                                          })
                                                    }}
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    name="lightThemeBackgroundImage"
                                                    value={quickRepliesAssetsOptions.filter((item) => item.value === ctaItem.lightThemeBackgroundImageName)}
                                                    placeholder="Select Uploaded Images"
                                                    onChange={(option) => {
                                                        setCtaItem({
                                                            ...ctaItem,
                                                            lightThemeBackgroundImageName: option.value,
                                                            lightThemeBackgroundImageURL: option.url
                                                        });
                                                    }}
                                                    isClearable={false}
                                                    isDisabled={showCTAItemSectionDetails}
                                                    options={quickRepliesAssetsOptions.filter((item) => item.theme === "light" && item.type === "background_image")}
                                                />
                                                
                                            )}



                                            {(ctaItem.lightThemeBackgroundImage || ctaItem.lightThemeBackgroundImageURL) && (
                                                <Col className="pb-4 mb-3 mt-3">
                                                    <img src={ctaItem.lightThemeBackgroundImage || ctaItem.lightThemeBackgroundImageURL} alt="preview" width="100" />
                                                </Col>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>
                                                <strong>
                                                    Dark Theme Background Image{" "} <span className="text-danger">*</span>
                                                    <p style={{ fontSize: 12, marginBottom: 0 }}>
                                                        The image should be jpg/png/gif/webp
                                                    </p>
                                                </strong>
                                            </Label>

                                            <Button
                                                className="float-right btn-sm"
                                                onClick={(e) => {
                                                    setCtaItem({
                                                        ...ctaItem,
                                                        enableDarkThemeBackgroundImageDropdown: !ctaItem.enableDarkThemeBackgroundImageDropdown
                                                    })
                                                }
                                                }

                                                disabled={showCTAItemSectionDetails}
                                            >
                                                {ctaItem.enableDarkThemeBackgroundImageDropdown ? "Upload new image" : "Select from Uploaded Images"}
                                            </Button>

                                            <br />
                                            {!ctaItem.enableDarkThemeBackgroundImageDropdown && (
                                                <Container className="custom-file">
                                                    <Input
                                                        type="file"
                                                        className="custom-file-input"
                                                        accept="image/*"
                                                        disabled={showCTAItemSectionDetails}
                                                        onChange={(event) => {
                                                            const file = event.target.files[0];
                                                            const reader = new FileReader();

                                                            reader.onloadend = () => {
                                                                setCtaItem({
                                                                    ...ctaItem,
                                                                    darkThemeBackgroundImage: reader.result,
                                                                    darkThemeBackgroundImageName: file.name
                                                                });
                                                            }
                                                            reader.readAsDataURL(file);
                                                        }}
                                                    />
                                                    <Label className="custom-file-label">
                                                        <strong>{ctaItem.darkThemeBackgroundImageName === null ? "Choose an image" : ctaItem.darkThemeBackgroundImageName}</strong>
                                                    </Label>

                                                </Container>
                                            )}

                                            {ctaItem.enableDarkThemeBackgroundImageDropdown && (
                                                <Select
                                                    style={{
                                                        control: base => ({
                                                            ...base,
                                                            height: 35,
                                                            minHeight: 35
                                                        })
                                                    }}
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    name="darkThemeBackgroundImage"
                                                    value={quickRepliesAssetsOptions.filter((item) => item.value === ctaItem.darkThemeBackgroundImageName)}
                                                    placeholder="Select Uploaded Images"
                                                    onChange={(option) => {
                                                        setCtaItem({
                                                            ...ctaItem,
                                                            darkThemeBackgroundImageName: option.value,
                                                            darkThemeBackgroundImageURL: option.url
                                                        });
                                                    }}
                                                    isClearable={false}
                                                    isDisabled={showCTAItemSectionDetails}
                                                    options={quickRepliesAssetsOptions.filter((item) => item.theme === "dark" && item.type === "background_image")}
                                                />
                                            )}

                                            {(ctaItem.darkThemeBackgroundImage || ctaItem.darkThemeBackgroundImageURL) && (
                                                <Col className="pb-4 mb-3 mt-3">
                                                    <img src={ctaItem.darkThemeBackgroundImage || ctaItem.darkThemeBackgroundImageURL} alt="preview" width="100" />
                                                </Col>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>
                                                <strong>
                                                    Text Color{" "}<span className="text-warning text-sm">(Enter Hex Code starting with #)</span>
                                                    {validateColor(ctaItem.textColor) ? (
                                                        <div
                                                            style={{
                                                                backgroundColor:
                                                                    ctaItem.textColor,
                                                                width: "16px",
                                                                height: "16px",
                                                                display: "inline-block",
                                                                verticalAlign: "middle",
                                                                border: "1px solid #000",
                                                                borderRadius: "30%",
                                                            }}
                                                        >
                                                            {" "}
                                                        </div>
                                                    ) : (
                                                        <small className="text-danger ml-2">
                                                            {"Invalid Hex Code"}
                                                        </small>
                                                    )}

                                                </strong>
                                            </Label>
                                            <Input
                                                type="text"
                                                name="textColor"
                                                className={
                                                    validateColor(ctaItem.textColor)
                                                        ? "form-control is-valid"
                                                        : "form-control is-invalid"
                                                }
                                                placeholder="Enter Text Color"
                                                disabled={showCTAItemSectionDetails}
                                                value={
                                                    ctaItem.textColor === null ? null : ctaItem.textColor
                                                }
                                                onChange={(event) => {
                                                    const { name, value } = event.target;
                                                    setCtaItem((prev) => ({
                                                        ...prev,
                                                        [name]: value,
                                                    }));
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>
                                                <strong>
                                                    Dark Theme Text Color{" "}<span className="text-warning text-sm">(Enter Hex Code starting with #)</span>
                                                    {validateColor(ctaItem.darkThemeTextColor) ? (
                                                        <div
                                                            style={{
                                                                backgroundColor:
                                                                    ctaItem.darkThemeTextColor,
                                                                width: "16px",
                                                                height: "16px",
                                                                display: "inline-block",
                                                                verticalAlign: "middle",
                                                                border: "1px solid #000",
                                                                borderRadius: "30%",
                                                            }}
                                                        >
                                                            {" "}
                                                        </div>
                                                    ) : (
                                                        <small className="text-danger ml-2">
                                                            {"Invalid Hex Code"}
                                                        </small>
                                                    )}
                                                </strong>
                                            </Label>
                                            <Input
                                                type="text"
                                                name="darkThemeTextColor"
                                                placeholder="Enter Text Color"
                                                className={
                                                    validateColor(ctaItem.darkThemeTextColor)
                                                        ? "form-control is-valid"
                                                        : "form-control is-invalid"
                                                }
                                                disabled={showCTAItemSectionDetails}
                                                value={
                                                    ctaItem.darkThemeTextColor === null ? null : ctaItem.darkThemeTextColor
                                                }
                                                onChange={(event) => {
                                                    const { name, value } = event.target;
                                                    setCtaItem((prev) => ({
                                                        ...prev,
                                                        [name]: value,
                                                    }));
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>
                                                <strong>
                                                    Client Id{" "}<span class="text-danger">*</span>
                                                </strong>
                                            </Label>
                                            <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                name="clientId"
                                                value={ctaItem.clientId}
                                                placeholder="Select Client Id"
                                                isClearable={false}
                                                options={clientOptions}
                                                isDisabled={showCTAItemSectionDetails}
                                                onChange={(option) => {
                                                    setCtaItem({
                                                        ...ctaItem,
                                                        clientId: option,
                                                    });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {/* ClickTrackers */}
                                <Row className="mt-6">
                                    <Col md={6}>
                                        <h1 className="h3 mb-3">Click Trackers</h1>
                                    </Col>
                                    <Col md={6}>
                                        <div className="float-right" onClick={(e) => {
                                            setCtaItemsClickTrackersToggle(!ctaItemsClickTrackersToggle)
                                        }}>
                                            {ctaItemsClickTrackersToggle ? <FaChevronUp /> : <FaChevronDown />}
                                        </div>
                                        <Button className="float-right mr-4" onClick={(e) => {
                                            if (ctaItem.clickTrackers === undefined) {
                                                ctaItem.clickTrackers = [];
                                            }
                                            setCtaItem({
                                                ...ctaItem,
                                                clickTrackers: [...ctaItem.clickTrackers, {
                                                    type: { value: "direct_url", label: "Direct URL" },
                                                    url: null,
                                                }]
                                            })
                                        }}
                                            disabled={showCTAItemSectionDetails}
                                        >
                                            <FontAwesomeIcon size="sm" icon={faPlus}></FontAwesomeIcon>
                                            {" "}Add Click Tracker
                                        </Button>

                                    </Col>
                                </Row>

                                <Collapse isOpen={ctaItemsClickTrackersToggle}>
                                    <Card>
                                        <CardBody>
                                            {ctaItem?.clickTrackers?.map((item, index) => (
                                                <Card key={`card-${index + 6}`} className="p-3 mb-5" style={{
                                                    boxShadow: '0 0 20px rgba(0,0,0,0.4)',  // Increased shadow
                                                    borderRadius: '15px'
                                                }}>

                                                    <Row key={`row-${index + 6}`}>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label>
                                                                    <strong>
                                                                        Type
                                                                    </strong>
                                                                </Label>
                                                                <Select
                                                                    className="react-select-container"
                                                                    classNamePrefix="react-select"
                                                                    name="type"
                                                                    value={item.type}
                                                                    placeholder="Select type"
                                                                    isClearable={false}
                                                                    isDisabled={showCTAItemSectionDetails}
                                                                    options={trackersTypeOptions}
                                                                    onChange={(option) => {
                                                                        let temp = [...ctaItem.clickTrackers];
                                                                        temp[index].type = option;
                                                                        setCtaItem({
                                                                            ...ctaItem,
                                                                            clickTrackers: temp,
                                                                        });
                                                                    }}

                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            {showCTAItemSectionDetails === false && (
                                                                <X
                                                                    className="float-right"
                                                                    size={20}
                                                                    color="red"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={(e) => {
                                                                        let temp = [...ctaItem.clickTrackers];
                                                                        temp = temp.filter((_, idx) => idx !== index);
                                                                        setCtaItem({
                                                                            ...ctaItem,
                                                                            clickTrackers: temp,
                                                                        });
                                                                    }}
                                                                ></X>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    <Row key={`row-${index + 7}`}>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label>
                                                                    <strong>
                                                                        URL <span class="text-danger">*</span>
                                                                    </strong>
                                                                </Label>
                                                                <Input
                                                                    type="textarea"
                                                                    name="url"
                                                                    value={item.url}
                                                                    placeholder="Enter URL"
                                                                    disabled={showCTAItemSectionDetails}
                                                                    onChange={(option) => {
                                                                        let temp = [...ctaItem.clickTrackers];
                                                                        temp[index].url = option.target.value;
                                                                        setCtaItem({
                                                                            ...ctaItem,
                                                                            clickTrackers: temp,
                                                                        });
                                                                    }}
                                                                    required
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>

                                                </Card>


                                            ))}
                                        </CardBody>
                                    </Card>
                                </Collapse>

                                {/* CTA Action  */}
                                <QuickReplyCTAActions
                                    quickReplyTypeCard={ctaItem}
                                    setQuickReplyTypeCard={setCtaItem}
                                    clientOptions={clientOptions}
                                    disabledForView={showCTAItemSectionDetails}
                                    formName={formName}
                                    disableAddEditActionButton={disableAddEditActionButton}
                                />
                            </Container>

                        )}

                        {showCTAItemSectionDetails === false && (
                            <Row>
                                <Col md={12}>
                                    <Button className="btn-success float-right btn-primary"
                                        onClick={() => {
                                            saveCTAItems();
                                        }}
                                        disabled={checkCTAItemValidity()}
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </CardBody>
                </Card>
            )}

            <ResponseModal
                show={failureModalState.failureModalDisplay}
                onHide={() =>
                    setFailureModalState({
                        failureModalDisplay: false,
                        failureModalText: "",
                    })
                }
                modalheading={"Error"}
                modaltext={failureModalState.failureModalText}
            />


            <Modal
                show={showCTAItemReorderModal}
                onHide={() => {
                    setShowCTAItemReorderModal(false);
                    setCtaItemsByClientID([]);
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Reorder CTA Items</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card style={{
                        boxShadow: '0 0 20px rgba(0,0,0,0.4)',  // Increased shadow
                        borderRadius: '15px'
                    }}>
                        <CardBody>
                            <Row>
                                <Col md={12}>
                                    <strong>Note :</strong>
                                    The Cards can be reordered by dragging at the desired position vertically. The topmost card will be assigned the highest priority.
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Reorder reorderId="reorder-list" onReorder={onCTAItemsReorder} className="justify-content-center">
                        {ctaItemsByClientID.map((item, index) => (
                            <Col md={12} key={`col-${index + 6}`}>
                                <Card
                                    className="p-3 mb-5 mx-auto"
                                    style={{
                                        boxShadow: '0 0 20px rgba(0,0,0,0.4)',  // Increased shadow
                                        borderRadius: '15px',
                                        width: '50%',
                                    }}
                                >
                                    <CardBody>
                                        <h5>CTA Text: {item.text}
                                        </h5>
                                        <p>Width Percentage: {item.widthPercentage}</p>
                                        <p>Text Color: {item.textColor} <div
                                            style={{
                                                backgroundColor:
                                                    item.textColor,
                                                width: "16px",
                                                height: "16px",
                                                display: "inline-block",
                                                verticalAlign: "middle",
                                                border: "1px solid #000",
                                                borderRadius: "30%",
                                            }}
                                        >
                                            {" "}
                                        </div></p>
                                        <p>Dark Theme Text Color: {item.darkThemeTextColor} <div
                                            style={{
                                                backgroundColor:
                                                    item.darkThemeTextColor,
                                                width: "16px",
                                                height: "16px",
                                                display: "inline-block",
                                                verticalAlign: "middle",
                                                border: "1px solid #000",
                                                borderRadius: "30%",
                                            }}
                                        >
                                            {" "}
                                        </div></p>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Reorder>
                    <Modal.Footer>
                        <Button color="primary" onClick={(e) => {
                            setShowCTAItemReorderModal(false);
                            // first remove all the cta items of that client id
                            let temp = [...quickReplyTypeCard.ctaItems];
                            temp = temp.filter((item, index) => item.clientId?.value !== ctaItemsByClientID[0]?.clientId?.value);
                            // now add a field priority to the reordered cta items
                            ctaItemsByClientID.map((item, index) => {
                                temp.push({
                                    ...item,
                                    priority: ctaItemsByClientID.length - index,
                                })
                            })

                            // now add the temp to the quickReplyTypeCard
                            setQuickReplyTypeCard({
                                ...quickReplyTypeCard,
                                ctaItems: temp,
                            })

                            // empty the ctaItemsByClientID
                            setCtaItemsByClientID([]);
                        }}>Save Order</Button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default QuickReplyCTAItems;
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
    Button,
    Col,
    Form,
    FormGroup,
    Label,
    Row,
    Input,
    Container,
} from "reactstrap";
import { TextareaAutosize, capitalize } from "@material-ui/core";
import ResponseModal from "../ResponseModal";
import { successModalHeading, errorModalHeading } from "../../../config/config";

import { PopTextStyleShareTextResponses } from "../../../config/popTextStyles";
import { savePopTextStyleShareText } from "../../../utilities/apiUtils/popTextStyles";



export const ManagePopTextStyleShareTexts = (props) => {
    const [PopTextStyleShareTexts, setPopTextStyleShareTexts] = useState([]);
    const [defaultPopTextStyleShareTexts, setDefaultPopTextStyleShareTexts] = useState([]);
    const [customPopTextStyleShareTexts, setCustomPopTextStyleShareTexts] = useState([]);
    const [successModalDisplay, setSuccessModalDisplay] = useState(false);
    const [successModalText, setSuccessModalText] = useState("");
    const [failureModalDisplay, setFailureModalDisplay] = useState(false);
    const [failureModalText, setFailureModalText] = useState("");
    const [responsePopTextStyleShareText, setResponsePopTextStyleShareText] = useState({});
    const [enableShareTextInKeyboard, setEnableShareTextInKeyboard] = useState(false);

    // ... (previously defined states and functions)


    let showErrorModal = (text) => {
        setFailureModalText(text);
        setFailureModalDisplay(false);
    };

    let showSuccessModal = (text) => {
        setSuccessModalText(text);
        setSuccessModalDisplay(true);
    };

    let successModalClose = async () => {
        setSuccessModalDisplay(false);
        props.onHide();
    };

    let failureModalClose = () => {
        setFailureModalDisplay(false);
        props.onHide();
    };

    const handleClose = () => {
        props.onHide();
    };

    const handleTextChange = (event, index) => {
        let temp = [...customPopTextStyleShareTexts];
        temp[index].text = event.target.value;
        setCustomPopTextStyleShareTexts(temp);
    };

    const setPopTextStyleShareTextData = () => {
        if (props.action === "edit" || props.action === "view") {
            setPopTextStyleShareTexts(props.shareTexts);
            setEnableShareTextInKeyboard(props.enableShareTextInKeyboard)
        }
        FilterPopTextStyleShareTextData();
    };
    const handleDeleteInput = (index) => {
        const updatedPopTextStyleShareText = customPopTextStyleShareTexts.filter((_, i) => i !== index);
        setCustomPopTextStyleShareTexts(updatedPopTextStyleShareText);
    };

    const FilterPopTextStyleShareTextData = () => {
        if (!Array.isArray(props.shareTexts)) {
            // Handle the case when props.shareTexts is not an array
            return null; // Or handle the error in an appropriate way
          }
        const customTexts = props.shareTexts.filter((shareText) => shareText.type === "custom");
        const defaultTexts = props.shareTexts.filter((shareText) => shareText.type === "default");
        setCustomPopTextStyleShareTexts(customTexts);
        setDefaultPopTextStyleShareTexts(defaultTexts);
    }
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (props.action === "add" || props.action === "edit") {


            const payload = {
                enableShareTextInKeyboard: enableShareTextInKeyboard,
                shareTexts: [],
            };

            customPopTextStyleShareTexts.forEach((shareText) => {
                payload.shareTexts.push({
                    text: shareText.text,
                    type: shareText.type
                });
            });
            console.log(payload.shareTexts.text)
            let response = await savePopTextStyleShareText(payload,props.popTextStyleID)


            if (response.status === "Success") {
                let text = PopTextStyleShareTextResponses.updatedSuccessfully;
                showSuccessModal(text);
                setResponsePopTextStyleShareText(response);
                window.location.reload()
            } else {
                let text = response.errorDescription;
                showErrorModal(text);
            }
        }
    };

    const handleEnableShareTextChange = (event) => {
        setEnableShareTextInKeyboard(event.target.checked)
    };
    useEffect(() => {
        setPopTextStyleShareTextData();
    }, []);
    return (
        <>
            <Modal
                show={props.show}
                onHide={props.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {capitalize(props.action)} PopTextStyle Share Texts
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(event) => handleSubmit(event)}>
                        <Row form>
                            <Col md={12}>
                                <Row className="mb-3">
                                    <Col md={12} xs={12}>
                                        <Row className="mb-3">
                                            <Col xs={12} className="d-flex align-items-left">
                                                <Input
                                                    type="checkbox"
                                                    name="enableShareTextInKeyboard"
                                                    checked={enableShareTextInKeyboard}
                                                    onChange={handleEnableShareTextChange}
                                                    style={{ padding: '0px 50px 0px 0px', margin: '5px 50px 0px 0px ' }}
                                                />
                                                <Label className="font-weight-bolder mb-2" style={{ fontSize: "16px", padding: '0px 50px 0px 30px', margin: '0px' }}>
                                                    Enable Share Text in Keyboard
                                                </Label>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>


                                <h5>Default Share Text</h5>
                                {defaultPopTextStyleShareTexts && defaultPopTextStyleShareTexts.length > 0 ? (
                                    defaultPopTextStyleShareTexts.map((shareTexts, index) => (
                                        <Row key={index} form>
                                            <Col md={8}>
                                                <FormGroup>
                                                    <Input
                                                        type="textarea"
                                                        name="description"
                                                        placeholder="Enter text"
                                                        value={shareTexts.text ? shareTexts.text : "Not set"}
                                                        disabled={props.action === "view" || shareTexts.type === "default"}
                                                        onChange={(event) => handleTextChange(event, index)}
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                {shareTexts.type !== "default" ? (
                                                    <Button
                                                        color="danger"
                                                        className="ml-auto d-flex btn-danger"
                                                        onClick={() => handleDeleteInput(index)}
                                                    >
                                                        <p className="mb-1 mt-1" style={{ fontSize: "0.75rem" }}>
                                                            Delete
                                                        </p>
                                                    </Button>
                                                ) : null}
                                            </Col>
                                            <hr style={{ width: "75rem", color: "black" }} />
                                        </Row>
                                    ))
                                ) : (
                                    <Row form>
                                        <Col md={12}>
                                            <p>Not Set</p>
                                        </Col>
                                    </Row>
                                )}

                                <Row className="mb-3">
                                    <Col md={6} xs={6}>
                                        <h5>Custom Share Texts</h5>
                                    </Col>
                                    <Col md={6} xs={6}>
                                        <div className="p-0">
                                            {props.action == "edit" && (
                                                <Button
                                                    color="success"
                                                    className="ml-auto d-flex btn-success"
                                                    onClick={() =>
                                                        setCustomPopTextStyleShareTexts(
                                                            customPopTextStyleShareTexts.concat({
                                                                text: "",
                                                                type: "custom"
                                                            })
                                                        )
                                                    }
                                                >
                                                    <p
                                                        className="mb-1 mt-1"
                                                        style={{ fontSize: "0.75rem" }}
                                                    >
                                                        Add
                                                    </p>
                                                </Button>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                {customPopTextStyleShareTexts?.map((shareTexts, index) => {
                                    return (

                                        <Row key={index} form>
                                            <Col md={8}>
                                                <FormGroup>

                                                    <Input
                                                        type="textarea"
                                                        name="description"
                                                        placeholder="Enter text"
                                                        value={shareTexts.text}
                                                        disabled={props.action === "view" || shareTexts.type === "default"}
                                                        onChange={(event) =>
                                                            handleTextChange(event, index)
                                                        }
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <Button
                                                    className="ml-auto d-flex btn-outline-danger" // Add the btn-outline-danger class for an outline button
                                                    onClick={() => handleDeleteInput(index)}
                                                    style={{ backgroundColor: "white", color: "red" }}
                                                >
                                                    <p className="mb-1 mt-1" style={{ fontSize: "0.75rem" }}>
                                                        Delete
                                                    </p>
                                                </Button>
                                            </Col>
                                        </Row>
                                    );

                                })}
                            </Col>
                        </Row>
                        <div className="d-block w-100">
                            {props.action !== "view" && (
                                <Button color="primary" type="submit" >
                                    Submit
                                </Button>
                            )}
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            <ResponseModal
                show={successModalDisplay}
                onHide={successModalClose}
                modalheading={successModalHeading}
                modaltext={successModalText}
            />
            <ResponseModal
                show={failureModalDisplay}
                onHide={failureModalClose}
                modalheading={errorModalHeading}
                modaltext={failureModalText}
            />
        </>
    );
};

export default ManagePopTextStyleShareTexts;

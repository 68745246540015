import { retrieveFromLocalStorage } from "../utilities/localStorageUtil";
import { permissions } from "../utilities/commonUtil";

const allowedPermissions = retrieveFromLocalStorage("userPermissions");

// Common
export let isConfigAPICreatePermission = () => {
  return allowedPermissions.includes(permissions.configAPICreate);
};

export let isConfigAPIEditPermission = () => {
  return allowedPermissions.includes(permissions.configAPIEdit);
};

export let isConfigAPIDeletePermission = () => {
  return allowedPermissions.includes(permissions.configAPIDelete);
};

export let isConfigAPIActivatePermission = () => {
  return allowedPermissions.includes(permissions.configAPIActivate);
};

export let isConfigAPIDeactivatePermission = () => {
  return allowedPermissions.includes(permissions.configAPIDeactivate);
};

export const statusOptions = [
  { value: "", label: "All" },
  { value: "Activated", label: "Activated" },
  { value: "Deactivated", label: "Deactivated" },
];

export const allActions = {
  delete: "Delete",
  activate: "Activate",
  deactivate: "Deactivate",
};

export const placementTypeOptions = [
  {
    value: "adsApi",
    label: "Ads API",
    target: {
      name: "type",
      value: {
        value: "adsApi",
        label: "Ads API"
      }
    }
  },
  {
    value: "mediationSdkApi",
    label: "Mediation SDK API",
    target: {
      name: "type",
      value: {
        value: "mediationSdkApi",
        label: "Mediation SDK API"
      }
    }
  }
]

export const adFetchMechanismOptions = [
  {
    value: "realtime",
    label: "Real-time",
    target: {
      name: "adFetchMechanism",
      value: {
        value: "realtime",
        label: "Real-time"
      }
    }
  },
  {
    value: "prefetchAppOpen",
    label: "Pre-fetch app open",
    target: {
      name: "adFetchMechanism",
      value: {
        value: "prefetchAppOpen",
        label: "Pre-fetch app open"
      }
    }
  },
  {
    value: "prefetchBackground",
    label: "Pre-fetch background",
    target: {
      name: "adFetchMechanism",
      value: {
        value: "prefetchBackground",
        label: "Pre-fetch background"
      }
    }
  }
]

export const clientOptions = [
  {
    value: "7wZFJWA5chjgat68y826IAIKQ6s197RM",
    label: "Bobble Android",
    target: {
      name: "clientId",
      value: {
        value: "7wZFJWA5chjgat68y826IAIKQ6s197RM",
        label: "Bobble Android"
      }
    }
  },
  {
    value: "com.mint.keyboard",
    label: "Com.Mint",
    target: {
      name: "clientId",
      value: {
        value: "com.mint.keyboard",
        label: "Com.Mint"
      }
    }
  },
  {
    value: "ai.mint.keyboard",
    label: "AI.Mint",
    target: {
      name: "clientId",
      value: {
        value: "ai.mint.keyboard",
        label: "AI.Mint"
      }
    }
  },
  {
    value: "rsEBSwo0yrDvRns7BE5nhzQqOgs3L1N0",
    label: "Malayalam Keyboard",
    target: {
      name: "clientId",
      value: {
        value: "rsEBSwo0yrDvRns7BE5nhzQqOgs3L1N0",
        label: "Malayalam Keyboard"
      }
    }
  },
  {
    value: "hbobVIWvQuV01gTBlnL94VKTLkD6t82hM2lVTvnM",
    label: "Bangla Keyboard",
    target: {
      name: "clientId",
      value: {
        value: "hbobVIWvQuV01gTBlnL94VKTLkD6t82hM2lVTvnM",
        label: "Bangla Keyboard"
      }
    }
  },
  {
    value: "dVQYCMXRB3rZ7IS3CyhtBy3TQbjSuNB8Nouos1E9",
    label: "Hindi Keyboard",
    target: {
      name: "clientId",
      value: {
        value: "dVQYCMXRB3rZ7IS3CyhtBy3TQbjSuNB8Nouos1E9",
        label: "Hindi Keyboard"
      }
    }
  },
  {
    value: "mDfMe0f6rmvMVLA4KUINUtysfgnVINPfIuVkMcxl",
    label: "Marathi Keyboard",
    target: {
      name: "clientId",
      value: {
        value: "mDfMe0f6rmvMVLA4KUINUtysfgnVINPfIuVkMcxl",
        label: "Marathi Keyboard"
      }
    }
  }
]

export const adsMixingStrategyOptions = [
  {
    value: "ordered",
    label: "Ordered",
    target: {
      name: "adsMixingStrategy",
      value: {
        value: "ordered",
        label: "Ordered"
      }
    }
  },
  {
    value: "round-robin",
    label: "Round-Robin",
    target: {
      name: "adsMixingStrategy",
      value: {
        value: "round-robin",
        label: "Round-Robin"
      }
    }
  },
  {
    value: "ecpm",
    label: "ECPM",
    target: {
      name: "adsMixingStrategy",
      value: {
        value: "ecpm",
        label: "ECPM"
      }
    }
  },
  {
    value: "ratio",
    label: "Ratio",
    target: {
      name: "adsMixingStrategy",
      value: {
        value: "ratio",
        label: "Ratio"
      }
    }
  }
]

// Category
export const categoriesAllActions = {
  delete: "Delete",
};

export const categoriesResponses = {
  updatedSuccessfully: "Category updated successfully",
  addedSuccessfully: "Categories added successfully",
};

// Creative types
export const creativeTypesAllActions = {
  delete: "Delete",
};

export const creativeTypesResponses = {
  updatedSuccessfully: "Creative type updated successfully",
  addedSuccessfully: "Creative type added successfully",
};

// Vendors
export const vendorsResponses = {
  updatedSuccessfully: "Vendor updated successfully",
  addedSuccessfully: "Vendor added successfully",
};

// Placements
export const placementsResponses = {
  updatedSuccessfully: "Placement updated successfully",
  addedSuccessfully: "Placement added successfully",
};

// Configs
export const configsResponses = {
  updatedSuccessfully: "Config updated successfully",
  addedSuccessfully: "Config added successfully",
};

// Placement vendor
export const placementVendorsAllActions = {
  delete: "Delete",
};

export const placementVendorsResponses = {
  updatedSuccessfully: "Placement vendor updated successfully",
  addedSuccessfully: "Placement vendor added successfully",
};

// Mixing strategy
export const mixingStrategyAllActions = {
  delete: "Delete",
};

export const mixingStrategyResponses = {
  updatedSuccessfully: "Mixing strategy updated successfully",
  addedSuccessfully: "Mixing strategy added successfully",
};

// Common utils
export const processDateAndStatus = (item) => {
  if (item.deactivatedAt !== null) {
    item.deactivatedAt = new Date(item.deactivatedAt).toLocaleString();
    if (item.deactivatedAt === "Invalid Date") {
      item.deactivatedAt = null;
    }
  }
  if (item.activatedAt !== null) {
    item.activatedAt = new Date(item.activatedAt).toLocaleString();
    if (item.activatedAt === "Invalid Date") {
      item.activatedAt = null;
    }
  }
  if (item.deactivatedAt === null) {
    item.status = "Activated";
  }
  if (item.activatedAt === null) {
    item.status = "Deactivated";
  }
};
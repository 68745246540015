import React, { useEffect, useState } from 'react'
import { Modal, Button } from "react-bootstrap";
import { Row, Col, FormGroup, Label, Input, Form } from "reactstrap";
import Select, { components } from "react-select";
import { fetchPlacements, fetchVendors } from '../../../../utilities/apiUtils/configAPI';
import { adFetchMechanismOptions } from '../../../../config/configConfigAPIDashboard';

const PlacementVendorModal = ({ show = false, type, formData, setFormData = () => { }, onSubmit = () => { }, onHide = () => { } }) => {
  const [data, setData] = useState({
    id: "",
    placementId: "",
    clientAdVendorId: "",
    clientAdVendorPlacementId: "",
    adFetchMechanism: "",
    prefetchInterval: "",
    maxAds: "",
    adFetchTimeout: "",
    minVisibilityImpressionPercentage: ""
  })
  const [placementOptions, setPlacementOptions] = useState([])
  const [vendorOptions, setVendorOptions] = useState([])

  const fetchPlacementOptions = async () => {
    try {
      let response = await fetchPlacements()
      if (response.length > 0) {
        setPlacementOptions(response.map(placement => ({
          value: placement.id,
          label: placement.description,
          target: {
            name: "placementId",
            value: {
              value: placement.id,
              label: placement.description
            }
          }
        })))
      }
    } catch (err) {
      console.log(err);
    }
  }

  const fetchVendorOptions = async () => {
    try {
      let response = await fetchVendors()
      if (response.length > 0) {
        setVendorOptions(response.map(vendor => ({
          value: vendor.id,
          label: vendor.name,
          target: {
            name: "clientAdVendorId",
            value: {
              value: vendor.id,
              label: vendor.name
            }
          }
        })))
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchPlacementOptions()
    fetchVendorOptions()
  }, [])

  useEffect(() => {
    if (formData?.id) {
      setData({
        id: formData?.id,
        placementId: {
          label: placementOptions.find(option => option.value === formData?.placementId)?.label ?? formData?.placementId,
          value: formData?.placementId
        },
        clientAdVendorId: {
          label: vendorOptions.find(option => option.value === formData?.clientAdVendorId)?.label ?? formData?.clientAdVendorId,
          value: formData?.clientAdVendorId
        },
        adFetchMechanism: {
          label: adFetchMechanismOptions.find(option => option.value === formData?.adFetchMechanism)?.label ?? formData?.adFetchMechanism,
          value: formData?.adFetchMechanism
        },
        clientAdVendorPlacementId: formData?.clientAdVendorPlacementId,
        prefetchInterval: formData?.prefetchInterval,
        maxAds: formData?.maxAds,
        adFetchTimeout: formData?.adFetchTimeout,
        minVisibilityImpressionPercentage: formData?.minVisibilityImpressionPercentage
      })
    } else {
      setData({
        id: "",
        placementId: "",
        clientAdVendorId: "",
        clientAdVendorPlacementId: "",
        adFetchMechanism: "",
        prefetchInterval: "",
        maxAds: "",
        adFetchTimeout: "",
        minVisibilityImpressionPercentage: ""
      })
    }
  }, [formData, placementOptions, vendorOptions])

  const handleChange = (event) => {
    const { name, value } = event.target
    let updatedState = {
      ...data,
      [name]: value
    }
    if (updatedState?.clientAdVendorId?.label === "bobble-adx") {
      // if vendor is "bobble-adx" then vendor's placement ID will be same as placement ID
      updatedState = {
        ...updatedState,
        clientAdVendorPlacementId: updatedState?.placementId?.value,
      }
    }
    setData(updatedState)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = {
      id: data.id,
      placementId: data.placementId?.value,
      clientAdVendorId: data.clientAdVendorId?.value,
      adFetchMechanism: data.adFetchMechanism?.value,
      clientAdVendorPlacementId: data.clientAdVendorPlacementId,
      prefetchInterval: data.prefetchInterval,
      maxAds: data.maxAds,
      adFetchTimeout: data.adFetchTimeout,
      minVisibilityImpressionPercentage: data.minVisibilityImpressionPercentage
    }
    setFormData(payload)
    onSubmit(payload)
  }

  const requiredComponent = (props) => (
    <div>
      <components.Input {...props} required={true} />
    </div>
  );

  const notRequiredComponent = (props) => (
    <div>
      <components.Input {...props} required={false} />
    </div>
  );

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header className="bg-light" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {type} Placement Vendor
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row form>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Placement <span className="text-danger">*</span>
                </Label>
                <Select
                  id="placementId"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="placementId"
                  placeholder="Select Placement"
                  value={data.placementId}
                  onChange={handleChange}
                  options={placementOptions}
                  components={{
                    Input:
                      (data.placementId)
                        ? notRequiredComponent
                        : requiredComponent,
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Vendor <span className="text-danger">*</span>
                </Label>
                <Select
                  id="clientAdVendorId"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="clientAdVendorId"
                  placeholder="Select Vendor"
                  value={data.clientAdVendorId}
                  onChange={handleChange}
                  options={vendorOptions}
                  components={{
                    Input:
                      (data.clientAdVendorId)
                        ? notRequiredComponent
                        : requiredComponent,
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col lg={12} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Vendor’s Placement ID <small className="text-info">(UUID)</small>
                </Label>
                <Input
                  type="text"
                  name="clientAdVendorPlacementId"
                  placeholder="Enter Placement Vendor ID"
                  value={data.clientAdVendorPlacementId}
                  onChange={handleChange}
                  disabled={type === "Edit" || data?.clientAdVendorId?.label === "bobble-adx"}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col lg={12} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Ad Fetch Mechanism <span className="text-danger">*</span>
                </Label>
                <Select
                  id="adFetchMechanism"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="adFetchMechanism"
                  placeholder="Select Ad Fetch Mechanism"
                  value={data.adFetchMechanism}
                  onChange={handleChange}
                  options={adFetchMechanismOptions}
                  components={{
                    Input:
                      (data.adFetchMechanism)
                        ? notRequiredComponent
                        : requiredComponent,
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Prefetch Interval <span className="text-danger">*</span>
                </Label>
                <Input
                  type="number"
                  name="prefetchInterval"
                  min={0}
                  placeholder="Enter Prefetch Interval"
                  value={data.prefetchInterval}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Max Ads <span className="text-danger">*</span>
                </Label>
                <Input
                  type="number"
                  name="maxAds"
                  min={0}
                  placeholder="Enter Max Ads"
                  value={data.maxAds}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Ad Fetch Timeout (in ms) <span className="text-danger">*</span>
                </Label>
                <Input
                  type="number"
                  name="adFetchTimeout"
                  min={0}
                  placeholder="Enter Ad Fetch Timeout (in ms)"
                  value={data.adFetchTimeout}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Min Visibility Impression Percentage <span className="text-danger">*</span>
                </Label>
                <Input
                  type="number"
                  name="minVisibilityImpressionPercentage"
                  min={0}
                  placeholder="Enter Min Visibility Impression Percentage"
                  value={data.minVisibilityImpressionPercentage}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
          <Button type='submit' className='btn-success'>Submit</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default PlacementVendorModal
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  FormGroup,
  Row,
  Col,
  Container,
  Card,
  Label,
  Input,
  CardBody,
  Button,
  CustomInput,
} from "reactstrap";
import {
  redirectTo,
  redirectOnTokenExpiry,
  error,
  errorDescription,
  validateEmojiStickerImage,
  dateFormattedWithoutTime,
  payloadDateFormatterWithoutTime,
} from "../../../../utilities/commonUtil";
import ResponseModal from "../../ResponseModal";
import {
  addEmojiStickerImage,
  updateEmojiStickerImage,
} from "../../../../utilities/apiUtils/emojiStickers";
import { getBrandCampaigns } from "../../../../utilities/apiUtils/brandCampaign";
import {
  defaultBrandCampaign,
  dateValidationFailure,
  waterMarkMissingText,
  imageValidationFailureText,
  bigMojiImageUploadSuccessText,
  bigMojiImageUpdateSuccessText,
  eventTypeMissingText, isComboEmojiStickerMissingText,
} from "../../../../config/UserConfig";
import { eventTypes } from "../../../../config/stickers";
import Select from "react-select";
import { RefreshCw } from "react-feather";

const CreateEmojiImage = (props) => {
  const emojiID = props.emojiID;
  const emojiImageID = props.emojiImageID;

  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [resourceFilePng, setResourceFilePng] = useState(null);
  const [resourceFileGif, setResourceFileGif] = useState(null);
  const [resourceFileNonTransparentGif, setResourceFileNonTransparentGif] = useState(null);
  const [resourceFileSmartBigmojiGif, setResourceFileSmartBigmojiGif] = useState(null);
  const [selectedPngFileName, setSelectedPngFileName] = useState("");
  const [selectedGifFileName, setSelectedGifFileName] = useState("");
  const [selectedGifURL, setSelectedGifURL] = useState("");
  const [selectedNonTransparentGifFileName, setSelectedNonTransparentGifFileName] = useState("");
  const[selectedNonTransparentGifURL, setSelectedNonTransparentGifURL] = useState("");
  const [selectedSmartBigmojiGifFileName, setSelectedSmartBigmojiGifFileName] = useState("");
  const [selectedSmartBigmojiGifURL, setSelectedSmartBigmojiGifURL] = useState("");
  const [watermarkSupport, setWatermarkSupport] = useState("");
  const [isComboEmojiSticker, setIsComboEmojiSticker] = useState("");
  const [eventType, setEventType] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateFormatted, setStartDateFormatted] = useState(null);
  const [endDateFormatted, setEndDateFormatted] = useState(null);
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [brandCampaigns, setBrandCampaigns] = useState([]);
  const [selectedBrandCampaign, setSelectedBrandCampaign] =
    useState(defaultBrandCampaign);

  const [hasResponseError, setHasResponseError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [additionalDetails, setAdditionalDetails] = useState({});
  const [isSmartBigmoji, setIsSmartBigmoji] = useState(props.isSmartBigmoji || false); 
  const [autoDownload , setAutoDownload] = useState(props.autoDownload || false);

  const pngWarningRef = useRef();
  const gifWarningRef = useRef();
  const nonTransparentGiftWarningRef = useRef();
  const smartBigmojiGifWarningRef = useRef();
  //componentDidMount Hook
  useEffect(() => {
    let watermarkSupport = "" + props.watermarkSupport;
    let isComboEmojiSticker = "" + props.isComboEmojiSticker;
    let isSmartBigmoji = "" + props.isSmartBigmoji;  
    let autoDownload = "" + props.autoDownload;
    let eventType = props.eventType;
    let startDate = null;
    let endDate = null;
    let startDateFormatted = null;
    let endDateFormatted = null;
    if (props.startDate) {
      startDate = payloadDateFormatterWithoutTime(props.startDate);
      startDateFormatted = dateFormattedWithoutTime(props.startDate);
    }
    if (props.endDate) {
      endDate = payloadDateFormatterWithoutTime(props.endDate);
      endDateFormatted = dateFormattedWithoutTime(props.endDate);
    }
    setWatermarkSupport(watermarkSupport);
    setIsComboEmojiSticker(isComboEmojiSticker);
    setIsSmartBigmoji(isSmartBigmoji);  
    setAutoDownload(autoDownload);
    setEventType(eventType);
    setStartDate(startDate);
    setEndDate(endDate);
    setStartDateFormatted(startDateFormatted);
    setEndDateFormatted(endDateFormatted);
    setAdditionalDetails(props.additionalDetails);
    setSelectedNonTransparentGifFileName(props?.additionalDetails?.images?.non_transparent_gif?.filename !== null ? props?.additionalDetails?.images?.non_transparent_gif?.filename : "");
    setSelectedNonTransparentGifURL(props?.additionalDetails?.images?.non_transparent_gif?.filename !== null ? props?.baseURL+"/"+props?.additionalDetails?.images?.non_transparent_gif?.filename : "");
    setIsSmartBigmoji(props.isSmartBigmoji)
    setAutoDownload(props.autoDownload)

    setSelectedSmartBigmojiGifFileName(props?.additionalDetails?.images?.smart_bigmoji_webp?.filename !== null ? props?.additionalDetails?.images?.smart_bigmoji_webp?.filename : "");
    setSelectedSmartBigmojiGifURL(props?.additionalDetails?.images?.smart_bigmoji_webp?.filename !== null ? props?.baseURL+"/"+props?.additionalDetails?.images?.smart_bigmoji_webp?.filename : "");
    const fetchBrandCampaigns = async () => {
      try {
        let response = await getBrandCampaigns();
        if (!response.errorCode && response) {
          let brandCampaigns = response;
          let updatedBrandCampaigns = [];
          updatedBrandCampaigns.push(defaultBrandCampaign);
  
          for (const element of brandCampaigns) {
            updatedBrandCampaigns = [...updatedBrandCampaigns, element];
  
            if (props.brandCampaignId !== null && element.value === props.brandCampaignId) {
              setSelectedBrandCampaign(element);
            }
          }
          setBrandCampaigns(updatedBrandCampaigns);
        } else if (response.errorCode) {
          if (response.errorCode === error.tokenExpired || response.errorCode === error.invalidAccessToken) {
            redirectOnTokenExpiry();
          } else {
            setHasResponseError(true);
            setFailureModalText(errorDescription(response.errorCode));
            setFailureModalDisplay(true);
          }
        }
      } catch (err) {
        setLoading(false);
        setFailureModalText(errorDescription(error.unexpectedError));
        setFailureModalDisplay(true);
      }
    };
  
    fetchBrandCampaigns();
  }, []);  

  const showAlert = (ref) => {
    ref.current.classList.remove("d-none");
    ref.current.classList.add("d-block");
  };

  const hideAlert = (ref) => {
    ref.current.classList.add("d-none");
    ref.current.classList.remove("d-block");
  };

  const handleStartDateChange = (e) => {
    let targetedValue = new Date(e.target.value);
    let startDateMonth =
      (targetedValue.getMonth() + 1 < 10 ? "0" : "") +
      (targetedValue.getMonth() + 1);
    let startDateDay =
      (targetedValue.getDate() < 10 ? "0" : "") + targetedValue.getDate();
    let startDate =
      targetedValue.getFullYear() + "-" + startDateMonth + "-" + startDateDay;

    let startDateFormatted =
      targetedValue.getFullYear() + "-" + startDateMonth + "-" + startDateDay;

    setStartDate(startDate);
    setStartDateFormatted(startDateFormatted);
  };

  const handleEndDateChange = (e) => {
    let targetedValue = new Date(e.target.value);
    let endDateMonth =
      (targetedValue.getMonth() + 1 < 10 ? "0" : "") +
      (targetedValue.getMonth() + 1);
    let endDateDay =
      (targetedValue.getDate() < 10 ? "0" : "") + targetedValue.getDate();
    let endDate =
      targetedValue.getFullYear() + "-" + endDateMonth + "-" + endDateDay;

    let endDateFormatted =
      targetedValue.getFullYear() + "-" + endDateMonth + "-" + endDateDay;
    setEndDate(endDate);
    setEndDateFormatted(endDateFormatted);
  };

  const handlePngFileChange = (e) => {
    const file = e.target.files[0];
    validateEmojiStickerImage("image/png", file)
      .then((res) => {
        hideAlert(pngWarningRef);
        setResourceFilePng(file ? file : null);
        setSelectedPngFileName(file ? file.name : "");
      })
      .catch((err) => {
        showAlert(pngWarningRef);
      });
  };

  const handleGifFileChange = (e) => {
    const file = e.target.files[0];
    validateEmojiStickerImage("image/gif", file)
      .then((res) => {
        hideAlert(gifWarningRef);
        setResourceFileGif(file ? file : null);
        setSelectedGifFileName(file ? file.name : "");
      })
      .catch((err) => {
        showAlert(gifWarningRef);
      });
  };
  const handleNonTransparentGifFileChange = (e) => {
    const file = e.target.files[0];
    validateEmojiStickerImage("image/gif", file)
      .then((res) => {
        hideAlert(nonTransparentGiftWarningRef);
        setResourceFileNonTransparentGif(file ? file : null);
        setSelectedNonTransparentGifFileName(file ? file.name : "");
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const image = new Image();
          image.src = reader.result;
          image.onload = () => {
            // Check if image is square when ad type IS NOT "product"
            setSelectedNonTransparentGifURL(reader.result);
          }
        };
      })
      .catch((err) => {
        showAlert(nonTransparentGiftWarningRef);
      });
  };

  const handleSmartBigmojiGifFileChange = (e) => {
    const file = e.target.files[0];
    validateEmojiStickerImage("image/gif", file)
      .then((res) => {
        hideAlert(smartBigmojiGifWarningRef);
        setResourceFileSmartBigmojiGif(file ? file : null);
        setSelectedSmartBigmojiGifFileName(file ? file.name : "");
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const image = new Image();
          image.src = reader.result;
          image.onload = () => {
            // Check if image is square when ad type IS NOT "product"
            setSelectedSmartBigmojiGifURL(reader.result);
          }
        };
      })
      .catch((err) => {
        showAlert(smartBigmojiGifWarningRef);
      });
  };


  const handleWatermarkChange = (e) => {
    setWatermarkSupport(e.target.value);
  };

    const handleComboEmojiStickerChange = (e) => {
    setIsComboEmojiSticker(e.target.value);
  }

  const handleEventTypeChange = (e) => {
    setEventType(e.target.value);
  };
  const handleSmartBigmojiChange = (e) => {
    setIsSmartBigmoji(e.target.value === 'true');
  };
  const handleAutoDownloadChange  = (e) => {
    setAutoDownload(e.target.value === 'true');
  }

  

  const validateSubmission = () => {
    if (!["true", "false"].includes(watermarkSupport)) {
      setFailureModalText(waterMarkMissingText);
      setFailureModalDisplay(true);
      return false;
    }
    if (!["true", "false"].includes(isComboEmojiSticker)) {
        setFailureModalText(isComboEmojiStickerMissingText);
        setFailureModalDisplay(true);
        return false;
    }
    if (!selectedPngFileName && !selectedGifFileName  && props.forCreate) {
      setFailureModalText(imageValidationFailureText);
      setFailureModalDisplay(true);
      return false;
    }
    if (eventType === undefined || !eventTypes.includes(eventType)) {
      setFailureModalText(eventTypeMissingText);
      setFailureModalDisplay(true);
      return false;
    }

    let startTimeOBJ = Date.parse(startDate);
    let endTimeOBJ = Date.parse(endDate);

    if (isNaN(startTimeOBJ) ? !isNaN(endTimeOBJ) : isNaN(endTimeOBJ)) {
      setFailureModalText(dateValidationFailure);
      setFailureModalDisplay(true);
      return false;
    }

    if (startDate !== null && endDate !== null) {
      if (startTimeOBJ >= endTimeOBJ) {
        setFailureModalText(dateValidationFailure);
        setFailureModalDisplay(true);
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async () => {
    let isValid = validateSubmission();
    let formData = new FormData();
    if (isValid) {
      formData.append("watermarkSupport", watermarkSupport);
        formData.append("isComboEmojiSticker", isComboEmojiSticker);
      formData.append("eventType", eventType);
      formData.append("isSmartBigmoji", isSmartBigmoji ? isSmartBigmoji : false);
      formData.append("autoDownload" , autoDownload ? autoDownload : false);
      if (resourceFilePng) formData.append("pngSourceFile", resourceFilePng);
      if (resourceFileGif) formData.append("gifSourceFile", resourceFileGif);
      if (resourceFileNonTransparentGif) formData.append("nonTransparentGifSourceFile", resourceFileNonTransparentGif);
      if (resourceFileSmartBigmojiGif) formData.append("smartBigmojiGifSourceFile", resourceFileSmartBigmojiGif);

      let startTimeOBJ = Date.parse(startDate);
      let endTimeOBJ = Date.parse(endDate);
      if (startDate && endDate && !isNaN(startTimeOBJ) && !isNaN(endTimeOBJ)) {
        formData.append("startTime", startDate);
        formData.append("endTime", endDate);
      }
      if (selectedBrandCampaign.value !== "none") {
        formData.append("brandCampaignId", selectedBrandCampaign.value);
      }

      if (props.forCreate) {
        setIsSubmitEnabled(true);
        try {
          let res = await addEmojiStickerImage(formData, emojiID);
          if (res && res.errorCode === undefined) {
            setIsSubmitEnabled(false);
            setSuccessModalText(bigMojiImageUploadSuccessText);
            setSuccessModalDisplay(true);
          } else {
            if (
              res.errorCode === error.tokenExpired ||
              res.errorCode === error.invalidAccessToken
            )
              redirectOnTokenExpiry();
            setIsSubmitEnabled(false);
            setFailureModalText(
              res.errorDescription || errorDescription(res.errorCode)
            );
            setFailureModalDisplay(true);
          }
        } catch (err) {
          setIsSubmitEnabled(false);
          setFailureModalText(errorDescription(error.unexpectedError));
          setFailureModalDisplay(true);
        }
      } else {
        setIsSubmitEnabled(true);
        try {
          let res = await updateEmojiStickerImage(
            formData,
            emojiID,
            emojiImageID
          );
          if (res && !res.errorCode) {
            setIsSubmitEnabled(false);
            setSuccessModalText(bigMojiImageUpdateSuccessText);
            setSuccessModalDisplay(true);
          } else if (
            res.errorCode === error.tokenExpired ||
            res.errorCode === error.invalidAccessToken
          ) {
            redirectOnTokenExpiry();
            setIsSubmitEnabled(false);
            setFailureModalText(errorDescription(res.errorCode));
            setFailureModalDisplay(true);
          } else {
            setIsSubmitEnabled(false);
            setFailureModalText(errorDescription(res.errorCode));
            setFailureModalDisplay(true);
          }
        } catch (err) {
          setIsSubmitEnabled(false);
          setFailureModalText(errorDescription(error.unexpectedError));
          setFailureModalDisplay(true);
        }
      }
    }
  };

  const handleBrandChange = (select) => {
    let selectedBrandCampaign = {};
    selectedBrandCampaign["value"] = select.value;
    selectedBrandCampaign["label"] = select.label;
    setSelectedBrandCampaign(selectedBrandCampaign);
  };

  return (
    <Container className="m-0 p-0">
      <Card>
        <CardBody>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Row
              form
              style={{ justifyContent: "space-between" }}
              className="px-2"
            >
              <Col md={6}>
                <FormGroup tag="fieldset">
                  <h6 className="h6">Watermark :</h6>
                  <Row>
                    <Col md={4}>
                      <FormGroup>
                        <CustomInput
                          type="radio"
                          name="watermarkSupport"
                          id="watermarkSupport1"
                          label="Enable"
                          value={true}
                          onChange={handleWatermarkChange}
                          defaultChecked={props.watermarkSupport}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <CustomInput
                          type="radio"
                          name="watermarkSupport"
                          id="watermarkSupport2"
                          label="Disable"
                          value={false}
                          onChange={handleWatermarkChange}
                          defaultChecked={
                            !props.forCreate && !props.watermarkSupport
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col md={5}>
                <FormGroup tag="fieldset">
                  <h6 className="h6">Event Type:</h6>
                  <Row>
                    <Col md={4}>
                      <FormGroup check>
                        <CustomInput
                          type="radio"
                          name="eventType"
                          id="eventType1"
                          label="default"
                          value="default"
                          onChange={handleEventTypeChange}
                          defaultChecked={props.eventType === "default"}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <CustomInput
                          type="radio"
                          name="eventType"
                          id="eventType2"
                          label="event"
                          value="event"
                          onChange={handleEventTypeChange}
                          defaultChecked={props.eventType === "event"}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={5}>
                      <FormGroup>
                        <CustomInput
                          type="radio"
                          name="eventType"
                          id="eventType3"
                          label="brand_campaign"
                          value="brand_campaign"
                          onChange={handleEventTypeChange}
                          defaultChecked={props.eventType === "brand_campaign"}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
            <Row
              form
              style={{ justifyContent: "space-between" }}
              className="px-2"
            >
              <Col md={5}>
                <FormGroup>
                  <small
                    ref={pngWarningRef}
                    id="pngAlert"
                    className="d-none text-danger mt-n4"
                  >
                    (Choose a 512*512 png file)
                  </small>
                  <Input
                    type="file"
                    className="custom-file-input"
                    name="resourceFile"
                    accept=".png"
                    onChange={(e) => {
                      handlePngFileChange(e);
                    }}
                  />
                  <Label className="custom-file-label" id="resourceFile">
                    {resourceFilePng === null
                      ? "Choose a png file"
                      : "Png File: " + selectedPngFileName}
                  </Label>
                </FormGroup>
              </Col>
              <Col md={5}>
                <FormGroup className="custom-file">
                  <small
                    ref={gifWarningRef}
                    id="gifAlert"
                    className="d-none text-danger mt-n4"
                  >
                    (Choose a 512*512 gif file)
                  </small>
                  <Input
                    type="file"
                    className="custom-file-input"
                    name="resourceFile"
                    accept=".gif"
                    onChange={(e) => {
                      handleGifFileChange(e);
                    }}
                  />
                  <Label className="custom-file-label" id="resourceFile">
                    {resourceFileGif === null
                      ? "Choose a gif file"
                      : "Gif File: " + selectedGifFileName}
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row               
            form
            style={{ justifyContent: "space-between" }}
            className="px-2">
            <Col md={5}>
                <FormGroup className="custom-file">
                  <small
                    ref={nonTransparentGiftWarningRef}
                    id="nonTransparentGifAlert"
                    className="d-none text-danger mt-n4"
                  >
                    (Choose a 512*512 gif file)
                  </small>
                  <Input
                    type="file"
                    className="custom-file-input"
                    name="resourceFile"
                    accept=".gif"
                    onChange={(e) => {
                      handleNonTransparentGifFileChange(e);
                    }}
                  />
                  <Label className="custom-file-label" id="resourceFile">
                    {"Choose a non transparent gif file"}
                  </Label>
                </FormGroup>
              </Col>
              
            </Row>
                  <Col lg={6} md={6}>
                    {(selectedNonTransparentGifURL!=="") ? (
                      <Col className="pb-4">
                        <img
                          src={selectedNonTransparentGifURL}
                          alt="Static Icon"
                          width="120"
                        />
                      </Col>
                    ) : (
                      <Col></Col>
                    )}
                  </Col>
            <br />
            <Row               
            form
            style={{ justifyContent: "space-between" }}
            className="px-2">
            <Col md={5}>
                <FormGroup className="custom-file">
                  <small
                    ref={smartBigmojiGifWarningRef}
                    id="smartBigmojiGifAlert"
                    className="d-none text-danger mt-n4"
                  >
                    (Choose a 512*512 gif file)
                  </small>
                  <Input
                    type="file"
                    className="custom-file-input"
                    name="resourceFile"
                    accept=".gif"
                    onChange={(e) => {
                      handleSmartBigmojiGifFileChange(e);
                    }}
                  />
                  <Label className="custom-file-label" id="resourceFile">
                    {"Choose a smart bigmoji gif file"}
                  </Label>
                </FormGroup>
              </Col>
              
            </Row>
                  <Col lg={6} md={6}>
                    {(selectedSmartBigmojiGifURL!=="") ? (
                      <Col className="pb-4">
                        <img
                          src={selectedSmartBigmojiGifURL}
                          alt="Static Icon"
                          width="120"
                        />
                      </Col>
                    ) : (
                      <Col></Col>
                    )}
                  </Col>
            <br />
            <Row
              form
              style={{ justifyContent: "space-between" }}
              className="px-2"
            >
              <Col md={5}>
                <FormGroup>
                  <Label>Start Date:</Label>
                  <Input
                    type="date"
                    onChange={handleStartDateChange}
                    value={startDateFormatted}
                  ></Input>
                </FormGroup>
              </Col>
              <Col md={5}>
                <FormGroup>
                  <Label>End Date:</Label>
                  <Input
                    type="date"
                    onChange={handleEndDateChange}
                    value={endDateFormatted}
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
            <br />
            <Row
                form
                style={{ justifyContent: "space-between" }}
                className="px-2"
            >
              <Col lg={5} >
                <Label>Select Brand: </Label>
                <Select
                  id="stickerBrandMapping"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="stickerBrandMapping"
                  onChange={(select) => handleBrandChange(select)}
                  value={selectedBrandCampaign}
                  options={brandCampaigns}
                  required
                />
              </Col>
              <Col md={5}>
                <FormGroup tag="fieldset">
                  <h6 className="h6">Is Combo BigMoji :</h6>
                  <Row>
                    <Col md={4}>
                      <FormGroup>
                        <CustomInput
                            type="radio"
                            name="isComboEmojiSticker"
                            id="isComboEmojiSticker1"
                            label="True"
                            value={true}
                            onChange={handleComboEmojiStickerChange}
                            defaultChecked={props.isComboEmojiSticker}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <CustomInput
                            type="radio"
                            name="isComboEmojiSticker"
                            id="isComboEmojiSticker2"
                            label="False"
                            value={false}
                            onChange={handleComboEmojiStickerChange}
                            defaultChecked={
                                !props.forCreate && !props.isComboEmojiSticker
                            }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>

              {/* is smart Bigmoji */}
             
            <Col md={5}>
              <FormGroup tag="fieldset">
                <h6 className="h6">Is Smart BigMoji :</h6>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                    <CustomInput
                      type="radio"
                      name="isSmartBigmoji"
                      id="isSmartBigmojiTrue"
                      label="True"
                      value={true}
                      onChange={handleSmartBigmojiChange}
                      checked={isSmartBigmoji}
                    />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <CustomInput
                          type="radio"
                          name="isSmartBigmoji"
                          id="isSmartBigmojiFalse"
                          label="False"
                          value={false}
                          onChange={handleSmartBigmojiChange}
                          checked={!isSmartBigmoji}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </FormGroup>
            </Col>

            <Col md={5}>
              <FormGroup tag="fieldset">
                <h6 className="h6">Auto Download :</h6>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                    <CustomInput
                      type="radio"
                      name="autoDownload"
                      id="autoDownloadTrue"
                      label="True"
                      value={true}
                      onChange=  {handleAutoDownloadChange}
                      checked={autoDownload}
                    />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <CustomInput
                          type="radio"
                          name="autoDownload"
                          id="autoDownloadFalse"
                          label="False"
                          value={false}
                          onChange={handleAutoDownloadChange}
                          checked={!autoDownload}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </FormGroup>
            </Col>

            </Row>
          </Form>
          <br />
          <Button
            className="d-block m-auto"
            color="primary"
            onClick={handleSubmit}
            disabled={isSubmitEnabled}
          >
            {props.forCreate ? "Upload" : "Update Image"}
          </Button>
        </CardBody>
      </Card>
      <ResponseModal
        show={successModalDisplay}
        onHide={(e) => redirectTo(`/stickers/emoji-stickers/${emojiID}`)}
        modalheading={"Success"}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={(e) => setFailureModalDisplay(false)}
        modalheading={"Error"}
        modaltext={failureModalText}
      />
    </Container>
  );
};
export default CreateEmojiImage;

export let Config = {
  KEYPRESS_DOWN: 13,
  PUBLISHED_STATUS: "Published",
  UNPUBLISHED_STATUS: "Unpublished",
  DEACTIVATED_STATUS: "Deactivated",
  ACTIVATED_STATUS: "Activated",
  COMPLETED_STATUS: "Completed",
  COMPLETED: "completed",
  NOT_STARTED: "not_started",
  ONGOING: "ongoing",
  FAILED: "failed",
  ACTIVATE_BUTTON: "Activate",
  DEACTIVATE_BUTTON: "Deactivate",
  PUBLISH_BUTTON: "Publish",
  UPDATED_AT_DESCENDING: "updated_at desc",
  UPDATED_AT_ASCENDING: "updated_at asc",
  CREATED_AT_DESCENDING: "created_at desc",
  CREATED_AT_ASCENDING: "created_at asc",
  PRIORITY_DESCENDING: "priority desc",
  PRIORITY_ASCENDING: "priority asc",

  UpdatedAtDescending: "updated_at desc",
  UpdatedAtAscending: "updated_at asc",
  CreatedAtDescending: "created_at desc",
  CreatedAtAscending: "created_at asc",
  PUBLISHED_AT_ASCENDING: "published_at asc",
  PUBLISHED_AT_DESCENDING: "published_at desc",
  PriorityDescending: "priority desc",
  ProirityAscending: "priority asc",
  IdDescending: "id desc",
  IdAscending: "id asc",
  PRICING_PREMIUM: "premium",

  SEARCH_BY_DEFAULT: "tag",
  SEARCH_BY_NAME: "name",
  SEARCH_BY_TITLE: "title",

  FORM_TYPE_EDIT: "edit",
  FORM_TYPE_CREATE: "create",
};

export const successModalHeading = "Success";
export const alertModalHeading = "Alert";
export const failureModalHeading = "Error";
export const rulesetCreatedSuccessfullyText = "Ruleset created Successfully.";
export const options = [
  { label: "Allow", value: "allow" },
  { label: "Deny", value: "deny" },
];

export const deactivatedTagColor = "rgba(237, 76, 76, 0.5)";
export const activatedTagColor = "rgba(102, 227, 89, 0.5)";
export const tagFontSize = "12px";

export const gendersList = [
  {
    label: "Unisex",
    value: "unisex",
  },
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
];
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { FaPlus, FaMinus, FaChevronUp, FaChevronDown } from "react-icons/fa";
import {
  getClients,
  getCountries,
  getRegions,
  getCities,
} from "../../../../utilities/apiUtils/stickers";
import {
  Collapse,
  Badge,
  Row,
  Col,
  Card,
  Label,
  CardBody,
  CardHeader,
  Button,
} from "reactstrap";

const options = [
  { label: "Allow", value: "allow" },
  { label: "Deny", value: "deny" },
];

const LocationRule = (props) => {
  var firstLoading = true;
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [countryRule, setCountryRule] = useState({});
  const [regionRule, setRegionRule] = useState({});
  const [cityRule, setCityRule] = useState({});
  const [ruleEffect, setRuleEffect] = useState({});

  const _addLocationRule = () => {
    props.addLocationRule();
  };

  const _removeLocationRule = () => {
    props.removeLocationRule(props.rule.id);
  };

  useEffect(() => {
    const _getCountries = async () => {
      let response = await getCountries();
      setCountries(response);
    };
    _getCountries();
  }, []);

  const handleCountries = async (event) => {
    setCountryRule({});
    setRegionRule({});
    setCityRule({});
    setRegions([]);
    setCities([]);

    if (event != null) {
      setCountryRule(event);
      let response = await getRegions(event.value);

      handleRegions();
      props.updateRule(
        props.rule.id,
        event.value,
        regionRule.value,
        cityRule.value,
        ruleEffect.value
      );

      props.updateRuleSummary(
        props.rule.id,
        event.label,
        regionRule.label,
        cityRule.label,
        ruleEffect.label
      );

      setRegions(response);
    }
  };

  const handleRegions = async (event) => {
    setRegionRule({});
    setCityRule({});
    setCities([]);

    if (event != null) {
      setRegionRule(event);
      let response = await getCities(countryRule.value, event.value);
      props.updateRule(
        props.rule.id,
        countryRule.value,
        event.value,
        cityRule.value,
        ruleEffect.value
      );

      props.updateRuleSummary(
        props.rule.id,
        countryRule.label,
        event.label,
        cityRule.label,
        ruleEffect.label
      );

      setCities(response);
    }
  };

  const handleCities = async (event) => {
    setCityRule({});
    if (event != null) {
      props.updateRule(
        props.rule.id,
        countryRule.value,
        regionRule.value,
        event.value,
        ruleEffect.value
      );
      props.updateRuleSummary(
        props.rule.id,
        countryRule.label,
        regionRule.label,
        event.label,
        ruleEffect.label
      );
      setCityRule(event);
    }
  };

  const handleEffect = async (event) => {
    setRuleEffect({});
    if (event != null) {
      props.updateRule(
        props.rule.id,
        countryRule.value,
        regionRule.value,
        cityRule.value,
        event.value
      );
      setRuleEffect(event);
    }
  };

  useEffect(() => {
    const _setInitValue = async () => {
      if (countries && countries.length > 0) {
        var country = countries.find(
          (item) => item.value === props.rule.countryID
        );

        setCountryRule(country);
        let _regions = await getRegions(country.value);

        setRegions(_regions);
        var region = _regions.find(
          (item) => item.value === props.rule.regionID
        );

        setRegionRule(region);
        let _cities = await getCities(country.value, region.value);

        setCities(_cities);

        var city = _cities.find((item) => item.value === props.rule.cityID);

        setCityRule(city);
        var effect = options.find((item) => item.value === props.rule.effect);
        setRuleEffect(effect);

        props.updateRuleSummary(
          props.rule.id,
          country.label,
          region.label,
          city.label,
          effect.label
        );
        firstLoading = false;
      }
    };
    _setInitValue();
  }, [countries, props.rule]);

  return (
    <Row className="mr-1">
      <Col md={3}>
        <Label>Country</Label>
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          name="country"
          placeholder="Select Country"
          options={countries}
          onChange={(option) => handleCountries(option)}
          isDisabled={!props.updateAllowed}
          value={countryRule}
          required
        />
      </Col>
      <Col md={3}>
        <Label>Region</Label>

        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          name="region"
          placeholder="Select region"
          options={regions}
          value={regionRule}
          onChange={(option) => handleRegions(option)}
          isDisabled={!props.updateAllowed}
          required
        />
      </Col>
      <Col md={3}>
        <Label>City</Label>

        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          name="city"
          placeholder="Select City"
          options={cities}
          value={cityRule}
          onChange={(option) => handleCities(option)}
          isDisabled={!props.updateAllowed}
          required
        />
      </Col>
      <Col md={3}>
        <Label>Effect</Label>

        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          name="locationEffect"
          placeholder="Select Effects"
          options={options}
          value={ruleEffect}
          onChange={(option) => handleEffect(option)}
          isDisabled={!props.updateAllowed}
          required
        />
      </Col>
      {props.updateAllowed ? (
        <Col md={1} className="align-self-end">
          {props.rule.id === 0 ? (
            <Button onClick={_addLocationRule} color="transparent">
              <FaPlus />
            </Button>
          ) : (
            <Button onClick={_removeLocationRule} color="transparent">
              <FaMinus />
            </Button>
          )}
        </Col>
      ) : (
        <div></div>
      )}
    </Row>
  );
};

const ClientRule = (props) => {
  var firstLoading = true;
  const [clients, setClients] = useState([]);
  const [clientRule, setClientRule] = useState({});
  const [ruleEffect, setRuleEffect] = useState({});

  const _addClientRule = (event) => {
    props.addClientRule();
  };

  const _removeClientRule = (event) => {
    props.removeClientRule(props.rule.id);
  };

  const handleClientRule = async (event) => {
    setClientRule({});
    if (event != null) {
      props.updateRule(props.rule.id, event.value, ruleEffect.value);
      props.updateRuleSummary(props.rule.id, event.label, ruleEffect.label);
      setClientRule(event);
    }
  };

  const handleEffect = async (event) => {
    setRuleEffect({});
    if (event != null) {
      setRuleEffect(event);
      props.updateRule(props.rule.id, clientRule.value, event.value);
      props.updateRuleSummary(props.rule.id, clientRule.label, event.label);
    }
  };

  useEffect(() => {
    const _getClients = async () => {
      let response = await getClients();
      setClients(response);
    };
    _getClients();
  }, []);

  useEffect(() => {
    const _setInitValue = async () => {
      if (firstLoading && clients.length > 0) {
        var client = clients.find(
          (item) =>
            item.value.toLowerCase() === props.rule.clientID.toLowerCase()
        );

        setClientRule(client);

        var effect = options.find(
          (item) => item.value.toLowerCase() === props.rule.effect.toLowerCase()
        );
        setRuleEffect(effect);

        if (client && effect)
          props.updateRuleSummary(props.rule.id, client.label, effect.label);

        firstLoading = false;
      }
    };
    _setInitValue();
  }, [clients, props.rule]);

  return (
    <Row className="mr-1">
      <Col md={6}>
        <Label>Name</Label>
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          name="clients"
          placeholder="Select Clients"
          value={clientRule}
          options={clients}
          onChange={(option) => handleClientRule(option)}
          isDisabled={!props.updateAllowed}
          required
        />
      </Col>
      <Col md={4}>
        <Label>Effect</Label>
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          name="clientEffect"
          placeholder="Select Effects"
          options={options}
          value={ruleEffect}
          onChange={(option) => handleEffect(option)}
          isDisabled={!props.updateAllowed}
          required
        />
      </Col>
      {props.updateAllowed ? (
        <Col md={1} className="align-self-end">
          {props.rule.id === 0 ? (
            <Button onClick={_addClientRule} color="transparent">
              <FaPlus />
            </Button>
          ) : (
            <Button onClick={_removeClientRule} color="transparent">
              <FaMinus />
            </Button>
          )}
        </Col>
      ) : (
        <div></div>
      )}
    </Row>
  );
};

const RuleSummary = (props) => {
  return (
    <div>
      <Row className="my-1 align-items-start">
        <Col md={1} className="p-0 ml-3 mt-2">
          <h6>Clients</h6>
        </Col>
        {props.clientRules
          ? props.clientRules.map(function (object, i) {
              return (
                <Col className="p-0 col-auto  mt-2" key={i}>
                  <Badge
                    className="float-left"
                    color={object.effect === "Allow" ? "success" : "danger"}
                  >
                    {object.client}
                  </Badge>
                </Col>
              );
            })
          : {}}
      </Row>
      <Row className="my-1 align-items-start">
        <Col className="pl-0 ml-3 mt-2" md={1}>
          <h6>Locations</h6>
        </Col>
        {props.locationRules
          ? props.locationRules.map(function (object, i) {
              return (
                <Col className="pl-0 col-auto mt-2" key={i}>
                  <Badge
                    className="float-left"
                    color={object.effect === "Allow" ? "success" : "danger"}
                  >
                    {object.country} : {object.region} : {object.city}
                  </Badge>
                </Col>
              );
            })
          : {}}
      </Row>
    </div>
  );
};

const RulesCard = (props) => {
  const [clientRules, setClientRules] = useState([
    { id: 0, clientID: "global", effect: "allow" },
  ]);
  const [locationRules, setLocationRules] = useState([
    {
      id: 0,
      countryID: 240,
      regionID: 1,
      cityID: 1,
      effect: "allow",
    },
  ]);
  const [activate, setActivate] = useState(true);
  const [toggle, setToggle] = useState(false);
  const [clientRulesSummary, setClientRulesSummary] = useState([]);
  const [locationRulesSummary, setLocationRulesSummary] = useState([]);

  const _updateClientRuleSummary = (clientRuleID, clientvalue, effect) => {
    const index = clientRulesSummary.findIndex((rule) => {
      return rule.id === clientRuleID;
    });

    if (index === -1) {
      setClientRulesSummary((prev) => {
        prev.push({
          id: clientRuleID,
          client: clientvalue,
          effect: effect,
        });
        return [...prev];
      });
    } else {
      setClientRulesSummary((prev) => {
        prev[index].client = clientvalue;
        prev[index].effect = effect;
        return [...prev];
      });
    }
  };

  const _updateLocationRuleSummary = (
    locationRuleID,
    country,
    region,
    city,
    effect
  ) => {
    const index = locationRulesSummary.findIndex((rule) => {
      return rule.id === locationRuleID;
    });
    if (index === -1) {
      setLocationRulesSummary((prev) => {
        prev.push({
          id: locationRuleID,
          country: country,
          region: region,
          city: city,
          effect: effect,
        });

        return [...prev];
      });
    } else {
      setLocationRulesSummary((prev) => {
        prev[index].country = country;
        prev[index].region = region;
        prev[index].city = city;
        prev[index].effect = effect;
        return [...prev];
      });
    }
  };

  const _updateClientRule = (clientRuleID, clientID, effect) => {
    const index = clientRules.findIndex((rule) => {
      return rule.id === clientRuleID;
    });

    setClientRules((prev) => {
      prev[index].clientID = clientID;
      prev[index].effect = effect;
      return [...prev];
    });

    props.updateRules(props.rule.id, clientRules, locationRules, activate);
  };

  const _updateLocationRule = (
    locationRuleID,
    countryID,
    regionID,
    cityID,
    effect
  ) => {
    const index = locationRules.findIndex((rule) => {
      return rule.id === locationRuleID;
    });
    setLocationRules((prev) => {
      prev[index].countryID = countryID;
      prev[index].regionID = regionID;
      prev[index].cityID = cityID;
      prev[index].effect = effect;
      return [...prev];
    });
    props.updateRules(props.rule.id, clientRules, locationRules, activate);
  };

  const _updateActivate = (status) => {
    setActivate(status);
    props.updateRules(props.rule.id, clientRules, locationRules, status);
  };

  useEffect(() => {
    const SetActivate = async () => {
      setActivate(props.rule.activate);
    };
    SetActivate();
  });

  useEffect(() => {
    const SetClientRules = async () => {
      for (let i = 0; i < props.rule.clientRules.length; i++) {
        props.rule.clientRules[i].id = i;
      }
      setClientRules(props.rule.clientRules);
    };
    SetClientRules();
  }, []);

  useEffect(() => {
    const SetLocationRules = async () => {
      for (let i = 0; i < props.rule.locationRules.length; i++) {
        props.rule.locationRules[i].id = i;
      }
      setLocationRules(props.rule.locationRules);
    };
    SetLocationRules();
  }, []);

  const addClientRule = () => {
    var _updateClientRules = clientRules;
    _updateClientRules.push({
      id: clientRules[clientRules.length - 1].id + 1,
      clientID: "global",
      effect: "allow",
    });
    setClientRules(_updateClientRules);
    props.updateRules(
      props.rule.id,
      _updateClientRules,
      locationRules,
      activate
    );
  };

  const removeClientRule = (id) => {
    const _updateClientRules = clientRules.filter((rule) => rule.id !== id);
    const _updateClientRulesSummary = clientRulesSummary.filter(
      (rule) => rule.id !== id
    );

    for (let i = 0; i < _updateClientRulesSummary.length; i++) {
      _updateClientRulesSummary[i]["id"] = i;
    }

    setClientRules(_updateClientRules);
    setClientRulesSummary(_updateClientRulesSummary);
    props.updateRules(
      props.rule.id,
      _updateClientRules,
      locationRules,
      activate
    );
  };

  const addLocationRule = (event) => {
    var _updateLocationRules = locationRules;

    _updateLocationRules.push({
      id: locationRules[locationRules.length - 1].id + 1,
      countryID: 240,
      regionID: 1,
      cityID: 1,
      effect: "allow",
    });

    setLocationRules(_updateLocationRules);
    props.updateRules(
      props.rule.id,
      clientRules,
      _updateLocationRules,
      activate
    );
  };

  const removeLocationRule = (id) => {
    const _updateLocationRules = locationRules.filter((rule) => rule.id !== id);
    const _updateLocationRulesSummary = locationRulesSummary.filter(
      (rule) => rule.id !== id
    );
    for (let i = 0; i < _updateLocationRulesSummary.length; i++) {
      _updateLocationRulesSummary[i]["id"] = i;
    }

    setLocationRules(_updateLocationRules);
    setLocationRulesSummary(_updateLocationRulesSummary);

    props.updateRules(
      props.rule.id,
      clientRules,
      _updateLocationRules,
      activate
    );
  };

  const _removeRule = () => {
    props.removeRule(props.rule.id);
  };

  return (
    <Card>
      <CardHeader onClick={() => setToggle(!toggle)} className="pt-3 pb-0">
        <div className="container">
          <div className="row">
            <Col md="auto" className="pl-0">
              <h4>
                <strong>Rule {props.rule.id + 1}</strong>
              </h4>
            </Col>
            <div className="ml-auto">
              {toggle ? <FaChevronUp /> : <FaChevronDown />}
            </div>
          </div>
        </div>
      </CardHeader>
      <Collapse isOpen={!toggle}>
        <CardBody md={12} className="pb-3 pt-1">
          <RuleSummary
            key={props.rule.id}
            clientRules={clientRulesSummary}
            locationRules={locationRulesSummary}
          />
        </CardBody>
      </Collapse>
      <Collapse isOpen={toggle}>
        <CardBody className="pb-3 pt-1" md={1} width={10}>
          <p className="mt-3 mb-1">
            <strong>Clients</strong>
          </p>
          {clientRules
            ? clientRules.map(function (object, i) {
                return (
                  <ClientRule
                    key={i}
                    rule={object}
                    updateRule={_updateClientRule}
                    updateRuleSummary={_updateClientRuleSummary}
                    addClientRule={addClientRule}
                    removeClientRule={removeClientRule}
                    updateAllowed={props.updateAllowed}
                  />
                );
              })
            : {}}
          <p className="mt-3 mb-1">
            <strong>Location</strong>
          </p>
          {locationRules
            ? locationRules.map(function (object, i) {
                return (
                  <LocationRule
                    key={object.id}
                    rule={object}
                    updateRule={_updateLocationRule}
                    updateRuleSummary={_updateLocationRuleSummary}
                    addLocationRule={addLocationRule}
                    removeLocationRule={removeLocationRule}
                    updateAllowed={props.updateAllowed}
                  />
                );
              })
            : {}}
          {props.updateAllowed ? (
            <div className="mt-4 d-flex flex-nowrap">
              <div className="order-1 p-2">
                <Button onClick={_removeRule} color={"danger"}>
                  Delete Rule
                </Button>
              </div>

              <div className="order-2 p-2">
                {activate ? (
                  <Button
                    onClick={(e) => _updateActivate(false)}
                    color={"warning"}
                  >
                    Deactivate Rule
                  </Button>
                ) : (
                  <Button
                    onClick={(e) => _updateActivate(true)}
                    color={"success"}
                  >
                    Activate Rule
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </CardBody>
      </Collapse>
    </Card>
  );
};

export default RulesCard;

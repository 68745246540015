import React, { useState } from "react";
import TargetingRules from "../TargetingRules";
import ThemeDetailBoard from "./components/ThemeDetailBoard";
import ThemeFormContainer from "./ThemeFormContainer";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { permissions } from "../../../utilities/commonUtil";

import {
  getThemeRules,
  saveThemeRules,
  getCountries,
  getRegions,
  getCities,
} from "../../../utilities/apiUtils/themes";
import { getClients } from "../../../utilities/apiUtils/stickers";

const ThemeDetail = (props) => {
  let id = props.match.params.id;

  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  const showRules = () => {
    return (
      allowedPermissions.includes(permissions.clientList) &&
      allowedPermissions.includes(permissions.countryList) &&
      allowedPermissions.includes(permissions.regionList) &&
      allowedPermissions.includes(permissions.cityList) &&
      allowedPermissions.includes(permissions.keyboardThemeRuleView)
    );
  };

  const updateAllowed = () => {
    return allowedPermissions.includes(permissions.keyboardThemeUpdate);
  };

  const updateRulesAllowed = () => {
    return allowedPermissions.includes(permissions.keyboardThemeRuleUpdate);
  };

  return (
    <div>
      <div style={{ width: "25%", float: "left", position: "fixed" }}>
        <ThemeDetailBoard id={id} />
      </div>
      <div style={{ width: "65%", float: "right" }}>
        <ThemeFormContainer
          {...props}
          id={id}
          formName={"Details"}
          updateAllowed={updateAllowed()}
        />

        {showRules() ? (
          <TargetingRules
            id={id}
            getRules={getThemeRules}
            saveRules={saveThemeRules}
            getClients={getClients}
            getCountries={getCountries}
            getRegions={getRegions}
            getCities={getCities}
            updateRulesAllowed={updateRulesAllowed()}
          />
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

export default ThemeDetail;

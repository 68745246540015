import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { Modal } from "react-bootstrap";
import { FaPlus, FaMinus } from "react-icons/fa";
import { CgCloseR } from 'react-icons/cg';
import { Row, Col, FormGroup, Input, Form, Button } from "reactstrap";
import Select, { components } from "react-select";
import { Checkbox, Radio } from 'antd';
import { createDeepCopy, parseDateInMoment, getDateValue, getMetricsFromBuyModel, metricsOptions, startAtSuffix, endAtSuffix, getNextDate, isDateBetween, isDateGreater, getDailyTarget } from '../../../config/configAutoCappings';
import { replaceUnderscoreAndCapitalize } from '../../../utilities/commonUtil';
import ResponseModal from '../ResponseModal';

const brandCampaignInitialState = {
  campaign: null,
  mediaBuyType: "",
  startAt: "",
  endAt: "",
  deliveryTarget: "",
  roAmountINR: null,
  entities: [],
  metrics: null,
  overriddenTarget: "",
  overriddenMetrics: null,
}

const ruleInitialState = {
  entities: [],
  metrics: null,
  target: "",
  cappingDistribution: "Even",
  distributionType: null,
  distribution: [],
}

const AutoCappingModal = ({ show = false, type, brandCampaigns, brandCampaignsAds, autoCappingsDetails, formData, onSubmit = () => { }, onHide = () => { } }) => {
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");
  const [brandCampaignDetails, setBrandCampaignDetails] = useState(brandCampaignInitialState)
  const [rules, setRules] = useState([{ ...ruleInitialState }])
  const [smartSuggestions, setSmartSuggestions] = useState([])
  const [selectedSmartSuggestions, setSelectedSmartSuggestions] = useState({})
  const [brandCampaignOptions, setBrandCampaignOptions] = useState([])

  useEffect(() => {
    if (formData?.id) {
      const { name, mediaBuyType, deliveryTarget, roAmountINR, startAt, endAt, entities } = brandCampaigns.find(el => el.id === formData?.id)
      const metrics = getMetricsFromBuyModel(mediaBuyType)
      setBrandCampaignDetails({
        campaign: {
          label: name,
          value: formData?.id
        },
        mediaBuyType,
        startAt,
        endAt,
        deliveryTarget,
        roAmountINR,
        entities,
        metrics: metrics ? {
          label: metricsOptions.find(option => option.value === metrics)?.label ?? metrics,
          value: metrics
        } : null,
        overriddenTarget: metrics !== formData?.metrics ? formData?.target : null,
        overriddenMetrics: metrics !== formData?.metrics ? {
          label: metricsOptions.find(option => option.value === formData?.metrics)?.label ?? formData?.metrics,
          value: formData?.metrics
        } : null,
      })
      setRules(formData?.rules?.map(rule => {
        if (rule.distributionType === "absolute_value")
          return rule
        else return {
          ...rule,
          distribution: rule.distribution?.map(dis => ({
            ...dis,
            overallTarget: Math.ceil(dis.overallTarget / rule.target * 100),
          }))
        }
      }))
      const selectedSmartSuggestions = {}
      formData.rules.forEach((rule, index) => {
        rule.entities.forEach(entity => {
          if (entity.type === "smart_suggestion")
            selectedSmartSuggestions[entity.id] = index
        })
      })
      setSelectedSmartSuggestions(selectedSmartSuggestions)
    } else {
      setBrandCampaignDetails(brandCampaignInitialState)
      setRules([{ ...ruleInitialState }])
    }
  }, [formData, brandCampaigns])

  useEffect(() => {
    if (brandCampaigns?.length <= 0) {
      setBrandCampaignOptions([])
      return
    }
    // Add active brand campaigns in brand campaign options which have 1 or more entities and whose capping is not already created
    const autoCappedBrandCampaignsIdMap = new Map()
    autoCappingsDetails.forEach(el => {
      autoCappedBrandCampaignsIdMap.set(el.id, true)
    })
    const options = []
    brandCampaigns.forEach(campaign => {
      let diff = parseDateInMoment(campaign.endAt).startOf('day').diff(moment().startOf('day'), 'days');
      if (brandCampaignsAds[campaign.id]?.length > 0 && diff >= 0 && !autoCappedBrandCampaignsIdMap.get(campaign.id)) {
        options.push({
          value: campaign.id,
          label: campaign.name,
          ...campaign
        })
      }
    })
    setBrandCampaignOptions(options)
  }, [brandCampaigns, autoCappingsDetails, brandCampaignsAds])

  useEffect(() => {
    if (brandCampaignDetails.campaign?.value)
      setSmartSuggestions(brandCampaignsAds[brandCampaignDetails.campaign?.value] ?? [])
  }, [brandCampaignDetails.campaign, brandCampaignsAds])

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  const handleBrandCampaignChange = (campaign) => {
    const { id, name, mediaBuyType, deliveryTarget, roAmountINR, startAt, endAt, entities } = campaign
    const metrics = getMetricsFromBuyModel(mediaBuyType)
    setBrandCampaignDetails({
      campaign: {
        label: name,
        value: id
      },
      mediaBuyType,
      startAt,
      endAt,
      deliveryTarget,
      roAmountINR,
      entities,
      metrics: metrics ? {
        label: metricsOptions.find(option => option.value === metrics)?.label ?? metrics,
        value: metrics
      } : null,
      overriddenTarget: null,
      overriddenMetrics: null,
    })
    setRules([{
      ...ruleInitialState,
      target: deliveryTarget ?? ""
    }])
    setSelectedSmartSuggestions({})
  }

  const handleRulesChange = (event, index) => {
    const { name, value } = event.target
    const temp = [...rules]
    temp[index][name] = value
    setRules(temp)
  }

  const handleRulesRadioChange = (event, index) => {
    const { name, value } = event.target
    const temp = createDeepCopy(rules)
    temp[index][name] = value
    if (name === "cappingDistribution") {
      // Reset distribution array
      temp[index]["distribution"] = []

      // If even capping distribution, then distribution type is not required
      if (value === "Even")
        temp[index]["distributionType"] = null

      else if (value === "Custom") {
        // If custom capping distribution, set default distribution type to absolute_value and initialize distribition array
        temp[index]["distributionType"] = "absolute_value"

        // Prefill start date, end date and target
        temp[index]["distribution"].push({
          startAt: getDateValue(brandCampaignDetails.startAt),
          endAt: getDateValue(brandCampaignDetails.endAt),
          overallTarget: temp[index]["target"],
        })
      }
    } else if (name === "distributionType") {
      // Reset distribution array
      temp[index]["distribution"] = []

      // Initialize distribition array
      temp[index]["distribution"].push({
        startAt: getDateValue(brandCampaignDetails.startAt),
        endAt: getDateValue(brandCampaignDetails.endAt),
        overallTarget: value === "absolute_value" ? temp[index]["target"] : 100,
      })
    }
    setRules(temp)
  }

  const addRule = () => {
    setRules((prevState) => (
      prevState.concat(({
        ...ruleInitialState,
        target: Number(brandCampaignDetails.overriddenTarget ?? brandCampaignDetails.deliveryTarget) - prevState.reduce((acc, rule) => Math.abs(acc + Number(rule?.target)), 0)
      }))
    ));
  }

  const removeRule = (index) => {
    setRules((prevState) => (
      prevState.filter((_, i) => i !== index)
    ));
    const updatedSelectedSmartSuggestions = { ...selectedSmartSuggestions }
    Object.entries(selectedSmartSuggestions).forEach(([smartSuggestionId, ruleId]) => {
      if (ruleId === index) delete updatedSelectedSmartSuggestions[smartSuggestionId]
    })
    setSelectedSmartSuggestions(updatedSelectedSmartSuggestions)
  }

  const handleRulesDistributionChange = (event, ruleIndex, distributionIndex) => {
    const { name, value } = event.target
    const temp = createDeepCopy(rules)
    temp[ruleIndex]["distribution"][distributionIndex][name] = value
    setRules(temp)
  }

  const addRuleDistribution = (ruleIndex) => {
    const temp = createDeepCopy(rules)

    let target
    if (temp[ruleIndex]["distributionType"] === "absolute_value")
      target = Number(temp[ruleIndex]["target"]) - temp[ruleIndex]["distribution"]?.reduce((acc, dis) => Math.abs(acc + Number(dis?.overallTarget)), 0)
    else if (temp[ruleIndex]["distributionType"] === "percentage")
      target = 100 - temp[ruleIndex]["distribution"]?.reduce((acc, dis) => Math.abs(acc + Number(dis?.overallTarget)), 0)

    const disLen = temp[ruleIndex]["distribution"]?.length
    temp[ruleIndex]["distribution"].push({
      startAt: disLen >= 1 ? getNextDate(temp[ruleIndex]["distribution"][disLen - 1]["endAt"]) : getDateValue(brandCampaignDetails.startAt),
      endAt: getDateValue(brandCampaignDetails.endAt),
      overallTarget: target,
    })
    setRules(temp);
  }

  const removeRuleDistribution = (ruleIndex, distributionIndex) => {
    const temp = createDeepCopy(rules)
    const distribution = [...temp[ruleIndex]["distribution"]]
    temp[ruleIndex]["distribution"] = distribution.filter((_, i) => i !== distributionIndex)
    setRules(temp);
  }

  const handleSelectSmartSuggestion = (event, smartSuggestionId, ruleId) => {
    let updatedSelectedSmartSuggestions = { ...selectedSmartSuggestions }

    // Entity and rule ID will have one-to-one relationship, so while select, store the value in key-value pair like - entity: ruleID
    if (event.target.checked) updatedSelectedSmartSuggestions[smartSuggestionId] = ruleId
    else delete updatedSelectedSmartSuggestions[smartSuggestionId] // while deselect, unlink entity from selected list

    setSelectedSmartSuggestions(updatedSelectedSmartSuggestions)
  }

  const handleHide = () => {
    setBrandCampaignDetails(brandCampaignInitialState)
    setRules([{ ...ruleInitialState }])
    onHide()
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    // Capping rule entities
    const ruleEntities = new Map()
    Object.entries(selectedSmartSuggestions).forEach(([smartSuggestionId, ruleId]) => {
      ruleEntities.set(ruleId, [...(ruleEntities.get(ruleId) ?? [])].concat({ id: Number(smartSuggestionId), type: "smart_suggestion" }))
    })

    // Error: if any rule doesn't have entities
    let error = false
    for (let i = 0; i < rules.length; i++) {
      if (!ruleEntities.has(i)) {
        error = true
        showErrorModal(`Please select atleast one entity in capping rule ${i + 1}`)
        break
      }
    }
    if (error) return

    // Error: if any rule's distribution has start date > end date
    for (let i = 0; i < rules.length; i++) {
      for (let j = 0; j < rules[i].distribution?.length; j++) {
        if (isDateGreater(rules[i].distribution[j].startAt, rules[i].distribution[j].endAt)) {
          error = true
          showErrorModal(`Start date can't be greater than end date`)
          break
        }
      }
      if (error) break
    }
    if (error) return

    // Form payload
    const metrics = brandCampaignDetails?.overriddenMetrics?.value ?? getMetricsFromBuyModel(brandCampaignDetails?.mediaBuyType)
    const payload = {
      id: brandCampaignDetails?.campaign?.value,
      name: brandCampaignDetails?.campaign?.label,
      target: metrics === getMetricsFromBuyModel(brandCampaignDetails?.mediaBuyType) ? brandCampaignDetails?.deliveryTarget : Number(brandCampaignDetails?.overriddenTarget ?? brandCampaignDetails?.deliveryTarget),
      buyModel: brandCampaignDetails?.mediaBuyType,
      metrics,
      rules: rules.map((rule, index) => ({
        entities: ruleEntities.get(index),
        metrics,
        target: Number(rule.target),
        distributionType: rule.cappingDistribution === "Custom"
          ? rule.distributionType
          : "even",
        distribution: rule.cappingDistribution === "Even" ? [{
          startAt: parseDateInMoment(brandCampaignDetails.startAt).format('YYYY-MM-DD') + startAtSuffix,
          endAt: parseDateInMoment(brandCampaignDetails.endAt).format('YYYY-MM-DD') + endAtSuffix,
          overallTarget: Number(rule.target),
          targetPerDay: getDailyTarget(rule.target, parseDateInMoment(brandCampaignDetails.startAt).format('YYYY-MM-DD') + startAtSuffix, parseDateInMoment(brandCampaignDetails.endAt).format('YYYY-MM-DD') + endAtSuffix)
        }] : rule.distribution.map(dis => ({
          startAt: parseDateInMoment(dis.startAt).format('YYYY-MM-DD') + startAtSuffix,
          endAt: parseDateInMoment(dis.endAt).format('YYYY-MM-DD') + endAtSuffix,
          overallTarget: rule.distributionType === "percentage"
            ? Math.ceil(Number(rule.target) * Number(dis.overallTarget) / 100)
            : Number(dis.overallTarget),
          targetPerDay: getDailyTarget(rule.distributionType === "percentage"
            ? Number(rule.target) * Number(dis.overallTarget) / 100
            : dis.overallTarget, parseDateInMoment(dis.startAt).format('YYYY-MM-DD') + startAtSuffix, parseDateInMoment(dis.endAt).format('YYYY-MM-DD') + endAtSuffix)
        }))
      }))
    }
    onSubmit(payload)
    handleHide()
  }

  const requiredComponent = (props) => (
    <div>
      <components.Input {...props} required={true} />
    </div>
  );

  const notRequiredComponent = (props) => (
    <div>
      <components.Input {...props} required={false} />
    </div>
  );

  return (
    <Modal
      show={show}
      onHide={handleHide}
      aria-labelledby="contained-modal-title-vcenter"
      size='lg'
      centered
      backdrop="static"
    >
      <Modal.Header className="bg-light" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {type} Auto Capping
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row form>
            <Col lg={12} md={12}>
              <FormGroup>
                <h4
                  className="font-weight-bolder mb-2"
                >
                  Brand Campaign <span className="text-danger">*</span><br />
                  {type === "Add" && <small className='text-info'>(Only active brand campaigns with atleast one entity mapped and no existing capping defined are shown)</small>}
                </h4>
                <Select
                  id="campaign"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="campaign"
                  placeholder="Select Brand Campaign"
                  value={brandCampaignDetails.campaign}
                  onChange={handleBrandCampaignChange}
                  options={brandCampaignOptions}
                  isDisabled={type === "Edit"}
                  components={{
                    Input:
                      (brandCampaignDetails.campaign?.value)
                        ? notRequiredComponent
                        : requiredComponent,
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          {brandCampaignDetails.campaign?.value && (
            <>
              <Row className="mb-3 py-3 px-2" style={{ backgroundColor: "#f5f9fc" }}>
                <Col lg={6} md={12}>
                  <ul className="pl-0 mb-0" style={{ listStyleType: "none" }}>
                    <li><strong>Campaign Name: </strong>{brandCampaignDetails.campaign?.label ?? "NA"}</li>
                    <li><strong>Start Date: </strong>{parseDateInMoment(brandCampaignDetails.startAt ?? "NA").format("Do MMM YYYY")}</li>
                    <li><strong>End Date: </strong>{parseDateInMoment(brandCampaignDetails.endAt ?? "NA").format("Do MMM YYYY")}</li>
                  </ul>
                </Col>
                <Col lg={6} md={12}>
                  <ul className="pl-0 mb-0" style={{ listStyleType: "none" }}>
                    <li><strong>Buy Model: </strong>{brandCampaignDetails.mediaBuyType ?? "NA"}</li>
                    <li><strong>Target: </strong>{brandCampaignDetails.deliveryTarget} {getMetricsFromBuyModel(brandCampaignDetails.mediaBuyType) ?? "NA"}</li>
                    <li><strong>Revenue: </strong>₹{brandCampaignDetails.roAmountINR ?? "NA"}</li>
                  </ul>
                </Col>
              </Row>
              <Row form>
                <Col lg={6} md={12}>
                  <FormGroup>
                    <h5
                      className="font-weight-bolder mb-2"
                      style={{ fontSize: "14px" }}
                    >
                      Capping Metrics <span className="text-danger">*</span><br /><small className='text-info'> (Metrics on which campaign auto capping needs to be done)</small>
                    </h5>
                    <Select
                      id="metrics"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="metrics"
                      placeholder="Select Capping Metrics"
                      value={brandCampaignDetails.overriddenMetrics ?? brandCampaignDetails.metrics}
                      onChange={(event) =>
                        setBrandCampaignDetails((prevState) => ({
                          ...prevState,
                          overriddenMetrics: event
                        }))}
                      options={metricsOptions}
                      components={{
                        Input:
                          (brandCampaignDetails.overriddenMetrics ?? brandCampaignDetails.metrics)
                            ? notRequiredComponent
                            : requiredComponent,
                      }}
                    />
                  </FormGroup>
                </Col>
                {getMetricsFromBuyModel(brandCampaignDetails.mediaBuyType) !== (brandCampaignDetails.overriddenMetrics?.value ?? brandCampaignDetails.metrics?.value) && (
                  <Col lg={6} md={12}>
                    <FormGroup>
                      <h5
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "14px" }}
                      >
                        Capping Target <span className="text-danger">*</span><small className='text-info'> (Since capping metrics is not same as buy model, so separate capping target needs to be defined)</small>
                        <small
                          className='text-danger font-weight-bolder cursor-pointer btn-link ml-2'
                          onClick={() =>
                            setBrandCampaignDetails((prevState) => ({
                              ...prevState,
                              overriddenTarget: null
                            }))
                          }>
                          Reset
                        </small>
                      </h5>
                      <Input
                        type="number"
                        name="target"
                        min={1}
                        placeholder="Enter Capping Target"
                        value={brandCampaignDetails.overriddenTarget ?? brandCampaignDetails.deliveryTarget}
                        onChange={(event) =>
                          setBrandCampaignDetails((prevState) => ({
                            ...prevState,
                            overriddenTarget: event.target.value
                          }))
                        }
                        required
                      />
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <hr />
              <Row form>
                <Col>
                  <h4 className="font-weight-bolder mb-2">Capping Rules <small className='text-info'> (Add atleast one capping rule)</small></h4>
                </Col>
                <Col>
                  <Button color='success' className="float-right" onClick={addRule}>Add Rule</Button>
                </Col>
              </Row>
              {rules?.map((rule, index) => (
                <Row key={`${brandCampaignDetails.campaign?.value}-${index + 1}`} form className="my-3">
                  <Col>
                    <h4
                      className="font-weight-bolder mb-2"
                    >
                      Rule {index + 1}
                    </h4>
                  </Col>
                  {rules.length > 1 && (
                    <Col>
                      <div className="float-right text-danger cursor-pointer" onClick={() => removeRule(index)}><CgCloseR size={20} /></div>
                    </Col>
                  )}
                  <Col md={12}>
                    <Row className="py-3 px-2" style={{ backgroundColor: "#f5f9fc" }}>
                      <Col lg={12} md={12} className="mb-3">
                        <h5 className="font-weight-bolder mb-2" style={{ fontSize: 16 }}>Entities <small className='text-info'> (Select entities on which rule needs to be applied)</small></h5>
                        <Row form>
                          <Col lg={4} md={12}>
                            <h5 className="font-weight-bolder mb-2" style={{ fontSize: 14 }}>Smart Suggestions</h5>
                            {smartSuggestions.map(smartSuggestion => (
                              <Checkbox
                                key={smartSuggestion.id}
                                className='px-1 my-1'
                                checked={smartSuggestion.id in selectedSmartSuggestions ? true : false}
                                disabled={smartSuggestion.id in selectedSmartSuggestions && selectedSmartSuggestions[smartSuggestion.id] !== index ? true : false}
                                onChange={(event) => handleSelectSmartSuggestion(event, smartSuggestion.id, index)}
                                style={{
                                  border: "1px solid #ccc",
                                  borderRadius: 4,
                                  backgroundColor: "#fff"
                                }}
                              >
                                <div className='d-flex align-items-center mt-1'>
                                  <img
                                    src={smartSuggestion.iconUrl}
                                    alt={smartSuggestion.name + " logo"}
                                    width={24}
                                    height={24}
                                    style={{
                                      objectFit: "contain",
                                      borderRadius: "0.25rem"
                                    }}
                                  />
                                  <span className='ml-2'>
                                    {smartSuggestion.id + ": "}
                                    {smartSuggestion.name?.length > 20
                                      ? smartSuggestion.name?.substr(0, 20)?.concat("...")
                                      : smartSuggestion.name
                                    }
                                  </span>
                                </div>
                              </Checkbox>
                            ))}
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={12} md={12}>
                        <FormGroup>
                          <h5
                            className="font-weight-bolder mb-2"
                            style={{ fontSize: "14px" }}
                          >
                            Target <span className="text-danger">*</span><br /><small className='text-info'> (Target that selected entities together needs to achieve)</small>
                          </h5>
                          <Input
                            type="number"
                            name="target"
                            min={1}
                            placeholder="Enter Target"
                            value={rule.target}
                            onChange={(event) => handleRulesChange(event, index)}
                            required
                          />
                          <div className='text-warning' style={{ fontSize: 12 }}>{
                            rules.reduce((acc, rule) => acc + Number(rule.target), 0) > Number(brandCampaignDetails.overriddenTarget ?? brandCampaignDetails.deliveryTarget)
                            && "⚠️ Sum of all rules' targets exceeds the brand campaign's target"
                          }</div>
                        </FormGroup>
                      </Col>
                      <Col lg={12} md={12}>
                        <FormGroup>
                          <h5
                            className="font-weight-bolder mb-2"
                            style={{ fontSize: "14px" }}
                          >
                            Capping Distribution <span className="text-danger">*</span><br />
                            <small className='text-info'>
                              ({rule.cappingDistribution === "Even"
                                ? rule.target
                                  ? `${getDailyTarget(rule.target, brandCampaignDetails.startAt, brandCampaignDetails.endAt)} ${brandCampaignDetails.overriddenMetrics?.value ?? brandCampaignDetails.metrics?.value} per day between ${parseDateInMoment(brandCampaignDetails.startAt).format("Do MMM YYYY")} and ${parseDateInMoment(brandCampaignDetails.endAt).format("Do MMM YYYY")}`
                                  : `${replaceUnderscoreAndCapitalize(brandCampaignDetails.overriddenMetrics?.value ?? brandCampaignDetails.metrics?.value)} will be evenly distributed in X days, between start and end date`
                                : "Custom date range combinations to achieve target metrics"
                              })
                            </small>
                          </h5>
                          <Radio.Group name='cappingDistribution' onChange={(event) => handleRulesRadioChange(event, index)} value={rule.cappingDistribution}>
                            <Radio value="Even">Even</Radio>
                            <Radio value="Custom">Custom</Radio>
                          </Radio.Group>
                        </FormGroup>
                      </Col>
                      {rule.cappingDistribution === "Custom" && (
                        <>
                          <Col lg={12} md={12}>
                            <FormGroup>
                              <h5
                                className="font-weight-bolder my-2"
                                style={{ fontSize: "14px" }}
                              >
                                Distribution Type <span className="text-danger">*</span>
                              </h5>
                              <Radio.Group name='distributionType' onChange={(event) => handleRulesRadioChange(event, index)} value={rule.distributionType}>
                                <Radio value="absolute_value">Absolute Value</Radio>
                                <Radio value="percentage">Percentage</Radio>
                              </Radio.Group>
                            </FormGroup>
                          </Col>
                          <Col lg={12} md={12}>
                            {rule.distribution?.map((distribution, i) => (
                              <Row key={`distribution-${index}-${i}`} form className="mb-3">
                                <Col>
                                  <FormGroup className="mb-0">
                                    <Input
                                      type="date"
                                      name="startAt"
                                      placeholder="Select Start Date"
                                      onChange={(event) => handleRulesDistributionChange(event, index, i)}
                                      value={getDateValue(distribution.startAt)}
                                      required
                                    />
                                    {isDateGreater(getDateValue(distribution.startAt), getDateValue(distribution.endAt))
                                      ? <div className='text-danger' style={{ fontSize: 12 }}>Start date can't be greater than end date</div>
                                      : !isDateBetween(getDateValue(distribution.startAt), parseDateInMoment(brandCampaignDetails.startAt).format("YYYY-MM-DD"), parseDateInMoment(brandCampaignDetails.endAt).format("YYYY-MM-DD"))
                                        ? <div className='text-warning' style={{ fontSize: 12 }}>
                                          <span role="img" aria-label="warning">⚠️</span>
                                          Start date should be between brand campaign's start and end date
                                        </div>
                                        : null
                                    }
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <FormGroup className="mb-0">
                                    <Input
                                      type="date"
                                      name="endAt"
                                      placeholder="Select End Date"
                                      onChange={(event) => handleRulesDistributionChange(event, index, i)}
                                      value={getDateValue(distribution.endAt)}
                                      required
                                    />
                                    <div className='text-warning' style={{ fontSize: 12 }}>{
                                      !isDateBetween(getDateValue(distribution.endAt), parseDateInMoment(brandCampaignDetails.startAt).format("YYYY-MM-DD"), parseDateInMoment(brandCampaignDetails.endAt).format("YYYY-MM-DD"))
                                      && "⚠️ End date should be between brand campaign's start and end date"
                                    }</div>
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <FormGroup className="mb-0">
                                    <Input
                                      type="number"
                                      name="overallTarget"
                                      min={1}
                                      max={rule.distributionType === "percentage" ? 100 : null}
                                      placeholder="Enter Target"
                                      value={distribution.overallTarget}
                                      onChange={(event) => handleRulesDistributionChange(event, index, i)}
                                      required
                                    />
                                    <div className='text-info' style={{ fontSize: 12 }}>{
                                      `(${getDailyTarget(
                                        rule.distributionType === "percentage"
                                          ? Number(rule.target) * Number(distribution.overallTarget) / 100
                                          : distribution.overallTarget,
                                        getDateValue(distribution.startAt) + startAtSuffix,
                                        getDateValue(distribution.endAt) + endAtSuffix
                                      )} ${brandCampaignDetails.overriddenMetrics?.value ?? brandCampaignDetails.metrics?.value} per day)`
                                    }</div>
                                    <div className='text-warning' style={{ fontSize: 12 }}>{
                                      rule.distributionType === "percentage"
                                        ?
                                        rule.distribution?.reduce((acc, dis) => acc + Number(dis.overallTarget), 0) > 100
                                        && "⚠️ Sum of all distribution' targets shouldn't be more than 100"
                                        :
                                        rule.distribution?.reduce((acc, dis) => acc + Number(dis.overallTarget), 0) > Number(rule.target)
                                        && "⚠️ Sum of all distribution' targets exceeds the rule's target"
                                    }</div>
                                  </FormGroup>
                                </Col>
                                <Col md={1}>
                                  {i === 0 ? (
                                    <Button onClick={() => addRuleDistribution(index)} color="transparent">
                                      <FaPlus />
                                    </Button>
                                  ) : (
                                    <Button onClick={() => removeRuleDistribution(index, i)} color="transparent">
                                      <FaMinus />
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                            ))}
                          </Col>
                        </>
                      )}
                    </Row>
                  </Col>
                  <Col md={12}>
                    {index !== rules?.length - 1 && <hr className='mb-0' />}
                  </Col>
                </Row>
              ))}
              <Row form>
                <Col>
                  <Button color='success' className="float-right" onClick={addRule}>Add Rule</Button>
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button color="primary" onClick={handleHide}>Close</Button>
          <Button type='submit' color='success'>Submit</Button>
        </Modal.Footer>
      </Form>
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={"Error"}
        modaltext={failureModalText}
      />
    </Modal>
  )
}

export default AutoCappingModal
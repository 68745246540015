import React from 'react'
import { Modal, Button } from "react-bootstrap";
import { Row, Col, FormGroup, Label, Input, Form } from "reactstrap";
import moment from "moment";

const RevenueDashboardNoteModal = ({ show = false, type, formData, setFormData = () => { }, onSubmit = () => { }, onHide = () => { } }) => {
  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit()
  }

  let getdateValue = (date) => {
    if (date === null) {
      return null;
    }
    return moment(date).format("YYYY-MM-DD");
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size='lg'
      backdrop="static"
    >
      <Modal.Header className="bg-light" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {type} Note
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row form>
            <Col lg={12} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Title <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="title"
                  placeholder="Enter Title"
                  value={formData.title}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col lg={12} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Date <span className="text-danger">*</span>
                </Label>
                <Input
                  type="date"
                  name="date"
                  onChange={handleChange}
                  value={getdateValue(formData.date)}
                  required
                />
              </FormGroup>
            </Col>
            <Col lg={12} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Description <span className="text-danger">*</span>
                </Label>
                <Input
                  type="textarea"
                  name="description"
                  rows={5}
                  placeholder="Enter Description"
                  value={formData.description}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
          <Button type='submit' className='btn-success'>Submit</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default RevenueDashboardNoteModal
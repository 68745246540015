import React from "react";
import Select from "react-select";
import {
  Card,
  CardBody,
  Row,
  Form,
  Col,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import { X } from "react-feather";
import { iconSize } from "../../../config/UserConfig";

const ResourceVariantCard = (props) => {
  return (
    <Card className="mb-3" key={props.index}>
      <CardBody>
        <Form>
          {props.formType === "create" && props.variants.length > 1 ? (
            <X
              size={iconSize}
              color="red"
              onClick={(e) => {
                props.deleteVariant(props.index);
              }}
              style={{ float: "right", position: "relative", top: "-0.6rem" }}
            ></X>
          ) : null}
          <Row>
            {props.formType === "create" ? (
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Select Resource Version{" "}
                    <span className="text-danger">*</span>{" "}
                    <small
                      id={`invalidResourceVersion${props.index}`}
                      className="text-danger d-none"
                    >
                      (Resource Version is a required field)
                    </small>
                  </Label>
                  <Select
                    className="react-select-container dropdown"
                    classNamePrefix="react-select"
                    name="resourceVariant"
                    id={`resourceVariant${props.index}`}
                    onChange={(option) =>
                      props.handleResourceVersionChange(option, props.index)
                    }
                    value={props.variant.selectedVersion || ""}
                    options={props.updatedResources}
                    required
                  />
                </FormGroup>
              </Col>
            ) : (
              <Col md={12}>
                <p className="mb-2">
                  <strong>Version: </strong>
                  {props.variant.version}
                </p>
              </Col>
            )}
            <Col md={6}>
              <FormGroup>
                <Label>
                  Percentage <span className="text-danger">*</span>
                  <small
                    id={`invalidPercentage${props.index}`}
                    className="text-danger d-none"
                  >
                    (Percentage is a required field)
                  </small>
                </Label>
                <Input
                  type="number"
                  name="percentage"
                  placeholder="Enter Percentage"
                  value={props.variant.percentage}
                  defaultValue={props.variant.percentage}
                  onChange={(evt) => props.handleChange(evt, props.index)}
                  min={0}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label>
                  Description <span className="text-danger">*</span>
                  <small
                    id={`invalidDescription${props.index}`}
                    className="text-danger d-none"
                  >
                    (Description is a required field)
                  </small>
                </Label>
                <Input
                  type="textarea"
                  name="description"
                  placeholder="Description"
                  style={{ height: "6rem" }}
                  value={props.variant.description}
                  defaultValue={props.variant.description}
                  onChange={(event) => props.handleChange(event, props.index)}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

export default ResourceVariantCard;

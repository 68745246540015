import { retrieveFromLocalStorage } from "../localStorageUtil";
import { host } from "../apiUtils/index";
import { secureFetch } from "../secureFetch";

// let host = "http://localhost:1323"


export let updateStoriesTargetingRules = async (id,data) => {

  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/suggestionDrawerContent/${id}/targetingRules`;
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res = await response.json();
    return res;
  } catch (error) {
    return;
  }
};

export const updateStory = async (storyId, formData) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stories/${storyId}`;

  try {
      let response = await fetch(url, {
          method: "POST",
          headers: {
              Authorization: `Bearer ${token}`,
          },
          body: formData,
      });

      return await response.json();
  } catch (error) {
      console.error("Error updating story:", error);
      return; 
  }
};

export let updateStoryRuleSets = async (rules, storyId, data) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stories/${storyId}/ruleSets`;

  try {
    var response = await fetch(url, {
      method: "POST", 
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res = await response.json();
    return res;
  } catch (error) {
    return;
  }
};

export const createStory = async (storyData) => {
  let token = retrieveFromLocalStorage("token");

  const url = `${host}/v4/internal/stories`;

  try {
      const response = await fetch(url, {
          method: "POST",
          headers: {
              Authorization: `Bearer ${token}`,
          },
          body: storyData, 
      });

      if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Story creation failed: ${response.status} - ${errorText}`);
      }

      return response.json(); 

  } catch (error) {
      console.error("Error creating story:", error);
      throw error; // Re-throw for centralized error handling if needed
  }
};
export const updateStoryTags = async (storyId, tags) => {
  let token = retrieveFromLocalStorage("token");

  const url = `${host}/v4/internal/stories/${storyId}/tags`;

  try {
      const response = await fetch(url, {
          method: "PUT",
          headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json" 
          },
          body: JSON.stringify(tags), 
      });

      if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Story tag update failed: ${response.status} - ${errorText}`);
      }

      return response.json(); 

  } catch (error) {
      console.error("Error updating story tags:", error);
     
      throw error; 
  }
};



export const createStoriesBulk = async (storiesData) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stories/bulk/create`;

  try {
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(storiesData),
    });

    return await response.json(); 
  } catch (error) {
    console.error("Error creating stories in bulk:", error);
    return; 
  }
};

export const updateStoryCtaItems = async (storyId, data) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stories/${storyId}/ctaItems`; // Modified URL
  console.log(data)
  try {
      let response = await fetch(url, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body:JSON.stringify(data),
      });

      return await response.json();
  } catch (error) {
      console.error("Error updating story CTA items:", error);
      return; 
  }
};


export const getStoriesCTAAssets = async () => {
  let token = retrieveFromLocalStorage("token");
  let quickReply = {};

  let url = `${host}/v4/internal/stories/ctaAssets`;

  try {
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let res = await response.json();
    if (res) {
      quickReply = res;
    }
    return quickReply;
  } catch (error) {
    return;
  }
}
export let getStoryUserAttributeRules = async (id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stories/${id}/userAttributeRules`;
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    return res; 
  } catch (error) {
    return;
  }
};

export let saveStoryUserAttributeRules = async (data, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stories/${id}/userAttributeRules`;
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let deactivateStoryUserAttributeRules = async (storyId, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stories/${storyId}/userAttributeRules/${id}/deactivate`;
  try {
    var response = await secureFetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let activateStoryUserAttributeRules = async (storyId, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stories/${storyId}/userAttributeRules/${id}/activate`;
  try {
    var response = await secureFetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};
// -------------------------------------------------------------------------------------------------------------------

export let getCountries = async () => {
  let countries = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stories/countries`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((country) => {
        let countryObj = {
          value: country.id,
          label: country.name,
        };
        countries.push(countryObj);
      });
    }
    return countries;
  } catch (error) {
    return;
  }
};

export let getRegions = async (countryID) => {
  let regions = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stories/regions?countryID=${countryID}`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((region) => {
        let regionObj = {
          value: region.id,
          label: region.name,
        };
        regions.push(regionObj);
      });
    }
    return regions;
  } catch (error) {
    return;
  }
};

export let getCities = async (countryID, regionID) => {
  let cities = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stories/cities?countryID=${countryID}&regionID=${regionID}`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((city) => {
        let cityObj = {
          value: city.id,
          label: city.name,
        };
        cities.push(cityObj);
      });
    }
    return cities;
  } catch (error) {
    return;
  }
};



// stories

export let getStoriesData = async (params) => {
  let url = `${host}/v4/internal/stories?` + new URLSearchParams(params);
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
          Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let getUserStoriesData = async (params) => {
  let url = `${host}/v4/internal/stories/userStories?` + new URLSearchParams(params);
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
          Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    console.log("error",error)
    return;
  }
};

export let getUserStoriesStats = async () => {
  let url = `${host}/v4/internal/stories/userStories/stats?` + new URLSearchParams();
    let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
          Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    console.log("error",error)
    return;
  }
};

export let getTemplateStats = async () => {
  let url = `${host}/v4/internal/stories/template/stats?` + new URLSearchParams();
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
          Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    console.log("error",error)
    return;
  }
};

export let getStoryDetailsData = async (storyId) => {
  let url = `${host}/v4/internal/stories/${storyId}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
          Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let getMultipleStoryDetailsData = async (storyIds) => {
  // Join the story IDs with commas
  const storyIdsString = storyIds.join(',');

  // Construct the URL with the story IDs string
  let url = `${host}/v4/internal/stories?storyIds=${storyIdsString}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
          Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let getStoryTagsData = async (storyId) => {
  let url = `${host}/v4/internal/stories/${storyId}/tags`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
          Authorization: "Bearer " + token,
      },
    });
    return await response.json(); // Assuming tags data is returned as JSON
  } catch (error) {
    return; 
  }
};
export let getStoryCTAItemsData = async (storyId) => {
  let url = `${host}/v4/internal/stories/${storyId}/ctaItems`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
          Authorization: "Bearer " + token,
      },
    });
    return await response.json(); // Assuming CTA items data is returned as JSON
  } catch (error) {
    return; 
  }
};
export let fetchStoryRuleSets = async (storyId) => {
  let ruleSets = []; // Initialize an empty array for rule sets

  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stories/${storyId}/ruleSets`; 
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    var res = await response.json(); 

    if (res) {
      // Assuming your API returns rule sets within a 'ruleSets' property
      ruleSets = res?.rules; 
    }
    return ruleSets; 
  } catch (error) {
    // Consider more robust error handling here
    return; 
  }
};

export let activateStoryData = async (storyId) => {
  let url = `${host}/v4/internal/stories/${storyId}/activate`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
          Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let deactivateStoryData = async (storyId) => {
  let url = `${host}/v4/internal/stories/${storyId}/deactivate`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
          Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let publishStoryData = async (storyId) => {
  let url = `${host}/v4/internal/stories/${storyId}/publish`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
          Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let getBucketData = async () => {
  let token = retrieveFromLocalStorage("token");
  let bucketData = [{ value: -1, label: "All" }];
  let url = `${host}/v4/internal/stories/buckets`;
  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.storyBuckets) {
        response.storyBuckets.map((bucket) => {
          let bucketObj = {
            value: bucket.id,
            label: bucket.name,
          };
          bucketData.push(bucketObj);
        });
      }
    });
  return bucketData;
};


export let getSingleBucketData = async (bucketId) => {
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/stories/buckets/${bucketId}`;
  return fetch(url, {
    method: "GET",
    headers: {
        Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      return response;
    });
}


export let getFullBucketData = async (params) => {
  try {
    let token = retrieveFromLocalStorage("token");

    let url = `${host}/v4/internal/stories/buckets?${new URLSearchParams(params)}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    console.log("response", responseData);
    return responseData;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export const createStoryBucket = async (data) => {
  let url = `${host}/v4/internal/stories/buckets`;
  let token = retrieveFromLocalStorage("token");
  return fetch(url, {
    method: "POST",
    headers: {
        Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      return response;
    });
}


export const updateStoryBucket = async (data,bucketId) => {
  // console.log("bucketId", bucketId);
  let url = `${host}/v4/internal/stories/buckets/${bucketId}`;
  let token = retrieveFromLocalStorage("token");
  return fetch(url, {
    method: "POST",
    headers: {
        Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      return response;
    });
}

export let getBucketsTagsData = async (storyId) => {
  let url = `${host}/v4/internal/stories/buckets/${storyId}/tags`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
          Authorization: "Bearer " + token,
      },
    });
    return await response.json(); // Assuming tags data is returned as JSON
  } catch (error) {
    return; 
  }
};

export let postBucketTagsData = async (data,storyBucketId) => {
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/stories/buckets/${storyBucketId}/tags`;
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchBucketTargetingRulesData = async (id) => {
  let rules = [];

  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stories/buckets/${id}/targetingRules`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    var res = await response.json();
    console.log(res)
    if (res) {
      rules = res.rules;
    }
    return rules;
  } catch (error) {
    return;
  }
}

export let updateBucketTargetingRulesData = async (data,id) => {
  let token = retrieveFromLocalStorage("token");
  let new_data = {
    "rules":data
  }
  let url = `${host}/v4/internal/stories/buckets/${id}/targetingRules`;
  console.log(new_data);
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(new_data),
    });
    var res = await response.json();
    return res;
  } catch (error) {
    return error;
  }
}

export const updateBulkStoriesData = async (data) => {
  let url = `${host}/v4/internal/stories/bulk/update`;
  let token = retrieveFromLocalStorage("token");
  return fetch(url, {
    method: "POST",
    headers: {
        Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      return response;
    });
}


// ---- SOTD ----//

export let activateSotdData = async (storyId) => {
  let url = `${host}/v4/internal/stories/featured/${storyId}/activate`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
          Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let deactivateSotdData = async (storyId) => {
  let url = `${host}/v4/internal/stories/featured/${storyId}/deactivate`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
          Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let publishSotdData = async (storyId) => {
  let url = `${host}/v4/internal/stories/featured/${storyId}/publish`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
          Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};


export let getSotdData = async (params) => {
  let url = `${host}/v4/internal/stories/featured?` + new URLSearchParams(params);
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
          Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let getStoriesList = async () => {
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/stories/list`;
  return fetch(url, {
    method: "GET",
    headers: {
        Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      return response.stories;
    });
}

export let createSotdData = async (data) => {
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/stories/featured`;
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },  
      body: JSON.stringify(data),
    });
    return await response.json();
  }
  catch (error) {
    return;
  }
};

export let getSotdDetailsData = async (id) => {
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/stories/featured/${id}`;
  return fetch(url, {
    method: "GET",
    headers: {
        Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      return response;
    });
}

export let updateSotdDetailsData = async(data,storyId) => {
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/stories/featured/${storyId}`;
  return fetch(url, {
    method: "POST",
    headers: {
        Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      return response;
    });
}


export let fetchSotdTargetingRulesData = async (id) => {
  let rules = [];

  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stories/featured/${id}/targetingRules`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    var res = await response.json();
    console.log(res)
    if (res) {
      rules = res.rules;
    }
    return rules;
  } catch (error) {
    return;
  }
}

export let updateSotdTargetingRulesData = async (data,id) => {
  let token = retrieveFromLocalStorage("token");
  let new_data = {
    "rules":data
  }
  let url = `${host}/v4/internal/stories/featured/${id}/targetingRules`;
  console.log(new_data);
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(new_data),
    });
    var res = await response.json();
    return res;
  } catch (error) {
    return error;
  }
}



// ---- Targeting Rules -----//

export let fetchStoriesListingTargetingRules = async (id) => {
  let rules = [];

  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stories/${id}/targetingRules`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    var res = await response.json();
    console.log(res)
    if (res) {
      rules = res.rules;
    }
    return rules;
  } catch (error) {
    return;
  }
};

export let updateStoriesListingTargetingRules = async (rules,id,data) => {

  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stories/${id}/targetingRules`;
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res = await response.json();
    return res;
  } catch (error) {
    return;
  }
};

export let getAudioList= async () => {
  try {
    let token = retrieveFromLocalStorage("token");

    let url = `${host}/v4/internal/stories/audio`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    console.log("response", responseData);
    return responseData;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

import { host } from "./index";
import { retrieveFromLocalStorage } from "../localStorageUtil";
import axios from "axios";

// let host = "http://localhost:1323"

export let fetchSegmentsData = async () => {
  let url = `${host}/v4/internal/segments`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    console.error("Error fetching segments data", error);
    return;
  }
};

export let addSegment = async (file, name, description) => {
  let url = `${host}/v4/internal/segments`;
  let token = retrieveFromLocalStorage("token");
  let formData = new FormData();
  formData.append("file", file);
  formData.append("name", name);
  formData.append("description", description);

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    });

    if (response.ok) {
      return await response.json();
    } else {
      console.error("Error uploading CSV file", response.statusText);
      return null;
    }
  } catch (error) {
    console.error("Error uploading CSV file", error);
    return null;
  }
};

export let updateSegment = async (segment, file) => {
  let url = `${host}/v4/internal/segments/${segment.id}`;
  let token = retrieveFromLocalStorage("token");

  // Create a FormData object
  let formData = new FormData();
  formData.append("name", segment.name);
  formData.append("description", segment.description);
  formData.append("file", file);
  formData.append("updateAll", segment.updateAll); 

  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    });

    if (response.ok) {
      return await response.json();
    } else {
      console.error("Error updating segment", response.statusText);
      return null;
    }
  } catch (error) {
    console.error("Error updating segment", error);
    return null;
  }
};

import React, { useEffect, useState } from 'react'
import Select from "react-select";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from "moment";
import { Container, Row, Col, Card, CardBody, CardHeader, Button, FormGroup, Label, Input } from "reactstrap";
import { Accordion } from "react-bootstrap";
import { BsChevronDown, BsChevronUp } from "react-icons/bs"
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import exportFromJSON from 'export-from-json'
import Loading from "../../../components/Loading";
import { clientOptions, dataSourceOptions, convertToIndianFormat, getValidProviderName, isRevenueNoteCreatePermission, isRevenueNoteDeletePermission, isRevenueNoteEditPermission, revenueDashboardNotesAllActions, revenueDashboardNotesResponses, revenueTypeOptions, bigqueryErrorMessage, graphGranularityOptions, providerDataGroupByOptions, adDataGroupByOptions } from '../../../config/configRevenueDashboard';
import RevenueGraph from './RevenueGraph';
import { createRevenueDashboardNote, deleteRevenueDashboardNote, fetchAdsWithAdIds, fetchAdsWithProviderRevenueType, fetchAllProviders, fetchPlacementsByClientId, fetchPlacementsWithPlacementIds, fetchProvidersByPlacementId, fetchRevenueAdSpecificData, fetchRevenueDashboardNotes, fetchRevenueFilterOptions, fetchRevenueGraphData, fetchRevenueMetricsData, fetchRevenueProviderSpecificData, updateRevenueDashboardNote } from '../../../utilities/apiUtils/revenue';
import { error, errorDescription, redirectOnTokenExpiry } from '../../../utilities/commonUtil';
import ResponseModal from '../ResponseModal';
import { errorModalHeading, successModalHeading } from '../../../config/config';
import { adsRevenueTableColumns, providersRevenueTableColumns, revenueNotesTableColumns } from './tableColumn';
import RevenueDashboardNoteModal from './RevenueDashboardNoteModal';
import ConfirmationModal from '../ConfirmationModal';

const initialStartDate = moment().format("MM/DD/YYYY")
const initialEndDate = moment().format("MM/DD/YYYY")

const filterInitialState = [{
  value: "all",
  label: "All"
}]

const formDataInitialState = {
  id: "",
  title: "",
  description: "",
  date: moment().format("YYYY-MM-DD")
}

const providerDataFiltersInitialState = {
  date: [],
  client: [],
  placement: [],
  provider: [],
  revenueType: []
}

const adDataFiltersInitialState = {
  date: [],
  client: [],
  placement: [],
  provider: [],
  ad: [],
  revenueType: []
}

const Revenue = () => {
  const [loading, setLoading] = useState(true);
  const [graphLoading, setGraphLoading] = useState(false)
  const [providerDataLoading, setProviderDataLoading] = useState(false)
  const [adDataLoading, setAdDataLoading] = useState(false)
  const [successModalText, setSuccessModalText] = useState("");
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [action, setAction] = useState("");
  const [id, setId] = useState(null);
  const [isCollapse, setIsCollapse] = useState(false)
  const [isGranularityDropdownEnabled, setIsGranularityDropdownEnabled] = useState(true)

  const [filters, setFilters] = useState({
    startDate: initialStartDate,
    endDate: initialEndDate,
    revenueType: [revenueTypeOptions[0]],
    clientId: filterInitialState,
    placementId: filterInitialState,
    providerId: filterInitialState,
    adId: filterInitialState,
  })
  const [placementOptions, setPlacementOptions] = useState([])
  const [providerOptions, setProviderOptions] = useState([])
  const [adOptions, setAdOptions] = useState([])

  const [revenueMetricsData, setRevenueMetricsData] = useState({})
  const [revenueGraphData, setRevenueGraphData] = useState([])
  const [revenueProviderData, setRevenueProviderData] = useState([])
  const [revenueAdsData, setRevenueAdsData] = useState([])
  const [notes, setNotes] = useState([])

  const [formData, setFormData] = useState(formDataInitialState)
  const [changeType, setChangeType] = useState("Add")
  const [isRevenueDashboardNoteModalVisible, setIsRevenueDashboardNoteModalVisible] = useState(false)

  const [dataSource, setDataSource] = useState(dataSourceOptions[0])
  const [graphGranularity, setGraphGranularity] = useState(graphGranularityOptions[0])
  const [postbackType, setPostbackType] = useState({
    value: "v1",
    label: "Mediation SDK + Ads API"
  })

  const [providerDataGroupBy, setProviderDataGroupBy] = useState(providerDataGroupByOptions)
  const [providerDataFilters, setProviderDataFilters] = useState(providerDataFiltersInitialState)
  const [providerDataFiltersOptions, setProviderDataFiltersOptions] = useState(providerDataFiltersInitialState)
  const [adDataGroupBy, setAdDataGroupBy] = useState(adDataGroupByOptions)
  const [adDataFilters, setAdDataFilters] = useState(adDataFiltersInitialState)
  const [adDataFiltersOptions, setAdDataFiltersOptions] = useState(adDataFiltersInitialState)

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    setLoading(true)
    getRevenueDashboardNotes().finally(() => setLoading(false));
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let showRevenueDashboardNoteModal = (type) => {
    setChangeType(type)
    setIsRevenueDashboardNoteModalVisible(true)
  }

  let hideRevenueDashboardNoteModal = () => {
    setIsRevenueDashboardNoteModalVisible(false)
    setFormData(formDataInitialState)
  }

  let setConfirmationModalState = (id, event) => {
    setAction(event);
    setConfirmationModalIsOpen(true);
    setId(id);
  };

  useEffect(() => {
    // Filter APIs
    getPlacementsByClientId("all")
    getAllProviders()
    getAds("all", "all")

    // Data APIs
    const queryFilters = {
      postbackType: postbackType.value,
      dataSource: dataSource.value,
      startDate: moment(initialStartDate).format("YYYY-MM-DD"),
      endDate: moment(initialEndDate).format("YYYY-MM-DD"),
      date: "all",
      adType: "cpi",
      clientId: "all",
      placementId: "all",
      providerId: "all",
      adId: "all"
    }
    const revenueProviderGroupBy = providerDataGroupBy?.map(el => el?.value).join(",")
    const revenueAdGroupBy = adDataGroupBy?.map(el => el?.value).join(",")
    setLoading(true)
    Promise.all([
      getRevenueMetricsData(queryFilters),
      getRevenueGraphData({ ...queryFilters, graphGranularity: graphGranularity.value }),
      getRevenueProviderData({ ...queryFilters, groupBy: revenueProviderGroupBy }),
      getRevenueAdsData({ ...queryFilters, groupBy: revenueAdGroupBy }),
      getRevenueFilterOptions(queryFilters),
      getRevenueDashboardNotes()
    ]).finally(() => {
      setLoading(false)
    })
  }, [])

  const getPlacementsByClientId = async (clientId) => {
    try {
      let response = await fetchPlacementsByClientId(clientId === "all" ? "" : clientId);
      const placements = []
      placements.push({
        value: "all",
        label: "All",
      })
      if (response?.length > 0) {
        response.forEach((placement) => {
          placements.push({
            value: placement?.id,
            label: placement?.description,
          })
        });
        setPlacementOptions(placements);
        setFilters((prevState) => ({
          ...prevState,
          placementId: [placements[0]]
        }))
      } else {
        if (
          response?.errorCode === error.tokenExpired ||
          response?.errorCode === error.invalidAccessToken ||
          response?.errorCode === error.unauthorized
        )
          redirectOnTokenExpiry();
        let text = errorDescription(response?.errorCode);
        showErrorModal(text);
      }
    } catch (err) {
      console.log(err);
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  }

  const getAllProviders = async () => {
    try {
      let response = await fetchAllProviders();
      const providers = []
      providers.push({
        value: "all",
        label: "All",
      })
      if (response?.length > 0) {
        response.forEach((provider) => {
          providers.push({
            value: provider?.name,
            label: provider?.name?.replace(/_/g, ' '),
          })
        });
        setProviderOptions(providers);
        setFilters((prevState) => ({
          ...prevState,
          providerId: [providers[0]]
        }))
      } else {
        if (
          response?.errorCode === error.tokenExpired ||
          response?.errorCode === error.invalidAccessToken ||
          response?.errorCode === error.unauthorized
        )
          redirectOnTokenExpiry();
        let text = errorDescription(response?.errorCode);
        showErrorModal(text);
      }
    } catch (err) {
      console.log(err);
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  }

  const getProvidersByPlacementId = async (placementId, revenueType) => {
    try {
      let response = await fetchProvidersByPlacementId(placementId === "all" ? "" : placementId);
      const providers = []
      providers.push({
        value: "all",
        label: "All",
      }, {
        value: "internal",
        label: "internal",
      })
      if (response?.length > 0) {
        response.forEach((provider) => {
          if (provider?.includes("cpi") && (revenueType?.includes("all") || revenueType?.includes("cpi"))) {
            providers.push({
              value: getValidProviderName(provider),
              label: getValidProviderName(provider)?.replace(/_/g, ' '),
            })
          } else if (provider?.includes("cpc") && (revenueType?.includes("all") || revenueType?.includes("cpc"))) {
            providers.push({
              value: getValidProviderName(provider),
              label: getValidProviderName(provider)?.replace(/_/g, ' '),
            })
          }
        });
        setProviderOptions(providers);
      } else {
        setProviderOptions(providers)
      }
      setFilters((prevState) => ({
        ...prevState,
        providerId: [providers[0]]
      }))
    } catch (err) {
      console.log(err);
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  }

  const getAds = async (providerId, revenueType) => {
    try {
      let response = await fetchAdsWithProviderRevenueType(providerId === "all" ? "" : providerId, revenueType === "all" ? "" : revenueType);
      const ads = []
      ads.push({
        value: "all",
        label: "All",
      })
      if (response?.ads?.length > 0) {
        response.ads.forEach((ad) => {
          ads.push({
            value: ad?.id,
            label: ad?.title,
          })
        });
        setAdOptions(ads);
        setFilters((prevState) => ({
          ...prevState,
          adId: [ads[0]]
        }))
      } else if (response?.ads?.length === 0) {
        setAdOptions(ads);
        setFilters((prevState) => ({
          ...prevState,
          adId: [ads[0]]
        }))
      } else {
        if (
          response?.errorCode === error.tokenExpired ||
          response?.errorCode === error.invalidAccessToken ||
          response?.errorCode === error.unauthorized
        )
          redirectOnTokenExpiry();
        let text = errorDescription(response?.errorCode);
        showErrorModal(text);
      }
    } catch (err) {
      console.log(err);
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  }

  const getRevenueMetricsData = async (queryFilters) => {
    try {
      let response = await fetchRevenueMetricsData(queryFilters);
      if (!response?.errorCode) {
        setRevenueMetricsData(response);
      } else {
        if (
          response?.errorCode === error.tokenExpired ||
          response?.errorCode === error.invalidAccessToken ||
          response?.errorCode === error.unauthorized
        )
          redirectOnTokenExpiry();
        let text
        if (response?.errorDescription?.startsWith("googleapi:"))
          text = response?.errorDescription?.split("googleapi: ")[1] + " " + bigqueryErrorMessage
        else
          text = errorDescription(response?.errorCode);
        showErrorModal(text);
        setRevenueMetricsData({})
      }
    } catch (err) {
      console.log(err);
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  }

  const getRevenueGraphData = async (queryFilters) => {
    try {
      let response = await fetchRevenueGraphData(queryFilters);
      if (response?.length >= 0) {
        setRevenueGraphData(response);
      } else if (response?.length === 0) {
        setRevenueGraphData([]);
      } else {
        if (
          response?.errorCode === error.tokenExpired ||
          response?.errorCode === error.invalidAccessToken ||
          response?.errorCode === error.unauthorized
        )
          redirectOnTokenExpiry();
        let text
        if (response?.errorDescription?.startsWith("googleapi:"))
          text = response?.errorDescription?.split("googleapi: ")[1] + " " + bigqueryErrorMessage
        else
          text = errorDescription(response?.errorCode);
        showErrorModal(text);
        setRevenueGraphData([])
      }
    } catch (err) {
      console.log(err);
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  }

  const getRevenueProviderData = async (queryFilters) => {
    try {
      let response = await fetchRevenueProviderSpecificData(queryFilters);
      if (response?.length >= 0) {
        let placementIds = []

        response.forEach((item, index) => {
          item.id = index
          // calc metrics
          item.ctr = !item?.impressions ? 0 : Number(((item?.clicks * 100) / item?.impressions).toFixed(5))
          item.conversionRate = !item?.clicks ? 0 : Number(((item?.installs * 100) / item?.clicks).toFixed(5))
          item.clientId = clientOptions.find(option => option.value === item?.clientId)?.label ?? item?.clientId
          // store placement id
          placementIds.push(item.placementId)
        });

        if (placementIds?.length > 0) {
          // fetch placement names
          fetchPlacementsWithPlacementIds({ placementIds }).then(placements => {
            if (placements?.length > 0) {
              response.forEach((item) => {
                item.placementId = placements?.find(placement => placement.id === item.placementId)?.description ?? item.placementId
              })
            }
          }).catch(err => console.log(err)).finally(() => {
            setRevenueProviderData(response);
          })
        } else {
          setRevenueProviderData(response);
        }
      } else if (response?.length === 0) {
        setRevenueProviderData([]);
      } else {
        if (
          response?.errorCode === error.tokenExpired ||
          response?.errorCode === error.invalidAccessToken ||
          response?.errorCode === error.unauthorized
        )
          redirectOnTokenExpiry();
        let text
        if (response?.errorDescription?.startsWith("googleapi:"))
          text = response?.errorDescription?.split("googleapi: ")[1] + " " + bigqueryErrorMessage
        else
          text = errorDescription(response?.errorCode);
        showErrorModal(text);
        setRevenueProviderData([]);
      }
    } catch (err) {
      console.log(err);
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  }

  const getRevenueAdsData = async (queryFilters) => {
    try {
      let response = await fetchRevenueAdSpecificData(queryFilters);
      if (response?.length >= 0) {
        let placementIds = []
        let adIds = []

        response.forEach((item, index) => {
          item.id = index
          // calc metrics
          item.ctr = !item?.impressions ? 0 : Number(((item?.clicks * 100) / item?.impressions).toFixed(5))
          item.conversionRate = !item?.clicks ? 0 : Number(((item?.installs * 100) / item?.clicks).toFixed(5))
          item.clientId = clientOptions.find(option => option.value === item?.clientId)?.label ?? item?.clientId
          // store placement id
          placementIds.push(item.placementId)
          adIds.push(item.adId)
        });

        if (placementIds?.length > 0 || adIds?.length > 0) {
          // fetch placement names
          const promisePlacementsDetails = fetchPlacementsWithPlacementIds({ placementIds }).then(placements => {
            if (placements?.length > 0) {
              response.forEach((item) => {
                item.placementId = placements?.find(placement => placement.id === item.placementId)?.description ?? item.placementId
              })
            }
          })

          // fetch ad names
          const promiseAdsDetails = fetchAdsWithAdIds({ adIds }).then(ads => {
            if (ads?.length > 0) {
              response.forEach((item) => {
                item.adName = ads?.find(ad => Number(ad.id) === Number(item.adId))?.title ?? item.adId
              })
            }
          })

          Promise.all([promisePlacementsDetails, promiseAdsDetails]).catch(err => console.log(err)).finally(() => {
            setRevenueAdsData(response);
          })
        } else {
          setRevenueAdsData(response);
        }
      } else if (response?.length === 0) {
        setRevenueAdsData([]);
      } else {
        if (
          response?.errorCode === error.tokenExpired ||
          response?.errorCode === error.invalidAccessToken ||
          response?.errorCode === error.unauthorized
        )
          redirectOnTokenExpiry();
        let text
        if (response?.errorDescription?.startsWith("googleapi:"))
          text = response?.errorDescription?.split("googleapi: ")[1] + " " + bigqueryErrorMessage
        else
          text = errorDescription(response?.errorCode);
        showErrorModal(text);
        setRevenueAdsData([])
      }
    } catch (err) {
      console.log(err);
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  }

  const getRevenueFilterOptions = async (queryFilters) => {
    try {
      let response = await fetchRevenueFilterOptions(queryFilters);
      if (response?.date) {
        let dates = [], clients = [], placements = [], providers = [], ads = [], revenueTypes = []
        if (response?.date?.length > 0) {
          response.date.forEach(date => {
            if (date)
              dates.push({
                label: moment(date).format("DD-MM-YYYY"),
                value: date,
              })
          })
        }
        if (response?.clientId?.length > 0) {
          response.clientId.forEach(clientId => {
            if (clientId)
              clients.push({
                label: clientOptions.find(option => option.value === clientId)?.label ?? clientId,
                value: clientId,
              })
          })
        }
        if (response?.providerId?.length > 0) {
          response.providerId.forEach(providerId => {
            let label = providerId?.replace(/_/g, ' ')
            if (providerId)
              providers.push({
                label: label?.charAt(0)?.toUpperCase() + label?.slice(1),
                value: providerId,
              })
          })
        }
        if (response?.adType?.length > 0) {
          response.adType.forEach(adType => {
            if (adType)
              revenueTypes.push({
                label: adType === "cpi" ? "Install" : adType === "cpc" ? "Click" : adType?.toUpperCase(),
                value: adType,
              })
          })
        }
        if (response?.placementId?.length > 0 || response?.adId?.length > 0) {
          const promisePlacementsDetails = fetchPlacementsWithPlacementIds({ placementIds: response.placementId }).then(res => {
            response.placementId.forEach(placementId => {
              if (placementId)
                placements.push({
                  label: res?.find(placement => placement.id === placementId)?.description ?? placementId,
                  value: placementId,
                })
            })
          })
          const promiseAdsDetails = fetchAdsWithAdIds({ adIds: response?.adId }).then(res => {
            response.adId.forEach(adId => {
              let label = res?.find(ad => Number(ad.id) === Number(adId))?.title ?? adId
              if (adId)
                ads.push({
                  label: !label ? adId : adId === label ? adId : `${adId} : ${label}`,
                  value: adId,
                })
            })
          })
          Promise.all([promisePlacementsDetails, promiseAdsDetails]).catch(err => console.log(err)).finally(() => {
            setProviderDataFiltersOptions(prevState => ({
              ...prevState,
              date: dates,
              client: clients,
              placement: placements,
              provider: providers,
              revenueType: revenueTypes
            }))
            setAdDataFiltersOptions(prevState => ({
              ...prevState,
              date: dates,
              client: clients,
              placement: placements,
              provider: providers,
              ad: ads,
              revenueType: revenueTypes
            }))
          })
        }
      } else {
        setProviderDataFiltersOptions(prevState => ({
          ...prevState,
          date: [],
          client: [],
          placement: [],
          provider: [],
          revenueType: []
        }))
        setAdDataFiltersOptions(prevState => ({
          ...prevState,
          date: [],
          client: [],
          placement: [],
          provider: [],
          ad: [],
          revenueType: []
        }))
      }
    }
    catch (err) {
      console.log(err);
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  }

  const getRevenueDashboardNotes = async () => {
    try {
      let response = await fetchRevenueDashboardNotes();
      if (!response || response?.length === 0) {
        setNotes([])
      } else if (response?.length > 0) {
        setNotes(response?.filter(note => !note?.isDeleted));
      } else {
        if (
          response?.errorCode === error.tokenExpired ||
          response?.errorCode === error.invalidAccessToken ||
          response?.errorCode === error.unauthorized
        )
          redirectOnTokenExpiry();
        let text = errorDescription(response?.errorCode);
        showErrorModal(text);
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  };

  const handleChange = (event, name) => {
    const isAllSelected = event?.some(e => e?.value === "all") || false
    const isAllAlreadySelected = filters[name]?.some(e => e?.value === "all") || false
    const updatedFilter = event?.filter(e => e?.value !== "all") || []

    setFilters((prevState) => ({
      ...prevState,
      [name]: isAllAlreadySelected
        ? updatedFilter
        : isAllSelected
          ? filterInitialState
          : event
    }))

    let value = isAllAlreadySelected
      ? updatedFilter?.map(e => e?.value)?.join(",")
      : isAllSelected
        ? "all"
        : event?.map(e => e?.value)?.join(",")

    if (!value)
      value = "all"

    // change other filter options
    switch (name) {
      case "revenueType":
        // filter providers
        if (filters.placementId?.some(e => e?.value === "all")) getAllProviders()
        else getProvidersByPlacementId(filters.placementId?.map(e => e?.value)?.join(",") || "all", value)

        // filter ads
        getAds(filters.providerId?.map(e => e?.value)?.join(",") || "all", value)
        break;

      case "clientId":
        // filter placements
        getPlacementsByClientId(value)
        break;

      case "placementId":
        // filter providers
        if (value === "all") getAllProviders()
        else getProvidersByPlacementId(value, filters.revenueType?.map(e => e?.value)?.join(",") || "all")
        break;

      case "providerId":
        // filter ads
        getAds(value, filters.revenueType?.map(e => e?.value)?.join(",") || "all")
        break;

      default:
        break;
    }
  }

  const handleApplyDates = (e, { startDate, endDate }) => {
    // Calc diff in days b/w start date and end date
    const diff = moment(endDate).diff(moment(startDate), "days")
    // Enable granularity dropdown only when diff in days is less than 7 - for other cases day level granularity will be default 
    if (diff < 7) setIsGranularityDropdownEnabled(true)
    else {
      setIsGranularityDropdownEnabled(false)
      setGraphGranularity(graphGranularityOptions[0])
    }

    setFilters((prevState) => ({
      ...prevState,
      startDate: startDate.format("MM/DD/YYYY"),
      endDate: endDate.format("MM/DD/YYYY")
    }));
  };

  const handleDateChange = (event) => {
    const { value } = event.target;
    setFilters((prevState) => ({
      ...prevState,
      startDate: moment(value).format("MM/DD/YYYY"),
      endDate: moment(value).format("MM/DD/YYYY")
    }));
  }

  let getdateValue = (date) => {
    if (date === null) {
      return null;
    }
    return moment(date).format("YYYY-MM-DD");
  }

  const ranges = {
    Today: [moment(), moment()],
    Yesterday: [
      moment().subtract(1, "days"),
      moment().subtract(1, "days")
    ],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month")
    ]
  };

  const handleFilter = () => {
    const { startDate, endDate, revenueType, clientId, placementId, providerId, adId } = filters
    const queryFilters = {
      postbackType: postbackType.value,
      dataSource: dataSource.value,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      date: "all",
      adType: revenueType?.map(e => e?.value)?.join(",") || "all",
      clientId: clientId?.map(e => e?.value)?.join(",") || "all",
      placementId: placementId?.map(e => e?.value)?.join(",") || "all",
      providerId: providerId?.map(e => e?.value)?.join(",") || "all",
      adId: adId?.map(e => e?.value)?.join(",") || "all"
    }
    const revenueProviderGroupBy = providerDataGroupBy?.map(el => el?.value).join(",")
    const revenueAdGroupBy = adDataGroupBy?.map(el => el?.value).join(",")
    setLoading(true)
    Promise.all([
      getRevenueMetricsData(queryFilters),
      getRevenueGraphData({ ...queryFilters, graphGranularity: graphGranularity.value }),
      getRevenueProviderData({ ...queryFilters, groupBy: revenueProviderGroupBy }),
      getRevenueAdsData({ ...queryFilters, groupBy: revenueAdGroupBy }),
      getRevenueFilterOptions(queryFilters)
    ]).finally(() => {
      setLoading(false)
      setProviderDataFilters(providerDataFiltersInitialState)
      setAdDataFilters(adDataFiltersInitialState)
    })
  }

  const handleDataSourceChange = (event) => {
    const { value } = event
    if (value !== dataSource.value) {
      setDataSource(event)
      // If data source is mysql then postback type will we both (mediation sdk and ads-api)
      if (value === "mysql") {
        setPostbackType({
          value: "v1",
          label: "Mediation SDK + Ads API"
        })
        setFilters((prevState) => ({
          ...prevState,
          endDate: moment(prevState.startDate).format("MM/DD/YYYY")
        }));
      } else {
        setPostbackType({
          value: "v0",
          label: "Ads API"
        })
      }
      const { startDate, endDate, revenueType, clientId, placementId, providerId, adId } = filters
      const queryFilters = {
        postbackType: value === "mysql" ? "v1" : "v0",
        dataSource: value,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(value === "mysql" ? startDate : endDate).format("YYYY-MM-DD"),
        date: "all",
        adType: revenueType?.map(e => e?.value)?.join(",") || "all",
        clientId: clientId?.map(e => e?.value)?.join(",") || "all",
        placementId: placementId?.map(e => e?.value)?.join(",") || "all",
        providerId: providerId?.map(e => e?.value)?.join(",") || "all",
        adId: adId?.map(e => e?.value)?.join(",") || "all"
      }
      const revenueProviderGroupBy = providerDataGroupBy?.map(el => el?.value).join(",")
      const revenueAdGroupBy = adDataGroupBy?.map(el => el?.value).join(",")
      setLoading(true)
      Promise.all([
        getRevenueMetricsData(queryFilters),
        getRevenueGraphData({ ...queryFilters, graphGranularity: graphGranularity.value }),
        getRevenueProviderData({ ...queryFilters, groupBy: revenueProviderGroupBy }),
        getRevenueAdsData({ ...queryFilters, groupBy: revenueAdGroupBy }),
        getRevenueFilterOptions(queryFilters)
      ]).finally(() => {
        setLoading(false)
        setProviderDataFilters(providerDataFiltersInitialState)
        setAdDataFilters(adDataFiltersInitialState)
      })
    }
  }

  const handleGraphGranularityChange = (event) => {
    const { value } = event
    if (value !== graphGranularity.value) {
      setGraphGranularity(event)
      const { startDate, endDate, revenueType, clientId, placementId, providerId, adId } = filters
      const queryFilters = {
        postbackType: postbackType.value,
        dataSource: dataSource.value,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        date: "all",
        adType: revenueType?.map(e => e?.value)?.join(",") || "all",
        clientId: clientId?.map(e => e?.value)?.join(",") || "all",
        placementId: placementId?.map(e => e?.value)?.join(",") || "all",
        providerId: providerId?.map(e => e?.value)?.join(",") || "all",
        adId: adId?.map(e => e?.value)?.join(",") || "all"
      }
      setGraphLoading(true)
      getRevenueGraphData({ ...queryFilters, graphGranularity: value }).finally(() => setGraphLoading(false));
    }
  }

  const handleProviderDataGroupBy = (event) => {
    if (event?.length > 0) {
      setProviderDataGroupBy(event)
    }
  }

  const handleProviderDataGroupFilter = () => {
    const { startDate, endDate, adId } = filters
    const { revenueType, client, placement, provider, date } = providerDataFilters
    const queryFilters = {
      postbackType: postbackType.value,
      dataSource: dataSource.value,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      date: date?.map(e => e?.value)?.join(",") || "all",
      adType: revenueType?.map(e => e?.value)?.join(",") || "cpi",
      clientId: client?.map(e => e?.value)?.join(",") || "all",
      placementId: placement?.map(e => e?.value)?.join(",") || "all",
      providerId: provider?.map(e => e?.value)?.join(",") || "all",
      adId: adId?.map(e => e?.value)?.join(",") || "all"
    }
    const revenueProviderGroupBy = providerDataGroupBy?.map(el => el?.value).join(",")
    setProviderDataLoading(true)
    Promise.all([
      getRevenueProviderData({ ...queryFilters, groupBy: revenueProviderGroupBy }),
    ]).finally(() => {
      setProviderDataLoading(false)
    })
  }

  const handleAdDataGroupFilter = () => {
    const { startDate, endDate } = filters
    const { revenueType, client, placement, provider, ad, date } = adDataFilters
    const queryFilters = {
      postbackType: postbackType.value,
      dataSource: dataSource.value,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      date: date?.map(e => e?.value)?.join(",") || "all",
      adType: revenueType?.map(e => e?.value)?.join(",") || "cpi",
      clientId: client?.map(e => e?.value)?.join(",") || "all",
      placementId: placement?.map(e => e?.value)?.join(",") || "all",
      providerId: provider?.map(e => e?.value)?.join(",") || "all",
      adId: ad?.map(e => e?.value)?.join(",") || "all"
    }
    const revenueAdGroupBy = adDataGroupBy?.map(el => el?.value).join(",")
    setAdDataLoading(true)
    Promise.all([
      getRevenueAdsData({ ...queryFilters, groupBy: revenueAdGroupBy }),
    ]).finally(() => {
      setAdDataLoading(false)
    })
  }

  const handleAdDataGroupBy = (event) => {
    if (event?.length > 0) {
      setAdDataGroupBy(event)
    }
  }

  const handleProviderDataFiltersChange = (event, name) => {
    setProviderDataFilters((prevState) => ({
      ...prevState,
      [name]: event
    }))
  }

  const handleAdDataFiltersChange = (event, name) => {
    setAdDataFilters((prevState) => ({
      ...prevState,
      [name]: event
    }))
  }

  const handlePostbackTypeChange = (event) => {
    const { value } = event
    if (value !== postbackType.value) {
      setPostbackType(event)
      const { startDate, endDate, revenueType, clientId, placementId, providerId, adId } = filters
      const queryFilters = {
        postbackType: value,
        dataSource: dataSource.value,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        date: "all",
        adType: revenueType?.map(e => e?.value)?.join(",") || "all",
        clientId: clientId?.map(e => e?.value)?.join(",") || "all",
        placementId: placementId?.map(e => e?.value)?.join(",") || "all",
        providerId: providerId?.map(e => e?.value)?.join(",") || "all",
        adId: adId?.map(e => e?.value)?.join(",") || "all"
      }
      const revenueProviderGroupBy = providerDataGroupBy?.map(el => el?.value).join(",")
      const revenueAdGroupBy = adDataGroupBy?.map(el => el?.value).join(",")
      setLoading(true)
      Promise.all([
        getRevenueMetricsData(queryFilters),
        getRevenueGraphData({ ...queryFilters, graphGranularity: graphGranularity.value }),
        getRevenueProviderData({ ...queryFilters, groupBy: revenueProviderGroupBy }),
        getRevenueAdsData({ ...queryFilters, groupBy: revenueAdGroupBy }),
        getRevenueFilterOptions(queryFilters)
      ]).finally(() => {
        setLoading(false)
        setProviderDataFilters(providerDataFiltersInitialState)
        setAdDataFilters(adDataFiltersInitialState)
      })
    }
  }

  const handleSubmit = async () => {
    const { id, title, description, date } = formData
    const payload = {
      title,
      description,
      date: moment(date).format("YYYY-MM-DD")
    }
    try {
      let response
      if (changeType === "Add")
        response = await createRevenueDashboardNote(payload)
      else
        response = await updateRevenueDashboardNote(id, payload)
      if (response?.status === "success") {
        setSuccessModalText(changeType === "Add" ? revenueDashboardNotesResponses.addedSuccessfully : revenueDashboardNotesResponses.updatedSuccessfully);
        setSuccessModalDisplay(true);
      } else {
        let text
        if (response?.errorDescription)
          text = response?.errorDescription?.split("):")[1];
        else
          text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      }
    } catch (err) {
      let text = errorDescription(error.unexpectedError);
      showErrorModal(text);
    } finally {
      hideRevenueDashboardNoteModal()
    }
  }

  const handleCSVExport = (adType) => {
    if (adType === "provider")
      exportFromJSON({
        data: revenueProviderData,
        fileName: `providers_revenue_${moment(filters.startDate).format("DD-MM-YYYY")}_to_${moment(filters.endDate).format("DD-MM-YYYY")}`,
        exportType: exportFromJSON.types.csv,
        fields: {
          date: "Date (DD-MM-YYYY)",
          clientId: "Client",
          placementId: "Placement",
          providerId: "Provider",
          adType: "Revenue Type",
          impressions: "Impressions",
          clicks: "Clicks",
          installs: "Installs",
          adValue: "Revenue (INR)",
          ctr: "Click Through Rate (%)",
          conversionRate: "Conversion Rate (%)"
        }
      })
    else if (adType === "directAds")
      exportFromJSON({
        data: revenueAdsData,
        fileName: `direct_ads_performance_${moment(filters.startDate).format("DD-MM-YYYY")}_to_${moment(filters.endDate).format("DD-MM-YYYY")}`,
        exportType: exportFromJSON.types.csv,
        fields: {
          date: "Date (DD-MM-YYYY)",
          clientId: "Client",
          placementId: "Placement",
          providerId: "Provider",
          adName: "Ad Name",
          adType: "Revenue Type",
          impressions: "Impressions",
          clicks: "Clicks",
          installs: "Installs",
          adValue: "Revenue (INR)",
          ctr: "Click Through Rate (%)",
          conversionRate: "Conversion Rate (%)"
        }
      })
  }

  let deleteNote = async (id) => {
    try {
      let response = await deleteRevenueDashboardNote(id);
      if (response.status === "success") {
        let text = "Note has been deleted";
        showSuccessModal(text);
      } else {
        let text
        if (response?.errorDescription)
          text = response?.errorDescription?.split("):")[1];
        else
          text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        {isRevenueNoteEditPermission() &&
          <Button
            className="mx-3"
            color="primary"
            onClick={() => {
              showRevenueDashboardNoteModal("Edit")
              setFormData((prevState) => ({
                ...prevState,
                id: row.id,
                title: row.title,
                description: row.description,
                date: row.date
              }))
            }}
          >
            Edit
          </Button>
        }
        {isRevenueNoteDeletePermission() &&
          <Button
            className="mx-3 btn-danger"
            onClick={(event) =>
              setConfirmationModalState(row.id, event.target.innerHTML)
            }
          >
            Delete
          </Button>
        }

        {!(isRevenueNoteEditPermission() || isRevenueNoteDeletePermission()) && (
          <center className="text-info" style={{ fontSize: 13 }}>You do not have permissions to perform any action.</center>
        )}
      </div>
    ),
  };

  return (
    <Container fluid className="p-0">
      <Row>
        <Col sm="6" className="mb-4">
          <h1 className="mt-2 w-50">Ad Revenue</h1>
          <p className="text-info">(Note: All dates and time are in UTC format)</p>
        </Col>
        <Col sm="3">
          <FormGroup>
            <Label
              className="font-weight-bolder mb-2"
              style={{ fontSize: "14px" }}
            >
              Postback Type
            </Label>
            <Select
              id="postbackType"
              className="react-select-container"
              classNamePrefix="react-select"
              name="postbackType"
              placeholder="Select Postback Type"
              value={
                dataSource.value === "mysql"
                  ? {
                    value: "v1",
                    label: "Mediation SDK + Ads API"
                  }
                  : postbackType
              }
              onChange={handlePostbackTypeChange}
              options={
                dataSource.value === "mysql"
                  ? [{
                    value: "v1",
                    label: "Mediation SDK + Ads API"
                  }]
                  : [{
                    value: "v0",
                    label: "Ads API"
                  },
                  {
                    value: "v1",
                    label: "Mediation SDK"
                  }]
              }
              isDisabled={loading}
            />
          </FormGroup>
        </Col>
        <Col sm="3">
          <FormGroup>
            <Label
              className="font-weight-bolder mb-2"
              style={{ fontSize: "14px" }}
            >
              Data Source
            </Label>
            <Select
              id="dataSource"
              className="react-select-container"
              classNamePrefix="react-select"
              name="dataSource"
              placeholder="Select Data Source"
              value={dataSource}
              onChange={handleDataSourceChange}
              options={dataSourceOptions}
              isDisabled={true}
            />
          </FormGroup>
        </Col>
      </Row>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Row form>
            <Col md={4}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  {dataSource.value === "mysql" ? (
                    <>
                      Date <small className="text-info">(DD/MM/YYYY format)</small>
                    </>
                  ) : (
                    <>
                      Date Range <small className="text-info">(MM/DD/YYYY format)</small>
                    </>
                  )}
                </Label>
                {dataSource.value === "mysql" ? (
                  <Input
                    type="date"
                    name="date"
                    onChange={handleDateChange}
                    value={getdateValue(filters.startDate)}
                  />
                ) : (
                  <DateRangePicker
                    initialSettings={{ startDate: filters.startDate, endDate: filters.endDate, ranges }}
                    onApply={handleApplyDates}
                  >
                    <input
                      type="text"
                      value={filters.startDate + " - " + filters.endDate}
                      className="form-control"
                    />
                  </DateRangePicker>
                )}
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Revenue Type
                </Label>
                <Select
                  isMulti
                  id="revenueType"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="revenueType"
                  placeholder="Select Revenue Type"
                  value={filters.revenueType}
                  onChange={(event) => handleChange(event, "revenueType")}
                  options={revenueTypeOptions}
                  isDisabled={true}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Client
                </Label>
                <Select
                  isMulti
                  id="clientId"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="clientId"
                  placeholder="Select Client"
                  value={filters.clientId}
                  onChange={(event) => handleChange(event, "clientId")}
                  options={clientOptions}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Placement
                </Label>
                <Select
                  isMulti
                  id="placementId"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="placementId"
                  placeholder="Select Placement"
                  value={filters.placementId}
                  onChange={(event) => handleChange(event, "placementId")}
                  options={placementOptions}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Provider
                </Label>
                <Select
                  isMulti
                  id="providerId"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="providerId"
                  placeholder="Select Provider"
                  value={filters.providerId}
                  onChange={(event) => handleChange(event, "providerId")}
                  options={providerOptions}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Ad
                </Label>
                <Select
                  isMulti
                  id="adId"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="adId"
                  placeholder="Select Ad"
                  value={filters.adId}
                  onChange={(event) => handleChange(event, "adId")}
                  options={adOptions}
                />
              </FormGroup>
            </Col>
          </Row>
          <Button className="d-block mt-2 mb-4 px-3" color="primary" onClick={handleFilter}>
            Filter
          </Button>

          <Row className="mb-4">
            <Col md={4}>
              <Card className="border-success h-100 mb-0" style={{ borderWidth: 2 }}>
                <CardBody>
                  <p>Total Earnings</p>
                  <div className='d-flex align-items-end flex-wrap'>
                    <h2 className='mb-0' style={{ lineHeight: 1 }}>₹ {convertToIndianFormat(revenueMetricsData?.eTo) ?? "NA"}</h2>
                    {revenueMetricsData?.eToD === 0
                      ? <span className='ml-2'>(NA)</span>
                      : revenueMetricsData?.eToD > 0
                        ? <span className='ml-2 text-success'>(▲ {revenueMetricsData?.eToD?.toFixed(2)}%)</span>
                        : <span className='ml-2 text-danger'>(▼ {revenueMetricsData?.eToD?.toFixed(2)}%)</span>
                    }
                  </div>
                  <p className='text-info font-weight-bolder mb-0 pt-2 ' style={{ fontSize: 16 }}>{convertToIndianFormat(revenueMetricsData?.tIm) ?? 0} impressions
                    {revenueMetricsData?.tImD === 0
                      ? <span className='ml-2'>(NA)</span>
                      : revenueMetricsData?.tImD > 0
                        ? <span className='ml-2 text-success' style={{ fontSize: 14 }}>(▲ {revenueMetricsData?.tImD?.toFixed(2)}%)</span>
                        : <span className='ml-2 text-danger' style={{ fontSize: 14 }}>(▼ {revenueMetricsData?.tImD?.toFixed(2)}%)</span>
                    }
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="border-primary h-100 mb-0" style={{ borderWidth: 2 }}>
                <CardBody>
                  <p>Earnings from Clicks</p>
                  <div className='d-flex align-items-end flex-wrap'>
                    <h2 className='mb-0' style={{ lineHeight: 1 }}>₹ {convertToIndianFormat(0) ?? "NA"}</h2>
                    <span className='ml-2'>(NA)</span>
                  </div>
                  <p className='text-info font-weight-bolder mb-0 pt-2 ' style={{ fontSize: 16 }}>{convertToIndianFormat(revenueMetricsData?.tCl) ?? 0} clicks
                    {revenueMetricsData?.tClD === 0
                      ? <span className='ml-2'>(NA)</span>
                      : revenueMetricsData?.tClD > 0
                        ? <span className='ml-2 text-success' style={{ fontSize: 14 }}>(▲ {revenueMetricsData?.tClD?.toFixed(2)}%)</span>
                        : <span className='ml-2 text-danger' style={{ fontSize: 14 }}>(▼ {revenueMetricsData?.tClD?.toFixed(2)}%)</span>
                    }
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="border-danger h-100 mb-0" style={{ borderWidth: 2 }}>
                <CardBody>
                  <p>Earnings from Installs</p>
                  <div className='d-flex align-items-end flex-wrap'>
                    <h2 className='mb-0' style={{ lineHeight: 1 }}>₹ {convertToIndianFormat(revenueMetricsData?.eIn) ?? "NA"}</h2>
                    {revenueMetricsData?.eInD === 0
                      ? <span className='ml-2'>(NA)</span>
                      : revenueMetricsData?.eInD > 0
                        ? <span className='ml-2 text-success'>(▲ {revenueMetricsData?.eInD?.toFixed(2)}%)</span>
                        : <span className='ml-2 text-danger'>(▼ {revenueMetricsData?.eInD?.toFixed(2)}%)</span>
                    }
                  </div>
                  <p className='text-info font-weight-bolder mb-0 pt-2 ' style={{ fontSize: 16 }}>{convertToIndianFormat(revenueMetricsData?.tIn) ?? 0} installs
                    {revenueMetricsData?.tInD === 0
                      ? <span className='ml-2'>(NA)</span>
                      : revenueMetricsData?.tInD > 0
                        ? <span className='ml-2 text-success' style={{ fontSize: 14 }}>(▲ {revenueMetricsData?.tInD?.toFixed(2)}%)</span>
                        : <span className='ml-2 text-danger' style={{ fontSize: 14 }}>(▼ {revenueMetricsData?.tInD?.toFixed(2)}%)</span>
                    }
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={3} className="offset-md-9">
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Granularity
                </Label>
                <Select
                  id="graphGranularity"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="graphGranularity"
                  placeholder="Select Granularity"
                  value={graphGranularity}
                  onChange={handleGraphGranularityChange}
                  options={graphGranularityOptions}
                  isDisabled={!isGranularityDropdownEnabled}
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              {graphLoading ? (
                <Card>
                  <CardBody>
                    <Loading />
                  </CardBody>
                </Card>
              ) : (
                <RevenueGraph
                  loading={loading}
                  data={revenueGraphData}
                />
              )}
            </Col>
          </Row>

          <Accordion defaultActiveKey={"0"}>
            <Card style={{ border: "4px solid #dee2e6" }}>
              <Accordion.Toggle
                as={CardHeader}
                eventKey={"0"}
                className="d-flex justify-content-between align-items-center revenueNotesHeader"
                onClick={() => setIsCollapse(!isCollapse)}
              >
                <h4 className='mb-0'>Notes</h4>
                <div className="d-flex justify-content-between align-items-center">
                  {isRevenueNoteCreatePermission() &&
                    <Button className="d-block px-3 my-1 mr-3" color="secondary" onClick={() => showRevenueDashboardNoteModal("Add")}>
                      Add Note
                    </Button>
                  }
                  {!isCollapse ? <BsChevronUp /> : <BsChevronDown />}
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={"0"}>
                <CardBody className="pt-0">
                  <BootstrapTable
                    bootstrap4={true}
                    wrapperClasses="table-responsive"
                    hover={true}
                    bordered={false}
                    keyField="id"
                    data={notes}
                    columns={revenueNotesTableColumns}
                    expandRow={expandRow}
                    filter={filterFactory()}
                    pagination={paginationFactory({
                      sizePerPage: 3,
                      sizePerPageList: [3, 5, 10, 25, 50],
                    })}
                    defaultSorted={[{
                      dataField: 'date',
                      order: 'desc'
                    }]}
                    remote={{ filter: true }}
                    noDataIndication="No results found"
                  />
                </CardBody>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          <Card>
            <CardHeader>
              <Row className="d-flex align-items-start">
                <Col md={7}>
                  <FormGroup>
                    <Label
                      className="font-weight-bolder mb-2"
                      style={{ fontSize: "14px" }}
                    >
                      Group By <small className="text-info">(Keep atleast one field)</small>
                    </Label>
                    <Select
                      isMulti
                      id="providerDataGroupBy"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="providerDataGroupBy"
                      placeholder="Select Fields"
                      value={providerDataGroupBy}
                      onChange={handleProviderDataGroupBy}
                      options={providerDataGroupByOptions}
                      isDisabled={providerDataLoading}
                    />
                  </FormGroup>
                </Col>
                <Col md={3} className="offset-md-2 d-flex justify-content-end">
                  <Button
                    className="d-block px-3 my-1 mt-3"
                    color="secondary"
                    disabled={revenueProviderData?.length === 0}
                    onClick={() => handleCSVExport("provider")}
                  >
                    Export as CSV
                  </Button>
                </Col>
              </Row>
              <Row className="d-flex align-items-center">
                <Col md={4}>
                  <FormGroup>
                    <Label
                      className="font-weight-bolder mb-2"
                      style={{ fontSize: "14px" }}
                    >
                      Date
                    </Label>
                    <Select
                      isMulti
                      id="date"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="date"
                      placeholder="Filter Date"
                      value={providerDataFilters.date}
                      onChange={(event) => handleProviderDataFiltersChange(event, "date")}
                      options={providerDataFiltersOptions.date}
                      isDisabled={providerDataLoading}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label
                      className="font-weight-bolder mb-2"
                      style={{ fontSize: "14px" }}
                    >
                      Client
                    </Label>
                    <Select
                      isMulti
                      id="client"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="client"
                      placeholder="Filter Client"
                      value={providerDataFilters.client}
                      onChange={(event) => handleProviderDataFiltersChange(event, "client")}
                      options={providerDataFiltersOptions.client}
                      isDisabled={providerDataLoading}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label
                      className="font-weight-bolder mb-2"
                      style={{ fontSize: "14px" }}
                    >
                      Placement
                    </Label>
                    <Select
                      isMulti
                      id="placement"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="placement"
                      placeholder="Filter Placement"
                      value={providerDataFilters.placement}
                      onChange={(event) => handleProviderDataFiltersChange(event, "placement")}
                      options={providerDataFiltersOptions.placement}
                      isDisabled={providerDataLoading}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label
                      className="font-weight-bolder mb-2"
                      style={{ fontSize: "14px" }}
                    >
                      Provider
                    </Label>
                    <Select
                      isMulti
                      id="provider"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="provider"
                      placeholder="Filter Provider"
                      value={providerDataFilters.provider}
                      onChange={(event) => handleProviderDataFiltersChange(event, "provider")}
                      options={providerDataFiltersOptions.provider}
                      isDisabled={providerDataLoading}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label
                      className="font-weight-bolder mb-2"
                      style={{ fontSize: "14px" }}
                    >
                      Revenue Type
                    </Label>
                    <Select
                      isMulti
                      id="revenueType"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="revenueType"
                      placeholder="Filter Revenue Type"
                      value={providerDataFilters.revenueType}
                      onChange={(event) => handleProviderDataFiltersChange(event, "revenueType")}
                      options={providerDataFiltersOptions.revenueType}
                      isDisabled={providerDataLoading}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button
                className="d-block px-3 my-1 mb-2"
                color="secondary"
                disabled={providerDataLoading}
                onClick={handleProviderDataGroupFilter}
              >
                Group and Filter
              </Button>
            </CardHeader>
            <CardBody className="pt-0">
              {providerDataLoading ? (
                <Loading />
              ) : (
                <BootstrapTable
                  bootstrap4={true}
                  wrapperClasses="table-responsive"
                  hover={true}
                  bordered={false}
                  keyField="id"
                  data={revenueProviderData}
                  columns={providersRevenueTableColumns}
                  filter={filterFactory()}
                  pagination={paginationFactory({
                    sizePerPage: 10,
                    sizePerPageList: [5, 10, 25, 50],
                  })}
                  defaultSorted={[{
                    dataField: 'adValue',
                    order: 'desc'
                  }]}
                  remote={{ filter: true }}
                  noDataIndication="No results found"
                />
              )}
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <Row className="d-flex align-items-start">
                <Col md={7}>
                  <h4 className='mb-2'>Direct Ads Performance</h4>
                  <FormGroup>
                    <Label
                      className="font-weight-bolder mb-2"
                      style={{ fontSize: "14px" }}
                    >
                      Group By <small className="text-info">(Keep atleast one field)</small>
                    </Label>
                    <Select
                      isMulti
                      id="adDataGroupBy"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="adDataGroupBy"
                      placeholder="Select Fields"
                      value={adDataGroupBy}
                      onChange={handleAdDataGroupBy}
                      options={adDataGroupByOptions}
                      isDisabled={adDataLoading}
                    />
                  </FormGroup>
                </Col>
                <Col md={3} className="offset-md-2 d-flex justify-content-end">
                  <Button
                    className="d-block px-3 my-1 mt-3"
                    color="secondary"
                    disabled={revenueAdsData?.length === 0}
                    onClick={() => handleCSVExport("directAds")}
                  >
                    Export as CSV
                  </Button>
                </Col>
              </Row>
              <Row className="d-flex align-items-center">
                <Col md={4}>
                  <FormGroup>
                    <Label
                      className="font-weight-bolder mb-2"
                      style={{ fontSize: "14px" }}
                    >
                      Date
                    </Label>
                    <Select
                      isMulti
                      id="date"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="date"
                      placeholder="Filter Date"
                      value={adDataFilters.date}
                      onChange={(event) => handleAdDataFiltersChange(event, "date")}
                      options={adDataFiltersOptions.date}
                      isDisabled={adDataLoading}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label
                      className="font-weight-bolder mb-2"
                      style={{ fontSize: "14px" }}
                    >
                      Client
                    </Label>
                    <Select
                      isMulti
                      id="client"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="client"
                      placeholder="Filter Client"
                      value={adDataFilters.client}
                      onChange={(event) => handleAdDataFiltersChange(event, "client")}
                      options={adDataFiltersOptions.client}
                      isDisabled={adDataLoading}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label
                      className="font-weight-bolder mb-2"
                      style={{ fontSize: "14px" }}
                    >
                      Placement
                    </Label>
                    <Select
                      isMulti
                      id="placement"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="placement"
                      placeholder="Filter Placement"
                      value={adDataFilters.placement}
                      onChange={(event) => handleAdDataFiltersChange(event, "placement")}
                      options={adDataFiltersOptions.placement}
                      isDisabled={adDataLoading}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label
                      className="font-weight-bolder mb-2"
                      style={{ fontSize: "14px" }}
                    >
                      Provider
                    </Label>
                    <Select
                      isMulti
                      id="provider"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="provider"
                      placeholder="Filter Provider"
                      value={adDataFilters.provider}
                      onChange={(event) => handleAdDataFiltersChange(event, "provider")}
                      options={adDataFiltersOptions.provider}
                      isDisabled={adDataLoading}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label
                      className="font-weight-bolder mb-2"
                      style={{ fontSize: "14px" }}
                    >
                      Ad Name
                    </Label>
                    <Select
                      isMulti
                      id="ad"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="ad"
                      placeholder="Filter Ad Name"
                      value={adDataFilters.ad}
                      onChange={(event) => handleAdDataFiltersChange(event, "ad")}
                      options={adDataFiltersOptions.ad}
                      isDisabled={adDataLoading}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label
                      className="font-weight-bolder mb-2"
                      style={{ fontSize: "14px" }}
                    >
                      Revenue Type
                    </Label>
                    <Select
                      isMulti
                      id="revenueType"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="revenueType"
                      placeholder="Filter Revenue Type"
                      value={adDataFilters.revenueType}
                      onChange={(event) => handleAdDataFiltersChange(event, "revenueType")}
                      options={adDataFiltersOptions.revenueType}
                      isDisabled={adDataLoading}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button
                className="d-block px-3 my-1 mb-2"
                color="secondary"
                disabled={adDataLoading}
                onClick={handleAdDataGroupFilter}
              >
                Group and Filter
              </Button>
            </CardHeader>
            <CardBody className="pt-0">
              {adDataLoading ? (
                <Loading />
              ) : (
                <BootstrapTable
                  bootstrap4={true}
                  wrapperClasses="table-responsive"
                  hover={true}
                  bordered={false}
                  keyField="id"
                  data={revenueAdsData}
                  columns={adsRevenueTableColumns}
                  filter={filterFactory()}
                  pagination={paginationFactory({
                    sizePerPage: 10,
                    sizePerPageList: [5, 10, 25, 50],
                  })}
                  defaultSorted={[{
                    dataField: 'adValue',
                    order: 'desc'
                  }]}
                  remote={{ filter: true }}
                  noDataIndication="No results found"
                />
              )}
            </CardBody>
          </Card>
        </div>
      )}
      <RevenueDashboardNoteModal
        show={isRevenueDashboardNoteModalVisible}
        type={changeType}
        formData={formData}
        setFormData={setFormData}
        onSubmit={handleSubmit}
        onHide={hideRevenueDashboardNoteModal}
      />
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={action}
        onConfirmationTrue={() => {
          setConfirmationModalIsOpen(false);
          if (action === revenueDashboardNotesAllActions.delete) deleteNote(id);
        }}
        onHide={() => setConfirmationModalIsOpen(false)}
      />
      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={successModalHeading}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={errorModalHeading}
        modaltext={failureModalText}
      />
    </Container>
  )
}

export default Revenue
import React, { useEffect, useState } from "react";
import { Container, Button, Row, Col, Spinner } from "reactstrap";
import Search from "../../../components/Search";
import QuickReplyCategoryCard from "./components/QuickReplyCategoryCard";

import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import UncontrolledButtonDropdown from "reactstrap/lib/UncontrolledButtonDropdown";
import { Config } from "../../../config";
import Select from "react-select";

import {
  getQuickReplyCategories,
  activateQuickReplyCategory,
  deactivateQuickReplyCategory,
  publishQuickReplyCategory,
} from "../../../utilities/apiUtils/quickReplies";
import { capitalize } from "@material-ui/core";
import EmptyQuickReplyCategories from "./components/EmptyQuickReplyCategory";

import {
  successModalHeading,
  failureModalHeading,
  publishedStatus,
  activatedStatus,
  deactivatedStatus,
} from "../../../config/quickReplies";
import ConfirmationModal from "../ConfirmationModal";
import ResponseModal from "../ResponseModal";
import {
  allActions,
  error,
  errorDescription,
  getStatus,
  getBtnName,
  getStatusColor,
  getButtonColor,
  permissions,
  redirectionOnTokenExpiry,
} from "../../../utilities/commonUtil";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { faUpload, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faWindows } from "@fortawesome/free-brands-svg-icons";

const { SearchBar } = Search;

const QuickReplyCategories = () => {
  const [quickReplyCategories, setQuickReplyCategories] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [searchString, setSearchString] = useState("");
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [id, setId] = useState(null);
  const [action, setAction] = useState("");
  const [hasMoreQuickReplyCategories, setHasMoreQuickReplyCategories] = useState(true);
  const [noQuickReplyFound, setNoQuickReplyFound] = useState(false);
  const [status, setStatus] = useState("");
  const [searchBy, setSearchBy] = useState(Config.SEARCH_BY_DEFAULT);
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState(Config.UpdatedAtDescending);

  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  const [successModalState, setSuccessModalState] = useState({
    successModalDisplay: false,
    successModalText: "",
  });
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });

  const fetchMoreData = async () => {
    if (searchBy === "id") return;

    setPage(page + 1);
    setNoQuickReplyFound(false);
    setHasMoreQuickReplyCategories(true);
  };

  useEffect(() => {
    search();
  }, [searchString]);


  useEffect(() => {
     search();
  }, [status, sortBy]);

  useEffect(() => {
    const fetchData = async () => { 
      try {
        let params = {
          searchString: searchBy === "name" ? searchString : "",
          id: searchBy === "id" ? searchString : "",
          status: status,
          sortBy: sortBy,
          page: page,
          limit: limit,
        };
  
        let response = await getQuickReplyCategories(params);

        if (response.quickReplyCategories !== null) {
          let allQuickRepliesCategories = quickReplyCategories.concat(
            response.quickReplyCategories
            );
            setQuickReplyCategories(allQuickRepliesCategories);
        } else {
          setHasMoreQuickReplyCategories(false);
        }
      } catch (err) {
        showErrorModal(errorDescription(error.unexpectedError));
      }
    }

    fetchData();
  }, [page]);

  const showCreateQuickReplyCategoryButton = () => {
    return allowedPermissions.includes(permissions.quickReplyCategoryCreate);
  };

  const handleStatusChange = (option) => {
    setStatus(option.value);
  };

  const handleOrderChange = (sortBy) => {
    setSortBy(sortBy.value);
  };

  const fetchQuickReplyCategoryData = async () => {
    try {
      let params = {
        searchString: searchBy === "name" ? searchString : "",
        id: searchBy === "id" ? searchString : "",
        status: status,
        sortBy: sortBy,
        page: page,
        limit: limit,
      };

      let response = await getQuickReplyCategories(params);

      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectionOnTokenExpiry();
        let errorCode = errorDescription(response.errorCode);
        showErrorModal(errorCode);
      } else if (response.quickReplyCategories) {
        setQuickReplyCategories(response.quickReplyCategories);
        setLoading(false);
      }
    } catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  };

  const getIcon = (status) => {
    if (status === "Deactivated") return faUpload;
    return faTimes;
  };

  const performAction = () => {
    if (action === allActions.publish) {
      publish(id);
    }
    if (action === allActions.deactivate) {
      deactivate(id);
    }
    if (action === allActions.activate) {
      activate(id);
    }
  };

  const successModelClose = () => {
    setSuccessModalState((prev) => ({ ...prev, successModalDisplay: false }));
    setQuickReplyCategories([]);
    setPage(0);
    setHasMoreQuickReplyCategories(true);
    fetchQuickReplyCategoryData();
  };

  const search = async (localSearchBy) => {
    setPage(0);
    setNoQuickReplyFound(false);
    setHasMoreQuickReplyCategories(true);
    setSearchBy(localSearchBy || searchBy);
    setLoading(true);
    setQuickReplyCategories([]);

    let params = {
      searchString: searchBy === "name" ? searchString : "",
      id: searchBy === "id" ? searchString : "",
      status: status,
      sortBy: sortBy,
      page: page,
      limit: limit,
    };

    try {
      let response = await getQuickReplyCategories(params);
      if (response.quickReplyCategories != null) {
        setQuickReplyCategories(response.quickReplyCategories);
        setLoading(false);
        return;
      }
      if (page === 0) {
        setLoading(false);
        setNoQuickReplyFound(true);
      } else {
        setLoading(false);
        setNoQuickReplyFound(false);
      }
    } catch (err) {
      setLoading(false);
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const failureModalClose = () => {
    setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
  };

  const showErrorModal = (errorCode) => {
    setFailureModalState({
      failureModalText: errorDescription(errorCode),
      failureModalDisplay: true,
    });
  };

  const showSuccessModal = (text) => {
    setSuccessModalState({
      successModalDisplay: true,
      successModalText: text,
    });
  };

  const renderAllQuickReplyCategories = () => {
    if (quickReplyCategories.length === 0 && !loading) {
      return <EmptyQuickReplyCategories />;
    } else {
      return quickReplyCategories.map((category) => {
        let status = getStatus(category.publishedAt, category.deactivatedAt);
        let color = getStatusColor(status);
        let btnColor = getButtonColor(status);
        let btnName = getBtnName(status);
        return (
          <Col md="6" lg="4" key={category.ID}>
            <QuickReplyCategoryCard
              category={category}
              color={color}
              status={status}
              btnName={btnName}
              btnColor={btnColor}
              btnIcon={getIcon(status)}
              getConfirmation={setConfirmationModalState}
            />
          </Col>
        );
      });
    }
  };

  const setConfirmationModalState = (id, event) => {
    setAction(event);
    setConfirmationModalIsOpen(true);
    setId(id);
  };

  const publish = async (id) => {
    try {
      let response = await publishQuickReplyCategory(id);
      if (response.status === publishedStatus) {
        showSuccessModal(response.description);
      } else {
        showErrorModal(response.errorDescription);
      }
    } catch (err) {
      showErrorModal(error.unexpectedError);
    }
  };

  const deactivate = async (id) => {
    try {
      let response = await deactivateQuickReplyCategory(id);
      if (response.status === deactivatedStatus) {
        showSuccessModal(response.description);
      } else {
        showErrorModal(response.errorDescription);
      }
    } catch (err) {
      showErrorModal(error.unexpectedError);
    }
  };

  const activate = async (id) => {
    try {
      let response = await activateQuickReplyCategory(id);
      if (response.status === activatedStatus) {
        showSuccessModal(response.description);
      } else {
        showErrorModal(response.errorDescription);
      }
    } catch (err) {
      showErrorModal(error.unexpectedError);
    }
  };

  const handleSearchStringChange = (event) => {
    event.preventDefault();
    let { value } = event.target;
    setSearchString(value);
    if (!value.length) {
      setSearchString("");
      search();
      return;
    }
  };

  const onKeyDown = (event) => {
    if (event.keyCode === Config.KEYPRESS_DOWN) {
      search();
    }
  };

  return (
    <Container fluid className="p-0">
      <Row>
        <h1 className="mb-3 pl-3">Quick Reply Categories</h1>
        {showCreateQuickReplyCategoryButton() ? (
          <Link
            className="d-block ml-auto mb-3 pr-3 text-decoration-none"
            to={{
              pathname: `/quick-reply/create-quick-reply-category`,
            }}
          >
            <Button color="primary" className="ml-auto d-flex">
              <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                Add Quick Reply Category
              </p>
            </Button>
          </Link>
        ) : null}
      </Row>
      <Row>
        <Col md={4} sm={12}>
          <Search
            placeholder="Search"
            value={searchString}
            onChange={(event) => handleSearchStringChange(event)}
            onKeyDown={(event) => onKeyDown(event)}
          ></Search>
        </Col>
        <Col md={3} sm={12}>
          <UncontrolledButtonDropdown className="mr-1 mb-1">
            <DropdownToggle caret color="success">
              {"Search By " + capitalize(searchBy) + " "}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  search("id");
                }}
              >
                ID
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  search("name");
                }}
              >
                Name
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      <Row>
        <Col md={4} sm={12}>
          <Select
            className="react-select-container mb-4"
            classNamePrefix="react-select"
            placeholder="Select Status"
            onChange={(option) => handleStatusChange(option)}
            options={[
              { value: "", label: "All" },
              { value: "active", label: "Active" },
              { value: "deactivated", label: "Deactivated" },
              { value: "unpublished", label: "Unpublished" },
            ]}
          />
        </Col>
        <Col md={4} sm={12}>
          <Select
            className="react-select-container mb-4"
            classNamePrefix="react-select"
            placeholder="Sort By"
            onChange={(option) => handleOrderChange(option)}
            options={[
              {
                value: Config.UpdatedAtDescending,
                label: "Updated At \u2193",
              },
              {
                value: Config.UpdatedAtAscending,
                label: "Updated At \u2191",
              },
              {
                value: Config.CreatedAtDescending,
                label: "Created At \u2193",
              },
              {
                value: Config.CreatedAtAscending,
                label: "Created At \u2191",
              },
              {
                value: Config.PriorityDescending,
                label: "Priority \u2193",
              },
              {
                value: Config.ProirityAscending,
                label: "Priority \u2191",
              },
            ]}
          />
        </Col>
      </Row>

      <InfiniteScroll
        className="infinite-scroll"
        dataLength={quickReplyCategories.length}
        next={fetchMoreData}
        hasMore={hasMoreQuickReplyCategories}
        loader={
          !loading &&
          searchBy !== "id" &&
          !noQuickReplyFound && <Spinner color="info" className=" d-flex mx-auto" />
        }
      >
        {loading ? (
          <Spinner color="info" className=" d-flex mx-auto" />
        ) : noQuickReplyFound ? (
          <Container
            sm="12"
            md="8"
            lg="6"
            className="mx-auto d-table mt-5 pt-2 pb-5"
          >
            <h1 className="text-center">No quick reply found</h1>
          </Container>
        ) : (
          <React.Fragment>
            <Row className="mt-2">{renderAllQuickReplyCategories()}</Row>
            <ResponseModal
              show={successModalState.successModalDisplay}
              onHide={() => successModelClose()}
              modalheading={successModalHeading}
              modaltext={successModalState.successModalText}
            />
            <ResponseModal
              show={failureModalState.failureModalDisplay}
              onHide={() => failureModalClose()}
              modalheading={failureModalHeading}
              modaltext={failureModalState.failureModalText}
            />
            <ConfirmationModal
              show={confirmationModalIsOpen}
              modaltext={action}
              onHide={() => {
                setConfirmationModalIsOpen(false);
              }}
              onConfirmationTrue={() => {
                setConfirmationModalIsOpen(false);
                performAction();
              }}
            />
          </React.Fragment>
        )}
      </InfiniteScroll>
    </Container>
  );
};

export default QuickReplyCategories;

import React, { useState, useEffect } from "react";
import { Card, CardBody, Badge } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { movieGifColumns, gifColumns } from "./tagTableColumns";

const TagTable = ({ tag, gifType }) => {
  const [userData, setUserData] = useState([]);
  const columns = gifType == "movieGif" ? movieGifColumns : gifColumns;

  useEffect(() => {
    if (gifType == "movieGif") {
      if (tag !== undefined && tag.length > 0) {
        const groupedTags = tag.reduce((grouped, tag) => {
          const { type, text, languageId } = tag;
          // replace with languageCode
          const languageIdString = languageId.toString();
          if (type == "generic") {
            if (!grouped[type]) {
              grouped[type] = {};
            }

            if (!grouped[type][languageIdString]) {
              grouped[type][languageIdString] = [];
            }

            grouped[type][languageIdString].push(text);
          }
          return grouped;
        }, {});
        const result = Object.entries(groupedTags).map(([type, languages]) => ({
          languages: Object.entries(languages).map(([languageId, texts]) => ({
            type,
            languageId,
            text: texts.join(", "),
          })),
        }));
        let userData = [];
        result.forEach((val) => {
          userData = [...userData, ...val.languages];
        });
        setUserData(userData);
      }
    } else if (gifType == "gif") {
      if (tag !== undefined && tag.length > 0) {
        const groupedTags = tag.reduce((grouped, tag) => {
          const { type, text } = tag;
          if (!grouped[type]) {
            grouped[type] = [];
          }
          grouped[type].push(text);

          return grouped;
        }, {});
        const result = Object.entries(groupedTags).map(([type, texts]) => ({
          type,
          text: texts.join(", "),
        }));
        console.log(result);
        setUserData(result);
      }
    }
  }, [tag, columns]);
  const textFormatter = (
    cell,
    row,
    rowIndex,
    columnIndex,
    columnIndexOriginal
  ) => {
    return (
      <div style={{ whiteSpace: "normal" }}>
        {row.text.split(",").map((text, index) => (
          <Badge key={index} className="badge-secondary ml-2 mb-2 p-1">
            {text.trim()}
          </Badge>
        ))}
      </div>
    );
  };
  return (
    <Card>
      <CardBody>
        <ToolkitProvider
          keyField="text"
          data={userData}
          columns={columns}
          bootstrap4
        >
          {(props) => (
            <div>
              <BootstrapTable
                {...props.baseProps}
                bordered={false}
                keyField="text"
                data={userData}
                columns={[
                  ...columns.map((col) => ({
                    ...col,
                    formatter:
                      col.dataField === "text" ? textFormatter : col.formatter,
                  })),
                ]}
                pagination={paginationFactory({
                  sizePerPage: 10,
                  sizePerPageList: [5, 10, 25, 50],
                })}
                hover={false}
                rowEvents={{
                  onMouseOver: (e, row, rowIndex) => {
                    e.target.style.cursor = "auto";
                  },
                }}
                groupBy="type"
              />
            </div>
          )}
        </ToolkitProvider>
      </CardBody>
    </Card>
  );
};

export default TagTable;

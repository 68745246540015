import React from "react";
import { Modal, Button } from "react-bootstrap";

class ConfirmationModal extends React.Component {
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header className="bg-light" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to {this.props.modaltext}?</p>
        </Modal.Body>
        <Modal.Footer className="bg-light">
          <Button onClick={this.props.onConfirmationTrue}>Yes</Button>
          <Button onClick={this.props.onHide}>No</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ConfirmationModal;

export const createDeepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}

export const clientSpecificServices = [
  'ads-api'
]

export const locationSpecificServices = [
  'ads-api'
]

export const placementSpecificServices = [
  'ads-api'
]

export const countrySpecificServices = [
  'ads-api'
] 
import { host } from "./index";
import { retrieveFromLocalStorage } from "../localStorageUtil";
import axios from "axios";
import { secureFetch } from "../secureFetch";

// let host = "http://localhost:1323"

export let fetchSuggestionDrawerCategoriesData = async (params) => {
  let url = `${host}/v4/internal/suggestionDrawerCategories?` + new URLSearchParams(params);
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let publishSuggestionDrawerCategory = async (id) => {
  let url = `${host}/v4/internal/suggestionDrawerCategories/${id}/publish`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let activateSuggestionDrawerCategory = async (id) => {
  let url = `${host}/v4/internal/suggestionDrawerCategories/${id}/activate`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let deactivateSuggestionDrawerCategory = async (id) => {
  let url = `${host}/v4/internal/suggestionDrawerCategories/${id}/deactivate`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};
export let publishSuggestionDrawerContent = async (id) => {
  let url = `${host}/v4/internal/suggestionDrawerContent/${id}/publish`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let activateSuggestionDrawerContent = async (id) => {
  let url = `${host}/v4/internal/suggestionDrawerContent/${id}/activate`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let deactivateSuggestionDrawerContent = async (id) => {
  let url = `${host}/v4/internal/suggestionDrawerContent/${id}/deactivate`;
  console.log(id)
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchSuggestionDrawerCategoryDataByID = async (id) => {
  let url = `${host}/v4/internal/suggestionDrawerCategories/${id}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};
export let fetchSuggestionDrawerContentDataByID = async (id) => {
  let url = `${host}/v4/internal/suggestionDrawerContent/${id}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};


export let fetchSuggestionDrawerContentData = async (params) => {
  let url = `${host}/v4/internal/suggestionDrawerContents?` + new URLSearchParams(params);
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchSuggestionDrawerCategoryContentOrderData = async (id) => {
  let url = `${host}/v4/internal/suggestionDrawerCategories/${id}/content/reorder`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchSuggestionDrawerCategoryOrderData = async () => {
  let url = `${host}/v4/internal/suggestionDrawerCategories/reorder`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
}

export let updateSuggestionDrawerCategoryOrderData = async (data) => {
  let url = `${host}/v4/internal/suggestionDrawerCategories/reorder`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
}


export let fetchSugggestionDrawerTargetingRules = async (id) => {
  let rules = [];

  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/suggestionDrawerCategories/${id}/targetingRules`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    var res = await response.json();
    console.log(res)
    if (res) {
      rules = res.rules;
    }
    return rules;
  } catch (error) {
    return;
  }
};

export let fetchSugggestionDrawerContentTargetingRules = async (contentId) => {
  let ruleSets = [];
  let token = retrieveFromLocalStorage("token");

  const fetchRuleSet = async (id) => {
    let url = `${host}/v4/internal/suggestionDrawerContent/${id}/targetingRules`;
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });

      let res = await response.json();
      console.log(`Rules for ID ${id}:`, res);

      return res?.rules || [];
    } catch (error) {
      console.error(`Error fetching rules for ID ${id}:`, error);
      return [];
    }
  };

  const idsArray = typeof contentId === "string" ? contentId.split(",") : [contentId];
  const allRuleSets = await Promise.all(idsArray.map((id) => fetchRuleSet(id)));
  ruleSets = allRuleSets.flat();

  const getRuleIdentifier = (rule) => {
    return JSON.stringify({
      activate: rule.activate,
      ageGenderRules: rule.ageGenderRules,
      locationRules: rule.locationRules,
      clientRules: rule.clientRules,
      targetUserRules: rule.targetUserRules,
      createdAt: rule.createdAt,
      updatedAt: rule.updatedAt,
      deactivatedAt: rule.deactivatedAt,
    });
  };

  const uniqueRulesSet = new Set();
  const uniqueRules = ruleSets.filter((rule) => {
    const identifier = getRuleIdentifier(rule);
    if (uniqueRulesSet.has(identifier)) {
      return false;
    }
    uniqueRulesSet.add(identifier);
    return true;
  });

  return uniqueRules;
};


export let updateSugggestionDrawerContentTargetingRules = async (rules, id, data) => {

  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/suggestionDrawerContent/${id}/targetingRules`;
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res = await response.json();
    return res;
  } catch (error) {
    return;
  }
};
export let updateSugggestionDrawerCategoryTargetingRules = async (rules, id, data) => {

  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/suggestionDrawerCategories/${id}/targetingRules`;
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res = await response.json();
    return res;
  } catch (error) {
    return;
  }
};

export let updateSuggestionDrawerCategory = async (id, data) => {
  let url = `${host}/v4/internal/suggestionDrawerCategories/${id}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
}

export let updateSuggestionDrawerContent = async (id, data) => {
  let url = `${host}/v4/internal/suggestionDrawerContent/${id}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data)
    });
    return await response.json();
  } catch (error) {
    return;
  }
}

export let updateSuggestionDrawerContentOrderData = async (id, data) => {
  let url = `${host}/v4/internal/suggestionDrawerCategories/${id}/content/reorder`;
  let token = retrieveFromLocalStorage("token");
  console.log("api")
  console.log(data)
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
}

export let createSuggestionDrawerCategory = async (data) => {
  let url = `${host}/v4/internal/suggestionDrawerCategories`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
}

export let createSuggestionDrawerContent = async (data) => {
  let url = `${host}/v4/internal/suggestionDrawerContent`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data)
    });
    return await response.json();
  } catch (error) {
    return;
  }
}

export let getContentUserAttributeRules = async (id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/suggestionDrawerContent/${id}/userAttributeRules`;
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    return res;
  } catch (error) {
    return;
  }
};

export let saveContentUserAttributeRules = async (data, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/suggestionDrawerContent/${id}/userAttributeRules`;
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let deactivateContentUserAttributeRules = async (contentId, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/suggestionDrawerContent/${contentId}/userAttributeRules/${id}/deactivate`;
  try {
    var response = await secureFetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let activateContentUserAttributeRules = async (contentId, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/suggestionDrawerContent/${contentId}/userAttributeRules/${id}/activate`;
  try {
    var response = await secureFetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};


export let getCategoryUserAttributeRules = async (id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/suggestionDrawerCategories/${id}/userAttributeRules`;
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    return res;
  } catch (error) {
    return;
  }
};

export let saveCategoryUserAttributeRules = async (data, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/suggestionDrawerCategories/${id}/userAttributeRules`;
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let deactivateCategoryUserAttributeRules = async (categoryId, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/suggestionDrawerCategories/${categoryId}/userAttributeRules/${id}/deactivate`;
  try {
    var response = await secureFetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let activateCategoryUserAttributeRules = async (categoryId, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/suggestionDrawerCategories/${categoryId}/userAttributeRules/${id}/activate`;
  try {
    var response = await secureFetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let saveBulkContentUserAttributeRules = async (data, id) => {
  let token = retrieveFromLocalStorage("token");
  const ids = Array.isArray(id) ? id : [id]; 

  const results = []; 

  try {
    const requests = ids.map(async (individualId) => {
      const url = `${host}/v4/internal/suggestionDrawerContent/${individualId}/userAttributeRules`;
      const response = await secureFetch(url, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      });
      const res = await response.json();
      results.push(res); 
    });

    await Promise.all(requests);
    return results; 
  } catch (error) {
    console.error("Error saving user attribute rules:", error);
    return; 
  }
};





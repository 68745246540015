import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Container,
  Card,
  CardBody,
  Button,
  CardImg,
  CardTitle,
  Row, // Import Row component from reactstrap
  Col, // Import Col component from reactstrap
} from "reactstrap";
import { activateCarouselItem, deactivateCarouselItem, getCarouselItems } from "../../../utilities/apiUtils/carousel.js";
import { Badge } from "react-bootstrap";
import ResponseModal from "../ResponseModal.js";
import { failureModalHeading, successModalHeading } from "../../../config/index.js";
import ConfirmationModal from "../ConfirmationModal.js";
import { allActions, error, errorDescription } from "../../../utilities/commonUtil.js";

const CarouselItems = () => {
  const { id } = useParams();
  const history = useHistory();

  const [carouselItems, setCarouselItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [action, setAction] = useState("");
  const [itemId, setItemId] = useState("");


  const fetchCarouselItems = async () => {
    try {
      const items = await getCarouselItems(id);
      setCarouselItems(items);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching carousel items:", error);
    }
  };

  useEffect(() => {
    fetchCarouselItems();
  }, [id]);

  const handleDetailsClick = (itemId) => {
    // Handle the click event, navigate to edit-items with the itemId
    history.push(`/cre/carousel/${id}/edit-items/${itemId}`);
  };

  const handleManageItemClick = () => {
    // Handle the click event for "Manage Item" at the top
    console.log("Manage Item clicked");
    history.push(`/cre/carousel/item-order/${id}`);
    // You can use history.push or any other logic here
  };

  const handleAddItemsClick = () => {
    // Handle the click event for "Add Items"
    history.push(`/cre/carousel/add-items/${id}`);
  };

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    fetchCarouselItems();
  };

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let disableDeactivateButton = (item) => {
    if (item.deactivatedAt !== null) return true;
    return false;
  };


  let disableActivateButton = (item) => {
    if (item.deactivatedAt === null) return true;
    return false;
  };


  let activate = async (id, itemId) => {
    try {
      let response = await activateCarouselItem(id, itemId);
      if (response.status === "activated") {
        showSuccessModal("Carousel Item Activated Successfully");
      } else {
        if (response.errorCode) {
          showErrorModal(response.errorCode)
        }
      }
    } catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  };

  let deactivate = async (id, itemId) => {
    try {
      let response = await deactivateCarouselItem(id, itemId);
      if (response.status === "deactivated") {
        showSuccessModal("Carousel Item Deactivated Successfully");
      } else {
        if (response.errorCode) {
          showErrorModal(response.errorCode)
        }
      }
    } catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  };

  let setConfirmationModalState = (event, item) => {
    setAction(event);
    setConfirmationModalIsOpen(true);
    setItemId(item.itemID);
  };


  let updateStatus = (update, id, itemId) => {
    if (update === "activate") {
      activate(id, itemId);
    }
    else if (update === "deactivate") {
      deactivate(id, itemId);
    }
  };

  return (
    <Container className="my-5">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>Carousel Items</h1>
        <div className="d-flex" style={{ gap: "20px" }}>
          <Button color="success" onClick={handleManageItemClick}>
            Manage Item Order
          </Button>
          <Button
            color="success"
            onClick={() => history.push("/cre/content-carousels")}
          >
            Back to Carousel List
          </Button>
          <Button color="primary" onClick={handleAddItemsClick}>
            Add Items
          </Button>
        </div>
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          {carouselItems.length === 0 ? (
            <p>No items found for this carousel.</p>
          ) : (
            <Row>
              {carouselItems.map((item) => (
                <Col md={4} key={item.itemID}>
                  <Card className="mb-3">
                    {item.imageURL && (
                      <CardImg
                        src={item.imageURL}
                        alt={`Preview for ${item.type}`}
                        style={{
                          maxHeight: "220px",
                          objectFit: "cover",
                          paddingLeft: "80px", // Adjust as needed
                          paddingRight: "80px", // Adjust as needed
                        }}
                      />
                    )}
                    <CardBody>
                      <CardTitle tag="h5">{item.type}
                        {item.deactivatedAt === null ?
                          <Badge className="badge-success float-right mt-1">{"Activated"}</Badge> :
                          <Badge className="badge-danger float-right mt-1">{"Deactivated"}</Badge>}
                      </CardTitle>
                      <Button className="mx-2 mt-3" onClick={() => handleDetailsClick(item.itemID)}>
                        Edit
                      </Button>
                      <Button
                        className="mx-2 btn-danger mt-3"
                        disabled={disableDeactivateButton(item)}
                        onClick={(event) =>
                          setConfirmationModalState(event.target.innerHTML, item)
                        }
                      >
                        Deactivate
                      </Button>
                      <Button
                        className="mx-2 btn-success mt-3"
                        disabled={disableActivateButton(item)}
                        onClick={(event) =>
                          setConfirmationModalState(event.target.innerHTML, item)
                        }
                      >
                        Activate
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          )}
        </>
      )}
      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={successModalHeading}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={failureModalHeading}
        modaltext={failureModalText}
      />
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={action}
        onConfirmationTrue={() => {
          setConfirmationModalIsOpen(false);
          if (action === allActions.deactivate) updateStatus("deactivate", id, itemId);
          else if (action === allActions.activate) updateStatus("activate", id, itemId);
        }}
        onHide={() => setConfirmationModalIsOpen(false)}
      />
    </Container>
  );
};

export default CarouselItems;

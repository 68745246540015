import React from 'react'
import { components } from "react-select";
import CreatableSelect from 'react-select/creatable';
import { Modal, Button } from "react-bootstrap";
import { Row, Col, FormGroup, Label, Input, Form } from "reactstrap";

const AdsCategory = ({ show = false, type, formData, setFormData = () => { }, onSubmit = () => { }, onHide = () => { } }) => {
  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit()
  }

  const requiredComponent = (props) => (
    <div>
      <components.Input {...props} required={true} />
    </div>
  );

  const notRequiredComponent = (props) => (
    <div>
      <components.Input {...props} required={false} />
    </div>
  );

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header className="bg-light" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {type} {type === "Edit" ? "Category" : "Categories"}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row form>
            <Col lg={12} md={12}>
              {type === "Edit" ? (
                <FormGroup>
                  <Label
                    className="font-weight-bolder mb-2"
                    style={{ fontSize: "14px" }}
                  >
                    Name <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    placeholder="Enter Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              ) : (
                <FormGroup>
                  <Label
                    className="font-weight-bolder mb-2"
                    style={{ fontSize: "14px" }}
                  >
                    Categories <span className="text-danger">*</span>
                  </Label>
                  <CreatableSelect
                    id="categories"
                    isMulti
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name="categories"
                    placeholder="Enter Category Names"
                    value={formData.categories}
                    onChange={(event) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        categories: event
                      }));
                    }}
                    components={{
                      DropdownIndicator: null,
                      Input:
                        (formData.categories?.length > 0)
                          ? notRequiredComponent
                          : requiredComponent,
                    }}
                  />
                </FormGroup>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
          <Button type='submit' className='btn-success'>Submit</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AdsCategory
import React, { useState } from "react";
import { userAttributeRuleFilterOptions } from "../../../../config/config";
import { retrieveFromLocalStorage } from "../../../../utilities/localStorageUtil";
import { permissions } from "../../../../utilities/commonUtil";
import { activateKeyboardPromptsUserAttributeRules, deactivateKeyboardPromptsUserAttributeRules, getKeyboardPromptsUserAttributeRules, saveKeyboardPromptsUserAttributeRules } from "../../../../utilities/apiUtils/keyboardPrompts";
import UserAttributeFilters from "../../UserAttributeFilters";


const KeyboardPromptsUserAttributeRules = () => {

    const [allowedPermissions] = useState(
        retrieveFromLocalStorage("userPermissions")
    );

    const updateRulesAllowed = () => {
        return allowedPermissions.includes(permissions.keyboardPromptTargetingRulesUpdate);
    };

    return (
        <div>
            <UserAttributeFilters
                ruleType={"keyboardPromptId"}
                getFilters={userAttributeRuleFilterOptions}
                getRules={getKeyboardPromptsUserAttributeRules}
                saveRules={saveKeyboardPromptsUserAttributeRules}
                deactivateRules={deactivateKeyboardPromptsUserAttributeRules}
                activateRules={activateKeyboardPromptsUserAttributeRules}
                updateRulesAllowed={updateRulesAllowed()}
            />
        </div>
    );
};

export default KeyboardPromptsUserAttributeRules;

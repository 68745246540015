import React, { useState, useEffect } from "react";
import { Card, CardBody, Container } from "reactstrap";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
    Row,
    Col,
} from "reactstrap";
import { useParams } from 'react-router-dom';
import { error, errorDescription } from "../../../utilities/commonUtil";
import { GetAIPlacementDetails } from "../../../utilities/apiUtils/genAIDashboard";
import { showEditPlacement } from "../../../config/configGenAIDashboard";
import ResponseModal from "../ResponseModal";
import { errorModalHeading } from "../../../config/config";
import PlacementButtons from "./PlacementButtons";
const TemplateDetails = () => {
    const { id } = useParams();
    const [failureModalDisplay, setFailureModalDisplay] = useState(false);
    const [failureModalText, setFailureModalText] = useState("");
    const [data, setData] = useState({
        id: 0,
        name: "",
        deviceType: "",
        description: "",
        minAppVersion: 0,
        maxAppVersion: 0,
        createdAt: "",
        updatedAt: "",
        publishedAt: "",
        deactivatedAt: ""
    });
    useEffect(() => {
        if (id) {
            fetchPlacementDetails(id);
        }
    }, [id]);
    const fetchPlacementDetails = async (id) => {
        try {
            let response = await GetAIPlacementDetails(id)
            if (!response) {
                return;
            }
            if (response?.id) {
                setData({
                    id: response.id,
                    name: response.name,
                    deviceType: response.deviceType,
                    description: response.description,
                    minAppVersion: response.minAppVersion,
                    maxAppVersion: response.maxAppVersion,
                    createdAt: response.createdAt,
                    updatedAt: response.updatedAt,
                    publishedAt: response.publishedAt,
                    deactivatedAt: response.deactivatedAt
                })
            } else {
                showErrorModal(errorDescription(response.errorCode));
            }
        } catch (err) {
            showErrorModal(errorDescription(error.unexpectedError));
        }
    }

    let showErrorModal = (text) => {
        setFailureModalText(text);
        setFailureModalDisplay(true);
    };

    let failureModalClose = () => {
        setFailureModalDisplay(false);
    };

    return (
        <>
            <h1>Placement</h1>
            <Card>
                <CardBody>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Name:
                        </Col>
                        <Col md={6} sm={12}>{data.name ?? "N/A"}</Col>
                    </Row>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Device Type:
                        </Col>
                        <Col md={6} sm={12}>{data.deviceType ?? "N/A"}</Col>
                    </Row>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Description:
                        </Col>
                        <Col md={6} sm={12}>{data.description ?? "N/A"}</Col>
                    </Row>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Min App Version:
                        </Col>
                        <Col md={6} sm={12}>{data.minAppVersion ?? "N/A"}</Col>
                    </Row>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Max App Version:
                        </Col>
                        <Col md={6} sm={12}>{data.maxAppVersion ?? "N/A"}</Col>
                    </Row>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Created at:
                        </Col>
                        <Col md={6} sm={12}>{data.createdAt ?? "N/A"}</Col>
                    </Row>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Updated at:
                        </Col>
                        <Col md={6} sm={12}>{data.updatedAt ?? "N/A"}</Col>
                    </Row>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Published at:
                        </Col>
                        <Col md={6} sm={12}>{data.publishedAt ?? "N/A"}</Col>
                    </Row>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Deactivated at:
                        </Col>
                        <Col md={6} sm={12}>{data.deactivatedAt ?? "N/A"}</Col>
                    </Row>
                    {
                        showEditPlacement() ? (
                            <Link
                                to={{
                                    pathname: `/gen-ai/placements/${id}/edit`
                                }}
                            >
                                <center>
                                    <Button className="btn-primary">Edit</Button></center></Link>) : null
                    }
                    <ResponseModal
                        show={failureModalDisplay}
                        onHide={failureModalClose}
                        modalheading={errorModalHeading}
                        modaltext={failureModalText}
                    />
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <PlacementButtons placementId={id} />
                </CardBody>
            </Card>
        </>
    )
}
const PlacementDetails = () => {
    return (
        <Container fluid className="p-0">
            <TemplateDetails />
        </Container>
    )
}
export default PlacementDetails;
import React, { useState, useEffect } from "react";
import { Card, CardBody, Container, Label } from "reactstrap";
import { Button, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  Row,
  Col,
} from "reactstrap";
import {
  errorDescription,
  allActions,
  error,
} from "../../../utilities/commonUtil";
import ResponseModal from "../ResponseModal";
import { clientTableColumns } from "./tableColumn";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ConfirmationModal from "../ConfirmationModal";
import { fetchTaasClients, createUpdateClient, fetchTaasPartners } from "../../../utilities/apiUtils/taasDashboard";
import { clientResponses, formatTime, showActivateClientButton, showCreateClientButton, showDeactivateClientButton, showEditClientButton } from "../../../config/configTaasDashboard";
import { failureModalHeading, successModalHeading } from "../../../config";

const { SearchBar } = Search;

const ExpandableRowsTable = () => {
  const [clientData, setClientData] = useState([]);
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [action, setAction] = useState("");
  const [status, setStatus] = useState({ value: "all", label: "All" });
  const [partner, setPartner] = useState();
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [data, setData] = useState({
    clientId: '',
    name: '',
    allowedEndpoints: '',
    apiKey: '',
    contentPreferences: '',
    identifier: '',
    randomizeResults: '',
    enableCustomContentLimit: '',
    customContentLimit: '',
    enableWatermark: '',
    enablePublicStorageContent: '',
    enableContentURLRegeneration: '',
  })

  const fetchClientsData = async () => {
    let params = {
      status: status.value,
      partnerId: partner?.value ?? ""
    }
    try {
      let response = await fetchTaasClients(params)
      if (!response || response?.length === 0) {
        setClientData([]);
        return;
      }
      if (response.length > 0) {
        setClientData(response);
      } else {
        showErrorModal(errorDescription(response.errorCode));
      }
    } catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  }

  useEffect(() => {
    fetchClientsData();
    getPartners();
  }, [status, partner]);

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    await fetchClientsData();
  };

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let disableDeactivateButton = (row) => {
    if (new Date(row.deactivatedAt) > new Date() || row.deactivatedAt === null) return false;
    return true;
  };

  let disableActivateButton = (row) => {
    if (new Date(row.deactivatedAt) > new Date() || row.deactivatedAt === null) return true;
    return false;
  };

  const getPartners = async () => {
    try {
      let response = await fetchTaasPartners()
      if (response?.length > 0) {
        setPartnerOptions(response.map(d => ({
          value: d.id,
          label: d.name,
        })))
      }
      else if (response & response.errorCode) {
        showErrorModal(errorDescription(response.errorCode));
      }
    } catch (err) {
      showErrorModal({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  }

  let updateStatus = async (update) => {
    try {
      let formData = new FormData();
      formData.append("clientId", data.clientId);
      formData.append("name", data.name);
      formData.append("allowedEndpoints", data.allowedEndpoints);
      formData.append("apiKey", data.apiKey);
      formData.append("contentPreferences", data.contentPreferences);
      formData.append("identifier", data.identifier);
      formData.append("randomizeResults", data.randomizeResults);
      formData.append("enableCustomContentLimit", data.enableCustomContentLimit);
      formData.append("customContentLimit", data.customContentLimit);
      formData.append("enableWatermark", data.enableWatermark);
      formData.append("enablePublicStorageContent", data.enablePublicStorageContent);
      formData.append("enableContentURLRegeneration", data.enableContentURLRegeneration);
      formData.append("activatedAt", update === "deactivate" ? false : true);
      formData.append("deactivatedAt", update === "deactivate" ? true : false);
      let response = await createUpdateClient(formData);
      if (response.code === "clientUpdated") {
        showSuccessModal(update === "deactivate" ? clientResponses.deactivatedSuccessfully : clientResponses.activatedSuccessfully);
      } else {
        if (response.errorDescription) {
          showErrorModal(response.errorDescription)
        }
      }
    } catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  };

  let setConfirmationModalState = (event, row) => {
    setAction(event);
    setConfirmationModalIsOpen(true);
    setData({
      clientId: row.id,
      name: row.name,
      allowedEndpoints: row.allowedEndpoints?.map(obj => obj['allowedEndpoint']).join(','),
      apiKey: row.apiKey.APIKey,
      contentPreferences: row.contentPreferences,
      identifier: row.identifier,
      randomizeResults: row.randomizeResults,
      enableCustomContentLimit: row.enableCustomContentLimit,
      customContentLimit: row.customContentLimit,
      enableWatermark: row.enableWatermark,
      enablePublicStorageContent: row.enablePublicStorageContent,
      enableContentURLRegeneration: row.enableContentUrlRegeneration,
    })
  };

  let handleStatusChange = (status) => {
    setStatus({ value: status.value, label: status.label });
  };
  let handlePartnerChange = (partner) => {
    setPartner({ value: partner.value, label: partner.label });
  };

  const handleResetPartner = () => {
    setPartner("");
  };

  const ClearButton = () => {
    return (
      <Button
        className="form-control-lg mt-2"
        onClick={handleResetPartner}
      >
        Reset Partner Filter
      </Button>
    );
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <ul>
          <li>{`Api Key : ${row.apiKey?.APIKey ?? "N/A"}`}</li>
          <li>{`Partner : ${row.partner?.name ?? "N/A"}`}</li>
          <li>{`Activated At : ${formatTime(row.activatedAt)}`}</li>
          <li>{`Deactivated At : ${formatTime(row.deactivatedAt)}`}</li>
          <li>
            Allowed Endpoints :
            {
              row.allowedEndpoints?.length > 0 ? Object.values(row.allowedEndpoints).map((endPoint, index) => {
                return (<>
                  {!endPoint.allowedEndpoint ? " N/A" :
                    <Badge key={index} className="badge-secondary ml-2">
                      {endPoint.allowedEndpoint}
                    </Badge>}</>
                );
              }) : " N/A"}
          </li>
        </ul>
        {showEditClientButton() ? (
          <Link to={{
            pathname: `/taas/clients/edit/${row.id}`
          }}>
            <Button className="mx-3">
              Edit
            </Button>
          </Link>
        ) : null}
        {showDeactivateClientButton() ? (
          <Button
            className="mx-3 btn-danger"
            disabled={disableDeactivateButton(row)}
            onClick={(event) =>
              setConfirmationModalState(event.target.innerHTML, row)
            }
          >
            Deactivate
          </Button>
        ) : null}
        {
          showActivateClientButton() ? (
            <Button
              className="mx-3 btn-success"
              disabled={disableActivateButton(row)}
              onClick={(event) =>
                setConfirmationModalState(event.target.innerHTML, row)
              }
            >
              Activate
            </Button>
          ) : null
        }
      </div >
    ),
  };

  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={3} sm={12}>
            <Label>Status</Label>
            <Select
              className="react-select-container mb-4"
              classNamePrefix="react-select"
              placeholder="Select Status"
              onChange={handleStatusChange}
              options={[
                { value: "", label: "All" },
                { value: "activated", label: "Activated" },
                { value: "deactivated", label: "Deactivated" },
              ]}
              value={status}
            />
          </Col>
          <Col md={3} sm={12}>
            <Label>Partner</Label>
            <Select
              className="react-select-container mb-4"
              classNamePrefix="react-select"
              placeholder="Select Partner"
              onChange={handlePartnerChange}
              options={partnerOptions}
              value={partner}
            />
          </Col>
          <Col md={6} xs={5} className="p-0 mt-3">
            <ClearButton></ClearButton>
          </Col>
        </Row>
        <ToolkitProvider keyField="id" data={clientData} columns={clientTableColumns} search>
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} />
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                bordered={false}
                keyField="id"
                data={clientData}
                columns={clientTableColumns}
                expandRow={expandRow}
                pagination={paginationFactory({
                  sizePerPage: 10,
                  sizePerPageList: [5, 10, 25, 50],
                })}
                defaultSorted={[{
                  dataField: 'id',
                  order: 'desc'
                }]}
              />
            </div>
          )}
        </ToolkitProvider>
      </CardBody>
      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={successModalHeading}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={failureModalHeading}
        modaltext={failureModalText}
      />
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={action}
        onConfirmationTrue={() => {
          setConfirmationModalIsOpen(false);
          if (action === allActions.deactivate) updateStatus("deactivate");
          else if (action === allActions.activate) updateStatus("activate");
        }}
        onHide={() => setConfirmationModalIsOpen(false)}
      />
    </Card>
  );
};

const Clients = () => {

  return (
    <Container fluid className="p-0">
      <div className="d-flex justify-content-between">
        <h1 className="h3">All Clients</h1>
        {showCreateClientButton() ? (
          <div>
            <Link
              to={{
                pathname: `/taas/partner/create`
              }}
            >
              <Button
                style={{ fontSize: "1rem" }}
                className="mx-3 btn-success mb-3"
              >
                Create a New Partner
              </Button>
            </Link>
            <Link
              to={{
                pathname: `/taas/clients/create`
              }}
            >
              <Button
                style={{ fontSize: "1rem" }}
                className="mx-3 btn-success mb-3"
              >
                Create a New Client
              </Button>
            </Link></div>
        ) : null}
      </div>
      <ExpandableRowsTable />
    </Container>
  )
};

export default Clients;

import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import ConfirmationModal from "../ConfirmationModal";
import ResponseModal from "../ResponseModal";
import {
  deactivateReward,
  fetchRewards,
  publishReward,
  ActivateReward,
} from "../../../utilities/apiUtils/rewards";
import { tableColumns } from "./tableColumn";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import Search from "../../../components/Search";

import Loading from "../../../components/Loading";
import {
  errorDescription,
  error,
  allActions,
  redirectOnTokenExpiry,
} from "../../../utilities/commonUtil";
import { successModalHeading, errorModalHeading } from "../../../config/config";
import { statusOptions, processDateAndStatus } from "../../../config/rewards";

const Rewards = () => {
  const [loading, setLoading] = useState(true);
  const [rewards, setRewards] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [successModalText, setSuccessModalText] = useState("");
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [action, setAction] = useState("");

  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [statusFilter, setStatusFilter] = useState(""); // Set the default status filter to "ALL"
  let onTableChange = (type, newState) => {};

  const fetchRewardsData = async () => {
    try {
      let rewardsData = await fetchRewards();

      if (rewardsData?.rewards !== undefined) {
        rewardsData.rewards.forEach((item) => {
          item = processDateAndStatus(item);
        });
        // Set the rewards state with the processed rewards
        setRewards(rewardsData.rewards);
        setLoading(false);
      }
    } catch (error) {
      setFailureModalText(error.message);
      setFailureModalDisplay(true);
      setLoading(false);
    }
  };

  const handleClick = () => {
    setStatusFilter("");
    setNameFilter("");
  };

  const ClearButton = () => {
    return (
      <Button
        style={{ float: "right" }}
        className="form-control-lg"
        onClick={handleClick}
      >
        Reset Filters
      </Button>
    );
  };

  const filterRewards = () => {
    let items = rewards;

    if (statusFilter.value) {
      items = items.filter((item) => item.status === statusFilter.value);
    }

    if (nameFilter) {
      items = items.filter((item) =>
        item.name?.toLowerCase().includes(nameFilter.toLowerCase())
      );
    }

    return items;
  };

  useEffect(() => {
    fetchRewardsData();
    if (sessionStorage.getItem("rewardsFilterName"))
      setNameFilter(sessionStorage.getItem("rewardsFilterName"));
    if (sessionStorage.getItem("rewardsFilterStatus"))
      setStatusFilter({
        value: sessionStorage.getItem("rewardsFilterStatus") ?? "",
        label:
          statusOptions.find(
            (option) =>
              option.value === sessionStorage.getItem("rewardsFilterStatus")
          )?.label ?? "",
      });
  }, []);

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    setLoading(true);
    fetchRewardsData();
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  const handlePublish = async (rewardId) => {
    const response = await publishReward(rewardId);
    let text = "Reward has been Published";
  };

  const handleDeactivate = async (rewardId) => {
    try {
      const response = await deactivateReward(rewardId);
      if ((response.status = "success")) {
        let text = "Reward has been Deactivated";
        showSuccessModal(text);
      } else {
        console.error(`Failed to deactivate reward (${response.status})`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleActivate = async (rewardId) => {
    try {
      const response = await ActivateReward(rewardId);
      if ((response.status = "success")) {
        let text = "Reward has been Activated";
        showSuccessModal(text);
      } else {
        console.error(`Failed to activate reward (${response.status})`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <Link
          className="text-decoration-none"
          to={{
            pathname: `/rewards/edit/${row.id}`,
            state: {
              formType: "edit",
            },
          }}
        >
          <Button className="mx-3">Edit</Button>
        </Link>
        {row.status === "Unpublished" ? (
          <Button
            className="mx-3  btn-success"
            onClick={() => handlePublish(row.id)}
          >
            Publish
          </Button>
        ) : (
          <Button className="mx-3  btn-success" disabled>
            Publish
          </Button>
        )}
        {row.status === "Activated" ? (
          <Button
            className="mx-3  btn-danger"
            onClick={() => handleDeactivate(row.id)}
          >
            Deactivate
          </Button>
        ) : (
          <Button className="mx-3  btn-danger" disabled>
            Deactivate
          </Button>
        )}
        {row.status === "Deactivated" ? (
          <Button
            className="mx-3  btn-success"
            onClick={() => handleActivate(row.id)}
          >
            Activate
          </Button>
        ) : (
          <Button className="mx-3  btn-success" disabled>
            Activate
          </Button>
        )}
        <ConfirmationModal
          show={confirmationModalIsOpen}
          modaltext={action}
          onConfirmationTrue={() => {
            setConfirmationModalIsOpen(false);
            if (action === allActions.deactivate) handleDeactivate(row.id);
            else if (action === allActions.activate) handleActivate(row.id);
            else if (action === allActions.publish) {
              handlePublish(row.id);
            }
          }}
          onHide={() => setConfirmationModalIsOpen(false)}
        />
        <ResponseModal
          show={successModalDisplay}
          onHide={successModalClose}
          modalheading={successModalHeading}
          modaltext={successModalText}
        />
        <ResponseModal
          show={failureModalDisplay}
          onHide={failureModalClose}
          modalheading={errorModalHeading}
          modaltext={failureModalText}
        />
      </div>
    ),
  };

  return (
    <Container fluid className="p-0">
      <Row>
        <h1 className="mb-4 mt-2 w-50 pl-3">Rewards</h1>
        <Link
          className="d-block ml-auto mb-4 pr-3 text-decoration-none"
          to="/rewards/add"
        >
          <Button color="success" className="ml-auto d-flex btn-success">
            <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
              Add Reward
            </p>
          </Button>
        </Link>
      </Row>
      <div>
        <Card style={{ overflow: "hidden", paddingBottom: "100px" }}>
          <CardBody>
            <Row className="justify-content-md-center mx-auto mb-4">
              <Col md={6} sm={12} className="mt-3">
                <Select
                  className="react-select-container"
                  id="statusSelect"
                  classNamePrefix="react-select"
                  name="status"
                  placeholder="Select Status"
                  value={statusFilter}
                  onChange={(event) => {
                    setStatusFilter(event);
                    sessionStorage.setItem("rewardsFilterStatus", event.value);
                  }}
                  options={statusOptions}
                />
              </Col>
              <Col md={6} xs={5} className="p-0 mt-3">
                <ClearButton>Reset Filters</ClearButton>
              </Col>
            </Row>
            <Row className="justify-content-md-center mx-auto">
              <Col md={12} xs={12}>
                <Search
                  placeholder="Search Name"
                  value={nameFilter}
                  onChange={(e) => {
                    setNameFilter(e.target.value);
                    sessionStorage.setItem("rewardsFilterName", e.target.value);
                  }}
                ></Search>
              </Col>
            </Row>
            {loading ? (
              <Loading />
            ) : (
              <CardBody>
                <BootstrapTable
                  bootstrap4={true}
                  wrapperClasses="table-responsive"
                  hover={true}
                  bordered={false}
                  keyField="id"
                  data={filterRewards()}
                  columns={tableColumns}
                  expandRow={expandRow}
                  filter={filterFactory()}
                  pagination={paginationFactory({
                    sizePerPage: 10,
                    sizePerPageList: [5, 10, 25, 50],
                  })}
                  defaultSorted={[
                    { dataField: "listing_priority", order: "asc" },
                  ]}
                  onTableChange={onTableChange}
                  remote={{ filter: true }}
                  noDataIndication="No results found"
                />
              </CardBody>
            )}
          </CardBody>
        </Card>
      </div>
      {failureModalDisplay && (
        <div className="modal">
          <div className="modal-content">
            <h2>Error</h2>
            <p>{failureModalText}</p>
            <button onClick={() => setFailureModalDisplay(false)}>Close</button>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Rewards;

import React, { useState, useEffect } from "react";
import { Card, CardBody, Container, Row, Col, Badge } from "reactstrap";
import { Button } from "react-bootstrap";
import { placementsTableColumns } from "./tableColumn";
import Select from "react-select";
import ConfirmationModal from "../ConfirmationModal";
import ResponseModal from "../ResponseModal";
import {
  successModalHeading,
  errorModalHeading,
} from "../../../config/config";
import filterFactory from "react-bootstrap-table2-filter";
import Loading from "../../../components/Loading";
import paginationFactory from "react-bootstrap-table2-paginator";
import Search from "../../../components/Search";
import BootstrapTable from "react-bootstrap-table-next";
import {
  errorDescription,
  error,
  redirectOnTokenExpiry,
  replaceUnderscoreAndCapitalize,
} from "../../../utilities/commonUtil";
import { activatePlacement, createPlacement, deactivatePlacement, deletePlacement, fetchPlacements, updatePlacement } from "../../../utilities/apiUtils/configAPI";
import { processDateAndStatus, statusOptions, clientOptions, allActions, placementsResponses, isConfigAPIEditPermission, isConfigAPIActivatePermission, isConfigAPIDeactivatePermission, isConfigAPIDeletePermission, isConfigAPICreatePermission, adsMixingStrategyOptions } from "../../../config/configConfigAPIDashboard";
import PlacementModal from "./modals/PlacementModal";
import { fetchPlacementLocations } from "../../../utilities/apiUtils/placementLocations";
import { fetchSmartSuggestionCategories } from "../../../utilities/apiUtils/smartSuggestions";
import { fetchClusters } from "../../../utilities/apiUtils/configAPI";

const formDataInitialState = {
  id: "",
  location: "",
  clientId: "",
  description: "",
  creativeTypeId: "",
  minAppVersion: "",
  maxAppVersion: "",
  excludeAppVersionList: [],
  adsMixingStrategy: "",
  isSupportedGaid: "",
  isSupportedStoryAds: "",
  categoriesName: "",
  clustersName: "",
}

const ManagePlacements = () => {
  const [loading, setLoading] = useState(true);
  const [statusFilter, setStatusFilter] = useState("");
  const [formData, setFormData] = useState(formDataInitialState)
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [placements, setPlacements] = useState([]);
  const [placementsAll, setPlacementsAll] = useState([])
  const [nameFilter, setNameFilter] = useState("");
  const [idFilter, setIdFilter] = useState("");
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [action, setAction] = useState("");
  const [id, setId] = useState(null);
  const [successModalText, setSuccessModalText] = useState("");
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [changeType, setChangeType] = useState("Add")
  const [isPlacementModalVisible, setIsPlacementModalVisible] = useState(false)
  const [locationOptions, setLocationOptions] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const [clusterOptions, setClusterOptions] = useState([])

  const getCategoryOptions = async () => {
    try {
      let response = await fetchSmartSuggestionCategories()
      if (response?.categories?.length > 0) {
        setCategoryOptions(response.categories.map(category => ({
          value: category.id,
          label: replaceUnderscoreAndCapitalize(category.name),
          target: {
            name: "categoryId",
            value: {
              value: category.id,
              label: replaceUnderscoreAndCapitalize(category.name)
            }
          }
        })))
      }
    } catch (err) {
      console.log(err);
    }
  }

  const getclusterOptions = async () => {
    try {
      let response = await fetchClusters()
      if (response?.clusters?.length > 0) {
        setClusterOptions(response.clusters.map(cluster => ({
          value: cluster.id,
          label: replaceUnderscoreAndCapitalize(cluster.name),
          target: {
            name: "clusterId",
            value: {
              value: cluster.id,
              label: replaceUnderscoreAndCapitalize(cluster.name)
            }
          }
        })))
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getCategoryOptions();
    getclusterOptions();
  }, [])

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    setLoading(true)
    fetchPlacementsTableData()
  };

  let showPlacementModal = (type) => {
    setChangeType(type)
    setIsPlacementModalVisible(true)
  }

  let hidePlacementModal = () => {
    setIsPlacementModalVisible(false)
    setFormData(formDataInitialState)
  }

  let onTableChange = (type, newState) => { };

  let disableDeactivateButton = (row) => {
    return row.status === "Deactivated";
  };

  let disableActivateButton = (row) => {
    return row.status === "Activated";
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  const handleClick = () => {
    setStatusFilter("");
    setNameFilter("");
    setIdFilter("");
    sessionStorage.removeItem("placementsFilterStatus");
    sessionStorage.removeItem("placementsFilterName")
    sessionStorage.removeItem("placementsFilterId")
  };

  const ClearButton = () => {
    return (
      <Button
        style={{ float: "right" }}
        className="form-control-lg"
        onClick={handleClick}
      >
        Reset Filters
      </Button>
    );
  };

  let setConfirmationModalState = (id, event) => {
    setAction(event);
    setConfirmationModalIsOpen(true);
    setId(id);
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <ul>
          <Row className="justify-content-md-center mx-auto mb-4">
            <Col md={6} sm={12}>
              <li>Placement ID : {row.id || "null"}</li>
              <li>Status :  {row.status === "Activated" ? (
                <Badge className="badge-success">{row.status}</Badge>
              ) : row.status === "Deactivated" ? (
                <Badge className="badge-danger">{row.status}</Badge>
              ) : (
                <Badge className="badge-warning">{row.status}</Badge>
              )}</li>
              <li>Client ID : {row.clientId || "null"}</li>
              <li>Ads Mixing Strategy : {adsMixingStrategyOptions.find(option => option.value === row.adsMixingStrategy)?.label ?? (row.adsMixingStrategy || "null")}</li>
              <li>Exclude Version List : {row.excludeAppVersionList || "null"}</li>
              <li>Category: {row.categories.join(", ") || "null"}</li>
              <li>Clusters: {row.clusters.join(", ") || "null"}</li>
            </Col>
            <Col md={6} sm={12}>
              <li>Is Supported Gaid : {row.isSupportedGaid ? "Yes" : "No"}</li>
              <li>Is Supported Story Ads : {row.isSupportedStoryAds ? "Yes" : "No"}</li>
              <li>Min App Version : {row.minAppVersion || "null"}</li>
              <li>Max App Version : {row.maxAppVersion || "null"}</li>
            </Col>
          </Row>
        </ul>
        {isConfigAPIEditPermission() && (
          <Button
            className="mx-3"
            onClick={() => {
              showPlacementModal("Edit")
              setFormData((prevState) => ({
                ...prevState,
                id: row.id,
                location: row.location,
                clientId: row.clientId,
                description: row.description,
                creativeTypeId: row.creativeTypeId,
                minAppVersion: row.minAppVersion,
                maxAppVersion: row.maxAppVersion,
                excludeAppVersionList: row.excludeAppVersionList,
                adsMixingStrategy: row.adsMixingStrategy,
                isSupportedGaid: row.isSupportedGaid,
                isSupportedStoryAds: row.isSupportedStoryAds,
                categoriesName: row.categoriesName,
                clustersName: row.clustersName
              }))
            }}
          >
            Edit
          </Button>
        )}

        {isConfigAPIActivatePermission() && (
          <Button
            className="mx-3 btn-success"
            disabled={disableActivateButton(row)}
            onClick={(event) =>
              setConfirmationModalState(row.id, event.target.innerHTML)
            }
          >
            Activate
          </Button>
        )}

        {isConfigAPIDeactivatePermission() && (
          <Button
            className="mx-3 btn-secondary"
            disabled={disableDeactivateButton(row)}
            onClick={(event) =>
              setConfirmationModalState(row.id, event.target.innerHTML)
            }
          >
            Deactivate
          </Button>
        )}

        {isConfigAPIDeletePermission() && (
          <Button
            className="mx-3 btn-danger"
            onClick={(event) =>
              setConfirmationModalState(row.id, event.target.innerHTML)
            }
          >
            Delete
          </Button>
        )}

        {!(isConfigAPIEditPermission() || isConfigAPIActivatePermission() || isConfigAPIDeactivatePermission() || isConfigAPIDeletePermission()) && (
          <center className="text-info" style={{ fontSize: 13 }}>You do not have permissions to perform any action.</center>
        )}
      </div>
    ),
  };

  let activateRecord = async (id) => {
    try {
      let response = await activatePlacement(id);
      if (response.status === "success") {
        let text = "Placement has been activated";
        showSuccessModal(text);
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  };

  let deactivateRecord = async (id) => {
    try {
      let response = await deactivatePlacement(id);
      if (response.status === "success") {
        let text = "Placement has been deactivated";
        showSuccessModal(text);
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  };

  let deleteRecord = async (id) => {
    try {
      let response = await deletePlacement(id);
      if (response.status === "success") {
        let text = "Placement has been deleted";
        showSuccessModal(text);
      } else {
        let text
        if (response?.errorDescription)
          text = response?.errorDescription?.split("):")[1];
        else
          text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  };

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let fetchPlacementsTableData = async () => {
    try {
      let response = await fetchPlacements();
      if (!response || response?.length === 0) {
        setPlacementsAll([])
        setLoading(false)
      } else if (response?.length > 0) {
        response.forEach((item) => {
          item.creativeTypeName = item?.creativeType?.name
          item.clientName = clientOptions.find(option => option.value === item?.clientId)?.label ?? item?.clientId
          item = processDateAndStatus(item);
        });
        setPlacementsAll(response);
        setLoading(false);
      } else {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken ||
          response.errorCode === error.unauthorized
        )
          redirectOnTokenExpiry();
        let text = errorDescription(error.unexpectedError);
        setLoading(false);
        showErrorModal(text);
      }
    } catch (err) {
      setLoading(false);
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  };

  const getLocationsOptions = async () => {
    try {
      let response = await fetchPlacementLocations();
      if (response?.length > 0) {
        setLocationOptions(response.map(location => ({
          value: location.value,
          label: location.name,
          target: {
            name: "location",
            value: {
              value: location.value,
              label: location.name
            }
          }
        })))
      } else {
        setLocationOptions([])
      }
    } catch (err) {
      console.log(err);
    }
  };

  const filterPlacements = () => {
    let items = placements;
    if (statusFilter.value)
      items = items.filter((item) => item.status === statusFilter.value);

    if (nameFilter)
      items = items.filter((item) =>
        item.description?.toLowerCase().includes(nameFilter.toLowerCase())
      );

    if (idFilter)
      items = items.filter((item) =>
        item.id?.toLowerCase().includes(idFilter.toLowerCase())
      );

    return items;
  };

  useEffect(() => {
    fetchPlacementsTableData();
    getLocationsOptions();

    // Fill stored filter values
    if (sessionStorage.getItem("placementsFilterName"))
      setNameFilter(sessionStorage.getItem("placementsFilterName"))
    if (sessionStorage.getItem("placementsFilterId"))
      setIdFilter(sessionStorage.getItem("placementsFilterId"))
    if (sessionStorage.getItem("placementsFilterStatus"))
      setStatusFilter({
        value: sessionStorage.getItem("placementsFilterStatus") ?? "",
        label: statusOptions.find(option => option.value === sessionStorage.getItem("placementsFilterStatus"))?.label ?? "",
      })
  }, []);

  useEffect(() => {
    if (locationOptions?.length > 0 && categoryOptions?.length > 0 && clusterOptions?.length > 0 && placementsAll?.length > 0) {
      setPlacements(placementsAll.map(placement => ({
        ...placement,
        locationName: locationOptions.find(option => option.value === placement.location)?.label ?? placement.location,
        categories: placement.categoriesName?.length > 0
          ? placement.categoriesName?.split(",")?.map(cat => categoryOptions.find(option => option.value === Number(cat))?.label ?? cat)
          : [],
        clusters: placement.clustersName?.length > 0
          ? placement.clustersName?.split(",")?.map(cluster => clusterOptions.find(option => option.value === Number(cluster))?.label ?? cluster)
          : []
      })))
    }
  }, [locationOptions, categoryOptions, clusterOptions, placementsAll])

  const handleSubmit = async (data) => {
    const { id, description, clientId, creativeTypeId, location, minAppVersion, maxAppVersion, excludeAppVersionList, adsMixingStrategy, isSupportedGaid, isSupportedStoryAds, categoriesName, clustersName } = data
    const payload = {
      description,
      clientId,
      creativeTypeId,
      location,
      minAppVersion,
      maxAppVersion,
      excludeAppVersionList,
      adsMixingStrategy,
      isSupportedGaid,
      isSupportedStoryAds,
      categoriesName,
      clustersName
    }
    try {
      let response
      if (changeType === "Add")
        response = await createPlacement(payload)
      else
        response = await updatePlacement(id, payload)
      if (response?.status === "success") {
        setSuccessModalText(changeType === "Add" ? placementsResponses.addedSuccessfully : placementsResponses.updatedSuccessfully);
        setSuccessModalDisplay(true);
      } else {
        let text
        if (response?.errorDescription)
          text = response?.errorDescription?.split("):")[1];
        else
          text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      }
    } catch (err) {
      let text = errorDescription(error.unexpectedError);
      showErrorModal(text);
    } finally {
      hidePlacementModal()
    }
  }

  return (
    <Container fluid className="p-0">
      <Row>
        <h1 className="mb-4 mt-2 w-50 pl-3">Placements</h1>

        {isConfigAPICreatePermission() && (
          <div className="d-flex align-items-center ml-auto">
            <Button color="success" className="d-flex btn-success mb-4 mr-3">
              <p className="mb-1 mt-1" style={{ fontSize: "1rem" }} onClick={() => showPlacementModal("Add")}>
                Add New Placement
              </p>
            </Button>
          </div>
        )}
      </Row>
      <div>
        <Card style={{ overflow: "hidden", paddingBottom: "100px" }}>
          <div container-fullwidth="true" style={{ margin: "2%" }}>
            <Row className="justify-content-md-center mx-auto mb-4">
              <Col md={6} sm={12} className="mt-3">
                <Select
                  className="react-select-container"
                  id="statusSelect"
                  classNamePrefix="react-select"
                  name="status"
                  placeholder="Select Status"
                  value={statusFilter}
                  onChange={(event) => {
                    setStatusFilter(event);
                    sessionStorage.setItem("placementsFilterStatus", event.value);
                  }}
                  options={statusOptions}
                />
              </Col>
              <Col md={6} xs={5} className="p-0 mt-3">
                <ClearButton>Reset Filters</ClearButton>
              </Col>
            </Row>

            <Row className="justify-content-md-center mx-auto">
              <Col md={6} xs={12}>
                <Search
                  placeholder="Search Placement ID"
                  value={idFilter}
                  onChange={(e) => {
                    setIdFilter(e.target.value)
                    sessionStorage.setItem("placementsFilterId", e.target.value)
                  }}
                ></Search>
              </Col>
              <Col md={6} xs={12}>
                <Search
                  placeholder="Search Placement Name"
                  value={nameFilter}
                  onChange={(e) => {
                    setNameFilter(e.target.value)
                    sessionStorage.setItem("placementsFilterName", e.target.value)
                  }}
                ></Search>
              </Col>
            </Row>

            <Row className="justify-content-md-center mx-auto">
              <Col md={12} sm={12} className="mt-3">
                <Badge
                  className="badge-success"
                  style={{
                    width: "1rem",
                    height: "1rem",
                    verticalAlign: "middle",
                  }}
                >
                  {" "}
                </Badge>{" "}
                : Status Activated
              </Col>
              <Col md={12} sm={12} className="mt-3">
                <Badge
                  className="badge-danger"
                  style={{
                    width: "1rem",
                    height: "1rem",
                    verticalAlign: "middle",
                  }}
                >
                  {" "}
                </Badge>{" "}
                : Status Deactivated
              </Col>
            </Row>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <CardBody>
              <BootstrapTable
                bootstrap4={true}
                wrapperClasses="table-responsive"
                hover={true}
                bordered={false}
                keyField="id"
                data={filterPlacements()}
                columns={placementsTableColumns}
                expandRow={expandRow}
                filter={filterFactory()}
                pagination={paginationFactory({
                  sizePerPage: 10,
                  sizePerPageList: [5, 10, 25, 50],
                })}
                defaultSorted={[{
                  dataField: 'description',
                  order: 'asc'
                }]}
                onTableChange={onTableChange}
                remote={{ filter: true }}
                noDataIndication="No results found"
              />
            </CardBody>
          )}
        </Card>
      </div>
      <PlacementModal
        show={isPlacementModalVisible}
        type={changeType}
        formData={formData}
        setFormData={setFormData}
        onSubmit={handleSubmit}
        onHide={hidePlacementModal}
      />
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={action}
        onConfirmationTrue={() => {
          setConfirmationModalIsOpen(false);
          if (action === allActions.activate) activateRecord(id)
          else if (action === allActions.deactivate) deactivateRecord(id)
          else if (action === allActions.delete) deleteRecord(id)
        }}
        onHide={() => setConfirmationModalIsOpen(false)}
      />
      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={successModalHeading}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={errorModalHeading}
        modaltext={failureModalText}
      />
    </Container>
  );
};

export default ManagePlacements;
import React, { Fragment, useState, useEffect } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import {
  Badge,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
} from "reactstrap";
import {
  getKeyboardThemeCategoryDetail,
  getKeyboardTheme,
} from "../../../../utilities/apiUtils/themes";
import Loading from "../../../../components/Loading";
import { getStatus, getStatusColor } from "../../../../utilities/commonUtil";

const ThemeDetailBoard = (props) => {
  const [theme, setTheme] = useState({});
  const [categoryName, setCategoryName] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getTheme = async () => {
      let id = props.id;
      let response = await getKeyboardTheme(id);
      if (response.theme) {
        setTheme(response.theme);
        let res = await getKeyboardThemeCategoryDetail(
          response.theme.categoryID
        );
        setCategoryName(res.name);
        setLoading(false);
      }
    };

    getTheme();
  }, []);

  return (
    <Fragment>
      {loading ? (
        <Loading />
      ) : (
        <Fragment>
          <Card>
            <React.Fragment>
              {theme.previewImage ? (
                <CardImg src={theme.previewImage} alt="Card image" />
              ) : (
                <div className="theme-preview-image bg-dark"></div>
              )}
            </React.Fragment>
            <CardBody>
              <CardTitle tag="h3">
                {theme.themeName}
                <Badge
                  className="float-right"
                  color={getStatusColor(
                    getStatus(theme.publishedAt, theme.deactivatedAt)
                  )}
                >
                  {getStatus(theme.publishedAt, theme.deactivatedAt)}
                </Badge>
              </CardTitle>
              ID : {theme.ID}
              <br />
              Theme Category: {categoryName}
              <br />
              Theme type: {theme.type}
              <br />
              Priority: {theme.priority}
              <br />
              Min App Version: {theme.min_app_version}
              <br />
              Keyboard Overlay Opacity: {theme.keyboardOverlayOpacity}
              <br />
              Is light theme:
              {String(theme.isLightTheme)
                ? String(theme.isLightTheme)
                : String(null)}
              <br />
              Created At : {theme.createdAt}
              <br />
              Published At : {theme.publishedAt}
              <br />
              Deactivated At : {theme.deactivatedAt}
              <br />
            </CardBody>
          </Card>

          <Row className="d-flex justify-content-between my-5">
            {theme.soundEffects ? (
              <Col lg={5}>
                <h3>Sound Effects :</h3>

                {theme.soundEffects.map((effect, index) => {
                  return (
                    <div className="my-5" key={index}>
                      <h5>{effect.keys[0]}</h5>
                      <AudioPlayer src={effect.url} />
                    </div>
                  );
                })}
              </Col>
            ) : null}
            {theme.animationEffects ? (
              <Col lg={5}>
                <h3>Animation Effects :</h3>
                {theme.animationEffects.map((effect, index) => {
                  return (
                    <div className="my-5" key={index}>
                      <h5>{effect.keys[0]}</h5>
                      <CardImg className="theme-animation" src={effect.url} />
                    </div>
                  );
                })}
              </Col>
            ) : null}
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ThemeDetailBoard;

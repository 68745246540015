import React from "react";
import moment from "moment";
import { parseDateInMoment } from "../../../config/configSmartSuggestions";
import { convertToISTDateFormat } from "../../../utilities/commonUtil";

const titleFormatter = (cell, row) => {
  return (
    <div className="d-flex align-items-center">
      <span className="badge-secondary px-2 font-weight-bolder mr-2" style={{ fontSize: 13 }}>
        {row.storyAdId ? "S" : ""}
      </span>
      <img src={row.iconURL} alt={"icon"} style={{ width: "1.5rem", marginRight: 6, objectFit: "contain" }} />
      <span className="one-lines-ellipsis">{cell ?? "-"}</span>
    </div>
  )
}

const dateFormatter = (cell, row) => {
  return (
    <div>
      {convertToISTDateFormat(cell) ?? "-"}
    </div>
  )
}

const partnerFormatter = (cell, row) => {
  return (
    <div>
      {cell ?? "-"}
    </div>
  )
}

const statusFormatter = (cell, row) => {
  const diffEnd = parseDateInMoment(row.endAt).startOf('day').diff(moment().startOf('day'), 'days');
  const diffStart = parseDateInMoment(row.startAt).startOf('day').diff(moment().startOf('day'), 'days');

  let statusText = "";
  let statusTextClass = "";

  if (row.status === "Deactivated") {
    statusText = "Deactivated";
    statusTextClass = "badge-danger";
  } else if (diffEnd < 0) {
    statusText = "Expired";
    statusTextClass = "badge-danger";
  } else if (diffStart > 0) {
    statusText = "Scheduled";
    statusTextClass = "badge-warning";
  } else if (row.status === "Unpublished") {
    statusText = "Unpublished";
    statusTextClass = "badge-warning";
  } else {
    statusText = "Activated";
    statusTextClass = "badge-success";
  }

  return (
    <div className={`d-flex align-items-center`}>
      <span className={`${statusTextClass} badge badge-secondary mr-2`} style={{ width: "1rem", height: "1rem", verticalAlign: "middle" }}>{" "} </span>  {statusText}
    </div>
  )
}

const targetingRulesFormatter = (cell, row) => {
  return (
    <div className="d-flex align-items-center">
      {cell
        ? <span className="badge-success badge badge-secondary" style={{ width: "1rem", height: "1rem", verticalAlign: "middle" }}> </span>
        : <span className="badge-danger badge badge-secondary" style={{ width: "1rem", height: "1rem", verticalAlign: "middle" }}> </span>
      }
      <div className="ml-1">{cell ? "Configured" : "Missing"}</div>
    </div>
  )
}

const brandStatusFormatter = (cell, row) => {
  const diffEnd = parseDateInMoment(row.endAt).startOf('day').diff(moment().startOf('day'), 'days');
  const diffStart = parseDateInMoment(row.startAt).startOf('day').diff(moment().startOf('day'), 'days');
  let timelineDays = row.status === "Deactivated" ? "" : Math.abs(diffStart <= 0 ? diffEnd : diffStart)
  let statusText = row.status === "Deactivated" ? "-" : diffEnd < 0 ? "days ago" : diffStart > 0 ? "days to go" : "days remaining";

  return (
    <>
      <div className="font-weight-bolder" style={{ color: "grey" }}>{timelineDays} {statusText}</div>
    </>
  )
}

const tableColumns = [
  {
    dataField: "id",
    text: "#",
    sort: true,
    headerAttrs: { width: 60 },
    searchable: true,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "title",
    text: "Name",
    sort: true,
    headerAttrs: { width: 250 },
    searchable: true,
    formatter: (cell, row) => titleFormatter(cell, row),
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
      width: 250,
      maxWidth: 250
    },
  },
  {
    dataField: "isTargetingSet",
    text: "Targeting Rules",
    sort: true,
    searchable: true,
    formatter: (cell, row) => targetingRulesFormatter(cell, row),
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "brandCampaignName",
    text: "Campaign",
    sort: true,
    searchable: true,
    formatter: (cell, row) => partnerFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "partner",
    text: "Partner",
    sort: true,
    searchable: true,
    formatter: (cell, row) => partnerFormatter(cell, row),
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "startAt",
    text: "Start Date",
    sort: true,
    searchable: false,
    formatter: (cell, row) => dateFormatter(cell, row),
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "endAt",
    text: "End Date",
    sort: true,
    searchable: false,
    formatter: (cell, row) => dateFormatter(cell, row),
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    searchable: true,
    formatter: (cell, row) => statusFormatter(cell, row),
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "timeline",
    text: "Timeline",
    sort: true,
    searchable: false,
    formatter: (cell, row) => brandStatusFormatter(cell, row),
    style: {
      cursor: "pointer",
    },
  },
];


const providerTableColumns = [
  {
    dataField: "provider",
    text: "Provider",
    sort: true,
    searchable: true,
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "total",
    text: "Total",
    sort: true,
    searchable: true,
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "cpi",
    text: "CPI",
    sort: true,
    searchable: true,
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "cpc",
    text: "CPC",
    sort: true,
    searchable: true,
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "cpm",
    text: "CPM",
    sort: true,
    searchable: true,
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
]

export { tableColumns, providerTableColumns };

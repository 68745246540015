import React, { Component } from "react";
import Select from "react-select";
import {
  Card,
  CardImg,
  Row,
  Col,
  Container,
  CardBody,
  Spinner,
  Input,
  CardTitle,
  Badge
} from "reactstrap";
import {
  errorDescription,
  error,
  redirectOnTokenExpiry,
  redirectTo,
  permissions,
  getStatusColor,
  getStatus,
} from "../../../utilities/commonUtil";
import Search from "../../../components/Search";
import ResponseModal from "../ResponseModal";
import { Config } from "../../../config/UserConfig";
import InfiniteScroll from "react-infinite-scroll-component";
import { fetchMovieGifs, updateMovieGifsStatus } from "../../../utilities/apiUtils/movieGifs";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import UncontrolledButtonDropdown from "reactstrap/lib/UncontrolledButtonDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import { capitalize } from "@material-ui/core";
import { movieGifResponses } from "../../../config/movieGifs";
import Loading from "../../../components/Loading";
import ConfirmationModal from "../ConfirmationModal";

class MovieGifs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      searchString: "",
      failureModalText: "",
      failureModalDisplay: false,
      successModalText: "",
      successModalDisplay: false,
      confirmationModalIsOpen: false,
      action:"",
      page: 0,
      limit: 20,
      gifs: [],
      hasMoreGifs: true,
      gifPacks: [],
      gifPackId: "",
      fetchFailed: false,
      pricing: "",
      status: "",
      sortBy: Config.UPDATED_AT_DESCENDING,
      searchBy: Config.SEARCH_BY_DEFAULT,
      isChecked: false,
      selectedGifs: [],
      allowEdit: false
    };
  }

  componentDidMount() {
    this.setPermission();
    this.getMovieGifs();
  }
  setPermission = () => {
    const allowedPermissions = retrieveFromLocalStorage("userPermissions");
    this.setState({ allowEdit: allowedPermissions.includes(permissions.movieGifsUpdate) })
  }

  getMovieGifs = async () => {
    console.log("IN get  Movie gifs")
    // Query parameter.
    let params = {
      searchString: this.state.searchBy === "tag" ? this.state.searchString : "",
      id: this.state.searchBy === "id" ? this.state.searchString : "",
      sortBy: this.state.sortBy,
      page: this.state.page,
      limit: this.state.limit,
      status: this.state.status,
    };
    // API called with given query parameters.
    await fetchMovieGifs(params)
      .then((response) => {
        if (response && response.length !== 0) {
          let filteredGifs = response.map((gif) => {
            let newgif = {
              ...gif,
              isChecked: false
            }
            return newgif
          })
          const newGifs = this.state.page === 0 ? filteredGifs : [...this.state.gifs, ...filteredGifs];
          this.setState({ gifs: newGifs });
          if (filteredGifs.length < 20)
            this.setState({ hasMoreGifs: false });
        } else this.setState({ gifs: [], hasMoreGifs: false });
        this.setState({ loading: false })
      })
      .catch((err) => {
        this.setState({
          loading: false,
          fetchFailed: true,
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      });
  };


  handleChange = (event) => {
    event.preventDefault();
    let value = event.target.value;
    this.setState({ searchString: value });
    if (!event.target.value.length) {
      this.search();
    }
  };

  onKeyDown = (event) => {
    if (event.keyCode === Config.KEYPRESS_DOWN) {
      this.search();
    }
  };

  search = (searchBy) => {
    this.setState(
      { loading: true, page: 0, hasMoreGifs: true, searchBy: searchBy || this.state.searchBy },
      async () => {
        this.getMovieGifs();
      });
  };

  fetchMoreData = () => {
    this.setState(
      { page: this.state.page + 1, hasMoreGifs: true },
      async () => {
        // Query parameters
        let params = {
          searchString: this.state.searchBy === "tag" ? this.state.searchString : "",
          id: this.state.searchBy === "id" ? this.state.searchString : "",
          pricing: this.state.pricing,
          sortBy: this.state.sortBy,
          page: this.state.page,
          limit: this.state.limit,
          status: this.state.status
        };
        // API called with given query parameters
        let res = await fetchMovieGifs(params);
        if (res && res.length > 0) {
          this.setState({
            gifs: this.state.gifs.concat(res),
            hasMoreGifs: true,
          });
        } else {
          this.setState({ hasMoreGifs: false });
        }
      }
    );
  };

  failureModalClose = () => {
    this.setState({ failureModalDisplay: false });
  };

  successModalClose = () => {
    this.setState({ successModalDisplay: false })
  }

  handleStatusChange = (selectedOption) => {
    this.setState({ status: selectedOption.value }, () => this.search());
  };


  handleCheckboxChange = (event, gif) => {
    if (event.target.checked) {
      this.setState({
        selectedGifs: [...this.state.selectedGifs, gif.id],
        gifs: this.state.gifs.map((gifObj) => {
          if (gifObj.id == gif.id) {
            gifObj.isChecked = true;
          }
          return gifObj
        })
      });
    } else {
      this.setState({
        selectedGifs: this.state.selectedGifs.filter(
          (id) => id !== gif.id
        ),
        gifs: this.state.gifs.map((gifObj) => {
          if (gifObj.id == gif.id) {
            gifObj.isChecked = false;
          }
          return gifObj
        })
      });
    }
  }

  handleOrderChange = (sortBy) => {
    this.setState({ sortBy: sortBy.value }, () => this.search());
  };

  handleBulkOperation = async (operation) => {
    // to activate or deactivate gifs in bulk
    let params = {
      operation: operation
    }
    this.setState({ loading: true })
    await updateMovieGifsStatus(params, this.state.selectedGifs).then((response) => {
      if (response.status == "success") {
        const message = operation == "activate" ? movieGifResponses.activatedSuccessfully : movieGifResponses.deactivatedSuccessfully
        this.setState({
          loading: false,
          successModalDisplay: true,
          successModalText: message,
        })
      }
      else {
        this.setState({
          loading: false,
          failureModalDisplay: true,
          failureModalText: response.message
        })
      }

      this.setState({
        gifs: this.state.gifs.map((gifObj) => {
          for (let i = 0; i < this.state.selectedGifs.length; i++) {
            if (this.state.selectedGifs[i] == gifObj.id) {
              gifObj.isChecked = false
            }
          }
          return gifObj
        }),
        selectedGifs: []
      });
      this.search()
    })


  }

  render() {
    return (
      <Container fluid className="p-0">
        <h1 className="heading">Movie Gifs</h1>
        <Row className="mt-4">

          <Col lg={6}>
            <Search
              value={this.state.searchString}
              onChange={this.handleChange}
              onKeyDown={this.onKeyDown}
              placeholder={"Search Movie Gifs"}
            ></Search>
          </Col>
          <Col md={3} sm={12}>
            <UncontrolledButtonDropdown className="mr-1 mb-1">
              <DropdownToggle caret color="success">
                {"Search By " + capitalize(this.state.searchBy) + " "}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => { this.search("id") }}>ID</DropdownItem>
                <DropdownItem onClick={() => { this.search("tag") }}>Tag</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </Col>
          <Col>
            <UncontrolledButtonDropdown className="my-auto float-right">
              {this.state.allowEdit && <DropdownToggle
                disabled={this.state.selectedGifs.length == 0}
                caret color="secondary">
                {"Bulk Operations"}
              </DropdownToggle>}
              <DropdownMenu>
                <DropdownItem
                  onClick={() => { this.setState({confirmationModalIsOpen : true , action : "activate"}) }
                  }>Activate</DropdownItem>
                <DropdownItem
                  onClick={() => {
                    this.setState({confirmationModalIsOpen : true , action : "deactivate"})
                  }
                  }>Deactivate</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </Col>
        </Row>
        <Row>
          <Col md={3} sm={12}>
            <Select
              className="react-select-container mb-4"
              classNamePrefix="react-select"
              placeholder="Select Status"
              onChange={this.handleStatusChange}
              options={[
                { value: "All", label: "All" },
                { value: "Published", label: "Active" },
                { value: "Deactivated", label: "Deactivated" },
                { value: "Unpublished", label: "Unpublished" },
              ]}
            />
          </Col>
          <Col md={3} sm={12}>
            <Select
              className="react-select-container mb-4"
              classNamePrefix="react-select"
              placeholder="Sort By"
              onChange={this.handleOrderChange}
              options={[
                { value: Config.UPDATED_AT_DESCENDING, label: "Updated At \u2193" },
                { value: Config.UPDATED_AT_ASCENDING, label: "Updated At \u2191" },
              ]}
            />
          </Col>
        </Row>
        {/* Component to displays all the Gifs */}
        {this.state.loading && !this.state.failureModalDisplay ? (<Loading />) : <> <InfiniteScroll
          style={{ overflow: "auto !important" }}
          className="infinite-scroll"
          dataLength={this.state.gifs.length}
          next={this.fetchMoreData}
          hasMore={this.state.hasMoreGifs}
          loader={
            this.state.fetchFailed ? (
              false
            ) : (
              <Spinner color="info" className="d-flex mx-auto" />
            )
          }
        >
          <Row className="mt-4 mx-0">
            {this.state.gifs.length === 0 ? (
              <Container className="mt-5">
                <h3 className="text-center">No Gif available.</h3>
                <h4 className="text-center">
                  Enter a valid search tag to view gifs.
                </h4>
              </Container>
            ) : (
              this.state.gifs.map((gif, index) => {
                let status = getStatus(gif.publishedAt, gif.deactivatedAt);
                return (
                  <Col key={index} md={6} lg={3}>
                    <Card className="importPageCard">
                      {this.state.allowEdit && <Input
                        type="checkbox"
                        className="importPageCheckbox"
                        id={gif.id}
                        checked={gif.isChecked}
                        onChange={(event) =>
                          this.handleCheckboxChange(event, gif)
                        }
                      />}
                      <React.Fragment>
                        <CardImg
                          src={gif.fixedWidthTiny != null && gif.fixedWidthTiny != undefined ? gif.fixedWidthTiny.gif.url : "/img/white-bg.png"}
                          onClick={() => {
                            redirectTo(`/movie-gifs/${gif.id}`);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </React.Fragment>
                      <CardBody>
                        <small className="card-text">Id: {gif.id}</small>
                        <Badge className="float-right" color={getStatusColor(status)}>
                          {status}
                        </Badge>
                        <CardTitle tag="h3" className="mb-0">
                          {gif.title}
                        </CardTitle>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })
            )}
          </Row>
        </InfiniteScroll></>}
        {/* Modal Component to display confirmation */}
        <ConfirmationModal
          show={this.state.confirmationModalIsOpen}
          modaltext={this.state.action}
          onConfirmationTrue={() => {
            this.setState({confirmationModalIsOpen : false});
            this.handleBulkOperation(this.state.action)
          }}
          onHide={() => this.setState({confirmationModalIsOpen : false , action:""})}
        />
        {/* Modal component to display failure response */}
        <ResponseModal
          show={this.state.failureModalDisplay}
          onHide={this.failureModalClose}
          modalheading={"Error"}
          modaltext={this.state.failureModalText}
        />
        {/* Modal component to display success response */}
        <ResponseModal
          show={this.state.successModalDisplay}
          onHide={this.successModalClose}
          modalheading={"Success"}
          modaltext={this.state.successModalText}
        />
      </Container>
    );
  }
}

export default MovieGifs;

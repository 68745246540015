import React, { useState, useEffect } from "react";
import { InputGroup, Modal } from "react-bootstrap";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
  Input,
  InputGroupText,
  InputGroupAddon,
} from "reactstrap";
import Select from "react-select";
import { getRolesDropdown } from "../../../utilities/apiUtils/roles";
import ResponseModal from "../ResponseModal";
import { error } from "../../../utilities/commonUtil";
import { Eye as EyeIcon, EyeOff as EyeOffIcon } from "react-feather";

const EditUserFormModal = (props) => {
  const id = props.data.id;
  const selectedRoles = props.data.selectedRoles;
  const [allRoles, setAllRoles] = useState([]);
  const [roleIDs, setRoleIDs] = useState(props.data.selectedRoleIds);
  const [username, setUsername] = useState(props.data.username);
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [failureModal, setFailureModal] = useState({
    display: false,
    text: "",
  });

  useEffect(() => {
    let fetchRoles = async () => {
      try {
        let roles = await getRolesDropdown();
        if (roles.length) {
          setAllRoles(roles);
        }
      } catch (err) {
        setFailureModal({ display: true, text: error.unexpectedError });
      }
    };
    fetchRoles();
  }, []);

  const handleRoleChange = (selectedRoles) => {
    let roles = [];
    if (selectedRoles !== null) {
      selectedRoles.forEach((role) => {
        roles.push(role.value);
      });
    }
    setRoleIDs(roles);
  };

  const handleChange = (event) => {
    hideAlert("passwordMismatch");

    switch (event.target.name) {
      case "username":
        setUsername(event.target.value);
        break;
      case "password":
        setPassword(event.target.value);
        break;
      case "confirmedPassword":
        setConfirmedPassword(event.target.value);
        break;

      default:
        return;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (password !== confirmedPassword) {
      showAlert("passwordMismatch");
      return;
    }
    let data = {
      id,
      username,
      roleIDs,
      password,
    };
    if (password !== "" && password !== undefined) {
      data["password"] = password;
    }
    props.update(data);
  };

  const showAlert = (elementId) => {
    document.getElementById(elementId).classList.remove("d-none");
  };

  const hideAlert = (elementId) => {
    document.getElementById(elementId).classList.add("d-none");
  };

  const generateRandomPassword = () => {
    var randomPassword = Math.random().toString(36).slice(-10);
    setPassword(randomPassword);
    setConfirmedPassword(randomPassword);
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        data={props.data}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label>Username</Label>
                  <Input
                    type="text"
                    name="username"
                    placeholder="Enter Username"
                    onChange={handleChange}
                    value={username}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label>Roles</Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name="roles"
                    defaultValue={selectedRoles}
                    onChange={handleRoleChange}
                    options={allRoles}
                    isMulti
                  />
                </FormGroup>
              </Col>
              <hr className="w-100" />
              <Col md={12} className="mb-2">
                <h4 className="d-block mx-auto opacity-4">Reset Password</h4>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Password</Label>
                  <InputGroup>
                    <Input
                      type={isPasswordVisible ? "text" : "password"}
                      name="password"
                      className="border-right-0"
                      placeholder="Enter Password"
                      onChange={handleChange}
                      value={password}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText className="d-flex align-items-center justify-content-center border-left-0 bg-white">
                        {isPasswordVisible ? (
                          <EyeIcon
                            size={16}
                            onClick={() => {
                              setIsPasswordVisible(!isPasswordVisible);
                            }}
                          />
                        ) : (
                          <EyeOffIcon
                            onClick={() => {
                              setIsPasswordVisible(!isPasswordVisible);
                            }}
                            size={16}
                          />
                        )}
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label>
                    Confirm Password
                    <small id="passwordMismatch" className="text-danger d-none">
                      (Password mismatch)
                    </small>
                  </Label>
                  <InputGroup>
                    <Input
                      type={isPasswordVisible ? "text" : "password"}
                      name="confirmedPassword"
                      placeholder="Confirm Password"
                      className="border-right-0"
                      onChange={handleChange}
                      value={confirmedPassword}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText className="d-flex align-items-center justify-content-center border-left-0 bg-white">
                        {isPasswordVisible ? (
                          <EyeIcon
                            size={16}
                            onClick={() => {
                              setIsPasswordVisible(!isPasswordVisible);
                            }}
                          />
                        ) : (
                          <EyeOffIcon
                            onClick={() => {
                              setIsPasswordVisible(!isPasswordVisible);
                            }}
                            size={16}
                          />
                        )}
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Button
              color="info"
              onClick={() => {
                generateRandomPassword();
              }}
            >
              Generate Password
            </Button>
            <hr className="w-100" />
            <div className="d-block w-100">
              <Button color="primary" type="submit">
                Submit
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <ResponseModal
        show={failureModal.display}
        onHide={() => setFailureModal({ display: false })}
        modalheading={"Error"}
        modaltext={failureModal.text}
      />
    </>
  );
};

export default EditUserFormModal;

import React, { useState, useEffect, useCallback } from "react";
import ResponseModal from "../ResponseModal";
import { useLocation } from 'react-router-dom';
import { successModalHeading } from '../../../config';
import { errorModalHeading } from '../../../config/config';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { X } from 'react-feather'
import {
  error, errorDescription
} from '../../../utilities/commonUtil';
import { useHistory } from "react-router-dom"
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CustomInput,
} from "reactstrap";
import { GetAIPromptTemplates, GetAIPromptTemplatesDetails, createPromptTemplate, createPromptTemplateVersion, updatePromptTemplate } from "../../../utilities/apiUtils/genAIDashboard";
import { iconSize } from "../../../config/UserConfig";
import { dataTypeOptions, responseTypeOptions, errorMissingMessage, llmProviderOptions, promptTemplateResponses, promptTemplateVersionResponses, styleOptions, localeOptions } from "../../../config/configGenAIDashboard";
import ToolTip from "../../../utilities/ToolTip";
import queryString from 'query-string';

const PromptTemplateForm = () => {
  const history = useHistory();
  const location = useLocation();
  let responseFieldsMappingJSON;
  const currentPath = location.pathname.split('/');
  const pageType = currentPath[currentPath.length - 1];
  const pageType2 = currentPath[currentPath.length - 2];
  let promptTemplateId;
  if (pageType2 === "edit") {
    promptTemplateId = currentPath[currentPath.length - 1];
  }
  if (pageType2 === "version") {
    promptTemplateId = currentPath[currentPath.length - 3];
  }
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");
  const [promptTemplateOptions, setPromptTemplateOptions] = useState([]);
  const [showResponseFieldsMapping, setShowResponseFieldsMapping] = useState(true);
  const [isMultiStyleMultiVariantPromptTemplate, setIsMultiStyleMultiVariantPromptTemplate] = useState(false);
  const [responseFieldsMappingArray, setResponseFieldsMappingArray] = useState([
    {
      "key": "text",
      "value": ""
    }
  ]);

  var promptTemplateType = queryString.parse(location.search).type;
  var basePromptTemplateId = queryString.parse(location.search).basePromptTemplateId;

  const [promptData, setPromptData] = useState({
    type: promptTemplateType,
    basePromptTemplateId: basePromptTemplateId,
    locale: '',
    identifier: '',
    description: '',
    errorMessagesStr: ['']
  })

  const [promptVersionData, setPromptVersionData] = useState({
    aiPromptTemplateId: promptTemplateId,
    llmProvidersOrder: [""],
    style: styleOptions[0],
    systemPrompt: '',
    text: '',
    maxRetries: 2,
    additionalDetails: {
      responseType: responseTypeOptions[0],
      multiVariantPromptIdentifier: [],
      output: {
        dataType: dataTypeOptions[0],
        responseFieldsMapping: {}
      }
    },
    isCurrentVersion: false,
    isBetaVersion: true,
  })

  const handlePromptChange = (event) => {
    const { name, value } = event.target
    setPromptData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }
  const handlePromptVersionChange = (event) => {
    const { name, value } = event.target
    setPromptVersionData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  let fetchPromptTemplate = useCallback(async (promptTemplateId) => {
    try {
      let response = await GetAIPromptTemplatesDetails(promptTemplateId);
      if (response) {
        setPromptData({
          identifier: response.identifier,
          description: response.description,
          errorMessagesStr: JSON.parse(response.errorMessagesStr),
        });
      }
      else {
        showErrorModal(errorDescription(response.errorCode));
      }
    }
    catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  }, []);

  let fetchPromptTemplates = useCallback(async () => {
    try {
      let response = await GetAIPromptTemplates();
      if (response?.length > 0) {
        setPromptTemplateOptions(response.map(d => ({
          value: d.id.toString(),
          label: d.identifier,
        })))
      }
      else if (response & response.errorCode) {
        showErrorModal(errorDescription(response.errorCode));
      }
    }
    catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  }, []);

  useEffect(() => {
    fetchPromptTemplates();
    if (pageType !== "create" && pageType2 !== "version") {
      fetchPromptTemplate(promptTemplateId);
    }
  }, [promptTemplateId, fetchPromptTemplates, fetchPromptTemplate, pageType, pageType2]);

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let successModalClose = () => {
    setSuccessModalDisplay(false);
    history.goBack();
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  function hasEmptyKeys(obj) {
    for (const key in obj) {
      if (key === "" || obj[key] === "") {
        return true;
      }
    }
    return false;
  }

  const handleSubmit = async () => {
    convertResponseFieldsMappingToJSON();
    const formPromptData = {
      identifier: promptData.identifier,
      description: promptData.description,
      errorMessagesStr: JSON.stringify(promptData.errorMessagesStr),
    };
    const formPromptVersionData = {
      aiPromptTemplateId: parseInt(promptTemplateId),
      llmProvidersOrder: promptVersionData.llmProvidersOrder,
      style: promptVersionData.style.value,
      systemPrompt: promptVersionData.systemPrompt,
      text: promptVersionData.text,
      maxRetries: parseInt(promptVersionData.maxRetries),
      additionalDetails: {
        responseType: promptVersionData.additionalDetails.responseType.value,
        paginationDetails: {
          numPages: 3
        },
        multiVariantPromptIdentifier: promptVersionData.additionalDetails?.multiVariantPromptIdentifier?.value,
        output: {
          dataType: promptVersionData.additionalDetails.output.dataType.value,
          responseFieldsMapping: promptVersionData.additionalDetails.output.dataType.value === "string" || promptVersionData.additionalDetails.output.dataType.value === "jsonObjectRaw" ? null : responseFieldsMappingJSON
        }
      },
      isCurrentVersion: promptVersionData.isCurrentVersion,
      isBetaVersion: promptVersionData.isBetaVersion,
    };
    const formPromptAndPromptVersionData = {
      type: promptData.type,
      basePromptTemplateId: promptData.basePromptTemplateId?.length > 0 ? parseInt(promptData.basePromptTemplateId) : null,
      locale: promptData.locale || null,
      identifier: promptData.identifier,
      description: promptData.description,
      errorMessagesStr: JSON.stringify(promptData.errorMessagesStr),
      currentVersion: {
        llmProvidersOrder: promptVersionData.llmProvidersOrder,
        style: promptVersionData.style.value,
        systemPrompt: promptVersionData.systemPrompt,
        text: promptVersionData.text,
        maxRetries: parseInt(promptVersionData.maxRetries),
        additionalDetails: {
          output: {
            dataType: promptVersionData.additionalDetails.output.dataType.value,
            responseFieldsMapping: promptVersionData.additionalDetails.output.dataType.value === "string" ? null : responseFieldsMappingJSON
          }
        },
        isCurrentVersion: promptVersionData.isCurrentVersion,
        isBetaVersion: promptVersionData.isBetaVersion,
      }
    }
    const hasEmptyLLMProvider = promptVersionData.llmProvidersOrder?.length === 0 || promptVersionData.llmProvidersOrder?.some(provider => provider.trim() === "");
    try {
      if (pageType === "create" && pageType2 !== "version") {
        if (!promptData.identifier || !promptVersionData.text || hasEmptyLLMProvider || !promptVersionData.maxRetries || ((promptVersionData.additionalDetails.output.dataType.value === "jsonObject" || promptVersionData.additionalDetails.output.dataType.value === "xmlObject") && (Object.keys(responseFieldsMappingJSON).length === 0 || hasEmptyKeys(responseFieldsMappingJSON)))) {
          showErrorModal(errorMissingMessage)
        }
        else {
          let response = await createPromptTemplate(formPromptAndPromptVersionData)
          if (response?.status === "aiPromptTemplateCreated") {
            showSuccessModal(promptTemplateResponses.addedSuccessfully);
          }
          else {
            if (response?.errorCode) {
              showErrorModal(response?.errorMessage)
            }
          }
        }
      }
      else if (pageType2 === "version") {
        if (!promptVersionData.text || !promptVersionData.maxRetries || hasEmptyLLMProvider || ((promptVersionData.additionalDetails.output.dataType.value === "jsonObject" || promptVersionData.additionalDetails.output.dataType.value === "xmlObject") && (Object.keys(responseFieldsMappingJSON).length === 0 || hasEmptyKeys(responseFieldsMappingJSON))) || (promptVersionData?.additionalDetails?.responseType.value === "multiStyleMultiVariant" && promptVersionData?.additionalDetails?.multiVariantPromptIdentifier.length == 0)) {
          showErrorModal(errorMissingMessage)
        }
        else {
          let response = await createPromptTemplateVersion(formPromptVersionData)
          if (response?.status === "aiPromptTemplateVersionCreated") {
            showSuccessModal(promptTemplateVersionResponses.addedSuccessfully);
          }
          else {
            if (response?.errorCode) {
              showErrorModal(response?.errorMessage)
            }
          }
        }
      }
      else {
        if (!promptData.identifier) {
          showErrorModal(errorMissingMessage)
        }
        else {
          let response = await updatePromptTemplate(formPromptData, promptTemplateId);
          if (response?.status === "aiPromptTemplateEdited") {
            showSuccessModal(promptTemplateResponses.updatedSuccessfully);
          }
          else {
            if (response?.errorCode) {
              showErrorModal(response?.errorMessage)
            }
          }
        }
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  }

  const addllmProvider = () => {
    setPromptVersionData((prevState) => ({
      ...prevState,
      llmProvidersOrder: [...prevState.llmProvidersOrder, ""],
    }));
  }

  const handlellmProvider = (event, index) => {
    const newLlmProvidersOrder = [...promptVersionData.llmProvidersOrder];
    newLlmProvidersOrder[index] = event.value;
    setPromptVersionData((prevState) => ({
      ...prevState,
      llmProvidersOrder: newLlmProvidersOrder,
    }));
  }

  const removellmProvider = (e, index) => {
    const newLlmProvidersOrder = [...promptVersionData.llmProvidersOrder];
    newLlmProvidersOrder.splice(index, 1);
    setPromptVersionData((prevState) => ({
      ...prevState,
      llmProvidersOrder: newLlmProvidersOrder,
    }));
  }

  const addErrorMessage = () => {
    setPromptData(prevState => ({
      ...prevState,
      errorMessagesStr: [...prevState.errorMessagesStr, '']
    }));
  };

  const handleErrorMessageChange = (index, value) => {
    setPromptData(prevState => {
      const updatedErrorMessages = [...prevState.errorMessagesStr];
      updatedErrorMessages[index] = value;
      return { ...prevState, errorMessagesStr: updatedErrorMessages };
    });
  };

  const removeErrorMessage = (indexToRemove) => {
    setPromptData(prevState => ({
      ...prevState,
      errorMessagesStr: prevState.errorMessagesStr.filter((_, index) => index !== indexToRemove)
    }));
  };

  let convertResponseFieldsMappingToJSON = () => {
    if (responseFieldsMappingArray.length > 0) {
      responseFieldsMappingJSON = responseFieldsMappingArray.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.value }), {});
    }
  }

  const addRow = (state = undefined) => {
    let responseFieldsMappingRow, currentState;
    responseFieldsMappingRow = {
      "key": "",
      "value": "",
    };
    if (state !== undefined) {
      currentState = [...state];
    } else {
      currentState = [...responseFieldsMappingArray];
    }
    currentState = [...currentState, responseFieldsMappingRow];
    setResponseFieldsMappingArray(currentState);
  };

  const subtractRow = (index) => {
    let currentState = [...responseFieldsMappingArray];
    currentState = currentState.filter((item, idx) => idx !== index);
    setResponseFieldsMappingArray(currentState);
  };

  const handleAPIResponseField = (event, index) => {
    let updatedState = [...responseFieldsMappingArray];
    let { value } = event.target;
    updatedState[index].key = value;
    setResponseFieldsMappingArray(updatedState);
  };

  const handleLLMResponseField = (event, index) => {
    let updatedState = [...responseFieldsMappingArray];
    let { value } = event.target;
    updatedState[index].value = value;
    setResponseFieldsMappingArray(updatedState);
  };

  const handleResponseType = (event) => {
    if (event.value === "multiStyleMultiVariant") {
      setIsMultiStyleMultiVariantPromptTemplate(true);
      handleDataType(dataTypeOptions[1]);
    }
    else {
      setIsMultiStyleMultiVariantPromptTemplate(false);
      handleDataType(dataTypeOptions[0]);
    }
    setPromptVersionData((prevState) => ({
      ...prevState,
      additionalDetails: {
        ...prevState.additionalDetails,
        responseType: event
      }
    }));
  };

  const handleDataType = (event) => {
    if (event.value === "string" || event.value === "jsonObjectRaw") {
      setShowResponseFieldsMapping(false);
    }
    else {
      setShowResponseFieldsMapping(true);
    }
    setPromptVersionData((prevState) => ({
      ...prevState,
      additionalDetails: {
        ...prevState.additionalDetails,
        output: {
          ...prevState.additionalDetails.output,
          dataType: event,
        }
      }
    }));
  };

  const handleCheckboxCurrentVersionChange = (event) => {
    const { checked } = event.target;
    setPromptVersionData({
      ...promptVersionData,
      isCurrentVersion: checked,
    });
  };

  const handleCheckboxBetaEnabledChange = (event) => {
    const { checked } = event.target;
    setPromptVersionData({
      ...promptVersionData,
      isBetaVersion: checked,
    });
  };


  return (
    <>
      {
        promptTemplateType == "variant" ?
          (<Card>
            <CardBody>
              <h1 className="h3 mb-3">
                Create Prompt Template Language Variant
              </h1>
              <Form>
                <Row form>
                  <Col md={6} lg={6}>
                    <FormGroup>
                      <Label>
                        Base Prompt Template
                      </Label>
                      <Input
                        type="text"
                        disabled
                        value={`${promptData.basePromptTemplateId} : ${promptTemplateOptions?.filter((template) => template.value == promptData.basePromptTemplateId)[0]?.label}`}
                        style={{ width: "100%" }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6} lg={6}>
                    <FormGroup>
                      <Label>
                        Locale <span className="text-danger">*</span>
                      </Label>
                      <Select
                        id="locale"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder="Select Locale"
                        name="locale"
                        value={llmProviderOptions.find(option => option.value === promptData.locale)}
                        onChange={(event) => {
                          setPromptData((prevState) => ({
                            ...prevState,
                            locale: event.value
                          }))
                        }}
                        options={localeOptions}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>)
          : null
      }
      {pageType2 !== "version" || pageType2 === "edit" ?
        <Card>
          <CardBody>
            <h1 className="h3 mb-3">
              {pageType === "create" ? "Create Prompt Template" : "Edit Prompt Template"}
            </h1>
            <Form>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      Identifier <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      name="identifier"
                      value={promptData.identifier}
                      placeholder="Enter Identifier"
                      required
                      onChange={handlePromptChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      Description
                    </Label>
                    <Input
                      type="text"
                      name="description"
                      value={promptData.description}
                      placeholder="Enter Description"
                      onChange={handlePromptChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <h3 className="mt-3">Error Messages</h3>
              <Card className="mb-4">
                <CardBody>
                  {promptData.errorMessagesStr?.map((errorMessage, index) => (
                    <div
                      key={index}
                      className="d-flex justify-content-between align-items-center mb-3"
                    >
                      <Row form className="w-100">
                        <Col md={5}>
                          <FormGroup className="mb-md-0">
                            <Input
                              type="text"
                              placeholder="Enter an Error Message"
                              value={errorMessage}
                              onChange={(e) =>
                                handleErrorMessageChange(index, e.target.value)
                              }
                              style={{ width: "100%" }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={1}>
                          <X
                            className="d-none d-lg-block mt-1"
                            size={iconSize}
                            color="red"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              removeErrorMessage(index);
                            }}
                          ></X>
                        </Col>
                      </Row>
                    </div>
                  ))}
                  <Button color="secondary"
                    onClick={addErrorMessage}
                  >
                    <FontAwesomeIcon size="sm" icon={faPlus}></FontAwesomeIcon>{" "}
                    Add More
                  </Button>
                </CardBody>
              </Card>
            </Form>
          </CardBody>
        </Card> : ''}
      {pageType === "create" || pageType2 === "version" ? <Card>
        <CardBody>
          <h1 className="h3 mb-3">
            Create Prompt Template Version
          </h1>
          <Form>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    LLM Providers Order<span className="text-danger">*</span>
                    <Button
                      color="primary"
                      className="ml-2"
                      onClick={addllmProvider}
                    >
                      Add
                    </Button>
                  </Label>
                  {promptVersionData.llmProvidersOrder?.map((provider, index) => (
                    <div key={index} className="d-flex align-items-center mb-2">
                      <Select
                        id={`llmProvider-${index}`}
                        className="react-select-container flex-grow-1"
                        classNamePrefix="react-select"
                        placeholder="Select LLM Provider"
                        name={`llmProvider-${index}`}
                        value={llmProviderOptions.find(option => option.value === provider)}
                        onChange={(e) => handlellmProvider(e, index)}
                        options={llmProviderOptions}
                      />
                      <Button
                        color="danger"
                        className="ml-2"
                        onClick={(e) => removellmProvider(e, index)}
                        disabled={promptVersionData.llmProvidersOrder?.length === 1}
                      >
                        -
                      </Button>
                    </div>
                  ))}
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Style <span className="text-danger">*</span>
                  </Label>
                  <Select
                    id="version"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    placeholder="Select Style"
                    name="style"
                    value={promptVersionData.style}
                    onChange={(event) => {
                      setPromptVersionData((prevState) => ({
                        ...prevState,
                        style: event,
                      }))
                    }}
                    options={styleOptions}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Max Retries <span className="text-danger">*</span> <ToolTip data={"Maximum number of times the llm request is tried"} />
                  </Label>
                  <Input
                    type="number"
                    name="maxRetries"
                    value={promptVersionData.maxRetries}
                    placeholder="Enter Max Retries"
                    required
                    onChange={handlePromptVersionChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label>
                    System Prompt
                  </Label>
                  <Input
                    type="textarea"
                    name="systemPrompt"
                    value={promptVersionData.systemPrompt}
                    placeholder="Enter System Prompt"
                    required
                    onChange={handlePromptVersionChange}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label>
                    Text <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="textarea"
                    name="text"
                    value={promptVersionData.text}
                    placeholder="Enter Text"
                    required
                    onChange={handlePromptVersionChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <h3 className="mt-3">Additional Details</h3>
            <Card className="mb-4">
              <CardBody>
                <Row form>
                  <Col md={6} lg={6}>
                    <FormGroup>
                      <Label>
                        Bobble API's Response Type <span className="text-danger">*</span>
                      </Label>
                      <Select
                        id="responseType"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder="Select Response Type"
                        name="responseType"
                        value={promptVersionData?.additionalDetails?.responseType}
                        onChange={handleResponseType}
                        options={responseTypeOptions}
                      />
                    </FormGroup>
                  </Col>
                  {isMultiStyleMultiVariantPromptTemplate &&
                    <Col md={6} lg={6}>
                      <FormGroup>
                        <Label>
                          Prompt Template (for Multiple Variants Response)
                        </Label>  <span className="text-danger">*</span>
                        <Select
                          id="multiVariantPromptIdentifier"
                          className="react-select-container"
                          classNamePrefix="react-select"
                          placeholder="Select Prompt Template"
                          name="multiVariantPromptIdentifier"
                          required={isMultiStyleMultiVariantPromptTemplate}
                          value={promptVersionData?.additionalDetails?.multiVariantPromptIdentifier}
                          onChange={(event) => {
                            setPromptVersionData((prevState) => ({
                              ...prevState,
                              additionalDetails: {
                                ...prevState.additionalDetails,
                                multiVariantPromptIdentifier: event
                              }
                            }));
                          }}
                          options={promptTemplateOptions}
                        />
                      </FormGroup>
                    </Col>
                  }
                </Row>
                <Row form>
                  <Col md={6} lg={6}>
                    <FormGroup>
                      <Label>
                        LLM Output Data Type <span className="text-danger">*</span>
                      </Label>
                      <Select
                        id="partner"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder="Select Data Type"
                        name="dataType"
                        value={promptVersionData?.additionalDetails?.output?.dataType}
                        onChange={handleDataType}
                        options={dataTypeOptions}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {showResponseFieldsMapping ?
                  <>
                    <Card className="mb-4">
                      <h5 className="mx-3 mt-3">LLM Response Parsing Configuration</h5>
                      <CardBody>
                        <>
                          <Row className="text-center">
                            <Col md={5} className="text-center">
                              <h6>API Response Field<span className="text-danger">*</span></h6>
                            </Col>
                            <Col md={5}>
                              <h6>LLM Response Field<span className="text-danger">*</span></h6>
                            </Col>
                          </Row>
                          {responseFieldsMappingArray && responseFieldsMappingArray.map((item, index) => {
                            return (
                              <Row key={index}>
                                <Col sm={5}>
                                  <FormGroup>
                                    <Input
                                      type="text"
                                      name="apiResponseField"
                                      value={item.key}
                                      placeholder="Enter API Response Field"
                                      onChange={(e) => {
                                        handleAPIResponseField(e, index);
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col sm={5}>
                                  <FormGroup>
                                    <Input
                                      type="text"
                                      name="llmResponseField"
                                      value={item.value}
                                      placeholder="Enter LLM Response Field"
                                      onChange={(e) => {
                                        handleLLMResponseField(e, index);
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col sm={1}>
                                  {responseFieldsMappingArray.length > 1 && (
                                    <X
                                      className="d-none d-lg-block mt-1"
                                      size={iconSize}
                                      color="red"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        subtractRow(index);
                                      }}
                                    ></X>)}
                                </Col>
                              </Row>
                            );
                          })}
                          <Button
                            className="d-block pr-3 text-decoration-none"
                            color="secondary"
                            onClick={() => {
                              addRow();
                            }}
                          >
                            <FontAwesomeIcon size="sm" icon={faPlus}></FontAwesomeIcon>{" "}
                            Add More
                          </Button>
                        </>

                      </CardBody>
                    </Card> </> : ''}
              </CardBody>
            </Card>
            <Row>
              <Col xs={12}>
                <FormGroup className="d-flex align-items-center">
                  <Label
                    className="font-weight-bolder mb-0"
                    style={{ fontSize: "14px" }}
                  >
                    Enable for Live Users
                  </Label>
                  <div className="custom-control custom-switch">
                    <CustomInput
                      type="checkbox"
                      id="isCurrentVersion"
                      onChange={handleCheckboxCurrentVersionChange}
                      checked={promptVersionData.isCurrentVersion}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup className="d-flex align-items-center">
                  <Label
                    className="font-weight-bolder mb-0"
                    style={{ fontSize: "14px" }}
                  >
                    Enable for Beta Users
                  </Label>
                  <div className="custom-control custom-switch">
                    <CustomInput
                      type="checkbox"
                      id="isBetaVersion"
                      onChange={handleCheckboxBetaEnabledChange}
                      checked={promptVersionData.isBetaVersion}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card> : ''}
      <Button color="primary" onClick={handleSubmit}>Submit</Button>
      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={successModalHeading}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={errorModalHeading}
        modaltext={failureModalText}
      />
    </>
  );
};
const CreatePromptTemplate = () => (
  <Container fluid className="p-0">
    <Row>
      <Col lg="12">
        <PromptTemplateForm />
      </Col>
    </Row>
  </Container>
);
export default CreatePromptTemplate;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { retrieveFromLocalStorage } from "../../../../utilities/localStorageUtil";
import { Config } from "../../../../config";
import {
  redirectToNewTab,
  getStatusOfBtn,
  getStatusColor,
  getBtnName,
  getButtonColor,
  permissions,
  redirectTo,
} from "../../../../utilities/commonUtil";
import {
  Badge,
  Card,
  Container,
  CardImg,
  CardBody,
  CardTitle,
  CardFooter,
  Button,
  Row,
  Col,
  Input,
} from "reactstrap";
import {
  storyActivatePermission,
  storyDeactivatePermission,
  storyPublishPermission,
  getProcessingStatus,
  getProcessingStatusColor,
  getdateValue,
} from "../../../../config/configStoryDashboard";

export const UserStoryListingCard = (props) => {
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );
  const [story, setStory] = useState(props.reply);

  const showButton = (btnName) => {
    if (btnName === Config.ACTIVATE_BUTTON) return storyActivatePermission();
    else if (btnName === Config.DEACTIVATE_BUTTON)
      return storyDeactivatePermission();
    else if (btnName === Config.PUBLISH_BUTTON) return storyPublishPermission();
    else return false;
  };
  function getBadgeText(status) {
    switch (status) {
      case "legacy_dashboard":
        return "Legacy Dashboard";
      case `bobble_dashboard`:
        return "Bobble Dashboard";
      default:
        return status; // Return the raw status if no match
    }
  }
  let status = getStatusOfBtn(
    story.publishedAt ? story.publishedAt : null,
    story.deactivatedAt ? story.deactivatedAt : null
  );



  useEffect(() => {
    setStory(props.reply);
  }, [props.reply]);

  return (
    <Card>
      <Container className="d-flex justify-content-center">

        
        {story.contentType === "video" ? (
        story.videoPreviewURL ? (
          <video
            src={story.videoPreviewURL}
            controls
            controlsList="nodownload noremoteplayback"
            className="p-2"
            style={{ height: "400px", cursor: "pointer", width: "100%" }}
          />
        ) : story.staticPreviewURL ? (
          <CardImg
            top
            src={story.staticPreviewURL ?? ""}
            alt="Card image cap"
            className="p-2"
            style={{
              height: "400px",
              width: "100%",
              maxWidth: "250px",
              cursor: "pointer",
            }}
          />
        ) : (
          <CardImg
            src={"/img/no-image-icon.png"}
            alt="Card image"
            style={{
              height: "400px",
              paddingLeft: "10px",
              marginBottom: "5px",
            }}
          />
        )
      ) : story.contentType === "static" && story.staticPreviewFilename ? (
        <CardImg
          top
          src={story.staticPreviewFilename ?? ""}
          alt="Card image cap"
          className="p-2"
          style={{
            height: "400px",
            width: "100%",
            maxWidth: "250px",
            cursor: "pointer",
          }}
        />
      ) : (
        <CardImg
          src={"/img/no-image-icon.png"}
          alt="Card image"
          style={{
            height: "400px",
            paddingLeft: "10px",
            marginBottom: "5px",
          }}
        />
      )}
      </Container>

      <CardBody>
        <div tag="h3" className="mb-0">
          <Badge className="float-right" color={getStatusColor(status)}>
            {status}
          </Badge>
        </div>
        <small>ID: {story?.id}</small>
        <br />
        <small>Title: {story?.title}</small>
        <br />
        <small>
          Processing Status:
          <Badge
            className="ml-2"
            color={getProcessingStatusColor(story?.imageProcessingStatus)}
          >
            {getProcessingStatus(story?.imageProcessingStatus)}
          </Badge>
        </small>
        <br />
        <small>
          Source Identifier: {getBadgeText(story?.sourceIdentifier)}
        </small>
      </CardBody>
    </Card>
  );
};

import React, { useState } from "react";
import QuickReplyFormContainer from "./QuickReplyFormContainer.js";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { permissions } from "../../../utilities/commonUtil";

const CreateQuickReply = (props) => {
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  const createAllowed = () => {
    return allowedPermissions.includes(permissions.quickReplyCreate);
  };

  return (
    <QuickReplyFormContainer
      {...props}
      formName={"Create Quick Reply"}
      updateAllowed={createAllowed()}
    />
  );
};

export default CreateQuickReply;

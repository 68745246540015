import React, { Component } from "react";
import {
  fetchAllStickerPacks
} from "../../../utilities/apiUtils";
import {
  getStatus,
  errorDescription,
  allActions,
  error,
  permissions,
  redirectOnTokenExpiry,
  getStatusColor,
  getButtonColor,
  getBtnName,
  updateStatusActions,
} from "../../../utilities/commonUtil";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import Search from "../../../components/Search";
import ResponseModal from "../ResponseModal";
import { Col, Container, Row, Button } from "reactstrap";
import Loading from "../../../components/Loading";
import { Config } from "../../../config/UserConfig";
import UncontrolledButtonDropdown from "reactstrap/lib/UncontrolledButtonDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import EmptyStickerPacks from "./components/EmptyStickerPacks";
import StickerPack from "./components/StickerPack";
import Select from "react-select";
import Spinner from "reactstrap/lib/Spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import { capitalize } from "@material-ui/core";

class AllStickerPacks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      hasResponseError: false,
      errorText: "",
      confirmationModalIsOpen: false,
      failureModalDisplay: false,
      failureModalText: "",
      successModalDisplay: false,
      successModalText: "",
      action: "",
      id: null,
      allowedPermissions: retrieveFromLocalStorage("userPermissions"),
      searchString: "",
      stickerPacks: [],
      page: 0,
      limit: 20,
      pricing: "",
      status: "",
      isAutoDownloaded: false,
      sortBy: Config.UPDATED_AT_DESCENDING,
      searchBy: Config.SEARCH_BY_DEFAULT,
      hasMorePacks: true,
    };
  }
  componentDidMount() {
    this.getAllStickerPacks();
  }

  getAllStickerPacks = () => {
    let params = {
      searchString:
        this.state.searchBy === "tag" ? this.state.searchString : "",
      id: this.state.searchBy === "id" ? this.state.searchString : "",
      pricing: this.state.pricing,
      status: this.state.status,
      sortBy: this.state.sortBy,
      page: this.state.page,
      limit: this.state.limit,
      isAutoDownloaded: this.state.isAutoDownloaded,
    };
    fetchAllStickerPacks(params)
      .then((res) => {
        if (!res.errorCode) {
          this.setState({
            hasResponseError: false,
            stickerPacks: res.stickerPacks || [],
            loading: false,
            hasMorePacks: true,
          });
        } else {
          if (
            res.errorCode === error.tokenExpired ||
            res.errorCode === error.invalidAccessToken
          )
            redirectOnTokenExpiry();
          this.setState({
            hasResponseError: true,
            errorText: errorDescription(res.errorCode),
          });
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      });
  };

  handleChange = (event) => {
    let value = event.target.value;
    this.setState({ searchString: value });
    if (!event.target.value.length) {
      this.search();
    }
    event.preventDefault();
  };

  onKeyDown = (event) => {
    if (event.keyCode === Config.KEYPRESS_DOWN) {
      this.search();
    }
  };

  search = (searchBy) => {
    this.setState(
      {
        loading: true,
        page: 0,
        hasMorePacks: true,
        searchBy: searchBy || this.state.searchBy,
      },
      async () => {
        this.getAllStickerPacks();
      }
    );
  };

  failureModalClose = () => {
    this.setState({ failureModalDisplay: false });
  };

  successModelClose = () => {
    this.setState({ successModalDisplay: false });
    this.getAllStickerPacks();
  };

  handleStatusChange = (status) => {
    this.setState({ status: status.value }, () => this.search());
  };

  handleOrderChange = (sortBy) => {
    this.setState({ sortBy: sortBy.value }, () => this.search());
  };

  fetchMoreData = () => {
    // to avoid repetition of same theme
    if (this.state.searchBy === "id") {
      return;
    }
    this.setState({ page: this.state.page + 1 });
    this.setState({ hasMorePacks: true }, async () => {
      let params = {
        searchString:
          this.state.searchBy === "tag" ? this.state.searchString : "",
        id: this.state.searchBy === "id" ? this.state.searchString : "",
        pricing: this.state.pricing,
        status: this.state.status,
        sortBy: this.state.sortBy,
        page: this.state.page,
        limit: this.state.limit,
        isAutoDownloaded: this.state.isAutoDownloaded,
      };

      let res = await fetchAllStickerPacks(params);

      if (res.stickerPacks !== null) {
        let stickerPacks = this.state.stickerPacks.concat(res.stickerPacks);
        this.setState({ stickerPacks: stickerPacks });
      } else {
        this.setState({ hasMorePacks: false });
      }
    });
  };
  renderAllStickers() {
    if (this.state.stickerPacks.length === 0) {
      return <EmptyStickerPacks />;
    } else {
      return this.state.stickerPacks.map((sticker) => {
        let status = getStatus(sticker.publishedAt, sticker.deactivatedAt);
        let color = getStatusColor(status);
        let btnColor = getButtonColor(status);
        let btnName = getBtnName(status);
        return (
          <Col md="6" lg="4" key={sticker.id}>
            <StickerPack
              sticker={sticker}
              color={color}
              status={status}
              btnName={btnName}
              btnColor={btnColor}
            />
          </Col>
        );
      });
    }
  }

  render() {
    return (
      <Container fluid className="p-0">
        <Row>
          <Col md="4" sm="12">
            <Search
              value={this.state.searchString}
              onChange={this.handleChange}
              onKeyDown={this.onKeyDown}
              placeholder="Search a Sticker pack"
            ></Search>
          </Col>
          <Col md={3} sm={12}>
            <UncontrolledButtonDropdown className="mr-1 mb-1">
              <DropdownToggle caret color="success">
                {"Search By " + capitalize(this.state.searchBy) + " "}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    this.search("id");
                  }}
                >
                  ID
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    this.search("tag");
                  }}
                >
                  Tag
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </Col>
        </Row>
        <Row>
          <Col md={3} sm={12}>
            <Select
              className="react-select-container mb-4"
              classNamePrefix="react-select"
              placeholder="Select Status"
              onChange={this.handleStatusChange}
              options={[
                { value: "", label: "All" },
                { value: "active", label: "Active" },
                { value: "deactivated", label: "Deactivated" },
                { value: "unpublished", label: "Unpublished" },
              ]}
            />
          </Col>
          <Col md={3} sm={12}>
            <Select
              className="react-select-container mb-4"
              classNamePrefix="react-select"
              placeholder="Sort By"
              onChange={this.handleOrderChange}
              options={[
                { value: Config.UPDATED_AT_DESCENDING, label: "Updated At \u2193" },
                { value: Config.UPDATED_AT_ASCENDING, label: "Updated At \u2191" },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <h1 className="mb-3 pl-3">Sticker Packs</h1>
        </Row>
        {this.state.loading ? (
          <Loading />
        ) : this.state.hasResponseError ? (
          <ResponseModal
            show={this.state.hasResponseError}
            onHide={() => {
              this.setState({ hasResponseError: false });
            }}
            modalheading={"Error"}
            modaltext={this.state.errorText}
          />
        ) : (
          <React.Fragment>
            <InfiniteScroll
              className="infinite-scroll"
              dataLength={this.state.stickerPacks.length}
              next={this.fetchMoreData}
              hasMore={this.state.hasMorePacks}
              loader={
                !this.state.loading &&
                this.state.stickerPacks.length != 0 &&
                this.state.searchBy !== "id" && (
                  <Spinner color="info" className=" d-flex mx-auto" />
                )
              }
            >
              <Row className="mt-2">{this.renderAllStickers()}</Row>
            </InfiniteScroll>
            <ResponseModal
              show={this.state.successModalDisplay}
              onHide={this.successModelClose}
              modalheading={"Success"}
              modaltext={this.state.successModalText}
            />
            <ResponseModal
              show={this.state.failureModalDisplay}
              onHide={this.failureModalClose}
              modalheading={"Error"}
              modaltext={this.state.failureModalText}
            />
          </React.Fragment>
        )}
      </Container>
    );
  }
}

export default AllStickerPacks;

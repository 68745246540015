import React, { Component } from "react";
import ResponseModal from "../ResponseModal";
import Search from "../../../components/Search"
import Select from "react-select";
import { Link } from "react-router-dom";
import { Button, Badge } from "react-bootstrap";
import {
  Card,
  CardImg,
  Row,
  Col,
  Container,
  CardBody,
  Spinner,
  CardTitle,
  Label
} from "reactstrap";
import {
  errorDescription,
  error,
  allActions,
} from "../../../utilities/commonUtil";
import { config, createMusicalStory, formatTime, showActivateStoryButton, showCreateStoryButton, showDeactivateStoryButton, showEditStoryButton, storyResponses } from "../../../config/configTaasDashboard";
import Loading from "../../../components/Loading";
import InfiniteScroll from "react-infinite-scroll-component";
import { fetchTaasClients, fetchTaasStories, fetchTaasStoryURL, generatePreview, createUpdateStory } from "../../../utilities/apiUtils/taasDashboard";
import UncontrolledButtonDropdown from "reactstrap/lib/UncontrolledButtonDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import { capitalize } from "@material-ui/core";
import ConfirmationModal from "../ConfirmationModal";
import { failureModalHeading, successModalHeading } from "../../../config";


class Stories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: "",
      clientId: 0,
      successModalText: "",
      successModalDisplay: false,
      failureModalText: "",
      failureModalDisplay: false,
      pageNumber: 0,
      stories: [],
      clients: [],
      type: { value: "static", label: "Static" },
      hasMoreStories: false,
      status: { value: "all", label: "All" },
      imageURL: "",
      searchBy: config.searchByDefault,
      confirmationModalIsOpen: false,
      previewLoading: false,
      loading: true,
      action: "",
      data: {
        id: '',
        storyId: '',
        clientId: '',
        storyIds: '',
        gender: '',
        type: '',
        musicUrl: '',
        startDate: '',
        endDate: '',
      }
    };
  }

  componentDidMount() {
    this.search();
    this.fetchClientsData();
  }
  getStories = async () => {
    // Query parameter.
    let params = {
      clientId: this.state.searchBy === "client" ? this.state.searchString : "",
      status: this.state.status.value,
      type: this.state.type.value,
      pageNumber: this.state.pageNumber,
    };
    if (this.state.type.value === "static") {
      params.storyId = this.state.searchBy === "id" ? this.state.searchString : "";
    } else if (this.state.type.value === "musical") {
      params.id = this.state.searchBy === "id" ? this.state.searchString : "";
    }
    // API called with given query parameters.
    fetchTaasStories(params)
      .then(async (response) => {
        if (!response || response?.length === 0) {
          this.setState({ stories: [], hasMoreStories: false });
          return;
        }
        else if (response?.length > 0) {
          for (let i = 0; i < response.length; i++) {
            let val;
            if (response[i].storyId) {
              val = response[i].storyId;
            }
            if (response[i].storyIds) {
              let storyIds = response[i].storyIds;
              let storyIdValues = storyIds.split(',').map(Number);
              val = storyIdValues[0];
            }
            if (val) {
              let res = await fetchTaasStoryURL(val);
              response[i].imageURL = res?.story?.fixedWidthSmall?.jpg?.url;;
            }
          }
          this.setState({ stories: response });
          this.setState({ hasMoreStories: response.length < 10 ? false : true });
        } else {
          this.showErrorModal(errorDescription(response.errorCode));
        }
      })
      .catch((err) => {
        this.setState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      })
  };

  handleChange = (event) => {
    event.preventDefault();
    let value = event.target.value;
    const onlyNumbers = value.replace(/\D/g, '');
    this.setState({ searchString: onlyNumbers });
    if (!event.target.value.length) {
      this.search();
    }
  };

  fetchClientsData = async () => {
    try {
      let response = await fetchTaasClients()
      if (response.length > 0) {
        this.setState({
          clients: response.map(d => ({
            value: d.id,
            label: d.name,
          }))
        });
      }
      else {
        this.showErrorModal(errorDescription(response.errorCode));
      }
    } catch (err) {
      this.setState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  }

  onKeyDown = (event) => {
    if (event.keyCode === config.keypressDown) {
      this.search();
    }
  };

  search = () => {
    this.setState(
      { loading: true, pageNumber: 1, hasMoreStories: false, searchBy: this.state.searchBy },
      () => {
        this.getStories();
      });
  };

  fetchMoreData = () => {
    this.setState(
      { pageNumber: this.state.pageNumber + 1, hasMoreStories: true, loading: true },
      () => {
        // Query parameters
        let params = {
          clientId: this.state.searchBy === "client" ? this.state.searchString : "",
          status: this.state.status.value,
          type: this.state.type.value,
          pageNumber: this.state.pageNumber,
        };
        if (this.state.type.value === "static") {
          params.storyId = this.state.searchBy === "id" ? this.state.searchString : "";
        } else if (this.state.type.value === "musical") {
          params.id = this.state.searchBy === "id" ? this.state.searchString : "";
        }
        // API called with given query parameters
        fetchTaasStories(params)
          .then(async (response) => {
            if (response?.length > 0) {
              for (let i = 0; i < response.length; i++) {
                let storyId = response[i].storyId;
                if (response[i].storyIds) {
                  let storyIds = response[i].storyIds;
                  let storyIdValues = storyIds.split(',').map(Number);
                  storyId = storyIdValues[0];
                }
                let res = await fetchTaasStoryURL(storyId)
                response[i].imageURL = res?.story?.fixedWidthSmall?.jpg?.url;
              }
              this.setState({
                stories: this.state.stories.concat(response),
              });
              this.setState({ hasMoreStories: response.length < 10 ? false : true });
            }
          })
          .catch((err) => {
            this.setState({
              failureModalDisplay: true,
              failureModalText: errorDescription(error.unexpectedError),
            });
          })
          .finally(() => {
            this.setState({ loading: false });
          })
      }
    )
  };

  failureModalClose = () => {
    this.setState({ failureModalDisplay: false });
  };

  showErrorModal = (text) => {
    this.setState({ failureModalText: text });
    this.setState({ failureModalDisplay: true });
  };

  successModalClose = async () => {
    this.setState({ successModalDisplay: false });
    this.search();
  };

  showSuccessModal = (text) => {
    this.setState({ successModalText: text });
    this.setState({ successModalDisplay: true });
  };

  handleStatusChange = (status) => {
    this.setState({ status: { value: status.value, label: status.label } }, () => this.search());
  };

  handleClient = (clientId) => {
    this.setState({ clientId: clientId.value, searchString: clientId.value }, () => this.search());
  }

  handleTypeChange = (type) => {
    this.setState({ type: { value: type.value, label: type.label } }, () => this.search());
  };

  disableDeactivateButton = (row) => {
    if (new Date(row.deactivatedAt) > new Date() || row.deactivatedAt === null) return false;
    return true;
  };

  disableActivateButton = (row) => {
    if (new Date(row.deactivatedAt) > new Date() || row.deactivatedAt === null) return true;
    return false;
  };

  updateStatus = async (update) => {
    try {
      let formData = new FormData();
      if (this.state.data.storyIds) {
        formData.append("id", this.state.data.id);
        formData.append("storyIds", this.state.data.storyIds);
        formData.append("clientId", this.state.data.clientId);
        formData.append("gender", this.state.data.gender);
        formData.append("type", this.state.data.type);
        formData.append("musicUrl", this.state.data.musicUrl);
        formData.append("startDate", this.state.data.startDate);
        formData.append("endDate", this.state.data.endDate);
        formData.append("activatedAt", update === "deactivate" ? false : true);
        formData.append("deactivatedAt", update === "deactivate" ? true : false);
      }
      else {
        formData.append("id", this.state.data.id);
        formData.append("storyId", this.state.data.storyId);
        formData.append("clientId", this.state.data.clientId);
        formData.append("gender", this.state.data.gender);
        formData.append("type", this.state.data.type);
        formData.append("startDate", this.state.data.startDate);
        formData.append("endDate", this.state.data.endDate);
        formData.append("activatedAt", update === "deactivate" ? false : true);
        formData.append("deactivatedAt", update === "deactivate" ? true : false);
      }
      let response = await createUpdateStory(formData);
      if (response.code === "storyUpdated") {
        this.showSuccessModal(update === "deactivate" ? storyResponses.deactivatedSuccessfully : storyResponses.activatedSuccessfully);
      } else {
        if (response.errorDescription) {
          this.showErrorModal(response.errorDescription)
        }
      }
    } catch (err) {
      this.showErrorModal(errorDescription(error.unexpectedError));
    }
  };

  setConfirmationModalState = (event, row) => {
    if (row.storyIds) {
      this.setState({
        action: event,
        confirmationModalIsOpen: true,
        data: {
          id: row.id,
          clientId: row.clientId,
          storyIds: row.storyIds,
          gender: row.gender,
          type: row.type,
          musicUrl: row.musicUrl,
          startDate: row.startDate,
          endDate: row.endDate,
        }
      })
    }
    else {
      this.setState({
        action: event,
        confirmationModalIsOpen: true,
        data: {
          id: row.id,
          clientId: row.clientId,
          storyId: row.storyId,
          gender: row.gender,
          type: row.type,
          startDate: row.startDate,
          endDate: row.endDate,
        }
      })
    }
  };

  generateFinalPreview = async (storyIds, musicUrl) => {
    this.setState({ previewLoading: true });
    let formData = new FormData();
    formData.append("gender1", createMusicalStory.gender1);
    formData.append("imageUrl1", createMusicalStory.imageUrl1);
    formData.append("gender2", createMusicalStory.gender2);
    formData.append("imageUrl2", createMusicalStory.imageUrl2);
    formData.append("storyIds", storyIds);
    formData.append("userId", createMusicalStory.userId);
    formData.append("bounce", createMusicalStory.bounce);
    formData.append("vibrate", createMusicalStory.vibrate);
    formData.append("logoUrl", createMusicalStory.logoUrl);
    formData.append("videoScale", createMusicalStory.videoScale);
    formData.append("musicUrl", musicUrl);
    try {
      let response = await generatePreview(formData);
      if (response) {
        this.setState({ previewLoading: false });
        let url = response.contents[0].media.fixedWidthFull.mp4.url;
        window.open(url);
      }
    }
    catch (err) {
      this.showErrorModal(errorDescription(error.unexpectedError));
    }
  }

  render() {
    return (
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between">
          <h1 className="h3">Stories</h1>
          <Link to={{
            pathname: `/taas/stories/create`
          }}>
            {showCreateStoryButton() ? (
              <Button
                style={{ fontSize: "1rem" }}
                className="mx-3 btn-success mb-3"
              >
                Add story for client
              </Button>) : null}
          </Link>
        </div>
        <Row className="mt-4">
          <Col lg={6}>
            {this.state.searchBy !== "client" && <Search
              type="text"
              value={this.state.searchString}
              onChange={this.handleChange}
              onKeyDown={this.onKeyDown}
              placeholder={"Search Stories"}
            ></Search>}
            {this.state.searchBy === "client" &&
              <Select
                className="react-select-container mb-4"
                id="statusSelect"
                classNamePrefix="react-select"
                name="client"
                placeholder="Select Client"
                onChange={this.handleClient}
                options={this.state.clients}
              />}
          </Col>
          <Col md={3} sm={12}>
            <UncontrolledButtonDropdown className="mr-1 mb-1">
              <DropdownToggle caret color="success">
                {"Search By " + capitalize(this.state.searchBy) + " "}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => this.setState({ searchBy: "id", searchString: "" })}>ID</DropdownItem>
                <DropdownItem onClick={() => this.setState({ searchBy: "client", searchString: "" })}>Client</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </Col>
        </Row>
        <Row>
          <Col md={3} sm={12}>
            <Label>Status</Label>
            <Select
              className="react-select-container mb-4"
              classNamePrefix="react-select"
              placeholder="Select Status"
              onChange={this.handleStatusChange}
              options={[
                { value: "all", label: "All" },
                { value: "activated", label: "Activated" },
                { value: "deactivated", label: "Deactivated" },
              ]}
              value={this.state.status}
            />
          </Col>
          <Col md={3} sm={12}>
            <Label>Story Type</Label>
            <Select
              className="react-select-container mb-4"
              classNamePrefix="react-select"
              value={this.state.type}
              onChange={this.handleTypeChange}
              options={[
                { value: "static", label: "Static" },
                { value: "musical", label: "Musical" },
              ]}
            />
          </Col>
        </Row>
        <InfiniteScroll
          style={{ overflow: "auto !important" }}
          className="infinite-scroll"
          dataLength={this.state.stories?.length}
          next={this.fetchMoreData}
          hasMore={this.state.hasMoreStories}
          loader={
            this.state.loading && (
              <Spinner color="info" className="d-flex mx-auto" />
            )
          }
        >
          <Row className="mt-4 mx-0">
            {(this.state.loading && this.state.stories?.length <= 0) ? (
              <Spinner color="info" className="d-flex mx-auto" />
            ) : (this.state.stories?.length > 0) ?
              this.state.previewLoading ?
                <Loading height="50px" weight="50px" />
                :
                this.state.stories.map((story, index) => {
                  return (<>
                    {story.imageURL &&
                      <Col key={index} md={6} lg={4}>
                        <Card className="importPageCard">
                          <div className="image-container" style={{ "width": "100%", "height": "300px", "position": "relative", "overflow": "hidden", "backgroundColor": "white" }}>
                            {story.imageURL ? <CardImg
                              top
                              src={story.imageURL ?? ""}
                              alt="Card image cap"
                              style={{
                                "width": "100%", "height": "100%", "objectFit": "contain", "position": "absolute"
                              }}
                            /> : <Spinner color="info" className="d-flex mx-auto" />}
                          </div>
                          <CardBody className="px-4">
                            <CardTitle tag="h3" className="mb-0">
                              {new Date(story.endDate) < new Date() ? <Badge className="bg-secondary float-right">{"Expired"}</Badge> :
                                new Date(story.deactivatedAt) > new Date() || story.deactivatedAt === null ?
                                  <Badge className="badge-success float-right">{"Activated"}</Badge> :
                                  <Badge className="badge-danger float-right">{"Deactivated"}</Badge>}
                            </CardTitle>

                            {!story.storyIds &&
                              <>
                                <small>ID : {story.id}</small>
                                <br />
                                <small>Bobble Story ID : {story.storyId}</small>
                              </>
                            }
                            {story.storyIds &&
                              <>
                                <small>ID : {`${story.id} (${story.storyIds.split(',').map(Number)[0]})`}</small>
                                <br />
                                <small>Bobble Story IDs : {story.storyIds}</small>
                              </>
                            }
                            <br />
                            <br />
                            <small className="font-weight-bold">
                              Client : {story.clientName}
                            </small>
                            <br />
                            <small className="font-weight-bold">
                              Gender : {story.gender ? story.gender : "Unisex"}
                            </small>
                            <br />
                            <small className="font-weight-bold">
                              Type : {story.type}
                            </small>
                            <br />
                            {story.type === "musical" && <><small className="font-weight-bold"> Music URL : <a href={story.musicUrl}>
                              Link
                            </a></small><br /></>}
                            <small className="font-weight-bold">
                              Start Date : {story.startDate ? story.startDate : "N/A"}
                            </small>
                            <br />
                            <small className="font-weight-bold">
                              End Date : {story.endDate ? story.endDate : "N/A"}
                            </small>
                            <br />
                            <small className="font-weight-bold">
                              Activated At : {formatTime(story.activatedAt)}
                            </small>
                            <br />
                            <small className="font-weight-bold">
                              Deactivated At : {formatTime(story.deactivatedAt)}
                            </small>
                            <br />
                          </CardBody>
                          <CardBody>
                            <Container className="text-center" fluid>
                              <Row>
                                <Col sm="12" md="12">
                                  {showEditStoryButton() ? <>
                                    {story.storyIds &&
                                      <Link to={{
                                        pathname: `/taas/stories/${story.type}/edit/${story.id}`
                                      }}>
                                        <Button
                                          color="info"
                                        >
                                          Edit
                                        </Button>
                                      </Link>} </> : null}
                                  {showEditStoryButton() ? <>
                                    {!story.storyIds &&
                                      <Link to={{
                                        pathname: `/taas/stories/${story.type}/edit/${story.id}`
                                      }}>
                                        <Button
                                          className="mx-2"
                                          color="info"
                                        >
                                          Edit
                                        </Button>
                                      </Link>} </> : null}
                                  {" "}
                                  {showDeactivateStoryButton() ? (
                                    <Button
                                      className={`btn-danger ${story.type === "static" ? 'mx-2' : ''}`}
                                      disabled={this.disableDeactivateButton(story)}
                                      onClick={(event) =>
                                        this.setConfirmationModalState(event.target.innerHTML, story)
                                      }
                                    >
                                      Deactivate
                                    </Button>) : null}
                                  {"  "}
                                  {showActivateStoryButton() ? (
                                    <Button
                                      className={`btn-success ${story.type === "static" ? 'mx-2' : ''}`}
                                      disabled={this.disableActivateButton(story)}
                                      onClick={(event) =>
                                        this.setConfirmationModalState(event.target.innerHTML, story)
                                      }
                                    >
                                      Activate
                                    </Button>) : null}
                                  {"  "}
                                  {story.type === "musical" &&
                                    <Button
                                      className="btn-secondary"
                                      onClick={() => this.generateFinalPreview(story.storyIds, story.musicUrl)}
                                    >
                                      View
                                    </Button>
                                  }
                                </Col>
                              </Row>
                            </Container>
                          </CardBody>
                        </Card>
                      </Col>}</>
                  );
                })
              : <Container className="mt-5">
                <h3 className="text-center">No Story available.</h3>
              </Container>
            }
          </Row>
        </InfiniteScroll>
        <ResponseModal
          show={this.state.successModalDisplay}
          onHide={this.successModalClose}
          modalheading={successModalHeading}
          modaltext={this.state.successModalText}
        />
        <ResponseModal
          show={this.state.failureModalDisplay}
          onHide={this.failureModalClose}
          modalheading={failureModalHeading}
          modaltext={this.state.failureModalText}
        />
        <ConfirmationModal
          show={this.state.confirmationModalIsOpen}
          modaltext={this.state.action}
          onConfirmationTrue={() => {
            this.setState({ confirmationModalIsOpen: false });
            if (this.state.action === allActions.deactivate) this.updateStatus("deactivate");
            else if (this.state.action === allActions.activate) this.updateStatus("activate");
          }}
          onHide={() => this.setState({ confirmationModalIsOpen: false })}
        />
      </Container>
    );
  }
}

export default Stories;

import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Card,
    FormGroup,
    Label,
    CardBody,
    Container,
    Badge,
    Row,
    Col,
    Button,
    Collapse,
} from "reactstrap";
import Select from "react-select";
import {
    error,
    permissions,
    errorDescription,
    redirectionOnTokenExpiry,
    getStatus,
    getStatusColor,
    redirectTo,
    createDeepCopy,
} from "../../../utilities/commonUtil";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { Download } from "react-feather";
import ResponseModal from "../ResponseModal";
import EditKeyboardLanguage from "./EditKeyboardLanguage";
import {
    getKeyboardLanguage,
    uploadKeyboardLanguageResources,
} from "../../../utilities/apiUtils/keyboardLanguages";
import UploadResource from "./UploadResource";
import {
    keyboardLanguageResourceUpdatedStatus,
    keyboardLanguageResourceUpdatedText,
    noShortcutsAvailableText,
    noDefaultSuggestionsAvailableText,
    noLayoutsAvailableText,
    successModalHeading,
    failureModalHeading,
    resourceCompressionMaxAppVersion,
} from "../../../config/keyboardLanguages";
import KeyboardLanguageLayouts from "./KeyboardLanguageLayouts";
import Resources from "./Resources";

const LanguageDetail = (props) => {
    const [isEditEnabled, setIsEditEnabled] = useState(false);
    const [isUploadEnabled, setIsUploadEnabled] = useState(false);

    const [successModalState, setSuccessModalState] = useState({
        successModalDisplay: false,
        successModalText: "",
    });
    const [failureModalState, setFailureModalState] = useState({
        failureModalDisplay: false,
        failureModalText: "",
    });

    const [keyboardLanguage, setKeyboardLanguage] = useState({});
    const [uploadFileProgess, setUploadFileProgess] = useState(0);
    const [allowedPermissions, setAllowedPermissions] = useState(
        retrieveFromLocalStorage("userPermissions")
    );
    const [resourceVersions, setResourceVersions] = useState([]);
    const [resources, setResources] = useState([]);
    const [selectedVersion, setSelectedVersion] = useState({});
    const [selectedAppVersion, setSelectedAppVersion] = useState({});
    const [currentVersion, setCurrentVersion] = useState({});
    const [isReleaseTypeABTest, setIsReleaseTypeABTest] = useState(false);
    const [baseVersion, setBaseVersion] = useState(0);
    const [additionalDetails, setAdditionalDetails] = useState({})

    // This useEffect will be called for componentDidMount condition
    useEffect(() => {
        const getLanguageDetail = async (id) => {
            try {
                const response = await getKeyboardLanguage(id);
                if (response.errorCode) {
                    setFailureModalState({
                        failureModalDisplay: true,
                        failureModalText: errorDescription(response.errorCode),
                    });
                    if (
                        response.errorCode === error.tokenExpired ||
                        response.errorCode === error.invalidAccessToken
                    )
                        redirectionOnTokenExpiry();
                } else if (response.keyboardLanguage) {
                    let keyboardLanguage = response.keyboardLanguage;
                    let resourceVersions = [];
                    let selectedVersion = {};
                    let currentVersion = {};
                    let additionalDetail = {};

                    if (keyboardLanguage.additionalDetails) {
                        try {
                            additionalDetail = JSON.parse(keyboardLanguage.additionalDetails)
                        } catch (e) {
                            console.error("Error parsing additional details", e)
                        }

                        setAdditionalDetails(additionalDetail)
                    }

                    for (const element of keyboardLanguage.resourceVersions) {
                        if (
                            element.releaseType === "production"
                        ) {
                            setBaseVersion(element.version);
                            break;
                        }
                    }

                    if (keyboardLanguage.resourceVersions.length > 0) {
                        keyboardLanguage.resourceVersions.map((item, index) => {
                            let version = {};
                            version["value"] = item.version;
                            version["label"] = item.releaseType + "-" + item.version;
                            resourceVersions.push(version);
                        });

                        selectedVersion["value"] = resourceVersions[0].value;
                        selectedVersion["label"] = resourceVersions[0].label;

                        currentVersion["createdAt"] =
                            keyboardLanguage.resourceVersions[0].createdAt;
                        currentVersion["updatedAt"] =
                            keyboardLanguage.resourceVersions[0].updatedAt;
                        currentVersion["resources"] =
                            keyboardLanguage.resourceVersions[0].resources;
                        currentVersion["releaseType"] =
                            keyboardLanguage.resourceVersions[0].releaseType;
                        currentVersion["version"] =
                            keyboardLanguage.resourceVersions[0].version;
                    }

                    setKeyboardLanguage(keyboardLanguage);
                    setResourceVersions(resourceVersions);
                    setResources(keyboardLanguage.resourceVersions);
                    setSelectedVersion(selectedVersion);
                    setCurrentVersion(currentVersion);
                }
            } catch (err) {
                setFailureModalState({
                    failureModalDisplay: true,
                    failureModalText: errorDescription(error.unexpectedError),
                });
            }
        };

        let languageId = props.match.params.languageId;
        getLanguageDetail(languageId);
    }, []);

    const openEditModal = () => {
        setIsEditEnabled(true);
    };

    const closeEditModal = () => {
        setIsEditEnabled(false);
    };

    const openUploadResourceModal = () => {
        setIsUploadEnabled(true);
    };

    const closeUploadResourceModal = () => {
        setIsUploadEnabled(false);
    };

    const successModalClose = () => {
        setSuccessModalState((prev) => ({ ...prev, successModalDisplay: false }));
        setUploadFileProgess(0);
        closeUploadResourceModal();
    };

    const failureModalClose = () => {
        setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
    };

    const showEditLanguageButton = () => {
        return allowedPermissions.includes(permissions.keyboardLanguageEdit);
    };

    const showEditLayoutButton = () => {
        return allowedPermissions.includes(permissions.keyboardLanguageLayoutEdit);
    };

    const showAddLayoutButton = () => {
        return allowedPermissions.includes(
            permissions.keyboardLanguageLayoutCreate
        );
    };

    const toggleCollapse = (index) => {
        let updatedLanguage = { ...keyboardLanguage };
        updatedLanguage.layouts[index].isOpen =
            !updatedLanguage.layouts[index].isOpen;
        setKeyboardLanguage(updatedLanguage);
    };

    const showErrorModal = (errorCode) => {
        setFailureModalState({
            failureModalText: errorCode,
            failureModalDisplay: true,
        });
    };

    const showSuccessModal = (text) => {
        setSuccessModalState({
            successModalText: text,
            successModalDisplay: true,
        });
        setUploadFileProgess(100);
    };

    const uploadResource = async (resource, id) => {
        try {
            const response = await uploadKeyboardLanguageResources(resource, id);
            if (response.status === keyboardLanguageResourceUpdatedStatus) {
                showSuccessModal(keyboardLanguageResourceUpdatedText);
                window.location.reload();
            } else {
                showErrorModal(response.errorDescription);
            }
        } catch (err) {
            showErrorModal(errorDescription(error.unexpectedError));
        }
    };

    const handleVersionChange = (option) => {
        let currentVersion = {};
        for (const element of resources) {
            if (element.version == option.value) {
                currentVersion["createdAt"] = element.createdAt;
                currentVersion["updatedAt"] = element.updatedAt;
                currentVersion["resources"] = element.resources;
                currentVersion["releaseType"] = element.releaseType;
                currentVersion["version"] = element.version;
            }
        }
        setSelectedVersion(option);
        setCurrentVersion(currentVersion);
    };

    const handleAppVersionChange = (option, selectedVersion) => {
        let currentVersion = {};
        for (const element of resources) {
            if (element.version == selectedVersion.value) {
                currentVersion["createdAt"] = element.createdAt;
                currentVersion["updatedAt"] = element.updatedAt;
                currentVersion["resources"] = createDeepCopy(element.resources);
                // Check if app version is >= 9.8 to use compressed resources
                if (parseFloat(option.value) >= parseFloat(resourceCompressionMaxAppVersion)) {
                    for (const resource of currentVersion.resources) {
                        if (resource.compressedFileChecksum && resource.compressedURL && resource.compressedFileChecksum !== "" && resource.compressedURL !== "") {
                            resource.checksum = resource.compressedFileChecksum;
                            resource.url = resource.compressedURL;
                        }
                    }
                }
                currentVersion["releaseType"] = element.releaseType;
                currentVersion["version"] = element.version;
            }
        }
        setSelectedAppVersion(option);
        setCurrentVersion(currentVersion);
    };


    return (
        <Container>
            <div className="d-flex">
                <h2>{keyboardLanguage.name}</h2>
                <Badge
                    className="ml-2"
                    style={{ display: "table" }}
                    color={() =>
                        getStatusColor(
                            getStatus(
                                keyboardLanguage.publishedAt,
                                keyboardLanguage.deactivatedAt
                            )
                        )
                    }
                >
                    {getStatus(
                        keyboardLanguage.publishedAt,
                        keyboardLanguage.deactivatedAt
                    )}
                </Badge>
            </div>
            <Card className="mt-3 mb-5">
                <CardBody>
                    <Row>
                        <Col md={6}>
                            <span>
                                <strong>ID:</strong> {keyboardLanguage.id ?? "N/A"}
                            </span>
                            <br />
                            <span>
                                <strong>Name:</strong> {keyboardLanguage.name ?? "N/A"}
                            </span>
                            <br />

                            <span>
                                <strong>Locale:</strong> {keyboardLanguage.locale ?? "N/A"}
                            </span>
                            <br />

                            <span>
                                <strong>Min App Version:</strong>{" "}
                                {keyboardLanguage.minAppVersion ?? "N/A"}
                            </span>
                            <br />
                            <span>
                                <strong>Min Bobble SDK Version:</strong>{" "}
                                {keyboardLanguage.minBobbleSDKVersion ?? "N/A"}
                            </span>
                            <br />
                            <span>
                                <strong>Personalized Dictionary Decay Min Threshold:</strong>{" "}
                                {additionalDetails.personalized_dictionary_decay_min_threshold ?? "N/A"}
                            </span>
                            <br />
                            <span>
                                <strong>Min Transliteration App Version:</strong>{" "}
                                {additionalDetails.min_transliteration_app_version ?? "N/A"}
                            </span>
                            <br />
                            <span>
                                <strong>Macronic Base Keyboard Language ID:</strong>{" "}
                                {keyboardLanguage.macaronicBaseKeyboardLanguageId ?? "N/A"}
                            </span>
                        </Col>

                        <Col md={6}>
                            <span>
                                <strong>Device Type:</strong>{" "}
                                {keyboardLanguage.deviceType ?? "N/A"}
                            </span>
                            <br />
                            <span>
                                <strong>Language Code:</strong> {keyboardLanguage.code ?? "N/A"}
                            </span>
                            <br />
                            <span>
                                <strong>Created At:</strong>{" "}
                                {keyboardLanguage.createdAt == null
                                    ? "N/A"
                                    : keyboardLanguage.createdAt}
                            </span>
                            <br />
                            <span>
                                <strong>Published At:</strong>{" "}
                                {keyboardLanguage.publishedAt == null
                                    ? "N/A"
                                    : keyboardLanguage.publishedAt}
                            </span>
                            <br />
                            <span>
                                <strong>Updated At:</strong>{" "}
                                {keyboardLanguage.updatedAt == null
                                    ? "N/A"
                                    : keyboardLanguage.updatedAt}
                            </span>
                            <br />
                            <span>
                                <strong>Deactivated At:</strong>{" "}
                                {keyboardLanguage.deactivatedAt == null
                                    ? "N/A"
                                    : keyboardLanguage.deactivatedAt}
                            </span>
                        </Col>
                        <Col md={6}>
                            <Label>
                                <strong>
                                    Icon Image
                                </strong>
                            </Label>
                            {keyboardLanguage.iconImageURL && keyboardLanguage.iconImageURL !== "" &&
                                keyboardLanguage.iconImageURL !== null ? (
                                <Col className="pb-4">
                                    <img
                                        src={keyboardLanguage.iconImageURL}
                                        height="120"
                                        alt="iconImage"
                                        width="120"
                                    />
                                </Col>
                            ) : (
                                <Col></Col>
                            )}
                        </Col>
                        <Col md={12}>
                            <span>
                                <strong>Transliteration Algorithm Usage Order:</strong>{" "}
                                {additionalDetails.transliteration_algo_usage_orders ==
                                    null ||
                                    additionalDetails.transliteration_algo_usage_orders
                                        .length === 0
                                    ? "Default (From Configs)"
                                    : additionalDetails.transliteration_algo_usage_orders
                                        .map((algo) => algo.displayName)
                                        .join(" > ")}
                            </span>
                            <br />

                        </Col>
                    </Row>
                    {/* Display shortcuts */}
                    <br />
                    <h4 className="d-flex justify-content-center">
                        <p className="pb-0 mb-0">Shortcuts</p>
                    </h4>
                    <Row>
                        {additionalDetails.shortcuts && Array.isArray(additionalDetails.shortcuts) ? (
                            additionalDetails.shortcuts.map((shortcut, index) => {
                                return (
                                    <Col key={index} md={4}>
                                        <Card
                                            style={{
                                                backgroundColor: "rgb(184, 237, 173)",
                                            }}
                                            className="my-2 p-0 badge-light text-dark"
                                        >
                                            <CardBody className="m-0 p-2">
                                                {shortcut}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                );
                            })
                        ) : (
                            <p className="d-block mx-auto text-danger">
                                {noShortcutsAvailableText}
                            </p>
                        )}
                    </Row>
                    <Container className="d-md-flex px-0">
                        {showEditLanguageButton() ? (
                            <Button
                                className="d-block mr-auto mt-4"
                                color="primary"
                                onClick={() => {
                                    openEditModal();
                                }}
                            >
                                Edit language details
                            </Button>
                        ) : null}
                    </Container>
                </CardBody>
            </Card>



            <Resources
                handleVersionChange={handleVersionChange}
                handleAppVersionChange={handleAppVersionChange}
                openUploadResourceModal={openUploadResourceModal}
                resourceVersions={resourceVersions}
                selectedVersion={selectedVersion}
                selectedAppVersion={selectedAppVersion}
                currentVersion={currentVersion}
                setIsReleaseTypeABTest={setIsReleaseTypeABTest}
                keyboardLanguage={keyboardLanguage}
                baseVersion={baseVersion}
            />
            <KeyboardLanguageLayouts
                keyboardLanguage={keyboardLanguage}
                toggleCollapse={toggleCollapse}
                showEditLayoutButton={showEditLayoutButton}
                showAddLayoutButton={showAddLayoutButton}
            />
            <EditKeyboardLanguage
                show={isEditEnabled}
                language={keyboardLanguage}
                onHide={closeEditModal}
                additionalDetails={additionalDetails}
            />
            <UploadResource
                show={isUploadEnabled}
                onHide={closeUploadResourceModal}
                id={keyboardLanguage.id}
                uploadKeyboardLanguageResources={uploadResource}
                uploadFileProgess={uploadFileProgess}
                isReleaseTypeABTest={isReleaseTypeABTest}
                version={parseInt(selectedVersion.value)}
            />
            <ResponseModal
                show={successModalState.successModalDisplay}
                onHide={() => successModalClose()}
                modalheading={successModalHeading}
                modaltext={successModalState.successModalText}
            />
            <ResponseModal
                show={failureModalState.failureModalDisplay}
                onHide={() => failureModalClose()}
                modalheading={failureModalHeading}
                modaltext={failureModalState.failureModalText}
            />
        </Container>
    );
};

export default LanguageDetail;

import React, { Component, useState, useEffect, useCallback } from "react";
import { Card, CardBody, Container } from "reactstrap";
import { Button, Badge } from "react-bootstrap";
import { getRoles, editRole } from "../../../utilities/apiUtils/roles";
import {
  errorDescription,
  permissions,
  error,
  redirectOnTokenExpiry,
} from "../../../utilities/commonUtil";
import ResponseModal from "../ResponseModal";
import { columns } from "./roleTableColumns";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import EditRoleFormModal from "./EditRoleFormModal";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";

const { SearchBar } = Search;

const ExpandableRowsTable = () => {
  const [roleData, setRoleData] = useState([]);
  const [column] = useState(columns);
  const [failuremodaltext, setFailuremodaltext] = useState("");
  const [failuremodaldisplay, setFailuremodaldisplay] = useState(false);
  const [showRoleEditForm, setShowRoleEditForm] = useState(false);
  const [successmodaltext, setSuccessmodaltext] = useState("");
  const [successmodaldisplay, setSuccessmodaldisplay] = useState(false);
  const [modalData, setModalData] = useState({});
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  let fetchRoleData = useCallback(async () => {
    try {
      let response = await getRoles();
      if (response.length) {
        setRoleData(response);
      } else if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectOnTokenExpiry();
        showErrorModal(errorDescription(response.errorCode));
      }
    } catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  }, []);

  useEffect(() => {
    fetchRoleData();
  }, [fetchRoleData]);

  let showErrorModal = (text) => {
    setFailuremodaltext(text);
    setFailuremodaldisplay(true);
  };

  let failureModalClose = () => {
    setFailuremodaldisplay(false);
  };

  let closeRoleEditForm = () => {
    setShowRoleEditForm(false);
    setModalData({});
  };

  let successModalClose = async () => {
    setSuccessmodaldisplay(false);
    try {
      let response = await getRoles();
      if (response.length) {
        setRoleData(response);
      } else if (response.errorCode) {
        showErrorModal(errorDescription(response.errorCode));
      }
    } catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  };

  let showSuccessModal = (text) => {
    setSuccessmodaltext(text);
    setSuccessmodaldisplay(true);
  };

  let update = async (data) => {
    try {
      let response = await editRole(data);
      if (response.success) {
        let text = errorDescription(response.success);
        setShowRoleEditForm(false);
        setModalData({});
        showSuccessModal(text);
      } else {
        showErrorModal(errorDescription(response.errorCode));
      }
    } catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  };

  let showEditModal = (row) => {
    let selectedPermissions = [];
    let selectedPermissionIds = [];
    row.permissions.forEach((permission) => {
      let data = {
        value: permission.id,
        label: permission.name,
      };
      selectedPermissions.push(data);
      selectedPermissionIds.push(permission.id);
    });
    let formData = {
      id: row.id,
      description: row.description,
      selectedPermissions: selectedPermissions,
      selectedPermissionIds: selectedPermissionIds,
    };
    setModalData(formData);
    setShowRoleEditForm(true);
  };

  let showEditButton = () => {
    return allowedPermissions.includes(permissions.updateRole);
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <ul>
          <li>{`Description : ${row.description}`}</li>
          <li>{`Created At : ${row.createdAt}`}</li>
          <li>
            Permissions:
            {Object.values(row.permissions).map((permission, index) => {
              return (
                <Badge key={index} className="badge-secondary ml-2">
                  {permission.name}
                </Badge>
              );
            })}
          </li>
        </ul>
        {showEditButton() ? (
          <Button className="mx-3" onClick={() => showEditModal(row)}>
            Edit
          </Button>
        ) : null}
        {Object.keys(modalData).length > 0 ? (
          <EditRoleFormModal
            show={showRoleEditForm}
            onHide={closeRoleEditForm}
            data={modalData}
            update={update}
          />
        ) : null}
      </div>
    ),
  };

  return (
    <Card>
      <CardBody>
        <ToolkitProvider keyField="id" data={roleData} columns={column} search>
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} />
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                bordered={false}
                keyField="id"
                data={roleData}
                columns={column}
                expandRow={expandRow}
                pagination={paginationFactory({
                  sizePerPage: 10,
                  sizePerPageList: [5, 10, 25, 50],
                })}
              />
            </div>
          )}
        </ToolkitProvider>
      </CardBody>
      <ResponseModal
        show={successmodaldisplay}
        onHide={successModalClose}
        modalheading={"Success"}
        modaltext={successmodaltext}
      />
      <ResponseModal
        show={failuremodaldisplay}
        onHide={failureModalClose}
        modalheading={"Error"}
        modaltext={failuremodaltext}
      />
    </Card>
  );
};

class RolesTable extends Component {
  render() {
    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">All Roles</h1>

        <ExpandableRowsTable />
      </Container>
    );
  }
}

export default RolesTable;

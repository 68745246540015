import React, { useEffect, useState } from 'react'
import Select, { components } from "react-select";
import { Row, Col, FormGroup, Label, Button } from "reactstrap"
import { FaPlus, FaMinus } from "react-icons/fa";
import { clientOptions, efftectOptions } from "../../config/configSmartSuggestions";

const PlacementRule = ({ index, placement, locationClientMappingOptions = {}, locationOptions = [], updateRules = () => { }, addRules = () => { }, removeRules = () => { }, updateAllowed = false }) => {
  const [rule, setRule] = useState({
    placementLocation: "launcher",
    clientID: "7wZFJWA5chjgat68y826IAIKQ6s197RM",
    effect: "allow"
  })
  const [filteredClientOptions, setFilteredClientOptions] = useState([])
  const [filteredLocationOptions, setFilteredLocationOptions] = useState([])

  useEffect(() => {
    if (placement) {
      setRule({
        placementLocation: {
          label: locationOptions.find(option => option.value === placement?.placementLocation)?.label ?? placement?.placementLocation,
          value: placement?.placementLocation
        },
        clientID: {
          label: clientOptions.find(option => option.value === placement?.clientID)?.label ?? placement?.clientID,
          value: placement?.clientID
        },
        effect: {
          label: efftectOptions.find(option => option.value === placement?.effect)?.label ?? placement?.effect,
          value: placement?.effect
        }
      })
      if (locationClientMappingOptions[placement?.placementLocation]) {
        setFilteredClientOptions(locationClientMappingOptions[placement?.placementLocation]?.map(clientId => ({
          value: clientId,
          label: clientOptions.find(option => option.value === clientId)?.label ?? clientId,
          target: {
            name: "client",
            value: {
              value: clientId,
              label: clientOptions.find(option => option.value === clientId)?.label ?? clientId
            }
          }
        })))
      }
      if (locationClientMappingOptions) {
        setFilteredLocationOptions(Object.keys(locationClientMappingOptions)?.map(location => ({
          value: location,
          label: locationOptions.find(option => option.value === location)?.label ?? location,
          target: {
            name: "location",
            value: {
              value: location,
              label: locationOptions.find(option => option.value === location)?.label ?? location
            }
          }
        })))
      }
    }
  }, [placement, placement?.placementLocation, placement?.clientID, placement?.effect, locationClientMappingOptions, locationOptions])

  const requiredComponent = (props) => (
    <div>
      <components.Input {...props} required={true} />
    </div>
  );

  const notRequiredComponent = (props) => (
    <div>
      <components.Input {...props} required={false} />
    </div>
  );

  return (
    <Row form>
      <Col>
        <FormGroup className="mb-0">
          <Label
            className="font-weight-bolder"
            style={{ fontSize: "14px" }}
          >
            Location
          </Label>
          <Select
            id="placementLocation"
            className="react-select-container"
            classNamePrefix="react-select"
            name="placementLocation"
            placeholder="Select Location"
            value={rule.placementLocation}
            onChange={(event) =>
              updateRules(event, index, "placementLocation")
            }
            isDisabled={!updateAllowed}
            options={filteredLocationOptions}
            components={{
              Input:
                !rule.placementLocation
                  ? requiredComponent
                  : notRequiredComponent,
            }}
          />
        </FormGroup>
      </Col>
      <Col>
        <FormGroup className="mb-0">
          <Label
            className="font-weight-bolder"
            style={{ fontSize: "14px" }}
          >
            Client
          </Label>
          <Select
            id="clientID"
            className="react-select-container"
            classNamePrefix="react-select"
            name="clientID"
            placeholder="Select Client"
            value={rule.clientID}
            onChange={(event) =>
              updateRules(event, index, "clientID")
            }
            isDisabled={!updateAllowed}
            options={filteredClientOptions}
            components={{
              Input:
                !rule.clientID
                  ? requiredComponent
                  : notRequiredComponent,
            }}
          />
        </FormGroup>
      </Col>
      <Col>
        <FormGroup className="mb-0">
          <Label
            className="font-weight-bolder"
            style={{ fontSize: "14px" }}
          >
            Effect
          </Label>
          <Select
            id="effect"
            className="react-select-container"
            classNamePrefix="react-select"
            name="effect"
            placeholder="Select Effect"
            value={rule.effect}
            onChange={(event) =>
              updateRules(event, index, "effect")
            }
            isDisabled={!updateAllowed}
            options={efftectOptions}
            components={{
              Input:
                !rule.effect
                  ? requiredComponent
                  : notRequiredComponent,
            }}
          />
        </FormGroup>
      </Col>
      {updateAllowed ? (
        <Col md={1} className="align-self-end">
          {index === 0 ? (
            <Button onClick={addRules} color="transparent">
              <FaPlus />
            </Button>
          ) : (
            <Button onClick={() => removeRules(index)} color="transparent">
              <FaMinus />
            </Button>
          )}
        </Col>
      ) : (
        <div></div>
      )}
    </Row>
  )
}

export default PlacementRule
import React from "react";
import { Col, Row, Card, CardBody, FormGroup, Label, Input, Container,  CustomInput} from "reactstrap";

const QuickReplyTypeText = ({ quickReplyTypeText, setQuickReplyTypeText, formName, updateAllowed }) => {

    const allowEdit = () => {
        return formName === "edit" && !updateAllowed;
    }

    return (
        <Container fluid className="p-0">
           
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label>
                                                <strong>Raw Text</strong> <span className="text-danger">*</span>
                                                <p style={{ fontSize: 12, marginBottom: 0 }}>
                                                    Raw Text of the Quick Reply
                                                </p>
                                            </Label>
                                            <Input
                                                type="textarea"
                                                name="rawText"
                                                value={quickReplyTypeText.rawText}
                                                onChange={(e) =>
                                                    setQuickReplyTypeText({
                                                        ...quickReplyTypeText,
                                                        rawText: e.target.value
                                                    })
                                                }
                                                required
                                                disabled={allowEdit()}
                                            />
                                        </FormGroup>
                                    <Row>
                                        <Col md={6}>
                                            <Row className="pl-3 mt-4">
                                                <Col className="pl-0">
                                                    <strong>Add Emojis to Text</strong>
                                                </Col>
                                                <Col
                                                    style={{ display: "flex", justifyContent: "flex-end" }}
                                                >
                                                    <div className="custom-control custom-switch">
                                                        <CustomInput
                                                            type="checkbox"
                                                            id="emojiToggle"
                                                            value={quickReplyTypeText.emojiToggle === true}
                                                            onChange={(e) =>
                                                                setQuickReplyTypeText({
                                                                    ...quickReplyTypeText,
                                                                    emojiToggle: e.target.value =="true"?false:true
                                                                })}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label>
                                                    <strong>Priority (Enter integer value greater than 0)</strong>

                                                </Label>
                                                <Input
                                                    type="number"
                                                    name="priority"
                                                    min={1}
                                                    value={quickReplyTypeText.priority}
                                                    onChange={(e) =>
                                                        setQuickReplyTypeText({
                                                            ...quickReplyTypeText,
                                                            priority: e.target.value
                                                        })
                                                    }
                                                    disabled={allowEdit()}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label>
                                                <strong>Customized Text</strong>
                                                <p style={{ fontSize: 12, marginBottom: 0 }}>
                                                    Customized Text of the Quick Reply
                                                </p>
                                            </Label>
                                            <Input
                                                type="textarea"
                                                name="customizedText"
                                                value={quickReplyTypeText.customizedText}
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label>
                                                <strong>Attribution Text</strong>
                                                <p style={{ fontSize: 12, marginBottom: 0 }}>
                                                    Attribution Text of the Quick Reply
                                                </p>
                                            </Label>
                                            <Input
                                                type="textarea"
                                                name="attributionText"
                                                value={quickReplyTypeText.attributionText}
                                                onChange={(e) =>
                                                    setQuickReplyTypeText({
                                                        ...quickReplyTypeText,
                                                        attributionText: e.target.value
                                                    })
                                                }
                                                disabled={allowEdit()}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label>
                                                <strong>Additional Details</strong>
                                                <p style={{ fontSize: 12, marginBottom: 0 }}>
                                                    Additional Details of the Quick Reply
                                                </p>
                                            </Label>
                                            <Input
                                                type="textarea"
                                                name="additionalDetails"
                                                value={quickReplyTypeText.additionalDetails}
                                                onChange={(e) =>
                                                    setQuickReplyTypeText({
                                                        ...quickReplyTypeText,
                                                        additionalDetails: e.target.value
                                                    })
                                                }
                                                disabled={allowEdit()}
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
      
        </Container>
    );
};

export default QuickReplyTypeText;
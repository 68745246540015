import React, { useState, useEffect } from "react";
import { Card, CardBody, Container, Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";

import { columns } from "./components/lottieAnimationColumns";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import Search from "../../../components/Search";

import {
  isOperationPermitted,
  processDateTime,
  permissions,
} from "../../../utilities/commonUtil";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { getLottieAnimations } from "../../../utilities/apiUtils/stickers";

const LottieAnimation = () => {
  const [userData, setUserData] = useState([]);
  const [column] = useState(columns);
  const [filters, setFilters] = useState({});
  const [nameFilter, setNameFilter] = useState("");
  const [lottieAnimationList, setLottieAnimationList] = useState([]);
  const [emojiFilter, setEmojiFilter] = useState("");
  const [allowedPermissions, setAllowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  useEffect(() => {
    const fetchData = async () => {
      let data = await getLottieAnimations();
      if (data !== undefined) {
        data = processDateTime(data);
        setLottieAnimationList(data);
      }
    };
    fetchData();
  }, []);

  let onTableChange = (type, newState) => {
    setFilters(newState.filters);
  };

  const filterLottieAnimation = () => {
    let filteredData = [...lottieAnimationList];
    if (nameFilter !== "") {
      filteredData = filteredData?.filter((item) =>
        item?.name?.toLowerCase().includes(nameFilter?.toLowerCase())
      );
    }

    // emoji is an array of emoji no need for lower case
    if (emojiFilter !== "") {
      filteredData = filteredData?.filter((item) =>
        // item.emoji is an array of emoji
        item?.emojis?.some((emoji) => emoji?.includes(emojiFilter))
      );
    }
    return filteredData;
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <ul>
          <li>ID : {row.id !== null ? row.id : "null"}</li>
          <li>Name : {row.name !== null ? row.name : "null"}</li>
          <li>
            Lottie Animation URL :{" "}
            {row.lottieAnimationURL !== null ? (
              <a
                href={row.lottieAnimationURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                {row.lottieAnimationURL}
              </a>
            ) : (
              "null"
            )}
          </li>
          <li>
            Emojis : {row.emojis !== null ? row.emojis.join(", ") : "null"}
          </li>
          <li>
            Created At : {row.createdAt !== null ? row.createdAt : "null"}
          </li>
          <li>
            Updated At : {row.updatedAt !== null ? row.updatedAt : "null"}
          </li>
        </ul>

        {(isOperationPermitted(
          allowedPermissions,
          permissions.EmojiStickerAnimationEdit
        ) ||
          isOperationPermitted(
            allowedPermissions,
            permissions.EmojiStickerAnimationView
          )) && (
          <Link
            className="text-decoration-none"
            to={{
              pathname: isOperationPermitted(allowedPermissions, permissions.EmojiStickerAnimationEdit) ? `/stickers/lottie-animations/edit/${row.id}` : `/stickers/lottie-animations/view/${row.id}`,
              state: {
                formType: "edit",
              },
            }}
          >
            <Button className="mx-3">
              {isOperationPermitted(
                allowedPermissions,
                permissions.EmojiStickerAnimationEdit
              )
                ? "Edit"
                : "View"}
            </Button>
          </Link>
        )}
      </div>
    ),
  };

  return (
    <Container fluid className="p-0">
      <Row className="mb-3">
        <Col md={6} xs={6}>
          <h1>Lottie Animation</h1>
        </Col>

        {isOperationPermitted(
          allowedPermissions,
          permissions.EmojiStickerAnimationCreate
        ) && (
          <Link
            className="d-block ml-auto mb-4 pr-3 text-decoration-none"
            to={{
              pathname: `/stickers/lottie-animations/add`,
              state: {
                formType: "Add",
              },
            }}
          >
            <Button color="success" className="ml-auto d-flex btn-success">
              <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                Add
              </p>
            </Button>
          </Link>
        )}
      </Row>

      <Card style={{ overflow: "hidden", paddingBottom: "100px" }}>
        <div container-fullwidth="true" style={{ margin: "2%" }}>
          <Row
            className="mx-auto"
            style={{ display: "flex", justifyContent: "right" }}
          ></Row>
          <Row>
            <Col md={3} sm={12}>
              <Search
                placeholder="Search Name"
                value={nameFilter}
                onChange={(e) => setNameFilter(e.target.value)}
              ></Search>
            </Col>
            <Col md={3} sm={12}>
              <Search
                placeholder="Search Emoji"
                value={emojiFilter}
                onChange={(e) => setEmojiFilter(e.target.value)}
              ></Search>
            </Col>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                data={userData}
                columns={column}
                search
              >
                {(props) => (
                  <div>
                    <BootstrapTable
                      bootstrap4={true}
                      wrapperClasses="table-responsive"
                      hover={true}
                      bordered={false}
                      keyField="id"
                      data={filterLottieAnimation()}
                      columns={column}
                      expandRow={expandRow}
                      filter={filterFactory()}
                      pagination={paginationFactory({
                        sizePerPage: 10,
                        sizePerPageList: [5, 10, 25, 50],
                      })}
                      remote={{ filter: true }}
                      onTableChange={onTableChange}
                      noDataIndication="No results found"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </CardBody>
          </Row>
        </div>
      </Card>
    </Container>
  );
};

export default LottieAnimation;
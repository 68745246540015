import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { authRoutes } from "./index";
import { unprotectedDashboardRoutes } from "./index";
import AllProtectedRoutes from "./index";
import AuthLayout from "../layouts/AuthLayout";
import DashboardLayout from "../layouts/DashboardLayout";
import AccessDenied from "../layouts/AccessDenied";
import { retrieveFromLocalStorage } from "../utilities/localStorageUtil";
import { filterPermittedRoutes, redirectOnTokenExpiry } from "../utilities/commonUtil";
import { cloneDeep } from "lodash";

let ProtectedRoutes = [];

let updateRoutes = () => {
  let _AllProtectedRoutes = cloneDeep(AllProtectedRoutes);
  ProtectedRoutes = filterPermittedRoutes(_AllProtectedRoutes);
};

const ProtectedRoute = ({ component: Component, layout: Layout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return retrieveFromLocalStorage("token") ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to={authRoutes.login.path} />
        );
      }}
    />
  );
};

const unprotectedRoutes = (Layout, routes) =>
  Object.values(routes).map(({ path, component: Component }, index) => (
    <Route
      key={index}
      path={path}
      exact
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  ));

const unprotectedRoutesDashboardLayout = (Layout, routes) =>
  Object.values(routes).map(({ path, component: Component }, index) => (
    <Route
      key={index}
      path={path}
      exact
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  ));

const protectedRoutes = (Layout, routes) =>
  Object.values(routes).map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      Object.values(children).map(({ path, component: Component }, index) => (
        <ProtectedRoute
          key={index}
          path={path}
          exact
          component={Component}
          layout={Layout}
        />
      ))
    ) : (
      // Route item without children
      <ProtectedRoute
        key={index}
        path={path}
        exact
        component={Component}
        layout={Layout}
      />
    )
  );

const Routes = () => {
  return (
    <Router>
      <Switch>
        {unprotectedRoutes(AuthLayout, authRoutes)}
        {unprotectedRoutesDashboardLayout(
          DashboardLayout,
          unprotectedDashboardRoutes
        )}
        {updateRoutes()}
        {protectedRoutes(DashboardLayout, ProtectedRoutes)}
        {retrieveFromLocalStorage("token") ?
          (<Route render={() => <AccessDenied />} />) :
          (redirectOnTokenExpiry())
        }
      </Switch>
    </Router>
  );
};

export default Routes;

import React, { useState, useEffect } from "react";
import {
  Card,
  FormGroup,
  Label,
  CardHeader,
  CardBody,
  Container,
  Collapse,
  Row,
  Col,
  Button,
} from "reactstrap";
import Select from "react-select";
import { Download } from "react-feather";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { releaseTypes ,appVersionOptions } from "../../../config/keyboardLanguages";

const Resources = (props) => {
  const handleVersionChange = props.handleVersionChange;
  const handleAppVersionChange = props.handleAppVersionChange;
  const openUploadResourceModal = props.openUploadResourceModal;
  const resourceVersions = props.resourceVersions;
  const selectedVersion = props.selectedVersion;
  const selectedAppVersion = props.selectedAppVersion;
  const currentVersion = props.currentVersion;
  const setIsReleaseTypeABTest = props.setIsReleaseTypeABTest;
  const keyboardLanguage = props.keyboardLanguage;
  const baseVersion = props.baseVersion;
  const [selectedReleaseType, setSelectedReleaseType] = useState(
    releaseTypes[0]
  );
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    for (const releaseType of releaseTypes) {
      if (releaseType.value === props.currentVersion.releaseType) {
        setSelectedReleaseType(releaseType);
      }
    }
  }, [props.currentVersion]);

  let handleReleaseTypeChange = (e) => {
    setSelectedReleaseType(e.value);
    setIsReleaseTypeABTest(e.value.value == "ab_test");
  };

  return (
    <>
      <Container className="d-flex px-0">
        <h3 className="mt-3 mb-0">Resources</h3>
      </Container>
      <Container className="px-0">
        <Card className="mt-3 mb-5">
          <CardHeader onClick={() => setToggle(!toggle)} className="pt-3 pb-0">
            <div className="container">
              <div className="row">
                <Col md={8} className="p-0 ">
                  <h4>
                    <strong>Uploaded Resources</strong>
                  </h4>
                </Col>
                <div className="ml-auto">
                  {toggle ? <FaChevronUp /> : <FaChevronDown />}
                </div>
              </div>
            </div>
          </CardHeader>
          <Collapse isOpen={toggle}>
            <CardBody>
              <Row>
                <Col md={4}>
                  <span>
                    <FormGroup>
                      <Label>Version</Label>
                      <Select
                        className="react-select-container dropdown"
                        classNamePrefix="react-select"
                        name="version"
                        onChange={(option) => handleVersionChange(option)}
                        options={resourceVersions}
                        value={selectedVersion}
                        defaultValue={selectedVersion}
                        required
                      />
                    </FormGroup>
                  </span>
                </Col>
                <Col md={4}>
                  <span>
                    <FormGroup>
                      <Label>Release Type</Label>
                      <Select
                        className="react-select-container dropdown"
                        classNamePrefix="react-select"
                        name="releaseType"
                        options={releaseTypes}
                        value={selectedReleaseType}
                        isDisabled={true}
                      />
                    </FormGroup>
                  </span>
                </Col>
                <Col md={4}>
                  <span>
                    <FormGroup>
                      <Label>App Version</Label> 
                      <Select
                        className="react-select-container dropdown"
                        classNamePrefix="react-select"
                        name="appVersion"
                        onChange={(option) => handleAppVersionChange(option, selectedVersion)}
                        options={appVersionOptions}
                        value={selectedAppVersion}
                        defaultValue={selectedAppVersion}
                        required
                      />
                    </FormGroup>
                  </span>
                </Col>
                {currentVersion.releaseType === "ab_test" &&
                currentVersion.version > baseVersion ? (
                  <Col md={4} className="mt-4 pt-1">
                    <Button
                      color="primary"
                      onClick={() => {
                        setIsReleaseTypeABTest(true);
                        openUploadResourceModal();
                      }}
                    >
                      Edit Resource
                    </Button>
                  </Col>
                ) : null}
              </Row>
              <Row className="mt-2">
                <Col md={4}>
                  <span>
                    <strong>Created At:</strong>{" "}
                    {currentVersion.hasOwnProperty("createdAt")
                      ? currentVersion.createdAt
                      : "NA"}
                  </span>
                </Col>
                <Col md={4}>
                  <span>
                    <strong>Updated At:</strong>{" "}
                    {currentVersion.hasOwnProperty("updatedAt")
                      ? currentVersion.updatedAt
                      : "NA"}
                  </span>
                </Col>
                <Col md={4}>
                  <Button
                    className="d-block mr-auto"
                    color="primary"
                    disabled={keyboardLanguage.activeABTest !== null}
                    onClick={() => {
                      setIsReleaseTypeABTest(false);
                      openUploadResourceModal();
                    }}
                  >
                    Upload Resource
                  </Button>
                </Col>
              </Row>
              {currentVersion.hasOwnProperty("resources") ? (
                <Row className="mt-4">
                  <Col md={4}>
                    <strong>Type</strong>
                  </Col>
                  <Col md={2}>
                    <strong>Checksum</strong>
                  </Col>
                  <Col md={3}>
                    <strong>Update Notes</strong>
                  </Col>
                  <Col md={3}>
                    <strong>URL</strong>
                  </Col>
                  <hr className="w-100" />
                </Row>
              ) : null}
              {currentVersion.resources
                ? currentVersion.resources.map((item, index) => {
                    return (
                      <Row className="mt-3" key={index}>
                        <Col md={4}>
                          <span>{item.type == "" ? "---" : item.type}</span>
                        </Col>
                        <Col md={2}>
                          <span className="mt-2">
                            {" "}
                            {item.checksum == "" ? "---" : item.checksum}
                          </span>
                          <a
                            href={item.url}
                            download
                            style={{ position: "relative", top: -3 }}
                          >
                            <Download
                              className="align-middle text-success ml-2"
                              size={16}
                            />
                          </a>
                        </Col>
                        <Col md={3}>
                          <span>
                            {item.updateNotes == "-" ? "NA" : item.updateNotes}
                          </span>
                        </Col>
                        <Col md={3}>
                          <a href={item.url} download>
                            {item.url}
                          </a>
                        </Col>
                        <hr className="w-75" />
                      </Row>
                    );
                  })
                : null}
            </CardBody>
          </Collapse>
        </Card>
      </Container>
    </>
  );
};

export default Resources;

import React, { useState, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import { redirectTo } from "../../../../utilities/commonUtil";
import { columns } from "./stickerTableColumns";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

const { SearchBar } = Search;

const StickerInStickerPacksTable = ({ stickerPacks }) => {
  const [userData, setUserData] = useState([]);
  const [column] = useState(columns);

  useEffect(() => {
    if (stickerPacks !== undefined && stickerPacks.length > 0) {
      setUserData(stickerPacks);
    }
  }, [stickerPacks]);

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      redirectTo(`/stickers/sticker-pack/${row["id"]}`);
    },
  };

  return (
    <Card>
      <CardBody>
      <h3 className="mb-4 mt-2 pl-3" >
          Sticker Packs
        </h3>
        <ToolkitProvider keyField="id" data={userData} columns={column} search>
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} />
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                bordered={false}
                keyField="id"
                data={userData}
                columns={column}
                pagination={paginationFactory({
                  sizePerPage: 10,
                  sizePerPageList: [5, 10, 25, 50],
                })}
                rowEvents={rowEvents}                
                hover={true}
              />
            </div>
          )}
        </ToolkitProvider>
      </CardBody>
    </Card>
  );
};

export default StickerInStickerPacksTable;

import React from 'react'
import { getColorByPercentage, parseDateInMoment } from '../../../config/configAutoCappings'
import { replaceUnderscoreAndCapitalize } from '../../../utilities/commonUtil'

const defaultFormatter = (cell, row) => {
  return cell || "-"
}

const unitPriceFormatter = (cell, row) => {
  return cell ? "₹" + cell : "-"
}

const totalTargetsFormatter = (cell, row) => {
  return (
    <>
      <div><strong>{replaceUnderscoreAndCapitalize(row.metrics)}: </strong>{row.target || "-"}</div>
      <div><strong>Revenue: </strong>{cell ? "₹" + cell : "-"}</div>
    </>
  )
}

const completeFormatter = (cell, row) => {
  return cell ? (
    <div>
      <div style={{ color: getColorByPercentage(cell), fontWeight: 900 }}>{Number(cell).toFixed(2)}%</div>
      <small className='font-weight-bold'>({row.completed} {row.metrics})</small>
    </div>
  ) : "-"
}

const statusFormatter = (cell, row) => {
  return (
    <>
      <div className={`font-weight-bolder ${cell < 0 ? "text-danger" : "text-success"}`}>{row.status}</div>
      <div className="font-weight-bolder" style={{ fontSize: 12, color: "grey" }}>{Math.abs(cell)} {cell < 0 ? "days ago" : "days remaining"}</div>
      <div style={{ fontSize: 11, color: "grey" }}>{parseDateInMoment(row.startAt).format("Do MMM YYYY") + " - " + parseDateInMoment(row.endAt).format("Do MMM YYYY")}</div>
    </>
  )
}

const cappingRuleTargetFormatter = (cell, row) => {
  return cell ? <div><strong>{replaceUnderscoreAndCapitalize(row.metrics)}:</strong> {cell}</div> : "-"
}

const cappingRuleEntitiesFormatter = (cell, row) => {
  const smartSuggestions = []
  if (cell?.length > 0)
    cell.forEach(entity => {
      if (entity.type === "smart_suggestion") smartSuggestions.push(entity.id)
    })
  return (
    <div>
      {smartSuggestions.length > 0 ? (
        <>
          <strong>Smart Suggestions: </strong>
          {smartSuggestions.map((el, index) => <a key={el} href={`/ads/smart-suggestions/edit/${el}`} target='_blank' rel="noopener noreferrer">{el}{index !== smartSuggestions.length - 1 && ", "}</a>)}
        </>
      ) : null}
    </div>
  )
}

export const autoCappingTableColumns = [
  {
    dataField: "id",
    text: "#",
    sort: true,
    headerAttrs: { width: 60 },
    searchable: true,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "name",
    text: "Brand Campaign Name",
    sort: true,
    searchable: true,
    formatter: (cell, row) => defaultFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "mediaBuyType",
    text: "Buy Model",
    sort: true,
    searchable: true,
    formatter: (cell, row) => defaultFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "unitPriceINR",
    text: "Unit Price",
    sort: true,
    searchable: true,
    formatter: (cell, row) => unitPriceFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "roAmountINR",
    text: "Total Targets",
    sort: true,
    searchable: true,
    formatter: (cell, row) => totalTargetsFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "completePercentage",
    text: "Complete %",
    sort: true,
    searchable: true,
    formatter: (cell, row) => completeFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "days",
    text: "Status",
    sort: true,
    headerAttrs: { width: 200 },
    searchable: true,
    formatter: (cell, row) => statusFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
];

export const cappingRulesTableColumns = [
  {
    dataField: "entities",
    text: "Entities",
    sort: false,
    headerAttrs: { width: 400 },
    searchable: false,
    formatter: (cell, row) => cappingRuleEntitiesFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "target",
    text: "Target",
    sort: true,
    searchable: true,
    formatter: (cell, row) => cappingRuleTargetFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "completePercentage",
    text: "Complete %",
    sort: true,
    searchable: true,
    formatter: (cell, row) => completeFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "cappingDistribution",
    text: "Capping Distribution",
    sort: true,
    searchable: true,
    formatter: (cell, row) => defaultFormatter(cell, row),
    style: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
];
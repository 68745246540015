import React, { useState, useEffect } from "react";
import ResponseModal from "../ResponseModal";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import ConfirmationModal from "../ConfirmationModal";
import { Container, Row, Col, Spinner } from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getCarousels,
  updateCarouselStatus,
} from "../../../utilities/apiUtils/carousel";
import CarouselCard from "./component/CarouselCard.jsx";
import { useHistory, useParams } from "react-router-dom";

const Carousels = () => {
  const [carousels, setCarousels] = useState([]);
  const [hasMoreCarousels, setHasMoreCarousels] = useState(true);
  const history = useHistory();
  const [selectedCarousel, setSelectedCarousel] = useState(null);
  const [successModalState, setSuccessModalState] = useState({
    successModalDisplay: false,
    successModalText: "",
  });
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });

  useEffect(() => {
    // Fetch data when the component mounts and on each page change
    getCarouselsData();
  }, []);

  const getCarouselsData = async () => {
    try {
      // Fetch carousels
      const response = await getCarousels();
      // Assuming the response is an object with a property 'carousels' that contains the array
      if (response) {
        // If new carousels are retrieved, append them to the existing list
        setCarousels(response.contentCategoryCarousels);
      }
    } catch (error) {
      // Handle the case when fetching carousels fails
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: "Failed to fetch carousels.",
      });
    }
  };

  const handleCarouselAction = async (carouselId, action) => {
    try {
      // Lowercase the first letter of the action
      const formattedAction = action.charAt(0).toLowerCase() + action.slice(1);
      // Trigger API call to update carousel status with formatted action
      await updateCarouselStatus(carouselId, formattedAction);
      // Refresh carousels after successful update
      getCarouselsData();
      // Show success modal
      setSuccessModalState({
        successModalDisplay: true,
        successModalText: `Carousel ${action}d successfully.`,
      });

      // Refresh the page after state update
      window.location.reload();
    } catch (error) {
      // Handle error cases here
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: `Failed to ${action} carousel.`,
      });
    } finally {
      // Reset selectedCarousel state after action is performed
      setSelectedCarousel(null);
    }
  };

  const setConfirmationModalState = (carousel, action) => {
    // Handle setting the selected carousel
    setSelectedCarousel(carousel);
    // Set the action for the ConfirmationModal
  };

  const renderAllCarousels = () => {
    return carousels.map((carousel) => (
      <Col md="4" key={carousel.id}>
        <CarouselCard
          carousel={carousel}
          onAction={(action) => setConfirmationModalState(carousel, action)}
        />
      </Col>
    ));
  };

  return (
    <Container fluid className="p-0">
      <Row>
        <h1 className="mb-4 mt-2 w-50 pl-3">Carousels</h1>
        <div className="ml-auto">
          <Link onClick={() => history.push(`/cre/create-carousel`)}>
            <Button color="primary" className="mr-2">
              Create a Carousel
            </Button>
          </Link>
          <Link to="/cre/carousel/order">
            <Button color="primary" className="mr-2">
              Manage Order
            </Button>
          </Link>
        </div>
      </Row>

      <InfiniteScroll
        className="infinite-scroll"
        dataLength={carousels.length}
        next={getCarouselsData}
        hasMore={hasMoreCarousels}
      >
        <Row className="mt-2">
          {carousels.map((carousel) => (
            <Col md="4" key={carousel.id}>
              <CarouselCard
                carousel={carousel}
                handleAction={handleCarouselAction}
              />
            </Col>
          ))}
        </Row>
      </InfiniteScroll>

      <ResponseModal
        show={successModalState.successModalDisplay}
        onHide={() => {
          setSuccessModalState({
            successModalDisplay: false,
            successModalText: "",
          });
        }}
        modalheading="Success Heading"
        modaltext={successModalState.successModalText}
      />

      <ResponseModal
        show={failureModalState.failureModalDisplay}
        onHide={() => {
          setFailureModalState({
            failureModalDisplay: false,
            failureModalText: "",
          });
        }}
        modalheading="Failure Heading"
        modaltext={failureModalState.failureModalText}
      />

      <ConfirmationModal
        show={selectedCarousel !== null}
        onHide={() => {
          setSelectedCarousel(null);
        }}
        onYes={(action) => handleCarouselAction(selectedCarousel.id, action)}
        action="activate" // Update this action value accordingly
      />
    </Container>
  );
};

export default Carousels;

import React from 'react'
import { Modal, Button } from "react-bootstrap";
import { Row, Col, FormGroup, Label, Input, Form, CustomInput } from "reactstrap";

const VendorModal = ({ show = false, type, formData, setFormData = () => { }, onSubmit = () => { }, onHide = () => { } }) => {
  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target
    setFormData((prevState) => ({
      ...prevState,
      [id]: checked
    }))
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit()
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header className="bg-light" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {type} Vendor
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row form>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Name <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="name"
                  placeholder="Enter Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Category mapping URL <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="categoryMappingUrl"
                  placeholder="Enter URL"
                  value={formData.categoryMappingUrl}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col lg={12} md={12}>
              <FormGroup className="d-flex align-items-center">
                <Label
                  className="font-weight-bolder mb-0"
                  style={{ fontSize: "14px" }}
                >
                  Enable tracker API
                </Label>
                <div className="custom-control custom-switch">
                  <CustomInput
                    type="checkbox"
                    id="enableTrackerApi"
                    value={formData.enableTrackerApi}
                    onChange={handleCheckboxChange}
                    checked={formData.enableTrackerApi}
                    disabled={formData?.name === "bobble-adx"}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
          <Button type='submit' className='btn-success'>Submit</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default VendorModal
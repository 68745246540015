import React, { useState, useEffect, useCallback } from "react";
import ResponseModal from "../ResponseModal";
import { getPermissionsDropdown } from "../../../utilities/apiUtils/permissions";
import { createRole } from "../../../utilities/apiUtils/roles";
import {
  errorDescription,
  validateRoleForm,
  redirectTo,
  error,
  redirectOnTokenExpiry,
} from "../../../utilities/commonUtil";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Routes from "../../../routes/index";

const RoleForm = () => {
  const [roles, setRole] = useState({ roleName: "", roleDescription: "" });
  const [allPermissions, setAllPermissions] = useState([]);
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [failureModal, setFailureModal] = useState({
    display: false,
    text: "",
  });
  const [permissionIDs, setPermissionIDs] = useState([]);

  let fetchPermissions = useCallback(async () => {
    try {
      let permissions = await getPermissionsDropdown();
      if (permissions.length) {
        setAllPermissions(permissions);
      } else if (permissions.errorCode) {
        setFailureModal({
          display: true,
          text: errorDescription(permissions.errorCode),
        });
      }
    } catch (err) {
      setFailureModal({ display: true, text: error.unexpectedError });
    }
  }, []);

  useEffect(() => {
    fetchPermissions();
  }, [fetchPermissions]);

  let handlePermissionChange = (select) => {
    let permissions = [];
    if (select !== null) {
      select.forEach((option) => {
        permissions.push(option.value);
      });
    }
    setPermissionIDs(permissions);
  };

  let hideAlert = (elementId) => {
    document.getElementById(elementId).classList.add("d-none");
  };

  let showAlert = (elementId) => {
    document.getElementById(elementId).classList.remove("d-none");
  };

  let successModalClose = () => {
    setSuccessModalDisplay(true);
    redirectTo(Routes.roles.children.viewRoles.path);
  };

  let handleSubmit = async (event) => {
    event.preventDefault();
    let data = {
      roleName: roles.roleName,
      roleDescription: roles.roleDescription,
      permissionIDs: permissionIDs,
    };
    let role = validateRoleForm(data);
    if (role.isValid) {
      try {
        let response = await createRole(role.data);
        if (response.success) setSuccessModalDisplay(true);
        else if (response.errorCode) {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken
          )
            redirectOnTokenExpiry();
          setFailureModal({
            display: true,
            text: errorDescription(response.errorCode),
          });
        }
      } catch (err) {
        setFailureModal({ display: true, text: error.unexpectedError });
      }
    } else {
      if (!role.hasValidName) {
        showAlert("invalidName");
      }
      if (!role.hasValidDescription) {
        showAlert("invalidDescription");
      }
    }
  };

  return (
    <Card>
      {allPermissions && (
        <CardBody>
          <Form onSubmit={(e) => handleSubmit(e)}>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Name
                    <small id="invalidName" className="text-danger d-none">
                      (Name cannot be empty)
                    </small>
                  </Label>
                  <Input
                    type="text"
                    name="roleName"
                    value={roles.roleName}
                    placeholder="Name"
                    onFocus={() => hideAlert("invalidName")}
                    onChange={(e) =>
                      setRole({ ...roles, roleName: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Permissions</Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name="permissions"
                    onChange={(e) => handlePermissionChange(e)}
                    options={allPermissions}
                    isMulti
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label>
                    Description
                    <small
                      id="invalidDescription"
                      className="text-danger d-none"
                    >
                      (Description cannot be empty)
                    </small>
                  </Label>
                  <Input
                    type="textarea"
                    name="roleDescription"
                    value={roles.roleDescription}
                    placeholder="Description"
                    onFocus={() => hideAlert("invalidDescription")}
                    onChange={(e) =>
                      setRole({ ...roles, roleDescription: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary">Submit</Button>
          </Form>
          <ResponseModal
            show={successModalDisplay}
            onHide={successModalClose}
            modalheading={"Success"}
            modaltext={"Role created successfully."}
          />
          <ResponseModal
            show={failureModal.display}
            onHide={() => setFailureModal({ display: false })}
            modalheading={"Error"}
            modaltext={failureModal.text}
          />
        </CardBody>
      )}
    </Card>
  );
};
const CreateRoleForm = () => (
  <Container fluid className="p-0">
    <h1 className="h3 mb-3">Create a new Role</h1>
    <Row>
      <Col lg="12">
        <RoleForm />
      </Col>
    </Row>
  </Container>
);
export default CreateRoleForm;

import React, { useState, useEffect } from "react";

import {
    Card,
    CardBody,
    Container,
    Button,
    Col,
    Form,
    FormGroup,
    Label,
    Row,
    Input,
} from "reactstrap";
import { BsToggleOn, BsToggleOff } from "react-icons/bs";
import IconButton from "@material-ui/core/IconButton";
import { validateHexCode } from "../../../../utilities/commonUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { X } from "react-feather";
import ToolTip from "../../../../utilities/ToolTip";
import Select, { components } from "react-select";
import {
    redirectTo,
    errorDescription,
    isOperationPermitted,
    permissions,
    redirectOnTokenExpiry,
    error,
    reduceSentence,
} from "../../../../utilities/commonUtil";

import {
    fetchUserSegments,
    createPushNotification,
    fetchPushNotification,
    editPushNotification,
    fetchPushNotificationTargetingRules,
    savePushNotificationTargetingRules
} from "../../../../utilities/apiUtils/pushNotifications";

import Routes from "../../../../routes/index";
import { retrieveFromLocalStorage } from "../../../../utilities/localStorageUtil";

import ConfirmationModal from "../../ConfirmationModal";
import ResponseModal from "../../ResponseModal";
import { layoutTypeOptions, scheduledOptions, schedulingTypes, notificationIntensityOptions, acceptedFormats, landscapeImageError, imageDimensionError, aspectRatioError, invalidAspectRatioError, largeIconSize, smallPictureImageSize, bigPictureImageSize, imageFormatError, fileSizeExceedError, largeIconMaxWidth, largeIconMinWidth, smallIconMaxWidth, smallIconMinWidth, bigPictureMaxHeight, bigPictureMaxWidth } from "../../../../config/ConfigPushNotifications";


const ManagePushNotification = () => {
    const [pushNotification, setPushNotification] = useState({
        layoutType: { value: "standard", label: "Standard" },
        name: null,
        description: null,
        subText: null,
        contentTitle: null,
        contentText: null,
        bigText: null,
        largeIconImageURL: null,
        largeIconImageFile: null,
        largeIconImageBuffer: null,
        largeIconImageName: null,
        showLargeIconImageExpandedMode: false,
        bigPictureImageURL: null,
        bigPictureImageFile: null,
        bigPictureImageBuffer: null,
        bigPictureImageName: null,
        smallPictureImageURL: null,
        smallPictureImageFile: null,
        smallPictureImageBuffer: null,
        smallPictureImageName: null,
        enableCustomTargeting: true,
        priority: { value: 2, label: "Low" },
        actionButtonSettings: {
            textColor: null,
        },
        actionButtons: [

        ],
        action: {
            deeplink: null,
        },
        scheduling: null,
        startDate: null,
        endDate: null,
        startHour: null,
        userSegmentId: null,
        userSegmentVersionId: null,
        publishedAt: null,
        changeLog: null
    });

    const [userSegmentOptions, setUserSegmentOptions] = useState([]);
    const [versionsOptions, setVersionsOptions] = useState([]);
    const [changeType, setChangeType] = useState("Add");
    const [action, setAction] = useState("");
    const [successModalDisplay, setSuccessModalDisplay] = useState(false);
    const [successModalText, setSuccessModalText] = useState("");
    const [failureModalDisplay, setFailureModalDisplay] = useState(false);
    const [failureModalText, setFailureModalText] = useState("");
    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
    const [allowedPermissions, setAllowedPermissions] = useState(
        retrieveFromLocalStorage("userPermissions")
    );

    const requiredComponent = (props) => {
        let required = props.selectProps.required;
        return (
            <components.Input {...props} required={required} />
        );
    };

    useEffect(() => {
        const fetchData = async () => {
            let userSegments = await fetchUserSegments();
            let userSegmentOptions = [];
            if (
                userSegments.errorCode === error.tokenExpired ||
                userSegments.errorCode === error.invalidAccessToken
            )
                redirectOnTokenExpiry();
            if (userSegments?.errorCode || userSegments?.userSegments === undefined) {
                showErrorModal(errorDescription(userSegments?.errorCode));
            } else {
                userSegmentOptions = userSegments.userSegments.map((userSegment) => {
                    return {
                        value: userSegment.id,
                        label: userSegment.id + "-" + reduceSentence(userSegment.name) + "-" + userSegment.type,
                        versions: userSegment.versions,
                    };
                });
                setUserSegmentOptions(userSegmentOptions);
            }

            const urlParams = new URLSearchParams(window.location.search);
            const id = urlParams.get('id');
            if (window.location.pathname.includes("edit") || window.location.pathname.includes("view") || id) {
                let pushNotificationID = id || window.location.pathname.split("/").pop();
                let pushNotification = await fetchPushNotification(pushNotificationID);
                if (pushNotification?.errorCode || pushNotification === undefined) {
                    showErrorModal(errorDescription(pushNotification.error));
                } else {
                    pushNotification = pushNotification.notification;
                    setVersionsOptions(
                        userSegmentOptions.find((option) => option.value === pushNotification.userSegment.id).versions.map((version) => {
                            return {
                                value: version.id,
                                label: version.filename + "-" + version.id,
                                changeLog: version.changeLog
                            };
                        })
                    );
                    setPushNotification({
                        layoutType: layoutTypeOptions.find((option) => option.value === pushNotification?.layoutType),
                        name: pushNotification?.name === undefined ? null : pushNotification?.name,
                        description: pushNotification?.description === undefined ? null : pushNotification?.description,
                        subText: pushNotification?.subtext === undefined ? null : pushNotification?.subtext,
                        contentTitle: pushNotification?.contentTitle === undefined ? null : pushNotification?.contentTitle,
                        contentText: pushNotification?.contentText === undefined ? null : pushNotification?.contentText,
                        bigText: pushNotification?.bigText === undefined ? null : pushNotification?.bigText,
                        largeIconImageURL: pushNotification?.largeIconImageURL === undefined ? null : pushNotification?.largeIconImageURL,
                        largeIconImageFile: null,
                        largeIconImageBuffer: null,
                        // extract last part of url as name
                        largeIconImageName: pushNotification.largeIconImageURL?.split("/").pop() || null,
                        showLargeIconImageExpandedMode: pushNotification?.showLargeIconImageExpandedMode,
                        bigPictureImageURL: pushNotification?.bigPictureImageURL,
                        bigPictureImageFile: null,
                        bigPictureImageBuffer: null,
                        bigPictureImageName: pushNotification.bigPictureImageURL?.split("/").pop() || null,
                        smallPictureImageURL: pushNotification?.smallPictureImageURL === undefined ? null : pushNotification?.smallPictureImageURL,
                        smallPictureImageFile: null,
                        smallPictureImageBuffer: null,
                        smallPictureImageName: pushNotification?.smallPictureImageURL?.split("/").pop() || null,
                        enableCustomTargeting: pushNotification?.enableCustomTargeting,
                        priority: notificationIntensityOptions.find((option) => option.value === Number(pushNotification?.priority)),
                        actionButtonSettings: {
                            textColor: pushNotification?.actionButtonSettings?.textColor === undefined ? null : pushNotification?.actionButtonSettings?.textColor,
                        },
                        actionButtons: pushNotification?.actionButtons,
                        action: pushNotification?.action,
                        startDate: pushNotification?.startDate === undefined ? null : pushNotification?.startDate?.split("T")[0],
                        endDate: pushNotification?.endDate === undefined ? null : pushNotification?.endDate?.split("T")[0],
                        startHour: pushNotification?.startHour === undefined ? null : pushNotification?.startHour,
                        userSegmentId: userSegmentOptions?.find((option) => option.value === pushNotification?.userSegment?.id),
                        userSegmentVersionId: {
                            value: pushNotification?.userSegment?.versions[0]?.id,
                            label: pushNotification?.userSegment?.versions[0]?.filename + "-" + pushNotification?.userSegment?.versions[0]?.id,
                            changeLog: pushNotification?.userSegment?.versions[0]?.changeLog
                        },
                        publishedAt: id !== null && id !== undefined ? null : pushNotification?.publishedAt,
                        scheduling: scheduledOptions.find((option) => option.value === pushNotification?.scheduling),
                        changeLog: pushNotification?.userSegment?.versions[0]?.changeLog
                    });
                }
            }

        };
        fetchData();

        if (window.location.pathname.includes("edit")) {
            setChangeType("Edit");

        } else if (window.location.pathname.includes("view")) {
            setChangeType("View");
        }
    }
        , []);

    let setConfirmationModalState = (event) => {
        event.preventDefault();
        setAction("Submit");
        setConfirmationModalIsOpen(true);
    };

    let showErrorModal = (text) => {
        setFailureModalText(text);
        setFailureModalDisplay(true);
    };

    let successModalClose = async () => {
        setSuccessModalDisplay(false);
        redirectTo(Routes.pushNotification.children.viewPushNotification.path);
    };

    let failureModalClose = () => {
        setFailureModalDisplay(false);
    };

    let disabled = () => {
        if (changeType === "Add") return false;

        return !isOperationPermitted(
            allowedPermissions,
            permissions.notificationEdit
        ) || !(pushNotification.publishedAt === null);
    };

    const validateColor = (color) => {
        if (color !== null && color !== "" && color !== undefined) {
            return validateHexCode(color);
        }
        return true;
    };

    const addActionButton = () => {
        setPushNotification({
            ...pushNotification,
            actionButtons: [
                ...pushNotification.actionButtons,
                {
                    text: null,
                    deeplink: null,
                },
            ],
        });
    };

    const handleSubmit = async () => {
        // check if start date is before end date
        if (pushNotification.startDate && pushNotification.endDate) {
            if (new Date(pushNotification.startDate) > new Date(pushNotification.endDate)) {
                showErrorModal("Start Date cannot be after End Date");
                return;
            }
        }

        // check if start hour is between 0 and 23
        if (pushNotification.startHour) {
            if (pushNotification.startHour < 0 || pushNotification.startHour > 23) {
                showErrorModal("Start Hour must be between 0 and 23");
                return;
            }
        }

        // check if color is valid
        if (pushNotification.actionButtonSettings.textColor) {
            if (!validateColor(pushNotification.actionButtonSettings.textColor)) {
                showErrorModal("Invalid Hex Code for Text Color");
                return;
            }
        }

        let formData = new FormData();
        formData.append("name", pushNotification.name);
        formData.append("description", pushNotification.description);
        formData.append("layoutType", pushNotification.layoutType.value);
        formData.append("showLargeIconImageExpandedMode", pushNotification.showLargeIconImageExpandedMode);
        formData.append("enableCustomTargeting", pushNotification.enableCustomTargeting);
        formData.append("scheduling", pushNotification.scheduling.value);

        if (pushNotification?.userSegmentId?.value) {
            formData.append("userSegmentId", pushNotification.userSegmentId.value);
            formData.append("userSegmentVersionId", pushNotification.userSegmentVersionId.value);
        }

        if (pushNotification?.scheduling?.value !== schedulingTypes.SendOnPublish) {
            if (pushNotification.startDate) {
                formData.append("startDate", pushNotification.startDate);
            }
            if (pushNotification.endDate) {
                formData.append("endDate", pushNotification.endDate);
            }
            if (pushNotification.startHour) {
                formData.append("startHour", pushNotification.startHour);
            }
        }

        if (pushNotification.priority) {
            formData.append("priority", pushNotification.priority.value);
        }
        if (pushNotification.layoutType.value === "standard") {
            if (pushNotification.contentTitle) {
                formData.append("contentTitle", pushNotification.contentTitle);
            }
            if (pushNotification.contentText) {
                formData.append("contentText", pushNotification.contentText);
            }
            if (pushNotification.subText) {
                formData.append("subText", pushNotification.subText);
            }
            if (pushNotification.bigText) {
                formData.append("bigText", pushNotification.bigText);
            }
            if (pushNotification.largeIconImageFile) {
                formData.append("largeIconImage", pushNotification.largeIconImageFile);
            }
            if (pushNotification.largeIconImageName) {
                formData.append("largeIconImageName", pushNotification.largeIconImageName);
            }
            if (pushNotification.bigPictureImageFile) {
                formData.append("bigPictureImage", pushNotification.bigPictureImageFile);
            }
            if (pushNotification.bigPictureImageName) {
                formData.append("bigPictureImageName", pushNotification.bigPictureImageName);
            }
            if (pushNotification.action) {
                formData.append("action", JSON.stringify(pushNotification.action));
            }
            if (pushNotification.actionButtonSettings) {
                formData.append("actionButtonSettings", JSON.stringify(pushNotification.actionButtonSettings));
            }
            if (pushNotification.actionButtons) {
                formData.append("actionButtons", JSON.stringify(pushNotification.actionButtons));
            }
        } else if (pushNotification.layoutType.value === "complete_image") {
            if (pushNotification.subText) {
                formData.append("subText", pushNotification.subText);
            }
            if (pushNotification.bigPictureImageFile) {
                formData.append("bigPictureImage", pushNotification.bigPictureImageFile);
            }
            if (pushNotification.bigPictureImageName) {
                formData.append("bigPictureImageName", pushNotification.bigPictureImageName);
            }
            if (pushNotification.smallPictureImageFile) {
                formData.append("smallPictureImage", pushNotification.smallPictureImageFile);
            }
            if (pushNotification.smallPictureImageName) {
                formData.append("smallPictureImageName", pushNotification.smallPictureImageName);
            }
            if (pushNotification.action) {
                formData.append("action", JSON.stringify(pushNotification.action));
            }
        } else if (pushNotification.layoutType.value === "portrait") {
            if (pushNotification.contentTitle) {
                formData.append("contentTitle", pushNotification.contentTitle);
            }
            if (pushNotification.contentText) {
                formData.append("contentText", pushNotification.contentText);
            }
            if (pushNotification.subText) {
                formData.append("subText", pushNotification.subText);
            }
            if (pushNotification.bigPictureImageFile) {
                formData.append("bigPictureImage", pushNotification.bigPictureImageFile);
            }
            if (pushNotification.bigPictureImageName) {
                formData.append("bigPictureImageName", pushNotification.bigPictureImageName);
            }
            if (pushNotification.action) {
                formData.append("action", JSON.stringify(pushNotification.action));
            }
        }
        if (changeType === "Edit") {
            let id = window.location.pathname.split("/").pop();
            let response = await editPushNotification(id, formData);
            if (response?.errorCode || response === undefined) {
                showErrorModal(response?.errorDescription || errorDescription(response?.errorCode));
            } else {
                setSuccessModalText("Push Notification Edited Successfully");
                setSuccessModalDisplay(true);
            }
            return;
        }
        let response = await createPushNotification(formData);
        if (response?.errorCode || response === undefined) {
            showErrorModal(errorDescription(response?.errorCode));
        } else {
            setSuccessModalText("Push Notification Created Successfully");
            setSuccessModalDisplay(true);
            // get targeting rules for that id
            const urlParams = new URLSearchParams(window.location.search);
            const id = urlParams.get('id');
            let pushNotificationID = response?.id;
            if (id !== null && id !== undefined) {
                let targetingRules = await fetchPushNotificationTargetingRules(id);
                if (targetingRules?.errorCode || targetingRules === undefined) {
                    showErrorModal(response?.errorDescription || errorDescription(response?.errorCode));
                } else {
                    targetingRules = {
                        rules: targetingRules
                    }
                    let response = await savePushNotificationTargetingRules({}, pushNotificationID, targetingRules);
                    if (response?.errorCode || response === undefined) {
                        showErrorModal(response?.errorDescription || errorDescription(response?.errorCode));
                    }
                }

            }
        }
    };


    const resetPushNotification = (value) => {
        setPushNotification({
            layoutType: value,
            name: null,
            description: null,
            subText: null,
            contentTitle: null,
            contentText: null,
            bigText: null,
            largeIconImageURL: null,
            largeIconImageFile: null,
            largeIconImageBuffer: null,
            largeIconImageName: null,
            showLargeIconImageExpandedMode: false,
            bigPictureImageURL: null,
            bigPictureImageFile: null,
            bigPictureImageBuffer: null,
            bigPictureImageName: null,
            smallPictureImageURL: null,
            smallPictureImageFile: null,
            smallPictureImageBuffer: null,
            smallPictureImageName: null,
            enableCustomTargeting: false,
            priority: { value: 2, label: "Low" },
            actionButtonSettings: {
                textColor: null,
            },
            actionButtons: [

            ],
            action: {
                deeplink: null,
            },
            startDate: null,
            endDate: null,
            startHour: null,
            userSegmentId: null,
            userSegmentVersionId: null,
            scheduling: null,
            changeLog: null,
            publishedAt: null,
        });
    }


    return (
        <Container fluid className="p-0">
            <Row className="mb-3">
                <Col md={6} xs={6}>
                    <h1>{changeType} Push Notification</h1>
                </Col>
            </Row>

            <Card>
                <CardBody>
                    <h5 className="text-muted">Note:</h5>
                    <ul>
                        <li>
                            <small className="text-muted">Notification for Scheduling "Send on Publish" and "Scheduled" will be sent only once.</small>
                        </li>
                    </ul>
                </CardBody>
            </Card>

            <Form onSubmit={(event) => setConfirmationModalState(event)}>
                <Card>
                    <CardBody>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}
                                    >
                                        Layout Type
                                    </Label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        name="layoutType"
                                        options={layoutTypeOptions}
                                        value={pushNotification.layoutType}
                                        onChange={(value) => {
                                            resetPushNotification(value);
                                        }}
                                        isDisabled={disabled()}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}
                                    >
                                        Name {" "}<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        type="text"
                                        name="name"
                                        placeholder="Enter Name"
                                        value={pushNotification.name === null ? "" : pushNotification.name}
                                        onChange={(event) => {
                                            setPushNotification({
                                                ...pushNotification,
                                                name: event.target.value,
                                            });
                                        }}
                                        required
                                        disabled={disabled()}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}
                                    >
                                        Description{" "}<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        type="text"
                                        name="description"
                                        placeholder="Enter Description"
                                        value={pushNotification.description === null ? "" : pushNotification.description}
                                        onChange={(event) => {
                                            setPushNotification({
                                                ...pushNotification,
                                                description: event.target.value,
                                            });
                                        }}
                                        required
                                        disabled={disabled()}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}
                                    >
                                        Subtext <ToolTip
                                            data="Brief info to complement the push notification"
                                            id="subText"
                                        />
                                    </Label>
                                    <Input
                                        type="text"
                                        name="subText"
                                        placeholder="Enter Subtext"
                                        value={pushNotification.subText === null ? "" : pushNotification.subText}
                                        onChange={(event) => {
                                            setPushNotification({
                                                ...pushNotification,
                                                subText: event.target.value,
                                            });
                                        }}
                                        disabled={disabled()}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}
                                    >
                                        Content Title{" "}<ToolTip
                                            data="A succinct, attention-grabbing headline conveying the key message of the push notification"
                                            id="contentTitle"
                                        />
                                        {" "}<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        type="text"
                                        name="contentTitle"
                                        placeholder="Enter Content Title"
                                        value={pushNotification.contentTitle === null ? "" : pushNotification.contentTitle}
                                        onChange={(event) => {
                                            setPushNotification({
                                                ...pushNotification,
                                                contentTitle: event.target.value,
                                            });
                                        }}
                                        required
                                        disabled={disabled() || pushNotification.layoutType.value === "complete_image"}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}
                                    >
                                        Content Text <ToolTip
                                            data="Detailed information or message presented in the body of the push notification, providing comprehensive context or actions for the user"
                                            id="contentText"
                                        />
                                        {" "}<span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        type="text"
                                        name="contentText"
                                        placeholder="Enter Content Text"
                                        value={pushNotification.contentText === null ? "" : pushNotification.contentText}
                                        onChange={(event) => {
                                            setPushNotification({
                                                ...pushNotification,
                                                contentText: event.target.value,
                                            });
                                        }}
                                        required
                                        disabled={disabled() || pushNotification.layoutType.value === "complete_image"}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}
                                    >
                                        Big Text
                                    </Label>
                                    <Input
                                        type="text"
                                        name="bigText"
                                        placeholder="Enter Big Text"
                                        value={pushNotification.bigText === null ? "" : pushNotification.bigText}
                                        onChange={(event) => {
                                            setPushNotification({
                                                ...pushNotification,
                                                bigText: event.target.value,
                                            });
                                        }}
                                        disabled={disabled() || pushNotification.layoutType.value !== "standard"}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}
                                    >
                                        Large Icon Image {" "} <ToolTip
                                            data="This is the right-aligned icon specifically utilized for standard notifications"
                                            id="largeIconImage"
                                        />
                                    </Label>
                                    <div className='text-info'><small>Formats: png, jpg, jpeg, webp & MaxSize: 500KB & Dimension Range : 72*72 - 256*256</small></div>
                                    <Container className="custom-file">
                                        <Input
                                            type="file"
                                            className="custom-file-input"
                                            accept="image/png, image/jpg, image/jpeg, image/webp"
                                            disabled={disabled() || pushNotification.layoutType.value !== "standard"}
                                            onChange={(event) => {
                                                const file = event.target.files[0];

                                                if (file) {
                                                    // Check for file size
                                                    if (file.size > largeIconSize) {
                                                        showErrorModal(fileSizeExceedError);
                                                        return;
                                                    }

                                                    // Check if the file format is accepted
                                                    if (!acceptedFormats.includes(file.type)) {
                                                        showErrorModal(imageFormatError);
                                                        return;
                                                    }

                                                    const reader = new FileReader();
                                                    reader.readAsDataURL(file);
                                                    reader.onload = () => {
                                                        const image = new Image();
                                                        image.src = reader.result;
                                                        image.onload = () => {
                                                            // Check if the image is square
                                                            if (image.width !== image.height) {
                                                                showErrorModal(aspectRatioError);
                                                                return;
                                                            }

                                                            if (image.width > largeIconMaxWidth || image.width < largeIconMinWidth) {
                                                                showErrorModal(imageDimensionError);
                                                                return;
                                                            }

                                                            // Update state if all validations pass
                                                            setPushNotification((prev) => ({
                                                                ...prev,
                                                                largeIconImageFile: file,
                                                                largeIconImageName: file.name,
                                                                largeIconImageBuffer: reader.result,
                                                            }));
                                                        };
                                                    };
                                                }
                                            }}

                                        />
                                        <Label className="custom-file-label">
                                            {pushNotification.largeIconImageName === null
                                                ? "Choose an image"
                                                : pushNotification.largeIconImageName}
                                        </Label>
                                    </Container>
                                    {(pushNotification.largeIconImageBuffer !== null ||
                                        pushNotification.largeIconImageURL) && (
                                            <Col className="pb-4 mb-3 mt-3">
                                                <img
                                                    src={
                                                        pushNotification.largeIconImageBuffer ||
                                                        pushNotification.largeIconImageURL
                                                    }
                                                    alt="preview"
                                                    width="100"
                                                />
                                            </Col>
                                        )}
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}
                                    >
                                        Big Picture Image  {" "} <ToolTip
                                            data="In full image mode, it acts as the expanded image. Note: If both big text and big picture URL are sent in standard mode, only the big picture will be displayed"
                                            id="bigPictureImage"
                                        /> {" "} {pushNotification.layoutType.value !== "standard" && <span className="text-danger">*</span>}
                                    </Label>
                                    <div className='text-info'><small>Formats: png, jpg, jpeg, webp & MaxSize: 500KB & Dimension : 1024 x 512</small></div>
                                    <Container className="custom-file">
                                        <Input
                                            type="file"
                                            className="custom-file-input"
                                            accept="image/png, image/jpg, image/jpeg, image/webp"
                                            disabled={disabled()}
                                            onChange={(event) => {
                                                const file = event.target.files[0];

                                                if (file) {
                                                    // Check for file size
                                                    if (file.size > bigPictureImageSize) {
                                                        showErrorModal(fileSizeExceedError);
                                                        return;
                                                    }

                                                    // Check if the file format is accepted
                                                    if (!acceptedFormats.includes(file.type)) {
                                                        showErrorModal(imageFormatError);
                                                        return;
                                                    }

                                                    const reader = new FileReader();
                                                    reader.readAsDataURL(file);
                                                    reader.onload = () => {
                                                        const image = new Image();
                                                        image.src = reader.result;
                                                        image.onload = () => {
                                                            // Check if the image is square
                                                            if (image.width < image.height) {
                                                                showErrorModal(landscapeImageError);
                                                                return;
                                                            }

                                                            if (image.width > bigPictureMaxWidth || image.height > bigPictureMaxHeight) {
                                                                showErrorModal(imageDimensionError);
                                                                return;
                                                            }

                                                            // Update state if all validations pass
                                                            setPushNotification({
                                                                ...pushNotification,
                                                                bigPictureImageFile: file,
                                                                bigPictureImageName: file.name,
                                                                bigPictureImageBuffer: reader.result,
                                                            });
                                                        };
                                                    };
                                                }
                                            }}
                                            required={
                                                (pushNotification.bigPictureImageURL ===
                                                    undefined ||
                                                    pushNotification.bigPictureImageURL === null ||
                                                    pushNotification.bigPictureImageURL === "") && pushNotification.layoutType.value !== "standard"
                                            }
                                        />
                                        <Label className="custom-file-label">
                                            {pushNotification.bigPictureImageName === null
                                                ? "Choose an image"
                                                : pushNotification.bigPictureImageName}
                                        </Label>
                                    </Container>
                                    {(pushNotification.bigPictureImageBuffer !== null ||
                                        pushNotification.bigPictureImageURL) && (
                                            <Col className="pb-4 mb-3 mt-3">
                                                <img
                                                    src={
                                                        pushNotification.bigPictureImageBuffer ||
                                                        pushNotification.bigPictureImageURL
                                                    }
                                                    alt="preview"
                                                    width="100"
                                                />
                                            </Col>
                                        )}
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}
                                    >
                                        Small Picture Image {" "} <ToolTip
                                            data="Required for the complete image style, utilized in non-expanded mode"
                                            id="smallPictureImage"
                                        /> {" "} {pushNotification.layoutType.value === "complete_image" && <span className="text-danger">*</span>}
                                    </Label>
                                    <div className='text-info'><small>Formats: png, jpg, jpeg, webp & MaxSize: 500KB & Dimension Range : 48*48 - 96*96</small></div>
                                    <Container className="custom-file">
                                        <Input
                                            type="file"
                                            className="custom-file-input"
                                            accept="image/png, image/jpg, image/jpeg, image/webp"
                                            disabled={disabled() || pushNotification.layoutType.value === "standard" || pushNotification.layoutType.value === "portrait"}
                                            onChange={(event) => {
                                                const file = event.target.files[0];

                                                if (file) {
                                                    // Check for file size
                                                    if (file.size > smallPictureImageSize) {
                                                        showErrorModal(fileSizeExceedError);
                                                        return;
                                                    }

                                                    // Check if the file format is accepted
                                                    if (!acceptedFormats.includes(file.type)) {
                                                        showErrorModal(imageFormatError);
                                                        return;
                                                    }

                                                    const reader = new FileReader();
                                                    reader.readAsDataURL(file);
                                                    reader.onload = () => {
                                                        const image = new Image();
                                                        image.src = reader.result;
                                                        image.onload = () => {
                                                            // Check if the image is square
                                                            if (image.width !== image.height) {
                                                                showErrorModal(aspectRatioError);
                                                                return;
                                                            }

                                                            if (image.width > smallIconMaxWidth || image.width < smallIconMinWidth) {
                                                                showErrorModal(imageDimensionError);
                                                                return;
                                                            }

                                                            // Update state if all validations pass
                                                            setPushNotification({
                                                                ...pushNotification,
                                                                smallPictureImageFile: file,
                                                                smallPictureImageName: file.name,
                                                                smallPictureImageBuffer: reader.result,
                                                            });
                                                        };
                                                    };
                                                }
                                            }}
                                            required={
                                                pushNotification.smallPictureImageURL ===
                                                undefined ||
                                                pushNotification.smallPictureImageURL === null ||
                                                pushNotification.smallPictureImageURL === ""
                                            }
                                        />
                                        <Label className="custom-file-label">
                                            {pushNotification.smallPictureImageName === null
                                                ? "Choose an image"
                                                : pushNotification.smallPictureImageName}
                                        </Label>
                                    </Container>
                                    {(pushNotification.smallPictureImageBuffer !== null ||
                                        pushNotification.smallPictureImageURL) && (
                                            <Col className="pb-4 mb-3 mt-3">
                                                <img
                                                    src={
                                                        pushNotification.smallPictureImageBuffer ||
                                                        pushNotification.smallPictureImageURL
                                                    }
                                                    alt="preview"
                                                    width="100"
                                                />
                                            </Col>
                                        )}
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}
                                    >
                                        Notification Intensity <ToolTip
                                            data="Select a priority level (High, Medium, Low):
                                                - High: Makes a sound, vibrates, and appears on top of other apps.
                                                - Medium: Makes a sound and vibrates.
                                                - Low: No sound, no vibration."
                                            id="priority"
                                        />
                                    </Label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        name="priority"
                                        options={notificationIntensityOptions}
                                        value={pushNotification.priority}
                                        onChange={(event) => {
                                            setPushNotification({
                                                ...pushNotification,
                                                priority: event,
                                            });
                                        }}
                                        isDisabled={disabled()}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}
                                    >
                                        Scheduling{" "}
                                        {pushNotification?.scheduling?.value && (
                                            <ToolTip
                                                data={pushNotification?.scheduling?.value === schedulingTypes.SendOnPublish ? `Instantly sends the content as soon as it is published.` : pushNotification?.scheduling?.value === schedulingTypes.Scheduled ? `Allows setting a specific date and time for sending the content.` : `Sends content at regular intervals based on a predefined schedule.`}
                                                id="scheduling"
                                            />
                                        )}
                                        {" "} <span className="text-danger">*</span>
                                    </Label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        name="scheduling"
                                        options={scheduledOptions.filter(option => option.label !== "All")}
                                        value={pushNotification.scheduling}
                                        components={{
                                            Input: requiredComponent,
                                        }}
                                        onChange={(value) => {
                                            setPushNotification({
                                                ...pushNotification,
                                                scheduling: value,
                                                startDate: null,
                                                endDate: null,
                                                startHour: null,
                                            });
                                        }}
                                        required={pushNotification.scheduling === null}
                                        isDisabled={disabled() || pushNotification.publishedAt !== null}
                                    />
                                </FormGroup>
                            </Col>

                            {pushNotification?.scheduling !== null && pushNotification?.scheduling?.value !== schedulingTypes.SendOnPublish && (
                                <>
                                    {pushNotification?.scheduling?.value !== schedulingTypes.Scheduled && (
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label
                                                    className="font-weight-bolder mb-2"
                                                    style={{ fontSize: "16px" }}
                                                >
                                                    Start Date {" "} <span className="text-danger">*</span>
                                                </Label>
                                                <Input
                                                    type="date"
                                                    name="startDate"
                                                    placeholder="Enter Start Date"
                                                    value={pushNotification.startDate === null ? "" : pushNotification.startDate}
                                                    onChange={(event) => {
                                                        setPushNotification({
                                                            ...pushNotification,
                                                            startDate: event.target.value,
                                                        });
                                                    }}
                                                    required
                                                    disabled={disabled() || pushNotification?.scheduling?.value === schedulingTypes.SendOnPublish || pushNotification?.scheduling?.value === schedulingTypes.Scheduled}
                                                />
                                            </FormGroup>
                                        </Col>
                                    )}

                                    <Col md={6}>
                                        <FormGroup>
                                            <Label
                                                className="font-weight-bolder mb-2"
                                                style={{ fontSize: "16px" }}
                                            >
                                                {pushNotification?.scheduling?.value === schedulingTypes.Scheduled ? "Date" : "End Date"} {" "} <span className="text-danger">*</span>
                                            </Label>
                                            <Input
                                                type="date"
                                                name="endDate"
                                                placeholder={pushNotification?.scheduling?.value === schedulingTypes.Scheduled ? "Date" : "End Date"}
                                                value={pushNotification.endDate === null ? "" : pushNotification.endDate}
                                                onChange={(event) => {
                                                    setPushNotification({
                                                        ...pushNotification,
                                                        endDate: event.target.value,
                                                    });
                                                    if (pushNotification?.scheduling?.value === schedulingTypes.Scheduled) {
                                                        setPushNotification({
                                                            ...pushNotification,
                                                            startDate: event.target.value,
                                                            endDate: event.target.value,
                                                        });
                                                    }
                                                }}
                                                required
                                                disabled={disabled() || pushNotification?.scheduling?.value === schedulingTypes.SendOnPublish}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md={6}>
                                        <FormGroup>
                                            <Label
                                                className="font-weight-bolder mb-2"
                                                style={{ fontSize: "16px" }}>
                                                Start Hour {" "} <small className="text-info">(in IST)</small><span className="text-danger">*</span>
                                            </Label>
                                            <Input
                                                type="number"
                                                name="startHour"
                                                placeholder="Enter Start Hour"
                                                min="0"
                                                max="23"
                                                value={pushNotification.startHour === null ? "" : pushNotification.startHour}
                                                onChange={(event) => {
                                                    setPushNotification({
                                                        ...pushNotification,
                                                        startHour: event.target.value,
                                                    });
                                                }}
                                                required
                                                disabled={disabled() || pushNotification?.scheduling?.value === schedulingTypes.SendOnPublish}
                                            />
                                        </FormGroup>
                                    </Col>
                                </>
                            )}

                            <Col md={6}>
                                <FormGroup>
                                    <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}>
                                        User Segment {" "} <span className="text-danger">*</span>
                                    </Label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        name="userSegmentId"
                                        options={userSegmentOptions}
                                        value={pushNotification.userSegmentId}
                                        components={{
                                            Input: requiredComponent,
                                        }}
                                        onChange={(value) => {
                                            setPushNotification({
                                                ...pushNotification,
                                                userSegmentId: value,
                                                userSegmentVersionId: null,
                                                changeLog: null
                                            });

                                            if (value?.versions) {
                                                setVersionsOptions(
                                                    value.versions.map((version) => {
                                                        return {
                                                            value: version.id,
                                                            label: version.filename + "-" + version.id,
                                                            changeLog: version.changeLog
                                                        };
                                                    })
                                                );
                                            }

                                        }}
                                        required={pushNotification.userSegmentId === null}
                                        isDisabled={disabled() || pushNotification.publishedAt !== null}
                                    />
                                </FormGroup>
                            </Col>


                            <Col md={6}>
                                <FormGroup>
                                    <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}>
                                        User Segment Version {" "} <span className="text-danger">*</span>
                                    </Label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        name="userSegmentVersionId"
                                        options={versionsOptions}
                                        value={pushNotification.userSegmentVersionId}
                                        onChange={(value) => {
                                            console.log(value)
                                            setPushNotification({
                                                ...pushNotification,
                                                userSegmentVersionId: value,
                                                changeLog: value.changeLog
                                            });
                                        }}
                                        components={{
                                            Input: requiredComponent,
                                        }}
                                        required={pushNotification.userSegmentVersionId === null}
                                        isDisabled={disabled() || pushNotification.publishedAt !== null || pushNotification.userSegmentId === null}
                                    />
                                </FormGroup>
                            </Col>

                            {pushNotification.changeLog !== null && <Col md={12}>
                                <FormGroup>
                                    <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}>
                                        Change Log
                                    </Label>
                                    <Input
                                        type="textarea"
                                        name="changeLog"
                                        disabled
                                        value={pushNotification.changeLog === null ? "" : pushNotification.changeLog}
                                    />
                                </FormGroup>
                            </Col>}
                            <Col md={6}>
                                <FormGroup >
                                    <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}>
                                        Show Large Icon Image Expanded Mode
                                        {" "}<ToolTip
                                            data="This toggle determines whether the large icon image should be displayed in expanded mode"
                                            id="showLargeIconImageExpandedMode"
                                        />
                                    </Label>
                                    <IconButton
                                        onClick={() => {
                                            setPushNotification({
                                                ...pushNotification,
                                                showLargeIconImageExpandedMode: !pushNotification.showLargeIconImageExpandedMode,
                                            });
                                        }}
                                        disabled={disabled()}
                                    >
                                        {pushNotification.showLargeIconImageExpandedMode ? (
                                            <BsToggleOn size={30} />
                                        ) : (
                                            <BsToggleOff size={30} />
                                        )}
                                    </IconButton>
                                </FormGroup>
                            </Col>

                            <Col md={12}>
                                <h3>Action</h3>
                            </Col>

                            <Col md={6}>
                                <FormGroup >
                                    <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}>
                                        Deeplink {" "}<ToolTip
                                            data="Deeplink for  default action upon tapping a notification"
                                            id="deeplink"
                                        />
                                        <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        type="text"
                                        name="deeplink"
                                        placeholder="Enter Deeplink"
                                        value={pushNotification.action.deeplink === null ? "" : pushNotification.action.deeplink}
                                        onChange={(event) => {
                                            setPushNotification({
                                                ...pushNotification,
                                                action: {
                                                    ...pushNotification.action,
                                                    deeplink: event.target.value,
                                                },
                                            });
                                        }}
                                        disabled={disabled()}
                                        required
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={6}>
                            </Col>

                            <Col md={12}>
                                <h3>CTA Button Settings</h3>
                            </Col>

                            <Col md={6}>
                                <FormGroup >
                                    <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "16px" }}>
                                        Text Color {" "}<ToolTip
                                            data="Text color for CTA buttons"
                                            id="textColor"
                                        /> {" "}
                                        : {" "} {pushNotification.actionButtons.length > 0 && <span className="text-danger">*</span>}{" "}
                                        {validateColor(pushNotification.actionButtonSettings.textColor) ? (<div
                                            style={{
                                                backgroundColor:
                                                    pushNotification.actionButtonSettings.textColor,
                                                width: "16px",
                                                height: "16px",
                                                display: "inline-block",
                                                verticalAlign: "middle",
                                                border: "1px solid #000",
                                                borderRadius: "30%",
                                            }}
                                        >
                                            {" "}
                                        </div>) : (
                                            <small className="text-danger ml-2">
                                                {"Invalid Hex Code"}
                                            </small>
                                        )}
                                    </Label>
                                    <Input
                                        type="text"
                                        name="textColor"
                                        placeholder="Enter Text Color"
                                        value={pushNotification.actionButtonSettings.textColor === null ? "" : pushNotification.actionButtonSettings.textColor}
                                        onChange={(event) => {
                                            setPushNotification({
                                                ...pushNotification,
                                                actionButtonSettings: {
                                                    ...pushNotification.actionButtonSettings,
                                                    textColor: event.target.value,
                                                },
                                            });
                                        }}
                                        disabled={disabled() || pushNotification.layoutType.value !== "standard"}
                                        required={pushNotification.actionButtons.length > 0}
                                        invalid={!validateColor(pushNotification.actionButtonSettings.textColor)}
                                    />
                                </FormGroup>
                            </Col>

                        </Row>
                    </CardBody>
                </Card>

                <Row>
                    <Col md={6}>
                        <h1 className="h3 mb-3">CTA Buttons</h1>
                    </Col>
                    <Col md={6}>
                        <Button className="float-right" onClick={addActionButton} disabled={disabled() || pushNotification.layoutType.value !== "standard" || pushNotification.actionButtons.length >= 3}>
                            <FontAwesomeIcon size="sm" icon={faPlus}></FontAwesomeIcon>
                            {" "}Add CTA Buttons
                        </Button>
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        {pushNotification?.actionButtons?.map((item, index) => (
                            <Card key={`card-${index}`} className="p-3 mb-5" style={{
                                boxShadow: '0 0 20px rgba(0,0,0,0.4)',  // Increased shadow
                                borderRadius: '15px'
                            }}>
                                <Row key={`row-${index}`}>
                                    <Col>
                                        <FormGroup>
                                            <Label>
                                                <strong>
                                                    Text <span className="text-danger">*</span>
                                                </strong>
                                            </Label>
                                            <Input
                                                type="text"
                                                name="text"
                                                value={item.text}
                                                placeholder="Enter Text"
                                                onChange={(option) => {
                                                    let temp = [...pushNotification.actionButtons];
                                                    temp[index].text = option.target.value;
                                                    setPushNotification({
                                                        ...pushNotification,
                                                        actionButtons: temp,
                                                    });
                                                }
                                                }
                                                required
                                                disabled={disabled() || pushNotification.layoutType.value !== "standard"}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        {!(disabled()) && (
                                            <X
                                                className="float-right"
                                                size={20}
                                                color="red"
                                                style={{ cursor: "pointer" }}
                                                onClick={(e) => {
                                                    let temp = [...pushNotification.actionButtons];
                                                    temp = temp.filter((_, idx) => idx !== index);
                                                    setPushNotification({
                                                        ...pushNotification,
                                                        actionButtons: temp,
                                                    });
                                                }}
                                            ></X>
                                        )}
                                    </Col>
                                </Row>
                                <Row key={`row-${index + 7}`}>
                                    <Col>
                                        <FormGroup>
                                            <Label>
                                                <strong>
                                                    Deeplink <span className="text-danger">*</span>
                                                </strong>
                                            </Label>
                                            <Input
                                                type="textarea"
                                                name="deeplink"
                                                value={item.deeplink}
                                                placeholder="Enter Deeplink"
                                                onChange={(option) => {
                                                    let temp = [...pushNotification.actionButtons];
                                                    temp[index].deeplink = option.target.value;
                                                    setPushNotification({
                                                        ...pushNotification,
                                                        actionButtons: temp,
                                                    });
                                                }}
                                                required
                                                disabled={disabled() || pushNotification.layoutType.value !== "standard"}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </Card>


                        ))}
                    </CardBody>
                </Card>

                {!(disabled()) && (
                    <Button
                        type="submit"
                        className="float-left"
                        color="primary"
                        disabled={disabled()}
                    >
                        Submit
                    </Button>
                )}
            </Form>
            <ConfirmationModal
                show={confirmationModalIsOpen}
                modaltext={action}
                onConfirmationTrue={() => {
                    setConfirmationModalIsOpen(false);
                    if (action === "Submit") handleSubmit();
                }}
                onHide={() => setConfirmationModalIsOpen(false)}
            />

            <ResponseModal
                show={successModalDisplay}
                onHide={successModalClose}
                modalheading={"Success"}
                modaltext={successModalText}
            />
            <ResponseModal
                show={failureModalDisplay}
                onHide={failureModalClose}
                modalheading={"Error"}
                modaltext={failureModalText}
            />
        </Container>
    )
}

export default ManagePushNotification;
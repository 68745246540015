import React from "react";
import { connect } from "react-redux";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import { withRouter } from "react-router-dom";
import {
  removeFromLocalStorage,
  retrieveFromLocalStorage,
} from "../utilities/localStorageUtil";
import { authRoutes } from "../routes/index";
import { redirectTo } from "../utilities/commonUtil";

import {
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { Settings } from "react-feather";

class NavbarComponent extends React.Component {
  handleLogout = (event) => {
    removeFromLocalStorage("token");
    removeFromLocalStorage("username");
    this.props.history.push(authRoutes.login.path);
  };

  render() {
    const { dispatch } = this.props;
    return (
      <Navbar color="white" light expand>
        <span
          className="sidebar-toggle d-flex mr-2"
          onClick={() => {
            dispatch(toggleSidebar());
          }}
        >
          <i className="hamburger align-self-center" />
        </span>

        <Collapse navbar>
          <Nav className="ml-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
              <span className="d-inline-block d-sm-none">
                <DropdownToggle nav caret>
                  <Settings size={18} className="align-middle" />
                </DropdownToggle>
              </span>
              <span className="d-none d-sm-inline-block">
                <DropdownToggle nav caret>
                  <span className="text-dark">
                    {retrieveFromLocalStorage("username")}
                  </span>
                </DropdownToggle>
              </span>
              <DropdownMenu right>
                <DropdownItem onClick={() => redirectTo("/user/profile")}>
                  Profile
                </DropdownItem>
                <DropdownItem onClick={this.handleLogout}>
                  Sign Out
                </DropdownItem>
                <DropdownItem disabled={true}>
                  <small className="text-muted">v5.0</small>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

export default withRouter(
  connect((store) => ({
    app: store.app,
  }))(NavbarComponent)
);

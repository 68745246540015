import React, { useState } from "react";
import { Card, CardBody, Container, Row, Col, Button, Form, Label, FormGroup, Input } from "reactstrap";
import Papa from "papaparse";
import ResponseModal from "../../../../pages/ui-elements/ResponseModal";
import ConfirmationModal from "../../../../pages/ui-elements/ConfirmationModal";
import { errorModalHeading } from "../../../../config/config";
import { testPushNotification } from "../../../../utilities/apiUtils/pushNotifications";
import { validateJSON } from "../../../../utilities/commonUtil";
import { clientOptions } from "../../../../config/ConfigPushNotifications";
import Select from "react-select";

const TestPushNotification = () => {
    const [gcmIDsFileName, setGcmIDsFileName] = useState(null);
    const [gcmIDs, setGcmIDs] = useState([]);
    const [appID, setAppID] = useState({
        value: "7wZFJWA5chjgat68y826IAIKQ6s197RM",
        label: "Bobble Android"
    });
    const [gcmIDsJSON, setGcmIDsJSON] = useState("");
    const [failureModal, setFailureModal] = useState({
        display: false,
        text: "",
    });
    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
    const [action, setAction] = useState("");
    const [successModalDisplay, setSuccessModalDisplay] = useState(false);
    const [successModalText, setSuccessModalText] = useState("");
    const [switchToGCMJsonField, setSwitchToGCMJsonField] = useState(false);

    let failureModalClose = () => {
        setFailureModal({ ...failureModal, display: false });
    };

    let setConfirmationModalState = (event) => {
        event.preventDefault();
        setAction("Submit");
        setConfirmationModalIsOpen(true);
    };

    let successModalClose = async () => {
        setSuccessModalDisplay(false);
    };

    const handleClientChange = (selectedOption) => {
        if (selectedOption && selectedOption.value) {
          setAppID({ label: selectedOption.label, value: selectedOption.value });
        }
      };

    let handleSubmit = async () => {

        let payload = {
            "gcmIDs": gcmIDs,
            "appID": appID?.value
        };


        let id = window.location.href.split("/").pop();

        let response = await testPushNotification(id, payload);
        if (response?.status === "success") {
            let text = "Notification sent successfully";
            setSuccessModalText(text);
            setSuccessModalDisplay(true);
        } else {
            let text = response?.errorDescription ?? "Something went wrong";
            setFailureModal((prevState) => ({
                ...prevState,
                display: true,
                text: text,
            }));
        };
    };

    return (
        <Container fluid className="p-0">
            <Row className="mb-3">
                <Col md={6} xs={6}>
                    <h1>Test Notification</h1>
                    <span className="text-warning font-weight-bold" style={{ fontSize: 13 }}>⚠️ Only top 500 GCM IDs will be notified</span>
                </Col>
            </Row>


            <Form onSubmit={(event) => setConfirmationModalState(event)}>
                <Card>
                    <CardBody>
                        <div>
                            <p className="font-weight-bolder mb-2" style={{ fontSize: "14px" }}>GCM IDs Count: {gcmIDs.length}</p>
                        </div>
                        <hr
                            style={{
                                width: "auto",
                                color: "black",
                            }}
                        />
                        <Row>
                            <Col md={3}>
                                <FormGroup>
                                    <Label
                                        className="font-weight-bolder mb-2"
                                        style={{ fontSize: "14px" }}
                                    >
                                        Select Client
                                    </Label>
                                    <Select
                                        id="client"
                                        name="client"
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        value={appID}  
                                        onChange={handleClientChange} 
                                        options={clientOptions}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12} xs={12}>
                                <FormGroup>
                                    <Label>
                                        <strong>
                                            GCM IDs List <small className="text-info">(Formats: csv)</small> {!validateJSON(gcmIDsJSON) ? (
                                                <small className="text-danger">
                                                    (Enter valid JSON value)
                                                </small>
                                            ) : null}{" "}
                                        </strong>
                                        <p style={{ fontSize: 12, marginBottom: 0 }}>
                                            Use this template <a href={"/csv/gcmIDs.csv"} download={"gcmIDs.csv"}>
                                                {"Sample gcmIDs List"}
                                            </a>
                                        </p>
                                    </Label>
                                    <Button
                                        className="float-right btn-sm"
                                        onClick={(e) => {
                                            setSwitchToGCMJsonField(!switchToGCMJsonField);
                                            setGcmIDsJSON("");
                                            setGcmIDsFileName(null);
                                            setGcmIDs([]);
                                        }
                                        }
                                    >
                                        {switchToGCMJsonField ? "Select CSV file" : "Enter GCM IDs Manually"}
                                    </Button>
                                    <br />

                                    {!switchToGCMJsonField ? (
                                        <Container className="custom-file" style={{ width: 300, marginLeft: "inherit" }}>
                                            <Input
                                                type="file"
                                                className="custom-file-input"
                                                name="gcmIDs"
                                                id="gcmIDs"
                                                placeholder="GCM IDs"
                                                required
                                                accept=".csv"
                                                onChange={(event) => {
                                                    const file = event.target.files[0];
                                                    if (file?.name?.endsWith(".csv")) {
                                                        Papa.parse(file, {
                                                            complete: function (results) {
                                                                // read at max 500 gcmIDs from the csv file
                                                                let gcmIDs = [];
                                                                if (results.data[0][0] === 'gcmIDs') {
                                                                    setGcmIDsFileName(file.name);
                                                                    for (let i = 1; i < Math.min(results.data.length, 501); i++) {
                                                                        if (results.data[i][0].length > 0)
                                                                            gcmIDs.push(results.data[i][0]);
                                                                    }
                                                                    setGcmIDs(gcmIDs);
                                                                } else {
                                                                    setFailureModal((prevState) => ({
                                                                        ...prevState,
                                                                        display: true,
                                                                        text: "Please upload file in accepted format"
                                                                    }))
                                                                    setGcmIDsFileName(null);
                                                                    return;
                                                                }
                                                            },
                                                        });
                                                    } else {
                                                        setFailureModal((prevState) => ({
                                                            ...prevState,
                                                            display: true,
                                                            text: "Please upload file in accepted format"
                                                        }))
                                                        setGcmIDsFileName(null);
                                                        return;
                                                    }
                                                }}
                                            />
                                            <Label className="custom-file-label">
                                                <strong>{gcmIDsFileName ?? "Choose a file"}</strong>
                                            </Label>
                                        </Container>
                                    ) : (
                                        <Input
                                            type="textarea"
                                            name="gcmIDs"
                                            id="gcmIDs"
                                            placeholder='["gcmID1", "gcmID2", ...]'
                                            required
                                            value={gcmIDsJSON}
                                            style={{ height: "10rem" }}
                                            onChange={(event) => {
                                                try {
                                                    if (validateJSON(event.target.value)) {
                                                        // extract gcmIDs from the json
                                                        let gcmIDs = JSON.parse(event.target.value);
                                                        if (gcmIDs !== undefined) {
                                                            // read at max 500 gcmIDs from the json
                                                            setGcmIDs(gcmIDs.slice(0, 500));
                                                        }
                                                    }
                                                } catch (e) {
                                                    console.error(e);
                                                }

                                                setGcmIDsJSON(event.target.value);
                                            }}
                                        />
                                    )}

                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <Button color="primary" type="submit" className="mt-2">
                    Submit
                </Button>

                <ConfirmationModal
                    show={confirmationModalIsOpen}
                    modaltext={action}
                    onConfirmationTrue={() => {
                        setConfirmationModalIsOpen(false);
                        if (action === "Submit") handleSubmit();
                    }}
                    onHide={() => setConfirmationModalIsOpen(false)}
                />
                <ResponseModal
                    show={failureModal.display}
                    onHide={failureModalClose}
                    modalheading={errorModalHeading}
                    modaltext={failureModal.text}
                />
                <ResponseModal
                    show={successModalDisplay}
                    onHide={successModalClose}
                    modalheading={"Success"}
                    modaltext={successModalText}
                />
            </Form>


        </Container>
    )
};

export default TestPushNotification;
import { retrieveFromLocalStorage } from "../utilities/localStorageUtil";
import { permissions } from "../utilities/commonUtil";
import moment from "moment";

const allowedPermissions = retrieveFromLocalStorage("userPermissions");

export const taasAllowedEndpoints = ["/healthcheck", "/v4/contents/search", "/v4/popText", "/v4/animatedStickers", "/v4/stickers", "/v4/merchandise/stickers", "/v4/stories", "/v4/head", "/v4/enhanceImage"];
export let config = {
    searchByDefault: "id",
    keypressDown: 13,
    debounceTime: 1000,
};
export const createMusicalStory = {
    gender1: "female",
    imageUrl1: "https://img.lovepik.com/free-png/20211105/lovepik-young-business-women-png-image_400317477_wh1200.png",
    gender2: "male",
    imageUrl2: "https://i.pinimg.com/originals/51/e0/d5/51e0d5aa27808ce689e3dd5a5cd7685a.png",
    userId: "taas-dashboard",
    bounce: "false",
    vibrate: "false",
    logoUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/2560px-Google_2015_logo.svg.png",
    videoScale: "0.5",
}
export const contentPrefrencesOptions = [
    { value: "custom", label: "Custom" },
    { value: "all", label: "All" },
];
export const booleanOptions = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
];
export const genderOptions = [
    { value: "unisex", label: "Unisex" },
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
];
export const typeOfStoryOptions = [
    { value: "static", label: "Static" },
    { value: "musical", label: "Musical" },
];
export let clientResponses = {
    addedSuccessfully: "Client added successfully",
    updatedSuccessfully: "Client updated successfully",
    activatedSuccessfully: "Client activated successfully",
    deactivatedSuccessfully: "Client deactivated successfully",
}
export let partnerResponses = {
    addedSuccessfully: "Partner added successfully",
}
export let stickerResponses = {
    addedSuccessfully: "Sticker added successfully",
    updatedSuccessfully: "Sticker updated successfully",
    activatedSuccessfully: "Sticker activated successfully",
    deactivatedSuccessfully: "Sticker deactivated successfully",
}
export let poptextStickerResponses = {
    addedSuccessfully: "Poptext Sticker added successfully",
    updatedSuccessfully: "Poptext Sticker updated successfully",
    activatedSuccessfully: "Poptext Sticker activated successfully",
    deactivatedSuccessfully: "Poptext Sticker deactivated successfully",
}
export let storyResponses = {
    addedSuccessfully: "Story added successfully",
    updatedSuccessfully: "Story updated successfully",
    activatedSuccessfully: "Story activated successfully",
    deactivatedSuccessfully: "Story deactivated successfully",
}
export let showEditClientButton = () => {
    return allowedPermissions.includes(permissions.taasClientManage);
};
export let showActivateClientButton = () => {
    return allowedPermissions.includes(permissions.taasClientManage);
};
export let showDeactivateClientButton = () => {
    return allowedPermissions.includes(permissions.taasClientManage);
};
export let showCreateClientButton = () => {
    return allowedPermissions.includes(permissions.taasClientManage);
};
export let showViewStatisticsButton = () => {
    return allowedPermissions.includes(permissions.taasStatisticsViewer);
};
export let showEditStickerButton = () => {
    return allowedPermissions.includes(permissions.taasStickerManage);
};
export let showActivateStickerButton = () => {
    return allowedPermissions.includes(permissions.taasStickerManage);
};
export let showDeactivateStickerButton = () => {
    return allowedPermissions.includes(permissions.taasStickerManage);
};
export let showCreateStickerButton = () => {
    return allowedPermissions.includes(permissions.taasStickerManage);
};
export let showEditStoryButton = () => {
    return allowedPermissions.includes(permissions.taasStoryManage);
};
export let showActivateStoryButton = () => {
    return allowedPermissions.includes(permissions.taasStoryManage);
};
export let showDeactivateStoryButton = () => {
    return allowedPermissions.includes(permissions.taasStoryManage);
};
export let showCreateStoryButton = () => {
    return allowedPermissions.includes(permissions.taasStoryManage);
};
export let showGenerateApiKeyButton = () => {
    return allowedPermissions.includes(permissions.taasClientApiKeyManage);
};
export let formatTime = (date) => {
    if (date === null) {
        return "N/A";
    }
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
};
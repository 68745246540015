import React, { useEffect, useState } from 'react'
import Select from "react-select";
import { Container, Row, Col, Card, CardBody, CardImg, Button, FormGroup, Label } from "reactstrap";
import Reorder, { reorder } from "react-reorder";
import Loading from "../../../components/Loading";
import ResponseModal from '../ResponseModal';
import { successModalHeading } from '../../../config';
import { errorModalHeading } from '../../../config/config';
import { error, errorDescription, redirectOnTokenExpiry } from '../../../utilities/commonUtil';
import { fetchSuggestionDrawerCategoryOrderData, updateSuggestionDrawerCategoryOrderData } from '../../../utilities/apiUtils/suggestionDrawer'
import { showSuggestionDrawerCategoryOrder } from '../../../config/configSuggestionDrawer';
const CategoryContentOrder = () => {
  const [loading, setLoading] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);

  const [suggestionDrawerCategories, setSuggestionDrawerCategories] = useState([])

  useEffect(() => {
    getSuggestionDrawerCategoryContentOrder()
  }, [])

  const getSuggestionDrawerCategoryContentOrder = async () => {
    setLoading(true)
    let response = await fetchSuggestionDrawerCategoryOrderData()
    setSuggestionDrawerCategories(response.suggestionDrawerCategories)
    setLoading(false)
  }

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  const handleReorder = (event, previousIndex, nextIndex, fromId, toId) => {
    setSuggestionDrawerCategories(reorder(suggestionDrawerCategories, previousIndex, nextIndex))
  };



  const handleSubmit = async () => {
    try {
      setLoading(true)
      let response = await updateSuggestionDrawerCategoryOrderData({ suggestionDrawerCategories })
      if (response.status === "edited") {
        let text = "Category order has been updated";
        showSuccessModal(text);
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container fluid className="p-0">
      <Row>
        <h1 className="mb-4 mt-2 w-50 pl-3">Manage Ordering</h1>
      </Row>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <h5>Instructions:</h5>
                      <ul>
                        <li>
                          Drag and Drop the contents to rearrange them.
                        </li>
                      </ul>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {(showSuggestionDrawerCategoryOrder() && suggestionDrawerCategories.length > 0) ? (
                        <Button className="d-block ml-2 mt-2 mb-3" color="primary" onClick={handleSubmit}>
                          Save
                        </Button>
                      ) : null}
                    </Col>
                  </Row>
                  {(suggestionDrawerCategories.length > 0) ? (
                    <Reorder reorderId="reorder-list" onReorder={handleReorder} disabled={!showSuggestionDrawerCategoryOrder()}>
                      {suggestionDrawerCategories?.map(ad => (
                          <div key={ad?.id} className="reorderContainer align-bottom mb-5">
                            <Card className="m-3 responseCard" style={{ "cursor": "pointer" }}>
                            <h3>{ad.name}</h3>
                            </Card>
                          </div>
                        ))
                      }
                    </Reorder>
                  )
                    : <p className='py-3'>No Contents.</p>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}
      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={successModalHeading}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={errorModalHeading}
        modaltext={failureModalText}
      />
    </Container>
  )
}

export default CategoryContentOrder
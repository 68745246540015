import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import ResponseModal from "../ResponseModal";
import { errorModalHeading, successModalHeading } from "../../../config/config";
import { createDeeplink } from "../../../utilities/apiUtils/deeplinks";
import { deeplinkOptions, clientOptions } from "../../../config/deeplinks";
import { DeeplinkResponseModal } from "./DeeplinkResponseModal";
import Select from "react-select";
import {
  getCategories,
  getSuggestionDrawerCategories,
  getMintCREContentCategories,
  getMintSuggestionDrawerCategories,
} from "../../../utilities/apiUtils/keyboardPrompts";
import { getQuickReplyCategories } from "../../../utilities/apiUtils/quickReplies";

export const ManageDeeplinks = (props) => {
  const [description, setDescription] = useState("");

  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");

  const [categories, setCategories] = useState([]);
  const [deeplinks, setDeeplinks] = useState([]);
  const [params, setParams] = useState({});

  const [category, setCategory] = useState("");
  const [deeplink, setDeeplink] = useState("");
  const [deeplinkObject, setDeeplinkObject] = useState({});

  const [contentCategories, setContentCategories] = useState([]);
  const [addCategoryOption, setAddCategoryOption] = useState([]);
  const [addQuickRepliesOption, setAddQuickRepliesOption] = useState([]);
  const [addSDContentCategoryOption, setAddSDContentCategoryOption] = useState(
    []
  );
  const [customMessageTypes, setCustomMessageTypes] = useState([]);
  const [client, setClient] = useState(null);
  const [assetsPreloadPriority, setAssetsPreloadPriority] = useState([
    { value: "0", label: "High" },
    { value: "1", label: "Medium" },
    { value: "2", label: "Low" },
  ]);

  const [enableMatchId, setEnableMatchId] = useState(false);
  const [mintSDV3Categories, setMintSDV3Categories] = useState([]);

  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });

  useEffect(() => {
    SetSDCategories();
    setQuickReplies();
    setCustomMessageTypes([
      {
        value: "invite",
        label: "Invite",
        target: {
          name: "customMessageType",
          value: {
            value: "invite",
            label: "Invite",
          },
        },
      },
    ]);
  }, []);

  useEffect(() => {
    if (client) {
      SetContentCategories();
      SetSDCategories();
      getAllCategories().then((categories) => {
        setCategories(categories);
        updateCategory({ target: { value: categories[0] } });
      });
    }
  }, [client]);

  const filterContentCategories = (category) => {
    const filteredCategories = [];

    contentCategories.map((item) => {
      item.contentTypes.forEach((contentType) => {
        if (contentType.contentType === category) {
          filteredCategories.push(item);
          // break the loop
          return false;
        }
      });
    });

    const categoryOptions = filteredCategories.map((category) => {
      return {
        value: category.id,
        label: category.name + " : " + category.id,
        target: {
          name: "categoryID",
          value: {
            value: category.id,
            label: category.name + " : " + category.id,
          },
        },
      };
    });

    const nullCategory = {
      value: null,
      label: "None",
      target: {
        name: "categoryID",
        value: {
          value: null,
          label: "None",
        },
      },
    };

    // add null category to the dropdown
    categoryOptions.unshift(nullCategory);

    return categoryOptions;
  };

  const SetContentCategories = async () => {
    let categories = null;
    if (client.value === "bobble") {
      categories = await getCategories();
    } else if (client.value === "mint") {
      categories = await getMintCREContentCategories();
    }

    if (categories?.contentCategories?.length > 0) {
      setContentCategories(categories.contentCategories);
      const categoryOptions = categories.contentCategories.map((category) => {
        return {
          value: category.id,
          label: category.name + " : " + category.id,
          target: {
            name: "categoryID",
            value: {
              value: category.id,
              label: category.name + " : " + category.id,
            },
          },
        };
      });

      const nullCategory = {
        value: null,
        label: "None",
        target: {
          name: "categoryID",
          value: {
            value: null,
            label: "None",
          },
        },
      };

      // add null category to the dropdown
      categoryOptions.unshift(nullCategory);

      setAddCategoryOption(categoryOptions);
    }
  };


  const SetSDCategories = async () => {
    const params = {
      status: "activated",
    }
    let categories = null;

    if(client?.value === "mint") {
      categories = await getMintSuggestionDrawerCategories();
    }

    if(client?.value === "bobble") {
      categories = await getSuggestionDrawerCategories(params);
    }
    if (categories?.categories?.length > 0) {
      const categoryOptions = categories.categories
      .filter(category => category.publishedAt !== null && category.deactivatedAt === null)
      .map((category) => {
        return {
          value: category.id,
          label: category.name + " : " + category.id,
          target: {
            name: "categoryID",
            value: {
              value: category.id,
              label: category.name + " : " + category.id,
            },
          },
        };
      });

      const nullCategory = {
        value: null,
        label: "None",
        target: {
          name: "categoryID",
          value: {
            value: null,
            label: "None",
          },
        },
      };
      // add suggestion drawer categories

      // add null category to the dropdown
      categoryOptions.unshift(nullCategory);

      setAddSDContentCategoryOption(categoryOptions);
    }
  };

  const setQuickReplies = async () => {
    const quickReplies = await getQuickReplyCategories();
    if (quickReplies?.quickReplyCategories?.length > 0) {
      const quickRepliesOptions = quickReplies.quickReplyCategories.map(
        (quickReply) => {
          return {
            value: quickReply.ID,
            label: quickReply.name + " : " + quickReply.ID,
            target: {
              name: "quickReplyCategoryID",
              value: {
                value: quickReply.ID,
                label: quickReply.name + " : " + quickReply.ID,
              },
            },
          };
        }
      );

      const nullQuickReply = {
        value: null,
        label: "None",
        target: {
          name: "quickReplyCategoryID",
          value: {
            value: null,
            label: "None",
          },
        },
      };

      // add null quick reply to the dropdown
      quickRepliesOptions.unshift(nullQuickReply);

      setAddQuickRepliesOption(quickRepliesOptions);
    }
  };

  let getAllCategories = async () => {
    const deeplinks = deeplinkOptions[client.value];
    // Retrieve all keys from the object
    const keys = Object.keys(deeplinks);
    // Initialize an empty array
    const result = [];
    // Iterate over keys
    for (let i = 0; i < keys.length; i++) {
      // Push the key to the array
      result.push(keys[i]);
    }
    return result;
  };

  let getAllDeeplinks = async (category) => {
    const deeplinks = deeplinkOptions[client.value];
    // Retrieve all deeplinks from the object for the given category
    const deeplinksForCategory = deeplinks[category];
    // Initialize an empty array
    const result = [];
    // Iterate over deeplinks

    for (let i = 0; i < deeplinksForCategory.length; i++) {
      // Push the deeplink to the array
      result.push(deeplinksForCategory[i]);
    }
    return result;
  };

  let onCopy = () => {
    // Copy the deeplink to the clipboard
    navigator.clipboard.writeText(deeplink);
  };

  let updateCategory = async (event) => {
    const category = event.target.value;
    setCategory(category);
    const deeplinks = await getAllDeeplinks(category);
    setDeeplinks(deeplinks);
    await updateDeeplink(deeplinks[0].name, category);
  };

  let updateDeeplink = async (deeplink, category) => {
    setDeeplink(deeplink);
    const _deeplinkObject = deeplinkOptions[client.value][category].find(
      (deeplinkObject) => deeplinkObject.name === deeplink
    );
    setDeeplinkObject(_deeplinkObject);
  };

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    props.onHide();
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
    props.onHide();
  };

  const handleDescriptionChange = (event) => {
    const description = event.target.value;
    setDescription(description);
  };

  // Function to check if a string is a valid URL
  const isValidUrl = (url) => {
    // Regular expression for URL validation
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!urlPattern.test(url);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let contentNameAndIDMapping = [];

    const error = checkRequiredParams();

    const urlFields = [
      "destinationUrl",
      "imageUrls",
      "videoUrls",
      "pngUrls",
      "gifUrls",
      "webpUrls",
    ];

    // Iterate over each URL field and validate it
    for (const field of urlFields) {
      if (params[field]) {
        // Trim the entire field to remove extra spaces
        params[field] = params[field].trim();

        const urls = params[field].split(",").map((url) => {
          return url.replace(/\s/g, ""); // Remove spaces from each URL
        });
        for (const url of urls) {
          // Check if the URL has any spaces
          if (url.includes(" ")) {
            showErrorModal("URLs should not contain spaces");
            return;
          }
          if (!isValidUrl(url)) {
            let fieldName =
              field === "destinationUrl" ? "Destination URL" : field;
            showErrorModal(`Invalid ${fieldName}`);
            return;
          }
        }
        // Join the URLs with comma and space
        params[field] = urls.join(","); // Update the params with formatted URLs
      }
    }

    if (error !== "") {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: error,
      });
      return;
    }

    if (client.value === "mint") {
      let pngURLS = params["pngUrls"];
      let pngContentIds = params["pngContentIds"]?.split(",");
      let pngContentTypes = params["pngContentTypes"]?.split(",");

      let gifURLS = params["gifUrls"];
      let gifContentIds = params["gifContentIds"]?.split(",");
      let gifContentTypes = params["gifContentTypes"]?.split(",");

      let webpURLS = params["webpUrls"];
      let webpContentIds = params["webpContentIds"]?.split(",");
      let webpContentTypes = params["webpContentTypes"]?.split(",");

      if (pngURLS !== "" && pngURLS !== undefined) {
        let urls = pngURLS.split(",");
        for (let i = 0; i < urls.length; i++) {
          let res = urls[i].split("/");
          let filename = res[res.length - 1];
          contentNameAndIDMapping.push({
            filename: filename,
            contentId: pngContentIds[i],
            contentType: pngContentTypes[i],
          });
        }
      }

      if (gifURLS !== "" && gifURLS !== undefined) {
        let urls = gifURLS.split(",");
        for (let i = 0; i < urls.length; i++) {
          let res = urls[i].split("/");
          let filename = res[res.length - 1];
          contentNameAndIDMapping.push({
            filename: filename,
            contentId: gifContentIds[i],
            contentType: gifContentTypes[i],
          });
        }
      }

      if (webpURLS !== "" && webpURLS !== undefined) {
        let urls = webpURLS.split(",");
        for (let i = 0; i < urls.length; i++) {
          let res = urls[i].split("/");
          let filename = res[res.length - 1];
          contentNameAndIDMapping.push({
            filename: filename,
            contentId: webpContentIds[i],
            contentType: webpContentTypes[i],
          });
        }
      }
    }
    let _deeplink = deeplinkObject.url;

    // Add ? at the end of the deeplink if parameters are present
    if (deeplinkObject.params.length > 0) {
      _deeplink = _deeplink + "?";
    }

    // Add parameters to the deeplink if present
    for (let i = 0; i < deeplinkObject.params.length; i++) {
      if (i > 0) {
        _deeplink = _deeplink + "&";
      }

      // URL encode the param value if it is present
      if (
        params[deeplinkObject.params[i].key] !== undefined &&
        params[deeplinkObject.params[i].key] !== "" &&
        params[deeplinkObject.params[i].key] !== null
      ) {
        params[deeplinkObject.params[i].key] = encodeURIComponent(
          params[deeplinkObject.params[i].key]
        );
      } else {
        continue;
      }

      _deeplink =
        _deeplink +
        deeplinkObject.params[i].key +
        "=" +
        params[deeplinkObject.params[i].key];
    }

    const categoryLowercase = category.toLowerCase();

    const paramsToRemove = [
      "pngContentIds",
      "pngContentTypes",
      "gifContentIds",
      "gifContentTypes",
      "webpContentIds",
      "webpContentTypes",
    ];
    _deeplink = removeQueryParams(_deeplink, paramsToRemove);

    const data = JSON.stringify({
      description: description,
      url: _deeplink,
      category: categoryLowercase, // Include the category
      action: deeplinkObject.id, // Include the selected deeplink
      client: client.value,
      contentNameAndIDMapping: contentNameAndIDMapping,
    });

    let response = await createDeeplink(data);

    if (response.id) {
      setDeeplink(response.url);
      showSuccessModal(response.description);
    } else {
      showErrorModal("Failed to create deeplink");
    }
  };

  const removeQueryParams = (urlStr, paramsToRemove) => {
    try {
      // add https:// to the url if it is not present
      if (!urlStr.startsWith("https://") && !urlStr.startsWith("http://")) {
        urlStr = "https://" + urlStr;
      }
      const url = new URL(urlStr);

      // Get the query parameters as a URLSearchParams object
      const queryParams = new URLSearchParams(url.search);

      // Remove the specified query parameters
      for (const param of paramsToRemove) {
        queryParams.delete(param);
      }

      // Reconstruct the URL with the modified query parameters
      url.search = queryParams.toString();
  
      // Convert the URL back to a string
      let modifiedUrlStr = url.toString();
  
      // Remove the protocol
      if (modifiedUrlStr.startsWith("https://")) {
        modifiedUrlStr = modifiedUrlStr.substring(8);
      }
  
      // Remove any unnecessary trailing slashes after the base path
      if (modifiedUrlStr.includes("?")) {
        // If a query string exists, ensure there's no slash before it
        modifiedUrlStr = modifiedUrlStr.replace(/\/\?/, "?");
      } else {
        // Otherwise, trim any trailing slash
        modifiedUrlStr = modifiedUrlStr.replace(/\/$/, "");
      }
  
      return modifiedUrlStr;
    } catch (error) {
      console.log(error);
      return urlStr;
    }
  };

  const checkRequiredParams = () => {
    if (client.value === "mint" && category === "Sharing") {
      if (
        (params["pngUrls"] === undefined || params["pngUrls"] === "") &&
        (params["gifUrls"] === undefined || params["gifUrls"] === "") &&
        (params["webpUrls"] === undefined || params["webpUrls"] === "")
      ) {
        return "Please enter at least one url";
      }

      if (params["pngUrls"] !== undefined && params["pngUrls"] !== "") {
        // check if pngContentIds and pngContentTypes are present
        if (
          params["pngContentIds"] === undefined ||
          params["pngContentIds"] === ""
        ) {
          return "Please enter PNG Content Ids";
        } else if (
          params["pngContentTypes"] === undefined ||
          params["pngContentTypes"] === ""
        ) {
          return "Please enter PNG Content Types";
        }
      }

      if (params["gifUrls"] !== undefined && params["gifUrls"] !== "") {
        // check if gifContentIds and gifContentTypes are present
        if (
          params["gifContentIds"] === undefined ||
          params["gifContentIds"] === ""
        ) {
          return "Please enter GIF Content Ids";
        } else if (
          params["gifContentTypes"] === undefined ||
          params["gifContentTypes"] === ""
        ) {
          return "Please enter GIF Content Types";
        }
      }

      if (params["webpUrls"] !== undefined && params["webpUrls"] !== "") {
        // check if webpContentIds and webpContentTypes are present
        if (
          params["webpContentIds"] === undefined ||
          params["webpContentIds"] === ""
        ) {
          return "Please enter Webp Content Ids";
        } else if (
          params["webpContentTypes"] === undefined ||
          params["webpContentTypes"] === ""
        ) {
          return "Please enter Webp Content Types";
        }
      }
    }
    return "";
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Create Deeplink
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(event) => handleSubmit(event)}>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label>Client</Label>
                  <Select
                    id="client"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name="client"
                    onChange={(event) => {
                      setClient(event);
                    }}
                    options={clientOptions}
                    required
                  />
                </FormGroup>
              </Col>

              {client && (
                <>
                  <Col md={12}>
                    <FormGroup>
                      <Label>Description</Label>
                      <Input
                        type="text"
                        name="Description"
                        placeholder="Enter Description"
                        defaultValue={description}
                        onChange={(event) => handleDescriptionChange(event)}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label>Category</Label>
                      <Input
                        type="select"
                        name="Category"
                        placeholder="Enter Category"
                        defaultValue={categories[0]}
                        value={category}
                        onChange={(event) => updateCategory(event)}
                        required
                      >
                        {categories.map((category) => (
                          <option key={category}>{category}</option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label>Actions</Label>
                      <Input
                        type="select"
                        name="Deeplink"
                        placeholder="Enter Action"
                        defaultValue={deeplinks[0]}
                        value={deeplink}
                        onChange={(event) => {
                          updateDeeplink(event.target.value, category);
                        }}
                        required
                      >
                        {deeplinks.map((deeplink) => (
                          <option key={deeplink.name}>{deeplink.name}</option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>

                  {/*Create the params input fields dynamically based on the deeplink selected */}

                  {deeplinkObject &&
                    deeplinkObject.params &&
                    deeplinkObject.params.map((param) =>
                      param.key === "categoryId" ? (
                        <Col md={6} xs={6}>
                          <FormGroup>
                            <Label>{param.name}</Label>
                            <Select
                              id="categoryID"
                              className="react-select-container"
                              classNamePrefix="react-select"
                              name={param.key}
                              onChange={(event) => {
                                const newParams = params;
                                newParams[param.key] = event.target.value.value;
                                setParams(newParams);
                              }}
                              options={
                                deeplinkObject.name === "Content Suggestion Drawer"
                                  ? addSDContentCategoryOption
                                  : deeplinkObject.name ===
                                    "Movie Gifs section with category"
                                    ? filterContentCategories("movieGif")
                                    : deeplinkObject.name.value ===
                                      "Sticker section with category"
                                      ? filterContentCategories("sticker")
                                      : deeplinkObject.name ===
                                        "Quick replies section"
                                        ? addQuickRepliesOption
                                        : addCategoryOption
                              }
                              required
                            />
                          </FormGroup>
                        </Col>
                      ) : param.key === "type" &&
                        deeplinkObject.name === "Share custom message" ? (
                        <Col md={6} xs={6}>
                          <FormGroup>
                            <Label>{param.name}</Label>
                            <Select
                              id="type"
                              className="react-select-container"
                              classNamePrefix="react-select"
                              name={param.key}
                              onChange={(event) => {
                                const newParams = params;
                                // if target value is not undefined, then set the value
                                if (event.target.value.value !== undefined)
                                  newParams[param.key] =
                                    event.target.value.value;
                                setParams(newParams);
                              }}
                              options={customMessageTypes}
                            />
                          </FormGroup>
                        </Col>
                      ) : param.key === "assetsPreloadPriority" ? (
                        <Col md={6} xs={6}>
                          <FormGroup>
                            <Label>{param.name}</Label>
                            <Select
                              id="assetsPreloadPriority"
                              className="react-select-container"
                              classNamePrefix="react-select"
                              name={param.key}
                              onChange={(event) => {
                                const newParams = params;
                                if (event.value !== undefined)
                                  newParams[param.key] = event.value;
                                setParams(newParams);
                              }}
                              options={assetsPreloadPriority}
                            />
                          </FormGroup>
                        </Col>
                      ) : param.key === "matchId" ? (
                        <>
                          <Col md={6} xs={6}>
                            <FormGroup>
                              <Label>{param.name}
                                {" "}
                                <input
                                  type="checkbox"
                                  onChange={() => {
                                    setEnableMatchId(!enableMatchId);
                                    if (enableMatchId) {
                                      const newParams = params;
                                      delete newParams[param.key];
                                      setParams(newParams);
                                    }
                                  }}
                                />
                              </Label>
                              {enableMatchId ? (
                                <Select
                                  options={
                                    [
                                      { value: '__FOOTBALL_MATCH_ID__', label: '__FOOTBALL_MATCH_ID__' },
                                      { value: '__CRICKET_MATCH_ID__', label: '__CRICKET_MATCH_ID__' }
                                    ]
                                  }
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  onChange={(selectedOption) => {
                                    const newParams = params;
                                    if (enableMatchId) {
                                      if (selectedOption) {
                                        newParams[param.key] = selectedOption.value;
                                      } else {
                                        delete newParams[param.key];
                                      }
                                      setParams(newParams);
                                    }
                                  }}
                                  required
                                />
                              ) : null}
                            </FormGroup>
                          </Col>
                        </>
                      ) : param.type === "checkbox" ? (
                        <Col md={12} xs={12}>
                          <FormGroup check inline>
                            <Label check></Label>
                            <Input
                              type="checkbox"
                              name={param.key}
                              className="form-check-input"
                              onChange={(event) => {
                                const newParams = params;
                                if (event.target.checked) {
                                  newParams[param.key] = param.value;
                                } else {
                                  // remove the key from the params if present if the checkbox is unchecked
                                  delete newParams[param.key];
                                }
                                setParams(newParams);
                              }}
                            />
                            {param.name}
                          </FormGroup>
                        </Col>
                      ) : (
                        <Col md={12}>
                          <FormGroup>
                            <Label>{param.name}</Label>
                            <Input
                              multiline
                              type="textarea"
                              name={param.key}
                              placeholder={`Enter ${param.name}`}
                              onChange={(event) => {
                                const newParams = params;
                                newParams[param.key] = event.target.value;
                                setParams(newParams);
                              }}
                              required={
                                param.required === undefined
                                  ? true
                                  : param.required
                              }
                            />
                          </FormGroup>
                        </Col>
                      )
                    )}
                </>
              )}
            </Row>
            <div className="d-block w-100 pt-3">
              <Button color="primary" type="submit" disabled={client === null}>
                Submit
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <DeeplinkResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={successModalHeading}
        modaltext={successModalText}
        url={deeplink}
        onCopy={onCopy}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={errorModalHeading}
        modaltext={failureModalText}
      />
      <ResponseModal
        show={failureModalState.failureModalDisplay}
        onHide={() =>
          setFailureModalState({
            failureModalDisplay: false,
            failureModalText: "",
          })
        }
        modalheading={"Error"}
        modaltext={failureModalState.failureModalText}
      />
    </>
  );
};

export default ManageDeeplinks;

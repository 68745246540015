import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
  updateContentCategoryCarouselsOrder,
  getContentCategoryCarouselsOrder,
} from "../../../utilities/apiUtils/carousel.js";

const ManageCarouselOrder = () => {
  const [loading, setLoading] = useState(true);
  const [carousels, setCarousels] = useState([]);
  const [newOrder, setNewOrder] = useState([]);
  const [modal, setModal] = useState({ isOpen: false, message: "", success: true });
  const [filterOptions, setFilterOptions] = useState({
    placement: "all",
  });
  const history = useHistory();

  useEffect(() => {
    getContentCategoryCarouselsOrder()
      .then((data) => {
        let filteredCarousels = data.contentCategoryCarousels;

        // Apply Placement filter
        if (filterOptions.placement !== "all") {
          filteredCarousels = filteredCarousels
            .map((carousel) => ({
              ...carousel,
              placementsRepo: carousel.placementsRepo.filter(
                (placement) => placement.placement === filterOptions.placement
              ),
            }))
            .filter((carousel) => carousel.placementsRepo.length > 0)
            .sort((a, b) => b.placementsRepo[0].priority - a.placementsRepo[0].priority);
        }

        setCarousels(filteredCarousels);
        setNewOrder(filteredCarousels);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching carousels:", error);
        setLoading(false);
      });
  }, [filterOptions]);

  const handleDragStart = (e, index, type, placementIndex) => {
    e.dataTransfer.setData("text/plain", JSON.stringify({ index, type, placementIndex }));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, dropIndex, dropType, dropPlacementIndex) => {
    e.preventDefault();

    const { index: dragIndex, type: dragType, placementIndex: dragPlacementIndex } = JSON.parse(
      e.dataTransfer.getData("text/plain")
    );

    let updatedOrder = [...newOrder];

    if (dragType === dropType) {
      if (dragType === "carousel") {
        const [draggedItem] = updatedOrder.splice(dragIndex, 1);
        updatedOrder.splice(dropIndex, 0, draggedItem);
      } else if (dragType === "placement" && dragPlacementIndex !== undefined && dropPlacementIndex !== undefined) {
        const placements = [...updatedOrder[dragIndex].placementsRepo];
        const [draggedPlacement] = placements.splice(dragPlacementIndex, 1);
        placements.splice(dropPlacementIndex, 0, draggedPlacement);
        updatedOrder[dragIndex].placementsRepo = placements;
      }
      setNewOrder(updatedOrder);
    }
  };

  const handleSaveOrder = () => {
    const selectedPlacement = filterOptions.placement;

    const payload = newOrder.map((carousel, index) => {
      const updatedCarousel = {
        id: carousel.id,
        priority: carousel.priority, // Keep the existing priority unless "all" is selected
        placementsRepo: [],
      };

      if (selectedPlacement === "all") {
        // When "ALL" is selected, update the carousel priority and include all placements as they are
        updatedCarousel.priority = newOrder.length - index; // Assigning higher priority to the first item
        updatedCarousel.placementsRepo = carousel.placementsRepo.map((placement) => ({
          ...placement,
          priority: placement.priority, // Keep existing placement priorities
        }));
      } else {
        // When a specific placement is selected, update only that placement's priority
        updatedCarousel.placementsRepo = carousel.placementsRepo.map((placement) => {
          if (placement.placement === selectedPlacement) {
            return {
              ...placement,
              priority: newOrder.length - index, // Assigning higher priority to the first item
            };
          }
          return placement;
        });
      }

      return updatedCarousel;
    });

    updateContentCategoryCarouselsOrder(payload)
      .then(() => {
        setModal({ isOpen: true, message: "Order updated successfully", success: true });
        history.push(`/cre/content-carousels`);
      })
      .catch((error) => {
        console.error("Error updating order:", error);
        setModal({ isOpen: true, message: "Error updating order", success: false });
      });
  };

  const handleFilterChange = (selectedOption, filterType) => {
    setFilterOptions({
      ...filterOptions,
      [filterType]: selectedOption.value,
    });
  };

  const placementOptions = [
    { value: "all", label: "All(old version)" },
    { value: "app", label: "App" },
    { value: "keyboard", label: "Keyboard" },
    { value: "settings", label: "Settings" },
    { value: "hamburgerMenu", label: "Hamburger Menu" },
  ];

  return (
    <Container fluid className="p-0">
      <Row className="justify-content-center mx-auto mb-4">
        <Col md={6} sm={12} className="mt-3">
          <label htmlFor="placementSelect" className="form-label">Select Placement</label>
          <Select
            id="placementSelect"
            name="placement"
            value={placementOptions.find(option => option.value === filterOptions.placement)}
            onChange={(option) => handleFilterChange(option, "placement")}
            options={placementOptions}
          />
        </Col>
      </Row>

      {loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <h5>Instructions:</h5>
                      <ul>
                        <li>Drag and Drop the carousels and their placements to rearrange them.</li>
                      </ul>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {newOrder.length > 0 && (
                        <Button
                          className="d-block ml-2 mt-2 mb-3"
                          color="primary"
                          onClick={handleSaveOrder}
                        >
                          Save Order
                        </Button>
                      )}
                    </Col>
                  </Row>
                  {newOrder.length > 0 ? (
                    <Row>
                      {newOrder.map((carousel, carouselIndex) => (
                        <Col md={3} key={carousel.id} className="mb-3"> {/* Changed md={4} to md={3} */}
                          <Card
                            className="drag-card"
                            draggable
                            onDragStart={(e) => handleDragStart(e, carouselIndex, "carousel")}
                            onDragOver={handleDragOver}
                            onDrop={(e) => handleDrop(e, carouselIndex, "carousel")}
                          >
                            <CardBody>
                              <div className="carousel-image mb-2">
                                {carousel.background?.imageURL ? (
                                  <img
                                    src={carousel.background.imageURL}
                                    alt={`${carousel.name} banner`}
                                    style={{
                                      maxWidth: "100%",
                                      height: "50px",
                                    }}
                                  />
                                ) : (
                                  <p style={{ maxWidth: "100%", height: "50px" }}>N/A</p>
                                )}
                              </div>
                              <p className="card-title">{carousel.name}</p>
                              <p className="card-text">Priority: {carousel.priority}</p>
                              {filterOptions.placement !== "all" && (
                                <h6>Placements:</h6>
                              )}
                              <ul>
                                {carousel.placementsRepo.map((placement, placementIndex) => (
                                  <li
                                    key={placement.id}
                                    className="placement-item"
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, carouselIndex, "placement", placementIndex)}
                                    onDragOver={handleDragOver}
                                    onDrop={(e) => handleDrop(e, carouselIndex, "placement", placementIndex)}
                                  >
                                    {placement.placement} - Priority: {placement.priority}
                                  </li>
                                ))}
                              </ul>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    <div>No carousels found.</div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}
      <Modal isOpen={modal.isOpen} toggle={() => setModal({ ...modal, isOpen: false })}>
        <ModalHeader toggle={() => setModal({ ...modal, isOpen: false })}>
          {modal.success ? "Success" : "Error"}
        </ModalHeader>
        <ModalBody>{modal.message}</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModal({ ...modal, isOpen: false })}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default ManageCarouselOrder;

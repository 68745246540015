import React, { useState, useEffect } from "react";
import Search from "../../../components/Search";
import Select from "react-select";
import { UserStoryListingCard } from "./components/UserStoryListingCard";
import ResponseModal from "../ResponseModal";
import ConfirmationModal from "../ConfirmationModal";
import { getUserStoriesData } from "../../../utilities/apiUtils/stories";
import {
  Col,
  Container,
  Row,
  Button,
  Spinner,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { Config } from "../../../config";
import {
  activateStoryData,
  deactivateStoryData,
  getStoryDetailsData,
  publishStoryData,
  getBucketData,
  updateBulkStoriesData,
  getMultipleStoryDetailsData,
} from "../../../utilities/apiUtils/stories";
import { successModalHeading, errorModalHeading } from "../../../config/config";
import {
  storyCreatePermission,
  storyUpdatePermission,
  storyViewPermission,
  storyListPermission,
  storiesBulkCreatePermission,
  storiesBulkUpdatePermission,
  sortByOptions,
  statusOptions,
} from "../../../config/configStoryDashboard";

import {
  errorDescription,
  error,
  getStatus,
  allActions,
  permissions,
  redirectionOnTokenExpiry,
  getStatusColor,
  getButtonColor,
  getBtnName,
  redirectOnTokenExpiry,
  redirectTo,
} from "../../../utilities/commonUtil";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { Link } from "react-router-dom";
import EmptyStoriesDisplay from "./components/EmptyStoriesDisplay";
import { faUpload, faTimes } from "@fortawesome/free-solid-svg-icons";
import UncontrolledButtonDropdown from "reactstrap/lib/UncontrolledButtonDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import { capitalize } from "@material-ui/core";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { getUserStoriesStats } from "../../../utilities/apiUtils/stories";
import UserStoriesPropertiesModal from "./components/UserStoriesPropertiesModal";

const UserStoriesListing = () => {
  const [displayedStories, setDisplayedStories] = useState(0);
  const [displayedDevices, setDisplayedDevices] = useState(0);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const bucketId = searchParams.get("bucketId");
  const [bucketList, setBucketList] = useState([]);
  const bucketParams = bucketList?.find(
    (bucket) => bucket.value === searchParams.get("bucketId")
  );
  const statusParams = statusOptions.find(
    (status) => status.value === searchParams.get("status")
  );
  const sortByParams = sortByOptions.find(
    (options) => options.value === searchParams.get("sortBy")
  );
  const isSelectAllCheckedParams =
    searchParams.get("selectAll") === "true" ? true : false;
  const searchStringParams = searchParams.get("searchString");
  const history = useHistory();

  const [id, setID] = useState(null);
  const [action, setAction] = useState("");
  const [stories, setStories] = useState([]);
  const [searchString, setSearchString] = useState(
    searchStringParams !== null ? searchStringParams : ""
  );
  const [bucket, setBucket] = useState(
    bucketParams !== undefined ? bucketParams : { value: -1, label: "All" }
  );
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [hasMoreStories, setHasMoreStories] = useState(false);
  const [noStoriesFound, setNoStoriesFound] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [stats, setStats] = useState({ numberOfUniqueDeviceIds: 0, totalNumberOfStories: 0 });
  const [successModalState, setSuccessModalState] = useState({
    successModalDisplay: false,
    successModalText: "",
  });
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );
  const [status, setStatus] = useState(
    statusParams !== undefined ? statusParams : statusOptions[0]
  );
  const [sortBy, setSortBy] = useState(
    sortByParams !== undefined ? sortByParams : sortByOptions[0]
  );
  const [searchBy, setSearchBy] = useState(Config.SEARCH_BY_TITLE);
  const [loading, setLoading] = useState(true);
  const [firstRender, setFirstRender] = useState(true);
  const [selectedStories, setSelectedStories] = useState([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(
    isSelectAllCheckedParams ? isSelectAllCheckedParams : false
  );
  const [updateBulkStories, setUpdateBulkStories] = useState({});
  const [showUserStoriesPropertiesModal, setShowUserStoriesPropertiesModal] =
    useState(false);
  
  const [searchTitle, setSearchTitle] = useState("");
  const [searchTag, setSearchTag] = useState("");
  const [searchId, setSearchId] = useState("");
  const [templateType, setTemplateType] = useState({ value: "all", label: "All" });

  useEffect(() => {
    setLoading(false);
    getStats();
    setFirstRender(false);
    const intervalId = setInterval(() => {
      getStats();
    }, 5000); 
    return () => clearInterval(intervalId);
    
  }, []);

  useEffect(() => {
    if (bucket?.label === "All") {
      setLimit(20);
    } else {
      setLimit(500);
    }
  }, [bucket]);

  useEffect(() => {
    const fetchData = async () => {
      let params = {
        searchString: searchTitle,
        id: searchId,
        status: status?.label !== "All" ? status.value : "",
        sortBy: sortBy.value,
        page: page,
        limit: bucket?.label === "All" ? 20 : 500,
            };
      let response = await getUserStoriesData(params);
      if (response.userStories !== null) {
        let allStoriesData = stories.concat(response.userStories);
        setStories(allStoriesData);
        if (response?.userStories?.length < limit) {
          setHasMoreStories(false);
        } else setHasMoreStories(true);
      } else {
        setHasMoreStories(false);
      }
    };

    if (!firstRender && page > 0) {
      fetchData();
    }
  }, [page]);

  const handleSearchStringChange = (event) => {
    event.preventDefault();
    console.log(event.target);
    let { placeholder, value } = event.target;
    if (placeholder === "Search by Device ID") setSearchId(value);
    if (!value.length) {
      setSearchString("");
      search();
      return;
    }
  };

  useEffect(() => {
    if (!firstRender) {
      const searchParams = new URLSearchParams();

      if (bucket?.label !== "All") searchParams.set("bucketId", bucket?.value);
      else searchParams.set("bucketId", "");

      searchParams.set("status", status?.value);
      searchParams.set("sortBy", sortBy?.value);

      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
      search();
    }
  }, [bucket, status, sortBy, searchId, searchTitle, searchTag, templateType]);

  useEffect(() => {
    if (!firstRender) {
      const searchParams = new URLSearchParams();
      searchParams.set("selectAll", isSelectAllChecked);
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    }
  }, [isSelectAllChecked]);


  const handleStatusChange = (status) => {
    setStatus(status);
  };

  const handleOrderChange = (sortBy) => {
    setSortBy(sortBy);
  };

  const fetchMoreData = async () => {
    setPage(page + 1);
    setNoStoriesFound(false);
    setHasMoreStories(true);
  };

  const search = async (localSearchBy) => {
    setPage(0);
    setNoStoriesFound(false);
    setHasMoreStories(false);
    setSearchBy(localSearchBy || searchBy);
    setLoading(true);
    setStories([]);
  
    let params = {
      deviceId: searchId,
      status: status.label !== "All" ? status.value : "",
      sortBy: sortBy.value,
      page: 0,
    };
  
    try {
      let response = await getUserStoriesData(params);
      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectionOnTokenExpiry();
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: response.errorDescription,
        });
      } else if (response.userStories != null) {
        setStories(response.userStories);
        setLoading(false);
  
        if (response?.userStories?.length == limit) {
          setHasMoreStories(true);
        }
        return;
      }
      setLoading(false);
      setNoStoriesFound(true);
    } catch (err) {
      setLoading(false);
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };


  const getStats = async () => {
    try {
      let response = await getUserStoriesStats();  
      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        ) {
          redirectionOnTokenExpiry();
        }
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: response.errorDescription,
        });
      } else if (response.numberOfUniqueDeviceIds != null && response.totalNumberOfStories != null) {
        setStats(response);
      }
    } catch (err) {
      console.error("Error fetching stats:", err);
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };
  

  const setConfirmationModalState = (id, btnName) => {
    setID(id);
    setAction(btnName);
    setConfirmationModalIsOpen(true);
  };


  const UpdateBulkStoriesData = async (data) => {
    try {
      const response = await getMultipleStoryDetailsData(data);
      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectionOnTokenExpiry();
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: response.errorDescription,
        });
      } else {
        setStories((prevStories) => {
          return prevStories.map((story) => {
            const updatedStoryData = response.userStories.find(
              (updatedStory) => updatedStory.id === story.id
            );
            if (updatedStoryData) {
              // Update the publishedAt and deactivatedAt properties if present in the updated data
              const updatedStory = {
                ...story,
                publishedAt: updatedStoryData.publishedAt,
                deactivatedAt: updatedStoryData.deactivatedAt,
              };
              return updatedStory;
            }
            return story; // Return the original story if not updated
          });
        });
        setIsSelectAllChecked(false);
      }
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };


  const failureModalClose = () => {
    setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
  };

  const successModelClose = () => {
    setSuccessModalState((prev) => ({
      ...prev,
      successModalDisplay: false,
    }));
  };

  const getIcon = (status) => {
    if (status === "Deactivated") return faUpload;
    return faTimes;
  };

  const handleSelectAll = () => {
    if (isSelectAllChecked) {
      setSelectedStories([]);
    } else {
      setSelectedStories(stories.map((reply) => reply.id));
    }
    setIsSelectAllChecked(!isSelectAllChecked);
  };


  const onHideUserStoriesPropertiesModal = () => {
    setShowUserStoriesPropertiesModal(false);
  };

  const renderAllReplies = () => {
    if (stories.length === 0 && !loading) {
      return <EmptyStoriesDisplay />;
    } else {
      return stories.map((reply) => {
        let status = getStatus(reply.publishedAt, reply.deactivatedAt);
        let color = getStatusColor(status);
        let btnColor = getButtonColor(status);
        let btnName = getBtnName(status);
        return (
          <Col md="6" lg="4" xxl="3" key={reply.id}>
            <UserStoryListingCard
              reply={reply}
              color={color}
              status={status}
              btnName={btnName}
              btnColor={btnColor}
              btnIcon={getIcon(status)}
              getConfirmation={setConfirmationModalState}
              selectedStories={selectedStories}
              setSelectedStories={setSelectedStories}
              bucket={bucket}
              isChecked={isSelectAllChecked}
              stories={stories}
              setIsSelectAllChecked={setIsSelectAllChecked}
            />
          </Col>
        );
      });
    }
  };

  const onKeyDown = (event) => {
    if (event.keyCode === Config.KEYPRESS_DOWN) {
      search();
    }
  };


  // Function to animate the number increase
  const animateValue = (currentValue, targetValue, setValue) => {
    const increment = Math.ceil((targetValue - currentValue) / 20); // Speed of animation
    const step = () => {
      if (currentValue < targetValue) {
        currentValue = Math.min(currentValue + increment, targetValue);
        setValue(currentValue);
        setTimeout(step, 50); // Animation step time
      }
    };
    step();
  };

  useEffect(() => {
    animateValue(displayedStories, stats.totalNumberOfStories, setDisplayedStories);
    animateValue(displayedDevices, stats.numberOfUniqueDeviceIds, setDisplayedDevices);
  }, [stats]);

  return (
    <Container fluid className="p-0">
      <Row className="align-items-center">
        <Col md={3} sm={6}>
          <h1 className="mb-3 mt-4">User Stories</h1>
        </Col>
        <Col md={9} sm={12} className="d-flex justify-content-end">
          <div
            className="stats-card me-4"
            style={{
              backgroundColor: "white",
              borderRadius: "15px",
              padding: "20px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
              width: "130px",
              margin: "10px",
              transition: "transform 0.3s ease-in-out",
            }}
            onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
            onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
          >
            <h4 style={{ fontSize: "1.2rem", fontWeight: "600", color: "#333" }}>Total Stories</h4>
            <p style={{ fontSize: "1.5rem", fontWeight: "bold", margin: "0" }} className="animated-number text-success">
              {displayedStories}
            </p>
          </div>
          <div
            className="stats-card"
            style={{
              backgroundColor: "white",
              borderRadius: "15px",
              padding: "20px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
              width: "130px",
              margin: "10px",
              transition: "transform 0.3s ease-in-out",
            }}
            onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
            onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
          >
            <h4 style={{ fontSize: "1.2rem", fontWeight: "600", color: "#333" }}>Unique Devices</h4>
            <p style={{ fontSize: "1.5rem", fontWeight: "bold", margin: "0" }} className="animated-number text-success">
              {displayedDevices}
            </p>
          </div>
        </Col>

      </Row>
      
      <Row>
        <Col md={4} sm={12}>
        <Label>Device Id</Label>
          <Input
            type="string"
            placeholder="Search by Device ID"
            value={searchId}
            onChange={handleSearchStringChange}
            onKeyDown={onKeyDown}
          />
        </Col>


        <Col md={4} sm={12}>
          <Label>Select Status</Label>
          <Select
            className="react-select-container mb-4"
            classNamePrefix="react-select"
            placeholder="Select Status"
            value={status}
            onChange={(option) => handleStatusChange(option)}
            options={statusOptions}
          />
        </Col>
        <Col md={4} sm={12}>
          <Label>Sort By</Label>
          <Select
            className="react-select-container mb-4"
            classNamePrefix="react-select"
            placeholder={"Sort By"}
            value={sortBy}
            onChange={(option) => handleOrderChange(option)}
            options={sortByOptions}
          />
        </Col>
      </Row>

      <InfiniteScroll
        className="infinite-scroll"
        dataLength={stories.length}
        next={fetchMoreData}
        hasMore={hasMoreStories}
        loader={
          !loading &&
          searchBy !== "id" &&
          noStoriesFound && <Spinner color="info" className=" d-flex mx-auto" />
        }
      >
        {loading ? (
          <Spinner color="info" className=" d-flex mx-auto" />
        ) : noStoriesFound ? (
          <Container
            sm="12"
            md="8"
            lg="6"
            className="mx-auto d-table mt-5 pt-2 pb-5"
          >
            <h1 className="text-center">No reply found</h1>
          </Container>
        ) : (
          <React.Fragment>
            {storyListPermission ? (
              <Row className="mt-2">{renderAllReplies()}</Row>
            ) : null}
            <UserStoriesPropertiesModal
              show={showUserStoriesPropertiesModal}
              onHide={onHideUserStoriesPropertiesModal}
              selectedStories={selectedStories}
              setConfirmationModalIsOpen={setConfirmationModalIsOpen}
              ac
              setAction={setAction}
              setStatus={status.value}
              setUpdateBulkStories={setUpdateBulkStories}
            />
            <ResponseModal
              show={successModalState.successModalDisplay}
              onHide={() => successModelClose()}
              modalheading={successModalHeading}
              modaltext={successModalState.successModalText}
            />
            <ResponseModal
              show={failureModalState.failureModalDisplay}
              onHide={() => failureModalClose()}
              modalheading={errorModalHeading}
              modaltext={failureModalState.failureModalText}
            />
          </React.Fragment>
        )}
      </InfiniteScroll>
    </Container>
  );
};

export default UserStoriesListing;

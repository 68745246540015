import {retrieveFromLocalStorage} from "../utilities/localStorageUtil";
import {permissions} from "../utilities/commonUtil";
export const successModalHeading = "Success";
export const failureModalHeading = "Error";
export const rulesetCreatedSuccessfullyText = "Ruleset created Successfully.";

export const eventTypes = ["event", "default", "brand_campaign"]


const allowedPermissions = retrieveFromLocalStorage("userPermissions");

export const stickerShareTextResponses = {
    updatedSuccessfully : "Share Texts  updated successfully",
}

export let showStickerShareTextButton = () => {
    return allowedPermissions.includes(permissions.stickerShareTextUpdate);
};
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Button,Modal,
  ModalHeader,
  ModalBody,
  ModalFooter, } from "reactstrap";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
  updateKeyboardPromptsOrder,
  getKeyboardPromptsOrder,
} from "../../../../utilities/apiUtils/keyboardPrompts";
import { statusOptions, processDateAndStatus } from "../../../../config/keyboardPrompt";

const ManageKeyboardPromptOrder = () => {
  const [loading, setLoading] = useState(true);
  const [prompts, setPrompts] = useState([]);
  const [newOrder, setNewOrder] = useState([]);
  const [modal, setModal] = useState({ isOpen: false, message: "", success: true });

  const [filterOptions, setFilterOptions] = useState({
    uiType: "both", // Default to both
    status: "",
  });
  const toggleModal = () => {
    setModal({ ...modal, isOpen: !modal.isOpen });
  };
  const history = useHistory();

  useEffect(() => {
    getKeyboardPromptsOrder()
      .then((data) => {
        if (data && data.keyboardPrompt && Array.isArray(data.keyboardPrompt)) {
          let uniquePrompts = data.keyboardPrompt.reduce((acc, prompt) => {
            if (!acc.some((p) => p.id === prompt.id)) {
              processDateAndStatus(prompt);
              acc.push(prompt);
            }
            return acc;
          }, []);

          // Strict filtering by UI type
          if (filterOptions.uiType === "prompts") {
            uniquePrompts = uniquePrompts.filter(
              (prompt) =>
                prompt.supportedUITypes &&
                prompt.supportedUITypes.length === 1 &&
                prompt.supportedUITypes.includes("prompts")
            );
          } else if (filterOptions.uiType === "pills") {
            uniquePrompts = uniquePrompts.filter(
              (prompt) =>
                prompt.supportedUITypes &&
                prompt.supportedUITypes.length === 1 &&
                prompt.supportedUITypes.includes("pills")
            );
          } else if (filterOptions.uiType === "Both") {
            uniquePrompts = uniquePrompts.filter(
              (prompt) =>
                prompt.supportedUITypes &&
                prompt.supportedUITypes.includes("prompts") &&
                prompt.supportedUITypes.includes("pills")
            );
          }

          // Filter by status
          if (filterOptions.status === "Activated") {
            uniquePrompts = uniquePrompts.filter(
              (prompt) => prompt.status === "Activated"
            );
          } else if (filterOptions.status === "Deactivated") {
            uniquePrompts = uniquePrompts.filter(
              (prompt) => prompt.status === "Deactivated"
            );
          } else if (filterOptions.status === "Unpublished") {
            uniquePrompts = uniquePrompts.filter(
              (prompt) => prompt.status === "Unpublished"
            );
          }

          uniquePrompts = uniquePrompts.sort((a, b) => b.priority - a.priority); // Sort by priority in descending order
          setPrompts(uniquePrompts);
          setLoading(false);
          setNewOrder([...uniquePrompts]);
        } else {
          console.error("Invalid data format:", data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching keyboard prompts:", error);
        setLoading(false);
      });
  }, [filterOptions]);

  const handleDragStart = (e, prompt) => {
    e.dataTransfer.setData("text/plain", prompt.id.toString());
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, index) => {
    e.preventDefault();

    const draggedPromptId = parseInt(e.dataTransfer.getData("text/plain"), 10);
    const updatedOrder = [...newOrder];
    const draggedPromptIndex = newOrder.findIndex(
      (prompt) => prompt.id === draggedPromptId
    );

    if (draggedPromptIndex !== -1) {
      const [draggedPrompt] = updatedOrder.splice(draggedPromptIndex, 1);
      updatedOrder.splice(index, 0, draggedPrompt);
      setNewOrder(updatedOrder);
    }
  };

  const handleSaveOrder = () => {
    const sanitizedOrder = newOrder.map((prompt, index) => ({
      id: prompt.id,
      priority: newOrder.length - index, // Assign priorities so that the highest priority is at the top
    }));

    updateKeyboardPromptsOrder(sanitizedOrder)
      .then(() => {
        console.log("Order updated successfully");
        setModal({ isOpen: true, message: "Order updated successfully", success: true });
      })
      .catch((error) => {
        console.error("Error updating order:", error);
        setModal({ isOpen: true, message: "Error updating order", success: false });
      });
  };

  const handleFilterChange = (selectedOption, filterType) => {
    const newFilterOptions = {
      ...filterOptions,
      [filterType]: selectedOption.value,
    };
    setFilterOptions(newFilterOptions);
  };

  const uiTypeOptions = [
    { value: "both", label: "Both" },
    { value: "prompts", label: "Prompts" },
    { value: "pills", label: "Pills" },
  ];

  return (
    <Container fluid className="p-0">
    <Row className="justify-content-center mx-auto mb-4">
      <Col md={6} sm={12} className="mt-3">
        <label htmlFor="uiTypeSelect" className="form-label">Select UI Type</label>
        <Select
          className="react-select-container"
          id="uiTypeSelect"
          classNamePrefix="react-select"
          name="uiType"
          placeholder="Select UI Type"
          value={uiTypeOptions.find(option => option.value === filterOptions.uiType)}
          onChange={(option) => handleFilterChange(option, "uiType")}
          options={uiTypeOptions}
        />
      </Col>
      <Col md={6} sm={12} className="mt-3">
        <label htmlFor="statusSelect" className="form-label">Select Status</label>
        <Select
          className="react-select-container"
          id="statusSelect"
          classNamePrefix="react-select"
          name="status"
          placeholder="Select Status"
          value={statusOptions.find(option => option.value === filterOptions.status)}
          onChange={(option) => handleFilterChange(option, "status")}
          options={statusOptions}
        />
      </Col>
    </Row>

      {loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <h5>Instructions:</h5>
                      <ul>
                        <li>Drag and Drop the prompts to rearrange them.</li>
                      </ul>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {newOrder.length > 0 && (
                        <Button
                          className="d-block ml-2 mt-2 mb-3"
                          color="primary"
                          onClick={handleSaveOrder}
                        >
                          Save Order
                        </Button>
                      )}
                    </Col>
                  </Row>
                  {newOrder.length > 0 ? (
                    <Row>
                      {newOrder.map((prompt, index) => {
                        // Access the display settings from the first object in the actions array
                        const displaySettings =
                          prompt.actions.displaySettings || {};

                        // Determine which image URL to use
                        const bannerImageURL = displaySettings.bannerImageURL;
                        const leftIconImageURL =
                          displaySettings.leftIcon?.imageURL;

                        // Determine which image URL to use, defaulting to "N/A" if both are empty
                        const imageURL =
                          bannerImageURL && bannerImageURL.trim() !== ""
                            ? bannerImageURL
                            : leftIconImageURL && leftIconImageURL.trim() !== ""
                            ? leftIconImageURL
                            : "N/A";

                        return (
                          <Col
                            md={2}
                            key={`${prompt.id}_${index}`}
                            className="mb-3"
                          >
                            <Card
                              className="drag-card"
                              draggable
                              onDragStart={(e) => handleDragStart(e, prompt)}
                              onDragOver={handleDragOver}
                              onDrop={(e) => handleDrop(e, index)}
                            >
                              <CardBody>
                                <div className="banner-image mb-2">
                                  {imageURL === "N/A" ? (
                                    <p
                                      style={{
                                        maxWidth: "100%",
                                        height: "50px",
                                      }}
                                    >
                                      N/A
                                    </p>
                                  ) : (
                                    <img
                                      src={imageURL}
                                      alt={`${prompt.name} banner`}
                                      style={{
                                        maxWidth: "100%",
                                        height: "50px",
                                      }}
                                    />
                                  )}
                                </div>
                                <p className="card-title">{prompt.name}</p>
                                <p className="card-text">
                                  Status: {prompt.status}
                                </p>
                                <p className="card-text">
                                  Priority: {prompt.priority}
                                </p>
                              </CardBody>
                            </Card>
                          </Col>
                        );
                      })}
                    </Row>
                  ) : (
                    <div>No keyboard prompts found.</div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}
        <Modal isOpen={modal.isOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {modal.success ? "Success" : "Error"}
        </ModalHeader>
        <ModalBody>{modal.message}</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default ManageKeyboardPromptOrder;

import React, { useState, useEffect, useCallback } from "react";
import { Card, CardBody, Container } from "reactstrap";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
    Row,
    Col,
} from "reactstrap";
import { useParams } from 'react-router-dom';
import { error, errorDescription } from "../../../utilities/commonUtil";
import { GetAIPromptTemplatesDetails, GetAITaskDetails } from "../../../utilities/apiUtils/genAIDashboard";
import { showEditTask } from "../../../config/configGenAIDashboard";
import ResponseModal from "../ResponseModal";
import { errorModalHeading } from "../../../config/config";
import TaskDropdownOptions from "./TaskDropdownOptions";
const TemplateDetails = () => {
    const { id } = useParams();
    const [failureModalDisplay, setFailureModalDisplay] = useState(false);
    const [failureModalText, setFailureModalText] = useState("");
    const [promptIdentifier, setPromptIdentifier] = useState("");
    const [data, setData] = useState({
        id: 0,
        name: "",
        description: "",
        promptIdentifier: 0,
        title: {},
        hintText: {},
        minAppVersion: 0,
        maxAppVersion: 0,
        backButtonAction: {},
        defaultOperation: {},
        createdAt: "",
        updatedAt: "",
        publishedAt: "",
        deactivatedAt: ""
    });
    useEffect(() => {
        if (id) {
            fetchTaskDetails(id);
        }
    }, [id]);

    useEffect(() => {
        if (data?.id && data?.promptIdentifier) {
            fetchPromptTemplate(data.promptIdentifier)
        }
    }, [data?.id, data?.promptIdentifier]);

    const fetchTaskDetails = async (id) => {
        try {
            let response = await GetAITaskDetails(id)
            if (!response) {
                return;
            }
            if (response?.id) {
                setData({
                    id: response.id,
                    name: response.name,
                    description: response.description,
                    promptIdentifier: response.promptIdentifier,
                    title: response.title,
                    hintText: response.hintText,
                    minAppVersion: response.minAppVersion,
                    maxAppVersion: response.maxAppVersion,
                    backButtonAction: response.backButtonAction,
                    defaultOperation: response.defaultOperation,
                    createdAt: response.createdAt,
                    updatedAt: response.updatedAt,
                    publishedAt: response.publishedAt,
                    deactivatedAt: response.deactivatedAt
                })
            } else {
                showErrorModal(errorDescription(response.errorCode));
            }
        } catch (err) {
            showErrorModal(errorDescription(error.unexpectedError));
        }
    }

    let fetchPromptTemplate = useCallback(async (id) => {
        try {
            let response = await GetAIPromptTemplatesDetails(id);
            if (response) {
                setPromptIdentifier(response.identifier);
            }
            else if (response & response.errorCode) {
                showErrorModal(errorDescription(response.errorCode));
            }
        }
        catch (err) {
            showErrorModal(errorDescription(error.unexpectedError));
        }
    }, []);

    let showErrorModal = (text) => {
        setFailureModalText(text);
        setFailureModalDisplay(true);
    };

    let failureModalClose = () => {
        setFailureModalDisplay(false);
    };

    return (
        <>
            <h1>Task</h1>
            <Card>
                <CardBody>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Name:
                        </Col>
                        <Col md={6} sm={12}>{data.name ?? "N/A"}</Col>
                    </Row>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Description:
                        </Col>
                        <Col md={6} sm={12}>{data.description ?? "N/A"}</Col>
                    </Row>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Prompt Template ID:
                        </Col>
                        <Col md={6} sm={12}>{data.promptIdentifier ?? "N/A"}</Col>
                    </Row>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Prompt Template Identifier:
                        </Col>
                        <Col md={6} sm={12}>{promptIdentifier ?? "N/A"}</Col>
                    </Row>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Title:
                        </Col>
                        <Col md={6} sm={12}>{data.title.default ?? "N/A"}</Col>
                    </Row>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Hint Text:
                        </Col>
                        <Col md={6} sm={12}>{data.hintText.default ?? "N/A"}</Col>
                    </Row>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Min App Version:
                        </Col>
                        <Col md={6} sm={12}>{data.minAppVersion ?? "N/A"}</Col>
                    </Row>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Max App Version:
                        </Col>
                        <Col md={6} sm={12}>{data.maxAppVersion ?? "N/A"}</Col>
                    </Row>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Title Translations
                        </Col>
                    </Row>
                    <Card>
                        <CardBody>
                            {data?.title !== null ? Object.keys(data?.title).map(key => (
                                key !== "default" && <li key={key}><b>{key} : </b>{data?.title[key]}</li>
                            )) : "N/A"}
                        </CardBody>
                    </Card>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Hint Text Translations
                        </Col>
                    </Row>
                    <Card>
                        <CardBody>
                            {data?.hintText !== null ? Object.keys(data?.hintText).map(key => (
                                key !== "default" && <li key={key}><b>{key} : </b>{data?.hintText[key]}</li>
                            )) : "N/A"}
                        </CardBody>
                    </Card>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Created at:
                        </Col>
                        <Col md={6} sm={12}>{data.createdAt ?? "N/A"}</Col>
                    </Row>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Updated at:
                        </Col>
                        <Col md={6} sm={12}>{data.updatedAt ?? "N/A"}</Col>
                    </Row>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Published at:
                        </Col>
                        <Col md={6} sm={12}>{data.publishedAt ?? "N/A"}</Col>
                    </Row>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Deactivated at:
                        </Col>
                        <Col md={6} sm={12}>{data.deactivatedAt ?? "N/A"}</Col>
                    </Row>
                    {
                        showEditTask() ? (
                            <Link
                                to={{
                                    pathname: `/gen-ai/tasks/${id}/edit`
                                }}
                            >
                                <center>
                                    <Button className="btn-primary">Edit</Button></center></Link>) : null
                    }
                    <ResponseModal
                        show={failureModalDisplay}
                        onHide={failureModalClose}
                        modalheading={errorModalHeading}
                        modaltext={failureModalText}
                    />
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <TaskDropdownOptions taskId={id} />
                </CardBody>
            </Card>
        </>
    )
}
const TaskDetails = () => {
    return (
        <Container fluid className="p-0">
            <TemplateDetails />
        </Container>
    )
}
export default TaskDetails;
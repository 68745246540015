import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
} from "reactstrap"; // Import necessary components from Reactstrap
import { useParams } from "react-router-dom";
import { AddTaskEvents } from "../../../utilities/apiUtils/tasks.js";
import ResponseModal from "../ResponseModal";
import { errorModalHeading } from "../../../config/config";

const AddTaskEventForm = (props) => {
  const [formData, setFormData] = useState({
    name: "",
    task_id: "",
    display_name: "",
    screen_names: "",
    event_label: "",
  });
  const [addedEvents, setAddedEvents] = useState([]); // State to store added events
  const { id } = useParams(); // Retrieve the taskId from URL params
  const history = useHistory();

  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
    props.onHide();
  };

  useEffect(() => {
    if (id) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        task_id: id,
      }));
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const requestData = {
        ...formData,
        task_id: parseInt(formData.task_id, 10),
      };
      await AddTaskEvents(requestData);
      setAddedEvents([...addedEvents, formData]);
      setFormData({
        ...formData,
        name: "",
        display_name: "",
        screen_names: "",
        event_label: "",
      });
    } catch (err) {
      showErrorModal("Failed to add task event. Please try again later.");
    }
  };

  return (
    <Container className="carousel-form-container my-5">
      <Row className="justify-content-between align-items-center mb-4">
        <Col md={6}>
          <h1 className="carousel-form-title mb-0">Create Task Event</h1>
        </Col>
        <Col md={6} className="text-right">
          <Button onClick={() => history.push("/tasks/view-tasks")}>
            Go to Tasks Page
          </Button>
        </Col>
      </Row>
      <Card className="carousel-form-card">
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="name">Name:</Label>{" "}
                  <span className="text-danger">*</span>
                  <Input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="task_id">Task ID:</Label>{" "}
                  <span className="text-danger">*</span>
                  <Input
                    type="text"
                    name="task_id"
                    value={formData.task_id}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="display_name">Display Name:</Label>{" "}
                  <span className="text-danger">*</span>
                  <Input
                    type="text"
                    name="display_name"
                    value={formData.display_name}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label for="screen_names">Screen Names:</Label>
                  <Input
                    type="text"
                    name="screen_names"
                    value={formData.screen_names}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="event_label">Event Label:</Label>
                  <Input
                    type="text"
                    name="event_label"
                    value={formData.event_label}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="text-center mt-4">
              <Button type="submit" color="primary">
                Submit
              </Button>
            </div>
          </Form>
          {/* Display added items */}
          {addedEvents.length > 0 && (
            <Card className="mt-5">
              <CardBody>
                <h2>Added Events</h2>
                <Table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Task ID</th>
                      <th>Display Name</th>
                      <th>Screen Names</th>
                      <th>Event Label</th>
                    </tr>
                  </thead>
                  <tbody>
                    {addedEvents.map((item, index) => (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>{item.task_id}</td>
                        <td>{item.display_name}</td>
                        <td>{item.screen_names}</td>
                        <td>{item.event_label}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          )}
        </CardBody>
      </Card>
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={errorModalHeading}
        modaltext={failureModalText}
      />
    </Container>
  );
};

export default AddTaskEventForm;

import React, { useState } from "react";
import AdBannerDetailBoard from "./AdBannerDetailBoard";
import AdBannerFormContainer from "./AdBannerFormContainer";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { permissions } from "../../../utilities/commonUtil";


const AdBannerDetails = (props) => {
  let id = props.match.params.id;

  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  

  const updateAllowed = () => {
    return allowedPermissions.includes(permissions.quickReplyAdbannerEdit);
  };

  return (
    <div>
      <div style={{  }}>
        <AdBannerDetailBoard id={id} />
      </div>
      <div style={{  }}>
        <AdBannerFormContainer
          {...props}
          formName={"Details"}
          updateAllowed={updateAllowed()}
        />

        
      </div>
    </div>
  );
};

export default AdBannerDetails;

import React from 'react'

const RightSidebar = ({ width = 260, isSidebarOpen }) => {
  return (
    <nav style={{ minWidth: width, maxWidth: width }} className={"sidebar right-sidebar" +
      (!isSidebarOpen ? " toggled" : "")
    }></nav>
  )
}

export default RightSidebar
import React from "react";
import { Row } from "reactstrap";
import { tagFontSize, activatedTagColor, deactivatedTagColor } from "../config";
class CountryTags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
    };
  }
  componentDidMount() {
    if (this.props.countries !== undefined) {
      let countries = this.props.countries.filter(
        (item) => item.effect === "allow"
      );
      countries = [
        ...countries,
        ...this.props.countries.filter((item) => item.effect !== "allow"),
      ];
      this.setState({ countries }, () => {});
    }
  }
  componentWillReceiveProps() {
    if (this.props.countries !== undefined) {
      let countries = this.props.countries.filter(
        (item) => item.effect === "allow"
      );
      countries = [
        ...countries,
        ...this.props.countries.filter((item) => item.effect !== "allow"),
      ];
      this.setState({ countries }, () => {});
    }
  }
  render() {
    return (
      <Row className="px-3" style={{ flexWrap: "wrap" }}>
        {this.state.countries.length > 0 ? (
          this.state.countries.map((item, index) => (
            <p
              key={index}
              className="p-1 m-1"
              style={{
                fontSize: tagFontSize,
                backgroundColor:
                  item.effect === "allow"
                    ? activatedTagColor
                    : deactivatedTagColor,
                borderRadius: "7px",
                color: "black",
                textTransform: "capitalize",
              }}
            >
              {item.countryName.toLowerCase()}
              {item.regionName
                ? ` - ${
                    item.regionName == "GLOBAL"
                      ? "All Regions"
                      : item.regionName
                  }`
                : ""}
              {item.city
                ? ` - ${item.city == "GLOBAL" ? "All Cities" : item.city}`
                : ""}
            </p>
          ))
        ) : (
          <p>No Countries data added here</p>
        )}
      </Row>
    );
  }
}
export default CountryTags;

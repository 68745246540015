import { retrieveFromLocalStorage } from "../localStorageUtil";
import { host } from "../apiUtils/index";
import { secureFetch } from "../secureFetch";

export let fetchEnvConfigs = async () => {
  let url = `${host}/v1/internal/adx/envConfigs`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let deleteEnvConfig = async (name) => {
  let url = `${host}/v1/internal/adx/envConfig/${name}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return error.json();
  }
};

export let createEnvConfig = async (data) => {
  let url = `${host}/v1/internal/adx/envConfig`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return error.json();
  }
};

export let updateEnvConfig = async (name, data) => {
  let url = `${host}/v1/internal/adx/envConfig/${name}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return error.json();
  }
};
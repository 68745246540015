import React from 'react'
import { Card, CardBody, Button, Badge, Row, Col } from 'reactstrap';
import { X } from 'react-feather';


const ActionCard = ({ height, index, item, formName, updateAllowed, setShowActionSectionDetails, setAction, setShowActionSubmitModal, setActionIndexForEdit, removeAction, duplicateAction }) => {
    const getBadgeColor = (typeValue) => {
        switch (typeValue) {
            case "copyCode":
                return "#FFC6AC";
            case "redirect":
                return "#65647C";
            case "share":
                return "#A8DF8E";
            default:
                return "#000000"; // Default color if typeValue doesn't match any case
        }
    };

    // truncate by character count
    const truncate = (str, n) => {
        return str?.length > n ? str.substr(0, n - 1) + "..." : str;
    };

    const allowEdit = () => {
        return formName === "edit" && !updateAllowed;
    }

    return (
        <Card
            key={`card-${index}`}
            className="p-3 mb-5"
            style={{
                boxShadow: '0 0 20px rgba(0,0,0,0.4)',  // Increased shadow
                borderRadius: '15px',
                height: height,  // Set height to 200px
            }}
        >
            <CardBody style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <h4>
                    <Badge pill style={{ backgroundColor: getBadgeColor(item.type?.value) }}>
                        Type: {item.type?.label}
                    </Badge>
                    {!(allowEdit()) && (
                        <X
                            className="float-right"
                            size={20}
                            color="red"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                                removeAction(index);
                            }}
                        ></X>
                    )}
                </h4>
                {item.textToCopy && <h6>Text to Copy: {truncate(item.textToCopy, 20)}</h6>}
                {item.shareText && <h6>Share Text: {truncate(item.shareText, 20)}</h6>}
                {item.redirectionType && <h6>Redirection Type: {item.redirectionType?.label}</h6>}
                {item.redirectionViewportHeight && <h6>Redirection Viewport Height: {item.redirectionViewportHeight}</h6>}
                {item.deeplink && <h6>Deeplink: {truncate(item.deeplink, 20)}</h6>}
                {item.deeplinkType && <h6>Deeplink Type: {item.deeplinkType?.label}</h6>}
                {item.url && <h6>URL: {truncate(item.url, 20)}</h6>}
            </CardBody>
            <Row>
                <Col md={4} className="d-flex justify-content-center">
                    <Button className="btn-block" color="primary" onClick={(e) => {
                        setShowActionSectionDetails(true);
                        setAction({
                            ...item,
                            autoGeneratedDeeplink: item.deeplink,
                        });
                        setShowActionSubmitModal(true);
                    }}>View Details</Button>
                </Col>
                <Col md={4} className="d-flex justify-content-center">
                    <Button className="btn-block"
                        disabled={allowEdit()}
                        color="success" onClick={(e) => {
                            duplicateAction(item);
                        }
                        }>Duplicate</Button>
                </Col>
                <Col md={4} className="d-flex justify-content-center">
                    <Button className="btn-block"
                        disabled={allowEdit()}
                        color="info" onClick={(e) => {
                            setShowActionSectionDetails(false);
                            setAction({
                                ...item,
                                autoGeneratedDeeplink: item.deeplink,
                            });
                            setShowActionSubmitModal(true);
                            setActionIndexForEdit(index);
                        }}>Edit</Button>
                </Col>
            </Row>
        </Card>
    )
}

export default ActionCard;
import { host } from "./index";
import { retrieveFromLocalStorage } from "../localStorageUtil";

export let getPopTextStyles = async (params) => {
  let token = retrieveFromLocalStorage("token");
  let popTexts = {};

  let url =
    `${host}/v4/internal/keyboardPopTextStyles?` + new URLSearchParams(params);

  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    popTexts = await response.json();
    return popTexts;
  } catch (error) {
    return;
  }
};

export let createPopTextStyle = async (themeObj) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardPopTextStyles/create`;

  try {
    var response = await fetch(url, {
      method: "POST",
      body: themeObj,
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let getPopTextStyleDetails = async (id) => {
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/keyboardPopTextStyles/${id}/showDetails`;
  let theme;

  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let editPopTextStyle = async (styleData, id) => {
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/keyboardPopTextStyles/${id}/edit`;

  try {
    var response = await fetch(url, {
      method: "POST",
      body: styleData,
      headers: {
        contentType: "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let publishPopTextStyle = async (id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardPopTextStyles/${id}/publish`;
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let activatePopTextStyle = async (id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardPopTextStyles/${id}/activate`;

  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let deactivatePopTextStyle = async (id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardPopTextStyles/${id}/deactivate`;

  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let getRules = async (id) => {
  let rules = [];

  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardPopTextStyles/${id}/rules`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      rules = res.rules;
    }
    return rules;
  } catch (error) {
    return;
  }
};

export let saveRules = async (data, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardPopTextStyles/${id}/rules`;
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ rules: data }),
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let getClients = async () => {
  let clients = [];

  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stickers/clients`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((client) => {
        let clientObj = {
          value: client.id,
          label: client.name,
        };
        clients.push(clientObj);
      });
    }
    return clients;
  } catch (error) {
    return;
  }
};

export let getBrandCampaigns = async () => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stickers/campaigns`;

  let campaigns = [];

  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((campaign) => {
        let campaignObj = {
          value: campaign.id,
          label: campaign.name,
        };
        campaigns.push(campaignObj);
      });
    }
    return campaigns;
  } catch (error) {
    return;
  }
};

export let getFonts = async () => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host} /v4/internal/stickers/fonts`;
  let fonts = [];

  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((font) => {
        let fontObj = {
          value: font.id,
          label: font.name,
        };
        fonts.push(fontObj);
      });
    }
  } catch (error) {
    return;
  }
  return fonts;
};

export let getLanguages = async () => {
  let languages = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stickers/languages`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((language) => {
        let languageObj = {
          value: language.code,
          label: language.name,
        };
        languages.push(languageObj);
      });
    }
    return languages;
  } catch (error) {
    return;
  }
};

export let getCountries = async () => {
  let countries = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stickers/countries`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((country) => {
        let countryObj = {
          value: country.id,
          label: country.name,
        };
        countries.push(countryObj);
      });
    }
    return countries;
  } catch (error) {
    return;
  }
};

export let getRegions = async (countryID) => {
  let regions = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stickers/regions?countryID=${countryID}`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((region) => {
        let regionObj = {
          value: region.id,
          label: region.name,
        };
        regions.push(regionObj);
      });
    }
    return regions;
  } catch (error) {
    return;
  }
};

export let getCities = async (countryID, regionID) => {
  let cities = [];
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stickers/cities?countryID=${countryID}&regionID=${regionID}`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.map((city) => {
        let cityObj = {
          value: city.id,
          label: city.name,
        };
        cities.push(cityObj);
      });
    }
    return cities;
  } catch (error) {
    return;
  }
};

export let savePopTextStyleShareText = async (data, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardPopTextStyles/${id}/shareTexts`;
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let getPopTextStyleShareText = async (id) => {
  let data = [];

  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/keyboardPopTextStyles/${id}/shareTexts`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      data = res;
    }
    return data;
  } catch (error) {
    return;
  }
};
import React, { useState, useEffect } from "react";
import Search from "../../../components/Search";
import Select from "react-select";
import ThemeCard from "./components/ThemeCard";
import ResponseModal from "../ResponseModal";
import ConfirmationModal from "../ConfirmationModal";
import { Col, Container, Row, Button, Spinner } from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { Config } from "../../../config";
import {
  getKeyboardThemes,
  getKeyboardThemeCategoriesForForm,
  publishKeyboardTheme,
  deactivateKeyboardTheme,
  activateKeyboardTheme,
} from "../../../utilities/apiUtils/themes";
import {
  successModalHeading,
  failureModalHeading,
} from "../../../config/themes";

import {
  errorDescription,
  error,
  getStatus,
  allActions,
  permissions,
  redirectionOnTokenExpiry,
  getStatusColor,
  getButtonColor,
  getBtnName,
} from "../../../utilities/commonUtil";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { Link } from "react-router-dom";
import EmptyKeyboardThemes from "./components/EmptyThemes";
import { faUpload, faTimes } from "@fortawesome/free-solid-svg-icons";
import UncontrolledButtonDropdown from "reactstrap/lib/UncontrolledButtonDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import { capitalize } from "@material-ui/core";

const KeyboardThemes = () => {
  const [id, setID] = useState(null);
  const [action, setAction] = useState("");
  const [themes, setThemes] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [category, setCategory] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [categories, setCategories] = useState([]);
  const [hasMoreThemes, setHasMoreThemes] = useState(true);
  const [noThemeFound, setNoThemeFound] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [successModalState, setSuccessModalState] = useState({
    successModalDisplay: false,
    successModalText: "",
  });
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );
  const [status, setStatus] = useState("");
  const [sortBy, setSortBy] = useState(Config.UpdatedAtDescending);
  const [searchBy, setSearchBy] = useState(Config.SEARCH_BY_DEFAULT);
  const [loading, setLoading] = useState(true);
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    getThemes();
    getCategories();
    setFirstRender(false);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let params = {
        categoryID: category,
        searchString: searchBy === "name" ? searchString : "",
        id: searchBy === "id" ? searchString : "",
        status: status,
        sortBy: sortBy,
        page: page,
        limit: limit,
      };

      let response = await getKeyboardThemes(params);

      if (response.keyboardThemes !== null) {
        let allThemes = themes.concat(response.keyboardThemes);
        setThemes(allThemes);
      } else {
        setHasMoreThemes(false);
      }
    };

    fetchData();
  }, [page]);

  const getThemes = async () => {
    let params = {
      categoryID: category,
      searchString: searchBy === "name" ? searchString : "",
      id: searchBy === "id" ? searchString : "",
      status: status,
      sortBy: sortBy,
      page: page,
      limit: limit,
    };

    try {
      let response = await getKeyboardThemes(params);

      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectionOnTokenExpiry();
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(response.errorCode),
        });
      } else if (response.keyboardThemes) {
        if (response.keyboardThemes !== null) {
          setThemes(response.keyboardThemes);
          setLoading(false);
          return;
        }
      }
      setLoading(false);
      setNoThemeFound(true);
    } catch (err) {
      setLoading(false);
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const getCategories = async () => {
    try {
      let categories = await getKeyboardThemeCategoriesForForm();
      setCategories(categories);
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const handleSearchStringChange = (event) => {
    event.preventDefault();
    let { value } = event.target;
    setSearchString(value);
    if (!value.length) {
      setSearchString("");
      search();
      return;
    }
  };

  useEffect(() => {
    search();
  }, [searchString]);

  useEffect(() => {
    if (!firstRender) search();
  }, [category, status, sortBy]);

  const handleCategoryChange = (category) => {
    setCategory(category.value);
  };

  const handleStatusChange = (status) => {
    setStatus(status.value);
  };

  const handleOrderChange = (sortBy) => {
    setSortBy(sortBy.value);
  };

  const fetchMoreData = async () => {
    // to avoid repetition of same theme
    if (searchBy === "id") return;

    setPage(page + 1);
    setNoThemeFound(false);
    setHasMoreThemes(true);
  };

  const search = async (localSearchBy) => {
    setPage(0);
    setNoThemeFound(false);
    setHasMoreThemes(true);
    setSearchBy(localSearchBy || searchBy);
    setLoading(true);
    setThemes([]);

    let params = {
      categoryID: category,
      searchString: searchBy === "name" ? searchString : "",
      id: searchBy === "id" ? searchString : "",
      status: status,
      sortBy: sortBy,
      page: page,
      limit: limit,
    };

    try {
      let response = await getKeyboardThemes(params);
      if (response.keyboardThemes != null) {
        setThemes(response.keyboardThemes);
        setLoading(false);
        return;
      }
      if (page === 0) {
        setLoading(false);
        setNoThemeFound(true);
      } else {
        setLoading(false);
        setNoThemeFound(false);
      }
    } catch (err) {
      setLoading(false);
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const setConfirmationModalState = (id, btnName) => {
    setID(id);
    setAction(btnName);
    setConfirmationModalIsOpen(true);
  };

  const performAction = () => {
    if (action === allActions.publish) {
      publish(id);
    }
    if (action === allActions.deactivate) {
      deactivate(id);
    }
    if (action === allActions.activate) {
      activate(id);
    }
  };

  const handleResponse = (response) => {
    if (!response.errorCode) {
      setSuccessModalState({
        successModalDisplay: true,
        successModalText: response.description,
      });
    } else {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: response.errorCode,
      });
    }
  };

  const publish = async (id) => {
    try {
      let response = await publishKeyboardTheme(id);
      handleResponse(response);
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const deactivate = async (id) => {
    try {
      let response = await deactivateKeyboardTheme(id);
      handleResponse(response);
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const activate = async (id) => {
    try {
      let response = await activateKeyboardTheme(id);
      handleResponse(response);
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const failureModalClose = () => {
    setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
  };

  const successModelClose = () => {
    setSuccessModalState((prev) => ({ ...prev, successModalDisplay: false }));
    setThemes([]);
    setPage(0);
    setHasMoreThemes(true);
    getThemes();
  };

  const showCreateKeyboardThemesButton = () => {
    return allowedPermissions.includes(permissions.keyboardThemeCreate);
  };

  const getIcon = (status) => {
    if (status === "Deactivated") return faUpload;
    return faTimes;
  };

  const renderAllThemes = () => {
    if (themes.length === 0 && !loading) {
      return <EmptyKeyboardThemes />;
    } else {
      return themes.map((theme) => {
        let status = getStatus(theme.publishedAt, theme.deactivatedAt);
        let color = getStatusColor(status);
        let btnColor = getButtonColor(status);
        let btnName = getBtnName(status);
        return (
          <Col md="6" lg="4" key={theme.id}>
            <ThemeCard
              theme={theme}
              color={color}
              status={status}
              btnName={btnName}
              btnColor={btnColor}
              btnIcon={getIcon(status)}
              getConfirmation={setConfirmationModalState}
            />
          </Col>
        );
      });
    }
  };

  const onKeyDown = (event) => {
    if (event.keyCode === Config.KEYPRESS_DOWN) {
      search();
    }
  };

  return (
    <Container fluid className="p-0">
      <Row>
        <h1 className="mb-3 pl-3">Keyboard Themes</h1>
        {showCreateKeyboardThemesButton() ? (
          <Link
            className="d-block ml-auto mb-3 pr-3 text-decoration-none"
            to={{
              pathname: `/keyboard-theme/create-keyboard-theme`,
            }}
          >
            <Button color="primary" className="ml-auto d-flex">
              <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                Add Keyboard Theme
              </p>
            </Button>
          </Link>
        ) : null}
      </Row>
      <Row>
        <Col md={4} sm={12}>
          <Search
            placeholder="Search"
            value={searchString}
            onChange={(event) => handleSearchStringChange(event)}
            onKeyDown={(event) => onKeyDown(event)}
          ></Search>
        </Col>
        <Col md={3} sm={12}>
          <UncontrolledButtonDropdown className="mr-1 mb-1">
            <DropdownToggle caret color="success">
              {"Search By " + capitalize(searchBy) + " "}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  search("id");
                }}
              >
                ID
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  search("name");
                }}
              >
                Name
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      <Row>
        <Col md={4} sm={12}>
          <Select
            className="react-select-container mb-4"
            classNamePrefix="react-select"
            placeholder="Select category"
            onChange={(option) => handleCategoryChange(option)}
            options={categories}
          />
        </Col>
        <Col md={4} sm={12}>
          <Select
            className="react-select-container mb-4"
            classNamePrefix="react-select"
            placeholder="Select Status"
            onChange={(option) => handleStatusChange(option)}
            options={[
              { value: "", label: "All" },
              { value: "active", label: "Active" },
              { value: "deactivated", label: "Deactivated" },
              { value: "unpublished", label: "Unpublished" },
            ]}
          />
        </Col>
        <Col md={4} sm={12}>
          <Select
            className="react-select-container mb-4"
            classNamePrefix="react-select"
            placeholder="Sort By"
            onChange={(option) => handleOrderChange(option)}
            options={[
              {
                value: Config.UpdatedAtDescending,
                label: "Updated At \u2193",
              },
              {
                value: Config.UpdatedAtAscending,
                label: "Updated At \u2191",
              },
              {
                value: Config.CreatedAtDescending,
                label: "Created At \u2193",
              },
              {
                value: Config.CreatedAtAscending,
                label: "Created At \u2191",
              },
              {
                value: Config.PriorityDescending,
                label: "Priority \u2193",
              },
              {
                value: Config.ProirityAscending,
                label: "Priority \u2191",
              },
            ]}
          />
        </Col>
      </Row>

      <InfiniteScroll
        className="infinite-scroll"
        dataLength={themes.length}
        next={fetchMoreData}
        hasMore={hasMoreThemes}
        loader={
          !loading &&
          searchBy !== "id" &&
          !noThemeFound && <Spinner color="info" className=" d-flex mx-auto" />
        }
      >
        {loading ? (
          <Spinner color="info" className=" d-flex mx-auto" />
        ) : noThemeFound ? (
          <Container
            sm="12"
            md="8"
            lg="6"
            className="mx-auto d-table mt-5 pt-2 pb-5"
          >
            <h1 className="text-center">No theme found</h1>
          </Container>
        ) : (
          <React.Fragment>
            <Row className="mt-2">{renderAllThemes()}</Row>
            <ResponseModal
              show={successModalState.successModalDisplay}
              onHide={() => successModelClose()}
              modalheading={successModalHeading}
              modaltext={successModalState.successModalText}
            />
            <ResponseModal
              show={failureModalState.failureModalDisplay}
              onHide={() => failureModalClose()}
              modalheading={failureModalHeading}
              modaltext={failureModalState.failureModalText}
            />
            <ConfirmationModal
              show={confirmationModalIsOpen}
              modaltext={action}
              onHide={() => {
                setConfirmationModalIsOpen(false);
              }}
              onConfirmationTrue={() => {
                setConfirmationModalIsOpen(false);
                performAction();
              }}
            />
          </React.Fragment>
        )}
      </InfiniteScroll>
    </Container>
  );
};

export default KeyboardThemes;

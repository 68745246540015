import React, { PureComponent } from "react";
import { fetchSticker } from "../../../utilities/apiUtils";
import { getBrandCampaign } from "../../../utilities/apiUtils/brandCampaign";
import { getStickerShareText } from "../../../utilities/apiUtils/stickers";
import Loading from "../../../components/Loading";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import Search from "../../../components/Search";
import { columns } from "./StickerShareTextColumn";
import filterFactory from "react-bootstrap-table2-filter";
import TagTable from "./components/tagTable";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import {
  error,
  errorDescription,
  getStatus,
  permissions,
  getStatusColor,
  redirectTo,
  redirectOnTokenExpiry
} from "../../../utilities/commonUtil";

import ResponseModal from "../ResponseModal";
import CountryTags from "../../../components/CountryTags";
import {
  Row,
  Col,
  Badge,
  Card,
  CardBody,
  CardImg,
  CardHeader,
  CardTitle,
  Button,
  Container,
  Input,
  Label
} from "reactstrap";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import StickerInStickerPacksTable from "./components/StickerInStickerPacksTable";
import ShareTextsTable from "./components/ShareTextsTable"
import { Link } from "react-router-dom";
import { saveStickerShareText } from "../../../utilities/apiUtils/stickers";
import ManageStickerShareTexts from "./ManageStickerShareText";


class StickerDetail extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      hasResponseError: false,
      failureModalText: "",
      sticker: {},
      shareTexts: {},
      loading: true,
      status: "",
      allowedPermissions: retrieveFromLocalStorage("userPermissions"),
      stickerPacks: [],
      brandCampaign: {},
      enableShareTextInKeyboard: false,
      showModal: false,
      showImagesJson: false,
    };
  }
  handleShowModal = () => {
    this.setState({ showModal: true });
  };
  toggleImagesJson = () => {
    this.setState((prevState) => ({
      showImagesJson: !prevState.showImagesJson,
    }));
  };
  handleCloseModal = () => {
    this.setState({ showModal: false });
  };
  closeCreateStickerShareText = () => {
    this.setState({ showStickerShareText: false });
  };
  onTableChange = (type, newState) => { };
  async componentDidMount() {
    await this.getSticker();
  }

  getSticker = () => {
    fetchSticker(this.props.match.params.stickerid)
      .then((res) => {
        if (res.sticker) {
          let stickerPacks = res.stickerPacks;

          for (let i = 0; i < stickerPacks.length; i++) {
            stickerPacks[i]["status"] = getStatus(
              stickerPacks[i].publishedAt,
              stickerPacks[i].deactivatedAt
            );
          }
          this.setState({
            sticker: res.sticker,
            stickerPacks: stickerPacks,
            status: getStatus(
              res.sticker.publishedAt,
              res.sticker.deactivatedAt
            ),
          });
          this.fetchBrandCampaign(res.sticker.brandCampaignId)
          this.fetchStickerShareText(this.state.sticker.id)
        } else {
          this.setState({
            hasResponseError: true,
            failureModalText: errorDescription(res.errorCode),
          });
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          hasResponseError: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      });
  };

  fetchBrandCampaign = async (brandCampaignId) => {
    if (brandCampaignId != null) {
      await getBrandCampaign(brandCampaignId)
        .then((response) => {
          if (response) {
            let brandCampaign = response.brandCampaign
            this.setState({ brandCampaign })
          } else if (response.errorCode) {
            if (
              response.errorCode === error.tokenExpired ||
              response.errorCode === error.invalidAccessToken
            ) {
              redirectOnTokenExpiry();
            } else {
              this.setState({
                hasResponseError: true,
                failureModalText: errorDescription(response.errorCode),
              });
            }
          }
        })
        .catch((err) => {
          this.setState({
            loading: false,
            failureModalDisplay: true,
            failureModalText: errorDescription(error.unexpectedError),
          });
        });
    }
  }

  fetchStickerShareText = async (StickerId) => {
    if (StickerId != null) {
      await getStickerShareText(StickerId)
        .then((response) => {
          if (response) {

            let shareTexts = response.shareTexts
            this.setState({ shareTexts })
            let enableShareTextInKeyboard = response.enableShareTextInKeyboard
            this.setState({ enableShareTextInKeyboard })
          } else if (response.errorCode) {
            if (
              response.errorCode === error.tokenExpired ||
              response.errorCode === error.invalidAccessToken
            ) {
              redirectOnTokenExpiry();
            } else {
              this.setState({
                hasResponseError: true,
                failureModalText: errorDescription(response.errorCode),
              });
            }
          }
        })
        .catch((err) => {
          this.setState({
            loading: false,
            failureModalDisplay: true,
            failureModalText: errorDescription(error.unexpectedError),
          });
        });

    }
  }
  showEditShareTextButton = () => {
    return this.state.allowedPermissions.includes(permissions.stickerShareTextUpdate)
  }


  showEditRulesButton = () => {
    return this.state.allowedPermissions.includes(permissions.updateStickerCountry)
      && this.state.allowedPermissions.includes(permissions.listCountryCodes)
  }
  render() {
    let { sticker, showModal} = this.state
    const { showImagesJson } = this.state;
    return (
      <Container fluid className="p-0">
        {this.state.hasResponseError ? (
          <ResponseModal
            show={this.state.hasResponseError}
            onHide={() => {
              this.setState({ hasResponseError: false });
            }}
            modalheading={"Error"}
            modaltext={this.state.failureModalText}
          />
        ) : (
          <React.Fragment>
            <h1 className="h3 mb-3">Sticker Detail</h1>
            {this.state.loading ? (
              <Loading />
            ) : (
              <div>
                <Card>
                  <CardBody>
                    <Container>
                      <Row style={{ justifyContent: "space-between" }}>
                        <Col md={2} lg={3} className="d-block my-auto">
                          <CardImg
                            top
                            src="/img/white-bg.png"
                            onLoad={(e) =>
                              (e.target.src = this.state.sticker.imageURL)
                            }
                            alt="Card image cap"
                          />
                        </Col>
                        <Col md={8} lg={7} className="d-block my-auto">
                          <Row>
                            <Col>
                              <p className="p-0 m-0">
                                {" "}
                                <span className="font-weight-bolder">
                                  ID :
                                </span>{" "}
                                {this.state.sticker.id}
                              </p>
                              <p className="p-0 m-0">
                                <span className="font-weight-bolder">
                                  Image Processing Status:
                                </span>{" "}
                                <Badge
                                  className="mb-1"
                                // color="primary"
                                >
                                  {this.state.sticker.imageProcessingStatus}
                                </Badge>
                              </p>
                              <p className="p-0 m-0">
                                {" "}
                                <span className="font-weight-bolder">
                                  Premium Sticker Template :
                                </span>{" "}
                                {this.state.sticker.isPremiumStickerTemplate ? (
                                  <Badge color="success">Yes</Badge>
                                ) : (
                                  <Badge color="danger">No</Badge>
                                )}
                              </p>

                              <p className="p-0 m-0">
                                {" "}
                                <span className="font-weight-bolder">
                                Event Sticker :
                                </span>{" "}
                                {this.state.sticker.isEventSticker ? (
                                  <Badge color="success">Yes</Badge>
                                ) : (
                                  <Badge color="danger">No</Badge>
                                )}
                              </p>

                              <p className="p-0 m-0">
                                <span className="font-weight-bolder">
                                  Status:
                                </span>{" "}
                                <Badge
                                  className="mb-1"
                                  color={getStatusColor(this.state.status)}
                                >
                                  {this.state.status}
                                </Badge>
                              </p>
                              <br></br>
                              <Button variant="primary" color="primary" onClick={this.handleShowModal}>
                              Show More
                            </Button>
                            <div className="justify-content-center mt-3 row">
                              <div className="col-sm-12 col-md-3">
                                <Link to={`/stickers/${sticker.id}/edit`}>
                                  <Button class="mw-75 w-120 btn btn-primary">Edit</Button>
                                </Link>
                              </div>
                            </div>
                            <Modal show={showModal} onHide={this.handleCloseModal} centered>
                              <Modal.Header closeButton>
                                <Modal.Title>Sticker Details</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <Row className="gy-3">
                                  {/* Grid Layout */}
                                  <Col xs={6}>
                                    <strong>Default Language:</strong>
                                  </Col>
                                  <Col xs={6}>{sticker.defaultLanguage || "Not specified"}</Col>

                                  <Col xs={6}>
                                    <strong>Is Head Supported:</strong>
                                  </Col>
                                  <Col xs={6}>
                                    {sticker.isHeadSupported ? (
                                      <span className="text-success">Yes</span>
                                    ) : (
                                      <span className="text-danger">No</span>
                                    )}
                                  </Col>
                                  <Col xs={6}>
                                    <strong>Head Gender:</strong>
                                  </Col>
                                  <Col xs={6}>{sticker.headGender || "Not specified"}</Col>
                                  <Col xs={6}>
                                    <strong>Is Sticker Premium Template:</strong>
                                  </Col>
                                  <Col xs={6}>{sticker.isPremiumStickerTemplate ? (
                                      <span className="text-success">Yes</span>
                                    ) : (
                                      <span className="text-danger">No</span>
                                    )}</Col>
                                  <Col xs={6}>
                                    <strong>Is Event Sticker:</strong>
                                  </Col>
                                  <Col xs={6}>{sticker.isEventSticker ? (
                                      <span className="text-success">Yes</span>
                                    ) : (
                                      <span className="text-danger">No</span>
                                    )}</Col>
                                  <Col xs={6}>
                                    <strong>Head Type:</strong>
                                  </Col>
                                  <Col xs={6}>{sticker.headType || "Not specified"}</Col>

                                  <Col xs={6}>
                                    <strong>Use Mascot Head:</strong>
                                  </Col>
                                  <Col xs={6}>
                                    {sticker.useMascotHead ? (
                                      <span className="text-success">Yes</span>
                                    ) : (
                                      <span className="text-danger">No</span>
                                    )}
                                  </Col>

                                  <Col xs={6}>
                                    <strong>Watermark Enabled:</strong>
                                  </Col>
                                  <Col xs={6}>
                                    {sticker.isWatermarkEnabled ? (
                                      <span className="text-success">Yes</span>
                                    ) : (
                                      <span className="text-danger">No</span>
                                    )}
                                  </Col>

                                  <Col xs={6}>
                                    <strong>OTF Supported:</strong>
                                  </Col>
                                  <Col xs={6}>
                                    {sticker.isOTFSupported ? (
                                      <span className="text-success">Yes</span>
                                    ) : (
                                      <span className="text-danger">No</span>
                                    )}
                                  </Col>

                                  <Col xs={6}>
                                    <strong>Font ID:</strong>
                                  </Col>
                                  <Col xs={6}>{sticker.fontId || "Not specified"}</Col>

                                  <Col xs={6}>
                                    <strong>Start At:</strong>
                                  </Col>
                                  <Col xs={6}>{sticker.startAt || "Not specified"}</Col>

                                  <Col xs={6}>
                                    <strong>End At:</strong>
                                  </Col>
                                  <Col xs={6}>{sticker.endAt || "Not specified"}</Col>

                                  <Col xs={6}>
                                    <strong>UTM Campaign:</strong>
                                  </Col>
                                  <Col xs={6}>{sticker.utmCampaign || "Not specified"}</Col>

                                  <Col xs={6}>
                                    <strong>Is Political:</strong>
                                  </Col>
                                  <Col xs={6}>
                                    {sticker.isPolitical ? (
                                      <span className="text-success">Yes</span>
                                    ) : (
                                      <span className="text-danger">No</span>
                                    )}
                                  </Col>

                                  <Col xs={6}>
                                    <strong>Is Copyright:</strong>
                                  </Col>
                                  <Col xs={6}>
                                    {sticker.isCopyright ? (
                                      <span className="text-success">Yes</span>
                                    ) : (
                                      <span className="text-danger">No</span>
                                    )}
                                  </Col>

                                  <Col xs={6}>
                                    <strong>Content Rating:</strong>
                                  </Col>
                                  <Col xs={6}>{sticker.contentRating || "Not specified"}</Col>


                                  {/* Additional Fields */}
                                  <Col xs={6}>
                                    <strong>Brand Campaign:</strong>
                                  </Col>
                                  <Col xs={6}>
                                    {sticker?.brandCampaignId != null
                                      ? sticker.brandCampaign?.brandName
                                      : "Brand not available"}
                                  </Col>

                                  <Col xs={6}>
                                    <strong>Created At:</strong>
                                  </Col>
                                  <Col xs={6}>
                                    {sticker.createdAt
                                      ? new Date(sticker.createdAt).toLocaleString()
                                      : "Not specified"}
                                  </Col>

                                  <Col xs={6}>
                                    <strong>Published At:</strong>
                                  </Col>
                                  <Col xs={6}>
                                    {sticker.publishedAt
                                      ? new Date(sticker.publishedAt).toLocaleString()
                                      : "Not specified"}
                                  </Col>
                                  <Col xs={6}>
                                    <strong>Updated At:</strong>
                                  </Col>
                                  <Col xs={6}>
                                    {sticker.updatedAt
                                      ? new Date(sticker.updatedAt).toLocaleString()
                                      : "Not specified"}
                                  </Col> 

                                  <Col xs={6}>
                                    <strong>Deactivated At:</strong>
                                  </Col>
                                  <Col xs={6}>
                                    {sticker.deactivatedAt
                                      ? new Date(sticker.deactivatedAt).toLocaleString()
                                      : "Not specified"}
                                  </Col>

                                  <Col xs={6}>
                                    <strong>Brand Campaign ID:</strong>
                                  </Col>
                                  <Col xs={6}>{sticker.brandCampaignId || "Not specified"}</Col>

                                  <Col xs={6}>
                                    <strong>Expression ID:</strong>
                                  </Col>
                                  <Col xs={6}>{sticker.expressionId || "Not specified"}</Col>

                                  <Col xs={6}>
                                    <strong>Wig ID:</strong>
                                  </Col>
                                  <Col xs={6}>{sticker.wigId || "Not specified"}</Col>

                                  <Col xs={6}>
                                    <strong>Accessory IDs:</strong>
                                  </Col>
                                  <Col xs={6}>
                                      {sticker.accessoryIds && sticker.accessoryIds.length > 0
                                        ? JSON.parse(sticker.accessoryIds).join(", ")
                                        : "Not specified"}
                                    </Col>


                                  <Col xs={6}>
                                    <strong>Accessory Group IDs:</strong>
                                  </Col>
                                  <Col xs={6}>
                                    {(() => {
                                      try {
                                        const groupIds = sticker.accessoryGroupIds 
                                          ? JSON.parse(sticker.accessoryGroupIds) 
                                          : [];
                                        return groupIds.length > 0 ? groupIds.join(", ") : "Not specified";
                                      } catch (error) {
                                        console.error("Failed to parse accessoryGroupIds:", error);
                                        return "Not specified";
                                      }
                                    })()}
                                  </Col>

                                  <Col xs={6}>
                                    <strong>Customization Details:</strong>
                                  </Col>
                                  <Col xs={6}>
                                    <pre className="bg-light p-2 rounded">
                                      {JSON.stringify(sticker.customizationDetails, null, 2)}
                                    </pre>
                                  </Col>

                                  <Col xs={6}>
                                    <strong>Targeting Details Source:</strong>
                                  </Col>
                                  <Col xs={6}>{sticker.targettingDetailsSource || "Not specified"}</Col>

                                  <Col xs={6}>
          <strong>Images JSON:</strong>
                                  </Col>
                                  <Col xs={6}>
                                    <div>
                                    <Button
                                        variant="outline-primary"
                                        className="my-2 px-3 py-1 text-decoration-none"
                                        onClick={this.toggleImagesJson}
                                      >
                                        {showImagesJson ? "Hide JSON" : "Show JSON"}
                                      </Button>
                                      {showImagesJson && (
                                        <pre className="bg-light p-2 rounded mt-2">
                                          {JSON.stringify(JSON.parse(sticker.imagesJson || "{}"), null, 2)}
                                        </pre>
                                      )}
                                    </div>
                                  </Col>

                                  <Col xs={6}>
                                    <strong>Raw Resources:</strong>
                                  </Col>
                                  <Col xs={6}>
                                    <Button
                                      variant="primary"
                                      onClick={() => window.open(sticker.rawResourcesUrl, "_blank")}
                                    >
                                      Download
                                    </Button>
                                  </Col>
                                                                </Row>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button variant="secondary" color="primary" onClick={this.handleCloseModal}>
                                  Close
                                </Button>
                              </Modal.Footer>
                            </Modal>
                            </Col>
                            
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                      <h3>Tags</h3>
                      <TagTable tag ={this.state.sticker.tags}></TagTable>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row className="px-3">
                      <Col md={8} sm={6}>
                        <h3>Available Locations</h3>
                        <p>Locations in which countries this sticker is available</p>
                      </Col>
                      {
                        this.showEditRulesButton() ?
                          <Col md={4} sm={6}>
                            <Button color="primary"
                              className="d-block ml-auto mt-2"
                              onClick={() => {
                                redirectTo(`/sticker/${sticker.id}/locationRules`)
                              }}
                            >
                              Edit Rules
                            </Button>
                          </Col> : null
                      }
                    </Row>
                    <Col>
                      <CountryTags countries={this.state.sticker.countries}></CountryTags>
                    </Col>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Container fluid className="p-0">
                      <Row>
                        <Col xs={6}>
                          <h3 className="mb-4 mt-2 w-50 pl-3" style={{ padding: "0 ", display: "flex", alignItems: "left" }}>Share Texts</h3>
                        </Col>
                        <Col xs={6} style={{ padding: "0px 5% 0px 0px", display: "flex", alignItems: "center" }}>
                          {
                            this.showEditShareTextButton() ? <Button color="primary" className="ml-auto d-flex" style={{ padding: "0px 10px 0px 10px" }} onClick={() => this.setState({ showStickerShareText: true })}>
                              <p className="mb-1 mt-1" style={{ fontSize: "1rem", margin: "0" }}>
                                Edit
                              </p>
                            </Button> : null
                          }
                          {this.state.showStickerShareText === true && (
                            <ManageStickerShareTexts
                              show={this.state.showStickerShareText}
                              onHide={this.closeCreateStickerShareText}
                              shareTexts={this.state.shareTexts}
                              enableShareTextInKeyboard={this.state.enableShareTextInKeyboard}
                              stickerID={this.state.sticker.id}
                              action={"edit"}
                            />
                          )}
                        </Col>
                      </Row>
                      <Row className="mb-3" style={{ padding: '0px 50px 0px 0px', margin: '0px 50px 0px 0px ' }}>
                        <Col xs={12} className="d-flex align-items-left" >
                          <Input
                            type="checkbox"
                            name="enableShareTextInKeyboard"
                            checked={this.state.enableShareTextInKeyboard}
                            disabled="true"
                            style={{ padding: '0px 50px 0px 0px', margin: '15px 50px 0px 0px ' }}
                          />
                          <Label
                            className="mb-4 mt-2"
                            style={{ fontSize: "16px", padding: '0px 50px 0px 30px', margin: '0px' }}
                          >
                            Enable Share Text in Keyboard
                          </Label>
                        </Col>
                      </Row>

                      <ShareTextsTable shareTexts={this.state.shareTexts} ></ShareTextsTable>
                    </Container>
                  </CardBody>
                </Card>
                <StickerInStickerPacksTable stickerPacks={this.state.stickerPacks} />
              </div>
            )}
          </React.Fragment>
        )}
      </Container>
    );
  }
}

export default StickerDetail;

import React, { useState } from "react";
import {
  redirectToNewTab,
  getStatus,
  getStatusColor,
  getBtnName,
  getButtonColor,
  permissions,
} from "../../../../utilities/commonUtil";
import {
  Badge,
  Card,
  Container,
  CardImg,
  CardBody,
  CardTitle,
  Button,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import "./CarouselCard.css"; // Add your CSS file

const CarouselCard = (props) => {
  const { carousel, handleAction } = props;

  const [status] = useState(
    getStatus(carousel.publishedAt, carousel.deactivatedAt)
  );

  const showAction = (btnName) => {
    // Implement your logic to determine if a particular action should be shown
    return true;
  };

  const renderOrDefault = (field, defaultValue) => {
    return field || defaultValue;
  };

  return (
    <Card className="carousel-card">
      <div className="carousel-image-container">
        {carousel.imageURL ? (
          <div className="carousel-image-wrapper">
            <CardImg
              src={carousel.imageURL}
              alt="Carousel image"
              className="carousel-image"
            />
          </div>
        ) : (
          <div className="carousel-image-placeholder bg-dark"></div>
        )}
      </div>

      <div className="card-content">
        <CardBody>
          <CardTitle tag="h3" className="mb-0">
            {renderOrDefault(carousel.name, "Default Name")}
            <Badge className="float-right" color={getStatusColor(status)}>
              {status}
            </Badge>
          </CardTitle>
          <small>ID: {renderOrDefault(carousel.id, "Default ID")}</small>
          <br />
          <small className="font-weight-bold">
            Created At:{" "}
            {renderOrDefault(carousel.createdAt, "Default Created At")}
          </small>
          <br />
          <small className="font-weight-bold">
            Updated At: {renderOrDefault(carousel.updatedAt, "Not Available")}
          </small>
          <br />
          <small className="font-weight-bold">
            Published At:{" "}
            {renderOrDefault(carousel.publishedAt, "Not Available")}
          </small>
          <br />
          <small className="font-weight-bold">
            Deactivated At:{" "}
            {renderOrDefault(carousel.deactivatedAt, "Not Available")}
          </small>
        </CardBody>

        <Container className="mt-3 mb-3">
          <Row>
            <Col md="6" className="text-center">
              <Link to={`/cre/carousel-details/${carousel.id}`}>
                <Button
                  className="w-100"
                  color="info"
                >
                  Edit Carousel
                </Button>
              </Link>
            </Col>
            {showAction(getBtnName(status)) && (
              <Col md="6" className="text-center">
                <Button
                  className="w-100"
                  color={getButtonColor(status)}
                  onClick={() => {
                    handleAction(carousel.id, getBtnName(status));
                  }}
                >
                  {getBtnName(status)}
                </Button>
              </Col>
            )}
          </Row>
          <Row>
            <Col md="6" className="text-center">
              <Link to={`/cre/carousel-rules/${carousel.id}`}>
                <Button className="w-100 mt-2" color="primary">
                  Edit Targeting Rules
                </Button>
              </Link>
            </Col>
            <Col>
              <Link to={`/cre/carousel-user-attribute-rules/${carousel.id}`}>
                <Button className="w-100 mt-2" color="primary">
                  Edit User Attribute Rules
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </Card>
  );
};

export default CarouselCard;

import React from "react";
import { Badge } from "reactstrap";

export const columns = [
  {
    dataField: "id",
    text: "#",
    headerAttrs: { width: 60 },
    searchable: false,
    style: {
      cursor: "pointer",
    },
    sort: true,
  },
  {
    dataField: "username",
    text: "Username",
    searchable: true,
    style: {
      cursor: "pointer",
    },
    sort: true,
  },
  {
    dataField: "status",
    text: "Status",
    searchable: false,
    style: {
      cursor: "pointer",
    },
    formatter: (cell) => {
      if ({ cell }.cell === "Activated")
        return <Badge className="badge-success">{cell}</Badge>;
      else if ({ cell }.cell === "Deactivated")
        return <Badge className="badge-danger">{cell}</Badge>;
    },
  },
];

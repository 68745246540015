import React, { useEffect, useState } from "react";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from "moment";
import { Container, Row, Col, Card, CardBody, CardHeader, Button, FormGroup, Label } from "reactstrap";
import StatisticsGraph from "./StatisticsGraph";
import { useParams } from 'react-router-dom';
import { fetchStatisticsGraphData } from "../../../utilities/apiUtils/taasDashboard";
import Loading from "../../../components/Loading";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import { statisticsTableColumns } from "./statisticsTableColumn";
import { error, errorDescription } from "../../../utilities/commonUtil";
import { errorModalHeading } from "../../../config/config";
import ResponseModal from "../ResponseModal";

const initialStartDate = moment().format("MM/DD/YYYY")
const initialEndDate = moment().format("MM/DD/YYYY")

const ClientStatistics = () => {
    const { id } = useParams();

    const [filters, setFilters] = useState({
        startDate: initialStartDate,
        endDate: initialEndDate,
    })
    const [loading, setLoading] = useState(true);
    const [statisticsGraphData, setStatisticsGraphData] = useState([]);
    const [statisticsTableData, setStatisticsTableData] = useState([])
    const [failureModalText, setFailureModalText] = useState("");
    const [failureModalDisplay, setFailureModalDisplay] = useState(false);


    const ranges = {
        Today: [moment(), moment()],
        Yesterday: [
            moment().subtract(1, "days"),
            moment().subtract(1, "days")
        ],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month")
        ]
    };

    let failureModalClose = () => {
        setFailureModalDisplay(false);
    };

    let showErrorModal = (text) => {
        setFailureModalText(text);
        setFailureModalDisplay(true);
    };

    const getStatisticsData = async (queryFilters) => {
        try {
            let response = await fetchStatisticsGraphData(queryFilters);
            if (!response || response?.length === 0) {
                if (queryFilters.overallStats)
                    setStatisticsGraphData([]);
                else
                    setStatisticsTableData([]);
                return;
            }
            if (response.length > 0) {
                if (queryFilters.overallStats)
                    setStatisticsGraphData(response);
                else
                    setStatisticsTableData(response);
            } else {
                showErrorModal(errorDescription(response.errorCode));
            }
        } catch (err) {
            showErrorModal(errorDescription(error.unexpectedError));
        }
    }


    const handleApplyDates = (e, { startDate, endDate }) => {
        setFilters((prevState) => ({
            ...prevState,
            startDate: startDate.format("MM/DD/YYYY"),
            endDate: endDate.format("MM/DD/YYYY")
        }));
    };

    useEffect(() => {
        handleFilter();
    }, [])

    const handleFilter = () => {
        const { startDate, endDate } = filters
        const queryFilters = {
            startDate: moment(startDate).format("YYYY-MM-DD"),
            endDate: moment(endDate).add(1, "days").format("YYYY-MM-DD"),
            clientId: id,
            overallStats: true,
        }
        setLoading(true)
        Promise.all([
            getStatisticsData({ ...queryFilters }),
            getStatisticsData({ ...queryFilters, overallStats: false }),
        ]).finally(() => {
            setLoading(false)
        })
    }
    return (
        <Container fluid className="p-0">
            <Row>
                <Col sm="6" className="mb-4">
                    <h1 className="mt-2 w-50">Statistics</h1>
                    <p className="text-info">(Note: All dates and time are in UTC format)</p>
                </Col>
            </Row>
            <Row form>
                <Col md={4}>
                    <FormGroup>
                        <Label
                            className="font-weight-bolder mb-2"
                            style={{ fontSize: "14px" }}
                        >
                            Date Range <small className="text-info">(MM/DD/YYYY format)</small>
                        </Label>
                        <DateRangePicker
                            initialSettings={{ startDate: filters.startDate, endDate: filters.endDate, ranges }}
                            onApply={handleApplyDates}
                        >
                            <input
                                type="text"
                                value={filters.startDate + " - " + filters.endDate}
                                className="form-control"
                            />
                        </DateRangePicker>
                    </FormGroup>
                </Col>
                <Col className="ml-5 mt-1"> <Button className="d-block mt-4 px-3" color="primary" onClick={handleFilter}>
                    Filter
                </Button></Col>
            </Row>
            <Row form>
                <Col md={12}>
                    <StatisticsGraph
                        loading={loading}
                        data={statisticsGraphData}
                    />
                </Col>
            </Row>
            <Row form>
                <Card>
                    <CardHeader>
                        <Row className="d-flex align-items-start">
                            <Col md={7}>
                                <h4 className='mb-2'>Request Traffic</h4>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody className="pt-0">
                        {loading ? (
                            <Loading />
                        ) : (
                            <BootstrapTable
                                bootstrap4={true}
                                wrapperClasses="table-responsive"
                                hover={true}
                                bordered={false}
                                keyField="id"
                                data={statisticsTableData}
                                columns={statisticsTableColumns}
                                filter={filterFactory()}
                                pagination={paginationFactory({
                                    sizePerPage: 10,
                                    sizePerPageList: [5, 10, 25, 50],
                                })}
                                defaultSorted={[{
                                    dataField: 'createdAt',
                                    order: 'desc'
                                }]}
                                remote={{ filter: true }}
                                noDataIndication="No results found"
                            />
                        )}
                    </CardBody>
                </Card>
            </Row>
            <ResponseModal
                show={failureModalDisplay}
                onHide={failureModalClose}
                modalheading={errorModalHeading}
                modaltext={failureModalText}
            />
        </Container>
    )
}
export default ClientStatistics;
import React, { useState } from "react";
import QuickReplyCategoryFormContainer from "./QuickReplyCategoryFormContainer.js";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { permissions } from "../../../utilities/commonUtil";
import { Col } from "reactstrap";

const CreateQuickReplyCategory = (props) => {
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  const createAllowed = () => {
    return allowedPermissions.includes(permissions.quickReplyCategoryCreate);
  };

  return (
    <Col lg="12">
      <QuickReplyCategoryFormContainer
        {...props}
        formName={"Create Quick Reply Category"}
        updateAllowed={createAllowed()}
      />
    </Col>
  );
};

export default CreateQuickReplyCategory;
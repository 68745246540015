import { retrieveFromLocalStorage } from "../utilities/localStorageUtil";
import { permissions } from "../utilities/commonUtil";

const allowedPermissions = retrieveFromLocalStorage("userPermissions");


export let showEditSegmentButton = () => {
  return allowedPermissions.includes(permissions.SegmentUpdate);
};

export let showAddSegmentButton = () => {
  return allowedPermissions.includes(permissions.SegmentCreate);
};

import React, { useState } from "react";
import {
    Col,
    Row,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Button,
    Collapse,
    Badge,
} from "reactstrap";
import ResponseModal from "../../../../pages/ui-elements/ResponseModal";
import ActionCard from "./ActionCard";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import Reorder, { reorder } from "react-reorder";
import { Modal } from "react-bootstrap";
import { quickRepliesCTAActionsType, quickRepliesCTAActionsRedirectionType, quickRepliesCTAActionsDeeplinkType, clientIDOptions } from "../../../../config/quickReplies";

const QuickReplyCTAActions = ({ quickReplyTypeCard, setQuickReplyTypeCard, clientOptions, disabledForView, formName, disableAddEditActionButton }) => {
    const [ctaAction, setCtaAction] = useState({
        type: null,
        clientId: null,
        textToCopy: null,
        shareText: null,
        redirectionType: null,
        redirectionViewportHeight: null,
        deeplink: null,
        autoGeneratedDeeplink: null,
        deeplinkType: null,
        url: null,
        priority: null,
    });
    const [ctaActionsToggle, setCtaActionsToggle] = useState(true);
    const [ctaActionsByClientID, setCtaActionsByClientID] = useState([]);
    const [showCTAActionSubmitModal, setShowCTAActionSubmitModal] = useState(false);
    const [showCTAActionReorderModal, setShowCTAActionReorderModal] = useState(false);
    const [showCTAActionSectionDetails, setShowCTAActionSectionDetails] = useState(false);
    const [ctaActionIndexForEdit, setCtaActionIndexForEdit] = useState(null);
    const [failureModalState, setFailureModalState] = useState({
        failureModalDisplay: false,
        failureModalText: "",
    });
    const [clientCheckBox, setClientCheckBox] = useState({
        selectAll: true,
        aiMintKeyboard: true,
        bobbleAndroidApp: true,
        mintKeyboardPreload: true,
    });

    const addCTAActions = () => {
        setCtaAction({
            type: null,
            clientId: null,
            textToCopy: null,
            shareText: null,
            redirectionType: null,
            redirectionViewportHeight: null,
            deeplink: null,
            deeplinkType: null,
            url: null,
            priority: null,
        })
        setShowCTAActionSubmitModal(true);
    }

    const getUniqueClientIdsWithName = () => {
        let uniqueClientIds = [];
        let uniqueClientIdsWithName = [];
        if (quickReplyTypeCard.ctaActions !== undefined && quickReplyTypeCard.ctaActions !== null) {
            quickReplyTypeCard.ctaActions.map((item) => {
                if (item.clientId) {
                    uniqueClientIds.push(item.clientId.value);
                }
            });
        }
        uniqueClientIds = [...new Set(uniqueClientIds)];
        uniqueClientIds.map((item) => {
            clientOptions.map((client) => {
                if (client.value === item) {
                    uniqueClientIdsWithName.push(client);
                }
            });
        });
        return uniqueClientIdsWithName;
    };

    const onCTAActionsReorder = (
        event,
        previousIndex,
        nextIndex,
        fromId,
        toId
    ) => {
        setCtaActionsByClientID(reorder(ctaActionsByClientID, previousIndex, nextIndex));
    }

    const getBadgeColor = (typeValue) => {
        switch (typeValue) {
            case "copyCode":
                return "#FFC6AC";
            case "redirect":
                return "#65647C";
            case "share":
                return "#A8DF8E";
            default:
                return "#000000"; // Default color if typeValue doesn't match any case
        }
    };


    // remove CTAAction 
    const removeCTAAction = (index) => {
        let temp = [...quickReplyTypeCard.ctaActions];
        temp = temp.filter((_, idx) => idx !== index);
        setQuickReplyTypeCard({
            ...quickReplyTypeCard,
            ctaActions: temp,
        });
    }

    // duplicate CTAAction
    const duplicateCTAAction = (item) => {
        setQuickReplyTypeCard({
            ...quickReplyTypeCard,
            ctaActions: [...quickReplyTypeCard.ctaActions, item]
        })
    }

    // save CTAAction 
    const saveCTAAction = () => {
        if (disableAddEditActionButton(ctaAction) !== "") {

            setFailureModalState({
                failureModalDisplay: true,
                failureModalText: disableAddEditActionButton(ctaAction),
            });
            return;
        }
        if (ctaActionIndexForEdit !== null) {
            let temp = [...quickReplyTypeCard.ctaActions];
            temp[ctaActionIndexForEdit] = ctaAction;
            setQuickReplyTypeCard({
                ...quickReplyTypeCard,
                ctaActions: temp,
            });
        } else {

            ctaAction.priority = 1;
            if (ctaAction.type.value === "copyCode") {
                ctaAction.priority = 2;
            }

            if (ctaAction.type.value === "share") {
                let tempCTA = []
                if (clientCheckBox.aiMintKeyboard) {
                    let temp = { ...ctaAction };
                    temp.clientId = clientIDOptions[0];
                    tempCTA.push(temp);
                }

                if (clientCheckBox.bobbleAndroidApp) {
                    let temp = { ...ctaAction };
                    temp.clientId = clientIDOptions[1];
                    tempCTA.push(temp);
                }

                if (clientCheckBox.mintKeyboardPreload) {
                    let temp = { ...ctaAction };
                    temp.clientId = clientIDOptions[2];
                    tempCTA.push(temp);
                }

                setQuickReplyTypeCard({
                    ...quickReplyTypeCard,
                    ctaActions: [...quickReplyTypeCard.ctaActions, ...tempCTA]
                })
            }

            if (ctaAction.type.value === "redirect") {
                setQuickReplyTypeCard({
                    ...quickReplyTypeCard,
                    ctaActions: [...quickReplyTypeCard.ctaActions, ctaAction]
                })
            }
        }
        setShowCTAActionSubmitModal(false);
        setCtaAction({
            type: null,
            clientId: null,
            textToCopy: null,
            shareText: null,
            redirectionType: null,
            redirectionViewportHeight: null,
            deeplink: null,
            autoGeneratedDeeplink: null,
            deeplinkType: null,
            url: null,
            priority: null,
        })
        setCtaActionIndexForEdit(null);
        setClientCheckBox({
            selectAll: true,
            aiMintKeyboard: true,
            bobbleAndroidApp: true,
            mintKeyboardPreload: true,
        })
    }

    return (
        <>
            <Row className="mt-6">
                <Col md={6}>
                    <h1 className="h3 mb-3">CTA Actions</h1>
                </Col>
                <Col md={6}>
                    <div className="float-right" onClick={(e) => {
                        setCtaActionsToggle(!ctaActionsToggle)
                    }}>
                        {ctaActionsToggle ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                    <Button onClick={addCTAActions} className="float-right mr-2" disabled={disabledForView}>
                        <FontAwesomeIcon size="sm" icon={faPlus}></FontAwesomeIcon>
                        {" "}Add CTA Action
                    </Button>
                </Col>
            </Row>

            <Collapse isOpen={ctaActionsToggle}>
                <Card>
                    <CardBody>
                        {getUniqueClientIdsWithName().map((client, index) => (
                            <>
                                <Row key={`row-${index}`}>
                                    <Col md={6}>
                                        <h1 className="h3 mb-3">
                                            {client.label}
                                        </h1>
                                    </Col>
                                    <Col md={6}>
                                        <Button className="float-right btn-warning" onClick={
                                            (e) => {
                                                setCtaActionsByClientID(quickReplyTypeCard.ctaActions.filter((item, index) => item.clientId?.value === client?.value));
                                                setShowCTAActionReorderModal(true);
                                            }
                                        }
                                            disabled={disabledForView}
                                        >
                                            Reorder
                                        </Button>
                                    </Col>
                                </Row>

                                <Row key={`row-${index + 7}`}>
                                    {quickReplyTypeCard.ctaActions.map((item, index) => {
                                        return item?.clientId?.value === client.value && (
                                            <Col md={6}>
                                                <ActionCard
                                                    height="300px"
                                                    index={index}
                                                    item={item}
                                                    formName={formName}
                                                    updateAllowed={!disabledForView}
                                                    setShowActionSectionDetails={setShowCTAActionSectionDetails}
                                                    setAction={setCtaAction}
                                                    setShowActionSubmitModal={setShowCTAActionSubmitModal}
                                                    setActionIndexForEdit={setCtaActionIndexForEdit}
                                                    removeAction={removeCTAAction}
                                                    duplicateAction={duplicateCTAAction}
                                                />
                                            </Col>

                                        );
                                    })}
                                </Row>
                            </>
                        ))}


                    </CardBody >
                </Card>
            </Collapse>

            <Modal
                show={showCTAActionSubmitModal}
                onHide={() => {
                    setShowCTAActionSubmitModal(false);
                    setCtaActionIndexForEdit(null);
                    setCtaAction({
                        type: null,
                        clientId: null,
                        textToCopy: null,
                        shareText: null,
                        redirectionType: null,
                        redirectionViewportHeight: null,
                        deeplink: null,
                        deeplinkType: null,
                        autoGeneratedDeeplink: null,
                        url: null,
                        priority: null,
                    })
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    {showCTAActionSectionDetails === false && (
                        <Modal.Title>{ctaActionIndexForEdit === null ? "Create CTA Action" : "Edit CTA Action"}</Modal.Title>
                    )}
                </Modal.Header>

                <Modal.Body>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="type">Type<span class="text-danger">*</span></Label>
                                <Select
                                    id="type"
                                    name="type"
                                    className="react-select-container mb-4"
                                    classNamePrefix="react-select"
                                    options={quickRepliesCTAActionsType}
                                    value={ctaAction.type}
                                    isDisabled={showCTAActionSectionDetails}
                                    onChange={(e) => {
                                        setCtaAction({
                                            ...ctaAction,
                                            type: e,
                                            clientId: null,
                                            textToCopy: null,
                                            shareText: null,
                                            redirectionType: null,
                                            redirectionViewportHeight: null,
                                            deeplink: "",
                                            autoGeneratedDeeplink: "",
                                            deeplinkType: null,
                                            url: null,
                                            priority: null,
                                        })
                                    }}
                                />
                            </FormGroup>
                        </Col>

                        {ctaAction?.type?.value === "share" && ctaActionIndexForEdit === null && !showCTAActionSectionDetails && (
                            <Row className="px-4">
                                <Col md={12} className="mb-4">
                                    <strong>
                                        Select Client<span className="text-danger">*</span>
                                    </strong>
                                </Col>
                                <Col md={3}>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                id="selectAll"
                                                name="selectAll"
                                                checked={clientCheckBox.selectAll}
                                                onChange={(e) => {
                                                    setClientCheckBox({
                                                        ...clientCheckBox,
                                                        selectAll: e.target.checked,
                                                        aiMintKeyboard: e.target.checked,
                                                        bobbleAndroidApp: e.target.checked,
                                                        mintKeyboardPreload: e.target.checked,
                                                    })
                                                }}
                                            />
                                            Select All
                                        </Label>
                                    </FormGroup>
                                </Col>

                                <Col md={3}>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                id="aiMintKeyboard"
                                                name="aiMintKeyboard"
                                                checked={clientCheckBox.aiMintKeyboard}
                                                onChange={(e) => {
                                                    setClientCheckBox({
                                                        ...clientCheckBox,
                                                        aiMintKeyboard: e.target.checked,
                                                        selectAll: e.target.checked && clientCheckBox.bobbleAndroidApp && clientCheckBox.mintKeyboardPreload,
                                                    })
                                                }}
                                            />
                                            AI Mint Keyboard (Playstore)
                                        </Label>
                                    </FormGroup>
                                </Col>

                                <Col md={3}>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                id="bobbleAndroidApp"
                                                name="bobbleAndroidApp"
                                                checked={clientCheckBox.bobbleAndroidApp}
                                                onChange={(e) => {
                                                    setClientCheckBox({
                                                        ...clientCheckBox,
                                                        bobbleAndroidApp: e.target.checked,
                                                        selectAll: e.target.checked && clientCheckBox.aiMintKeyboard && clientCheckBox.mintKeyboardPreload,
                                                    })
                                                }}
                                            />
                                            Bobble Android App
                                        </Label>
                                    </FormGroup>
                                </Col>

                                <Col md={3}>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                id="mintKeyboardPreload"
                                                name="mintKeyboardPreload"
                                                checked={clientCheckBox.mintKeyboardPreload}
                                                onChange={(e) => {
                                                    setClientCheckBox({
                                                        ...clientCheckBox,
                                                        mintKeyboardPreload: e.target.checked,
                                                        selectAll: e.target.checked && clientCheckBox.aiMintKeyboard && clientCheckBox.bobbleAndroidApp,
                                                    })
                                                }}
                                            />
                                            Mint Keyboard (Preload)
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        )}

                        {ctaAction?.type !== null && ((ctaActionIndexForEdit === null && ctaAction?.type?.value !== "share") || ctaActionIndexForEdit !== null || showCTAActionSectionDetails) &&  (
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="clientId">Client ID<span className="text-danger">*</span></Label>
                                    <Select
                                        id="clientId"
                                        name="clientId"
                                        className="react-select-container mb-4"
                                        classNamePrefix="react-select"
                                        options={ctaAction.type.value === "share" ? clientIDOptions : clientOptions}
                                        value={ctaAction.clientId}
                                        isDisabled={showCTAActionSectionDetails}
                                        onChange={(e) => {
                                            setCtaAction({
                                                ...ctaAction,
                                                clientId: e
                                            })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        )}

                        {ctaAction?.type?.value === "copyCode" && (
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="textToCopy">Text to Copy<span class="text-danger">*</span></Label>
                                    <Input
                                        type="text"
                                        id="textToCopy"
                                        name="textToCopy"
                                        disabled={showCTAActionSectionDetails}
                                        value={ctaAction.textToCopy}
                                        onChange={(e) => {
                                            setCtaAction({
                                                ...ctaAction,
                                                textToCopy: e.target.value
                                            })
                                        }}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                        )}

                        {ctaAction?.type?.value === "share" && (
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="shareText">Share Text<span class="text-danger">*</span></Label>
                                    <Input
                                        type="textarea"
                                        id="shareText"
                                        name="shareText"
                                        value={ctaAction.shareText}
                                        disabled={showCTAActionSectionDetails}
                                        onChange={(e) => {
                                            setCtaAction({
                                                ...ctaAction,
                                                shareText: e.target.value
                                            })
                                        }}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                        )}

                        {ctaAction?.type?.value === "redirect" && (
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="redirectionType">Redirection Type<span class="text-danger">*</span></Label>
                                    <Select
                                        id="redirectionType"
                                        name="redirectionType"
                                        className="react-select-container mb-4"
                                        classNamePrefix="react-select"
                                        options={quickRepliesCTAActionsRedirectionType}
                                        value={ctaAction.redirectionType}
                                        isDisabled={showCTAActionSectionDetails}
                                        onChange={(e) => {
                                            setCtaAction({
                                                ...ctaAction,
                                                redirectionType: e
                                            })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        )}

                        {ctaAction?.type?.value === "redirect" && (
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="redirectionViewportHeight">Redirection Viewport Height</Label>
                                    <Input
                                        type="number"
                                        id="redirectionViewportHeight"
                                        name="redirectionViewportHeight"
                                        disabled={showCTAActionSectionDetails}
                                        value={ctaAction.redirectionViewportHeight}
                                        onChange={(e) => {
                                            setCtaAction({
                                                ...ctaAction,
                                                redirectionViewportHeight: e.target.value
                                            })
                                        }}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                        )}

                        {ctaAction?.type?.value === "redirect" && (
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="url">URL<span class="text-danger">*</span></Label>
                                    <Input
                                        type="textarea"
                                        id="url"
                                        name="url"
                                        disabled={showCTAActionSectionDetails}
                                        value={ctaAction.url}
                                        onChange={(e) => {
                                            setCtaAction({
                                                ...ctaAction,
                                                url: e.target.value
                                            })
                                        }}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                        )}

                        {ctaAction?.type !== null && (
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="deeplinkType">Deeplink Type<span class="text-danger">*</span></Label>
                                    <Select
                                        id="deeplinkType"
                                        name="deeplinkType"
                                        isDisabled={showCTAActionSectionDetails}
                                        className="react-select-container mb-4"
                                        classNamePrefix="react-select"
                                        options={quickRepliesCTAActionsDeeplinkType}
                                        value={ctaAction.deeplinkType}
                                        onChange={(e) => {
                                            setCtaAction({
                                                ...ctaAction,
                                                deeplinkType: e,
                                                deeplink: "",
                                            })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        )}

                        {ctaAction?.type !== null && (
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="deeplink">Deeplink<span class="text-danger">*</span></Label>
                                    <Input
                                        type="textarea"
                                        id="deeplink"
                                        name="deeplink"
                                        value={ctaAction?.deeplinkType?.value === "default" ? ctaAction.autoGeneratedDeeplink : ctaAction.deeplink}
                                        disabled={showCTAActionSectionDetails || (ctaAction.deeplinkType?.value === "default" || ctaAction.deeplinkType === null)}
                                        onChange={(e) => {
                                            setCtaAction({
                                                ...ctaAction,
                                                deeplink: e.target.value
                                            })
                                        }}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                        )}


                    </Row>

                    <Modal.Footer style={{
                        padding: '0px',
                    }}>
                        <Button
                            color="primary"
                            className="btn-lg"
                            onClick={() => {
                                saveCTAAction();
                            }}
                            disabled={showCTAActionSectionDetails}
                        >
                            {ctaActionIndexForEdit !== null ? "Edit" : "Add"}
                        </Button>
                    </Modal.Footer>
                </Modal.Body>

            </Modal>

            <Modal
                show={showCTAActionReorderModal}
                onHide={() => {
                    setShowCTAActionReorderModal(false);
                    setCtaActionsByClientID([]);
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Reorder CTA Actions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card style={{
                        boxShadow: '0 0 20px rgba(0,0,0,0.4)',  // Increased shadow
                        borderRadius: '15px'
                    }}>
                        <CardBody>
                            <Row>
                                <Col md={12}>
                                    <strong>Note :</strong>
                                    The Cards can be reordered by dragging at the desired position vertically. The topmost card will be assigned the highest priority.
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Reorder reorderId="reorder-list" onReorder={onCTAActionsReorder} className="justify-content-center">
                        {ctaActionsByClientID.map((item, index) => (
                            <Col md={12} key={`card-${index}`}>
                                <Card
                                    className="p-3 mb-5 mx-auto"
                                    style={{
                                        boxShadow: '0 0 20px rgba(0,0,0,0.4)',
                                        borderRadius: '15px',
                                        width: '50%',
                                    }}
                                >
                                    <CardBody style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                        <h4>
                                            <Badge pill style={{ backgroundColor: getBadgeColor(item.type?.value) }}>
                                                Type: {item.type?.label}
                                            </Badge>
                                        </h4>

                                        {item.textToCopy && <h6>Text to Copy: {item.textToCopy}</h6>}
                                        {item.shareText && <h6>Share Text: {item.shareText}</h6>}
                                        {item.redirectionType && <h6>Redirection Type: {item.redirectionType?.value}</h6>}
                                        {item.redirectionViewportHeight && <h6>Redirection Viewport Height: {item.redirectionViewportHeight}</h6>}
                                        {item.deeplink && <h6>Deeplink: {item.deeplink}</h6>}
                                        {item.deeplinkType && <h6>Deeplink Type: {item.deeplinkType?.value}</h6>}
                                        {item.url && <h6>URL: {item.url}</h6>}
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Reorder>

                    <Modal.Footer>
                        <Button color="primary" onClick={(e) => {
                            setShowCTAActionReorderModal(false);
                            // first remove all the cta actions of that client id
                            let temp = [...quickReplyTypeCard.ctaActions];
                            temp = temp.filter((item, index) => item.clientId?.value !== ctaActionsByClientID[0]?.clientId?.value);
                            // now add a field priority to the reordered cta actions
                            ctaActionsByClientID.map((item, index) => {
                                temp.push({
                                    ...item,
                                    priority: ctaActionsByClientID.length - index,
                                })
                            })

                            // now add the temp to the quickReplyTypeCard
                            setQuickReplyTypeCard({
                                ...quickReplyTypeCard,
                                ctaActions: temp,
                            })

                            // empty the ctaActionsByClientID
                            setCtaActionsByClientID([]);
                        }}>Save Order</Button>
                    </Modal.Footer>
                </Modal.Body>

            </Modal>

            <ResponseModal
                show={failureModalState.failureModalDisplay}
                onHide={() =>
                    setFailureModalState({
                        failureModalDisplay: false,
                        failureModalText: "",
                    })
                }
                modalheading={"Error"}
                modaltext={failureModalState.failureModalText}
            />
        </>
    )
}

export default QuickReplyCTAActions;
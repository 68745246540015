/* eslint-disable default-case */
import React, { useState, useEffect } from "react";
import Search from "../../../components/Search";
import { Link } from "react-router-dom";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import Loading from "../../../components/Loading";
import ContentPromptCard from "./components/ContentPromptCard";
import { Container, Button, Col, Row } from "reactstrap";
import {
  error,
  errorDescription,
  redirectionOnTokenExpiry,
  allActions,
  permissions,
} from "../../../utilities/commonUtil";
import {
  getContentUpdatePrompts,
  publishContentUpdatePrompt,
  activateContentUpdatePrompt,
  deactivateContentUpdatePrompt,
} from "../../../utilities/apiUtils/contentPrompts";
import { Config } from "../../../config/UserConfig";
import ResponseModal from "../ResponseModal";
import ConfirmationModal from "../ConfirmationModal";
import {
  successModalHeading,
  errorModalHeading,
  publishSuccess,
  deactivateSuccess,
  activateSuccess,
} from "../../../config/config";
const ContentPrompt = () => {
  const [loading, setLoading] = useState(true);
  const [prompts, setPrompts] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedPrompts, setSearchedPrompts] = useState([]);
  const [successModal, setSuccessModal] = useState({
    display: false,
    text: "",
  });
  const [failureModal, setFailureModal] = useState({
    display: false,
    text: "",
  });
  const [id, setId] = useState("");
  const [action, setAction] = useState("");
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  useEffect(() => {
    if (!successModal.display) {
      let getContentPrompts = async () => {
        try {
          let response = await getContentUpdatePrompts();
          if (response.errorCode) {
            if (
              response.errorCode === error.tokenExpired ||
              response.errorCode === error.invalidAccessToken
            )
              redirectionOnTokenExpiry();
            setLoading(false);
            setFailureModal({
              display: true,
              text: errorDescription(response.errorCode),
            });
          } else if (response.prompts) {
            setPrompts(response.prompts);
            setSearchedPrompts(response.prompts);
            setLoading(false);
          }
        } catch (err) {
          setLoading(false);
          setFailureModal({
            display: true,
            text: errorDescription(error.unexpectedError),
          });
        }
      };
      getContentPrompts();
    }
  }, [successModal]);

  let handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
    if (!event.target.value.length) {
      reset();
    }
    event.preventDefault();
  };
  let reset = () => {
    setSearchedPrompts(prompts);
  };
  let onKeyDown = (event) => {
    if (event.keyCode === Config.KEYPRESS_DOWN) {
      search();
    }
  };
  let search = () => {
    if (searchText === "") return;
    let searchedPrompts = filterPrompts(prompts);
    setSearchedPrompts(searchedPrompts);
  };

  //Filter content prompts that include search text in their name
  let filterPrompts = (prompts) => {
    return prompts.filter((prompt) => {
      let text = prompt.additionalDetails.text.en;
      return text.toLowerCase().includes(searchText.toLowerCase());
    });
  };
  let showCreatePromptsButton = () => {
    return allowedPermissions.includes(permissions.contentUpdatePromptCreate);
  };
  let setConfirmationModalState = (id, btnName) => {
    setId(id);
    setAction(btnName);
    setConfirmationModalIsOpen(true);
  };
  let successModalClose = () => {
    setSuccessModal({ ...successModal, display: false });
  };
  let failureModalClose = () => {
    setFailureModal({ ...failureModal, display: false });
  };

  search = () => {
    if (searchText === "") return;
    let searchedPrompts = filterPrompts(prompts);
    setSearchedPrompts(searchedPrompts);
  };
  let performAction = () => {
    if (action === allActions.publish) {
      publish(id);
    }
    if (action === allActions.deactivate) {
      deactivate(id);
    }
    if (action === allActions.activate) {
      activate(id);
    }
  };

  let getResponseStatus = (action) => {
    switch (action) {
      case allActions.publish:
        return publishSuccess;
      case allActions.deactivate:
        return deactivateSuccess;
      case allActions.activate:
        return activateSuccess;
    }
  };

  let handleResponse = (response, action) => {
    if (!response.errorCode) {
      let text = getResponseStatus(action);
      setSuccessModal({ display: true, text: text });
      return;
    } else
      setFailureModal({
        display: true,
        text: errorDescription(response.errorCode),
      });
  };

  let publish = async (id) => {
    let response = await publishContentUpdatePrompt(id);
    handleResponse(response, allActions.publish);
  };

  let deactivate = async (id) => {
    let response = await deactivateContentUpdatePrompt(id);
    handleResponse(response, allActions.deactivate);
  };

  let activate = async (id) => {
    let response = await activateContentUpdatePrompt(id);
    handleResponse(response, allActions.activate);
  };
  return (
    <Container>
      <Row>
        <Col md="4" sm="12">
          <Search
            value={searchText}
            onChange={handleSearchTextChange}
            onKeyDown={onKeyDown}
          />
        </Col>
        <Col md="4" sm="2">
          <Button color="success" onClick={search}>
            Search by text
          </Button>
        </Col>
      </Row>
      <Row>
        <h1 className="mb-4 mt-2 w-50 pl-3">Content Update Prompts</h1>
        {showCreatePromptsButton ? (
          <Link
            className="d-block ml-auto mb-4 pr-3 text-decoration-none"
            to={{
              pathname: `/cp/content-prompts/create`,
              state: {
                formType: "create",
              },
            }}
          >
            <Button color="primary" className="ml-auto d-flex">
              <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                Create Prompt
              </p>
            </Button>
          </Link>
        ) : null}
      </Row>
      {loading ? (
        <Loading />
      ) : (
        <Row>
          {Object(searchedPrompts).length === 0 ? (
            <Container className="mt-5">
              <h3 className="text-center">
                No Content Update Prompts Avaiable.
              </h3>
              <h4 className="text-center">
                Enter a valid search tag to view prompts.
              </h4>
            </Container>
          ) : (
            Object(searchedPrompts).map((prompt, index) => {
              return (
                <Col md={6} key={index}>
                  <ContentPromptCard
                    prompt={prompt}
                    setConfirmationModalState={setConfirmationModalState}
                  />
                </Col>
              );
            })
          )}
        </Row>
      )}
      <ResponseModal
        show={successModal.display}
        onHide={successModalClose}
        modalheading={successModalHeading}
        modaltext={successModal.text}
      />
      <ResponseModal
        show={failureModal.display}
        onHide={failureModalClose}
        modalheading={errorModalHeading}
        modaltext={failureModal.text}
      />
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={action}
        onHide={() => {
          setConfirmationModalIsOpen(false);
        }}
        onConfirmationTrue={() => {
          setConfirmationModalIsOpen(false);
          performAction();
        }}
      />
    </Container>
  );
};

export default ContentPrompt;

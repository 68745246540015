import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  Col,
  Container,
  Row,
  Button,
  FormGroup,
  Label,
  Input,
  Form,
  Card,
  CardBody,
  CardImg,
  Spinner,
} from "reactstrap";
import {
  getStoriesList,
  createSotdData,
  getSotdDetailsData,
  updateSotdDetailsData,
  getStoriesData,
  getStoryDetailsData,
  getCountries,
  getRegions,
  getCities,
  fetchSotdTargetingRulesData,
  updateSotdTargetingRulesData,
} from "../../../utilities/apiUtils/stories";
import { successModalHeading, errorModalHeading } from "../../../config/config";
import { getClients } from "../../../utilities/apiUtils/cre";
import { useParams, useLocation, useHistory } from "react-router-dom";
import TargetingRules from "../TargetingRules";
import ResponseModal from "../ResponseModal";
import ConfirmationModal from "../ConfirmationModal";
import {
  errorDescription,
  error,
  redirectionOnTokenExpiry,
  redirectTo,
} from "../../../utilities/commonUtil";
import {
  getdateValue,
  truncateTextByChar,
  sotdConfig,
  sotdUpdatePermission,
  sotdCreatePermission,
} from "../../../config/configStoryDashboard";

const CreateSotd = () => {
  const [selectedStory, setSelectedStory] = useState(null);
  const [forceEnableNotification, setForceEnableNotification] = useState({
    value: false,
    label: "No",
  });
  const [priority, setPriority] = useState(0);
  const [startAt, setStartAt] = useState(null);
  const [endAt, setEndAt] = useState(null);
  const [stories, setStories] = useState([]);
  const [videoPreviewURL, setVideoPreviewURL] = useState(null);
  const [staticPreviewURL, setStaticPreviewURL] = useState(null);
  const [isTargetingDataSet, setIsTargetingDataSet] = useState(false);
  const [triggerTargetingRulesUpdate, setTriggerTargetingRulesUpdate] =
    useState(false);
  const [action, setAction] = useState("");
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [successModalState, setSuccessModalState] = useState({
    successModalDisplay: false,
    successModalText: "",
  });
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });
  const [submitLoader, setSubmitLoader] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [targetingRules, setTargetingRules] = useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [sotdId, setSotdId] = useState(searchParams.get("sotdId"));
  const id = searchParams.get("id");

  useEffect(() => {
    if (!sotdId && !id) {
      fetchStories();
    }
  }, []);

  useEffect(() => {
    if (id) fetchAddToSotdData(id);
    else if (sotdId) fetchGetStoriesDetailsData(sotdId);
  }, [sotdId, id]);

  useEffect(() => {
    if (selectedStory) {
      fetchStoryPreviewDetails(selectedStory.value);
    }
  }, [selectedStory]);

  const fetchStoryPreviewDetails = async (id) => {
    try {
      const response = await getStoryDetailsData(id);
      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectionOnTokenExpiry();
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: response.errorDescription,
        });
      } else {
        if (response?.videoPreviewURL) {
          setVideoPreviewURL(response.videoPreviewURL);
        } else {
          setVideoPreviewURL(null);
        }
        if (response?.staticPreviewURL) {
          setStaticPreviewURL(response.staticPreviewURL);
        } else {
          setStaticPreviewURL(null);
        }
      }
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const fetchAddToSotdData = async (sotdId) => {
    await getStoriesList()
      .then((response) => {
        // Check if the response contains any error
        if (response && response.errorCode) {
          // Show failure modal with error description
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken
          )
            redirectionOnTokenExpiry();
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: response.errorDescription,
          });
        } else {
          setStories(response);
          const selected = response.find((story) => story.id == sotdId);
          setSelectedStory({
            id: selected.id,
            value: selected.id,
            label: selected.title,
          });
        }
      })
      .catch((err) => {
        // Show failure modal with unexpected error description
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      });
  };

  useEffect(() => {
    if (isTargetingDataSet) {
      setConfirmationModalState();
    }
  }, [isTargetingDataSet]);

  const fetchGetStoriesDetailsData = async (sotdId) => {
    const stories = await getStoriesList();
    await getSotdDetailsData(sotdId)
      .then((response) => {
        // Check if the response contains any error
        if (response && response.errorCode) {
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: response.errorDescription,
          });
        } else {
          const data = response;
          const selected = stories.find((story) => story.id === data.storyId);
          setSelectedStory({
            id: data.storyId,
            value: data.storyId,
            label: selected ? selected.title : "",
          });
          setForceEnableNotification({
            value: data.forceEnableStoryOfTheDayNotification,
            label: data.forceEnableStoryOfTheDayNotification ? "Yes" : "No",
          });
          setPriority(data.priority);
          setStartAt(data.startAt ? getdateValue(data.startAt) : null);
          setEndAt(data.endAt ? getdateValue(new Date(data.endAt)) : null);
        }
      })
      .catch((err) => {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      });
  };

  const updateData = async (sotdId) => {
    await getSotdDetailsData(sotdId)
      .then((response) => {
        // Check if the response contains any error
        if (response && response.errorCode) {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken
          )
            redirectionOnTokenExpiry();
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: response.errorDescription,
          });
        } else {
          const data = response;
          const selected = stories.find((story) => story.id === data.storyId);
          setSelectedStory({
            id: data.storyId,
            value: data.storyId,
            label: selected ? selected.title : "",
          });
          setForceEnableNotification({
            value: data.forceEnableStoryOfTheDayNotification,
            label: data.forceEnableStoryOfTheDayNotification ? "Yes" : "No",
          });
          setPriority(data.priority);
          setStartAt(data.startAt ? getdateValue(data.startAt) : null);
          setEndAt(data.endAt ? getdateValue(new Date(data.endAt)) : null);
        }
      })
      .catch((error) => {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      });
  };

  const fetchStories = async () => {
    try {
      const response = await getStoriesList();
      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectionOnTokenExpiry();
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: response.errorDescription,
        });
      } else {
        setStories(response);
      }
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const setConfirmationModalState = (e) => {
    e.preventDefault();
    setAction(sotdConfig.SUBMIT);
    setConfirmationModalIsOpen(true);
  };

  const failureModalClose = () => {
    setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
  };

  const successModelClose = () => {
    setSuccessModalState((prev) => ({ ...prev, successModalDisplay: false }));
    if (action === sotdConfig.SAVE_TARGETING_RULES)
      setTriggerTargetingRulesUpdate((prev) => !prev);
  };

  const performAction = () => {
    if (action === sotdConfig.SUBMIT) {
      handleSubmit();
    } else if (action === sotdConfig.SAVE_TARGETING_RULES) {
      updateTargetingRulesOnServer();
    }
  };

  const saveRules = async (rules) => {
    setTargetingRules(rules);
    setAction(sotdConfig.SAVE_TARGETING_RULES);
    setConfirmationModalIsOpen(true);
  };

  const updateTargetingRulesOnServer = async () => {
    setShowLoader(true);
    try {
      const response = await updateSotdTargetingRulesData(
        targetingRules,
        sotdId
      );
      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectionOnTokenExpiry();
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: response.errorDescription,
        });
      } else if (response) {
        setSuccessModalState({
          successModalDisplay: true,
          successModalText: "Targeting rules updated successfully",
        });
      }
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
    setShowLoader(false);
  };

  const handleSubmit = async (e) => {
    if (selectedStory?.value === null) return;

    setSubmitLoader(true);
    const data = {
      storyId: selectedStory?.value,
      forceEnableStoryOfTheDayNotification:
        forceEnableNotification?.label == "No" ? false : true,
      priority,
      startAt: startAt ? new Date(startAt) : null,
      endAt: endAt ? new Date(endAt) : null,
    };
    if (sotdId !== null) {
      data.publishedAt = null;
      data.deactivatedAt = null;
    }
    if (sotdId === null) {
      try {
        let response = await createSotdData(data);
        if (response.errorCode) {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken
          )
            redirectionOnTokenExpiry();
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: response.errorDescription,
          });
        } else if (response) {
          setSuccessModalState({
            successModalDisplay: true,
            successModalText: "Story of the day created successfully",
          });
          redirectTo(`/stories/sotd/create?sotdId=${response.id}`);
        }
      } catch (err) {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      }
    } else {
      try {
        const response = await updateSotdDetailsData(data, sotdId);
        if (response.errorCode) {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken
          )
            redirectionOnTokenExpiry();
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: response.errorDescription,
          });
        } else if (response) {
          setSuccessModalState({
            successModalDisplay: true,
            successModalText: "Story of the day updated successfully",
          });
          updateData(response.id);
        }
      } catch (err) {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      }
    }
    setSubmitLoader(false);
  };

  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <Form onSubmit={(e) => setConfirmationModalState(e)}>
          <Card>
            <CardBody>
              <Row>
                <Col md={6}>
                  <Col sm={12}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "16px" }}
                      >
                        Story
                      </Label>
                      <Select
                        id="story"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        name="story"
                        placeholder="Select Story"
                        value={selectedStory}
                        onChange={setSelectedStory}
                        options={
                          stories
                            ? stories.map((story) => ({
                                value: story.id,
                                label: truncateTextByChar(
                                  story.title || " ",
                                  60
                                ),
                                id: story.id, // Add the id to each option
                              }))
                            : []
                        }
                        isDisabled={sotdId !== null || id !== null}
                        required
                        getOptionLabel={(option) =>
                          `${option.id} - ${option?.label !== undefined ? option.label : ""}`
                        } // Display both id and title in the options
                        getOptionValue={(option) => option.id} // Use the id as the value for each option
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={12} className="d-flex justify-content-center">
                    {videoPreviewURL ? (
                      <video
                        src={videoPreviewURL}
                        controls
                        controlsList="nodownload noremoteplayback"
                        className="p-2"
                        style={{
                          cursor: "pointer",
                          width: "40%",
                        }}
                      />
                    ) : staticPreviewURL ? (
                      <CardImg
                        top
                        src={staticPreviewURL ?? ""}
                        alt="Card image cap"
                        className="p-2"
                        style={{
                          width: "40%",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <CardImg
                        src={"/img/no-image-icon.png"}
                        alt="Card image"
                        style={{
                          height: "40%",
                          width: "40%",
                        }}
                      />
                    )}
                  </Col>
                </Col>
                <Col md={6}>
                  {sotdId && (
                    <Col sm={12}>
                      <FormGroup>
                        <Label
                          className="font-weight-bolder mb-2"
                          style={{ fontSize: "16px" }}
                        >
                          SOTD ID
                        </Label>
                        <Input type="text" value={sotdId} disabled />
                      </FormGroup>
                    </Col>
                  )}
                  <Col sm={12}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "16px" }}
                      >
                        Force Enable SOTD Notification
                      </Label>
                      <Select
                        id="forceEnableNotification"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        name="forceEnableNotification"
                        placeholder="Select"
                        value={forceEnableNotification}
                        onChange={(selectedOption) =>
                          setForceEnableNotification(selectedOption)
                        }
                        options={[
                          {
                            value: true,
                            label: "Yes",
                          },
                          {
                            value: false,
                            label: "No",
                          },
                        ]}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={12}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "16px" }}
                      >
                        Priority:
                      </Label>
                      <Input
                        type="number"
                        name="priority"
                        onChange={(e) => setPriority(Number(e.target.value))}
                        placeholder="Priority"
                        value={priority}
                        min={0}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={12}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "16px" }}
                      >
                        Start At
                      </Label>
                      <br />
                      <Input
                        type="date"
                        name="startAt"
                        onChange={(e) => setStartAt(e.target.value)}
                        value={getdateValue(startAt)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={12}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "16px" }}
                      >
                        End At
                      </Label>
                      <br />
                      <Input
                        type="date"
                        name="endAt"
                        onChange={(e) => setEndAt(e.target.value)}
                        value={getdateValue(endAt)}
                      />
                    </FormGroup>
                  </Col>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between align-items-center">
                {!sotdId ? (
                  <>
                    <Col sm={9} className="d-flex align-items-center">
                      <span style={{ fontSize: 13 }}>⚠️</span>
                      <span
                        className="ml-2 text-warning font-weight-bold"
                        style={{ fontSize: 13 }}
                      >
                        The targeting details for this SOTD will be inherited
                        from the story. You can update the targeting details
                        later on if required.
                      </span>
                    </Col>
                    {((sotdId && sotdUpdatePermission()) ||
                      (!sotdId && sotdCreatePermission())) && (
                      <Col sm={3} className="d-flex justify-content-end">
                        <Button
                          color="primary"
                          type="submit"
                          disabled={!selectedStory}
                        >
                          {!submitLoader ? (
                            "Submit"
                          ) : (
                            <Spinner color="info" className=" d-flex mx-auto" />
                          )}
                        </Button>
                      </Col>
                    )}
                  </>
                ) : (
                  <Col sm={12} className="d-flex justify-content-end">
                    <Button
                      color="primary"
                      type="submit"
                      disabled={!selectedStory}
                    >
                      {!submitLoader ? (
                        "Submit"
                      ) : (
                        <Spinner color="info" className=" d-flex mx-auto" />
                      )}
                    </Button>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>

          {sotdId && (
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <TargetingRules
                      id={sotdId}
                      showAgeGenderRules={true}
                      showCustomAudienceTargetingRules={false}
                      showPlacementRules={false}
                      showClientRules={true}
                      showHardFiltering={false}
                      isAdvertisingAttributes={true}
                      getRules={fetchSotdTargetingRulesData}
                      saveRules={saveRules}
                      getClients={getClients}
                      getCountries={getCountries}
                      getRegions={getRegions}
                      getCities={getCities}
                      updateRulesAllowed={true}
                      triggerTargetingRulesUpdate={triggerTargetingRulesUpdate}
                      setIsTargetingDataSet={setIsTargetingDataSet}
                      showLoader={showLoader}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}
        </Form>
      </Container>
      <ResponseModal
        show={successModalState.successModalDisplay}
        onHide={() => successModelClose()}
        modalheading={successModalHeading}
        modaltext={successModalState.successModalText}
      />
      <ResponseModal
        show={failureModalState.failureModalDisplay}
        onHide={() => failureModalClose()}
        modalheading={errorModalHeading}
        modaltext={failureModalState.failureModalText}
      />
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={action}
        onHide={() => {
          setConfirmationModalIsOpen(false);
        }}
        onConfirmationTrue={(e) => {
          setConfirmationModalIsOpen(false);
          performAction();
        }}
      />
    </React.Fragment>
  );
};

export default CreateSotd;

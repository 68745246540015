import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import Select, { components } from "react-select";
import { FaPlus, FaMinus } from "react-icons/fa";
import { Row, Col, FormGroup, Label, Input, Form, Button, Badge, CustomInput } from "reactstrap";
import ToolTip from "../../../utilities/ToolTip";
import { generatePriority, isPartnerAd, metadataOverrideAllActions, showAdMetadataResetButton, showAdMetadataUpdateButton, showAdPromoteUpdateButton, maxAlternateLongTitles } from '../../../config/configSmartSuggestions';
import ResponseModal from '../ResponseModal';
import ConfirmationModal from '../ConfirmationModal';
import { replaceUnderscoreAndCapitalize } from '../../../utilities/commonUtil';
import { fetchSmartSuggestionCategories } from '../../../utilities/apiUtils/smartSuggestions';
import { Link } from "react-router-dom";
import SmartSuggestionDetails from './SmartSuggestionDetails';

const detailsInitialState = {
  title: "",
  titleLong: "",
  subTitle: "",
  description: "",
  categories: []
}

const creativesInitialState = {
  staticIcon: null,
  animatedIcon: null,
  providerIcon: null,
  staticIconSrc: "",
  animatedIconSrc: "",
  providerIconSrc: "",
  staticIconFilename: "",
  animatedIconFilename: "",
  providerIconFilename: "",
  staticIconAspectRatio: null,
  animatedIconAspectRatio: null,
  providerIconAspectRatio: null,
  staticIconOverride: false,
  animatedIconOverride: false,
  providerIconOverride: false,
}

const SmartSuggestionsMetadataOverrideModal = ({ show = false, adDetails, isSupportedStoryAds = false, promoteAd = () => { }, onReset = () => { }, onSubmit = () => { }, onHide = () => { }, onManageStoryAds = () => { } }) => {
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");
  const [action, setAction] = useState("");

  const [details, setDetails] = useState(detailsInitialState)
  const [creatives, setCreatives] = useState(creativesInitialState)
  const [creativeUIDetails, setCreativeUIDetails] = useState({
    uiTilesDetails: { isDisabled: false },
    uiDetailsCardDetails: { isDisabled: false },
    uiProductsCardDetails: { isDisabled: false },
    uiProductsCardTwoDetails: { isDisabled: false },
    uiProductsCardThreeDetails: { isDisabled: false }
  })
  const [detailsCardFieldsEdit, setDetailsCardFieldsEdit] = useState({
    title: false,
    subTitle: false
  })
  const [isPromoted, setIsPromoted] = useState(false)
  const [alternateLongTitles, setAlternateLongTitles] = useState([])
  const [categoriesOptions, setCategoriesOptions] = useState([])
  const [isOverrideCategories, setIsOverrideCategories] = useState(false)

  useEffect(() => {
    getCategories()
  }, [])

  useEffect(() => {
    if (categoriesOptions?.length > 0) {
      if (adDetails?.id) {
        setDetails((prevState) => ({
          ...prevState,
          title: adDetails?.title ?? "",
          titleLong: adDetails?.titleLong ?? "",
          subTitle: adDetails?.subTitle ?? "",
          categories: adDetails?.categories?.map(cat => {
            return {
              label: replaceUnderscoreAndCapitalize(cat),
              value: categoriesOptions.find(option => option.label === replaceUnderscoreAndCapitalize(cat))?.value ?? cat
            }
          }) ?? []
        }))

        setCreativeUIDetails((prevState) => ({
          ...prevState,
          uiTilesDetails: adDetails?.uiTilesDetails,
          uiDetailsCardDetails: adDetails?.uiDetailsCardDetails,
          uiProductsCardDetails: adDetails?.uiProductsCardDetails,
          uiProductsCardTwoDetails: adDetails?.uiProductsCardTwoDetails,
          uiProductsCardThreeDetails: adDetails?.uiProductsCardThreeDetails
        }))
        setDetailsCardFieldsEdit((prevState) => ({
          ...prevState,
          title: !!adDetails?.uiDetailsCardDetails?.title,
          subTitle: !!adDetails?.uiDetailsCardDetails?.subTitle
        }))

        setCreatives((prevState) => ({
          ...prevState,
          staticIconSrc: adDetails?.staticIconSrc,
          animatedIconSrc: adDetails?.animatedIconSrc,
          providerIconSrc: adDetails?.providerIconSrc,
          staticIconFilename: adDetails?.staticIconFilename,
          animatedIconFilename: adDetails?.animatedIconFilename,
          providerIconFilename: adDetails?.providerIconFilename,
          staticIconAspectRatio: adDetails?.staticIconAspectRatio,
          animatedIconAspectRatio: adDetails?.animatedIconAspectRatio,
          providerIconAspectRatio: adDetails?.providerIconAspectRatio,
          staticIconOverride: adDetails?.staticIconOverride ?? false,
          animatedIconOverride: adDetails?.animatedIconOverride ?? false,
          providerIconOverride: adDetails?.providerIconOverride ?? false,
        }))

        setIsPromoted(adDetails?.isPromoted)
        setAlternateLongTitles(adDetails?.alternateLongTitles ?? [])

        if (adDetails?.categories?.length > 0) setIsOverrideCategories(true)
        else setIsOverrideCategories(false)
      }
    }
  }, [adDetails, categoriesOptions])

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  const getCategories = async () => {
    try {
      let response = await fetchSmartSuggestionCategories()
      const options = []
      if (response?.categories?.length > 0) {
        response.categories.forEach(category => {
          options.push({
            label: replaceUnderscoreAndCapitalize(category.name),
            value: category.id
          })
        })
      }
      setCategoriesOptions(options)
    } catch (err) {
      console.log(err);
    }
  }

  const handleCopyToAll = () => {
    setCreativeUIDetails((prevState) => ({
      ...prevState,
      uiTilesDetails: null,
      uiDetailsCardDetails: null,
      uiProductsCardDetails: null,
      uiProductsCardTwoDetails: null,
      uiProductsCardThreeDetails: null
    }))
  }

  const handleAlternateLongTitlesChange = (event, index) => {
    const { value } = event.target
    const temp = [...alternateLongTitles]
    temp[index] = value
    setAlternateLongTitles(temp)
  }

  const addAlternateLongTitle = () => {
    setAlternateLongTitles((prevState) => (
      prevState.concat("")
    ));
  }

  const removeAlternateLongTitle = (index) => {
    setAlternateLongTitles((prevState) => (
      prevState.filter((_, i) => i !== index)
    ));
  }

  const removeAllAlternateLongTitles = (index) => {
    setAlternateLongTitles([]);
  }

  let setConfirmationModalState = (event) => {
    setAction(event);
    setConfirmationModalIsOpen(true);
  };

  const handleHide = () => {
    setDetails(detailsInitialState)
    setCreatives(creativesInitialState)
    setIsPromoted(false)
    setAlternateLongTitles([])
    setIsOverrideCategories(false)
    onHide()
  };

  const handlePromoteAd = () => {
    const payload = {
      id: adDetails.id,
      title: adDetails?.title,
      isPromoted: !isPromoted,
      priority: generatePriority()
    }
    promoteAd(payload)
    handleHide()
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    let staticIconFilename = "", animatedIconFilename = "", providerIconFilename = "";
    if (creatives.staticIconSrc) {
      staticIconFilename = creatives.staticIconSrc.startsWith("data:") ? "" : creatives.staticIconFilename
    }
    if (creatives.animatedIconSrc) {
      animatedIconFilename = creatives.animatedIconSrc.startsWith("data:") ? "" : creatives.animatedIconFilename
    }
    if (creatives.providerIconSrc) {
      providerIconFilename = creatives.providerIconSrc.startsWith("data:") ? "" : creatives.providerIconFilename
    }

    // Creative UI details
    let uiTilesDetails = null, uiDetailsCardDetails = null, uiProductsCardDetails = null, uiProductsCardTwoDetails = null, uiProductsCardThreeDetails = null

    // Set tiles UI details
    uiTilesDetails = { isDisabled: !!creativeUIDetails.uiTilesDetails?.isDisabled }
    if (creativeUIDetails.uiTilesDetails?.title)
      uiTilesDetails = {
        ...uiTilesDetails,
        title: creativeUIDetails.uiTilesDetails?.title
      }
    if (creativeUIDetails.uiTilesDetails?.titleLong)
      uiTilesDetails = {
        ...uiTilesDetails,
        titleLong: creativeUIDetails.uiTilesDetails?.titleLong
      }
    if (creativeUIDetails.uiTilesDetails?.subTitle)
      uiTilesDetails = {
        ...uiTilesDetails,
        subTitle: creativeUIDetails.uiTilesDetails?.subTitle
      }
    if (creativeUIDetails.uiTilesDetails?.iconURL)
      uiTilesDetails = {
        ...uiTilesDetails,
        iconFilename: creativeUIDetails?.uiTilesDetails?.iconURL.startsWith("data:") ? "" : creativeUIDetails.uiTilesDetails.iconFilename,
        iconURL: creativeUIDetails?.uiTilesDetails?.iconURL.startsWith("data:") ? creativeUIDetails?.uiTilesDetails?.iconURL : "",
        iconAspectRatio: Number(creativeUIDetails.uiTilesDetails.iconAspectRatio) || 0
      }

    // Set details card UI details
    uiDetailsCardDetails = { isDisabled: !!creativeUIDetails.uiDetailsCardDetails?.isDisabled }
    if (creativeUIDetails.uiDetailsCardDetails?.title)
      uiDetailsCardDetails = {
        ...uiDetailsCardDetails,
        title: creativeUIDetails.uiDetailsCardDetails?.title
      }
    if (creativeUIDetails.uiDetailsCardDetails?.subTitle)
      uiDetailsCardDetails = {
        ...uiDetailsCardDetails,
        subTitle: creativeUIDetails.uiDetailsCardDetails?.subTitle
      }
    if (creativeUIDetails.uiDetailsCardDetails?.iconURL)
      uiDetailsCardDetails = {
        ...uiDetailsCardDetails,
        iconFilename: creativeUIDetails?.uiDetailsCardDetails?.iconURL.startsWith("data:") ? "" : creativeUIDetails.uiDetailsCardDetails.iconFilename,
        iconURL: creativeUIDetails?.uiDetailsCardDetails?.iconURL.startsWith("data:") ? creativeUIDetails?.uiDetailsCardDetails?.iconURL : "",
        iconAspectRatio: Number(creativeUIDetails.uiDetailsCardDetails.iconAspectRatio) || 0
      }

    // Set products card UI details
    uiProductsCardDetails = { isDisabled: !!creativeUIDetails.uiProductsCardDetails?.isDisabled }
    if (creativeUIDetails.uiProductsCardDetails?.title)
      uiProductsCardDetails = {
        ...uiProductsCardDetails,
        title: creativeUIDetails.uiProductsCardDetails?.title
      }
    if (creativeUIDetails.uiProductsCardDetails?.subTitle)
      uiProductsCardDetails = {
        ...uiProductsCardDetails,
        subTitle: creativeUIDetails.uiProductsCardDetails?.subTitle
      }
    if (creativeUIDetails.uiProductsCardDetails?.description)
      uiProductsCardDetails = {
        ...uiProductsCardDetails,
        description: creativeUIDetails.uiProductsCardDetails?.description
      }
    if (creativeUIDetails.uiProductsCardDetails?.iconURL)
      uiProductsCardDetails = {
        ...uiProductsCardDetails,
        iconFilename: creativeUIDetails?.uiProductsCardDetails?.iconURL.startsWith("data:") ? "" : creativeUIDetails.uiProductsCardDetails.iconFilename,
        iconURL: creativeUIDetails?.uiProductsCardDetails?.iconURL.startsWith("data:") ? creativeUIDetails?.uiProductsCardDetails?.iconURL : "",
        iconAspectRatio: Number(creativeUIDetails.uiProductsCardDetails.iconAspectRatio) || 0
      }

    // Set products card two UI details
    uiProductsCardTwoDetails = { isDisabled: !!creativeUIDetails.uiProductsCardTwoDetails?.isDisabled }
    if (creativeUIDetails.uiProductsCardTwoDetails?.title)
      uiProductsCardTwoDetails = {
        ...uiProductsCardTwoDetails,
        title: creativeUIDetails.uiProductsCardTwoDetails?.title
      }
    if (creativeUIDetails.uiProductsCardTwoDetails?.subTitle)
      uiProductsCardTwoDetails = {
        ...uiProductsCardTwoDetails,
        subTitle: creativeUIDetails.uiProductsCardTwoDetails?.subTitle
      }
    if (creativeUIDetails.uiProductsCardTwoDetails?.description)
      uiProductsCardTwoDetails = {
        ...uiProductsCardTwoDetails,
        description: creativeUIDetails.uiProductsCardTwoDetails?.description
      }
    if (creativeUIDetails.uiProductsCardTwoDetails?.iconURL)
      uiProductsCardTwoDetails = {
        ...uiProductsCardTwoDetails,
        iconFilename: creativeUIDetails?.uiProductsCardTwoDetails?.iconURL.startsWith("data:") ? "" : creativeUIDetails.uiProductsCardTwoDetails.iconFilename,
        iconURL: creativeUIDetails?.uiProductsCardTwoDetails?.iconURL.startsWith("data:") ? creativeUIDetails?.uiProductsCardTwoDetails?.iconURL : "",
        iconAspectRatio: Number(creativeUIDetails.uiProductsCardTwoDetails.iconAspectRatio) || 0
      }

    // Set products card three UI details
    uiProductsCardThreeDetails = { isDisabled: !!creativeUIDetails.uiProductsCardThreeDetails?.isDisabled }
    if (creativeUIDetails.uiProductsCardThreeDetails?.iconURL)
      uiProductsCardThreeDetails = {
        ...uiProductsCardThreeDetails,
        iconFilename: creativeUIDetails?.uiProductsCardThreeDetails?.iconURL.startsWith("data:") ? "" : creativeUIDetails.uiProductsCardThreeDetails.iconFilename,
        iconURL: creativeUIDetails?.uiProductsCardThreeDetails?.iconURL.startsWith("data:") ? creativeUIDetails?.uiProductsCardThreeDetails?.iconURL : "",
        iconAspectRatio: Number(creativeUIDetails.uiProductsCardThreeDetails.iconAspectRatio) || 0
      }

    let payload = {
      id: adDetails.id,
      title: creativeUIDetails.uiTilesDetails?.title ?? details.title ?? "",
      titleLong: creativeUIDetails.uiDetailsCardDetails?.title ?? (creativeUIDetails.uiProductsCardDetails?.title || details.titleLong || details.title),
      subTitle: creativeUIDetails.uiDetailsCardDetails?.subTitle ?? (creativeUIDetails.uiProductsCardDetails?.subTitle || details.subTitle),

      staticIconSrc: creatives.staticIconSrc ?? "",
      staticIconAspectRatio: Number(creatives.staticIconAspectRatio) ?? 0,
      animatedIconSrc: creatives.animatedIconSrc ?? "",
      animatedIconAspectRatio: Number(creatives.animatedIconAspectRatio) ?? 0,
      providerIconSrc: creatives.providerIconSrc ?? "",
      providerIconAspectRatio: Number(creatives.providerIconAspectRatio) ?? 0,

      staticIconFilename,
      animatedIconFilename,
      providerIconFilename,

      uiTilesDetails,
      uiDetailsCardDetails,
      uiProductsCardDetails,
      uiProductsCardTwoDetails,
      uiProductsCardThreeDetails,

      alternateLongTitles
    }
    if (isOverrideCategories && details.categories?.length > 0) {
      payload = {
        ...payload,
        categoriesString: details.categories.map(cat => Number(cat.value) || "").filter(cat => cat).join()
      }
    }
    onSubmit(payload, isPromoted)
    handleHide()
  }

  const handleManageStoryAds = () => {
    onManageStoryAds()
    handleHide()
  }

  const requiredComponent = (props) => (
    <div>
      <components.Input {...props} required={true} />
    </div>
  );

  const notRequiredComponent = (props) => (
    <div>
      <components.Input {...props} required={false} />
    </div>
  );

  return (
    <Modal
      show={show}
      onHide={handleHide}
      aria-labelledby="contained-modal-title-vcenter"
      size='xl'
      centered
      backdrop="static"
    >
      <Modal.Header className="bg-light" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Metadata Override {adDetails?.title ? (" - " + adDetails?.title) : ""}{adDetails?.id ? (" (" + adDetails?.id + ")") : ""}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='font-weight-bolder'>
              <p className='mb-0'>Status:
                {
                  isPromoted
                    ? <Badge color="success" className='ml-2'>Promoted Ad</Badge>
                    : <Badge color="danger" className='ml-2'>Non-Promoted Ad</Badge>
                }
              </p>
            </div>
            {showAdPromoteUpdateButton()
              ? isPromoted
                ? <Button color="danger" onClick={handlePromoteAd}>Set Ad as Non-Promoted</Button>
                : <Button color="success" onClick={handlePromoteAd}>Set Ad as Promoted</Button>
              : null
            }
          </div>
          <hr />
          <SmartSuggestionDetails
            isMetadataOverride={true}
            disabled={!showAdMetadataUpdateButton()}
            details={details}
            creatives={creatives}
            creativeUIDetails={creativeUIDetails}
            detailsCardFieldsEdit={detailsCardFieldsEdit}
            setDetailsCardFieldsEdit={setDetailsCardFieldsEdit}
            setCreativeUIDetails={setCreativeUIDetails}
            setCreatives={setCreatives}
            showErrorModal={showErrorModal}
          />
          <Row form>
            <Col md={12}>
              <FormGroup className="d-flex align-items-center">
                <Label
                  className="font-weight-bolder mb-0"
                  style={{ fontSize: "14px" }}
                >
                  Do you want to override categories{" "}
                  <ToolTip
                    data="If enabled then categories from direct ads and app package clusters will be ignored."
                    id="isOverrideCategoriesTooltip"
                  />
                </Label>
                <div className="custom-control custom-switch">
                  <CustomInput
                    type="checkbox"
                    id="isOverrideCategories"
                    value={isOverrideCategories}
                    onChange={(e) => setIsOverrideCategories(e.target.checked)}
                    checked={isOverrideCategories}
                    disabled={!showAdMetadataUpdateButton()}
                  />
                </div>
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Related Categories <span className="text-danger">* </span>
                  <ToolTip
                    data="Categories related to this smart suggestion. Used while fetching relevant ads for the user"
                    id="relatedCategoriesTooltip"
                  />
                  <div className="text-warning font-weight-bold" style={{ fontSize: 14 }}>
                    <span role="img" aria-label="warning-icon">⚠️</span> Metadata overridden categories will have more preference over app package clusters.
                  </div>
                </Label>
                <Select
                  id="relatedCategories"
                  isMulti
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="relatedCategories"
                  placeholder="Select Related Categories"
                  value={details.categories}
                  onChange={(event) => {
                    setDetails((prevState) => ({
                      ...prevState,
                      categories: event,
                    }))
                  }}
                  options={categoriesOptions}
                  isDisabled={!showAdMetadataUpdateButton() || !isOverrideCategories}
                  components={{
                    Input:
                      (details.categories?.length > 0)
                        ? notRequiredComponent
                        : requiredComponent,
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <div
                className="font-weight-bolder mb-2"
                style={{ fontSize: "14px" }}
              >
                Alternate Long Titles{" "}
                <ToolTip
                  data="Alternative text show in the smart suggestion. Used to add more relevant text for a given smart suggestion based on the user's search context"
                  id="alternativeLongTitlesTooltip"
                />
              </div>
            </Col>
            {showAdMetadataUpdateButton() && (
              <Col>
                {(alternateLongTitles?.length <= 0) ? (
                  <Button color='success' className="float-right" onClick={addAlternateLongTitle}>Add Alternate Long Titles</Button>
                ) :
                  <Button color='danger' className="float-right" onClick={removeAllAlternateLongTitles}>Delete Alternate Long Titles</Button>
                }
              </Col>
            )}
          </Row>
          {alternateLongTitles?.map((alternateLongTitle, index) => (
            <Row key={index} form className="mb-3 mt-3">
              <Col>
                <FormGroup className="mb-0">
                  <Input
                    type="text"
                    name="titleLong"
                    placeholder={`Enter Alternate Long Title #${index + 1}`}
                    value={alternateLongTitle}
                    onChange={(event) => handleAlternateLongTitlesChange(event, index)}
                    disabled={!showAdMetadataUpdateButton()}
                    required
                  />
                  {/* {alternateLongTitle ? <div className='font-weight-bolder mt-2'>Preview #{index + 1}:</div> : null}
                  <div dangerouslySetInnerHTML={{ __html: getParsedHTML(alternateLongTitle) }}></div> */}
                </FormGroup>
              </Col>
              {showAdMetadataUpdateButton() && (
                <Col md={1}>
                  {index === 0 && alternateLongTitles.length < maxAlternateLongTitles ? (
                    <Button onClick={addAlternateLongTitle} color="transparent">
                      <FaPlus />
                    </Button>
                  ) : (
                    <Button onClick={() => removeAlternateLongTitle(index)} color="transparent">
                      <FaMinus />
                    </Button>
                  )}
                </Col>
              )}
              <Col lg="12">
                {index !== (alternateLongTitles.length - 1) && <hr style={{ marginBottom: 0 }} />}
              </Col>
            </Row>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100 d-flex justify-content-between align-items-center">
            <div>
              {!isPartnerAd(adDetails?.id) &&
                <Link
                  className="text-decoration-none"
                  to={{
                    pathname: `/ads/smart-suggestions/edit/${adDetails?.id}`,
                    state: {
                      formType: "edit",
                    },
                  }}
                >
                  <Button className="m-1">
                    Edit Details
                  </Button>
                </Link>
              }
              {!isPartnerAd(adDetails?.id) &&
                <Link
                  className="text-decoration-none"
                  to={{
                    pathname: `/ads/smart-suggestions/targeting-rules/${adDetails?.id}`,
                    state: {
                      formType: "edit",
                    },
                  }}
                >
                  <Button className="m-1">Edit Targeting Rules</Button>
                </Link>
              }
            </div>
            <div>
              {isSupportedStoryAds &&
                <Button className="m-1" color="secondary" onClick={handleManageStoryAds}>
                  Manage Story Ad
                </Button>
              }
              {showAdMetadataResetButton() && adDetails?.isModified &&
                <Button className="m-1" color="danger" onClick={() => setConfirmationModalState(metadataOverrideAllActions.resetMetadataOverride)}>
                  Reset Override Metadata
                </Button>
              }
              <Button className="m-1" color="primary" onClick={handleHide}>Close</Button>
              {showAdMetadataUpdateButton() &&
                <Button className="m-1" type='submit' color='success'>Submit</Button>
              }
            </div>
          </div>
        </Modal.Footer>
      </Form>
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={"Error"}
        modaltext={failureModalText}
      />
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={action}
        onConfirmationTrue={() => {
          setConfirmationModalIsOpen(false);
          if (action === metadataOverrideAllActions.resetMetadataOverride) {
            onReset(adDetails?.id, adDetails?.title, isPromoted)
            handleHide()
          }
          else if (action === metadataOverrideAllActions.copyDefaultAppearance) handleCopyToAll();
        }}
        onHide={() => setConfirmationModalIsOpen(false)}
      />
    </Modal>
  )
}

export default SmartSuggestionsMetadataOverrideModal
import React, { Component } from "react";
import { Modal, Button, Row, Col, Alert } from "react-bootstrap";
import Reorder, { reorder } from "react-reorder";
import Select from "react-select";
import { capitalize } from "@material-ui/core";
import StickerPackStickerStatusTable from "./components/StickerPackStickerStatusTable";
import {
  getStatus,
  getStatusColor,
} from "../../../utilities/commonUtil";
import { Badge, Card, CardBody } from "reactstrap";
import ToolTip from "../../../utilities/ToolTip";

class StickerStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sticker: {},
      stickerPackIds: [],
      globalStatus: false,
    };
  }

  componentWillReceiveProps() {
    if (this.props.sticker != undefined && this.props.sticker.stickerPacks) {
      for (let i = 0; i < this.props.sticker.stickerPacks.length; i++) {
        const element = this.props.sticker.stickerPacks[i];
        this.props.sticker.stickerPacks[i].status = getStatus(element.publishedAt, element.deactivatedAt)
      }
    }
    this.props.sticker.status = getStatus(this.props.sticker.publishedAtGlobal, this.props.sticker.deactivatedAtGlobal)
    this.setState({
      sticker: this.props.sticker,
    });
  }

  handleStickerPackChange = (stickerPack) => {
    this.setState({ stickerPackId: stickerPack.value });
  };

  handleStickerPackSelection = (data, isSelected) => {
    if (isSelected) {
      this.setState({ stickerPackIds: [...this.state.stickerPackIds, data.id] });
    }
    else {
      this.setState({ stickerPackIds: this.state.stickerPackIds.filter(id => id !== data.id) });
    }
  }

  handleGlobalStatusSelection = (e) => {
    const isSelected = e.target.checked;
    this.setState({ globalStatus: isSelected });
  }

  handleSubmit = () => {
    this.props.updateStickerStatus(this.state.sticker.id, this.props.action, this.state.stickerPackIds, this.state.globalStatus);
    this.setState({ stickerPackIds: [], globalStatus: false, sticker: {} });
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header className="bg-light" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {capitalize(this.props.action)} Sticker
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StickerPackStickerStatusTable
            stickerPacks={this.state.sticker?.stickerPacks}
            action={capitalize(this.props.action)}
            onSelectRow={this.handleStickerPackSelection}
          />
          <Card>
            <CardBody>
              <Row className="pb-2">
                <h4 className="px-2">Global Status</h4>
                <span className="px-2">
                  <ToolTip
                    data="Global Status controls the availability of sticker in searches & recommendations."
                    id="stickerGlobalStatus"
                  />
                </span>
                <Badge color={getStatusColor(this.state.sticker?.status)}>{this.state.sticker?.status}</Badge>
              </Row>
              <Row>
                <Col>
                  <input type="checkbox" class="selection-input-4" onChange={this.handleGlobalStatusSelection}></input>{"  "}
                  Update Global Status
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Modal.Footer>
            <Button onClick={this.handleSubmit}>Submit</Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    );
  }
}

export default StickerStatus;

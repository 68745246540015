import React, { useState, useEffect } from "react";
import TargetingRules from "../TargetingRules";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { permissions } from "../../../utilities/commonUtil";

import {
  getCountries,
  getRegions,
  getCities,
  getQuickReplyTargetingRules,
  getQuickReplyTargetingRulesType,
  saveQuickReplyTargetingRules,
} from "../../../utilities/apiUtils/quickReplies";

import { getClients } from "../../../utilities/apiUtils/cre";

import {
  Col,
  Row,
  Container,
  Card,
  CardBody,
  Label,
  FormGroup,
} from "reactstrap";

import Select from "react-select";

import { targettingTypeOptions } from "../../../config/quickReplies";

const QuickRepyTargettingRules = (props) => {
  let id = props.match.params.id;

  console.log(props.match.params.id);

  const [targetingType, setTargetingType] = useState(null);

  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  const updateRulesAllowed = () => {
    return allowedPermissions.includes(permissions.quickReplyRulesUpdate);
  };

  useEffect(() => {
    const _getQuickReplyTargetingRulesType = async () => {
      let response = await getQuickReplyTargetingRulesType(id);

      if (response !== undefined) {
        setTargetingType(
          targettingTypeOptions.find(
            (item) => item.value.toLowerCase() === response.toLowerCase()
          )
        );
      }
    };
    _getQuickReplyTargetingRulesType();
  }, []);

  return (
    <Container fluid className="p-0">
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      <strong>Targeting Type</strong>
                    </Label>
                    <Select
                      name="type"
                      className="react-select-container mb-4"
                      classNamePrefix="react-select"
                      options={targettingTypeOptions}
                      onChange={(e) => setTargetingType(e)}
                      value={targetingType}
                      placeholder="Select a Targeting type"
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {targetingType && targetingType.value === "custom" && (
        <TargetingRules
          id={id}
          showAgeGenderRules={true}
          rulesType={targetingType.value}
          showCustomAudienceTargetingRules={TargetingRules}
          showHardFiltering={false}
          getRules={getQuickReplyTargetingRules}
          showAppVersionRules={true}
          saveRules={saveQuickReplyTargetingRules}
          getClients={getClients}
          getCountries={getCountries}
          getRegions={getRegions}
          getCities={getCities}
          isAdvertising={true}
          updateRulesAllowed={updateRulesAllowed()}
        />
      )}
    </Container>
  );
};

export default QuickRepyTargettingRules;

import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  CardImg,
  Label,
  Row,
  Spinner,
  CustomInput,
} from "reactstrap";
import ResponseModal from "../ResponseModal";
import { components } from "react-select";
import {
  errorDescription,
  error,
} from "../../../utilities/commonUtil";
import Select from "react-select";
import { createUpdatePoptextSticker, fetchTaasClients, fetchTaasPoptextSticker, fetchTaasPoptextStickerURL, testTextPoptextSticker } from "../../../utilities/apiUtils/taasDashboard";
import { config, poptextStickerResponses } from "../../../config/configTaasDashboard";
import { successModalHeading } from "../../../config";
import { useParams, useLocation } from 'react-router-dom';
import { useHistory } from "react-router-dom"
import { errorModalHeading } from "../../../config/config";
import { debounce } from "../../../utilities/debounce";

const StickerForm = () => {
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const currentPath = location.pathname.split('/');
  const pageType = currentPath[currentPath.length - 1];
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTextStickerLoading, setIsTextStickerLoading] = useState(false);
  const [previewBackgroundColor, setPreviewBackgroundColor] = useState('white');
  const [textPreviewBackgroundColor, setTextPreviewBackgroundColor] = useState('white');
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [imageURL, setImageURL] = useState('');
  const [textImageURL, setTextImageURL] = useState("");
  const [successModalText, setSuccessModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");

  const [data, setData] = useState({
    poptextStickerId: '',
    clientId: [],
    data: '',
    getBackgroundImage: true,
    resetData: false,
    activatedAt: false,
    deactivatedAt: true,
  })

  const [metaData, setMetaData] = useState({
    originalHeight: '',
    originalWidth: '',
    position: {
      x: '',
      y: '',
      height: '',
      width: '',
      angle: '',
    },
    font: {
      size: '',
      color: '',
      url: '',
    },
    stroke1: {
      color: '',
      width: '',
    },
    stroke2: {
      color: '',
      width: '',
    },
    stroke3: {
      color: '',
      width: '',
    },
    shadow: '',
    backgroundImageURL: '',
  })

  const [textData, setTextData] = useState({
    text: '',
    fontStickerId: data.poptextStickerId,
  })

  let fetchClientsData = async () => {
    try {
      let response = await fetchTaasClients()
      if (response?.length > 0) {
        setClients(response.map(d => ({
          value: d.id,
          label: d.name,
        }))
        )
      }
      else if (response & response.errorCode) {
        showErrorModal(errorDescription(response.errorCode));
      }
    } catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  }

  const fetchStickerDetails = async (id) => {
    try {
      let sticker = await fetchTaasPoptextSticker(id);
      let parsedMetaData = "";
      if (sticker?.length > 0) {
        sticker = sticker[0];
        await fetchData(sticker?.poptextStickerId);
        setData({
          id: id,
          poptextStickerId: sticker?.poptextStickerId,
          clientId: {
            label: clients.find(option => option.value === sticker?.clientId)?.label ?? sticker?.clientName,
            value: sticker?.clientId
          },
          data: sticker?.data,
          getBackgroundImage: data.getBackgroundImage,
          resetData: data.resetData,
          activatedAt: new Date(sticker?.deactivatedAt) > new Date() || sticker?.deactivatedAt === null ? true : false,
          deactivatedAt: new Date(sticker?.deactivatedAt) > new Date() || sticker?.deactivatedAt === null ? false : true,
        });
        setTextData({
          text: textData.text,
          fontStickerId: sticker?.poptextStickerId,
        })
        parsedMetaData = sticker?.data !== "undefined" ? JSON.parse(sticker?.data) : '';
        setMetaData({
          originalHeight: parsedMetaData?.originalHeight ?? '',
          originalWidth: parsedMetaData?.originalWidth ?? '',
          position: {
            x: parsedMetaData?.position?.x ?? '',
            y: parsedMetaData?.position?.y ?? '',
            height: parsedMetaData?.position?.height ?? '',
            width: parsedMetaData?.position?.width ?? '',
            angle: parsedMetaData?.position?.angle ?? '',
          },
          font: {
            size: parsedMetaData?.font?.size ?? '',
            color: parsedMetaData?.font?.color ?? '',
            url: parsedMetaData?.font?.url ?? '',
          },
          stroke1: {
            color: parsedMetaData?.stroke1?.color ?? '',
            width: parsedMetaData?.stroke1?.width ?? '',
          },
          stroke2: {
            color: parsedMetaData?.stroke2?.color ?? '',
            width: parsedMetaData?.stroke2?.width ?? '',
          },
          stroke3: {
            color: parsedMetaData?.stroke3?.color ?? '',
            width: parsedMetaData?.stroke3?.width ?? '',
          },
          shadow: parsedMetaData?.shadow ?? '',
          backgroundImageURL: parsedMetaData?.backgroundImageURL ?? '',
        })
      }
      else {
        setData({
          poptextStickerId: [],
          clientId: [],
          data: '',
          activatedAt: false,
          deactivatedAt: true,
        })
      }
    }
    catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  }

  const handleTextDataChange = (event) => {
    const { name, value } = event.target
    setTextData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  useEffect(() => {
    fetchClientsData();
  }, [])

  useEffect(() => {
    if (pageType !== "create" && id) {
      fetchStickerDetails(id);
    }
  }, [id, pageType])


  const fetchData = async (stickerId) => {
    try {
      const res = await fetchTaasPoptextStickerURL(stickerId);
      if (res?.sticker?.fixedWidthTiny) {
        setImageURL(res?.sticker?.fixedWidthTiny?.png?.url);
      }
      else {
        setImageURL('');
        setTextImageURL('');
      }
    } catch (err) {
      setImageURL('');
      setTextImageURL('');
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleStickerIdChange = (event) => {
    const { name, value } = event.target
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }))
    setTextData((prevState) => ({
      ...prevState,
      fontStickerId: value
    }))
    debounce(() => {
      setIsLoading(true);
      fetchData(value).finally(() => setIsLoading(false))
    }, config.debounceTime)
  };

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let successModalClose = () => {
    setSuccessModalDisplay(false);
    history.goBack();
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  const handleTryText = async (e) => {
    e.preventDefault();
    try {
      if (!textData.text || !textData.fontStickerId) {
        showErrorModal("Please Enter Text and Poptext Sticker Id");
      }
      else {
        setIsTextStickerLoading(true);
        let response = await testTextPoptextSticker(textData)
        if (response) {
          setTextImageURL(response?.contents[0]?.media?.fixedWidthFull?.png?.url)
        }
        else {
          setTextImageURL('');
        }
      }
    } catch (err) {
      setTextImageURL('');
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error));
    }
    finally {
      setIsTextStickerLoading(false);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const defaultStroke = { color: "#000000", width: 8 };
    const defaultShadow = null;
    let metaDataString = "";
    setMetaData((prevState) => {
      const updatedMetaData = {
        ...prevState,
        stroke1: (prevState?.stroke1 && prevState?.stroke1?.color !== "" && prevState?.stroke1?.width !== "") ? prevState?.stroke1 : defaultStroke,
        stroke2: (prevState?.stroke2 && prevState?.stroke2?.color !== "" && prevState?.stroke2?.width !== "") ? prevState?.stroke2 : null,
        stroke3: (prevState?.stroke3 && prevState?.stroke3?.color !== "" && prevState?.stroke3?.width !== "") ? prevState?.stroke3 : null,
        shadow: (prevState?.shadow !== "") ? prevState?.shadow : defaultShadow
      };
      if (data.getBackgroundImage && metaData.backgroundImageURL) {
        updatedMetaData.backgroundImageURL = metaData.backgroundImageURL;
      } else {
        delete updatedMetaData.backgroundImageURL;
      }
      metaDataString = JSON.stringify(updatedMetaData);
      return updatedMetaData;
    });
    let formData = new FormData();
    id && formData.append("id", data.id);
    formData.append("poptextStickerId", data.poptextStickerId);
    formData.append("clientId", data.clientId?.value);
    formData.append("getBackgroundImage", data.getBackgroundImage);
    formData.append("resetData", data.resetData);
    id && !data.resetData && formData.append("data", metaDataString);
    formData.append("activatedAt", data.activatedAt);
    formData.append("deactivatedAt", data.deactivatedAt);
    try {
      if (!imageURL) {
        showErrorModal("Enter a valid sticker id");
      }
      else {
        let response = await createUpdatePoptextSticker(formData)
        if (response.code === "PoptextStickerAdded") {
          showSuccessModal(poptextStickerResponses.addedSuccessfully);
        }
        else if (response.code === "PoptextStickerUpdated") {
          showSuccessModal(poptextStickerResponses.updatedSuccessfully);
        }
        else {
          if (response.errorDescription) {
            showErrorModal(response.errorDescription)
          }
        }
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  }
  const requiredComponent = (props) => (
    <div>
      <components.Input {...props} required={true} />
    </div>
  );

  const notRequiredComponent = (props) => (
    <div>
      <components.Input {...props} required={false} />
    </div>
  );

  const handleActivateCheckboxChange = (event) => {
    const { checked } = event.target;
    setData({
      ...data,
      activatedAt: checked,
      deactivatedAt: !checked,
    });
  };

  const handleBackgroundImageCheckboxChange = (event) => {
    const { checked } = event.target;
    setData({
      ...data,
      getBackgroundImage: checked,
    });
  };

  const handleResetData = async () => {
    let formData = new FormData();
    id && formData.append("id", data.id);
    formData.append("poptextStickerId", data.poptextStickerId);
    formData.append("clientId", data.clientId?.value);
    formData.append("getBackgroundImage", data.getBackgroundImage);
    formData.append("resetData", true);
    formData.append("activatedAt", data.activatedAt);
    formData.append("deactivatedAt", data.deactivatedAt);
    try {
      let response = await createUpdatePoptextSticker(formData)
      if (response.code === "PoptextStickerAdded") {
        showSuccessModal(poptextStickerResponses.addedSuccessfully);
      }
      else if (response.code === "PoptextStickerUpdated") {
        showSuccessModal(poptextStickerResponses.updatedSuccessfully);
      }
      else {
        if (response.errorDescription) {
          showErrorModal(response.errorDescription)
        }
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  }

  const handlePreviewBackgroundColor = () => {
    if (previewBackgroundColor === 'white') {
      setPreviewBackgroundColor('black');
    }
    else
      setPreviewBackgroundColor('white');
  }

  const handleTextPreviewBackgroundColor = () => {
    if (textPreviewBackgroundColor === 'white') {
      setTextPreviewBackgroundColor('black');
    }
    else
      setTextPreviewBackgroundColor('white');
  }

  return (
    <>
      <h1 className="h3 mb-3">
        {pageType !== "create" ? "Edit Poptext Sticker" : "Add Poptext Sticker"}
      </h1>
      <Card>
        <CardBody>
          <Form onSubmit={(e) => handleSubmit(e)}>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label>
                    Bobble Poptext Sticker ID <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="number"
                    name="poptextStickerId"
                    value={data.poptextStickerId}
                    disabled={pageType !== "create"}
                    placeholder="Enter Bobble Poptext Sticker ID"
                    required
                    onChange={handleStickerIdChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Client <span className="text-danger">*</span></Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    placeholder="Select Client"
                    name="clientId"
                    required
                    value={data.clientId}
                    onChange={(event) => {
                      setData((prevState) => ({
                        ...prevState,
                        clientId: event,
                      }))
                    }}
                    components={{
                      Input:
                        (data.clientId && data.clientId?.value)
                          ? notRequiredComponent
                          : requiredComponent,
                    }}
                    options={clients}
                  />
                </FormGroup>
              </Col>
            </Row>
            {pageType === "create" && <><Row>
              <Col xs={12}>
                <FormGroup className="d-flex align-items-center">
                  <Label
                    className="font-weight-bolder mb-0"
                    style={{ fontSize: "14px" }}
                  >
                    Activate Poptext Sticker
                  </Label>
                  <div className="custom-control custom-switch">
                    <CustomInput
                      type="checkbox"
                      id="activateStickersByDefault"
                      onChange={handleActivateCheckboxChange}
                      checked={data.activatedAt}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup className="d-flex align-items-center">
                    <Label
                      className="font-weight-bolder mb-0"
                      style={{ fontSize: "14px" }}
                    >
                      Get Background Image
                    </Label>
                    <div className="custom-control custom-switch">
                      <CustomInput
                        type="checkbox"
                        id="activateGetBackgroundImageByDefault"
                        onChange={handleBackgroundImageCheckboxChange}
                        checked={data.getBackgroundImage}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row></>
            }
            <Row form>
              <Col md={8}>
                <FormGroup>
                  <Label>
                    Preview
                  </Label>
                  <div style={{ display: 'flex' }}>
                    <div style={{ flex: '0 0 auto', margin: 'auto', width: '200px' }}>
                      {isLoading ? (
                        <Spinner color="info" className="d-flex mx-auto" />
                      ) : imageURL ? (
                        <CardImg
                          top
                          src={imageURL ?? ""}
                          alt="Card image cap"
                          style={{ width: '100%', backgroundColor: previewBackgroundColor }}
                        />
                      ) : (
                        <>No Sticker</>
                      )}
                    </div>
                  </div>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup className="d-flex align-items-center justify-content-end">
                  <Label
                    className="font-weight-bolder mb-0"
                    style={{ fontSize: "14px" }}
                  >
                    <h4>
                      Try Dark Mode
                    </h4>
                  </Label>
                  <div className="custom-control custom-switch mb-2">
                    <CustomInput
                      type="checkbox"
                      id="changePreviewBackgroundColor"
                      onChange={handlePreviewBackgroundColor}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
            {pageType !== "create" && <>
              < hr />
              <Row form className="mb-2">
                <Col md={8}>
                  <h3>Data</h3>
                </Col>
                <Col md={4}>
                  <div className="d-flex align-items-center justify-content-end">
                    <FormGroup className="d-flex align-items-center">
                      <Label
                        className="font-weight-bolder mb-0"
                        style={{ fontSize: "14px" }}
                      >
                        Get Background Image
                      </Label>
                      <div className="custom-control custom-switch">
                        <CustomInput
                          type="checkbox"
                          id="activateGetBackgroundImageByDefault"
                          onChange={handleBackgroundImageCheckboxChange}
                          checked={data.getBackgroundImage}
                        />
                      </div>
                    </FormGroup>
                    <Button className="mx-2 mb-3" onClick={handleResetData}>Reset Data</Button>
                  </div>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <h4>
                          Original Height
                        </h4>
                        <Input
                          type="number"
                          name="originalHeight"
                          value={metaData?.originalHeight}
                          placeholder="Enter Original Height"
                          onChange={(e) => setMetaData((prevState) => ({
                            ...prevState,
                            originalHeight: e.target.value
                          }))}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <h4>
                          Original Width
                        </h4>
                        <Input
                          type="number"
                          name="originalWidth"
                          value={metaData?.originalWidth}
                          placeholder="Enter Original Width"
                          onChange={(e) => setMetaData((prevState) => ({
                            ...prevState,
                            originalWidth: e.target.value
                          }))}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <h4 className="mt-3">Position</h4>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          X
                        </Label>
                        <Input
                          type="number"
                          name="x"
                          value={metaData?.position?.x}
                          placeholder="Enter Position X"
                          onChange={(e) => setMetaData((prevState) => ({
                            ...prevState,
                            position: {
                              ...prevState.position,
                              x: e.target.value
                            }
                          }))}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          Y
                        </Label>
                        <Input
                          type="number"
                          name="y"
                          value={metaData?.position?.y}
                          placeholder="Enter Position Y"
                          onChange={(e) => setMetaData((prevState) => ({
                            ...prevState,
                            position: {
                              ...prevState.position,
                              y: e.target.value
                            }
                          }))}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          Height
                        </Label>
                        <Input
                          type="number"
                          name="height"
                          value={metaData?.position?.height}
                          placeholder="Enter Position Height"
                          onChange={(e) => setMetaData((prevState) => ({
                            ...prevState,
                            position: {
                              ...prevState.position,
                              height: e.target.value
                            }
                          }))}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          Width
                        </Label>
                        <Input
                          type="number"
                          name="width"
                          value={metaData?.position?.width}
                          placeholder="Enter Position Width"
                          onChange={(e) => setMetaData((prevState) => ({
                            ...prevState,
                            position: {
                              ...prevState.position,
                              width: e.target.value
                            }
                          }))}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          Angle
                        </Label>
                        <Input
                          type="number"
                          name="angle"
                          value={metaData?.position?.angle}
                          placeholder="Enter Position Angle"
                          onChange={(e) => setMetaData((prevState) => ({
                            ...prevState,
                            position: {
                              ...prevState.position,
                              angle: e.target.value
                            }
                          }))}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <h4 className="mt-3">Background Image URL</h4>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Input
                          type="text"
                          name="backgroundImageURL"
                          disabled
                          value={metaData?.backgroundImageURL}
                          placeholder="Enter Background Image URL"
                          onChange={(e) => setMetaData((prevState) => ({
                            ...prevState,
                            backgroundImageURL: e.target.value
                          }))}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <h4 className="mt-3">Font</h4>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          Size
                        </Label>
                        <Input
                          type="number"
                          name="size"
                          value={metaData?.font?.size}
                          placeholder="Enter Font Size"
                          onChange={(e) => setMetaData((prevState) => ({
                            ...prevState,
                            font: {
                              ...prevState.font,
                              size: e.target.value
                            }
                          }))}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          Color
                        </Label>
                        <Input
                          type="text"
                          name="color"
                          value={metaData?.font?.color}
                          placeholder="Enter Font Color"
                          onChange={(e) => setMetaData((prevState) => ({
                            ...prevState,
                            font: {
                              ...prevState.font,
                              color: e.target.value
                            }
                          }))}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          URL
                        </Label>
                        <Input
                          type="text"
                          name="url"
                          value={metaData?.font?.url}
                          placeholder="Enter Font URL"
                          onChange={(e) => setMetaData((prevState) => ({
                            ...prevState,
                            font: {
                              ...prevState.font,
                              url: e.target.value
                            }
                          }))}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <h4 className="mt-3">Stroke 1</h4>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          Color
                        </Label>
                        <Input
                          type="text"
                          name="color"
                          value={metaData?.stroke1?.color}
                          placeholder="Enter Stroke 1 Color"
                          onChange={(e) => setMetaData((prevState) => ({
                            ...prevState,
                            stroke1: {
                              ...prevState.stroke1,
                              color: e.target.value
                            }
                          }))}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          Width
                        </Label>
                        <Input
                          type="number"
                          name="width"
                          value={metaData?.stroke1?.width}
                          placeholder="Enter Stroke 1 Width"
                          onChange={(e) => setMetaData((prevState) => ({
                            ...prevState,
                            stroke1: {
                              ...prevState.stroke1,
                              width: e.target.value
                            }
                          }))}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <h4 className="mt-3">Stroke 2</h4>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          Color
                        </Label>
                        <Input
                          type="text"
                          name="color"
                          value={metaData?.stroke2?.color}
                          placeholder="Enter Stroke 2 Color"
                          onChange={(e) => setMetaData((prevState) => ({
                            ...prevState,
                            stroke2: {
                              ...prevState.stroke2,
                              color: e.target.value
                            }
                          }))}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          Width
                        </Label>
                        <Input
                          type="number"
                          name="width"
                          value={metaData?.stroke2?.width}
                          placeholder="Enter Stroke 2 Width"
                          onChange={(e) => setMetaData((prevState) => ({
                            ...prevState,
                            stroke2: {
                              ...prevState.stroke2,
                              width: e.target.value
                            }
                          }))}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <h4 className="mt-3">Stroke 3</h4>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          Color
                        </Label>
                        <Input
                          type="text"
                          name="color"
                          value={metaData?.stroke3?.color}
                          placeholder="Enter Stroke 3 Color"
                          onChange={(e) => setMetaData((prevState) => ({
                            ...prevState,
                            stroke3: {
                              ...prevState.stroke3,
                              color: e.target.value
                            }
                          }))}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          Width
                        </Label>
                        <Input
                          type="number"
                          name="width"
                          value={metaData?.stroke3?.width}
                          placeholder="Enter Stroke 3 Width"
                          onChange={(e) => setMetaData((prevState) => ({
                            ...prevState,
                            stroke3: {
                              ...prevState.stroke3,
                              width: e.target.value
                            }
                          }))}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <h4 className="mt-3">Shadow</h4>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Input
                          type="number"
                          name="shadow"
                          value={metaData?.shadow}
                          placeholder="Enter Shadow"
                          onChange={(e) => setMetaData((prevState) => ({
                            ...prevState,
                            shadow: e.target.value
                          }))}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </>
            }
            {pageType !== "create" && data.clientId?.value == 2 && <>
              <hr />
              <h3>Try Adding Text On Sticker</h3>
              <Card>
                <CardBody>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label>
                          Text <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="text"
                          value={textData.text}
                          placeholder="Enter Text"
                          onChange={handleTextDataChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={8}>
                      <FormGroup>
                        <Label>
                          Preview
                        </Label>
                        <div style={{ display: 'flex' }}>
                          <div style={{ flex: '0 0 auto', margin: 'auto', width: '200px' }}>
                            {isTextStickerLoading ? (
                              <Spinner color="info" className="d-flex mx-auto" />
                            ) : textImageURL ? (
                              <CardImg
                                top
                                src={textImageURL ?? ""}
                                alt="Card image cap"
                                style={{ width: '100%', backgroundColor: textPreviewBackgroundColor }}
                              />
                            ) : (
                              <>No Sticker</>
                            )}
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup className="d-flex align-items-center justify-content-end">
                        <Label
                          className="font-weight-bolder mb-0"
                          style={{ fontSize: "14px" }}
                        >
                          <h4>
                            Try Dark Mode
                          </h4>
                        </Label>
                        <div className="custom-control custom-switch mb-2">
                          <CustomInput
                            type="checkbox"
                            id="changeTextPreviewBackgroundColor"
                            onChange={handleTextPreviewBackgroundColor}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button color="primary" onClick={handleTryText}>Try Now</Button>
                </CardBody>
              </Card>
            </>}
            <Button color="primary">Submit</Button>
          </Form>
          <ResponseModal
            show={successModalDisplay}
            onHide={successModalClose}
            modalheading={successModalHeading}
            modaltext={successModalText}
          />
          <ResponseModal
            show={failureModalDisplay}
            onHide={failureModalClose}
            modalheading={errorModalHeading}
            modaltext={failureModalText}
          />
        </CardBody>
      </Card>
    </>
  )
}
const ManagePopTextSticker = () => (
  <Container fluid className="p-0">
    <Row>
      <Col lg="12">
        <StickerForm />
      </Col>
    </Row>
  </Container>
)
export default ManagePopTextSticker;
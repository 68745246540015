import { retrieveFromLocalStorage } from "../localStorageUtil";
import { host } from "../apiUtils/index";
import { secureFetch } from "../secureFetch";

export let fetchPlacementLocations = async () => {
  let url = `${host}/v1/internal/adx/placementLocations`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let deletePlacementLocation = async (id) => {
  let url = `${host}/v1/internal/adx/placementLocation/${id}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return error.json();
  }
};

export let createPlacementLocation = async (data) => {
  let url = `${host}/v1/internal/adx/placementLocations`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return error.json();
  }
};

export let updatePlacementLocation = async (id, data) => {
  let url = `${host}/v1/internal/adx/placementLocation/${id}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return error.json();
  }
};
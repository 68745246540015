import moment from "moment";

const dateFormatter = (cell, row) => {
  return moment(cell).local().format("Do MMM YYYY hh:mm A")
}

export const envConfigTableColumns = [
  {
    dataField: "name",
    text: "Key",
    sort: true,
    searchable: true,
    style: {
      cursor: "pointer",
    },
  },
  {
    dataField: "value",
    text: "Value",
    sort: true,
    searchable: true,
    headerAttrs: { width: 500 },
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
      width: 500,
      maxWidth: 500
    },
  },
  {
    dataField: "createdAt",
    text: "Created At",
    sort: true,
    searchable: true,
    formatter: (cell, row) => dateFormatter(cell, row),
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  },
  {
    dataField: "updatedAt",
    text: "Updated At",
    sort: true,
    searchable: true,
    formatter: (cell, row) => dateFormatter(cell, row),
    style: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      cursor: "pointer",
    },
  }
];
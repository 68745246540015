import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Button, Col, Input, Form, FormGroup, Label, Row } from "reactstrap";
import Select from "react-select";
import { getPermissionsDropdown } from "../../../utilities/apiUtils/permissions";
import ResponseModal from "../ResponseModal";
import { error } from "../../../utilities/commonUtil";

const EditRoleFormModal = (props) => {
  const { id, selectedPermissions } = props.data;
  const [roleDescription, setRoleDescription] = useState(
    props.data.description
  );
  const [allPermissions, setAllPermissions] = useState([]);
  const [permissionIDs, setPermissionIDs] = useState(
    props.data.selectedPermissionIds
  );
  const [failureModal, setFailureModal] = useState({
    display: false,
    text: "",
  });

  useEffect(() => {
    let fetchPermissions = async () => {
      try {
        let permissions = await getPermissionsDropdown();
        if (permissions.length) setAllPermissions(permissions);
      } catch (err) {
        setFailureModal({ display: true, text: error.unexpectedError });
      }
    };
    fetchPermissions();
  }, []);

  let handlePermissionChange = (permissionsSelected) => {
    let permissions = [];
    if (permissionsSelected !== null) {
      permissionsSelected.forEach((permission) => {
        permissions.push(permission.value);
      });
    }
    setPermissionIDs(permissions);
  };
  let showAlert = (elementId) => {
    document.getElementById(elementId).classList.remove("d-none");
  };

  let hideAlert = (elementId) => {
    document.getElementById(elementId).classList.add("d-none");
  };
  let handleSubmit = (event) => {
    event.preventDefault();
    if (roleDescription === "") {
      showAlert("invalidDescription");
    } else {
      let data = {
        id: id,
        description: roleDescription,
        permissionIDs: permissionIDs,
      };
      props.update(data);
    }
  };
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Role
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => handleSubmit(e)}>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label>Permissions</Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name="permissions"
                    defaultValue={selectedPermissions}
                    onChange={(e) => handlePermissionChange(e)}
                    options={allPermissions}
                    isMulti
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label>
                    Description
                    <small
                      id="invalidDescription"
                      className="text-danger d-none"
                    >
                      (Description cannot be empty)
                    </small>
                  </Label>
                  <Input
                    type="textarea"
                    name="roleDescription"
                    defaultValue={roleDescription}
                    placeholder="Description"
                    onChange={(e) => {
                      setRoleDescription(e.target.value);
                    }}
                    onFocus={() => hideAlert("invalidDescription")}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <ResponseModal
        show={failureModal.display}
        onHide={() => setFailureModal({ display: false })}
        modalheading={"Error"}
        modaltext={failureModal.text}
      />
    </>
  );
};

export default EditRoleFormModal;

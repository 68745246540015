import React, { useState, useEffect } from "react";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import { Button } from "react-bootstrap";
import { tableColumns } from "./tableColumn";
import ConfirmationModal from "../ConfirmationModal";
import ResponseModal from "../ResponseModal";
import {
  successModalHeading,
  errorModalHeading,
} from "../../../config/config";
import filterFactory from "react-bootstrap-table2-filter";
import Loading from "../../../components/Loading";
import paginationFactory from "react-bootstrap-table2-paginator";
import Search from "../../../components/Search";
import BootstrapTable from "react-bootstrap-table-next";
import {
  errorDescription,
  error,
} from "../../../utilities/commonUtil";
import { fetchSmartSuggestionCategories } from "../../../utilities/apiUtils/smartSuggestions";
import { createCategory, deleteCategory, updateCategory } from "../../../utilities/apiUtils/adsCategories";
import { categoriesAllActions, categoriesResponses, isConfigAPICreatePermission, isConfigAPIDeletePermission, isConfigAPIEditPermission } from "../../../config/configConfigAPIDashboard";
import AdsCategory from "./AdsCategoryModal";

const formDataInitialState = {
  id: "",
  name: "",
  categories: []
}

const AdsCategories = () => {
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState(formDataInitialState)
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [categories, setCategories] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [action, setAction] = useState("");
  const [id, setId] = useState(null);
  const [successModalText, setSuccessModalText] = useState("");
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [changeType, setChangeType] = useState("Add")
  const [isCategoryModalVisible, setIsCategoryModalVisible] = useState(false)

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    setLoading(true)
    fetchCategoriesTableData()
  };

  let showCategoryModal = (type) => {
    setChangeType(type)
    setIsCategoryModalVisible(true)
  }

  let hideCategoryModal = () => {
    setIsCategoryModalVisible(false)
    setFormData(formDataInitialState)
  }

  let onTableChange = (type, newState) => { };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  const handleClick = () => {
    setNameFilter("");
    sessionStorage.removeItem("categoryFilterName")
  };

  const ClearButton = () => {
    return (
      <Button
        style={{ float: "right" }}
        className="form-control-lg"
        onClick={handleClick}
      >
        Reset Filters
      </Button>
    );
  };

  let setConfirmationModalState = (id, event) => {
    setAction(event);
    setConfirmationModalIsOpen(true);
    setId(id);
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        {isConfigAPIEditPermission() && (
          <Button
            className="mx-3"
            onClick={() => {
              showCategoryModal("Edit")
              setFormData((prevState) => ({
                ...prevState,
                id: row.id,
                name: row.name
              }))
            }}
          >
            Edit
          </Button>
        )}

        {isConfigAPIDeletePermission() && (
          <Button
            className="mx-3 btn-danger"
            onClick={(event) =>
              setConfirmationModalState(row.id, event.target.innerHTML)
            }
          >
            Delete
          </Button>
        )}

        {!(isConfigAPIEditPermission() || isConfigAPIDeletePermission()) && (
          <center className="text-info" style={{ fontSize: 13 }}>You do not have permissions to perform any action.</center>
        )}
      </div>
    ),
  };

  let deleteRecord = async (id) => {
    try {
      let response = await deleteCategory(id);
      if (response.status === "success") {
        let text = "Category has been deleted";
        showSuccessModal(text);
      } else {
        let text
        if (response?.errorDescription)
          text = response?.errorDescription?.split("):")[1];
        else
          text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  };

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let fetchCategoriesTableData = async () => {
    try {
      let response = await fetchSmartSuggestionCategories();
      if (response?.categories?.length > 0) setCategories(response.categories)
      else setCategories([])
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    } finally {
      setLoading(false);
    }
  };

  const filterCategories = () => {
    let items = categories;

    if (nameFilter)
      items = items.filter(item => item.name?.toLowerCase()?.includes(nameFilter?.toLowerCase()) || item.id?.toString()?.toLowerCase()?.includes(nameFilter?.toLowerCase()));

    return items;
  };

  useEffect(() => {
    fetchCategoriesTableData();

    // Fill stored filter values
    if (sessionStorage.getItem("categoryFilterName"))
      setNameFilter(sessionStorage.getItem("categoryFilterName"))
  }, []);

  const handleSubmit = async () => {
    const { id, name, categories = [] } = formData
    const payload = {
      name
    }
    const categoryNames = categories?.map(cat => cat.value)
    try {
      let response
      if (changeType === "Add")
        response = await createCategory(categoryNames)
      else
        response = await updateCategory(id, payload)
      if (response?.status === "success") {
        setSuccessModalText(changeType === "Add" ? categoriesResponses.addedSuccessfully : categoriesResponses.updatedSuccessfully);
        setSuccessModalDisplay(true);
      } else {
        let text
        if (response?.errorDescription)
          text = response?.errorDescription?.split("):")[1];
        else
          text = errorDescription(error.unexpectedError);
        showErrorModal(text);
      }
    } catch (err) {
      let text = errorDescription(error.unexpectedError);
      showErrorModal(text);
    } finally {
      hideCategoryModal()
    }
  }

  return (
    <Container fluid className="p-0">
      <Row>
        <h1 className="mb-4 mt-2 w-50 pl-3">Ads Categories</h1>

        {isConfigAPICreatePermission() && (
          <div className="d-flex align-items-center ml-auto">
            <Button color="success" className="d-flex btn-success mb-4 mr-3">
              <p className="mb-1 mt-1" style={{ fontSize: "1rem" }} onClick={() => showCategoryModal("Add")}>
                Add New Categories
              </p>
            </Button>
          </div>
        )}
      </Row>
      <div>
        <Card style={{ overflow: "hidden", paddingBottom: "100px" }}>
          <div container-fullwidth="true" style={{ margin: "2%" }}>
            <Row className="justify-content-md-center mx-auto">
              <Col md={6} sm={12} className="mt-3">
                <Search
                  placeholder="Search Name"
                  value={nameFilter}
                  onChange={(e) => {
                    setNameFilter(e.target.value)
                    sessionStorage.setItem("categoryFilterName", e.target.value)
                  }}
                ></Search>
              </Col>
              <Col md={6} xs={5} className="p-0 mt-3">
                <ClearButton>Reset Filters</ClearButton>
              </Col>
            </Row>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <CardBody>
              <BootstrapTable
                bootstrap4={true}
                wrapperClasses="table-responsive"
                hover={true}
                bordered={false}
                keyField="id"
                data={filterCategories()}
                columns={tableColumns}
                expandRow={expandRow}
                filter={filterFactory()}
                pagination={paginationFactory({
                  sizePerPage: 10,
                  sizePerPageList: [5, 10, 25, 50],
                })}
                defaultSorted={[{
                  dataField: 'id',
                  order: 'desc'
                }]}
                onTableChange={onTableChange}
                remote={{ filter: true }}
                noDataIndication="No results found"
              />
            </CardBody>
          )}
        </Card>
      </div>
      <AdsCategory
        show={isCategoryModalVisible}
        type={changeType}
        formData={formData}
        setFormData={setFormData}
        onSubmit={handleSubmit}
        onHide={hideCategoryModal}
      />
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={action}
        onConfirmationTrue={() => {
          setConfirmationModalIsOpen(false);
          if (action === categoriesAllActions.delete) deleteRecord(id);
        }}
        onHide={() => setConfirmationModalIsOpen(false)}
      />
      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={successModalHeading}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={errorModalHeading}
        modaltext={failureModalText}
      />
    </Container>
  );
};

export default AdsCategories;
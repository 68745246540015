import { host } from "./index";
import { retrieveFromLocalStorage } from "../localStorageUtil";


export let getGifAutoDownloadRules = async (id) => {
  let rules = [];

  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/gifPacks/${id}/autoDownloadRules`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      rules = res.rules;
    }
    return rules;
  } catch (error) {
    return;
  }
};

export let saveGifAutoDownloadRules = async (data, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/gifPacks/${id}/autoDownloadRules`;
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ rules: data }),
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let getGifPackTargetingRules = async (id) => {
  let rules = [];

  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/gifPacks/${id}/targetingRules`;
  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      rules = res.rules;
    }
    return rules;
  } catch (error) {
    return;
  }
};

export let getGifTargetingRules = async (gifIds) => {
  let rules = [];

  let token = retrieveFromLocalStorage("token");

  // Construct the URL with the given gifIds
  let url = `${host}/v4/internal/gifs/targetingRules?gifIDs=${gifIds}`;

  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    var res = await response.json();

    if (res) {
      rules = res.rules;
    }

    return rules;
  } catch (error) {
    console.error("Error fetching GIF targeting rules:", error);
    return [];
  }
};


export let saveGifPackTargetingRules = async (data, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/gifPacks/${id}/targetingRules`;
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ rules: data }),
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let saveGifsTargetingRules = async (data, gifIds) => {
  let token = retrieveFromLocalStorage("token");
  gifIds = gifIds.split(",").map(id => parseInt(id))

  let url = `${host}/v4/internal/gifs/targetingRules`;
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ gifIds: gifIds, rules: data }),
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let getGifShareText = async (GifId) => {
  let token = retrieveFromLocalStorage("token");
  let Gif = {};
  let url = `${host}/v4/internal/gifs/${GifId}/shareTexts`;

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
  Gif = response.json();
  });

  return Gif;
};

export let saveGifShareText = async (data, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/gifs/${id}/shareTexts`;
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

export let editGif = async (id, data) => {
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/gifs/${id}`;

  try {
    let response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
    },
    });
    return await response.json();
  } catch (error) {
    return;
  }
}

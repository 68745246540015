import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Button, Col, Form, FormGroup, Label, Row, Input } from "reactstrap";
import TagsInput from "react-tagsinput";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { capitalize } from "@material-ui/core";
import ResponseModal from "../../ResponseModal";
import { X } from "react-feather";
import { iconSize } from "../../../../config/UserConfig";
import {
  addPackageSetData,
  editPackageSetData,
} from "../../../../utilities/apiUtils/keyboardPrompts";
import {
  successModalHeading,
  errorModalHeading,
} from "../../../../config/config";

import { packageSetResponses } from "../../../../config/ConfigKeyboardPrompts";

export const ManagePackageSets = (props) => {
  const [name, setName] = useState("");
  const [packages, setPackages] = useState([
    {
      packageName: "",
      fields: [],
      fieldType: "",
    },
  ]);

  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");
  const [selectedFields, setSelectedFields] = useState([]);

  const predefinedFields = [
    "phone",
    "search",
    "email",
    "password",
    "web",
    "default",
    "unknown",
  ].map((field) => ({ value: field, label: field }));

  const handleDropdownItemClick = (value, index) => {
    let temp = [...packages];
    if (!temp[index].fields.includes(value)) {
      temp[index].fields.push(value);
      setPackages(temp);
      setSelectedFields(temp[index].fields);
    } else {
      temp[index].fields = temp[index].fields.filter(
        (field) => field !== value
      );
      setPackages(temp);
      setSelectedFields(temp[index].fields);
    }
  };

  const handleTagsChange = (fields, index) => {
    let temp = [...packages];
    const uniqueFields = [...new Set(fields)]; // Remove duplicates
  
    temp[index].fields = uniqueFields;
    setPackages(temp);
    setSelectedFields(uniqueFields);
  };

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    props.onHide();
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
    props.onHide();
  };

  const handlePackageNameChange = (event, index) => {
    let temp = [...packages];
    temp[index].packageName = event.target.value;
    setPackages(temp);
  };

  const setPackageSetData = () => {
    if (props.action === "edit" || props.action === "view") {
      setName(props.packageSet.name);
      // parse fields from string to object
      var convertFields = props.packageSet.packages;
      convertFields.forEach((element) => {
        if (element.fields !== null) {
          // check if fields is a string or an object
          if (typeof element.fields === "string") {
            element.fields = JSON.parse(element.fields);
          }
        } else {
          element.fields = [];
        }
      });
      setPackages(convertFields);
    }
  };

  const handleNameChange = (event) => {
    const name = event.target.value;
    setName(name);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // show error along with the duplicate packageName if there is any duplicate packageName
    let duplicatePackageNames = [];
    packages.forEach((element) => {
      let count = 0;
      packages.forEach((item) => {
        if (element.packageName === item.packageName) {
          count++;
        }
      });
      if (count > 1) {
        duplicatePackageNames.push(element.packageName);
      }
    });

    if (duplicatePackageNames.length > 0) {
      let text = "Duplicate package names: ";
      text += duplicatePackageNames.join(", ");
      showErrorModal(text);
      return;
    }

    // convert fields from json to string
    var convertedPackages = packages.map((item) => {
      return {
        packageName: item.packageName,
        fields: JSON.stringify(item.fields),
        fieldType: item.fieldType ? item.fieldType : null,
      };
    });

    if (props.action == "add") {
      const data = JSON.stringify({
        name: name,
        packages: convertedPackages,
      });

      let response = await addPackageSetData(data);

      if (response.status == 200) {
        let text = packageSetResponses.addeddSuccessfully;
        showSuccessModal(text);
      } else {
        response = await response.json();
        let text = response.errorDescription;
        showErrorModal(text);
      }
    } else if (props.action == "edit") {
      const data = JSON.stringify({
        name: name,
        packages: convertedPackages,
      });

      let response = await editPackageSetData(data, props.packageSet.id);

      if (response.status == 200) {
        let text = packageSetResponses.updatedSuccessfully;
        showSuccessModal(text);
      } else {
        response = await response.json();
        let text = response.errorDescription;
        showErrorModal(text);
      }
    }
  };

  const handleFieldTypeChange = (event, index) => {
    let temp = [...packages];
    temp[index].fieldType = event.target.value;
    setPackages(temp);
  };

  useEffect(() => {
    setPackageSetData();
  }, []);

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {capitalize(props.action)} Package Sets
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(event) => handleSubmit(event)}>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label>Name</Label>
                  <Input
                    type="text"
                    name="Name"
                    placeholder="Enter Name"
                    disabled={props.action === "add" ? false : true}
                    defaultValue={name}
                    onChange={(event) => handleNameChange(event)}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <Row className="mb-3">
                  <Col md={6} xs={6}>
                    <Label
                      className="font-weight-bolder mb-2"
                      style={{ fontSize: "16px" }}
                    >
                      Packages:
                    </Label>
                  </Col>
                  <Col md={6} xs={6}>
                    <div className="p-0">
                      {props.action !== "view" && (
                        <Button
                          color="success"
                          className="ml-auto d-flex btn-success"
                          // disabled={props.action === "view" ? true : false}
                          onClick={() =>
                            setPackages(
                              packages.concat({
                                packageName: "",
                                fields: [],
                                fieldType: "",
                              })
                            )
                          }
                        >
                          <p
                            className="mb-1 mt-1"
                            style={{ fontSize: "0.75rem" }}
                          >
                            Add Packages
                          </p>
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
                {packages?.map((item, index) => {
                  return (
                    <Row key={index} form>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Package Name: </Label>
                          <Input
                            type="text"
                            name="packageName"
                            placeholder="Enter Package Name"
                            value={item.packageName}
                            disabled={props.action === "view" ? true : false}
                            onChange={(event) =>
                              handlePackageNameChange(event, index)
                            }
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <div className="p-0" style={{ marginRight: "20px" }}>
                          {props.action !== "view" && (
                            <X
                              className="d-none d-lg-block ml-auto d-flex"
                              size={iconSize}
                              color="red"
                              style={{
                                cursor: "pointer",
                                border: "2px solid red",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                setPackages(
                                  packages.filter((_, i) => i !== index)
                                );
                              }}
                            ></X>
                          )}
                        </div>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Label>Field Type </Label>
                          <Input
                            type="text"
                            name="fieldType"
                            placeholder="Enter Field Type"
                            value={item.fieldType}
                            disabled={props.action === "view" ? true : false}
                            onChange={(event) =>
                              handleFieldTypeChange(event, index)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Label>Fields (Deprecated)</Label>
                          <div className="d-flex align-items-center">
                            <TagsInput
                              disabled={props.action === "view"}
                              value={packages[index].fields}
                              onChange={(select) =>
                                handleTagsChange(select, index)
                              }
                              inputProps={{ placeholder: "Select fields" }}
                            />
                            <DropdownButton
                              id={`dropdown-button-${index}`}
                              title=""
                              variant="info"
                              disabled={props.action === "view"}
                              onSelect={(eventKey) =>
                                handleDropdownItemClick(eventKey, index)
                              }
                            >
                              {predefinedFields.map((field, idx) => (
                                <Dropdown.Item
                                  key={idx}
                                  eventKey={field.value}
                                  active={packages[index].fields.includes(
                                    field.value
                                  )}
                                >
                                  {field.label}
                                </Dropdown.Item>
                              ))}
                            </DropdownButton>
                          </div>
                        </FormGroup>
                      </Col>
                      <hr
                        style={{
                          width: "75rem",
                          color: "black",
                        }}
                      />
                    </Row>
                  );
                })}
                {/* <FormGroup>
                  <Label>
                    Packages{" "}
                    <small id="invalidTagsAlert" className="text-danger d-none">
                      (Enter Packages)
                    </small>
                  </Label>
                  <TagsInput
                    value={packages}
                    onChange={(select) => handlePackageChange(select)}
                  />
                </FormGroup> */}
              </Col>
            </Row>
            <div className="d-block w-100">
              {props.action !== "view" ? (
                <Button color="primary" type="submit" onSubmit={handleSubmit}>
                  Submit
                </Button>
              ) : null}
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <ResponseModal
        show={successModalDisplay}
        onHide={successModalClose}
        modalheading={successModalHeading}
        modaltext={successModalText}
      />
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={errorModalHeading}
        modaltext={failureModalText}
      />
    </>
  );
};

export default ManagePackageSets;

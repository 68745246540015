import React, { useState } from 'react'
import { Modal, Button } from "react-bootstrap";
import { Row, Col, FormGroup, Label, Input, Form, CustomInput } from "reactstrap";
import { formatJSON, isValidJSON } from '../../../config/configAdsEnvConfigs';

const EnvConfigModal = ({ show = false, type, formData, setFormData = () => { }, onSubmit = () => { }, onHide = () => { } }) => {
  const [isValid, setIsValid] = useState(true)
  const [isValidatorEnable, setIsValidatorEnable] = useState(true)

  const handleChange = (event) => {
    const { name, value } = event.target

    // If validator is enabled and value is not valid then show error
    if (name === "value" && isValidatorEnable) {
      setIsValid(isValidJSON(value))
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit()
    handleHide()
  }

  const handleToggleValidator = (event) => {
    const { checked } = event.target
    setIsValidatorEnable(checked)
  }

  const handleHide = () => {
    setIsValid(true)
    setIsValidatorEnable(true)
    onHide()
  }

  return (
    <Modal
      show={show}
      onHide={handleHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header className="bg-light" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {type} Env Config
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row form>
            <Col lg={12} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Name <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="name"
                  placeholder="Enter Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col lg={12} md={12}>
              <FormGroup>
                <div
                  className="font-weight-bolder mb-2 d-flex align-items-center"
                  style={{ fontSize: "14px" }}
                >
                  Value
                  <span className="text-danger ml-1">*</span>
                  {!isValid && isValidatorEnable && (
                    <span className='font-weight-bolder text-danger ml-1'>(Below JSON is not valid)</span>
                  )}
                  <div className='d-flex align-items-center ml-auto'>
                    JSON Validator
                    <CustomInput
                      className="ml-1"
                      type="checkbox"
                      id="isValidatorEnable"
                      value={isValidatorEnable}
                      onChange={handleToggleValidator}
                      checked={isValidatorEnable}
                    />
                  </div>
                </div>
                <Input
                  rows={10}
                  type="textarea"
                  name="value"
                  placeholder="Enter Name"
                  value={formatJSON(formData.value)}
                  onChange={handleChange}
                  required
                />
                {!isValidatorEnable && (
                  <div style={{ fontSize: 13 }} className='text-warning'>
                    <span role="img" aria-label="warning-icon">⚠️</span>JSON validator is disabled, backend issues may arise due to invalid JSON value
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleHide}>Close</Button>
          {(isValid || (!isValid && !isValidatorEnable)) && (
            <Button type='submit' className='btn-success'>Submit</Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default EnvConfigModal
import { retrieveFromLocalStorage } from "../localStorageUtil";
import { host } from "./index";
// Creates keyboard font
export let createKeyboardFont = async (newKeyboardFont) => {
  let url = `${host}/v4/internal/keyboardFonts`;
  let token = retrieveFromLocalStorage("token");

  try {
    var response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(newKeyboardFont),
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    return await response.json();
  } catch (error) {
    return ;
  }
};
// Updates the keyboard font according to the id
export let updateKeyboardFont = async (updatedKeyboardFont, id) => {
  let url = `${host}/v4/internal/keyboardFonts/${id}`;
  let token = retrieveFromLocalStorage("token");

  try {
    var response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(updatedKeyboardFont),
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    return await response.json();
  } catch (error) {
    return ;
  }
};
// Fetches all the font languages
export let fetchFontLanguages = async () => {
  let url = `${host}/v4/internal/keyboardFonts/languages`;
  let token = retrieveFromLocalStorage("token");

  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    return await response.json();
  } catch (error) {
    return ;
  }
  
};
// Fetches all active the keyboard fonts
export let fetchActiveKeyboardFonts = async () => {
  let status = "active";
  let url = `${host}/v4/internal/keyboardFonts?status=${status}`;
  let token = retrieveFromLocalStorage("token");

  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    return await response.json();
  } catch (error) {
    return ;
  }
};
// Fetches all the keyboard fonts
export let fetchKeyboardFonts = async () => {
  let url = `${host}/v4/internal/keyboardFonts`;
  let token = retrieveFromLocalStorage("token");

  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    return await response.json();
  } catch (error) {
    return ;
  }
};
// Fetches details of a keyboard font
export let getKeyboardFontDetails = async (id) => {
  let url = `${host}/v4/internal/keyboardFonts/${id}`;
  let token = retrieveFromLocalStorage("token");

  try {
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    return await response.json();
  } catch (error) {
    return ;
  }
};
// Reorders the keyboard fonts based on priorities
export let reorderKeyboardFonts = async (order) => {
  let body = {
    "order": order,
  }
  let url = `${host}/v4/internal/keyboardFonts/reorderFonts`;
  let token = retrieveFromLocalStorage("token");

  try {
    var response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    return await response.json();
  } catch (error) {
    return ;
  }
};
// Publishes the keyboard font
export let publishKeyboardFont = async (id) => {
  let url = `${host}/v4/internal/keyboardFonts/${id}/publish`;
  let token = retrieveFromLocalStorage("token");

  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
          Authorization: "Bearer " + token,
      },
  })
    return await response.json();
  } catch (error) {
    return ;
  }
};
// Activates the keyboard font
export let activateKeyboardFont = async (id) => {
  let url = `${host}/v4/internal/keyboardFonts/${id}/activate`;
  let token = retrieveFromLocalStorage("token");

  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
          Authorization: "Bearer " + token,
      },
  })
    return await response.json();
  } catch (error) {
    return ;
  }
};
// Deactivates the keyboard font
export let deactivateKeyboardFont = async (id) => {
  let url = `${host}/v4/internal/keyboardFonts/${id}/deactivate`;
  let token = retrieveFromLocalStorage("token");

  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
          Authorization: "Bearer " + token,
      },
  })
    return await response.json();
  } catch (error) {
    return ;
  }
};

import React, { Fragment, useState } from "react";
import ResponseModal from "../ResponseModal";
import {
  
  createAdBanner,
  editAdBanner,
  
} from "../../../utilities/apiUtils/adBanners";

import AdBannerForm from "./AdBannerForm";
import Routes from "../../../routes/index";
import {
  errorDescription,
  error,
  redirectTo,
  redirectionOnTokenExpiry,
} from "../../../utilities/commonUtil";
import { successModalHeading, failureModalHeading } from "../../../config";

const AdBannerFormContainer = (props) => {
  const [formTextState, setFormTextState] = useState({
    formName : props.formName,
    name : "",
    bannerImageBuffer: "",
    bannerVideoBuffer: "",
    enableAutoPlay: false,
    enableAudio: false,
    bannerAspectRatio: "",
    brandCampaignID: "",
    widthPercentage: "",
    type: "",
    mediaType: "",
    quickReplyCategories: "",
    clickTrackers: "",
    impressionTrackers: "",
    adBannerAction: "",
  });
  const [showForm, setShowForm] = useState(true);
  const [successModalState, setSuccessModalState] = useState({
    successModalDisplay: false,
    successModalText: "",
  });
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });



  const onSubmit = async (adBannerData) => {
    let formData = new FormData();

    // Append the form data

    if (adBannerData.bannerImageBuffer) {
      formData.append("bannerImageBuffer", adBannerData.bannerImageBuffer);
    }
    formData.append("bannerAspectRatio", adBannerData.bannerAspectRatio);
    formData.append("name", adBannerData.name);
    formData.append("widthPercentage", adBannerData.widthPercentage);
    formData.append("type", adBannerData.type);
    formData.append("mediaType", adBannerData.mediaType);

    if (adBannerData.brandCampaignID) {
      formData.append("brandCampaignID", adBannerData.brandCampaignID);
    }

    
    if (adBannerData.mediaType === "video") {

      if (adBannerData.bannerVideoBuffer) {
        formData.append("bannerVideoBuffer", adBannerData.bannerVideoBuffer);
      }
      formData.append("enableAutoPlay", adBannerData.enableAutoPlay);
      formData.append("enableAudio", adBannerData.enableAudio);
    }

    if (adBannerData.quickReplyCategories) {
      formData.append(
        "quickReplyCategoryID",
        JSON.stringify(adBannerData.quickReplyCategories)
      );
    }

    if (adBannerData.clickTrackers && adBannerData.clickTrackers?.length > 0) {
      formData.append("clickTrackers", JSON.stringify(adBannerData.clickTrackers));
    }

    if (adBannerData.impressionTrackers && adBannerData.impressionTrackers?.length > 0) {
      formData.append(
        "impressionTrackers",
        JSON.stringify(adBannerData.impressionTrackers)
      );
    }

    formData.append("adBannerAction" , JSON.stringify(adBannerData.adBannerAction));

    

    if (props.formName === "Create Ad Banner") {
      try {
        let response = await createAdBanner(
          formData,
        );
        if (response.errorCode) {
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: response.errorDescription,
          });
        } else {
          setSuccessModalState({
            successModalDisplay: true,
            successModalText: response.description,
          });
        }
      } catch (err) {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      }
    } else {
      // API call for edit pop text style
      formData.append("id", props.match.params.id);
      try {
        let response = await editAdBanner(formData, props.match.params.id);
        if (response.errorCode) {
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: response.errorDescription,
          });
        } else {
          setSuccessModalState({
            successModalDisplay: true,
            successModalText: response.description,
          });
        }
      } catch (err) {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      }
    }
  };

  const failureModalClose = () => {
    setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
  };

  const successModalClose = () => {
    setSuccessModalState((prev) => ({ ...prev, successModalDisplay: false }));
    redirectTo(Routes.quickReplies.children.adBanners.path);
  };

  return (
    <Fragment>
      {showForm ? (
        <AdBannerForm
          id={props.match.params.id}
          adBanner={formTextState}
          submit={onSubmit}
          updateAllowed={props.updateAllowed}
        />
      ) : null}
      <ResponseModal
        show={failureModalState.failureModalDisplay}
        onHide={() => failureModalClose()}
        modalheading={failureModalHeading}
        modaltext={failureModalState.failureModalText}
      />
      <ResponseModal
        show={successModalState.successModalDisplay}
        onHide={() => successModalClose()}
        modalheading={successModalHeading}
        modaltext={successModalState.successModalText}
      />
    </Fragment>
  );
};

export default AdBannerFormContainer;

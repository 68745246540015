import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import { AutoComplete, Input as AntdInput } from 'antd';
import { FaPlus, FaMinus } from "react-icons/fa";
import { Row, Col, FormGroup, Label, Input, Form, Button, CustomInput } from "reactstrap";
import Select, { components } from "react-select";
import { providerOptions } from '../../../config/configPlacementProviders';
import { fixedPositionTypeOptions, showFixedPositionsUpdateButton } from '../../../config/configSmartSuggestions';
import ResponseModal from '../ResponseModal';
import { errorModalHeading } from '../../../config/config';
import { generateColor, replaceUnderscoreAndCapitalize } from '../../../utilities/commonUtil';
import Routes from "../../../routes/index";

const SmartSuggestionsFixedPositionModal = ({ show = false, type, position, isExtraFixedPosition, allAdsOptions = [], placementProvider = [], formData, setFormData = () => { }, onSubmit = () => { }, onHide = () => { } }) => {
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");
  const [isAppliedToAllPlacementIds, setIsAppliedToAllPlacementIds] = useState(false)
  const [positionBeforeUpdate, setPositionBeforeUpdate] = useState(null)

  const [data, setData] = useState({
    position: "",
    type: "",
    providers: []
  })
  const [fixedPositionProviders, setFixedPositionProviders] = useState([{
    name: "",
    priority: ""
  }])
  const [fixedPositionAds, setFixedPositionAds] = useState([{
    ad: "",
    weightage: ""
  }])
  const [adsOptions, setAdsOptions] = useState([])
  const [fixedPositionProviderOptions, setFixedPositionProviderOptions] = useState([])

  useEffect(() => {
    if (placementProvider?.length > 0) {
      setData((prevState) => ({
        ...prevState,
        providers: placementProvider?.map(el => ({
          value: el,
          label: providerOptions.find(option => option.value === el)?.label ?? el
        }))
      }))
      const providers = placementProvider?.filter(provider => !provider.includes("internal"))
      setFixedPositionProviderOptions(providers?.map(provider => ({
        value: provider,
        label: providerOptions.find(option => option.value === provider)?.label ?? provider,
        target: {
          name: "name",
          value: {
            value: provider,
            label: providerOptions.find(option => option.value === provider)?.label ?? provider
          }
        }
      })))
    } else {
      setData((prevState) => ({
        ...prevState,
        providers: []
      }))
      setFixedPositionProviderOptions([])
    }
  }, [placementProvider])

  useEffect(() => {
    if (type) {
      setPositionBeforeUpdate(formData?.position)
      setData((prevState) => ({
        ...prevState,
        position: formData?.position,
        type: {
          value: type,
          label: fixedPositionTypeOptions.find(option => option.value === type)?.label ?? type
        }
      }))
      setFixedPositionProviders(formData?.fixedPositionProviders?.length > 0
        ? formData?.fixedPositionProviders?.map(el => ({
          name: el?.name ? {
            value: el?.name,
            label: providerOptions.find(option => option.value === el?.name)?.label ?? el?.name
          } : "",
          priority: el?.priority
        }))
        : [{
          name: "",
          priority: ""
        }])
      setFixedPositionAds(formData?.fixedPositionAds?.length > 0
        ? formData?.fixedPositionAds?.map(el => ({
          ad: el?.id ? {
            ...el,
            id: el?.id,
            name: el?.title,
            provider: el?.provider,
            iconURL: el?.iconURL
          } : "",
          weightage: el?.weightage
        }))
        : [{
          ad: "",
          weightage: ""
        }])
    } else {
      setData((prevState) => ({
        ...prevState,
        position: "",
        type: "",
      }))
      setFixedPositionProviders([{
        name: "",
        priority: ""
      }])
      setFixedPositionAds([{
        ad: "",
        weightage: ""
      }])
    }
  }, [formData, type, position])

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  let showFailureModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleFixedPositionProvidersChange = (event, index) => {
    const { name, value } = event.target
    const temp = [...fixedPositionProviders]
    temp[index][name] = value
    setFixedPositionProviders(temp)
  }

  const addFixedPositionProvider = () => {
    setFixedPositionProviders((prevState) => (
      prevState.concat({
        name: "",
        priority: ""
      })
    ));
  }

  const removeFixedPositionProvider = (index) => {
    setFixedPositionProviders((prevState) => (
      prevState.filter((_, i) => i !== index)
    ));
  }

  const handleFixedPositionAdsChange = (name, value, index) => {
    const temp = [...fixedPositionAds]
    temp[index][name] = value
    setFixedPositionAds(temp)
  }

  const addFixedPositionAd = () => {
    setFixedPositionAds((prevState) => (
      prevState.concat({
        ad: "",
        weightage: ""
      })
    ));
  }

  const removeFixedPositionAd = (index) => {
    setFixedPositionAds((prevState) => (
      prevState.filter((_, i) => i !== index)
    ));
  }

  const handleSelectAd = (option, index) => {
    const temp = [...fixedPositionAds]
    temp[index]["ad"] = allAdsOptions.find(ad => ad.id === option.id)
    setFixedPositionAds(temp)
    setAdsOptions([])
  };

  const formatAdsDropdownLabel = (ad) => {
    const { background, color } = generateColor(ad.provider)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <div className='d-flex align-items-center'>
          <img
            src={ad.iconURL}
            alt={ad.name + " logo"}
            width={24}
            height={24}
            style={{
              objectFit: "contain",
              borderRadius: "0.25rem"
            }}
          />
          <span className='ml-2'>
            {ad.id + ": "}
            {ad.name?.length > 20
              ? ad.name?.substr(0, 20)?.concat("...")
              : ad.name
            }
          </span>
        </div>
        <span style={{
          background,
          color,
          fontSize: 11,
          fontWeight: 600,
          borderRadius: 4,
          width: "fit-content",
          padding: "2px 10px"
        }}>
          {replaceUnderscoreAndCapitalize(ad.provider)}
        </span>
      </div>
    )
  }

  const handleSearch = (value) => {
    const filteredOptions = allAdsOptions.filter(option => option.name?.toLowerCase()?.includes(value?.toLowerCase()) || option.id?.toString()?.includes(value)).map((ad) => ({
      value: ad.id + ": " + ad.name + " - " + replaceUnderscoreAndCapitalize(ad.provider),
      label: formatAdsDropdownLabel(ad),
      id: ad.id
    }))
    setAdsOptions(value ? filteredOptions : []);
  };

  const handleHide = () => {
    setData((prevState) => ({
      ...prevState,
      position: "",
      type: ""
    }))
    setFixedPositionProviders([{
      name: "",
      priority: ""
    }])
    setFixedPositionAds([{
      ad: "",
      weightage: ""
    }])
    setIsAppliedToAllPlacementIds(false)
    setPositionBeforeUpdate(null)
    onHide()
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    let payload
    if (isExtraFixedPosition && Number(data.position) <= 5) {
      showFailureModal("Fixed position should be greater than 5, else use top fixed position")
      return
    }
    if (data.type.value === "provider" && fixedPositionProviders.length <= 0) {
      showFailureModal("Please add atleast one Fixed Position Provider")
      return
    }
    if (data.type.value === "ad" && fixedPositionAds.length <= 0) {
      showFailureModal("Please add atleast one Fixed Position Ad")
      return
    }
    if (!data.type.value) {
      payload = {
        position,
        isAppliedToAllPlacementIds: data.type.value === "ad" ? isAppliedToAllPlacementIds : false,
        fixedPositionProviders: [],
        fixedPositionAds: []
      }
    }
    else {
      payload = {
        position: isExtraFixedPosition ? Number(data.position) : position,
        isAppliedToAllPlacementIds: data.type.value === "ad" ? isAppliedToAllPlacementIds : false,
        fixedPositionProviders: data.type.value === "ad"
          ? []
          : fixedPositionProviders.map(provider => ({
            name: provider.name?.value,
            position: isExtraFixedPosition ? Number(data.position) : position,
            priority: Number(provider.priority)
          })),
        fixedPositionAds: data.type.value === "provider"
          ? []
          : fixedPositionAds.map(ad => ({
            ...ad.ad,
            id: Number(ad.ad.id),
            title: ad.ad.name,
            provider: ad.ad.provider,
            iconURL: ad.ad.iconURL,
            weightage: Number(ad.weightage)
          })),
      }
    }
    setFormData(payload)
    onSubmit(payload, isExtraFixedPosition ? positionBeforeUpdate : position)
    handleHide()
  }

  const handleReset = () => {
    let payload = {
      position: isExtraFixedPosition ? Number(data.position) : position,
      isAppliedToAllPlacementIds: data.type.value === "ad" ? isAppliedToAllPlacementIds : false,
      fixedPositionProviders: [],
      fixedPositionAds: []
    }
    setFormData(payload)
    onSubmit(payload, isExtraFixedPosition ? positionBeforeUpdate : position)
    handleHide()
  }

  const requiredComponent = (props) => (
    <div>
      <components.Input {...props} required={true} />
    </div>
  );

  const notRequiredComponent = (props) => (
    <div>
      <components.Input {...props} required={false} />
    </div>
  );

  return (
    <Modal
      show={show}
      onHide={handleHide}
      aria-labelledby="contained-modal-title-vcenter"
      size='lg'
      centered
      backdrop="static"
    >
      <Modal.Header className="bg-light" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {isExtraFixedPosition ? "Extra Fixed Position" : `Fixed Position #${position}`}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row form>
            {isExtraFixedPosition && (
              <Col lg={6} md={12}>
                <FormGroup>
                  <Label
                    className="font-weight-bolder mb-2"
                    style={{ fontSize: "14px" }}
                  >
                    Position <span className="text-danger">*</span><small className='text-info'> (If position index is less than 6 choose top fixed positions)</small>
                  </Label>
                  <Input
                    type="number"
                    name="position"
                    min={6}
                    placeholder="Position"
                    value={data.position}
                    onChange={handleChange}
                    disabled={!showFixedPositionsUpdateButton()}
                    required
                  />
                </FormGroup>
              </Col>
            )}
            <Col lg={6} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Fixed Position Type <span className="text-danger">*</span>
                </Label>
                <Select
                  id="type"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="type"
                  placeholder="Select Fixed Position Type"
                  value={data.type}
                  onChange={handleChange}
                  options={fixedPositionTypeOptions}
                  components={{
                    Input:
                      (data.type)
                        ? notRequiredComponent
                        : requiredComponent,
                  }}
                  isDisabled={!showFixedPositionsUpdateButton()}
                />
              </FormGroup>
            </Col>
          </Row>
          {data.type?.value === "provider" ? (
            <>
              <Row form>
                <Col lg={12} md={12}>
                  <FormGroup>
                    <Label
                      className="font-weight-bolder mb-2"
                      style={{ fontSize: "14px" }}
                    >
                      Providers <span className="text-danger">*</span>
                      {showFixedPositionsUpdateButton() && (
                        <div className='text-info' style={{ fontSize: "13px" }}>
                          To manage placement providers visit {" "}
                          <a href={Routes.ads.children.placementProviders.path} target='_blank' rel="noopener noreferrer">
                            <span className='font-weight-bolder'>Link</span>
                          </a>
                        </div>
                      )}
                    </Label>
                    <Select
                      id="providers"
                      isMulti
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="providers"
                      placeholder="Select Providers"
                      value={data.providers}
                      onChange={(event) => {
                        setData((prevState) => ({
                          ...prevState,
                          providers: event,
                        }))
                      }}
                      options={providerOptions}
                      components={{
                        Input:
                          (data.providers?.length > 0)
                            ? notRequiredComponent
                            : requiredComponent,
                      }}
                      isDisabled={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div
                    className="font-weight-bolder mb-2"
                    style={{ fontSize: "14px" }}
                  >
                    Fixed Position Providers
                  </div>
                </Col>
                <Col>
                  {(fixedPositionProviders?.length <= 0) ? (
                    <Button color='success' className="float-right" onClick={addFixedPositionProvider}>Add Fixed Position Providers</Button>
                  ) : null}
                </Col>
              </Row>
              {fixedPositionProviders?.map((fixedPositionProvider, index) => (
                <Row key={index} form className="mb-3">
                  <Col>
                    <FormGroup className="mb-0">
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "14px" }}
                      >
                        Name <span className="text-danger">*</span>
                      </Label>
                      <Select
                        id="name"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        name="name"
                        placeholder="Select Name"
                        value={fixedPositionProvider.name}
                        onChange={(event) => handleFixedPositionProvidersChange(event, index)}
                        options={fixedPositionProviderOptions}
                        components={{
                          Input:
                            (fixedPositionProvider.name)
                              ? notRequiredComponent
                              : requiredComponent,
                        }}
                        isDisabled={!showFixedPositionsUpdateButton()}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="mb-0">
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "14px" }}
                      >
                        Priority <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        name="priority"
                        min={1}
                        placeholder="Priority"
                        value={fixedPositionProvider.priority}
                        onChange={(event) => handleFixedPositionProvidersChange(event, index)}
                        disabled={!showFixedPositionsUpdateButton()}
                        required
                      />
                    </FormGroup>
                  </Col>
                  {showFixedPositionsUpdateButton() && (
                    <Col md={1} className="align-self-end">
                      {index === 0 ? (
                        <Button onClick={addFixedPositionProvider} color="transparent">
                          <FaPlus />
                        </Button>
                      ) : (
                        <Button onClick={() => removeFixedPositionProvider(index)} color="transparent">
                          <FaMinus />
                        </Button>
                      )}
                    </Col>
                  )}
                </Row>
              ))}
            </>
          ) : null}
          {data.type?.value === "ad" ? (
            <>
              <Row>
                <Col>
                  <div
                    className="font-weight-bolder mb-2"
                    style={{ fontSize: "14px" }}
                  >
                    Ads
                  </div>
                </Col>
                <Col>
                  {(fixedPositionAds?.length <= 0) ? (
                    <Button color='success' className="float-right" onClick={addFixedPositionAd}>Add Fixed Position Ads</Button>
                  ) : null}
                </Col>
              </Row>
              {fixedPositionAds?.map((fixedPositionAd, index) => (
                <Row key={fixedPositionAd?.ad?.id} form className="mb-3">
                  <Col>
                    <FormGroup className="mb-0 d-flex flex-column">
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "14px" }}
                      >
                        Name <span className="text-danger">*</span>
                      </Label>
                      <AutoComplete
                        defaultValue={
                          !fixedPositionAd.ad
                            ? ""
                            : fixedPositionAd.ad?.id + ": " + fixedPositionAd.ad?.name + " - " + replaceUnderscoreAndCapitalize(fixedPositionAd.ad?.provider)
                        }
                        options={adsOptions}
                        onSelect={(value, option) => handleSelectAd(option, index)}
                        onSearch={handleSearch}
                        disabled={!showFixedPositionsUpdateButton()}
                      >
                        <AntdInput.Search placeholder="Search ad by name" required />
                      </AutoComplete>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="mb-0">
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "14px" }}
                      >
                        Weightage <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        name="weightage"
                        min={1}
                        placeholder="Weightage"
                        value={fixedPositionAd.weightage}
                        onChange={(event) => handleFixedPositionAdsChange("weightage", event.target.value, index)}
                        disabled={!showFixedPositionsUpdateButton()}
                        required
                      />
                    </FormGroup>
                  </Col>
                  {showFixedPositionsUpdateButton() && (
                    <Col md={1} className="align-self-end">
                      {index === 0 ? (
                        <Button onClick={addFixedPositionAd} color="transparent">
                          <FaPlus />
                        </Button>
                      ) : (
                        <Button onClick={() => removeFixedPositionAd(index)} color="transparent">
                          <FaMinus />
                        </Button>
                      )}
                    </Col>
                  )}
                </Row>
              ))}
              {showFixedPositionsUpdateButton() && (
                <Row>
                  <Col>
                    <FormGroup className="d-flex align-items-center">
                      <Label
                        className="font-weight-bolder mb-0 text-info"
                        style={{ fontSize: "14px" }}
                      >
                        Apply fixed position ads settings to all the placements
                      </Label>
                      <div className="custom-control custom-switch">
                        <CustomInput
                          type="checkbox"
                          id="isAppliedToAllPlacementIds"
                          value={isAppliedToAllPlacementIds}
                          onChange={() => setIsAppliedToAllPlacementIds(!isAppliedToAllPlacementIds)}
                          checked={isAppliedToAllPlacementIds}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              )}
              {isAppliedToAllPlacementIds && (
                <span className="text-warning font-weight-bold" style={{ fontSize: 13 }}><span role="img" aria-label="warning-icon">⚠️</span> In order for the ad(s) to be visible across all the placements, the necessary placement targeting rules should be set.</span>
              )}
            </>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          {(showFixedPositionsUpdateButton() && data.type.value && (formData?.fixedPositionProviders?.length > 0 || formData?.fixedPositionAds?.length > 0))
            ? <Button color="danger" onClick={handleReset}>Reset Fixed Position</Button>
            : null
          }
          <Button color="primary" onClick={handleHide}>Close</Button>
          {showFixedPositionsUpdateButton() &&
            <Button type='submit' color='success'>Submit</Button>
          }
        </Modal.Footer>
      </Form>
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={errorModalHeading}
        modaltext={failureModalText}
      />
    </Modal>
  )
}

export default SmartSuggestionsFixedPositionModal
import { host } from "./index";
import { retrieveFromLocalStorage } from "../localStorageUtil";
import { maxDefaultLimit } from "../../config/UserConfig";

// let host = "http://localhost:1323"
export let fetchMovieGifs = async (params) => {

  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/movieGifs?` + new URLSearchParams(params);
  let resStatus = "";
  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      resStatus = res;
    });
  return resStatus;
};
export let getMovieGifsLocales = async () => {
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/movieGifs/locales`;
  let resStatus = "";
  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      resStatus = res;
    });
  return resStatus;
}


export let movieGifDetail = async (id) => {
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/movieGifs/${id}`;
  let resStatus = "";
  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      resStatus = res;
    });
  return resStatus;
}

export let updateMovieGifs = async (requestBody , id) => {
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/movieGifs/${id}`;
  let resStatus = "";
  await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body:JSON.stringify(requestBody)
  })
    .then((response) => response.json())
    .then((res) => {
      resStatus = res;
    });
  return resStatus;
}

export let updateMovieGifsStatus = async (params, selectedGifs) => {
  // create a generic function to set the attributes of a set of gifs
  let token = retrieveFromLocalStorage("token");
  let url = `${host}/v4/internal/movieGifs/status?` + new URLSearchParams(params);
  let resStatus = "";
  const requestBody = {
    ids: selectedGifs
  }
  await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody)
  })
    .then((response) => response.json())
    .then((res) => {
      resStatus = res;
    });

  return resStatus
}


export let processDateAndStatus = (item) => {
  if (item.publishedAt !== null) {
    item.publishedAt = new Date(item.publishedAt).toLocaleString();
    if (item.publishedAt === "Invalid Date") {
      item.publishedAt = null;
    }
  }
  if (item.createdAt !== null) {
    item.createdAt = new Date(item.createdAt).toLocaleString();
    if (item.createdAt === "Invalid Date") {
      item.createdAt = null;
    }
  }
  if (item.updatedAt !== null) {
    item.updatedAt = new Date(item.updatedAt).toLocaleString();
    if (item.updatedAt === "Invalid Date") {
      item.updatedAt = null;
    }
  }
  if (item.deactivatedAt !== null) {
    item.deactivatedAt = new Date(item.deactivatedAt).toLocaleString();
    if (item.deactivatedAt === "Invalid Date") {
      item.deactivatedAt = null;
    }
  }
  if (
    (item.publishedAt === null || item.publishedAt === "Invalid Date") &&
    item.deactivatedAt === null
  ) {
    item.status = "Unpublished";
  }
  if (
    item.publishedAt !== null &&
    item.publishedAt !== "Invalid Date" &&
    item.deactivatedAt === null
  ) {
    item.status = "Published";
  }
  if (item.publishedAt !== null && item.deactivatedAt !== null) {
    item.status = "Deactivated";
  }
};

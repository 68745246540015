import { retrieveFromLocalStorage } from "../localStorageUtil";
import { host } from "./index";

// List all tasks
export const fetchTasks = async () => {
    const url = `${host}/v4/internal/tasks`; // Correct the URL to include "/tasks"
    const token = retrieveFromLocalStorage("token");

    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });

        if (response.ok) {
            return await response.json();
        } else {
            // Handle error cases here
            throw new Error(`Failed to fetch tasks (${response.status})`);
        }
    } catch (error) {
        // Handle network errors or other exceptions
        throw error;
    }
}
// Fetch a single task by id
export const fetchSingletask = async (id) => {
    const url = `${host}/v4/internal/tasks/${id}`;
    const token = retrieveFromLocalStorage("token");
  
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      });
  
      if (response.ok) {
        // If the response is OK (status 200), parse and return the task data
        return await response.json();
      } else if (response.status === 404) {
        // If the response status is 404, it means the task was not found
        throw new Error("task not found");
      } else {
        // Handle other error cases
        throw new Error(`Failed to fetch task (${response.status})`);
      }
    } catch (error) {
      // Handle network errors or other exceptions
      throw error;
    }
  };

// Create a task
export const createtask = async (newtask) => {
    const url = `${host}/v4/internal/tasks`; // Correct the URL to include "/tasks"
    const token = retrieveFromLocalStorage("token");

    try {
        const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify(newtask), // Pass the new task data
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        });

        if (response.ok) {
            return await response.json();
        } else {
            // Handle error cases here
            throw new Error(`Failed to create task (${response.status})`);
        }
    } catch (error) {
        // Handle network errors or other exceptions
        throw error;
    }
}

// Update a task
export const updatetask = async (id, updatedtask) => {
    const url = `${host}/v4/internal/tasks/${id}`;
    const token = retrieveFromLocalStorage("token");

    try {
        const response = await fetch(url, {
            method: "PUT", // Use PUT method for updating
            body: JSON.stringify(updatedtask),
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        });

        if (response.ok) {
            return await response.json();
        } else {
            // Handle error cases here
            throw new Error(`Failed to update task (${response.status})`);
        }
    } catch (error) {
        // Handle network errors or other exceptions
        throw error;
    }
}
// Deactivate a task
export const deactivatetask = async (id) => {
  const url = `${host}/v4/internal/tasks/${id}/deactivate`;
  const token = retrieveFromLocalStorage("token");

  try {
    const response = await fetch(url, {
      method: "PUT", // Use PUT method for deactivation
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    if (response.ok) {
      return {
        message: "Task Deactivated",
        status: "success",
      };
    } else {
      // Handle error cases here
      throw new Error(`Failed to deactivate task (${response.status})`);
    }
  } catch (error) {
    // Handle network errors or other exceptions
    throw error;
  }
};


export const Activatetask = async (id) => {
const url = `${host}/v4/internal/tasks/${id}/activate`;
const token = retrieveFromLocalStorage("token");

try {
    const response = await fetch(url, {
    method: "PUT", // Use PUT method for deactivation
    headers: {
        Authorization: "Bearer " + token,
    },
    });

    if (response.ok) {
    return await response.json();
    } else {
    // Handle error cases here
    throw new Error(`Failed to activate task (${response.status})`);
    }
} catch (error) {
    // Handle network errors or other exceptions
    throw error;
}
};

//Publish a task
export const publishtask = async (id) => {
    const url = `${host}/v4/internal/tasks/${id}/publish`;
    const token = retrieveFromLocalStorage("token");

    try {
        let response = await fetch(url, {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + token,
          },
        });
    
        return await response.json();
      } catch (error) {
        return;
      }
  };

// Fetch a single task_event id
export const fetchTasksEventbyId = async (id) => {
  const url = `${host}/v4/internal/tasks/event/${id}`;
  const token = retrieveFromLocalStorage("token");

  try {
      const response = await fetch(url, {
          method: "GET",
          headers: {
              Authorization: "Bearer " + token,
          },
      });

      if (response.ok) {
          return await response.json();
      } else {
          // Handle error cases here
          throw new Error(`Failed to fetch tasks (${response.status})`);
      }
  } catch (error) {
      // Handle network errors or other exceptions
      throw error;
  }
}

// List all tasksEvents for a task_id
export const fetchTasksEvent = async (taskId) => {
  const url = `${host}/v4/internal/tasks/events/${taskId}`; // Correct the URL to include "/tasks"
  const token = retrieveFromLocalStorage("token");

  try {
      const response = await fetch(url, {
          method: "GET",
          headers: {
              Authorization: "Bearer " + token,
          },
      });

      if (response.ok) {
          return await response.json();
      } else {
          // Handle error cases here
          throw new Error(`Failed to fetch tasks (${response.status})`);
      }
  } catch (error) {
      // Handle network errors or other exceptions
      throw error;
  }
}

export const AddTaskEvents = async (newtaskEvent) => {
  const url = `${host}/v4/internal/tasks/event`; // Correct the URL to include "/tasks"
  const token = retrieveFromLocalStorage("token");

  try {
    const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(newtaskEvent), // Pass the new task data
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
        },
    });

    if (response.ok) {
        return await response.json();
    } else {
        // Handle error cases here
        throw new Error(`Failed to create task_event (${response.status})`);
    }
} catch (error) {
    // Handle network errors or other exceptions
    throw error;
}
}

// List all tasksEvents
export const updateTasksEvent = async (id,newtaskEvent) => {
  const url = `${host}/v4/internal/tasks/event/${id}`;
  const token = retrieveFromLocalStorage("token");

  try {
    const response = await fetch(url, {
        method: "PUT", // Use PUT method for updating
        body: JSON.stringify(newtaskEvent),
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
        },
    });

    if (response.ok) {
        return await response.json();
    } else {
        // Handle error cases here
        throw new Error(`Failed to update task (${response.status})`);
    }
} catch (error) {
    // Handle network errors or other exceptions
    throw error;
}
}
import {retrieveFromLocalStorage} from "../utilities/localStorageUtil";
import {permissions} from "../utilities/commonUtil";

const allowedPermissions = retrieveFromLocalStorage("userPermissions");

export const imageFileSizeExceedError = `Image file is too large. Please upload file size Max: 2MB`;

export const compressibleImageTypes = [
    "image/webp",
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
  ];

export const maxImageUploadSize = 2097152;

export let appResourcesResponses = {
    addedSuccessfully : "AppResources added successfully",
    updatedSuccessfully : "AppResources updated successfully",
}

export let showAddAppResourcesButton = () => {
    return allowedPermissions.includes(permissions.appResourceCreate);
};
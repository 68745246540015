import React, { Component } from "react";
import { getEmojiStickers } from "../../../utilities/apiUtils/emojiStickers";
import { Link } from "react-router-dom";
import {
  errorDescription,
  error,
  permissions,
  redirectOnTokenExpiry,
} from "../../../utilities/commonUtil";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import Search from "../../../components/Search";
import EmojiSticker from "./components/EmojiSticker";
import ResponseModal from "../ResponseModal";
import { Col, Container, Row, Button } from "reactstrap";
import ConfirmationModal from "../ConfirmationModal";
import Loading from "../../../components/Loading";
import { Config } from "../../../config/index";
import UncontrolledButtonDropdown from "reactstrap/lib/UncontrolledButtonDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import Select from "react-select";
import Spinner from "reactstrap/lib/Spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import { capitalize } from "@material-ui/core";

class AllEmojiStickers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      hasResponseError: false,
      errorText: "",
      confirmationModalIsOpen: false,
      failureModalDisplay: false,
      failureModalText: "",
      successModalDisplay: false,
      successModalText: "",
      action: "",
      id: null,
      allEmojiStickers: [],
      searchResults: [],
      allowedPermissions: retrieveFromLocalStorage("userPermissions"),
      searchString: "",
      emoji: "",
      searchBy: Config.SEARCH_BY_NAME,
      sortBy: Config.UpdatedAtDescending,
      page: 0,
      limit: 20,
      hasMoreEmojiStickers: true,
    };
  }
  componentDidMount() {
    this.getAllEmojiStickers();
  }

  getAllEmojiStickers = async () => {
    try {
      let params = {
        searchString:
          this.state.searchBy === "name" ? this.state.searchString : "",
        id: this.state.searchBy === "id" ? this.state.searchString : "",
        emoji: this.state.searchBy === "emoji" ? this.state.searchString : "" ,
        sortBy: this.state.sortBy,
        page: this.state.page,
        limit: this.state.limit,
      };
      let res = await getEmojiStickers(params);
      if (res.errorCode === undefined) {
        this.setState(
          {
            hasResponseError: false,
            allEmojiStickers: res.emojis || [],
          }
        );
      } else {
        if (
          res.errorCode === error.tokenExpired ||
          res.errorCode === error.invalidAccessToken
        )
          redirectOnTokenExpiry();
        this.setState({
          hasResponseError: true,
          errorText: errorDescription(res.errorCode),
        });
      }
      this.setState({ loading: false });
    } catch (err) {
      this.setState({
        loading: false,
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  handleChange = (event) => {
    let value = event.target.value;
    this.setState({ searchString: value });
    if (!event.target.value.length) {
      this.search();
    }
    event.preventDefault();
  };

  onKeyDown = (event) => {
    if (event.keyCode === Config.KEYPRESS_DOWN) {
      this.search();
    }
  };

  search = (searchBy) => {
    this.setState(
      {
        loading: true,
        page: 0,
        hasMoreEmojiStickers: true,
        searchBy: searchBy || this.state.searchBy,
      },
      async () => {
        this.getAllEmojiStickers();
      }
    );
  };

  showAddBigMojiButton = () => {
    return this.state.allowedPermissions.includes(permissions.addEmojiStickers);
  };

  handleResponse = (response) => {
    if (!response.errorCode) {
      let text = response.success;
      this.setState({ successModalDisplay: true, successModalText: text });
      return;
    }
    let text = errorDescription(response.errorCode);
    this.setState({ failureModalDisplay: true, failureModalText: text });
  };

  failureModalClose = () => {
    this.setState({ failureModalDisplay: false });
  };

  successModelClose = () => {
    this.setState({ successModalDisplay: false });
    this.getAllStickerPacks();
  };

  setConfirmationModalState = (id, btnName) => {
    this.setState({ id: id, action: btnName, confirmationModalIsOpen: true });
  };

  handleOrderChange = (sortBy) => {
    this.setState({ sortBy: sortBy.value }, () => this.search());
  };

  fetchMoreData = () => {
    // to avoid repetition of same theme
    if (this.state.searchBy === "id") {
      return;
    }
    this.setState({ page: this.state.page + 1 });
    this.setState(
      { hasMoreEmojiStickers: true },
      async () => {
        let params = {
          searchString:
            this.state.searchBy === "name" ? this.state.searchString : "",
          id: this.state.searchBy === "id" ? this.state.searchString : "",
          emoji: this.state.searchBy === "emoji" ? this.state.searchString : "" ,
          sortBy: this.state.sortBy,
          page: this.state.page,
          limit: this.state.limit,
        };

        let res = await getEmojiStickers(params);

        if (res.emojis && res.emojis.length > 0) {
          let allEmojiStickers = this.state.allEmojiStickers.concat(res.emojis);
          this.setState({ allEmojiStickers: allEmojiStickers });
        } else {
          this.setState({ hasMoreEmojiStickers: false, loading: false });
        }
      }
    );
  };

  render() {
    return (
      <Container fluid className="p-0">
        <Row>
          <h1 className="mb-4 mt-2 w-50 pl-3">BigMojis</h1>
          {this.showAddBigMojiButton() ? (
            <Link
              className="d-block ml-auto mb-4 pr-3 text-decoration-none"
              to={{
                pathname: `/stickers/emoji-stickers/add`,
              }}
            >
              <Button color="primary" className="ml-auto d-flex">
                <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                  Add BigMoji
                </p>
              </Button>
            </Link>
          ) : null}
        </Row>
        <Row>
          <Col md={3} sm={12}>
            <Search
              placeholder="Search"
              value={this.state.searchString}
              onChange={this.handleChange}
              onKeyDown={this.onKeyDown}
            ></Search>
          </Col>
          <Col md={3} sm={12}>
            <UncontrolledButtonDropdown className="mr-1 mb-1">
              <DropdownToggle caret color="success">
                {"Search By " + capitalize(this.state.searchBy) + " "}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    this.search("id");
                  }}
                >
                  ID
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    this.search("name");
                  }}
                >
                  Name
                </DropdownItem>
                <DropdownItem
                onClick={() => {
                    this.search("emoji");
                  }}
                >
                  Emoji
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </Col>
          <Col md={3} sm={12}></Col>
          <Col md={3} sm={12}>
            <Select
              className="react-select-container mb-4"
              classNamePrefix="react-select"
              placeholder="Sort By"
              onChange={this.handleOrderChange}
              options={[
                { value: Config.UpdatedAtDescending, label: "Updated At \u2193" },
                { value: Config.UpdatedAtAscending, label: "Updated At \u2191" },
              ]}
            />
          </Col>
        </Row>
        {this.state.loading ? (
          <Loading />
        ) : this.state.hasResponseError ? (
          <ResponseModal
            show={this.state.hasResponseError}
            onHide={() => {
              this.setState({ hasResponseError: false });
            }}
            modalheading={"Error"}
            modaltext={this.state.errorText}
          />
        ) : (
          <React.Fragment>
            <InfiniteScroll
              className="infinite-scroll"
              dataLength={this.state.allEmojiStickers.length}
              next={this.fetchMoreData}
              hasMore={this.state.hasMoreEmojiStickers}
              loader={
                !this.state.loading &&
                this.state.searchBy !== "id" && (
                  <Spinner color="info" className=" d-flex mx-auto" />
                )
              }
            >
              <Row style={{ flexWrap: "wrap" }}>
                {this.state.allEmojiStickers &&
                  this.state.allEmojiStickers.length > 0 ? (
                  this.state.allEmojiStickers.map((element, index) => {
                    return (
                      <Col md={3} xl={3} lg={3}>
                        <EmojiSticker
                          key={index}
                          emoji={element}
                        ></EmojiSticker>
                      </Col>
                    );
                  })
                ) : (
                  <h1 className=" d-block mx-auto h2 mt-5">No Emojis</h1>
                )}
              </Row>
            </InfiniteScroll>
            <ResponseModal
              show={this.state.successModalDisplay}
              onHide={this.successModelClose}
              modalheading={"Success"}
              modaltext={this.state.successModalText}
            />
            <ResponseModal
              show={this.state.failureModalDisplay}
              onHide={this.failureModalClose}
              modalheading={"Error"}
              modaltext={this.state.failureModalText}
            />
            <ConfirmationModal
              show={this.state.confirmationModalIsOpen}
              modaltext={this.state.action}
              onHide={() => {
                this.setState({ confirmationModalIsOpen: false });
              }}
              onConfirmationTrue={() => {
                this.setState({ confirmationModalIsOpen: false });
                this.performAction();
              }}
            />
          </React.Fragment>
        )}
      </Container>
    );
  }
}

export default AllEmojiStickers;
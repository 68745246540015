import React, { useState, useEffect } from "react";
import { Card, CardBody, Container } from "reactstrap";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
    Row,
    Col,
} from "reactstrap";
import { useParams } from 'react-router-dom';
import { error, errorDescription } from "../../../utilities/commonUtil";
import { GetAIPromptTemplatesDetails } from "../../../utilities/apiUtils/genAIDashboard";
import PromptTemplateVersions from "./PromptTemplateVersions";
import { showEditPromptTemplateButton } from "../../../config/configGenAIDashboard";
import ResponseModal from "../ResponseModal";
import { errorModalHeading } from "../../../config/config";
const TemplateDetails = () => {
    const { id } = useParams();
    const [failureModalDisplay, setFailureModalDisplay] = useState(false);
    const [failureModalText, setFailureModalText] = useState("");
    const [data, setData] = useState({
        id: 0,
        identifier: "",
        description: "",
        errorMessagesStr: "",
        errorMessages: "",
        currentVersion: {},
        createdAt: "",
        updatedAt: "",
        publishedAt: "",
        deactivatedAt: ""
    });
    useEffect(() => {
        if (id) {
            fetchPromptTemplatesDetails(id);
        }
    }, [id]);
    const fetchPromptTemplatesDetails = async (id) => {
        try {
            let response = await GetAIPromptTemplatesDetails(id)
            if (!response) {
                return;
            }
            if (response) {
                setData({
                    id: response.id,
                    identifier: response.identifier,
                    description: response.description,
                    errorMessagesStr: JSON.parse(response.errorMessagesStr),
                    errorMessages: response.errorMessages,
                    currentVersion: response.currentVersion,
                    createdAt: response.createdAt,
                    updatedAt: response.updatedAt,
                    publishedAt: response.publishedAt,
                    deactivatedAt: response.deactivatedAt
                })
            } else {
                showErrorModal(errorDescription(response.errorCode));
            }
        } catch (err) {
            showErrorModal(errorDescription(error.unexpectedError));
        }
    }

    let showErrorModal = (text) => {
        setFailureModalText(text);
        setFailureModalDisplay(true);
    };

    let failureModalClose = () => {
        setFailureModalDisplay(false);
    };

    return (
        <>
            <h1>Prompt Template</h1>
            <Card>
                <CardBody>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Identifier:
                        </Col>
                        <Col md={6} sm={12}>{data.identifier ?? "N/A"}</Col>
                    </Row>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Description:
                        </Col>
                        <Col md={6} sm={12}>{data.description ? data.description : "N/A"}</Col>
                    </Row>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Error Messages:
                        </Col>
                        {data.errorMessagesStr?.length > 0 && data.errorMessagesStr[0] !== '' ?
                            <Col md={6} sm={12}>{data.errorMessagesStr.map((d, index) => (
                                <div key={index}>
                                    {index + 1}. {d}
                                </div>
                            )
                            )}
                            </Col> : <Col>N/A</Col>}
                    </Row>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Created at:
                        </Col>
                        <Col md={6} sm={12}>{data.createdAt ?? "N/A"}</Col>
                    </Row>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Updated at:
                        </Col>
                        <Col md={6} sm={12}>{data.updatedAt ?? "N/A"}</Col>
                    </Row>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Published at:
                        </Col>
                        <Col md={6} sm={12}>{data.publishedAt ?? "N/A"}</Col>
                    </Row>
                    <Row className="m-3 font-size-lg">
                        <Col md={3} sm={12} className="font-weight-bolder">
                            Deactivated at:
                        </Col>
                        <Col md={6} sm={12}>{data.deactivatedAt ?? "N/A"}</Col>
                    </Row>
                    {
                        showEditPromptTemplateButton() ? (
                            <Link
                                to={{
                                    pathname: `/gen-ai/prompt-templates/edit/${id}`
                                }}
                            >
                                <center>
                                    <Button className="btn-primary">Edit</Button></center></Link>) : null
                    }
                    <ResponseModal
                        show={failureModalDisplay}
                        onHide={failureModalClose}
                        modalheading={errorModalHeading}
                        modaltext={failureModalText}
                    />
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <PromptTemplateVersions promptTemplateId={id} />
                </CardBody>
            </Card>
        </>
    )
}
const PromptTemplateDetails = () => {
    return (
        <Container fluid className="p-0">
            <TemplateDetails />
        </Container>
    )
}
export default PromptTemplateDetails;
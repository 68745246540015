import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { errorDescription, error } from "../../../utilities/commonUtil";
import {
  createCarousel,
  getLanguages,
} from "../../../utilities/apiUtils/carousel.js";
import { getBrandCampaigns } from "../../../utilities/apiUtils/brandCampaign";

const CreateCarouselForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    type: "generic",
    brandCampaignID: "",
    aspectRatio: 2.4,
    placements: ["app", "keyboard"],
    contentType: ["sticker"],
    minAppVersion: 1, // Added minAppVersion
    maxAppVersion: 0, // Added maxAppVersion
    background: {
      gradient: {
        startColor: "",
        centerColor: "",
        endColor: "",
      },
      darkThemeGradient: {
        darkThemeStartColor: "",
        darkThemeCenterColor: "",
        darkThemeEndColor: "",
      },
      imageFile: "",
      darkThemeImageFile: "",
      deeplink: {
        app: "",
        keyboard: "",
        hamburgerMenu: "",
        settings: "",
      },
      trackers: [{ type: "", url: "" }],
      translations: [
        {
          languageCode: null,
          imageFilename: null,
          imageFile: null,
          darkThemeImageFilename: null,
          darkThemeImageFile: null,
        },
      ],
    },
  });

  const history = useHistory();
  const [createdCarouselId, setCreatedCarouselId] = useState(null);
  const [addBrandCampaignIDOption, setAddBrandCampaignIDOption] = useState([]);
  const [languagesOptions, setLanguagesOptions] = useState([]);
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });

  const addTracker = () => {
    setFormData({
      ...formData,
      background: {
        ...formData.background,
        trackers: [
          ...formData.background.trackers,
          {
            type: "",
            url: "",
          },
        ],
      },
    });
  };

  const handleTrackerTypeChange = (index, value) => {
    handleTrackerChange(index, "type", value);
  };

  const handleTranslationChange = (index, field, value) => {
    const updatedTranslations = [...formData.background.translations];
    updatedTranslations[index][field] = value;

    setFormData({
      ...formData,
      background: {
        ...formData.background,
        translations: updatedTranslations,
      },
    });
  };

  const handleTrackerImageFileChange = (index, field, file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      handleTranslationChange(index, field, event.target.result);
    };
    reader.readAsDataURL(file);
  };

  const addTranslation = () => {
    setFormData({
      ...formData,
      background: {
        ...formData.background,
        translations: [
          ...formData.background.translations,
          {
            languageCode: null,
            imageFilename: null,
            imageFile: null,
            darkThemeImageFilename: null,
            darkThemeImageFile: null,
          },
        ],
      },
    });
  };

  const placementOptions = [
    { value: "app", label: "App" },
    { value: "keyboard", label: "Keyboard" },
    { value: "settings", label: "Settings" },
    { value: "hamburgerMenu", label: "HamburgerMenu" }
  ];

  const contentTypeOptions = [
    { value: "sticker", label: "Sticker" },
    { value: "gif", label: "GIF" },
  ];

  const handleTypeChange = (selectedOption) => {
    setFormData({ ...formData, type: selectedOption.value });
  };

  const typeOptions = [
    { value: "generic", label: "Generic" },
    { value: "sotd", label: "SOTD" },
    { value: "full_image", label: "Full Image" },
    { value: "heads", label: "Heads" },
    { value: "permissions", label: "Permissions" },
  ];

  // Handle changes in tracker type and URL
  const handleTrackerChange = (index, field, value) => {
    const updatedTrackers = [...formData.background.trackers];
    updatedTrackers[index][field] = value;

    setFormData({
      ...formData,
      background: {
        ...formData.background,
        trackers: updatedTrackers,
      },
    });
  };

  const handleDeeplinkChange = (type, value) => {
    setFormData({
      ...formData,
      background: {
        ...formData.background,
        deeplink: {
          ...formData.background.deeplink,
          [type]: value,
        },
      },
    });
  };

  const setBrandCampaign = async () => {
    const brandCampaigns = await getBrandCampaigns();

    if (brandCampaigns?.length > 0) {
      const brandCampaignOptions = brandCampaigns.map((brandCampaign) => {
        return {
          value: brandCampaign.value,
          label: brandCampaign.label,
          target: {
            name: "brandCampaignID",
            value: {
              value: brandCampaign.value,
              label: brandCampaign.label,
            },
          },
        };
      });
      setAddBrandCampaignIDOption(brandCampaignOptions);
    }
  };

  const removeTracker = (indexToRemove) => {
    // Create a copy of the trackers array from the form data
    const updatedTrackers = [...formData.background.trackers];

    // Remove the tracker at the specified index
    updatedTrackers.splice(indexToRemove, 1);

    // Update the form data with the modified trackers array
    setFormData({
      ...formData,
      background: {
        ...formData.background,
        trackers: updatedTrackers,
      },
    });
  };

  const removeTranslation = (indexToRemove) => {
    const updatedTranslations = [...formData.background.translations];

    // Remove the translation entry at the specified index
    updatedTranslations.splice(indexToRemove, 1);

    // Update the state with the modified translations array
    setFormData({
      ...formData,
      background: {
        ...formData.background,
        translations: updatedTranslations,
      },
    });
  };

  const getLanguagesOptions = async () => {
    try {
      let response = await getLanguages();
      // remove All if present
      response = response?.filter((item) => item?.value !== "All");
      setLanguagesOptions(response);
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const removeBackgroundImage = (isDarkThemeImage) => {
    if (isDarkThemeImage) {
      // Remove dark theme image
      setFormData({
        ...formData,
        background: {
          ...formData.background,
          darkThemeImageFile: null,
        },
      });
    } else {
      // Remove regular background image
      setFormData({
        ...formData,
        background: {
          ...formData.background,
          imageFile: null,
        },
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Perform validation directly in the onChange event
    if (name === "aspectRatio" && parseFloat(value) < 0) {
      // Prevent updating the state if the value is negative
      return;
    }

    if (
      name === "startColor" ||
      name === "centerColor" ||
      name === "endColor" ||
      name === "darkThemeStartColor" ||
      name === "darkThemeCenterColor" ||
      name === "darkThemeEndColor"
    ) {
      setFormData({
        ...formData,
        background: {
          ...formData.background,
          gradient: {
            ...formData.background.gradient,
            [name]: value,
          },
          darkThemeGradient: {
            ...formData.background.darkThemeGradient,
            [name]: value,
          },
        },
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleDetailsChange = (event) => {
    const { name, value } = event.target;

    if (name === "brandCampaignID") {
      // Check if the change is for brandCampaignID
      setFormData((prevState) => ({
        ...prevState,
        brandCampaignID: value, // Update brandCampaignID in formData
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleContentTypeChange = (selectedOptions) => {
    const contentTypes = selectedOptions.map((option) => option.value);
    setFormData({ ...formData, contentType: contentTypes });
  };

  const handlePlacementChange = (selectedOptions) => {
    const placements = selectedOptions.map((option) => option.value);
    setFormData({ ...formData, placements: placements });
  };

  // Handle image file input change
  const handleImageFileChange = (e, isDarkTheme) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setFormData({
          ...formData,
          background: {
            ...formData.background,
            [isDarkTheme ? "darkThemeImageFile" : "imageFile"]:
              event.target.result,
          },
        });
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  useEffect(() => {
    setBrandCampaign();
    getLanguagesOptions();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const combinedData = {
      name: formData.name,
      description: formData.description,
      type: formData.type,
      brandCampaignID: formData.brandCampaignID,
      aspectRatio: formData.aspectRatio,
      placements: formData.placements,
      contentType: formData.contentType,
      background: {
        gradient: formData.background.gradient,
        darkThemeGradient: formData.background.darkThemeGradient,
        imageFilename: "",
        imageFile: formData.background.imageFile,
        darkThemeImageFilename: "",
        darkThemeImageFile: formData.background.darkThemeImageFile,
        deeplink: formData.background.deeplink, // Include deeplink here
        trackers: formData.background.trackers,
        translations: formData.background.translations,
      },
    };
    const hasNonEmptyTracker = formData.background.trackers.some((tracker) =>
      Object.values(tracker).some((value) => value !== "")
    );

    const hasNonNullTranslation = formData.background.translations.some(
      (translation) =>
        Object.values(translation).some((value) => value !== null)
    );

    if (formData.background.trackers.length > 0 && hasNonEmptyTracker) {
      combinedData.background.trackers = formData.background.trackers;
    } else {
      delete combinedData.background.trackers;
    }

    if (formData.background.translations.length > 0 && hasNonNullTranslation) {
      combinedData.background.translations = formData.background.translations;
    } else {
      delete combinedData.background.translations;
    }

    try {
      const carouselId = await createCarousel(combinedData);
      setCreatedCarouselId(carouselId); // Set the created carousel's ID in state
      if (formData.type !== "full_image") {
        history.push(`/cre/carousel/add-items/${carouselId}`);
      } else {
        history.push(`/cre/carousel-rules/${carouselId}`);
      }
    } catch (error) {
      // Handle errors here
      console.error("Failed to create carousel:", error);
    }
  };

  return (
    <Container className="carousel-form-container my-5">
      <h1 className="carousel-form-title mb-4">Create Carousel</h1>
      <Card className="carousel-form-card">
        <CardBody>
          <Form onSubmit={handleSubmit}>
            {/* General Information */}
            <Card className="mb-4">
              <h3>General Information</h3>
              <CardBody>
                <FormGroup className="mb-4">
                  <Label for="name">
                    Name <span className="text-danger">*</span>
                  </Label>{" "}
                  <Input
                    className="col-sm-4"
                    type="text"
                    name="name"
                    id="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
                <FormGroup className="mb-4">
                  <Label for="description">Description</Label>
                  <Input
                    type="text"
                    name="description"
                    id="description"
                    value={formData.description}
                    onChange={handleChange}
                    className="carousel-form-input"
                  />
                </FormGroup>
              </CardBody>
            </Card>

            {/* Type, Brand Campaign ID, Aspect Ratio, Placements, Content Type */}
            <h3>Type & Details</h3>
            <Card className="mb-4">
              <CardBody>
                <div className="row">
                  <div className="col-md-6">
                    <FormGroup className="mb-4">
                      <Label for="type">Type</Label>
                      <Select
                        value={typeOptions.find(
                          (option) => option.value === formData.type
                        )}
                        onChange={handleTypeChange}
                        options={typeOptions}
                      />
                    </FormGroup>
                  </div>

                  {/* Second Input - Brand Campaign ID */}
                  <div className="col-md-6">
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "16px" }}
                      >
                        Brand Campaign:
                      </Label>
                      <Select
                        id="brandCampaign"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        name="brandCampaign"
                        placeholder="Select BrandCampaign"
                        value={formData.brandCampaignID}
                        onChange={handleDetailsChange}
                        options={addBrandCampaignIDOption}
                      />
                    </FormGroup>
                  </div>

                  {/* Third Input - Aspect Ratio */}
                  <div className="col-md-6">
                    <FormGroup className="mb-4">
                      <Label for="aspectRatio">Aspect Ratio</Label>
                      <Input
                        type="number"
                        name="aspectRatio"
                        id="aspectRatio"
                        value={formData.aspectRatio}
                        onChange={handleChange}
                        className="carousel-form-input"
                      />
                    </FormGroup>
                  </div>

                  {/* Fourth Input - Placements */}
                  <div className="col-md-6">
                    <FormGroup className="mb-4">
                      <Label for="placements">Placements</Label>
                      <Select
                        isMulti
                        name="placements"
                        options={placementOptions}
                        value={placementOptions.filter((option) =>
                          formData.placements.includes(option.value)
                        )}
                        onChange={handlePlacementChange}
                      />
                    </FormGroup>
                  </div>

                  {/* Fifth Input - Content Type */}
                  <div className="col-md-6">
                    <FormGroup className="mb-4">
                      <Label for="contentType">Content Type</Label>
                      <Select
                        isMulti
                        name="contentType"
                        options={contentTypeOptions}
                        value={contentTypeOptions.filter((option) =>
                          formData.contentType.includes(option.value)
                        )}
                        onChange={handleContentTypeChange}
                      />
                    </FormGroup>
                  </div>
                  {/* Min App Version */}
                  <div className="col-md-6">
                    <FormGroup className="mb-4">
                      <Label for="minAppVersion">Min App Version</Label>
                      <Input
                        type="number"
                        name="minAppVersion"
                        id="minAppVersion"
                        value={formData.minAppVersion}
                        onChange={handleChange}
                        className="carousel-form-input"
                      />
                    </FormGroup>
                  </div>

                  {/* Max App Version */}
                  <div className="col-md-6">
                    <FormGroup className="mb-4">
                      <Label for="maxAppVersion">Max App Version</Label>
                      <Input
                        type="number"
                        name="maxAppVersion"
                        id="maxAppVersion"
                        value={formData.maxAppVersion}
                        onChange={handleChange}
                        className="carousel-form-input"
                      />
                    </FormGroup>
                  </div>
                </div>
              </CardBody>
            </Card>
            <h3>Background Colors</h3>
            <Card className="mb-4">
              <CardBody>
                <Row className="pb-3 pt-1" form>
                  {/* Regular Theme Colors */}
                  <Col md={4} xs={4}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "16px" }}
                      >
                        Start Color (Hex):{" "}
                        <span className="text-danger">*</span>{" "}
                        <div
                          style={{
                            backgroundColor:
                              formData.background.gradient.startColor,
                            width: "16px",
                            height: "16px",
                            display: "inline-block",
                            verticalAlign: "middle",
                            border: "1px solid #000",
                            borderRadius: "30%",
                          }}
                        >
                          {" "}
                        </div>
                      </Label>
                      <Input
                        type="text"
                        name="startColor"
                        id="startColor"
                        value={formData.background.gradient.startColor}
                        onChange={handleChange}
                        required
                        className="carousel-form-input"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4} xs={4}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "16px" }}
                      >
                        Center Color (Hex):{" "}
                        <span className="text-danger">*</span>{" "}
                        <div
                          style={{
                            backgroundColor:
                              formData.background.gradient.centerColor,
                            width: "16px",
                            height: "16px",
                            display: "inline-block",
                            verticalAlign: "middle",
                            border: "1px solid #000",
                            borderRadius: "30%",
                          }}
                        >
                          {" "}
                        </div>
                      </Label>
                      <Input
                        type="text"
                        name="centerColor"
                        id="centerColor"
                        value={formData.background.gradient.centerColor}
                        onChange={handleChange}
                        required
                        className="carousel-form-input"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4} xs={4}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "16px" }}
                      >
                        End Color (Hex): <span className="text-danger">*</span>{" "}
                        <div
                          style={{
                            backgroundColor:
                              formData.background.gradient.endColor,
                            width: "16px",
                            height: "16px",
                            display: "inline-block",
                            verticalAlign: "middle",
                            border: "1px solid #000",
                            borderRadius: "30%",
                          }}
                        >
                          {" "}
                        </div>
                      </Label>
                      <Input
                        type="text"
                        name="endColor"
                        id="endColor"
                        value={formData.background.gradient.endColor}
                        onChange={handleChange}
                        required
                        className="carousel-form-input"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="pb-3 pt-1" form>
                  {/* Dark Theme Colors */}
                  <Col md={4} xs={4}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "16px" }}
                      >
                        Dark Theme Start Color (Hex):{" "}
                        <span className="text-danger">*</span>{" "}
                        <div
                          style={{
                            backgroundColor:
                              formData.background.darkThemeGradient
                                .darkThemeStartColor,
                            width: "16px",
                            height: "16px",
                            display: "inline-block",
                            verticalAlign: "middle",
                            border: "1px solid #000",
                            borderRadius: "30%",
                          }}
                        >
                          {" "}
                        </div>
                      </Label>
                      <Input
                        type="text"
                        name="darkThemeStartColor"
                        id="darkThemeStartColor"
                        value={
                          formData.background.darkThemeGradient
                            .darkThemeStartColor
                        }
                        onChange={handleChange}
                        required
                        className="carousel-form-input"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4} xs={4}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "16px" }}
                      >
                        Dark Theme Center Color (Hex):{" "}
                        <span className="text-danger">*</span>{" "}
                        <div
                          style={{
                            backgroundColor:
                              formData.background.darkThemeGradient
                                .darkThemeCenterColor,
                            width: "16px",
                            height: "16px",
                            display: "inline-block",
                            verticalAlign: "middle",
                            border: "1px solid #000",
                            borderRadius: "30%",
                          }}
                        >
                          {" "}
                        </div>
                      </Label>
                      <Input
                        type="text"
                        name="darkThemeCenterColor"
                        id="darkThemeCenterColor"
                        value={
                          formData.background.darkThemeGradient
                            .darkThemeCenterColor
                        }
                        onChange={handleChange}
                        required
                        className="carousel-form-input"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4} xs={4}>
                    <FormGroup>
                      <Label
                        className="font-weight-bolder mb-2"
                        style={{ fontSize: "16px" }}
                      >
                        Dark Theme End Color (Hex):{" "}
                        <span className="text-danger">*</span>{" "}
                        <div
                          style={{
                            backgroundColor:
                              formData.background.darkThemeGradient
                                .darkThemeEndColor,
                            width: "16px",
                            height: "16px",
                            display: "inline-block",
                            verticalAlign: "middle",
                            border: "1px solid #000",
                            borderRadius: "30%",
                          }}
                        >
                          {" "}
                        </div>
                      </Label>
                      <Input
                        type="text"
                        name="darkThemeEndColor"
                        id="darkThemeEndColor"
                        value={
                          formData.background.darkThemeGradient
                            .darkThemeEndColor
                        }
                        onChange={handleChange}
                        required
                        className="carousel-form-input"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            {/* Background Images */}
            <h3>Background Images</h3>
            <Card className="mb-4">
              <CardBody>
                <div className="row">
                  {/* First Input - Background Image File */}
                  <div className="col-md-6">
                    <FormGroup className="mb-4">
                      <Label for="backgroundImageFile">
                        Background Image File
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="file"
                        name="backgroundImageFile"
                        id="backgroundImageFile"
                        onChange={(e) => handleImageFileChange(e, false)} // Pass `false` to indicate it's not a dark theme image
                        className="carousel-form-input"
                        required
                      />
                      {formData.background.imageFile && (
                        <div className="carousel-form-image-preview mt-3">
                          <img
                            src={formData.background.imageFile}
                            alt="Selected File"
                            className="carousel-form-preview-image img-thumbnail"
                            style={{ maxWidth: "150px" }}
                          />
                        </div>
                      )}
                      {/* Remove Button for Background Image */}
                      {formData.background.imageFile && (
                        <Button
                          color="danger"
                          size="sm"
                          onClick={() => removeBackgroundImage(false)} // Pass `false` to indicate it's not a dark theme image
                          className="mt-2"
                        >
                          Remove Background Image
                        </Button>
                      )}
                    </FormGroup>
                  </div>
                  {/* Second Input - Dark Theme Image File */}
                  <div className="col-md-6">
                    <FormGroup className="mb-4">
                      <Label for="darkThemeImageFile">
                        Dark Theme Image File
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="file"
                        name="darkThemeImageFile"
                        id="darkThemeImageFile"
                        onChange={(e) => handleImageFileChange(e, true)} // Pass `true` to indicate it's a dark theme image
                        className="carousel-form-input"
                        required
                      />
                      {formData.background.darkThemeImageFile && (
                        <div className="carousel-form-image-preview mt-3">
                          <img
                            src={formData.background.darkThemeImageFile}
                            alt="Selected File"
                            className="carousel-form-preview-image img-thumbnail"
                            style={{ maxWidth: "150px" }}
                          />
                        </div>
                      )}
                      {/* Remove Button for Dark Theme Image */}
                      {formData.background.darkThemeImageFile && (
                        <Button
                          color="danger"
                          size="sm"
                          onClick={() => removeBackgroundImage(true)} // Pass `true` to indicate it's a dark theme image
                          className="mt-2"
                        >
                          Remove Dark Theme Image
                        </Button>
                      )}
                    </FormGroup>
                  </div>
                </div>
              </CardBody>
            </Card>

            {/* Deeplinks */}
            <h3>Deeplinks</h3>
            <Card className="mb-4">
              <CardBody>
                <div className="row">
                  {/* First Input - App Deeplink */}
                  <div className="col-md-6">
                    <FormGroup className="mb-4">
                      <Label for="appDeeplink">
                        App Deeplink<span className="text-danger">*</span>
                      </Label>{" "}
                      <Input
                        type="text"
                        name="appDeeplink"
                        value={formData.background.deeplink.app}
                        onChange={(e) =>
                          handleDeeplinkChange("app", e.target.value)
                        }
                        className="carousel-form-input form-control-lg" // Use form-control-lg for larger size
                        required
                      />
                    </FormGroup>
                  </div>

                  {/* Second Input - Keyboard Deeplink */}
                  <div className="col-md-6">
                    <FormGroup className="mb-4">
                      <Label for="keyboardDeeplink">
                        Keyboard Deeplink<span className="text-danger">*</span>
                      </Label>{" "}
                      <Input
                        type="text"
                        name="keyboardDeeplink"
                        value={formData.background.deeplink.keyboard}
                        onChange={(e) =>
                          handleDeeplinkChange("keyboard", e.target.value)
                        }
                        className="carousel-form-input form-control-lg" // Use form-control-lg for larger size
                        required
                      />
                    </FormGroup>
                  </div>
                </div>
                <div className="row">
                  {/* Third Input - Hamburger Deeplink */}
                  <div className="col-md-6">
                    <FormGroup className="mb-4">
                      <Label for="hanbergurMenuDeeplink">
                        Hamburger Menu Deeplink
                      </Label>
                      <Input
                        type="text"
                        name="deeplink.hamburgerMenu"
                        id="hanbergurMenuDeeplink"
                        value={formData.background.deeplink.hamburgerMenu}
                        onChange={(e) =>
                          handleDeeplinkChange("hamburgerMenu", e.target.value)
                        }
                        className="carousel-form-input form-control-lg" // Use form-control-lg for larger size
                      />
                    </FormGroup>
                  </div>

                  {/* Fourth Input - Settings Deeplink */}
                  <div className="col-md-6">
                    <FormGroup className="mb-4">
                      <Label for="settingsDeeplink">Settings Deeplink</Label>
                      <Input
                        type="text"
                        name="deeplink.settings"
                        id="settingsDeeplink"
                        value={formData.background.deeplink.settings}
                        onChange={(e) =>
                          handleDeeplinkChange("settings", e.target.value)
                        }
                        className="carousel-form-input form-control-lg" // Use form-control-lg for larger size
                      />
                    </FormGroup>
                  </div>
                </div>
              </CardBody>
            </Card>

            {/* Trackers */}
            <h3>Trackers</h3>
            <Card className="mb-4">
              <CardBody>
                {formData.background.trackers.map((tracker, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-between align-items-center mb-3"
                  >
                    <Row form className="w-100">
                      <Col md={5}>
                        <FormGroup className="mb-md-0">
                          <Label>Tracker Type</Label>{" "}
                          <span className="text-danger">*</span>{" "}
                          <Input
                            type="select"
                            value={tracker.type}
                            onChange={(e) =>
                              handleTrackerTypeChange(index, e.target.value)
                            }
                            required
                          >
                            <option value="">Select Tracker Type</option>
                            <option value="click">Click</option>
                            <option value="impression">Impression</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={5}>
                        <FormGroup className="mb-md-0">
                          <Label>Tracker URL</Label>{" "}
                          <span className="text-danger">*</span>{" "}
                          <Input
                            type="text"
                            placeholder="Tracker URL"
                            value={tracker.url}
                            onChange={(e) =>
                              handleTrackerChange(index, "url", e.target.value)
                            }
                            required
                            style={{ width: "100%" }} // Adjust width here if needed
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        md={2}
                        className="text-end d-flex align-items-center justify-content-end"
                      >
                        {index === formData.background.trackers.length - 1 && (
                          <Button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => removeTracker(index)}
                            aria-label="Remove"
                            style={{
                              fontSize: "1rem",
                              padding: "5px 10px",
                              marginLeft: "auto",
                            }} // Adjust size and margin
                          >
                            X
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </div>
                ))}
                <Button color="secondary" onClick={addTracker}>
                  Add Tracker
                </Button>
              </CardBody>
            </Card>

            {/* Translations */}
            <h3>Translations</h3>
            <Card className="mb-4">
              <CardBody>
                {[...Array(formData.background.translations.length)].map(
                  (_, index) => (
                    <div key={index}>
                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label>Language Code</Label>{" "}
                            <span className="text-danger">*</span>{" "}
                            <Select
                              options={languagesOptions} // Pass your language options array here
                              value={
                                languagesOptions.find(
                                  (option) =>
                                    option.value ===
                                    formData.background.translations[index]
                                      .languageCode
                                ) || null
                              }
                              onChange={(selectedOption) =>
                                handleTranslationChange(
                                  index,
                                  "languageCode",
                                  selectedOption.value
                                )
                              }
                              required
                              placeholder="Select Language Code"
                            />
                          </FormGroup>
                        </Col>

                        <Col md={6}>
                          <FormGroup>
                            <Label>Image File</Label>
                            <Input
                              type="file"
                              onChange={(e) =>
                                handleTrackerImageFileChange(
                                  index,
                                  "imageFile",
                                  e.target.files[0]
                                )
                              }
                            />
                            {/* Display selected file */}
                            {formData.background.translations[index]
                              .imageFile && (
                                <div className="carousel-form-image-preview mt-3">
                                  <img
                                    src={
                                      formData.background.translations[index]
                                        .imageFile
                                    }
                                    alt="Selected File"
                                    className="carousel-form-preview-image img-thumbnail"
                                    style={{ maxWidth: "150px" }}
                                  />
                                </div>
                              )}
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label>Dark Theme Image File</Label>
                            <Input
                              type="file"
                              onChange={(e) =>
                                handleTrackerImageFileChange(
                                  index,
                                  "darkThemeImageFile",
                                  e.target.files[0]
                                )
                              }
                            />
                            {/* Display selected file */}
                            {formData.background.translations[index]
                              .darkThemeImageFile && (
                                <div className="carousel-form-image-preview mt-3">
                                  <img
                                    src={
                                      formData.background.translations[index]
                                        .darkThemeImageFile
                                    }
                                    alt="Selected File"
                                    className="carousel-form-preview-image img-thumbnail"
                                    style={{ maxWidth: "150px" }}
                                  />
                                </div>
                              )}
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <Button
                            type="button"
                            color="danger"
                            onClick={() => removeTranslation(index)}
                            aria-label="Remove Translation"
                            className="mt-4"
                          >
                            X
                          </Button>
                        </Col>
                      </Row>
                      {index !==
                        formData.background.translations.length - 1 && (
                          <hr />
                        )}{" "}
                    </div>
                  )
                )}
                <Button color="secondary" onClick={addTranslation}>
                  Add Translation
                </Button>
              </CardBody>
            </Card>

            <Button
              color="primary"
              type="submit"
              className="carousel-form-button"
            >
              Create Carousel
            </Button>
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
};

export default CreateCarouselForm;

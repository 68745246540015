import React, { useState, useEffect } from "react";
import { Card, CardBody, Container, Label } from "reactstrap";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import ResponseModal from "../ResponseModal";
import { failureModalHeading, successModalHeading } from "../../../config";
import ConfirmationModal from "../ConfirmationModal";
import { GetAITaskDropdownOptions, updatePromptTemplateVersion } from "../../../utilities/apiUtils/genAIDashboard";
import { formatTime, promptTemplateVersionResponses, showCreateTaskDropdownOption, showEditTaskDropdownOption } from "../../../config/configGenAIDashboard";
import { useParams } from 'react-router-dom';
import { error, errorDescription } from "../../../utilities/commonUtil";
import { taskDropdownOptionTableColumns } from "./dropdownOptionTableColumn";


const ExpandableRowsTable = ({ taskId }) => {
    const [failureModalText, setFailureModalText] = useState("");
    const [failureModalDisplay, setFailureModalDisplay] = useState(false);
    const [successModalText, setSuccessModalText] = useState("");
    const [successModalDisplay, setSuccessModalDisplay] = useState(false);
    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
    const [action, setAction] = useState("");
    const { id } = useParams();
    const [versionId, setVersionId] = useState();
    const [taskDropdownOptions, setTaskDropdownOptions] = useState([]);

    const [data, setData] = useState({
        identifier: "",
        displayName: {},
        priority: 0,
        attributes: {},
        createdAt: "",
        updatedAt: ""
    })

    const fetchTaskDropdownOptions = async (id) => {
        try {
            let response = await GetAITaskDropdownOptions(id)
            if (!response || response?.length === 0) {
                setTaskDropdownOptions([])
                return;
            }
            if (response.length > 0) {
                setTaskDropdownOptions(response);
            } else {
                showErrorModal(errorDescription(response.errorCode));
            }
        } catch (err) {
            showErrorModal(errorDescription(error.unexpectedError));
        }
    }

    useEffect(() => {
        if (id) {
            fetchTaskDropdownOptions(id);
        }
    }, [id]);


    let showErrorModal = (text) => {
        setFailureModalText(text);
        setFailureModalDisplay(true);
    };

    let failureModalClose = () => {
        setFailureModalDisplay(false);
    };

    let successModalClose = async () => {
        setSuccessModalDisplay(false);
        await fetchTaskDropdownOptions(id);
    };

    let showSuccessModal = (text) => {
        setSuccessModalText(text);
        setSuccessModalDisplay(true);
    };

    let updateStatus = async (update, id) => {
        try {
            const formPromptVersionData = {
                aiPromptTemplateId: data.aiPromptTemplateId,
                identifier: data.identifier,
                llmProvider: data.llmProvider,
                style: data.style,
                text: data.text,
                maxRetries: data.maxRetries,
                additionalDetails: data.additionalDetails,
                isCurrentVersion: update === "enableCurrentVersion" ? true : data.isCurrentVersion,
                isBetaVersion: update === "enableBetaVersion" ? true : data.isBetaVersion,
            };
            let response = await updatePromptTemplateVersion(formPromptVersionData, id);
            if (response.status === "aiPromptTemplateVersionEdited") {
                showSuccessModal(promptTemplateVersionResponses.updatedSuccessfully);
            } else {
                if (response.errorDescription) {
                    showErrorModal(response.errorDescription)
                }
            }
        } catch (err) {
            showErrorModal(errorDescription(error.unexpectedError));
        }
    };

    let setConfirmationModalState = (event, row) => {
        setAction(event);
        setConfirmationModalIsOpen(true);
        setVersionId(row.id);
        setData({
            aiPromptTemplateId: row.aiPromptTemplateId,
            identifier: row.identifier,
            llmProvider: row.llmProvider,
            style: row.style,
            text: row.text,
            maxRetries: row.maxRetries,
            additionalDetails: row.additionalDetails,
            isCurrentVersion: row.isCurrentVersion,
            isBetaVersion: row.isBetaVersion,
        })
    };

    const expandRow = {
        onlyOneExpanding: true,
        renderer: (row) => (
            <div>
                <ul>
                    <h5>Attributes</h5>
                    {row?.attributes !== null ? Object.keys(row?.attributes).map(key => (
                        <li key={key}><b>{key} : </b>{row?.attributes[key]}</li>
                    )) : "N/A"}
                </ul>
                <ul>
                    <li><b>Created At : </b>{formatTime(row.createdAt)}</li>
                    <li><b>Updated At : </b>{formatTime(row.updatedAt)}</li>
                </ul>
                <ul>
                    <h5>Display Name Translations</h5>
                    {row?.displayName !== null ? Object.keys(row?.displayName).map(key => (
                        key !== "default" && <li key={key}><b>{key} : </b>{row?.displayName[key]}</li>
                    )) : "N/A"}
                </ul>
                {showEditTaskDropdownOption() ? (
                    <>
                        <Link
                            to={{
                                pathname: `/gen-ai/tasks/${taskId}/dropdownOptions/${row.id}/edit`
                            }}
                        >
                            <Button className="mx-3"
                                onClick={(event) =>
                                    setConfirmationModalState(event.target.innerHTML, row)
                                }
                            >
                                Edit
                            </Button>
                        </Link>
                    </>
                ) : null
                }
            </div >
        ),
    };

    return (
        <Card>
            <CardBody>
                <ToolkitProvider keyField="id" data={taskDropdownOptions} columns={taskDropdownOptionTableColumns}>
                    {(props) => (
                        <div>
                            <BootstrapTable
                                {...props.baseProps}
                                bootstrap4
                                bordered={false}
                                keyField="id"
                                expandRow={expandRow}
                                pagination={paginationFactory({
                                    sizePerPage: 10,
                                    sizePerPageList: [5, 10, 25, 50],
                                })}
                                defaultSorted={[{
                                    dataField: 'id',
                                    order: 'asc'
                                }]}
                            />
                        </div>
                    )}
                </ToolkitProvider>
            </CardBody>
            <ResponseModal
                show={successModalDisplay}
                onHide={successModalClose}
                modalheading={successModalHeading}
                modaltext={successModalText}
            />
            <ResponseModal
                show={failureModalDisplay}
                onHide={failureModalClose}
                modalheading={failureModalHeading}
                modaltext={failureModalText}
            />
            <ConfirmationModal
                show={confirmationModalIsOpen}
                modaltext={action}
                onConfirmationTrue={() => {
                    setConfirmationModalIsOpen(false);
                    if (action === "Enable for Live Users") updateStatus("enableCurrentVersion", versionId);
                    else if (action === "Enable for Beta Users") updateStatus("enableBetaVersion", versionId);
                }}
                onHide={() => setConfirmationModalIsOpen(false)}
            />
        </Card>
    );
};

const TaskDropdownOptions = ({ taskId }) => {
    return (
        <Container fluid className="p-0">
            <div className="d-flex justify-content-between">
                <h1 className="h3">Task Dropdown Options</h1>
                {showCreateTaskDropdownOption() ? (
                    <div>
                        <Link
                            to={{
                                pathname: `/gen-ai/tasks/${taskId}/dropdownOptions/create`
                            }}
                        >
                            <Button
                                style={{ fontSize: "1rem" }}
                                className="mx-3 btn-success mb-3"
                            >
                                Create a New Dropdown Option
                            </Button>
                        </Link>
                    </div>
                ) : null}
            </div>
            <ExpandableRowsTable taskId={taskId} />
        </Container>
    )
}
export default TaskDropdownOptions;
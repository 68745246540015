import { retrieveFromLocalStorage } from "../localStorageUtil";
import { host } from "../apiUtils/index";
import { secureFetch } from "../secureFetch";

export let fetchCreativeTypes = async () => {
  let url = `${host}/v1/internal/adx/creativeTypes`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let deleteCreativeType = async (id) => {
  let url = `${host}/v1/internal/adx/creativeType/${id}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let createCreativeType = async (data) => {
  let url = `${host}/v1/internal/adx/creativeTypes`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let updateCreativeType = async (id, data) => {
  let url = `${host}/v1/internal/adx/creativeType/${id}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchVendors = async () => {
  let url = `${host}/v1/internal/adx/vendors`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let deleteVendor = async (id) => {
  let url = `${host}/v1/internal/adx/vendor/${id}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let createVendor = async (data) => {
  let url = `${host}/v1/internal/adx/vendors`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let updateVendor = async (id, data) => {
  let url = `${host}/v1/internal/adx/vendor/${id}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let activateVendor = async (id) => {
  let url = `${host}/v1/internal/adx/vendor/${id}/activate`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let deactivateVendor = async (id) => {
  let url = `${host}/v1/internal/adx/vendor/${id}/deactivate`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchPlacements = async () => {
  let url = `${host}/v1/internal/adx/placements`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let deletePlacement = async (id) => {
  let url = `${host}/v1/internal/adx/placement/${id}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let createPlacement = async (data) => {
  let url = `${host}/v1/internal/adx/placements`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let updatePlacement = async (id, data) => {
  let url = `${host}/v1/internal/adx/placement/${id}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let activatePlacement = async (id) => {
  let url = `${host}/v1/internal/adx/placement/${id}/activate`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let deactivatePlacement = async (id) => {
  let url = `${host}/v1/internal/adx/placement/${id}/deactivate`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchClients = async () => {
  let url = `https://internal-api.bobbleapp.me/v4/internal/clients`;
  try {
    var response = await secureFetch(url);
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchConfigs = async () => {
  let url = `${host}/v1/internal/adx/configs`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let deleteConfig = async (id) => {
  let url = `${host}/v1/internal/adx/config/${id}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let createConfig = async (data) => {
  let url = `${host}/v1/internal/adx/configs`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let updateConfig = async (id, data) => {
  let url = `${host}/v1/internal/adx/config/${id}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let activateConfig = async (id) => {
  let url = `${host}/v1/internal/adx/config/${id}/activate`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let deactivateConfig = async (id) => {
  let url = `${host}/v1/internal/adx/config/${id}/deactivate`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchPlacementVendors = async () => {
  let url = `${host}/v1/internal/adx/placementVendors`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let deletePlacementVendor = async (id) => {
  let url = `${host}/v1/internal/adx/placementVendor/${id}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let createPlacementVendor = async (data) => {
  let url = `${host}/v1/internal/adx/placementVendors`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let updatePlacementVendor = async (id, data) => {
  let url = `${host}/v1/internal/adx/placementVendor/${id}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchMixingStrategy = async () => {
  let url = `${host}/v1/internal/adx/mixingStrategy`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let deleteMixingStrategy = async (id) => {
  let url = `${host}/v1/internal/adx/mixingStrategy/${id}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let createMixingStrategy = async (data) => {
  let url = `${host}/v1/internal/adx/mixingStrategy`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let updateMixingStrategy = async (data) => {
  let url = `${host}/v1/internal/adx/mixingStrategy`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let updateSingleMixingStrategy = async (id, data) => {
  let url = `${host}/v1/internal/adx/mixingStrategy/${id}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchVendorsByPlacementId = async (id) => {
  let url = `${host}/v1/internal/adx/vendors/${id}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchClusters = async () => {
  let url = `${host}/v4/internal/ads/clusters`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};
import React, { useState } from "react";
import { retrieveFromLocalStorage } from "../../../../utilities/localStorageUtil";
import {
  redirectToNewTab,
  getStatusOfBtn,
  getStatusColor,
  getBtnName,
  getButtonColor,
  permissions,
  redirectTo,
} from "../../../../utilities/commonUtil";
import {
  Badge,
  Card,
  Container,
  CardImg,
  CardBody,
  CardTitle,
  CardFooter,
  Button,
  Row,
  Col,
  Input,
} from "reactstrap";
import {
  storyBucketCreatePermission,
  storyBucketListPermission,
  storyBucketUpdatePermission,
  storyBucketViewPermission,
  storiesBulkUpdatePermission,
  truncateText,
} from "../../../../config/configStoryDashboard";

export const BucketListingCard = (props) => {
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );
  const [bucket] = useState(props.reply);

  const handleManageStoriesRedirect = () => {
    redirectTo(`/stories/stories?bucketId=${bucket.id}&selectAll=true`);
  };

  const handleUploadStoriesRedirect = () => {
    redirectTo(`/stories/bulkUpload?bucketId=${bucket.id}`);
  };

  const handleClickDetails = (e) => {
    e.preventDefault();
    props.setShowDetailsModal(true);
    props.setEditDetails({
      id: bucket?.id,
      name: bucket?.name,
      shareText: bucket?.shareText,
      description: bucket?.description,
    });
  };

  return (
    <Card>
      <CardBody>
        <CardTitle>
          <small>ID: {bucket?.id}</small>
          <br />
          <large>Name: {bucket?.name}</large>
          <br />
          <large>
            Share Text : {truncateText(bucket?.shareText || " ", 25)}
          </large>
        </CardTitle>
      </CardBody>

      <CardFooter>
        <Container className="">
          <Row className="justify-content-center">
            {storyBucketViewPermission() ? (
              <Col xs={12} sm={12} className="mb-2">
                <Button
                  className="mw-75 w-100"
                  color="primary" // Set your desired color here
                  onClick={() => {
                    redirectTo(`/stories/buckets/create?id=${bucket?.id}`);
                  }}
                >
                  Details
                </Button>
              </Col>
            ) : null}
            <Col xs={12} sm={6} className="mb-2">
              <Button
                className="mw-75 w-100"
                color="success" // Set your desired color here
                onClick={handleManageStoriesRedirect}
              >
                Manage Stories
              </Button>
            </Col>
            <Col xs={12} sm={6} className="mb-2">
              <Button
                className="mw-75 w-100"
                color="secondary" // Set your desired color here
                onClick={handleUploadStoriesRedirect}
              >
                Upload Stories
              </Button>
            </Col>
          </Row>
        </Container>
      </CardFooter>
    </Card>
  );
};

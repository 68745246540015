import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import {
  errorDescription,
  error,
  getStatus,
  allActions,
  permissions,
  redirectionOnTokenExpiry,
  getStatusColor,
  getButtonColor,
  getBtnName,
  redirectOnTokenExpiry,
} from "../../../utilities/commonUtil";
import CreatableSelect from "react-select/creatable";
import ConfirmationModal from "../ConfirmationModal";
import ResponseModal from "../ResponseModal";
import { successModalHeading, errorModalHeading } from "../../../config/config";
import {
  createStoryBucket,
  updateStoryBucket,
  getSingleBucketData,
  getBucketsTagsData,
  postBucketTagsData,
  fetchBucketTargetingRulesData,
  updateBucketTargetingRulesData,
  getCountries,
  getRegions,
  getCities,
} from "../../../utilities/apiUtils/stories";
import {
  bucketsConfig,
  getdateValue,
  storyBucketTargetingRuleUpdatePermission,
  storyBucketTargetingRuleViewPermission,
} from "../../../config/configStoryDashboard";
import { getClients } from "../../../utilities/apiUtils/cre";
import TargetingRules from "../TargetingRules";

const CreateBucket = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [bucketData, setBucketData] = useState({
    name: "",
    shareText: "",
    description: "",
    startAt: null,
    endAt: null,
  });
  const [tags, setTags] = useState([]);
  const [action, setAction] = useState("");
  const [isTargetingDataSet, setIsTargetingDataSet] = useState(false);
  const [triggerTargetingRulesUpdate, setTriggerTargetingRulesUpdate] =
    useState(false);
  const [targetingRules, setTargetingRules] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [successModalState, setSuccessModalState] = useState({
    successModalDisplay: false,
    successModalText: "",
  });
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [saveTagsLoader, setSaveTagsLoader] = useState(false);

  // Fetch bucket data if ID parameter exists
  useEffect(() => {
    fetchBucketData();
  }, []);

  const fetchBucketData = async () => {
    const params = new URLSearchParams(window.location.search);
    const bucketId = params.get("id");
    if (bucketId) {
      try {
        const response = await getSingleBucketData(bucketId);
        if (response.errorCode) {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken
          )
            redirectionOnTokenExpiry();
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: errorDescription(response.errorCode),
          });
        } else {
          if (response) {
            setBucketData(response);
          }
          fetchTags(bucketId);
        }
      } catch (err) {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      }
    }
  };

  const fetchBucketDataWithoutTags = async () => {
    const params = new URLSearchParams(window.location.search);
    const bucketId = params.get("id");
    if (bucketId) {
      try {
        const response = await getSingleBucketData(bucketId);
        if (response.errorCode) {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken
          )
            redirectionOnTokenExpiry();
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: errorDescription(response.errorCode),
          });
        } else {
          if (response) {
            setBucketData(response);
          }
        }
      } catch (err) {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      }
    }
  };

  const fetchTags = async (id) => {
    try {
      let response = await getBucketsTagsData(id);
      // Assuming getBucketsTagsData returns an array of tag strings
      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectionOnTokenExpiry();
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(response.errorCode),
        });
      } else {
        const formattedTags = response.tags.map((tag) => ({
          label: tag,
          value: tag,
          __isNew__: true,
        }));
        setTags((prevState) => ({
          ...prevState,
          tags: formattedTags,
        }));
      }
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  useEffect(() => {
    if (isTargetingDataSet) {
      setConfirmationModalState();
    }
  }, [isTargetingDataSet]);

  const handlePostRerendering = (id) => {
    const searchParams = new URLSearchParams();
    searchParams.set("id", id);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
    fetchBucketData();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBucketData({ ...bucketData, [name]: value });
  };

  const successModelClose = () => {
    setSuccessModalState((prev) => ({ ...prev, successModalDisplay: false }));
    // fetchBucketData();
    if (action === bucketsConfig.SAVE_TARGETING_RULES)
      setTriggerTargetingRulesUpdate((prev) => !prev);
  };

  const failureModalClose = () => {
    setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
  };

  const setConfirmationModalState = (e) => {
    e.preventDefault();
    setAction(bucketsConfig.SUBMIT);
    setConfirmationModalIsOpen(true);
  };

  const performAction = () => {
    if (action === bucketsConfig.SUBMIT) {
      handleSubmit();
    } else if (action === bucketsConfig.SAVE_TARGETING_RULES) {
      updateTargetingRulesOnServer();
    } else if (action === bucketsConfig.SAVE_TAGS) {
      handleSaveTags();
    }
  };

  const saveRules = async (rules) => {
    setTargetingRules(rules);
    setAction(bucketsConfig.SAVE_TARGETING_RULES);
    setConfirmationModalIsOpen(true);
  };

  const updateTargetingRulesOnServer = async () => {
    setShowLoader(true);
    try {
      const response = await updateBucketTargetingRulesData(
        targetingRules,
        bucketData?.id
      );
      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectionOnTokenExpiry();
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: (response.errorDescription),
        });
      } else if (response) {
        setSuccessModalState({
          successModalDisplay: true,
          successModalText: "Targeting rules updated successfully",
        });
      }
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
    setShowLoader(false);
  };

  const setActionSaveTags = () => {
    setAction(bucketsConfig.SAVE_TAGS);
    setConfirmationModalIsOpen(true);
  };

  const handleSaveTags = async () => {
    let tagsArray = tags.tags.map((tag) => {
      return tag.value;
    });
    const data = {
      tags: tagsArray,
    };
    setSaveTagsLoader(true);
    try {
      let response = await postBucketTagsData(data, bucketData.id);
      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectionOnTokenExpiry();
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: response.errorDescription,
        });
      } else {
        setSuccessModalState({
          successModalDisplay: true,
          successModalText: response.message,
        });
        fetchTags(bucketData.id);
      }
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
    setSaveTagsLoader(false);
  };

  const handleSubmit = async () => {
    if (bucketData.name === "") {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: "Bucket name cannot be empty",
      });
      return;
    }

    let data = {};

    if (bucketData?.id) {
      data.id = bucketData?.id;
      data.name = bucketData?.name;
      data.shareText = bucketData?.shareText;
      data.description = bucketData?.description;
      data.startAt = bucketData?.startAt ? new Date(bucketData?.startAt) : null;
      data.endAt = bucketData?.endAt ? new Date(bucketData?.endAt) : null;
    }
    setSubmitLoader(true);
    try {
      let response;
      if (bucketData?.id) {
        response = await updateStoryBucket(data, bucketData?.id);
      } else {
        response = await createStoryBucket(bucketData);
      }
      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectionOnTokenExpiry();
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: response.errorDescription,
        });
      } else {
        setSuccessModalState({
          successModalDisplay: true,
          successModalText: response.message,
        });
        if (bucketData?.id && response?.id) {
          fetchBucketDataWithoutTags();
        } else if (response?.id) {
          handlePostRerendering(response.id);
        }
      }
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
    setSubmitLoader(false);
  };
  return (
    <Container fluid className="p-0">
      <Row className="mb-3">
        <Col md={6} xs={6}>
          <h1>{bucketData.id ? "Update" : "Create"} Bucket</h1>
        </Col>
      </Row>
      <Form onSubmit={(e) => setConfirmationModalState(e)}>
        <Card>
          <CardBody>
            <FormGroup>
              <Label for="name">Bucket Name</Label>
              <Input
                type="text"
                id="name"
                name="name"
                value={bucketData.name}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="shareText">Share Text</Label>
              <Input
                type="text"
                id="shareText"
                name="shareText"
                value={bucketData.shareText}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="description">Description</Label>
              <Input
                type="textarea"
                id="description"
                name="description"
                value={bucketData.description}
                onChange={handleInputChange}
              />
            </FormGroup>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="startAt">Start At</Label>
                  <Input
                    type="date"
                    id="startAt"
                    name="startAt"
                    value={getdateValue(bucketData.startAt)}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="endAt">End At</Label>
                  <Input
                    type="date"
                    id="endAt"
                    name="endAt"
                    value={getdateValue(bucketData.endAt)}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <br />
            <Button color="primary" type="submit">
              {!submitLoader ? (
                "Submit"
              ) : (
                <Spinner color="info" className=" d-flex mx-auto" />
              )}
            </Button>
          </CardBody>
        </Card>
      </Form>
      {bucketData.id && (
        <Row lg="12">
          <Col>
            <Card>
              <CardBody>
                <Col md={6}>
                  <h1> Tags </h1>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <CreatableSelect
                      id="tags"
                      isMulti
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="tags"
                      placeholder="Enter Tags"
                      value={tags.tags}
                      onChange={(event) => {
                        setTags((prevState) => ({
                          ...prevState,
                          tags: event,
                        }));
                      }}
                      components={{
                        DropdownIndicator: null,
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <Button color="primary" onClick={setActionSaveTags}>
                    {saveTagsLoader ? (
                      <Spinner color="info" className=" d-flex mx-auto" />
                    ) : (
                      "Save Tags"
                    )}
                  </Button>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {bucketData.id && storyBucketTargetingRuleViewPermission() && (
        <Row lg="12">
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <TargetingRules
                      id={bucketData?.id}
                      showAgeGenderRules={true}
                      showCustomAudienceTargetingRules={false}
                      showPlacementRules={false}
                      showClientRules={true}
                      showHardFiltering={false}
                      isAdvertisingAttributes={true}
                      getRules={fetchBucketTargetingRulesData}
                      saveRules={saveRules}
                      getClients={getClients}
                      getCountries={getCountries}
                      getRegions={getRegions}
                      getCities={getCities}
                      updateRulesAllowed={storyBucketTargetingRuleUpdatePermission()}
                      triggerTargetingRulesUpdate={triggerTargetingRulesUpdate}
                      setIsTargetingDataSet={setIsTargetingDataSet}
                      showLoader={showLoader}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      <ResponseModal
        show={successModalState.successModalDisplay}
        onHide={() => successModelClose()}
        modalheading={successModalHeading}
        modaltext={successModalState.successModalText}
      />
      <ResponseModal
        show={failureModalState.failureModalDisplay}
        onHide={() => failureModalClose()}
        modalheading={errorModalHeading}
        modaltext={failureModalState.failureModalText}
      />
      <ConfirmationModal
        show={confirmationModalIsOpen}
        modaltext={action}
        onConfirmationTrue={() => {
          setConfirmationModalIsOpen(false);
          performAction();
          // handleSubmit();
        }}
        onHide={() => setConfirmationModalIsOpen(false)}
      />
    </Container>
  );
};

export default CreateBucket;

import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import { Row, Col, FormGroup, Label, Input, Form, Button } from "reactstrap";

const PlacementLocationsModal = ({ show = false, type, formData, setFormData = () => { }, onSubmit = () => { }, onHide = () => { } }) => {
  const [data, setData] = useState({
    id: "",
    name: "",
    value: ""
  })

  useEffect(() => {
    if (formData?.id) {
      setData({
        id: formData?.id,
        name: formData?.name,
        value: formData?.value,
      })
    } else {
      setData({
        id: "",
        name: "",
        value: ""
      })
    }
  }, [formData])

  const handleChange = (event) => {
    const { name, value } = event.target
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleHide = () => {
    setData({
      id: "",
      name: "",
      value: ""
    })
    onHide()
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = {
      id: data.id,
      name: data.name,
      value: data.value
    }
    setFormData(payload)
    onSubmit(payload)
  }

  return (
    <Modal
      show={show}
      onHide={handleHide}
      aria-labelledby="contained-modal-title-vcenter"
      size='lg'
      centered
      backdrop="static"
    >
      <Modal.Header className="bg-light" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {type} Placement Location
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row form>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Name <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="name"
                  placeholder="Enter Name"
                  value={data.name}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Value <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="value"
                  placeholder="Enter Value"
                  value={data.value}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button color="primary" onClick={handleHide}>Close</Button>
          <Button type='submit' color='success'>Submit</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default PlacementLocationsModal
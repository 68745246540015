import React, { useState, useEffect } from "react";
import Select from "react-select";
import { FaPlus, FaMinus } from "react-icons/fa";
import { getClients } from "../../utilities/apiUtils/stickers";
import { Row, Col, Label, Button } from "reactstrap";

const options = [
  { label: "Allow", value: "allow" },
  { label: "Deny", value: "deny" },
];

const ClientRule = (props) => {
  var firstLoading = true;
  const [clients, setClients] = useState([]);
  const [clientRule, setClientRule] = useState({});
  const [ruleEffect, setRuleEffect] = useState({});

  const _addClientRule = (event) => {
    props.addClientRule();
  };

  const _removeClientRule = (event) => {
    props.removeClientRule(props.rule.id);
  };

  const handleClientRule = async (event) => {
    setClientRule({});
    if (event != null) {
      props.updateRule(props.rule.id, event.value, ruleEffect.value);
      props.updateRuleSummary(props.rule.id, event.label, ruleEffect.label);
      setClientRule(event);
    }
  };

  const handleEffect = async (event) => {
    setRuleEffect({});
    if (event != null) {
      setRuleEffect(event);
      props.updateRule(props.rule.id, clientRule.value, event.value);
      props.updateRuleSummary(props.rule.id, clientRule.label, event.label);
    }
  };

  useEffect(() => {
    const _getClients = async () => {
      let res
      res = await getClients();
      if (props.overrideClientApi) {
        res = await props.getClients
      }
      setClients(res);
    };
    _getClients();
  }, []);

  useEffect(() => {
    const _setInitValue = async () => {
      if (firstLoading && clients.length > 0) {
        var client = clients.find(
          (item) =>
            item.value.toLowerCase() === props.rule.clientID.toLowerCase()
        );

        setClientRule(client);

        var effect = options.find(
          (item) => item.value.toLowerCase() === props.rule.effect.toLowerCase()
        );
        setRuleEffect(effect);

        props.updateRuleSummary(props.rule.id, client?.label, effect?.label);

        firstLoading = false;
      }
    };
    _setInitValue();
  }, [clients, props.rule]);

  const disableAddClientRule = () => {
    const currentPath = window.location.href;

    // Check if the current path matches either of the specified paths
    return !!(currentPath.includes("/quick-reply/quick-reply-categories/") ||
      currentPath.match(/\/quick-reply\/targeting-rules\/[0-9-]+/));
  };


  return (
    <Row className="mr-1">
      <Col>
        <Label>Name</Label>
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          name="clients"
          placeholder="Select Clients"
          value={clientRule}
          options={clients}
          onChange={(option) => handleClientRule(option)}
          isDisabled={!props.updateAllowed}
          required
        />
      </Col>
      <Col md={2}>
        <Label>Effect</Label>
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          name="clientEffect"
          placeholder="Select Effects"
          options={options}
          value={ruleEffect}
          onChange={(option) => handleEffect(option)}
          isDisabled={!props.updateAllowed}
          required
        />
      </Col>
      {props.updateAllowed ? (
        <Col md={1} className="align-self-end">
          {props.rule.id === 0 && (
            <Button onClick={_addClientRule} color="transparent" disabled={disableAddClientRule()}>
              <FaPlus />
            </Button>
          )}
          {props.rule.id !== 0 && (
            <Button onClick={_removeClientRule} color="transparent">
              <FaMinus />
            </Button>
          )}
        </Col>
      ) : (
        <div></div>
      )}
    </Row>
  );
};

export default ClientRule;

import React, { useEffect, useState } from "react";
import { Card, CardBody, Container, Button, Badge, Row } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  getLanguages,
  updateLanguage,
} from "../../../utilities/apiUtils/keyboardLanguages";
import ResponseModal from "../ResponseModal";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import EditLanguage from "./EditLanguage";
import { Link } from "react-router-dom";
import {
  error,
  permissions,
  errorDescription,
  redirectionOnTokenExpiry,
} from "../../../utilities/commonUtil";
import {
  languagesTableColumns,
  languageUpdatedStatus,
  languageUpdatedText,
  successModalHeading,
  failureModalHeading,
} from "../../../config/keyboardLanguages";

const { SearchBar } = Search;

const LanguagesTable = () => {
  const [languages, setLanguages] = useState([]);
  const [column] = useState(languagesTableColumns);
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [editFormDisplay, setEditFormDisplay] = useState(false);
  const [editData, setEditData] = useState({});
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  useEffect(() => {
    fetchLanguages();
  }, []);

  let fetchLanguages = async () => {
    await getLanguages()
      .then((response) => {
        if (response.errorCode) {
          let error_code = errorDescription(response.errorCode);
          showErrorModal(error_code);
          if (
            response.errorCode === "Unauthorized" ||
            response.errorCode === error.invalidAccessToken ||
            response.errorCode === error.tokenExpired
          )
            redirectionOnTokenExpiry();
        } else if (response.languages) {
          setLanguages(response.languages);
        }
      })
      .catch((err) => {
        showErrorModal(errorDescription(error.unexpectedError));
      });
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  let successModalClose = () => {
    setSuccessModalDisplay(false);
    fetchLanguages();
  };

  let showErrorModal = (error_code) => {
    setFailureModalText(error_code);
    setFailureModalDisplay(true);
  };

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let showEditButton = () => {
    return allowedPermissions.includes(permissions.languageEdit);
  };

  let showEditForm = (row) => {
    let language = {
      id: row.id,
      name: row.name,
      nativeName: row.nativeName,
      code: row.code,
      territory: row.territory,
      vocabulary: row.vocabulary ? row.vocabulary : [],
    };
    setEditData(language);
    setEditFormDisplay(true);
  };

  let hideEditForm = () => {
    setEditFormDisplay(false);
    setEditData({});
  };

  let editLanguage = async (updatedLanguage, id) => {
    await updateLanguage(updatedLanguage, id).then((response) => {
      if (response.status === languageUpdatedStatus) {
        showSuccessModal(languageUpdatedText);
        setEditData({});
      } else {
        showErrorModal(response.errorDescription);
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken ||
          response.errorCode === "Unauthorized"
        )
          redirectionOnTokenExpiry();
      }
    });
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <ul>
          <li>{`Native Name : ${row.nativeName}`}</li>
          <li>{`Code : ${row.code}`}</li>
          <li>{`Created At : ${row.createdAt}`}</li>
          <li>{`Updated At : ${row.updatedAt}`}</li>
          <li>
            Vocabulary :{" "}
            {row.vocabulary
              ? Object.values(row.vocabulary).map((vocab, index) => {
                  return (
                    <Badge key={index} className="badge-light text-dark ml-2">
                      {vocab}
                    </Badge>
                  );
                })
              : String(null)}
          </li>
        </ul>
        {showEditButton() ? (
          <Button
            color="primary"
            className="mx-3"
            onClick={() => showEditForm(row)}
          >
            Edit
          </Button>
        ) : null}
        {Object.keys(editData).length > 0 ? (
          <EditLanguage
            show={editFormDisplay}
            onHide={hideEditForm}
            data={editData}
            edit={editLanguage}
          />
        ) : null}
      </div>
    ),
  };

  return (
    <Card>
      <CardBody>
        <ToolkitProvider keyField="id" data={languages} columns={column} search>
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} />
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                bordered={false}
                keyField="id"
                data={languages}
                columns={column}
                expandRow={expandRow}
                pagination={paginationFactory({
                  sizePerPage: 10,
                  sizePerPageList: [5, 10, 25, 50],
                })}
              />
            </div>
          )}
        </ToolkitProvider>
        <ResponseModal
          show={successModalDisplay}
          onHide={successModalClose}
          modalheading={successModalHeading}
          modaltext={successModalText}
        />
        <ResponseModal
          show={failureModalDisplay}
          onHide={failureModalClose}
          modalheading={failureModalHeading}
          modaltext={failureModalText}
        />
      </CardBody>
    </Card>
  );
};

const Languages = () => {
  const [allowedPermissions, setAllowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  const showCreateLanguageButton = () => {
    // return allowedPermissions.includes(permissions.languageCreate);
    return true;
  };

  return (
    <Container fluid className="p-0">
      <Row>
        <h1 className="mb-4 mt-2 w-50 pl-3">Languages</h1>
        {showCreateLanguageButton() ? (
          <Link
            className="d-block ml-auto mb-4 pr-3 text-decoration-none"
            to={{
              pathname: `/kl/create-language`,
            }}
          >
            <Button color="primary" className="ml-auto d-flex">
              <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                Add Language
              </p>
            </Button>
          </Link>
        ) : null}
      </Row>
      <LanguagesTable />
    </Container>
  );
};

export default Languages;

import React, { useState } from "react";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { permissions } from "../../../utilities/commonUtil";
import { getContentCategoryCarouselUserAttributeRules, saveContentCategoryCarouselUserAttributeRules, deactivateContentCategoryCarouselUserAttributeRules, activateContentCategoryCarouselUserAttributeRules } from "../../../utilities/apiUtils/cre";
import UserAttributeFilters from "../UserAttributeFilters";
import { userAttributeRuleFilterOptions } from "../../../config/config";

const ContentCategoryCarouselUserAttributeRules = () => {

    const [allowedPermissions] = useState(
        retrieveFromLocalStorage("userPermissions")
    );

    const updateRulesAllowed = () => {
        return allowedPermissions.includes(permissions.contentCategoryCarouselRuleUpdate);
    };

    return (
        <div>
            <UserAttributeFilters
                ruleType={"contentCategoryCarouselId"}
                getFilters={userAttributeRuleFilterOptions}
                getRules={getContentCategoryCarouselUserAttributeRules}
                saveRules={saveContentCategoryCarouselUserAttributeRules}
                deactivateRules={deactivateContentCategoryCarouselUserAttributeRules}
                activateRules={activateContentCategoryCarouselUserAttributeRules}
                updateRulesAllowed={updateRulesAllowed()}
            />
        </div>
    );
};

export default ContentCategoryCarouselUserAttributeRules;

import React from "react";
import { Badge } from "reactstrap";

export const columns = [
    {
        dataField: "id",
        text: "#",
        headerAttrs: { width: 60 },
        searchable: false,
        sort: true,
        style: {
            cursor: "pointer",
        },
    },
    {
        dataField: "successCount",
        text: "Success Count",
        searchable: true,
        style: {
            cursor: "pointer",
        },
    },
    {
        dataField: "failureCount",
        text: "Failure Count",
        searchable: true,
        style: {
            cursor: "pointer",
        },
    },
    {
        dataField: "status",
        text: "Status",
        sort: false,
        searchable: true,
        style: {
            cursor: "pointer",
        },
        formatter: (cell) => {
            if ({ cell }.cell === "completed")
                return <Badge className="badge-success">{cell}</Badge>;
            else if ({ cell }.cell === "failed")
                return <Badge className="badge-danger">{cell}</Badge>;
            else if ({ cell }.cell === "canceled")
                return <Badge className="badge-primary">{cell}</Badge>;
            else if ({ cell }.cell === "ongoing")
                return <Badge className="badge-warning">{cell}</Badge>;
            else if ({ cell }.cell === "not_started")
                return <Badge className="badge-info">{"not started"}</Badge>;
        }
    },
    {
        dataField: "canceledAt",
        text: "Canceled At",
        sort: true,
        searchable: true,
        style: {
            cursor: "pointer",
        },
        formatter: (cell) => {
            return cell === null ? "--" : cell;
        },
    },
    {
        dataField: "completedAt",
        text: "Completed At",
        sort: true,
        searchable: true,
        style: {
            cursor: "pointer",
        },
        formatter: (cell) => {
            return cell === null ? "--" : cell;
        },
    },
    {
        dataField: "createdAt",
        text: "Created At",
        sort: true,
        searchable: true,
        style: {
            cursor: "pointer",
        },
    },
    {
        dataField: "updatedAt",
        text: "Updated At",
        searchable: true,
        sort: true,
        style: {
            cursor: "pointer",
        },
    },
];

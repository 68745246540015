import { retrieveFromLocalStorage } from "../localStorageUtil";
import { secureFetch } from "../secureFetch";
import { host } from "./index";

export let getBrandCampaign = async (brandCampaignId) => {
  let token = retrieveFromLocalStorage("token");
  let brandCampaigns = {};
  let url = `${host}/v1/internal/brandCampaigns/${brandCampaignId}`;

  await secureFetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    brandCampaigns = response.json();
  });

  return brandCampaigns;
};

export let getBrandCampaigns = async () => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/stickers/brandCampaigns`;

  let campaigns = [];

  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    var res = await response.json();
    if (res) {
      res.forEach((campaign) => {
        let campaignObj = {
          value: campaign.id,
          label: campaign.name,
          ...campaign
        };
        campaigns.push(campaignObj);
      });
    }
    return campaigns;
  } catch (error) {
    return;
  }
};
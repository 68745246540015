import React, { useState, useEffect } from "react";
import { Card, CardBody, Row } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Badge } from "reactstrap";
import ToolTip from "../../../../utilities/ToolTip";

const GifPackGifStatusTable = ({ gifPacks, action, onSelectRow }) => {
    const [userData, setUserData] = useState([]);
    const [column] = useState(
        [
            {
                dataField: "id",
                text: "#",
                headerAttrs: { width: 60 },
                searchable: false,
            },
            {
                dataField: "name",
                text: "Name",
                searchable: true,
            },
            {
                dataField: "status",
                text: "Status",
                searchable: false,
                formatter: (cell) => {
                    if ({ cell }.cell === "Activated")
                        return <Badge className="badge-success">{cell}</Badge>;
                    else if ({ cell }.cell === "Deactivated")
                        return <Badge className="badge-danger">{cell}</Badge>;
                    else if ({ cell }.cell === "Published")
                        return <Badge className="badge-success">{cell}</Badge>;
                    else if ({ cell }.cell === "Unpublished")
                        return <Badge color="primary">{cell}</Badge>;
                },
            },
        ]
    );

    useEffect(() => {
        if (gifPacks !== undefined && gifPacks.length > 0) {
            setUserData(gifPacks);
        }
    }, [gifPacks]);

    const selectRow = {
        mode: "checkbox",
        clickToSelect: false,
        hideSelectAll: true,
        classes: "selection-row",
        onSelect: (row, isSelect, rowIndex, e) => {
            onSelectRow(row, isSelect);
        },
    };

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            console.log({ row, rowIndex });
        }
    };

    return (
        <Card>
            <CardBody>
                <ToolkitProvider keyField="id" data={userData} columns={column} search>
                    {(props) => (
                        <div>
                            <Row>
                                <h4 className="px-2">Gif Packs</h4>
                                <ToolTip
                                    data="Gif Pack Status controls the availability of gif in corresponding gif pack."
                                    id="gifPackStatus"
                                />
                            </Row>
                            <BootstrapTable
                                {...props.baseProps}
                                bootstrap4
                                bordered={false}
                                keyField="id"
                                data={userData}
                                columns={column}
                                rowEvents={rowEvents}
                                selectRow={selectRow}
                            />
                        </div>
                    )}
                </ToolkitProvider>
            </CardBody>
        </Card>
    );
};

export default GifPackGifStatusTable;

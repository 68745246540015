import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
} from "reactstrap";
import { getTemplateStats } from "../../../utilities/apiUtils/stories";
import { Loader, Spinner } from "reactstrap";

const TemplateStatsListing = () => {
  const [stats, setStats] = useState({});
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [loading, setLoading] = useState(true);
  const fetchStats = async () => {
    try {
      const response = await getTemplateStats();
      if (response) {
        setStats(response);
        setLoading(false);
      }
    } catch (error) {
      showErrorModal(errorDescription(error));
    }
  };
  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };


let failureModalClose = () => {
    setFailureModalDisplay(false);
};

  let errorDescription = (error) => {
    return error?.message || "An unexpected error occurred";
  };

  useEffect(() => {
    
    fetchStats();
    
  }, []);

  return (
<Container fluid className="p-3">
  {loading ? (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <Spinner color="primary" />
    </div>
  ) : (
    <>
      <h2 className="mb-4">AI Story Generation Status</h2>
      <Button color="primary" onClick={fetchStats} className="mb-3">
        Refresh Stats
      </Button>
      <Row>
        {Object.entries(stats.PerSourceContentStats || {}).map(
          ([sourceId, sourceStats]) => (
            <Col md="4" key={sourceId} className="mb-4">
              <Card>
                <CardBody>
                  <CardTitle tag="h5">Source ID: {sourceId}</CardTitle>
                  <CardText>Total Stories: {sourceStats.TotalStories}</CardText>
                  <CardText>Pending Stories: {sourceStats.PendingStories}</CardText>
                  <img
                    src={sourceStats.staticPreviewFilename}
                    alt={`Preview for source ${sourceId}`}
                    className="img-fluid"
                  />
                </CardBody>
              </Card>
            </Col>
          )
        )}
      </Row>
    </>
  )}
</Container>

  );
};

export default TemplateStatsListing;

import React, { useState, useEffect } from "react";
import {
  updateCarouselItemsOrder,
  fetchCarouselItemsOrder,
} from "../../../utilities/apiUtils/carousel.js";
import ResponseModal from "../ResponseModal";
import { useHistory, useParams } from "react-router-dom";

const ManageCarouselItems = () => {
  const [items, setItems] = useState([]);
  const [carouselId, setCarouselId] = useState(null);
  const history = useHistory();
  const { id } = useParams();
  const [successModalState, setSuccessModalState] = useState({
    successModalDisplay: false,
    successModalText: "",
  });
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });

  useEffect(() => {
    setCarouselId(id);
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!carouselId) {
          console.error("Carousel ID is not available.");
          return;
        }

        const response = await fetchCarouselItemsOrder(carouselId);
        const carouselItems = response?.contentCategoryCarouselItems || [];
        setItems(carouselItems);
      } catch (error) {
        console.error("Error fetching carousel items:", error.message);
      }
    };

    if (carouselId) {
      fetchData();
    }
  }, [carouselId]);

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index.toString());
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, toIndex) => {
    e.preventDefault();
    const fromIndex = parseInt(e.dataTransfer.getData("text/plain"), 10);

    if (fromIndex !== toIndex) {
      const draggedItem = items[fromIndex];
      const newOrder = items.filter((_, index) => index !== fromIndex);

      newOrder.splice(toIndex, 0, draggedItem);
      setItems(newOrder);
    }
  };

  const handleSaveOrder = async () => {
    try {
      if (!carouselId) {
        console.error("Carousel ID is undefined.");
        return;
      }

      // Construct the new order directly from the dragged items' positions
      const newOrder = items.map((item, index) => ({
        id: item.id,
        priority: index,
      }));

      // Make the POST request with the constructed payload
      const updateOrderSuccess = await updateCarouselItemsOrder(carouselId, {
        contentCategoryCarouselItems: newOrder,
      });

      setSuccessModalState({
        successModalDisplay: true,
        successModalText: "Item Order updated successfully",
      });
      // Reload the page after successful update
      window.location.reload();
    } catch (error) {
      console.error("Error:", error);
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: "Error occurred while updating order of the items",
      });
    }
  };

  return (
    <div style={{ maxWidth: "800px", margin: "0 auto" }}>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          marginBottom: "20px",
          border: "1px solid #ddd",
          borderRadius: "5px",
          overflow: "hidden",
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                padding: "12px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
                borderBottom: "1px solid #ddd",
              }}
            >
              Item ID
            </th>
            <th
              style={{
                padding: "12px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
                borderBottom: "1px solid #ddd",
              }}
            >
              Item Type
            </th>
            <th
              style={{
                padding: "12px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
                borderBottom: "1px solid #ddd",
              }}
            >
              Priority
            </th>
            <th
              style={{
                padding: "12px",
                textAlign: "left",
                backgroundColor: "#f2f2f2",
                borderBottom: "1px solid #ddd",
              }}
            >
              Image
            </th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr
              key={item.id}
              draggable
              onDragStart={(e) => handleDragStart(e, index)}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, index)}
              style={{
                borderBottom: "1px solid #ddd",
                cursor: "move",
                backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff",
              }}
            >
              <td style={{ padding: "12px" }}>{item.id}</td>
              <td style={{ padding: "12px" }}>{item.type}</td>
              <td style={{ padding: "12px" }}>{item.priority}</td>
              <td style={{ padding: "12px" }}>
                {item.imageUrl && (
                  <img
                    src={item.imageUrl}
                    alt={`Preview for ${item.type}`}
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "cover",
                    }}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button
          style={{
            padding: "10px 20px",
            fontSize: "16px",
            backgroundColor: "#4CAF50",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            marginRight: "10px",
          }}
          onClick={handleSaveOrder}
        >
          Save Order
        </button>
        <ResponseModal
          show={successModalState.successModalDisplay}
          onHide={() => {
            setSuccessModalState({
              successModalDisplay: false,
              successModalText: "",
            });
          }}
          modalheading="Success Heading"
          modaltext={successModalState.successModalText}
        />

        <ResponseModal
          show={failureModalState.failureModalDisplay}
          onHide={() => {
            setFailureModalState({
              failureModalDisplay: false,
              failureModalText: "",
            });
          }}
          modalheading="Failure Heading"
          modaltext={failureModalState.failureModalText}
        />
        <button
          style={{
            padding: "10px 20px",
            fontSize: "16px",
            backgroundColor: "#ccc",
            color: "#333",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          onClick={() => history.push(`/cre/content-carousels`)}
        >
          Back to Carousel List
        </button>
      </div>
    </div>
  );
};

export default ManageCarouselItems;

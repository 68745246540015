import React, { useState, useEffect } from "react";
import Search from "../../../components/Search";
import Select from "react-select";
import {
  QuickReplyCard,
  QuickReplyCardType,
} from "./components/QuickReplyCard";
import ResponseModal from "../ResponseModal";
import ConfirmationModal from "../ConfirmationModal";
import { Col, Container, Row, Button, Spinner, FormGroup } from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { Config } from "../../../config";
import {
  getLanguages,
  getQuickReplies,
  getQuickReplyCategoriesForForm,
  deactivateQuickReply,
  activateQuickReply,
  publishQuickReply,
} from "../../../utilities/apiUtils/quickReplies";
import {
  successModalHeading,
  failureModalHeading,
} from "../../../config/quickReplies";

import {
  errorDescription,
  error,
  getStatus,
  allActions,
  permissions,
  redirectionOnTokenExpiry,
  getStatusColor,
  getButtonColor,
  getBtnName,
  redirectOnTokenExpiry,
} from "../../../utilities/commonUtil";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { Link } from "react-router-dom";
import EmptyQuickReplies from "./components/EmptyQuickReplies";
import { faUpload, faTimes } from "@fortawesome/free-solid-svg-icons";
import UncontrolledButtonDropdown from "reactstrap/lib/UncontrolledButtonDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import { capitalize } from "@material-ui/core";

const QuickReplies = () => {
  const [id, setID] = useState(null);
  const [action, setAction] = useState("");
  const [quickReplies, setQuickReplies] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [category, setCategory] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [categories, setCategories] = useState([]);
  const [hasMoreQuickReplies, setHasMoreQuickReplies] = useState(true);
  const [noQuickReplyFound, setNoQuickReplyFound] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [languages, setLanguages] = useState();
  const [language, setLanguage] = useState("");
  const [successModalState, setSuccessModalState] = useState({
    successModalDisplay: false,
    successModalText: "",
  });
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );
  const [status, setStatus] = useState("");
  const [sortBy, setSortBy] = useState(Config.CREATED_AT_DESCENDING);
  const [searchBy, setSearchBy] = useState(Config.SEARCH_BY_DEFAULT);
  const [loading, setLoading] = useState(true);
  const [firstRender, setFirstRender] = useState(true);
  const [showTranslations, setShowTranslations] = useState(false);
  const [translations, setTranslations] = useState([]);

  useEffect(() => {
    const _getLanguages = async () => {
      let response = await getLanguages();
      setLanguages(response);
    };
    _getLanguages();
    getReplies();
    getCategories();
    setFirstRender(false);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let params = {
        categoryID: category,
        language: language,
        searchString: searchBy === "name" ? searchString : "",
        id: searchBy === "id" ? searchString : "",
        status: status,
        sortBy: sortBy,
        page: page,
        limit: limit,
      };

      let response = await getQuickReplies(params);

      if (response.quickReplies !== null) {
        let allQuickReplies = quickReplies.concat(response.quickReplies);
        setQuickReplies(allQuickReplies);
      } else {
        setHasMoreQuickReplies(false);
      }
    };

    fetchData();
  }, [page]);

  const handleLanguageChange = (language) => {
    setLanguage(language.value);
  };

  const getReplies = async () => {
    let params = {
      categoryID: category,
      language: language,
      searchString: searchBy === "name" ? searchString : "",
      id: searchBy === "id" ? searchString : "",
      status: status,
      sortBy: sortBy,
      page: page,
      limit: limit,
    };

    try {
      let response = await getQuickReplies(params);

      if (response.errorCode) {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectionOnTokenExpiry();
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(response.errorCode),
        });
      } else if (response.quickReplies) {
        if (response.quickReplies !== null) {
          setQuickReplies(response.quickReplies);
          setLoading(false);
          return;
        }
      }
      setLoading(false);
      setNoQuickReplyFound(true);
    } catch (err) {
      setLoading(false);
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const getCategories = async () => {
    try {
      let categories = await getQuickReplyCategoriesForForm();
      setCategories(categories);
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const handleSearchStringChange = (event) => {
    event.preventDefault();
    let { value } = event.target;
    setSearchString(value);
    if (!value.length) {
      setSearchString("");
      search();
      return;
    }
  };

  useEffect(() => {
    search();
  }, [searchString]);

  useEffect(() => {
    if (!firstRender) search();
  }, [category, status, sortBy, language]);

  const handleCategoryChange = (category) => {
    setCategory(category.value);
  };

  const handleStatusChange = (status) => {
    setStatus(status.value);
  };

  const handleOrderChange = (sortBy) => {
    setSortBy(sortBy.value);
  };

  const fetchMoreData = async () => {
    if (searchBy === "id") return;

    setPage(page + 1);
    setNoQuickReplyFound(false);
    setHasMoreQuickReplies(true);
  };

  const search = async (localSearchBy) => {
    setPage(0);
    setNoQuickReplyFound(false);
    setHasMoreQuickReplies(true);
    setSearchBy(localSearchBy || searchBy);
    setLoading(true);
    setQuickReplies([]);

    let params = {
      categoryID: category,
      searchString: searchBy === "name" ? searchString : "",
      id: searchBy === "id" ? searchString : "",
      status: status,
      sortBy: sortBy,
      page: page,
      limit: limit,
      language: language,
    };

    try {
      let response = await getQuickReplies(params);
      if (response.quickReplies != null) {
        setQuickReplies(response.quickReplies);
        setLoading(false);
        return;
      }
      if (page === 0) {
        setLoading(false);
        setNoQuickReplyFound(true);
      } else {
        setLoading(false);
        setNoQuickReplyFound(false);
      }
    } catch (err) {
      setLoading(false);
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const setConfirmationModalState = (id, btnName) => {
    setID(id);
    setAction(btnName);
    setConfirmationModalIsOpen(true);
  };

  const performAction = () => {
    if (action === allActions.publish) {
      publish(id);
    }
    if (action === allActions.deactivate) {
      deactivate(id);
    }
    if (action === allActions.activate) {
      activate(id);
    }
  };

  const handleResponse = (response) => {
    if (!response.errorCode) {
      setSuccessModalState({
        successModalDisplay: true,
        successModalText: response.description,
      });
    } else {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: response.errorCode,
      });
    }
  };

  const publish = async (id) => {
    try {
      let response = await publishQuickReply(id);
      handleResponse(response);
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const deactivate = async (id) => {
    try {
      let response = await deactivateQuickReply(id);
      handleResponse(response);
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const activate = async (id) => {
    try {
      let response = await activateQuickReply(id);
      handleResponse(response);
    } catch (err) {
      setFailureModalState({
        failureModalDisplay: true,
        failureModalText: errorDescription(error.unexpectedError),
      });
    }
  };

  const failureModalClose = () => {
    setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
  };

  const successModelClose = () => {
    setSuccessModalState((prev) => ({ ...prev, successModalDisplay: false }));
    setQuickReplies([]);
    setPage(0);
    setHasMoreQuickReplies(true);
    getReplies();
  };

  const showCreateQuickRepliesButton = () => {
    return allowedPermissions.includes(permissions.quickReplyCreate);
  };

  const getIcon = (status) => {
    if (status === "Deactivated") return faUpload;
    return faTimes;
  };

  const renderAllReplies = () => {
    if (quickReplies.length === 0 && !loading) {
      return <EmptyQuickReplies />;
    } else {
      return quickReplies.map((reply) => {
        let status = getStatus(reply.publishedAt, reply.deactivatedAt);
        let color = getStatusColor(status);
        let btnColor = getButtonColor(status);
        let btnName = getBtnName(status);
        return (
          <>
            {reply?.viewType === "text" && (
              <Col md="6" lg="4" key={reply.id}>
                <QuickReplyCard
                  reply={reply}
                  color={color}
                  status={status}
                  btnName={btnName}
                  btnColor={btnColor}
                  btnIcon={getIcon(status)}
                  getConfirmation={setConfirmationModalState}
                />
              </Col>
            )}

            {reply?.viewType !== "text" && (
              <Col md="6" lg="4" key={reply.id}>
                <QuickReplyCardType
                  reply={reply}
                  color={color}
                  status={status}
                  btnName={btnName}
                  btnColor={btnColor}
                  btnIcon={getIcon(status)}
                  getConfirmation={setConfirmationModalState}
                />
              </Col>
            )}
          </>
        );
      });
    }
  };

  const onKeyDown = (event) => {
    if (event.keyCode === Config.KEYPRESS_DOWN) {
      search();
    }
  };

  return (
    <Container fluid className="p-0">
      <Row>
        <h1 className="mb-3 pl-3">Quick Replies</h1>
        {showCreateQuickRepliesButton() ? (
          <>
            <Link
              className="d-block ml-auto mb-3 pr-3 text-decoration-none"
              to={{
                pathname: `/quick-reply/create-quick-reply`,
              }}
            >
              <Button color="primary" className="ml-auto d-flex">
                <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                  Create
                </p>
              </Button>
            </Link>
            <Link
              className="d-block mb-3 pr-3 text-decoration-none"
              to={{
                pathname: `/quick-reply/create-quick-reply-bulk`,
              }}
            >
              <Button color="primary" className="ml-auto d-flex">
                <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                  Bulk Upload
                </p>
              </Button>
            </Link>
          </>
        ) : null}
      </Row>
      <Row>
        <Col md={4} sm={12}>
          <Search
            placeholder="Search"
            value={searchString}
            onChange={(event) => handleSearchStringChange(event)}
            onKeyDown={(event) => onKeyDown(event)}
          ></Search>
        </Col>
        <Col md={3} sm={12}>
          <UncontrolledButtonDropdown className="mr-1 mb-1">
            <DropdownToggle caret color="success">
              {"Search By " + capitalize(searchBy) + " "}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  search("id");
                }}
              >
                ID
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  search("name");
                }}
              >
                Name
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      <Row>
        <Col md={3} sm={12}>
          <Select
            className="react-select-container mb-4"
            classNamePrefix="react-select"
            placeholder="Select category"
            onChange={(option) => handleCategoryChange(option)}
            options={categories}
          />
        </Col>
        <Col md={3} sm={12}>
          <FormGroup>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              name="language"
              placeholder="Select Languages"
              options={languages}
              onChange={(option) => handleLanguageChange(option)}
            />
          </FormGroup>
        </Col>
        <Col md={3} sm={12}>
          <Select
            className="react-select-container mb-4"
            classNamePrefix="react-select"
            placeholder="Select Status"
            onChange={(option) => handleStatusChange(option)}
            options={[
              { value: "", label: "All" },
              { value: "active", label: "Active" },
              { value: "deactivated", label: "Deactivated" },
              { value: "unpublished", label: "Unpublished" },
            ]}
          />
        </Col>
        <Col md={3} sm={12}>
          <Select
            className="react-select-container mb-4"
            classNamePrefix="react-select"
            placeholder="Sort By"
            onChange={(option) => handleOrderChange(option)}
            options={[
              {
                value: Config.UpdatedAtDescending,
                label: "Updated At \u2193",
              },
              {
                value: Config.UpdatedAtAscending,
                label: "Updated At \u2191",
              },
              {
                value: Config.CreatedAtDescending,
                label: "Created At \u2193",
              },
              {
                value: Config.CreatedAtAscending,
                label: "Created At \u2191",
              },
              {
                value: Config.PriorityDescending,
                label: "Priority \u2193",
              },
              {
                value: Config.ProirityAscending,
                label: "Priority \u2191",
              },
            ]}
          />
        </Col>
      </Row>

      <InfiniteScroll
        className="infinite-scroll"
        dataLength={quickReplies.length}
        next={fetchMoreData}
        hasMore={hasMoreQuickReplies}
        loader={
          !loading &&
          searchBy !== "id" &&
          !noQuickReplyFound && (
            <Spinner color="info" className=" d-flex mx-auto" />
          )
        }
      >
        {loading ? (
          <Spinner color="info" className=" d-flex mx-auto" />
        ) : noQuickReplyFound ? (
          <Container
            sm="12"
            md="8"
            lg="6"
            className="mx-auto d-table mt-5 pt-2 pb-5"
          >
            <h1 className="text-center">No reply found</h1>
          </Container>
        ) : (
          <React.Fragment>
            <Row className="mt-2">{renderAllReplies()}</Row>
            <ResponseModal
              show={successModalState.successModalDisplay}
              onHide={() => successModelClose()}
              modalheading={successModalHeading}
              modaltext={successModalState.successModalText}
            />
            <ResponseModal
              show={failureModalState.failureModalDisplay}
              onHide={() => failureModalClose()}
              modalheading={failureModalHeading}
              modaltext={failureModalState.failureModalText}
            />
            <ConfirmationModal
              show={confirmationModalIsOpen}
              modaltext={action}
              onHide={() => {
                setConfirmationModalIsOpen(false);
              }}
              onConfirmationTrue={() => {
                setConfirmationModalIsOpen(false);
                performAction();
              }}
            />
          </React.Fragment>
        )}
      </InfiniteScroll>
    </Container>
  );
};

export default QuickReplies;

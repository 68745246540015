import React from "react";
import { Row, Col, Label, Input } from "reactstrap";

const AppVersionRule = (props) => {
  return (
    <Row className="mr-1">
      <Col>
        <Label>MinAppVersion</Label>
        <Input
          type="number"
          name="minAppVersion"
          id="minAppVersion"
          placeholder="MinAppVersion"
          value={props.appVersionRules.minAppVersion}
          onChange={(e) => props.updateAppVersionRules(e, "minAppVersion")}
          disabled={!props.updateAllowed || props.disableAppVersion}
        />
      </Col>
      <Col>
        <Label>MaxAppVersion</Label>
        <Input
          type="number"
          name="maxAppVersion"
          id="maxAppVersion"
          placeholder="MaxAppVersion"
          value={props.appVersionRules.maxAppVersion === null ? "" : props.appVersionRules.maxAppVersion}
          onChange={(e) => props.updateAppVersionRules(e, "maxAppVersion")}
          disabled={!props.updateAllowed || props.disableAppVersion}
        />
      </Col>
    </Row>
  );
};

export default AppVersionRule;

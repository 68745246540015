import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Card,
  CardBody,
} from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import {
  fetchSingleReward,
  updateReward,
} from "../../../utilities/apiUtils/rewards";

const EditRewardForm = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    listing_priority: "",
    num_coins: "",
    redirection_link: "",
    file_data_url: "", // Store file data URL
  });

  const history = useHistory();
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReward = async () => {
      try {
        const response = await fetchSingleReward(id);
        const rewardData = response[0];

        setFormData({
          ...rewardData,
          file_data_url: rewardData.static_icon_filename,
        });
      } catch (error) {
        console.error("An error occurred:", error);
        setError(
          "There was an error while fetching the reward. Please try again."
        );
      }
    };

    fetchReward();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Preserve the image field when other fields are changed
    setFormData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  // Handle file input change
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // Read the file and convert it to a data URL
      const reader = new FileReader();
      reader.onload = (event) => {
        setFormData({ ...formData, file_data_url: event.target.result });
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      name: formData.name,
      description: formData.description,
      listing_priority: parseInt(formData.listing_priority),
      num_coins: parseInt(formData.num_coins),
      redirection_link: formData.redirection_link,
      static_icon_filename: "", // Use the existing image URL
      static_icon_src: formData.file_data_url, // Include the existing image URL as src
    };

    try {
      const response = await updateReward(id, payload);
      // Reward update was successful
      history.push("/rewards/view-rewards");
    } catch (error) {
      console.error("An error occurred while updating the reward:", error);
      setError(
        "There was an error while updating the reward. Please try again."
      );
    }
  };

  return (
    <Container className="reward-form-container my-5">
      <Card className="reward-form-card">
        <CardBody>
          <h1 className="reward-form-title mb-4">Edit Reward</h1>
          {error && <div className="error-message">{error}</div>}
          <Form onSubmit={handleSubmit}>
            <FormGroup className="mb-3">
              <Label for="name">
                Name <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="reward-form-input"
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="description">
                Description <span className="text-danger">*</span>
              </Label>
              <Input
                type="textarea"
                name="description"
                id="description"
                value={formData.description}
                onChange={handleChange}
                required
                className="reward-form-input"
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <div className="d-flex">
                <div className="flex-grow-1 me-4">
                  <Label for="listing_priority" className="mb-2">
                    Priority <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="number"
                    name="listing_priority"
                    id="listing_priority"
                    value={formData.listing_priority}
                    onChange={handleChange}
                    required
                    className="reward-form-input"
                  />
                </div>
                <div className="flex-grow-1">
                  <Label for="num_coins" className="mb-2">
                    Number of Coins <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="number"
                    name="num_coins"
                    id="num_coins"
                    value={formData.num_coins}
                    onChange={handleChange}
                    required
                    className="reward-form-input"
                  />
                </div>
              </div>
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="file">Image File</Label>
              <Input
                type="file"
                name="file"
                id="file"
                onChange={handleFileChange}
                className="reward-form-input"
              />
              {formData.file_data_url && (
                <div className="reward-form-image-preview mt-3">
                  <img
                    src={formData.file_data_url}
                    alt="Selected File"
                    className="reward-form-preview-image img-thumbnail"
                    style={{ maxWidth: "150px" }}
                  />
                </div>
              )}
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="redirection_link">Redirection Link</Label>
              <Input
                type="text"
                name="redirection_link"
                id="redirection_link"
                value={formData.redirection_link}
                onChange={handleChange}
                className="reward-form-input"
              />
            </FormGroup>
            <Button
              color="primary"
              type="submit"
              className="reward-form-button"
            >
              Update Reward
            </Button>
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
};

export default EditRewardForm;

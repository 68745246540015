import React, { useState, useEffect } from "react";
import Select from "react-select";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import {
  getFonts,
  getLanguages,
  getBrandCampaigns,
} from "../../../utilities/apiUtils/stickers";
import {
  validateHexCode,
  validateInteger,
} from "../../../utilities/commonUtil";
import {
  Col,
  Row,
  Button,
  Container,
  Card,
  CardBody,
  Input,
  Label,
  FormText,
  Form,
  FormGroup,
  CustomInput,
} from "reactstrap";

import ResponseModal from "../ResponseModal";

import { imageFileSizeExceedError, maxImageUploadSize } from "../../../config/configPopTextStyle";

import { errorModalHeading } from "../../../config/config";

const PopTextForm = (props) => {
  const [formTextState, setFormTextState] = useState({
    name: props.style.name,
    enableWatermark: props.style.enableWatermark,
    previewImage: "",
    startDate: props.style.startDate,
    endDate: props.style.endDate,
    brandCampaignID: props.style.brandCampaign
      ? props.style.brandCampaign.id
      : 0,
    backgroundImage: "",
    fontID: props.style.fontID,
    enableShadow: props.style.shadow.enable,
    enableStroke1: props.style.stroke1.enable,
    enableStroke2: props.style.stroke2.enable,
    enableStroke3: props.style.stroke3.enable,

    font: {
      value: props.style.font.value
        ? {
            name: props.style.font.value.name,
            color: props.style.font.value.color,
          }
        : null,
      valid: true,
    },
    stroke1: {
      value: props.style.stroke1.value
        ? {
            color: props.style.stroke1.value.color,
            width: parseFloat(props.style.stroke1.value.width),
          }
        : null,
      valid: true,
    },
    stroke2: {
      value: props.style.stroke2.value
        ? {
            color: props.style.stroke2.value.color,
            width: parseFloat(props.style.stroke2.value.width),
          }
        : null,
      valid: true,
    },
    stroke3: {
      value: props.style.stroke3.value
        ? {
            color: props.style.stroke3.value.color,
            width: parseFloat(props.style.stroke3.value.width),
          }
        : null,
      valid: true,
    },
    shadow: {
      value: props.style.shadow.value
        ? {
            color: props.style.shadow.value.color,
            x: parseFloat(props.style.shadow.value.x),
            y: parseFloat(props.style.shadow.value.y),
            radius: parseFloat(props.style.shadow.value.radius),
          }
        : null,
      valid: true,
    },
    customTextDetails: {
      value: props.style.customTextDetails.value
        ? {
            position: {
              x: parseFloat(props.style.customTextDetails.value.position.x),
              y: parseFloat(props.style.customTextDetails.value.position.y),
              width: parseFloat(
                props.style.customTextDetails.value.position.width
              ),
              height: parseFloat(
                props.style.customTextDetails.value.position.height
              ),
            },
          }
        : null,
    },
    priority: {
      value: props.style.priority.value,
      valid: true,
    },
    targetLanguages: props.style.targetLanguages ?? null,

    keywords: props.style.keywords,
    previewImageSrc: props.style.previewImageSrc,
    backgroundImageSrc: props.style.backgroundImageSrc,
  });

  const [displayError, setDisplayError] = useState(false);
  const [fonts, setFonts] = useState([]);
  const [defaultFont, setDefaultFont] = useState({});
  const [campaigns, setCampaigns] = useState([]);
  const [defaultCampaign, setDefaultcampaign] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");

  useEffect(() => {
    const _getBrandCampaigns = async () => {
      let response = await getBrandCampaigns();
      setCampaigns(response);
    };
    _getBrandCampaigns();
  }, []);

  useEffect(() => {
    setDefaultcampaign(defaultValueForCampaign());
  }, [campaigns]);

  const defaultValueForCampaign = () => {
    var brandID = props.style.brandCampaign ? props.style.brandCampaign.id : 0;
    var defCampaign = campaigns.find((item) => item.value === brandID);
    return defCampaign;
  };

  useEffect(() => {
    const _getFonts = async () => {
      let response = await getFonts();
      setFonts(response);
    };
    _getFonts();
  }, []);

  useEffect(() => {
    setDefaultFont(defaultValueForFonts());
  }, [fonts]);

  const defaultValueForFonts = () => {
    var defFont = fonts.find((item) => item.value === props.style.fontID);
    return defFont;
  };

  useEffect(() => {
    const _getLanguages = async () => {
      let response = await getLanguages();
      setLanguages(response);
    };
    _getLanguages();
  }, []);
  

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
    props.onHide();
  };

  const handleWidthChange = (event) => {
    const { name, value } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: {
        value: {
          color: prev[name].value ? prev[name].value.color ?? "" : "",
          width: parseFloat(value),
        },
        valid: prev[name].valid,
      },
    }));
    setDisplayError(false);
  };

  const handleTexBoxWidthChange = (event) => {
    const { name, value } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: {
        value: {
          position: {
            x: prev[name].value ? prev[name].value.position.x ?? null : null,
            y: prev[name].value ? prev[name].value.position.y ?? null : null,
            height: prev[name].value
              ? prev[name].value.position.height ?? null
              : null,
            width: parseFloat(value),
          },
        },
      },
    }));
    setDisplayError(false);
  };

  const handleTextBoxHeightChange = (event) => {
    const { name, value } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: {
        value: {
          position: {
            x: prev[name].value ? prev[name].value.position.x ?? null : null,
            y: prev[name].value ? prev[name].value.position.y ?? null : null,
            width: prev[name].value
              ? prev[name].value.position.width ?? null
              : null,
            height: parseFloat(value),
          },
        },
      },
    }));
    setDisplayError(false);
  };

  const handleTextBoxXChange = (event) => {
    const { name, value } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: {
        value: {
          position: {
            y: prev[name].value ? prev[name].value.position.y ?? null : null,
            x: parseFloat(value),
            width: prev[name].value
              ? prev[name].value.position.width ?? null
              : null,
            height: prev[name].value
              ? prev[name].value.position.height ?? null
              : null,
          },
        },
      },
    }));
    setDisplayError(false);
  };

  const handleTextBoxYChange = (event) => {
    const { name, value } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: {
        value: {
          position: {
            x: prev[name].value ? prev[name].value.position.x ?? null : null,
            y: parseFloat(value),
            width: prev[name].value
              ? prev[name].value.position.width ?? null
              : null,
            height: prev[name].value
              ? prev[name].value.position.height ?? null
              : null,
          },
        },
      },
    }));
    setDisplayError(false);
  };

  const handleShadowWidthChange = (event) => {
    const { name, value } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: {
        value: {
          x: prev[name].value ? prev[name].value.x ?? null : null,
          y: prev[name].value ? prev[name].value.y ?? null : null,
          color: prev[name].value ? prev[name].value.color ?? null : null,
          radius: parseFloat(value),
        },
        valid: prev[name].valid,
      },
    }));
    setDisplayError(false);
  };

  const handleShadowColorChange = (event) => {
    const { name, value } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: {
        value: {
          x: prev[name].value ? prev[name].value.x ?? null : null,
          y: prev[name].value ? prev[name].value.y ?? null : null,
          radius: prev[name].value ? prev[name].value.width ?? null : null,
          color: value,
        },
        valid: validateHexCode(value),
      },
    }));
    setDisplayError(false);
  };

  const handleShadowXChange = (event) => {
    const { name, value } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: {
        value: {
          radius: prev[name].value ? prev[name].value.radius ?? null : null,
          y: prev[name].value ? prev[name].value.y ?? null : null,
          color: prev[name].value ? prev[name].value.color ?? null : null,
          x: parseFloat(value),
        },
        valid: prev[name].valid,
      },
    }));
    setDisplayError(false);
  };

  const handleShadowYChange = (event) => {
    const { name, value } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: {
        value: {
          x: prev[name].value ? prev[name].value.x ?? null : null,
          radius: prev[name].value ? prev[name].value.radius ?? null : null,
          color: prev[name].value ? prev[name].value.color ?? null : null,
          y: parseFloat(value),
        },
        valid: prev[name].valid,
      },
    }));
    setDisplayError(false);
  };

  const handleHexCodeChange = (event) => {
    const { name, value } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: {
        value: {
          color: value,
          width: prev[name].value ? prev[name].value.width ?? "" : "",
        },
        valid: validateHexCode(value),
      },
    }));
    setDisplayError(false);
  };

  const handleFontHexCodeChange = (event) => {
    var value = event.target.value;
    setFormTextState((prev) => ({
      ...prev,
      font: {
        value: {
          color: value,
          name: prev.font.value ? prev.font.value.name ?? "" : "",
        },
        valid: validateHexCode(value),
      },
    }));
    setDisplayError(false);
  };

  const handleCampaignChange = (campaign) => {
    setFormTextState((prev) => ({
      ...prev,
      brandCampaignID: campaign ? campaign.value : 0,
    }));
    setDefaultcampaign(campaign);
    setDisplayError(false);
  };

  const handleFontChange = (font) => {
    setFormTextState((prev) => ({
      ...prev,
      font: {
        value: {
          color: prev.font.value ? prev.font.value.color ?? "" : "",
          name: font.label,
        },
        valid: prev.font.valid,
      },
      fontID: font.value,
    }));
    setDefaultFont(font);
    setDisplayError(false);
  };

  const handlePriorityChange = (event) => {
    const { name, value } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: {
        value: value,
        valid: validateInteger(value) && parseInt(value) !== 0,
      },
    }));
    setDisplayError(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: value,
    }));

    setDisplayError(false);
  };

  const handleCheckBoxChange = (event) => {
    const { id, checked } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [id]: checked,
    }));

    setDisplayError(false);
  };

  const handleTargetLanguages = (language) => {
    setFormTextState((prev) => ({
      ...prev,

      ["targetLanguages"]: language,
    }));
    setDisplayError(false);
  };

  const handleTagsChange = (tags) => {
    // Split the input value by commas and trim spaces around each tag
    const tagsSplit = tags
      .map((item) => item.split(','))
      .flat() // Flatten the array to remove nested arrays
      .map((item) => item.trim());

    // Remove duplicates by converting the tagsSplit array to a Set and then back to an array
    const uniqueTags = [...new Set(tagsSplit)];

    // Update the formTextState with the unique tags
    setFormTextState((prev) => ({
      ...prev,
      keywords: uniqueTags,
    }));
  };
  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: files[0],
    }));

    event.persist();
    // For showing Image preview
    if (files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        if (files[0].size > maxImageUploadSize && !defaultCampaign) {
          setFailureModalText(imageFileSizeExceedError);
          setFailureModalDisplay(true);
          return;
        }
        if (name === "previewImage") {
          setFormTextState((prev) => ({
            ...prev,
            previewImageSrc: reader.result,
          }));
          document.getElementById("previewImg").innerHTML = files[0].name;
        } else {
          setFormTextState((prev) => ({
            ...prev,
            backgroundImageSrc: reader.result,
          }));
          document.getElementById("backgroundImg").innerHTML = files[0].name;
        }
      };
    }
  };

  const submit = (event) => {
    event.preventDefault();
    let inputsWithError = document.getElementsByClassName("is-invalid");

    if (
      inputsWithError.length === 0 &&
      formTextState.name !== "" &&
      formTextState.font.value &&
      formTextState.font.value.name !== ""
    )
      props.submit(formTextState);
    else {
      setDisplayError(true);
    }
  };

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">{props.style.formName}</h1>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Form
                onSubmit={(event) => {
                  submit(event);
                }}
              >
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        <strong>Name*</strong>
                      </Label>
                      <p style={{ fontSize: 12 }}>Name of the pop text style</p>

                      <Input
                        type="text"
                        name="name"
                        defaultValue={props.style.name}
                        onChange={(event) => handleChange(event)}
                        disabled={!props.updateAllowed}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col className="themeCategoryDropdown">
                    <Label>
                      <strong>Brand Campaign</strong>
                      <p style={{ fontSize: 12 }}>
                        Brand Campaign Pop text style is linked with
                      </p>
                    </Label>
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="campaign"
                      value={defaultCampaign != null ? defaultCampaign : null}
                      placeholder="Select campaign"
                      isClearable={true}
                      options={campaigns}
                      onChange={(option) => handleCampaignChange(option)}
                      isDisabled={!props.updateAllowed}
                    />
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row className="my-2">
                  <Col className="align-middle" display="table">
                    <Row className="pl-2">
                      <Col className="pl-0">
                        <strong>Enable Watermarks*</strong>
                      </Col>
                      <Col
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div className="custom-control custom-switch">
                          <CustomInput
                            type="checkbox"
                            id="enableWatermark"
                            value={formTextState.enableWatermark === true}
                            onChange={(event) => handleCheckBoxChange(event)}
                            disabled={!props.updateAllowed}
                            checked={formTextState.enableWatermark === true}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="pl-2">
                      <p style={{ fontSize: 12 }}>
                        Whether to display water mark in the pop text
                      </p>
                    </Row>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                <Col md={6}>
                <FormGroup>
                  <Label
                    className="font-weight-bolder mb-2"
                  >
                    <strong>
                    Start Date
                    </strong>
                  </Label>
                  <Input
                    type="date"
                    name="startDate"
                    placeholder="Enter Start Date"
                    defaultValue={formTextState.startDate}
                    disabled={!props.updateAllowed}
                    onChange={(event) => {
                      setFormTextState({
                        ...formTextState,
                        startDate: event.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label
                    className="font-weight-bolder mb-2"
                  >
                    <strong>
                    End Date
                    </strong>
                  </Label>
                  <Input
                    type="date"
                    name="endDate"
                    placeholder="Enter End Date"
                    disabled={!props.updateAllowed}
                    defaultValue={formTextState.endDate}
                    onChange={(event) => {
                      setFormTextState({
                        ...formTextState,
                        endDate: event.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        <strong>
                          Priority
                          <small className="text-info">
                            (Enter integer value greater than 0)
                          </small>
                        </strong>
                      </Label>
                      <Input
                        type="number"
                        name="priority"
                        defaultValue={props.style.priority.value}
                        className={
                          formTextState.priority.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handlePriorityChange(event)}
                        disabled={!props.updateAllowed}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        <strong>
                          Background Image
                          <small className="text-info">
                            (Choose a 512x512 png file)
                          </small>
                        </strong>
                      </Label>
                      <Container className="custom-file">
                        <Input
                          type="file"
                          className="custom-file-input"
                          accept=".png"
                          name="backgroundImage"
                          onChange={(event) => handleFileChange(event)}
                          disabled={!props.updateAllowed}
                          required={
                            props.style.formName === "Create Pop Text Style"
                          }
                        />
                        <Label className="custom-file-label" id="backgroundImg">
                          <strong>Choose an image</strong>
                        </Label>
                      </Container>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  {formTextState.backgroundImageSrc !== "" &&
                  formTextState.backgroundImageSrc !== null ? (
                    <Col className="pb-4">
                      <img
                        src={formTextState.backgroundImageSrc}
                        height="120"
                        alt="backgroundImage"
                        width="120"
                      />
                    </Col>
                  ) : (
                    <Col></Col>
                  )}
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        <strong>
                          Preview Image
                          <small className="text-info">
                            (Choose a 512x512 jpeg file)
                          </small>
                        </strong>
                      </Label>
                      <Container className="custom-file">
                        <Input
                          type="file"
                          className="custom-file-input"
                          accept="false/jpeg"
                          name="previewImage"
                          onChange={(event) => handleFileChange(event)}
                          disabled={!props.updateAllowed}
                          required={
                            props.style.formName === "Create Pop Text Style"
                          }
                        />
                        <Label className="custom-file-label" id="previewImg">
                          <strong>Choose an image</strong>
                        </Label>
                      </Container>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  {formTextState.previewImageSrc !== "" &&
                  formTextState.previewImageSrc !== null ? (
                    <Col className="pb-4">
                      <img
                        src={formTextState.previewImageSrc}
                        height="120"
                        alt="previewImage"
                        width="120"
                      />
                    </Col>
                  ) : (
                    <Col></Col>
                  )}
                </Row>
                <hr style={{ margin: 8 }} />

                <Row>
                  <Col lg={3}>
                    <FormGroup>
                      <Label>
                        <strong>TextBox X</strong>
                      </Label>
                      <Input
                        type="number"
                        name="customTextDetails"
                        defaultValue={
                          props.style.customTextDetails.value != null
                            ? props.style.customTextDetails.value.position.x
                            : null
                        }
                        onChange={(event) => handleTextBoxXChange(event)}
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={3}>
                    <FormGroup>
                      <Label>
                        <strong>TextBox Y</strong>
                      </Label>
                      <Input
                        type="number"
                        name="customTextDetails"
                        defaultValue={
                          props.style.customTextDetails.value != null
                            ? props.style.customTextDetails.value.position.y
                            : null
                        }
                        onChange={(event) => handleTextBoxYChange(event)}
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={3}>
                    <FormGroup>
                      <Label>
                        <strong>TextBox Width</strong>
                      </Label>
                      <Input
                        type="number"
                        name="customTextDetails"
                        defaultValue={
                          props.style.customTextDetails.value != null
                            ? props.style.customTextDetails.value.position.width
                            : null
                        }
                        onChange={(event) => handleTexBoxWidthChange(event)}
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={3}>
                    <FormGroup>
                      <Label>
                        <strong>TextBox Height</strong>
                      </Label>
                      <Input
                        type="number"
                        name="customTextDetails"
                        defaultValue={
                          props.style.customTextDetails.value != null
                            ? props.style.customTextDetails.value.position
                                .height
                            : null
                        }
                        onChange={(event) => handleTextBoxHeightChange(event)}
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col lg={6}>
                    <FormGroup>
                      <Label>
                        <strong>
                          Font Color*
                          {formTextState.font.valid ? (
                            <div
                              className="displayColor ml-2"
                              style={{
                                backgroundColor: formTextState.font.value
                                  ? formTextState.font.value.color
                                  : null,
                              }}
                            ></div>
                          ) : (
                            <small className="text-danger ml-2">
                              {"Invalid Hex Code"}
                            </small>
                          )}
                        </strong>
                      </Label>
                      <Input
                        type="text"
                        name="fontColor"
                        defaultValue={
                          props.style.font.value
                            ? props.style.font.value.color
                            : null
                        }
                        className={
                          formTextState.font.valid
                            ? "form-control is-valid"
                            : "form-control is-invalid"
                        }
                        onChange={(event) => handleFontHexCodeChange(event)}
                        disabled={!props.updateAllowed}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6} className="themeCategoryDropdown">
                    <Label>
                      <strong>Font* </strong>
                    </Label>
                    {formTextState.font.valid ? null : (
                      <small className="text-danger">(Fonts)</small>
                    )}
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      name="name"
                      value={defaultFont == null ? null : defaultFont}
                      placeholder="Select font"
                      options={fonts}
                      onChange={(option) => handleFontChange(option)}
                      isDisabled={!props.updateAllowed}
                      required
                    />
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col>
                    <Label>
                      <strong>Enable Stroke 1</strong>
                    </Label>
                  </Col>

                  <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div className="custom-control custom-switch">
                      <CustomInput
                        type="checkbox"
                        id="enableStroke1"
                        value={formTextState.enableStroke1 === true}
                        onChange={(event) => handleCheckBoxChange(event)}
                        disabled={!props.updateAllowed}
                        checked={formTextState.enableStroke1 === true}
                      />
                    </div>
                  </Col>
                </Row>
                {formTextState.enableStroke1 == true ? (
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>
                          <strong>
                            Storke 1 Color
                            {formTextState.stroke1.valid ? (
                              <div
                                className="displayColor ml-2"
                                style={{
                                  backgroundColor: formTextState.stroke1.value
                                    ? formTextState.stroke1.value.color
                                    : "",
                                }}
                              ></div>
                            ) : (
                              <small className="text-danger ml-2">
                                {"Invalid Hex Code"}
                              </small>
                            )}
                          </strong>
                        </Label>
                        <Input
                          type="text"
                          id="stroke1Color"
                          name="stroke1"
                          defaultValue={
                            props.style.stroke1.value != null
                              ? props.style.stroke1.value.color
                              : ""
                          }
                          onChange={(event) => handleHexCodeChange(event)}
                          disabled={!props.updateAllowed}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={6}>
                      <FormGroup>
                        <Label>
                          <strong>Stroke 1 Width</strong>
                        </Label>
                        <Input
                          type="number"
                          id="stroke1Width"
                          name="stroke1"
                          defaultValue={
                            props.style.stroke1.value != null
                              ? props.style.stroke1.value.width
                              : ""
                          }
                          onChange={(event) => handleWidthChange(event)}
                          disabled={!props.updateAllowed}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                ) : null}
                <hr style={{ margin: 8 }} />

                <Row>
                  <Col>
                    <Label>
                      <strong>Enable Stroke 2</strong>
                    </Label>
                  </Col>

                  <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div className="custom-control custom-switch">
                      <CustomInput
                        type="checkbox"
                        id="enableStroke2"
                        value={formTextState.enableStroke2 === true}
                        onChange={(event) => handleCheckBoxChange(event)}
                        disabled={!props.updateAllowed}
                        checked={formTextState.enableStroke2 === true}
                      />
                    </div>
                  </Col>
                </Row>
                {formTextState.enableStroke2 == true ? (
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>
                          <strong>
                            Storke 2 Color
                            {formTextState.stroke2.valid ? (
                              <div
                                className="displayColor ml-2"
                                style={{
                                  backgroundColor: formTextState.stroke2.value
                                    ? formTextState.stroke2.value.color
                                    : "",
                                }}
                              ></div>
                            ) : (
                              <small className="text-danger ml-2">
                                {"Invalid Hex Code"}
                              </small>
                            )}
                          </strong>
                        </Label>
                        <Input
                          type="text"
                          id="stroke2Color"
                          name="stroke2"
                          defaultValue={
                            props.style.stroke2.value != null
                              ? props.style.stroke2.value.color
                              : ""
                          }
                          className={
                            formTextState.stroke2.valid
                              ? "form-control is-valid"
                              : "form-control is-invalid"
                          }
                          onChange={(event) => handleHexCodeChange(event)}
                          disabled={!props.updateAllowed}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>
                          <strong>Stroke 2 Width</strong>
                        </Label>
                        <Input
                          type="number"
                          id="stroke2Width"
                          name="stroke2"
                          defaultValue={
                            props.style.stroke2.value != null
                              ? props.style.stroke2.value.width
                              : ""
                          }
                          onChange={(event) => handleWidthChange(event)}
                          disabled={!props.updateAllowed}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                ) : null}
                <hr style={{ margin: 8 }} />

                <Row>
                  <Col>
                    <Label>
                      <strong>Enable Stroke 3</strong>
                    </Label>
                  </Col>

                  <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div className="custom-control custom-switch">
                      <CustomInput
                        type="checkbox"
                        id="enableStroke3"
                        value={formTextState.enableStroke3 === true}
                        onChange={(event) => handleCheckBoxChange(event)}
                        disabled={!props.updateAllowed}
                        checked={formTextState.enableStroke3 === true}
                      />
                    </div>
                  </Col>
                </Row>
                {formTextState.enableStroke3 == true ? (
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>
                          <strong>
                            Storke 3 Color
                            {formTextState.stroke3.valid ? (
                              <div
                                className="displayColor ml-2"
                                style={{
                                  backgroundColor: formTextState.stroke3.value
                                    ? formTextState.stroke3.value.color
                                    : "",
                                }}
                              ></div>
                            ) : (
                              <small className="text-danger ml-2">
                                {"Invalid Hex Code"}
                              </small>
                            )}
                          </strong>
                        </Label>
                        <Input
                          type="text"
                          id="stroke3color"
                          name="stroke3"
                          defaultValue={
                            props.style.stroke3.value != null
                              ? props.style.stroke3.value.color
                              : ""
                          }
                          className={
                            formTextState.stroke3.valid
                              ? "form-control is-valid"
                              : "form-control is-invalid"
                          }
                          onChange={(event) => handleHexCodeChange(event)}
                          disabled={!props.updateAllowed}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>
                          <strong>Stroke 3 Width</strong>
                        </Label>
                        <Input
                          type="number"
                          id="stroke3Width"
                          name="stroke3"
                          defaultValue={
                            props.style.stroke3.value != null
                              ? props.style.stroke3.value.width
                              : ""
                          }
                          className={
                            formTextState.stroke3.valid
                              ? "form-control is-valid"
                              : "form-control is-invalid"
                          }
                          onChange={(event) => handleWidthChange(event)}
                          disabled={!props.updateAllowed}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                ) : null}
                <hr style={{ margin: 8 }} />

                <Row>
                  <Col>
                    <Label>
                      <strong>Enable Shadow</strong>
                    </Label>
                  </Col>

                  <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div className="custom-control custom-switch">
                      <CustomInput
                        type="checkbox"
                        id="enableShadow"
                        value={formTextState.enableShadow === true}
                        onChange={(event) => handleCheckBoxChange(event)}
                        checked={formTextState.enableShadow === true}
                        disabled={!props.updateAllowed}
                      />
                    </div>
                  </Col>
                </Row>

                {formTextState.enableShadow == true ? (
                  <Row>
                    <Col lg={3}>
                      <FormGroup>
                        <Label>
                          <strong>Shadow X</strong>
                        </Label>
                        <Input
                          type="number"
                          name="shadow"
                          defaultValue={
                            props.style.shadow.value
                              ? props.style.shadow.value.x
                              : null
                          }
                          className={
                            formTextState.shadow.valid
                              ? "form-control is-valid"
                              : "form-control is-invalid"
                          }
                          onChange={(event) => handleShadowXChange(event)}
                          disabled={!props.updateAllowed}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={3}>
                      <FormGroup>
                        <Label>
                          <strong>Shadow Y</strong>
                        </Label>
                        <Input
                          type="number"
                          name="shadow"
                          defaultValue={
                            props.style.shadow.value
                              ? props.style.shadow.value.y
                              : null
                          }
                          className={
                            formTextState.shadow.valid
                              ? "form-control is-valid"
                              : "form-control is-invalid"
                          }
                          onChange={(event) => handleShadowYChange(event)}
                          disabled={!props.updateAllowed}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={3}>
                      <FormGroup>
                        <Label>
                          <strong>
                            Shadow Color
                            {formTextState.shadow.valid ? (
                              <div
                                className="displayColor ml-2"
                                style={{
                                  backgroundColor: formTextState.shadow.value
                                    ? formTextState.shadow.value.color
                                    : "",
                                }}
                              ></div>
                            ) : (
                              <small className="text-danger ml-2">
                                {"Invalid Hex Code"}
                              </small>
                            )}
                          </strong>
                        </Label>
                        <Input
                          type="text"
                          name="shadow"
                          defaultValue={
                            props.style.shadow.value
                              ? props.style.shadow.value.color
                              : null
                          }
                          className={
                            formTextState.shadow.valid
                              ? "form-control is-valid"
                              : "form-control is-invalid"
                          }
                          onChange={(event) => handleShadowColorChange(event)}
                          disabled={!props.updateAllowed}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={3}>
                      <FormGroup>
                        <Label>
                          <strong>Shadow Radius</strong>
                        </Label>
                        <Input
                          type="number"
                          name="shadow"
                          defaultValue={
                            props.style.shadow.value
                              ? props.style.shadow.value.radius
                              : null
                          }
                          className={
                            formTextState.shadow.valid
                              ? "form-control is-valid"
                              : "form-control is-invalid"
                          }
                          onChange={(event) => handleShadowWidthChange(event)}
                          disabled={!props.updateAllowed}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                ) : null}
                <hr style={{ margin: 8 }} />

                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        <strong>Target Languages</strong>
                      </Label>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        name="languages"
                        placeholder="Select Languages"
                        options={languages}
                        isMulti
                        defaultValue={formTextState.targetLanguages}
                        onChange={(option) => handleTargetLanguages(option)}
                        isDisabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                <Col>
                  <FormGroup>
                    <Label>
                      <strong>Tags</strong>
                    </Label>
                    <TagsInput
                      value={formTextState.keywords}
                      onChange={(tags) => handleTagsChange(tags)}
                      disabled={!props.updateAllowed}
                      inputProps={{
                        placeholder: "Add a Tag",
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

                {displayError ? (
                  <p className="text-danger mb-0">
                    Please enter all valid details.
                  </p>
                ) : null}
                {props.updateAllowed ? (
                  <Button color="primary" type="submit" className="mt-2">
                    Submit
                  </Button>
                ) : (
                  <div />
                )}
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ResponseModal
        show={failureModalDisplay}
        onHide={failureModalClose}
        modalheading={errorModalHeading}
        modaltext={failureModalText}
      />
    </Container>
  );
};

export default PopTextForm;

import React, { useState, useEffect } from "react";
import {
  validateHexCode,
  validateInteger,
  validateFloat,
  errorDescription,
  error,
  redirectOnTokenExpiry,
  permissions,
} from "../../../utilities/commonUtil";
import Reorder, { reorder } from "react-reorder";
import ResponseModal from "../ResponseModal";
import {
  Col,
  Row,
  Button,
  Container,
  Card,
  CardBody,
  Input,
  Label,
  Form,
  FormGroup,
  CustomInput,
  Collapse,
} from "reactstrap";

import { getClients } from "../../../utilities/apiUtils/stickers";

import {
  titleAlignmentOptions,
  quickRepliesPageType,
  failureModalHeading,
  invalidColorCodeResponse,
} from "../../../config/quickReplies";
import { FaChevronUp, FaChevronDown, FaMinus } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { X } from "react-feather";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { CardImg } from "react-bootstrap";
import Select from "react-select";
import { getLanguages } from "../../../utilities/apiUtils/quickReplies";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";

const QuickReplyCategoryForm = (props) => {
  const [formTextState, setFormTextState] = useState({
    name: props.name,
    metadata: props.category.metadata,
    providerName: props.category.providerName,
    viewType: props.category.viewType,
    priority: props.priority,
    translations: props.category.translations,
  });

  const [metadata, setMetadata] = useState({
    shareURL: "",
    clientId: { value: "global", label: "Global" },
    horizontal: false,
    vertical: false,
    bannerSettings: {
      horizontal: {
        startIndex: null,
        repeatFrequency: null,
        limit: null,
        lightThemeBackgroundColor: "",
        darkThemeBackgroundColor: "",
        lightThemeTitleColor: "",
        darkThemeTitleColor: "",
        title: "",
        titleAlignment: "",
      },
      vertical: {
        startIndex: null,
        repeatFrequency: null,
        limit: null,
        lightThemeBackgroundColor: "",
        darkThemeBackgroundColor: "",
        lightThemeTitleColor: "",
        darkThemeTitleColor: "",
        title: "",
        titleAlignment: "",
      },
    },
  });

  const [clientOptions, setClientOptions] = useState(props?.clientOptions);
  const [displayError, setDisplayError] = useState(false);

  const [horizontalAdBanners, setHorizontalAdBanners] = useState(
    props.category.adBanners?.filter(
      (adBanner) => adBanner.type === "horizontal"
    )
  );

  const [shareURLToggle, setShareURLToggle] = useState(true);

  const [verticalAdBanners, setVerticalAdBanners] = useState(
    props.category.adBanners?.filter((adBanner) => adBanner.type === "vertical")
  );

  const [horizontalAdBannersID, setHorizontalAdBannersID] = useState([]);
  const [verticalAdBannersID, setVerticalAdBannersID] = useState([]);

  const [translations, setTranslations] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [showTranslations, setShowTranslations] = useState(false);
  const [loading, setLoading] = useState(true);
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [showShareURLSection, setShowShareURLSection] = useState(false);
  const [showShareURLSectionDetails, setShowShareURLSectionDetails] =
    useState(false);
  const [shareURLIndexForEdit, setShareURLIndexForEdit] = useState(null);
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );
  // Update allowed permissions for the user
  let updateAllowed = () => {
    return allowedPermissions.includes(permissions.quickReplyCategoryUpdate);
  };

  // priority allowed permissions for user
  let priorityAllowed = () => {
    return allowedPermissions.includes(permissions.quickReplyAdbannerReorder);
  };

  const validateColor = (color) => {
    if (color !== null && color !== "" && color !== undefined) {
      return validateHexCode(color);
    }
    return true;
  };

  const failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  useEffect(() => {
    const _getLanguages = async () => {
      let response = await getLanguages();
      if (!response?.errorCode) {
        setLanguages(response);
        setShowTranslations(true);
      } else {
        let text = errorDescription(response.errorCode);
        setLoading(false);
        showErrorModal(text);
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectOnTokenExpiry();
      }
    };

    const getTranslations = () => {
      setTranslations(props.category.translations);
    };
    getTranslations();
    _getLanguages();
    _getClientOptions();
  }, []);

  useEffect(() => {
    if (props.category.translations && props.category.translations.length > 0) {
      let translation = [];
      let index = 0;
      props.category.translations.map((trans) => {
        if (languages !== undefined && languages !== null) {
          languages.map((lang) => {
            if (lang?.value === trans?.languageCode) {
              translation.push({
                id: index,
                languageCode: lang.value,
                name: trans.name,
                dropdownValue: lang,
              });
            }
          });
        }
        index++;
      });
      setTranslations(translation);
    }
  }, [showTranslations]);

  const addMetadata = () => {
    setMetadata({
      shareURL: "",
      clientId: { value: "global", label: "Global" },
      horizontal: false,
      vertical: false,
      bannerSettings: {
        horizontal: {
          startIndex: null,
          repeatFrequency: null,
          limit: null,
          lightThemeBackgroundColor: "",
          darkThemeBackgroundColor: "",
          lightThemeTitleColor: "",
          darkThemeTitleColor: "",
          title: "",
          titleAlignment: "",
        },
        vertical: {
          startIndex: null,
          repeatFrequency: null,
          limit: null,
          lightThemeBackgroundColor: "",
          darkThemeBackgroundColor: "",
          lightThemeTitleColor: "",
          darkThemeTitleColor: "",
          title: "",
          titleAlignment: "",
        },
      },
    });
    setShowShareURLSection(true);
    setShowShareURLSectionDetails(false);
  };

  const saveMetadata = () => {
    let isClientIdPresent = false;

    if (
      shareURLIndexForEdit === null ||
      (shareURLIndexForEdit !== null &&
        metadata.clientId.value !==
          formTextState.metadata[shareURLIndexForEdit].clientId.value)
    ) {
      // check if client id is already present in the shareURLs
      formTextState.metadata.forEach((item, index) => {
        if (item.clientId.value === metadata.clientId.value) {
          // display error modal
          isClientIdPresent = true;
          // break the loop
          return;
        }
      });
    }

    if (isClientIdPresent) {
      showErrorModal("Client Id already present");
      return;
    }

    let inputsWithError = document.getElementsByClassName("is-invalid");
    if (inputsWithError.length !== 0) {
      showErrorModal(invalidColorCodeResponse);
      return;
    }

    if (shareURLIndexForEdit === null) {

      let newMetadata = {
        shareURL: metadata.shareURL,
        clientId: metadata.clientId,
        horizontal: metadata.horizontal,
        vertical: metadata.vertical,
        bannerSettings: {
          horizontal: metadata.horizontal ? metadata.bannerSettings.horizontal : null,
          vertical: metadata.vertical ? metadata.bannerSettings.vertical : null,
        }
      }

      setFormTextState((prev) => ({
        ...prev,
        metadata: [...prev.metadata, newMetadata],
      }));
    }

    if (shareURLIndexForEdit !== null) {
      let metadatas = [...formTextState.metadata];
      metadatas[shareURLIndexForEdit] = metadata;
      setFormTextState((prev) => ({
        ...prev,
        metadata: metadatas,
      }));
    }

    setShareURLIndexForEdit(null);
    setShowShareURLSection(false);
    setMetadata({
      shareURL: "",
      clientId: { value: "global", label: "Global" },
      horizontal: false,
      vertical: false,
      bannerSettings: {
        horizontal: {
          startIndex: null,
          repeatFrequency: null,
          limit: null,
          lightThemeBackgroundColor: "",
          darkThemeBackgroundColor: "",
          lightThemeTitleColor: "",
          darkThemeTitleColor: "",
          title: "",
          titleAlignment: "",
        },
        vertical: {
          startIndex: null,
          repeatFrequency: null,
          limit: null,
          lightThemeBackgroundColor: "",
          darkThemeBackgroundColor: "",
          lightThemeTitleColor: "",
          darkThemeTitleColor: "",
          title: "",
          titleAlignment: "",
        },
      },
    });
  };

  const _getClientOptions = async () => {
    let clientOptions = await getClients();
    setClientOptions(clientOptions);
  };

  const handleTranslationChange = (id, event, languageCode) => {
    const newTranslations = translations.map((i) => {
      if (id === i.id) {
        if (languageCode !== null) {
          i["languageCode"] = languageCode.value;
        } else {
          i[event.target.name] = event.target.value;
        }
      }
      return i;
    });

    setTranslations(newTranslations);
  };

  const handleAddTranslation = () => {
    if (translations.length > 0) {
      setTranslations([
        ...translations,
        {
          id: translations[translations.length - 1].id + 1,
          languageCode: "",
          name: "",
        },
      ]);
    } else {
      setTranslations([...translations, { id: 0, languageCode: "", name: "" }]);
    }
  };

  const handleRemoveTranslation = (id) => {
    const values = [...translations];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setTranslations(values);
  };

  const handlePriorityChange = (event) => {
    const { name, value } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: parseInt(value),
    }));
    setDisplayError(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [name]: value,
    }));
    setDisplayError(false);
  };

  const handleViewTypeChange = (event) => {
    setFormTextState((prev) => ({
      ...prev,
      viewType: event,
    }));
    setDisplayError(false);
  };

  const handleCheckBoxChange = (event) => {
    const { id, checked } = event.target;
    setMetadata((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };

  const reOrderHorizontalAdBanner = (
    event,
    previousIndex,
    nextIndex,
    fromId,
    toId
  ) => {
    setHorizontalAdBanners(
      reorder(horizontalAdBanners, previousIndex, nextIndex)
    );
    let id = [];
    horizontalAdBanners.forEach((adBanner) => id.push(adBanner.id));
    setHorizontalAdBannersID(id);
  };

  const reOrderVerticalAdBanner = (
    event,
    previousIndex,
    nextIndex,
    fromId,
    toId
  ) => {
    setVerticalAdBanners(reorder(verticalAdBanners, previousIndex, nextIndex));
    let id = [];
    verticalAdBanners.forEach((adBanner) => id.push(adBanner.id));
    setVerticalAdBannersID(id);
  };

  const checkShareTextValidity = () => {

    if (
      metadata.shareURL === null ||
      metadata.shareURL === "" ||
      metadata.shareURL === undefined ||
      metadata.clientId === null ||
      metadata.clientId === ""
    ) {
      return true;
    }

    if (metadata?.horizontal) {
      if (
        metadata?.bannerSettings?.horizontal?.limit === null ||
        metadata?.bannerSettings?.horizontal?.limit === "" ||
        metadata?.bannerSettings?.horizontal?.limit === undefined ||
        metadata?.bannerSettings?.horizontal?.repeatFrequency === null ||
        metadata?.bannerSettings?.horizontal?.repeatFrequency === "" ||
        metadata?.bannerSettings?.horizontal?.repeatFrequency === undefined
      ) {
        return true;
      }
    }

    if (metadata?.vertical) {
      if (
        metadata?.bannerSettings?.vertical?.limit === null ||
        metadata?.bannerSettings?.vertical?.limit === "" ||
        metadata?.bannerSettings?.vertical?.limit === undefined ||
        metadata?.bannerSettings?.vertical?.repeatFrequency === null ||
        metadata?.bannerSettings?.vertical?.repeatFrequency === "" ||
        metadata?.bannerSettings?.vertical?.repeatFrequency === undefined
      ) {
        return true;
      }
    }
    return false;
  };

  const submit = async (event) => {
    event.preventDefault();
    formTextState["translations"] = translations;
    let inputsWithError = document.getElementsByClassName("is-invalid");
    if (inputsWithError.length === 0) {
      // iterate over formTextState metadata and filter bannerSettings
      formTextState.metadata.forEach((item, index) => {
        item.clientId = item.clientId.value;
        if (item?.bannerSettings) {
          if (item?.horizontal) {
            if (item?.bannerSettings?.horizontal?.titleAlignment) {
              item.bannerSettings.horizontal.titleAlignment =
                item.bannerSettings.horizontal.titleAlignment.value;
            }
          }

          if (item?.vertical) {
            if (item?.bannerSettings?.vertical?.titleAlignment) {
              item.bannerSettings.vertical.titleAlignment =
                item.bannerSettings.vertical.titleAlignment.value;
            }
          }

          // remove all the fields whose value is null or undefined or "" for horizontal
          if (item?.bannerSettings?.horizontal) {
            Object.keys(item?.bannerSettings?.horizontal).forEach(
              (key) =>
                (item.bannerSettings.horizontal[key] === null ||
                  item.bannerSettings.horizontal[key] === undefined ||
                  item.bannerSettings.horizontal[key] === "") &&
                delete item.bannerSettings.horizontal[key]
            );
          }

          // remove all the fields whose value is null or undefined or "" for vertical
          if (item?.bannerSettings?.vertical) {
            Object.keys(item?.bannerSettings?.vertical).forEach(
              (key) =>
                (item.bannerSettings.vertical[key] === null ||
                  item.bannerSettings.vertical[key] === undefined ||
                  item.bannerSettings.vertical[key] === "") &&
                delete item.bannerSettings.vertical[key]
            );
          }

          // convert string to int for horizontal (startIndex, repeatFrequency, limit)
          if (item?.bannerSettings?.horizontal?.startIndex)
            item.bannerSettings.horizontal.startIndex = parseInt(
              item.bannerSettings.horizontal.startIndex
            );

          if (item?.bannerSettings?.horizontal?.repeatFrequency)
            item.bannerSettings.horizontal.repeatFrequency = parseInt(
              item.bannerSettings.horizontal.repeatFrequency
            );

          if (item?.bannerSettings?.horizontal?.limit)
            item.bannerSettings.horizontal.limit = parseInt(
              item.bannerSettings.horizontal.limit
            );

          // convert string to int for vertical (startIndex, repeatFrequency, limit)
          if (item?.bannerSettings?.vertical?.startIndex)
            item.bannerSettings.vertical.startIndex = parseInt(
              item.bannerSettings.vertical.startIndex
            );

          if (item?.bannerSettings?.vertical?.repeatFrequency)
            item.bannerSettings.vertical.repeatFrequency = parseInt(
              item.bannerSettings.vertical.repeatFrequency
            );

          if (item?.bannerSettings?.vertical?.limit)
            item.bannerSettings.vertical.limit = parseInt(
              item.bannerSettings.vertical.limit
            );
        }
      });

      props.submit(formTextState);
    } else {
      setDisplayError(true);
      if (formTextState.categoryID.value === "") {
        setFormTextState((prev) => ({
          ...prev,
          categoryID: {
            value: formTextState.categoryID.value,
            valid: false,
          },
        }));
      }
    }
  };

  const submitPriority = async (event) => {
    event.preventDefault();

    // collect all the ids of adBanners in order
    let adBannersID = [];

    horizontalAdBanners.forEach((adBanner) => adBannersID.push(adBanner.id));
    verticalAdBanners.forEach((adBanner) => adBannersID.push(adBanner.id));

    let data = {
      adBanners: adBannersID,
    };

    props.submitPriority(data);
  };

  const getUniqueClientIdsWithName = () => {
    let uniqueClientIds = [];
    let uniqueClientIdsWithName = [];
    if (
      formTextState.metadata !== undefined &&
      formTextState.metadata !== null
    ) {
      formTextState.metadata.map((item) => {
        if (item.clientId) {
          uniqueClientIds.push(item.clientId.value);
        }
      });
    }

    uniqueClientIds = [...new Set(uniqueClientIds)];
    uniqueClientIds.map((item) => {
      if (clientOptions !== undefined && clientOptions !== null) {
        clientOptions.map((client) => {
          if (client.value === item) {
            uniqueClientIdsWithName.push(client);
          }
        });
      }
    });
    return uniqueClientIdsWithName;
  };

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">{props.category.formName}</h1>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Form
                onSubmit={(event) => {
                  submit(event);
                }}
              >
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        <strong>
                          Name <span className="text-danger">*</span>
                        </strong>
                        <p style={{ fontSize: 12, marginBottom: 0 }}>
                          Name of the quick reply
                        </p>
                      </Label>
                      <Input
                        type="text"
                        name="name"
                        defaultValue={props.category.name}
                        onChange={(e) => handleChange(e)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        <strong>Provider Name</strong>
                        <p style={{ fontSize: 12, marginBottom: 0 }}>
                          Name of the provider
                        </p>
                      </Label>
                      <Input
                        type="text"
                        name="providerName"
                        defaultValue={props.category.providerName}
                        onChange={(event) => handleChange(event)}
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        <strong>
                          View Type <span className="text-danger">*</span>
                        </strong>
                      </Label>
                      <Select
                        name="viewType"
                        className="react-select-container mb-4"
                        classNamePrefix="react-select"
                        options={quickRepliesPageType}
                        onChange={(event) => handleViewTypeChange(event)}
                        value={formTextState.viewType}
                        placeholder="Select a view type"
                        required={props.category.viewType === null}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        <strong>Priority</strong>
                        <small className="text-info">
                          (Enter integer value greater than 0)
                        </small>
                      </Label>
                      <Input
                        type="number"
                        name="priority"
                        defaultValue={props.category.priority.value}
                        value={formTextState.priority}
                        onChange={(event) => handlePriorityChange(event)}
                        required
                        disabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{ marginBottom: 10 }}>
                  <Col>
                    <strong>Translations</strong>
                  </Col>
                </Row>
                {translations
                  ? translations.map((translation) => {
                      return (
                        <Row key={translation.id}>
                          <Col md={4}>
                            <FormGroup>
                              <Label>Target Language</Label>
                              <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                name="languageCode"
                                placeholder="Select Languages"
                                options={languages}
                                defaultValue={translation.dropdownValue}
                                onChange={(option) =>
                                  handleTranslationChange(
                                    translation.id,
                                    null,
                                    option
                                  )
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <FormGroup>
                              <Label>Translation Text</Label>
                              <Input
                                type="text"
                                name="name"
                                value={translation.name}
                                placeholder="Translation"
                                onChange={(event) =>
                                  handleTranslationChange(
                                    translation.id,
                                    event,
                                    null
                                  )
                                }
                                required
                                disabled={!updateAllowed()}
                              />
                            </FormGroup>
                          </Col>
                          <Col
                            md={2}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              onClick={() =>
                                handleRemoveTranslation(translation.id)
                              }
                              color="transparent"
                            >
                              <FaMinus />
                            </Button>
                          </Col>
                        </Row>
                      );
                    })
                  : {}}
                <Row>
                  <Col>
                    <Button
                      onClick={handleAddTranslation}
                      disabled={!updateAllowed()}
                    >
                      Add Translations
                    </Button>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />

                <Row className="mt-6">
                  <Col md={6}>
                    <h1 className="h3 mb-3">MetaData</h1>
                  </Col>
                  <Col md={6}>
                    <div
                      className="float-right"
                      onClick={() => {
                        setShareURLToggle(!shareURLToggle);
                      }}
                    >
                      {shareURLToggle ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                    <Button
                      onClick={addMetadata}
                      className="float-right mr-2"
                      disabled={!updateAllowed()}
                    >
                      <FontAwesomeIcon size="sm" icon={faPlus} /> Add MetaData
                    </Button>
                  </Col>
                </Row>

                {(showShareURLSection || showShareURLSectionDetails) && (
                  <Card 
                  style={{
                    boxShadow: "0 0 20px rgba(0,0,0,0.4)", // Increased shadow
                    borderRadius: "15px",
                  }}>
                    <CardBody>
                      <Container fluid className="p-0">
                        <Row>
                          <Col>
                            <h1 className="h3 mb-3">
                              
                              <X
                                className="float-right"
                                size={20}
                                color="red"
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  setShowShareURLSection(false);
                                  setShowShareURLSectionDetails(false);
                                  setShareURLIndexForEdit(null);
                                  setMetadata({
                                    shareURL: "",
                                    clientId: null,
                                    horizontal: false,
                                    vertical: false,
                                    bannerSettings: {
                                      horizontal: {
                                        startIndex: null,
                                        repeatFrequency: null,
                                        limit: null,
                                        lightThemeBackgroundColor: "",
                                        darkThemeBackgroundColor: "",
                                        lightThemeTitleColor: "",
                                        darkThemeTitleColor: "",
                                        title: "",
                                        titleAlignment: "",
                                      },
                                      vertical: {
                                        startIndex: null,
                                        repeatFrequency: null,
                                        limit: null,
                                        lightThemeBackgroundColor: "",
                                        darkThemeBackgroundColor: "",
                                        lightThemeTitleColor: "",
                                        darkThemeTitleColor: "",
                                        title: "",
                                        titleAlignment: "",
                                      },
                                    },
                                  });
                                }}
                              ></X>
                            </h1>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <Label>
                                <strong>
                                  Share URL{" "}
                                  <span className="text-danger">*</span>
                                </strong>
                              </Label>
                              <Input
                                type="text"
                                name="shareURL"
                                value={metadata.shareURL}
                                placeholder="Enter Share URL"
                                disabled={showShareURLSectionDetails}
                                onChange={(e) => {
                                  const { name, value } = e.target;
                                  setMetadata((prev) => ({
                                    ...prev,
                                    [name]: value,
                                  }));
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label>
                                <strong>
                                  Client Id{" "}
                                  <span className="text-danger">*</span>
                                </strong>
                              </Label>
                              <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                name="clientId"
                                value={metadata.clientId}
                                placeholder="Select Client Id"
                                isClearable={false}
                                options={
                                  // remove the client ids which are already present in the formTextState.metadata
                                  clientOptions?.filter((client) => {
                                    let isPresent = false;
                                    formTextState.metadata.forEach(
                                      (item, index) => {
                                        if (item.clientId) {
                                          if (
                                            item.clientId.value === client.value
                                          ) {
                                            isPresent = true;
                                          }
                                        }
                                      }
                                    );
                                    return !isPresent;
                                  })
                                }
                                isDisabled={showShareURLSectionDetails}
                                onChange={(option) => {
                                  setMetadata({
                                    ...metadata,
                                    clientId: option,
                                  });
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: 15 }}>
                          <Col md={12}>
                            <h3>Banner Settings</h3>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={4}>
                            <FormGroup>
                              <Label>
                                <strong>Horizontal</strong>
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <div className="custom-control custom-switch">
                              <CustomInput
                                type="checkbox"
                                id="horizontal"
                                value={metadata.horizontal === true}
                                onChange={(event) =>
                                  handleCheckBoxChange(event)
                                }
                                disabled={showShareURLSectionDetails}
                                checked={metadata.horizontal === true}
                              />
                            </div>
                          </Col>
                        </Row>

                        {metadata.horizontal && (
                          <>
                            <Row>
                              <Col md={4}>
                                <FormGroup>
                                  <Label>
                                    <strong>Start Index </strong>
                                    <small className="text-warning">
                                      (Mandatory for Bobble){" "}
                                    </small>
                                  </Label>
                                  <Input
                                    type="number"
                                    name="startIndex"
                                    placeholder="Enter Start Index"
                                    value={
                                      metadata.bannerSettings?.horizontal
                                        ?.startIndex
                                    }
                                    onChange={(event) => {
                                      const { name, value } = event.target;
                                      setMetadata((prev) => ({
                                        ...prev,
                                        bannerSettings: {
                                          ...prev?.bannerSettings,
                                          horizontal: {
                                            ...prev?.bannerSettings?.horizontal,
                                            [name]: value,
                                          },
                                        },
                                      }));
                                    }}
                                    disabled={
                                      !updateAllowed() ||
                                      showShareURLSectionDetails
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={4}>
                                <FormGroup>
                                  <Label>
                                    <strong>
                                      Repeat Frequency{" "}
                                      <span className="text-danger">*</span>
                                    </strong>
                                  </Label>
                                  <Input
                                    type="number"
                                    name="repeatFrequency"
                                    placeholder="Enter Repeat Frequency"
                                    value={
                                      metadata?.bannerSettings?.horizontal
                                        ?.repeatFrequency
                                    }
                                    onChange={(event) => {
                                      const { name, value } = event.target;
                                      setMetadata((prev) => ({
                                        ...prev,
                                        bannerSettings: {
                                          ...prev.bannerSettings,
                                          horizontal: {
                                            ...prev.bannerSettings.horizontal,
                                            [name]: value,
                                          },
                                        },
                                      }));
                                    }}
                                    disabled={
                                      !updateAllowed() ||
                                      showShareURLSectionDetails
                                    }
                                    required
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={4}>
                                <FormGroup>
                                  <Label>
                                    <strong>
                                      Limit{" "}
                                      <span className="text-danger">*</span>
                                    </strong>
                                  </Label>
                                  <Input
                                    type="number"
                                    name="limit"
                                    placeholder="Enter Limit"
                                    value={
                                      metadata.bannerSettings?.horizontal?.limit
                                    }
                                    onChange={(event) => {
                                      const { name, value } = event.target;
                                      setMetadata((prev) => ({
                                        ...prev,
                                        bannerSettings: {
                                          ...prev.bannerSettings,
                                          horizontal: {
                                            ...prev.bannerSettings.horizontal,
                                            [name]: value,
                                          },
                                        },
                                      }));
                                    }}
                                    disabled={
                                      !updateAllowed() ||
                                      showShareURLSectionDetails
                                    }
                                    required
                                  />
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={6}>
                                <FormGroup>
                                  <Label>
                                    <strong>
                                      Light Theme Background Color:{" "}
                                      <small className="text-warning">
                                        (Mandatory for Bobble){" "}
                                      </small>
                                      {validateColor(
                                        metadata.bannerSettings?.horizontal
                                          ?.lightThemeBackgroundColor
                                      ) ? (
                                        <div
                                          style={{
                                            backgroundColor:
                                            metadata.bannerSettings
                                                ?.horizontal
                                                ?.lightThemeBackgroundColor,
                                            width: "16px",
                                            height: "16px",
                                            display: "inline-block",
                                            verticalAlign: "middle",
                                            border: "1px solid #000",
                                            borderRadius: "30%",
                                          }}
                                        >
                                          {" "}
                                        </div>
                                      ) : (
                                        <small className="text-danger ml-2">
                                          {"Invalid Hex Code"}
                                        </small>
                                      )}
                                    </strong>
                                  </Label>
                                  <Input
                                    type="text"
                                    className={
                                      validateColor(
                                        metadata.bannerSettings?.horizontal
                                          ?.lightThemeBackgroundColor
                                      ) ? "form-control is-valid"
                                      : "form-control is-invalid"
                                    }
                                    name="lightThemeBackgroundColor"
                                    placeholder="Enter Light Theme Background Color"
                                    value={
                                      metadata.bannerSettings?.horizontal
                                        ?.lightThemeBackgroundColor
                                    }
                                    onChange={(event) => {
                                      const { name, value } = event.target;
                                      setMetadata((prev) => ({
                                        ...prev,
                                        bannerSettings: {
                                          ...prev.bannerSettings,
                                          horizontal: {
                                            ...(prev.bannerSettings
                                              ?.horizontal || {}), // Ensure horizontal is an object
                                            [name]: value,
                                          },
                                        },
                                      }));
                                    }}
                                    disabled={
                                      !updateAllowed() ||
                                      showShareURLSectionDetails
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label>
                                    <strong>
                                      Dark Theme Background Color:{" "}
                                      <small className="text-warning">
                                        (Mandatory for Bobble){" "}
                                      </small>
                                      {validateColor(
                                        metadata.bannerSettings?.horizontal
                                          ?.darkThemeBackgroundColor
                                      ) ? (
                                        <div
                                          style={{
                                            backgroundColor:
                                            metadata.bannerSettings
                                                ?.horizontal
                                                ?.darkThemeBackgroundColor,
                                            width: "16px",
                                            height: "16px",
                                            display: "inline-block",
                                            verticalAlign: "middle",
                                            border: "1px solid #000",
                                            borderRadius: "30%",
                                          }}
                                        >
                                          {" "}
                                        </div>
                                      ) : (
                                        <small className="text-danger ml-2">
                                          {"Invalid Hex Code"}
                                        </small>
                                      )}
                                    </strong>
                                  </Label>
                                  <Input
                                    type="text"
                                    name="darkThemeBackgroundColor"
                                    className={
                                      validateColor(
                                        metadata.bannerSettings?.horizontal
                                          ?.darkThemeBackgroundColor
                                      ) ? "form-control is-valid"
                                      : "form-control is-invalid"
                                    }
                                    value={
                                      metadata.bannerSettings?.horizontal
                                        ?.darkThemeBackgroundColor
                                    }
                                    placeholder="Enter Dark Background Color"
                                    onChange={(event) => {
                                      const { name, value } = event.target;
                                      setMetadata((prev) => ({
                                        ...prev,
                                        bannerSettings: {
                                          ...prev.bannerSettings,
                                          horizontal: {
                                            ...prev.bannerSettings.horizontal,
                                            [name]: value,
                                          },
                                        },
                                      }));
                                    }}
                                    disabled={
                                      !updateAllowed() ||
                                      showShareURLSectionDetails
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label>
                                    <strong>
                                      Light Theme Title Color:{" "}
                                      <small className="text-warning">
                                        (Mandatory for Bobble){" "}
                                      </small>
                                      {validateColor(
                                        metadata.bannerSettings?.horizontal
                                          ?.lightThemeTitleColor
                                      ) ? (
                                        <div
                                          style={{
                                            backgroundColor:
                                            metadata.bannerSettings
                                                ?.horizontal
                                                ?.lightThemeTitleColor,
                                            width: "16px",
                                            height: "16px",
                                            display: "inline-block",
                                            verticalAlign: "middle",
                                            border: "1px solid #000",
                                            borderRadius: "30%",
                                          }}
                                        >
                                          {" "}
                                        </div>
                                      ) : (
                                        <small className="text-danger ml-2">
                                          {"Invalid Hex Code"}
                                        </small>
                                      )}
                                    </strong>
                                  </Label>
                                  <Input
                                    type="text"
                                    className={
                                      validateColor(
                                        metadata.bannerSettings?.horizontal
                                          ?.lightThemeTitleColor
                                      ) ? "form-control is-valid"
                                      : "form-control is-invalid"
                                    }
                                    name="lightThemeTitleColor"
                                    placeholder="Enter Light Theme Title Color"
                                    value={
                                      metadata.bannerSettings?.horizontal
                                        ?.lightThemeTitleColor
                                    }
                                    onChange={(event) => {
                                      const { name, value } = event.target;
                                      setMetadata((prev) => ({
                                        ...prev,
                                        bannerSettings: {
                                          ...prev.bannerSettings,
                                          horizontal: {
                                            ...(prev.bannerSettings
                                              ?.horizontal || {}), // Ensure horizontal is an object
                                            [name]: value,
                                          },
                                        },
                                      }));
                                    }}
                                    disabled={
                                      !updateAllowed() ||
                                      showShareURLSectionDetails
                                    }
                                  />
                                </FormGroup>
                              </Col>

                              <Col md={6}>
                                <FormGroup>
                                  <Label>
                                    <strong>
                                      Dark Theme Title Color:{" "}
                                      <small className="text-warning">
                                        (Mandatory for Bobble){" "}
                                      </small>
                                      {validateColor(
                                        metadata.bannerSettings?.horizontal
                                          ?.darkThemeTitleColor
                                      ) ? (
                                      <div
                                        style={{
                                          backgroundColor:
                                          metadata.bannerSettings?.horizontal
                                              ?.darkThemeTitleColor,
                                          width: "16px",
                                          height: "16px",
                                          display: "inline-block",
                                          verticalAlign: "middle",
                                          border: "1px solid #000",
                                          borderRadius: "30%",
                                        }}
                                      >
                                        {" "}
                                      </div> 
                                      ) : (
                                        <small className="text-danger ml-2">
                                          {"Invalid Hex Code"}
                                        </small>
                                      )}
                                    </strong>
                                  </Label>
                                  <Input
                                    type="text"
                                    name="darkThemeTitleColor"
                                    className={
                                      validateColor(
                                        metadata.bannerSettings?.horizontal
                                          ?.darkThemeTitleColor
                                      ) ? "form-control is-valid"
                                      : "form-control is-invalid"
                                    }
                                    value={
                                      metadata.bannerSettings?.horizontal
                                        ?.darkThemeTitleColor
                                    }
                                    placeholder="Enter Dark Title Color"
                                    onChange={(event) => {
                                      const { name, value } = event.target;
                                      setMetadata((prev) => ({
                                        ...prev,
                                        bannerSettings: {
                                          ...prev.bannerSettings,
                                          horizontal: {
                                            ...prev.bannerSettings.horizontal,
                                            [name]: value,
                                          },
                                        },
                                      }));
                                    }}
                                    disabled={
                                      !updateAllowed() ||
                                      showShareURLSectionDetails
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={6}>
                                <FormGroup>
                                  <Label>
                                    <strong>Title </strong>
                                    <small className="text-warning">
                                      (Mandatory for Bobble){" "}
                                    </small>
                                  </Label>
                                  <Input
                                    type="text"
                                    name="title"
                                    placeholder="Enter Title"
                                    value={
                                      metadata.bannerSettings?.horizontal?.title
                                    }
                                    onChange={(event) => {
                                      const { name, value } = event.target;
                                      setMetadata((prev) => ({
                                        ...prev,
                                        bannerSettings: {
                                          ...prev.bannerSettings,
                                          horizontal: {
                                            ...prev.bannerSettings.horizontal,
                                            [name]: value,
                                          },
                                        },
                                      }));
                                    }}
                                    disabled={
                                      !updateAllowed() ||
                                      showShareURLSectionDetails
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label>
                                    <strong>Title Alignment </strong>
                                    <small className="text-warning">
                                      (Mandatory for Bobble){" "}
                                    </small>
                                  </Label>
                                  <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    name="titleAlignment"
                                    options={titleAlignmentOptions}
                                    placeholder="Enter Title Alignment"
                                    value={
                                      metadata.bannerSettings?.horizontal
                                        ?.titleAlignment
                                    }
                                    onChange={(event) => {
                                      setMetadata((prev) => ({
                                        ...prev,
                                        bannerSettings: {
                                          ...prev.bannerSettings,
                                          horizontal: {
                                            ...prev.bannerSettings.horizontal,
                                            titleAlignment: event,
                                          },
                                        },
                                      }));
                                    }}
                                    isDisabled={
                                      !updateAllowed() ||
                                      showShareURLSectionDetails
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </>
                        )}

                        {/* Vertical */}
                        <Row>
                          <Col md={4}>
                            <FormGroup>
                              <Label>
                                <strong>Vertical</strong>
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <div className="custom-control custom-switch">
                              <CustomInput
                                type="checkbox"
                                id="vertical"
                                value={metadata.vertical === true}
                                onChange={(event) =>
                                  handleCheckBoxChange(event)
                                }
                                disabled={showShareURLSectionDetails}
                                checked={metadata.vertical === true}
                              />
                            </div>
                          </Col>
                        </Row>

                        {metadata.vertical && (
                          <>
                            <Row>
                              <Col md={4}>
                                <FormGroup>
                                  <Label>
                                    <strong>Start Index </strong>
                                    <small className="text-warning">
                                      (Mandatory for Bobble){" "}
                                    </small>
                                  </Label>
                                  <Input
                                    type="number"
                                    name="startIndex"
                                    placeholder="Enter Start Index"
                                    value={
                                      metadata?.bannerSettings?.vertical
                                        ?.startIndex
                                    }
                                    onChange={(event) => {
                                      const { name, value } = event.target;
                                      setMetadata((prev) => ({
                                        ...prev,
                                        bannerSettings: {
                                          ...prev?.bannerSettings,
                                          vertical: {
                                            ...prev?.bannerSettings?.vertical,
                                            [name]: value,
                                          },
                                        },
                                      }));
                                    }}
                                    disabled={
                                      !updateAllowed() ||
                                      showShareURLSectionDetails
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={4}>
                                <FormGroup>
                                  <Label>
                                    <strong>
                                      Repeat Frequency{" "}
                                      <span className="text-danger">*</span>
                                    </strong>
                                  </Label>
                                  <Input
                                    type="number"
                                    name="repeatFrequency"
                                    placeholder="Enter Repeat Frequency"
                                    value={
                                      metadata.bannerSettings?.vertical
                                        ?.repeatFrequency
                                    }
                                    onChange={(event) => {
                                      const { name, value } = event.target;
                                      setMetadata((prev) => ({
                                        ...prev,
                                        bannerSettings: {
                                          ...prev.bannerSettings,
                                          vertical: {
                                            ...prev.bannerSettings.vertical,
                                            [name]: value,
                                          },
                                        },
                                      }));
                                    }}
                                    disabled={
                                      !updateAllowed() ||
                                      showShareURLSectionDetails
                                    }
                                    required
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={4}>
                                <FormGroup>
                                  <Label>
                                    <strong>
                                      Limit{" "}
                                      <span className="text-danger">*</span>
                                    </strong>
                                  </Label>
                                  <Input
                                    type="number"
                                    name="limit"
                                    placeholder="Enter Limit"
                                    value={
                                      metadata?.bannerSettings?.vertical?.limit
                                    }
                                    onChange={(event) => {
                                      const { name, value } = event.target;
                                      setMetadata((prev) => ({
                                        ...prev,
                                        bannerSettings: {
                                          ...prev.bannerSettings,
                                          vertical: {
                                            ...prev.bannerSettings.vertical,
                                            [name]: value,
                                          },
                                        },
                                      }));
                                    }}
                                    disabled={
                                      !updateAllowed() ||
                                      showShareURLSectionDetails
                                    }
                                    required
                                  />
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={6}>
                                <FormGroup>
                                  <Label>
                                    <strong>
                                      Light Theme Background Color: :
                                      <small className="text-warning">
                                        (Mandatory for Bobble){" "}
                                      </small>
                                      {validateColor(
                                        metadata.bannerSettings?.vertical
                                          ?.lightThemeBackgroundColor
                                      ) ? (
                                        <div
                                          style={{
                                            backgroundColor:
                                              metadata.bannerSettings
                                                ?.vertical
                                                ?.lightThemeBackgroundColor,
                                            width: "16px",
                                            height: "16px",
                                            display: "inline-block",
                                            verticalAlign: "middle",
                                            border: "1px solid #000",
                                            borderRadius: "30%",
                                          }}
                                        >
                                          {" "}
                                        </div>
                                      ) : (
                                        <small className="text-danger ml-2">
                                          {"Invalid Hex Code"}
                                        </small>
                                      )}
                                    </strong>
                                  </Label>
                                  <Input
                                    type="text"
                                    name="lightThemeBackgroundColor"
                                    className={
                                      validateColor(
                                        metadata.bannerSettings?.vertical
                                          ?.lightThemeBackgroundColor
                                      ) ? "form-control is-valid"
                                      : "form-control is-invalid"
                                    }
                                    placeholder="Enter Light Theme Background Color"
                                    value={
                                      metadata.bannerSettings?.vertical
                                        ?.lightThemeBackgroundColor
                                    }
                                    onChange={(event) => {
                                      const { name, value } = event.target;
                                      setMetadata((prev) => ({
                                        ...prev,
                                        bannerSettings: {
                                          ...prev.bannerSettings,
                                          vertical: {
                                            ...(prev.bannerSettings?.vertical ||
                                              {}), // Ensure horizontal is an object
                                            [name]: value,
                                          },
                                        },
                                      }));
                                    }}
                                    disabled={
                                      !updateAllowed() ||
                                      showShareURLSectionDetails
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label>
                                    <strong>
                                      Dark Theme Background Color:{" "}
                                      <small className="text-warning">
                                        (Mandatory for Bobble){" "}
                                      </small>
                                      {validateColor(
                                        metadata.bannerSettings?.vertical
                                          ?.darkThemeBackgroundColor
                                      ) ? (
                                      <div
                                        style={{
                                          backgroundColor:
                                            metadata.bannerSettings?.vertical
                                              ?.darkThemeBackgroundColor,
                                          width: "16px",
                                          height: "16px",
                                          display: "inline-block",
                                          verticalAlign: "middle",
                                          border: "1px solid #000",
                                          borderRadius: "30%",
                                        }}
                                      >
                                        {" "}
                                      </div>
                                      ) : (
                                        <small className="text-danger ml-2">
                                          {"Invalid Hex Code"}
                                        </small>
                                      )}
                                    </strong>
                                  </Label>
                                  <Input
                                    type="text"
                                    name="darkThemeBackgroundColor"
                                    className={
                                      validateColor(
                                        metadata.bannerSettings?.vertical 
                                          ?.darkThemeBackgroundColor
                                      ) ? "form-control is-valid"
                                      : "form-control is-invalid"
                                    }
                                    placeholder="Enter Dark Theme Background Color"
                                    value={
                                      metadata.bannerSettings?.vertical
                                        ?.darkThemeBackgroundColor
                                    }
                                    onChange={(event) => {
                                      const { name, value } = event.target;
                                      setMetadata((prev) => ({
                                        ...prev,
                                        bannerSettings: {
                                          ...prev.bannerSettings,
                                          vertical: {
                                            ...prev.bannerSettings.vertical,
                                            [name]: value,
                                          },
                                        },
                                      }));
                                    }}
                                    disabled={
                                      !updateAllowed() ||
                                      showShareURLSectionDetails
                                    }
                                  />
                                </FormGroup>
                              </Col>

                              <Col md={6}>
                                <FormGroup>
                                  <Label>
                                    <strong>
                                      Light Theme Title Color:{" "}
                                      <small className="text-warning">
                                        (Mandatory for Bobble){" "}
                                      </small>
                                      {validateColor(
                                        metadata.bannerSettings?.vertical
                                          ?.lightThemeTitleColor
                                      ) ? (
                                        <div
                                          style={{
                                            backgroundColor:
                                              metadata.bannerSettings
                                                ?.vertical
                                                ?.lightThemeTitleColor,
                                            width: "16px",
                                            height: "16px",
                                            display: "inline-block",
                                            verticalAlign: "middle",
                                            border: "1px solid #000",
                                            borderRadius: "30%",
                                          }}
                                        >
                                          {" "}
                                        </div>
                                      ) : (
                                        <small className="text-danger ml-2">
                                          {"Invalid Hex Code"}
                                        </small>
                                      )}
                                    </strong>
                                  </Label>
                                  <Input
                                    type="text"
                                    name="lightThemeTitleColor"
                                    className={
                                      validateColor(
                                        metadata.bannerSettings?.vertical
                                          ?.lightThemeTitleColor
                                      ) ? "form-control is-valid"
                                      : "form-control is-invalid"
                                    }
                                    placeholder="Enter Light Theme Title Color"
                                    value={
                                      metadata.bannerSettings?.vertical
                                        ?.lightThemeTitleColor
                                    }
                                    onChange={(event) => {
                                      const { name, value } = event.target;
                                      setMetadata((prev) => ({
                                        ...prev,
                                        bannerSettings: {
                                          ...(prev.bannerSettings || {}), // Ensure horizontal is an object
                                          vertical: {
                                            ...prev.bannerSettings.vertical,
                                            [name]: value,
                                          },
                                        },
                                      }));
                                    }}
                                    disabled={
                                      !updateAllowed() ||
                                      showShareURLSectionDetails
                                    }
                                  />
                                </FormGroup>
                              </Col>

                              <Col md={6}>
                                <FormGroup>
                                  <Label>
                                    <strong>
                                      Dark Theme Title Color:{" "}
                                      <small className="text-warning">
                                        (Mandatory for Bobble){" "}
                                      </small>
                                      {validateColor(
                                        metadata.bannerSettings?.vertical
                                          ?.darkThemeTitleColor
                                      ) ? (
                                      <div
                                        style={{
                                          backgroundColor:
                                            metadata.bannerSettings?.vertical
                                              ?.darkThemeTitleColor,
                                          width: "16px",
                                          height: "16px",
                                          display: "inline-block",
                                          verticalAlign: "middle",
                                          border: "1px solid #000",
                                          borderRadius: "30%",
                                        }}
                                      >
                                        {" "}
                                      </div>
                                      ) : (
                                        <small className="text-danger ml-2">
                                          {"Invalid Hex Code"}
                                        </small>
                                      )}
                                    </strong>
                                  </Label>
                                  <Input
                                    type="text"
                                    name="darkThemeTitleColor"
                                    className={
                                      validateColor(
                                        metadata.bannerSettings?.vertical
                                          ?.darkThemeTitleColor
                                      ) ? "form-control is-valid"
                                      : "form-control is-invalid"
                                    }
                                    placeholder="Enter Dark Theme Title Color"
                                    value={
                                      metadata.bannerSettings?.vertical
                                        ?.darkThemeTitleColor
                                    }
                                    onChange={(event) => {
                                      const { name, value } = event.target;
                                      setMetadata((prev) => ({
                                        ...prev,
                                        bannerSettings: {
                                          ...(prev.bannerSettings || {}), // Ensure horizontal is an object
                                          vertical: {
                                            ...prev.bannerSettings.vertical,
                                            [name]: value,
                                          },
                                        },
                                      }));
                                    }}
                                    disabled={
                                      !updateAllowed() ||
                                      showShareURLSectionDetails
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={6}>
                                <FormGroup>
                                  <Label>
                                    <strong>Title </strong>
                                    <small className="text-warning">
                                      (Mandatory for Bobble){" "}
                                    </small>
                                  </Label>
                                  <Input
                                    type="text"
                                    name="title"
                                    placeholder="Enter Title"
                                    value={
                                      metadata.bannerSettings?.vertical?.title
                                    }
                                    onChange={(event) => {
                                      const { name, value } = event.target;
                                      setMetadata((prev) => ({
                                        ...prev,
                                        bannerSettings: {
                                          ...prev.bannerSettings,
                                          vertical: {
                                            ...prev.bannerSettings.vertical,
                                            [name]: value,
                                          },
                                        },
                                      }));
                                    }}
                                    disabled={
                                      !updateAllowed() ||
                                      showShareURLSectionDetails
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label>
                                    <strong>Title Alignment </strong>
                                    <small className="text-warning">
                                      (Mandatory for Bobble){" "}
                                    </small>
                                  </Label>
                                  <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    name="titleAlignment"
                                    options={titleAlignmentOptions}
                                    placeholder="Enter Title Alignment"
                                    value={
                                      metadata.bannerSettings?.vertical
                                        ?.titleAlignment
                                    }
                                    onChange={(event) => {
                                      setMetadata((prev) => ({
                                        ...prev,
                                        bannerSettings: {
                                          ...prev.bannerSettings,
                                          vertical: {
                                            ...prev.bannerSettings.vertical,
                                            titleAlignment: event,
                                          },
                                        },
                                      }));
                                    }}
                                    isDisabled={
                                      !updateAllowed() ||
                                      showShareURLSectionDetails
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </>
                        )}

                        {!showShareURLSectionDetails && (
                          <Row>
                            <Col md={12}>
                              <Button
                                className="btn-success float-right btn-primary"
                                onClick={() => {
                                  saveMetadata();
                                }}
                                disabled={checkShareTextValidity()}
                              >
                                Save
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </Container>
                    </CardBody>
                  </Card>
                )}

                <Collapse isOpen={shareURLToggle}>
                  <Card>
                    <CardBody>
                      {getUniqueClientIdsWithName()?.map((client, index) => (
                        <>
                          <Row key={`row-${index + 6}`}>
                            <Col md={6}>
                              <h1 className="h3 mb-3">{client?.label}</h1>
                            </Col>
                          </Row>

                          <Row key={`row-${index + 7}`}>
                            {formTextState?.metadata?.map((item, index) => {
                              return (
                                item?.clientId?.value === client.value && (
                                  <Col md={6}>
                                    <Card
                                      key={`card-${index + 6}`}
                                      className="p-3 mb-5"
                                      style={{
                                        boxShadow: "0 0 20px rgba(0,0,0,0.4)", // Increased shadow
                                        borderRadius: "15px",
                                      }}
                                    >
                                      <CardBody>
                                        <h5>
                                          Share URL: {item.shareURL}
                                          {updateAllowed() && (
                                            <X
                                              className="float-right"
                                              size={20}
                                              color="red"
                                              style={{ cursor: "pointer" }}
                                              onClick={(e) => {
                                                let temp = [
                                                  ...formTextState.metadata,
                                                ];
                                                temp = temp.filter(
                                                  (_, idx) => idx !== index
                                                );
                                                setFormTextState((prev) => ({
                                                  ...prev,
                                                  metadata: temp,
                                                }));
                                              }}
                                            ></X>
                                          )}
                                        </h5>
                                        <p>
                                          {item.horizontal || item.vertical
                                            ? "Banner Settings : ..."
                                            : ""}
                                        </p>
                                      </CardBody>
                                      <Row>
                                        <Col
                                          md={4}
                                          className="d-flex justify-content-center"
                                        >
                                          <Button
                                            className="btn-block btn-sm"
                                            color="primary"
                                            onClick={(e) => {
                                              setShowShareURLSectionDetails(
                                                true
                                              );
                                              setMetadata(item);
                                              setShowShareURLSection(true);
                                            }}
                                          >
                                            View Details
                                          </Button>
                                        </Col>
                                        <Col
                                          md={4}
                                          className="d-flex justify-content-center"
                                        >
                                          <Button
                                            className="btn-block btn-sm"
                                            disabled={!updateAllowed()}
                                            color="success"
                                            onClick={(e) => {
                                              setShowShareURLSectionDetails(
                                                false
                                              );
                                              setMetadata(item);
                                              setShowShareURLSection(true);
                                              setShareURLIndexForEdit(null);
                                            }}
                                          >
                                            Duplicate
                                          </Button>
                                        </Col>
                                        <Col
                                          md={4}
                                          className="d-flex justify-content-center"
                                        >
                                          <Button
                                            className="btn-block btn-sm"
                                            disabled={!updateAllowed()}
                                            color="info"
                                            onClick={(e) => {
                                              setShowShareURLSectionDetails(
                                                false
                                              );
                                              setMetadata(item);
                                              setShowShareURLSection(true);
                                              setShareURLIndexForEdit(index);
                                            }}
                                          >
                                            Edit
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Col>
                                )
                              );
                            })}
                          </Row>
                        </>
                      ))}
                    </CardBody>
                  </Card>
                </Collapse>

                {displayError ? (
                  <p className="text-danger mb-0">
                    Please enter all valid details.
                  </p>
                ) : null}
                <br />
                {props.updateAllowed ? (
                  <Button color="primary" type="submit" className="mt-2" size="lg">
                    Submit
                  </Button>
                ) : (
                  <div />
                )}
              </Form>
            </CardBody>
          </Card>

          {props.category.adBanners.length !== 0 && priorityAllowed() && (
            <>
              <h1 className="h3 mb-3">Ad Banners Priority</h1>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>
                          <strong>Horizontal</strong>
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Reorder
                      reorderId="reorder-horizontal-list"
                      onReorder={reOrderHorizontalAdBanner}
                    >
                      {horizontalAdBanners.map((adBanner, index) => {
                        return (
                          <div
                            key={adBanner.id}
                            style={{
                              display: "inline-block",
                            }}
                          >
                            <Card
                              className="m-3 responseCard"
                              style={{
                                cursor: "pointer",
                                width: "200px",
                                height: "100px",
                              }}
                            >
                              <CardImg
                                src={adBanner.bannerImageURL}
                                style={{
                                  height: "100%", // Maintain the image's original aspect ratio
                                  width: "100%",
                                }}
                              />
                            </Card>
                          </div>
                        );
                      })}
                    </Reorder>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>
                          <strong>Vertical</strong>
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Reorder
                      reorderId="reorder-vertical-list"
                      onReorder={reOrderVerticalAdBanner}
                    >
                      {verticalAdBanners.map((adBanner, index) => {
                        return (
                          <div
                            key={adBanner.id}
                            style={{
                              display: "inline-block",
                            }}
                          >
                            <Card
                              className="m-3 responseCard"
                              style={{
                                cursor: "pointer",
                                width: "200px",
                                height: "100px",
                              }}
                            >
                              <CardImg
                                src={adBanner.bannerImageURL}
                                style={{
                                  height: "100%", // Maintain the image's original aspect ratio
                                  width: "100%",
                                }}
                              />
                            </Card>
                          </div>
                        );
                      })}
                    </Reorder>
                  </Row>

                  <Button
                    color="primary"
                    className="mt-2"
                    onClick={submitPriority}
                  >
                    Submit
                  </Button>
                </CardBody>
              </Card>
            </>
          )}
        </Col>
      </Row>
      <ResponseModal
        show={failureModalDisplay}
        onHide={() => failureModalClose()}
        modalheading={failureModalHeading}
        modaltext={failureModalText}
      />
    </Container>
  );
};

export default QuickReplyCategoryForm;

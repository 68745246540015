import React, { useState } from "react";
import PopTextStyleFormContainer from "./PopTextStyleFormContainer.js";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { permissions } from "../../../utilities/commonUtil";

const CreatePopTextStyle = (props) => {
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  const createAllowed = () => {
    return allowedPermissions.includes(permissions.popTextStyleCreate);
  };

  return (
    <PopTextStyleFormContainer
      {...props}
      formName={"Create Pop Text Style"}
      updateAllowed={createAllowed()}
    />
  );
};

export default CreatePopTextStyle;

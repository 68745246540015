import { retrieveFromLocalStorage } from "../localStorageUtil";
import { secureFetch } from "../secureFetch";
import { host } from "./index";

export let getSystemPrompts = async (params) => {
    let token = retrieveFromLocalStorage("token");
    let url = `${host}/v4/internal/chatAssistant/systemPrompts?` + new URLSearchParams(params);
    try {
        var response = await secureFetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await response.json();
    } catch (error) {
        return;
    }
}

export let activateSystemPrompt = async (id) => {
    let token = retrieveFromLocalStorage("token");
    let url = `${host}/v4/internal/chatAssistant/systemPrompts/${id}/activate`;
    try {
        let response = await secureFetch(url, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': "application/json"
            },
        });
        return await response.json();
    }
    catch (error) {
        return;
    }
}

export let deactivateSystemPrompt = async (id) => {
    let token = retrieveFromLocalStorage("token");
    let url = `${host}/v4/internal/chatAssistant/systemPrompts/${id}/deactivate`;
    try {
        let response = await secureFetch(url, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': "application/json"
            },
        });
        return await response.json();
    }
    catch (error) {
        return;
    }
}

export let createSystemPrompt = async (data) => {
    let url = `${host}/v4/internal/chatAssistant/systemPrompts`;
    let token = retrieveFromLocalStorage("token");
    try {
        let response = await secureFetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return await response.json();
    } catch (error) {
        return error?.json();
    }
};
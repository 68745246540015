import { retrieveFromLocalStorage } from "../localStorageUtil";
import { host } from "../apiUtils/index";
import { secureFetch } from "../secureFetch";

export let fetchPlacementsByClientId = async (clientId) => {
  let url = `${host}/v1/internal/adx/placements?clientId=${clientId}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchAllProviders = async () => {
  let url = `${host}/v1/internal/adx/serverAdVendors`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchProvidersByPlacementId = async (placementId) => {
  let url = `${host}/v1/internal/adx/providers?placementId=${placementId}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchAdsWithProviderRevenueType = async (providerId, revenueType) => {
  let url = `${host}/v4/internal/ads?partner=${providerId}&revenueModel=${revenueType}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchRevenueMetricsData = async ({ postbackType = "v1", dataSource = "mysql", startDate, endDate, date, adType, clientId, placementId, providerId, adId }) => {
  if (dataSource === "mysql") {
    startDate = startDate + " 00:00:00"
    endDate = endDate + " 23:59:59"
  }
  let url = `${host}/v1/internal/revenue/metrics?postbackType=${postbackType}&dataSource=${dataSource}&startDate=${startDate}&date=${date}&endDate=${endDate}&adType=${adType}&clientId=${clientId}&placementId=${placementId}&providerId=${providerId}&adId=${adId}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchRevenueGraphData = async ({ postbackType = "v1", dataSource = "mysql", startDate, endDate, date, adType, clientId, placementId, providerId, adId, graphGranularity }) => {
  if (dataSource === "mysql") {
    startDate = startDate + " 00:00:00"
    endDate = endDate + " 23:59:59"
  }
  let url = `${host}/v1/internal/revenue/graph?postbackType=${postbackType}&dataSource=${dataSource}&startDate=${startDate}&date=${date}&endDate=${endDate}&adType=${adType}&clientId=${clientId}&placementId=${placementId}&providerId=${providerId}&adId=${adId}&granularity=${graphGranularity}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchRevenueProviderSpecificData = async ({ postbackType = "v1", dataSource = "mysql", startDate, endDate, date, adType, clientId, placementId, providerId, adId, groupBy }) => {
  if (dataSource === "mysql") {
    startDate = startDate + " 00:00:00"
    endDate = endDate + " 23:59:59"
  }
  let url = `${host}/v1/internal/revenue/provider?postbackType=${postbackType}&dataSource=${dataSource}&startDate=${startDate}&date=${date}&endDate=${endDate}&adType=${adType}&clientId=${clientId}&placementId=${placementId}&providerId=${providerId}&adId=${adId}&groupBy=${groupBy}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchRevenueAdSpecificData = async ({ postbackType = "v1", dataSource = "mysql", startDate, endDate, date, adType, clientId, placementId, providerId, adId, groupBy }) => {
  if (dataSource === "mysql") {
    startDate = startDate + " 00:00:00"
    endDate = endDate + " 23:59:59"
  }
  let url = `${host}/v1/internal/revenue/ads?postbackType=${postbackType}&dataSource=${dataSource}&startDate=${startDate}&date=${date}&endDate=${endDate}&adType=${adType}&clientId=${clientId}&placementId=${placementId}&providerId=${providerId}&adId=${adId}&groupBy=${groupBy}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchRevenueFilterOptions = async ({ postbackType = "v1", dataSource = "mysql", startDate, endDate, date, adType, clientId, placementId, providerId, adId }) => {
  if (dataSource === "mysql") {
    startDate = startDate + " 00:00:00"
    endDate = endDate + " 23:59:59"
  }
  let url = `${host}/v1/internal/revenue/filters?postbackType=${postbackType}&dataSource=${dataSource}&startDate=${startDate}&date=${date}&endDate=${endDate}&adType=${adType}&clientId=${clientId}&placementId=${placementId}&providerId=${providerId}&adId=${adId}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchPlacementsWithPlacementIds = async (placements) => {
  let url = `${host}/v1/internal/adx/placementsDetails`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(placements)
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchAdsWithAdIds = async (ads) => {
  let url = `${host}/v1/internal/adx/adsDetails`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(ads)
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let fetchRevenueDashboardNotes = async () => {
  let url = `${host}/v1/internal/adx/revenueDashboardNotes`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let createRevenueDashboardNote = async (data) => {
  let url = `${host}/v1/internal/adx/revenueDashboardNotes`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let updateRevenueDashboardNote = async (id, data) => {
  let url = `${host}/v1/internal/adx/revenueDashboardNote/${id}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return;
  }
};

export let deleteRevenueDashboardNote = async (id) => {
  let url = `${host}/v1/internal/adx/revenueDashboardNote/${id}`;
  let token = retrieveFromLocalStorage("token");
  try {
    var response = await secureFetch(url, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return await response.json();
  } catch (error) {
    return;
  }
};
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import KeyboardLanguageForm from "./KeyboardLanguageForm";

const EditKeyboardLanguage = (props) => {
    const [formTextState, setFormTextState] = useState({
        id: "",
        name: "",
        locale: "",
        searchTags:"",
        code: "",
        priority: "",
        minAppVersion: "",
        minBobbleSDKVersion: "",
        languageId: "",
        additionalDetails: "",
        deviceType: "",
        iconImageURL: "",
        defaultLayoutId: "",
        layouts: [],
    });
    const formType = "edit";
    const [additionalDetails, setAdditionalDetails] = useState(props.additionalDetails)

    // This useEffect will be called for componentWillReceiveProps condition
    useEffect(() => {
        setFormTextState({
            id: props.language.id,
            name: props.language.name,
            locale: props.language.locale,
            searchTags: props.language.searchTags,
            priority: props.language.priority,
            minAppVersion: props.language.minAppVersion,
            minBobbleSDKVersion: props.language.minBobbleSDKVersion,
            languageId: props.language.languageId,
            code: props.language.code,
            deviceType: props.language.deviceType,
            additionalDetails: props.additionalDetails,
            iconImageURL: props.language.iconImageURL,
            macaronicBaseKeyboardLanguageId: props.language.macaronicBaseKeyboardLanguageId,
            layouts: props.language.layouts ?? [],
            defaultLayoutId: props.language.defaultLayoutId,
        });
    }, [props.language, props.additionalDetails]);

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit Keyboard Language
                </Modal.Title>
            </Modal.Header>
            <KeyboardLanguageForm
                id={formTextState.id}
                name={formTextState.name}
                locale={formTextState.locale}
                searchTags={formTextState.searchTags}
                priority={formTextState.priority}
                minAppVersion={formTextState.minAppVersion}
                minBobbleSDKVersion={formTextState.minBobbleSDKVersion}
                languageId={formTextState.languageId}
                code={formTextState.code}
                deviceType={formTextState.deviceType}
                additionalDetails={formTextState.additionalDetails}
                iconImageURL={formTextState.iconImageURL}
                formType={formType}
                macaronicBaseKeyboardLanguageId={formTextState.macaronicBaseKeyboardLanguageId}
                layouts={formTextState.layouts}
                defaultLayoutId={formTextState.defaultLayoutId}
            />
        </Modal>
    );
};

export default EditKeyboardLanguage;

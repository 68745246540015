import React, { Component } from "react";
import { Modal, Button, Row, Col, Card, CardImg } from "react-bootstrap";
import Reorder, { reorder } from "react-reorder";
import { CardBody } from "reactstrap";
import Loading from "../../../components/Loading";
import CustomTooltip from "../../../components/customToolTip";

class RearrangeStickers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stickers: props.stickers,
      id: [],
    };
  }

  componentWillReceiveProps() {
    this.setState({
      stickers: this.props.stickers,
    });
    let id = [];
    this.props.stickers.forEach((sticker) => id.push(sticker.id));
    this.setState({ id });
  }

  onReorder = (event, previousIndex, nextIndex, fromId, toId) => {
    this.setState({
      stickers: reorder(this.state.stickers, previousIndex, nextIndex),
    });
    let id = [];
    this.state.stickers.forEach((sticker) => id.push(sticker.id));
    this.setState({ id });
  };

  submitRearrangedList = () => {
    let data = {
      stickerIds: this.state.id,
    };
    this.props.reorder(data);
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header className="bg-light" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Rearrange Stickers{" "}
            <CustomTooltip
              target="Reorder"
              content={
                <>
                  This ordering is depricated for SDV3 All category.
                </>
              }
              placement="right"
            />
          </Modal.Title>
        </Modal.Header>
        {
          this.props.isLoading
            ? <Loading></Loading>
            : (
              <Modal.Body>
                <Row>
                  <Col>
                    <h5>Instructions:</h5>
                    <ul>
                      <li>
                        Drag and Drop the stickers to rearrange them.
                      </li>
                    </ul>
                  </Col>
                </Row>
                <Row>
                  <Reorder reorderId="reorder-list" onReorder={this.onReorder}>
                    {this.state.stickers.map((sticker, index) => {
                      return (
                        <div key={index} className="reorderContainer">
                          <Card className="m-3 responseCard" style={{ "cursor": "pointer" }}>
                            <CardImg src={sticker.imageURL} />
                          </Card>
                        </div>
                      );
                    })}
                  </Reorder>
                </Row>
                <Modal.Footer>
                  <Button onClick={this.submitRearrangedList}>Submit</Button>
                </Modal.Footer>
              </Modal.Body>
            )
        }
      </Modal>
    );
  }
}

export default RearrangeStickers;

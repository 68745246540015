import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom"
import moment from "moment";
import DateTimePicker from 'react-datetime-picker'
import Select from "react-select";
import { Container, Row, Col, FormGroup, Label, Input } from "reactstrap";
import Loading from "../../../components/Loading";
import { fetchDashboardSessionLogs } from "../../../utilities/apiUtils/dashboardLogs";
import Logs from "./Logs";

const LIMIT = 5

const filtersInitialState = {
  id: "",
  start: "",
  end: "",
  service: null
}

const DashboardSessionLogs = () => {
  const location = useLocation()

  const [loading, setLoading] = useState(true);
  const [sessions, setSessions] = useState([])
  const [activeSessionAccordion, setActiveSessionAccordion] = useState(null)
  const [activeSessionChangeAccordion, setActiveSessionChangeAccordion] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const [activePage, setActivePage] = useState(0)
  const [filters, setFilters] = useState(filtersInitialState)

  const handleToggleSessionAccordion = useCallback((value) => {
    if (value === activeSessionAccordion) setActiveSessionAccordion(null)
    else setActiveSessionAccordion(value)
    setActiveSessionChangeAccordion(null)
  }, [activeSessionAccordion])

  const handleToggleSessionChangeAccordion = useCallback((value) => {
    if (value === activeSessionChangeAccordion) setActiveSessionChangeAccordion(null)
    else setActiveSessionChangeAccordion(value)
  }, [activeSessionChangeAccordion])

  const getDashboardLogs = async (filters) => {
    try {
      let response = await fetchDashboardSessionLogs(filters);
      if (response?.logs?.length > 0) {
        setPageCount(Math.ceil(response.total / LIMIT));
        setSessions(response.logs);
      } else {
        setPageCount(0);
        setSessions([]);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    setLoading(true)
    const params = new URLSearchParams(location.search);
    setFilters((prevState) => ({
      ...prevState,
      id: params.get("id"),
      start: moment.unix(params.get("startTime")).format('YYYY-MM-DD HH:mm:ss'),
      end: moment.unix(params.get("endTime")).format('YYYY-MM-DD HH:mm:ss'),
      service: params.get("service")
        ? {
          label: params.get("service"),
          value: params.get("service")
        } : null
    }))
    const queryFilters = {
      id: params.get("id"),
      start: moment.utc(Number(params.get("startTime")) * 1000).format('YYYY-MM-DD HH:mm:ss'),
      end: moment.utc(Number(params.get("endTime")) * 1000).format('YYYY-MM-DD HH:mm:ss'),
      service: params.get("service") ?? ""
    }
    setActiveSessionAccordion(queryFilters.id)
    getDashboardLogs(queryFilters).finally(() => {
      setLoading(false)
    })
  }, [location.search])

  const handlePageClick = useCallback((event) => {
    setActivePage(event.selected)
  }, []);

  return (
    <Container fluid className="p-0">
      <Row>
        <h1 className="mb-2 mt-2 w-50 pl-3">Session Logs</h1>
      </Row>
      <Row form>
        <Col md={3}>
          <FormGroup>
            <Label
              className="font-weight-bolder mb-2"
              style={{ fontSize: "14px" }}
            >
              Service
            </Label>
            <Select
              id="service"
              className="react-select-container"
              classNamePrefix="react-select"
              name="service"
              placeholder="Select Service"
              value={filters.service}
              onChange={(event) => {
                setFilters((prevState) => ({
                  ...prevState,
                  service: event
                }));
              }}
              options={[]}
              isDisabled
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label
              className="font-weight-bolder mb-2"
              style={{ fontSize: "14px" }}
            >
              Session ID
            </Label>
            <Input
              type="text"
              name="sessionId"
              placeholder="Search Session ID"
              value={filters.id}
              onChange={(event) => {
                const { value } = event.target
                setFilters((prevState) => ({
                  ...prevState,
                  id: value || ""
                }));
              }}
              disabled
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label
              className="font-weight-bolder mb-2"
              style={{ fontSize: "14px" }}
            >
              Start Date & Time
            </Label>
            <DateTimePicker
              value={filters.start ? new Date(filters.start) : new Date()}
              format="dd-MM-y h:mm:ss a"
              disabled
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label
              className="font-weight-bolder mb-2"
              style={{ fontSize: "14px" }}
            >
              End Date & Time
            </Label>
            <DateTimePicker
              value={filters.end ? new Date(filters.end) : new Date()}
              format="dd-MM-y h:mm:ss a"
              disabled
            />
          </FormGroup>
        </Col>
      </Row>
      {!loading && sessions.length <= 0 && (
        <span className="text-info font-weight-bold">No logs available for the selected filters</span>
      )}
      {loading ? (
        <Loading />
      ) : sessions.length > 0 && (
        <Logs
          sessions={sessions}
          activeSessionAccordion={activeSessionAccordion}
          activeSessionChangeAccordion={activeSessionChangeAccordion}
          pageCount={pageCount}
          activePage={activePage}
          limit={LIMIT}
          onPageClick={handlePageClick}
          onToggleSessionAccordion={handleToggleSessionAccordion}
          onToggleSessionChangeAccordion={handleToggleSessionChangeAccordion}
        />
      )
      }
    </Container>
  );
};

export default DashboardSessionLogs;
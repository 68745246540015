// Content Update Prompts
import { retrieveFromLocalStorage } from "../localStorageUtil";
import { host } from "./index";
// Content Update Prompts

export let getContentUpdatePrompts = async () => {
  let token = retrieveFromLocalStorage("token");
  let contentUpdatePrompt = [];
  let url = `${host}/v4/internal/contentUpdatePrompts`;

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    contentUpdatePrompt = response.json();
  });

  return contentUpdatePrompt;
};

export let getContentUpdatePromptDetails = async (id) => {
  let token = retrieveFromLocalStorage("token");
  let contentUpdatePrompt = {};
  let url = `${host}/v4/internal/contentUpdatePrompts/${id}`;

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    contentUpdatePrompt = response.json();
  });

  return contentUpdatePrompt;
};

export let publishContentUpdatePrompt = async (id) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentUpdatePrompts/${id}/publish`;

  await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let deactivateContentUpdatePrompt = async (id) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentUpdatePrompts/${id}/deactivate`;

  await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let activateContentUpdatePrompt = async (id) => {
  let response = {};
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentUpdatePrompts/${id}/activate`;

  await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      response = res;
    });
  return response;
};

export let createContentUpdatePrompt = async (body) => {
  let token = retrieveFromLocalStorage("token");
  let url = "",
    resStatus = "";

  url = `${host}/v4/internal/contentUpdatePrompts`;
  await fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    resStatus = response.json();
  });

  return resStatus;
};

export let updateContentUpdatePrompt = async (body, contentPromptId) => {
  let token = retrieveFromLocalStorage("token");
  let url = "",
    resStatus = "";

  url = `${host}/v4/internal/contentUpdatePrompts/${contentPromptId}`;
  await fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    resStatus = response.json();
  });

  return resStatus;
};

export let validateDetailsForm = (input) => {
  let prompts = {
    hasValidMaxCount: false,
    hasValidMinKBSessionCount: false,
    hasValidRepeatKBSessionCount: false,
    hasValidTextMaxLines: false,
    hasValidText: false,
    hasValidCtaText: false,
    hasValidImpressionTrackers: true,
    isValid: false,
    data: {
      maxCount: 0,
      minKBSessionCount: 0,
      repeatKBSessionCount: 0,
      textMaxLines: 0,
      backgroundColor: "",
      textColor: "",
      text: "",
      ctaTextColor: "",
      ctaBackgroundColor: "",
      ctaText: "",
    },
  };
  let maxCount = input.maxCount;
  let minKBSessionCount = input.minKBSessionCount;
  let repeatKBSessionCount = input.repeatKBSessionCount;
  let textMaxLines = input.textMaxLines;
  let text = input.text;
  let ctaText = input.ctaText;
  let impressionTrackers = input.impressionTrackers;
  if (maxCount !== "" && parseFloat(maxCount) > 0) {
    prompts.data.maxCount = parseFloat(maxCount);
    prompts.hasValidMaxCount = true;
  }
  if (minKBSessionCount !== "" && parseFloat(minKBSessionCount) > 0) {
    prompts.data.minKBSessionCount = parseFloat(minKBSessionCount);
    prompts.hasValidMinKBSessionCount = true;
  }
  if (repeatKBSessionCount !== "" && parseFloat(repeatKBSessionCount) > 0) {
    prompts.data.repeatKBSessionCount = parseFloat(repeatKBSessionCount);
    prompts.hasValidRepeatKBSessionCount = true;
  }
  if (textMaxLines !== "" && parseFloat(textMaxLines) > 0) {
    prompts.data.textMaxLines = parseFloat(textMaxLines);
    prompts.hasValidTextMaxLines = true;
  }
  if (text !== "") {
    try {
      let res = JSON.parse(text);
      if (res && typeof res === "object") {
        for (let key in res) {
          if (res.hasOwnProperty(key)) {
            if (res[key] === "") {
              prompts.hasValidText = false;
              break;
            }
            prompts.hasValidText = true;
            prompts.data.text = res;
          }
        }
      }
    } catch (e) {
      prompts.hasValidText = false;
    }
  }
  if (ctaText !== "") {
    try {
      let res = JSON.parse(ctaText);
      if (res && typeof res === "object") {
        for (let key in res) {
          if (res.hasOwnProperty(key)) {
            if (res[key] === "") {
              prompts.hasValidCtaText = false;
              break;
            }
            prompts.hasValidCtaText = true;
            prompts.data.ctaText = res;
          }
        }
      }
    } catch (e) {
      prompts.hasValidCtaText = false;
    }
  }
  if (impressionTrackers !== "") {
    try {
      let res = JSON.parse(impressionTrackers);
      if (
        res &&
        typeof res === "object" &&
        Object.keys(res).length >= 0 &&
        Array.isArray(res)
      ) {
        prompts.hasValidImpressionTrackers = true;
        prompts.data["impressionTrackers"] = res;
      } else {
        prompts.hasValidImpressionTrackers = false;
      }
    } catch (e) {
      prompts.hasValidImpressionTrackers = false;
    }
  }
  if (input.logoURL !== "") {
    prompts.data["logoURL"] = input.logoURL;
  }
  if (
    prompts.hasValidCtaText &&
    prompts.hasValidMaxCount &&
    prompts.hasValidRepeatKBSessionCount &&
    prompts.hasValidText &&
    prompts.hasValidTextMaxLines &&
    prompts.hasValidImpressionTrackers
  ) {
    prompts.isValid = true;
    prompts.data.backgroundColor = input.backgroundColor.value;
    prompts.data.ctaBackgroundColor = input.ctaBackgroundColor.value;
    prompts.data.ctaTextColor = input.ctaTextColor.value;
    prompts.data.textColor = input.textColor.value;
  }
  return prompts;
};

export let fetchContentUpdatePromptsRegionCodes = async () => {
  let token = retrieveFromLocalStorage("token");
  let regionCodes = {};
  let url = `${host}/v4/internal/contentUpdatePrompts/regions`;

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then(async (response) => {
    regionCodes = await response.json();
  });
  return groupRegionsByCountry(regionCodes["regions"]);
};

export let fetchContentUpdatePromptsCities = async () => {
  let token = retrieveFromLocalStorage("token");
  let cityNames = {};
  let url = `${host}/v4/internal/contentUpdatePrompts/cities`;

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then(async (response) => {
    cityNames = await response.json();
  });

  return groupCities(cityNames["cities"]);
};

let groupRegionsByCountry = (list) => {
  let regions = {};
  if (list === undefined) {
    return regions;
  }
  for (let i = 0; i < list.length; i++) {
    const element = list[i];

    if (!regions[element["countryId"]]) {
      regions[element["countryId"]] = [];
    }
    if (element["regionCode"] !== "GLOBAL")
      regions[element["countryId"]] = [
        ...regions[element["countryId"]],
        element,
      ];
    else {
      regions[element["countryId"]] = [
        element,
        ...regions[element["countryId"]],
      ];
    }
  }
  return regions;
};

//groups the cities by region code and country code
let groupCities = (list) => {
  let cities = {};

  if (list === undefined) {
    return cities;
  }
  for (let i = 0; i < list.length; i++) {
    const element = list[i];
    if (
      !cities[element["countryId"].toString() + element["regionId"].toString()]
    ) {
      cities[element["countryId"].toString() + element["regionId"].toString()] =
        [];
    }
    if (element["cityName"] !== "GLOBAL")
      cities[element["countryId"].toString() + element["regionId"].toString()] =
        [
          ...cities[
            element["countryId"].toString() + element["regionId"].toString()
          ],
          element,
        ];
    else {
      cities[element["countryId"].toString() + element["regionId"].toString()] =
        [
          element,
          ...cities[
            element["countryId"].toString() + element["regionId"].toString()
          ],
        ];
    }
  }
  return cities;
};

export let fetchContentUpdatePromptsCountryCodes = async () => {
  let countryCodes = {};
  let url = `${host}/v4/internal/contentUpdatePrompts/countries`;
  let token = retrieveFromLocalStorage("token");

  await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    countryCodes = response.json();
  });

  return countryCodes;
};

export let createContentUpdatePromptRulesets = async (data, id) => {
  let token = retrieveFromLocalStorage("token");

  let url = `${host}/v4/internal/contentUpdatePrompts/${id}/rules`;
  try {
    var response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ rulesets: data }),
    });
    var res = await response.json();

    return res;
  } catch (error) {
    return;
  }
};

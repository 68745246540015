import React, { useState, useEffect } from "react";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import { Button } from "react-bootstrap";
import { tableColumns } from "./tableColumn";
import Select from "react-select";
import { Badge } from "reactstrap";
import { Link } from "react-router-dom";
import ConfirmationModal from "../../../../pages/ui-elements/ConfirmationModal";
import ResponseModal from "../../../../pages/ui-elements/ResponseModal";
import {
  statusOptions,
  typeOptions,
  showAddKeyboardPromptsButton,
  showPublishKeyboardPromptsButton,
  showDeactivateKeyboardPromptsButton,
  showActivateKeyboardPromptsButton,
} from "../../../../config/ConfigKeyboardPrompts";

import {
  successModalHeading,
  errorModalHeading,
} from "../../../../config/config";

import filterFactory from "react-bootstrap-table2-filter";
import Loading from "../../../../components/Loading";
import paginationFactory from "react-bootstrap-table2-paginator";
import Search from "../../../../components/Search";

import BootstrapTable from "react-bootstrap-table-next";
import {
  fetchKeyboardPrompts,
  processDateAndStatus,
  publishKeyboardPrompts,
  deactivateKeyboardPrompts,
  activateKeyboardPrompts,
} from "../../../../utilities/apiUtils/keyboardPrompts";

import {
  errorDescription,
  error,
  allActions,
  redirectOnTokenExpiry,
} from "../../../../utilities/commonUtil";
import { getBrandCampaigns } from "../../../../utilities/apiUtils/brandCampaign";
import { getClients } from "../../../../utilities/apiUtils/cre";
import RuleSummary from "../../RuleSummary";

const KeyboardPrompts = () => {
  const [loading, setLoading] = useState(true);
  const [statusFilter, setStatusFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [brandCampaignFilter, setBrandCampaignFilter] = useState("")
  const [brandCampaignOptions, setBrandCampaignOptions] = useState([])
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [keyboardPrompts, setKeyboardPrompts] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [descriptionFilter, setDescriptionFilter] = useState("");
  const [idFilter, setIdFilter] = useState("");
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [action, setAction] = useState("");
  const [id, setId] = useState(null);
  const [successModalText, setSuccessModalText] = useState("");
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [clientOptions, setClientOptions] = useState([])

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let successModalClose = async () => {
    setSuccessModalDisplay(false);
    setLoading(true)
    await fetchKeyboardPromptsTableData();
    setLoading(false)
  };

  let onTableChange = (type, newState) => {
  };

  let disablePublishButton = (row) => {
    return row.publishedAt !== null;
  };

  let disableDeactivateButton = (row) => {
    return row.deactivatedAt !== null || row.publishedAt === null;
  };

  let disableActivateButton = (row) => {
    return row.deactivatedAt === null;
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  const handleClick = () => {
    setStatusFilter("");
    setTypeFilter("");
    setBrandCampaignFilter("");
    setNameFilter("");
    setDescriptionFilter("");
    setIdFilter("")
  };

  const ClearButton = () => {
    return (
      <Button
        style={{ float: "right" }}
        className="form-control-lg"
        onClick={handleClick}
      >
        Reset Filters
      </Button>
    );
  };

  let setConfirmationModalState = (id, event) => {
    setAction(event);
    setConfirmationModalIsOpen(true);
    setId(id);
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <ul>
          <Row className="justify-content-md-center mx-auto mb-4">
            <Col md={6} sm={12}>
              <li>Type : {row.type !== null ? row.type : "null"}</li>
              <li>
                Brand Campaign ID :{" "}
                {row.brandCampaignId !== null
                  ? brandCampaignOptions.find(option => option.value === row.brandCampaignId?.toString())?.label ?? row.brandCampaignId
                  : "null"
                }
              </li>
              <li>
                Status :{" "}
                {row.status === "Activated" ? (
                  <Badge className="badge-success">{row.status}</Badge>
                ) : row.status === "Deactivated" ? (
                  <Badge className="badge-danger">{row.status}</Badge>
                ) : (
                  <Badge className="badge-warning">{row.status}</Badge>
                )}
              </li>
              <li>
                Start Time :{" "}
                {row.settings.startTime !== null
                  ? row.settings.startTime
                  : "null"}
              </li>
              <li>
                End Time :{" "}
                {row.settings.endTime !== null ? row.settings.endTime : "null"}
              </li>
            </Col>
            <Col md={6} sm={12}>
              <li>
                Min App Version :{" "}
                {row.minAppVersion !== null ? row.minAppVersion : "null"}
              </li>
              <li>
                Created At : {row.createdAt !== null ? row.createdAt : "null"}
              </li>
              <li>
                Updated At : {row.updatedAt !== null ? row.updatedAt : "null"}
              </li>
              <li>
                Published At :{" "}
                {row.publishedAt !== null ? row.publishedAt : "null"}
              </li>
              <li>
                Deactivated At :{" "}
                {row.deactivatedAt !== null ? row.deactivatedAt : "null"}
              </li>
            </Col>
            <Col md={12} sm={12}>
              <li>
                Action Types :{" "}
                {row.actions.map((item) => {
                  return (
                    <>
                      <Badge className="badge-secondary">{item.type}</Badge>{" "}
                    </>
                  );
                })}
              </li>
              <li>
                Targeting Rules :{" "}
                {row.rulesSummary?.map((rule, index) => {
                  const clientRules = rule.clientRules?.map(cRule => ({
                    ...cRule,
                    client: clientOptions.find(option => option.value === cRule.client)?.label || cRule.client
                  }))
                  return (
                    <Col key={rule.ruleId} md={12} sm={12} className="my-2">
                      <span className="font-weight-bolder" style={{ marginLeft: -12 }}>
                        Rule {index + 1}:
                        <Badge
                          className="ml-1"
                          color={rule.activate ? "success" : "danger"}
                        >
                          {rule.activate ? "Activated" : "Deactivated"}
                        </Badge>
                      </span>
                      <RuleSummary
                        clientRules={clientRules}
                        locationRules={rule.locationRules}
                        appVersionRules={{}}
                        showAppVersionRules={false}
                      />
                    </Col>
                  )
                })}
              </li>
            </Col>
          </Row>
        </ul>

        <Link
          className="text-decoration-none"
          to={{
            pathname: `/keyboard-prompt/prompts/edit/${row.id}`,
            state: {
              formType: "edit",
            },
          }}
        >
          <Button className="mx-3">Edit</Button>
        </Link>

        {showPublishKeyboardPromptsButton() && (
          <Button
            className="mx-3  btn-success"
            disabled={disablePublishButton(row)}
            onClick={(event) =>
              setConfirmationModalState(row.id, event.target.innerHTML)
            }
          >
            Publish
          </Button>
        )}

        {showAddKeyboardPromptsButton() && (
          <Link
            className="text-decoration-none"
            to={{
              pathname: `/keyboard-prompt/prompts/add`,
              state: {
                formType: "create",
                clonePromptId: row.id
              },
            }}
          >
            <Button
              className="mx-3"
            >
              Clone
            </Button>
          </Link>
        )}

        {showDeactivateKeyboardPromptsButton() && (
          <Button
            className="mx-3  btn-danger"
            disabled={disableDeactivateButton(row)}
            onClick={(event) =>
              setConfirmationModalState(row.id, event.target.innerHTML)
            }
          >
            Deactivate
          </Button>
        )}

        {showActivateKeyboardPromptsButton() && (
          <Button
            className="mx-3  btn-success"
            disabled={disableActivateButton(row)}
            onClick={(event) =>
              setConfirmationModalState(row.id, event.target.innerHTML)
            }
          >
            Activate
          </Button>
        )}

        <Link
          className="text-decoration-none"
          to={{
            pathname: `/keyboard-prompt/prompts/targeting-rules/${row.id}`,
            state: {
              formType: "edit",
            },
          }}
        >
          <Button className="mx-3">Edit Targeting Rules</Button>
        </Link>

        <Link
          className="text-decoration-none"
          to={{
            pathname: `/keyboard-prompt/prompts/user-attribute-rules/${row.id}`,
            state: {
              formType: "edit",
            },
          }}
        >
          <Button className="mx-3">Edit User Attribute Rules</Button>
        </Link>

        <ConfirmationModal
          show={confirmationModalIsOpen}
          modaltext={action}
          onConfirmationTrue={() => {
            setConfirmationModalIsOpen(false);
            if (action === allActions.publish) publish(id);
            else if (action === allActions.deactivate) deactivate(id);
            else if (action === allActions.activate) activate(id);
          }}
          onHide={() => setConfirmationModalIsOpen(false)}
        />
        <ResponseModal
          show={successModalDisplay}
          onHide={successModalClose}
          modalheading={successModalHeading}
          modaltext={successModalText}
        />
        <ResponseModal
          show={failureModalDisplay}
          onHide={failureModalClose}
          modalheading={errorModalHeading}
          modaltext={failureModalText}
        />
      </div>
    ),
  };

  let publish = async (id) => {
    let response = await publishKeyboardPrompts(id);
    if (response.status === "success") {
      let text = "Keyboard Prompt has been Published";
      showSuccessModal(text);
    } else {
      let text = errorDescription(response.errorCode);
      showErrorModal(text);
    }
  };

  let deactivate = async (id) => {
    let response = await deactivateKeyboardPrompts(id);
    if (response.status === "success") {
      let text = "Keyboard Prompt has been Deactivated";
      showSuccessModal(text);
    } else {
      let text = errorDescription(response.errorCode);
      showErrorModal(text);
    }
  };

  let activate = async (id) => {
    let response = await activateKeyboardPrompts(id);
    if (response.status === "success") {
      let text = "Keyboard Prompt has been Activated";
      showSuccessModal(text);
    } else {
      let text = errorDescription(response.errorCode);
      showErrorModal(text);
    }
  };

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  let fetchKeyboardPromptsTableData = async () => {
    try {
      let response = await fetchKeyboardPrompts();
      if (response?.prompts !== undefined) {
        response.prompts.forEach((item) => {
          item = processDateAndStatus(item);
          let rulesSummary = []
          if (item?.targetingRules?.length > 0) {
            item.targetingRules.forEach(rule => {
              let clientRules = [], locationRules = []
              if (rule.clientRules.length > 0) {
                rule.clientRules.forEach(clientRule => {
                  clientRules.push({
                    id: clientRule.id,
                    client: clientRule.clientID,
                    effect: clientRule.effect === "allow" ? "Allow" : "Deny"
                  })
                })
              }
              if (rule.locationRules.length > 0) {
                rule.locationRules.forEach(locationRule => {
                  locationRules.push({
                    id: locationRule.id,
                    country: locationRule.country.name,
                    region: locationRule.region.name,
                    city: locationRule.city.name,
                    effect: locationRule.effect === "allow" ? "Allow" : "Deny"
                  })
                })
              }
              rulesSummary.push({
                ruleId: rule.ruleID,
                activate: rule.activate,
                clientRules,
                locationRules
              })
            })
          }
          item.rulesSummary = rulesSummary
        });
        setKeyboardPrompts(response.prompts);
      } else {
        if (
          response.errorCode === error.tokenExpired ||
          response.errorCode === error.invalidAccessToken
        )
          redirectOnTokenExpiry();
        let text = errorDescription(response.errorCode);
        showErrorModal(text);
      }
    } catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
  };

  const filterKeyboardPrompts = () => {
    let items = keyboardPrompts;

    if (statusFilter.value)
      items = items.filter((item) => item.status === statusFilter.value);

    if (typeFilter.value)
      items = items.filter((item) => item.type === typeFilter.value);

    if (brandCampaignFilter.value)
      items = brandCampaignFilter.value === "branded"
        ? items.filter((item) => item.brandCampaignId)
        : brandCampaignFilter.value === "nonBranded"
          ? items.filter((item) => !item.brandCampaignId)
          : items.filter((item) => item.brandCampaignId?.toString() === brandCampaignFilter.value)

    if (nameFilter)
      items = items.filter((item) =>
        item.name.toLowerCase().includes(nameFilter.toLowerCase())
      );

    if (descriptionFilter)
      items = items.filter((item) =>
        item.description.toLowerCase().includes(descriptionFilter.toLowerCase())
      );

    if (idFilter) {
      items = items.filter((item) =>
        item.id.toString().includes(idFilter)
      );
    }

    return items;
  };

  const fetchClientOptions = async () => {
    try {
      let response = await getClients();
      if (response.length > 0) {
        setClientOptions(response)
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    setLoading(true)
    Promise.all([
      fetchKeyboardPromptsTableData(),
      fetchBrandCampaignOptions(),
      fetchClientOptions(),
    ]).finally(() => {
      setLoading(false)
    })

    // Fill stored filter values
    if (sessionStorage.getItem("keyboardPromptFilterName"))
      setNameFilter(sessionStorage.getItem("keyboardPromptFilterName"));
    if (sessionStorage.getItem("keyboardPromptFilterId"))
      setIdFilter(sessionStorage.getItem("keyboardPromptFilterId"));
    if (sessionStorage.getItem("keyboardPromptFilterDescription"))
      setDescriptionFilter(
        sessionStorage.getItem("keyboardPromptFilterDescription")
      );
    if (sessionStorage.getItem("keyboardPromptFilterStatus"))
      setStatusFilter({
        value: sessionStorage.getItem("keyboardPromptFilterStatus") ?? "",
        label:
          statusOptions.find(
            (option) =>
              option.value ===
              sessionStorage.getItem("keyboardPromptFilterStatus")
          )?.label ?? "",
      });
    if (sessionStorage.getItem("keyboardPromptFilterType"))
      setTypeFilter({
        value: sessionStorage.getItem("keyboardPromptFilterType") ?? "",
        label:
          typeOptions.find(
            (option) =>
              option.value ===
              sessionStorage.getItem("keyboardPromptFilterType")
          )?.label ?? "",
      });
  }, []);

  useEffect(() => {
    if (brandCampaignOptions?.length > 0) {
      if (sessionStorage.getItem("keyboardPromptFilterBrandCampaign"))
        setBrandCampaignFilter({
          value: sessionStorage.getItem("keyboardPromptFilterBrandCampaign") ?? "",
          label:
            brandCampaignOptions.find(
              (option) =>
                option.value ===
                sessionStorage.getItem("keyboardPromptFilterBrandCampaign")
            )?.label ?? "",
        });
    }
  }, [brandCampaignOptions])

  const fetchBrandCampaignOptions = async () => {
    let options = []
    options.push(
      { value: "", label: "All" },
      { value: "branded", label: "Branded" },
      { value: "nonBranded", label: "Non-Branded" }
    )
    const response = await getBrandCampaigns();
    if (response?.length > 0) {
      response.forEach((brandCampaign) => {
        options.push({
          value: brandCampaign.value?.toString(),
          label: brandCampaign.label
        })
      })
    }
    setBrandCampaignOptions(options)
  };

  return (
    <Container fluid className="p-0">
    <Row className="align-items-center justify-content-between mb-4 mt-2">
      <Col xs="auto">
        <h1 className="mb-0 pl-3">Keyboard Prompts</h1>
      </Col>
      <Col xs="auto" className="d-flex">
        <Link
          className="text-decoration-none"
          to="/keyboard-prompt/prompts/order"
        >
          <Button color="primary" className="d-flex align-items-center">
            <p className="mb-0" style={{ fontSize: "1rem" }}>
              Manage Prompt Order
            </p>
          </Button>
        </Link>
        {showAddKeyboardPromptsButton() && (
          <Link
            className="text-decoration-none ml-3"
            to={{
              pathname: `/keyboard-prompt/prompts/add`,
              state: {
                formType: "create",
              },
            }}
          >
            <Button
              color="success"
              className="d-flex align-items-center btn-success"
            >
              <p className="mb-0" style={{ fontSize: "1rem" }}>
                Add Keyboard Prompts
              </p>
            </Button>
          </Link>
          
        )}
        <div className="m1-auto">
              <Link
                to={{
                  pathname: `/keyboard-prompt/prompts/segmentOrder`,
                }}
              >
                <Button
                  style={{ fontSize: "1rem" }}
                  className="mx-3 btn-success mb-3"
                >
                  Manage Order (Segment based)
                </Button>
              </Link>
            </div>
      </Col>
    </Row>

      <div>
        <Card style={{ overflow: "hidden", paddingBottom: "100px" }}>
          <div container-fullwidth="true" style={{ margin: "2%" }}>
            <Row className="justify-content-md-center mx-auto mb-4">
              <Col md={3} sm={12} className="mt-3">
                <Select
                  className="react-select-container"
                  id="statusSelect"
                  classNamePrefix="react-select"
                  name="status"
                  placeholder="Select Status"
                  value={statusFilter}
                  onChange={(event) => {
                    setStatusFilter(event);
                    sessionStorage.setItem(
                      "keyboardPromptFilterStatus",
                      event.value
                    );
                  }}
                  options={statusOptions}
                />
              </Col>
              <Col md={3} sm={12} className="mt-3">
                <Select
                  className="react-select-container"
                  id="typeSelect"
                  classNamePrefix="react-select"
                  name="usageType"
                  placeholder="Select Type"
                  value={typeFilter}
                  onChange={(event) => {
                    setTypeFilter(event);
                    sessionStorage.setItem(
                      "keyboardPromptFilterType",
                      event.value
                    );
                  }}
                  options={typeOptions}
                />
              </Col>
              <Col md={3} sm={12} className="mt-3">
                <Select
                  className="react-select-container"
                  id="brandCampaignSelect"
                  classNamePrefix="react-select"
                  name="brandCampaignSelect"
                  placeholder="Select Brand Campaign"
                  value={brandCampaignFilter}
                  onChange={(event) => {
                    setBrandCampaignFilter(event);
                    sessionStorage.setItem(
                      "keyboardPromptFilterBrandCampaign",
                      event.value
                    );
                  }}
                  options={brandCampaignOptions}
                />
              </Col>
              <Col md={3} xs={12} className="p-0 mt-3">
                <ClearButton>Reset Filters</ClearButton>
              </Col>
            </Row>

            <Row className="justify-content-md-center mx-auto">
              <Col md={3} xs={3}>
                <Search
                  placeholder="Search Name"
                  value={nameFilter}
                  onChange={(e) => {
                    setNameFilter(e.target.value);
                    sessionStorage.setItem(
                      "keyboardPromptFilterName",
                      e.target.value
                    );
                  }}
                ></Search>
              </Col>

              <Col md={3} xs={3}>
                <Search
                  placeholder="Search Id"
                  value={idFilter}
                  onChange={(e) => {
                    setIdFilter(e.target.value);
                    sessionStorage.setItem(
                      "keyboardPromptFilterId",
                      e.target.value
                    );
                  }}
                ></Search>
              </Col>

              <Col md={6} xs={6}>
                <Search
                  placeholder="Search Description"
                  value={descriptionFilter}
                  onChange={(e) => {
                    setDescriptionFilter(e.target.value);
                    sessionStorage.setItem(
                      "keyboardPromptFilterDescription",
                      e.target.value
                    );
                  }}
                ></Search>
              </Col>
            </Row>
            <Row className="justify-content-md-center mx-auto">
              <Col md={6} sm={6} className="mt-3">
                <Badge
                  className="badge-success"
                  style={{
                    width: "1rem",
                    height: "1rem",
                    verticalAlign: "middle",
                  }}
                >
                  {" "}
                </Badge>{" "}
                : Status Activated
              </Col>
              <Col md={6} sm={6} className="mt-3">
                <b>P</b> - Type Prompt
              </Col>
              <Col md={6} sm={6} className="mt-3">
                <Badge
                  className="badge-danger"
                  style={{
                    width: "1rem",
                    height: "1rem",
                    verticalAlign: "middle",
                  }}
                >
                  {" "}
                </Badge>{" "}
                : Status Deactivated
              </Col>
              <Col md={6} sm={6} className="mt-3">
                <b>SD</b> - Type Suggestion Drawer
              </Col>
              <Col md={6} sm={6} className="mt-3">
                <Badge
                  className="badge-warning"
                  style={{
                    width: "1rem",
                    height: "1rem",
                    verticalAlign: "middle",
                  }}
                >
                  {" "}
                </Badge>{" "}
                : Status Unpublished
              </Col>
              <Col md={6} sm={6} className="mt-3">
                <b>AI</b> - Type AI Powered Bar
              </Col>
            </Row>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <CardBody>
              <BootstrapTable
                bootstrap4={true}
                wrapperClasses="table-responsive"
                hover={true}
                bordered={false}
                keyField="id"
                data={filterKeyboardPrompts()}
                columns={tableColumns}
                expandRow={expandRow}
                filter={filterFactory()}
                pagination={paginationFactory({
                  sizePerPage: 10,
                  sizePerPageList: [5, 10, 25, 50],
                })}
                defaultSorted={[
                  {
                    dataField: "id",
                    order: "desc",
                  },
                ]}
                onTableChange={onTableChange}
                remote={{ filter: true }}
                noDataIndication="No results found"
              />
            </CardBody>
          )}
        </Card>
      </div>
    </Container>
  );
};

export default KeyboardPrompts;

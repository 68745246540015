import React, { useState, useEffect, useCallback } from "react";
import ResponseModal from "../ResponseModal";
import { useParams, useLocation } from 'react-router-dom';
import { successModalHeading } from '../../../config';
import { errorModalHeading } from '../../../config/config';
import { X } from "react-feather";
import {
    error, errorDescription
} from '../../../utilities/commonUtil';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom"
import Select from "react-select";
import { components } from "react-select";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import { CreateAITask, GetAIPlacements, GetAIPromptTemplates, GetAITaskDetails, UpdateAITask } from "../../../utilities/apiUtils/genAIDashboard";
import { backButtonActionTypeOptions, defaultOperationTypeOptions, taskResponses } from "../../../config/configGenAIDashboard";
import ToolTip from "../../../utilities/ToolTip";
import { iconSize } from "../../../config/UserConfig";
import { languageMapping } from "../../../utilities/languageMapping";
import Loading from "../../../components/Loading";

const TaskForm = () => {
    const { id } = useParams();
    const history = useHistory();
    const location = useLocation();
    const currentPath = location.pathname.split('/');
    const pageType = currentPath[currentPath.length - 1];
    let [addLanguageCodeOption, setAddLanguageCodeOption] = useState([]);
    const [successModalDisplay, setSuccessModalDisplay] = useState(false);
    const [successModalText, setSuccessModalText] = useState("");
    const [failureModalDisplay, setFailureModalDisplay] = useState(false);
    const [failureModalText, setFailureModalText] = useState("");
    const [promptTemplateOptions, setPromptTemplateOptions] = useState([]);
    const [placementOptions, setPlacementOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
        id: 0,
        name: "",
        description: "",
        promptIdentifier: 0,
        title: {
            default: "",
        },
        hintText: {
            default: "",
        },
        minAppVersion: "",
        maxAppVersion: "",
        backButtonAction: {
            type: backButtonActionTypeOptions[0],
            metadata: {
                deeplink: "",
                placementId: ""
            }
        },
        defaultOperation: {
            type: defaultOperationTypeOptions[0],
            metadata: {
                promptIdentifier: "",
                attributes: {}
            }
        },
        createdAt: "",
        updatedAt: "",
        publishedAt: "",
        deactivatedAt: ""
    })

    const [translations, setTranslations] = useState([
        {
            languageCode: "",
            title: "",
            hintText: "",
        },
    ]);
    const [attributesArray, setAttributesArray] = useState([
        {
            key: "",
            value: ""
        }
    ]);

    const setLanguageCode = async () => {
        let languageCode = languageMapping;
        if (languageCode?.length > 0) {
            const languageCodeOptions = languageCode.map((language) => {
                return {
                    value: language.code,
                    label: language.name + " : " + language.code,
                    target: {
                        name: "languageCode",
                        value: {
                            value: language.code,
                            label: language.name + " : " + language.code,
                        },
                    },
                };
            });
            setAddLanguageCodeOption(languageCodeOptions);
        }
    };

    const showLanguageCodeOptions = () => {
        let finalLanguageCode = [...addLanguageCodeOption];
        // remove language codes from the dropdown those are already selected.
        translations.forEach((translation) => {
            finalLanguageCode = finalLanguageCode.filter(
                (item) => item?.value !== translation.languageCode?.value
            );
        });
        return finalLanguageCode;
    };

    const handleLanguageCodeChange = (event, index) => {
        const { name, value } = event.target;
        let temp = [...translations];
        temp[index][name] = value;
        setTranslations(temp);
    };

    const handleTextChange = (event, index) => {
        const { name, value } = event.target;
        let temp = [...translations];
        temp[index][name] = value;
        setTranslations(temp);
    };

    const handleChange = (event) => {
        const { name, value } = event.target
        setData((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const convertResponseToTranslations = (titleObj, hintTextObj) => {
        let translations = Object.entries(titleObj)
            .filter(([key]) => key !== "default")
            .map(([key, value]) => ({
                languageCode: {
                    value: key,
                    label: languageMapping.find(lang => lang.code === key)?.name + " : " + key || key,
                },
                title: value,
                hintText: hintTextObj[key] ?? ""
            }));
        return translations;
    };

    const convertAttributes = (textObj) => {
        return Object.entries(textObj)
            .filter(([key]) => key !== "default")
            .map(([key, value]) => ({
                key: key,
                value: value
            }));
    };

    const addRow = () => {
        let currentState = [...attributesArray, {
            "key": "",
            "value": "",
        }];
        setAttributesArray(currentState);
    };

    const subtractRow = (index) => {
        let currentState = [...attributesArray];
        currentState = currentState.filter((item, idx) => idx !== index);
        setAttributesArray(currentState);
    };

    const handleAttributeKey = (event, index) => {
        let updatedState = [...attributesArray];
        let { value } = event.target;
        updatedState[index].key = value;
        setAttributesArray(updatedState);
    };

    const handleAttributeValue = (event, index) => {
        let updatedState = [...attributesArray];
        let { value } = event.target;
        updatedState[index].value = value;
        setAttributesArray(updatedState);
    };

    let fetchTask = useCallback(async (taskId) => {
        try {
            let response = await GetAITaskDetails(taskId);
            if (response?.id) {
                setData({
                    id: response?.id,
                    name: response?.name,
                    description: response?.description,
                    promptIdentifier: response?.promptIdentifier,
                    title: response?.title?.default,
                    hintText: response?.hintText?.default,
                    minAppVersion: response?.minAppVersion,
                    maxAppVersion: response?.maxAppVersion,
                    backButtonAction: {
                        type: {
                            label: backButtonActionTypeOptions?.find(option => option.value === response?.backButtonAction?.type)?.label ?? response?.backButtonAction?.type,
                            value: response?.backButtonAction?.type
                        },
                        metadata: {
                            deeplink: response?.backButtonAction?.metadata?.deeplink,
                            placementId: response?.backButtonAction?.metadata?.placementId
                        }
                    },
                    defaultOperation: {
                        type: {
                            label: defaultOperationTypeOptions?.find(option => option.value === response?.defaultOperation?.type)?.label ?? response?.defaultOperation?.type,
                            value: response?.defaultOperation?.type
                        },
                        metadata: {
                            promptIdentifier: response?.defaultOperation?.metadata?.promptIdentifier,
                            attributes: response?.defaultOperation?.metadata?.attributes
                        }
                    },
                })
                setAttributesArray(convertAttributes(response?.defaultOperation?.metadata?.attributes));
                setTranslations(convertResponseToTranslations(response?.title, response?.hintText));
            }
            else {
                setData({
                    name: "",
                    description: "",
                    promptIdentifier: 0,
                    title: {},
                    hintText: {},
                    minAppVersion: 0,
                    maxAppVersion: 0,
                    backButtonAction: {},
                    defaultOperation: {}
                });
            }
        }
        catch (err) {
            showErrorModal(errorDescription(error.unexpectedError));
        }
    }, []);

    let fetchPromptTemplates = useCallback(async () => {
        try {
            let response = await GetAIPromptTemplates();
            if (response?.length > 0) {
                setPromptTemplateOptions(response.map(d => ({
                    value: d.id,
                    label: d.identifier,
                })))
            }
            else if (response & response.errorCode) {
                showErrorModal(errorDescription(response.errorCode));
            }
        }
        catch (err) {
            showErrorModal(errorDescription(error.unexpectedError));
        }
    }, []);

    let fetchPlacements = useCallback(async () => {
        try {
            let response = await GetAIPlacements();
            if (response?.length > 0) {
                setPlacementOptions(response.map(d => ({
                    value: d.id,
                    label: d.name,
                })))
            }
            else if (response & response.errorCode) {
                showErrorModal(errorDescription(response.errorCode));
            }
        }
        catch (err) {
            showErrorModal(errorDescription(error.unexpectedError));
        }
    }, []);

    function convertToString(value) {
        return value != null ? String(value) : '';
    }

    function CompareOptions(option, dataValue) {
        return convertToString(option.value) === convertToString(dataValue);
    }

    useEffect(() => {
        if (data?.id) {
            const placementOption = placementOptions?.find(option => CompareOptions(option, data?.backButtonAction?.metadata?.placementId));
            const promptTemplateOption = promptTemplateOptions?.find(option => CompareOptions(option, data?.promptIdentifier));
            setData(prevData => ({
                ...prevData,
                promptIdentifier: promptTemplateOption ? {
                    label: promptTemplateOption?.label ?? prevData.promptIdentifier,
                    value: prevData.promptIdentifier
                } : prevData.promptIdentifier,
                backButtonAction: {
                    ...prevData.backButtonAction,
                    metadata: {
                        ...prevData.backButtonAction?.metadata,
                        placementId: placementOption ? {
                            label: placementOption?.label ?? prevData.backButtonAction?.metadata?.placementId,
                            value: prevData.backButtonAction?.metadata?.placementId
                        } : prevData.backButtonAction?.metadata?.placementId,
                    }
                },
            }));
        } else {
            setData(prevData => ({
                ...prevData
            }));
        }
    }, [data?.id, placementOptions, promptTemplateOptions, data?.backButtonAction?.metadata?.placementId, data?.promptIdentifier]);

    useEffect(() => {
        fetchPlacements();
        fetchPromptTemplates();
        if (pageType !== "create") {
            fetchTask(id);
        }
        setLanguageCode();
    }, [id, pageType, fetchPlacements, fetchPromptTemplates, fetchTask]);

    let showSuccessModal = (text) => {
        setSuccessModalText(text);
        setSuccessModalDisplay(true);
    };

    let successModalClose = () => {
        setSuccessModalDisplay(false);
        history.goBack();
    };

    let failureModalClose = () => {
        setFailureModalDisplay(false);
    };

    let showErrorModal = (text) => {
        setFailureModalText(text);
        setFailureModalDisplay(true);
    };

    const handleSelectPlacement = (event) => {
        setData((prevState) => ({
            ...prevState,
            backButtonAction: {
                ...prevState.backButtonAction,
                metadata: {
                    deeplink: `feature/aiPoweredBar?placementId=${event.value}`,
                    placementId: event
                }
            }
        }))
    }

    const updateTitleData = (translations) => {
        const title = translations.reduce((acc, item) => {
            acc[item.languageCode.value] = item.title;
            return acc;
        }, { default: data.title });
        return title;
    };

    const updateHintTextData = (translations) => {
        const hintText = translations.reduce((acc, item) => {
            acc[item.languageCode.value] = item.hintText;
            return acc;
        }, { default: data.hintText });
        return hintText;
    };

    const updateAttributes = (attributesArray) => {
        const attributes = attributesArray.reduce((acc, item) => {
            acc[item.key] = item.value;
            return acc;
        }, {});
        return attributes;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        for (const translation of translations) {
            if (translation.languageCode && (!translation.title || !translation.hintText)) {
                showErrorModal("Title and Hint Text are required for each language.");
                setLoading(false);
                return;
            }
        }
        let titleValue;
        let hintTextValue;
        titleValue = translations[0]?.title !== "" ? updateTitleData(translations) : data?.title;
        hintTextValue = translations[0]?.hintText !== "" ? updateHintTextData(translations) : data?.hintText;
        let attributesValue;
        attributesValue = attributesArray[0]?.key !== "" ? updateAttributes(attributesArray) : data?.defaultOperation?.metadata?.attributes;
        try {
            const formData = {
                name: data?.name,
                description: data?.description,
                promptIdentifier: data?.promptIdentifier ? data?.promptIdentifier?.value : 0,
                title: titleValue,
                hintText: hintTextValue,
                minAppVersion: data?.minAppVersion?.toString(),
                maxAppVersion: data.maxAppVersion !== "" && data.maxAppVersion !== null
                    ? data.maxAppVersion?.toString()
                    : null,
                backButtonAction: {
                    type: data?.backButtonAction.type?.value,
                    metadata: {
                        deeplink: data?.backButtonAction?.metadata?.deeplink,
                        placementId: data?.backButtonAction?.metadata?.placementId?.value?.toString()
                    }
                },
                defaultOperation: {
                    type: data?.defaultOperation?.type?.value,
                    metadata: {
                        promptIdentifier: data?.promptIdentifier?.value?.toString(),
                        attributes: attributesValue
                    }
                },
            };
            if (!id) {
                let response = await CreateAITask(formData)
                if (response?.status === "aiPoweredBarTaskCreated") {
                    showSuccessModal(taskResponses.addedSuccessfully);
                }
                else {
                    if (response?.errorCode) {
                        showErrorModal(response?.errorMessage)
                    }
                }
            }
            else {
                let response = await UpdateAITask(formData, id)
                if (response?.status === "aiPoweredBarTaskEdited") {
                    showSuccessModal(taskResponses.updatedSuccessfully);
                }
                else {
                    if (response?.errorCode) {
                        showErrorModal(response?.errorMessage)
                    }
                }
            }
        } catch (err) {
            setFailureModalDisplay(true);
            setFailureModalText(errorDescription(error.unexpectedError));
        }
        finally {
            setLoading(false);
        }
    }

    const requiredComponent = (props) => (
        <div>
            <components.Input {...props} required={true} />
        </div>
    );

    const notRequiredComponent = (props) => (
        <div>
            <components.Input {...props} required={false} />
        </div>
    );


    return (
        <>
            {loading ? <Loading /> :
                <Card>
                    <CardBody>
                        <h1 className="h3 mb-3">
                            {pageType === "create" ? "Create Task" : "Edit Task"}
                        </h1>
                        <Form onSubmit={(e) => handleSubmit(e)}>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>
                                            Name <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="text"
                                            name="name"
                                            value={data.name}
                                            placeholder="Enter name"
                                            required
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>
                                            Prompt Template
                                        </Label>  <span className="text-danger">*</span>
                                        <Select
                                            id="promptIdentifier"
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            placeholder="Select Prompt Template"
                                            name="promptIdentifier"
                                            required
                                            value={data.promptIdentifier}
                                            onChange={(event) => {
                                                setData((prevState) => ({
                                                    ...prevState,
                                                    promptIdentifier: event,
                                                    defaultOperation: {
                                                        ...prevState.defaultOperation,
                                                        metadata: {
                                                            ...prevState.defaultOperation?.metadata,
                                                            promptIdentifier: event.value?.toString()
                                                        }
                                                    }
                                                }))
                                            }}
                                            components={{
                                                Input:
                                                    (data.promptIdentifier && data.promptIdentifier?.value)
                                                        ? notRequiredComponent
                                                        : requiredComponent,
                                            }}
                                            options={promptTemplateOptions}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>
                                            Min App Version <span className="text-danger">*</span><ToolTip data={"Minimum required App version of the Task. for eg: 1.000"} />
                                        </Label>
                                        <Input
                                            type="text"
                                            name="minAppVersion"
                                            value={data.minAppVersion}
                                            required
                                            placeholder="Enter Min App Version"
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>
                                            Max App Version
                                        </Label>
                                        <Input
                                            type="text"
                                            name="maxAppVersion"
                                            value={data.maxAppVersion}
                                            placeholder="Enter Max App Version"
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label>
                                            Description
                                        </Label>
                                        <Input
                                            type="textarea"
                                            name="description"
                                            value={data.description}
                                            placeholder="Enter Description"
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>
                                            Title <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="text"
                                            name="title"
                                            value={data.title.default !== "" ? data.title : data.title.default}
                                            placeholder="Enter Title"
                                            required
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>
                                            Hint Text <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="text"
                                            name="hintText"
                                            value={data.hintText.default !== "" ? data.hintText : data.hintText.default}
                                            placeholder="Enter Hint Text"
                                            required
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <Row className="mb-3 mt-3">
                                        <Col md={6} xs={6}>
                                            <h3>Translations</h3>
                                        </Col>
                                        <Col md={6} xs={6}>
                                            <div className="p-0">
                                                <Button
                                                    color="success"
                                                    className="ml-auto d-flex btn-success"
                                                    onClick={() =>
                                                        setTranslations(
                                                            translations.concat({
                                                                languageCode: "",
                                                                title: "",
                                                                hintText: "",
                                                            })
                                                        )
                                                    }
                                                >
                                                    <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                                                        Add Language
                                                    </p>
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Card>
                                        <CardBody>
                                            {translations?.map((item, index) => {
                                                return (
                                                    <Row key={index} form>
                                                        <Col md={3}>
                                                            <FormGroup>
                                                                <Label
                                                                    className="font-weight-bolder mb-2"
                                                                    style={{ fontSize: "16px" }}
                                                                >
                                                                    Language Code:{" "}
                                                                </Label>
                                                                <Select
                                                                    id="languageCode"
                                                                    className="react-select-container"
                                                                    classNamePrefix="react-select"
                                                                    name="languageCode"
                                                                    placeholder="Select Language Code"
                                                                    value={item.languageCode}
                                                                    onChange={(event) =>
                                                                        handleLanguageCodeChange(event, index)
                                                                    }
                                                                    options={showLanguageCodeOptions()}
                                                                    required
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={4}>
                                                            <FormGroup>
                                                                <Label className="font-weight-bolder mb-2" style={{ fontSize: "16px" }}>
                                                                    Title Translation:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    name="title"
                                                                    placeholder="Add a translation"
                                                                    value={item.title}
                                                                    onChange={(event) => handleTextChange(event, index)}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={4}>
                                                            <FormGroup>
                                                                <Label className="font-weight-bolder mb-2" style={{ fontSize: "16px" }}>
                                                                    Hint Text Translation:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    name="hintText"
                                                                    placeholder="Add a translation"
                                                                    value={item.hintText}
                                                                    onChange={(event) => handleTextChange(event, index)}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={1}>
                                                            <div className="p-0" style={{ marginRight: "20px" }}>
                                                                <X
                                                                    className="d-none d-lg-block ml-auto d-flex"
                                                                    size={iconSize}
                                                                    color="red"
                                                                    style={{ cursor: "pointer", border: "2px solid red" }}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setTranslations(translations.filter((_, i) => i !== index));
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>

                                                        <hr
                                                            style={{
                                                                width: "75rem",
                                                                color: "black",
                                                            }}
                                                        />
                                                    </Row>
                                                );
                                            })}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <Row className="mb-3 mt-3">
                                        <Col md={6} xs={6}>
                                            <h3>Back Button Action <ToolTip data={"Action triggered by the back button"} id="backButtonAction" /></h3>
                                        </Col>
                                    </Row>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label>
                                                            Type
                                                        </Label> <span className="text-danger">*</span>
                                                        <Select
                                                            id="version"
                                                            className="react-select-container"
                                                            classNamePrefix="react-select"
                                                            placeholder="Select Action Type"
                                                            name="actionType"
                                                            value={data.backButtonAction?.type}
                                                            onChange={(event) => {
                                                                setData((prevState) => ({
                                                                    ...prevState,
                                                                    backButtonAction: {
                                                                        type: event,
                                                                    }
                                                                }))
                                                            }}
                                                            options={backButtonActionTypeOptions}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3 mt-3">
                                                <Col md={6} xs={6}>
                                                    <h4>Meta Data</h4>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label>
                                                            Deep Link
                                                        </Label> <span className="text-danger">*</span>
                                                        <Input
                                                            type="text"
                                                            name="taskId"
                                                            value={data.backButtonAction?.metadata?.deeplink}
                                                            placeholder=""
                                                            required
                                                            onChange={handleChange}
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label>
                                                            Placement
                                                        </Label> <span className="text-danger">*</span>
                                                        <Select
                                                            id="placement"
                                                            className="react-select-container"
                                                            classNamePrefix="react-select"
                                                            placeholder="Select Placement"
                                                            name="placement"
                                                            value={data.backButtonAction?.metadata?.placementId}
                                                            onChange={handleSelectPlacement}
                                                            options={placementOptions}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <Row className="mb-3 mt-3">
                                        <Col md={6} xs={6}>
                                            <h3>Default Operation <ToolTip data={"Metadata for the default operation containing attributes"} id="defaultOperation" /></h3>
                                        </Col>
                                    </Row>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label>
                                                            Type
                                                        </Label> <span className="text-danger">*</span>
                                                        <Select
                                                            id="version"
                                                            className="react-select-container"
                                                            classNamePrefix="react-select"
                                                            placeholder="Select Action Type"
                                                            name="actionType"
                                                            value={data.defaultOperation?.type}
                                                            onChange={(event) => {
                                                                setData((prevState) => ({
                                                                    ...prevState,
                                                                    defaultOperation: {
                                                                        type: event
                                                                    }
                                                                }))
                                                            }}
                                                            options={defaultOperationTypeOptions}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3 mt-3">
                                                <Col md={6} xs={6}>
                                                    <h4>Meta Data</h4>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label>
                                                            Prompt Identifier
                                                        </Label> <span className="text-danger">*</span>
                                                        <Input
                                                            type="text"
                                                            name="promptIdentifier"
                                                            value={data.promptIdentifier.label}
                                                            placeholder="Enter Prompt Identifier"
                                                            required
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3 mt-3">
                                                <Col md={6} xs={6}>
                                                    <h4>Attributes</h4>
                                                </Col>
                                            </Row>
                                            <Card>
                                                <CardBody>
                                                    {attributesArray && attributesArray.map((item, index) => {
                                                        return (
                                                            <Row key={index}>
                                                                <Col sm={5}>
                                                                    <FormGroup>
                                                                        <Input
                                                                            type="text"
                                                                            name="attributekey"
                                                                            value={item.key}
                                                                            placeholder="Enter Key"
                                                                            onChange={(e) => {
                                                                                handleAttributeKey(e, index);
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm={5}>
                                                                    <FormGroup>
                                                                        <Input
                                                                            type="text"
                                                                            name="attributeValue"
                                                                            value={item.value}
                                                                            placeholder="Enter Value"
                                                                            onChange={(e) => {
                                                                                handleAttributeValue(e, index);
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm={1}>
                                                                    <X
                                                                        className="d-none d-lg-block mt-1"
                                                                        size={iconSize}
                                                                        color="red"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={(e) => {
                                                                            subtractRow(index);
                                                                        }}
                                                                    ></X>
                                                                </Col>
                                                            </Row>
                                                        );
                                                    })}
                                                    <Button
                                                        className="d-block pr-3 text-decoration-none"
                                                        color="secondary"
                                                        onClick={() => {
                                                            addRow();
                                                        }}
                                                    >
                                                        <FontAwesomeIcon size="sm" icon={faPlus}></FontAwesomeIcon>{" "}
                                                        Add More
                                                    </Button>

                                                </CardBody>
                                            </Card>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Button color="primary">Submit</Button>
                            <ResponseModal
                                show={successModalDisplay}
                                onHide={successModalClose}
                                modalheading={successModalHeading}
                                modaltext={successModalText}
                            />
                            <ResponseModal
                                show={failureModalDisplay}
                                onHide={failureModalClose}
                                modalheading={errorModalHeading}
                                modaltext={failureModalText}
                            />
                        </Form>
                    </CardBody>
                </Card>
            }
        </>
    );
};
const CreateTask = () => (
    <Container fluid className="p-0">
        <Row>
            <Col lg="12">
                <TaskForm />
            </Col>
        </Row>
    </Container>
);
export default CreateTask;

import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  CardImg,
  Label,
  Row,
  Spinner,
  CustomInput,
} from "reactstrap";
import moment from "moment";
import ResponseModal from "../ResponseModal";
import { components } from "react-select";
import { useParams, useLocation } from 'react-router-dom';
import { createUpdateStory, fetchTaasClients, fetchTaasStory, fetchTaasStoryURL, generatePreview } from "../../../utilities/apiUtils/taasDashboard";
import {
  errorDescription,
  error,
} from "../../../utilities/commonUtil";
import Select from "react-select";
import { Chips } from 'primereact/chips';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import { genderOptions, typeOfStoryOptions, config, storyResponses, createMusicalStory, showActivateStoryButton } from "../../../config/configTaasDashboard";
import { successModalHeading } from "../../../config";
import { useHistory } from "react-router-dom"
import { errorModalHeading } from "../../../config/config";
import Loading from "../../../components/Loading";
import Routes from "../../../routes/index";
import { debounce } from "../../../utilities/debounce";

const StoryForm = () => {
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const currentPath = location.pathname.split('/');
  const pageType = currentPath[currentPath.length - 1];
  const [isLoading, setIsLoading] = useState(false);
  const [successModalDisplay, setSuccessModalDisplay] = useState(false);
  const [clients, setClients] = useState([]);
  const [imageURL, setImageURL] = useState('');
  const [successModalText, setSuccessModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [failureModalText, setFailureModalText] = useState("");
  const [selectedStoryIds, setSelectedStoryIds] = useState([]);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    type: typeOfStoryOptions[0],
    musicUrl: '',
    storyId: '',
    storyIds: [],
    clientId: [],
    gender: genderOptions[0],
    startDate: "",
    endDate: "",
    activatedAt: false,
    deactivatedAt: true,
  })

  const handleChange = (event) => {
    const { name, value } = event.target
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  let fetchClientsData = async () => {
    try {
      let response = await fetchTaasClients()
      if (response?.length > 0) {
        setClients(response.map(d => ({
          value: d.id,
          label: d.name,
        }))
        )
      }
      else if (response && response.errorCode) {
        showErrorModal(errorDescription(response.errorCode));
      }
    } catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  }

  const handleCheckboxChange = (event) => {
    const { checked } = event.target;
    setData({
      ...data,
      activatedAt: checked,
      deactivatedAt: !checked,
    });
  };

  const fetchStoryDetails = async (id, storyType) => {
    try {
      let story = await fetchTaasStory(id, storyType);
      if (story?.length > 0 && story[0]?.storyId) {
        story = story[0];
        await fetchData(story.storyId);
        setData({
          id: story.id,
          storyId: story.storyId,
          clientId: {
            label: clients.find(option => option.value === story.clientId)?.label ?? story.clientName,
            value: story.clientId
          },
          type: {
            label: typeOfStoryOptions.find(option => option.value === story.type)?.label ?? story.type,
            value: story.type
          },
          gender: {
            label: genderOptions.find(option => option.value === story.gender)?.label ?? story.gender,
            value: story.gender
          },
          startDate: story.startDate,
          endDate: story.endDate,
          activatedAt: new Date(story.deactivatedAt) > new Date() || story.deactivatedAt === null ? true : false,
          deactivatedAt: new Date(story.deactivatedAt) > new Date() || story.deactivatedAt === null ? false : true,
        })
      }
      else if (story?.length > 0 && story[0]?.storyIds) {
        story = story[0];
        let ids = story.storyIds.split(",").map(Number);
        for (let i = 0; i < ids.length; i++) {
          let url = await fetchUrl(ids[i]);
          selectedStoryIds.push({ label: ids[i].toString(), val: url });
        }
        setData({
          id: story.id,
          storyIds: story.storyIds.split(","),
          clientId: {
            label: clients.find(option => option.value === story.clientId)?.label ?? story.clientName,
            value: story.clientId
          },
          type: {
            label: typeOfStoryOptions.find(option => option.value === story.type)?.label ?? story.type,
            value: story.type
          },
          gender: {
            label: genderOptions.find(option => option.value === story.gender)?.label ?? story.gender,
            value: story.gender
          },
          musicUrl: story.musicUrl,
          startDate: story.startDate,
          endDate: story.endDate,
          activatedAt: new Date(story.deactivatedAt) > new Date() || story.deactivatedAt === null ? true : false,
          deactivatedAt: new Date(story.deactivatedAt) > new Date() || story.deactivatedAt === null ? false : true,
        });
      }
      else {
        setData({
          type: typeOfStoryOptions[0],
          musicUrl: '',
          storyId: '',
          storyIds: [],
          clientId: [],
          gender: genderOptions[0],
          startDate: "",
          endDate: "",
          activatedAt: false,
          deactivatedAt: true,
        })
      }
    }
    catch (err) {
      showErrorModal(errorDescription(error.unexpectedError));
    }
  }

  useEffect(() => {
    if (pageType !== "create" && id) {
      let storyType = currentPath[currentPath.length - 3];
      fetchStoryDetails(id, storyType);
    }
  }, [id, pageType]);

  useEffect(() => {
    fetchClientsData();
  }, []);

  const fetchData = async (storyId) => {
    try {
      const res = await fetchTaasStoryURL(storyId);
      if (res.story) {
        setImageURL(res.story?.fixedWidthSmall?.jpg?.url);
      }
      else {
        setImageURL('');
      }
    } catch (err) {
      setImageURL('');
    }
    finally {
      setIsLoading(false);
    }
  };
  const handleStoryIdChange = (event) => {
    const { name, value } = event.target
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }))
    debounce(() => {
      setIsLoading(true);
      fetchData(value).finally(() => setIsLoading(false))
    }, config.debounceTime)
  };

  const renderChip = (item, index) => {
    return <div key={index} className="text-2xl text-black">{item.label}</div>;
  };

  const handleAddChip = async (newChip) => {
    let url = await fetchUrl(newChip.value);
    if (url.length > 1) {
      setData((prevState) => ({
        ...prevState,
        storyIds: [...prevState.storyIds, newChip.value],
      })
      )
    }
    setSelectedStoryIds((prevState) => [...prevState, { label: newChip.value, val: url }]);
  };

  const handleRemoveChip = (chip) => {
    chip = chip.value;
    setSelectedStoryIds((prevState) => {
      const newSequence = [...prevState];
      newSequence.splice(selectedStoryIds.findIndex((value) => value === chip[0]), 1);
      return newSequence;
    })
    let tempArr = data.storyIds;
    if (tempArr.findIndex((value) => value === chip[0].label) >= 0)
      tempArr.splice(tempArr.findIndex((value) => value === chip[0].label), 1);
    setData((prevState) => ({
      ...prevState,
      storyIds: tempArr,
    })
    )
  };

  const fetchUrl = async (val) => {
    let ans = '';
    try {
      const res = await fetchTaasStoryURL(val);
      if (res.errorCode) {
        ans = '';
      }
      if (res.story) {
        ans = res.story?.fixedWidthSmall?.jpg?.url;
      }
    }
    catch (err) {
      console.error('Error fetching data:', err);
    }
    return ans;
  }

  let showSuccessModal = (text) => {
    setSuccessModalText(text);
    setSuccessModalDisplay(true);
  };

  let successModalClose = () => {
    setSuccessModalDisplay(false);
    history.goBack();
  };

  let failureModalClose = () => {
    setFailureModalDisplay(false);
  };

  let showErrorModal = (text) => {
    setFailureModalText(text);
    setFailureModalDisplay(true);
  };

  const requiredComponent = (props) => (
    <div>
      <components.Input {...props} required={true} />
    </div>
  );

  const notRequiredComponent = (props) => (
    <div>
      <components.Input {...props} required={false} />
    </div>
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    const startDate = new Date(data.startDate);
    const endDate = new Date(data.endDate);
    if (startDate > endDate) {
      showErrorModal("Start Date should come before End Date.");
    }
    else {
      let formData = new FormData();
      data.id && formData.append("id", data.id);
      data.storyId && formData.append("storyId", data.storyId);
      data.storyIds && formData.append("storyIds", data.storyIds);
      formData.append("clientId", data.clientId?.value);
      formData.append("type", data.type?.value);
      data.musicUrl && formData.append("musicUrl", data.musicUrl)
      formData.append("gender", data.gender?.value);
      formData.append("startDate", data.startDate);
      formData.append("endDate", data.endDate);
      formData.append("activatedAt", data.activatedAt);
      formData.append("deactivatedAt", data.deactivatedAt);

      try {
        if ((data.type.value === "static" && !imageURL) || (data.type.value === "musical" && data.storyIds?.length === 0)) {
          showErrorModal("Enter a valid story id");
        }
        else {
          if (pageType === "create") {
            let response = await createUpdateStory(formData)
            if (response.code === "storyAdded") {
              showSuccessModal(storyResponses.addedSuccessfully);
            }
            else {
              if (response.errorDescription) {
                showErrorModal(response.errorDescription)
              }
            }
          }
          else {
            let response = await createUpdateStory(formData);
            if (response.code === "storyUpdated") {
              showSuccessModal(storyResponses.updatedSuccessfully);
            }
            else {
              if (response.errorDescription) {
                showErrorModal(response.errorDescription)
              }
            }
          }
        }
      } catch (err) {
        setFailureModalDisplay(true);
        setFailureModalText(errorDescription(error.unexpectedError));
      }
    }
  }
  const generateFinalPreview = async (e) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("gender1", createMusicalStory.gender1);
    formData.append("imageUrl1", createMusicalStory.imageUrl1);
    formData.append("gender2", createMusicalStory.gender2);
    formData.append("imageUrl2", createMusicalStory.imageUrl2);
    formData.append("storyIds", data.storyIds);
    formData.append("userId", createMusicalStory.userId);
    formData.append("bounce", createMusicalStory.bounce);
    formData.append("vibrate", createMusicalStory.vibrate);
    formData.append("logoUrl", createMusicalStory.logoUrl);
    formData.append("videoScale", createMusicalStory.videoScale);
    formData.append("musicUrl", data.musicUrl);
    try {
      if (data.storyIds?.length > 0 && data.musicUrl !== "") {
        let response = await generatePreview(formData);
        if (response) {
          setLoading(false);
          let url = response.contents[0].media.fixedWidthFull.mp4.url;
          window.open(url);
        }
      }
      else {
        showErrorModal("Enter valid story id and music url")
      }
    }
    catch (err) {
      setFailureModalDisplay(true);
      setFailureModalText(errorDescription(error.unexpectedError));
    }
    finally {
      setLoading(false);
    }
  }

  const handleStartDateChange = (event) => {
    const { value } = event.target;
    setData((prevState) => ({
      ...prevState,
      startDate: moment(value).format("YYYY-MM-DD"),
    }));
  }

  const handleEndDateChange = (event) => {
    const { value } = event.target;
    setData((prevState) => ({
      ...prevState,
      endDate: moment(value).format("YYYY-MM-DD"),
    }));
  }

  let getdateValue = (date) => {
    if (date === null) {
      return null;
    }
    return moment(date).format("YYYY-MM-DD");
  }

  return (
    <>
      <h1 className="h3 mb-3">
        {pageType !== "create" ? "Edit Story" : "Add Story"}
      </h1>
      {loading ? (
        <Loading height="50px" weight="50px" />
      ) : (
        <Card>
          <CardBody>
            <Form onSubmit={(e) => handleSubmit(e)}>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      Type of story <span className="text-danger">*</span>
                    </Label>
                    {pageType === "create" &&
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder="Select Story Type"
                        name="type"
                        required
                        value={data.type}
                        onChange={(event) => {
                          setData((prevState) => ({
                            ...prevState,
                            type: event,
                          }))
                        }}
                        options={typeOfStoryOptions}
                      />}
                    {pageType !== "create" &&
                      <Input
                        type="text"
                        name="type"
                        value={data.type.label}
                        disabled
                        required
                      />}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>Client <span className="text-danger">*</span></Label>
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      placeholder="Select Client"
                      name="client"
                      required
                      value={data.clientId}
                      onChange={(event) => {
                        setData((prevState) => ({
                          ...prevState,
                          clientId: event,
                        }))
                      }}
                      components={{
                        Input:
                          (data.clientId && data.clientId?.value)
                            ? notRequiredComponent
                            : requiredComponent,
                      }}
                      options={clients}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <Label>
                    Start Date <small className="text-info">(DD/MM/YYYY format)</small>
                  </Label>
                  <Input
                    type="date"
                    name="startDate"
                    onChange={handleStartDateChange}
                    value={getdateValue(data.startDate)}
                  />
                </Col>
                <Col md={6}>
                  <Label>
                    End Date <small className="text-info">(DD/MM/YYYY format)</small>
                  </Label>
                  <Input
                    type="date"
                    name="endDate"
                    onChange={handleEndDateChange}
                    value={getdateValue(data.endDate)}
                  />
                </Col>
              </Row>
              <Row form className="mt-3">
                <Col md={6}>
                  <FormGroup>
                    <Label>
                      Gender
                    </Label>
                    <Select
                      className="react-select-container"
                      id="statusSelect"
                      classNamePrefix="react-select"
                      name="gender"
                      placeholder="Select Gender"
                      value={data.gender}
                      onChange={(event) => {
                        setData((prevState) => ({
                          ...prevState,
                          gender: event,
                        }))
                      }}
                      options={genderOptions}
                      defaultValue={{ label: "Unisex", value: "unisex" }}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  {data.type.value === "musical" &&
                    <FormGroup>
                      <Label>Music URL <span className="text-danger">*</span></Label>
                      <Input
                        type="text"
                        name="musicUrl"
                        value={data.musicUrl}
                        placeholder="Enter Music URL"
                        required
                        onChange={handleChange}
                      />
                    </FormGroup>
                  }
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  {data.type.value === "static" ? <FormGroup>
                    <Label>
                      Bobble Story ID <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="number"
                      name="storyId"
                      value={data.storyId}
                      placeholder="Enter Bobble Story ID"
                      required
                      onChange={handleStoryIdChange}
                    />
                  </FormGroup> : ''}
                  {data.type.value === "musical" ?
                    <FormGroup>
                      <Label>
                        Bobble Story IDs <span className="text-danger">*</span><small className="text-info"> (Press enter after entering a story id)</small>
                      </Label>
                      <div>
                        <Chips
                          value={selectedStoryIds}
                          onAdd={handleAddChip}
                          onRemove={handleRemoveChip}
                          itemTemplate={renderChip}
                        />
                      </div>
                    </FormGroup> : ''}
                </Col>
                <Col>
                  {data.type.value === "musical" &&
                    <div className="pt-4">
                      <Button
                        className="btn-secondary mt-1"
                        onClick={generateFinalPreview}
                      >
                        Generate Preview
                      </Button>
                    </div>
                  }
                </Col>
              </Row>
              {pageType === "create" && showActivateStoryButton() ?
                <Row>
                  <Col xs={12}>
                    <FormGroup className="d-flex align-items-center">
                      <Label
                        className="font-weight-bolder mb-0"
                        style={{ fontSize: "14px" }}
                      >
                        Activate
                      </Label>
                      <div className="custom-control custom-switch">
                        <CustomInput
                          type="checkbox"
                          id="activateStickersByDefault"
                          onChange={handleCheckboxChange}
                          checked={data.activatedAt}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row> : ''}
              <Row form>
                <Col md={data.type.value === "static" ? 6 : 12}>
                  <FormGroup>
                    <Label>
                      Preview
                    </Label>
                    {data.type.value === "static" &&
                      <div style={{ display: 'flex' }}>
                        <div style={{ flex: '0 0 auto', margin: 'auto', width: '200px' }}>
                          {isLoading ? (
                            <Spinner color="info" className="d-flex mx-auto" />
                          ) : imageURL ? (
                            <CardImg
                              top
                              src={imageURL ?? ""}
                              alt="Card image cap"
                              style={{ width: '100%' }}
                            />
                          ) : (
                            <>No Story</>
                          )}
                        </div>
                      </div>}
                    {data.type.value === "musical" && selectedStoryIds?.length > 0 &&
                      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        {selectedStoryIds.map((item, i) => (
                          <div style={{ flex: '0 0 auto', margin: '10px', width: '250px' }}>
                            {item.val ? <CardImg
                              key={i}
                              className="my-2"
                              top
                              src={item.val ?? ""}
                              alt="Card image cap"
                              style={{ width: '100%' }}
                            /> : <div>No story</div>
                            }
                          </div>
                        ))}
                      </div>}
                  </FormGroup>
                </Col>
              </Row>
              <Button color="primary">Submit</Button>
            </Form>
            <ResponseModal
              show={successModalDisplay}
              onHide={successModalClose}
              modalheading={successModalHeading}
              modaltext={successModalText}
            />
            <ResponseModal
              show={failureModalDisplay}
              onHide={failureModalClose}
              modalheading={errorModalHeading}
              modaltext={failureModalText}
            />
          </CardBody>
        </Card >
      )}
    </>
  )
}
const ManageStory = () => (
  <Container fluid className="p-0">
    <Row>
      <Col lg="12">
        <StoryForm />
      </Col>
    </Row>
  </Container>
)
export default ManageStory;
import React, { Component } from "react";
import { Modal, Button, Row, Card, CardImg, Col } from "react-bootstrap";
import Reorder, { reorder } from "react-reorder";
import Select from "react-select";

class ImportGifs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gifs: props.gifs,
      id: [],
      gifPackId: null,
      gifPacks: [],
    };
  }

  componentWillReceiveProps() {
    let id = [];
    this.props.gifs.forEach((gif) => id.push(gif.id));

    this.setState({
      gifs: this.props.gifs,
      gifPacks: this.props.gifPacks,
      id: id,
    });
  }

  onReorder = (event, previousIndex, nextIndex, fromId, toId) => {
    this.setState({
      gifs: reorder(this.props.gifs, previousIndex, nextIndex),
    });
    let id = [];
    this.props.gifs.forEach((gif) => id.push(gif.id));
    this.setState({ id });
  };

  submitRearrangedList = () => {
    let gifs = {
      gifIds: this.props.gifs.map((gif) => gif.id),
    };
    this.props.import(gifs, this.state.gifPackId);
  };

  handleGifPackChange = (gifPack) => {
    this.setState({ gifPackId: gifPack.value });
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header className="bg-light" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Import Gifs
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Row>
            <Col md={6}>
              <h4>{this.props.gifs?.length} Gif(s) selected</h4>
              <Row>
                <Reorder reorderId="reorder-list" disabled={true} onReorder={this.onReorder}>
                  {this.props.gifs.map((gif, index) => {
                    console.log("gif", gif);
                    return (
                      <div key={index} className="reorderContainer">
                        <Card className="m-3 responseCard">
                          <CardImg src={gif.imageURL} />
                        </Card>
                      </div>
                    );
                  })}
                </Reorder>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col lg={8}>
                  <Select
                    className="react-select-container"
                    id="gifPackSelect"
                    classNamePrefix="react-select"
                    name="gifPackId"
                    placeholder="Select Gif Pack"
                    onChange={this.handleGifPackChange}
                    options={this.state.gifPacks}
                  />
                </Col>
              </Row>
              <br />
              <h5 className="text-muted">Instructions:</h5>
              <ul>
                <li>
                  <small className="text-muted">
                    Choose a Gif pack in which you want to add the
                    selected Gifs.
                  </small>
                </li>
                <li>
                  <small className="text-muted" >
                    <span style={{ color: "red", fontWeight: "bold" }}> {" "}
                      Note: The gifs will have same targeting rules even after importing to another pack, edit the gifs to add custom rules such that targeting rules are compatible for both the packs.
                    </span>
                  </small>
                </li>

                <li>
                  <small className="text-muted">
                    Click{" "}
                    <span style={{ color: "#000", fontWeight: 600 }}>
                      Submit
                    </span>{" "}
                    to add the selected Gifs to Gif pack.
                  </small>
                </li>
              </ul>
            </Col>
          </Row>
          <Modal.Footer>
            <Button
              onClick={this.submitRearrangedList}
              disabled={!this.state.gifPackId || this.props.gifs?.length == 0}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ImportGifs;

import React, { useState } from "react";
import { retrieveFromLocalStorage } from "../../../../utilities/localStorageUtil";
import { Config } from "../../../../config";
import {
  redirectTo,
  redirectToNewTab,
  getStatus,
  getStatusColor,
  getBtnName,
  getButtonColor,
  permissions,
} from "../../../../utilities/commonUtil";
import {
  Row,
  Col,
  Badge,
  Card,
  Container,
  CardImg,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";

export const AdBannerCard = (props) => {
  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );
  const [adBanner] = useState(props.adBanner);

  const showDetailsButton = () => {
    return allowedPermissions.includes(permissions.quickReplyAdbannerDetails);
  };

  const showTargetingRulesButton = () => {
    return allowedPermissions.includes(
      permissions.quickReplyAdbannerRulesView
    );
  };

  const showButton = (btnName) => {
    if (btnName === Config.ACTIVATE_BUTTON)
      return allowedPermissions.includes(permissions.quickReplyAdbannerActivate);
    else if (btnName === Config.DEACTIVATE_BUTTON)
      return allowedPermissions.includes(permissions.quickReplyAdbannerDeactivate);
    else if (btnName === Config.PUBLISH_BUTTON)
      return allowedPermissions.includes(permissions.quickReplyAdbannerPublish);
    else return false;
  };

  let status = getStatus(adBanner.publishedAt, adBanner.deactivatedAt);

  return (
    <Card>
      <React.Fragment>
        {adBanner.bannerImageURL ? (
          <div
            style={{
              overflow: "hidden", height: "200px", backgroundSize: "contain", backgroundPosition: "center center", backgroundRepeat: "no-repeat",     backgroundImage:  `url(${adBanner.bannerImageURL}) , linear-gradient(rgba(200, 200, 200, 0.5), rgba(200, 200, 200, 0.5))`

            }}
          >
          </div>
        ) : (
          <div className="theme-preview-image bg-dark"></div>
        )}
      </React.Fragment>
      <CardBody>
        <small></small>
        <CardTitle tag="h3" className="mb-0">
          ID : {adBanner.id} <Badge className="float-right" color={getStatusColor(status)}>
            {status}
          </Badge>

        </CardTitle>
        <Col className="mt-4">
        <Row>
            <small className="font-weight-bold">
              name : {adBanner.name}
            </small>
          </Row>
          <Row>
            <small className="font-weight-bold">
              Type : {adBanner.type}
            </small>
          </Row>
          <Row>
            <small className="font-weight-bold">
              Media Type : {adBanner.mediaType}
            </small>
          </Row>
          <Row>
            <small className="font-weight-bold">
              Created At : {adBanner.createdAt}
            </small>
          </Row>
          <Row>
            <small className="font-weight-bold">
              Updated At : {adBanner.updatedAt}
            </small>
          </Row>

          <Row>
            <small className="font-weight-bold">
              Published At : {adBanner.publishedAt}
            </small>
          </Row>

          <Row>
            <small className="font-weight-bold">
              Deactivated At : {adBanner.deactivatedAt}
            </small>
          </Row>
        </Col>
        <Container className="my-4">
          <Row className="justify-content-center">
            {showDetailsButton() && (
              <Col xs={12} sm={6} className="mb-2">
                <Button
                  className="mw-75 w-100"
                  color="info"
                  onClick={() => {
                    redirectToNewTab(`/quick-reply/ad-banners/${adBanner.id}`);
                  }}
                >
                  Details
                </Button>
              </Col>
            )}
            {showButton(getBtnName(status)) && (
              <Col xs={12} sm={6} className="mb-2">
                <Button
                  className="mw-100 w-100"
                  style={{
                    paddingLeft : "7px",
                    paddingRight : "7px"
                  }}
                  color={getButtonColor(status)}
                  onClick={() => {
                    props.getConfirmation(adBanner.id, getBtnName(status));
                  }}
                >
                  {getBtnName(status)}
                </Button>
              </Col>
            )}
          </Row>
          {showTargetingRulesButton() && (
            <Row className="justify-content-center">
              <Col xs={12} sm={12} className="mb-2">
                <Button
                  className="mw-100 w-100"
                  color="secondary"
                  style={{
                    width: "100%",
                  }}
                  onClick={() => {
                    redirectToNewTab(`/quick-reply/ad-banners/targeting-rules/${adBanner.id}`);
                  }}
                >
                  Edit Targeting Rules
                </Button>
              </Col>


            </Row>
          )}
        </Container>

      </CardBody>
    </Card>
  );
};

export default AdBannerCard;

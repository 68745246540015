import React from "react";
import { Col } from "reactstrap";

const EmptyQuickReplyCategories = () => {
  return (
    <Col sm="12" md="8" lg="6" className="mx-auto d-table mt-5">
      <h1 className="text-center">No Quick Repley Categories Available</h1>
    </Col>
  );
};

export default EmptyQuickReplyCategories;

import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Button, Overlay, Tooltip } from "react-bootstrap";
import { columns } from "./appResourceColumns";
import filterFactory from "react-bootstrap-table2-filter";
import Loading from "../../../components/Loading";
import paginationFactory from "react-bootstrap-table2-paginator";
import Search from "../../../components/Search";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { ManageAppResources } from "./ManageAppResources";
import { fetchAppResources } from "../../../utilities/apiUtils/appResources";
import { showAddAppResourcesButton } from "../../../config/ConfigAppResources";

const AppResourceTable = () => {
  const [loading, setLoading] = useState(true);
  const [searchString, setSearchString] = useState("");
  const [appResources, setAppResources] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pagesAfterQuery, setPagesAfterQuery] = useState(0);
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [failureModalText, setFailureModalText] = useState("");
  const [failureModalDisplay, setFailureModalDisplay] = useState(false);
  const [showAppResources, setShowAppResources] = useState(false);
  const [modalData, setModalData] = useState({});

  const closeCreateAppResources = () => {
    setShowAppResources(false);
    setModalData({});
    fetchAppResourceTableData(currentPage, 10);
  };

  const unsecuredCopyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
    document.body.removeChild(textArea);
  };

  const copyToClipboard = (content) => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(content);
    } else {
      unsecuredCopyToClipboard(content);
    }
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <ul>
          <Row className="justify-content-md-center mx-auto mb-4">
            <Col md={6} sm={12}>
              <li>ID: {row.id !== null ? row.id : "null"}</li>
              <li>
                Description:{" "}
                {row.description !== null ? row.description : "null"}
              </li>
              <li>Filename: {row.filename !== null ? row.filename : "null"}</li>
              {row?.compressedURL && <li> Compressed URL: {row.compressedURL !== null ? row.compressedURL : "null"}</li>}
              <li>Original URL: {row.originalURL !== null ? row.originalURL : "null"}</li>   
              <li>
                Created At: {row.createdAt !== null ? row.createdAt : "null"}
              </li>
            </Col>
            <Col md={6} sm={12}>
              {row.filename &&
                /\.(png|jpe?g|gif|mp4|webm)$/i.test(row.filename) && (
                  <div className="d-flex justify-content-center">
                    <img
                      src={row?.compressedURL ?? row?.originalURL}
                      alt="preview"
                      style={{ width: "20%", height: "20%" }}
                    />
                  </div>
                )}
            </Col>
          </Row>

          <Button
            id={row.id}
            key={"button" + row.id}
            ref={target}
            onClick={() => {
              copyToClipboard(row?.compressedURL ?? row?.originalURL);
              setShow(!show);
              setTimeout(() => {
                setShow(false);
              }, 1000);
            }}
          >
            Copy to Clipboard
          </Button>
          <Overlay
            key={"overlay" + row.id}
            target={target.current}
            show={show}
            placement="right"
            id={row.id}
          >
            {(props) => (
              <Tooltip id="overlay-example" key={"tooltip" + row.id} {...props}>
                Copied!
              </Tooltip>
            )}
          </Overlay>
        </ul>
      </div>
    ),
  };

  const filterAppResources = () => {
    let items = appResources;
    if (searchString) {
      // filter description
      items = items.filter((item) => {
        if (item.description) {
          return item.description
            .toLowerCase()
            .includes(searchString.toLowerCase());
        }
        return false; // Handle cases where item.description is null
      });
    }

    return items;
  };

  const processDateTime = (data) => {
    if (data && data.appResources && Array.isArray(data.appResources)) {
      data.appResources.forEach((item) => {
        if (item.createdAt) {
          item.createdAt = new Date(item.createdAt)
            .toLocaleString()
            .replace(/\//g, "-");
        }
      });
    }
    return data;
  };

  const fetchAppResourceTableData = async (page, sizePerPage) => {
    try {
      setLoading(true);
      const response = await fetchAppResources(page, sizePerPage, searchString, "desc");
  
      if (response?.appResources?.length) {
        const processedData = processDateTime(response);
  
        if (page === 1) {
          // Clear appResources when navigating to the first page
          setAppResources(processedData.appResources);
        } else {
          // Append new data to appResources for subsequent pages
          setAppResources((prevAppResources) => [
            ...prevAppResources,
            ...processedData.appResources,
          ]);
        }
  
        // Update totalPages based on the total count of resources
        setTotalPages(Math.ceil(response.totalCount / sizePerPage));
      } else {
        // Clear appResources if no data is returned
        setAppResources([]);
        setTotalPages(0);
      }
  
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error("Error fetching app resources:", err);
    }
  };
  
  
  
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoading(true);

        const response = await fetchAppResources(1, 10, searchString, "desc");

        if (response && response.appResources) {
          if (response.appResources.length > 0) {
            setAppResources(processDateTime(response.appResources));
            setTotalPages(Math.ceil(response.totalCount / 10));
          } else {
            setAppResources([]);
          }
        } else {
          console.error("Invalid response received from the API");
        }

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error("Error fetching initial data:", err);
      }
    };

    fetchInitialData();
  }, [searchString]);

  return (
    <Container fluid className="p-0">
      <Row className="mb-3">
        <Col md={6} xs={6}>
          <h1>App Resources</h1>
        </Col>
        <Col md={6} xs={6}>
          <div className="p-0">
            {showAddAppResourcesButton() && (
              <Button
                color="success"
                className="ml-auto d-flex btn-success"
                onClick={() => setShowAppResources(true)}
              >
                <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                  Add App Resources
                </p>
              </Button>
            )}

            {showAppResources && (
              <ManageAppResources
                show={showAppResources}
                onHide={closeCreateAppResources}
                action={"add"}
              />
            )}
          </div>
        </Col>
      </Row>
      <div>
        <Card style={{ overflow: "hidden", paddingBottom: "100px" }}>
          <div container-fullwidth="true" style={{ margin: "2%" }}>
            <Row
              className="mx-auto"
              style={{ display: "flex", justifyContent: "right" }}
            ></Row>
            <Row>
              <Col md={3} sm={12}>
                <h3>Search</h3>
                <Search
                  placeholder="Search"
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                />
              </Col>
            </Row>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <CardBody>
              <ToolkitProvider
                keyField="id"
                data={filterAppResources()}
                columns={columns}
                search
              >
                {(props) => (
                  <div>
                    <BootstrapTable
                      bootstrap4={true}
                      wrapperClasses="table-responsive"
                      hover={true}
                      bordered={false}
                      keyField="id"
                      data={filterAppResources()}
                      columns={columns}
                      expandRow={expandRow}
                      filter={filterFactory()}
                      pagination={paginationFactory({
                        page: currentPage,
                        sizePerPage: 10,
                        paginationSize: 2,
                        totalSize:
                          searchString === ""
                            ? totalPages * 10
                            : pagesAfterQuery * 10,
                        sizePerPageList: [5, 10, 25, 50],
                        hidePageList: true,
                        showTotal: true,
                        hideSizePerPage: true,
                        withFirstAndLast: false,
                        onPageChange: (page, sizePerPage) => {
                          setCurrentPage(page); // Update the currentPage state
                          fetchAppResourceTableData(page, sizePerPage); // Pass page directly
                        },
                      })}
                      defaultSorted={[{ dataField: "id", order: "desc" }]}
                      remote={{ filter: true }}
                      noDataIndication="No results found"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </CardBody>
          )}
        </Card>
      </div>
    </Container>
  );
};

export default AppResourceTable;

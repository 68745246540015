export let resourceTypes = [
  {
    label: "Sticker Pack",
    value: "stickerPack",
  },
  {
    label: "GIF Pack",
    value: "gifPack",
  },
  {
    label: "Keyboard Theme",
    value: "keyboardTheme",
  },
  {
    label: "Offers Zone",
    value: "offersZone",
  },
];

export const appVersionInputLowerLimit = 1;
export const appVersionInputUpperLimit = 999999999;

export const defaultLocationRule = {
  name: "GLOBAL",
  countryId: 240,
  regionId: 1,
  cityId: 1,
  effect: "allow",
};
export const successModalHeading = "Success";
export const failureModalHeading = "Error";
export const rulesetCreatedSuccessfullyText = "Ruleset created Successfully.";
export const ContentUpdatePromptCreatedSuccessfullyText ="Content Update Prompt created successfully.";
export const ContentUpdatePromptUpdatedSuccessfullyText = "Content Update Prompt updated successfully.";
export const options = [
  { label: "Allow", value: "allow" },
  { label: "Deny", value: "deny" },
];
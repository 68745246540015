import React from "react";
import { Badge } from "reactstrap";

const versionFormatter = (cell) => {
    if (cell) {
        return <Badge className="badge-success">{"Enabled"}</Badge>
    }
    else
        return <Badge className="badge-danger">{"Disabled"}</Badge>
}
export const promptTemplateVersionTableColumns = [
    {
        dataField: "identifier",
        text: "Identifier",
        searchable: true,
        style: {
            cursor: "pointer",
        },
    },
    {
        dataField: "isCurrentVersion",
        text: "Is Live Version",
        searchable: true,
        style: {
            cursor: "pointer",
        },
        formatter: versionFormatter,
    },
    {
        dataField: "isBetaVersion",
        text: "Is Beta Version",
        searchable: true,
        style: {
            cursor: "pointer",
        },
        formatter: versionFormatter,
    },
];

import React, { useState, useEffect } from "react";
import { Card, CardBody, Container, Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import Search from "../../../../components/Search";
import Select from "react-select";
import ConfirmationModal from "../../../../pages/ui-elements/ConfirmationModal";
import ResponseModal from "../../../../pages/ui-elements/ResponseModal";
import { typeOptions, statusOptions } from "../../../../config/ConfigPushNotifications";
import { columns } from './userSegmentColumns';
import {
    fetchUserSegments,
    publishUserSegment,
    deactivateUserSegment,
    activateUserSegment,
} from '../../../../utilities/apiUtils/pushNotifications';
import {
    isOperationPermitted,
    processDateAndStatus,
    permissions,
    allActions,
    errorDescription,
    error,
    redirectOnTokenExpiry,
    reduceSentence,
} from "../../../../utilities/commonUtil";

import { retrieveFromLocalStorage } from "../../../../utilities/localStorageUtil";

import {
    successModalHeading,
    errorModalHeading,
} from "../../../../config/config";

const UserSegments = () => {
    const [userSegments, setUserSegments] = useState([]);
    const [column] = useState(columns);
    const [filters, setFilters] = useState({});
    const [contentSearch, setContentSearch] = useState("");
    const [nameFilter, setNameFilter] = useState("");
    const [descriptionFilter, setDescriptionFilter] = useState("");
    const [typeFilter, setTypeFilter] = useState("");
    const [statusFilter, setStatusFilter] = useState("");
    const [allowedPermissions, setAllowedPermissions] = useState(
        retrieveFromLocalStorage("userPermissions")
    );
    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
    const [action, setAction] = useState("");
    const [id, setId] = useState(null);
    const [failureModalText, setFailureModalText] = useState("");
    const [failureModalDisplay, setFailureModalDisplay] = useState(false);
    const [successModalText, setSuccessModalText] = useState("");
    const [successModalDisplay, setSuccessModalDisplay] = useState(false);

    // Fetch data and update state
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const result = await fetchUserSegments();
            if (
                result.errorCode === error.tokenExpired ||
                result.errorCode === error.invalidAccessToken
            )
                redirectOnTokenExpiry();
            if (result?.userSegments && result?.userSegments?.length > 0) {

                result.userSegments.map((item) => {
                    item = processDateAndStatus(item);
                })
            }
            setUserSegments(result.userSegments);
        } catch (error) {
            console.log(error);
        }
    };

    let disablePublishButton = (row) => {
        return row.publishedAt !== null;
    };

    let disableDeactivateButton = (row) => {
        return row.deactivatedAt !== null || row.publishedAt === null;
    };

    let disableActivateButton = (row) => {
        return row.deactivatedAt === null;
    };

    let setConfirmationModalState = (id, event) => {
        setAction(event);
        setConfirmationModalIsOpen(true);
        setId(id);
    };

    let showSuccessModal = (text) => {
        setSuccessModalText(text);
        setSuccessModalDisplay(true);
    };

    let successModalClose = async () => {
        setSuccessModalDisplay(false);
        await fetchData();
    };

    let failureModalClose = () => {
        setFailureModalDisplay(false);
    };

    let showErrorModal = (text) => {
        setFailureModalText(text);
        setFailureModalDisplay(true);
    };


    let onTableChange = (type, newState) => {
        setFilters(newState.filters);
    };

    let publish = async (id) => {
        let response = await publishUserSegment(id);
        if (response?.status === "success") {
            let text = "User Segment has been Published";
            showSuccessModal(text);
        } else {
            let text = errorDescription(response?.errorCode);
            showErrorModal(text);
        }
    };

    let deactivate = async (id) => {
        let response = await deactivateUserSegment(id);
        if (response?.status === "success") {
            let text = "User Segment has been Deactivated";
            showSuccessModal(text);
        } else {
            let text = errorDescription(response?.errorCode);
            showErrorModal(text);
        }
    };

    let activate = async (id) => {
        let response = await activateUserSegment(id);
        if (response?.status === "success") {
            let text = "User Segment has been Activated";
            showSuccessModal(text);
        } else {
            let text = errorDescription(response?.errorCode);
            showErrorModal(text);
        }
    };


    const filterUserSegments = () => {
        let filteredUserSegments = [...userSegments];

        if (contentSearch !== "") {
            filteredUserSegments = filteredUserSegments?.filter((userSegment) =>
                userSegment?.name?.toLowerCase().includes(contentSearch?.toLowerCase()) ||
                userSegment?.description?.toLowerCase().includes(contentSearch?.toLowerCase())
            );
        }

        if (statusFilter.value)
            filteredUserSegments = filteredUserSegments?.filter((item) => item.status === statusFilter.value);

        if (typeFilter.value)
            filteredUserSegments = filteredUserSegments?.filter((item) => item.type === typeFilter.value);

        return filteredUserSegments;
    };

    const expandRow = {
        onlyOneExpanding: true,
        renderer: (row) => (
            <div>
                <ul>
                    <li>ID : {row.id !== null ? row.id : "null"}</li>
                    <li>Name : {row.name !== null ? reduceSentence(row.name, 5) : "null"}</li>
                    <li>Description : {row.description !== null ? reduceSentence(row.description, 5) : "null"}</li>
                    <li>
                        Type : {row.type !== null ? row.type : "null"}
                    </li>
                    <li>
                        Status : {row.status !== null ? row.status : "null"}
                    </li>
                    <li>
                        Created At : {row.createdAt !== null ? row.createdAt : "null"}
                    </li>
                    <li>
                        Updated At : {row.updatedAt !== null ? row.updatedAt : "null"}
                    </li>
                    <li>
                        Deactivated At : {row.deactivatedAt !== null ? row.deactivatedAt : "null"}
                    </li>
                </ul>


                {(isOperationPermitted(
                    allowedPermissions,
                    permissions.userSegmentEdit
                ) ||
                    isOperationPermitted(
                        allowedPermissions,
                        permissions.userSegmentDetail
                    )) && (
                        <Link
                            className="text-decoration-none"
                            to={{
                                pathname: isOperationPermitted(allowedPermissions, permissions.userSegmentEdit) ? `/push-notification/user-segments/edit/${row.id}` : `/push-notification/user-segments/view/${row.id}`,
                                state: {
                                    formType: "edit",
                                },
                            }}
                        >
                            <Button className="mx-3">
                                {isOperationPermitted(
                                    allowedPermissions,
                                    permissions.userSegmentEdit
                                )
                                    ? "Edit"
                                    : "View"}
                            </Button>
                        </Link>
                    )}

                {isOperationPermitted(allowedPermissions, permissions.userSegmentPublish) && !disablePublishButton(row) && (
                    <Button
                        className="mx-3  btn-success"
                        disabled={disablePublishButton(row)}
                        onClick={(event) =>
                            setConfirmationModalState(row.id, event.target.innerHTML)
                        }
                    >
                        Publish
                    </Button>
                )}

                {isOperationPermitted(allowedPermissions, permissions.userSegmentDeactivate) && !disableDeactivateButton(row) && (
                    <Button
                        className="mx-3 btn-danger"
                        disabled={disableDeactivateButton(row)}
                        onClick={(event) =>
                            setConfirmationModalState(row.id, event.target.innerHTML)
                        }
                    >
                        Deactivate
                    </Button>
                )}

                {isOperationPermitted(allowedPermissions, permissions.userSegmentActivate) && !disableActivateButton(row) && (
                    <Button
                        className="mx-3 btn-success"
                        disabled={disableActivateButton(row)}
                        onClick={(event) =>
                            setConfirmationModalState(row.id, event.target.innerHTML)
                        }
                    >
                        Activate
                    </Button>
                )}



                <ConfirmationModal
                    show={confirmationModalIsOpen}
                    modaltext={action}
                    onConfirmationTrue={() => {
                        setConfirmationModalIsOpen(false);
                        if (action === allActions.publish) publish(id);
                        else if (action === allActions.deactivate) deactivate(id);
                        else if (action === allActions.activate) activate(id);
                    }}
                    onHide={() => setConfirmationModalIsOpen(false)}
                />
                <ResponseModal
                    show={successModalDisplay}
                    onHide={successModalClose}
                    modalheading={successModalHeading}
                    modaltext={successModalText}
                />
                <ResponseModal
                    show={failureModalDisplay}
                    onHide={failureModalClose}
                    modalheading={errorModalHeading}
                    modaltext={failureModalText}
                />
            </div>
        )
    };

    return (
        <Container fluid className="p-0">
            <Row className="mb-3">
                <Col md={6} xs={6}>
                    <h1>User Segments</h1>
                </Col>

                {isOperationPermitted(
                    allowedPermissions,
                    permissions.userSegmentCreate
                ) && (
                        <Link
                            className="d-block ml-auto mb-4 pr-3 text-decoration-none"
                            to={{
                                pathname: `/push-notification/user-segments/create`,
                                state: {
                                    formType: "Add",
                                },
                            }}
                        >
                            <Button color="success" className="ml-auto d-flex btn-success">
                                <p className="mb-1 mt-1" style={{ fontSize: "1rem" }}>
                                    Add
                                </p>
                            </Button>
                        </Link>
                    )}
            </Row>
            <Card style={{ overflow: "hidden", paddingBottom: "100px" }}>
                <div container-fullwidth="true" style={{ margin: "2%" }}>
                    <Row
                        className="mx-auto"
                        style={{ display: "flex", justifyContent: "right" }}
                    ></Row>
                    <Row>
                    <Col md={3} sm={12}>
                            <Search
                                placeholder="Search"
                                value={contentSearch}
                                onChange={(e) => setContentSearch(e.target.value)}
                            ></Search>
                        </Col>
                        <Col md={3} sm={12}>
                            <Select
                                className="react-select-container"
                                id="statusSelect"
                                classNamePrefix="react-select"
                                name="status"
                                placeholder="Select Status"
                                value={statusFilter}
                                onChange={(event) => {
                                    setStatusFilter(event);
                                }}
                                options={statusOptions}
                            />
                        </Col>
                        <Col md={3} sm={12}>
                            <Select
                                className="react-select-container"
                                id="typeSelect"
                                classNamePrefix="react-select"
                                name="type"
                                placeholder="Select Type"
                                value={typeFilter}
                                onChange={(event) => {
                                    setTypeFilter(event);
                                }}
                                options={typeOptions}
                            />
                        </Col>
                    <CardBody>
                    <ToolkitProvider
                                keyField="id"
                                data={userSegments}
                                columns={column}
                                search
                            >
                                {(props) => (
                                    <div>
                                        <BootstrapTable
                                            bootstrap4={true}
                                            wrapperClasses="table-responsive"
                                            hover={true}
                                            bordered={false}
                                            keyField="id"
                                            data={filterUserSegments()}
                                            columns={column}
                                            expandRow={expandRow}
                                            filter={filterFactory()}
                                            pagination={paginationFactory({
                                                sizePerPage: 10,
                                                sizePerPageList: [5, 10, 25, 50],
                                            })}
                                            remote={{ filter: true }}
                                            onTableChange={onTableChange}
                                            noDataIndication="No results found"
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                    </CardBody>
                    </Row>
                </div>
            </Card>
        </Container>
    );
};

export default UserSegments;

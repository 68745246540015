import React, { useState, useEffect } from "react";
import {
  Card,
  CardImg,
  Row,
  Col,
  Container,
  CardBody,
  Spinner,
  Button,
  Label,
  Badge,
} from "reactstrap";
import {
  getClients,
  getRegions,
  getCities,
  getCountries,
} from "../../../utilities/apiUtils/stickers";
import { getKeyboardPromptsLanguages } from "../../../utilities/apiUtils/keyboardPrompts";

import {
  errorDescription,
  error,
  redirectOnTokenExpiry,
} from "../../../utilities/commonUtil";
import Search from "../../../components/Search";
import Select from "react-select";
import { getInternalContentSearches } from "../../../utilities/apiUtils/cre";
import ContentSearchDetailsModal from "./ContentSearchDetailsModal";
import { set } from "lodash";
const ContentSearch = () => {
  const [searchParams, setSearchParams] = useState({
    searchString: "hello",
    contentTypes: [
      "sticker",
      "animatedSticker",
      "movieGif",
      "emojiSticker",
      "popTextStyle",
    ],
    deviceType: { value: "android", label: "Android" },
    clientId: { value: "7wZFJWA5chjgat68y826IAIKQ6s197RM", label: "Bobble" },
    deviceId: "",
    languageCode: { value: "en", label: "English: en" },
    strategy: {},
  });
  const [addLanguageCodeOption, setAddLanguageCodeOption] = useState([]);
  const [clients, setClients] = useState([]);

  const [countries, setCountries] = useState();
  const [regions, setRegions] = useState();
  const [cities, setCities] = useState();
  const [contents, setContents] = useState([]);
  const [noContentFound, setNoContentFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showContentDetails, setShowContentDetails] = useState(false);
  const [strategies , setStrategies] = useState([
    { value: "exploration" , label: "Exploration" },
    { value: "exploitation" , label: "Exploitation" },
  ]);
  const [countryRule, setCountryRule] = useState({
    label: "INDIA",
    isoCode: "IN",
  });
  const [regionRule, setRegionRule] = useState({
    label: "Maharashtra",
    regionCode: "MH",
  });
  const [cityRule, setCityRule] = useState({ label: "GLOBAL" });
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });
  const [modalData, setModalData] = useState({});
  let closeCreateAppResources = () => {
    setShowContentDetails(false);
    setModalData({});
  };
  const handleSearchStringChange = (event) => {
    event.preventDefault();
    let { value } = event.target;
    setSearchParams((prevState) => ({
      ...prevState,
      searchString: value,
    }));
  };

  const handleLanguageCodeChange = (event) => {
    const { value } = event.target;
    setSearchParams((prevState) => ({
      ...prevState,
      languageCode: value,
    }));
  };
  const setLanguageCode = async () => {
    const languageCode = await getKeyboardPromptsLanguages();
    if (languageCode?.languages?.length > 0) {
      const languageCodeOptions = languageCode.languages.map((language) => {
        return {
          value: language.code,
          label: language.name + " : " + language.code,
          target: {
            name: "languageCode",
            value: {
              value: language.code,
              label: language.name + " : " + language.code,
            },
          },
        };
      });
      setAddLanguageCodeOption(languageCodeOptions);
    }
  };

  const handleDeviceIdChange = (event) => {
    event.preventDefault();
    let { value } = event.target;
    setSearchParams((prevState) => ({
      ...prevState,
      deviceId: value,
    }));
  };
  const handleContentTypesChange = (selectedContent) => {
    let contentType = [];
    if (selectedContent !== null) {
      selectedContent.forEach((content) => {
        contentType.push(content.value);
      });
    }
    setSearchParams((prevState) => ({
      ...prevState,
      contentTypes: contentType,
    }));
  };

  const handleClientChange = (client) => {
    setSearchParams((prev) => ({
      ...prev,
      clientId: client,
    }));
  };
  useEffect(() => {
    const _getCountries = async () => {
      let response = await getCountries();
      setCountries(response);
    };
    _getCountries();
  }, []);
  const handleDeviceTypeChange = (deviceType) => {
    setSearchParams((prev) => ({
      ...prev,
      deviceType: deviceType,
    }));
  };
  const handleCountries = async (event) => {
    setCountryRule({});
    setRegionRule({});
    setCityRule({});
    setRegions([]);
    setCities([]);

    if (event != null) {
      setCountryRule(event);
      let response = await getRegions(event.value);
      setRegions(response);
    }
  };

  const handleRegions = async (event) => {
    setRegionRule({});
    setCityRule({});
    setCities([]);

    if (event != null) {
      setRegionRule(event);
      let response = await getCities(countryRule.value, event.value);
      setCities(response);
    }
  };

  const handleCities = async (event) => {
    setCityRule({});
    if (event != null) {
      setCityRule(event);
    }
  };

  useEffect(() => {
    const getContents = async () => {
      let params = {
        searchString: searchParams.searchString,
        contentTypes: searchParams.contentTypes,
        deviceType: searchParams.deviceType.value,
        clientId: searchParams.clientId.value,
        languageCode: searchParams.languageCode.value,
        countryCode: countryRule.isoCode,
        strategy: searchParams?.strategy?.value,
      };
      if (regionRule.regionCode) {
        params.regionCode = regionRule.regionCode;
      }
      if (cityRule.label) {
        params.cityName = cityRule.label;
      }
      if (searchParams.deviceId) {
        params.deviceId = searchParams.deviceId;
      }
      try {
        if (searchParams.searchString === "") {
          setNoContentFound(true);
          setLoading(false);
          return;
        }
        let response = await getInternalContentSearches(params);

        if (response.errorCode) {
          if (
            response.errorCode === error.tokenExpired ||
            response.errorCode === error.invalidAccessToken
          )
            redirectOnTokenExpiry();
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: errorDescription(response.errorCode),
          });
          setNoContentFound(true);
          setLoading(false);
        } else if (response.contents) {
          if (response.contents !== null) {
            setContents(response.contents);
            setLoading(false);
            return;
          }
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      }
    };
    getContents();
  }, [searchParams, cityRule]);
  useEffect(() => {
    const _getClients = async () => {
      let clients = await getClients();
      setClients(clients);
    };
    _getClients();
    setLanguageCode();
  }, []);

  const renderAllContents = () => {
    if (contents.length === 0 && !loading) {
      return (
        <Col sm="12" md="3" lg="3" className="mx-auto d-table mt-5">
          <h1 className="text-center">No Content Found</h1>
        </Col>
      );
    } else {
      return contents.map((content) => {
        return (
          <Col md="3" lg="3" key={content.id}>
            <Card>
              <React.Fragment>
                {content.previewImageURL ? (
                  <CardImg
                    src={content.previewImageURL}
                    alt="Card image"
                    style={{ height: "200px", objectFit: "cover" }}
                  />
                ) : (
                  <div className="theme-preview-image bg-dark"></div>
                )}
              </React.Fragment>
              <CardBody>
                <small>ID : </small> <b>{content?.id} </b>
                <br />
                <small>Content Type : </small>{" "}
                <b>
                  {" "}
                  <Badge
                    className="badge-primary"
                    style={{ margin: 5 }}
                    key={content?.id}
                  >
                    {content?.type}
                  </Badge>{" "}
                </b>
                <br />
                <small>Score : </small>{" "}
                <b>
                  {content?.finalContentScore
                    ? Math.abs(content?.finalContentScore) > 99999
                      ? content?.finalContentScore
                          .toExponential(2)
                          .replace(/e\+?/, " x 10^")
                      : content?.finalContentScore
                    : "Not Available"}
                </b>
                <Container className=" my-4 d-flex justify-content-around">
                  {
                    <Button
                      color="info"
                      onClick={() => {
                        setShowContentDetails(true);
                        setModalData(content);
                      }}
                      size="sm"
                    >
                      View More Details
                    </Button>
                  }
                </Container>
              </CardBody>
            </Card>
          </Col>
        );
      });
    }
  };
  return (
    <>
      <Container fluid className="p-0">
        <h1 className="heading">Content Search</h1>

        <Row>
          <Col md={6} sm={12}>
            <Label>Search</Label>
            <Search
              id="searchString"
              name="searchString"
              placeholder="Search"
              value={searchParams.searchString}
              onChange={(event) => {
                handleSearchStringChange(event);
              }}
            ></Search>
          </Col>
          <Col md={6} sm={12}>
            <Label>Content Types</Label>
            <Select
              id="contentTypes"
              className="react-select-container"
              classNamePrefix="react-select"
              name="contentTypes"
              placeholder="Select Content Types"
              isMulti
              onChange={(event) => handleContentTypesChange(event)}
              defaultValue={[
                {
                  value: "sticker",
                  label: "Sticker",
                  disabled: false,
                  target: {
                    name: "type",
                    value: {
                      value: "sticker",
                      label: "Sticker",
                    },
                  },
                },
                {
                  value: "animatedSticker",
                  label: "Animated Sticker",
                  disabled: false,
                  target: {
                    name: "type",
                    value: {
                      value: "animatedSticker",
                      label: "Animated Sticker",
                    },
                  },
                },
                {
                  value: "movieGif",
                  label: "Movie Gif",
                  disabled: false,
                  target: {
                    name: "type",
                    value: {
                      value: "movieGif",
                      label: "Movie Gif",
                    },
                  },
                },
                {
                  value: "emojiSticker",
                  label: "Emoji Sticker",
                  disabled: false,
                  target: {
                    name: "type",
                    value: {
                      value: "emojiSticker",
                      label: "Emoji Sticker",
                    },
                  },
                },
                {
                  value: "popTextStyle",
                  label: "Pop Text Style",
                  disabled: false,
                  target: {
                    name: "type",
                    value: {
                      value: "popTextStyle",
                      label: "Pop Text Style",
                    },
                  },
                },
              ]}
              options={[
                {
                  value: "sticker",
                  label: "Sticker",
                  disabled: false,
                  target: {
                    name: "type",
                    value: {
                      value: "sticker",
                      label: "Sticker",
                    },
                  },
                },
                {
                  value: "animatedSticker",
                  label: "Animated Sticker",
                  disabled: false,
                  target: {
                    name: "type",
                    value: {
                      value: "animatedSticker",
                      label: "Animated Sticker",
                    },
                  },
                },
                {
                  value: "movieGif",
                  label: "Movie Gif",
                  disabled: false,
                  target: {
                    name: "type",
                    value: {
                      value: "movieGif",
                      label: "Movie Gif",
                    },
                  },
                },
                {
                  value: "emojiSticker",
                  label: "Emoji Sticker",
                  disabled: false,
                  target: {
                    name: "type",
                    value: {
                      value: "emojiSticker",
                      label: "Emoji Sticker",
                    },
                  },
                },
                {
                  value: "popTextStyle",
                  label: "Pop Text Style",
                  disabled: false,
                  target: {
                    name: "type",
                    value: {
                      value: "popTextStyle",
                      label: "Pop Text Style",
                    },
                  },
                },
              ]}
            />
          </Col>
          <Col md={3} sm={12}>
            <Label>Device Id</Label>
            <Search
              id="deviceId"
              name="deviceId"
              placeholder="Enter device Id"
              value={searchParams.deviceId}
              onChange={(event) => {
                handleDeviceIdChange(event);
              }}
            ></Search>
          </Col>
          <Col md={3}>
            <Label>Device Type</Label>
            <Select
              className="react-select-container"
              id="statusSelect"
              classNamePrefix="react-select"
              name="deviceType"
              placeholder="Select Device Type"
              value={searchParams.deviceType}
              onChange={handleDeviceTypeChange}
              options={[
                { value: "android", label: "Android" },
                { value: "iOS", label: "iOS" },
              ]}
            />
          </Col>
          <Col md={3}>
            <Label>Client</Label>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              name="clientId"
              placeholder="Select Client"
              value={searchParams.clientId}
              options={clients}
              onChange={handleClientChange}
            />
          </Col>
          <Col md={3}>
            <Label>Language Code</Label>
            <Select
              id="languageCode"
              className="react-select-container"
              classNamePrefix="react-select"
              name="languageCode"
              placeholder="Select Language Code"
              value={searchParams.languageCode}
              onChange={handleLanguageCodeChange}
              options={addLanguageCodeOption}
              required
            />
          </Col>
          <Col md={3}>
            <Label>Country</Label>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              name="country"
              placeholder="Select Country"
              options={countries}
              onChange={(option) => handleCountries(option)}
              value={countryRule}
              required
            />
          </Col>
          <Col md={3}>
            <Label>Region</Label>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              name="region"
              placeholder="Select region"
              options={regions}
              value={regionRule}
              onChange={(option) => handleRegions(option)}
              required
            />
          </Col>
          <Col md={3}>
            <Label>City</Label>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              name="city"
              placeholder="Select City"
              options={cities}
              value={cityRule}
              onChange={(option) => handleCities(option)}
              required
            />
          </Col>
          <Col md={3}>
            <Label>Strategy</Label>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              name="strategy"
              placeholder="Select Strategy"
              options={strategies}
              value={searchParams.strategy}
              onChange={(option) => setSearchParams((prev) => ({
                ...prev,
                strategy: option
              }
              ))}
            />
          </Col>
        </Row>
        {loading ? (
          <Spinner color="info" className=" d-flex mx-auto" />
        ) : noContentFound ? (
          <Container
            sm="12"
            md="8"
            lg="6"
            className="mx-auto d-table mt-5 pt-2 pb-5"
          >
            <h1 className="text-center">No content found</h1>
          </Container>
        ) : (
          <React.Fragment>
            <Row className="mt-5">{renderAllContents()}</Row>
          </React.Fragment>
        )}
      </Container>
      <ContentSearchDetailsModal
        show={showContentDetails}
        onHide={closeCreateAppResources}
        data={modalData}
      />
    </>
  );
};

export default ContentSearch;

import { retrieveFromLocalStorage } from "../utilities/localStorageUtil";
import { permissions } from "../utilities/commonUtil";

const allowedPermissions = retrieveFromLocalStorage("userPermissions");

export let showAddPackageSetsButton = () => {
  return allowedPermissions.includes(permissions.packageSetCreate);
};

export let showEditPackageSetsButton = () => {
  return allowedPermissions.includes(permissions.packageSetUpdate);
};

export let showAddKeyboardPromptsButton = () => {
  return allowedPermissions.includes(permissions.keyboardPromptCreate);
};

export let showEditKeyboardPromptsView = () => {
  return allowedPermissions.includes(permissions.keyboardPromptEditView);
}

export let showEditKeyboardPromptsButton = () => {
  return allowedPermissions.includes(permissions.keyboardPromptUpdate);
};

export let showPublishKeyboardPromptsButton = () => {
  return allowedPermissions.includes(permissions.keyboardPromptPublish);
};

export let showActivateKeyboardPromptsButton = () => {
  return allowedPermissions.includes(permissions.keyboardPromptActivate);
};

export let showDeactivateKeyboardPromptsButton = () => {
  return allowedPermissions.includes(permissions.keyboardPromptDeactivate);
};

export let showEditTargetingRulesButton = () => {
  return allowedPermissions.includes(
    permissions.keyboardPromptTargetingRulesUpdate
  );
};

export let packageSetResponses = {
  addeddSuccessfully: "Package Set Added Successfully",
  updatedSuccessfully: "Package Set Edited Successfully",
};

export let endDateError = "End date can not be today or previous date";

export let dateError = "End date should be greater than start date";

export let timeError = "End time should be greater than start time";

export let typeNotSelectedError = "Please select a type";

export let keyboardPromptResponses = {
  updatedSuccessfully: "Keyboard Prompt Updated Successfully",
  addeddSuccessfully: "Keyboard Prompt Added Successfully",
};

export const statusOptions = [
  { value: "", label: "All" },
  { value: "Activated", label: "Activated" },
  { value: "Deactivated", label: "Deactivated" },
  { value: "Unpublished", label: "Unpublished" },
];

export const typeOptions = [
  { value: "", label: "All" },
  { value: "prompt", label: "Prompt" },
  { value: "suggestionDrawer", label: "Suggestion Drawer" },
  { value: "aiPoweredBar", label: "AI Powered Bar" },
  { value: "popText", label: "PopText" },
  { value: "scorebar", label: "Score Bar" },
  { value: "aiClipboard", label: "AI Clipboard" },
];

export const addTypeOptions = [
  {
    value: "prompt",
    label: "Prompt",
    disabled: false,
    target: {
      name: "type",
      value: {
        value: "prompt",
        label: "Prompt",
      },
    },
  },
  {
    value: "suggestionDrawer",
    label: "Suggestion Drawer",
    disabled: false,
    target: {
      name: "type",
      value: {
        value: "suggestionDrawer",
        label: "Suggestion Drawer",
      },
    },
  },
  {
    value: "aiPoweredBar",
    label: "AI Powered Bar",
    disabled: false,
    target: {
      name: "type",
      value: {
        value: "aiPoweredBar",
        label: "AI Powered Bar",
      },
    },
  },
  {
    value: "popText",
    label: "PopText",
    disabled: false,
    target: {
      name: "type",
      value: {
        value: "popText",
        label: "PopText",
      },
    },
  },
  {
    value: "scorebar",
    label: "Scorebar",
    disabled: false,
    target: {
      name: "type",
      value: {
        value: "scorebar",
        label: "Scorebar",
      },
    },
  },
  {
    value: "aiClipboard",
    label: "AI Clipboard",
    disabled: false,
    target: {
      name: "type",
      value: {
        value: "aiClipboard",
        label: "AI Clipboard",
      },
    },
  }
];

export const categoryOptions = [
  {
    value: "redirection",
    label: "Redirection",
    disabled: false,
    target: {
      name: "category",
      value: {
        value: "redirection",
        label: "Redirection",
      },
    },
  },

  {
    value: "contentSharing",
    label: "Content Sharing",
    disabled: false,
    target: {
      name: "category",
      value: {
        value: "contentSharing",
        label: "Content Sharing",
      },
    },
  },

  {
    value: "commerce",
    label: "Commerce",
    disabled: false,
    target: {
      name: "category",
      value: {
        value: "commerce",
        label: "Commerce",
      },
    },
  },
];

export const addActionTypeOptions = [
  {
    value: "webView",
    label: "Web View",
    target: {
      name: "actionType",
      value: {
        value: "webView",
        label: "Web View",
      },
    },
    metadata: [
      {
        name: "url",
        label: "URL",
        type: "text",
        required: false,
        placeholder: "Add URL",
        disabled: false,
      },
    ],
  },
  {
    value: "browser",
    label: "Browser",
    target: {
      name: "actionType",
      value: {
        value: "browser",
        label: "Browser",
      },
    },
    metadata: [
      {
        name: "url",
        label: "URL",
        type: "text",
        required: false,
        placeholder: "Add URL",
        disabled: false,
      },
    ],
  },
  {
    value: "deeplink",
    label: "Deep Link",
    target: {
      name: "actionType",
      value: {
        value: "deeplink",
        label: "Deep Link",
      },
    },
    metadata: [
      {
        name: "url",
        label: "URL",
        type: "text",
        required: false,
        placeholder: "Add URL",
        disabled: false,
      },
    ],
  },
  {
    value: "bobbleSuper",
    label: "Bobble Super",
    target: {
      name: "actionType",
      value: {
        value: "bobbleSuper",
        label: "Bobble Super",
      },
    },
    metadata: [
      {
        name: "url",
        label: "URL",
        type: "text",
        required: false,
        placeholder: "Add URL",
        disabled: false,
      },
    ],
  },
  {
    value: "autoOpenSuggestionDrawer",
    label: "Auto Open Suggestion Drawer",
    target: {
      name: "actionType",
      value: {
        value: "autoOpenSuggestionDrawer",
        label: "Auto Open Suggestion Drawer",
      },
    },
    metadata: [
      {
        name: "searchString",
        label: "Search String",
        placeholder: "Enter Search String",
        disabled: false,
        required: false,
      },
    ],
  },
  {
    value: "internalRedirection",
    label: "Internal Redirection",
    target: {
      name: "actionType",
      value: {
        value: "internalRedirection",
        label: "Internal Redirection",
      },
    },
    internalRedirectionOptions: [
      {
        value: "specificCategoryInStickers",
        label: "Specific Category in Stickers",
        disabled: false,
        target: {
          name: "internalRedirectionType",
          value: {
            value: "specificCategoryInStickers",
            label: "Specific Category in Stickers",
          },
        },
      },
      {
        value: "stickersTabWithOTF",
        label: "Stickers tab with OTF",
        disabled: false,
        target: {
          name: "internalRedirectionType",
          value: {
            value: "stickersTabWithOTF",
            label: "Stickers tab with OTF",
          },
        },
      },
      {
        value: "specificCategoryInMovieGIFs",
        label: "Specific Category in Movie GIFs",
        disabled: false,
        target: {
          name: "internalRedirectionType",
          value: {
            value: "specificCategoryInMovieGIFs",
            label: "Specific Category in Movie GIFs",
          },
        },
      },
      {
        value: "gifsTabWithOTF",
        label: "GIFs tab with OTF",
        disabled: false,
        target: {
          name: "internalRedirectionType",
          value: {
            value: "gifsTabWithOTF",
            label: "GIFs tab with OTF",
          },
        },
      },
      {
        value: "sotd",
        label: "SOTD",
        disabled: false,
        target: {
          name: "internalRedirectionType",
          value: {
            value: "sotd",
            label: "SOTD",
          },
        },
      },
      {
        value: "storiesTab",
        label: "Stories Tab",
        disabled: false,
        target: {
          name: "internalRedirectionType",
          value: {
            value: "storiesTab",
            label: "Stories Tab",
          },
        },
      },
      {
        value: "storiesSearch",
        label: "Stories search",
        disabled: false,
        target: {
          name: "internalRedirectionType",
          value: {
            value: "storiesSearch",
            label: "Stories search",
          },
        },
      },
      {
        value: "autoOpenSuggestionDrawer",
        label: "Auto Open Suggestion Drawer",
        disabled: false,
        target: {
          name: "internalRedirectionType",
          value: {
            value: "autoOpenSuggestionDrawer",
            label: "Auto Open Suggestion Drawer",
          },
        },
      },
      {
        value: "themes",
        label: "Themes",
        disabled: false,
        target: {
          name: "internalRedirectionType",
          value: {
            value: "themes",
            label: "Themes",
          },
        },
      },
      {
        value: "quotesShayariJokes",
        label: "Quotes/Shayari/Jokes",
        disabled: false,
        target: {
          name: "internalRedirectionType",
          value: {
            value: "quotesShayariJokes",
            label: "Quotes/Shayari/Jokes",
          },
        },
      },
      {
        value: "font",
        label: "Font",
        disabled: false,
        target: {
          name: "internalRedirectionType",
          value: {
            value: "font",
            label: "Font",
          },
        },
      },
    ],
  },
];

export const internalRedirectionOptions = {
  specificCategoryInStickers: [
    {
      name: "categoryID",
      label: "Category",
      value: {
        value: "1",
        label: "For You",
      },
      placeholder: "Select Category",
      disabled: false,
    },
    {
      name: "screenName",
      label: "Screen Name",
      value: "stickers",
      placeholder: "Enter Screen Name",
      disabled: true,
      required: true,
    },
  ],
  stickersTabWithOTF: [
    {
      name: "screenName",
      label: "Screen Name",
      value: "stickers",
      placeholder: "Enter Screen Name",
      disabled: true,
      required: true,
    },
    {
      name: "searchString",
      label: "Search String",
      value: "",
      placeholder: "Enter Search String",
      disabled: false,
      required: false,
    },
  ],
  specificCategoryInMovieGIFs: [
    {
      name: "categoryID",
      label: "Category",
      value: {
        value: "1",
        label: "For You",
      },
      placeholder: "Select Category",
      disabled: false,
    },
    {
      name: "screenName",
      label: "Screen Name",
      value: "gifs",
      placeholder: "Enter Screen Name",
      disabled: true,
      required: true,
    },
  ],
  gifsTabWithOTF: [
    {
      name: "screenName",
      label: "Screen Name",
      value: "gifs",
      placeholder: "Enter Screen Name",
      disabled: true,
      required: true,
    },
    {
      name: "searchString",
      label: "Search String",
      value: "",
      placeholder: "Enter Search String",
      disabled: false,
      required: false,
    },
  ],
  sotd: [
    {
      name: "screenName",
      label: "Screen Name",
      value: "sotd",
      placeholder: "Enter Screen Name",
      disabled: true,
      required: true,
    },
  ],
  storiesTab: [
    {
      name: "screenName",
      label: "Screen Name",
      value: "story",
      placeholder: "Enter Screen Name",
      disabled: true,
      required: true,
    },
  ],
  storiesSearch: [
    {
      name: "screenName",
      label: "Screen Name",
      value: "story",
      placeholder: "Enter Screen Name",
      disabled: true,
      required: true,
    },
    {
      name: "searchString",
      label: "Search String",
      value: "",
      placeholder: "Enter Search String",
      disabled: false,
      required: false,
    },
  ],
  autoOpenSuggestionDrawer: [
    {
      name: "screenName",
      label: "Screen Name",
      value: "contentSuggestions",
      placeholder: "Enter Screen Name",
      disabled: true,
      required: false,
    },
    {
      name: "searchString",
      label: "Search String",
      value: "",
      placeholder: "Enter Search String",
      disabled: false,
      required: false,
    },
  ],
  themes: [
    {
      name: "screenName",
      label: "Screen Name",
      value: "themes",
      placeholder: "Enter Screen Name",
      disabled: true,
      required: true,
    },
  ],
  quotesShayariJokes: [
    {
      name: "categoryID",
      label: "Quick Reply Category",
      value: {
        value: "1",
        label: "Shayari",
      },
      placeholder: "Select Quick Replies Category",
      disabled: false,
    },
    {
      name: "screenName",
      label: "Screen Name",
      value: "quickReply",
      placeholder: "Enter Screen Name",
      disabled: true,
      required: true,
    },
  ],
  font: [
    {
      name: "screenName",
      label: "Screen Name",
      value: "fonts",
      placeholder: "Enter Screen Name",
      disabled: true,
      required: true,
    },
  ],
};

export const iconPointerOptions = [
  {
    value: "SETTINGS",
    label: "SETTINGS"
  },
  {
    value: "VOICE",
    label: "VOICE"
  },
  {
    value: "YOU_MOJI",
    label: "YOU_MOJI"
  },
  {
    value: "THEME",
    label: "THEME"
  },
  {
    value: "CAMPAIGN",
    label: "CAMPAIGN"
  },
  {
    value: "CLIPBOARD",
    label: "CLIPBOARD"
  },
  {
    value: "UPDATE_KEYBOARD",
    label: "UPDATE_KEYBOARD"
  },
  {
    value: "CANCEL_RATING",
    label: "CANCEL_RATING"
  },
  {
    value: "RATING_STAR",
    label: "RATING_STAR"
  },
  {
    value: "FONT",
    label: "FONT"
  },
  {
    value: "STICKERS",
    label: "STICKERS"
  },
  {
    value: "GIFS",
    label: "GIFS"
  },
  {
    value: "SECURE_VIEW",
    label: "SECURE_VIEW"
  },
  {
    value: "BRAND",
    label: "BRAND"
  },
  {
    value: "JOKES",
    label: "JOKES"
  },
  {
    value: "SHAYARIS",
    label: "SHAYARIS"
  },
  {
    value: "SHORTCUTS",
    label: "SHORTCUTS"
  },
  {
    value: "LANGUAGE_SWITCH",
    label: "LANGUAGE_SWITCH"
  },
  {
    value: "QUOTES",
    label: "QUOTES"
  },
  {
    value: "EMOJIS",
    label: "EMOJIS"
  },
  {
    value: "CUSTOMISE",
    label: "CUSTOMISE"
  },
  {
    value: "SOTD",
    label: "SOTD"
  },
  {
    value: "LOCATION",
    label: "LOCATION"
  },
  {
    value: "MORE",
    label: "MORE"
  },
  {
    value: "SEE_MORE",
    label: "SEE_MORE"
  },
  {
    value: "SUPER_APP",
    label: "SUPER_APP"
  },
  {
    value: "INVITE_FRIENDS",
    label: "INVITE_FRIENDS"
  },
  {
    value: "DUMMY",
    label: "DUMMY"
  },
];

export const trackerTypeOptions = [
  {
    value: "click",
    label: "Click"
  },
  {
    value: "impression",
    label: "Impression"
  }
]

export const fileSizeLimit = {
  bannerImage: 174080,
  animatedBannerImage: 358400,
  iconImage: 51200,
  animatedIconImage: 358400,
}  // File size limit in Bytes
import React, { useState, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { fetchTasksEvent } from "../../../utilities/apiUtils/tasks.js";

const TaskEvents = () => {
  const { id } = useParams();
  const history = useHistory();
  const [taskEvents, setTaskEvents] = useState([]);

  useEffect(() => {
    // Fetch task events when the component mounts
    fetchTasksEvent(id)
      .then((data) => {
        setTaskEvents(data.task_events);
      })
      .catch((error) => {
        console.error("Error fetching task events:", error);
      });
  }, [id]);

  return (
    <div>
      <table className="table">
        <thead className="thead-dark">
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Display Name</th>
            <th>Published At</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {taskEvents.map((event) => (
            <tr key={event.id}>
              <td>{event.id}</td>
              <td>{event.name}</td>
              <td>{event.display_name}</td>
              <td>{event.published_at}</td>
              <td>
                <Link
                  to={`/edit/taskEvents/${event.id}`}
                  className="btn btn-primary btn-sm"
                >
                  Edit
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      
      {/* Button to add new task events */}
      <div className="text-center mt-4">
        <Link
          to={`/tasksEvent/add/${id}`} // Adjust the route as per your application
          className="btn btn-success"
        >
          Add Task Event
        </Link>
      </div>
    </div>
  );
};

export default TaskEvents;

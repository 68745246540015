import {retrieveFromLocalStorage} from "../utilities/localStorageUtil";
import {permissions} from "../utilities/commonUtil";
export const successModalHeading = "Success";
export const failureModalHeading = "Error";

const allowedPermissions = retrieveFromLocalStorage("userPermissions");

export const gifShareTextResponses = {
    updatedSuccessfully : "Share Texts  updated successfully",
}

export let showGifShareTextButton = () => {
    return allowedPermissions.includes(permissions.gifShareTextUpdate);
};
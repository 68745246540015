import React, { useState, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import { redirectTo } from "../../../../utilities/commonUtil";
import { columns } from "../PopTextStyleShareTextColumn";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";


const ShareTextsTable = ({ shareTexts,enableShareTextInKeyboard }) => {
  const [userData, setUserData] = useState([]);
  const [enableShareText,setEnableShareText] = useState("false");
  const [column] = useState(columns);



  useEffect(() => {
    if (shareTexts !== undefined && shareTexts.length > 0) {
      setUserData(shareTexts);
      setEnableShareText(enableShareTextInKeyboard);
    }
  }, [shareTexts]);

console.log(userData)
  return (
    <Card>
      <CardBody>
        <ToolkitProvider  keyField="ID" data={userData} columns={column}>
          {(props) => (
            <div>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                bordered={false}
                data={userData}
                keyField="ID"
                columns={column}
                pagination={paginationFactory({
                  sizePerPage: 10,
                  sizePerPageList: [5, 10, 25, 50],
                })}             
                hover={false}
                rowEvents={{
                  onMouseOver: (e, row, rowIndex) => {
                    e.target.style.cursor = "auto";
                  },
                }}
              />
            </div>
          )}
        </ToolkitProvider>
      </CardBody>
    </Card>
  );
};

export default ShareTextsTable;

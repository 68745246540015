import { retrieveFromLocalStorage } from "../utilities/localStorageUtil";
import { permissions } from "../utilities/commonUtil";

const allowedPermissions = retrieveFromLocalStorage("userPermissions");

export let isPlacementLocationsCreatePermission = () => {
  return allowedPermissions.includes(permissions.placementLocationsCreate);
};

export let isPlacementLocationsEditPermission = () => {
  return allowedPermissions.includes(permissions.placementLocationsEdit);
};

export let isPlacementLocationsDeletePermission = () => {
  return allowedPermissions.includes(permissions.placementLocationsDelete);
};

export const allActions = {
  delete: "Delete"
};

export const placementLocationsResponses = {
  updatedSuccessfully: "Placement Location updated successfully",
  addedSuccessfully: "Placement Location added successfully",
};
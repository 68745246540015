/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { FaMinus, FaChevronUp, FaChevronDown } from "react-icons/fa";
import { error, errorDescription } from "../../../../utilities/commonUtil";
import ResponseModal from "../../ResponseModal";
import {
  Collapse,
  Badge,
  Row,
  Col,
  Card,
  Label,
  CardBody,
  CardHeader,
  Button,
} from "reactstrap";
import {
  failureModalHeading,
  options,
  defaultLocationRule,
} from "../../../../config/contentUpdatePrompts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { getClients } from "../../../../utilities/apiUtils/stickers";
const LocationRule = (props) => {
  const [countries] = useState(props.countries);
  const [regions, setRegions] = useState(props.regions);
  const [cities, setCities] = useState(props.cities);
  const [regionOptions, setRegionOptions] = useState([
    { label: "All Regions", value: 1 },
  ]);
  const [cityOptions, setCityOptions] = useState([]);
  const [countryRule, setCountryRule] = useState();
  const [regionRule, setRegionRule] = useState();
  const [cityRule, setCityRule] = useState();
  const [ruleEffect, setRuleEffect] = useState(options[0]);
  // eslint-disable-next-line no-unused-vars
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });

  useEffect(() => {
    setCities(props.cities);
  }, [props.cities]);
  useEffect(() => {
    if (countryRule && regionRule && cityRule && ruleEffect) {
      props.updateRuleSummary(
        props.rule.id,
        countryRule.label,
        regionRule.label,
        cityRule.label,
        ruleEffect.label
      );
      props.updateRule(
        props.rule.id,
        countryRule.value,
        regionRule.value,
        cityRule.value,
        ruleEffect.value
      );
    }
  }, [countryRule, regionRule, cityRule, ruleEffect]);

  const _removeLocationRule = () => {
    props.removeLocationRule(props.rule.id);
  };

  const handleCountries = async (event) => {
    let regionOptions, selectedRegion;
    if (regions) regionOptions = regions[event.value];
    if (regionOptions)
      selectedRegion = regionOptions.filter(
        (region) => region.region === defaultLocationRule.name
      );
    if (selectedRegion) {
      setRegionRule({
        label: defaultLocationRule.name,
        value: selectedRegion[0].regionId,
      });
      let citiesData;
      if (event && selectedRegion && cities)
        citiesData =
          cities[
            event.value.toString() + selectedRegion[0].regionId.toString()
          ];
      if (citiesData) {
        let selectedCity = citiesData.filter(
          (city) => city.cityName === defaultLocationRule.name
        );
        if (selectedCity)
          setCityRule({
            label: defaultLocationRule.name,
            value: selectedCity[0].cityId,
          });
      }
    }

    setRegionOptions([]);

    if (event != null && regionRule && cityRule) {
      props.updateRule(
        props.rule.id,
        event.value,
        regionRule.value,
        cityRule.value,
        ruleEffect.value
      );
      setCountryRule(event);
    }
  };

  useEffect(() => {
    if (regions && countryRule) {
      let _regions = regions[countryRule.value];
      if (_regions) {
        _regions = _regions.map((region) => {
          let regionName;
          if (region) {
            regionName =
              region.region === defaultLocationRule.name
                ? region.region
                : region.regionCode + " - " + region.region;
          }
          return { label: regionName, value: region.regionId };
        });

        setRegionOptions([..._regions]);
      }
    }
  }, [props.regions, countryRule]);

  useEffect(() => {
    if (countryRule && regionRule && regions && cities) {
      let citiesData;
      if (countryRule.value && regionRule.value)
        citiesData =
          cities[countryRule.value.toString() + regionRule.value.toString()];
      if (citiesData) {
        let cityOptions = citiesData.map((type) => {
          let cityId = type.cityId;
          let cityName = "";
          if (type.cityName !== undefined) {
            cityName = type.cityName;
          }
          return { label: cityName, value: cityId };
        });
        setCityOptions([...cityOptions]);
      }
    }
  }, [props.regions, countryRule, regionRule, props.cities]);

  const handleRegions = async (event) => {
    let citiesData;
    if (countryRule && event)
      citiesData =
        cities[countryRule.value.toString() + event.value.toString()];
    if (citiesData) {
      let selectedCity = citiesData.filter(
        (city) => city.cityName === defaultLocationRule.name
      );
      if (selectedCity.length)
        setCityRule({
          label: defaultLocationRule.name,
          value: selectedCity[0].cityId,
        });
    }
    setCityOptions([]);

    if (event != null) {
      if (countryRule && ruleEffect) {
        props.updateRule(
          props.rule.id,
          countryRule.value,
          event.value,
          cityRule.value,
          ruleEffect.value
        );
      }
      setRegionRule(event);
    }
  };

  const handleCities = async (event) => {
    setCityRule({});
    if (event != null) {
      if (countryRule && regionRule && ruleEffect) {
        props.updateRule(
          props.rule.id,
          countryRule.value,
          regionRule.value,
          event.value,
          ruleEffect.value
        );
      }
      setCityRule(event);
    }
  };

  const handleEffect = async (event) => {
    setRuleEffect({});
    if (event != null) {
      props.updateRule(
        props.rule.id,
        countryRule ? countryRule.value : null,
        regionRule ? regionRule.value : null,
        cityRule ? cityRule.value : null,
        event.value
      );
      setRuleEffect(event);
    }
  };

  useEffect(() => {
    const _setInitValue = async () => {
      if (countries && regions && cities) {
        var country = countries.find(
          (item) => item.value === props.rule.countryId
        );
        setCountryRule(country);

        let _regions = regions[props.rule.countryId];
        if (_regions) {
          var region = _regions.find(
            (item) => item.regionId === props.rule.regionId
          );
          if (region) {
            handleRegions({
              label:
                region.region === defaultLocationRule.name
                  ? region.region
                  : region.regionCode + " - " + region.region,
              value: region.regionId,
            });
          }
        }
        if (cities && props.rule.countryId && props.rule.regionId) {
          let citiesData =
            cities[
              props.rule.countryId.toString() + props.rule.regionId.toString()
            ];
          if (citiesData) {
            var city = citiesData.find(
              (item) => item.cityId === props.rule.cityId
            );
            if (city)
              handleCities({ label: city.cityName, value: city.cityId });
            else handleCities({ label: defaultLocationRule.name, value: 1 });
          }
        }

        var effect = options.find((item) => item.value === props.rule.effect);
        setRuleEffect(effect);
      }
    };
    _setInitValue();
  }, [props.countries, props.rule, props.regions, props.cities]);

  const failureModalClose = () => {
    setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
  };

  return (
    <Row className="mr-1 mb-3">
      <Col>
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          name="country"
          placeholder="Select Country"
          options={countries}
          onChange={(option) => handleCountries(option)}
          isDisabled={!props.updateAllowed}
          value={countryRule}
          required
        />
      </Col>
      <Col>
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          name="region"
          placeholder="Select region"
          options={regionOptions}
          value={regionRule}
          onChange={(option) => handleRegions(option)}
          isDisabled={!props.updateAllowed}
          required
        />
      </Col>
      <Col>
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          name="city"
          placeholder="Select City"
          options={cityOptions}
          value={cityRule}
          onChange={(option) => handleCities(option)}
          isDisabled={!props.updateAllowed}
          required
        />
      </Col>
      <Col md={2}>
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          name="locationEffect"
          placeholder="Select Effects"
          options={options}
          value={ruleEffect}
          onChange={(option) => handleEffect(option)}
          isDisabled={!props.updateAllowed}
          required
        />
      </Col>
      {props.updateAllowed ? (
        <Col md={1} className="align-self-end">
          <Button
            onClick={_removeLocationRule}
            color="transparent"
            disabled={props.locationRules.length < 2}
          >
            <FaMinus />
          </Button>
        </Col>
      ) : (
        <div></div>
      )}
      <ResponseModal
        show={failureModalState.failureModalDisplay}
        onHide={() => failureModalClose()}
        modalheading={failureModalHeading}
        modaltext={failureModalState.failureModalText}
      />
    </Row>
  );
};

const ClientRule = (props) => {
  const [clients, setClients] = useState([]);
  const [clientRule, setClientRule] = useState([]);
  const [ruleEffect, setRuleEffect] = useState(options[0]);
  const [failureModalState, setFailureModalState] = useState({
    failureModalDisplay: false,
    failureModalText: "",
  });

  const _removeClientRule = () => {
    props.removeClientRule(props.rule.id);
  };
  useEffect(() => {
    const _getClients = async () => {
      try {
        let response = await getClients();
        if (response) {
          setClients(response);
        } else {
          setFailureModalState({
            failureModalDisplay: true,
            failureModalText: errorDescription(error.unexpectedError),
          });
        }
      } catch (error) {
        setFailureModalState({
          failureModalDisplay: true,
          failureModalText: errorDescription(error.unexpectedError),
        });
      }
    };
    _getClients();
  }, []);
  const handleClientRule = async (event) => {
    setClientRule({});
    if (event != null) {
      props.updateRule(props.rule.id, event.value, ruleEffect.value);
      props.updateRuleSummary(props.rule.id, event.label, ruleEffect.label);
      setClientRule(event);
    }
  };

  const handleEffect = async (event) => {
    setRuleEffect({});
    if (event != null) {
      setRuleEffect(event);
      props.updateRule(props.rule.id, clientRule.value, event.value);
      props.updateRuleSummary(props.rule.id, clientRule.label, event.label);
    }
  };

  useEffect(() => {
    const _setInitValue = async () => {
      if (clients.length > 0) {
        var client = props.rule.clientId
          ? clients.find(
              (item) =>
                item.value.toLowerCase() === props.rule.clientId.toLowerCase()
            )
          : "";
        setClientRule(client);
        var effect = options.find((item) => item.value === props.rule.effect);
        setRuleEffect(effect);
      }
    };
    _setInitValue();
  }, [clients, props.rule]);
  useEffect(() => {
    if (clientRule && ruleEffect)
      props.updateRuleSummary(
        props.rule.id,
        clientRule.label,
        ruleEffect.label
      );
  }, [clientRule, ruleEffect]);

  const failureModalClose = () => {
    setFailureModalState((prev) => ({ ...prev, failureModalDisplay: false }));
  };

  return (
    <Row className="mr-1 mb-3">
      <Col>
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          name="clientRules"
          placeholder="Select Clients"
          value={clientRule}
          options={clients}
          onChange={(option) => handleClientRule(option)}
          isDisabled={!props.updateAllowed}
          required
        />
      </Col>
      <Col md={2}>
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          name="appEffect"
          placeholder="Select Effects"
          options={options}
          value={ruleEffect}
          onChange={(option) => handleEffect(option)}
          isDisabled={!props.updateAllowed}
          required
        />
      </Col>
      <Col md={1} className="align-self-end">
        <Button
          onClick={_removeClientRule}
          color="transparent"
          disabled={props.clientRules.length < 2}
        >
          <FaMinus />
        </Button>
      </Col>
      <ResponseModal
        show={failureModalState.failureModalDisplay}
        onHide={() => failureModalClose()}
        modalheading={failureModalHeading}
        modaltext={failureModalState.failureModalText}
      />
    </Row>
  );
};

const RuleSummary = (props) => {
  return (
    <div>
      <Row className="my-1 align-items-start">
        <Col md={1} className="p-0 ml-3 mt-2">
          <h6>Clients</h6>
        </Col>
        {props.clientRules
          ? props.clientRules.map(function (object, i) {
              return (
                <Col className="pl-0 col-auto  mt-2" key={i}>
                  <Badge
                    className="float-left"
                    color={object.effect === "Allow" ? "success" : "danger"}
                  >
                    {object.app}
                  </Badge>
                </Col>
              );
            })
          : {}}
      </Row>
      <Row className="my-1 align-items-start">
        <Col className="pl-0 ml-3 mt-2" md={1}>
          <h6>Locations</h6>
        </Col>
        {props.locationRules
          ? props.locationRules.map(function (object, i) {
              return (
                <Col className="pl-0 col-auto mt-2" key={i}>
                  <Badge
                    className="float-left"
                    color={object.effect === "Allow" ? "success" : "danger"}
                  >
                    {object.country} : {object.region} : {object.city}
                  </Badge>
                </Col>
              );
            })
          : {}}
      </Row>
    </div>
  );
};

const ContentPromptsRuleCard = (props) => {
  const [clientRules, setClientRules] = useState([]);
  const [locationRules, setLocationRules] = useState([]);
  const [deactivate, setDeactivate] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [appRulesSummary, setClientRulesSummary] = useState([]);
  const [locationRulesSummary, setLocationRulesSummary] = useState([]);

  const _updateClientRuleSummary = (appRuleID, appvalue, effect) => {
    const index = appRulesSummary.findIndex((rule) => {
      return rule.id === appRuleID;
    });
    if (index === -1) {
      setClientRulesSummary((prev) => {
        prev.push({
          id: appRuleID,
          app: appvalue,
          effect: effect,
        });
        return [...prev];
      });
    } else {
      setClientRulesSummary((prev) => {
        prev[index].app = appvalue;
        prev[index].effect = effect;
        return [...prev];
      });
    }
  };

  const _updateLocationRuleSummary = (
    locationRuleID,
    country,
    region,
    city,
    effect
  ) => {
    const index = locationRulesSummary.findIndex((rule) => {
      return rule.id === locationRuleID;
    });
    if (index === -1) {
      setLocationRulesSummary((prev) => {
        prev.push({
          id: locationRuleID,
          country: country,
          region: region,
          city: city,
          effect: effect,
        });

        return [...prev];
      });
    } else {
      setLocationRulesSummary((prev) => {
        prev[index].country = country;
        prev[index].region = region;
        prev[index].city = city;
        prev[index].effect = effect;
        return [...prev];
      });
    }
  };

  const _updateClientRule = (appRuleID, clientId, effect) => {
    const index = clientRules.findIndex((rule) => {
      return rule.id === appRuleID;
    });
    setClientRules((prev) => {
      prev[index].clientId = clientId;
      prev[index].effect = effect;
      return [...prev];
    });
    props.updateRules(props.rule.id, clientRules, locationRules, deactivate);
  };

  const _updateLocationRule = (
    locationRuleID,
    countryId,
    regionId,
    cityId,
    effect
  ) => {
    const index = locationRules.findIndex((rule) => {
      return rule.id === locationRuleID;
    });
    setLocationRules((prev) => {
      prev[index].countryId = countryId;
      prev[index].regionId = regionId;
      prev[index].cityId = cityId;
      prev[index].effect = effect;
      return [...prev];
    });
    props.updateRules(props.rule.id, clientRules, locationRules, deactivate);
  };

  const _updateDeactivate = (status) => {
    setDeactivate(status);
    props.updateRules(props.rule.id, clientRules, locationRules, status);
  };

  useEffect(() => {
    const SetActivate = async () => {
      setDeactivate(
        props.rule.deactivatedAt ? props.rule.deactivatedAt : false
      );
    };
    SetActivate();
  });

  useEffect(() => {
    const SetClientRules = async () => {
      if (props.rule.clientRules) {
        for (let i = 0; i < props.rule.clientRules.length; i++) {
          props.rule.clientRules[i].id = i;
        }
        setClientRules(props.rule.clientRules);
      }
    };
    SetClientRules();
  }, [props.rules]);

  useEffect(() => {
    const SetLocationRules = async () => {
      if (props.rule.locationRules) {
        for (let i = 0; i < props.rule.locationRules.length; i++) {
          props.rule.locationRules[i]["id"] = i;
        }
        setLocationRules(props.rule.locationRules);
      }
    };
    SetLocationRules();
  }, [props.rules]);

  const addClientRule = () => {
    var _updateClientRules = clientRules;
    _updateClientRules.push({
      id: clientRules[clientRules.length - 1].id + 1,
      effect: options[0].value,
    });
    setClientRules(_updateClientRules);
    props.updateRules(
      props.rule.id,
      _updateClientRules,
      locationRules,
      deactivate
    );
  };

  const removeClientRule = (id) => {
    const _updateClientRules = clientRules.filter((rule) => rule.id !== id);
    const _updateClientRulesSummary = appRulesSummary.filter(
      (rule) => rule.id !== id
    );
    for (let i = 0; i < _updateClientRulesSummary.length; i++) {
      _updateClientRulesSummary[i]["id"] = i;
    }
    setClientRules(_updateClientRules);

    props.updateRules(
      props.rule.id,
      _updateClientRules,
      locationRules,
      deactivate
    );
    setClientRulesSummary(_updateClientRulesSummary);
  };

  const addLocationRule = () => {
    var _updateLocationRules = locationRules;

    _updateLocationRules.push({
      id: locationRules[locationRules.length - 1].id + 1,
      countryId: defaultLocationRule.countryId,
      regionId: defaultLocationRule.regionId,
      cityId: defaultLocationRule.cityId,
      effect: defaultLocationRule.effect,
    });

    setLocationRules(_updateLocationRules);
    props.updateRules(
      props.rule.id,
      clientRules,
      _updateLocationRules,
      deactivate
    );
  };

  const removeLocationRule = (id) => {
    const _updateLocationRules = locationRules.filter((rule) => rule.id !== id);
    const _updateLocationRulesSummary = locationRulesSummary.filter(
      (rule) => rule.id !== id
    );
    for (let i = 0; i < _updateLocationRulesSummary.length; i++) {
      _updateLocationRulesSummary[i]["id"] = i;
    }
    setLocationRules(_updateLocationRules);
    props.updateRules(
      props.rule.id,
      clientRules,
      _updateLocationRules,
      deactivate
    );
    setLocationRulesSummary(_updateLocationRulesSummary);
  };

  const _removeRule = () => {
    props.removeRule(props.rule.id);
  };

  return (
    <Card>
      <CardHeader
        onClick={() => setToggle(!toggle)}
        className="pt-3 pb-0"
        style={{ cursor: "pointer" }}
      >
        <div className="container">
          <div className="row">
            <Col md="auto" className="pl-0">
              <h4>
                <strong>Rule {props.rule.id + 1}</strong>
              </h4>
            </Col>
            <Col>
              {props.rule.deactivatedAt ? (
                <h4>
                  <Badge
                    className="float-left ml-2"
                    style={{ width: "100px" }}
                    color={"warning"}
                  >
                    Deactivated
                  </Badge>
                </h4>
              ) : (
                <h4>
                  <Badge
                    className="float-left ml-2"
                    style={{ width: "100px" }}
                    color={"success"}
                  >
                    Activated
                  </Badge>
                </h4>
              )}
            </Col>
            <div className="ml-auto">
              {toggle ? <FaChevronUp /> : <FaChevronDown />}
            </div>
          </div>
        </div>
      </CardHeader>
      <Collapse isOpen={!toggle}>
        <CardBody className="pb-3 pt-1">
          <RuleSummary
            key={props.rule.id}
            clientRules={appRulesSummary}
            locationRules={locationRulesSummary}
          />
        </CardBody>
      </Collapse>
      <Collapse isOpen={toggle}>
        <CardBody className="pb-3 pt-1" width={10}>
          <p className="mt-3 mb-1">
            <strong>Clients</strong>
          </p>
          <Row>
            <Col md={8} className="mr-5">
              <Label>Name</Label>
            </Col>
            <Col md={3} className="ml-4 pl-4">
              <Row>
                <Col md={8}>
                  <Label>Effect</Label>
                </Col>
                <Col md={4}>
                  <Button onClick={addClientRule} color="transparent">
                    <FontAwesomeIcon size="sm" icon={faPlus} />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          {clientRules
            ? clientRules.map(function (object, i) {
                return (
                  <ClientRule
                    key={i}
                    rule={object}
                    updateRule={_updateClientRule}
                    updateRuleSummary={_updateClientRuleSummary}
                    addClientRule={addClientRule}
                    removeClientRule={removeClientRule}
                    updateAllowed={props.updateAllowed}
                    clientRules={clientRules}
                  />
                );
              })
            : {}}
          <Row></Row>
          <p className="mt-3 mb-1">
            <strong>Locations</strong>
          </p>
          <Row>
            <Col>
              <Label>Country</Label>
            </Col>
            <Col md={2} className="pl-0">
              <Label>Region</Label>
            </Col>
            <Col className="ml-5 pl-5">
              <Label>City</Label>
            </Col>
            <Col className="pl-0">
              <Row>
                <Col md={7}>
                  <Label>Effect</Label>
                </Col>
                <Col md={5} className="pl-4">
                  <Button onClick={addLocationRule} color="transparent">
                    <FontAwesomeIcon size="sm" icon={faPlus} />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          {locationRules
            ? locationRules.map(function (object) {
                return (
                  <LocationRule
                    key={object.id}
                    rule={object}
                    updateRule={_updateLocationRule}
                    updateRuleSummary={_updateLocationRuleSummary}
                    addLocationRule={addLocationRule}
                    removeLocationRule={removeLocationRule}
                    updateAllowed={props.updateAllowed}
                    countries={props.countries}
                    regions={props.regions}
                    cities={props.cities}
                    locationRules={locationRules}
                  />
                );
              })
            : {}}
          <Row></Row>
          {props.updateAllowed ? (
            <>
              <hr />
              <div className="mt-1 d-flex flex-nowrap">
                <div className="order-1 p-2">
                  <Button onClick={_removeRule} color="danger">
                    Delete Rule
                  </Button>
                </div>

                <div className="order-2 p-2">
                  {deactivate ? (
                    <Button
                      onClick={() => _updateDeactivate(false)}
                      color="success"
                    >
                      Activate Rule
                    </Button>
                  ) : (
                    <Button
                      onClick={() => _updateDeactivate(true)}
                      color="warning"
                    >
                      Deactivate Rule
                    </Button>
                  )}
                </div>
              </div>
              <hr />
            </>
          ) : (
            <div></div>
          )}
        </CardBody>
      </Collapse>
    </Card>
  );
};

export default ContentPromptsRuleCard;

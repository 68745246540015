import React from 'react'
import { Modal, Button } from "react-bootstrap";
import { Row, Col, FormGroup, Label, Input, Form } from "reactstrap";

const ConfigModal = ({ show = false, type, formData, setFormData = () => { }, onSubmit = () => { }, onHide = () => { } }) => {
  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit()
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header className="bg-light" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {type} Config
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row form>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  Config Update Interval <span className="text-danger">*</span>
                </Label>
                <Input
                  type="number"
                  name="configUpdateInterval"
                  min={0}
                  placeholder="Enter Config Update Interval"
                  value={formData.configUpdateInterval}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col lg={6} md={12}>
              <FormGroup>
                <Label
                  className="font-weight-bolder mb-2"
                  style={{ fontSize: "14px" }}
                >
                  App Metadata URL <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="appMetadataUrl"
                  placeholder="Enter URL"
                  value={formData.appMetadataUrl}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
          <Button type='submit' className='btn-success'>Submit</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default ConfigModal
import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import "react-tagsinput/react-tagsinput.css";
import { Link } from "react-router-dom";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { permissions } from "../../../utilities/commonUtil";
import ToolTip from "../../../utilities/ToolTip";
import {
  adBannerTypes,
  actionTypeOptions,
  mediaTypeOptions,
  trackersTypeOptions,
  bannerSizeOptions,
  quickRepliesCTAActionsDeeplinkType,
  DeeplinkType
} from "../../../config/quickReplies";
import { Plus, X } from "react-feather";
import {
  getClients,
  getBrandCampaigns,
} from "../../../utilities/apiUtils/stickers";

import { redirectionOnTokenExpiry } from "../../../utilities/commonUtil";

import { getAdBannerDetail } from "../../../utilities/apiUtils/adBanners";

import { getQuickReplyCategories } from "../../../utilities/apiUtils/quickReplies";

import {
  Col,
  Row,
  Button,
  Container,
  Card,
  CardBody,
  Input,
  Label,
  Form,
  FormGroup,
  CustomInput,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const AdBannerForm = (props) => {
  const [formTextState, setFormTextState] = useState({
    name: "",
    bannerImageBuffer: "",
    bannerVideoBuffer: "",
    bannerVideoBufferType: "",
    enableAutoPlay: "",
    enableAudio: "",
    bannerAspectRatio: "",
    bannerImageURL: "",
    bannerVideoURL: "",
    brandCampaignID: null,
    widthPercentage: "",
    type: "",
    mediaType: "",
    quickReplyCategories: "",
  });

  const [clientOptions, setClientOptions] = useState([]);

  const [quickReplyCategories, setQuickReplyCategories] = useState([]);

  const [adBannerAction, setAdBannerAction] = useState([
    {
      deeplink: null,
      url: null,
      redirectionType: null,
      redirectionViewportHeight: null,
      clientID: "",
      deeplinkType: { value: "default", label: "Auto Generate From Input Values" },
      autoGeneratedDeeplink: null,
    },
  ]);

  console.log("adBannerAction: ", adBannerAction);

  // make the input field required
  const requiredComponent = (props) => (
    <div>
      <components.Input {...props} required={true} />
    </div>
  );

  const notRequiredComponent = (props) => (
    <div>
      <components.Input {...props} required={false} />
    </div>
  );

  const [imageAspectRatioError, setImageAspectRatioError] = useState(null);
  const [videoAspectRatioError, setVideoAspectRatioError] = useState(null);

  const [clickTrackers, setClickTrackers] = useState([]);

  const [impressionTrackers, setImpressionTrackers] = useState([]);

  const [displayError, setDisplayError] = useState(false);
  const [campaigns, setCampaigns] = useState([]);

  const [allowedPermissions] = useState(
    retrieveFromLocalStorage("userPermissions")
  );

  const showEditRulesButton = () => {
    return allowedPermissions.includes(
      permissions.quickReplyAdbannerRulesUpdate
    );
  };

  const handleTypeChange = (type) => {
    setFormTextState((prev) => ({
      ...prev,
      type: type,
    }));

    if (type?.value === "horizontal") {
      setFormTextState((prev) => ({
        ...prev,
        bannerAspectRatio: bannerSizeOptions.horizontal.bannerAspectRatio,
        widthPercentage: bannerSizeOptions.horizontal.widthPercentage,
      }));
    }

    if (type?.value === "vertical") {
      setFormTextState((prev) => ({
        ...prev,
        bannerAspectRatio: bannerSizeOptions.vertical.bannerAspectRatio,
        widthPercentage: bannerSizeOptions.vertical.widthPercentage,
      }));
    }

    // make the image and video buffer null
    setFormTextState((prev) => ({
      ...prev,
      bannerImageBuffer: "",
      bannerVideoBuffer: "",
      bannerVideoBufferType: "",
      bannerImagePreview: "",
      bannerVideoPreview: "",
    }));

    // make the image and video url null
    setFormTextState((prev) => ({
      ...prev,
      bannerImageURL: "",
      bannerVideoURL: "",
    }));

    // make the image and video aspect ratio error null
    setImageAspectRatioError(null);
    setVideoAspectRatioError(null);

    // also make placeholder of image and video null

    document.addEventListener("DOMContentLoaded", function() {
      document.getElementById("bannerImageBuffer").innerHTML = "Choose an image";
      document.getElementById("bannerVideoBuffer").innerHTML = "Choose a video";
    });

    setDisplayError(false);
  };

  const handleCampaignChange = (campaign) => {
    setFormTextState((prev) => ({
      ...prev,
      brandCampaignID: campaign,
    }));
    setDisplayError(false);
  };

  const handleFileChange = async (event) => {
    const { name, files } = event.target;

    // Check if the selected file is an image
    if (name === "bannerImageBuffer" && !files[0]?.type.startsWith("image/")) {
      // Handle the case where a non-image file is selected
      console.error("Please select an image file.");
      return;
    }

    // Check if the selected file is a video/image
    if (
      name === "bannerVideoBuffer" &&
      !files[0]?.type.startsWith("video/") &&
      !files[0]?.type.startsWith("image/")
    ) {
      // Handle the case where a non-video/non-image file is selected
      console.error("Please select a video/image file.");
      return;
    }

    event.persist();

    // For showing Image preview
    if (files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        if (name === "bannerImageBuffer") {
          const img = new Image();
          img.src = reader.result;
          img.onload = () => {
            console.log(
              "Image Aspect Ratio : " +
                img.width / img.height +
                " Required : " +
                formTextState.bannerAspectRatio
            );
            if (img.width / img.height !== formTextState.bannerAspectRatio) {
              let ratio =
                formTextState.bannerAspectRatio === 2.5 ? "5:2" : "9:16";
              setImageAspectRatioError(
                `(Please upload an image with an aspect ratio of ${ratio})`
              );
              setFormTextState((prev) => ({
                ...prev,
                bannerImagePreview: "",
              }));
              setFormTextState((prev) => ({
                ...prev,
                bannerImageBuffer: "",
              }));
              setTimeout(() => {
                document.getElementById("bannerImageBuffer").innerHTML =
                  "Choose an image";
              }, 100);
            } else {
              setImageAspectRatioError(null);
              setFormTextState((prev) => ({
                ...prev,
                bannerImagePreview: reader.result,
              }));

              setFormTextState((prev) => ({
                ...prev,
                bannerImageBuffer: files[0],
              }));

              setTimeout(() => {
                document.getElementById("bannerImageBuffer").innerHTML =
                  files[0].name;
              }, 100);
            }
          };
        } else {
          if (files[0].type.startsWith("image/")) {
            setFormTextState((prev) => ({
              ...prev,
              bannerVideoBufferType: "image",
            }));
            const img = new Image();
            img.src = reader.result;
            img.onload = () => {
              console.log(
                "Image Aspect Ratio : " +
                  img.width / img.height +
                  " Required : " +
                  formTextState.bannerAspectRatio
              );
              if (img.width / img.height !== formTextState.bannerAspectRatio) {
                let ratio =
                  formTextState.bannerAspectRatio === 2.5 ? "5:2" : "9:16";
                setVideoAspectRatioError(
                  `(Please upload an image with an aspect ratio of ${ratio})`
                );
                setFormTextState((prev) => ({
                  ...prev,
                  bannerVideoPreview: "",
                }));
                setFormTextState((prev) => ({
                  ...prev,
                  bannerVideoBuffer: "",
                }));
                setTimeout(() => {
                  document.getElementById("bannerVideoBuffer").innerHTML =
                    "Choose a video/image";
                }, 100);
              } else {
                setVideoAspectRatioError(null);
                setFormTextState((prev) => ({
                  ...prev,
                  bannerVideoPreview: reader.result,
                }));

                setFormTextState((prev) => ({
                  ...prev,
                  bannerVideoBuffer: files[0],
                }));

                setTimeout(() => {
                  document.getElementById("bannerVideoBuffer").innerHTML =
                    files[0].name;
                }, 100);
              }
            };
          }

          if (files[0].type.startsWith("video/")) {
            setFormTextState((prev) => ({
              ...prev,
              bannerVideoBufferType: "video",
            }));
            const video = document.createElement("video");
            video.src = reader.result;
            video.preload = "metadata";
            video.onloadedmetadata = () => {
              console.log(
                "Video Aspect Ratio : " +
                  video.videoWidth / video.videoHeight +
                  " Required : " +
                  formTextState.bannerAspectRatio
              );
              if (
                video.videoWidth / video.videoHeight !==
                formTextState.bannerAspectRatio
              ) {
                let ratio =
                  formTextState.bannerAspectRatio === 2.5 ? "5:2" : "9:16";
                setVideoAspectRatioError(
                  `(Please upload a video with an aspect ratio of ${ratio})`
                );
                setFormTextState((prev) => ({
                  ...prev,
                  bannerVideoPreview: "",
                }));

                setFormTextState((prev) => ({
                  ...prev,
                  bannerVideoBuffer: "",
                }));

                setTimeout(() => {
                  document.getElementById("bannerVideoBuffer").innerHTML =
                    "Choose a video";
                }, 100);
              } else {
                setVideoAspectRatioError(null);
                setFormTextState((prev) => ({
                  ...prev,
                  bannerVideoPreview: reader.result,
                }));

                setFormTextState((prev) => ({
                  ...prev,
                  bannerVideoBuffer: files[0],
                }));

                setTimeout(() => {
                  document.getElementById("bannerVideoBuffer").innerHTML =
                    files[0].name;
                }, 100);
              }
            };
          }
        }
      };
    }
  };

  const handleCheckBoxChange = (event) => {
    const { id, checked } = event.target;
    setFormTextState((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };

  const addClickTracker = () => {
    setClickTrackers((prev) => [
      ...prev,
      {
        type: { value: "direct_url", label: "Direct URL" },
        url: "",
      },
    ]);
  };

  const addImpressionTracker = () => {
    setImpressionTrackers((prev) => [
      ...prev,
      {
        type: { value: "direct_url", label: "Direct URL" },
        url: "",
      },
    ]);
  };

  const submit = (event) => {
    event.preventDefault();
    let inputsWithError = document.getElementsByClassName("is-invalid");
    if (
      inputsWithError.length === 0 &&
      formTextState.name !== "" &&
      formTextState.bannerImageBuffer !== "" &&
      formTextState.mediaType !== null &&
      formTextState.type !== null &&
      formTextState.bannerAspectRatio !== "" &&
      formTextState.widthPercentage !== "" &&
      formTextState.quickReplyCategories !== null
    ) {
      let temp = { ...formTextState };

      temp.clickTrackers = clickTrackers;
      if (temp?.clickTrackers?.length === 0) {
        temp.clickTrackers = null;
      }
      temp.impressionTrackers = impressionTrackers;
      if (temp?.impressionTrackers?.length === 0) {
        temp.impressionTrackers = null;
      }
      temp.adBannerAction = adBannerAction;

      if (temp.clickTrackers !== null && temp.clickTrackers !== undefined) {
        temp.clickTrackers.forEach((item) => {
          item.type = item.type.value;
        });
      }

      if (
        temp.impressionTrackers !== null &&
        temp.impressionTrackers !== undefined
      ) {
        temp.impressionTrackers.forEach((item) => {
          item.type = item.type.value;
        });
      }

      temp.adBannerAction.forEach((item) => {
        item.redirectionType = item.redirectionType?.value;
        if (item.redirectionType === undefined) {
          item.redirectionType = null;
        }

        // change later
        item.clientID = item.clientID?.value;
        item.redirectionViewportHeight = parseInt(
          item.redirectionViewportHeight
        );

        if (item?.deeplinkType?.value === "default") {
          item.deeplink = null;
        }
        item.deeplinkType = item?.deeplinkType?.value;

      });

      console.log(temp.quickReplyCategories);

      temp.quickReplyCategories = temp.quickReplyCategories.map(
        (item) => item?.value
      );

      // remove all null values from the array
      temp.quickReplyCategories = temp.quickReplyCategories.filter(
        (item) => item !== null
      );

      temp.mediaType = formTextState?.mediaType?.value;
      temp.type = formTextState?.type?.value;

      temp.brandCampaignID = null;
      if (formTextState.brandCampaignID)
        temp.brandCampaignID = formTextState.brandCampaignID.value;

      props.submit(temp);
    } else {
      setDisplayError(true);
    }
  };

  const manageClientOptions = () => {
    if (clientOptions === undefined || clientOptions?.length === 0) return [];

    // return only those client options which are not already selected
    let temp = [...clientOptions];

    adBannerAction.forEach((item) => {
      temp = temp.filter((client) => client.value !== item.clientID?.value);
    });
    return temp;
  };

  useEffect(() => {
    let brandCampaigns = [];
    let clientOptions = [];
    let quickReplyCategories = [];
    const _getBrandCampaigns = async () => {
      brandCampaigns = await getBrandCampaigns();
      setCampaigns(brandCampaigns);
    };
    const _getClientOptions = async () => {
      clientOptions = await getClients();
      setClientOptions(clientOptions);
    };
    const _getQuickReplyCategories = async () => {
      let response = await getQuickReplyCategories();
      quickReplyCategories = response?.quickReplyCategories?.map((item) => ({
        value: item.ID,
        label: item.name,
      }));
      setQuickReplyCategories(quickReplyCategories);
    };

    const getAdBannerDetails = async () => {
      if (props.adBanner.formName === "Details") {
        try {
          let res = await getAdBannerDetail(props.id);
          const type = adBannerTypes.find((type) => type.value === res.type);

          const mediaType = mediaTypeOptions.find(
            (mediaType) => mediaType.value === res.mediaType
          );

          const brandCampaignID = brandCampaigns?.find(
            (campaign) => campaign.value === res.brandCampaignID
          );

          if (res.adBannerAction !== undefined) {
            const adBannerActions = res?.adBannerAction?.map((item) => ({
              deeplink: item.deeplink,
              url: item.url,
              redirectionType: actionTypeOptions.find(
                (redirectionType) =>
                  redirectionType.value === item.redirectionType
              ),
              deeplinkType: DeeplinkType.find(
                (deeplinkType) => deeplinkType?.value === item?.deeplinkType
              ) || DeeplinkType.find(
                (deeplinkType) => deeplinkType?.value === "custom"
              ),
              autoGeneratedDeeplink: item.deeplink,
              redirectionViewportHeight: item.redirectionViewportHeight,
              clientID: clientOptions?.find(
                (client) => client.value === item.clientID
              ),
            }));

            setAdBannerAction(adBannerActions);
          }

          if (res.clickTrackers !== "" && res.clickTrackers !== null) {
            res.clickTrackers = JSON.parse(res.clickTrackers);

            const clickTrackers = res.clickTrackers?.map((item) => ({
              type: trackersTypeOptions.find(
                (trackersType) => trackersType.value === item?.type
              ),
              url: item?.url,
            }));
            setClickTrackers(clickTrackers);
          }

          if (
            res.impressionTrackers !== "" &&
            res.impressionTrackers !== null
          ) {
            res.impressionTrackers = JSON.parse(res.impressionTrackers);

            const impressionTrackers = res.impressionTrackers?.map((item) => ({
              type: trackersTypeOptions.find(
                (trackersType) => trackersType.value === item.type
              ),
              url: item.url,
            }));

            setImpressionTrackers(impressionTrackers);
          }

          let quickReplyCategoriesID = [];

          if (
            quickReplyCategories !== undefined &&
            quickReplyCategories?.length > 0
          ) {
            quickReplyCategoriesID = res.quickReplyCategoriesIDs?.map(
              (item) => {
                return quickReplyCategories?.find(
                  (option) => option.value === item
                );
              }
            );
          }

          let bannerVideoBufferType = "";

          // set bannerVideoBufferType by using the bannerVideoURL
          if (res.bannerVideoURL !== "" && res.bannerVideoURL !== null) {
            // detect video type
            if (res.bannerVideoURL.includes(".mp4")) {
              bannerVideoBufferType = "video";
            }
            else {
              bannerVideoBufferType = "image";
            }
          }

          setFormTextState({
            ...formTextState,
            name: res.name,
            bannerImageBuffer: res.bannerImageBuffer,
            bannerVideoBuffer: res.bannerVideoBuffer,
            bannerImageURL: res.bannerImageURL,
            bannerVideoURL: res.bannerVideoURL,
            bannerVideoBufferType: bannerVideoBufferType,
            enableAutoPlay: res.enableAutoPlay,
            enableAudio: res.enableAudio,
            bannerAspectRatio:
              type?.value === "horizontal"
                ? bannerSizeOptions.horizontal.bannerAspectRatio
                : bannerSizeOptions.vertical.bannerAspectRatio,
            brandCampaignID: brandCampaignID,
            widthPercentage:
              type?.value === "horizontal"
                ? bannerSizeOptions.horizontal.widthPercentage
                : bannerSizeOptions.vertical.widthPercentage,
            type: type,
            mediaType: mediaType,
            quickReplyCategories: quickReplyCategoriesID,
          });
        } catch (err) {
          console.log("err", err);
          redirectionOnTokenExpiry(err);
        }
      }
    };

    // make a async function to call all the below functions
    const makeAsyncCalls = async () => {
      await _getQuickReplyCategories();
      await _getClientOptions();
      await _getBrandCampaigns();
      await getAdBannerDetails();
    };

    makeAsyncCalls();
  }, []);

  return (
    <Container fluid className="p-0">
      {props.adBanner.formName === "Details" && (
        <Card>
          <CardBody>
            <Row className="px-3">
              <Col md={8} sm={6}>
                <h3>Targeting Rules</h3>
                <p>
                  Age-Gender, Clients and Locations based targeting for this AD
                  Banner
                </p>
              </Col>
              {showEditRulesButton() ? (
                <Col md={4} sm={6}>
                  <Link
                    className="text-decoration-none mx-5"
                    to={{
                      pathname: `/quick-reply/ad-banners/targeting-rules/${props.id}`,
                    }}
                  >
                    <Button color="primary" className="d-block ml-auto mt-2">
                      Edit Rules
                    </Button>
                  </Link>
                </Col>
              ) : null}
            </Row>
          </CardBody>
        </Card>
      )}

      <h1 className="h3 mb-3">{props.adBanner.formName}</h1>
      <Form
        onSubmit={(event) => {
          submit(event);
        }}
      >
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        <strong>
                          Name <span className="text-danger">*</span>
                        </strong>
                      </Label>
                      <Input
                        type="text"
                        name="name"
                        placeholder="Enter name"
                        value={formTextState.name}
                        onChange={(event) => {
                          const { name, value } = event.target;
                          setFormTextState((prev) => ({
                            ...prev,
                            [name]: value,
                          }));
                        }}
                        disabled={!props.updateAllowed}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        <strong>Brand Campaign</strong>
                      </Label>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        name="campaign"
                        value={formTextState.brandCampaignID}
                        placeholder="Select campaign"
                        isClearable={true}
                        options={campaigns}
                        onChange={(option) => handleCampaignChange(option)}
                        isDisabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        <strong>
                          Type <span className="text-danger">*</span>
                          {formTextState?.type?.value === "horizontal" && (
                            <ToolTip
                              data="Formats: Banner Aspect Ratio = 5:2 & Width Percentage: 83.33%"
                              id="staticIconTooltip"
                            />
                          )}
                          {formTextState?.type?.value === "vertical" && (
                            <ToolTip
                              data="Formats: Banner Aspect Ratio = 9:16 & Width Percentage: 41.67%"
                              id="staticIconTooltip"
                            />
                          )}
                        </strong>
                      </Label>

                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        name="type"
                        value={formTextState.type}
                        placeholder="Select type"
                        isClearable={true}
                        options={adBannerTypes}
                        components={{
                          Input:
                            formTextState.type === ""
                              ? requiredComponent
                              : notRequiredComponent,
                        }}
                        onChange={(option) => handleTypeChange(option)}
                        isDisabled={!props.updateAllowed}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>
                        <strong>
                          Media Type <span className="text-danger">*</span>
                        </strong>
                      </Label>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        name="mediaType"
                        value={formTextState.mediaType}
                        placeholder="Select media type"
                        isClearable={true}
                        options={mediaTypeOptions}
                        components={{
                          Input:
                            formTextState.mediaType === ""
                              ? requiredComponent
                              : notRequiredComponent,
                        }}
                        onChange={(option) => {
                          setFormTextState((prev) => ({
                            ...prev,
                            mediaType: option,
                          }));
                        }}
                        isDisabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        <strong>
                          Banner Image <span className="text-danger">*</span>
                          {imageAspectRatioError !== null && (
                            <span className="text-danger">
                              {imageAspectRatioError}
                            </span>
                          )}
                        </strong>
                      </Label>
                      <br />
                      <Container className="custom-file">
                        <Input
                          type="file"
                          className="custom-file-input"
                          accept="image/*"
                          name="bannerImageBuffer"
                          onChange={(event) => handleFileChange(event)}
                          disabled={
                            !props.updateAllowed ||
                            formTextState.type === "" ||
                            formTextState.type === null
                          }
                          required={
                            formTextState.bannerImageURL === undefined ||
                            formTextState.bannerImageURL === "" ||
                            formTextState.bannerImageURL === null
                          }
                        />
                        <Label
                          className="custom-file-label"
                          id="bannerImageBuffer"
                        >
                          <strong>Choose an image</strong>
                        </Label>
                      </Container>
                    </FormGroup>
                  </Col>
                </Row>

                {(formTextState.bannerImagePreview !== "" &&
                  formTextState.bannerImagePreview !== undefined) ||
                (formTextState.bannerImageURL !== "" &&
                  formTextState.bannerImageURL !== undefined) ? (
                  <Col className="pb-4">
                    <img
                      src={
                        formTextState.bannerImagePreview ||
                        formTextState.bannerImageURL
                      }
                      height="120"
                      alt="previewImage"
                      width="120"
                    />
                  </Col>
                ) : (
                  <Col></Col>
                )}

                {formTextState?.mediaType?.value === "video" && (
                  <>
                    <hr style={{ margin: 8 }} />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>
                            <strong>
                              Banner Video <span className="text-danger">*</span>
                              {videoAspectRatioError !== null && (
                                <span className="text-danger">
                                  {videoAspectRatioError}
                                </span>
                              )}
                            </strong>
                          </Label>
                          <br />
                          <Container className="custom-file">
                            <Input
                              type="file"
                              className="custom-file-input"
                              accept="image/*,video/*"
                              name="bannerVideoBuffer"
                              onChange={(event) => handleFileChange(event)}
                              disabled={
                                !props.updateAllowed ||
                                formTextState.type === "" ||
                                formTextState.type === null
                              }
                              required={
                                formTextState.bannerVideoURL === undefined ||
                                formTextState.bannerVideoURL === "" ||
                                formTextState.bannerVideoURL === null
                              }
                            />
                            <Label
                              className="custom-file-label"
                              id="bannerVideoBuffer"
                            >
                              <strong>Choose an video</strong>
                            </Label>
                          </Container>
                        </FormGroup>
                      </Col>
                    </Row>

                    {(formTextState.bannerVideoPreview !== "" &&
                      formTextState.bannerVideoPreview !== undefined) ||
                    (formTextState.bannerVideoURL !== "" &&
                      formTextState.bannerVideoURL !== undefined) ? (
                      <>
                        {formTextState.bannerVideoBufferType === "video" && (
                          <video
                            src={
                              formTextState.bannerVideoPreview ||
                              formTextState.bannerVideoURL
                            }
                            height="320"
                            alt="bannerVideoBuffer"
                            width="480"
                            controls
                          />
                        )}

                        {formTextState.bannerVideoBufferType === "image" && (
                          <img
                            src={
                              formTextState.bannerVideoPreview ||
                              formTextState.bannerVideoURL
                            }
                            height="320"
                            alt="bannerVideoBuffer"
                            width="480"
                          />
                        )}
                      </>
                    ) : (
                      <Col></Col>
                    )}

                    <hr style={{ margin: 8 }} />
                    <Row>
                      <Col md={2}>
                        <Label>
                          <strong>Enable AutoPlay</strong>
                        </Label>
                      </Col>

                      <Col
                        md={2}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div className="custom-control custom-switch">
                          <CustomInput
                            type="checkbox"
                            id="enableAutoPlay"
                            value={formTextState.enableAutoPlay === true}
                            onChange={(event) => handleCheckBoxChange(event)}
                            checked={formTextState.enableAutoPlay === true}
                          />
                        </div>
                      </Col>

                      {/* Vertical Line */}
                      <Col md={4}></Col>

                      <Col md={2}>
                        <Label>
                          <strong>Enable Audio</strong>
                        </Label>
                      </Col>

                      <Col
                        md={2}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div className="custom-control custom-switch">
                          <CustomInput
                            type="checkbox"
                            id="enableAudio"
                            value={formTextState.enableAudio === true}
                            onChange={(event) => handleCheckBoxChange(event)}
                            checked={formTextState.enableAudio === true}
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}

                <hr style={{ margin: 8 }} />
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        <strong>
                          Quick Reply Categories{" "}
                          <span className="text-danger">*</span>
                        </strong>
                      </Label>
                      <Select
                        id="quickReplyCategories"
                        isMulti
                        className="react-select-container"
                        classNamePrefix="react-select"
                        name="quickReplyCategories"
                        placeholder="Select Quick Reply categories"
                        value={formTextState.quickReplyCategories}
                        components={{
                          Input:
                            formTextState?.quickReplyCategories?.length === 0 ||
                            formTextState.quickReplyCategories === ""
                              ? requiredComponent
                              : notRequiredComponent,
                        }}
                        onChange={(event) => {
                          setFormTextState((prev) => ({
                            ...prev,
                            quickReplyCategories: event,
                          }));
                        }}
                        options={quickReplyCategories}
                        isDisabled={!props.updateAllowed}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <h4>Actions</h4>
            <Card>
              <CardBody>
                {adBannerAction?.map((item, index) => {
                  return (
                    <>
                      <Row key={`row-${index + 1}`}>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="deeplinkType">Deeplink Type<span className="text-danger">*</span></Label>
                                    <Select
                                        id="deeplinkType"
                                        name="deeplinkType"
                                        isDisabled={!props.updateAllowed}
                                        className="react-select-container mb-4"
                                        classNamePrefix="react-select"
                                        options={quickRepliesCTAActionsDeeplinkType}
                                        value={item?.deeplinkType}
                                        onChange={(e) => {
                                          console.log("Deeplink Type changed: ",e);
                                          let temp = [...adBannerAction];
                                          temp[index].deeplinkType = e;
                                          temp[index].deeplink = null;
                                          setAdBannerAction(temp);
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        <Col>
                          {props.updateAllowed && (
                            <X
                              className="float-right"
                              size={20}
                              color="red"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                let temp = [...adBannerAction];
                                if (temp.length === 1) {
                                  // If the length is 1, do not remove the item
                                  alert("Cannot remove the last item.");
                                } else {
                                  temp = temp.filter((_, idx) => idx !== index);
                                  setAdBannerAction(temp);
                                }
                              }}
                            ></X>
                          )}
                        </Col>
                        <Col md={12}>
                          <FormGroup>
                            <Label>
                              <strong>
                                Deeplink{" "}
                                <small className="text-warning">
                                  (Mandatory for Bobble)
                                </small>
                              </strong>
                            </Label>
                            <Input
                              type="textarea"
                              name="deeplink"
                              placeholder="Enter Deeplink"
                              value={item?.deeplinkType?.value === "default" ? item.autoGeneratedDeeplink : item.deeplink}
                              onChange={(e) => {
                                let temp = [...adBannerAction];
                                temp[index].deeplink = e.target.value;
                                setAdBannerAction(temp);
                              }}
                              disabled={!props.updateAllowed || item?.deeplinkType?.value === "default"}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row key={`row-${index + 2}`}>
                        <Col md={6}>
                          <FormGroup>
                            <Label>
                              <strong>
                                URL{" "}
                                <small className="text-warning">
                                  (Mandatory for Mint)
                                </small>
                              </strong>
                            </Label>
                            <Input
                              type="text"
                              name="url"
                              placeholder="Enter URL"
                              value={item.url}
                              onChange={(event) => {
                                let temp = [...adBannerAction];
                                temp[index].url = event.target.value;
                                setAdBannerAction(temp);
                              }}
                              disabled={!props.updateAllowed}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <Label>
                            <strong>
                              Redirection Type{" "}
                              <small className="text-warning">
                                (Mandatory for Mint)
                              </small>
                            </strong>
                          </Label>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            name="redirectionType"
                            value={item.redirectionType}
                            placeholder="Select Redirection Type"
                            isClearable={true}
                            options={actionTypeOptions}
                            onChange={(option) => {
                              let temp = [...adBannerAction];
                              temp[index].redirectionType = option;
                              setAdBannerAction(temp);
                            }}
                            isDisabled={!props.updateAllowed}
                          />
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label>
                              <strong>
                                Redirection View Port Height{" "}
                                <small className="text-warning">
                                  (Mandatory for Mint)
                                </small>
                              </strong>
                            </Label>
                            <Input
                              type="number"
                              placeholder="Enter Redirection View Port Height"
                              name="redirectionViewportHeight"
                              value={item.redirectionViewportHeight}
                              onChange={(event) => {
                                const numericValue = event.target.value;

                                if (numericValue >= 0 && numericValue <= 100) {
                                  let temp = [...adBannerAction];
                                  temp[index].redirectionViewportHeight =
                                    numericValue;
                                  setAdBannerAction(temp);
                                }
                              }}
                              disabled={!props.updateAllowed}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label>
                              <strong>
                                Client <span className="text-danger">*</span>
                              </strong>
                            </Label>
                            <Select
                              className="react-select-container"
                              classNamePrefix="react-select"
                              name="clientID"
                              value={item.clientID}
                              placeholder="Select Client ID"
                              isClearable={true}
                              components={{
                                Input:
                                  item.clientID === ""
                                    ? requiredComponent
                                    : notRequiredComponent,
                              }}
                              options={manageClientOptions()}
                              onChange={(option) => {
                                let temp = [...adBannerAction];
                                temp[index].clientID = option;
                                setAdBannerAction(temp);
                              }}
                              isDisabled={!props.updateAllowed}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <hr style={{ marginTop: "50px", marginBottom: "50px" }} />
                    </>
                  );
                })}

                <Row>
                  <Col>
                    {props.updateAllowed && (
                      <Button
                        color="primary"
                        className="mt-2"
                        onClick={() => {
                          setAdBannerAction((prev) => [
                            ...prev,
                            {
                              deeplink: null,
                              url: null,
                              redirectionType: null,
                              redirectionViewportHeight: null,
                              clientID: "",
                            },
                          ]);
                        }}
                      >
                        <FontAwesomeIcon
                          size="sm"
                          icon={faPlus}
                        ></FontAwesomeIcon>{" "}
                        Add Ad Banner Action
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <h4>Click Trackers</h4>
            <Card>
              <CardBody>
                {clickTrackers?.map((item, index) => (
                  <>
                    <Row key={`row-${index + 6}`}>
                      <Col>
                        <FormGroup>
                          <Label>
                            <strong>
                              Type <span className="text-danger">*</span>
                            </strong>
                          </Label>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            name="type"
                            value={item.type}
                            placeholder="Select type"
                            isClearable={true}
                            components={{
                              Input:
                                item.type === ""
                                  ? requiredComponent
                                  : notRequiredComponent,
                            }}
                            options={trackersTypeOptions}
                            onChange={(option) => {
                              let temp = [...clickTrackers];
                              temp[index].type = option;
                              setClickTrackers(temp);
                            }}
                            isDisabled={!props.updateAllowed}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        {props.updateAllowed && (
                          <X
                            className="float-right"
                            size={20}
                            color="red"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              let temp = [...clickTrackers];
                              temp = temp.filter((_, idx) => idx !== index);
                              setClickTrackers(temp);
                            }}
                          ></X>
                        )}
                      </Col>
                    </Row>

                    <Row key={`row-${index + 7}`}>
                      <Col>
                        <FormGroup>
                          <Label>
                            <strong>
                              URL <span className="text-danger">*</span>
                            </strong>
                          </Label>
                          <Input
                            type="textarea"
                            name="url"
                            value={item.url}
                            placeholder="Enter URL"
                            onChange={(option) => {
                              let temp = [...clickTrackers];
                              temp[index].url = option.target.value;
                              setClickTrackers(temp);
                            }}
                            disabled={!props.updateAllowed}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr style={{ marginTop: "50px", marginBottom: "50px" }} />
                  </>
                ))}

                {props.updateAllowed && (
                  <Button onClick={addClickTracker}>
                    <FontAwesomeIcon size="sm" icon={faPlus}></FontAwesomeIcon>
                    Add Click Tracker
                  </Button>
                )}
              </CardBody>
            </Card>

            {/* Impression Trackers */}
            <h4>Impression Trackers</h4>
            <Card>
              <CardBody>
                {impressionTrackers?.map((item, index) => (
                  <>
                    <Row key={`row-${index + 8}`}>
                      <Col>
                        <FormGroup>
                          <Label>
                            <strong>
                              Type <span className="text-danger">*</span>
                            </strong>
                          </Label>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            name="type"
                            value={item.type}
                            components={{
                              Input:
                                item.type === ""
                                  ? requiredComponent
                                  : notRequiredComponent,
                            }}
                            placeholder="Select type"
                            isClearable={true}
                            options={trackersTypeOptions}
                            onChange={(option) => {
                              let temp = [...impressionTrackers];
                              temp[index].type = option;
                              setImpressionTrackers(temp);
                            }}
                            isDisabled={!props.updateAllowed}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        {props.updateAllowed && (
                          <X
                            className="float-right"
                            size={20}
                            color="red"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              let temp = [...impressionTrackers];
                              temp = temp.filter((_, idx) => idx !== index);
                              setImpressionTrackers(temp);
                            }}
                          ></X>
                        )}
                      </Col>
                    </Row>

                    <Row key={`row-${index + 9}`}>
                      <Col>
                        <FormGroup>
                          <Label>
                            <strong>
                              URL <span className="text-danger">*</span>
                            </strong>
                          </Label>
                          <Input
                            type="textarea"
                            name="url"
                            value={item.url}
                            placeholder="Enter URL"
                            onChange={(option) => {
                              let temp = [...impressionTrackers];
                              temp[index].url = option.target.value;
                              setImpressionTrackers(temp);
                            }}
                            disabled={!props.updateAllowed}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr style={{ marginTop: "50px", marginBottom: "50px" }} />
                  </>
                ))}

                {props.updateAllowed && (
                  <Button onClick={addImpressionTracker}>
                    <FontAwesomeIcon size="sm" icon={faPlus}></FontAwesomeIcon>
                    Add Impression Tracker
                  </Button>
                )}
              </CardBody>
            </Card>

            {displayError ? (
              <p className="text-danger mb-0">
                Please enter all valid details.
              </p>
            ) : null}
            <div style={{ display: "flex", alignItems: "center" }}>
              {props.updateAllowed ? (
                <Button color="primary" type="submit" className="mt-2">
                  Submit
                </Button>
              ) : (
                <div />
              )}
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default AdBannerForm;

import React, { useState } from "react";
import TargetingRules from "../../TargetingRules";
import { retrieveFromLocalStorage } from "../../../../utilities/localStorageUtil";
import { permissions } from "../../../../utilities/commonUtil";
import { getClients } from "../../../../utilities/apiUtils/panelIcon";
import { targetRulesClients } from "../../../../config/ConfigPushNotifications";

import {
    getCountries,
    getRegions,
    getCities, 
    fetchPushNotificationTargetingRules,
    savePushNotificationTargetingRules,
} from "../../../../utilities/apiUtils/pushNotifications";

const PushNotificationTargetingRules = (props) => {
    let id = props.match.params.id;

    const [allowedPermissions] = useState(
        retrieveFromLocalStorage("userPermissions")
    );    

    const updateRulesAllowed = () => {
        return allowedPermissions.includes(permissions.notificationRuleUpdate) && !window.location.href.includes("view");
    };

    return (
        <div>
            {console.log(updateRulesAllowed())}
            <TargetingRules
                showAgeGenderRules={true}
                showCustomAudienceTargetingRules={false}
                showHardFiltering={false}
                id={id}
                getRules={fetchPushNotificationTargetingRules}
                saveRules={savePushNotificationTargetingRules}
                getClients={targetRulesClients}
                getCountries={getCountries}
                getRegions={getRegions}
                getCities={getCities}
                updateRulesAllowed={updateRulesAllowed()}
                overrideClientApi={true}
            />
        </div>
    );
};

export default PushNotificationTargetingRules;

import React, { useState, useEffect, useCallback, Fragment } from "react"
import {
    CardHeader,
    Card,
    CardBody,
    CardImg,
    Col,
    Row,
    Container,
    CardFooter,
    Button,
    Badge,
} from "reactstrap";
import { movieGifDetail, processDateAndStatus } from "../../../utilities/apiUtils/movieGifs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../../components/Loading";
import { fas, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import {
    getStatus,
    errorDescription,
    error,
    permissions,
    redirectTo,
    redirectOnTokenExpiry,
} from "../../../utilities/commonUtil";
import { retrieveFromLocalStorage } from "../../../utilities/localStorageUtil";
import { emojiStickerDetails } from "../../../utilities/apiUtils/emojiStickers";
import TagTable from "./components/tagTable";
import CountryTags from "../../../components/CountryTags";
import MovieGifPreview from "./components/MovieGifPreview";

const MovieGifsDetail = (props) => {
    const allowedPermissions = retrieveFromLocalStorage("userPermissions");

    const movieGifsID = props.match.params.id
    const [hasResponseError, setHasResponseError] = useState(false);
    const [movieGifDetails, setmovieGifDetails] = useState({})
    const [emojiStickerImages, setEmojiStickerImages] = useState([])
    const [errorText, setErrorText] = useState("")
    const [loading, setLoading] = useState(true)
    const [failureModalDisplay, setFailureModalDisplay] = useState(false)
    const [failureModalText, setFailureModalText] = useState("")

    useEffect(() => {
        const fetchMovieGifsDetail = async (movieGifsID) => {
            try {
                let res = await movieGifDetail(movieGifsID)
                if (!res.errorCode) {
                    setHasResponseError(false)
                    setmovieGifDetails(res)
                } else {
                    if (
                        res.errorCode === error.tokenExpired ||
                        res.errorCode === error.invalidAccessToken
                    )
                        redirectOnTokenExpiry();
                    setHasResponseError(true)
                    setErrorText(errorDescription(res.errorCode))
                }
                setLoading(false)
            } catch (err) {
                setLoading(false);
                setFailureModalText(errorDescription(error.unexpectedError))
                setFailureModalDisplay(true);
            }
        }
        fetchMovieGifsDetail(movieGifsID)
    }, [movieGifsID])

    useEffect(() => {
        processDateAndStatus(movieGifDetails)
    },[movieGifDetails])

    const showEditTagsButton = () => {
        return allowedPermissions.includes(permissions.movieGifsUpdate)
    }

    return (
        <Container fluid>

            {
                loading && !failureModalDisplay ? (<Loading />) :
                    <Fragment>
                        <Row className="h2" style={{ justifyContent: "space-between" }} >
                            <h1>Movie Gif Detail</h1>
                            {showEditTagsButton() &&
                                <Button onClick={() => {
                                redirectTo(`/movie-gifs/${movieGifDetails.id}/edit`);
                            }}>Edit</Button>}
                        </Row>
                        <MovieGifPreview movieGifDetails = {movieGifDetails}/>
                        <Card>
                            <TagTable tag={movieGifDetails.tags} gifType="movieGif"> </TagTable>
                        </Card>
                        <Card>
                            <CardBody>
                                <Row className="px-3">
                                    <Col md={8} sm={6}>
                                        <h3>Available Locations</h3>
                                        <p>Locations in which countries this sticker is available</p>
                                    </Col>
                                </Row>
                                <Col>
                                    <CountryTags countries={movieGifDetails.countries}></CountryTags>
                                </Col>
                            </CardBody>
                        </Card>
                    </Fragment>
            }
        </Container>
    )
}
export default MovieGifsDetail
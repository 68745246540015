import { host } from "./index";
import { retrieveFromLocalStorage } from "../localStorageUtil";

// let host = "http://localhost:1323"

export let fetchSegmentsData = async () => {
    let url = `${host}/v4/internal/segments`;
    let token = retrieveFromLocalStorage("token");
    try {
      var response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      return await response.json();
    } catch (error) {
      console.error("Error fetching segments data", error);
      return;
    }
  };
  
// Fetch categories for a specific segment
export let fetchCategoriesForSegment = async () => {
  let url = `${host}/v4/internal/suggestionDrawerCategories?status=all`;
  let token = retrieveFromLocalStorage("token");
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching categories for segment", error);
    return { suggestionDrawerCategories: [] }; // Return an empty array in case of error
  }
};

// Update a priority rule set by ID
export let updatePriorityRuleSet = async (id, data) => {
  let url = `${host}/v4/internal/suggestionDrawerCategories/priorityRuleSets/${id}`;
  let token = retrieveFromLocalStorage("token");
  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error updating priority rule set", error);
    return { status: "error" }; // Return a default status in case of error
  }
};

// List all priority rule sets
export let listPriorityRuleSets = async () => {
  let url = `${host}/v4/internal/suggestionDrawerCategories/priorityRuleSets`;
  let token = retrieveFromLocalStorage("token");
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching priority rule sets", error);
    return { priorityRuleSets: [] }; // Return an empty array in case of error
  }
};

// Create a new priority rule set
export let createPriorityRuleSet = async (data) => {
  let url = `${host}/v4/internal/suggestionDrawerCategories/priorityRuleSets`;
  let token = retrieveFromLocalStorage("token");
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error creating priority rule set", error);
    return { status: "error" }; // Return a default status in case of error
  }
};

// Fetch priority rule set by segment ID
export let fetchPriorityRuleSetBySegment = async (segmentID) => {
  let url = `${host}/v4/internal/suggestionDrawerCategories/priorityRuleSets/${segmentID}`;
  let token = retrieveFromLocalStorage("token");
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching priority rule set by segment", error);
    return { categories: [], priority: null }; // Return default values in case of error
  }
};